  <form [formGroup]="filtersGroup">
    <div class="title-container">
      <span class="main-text">THEN</span>
    </div>

    <div *ngFor="let filterGroup of filtersArray?.controls; let i = index; let last = last"
          formArrayName="Then"
          class="filters" [class.last]="last">
      <then-condition [formData]="filterGroup"
                      [allTags]="allTags"
                      [removable]="filtersArray.length > 1"
                      (onRemoveMainCondition)="removeMainCondition(i)">
      </then-condition>
    </div>

    <op-button-2021 labelText="Add additional tag & variable(s)"
                    matIcon="control_point"
                    buttonType="filled"
                    [disabled]="filtersArray?.invalid"
                    (buttonAction)="addMainCondition()">
    </op-button-2021>
</form>