import { Pipe, PipeTransform } from '@angular/core';

export enum EStraightDatePipeFormats {
  dateSeven2 = 'MMM d, yyyy',
  dateTwentyEight = 'MMM d'
}

@Pipe({
  name: 'straightDate'
})
export class StraightDatePipe implements PipeTransform {

  transform(date: string, format?: EStraightDatePipeFormats): string {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateParts = date.split('-');

    if (format === EStraightDatePipeFormats.dateTwentyEight) {
      return months[+dateParts[1] - 1] + ' ' + dateParts[2];
    }

    return months[+dateParts[1] - 1] + ' ' + dateParts[2] + ', ' + dateParts[0];
  }

}
