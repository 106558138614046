import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { BehaviorSubject, EMPTY, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  skip,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { PrivacyTagsService } from './privacy-tags.service';
import {
  ITagPrivacyComplianceResponse,
  ITagPrivacyPagesResponse,
  ITagPrivacyPagesTagApiBody,
  ITagPrivacySorting,
  ITagPrivacySummary,
  ITagPrivacyTrendOverview,
  ITagPrivacyTrendsOverviews,
  TagPrivacyRelevantFilters,
  TagsPrivacyPagesExportMenuData,
  TagsPrivacyTagComplianceExportMenuData
} from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.models';
import { formatNumber } from '@angular/common';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { sortBy } from 'lodash-es';
import {
  IFullscreenChartConfig,
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  APPROVED_TAGS_CHART_CONFIG,
  TagPrivacyTrendName,
  UNAPPROVED_TAGS_CHART_CONFIG,
  UNIQUE_TAGS_CHART_CONFIG
} from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.constants';
import { Sort } from '@angular/material/sort';
import {
  TagComplianceTableComponent
} from '@app/components/audit-reports/reports/privacy-tags/components/tag-compliance-table/tag-compliance-table.component';
import {
  PrivacyTagsPagesTableComponent
} from '@app/components/audit-reports/reports/privacy-tags/components/privacy-tags-pages-table/privacy-tags-pages-table.component';
import { isEmpty } from 'lodash';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import {
  EConsentCategoryType,
  IConsentCategories,
  IConsentCategorySnapshot
} from '@app/components/consent-categories/consent-categories.models';
import { ECCEditTabs } from '@app/components/consent-categories/cc-edit/cc-edit.constants';
import { IAuditModel } from '@app/components/modals/modalData';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { EPrivacyTagsExportType } from './privacy-tags.enums';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { RouteReloadService } from '@app/components/shared/services/route-reload.service';
import { AlertMetricType, EAlertPrivacyTagsMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { TagsService } from '@app/components/tags/tagsService';
import { PrivacyReportsAbstract } from '@app/components/audit-reports/reports/abstracts/privacy-reports.abstract';
import { EConsentCategoryCreateStep } from '@app/components/consent-categories/cc-create/cc-create.enums';
import { ConsentCategoryCreateComponent } from '@app/components/consent-categories/cc-create/cc-create.component';
import {
  AddConsentCategoriesModalComponent
} from '@app/components/audit-reports/reports/privacy/add-consent-categories-modal/add-consent-categories-modal.component';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'privacy-tags',
  templateUrl: './privacy-tags.component.html',
  styleUrls: ['./privacy-tags.component.scss'],
  providers: [ResizeableTableService]
})
export class PrivacyTagsComponent
  extends PrivacyReportsAbstract
  implements IFilterableAuditReport, OnInit, OnDestroy {

  readonly EConsentCategoryType = EConsentCategoryType;
  // GENERAL
  audit: IAuditModel;
  auditId: number;
  runId: number;
  totalPageCount: number = null;
  filteredPageCount: number = null;
  sparklineDataLoaded: boolean = false;

  apiFilters: IAuditReportApiPostBody = {};
  private filtersUpdated$ = new Subject<void>();

  //Consent Categories
  isLoadedAuditConsentCategories = false;
  isLoadedRunConsentCategories = false;
  ccsAssignedToRun: IConsentCategorySnapshot[] = [];
  auditConsentCategories: IConsentCategories[] = [];
  categoriesType: ECCEditTabs = ECCEditTabs.tags;

  //Tag Compliance
  tags$: Observable<ITagPrivacyComplianceResponse | any[]>;
  tagComplianceTableState: EFilterSpinnerState;
  tagComplianceSortingPagination: ITagPrivacySorting = {
    sortDesc: true,
    sortBy: 'compliance_status',
    page: 0,
    size: 100
  };
  sortPaginateTagCompliance$ = new Subject<void>();
  tagComplianceVariables: ITagPrivacyPagesTagApiBody;
  tagComplianceLocalFilter$ = new Subject<void>();

  tagsMap: { [key: number]: string } = {};
  tagNameToAutofill: string = '';

  //Pages Scanned
  pages$: Observable<ITagPrivacyPagesResponse>;
  pagesTableState: EFilterSpinnerState;
  pagesTableSortingPagination: ITagPrivacySorting = {
    page: 0,
    size: 200,
    sortBy: 'tag_count',
    sortDesc: true
  };
  sortPaginatePages$ = new Subject<void>();

  //WIDGETS
  widgetsState: EFilterSpinnerState;
  widgetSparklineRunsInfo: ISparklineRunInfo[];
  //1 - PAGES SCANNED
  widgetPagesScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Count of pages scanned in this audit/run.',
    metricType: EAlertPrivacyTagsMetric.PagesScanned,
  };
  //2 - PAGES UNAPPROVED TAGS
  widgetPagesUnapprovedTags: ISplitCardChartData = {
    topLabel: 'Pages with Unapproved Tags',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Pages with a tag marked as unapproved in one or more consent categories.',
    topHandler: this.toggleFilterByTag(EConsentCategoryType.UNAPPROVED),
    metricType: EAlertPrivacyTagsMetric.PagesWithUnapprovedTags,
  };
  unapprovedTagCount: number;
  //3 - TAGS EVALUATED
  widgetTagsEvaluated: ISplitCardChartData = {
    topLabel: 'Tags Evaluated ',
    topChangeContent: '',
    tooltip: 'Count of unique Tags discovered during this audit/run.',
    bottomHandler: this.openTagsEvaluatedChart.bind(this),
    metricType: EAlertPrivacyTagsMetric.UniqueTags,
  };
  widgetTagsEvaluatedData: ISparklineChartData[];
  //4 - APPROVED TAGS
  widgetTagsApproved: ISplitCardChartData = {
    topLabel: 'Approved Tags',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Tags approved in one or more consent categories.',
    topHandler: this.toggleFilterByTag(EConsentCategoryType.APPROVED),
    bottomHandler: this.openTagsApprovedChart.bind(this),
    metricType: EAlertPrivacyTagsMetric.ApprovedTags,
  };
  widgetTagsApprovedData: ISparklineChartData[];
  //5 UnapprovedTags
  widgetUnapprovedTags: ISplitCardChartData = {
    topLabel: 'Unapproved Tags',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Tags unapproved in one or more consent categories.',
    topHandler: this.toggleFilterByTag(EConsentCategoryType.UNAPPROVED),
    bottomHandler: this.openTagsUnapprovedChart.bind(this),
    metricType: EAlertPrivacyTagsMetric.UnapprovedTags,
  };
  widgetUnapprovedTagsData: ISparklineChartData[];

  @ViewChild('tagCompliance') tagComplianceComponent: TagComplianceTableComponent;
  @ViewChild('pagesTable') pagesComponent: PrivacyTagsPagesTableComponent;

  // EXPORTS
  tagComplianceExportConfig: TagsPrivacyTagComplianceExportMenuData = {
    tableName: 'Tag Compliance',
    exportType: EPrivacyTagsExportType.tagCompliance,
    totalRows: 0,
    filteredRows: 0,
    tableState: this.tagComplianceSortingPagination,
    filters: this.apiFilters
  };
  pagesExportConfig: TagsPrivacyPagesExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: EPrivacyTagsExportType.pages,
    totalRows: this.totalPageCount,
    filteredRows: this.filteredPageCount,
    tableState: this.pagesTableSortingPagination,
    filters: this.apiFilters,
    specificExportTypes: {
      all: EPrivacyTagsExportType.pages
    },
    dataToCopy: {
      config: [
        {
          property: 'pageUrl',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl,
        },
        {
          property: 'finalPageUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl,
        },
        {
          property: 'pageLoadTime',
          displayLike: row => parseFloat((row.pageLoadTime / 1000).toFixed(1)),
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
        },
        {
          title: '# OF TAG REQUESTS',
          property: 'tagRequestCount',
          tableColumnName: CommonReportsPagesTableColumns.TagCount
        }
      ],
      data: null,
      displayedColumns$: this.tableService.displayedColumns$
    }
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  constructor(
    private route: ActivatedRoute,
    private routeReloadService: RouteReloadService,
    private tagPrivacyService: PrivacyTagsService,
    private filterBarService: AuditReportFilterBarService,
    private auditReportLoadingService: AuditReportLoadingService,
    private modalEscapeService: ModalEscapeService,
    protected modalService: OpModalService,
    private ccService: ConsentCategoriesService,
    private auditService: DiscoveryAuditService,
    private alertReportingService: AlertReportingService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private tagsService: TagsService,
    private uiTagService: UiTagService,
    private tableService: ResizeableTableService,
    private scrollService: AuditReportScrollService,
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.pipe(
      takeUntil(this.onDestroy$),
      tap(({ auditId, runId }) => {
        this.auditId = +auditId;
        this.runId = +runId;

        const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
        const alertId = queryParams?.alertId;
        const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
        this.preventHighlight = !highlight;
        // When loading report from an alert, first override filters with alert
        // filters before loading report data
        if (alertId) {
          this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
            this.filterBarService.overrideFilters(alert.config.filtersV0);
            this.highlightMetricType = highlight && alert.config.metricType;

            this.alertCheckComplete$.next(true);
          });
        } else {
          this.alertCheckComplete$.next(true);
        }

        this.getConsentCategoriesList();
      }),
      skip(1), // only fire when calendar control updated
      tap(() => {
        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          this.onFiltersChanged(this.apiFilters);
        });
      })
    ).subscribe();

    this.routeReloadService.reloadRouteEvents$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getAudit();
        this.getAuditConsentCategoriesList();
      });

    this.getAudit();
    this.getAuditConsentCategoriesList();

    this.handleWidgets();
    this.handleSparklines();
    this.handleComplianceTags();
    this.handlePages();

    this.initFilters();
    this.createTagsMap();
    this.filterBarService.apiPostBody$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  ngOnDestroy() {
    this.destroy();
    this.auditReportLoadingService.forceOff();
    this.pageDetailsDrawerService.closePageDetails();
  }

  private getConsentCategoriesList() {
    this.ccService.getConsentCategoriesAssignedToRun(this.auditId, this.runId)
      .pipe(
        catchError(err => {
          console.error(`Failed to load tag privacy summary for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.message}`);
          return EMPTY;
        })
      ).subscribe(ccs => {
      this.ccsAssignedToRun = ccs;
      this.isLoadedRunConsentCategories = true;
      // compliance and pages are only loaded in response to filter, sorting or pagination change
      // ngIf for the entire component does not allow async pipes to be initialised until assigned CCs are loaded
      // see https://github.com/observepoint/moonbeam/blob/f461ec811f2b642df46a66863e820f614abc4e9b/src/main/web/components/audit-reports/reports/privacy-tags/privacy-tags.component.html#L13
      // sometimes assigned CCS are loaded slower than 1000 ms, so filtersUpdated$ is no longer emitted after that (see ngOnInit)
      // so if neither filtersUpdated$ nor sorting pagination changes are emitted, complience and pages are not loaded
      // emitting sorting pagination change to force loading of compliance and pages
      setTimeout(() => {
        this.sortPaginateTagCompliance$.next();
        this.sortPaginatePages$.next();
      });
      // TODO even though the initial issue is fixed, this entire async mess needs to be refactored
    });
  }

  private isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.length > 0;
  }

  private formatWidgetContent(filteredCount: number, totalCount?: number): string {
    const format = (x: number) => formatNumber(x, 'en-US');
    if (!this.isFiltered() || (this.isFiltered() && !totalCount)) {
      return format(filteredCount);
    } else {
      return `${format(filteredCount)} of ${format(totalCount)}`;
    }
  }

  initFilters(): void {
    this.filterBarService.updateSupportedFiltersList(TagPrivacyRelevantFilters);
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody): void {
    this.apiFilters = apiPostBody;
    this.setFirstPagePages();
    this.setFirstPageTagCompliance();
    this.tagComplianceVariables = undefined;
    this.filtersUpdated$.next();
    this.updateCurrentFilters();
    this.tagNameToAutofill = this.tagsMap[apiPostBody.tagId];
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  handleConsentCategoryNameFilterFromCompliance(snapshotId: number): void {
    const cc = this.ccsAssignedToRun.find(cc => cc.consentCategorySnapshotId === snapshotId);
    if (cc) {
      this.filterBarService.addConsentCategoryNameFilter(cc.consentCategoryId, cc.name);
    }
  }

  private handleWidgets() {
    this.filtersUpdated$.pipe(
      tap(() => {
        this.auditReportLoadingService.addLoadingToken();
        this.widgetsState = EFilterSpinnerState.Loading;
      }),
      switchMap(() =>
        this.tagPrivacyService.getTagPrivacySummary(this.auditId, this.runId, this.apiFilters).pipe(catchError((err) => {
          console.error(`Failed to load tag privacy summary for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);

          return of([]);
        }))
      ),
      tap(() => {
        this.auditReportLoadingService.removeLoadingToken();
      })
    ).subscribe((data: ITagPrivacySummary) => {
      this.updateWidgets(data);

      this.widgetsState = this.isFiltered() ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
    });
  }

  private updateWidgets(data: ITagPrivacySummary) {
    this.totalPageCount = data.totalPageCount;
    this.filteredPageCount = data.filteredPageCount;

    this.tagComplianceExportConfig = {
      ...this.tagComplianceExportConfig,
      totalRows: data.totalUniqueTagCount > 0 ? Infinity : 0
    };
    this.pagesExportConfig = {
      ...this.pagesExportConfig,
      totalRows: this.totalPageCount,
      filteredRows: this.filteredPageCount
    };
    this.unapprovedTagCount = data.filteredPageWithUnapprovedTagCount;

    this.widgetTagsEvaluated.topChangeContent = this.formatWidgetContent(data.filteredUniqueTagCount, data.totalUniqueTagCount);
    this.widgetTagsApproved.topChangeContent = this.formatWidgetContent(data.filteredApprovedTagCount, data.totalApprovedTagCount);

    this.widgetTagsApproved.topChangeMeaning = data.filteredApprovedTagCount === 0
      ? ESplitCardChangeMeaning.NEUTRAL
      : ESplitCardChangeMeaning.POSITIVE;
    this.widgetUnapprovedTags.topChangeContent = this.formatWidgetContent(data.filteredUnapprovedTagCount, data.totalUnapprovedTagCount);
    this.widgetUnapprovedTags.topChangeMeaning = data.filteredUnapprovedTagCount === 0
      ? ESplitCardChangeMeaning.NEUTRAL
      : ESplitCardChangeMeaning.NEGATIVE;
  }

  private handleSparklines() {
    this.filtersUpdated$.pipe(
      tap(() => {
        this.sparklineDataLoaded = false;
        this.auditReportLoadingService.addLoadingToken();
      }),
      switchMap(() => this.tagPrivacyService.getTagPrivacyTrends(this.auditId, this.runId)
        .pipe(catchError((err) => {
          console.error(`Failed to load tag privacy trends for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);

          return of([]);
        }))),
      map((data: ITagPrivacyTrendsOverviews) => sortBy(data.runs, el => el.runId)),
      tap(() => {
        this.auditReportLoadingService.removeLoadingToken();
      })
    ).subscribe(runs => {
      this.updateSparkLines(runs);
      this.sparklineDataLoaded = true;
    });
  }

  private handleComplianceTags() {
    this.tags$ = merge(
      this.filtersUpdated$.pipe(startWith({})),
      this.sortPaginateTagCompliance$
    ).pipe(
      debounceTime(300),
      tap(() => {
        this.auditReportLoadingService.addLoadingToken();
        this.tagComplianceTableState = EFilterSpinnerState.Loading;
      }),
      switchMap(() =>
        this.tagPrivacyService.getCompliance(
          this.auditId,
          this.runId,
          this.tagComplianceSortingPagination,
          this.apiFilters
        ).pipe(
          tap(({ metadata }) => {
            this.tagComplianceExportConfig = {
              ...this.tagComplianceExportConfig,
              filteredRows: metadata.pagination.totalCount,
              filters: this.apiFilters
            };
          }),
          catchError((err) => {
            console.error(`Failed to load tag privacy compliance for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);
            this.tagComplianceTableState = EFilterSpinnerState.None;

            return of([]);
          }),
          finalize(() => {
            this.auditReportLoadingService.removeLoadingToken();
            this.tagComplianceTableState = this.filterBarService.currentRelevantFilters.length
              ? EFilterSpinnerState.Filtered
              : EFilterSpinnerState.None;
          })
        )
      )
    );
  }

  private handlePages() {
    this.pages$ =
      merge(
        this.filtersUpdated$,
        this.tagComplianceLocalFilter$,
        this.sortPaginatePages$,
      ).pipe(
        debounceTime(300),
        tap(() => {
          this.auditReportLoadingService.addLoadingToken();
          this.pagesTableState = EFilterSpinnerState.Loading;
        }),
        switchMap(() => {
          const getPagesRequest = isEmpty(this.tagComplianceVariables) ?
            () => this.tagPrivacyService.getPages(
              this.auditId,
              this.runId,
              this.pagesTableSortingPagination,
              this.apiFilters
            ) :
            () => this.tagPrivacyService.getTagPages(
              this.auditId,
              this.runId,
              this.pagesTableSortingPagination,
              {...this.apiFilters, ...this.tagComplianceVariables}
            );

          return getPagesRequest().pipe(
            tap(({ metadata, pages }) => {
              const filters = isEmpty(this.tagComplianceVariables) ? this.apiFilters : { ...this.apiFilters, ...this.tagComplianceVariables };
              const exportType = isEmpty(this.tagComplianceVariables) ? EPrivacyTagsExportType.pages : EPrivacyTagsExportType.tagPages;
              this.pagesExportConfig = {
                ...this.pagesExportConfig,
                filteredRows: metadata.pagination.totalCount,
                filters,
                exportType,
              };
              this.pagesExportConfig.dataToCopy.data = pages;

              if (!!this.tagComplianceVariables) {
                this.scrollToPages();
              }
            }),
            catchError(() => {
              this.pagesTableState = EFilterSpinnerState.None;
              return EMPTY;
            }),
            finalize(() => {
              this.auditReportLoadingService.removeLoadingToken();
              this.pagesTableState = (this.filterBarService.currentRelevantFilters.length || this.tagComplianceVariables)
                ? EFilterSpinnerState.Filtered
                : EFilterSpinnerState.None;
            })
          );
        })
      );
  }

  private updateSparkLines(data: ITagPrivacyTrendOverview[]) {
    const tagsEvaluated: ISparklineChartData[] = [];
    const approvedTags: ISparklineChartData[] = [];
    const unapprovedTags: ISparklineChartData[] = [];
    const runsInfo: ISparklineRunInfo[] = [];

    data.forEach((val, index) => {
      tagsEvaluated.push({value: val.totalUniqueTagCount, sequence: index});
      approvedTags.push({value: val.totalApprovedTagCount, sequence: index});
      unapprovedTags.push({value: val.totalUnapprovedTagCount, sequence: index});
      runsInfo.push({runId: val.runId, runCompletionDate: val.completedAt});
    });

    this.widgetTagsEvaluated.bottomHandler = tagsEvaluated.length < 2 ? null : this.widgetTagsEvaluated.bottomHandler;
    this.widgetTagsApproved.bottomHandler = approvedTags.length < 2 ? null : this.widgetTagsApproved.bottomHandler;
    this.widgetUnapprovedTags.bottomHandler = unapprovedTags.length < 2 ? null : this.widgetUnapprovedTags.bottomHandler;

    this.widgetSparklineRunsInfo = runsInfo;
    this.widgetTagsEvaluatedData = tagsEvaluated;
    this.widgetTagsApprovedData = approvedTags;
    this.widgetUnapprovedTagsData = unapprovedTags;
  }

  private toggleFilterByTag(name: EConsentCategoryType) {
    return () => {
      if (this.isFilteredByStatus(name)) {
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.ConsentCategoryComplianceStatus);
      } else {
        this.filterBarService.addConsentCategoryStatusFilter(name);
      }
    };
  }

  isFilteredByStatus(name: EConsentCategoryType) {
    return this.filterBarService.isFilteredByTypeAndValue(EAuditReportFilterTypes.ConsentCategoryComplianceStatus, name);
  }

  private addSelectionToConsentCategories(items): void {
    this.modalService
      .openModal(AddConsentCategoriesModalComponent, {
        data: {
          runId: this.runId,
          auditId: this.auditId,
          items
        },
        autoFocus: false
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
      });
  }

  openConsentCategoryManager(): void {
    const assignToCCConfig = {
      data: {
        skipCreate: true,
        step: EConsentCategoryCreateStep.TAGS,
        type: !this.ccsAssignedToRun?.length ? 'Assign ' : this.ccsAssignedToRun[0]?.type === EConsentCategoryType.UNAPPROVED ? 'Unapproved' : 'Approved',
        editing: true,
        cookies: [],
        tags: [],
        requestDomains: [],
        auditId: this.auditId,
      }
    };

    this.modalService.openFixedSizeModal(ConsentCategoryCreateComponent, assignToCCConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe((ccData) => {
        if (ccData) {
          this.addSelectionToConsentCategories(ccData);
        }
      });
  }

  private openTagsApprovedChart() {
    if (this.widgetSparklineRunsInfo.length > 1) {
      this.openFullscreenChart(
        TagPrivacyTrendName.ApprovedTags, this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate);
    }
  }

  private openTagsEvaluatedChart() {
    if (this.widgetSparklineRunsInfo.length > 1) {
      this.openFullscreenChart(TagPrivacyTrendName.UniqueTags, this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate);
    }
  }

  private openTagsUnapprovedChart() {
    if (this.widgetSparklineRunsInfo.length > 1) {
      this.openFullscreenChart(TagPrivacyTrendName.UnApprovedTags, this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate);
    }
  }

  private openFullscreenChart(
    trendName: TagPrivacyTrendName,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName)
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  private getFullscreenChartData(trendName: any, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.tagPrivacyService.getTagPrivacyTrend(this.auditId, trendName, days)
      .pipe(
        map(({ runs }) => ({
            chartData: runs.map(({ trendValue, completedAt }) => ({ value: trendValue, date: completedAt }))
          }),
        )
      );
  }

  private getFullscreenChartConfig(trendName: TagPrivacyTrendName): IFullscreenChartConfig {
    switch (trendName) {
      case TagPrivacyTrendName.UniqueTags:
        return UNIQUE_TAGS_CHART_CONFIG;
      case TagPrivacyTrendName.UnApprovedTags:
        return UNAPPROVED_TAGS_CHART_CONFIG;
      case TagPrivacyTrendName.ApprovedTags:
        return APPROVED_TAGS_CHART_CONFIG;
    }
  }

  handleSortTagCompliance(sort: Sort) {
    this.tagComplianceSortingPagination.sortBy = sort.active;
    this.tagComplianceSortingPagination.sortDesc = sort.direction === 'desc';
    this.setFirstPageTagCompliance();
    this.sortPaginateTagCompliance$.next();
  }

  handlePaginationTagCompliance(index: number) {
    this.tagComplianceSortingPagination.page = index;
    this.sortPaginateTagCompliance$.next();
  }

  private setFirstPagePages() {
    this.pagesTableSortingPagination.page = 0;

    if (this.pagesComponent) {
      this.pagesComponent.paginator.pageIndex = 0;
    }
  }

  private setFirstPageTagCompliance() {
    this.tagComplianceSortingPagination.page = 0;

    if (this.tagComplianceComponent) {
      this.tagComplianceComponent.paginator.pageIndex = 0;
    }
  }

  handleLocalFilter(tagComplianceVariables: ITagPrivacyPagesTagApiBody) {
    this.tagComplianceVariables = tagComplianceVariables;
    this.pagesTableSortingPagination.page = 0;
    this.setFirstPagePages();
    this.tagComplianceLocalFilter$.next();
    this.tagNameToAutofill = this.tagsMap[tagComplianceVariables.tagId];
  }

  handleSortPages(sort: Sort) {
    this.pagesTableSortingPagination.sortBy = sort.active;
    this.pagesTableSortingPagination.sortDesc = sort.direction === 'desc';
    this.setFirstPagePages();
    this.sortPaginatePages$.next();
  }

  handlePaginationPages(index: number) {
    this.pagesTableSortingPagination.page = index;
    this.sortPaginatePages$.next();
  }

  private getAuditConsentCategoriesList() {
    this.ccService
      .getConsentCategoriesAssignedToAudit(this.auditId)
      .subscribe(ccs => {
        this.auditConsentCategories = ccs;
        this.isLoadedAuditConsentCategories = true;
      });
  }

  private getAudit() {
    this.auditService
      .getAudit(this.auditId)
      .then(audit => this.audit = audit);
  }

  private createTagsMap(): void {
    this.uiTagService.getTags().subscribe((tags: IUiTag[]) => {
      this.tagsMap = tags.reduce((acc, tag) => {
        acc[tag.id] = tag.name;
        return acc;
      }, {});
    });
  }

  private scrollToPages(): void {
    this.scrollService.scrollByElement(this.pagesComponent.scrollTop.nativeElement);
  }
}
