<div [formGroup]="formGroup" [class.read-only]="modalType === EAlertModalType.ReadOnly">
  <div class="form-input emails-control">
    <mat-label class="form-label">
      Add subscribers to this alert
    </mat-label>
     <op-recipients-form-control formControlName="emails" [readOnlyLabel]="readOnlyLabel" [isReadOnly]="modalType === EAlertModalType.ReadOnly"></op-recipients-form-control>
  </div>

  <div class="form-input">
    <mat-label class="form-label">
      Add custom alert message
    </mat-label>
    <mat-form-field [matTooltip]="readOnlyLabel"
                    matTooltipPosition="above"
                    [matTooltipDisabled]="modalType !== EAlertModalType.ReadOnly"
                    appearance="outline"
                    floatLabel="always"
                    class="multi-line">
      <mat-label>ENTER MESSAGE</mat-label>
      <textarea matInput rows="3"
                formControlName="message"
                placeholder="Notes would typically include a description of what this alert is monitoring and/or expected actions that should be taken by your team when the alert is triggered.">
      </textarea>
      <mat-error *ngIf="messageControl?.errors?.maxlength">Character limit exceeded ({{ messageControl?.errors?.maxlength?.actualLength }} of {{ messageControl?.errors?.maxlength?.requiredLength }})</mat-error>
      <mat-hint *ngIf="!messageControl?.errors?.maxlength">Notes will be included in all notifications that are sent.</mat-hint>
    </mat-form-field>
  </div>

  <div class="form-input policy-control">
    <mat-label class="form-label">
      Specify when alert is to be sent
    </mat-label>
    <mat-form-field appearance="outline" class="styled-mat-input no-label">
      <mat-select formControlName="policy"
                  [matTooltip]="readOnlyLabel"
                  matTooltipPosition="above"
                  [matTooltipDisabled]="modalType !== EAlertModalType.ReadOnly"
                  [disabled]="modalType === EAlertModalType.ReadOnly"
                  placeholder="Select option..."
                  disableOptionCentering>
        <mat-option *ngFor="let policy of NotificationPolicies" [value]="policy.id" class="slim-option">
          {{ policy.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
