import { Component, Input } from '@angular/core';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import { VerticalBarsChartComponent } from '@app/components/shared/components/viz/vertical-bars-chart/vertical-bars-chart.component';
import { IAlertBarsChartBar, IAlertBarsChartColumn } from './alert-preview-chart.models';
import { AuditReportUrlBuilders } from '@app/components/audit-reports/audit-report/audit-report.constants';
import * as d3 from 'd3';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-preview-chart',
  templateUrl: './alert-preview-chart.component.html',
  styleUrls: ['./alert-preview-chart.component.scss']
})
export class AlertPreviewChartComponent extends VerticalBarsChartComponent<IAlertBarsChartBar> {

  @Input() subgroups: Array<keyof IAlertBarsChartBar> = ['actualValue'];

  columns: IAlertBarsChartColumn[];
  columnTooltip;
  columnTooltipWidth: number;

  protected readonly TOOLTIP_CLASS = 'triggered-alert-tooltip';

  protected readonly MARGIN = {
    TOP: 60,
    RIGHT: 0,
    BOTTOM: 45,
    LEFT: 10
  };

  /**
   * This instance of drawChart allows us to have a fixed width for the bars so that they don't get
   * too squiched when there are many data points. We've allowed the chart to get as wide as it needs
   * in order to display all of the bars. In the CSS we've set an overflow: auto; so that if there
   * are too many bars to display within the viewport width, we can scroll horizontally to see them.
   */
  drawChart(): void {
    // ensure we have data
    if (!this.data?.length) return;

    // format the data
    this.data = this.formatChartData(this.data);

    // get the dimensions of our container element
    this.container = d3.select(`.svg-container-${this.uniqueIdentifier}`).html('');
    if (this.container.empty()) return;

    // get dimensions of svg container
    this.svgHeight = Math.max(parseInt(this.container.style('height')), this.MIN_CHART_HEIGHT);

    // append svg element and set dimensions
    this.svg = this.container
      .append('svg')
      .attr('height', this.svgHeight);

    // get column labels and ids
    this.getColumns();

    // get all values to set y scale (we need to know the largest value that could be displayed)
    this.values = this.data.map(d => this.subgroups.reduce(
      (acc, subgroup) => acc += d[subgroup as string],
      0
    ));

    // y scale
    this.y = d3
      .scaleLinear()
      // if domain is [0, 0], the bars are drawn in the middle of y axis
      // in order to draw them at the bottom, the max domain value should be > than the min (in this cases, the domain is [0, 1e-9])
      .domain([0, d3.max([...this.values, this.limit ? (this.limit + Math.floor(this.limit / 10)) : 0, 1e-9])])
      .range([this.svgHeight - this.MARGIN.BOTTOM - this.MARGIN.TOP, 0]);

    // y axis
    this.yAxis = this.svg
      .append('g')
      .attr('dominant-baseline', 'ideographic')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(this.y).ticks(2));

    const { width: yAxisWidth } = this.yAxis.node().getBBox();

    this.yAxis.attr('transform', `translate(${this.showYAxis ? yAxisWidth + this.MARGIN.LEFT : 0}, ${this.MARGIN.TOP + 3})`);

    // Set a static bar width
    this.normalisedBandWidth = 85;

    // x scale
    this.x = d3
      .scaleBand()
      .domain(this.columns.map(column => column.id))
      .range([0, this.columns.length * this.normalisedBandWidth])
      .paddingInner(0.1);

    // format data for chart
    this.formattedData = d3.stack().keys(this.subgroups as string[])(this.data as any);

    // bars
    this.buildBar(yAxisWidth);

    // bar labels (underneath bars)
    this.buildBarLabels(yAxisWidth);

    // bar values (on top of bars)
    this.buildBarValues(yAxisWidth);

    if (this.isClickable) {
      // tooltip
      // only create if it's not already on the page
      if (!d3.select(`.${this.TOOLTIP_CLASS}`).node()) this.createTooltip();

      // get reference to the DOM element
      this.tooltip = d3.select(`.${this.TOOLTIP_CLASS}`);

      this.bars.on('mouseover', (e: MouseEvent, d: any) => this.onBarsMouseOver(e, d));
      this.bars.on('mousemove', (e: MouseEvent) => this.handleTooltipPosition(e));
      this.bars.on('mouseout', () => this.onBarsMouseOut());
    }

    this.buildLimitLine();

    // After calculating the x scale
    this.svgWidth = this.x.range()[1] + (this.showYAxis ? yAxisWidth + this.MARGIN.LEFT : 0);
    this.svg.attr('width', this.svgWidth);
  }

  protected getColumns() {
    this.columns = this.data.map(d => ({
      groupLabel: d.groupLabel,
      label: d.label,
      id: d.id,
      auditId: d.auditId
    }));
  }

  protected buildBar(yAxisWidth: number) {
    this.bars = this.svg
      .append('g')
      .attr('class', 'bars-not-clickable bars')
      .attr('transform', `translate(${this.showYAxis ? yAxisWidth + this.MARGIN.LEFT : 0}, ${this.MARGIN.TOP})`)
      .selectAll('g')
      .data(this.formattedData)
      .join('g')
      .attr('class', (d: any) => d.key)
      .selectAll('rect')
      .data((d: any) => d)
      .join('rect')
      .attr('x', (d: any) => this.xBandStartNormalised(this.x(d.data.id)))
      .attr('y', (d: any) => this.y(d[1]))
      .attr('height', (d: any) => this.getBarHeight(d))
      .attr('width', this.normalisedBandWidth)
      .attr('class', (d: any) => d.data.triggered ? 'triggered' : '');
  }

  protected buildBarLabels(yAxisWidth: number) {
    /* Days of month */
    this.barLabels = this.svg.append('g');

    // add <g> for each column
    this.barLabels
      .selectAll('g')
      .data(this.columns)
      .join('g')
      .attr('class', 'bar-label-group')
      .on('click', (e: MouseEvent, d) => {
        this.openRunInNewTab(d);
      })
      .on('mouseover', (e: MouseEvent, d) => {
        this.columnTooltip
          .style('display', 'block')

        this.columnTooltipWidth = this.columnTooltip.node().getBoundingClientRect().width;

        this.columnTooltip
          .style('left', e.pageX - (this.columnTooltipWidth / 2) + 'px')
          .style('top', e.pageY - 50 + 'px');
      })
      .on('mouseout', () => {
        this.columnTooltip.style('display', 'none');
      })
      .append('text')
      .text((d: { label: string; id: string }) => d.label.split('|')[0].trim())
      .attr('x', (d: { label: string; id: string }) => this.xBandStartNormalised(this.x(d.id)) + (this.normalisedBandWidth / 2))
      .attr('y', this.svgHeight - 25)
      .attr('text-anchor', 'middle')
      .attr('class', `${this.uppercaseText ? 'uppercase' : 'lowercase'} bar-label`);

    // add inner <g> for each column to group time and icon together
    let iconGroup = this.barLabels
      .selectAll('g')
      .data(this.columns)
      .append('g')
      .attr('class', 'time-icon-group');

    // add time <text> to inner <g>
    iconGroup
      .append('text')
      .text((d: { label: string; id: string }) => d.label.split('|')[1].trim())
      .attr('x', (d: { label: string; id: string }) => this.xBandStartNormalised(this.x(d.id)))
      .attr('y', this.svgHeight - 10)
      .attr('text-anchor', 'start')
      .attr('class', `${this.uppercaseText ? 'uppercase' : 'lowercase'} bar-label`);

    // add icon to inner <g>
    iconGroup
      .append('svg:image')
      .attr('xlink:href', '/images/open-in-new-tab-icon.svg')
      .attr('x', (d: { label: string; id: string }) => this.xBandStartNormalised(this.x(d.id)) + (this.normalisedBandWidth / 2) + 5)
      .attr('y', this.svgHeight - 20)
      .attr('width', 12)
      .attr('class', 'open-in-new-tab-icon');

    // move inner <g> to center it under the column
    iconGroup
      .data(this.columns)
      .attr('transform', (d, i, n) => {
        const groupWidth = n[i].getBBox().width;
        return `translate(${(this.normalisedBandWidth - groupWidth) / 2}, 0)`;
      });

    // tooltip
    const createTooltip = () => {
      d3
        .select('body')
        .append('div')
        .attr('class', this.TOOLTIP_CLASS)
        .html('View this audit run in a new browser tab');
    }

    // only create if it's not already on the page
    if(!d3.select(`.${this.TOOLTIP_CLASS}`).node()) createTooltip();

    // get reference to the DOM element
    this.columnTooltip = d3.select(`.${this.TOOLTIP_CLASS}`);
  }

  private openRunInNewTab(d: IAlertBarsChartColumn) {
    const url = AuditReportUrlBuilders.useCaseOverview(+d.auditId, +d.id);
    window.open(url, '_blank');
  }

  protected buildBarValues(yAxisWidth: number) {
    /* Display actual run value */
    this.barValues = this.svg
      .append('g')
      .attr('transform', `translate(${this.showYAxis ? yAxisWidth + this.MARGIN.LEFT : 0}, 0)`)
      .data(this.formattedData)
      .selectAll('text')
      .data(d => d)
      .join('text')
      .attr('x', d => this.xBandStartNormalised(this.x(d.data.id)) + (this.normalisedBandWidth / 2))
      .attr('y', (d, index) => {
        const barValue = this.subgroups.reduce((acc, subgroup) => acc += d.data[subgroup], 0);
        const offset = index === this.formattedData.length - 1 ? 5 : 15;
        return this.y(barValue) + this.MARGIN.TOP - offset;
      })
      .attr('text-anchor', 'middle')
      .text(d => VizUtilsService.formatChartNumbers(
        this.subgroups.reduce((acc, subgroup) => acc += d.data[subgroup], 0)
      ))
      .attr('class', 'bar-value');

    /* Display difference from the previous run */
    this.svg
      .append('g')
      .attr('transform', `translate(${this.showYAxis ? yAxisWidth + this.MARGIN.LEFT : 0}, 0)`)
      .data(this.formattedData)
      .selectAll('text')
      .data(d => d)
      .join('text')
      .attr('x', d => this.xBandStartNormalised(this.x(d.data.id)) + (this.normalisedBandWidth / 2))
      .attr('y', d => {
        const barValue = this.subgroups.reduce((acc, subgroup) => acc += d.data[subgroup], 0);
        const offset = 5;
        return this.y(barValue) + this.MARGIN.TOP - offset;
      })
      .attr('text-anchor', 'middle')
      .text((d: any) => {
        const { diff } = d.data;

        if (!diff || diff.value === null) return '';

        const sign = diff.value > 0 ? '+' : '';
        const unit = diff.isRelative ? '%' : '';

        return  '(' + sign + diff.value + unit + ')';
      })
      .attr('class', 'bar-value bar-diff');
  }

}
