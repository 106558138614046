<div class="standards-changed-snackbar-container">
  <header>
    <button mat-flat-button class="close-button" (click)="closeSnackbar()">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  <div *ngIf="loading">
    <mat-spinner [diameter]="50" [strokeWidth]="3" [color]="'accent'" ></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <p>{{standardsCountText}}</p>
    <p class="call-to-action-text" *ngIf="runId">Would you like to reprocess and apply {{totalStandardsChanged > 1 ? 'them' : 'it'}} now?</p>
    <p class="call-to-action-text" *ngIf="!runId">This audit has not yet run. Would like to run it now?</p>
    <div class="buttons" *ngIf="runId">
      <button mat-flat-button class="bordered-button" (click)="closeSnackbar()">
        No, don't reprocess
      </button>
      <button mat-flat-button [disabled]="disableReprocess" (click)="reprocessStandards()">
        <mat-icon>loop</mat-icon>
        Yes, reprocess now
      </button>
    </div>
    <div class="buttons" *ngIf="!runId">
      <button mat-flat-button class="bordered-button" (click)="closeSnackbar()">
        No, don't run now
      </button>
      <button mat-flat-button [disabled]="disableRunNow" (click)="runAudit()">
        <mat-icon>loop</mat-icon>
        Run now
      </button>
    </div>
  </div>
</div>
