import { INavActionDetails } from '@app/components/actions/action-details/action-details.models';
import { EActionType } from '@app/components/web-journey/web-journey.models';

export const defaultAuditAction = (): INavActionDetails => {
  return {
    type: EActionType.NavTo,
    url: '',
    preventNavigation: false,
  };
};

export const defaultWebJourneyAction = (url: string = ''): INavActionDetails => {
  return {
    type: EActionType.NavTo,
    url,
    rules: []
  };
};
