<div class="mat-app-background">
  <div id="application" class="flex-col"
      *ngIf="user"
      [class]="sidebarStatusClass"
      [class.upgrade-banner-visible]="showUpgradeUserBanner">
    <div id="loggedInAsAnotherBar" *ngIf="isAnotherUser">
      <span>You are currently logged in as user <b>{{user.username}}</b> with id:{{user.id}} and account id:{{user.accountId}}.</span>
      <a id="switchBack" (click)="loginAsOriginal()">Switch Back</a>
    </div>
    <upgrade-user-banner *ngIf="showUpgradeUserBanner"></upgrade-user-banner>
    <mat-drawer-container class="app-drawer-container" [hasBackdrop]="true">
      <mat-drawer #drawer mode="over" (openedChange)="handleOpenState($event)">
        <op-sidebar [isMobile]="true" [drawer]="drawer" [isVisible]="mobileFlyoutIsVisible" (mobileFlyoutIsVisible)="mobileFlyoutIsVisible = $event"></op-sidebar>
      </mat-drawer>
      <mat-drawer-content>
        <div class="global-menu-header">
          <button [class.hide-for-visitors]="isVisitorMode$ | async" (click)="drawer.open()" class="mobile-menu-btn">
            <mat-icon>menu</mat-icon>
          </button>
          <i class="icon-op-logo"
             [routerLink]="'/data-sources'"
             matTooltip="Nav to Data Sources"
             matTooltipPosition="right"></i>
          <div class="blank-for-flex"></div>
        </div>
        <div class="app-wrap flex-row" [ngClass]="{'logged-in-as-active' : isAnotherUser}">
          <op-sidebar class="app-sidebar" *ngIf="(isVisitorMode$ | async) === false"></op-sidebar>
          <div class="mainView flex-col flex-stretch" [ngClass]="{'full-screen' : isFullScreen}">
            <div class="top-bar-tools flex-row">
              <router-outlet name="navTopBar" class="nav-top-bar-view flex-row flex-stretch"></router-outlet>
            </div>
            <div class="mainContent flex-col" [ngClass]="{'adminPortalBackground' : isInAdminPortal()}">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
