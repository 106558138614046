import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { WebJourneyReportUrlBuilders } from './../web-journey-report/web-journey-report.constants';
import { DataSourcesUrlBuilders } from './../manage/cards/manage-cards.constants';
import { Router } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';
import { ISearchResult, ISearchFolder, ISearchDomain, ISearchJourney } from '@app/moonbeamModels';
import { AccountSearchService } from './account-search.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'account-search',
  templateUrl: './account-search.component.html',
  styleUrls: ['./account-search.component.scss']
})
export class AccountSearchComponent implements OnInit {

  query: string = '';
  results: ISearchResult;
  maxSearchHeight: number;
  search$ = new Subject<string>();
  resize$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<AccountSearchComponent>,
    private searchService: AccountSearchService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.search$.pipe(debounceTime(150)).subscribe(() => {
      this.getSearchResults();
    });
  }

  @HostListener('window:resize')
  onResize(): void {
    this.resize$.next();
  }

  searchAccount(): void {
    this.search$.next();
  }

  getSearchResults(): void {
    this.searchService.doSearch(this.query).subscribe((results: ISearchResult) => {
      this.results = results;
    });
  }

  clearSearch(): void {
    this.query = '';
    this.results = null;
  }

  goToFolder(folder: ISearchFolder): void {
    const {extras, route} = DataSourcesUrlBuilders.selected('folder', folder.id);
    this.router.navigate([route], extras);
    this.closeModal();
  }

  goToDomain(domain: ISearchDomain): void {
    const {extras, route} = DataSourcesUrlBuilders.selected('domain', domain.id);
    this.router.navigate([route], extras);
    this.closeModal();
  }

  goToAudit(audit: ISearchJourney): void {
    this.router.navigateByUrl(AuditReportContainerUrlBuilders.base(audit.id, audit.runId));
    this.closeModal();
  }

  goToWebJourney(webJourney: ISearchJourney): void {
    this.router.navigateByUrl(WebJourneyReportUrlBuilders.results(webJourney.id, webJourney.runId));
    this.closeModal();
  }

  closeModal(): void {
    this.clearSearch();
    this.dialogRef.close();
  }
}
