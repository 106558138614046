import { Injectable } from '@angular/core';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import {
  IOpFilterBarV2Filter,
  IOpFilterBarV2MenuItem
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { OpFilterBarV2Service } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EEmailInboxesFilterBarMenuStrings,
  EEmailInboxesFilterTypes,
  EMAIL_INBOXES_SUPPORTED_FILTER_TYPES
} from './email-inboxes-filter-bar.constants';
import { IEmailInboxesFilter } from './email-inboxes-filter-bar.models';

const FILTERS_STORAGE_KEY = 'email_inboxes_filters';

@Injectable()
export class EmailInboxesFilterBarService extends OpFilterBarV2Service<EEmailInboxesFilterTypes> {
  constructor(
    storage: StorageService,
    cacheReset: CacheResetService
  ) {
    super(storage, FILTERS_STORAGE_KEY, cacheReset);
    this.updateSupportedFiltersList(EMAIL_INBOXES_SUPPORTED_FILTER_TYPES);
  }

  get filters$(): Observable<IOpFilterBarV2Filter<EEmailInboxesFilterTypes>[]> {
    return this.relevantFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters
          .filter((filter: IEmailInboxesFilter) => this.validTypes.includes(filter.type))
          .reduce((acc, curr) => {
            acc.push(curr);
            return acc;
          }, [])
      )
    );
  }

  protected createUniqueArrayOfFilters(filters: IOpFilterBarV2Filter<EEmailInboxesFilterTypes>[]): IOpFilterBarV2Filter<EEmailInboxesFilterTypes>[] {
    if (!filters) return [];

    // Unique filters
    const filterStringsToObjects = filters
      .reduce<{ [key: string]: IOpFilterBarV2Filter<EEmailInboxesFilterTypes> }>((acc, filter) => {
        acc[JSON.stringify(filter)] = filter;
        return acc;
      }, {});

    return Object.values(filterStringsToObjects);
  }

  handleEmailInboxNameContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: EEmailInboxesFilterTypes.Name,
      display: `Inbox name contains '${contains}'`,
      value: [contains],
      menuItems: [],
      icon: 'search',
      order: 2
    }, replaceAll);
  }

  handleEmailInboxesLabelsFilter(checked: boolean, label: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false): void {
    label.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    this.addFilter({
        type: EEmailInboxesFilterTypes.Label,
        display: `Email Inbox Labels (${numChecked})`,
        value: [label.id],
        menuName: EEmailInboxesFilterBarMenuStrings.Label,
        menuItems: menuItems,
        icon: 'label',
        order: 3
      },
      replaceAll,
      numChecked,
      checked
    );
  }

  handleEmailAddressContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: EEmailInboxesFilterTypes.EmailAddress,
      display: `Email address contains '${contains}'`,
      value: [contains],
      menuItems: [],
      icon: 'search',
      order: 4
    }, replaceAll);
  }

  handleInboxNameContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: EEmailInboxesFilterTypes.Name,
      display: `Inbox name contains '${contains}'`,
      value: [contains],
      menuItems: [],
      icon: 'search',
      order: 4
    }, replaceAll);
  }

  handleAuditNameContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: EEmailInboxesFilterTypes.AssociatedAuditName,
      display: `Audit name contains '${contains}'`,
      value: contains,
      menuItems: [],
      icon: 'search',
      order: 5
    }, replaceAll);
  }

  handleEmailInboxesAssociatedAuditFilter(checked: boolean, audit: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false) {
    audit.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    this.addFilter({
        type: EEmailInboxesFilterTypes.AssociatedAuditName,
        display: `Associated Audit Name (${numChecked})`,
        value: [audit.id],
        menuName: EEmailInboxesFilterBarMenuStrings.AuditName,
        menuItems: menuItems,
        order: 6
      },
      replaceAll,
      numChecked,
      checked
    );
  }

  handleEmailInboxesCreatedByFilter(checked: boolean, user: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false) {
    user.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    this.addFilter({
        type: EEmailInboxesFilterTypes.CreatedBy,
        display: `Created by (${numChecked})`,
        value: [user.id],
        menuName: EEmailInboxesFilterBarMenuStrings.Label,
        menuItems: menuItems,
        icon: 'person',
        order: 7
      },
      replaceAll,
      numChecked,
      checked
    );
  }

  getNumChecked(menuItems: IOpFilterBarV2MenuItem[]): number {
    let count = 0;

    menuItems.forEach(item => {
      if (item.checked && !item.children) count++;
      if (item.children?.length) {
        item.children.forEach(child => {
          if (child.checked) count++;
        });
      }
    });

    return count;
  }

  onMenuClosed(): void {
    this.currentFilters.forEach(filter => {
      if (filter.value.length === 0) this.removeFilterByType(filter.type);
    });
  }

  getFilterValues(rawFilters) {
    let filters = {};
    rawFilters.forEach(filter => filters[filter.type] = filter);

    let filterByName = filters[EEmailInboxesFilterTypes.Name]?.value?.length > 0;
    let filterByLabel = filters[EEmailInboxesFilterTypes.Label]?.value?.length > 0;

    return {
      filters,
      filterByName,
      filterByLabel
    };
  }

  filterByName(card, filters): boolean {
    return !!filters[EEmailInboxesFilterTypes.Name].value.find(value => {
      return card.name?.toLowerCase().includes(value.toLowerCase());
    });
  }

  filterByLabel(card, filters): boolean {
    const labelFilterValues = filters[EEmailInboxesFilterTypes.Label]?.value;
    let found = [];
    labelFilterValues.forEach(labelId => {
      let labelIndex = card.labels.findIndex(label => labelId === label.id);
      labelIndex > -1 ? found.push(true) : found.push(false);
    });
    return found.includes(false);
  }
}
