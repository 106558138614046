<p class="message">{{ data.itemType }} have been updated, would you like these changes reflected in this report?</p>
<div class="items-container">
  <ul>
    <li *ngFor="let item of items" class="item">{{ item.name }}</li>
  </ul>
</div>
<div class="buttons">
  <button class="btn" (click)="close()">CLOSE</button>
  <button class="btn" (click)="reprocessRules()">YES, REPROCESS NOW</button>
</div>

