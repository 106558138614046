<div class="flex-row action-wait-time">
  <span class="text">Wait</span>

  <mat-form-field floatLabel="never" appearance="outline">
    <input matInput
          type="number"
          [min]="waitTime.min"
          [max]="waitTime.max"
          [(ngModel)]="waitDuration"
          (ngModelChange)="onModelChange($event)" />
  </mat-form-field>

  <span class="text">additional seconds on this action.</span>
</div>
