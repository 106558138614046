export enum EFieldLabels {
  NAME = 'Audit Name',
  STARTING_URL = 'Starting URLS',
  PAGE_COUNT = 'How many pages should be audited?',
  REQUEST_RATE = 'How fast should the audit go?',
  FREQUENCY = 'How often should the audit run?',
  RUN_FROM = 'Where should the audit run from?',
  INCLUDE = 'included',
  EXCLUDE = 'excluded',
  RFM = 'File substitutions (remote file mapping)',
  NOTIFICATIONS = 'Input email addresses (one per line)',
  NEXT_DATE = 'Next run date',
  INITIAL_DATE = 'Initial run date',
  NEXT_TIME = 'Next run time',
  INITIAL_TIME = 'Initial run time',
  BROWSER = 'Browser',
  USER_AGENT = 'User agent',
  WIDTH = 'Width (pixels)',
  HEIGHT = 'Height (pixels)',
  LABELS = 'LABELS',
  START_TIME = 'Start time',
  END_TIME = 'End time',
  CUSTOM_PROXY = 'Custom Proxy',
  EASY_BLOCK_TAGS = 'Are there specific tags or tag categories that should be blocked?',
}

export enum ELearnMoreOptions {
  dedup,
  requestRate,
  vpn,
  frequency,
  proxy,
  browser,
  include,
  rfm,
  audit,
  blackout,
}

export const ELearnMoreOptionsLinks = {
  dedup: 'https://help.observepoint.com/en/articles/9099982-creating-audits#h_190e61cb8f',
  requestRate: 'https://help.observepoint.com/en/articles/9101465-allow-exclude-observepoint-traffic',
  vpn: 'https://help.observepoint.com/en/articles/9101494-scanning-secure-content#h_84c0a0d98d',
  gpc: 'https://help.observepoint.com/en/articles/9101148-gpc-signal',
  browser: 'https://help.observepoint.com/en/articles/9100800-user-agents',
  userAgents: 'https://help.observepoint.com/en/articles/9100800-user-agents',
  include: 'https://help.observepoint.com/en/articles/9101674-audit-include-exclude-lists',
  rfm: 'https://help.observepoint.com/en/articles/9094817-file-substitutions',
  audit: 'https://help.observepoint.com/en/articles/9099982-creating-audits',
  blackout: 'http://help.observepoint.com/en/articles/9101206-blackout-periods',
  frequency: 'https://help.observepoint.com/en/articles/9101166-audit-best-practices ',
  proxy: 'https://help.observepoint.com/en/articles/9101494-scanning-secure-content',
  customProxy: 'https://help.observepoint.com/en/articles/9101494-scanning-secure-content#h_7c44c02fa6',
  webhook: 'https://help.observepoint.com/en/articles/9101509-webhooks',
  labels: 'https://help.observepoint.com/en/articles/9090897-labels',
};

export const REQUEST_RATES = [
  { rate: 1, label: '1 Browser' },
  { rate: 5, label: '5 Simultaneous Browsers' },
  { rate: 10, label: '10 Simultaneous Browsers (default)' },
  { rate: 20, label: '20 Simultaneous Browsers' },
  { rate: 50, label: '50 Simultaneous Browsers' },
];

export enum EAuditSetupOpSelectors {
  name = 'audit-setup-name',
  subfolder = 'audit-setup-subfolder',
  startingUrls = 'audit-setup-starting-urls-textarea',
  startingUrlsToggle = 'audit-setup-starting-urls-toggle',
  pageLimit = 'audit-setup-page-limit',
  auditSpeed = 'audit-setup-audit-speed',
  frequency = 'audit-setup-frequency',
  nextRunDate = 'audit-setup-next-run-date',
  nextRunTime = 'audit-setup-next-run-time',
  labels = 'audit-setup-labels',
  vpn = 'audit-setup-vpn',
  customVpn = 'audit-setup-custom-vpn',
  browser = 'audit-setup-browser',
  userAgent = 'audit-setup-user-agent',
  browserWidth = 'audit-setup-browser-width',
  browserHeight = 'audit-setup-browser-height',
  includeList = 'audit-setup-include-list',
  excludeList = 'audit-setup-exlude-list',
  auditSameUrlsToggle = 'audit-setup-audit-same-urls-toggle',
  dedupeUrlToggle = 'audit-setup-audit-dedupe-url-toggle',
  blackoutToggle = 'audit-setup-audit-blackout-toggle',
  blackoutStart = 'audit-setup-audit-blackout-start',
  blackoutEnd = 'audit-setup-audit-blackout-end',
  rfm = 'audit-setup-rfm',
  vpnToggle = 'audit-setup-vpn-toggle',
  gpcToggle = 'audit-setup-gpc-toggle',
  notificationList = 'audit-setup-notification-list',
  webhook = 'audit-setup-webhook',
  blockThirdPartyCookiesToggle = 'audit-setup-block-3rd-party-cookies-toggle',
}
