<div class="tab-titles">
  <div class="tab-title">
    <div class="tab-title-content" [class.active]="currentTab === EUsageTabTitleChartTypes.Audit" (click)="changeTab(EUsageTabTitleChartTypes.Audit)">
      <div class="alert-bell-container non-printable">
        <div class="alert-bell-relative-container">
          <op-widget-bell *ngIf="(isVisitorMode$ | async) === false"
                          [widgetName]="usageAlertMetric.AUDIT_12_MONTH"
                          [widgetIsHovered]="true"
                          [disableHighlightFromClick]="true"
                          [user]="user"
                          [filterType]="EAlertFilterType.V2"
                          [filters]="filters"
                          [menuContext]="EAlertMenuContext.Usage"
          ></op-widget-bell>
        </div>
      </div>
      <div class="title-container">
        <mat-icon>explore</mat-icon> Audit Subscription
      </div>
      <div class="additional-info">
        <p class="dates"
           *ngIf="auditUsage"
           [matMenuTriggerFor]="auditTermMenu"
           (click)="$event.stopImmediatePropagation()">
          {{auditUsage.currentContractTerm.startDateInclusive | straightDate}} to {{auditUsage.currentContractTerm.endDateInclusive | straightDate}} <mat-icon>expand_more</mat-icon>
        </p>
        <p class="scanned-pages">Total # of Pages Scanned</p>
        <div class="graph-placeholder">
          <ng-content select="usage-tab-title-chart.audits"></ng-content>
        </div>
      </div>
    </div>

    <mat-menu #auditTermMenu="matMenu"
              backdropClass="no-filter">
      <div class="popover-dates-container">
        <h4>SUBSCRIPTION</h4>
        <mat-radio-group color="primary" *ngIf="auditUsage">
          <mat-radio-button [value]="1"
                            [checked]="term.sequence === auditUsage.currentContractTerm.sequence" *ngFor="let term of auditUsage.contractTerms"
                            (click)="onAuditTermSelected(term)">
            <span class="option">{{term.startDateInclusive | straightDate}} <span class="thin">to</span> {{term.endDateInclusive | straightDate}}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-menu>
  </div>

  <div class="tab-title non-printable">
    <div class="tab-title-content" [class.active]="currentTab === EUsageTabTitleChartTypes.WebJourney" (click)="changeTab(EUsageTabTitleChartTypes.WebJourney)">
      <div class="alert-bell-container non-printable">
        <div class="alert-bell-relative-container">
          <op-widget-bell *ngIf="(isVisitorMode$ | async) === false && isWebJourneysAllowed"
                          [widgetName]="usageAlertMetric.WEB_JOURNEY_12_MONTH"
                          [widgetIsHovered]="true"
                          [disableHighlightFromClick]="true"
                          [user]="user"
                          [filterType]="EAlertFilterType.V2"
                          [filters]="filters"
                          [menuContext]="EAlertMenuContext.Usage"
          ></op-widget-bell>
        </div>
      </div>
      <div class="title-container">
        <mat-icon>map</mat-icon> Web Journey Subscription
      </div>
      <div class="additional-info">
        <div *ngIf="isWebJourneysAllowed">
          <p class="dates"
              *ngIf="webJourneyUsage"
             [matMenuTriggerFor]="journeyTermMenu"
             (click)="$event.stopImmediatePropagation()">
            {{webJourneyUsage.currentContractTerm.startDateInclusive | straightDate}} to {{webJourneyUsage.currentContractTerm.endDateInclusive | straightDate}} <mat-icon>expand_more</mat-icon>
          </p>
          <p class="scanned-pages">Total # of Journeys Runs </p>
          <div class="graph-placeholder">
            <ng-content select="usage-tab-title-chart.webjourneys:not(.to-print)"></ng-content>
          </div>
        </div>
        <div class="no-audits-allowed" *ngIf="!isWebJourneysAllowed">
          <p>
            Web Journeys aren't included in your contract.
          </p>
          <p>
            <a href="https://www.observepoint.com/feature-requests/journeys/" target="_blank">Learn more</a> about why you should be using this feature.
          </p>
        </div>
      </div>
    </div>

    <mat-menu #journeyTermMenu="matMenu"
              backdropClass="no-filter">
      <div class="popover-dates-container">
        <h4>SUBSCRIPTION</h4>
        <mat-radio-group color="primary" *ngIf="webJourneyUsage">
          <mat-radio-button [value]="1"
                            [checked]="term.sequence === webJourneyUsage.currentContractTerm.sequence" *ngFor="let term of webJourneyUsage.contractTerms"
                            (click)="onWebJourneyTermSelected(term)">
            <span class="option">{{term.startDateInclusive | straightDate}} <span class="thin">to</span> {{term.endDateInclusive | straightDate}}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </mat-menu>
  </div>

</div>
<div class="tab-contents active-{{currentTab}}">
  <div class="decoration inner-border-1of4">
    <div class="border"></div>
    <div class="outer-border-overlay outer-border-overlay-1"></div>
    <div class="outer-border-overlay outer-border-overlay-2"></div>
  </div>
  <div class="decoration side-border">
    <div class="line"></div>
  </div>
  <div class="tab-content" [class.active]="currentTab === EUsageTabTitleChartTypes.Audit">
    <ng-content select=".tab-audits"></ng-content>
  </div>
  <div class="tab-title printable">
    <div class="tab-title-content" [class.active]="currentTab === EUsageTabTitleChartTypes.WebJourney" [class.disabled]="!isWebJourneysAllowed" (click)="changeTab(EUsageTabTitleChartTypes.WebJourney)">
      <div class="alert-bell-container non-printable">
        <div class="alert-bell-relative-container">
          <op-widget-bell *ngIf="(isVisitorMode$ | async) === false"
                          [widgetName]="usageAlertMetric.WEB_JOURNEY_12_MONTH"
                          [widgetIsHovered]="true"
                          [disableHighlightFromClick]="true"
                          [user]="user"
                          [filterType]="EAlertFilterType.V2"
                          [filters]="filters"
                          [menuContext]="EAlertMenuContext.Usage"
          ></op-widget-bell>
        </div>
      </div>
      <div class="title-container">
        <mat-icon>map</mat-icon> Web Journey Subscription
      </div>
      <div class="additional-info">
        <div *ngIf="isWebJourneysAllowed">
          <p class="dates"
             *ngIf="webJourneyUsage"
             (click)="$event.stopImmediatePropagation()">
            {{webJourneyUsage.currentContractTerm.startDateInclusive | straightDate}} to {{webJourneyUsage.currentContractTerm.endDateInclusive | straightDate}} <mat-icon>expand_more</mat-icon>
          </p>
          <p class="scanned-pages">Total # of Journeys Runs </p>
          <div class="graph-placeholder">
            <ng-content select="usage-tab-title-chart.webjourneys.to-print"></ng-content>
          </div>
        </div>
        <div class="no-audits-allowed" *ngIf="!isWebJourneysAllowed">
          <p>
            Web Journeys aren't included in your contract.
          </p>
          <p>
            <a href="https://www.observepoint.com/feature-requests/journeys/" target="_blank">Learn more</a> about why you should be using this feature.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-content" [class.active]="currentTab === EUsageTabTitleChartTypes.WebJourney">
    <ng-content select=".tab-webjourneys"></ng-content>
  </div>
</div>
