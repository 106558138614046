import {
  TagLoadTimeDistribution,
  TagStatusCodeDistribution
} from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import { IHorizontalBarChartDataPoint } from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.models';
import {
  ETagLoadTimePills,
  TagHealthChartType
} from '@app/components/audit-reports/reports/tag-health/tag-health.constants';
import {
  EAuditReportFilterTypes,
  IAuditReportFilter
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { EChartColor } from '@app/components/audit-reports/audit-report/audit-report.constants';

export const getTagLoadTimeChartData = (data: TagLoadTimeDistribution = undefined): IHorizontalBarChartDataPoint[] => {
  return [
    {
      value: data ? data['below500'] : 0,
      colorClass: EChartColor.Green,
      name: ETagLoadTimePills['Below500'],
      filtered: false
    },
    {
      value: data ? data['500to1000'] : 0,
      colorClass: EChartColor.Yellow,
      name: ETagLoadTimePills['500to1000'],
      filtered: false
    },
    {
      value: data ? data['1000to2000'] : 0,
      colorClass: EChartColor.Orange,
      name: ETagLoadTimePills['1000to2000'],
      filtered: false
    },
    {
      value: data ? data['above2000'] : 0,
      colorClass: EChartColor.Red,
      name: ETagLoadTimePills['2000andAbove'],
      filtered: false
    }
  ];
}

export const getTagStatusCodeChartData = (data: TagStatusCodeDistribution = undefined): IHorizontalBarChartDataPoint[] => {
  return [
    {
      value: data ? data.good : 0,
      colorClass: EChartColor.Green,
      name: 'Successes',
      filtered: false
    },
    {
      value: data ? data.redirect : 0,
      colorClass: EChartColor.Yellow,
      name: 'Redirects',
      filtered: false
    },
    {
      value: data ? data.broken : 0,
      colorClass: EChartColor.Red,
      name: 'Broken',
      filtered: false
    }
  ];
}

export const determineChartFilterState = (chartType: TagHealthChartType, currentFilters: IAuditReportFilter[]): EFilterSpinnerState => {
  if (chartType === TagHealthChartType.LoadTime) {
    const isFiltered = !!currentFilters.find((filter: IAuditReportFilter) => filter.type === EAuditReportFilterTypes.TagLoadTime);

    if (isFiltered && currentFilters.length === 0) {
      return EFilterSpinnerState.None;
    } else if (isFiltered && currentFilters.length > 1) {
      return EFilterSpinnerState.Filtered;
    } else if (!isFiltered && currentFilters.length > 0) {
      return EFilterSpinnerState.Filtered;
    } else {
      return EFilterSpinnerState.None;
    }
  }

  if (chartType === TagHealthChartType.StatusCode) {
    const isFiltered = !!currentFilters.find((filter: IAuditReportFilter) => filter.type === EAuditReportFilterTypes.TagStatusCode);

    if (isFiltered && currentFilters.length === 0) {
      return EFilterSpinnerState.None;
    } else if (isFiltered && currentFilters.length > 1) {
      return EFilterSpinnerState.Filtered;
    } else if (!isFiltered && currentFilters.length > 0) {
      return EFilterSpinnerState.Filtered;
    } else {
      return EFilterSpinnerState.None;
    }
  }
};
