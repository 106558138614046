<div class="flex-col flex-stretch">
  <mat-tab-group disableRipple [mat-stretch-tabs]="false">
    <mat-tab label="Running ({{webJourneysRunning?.length}})">
      <!-- Running -->
      <div class="system-status-zero-state" *ngIf="webJourneysRunning?.length === 0">
        No journeys running
      </div>
      <div class="flex-col flex-stretch system-status-tab-content-wrapper"
           [class.hide-element]="webJourneysRunning?.length === 0">
        <div class="control-panel">
          <div class="table-filter-input">
            <op-clearable-input placeholderText="Enter a value to search by..."
                                [emitKeyboardEvent]="true"
                                appearance="OUTLINED"
                                (onUserInput)="debounceRunningFilterData($event)"></op-clearable-input>
            <div class="show-columns-container">
            </div>
            <mat-form-field class="icon-dropdown-wrapper no-underline no-select-arrow">
              <mat-label class="icon-dropdown-label"><mat-icon style="font-size: 30px;" class="dropdown-icon">visibility</mat-icon></mat-label>
              <mat-select #selector class="hide-column-trigger" [panelClass]="'hide-column-panel'">
                <mat-option (click)="toggleAllColumns('running', true); selector.open();">Show all</mat-option>
                <mat-option (click)="toggleAllColumns('running', false); selector.open();">Hide all</mat-option>
                <mat-option *ngFor="let col of runningColumns"
                            (click)="col.show = !col.show; prepareRunningCols(); selector.open();">
                  <mat-icon>{{col.show ? 'visibility' : 'visibility_off'}}</mat-icon>&nbsp;{{col.header}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="action-button-group" *ngIf="userIsOpSysAdmin">
            <button mat-stroked-button
                    color="primary"
                    class="action-button"
                    [class.disabled]="selection.selected.length === 0"
                    [disabled]="selection.selected.length === 0"
                    (click)="restartWebJourneys()">Restart</button>
            <button mat-stroked-button
                    color="primary"
                    class="action-button"
                    [class.disabled]="selection.selected.length === 0"
                    [disabled]="selection.selected.length === 0"
                    (click)="removeWebJourneysRuns()">Remove Runs</button>
          </div>
        </div>
        <div class="system-status-table-wrapper">
          <table mat-table
                 matSort
                 #runningTable
                 [dataSource]="runningDataSource"
                 multiTemplateDataRows

                 [class.hide-element]="runningDisplayedColumns.length === 0"
          >
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <div class="no-sort-column-header-content-wrapper">
                  <mat-checkbox (click)="masterToggle(); $event.stopPropagation();"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                color="primary">
                  </mat-checkbox>
                </div>
              </th>
              <td mat-cell *matCellDef="let row" valign="middle">
                <mat-checkbox (click)="onCheckboxChecked($event, row)"
                              [checked]="selection.isSelected(row)"
                              color="primary">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  Run ID
                </div>
              </th>
              <td mat-cell
                  *matCellDef="let row"
                  valign="middle"
                  ngxClipboard
                  [cbContent]="row.id"
                  (click)="showSnackbar()"
                  [matTooltip]="'Click to copy to clipboard'"
                  matTooltipPosition="above"
                  class="copy-message">
              <span>
                {{row.id}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="simulationId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  Journey Id
                </div>
              </th>
              <td mat-cell
                  *matCellDef="let row"
                  valign="middle"
                  ngxClipboard
                  [cbContent]="row.simulationId"
                  (click)="showSnackbar()"
                  [matTooltip]="'Click to copy to clipboard'"
                  matTooltipPosition="above"
                  class="copy-message"
              >
              <span>
                {{row.simulationId}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="accountId">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  Account Id
                </div>
              </th>
              <td mat-cell
                  *matCellDef="let row"
                  valign="middle"
                  ngxClipboard
                  [cbContent]="row.accountId"
                  (click)="showSnackbar()"
                  [matTooltip]="'Click to copy to clipboard'"
                  matTooltipPosition="above"
                  class="copy-message">
              <span>
                {{row.accountId}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="company">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  Account
                </div>
              </th>
              <td mat-cell *matCellDef="let row" valign="middle" (click)="goToAccountHandler(row)">
              <span class="is-clickable">
                {{row.company}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  Journey Name
                </div>
              </th>
              <td mat-cell *matCellDef="let row" valign="middle" (click)="goToWebJourneyRunHandler(row)">
              <span class="is-clickable">
                {{row.name}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="frequency">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  Frequency
                </div>
              </th>
              <td mat-cell
                  *matCellDef="let row"
                  valign="middle">
              <span>
                {{row.frequency}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="userAgent">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  User Agent
                </div>
              </th>
              <td mat-cell
                  *matCellDef="let row"
                  valign="middle">
              <span>
                {{row.userAgent}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  Started At
                </div>
              </th>
              <td mat-cell
                  *matCellDef="let row"
                  valign="middle">
              <span>
                {{row.createdAt}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  Actions
                </div>
              </th>
              <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.actions}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="waitTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="mat-sort-header-content">
                  Total Wait Time
                </div>
              </th>
              <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.waitTime}}
              </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <div class="column-header-content-wrapper">
                  Location
                </div>
              </th>
              <td mat-cell *matCellDef="let row" valign="middle">
                <ng-container>
                  <span class="flag-icon flag-icon-{{ row?.location?.countryCode }}"></span>
                  <span class="location-label">{{ row?.location?.label }}</span>
                </ng-container>
              </td>
            </ng-container>
            <tr mat-header-row
                class="system-status-table-row"
                *matHeaderRowDef="runningDisplayedColumns; sticky: true;">
            </tr>
            <tr mat-row *matRowDef="let row; columns: runningDisplayedColumns;">
            </tr>
          </table>
        </div>
        <div *ngIf="loadingRunning" class="spinner-wrapper">
          <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Queued ({{webJourneysInQueue?.length}})">
      <!-- In Queue -->
      <div class="system-status-zero-state" *ngIf="webJourneysInQueue?.length === 0">
        No journeys queued
      </div>
      <div class="flex-col flex-stretch"
           [class.hide-element]="webJourneysInQueue?.length === 0">
        <div class="control-panel">
          <op-clearable-input placeholderText="Enter a value to search by..."
                              [emitKeyboardEvent]="true"
                              appearance="OUTLINED"
                              class="table-filter-input"
                              (onUserInput)="debounceQueuedFilterData($event)"></op-clearable-input>

          <mat-form-field class="icon-dropdown-wrapper no-underline no-select-arrow">
            <mat-label class="icon-dropdown-label"><mat-icon style="font-size: 30px;" class="dropdown-icon">visibility</mat-icon></mat-label>
            <mat-select #queuedSelector class="hide-column-trigger" [panelClass]="'hide-column-panel'">
              <mat-option (click)="toggleAllColumns('queued', true); queuedSelector.open();">Show all</mat-option>
              <mat-option (click)="toggleAllColumns('queued', false); queuedSelector.open();">Hide all</mat-option>
              <mat-option *ngFor="let col of queuedColumns"
                          (click)="col.show = !col.show; prepareQueuedCols(); queuedSelector.open();">
                <mat-icon>{{col.show ? 'visibility' : 'visibility_off'}}</mat-icon>&nbsp;{{col.header}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="system-status-table-wrapper">
          <table mat-table
               matSort
               #queuedTable
               [dataSource]="queuedDataSource"
               multiTemplateDataRows
               class="system-status-table"
        >
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="mat-sort-header-content">
                Journey ID
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                ngxClipboard
                [cbContent]="row.id"
                (click)="showSnackbar()"
                [matTooltip]="'Click to copy to clipboard'"
                matTooltipPosition="above"
                class="copy-message">
              <span>
                {{row.id}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="accountId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="mat-sort-header-content">
                Account ID
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                ngxClipboard
                [cbContent]="row.accountId"
                (click)="showSnackbar()"
                [matTooltip]="'Click to copy to clipboard'"
                matTooltipPosition="above"
                class="copy-message">
              <span>
                {{row.accountId}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="mat-sort-header-content">
                Account
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle" (click)="goToAccountHandler(row)">
              <span class="is-clickable">
                {{row.company}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="mat-sort-header-content">
                Journey Name
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle" (click)="goToWebJourneyRunHandler(row)">
              <span class="is-clickable">
                {{row.name}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="frequency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="mat-sort-header-content">
                Frequency
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.frequency}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="userAgent">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="mat-sort-header-content">
                User Agent
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.userAgent}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="mat-sort-header-content">
                Actions
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.actions}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="waitTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="mat-sort-header-content">
                Total Wait Time
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.waitTime}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Location
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <ng-container>
                <span class="flag-icon flag-icon-{{ row?.location?.countryCode }}"></span>
                <span class="location-label">{{ row?.location?.label }}</span>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row
              class="system-status-table-row"
              *matHeaderRowDef="queuedDisplayedColumns; sticky: true;">
          </tr>
          <tr mat-row *matRowDef="let row; columns: queuedDisplayedColumns;">
          </tr>
        </table>
        </div>
        <div *ngIf="loadingRunning" class="spinner-wrapper">
          <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
