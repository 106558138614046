import { Pipe, PipeTransform } from '@angular/core';
import { IFailedRuleTagReport } from '@app/components/reporting/rules/rule.models';

@Pipe({
  name: 'tagMissed'
})
export class TagMissedPipe implements PipeTransform {
  transform(items: IFailedRuleTagReport[], isMissed: boolean): IFailedRuleTagReport[] {
    if (!items || !Array.isArray(items)){
      return items;
    }

    return items.filter(item => !!item.missed === isMissed);
  }
}
