import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IOpTab } from './op-tabs.models';
import { Router } from '@angular/router';

@Component({
  selector: 'op-tabs',
  templateUrl: './op-tabs.component.html',
  styleUrls: ['./op-tabs.component.scss']
})
export class OpTabsComponent {

  @Input() tabs: IOpTab[] = [];
  @Input() useRouting: boolean = false;
  @Input() isSubMenu: boolean = false;
  @Input() activeTab: string;
  @Output() tabClicked: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) { }

  changeTab(path: string): void {
    this.useRouting
      ? this.router.navigateByUrl(path)
      : this.tabClicked.emit(path);
  }

  tabIsActive(path: string): boolean {
    return this.useRouting
      ? this.router.isActive(path, true)
      : path === this.activeTab;
  }
}
