<div class="op-run-time">
  <mat-form-field floatLabel="always" appearance="outline">
    <mat-label>Initial Run Time</mat-label>
    <input matInput type="time" [formControl]="time">
    <button class="timezone-btn"
            [matTooltip]="selectedTimeZoneTooltip"
            matTooltipClass="multi-line-tooltip"
            matTooltipPosition="above"
            matTooltipShowDelay="250"
            [matMenuTriggerFor]="timeZoneMenu"
            (click)="$event.stopPropagation()">
      {{selectedTimeZone?.abbr}}
    </button>
    <button matSuffix mat-icon-button [matMenuTriggerFor]="timeZoneMenu" (click)="$event.stopPropagation()">
      <mat-icon>public</mat-icon>
    </button>
    <mat-menu #timeZoneMenu="matMenu" class="tz-group-menu">
      <div class="menu-input-wrapper" (click)="$event.stopPropagation(); $event.preventDefault();">
        <mat-form-field appearance="outline">
          <mat-icon class="search-icon">search</mat-icon>
          <input matInput
                 #timeZoneFilter
                  placeholder="Search time zones"
                 [formControl]="timeZoneFilterControl" />
        </mat-form-field>
      </div>

      <ng-container *ngFor="let tzGroup of filteredTimeZones; let index = index;">
        <div mat-menu-item class="offset-button pointer-events-none">{{tzGroup.offset}}</div>
        <button mat-menu-item
                *ngFor="let tz of tzGroup.zonesInOffset"
                [class.selected-tz]="timeZone.value === tz.name"
                (click)="setTimeZone(tz)"
        ><span class="tz-group-button">{{tz.name}} ({{tz.longName}}) <mat-icon>check</mat-icon></span></button>
        <mat-divider class="divider"></mat-divider>
      </ng-container>
    </mat-menu>
    <mat-error *ngIf="time.errors?.required">Time value is required.</mat-error>
  </mat-form-field>
</div>
