import { AngularNames } from '@app/moonbeamConstants';
import * as angular from 'angular';

export interface IModalHelperService {
    closeModal($scope: angular.IScope): void;
    closeShadeModal($scope: angular.IScope, createdItem?: any, shadeWindowCtrl?: string): void
    closeModalOrShadeWizardWindow($scope: angular.IScope, payload?: any, shadeWindowCtrl?: string): void
  }

  export class ModalHelperService implements IModalHelperService {
    static $inject = [
      AngularNames.timeout
    ];

    constructor(private $timeout: angular.ITimeoutService) {
    }

    private getParentScope($scope: angular.IScope): any {
      const parentScope = $scope.$parent;
      if (!parentScope) {
        throw new Error('parentScope does not exist');
      }
      return parentScope;
    }

    closeModal($scope: angular.IScope): void {
      const parentScope = this.getParentScope($scope) as mgcrea.ngStrap.modal.IModalScope;
      this.$timeout(() => {
        if (parentScope.$hide) {
          parentScope.$hide();
        }
      });
    }

    closeShadeModal($scope: angular.IScope, createdItem: any, shadeWindowCtrl = 'directiveCtrl'): void {
      this.$timeout(() => {
        const parentScope = this.getParentScope($scope);
        if (parentScope[shadeWindowCtrl]) {
          parentScope[shadeWindowCtrl].close(createdItem);
        }
      });
    }

    closeModalOrShadeWizardWindow($scope: angular.IScope, payload: any, shadeWindowCtrl = 'shadeCtrl'): void {
      this.$timeout(() => {
        const parentScope = this.getParentScope($scope);
        if (parentScope.$hide) {
          this.closeModal($scope);
        } else if (parentScope[shadeWindowCtrl]) {
          parentScope[shadeWindowCtrl].close(payload);
        } else {
          throw new Error('can\'t close this window');
        }
      });
    }
}
