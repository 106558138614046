import * as Immutable from 'immutable';

export const firstOrGiven = (list: Array<any>, given) => {
  return list && list[0] ? list[0] : given;
};

export const toJSOrGivenIfEmpty = (list: Immutable.Iterable<any, any>, given) => {
  return list.size == 0 ? given : list.toJS();
};

export const givenOrList = (given = Immutable.List<Immutable.Map<string, any>>()) => {
  return given;
};

export const toJSOrGiven = <T>(list: Immutable.Iterable<any, any>, given): T => {
  return list ? list.toJS() : given;
};

export const filterOrGiven = (list: Immutable.Iterable<any, any>, callback: (item: any) => boolean, given: any) => {
  return list ? list.filter(callback) : given;
};

export const mapOrGiven = <T>(list, callback: (item: any) => T, given): Array<T> => {
  return list ? list.map(callback) : given;
};

export const concatLists = (a: Array<any>, b: Array<any>) => {
  if (a && b) {
    return a.concat(b);
  }

  if (!a && b) {
    return b;
  }

  if (a && !b) {
    return a;
  }

  if (!a && !b) {
    return undefined;
  }
};

export const checkForKey = (map: Immutable.Map<any, any>, key: string, func) => {
  if (!map.get(key)) {
    return map;
  }

  return func();
};
