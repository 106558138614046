import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-sort-indicator',
  templateUrl: './cl-sort-indicator.component.html',
  styleUrls: ['./cl-sort-indicator.component.scss']
})
export class ComponentLibrarySortIndicatorComponent {
}
