import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class VizUtilsService {
  static formatChartNumbers(value: number): string {
    // 1,000,000,000 or greater
    if (value > 999_999_999) {
      const firstTwoNumbers = String(value).substring(0, 2).split('');
      // format as decimal (1.2B) if second number is not zero
      // format as whole number (1B) if second number is a zero
      return (firstTwoNumbers[1] !== '0')
        ? `${firstTwoNumbers.join('.')}B`
        : `${firstTwoNumbers[0]}B`;
    }

    // 100,000,000 or greater
    if (value > 99_999_999) {
      return `${String(value).substring(0, 3)}M`;
    }

    // 10,000,000 or greater
    if (value > 9_999_999) {
      const firstThreeNumbers = String(value).substring(0, 3).split('');
      // format as decimal (10.2M) if third number is not zero
      // format as whole number (10M) if third number is a zero
      return (firstThreeNumbers[2] !== '0')
        ? `${firstThreeNumbers[0]}${firstThreeNumbers[1]}.${firstThreeNumbers[2]}M`
        : `${firstThreeNumbers[0]}${firstThreeNumbers[1]}M`;
    }

    // 1,000,000 or greater
    if (value > 999_999) {
      const firstTwoNumbers = String(value).substring(0, 2).split('');
      // format as decimal (1.2M) if second number is not zero
      // format as whole number (1M) if second number is a zero
      return (firstTwoNumbers[1] !== '0')
        ? `${firstTwoNumbers.join('.')}M`
        : `${firstTwoNumbers[0]}M`;
    }

    // 100,000 or greater
    if (value > 99_999) {
      return `${String(value).substring(0, 3)}K`;
    }

    // 10,000 or greater
    if (value > 9_999) {
      const firstThreeNumbers = String(value).substring(0, 3).split('');
      // format as decimal (10.2K) if third number is not zero
      // format as whole number (10K) if third number is a zero
      return (firstThreeNumbers[2] !== '0')
        ? `${firstThreeNumbers[0]}${firstThreeNumbers[1]}.${firstThreeNumbers[2]}K`
        : `${firstThreeNumbers[0]}${firstThreeNumbers[1]}K`;
    }

    // 1,000 or greater
    if (value > 999) {
      const firstTwoNumbers = String(value).substring(0, 2).split('');
      // format as decimal (1.2K) if second number is not zero
      // format as whole number (1K) if second number is a zero
      return (firstTwoNumbers[1] !== '0')
        ? `${firstTwoNumbers.join('.')}K`
        : `${firstTwoNumbers[0]}K`;
    }

    // anything lower than 1,000
    return String(value);
  }
}
