export enum EFilterBarMenuTypes {
  Flyout = 'flyout',
  Button = 'button',
  Search = 'search',
  Divider = 'divider',
  ClearBtn = 'clearBtn'
}

export enum ETextInputCheckboxFilterType {
  Contains = 'contains',
  Regex = 'regex'
}