import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { IUser } from '@app/moonbeamModels';
import { TagsService } from '@app/components/tags/tagsService';
import { Router } from '@angular/router';
import { AccountsService } from '@app/components/account/account.service';
import { userIsOPAdmin } from '@app/authUtils';
import { AdminPortalUrlBuilders } from '@app/components/admin-portal/admin-portal.constants';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-database-library',
  templateUrl: './tag-database-library.component.html',
  styleUrls: ['./tag-database-library.component.scss'],
})

export class TagDatabaseLibraryComponent implements AfterViewInit {
  loading: boolean = true;
  tableItems: any[] = [];
  tagsCount: number;
  currentUser: IUser;
  isOpAdmin: boolean = false;
  dataSource = new MatTableDataSource;
  displayedColumns = ['id', 'name', 'numSignatures', 'category', 'numVariables'];

  tagFilter: string = '';
  filterChanged$ = new Subject<string>();

  private destroy$: Subject<void> = new Subject();

  @ViewChild(MatSort) sort: MatSort;

  constructor(private tagsService: TagsService,
              private router: Router,
              private accountsService: AccountsService) {

    this.filterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.tagFilter = filter;
      this.dataSource.filter = this.tagFilter;
    });

    this.accountsService.getUser().subscribe(user => {
      this.currentUser = user;
      this.isOpAdmin = userIsOPAdmin(user);
    });

    this.tagsService.getAllTags().then(tags => {
      this.tagsCount = tags.length;
      this.dataSource.data = tags.map(tag => {
        return {
          id: String(tag.id),
          name: tag.name,
          numSignatures: String(tag.numSignatures || 0),
          category: tag.category.name,
          numVariables: String(tag.numVariables || 0),
          icon: UiTagService.getTagIconUrl(tag.id)
        };
      });
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, attribute) => {
      switch (attribute) {
        case 'id':
        case 'numSignatures':
        case 'numVariables':
          return +data[attribute];
          break;
        default:
          return data[attribute];
      }
    };
  }

  debounceFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.filterChanged$.next(filter);
  }

  viewTag(item: any): void {
    if (userIsOPAdmin(this.currentUser)) {
      this.router.navigateByUrl(AdminPortalUrlBuilders.tagDatabaseViewTag(item.id));
    }
  }
}
