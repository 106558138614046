<op-modal [leftFooterButtons]="[]"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header
      [title]="'SHARE '+ data.title + ' EXPORT'"
      (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="body-container">
      <div class="input-container">
        <mat-form-field appearance="outline">
          <mat-label>Enter email addresses</mat-label>
          <mat-chip-grid #chipList>
            <mat-chip-row *ngFor="let email of emails" (removed)="remove(email)">
              {{email}}
              <button matChipRemove>
                <mat-icon>close</mat-icon>
              </button>
            </mat-chip-row>
            <input [matChipInputFor]="chipList"
                   autocomplete="false"
                   [(ngModel)]="emailsInput"
                   (keyup)="onKeyUp($event)"
                   (blur)="onBlur($event)"
                   (paste)="onPaste($event)"
            >
          </mat-chip-grid>
          <mat-icon matSuffix
                    *ngIf="emails.length"
                    matTooltip="Clear all emails"
                    matTooltipPosition="above"
                    matTooltipShowDelay="300"
                    (click)="removeAll()">close</mat-icon>
        </mat-form-field>
        <mat-error *ngIf="redEmailsHint">{{redEmailsHint}}</mat-error>
        <mat-hint *ngIf="!redEmailsHint">Enter up to 10 email addresses, separate each with a comma.</mat-hint>
        <mat-hint class="counter" [class.red]="emails.length > MAX_EMAILS_LENGTH">{{emails.length}}/{{MAX_EMAILS_LENGTH}}</mat-hint>
      </div>
      <p class="description"></p>
      <div class="input-container">
        <mat-form-field appearance="outline">
          <mat-label>Enter email message</mat-label>
          <textarea matInput [(ngModel)]="message" rows="5" (input)="validateMessage()" (keydown)="redMessagesHint = ''"></textarea>
        </mat-form-field>
        <mat-hint class="counter" [class.red]="message.length > MAX_MESSAGE_LENGTH">{{message.length}}/{{MAX_MESSAGE_LENGTH}}</mat-hint>
        <mat-error *ngIf="redMessagesHint">{{redMessagesHint}}</mat-error>
      </div>

    </div>
  </ng-template>
</op-modal>
