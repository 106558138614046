<div class="flex-col flex-stretch inside-table-wrap">
  <div *ngIf="loading" class="inside-table-loader">
    <mat-spinner [diameter]="40" [strokeWidth]="3"></mat-spinner>
  </div>
  <div *ngIf="!loading && !runs?.length" class="no-items">
    No history for this Comparison yet
  </div>
  <table *ngIf="!loading && runs?.length" class="inside-table item-details mat-table">
    <thead>
    <tr class="mat-header-row">
      <th class="mat-header-cell">History:</th>
      <th class="mat-header-cell">Time:</th>
      <th class="mat-header-cell">Status:</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of runs" class="mat-row">
      <td class="mat-cell">
        <a class="hover-link" (click)="openRun(item)">
          Applied to {{ item.itemName }}
        </a>
      </td>
      <td class="mat-cell">{{ item.createdAt | date: dateFormat }}</td>
      <td class="mat-cell" [ngSwitch]="item.status">
        <mat-icon *ngSwitchCase="statuses.inProgress" class="in-progress">sync</mat-icon>
        <mat-icon *ngSwitchCase="statuses.completed">check</mat-icon>
        <mat-icon *ngSwitchCase="statuses.failed" class="has-error">error_outline</mat-icon>
      </td>
    </tr>
    </tbody>
  </table>
</div>
