import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TagStatusCodeOperators, TagStatusCodeValues } from './rule-tag-status-code-filter.constants';
import {
  ITagStatusCodeOperator
} from '@app/components/rules/rule-setup/tabs/conditions-tab/shared/rule-tag-status-code-filter/rule-tag-status-code-filter.models';
import { ERuleTagStatusCodeValueType } from '@app/components/rules/rules.models';

@Component({
  selector: 'op-rule-tag-status-code-filter',
  templateUrl: './rule-tag-status-code-filter.component.html',
  styleUrls: ['./rule-tag-status-code-filter.component.scss']
})
export class RuleTagStatusCodeFilterComponent {

  @Input() formData: UntypedFormGroup;
  @Output() onRemoveStatusCode = new EventEmitter<void>();

  readonly TagStatusCodeOperators = TagStatusCodeOperators;
  readonly TagStatusCodeValues = TagStatusCodeValues;

  get matchTypeControl(): UntypedFormControl {
    return this.formData?.get('matchType') as UntypedFormControl;
  }

  get valueControl(): UntypedFormControl {
    return this.formData?.get('value') as UntypedFormControl;
  }

  displayStatusCodeFn(statusCode: ITagStatusCodeOperator): string {
    return statusCode?.name || '';
  }

  setValue(value: string): void {
    this.valueControl.setValue({ value, name: value, valueType: ERuleTagStatusCodeValueType.Specific });
  }
}
