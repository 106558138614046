import { IRuleV3WebJourneyRules } from '@app/components/rules/rules.models';
import { WebJourneyReportStateNames } from '@app/components/web-journey-report/web-journey-report.constants';
import { IHistoryFrame, RouteHistoryService } from '@app/components/shared/services/route-history.service';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { WebJourneyV3RulesService } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3-rules.service';
import { IWebJourneyAPIAction, IWebJourneyResults } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, from, throwError } from 'rxjs';
import { shareReplay, catchError } from 'rxjs/operators';
import { IWebJourneyApiService } from '../domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { IWebJourneyRun } from '../domains/webJourneys/webJourneyDefinitions';
import { IActionTagPresenceDiffs } from './web-journey-results/tag-comparison/wj-results-tag-comparison.models';
import { ComparisonError } from './web-journey-results/web-journey-results.constants';
import { ApiService } from '../core/services/api.service';
import { environment } from '@app/environments/environment';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IJourneyStatus } from './web-journey-results/web-journey-results.models';
import { IActionSelectorAction } from '../action-selector/action-selector.models';
import { EJourneyFailureType } from './web-journey-results/web-journey-results.enums';
import { IWebJourneyV3 } from '../domains/webJourneys/web-journey-v3-api/web-journey-v3.models';

export interface IWebJourneyInfoActionsData {
  snapshotActions: IWebJourneyAPIAction[];
  configuredActions: number;
}

/**
 * This service is used for caching web journey report details.
 * Data is reset on web-journey-report component detroying
 */
@Injectable()
export class WebJourneyReportService {

  private readonly comparisonUrl = environment.apiV3Url + 'comparisons/web-journeys';

  webJourneySubject = new ReplaySubject<IWebJourneyV3>(1);
  webJourney$ = this.webJourneySubject.asObservable();

  webJourneyConfiguredActionsSubject = new ReplaySubject<number>(1);
  webJourneyConfiguredActions$ = this.webJourneyConfiguredActionsSubject.asObservable();

  webJourneyJumpToActionSubject = new BehaviorSubject<number>(null);
  webJourneyJumpToAction$ = this.webJourneyJumpToActionSubject.asObservable();

  tagFilteringSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tagFiltering$: Observable<boolean> = this.tagFilteringSubject.asObservable();

  private tagCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  tagCount$: Observable<number> = this.tagCountSubject.asObservable();

  constructor(private apiService: ApiService,
    private routeHistoryService: RouteHistoryService,
    private webJourneyApiService: IWebJourneyApiService,
    private webJourneyRulesService: WebJourneyV3RulesService) { }

  @CacheApiResponse({ liveTime: 5000 })
  getJourneyResult(journeyId: number, runId: number): Observable<IWebJourneyResults> {
    return from(this.webJourneyApiService.getJourneyResult(journeyId, runId));
  }

  @CacheApiResponse({ liveTime: 5000 })
  getJourneyRules(journeyId: number): Observable<IRuleV3WebJourneyRules> {
    return this.webJourneyRulesService.getJourneyRules(journeyId).pipe(shareReplay(1));
  }

  @CacheApiResponse({ liveTime: 5000 })
  getJourneyRuns(journeyId: number): Observable<IWebJourneyRun[]> {
    return from(this.webJourneyApiService.getWebJourneyRuns(journeyId));
  }

  @CacheApiResponse({ liveTime: 5000 })
  getTagPresence(journeyId: number, runId: number): Observable<IActionTagPresenceDiffs[] | ComparisonError> {
    return this.apiService.get<IActionTagPresenceDiffs[]>(
      `${this.comparisonUrl}/${journeyId}/runs/${runId}/tag-account-presence`
    ).pipe(
      shareReplay(1),
      catchError(_ => throwError(new ComparisonError()))
    );
  }

  /**
   * Http Status:
   * 200 - returns an array of missing actions
   * 204 - report on no comparison run (first run or old run) - returns null
   * 500 - report on comparison error
   */
  @CacheApiResponse({ liveTime: 5000 })
  getActionsMissingFromBaseline(journeyId: number, runId: number): Observable<number[] | ComparisonError> {
    return this.apiService.get<number[]>(
      `${this.comparisonUrl}/${journeyId}/runs/${runId}/actions-missing-from-baseline`
    ).pipe(
      shareReplay(1),
      catchError(_ => throwError(new ComparisonError()))
    );
  }

  /**
   * If previous state was Web Journey Report or My Cards - navigates to My Cards with selected item = current web journey
   * Otherwise - navigates to the last state from history
   */
  closeReport(journeyId: number): void {
    const { extras, route } = DataSourcesUrlBuilders.selected('webJourney', journeyId);

    const shouldIgnore = (previousState: IHistoryFrame) => {
      const wasManageCards = previousState?.url.startsWith(DataSourcesUrlBuilders.sources());
      const wasReportPage = previousState?.stateName?.includes(WebJourneyReportStateNames.base);
      return wasManageCards || wasReportPage;
    };
    this.routeHistoryService.goToLastFromHistory(route, shouldIgnore, extras);
  }

  getJourneyStatus(results: IWebJourneyResults): IJourneyStatus {
    let failedActions = [];
    let failedRules = [];
    let journeyStatus: IJourneyStatus;

    if (!results || !results.actions || results.actions.length === 0) {
      journeyStatus = {
        failed: true,
        failureType: EJourneyFailureType.Action,
        failureStep: 1
      };
      return journeyStatus;
    }

    results.actions.forEach((action: IActionSelectorAction, index: number) => {
      if (!action?.success) failedActions.push(index);
      if (action.failedRuleReports?.length) failedRules.push(index);
    });

    if (failedActions.length) {
      journeyStatus = {
        failed: true,
        failureType: EJourneyFailureType.Action,
        failureStep: failedActions[0] + 1
      };
    } else if (failedRules.length) {
      journeyStatus = {
        failed: true,
        failureType: EJourneyFailureType.Rule,
        failureStep: failedRules[0] + 1
      };
    } else if (results.failedRuleReports?.length) {
      journeyStatus = {
        failed: true,
        failureType: EJourneyFailureType.Global
      };
    } else {
      journeyStatus = {
        failed: false
      };
    }

    return journeyStatus;
  }

  setTagCount(count: number) {
    this.tagCountSubject.next(count);
  }
}
