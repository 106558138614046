import { Component, Inject } from '@angular/core';
import { IOpSnackbar, IOpSnackbarButton } from './op-snackbar.models';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'op-snackbar',
  templateUrl: './op-snackbar.component.html',
  styleUrls: ['./op-snackbar.component.scss']
})
export class OpSnackbarComponent {

  icon: string;
  message: string; // allows for custom HTML
  closeBtnLabel: string;
  additionalButtons?: IOpSnackbarButton[];

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IOpSnackbar,
    private snackBarRef: MatSnackBarRef<OpSnackbarComponent>,
  ) {
    this.icon = data.icon;
    this.message = data.message;
    this.closeBtnLabel = data.closeBtnLabel;
    this.additionalButtons = data.additionalButtons;
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
