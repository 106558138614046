import { Component } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'op-email-inbox-not-created-snackbar',
  templateUrl: './email-inbox-not-created-snackbar.component.html',
  styleUrls: ['./email-inbox-not-created-snackbar.component.scss']
})
export class EmailInboxNotCreatedSnackbarComponent {
  constructor(
      private snackBarRef: MatSnackBarRef<EmailInboxNotCreatedSnackbarComponent>,
  ){}

  close(): void {
    this.snackBarRef.dismiss();
  }
}
