import { dateFromString } from '@app/components/date/date.service';
import { ISerializer } from '@app/components/api/apiService';
import {
  IAuditReportCard,
  IWebJourneyReportCard,
  IWebJourneyReportCardDTO,
  IAuditReportCardDTO,
} from '../cards/report-card-list/report-card-list.models';
import { CardTypes } from '../cards/report-card-list/report-card-list.constants';
import { PAUSED_YEAR } from '@app/components/utilities/dataSourcesUtils';

export type ReportStatus = 'running' | 'done' | 'scheduled' | 'real_paused' | 'paused' | 'failed' | 'rule_failed' | 'app_failed' | 'in_queue';

export const getAuditStatus = (audit: IAuditReportCardDTO) => {
  if (audit.webAuditRunning) {
    return 'running';
  } else if (new Date(audit.nextRun).getUTCFullYear()>=(+PAUSED_YEAR)) {
    return 'real_paused';
  } else if (audit.queued) {
    return 'scheduled';
  } else if (audit.lastRun) {
    return 'done';
  } else if (!audit.nextRun && !audit.queued) {
    return 'paused';
  } else if (audit.nextRun) {
    return 'scheduled';
  } else {
    return 'failed';
  }
};

export const getWebJourneyStatus = (webJourney: IWebJourneyReportCardDTO) => {
  if (webJourney.webJourneyRunning || webJourney.queued) {
    return 'running';
  } else if ((webJourney.lastCheck == 'Not run yet' && webJourney.nextCheck) || webJourney.status === 'NotChecked') {
    return 'scheduled';
  } else if (webJourney.lastCheck && webJourney.status === 'Ok') {
    return 'done';
  } else if (webJourney.lastCheck && webJourney.status == 'ActionFailure') {
    return 'failed';
  } else if (webJourney.lastCheck && webJourney.status == 'RulesFailure') {
    return 'rule_failed';
  } else if (!webJourney.nextCheck) {
    return 'paused';
  } else if (new Date(webJourney.nextCheck).getUTCFullYear()>=(+PAUSED_YEAR)) {
    return 'real_paused';
  }
};

const getDomainFromUrl = (url: string): string => {
  var a = jQuery('<a href="' + url + '"></a>');
  return (<any>a[0]).hostname;
};

export const serializeAuditReport = (json: Array<IAuditReportCardDTO>): Array<IAuditReportCard> => {
  if (!json || !json[0]) {
    return [];
  }

  return json.map(audit => {
    return <IAuditReportCard> {
      ...audit,
      type: CardTypes.audit,
      selected: false,
      cardStatus: getAuditStatus(audit),
      selectRunId: audit.lastRun ? audit.lastRun.id : 0,
      lastRunDate: audit?.lastRun?.completed ? dateFromString(audit.lastRun.completed) : null,
      frequency: audit.frequency
    };
  });
};

export const serializeWebJourneyReport = (json: IWebJourneyReportCardDTO[]): IWebJourneyReportCard[] => {
  if (!json || !json[0]) {
    return [];
  }
  return json.map((json: IWebJourneyReportCardDTO) => {
    const userAgent = json.options.userAgent.replace('(', '-').replace(')', '').split('-');
    return <IWebJourneyReportCard> {
      type: CardTypes.webJourney,
      selected: false,
      domain: getDomainFromUrl(json.domain),
      startingUrl: getDomainFromUrl(json.startingUrl),
      browser: userAgent[0],
      version: Math.floor(userAgent.length === 3 ? parseInt(userAgent[1]) : null),
      os: userAgent.length === 3 ? userAgent[2] : userAgent.length === 2 ? userAgent[1] : null,
      userAgentDescription: json.options.userAgentDescription,
      labels: json.labels,
      ownerId: json.userId,
      lastCheck: json.lastCheck ? dateFromString(json.lastCheck).toISOString() : 'Not run yet',
      lastRun: json.lastRun,
      nextCheck: json.nextCheck,
      lastRunDate: json.lastRun ? dateFromString(json.lastRun.completedAt) : null,
      cardStatus: getWebJourneyStatus(json),
      selectRunId: json.lastRun ? json.lastRun.id : 0,
      createdAt: json.createdAt,
      updatedAt: json.updatedAt,
      id: json.id,
      userId: json.userId,
      webJourneyRunning: json.webJourneyRunning,
      domainId: json.domainId,
      folderId: json.folderId,
      name: json.name,
      emails: json.emails,
      status: json.status,
      options: json.options,
      screenshot: json.screenshot,
      schedule: json.schedule
    };
  });
};

export const auditReportSerializer: ISerializer<IAuditReportCard[]> = {
  serialize: serializeAuditReport
};

export const webJourneyReportSerializer: ISerializer<IWebJourneyReportCard[]> = {
  serialize: serializeWebJourneyReport
};

