import {AngularNames, Names} from '@app/moonbeamConstants';
import {IModalHelperService} from '../../modals/modalHelperService';
import {IShadeWindowDataService} from '../shadeWindow/shadeWindowDataService';
import {ModalEscapeService} from './modalEscapeService';
import * as angular from 'angular';


interface IEscapeAttributes extends angular.IAttributes {
    animatedTrigger: string;
  }

  modalEscapeDirective.$inject = [
    Names.Services.modalHelper,
    Names.Services.shadeWindowData,
    AngularNames.timeout,
    Names.Services.modalEscape
  ];

  export function modalEscapeDirective(modalHelper: IModalHelperService,
    shadeWindowDataService: IShadeWindowDataService,
    $timeout: angular.ITimeoutService,
    modalEscapeService: ModalEscapeService) {
    return {
      scope: false,
      link: ($scope: angular.IScope, element: angular.IAugmentedJQuery, attr: IEscapeAttributes) => {
        const animatedTrigger = attr.animatedTrigger;
        $scope[animatedTrigger] = false;

        let index = modalEscapeService.getLast() + 1;

        const keyUpEvent = (e: KeyboardEvent) => {
          if (e.keyCode == 27 && modalEscapeService.getLast() == index ) {
            if (shadeWindowDataService.isShadeWindowOpen()) {
              $timeout(() => shadeWindowDataService.setShadeWindowIsClosed());
            } else {
              modalHelper.closeModal($scope);
              $scope.$eval(attr.action);
              cleanUp();
            }
          }
        };

        let cleanUp = () => {
          document.removeEventListener('keyup', keyUpEvent);
          modalEscapeService.remove(index);
        };

        modalEscapeService.add(index);
        document.addEventListener('keyup', keyUpEvent);
        $scope.$on('$destroy', () => {
          cleanUp();
        });
      }
    };
}

