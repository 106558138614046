import { AngularNames, Events, Names } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { IEventManager } from '../../eventManager/eventManager';
import * as ngRedux from 'ng-redux';
import { AuthenticationActions } from '../../../store/actions/authenticationActions';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { environment } from '@app/environments/environment';
import { Router } from '@angular/router';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { FeatureFlagHelper } from '@app/environments/feature-flag-helper';
import { FeatureFlags } from '@app/environments/feature-flags.enum';
import {
  IAuditRun,
  IQueuedAudit,
  IQueuedWebJourney,
  IWebJourneyStatusRun
} from '@app/components/admin-portal/system-status/system-status.models';
import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable, of } from 'rxjs';

export interface IAuditResult {
  id: number;
  mostRecentRunId: number;
  folderId: number;
  domainId: number;
  accountId: number;
  description: string;
  company: string;
  pages: number;
}

export interface ISimulationResult {
  id: number;
  mostRecentRunId: number;
  accountId: number;
  company: string;
  description: string;
  domainId: number;
  folderId: number;
}

export interface IWebsocketInformation {
  scheme: string;
  host: string;
  port: number;
  namespace: string;
  user: string;
}

export interface IItemFinderResults {
  audits: Array<IAuditResult>;
  simulations: Array<ISimulationResult>;
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private apiService: ApiService,
              private router: Router,
              @Inject(AngularNames.ngRedux) private $ngRedux: ngRedux.INgRedux,
              private authenticationStorageService: AuthenticationStorageService,
              private eventManager: IEventManager,
              private storageService: StorageService) {
  }

  root: string = environment.apiUrl;
  apiV3Url: string = environment.apiV3Url;

  findItems(searchString: string): Observable<IItemFinderResults> {
    return this.apiService.post(this.root + 'admin/item-finder', searchString);
  }

  loginAsUser(accountId: number): Promise<any> {
    return this.apiService.post(this.root + 'admin/login-as-user', { accountId: accountId })
      .toPromise()
      .then((response) => {
      this.$ngRedux.dispatch(AuthenticationActions.userLogout(null));
      return response;
    });
  }

  getAuditsInQueue(): Observable<Array<IQueuedAudit>> {
    return this.apiService.get(this.root + 'system/status/audits/queued');
  }

  getAuditsRunning(): Observable<Array<IAuditRun>> {
    return this.apiService.get(this.root + 'system/status/audits/running');
  }

  getWebJourneysInQueue(): Observable<Array<IQueuedWebJourney>> {
    return this.apiService.get(this.root + 'system/status/web-journeys/queued');
  }

  getWebJourneysRunning(): Observable<Array<IWebJourneyStatusRun>> {
    return this.apiService.get(this.root + 'system/status/web-journeys/running');
  }

  pauseAudits(audits: Array<number>): Observable<any> {
    return this.apiService.put(this.root + 'system/status/audits/pause', audits);
  }

  resumeAudits(audits: Array<number>): Observable<any> {
    return this.apiService.put(this.root + 'system/status/audits/resume', audits);
  }

  verifyAudits(audits: Array<number>): Observable<any> {
    return this.apiService.put(this.root + 'system/status/audits/verify', audits);
  }

  stopAudits(audits: Array<number>): Observable<any> {
    return this.apiService.put(this.root + 'system/status/audits/stop', audits);
  }

  restartAudits(auditRuns: Array<number>): Observable<any> {
    return this.apiService.put(this.root + 'system/status/audits/restart', auditRuns);
  }

  removeAuditRuns(auditRuns: Array<number>): Observable<any> {
    return this.apiService.put(this.root + 'system/status/audits/remove/runs', auditRuns);
  }
  removeWebJourneyRuns(webJourneyRuns: Array<number>): Observable<any> {
    return this.apiService.put(this.apiV3Url + 'system/status/web-journeys/remove/runs', webJourneyRuns);
  }

  removeAllRunsOfAudits(audits: Array<number>): Observable<any> {
    return this.apiService.put(this.root + 'system/status/audits/remove/runs/all', audits);
  }
  removeAllRunsOfWebJourneys(webJourneys: Array<number>): Observable<any> {
    return this.apiService.put(this.apiV3Url + 'system/status/web-journeys/remove/runs/all', webJourneys);
  }

  restartWebJourneys(webJourneyRuns: Array<number>): Observable<any> {
    if (FeatureFlagHelper.isEnabled(FeatureFlags.SQSJourneyEngines)) {
      return this.apiService.post(this.apiV3Url + 'web-journeys/restart', webJourneyRuns);
    } else {
      return this.apiService.put(this.root + 'system/status/web-journeys/restart', webJourneyRuns);
    }
  }

  login(currentAccountId: number,
        loginAsAccountId: number,
        navigationUrl: string,
        originalUrl: string): Observable<boolean> {
    if (currentAccountId === loginAsAccountId) {
      this.router.navigateByUrl(navigationUrl);
      return of(true);
    }

    this.loginAsUser(loginAsAccountId).then(tokenInfo => {
      let authorizationData = { token: tokenInfo.accessToken, id: tokenInfo.userId, expiresAt: Date.now() + 10 * 60 * 60 * 1000 };
      this.authenticationStorageService.impersonateUser(authorizationData);
      this.storageService.removeParamsForAudits();
      this.storageService.removeParamsForWebJourneys();
      this.storageService.setOriginalState({
        url: originalUrl
      });

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigateByUrl(navigationUrl).then(() => {
          this.eventManager.publish(Events.loggedInAsAnother);
        });
      });
    }, (error) => {
      console.log('Can\'t log in', error);
    });
  }
}
