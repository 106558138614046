import {
  EPageDetailsAccessibilityFilterName,
  EPageDetailsAccessibilityWCAGConformanceLevel,
  EPageDetailsAccessibilityWCAGIssueType,
  EPageDetailsAccessibilityWCAGVersion,
  IPagesDetailsAccessibilityFilter,
  IPagesDetailsAccessibilityFilterBarFilters,
  IPagesDetailsAccessibilitySeverityFilter
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.models';
import {
  getDefaultOptions
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.utils';
import { EAccessibilityImpact } from '@app/components/audit-reports/page-details/page-details.models';

export const PageDetailsAccessibilityFiltersStorageKey = 'page-details-accessibility-filters';
export const PageDetailsAccessibilityWCAGVersions = Object.values(EPageDetailsAccessibilityWCAGVersion);
export const PageDetailsAccessibilityWCAGConformanceLevels = Object.values(EPageDetailsAccessibilityWCAGConformanceLevel);
export const DetailsAccessibilityWCAGIssueTypes = Object.values(EPageDetailsAccessibilityWCAGIssueType);
export const PageDetailsAccessibilityDefaultTopFilters: Record<EPageDetailsAccessibilityFilterName, IPagesDetailsAccessibilityFilter> = {
  [EPageDetailsAccessibilityFilterName.Versions]: {
    label: 'VERSIONS',
    menuTitle: 'WCAG Versions',
    menuOptions: getDefaultOptions(PageDetailsAccessibilityWCAGVersions),
    name: EPageDetailsAccessibilityFilterName.Versions,
  },
  [EPageDetailsAccessibilityFilterName.Levels]: {
    label: 'LEVELS',
    menuTitle: 'WCAG Levels',
    menuOptions: getDefaultOptions(PageDetailsAccessibilityWCAGConformanceLevels),
    name: EPageDetailsAccessibilityFilterName.Levels,
  },
  [EPageDetailsAccessibilityFilterName.Types]: {
    label: 'TYPES',
    menuTitle: 'WCAG Issue Types',
    menuOptions: getDefaultOptions(DetailsAccessibilityWCAGIssueTypes),
    name: EPageDetailsAccessibilityFilterName.Types,
  }
};

export const PageDetailsAccessibilitySeverityTooltips = {
  [EAccessibilityImpact.Critical]: 'Critical issues are accessibility problems that significantly hinder or completely block the ability of people with disabilities to access and use web content.',
  [EAccessibilityImpact.Serious]: 'Serious issues are accessibility problems that significantly impact the usability of web content for people with disabilities, causing considerable inconvenience or difficulty without completely blocking access.',
  [EAccessibilityImpact.Moderate]: 'Moderate issues are accessibility problems that impact the usability of web content for people with disabilities, creating noticeable challenges that can be overcome with some effort.',
  [EAccessibilityImpact.Minor]: 'Minor issues are accessibility problems that have a small impact on the usability of web content for people with disabilities, causing minor inconveniences or deviations from best practices without significantly hindering access.',
};

export const PageDetailsAccessibilityDefaultSeverityFilters: Record<EAccessibilityImpact, IPagesDetailsAccessibilitySeverityFilter> = {
  [EAccessibilityImpact.Critical]: {
    label: 'Critical',
    name: EAccessibilityImpact.Critical,
    className: EAccessibilityImpact.Critical,
    checked: false,
  },
  [EAccessibilityImpact.Serious]: {
    label: 'Serious',
    name: EAccessibilityImpact.Serious,
    className: EAccessibilityImpact.Serious,
    checked: false,
  },
  [EAccessibilityImpact.Moderate]: {
    label: 'Moderate',
    name: EAccessibilityImpact.Moderate,
    className: EAccessibilityImpact.Moderate,
    checked: false,
  },
  [EAccessibilityImpact.Minor]: {
    label: 'Minor',
    name: EAccessibilityImpact.Minor,
    className: EAccessibilityImpact.Minor,
    checked: false,
  },
};

export const PageDetailsAccessibilityDefaultFilters: IPagesDetailsAccessibilityFilterBarFilters = {
  topFilters: PageDetailsAccessibilityDefaultTopFilters,
  severity: PageDetailsAccessibilityDefaultSeverityFilters,
};
