import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { AuditReportBase } from '../general-reports.models';
import { IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { finalize, map, skip, take, takeUntil, tap } from 'rxjs/operators';
import { AuditReportFilterBarService } from '../../audit-report-filter-bar/audit-report-filter-bar.service';
import {
  EAuditReportFilterTypes,
  IAuditReportFilter
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { AuditSummaryService } from './audit-summary.service';
import {
  AuditSummaryRelevantFilters,
  EAuditSummaryScoreTrendNames,
  EAuditSummaryTrendType,
  IAuditScoreDatum,
  IAuditSummaryDetectedTagsTableRow,
  IAuditSummaryHeaderData,
  IAuditSummaryPrimaryTag,
  IAuditSummaryPrimaryTags,
  IAuditSummaryScoreTrend,
  IAuditSummaryScoreTrendByName,
  IAuditSummaryTag,
  IAuditSummaryTags
} from './audit-summary.models';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import {
  IAuditRunRuleResultsAllTrendsDTO,
  IAuditRunRuleResultsSummaryDTO,
  IAuditRunRuleResultsTrendItem,
  IAuditRunRuleSummaryTrend,
  IAuditRunRuleSummaryTrendFilters
} from '../rule-summary/rule-summary.models';
import {
  IStackedBarChartInput
} from '@app/components/shared/components/viz/vertical-stacked-bar-chart/vertical-stacked-bar-chart.models';
import { RuleSummaryReportService } from '../rule-summary/rule-summary.service';
import { EDateFormats, formatToUTC } from '@app/components/date/date.service';
import { AuditReportUrlBuilders, EChartColor, EStatusCodeCategories } from '../../audit-report/audit-report.constants';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import {
  AverageBrokenPagesChartConfig,
  AverageBrokenTagsChartConfig,
  AverageUniqueTagsChartConfig,
  ETagInventoryTrendNames
} from '../tag-inventory/tag-inventory.constants';
import {
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  CookiesScoreChartConfig,
  EPrimaryTagHealthCardMenuItem,
  PagePerformanceScoreChartConfig,
  RulesScoreChartConfig,
  TagPerformanceScoreChartConfig,
  TagPresenceScoreChartConfig,
  TotalAuditScoreChartConfig
} from './audit-summary.constants';
import {
  IPrimaryTagEmittedData,
  IPrimaryTagHealthCard
} from './primary-tag-health-card/primary-tag-health-card.models';
import { TagInventoryService } from '../tag-inventory/tag-inventory.service';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import {
  ITagInventoryRouteState,
  ITagInventoryTrend,
  ITagInventoryTrendByName
} from '../tag-inventory/tag-inventory.models';
import {
  FullscreenVerticalStackedBarChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-vertical-stacked-bar-chart-modal/fullscreen-vertical-stacked-bar-chart-modal.component';
import {
  IFullscreenVerticalStackedBarChartModalConfig
} from '@app/components/shared/components/viz/fullscreen-vertical-stacked-bar-chart-modal/fullscreen-vertical-stacked-bar-chart-modal.constants';
import { EMissingTagsCardStates } from './missing-tags-card/missing-tags-card.constants';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AuditReportService } from '../../audit-report/audit-report.service';
import { formatNumber } from '@angular/common';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Features } from '@app/moonbeamConstants';
import {
  IAuditReportExportMenuData
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import _isEmpty from 'lodash-es/isEmpty';
import {
  SummaryCardFilterType
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-trends/rule-summary-trends.component';
import { IAuditReportPageDetailsDrawerService } from '../../audit-report/audit-report-page-details-drawer.models';
import { PageSummaryReportService } from '../page-summary/page-summary.service';
import { AlertMetricType, EAlertAuditSummaryMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-summary',
  templateUrl: './audit-summary.component.html',
  styleUrls: ['./audit-summary.component.scss']
})
export class AuditSummaryComponent extends AuditReportBase implements IFilterableAuditReport, OnInit, AfterViewInit, OnDestroy {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;
  private readonly xAxisLabelDateFormat = EDateFormats.dateSix;
  private readonly xAxisLabelTimeFormat = EDateFormats.timeOne;

  auditId: number;
  runId: number;
  pageIdOpenInPageDetails: string;
  hasPrimaryTags: boolean;
  hasTagFilterEnabled: boolean;
  userIsReadOnly: boolean;

  // primary tag health cards variables
  primaryTags: IAuditSummaryPrimaryTag[][];
  primaryTagsCount: number;
  missingTags: IAuditSummaryPrimaryTag[];
  missingTagsState = EMissingTagsCardStates;
  pagesScanned: number;
  primaryTagsHealthState: EFilterSpinnerState;
  primaryTagsCardHeight: number = 235;
  primaryTagsViewportHeight: number;

  // detected tags table variables
  detectedTagsState: EFilterSpinnerState;
  detectedTagsDataSource: MatTableDataSource<IAuditSummaryTag> = new MatTableDataSource();
  detectedTagsDisplayedColumns = ['tagName', 'tagCategoryName', 'pageCount', 'pageNotPresentCount', 'tagLoadTimeAverage', 'tagAccountCount', 'uniqueVariableCount'];

  readonly stateOptions = EFilterSpinnerState;

  apiFilters: IAuditReportApiPostBody = {};
  private filtersUpdated$: Subject<null> = new Subject();

  /*** Header Data */
  headerDataState: EFilterSpinnerState;
  headerData: IAuditSummaryHeaderData;
  ruleSummaryData: IAuditRunRuleResultsSummaryDTO;

  /*** Rule Trend Data */
  chartState: EFilterSpinnerState = EFilterSpinnerState.None;
  techValidationState: EFilterSpinnerState;
  trendFilters: IAuditRunRuleSummaryTrendFilters = {cards: undefined};
  ruleInstancesTrendChartData: IStackedBarChartInput = this.prepareRuleInstancesTrendChartData();
  ruleInstancesTrendData: IAuditRunRuleResultsTrendItem[] = [];
  ruleInstancesTrend: IAuditRunRuleSummaryTrend = this.emptyTrend();

  /*** Split Card Sparklines */
  sparklineDataLoaded: boolean = false;
  uniqueTagsSparkLineData: ISparklineChartData[] = [];
  brokenTagsSparkLineData: ISparklineChartData[] = [];
  brokenPagesSparkLineData: ISparklineChartData[] = [];
  runInfo: ISparklineRunInfo[] = [];

  /** Score Card Sparklines */
  auditScoreData: IAuditScoreDatum;
  auditScoreSparklineDataLoaded: boolean = false;
  auditScoreSparkLineData = [];
  auditTagPresenceScoreSparkLineData = [];
  auditTagPerformanceScoreSparkLineData = [];
  auditRulesScoreSparkLineData = [];
  auditPagePerformanceScoreSparkLineData = [];
  auditCookiesScoreSparkLineData = [];
  scoreRunInfo = [];
  auditScoreLoadingState: EFilterSpinnerState = EFilterSpinnerState.None;

  widgetAuditScore: ISplitCardChartData = {
    topLabel: 'Audit Score',
    topChangeContent: '',
    tooltip: 'Summation of all sub-scores, total possible score is 100.',
    bottomHandler: this.openAuditScoreFullScreenWidget.bind(this),
    metricType: EAlertAuditSummaryMetric.AuditScore,
  };

  widgetAuditTagPresenceScore: ISplitCardChartData = {
    topLabel: 'Tag Presence',
    topChangeContent: '',
    topHandler: () => this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId)),
    tooltip: 'Average implementation rate of primary tags. If no primary tags are set all tags are considered.',
    bottomHandler: this.openAuditTagPresenceScoreFullScreenWidget.bind(this),
    metricType: EAlertAuditSummaryMetric.TagPresenceScore,
  };

  widgetAuditTagPerformanceScore: ISplitCardChartData = {
    topLabel: 'Tag Performance',
    topChangeContent: '',
    topHandler: () => this.router.navigateByUrl(AuditReportUrlBuilders.tagHealth(this.auditId, this.runId)),
    tooltip: 'Evaluation of primary tags that take longer than 500ms to load. If no primary tags are set all tags are considered',
    bottomHandler: this.openAuditTagPerformanceScoreFullScreenWidget.bind(this),
    metricType: EAlertAuditSummaryMetric.TagPerformanceScore,
  };

  widgetAuditRulesScore: ISplitCardChartData = {
    topLabel: 'Rules',
    topChangeContent: '',
    topHandler: () => this.router.navigateByUrl(AuditReportUrlBuilders.ruleSummary(this.auditId, this.runId)),
    tooltip: 'Evaluation of the total failed rules across all pages scanned. If no rules are applied a score of 0 is given.',
    bottomHandler: this.openAuditRulesScoreFullScreenWidget.bind(this),
    metricType: EAlertAuditSummaryMetric.RuleScore,
  };

  widgetAuditPagePerformanceScore: ISplitCardChartData = {
    topLabel: 'Page Performance',
    topChangeContent: '',
    tooltip: 'This score reflects how quickly pages are loading. Pages should ideally have a Largest Contentful Paint (LCP) of 2.5 seconds or less for optimal user experience and search engine rankings. We calculate this score using the 75th percentile of the Largest Contentful Paint (LCP) metric, ensuring a more accurate reflection of how your pages are performing.',
    topHandler: () => this.router.navigateByUrl(AuditReportUrlBuilders.pageSummary(this.auditId, this.runId)),
    bottomHandler: this.openAuditPagePerformanceScoreFullScreenWidget.bind(this),
    metricType: EAlertAuditSummaryMetric.PagePerformanceScore,
  };

  widgetAuditCookiesScore: ISplitCardChartData = {
    topLabel: 'Cookies',
    topChangeContent: '',
    tooltip: 'Evaluation of both non-secure and empty SameSite 3rd party cookies. ',
    topHandler: () => this.router.navigateByUrl(AuditReportUrlBuilders.cookieInventory(this.auditId, this.runId)),
    bottomHandler: this.openAuditCookiesScoreFullScreenWidget.bind(this),
    metricType: EAlertAuditSummaryMetric.CookieScore,
  };

  widgetUniqueTags: ISplitCardChartData = {
    topLabel: 'Unique tags',
    topChangeContent: '',
    tooltip: 'Count of Unique Tags found in this audit run.',
    topHandler: () => this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId)),
    bottomHandler: this.openUniqueTagsFullScreenWidget.bind(this)
  };

  widgetBrokenTags: ISplitCardChartData = {
    topLabel: 'Broken Tags',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEGATIVE,
    tooltip: 'Count of broken tags found in this audit which have a 0, 400+, or 500+ HTTP status code.',
    topHandler: () => {
      this.filterBarService.addTagStatusCodeFilter(EStatusCodeCategories.Broken);
      this.router.navigateByUrl(AuditReportUrlBuilders.tagHealth(this.auditId, this.runId));
    },
    bottomHandler: this.openBrokenTagsFullScreenWidget.bind(this)
  };

  widgetBrokenFinalPages: ISplitCardChartData = {
    topLabel: 'Broken Final Pages',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEGATIVE,
    topHandler: () => {
      this.filterBarService.addFinalPageStatusCodeFilter(EStatusCodeCategories.Broken);
      this.router.navigateByUrl(AuditReportUrlBuilders.pageSummary(this.auditId, this.runId));
    },
    bottomHandler: this.openBrokenPagesFullScreenWidget.bind(this)
  };

  privacyFeatureEnabled: boolean;

  @ViewChild(MatSort) sort: MatSort;

  exportReportConfig: IAuditReportExportMenuData = {
    tableName: 'Detected Tags',
    exportType: 'audit_summary_detected_tags',
    totalRows: 0,
    filteredRows: 0,
    tableState: {
      sortBy: undefined,
      sortDesc: undefined,
      page: null,
      size: null
    },
    filters: this.apiFilters,
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private filterBarService: AuditReportFilterBarService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private auditSummaryService: AuditSummaryService,
    private pageSummaryService: PageSummaryReportService,
    private auditLoadingService: AuditReportLoadingService,
    private ruleSummaryService: RuleSummaryReportService,
    private modalEscapeService: ModalEscapeService,
    private modalService: OpModalService,
    private tagInventoryService: TagInventoryService,
    private auditReportService: AuditReportService,
    private authenticationService: AuthenticationService,
    private alertReportingService: AlertReportingService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(
      tap(({ auditId, runId }) => {
        this.auditId = +auditId;
        this.runId = +runId;
        const queryParams = (this.activatedRoute?.queryParams as BehaviorSubject<any>)?.getValue();
        const alertId = queryParams?.alertId;
        const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
        this.preventHighlight = !highlight;
        // When loading report from an alert, first override filters with alert
        // filters before loading report data
        if (alertId) {
          this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
            this.filterBarService.overrideFilters(alert.config.filtersV0);
            this.highlightMetricType = highlight && alert.config.metricType;

            this.alertCheckComplete$.next(true);
          });
        } else {
          this.alertCheckComplete$.next(true);
        }
      }),
      skip(1), // only fire when calendar control updated
      tap(() => {
        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          this.onFiltersChanged(this.apiFilters);
        });
      })
    ).subscribe();

    this.userIsReadOnly = this.auditReportService.userIsReadOnly;

    this.authenticationService.getFeaturesWithCache().pipe(take(1)).subscribe(features => {
      this.privacyFeatureEnabled = features.includes(Features.productLinePrivacy);
    });

    // setup for page details
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.PageInformation);
    this.pageDetailsDrawerService.pageDrawerClosed$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handlePageDetailsClosed());

    // setup filters
    this.initFilters();

    this.headerDataState = EFilterSpinnerState.Loading;
    this.techValidationState = EFilterSpinnerState.Loading;
  }

  ngAfterViewInit(): void {
    this.detectedTagsDataSource.sort = this.sort;

    this.detectedTagsDataSource.sortingDataAccessor = (item, property) => {
      return typeof item[property] === 'string'
        ? item[property].toLocaleLowerCase()
        : item[property];
    };
  }

  ngOnDestroy(): void {
    this.destroy();
    this.onDestroy$.next();
    this.pageDetailsDrawerService.closePageDetails();
  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(AuditSummaryRelevantFilters);

    // ReplaySubject in filterBarService means this executes immediately
    this.filterBarService.apiPostBody$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody) {
    this.apiFilters = apiPostBody;
    this.exportReportConfig.filters = {...apiPostBody};
    this.filtersUpdated$.next();
    this.updateCurrentFilters();
    this.handlePrimaryTagCards();
    this.getAuditSummaryHeaderData();
    this.getAuditSummaryTrendsData();
    this.handleTagsTable();
    this.getAuditSummaryScoreData();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  handlePageDetailsClosed(): void {
    this.pageIdOpenInPageDetails = null;
  }

  handlePrimaryTagCards(): void {
    this.auditLoadingService.addLoadingToken();
    this.primaryTagsHealthState = EFilterSpinnerState.Loading;

    this.auditSummaryService
      .getPrimaryTags(this.auditId, this.runId, this.apiFilters)
      .pipe(
        finalize(() => {
          // always update loading states (this runs last)
          this.auditLoadingService.removeLoadingToken();
          this.primaryTagsHealthState = this.filterBarService.currentRelevantFilters.length
            ? EFilterSpinnerState.Filtered
            : EFilterSpinnerState.None;

          this.hasTagFilterEnabled = !!this.filterBarService
            .currentFilters
            .find((filter: IAuditReportFilter) =>
              filter.type === EAuditReportFilterTypes.TagId ||
              filter.type === EAuditReportFilterTypes.TagCategory ||
              filter.type === EAuditReportFilterTypes.TagVendorId ||
              filter.type === EAuditReportFilterTypes.TagPrimaryTagsOnly
            );
        })
      )
      .subscribe(
        // success
        (data: IAuditSummaryPrimaryTags) => {
          if (data.tags) {
            const presentTags = data.tags.filter((tag: IAuditSummaryPrimaryTag) => tag.pageCount);
            this.primaryTagsCount = presentTags.length;
            this.primaryTags = this.groupPrimaryTagsForDisplay(presentTags);
            this.missingTags = data.tags.filter((tag: IAuditSummaryPrimaryTag) => !tag.pageCount);
            this.hasPrimaryTags = true;
            this.calcPrimaryTagsViewportHeight();
            return;
          } else {
            this.hasPrimaryTags = false;
            this.primaryTags = [];
            this.missingTags = [];
          }
        },

        // error
        (error) => {
          // 424 error happens when the request is successful but the user
          // has not set up any primary tags in their account
          this.hasPrimaryTags = !(error.code === 424);
          this.primaryTags = [];
          this.missingTags = [];
        }
      );
  }

  calcPrimaryTagsViewportHeight(): void {
    if (this.missingTags.length && this.primaryTags.length) {
      const lastGroupSize = this.primaryTags[this.primaryTags.length - 1].length;
      const multiplier = (lastGroupSize % 2 === 0)
        ? this.primaryTags.length + 1
        : this.primaryTags.length;

      this.primaryTagsViewportHeight = this.primaryTagsCardHeight * multiplier;
    } else {
      this.primaryTagsViewportHeight = this.primaryTagsCardHeight * this.primaryTags.length;
    }
  }

  groupPrimaryTagsForDisplay(tags: IAuditSummaryPrimaryTag[]) {
    let groupedTags = [];

    tags.forEach((tag: IAuditSummaryPrimaryTag, index: number) => {
      if ((index % 2) === 0) {
        let tagGroup = [tag];

        if (tags[index + 1]) {
          tagGroup.push(tags[index + 1]);
        }

        groupedTags.push(tagGroup);
      }
    });

    return groupedTags;
  }

  goToPrimaryTagsSetup(): void {
    this.router.navigateByUrl(AccountSettingsUrlBuilders.manageTags());
  }

  handleTagHealthEmit(data: IPrimaryTagEmittedData): void {
    let state = {};

    if (data.event !== EPrimaryTagHealthCardMenuItem.TagSetup) {
      if (data.tagPresenceFilter?.missingTags) {
        this.filterBarService.addPagesWithoutTagIdFilter(data.tag.tagName, data.tag.tagId);
      } else {
        this.filterBarService.addTagIdFilter(data.tag.tagName, data.tag.tagId);
      }

      state = {
        tagPresenceFilter: data.tagPresenceFilter
      };
    }

    let route: string;

    switch (data.event) {
      case EPrimaryTagHealthCardMenuItem.TagInventory:
        route = AuditReportUrlBuilders.tagInventory(this.auditId, this.runId);
        break;

      case EPrimaryTagHealthCardMenuItem.TagHealth:
        route = AuditReportUrlBuilders.tagHealth(this.auditId, this.runId);
        break;

      case EPrimaryTagHealthCardMenuItem.TagSetup:
        route = AccountSettingsUrlBuilders.manageTags();
        break;
    }

    this.router.navigateByUrl(route, { state });
  }

  summaryCardClicked(event: SummaryCardFilterType) {
    this.router.navigateByUrl(
      AuditReportUrlBuilders.ruleSummary(this.auditId, this.runId),
      { state: { auditSummaryTrendCardClicked: event } }
    );
  }

  openTrendsFullscreenChart(event) {
    const ruleInstancesTrendDataCached = [...this.ruleInstancesTrendData];
    const title = 'Rule Instances by Status';
    const getData = (days: number) => this.ruleSummaryService.getRuleResultsTrends(this.auditId, days)
      .pipe(
        tap(data => this.ruleInstancesTrendData = data.runs),
        map(data => this.prepareRuleInstancesTrendChartData())
      );
    const emptyDataset = {...this.ruleInstancesTrendChartData, bars: []};

    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenVerticalStackedBarChartModalComponent, {
      data: {
        currentRunCompletedAt: this.runInfo.sort((a, b) => a.runId - b.runId)[this.runInfo.length - 1]?.runCompletionDate,
        getData,
        chartConfig: {
          title,
          data: emptyDataset
        }
      } as IFullscreenVerticalStackedBarChartModalConfig
    }).afterClosed()
      .subscribe(() => {
        this.ruleInstancesTrendData = ruleInstancesTrendDataCached;
        this.modalEscapeService.remove(index);
      });
  }

  private getAuditSummaryHeaderData() {
    forkJoin([
      this.auditSummaryService.getHeaderData(this.auditId, this.runId, this.apiFilters),
      this.pageSummaryService.getAuditPageSummaryInsights(this.auditId, this.runId, this.apiFilters),
      this.ruleSummaryService.getRuleSummary(this.auditId, this.runId, this.apiFilters),
      this.ruleSummaryService.getRuleSummaryTrends(this.auditId, this.runId),
      this.tagInventoryService.getTagInventorySummary(this.auditId, this.runId, this.apiFilters)
    ]).subscribe(([auditSummaryData, pageSummaryData, ruleSummaryData, ruleSummaryTrends, tagInventorySummary]) => {
      this.headerData = {
        ...auditSummaryData,
        totalPageCount: tagInventorySummary.totalPageCount,
        filteredPageCount: tagInventorySummary.filteredPageCount,
        filteredBrokenPageCount: pageSummaryData.pagesWithBrokenFinalStatusCode,
        filteredBrokenTagCount: tagInventorySummary.filteredBrokenTagCount,
        filteredUniqueTagCount: tagInventorySummary.filteredUniqueTagCount,
      };
      this.pagesScanned = tagInventorySummary.totalPageCount;

      //update widgetBrokenPages meaning
      this.widgetBrokenFinalPages.topChangeMeaning = this.headerData.filteredBrokenPageCount > 0
        ? ESplitCardChangeMeaning.NEGATIVE
        : ESplitCardChangeMeaning.NEUTRAL;

      //update widgetBrokenTags meaning
      this.widgetBrokenTags.topChangeMeaning = this.headerData.filteredBrokenTagCount > 0
        ? ESplitCardChangeMeaning.NEGATIVE
        : ESplitCardChangeMeaning.NEUTRAL;

      //ruleSummaryData
      this.ruleSummaryData = ruleSummaryData;

      //ruleSummaryTrends
      this.prepareCurrentRunTrend(ruleSummaryTrends);
      this.ruleInstancesTrendData = ruleSummaryTrends.runs;
      this.ruleInstancesTrendChartData = this.prepareRuleInstancesTrendChartData();

      this.headerDataState = this.filterBarService.currentRelevantFilters.length > 0
        ? EFilterSpinnerState.Filtered
        : EFilterSpinnerState.None;
      this.techValidationState = this.filterBarService.currentRelevantFilters.length > 0
        ? EFilterSpinnerState.Filtered
        : EFilterSpinnerState.None;
    });
  }

  private getAuditSummaryTrendsData() {
    this.sparklineDataLoaded = false;
    this.auditSummaryService.getSummaryTrendsData(this.auditId, this.runId).subscribe(res => {
      res.runs.sort((a, b) => a.runId - b.runId);

      const currentRun = res.runs.find(run => run.runId === this.runId);
      this.exportReportConfig.totalRows = currentRun.totalUniqueTagCount;

      this.uniqueTagsSparkLineData = [];
      this.brokenPagesSparkLineData = [];
      this.brokenTagsSparkLineData = [];

      res.runs.forEach((run, i: number) => {
        const sequence = i++;
        this.uniqueTagsSparkLineData = [...this.uniqueTagsSparkLineData, {sequence, value: run.totalUniqueTagCount}];
        this.brokenPagesSparkLineData = [...this.brokenPagesSparkLineData, {sequence, value: run.totalBrokenPageCount}];
        this.brokenTagsSparkLineData = [...this.brokenTagsSparkLineData, {sequence, value: run.totalBrokenTagCount}];

        this.runInfo = [...this.runInfo, {runId: run.runId, runCompletionDate: run.completedAt.toString()}];
      });

      this.sparklineDataLoaded = true;
    });
  }

  private getAuditSummaryScoreData(): void {
    this.auditScoreSparklineDataLoaded = false;
    this.auditScoreLoadingState = EFilterSpinnerState.Loading;
    forkJoin([
      this.auditSummaryService.getAuditScoreData(this.auditId, this.runId),
      this.auditSummaryService.getAuditScoreTrendData(this.auditId, this.runId),
    ]).pipe(finalize(() => {
      this.auditScoreLoadingState = this.filterBarService.currentRelevantFilters.length > 0
        ? EFilterSpinnerState.Filtered
        : EFilterSpinnerState.None;
    })).subscribe(([scoreData, scoreTrendRuns]) => {
      this.auditScoreData = scoreData;

      scoreTrendRuns.runs.sort((a, b) => a.runId - b.runId);

      this.auditScoreSparkLineData = [];
      this.auditTagPresenceScoreSparkLineData = [];
      this.auditTagPerformanceScoreSparkLineData = [];
      this.auditRulesScoreSparkLineData = [];
      this.auditPagePerformanceScoreSparkLineData = [];
      this.auditCookiesScoreSparkLineData = [];

      scoreTrendRuns.runs.forEach((run, i: number) => {
        const sequence = i++;

        this.auditScoreSparkLineData = [...this.auditScoreSparkLineData, {sequence, value: run.scores.total.score}];
        this.auditTagPresenceScoreSparkLineData = [...this.auditTagPresenceScoreSparkLineData, {
          sequence,
          value: run.scores.tagPresence.score
        }];
        this.auditTagPerformanceScoreSparkLineData = [...this.auditTagPerformanceScoreSparkLineData, {
          sequence,
          value: run.scores.tagPerformance.score
        }];
        this.auditRulesScoreSparkLineData = [...this.auditRulesScoreSparkLineData, {
          sequence,
          value: run.scores.rules.score
        }];
        this.auditPagePerformanceScoreSparkLineData = [...this.auditPagePerformanceScoreSparkLineData, {
          sequence,
          value: run.scores.pagePerformance.score
        }];
        this.auditCookiesScoreSparkLineData = [...this.auditCookiesScoreSparkLineData, {
          sequence,
          value: run.scores.cookies.score
        }];

        this.scoreRunInfo = [...this.runInfo, {runId: run.runId, runCompletionDate: run.completedAt.toString()}];
      });

      this.auditScoreSparklineDataLoaded = true;
    }, (error) => {
      console.error(error);
    });
  }

  private prepareCurrentRunTrend(data: IAuditRunRuleResultsAllTrendsDTO): void {
    data.runs.sort((a, b) => a.runId - b.runId);
    const currentRunTrend = data.runs[data.runs.length - 1];
    const previousRunTrend = data.runs[data.runs.length - 2];

    this.ruleInstancesTrend = {
      failedRulesCount: this.ruleSummaryData?.failedRuleCount,
      failedRulesCountDiffWithPrev: currentRunTrend.failedRuleCount - previousRunTrend?.failedRuleCount,
      passedRulesCount: this.ruleSummaryData?.passedRuleCount,
      passedRulesCountDiffWithPrev: currentRunTrend.passedRuleCount - previousRunTrend?.passedRuleCount,
      notAppliedRulesCount: this.ruleSummaryData?.notAppliedRuleCount,
      notAppliedRulesCountDiffWithPrev: currentRunTrend.notAppliedRuleCount - previousRunTrend?.notAppliedRuleCount
    };
  }

  private prepareRuleInstancesTrendChartData(): IStackedBarChartInput {
    return {
      layerDefinitions: this.barLayerDefinitions(),
      yAxisLabel: undefined,
      emptyBarText: 'ø',
      bars: (this.ruleInstancesTrendData || [])
        .sort((a, b) => a.runId - b.runId)
        .map(t => ({
          layerValues: {
            failedRuleCount: t.failedRuleCount,
            passedRuleCount: t.passedRuleCount,
            notAppliedRuleCount: t.notAppliedRuleCount
          },
          label: this.dateTimeToFormat(t.completedAt, this.xAxisLabelDateFormat),
          subLabel: this.dateTimeToFormat(t.completedAt, this.xAxisLabelTimeFormat),
          id: '' + t.runId
        }))
    };
  }

  private emptyTrend(): IAuditRunRuleSummaryTrend {
    return {
      failedRulesCount: 0,
      failedRulesCountDiffWithPrev: 0,
      passedRulesCount: 0,
      passedRulesCountDiffWithPrev: 0,
      notAppliedRulesCount: 0,
      notAppliedRulesCountDiffWithPrev: 0
    };
  }

  private dateTimeToFormat(dateString: string, format: EDateFormats): string {
    return formatToUTC(new Date(dateString), format);
  }

  private barLayerDefinitions() {
    return [
      {
        key: 'failedRuleCount',
        label: 'Rules Failed',
        colorClass: !this.trendFilters?.cards || this.trendFilters?.cards === 'failed'
          ? EChartColor.Red : EChartColor.TransparentRed
      },
      {
        key: 'passedRuleCount',
        label: 'Rules Passed',
        colorClass: !this.trendFilters?.cards || this.trendFilters?.cards === 'passed'
          ? EChartColor.Green : EChartColor.TransparentGreen
      },
      {
        key: 'notAppliedRuleCount',
        label: 'Rules Not Applied',
        colorClass: !this.trendFilters?.cards || this.trendFilters?.cards === 'notApplied'
          ? EChartColor.Yellow : EChartColor.TransparentYellow
      }
    ];
  }

  private openUniqueTagsFullScreenWidget() {
    if (this.uniqueTagsSparkLineData.length > 0) {
      this.openFullScreenChart(
        ETagInventoryTrendNames.UniqueTags,
        this.runInfo.length > 1 ? this.runInfo[this.runInfo.length - 2].runCompletionDate : undefined,
        EAuditSummaryTrendType.tagInventory,
      );
    }
  }

  private openBrokenTagsFullScreenWidget() {
    if (this.brokenTagsSparkLineData.length > 0) {
      this.openFullScreenChart(
        ETagInventoryTrendNames.BrokenTags,
        this.runInfo.length > 1 ? this.runInfo[this.runInfo.length - 2].runCompletionDate : undefined,
        EAuditSummaryTrendType.tagInventory,
      );
    }

  }

  private openBrokenPagesFullScreenWidget() {
    if (this.brokenPagesSparkLineData.length > 0) {
      this.openFullScreenChart(
        ETagInventoryTrendNames.BrokenPages,
        this.runInfo.length > 1 ? this.runInfo[this.runInfo.length - 2].runCompletionDate : undefined,
        EAuditSummaryTrendType.tagInventory,
      );
    }
  }

  private openAuditScoreFullScreenWidget() {
    if (this.auditScoreSparkLineData.length > 0) {
      this.openFullScreenChart(
        EAuditSummaryScoreTrendNames.AuditScore,
        this.runInfo.length > 1 ? this.runInfo[this.runInfo.length - 2].runCompletionDate : undefined,
        EAuditSummaryTrendType.auditScore,
      );
    }
  }

  private openAuditTagPresenceScoreFullScreenWidget() {
    if (this.auditScoreSparkLineData.length > 0) {
      this.openFullScreenChart(
        EAuditSummaryScoreTrendNames.TagPresenceScore,
        this.runInfo.length > 1 ? this.runInfo[this.runInfo.length - 2].runCompletionDate : undefined,
        EAuditSummaryTrendType.auditScore,
      );
    }
  }

  private openAuditTagPerformanceScoreFullScreenWidget() {
    if (this.auditScoreSparkLineData.length > 0) {
      this.openFullScreenChart(
        EAuditSummaryScoreTrendNames.TagPerformanceScore,
        this.runInfo.length > 1 ? this.runInfo[this.runInfo.length - 2].runCompletionDate : undefined,
        EAuditSummaryTrendType.auditScore,
      );
    }
  }

  private openAuditRulesScoreFullScreenWidget() {
    if (this.auditScoreSparkLineData.length > 0) {
      this.openFullScreenChart(
        EAuditSummaryScoreTrendNames.RulesScore,
        this.runInfo.length > 1 ? this.runInfo[this.runInfo.length - 2].runCompletionDate : undefined,
        EAuditSummaryTrendType.auditScore,
      );
    }
  }

  private openAuditPagePerformanceScoreFullScreenWidget() {
    if (this.auditScoreSparkLineData.length > 0) {
      this.openFullScreenChart(
        EAuditSummaryScoreTrendNames.PagePerformanceScore,
        this.runInfo.length > 1 ? this.runInfo[this.runInfo.length - 2].runCompletionDate : undefined,
        EAuditSummaryTrendType.auditScore,
      );
    }
  }

  private openAuditCookiesScoreFullScreenWidget() {
    if (this.auditScoreSparkLineData.length > 0) {
      this.openFullScreenChart(
        EAuditSummaryScoreTrendNames.CookiesScore,
        this.runInfo.length > 1 ? this.runInfo[this.runInfo.length - 2].runCompletionDate : undefined,
        EAuditSummaryTrendType.auditScore,
      );
    }
  }

  private openFullScreenChart(
    trendName: ETagInventoryTrendNames | EAuditSummaryScoreTrendNames,
    secondToLastCompletionDate: string,
    trendType: EAuditSummaryTrendType
  ) {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days, trendType),
        chartConfig: this.getFullscreenChartConfig(trendName)
      }
    });
  }

  private getFullscreenChartData(trendName: ETagInventoryTrendNames | EAuditSummaryScoreTrendNames, days: number, trendType: EAuditSummaryTrendType): Observable<IFullscreenChartDataWithStats> {
    const getTrendObservable = trendType === EAuditSummaryTrendType.tagInventory
      ? this.auditSummaryService.getTrend(this.auditId, trendName, days)
      : this.auditSummaryService.getScoreTrend(this.auditId, trendName, days);

    return getTrendObservable
      .pipe(
        map((trendData: ITagInventoryTrendByName | IAuditSummaryScoreTrendByName) => {
          return {
            chartData: (trendData.runs as Array<ITagInventoryTrend | IAuditSummaryScoreTrend>).map((dataPoint: ITagInventoryTrend | IAuditSummaryScoreTrend) => {

              return {
                value: trendType === EAuditSummaryTrendType.tagInventory ? +(dataPoint as ITagInventoryTrend).trendValue : Math.round(((+(dataPoint as IAuditSummaryScoreTrend).score?.score) + Number.EPSILON) * 10) / 10, // round to one decimal place
                date: dataPoint.completedAt
              };
            })
          };
        })
      );
  }

  private getFullscreenChartConfig(trendName: ETagInventoryTrendNames | EAuditSummaryScoreTrendNames) {
    switch (trendName) {
      case ETagInventoryTrendNames.UniqueTags:
        return AverageUniqueTagsChartConfig;
      case ETagInventoryTrendNames.BrokenTags:
        return AverageBrokenTagsChartConfig;
      case ETagInventoryTrendNames.BrokenPages:
        return AverageBrokenPagesChartConfig;
      case EAuditSummaryScoreTrendNames.AuditScore:
        return TotalAuditScoreChartConfig;
      case EAuditSummaryScoreTrendNames.CookiesScore:
        return CookiesScoreChartConfig;
      case EAuditSummaryScoreTrendNames.PagePerformanceScore:
        return PagePerformanceScoreChartConfig;
      case EAuditSummaryScoreTrendNames.RulesScore:
        return RulesScoreChartConfig;
      case EAuditSummaryScoreTrendNames.TagPerformanceScore:
        return TagPerformanceScoreChartConfig;
      case EAuditSummaryScoreTrendNames.TagPresenceScore:
        return TagPresenceScoreChartConfig;
      default:
        return null;
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  handleTagsTable(): void {
    // update loading states
    this.auditLoadingService.addLoadingToken();
    this.detectedTagsState = EFilterSpinnerState.Loading;

    this.auditSummaryService
      .getTags(this.auditId, this.runId, this.apiFilters)
      .subscribe((data: IAuditSummaryTags) => {
        if (_isEmpty(this.apiFilters)) {
          this.exportReportConfig.totalRows = data.tags.length;
        } else {
          this.exportReportConfig.filteredRows = data.tags.length;
        }

        this.detectedTagsDataSource.data = data.tags
          .map((tag: IAuditSummaryTag) => {
            return {
              ...tag,
              menuOpen: false,
              tagLoadTimeClass: this.auditReportService.getLoadTimeClassForMs(tag.tagLoadTimeAverage)
            };
          })
          .sort((a: IAuditSummaryTag, b: IAuditSummaryTag) => {
            return (a.tagName.toLowerCase() > b.tagName.toLowerCase()) ? 1 : -1;
          });

        // update loading states
        this.auditLoadingService.removeLoadingToken();
        this.detectedTagsState = this.filterBarService.currentRelevantFilters.length
          ? EFilterSpinnerState.Filtered
          : EFilterSpinnerState.None;
      });
  }

  navToTagInventory(tag: IAuditSummaryDetectedTagsTableRow): void {
    this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);
    this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
  }

  navToVariableInventory(tag: IAuditSummaryDetectedTagsTableRow): void {
    this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);
    this.router.navigateByUrl(AuditReportUrlBuilders.variableInventory(this.auditId, this.runId));
  }

  navToTagHealth(tag: IAuditSummaryDetectedTagsTableRow): void {
    this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);
    this.router.navigateByUrl(AuditReportUrlBuilders.tagHealth(this.auditId, this.runId));
  }

  filterReportByTag(tag: IAuditSummaryDetectedTagsTableRow): void {
    this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);
  }

  navToTagInventoryWithReportFilter(tag: IAuditSummaryDetectedTagsTableRow, missingTags: boolean): void {
    this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);
    const state: ITagInventoryRouteState = {
      tagPresenceFilter: {
        tagId: tag.tagId,
        missingTags: missingTags
      }
    };
    this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId), { state });
  }

  handleTagCategoryClick(tag: IAuditSummaryDetectedTagsTableRow): void {
    this.filterBarService.addTagCategoryFilter(tag.tagCategoryName, tag.tagCategoryId);
    this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
  }

  handleAccountCountClicked(tag: IPrimaryTagHealthCard): void {
    this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);
    const state: ITagInventoryRouteState = {
      accountFilter: {
        tagId: tag.tagId
      }
    };
    this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId), { state });
  }

  formatNumber(n: number, digitsInfo?): string {
    return formatNumber(n || 0, 'en-us', digitsInfo);
  }

  formatTopWidgetValue(firstNum: number, secondNum: number): string {
    if (typeof firstNum === 'number' || typeof secondNum === 'number') {
      return `${this.formatNumber(firstNum, '1.0-1')}/${this.formatNumber(secondNum)}`;
    } else {
      return '';
    }
  }
}
