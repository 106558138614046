export const CookiePropertyNames = {
  domain: 'Domain',
  expandedInitiatorsDate: 'Expanded Initiators Date',
  expires: 'Cookie Expiration Date',
  httpOnly: 'HttpOnly',
  initiators: 'Initiators',
  name: 'Name',
  origin: 'Origin',
  partyType: 'Party Type',
  path: 'Path',
  sameSite: 'SameSite',
  secure: 'Secure',
  cookieTTL: 'Cookie Duration',
  duration: 'Cookie Duration',
  ttlInMinutes: 'Cookie Duration',
  value: 'Value'
};
