import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/components/shared/shared.module';
import { OpDrawerComponent } from './op-drawer/op-drawer.component';
import { ModalSidebarComponent } from './modal-sidebar.component';
import { ModalSidebarLinkComponent } from './modal-sidebar-link/modal-sidebar-link.component';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule.forChild([])],
  declarations: [OpDrawerComponent, ModalSidebarComponent, ModalSidebarLinkComponent],
  exports: [OpDrawerComponent, ModalSidebarComponent]
})
export class ModalSidebarModule {}
