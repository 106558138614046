import { Route } from '@angular/router';
import { RuleLibraryContainerComponent } from './rule-library-container/rule-library-container.component';
import { RuleLibraryComponent } from './rule-library.component';
import { RuleLibraryPaths, RuleLibraryStateNames } from './rule-library.constants';

export const RulesLibratyFutureState: Route = {
  path: RuleLibraryPaths.base,
  loadChildren: () => import('./rule-library.module').then(mod => mod.RuleLibraryModule)
};

export const RulesRoutes: Route = {
  path: '',
  component: RuleLibraryContainerComponent,
  data: {
    stateName: RuleLibraryStateNames.rules
  },
  children: [{
    path: '',
    pathMatch: 'full',
    redirectTo: 'library',
  }, {
    path: RuleLibraryPaths.library,
    component: RuleLibraryComponent,
    data: {
      stateName: RuleLibraryStateNames.rulesLibrary,
      title: 'Tag & Variable Rules',
    }
  }]
};
