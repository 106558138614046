<mde-popover #popover="mdePopover" 
            mdePopoverTriggerOn="click"
            [mdePopoverOverlapTrigger]="false" 
            classList="popover-container">
  <div class="popover-header">{{title}}</div>
  <div class="popover-controls">
    <button mat-raised-button class="accept-btn" (click)="accept()">{{acceptText}}</button>
    <a mat-button class="cancel-btn" (click)="cancel()">{{cancelText}}</a>
  </div>
</mde-popover>

<span class="popover-trigger {{openIcon}}" [mdePopoverTriggerFor]="popover">
  {{openText}}
</span>
