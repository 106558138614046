<div class="tag-comparison-tab">

  <div class="comparison-details" *ngIf="!loading">
    <div class="header-controls">
      <span class="search-ctrl">
        <mat-form-field [floatLabel]="'never'">
          <input matInput type="search" placeholder="Filter by Vendor" [(ngModel)]="filterBy"
          />
          <button matSuffix mat-icon-button aria-label="Clear"
                  (click)="filterBy = ''" *ngIf="filterBy">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </span>

      <div class="slide-toggles">
        <span class="primary-tag-ctrl">
          <mat-slide-toggle color="primary"
                            [disabled]="primaryTagFilteringDisabled"
                            [checked]="primaryTagsToggleValue"
                            [matTooltip]="'Add primary tags to enable tag filtering'"
                            [matTooltipDisabled]="!primaryTagFilteringDisabled"
                            (change)="onPrimaryTagsToggleValueChange.emit($event.checked)">
            Primary Tags Only
          </mat-slide-toggle>
        </span>

        <span class="only-changes-ctrl" *ngIf="hasComparison && !isActionMissing">
          <mat-slide-toggle [(ngModel)]="showDiffsOnly" color="primary">
            Only Show Changes
          </mat-slide-toggle>
        </span>
      </div>
    </div>

    <h4 class="no-comparison" *ngIf="!loading">{{getErrorMessage()}}</h4>

    <wj-results-tag-comparison-table [loading]="loading"
                                     [actionId]="action.actionId"
                                     [groupedDiffs]="groupedDiffs"
                                     [filterByText]="filterBy"
                                     [showDiffsOnly]="showDiffsOnly"
                                     [isActionMissing]="isActionMissing">
    </wj-results-tag-comparison-table>
  </div>

</div>

<mat-spinner *ngIf="loading" [diameter]="75" [strokeWidth]="4" class="centered"></mat-spinner>

