import { darkCircleProgress, lightCircleProgress } from '@app/components/shared/components/op-circle-progress/circle-progress.constants';
import { IAverageScoreChartOpts } from './ror-comparison-results.models';
import { formatDefs } from '@app/components/date/date.service';

export function getAverageScoreChartOptions(isDark = true): IAverageScoreChartOpts {
  const colorOptions = isDark ? darkCircleProgress : lightCircleProgress;
  return {
    fgArcColor: '',
    strokeRadiusPercentage: '20',
    message: 'Average Score',
    ...colorOptions
  };
}

export const dateRunFormat = formatDefs.dateFourteen;
