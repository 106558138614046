<div class="step"
     [class.active]="currentStep === EAlertStep.Logic"
     (click)="stepSelected.emit(EAlertStep.Logic)">
    <div class="top">
      <span class="step-id">1. </span>
      <span class="step-title">Alert Logic</span>
    </div>
    <div class="bottom">
      <alert-logic-details [metricType]="triggerFormData?.metricType"
                           [operator]="triggerFormData?.operator"
                           [targetValue]="triggerFormData?.targetValue"
                           [showFiltersList]="true"
                           [filters]="filters">
      </alert-logic-details>
    </div>
</div>

<div class="step notification-step"
     [class.active]="currentStep === EAlertStep.Notification"
     (click)="stepSelected.emit(EAlertStep.Notification)">
    <div class="top">
      <span class="step-id">2. </span>
      <span class="step-title">Notification</span>
  </div>
  <div class="bottom" *ngIf="notificationEmails.length > 0">
    {{ customNotificationMessage ? 'Custom' : 'Standard' }} alert email message being sent to {{ notificationEmails.length }} email address{{ notificationEmails.length > 1 ? 'es' : '' }}, when it is in a triggered state after each data source completes a run.
  </div>
</div>

<div class="step"
     [class.hidden]="noDataSourcesSupport"
     [class.active]="currentStep === EAlertStep.DataSources"
     (click)="stepSelected.emit(EAlertStep.DataSources)">
    <div class="top">
      <span class="step-id">3. </span>
      <span class="step-title">Data Sources</span>
  </div>
  <div class="bottom" *ngIf="dataSourcesItems.length > 0">
    Alert applied to {{ dataSourcesItems.length }} data sources.
  </div>
</div>

<div class="step history"
     [class.hidden]="noHistorySupport"
     [class.active]="currentStep === EAlertStep.Preview"
     (click)="stepSelected.emit(EAlertStep.Preview)">
    <div class="top">
      <span class="step-id">4. </span>
      <span class="step-title">Alert Preview</span>
  </div>
  <div class="bottom" *ngIf="isAlertTriggeredInLastRun">
    <mat-icon>warning_amber</mat-icon>
    Alert triggered in most recent run data.
  </div>
</div>
