<div class="run-picker">
  <calendar [events]="allEvents"
            [startMonth]="startMonth"
            (onSelectDate)="handleClickDate($event)">
  </calendar>
  <div class="flex-col events-list-container" *ngIf="dateEvents && dateEvents.length > 1">
    <calendar-events [currentDate]="currentDate"
                     [events]="dateEvents"
                     (onClickEvent)="handleClickEvent($event)">
    </calendar-events>
  </div>
</div>
