import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import {
  IAccessibilityTestedElement,
  IAuditPageInfoAccessibilityChecksResults
} from '@app/components/audit-reports/page-details/page-details.models';

@Injectable({ providedIn: 'root' })
export class ExportService {

  downloadAllAccessibilityIssues(
    accessibilityData: IAuditPageInfoAccessibilityChecksResults,
    finalStatusCode: number,
  ): void {
    const d = accessibilityData.issues.map(i => ({ ...i, type: 'conclusive' }))
      .concat(accessibilityData.inconclusive.map(i => ({ ...i, type: 'inconclusive' })));

    const aoaData = [
      ['Initial URL:', accessibilityData.url],
      ['Final URL:', accessibilityData.url],
      ['Final Status Code:', finalStatusCode],
      [],
      [
        'Type',
        'Impact',
        'Standard',
        'Conformance Level',
        'Criteria',
        'Criteria Link',
        'Summary',
        'How to Fix',
        'Selector',
        'Element',
      ],
      ...d.map(i => i.htmlElements.map((e: IAccessibilityTestedElement) => [
        i.type,
        i.impact,
        `WCAG ${i.wcagVersion}`,
        i.wcagConformanceLevel,
        i.successCriteria?.length
          ? i.successCriteria.map(c => `WCAG SC ${c.wcagSuccessCriteria ?? ''}`)
            .join(' \n')
          : '',
        i.successCriteria?.length
          ? i.successCriteria.map(c => c.w3Link)
            .join(' \n')
          : '',
        i.help,
        e.failureSummary,
        e.htmlCssSelectors?.length
          ? (e.htmlCssSelectors ?? []).concat(e.shadowDomCssSelectors?.map(g => g.join(' ')) ?? [])
            .join(' \n')
          : '',
        e.html
      ])).flat()
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(aoaData);

    // Set column widths to prevent content from being cropped
    ws['!cols'] = [
      { wch: 15 }, // Type
      { wch: 10 }, // Impact
      { wch: 15 }, // Standard
      { wch: 20 }, // Conformance Level
      { wch: 30 }, // Criteria
      { wch: 70 }, // Criteria Link
      { wch: 50 }, // Summary
      { wch: 90 }, // How to Fix
      { wch: 50 }, // Selector
      { wch: 90 }  // Element
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Page Accessibility');
    XLSX.writeFile(wb, 'Page Accessibility Checks.xlsx');
  }
}
