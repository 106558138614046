import { Injectable } from '@angular/core';
import { StorageService } from '@app/components/shared/services/storage.service';
import { ReplaySubject } from 'rxjs';
import { DARK_THEME_SELECTED } from './theme.service.constants';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private darkTheme$ = new ReplaySubject<boolean>(1);
  isDarkTheme = this.darkTheme$.asObservable();

  constructor(private storageService: StorageService) {
    const localStorageThemeValue: boolean | null = this.storageService.getValue(DARK_THEME_SELECTED);
    const isDarkTheme: boolean = localStorageThemeValue !== null ? localStorageThemeValue : true;

    setTimeout(() => { this.toggleTheme(isDarkTheme); });
  }

  toggleTheme(isDarkTheme: boolean): void {
    this.darkTheme$.next(isDarkTheme);
    this.saveToLocalStorage(isDarkTheme);

    if (isDarkTheme) {
      document.getElementsByTagName('body')[0].classList.add('dark-theme');
      document.getElementsByTagName('body')[0].classList.remove('light-theme')
    } else {
      document.getElementsByTagName('body')[0].classList.remove('dark-theme');
      document.getElementsByTagName('body')[0].classList.add('light-theme')
    }
  }

  saveToLocalStorage(isDarkTheme: boolean): void {
    this.storageService.setValue(DARK_THEME_SELECTED, isDarkTheme);
  }
}
