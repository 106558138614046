import { Component, Input } from '@angular/core';
import { IEmailInbox } from '@app/components/email-inboxes/email-inboxes.models';

@Component({
  selector: 'op-email-inbox-messages-zero-state',
  templateUrl: './email-inbox-messages-zero-state.component.html',
  styleUrls: ['./email-inbox-messages-zero-state.component.scss']
})
export class EmailInboxMessagesZeroStateComponent {
  @Input() inbox: IEmailInbox = null;
}
