import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ComponentType } from '@angular/cdk/overlay';
import {
  SnackbarSuccessComponent
} from '@app/components/shared/components/snackbars/snackbar-success/snackbar-success.component';
import {
  SnackbarErrorComponent
} from '@app/components/shared/components/snackbars/snackbar-error/snackbar-error.component';
import {
  SnackbarInfoComponent
} from '@app/components/shared/components/snackbars/snackbar-info/snackbar-info.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  openFromComponent<T, D = any>(component: ComponentType<T>, config?: MatSnackBarConfig<D>) {
    return this.snackBar.openFromComponent(component, config);
  }

  openSuccessSnackbar(message: string, config?: MatSnackBarConfig<string>) {
    return this.snackBar.openFromComponent(SnackbarSuccessComponent, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'system-status-snackbar',
      data: {
        message
      },
      ...config
    });
  }

  openErrorSnackbar(message: string, config?: MatSnackBarConfig<string>) {
    return this.snackBar.openFromComponent(SnackbarErrorComponent, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'system-error-status-snackbar',
      data: {
        message
      },
      ...config
    });
  }

  openInfoSnackbar(message: string, config?: MatSnackBarConfig<string>) {
    return this.snackBar.openFromComponent(SnackbarInfoComponent, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'system-status-snackbar',
      data: {
        message
      },
      ...config
    });
  }
}
