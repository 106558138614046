import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import {
  IOpFilterBarV2Filter,
  IOpFilterBarV2MenuItem,
  IOpFilterBarV2MenuItemCommon
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { OpFilterBarV2Service } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { EUsageFilterApiFilters, EUsageFilterTypes, IUsageFrequency, UsageFrequencies } from './usage-filter-bar.enums';
import { EUsageFrequency, IUsageFolderFilterDTO, IUsageRequestDTO } from '@app/components/usage-v2/usage-v2.models';
import { IUser } from '@app/moonbeamModels';
import { EFilterBarV2MenuTypes } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.constants';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import {
  IAuditReportCard,
  IWebJourneyReportCard
} from '@app/components/manage/cards/report-card-list/report-card-list.models';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { Features } from '@app/moonbeamConstants';
import {
  ReportCardsApiService
} from '@app/components/manage/shared/services/report-cards-api/report-cards-api.service';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AccountsService } from '@app/components/account/account.service';

@Injectable()
export class UsageFilterBarService extends OpFilterBarV2Service<EUsageFilterTypes> {

  private domains: IDomain[] = [];
  private folders: IFolder[] = [];
  private labels: ILabel[] = [];
  private audits: IAuditReportCard[] = [];
  private webJourneys: IWebJourneyReportCard[] = [];
  private creators: IUser[] = [];

  private foldersSearchValue = '';
  private folderMenuItems: IOpFilterBarV2MenuItem[];
  private folderSearchTextUpdated: number;
  private folderSubFolderCheckedStatusMap: { [id: number]: boolean } = {};

  private creatorsSearchValue = '';
  private creatorMenuItems: IOpFilterBarV2MenuItem[];
  private creatorSearchTextUpdated: number;
  private creatorCheckedStatusMap: { [id: number]: boolean } = {};

  private labelsSearchValue = '';
  private labelMenuItems: IOpFilterBarV2MenuItem[];
  private labelSearchTextUpdated: number;
  private labelCheckedStatusMap: { [id: number]: boolean } = {};

  private auditsSearchValue = '';
  private auditMenuItems: IOpFilterBarV2MenuItem[] = [];
  private auditSearchTextUpdated: number;
  private auditCheckedStatusMap: { [id: number]: boolean } = {};

  private journeysSearchValue = '';
  private journeyMenuItems: IOpFilterBarV2MenuItem[] = [];
  private journeySearchTextUpdated: number;
  private journeyCheckedStatusMap: { [id: number]: boolean } = {};

  private auditsJourneysPerLabel: { [id: number]: number } = {};
  private auditsJourneysPerOwner: { [id: number]: number } = {};
  private auditsJourneysPerDomain: { [id: number]: number } = {};
  private auditsJourneysPerFrequency: { [id: string]: number } = {};

  private filterBarMenuItems: IOpFilterBarV2MenuItem[] = [];
  private filterBarMenu = new BehaviorSubject<IOpFilterBarV2MenuItem[]>([]);
  filterBarMenuItems$ = this.filterBarMenu.asObservable();

  private domainsToFoldersMap: {
    [folderId: string]: IOpFilterBarV2MenuItem[]
  } = {};

  showItemsInFilters = 10;
  showFoldersAndSubfolders = 50;

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService,
    private foldersService: IFoldersApiService,
    private domainsService: IDomainsService,
    private labelsService: LabelService,
    private accountsService: AccountsService,
    private reportCardsService: ReportCardsApiService,
    private authenticationService: AuthenticationService
  ) {
    super(storage, 'USAGE_V2_FILTERS', cacheReset);
  }

  updateFilterItems(webJourneysEnabled: boolean): Observable<IOpFilterBarV2MenuItem[]> {
    const requests = [
      this.reportCardsService.getAudits(),
      this.foldersService.getFolders(true, true),
      this.domainsService.getDomains(true),
      this.accountsService.getUsers(),
      this.labelsService.getLabels(),
      this.anyFiltersUpdates$.pipe(first())
    ];

    if (webJourneysEnabled) {
      requests.push(this.reportCardsService.getWebJourneys());
    }

    forkJoin(requests)
      .subscribe((
        [audits, folders, domains, creators, labels, filters, webJourneys]:
        [
          audits: IAuditReportCard[],
          folders: IFolder[],
          domains: IDomain[],
          creators: IUser[],
          labels: ILabel[],
          filters: IOpFilterBarV2Filter<EUsageFilterTypes>[],
          webJourneys: IWebJourneyReportCard[]
        ]
      ) => {

        this.folders = folders;
        this.domains = domains;
        this.creators = creators;
        this.audits = audits.sort((a, b) => b.lastRunDate?.getTime() - a.lastRunDate?.getTime());
        this.labels = labels;

        audits.forEach(audit => {
          if (this.auditsJourneysPerDomain[audit.domainId]) {
            this.auditsJourneysPerDomain[audit.domainId]++;
          } else {
            this.auditsJourneysPerDomain[audit.domainId] = 1;
          }

          if (this.auditsJourneysPerFrequency[audit.frequency]) {
            this.auditsJourneysPerFrequency[audit.frequency]++;
          } else {
            this.auditsJourneysPerFrequency[audit.frequency] = 1;
          }

          if (this.auditsJourneysPerOwner[audit.ownerId]) {
            this.auditsJourneysPerOwner[audit.ownerId]++;
          } else {
            this.auditsJourneysPerOwner[audit.ownerId] = 1;
          }
          audit.labels.forEach(label => {
            if (this.auditsJourneysPerLabel[label.id]) {
              this.auditsJourneysPerLabel[label.id]++;
            } else {
              this.auditsJourneysPerLabel[label.id] = 1;
            }
          });
        });

        if (webJourneys) {
          this.webJourneys = webJourneys.sort((a, b) => b.lastRunDate?.getTime() - a.lastRunDate?.getTime());

          webJourneys.forEach(journey => {
            if (this.auditsJourneysPerDomain[journey.domainId]) {
              this.auditsJourneysPerDomain[journey.domainId]++;
            } else {
              this.auditsJourneysPerDomain[journey.domainId] = 1;
            }

            if (this.auditsJourneysPerFrequency[journey.options.frequency]) {
              this.auditsJourneysPerFrequency[journey.options.frequency]++;
            } else {
              this.auditsJourneysPerFrequency[journey.options.frequency] = 1;
            }

            if (this.auditsJourneysPerOwner[journey.ownerId]) {
              this.auditsJourneysPerOwner[journey.ownerId]++;
            } else {
              this.auditsJourneysPerOwner[journey.ownerId] = 1;
            }

            journey.labels.forEach(label => {
              if (this.auditsJourneysPerLabel[label.id]) {
                this.auditsJourneysPerLabel[label.id]++;
              } else {
                this.auditsJourneysPerLabel[label.id] = 1;
              }
            });
          });
        }

        this.initMenu();
        this.addUsageFiltersFromLocalStorage();
      });

    return this.filterBarMenuItems$;
  }

  initMenu() {
    this.createFiltersStatusMaps();
    this.generateMenuItems();
    this.buildMenu();
    this.updateFiltersView();
  }

  get filters$(): Observable<IOpFilterBarV2Filter<EUsageFilterTypes>[]> {
    return this.relevantFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters
          .filter(filter => this.validTypes.includes(filter.type))
          .reduce((acc, curr) => {
            acc.push(curr);
            return acc;
          }, [])
      )
    );
  }

  onMenuClosed(): void {
    this.currentFilters.forEach(filter => {
      if (filter.value.length === 0) this.removeFilterByType(filter.type);
    });
  }

  addFolderFilter(checked: boolean, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false, value: number[], numChecked: number): void {
    this.addFilter(
      {
        type: EUsageFilterTypes.Folder,
        display: `Folders (${numChecked})`,
        value,
        icon: 'folder',
        menuName: 'Folder & Sub-folder',
        menuItems: menuItems,
        order: 2,
        printViewLabel: 'Folder',
      },
      replaceAll,
      numChecked,
      checked
    );
  }

  handleFolderFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false, folders: IFolder[], domains: any, replace = false): void {
    // get count of checked items
    const numChecked = this.getFoldersNumChecked(menuItems);

    if (numChecked) {
      const folderIds = folders.map(folder => folder.id);

      // When using the 'only' button, clear out the existing filter values before adding the new selection
      if (replaceAll) {
        this.addFolderFilter(checked, menuItems, replaceAll, [], numChecked);
        replaceAll = false;
      }

      // If user clicked a Folder checkbox
      if (folderIds.includes(item.id)) {
        // If checking a folder, add all subfolder ids to values array if not already there
        if (checked) {
          domains[item.id]?.forEach(domain => {
            this.addFolderFilter(checked, menuItems, replaceAll, [domain.id], numChecked);
          });

          // If unchecking a folder, remove all subfolder ids from values array
        } else {
          // Deselect all domains in the deselected folder
          domains[item.id]?.forEach(domain => {
            this.addFolderFilter(checked, menuItems, replaceAll, [domain.id], numChecked);
          });
        }
      } else {
        // Add subfolder
        this.addFolderFilter(checked, menuItems, replaceAll, [item.id], numChecked);
      }

      item.checked = checked;
    } else {
      this.removeFilterByType(EUsageFilterTypes.Folder);
    }

  }

  handleLabelFilter(checked: boolean, label: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false): void {
    label.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    if (numChecked) {
      this.addFilter(
        {
          type: EUsageFilterTypes.Label,
          display: `Labels (${numChecked})`,
          value: [label.id],
          menuName: 'Label',
          menuItems: menuItems,
          icon: 'label',
          order: 3,
          printViewLabel: 'Label',
        },
        replaceAll,
        numChecked,
        checked
      );
    } else {
      this.removeFilterByType(EUsageFilterTypes.Label);
    }

  }

  handleWebJourneyFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false): void {
    item.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    if (numChecked) {
      this.addFilter(
        {
          type: EUsageFilterTypes.WebJourney,
          display: `WebJourneys (${numChecked})`,
          value: [item.id],
          icon: 'map',
          menuName: EUsageFilterTypes.WebJourney,
          menuItems: menuItems,
          order: 2,
          printViewLabel: 'Web Journey',
        },
        replaceAll,
        numChecked,
        checked);
    } else {
      this.removeFilterByType(EUsageFilterTypes.WebJourney);
    }
  }

  handleAuditFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false): void {
    item.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    if (numChecked) {
      this.addFilter(
        {
          type: EUsageFilterTypes.Audits,
          display: `Audits (${numChecked})`,
          value: [item.id],
          icon: 'explore',
          menuName: EUsageFilterTypes.Audits,
          menuItems: menuItems,
          order: 2,
          printViewLabel: 'Audit',
        },
        replaceAll,
        numChecked,
        checked);
    } else {
      this.removeFilterByType(EUsageFilterTypes.Audits);
    }

  }

  handleDataSourceFrequencyFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], value: EUsageFrequency, replaceAll: boolean = false): void {
    item.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    if (numChecked) {
      this.addFilter(
        {
          type: EUsageFilterTypes.Frequency,
          display: `Data Source Run Frequency (${numChecked})`,
          value: [value],
          icon: 'calendar_month',
          menuName: 'Data Source Run Frequency',
          menuItems: menuItems,
          order: 3,
          printViewLabel: 'Data Source Run Frequency',
        },
        replaceAll,
        numChecked,
        checked
      );
    } else {
      this.removeFilterByType(EUsageFilterTypes.Frequency);
    }

  }

  handleCreatorFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false): void {
    item.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    if (numChecked) {
      this.addFilter(
        {
          type: EUsageFilterTypes.Creator,
          display: `Creator (${numChecked})`,
          value: [item.id],
          menuName: EUsageFilterTypes.Creator,
          menuItems: menuItems,
          icon: 'person',
          order: 8,
          printViewLabel: 'Creator',
        },
        replaceAll,
        numChecked,
        checked
      );
    } else {
      this.removeFilterByType(EUsageFilterTypes.Creator);
    }

  }

  getFoldersNumChecked(menuItems: IOpFilterBarV2MenuItem[]): number {
    let count = 0;

    menuItems.forEach(item => {
      if (item.type === EFilterBarV2MenuTypes.Checkbox && !item.duplicated && item.checked) count++;
      if (item.children?.length) {
        item.children.forEach(child => {
          if (child.checked && !item.duplicated) count++;
        });
      }
    });

    return count;
  }

  getNumChecked(menuItems: IOpFilterBarV2MenuItem[]): number {
    let count = 0;

    menuItems.forEach(item => {
      if (!item.duplicated && item.checked && !item.children) count++;
      if (item.children?.length) {
        item.children.forEach(child => {
          if (child.checked) count++;
        });
      }
    });

    return count;
  }

  generateApiPostBody(filters: IOpFilterBarV2Filter<EUsageFilterTypes>[]): IUsageRequestDTO {
    const dto: IUsageRequestDTO = {} as IUsageRequestDTO;

    filters.forEach(f => {
      switch (f.type) {
        case EUsageFilterTypes.Audits:
          if (f.value.length) {
            dto.dataSources = f.value;
          }

          break;
        case EUsageFilterTypes.WebJourney:
          if (f.value.length) {
            dto.dataSources = f.value;
          }

          break;
        case EUsageFilterTypes.Creator: {
          if (f.value.length) {
            dto.dataSourceCreators = f.value;
          }

          break;
        }
        case EUsageFilterTypes.Folder:
          if (f.value.length) {
            dto.dataSourceFolders = this.folderFilterToRequestDTO(f);
          }

          break;
        case EUsageFilterTypes.Label:
          if (f.value.length) {
            dto.dataSourceLabels = f.value;
          }

          break;
        case EUsageFilterTypes.Frequency:
          if (f.value.length) {
            dto.runFrequencies = f.value;
          }

          break;
        default:
          console.error('Unknown filter type: ', f);
      }
    });

    return dto;
  }

  private folderFilterToRequestDTO(filter: IOpFilterBarV2Filter<EUsageFilterTypes>): IUsageFolderFilterDTO[] {
    const menuItems = filter.menuItems;
    return menuItems
      .filter(mi => !mi.duplicated && (mi.checked || mi.children?.find(c => c.checked)))
      .map(mi => {
        if (mi.checked) {
          return { folderId: mi.id, domains: null }; //"domains: null" means that all domains are checked
        } else {
          const domains = mi.children?.length
            ? mi.children.filter(c => c.checked).map(c => c.id)
            : [];
          return { folderId: mi.id, domains };
        }
      });
  }

  generateCreatorMenuItems(): IOpFilterBarV2MenuItem[] {
    return this.creators.map((creator: IUser, idx: number) => {
      let creatorMenuItem: IOpFilterBarV2MenuItem = {
        name: `${creator.firstName || ''} ${creator.lastName || ''} ${creator.email}`,
        menuItemNameTemplate: `<strong>${creator.firstName || ''} ${creator.lastName || ''}</strong> (${creator.email}) (${this.auditsJourneysPerOwner[creator.id] || 0})`,
        id: creator.id,
        type: EFilterBarV2MenuTypes.Checkbox,
        checked: this.getCheckedStateById(this.creatorCheckedStatusMap, creator.id),
        displayWhen: this.getCheckedStateById(this.creatorCheckedStatusMap, creator.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleCreatorFilter(checked, item, menuItems),
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.handleCreatorFilter(item.checked, item, menuItems, true);
          this.updateFiltersList(this.creatorMenuItems, this.creatorsSearchValue, this.filterBarMenuItems[3]);
        }
      };

      if (creator.email) creatorMenuItem.name = creatorMenuItem.name + ` (${creator.email})`;

      return creatorMenuItem;
    });
  }

  generateLabelMenuItems(): IOpFilterBarV2MenuItem[] {
    return this.labels?.map((label: ILabel, idx: number) => {
      return {
        id: label.id,
        name: label.name,
        menuItemNameTemplate: `<strong>${label.name}</strong> (${this.auditsJourneysPerLabel[label.id] || 0})`,
        checked: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
        type: EFilterBarV2MenuTypes.Checkbox,
        displayWhen: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleLabelFilter(checked, item, menuItems),
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.handleLabelFilter(item.checked, item, menuItems, true);
          this.updateFiltersList(this.labelMenuItems, this.labelsSearchValue, this.filterBarMenuItems[2]);
        }
      };
    });
  }

  generateAuditsMenuItems(): IOpFilterBarV2MenuItem[] {
    return this.audits.map((audit: IAuditReportCard, idx: number) => {
      return {
        id: audit.id,
        name: audit.name,
        menuItemNameTemplate: `<strong>${audit.name}</strong>`,
        checked: this.getCheckedStateById(this.auditCheckedStatusMap, audit.id),
        type: EFilterBarV2MenuTypes.Checkbox,
        displayWhen: false,
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleAuditFilter(checked, item, menuItems),
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.handleAuditFilter(item.checked, item, menuItems, true);
          this.updateFiltersList(this.auditMenuItems, this.auditsSearchValue, this.filterBarMenuItems[0].children[0]);
        }
      };
    });
  }

  generateJourneysMenuItems(): IOpFilterBarV2MenuItem[] {
    if (!this.webJourneys) return [];
    return this.webJourneys.map((journey: IWebJourneyReportCard, idx: number) => {
      return {
        id: journey.id,
        name: journey.name,
        menuItemNameTemplate: `<strong>${journey.name}</strong>`,
        checked: this.getCheckedStateById(this.journeyCheckedStatusMap, journey.id),
        type: EFilterBarV2MenuTypes.Checkbox,
        displayWhen: false,
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleJourneyFilter(checked, item, menuItems),
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.handleWebJourneyFilter(item.checked, item, menuItems, true);
          this.updateFiltersList(this.journeyMenuItems, this.journeysSearchValue, this.filterBarMenuItems[0].children[1]);
        }
      };
    });
  }

  private generateDomainsAndFoldersMenuItems(): IOpFilterBarV2MenuItem[] {
    this.domainsToFoldersMap = {};

    this.domains.forEach((domain: IDomain, idx: number) => {
      if (!this.domainsToFoldersMap[domain.folderId]) {
        this.domainsToFoldersMap[domain.folderId] = [];
      }

      this.domainsToFoldersMap[domain.folderId].push({
        id: domain.id,
        folderId: domain.folderId,
        name: domain.name,
        menuItemNameTemplate: `<strong>${domain.name}</strong> (${this.auditsJourneysPerDomain[domain.id] || 0})`,
        type: EFilterBarV2MenuTypes.Checkbox,
        hideSelectedOnSearch: false,
        checked: this.getCheckedStateById(this.folderSubFolderCheckedStatusMap, domain.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, parent: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleDomainFilter(checked, item, menuItems),
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.handleFolderFilter(item.checked, item, menuItems, true, this.folders, this.domainsToFoldersMap);
          this.updateFolderFilter();
        }
      } as IOpFilterBarV2MenuItemCommon);
    });

    return this.folders
      .map((folder: IFolder, idx: number) => {
        return {
          id: folder.id,
          name: folder.name,
          menuItemNameTemplate: `<strong>${folder.name}</strong> (${this.domainsToFoldersMap[folder.id]?.length || 0})`,
          type: EFilterBarV2MenuTypes.Checkbox,
          checked: this.getCheckedStateById(this.folderSubFolderCheckedStatusMap, folder.id),
          hideSelectedOnSearch: false,
          children: this.domainsToFoldersMap[folder.id] || [],
          displayWhen: !!this.domainsToFoldersMap[folder.id]?.length,
          action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleFolderFilter(checked, item, menuItems),
          onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
            this.handleOnlyButtons(item, menuItems, parent);
            this.handleFolderFilter(item.checked, item, menuItems, true, this.folders, this.domainsToFoldersMap);
            this.updateFolderFilter();
          }
        };
      })
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
  }

  private getCheckedStateById(mapName: { [id: number]: boolean }, itemId: number): boolean {
    return mapName[itemId] !== undefined ? mapName[itemId] : false;
  }

  deselectAllMenuItems(arr: IOpFilterBarV2MenuItem[]): void {
    arr.forEach((menuItem: IOpFilterBarV2MenuItem) => {
      menuItem.checked = false;

      if (menuItem.children?.length) {
        this.deselectAllMenuItems(menuItem.children);
      }
    });
  }

  updateFiltersView() {
    this.updateFiltersList(this.auditMenuItems, this.auditsSearchValue, this.filterBarMenuItems[0].children[0]);
    this.updateFolderFilter();
    this.updateFiltersList(this.journeyMenuItems, this.journeysSearchValue, this.filterBarMenuItems[0].children[1]);
    this.updateFiltersList(this.labelMenuItems, this.labelsSearchValue, this.filterBarMenuItems[2]);
    this.updateFiltersList(this.creatorMenuItems, this.creatorsSearchValue, this.filterBarMenuItems[3]);
  }

  handleCreatorSearch(event: KeyboardEvent, index: number, el?: HTMLElement): void {
    this.creatorSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.creatorSearchTextUpdated + 200) {
      el.focus();
      return;
    }

    this.creatorsSearchValue = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.updateFiltersList(this.creatorMenuItems, this.creatorsSearchValue, this.filterBarMenuItems[3]);
  }

  handleLabelSearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.labelSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.labelSearchTextUpdated + 200) {
      try {
        el.focus();
      } catch (e) {}

      return;
    }

    // filter labels that include search value
    this.labelsSearchValue = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.updateFiltersList(this.labelMenuItems, this.labelsSearchValue, this.filterBarMenuItems[2]);
  }

  handleAuditSearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.auditSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.auditSearchTextUpdated + 200) {
      try {
        el.focus();
      } catch (e) {}

      return;
    }

    // filter labels that include search value
    this.auditsSearchValue = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.updateFiltersList(this.auditMenuItems, this.auditsSearchValue, this.filterBarMenuItems[0].children[0]);
  }

  handleJourneySearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.journeySearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.journeySearchTextUpdated + 200) {
      try {
        el.focus();
      } catch (e) {}

      return;
    }

    // filter labels that include search value
    this.journeysSearchValue = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.updateFiltersList(this.journeyMenuItems, this.journeysSearchValue, this.filterBarMenuItems[0].children[1]);
  }

  updateFiltersList(allItems: IOpFilterBarV2MenuItem[], searchValue: string, menu: IOpFilterBarV2MenuItem) {
    const rawItems = allItems.map(v => v);
    rawItems.sort((a, b) => a.name.localeCompare(b.name));

    const filtered = rawItems.filter(item => item.checked);
    const duplicated = filtered.map(item => {
      return {
        ...item,
        duplicated: true,
        displayWhen: true
      } as IOpFilterBarV2MenuItem;
    });

    let uncheckedShown = 0;
    rawItems.forEach((item: IOpFilterBarV2MenuItem) => {
      if (uncheckedShown < this.showItemsInFilters && item.name.toLowerCase().includes(searchValue.toLowerCase())) {
        uncheckedShown++;
        item.displayWhen = true;
      } else {
        item.displayWhen = false;
      }
    });

    menu.children[0].additionalInfo = duplicated.length + ' SELECTED ITEMS';
    menu.children.length = 1;

    if (!searchValue) {
      const items = duplicated;

      if (duplicated.length) {
        items.push({
          type: EFilterBarV2MenuTypes.Divider,
          name: '',
          additionalInfo: allItems.length + ' ITEMS',
        });
      }

      items.push(...rawItems);

      menu.children.push(...items);
    } else {
      menu.children[0].additionalInfo = allItems.length + ' ITEMS';
      menu.children.push(...rawItems);
    }
  }

  handleFolderAndSubfolderSearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.folderSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.folderSearchTextUpdated + 200) {
      el.focus();
      return;
    }

    this.foldersSearchValue = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

    this.updateFolderFilter();
  }

  private updateFolderFilter() {
    this.folderMenuItems.forEach(folder => {
      folder.displayWhen = true;

      folder.children.forEach(domain => domain.displayWhen = true);
    });

    const duplicatedFolders = this.folderMenuItems.map(folder => {
      return {
        ...folder,
        duplicated: true
      };
    }).filter(folder => {
      const checkedSubFolders = folder.children.filter(subFolder => subFolder.checked).map(subFolder => {
        return {
          ...subFolder,
          duplicated: true
        };
      });

      folder.indeterminate = folder.children.length && checkedSubFolders.length !== folder.children.length;
      folder.children = checkedSubFolders;
      return checkedSubFolders.length;
    });

    let selectedCounter = 0;

    duplicatedFolders.forEach(f => {
      selectedCounter += f.children.length + (f.checked ? 1 : 0);
    });

    const rawFolders = this.folderMenuItems.map((folder: IOpFilterBarV2MenuItem) => {
      folder.children.forEach((subFolder: IOpFilterBarV2MenuItem) => {
        subFolder.displayWhen = this.foldersSearchValue ? subFolder.name.toLowerCase().includes(this.foldersSearchValue) : true;
      });

      const folderNameMatchesSearchValue = folder.name.toLowerCase().includes(this.foldersSearchValue);
      const folderHasVisibleSubfolder = folder.children.map(domain => domain.displayWhen).filter(domain => domain);
      const folderHasVisibleChecked = folder.children.filter(domain => domain.checked);
      const folderHasSubfolders = !!folder.children.length;

      folder.indeterminate = !!folderHasVisibleChecked.length && folderHasVisibleChecked.length !== folder.children.length;

      folder.displayWhen = this.foldersSearchValue
        ? folderNameMatchesSearchValue || !!folderHasVisibleSubfolder.length
        : folderHasSubfolders;

      return folder;
    });

    let rawCount = 0;
    rawFolders.forEach(f => {
      f.children?.forEach(() => rawCount++);
      rawCount++;
    });

    const items = [];
    if (!this.foldersSearchValue) {

      this.filterBarMenuItems[1].children[0].additionalInfo = selectedCounter + ' SELECTED ITEM(S)';

      items.push(...duplicatedFolders);

      this.addHiddenItemsNote(duplicatedFolders, items);

      if (items.length) {
        items.push({
          type: EFilterBarV2MenuTypes.Divider,
          name: '',
          additionalInfo: rawCount + ' ITEMS',
        });
      }

      items.push(...rawFolders);
      this.addHiddenItemsNote(rawFolders, items, true);

    } else {
      this.filterBarMenuItems[1].children[0].additionalInfo = rawCount + ' ITEM(S)';
      items.push(...rawFolders);
      this.addHiddenItemsNote(rawFolders, items, true);
    }

    this.filterBarMenuItems[1].children.length = 1;
    this.filterBarMenuItems[1].children.push(...items);
  }

  private addHiddenItemsNote(folders: IOpFilterBarV2MenuItem[], items: IOpFilterBarV2MenuItem[], displayedOnly = false) {
    let displayed = 0;
    let totalDomains = 0;
    let shownFolders = 0;
    let shownDomains = 0;

    folders.forEach(folder => {
      if ((displayedOnly ? folder.displayWhen : true) && displayed < this.showFoldersAndSubfolders) {
        displayed++;
        shownFolders++;
        folder.displayWhen = true;
      } else {
        folder.displayWhen = false;
      }

      folder.children.forEach(domain => {
        if ((displayedOnly ? folder.displayWhen : true) && displayed < this.showFoldersAndSubfolders) {
          displayed++;
          shownDomains++;
          domain.displayWhen = true;
        } else {
          domain.displayWhen = false;
        }

        totalDomains++;
      });
    });

    if (folders.length > shownFolders) {

      if (totalDomains > shownDomains) {
        items.push({
          type: EFilterBarV2MenuTypes.Note,
          name: folders.length - shownFolders + ' FOLDERS AND ' + (totalDomains - shownDomains)  + ' DOMAINS ARE HIDDEN'
        });
      } else {
        items.push({
          type: EFilterBarV2MenuTypes.Note,
          name: folders.length - shownFolders + ' FOLDERS ARE HIDDEN'
        });
      }

    } else {
      if (totalDomains > shownDomains) {
        items.push({
          type: EFilterBarV2MenuTypes.Note,
          name: (totalDomains - shownDomains)  + ' DOMAINS ARE HIDDEN'
        });
      }
    }
  }

  handleOnlyButtons(item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem): void {
    // deselect everything
    this.deselectAllMenuItems(menuItems);

    // set checked = true to item
    item.checked = true;

    // handle child elements if they exist
    if (item.children?.length) {
      item.children.forEach((child: IOpFilterBarV2MenuItem) => child.checked = true);
    }
  }

  private toggleAuditFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replace = false) {
    this.auditMenuItems.forEach(menuItem => {
      if (item.id === menuItem.id) {
        menuItem.checked = checked;
      }
    });

    this.handleAuditFilter(checked, item, menuItems, replace);
    this.updateFiltersList(this.auditMenuItems, this.auditsSearchValue, this.filterBarMenuItems[0].children[0]);
  }

  private toggleJourneyFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replace = false) {
    this.journeyMenuItems.forEach(menuItem => {
      if (item.id === menuItem.id) {
        menuItem.checked = checked;
      }
    });
    this.handleWebJourneyFilter(checked, item, menuItems, replace);
    this.updateFiltersList(this.journeyMenuItems, this.journeysSearchValue, this.filterBarMenuItems[0].children[1]);
  }

  private toggleLabelFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replace = false) {
    this.labelMenuItems.forEach(menuItem => {
      if (item.id === menuItem.id) {
        menuItem.checked = checked;
      }
    });
    this.handleLabelFilter(checked, item, menuItems, replace);
    this.updateFiltersList(this.labelMenuItems, this.labelsSearchValue, this.filterBarMenuItems[2]);
  }

  private toggleCreatorFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replace = false) {
    this.creatorMenuItems.forEach(menuItem => {
      if (item.id === menuItem.id) {
        menuItem.checked = checked;
      }
    });
    this.handleCreatorFilter(checked, item, menuItems, replace);
    this.updateFiltersList(this.creatorMenuItems, this.creatorsSearchValue, this.filterBarMenuItems[3]);
  }

  private toggleDomainFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replace = false) {

    this.folderMenuItems.forEach(folder => {
      if (folder.id === item.folderId) {
        folder.children.forEach(domain => {
          if (domain.id === item.id) {
            domain.checked = checked;
          }
        });

        const checkedDomains = folder.children.filter(domain => domain.checked);

        if (checkedDomains.length === folder.children.length) {
          folder.checked = true;
        } else {
          folder.checked = false;
        }
      }
    });

    this.handleFolderFilter(checked, item, menuItems, false, this.folders, this.domainsToFoldersMap, replace);
    this.updateFolderFilter();
  }

  private toggleFolderFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) {
    this.folderMenuItems.forEach(folder => {

      if (folder.id === item.id) {
        checked = !folder.checked;
        item.children.forEach(domain => domain.checked = checked);
        folder.children.forEach(domain => {
          domain.checked = checked;
        });

        folder.checked = checked;
      }

    });
    this.handleFolderFilter(checked, item, menuItems, false, this.folders, this.domainsToFoldersMap);
    this.updateFolderFilter();
  }
  addFiltersFromLocalStorage() {}

  addUsageFiltersFromLocalStorage() {
    this.filterBarMenuItems$.subscribe(items => {
      Object.entries(this.generateApiPostBody(this.filters)).forEach(([key, value]) => {
        this.addFilterByApiPostBody(key as EUsageFilterApiFilters, value);
      });
    });
  }

  addFilterByApiPostBody(key: EUsageFilterApiFilters, value, replace = false) {
    switch (key) {
      case EUsageFilterApiFilters.DataSources: {
        value.forEach(datasourceId => {
          const audit = this.audits?.find(a => a.id === datasourceId);
          const journey = this.webJourneys?.find(j => j.id === datasourceId);

          if (audit) {
            this.toggleAuditFilter(true, {
                id: audit.id,
                name: audit.name,
                menuItemNameTemplate: `<strong>${audit.name}</strong>`,
                checked: this.getCheckedStateById(this.auditCheckedStatusMap, audit.id),
                type: EFilterBarV2MenuTypes.Checkbox,
                displayWhen: false,
                action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleAuditFilter(checked, item, menuItems),
                onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
                  this.handleOnlyButtons(item, menuItems, parent);
                  this.handleAuditFilter(item.checked, item, menuItems, true);
                  this.updateFiltersList(this.auditMenuItems, this.auditsSearchValue, this.filterBarMenuItems[0].children[0]);
                }
              },
              this.filterBarMenuItems[0].children[0].children, replace);
          } else if (journey) {
            this.toggleJourneyFilter(true, {
                id: journey.id,
                name: journey.name,
                menuItemNameTemplate: `<strong>${journey.name}</strong>`,
                checked: this.getCheckedStateById(this.auditCheckedStatusMap, journey.id),
                type: EFilterBarV2MenuTypes.Checkbox,
                displayWhen: false,
                action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleJourneyFilter(checked, item, menuItems),
                onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
                  this.handleOnlyButtons(item, menuItems, parent);
                  this.handleWebJourneyFilter(item.checked, item, menuItems, true);
                  this.updateFiltersList(this.auditMenuItems, this.auditsSearchValue, this.filterBarMenuItems[0].children[1]);
                }
              },
              this.filterBarMenuItems[0].children[1].children, replace);
          }

        });

        break;
      }

      case EUsageFilterApiFilters.DataSourceCreators: {
        value.forEach(creatorId => {
          const creator = this.creators?.find(c => c.id === creatorId);

          if (creator) {
            let creatorMenuItem: IOpFilterBarV2MenuItem = {
              name: `${creator.firstName || ''} ${creator.lastName || ''} ${creator.email}`,
              menuItemNameTemplate: `<strong>${creator.firstName || ''} ${creator.lastName || ''}</strong> (${creator.email}) (${this.auditsJourneysPerOwner[creator.id] || 0})`,
              id: creator.id,
              type: EFilterBarV2MenuTypes.Checkbox,
              checked: this.getCheckedStateById(this.creatorCheckedStatusMap, creator.id),
              displayWhen: this.getCheckedStateById(this.creatorCheckedStatusMap, creator.id),
              action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleCreatorFilter(checked, item, menuItems),
              onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
                this.handleOnlyButtons(item, menuItems, parent);
                this.handleCreatorFilter(item.checked, item, menuItems, true);
                this.updateFiltersList(this.creatorMenuItems, this.creatorsSearchValue, this.filterBarMenuItems[3]);
              }
            };

            if (creator.email) creatorMenuItem.name = creatorMenuItem.name + ` (${creator.email})`;

            this.toggleCreatorFilter(true, creatorMenuItem, this.filterBarMenuItems[3].children, replace);
          }
        });

        break;
      }

      case EUsageFilterApiFilters.DataSourceLabels: {
        value.forEach(labelId => {
          const label = this.labels?.find(l => l.id === labelId);

          if (label) {
            this.toggleLabelFilter(true, {
              id: label.id,
              name: label.name,
              menuItemNameTemplate: `<strong>${label.name}</strong> (${this.auditsJourneysPerLabel[label.id] || 0})`,
              checked: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
              type: EFilterBarV2MenuTypes.Checkbox,
              displayWhen: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
              action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.toggleLabelFilter(checked, item, menuItems),
              onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
                this.handleOnlyButtons(item, menuItems, parent);
                this.handleLabelFilter(item.checked, item, menuItems, true);
                this.updateFiltersList(this.labelMenuItems, this.labelsSearchValue, this.filterBarMenuItems[2]);
              }
            }, this.filterBarMenuItems[2].children, replace);
          }
        });

        break;
      }

      case EUsageFilterApiFilters.RunFrequencies: {
        value.forEach(value => {
          const frequency = Object.values(UsageFrequencies).find(f => f.id == value);

          if (frequency) {
            this.addFrequencyFilter(true, this.filterBarMenuItems[4].children.find(item => item.name === frequency.name), this.filterBarMenuItems[4].children, frequency);
          }
        });

        break;
      }

      case EUsageFilterApiFilters.DataSourceFolders: {
        value.forEach(item => {

          if (item) {
            if (item.domains) {
              item.domains.forEach(domainId => {
                const menuItem = this.domainsToFoldersMap[item.folderId]?.find(domainItem => domainItem.id === domainId);
                if (menuItem) {
                  this.toggleDomainFilter(true, menuItem, this.filterBarMenuItems[1].children, replace);
                }
              });
            } else {
              this.domainsToFoldersMap[item.folderId]?.forEach(domainItem => this.toggleDomainFilter(true, domainItem, this.filterBarMenuItems[1].children));
            }
          }
        });

      }

    }
  }

  overrideFilters(filters: IUsageRequestDTO) {
    this.filterBarMenuItems$.pipe(filter(v => !!v.length), first()).subscribe(() => {
      this.clear();
      console.log('overrideFilters', filters);
      Object.entries(filters).forEach(([key, value]) => {
        this.addFilterByApiPostBody(key as EUsageFilterApiFilters, value);
      });
    });
  }

  private addFrequencyFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], f: IUsageFrequency) {
    this.handleDataSourceFrequencyFilter(checked, item, menuItems, f.id);
  }

  private makeFrequencyFilterOption(f: IUsageFrequency): IOpFilterBarV2MenuItem {
    return {
      name: f.name,
      menuItemNameTemplate: `<strong>${f?.displayValue || f.name}</strong> (${this.auditsJourneysPerFrequency[f.id] || 0})`,
      type: EFilterBarV2MenuTypes.Checkbox,
      checked: this.getCheckedStateByName('Run Frequency', f.name),
      onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
        this.handleOnlyButtons(item, menuItems, parent);
        this.handleDataSourceFrequencyFilter(true, item, menuItems, f.id, true);
      },
      action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => this.addFrequencyFilter(checked, item, menuItems, f)
    };
  }

  buildMenu() {
    this.filterBarMenuItems = [
      {
        name: 'Data Source Name',
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'library_books',
        children: [
          {
            name: `Audits (${this.audits.length})`,
            type: EFilterBarV2MenuTypes.Flyout,
            children: [
              {
                name: 'search',
                type: EFilterBarV2MenuTypes.Search,
                searchPlaceholder: 'Search by Audits',
                searchAllowsMultipleSelections: true,
                action: (event: KeyboardEvent, el?: HTMLElement) => this.handleAuditSearch(event, el),
                additionalInfo: this.auditMenuItems.length + ' SELECTED ITEM(S)',
              } as IOpFilterBarV2MenuItem,
              ...this.auditMenuItems
            ]
          } as IOpFilterBarV2MenuItem,
          {
            name: `Journeys (${this.webJourneys.length})`,
            type: EFilterBarV2MenuTypes.Flyout,
            children: [
              {
                name: 'search',
                type: EFilterBarV2MenuTypes.Search,
                searchPlaceholder: 'Search by Web Journeys',
                searchAllowsMultipleSelections: true,
                additionalInfo: this.journeyMenuItems.length + ' ITEM(S)',
                action: (event: KeyboardEvent, el?: HTMLElement) => this.handleJourneySearch(event, el),
              } as IOpFilterBarV2MenuItem,
              ...this.journeyMenuItems
            ]
          } as IOpFilterBarV2MenuItem
        ]
      },
      {
        name: 'Folder & Sub-folder',
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'folder',
        children: [
          {
            name: 'search',
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search by folder or sub-folder',
            searchAllowsMultipleSelections: true,
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleFolderAndSubfolderSearch(event, el),
            additionalInfo: this.folderMenuItems.length + ' ITEM(S)'
          } as IOpFilterBarV2MenuItem,
          ...this.folderMenuItems
        ]
      },
      {
        name: 'Labels',
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'label',
        children: [
          {
            name: 'Labels',
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search by label name',
            searchAllowsMultipleSelections: true,
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleLabelSearch(event, el),
            additionalInfo: this.labelMenuItems.length + ' SELECTED ITEM(S)'
          } as IOpFilterBarV2MenuItem,
          ...this.labelMenuItems
        ]
      },
      {
        name: 'Data Source Creator',
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'person',
        children: [
          {
            name: 'Data Source Creator',
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search by creator name',
            searchAllowsMultipleSelections: true,
            unremovable: true,
            additionalInfo: this.creatorMenuItems.length + ' SELECTED ITEM(S)',
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleCreatorSearch(event, 5, el)
          } as IOpFilterBarV2MenuItem,
          ...this.creatorMenuItems
        ]
      },
      {
        name: 'Run Frequency',
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'repeat_one',
        children: [
          this.makeFrequencyFilterOption(UsageFrequencies.NotScheduled),
          this.makeFrequencyFilterOption(UsageFrequencies.Once),
          this.makeFrequencyFilterOption(UsageFrequencies.Every15Minutes),
          this.makeFrequencyFilterOption(UsageFrequencies.Hourly),
          this.makeFrequencyFilterOption(UsageFrequencies.Every6Hours),
          this.makeFrequencyFilterOption(UsageFrequencies.Every12Hours),
          this.makeFrequencyFilterOption(UsageFrequencies.Daily),
          this.makeFrequencyFilterOption(UsageFrequencies.Weekly),
          this.makeFrequencyFilterOption(UsageFrequencies.Biweekly),
          this.makeFrequencyFilterOption(UsageFrequencies.Monthly),
          this.makeFrequencyFilterOption(UsageFrequencies.Quarterly),
          this.makeFrequencyFilterOption(UsageFrequencies.SemiAnnually),
          this.makeFrequencyFilterOption(UsageFrequencies.Yearly)
        ]
      }
    ];

    this.filterBarMenu.next(this.filterBarMenuItems);
  }

  getCheckedStateByName(parentMenuName: string, childMenuName: string): boolean {
    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.display?.includes(parentMenuName));
    if (!filter) return false;

    let menuItem = filter.menuItems?.find(item => item.name?.includes(childMenuName));
    return menuItem ? menuItem.checked : false;
  }

  createFolderCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.folderSubFolderCheckedStatusMap = {};
      return;
    }

    this.filters.forEach((filter: IOpFilterBarV2Filter<string>) => {
      if (filter.type !== EUsageFilterTypes.Folder) return;

      if (filter.menuItems) {
        // filter.menuItems is the folder
        // menuItems.children is the subfolder (domain)
        filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
          if (item.type !== EFilterBarV2MenuTypes.Checkbox) return;
          this.folderSubFolderCheckedStatusMap[item.id] = item.checked;

          if (item.children?.length) {
            item.children.forEach((child: IOpFilterBarV2MenuItem) => {
              this.folderSubFolderCheckedStatusMap[child.id] = child.checked;
            });
          }
        });
      }
    });
  }

  createLabelCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.labelCheckedStatusMap = {};
      return;
    }

    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.type === EUsageFilterTypes.Label);

    if (!filter) {
      this.labelCheckedStatusMap = {};
      return;
    }

    filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
      this.labelCheckedStatusMap[item.id] = item.checked;
    });
  }

  createCreatorCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.creatorCheckedStatusMap = {};
      return;
    }

    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.type === EUsageFilterTypes.Creator);

    if (!filter) {
      this.creatorCheckedStatusMap = {};
      return;
    }

    filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
      this.creatorCheckedStatusMap[item.id] = item.checked;
    });
  }

  createAuditCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.auditCheckedStatusMap = {};
      return;
    }

    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.type === EUsageFilterTypes.Audits);

    if (!filter) {
      this.auditCheckedStatusMap = {};
      return;
    }

    filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
      this.auditCheckedStatusMap[item.id] = item.checked;
    });
  }

  createJourneyCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.journeyCheckedStatusMap = {};
      return;
    }

    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.type === EUsageFilterTypes.WebJourney);

    if (!filter) {
      this.journeyCheckedStatusMap = {};
      return;
    }

    filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
      this.journeyCheckedStatusMap[item.id] = item.checked;
    });
  }

  createFiltersStatusMaps() {
    this.createFolderCheckedStatusMap();
    this.createLabelCheckedStatusMap();
    this.createCreatorCheckedStatusMap();
    this.createAuditCheckedStatusMap();
    this.createJourneyCheckedStatusMap();
  }

  generateMenuItems(): void {
    this.labelMenuItems = this.generateLabelMenuItems();
    this.creatorMenuItems = this.generateCreatorMenuItems();
    this.auditMenuItems = this.generateAuditsMenuItems();
    this.journeyMenuItems = this.generateJourneysMenuItems();
    this.folderMenuItems = this.generateDomainsAndFoldersMenuItems();
  }
}
