import { Component, Input } from '@angular/core';
import { IUsersUsage } from '@app/components/usage-v2/usage-v2.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-users-widget',
  templateUrl: './usage-users-widget.component.html',
  styleUrls: ['./usage-users-widget.component.scss']
})
export class UsageUsersWidgetComponent {
  @Input() usersUsage: IUsersUsage;

}
