<div class="web-journey-report-info-container">
  <div class="top-section">
    <h2 class="heading">Journey Run Information</h2>

    <!-- rule failure (red) template -->
    <div *ngIf="failedRulesData" class="rule-failure highlight-box">
      <div class="description-icon-wrap">
        <mat-icon>warning</mat-icon>
        <div class="description">
          Journey has {{ failedRulesData.totalFailedRulesCount }} rule failure{{ failedRulesData.totalFailedRulesCount > 1 ? 's' : '' }}:
        </div>
      </div>
      <ul class="bulleted-list">
        <li *ngFor="let action of failedRulesData.failedActionRules" (click)="jumpToAction.emit(action.actionIndex)">
          <div class="flex-container">
            {{ action.failedCount }} rule{{ action.failedCount > 1 ? 's' : '' }} failed
            on action #{{ action.actionIndex }} <ng-container *ngIf="action.actionName">({{ action.actionName }})</ng-container>
            <mat-icon class="arrow-icon">arrow_forward</mat-icon>
          </div>
        </li>
        <li *ngIf="failedRulesData.failedGlobalRulesCount" (click)="jumpToGlobalRules.emit()">
          <div class="flex-container">
            {{ failedRulesData.failedGlobalRulesCount }} Global rule failure{{ failedRulesData.failedGlobalRulesCount ? 's' : '' }}
            <mat-icon class="arrow-icon">arrow_forward</mat-icon>
          </div>
        </li>
      </ul>
    </div>

    <!-- action failure (orange) template -->
    <div *ngIf="failedActionData" class="action-failure highlight-box">
      <div class="description-icon-wrap">
        <mat-icon>cancel</mat-icon>
        <div class="description">
          Journey was stopped because there was a failure on action {{ failedActionData.actionIndex }}.
        </div>
      </div>
      <div class="lower">
        {{ failedActionData.failureReason }}
        <div class="buttons">
          <button mat-flat-button (click)="jumpToAction.emit(failedActionData.actionIndex)">Jump To Action Failure</button>
          <!-- have to add tooltip to span wrapping btn because tooltips don't work on disabled buttons -->
          <span matTooltip="No more journey fixes available"
                matTooltipPosition="above"
                [matTooltipDisabled]="!failedActionData.outOfFixes"
          >
            <button mat-flat-button
                    (click)="requestJourneyFix.emit()"
                    class="fix-journey-btn"
                    [disabled]="failedActionData.fixRequested || failedActionData.outOfFixes"
            >

              <!-- if fix journey request hasn't been submitted -->
              <ng-container *ngIf="!failedActionData.fixRequested && !failedActionData.showFixJourneySpinner">
                <mat-icon class="btn-icon">flag</mat-icon>
                Have Journey Support Fix The Issue
              </ng-container>

              <!-- fix requested and waiting for API to respond -->
              <ng-container *ngIf="failedActionData.showFixJourneySpinner">
                <mat-spinner [diameter]="20" [strokeWidth]="3"></mat-spinner>
              </ng-container>

              <!-- fix successfully requested -- prevent user from clicking again -->
              <ng-container *ngIf="failedActionData.fixRequested  && !failedActionData.showFixJourneySpinner">
                <mat-icon class="btn-icon check-icon">check_circle</mat-icon>
                Fix has been requested
              </ng-container>

            </button>
          </span>
        </div>
      </div>
    </div>

    <!-- advanced journey (yellow) template -->
    <div *ngIf="advancedJourneyFeatures.length" class="advanced-features highlight-box">
      <div class="description-icon-wrap">
        <mat-icon>shuffle</mat-icon>
        <div class="description">
          All pages scanned in this journey run used settings that may affect collected data
          in tags, cookies, and other technologies. Report data reflects these changes.
        </div>
      </div>
      <ul class="bulleted-list">
        <li *ngFor="let feature of advancedJourneyFeatures">
          {{ feature }}
        </li>
      </ul>
    </div>

    <div *ngIf="!data?.runInfoExists" class="no-snapshot-data-available">
      Some run info is only available on newer runs. Please re-run now or schedule this Journey to take advantage of new information.
    </div>

    <div *ngIf="data?.runInfoExists" class="columns">
      <div class="left-col">

        <web-journey-report-info-section [sectionHeading]="'Executed Actions'">
          {{ data?.executedActions }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Run Duration'">
          <span class="capitalize">Start:</span> {{ data?.runDuration?.start }}<br>
          <span class="capitalize">End:</span> {{ data?.runDuration?.end }}<br>
          <span class="capitalize">Duration:</span> {{ data?.runDuration?.duration }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Browser Version'">
          {{ data?.browserVersion }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'User Agent Used'">
          {{ data?.userAgent }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Browser Viewport'">
          {{ data?.viewport }}
        </web-journey-report-info-section>

      </div>
      <div class="right-col">

        <web-journey-report-info-section [sectionHeading]="data?.customProxy ? 'Custom Proxy' : 'Location'">
          <ng-container *ngIf="!data?.customProxy">
            <span class="flag-icon flag-icon-{{ data?.location.countryCode | lowercase }}"></span>
            &nbsp;
          </ng-container>
          {{ data?.customProxy ?? data?.location.label }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Data Layer'">
          {{ data?.dataLayer }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'VPN'">
          {{ data?.vpn }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Run ID'">
          {{ data?.runId }}
        </web-journey-report-info-section>

      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="bottom-section">
    <h2 class="heading">Current Journey Settings</h2>
    <div class="columns">
      <div class="left-col">

        <web-journey-report-info-section [sectionHeading]="'Folder/Sub-Folder'">
          {{ data?.folderDomain }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Run Frequency'">
          {{ data?.runFrequency }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Actions Configured'">
          {{ data?.actionsConfigured }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Journey ID'">
          {{ data?.journeyId }}
        </web-journey-report-info-section>

      </div>
      <div class="right-col">

        <web-journey-report-info-section [sectionHeading]="'Creator'">
          {{ data?.creator }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Labels'">
          {{ data?.labels }}
        </web-journey-report-info-section>

        <web-journey-report-info-section [sectionHeading]="'Monitored Journey'">
          <div class="monitored-journey">
            <mat-icon *ngIf="data?.monitoredJourney" class="monitored-icon">visibility_outline</mat-icon>
            {{ data?.monitoredJourney ? 'Yes' : 'No' }}
          </div>
        </web-journey-report-info-section>

      </div>
    </div>
  </div>
</div>