import { Component, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-progress-bar',
  templateUrl: './cl-progress-bar.component.html',
  styleUrls: ['./cl-progress-bar.component.scss']
})
export class ComponentLibraryProgressBarComponent implements OnInit {

  value: number = 0;

  ngOnInit(): void {
    this.countToHundred();
  }

  countToHundred() {
    const interval = setInterval(() => {
      if (this.value < 100) {
        this.value++;
      } else {
        clearInterval(interval);
        this.value = 0;
        setTimeout(() => {
          this.countToHundred(); // Restart the count after 2 seconds
        }, 1000);
      }
    }, 50);
  }
}
