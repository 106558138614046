export class S3ErrorResponseParser {

  /**
   * Example
   * <Error>
   <Code>EntityTooLarge</Code>
   <Message>Your proposed upload exceeds the maximum allowed size</Message>
   <ProposedSize>10488101</ProposedSize>
   <MaxSizeAllowed>10485760</MaxSizeAllowed>
   <RequestId>4D048B8627231841</RequestId>
   <HostId>t3BWv8+Waa+vHhEmCnTQW6FICUSAFjhTdapb83uX3RCm1HkkMEQ9vWB51Pb4HzXVt1GzrTr+3PI=</HostId>
   </Error>
   * @param errorResponseXml
   */
  static parseToString(errorResponseXml: string): string {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(errorResponseXml, 'text/xml');

    const errorCode = S3ErrorResponseParser.getInnerHtml(xmlDoc, 'Code');
    const message = S3ErrorResponseParser.getInnerHtml(xmlDoc, 'Message');

    switch (errorCode) {
      case 'EntityTooLarge':
        const proposedSizeBytes = S3ErrorResponseParser.getInnerHtml(xmlDoc, 'ProposedSize');
        const maxSizeAllowedBytes = S3ErrorResponseParser.getInnerHtml(xmlDoc, 'MaxSizeAllowed');
        return `${message}. Proposed size = ${proposedSizeBytes}, allowed size = ${maxSizeAllowedBytes} bytes`;
      default:
        return message;
    }
  }

  private static getInnerHtml(xmlDoc: Document, tagName: string): string {
    return xmlDoc.getElementsByTagName(tagName)[0].innerHTML;
  }
}
