import {
  IFullscreenChartConfig, IFullscreenChartDataFormatted
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { EDateFormats, formatDate } from '@app/components/date/date.service';
import { DecimalPipe } from '@angular/common';

export enum EWebVitalsFullScreenChartTooltipType {
  PAGE_DETAILS = 'PAGE_DETAILS',
  PAGE_SUMMARY = 'PAGE_SUMMARY'
}

export const WebVitalsFullScreenChartTooltips = {
  [EWebVitalsFullScreenChartTooltipType.PAGE_SUMMARY]: (
    d: IFullscreenChartDataFormatted,
    chartConfig: IFullscreenChartConfig,
    valueTransformFn = value => value,
  ) => `<div class="wrapper">
              <div class="main">
                  <div class="main-value">${valueTransformFn(d.value)} ${chartConfig.sideLabel}</div>
              </div>
              <div class="separator"></div>
              <div class="values-container">
                <div class="item">
                  <div class="item-label">
                      max
                  </div>
                  <div class="item-value">
                    ${valueTransformFn(d.data.stats['max'])} ${chartConfig.shortLabel}
                  </div>
                </div>
                <div class="item">
                  <div class="item-label">
                      75th %
                  </div>
                  <div class="item-value">
                    ${valueTransformFn(d.data.stats['p75'])} ${chartConfig.shortLabel}
                  </div>
                </div>
                <div class="item">
                  <div class="item-label">
                      median
                  </div>
                  <div class="item-value">
                    ${valueTransformFn(d.data.stats['median'])} ${chartConfig.shortLabel}
                  </div>
                </div>
                <div class="item">
                  <div class="item-label">
                      average
                  </div>
                  <div class="item-value">
                    ${valueTransformFn(d.data.stats['average'])} ${chartConfig.shortLabel}
                  </div> 
                </div>
                <div class="item">
                  <div class="item-label">
                      25th %
                  </div>
                  <div class="item-value">
                    ${valueTransformFn(d.data.stats['p25'])} ${chartConfig.shortLabel}
                  </div>
                </div>
                <div class="item">
                  <div class="item-label">
                      min
                  </div>
                  <div class="item-value">
                    ${valueTransformFn(d.data.stats['min'])} ${chartConfig.shortLabel}
                  </div>
                </div>
              </div>
              <div class="separator"></div>
              <div class="date">
                ${formatDate(d.date, EDateFormats.dateThirty)}
              </div>
              </div>`,
  [EWebVitalsFullScreenChartTooltipType.PAGE_DETAILS]: (
    d: IFullscreenChartDataFormatted,
    chartConfig: IFullscreenChartConfig,
    valueTransformFn = (value: number) => value,
  ) => `<div class="wrapper">
               <div class="main">
                   <div class="main-value">${valueTransformFn(d.value)} ${chartConfig.shortLabel}</div>
               </div>
               <div class="date">
                ${formatDate(d.date, EDateFormats.dateThirty)}
               </div>
               </div>`,
};
