<div class="cc-create-tab-content-wrapper">
  <div class="source-selection-description" *ngIf="!noSelectionDisplay">Select the tags from your domain(s) that you would like to add to this consent category.</div>
  <div class="source-selection-description" *ngIf="noSelectionDisplay">The following is a list of all tags present in this audit. Please select tags you want to add to any consent category.</div>
  <div class="source-item-search">
    <op-clearable-input placeholderText="Search by tag name or account"
                        [emitKeyboardEvent]="true"
                        appearance="OUTLINED"
                        class="table-filter-input"
                        (onUserInput)="debounceFilterData($event)">
    </op-clearable-input>
  </div>
  <div class="cc-tables-scroll-container">
    <div class="cc-selected-table-wrapper" [class.no-selection-display]="noSelectionDisplay">
      <mat-expansion-panel hideToggle
                           [disabled]="selectedDataSource.data?.length === 0"
                           (opened)="openedSelection()"
                           (closed)="closedSelection()">
        <mat-expansion-panel-header>
          <cc-create-selection-info [selectedItems]="selectedTags"
                                    selectedItemText="Tags"
                                    [displaySelection]="displaySelection"
                                    [countOnly]="noSelectionDisplay"
                                    [selectedLimit]="selectedLimit">
          </cc-create-selection-info>
        </mat-expansion-panel-header>
        <div class="cc-selected-tags-table-wrapper" [hidden]="loading || this.selectedDataSource.data?.length === 0 || noSelectionDisplay">
          <table mat-table
                 [dataSource]="selectedDataSource"
                 class="cc-selected-tags-table"
                 #selectedTableSort="matSort"
                 matSort
                 multiTemplateDataRows
          >
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let row;" valign="middle">
                <mat-checkbox (change)="toggleSelectOne($event.checked, row, true)"
                              [disabled]="tagsHash[row.tagId]?.existing"
                              [checked]="tagsHash[row.tagId]?.selected"
                              color="primary"
                              [class.checkbox-disabled]="tagsHash[row.tagId]?.existing"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="tagName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Tag Name</th>
              <td mat-cell
                  (click)="toggleSelectOne(!tagsHash[tag.tagId]?.selected, tag, true)"
                  *matCellDef="let tag">
                <div class="tag-icon-name-wrap" [class.clickable]="!tagsHash[tag?.tagId]?.existing">
                  <div class="tag-icon">
                    <img [src]="getTagIconUrl(tag.tagId)" alt="{{tag.tagName}}" />
                  </div>
                  <div class="tag-name">{{ tag.tagName }}</div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="tagAccounts">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Tag Accounts</th>
              <td mat-cell *matCellDef="let tag">
                <div class="cc-source-simple-chip-wrapper" *ngIf="tagsHash[tag.tagId]?.existing">
                  <div class="cc-source-any-accounts" *ngIf="tagsHash[tag.tagId]?.accounts?.length === 0">Any accounts</div>
                  <div class="cc-source-simple-chip"
                       *ngIf="tagsHash[tag.tagId]?.accounts?.length >= 1">
                    <div class="simple-chip-text">
                      {{tagsHash[tag.tagId]?.accounts?.length}} more
                    </div>
                    <mat-icon>expand_more</mat-icon>
                  </div>
                  <div class="existing-message">
                    <mat-icon>info</mat-icon>
                    Item already included in this consent category
                  </div>
                </div>
                <div class="cc-source-simple-chip-wrapper" *ngIf="!tagsHash[tag.tagId]?.existing">
                  <ng-container *ngIf="tagsHash[tag.tagId]?.anyAccount">
                    <div class="any-account-message">Any Account</div>
                    <div class="existing-message">
                      <mat-icon>info</mat-icon>
                      Found more than 20 accounts, setting to "Any"
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!tagsHash[tag.tagId]?.anyAccount">
                    <div class="cc-source-simple-chip" *ngFor="let account of tagsHash[tag.tagId]?.accounts | slice:0:4; let index = index;">
                      <div class="simple-chip-text" [matTooltip]="account">
                        {{ account }}
                      </div>
                      <mat-icon (click)="removeAccount(tag, account)" [matTooltip]="'Remove ' + account">close</mat-icon>
                    </div>
                    <div class="cc-source-simple-chip simple-chip-inverted"
                         *ngIf="tagsHash[tag.tagId]?.accounts?.length > 1 && tagsHash[tag.tagId]?.accounts?.length <= 4">
                      <div class="simple-chip-text">
                        clear all
                      </div>
                      <mat-icon (click)="removeAllTagAccounts(tag)">close</mat-icon>
                    </div>
                    <div class="cc-source-simple-chip"
                         *ngIf="tagsHash[tag.tagId]?.accounts?.length >= 5">
                      <div class="simple-chip-text">
                        {{tagsHash[tag.tagId]?.accounts?.length - 4}} more
                      </div>
                      <mat-icon (click)="openOtherTagAccountsDropdown(tag)" [matMenuTriggerFor]="selectedTagsMoreMenu">expand_more</mat-icon>
                      <mat-menu #selectedTagsMoreMenu="matMenu" class="simple-chips-menu" xPosition="before">
                        <div class="cc-source-simple-chip-wrapper" [class.chips-dark-theme]="isDarkTheme">
                          <div class="cc-source-simple-chip" *ngFor="let account of moreTagAccounts">
                            <div class="simple-chip-text" [matTooltip]="account">
                              {{ account }}
                            </div>
                            <mat-icon (click)="removeAccount(tag, account)" [matTooltip]="'Remove ' + account">close</mat-icon>
                          </div>
                        </div>
                      </mat-menu>
                    </div>
                  </ng-container>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row
                class="cc-source-table-row"
                *matHeaderRowDef="displayedColumns; sticky: true;">
            </tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"
                [class.existing]="tagsHash[row.tagId]?.existing"
                class="cc-source-table-row"></tr>
          </table>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="cc-import-tables-wrapper">
      <div class="cc-tags-table-wrapper">
        <div class="source-zero-state" *ngIf="!loading && this.dataSource.data?.length === 0 && tagFilter !== ''">No tags or accounts match the current filter.</div>
        <div class="source-zero-state" *ngIf="!loading && this.dataSource.data?.length === 0 && tagFilter === ''">No tags were discovered on the selected run(s).</div>
        <table mat-table
               [hidden]="loading || this.dataSource.data?.length === 0"
               [dataSource]="dataSource"
               class="cc-source-table"
               #resultsTableSort="matSort"
               matSort
               multiTemplateDataRows
        >
          <ng-container matColumnDef="select-all">
            <th mat-header-cell *matHeaderCellDef
                valign="middle"
                [attr.colspan]="displayedColumns.length">
              <div class="select-all-wrapper"
                   [class.disabled-select-all]="!allTagsSelected && ((pagination.totalCount > selectedLimit) || unselectedTagsInResponse > (selectedLimit - selectedTags.length))"
              >
                <div class="checkbox-wrapper">
                  <mat-checkbox (change)="toggleSelectAll($event.checked)"
                                [checked]="allTagsSelected"
                                [disabled]="!allTagsSelected && ((pagination.totalCount > selectedLimit) || unselectedTagsInResponse > (selectedLimit - selectedTags.length))"
                                color="primary"
                                aria-label="Select all tags">
                  </mat-checkbox>
                </div>
                <div class="select-all-text">Select All ({{pagination.totalCount}})
                  <span class="disabled-select-all-text" *ngIf="!allTagsSelected && ((pagination.totalCount > selectedLimit) || unselectedTagsInResponse > (selectedLimit - selectedTags.length))">The items listed are greater than the limit of {{selectedLimit}}, please filter your results to enable select all.</span>
                </div>
               </div>
            </th>
          </ng-container>
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let row; let i = dataIndex" valign="middle">
              <div class="checkbox-wrapper">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="handleCheckboxClick($event.checked, i)"
                              [checked]="tagsHash[row.tagId]?.selected"
                              [disabled]="tagsHash[row.tagId]?.existing"
                              color="primary">
                </mat-checkbox>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="tagName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Tag Name</th>
            <td mat-cell
                (click)="toggleSelectOne(!tagsHash[tag.tagId]?.selected, tag)"
                *matCellDef="let tag">
              <div class="tag-icon-name-wrap" [class.clickable]="!tagsHash[tag?.tagId]?.existing">
                <div class="tag-icon">
                  <img [src]="getTagIconUrl(tag.tagId)"  alt="{{tag.tagName}}" />
                </div>
                <div class="tag-name">{{ tag.tagName }}</div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="tagAccounts">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Tag Accounts</th>
            <td mat-cell *matCellDef="let tag">
              <div class="cc-source-simple-chip-wrapper" *ngIf="tagsHash[tag.tagId]?.existing">
                <div class="cc-source-any-accounts" *ngIf="tagsHash[tag.tagId]?.accounts?.length === 0">Any accounts</div>
                <div class="cc-source-simple-chip"
                     *ngIf="tagsHash[tag.tagId]?.accounts?.length >= 1">
                  <div class="simple-chip-text">
                    {{tagsHash[tag.tagId]?.accounts?.length}} more
                  </div>
                  <mat-icon>expand_more</mat-icon>
                </div>
                <div class="existing-message">
                  <mat-icon>info</mat-icon>
                  Item already included in this consent category
                </div>
              </div>
              <div class="cc-source-simple-chip-wrapper" *ngIf="!tagsHash[tag.tagId]?.existing">
                <ng-container *ngIf="tagsHash[tag.tagId]?.anyAccount">
                  <div class="any-account-message">Any Account</div>
                  <div class="existing-message">
                    <mat-icon>info</mat-icon>
                    Found more than 20 accounts, setting to "Any"
                  </div>
                </ng-container>
                <ng-container *ngIf="!tagsHash[tag.tagId]?.anyAccount">
                  <div class="cc-source-simple-chip" *ngFor="let account of tagsHash[tag.tagId]?.accounts | slice:0:4; let index = index;">
                    <div class="simple-chip-text" [matTooltip]="account">
                      {{ account }}
                    </div>
                    <mat-icon (click)="removeAccount(tag, account)" [matTooltip]="'Remove ' + account">close</mat-icon>
                  </div>
                  <div class="cc-source-simple-chip simple-chip-inverted"
                       *ngIf="tagsHash[tag.tagId]?.accounts?.length > 1 && tagsHash[tag.tagId]?.accounts?.length <= 4">
                    <div class="simple-chip-text">
                      clear all
                    </div>
                    <mat-icon (click)="removeAllTagAccounts(tag)">close</mat-icon>
                  </div>
                  <div class="cc-source-simple-chip"
                       *ngIf="tagsHash[tag.tagId]?.accounts?.length >= 5">
                    <div class="simple-chip-text">
                      {{tagsHash[tag.tagId]?.accounts?.length - 4}} more
                    </div>
                    <mat-icon (click)="openOtherTagAccountsDropdown(tag)" [matMenuTriggerFor]="availableTagsMoreMenu">expand_more</mat-icon>
                    <mat-menu #availableTagsMoreMenu="matMenu" class="simple-chips-menu" xPosition="before">
                      <div class="cc-source-simple-chip-wrapper" [class.chips-dark-theme]="isDarkTheme">
                        <div class="cc-source-simple-chip" *ngFor="let account of moreTagAccounts">
                          <div class="simple-chip-text" [matTooltip]="account">
                            {{ account }}
                          </div>
                          <mat-icon (click)="removeAccount(tag, account)" [matTooltip]="'Remove ' + account">close</mat-icon>
                        </div>
                      </div>
                    </mat-menu>
                  </div>
                </ng-container>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row
              class="cc-source-table-row"
              *matHeaderRowDef="displayedColumns; sticky: true;">
          </tr>
          <tr mat-header-row
              class="cc-source-table-row"
              *matHeaderRowDef="['select-all']">
          </tr>
          <tr mat-row
              *matRowDef="let row; columns: displayedColumns;"
              [class.existing]="tagsHash[row.tagId]?.existing"
              class="cc-source-table-row"></tr>
        </table>
        <mat-paginator [class.hidden]="loading"
                       [length]="pagination.totalCount"
                       [pageSize]="pagination.pageSize"
                       [hidePageSize]="true"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" class="spinner-wrapper">
  <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
</div>
