import { Injectable } from '@angular/core';
import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { map } from 'rxjs/operators';
import { StorageService } from '@app/components/shared/services/storage.service';
import { RULE_LIBRARY_FILTERS_STORAGE_KEY } from '@app/components/rules/rule-library/rule-library.constants';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { Observable } from 'rxjs';
import { ERulesV3FilterTypes } from './rule-library.enums';

@Injectable()
export class RulesFilterBarService extends OpFilterBarService<ERulesV3FilterTypes> {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService
  ) {
    super(storage, RULE_LIBRARY_FILTERS_STORAGE_KEY, cacheReset, new Map([[ERulesV3FilterTypes.Label, true]]));
    this.updateSupportedFiltersList([
      ERulesV3FilterTypes.Label,
      ERulesV3FilterTypes.RuleName,
      ERulesV3FilterTypes.FromTemplate
    ]);
  }

  get filters$(): Observable<IOpFilterBarFilter<ERulesV3FilterTypes>[]> {
    return this.anyFiltersUpdates$
      .pipe(
        map(newFilters =>
          newFilters
            .filter(filter => this.validTypes.includes(filter.type))
            .reduce((acc, curr) => {
              acc.push(curr);
              return acc;
            }, [])
        )
      );
  }

  addNameContainsFilter(contains: string) {
    this.addFilter({
      type: ERulesV3FilterTypes.RuleName,
      display: `Rule Name contains '${contains}'`,
      value: contains,
      order: 1
    });
  }

  addRuleTypeFilter(isBuiltIn: boolean) {
    this.addFilter({
      type: ERulesV3FilterTypes.FromTemplate,
      display: `Type is ${isBuiltIn ? 'Built-In' : 'Custom'}`,
      value: isBuiltIn,
      order: 2
    });
  }

  addRuleLabelIdFilter(label: { name: string, id: number }) {
    this.addFilter({
      type: ERulesV3FilterTypes.Label,
      display: `Label: ${label.name}`,
      value: label.id,
      order: 3
    });
  }

  addRuleCreatorFilter(creator: { name: string, id: number }) {
    this.addFilter({
      type: ERulesV3FilterTypes.CreatedBy,
      display: `Created by ${creator.name}`,
      value: creator.id,
      order: 4
    });
  }

  addRuleNotificationStatusFilter(notification: boolean) {
    this.addFilter({
      type: ERulesV3FilterTypes.Notification,
      display: `Notification is ${notification ? 'On' : 'Off'}`,
      value: notification,
      order: 5
    });
  }
}
