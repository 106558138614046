<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header
      title="Select one of the following"
      (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <mat-radio-group [(ngModel)]="selectedOption">
      <mat-radio-button color="primary" [value]="comparisonType.runOverRun">Run Over Run Comparison</mat-radio-button>
      <mat-radio-button color="primary" [value]="comparisonType.oneTime">One Time Comparison</mat-radio-button>
    </mat-radio-group>
  </ng-template>
</op-modal>
