<div class="full-width-widget">
  <span>{{comparedRunLabel}}<span class="date" *ngIf="comparedRun">{{comparedRun.date | date: dateFormat }}</span></span>
</div>
<div class="charts">
  <div class="widget-container">
    <div class="widget-container widget-nowrap">
      <op-summary-card class="left-summary-card"
                       [headerText]="widgetPagesScanned.topLabel"
                       [filteredValue]="widgetPagesScanned.values.filtered"
                       [totalValue]="widgetPagesScanned.values.total"
                       [showTotalValueWhenFiltered]="true"
                       [widgetState]="widgetsState"
                       [metricType]="widgetPagesScanned.metricType"
                       [auditId]="auditId"
                       [runId]="runId"
                       [currentValue]="widgetPagesScanned.values.filtered"
                       [filters]="currentFilters"
                       [highlight]="highlightMetricType === widgetPagesScanned.metricType"
                       [disableHighlightFromClick]="true"
                       tooltipText="A count of pages scanned in this audit/run.">
      </op-summary-card>

      <op-summary-card class="left-summary-card"
                       [headerText]="widgetChangedFiles.topLabel"
                       [filteredValue]="widgetChangedFiles.values.filtered"
                       [totalValue]="widgetChangedFiles.values.total"
                       [showTotalValueWhenFiltered]="true"
                       [widgetState]="widgetsState"
                       [metricType]="widgetChangedFiles.metricType"
                       [auditId]="auditId"
                       [runId]="runId"
                       [filters]="currentFilters"
                       [highlight]="highlightMetricType === widgetChangedFiles.metricType"
                       [disableHighlightFromClick]="true"
                       [activated]="isFilteredByFilesChangeType(EJSFileChangeType.changedFile)"
                       tooltipText="Number of JavaScript files that had a size or date modified difference."
                       (cardClicked)="widgetChangedFiles.topHandler()">
      </op-summary-card>
    </div>
    <div class="widget-container widget-nowrap">
      <op-summary-card class="left-summary-card"
                       [headerText]="widgetNewFiles.topLabel"
                       [filteredValue]="widgetNewFiles.values.filtered"
                       [totalValue]="widgetNewFiles.values.total"
                       [showTotalValueWhenFiltered]="true"
                       [widgetState]="widgetsState"
                       [metricType]="widgetNewFiles.metricType"
                       [auditId]="auditId"
                       [runId]="runId"
                       [filters]="currentFilters"
                       [highlight]="highlightMetricType === widgetNewFiles.metricType"
                       [disableHighlightFromClick]="true"
                       [activated]="isFilteredByFilesChangeType(EJSFileChangeType.newFile)"
                       tooltipText="Count of JavaScript files that where not found in the previous run."
                       (cardClicked)="widgetNewFiles.topHandler()">
      </op-summary-card>

      <op-summary-card class="left-summary-card"
                       [headerText]="widgetFileDateChanges.topLabel"
                       [filteredValue]="widgetFileDateChanges.values.filtered"
                       [totalValue]="widgetFileDateChanges.values.total"
                       [showTotalValueWhenFiltered]="true"
                       [widgetState]="widgetsState"
                       [metricType]="widgetFileDateChanges.metricType"
                       [auditId]="auditId"
                       [runId]="runId"
                       [filters]="currentFilters"
                       [highlight]="highlightMetricType === widgetFileDateChanges.metricType"
                       [disableHighlightFromClick]="true"
                       [activated]="isFilteredByFilesDateDifference()"
                       tooltipText="Number of JavaScript files that had a date modified difference."
                       (cardClicked)="widgetFileDateChanges.topHandler()">
      </op-summary-card>

      <op-summary-card class="left-summary-card"
                       [headerText]="widgetFileSizeChanges.topLabel"
                       [filteredValue]="widgetFileSizeChanges.values.filtered"
                       [totalValue]="widgetFileSizeChanges.values.total"
                       [showTotalValueWhenFiltered]="true"
                       [widgetState]="widgetsState"
                       [metricType]="widgetFileSizeChanges.metricType"
                       [auditId]="auditId"
                       [runId]="runId"
                       [filters]="currentFilters"
                       [highlight]="highlightMetricType === widgetFileSizeChanges.metricType"
                       [disableHighlightFromClick]="true"
                       [activated]="isFilteredByFilesSizeDifference()"
                       tooltipText="Number of JavaScript files that had a size difference."
                       (cardClicked)="widgetFileSizeChanges.topHandler()">
      </op-summary-card>
    </div>
  </div>
</div>
<div>
  <div class="section-title-wrap" #fileChangesSection>
    <div class="title-spinner-wrap">
      <h5 class="section-title">JavaScript File Changes</h5>
      <filter-spinner [state]="fileChangesLoaderState"></filter-spinner>
    </div>
  </div>

  <js-file-changes-table
    [inputData]="fileChangesItems"
    [spinnerState]="fileChangesLoaderState"
    [tableState]="fileChangesTableState"
    [apiFilters]="apiFilters"
    (onChangeClicked)="handleChangeClicked($event)"
    (updateTableState)="updateFileChangesState()"
  ></js-file-changes-table>
</div>
<div #pagesSection>
  <div class="section-title-wrap pages">
    <div class="title-spinner-wrap">
      <filter-spinner class="left-side" [state]="pagesSpinnerState"></filter-spinner>
      <p class="section-title">Pages Scanned</p>
      <op-audit-report-export-menu
        [auditId]="auditId"
        [runId]="runId"
        [tableCompletedState]="pagesSpinnerState"
        [config]="exportReportConfig">
      </op-audit-report-export-menu>
    </div>

    <op-switchable-column-menu [columnConfig]="PagesTableColumns"
                               [storageKey]="CommonPagesConfigLocalStorageKey"
                               [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
  </div>

  <js-file-changes-pages-table
    [auditId]="auditId"
    [runId]="runId"
    [inputData]="pagesData"
    [spinnerState]="pagesSpinnerState"
    [tableState]="pagesTableState"
    [selectedFile]="selectedFile"
    (updateTableState)="updatePagesTableState($event)"
  ></js-file-changes-pages-table>
</div>
