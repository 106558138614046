import { Directive, HostListener, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Directive({
  selector: '[opFileDrop]',
  standalone: true
})
export class OpFileDropDirective {
  private _enabled: boolean = true;

  @Input() set opFileDrop(value: boolean | string) {
    this._enabled = value === '' || value === true || value === 'true';
  }

  @Output() dropped = new EventEmitter<DragEvent>();

  @HostBinding('class.dragging') private _dragInProgress = false;

  @HostListener('dragenter', ['$event'])
  @HostListener('dragover', ['$event'])
  private handleDragOver(event: DragEvent): void {
    if (!this._enabled) return;

    event.dataTransfer!.dropEffect = 'move';
    this.stopAndPreventDefault(event);
    this._dragInProgress = true;
  }

  @HostListener('dragleave', ['$event'])
  @HostListener('dragend', ['$event'])
  private handleDragEnd(event: DragEvent): void {
    if (!this._enabled) return;

    this.stopAndPreventDefault(event);
    event.dataTransfer!.effectAllowed = 'copy';
    this._dragInProgress = false;
  }

  @HostListener('drop', ['$event'])
  private handleDrop(event: DragEvent): void {
    this.stopAndPreventDefault(event);
    this._dragInProgress = false;
    this.dropped.emit(event);
  }

  private stopAndPreventDefault(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
  }
}
