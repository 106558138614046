<div class="tag-signatures-table-wrapper" (click)="$event.stopPropagation()">
  <table mat-table
         class="tag-signatures-table"
         [dataSource]="signatures"
         matSort
         multiTemplateDataRows
  >
    <ng-container matColumnDef="signature">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Signature
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.signature}}
      </td>
    </ng-container>
    <ng-container matColumnDef="matches">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Matches
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.matches}}
      </td>
    </ng-container>
    <ng-container matColumnDef="accountDelimiter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Account Delimiter
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.accountDelimiter}}
      </td>
    </ng-container>
    <ng-container matColumnDef="variableDelimiter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Variable Delimiter
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.variableDelimiter}}
      </td>
    </ng-container>
    <ng-container matColumnDef="batchDelimiter">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Batch Delimiter
      </th>
      <td mat-cell *matCellDef="let row" valign="middle" [ngSwitch]="row.batchDelimiter">
        <ng-container *ngSwitchCase="'\n'">\n</ng-container>
        <ng-container *ngSwitchCase="'\r'">\r</ng-container>
        <ng-container *ngSwitchDefault>{{ row.batchDelimiter }}</ng-container>
      </td>
    </ng-container>
    <tr mat-header-row
        class="tag-database-library-row"
        *matHeaderRowDef="displayedColumns; sticky: true;">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
</div>
