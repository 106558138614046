<div class="setup-tab-body">
  <p class="setup-instructions">Select the Data for Recurring Comparisons</p>
  
  <form [formGroup]="setupForm" validate-form formName="setupForm" [submitted]="submitted">
    <div class="setup-form-fields">
      <div class="setup-name">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" op-selector="comparison-name">
        </mat-form-field>
        <op-error-message formControlPath="setupForm.name"></op-error-message>
      </div>

      <op-chip-selector formControlName="labels"
                        class="setup-labels"
                        label="Add Labels"
                        appearance="fill"
                        bindValue="id"
                        hint="Use labels to categorize and identify your comparisons"
                        op-selector="comparison-labels"
                        [allItems]="allLabels"
                        [placeholder]="{ hasSelected: '+ Label', hasNoSelected: '+ Label' }"
                        (onCreateChip)="createLabel($event)">
      </op-chip-selector>
    </div>

    <div class="setup-tag-list">
      <op-drag-list [data]="tagData"
                    [draggable]="false"
                    [dragulaContainer]="'ror-comparison-tags'"
                    formArrayName="tags">
        <ng-template #row let-item="item" let-index="index">
          <div class="flex-row variable-selector-wrapper" [formGroupName]="index">
            <comparison-variable-selector [attr.op-selector]="'dataSource-' + index"
                                          [allTags]="allTags"
                                          [formGroup]="tags.get(index.toString())"
                                          (onDeleteTag)="deleteTag(index)"
                                          [tagToAllVariablesMap]="tagToAllVariablesMap"
                                          [tagToExcludedVariablesMap]="tagToExcludedVariablesMap"
                                          [isTagPresenceOnly]="isTagPresenceOnly"
                                          (onTagPresenceOnlyChange)="isTagPresenceOnly = $event">
            </comparison-variable-selector>
          </div>
        </ng-template>
      </op-drag-list>
    </div>
  </form>
</div>
<button class="btn btn-primary add-additional-tag-btn"
        op-selector="add-tag-btn"
        [style.visibility]="isTagPresenceOnly ? 'hidden' : 'visible'"
        (click)="addTag(tagData.length)">
  Add Additional tag
</button>
