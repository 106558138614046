import { Component, Input, forwardRef, ElementRef, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface ISelectItemBase {
  id?: any;
  name: string;
}

export interface ISelectWithIconItem extends ISelectItemBase {
  id?: number;
  name: string;
  icon?: string;
  iconId?: number;
  value?: boolean | string;
  iconClass?: string;
  iconUrl?: string;
}

export interface IStringSelectWithIconItem extends ISelectItemBase {
  id: string;
  name: string;
  icon?: string;
  iconId?: number;
  iconClass?: string;
  iconUrl?: string;
}

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OpSelectWithIconsComponent),
  multi: true
};

@Component({
  selector: 'op-select-with-icons',
  templateUrl: './op-select-with-icons.component.html',
  styleUrls: ['./op-select-with-icons.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class OpSelectWithIconsComponent implements ControlValueAccessor, AfterViewInit {

  @Input() data: Array<ISelectWithIconItem>;
  @Input() label?: string;
  @Input() groupBy: string;
  @Input() clearable: boolean = true;

  searchText: string;
  input: HTMLElement;

  selectedItem: ISelectWithIconItem;

  /**
   * Callback function that should be called when the control's value changes in the UI.
   * Initialized in registerOnChange().
   */
  onChange = (newValue: any) => {};

  /**
   * Callback function that should be called when the control was touched.
   * Initialized in registerOnTouched().
   */
  onTouched = () => {};

  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.input = this.elRef.nativeElement.querySelector('.ng-input input');
  }

  onOpen = () => this.searchText = '';

  onClose = () => {
    this.searchText = null;
    this.input.blur();
  }

  searchItem(searchTerm: string, item: ISelectWithIconItem): boolean {
    this.searchText = searchTerm;
    const lowerSearch = searchTerm.toLowerCase();
    return item.name.toLowerCase().indexOf(lowerSearch) >= 0;
  }

  clear() {
    this.selectedItem = null;
  }

  writeValue(obj: any): void {
    if (obj) this.selectedItem = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
