import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IGeoLocation } from '@app/components/shared/services/locations.models';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {
  root: string = environment.apiUrl;
  private locationsMapSubject: BehaviorSubject<Map<number, IGeoLocation>> = new BehaviorSubject(new Map<number, IGeoLocation>());
  locationsMapById$ = this.locationsMapSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) {
    this.getAllLocations().subscribe(locations => {
      const locationsMap = new Map<number, IGeoLocation>();
      locations.forEach(location => {
        locationsMap.set(location.id, location);
      });

      this.locationsMapSubject.next(locationsMap);
    });
  }

  @CacheApiResponse()
  getAllLocations(): Observable<IGeoLocation[]> {
    return this.apiService.get(this.root + 'locations');
  }
}
