<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="modalTitle"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="add-label-modal">
      <div class="instructions">
        Add labels to audits and journeys so you can quickly categorize and identify them later.
      </div>
      <div class="label-manager-wrap">
        <op-label-manager [selectedLabels]="[]"
                          [numberOfRows]="1"
                          (onLabelCreated)="addLabel($event)"
                          (onLabelSelected)="addLabel($event)"
                          (onLabelRemoved)="removeLabel($event)">
        </op-label-manager>
      </div>
    </div>
  </ng-template>
</op-modal>
