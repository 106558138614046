import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '@app/components/core/services/api.service';
import { HttpParams } from '@angular/common/http';
import { auditReportSerializer, webJourneyReportSerializer } from '../../manage.util';
import { IAuditReportCard, IWebJourneyReportCard } from '@app/components/manage/cards/report-card-list/report-card-list.models';
import { environment } from '@app/environments/environment';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AuthenticationService } from '@app/components/core/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ReportCardsApiService {

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService
  ) { }

  getAudits(ids: number[] = []): Observable<IAuditReportCard[]> {
    let params: HttpParams = ids.reduce(
      (httpParams, id) => httpParams.append('audit_id', id.toString()),
      new HttpParams()
    );

    return this.getUrlRoot().pipe(
      concatMap(root => this.apiService.get(root + 'web-audits/card-data', { params }, auditReportSerializer, 'report-cards')),
    );
  }

  getWebJourneys(): Observable<IWebJourneyReportCard[]> {
    return this.getUrlRoot().pipe(
      concatMap(root => this.apiService.get(root + 'web-journeys/card-data', undefined, webJourneyReportSerializer)),
      catchError(error => {
        console.error('Error fetching web journeys:', error);
        return of([]);
      })
    );
  }

  getWebJourneysByDomain(id: number): Observable<IWebJourneyReportCard[]> {
    return this.getUrlRoot().pipe(
      concatMap(root => this.apiService.get(root + `domains/${id}/web-journeys`, undefined, webJourneyReportSerializer)),
    );
  }

  private getUrlRoot(): Observable<string> {
    // API GW doesn't allow input or output payloads larger than 10MB. We have a customer that has
    // a response exceeding 16MB in size when hitting the web-audits card-data endpoint. This is
    // a temporary workaround to bypass the API gateway for that customer.
    // The bypass only works for the customer account IDs in the following array and only on prod.
    const prodAcctIdsBypassingApiGw = [
      8410 // goldman sachs privacy
    ];
    const isStaging = environment.domain === 'observepointstaging.com';
    if (isStaging) return of(environment.apiUrl);
    return this.authService.getAccountPreview().pipe(
      map(({ id }) => {
        return prodAcctIdsBypassingApiGw.includes(id) ? environment.apiUploadAppUrl : environment.apiUrl;
      })
    );
  }

}
