import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export const CookiesListTableColumnsStorageKey = 'cookies-list-config';

export enum CookiesListTableColumns {
  Expand = 'expand',
  Name = 'name',
  Domain = 'domain',
  Initiators = 'unique_initiator_count',
  PartyType = 'party_type',
  Duration = 'net_duration',
  PartitionKey = 'partition_key',
  Secure = 'secure',
  SameSite = 'same_site',
  HttpOnly = 'http_only',
  AvgSize = 'avg_size',
  Origin = 'cookie_changed_during',
  PresentOnPages = 'page_count',
}

export const CookiesTableColumns: ISwitchableMenuItems = {
  [CookiesListTableColumns.Expand]: {
    title: 'expand',
    checked: true,
    hiddenInMenu: true,
  },
  [CookiesListTableColumns.Name]: {
    title: 'Cookie Name',
    checked: true,
    pinned: true,
  },
  [CookiesListTableColumns.Domain]: {
    title: 'Domain',
    checked: true,
    pinned: true,
  },
  [CookiesListTableColumns.Initiators]: {
    title: 'Initiator(s)',
    checked: true,
  },
  [CookiesListTableColumns.PartyType]: {
    title: '1st/3rd Party',
    checked: true,
  },
  [CookiesListTableColumns.PartitionKey]: {
    title: 'Partition Key',
    checked: true,
  },
  [CookiesListTableColumns.Duration]: {
    title: 'Duration',
    checked: true,
  },
  [CookiesListTableColumns.SameSite]: {
    title: 'SameSite',
    checked: true,
  },
  [CookiesListTableColumns.Secure]: {
    title: 'Secure',
    checked: true,
  },
  [CookiesListTableColumns.HttpOnly]: {
    title: 'HttpOnly',
    checked: true,
  },
  [CookiesListTableColumns.AvgSize]: {
    title: 'AvgSize (bytes)',
    checked: true,
  },
  [CookiesListTableColumns.Origin]: {
    title: 'Origin',
    checked: true,
  },
  [CookiesListTableColumns.PresentOnPages]: {
    title: 'Present on # Pages',
    checked: true,
  },
};
