import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RemoteFileMapService } from '@app/components/creator/services/remote-file-map.service';
import { IRFMConfigV3, IRFMConfigV3CreationRequest } from '@app/components/creator/shared/remoteFileMapping/remote-file-mapping.component';
import { RfmEditorComponent } from '@app/components/rfm-library/rfm-editor/rfm-editor.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ELearnMoreOptionsLinks } from '@app/components/audit/audit-setup-form/audit-setup-form.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-file-substitutions',
  templateUrl: './manage-file-substitutions.component.html',
  styleUrls: ['./manage-file-substitutions.component.scss']
})
export class ManageFileSubstitutionsComponent implements OnInit {

  manageSubstitutionsForm = new FormGroup({
    rfmConfigField: new FormControl([])
  });

  @Input() selectedCards: any[] = [];
  remoteFileMappings: IRFMConfigV3[];
  LEARN_MORE = ELearnMoreOptionsLinks;

  editing = true;
  saving = false;
  error = false;
  useDefaultDomain: boolean = false;  // Domain field is hidden and value defaults to http://www.example.com if set to true

  rightFooterButtons = [
    {
      label: 'Save',
      action: () => {
        this.save();
      },
      primary: true
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<ManageFileSubstitutionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: MatSnackBar,
    private modalService: OpModalService,
    private rfmService: RemoteFileMapService,
  ) {
    this.selectedCards = this.data.selectedCards;
  }

  ngOnInit() {
    this.loadRfms();
  }

  compareObjectsWithIds(a: any, b: any): boolean {
    if (!a || !b) return;
    return a.id === b.id;
  }

  createNewFileSubstition(): void {
    this.modalService.openModal(RfmEditorComponent, { data: null })
      .afterClosed()
      .subscribe((rfmConfig?: IRFMConfigV3CreationRequest) => {
        if (rfmConfig) {
          this.rfmService.createRfmConfig(rfmConfig).subscribe(
            (config: IRFMConfigV3) => {
              //add newly created file substitution to selection
              this.loadRfms();
            },
            error => {
              console.log(error);
              this.showSnackbarError('There was a problem saving the remote file mapping configuration.');
            }
          );
        }
      });
  }

  loadRfms(): void {
    this.rfmService.getRfmConfigs().subscribe((configs: IRFMConfigV3[]) => {
      this.remoteFileMappings = configs
        .filter((rfmConfig: IRFMConfigV3) => !!rfmConfig.name)
        .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    });
  }

  private showSnackbarError(errorMessage: string): void {
    this.snackbar.open(
      errorMessage,
      '',
      { duration: 5000, horizontalPosition: 'center', verticalPosition: 'top' }
    );
  }

  save(): void {
    if(this.rfmConfigField().length === 0) {
      this.showSnackbarError('Please select at least one file substitution.');
      return;
    }

    this.rightFooterButtons[0]['disabled'] = true;
    this.dialogRef.close(this.rfmConfigField());
  }

  rfmConfigField(): FormArray {
     return this.manageSubstitutionsForm.get('rfmConfigField') as FormArray;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
