import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { EUserStep } from '@app/components/create-edit-user-modal/create-edit-user-modal.constants';
import { startsWith } from '@app/moonbeamConstants';
import { AdminPortalUrlBuilders } from '@app/components/admin-portal/admin-portal.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'op-user-steps',
  templateUrl: './user-steps.component.html',
  styleUrls: ['./user-steps.component.scss']
})
export class UserStepsComponent {
  isInAdminPortal: boolean = false;

  @Input() currentStep: EUserStep;
  @Input() profileStepSubtitle: string;
  @Input() selectedPermissionLevel: number;
  @Input() selectedFoldersCount: number;
  @Input() selectedNotificationsCount: number = 0;
  @Input() stepOneValid: boolean;
  @Input() stepTwoValid: boolean;
  @Input() stepThreeValid: boolean;
  @Input() editMode: boolean;

  @Output() stepSelected = new EventEmitter<EUserStep>();

  readonly EUserStep = EUserStep;
  readonly permissionLevelMap = {
    0: 'Read Only',
    1: 'Standard',
    32: 'Admin',
    256: 'OP Admin',
    1024: 'OP Sys Admin'
  };

  steps = [
    {
      id: EUserStep.Profile,
      title: 'User Profile',
      subtitle: 'Profile Information',
      valid: false,
      disabled: false,
    },
    {
      id: EUserStep.Permissions,
      title: 'User Permissions',
      subtitle: 'Permissions subtitle',
      valid: false,
      disabled: false,
    },
    {
      id: EUserStep.FolderAccess,
      title: 'User Folder Access',
      subtitle: 'Access to 0 folders',
      valid: false,
      disabled: false,
    },
    {
      id: EUserStep.Notifications,
      title: 'User Notifications',
      subtitle: 'Select items',
      valid: false,
      disabled: false,
    },
  ];

  constructor(private router: Router) {
    const route = this.router.url;
    this.isInAdminPortal = startsWith(route, AdminPortalUrlBuilders.base());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profileStepSubtitle) {
      this.steps[0].subtitle = this.profileStepSubtitle;
    }

    if (changes.selectedPermissionLevel) {
      this.steps[1].subtitle = this.permissionLevelMap[this.selectedPermissionLevel];
    }

    if (changes.selectedFoldersCount) {
      this.steps[2].subtitle = `Access to ${this.selectedFoldersCount ?? 0} folder${this.selectedFoldersCount === 1 ? '' : 's'}`;
    }

    if (changes.stepOneValid) {
      this.steps[0].valid = this.stepOneValid;
    }

    if (changes.stepTwoValid) {
      this.steps[1].valid = this.stepTwoValid;
    }


    if (changes.stepThreeValid) {
      this.steps[2].valid = this.stepThreeValid;
    }

    if (changes.selectedNotificationsCount) {
      this.steps[3].valid = this.selectedNotificationsCount > 0;
      this.steps[3].subtitle = `${this.selectedNotificationsCount ?? 0} item${this.selectedNotificationsCount === 1 ? '' : 's'} selected`;
    }
  }
}
