import * as angular from 'angular';

import {
  name as MJS_NAME,
  ManualJourneyService,
} from '@app/components/live-connect/manual-journey/manualJourneyService';
import {
  name as DDPC_NAME,
  DeleteDeviceProfileController,
} from '@app/components/live-connect/device-profile/deleteDeviceProfileController';
import {
  name as DMJC_NAME,
  DeleteManualJourneyController,
} from '@app/components/live-connect/manual-journey/deleteManualJourneyController';
import {
  name as MJSS_NAME,
  ManualJourneySocketsService,
} from '@app/components/live-connect/manual-journey/manualJourneyWsService';
import {
  name as LCAFS_NAME,
  LiveConnectAttachedFilesService,
} from '@app/components/live-connect/manual-journey/liveConnectAttachedFilesService';

export const liveConnectModule = angular
  .module('liveConnect', [])
  .service(MJS_NAME, ManualJourneyService)
  .service(MJSS_NAME, ManualJourneySocketsService)
  .service(LCAFS_NAME, LiveConnectAttachedFilesService)
  .controller(DDPC_NAME, DeleteDeviceProfileController)
  .controller(DMJC_NAME, DeleteManualJourneyController).name;
