import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
import { ESupportContactType } from '@app/components/usage-v2/usage-v2.models';
import { EUsageOveragesModalType } from './components/usage-overages-modal/usage-overages-modal.constants';

export const UsageV2StateNames = {
  base: 'usage',
};

export const UsageV2Paths = {
  base: 'usage',
};

export const UsageUrlBuilders = {
  base: () => RouteBuilder.buildUrl([UsageV2Paths.base]),
};

export const SupportContactData = {
  [ESupportContactType.AE]: {
    title: 'Account Executive (AE)',
    description: 'Your Account Executive will help with all things regarding upgrading or renewing your contract.'
  },
  [ESupportContactType.CSM]: {
    title: 'Customer Success Manager (CSM)',
    description: 'Your Customer Service Manager will help you get the most out of the ObservePoint platform and answer any technical questions.'
  }
};

export enum EOverageMessageVersion {
  BothOver = 'bothOver',
  OnlyAuditsOver = 'onlyAuditsOver',
  OnlyJourneysOver = 'onlyJourneysOver',
  OnlyAuditsWarning = 'onlyAuditsWarning',
  OnlyJourneysWarning = 'onlyJourneysWarning',
  AuditsOverJourneysWarning = 'auditsOverJourneysWarning',
  JourneysOverAuditsWarning = 'journeysOverAuditsWarning',
  BothWarning = 'bothWarning',
}

export const USAGE_OVERAGES_POPOVER = {
  [EUsageOveragesModalType.Allow]: 'When set to "YES" audits and journeys will start new runs according to their normal schedules.',
  [EUsageOveragesModalType.Prevent]: 'When set to "NO" audits and journeys will not start new runs that would cause the account to go over the annual contract limits.'
}
