import { RouteReloadService } from '@app/components/shared/services/route-reload.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import {
  AssignDatasetsModal,
  ConsentCategoriesUIConstants,
  DefaultConsentCategory,
} from '@app/components/consent-categories/consent-categories.constants';
import { IExportResponse } from '@app/components/usage-v2/usage-v2.models';
import { ExportReportModalComponent } from '../shared/components/export-report/export-report-modal/export-report-modal.component';
import { ExportModal } from '../shared/components/export-report/export-report-modal/export-report-modal.constants';
import {
  ECCSortColumns,
  EReportType,
  ICCLibraryQueryParams,
  ICCSort,
  IConsentCategory,
  IConsentCategoryBase,
  IConsentCategoryLibraryDTO,
  IConsentCategoryTableRow,
  IPaginationMetaData
} from '@app/components/consent-categories/consent-categories.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ConsentCategoryCreateComponent } from '@app/components/consent-categories/cc-create/cc-create.component';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, merge, of, Subject } from 'rxjs';
import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { Router } from '@angular/router';
import { catchError, debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { IUser } from '@app/moonbeamModels';
import { IAdvancedConfigs } from '@app/components/shared/components/op-chip-selector/op-chip-selector.models';
import {
  ImportCategorizedCookiesModalComponent
} from './import-categorized-cookies-modal/import-categorized-cookies-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ImportCategorizedCookiesSnackbarComponent
} from './import-categorized-cookies-snackbar/import-categorized-cookies-snackbar.component';
import { CCAssignComponent } from './cc-assign/cc-assign.component';
import { ECCEditTabs } from '@app/components/consent-categories/cc-edit/cc-edit.constants';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { userIsAdmin, userIsGuest, userIsStandard } from '@app/authUtils';
import { hasOwnProperty } from 'fast-json-patch/module/helpers';
import { MatSort, Sort } from '@angular/material/sort';
import {
  ConsentCategoriesFilterBarService
} from './consent-categories-filter-bar/consent-categories-filter-bar.service';
import {
  EConsentCategoriesFilterTypes,
  IConsentCategoryFilter
} from '@app/components/consent-categories/consent-categories-filter-bar/consent-categories-filter-bar.constants';
import { MatPaginator } from '@angular/material/paginator';
import {
  ImportCategorizedCookiesWithDataSourcesSnackbarComponent
} from './import-categorized-cookies-with-datasources-snackbar/import-categorized-cookies-with-datasources-snackbar.component';
import { MenuItems } from '@app/components/shared/components/op-button-2021/op-button-2021.component';
import { AccountsService } from '@app/components/account/account.service';
import {
  OpDeleteItemWarningComponent
} from '../shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { ConsentCategoriesEditComponent, IEditConsentCategoryModal } from './cc-edit/cc-edit.component';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { OpStandardsSelectorService } from '@app/components/shared/components/op-standards-selector/op-standards-selector.service';
import { SyncOnetrustCategorizedCookiesModalComponent } from './sync-onetrust-categorized-cookies-modal/sync-onetrust-categorized-cookies-modal.component';
import { StorageService } from '@app/components/shared/services/storage.service';
import { DateService, EDateFormats } from '@app/components/date/date.service';

@Component({
  selector: 'op-consent-categories',
  templateUrl: './consent-categories.component.html',
  styleUrls: ['./consent-categories.component.scss'],
  animations: [
    trigger('expand', [
      state('collapsed', style({ maxHeight: '0', minHeight: '0' })),
      state('loading', style({ maxHeight: '60px', minHeight: '60px' })),
      state('expanded', style({ maxHeight: '200px', minHeight: '{{height}}' }), { params: { height: '*' } }),
      transition('collapsed <=> expanded, loading <=> *', animate('200ms'))
    ])
  ]
})
export class ConsentCategoriesComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject();
  private noteSubject = new Subject();
  private sortPaginateConsentCategories$ = new Subject();
  private filtersUpdated$ = new Subject();

  CONSTANTS = { ...ConsentCategoriesUIConstants };
  loading = true;
  consentCategories: IConsentCategory[] = [DefaultConsentCategory];
  dataSource = new MatTableDataSource;
  displayedColumns: string[] = ['name', 'type', 'notes', 'labels', 'cookies', 'tags', 'requestDomains', 'audits', 'updatedAt', 'editOptions'];
  expandedCC: IConsentCategory | null;
  clicked = 'name';
  reportType = EReportType;
  users = {};
  allLabels: ILabel[] = [];
  chipsConfig: IAdvancedConfigs = {
    collapsible: true,
    lines: 1,
    readOnly: false,
    availableWidth: 240
  };
  infoIsExpanded: boolean = false;
  isReadOnly: boolean = false;
  isAdmin: boolean = false;
  isStandard: boolean = false;
  enabledFeatures: string[];
  currentUser: IUser;
  initialCCValueReceived: boolean = false;
  filtersCount = 0; // -1 is a valid number representing going from >0 to 0
  consentCategoriesFilters: EConsentCategoriesFilterTypes[] = [
    EConsentCategoriesFilterTypes.Type,
    EConsentCategoriesFilterTypes.Name,
    EConsentCategoriesFilterTypes.Label,
  ];
  noteSaved: boolean = false;
  noteSavedTimeout;
  filters: IConsentCategoryFilter[] = [];

  createCCMenu: MenuItems[] = [
    {
      label: this.CONSTANTS.importCategorizedCookies,
      action: () => this.openConsentCategoryImportModal()
    },
    {
      label: this.CONSTANTS.syncWithOneTrust_Part1,
      labelIcon: 'onetrust-logo',
      labelAfterIcon: this.CONSTANTS.syncWithOneTrust_Part2,
      action: () => this.openConsentCategorySyncOneTrust(),
      hidden: false
    },
    {
      label: this.CONSTANTS.createNew,
      action: () => this.openConsentCategoryModal({
        data: {
          allLabels: this.allLabels,
          cookies: [],
          tags: [],
          type: 'approved',
          editing: false,
          requestDomains: [],
        }
      })
    },
    {
      label: this.CONSTANTS.assign,
      action: () => this.openAssignModal()
    }
  ];
  sortOptions: ICCSort = {
    sortBy: ECCSortColumns.UPDATED_AT,
    sortDesc: true,
    sortDir: 'desc'
  };
  pagination: IPaginationMetaData = {
    pageSize: 100,
    currentPageNumber: 0,
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private modalService: OpModalService,
    private router: Router,
    private routeReloadService: RouteReloadService,
    private ccService: ConsentCategoriesService,
    private standardsSelectorService: OpStandardsSelectorService,
    private accountsService: AccountsService,
    private snackbarService: SnackbarService,
    private labelService: LabelService,
    private snackbar: MatSnackBar,
    private opModalService: OpModalService,
    private authenticationService: AuthenticationService,
    private filterBarService: ConsentCategoriesFilterBarService,
    private cdr: ChangeDetectorRef,
    private storageService: StorageService,
    private dateService: DateService,
  ) {
  }

  ngOnInit(): void {
    this.pageInit();

    this.routeReloadService.reloadRouteEvents$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.reloadData());
  }

  ngAfterViewInit(): void {
    this.paginator.page.subscribe(({ pageIndex }) => this.handlePagination(pageIndex));
    this.dataSource.filterPredicate = (data, filter): boolean => {
      let nameCol = data['name'].trim().toLowerCase();
      let labelCol = data['labels'].map(label => label.name).join(', ').toLowerCase();
      let filterVal = filter.trim().toLowerCase();

      return nameCol.includes(filterVal) || labelCol.includes(filterVal);
    };

    // Set the updatedAt column to desc initially
    const nameSortable = this.sort.sortables.get('name');
    if (nameSortable) {
      nameSortable.start = 'asc';
    }
    const typeSortable = this.sort.sortables.get('type');
    if (typeSortable) {
      typeSortable.start = 'asc';
    }
  }

  pageInit() {
    this.labelService.getLabels().subscribe((labels: ILabel[]) => {
      this.allLabels = labels;
      this.initFilters();
      this.initListeners();
      this.handleLoadingConsentCategories();
    });

    forkJoin([
      this.accountsService.getUser(),
      this.authenticationService.getFeaturesWithCache()
    ]).pipe(
      take(1)
    ).subscribe(([user, features]) => {
      this.currentUser = user;
      this.enabledFeatures = features;
      this.isReadOnly = userIsGuest(user);
      this.isAdmin = userIsAdmin(user);
      this.isStandard = userIsStandard(user);
      this.getUsers();
    });
  }

  ngOnDestroy(): void {
    if (this.noteSavedTimeout) clearTimeout(this.noteSavedTimeout);

    this.destroy$.next();
  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(this.consentCategoriesFilters);

    this.filterBarService.filters$
      .pipe(
        debounceTime(100),
        takeUntil(this.destroy$),
      ).subscribe(this.onFiltersChanged.bind(this));
  }

  handleLoadingConsentCategories(): void {
    merge(
      this.sortPaginateConsentCategories$,
      this.filtersUpdated$
    ).pipe(
      debounceTime(150),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.loadConsentCategories();
    });
  }

  handlePagination(pageIndex: number) {
    this.pagination.currentPageNumber = pageIndex;
    this.sortPaginateConsentCategories$.next();
  }

  handleSort(sort: Sort) {

    // The last update column has a different column name from the sort name.
    let data: Sort = sort.active === 'updatedAt' ? { active: 'updated_at', direction: sort.direction } : sort;

    // Check if the sort column is the same as the current sort column
    if (this.sortOptions.sortBy === data.active) {
      // Toggle the sort direction if the column is the same
      this.sortOptions.sortBy = data.active as ECCSortColumns;
      this.sortOptions.sortDesc = !this.sortOptions.sortDesc;
      this.sortOptions.sortDir = this.sortOptions.sortDesc ? 'desc' : 'asc';
    } else {
      // Set the new sort column and direction
      this.sortOptions.sortBy = data.active as ECCSortColumns;
      this.sortOptions.sortDesc = data.direction === 'desc';
      this.sortOptions.sortDir = data.direction;
    }
    
    this.setFirstPagePaginator();
    this.sortPaginateConsentCategories$.next();
  }

  private setFirstPagePaginator() {
    this.pagination.currentPageNumber = 0;

    if (this.consentCategories) {
      this.paginator.pageIndex = 0;
    }
  }

  onFiltersChanged(filters: IConsentCategoryFilter[]) {
    // filtersCount will take into consideration previous count as well
    // because we don't want the filter bar blinking off and on when clearing filters
    this.filtersCount = this.filtersCount > 0 && !filters.length ? -1 : filters.length;
    this.filters = filters;
    this.resetPagination();
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.filtersUpdated$.next();
  }

  loadConsentCategories(ccId?: number): void {
    this.loading = true;
    this.ccService.getConsentCategoriesLibrary(this.getCCLibraryOptions()).pipe(
      takeUntil(this.destroy$),
      catchError(error => {
        this.loading = false;
        console.log("Error getting CCs in library -- it might be a bad filter: ", error);
        const filters = this.filterBarService.currentRelevantFilters;
        this.snackbarService.openErrorSnackbar('There was a problem loading Consent Categories. ' + (filters.length ? 'Please try clearing your filters.' : ''));
        // Return an empty observable to complete the stream
        return of({ metadata: { pagination: { currentPageNumber: 0 } }, consentCategories: [] });
      })
    ).subscribe(
      ({ metadata: { pagination }, consentCategories }: IConsentCategoryLibraryDTO) => {
        this.consentCategories = consentCategories.map(cc => {
          cc.updatedAt = this.dateService.formatDate(new Date(cc.updatedAt), EDateFormats.dateTwentyOne),
          cc.labels = cc.labelIds.map(labelId => {
            const foundLabel = this.allLabels.find(label => label.id === labelId);
            return foundLabel ? { id: foundLabel.id, name: foundLabel.name } : undefined;
          }).filter(item => item);

          return cc;
        });

        // Clear the expanded status after deleting if it is the last audit assigned to the CC
        if (ccId) {
          const expandedRow = this.consentCategories.find(cc => cc.id === ccId);
          if (expandedRow.auditCount === 0) {
            this.clicked = undefined;
            this.expandedCC = undefined;
          }
        }
        this.dataSource.data = this.consentCategories;
        this.pagination = pagination;
        this.paginator.pageIndex = pagination.currentPageNumber;
        this.loading = false;
        this.initialCCValueReceived = true;
      }, error => {
        this.loading = false;
        console.log(error);
        this.snackbarService.openErrorSnackbar('There was a problem loading Consent Categories.');
      }
    );
  }

  resetPagination(): void {
    this.pagination.currentPageNumber = 0;
  }

  initListeners() {
    this.noteSubject.pipe(
      debounceTime(250),
      takeUntil(this.destroy$),
      distinctUntilChanged(),
    ).subscribe((ccData) => {
      this.updateNote(ccData);
    });
  }

  getUsers(): void {
    this.accountsService.getUsers().subscribe((users: IUser[]) => {
      users.forEach(user => {
        this.users[user.id] = user;
      });
    });
  }

  openConsentCategoryModal(modalConfig = {}): void {
    this.modalService.openFixedSizeModal(ConsentCategoryCreateComponent, modalConfig)
      .afterClosed()
      .subscribe((data = { allLabels: [] }) => {
        this.allLabels.push(...data.allLabels);
        this.ccService.getConsentCategoriesLibrary(this.getCCLibraryOptions()).pipe(
          takeUntil(this.destroy$)
        ).subscribe(({ metadata: { pagination }, consentCategories }: IConsentCategoryLibraryDTO) => {
          this.consentCategories = consentCategories;
          this.pagination = pagination;
          this.loading = false;
        });
      });
  }

  openConsentCategoryImportModal(modalConfig = {}): void {
    this.modalService.openModal(ImportCategorizedCookiesModalComponent, modalConfig)
      .afterClosed().subscribe(data => {
        this.filtersUpdated$.next();

        if (data && data === AssignDatasetsModal) {
          this.openAssignModal();
        } else if (data && hasOwnProperty(data, 'topXNew')) {
          this.snackbar.openFromComponent(ImportCategorizedCookiesSnackbarComponent, {
            data: { data },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 7000, //7 seconds
          });
        } else if (data && hasOwnProperty(data, 'importedSnackbarData')) {
          //snackbar for imported cookies
          this.snackbar.openFromComponent(ImportCategorizedCookiesWithDataSourcesSnackbarComponent, {
            data: { data: data.importedSnackbarData },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 7000, //7 seconds
          });
        }
      });
  }

  openConsentCategorySyncOneTrust(modalConfig = {}): void {
    this.modalService.openModal(SyncOnetrustCategorizedCookiesModalComponent, modalConfig)
      .afterClosed().subscribe(data => {
        // If sync successfully imported records then refresh the page
        if (data && (data === true)) {
          this.filtersUpdated$.next();
        } else if (data && ((data === AssignDatasetsModal) || (data.func === AssignDatasetsModal)) && (data.newImports?.length > 0)) {
          this.openAssignModal(data.newImports);
        } else if (data && hasOwnProperty(data, 'topXNew')) {
          this.snackbar.openFromComponent(ImportCategorizedCookiesSnackbarComponent, {
            data: { data },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 7000, //7 seconds
          });
          this.filtersUpdated$.next();
        } else if (data && hasOwnProperty(data, 'importedSnackbarData')) {
          //snackbar for imported cookies
          this.snackbar.openFromComponent(ImportCategorizedCookiesWithDataSourcesSnackbarComponent, {
            data: { data: data.importedSnackbarData },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 7000, //7 seconds
          });
          this.filtersUpdated$.next();
        }
      });
  }

  private openAssignModal(newImports?: IConsentCategory[]): void {
    const data: any = {
      labels: this.allLabels
    };
    if (newImports !== undefined) {
      data.newImports = newImports;
    }

    this.modalService
      .openModal(
        CCAssignComponent,
        {
          data: data,
          autoFocus: false
        }
      )
      .afterClosed()
      .subscribe((counts: { ccCount: number, itemCount: number }) => {
        this.filtersUpdated$.next();
      });
  }

  showAssignModalConfirm(counts: { ccCount: number, itemCount: number }): void {
    const message = `${counts.ccCount} Consent Categor${counts.ccCount > 1 ? 'ies have' : 'y has'} now been assigned to ${counts.itemCount} data source${counts.itemCount > 1 ? 's' : ''}.`;
    this.snackbar.open(message, '', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'top' });
  }

  showPermissionsErrorSnackbar(): void {
    const message = `You do not have access to perform this action`;
    this.snackbar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  showDeleteSuccessSnackbar(deletedCC): void {
    const message = `You have successfully deleted ${deletedCC.name.length > 0 ? deletedCC.name : 'the consent category'}`;
    this.snackbar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  isClickInActiveRow(cc) {
    return this.expandedCC === cc;
  }

  setExpandedCC(cc) {
    if (this.expandedCC === cc) {
      this.expandedCC = null;
    } else {
      this.expandedCC = cc;
      this.noteSaved = false;
    }

    this.lastOpenedNestedTableHeight = 0;
    setTimeout(() => {
      this.lastOpenedNestedTableHeight = this.lastOpenedNestedTable?.getBoundingClientRect().height;
      this.cdr.detectChanges();
    }, 1000);
  }

  clickTagsCount(event, cc) {
    event.stopPropagation();
    if (cc.itemCounts.tags === 0) return;
    this.editConsentCategories(cc.id, ECCEditTabs.tags);
  }

  clickCookiesCount(event, cc) {
    event.stopPropagation();
    if (cc.itemCounts.cookies === 0) return;
    this.editConsentCategories(cc.id, ECCEditTabs.cookies);
  }

  clickRequestDomainsCount(event, cc) {
    event.stopPropagation();
    if (cc.itemCounts.requestDomainsAndGeos === 0) return;
    this.editConsentCategories(cc.id, ECCEditTabs.requestDomains);
  }

  onEditCC(event, cc) {
    event.stopPropagation();

    this.clicked = undefined;
    this.ccService.selectedCCId = cc.id;

    this.editConsentCategories(cc.id, ECCEditTabs.cookies, false, false);
  }

  private editConsentCategories(consentCategoryId: number, tab: ECCEditTabs, forceUpdate = false, isCopyOperation = false) {
    const data: IEditConsentCategoryModal = {
      consentCategoryId,
      tab,
      newCopyCC: isCopyOperation,
    };
    this.opModalService.openFixedSizeModal(ConsentCategoriesEditComponent, { disableClose: true, data })
      .afterClosed()
      .subscribe(result => {
        if (result || forceUpdate) this.reloadData();
      });
  }

  private reloadData() {
    this.labelService.getLabels().subscribe(labels => {
      this.allLabels = labels;
      if(this.loading) return;
      this.loadConsentCategories();
    });
  }

  async onCopyCC(event: MouseEvent, cc: IConsentCategory) {
    event.stopPropagation();

    this.clicked = undefined;
    let copiedCC = await this.ccService.copyConsentCategory(cc).toPromise();
    this.editConsentCategories(copiedCC.id, ECCEditTabs.cookies, true, true);
  }

  onDeleteCC(event: MouseEvent, cc: IConsentCategory) {
    event.stopPropagation();
    this.clicked = undefined;

    this.openDeleteConfirmationModal(cc);
  }

  openDeleteConfirmationModal(cc: IConsentCategory): void {
    this.modalService.openModal(OpDeleteItemWarningComponent, {
      data: {
        itemType: 'Consent Category',
        name: cc.name
      }
    })
      .afterClosed()
      .subscribe((confirmDelete: boolean) => {
        if (confirmDelete) {
          this.deleteConsentCategory(cc);
        }
      });
  }

  deleteConsentCategory(cc: IConsentCategory): void {
    this.ccService.deleteConsentCategory(cc.id)
      .toPromise()
      .then(() => {
        const updatedCCs = [...this.dataSource.data];
        const removedIndex = updatedCCs.findIndex((item: any) => item.id === cc.id);
        const [deletedCC] = updatedCCs.splice(removedIndex, 1);
        this.dataSource.data = updatedCCs;

        this.ccService.getConsentCategoriesLibrary(this.getCCLibraryOptions()).pipe(
          takeUntil(this.destroy$)
        ).subscribe(({ metadata: { pagination }, consentCategories }: IConsentCategoryLibraryDTO) => {
          this.consentCategories = consentCategories;
          this.pagination = pagination;
          this.loading = false;
          this.showDeleteSuccessSnackbar(deletedCC);
          this.standardsSelectorService.invalidateConsentCatCache();
        });
      }, err => {
        if (err.code === 404) {
          this.showPermissionsErrorSnackbar();
        }
      });
  }

  getCCLibraryOptions(): ICCLibraryQueryParams {
    const { sortBy, sortDesc } = this.sortOptions;
    const page = this.pagination.currentPageNumber;
    const { pageSize } = this.pagination;
    const filters = this.filterBarService.currentRelevantFilters;
    let name, type, labels;
    if (filters) {
      let labelIds = [];
      filters.forEach((filter) => {
        if (filter['type'] === 'name') name = encodeURIComponent(filter.value as string);
        if (filter['type'] === 'type') type = filter.value;
        if (filter['type'] === 'label') labelIds.push(filter.value);
      });

      if (labelIds.length > 0) labels = labelIds.toString();
    }

    return { page, pageSize, name, type, labels, sortBy, sortDesc };
  }

  onViewChangeLog(event, cc) {
    event.stopPropagation();

    this.clicked = undefined;
    this.router.navigate([AccountSettingsUrlBuilders.userEvents()], {
      state: {
        itemType: 'consent-category',
        itemId: cc.id
      }
    });
  }

  clickAuditCount(event, cc) {
    if (cc.auditCount === 0) {
      event.stopPropagation();
      return; // Don't do anything if no audits assigned
    }

    if (this.isClickInActiveRow(cc) && this.clicked !== 'audits') {
      event.stopPropagation();
      this.lastOpenedNestedTableHeight = 0;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = this.lastOpenedNestedTable?.getBoundingClientRect().height;
        this.cdr.detectChanges();
      }, 1000);
    }

    this.clicked = 'audits';
  }

  clickNote(event, cc) {
    if (this.isClickInActiveRow(cc) && this.clicked !== 'notes') {
      event.stopPropagation();
    }
    this.clicked = 'notes';
  }

  exportConsentCategories() {

    this.ccService.exportConsentCategories().subscribe(
        (exportResponse: IExportResponse) => {
          if(exportResponse && exportResponse.exportId) {
            this.showExportProgress(exportResponse.exportId);
          } else {
            this.onExportFailure();
          }
        },
        (error) => {
          this.onExportFailure();
        }
      );
  }

  protected showExportProgress(exportId: number) {
    this.accountsService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        const message = user.email;
        this.modalService.openModal(ExportReportModalComponent, {
            width: ExportModal.width,
            height: ExportModal.height,
            disableClose: true,
            data: {
              message: message,
              reportItem: {},
              exportId: exportId,
            }
          }, 'exported-consent-category-modal');
        }
      )
  }

  private onExportFailure(): void {
    const message = `There was an error exporting these consent categories`;
    this.snackbar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  updateNote(ccData) {
    this.noteSaved = false;
    const body = { name: ccData.name, notes: ccData.notes, type: ccData.type, isDefaultCC: ccData.isDefaultCC, cmpData: ccData.cmpData };
    this.ccService.updateConsentCategory(ccData.id, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res: IConsentCategoryBase) => {
      const updatedIndex = this.consentCategories.findIndex(cc => cc.id === ccData.id);
      this.consentCategories[updatedIndex].notes = res.notes;
      this.noteSaved = true;
      this.noteSavedTimeout = setTimeout(() => {
        this.noteSaved = false;
      }, 5000);
    });
  }

  debouncedUpdateNote(event, cc: IConsentCategoryTableRow) {
    const noteData = { name: cc.name, notes: event.target.value, type: cc.type, id: cc.id };

    this.noteSubject.next(noteData);
  }

  selectChip(addedLabel: { id: number, name: string }, cc: IConsentCategoryTableRow) {
    const oldLabelIds = cc.labels.map((label: { id: number, name: string }) => label.id);
    const newLabelIds = [...oldLabelIds, addedLabel.id];

    this.ccService.patchConsentCategoryLabels(cc.id, oldLabelIds, newLabelIds).subscribe(() => {
    });
  }

  removeChip(removedLabel: { id: number, name: string }, cc: IConsentCategoryTableRow) {
    const newLabelIds = cc.labels.map((label: { id: number, name: string }) => label.id);
    const oldLabelIds = [...newLabelIds, removedLabel.id];

    this.ccService.patchConsentCategoryLabels(cc.id, oldLabelIds, newLabelIds).subscribe(() => {
    });
  }

  lastOpenedNestedTableHeight: number;
  lastOpenedNestedTable: HTMLDivElement;

  animationDone($event: AnimationEvent, container: HTMLDivElement) {
    if (($event.fromState === 'loading' && $event.toState === 'expanded')
      || ($event.fromState === 'collapsed' && $event.toState === 'expanded')) {
      this.lastOpenedNestedTable = container;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = container.getBoundingClientRect().height;
        this.cdr.detectChanges();
      }, 50);
    }

    if ($event.fromState === 'expanded' && $event.toState === 'collapsed') {
      this.lastOpenedNestedTable = null;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = 0;
        this.cdr.detectChanges();
      }, 300);
    }
  }
}
