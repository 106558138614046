import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NotificationPolicies } from './alert-notification.constants';
import { EAlertModalType } from '@app/components/alert/alert.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-notification',
  templateUrl: './alert-notification.component.html',
  styleUrls: ['./alert-notification.component.scss']
})
export class AlertNotificationComponent {

  @Input() formGroup: UntypedFormGroup;
  @Input() modalType: EAlertModalType;
  @Input() readOnlyLabel: string;

  readonly EAlertModalType = EAlertModalType;
  readonly NotificationPolicies = NotificationPolicies;

  get messageControl(): UntypedFormControl {
    return this.formGroup.get('message') as UntypedFormControl;
  }

}
