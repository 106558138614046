import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { Observable, of, Subject } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EEmailInboxModalType, EEmailInboxStep } from './email-inbox-editor.constants';
import { IButton } from '@app/models/commons';
import { IUser } from '@app/moonbeamModels';
import { AccountsService } from '@app/components/account/account.service';
import {
  IEmailInbox,
  IEmailInboxConfigurationDTO,
  IEmailInboxEditorModalPayload,
} from '@app/components/email-inboxes/email-inboxes.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailInboxesService } from '@app/components/email-inboxes/email-inboxes.service';
import {
  EEmailInboxSuccessType,
  EmailInboxChangeSuccessSnackbarComponent
} from '@app/components/email-inboxes/email-inbox-change-success-snackbar/email-inbox-change-success-snackbar.component';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import {
  EmailInboxNotCreatedSnackbarComponent
} from '@app/components/email-inboxes/email-inbox-not-created-snackbar/email-inbox-not-created-snackbar.component';
import {
  EmailInboxQuickSetupSuccessSnackbarComponent
} from '@app/components/email-inboxes/email-inbox-quick-setup-success-snackbar/email-inbox-quick-setup-success-snackbar.component';
import { IAuditModel } from '@app/components/modals/modalData';
import { NotificationCenterService } from '@app/components/notifications-center/notification-center.service';
import {
  ENotificationCenterTargetItemType,
  INotificationCenterEmailsResponse
} from '@app/components/notifications-center/notification-center.models';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';

@Component({
  selector: 'op-email-inbox-editor',
  templateUrl: './email-inbox-editor.component.html',
  styleUrls: ['./email-inbox-editor.component.scss']
})
export class EmailInboxEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroySubject = new Subject<void>();

  emailInboxForm: UntypedFormGroup;
  labels: ILabel[] = [];
  modalType: EEmailInboxModalType = EEmailInboxModalType.QuickCreate;
  currentStep = EEmailInboxStep.Inbox;
  user: IUser;
  userEmail: string;

  associatedAuditIds: number[] = [];
  configuredAudits: IEmailInboxConfigurationDTO[] = [];
  configuredAuditsAmount: number = 0;
  associatedAuditItems: IEmailInboxConfigurationDTO[] = [];
  auditsToDisassociate: IEmailInboxConfigurationDTO[] = [];

  emailInboxData: IEmailInbox = null;
  receivedSubscribers: string[] = [];
  processedSubscribers: string[] = [];
  shouldSaveReceivedSubscribers: boolean = false;
  emailsSubscribedToPopulated: boolean = false;
  loading: boolean = false;
  destroy$ = new Subject();

  // delete this when step 2 is rebuilt
  // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  labelsMap = new Map<number, ILabel>();
  // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  private backButton: IButton = {
    label: 'Back',
    icon: 'icon-back-empty',
    action: () => this.previousStep(),
    hidden: true
  };

  private nextButton: IButton = {
    label: 'Next',
    icon: 'icon-forward-empty modal-footer-button-icon',
    action: () => this.nextStep(),
    primary: true,
    hidden: false,
  };

  private saveButton: IButton = {
    label: 'Save',
    action: () => this.save(),
    primary: true,
    hidden: false
  };

  rightFooterButtons = [this.backButton, this.nextButton, this.saveButton];

  readonly EEmailInboxStep = EEmailInboxStep;

  constructor(
    private dialogRef: MatDialogRef<EmailInboxEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public payload: IEmailInboxEditorModalPayload,
    private formBuilder: UntypedFormBuilder,
    private labelService: LabelService,
    private accountsService: AccountsService,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private emailInboxesService: EmailInboxesService,
    private notificationCenterService: NotificationCenterService,
    private auditService: DiscoveryAuditService,
    private snackbarService: SnackbarService,
  ) {
    this.modalType = payload.type;
    this.setInitialStep(payload);
  }

  ngOnInit(): void {
    this.initForm();
    this.loadData();
    this.updateButtons();
  }

  ngAfterViewInit(): void {
    if (this.isPrefilledMode(this.payload?.type) || this.isEditMode(this.payload?.type) || this.isDuplicatingMode(this.payload?.type)) {
      this.emailInboxForm.patchValue({
        name: this.payload.name,
        labels: this.payload.labels,
        notes: this.payload.notes,
      }, { emitEvent: true });

      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  initForm(): void {
    this.emailInboxForm = this.formBuilder.group({
      name: this.formBuilder.control(null, [Validators.required]),
      labels: this.formBuilder.array(this.payload.id ? this.payload.labels : []),
      notes: this.formBuilder.control(null),
    });
  }

  async onAuditUpdated(item: IAuditModel): Promise<void> {
    const audit = await this.auditService.getAudit(item.id);
    const auditIndex = this.configuredAudits.findIndex(a => a.auditId === audit.id);

    const updatedAudit = {
      auditId: audit.id,
      auditName: audit.name,
      auditLastRunAt: String(audit.lastRun),
      auditLabels: audit.labels.map(label => label.id),
    };

    if (auditIndex !== -1) {
      this.configuredAudits[auditIndex] = updatedAudit;
    } else {
      this.configuredAudits = [updatedAudit, ...this.configuredAudits];
    }
  }

  loadData(): void {
    this.labelService.allLabels$.pipe(takeUntil(this.destroy$)).subscribe((labels) => {
      this.labels = labels;
      this.labelsMap = ArrayUtils.toMap(labels, 'id');
    });

    this.accountsService.getUser().subscribe((user: IUser) => {
      this.user = user;
      this.userEmail = user.email;
    });

    if (this.payload.id) {
      this.getInboxAuditAssociations(this.payload.id)
        .pipe(
          switchMap((auditAssociations: IEmailInboxConfigurationDTO[]) => {
            this.configuredAudits = [...new Set([...auditAssociations, ...this.configuredAudits])];
            this.associatedAuditItems = [...new Set([...auditAssociations, ...this.configuredAudits])];
            this.associatedAuditIds = [...new Set(auditAssociations.map(association => association.auditId))];

            return this.emailInboxesService.getEmailInbox(this.payload.id);
          }))
        .subscribe((inbox: IEmailInbox) => {
          this.emailInboxData = inbox;
        });
    }
  }

  getEmailsSubscribedTo(): Observable<INotificationCenterEmailsResponse> {
    return this.notificationCenterService.getEmailsSubscribedTo(
      ENotificationCenterTargetItemType.AUDIT,
      this.configuredAudits.map(a => a.auditId),
    );
  }

  populateEmailsSubscribedTo(): void {
    if (
      this.isEditMode(this.payload.type)
      || this.emailsSubscribedToPopulated
      || !this.configuredAudits?.length
    ) {
      return;
    }
    this.enableLoadingIndicator();
    this.getEmailsSubscribedTo().subscribe((data) => {
      this.emailInboxData = {
        ...this.emailInboxData,
        subscribers: {
          onReceived: this.emailInboxData?.subscribers.onReceived,
          onProcessed: data.emails?.map((email) => email.email) || [],
        },
      };
      this.emailsSubscribedToPopulated = true;
      this.disableLoadingIndicator();
    }, error => {
      this.disableLoadingIndicator();
    });
  }

  getInboxAuditAssociations(inboxId: number): Observable<IEmailInboxConfigurationDTO[]> {
    return this.emailInboxesService.getInboxAuditAssociations(inboxId);
  }

  setSelectedAudits(value: IEmailInboxConfigurationDTO[]): void {
    this.configuredAudits = value;
    this.configuredAuditsAmount = value.length;
  }

  saveDisassociatedAudits(value: IEmailInboxConfigurationDTO[]): void {
    this.configuredAuditsAmount = this.associatedAuditItems.length - value.length;
    this.auditsToDisassociate = value;
  }

  updateReceivedSubscribers(emails: string[]): void {
    this.receivedSubscribers = emails;
  }

  updateProcessedSubscribers(emails: string[]): void {
    this.processedSubscribers = emails;
  }

  updateShouldSaveReceivedSubscribers(value: boolean): void {
    this.shouldSaveReceivedSubscribers = value;
  }

  onCreateLabel(label): void {
    let labels = [...this.labelsControl.value, label];
    this.labelsControl.setValue([]);
    this.labelsControl.patchValue(labels);
  }

  close(): void {
    if (!this.payload.id) {
      // Temp thing just to check different snackbars
      const component = parseInt(localStorage.getItem('qsSnack'))
        ? EmailInboxQuickSetupSuccessSnackbarComponent
        : EmailInboxNotCreatedSnackbarComponent;

      this.snackbar.openFromComponent(component as any, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackbar-wide-width'
      });
    }

    this.dialogRef.close();
  }

  nextStep() {
    this.goToStep(this.currentStep + 1);
  }

  previousStep() {
    this.goToStep(this.currentStep - 1);
  }

  goToStep(stepId: EEmailInboxStep): void {
    // name's validity is not important when switching steps
    this.name.clearValidators();
    this.name.updateValueAndValidity();

    if (this.emailInboxForm.invalid) {
      this.emailInboxForm.markAllAsTouched();
      return;
    }

    this.currentStep = stepId;
    this.updateButtons();

    // Due to the design when all tabs exist simultaneously
    // this action moved from init of the notifications tab
    if (stepId === EEmailInboxStep.Notifications) {
      this.populateEmailsSubscribedTo();
    }
  }

  private updateButtons(): void {
    this.backButton.hidden = this.currentStep === EEmailInboxStep.Inbox;
    this.nextButton.hidden = this.currentStep === EEmailInboxStep.Notifications;
    const isReadOnly = this.isReadOnlyMode(this.payload.type);
    this.saveButton.hidden = this.isEditMode(this.payload.type) ? false : isReadOnly;
    if (!this.isEditMode(this.payload.type) && this.currentStep === EEmailInboxStep.Notifications) {
      this.saveButton.label = 'SAVE & CREATE INBOX ADDRESS';
    }
  }

  get name(): UntypedFormControl {
    return this.emailInboxForm.get('name') as UntypedFormControl;
  }

  // delete this when step 2 is rebuilt
  // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  get dataSourcesFormGroup(): UntypedFormGroup {
    return this.emailInboxForm.get('dataSources') as UntypedFormGroup;
  }

  get associatedAudits(): UntypedFormControl {
    return this.dataSourcesFormGroup.get('items') as UntypedFormControl;
  }

  // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  isFromScratchMode(payload) {
    return payload.type === EEmailInboxModalType.QuickCreate || payload.type === EEmailInboxModalType.AdvancedCreate;
  }

  isPrefilledMode(type) {
    return type === EEmailInboxModalType.CreatePrefilled;
  }

  isReadOnlyMode(type): boolean {
    return type === EEmailInboxModalType.ReadOnly;
  }

  isEditMode(type): boolean {
    return type === EEmailInboxModalType.Edit;
  }

  isDuplicatingMode(type) {
    return type === EEmailInboxModalType.Duplicate;
  }

  getSuccessTypeFromModalType(modalType: EEmailInboxModalType): EEmailInboxSuccessType {
    switch (this.payload.type) {
      case EEmailInboxModalType.QuickCreate:
      case EEmailInboxModalType.AdvancedCreate:
      case EEmailInboxModalType.CreatePrefilled:
        return EEmailInboxSuccessType.Created;
      case EEmailInboxModalType.Duplicate:
        return EEmailInboxSuccessType.Duplicated;
      case EEmailInboxModalType.Edit:
        return EEmailInboxSuccessType.Edited;
    }
  }

  save(): void {
    const { name, labels, notes } = this.emailInboxForm.getRawValue();
    const associatedAuditIds = this.configuredAudits.map(a => a.auditId);
    const auditsToDisassociateIds = this.auditsToDisassociate.map(a => a.auditId);

    const auditsToAssociate = associatedAuditIds.filter(auditId => !auditsToDisassociateIds.includes(auditId));

    const inboxData = {
      name,
      labels,
      labelIds: labels.map(label => label.id),
      notes,
      type: this.getSuccessTypeFromModalType(this.payload.type),
      id: this.payload.id,
      emailAddress: this.payload.emailAddress,
      subscribers: {
        onReceived: this.shouldSaveReceivedSubscribers ? this.receivedSubscribers : [],
        onProcessed: this.processedSubscribers,
      }
    };

    // name's validity is important when saving an alert
    this.name.setValidators(Validators.required);
    this.name.updateValueAndValidity();
    if (this.emailInboxForm.invalid) {
      this.emailInboxForm.markAllAsTouched();
      return;
    }

    // disable the ability to accidentally create an alert twice
    this.enableLoadingIndicator();
    (this.isEditMode(this.payload.type)
      ? this.emailInboxesService.updateEmailInbox(inboxData)
      : this.emailInboxesService.createInbox(inboxData)
    )
      .pipe(
        switchMap((res: IEmailInbox) =>
          this.emailInboxesService.updateInboxWithAuditAssociations(res.id, auditsToAssociate)
            .pipe(tap(() => this.showSnackbar(res, inboxData))),
        ),
        finalize(() => this.disableLoadingIndicator()),
      )
      .subscribe(
        () => {
          this.dialogRef.close(inboxData);
        },
        error => {
          const message = `Failed to save email inbox. Status: ${error?.statusCode}`;
          const messageWithReason = error?.statusCode === 400
            ? `${message}. Reason: ${error?.errorCode?.message || error?.message}`
            : message;
          console.error(messageWithReason);
          this.snackbarService.openErrorSnackbar(messageWithReason, { duration: 10000 });
        });
  }

  private showSnackbar(res: IEmailInbox, inboxData): void {
    this.snackbar.openFromComponent(EmailInboxChangeSuccessSnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        ...res,
        type: inboxData.type,
        edit: this.payload.edit,
        audits: this.configuredAudits,
      },
      panelClass: 'snackbar-wide-width'
    });
  }

  onAuditCreated(audit: IAuditModel): void {
    this.configuredAudits = [
      {
        auditId: audit.id,
        auditName: audit.name,
        auditLastRunAt: String(audit.lastRun),
        auditLabels: audit.labels.map(label => label.id),
      },
      ...this.configuredAudits
    ];
  }

  get labelsControl(): UntypedFormControl {
    return this.emailInboxForm.get('labels') as UntypedFormControl;
  }

  private setInitialStep(payload: IEmailInboxEditorModalPayload): void {
    if(!payload?.step) {
      return;
    }

    this.currentStep = payload.step;
  }

  private enableLoadingIndicator() {
    this.loading = true;
    this.saveButton.disabled = true;
    this.backButton.disabled = true;
  }

  private disableLoadingIndicator() {
    this.loading = false;
    this.saveButton.disabled = false;
    this.backButton.disabled = false;
  }
}
