<op-modal (onClose)="close()" [rightFooterButtons]="rightFooterButtons">
  <ng-template #headerTemplate>
    <op-modal-header-base title="Email Inbox" (onClose)="close()">
      <email-inbox-editor-header
        [formGroup]="emailInboxForm"
        [labels]="labels"
        (onCreateLabel)="onCreateLabel($event)"
        [readOnly]="isReadOnlyMode(payload?.type)"
        [modalType]="modalType"
      >
      </email-inbox-editor-header>
    </op-modal-header-base>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="form-container">
      <div class="spinner-container" *ngIf="loading">
        <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
      </div>
      <op-email-inbox-editor-steps
        [formGroup]="emailInboxForm"
        [currentStep]="currentStep"
        (stepSelected)="goToStep($event)"
        [configuredAudits]="configuredAuditsAmount"
        [notificationEmailsCount]="processedSubscribers.length"
        [displayAuditsCount]="isEditMode(payload.type)"
        [emailInboxAddress]="payload.emailAddress"
      >
      </op-email-inbox-editor-steps>

      <div class="content">
        <op-email-inbox-editor-step-1
          class="tab-content"
          [class.visible]="currentStep === EEmailInboxStep.Inbox"
          [formGroup]="emailInboxForm"
          [emailInboxAddress]="payload.emailAddress"
          [modalType]="modalType"
          [userEmail]="userEmail"
        >
        </op-email-inbox-editor-step-1>

        <!-- this will be deleted and step 2 rebuilt -->
        <op-email-inbox-editor-data-sources
          class="tab-content"
          [class.visible]="currentStep === EEmailInboxStep.Testing"
          [modalType]="modalType"
          readOnlyLabel="You do not have editing rights for this alert"
          [labelsMap]="labelsMap"
          [associatedAuditItems]="configuredAudits"
          (selectedAuditsChange)="setSelectedAudits($event)"
          (auditsDisassociated)="saveDisassociatedAudits($event)"
          (auditUpdated)="onAuditUpdated($event)"
          (auditCreated)="onAuditCreated($event)"
        >
        </op-email-inbox-editor-data-sources>

        <op-email-inbox-editor-notifications
          class="tab-content"
          [class.visible]="currentStep === EEmailInboxStep.Notifications"
          (processedSubscribersChanged)="updateProcessedSubscribers($event)"
          (receivedSubscribersChanged)="updateReceivedSubscribers($event)"
          (shouldSaveReceivedSubscribersChanged)="updateShouldSaveReceivedSubscribers($event)"
          [emailInboxData]="emailInboxData">
        </op-email-inbox-editor-notifications>
      </div>
    </div>
  </ng-template>
</op-modal>
