import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import {
  ExportReportSuccessSnackbarComponent
} from '@app/components/shared/components/export-report/export-report-success-snackbar/export-report-success-snackbar.component';
import {
  ExportReportErrorSnackbarComponent
} from '@app/components/shared/components/export-report/export-report-error-snackbar/export-report-error-snackbar.component';
import { AccountsService } from '@app/components/account/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UsageV2Service {

  apiRoot: string = `${environment.apiV3Url}usage`;

  constructor(
    private accountsService: AccountsService,
    private snackbar: MatSnackBar,
  ) {
  }

  showExportSuccessSnackbar() {
    this.accountsService
      .getUser()
      .subscribe(user => this.snackbar.openFromComponent(ExportReportSuccessSnackbarComponent, {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'email-report-snackbar',
        data: {
          email: user.email
        }
      }));
  }

  showExportErrorSnackbar() {
    return this.snackbar
      .openFromComponent(
        ExportReportErrorSnackbarComponent, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'email-report-error-snackbar',
          data: {}
        }
      );
  }
}
