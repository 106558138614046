<op-modal [leftFooterButtons]="leftFooterButtons"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header
      [title]="title"
      (onClose)="close()">
    </op-modal-header>
  </ng-template>
  
  <ng-template #bodyTemplate>
    <div class="new-user-confirm">
      <mat-icon class="big-blue-check">check_circle</mat-icon>
      <h3>That's it!</h3>
      <p>{{ name }} is all set to get going on the ObservePoint platform!</p>
      <p>We've sent {{ name }} a welcome email.</p>
    </div>
  </ng-template>
</op-modal>