import { Component, Input } from '@angular/core';
import {
  EAccessibilityImpact,
  IAccessibilityRuleResult,
  IAuditPageInfoAccessibilityChecksResults
} from '@app/components/audit-reports/page-details/page-details.models';
import {
  PageDetailsAccessibilityService
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.service';
import {
  IPagesDetailsAccessibilitySeverityFilter
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-details-accessibility-widget-bar',
  templateUrl: './page-details-accessibility-widget-bar.component.html',
  styleUrls: ['./page-details-accessibility-widget-bar.component.scss']
})
export class PageDetailsAccessibilityWidgetBarComponent {
  widgets: IPagesDetailsAccessibilitySeverityFilter[] = Object.values(this.accessibilityService.filters.value.severity);
  groupedIssues: Record<EAccessibilityImpact, IAccessibilityRuleResult[]>;

  @Input() accessibilityChecksSummary: IAuditPageInfoAccessibilityChecksResults;

  @Input() set filteredItems(items: IAccessibilityRuleResult[]) {
    this._filteredItems = items;
    this.sortIssues(items);
  }

  private sortIssues(items: IAccessibilityRuleResult[]): void {
    this.groupedIssues = items.reduce((acc, item) => {
      if (!acc[item.impact]) {
        acc[item.impact] = [];
      }
      acc[item.impact].push(item);
      return acc;
    }, {} as Record<EAccessibilityImpact, IAccessibilityRuleResult[]>);
  }

  private _filteredItems: IAccessibilityRuleResult[];

  get filteredItems(): IAccessibilityRuleResult[] {
    return this._filteredItems;
  }

  get totalPageIssues(): number {
    return this.accessibilityChecksSummary?.issues.length + this.accessibilityChecksSummary?.inconclusive.length;
  }

  get filteredPageIssuesNumber(): number {
    return this.filteredItems?.length;
  }

  updateSeverity(severity: IPagesDetailsAccessibilitySeverityFilter): void {
    this.accessibilityService.updateSeverity(severity);
  }

  constructor(private accessibilityService: PageDetailsAccessibilityService) {
  }
}
