<op-modal [leftFooterButtons]="[]"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Add New Tag"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="add-new-tag">
      <form [formGroup]="newTagForm" class="new-tag-form">
        <div class="tag-picker-wrapper">
          <op-tag-picker [clearOnSelect]="false"
                         [tags]="tags"
                         appearance="outline"
                         placeholder="Tag Name"
                         (selected)="onSelectTag($event)">
          </op-tag-picker>
        </div>
        <div class="tag-account-list-wrapper">
          <mat-error *ngIf="tag.invalid && tag.touched">This field is required</mat-error>

          <mat-form-field class="tag-account-list chips no-hint-container" appearance="outline">
            <mat-chip-grid #accountList formArrayName="accounts">
              <mat-chip-row *ngFor="let account of accounts.value; let i = index;" selectable="false"
                      removable="true" (removed)="removeAccount(i)">
                {{ account }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip-row>
              <input placeholder="Enter Account (or leave empty for any account)"
                     [matChipInputFor]="accountList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     matChipInputAddOnBlur="true"
                     (matChipInputTokenEnd)="addAccount($event)">
            </mat-chip-grid>
          </mat-form-field>
        </div>
      </form>
    </div>
  </ng-template>
</op-modal>
