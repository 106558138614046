import {
  ITagsVariablesResults,
  ITagVariableResult
} from '@app/components/ror-comparison-report/ror-comparison-variable-presence/ror-comparison-variable-presence.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RorComparisonReportService } from '@app/components/ror-comparison-report/ror-comparison-report.service';
import { userIsAdmin } from '@app/authUtils';
import { IUser } from '@app/moonbeamModels';
import { IAccountTagSettings } from '@app/components/comparisons/comparisons.models';

import { IUiTag } from '@app/components/tag-database/tag-database.model';

export class RorComparisonVariableReport {
  auditId: number;
  runId: number;
  comparisonSnapshotId: number;
  
  isAllComparisonsSelected: boolean;
  isCurrentUserAdmin: boolean;

  tagsVariables: ITagsVariablesResults[];
  tagIdToTagMap: Map<number, IUiTag>;

  itemsCount: number = 0;
  loaded: boolean = false;
  
  constructor(protected snackBar: MatSnackBar, 
              protected comparisonReportService: RorComparisonReportService) { }
  
  initialiseCommonData(tagsVariables: ITagsVariablesResults[], allTags: IUiTag[], comparisonTags: IAccountTagSettings[], user: IUser) {
    this.isCurrentUserAdmin = userIsAdmin(user);
    this.tagsVariables = tagsVariables;
    this.tagIdToTagMap = allTags.reduce((map, tag) => map.set(tag.id, tag), new Map());
    this.itemsCount = tagsVariables.reduce(
      (count, tag) => count += tag.variables.length,
      0
    );
    this.loaded = true;

    this.tagsVariables.forEach(tv => {
      const tag = comparisonTags.find(ct => ct.tagId === tv.tagId);
      tv.variables.forEach(v => {
        v.excluded = tag.excludedVariables.includes(v.variableName);
      });
    });
  }

  excludeVariable(tagId: number, variable: ITagVariableResult): void {
    if (this.isAllComparisonsSelected && !this.isCurrentUserAdmin) {
      this.showExclusionFailedMessage(
        'Only Account Admin is allowed to exclude variable from All Comparisons. Please select a specific comparison in the dropdown above.'
      );
      return;
    }
    const comparisonSnapshotId = this.isAllComparisonsSelected ? undefined : this.comparisonSnapshotId;
    this.comparisonReportService.excludeVariable(tagId, variable.variableName, comparisonSnapshotId).subscribe(() => {
      if (!comparisonSnapshotId) {
        variable.excluded = true;
      }
      this.showVarExcludedMessage(comparisonSnapshotId);
    }, () => {
      this.showExclusionFailedMessage(`Failed to exclude variable`);
    });
  }

  private showVarExcludedMessage(comparisonSnapshotId?: number): void {
    const message = comparisonSnapshotId
      ? `Variable has been excluded for the selected comparison`
      : `Variable has been excluded in account level comparison settings`;
    this.snackBar.open(
      message,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom', panelClass: 'var-deleted-snackbar' }
    );
  }

  private showExclusionFailedMessage(message: string): void {
    this.snackBar.open(
      message,
      '',
      // weird but it it will be shown in a top right corner
      { duration: 8000, horizontalPosition: 'end', verticalPosition: 'bottom', panelClass: 'var-deleted-snackbar' }
    );
  }
}
