<!-- no alerts -- open quick create -->
<ng-container *ngIf="alerts.length === 0 || isVisitorMode">

  <!-- Removed the tooltip when the icon isn't already visible (creating a new alert). Weird issue where mat-tooltip immediately draws a transparent placeholder, which forces the element to blur and creates an awkward flickering effect with our hover handlers. -->
  <alert-bell [status]="status"
              [hoverStatus]="hoverStatus"
              [count]="alertCount"
              (click)="$event.preventDefault(); $event.stopPropagation();"
              (onClick)="openQuickCreateModal()"
              class="create-new-alert-icon">
  </alert-bell>
</ng-container>

<!-- if there are >= 1 alerts then show a menu -->
<ng-container *ngIf="alerts.length >= 1 && !isVisitorMode">
  <alert-bell [matMenuTriggerFor]="menu"
              [status]="status"
              [hoverStatus]="hoverStatus"
              [count]="alertCount"
              (click)="$event.preventDefault(); $event.stopPropagation();"
              [tooltipPlaceholder]="tooltip">
  </alert-bell>
  <mat-menu #menu="matMenu"
            class="full-width-menu-dropdown"
            backdropClass="no-filter">
    <button *ngFor="let alert of alerts" mat-menu-item type="button" class="op-widget-bell-menu-option">
      <div class="widget-bell-menu-contents"
           [class.deleted-alert]="!alert.alertConfigExists"
           [class.triggered-red]="alert.status === 'TRIGGERED' && alert.isSubscribed"
           [matTooltip]="!alert.alertConfigExists ? 'Alert configuration no longer exists because it has been deleted' : null"
           matTooltipPosition="above"
           matTooltipShowDelay="250"
      >
        <div class="widget-bell-menu-left" (click)="handleNameClick(alert)">
          <!-- exclamation ringing bell -->
          <mat-icon *ngIf="alert.status === 'TRIGGERED' && alert.isSubscribed"
                    svgIcon="bell-filled-triggered"
                    [matTooltip]="alert.alertConfigExists ? 'You are subscribed to this triggered alert.' : null"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    class="custom-icon">
          </mat-icon>

          <!-- empty exclamation ringing bell -->
          <mat-icon *ngIf="alert.status === 'TRIGGERED' && !alert.isSubscribed"
                    svgIcon="bell-empty-triggered"
                    [matTooltip]="alert.alertConfigExists ? 'You are not subscribed to this triggered alert.' : null"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    class="custom-icon">
          </mat-icon>
          <!-- checked bell -->
          <mat-icon *ngIf="alert.status !== 'TRIGGERED' && alert.isSubscribed"
                    svgIcon="bell-check"
                    [matTooltip]="alert.alertConfigExists ? 'You are subscribed to this non-triggered alert.' : null"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    class="custom-icon">
          </mat-icon>
          <!-- empty bell -->
          <mat-icon *ngIf="alert.status !== 'TRIGGERED' && !alert.isSubscribed"
                    [matTooltip]="alert.alertConfigExists ? 'You are not subscribed to this non-triggered alert.' : null"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
          >
            notifications_none
          </mat-icon>
          <div class="alert-name" matTooltip="Reset report with any filters related to this alert." matTooltipPosition="above">
            {{ alert.name }} alert
          </div>
          <mat-icon class="alert-redirect" *ngIf="alert.alertConfigExists">arrow_forward</mat-icon>
        </div>
        <div class="widget-bell-menu-right" *ngIf="alert.alertConfigExists">
          <span class="vertical-divider">|</span> <mat-icon class="alert-edit" matTooltip="Edit alert" matTooltipPosition="above" (click)="openAlertEditor(alert)">edit</mat-icon>
        </div>
      </div>
    </button>
    <button class="create-new-alert-button" (click)="openQuickCreateModal()" mat-menu-item type="button">
      <mat-icon>add_circle_outline</mat-icon>
      Create New Alert
    </button>
  </mat-menu>
</ng-container>
