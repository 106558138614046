import { Component, Input, ViewChild } from '@angular/core';
import { IUsersUsage } from '@app/components/usage-v2/usage-v2.models';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-users-overlay-trigger',
  templateUrl: './usage-users-overlay-trigger.component.html',
  styleUrls: ['./usage-users-overlay-trigger.component.scss']
})
export class UsageUsersOverlayTriggerComponent {
  @Input() usersUsage: IUsersUsage;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
}
