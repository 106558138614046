import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EAlertsLibraryFilterTypes } from './alerts-library.enums';
import { ALERTS_LIBRARY_FILTERS_STORAGE_KEY } from '@app/components/alerts-library/alerts-library.constants';
import { ILabel } from '@app/components/shared/services/label.service';
import { AlertService } from '@app/components/alert/alert.service';
import { MetricTypeFilterUtils } from '@app/components/alert/alert-logic/metric-type-filter/metric-type-filter.utils';
import { AlertMetricType } from '@app/components/alert/alert-logic/alert-logic.enums';

@Injectable()
export class AlertsLibraryFilterBarService extends OpFilterBarService<EAlertsLibraryFilterTypes> {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService,
    private alertService: AlertService
  ) {
    super(storage, ALERTS_LIBRARY_FILTERS_STORAGE_KEY, cacheReset, new Map([[EAlertsLibraryFilterTypes.Labels, true]]));
    this.updateSupportedFiltersList([
      EAlertsLibraryFilterTypes.Name,
      EAlertsLibraryFilterTypes.ReportMetric,
      EAlertsLibraryFilterTypes.Labels,
      EAlertsLibraryFilterTypes.CreatedBy,
      EAlertsLibraryFilterTypes.IsSubscribedOnly,
    ]);
  }

  get filters$(): Observable<IOpFilterBarFilter<EAlertsLibraryFilterTypes>[]> {
    return this.anyFiltersUpdates$
      .pipe(
        map(newFilters =>
          newFilters
            .filter(filter => this.validTypes.includes(filter.type))
            .reduce((acc, curr) => ([...acc, curr]), [])
        )
      );
  }

  addNameContainsFilter(contains: string) {
    this.addFilter({
      type: EAlertsLibraryFilterTypes.Name,
      display: `Name contains '${contains}'`,
      value: contains,
      order: 1
    });
  }

  addReportMetricFilter(reportName: string,
                        metricName: string,
                        metricType: AlertMetricType): void {
    this.addFilter({
      type: EAlertsLibraryFilterTypes.ReportMetric,
      display: MetricTypeFilterUtils.getFilterDisplay(reportName, metricName),
      value: metricType,
      order: 1
    });
  }

  addSubscribedAlertsFilter() {
    this.addFilter({
      type: EAlertsLibraryFilterTypes.IsSubscribedOnly,
      display: `Only subscribed alerts shown`,
      value: true,
      order: 3
    });
  }

  removeSubscribedAlertsFilter() {
    this.removeFilterByType(EAlertsLibraryFilterTypes.IsSubscribedOnly);
  }

  addCreatedByFilter(name: string, id: number) {
    this.addFilter({
      type: EAlertsLibraryFilterTypes.CreatedBy,
      display: `Created by '${name}'`,
      value: id,
      order: 5
    });
  }

  addLabelIdFilter({name, id}: ILabel) {
    this.addFilter({
      type: EAlertsLibraryFilterTypes.Labels,
      display: `Label: ${name}`,
      value: id,
      order: 3
    });
  }
}
