import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ILabel } from '@app/components/shared/services/label.service';
import { EEmailInboxNameControlMode } from './email-inbox-name-control/email-inbox-name-control.constants';
import { ComponentChanges } from '@app/models/commons';
import { EEmailInboxModalType } from '../email-inbox-editor.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'email-inbox-editor-header',
  templateUrl: './email-inbox-editor-header.component.html',
  styleUrls: ['./email-inbox-editor-header.component.scss']
})
export class EmailInboxEditorHeaderComponent implements OnChanges {

  @Input() formGroup: UntypedFormGroup;
  @Input() labels: ILabel[];
  @Input() modalType: EEmailInboxModalType;
  @Input() readOnly: boolean = false;
  @Output() onCreateLabel = new EventEmitter<ILabel>();

  allLabels: ILabel[] = [];

  readonly EEmailInboxNameControlMode = EEmailInboxNameControlMode;
  readonly EEmailInboxModalType = EEmailInboxModalType;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnChanges(changes: ComponentChanges<EmailInboxEditorHeaderComponent>) {
    if (changes.labels) {
      const { previousValue, currentValue } = changes.labels;
      if (previousValue !== currentValue) this.allLabels = currentValue;
    }
  }

  createLabel(label: ILabel) {
    this.onCreateLabel.emit(label);
    this.addLabel(label);
  }

  addLabel(label: ILabel): void {
    this.labelsArray.push(this.formBuilder.control(label));
  }

  removeLabel(label: ILabel): void {
    const index = (this.labelsArray.value as ILabel[]).findIndex(l => l.id === label.id);
    this.labelsArray.removeAt(index);
  }

  // when name is changed, we should recalculate labels chips by triggering change detection
  triggerLabelsRerendering() {
    this.allLabels = [...this.allLabels];
  }

  get nameControl(): UntypedFormControl {
    return this.formGroup.get('name') as UntypedFormControl;
  }

  get labelsArray(): UntypedFormArray {
    return this.formGroup.get('labels') as UntypedFormArray;
  }
}
