import { IContentItem } from './../shared/components/op-modal/op-delete-modal/op-delete-modal.models';
import { IDomain, IDomainsService } from './../domains/domainsService';
import { Inject, Injectable } from '@angular/core';
import { AngularNames } from '@app/moonbeamConstants';
import { IDomainActionsService } from '@app/store/actions/domainActions';
import { ICreateDomainRequest } from '../domains/domainsService';
import { Observable, of, from, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IUser } from '@app/moonbeamModels';
import { userIsAdmin } from '@app/authUtils';
import {
  DEFAULT_DOMAIN
} from '@app/components/bulk-operations/manage-domains/create-edit-domain/create-edit-domain.constants';

@Injectable()
export class DomainService {

  constructor(@Inject(AngularNames.ngRedux) private ngRedux: ngRedux.INgRedux,
              private domainActionsService: IDomainActionsService,
              private domainsServiceJS: IDomainsService) {}

  handleDomain(folderId: number, domain: IDomain | string, dataLayer?: string, useDefaultUrl?: boolean): Observable<IDomain> {
    return this.isDomainCreateMode(domain) ? 
      from(this.createDomain(folderId, domain, dataLayer, useDefaultUrl)) :
      of(domain);
  }
  
  isDomainCreateMode(domain: IDomain | string): domain is string {
    return typeof domain === 'string';
  }

  prepareDomainUrl(domainUrl: string, useDefault?: boolean): string {
    if (useDefault) return DEFAULT_DOMAIN;
    if (domainUrl.indexOf('https') !== -1) return domainUrl;
    if (domainUrl.indexOf('http') !== -1) return domainUrl;
    return 'http://' + domainUrl;
  }

  private createDomain(folderId: number, domainUrl: string, dataLayer?: string, useDefaultUrl?: boolean): Promise<IDomain> {
    return this.ngRedux.dispatch(
      this.domainActionsService.createDomain(this.prepareCreateDomainRequest(folderId, domainUrl, dataLayer, useDefaultUrl))
    );
  }

  // domainUrl will be the domain name if useDefaultUrl is true
  private prepareCreateDomainRequest(folderId: number, domain: string, dataLayer?: string, useDefaultUrl?: boolean): ICreateDomainRequest {
    const defaultDomainUrl = 'http://www.example.com';
    const domainObj = {
      name: !useDefaultUrl ? this.prepareDomainName(domain) : domain,
      folderId,
      domain: !useDefaultUrl ? this.prepareDomainUrl(domain) : defaultDomainUrl,
    } as ICreateDomainRequest;
    if (dataLayer) domainObj.dataLayer = dataLayer;
    return domainObj;
  }

  private prepareDomainName(url: string): string {
    var hostname;
    if (url.indexOf('://') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    return hostname;
  }

  getDomainsWithContent(folderId: number, user: IUser): Observable<IContentItem[]> {
    return from(this.domainsServiceJS.getDomains(false, folderId)).pipe(
      mergeMap(domains => {
        return domains.length > 0 ? 
          domains.map(domain => this.getDomainContent(domain, user, true)) : 
          of(of([]));
      }),
      mergeMap(domains => domains)
    );
  }

  getDomainContent(domain: IDomain, user: IUser, includeDomain = false): Observable<IContentItem[]> {
    return forkJoin([
      this.domainsServiceJS.getAuditsByDomain(domain.id),
      this.domainsServiceJS.getSimulationsByDomain(domain.id)
    ]).pipe(
      map(([audits, webJourneys]) => {
        const domainItem = {
          type: 'Domain',
          name: domain.name,
          createdAt: domain.createdAt,
          authorized: this.checkUser(domain.userId, user)
        };

        const auditItems = audits.map(audit => {
          return {
            type: 'Audit',
            name: audit.name,
            createdAt: audit.created,
            authorized: this.checkUser(audit.ownerId, user)
          };
        });

        const webJourneyItems = webJourneys.map(journey => {
          return {
            type: 'Web Journey',
            name: journey.name,
            createdAt: journey.createdAt,
            authorized: this.checkUser(journey.userId, user)
          };
        });

        const items = [...auditItems, ...webJourneyItems];
        if (includeDomain) items.unshift(domainItem);

        return items;
      })
    );
  }

  private checkUser(userId: number, currentUser: IUser): boolean {
    return userIsAdmin(currentUser) || currentUser.id === userId;
  }

}
