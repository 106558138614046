import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AccountsService } from '@app/components/account/account.service';
import {
  ETagsExpansionOPData,
  ETagView,
  ITagInstance, ITagOPDataHeaders
} from '@app/components/audit-reports/page-details-tags/page-details-tags.constants';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { SimpleRuleCreatorComponent } from '@app/components/rules/simple-rule-creator/simple-rule-creator.component';
import { ISimpleRuleCreatorPayload } from '@app/components/rules/simple-rule-creator/simple-rule-creator.model';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { ClipboardService } from 'ngx-clipboard';
import { fromPromise } from 'rxjs/internal-compatibility';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-details-tags-expansion-content',
  templateUrl: './page-details-tags-expansion-content.component.html',
  styleUrls: ['./page-details-tags-expansion-content.component.scss']
})
export class PageDetailsTagsExpansionContentComponent implements OnChanges {
  ETagView = ETagView;
  showAccount: boolean;
  showStatusCode: boolean;
  showTagSize: boolean;
  showLoadTime: boolean;
  showDuplicateRequest: boolean;
  showMultipleRequest: boolean;
  ETagsExpansionOPData = ETagsExpansionOPData;
  userIsReadOnly: boolean;

  @Input() view: ETagView = ETagView.requestView;
  @Input() data: ITagInstance;
  @Input() originTag: ITagInstance;
  @Input() opDataRowsForTag: ITagOPDataHeaders;
  @Input() variableList?: string[] = [];
  @Input() filterString?: string = '';
  @Input() itemId: number;
  @Input() canAddRule: boolean = true;

  constructor(
    private opModalService: OpModalService,
    private modalEscapeService: ModalEscapeService,
    private discoveryAuditService: DiscoveryAuditService,
    private clipboardService: ClipboardService,
    private accountsService: AccountsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.userIsReadOnly = this.accountsService.userIsReadOnly();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filterString) {
      this.filterOPVariablesForRequestView(changes.filterString.currentValue);
    }
  }

  filterOPVariablesForRequestView(filterString): void {
    this.showAccount = this.data.account && this.data.account.includes(filterString) || this.ETagsExpansionOPData.ACCOUNT.includes(filterString);
    this.showStatusCode = ('' + this.data.statusCode).includes(filterString) || this.ETagsExpansionOPData.STATUS_CODE.includes(filterString);
    this.showTagSize = ('' + this.data.bytes).includes(filterString) || this.ETagsExpansionOPData.TAG_SIZE.includes(filterString);
    this.showLoadTime = ('' + this.data.loadTime).includes(filterString) || this.ETagsExpansionOPData.LOAD_TIME.includes(filterString);
    this.showDuplicateRequest = ('' + this.data.duplicates).includes(filterString) || this.ETagsExpansionOPData.DUPLICATES.includes(filterString);
    this.showMultipleRequest = ('' + this.data.multiples).includes(filterString) || this.ETagsExpansionOPData.MULTIPLES.includes(filterString);
  }

  getTagViewTooltip(value: string, isVariable?: boolean): string {
    let tooltip = value;
    if (isVariable &&
      this.data.tagInstanceVariableValueMap.hasOwnProperty(value) &&
      this.data.tagInstanceVariableValueMap[value].hasOwnProperty(this.data.tagInstanceId) &&
      this.data.tagInstanceVariableValueMap[value][this.data.tagInstanceId]) {
      tooltip = this.data.tagInstanceVariableValueMap[value][this.data.tagInstanceId];
    } else {
      return '';
    }

    return `${tooltip}\n(click to copy)`;
  }

  getRequestViewTooltip(value: string): string {
    return value.length > 0 ? `${value} (click to copy)` : '';
  }

  showTooltip(tooltip) {
    const message = tooltip.message;

    tooltip.message = 'copied!';
    tooltip.hide(0);
    tooltip.show(0);
    setTimeout(() => {
      tooltip.hide(0);
      tooltip.message = message;
    }, 1500);
  }

  copyToClipboard(value: string, tooltip, isTagViewVariableValue?: boolean): void {
    if (!isTagViewVariableValue) {
      this.clipboardService.copy(value);
    } else if (
      this.data.tagInstanceVariableValueMap.hasOwnProperty(value) &&
      this.data.tagInstanceVariableValueMap[value].hasOwnProperty(this.data.tagInstanceId) &&
      this.data.tagInstanceVariableValueMap[value][this.data.tagInstanceId]
    ) {
      this.clipboardService.copy(this.data.tagInstanceVariableValueMap[value][this.data.tagInstanceId]);
    } else {
      this.clipboardService.copy('');
    }
  }

  openRuleAddModal() {
    if (!this.canAddRule) return;

    fromPromise(this.discoveryAuditService
      .getAudit(this.itemId))
      .subscribe((rules) => {
        let index = this.modalEscapeService.getLast() + 1;
        this.modalEscapeService.add(index);

        this.opModalService
          .openModal(SimpleRuleCreatorComponent, {
            data: {
              journeyId: this.itemId,
              tag: {
                account: this.data.account,
                tagId: this.data.tagId,
                tagName: this.data.tagName,
                tagInstanceId: this.data.tagInstanceId,
                variables: this.originTag.tagInstanceVariables,
              },
              rules: rules.rules,
              variables: this.originTag.tagInstanceVariables,
              bindTo: 'AUDIT'
            } as ISimpleRuleCreatorPayload
          })
          .afterClosed()
          .subscribe(() => this.modalEscapeService.remove(index));
      });
  }

  opDataRowHighlight(action: 'enter' | 'leave', className: string) {
    if (action === 'enter') {
      const el = this.document.getElementsByClassName(className);
      for (let i = 0; i < el.length; i++) {
        el[i].className += ' hover-tag-row';
      }
    } else {
      const el = this.document.getElementsByClassName(className);
      for (let i = 0; i < el.length; i++) {
        el[i].className = el[i].className.replace(' hover-tag-row', '');
      }
    }
  }

  variableDataRowHighlight(variableName: string, action: 'enter' | 'leave') {
    if (action === 'enter') {
      const el = this.document.getElementsByClassName(`highlight-${variableName}`);
      for (let i = 0; i < el.length; i++) {
        el[i].className += ' hover-tag-row';
      }
    } else {
      const el = this.document.getElementsByClassName(`highlight-${variableName}`);
      for (let i = 0; i < el.length; i++) {
        el[i].className = el[i].className.replace(' hover-tag-row', '');
      }
    }
  }
}
