import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import * as d3 from 'd3';
import { geoMercator } from 'd3';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { IPrivacyRequestsLocation } from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.models';
import countries from 'i18n-iso-countries';
import { groupBy, head } from 'lodash';
import { of, Subject } from 'rxjs';
import { delay, switchMap, takeUntil } from 'rxjs/operators';

interface ICountry {
  id: string;
  geometry: {
    type: string
    coordinates: [number, number][][];
  };
  properties: {
    name: string;
  };
}

export interface ISelectedCountry {
  countryCode: string;
  countryName: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'privacy-request-map',
  templateUrl: './privacy-requests-map.component.html',
  styleUrls: ['./privacy-requests-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyRequestsMapComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() widthSVG = '100%';
  @Input() heightSVG = '270px';
  @Input() showZoomButtons = true;
  @Input() smoothScrollToFocusedCountriesInSteps = 60;
  @Input() startInitialScrollDelay = 2000;
  @Input() showInitialAnimation = true;

  @Input() set focusedCountries(data: IPrivacyRequestsLocation[]) {
    if (data?.length) {
      this.locations = data;
    }
  }

  @Input() set showFullscreen(val: boolean) {
    this._showFullScreen = coerceBooleanProperty(val);
  }

  get showFullscreen() {
    return this._showFullScreen;
  }

  @Input() selectedCountry: ISelectedCountry;
  @Output() selectCountry = new EventEmitter<ISelectedCountry>();
  @Output() fullscreen = new EventEmitter<void>();

  isTooltipActivated: boolean;

  private previousTotalRequests: string;
  private _showFullScreen = false;
  private polygons: ICountry[];
  private isDragging = false;
  private minScale = 3;
  private maxScale = 12;
  private scale = 3;
  private posX = 0;
  private posY = 0;
  private startDragX: number = null;
  private startDragY: number = null;
  private mapSVG;
  private svgContext: any;
  private tooltipContext: any;
  private tension = 20;
  private inertiaCoordinates: [number, number, number][] = [];
  private acceleration = 0;
  private inertiaForce = 0;
  private mapMass = 300;
  private mapResistance = 2;
  private inertiaTimeout = null;
  private locations: IPrivacyRequestsLocation[];
  private polygonMouseUp = new Subject();
  private defaultMapWidth = 942.48; // The width from the SVG file

  @ViewChild('map') svg: ElementRef<SVGAElement>;
  @ViewChild('tooltip') tooltip: ElementRef<SVGAElement>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.focusedCountries?.currentValue?.length) {
      this.paintPolygons();

      const theMostRequestedCountry = this.getTheMostRequestedCountry();

      const totalRequests = changes.focusedCountries?.currentValue.reduce( (total, location: IPrivacyRequestsLocation) => {
        return total + location.requestCount;
      }, 0);

      if (theMostRequestedCountry && totalRequests !== this.previousTotalRequests) {
        this.previousTotalRequests = totalRequests;
        const polygon = this.getCountryPolygon(theMostRequestedCountry);
        if (polygon) setTimeout(() => {
          this.smoothYScroll(polygon, this.showInitialAnimation ? this.smoothScrollToFocusedCountriesInSteps : 1);
        }, this.showInitialAnimation ? this.startInitialScrollDelay : 500);
      }
    }
  }

  ngAfterViewInit() {
    this.svgContext = d3.select(this.svg.nativeElement)
      .on('mousedown', (ev: MouseEvent) => {
        this.isDragging = true;
        this.startDragX = ev.screenX;
        this.startDragY = ev.screenY;

        this.svgContext.node().classList.add('dragging');
        this.isTooltipActivated = false;
        this.tooltipContext.node().classList.remove('active');
        if (this.inertiaTimeout) {
          clearTimeout(this.inertiaTimeout);
          this.inertiaTimeout = null;
          this.inertiaCoordinates.length = 0;
        }
      })
      .on('mouseup', (ev) => {
        this.isDragging = false;
        this.tooltipContext.node().classList.remove('active');
        this.svgContext.node().classList.remove('dragging');

        this.inertiaMove();
      })
      .on('mousemove', (ev, a) => {
        if (this.isDragging) {
          const borders = this.borders();
          const inertiaCoordinates: [number, number, number] = [performance.now(), 0, 0];
          if (this.inertiaCoordinates.length === this.tension) {
            this.inertiaCoordinates.shift();
          }

          const newX = this.posX + (ev.screenX - this.startDragX) / this.scale;
          if (newX > borders.minX && newX < borders.maxX) {
            this.posX += (ev.screenX - this.startDragX) / this.scale;
            inertiaCoordinates[1] = ev.screenX - this.startDragX;
          } else {
            if (this.posX < borders.minX && this.posX < newX) {
              this.posX += (ev.screenX - this.startDragX) / this.scale;
              inertiaCoordinates[1] = ev.screenX - this.startDragX;
            } else if (this.posX > borders.maxX && this.posX > newX) {
              this.posX += (ev.screenX - this.startDragX) / this.scale;
              inertiaCoordinates[1] = ev.screenX - this.startDragX;
            }
          }

          const newY = this.posY + (ev.screenY - this.startDragY) / this.scale;
          if (newY > borders.minY && newY < borders.maxY) {
            this.posY += (ev.screenY - this.startDragY) / this.scale;
            inertiaCoordinates[2] = ev.screenY - this.startDragY;
          } else {
            if (this.posY < borders.minY && this.posY < newY) {
              this.posY += (ev.screenY - this.startDragY) / this.scale;
              inertiaCoordinates[2] = ev.screenY - this.startDragY;
            } else if (this.posY > borders.maxY && this.posY > newY) {
              this.posY += (ev.screenY - this.startDragY) / this.scale;
              inertiaCoordinates[2] = ev.screenX - this.startDragX;
            }
          }

          this.startDragX = ev.screenX;
          this.startDragY = ev.screenY;

          this.inertiaCoordinates.push(inertiaCoordinates);

          this.updateMapState();
        }
      })
      .on('mouseleave', (ev, a) => {
        if (this.isDragging) {
          this.isDragging = false;
          this.svgContext.node().classList.remove('dragging');
          this.inertiaMove();
        }
      })
      .on('wheel', (ev) => {
        ev.stopPropagation();
        ev.preventDefault();

        if (ev.deltaY > 0) {
          this.zoomOut();
        } else if (ev.deltaY < 0) {
          this.zoomIn();
        }
      })
      .on('contextmenu', (ev: MouseEvent) => {
        ev.preventDefault();
        this.posX = 0;
        this.posY = 0;
        this.scale = 3;
        this.inertiaForce = null;
        clearTimeout(this.inertiaTimeout);
        this.inertiaTimeout = null;
        this.updateMapState();
      });

    this.mapSVG = this.svgContext.append('g')
      .style('transform-origin', '50%')
      .style('transform', `scale(${this.scale}) translate(${this.posX}px, ${this.posY}px)`);

    this.tooltipContext = d3.select(this.tooltip.nativeElement);

    d3.json<{ features: ICountry[] }>('https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson')
      .then(data => {
        this.polygons = data.features;
        setTimeout(() => {
          this.drawPolygons();
          if (this.focusedCountries) {
            this.paintPolygons();
          }
        }, 100);
      });
  }

  zoomIn() {
    if (this.scale < this.maxScale) {
      this.scale += 0.5;
      this.updateMapState();
    }
  }

  zoomOut() {
    if (this.scale > this.minScale) {
      this.scale -= 0.5;
      this.updateMapState();
    }
  }

  fullScreen() {
    this.fullscreen.emit();
  }

  private updateMapState() {
    this.mapSVG.style('transform', `scale(${this.scale}) translate(${this.posX}px, ${this.posY}px)`);
  }

  private drawPolygon(id: string): string | '' {
    let currentLocation: IPrivacyRequestsLocation;
    const countryCodes = countries.getAlpha2Codes();
    currentLocation = this.locations?.find(loc => countryCodes[loc?.requestGeo?.countryCode] === id);
    const classes = [];

    if (currentLocation) {
      if (currentLocation.status) {
        if (currentLocation.status === 'unapproved') {
          classes.push('unapprovedRed');
        } else {
          classes.push('approvedGreen');
        }
      } else {
        classes.push('uncategorizedYellow');
      }
    }

    if (countryCodes[this.selectedCountry?.countryCode] === id) {
      classes.push('selected');
    }
    return classes.join(' ');
  }

  private drawPolygons() {
    this.removeMapListeners();
    const projection = geoMercator()
      .scale(50)
      .translate([
        this.svgContext.node().getBoundingClientRect().width / 2,
        this.svgContext.node().getBoundingClientRect().height / 2
      ]);

    this.mapSVG
      .selectAll('path')
      .data(this.polygons)
      .join('path')
      // draw each country
      .attr('d', d3.geoPath().projection(projection))
      // set the color of each country
      .attr('class', (polygon: ICountry) => this.drawPolygon(polygon.id))
      .attr('title', (polygon: ICountry) => polygon.id)
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', .3)
      .on('mousemove', (ev: MouseEvent, polygon: ICountry) => {
        if (!this.isDragging) {
          const svgCoords = this.svg.nativeElement.getBoundingClientRect();
          const svgX = svgCoords.x;
          const svgY = svgCoords.y;

          this.isTooltipActivated = true;
          this.tooltipContext.node().classList.add('active');

          this.tooltipContext
            .style('top', ev.clientY - svgY + 'px')
            .style('left', ev.clientX - svgX + 'px');

          this.tooltipContext.html(`
            <strong>${this.getPolygonName(polygon.properties.name)}</strong></br>
            ${this.getTooltipData(polygon.id)}
          `);
        }
      })
      .on('mouseleave', () => {
        this.isTooltipActivated = false;
        this.tooltipContext.node().classList.remove('active');
      })
      .on('mousedown', (ev: MouseEvent, data: ICountry) => {

        // When user emit mousedown we start timer to check if mouseup was emitted on time (100ms)
        // This solution is imitating click because drag event is emitted click instead of mousedown

        of(1)
          .pipe(
            switchMap(() => this.polygonMouseUp),
            takeUntil(of(2).pipe(delay(200)))
          )
          .subscribe(() => {
            (ev.target as SVGElement).classList.toggle('selected');
            const codes = countries.getAlpha3Codes();
            if (this.selectedCountry?.countryCode !== codes[data.id]) {
              this.selectedCountry = {
                countryCode: codes[data.id],
                countryName: this.getPolygonName(data.properties.name)
              };
            } else {
              this.selectedCountry = null;
            }

            this.selectCountry.emit(this.selectedCountry);

            this.paintPolygons();
          });
      })
      .on('mouseup', () => this.polygonMouseUp.next(3));
  }

  ngOnDestroy() {
    this.removeMapListeners();

    this.svgContext
      .on('mousedown', null)
      .on('mouseup', null)
      .on('mousemove', null)
      .on('mouseleave', null)
      .on('wheel', null);
  }

  private removeMapListeners() {
    this.mapSVG
      .selectAll('path')
      .on('mousemove', null)
      .on('mouseleave', null)
      .on('mousedown', null)
      .on('mouseup', null);
  }

  private calcInertiaPoint() {
    let toX = 0;
    let toY = 0;
    let fromPoints = this.inertiaCoordinates.length;

    this.inertiaCoordinates.forEach(coords => {
      toX += coords[1];
      toY += coords[2];
    });

    return [toX / fromPoints, toY / fromPoints];
  }

  private getSpeed() {
    if (this.inertiaCoordinates.length >= 2) {
      const p1 = this.inertiaCoordinates[0];
      const p2 = this.inertiaCoordinates[this.inertiaCoordinates.length - 1];
      const time = p2[0] - p1[0];

      const x1 = p1[1];
      const x2 = p2[1];
      const y1 = p1[2];
      const y2 = p2[2];
      const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

      return distance / time; // px per tick
    }

    return 0;
  }

  private inertiaMove() {
    if (!this.inertiaForce) {
      this.acceleration = this.getSpeed();
      this.inertiaForce = this.acceleration * this.mapMass;
    }

    if (this.inertiaForce > this.mapResistance) {
      const vector = this.calcInertiaPoint();
      const borders = this.borders();
      let updates = false;

      this.inertiaForce -= this.mapResistance;

      const stepX = vector[0] / this.acceleration * this.inertiaForce / 1000;
      const stepY = vector[1] / this.acceleration * this.inertiaForce / 1000;

      if (this.posX + stepX < borders.maxX && this.posX + stepX > borders.minX) {
        this.posX += stepX;
        updates = true;
      }

      if (this.posY + stepY < borders.maxY && this.posY + stepY > borders.minY) {
        this.posY += stepY;
        updates = true;
      }

      if (updates) {
        this.updateMapState();

        this.inertiaTimeout = setTimeout(() => this.inertiaMove(), 1000 / 60);
      }
    } else {
      this.inertiaCoordinates.length = 0;
      this.inertiaForce = null;
    }
  }

  private getPolygonName(initials: string): string {
    switch (initials) {
      case 'USA':
        return 'United States';
      case 'England':
        return 'United Kingdom';
      default:
        return initials;
    }
  }

  private getTooltipData(name: string): string {
    const groups = groupBy(this.locations, 'requestGeo.countryCode');
    const currentLocation = head(groups[countries.getAlpha3Codes()[name]]);

    if (currentLocation === undefined) {
      return '';
    }

    if (currentLocation.status) {
      if (currentLocation.status === 'unapproved') {
        return `<span>${currentLocation?.requestCount} Unapproved Requests</span></br>
              ${currentLocation.approvedCount ? `<span>${currentLocation.approvedCount} Approved Requests</span>` : ''}`;
      }

      if (currentLocation.status === 'approved') {
        return `<span>${currentLocation?.requestCount} Approved Requests</span>`;
      }
    } else {
      return `<i>${currentLocation?.requestCount} Uncategorized Requests</i>`;
    }
  }

  private paintPolygons() {
    this.svgContext?.selectAll('path').attr('class', (polygon: ICountry) => this.drawPolygon(polygon.id));
  }

  private borders() {
    const containerRectangle: DOMRect = this.svg.nativeElement.getBoundingClientRect();
    const mapRectangle: DOMRect = this.mapSVG.node().getBoundingClientRect();

    const diffW = mapRectangle.width - containerRectangle.width;
    const diffH = mapRectangle.height - containerRectangle.height;
    const margin = .01;
    return {
      maxX: diffW / 2 / this.scale + containerRectangle.width * margin,
      minX: diffW / -2 / this.scale - containerRectangle.width * margin,
      maxY: diffH / 2 / this.scale + containerRectangle.height * margin,
      minY: diffH / -2 / this.scale - containerRectangle.height * margin
    };
  }

  private getTheMostRequestedCountry() {
    let country;

    if (this.locations.length) {
      country = this.locations[0];
    }

    if (!country) return;

    this.locations.forEach(location => {
      if (location.status === 'unapproved' && location.requestCount > country.requestCount) {
        country = location;
      }
    });

    return country;
  }

  private getCountryPolygon(country: IPrivacyRequestsLocation) {
    return this.svgContext?.node().querySelector('[title="' + countries.getAlpha2Codes()[country.requestGeo.countryCode] + '"]');
  }

  smoothYScroll(polygon, steps: number) {
    if (steps) {
      const polygonSizes = polygon.getBoundingClientRect();
      const containerSizes = this.svg.nativeElement.getBoundingClientRect();
      const additionalSpace = containerSizes.height - containerSizes.height / 2;
      const stepScale = (this.minScale - this.scale) / steps;
      this.scale += stepScale;

      const stepY = (containerSizes.top - polygonSizes.top + additionalSpace / this.scale) / this.scale / steps;
      const stepX = -this.posX / steps;
      this.posY += stepY;
      this.posX += stepX;
      this.updateMapState();

      setTimeout(() => this.smoothYScroll(polygon, --steps), 1000 / 60);
    }
  }

  scrollToTheMostRequestedCountry() {
    const country = this.getTheMostRequestedCountry();
    const polygon = this.getCountryPolygon(country);

    if (polygon) {
      this.smoothYScroll(polygon, this.smoothScrollToFocusedCountriesInSteps);
    }
  }
}
