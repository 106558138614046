import { Injectable } from '@angular/core';
import { Names } from '@app/moonbeamConstants';
import { IDataSelectorStateParams } from '@app/moonbeamModels';
import { IPrimaryTagParams, IPrimaryTagsMessageParams } from '@app/components/account/manageTags/manageTagsService';
import {
  ECardViewSortByType,
  ReportCardViewGroupByType
} from '@app/components/manage/cards/manage-cards.component';
import { IVisibleReportCards } from '@app/components/manage/shared/services/manage-cards-data/manage-cards-data.service';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';

export enum StorageType {
  Local = 'localStorage',
  Session = 'sessionStorage'
}

export interface IGlobalStateInfo {
  prefix: string;
  name: string;
}

export interface IOriginalStateInfo {
  url: string;
}

export interface ICardViewParams {
  orderBy?: ReportCardViewGroupByType;
  sortBy?: ECardViewSortByType;
  sortDir?: ESortDirection;
  filterByName?: string;
}

const DEFAULT_CARD_FILTER_OPTION = true;

type Defaults = { [name: string]: any };

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  localStorage: Storage;
  sessionStorage: Storage;

  reportCardsGlobalStateDefaults: IVisibleReportCards = {
    audit: DEFAULT_CARD_FILTER_OPTION,
    webJourney: DEFAULT_CARD_FILTER_OPTION
  };

  globalStateDefaults: Defaults = {
    [Names.GlobalStateKeys.visibleReportCards]: this.reportCardsGlobalStateDefaults,
  };

  constructor() {
    this.localStorage = window.localStorage;
    this.sessionStorage = window.sessionStorage;
  }

  resetDefaults() {
    for (let name in this.globalStateDefaults) {
      this.setValue(name, this.globalStateDefaults[name], StorageType.Local);
    }
  }

  isStorageSupported(storageType): boolean {
    return !!this.getStorageByType(storageType);
  }

  getStorageByType(storageType?: StorageType) {
    return storageType === StorageType.Session ?
      this.sessionStorage
      : this.localStorage;
  }

  /**
   * Retrieve a value stored in browser storage
   * @param key The identifier for the data in storage
   * @param storageType Session or Local
   * @param deserialize True to interpret data as JSON. This will depend on how it was written, but should rarely be overridden/false
   * @returns The (deserialized) value in storage
   */
  getValue<T>(key: string, storageType: StorageType = StorageType.Local, deserialize = true): T {
    if (this.isStorageSupported(storageType)) {
      const storageValue = this.getStorageByType(storageType).getItem(key);
      // bulletproofing parsing the JSON no matter what is in localstorage
      if (deserialize) {
        try {
          return JSON.parse(storageValue);
        } catch (e) {
          return null;
        }
      } else {
        return storageValue as any;
      }
    }
    return null;
  }

  /**
   * Sets a value to be stored in browser storage
   * @param key The identifier for the data in storage
   * @param value The value to store
   * @param storageType Session or Local
   * @param serialize True to serialize data as JSON. Rarely should this be overridden/false
   * @returns True if browser supports writing to storage
   */
  setValue<T>(key: string, value: T, storageType: StorageType = StorageType.Local, serialize = true): boolean {
    if (this.isStorageSupported(storageType)) {
      // Still store the value as 'false' if receiving a boolean value. Otherwise, store whatever value is received.
      if ((typeof value === 'boolean' && !value) || value) {
        const storageValue = serialize ? JSON.stringify(value) : value.toString();
        this.getStorageByType(storageType).setItem(key, storageValue);
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  removeValue(key: string, storageType: StorageType = StorageType.Local): boolean {
    if (this.isStorageSupported(storageType)) {
      this.getStorageByType(storageType).removeItem(key);
      return true;
    }
    return false;
  }

  clearLocalStorage(): void {
    this.localStorage.clear();
  }

  clearSessionStorage(): void {
    this.sessionStorage.clear();
  }

  clearAll() {
    this.localStorage.clear();
    this.sessionStorage.clear();
  }

  isLoggedInAsAnother(): boolean {
    return !!this.getValue(Names.GlobalStateKeys.authImpersonate, StorageType.Session);
  }

  isVisitorUser(): boolean {
    return !!this.getValue(Names.GlobalStateKeys.isVisitorUser, StorageType.Session);
  }

  private getParamKey(key: string): string {
    if (this.isLoggedInAsAnother()) {
      return key + '.other';
    }
    return key;
  }

  setParamsForFolders(params: IDataSelectorStateParams): void {
    this.setValue<IDataSelectorStateParams>(this.getParamKey(Names.GlobalStateKeys.folderParams), params, StorageType.Session);
  }

  getParamsForFolders(): IDataSelectorStateParams {
    return this.getValue<IDataSelectorStateParams>(this.getParamKey(Names.GlobalStateKeys.folderParams), StorageType.Session);
  }

  setParamsForDomains(params: IDataSelectorStateParams): void {
    this.setValue<IDataSelectorStateParams>(this.getParamKey(Names.GlobalStateKeys.domainParams), params, StorageType.Session);
  }

  getParamsForDomains(): IDataSelectorStateParams {
    return this.getValue<IDataSelectorStateParams>(this.getParamKey(Names.GlobalStateKeys.domainParams), StorageType.Session);
  }

  setParamsForAudits(params: IDataSelectorStateParams): void {
    this.setValue<IDataSelectorStateParams>(this.getParamKey(Names.GlobalStateKeys.auditParams), params, StorageType.Session);
  }

  getParamsForAudits(): IDataSelectorStateParams {
    return this.getValue<IDataSelectorStateParams>(this.getParamKey(Names.GlobalStateKeys.auditParams), StorageType.Session);
  }

  removeParamsForAudits(): void {
    this.removeValue(this.getParamKey(Names.GlobalStateKeys.auditParams), StorageType.Session);
  }

  setParamsForWebJourneys(params: IDataSelectorStateParams): void {
    this.setValue<IDataSelectorStateParams>(this.getParamKey(Names.GlobalStateKeys.webParams), params, StorageType.Session);
  }

  getParamsForWebJourneys(): IDataSelectorStateParams {
    return this.getValue<IDataSelectorStateParams>(this.getParamKey(Names.GlobalStateKeys.webParams), StorageType.Session);
  }

  removeParamsForWebJourneys(): void {
    this.removeValue(this.getParamKey(Names.GlobalStateKeys.webParams), StorageType.Session);
  }

  setStateByPrefix(pageReport: IGlobalStateInfo) {
    return this.setValue<string>(pageReport.prefix, pageReport.name, StorageType.Session);
  }

  getStateByPrefix(prefix: string) {
    return this.getValue<string>(prefix, StorageType.Session);
  }

  setOriginalState(originalState: IOriginalStateInfo) {
    this.setValue<IOriginalStateInfo>(Names.GlobalStateKeys.originalState, {
      url: originalState.url
    }, StorageType.Local);
  }

  getOriginalState(): IOriginalStateInfo {
    return this.getValue<IOriginalStateInfo>(this.getParamKey(Names.GlobalStateKeys.originalState), StorageType.Local);
  }

  setReportCardViewParams(params: ICardViewParams): void {
    this.setValue<ICardViewParams>(this.getParamKey(Names.GlobalStateKeys.reportCardViewParams), params, StorageType.Local);
  }
  getReportCardViewParams(): ICardViewParams {
    return this.getValue<ICardViewParams>(this.getParamKey(Names.GlobalStateKeys.reportCardViewParams), StorageType.Local);
  }

  setPrimaryTagsMessageParam(params: IPrimaryTagsMessageParams): void {
    this.setValue<IPrimaryTagsMessageParams>(this.getParamKey(Names.GlobalStateKeys.primaryTagsMessage), params);
  }
  getPrimaryTagsMessageParam(): IPrimaryTagsMessageParams {
    return this.getValue<IPrimaryTagsMessageParams>(this.getParamKey(Names.GlobalStateKeys.primaryTagsMessage));
  }

  setPrimaryTagsParam(params: IPrimaryTagParams): void {
    this.setValue<IPrimaryTagParams>(this.getParamKey(Names.GlobalStateKeys.primaryTags), params);
  }
  getPrimaryTagsParam(): IPrimaryTagParams {
    return this.getValue<IPrimaryTagParams>(this.getParamKey(Names.GlobalStateKeys.primaryTags));
  }

  getAnnouncementValue(): boolean {
    return this.getValue<boolean>(this.getParamKey(Names.GlobalStateKeys.announcement));
  }

  clearAnnouncement(): void {
    this.removeValue(this.getParamKey(Names.GlobalStateKeys.announcement));
  }
}
