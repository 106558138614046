import { ThemeService } from './../../../services/theme-service/theme.service';
import { ActivatedRoute, Router } from '@angular/router';
import { allComparisonsPlaceholder } from './../ror-comparison-report.contants';
import { dateRunFormat, getAverageScoreChartOptions } from './ror-comparison-results.constants';
import { IItemScore } from './../ror-comparison-report.models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IComparisonReportStateParams } from '../ror-comparison-report.models';
import { IUser, Account } from '@app/moonbeamModels';
import { IFormattedRun } from '@app/components/domains/discoveryAudits/reporting/summaryReports/auditSummary/auditSummaryData';
import { ITimeSeriesPlot } from '@app/components/reporting/widgets/timeSeries/time-series.models';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { chartColor } from '../ror-comparison-report.utils';
import { takeUntil, catchError, map } from 'rxjs/operators';
import { IAuditDataService } from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { IAuditRunSummary } from '@app/components/domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { combineLatest, Subject, throwError } from 'rxjs';
import { RorComparisonReportScoresService } from '../shared/ror-comparison-report-scores.service';
import { IAverageScoreChartOpts } from './ror-comparison-results.models';
import { AccountsService } from '@app/components/account/account.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-comparison-results',
  templateUrl: './ror-comparison-results.component.html',
  styleUrls: ['./ror-comparison-results.component.scss']
})
export class RorComparisonResultsComponent implements OnInit, OnDestroy {

  readonly dateFormat = dateRunFormat;

  auditId: number;
  runId: number;
  comparisonSnapshotId: number;

  currentRun: IAuditRunSummary;
  previousRun: IAuditRunSummary;

  loading = true;
  noResults = false;

  averageScoreChartOpts: IAverageScoreChartOpts;
  timeSeriesData: ITimeSeriesPlot[];
  account: Account;
  user: IUser;

  comparisonToScoresMap: Map<number, IItemScore> = new Map();
  comparisonScores: IItemScore;

  private destroySubject = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private themeService: ThemeService,
              private comparisonReportScoresService: RorComparisonReportScoresService,
              private accountsService: AccountsService,
              private auditDataService: IAuditDataService) { }

  ngOnInit() {
    combineLatest(
      [this.activatedRoute.params, this.activatedRoute.queryParams]
    ).pipe(
      map(([params, queryParams]) => ({...params, ...queryParams})),
      takeUntil(this.destroySubject)
    ).subscribe((stateParams: IComparisonReportStateParams) => {
      this.auditId = parseInt(stateParams.auditId);
      this.runId = parseInt(stateParams.runId);
      this.comparisonSnapshotId = stateParams.comparisonSnapshotId ? parseInt(stateParams.comparisonSnapshotId) : allComparisonsPlaceholder.comparisonSnapshotId;
      this.fetchData();
    });

    this.subsribeOnThemeChange();
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  private subsribeOnThemeChange() {
    this.themeService.isDarkTheme
      .pipe(takeUntil(this.destroySubject))
      .subscribe(isDark => {
        this.averageScoreChartOpts = getAverageScoreChartOptions(isDark);
        if (this.comparisonScores) this.updateChart(this.comparisonScores);
      });
  }

  private fetchData() {
    this.accountsService.getUser().subscribe(user => {
      this.user = user;
    });

    this.auditDataService.getAuditRuns(this.auditId).then(runs => {
      const currentRunIndex = runs.findIndex(run => run.id === this.runId);
      this.currentRun = runs[currentRunIndex];
      this.previousRun = runs[currentRunIndex + 1];
    });

    this.comparisonReportScoresService.auditScores$
      .pipe(
        takeUntil(this.destroySubject),
        catchError(err => {
          this.loading = false;
          return throwError(err);
        })
      )
      .subscribe((scores: IItemScore[]) => {
        this.loading = false;

        if (!scores.filter(s => s.runId === this.runId).length) {
          this.noResults = true;
          return;
        }

        this.buildComparisonToScoresMap(scores);
        this.buildTimeSeriesData(scores);

        this.comparisonScores = this.comparisonToScoresMap.get(this.comparisonSnapshotId);

        if (this.comparisonScores) this.updateChart(this.comparisonScores);
        else {
          this.router.navigate([], {
            queryParams: { comparisonSnapshotId: null },
            queryParamsHandling: 'merge'
          });
        }
      }, err => {
        this.noResults = true;
      });
  }

  updateChart(comparisonScores: IItemScore): void {
    if (!this.averageScoreChartOpts) {
      return;
    }
    this.averageScoreChartOpts.fgArcColor = chartColor(comparisonScores.averageScore / 100);
  }

  private buildComparisonToScoresMap(scores: IItemScore[]): Map<number, IItemScore> {
    const filteredScores = scores.filter(s => s.runId === this.runId);

    this.comparisonToScoresMap.set(
      allComparisonsPlaceholder.comparisonSnapshotId,
      this.comparisonReportScoresService.calcAllTags(filteredScores)
    );
    filteredScores.forEach(s => this.comparisonToScoresMap.set(s.comparisonSnapshotId, s));

    return this.comparisonToScoresMap;
  }

  private buildTimeSeriesData(timeSeriesData: IItemScore[]): ITimeSeriesPlot[] {
    if (!(timeSeriesData instanceof Array)) return [];

    const groupedComparisons = ArrayUtils.groupByAsArray(timeSeriesData, 'comparisonId');

    this.timeSeriesData = groupedComparisons.map(([comparisonId, comparisons]) => {
      const name = comparisonId.toString();
      return {
        name,
        values: comparisons.map(c => this.formatSummaryData(c, name, c.averageScore))
      };
    });
  }

  private formatSummaryData(scores: IItemScore, name: string, averageScore: number): IFormattedRun {
    return {
      id: scores.comparisonSnapshotId,
      date: scores.finishedAt as Date,
      className: name,
      name: 'Average Score',
      score: averageScore,
      possibleScore: 100,
      value: averageScore / 100
    };
  }

}
