import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { ESplitCardChangeMeaning } from '@app/components/shared/components/split-card/split-card.models';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { EPageDetailsTabs } from '../page-details/page-details.constants';
import {
  IAuditPageInfo,
  IAuditPageInfoSparklineData,
  IAuditPageInfoTrendData,
  IAuditPageInfoWebVitalsSummaryItem,
  IAuditPageInfoWebVitalsTrends,
  IAuditRfm
} from '../page-details/page-details.models';
import { PageDetailsReportService } from '../page-details/page-details.service';
import { IStatusBubble } from '../status-bubble-timeline/status-bubble-timeline.models';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  CUMULATIVE_LAYOUT_SHIFT_CHART_CONFIG,
  EChartChangeTypes,
  EPageInfoTrendNames, EPageInfoTrendNamesToDigitsInfo,
  FIRST_CONTENTFUL_PAINT_CHART_CONFIG,
  LARGEST_CONTENTFUL_PAINT_CHART_CONFIG,
  PAGE_LOAD_TIME_CHART_CONFIG,
  PAGE_SIZE_CHART_CONFIG,
  TIME_TO_FIRST_BYTE_CHART_CONFIG
} from '@app/components/audit-reports/page-information/page-information.constants';
import {
  EChartType,
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalService
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.service';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import { bytesToMB, convertTimeWithPrecision } from '@app/components/utilities/number.utils';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EChartColor, PageLoadWidgetTooltip } from '../audit-report/audit-report.constants';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import {
  EPageInfoLinkDownloadDirection,
  EPageInfoLinkDownloadLinkType,
  EPageInfoLinkDownloadOption,
  EPageInfoWebVitalsItems
} from './page-information.enums';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import {
  FreeTrialAdModalComponent
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import {
  EFreeTrialAdModalType
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { IAuditRunErrors } from '@app/components/domains/discoveryAudits/discoveryAuditModels';
import { MdePopoverTrigger } from '@app/components/popover';
import {
  EWebVitalsMetricType
} from '@app/components/shared/components/viz/web-vitals-chart/web-vitals-chart.constants';
import {
  ISparklineChartColorizedBoundaries
} from '@app/components/shared/components/viz/sparkline-chart-colorized/sparkline-chart-colorized.models';
import {
  SortingService
} from '@app/components/domains/discoveryAudits/reporting/services/sortingService/sortingService';
import {
  statsToChartStatsItems
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.utils';
import {
  EWebVitalsFullScreenChartTooltipType
} from '@app/components/shared/components/viz/web-vitals-full-screen-chart/web-vitals-full-screen-chart.constants';
import {
  getWebVitalsDecimalsCount,
  getWebVitalsDivider
} from '@app/components/audit-reports/reports/page-summary/page-summary.helpers';

@Component({
  selector: 'op-page-information',
  templateUrl: './page-information.component.html',
  styleUrls: ['./page-information.component.scss'],
  providers: [SortingService],
})
export class PageInformationComponent implements OnDestroy, OnChanges {
  readonly EChartChangeTypes = EChartChangeTypes;
  isVisitorMode: boolean;
  private destroy$: Subject<void> = new Subject();

  // Widgets
  pageStatusCodeMeaning: ESplitCardChangeMeaning;
  pageStatusCodeValue: string;
  pageStatusCodeTooltip: string;

  pageLoadTimeMeaning: ESplitCardChangeMeaning;
  pageLoadTimeValue: string;

  pageSizeValue: string;

  widgetState: EFilterSpinnerState;

  PageLoadWidgetTooltip = PageLoadWidgetTooltip;

  webVitalsWidgets: {
    metricType: EWebVitalsMetricType;
    data: IAuditPageInfoWebVitalsSummaryItem;
    webVitalsAvailable: boolean;
    loading: boolean;
    webVitalsTrends: ISparklineChartData[];
    uniqueId?: string;
    digitsInfo?: string;
    boundaries?: ISparklineChartColorizedBoundaries;
    clickHandler?: () => void;
  }[] = [
    {
      metricType: EWebVitalsMetricType.LargestContentfulPaint,
      data: null,
      webVitalsAvailable: null,
      loading: true,
      webVitalsTrends: null,
      digitsInfo: EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.LARGEST_CONTENTFUL_PAINT],
    },
    {
      metricType: EWebVitalsMetricType.FirstContentfulPaint,
      data: null,
      webVitalsAvailable: null,
      loading: true,
      webVitalsTrends: null,
      digitsInfo: EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.FIRST_CONTENTFUL_PAINT],
    },
    {
      metricType: EWebVitalsMetricType.TimeToFirstByte,
      data: null,
      webVitalsAvailable: null,
      loading: true,
      webVitalsTrends: null,
      digitsInfo: EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.TIME_TO_FIRST_BYTE],
    },
    {
      metricType: EWebVitalsMetricType.CumulativeLayoutShift,
      data: null,
      webVitalsAvailable: null,
      loading: true,
      webVitalsTrends: null,
      digitsInfo: EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.CUMULATIVE_LAYOUT_SHIFT],
    }
  ];

  // Sparklines
  sparklineDataLoaded: boolean = false;
  widgetLoadTimeSparklineData: ISparklineChartData[] = [];
  widgetPageLoadTimeBottomHandler = this.openLoadTimeFullscreenChart.bind(this);

  widgetPageSizeSparklineData: ISparklineChartData[] = [];
  widgetPageSizeBottomHandler = this.openPageSizeFullscreenChart.bind(this);

  pageRunInfos: ISparklineRunInfo[];

  statusBubbles: IStatusBubble[] = [];

  // Other
  rfms: IAuditRfm[] = [];

  EPageInfoLinkDownloadDirection = EPageInfoLinkDownloadDirection;
  EPageInfoLinkDownloadLinkType = EPageInfoLinkDownloadLinkType;

  exportLinksPopoverVisible = false;
  infoIsExpanded: boolean = false;
  webVitalsTrends: IAuditPageInfoWebVitalsTrends[];

  private readonly webVitalsCutoffDate = new Date('2024-03-18');

  @Input() itemId: number;
  @Input() runId: number;
  @Input() auditId: number;
  @Input() pageId: string;
  @Input() activeTab: EPageDetailsTabs;
  @Input() pageInfo: IAuditPageInfo;
  @Input() pageErrors: IAuditRunErrors;

  @Output() screenshotClosed = new EventEmitter();

  @ViewChild(MdePopoverTrigger) exportLinksPopover: MdePopoverTrigger;
  @ViewChild('webVitalsWrapper', { read: ElementRef }) wvWrapper: ElementRef;

  constructor(
    private pageDetailsService: PageDetailsReportService,
    private modalService: OpModalService,
    private modalEscapeService: ModalEscapeService,
    private dateService: DateService,
    private applicationChromeService: ApplicationChromeService,
    private sortingService: SortingService,
  ) {
    this.applicationChromeService.isVisitorMode$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(isVisitorMode => {
      this.isVisitorMode = isVisitorMode;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges() {
    this.loadPageDetails();
    this.loadSparklines();
    this.getAuditPageInfoWebVitalsTrends();

    this.pageDetailsService.getAuditRemoteFileMappings(this.itemId, this.runId, this.pageId).subscribe(rfms => {
      this.rfms = rfms;
    });
  }

  openFullscreenWebVitalsChart(
    trendName: EPageInfoTrendNames,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[],
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: this.secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenWebVitalsChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName),
        chartType: EChartType.WebVitals,
        tooltipStyle: 'web-vitals',
        tooltipType: EWebVitalsFullScreenChartTooltipType.PAGE_DETAILS,
        metricType: trendName,
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  getFullscreenWebVitalsChartData(trendName: EPageInfoTrendNames, days: number = 30): Observable<IFullscreenChartDataWithStats> {
    const divider = getWebVitalsDivider(trendName);
    const decimalsCount = getWebVitalsDecimalsCount(trendName);

    return this.pageDetailsService.getPageDetailsPageWebVitalsTrend(this.auditId, this.pageId, trendName, days)
      .pipe(
        map(({ runs, stats }) => ({
            chartData: runs
              // Filtering out empty data points
              .filter(run => !isNaN(run.trendValue))
              .map(run => ({
                  date: run.completedAt,
                  value: convertTimeWithPrecision(run.trendValue, divider, decimalsCount),
                })
              ),
            stats: statsToChartStatsItems({
              min: convertTimeWithPrecision(stats.min, divider, decimalsCount),
              max: convertTimeWithPrecision(stats.max, divider, decimalsCount),
              average: convertTimeWithPrecision(stats.average, divider, decimalsCount),
              median: convertTimeWithPrecision(stats.median, divider, decimalsCount),
            }),
          })
        )
      );
  }

  get secondToLastCompletionDate(): string {
    return this.pageRunInfos.length > 1 ? this.pageRunInfos[this.pageRunInfos.length - 2].runCompletionDate : undefined;
  }

  private getAuditPageInfoWebVitalsTrends(): void {
    this.pageDetailsService.getAuditPageInfoWebVitalsTrends(this.itemId, this.runId, this.pageId)
      .subscribe(webVitals => {
        this.webVitalsTrends = webVitals.runs
          .sort((a, b) =>
            this.sortingService.compareDates(new Date(a.completedAt), new Date(b.completedAt), false)
          );
        this.buildWebVitalsWidgets();
      });
  }

  private buildWebVitalsWidgets(): void {
    const webVitalsSummary = this.pageInfo?.webVitalsSummary || {} as any;
    const runDate = new Date(this.pageInfo?.visitStartTimestamp);
    const webVitalsAvailable = runDate >= this.webVitalsCutoffDate;

    function getBoundaries(propertyName: EPageInfoWebVitalsItems, divider = 1): ISparklineChartColorizedBoundaries {
      return {
        warnThreshold: webVitalsSummary[propertyName] ? webVitalsSummary[propertyName].warnThreshold / divider : 0,
        failThreshold: webVitalsSummary[propertyName] ? webVitalsSummary[propertyName].failThreshold / divider : 0,
      };
    }

    function getWebVitalsTrends(
      propertyName: EPageInfoWebVitalsItems,
      webVitalsTrends: IAuditPageInfoWebVitalsTrends[],
      divider = 1,
    ): ISparklineChartData[] {
      return webVitalsTrends
        .filter(t => !isNaN(t[propertyName]))
        .map((t, i) => ({ value: t[propertyName] / divider, sequence: i }));
    }

    // since webVitals aren't available for all runs we're using a
    // different object property to know if page info has loaded
    const loading = !this.pageInfo?.url;

    this.webVitalsWidgets = [
      {
        metricType: EWebVitalsMetricType.LargestContentfulPaint,
        data: webVitalsSummary.largestContentfulPaint || {},
        boundaries: getBoundaries(EPageInfoWebVitalsItems.LargestContentfulPaint, 1000),
        webVitalsTrends: getWebVitalsTrends(EPageInfoWebVitalsItems.LargestContentfulPaint, this.webVitalsTrends, 1000),
        webVitalsAvailable,
        loading,
        clickHandler: () => this.openFullscreenWebVitalsChart(EPageInfoTrendNames.LARGEST_CONTENTFUL_PAINT),
        uniqueId: 'page-information-lcp-sparkline',
      },
      {
        metricType: EWebVitalsMetricType.FirstContentfulPaint,
        data: webVitalsSummary.firstContentfulPaint || {},
        boundaries: getBoundaries(EPageInfoWebVitalsItems.FirstContentfulPaint, 1000),
        webVitalsTrends: getWebVitalsTrends(EPageInfoWebVitalsItems.FirstContentfulPaint, this.webVitalsTrends, 1000),
        webVitalsAvailable,
        loading,
        clickHandler: () => this.openFullscreenWebVitalsChart(EPageInfoTrendNames.FIRST_CONTENTFUL_PAINT),
        uniqueId: 'page-information-fcp-sparkline',
      },
      {
        metricType: EWebVitalsMetricType.TimeToFirstByte,
        data: webVitalsSummary.timeToFirstByte || {},
        boundaries: getBoundaries(EPageInfoWebVitalsItems.TimeToFirstByte),
        webVitalsTrends: getWebVitalsTrends(EPageInfoWebVitalsItems.TimeToFirstByte, this.webVitalsTrends),
        webVitalsAvailable,
        loading,
        clickHandler: () => this.openFullscreenWebVitalsChart(EPageInfoTrendNames.TIME_TO_FIRST_BYTE),
        uniqueId: 'page-information-ttfb-sparkline',
      },
      {
        metricType: EWebVitalsMetricType.CumulativeLayoutShift,
        data: webVitalsSummary.cumulativeLayoutShift || {},
        boundaries: getBoundaries(EPageInfoWebVitalsItems.CumulativeLayoutShift),
        webVitalsTrends: getWebVitalsTrends(EPageInfoWebVitalsItems.CumulativeLayoutShift, this.webVitalsTrends),
        webVitalsAvailable,
        loading,
        clickHandler: () => this.openFullscreenWebVitalsChart(EPageInfoTrendNames.CUMULATIVE_LAYOUT_SHIFT),
        uniqueId: 'page-information-cls-sparkline',
      }
    ];
  }

  private loadPageDetails() {
    if (this.pageInfo) {
      this.pageStatusCodeValue = (this.pageInfo.finalStatusCode || this.pageInfo.statusCode).toString();
      this.pageStatusCodeTooltip = PageStatusCodeTooltipMap[this.pageStatusCodeValue] || 'Showing status codes for this page over the last few audit runs it has appeared in.';
      this.pageStatusCodeMeaning = this.interpretStatusCode(this.pageInfo.finalStatusCode || this.pageInfo.statusCode);

      this.pageLoadTimeValue = (this.pageInfo.loadTime / 1000).toFixed(2).toString() + ' sec';
      this.pageLoadTimeMeaning = this.interpretLoadTime(this.pageInfo.loadTime);

      this.pageSizeValue = bytesToMB(this.pageInfo.size).toString() + ' MB';
      this.widgetState = EFilterSpinnerState.None;
    } else {
      this.widgetState = EFilterSpinnerState.Loading;
    }
  }

  private loadSparklines() {
    this.sparklineDataLoaded = false;
    this.pageDetailsService
      .getAuditPageInfoSparklines(this.itemId, this.runId, this.pageId)
      .subscribe((data: IAuditPageInfoSparklineData[]) => {
        const loadTimeData: ISparklineChartData[] = [];
        const pageSizeData: ISparklineChartData[] = [];
        const statusBubbles: IStatusBubble[] = [];

        const runInfos: ISparklineRunInfo[] = [];

        data.forEach((dataPt: IAuditPageInfoSparklineData, index: number) => {
          const loadTime = Math.round(dataPt.pageInsights.loadTime / 10) / 100;
          loadTimeData.push({ value: loadTime, sequence: index });
          pageSizeData.push({ value: dataPt.pageInsights.size / 1_000_000, sequence: index });

          statusBubbles.push(this.createStatusBubble(dataPt));

          runInfos.push({ runId: dataPt.runId, runCompletionDate: dataPt.runCompletionDate });
        });

        this.widgetLoadTimeSparklineData = loadTimeData;
        this.widgetPageSizeSparklineData = pageSizeData;
        this.statusBubbles = statusBubbles;
        this.sparklineDataLoaded = true;

        this.pageRunInfos = runInfos;
      });
  }

  private createStatusBubble(dataPt: IAuditPageInfoSparklineData): IStatusBubble {
    const runDate = this.dateService.formatDate(new Date(dataPt.runCompletionDate), EDateFormats.dateThirteen);
    const statusCode = dataPt.pageInsights.statusCode;
    const tooltip = `${statusCode} status code | ${runDate}`;

    if (statusCode > 0 && statusCode < 300) {
      return {
        color: EChartColor.Green,
        tooltip,
        runDate,
        statusCode: statusCode
      };
    } else if (statusCode === 0 || statusCode >= 400) {
      return {
        color: EChartColor.Red,
        tooltip,
        runDate,
        statusCode
      };
    } else {
      return {
        color: EChartColor.Yellow,
        tooltip,
        runDate,
        statusCode
      };
    }
  }

  private interpretStatusCode(statusCode: number): ESplitCardChangeMeaning {
    if (statusCode === 0) return ESplitCardChangeMeaning.NEGATIVE;
    if (statusCode >= 100 && statusCode < 200) return ESplitCardChangeMeaning.SORT_OF_NEGATIVE;
    if (statusCode >= 200 && statusCode < 300) return ESplitCardChangeMeaning.POSITIVE;
    if (statusCode >= 300 && statusCode < 400) return ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    if (statusCode >= 400) return ESplitCardChangeMeaning.NEGATIVE;

    return ESplitCardChangeMeaning.NEUTRAL;
  }

  private interpretLoadTime(loadTime: number): ESplitCardChangeMeaning {
    if (loadTime < 3000) return ESplitCardChangeMeaning.POSITIVE;
    else if (loadTime < 6000) return ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    else if (loadTime < 10000) return ESplitCardChangeMeaning.SORT_OF_NEGATIVE;
    else return ESplitCardChangeMeaning.NEGATIVE;
  }

  openLoadTimeFullscreenChart() {
    if (this.widgetLoadTimeSparklineData.length > 1) {
      this.openFullscreenChart(
        EPageInfoTrendNames.PAGE_LOAD_TIME,
        this.pageRunInfos.length > 1 ? this.pageRunInfos[this.pageRunInfos.length - 2].runCompletionDate : undefined,
        FullscreenChartModalService.getPageLoadTimeSummaryLines,
      );
    }
  }

  openPageSizeFullscreenChart() {
    if (this.widgetLoadTimeSparklineData.length > 1) {
      this.openFullscreenChart(
        EPageInfoTrendNames.PAGE_SIZE,
        this.pageRunInfos.length > 1 ? this.pageRunInfos[this.pageRunInfos.length - 2].runCompletionDate : undefined);
    }
  }

  private openFullscreenChart(
    trendName: EPageInfoTrendNames,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[],
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName),
        metricType: trendName,
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  getFullscreenChartData(trendName: EPageInfoTrendNames, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.pageDetailsService.getAuditPageInfoTrends(this.itemId, this.pageId, trendName, days)
      .pipe(
        map((trendData: IAuditPageInfoTrendData[]) => {
          return {
            chartData: trendData
              .filter(dataPoint => !isNaN(dataPoint.trendValue))
              .map((dataPoint: IAuditPageInfoTrendData) => {
                let val = +dataPoint.trendValue;

                if (trendName === EPageInfoTrendNames.PAGE_SIZE) {
                  val = bytesToMB(val);
                }
                if (trendName === EPageInfoTrendNames.PAGE_LOAD_TIME) {
                  val = parseFloat((val / 1000).toFixed(1));
                }

                return {
                  value: val,
                  date: dataPoint.runCompletionDate
                };
              }),
          };
        })
      );
  }

  getFullscreenChartConfig(trendName: EPageInfoTrendNames) {
    switch (trendName) {
      case EPageInfoTrendNames.PAGE_LOAD_TIME:
        return PAGE_LOAD_TIME_CHART_CONFIG;
      case EPageInfoTrendNames.PAGE_SIZE:
        return PAGE_SIZE_CHART_CONFIG;
      case EPageInfoTrendNames.LARGEST_CONTENTFUL_PAINT:
        return LARGEST_CONTENTFUL_PAINT_CHART_CONFIG;
      case EPageInfoTrendNames.FIRST_CONTENTFUL_PAINT:
        return FIRST_CONTENTFUL_PAINT_CHART_CONFIG;
      case EPageInfoTrendNames.TIME_TO_FIRST_BYTE:
        return TIME_TO_FIRST_BYTE_CHART_CONFIG;
      case EPageInfoTrendNames.CUMULATIVE_LAYOUT_SHIFT:
        return CUMULATIVE_LAYOUT_SHIFT_CHART_CONFIG;
      default:
        return null;
    }
  }

  downloadReport(direction: EPageInfoLinkDownloadDirection, downloadOptions?: EPageInfoLinkDownloadOption[], linkType?: EPageInfoLinkDownloadLinkType) {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal(EFreeTrialAdModalType.EXPORT);
      return;
    }

    this.pageDetailsService.getReportPageLinks(this.itemId, this.runId, this.pageId, direction, downloadOptions, linkType).subscribe();
  }

  openFreeTrialAdModal(type: EFreeTrialAdModalType) {
    const data = {
      type: EFreeTrialAdModalType.EXPORT
    };

    this.modalService.openModal(FreeTrialAdModalComponent, { data });
  }

  sumLinksFromPage() {
    const linksFromThisPageV2 = this.pageInfo?.links.linksFromThisPageV2;
    if (!linksFromThisPageV2) return 0;

    return linksFromThisPageV2.scannedInternalSuccessfulPageCount +
      linksFromThisPageV2.scannedInternalBrokenPageCount +
      linksFromThisPageV2.scannedExternalSuccessfulPageCount +
      linksFromThisPageV2.scannedExternalBrokenPageCount +
      linksFromThisPageV2.unscannedInternaLinkCount +
      linksFromThisPageV2.unscannedExternaLinkCount;
  }

  sumLinksToThisPage(): number {
    return this.sumLinksToInitialUrl() + this.sumLinksToFinalUrl();
  }

  sumLinksToInitialUrl(): number {
    const links = this.pageInfo?.links;
    return links?.linkToInitialUrlCount || 0;
  }

  sumLinksToFinalUrl(): number {
    const links = this.pageInfo?.links;
    return links?.linkToFinalUrlCount || 0;
  }

  exportLinks(downloadOptions: EPageInfoLinkDownloadOption[]) {
    this.exportLinksPopover.closePopover();
    this.downloadReport(EPageInfoLinkDownloadDirection.FromThisPage, downloadOptions);
  }
}
