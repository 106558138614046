import { format } from 'date-fns';
import { Component, Input } from "@angular/core";
import { IAuditRunSummary } from '@app/components/domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { IAlertDataSourcesTableItem } from '../../alert-data-sources/alert-data-sources.models';
import { IAlertBarsChartBar } from '../alert-preview-chart/alert-preview-chart.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-preview-notification',
  templateUrl: './alert-preview-notification.component.html',
  styleUrls: ['./alert-preview-notification.component.scss']
})
export class AlertPreviewNotificationComponent {

  @Input() dataSource: IAlertDataSourcesTableItem;
  @Input() bars: IAlertBarsChartBar[];
  @Input() lastFailedRun: IAuditRunSummary;

  get runDate(): string {
    return this.lastFailedRun
      ? format(new Date(this.lastFailedRun.completed), 'MMM do')
      : '';
  }

}