<op-modal [leftFooterButtons]="[]"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="{{fullscreenChartConfig?.title}}"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="date-range-filter">
      <op-date-range-buttons
        [loading]="loading"
        [dateRange]="dateRange"
        [greaterThanOneMonthDisabled]="greaterThanOneMonthDisabled"
        [greaterThanThreeMonthsDisabled]="greaterThanThreeMonthsDisabled"
        [greaterThanSixMonthsDisabled]="greaterThanSixMonthsDisabled"
        [greaterThanNineMonthsDisabled]="greaterThanNineMonthsDisabled"
        [greaterThanThirteenMonthsDisabled]="greaterThanThirteenMonthsDisabled"
        (dateRangeChange)="getDataForDateRange($event)">
      </op-date-range-buttons>
    </div>

    <div class="stats">
      <div *ngFor="let stat of stats" class="stat">
        <div class="stat-title">{{ stat.title }}</div>
        <div class="stat-value">
          <!-- Note: The NumberFormatPipe is used here to format the stat value.
               It allows for flexible number formatting with options for truncation
               and decimal place control. The pipe is applied with the digitsInfo
               and truncate parameters, which can be customized as needed. -->
          {{ stat.value !== null ? (stat.value | numberFormat: digitsInfo : truncate) : stat.value }}
        </div>
      </div>
    </div>

    <div class="spinner-container" *ngIf="loading">
      <mat-spinner [diameter]="50" [strokeWidth]="5"></mat-spinner>
    </div>

    <div *ngIf="dateRange === null && !loading"></div>
    <div class="svg-container-area-fullscreen" *ngIf="dateRange !== null && !loading"
         [class.wv-chart-wrapper]="chartType === EChartType.WebVitals">
      <op-area-chart *ngIf="chartType === EChartType.Area" [config]="fullscreenChartConfig"
                     [uniqueIdentifier]="areaChartUID"></op-area-chart>

      <op-web-vitals-full-screen-chart *ngIf="chartType === EChartType.WebVitals"
                                       [tooltipType]="data.tooltipType"
                                       [config]="fullscreenChartConfig"></op-web-vitals-full-screen-chart>

      <op-web-vitals-box-plot-fullscreen-chart *ngIf="chartType === EChartType.WebVitalsBoxPlot"
                                               [config]="fullscreenChartConfig"></op-web-vitals-box-plot-fullscreen-chart>
    </div>
  </ng-template>
</op-modal>
