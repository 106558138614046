import { Component, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AccountsService } from '@app/components/account/account.service';
import { userIsOPAdmin, userIsOPSysAdmin } from '@app/authUtils';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { SelectionModel } from '@angular/cdk/collections';
import { AuditReportUrlBuilders } from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IAuditRun,
  IQueuedAudit,
  ISystemStatusAuditStatus
} from '@app/components/admin-portal/system-status/system-status.models';
import { SystemStatusService } from '@app/components/admin-portal/system-status/system-status.service';
import { AdminService } from '@app/components/admin-portal/system-status/admin.service';
import { LocationsService } from '@app/components/creator/webJourney/locationsService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'system-status-audits',
  templateUrl: './system-status-audits.component.html',
})
export class SystemStatusAuditsComponent implements OnDestroy, AfterViewInit {
  private destroy$: Subject<void> = new Subject();

  TIMER_INTERVAL = 60000;
  selected = 'Column Toggle';
  userIsSysAdmin: boolean = false;
  auditsInQueue: Array<IQueuedAudit>;
  auditsRunning: Array<IAuditRun>;
  areAllAuditsSelected: boolean = false;
  currentStatusList: Array<string>;
  loadingRunning: boolean = false;
  loadingQueued: boolean = false;
  locationsMap: { [name: string]: { countryCode: string; label: string; } };

  runningDataSource = new MatTableDataSource();
  runningDisplayedColumns = ['select', 'runId', 'auditId', 'accountId', 'company', 'location', 'description', 'status', 'open', 'pending', 'visited', 'pageLimit', 'speed', 'createdAt', 'frequency', 'actions', 'loginActions', 'dataLayer'];

  queuedDataSource = new MatTableDataSource();
  queuedDisplayedColumns = ['auditId', 'accountId', 'company', 'location', 'description', 'pageLimit', 'runningCount', 'maxConcAudits', 'run', 'actions', 'loginActions', 'dataLayer'];

  runningFilter: string = '';
  queuedFilter: string = '';

  runningFilterChanged$ = new Subject<string>();
  queuedFilterChanged$ = new Subject<string>();
  selection = new SelectionModel(true, []);

  goToAuditHandler = (audit, event?) => this.goToAudit(audit.accountId, audit.auditId, audit.runId);
  goToAccountHandler = (audit, event?) => this.goToAccount(audit.accountId);
  setStatusListHandler = (audit, event?) => this.setStatusList(audit.statusList, event);

  runningColumns = [
    { header: 'Select', property: 'select', title: 'Select one or more audits', show: true },
    { header: 'Run Id', property: 'runId', title: 'The current audit run ID', show: true },
    { header: 'Audit Id', property: 'auditId', title: 'The audit ID', show: true },
    { header: 'Account Id', property: 'accountId', title: 'The account ID', show: true },
    { header: 'Account', property: 'company', title: 'The account description', show: true },
    { header: 'Name', property: 'description', title: 'The audit name', show: true },
    { header: 'Status', property: 'status', title: 'The current status of the running audit', show: true },
    { header: 'Open', property: 'open', title: 'How many pages are being worked on at the moment', show: true },
    { header: 'Pending', property: 'pending', title: 'How many pages are queued up to run (will be more than there is left)', show: true },
    { header: 'Completed', property: 'visited', title: 'How many pages are finished', show: true },
    { header: 'Page Limit', property: 'pageLimit', title: 'Max number of pages for audit run', show: true },
    { header: 'Engines', property: 'speed', title: 'How many engines are allowed to work on audit run', show: true },
    { header: 'Started At', property: 'createdAt', title: 'How long ago the audit started running', show: true },
    { header: 'Frequency', property: 'frequency', title: 'How often the audit runs', show: true },
    { header: 'Data Layer', property: 'dataLayer', title: 'Name of the data layer property', show: true },
    { header: 'Actions', property: 'actions', title: 'How many actions are on the audit run', show: true },
    { header: 'Login Actions', property: 'loginActions', title: 'How many login actions are on the audit run', show: true },
    { header: 'Location', property: 'location', title: 'Displays where the audit is running', show: true },
  ];

  queuedColumns = [
    { header: 'Audit Id', property: 'auditId', title: 'The audit ID', show: true },
    { header: 'Account Id', property: 'accountId', title: 'The account ID', show: true },
    { header: 'Account', property: 'company', title: 'The account description', show: true },
    { header: 'Name', property: 'description', title: 'The name of the Audit', show: true },
    { header: 'Page Limit', property: 'pageLimit', title: 'Max number of pages for audit run', show: true },
    { header: '# Of Items Currently Running', property: 'runningCount', title: 'How many audits are currently running in this account', show: true },
    { header: 'Account Run Limit', property: 'maxConcAudits', title: 'How many audits the account is allowed to run concurrently', show: true },
    { header: 'Times Run', property: 'run', title: 'How many times this audit has been run', show: true },
    { header: 'Data Layer', property: 'dataLayer', title: 'Name of the data layer property', show: true },
    { header: 'Actions', property: 'actions', title: 'How many actions are on the audit run', show: true },
    { header: 'Login Actions', property: 'loginActions', title: 'How many login actions are on the audit run', show: true },
    { header: 'Location', property: 'location', title: 'Displays where the audit is running', show: true },
  ];

  @ViewChild('runningTable', { read: MatSort, static: true }) runningSort: MatSort;
  @ViewChild('queuedTable', { read: MatSort, static: true }) queuedSort: MatSort;

  constructor(private router: Router,
              private accountsService: AccountsService,
              private adminService: AdminService,
              private systemStatusService: SystemStatusService,
              private locationsService: LocationsService) {

    this.accountsService.getUser().subscribe(user => {
      this.userIsSysAdmin = userIsOPSysAdmin(user);
      if (!userIsOPAdmin(user)) {
        const url = AccountSettingsUrlBuilders.user();
        this.router.navigateByUrl(url);
      }
    });

    this.locationsService.getAllLocations().then((locations) => {
      this.locationsMap = locations.reduce((acc, location) => {
        acc[location.name] = { countryCode: location.countryCode.toLowerCase(), label: location.label };
        return acc;
      }, {}
      );

      this.systemStatusService.setRefreshFunction(() => { this.getAudits(); });
      this.systemStatusService.registerTimer(() => { this.getAudits(); }, this.TIMER_INTERVAL);
      this.getAudits();
    });

    this.runningFilterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.runningFilter = filter;
      this.runningDataSource.filter = this.runningFilter;
    });

    this.queuedFilterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.queuedFilter = filter;
      this.queuedDataSource.filter = this.queuedFilter;
    });
  }

  ngAfterViewInit() {
    this.runningDataSource.sort = this.runningSort;
    this.runningDataSource.sortingDataAccessor = this.tableSortingDataAccessor;
    this.queuedDataSource.sort = this.queuedSort;
  }

  ngOnDestroy(): void {
    this.systemStatusService.unregisterTimer();
    this.destroy$.next();
  }

  tableSortingDataAccessor(data, property) {
    switch (property) {
      case 'createdAt':
        return data['originalCreatedAt'];
      case 'location':
        return data['location'].label
      default:
        return data[property];
    }
  }

  showSnackbar() {
    this.systemStatusService.copyToClipboard();
  }

  getAudits() {
    this.loadingRunning = true;
    this.loadingQueued = true;
    this.adminService.getAuditsInQueue()
      .pipe(
        finalize(() => {
          this.loadingQueued = false;
        })
      ).subscribe(audits => {
        this.auditsInQueue = audits.map((a: any) => {
          a.createdAt = this.systemStatusService.getRecentDate(a.createdAt).replace('about ', '');
          a.location = this.locationsMap[a.location];
          return a;
        });
        this.queuedDataSource.data = this.auditsInQueue;
      }, this.systemStatusService.handleError);

    this.adminService.getAuditsRunning()
      .pipe(
        finalize(() => {
          this.loadingRunning = false;
        })
      ).subscribe(audits => {
        this.auditsRunning = audits.map((a: any) => {
          a.originalCreatedAt = a.createdAt;
          a.createdAt = this.systemStatusService.getRecentDate(a.createdAt).replace('about ', '');
          a.location = this.locationsMap[a.location];
          return a;
        });
        this.selection.clear();
        this.areAllAuditsSelected = false;
        this.runningDataSource.data = this.auditsRunning;
      }, this.systemStatusService.handleError);
  }

  pauseAudits(): void {
    let audits: number[] = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      const notPausedAudit = this.auditsRunning.find(
        a => a.runId === this.selection.selected[i].runId && a.status.toLowerCase().indexOf('paused') == -1
      );
      if (notPausedAudit) {
        audits.push(this.selection.selected[i].runId);
      }
    }
    if (audits.length != 0 && window.confirm(`Are you sure you want to pause ${audits.length} audits?`)) {
      this.adminService.pauseAudits(audits)
        .subscribe(
          () => { this.getAudits(); },
          () => { this.getAudits(); }
          );
    }
  }

  resumeAudits(): void {
    let audits: number[] = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      const pausedAudit = this.auditsRunning.find(
        a => a.runId === this.selection.selected[i].runId && a.status.toLowerCase().indexOf('paused') != -1
      );
      if (pausedAudit) {
        audits.push(this.selection.selected[i].runId);
      }
    }
    if (audits.length != 0 && window.confirm(`Are you sure you want to resume ${audits.length} audits?`)) {
      this.adminService.resumeAudits(audits).subscribe(() => { this.getAudits(); }, () => { this.getAudits(); });
    }
  }

  verifyAudits(): void {
    let audits: number[] = [];
    for (let i = 0; i < this.selection.selected.length; i++){
      const audit = this.auditsRunning.find(a => a.runId === this.selection.selected[i].runId);
      if (audit.status.toLowerCase().indexOf('verify') == -1 && audit.status.toLowerCase().indexOf('stop') == -1) {
        audits.push(this.selection.selected[i].runId);
      }
    }
    if (audits.length != 0 && window.confirm(`Are you sure you want to verify ${audits.length} audits?`)) {
      this.adminService.verifyAudits(audits).subscribe(() => { this.getAudits(); }, () => { this.getAudits(); });
    }
  }

  stopAudits(): void {
    let audits: number[] = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      const audit = this.auditsRunning.find(a => a.runId === this.selection.selected[i].runId);
      if (['force_stopped', 'stopped'].indexOf(audit.status) == -1) {
        audits.push(this.selection.selected[i].runId);
      }
    }
    if (audits.length != 0 && window.confirm(`Are you sure you want to stop ${audits.length} audits?`)) {
      this.adminService.stopAudits(audits).subscribe(() => { this.getAudits(); }, () => { this.getAudits(); });
    }
  }

  restartAudits(): void {
    let audits: number[] = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      audits.push(this.selection.selected[i].runId);
    }
    if (audits.length != 0 && window.confirm(`Are you sure you want to restart ${audits.length} audits? This will REMOVE the running audits.`)) {
      this.adminService.restartAudits(audits).subscribe(() => { this.getAudits(); }, () => { this.getAudits(); });
    }
  }

  removeAuditsRuns(): void {
    let auditsRuns: number[] = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      auditsRuns.push(this.selection.selected[i].runId);
    }
    if (auditsRuns.length && window.confirm(`Are you sure you want to remove ${auditsRuns.length} run(s)?`)) {
      let callback = () => {
        this.selection.clear();
        this.getAudits();
      };
      let positiveCallback = () => {
        callback();
        window.alert(
          'Deletion process started. See progress in logs for Starter, Manager, Processor, Enforcer, Indexer, Summariser services.'
        );
      };
      let negativeCallback = () => {
        callback();
        window.alert('Cannot start delete process. See error log');
      };
      this.adminService.removeAuditRuns(auditsRuns).subscribe(positiveCallback, negativeCallback);
    }
  }

  goToAccount(accountId: number) {
    if (!this.userIsSysAdmin) {
      return;
    }

    this.systemStatusService.goToAccount(accountId);
  }

  goToAudit(accountId: number, auditId: number, runId: number) {
    if (!this.userIsSysAdmin) {
      return;
    }

    this.systemStatusService.loginAsAccount(accountId, AuditReportUrlBuilders.auditSummary(auditId, runId));
  }

  setStatusList(list: Array<ISystemStatusAuditStatus>, event){
    if (list) {
      this.currentStatusList = [];
      list.forEach(s => {
        let str = `Status: ${s.status} (${this.systemStatusService.getRecentDate(s.created)})`;
        this.currentStatusList.push(str);
      });
    } else {
      this.currentStatusList = null;
    }
    if (event) {
      event.stopPropagation();
    }
  }

  prepareRunningCols(): void {
    const cols = this.runningColumns.filter(col => col.show).map(col => col.property);
    this.runningDisplayedColumns = cols;
  }

  prepareQueuedCols(): void {
    const cols = this.queuedColumns.filter(col => col.show).map(col => col.property);
    this.queuedDisplayedColumns = cols;
  }

  toggleAllColumns(type: 'running'|'queued', showAll: boolean) {
    if (type === 'running') {
      this.runningColumns.forEach(col => col.show = showAll);
      this.prepareRunningCols();
    } else {
      this.queuedColumns.forEach(col => col.show = showAll);
      this.prepareQueuedCols();
    }
  }

  debounceRunningFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.runningFilterChanged$.next(filter);
  }

  debounceQueuedFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.queuedFilterChanged$.next(filter);
  }

  // Running Table
  masterToggle(): void {
    setTimeout(() => {
      if (this.runningDataSource.filter) {
        this.isAllFilteredSelected() ?
          this.runningDataSource.filteredData.forEach(row => this.selection.deselect(row)) :
          this.runningDataSource.filteredData.forEach(row => this.selection.select(row));
      } else {
        this.isAllSelected() ?
          this.selection.clear() :
          this.runningDataSource.data.forEach(row => this.selection.select(row));
      }
    });
  }

  onCheckboxChecked(event: MouseEvent, row): void {
    event.stopPropagation();
    setTimeout(() => {
      this.selection.toggle(row);
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.runningDataSource.data.length;
    return numSelected === numRows;
  }

  isAllFilteredSelected(): boolean {
    return <boolean> this.runningDataSource.filteredData.reduce(
      (allSelected: boolean, row) => allSelected && this.selection.isSelected(row),
      true
    );
  }
}
