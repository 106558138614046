import { Route } from '@angular/router';
import { ShareLinksComponent } from './share-links.component';
import { ShareLinksPaths, ShareLinksStateNames } from './share-links.constants';

export const ShareLinksFutureState: Route = {
  path: ShareLinksPaths.base,
  loadChildren: () => import('./share-links.module').then(mod => mod.ShareLinksModule),
};

export const ShareLinksRoutes: Route = {
  path: '',
  component: ShareLinksComponent,
  data: {
    stateName: ShareLinksStateNames.base,
  },
};