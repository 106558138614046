<component-template componentName="Open Menu On Hover">
  <component-library-section sectionName="Overview" selector="<open-menu-on-hover>">
    <p>This is a wrapper component that allows a menu to be opened on hover. Currently this is only in use in the Easy Block Tags component.</p>

    <div class="open-menu-on-hover-example">
      <open-menu-on-hover>
        <div trigger mat-menu-item>Hover over me</div>
        <div content>
          <button mat-menu-item *ngFor="let item of menuItems">
            {{ item.label }}
          </button>
        </div>
      </open-menu-on-hover>
    </div>
  </component-library-section>
</component-template>