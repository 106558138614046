import './polyfills'; // Used by angular material
import 'jquery';
import * as angular from 'angular';
import { setAngularJSGlobal } from '@angular/upgrade/static';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app.module';
import * as Sentry from '@sentry/angular';
import { blacklistZone } from '@app/blacklist';
import { environment } from '@app/environments/environment';
import { enableProdMode } from '@angular/core';

if (environment.production) {
  enableProdMode();
}
if (environment.sentryConfig) {
  Sentry.init(environment.sentryConfig);
} else {
  console.warn('Sentry config is not provided');
}

/**
 * ngUpgrade needs a reference to AngularJs before it kicks of the bootstrapping process.
 * That's why we explicitly set the AngularJS lib before bootstrapping.
 */
setAngularJSGlobal(angular);

/**
 * We bootstrap the Angular module first, which then bootstraps the Angular JS.
 */
blacklistZone.run(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(console.error);
});
