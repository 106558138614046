import { EUseCaseBannerColor } from '@app/components/audit-reports/use-cases/use-cases.models';
import { UseCaseUtils } from '@app/components/audit-reports/use-cases/use-case.utils';

export const AnalyticsSection = {
  sectionIcon: 'finance',
  sectionTitle: 'Analytics',
  bannerText: 'Share with others inside your company',
};

export const AnalyticsTagsSection = {
  sectionIcon: 'local_offer',
  sectionTitle: 'Analytics Tags',
};

export const AnalyticsPagesScannedWidget = {
  topText: 'Pages Scanned',
  topTooltip: 'Count of all unique pages scanned in this audit run. Click to view additional details.',
  bottomText: 'Audited URL',
  bodyContent: {
    navToText: 'View Pages report to get more detail',
    bodyText: {
      paid: 'Validating web analytics requires careful analysis and a large sample size. A thorough audit of your most important pages should be performed regularly and with every release of new functionality on both pre-production and production environments. Larger site sections should be audited at least quarterly.',
      guest: 'Validating web analytics requires careful analysis and a large sample size. This small page scan is typically not the best indicator of overall analytics quality. A thorough audit of your most important pages should be performed regularly and with every release of new functionality. Larger site sections should be audited at least quarterly.',
      trial: 'Validating web analytics requires careful analysis and a large sample size. A thorough audit of your most important pages should be performed regularly and with every release of new functionality on both pre-production and production environments. Larger site sections should be audited at least quarterly.',
    }
  }
};

export const UniqueAnalyticsTagsStats = {
  min: '1',
  max: '195',
  median: '6',
  average: '11',
  ninetyFifthPercentile: '33',
  ninetyNinthPercentile: '69',
};

export const UniqueAnalyticsTagsWidget = {
  topText: 'Unique Analytics Tags',
  topTooltip: 'Total number of unique Analytics tags (identified technologies) found in this audit run. Click to view additional details.',
  statsData: {
    headerText: '<div class="header-text-top">UNIQUE ANALYTICS</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...UniqueAnalyticsTagsStats,
  },
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    UniqueAnalyticsTagsStats.median,
    UniqueAnalyticsTagsStats.average,
    UniqueAnalyticsTagsStats.min,
    UniqueAnalyticsTagsStats.max,
  ),
  bodyContent: {
    navToText: 'View your analytics tag requests',
    bodyText: {
      paid: 'Most organizations have dozens of marketing technologies deployed on their website. Here is the breakdown all tag technology categories found in this audit of your site. We recommend setting alerts on which tags are expected to load on your pages.',
      guest: 'Most organizations have dozens of marketing technologies deployed on their website. Here is the breakdown all tag technology categories found in this sample audit of your site. After getting started with your free trial you can set alerts on which tags are expected to load on your pages.',
      trial: 'Most organizations have dozens of marketing technologies deployed on their website. Here is the breakdown all tag technology categories found in this audit of your site. We recommend setting alerts on which tags are expected to load on your pages.',
    }
  }
};

export const TopAnalyticsWidget = {
  topText: 'Top Analytics:',
  topTooltip: 'This analytics tag was on highest percent of pages.',
  bottomText: 'BEST PRACTICE',
  bottomValue: '100% Coverage',
  bodyContent: {
    navToText: 'View pages missing ',
    bodyText: {
      paid: 'Accurate measurement is a critical component in all of your marketing strategies. At a minimum all pages should have your primary analytics tag.',
      guest: 'Accurate measurement is a critical component in all of your marketing strategies. At a minimum all pages should have your primary analytics tag.',
      trial: 'Accurate measurement is a critical component in all of your marketing strategies. At a minimum all pages should have your primary analytics tag.',
    }
  }
};

export const TopTagManagerWidget = {
  topText: 'Top Tag Manager:',
  topTooltip: 'This tag manager was on the highest percent of pages.',
  bottomText: 'BEST PRACTICE',
  bottomValue: '100% Coverage',
  bodyContent: {
    navToText: 'View pages missing ',
    bodyText: {
      paid: 'All tag technologies should be delivered and controlled by a tag management system. Ensuring consistent analytics measurement on pages without a tag manager is typically very difficult.',
      guest: 'All tag technologies should be delivered and controlled by a tag management system. Ensuring consistent analytics measurement on pages without a tag manager is typically very difficult.',
      trial: 'All tag technologies should be delivered and controlled by a tag management system. Ensuring consistent analytics measurement on pages without a tag manager is typically very difficult.',
    },
  }
};

export const TagRequestsStats = {
  min: '0.05',
  max: '196,291',
  median: '2,053',
  average: '2,713',
  ninetyFifthPercentile: '7,596',
  ninetyNinthPercentile: '12,703',
};

export const TagRequestsWidget = {
  topText: 'Analytics Tag Requests',
  topTooltip: 'Count of all network requests identified as tags. Click to view additional details.',
  statsData: {
    headerText: '<div class="header-text-top">TAG REQUESTS</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...TagRequestsStats,
  },
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    TagRequestsStats.median,
    TagRequestsStats.average,
    TagRequestsStats.min,
    TagRequestsStats.max,
  ),
  bodyContent: {
    navToText: 'View your slow loading analytics tag requests',
    bodyText: {
      paid: 'For accurate, reliable measurement and responsive personalization, it’s critical that tags and other technologies load quickly. Slow loading tags contribute to data loss and poor user experiences.',
      guest: 'For accurate, reliable measurement and responsive personalization, it’s critical that tags and other technologies load quickly. Slow loading tags contribute to data loss and poor user experiences.',
      trial: 'For accurate, reliable measurement and responsive personalization, it’s critical that tags and other technologies load quickly. Slow loading tags contribute to data loss and poor user experiences.',
    },
  }
};

export const BrokenTagRequestsWidget = {
  topText: 'Broken Analytics Tag Requests',
  topTooltip: 'Count tag requests that had an HTTP status code of 400+ or 500+. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'View broken analytics tag requests',
    bodyText: {
      paid: 'Broken tags have HTTP status codes of either 400+ or 500+. This typically means that no data is being collected for these technologies. You\'ll want to fix those as quickly as possible.',
      guest: 'Broken tags have HTTP status codes of either 400+ or 500+. This typically means that no data is being collected for these technologies. You\'ll want to fix those as quickly as possible.',
      trial: 'Broken tags have HTTP status codes of either 400+ or 500+. This typically means that no data is being collected for these technologies. You\'ll want to fix those as quickly as possible.',
    },
  }
};

export const DuplicateTagRequestsStats = {
  min: '0',
  max: '89,860',
  median: '137',
  average: '342',
  ninetyFifthPercentile: '1,089',
  ninetyNinthPercentile: '4,275',
};

export const DuplicateTagRequestsWidget = {
  topText: 'Duplicate Analytics Tag Requests',
  topTooltip: 'Count of tag request instances that are exact copies of each other and load on the same page. Click to view additional details.',
  statsData: {
    headerText: '<div class="header-text-top">DUPLICATE TAG REQUESTS</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...DuplicateTagRequestsStats,
  },
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    DuplicateTagRequestsStats.median,
    DuplicateTagRequestsStats.average,
    DuplicateTagRequestsStats.min,
    DuplicateTagRequestsStats.max,
  ),
  bodyContent: {
    navToText: 'View duplicate analytics tag requests',
    bodyText: {
      paid: 'Inflated data can lead to misinformed analysis, as a general rule no analytics tags should have duplicate data. Other technologies like ad placements may load in a duplicate manner without any issues.',
      guest: 'Inflated data can lead to misinformed analysis, as a general rule no analytics tags should have duplicate data. Other technologies like ad placements may load in a duplicate manner without any issues.',
      trial: 'Inflated data can lead to misinformed analysis, as a general rule no analytics tags should have duplicate data. Other technologies like ad placements may load in a duplicate manner without any issues.',
    },
  }
};

export const TagAndVariableRuleFailuresWidget = {
  topText: 'Tag & Variable Rule Failures',
  topTooltip: 'Count of all rule failures across all pages scanned. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'Tag & Variable Rule Failures',
    bodyText: {
      paid: 'dynamic content',
      guest: 'dynamic content',
      trial: 'dynamic content',
    },
  }
};

export const UniqueTagVariablesStats = {
  min: '0',
  max: '69,202',
  median: '159',
  average: '975',
  ninetyFifthPercentile: '5,746',
  ninetyNinthPercentile: '9,384',
};

export const UniqueTagVariablesWidget = {
  topText: 'Unique Analytics Tag Variables',
  topTooltip: 'Count of unique variable names in all identified tags. Click to view additional details.',
  statsData: {
    headerText: '<div class="header-text-top">UNIQUE TAG VARIABLES</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...UniqueTagVariablesStats,
  },
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    UniqueTagVariablesStats.median,
    UniqueTagVariablesStats.average,
    UniqueTagVariablesStats.min,
    UniqueTagVariablesStats.max,
  ),
  bodyContent: {
    navToText: 'View variables for analytics tags',
    bodyText: {
      paid: 'You can specify data quality standards for your most important data collection and site personalization technologies.',
      guest: 'After starting a free trial or purchasing a full account you’ll be able to specify data quality standards for your most important data collection and site personalization technologies.',
      trial: 'You can specify data quality standards for your most important data collection and site personalization technologies.',
    },
  }
};

export const UniqueTagVariableValuesStats = {
  min: '0',
  max: '174,853',
  median: '2,421',
  average: '4,256',
  ninetyFifthPercentile: '12,821',
  ninetyNinthPercentile: '22,157',
};

export const UniqueTagVariableValuesWidget = {
  topText: 'Unique Analytics Variable Values',
  topTooltip: 'Count of unique variable values across all tags evaluated. Click to view additional details.',
  statsData: {
    headerText: '<div class="header-text-top">UNIQUE VARIABLE VALUES</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...UniqueTagVariableValuesStats,
  },
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    UniqueTagVariableValuesStats.median,
    UniqueTagVariableValuesStats.average,
    UniqueTagVariableValuesStats.min,
    UniqueTagVariableValuesStats.max,
  ),
  bodyContent: {
    navToText: 'View variable values for analytics tags',
    bodyText: {
      paid: 'As part of Tag & Variable Rules you’ll be able to setup variable value standards. These are useful to ensure KPI variables are collected correctly and consistently.',
      guest: 'After starting a free trial or purchasing a full account you’ll be able to specify data quality standards for your most important data collection and site personalization technologies.',
      trial: 'As part of Tag & Variable Rules you’ll be able to setup variable value standards. These are useful to ensure KPI variables are collected correctly and consistently.',
    },
  }
};

export const DataLayerCoverageWidget = {
  topText: 'Data Layer Coverage',
  topTooltip: 'Percent of pages that have a data layer. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: '100%',
  bannerColor: EUseCaseBannerColor.Yellow,
  bodyContent: {
    navToText: '',
    bodyText: {
      paid: 'dynamic',
      guest: 'After starting a free trial or purchasing a full account you’ll be able to specify data quality standards for your most important data collection and site personalization technologies.',
      trial: 'dynamic',
    },
  }
};

export enum EUseCaseTagLoadTimePills {
  'Below500' = 'Below 500 ms',
  '500to1000' = '500 ms to 999 ms',
  '1000to2000' = '1000 ms to 1999 ms',
  '2000andAbove' = '2000 ms and above'
}
