import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const AccountSettingsStateNames = {
  base: 'account_settings',
  myProfile: 'account_settings.user',
  manageTags: 'account_settings.manage-tags',
  manageUsers: 'account_settings.users',
  userEvents: 'account_settings.userEvents',
};

export const AccountSettingsPaths = {
  base: 'account',
  user: 'user',
  manageTags: 'manage-tags',
  manageUsers: 'users',
  manageFolders: 'manage-folders',
  bulkOperations: 'bulk-operations',
  userEvents: 'user-events',
  notificationCenter: 'notification-center',
};

export const AccountSettingsUrlBuilders = {
  base: () => RouteBuilder.buildUrl([AccountSettingsPaths.base]),
  user: () => RouteBuilder.buildUrl([AccountSettingsPaths.base, AccountSettingsPaths.user]),
  manageTags: () => RouteBuilder.buildUrl([AccountSettingsPaths.manageTags]),
  manageUsers: () => RouteBuilder.buildUrl([AccountSettingsPaths.base, AccountSettingsPaths.manageUsers]),
  eventLog: () => RouteBuilder.buildUrl([AccountSettingsPaths.base, AccountSettingsPaths.userEvents]),
  notificationCenter: () => RouteBuilder.buildUrl([AccountSettingsPaths.notificationCenter]),
  manageFolders: () => RouteBuilder.buildUrl([AccountSettingsPaths.bulkOperations, AccountSettingsPaths.manageFolders]),
  userEvents: () => RouteBuilder.buildUrl([AccountSettingsPaths.base, AccountSettingsPaths.userEvents]),
};
