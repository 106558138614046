import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
  HostListener,
  OnInit, OnDestroy, OnChanges, NgZone
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FloatLabelType } from '@angular/material/form-field';
import {
  CalculateWordWidth
} from '@app/components/domains/discoveryAudits/reporting/services/calculateWordWidthService/calculateWordWidthService';
import { IChipsPLaceholderConfig, IAdvancedConfigs } from './op-chip-selector.models';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OpChipSelectorComponent),
  multi: true
};

/**
 * This component is used for selecting item from items array.
 * We can filter displayed items by search text.
 *
 * @param {Array} allItems. Available chips array in dropdown.
 * @param {Array} selectedChips. Chips array, that will be displayed.
 * @param {string} bindValue. Object property to use for chip.
 * @param {string} bindChipLabel. Object property to use for chip. Default is 'name'.
 * @param {string} label. Floating label text.
 * @param {string} placeholder. Placeholder text.
 * @param {IAdvancedConfigs} advancedConfigs. Advanced configs for chips that use in sources (like former 'simple-chips') .
 * @param {number} maxlength. max length for input field
 * @param {string} createNewText. text to show as the label for the create new chip option
 */

@Component({
  selector: 'op-chip-selector',
  templateUrl: './op-chip-selector.component.html',
  styleUrls: ['./op-chip-selector.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class OpChipSelectorComponent
  implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  resize$ = new Subject<void>();
  destroy$ = new Subject<void>();

  @Input() allItems: any[] = [];
  @Input() floatLabel?: FloatLabelType = 'always';
  @Input() label?: string;
  @Input() hint?: string;
  @Input() placeholder?: IChipsPLaceholderConfig;
  @Input() maxlength = 256;
  @Input() createNewText = 'Create new';
  @Input() removable = true;
  @Input() appearance?: MatFormFieldAppearance = 'outline';

  // advancedConfigs is merged with default config in ngOnInit
  @Input() advancedConfigs: IAdvancedConfigs = {};

  @Input() showNotFoundedLabels: boolean;

  @Input() additionalFormFieldClasses: string[] = [];

  @Input() bindValue: string;
  @Input() bindChipLabel: string = 'name';

  /* onCreateChip is used to add a new chip via the api */
  @Output() onCreateChip?: EventEmitter<string> = new EventEmitter<string>(true);
  /* onRemoveChip is used to remove a chip via the api */
  @Output() onRemoveChip?: EventEmitter<any> = new EventEmitter<any>();
  /* onSelectChip is used to add a chip to the selectedChips parent */
  @Output() onSelectChip?: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('chipInput') chipInput: ElementRef;

  visible = true;
  addOnBlur = false;
  searchText: string;
  exactMatch: any;

  @HostBinding('class.no-input-padding')
  closed = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new UntypedFormControl();
  filteredChips: any[];

  selectedChips: any[] = [];

  chipsInPreview: number = 0;
  maxChipWidth: number = 200;
  splitterWidth: number = 22;
  containerWidth: number;

  writeValue(obj: any): void {
    if (!Array.isArray(obj)) return;
    if (this.bindValue) {
      this.selectedChips = obj.map(value => this.allItems.find(item => item[this.bindValue] === value));
    } else {
      this.selectedChips = obj;
    }
    this.onChange(this.selectedChips);
    if (this.advancedConfigs.collapsible) this.calculateHiddenChips();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  propagateChange = (newValue: any) => {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.advancedConfigs.resizable && this.advancedConfigs.collapsible) {
      this.resize$.next();
    }
  }

  @HostListener('click', ['$event'])
  onClick() {
    if (this.advancedConfigs.collapsible && !this.advancedConfigs.readOnly && this.closed) {
      this.switchView();
    }
  }

  constructor(
    private el: ElementRef,
    private calculateWordWidthService: CalculateWordWidth
    ){}

  ngOnInit(): void {
    this.advancedConfigs = {
      collapsible: false,
      lines: 1,
      readOnly: false,
      resizable: true,
      ...this.advancedConfigs
    };

    if (this.advancedConfigs.collapsible) {
      this.closed = true;
      this.containerWidth = this.advancedConfigs.availableWidth || this.el.nativeElement.offsetWidth;

      this.calculateHiddenChips();

      this.resize$
        .pipe(
          debounceTime(100),
          takeUntil(this.destroy$)
        )
        .subscribe(_ => {
          this.containerWidth = this.advancedConfigs.availableWidth || this.el.nativeElement.offsetWidth;
          this.calculateHiddenChips();
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allItems && changes.allItems.currentValue !== changes.allItems.previousValue) {
      this.filteredChips = this.calcFilteredChips();
    }
  }

  onChange(chips: any[]) {
    this.propagateChange(this.bindValue ? chips.map(chip => chip[this.bindValue]) : chips);
  }

  onInputTokenEnd(event: MatChipInputEvent): void {
    const value = event.value;
    const chip = this.filteredChips.find(c => c[this.bindChipLabel] === value);

    if (chip) {
      this.selectChip(chip);
    } else {
      this.add(value);
    }
  }

  add(value: string): void {
    const trimmedValue = (value || '').trim();

    if (trimmedValue) {
      this.onCreateChip.emit(trimmedValue);
    }

    if (value && this.showNotFoundedLabels && this.onCreateChip.observers.length === 0) {
      this.selectedChips = [...this.selectedChips, {name: value, color: 'warn'}];
    }

    this.clearSearchText();
  }

  selectChip(value: any) {
    this.selectedChips = [...this.selectedChips, value];
    this.onChange(this.selectedChips);
    this.onSelectChip.emit(value);
    this.clearSearchText();
  }

  remove(chip: any): void {
    this.selectedChips = this.selectedChips.filter(e => e !== chip);
    this.onChange(this.selectedChips);
    this.onRemoveChip.emit(chip);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // Create new chip option selected
    if (typeof event.option.value === 'string') {
      this.add(event.option.value);
      return;
    }

    this.selectChip(event.option.value);
  }

  clearSearchText() {
    this.searchText = '';
    this.chipInput.nativeElement.value = '';
    this.chipCtrl.setValue(null);
    this.filteredChips = [];
  }

  getPlaceholder(): string {
    if (!this.placeholder) return '';
    return this.selectedChips.length > 0 ? this.placeholder.hasSelected : this.placeholder.hasNoSelected;
  }

  onSearchChange(event: any) {
    const searchText = event.target.value;
    this.filteredChips = this.calcFilteredChips(searchText);
    this.exactMatch = this.findChipByName(searchText);
  }

  private findChipByName(searchText: string = ''): any {
    return this.allItems.find(chip => {
      const val = this.bindChipLabel ? chip[this.bindChipLabel] : chip;
      return val === searchText;
    });
  }

  private calcFilteredChips(searchText: string = ''): any[] {
    if (searchText === '') {
      return [];
    }

    return this.allItems.filter(chip => {
      const isNotSelected = !this.selectedChips.find(c => c[this.bindChipLabel] === chip[this.bindChipLabel]);
      const isContainSearchText = chip[this.bindChipLabel].indexOf(searchText) !== -1;
      return isNotSelected && isContainSearchText;
    });
  }

  switchView() {
    this.closed = !this.closed;
    if (!this.closed && !this.advancedConfigs.readOnly) {
      setTimeout(() => {
        this.chipInput.nativeElement.focus();
      });
    }

    if (!this.closed && this.advancedConfigs.readOnly) {
      this.chipsInPreview = this.selectedChips.length;
    } else {
      this.calculateHiddenChips();
    }
  }

  calculateHiddenChips = () => {
    this.chipsInPreview = 0;
    let currentLine = 1;
    let rowWidth = 0;
    for (let i = 0; i < this.selectedChips.length; i++) {
      if (this.selectedChips[i]) {
        let textWidth = this.getTextWidth(this.selectedChips[i][this.bindChipLabel]);
        textWidth = textWidth > this.maxChipWidth ? this.maxChipWidth : textWidth;
        textWidth += this.splitterWidth;
        if ((rowWidth + textWidth + this.getTextWidth('+ ' + (this.selectedChips.length - this.chipsInPreview + 1))) < this.containerWidth * currentLine) {
          // todo configure 'more' text if ((rowWidth + textWidth + this.getTextWidth('more')) < this.containerWidth * currentLine) {
          rowWidth += textWidth;
          this.chipsInPreview++;
        } else if (this.advancedConfigs.lines != currentLine) {
          rowWidth = currentLine * this.containerWidth + textWidth;
          this.chipsInPreview++;
          currentLine++;
        } else {
          break;
        }
      }
    }
  }

  getTextWidth(text: string): number {
    return this.calculateWordWidthService.calculate(text, {
      font: 'Open Sans',
      fontSize: '12px'
    }).width;
  }

}
