<div *ngIf="!menuItems?.length">
  <button mat-flat-button
          [disabled]="disabled"
          [class.transparent-button]="buttonType === 'transparent'"
          [class.filled-button]="buttonType === 'filled'"
          [class.primary-button]="buttonType === 'primary'"
          [color]="buttonType === 'primary' || buttonType === 'accent' ? buttonType : null"
          (click)="handleClick($event)">
    <mat-icon class="button-icon" *ngIf="!customMatIcon && !hiddenIcon">{{ matIcon }}</mat-icon>
    <mat-icon class="button-icon" [class]="customMatIconClass" [svgIcon]="customMatIcon" *ngIf="customMatIcon && !hiddenIcon"></mat-icon>
    <span class="button-label">{{ labelText }}</span>
    <ng-content select=".after-text"></ng-content>
  </button>
</div>
<div *ngIf="menuItems?.length">
  <button mat-flat-button
          #menuTrigger="matMenuTrigger"
          [disabled]="disabled"
          [class.transparent-button]="buttonType === 'transparent'"
          [class.filled-button]="buttonType === 'filled'"
          [class.primary-button]="buttonType === 'primary'"
          [class.expanded-menu]="menuTrigger.menuOpen"
          [color]="buttonType !== 'transparent' ? buttonType : null"
          [matMenuTriggerFor]="menu">
    <mat-icon class="button-expand-more-icon" *ngIf="showDefaultMenuIcon" iconPositionEnd>expand_more</mat-icon>
    <mat-icon class="button-icon" *ngIf="!customMatIcon && !hiddenIcon">{{ matIcon }}</mat-icon>
    <mat-icon class="button-icon" [class]="customMatIconClass" [svgIcon]="customMatIcon" *ngIf="customMatIcon && !hiddenIcon"></mat-icon>
    <span class="button-label">{{ labelText }}</span>
    <ng-content select=".after-text"></ng-content>
  </button>
  <mat-menu class="mat-menu-op-button-2021"
            #menu="matMenu"
            backdropClass="no-filter">
    <ng-container *ngFor="let item of menuItems">
      <button mat-menu-item *ngIf="!item.type && !item.hidden" [ngClass]="item.itemClass" (click)="item.action()">
        <mat-icon *ngIf="item.icon" class="item-icon">{{ item.icon }}</mat-icon>
        <span>
          <span class="item-label">{{ item.label }}</span>
          <mat-icon *ngIf="item.labelIcon" class="menu-item-icon" svgIcon="{{ item.labelIcon }}"></mat-icon>
          <span *ngIf="item.labelAfterIcon">{{item.labelAfterIcon}}</span>
        </span>
        <span class="item-label-post" *ngIf="item.postLabel">{{item.postLabel}}</span>
      </button>
      <hr *ngIf="item.type === EOpButtonMenuTypes.Hr && !item.hidden" [ngClass]="item.itemClass"/>
      <div class="item-label-header" *ngIf="item.type === EOpButtonMenuTypes.Header && !item.hidden" [ngClass]="item.itemClass">
        {{ item.label }}
      </div>
    </ng-container>
  </mat-menu>
</div>
