import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { Names } from '@app/moonbeamConstants';

import * as angular from 'angular';
import { ModalEscapeService } from './modalEscape/modalEscapeService';
import { ShadeWindowDataController } from './shadeWindow/shadeWindowDataController';
import { ShadeWindowDataDirective } from './shadeWindow/shadeWindowDataDirective';
import { ShadeWindowDataService } from './shadeWindow/shadeWindowDataService';
import { ShadeWindowDirective } from './shadeWindow/shadeWindowDirective';
import { SpinnerDirective } from './spinner/spinnerDirective';
import { OPSpinnerService } from './spinner/spinnerService';
import { focusGuardDirective } from './focusGuard/focusGuardDirective';
import { modalEscapeDirective } from './modalEscape/modalEscapeDirective';
import './tabset/tabset.js';
import { OpClipboardDirective } from '@app/components/ui/opClipboard/opClipboardDirective';

import { OpMenuComponent } from '@app/components/shared/components/op-menu/op-menu.component';
import { OpChipSelectorComponent } from '../shared/components/op-chip-selector/op-chip-selector.component';
import { opCircleProgressNgName, CircleProgressComponent } from '../shared/components/op-circle-progress/circle-progress.component';
import { ThumbnailComponent } from '../shared/components/op-thumbnail/thumbnail.component';
import { OpModalComponent, OpModalService } from '@app/components/shared/components/op-modal';
import { TerminateActiveRunsModalService } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';

export default angular.module('uiModule', [])
  .directive('focusGuard', focusGuardDirective)
  .directive('modalEscape', modalEscapeDirective)
  .service(Names.Services.modalEscape, ModalEscapeService)
  .controller(Names.Controllers.shadeWindowData, ShadeWindowDataController)
  .directive('shadeWindowData', ShadeWindowDataDirective.factory())
  .service(Names.Services.shadeWindowData, ShadeWindowDataService)
  .directive('shadeWindow', ShadeWindowDirective.factory())
  .directive('opSpinner', SpinnerDirective.factory())
  .service(Names.Services.opSpinner, OPSpinnerService)
  .directive(Names.Directives.opClipboard, OpClipboardDirective.factory())
  .directive(Names.NgComponents.opThumbnail, downgradeComponent({ component: ThumbnailComponent }))
  .directive(Names.NgComponents.opMenuNg, downgradeComponent({ component: OpMenuComponent }))
  .directive(Names.NgComponents.opChipsNg, downgradeComponent({ component: OpChipSelectorComponent }))
  .directive(opCircleProgressNgName, downgradeComponent({ component: CircleProgressComponent }))
  .directive(Names.NgComponents.opModal, downgradeComponent({ component: OpModalComponent }))
  .service(Names.NgServices.opModalService, downgradeInjectable(OpModalService))
  .service(Names.NgServices.terminateActiveRunsModalService, downgradeInjectable(TerminateActiveRunsModalService))

  .name;
