<div class="tag-info-wrap">
  <form [formGroup]="tagInfoForm" (ngSubmit)="onSubmit()">
    <div class="tabs">
      <mat-tab-group mat-stretch-tabs="false" class="inner-group">
        <mat-tab label="Tag Owners">
          <h2 class="tag-info-section-heading" *ngIf="!readOnly">Add Owners</h2>
          <op-drag-list [data]="tagOwnersData"
                        [draggable]="false"
                        [dragulaContainer]="'tagOwners-'+tagInfo.tagId"
                        formArrayName="tagOwners">
              <ng-template #row let-item="item" let-index="index">
                <div class="contact-row-flex" [formGroupName]="index">
                  <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email">
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Phone Number</mat-label>
                    <input matInput formControlName="phone">
                  </mat-form-field>
                  <mat-icon class="add-icon" (click)="addTagOwner(index + 1)" *ngIf="!readOnly">add_circle_outline</mat-icon>
                  <mat-icon class="delete-icon" (click)="deleteTagOwner(index)" *ngIf="tagOwnersData.length > 1 && !readOnly">clear</mat-icon>
                </div>
              </ng-template>
          </op-drag-list>
        </mat-tab>
        <mat-tab label="Technical Specialists">
          <h2 class="tag-info-section-heading" *ngIf="!readOnly">Add Specialist</h2>
          <op-drag-list [data]="techSpecialistsData"
                        [draggable]="false"
                        [dragulaContainer]="'techSpecialists-'+tagInfo.tagId"
                        formArrayName="techSpecialists">
            <ng-template #row let-item="item" let-index="index">
              <div class="contact-row-flex" [formGroupName]="index">
                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Phone Number</mat-label>
                  <input matInput formControlName="phone">
                </mat-form-field>
                <mat-icon class="add-icon" (click)="addTechSpecialist(index + 1)" *ngIf="!readOnly">add_circle_outline</mat-icon>
                <mat-icon class="delete-icon" (click)="deleteTechSpecialist(index)" *ngIf="techSpecialistsData.length > 1 && !readOnly">clear</mat-icon>
              </div>
            </ng-template>
          </op-drag-list>
        </mat-tab>
        <mat-tab label="Vendor Contacts">
          <h2 class="tag-info-section-heading" *ngIf="!readOnly">Add Contact</h2>
          <op-drag-list [data]="vendorContactsData"
                        [draggable]="false"
                        [dragulaContainer]="'vendorContacts-'+tagInfo.tagId"
                        formArrayName="vendorContacts">
            <ng-template #row let-item="item" let-index="index">
              <div class="contact-row-flex" [formGroupName]="index">
                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput formControlName="name">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Email</mat-label>
                  <input matInput formControlName="email">
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Phone Number</mat-label>
                  <input matInput formControlName="phone">
                </mat-form-field>
                <mat-icon class="add-icon" (click)="addVendorContact(index + 1)" *ngIf="!readOnly">add_circle_outline</mat-icon>
                <mat-icon class="delete-icon" (click)="deleteVendorContact(index)" *ngIf="vendorContactsData.length > 1 && !readOnly">clear</mat-icon>
              </div>
            </ng-template>
          </op-drag-list>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="other">
      <h2 class="tag-info-section-heading">More Info</h2>
      <div class="other-flex">
        <div class="cost" formGroupName="cost">
          <mat-form-field class="flex-item no-hint-container">
            <mat-label>Cost</mat-label>
            <input matInput formControlName="cost">
          </mat-form-field>
          <span class="flex-item-separator">per</span>
          <mat-form-field class="flex-item no-hint-container">
            <mat-label>Period</mat-label>
            <mat-select formControlName="period">
              <mat-option value="month">Month</mat-option>
              <mat-option value="quarter">Quarter</mat-option>
              <mat-option value="year">Year</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-form-field class="no-hint-container notes no-padding">
          <textarea matInput placeholder="Notes" formControlName="notes" rows="1"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="button-flex" *ngIf="!readOnly">
      <button class="save" mat-flat-button color="primary" type="submit" [disabled]="tagInfoForm.pristine">Save</button>
    </div>
  </form>
</div>
