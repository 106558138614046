<!-- ------------ -->
<!-- COMPACT VIEW -->
<!-- ------------ -->
<div #container *ngIf="compactView" class="op-chip-manager compact-view">
  <div class="split-chip" *ngIf="!isAddingNewChip">
    <button *ngIf="!chipList.length"
            class="split-chip-segment split-chip-left empty-chip"
            [class.read-only-chip]="isReadOnly"
            (click)="addNewChip()">
      {{ splitChipText }}
    </button>
    <button *ngIf="chipList.length"
            class="split-chip-segment split-chip-left"
            [class.read-only-chip]="isReadOnly"
            [mdePopoverTriggerFor]="showMorePopover"
            [mdePopoverTriggerOn]="'click'">
      {{ splitChipText }}
      <mat-icon class="split-chip-left-icon" *ngIf="chipList.length">expand_more</mat-icon>
    </button>
    <button *ngIf="!isReadOnly"
            class="split-chip-segment split-chip-right"
            (click)="addNewChip()">
      <mat-icon class="add-icon">add</mat-icon>
    </button>
    <mde-popover #showMorePopover="mdePopover"
                [mdePopoverOverlapTrigger]="false"
                [mdePopoverCloseOnClick]="false">
      <div class="chips-popover-wrapper mat-elevation-z5" [ngStyle]="{ 'width.px': containerWidth }">
        <op-chip *ngFor="let chip of chipList; let i = index"
                  [chip]="chip"
                  [chipConfig]="chipListConfigs[i]"
                  [isReadOnly]="isReadOnly"
                  (onChipRemoved)="onChipRemoved.emit($event)">
        </op-chip>
      </div>
  </mde-popover>
  </div>
  <div *ngIf="isAddingNewChip" class="add-chip">
    <input #chipInput
           type="text"
           class="compact-chip-input"
           maxlength="maxNoOfCharacters"
           [attr.maxlength]="maxNoOfCharacters"
           [formControl]="chipName"
           [matAutocomplete]="auto"
           (blur)="handleNewChipBlurred()"
           (keyup.enter)="handleCreateNewChip()"
           (keyup.escape)="handleRemoveNewChip()" />
    <button *ngIf="!isReadOnly" class="remove-chip-btn" (click)="handleRemoveNewChip()">
      <mat-icon class="remove-chip-icon">close</mat-icon>
    </button>
  </div>
  <mat-autocomplete #auto="matAutocomplete" [panelWidth]="containerWidth" class="new-chip-autocomplete" (optionSelected)="handleChipSelected($event.option.value)">
    <mat-option [value]="chip" *ngFor="let chip of autocompleteChips">{{ chip.name }}</mat-option>
    <mat-option *ngIf="chipName.value.length && !exactMatch && !alreadyApplied" [value]="chipName.value" (click)="handleCreateNewChip()" class="create-new-option">
      <div class="create-new-wrapper">
        <div class="create-new-name">{{ chipName.value }}</div>
        <div class="create-new-label">create new {{ chipType | lowercase }}</div>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>

<!-- ----------- -->
<!-- NORMAL VIEW -->
<!-- ----------- -->
<div #container *ngIf="!compactView" class="op-chip-manager normal-view">
  <op-chip *ngFor="let chip of chipList; let i = index"
           [chip]="chip"
           [chipConfig]="chipListConfigs[i]"
           [isReadOnly]="isReadOnly"
           (onChipRemoved)="onChipRemoved.emit($event)">
  </op-chip>

  <!-- more button -->
  <button class="more-btn"
          [class.popover-open]="popoverOpen"
          [hidden]="!showMoreBtn"
          [mdePopoverTriggerFor]="showMorePopover"
          [mdePopoverTriggerOn]="'click'"
          (opened)="popoverOpen = true"
          (closed)="popoverOpen = false">
    {{ noOfHiddenChips }} more <mat-icon class="arrow-down-icon">expand_more</mat-icon>
  </button>
  <mde-popover #showMorePopover="mdePopover"
               [mdePopoverOverlapTrigger]="false"
               [mdePopoverCloseOnClick]="false">
      <div class="chips-popover-wrapper mat-elevation-z5" [ngStyle]="{ 'width.px': containerWidth }">
        <op-chip *ngFor="let chip of hiddenChips; let i = index"
                  [chip]="chip"
                  [chipConfig]="hiddenChipsConfigs[i]"
                  [isReadOnly]="isReadOnly"
                  (onChipRemoved)="onChipRemoved.emit($event)">
        </op-chip>
      </div>
  </mde-popover>

  <!-- add new chip button -->
  <button #addChip
          [class.invisible]="isAddingNewChip"
          (click)="addNewChip()"
          matTooltip="Add {{ chipType }}"
          matTooltipPosition="above"
          [matTooltipDisabled]="!chipList.length || isReadOnly"
          class="add-new-chip-btn">
    <div class="add-icon-wrapper" *ngIf="!isReadOnly">
      <mat-icon class="add-icon">add_circle</mat-icon>
      <div class="add-icon-bg"></div>
    </div>
    <span class="add-label-text" *ngIf="!chipList.length && !hiddenChips.length && !isReadOnly">add {{ chipType | lowercase }}</span>
  </button>

  <!-- new chip -->
  <ng-container *ngIf="isAddingNewChip">
    <div class="new-chip-wrapper">
      <input #chipInput
             type="text"
             class="chip-input ellipsis"
             [ngStyle]="{ 'width.px': inputSize }"
             [attr.maxlength]="maxNoOfCharacters"
             [formControl]="chipName"
             [matAutocomplete]="auto"
             (blur)="handleNewChipBlurred()"
             (keyup.enter)="handleCreateNewChip()"
             (keyup.escape)="handleRemoveNewChip()" />
      <button class="remove-chip-btn" *ngIf="!isReadOnly" (click)="handleRemoveNewChip()">
        <mat-icon class="remove-chip-icon">close</mat-icon>
      </button>
    </div>
    <mat-autocomplete #auto="matAutocomplete"
                      autoActiveFirstOption
                      [panelWidth]="containerWidth > maxAutocompleteWidth ? maxAutocompleteWidth : containerWidth"
                      class="new-chip-autocomplete"
                      (optionSelected)="handleChipSelected($event.option.value)">
      <mat-option [value]="chip" *ngFor="let chip of autocompleteChips">{{ chip.name }}</mat-option>
      <mat-option *ngIf="chipName.value.length && !exactMatch && !alreadyApplied" [value]="chipName.value" (click)="handleCreateNewChip()" class="create-new-option">
        <div class="create-new-wrapper">
          <div class="create-new-name">{{ chipName.value }}</div>
          <div class="create-new-label">create new {{ chipType | lowercase }}</div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </ng-container>
</div>
