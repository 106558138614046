<div class="table-container" [class.loading]="dataStatus !== dataStatusType.loaded">
  <table mat-table 
         matSort
         class="styled-mat-table"
         [dataSource]="tableDataSource"
         [matSortActive]="tableState?.sortBy"
         [matSortDirection]="tableState?.sortDesc ? 'desc' : 'asc'"
         [matSortStart]="tableState?.sortDesc ? 'desc' : 'asc'"
         (matSortChange)="onSort.emit(tableSort)">

    <ng-container [matColumnDef]="tableColumn.dateExported">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE EXPORTED</th>
      <td mat-cell *matCellDef="let export"> {{export.dateExported | date: dateFormat}} </td>
    </ng-container>

    <ng-container [matColumnDef]="tableColumn.itemName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>NAME</th>
      <td mat-cell 
          *matCellDef="let export"
          class="truncated"
          matTooltipClass="export-center-item-name"
          matTooltipPosition="above"
          [matTooltip]="export?.itemName"
          [matTooltipDisabled]="export?.itemName?.length < 20">
        <a class="hover-link"
           [routerLink]="export.routerLink.url"
           [queryParams]="export.routerLink.queryParams">
          {{export.itemName}}
        </a>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="tableColumn.itemType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ITEM TYPE</th>
      <td mat-cell *matCellDef="let export">
        <div class="date-format">
          <span>{{export.itemLabel}}</span>
        </div>
    </ng-container>

    <ng-container [matColumnDef]="tableColumn.runDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>RUN DATE</th>
      <td mat-cell *matCellDef="let export">
        <span [innerHTML]="export.runDate"></span>
      </td>
    </ng-container>
    
    <ng-container [matColumnDef]="tableColumn.exportName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>EXPORT NAME</th>
      <td mat-cell *matCellDef="let export"> {{export.exportName}} </td>
    </ng-container>

    <ng-container [matColumnDef]="tableColumn.requestedByUserId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>REQUESTED BY</th>
      <td mat-cell *matCellDef="let export">
        <span [matTooltip]="export.user?.email" matTooltipPosition="above">
          {{export.user ? export.user.firstName + ' ' + export.user.lastName : 'Unknown'}}
        </span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="tableColumn.options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let export" align="right">
        <div class="table-actions" *ngIf="export.exportStatus === exportStatus.completed else exportTextStatus">
          <button class="table-action-icon done-icon"
                *ngIf="copiedExportLink === export.exportDownloadLink"
                matTooltip="Copied!"
                matTooltipPosition="above"
                [matTooltipHideDelay]="copiedIconTimeout">
            <mat-icon>done</mat-icon>
          </button>
          <button class="table-action-icon"
               *ngIf="copiedExportLink !== export.exportDownloadLink"
               matTooltip="Copy link to export"
               matTooltipPosition="above"
               (click)="copyLink(export)">
            <mat-icon>content_copy</mat-icon>
          </button>

          <button class="table-action-icon"
               matTooltip="Download export"
               matTooltipPosition="above"
               (click)="onDownloadExport.emit(export)">
            <mat-icon>cloud_download</mat-icon>
          </button>

          <button class="table-action-icon"
                matTooltip="Email export"
                matTooltipPosition="above"
                (click)="onSendReport.emit(export)">
            <mat-icon>email</mat-icon>
          </button>
        </div>
        <ng-template #exportTextStatus>
          <span [ngSwitch]="export.exportStatus" class="export-text-status">
            <span *ngSwitchCase="exportStatus.queued">Export queued</span>
            <span *ngSwitchCase="exportStatus.inProgress">Export creation<br>in progress...</span>
            <span *ngSwitchCase="exportStatus.noData">Export completed<br>without data</span>
            <span *ngSwitchDefault>Export failed</span>
          </span>
        </ng-template>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let export; columns: columnsToDisplay"></tr>
    <tr *matNoDataRow>
      <td [colSpan]="columnsToDisplay.length">
        <div *ngIf="dataStatus === dataStatusType.loaded" class="no-data">
          <span>No exports found.</span>
        </div>
      </td>
    </tr>
  </table>

  <mat-paginator
    class="styled-mat-paginator"
    [length]="pagination?.totalCount"
    [pageSize]="pagination?.pageSize"
    [pageIndex]="pagination?.currentPageNumber"
    [hidePageSize]="true"
    (page)="onPagination.emit($event)"
  ></mat-paginator>

  <mat-spinner 
    *ngIf="dataStatus !== dataStatusType.loaded"
    class="centered"
    [diameter]="75"
    [strokeWidth]="8">
  </mat-spinner>
</div>
