<table mat-table
       class="styled-mat-table"
       [dataSource]="dataSource"
       matSort
       (matSortChange)="sortChanged.emit($event)">

  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <div *ngIf="selectionMode === ESimpleTableSelectionMode.Multiple"
           [matTooltip]="readOnlyLabel"
           matTooltipPosition="above"
           [matTooltipDisabled]="!isReadOnly">
        <mat-checkbox *ngIf="allItems?.items.length > 0 && !hideSelectAll"
                      (change)="$event ? toggleAllRows($event.checked) : null"
                      [checked]="areAllSelected()"
                      [disabled]="isReadOnly"
                      color="primary">
        </mat-checkbox>
      </div>
    </th>
    <td mat-cell *matCellDef="let row">
      <div [matTooltip]="readOnlyLabel"
           matTooltipPosition="above"
           [matTooltipDisabled]="!isReadOnly">
        <ng-container *ngIf="selectionMode === ESimpleTableSelectionMode.Multiple">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? toggleRow(row) : null"
            [checked]="isSelected(row)"
            [disabled]="isReadOnly"
            color="primary">
          </mat-checkbox>
        </ng-container>

        <ng-container *ngIf="selectionMode === ESimpleTableSelectionMode.Single">
          <mat-radio-button
            (click)="$event.stopPropagation()"
            (change)="$event ? toggleRow(row) : null"
            [checked]="isSelected(row)"
            [disabled]="isReadOnly"
            color="primary">
          </mat-radio-button>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <!-- Custom Columns -->
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.propName">
    <ng-container *ngIf="column.type === ESimpleTableColumnType.Text">
      <th mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="column.sortKey"
          [disabled]="!column.sortKey"
      >
        {{ column.title }}
      </th>
      <td mat-cell
          *matCellDef="let item"
          [matTooltip]="item[column.propName]"
          [matTooltipDisabled]="item[column.propName]?.length < 19 || column.type === ESimpleTableColumnType.Chips"
          matTooltipPosition="above"
          matTooltipClass="simple-table-truncated-text"
          class="truncated">
        <span>
          {{ item[column.propName] }}
        </span>
      </td>
    </ng-container>
    <ng-container *ngIf="column.type === ESimpleTableColumnType.Chips">
      <th mat-header-cell
          *matHeaderCellDef
          [mat-sort-header]="column.sortKey"
          [disabled]="!column.sortKey"
          [class.chips-column]="column.type === ESimpleTableColumnType.Chips"
      >
        {{ column.title }}
      </th>
      <td mat-cell
          *matCellDef="let item"
          [matTooltip]="item[column.propName]"
          [matTooltipDisabled]="item[column.propName]?.length < 19 || column.type === ESimpleTableColumnType.Chips"
          matTooltipPosition="above"
          matTooltipClass="simple-table-truncated-text"
          class="truncated">
        <op-label-manager [selectedLabels]="item[column.propName]"
                          [compactView]="true"
                          [isReadOnly]="true">
        </op-label-manager>
      </td>
    </ng-container>
    <ng-container *ngIf="column.type === ESimpleTableColumnType.Popover">
      <th mat-header-cell *matHeaderCellDef>
        <div class="center-col-heading">
          <ng-container *ngIf="column.icon">
            <mat-icon>{{ column.icon }}</mat-icon>
          </ng-container>
          {{ column.title }}
        </div>
      </th>
      <td mat-cell *matCellDef="let item">
        <div *ngIf="item[column.propName] > 0; else noPopover" class="center-cell-content pointer"
             mdePopoverTriggerOn="click"
             [mdePopoverTriggerFor]="popover">
          {{ item[column.propName] }}
          <mat-icon class="popover-arrow">keyboard_arrow_down</mat-icon>
        </div>
        <ng-template #noPopover>
          <div class="center-cell-content dim">
            {{ item[column.propName] }}
          </div>
        </ng-template>

        <mde-popover #popover="mdePopover"
                     [mdePopoverOverlapTrigger]="false"
                     [mdePopoverPositionX]="'after'">
          <mat-card class="popover-content">
            <div class="popover-inner-content">
              <ng-container *ngIf="isArray(item[column.popoverPropName]); else string">
                <div *ngFor="let value of item[column.popoverPropName]" class="items">
                  {{ value }}
                </div>
              </ng-container>
              <ng-template #string>
                <div class="items">
                  {{ item[column.popoverPropName] }}
                </div>
              </ng-template>
            </div>
          </mat-card>
        </mde-popover>
      </td>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: stickyHeader"></tr>
  <tr mat-row *matRowDef="let item; columns: displayedColumns"></tr>

  <tr *matNoDataRow>
    <td [colSpan]="displayedColumns.length">
      <div class="data-loading" *ngIf="allItems?.loading; else noData">
        <mat-spinner [diameter]="25" [strokeWidth]="4"></mat-spinner>
      </div>
      <ng-template #noData>
        <div class="no-data">No data.</div>
      </ng-template>
    </td>
  </tr>
</table>

<mat-paginator *ngIf="pagination"
               class="styled-mat-paginator"
               [class.hidden]="allItems?.loading"
               [length]="pagination?.totalCount"
               [pageSize]="pagination?.pageSize"
               [pageIndex]="pagination?.currentPageNumber"
               [hidePageSize]="true">
</mat-paginator>
