<div class="header-row">
  <p class="title">
    WCAG Standards
    <mat-icon class="help-icon"
              [matMenuTriggerFor]="helpMenu">help_outline
    </mat-icon>
  </p>

  <div class="buttons">
    <share-report-btn *ngIf="reportType === 'audit'"
                      [auditId]="itemId"
                      [runId]="runId"
                      [pageId]="pageId"
                      [activeTab]="activeTab">
    </share-report-btn>
    <button class="export-btn"
            (click)="exportAllIssues()"
            [matTooltip]="exportAllIssuesTooltip"
            [matTooltipPosition]="'above'"
    >
      <mat-icon>cloud_download</mat-icon>
    </button>
  </div>
</div>

<div class="filters">
  <page-details-accessibility-filter *ngFor="let filter of filters"
                                     (valueChanged)="updateFilter($event)"
                                     [filter]="filter"></page-details-accessibility-filter>
</div>

<mat-menu #helpMenu="matMenu"
          xPosition="after"
          yPosition="above"
          backdropClass="no-filter">
  <div class="help-menu">
    The Accessibility report provides a comprehensive overview of
    all issues detected by automated accessibility tests, to help
    ensure compliance with WCAG 2.0, 2.1, and 2.2 standards (A, AA, AAA levels).
    <br>
    <a class="link" href="https://help.observepoint.com/en/articles/9100627-page-details-report#h_9272fc6025"
       target="_blank">Learn More</a>
  </div>
</mat-menu>
