import { Component, Inject, Input } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-data-layers',
  templateUrl: './manage-data-layers.component.html',
  styleUrls: ['./manage-data-layers.component.scss']
})
export class ManageDataLayersComponent {

  manageSubfolderForm = new FormGroup({
    dataLayerField: new FormControl(''),
  });

  editing = true;
  saving = false;
  error = false;
  useDefaultDomain: boolean = false;  // Domain field is hidden and value defaults to http://www.example.com if set to true

  rightFooterButtons = [
    {
      label: 'Save',
      action: () => {
        this.save();
      },
      primary: true
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<ManageDataLayersComponent>
  ) {
  }

  save(): void {
    this.rightFooterButtons[0]['disabled'] = true;
    this.dialogRef.close(this.dataLayerField());
  }

  dataLayerField(): string {
     return this.manageSubfolderForm.get('dataLayerField').value;
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
