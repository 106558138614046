import {
  Component,
  Input,
  HostListener,
  OnChanges,
  AfterViewInit,
  OnDestroy,
  Output,
  EventEmitter, SimpleChanges, AfterContentInit
} from '@angular/core';
import { combineLatest, merge, Subject } from 'rxjs';
import { auditTime, debounceTime, filter, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import * as d3 from 'd3';
import { ScaleLinear } from 'd3-scale';
import { Selection } from 'd3-selection';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { SidebarService } from '@app/components/navigation/sidebar/sidebar.service';
import {
  IStackedBarChartDataPoint,
  IStackedLimitBarChartFormattedDataPoint
} from './horizontal-stacked-limit-bar-chart.models';
import { EChartColor } from '@app/components/audit-reports/audit-report/audit-report.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'horizontal-stacked-limit-bar-chart',
  templateUrl: './horizontal-stacked-limit-bar-chart.component.html',
  styleUrls: ['./horizontal-stacked-limit-bar-chart.component.scss']
})
export class HorizontalStackedLimitBarChartComponent implements OnChanges, OnDestroy, AfterContentInit {

  @Input() data: [IStackedBarChartDataPoint, IStackedBarChartDataPoint];
  @Input() limits: [IStackedBarChartDataPoint, IStackedBarChartDataPoint];
  @Input() filtered?: IStackedBarChartDataPoint;
  @Input() state: EFilterSpinnerState = EFilterSpinnerState.None;
  @Input() chartHeight?: number = 31; // measured in pixels
  @Input() additionalTooltipClass?: string;
  @Input() hideTooltip?: boolean;
  @Input() getTooltipMessageFn?: (data: IStackedLimitBarChartFormattedDataPoint | IStackedBarChartDataPoint) => string;
  @Input() clickAction?: Function;

  dataChanged$ = new Subject<[IStackedBarChartDataPoint, IStackedBarChartDataPoint]>();
  limitsChanged$ = new Subject<[IStackedBarChartDataPoint, IStackedBarChartDataPoint]>();
  filteredChanged$ = new Subject<IStackedBarChartDataPoint | void>();

  @Output() filterSelected: EventEmitter<{ mouseEvent: MouseEvent, item: IStackedLimitBarChartFormattedDataPoint }> = new EventEmitter();

  uniqueIdentifier = (1 | Math.random() * 16e6).toString(16);
  formattedData: IStackedLimitBarChartFormattedDataPoint[];
  hasActiveFilter: boolean = false;
  container: Selection<any, any, any, any>;
  svg: Selection<any, any, any, any>;
  svgGroup: Selection<any, any, any, any>;
  svgHeight: number;
  svgWidth: number;
  tooltip: Selection<any, any, any, any>;
  tooltipClass: string = 'horizontal-stacked-limit-bar-chart-tooltip';
  tooltipWidth: number;
  tooltipHeight: number;
  windowWidth: number = window.innerWidth;

  private x: ScaleLinear<any, any, any>;
  private y: ScaleLinear<number, number, never>;
  private resize$ = new Subject();
  private destroy$ = new Subject();
  private totalPoints: number;
  private allRequiredDataArePassed: boolean;

  constructor(private sidebarService: SidebarService) {
    const viewObservables = [
      this.dataChanged$.pipe(tap(() => this.formatData())),
      this.limitsChanged$,
      this.filteredChanged$
    ];

    combineLatest(viewObservables).pipe(
      debounceTime(2000),
      take(1),
      tap(() => {
        this.allRequiredDataArePassed = true;
        this.redrawCharts();
      }),
      switchMap(() => merge(...viewObservables)),
      auditTime(100),
      takeUntil(this.destroy$)
    ).subscribe(() => this.redrawCharts());

    merge(...viewObservables).subscribe();

  }

  ngAfterContentInit(): void {
    merge([this.resize$, this.sidebarService.isClosed.pipe(skip(1))])
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$),
        filter(() => this.allRequiredDataArePassed)
      )
      .subscribe(() => this.redrawCharts());
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['data'].currentValue && changes['data'].currentValue !== changes['data'].previousValue) {
      this.dataChanged$.next(changes['data'].currentValue);
    }
    if (changes['limits']?.currentValue !== changes['limits']?.previousValue) {
      this.limitsChanged$.next(changes['limits'].currentValue);
    }
    if (changes['filtered']?.currentValue !== changes['filtered']?.previousValue) {
      this.filteredChanged$.next(changes['filtered'].currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private formatData(): void {
    this.totalPoints = d3.max(this.data, (d: IStackedBarChartDataPoint) => d.value);
    let totalSum = 0;

    this.formattedData = this.data.map((d: IStackedBarChartDataPoint): IStackedLimitBarChartFormattedDataPoint => {
      let roundedPercentage: number;

      // ensures fractional percentages don't round to
      // 0% or 100% and instead display as 0.xx% and 99%
      const percentage = (d.value / this.totalPoints) * 100;

      if (percentage < 100 && percentage > 99) {
        roundedPercentage = 99;
      } else if (percentage < 1 && percentage > 0) {
        roundedPercentage = parseFloat(percentage.toFixed(2)) || 0.01;
      } else {
        roundedPercentage = Math.round(percentage);
      }

      const chartBarConfig = {
        id: d.id,
        name: `${d.name} (${roundedPercentage}%)`,
        tooltipDisplayName: d.name,
        textOnHover: d.textOnHover,
        value: d.value,
        startPosition: totalSum,
        colorClass: d.colorClass,
        filtered: d.filtered
      };
      totalSum += d.value;
      return chartBarConfig;
    });
  }

  redrawCharts(): void {
    // svg element
    this.container = d3.select(`.svg-container-${this.uniqueIdentifier}`).html('');

    if (!this.container) {
      return;
    }

    this.svgHeight = this.chartHeight;
    this.svgWidth = parseInt(this.container.style('width'));

    this.svg = this.container
      .append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    this.svgGroup = this.svg.append('g');

    // tooltip
    // only create if it's not already on the page
    if (this.getTooltipMessageFn && !d3.select(`.${this.tooltipClass}${this.additionalTooltipClass ? '.' + this.additionalTooltipClass : ''}`).node()) {
      this.createTooltip();
    }

    // get reference to the DOM element
    this.tooltip = d3.select(`.${this.tooltipClass}${this.additionalTooltipClass ? '.' + this.additionalTooltipClass : ''}`);

    // start the drawing process
    this.drawLines();
  }

  drawLines(): void {
    if (!this.data.length || !this.svgGroup) return;

    this.x = d3.scaleLinear([0, 1], [0, this.svgWidth]);
    this.y = d3.scaleLinear([0, this.svgHeight], [0, this.svgHeight]);

    this.svgGroup
      .selectAll('.stacked-bar-rect')
      .data(this.formattedData)
      .join((enter: Selection<any, any, any, any>) => this.categoryEnter(enter));

    this.svgGroup
      .selectAll('.stacked-bar-rect-text')
      .data(this.formattedData)
      .join((enter: Selection<any, any, any, any>) => this.textEnter(enter));

    if (this.filtered) {
      this.svgGroup
        .selectAll('.stacked-bar-rect-filtered')
        .data([this.filtered])
        .join((enter: Selection<any, any, any, any>) => this.filteredEnter(enter));
    }

    if (this.limits) {
      this.svgGroup
        .selectAll('.stacked-bar-rect-limit')
        .data(this.limits)
        .join((enter: Selection<any, any, any, any>) => this.limitEnter(enter));
    }
  }

  private createTooltip(): void {
    d3
      .select('body')
      .append('div')
      .attr('class', `${this.tooltipClass} ${this.additionalTooltipClass || ''}`);
  }

  private categoryEnter(enter: Selection<any, any, any, any>) {
    const rect = enter
      .append('path')
      .attr('d', (d: IStackedLimitBarChartFormattedDataPoint) => {
        const radius = 12;
        let rightCornerRadius = d.colorClass === EChartColor.Gray ? radius : d.value === this.totalPoints ? radius : 0;
        return HorizontalStackedLimitBarChartComponent.generatePathD(0, this.y(3), this.x(1), this.y(this.svgHeight - 6), 10);
      })
      .attr('opacity', this.filtered ? .2 : 1)
      .attr('class', (d: IStackedLimitBarChartFormattedDataPoint) => `stacked-bar-rect ${d.colorClass} ${this.filtered ? 'filtered' : ''}`);

    return this.setView(rect);
  }

  private textEnter(enter: Selection<any, any, any, any>) {
    return enter.append('text')
      .text((d: IStackedLimitBarChartFormattedDataPoint) => d.textOnHover)
      .attr('class', (d: IStackedLimitBarChartFormattedDataPoint) => `stacked-bar-rect-text ${d.colorClass}-text`)
      .attr('x', (d: IStackedLimitBarChartFormattedDataPoint) => {

        const chart = `.stacked-bar-rect.${d.colorClass}`;
        const blueChart = this.svgGroup.select(chart);
        const chartSizes = (blueChart.node() as Element).getBoundingClientRect();
        const text = enter.select(`.stacked-bar-rect-text.${d.colorClass}-text`).node();
        const textWidth = (text as Element).getBoundingClientRect();
        const percent = d.value / this.totalPoints;

        if ((textWidth.width + 10) > chartSizes.width * percent) {
          return chartSizes.width * percent + 20;
        } else {
          return this.x(d.value / this.totalPoints * percent) - 20;
        }
      })
      .attr('y', this.y(this.svgHeight / 2) + 5);

  }

  private filteredEnter(enter: Selection<any, any, any, any>) {
    const rect = enter
      .append('rect')
      .attr('class', (d: IStackedLimitBarChartFormattedDataPoint) => `stacked-bar-rect-filtered ${d.colorClass}`);

    return this.setView(rect);
  }

  private limitEnter(enter: Selection<any, any, any, any>) {
    const res = enter
      .append('rect')
      .attr('class', (d: IStackedBarChartDataPoint) => `stacked-bar-rect-limit ${d.colorClass}`)
      .attr('x', (d: IStackedBarChartDataPoint) => this.x(d.value / this.totalPoints))
      .attr('width', 4)
      .attr('y', this.y(0))
      .attr('height', this.svgHeight)
      .attr('opacity', 1)
      .classed('selected', (d: IStackedBarChartDataPoint) => d.filtered);

    if (this.getTooltipMessageFn) {
      res.on('mouseover', (e: MouseEvent, d: IStackedBarChartDataPoint) => {
        this.setTooltipTextValue(d);

        // send to bottom of the stack, so we can get dimensions
        setTimeout(() => this.setTooltipBoxDimensions(), 0);

        this.tooltip
          .transition()
          .duration(150)
          .style('opacity', 1);
      })
        .on('mousemove', (e: MouseEvent, d: IStackedBarChartDataPoint) => {
          this.setTooltipBoxDimensions();
          this.handleTooltipPosition(e);
        })
        .on('mouseout', () => {
          this.tooltip
            .transition()
            .delay(25)
            .style('opacity', 0);
        });
    }

    return res;
  }

  private setTooltipTextValue(d: IStackedLimitBarChartFormattedDataPoint | IStackedBarChartDataPoint): void {
    this.tooltip.html(this.getTooltipMessageFn(d));
  }

  private setTooltipBoxDimensions(): void {
    this.tooltipWidth = this.tooltip.node().getBoundingClientRect().width + 14;
    this.tooltipHeight = this.tooltip.node().getBoundingClientRect().height + 8;
  }

  private handleTooltipPosition(e: MouseEvent): void {
    const horizPos = e.clientX;
    const vertPos = e.clientY;
    const leftEdge = 0;
    const rightEdge = this.windowWidth;
    const edgePadding = 5;

    // left edge of window
    if ((horizPos - (this.tooltipWidth / 2)) <= leftEdge + edgePadding) {
      this.tooltip
        .style('left', `${(this.tooltipWidth / 2) + edgePadding}px`)
        .style('top', `${vertPos - this.tooltipHeight - 20}px`);
    } else if ((horizPos + (this.tooltipWidth / 2)) >= rightEdge - edgePadding) {
      this.tooltip
        .style('left', `${rightEdge - (this.tooltipWidth)}px`)
        .style('top', `${vertPos - this.tooltipHeight - 20}px`);
    } else {
      this.tooltip
        .style('left', `${horizPos - (this.tooltipWidth / 2)}px`)
        .style('top', `${vertPos - this.tooltipHeight - 20}px`);
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
    this.windowWidth = window.innerWidth;
  }

  private setView(rect: Selection<any, any, any, any>) {
    const res = rect
      .attr('clip-path', (d: IStackedLimitBarChartFormattedDataPoint) => {
        const percent = d.value / this.totalPoints * 100;
        return `polygon(0 0, ${percent}% 0%, ${percent}% 100%, 0 100%)`;
      })
      .attr('y', this.y(3))
      .attr('x', 0);

    if (this.getTooltipMessageFn) {
      res
        .on('mouseover', (e: MouseEvent, d: IStackedLimitBarChartFormattedDataPoint) => {
          this.setTooltipTextValue(d);

          // send to bottom of the stack, so we can get dimensions
          setTimeout(() => this.setTooltipBoxDimensions(), 0);
          this.svgGroup.selectAll('text.' + d.colorClass + '-text').classed('visible', true);
          this.tooltip
            .transition()
            .duration(150)
            .style('opacity', 1);
        })
        .on('mousemove', (e: MouseEvent) => this.handleTooltipPosition(e))
        .on('mouseout', (e: MouseEvent, d: IStackedLimitBarChartFormattedDataPoint) => {
          this.svgGroup.selectAll('text.' + d.colorClass + '-text').classed('visible', false);
          this.tooltip
            .transition()
            .delay(25)
            .style('opacity', 0);
        });
    }

    return res;
  }

  private static generatePathD(x, y, width, height, radius) {
    const progressWidth = width;
    let d = `M${x + radius},${y} `;
    d += `h${progressWidth - radius} `;
    d += `a${radius},${radius} 0 0 1 ${radius},${radius} `;
    d += `v${height - 2 * radius} `;
    d += `a${radius},${radius} 0 0 1 -${radius},${radius} `;
    d += `h${-width + 2 * radius} `;
    d += `a${radius},${radius} 0 0 1 -${radius},-${radius} `;
    d += `v${-height + 2 * radius} `;
    d += `a${radius},${radius} 0 0 1 ${radius},-${radius} `;

    d += `z`;
    return d;
  }
}
