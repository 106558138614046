<component-template componentName="Tabs">
  <component-library-section sectionName="Overview" selector="<op-tabs>">
    <p>This is a custom tab component that is used in various places throughout the app.</p>

    <div class="tabs-example">
      <op-tabs
        [tabs]="sampleTabs"
        [activeTab]="activeTab"
        (tabClicked)="onTabClicked($event)">
      </op-tabs>
      <div class="tab-1-content" *ngIf="activeTab === 'tab1'">
        <p>This is the content for tab 1</p>
      </div>
      <div class="tab-2-content" *ngIf="activeTab === 'tab2'">
        <p>This is the content for tab 2</p>
      </div>
      <div class="tab-3-content" *ngIf="activeTab === 'tab3'">
        <p>This is the content for tab 3</p>
      </div>
    </div>
  </component-library-section>
</component-template>