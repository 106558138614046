<usage-chart [trends]="trends"
             [currentTerm]="currentTerm"
             [isFiltered]="isFiltered"
             [svgBoxSelectorName]="'usage-web-journey-top-chart'"
             [displayedCharts]="topChartDisplayedCharts"
             [title]="'Web Journey Runs Per Month'"
             (periodExport)="exportPeriod($event)"
             [chartType]="EChartType.WEB_JOURNEY"
></usage-chart>

<usage-chart [trends]="trends"
             [currentTerm]="currentTerm"
             [isFiltered]="isFiltered"
             [svgBoxSelectorName]="'usage-web-journey-bottom-chart'"
             [displayedCharts]="bottomChartDisplayedCharts"
             [title]="'Web Journey Runs Per Term'"
             (periodExport)="exportPeriod($event)"
             [chartType]="EChartType.WEB_JOURNEY"
></usage-chart>
