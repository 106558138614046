import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'web-journey-report-info-section',
  templateUrl: './web-journey-report-info-section.component.html',
  styleUrls: ['./web-journey-report-info-section.component.scss']
})
export class WebJourneyReportInfoSectionComponent {

  @Input() sectionHeading: string;

}
