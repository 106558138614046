import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const ALERTS_LIBRARY_FILTERS_STORAGE_KEY = 'alertsLibrary_filters';

export const AlertsLibraryNames = {
  base: 'alerts_library'
};

export const AlertsLibraryPaths = {
  base: 'alerts-library'
};

export const AlertsLibraryUrlBuilders = {
  library: () => RouteBuilder.buildUrl([AlertsLibraryPaths.base])
};

export const OPEN_ALERT_DESIGNER = 'openAlertDesigner';