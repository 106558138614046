import { Component, Input, OnChanges, ElementRef, AfterViewInit, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ComponentChanges } from '@app/models/commons';
import { Subject, Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

const labelSize = 40;

/**
 * Wrapper around material progress bar to give OP branded styles
 */
@Component({
  selector: 'op-progress-bar',
  templateUrl: './op-progress-bar.component.html',
  styleUrls: ['./op-progress-bar.component.scss'],
})
export class OpProgressBarComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {

  @Input() value: number = 0;
  
  resizeSubject: Subject<void> = new Subject();
  private destroy$ = new Subject();
  
  onResize$: Observable<void> = this.resizeSubject.asObservable();
  displayPercentOnLeft: boolean = false;
  
  constructor(private elementRef: ElementRef) { }

  @HostListener('window:resize', [])
  onWindowResize() {
    this.resizeSubject.next();
  }

  ngOnInit() {
    this.onResize$.pipe(
      debounceTime(250),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.displayPercentOnLeft = this.calcLeftoverWidth() < labelSize;
    });
  }

  ngOnChanges(changes: ComponentChanges<OpProgressBarComponent>) {
    if (!changes.value)  return;

    if (typeof changes.value.currentValue !== 'number' || changes.value.currentValue < 0) {
      this.value = 0;
    } else if (changes.value.currentValue > 100) {
      this.value = 100;
    } else if (changes.value.currentValue != changes.value.previousValue){
      this.resizeSubject.next();
    }
  }

  ngAfterViewInit() {
    this.resizeSubject.next();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private calcLeftoverWidth(): number {
    const scrollWidth = this.elementRef.nativeElement.scrollWidth;
    const clientWidth = this.elementRef.nativeElement.clientWidth;

    if (!scrollWidth || !clientWidth) {
      return 0;
    }

    const percentageWidth = scrollWidth - clientWidth; // Width the current progress takes up
    return clientWidth - percentageWidth; // Width leftover
  }
}
