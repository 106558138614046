<div class="inboxes-zero-state" *ngIf="!loading && showZeroState">
  <img src="/images/email-inboxes-icon.png" alt="Email Inboxes Icon" class="email-inboxes-icon" />
  <h2 class="main-heading">
    Validate critical pages from marketing emails with all of our popular use cases like: CMP validation, cookie compliance, digital analytics, campaign tracking, and user experience.
  </h2>
  <h3 class="sub-heading">
    Send test marketing emails to your custom OP Inbox and we will auto-run all assigned audit test scenarios to make sure there aren't any issues.
    <a href="https://help.observepoint.com/en/articles/9369053-validate-critical-links-from-marketing-emails" target="_blank">Learn more.</a>
  </h3>

  <div class="stat">
    <mat-icon class="material-icons-outlined stat-icon">wb_incandescent</mat-icon>
    <span class="stat-message">Every day, <b>319.6 billion emails</b> are sent and received globally, with <b>99%</b> of email users checking their inboxes daily.</span>
  </div>

  <button *ngIf="!isReadOnly" mat-flat-button color="primary" (click)="onCreate.emit()" class="create-now-btn">Create Inbox Now</button>
</div>