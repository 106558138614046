import { ECookieOpChangeType } from '@app/components/audit-reports/page-details/page-details.enums';
import { ECookieOrigin } from '@app/components/audit-reports/page-details/page-details.models';

export enum EPageDetailsTabs {
  PageInformation = 'page-information',
  Tags = 'tags',
  Cookies = 'cookies',
  RequestLog = 'request-log',
  ConsoleLog = 'console-log',
  Accessibility = 'accessibility',
  TagInitators = 'tag-initiators',
  Rules = 'rules'
}

export enum EPageDetailsOPSelectors {
  // Page details section
  PageInfo = 'pagedetails-tab-pageinfo',
  Tags = 'pagedetails-tab-tags',
  Cookies = 'pagedetails-tab-cookies',
  RequestLog = 'pagedetails-tab-requestlog',
  ConsoleLog = 'pagedetails-tab-consolelog',
  TagInitiators = 'pagedetails-tab-taginitiators',
  Rules = 'pagedetails-tab-rules',
  Accessibility = 'pagedetails-tab-accessibility',
}

export const party = {
  'owned_3rd_party': '"owned" 3rd',
  '3rd_party': '3rd',
  '1st_party': '1st'
};

export enum ECookieSameSiteResponseTypes {
  STRICT = 'STRICT',
  EMPTY = 'EMPTY',
  LAX = 'LAX',
  NONE = 'NONE'
}

export enum ECookieSameSiteDisplayTypes {
  STRICT = 'Strict',
  EMPTY = 'Empty',
  LAX = 'Lax',
  NONE = 'None'
}

export const CookieChangeActionStringMap = {
  [ECookieOpChangeType.Add]: 'Added',
  [ECookieOpChangeType.Delete]: 'Deleted',
  [ECookieOpChangeType.Update]: 'Changed',
  [ECookieOpChangeType.NoChange]: 'No Change'
};

export const AuditCookieOriginMap = {
  [ECookieOrigin.PreAuditAction]: 'on Pre-Audit Action',
  [ECookieOrigin.OnPageAction]: 'by On-Page Action',
  [ECookieOrigin.PageLoad]: 'on Page Load',
};

export enum EPageDetailsPlacement {
  InDrawer = 'inDrawer',
  DeepLink = 'deepLink',
  FullScreen = 'fullScreen'
}

export enum ECookieInitiatorType {
  HTTP,
  APP,
  OP_ACTION,
  UNKNOWN
}

export enum ECookieInitiatorTypeSetMethod {
  HTTP = 'Server-Side HTTP',
  APP = 'Client-Side Application',
  OP_ACTION = 'Audit Action',
  UNKNOWN = 'Unknown'
}

export const CookieOriginType = {
  [ECookieOrigin.PreAuditAction]: 'pre-audit',
  [ECookieOrigin.OnPageAction]: 'on-page',
  [ECookieOrigin.PageLoad]: 'page-load'
}
