import { IDomain } from '../../components/domains/domainsService';
import { checkForKey } from '../selectors/selectorHelpers';
import { IReduxAction } from './reducers';
import { ActionTypes } from '../constants/actionTypes';
import * as Immutable from 'immutable';

function getDomainIndex(domainsState, domainId: number) {
  return domainsState.get('all').findIndex(domainMap => {
    return domainMap.get('id') == domainId;
  });
}

function setDomains(domainsState, domains: Array<IDomain>) {
  return domainsState.set('all', Immutable.fromJS(domains));
}

function updateDomainField(domainsState, domainId: number, field: string, updateFn) {
  return checkForKey(domainsState, 'all', () => {
    return domainsState.updateIn(['all', getDomainIndex(domainsState, domainId), field], updateFn);
  });
}

function updateAuditCount(domainsState, domainId: number, updateFn) {
  return updateDomainField(domainsState, domainId, 'automatedJourneys', updateFn);
}

function updateJourneyCount(domainsState, domainId: number, updateFn) {
  return updateDomainField(domainsState, domainId, 'guidedJourneys', updateFn);
}

function addDomain(domainsState, domain: IDomain) {
  return checkForKey(domainsState, 'all', () => {
    return domainsState.update('all', allDomains => allDomains.push(Immutable.fromJS(domain)));
  });
}

function updateDomain(domainsState, domain: IDomain) {
  return checkForKey(domainsState, 'all', () => {
    return domainsState.updateIn(['all', getDomainIndex(domainsState, domain.id)], domainMap => domainMap.merge(domain));
  });
}

function deleteDomain(domainsState, domainId: number) {
  return checkForKey(domainsState, 'all', () => {
    return domainsState.update('all', allDomains => allDomains.filter(domainMap => domainMap.get('id') != domainId));
  });
}

export function domainReducer(domainsState = Immutable.Map(), action: IReduxAction<any>) {
  switch (action.type) {
    case ActionTypes.SET_DOMAINS:
      return setDomains(domainsState, action.payload);
    case ActionTypes.DEL_AUDIT:
      return updateAuditCount(domainsState, action.payload.domainId, count => count - 1);
    case ActionTypes.ADD_AUDIT:
      return updateAuditCount(domainsState, action.payload.domainId, count => count + 1);
    case ActionTypes.ADD_DOMAIN:
      return addDomain(domainsState, action.payload);
    case ActionTypes.UPDATE_DOMAIN:
      return updateDomain(domainsState, action.payload);
    case ActionTypes.DEL_DOMAIN:
      return deleteDomain(domainsState, action.payload.domainId);
  }

  return domainsState;

}
