import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
  auditDataServiceProvider,
  domainsServiceProvider,
  eventManagerServiceProvider,
  foldersServiceProvider,
  manageCardsServiceProvider,
  modalServiceProvider,
  reprocessRulesServiceProvider,
  sortingServiceProvider,
  spinnerServiceProvider,
  stopRunProvider,
  webJourneyAPIServiceProvider,
  apiServiceProvider,
  tagsServiceProvider,
  discoveryAuditServiceProvider,
  calculateWordWidthServiceProvider,
  rootScopeProvider,
  qProvider,
  ngReduxProvider,
  folderActionsServiceProvider,
  locationProvider,
  manualJourneyServiceProvider,
  auditActionsServiceProvider,
  domainActionsServiceProvider,
  locationServiceProvider,
  userAgentServiceProvider,
  numberFormatterProvider,
  ngToastProvider,
  toastServiceProvider,
  manageTagsServiceProvider,
  statusBannerServiceProvider,
  statusBannerProcessorProvider,
  modalEscapeServiceProvider,
  domainSelectorServiceProvider,
  folderSelectorServiceProvider,
  utilitiesServiceProvider,
  apiKeyServiceProvider,
  userEventServiceProvider,
  webJourneyRunsSeriazlierProvider,
} from '@app/ajs-upgraded.providers';
import { WindowRef } from '@app/components/core/services/window.service';
import { ValidateMessages } from '@app/components/form/validate-messages.service';
import { ApiService } from '@app/components/core/services/api.service';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AuthenticationStorageService } from './services/authentication-storage.service';
import { FolderService } from '../folder/folder.service';
import { DomainService } from '../domain/domain.service';
import { AccountsService } from '@app/components/account/account.service';
import { IAuditReportPageDetailsDrawerService } from '../audit-reports/audit-report/audit-report-page-details-drawer.models';
import { AuditReportPageDetailsDrawerService } from '../audit-reports/audit-report/audit-report-page-details-drawer.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SplitterStatusDataService } from '@app/components/manage/cards/report-card-list/services/splitter-status-data/splitter-status-data.service';
import { SvgIconsForMatIconRegistry } from '@app/components/core/constants/icons';

/**
 * This module contains code that will be used across all app.
 * Should be included just once in AppModule. This will ensure that items inside it will be created once in the entire app.
 *
 * The following items should be declared inside this module:
 * 1. Global services
 * 2. Single use components (for example, HeaderComponent)
 * 3. Any third party module that is required in the AppModule
 */
@NgModule({
  providers: [
    ApiService,
    AuthenticationStorageService,
    modalServiceProvider,
    eventManagerServiceProvider,
    manageCardsServiceProvider,
    foldersServiceProvider,
    domainsServiceProvider,
    auditDataServiceProvider,
    reprocessRulesServiceProvider,
    webJourneyAPIServiceProvider,
    sortingServiceProvider,
    WindowRef,
    stopRunProvider,
    apiServiceProvider,
    spinnerServiceProvider,
    tagsServiceProvider,
    discoveryAuditServiceProvider,
    ValidateMessages,
    calculateWordWidthServiceProvider,
    rootScopeProvider,
    qProvider,
    ngReduxProvider,
    folderActionsServiceProvider,
    AuthenticationService,
    AccountsService,
    domainActionsServiceProvider,
    auditActionsServiceProvider,
    locationProvider,
    manualJourneyServiceProvider,
    locationServiceProvider,
    userAgentServiceProvider,
    FolderService,
    DomainService,
    numberFormatterProvider,
    ngToastProvider,
    toastServiceProvider,
    manageTagsServiceProvider,
    statusBannerServiceProvider,
    statusBannerProcessorProvider,
    modalEscapeServiceProvider,
    folderSelectorServiceProvider,
    domainSelectorServiceProvider,
    utilitiesServiceProvider,
    apiKeyServiceProvider,
    userEventServiceProvider,
    webJourneyRunsSeriazlierProvider,
    SplitterStatusDataService,
    { provide: IAuditReportPageDetailsDrawerService, useClass: AuditReportPageDetailsDrawerService }
  ]
})
export class CoreModule {

  // Inject CoreModule into himself.
  // If it's already injected - error because CoreModule should be injected JUST once.
  constructor(@Optional() @SkipSelf() core: CoreModule,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    if (core) throw new Error('CoreModule has been already injected');
    this.registerSvgIcons();
  }

  private registerSvgIcons() {
    SvgIconsForMatIconRegistry.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`images/custom-mat-icons/${icon}.svg`)
      );
    });
  }
}
