import { Component } from '@angular/core';

@Component({
  selector: 'op-email-inbox-help-guide',
  templateUrl: './email-inbox-help-guide.component.html',
  styleUrls: ['./email-inbox-help-guide.component.scss']
})
export class EmailInboxHelpGuideComponent {
  isExpanded = false;

  toggle(): void {
    this.isExpanded = !this.isExpanded;
  }
}
