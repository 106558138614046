<div class="panel-row" [ngClass]="{'show-comparison': showComparison, 'is-admin': isCurrentUserAdmin}">
  <span class="data-col name-header" (click)="onSortChange('name')">
    <div>Variables</div>
    <ng-container *ngTemplateOutlet="sortBtnTemplate; context: { $implicit: sortVarsConfig, column: 'name' }">
    </ng-container>
  </span>
  <div class="data-col" *ngIf="showComparison" (click)="onSortChange('previousValue')">
    <div class="flex-col">
      <div class="run-number">Run 1:</div>
      <div class="run-date">
        <span overflowFormatter>{{ previousRun?.completedAt | date : dateTimeFormat }}</span>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="sortBtnTemplate; context: { $implicit: sortVarsConfig, column: 'previousValue' }">
    </ng-container>
  </div>
  <div class="data-col" (click)="onSortChange('currentValue')">
    <div class="flex-col">
      <div class="run-number">{{showComparison ? 'Run 2:' : 'Run:'}}</div>
      <div class=run-date>
        <span overflowFormatter>{{ currentRun?.completedAt | date : dateTimeFormat}}</span>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="sortBtnTemplate; context: { $implicit: sortVarsConfig, column: 'currentValue' }">
    </ng-container>
  </div>
  <div class="data-col" *ngIf="showComparison" (click)="onSortChange('outcome')">
    <div class="flex-col">
      <span class="no-line-break">Tag, Variables, &</span>
      <span class="no-line-break">Value Results</span>
    </div>
    <ng-container *ngTemplateOutlet="sortBtnTemplate; context: { $implicit: sortVarsConfig, column: 'outcome' }">
    </ng-container>
  </div>
  <span class="stub-col" *ngIf="showComparison && isCurrentUserAdmin"></span>
</div>

<ng-template #sortBtnTemplate let-config let-column="column">
  <span class="sort-btn" [class.visible]="config?.propName === column">
    <mat-icon *ngIf="config.direction === sortDirection.asc">
      arrow_upward
    </mat-icon>
    <mat-icon *ngIf="config.direction === sortDirection.desc">
      arrow_downward
    </mat-icon>
  </span>
</ng-template>
