import { ComparisonLibraryComponent } from './comparison-library.component';
import { Route } from '@angular/router';
import { ComparisonLibraryContainerComponent } from './comparison-library-container/comparison-library-container.component';
import { ComparisonLibraryPaths, ComparisonStateNames } from './comparison-library.constants';
import { StandardOnlyGuard } from '../core/permissions.guard';

export const ComparisonLibraryFutureState: Route = {
  path: ComparisonLibraryPaths.base,
  canLoad: [StandardOnlyGuard],
  loadChildren: () => import('./comparison-library.module').then(mod => mod.ComparisonLibraryModule),
};

export const ComparisonRoutes: Route = {
  path: '',
  component: ComparisonLibraryContainerComponent,
  data: {
    stateName: ComparisonStateNames.comparison,
  },
  children: [{
    path: '',
    pathMatch: 'full',
    redirectTo: 'library',
  }, {
    path: ComparisonLibraryPaths.library,
    component: ComparisonLibraryComponent,
    data: {
      stateName: ComparisonStateNames.comparisonLibrary,
      title: 'Comparison Library',
      betaLabel: true
    }
  }]
};
