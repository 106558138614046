import { IAuditModel } from '../../components/modals/modalData';
import { checkForKey } from '../selectors/selectorHelpers';
import { IReduxAction } from './reducers';
import { ActionTypes } from '../constants/actionTypes';
import * as Immutable from 'immutable';

function setAudits(auditsState, audits: Array<IAuditModel>) {
  return auditsState.set('all', Immutable.fromJS(audits));
}

function getAuditIndex(auditsState, auditId) {
  return auditsState.get('all').findIndex(listItem => {
    return listItem.get('id') == auditId;
  });
}

function setAuditLabels(auditsState, auditId, labels) {
  return checkForKey(auditsState, 'all', () => {
    return auditsState.setIn(['all', getAuditIndex(auditsState, auditId), 'labels'], Immutable.fromJS(labels));
  });
}

function deleteAuditLabel(auditsState, auditId, labelId) {
  return checkForKey(auditsState, 'all', () => {
    const auditIndex = getAuditIndex(auditsState, auditId);
    const labels = auditsState.getIn(['all', auditIndex, 'labels']);

    return auditsState.setIn(['all', auditIndex, 'labels'], labels.filter(labelMap => labelMap.get('id') != labelId));
  });
}

function updateAudit(auditsState, audit: IAuditModel) {
  return checkForKey(auditsState, 'all', () => {
    return auditsState.setIn(['all', getAuditIndex(auditsState, audit.id)], Immutable.fromJS(audit));
  });
}

function addAudit(auditsState, audit: IAuditModel) {
  return checkForKey(auditsState, 'all', () => {
    return auditsState.update('all', allAudits => allAudits.push(Immutable.fromJS(audit)));
  });
}

function deleteAudit(auditsState, auditId: number) {
  return checkForKey(auditsState, 'all', () => {
    return auditsState.update('all', allAudits => allAudits.filter(auditMap => auditMap.get('id') != auditId));
  });
}

function setSelectedAudit(auditsState, auditId: number) {
  return auditsState.set('selectedId', auditId);
}

export function auditReducer(auditsState = Immutable.Map(), action: IReduxAction<any>) {
  switch (action.type) {
    case ActionTypes.SET_AUDITS:
      return setAudits(auditsState, action.payload);
    case ActionTypes.SET_AUDIT_LABELS:
      return setAuditLabels(auditsState, action.payload.auditId, action.payload.labels);
    case ActionTypes.DEL_AUDIT_LABEL:
      return deleteAuditLabel(auditsState, action.payload.auditId, action.payload.labelId);
    case ActionTypes.UPDATE_AUDIT:
      return updateAudit(auditsState, action.payload);
    case ActionTypes.ADD_AUDIT:
      return addAudit(auditsState, action.payload);
    case ActionTypes.DEL_AUDIT:
      return deleteAudit(auditsState, action.payload.auditId);
    case ActionTypes.SET_SELECTED_AUDIT:
      return setSelectedAudit(auditsState, action.payload);
  }

  return auditsState;
}
