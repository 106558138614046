<div class="inside-table-wrap">
  <div *ngIf="loading" class="loading-spinner">
    <mat-spinner [diameter]="40" [strokeWidth]="3"></mat-spinner>
  </div>
  <div *ngIf="reloading" class="reloading-spinner">
    <mat-spinner [diameter]="40" [strokeWidth]="3"></mat-spinner>
  </div>

  <table class="inside-table"
         [class.hidden]="!dataSource.data.length"
         [class.dimmed]="reloading"
         [dataSource]="dataSource"
          mat-table
          matSort
          [matSortActive]="sortOptions.sortBy"
          [matSortDirection]="sortOptions.sortDir">
    <ng-container [matColumnDef]="ETriggeredAlertDataSourcesTableColumns.RunDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Run Date</th>
      <td mat-cell *matCellDef="let dataSource">
        <span>{{ dataSource.runDate | date: 'MM/dd/yyyy' }}</span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="ETriggeredAlertDataSourcesTableColumns.DataSourceName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Data Source ({{ dataSource.data.length }})
      </th>
      <td mat-cell *matCellDef="let dataSource">
        <span class="name">
          {{ dataSource.itemName }} ({{ dataSource.itemType === EAlertAssignmentItemType.audit ? 'Audit' : 'Web Journey' }})
        </span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="ETriggeredAlertDataSourcesTableColumns.Value">
      <th mat-header-cell *matHeaderCellDef>
        <span>Value</span>
      </th>
      <td mat-cell *matCellDef="let dataSource"
                  [class.triggered]="dataSource.status === EAlertResultStatus.Triggered">
        <div class="status-wrap">
          <mat-icon class="triggered-icon status-icon">warning_amber</mat-icon>
          <ng-container *ngIf="
            alert.trigger.operator.changeType === EAlertMetricChangeType.ValueChange &&
            [EAlertMetricValueComparator.GreaterOrEqual, EAlertMetricValueComparator.LessOrEqual].includes(alert.trigger.operator.comparator)
          ">
            <mat-icon *ngIf="alert.trigger.targetValue > 0" class="change-by-icon">arrow_upward</mat-icon>
            <mat-icon *ngIf="alert.trigger.targetValue < 0" class="change-by-icon">arrow_downward</mat-icon>
          </ng-container>
          <span class="value">{{ dataSource.value }}</span>
          <mat-icon class="redirect-arrow">arrow_forward</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="ETriggeredAlertDataSourcesTableColumns.AlertHistory">
      <th mat-header-cell *matHeaderCellDef>Alert History</th>
      <td mat-cell *matCellDef="let dataSource">
        <mat-icon class="alert-history-icon" (click)="openHistoricalReview($event, dataSource)">
          analytics
        </mat-icon>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let dataSource; columns: displayedColumns" (click)="openAuditReport(dataSource)"></tr>
  </table>
</div>
