import { IComparisonVariableSelectorForm } from './shared/comparison-variable-selector/comparison-variable-selector.models';
import { IDatasourceTag } from '@app/components/comparison-library/comparison-library.model';
import { EComparisonType, EDatasourceTagType, EDatasourcesUseType } from './comparisons.enums';
import { ITagVariableAlignment } from '../comparison-library/comparison-library.model';
import { IOneTimeComparisonDetails } from './one-time-comparison/one-time-comparison.models';
import { datasourceAllTagsOnly } from './shared/comparison-variable-selector/comparison-variable-selector.constants';
import { IRorSetupForm } from './ror-comparison/ror-setup-form/ror-setup-form.models';
import { IComparisonSetupModel } from './one-time-comparison/one-time-comparison-setup/one-time-component-setup.models';
import { IRorComparisonDetails } from './ror-comparison/ror-comparison.models';

export interface IComparisonCreate {
  type: EComparisonType;
  name: string;
  alignments: ITagVariableAlignment[];
  labels: number[];
  details: IRorComparisonDetails | IOneTimeComparisonDetails;
}

export interface IComparison extends IComparisonCreate {
  id: number;
  createdAt: string;
  createdBy: number;
  updatedAt?: string;
  updatedBy?: number;
}

export class IComparison {
  static toRORComparisonSetupForm(comparison: IComparison,
                                  accountTagSettingsMap: Map<number, IAccountTagSettings>): IRorSetupForm {
    const details = (comparison.details as IRorComparisonDetails);
    const tags = details.datasources.map(
      datasource => IComparison.toComparisonVariableSelectorForm(datasource, accountTagSettingsMap)
    );

    return {
      name: comparison.name,
      labels: comparison.labels,
      tags: details.datasourcesUseType === EDatasourcesUseType.allEnabledTagsPresence ?
        [{...IComparison.tagPresenceOnly}, ...tags] :
        tags
    };
  }

  static toOneTimeComparisonSetupModel(comparison: IComparison,
                                       accountTagSettingsMap: Map<number, IAccountTagSettings>): IComparisonSetupModel {
    const details = comparison.details as IOneTimeComparisonDetails;
    const datasource = details.datasource;

    const baselineAudit = {
      id: datasource.sourceRun?.itemId,
      run: datasource.sourceRun?.runId
    };
    const destinationAudit = {
      id: details.assignment?.itemId,
      run: details.assignment?.runId
    };

    const tags = datasource.tags.map(
      datasource => IComparison.toComparisonVariableSelectorForm(datasource, accountTagSettingsMap)
    );

    return {
      name: comparison.name,
      labels: comparison.labels,
      baselineAudit,
      destinationAudit,
      tags: details.datasourcesUseType === EDatasourcesUseType.allEnabledTagsPresence ?
        [{...IComparison.tagPresenceOnly}, ...tags] :
        tags,
      ignoreUrlDomain: details.advancedOptions.ignoreUrlDomain,
      ignoreQueryParameters: details.advancedOptions.ignoreUrlQuery
    };
  }

  private static tagPresenceOnly = {
    tagId: datasourceAllTagsOnly.key,
    type: null,
    selectedVariables: []
  };

  private static toComparisonVariableSelectorForm(datasource: IDatasourceTag,
                                                  accountTagSettingsMap: Map<number, IAccountTagSettings>): IComparisonVariableSelectorForm {
    if (datasource.type === EDatasourceTagType.AllVariablesExceptExcluded)
    datasource.selectedVariables = [
      ...accountTagSettingsMap.get(datasource.tagId).excludedVariables
                              .filter(v => !datasource.removedExclusions.includes(v)),
      ...datasource.addedExclusions
    ];
    if (!datasource.selectedVariables) datasource.selectedVariables = [];
    return datasource;
  }
}

export interface IAccountTagSettings {
  tagId: number;
  comparisonEnabled: boolean;
  excludedVariables: string[];
  variableAlignment: string[];
}

export interface IComparisonTag {
  id: number;
  name: string;
  icon?: string;
  variables: string[];
  excludedVariables: string[];
  accountExcludedVariables: string[];
  variableAlignment: string[];
  comparisonEnabled: boolean;
}
