<div class="title">{{ heading }}</div>
<div class="flex-col actions-preview" [dragula]="ACTIONS_GROUP_NAME" [(dragulaModel)]="actions">
  <action-preview *ngFor="let action of actions; index as i;"
                  [ngClass]="{'is-active': i === activeActionIndex, 'draggable': isDraggable(action), 'nav-to': action.type === eActionType.NavTo, 'cmp-action': action.type === eActionType.CmpOptInOptOut}"
                  [actions]="actions"
                  [action]="action"
                  [mode]="mode"
                  [index]="i+1"
                  [draggedMode]="draggedMode"
                  [actionSetCreationMode]="actionSetCreationMode"
                  (onSelect)="selectAction(action, i)"
                  (onDelete)="deleteAction(action, i)"
                  (onInsert)="insertAction(action, i)"
                  (onActionSelected)="addActionToSet($event, action, i)">
  </action-preview>
</div>

<div class="actions-preview-buttons">
  <button mat-flat-button
          class="add-action action-preview-button"
          color="primary"
          (click)="onAddAction()"
          *ngIf="!actionSetCreationMode">
    {{ buttonText }}
  </button>
  <button mat-flat-button
          class="create-action-set action-preview-button"
          (click)="createActionSet()"
          [disabled]="createActionSetDisabled"
          *ngIf="mode !== actionCreatorMode.ActionSet && !actionSetCreationMode">
    Create Action Set
  </button>
  <button mat-flat-button
          class="cancel-create-action-set action-preview-button"
          (click)="cancelCreateActionSet()"
          *ngIf="actionSetCreationMode">
    Cancel
  </button>
  <button mat-flat-button
          class="save-new-action-set action-preview-button"
          (click)="saveActionSet()"
          *ngIf="actionSetCreationMode"
          [disabled]="saveNewActionSetDisabled">
    Save As New Action Set
  </button>
</div>
