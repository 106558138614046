import { format } from 'date-fns';
import { forkJoin, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { IAuditModel } from '@app/components/modals/modalData';
import { IButton } from '@app/models/commons';
import {
  IAuditRunSummary
} from '@app/components/domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { AlertLogicFilterBarService } from './alert-logic/alert-logic-filter-bar.service';
import {
  EAlertFilterType,
  EAlertMenuContext,
  EAlertModalType,
  EAlertNotificationPolicy,
  EAlertStep
} from './alert.enums';
import {
  AlertFormData,
  IAlertCreateForAuditModalPayload,
  IAlertCreateUndefinedModalPayload, IAlertCreateWithFiltersModalPayload,
  IAlertDuplicateModalPayload,
  IAlertEditModalPayload,
  IAlertFormData,
  IAlertModalPayload,
  IAlertPartialModalPayload,
  IAlertReadOnlyModalPayload,
  IAlertRequestItem,
  IAlertResultsDTO,
  ISearchAlertAssignmentsRequest,
  ISearchAlertAssignmentsResponse
} from './alert.models';
import { AlertService } from './alert.service';
import { AlertPreviewService } from './alert-preview/alert-preview.service';
import {
  AlertMetricChangeOperators,
  AlertMetricThresholdOperators
} from '@app/components/alert/alert-logic/alert-logic.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AlertChangeSuccessSnackbarComponent,
  EAlertSuccessType
} from '@app/components/alert/alert-change-success-snackbar/alert-change-success-snackbar.component';
import {
  EAlertMetricChangeType,
  EAlertMetricValueComparator,
  EAlertUsageMetricV2
} from '@app/components/alert/alert-logic/alert-logic.enums';
import {
  AlertDataSourcesFilterBarService
} from './alert-data-sources/alert-data-sources-filter-bar/alert-data-sources-filter-bar.service';
import {
  AlertChangeSuccessSnackbarService
} from './alert-change-success-snackbar/alert-change-success-snackbar.service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { UsageAlertFilterBarService } from '@app/components/alert/alert-logic/usage-alert-filter-bar.service';
import { UsageFilterBarService } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.service';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  providers: [
    AlertService,
    AlertLogicFilterBarService,
    UsageFilterBarService,
    UsageAlertFilterBarService,
    AlertDataSourcesFilterBarService,
    AlertPreviewService
  ]
})
export class AlertComponent implements OnInit, OnDestroy, AfterViewInit {
  EAlertType = EAlertModalType;

  private backButton: IButton = {
    label: 'Back',
    icon: 'icon-back-empty',
    action: () => this.previousStep(),
    hidden: true
  };
  private nextButton: IButton = {
    label: 'Next',
    icon: 'icon-forward-empty modal-footer-button-icon',
    action: () => this.nextStep(),
    primary: true,
    hidden: false
  };
  private saveButton: IButton = {
    label: 'Save',
    action: () => this.save(),
    primary: true,
    hidden: false
  };
  readonly rightFooterButtons = [this.backButton, this.nextButton, this.saveButton];

  readonly EAlertStep = EAlertStep;

  alertForm: UntypedFormGroup;
  currentStep = EAlertStep.Logic;

  runs: IAuditRunSummary[];

  labels: ILabel[] = [];
  labelsMap = new Map<number, ILabel>();

  audit: IAuditModel;
  alerts: IAlertResultsDTO;

  modalType: EAlertModalType;
  menuContext: EAlertMenuContext;
  readOnlyLabel = 'You do not have editing rights for this alert';

  filterType: EAlertFilterType;
  noDataSourcesSupport: boolean = false;
  noHistorySupport: boolean = false;
  noReprocessOption: boolean = false;

  private destroySubject = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<AlertComponent>,
              @Inject(MAT_DIALOG_DATA) public payload: IAlertModalPayload,
              private formBuilder: UntypedFormBuilder,
              private labelsApiService: LabelService,
              private cdr: ChangeDetectorRef,
              private snackBar: MatSnackBar,
              private alertService: AlertService,
              private alertChangeService: AlertChangeSuccessSnackbarService) {

    this.modalType = payload.type;
    this.filterType = payload.filterType;
    this.menuContext = payload.menuContext;
    this.noReprocessOption = payload.noReprocessOption;
  }

  isForAuditMode(payload: IAlertModalPayload): payload is IAlertCreateForAuditModalPayload {
    return payload.type === EAlertModalType.CreateForAudit;
  }

  isPrefilledMode(payload: IAlertModalPayload): payload is IAlertPartialModalPayload {
    return payload.type === EAlertModalType.CreatePrefilled;
  }

  isReadOnlyMode(payload: IAlertModalPayload): payload is IAlertReadOnlyModalPayload {
    return payload.type === EAlertModalType.ReadOnly;
  }

  isFromScratchMode(payload: IAlertModalPayload): payload is IAlertCreateUndefinedModalPayload {
    return payload.type === EAlertModalType.CreateFromLibrary;
  }

  isEditMode(payload: IAlertModalPayload): payload is IAlertEditModalPayload {
    return payload.type === EAlertModalType.Edit;
  }

  isDuplicatingMode(payload: IAlertModalPayload): payload is IAlertDuplicateModalPayload {
    return payload.type === EAlertModalType.Duplicate;
  }

  isFiltersOnlyMode(payload: IAlertModalPayload): payload is IAlertCreateWithFiltersModalPayload {
    return payload.type === EAlertModalType.CreateWithFilters;
  }

  ngOnInit() {
    this.initForm();
    this.initListeners();
    this.fetchData();
    this.updateButtons();
  }

  ngAfterViewInit() {
    if (this.isPrefilledMode(this.payload)) {
      this.alertForm.patchValue({
        name: this.payload.name,
        logic: {
          trigger: {
            metricType: this.payload.metricType,
            operator: this.payload.operator,
            targetValue: this.payload.currentValue
          },
          filters: this.payload.filters
        },
        notification: {
          emails: this.payload.emails
        }
      }, {emitEvent: true});

      this.cdr.detectChanges();
    }

    if (this.isFiltersOnlyMode(this.payload)) {
      this.logicFiltersFormGroup.patchValue(this.payload.filters, {emitEvent: true});

      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private initForm() {
    this.alertForm = this.formBuilder.group({
      id: this.formBuilder.control(null),
      name: this.formBuilder.control(null),
      labels: this.formBuilder.array([]),
      logic: this.formBuilder.group({
        trigger: this.formBuilder.group({
          metricType: this.formBuilder.control(null, [Validators.required]),
          operator: this.formBuilder.control({ value: null, disabled: true }, [Validators.required]),
          targetValue: this.formBuilder.control({ value: null, disabled: true }, [Validators.required]),
        }),
        filters: this.formBuilder.control({ value: {}, disabled: true }),
      }),
      notification: this.formBuilder.group({
        emails: this.formBuilder.control([]),
        message: this.formBuilder.control(null, [Validators.maxLength(1500)]),
        policy: this.formBuilder.control(EAlertNotificationPolicy.AlwaysWhenTriggered, [Validators.required])
      }),
      dataSources: this.formBuilder.group({
        isDefaultAlert: this.formBuilder.control(false),
        items: this.formBuilder.control([]),
      })
    });
  }

  private initListeners() {
    this.logicTriggerMetricTypeControl
      .valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        const usageAlert = this.isUsageAlert();

        this.noDataSourcesSupport = usageAlert;
        this.noHistorySupport = usageAlert;
        this.filterType = this.isReportSupportsFilters() ? usageAlert ? EAlertFilterType.V2 : EAlertFilterType.V1 : EAlertFilterType.None;

        this.updateButtons();

        this.cdr.detectChanges();
      });
  }

  private fetchData() {
    const assignmentsRequest = this.getAssignmentsRequest();

    forkJoin([
      this.labelsApiService.getLabels(),
      assignmentsRequest ? this.alertService.getAllAssignedDataSources(assignmentsRequest) : of({ items: [] }),
      this.isEditMode(this.payload) || this.isReadOnlyMode(this.payload) || this.isDuplicatingMode(this.payload) ? this.alertService.getAlertById(this.payload.alertId) : of(null)
    ]).subscribe(([labels, assignments, alert]: [ILabel[], ISearchAlertAssignmentsResponse, IAlertRequestItem | null]) => {
      this.labels = labels;
      this.labelsMap = ArrayUtils.toMap(labels, 'id');

      if (this.isEditMode(this.payload)) {
        this.alertForm.patchValue({
          labels: alert.labels.map(id => this.formBuilder.control(this.labelsMap.get(id)))
        });
      }

      // this will automatically add the users email address
      // and assign this new alert to the current audit
      //
      // only applies when creating a new alert from audit report header or
      // from the standards tab when editing an audit
      if (this.isForAuditMode(this.payload)) {
        let formValues = {
          notification: {
            emails: this.payload.emails
          }
        };

        // only add assignment if auditId is present
        if (this.payload.auditId) {
          formValues['dataSources'] = {
            items: [this.payload.auditId]
          };
        }

        this.alertForm.patchValue(formValues);
      }

      const assignedDataSources = assignments.items
        .map(({ itemId, itemName, itemLastRunAt, itemLabels }) => ({
          id: itemId,
          name: itemName,
          labels: itemLabels.map(id => this.labelsMap.get(id)),
          lastRunAt: itemLastRunAt ? format(new Date(itemLastRunAt), 'yyyy/MM/dd, hh:mm aaa') : null,
        }));

      this.dataSourcesItemsControl.setValue(assignedDataSources);

      if (alert) {
        let operator = AlertMetricChangeOperators
          .find(operator => operator.comparator === alert.targetValueComparator.operator && operator.changeType === alert.metricChangeType);

        if (!operator) operator = AlertMetricThresholdOperators
          .find(operator => operator.comparator === alert.targetValueComparator.operator);

        const targetValue = AlertUtils.convertToUIValue(alert.metricType, alert.metricChangeType, alert.targetValueComparator.targetValue);
        // True if operator = Decreased by value or percentage
        const isInvertedValue = operator.comparator === EAlertMetricValueComparator.LessOrEqual
          && [EAlertMetricChangeType.ValueChange, EAlertMetricChangeType.RelativeValueChange].includes(alert.metricChangeType);

        this.alertForm.patchValue({
          id: this.isDuplicatingMode(this.payload) ? null : alert.id,
          name: this.isDuplicatingMode(this.payload) ? alert.name + ' (copy)' : alert.name,
          logic: {
            trigger: {
              metricType: alert.metricType,
              operator,
              targetValue: isInvertedValue ? -targetValue : targetValue
            },
            filters: alert.filtersV0
          },
          notification: {
            emails: alert.emails,
            message: alert.customAlertMessage,
            policy: alert.notificationPolicy
          },
          dataSources: {
            isDefaultAlert: alert.isDefaultForNewDataSources,
          }
         } as IAlertFormData, {emitEvent: true});

        alert.labels.forEach(labelId => (this.alertForm.get('labels') as UntypedFormArray).push(this.formBuilder.control(this.labelsMap.get(labelId))));

        if (this.isEditMode(this.payload) || this.isReadOnlyMode(this.payload)) {
          if (this.payload.currentStep) {
            this.goToStep(this.payload.currentStep);
          }
        }

        this.cdr.detectChanges();
      }
    });
  }

  private getAssignmentsRequest(): ISearchAlertAssignmentsRequest {
    if (this.isEditMode(this.payload)) {
      return { alertId: this.payload.alertId, assignedToAlert: true };
    }

    if (this.isForAuditMode(this.payload) || this.isPrefilledMode(this.payload)) {
      return this.payload.auditId ? { itemId: this.payload.auditId, assignedToAlert: true } : null;
    }

    return null;
  }

  onCreateLabel(label: ILabel) {
    this.labels = [...this.labels, label];
  }

  nextStep() {
    this.goToStep(this.currentStep + 1);
  }

  previousStep() {
    this.goToStep(this.currentStep - 1);
  }

  goToStep(stepId: EAlertStep): void {
    // name's validity is not important when switching steps
    this.nameControl.clearValidators();
    this.nameControl.updateValueAndValidity();

    if (this.alertForm.invalid) {
      this.alertForm.markAllAsTouched();
      return;
    }

    this.currentStep = stepId;
    this.updateButtons();
  }

  private updateButtons(): void {
    const usageAlert = this.isUsageAlert();

    this.backButton.hidden = this.currentStep === EAlertStep.Logic;
    this.nextButton.hidden = (this.currentStep === EAlertStep.Preview) || (usageAlert && this.currentStep === EAlertStep.Notification);

      this.saveButton.hidden = this.isEditMode(this.payload)
      ? false
      : this.isReadOnlyMode(this.payload) ||
        (!usageAlert && this.currentStep !== EAlertStep.Preview) || (usageAlert && this.currentStep !== EAlertStep.Notification);
  }

  private isUsageAlert(): boolean {
    const usageMetricTypes: EAlertUsageMetricV2[] = [
      EAlertUsageMetricV2.AccountUsageCurTermAuditPageScannedCount,
      EAlertUsageMetricV2.AccountUsageMonthPaceAuditPageScannedCount,
      EAlertUsageMetricV2.AccountUsageCurTermWebJourneyRunsCount,
      EAlertUsageMetricV2.AccountUsageMonthPaceWebJourneyRunsCount,
      EAlertUsageMetricV2.UsersLoggedInLast30Days,
    ];
    const selectedMetricType = (this.logicFormGroup.controls.trigger as UntypedFormGroup).controls.metricType.value;

    return usageMetricTypes.includes(selectedMetricType);
  }

  private isReportSupportsFilters(): boolean {
    const usageMetricTypes: EAlertUsageMetricV2[] = [
      EAlertUsageMetricV2.UsersLoggedInLast30Days,
    ];
    const selectedMetricType = (this.logicFormGroup.controls.trigger as UntypedFormGroup).controls.metricType.value;

    return !usageMetricTypes.includes(selectedMetricType);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    // prepare data
    const saveDTO = AlertFormData.toAlertRequestItem(this.alertForm.getRawValue(), this.filterType);
    const snackbarData = {
      name: saveDTO.name,
      type: null,
      alert: this.payload,
      noReprocessOption: this.noReprocessOption || this.isUsageAlert(),
    };

    // name's validity is important when saving an alert
    this.nameControl.setValidators(Validators.required);
    this.nameControl.updateValueAndValidity();
    if (this.alertForm.invalid) {
      this.alertForm.markAllAsTouched();
      return;
    }

    // disable the ability to accidentally create an alert twice
    this.saveButton.disabled = true;

    if (this.isEditMode(this.payload)) {
      this.alertService.updateAlert(saveDTO).subscribe(v => {
        snackbarData.type = EAlertSuccessType.Edited;

        this.snackBar.openFromComponent(AlertChangeSuccessSnackbarComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: snackbarData,
          panelClass: 'snackbar-wide-width'
        });
        this.alertChangeService.closeSnackbarOnNextRouteChange(true);
        this.dialogRef.close(saveDTO);
      });
    } else {
      this.alertService.createAlert(saveDTO).subscribe(alert => {
        snackbarData.type = EAlertSuccessType.Created;

        saveDTO.id = alert.id;

        this.snackBar.openFromComponent(AlertChangeSuccessSnackbarComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: snackbarData,
          panelClass: 'snackbar-wide-width'
        });
        this.alertChangeService.closeSnackbarOnNextRouteChange(true);
        this.dialogRef.close(saveDTO);
      });
    }
  }

  private get nameControl(): UntypedFormControl {
    return this.alertForm.get('name') as UntypedFormControl;
  }

  get logicFormGroup(): UntypedFormGroup {
    return this.alertForm.get('logic') as UntypedFormGroup;
  }

  get logicTriggerFormGroup(): UntypedFormGroup {
    return this.logicFormGroup.get('trigger') as UntypedFormGroup;
  }

  get logicFiltersFormGroup(): UntypedFormGroup {
    return this.logicFormGroup.get('filters') as UntypedFormGroup;
  }

  get logicTriggerMetricTypeControl(): UntypedFormControl {
    return this.logicTriggerFormGroup.get('metricType') as UntypedFormControl;
  }

  get notificationFormGroup(): UntypedFormGroup {
    return this.alertForm.get('notification') as UntypedFormGroup;
  }

  get dataSourcesFormGroup(): UntypedFormGroup {
    return this.alertForm.get('dataSources') as UntypedFormGroup;
  }

  get dataSourcesItemsControl(): UntypedFormControl {
    return this.dataSourcesFormGroup.get('items') as UntypedFormControl;
  }
}
