import { environment } from '@app/environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { ApiService } from '@app/components/core/services/api.service';
import { delay, tap } from 'rxjs/operators';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';

export interface ILabel {
  id: number;
  name: string;
  rules?: number[];
  webJourney?: number[];
  audits?: number[];
}

const resetLabelCache: Subject<any> = new Subject<any>();

@Injectable({
  providedIn: 'root'
})
export class LabelService {
  root: string = environment.apiUrl;
  labelMap: Map<number, ILabel> = new Map();
  allLabelsSubject: BehaviorSubject<ILabel[]> = new BehaviorSubject<ILabel[]>([]);
  allLabels$: Observable<ILabel[]> = this.allLabelsSubject.asObservable();

  allLabels = [];

  constructor(
      private apiService: ApiService,
      private cacheResetService: CacheResetService,
  ) {
    resetLabelCache.pipe(delay(100)).subscribe(() => this.getLabels().subscribe());

    this.cacheResetService.reset$.subscribe(_ => {
      resetLabelCache.next();
    });
  }

  @CacheApiResponse({
    resetCache: resetLabelCache
  })
  getLabels(): Observable<ILabel[]> {
    return this.apiService.get(this.root + 'labels')
        .pipe(
            tap((labels: ILabel[]) => {
              this.labelMap.clear();
              this.allLabels = labels;
              labels.forEach(label => this.labelMap.set(label.id, label));
              this.allLabelsSubject.next(this.allLabels);
            })
        );
  }

  createLabel(name: string): Observable<ILabel> {
    resetLabelCache.next();
    return this.apiService.post(this.root + 'labels', { name: name }).pipe(
        tap((label: ILabel) => {
          this.labelMap.set(label.id, label);
          this.allLabels.push(label);
          this.allLabelsSubject.next(this.allLabels);
        })
    );
  }

  resetCache() {
    resetLabelCache.next();
  }

  updateLabel(id: number, name: string): Observable<ILabel> {
    resetLabelCache.next();
    return this.apiService.put(`${this.root}labels/${id}`, { name: name });
  }

  deleteLabel(id: number): Observable<any> {
    resetLabelCache.next();
    return this.apiService.delete(`${this.root}labels/${id}`);
  }

  /**
   * Returns an array of labels from an array of label ids, filtering out any label ids that no longer exist on the account.
   */
  private findLabelsByIds(ids: number[]): ILabel[] {
    return ids.map(id => this.labelMap.get(id)).filter(label => !!label);
  }

  getLabelsByIds(ids: number[]): Promise<ILabel[]> {
    if (this.allLabels.length > 0) {
      // Labels are already loaded, return the filtered array directly
      return Promise.resolve(this.findLabelsByIds(ids));
    } else {
      // Labels are not loaded yet, so we fetch them first
      return this.getLabels().toPromise().then(() => {
        // Once loaded, return the filtered labels
        return this.findLabelsByIds(ids);
      });
    }
  }
}
