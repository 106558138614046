<mat-expansion-panel (opened)="expand()" (closed)="collapse()" class="feedback-banner">
  <mat-expansion-panel-header>
    <mat-panel-description class="feedback-banner-description">
      <div>
        Only the ObservePoint product team has access to submitted ideas and feedback. It’s completely anonymous to all other users. Items are grouped into these categories: {{collapsed ? '...' : ''}}
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div><span class="feedback-main-point">Under Consideration:</span> Items that the product team has on the long-range roadmap or are considering adding to the roadmap.</div>
  <div><span class="feedback-main-point">Planned:</span> Items on the roadmap for the next 2 quarters.</div>
  <div><span class="feedback-main-point">Launched:</span> A list of what has recently released.</div>
  <div><span class="feedback-main-point">Submit Idea:</span> Submit an idea here in the portal, talk to your CSM, or set up time with the product team.</div>
</mat-expansion-panel>
<iframe src="https://portal.productboard.com/wddnevralmkberbppskjiktv?hide_logo=1" frameborder="0" height="100%" width="100%" class="mat-elevation-z8"></iframe>
