<div class="cookie-origin-story">
  <div class="initiator-row-section" *ngIf="dataSourceType === EReportType.WEB_JOURNEY">
    <div class="initiator-row">Total Initiators across all action(s):&nbsp;<span class="initiator-value">{{ cookie.originStory?.totalCookieInitiatorCount }}</span></div>
    <div class="initiator-row">Total Action(s) across this web journey:&nbsp;<span class="initiator-value">{{ cookie.originStory?.totalCookieActionCount }}</span></div>
  </div>
  <div class="initiator-row" *ngIf="dataSourceType === EReportType.AUDIT">Total Initiators:&nbsp;<span class="initiator-value">{{ cookie.originStory?.uniqueInitiatorsCount }}</span></div>
  <div class="initiator-row" *ngIf="dataSourceType === EReportType.AUDIT">Action(s):&nbsp;<span class="initiator-value">{{ cookie.originStory?.actionCount }}</span></div>
  <div class="origin-story-action" *ngFor="let action of cookie.originStory?.actions; let index = index">
    <div class="clickable-nav-link"
         *ngIf="dataSourceType === EReportType.WEB_JOURNEY && actionIndex !== action?.action?.sequence"
    >
      <a (click)="navToAction.emit({actionIndex: cookie.changes[index].action.sequence + 1, stepEventItem: cookie})"
         matTooltip="Jump to action {{action?.action?.sequence + 1}} to see this cookie's attributes"
         matTooltipPosition="above"
         matTooltipShowDelay="300"
      >
        {{ getOriginStoryText(action, index) }}<span *ngIf="action.cookieOriginType !== 'PAGE_LOAD'" [class.unnamed-action]="!action.action?.label">&nbsp;({{action.action?.label ? action.action?.label : 'unnamed'}})</span> <mat-icon>east</mat-icon>
      </a>
    </div>
    <div class="action-name" *ngIf="dataSourceType === EReportType.AUDIT || (dataSourceType === EReportType.WEB_JOURNEY && actionIndex === action?.action?.sequence)">
      {{ getOriginStoryText(action, index) }}<span *ngIf="action.cookieOriginType !== 'PAGE_LOAD'" [class.unnamed-action]="!action.action?.label">&nbsp;({{action.action?.label ? action.action?.label : 'unnamed'}})</span>
    </div>
    <div class="initiators" *ngIf="dataSourceType === EReportType.AUDIT || (dataSourceType === EReportType.WEB_JOURNEY && actionIndex === action?.action?.sequence)">
      <div class="initiator-section-header" *ngIf="dataSourceType === EReportType.WEB_JOURNEY">
       Total initiator(s) in this action: {{action.initiators?.length}}
      </div>
      <div *ngFor="let initiator of action.initiators; let index = index;" class="initiator" [class.journey-initiator]="dataSourceType === EReportType.WEB_JOURNEY">
        <div *ngIf="initiator.tagId; else noTag" class="initiator-name initiator-row">
          <strong>Initiator:</strong>
          <img class="tag-icon"
               [src]="getTagIconUrl(initiator.tagId)" [alt]="initiator.tagId"/>
          <span class="initiator-value">
            {{ initiator.name }}
          </span>
        </div>
        <ng-template #noTag>
          <div *ngIf="initiator.icon" class="initiator-name">
            <div class="initiator-row"><strong>Initiator:</strong></div>
            <img *ngIf="initiator.type && initiator.type === 'js'" [src]="initiator.icon" alt="JavaScript Logo" class="js-logo" />
            <div *ngIf="!initiator.type"  class="icon-bg">
              <mat-icon>{{ initiator.icon }}</mat-icon>
            </div>
            {{ initiator.name }}
          </div>
          <div *ngIf="!initiator.icon" class="initiator-name">
            <div class="initiator-row"><strong>Initiator:</strong></div>&nbsp;{{ initiator.name }}
          </div>
        </ng-template>
        <div class="indented">
          <div *ngIf="initiator.requestUrl" class="request-url-wrp can-navigate" (click)="navAndFilterRequests({ cookieName: initiator.requestUrl, showPreAuditActions: initiator.isPreauditAction })">
            <div class="truncated"
                matTooltip="See more details on the Request Log report"
                matTooltipPosition="above"
            >
              {{ initiator.requestUrl }}
            </div>
            <mat-icon>east</mat-icon>
          </div>
          <div class="line-column initiator-row" *ngIf="dataSourceType === EReportType.AUDIT && (initiator.line && initiator.column) && initiator.setMethod !== ECookieInitiatorTypeSetMethod.HTTP">
            Line:&nbsp;<span class="initiator-value">{{ initiator.line }}</span>,
            Column:&nbsp;<span class="initiator-value">{{ initiator.column }}</span>
          </div>
          <div *ngIf="initiator.setMethod" class="initiator-row set-method">
            Set Method:&nbsp;
            <span class="initiator-value">{{ initiator.setMethod }}
              <mat-icon [matTooltip]="initiator.tooltip"
                        matTooltipPosition="above"
              >
                info_outline
              </mat-icon>
            </span>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="cookie.originStory?.actionCount > 1 && (dataSourceType === EReportType.AUDIT || (dataSourceType === EReportType.WEB_JOURNEY && actionIndex === action?.action?.sequence))">
      <div *ngIf="index === 0" class="cookie-values">
        <div class="initiator-value heading">This action created this cookie with these attributes:</div>
        <div class="indented">
          <div class="initiator-row">Name:&nbsp;<span class="initiator-value">{{ action.cookie.name }}</span></div>
          <div class="initiator-row">Value:&nbsp;<span class="initiator-value">{{ action.cookie.value }}</span></div>
          <div class="initiator-row">Domain:&nbsp;<span class="initiator-value">{{ action.cookie.domain }}</span></div>
          <div class="initiator-row">Path:&nbsp;<span class="initiator-value">{{ action.cookie.path }}</span></div>
          <div class="initiator-row" *ngIf="dataSourceType === EReportType.AUDIT">Cookie Duration:&nbsp;<span class="initiator-value">{{ action.cookie.duration }}</span></div>
          <div class="initiator-row">Cookie Expiration Date:&nbsp;<span class="initiator-value">{{ action.cookie.expires }}</span></div>
          <div class="initiator-row">HttpOnly:&nbsp;<span class="initiator-value">{{ action.cookie.httpOnly }}</span></div>
          <div class="initiator-row">SameSite:&nbsp;<span class="initiator-value">{{ action.cookie.sameSite }}</span></div>
          <div class="initiator-row">Secure:&nbsp;<span class="initiator-value">{{ action.cookie.secure }}</span></div>
          <div class="initiator-row">1st/3rd Party:&nbsp;<span class="initiator-value">{{ action.cookie.partyType }}</span></div>
        </div>
      </div>
      <div *ngIf="index > 0 && action.changes" class="cookie-values changes">
        <div class="initiator-value heading">This action changed these cookie attributes:</div>
        <div [innerHTML]="action.changes" class="indented"></div>
      </div>
    </ng-container>
  </div>
</div>
