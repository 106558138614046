<component-template componentName="Report Widgets">
  <component-library-section sectionName="OP Summary Card" selector="<op-summary-card>">
    <p>This is one of the basic widgets used in audit reports and other places in the app.<p>
      <div class="widgets-wrapper">
        <op-summary-card [headerText]="'Example Widget 1'"
                         [filteredValue]="57"
        ></op-summary-card>
        <op-summary-card [headerText]="'Example Widget 2'"
                         [totalValue]="100"
                         [filteredValue]="78"
                         [showTotalValueWhenFiltered]="true"
                         [showBothNumbersAlways]="true"
        ></op-summary-card>
        <op-summary-card [headerText]="'Example Widget 3'"
                         [filteredValue]="42"
                         [valueColorHighlight]="EColorHighlightMeaning.NEGATIVE"
        ></op-summary-card>
        <op-summary-card [headerText]="'Example Widget 4'"
                         [totalValue]="100"
                         [filteredValue]="96"
                         [showTotalValueWhenFiltered]="true"
                         [showBothNumbersAlways]="true"
                         [tooltipText]="'This is a tooltip'"
                         [leftSideIcon]="'settings'"
        ></op-summary-card>
      </div>
  </component-library-section>

  <component-library-section sectionName="Split Card" selector="<split-card>">
    <p>This is another widget commonly used in audit reports. The bottom portion of the widget accepts any component and is fully customizable.</p>
    <div class="widgets-wrapper split-card-widgets">
      <split-card [topLabel]="'Example Widget 1'"
                  [topTooltip]="'This is a tooltip'"
      >
        <div class="basic-value">{{ widget1Value }}</div>
      </split-card>
      <split-card [topLabel]="'Example Widget 2'"
                  [topChangeContent]="widget2Value"
                  [topTooltip]="'This is a tooltip'"
                  [metricType]="EAlertAuditSummaryMetric.AuditScore"
      >
        <sparkline-chart-insight [meaningOfUp]="'negative'"
                                 [meaningOfNoChange]="'none'"
                                 [label]="'since last run'"
                                 [dataLoaded]="true"
                                 [dataPoints]="widget3SparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="'Example Widget 3'"
                  [topChangeContent]="widget3Value"
                  [topTooltip]="'This is a tooltip'"
                  [icon]="'done'"
                  [topChangeMeaning]="'positive'"
                  [state]="EFilterSpinnerState.Filtered"
                  [topChangeFilteredContent]="1107"
      >
        <div class="basic-value">5</div>
      </split-card>
    </div>
  </component-library-section>
</component-template>