<ng-container *ngIf="isLoaded() && auditConsentCategories && !auditConsentCategories.length && !categoriesList?.length">
  <div class="yellow-banner" op-selector="privacy-banner-no-ccs-applied">
    <span>
      See when unapproved cookies, tags, or geolocations appear in future audit runs by
      <span class="link" (click)="openConsentCategoryManager()" *ngIf="!userIsReadOnly">
        assigning a Consent Category to this Audit.
      </span>
    </span>
  </div>
</ng-container>
<ng-container *ngIf="isLoaded() && auditConsentCategories?.length && categoriesList && !categoriesList.length">
  <div class="yellow-banner" op-selector="privacy-banner-ccs-not-run">
    <span>
      Consent Categories have been assigned to this audit but have not been processed or the audit has not run.
      <span class="link" (click)="showReprocessModal()" *ngIf="!userIsReadOnly">
        Process now
      </span>
    </span>
  </div>
</ng-container>
<ng-container *ngIf="isLoaded() && showRunsDifferBanner">
  <div class="yellow-banner banner-spacer" op-selector="privacy-banner-needs-reprocess">
    <span>
      Consent Categories assigned to the audit differ from what is presented in this run. Reprocess to evaluate this run against the latest config.
      <span class="link" (click)="showReprocessModal()" *ngIf="!userIsReadOnly">Process now</span>
    </span>
  </div>
</ng-container>
<ng-container *ngIf="categoriesList?.length">
  <div class="categories-wrap">
    <div class="category-section" [matMenuTriggerFor]="categoriesMenu">
      <span>{{categoriesList.length}} APPLIED CONSENT CATEGORIES</span>
      <mat-icon>expand_more</mat-icon>
    </div>
  </div>

  <mat-menu class="applied-consent-categories-menu"
            #categoriesMenu="matMenu"
  >
    <ul>
      <li *ngFor="let category of categoriesList"
          (click)="selectCategory($event, category)"
      >
        <div class="line" [class.deleted]="!category.consentCategoryConfigExists">
          <p class="name"
             [matTooltip]="getAssignedCCTooltip(category)"
             matTooltipPosition="above"
             matTooltipShowDelay="500"
          >{{category.name}} ({{category.type}})</p>
          <div class="icons-actions" *ngIf="category.consentCategoryConfigExists">
            <mat-icon>edit</mat-icon>
          </div>
        </div>
      </li>
      <hr>
      <li class="add-cc-option"
          (click)="openAuditStandards()"
      >
        <div class="line">
          <mat-icon>add_circle_outline</mat-icon>
          Assign an additional Consent Category
        </div>
      </li>
    </ul>
  </mat-menu>

</ng-container>
