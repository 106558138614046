<component-template componentName="Breadcrumb">
  <component-library-section sectionName="Basic Breadcrumb" selector="<breadcrumb>">
    <p>This the breadcrumb component. The basic breadcrumb is a list of links that represent the current page's position in the site hierarchy.</p>

    <div class="breadcrumb-example">
      <breadcrumb [history]="dummyBreadcrumb"></breadcrumb>
    </div>
  </component-library-section>

  <component-library-section sectionName="Dropdown Breadcrumb" selector="<breadcrumb>">
    <p>The breadcrumb can also be a dropdown menu.</p>

    <div class="breadcrumb-example">
      <breadcrumb [history]="dummyDropdownBreadcrumb"></breadcrumb>
    </div>
  </component-library-section>
</component-template>