<mat-form-field class="easy-block-field"
                appearance="outline"
                floatLabel="always"
                [mdePopoverTriggerFor]="tagSearchPopover"
                mdePopoverTriggerOn="click"
                [mdePopoverCloseOnClick]="false"
                [mdePopoverOverlapTrigger]="false"
                mdePopoverOffsetY="-20"
>
  <mat-label>{{ EFieldLabels.EASY_BLOCK_TAGS }}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let tag of visibleTags" (removed)="handleChipRemoved(tag)">
      {{ tag.name }}
      <button matChipRemove>
        <mat-icon>close</mat-icon>
      </button>
    </mat-chip-row>
    <mat-chip-row *ngIf="hiddenTags.length"
                  (click)="$event.stopPropagation()"
                  class="more-chip"
                  [mdePopoverTriggerFor]="hiddenTagsPopover"
                  [mdePopoverOverlapTrigger]="false"
                  mdePopoverTriggerOn="click"
    >
      More ({{ hiddenTags.length }})<mat-icon>expand_more</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>
  <input [matChipInputFor]="chipGrid"
         autocomplete="one-time-code"
         placeholder="{{ visibleTags.length ? '' : EEasyBlockStrings.Placeholder }}"
  />
  <mat-icon matSuffix class="drop-down-arrow">arrow_drop_down</mat-icon>
</mat-form-field>
<mat-error *ngIf="value?.errors?.easyBlockForm">{{value?.errors?.easyBlockForm?.message}}</mat-error>
<mat-hint class="field-hint">{{ EEasyBlockStrings.Hint }} <a [href]="EEasyBlockStrings.Link" target="_blank">{{ EEasyBlockStrings.LinkText }}</a></mat-hint>

<!-- this is just a field to attach the validator to so validation occurs -->
<input [formControl]="value" style="display: none;">

<mde-popover #tagSearchPopover="mdePopover">
  <mat-card class="easy-block-popover">
    <div class="input-wrap">
      <op-clearable-input placeholderText="Search by Tag Name"
                          [appearance]="ECIAppearance.OUTLINED"
                          (onUserInput)="searchTags($event)"
      ></op-clearable-input>
    </div>
    <small class="tag-cats-heading">Tag Categories</small>
    <open-menu-on-hover *ngFor="let category of filteredTags"
                        [menuClass]="'easy-block-menu'"
                        [activeClass]="'is-active'"
    >
      <div class="menu-trigger" trigger mat-menu-item>
        {{ category.name }} ({{ category.name.toLowerCase().includes(searchString) ? category.tags.length - 1 : category.tags.length }})
        <mat-icon>chevron_right</mat-icon>
      </div>
      <div class="secondary-menu-scroll-container" content>
        <div *ngFor="let tag of category.tags" mat-menu-item (click)="handleCheckboxChange(tag, category, tag.checked, $event)">
          <mat-checkbox color="primary"
                        [checked]="tag.checked"
          >
            {{ tag.name }} <span *ngIf="tag.easyBlockType === EEasyBlockTagType.CATEGORY">({{ category.tags.length - 1 }})</span>
          </mat-checkbox>
        </div>
      </div>
    </open-menu-on-hover>
    <p *ngIf="!filteredTags.length" class="no-results">No results</p>
  </mat-card>
</mde-popover>
<mde-popover #hiddenTagsPopover="mdePopover">
  <mat-card class="hiden-tags-popover">
    <mat-chip-row *ngFor="let tag of hiddenTags" (removed)="handleChipRemoved(tag)">
      {{ tag.name }}
      <button matChipRemove>
        <mat-icon>close</mat-icon>
      </button>
    </mat-chip-row>
  </mat-card>
</mde-popover>
