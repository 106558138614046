import { LiveConnectBaseComponent } from './live-connect-base/live-connect-base.component';
import { DeviceProfileCreatorComponent } from './device-profile/device-profile-creator.component';
import { LiveConnectWrapperComponent } from '@app/components/live-connect/live-connect-wrapper/live-connect-wrapper.component';
import { LiveConnectPaths, LiveConnectStateNames } from './live-connect.constants';
import { Route } from '@angular/router';
import { ManualJourneyRecorderRouteContainerComponent } from './manual-journey/manual-journey-recorder-route-container/manual-journey-recorder-route-container.component';

export const LiveConnectFutureState: Route = {
  path: LiveConnectPaths.base,
  loadChildren: () => import('./live-connect.module').then(mod => mod.LiveConnectModule),
};

export const LiveConnectRoutes: Route = {
  path: '',
  component: LiveConnectBaseComponent,
  data: {
    stateName: LiveConnectStateNames.base,
    title: 'LiveConnect',
  },
  children: [{
    path: '',
    pathMatch: 'full',
    component: LiveConnectWrapperComponent,
    data: {
      stateName: LiveConnectStateNames.base,
      title: 'LiveConnect',
    }
  }, {
    path: LiveConnectPaths.deviceProfile,
    component: DeviceProfileCreatorComponent,
    data: {
      stateName: LiveConnectStateNames.deviceCreator,
      title: 'New Device',
      fullScreen: true
    }
  }, {
    path: LiveConnectPaths.manualJourneyCreate,
    component: ManualJourneyRecorderRouteContainerComponent,
    data: {
      stateName: LiveConnectStateNames.manualJourney,
      title: 'Manual Journey',
      fullScreen: true
    }
  }, {
    path: LiveConnectPaths.manualJourneyEdit,
    component: ManualJourneyRecorderRouteContainerComponent,
    data: {
      stateName: LiveConnectStateNames.manualJourney,
      title: 'Manual Journey',
      fullScreen: true,
    }
  }, { // it should be used for redirecting on create / edit device profile, but this logic isn't fully implemented
    path: LiveConnectPaths.liveConnectSelected,
    component: LiveConnectWrapperComponent,
    data: {
      stateName: LiveConnectStateNames.liveConnectSelectedDevice,
      title: 'LiveConnect',
    }
  }]
};
