import { ComponentChanges } from '@app/models/commons';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { EAlertStep } from '@app/components/alert/alert.enums';
import { EAlertBellStatus } from '@app/components/alert/alert-bell/alert-bell.enums';
import { ETriggeredAlertsTableColumns } from './triggered-alerts-table.enum';
import { ITriggeredAlertsTablePagination, ITriggeredAlertsTableSort, ITriggeredAlertTableEditPayload, ITriggeredAlertTableRow } from './triggered-alerts-table.models';
import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { TriggeredAlertsService } from '../triggered-alerts.service';
import { ITriggeredAlertDataSourcesTableRow, ITriggeredAlertDataSourcesTableSort } from '../triggered-alert-data-sources-table/triggered-alert-data-sources-table.models';
import { ITriggeredAlertsSearchBody } from '../triggered-alerts.models';
import { triggeredAlertDataSourcesTableDefaultSorting } from '../triggered-alert-data-sources-table/triggered-alert-data-sources-table.constants';
import { triggeredAlertsTableDefaultSorting } from './triggered-alerts-table.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'triggered-alerts-table',
  templateUrl: './triggered-alerts-table.component.html',
  styleUrls: ['./triggered-alerts-table.component.scss'],
  animations: [
    trigger('expand', [
      state('collapsed', style({ maxHeight: '0', minHeight: '0' })),
      state('loading', style({ maxHeight: '60px', minHeight: '60px' })),
      state('expanded', style({ maxHeight: '200px', minHeight: '{{height}}' }), { params: { height: '*' } }),
      transition('collapsed <=> expanded, loading <=> *', animate('200ms'))
    ])
  ]
})
export class TriggeredAlertsTableComponent implements OnChanges, AfterViewInit {

  @Input() data: ITriggeredAlertTableRow[];
  @Input() pagination: ITriggeredAlertsTablePagination;
  @Input() sortOptions: ITriggeredAlertsTableSort;
  @Input() assignments: ITriggeredAlertsSearchBody;

  @Output() updateTableState = new EventEmitter<ITriggeredAlertsTablePagination & ITriggeredAlertsTableSort>();
  @Output() editAlertEvent = new EventEmitter<ITriggeredAlertTableEditPayload>();

  @ViewChild(MatPaginator) matPaginator: MatPaginator;
  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild(MatTable) table: MatTable<ITriggeredAlertTableRow>;

  ALERT_DELETED_MESSAGE = 'Alert configuration no longer exists because it has been deleted';

  loading = false;
  selectedAlert: ITriggeredAlertTableRow;
  dataSource = new MatTableDataSource<ITriggeredAlertTableRow>();

  lastOpenedNestedTableHeight: number;
  lastOpenedNestedTable: HTMLDivElement;
  visibleAlertDataSources: ITriggeredAlertDataSourcesTableRow[];

  readonly displayedColumns = [
    ETriggeredAlertsTableColumns.RecentlyTriggeredDate,
    ETriggeredAlertsTableColumns.AlertName,
    ETriggeredAlertsTableColumns.ReportMetric,
    ETriggeredAlertsTableColumns.Subscribers,
    ETriggeredAlertsTableColumns.DataSources
  ];
  readonly triggeredAlertsTableDefaultSorting = triggeredAlertsTableDefaultSorting;

  readonly ETriggeredAlertsTableColumns = ETriggeredAlertsTableColumns;
  readonly EAlertBellStatus = EAlertBellStatus;
  readonly EAlertStep = EAlertStep;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private triggeredAlertsService: TriggeredAlertsService) { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.matSort;
    this.dataSource.paginator = this.matPaginator;

    this.matSort?.sortChange.subscribe(({ active, direction }) => {
      this.sortOptions.sortBy = active as ETriggeredAlertsTableColumns;
      this.sortOptions.sortDesc = direction === 'desc';
      this.sortOptions.sortDir = direction;

      this.pagination.currentPageNumber = 0;
      this.updateTableState.emit({...this.sortOptions, ...this.pagination});
    });

    this.matPaginator?.page.subscribe(pagination => {
      this.pagination.currentPageNumber = pagination.pageIndex;
      this.updateTableState.emit({...this.sortOptions, ...this.pagination});
    });
  }

  ngOnChanges(changes: ComponentChanges<TriggeredAlertsTableComponent>) {
    if (changes.data?.currentValue !== changes.data?.previousValue) {
      this.dataSource.data = changes.data.currentValue;
    }
  }

  editAlert(event: ITriggeredAlertTableEditPayload) {
    if (event.alert.alertConfigExists) {
      this.editAlertEvent.emit(event);
    }
  }

  selectAlert(alert: ITriggeredAlertTableRow) {
    const sameAlert = this.selectedAlert === alert;
    if (sameAlert) {
      this.deselectAlert();
      return;
    }

    this.selectedAlert = alert;
    this.loading = true;
    this.getAlertDataSources(alert.id);
  }

  private deselectAlert() {
    this.selectedAlert = null;
    // timeout is needed for animation rendering
    setTimeout(() => this.visibleAlertDataSources = null, 225);
  }

  updateDataSourcesTableState(state: ITriggeredAlertDataSourcesTableSort) {
    this.getAlertDataSources(this.selectedAlert.id, state);
  }

  animationDone($event: AnimationEvent, container: HTMLDivElement) {
    if (($event.fromState === 'loading' && $event.toState === 'expanded')
      || ($event.fromState === 'collapsed' && $event.toState === 'expanded')) {
      this.lastOpenedNestedTable = container;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = container.getBoundingClientRect().height;
        this.changeDetectorRef.detectChanges();
      }, 300);
    }

    if ($event.fromState === 'expanded' && $event.toState === 'collapsed') {
      this.lastOpenedNestedTable = null;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = 0;
        this.changeDetectorRef.detectChanges();
      }, 300);
    }
  }

  private getAlertDataSources(alertId: number, sort?: ITriggeredAlertDataSourcesTableSort) {
    const searchParams = {
      sortBy: sort?.sortBy ?? triggeredAlertDataSourcesTableDefaultSorting.sortBy,
      sortDesc: sort?.sortDesc ?? triggeredAlertDataSourcesTableDefaultSorting.sortDesc
    };

    this.triggeredAlertsService.getAlertDataSources(alertId, searchParams, this.assignments).subscribe(dataSourcesDTO => {
      this.loading = false;
      this.lastOpenedNestedTableHeight = 0;
      this.visibleAlertDataSources = dataSourcesDTO.dataSources.map(dataSourceDTO => ({
        runDate: dataSourceDTO.runDate,
        itemId: dataSourceDTO.itemId,
        itemName: dataSourceDTO.itemName,
        itemType: dataSourceDTO.itemType,
        runId: dataSourceDTO.runId,
        value: dataSourceDTO.actualValue,
        status: dataSourceDTO.status,
      }));

      if (this.lastOpenedNestedTable) {
        setTimeout(() => {
          this.lastOpenedNestedTableHeight = this.lastOpenedNestedTable.getBoundingClientRect().height;
          this.changeDetectorRef.detectChanges();
        }, 300);
      }
    })
  }

}
