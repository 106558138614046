import { NgModule } from '@angular/core';

import { LabeledMenuComponent } from './labeled-menu.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/components/material/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [LabeledMenuComponent],
  declarations: [LabeledMenuComponent]
})
export class LabeledMenuModule { }
