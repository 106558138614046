<div class="hsbc-top-section">
  <div *ngIf="showHeading" class="title" matTooltip="Total percentage of tag instances in each category">
    {{ numUniqueCats }} Unique Categor{{ numUniqueCats === 1 ? 'y' : 'ies' }}
    <filter-spinner [state]="state"></filter-spinner>
  </div>
</div>
<div class="svg-container svg-container-{{ uniqueIdentifier }}"
     [class.filtered]="hasActiveFilter"
     [class.loading]="state === 'loading'"
     [class.rounded]="roundedCorners">
</div>
<div class="pill-container" *ngIf="showPills">
  <filter-pill *ngFor="let pill of formattedData" [data]="pill" (onClick)="filterChart($event.mouseEvent, $event.item)"></filter-pill>
</div>
