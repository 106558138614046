import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import {
  IAlertsRequestDTO,
  IAlertSummaryByMetricMap,
  IAlertSummaryRequestBody,
  IAlertSummaryResponse,
  IAuditSummaryAlert,
  IAuditSummaryAlerts,
  ISpecificAlertSummaryDTO
} from '@app/components/alert/alert.models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { map, take, tap } from 'rxjs/operators';
import {
  AlertMetricType,
  EAlertUsageMetricV2,
} from '@app/components/alert/alert-logic/alert-logic.enums';
import { ICommonTableState } from '../shared/components/export-report/export-reports.models';
import { IAlertsLibraryResponse } from '@app/components/alerts-library/alerts-library.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';

@Injectable({
  providedIn: 'root'
})
export class AlertReportingService {
  protected responseCache = new Map();

  private apiRoot = `${environment.apiV3Url}`;
  private apiAuditsRoot = `${environment.apiV3Url}web-audits`;
  private alertPopoverSubject = new BehaviorSubject<IAuditSummaryAlert[] | []>([] as IAuditSummaryAlert[]);
  private selectedRunAlertSummaryMap: IAlertSummaryByMetricMap | {} = {};
  private alertSummaryMapSubject = new BehaviorSubject<IAlertSummaryByMetricMap | {}>({} as IAlertSummaryByMetricMap);
  alertSummaryMap$: Observable<IAlertSummaryByMetricMap | {}> = this.alertSummaryMapSubject.asObservable();
  private accountAlertSummaryMap: IAlertSummaryByMetricMap | {} = {};
  private accountAlertSummaryMapSubject = new BehaviorSubject<IAlertSummaryByMetricMap | {}>({} as IAlertSummaryByMetricMap);
  accountAlertSummaryMap$: Observable<IAlertSummaryByMetricMap | {}> = this.accountAlertSummaryMapSubject.asObservable();
  alertPopoverData$: Observable<IAuditSummaryAlert[] | []> = this.alertPopoverSubject.asObservable();
  private accountHasAlertsSubject = new BehaviorSubject<boolean>(false);
  accountHasAlerts$: Observable<boolean> = this.accountHasAlertsSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private cacheResetService: CacheResetService
  ) {
    this.cacheResetService.reset$.subscribe(_ => {
      this.responseCache.clear();
    });

    this.apiService.post(this.apiRoot + 'alerts/library', {}, {}).pipe(
      take(1)
    ).subscribe((alerts: IAlertsLibraryResponse) => {
      this.accountHasAlertsSubject.next(alerts?.alerts?.length > 0);
    });
  }

  removeFromCache(key: string): void {
    this.responseCache.delete(key);
  }

  getAlertsByMetric(metric: AlertMetricType, forceLoad?: boolean): Observable<IAlertsRequestDTO> {
    const requestUrl = `${this.apiRoot}alerts/metrics/${metric}`;

    const cached = this.responseCache.get(requestUrl);

    if (cached && !forceLoad) {
      return of(cached);
    }

    return this.apiService
      .get<IAlertsRequestDTO>(requestUrl)
      .pipe(
        tap(alerts => {
          this.responseCache.set(requestUrl, alerts);
        })
      );
  }

  @CacheApiResponse({ liveTime: 10000 }) getAlertSummary(auditId: number, runId: number, params: Partial<ICommonTableState>, body: IAlertSummaryRequestBody): Observable<IAlertSummaryResponse> {
    const baseRequestUrl = `${this.apiAuditsRoot}/${auditId}/runs/${runId}/reports/alert-summary/alerts`;

    let queryParams = new HttpParams();
    if (params.page) queryParams = queryParams.set('page', params.page);
    if (params.size) queryParams = queryParams.set('size', params.size);
    if (params.sortBy) queryParams = queryParams.set('sortBy', params.sortBy);
    if (params.sortDesc) queryParams = queryParams.set('sortDesc', params.sortDesc);
    if (params.alertId) queryParams = queryParams.set('alertId', params.alertId);

    return this.apiService.post(baseRequestUrl, body, { params: queryParams });
  }

  getUsageAlertSummary(): Observable<IAlertSummaryResponse> {
    const requestUrl = `${this.apiRoot}usage/alert-summary/alerts`;

    return this.apiService
      .get(requestUrl)
      .pipe(
        tap((response: IAlertSummaryResponse) => {
          this.accountAlertSummaryMap = this.updateAccountAlertResultsMap(response);
          this.accountAlertSummaryMapSubject.next(this.accountAlertSummaryMap);
        }),
        map((response: IAlertSummaryResponse) => {
          const accountAlerts = (response.alerts ?? []).filter(alert => this.isAccountLevelMetric(alert.config.metricType));
          response.alerts = accountAlerts;
          return response;
        })
      );
  }

  getSpecificAlertSummary(auditId: number, runId: number, alertId: number, forceLoad?: boolean): Observable<ISpecificAlertSummaryDTO> {
    const requestUrl = `${this.apiAuditsRoot}/${auditId}/runs/${runId}/reports/alert-summary/alerts/${alertId}`;

    const cached = this.responseCache.get(requestUrl);

    if (cached && !forceLoad) {
      return of(cached);
    }

    return this.apiService
      .get<ISpecificAlertSummaryDTO>(requestUrl)
      .pipe(
        tap((alert: ISpecificAlertSummaryDTO) => {
          this.responseCache.set(requestUrl, alert);
        })
      );
  }

  // Update the selectedRunAlertSummaryMap to reflect the most recently loaded
  // audit run state.
  getAlertsForRun(auditId: number, runId: number, forceLoad?: boolean): Observable<IAuditSummaryAlerts> {
    const requestUrl = `${this.apiAuditsRoot}/${auditId}/runs/${runId}/reports/alert-summary/overview`;
    const cached = this.responseCache.get(requestUrl);

    if (cached && !forceLoad) {
      this.selectedRunAlertSummaryMap = cached;
      this.alertSummaryMapSubject.next(this.selectedRunAlertSummaryMap);
      return;
    }

    this.apiService.get<IAuditSummaryAlerts>(requestUrl).subscribe((results: IAuditSummaryAlerts) => {
      const { alerts } = results;
      this.alertPopoverSubject.next(alerts);
      this.selectedRunAlertSummaryMap = this.updateAlertResultsMap(results);
      this.alertSummaryMapSubject.next(this.selectedRunAlertSummaryMap);
      this.responseCache.set(requestUrl, this.selectedRunAlertSummaryMap);
    });
  }

  // Create map by metric type for all results received
  private updateAlertResultsMap(results: IAuditSummaryAlerts): IAlertSummaryByMetricMap {
    let alertResultsMap = results?.alerts?.reduce((acc, item) => {

      if (!acc[item.metricType]) acc[item.metricType] = [];

      acc[item.metricType].push(item);
      return acc;
    }, {} as IAlertSummaryByMetricMap);

    return alertResultsMap;
  }

  // Create map by metric type from alert summary response
  private updateAccountAlertResultsMap(accountAlertSummary: IAlertSummaryResponse): IAlertSummaryByMetricMap {
    let alertResultsMap = accountAlertSummary?.alerts?.reduce((acc, item) => {

      if (!acc[item.config.metricType]) acc[item.config.metricType] = [];

      acc[item.config.metricType].push({
        id: item.id,
        name: item.config.name,
        metricType: item.config.metricType,
        status: item.result.status,
        isSubscribed: item.config.isUserSubscribed,
        alertConfigExists: true,
      });
      return acc;
    }, {} as IAlertSummaryByMetricMap);

    return alertResultsMap;
  }

  // Retrieve the current alert statuses by metric for selected run
  getAlertStatusByMetric(metric: AlertMetricType): IAuditSummaryAlert[] {
    if (this.isAccountLevelMetric(metric)) {
      return this.accountAlertSummaryMap ? this.accountAlertSummaryMap[metric] || [] : [];
    } else {
      return this.selectedRunAlertSummaryMap ? this.selectedRunAlertSummaryMap[metric] || [] : [];
    }
  }

  isAccountLevelMetric(metric: AlertMetricType): boolean {
    switch (metric) {
      case EAlertUsageMetricV2.UsersLoggedInLast30Days:
      case EAlertUsageMetricV2.AccountUsageCurTermAuditPageScannedCount:
      case EAlertUsageMetricV2.AccountUsageMonthPaceAuditPageScannedCount:
      case EAlertUsageMetricV2.AccountUsageCurTermWebJourneyRunsCount:
      case EAlertUsageMetricV2.AccountUsageMonthPaceWebJourneyRunsCount:
        return true;
      default:
        return false;
    }
  }

  isMetricSupportsFilters(metric: AlertMetricType) {
    return metric !== EAlertUsageMetricV2.UsersLoggedInLast30Days;
  }
}
