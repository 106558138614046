import { Route } from '@angular/router';
import { UsageV2Paths, UsageV2StateNames } from '@app/components/usage-v2/usage-v2.constants';
import { UsageV2Component } from '@app/components/usage-v2/usage-v2.component';
import { UsageReportGuard } from '../core/special-permissions.guard';

export const UsageV2FutureState: Route = {
  path: UsageV2Paths.base,
  canLoad: [ UsageReportGuard ],
  loadChildren: () => import('./usage-v2.module').then(mod => mod.UsageV2Module),
};

export const UsageV2Routes: Route = {
  path: '',
  component: UsageV2Component,
  data: {
    title: 'Usage Report',
    stateName: UsageV2StateNames.base
  }
};
