import { Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { filter, map, pairwise, share, takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RouteReloadService implements OnDestroy {

  reloadRouteEvents$: Observable<NavigationEnd>;

  private destroySubject = new Subject<void>();

  constructor(private router: Router) {}

  init() {
    this.reloadRouteEvents$ = this.router.events.pipe(
      takeUntil(this.destroySubject),
      filter(event => event instanceof NavigationEnd),
      pairwise(),
      filter(([previousNavigation, currentNavigation]: [NavigationEnd, NavigationEnd]) => {
        return previousNavigation.urlAfterRedirects === currentNavigation.urlAfterRedirects;
      }),
      map(([_, currentNavigation]: [NavigationEnd, NavigationEnd]) => {
        return currentNavigation;
      }),
      share()
    );

    this.reloadRouteEvents$.subscribe();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

}