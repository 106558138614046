import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDeleteModalPayload } from '@app/components/shared/components/op-modal';
import { IButton } from 'models/commons';
import { IDisplayItem } from '@app/components/delete/deleteControllerBase';
import { modifyButtonsActions } from '../shared/op-modal.utils';
import { finalize } from 'rxjs/operators';
import { EDateFormats } from '@app/components/date/date.service';

@Component({
  selector: 'op-delete-modal',
  templateUrl: './op-delete-modal.component.html',
  styleUrls: ['./op-delete-modal.component.scss']
})
export class OpDeleteModalComponent {

  title: string;
  leftFooterButtons: IButton[] = [{
    label: 'Cancel',
    action: () => this.close(),
    transparent: true,
  }];
  rightFooterButtons: IButton[];

  allAuthorized: boolean = true;

  displayItem: IDisplayItem;
  content: Array<IDisplayItem> = [];
  loadingContent: boolean = false;

  deleteContentRequestText: string;
  deleteNoContentText: string;

  createdDateFormat: string = EDateFormats.dateSeven;

  constructor(@Inject(MAT_DIALOG_DATA) private data: IDeleteModalPayload,
              private dialogRef: MatDialogRef<OpDeleteModalComponent>) {

    const buttons = [{
      label: data.deleteButtonText || 'Yes, Delete',
      action: data.deleteButtonAction,
      primary: true
    }];

    this.title = data.title || 'Confirm Delete';
    this.rightFooterButtons = modifyButtonsActions(buttons, () => this.close(true));

    this.displayItem = data.displayItem;
    this.deleteContentRequestText = data.deleteContentRequestText;
    this.deleteNoContentText = data.deleteNoContentText;

    if (data.disableDeleting) {
      this.allAuthorized = false;
      this.disableDeleting();
    }

    if (data.content) {
      this.loadingContent = true;
      this.disableDeleting();
      data.content.pipe(
        finalize(() => this.loadingContent = false)
      ).subscribe(content => {
        this.content = content;
        if (content.some(item => !item.authorized)) {
          this.allAuthorized = false;
        } else {
          this.enableDeleting(buttons);
        }
      });
    }
  }

  private disableDeleting() {
    this.rightFooterButtons = [{
      label: 'Close',
      action: () => this.close()
    }];
    this.leftFooterButtons[0].hidden = true;
  }

  private enableDeleting(buttons) {
    this.rightFooterButtons = modifyButtonsActions(buttons, () => this.close(true));
    this.leftFooterButtons[0].hidden = false;
  }

  close(toDelete = false): void {
    this.dialogRef.close(toDelete);
  }

}
