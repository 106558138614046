import { RouteBuilder } from '../shared/services/route-builder.service';

export const LiveConnectStateNames = {
  base: 'live-connect',
  liveConnectSelectedDevice: 'live-connect.device',
  deviceCreator: 'live-connect.device-profile',
  manualJourney: 'live-connect.manual-journey'
};

export const LiveConnectPaths = {
  base: 'live-connect',
  deviceProfile: 'deviceProfile/:type',
  manualJourneyCreate: 'manual-journey/:dpId',
  manualJourneyEdit: 'manual-journey/:dpId/:mjId',
  liveConnectSelected: ':dpType/:dpId'
};

export const LiveConnectUrlBuilders = {
  base: () => LiveConnectPaths.base,
  deviceProfile: (type: string) => RouteBuilder.buildUrl([LiveConnectPaths.base, LiveConnectPaths.deviceProfile], type),
  manualJourneyCreate: (dpId: number) => {
    return RouteBuilder.buildUrl([LiveConnectPaths.base, LiveConnectPaths.manualJourneyCreate], String(dpId));
  },
  manualJourneyEdit: (dpId: number, mjId: number) => {
    return RouteBuilder.buildUrl([LiveConnectPaths.base, LiveConnectPaths.manualJourneyEdit], String(dpId), String(mjId));
  },
  liveConnectSelected: (dpType: string, dpId: number) => {
    return RouteBuilder.buildUrl([LiveConnectPaths.base, LiveConnectPaths.liveConnectSelected], dpType, String(dpId));
  }
};
