import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IAudit } from '../audits-picker.models';
import { EAuditsPickerListMode } from './audits-picker-list.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audits-picker-list',
  templateUrl: './audits-picker-list.component.html',
  styleUrls: ['./audits-picker-list.component.scss']
})
export class AuditsPickerListComponent {

  @Input() audits: IAudit[] = [];
  @Input() mode: EAuditsPickerListMode;
  @Input() filtering: boolean = false;

  @Output() onAdd?: EventEmitter<IAudit | IAudit[]> = new EventEmitter();
  @Output() onRemove?: EventEmitter<IAudit> = new EventEmitter();

  readonly auditsPickerListMode = EAuditsPickerListMode;

}
