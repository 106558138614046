import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConsentCategoriesUIConstants } from '@app/components/consent-categories/consent-categories.constants';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { EmailInboxesService } from '@app/components/email-inboxes/email-inboxes.service';
import {
  IEmailInbox,
  IEmailInboxMessage,
  IEmailInboxMessageAlertMessageItem,
  IEmailInboxMessageNormalized,
  EEmailMessagesSortColumns,
  IEmailMessagesSort, IEmailInboxMessageRun, IEmailInboxMessageAlertAuditItem
} from '@app/components/email-inboxes/email-inboxes.models';
import {
  ClickToEnlargeScreenshotModalComponent
} from '@app/components/click-to-enlarge-screenshot/click-to-enlarge-screenshot-modal/click-to-enlarge-screenshot-modal.component';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { AuditReportUrlBuilders } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { catchError, finalize, map, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';
import { dateFromString, DateService, EDateFormats } from '@app/components/date/date.service';
import { differenceInHours } from 'date-fns';
import { EEmailInboxAlertStatus } from '@app/components/email-inboxes/email-inboxes.constants';
import { MatSort, Sort } from '@angular/material/sort';
import { interval, of, Subject } from 'rxjs';
import { IPaginationMetaData } from '@app/components/consent-categories/consent-categories.models';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'op-email-inbox-messages-table',
  templateUrl: './email-inbox-messages-table.component.html',
  styleUrls: ['./email-inbox-messages-table.component.scss'],
})
export class EmailInboxMessagesTableComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly POLLING_INTERVAL = 30000;

  CONSTANTS = { ...ConsentCategoriesUIConstants };
  loading: boolean = true;
  dataSource = new MatTableDataSource<IEmailInboxMessageNormalized>;
  displayedColumns: string[] = [
    'screenshotUrl',
    'subject',
    'receivedAt',
    'senderEmailAddress',
    'status',
    'uniqueLinks',
    'testScenarioRuns',
    'readyToSend',
  ];

  @Input() inboxId: number;
  @Input() inbox: IEmailInbox = null;
  @Input() accountId: number;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  pagination: IPaginationMetaData = {
    pageSize: 100,
    currentPageNumber: 0,
  };

  sortOptions: IEmailMessagesSort = {
    sortBy: EEmailMessagesSortColumns.RECEIVED_AT,
    sortDesc: true,
    sortDir: 'desc'
  };

  private destroy$ = new Subject();
  private sortPaginateEmails$ = new Subject();

  constructor(
    private router: Router,
    private modalService: OpModalService,
    private emailInboxesService: EmailInboxesService,
    private modalEscapeService: ModalEscapeService,
    private dateService: DateService,
  ) {
  }

  ngOnInit(): void {
    this.initData();
  }

  ngAfterViewInit(): void {
    this.paginator.page.subscribe(({pageIndex}) => this.handlePagination(pageIndex));
  }


  handlePagination(pageIndex: number): void {
    this.pagination.currentPageNumber = pageIndex;
    this.sortPaginateEmails$.next();
  }

  initData(): void {
    this.pagination.currentPageNumber = 0;

    this.sortPaginateEmails$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.loadMessages());

    this.sortPaginateEmails$.next();
  }

  handleSort(sort: Sort): void {
    let data: Sort = sort;
    this.sortOptions.sortBy = (data.active as EEmailMessagesSortColumns);
    this.sortOptions.sortDesc = data.direction === 'desc';
    this.sortOptions.sortDir = data.direction;
    this.setFirstPagePaginator();
    this.sortPaginateEmails$.next();
  }

  private setFirstPagePaginator(): void {
    this.pagination.currentPageNumber = 0;

    if (this.dataSource.data) {
      this.paginator.pageIndex = 0;
    }
  }

  private loadMessages(): void {
    const pagination = {
      page: this.pagination.currentPageNumber,
      pageSize: this.pagination.currentPageSize,
      sortBy: this.sortOptions.sortBy,
      sortDesc: this.sortOptions.sortDesc
    };

    this.loading = true;

    this.emailInboxesService.getEmailInboxMessages(this.inboxId, pagination)
        .pipe(
            tap(({ metadata, messages }) => {
              this.pagination = metadata.pagination;
              return messages;
            }),
            pluck('messages'),
            switchMap((messages: IEmailInboxMessage[]) =>
                this.emailInboxesService.getEmailInboxMessageAlerts({
                      emailInboxId: this.inboxId,
                      messageIds: messages.map(m => m.id),
                    })
                    .pipe(
                        pluck('messages'),
                        catchError(() => of([])),
                        map((alerts: IEmailInboxMessageAlertMessageItem[]) => messages.map((message: IEmailInboxMessage): IEmailInboxMessageNormalized =>
                                  this.normalizeMessage(
                                      message,
                                      alerts?.find(m => m.messageId === message.id),
                                  ))
                        ))
            ),
            finalize(() => this.loading = false)
        )
        .subscribe((messages: IEmailInboxMessageNormalized[]) => {
          this.dataSource.data = messages;
          this.initPolling(messages);
          setTimeout(() => this.dataSource.sort = this.sort);
        });
  }

  private initPolling(messages: IEmailInboxMessageNormalized[]): void {
    interval(this.POLLING_INTERVAL)
      .pipe(
        takeUntil(this.sortPaginateEmails$),
        switchMap(() => this.emailInboxesService.getEmailInboxMessagesStatuses(this.inboxId, messages.map(m => m.id))),
      )
      .subscribe(d => {
        d.forEach((messageStatus, index) => {
          let status = this.dataSource.data.find(i => i.id === messageStatus.id)?.status;
          if (status) {
            status = String(messageStatus.status).toLowerCase();
          }
        });
      });
  }

  private normalizeMessage(message: IEmailInboxMessage, alert: IEmailInboxMessageAlertMessageItem): IEmailInboxMessageNormalized {
    if (!alert) {
      return {
        ...message,
        audits: alert?.audits ?? [],
        receivedAtText: this.setReceivedAtText(message),
        receivedAtTooltipText: this.setReceivedAtTooltipText(message),
        status: message.status.toLowerCase(),
      };
    }

    const assignedAlertCount = alert.audits.reduce((acc, audit) => acc + audit.assignedAlertCount, 0) || 0;
    const triggeredAlertCount = alert.audits.reduce((acc, audit) => acc + audit.triggeredAlertCount, 0) || 0;
    const hasTriggeredAlerts = triggeredAlertCount > 0;
    const status = assignedAlertCount === 0
        ? EEmailInboxAlertStatus.UNCONFIGURED
        : hasTriggeredAlerts
          ? EEmailInboxAlertStatus.BAD
          : EEmailInboxAlertStatus.GOOD;
    const label = assignedAlertCount === 0
        ? 'Unconfigured'
        : hasTriggeredAlerts
            ? 'Not Ready'
            : 'Ready';
    const iconName =  assignedAlertCount === 0 ? 'indeterminate_question_box' :
        hasTriggeredAlerts
          ? 'thumb_down'
          : 'thumb_up';

    return {
      ...message,
      receivedAtText: this.setReceivedAtText(message),
      receivedAtTooltipText: this.setReceivedAtTooltipText(message),
      status: message.status.toLowerCase(),
      audits: alert.audits ?? [],
      readyToSend: {
        assignedAlertCount,
        triggeredAlertCount,
        status,
        label,
        iconName,
      }
    };
  }

  setReceivedAtText(message: IEmailInboxMessage): string {
    if (differenceInHours(this.dateService.now(), dateFromString(message.receivedAt)) < 24) {
      return `${this.dateService.distanceToNow(dateFromString(message.receivedAt))} ago`;
    }

    return this.dateService.formatDate(dateFromString(message.receivedAt), EDateFormats.dateEleven);
  }

  setReceivedAtTooltipText(message: IEmailInboxMessage): string {
    return `${this.dateService.distanceToNow(dateFromString(message.receivedAt))} ago on
       ${this.dateService.formatDate(dateFromString(message.receivedAt), EDateFormats.dateEleven)}`;
  }

  openScreenshot(message: IEmailInboxMessage): void {
    const currentRoute = this.router.url;
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);
    this.modalService
      .openFullscreenModalTransparent(ClickToEnlargeScreenshotModalComponent, {
        data: {
          screenshotUrl: message.screenshotPath,
          title: message.subject,
        }
      })
      .afterClosed()
      .subscribe(() => {
        this.router.navigateByUrl(currentRoute);
        this.modalEscapeService.remove(index);
      });
  }

  gotoRun(run: IEmailInboxMessageRun): void {
    const runIdToNavigateTo = run.isCompleted ? run.runId : 0;
    this.router.navigateByUrl(AuditReportUrlBuilders.useCaseOverview(run.auditId, runIdToNavigateTo));
  }

  displayReadyToSend(message: IEmailInboxMessageNormalized): boolean {
    return message.readyToSend && message.status.toLowerCase() === 'complete'
  }

  gotoAudit(audit: IEmailInboxMessageAlertAuditItem): void {
    if(audit.assignedAlertCount > 0) {
      this.router.navigateByUrl(AuditReportUrlBuilders.alertSummary(audit.auditId, audit.runId));
    } else {
      this.router.navigateByUrl(AuditReportUrlBuilders.useCaseLandingPage(audit.auditId, audit.runId));
    }
  }

  getReadyToSendTooltipText(row): string  {
    return row.readyToSend.assignedAlertCount === 0 ? 'Associated test scenarios have no alerts configured' :
      row.readyToSend.triggeredAlertCount > 0
          ? `${row.readyToSend.triggeredAlertCount} of ${row.readyToSend.assignedAlertCount} alerts triggered in test scenarios`
          : 'No alerts were triggered in test scenarios';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.sortPaginateEmails$.next();
  }
}
