import { ButtonSet } from '@app/models/commons';

export interface IConfirmationModalScope extends mgcrea.ngStrap.modal.IModalScope {
    messages: string[];
    hideDiscardButton?: boolean;
  }

  export class ConfirmationController {
    messages: string[];
    hideDiscardButton: boolean;
    modalButtons: Array<ButtonSet> = [[{
      label: 'Yes',
      action: 'confirm',
      primary: false
    },
    {
      label: 'No',
      action: 'cancel',
      primary: true
    }]];

    static $inject = [
      '$scope',
      'answer'
    ];
    constructor(private $scope: IConfirmationModalScope,
      private answer: Function) {
        this.messages = this.$scope.messages;
        this.hideDiscardButton = this.$scope.hideDiscardButton;
    }

    confirm(event): void {
      this.answer(true);
      this.close();
    }

    cancel(): void {
      this.answer(false);
      this.close();
    }

    close(): void {
      this.$scope.$hide();
    }
}
