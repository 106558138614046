import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UntypedFormGroup } from '@angular/forms';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { EAlertFilterType, EAlertModalType, EAlertStep } from '../alert.enums';
import { IAlertTriggerFormData } from '../alert.models';
import { IAlertPreviewState } from '../alert-preview/alert-preview.models';
import { AlertPreviewService } from '../alert-preview/alert-preview.service';
import { AlertLogicFilterBarService } from '../alert-logic/alert-logic-filter-bar.service';
import { UsageFilterBarService } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.service';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { UsageAlertFilterBarService } from '@app/components/alert/alert-logic/usage-alert-filter-bar.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-steps',
  templateUrl: './alert-steps.component.html',
  styleUrls: ['./alert-steps.component.scss']
})
export class AlertStepsComponent implements OnInit, OnDestroy {

  @Input() formGroup: UntypedFormGroup;
  @Input() noDataSourcesSupport: boolean = false;
  @Input() noHistorySupport: boolean = false;
  @Input() currentStep: EAlertStep;
  @Input() modalType: EAlertModalType;
  @Input() filterType: EAlertFilterType;
  @Output() stepSelected = new EventEmitter<EAlertStep>();

  filterInfoPlaceholder: string;
  isAlertTriggeredInLastRun = false;
  customNotificationMessage: boolean = false;

  private destroySubject = new Subject();

  readonly EAlertStep = EAlertStep;

  constructor(private filterBarService: AlertLogicFilterBarService,
              private usageFilterBarService: UsageAlertFilterBarService,
              private alertPreviewService: AlertPreviewService) {}

  ngOnInit() {
    this.alertPreviewService.state$
      .pipe(takeUntil(this.destroySubject))
      .subscribe((data: IAlertPreviewState) => {
        this.isAlertTriggeredInLastRun = data.bars?.length > 0 && data.bars[data.bars.length - 1].triggered;
      });

    this.notificationFormGroup.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe(value => {
        this.customNotificationMessage = value?.message?.length > 0;
      });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  get filters(): (IOpFilterBarV2Filter<unknown | IOpFilterBarFilter<unknown>>)[] {
    switch (this.filterType) {
      case EAlertFilterType.None: return [];
      case EAlertFilterType.V1: return this.filterBarService.currentRelevantFilters.sort((a, b) => a.order > b.order ? 1 : -1);
      case EAlertFilterType.V2: return this.usageFilterBarService.currentRelevantFilters.sort((a, b) => a.order > b.order ? 1 : -1);
    }
  }

  get logicFormGroup(): UntypedFormGroup {
    return this.formGroup.get('logic') as UntypedFormGroup;
  }

  get triggerFormData(): IAlertTriggerFormData {
    return this.logicFormGroup.get('trigger').value as IAlertTriggerFormData;
  }

  get filtersFormData(): IAlertTriggerFormData {
    return this.logicFormGroup.get('trigger').value as IAlertTriggerFormData;
  }

  get notificationFormGroup(): UntypedFormGroup {
    return this.formGroup.get('notification') as UntypedFormGroup;
  }

  get notificationEmails(): string[] {
    return this.notificationFormGroup.get('emails').value as string[];
  }

  get dataSourcesFormGroup(): UntypedFormGroup {
    return this.formGroup.get('dataSources') as UntypedFormGroup;
  }

  get dataSourcesItems(): number[] {
    return this.dataSourcesFormGroup.get('items').value;
  }
}
