import _sortBy from 'lodash/sortBy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IAuditRunSummary } from '@app/components/domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { IAuditReportFilter } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import {
  IAlertCreateForAuditModalPayload, IAlertEditModalPayload,
  IAlertFormData,
  IAlertModalPayload,
  IAlertTriggerFormData
} from '../alert.models';
import { IAlertDataSourcesTableItem } from '../alert-data-sources/alert-data-sources.models';
import { AlertLogicFilterBarService } from '../alert-logic/alert-logic-filter-bar.service';
import { IAlertBarsChartBar } from './alert-preview-chart/alert-preview-chart.models';
import { AlertPreviewService } from './alert-preview.service';
import { EAlertModalType } from '@app/components/alert/alert.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-preview',
  templateUrl: './alert-preview.component.html',
  styleUrls: ['./alert-preview.component.scss']
})
export class AlertPreviewComponent implements OnInit, OnDestroy {

  @Input() formGroup: UntypedFormGroup;
  @Input() payload: IAlertModalPayload;

  selectedDataSource: IAlertDataSourcesTableItem;

  bars: IAlertBarsChartBar[];
  loading: boolean;
  lastFailedRun: IAuditRunSummary;

  readonly uniqueIdentifier = 'alert-preview';

  private destroySubject = new Subject<void>();

  constructor(
    private filterBarService: AlertLogicFilterBarService,
    private discoveryAuditService: DiscoveryAuditService,
    private previewService: AlertPreviewService,
  ) {}

  ngOnInit() {
    this.selectInitialDatasource();

    this.previewService.state$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(({ bars, loading, lastFailedRun }) => {
        this.bars = bars;
        this.loading = loading;
        this.lastFailedRun = lastFailedRun;
      });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  selectInitialDatasource(): void {
    let datasourceIndex = 0;
    if (
      this.payload.type === EAlertModalType.Edit &&
      (this.payload as IAlertEditModalPayload)?.auditId &&
      this.dataSources.length
    ) {
      datasourceIndex = this.dataSources.findIndex(item => item.id === (this.payload  as IAlertCreateForAuditModalPayload)?.auditId);
    }

    if (this.dataSources.length) {
      this.dataSourceSelected(this.dataSources[datasourceIndex]);
    }
  }

  dataSourceSelected(dataSource: IAlertDataSourcesTableItem) {
    this.selectedDataSource = dataSource;
    this.fetchRuns(this.selectedDataSource.id);
  }

  private async fetchRuns(itemId: number) {
    const runs = await this.discoveryAuditService.getAuditRuns(itemId);
    const sortedRuns = _sortBy(runs.filter(run => run.completed).slice(0, 10), 'completed');

    const formValue = this.formGroup.getRawValue() as IAlertFormData;
    // name is optional on the UI side, but required on the API side
    const alertName = formValue.name || 'Alert preview';
    this.previewService.prepareChartData(sortedRuns, { ...formValue, name: alertName }, itemId);
  }

  get filters(): IAuditReportFilter[] {
    return this.filterBarService.currentRelevantFilters.sort((a, b) => a.order > b.order ? 1 : -1);
  }

  get logicFormGroup(): UntypedFormGroup {
    return this.formGroup.get('logic') as UntypedFormGroup;
  }

  get triggerFormData(): IAlertTriggerFormData {
    return this.logicFormGroup.get('trigger').value as IAlertTriggerFormData;
  }

  get dataSourcesFormGroup(): UntypedFormGroup {
    return this.formGroup.get('dataSources') as UntypedFormGroup;
  }

  get dataSources(): IAlertDataSourcesTableItem[] {
    return this.dataSourcesFormGroup.get('items').value as IAlertDataSourcesTableItem[];
  }

}
