<op-modal [title]="title"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="close()">

  <ng-template #bodyTemplate>
    <div class="flex-col flex-center-items scroll-vertically scroll-device-profile-creator">
      <device-categories [categories]="categories" [categoryImgs]="categoryImgs" [activeCategoryKey]="activeCategoryKey"
        (activateNewCategory)="handleCategoryClick($event)">
      </device-categories>
      <form validate-form [formGroup]="deviceProfileForm" formName="deviceProfileForm" [submitted]="submitted" class="form-wrp">
        <div class="flex-row flex-no-shrink">
          <div class="device-config">
            <div class="form-input">
              <mat-form-field>
                <mat-label>Device Model</mat-label>
                <input matInput formControlName="deviceModel">
              </mat-form-field>
              <op-error-message formControlPath="deviceProfileForm.deviceModel"></op-error-message>
            </div>
            <div class="form-input">
              <op-select-create
                #osDevice
                label="Device Operating System"
                formControlName="deviceOs"
                formControlPath="deviceProfileForm.deviceOs"
                [data]="deviceOSList"
                itemName="operating system"
                subItemName="operating system"
                createText="Custom Operating System"
                cancelText="Select from list"
              >
              </op-select-create>
            </div>
          </div>
          <div class="organization-config">
            <div class="form-input">
              <op-select-create
                label="Folder"
                formControlName="folder"
                formControlPath="deviceProfileForm.folder"
                [data]="folders"
                itemName="folder"
                subItemName="folder"
                createText="create folder"
                cancelText="select from list"
              >
              </op-select-create>
            </div>
            <div class="form-input">
              <op-chip-selector
                label="Add labels"
                [appearance]="'fill'"
                [placeholder]="{ hasSelected: '+ Label', hasNoSelected: '+ Label' }"
                [(ngModel)]="selectedLabels"
                [allItems]="labels"
                formControlName="labels"
              ></op-chip-selector>
            </div>
          </div>
        </div>
        <op-clipboard
          class="form-input"
          label='Install this SSL certificate on your device (<a class="yellow-link" target="_blank" href="https://help.observepoint.com/article/149-liveconnect-certificate-and-proxy-setup">setup guide</a>)'
          [text]="certLink"
        >
        </op-clipboard>
        <div class="qr-code-section">
          <p class="hint">Or scan QR code for direct download</p>
          <img class="qr-code" src="/images/{{ qrCodeFilename }}" alt="ObservePoint QR code" />
        </div>
      </form>
    </div>
  </ng-template>

</op-modal>
