<button mat-button *ngIf="buttonLabel" [matMenuTriggerFor]="labeledMenu">{{buttonLabel}}</button>
<button
  *ngIf="icon"
  mat-icon-button
  [matMenuTriggerFor]="labeledMenu"
  aria-label="Open menu button"
>
<mat-icon>{{icon}}</mat-icon>
</button>
<mat-menu
  class="labeled-menu-overlay"
  #labeledMenu="matMenu"
  xPosition="before"
  yPosition="below"
  [overlapTrigger]="false"
>
  <div class="menu-header">
    <span>{{menuLabel}}</span>
  </div>
  <mat-divider></mat-divider>
  <div class="button-container">
    <button
      *ngFor="let item of menuItems"
      mat-menu-item
      class="labeled-menu-item"
      [disabled]="item.disabled"
      (click)="item.onClick($event)"
    >
      {{item.label}}
    </button>
  </div>
</mat-menu>
