import { Component, EventEmitter, Input, Output, OnInit, OnChanges } from '@angular/core';
import { ILabel } from '@app/components/shared/services/label.service';
import { IUser } from '@app/moonbeamModels';
import { EProductType, EStandardsSelectorType } from '../op-standards-selector/op-standards-selector.constants';
import { IStandardsSelectorItem } from '../op-standards-selector/op-standards-selector.models';
import { IOpTab } from '../op-tabs/op-tabs.models';
import { EStandardsTabs } from './standards-tab.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'standards-tab',
  templateUrl: './standards-tab.component.html',
  styleUrls: ['./standards-tab.component.scss']
})
export class StandardsTabComponent implements OnInit, OnChanges {

  @Input() selectedRules: IStandardsSelectorItem[] = [];
  @Input() selectedConsentCats: IStandardsSelectorItem[] = [];
  @Input() selectedAlerts: IStandardsSelectorItem[] = [];
  @Input() privacyEnabled: boolean = true;
  @Input() productType: EProductType = EProductType.AUDIT;
  @Input() createDataSource: boolean = false;
  @Input() explanationText: string = '';
  @Input() learnMoreLink: string = '';
  @Input() labels: ILabel[] = [];
  @Input() journeyId: number = 0;
  @Input() auditId: number = 0;
  @Input() activeTab: EStandardsTabs;
  @Input() runId: number;
  @Input() user: IUser;
  @Output() rulesUpdated: EventEmitter<IStandardsSelectorItem[]> = new EventEmitter();
  @Output() consentCatsUpdated: EventEmitter<IStandardsSelectorItem[]> = new EventEmitter();
  @Output() alertsUpdated: EventEmitter<IStandardsSelectorItem[]> = new EventEmitter();

  hasLoaded: boolean = false;
  tabs: IOpTab[] = [];
  EProductType = EProductType;
  EStandardsTabs = EStandardsTabs;
  EStandardsSelectorType = EStandardsSelectorType;

  constructor() {}

  ngOnInit() {
    this.activeTab = this.activeTab
      ? this.activeTab
      : this.productType === EProductType.JOURNEY
      ? EStandardsTabs.Rules
      : EStandardsTabs.Alerts;

    this.createDataSource = this.auditId === 0 && this.journeyId === 0;
  }

  ngOnChanges(): void {
    this.createTabs();
  }

  handleHasLoaded() {
    this.hasLoaded = true;
  }

  createTabs(): void {
    this.tabs = [
      {
        name: `Tag & Variable Rules (${this.selectedRules.length})`,
        path: EStandardsTabs.Rules
      }
    ];

    if (this.privacyEnabled) {
      this.tabs.unshift({
        name: `Consent Categories (${this.selectedConsentCats.length})`,
        path: EStandardsTabs.ConsentCategories
      });
    }

    if (this.productType === EProductType.AUDIT) {
      this.tabs.unshift({
        name: `Alerts (${this.selectedAlerts.length})`,
        path: EStandardsTabs.Alerts
      });
    }
  }

  handleTabClicked(path: EStandardsTabs): void {
    this.activeTab = path;
  }
}
