<div class="page-summary-charts">
  <div class="widget-container">
    <div class="widget-container widget-nowrap">
      <split-card [topLabel]="widgetPageScanned.topLabel"
                  [topChangeMeaning]="widgetPageScanned.topChangeMeaning"
                  [state]="widgetState"
                  [topTooltip]="'A count of pages scanned in this audit/run.'"
                  [metricType]="widgetPageScanned.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="pagesFilteredCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPageScanned.metricType"
                  [disableHighlightFromClick]="true"
                  class="pages-scanned-card">
        <div class="widget-pages-details">
          <div class="page-counts">
            <span class="pages-filtered-count">{{ pagesFilteredCount | number }}</span>
            <span *ngIf="widgetState === 'filtered'">&nbsp; of {{ pagesTotalCount | number }}</span>
          </div>
          <filter-spinner [state]="widgetState"></filter-spinner>
        </div>
      </split-card>
      <split-card [topLabel]="widgetAvgPageLoadTime.topLabel"
                  [topChangeContent]="widgetAvgPageLoadTime.topChangeContent"
                  [topChangeMeaning]="widgetAvgPageLoadTime.topChangeMeaning"
                  [bottomHandler]="widgetAvgPageLoadTime.bottomHandler"
                  [haveEnoughData]="widgetAvgPageLoadTimeSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="PageLoadWidgetTooltip"
                  [metricType]="widgetAvgPageLoadTime.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetAvgPageLoadTime.metricType"
                  [disableHighlightFromClick]="true"
                  class="average-page-load-time-card">
        <sparkline-chart-insight [meaningOfUp]="'negative'"
                                 [meaningOfNoChange]="'positive'"
                                 [label]="'since last run'"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetAvgPageLoadTimeSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetBrokenInitialPages.topLabel"
                  [topChangeContent]="widgetBrokenInitialPages.topChangeContent"
                  [topChangeMeaning]="widgetBrokenInitialPages.topChangeMeaning"
                  [topHandler]="widgetBrokenInitialPages.topHandler"
                  [bottomHandler]="widgetBrokenInitialPages.bottomHandler"
                  [activated]="isFilteredByBrokenInitialPages"
                  [state]="widgetState"
                  [haveEnoughData]="widgetBrokenInitialPagesSparklineData.length > 1"
                  [topTooltip]="'Total number of initial pages which have a 0, 400+ or 500+ HTTP status code.' + (widgetState === EFilterSpinnerState.Filtered ? ' (Filtered)' : '')"
                  [metricType]="widgetBrokenInitialPages.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetBrokenInitialPages.metricType"
                  [disableHighlightFromClick]="true"
                  class="broken-initial-pages-card">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetBrokenInitialPagesSparklineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
    <div class="widget-container widget-nowrap">
      <split-card [topLabel]="widgetBrokenFinalPages.topLabel"
                  [topChangeContent]="widgetBrokenFinalPages.topChangeContent"
                  [topChangeMeaning]="widgetBrokenFinalPages.topChangeMeaning"
                  [topHandler]="widgetBrokenFinalPages.topHandler"
                  [bottomHandler]="widgetBrokenFinalPages.bottomHandler"
                  [activated]="isFilteredByBrokenFinalPages"
                  [state]="widgetState"
                  [haveEnoughData]="widgetBrokenFinalPagesSparklineData.length > 1"
                  [topTooltip]="'Total number of final pages which have a 0, 400+ or 500+ HTTP status code.' + (widgetState === EFilterSpinnerState.Filtered ? ' (Filtered)' : '')"
                  [metricType]="widgetBrokenFinalPages.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetBrokenFinalPages.metricType"
                  [disableHighlightFromClick]="true"
                  class="broken-final-pages-card">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetBrokenFinalPagesSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetPagesWithBrokenLinks.topLabel"
                  [topChangeContent]="widgetPagesWithBrokenLinks.topChangeContent"
                  [topChangeMeaning]="widgetPagesWithBrokenLinks.topChangeMeaning"
                  [topHandler]="widgetPagesWithBrokenLinks.topHandler"
                  [bottomHandler]="widgetPagesWithBrokenLinks.bottomHandler"
                  [activated]="isFilteredByPagesWithBrokenLinks"
                  [state]="widgetState"
                  [haveEnoughData]="widgetPagesWithBrokenLinksSparklineData.length > 1"
                  topTooltip="Count of all pages that have broken links pointing to other pages."
                  [metricType]="widgetPagesWithBrokenLinks.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPagesWithBrokenLinks.metricType"
                  [disableHighlightFromClick]="true"
                  class="pages-with-broken-links-card">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetPagesWithBrokenLinksSparklineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
  </div>
</div>
<p class="web-vitals-label">WEB VITALS - 75TH PERCENTILE
  <mat-icon
            (mouseenter)="webVitalsIconMouseEnter()"
            (mouseleave)="webVitalsMouseHandle($event)"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            class="material-icons-outlined">help_outline</mat-icon>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="webVitalsIconHovered"
    cdkConnectedOverlayPush
    [cdkConnectedOverlayPositions]="webVitalsTooltipPositionPairs"
    [cdkConnectedOverlayWidth]="240"
  >
    <div class="page-summary-web-vitals-75-percentile-tooltip-wrapper" (mouseenter)="webVitalsTooltipMouseEnter()"
         (mouseleave)="webVitalsIconMouseLeave()">
      <div class="page-summary-web-vitals-75-percentile-tooltip">
        To classify the overall performance of all scanned pages, we use the 75th percentile value based on Google's
        standard. These are stable Core Web Vital metric.
        <a class="learn-more-link"
           href="https://help.observepoint.com/en/articles/9291892-observepoint-web-vitals-guide" target="_blank">Learn
                                                                                                                  more.</a>
      </div>
    </div>
  </ng-template>
</p>
<div class="page-summary-charts">
  <div class="widget-container">
    <div class="widget-container widget-nowrap">
      <split-card [topLabel]="widgetLargestContentfulPaint.topLabel"
                  [topChangeContent]="widgetLargestContentfulPaint.topChangeContent"
                  [topChangeMeaning]="widgetLargestContentfulPaint.topChangeMeaning"
                  [bottomHandler]="widgetLargestContentfulPaint.bottomHandler"
                  [haveEnoughData]="widgetLargestContentfulPaintSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="WEB_VITALS_WIDGET_TOOLTIPS[EWebVitalsMetricType.LargestContentfulPaint]"
                  [metricType]="widgetLargestContentfulPaint.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetLargestContentfulPaint.metricType"
                  [disableHighlightFromClick]="true"
                  [richTooltip]="true"
                  [cardFooterVisible]="true"
                  class="median-largest-contentful-paint-card">
        <op-sparkline-chart-insight-colorized [meaningOfUp]="'negative'"
                                              [meaningOfNoChange]="'positive'"
                                              [label]="'since last run'"
                                              [dataLoaded]="sparklineDataLoaded"
                                              [digitsInfo]="widgetLargestContentfulPaint.digitsInfo"
                                              [uniqueId]="widgetLargestContentfulPaint.uniqueId"
                                              [boundaries]="widgetLargestContentfulPaintBoundaries"
                                              [dataPoints]="widgetLargestContentfulPaintSparklineData">
        </op-sparkline-chart-insight-colorized>

        <ng-container overlayTooltip>
          <op-web-vitals-widget-tooltip
            [tooltip]="EWebVitalsMetricType.LargestContentfulPaint"></op-web-vitals-widget-tooltip>
        </ng-container>

        <ng-container cardFooter>
          <op-box-plot-mini-chart (click)="widgetLargestContentfulPaint.fullscreenChartHandler()"
                                  [dataLoaded]="sparklineDataLoaded"
                                  [config]="widgetLargestContentfulPaint.boxPlotDataConfig"></op-box-plot-mini-chart>
        </ng-container>
      </split-card>

      <split-card [topLabel]="widgetFirstContentfulPaint.topLabel"
                  [topChangeContent]="widgetFirstContentfulPaint.topChangeContent"
                  [topChangeMeaning]="widgetFirstContentfulPaint.topChangeMeaning"
                  [bottomHandler]="widgetFirstContentfulPaint.bottomHandler"
                  [haveEnoughData]="widgetFirstContentfulPaintSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="WEB_VITALS_WIDGET_TOOLTIPS[EWebVitalsMetricType.FirstContentfulPaint]"
                  [metricType]="widgetFirstContentfulPaint.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetFirstContentfulPaint.metricType"
                  [disableHighlightFromClick]="true"
                  [richTooltip]="true"
                  [cardFooterVisible]="true"
                  class="median-first-contentful-paint-card">
        <op-sparkline-chart-insight-colorized [meaningOfUp]="'negative'"
                                              [meaningOfNoChange]="'positive'"
                                              [label]="'since last run'"
                                              [dataLoaded]="sparklineDataLoaded"
                                              [digitsInfo]="widgetFirstContentfulPaint.digitsInfo"
                                              [uniqueId]="widgetFirstContentfulPaint.uniqueId"
                                              [boundaries]="widgetFirstContentfulPaintBoundaries"
                                              [dataPoints]="widgetFirstContentfulPaintSparklineData">
        </op-sparkline-chart-insight-colorized>

        <ng-container overlayTooltip>
          <op-web-vitals-widget-tooltip
            [tooltip]="EWebVitalsMetricType.FirstContentfulPaint"></op-web-vitals-widget-tooltip>
        </ng-container>

        <ng-container cardFooter>
          <op-box-plot-mini-chart (click)="widgetFirstContentfulPaint.fullscreenChartHandler()"
                                  [dataLoaded]="sparklineDataLoaded"
                                  [config]="widgetFirstContentfulPaint.boxPlotDataConfig"></op-box-plot-mini-chart>
        </ng-container>
      </split-card>
      <split-card [topLabel]="widgetTimeToFirstByte.topLabel"
                  [topChangeContent]="widgetTimeToFirstByte.topChangeContent"
                  [topChangeMeaning]="widgetTimeToFirstByte.topChangeMeaning"
                  [bottomHandler]="widgetTimeToFirstByte.bottomHandler"
                  [haveEnoughData]="widgetTimeToFirstByteSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="WEB_VITALS_WIDGET_TOOLTIPS[EWebVitalsMetricType.TimeToFirstByte]"
                  [metricType]="widgetTimeToFirstByte.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetTimeToFirstByte.metricType"
                  [disableHighlightFromClick]="true"
                  [richTooltip]="true"
                  [cardFooterVisible]="true"
                  class="median-time-to-first-byte-card">
        <op-sparkline-chart-insight-colorized [meaningOfUp]="'negative'"
                                              [meaningOfNoChange]="'positive'"
                                              [label]="'since last run'"
                                              [dataLoaded]="sparklineDataLoaded"
                                              [digitsInfo]="widgetTimeToFirstByte.digitsInfo"
                                              [uniqueId]="widgetTimeToFirstByte.uniqueId"
                                              [boundaries]="widgetTimeToFirstByteBoundaries"
                                              [dataPoints]="widgetTimeToFirstByteSparklineData">
        </op-sparkline-chart-insight-colorized>

        <ng-container overlayTooltip>
          <op-web-vitals-widget-tooltip [tooltip]="EWebVitalsMetricType.TimeToFirstByte"></op-web-vitals-widget-tooltip>
        </ng-container>

        <ng-container cardFooter>
          <op-box-plot-mini-chart (click)="widgetTimeToFirstByte.fullscreenChartHandler()"
                                  [dataLoaded]="sparklineDataLoaded"
                                  [config]="widgetTimeToFirstByte.boxPlotDataConfig"></op-box-plot-mini-chart>
        </ng-container>
      </split-card>
      <split-card [topLabel]="widgetCumulativeLayoutShift.topLabel"
                  [topChangeContent]="widgetCumulativeLayoutShift.topChangeContent"
                  [topChangeMeaning]="widgetCumulativeLayoutShift.topChangeMeaning"
                  [bottomHandler]="widgetCumulativeLayoutShift.bottomHandler"
                  [haveEnoughData]="widgetCumulativeLayoutShiftSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="WEB_VITALS_WIDGET_TOOLTIPS[EWebVitalsMetricType.CumulativeLayoutShift]"
                  [metricType]="widgetCumulativeLayoutShift.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetCumulativeLayoutShift.metricType"
                  [disableHighlightFromClick]="true"
                  [richTooltip]="true"
                  [cardFooterVisible]="true"
                  class="median-cumulative-layout-shift-card">
        <op-sparkline-chart-insight-colorized [meaningOfUp]="'negative'"
                                              [meaningOfNoChange]="'positive'"
                                              [label]="'since last run'"
                                              [dataLoaded]="sparklineDataLoaded"
                                              [digitsInfo]="widgetCumulativeLayoutShift.digitsInfo"
                                              [uniqueId]="widgetCumulativeLayoutShift.uniqueId"
                                              [boundaries]="widgetCumulativeLayoutShiftBoundaries"
                                              [dataPoints]="widgetCumulativeLayoutShiftSparklineData">
        </op-sparkline-chart-insight-colorized>

        <ng-container overlayTooltip>
          <op-web-vitals-widget-tooltip
            [tooltip]="EWebVitalsMetricType.CumulativeLayoutShift"></op-web-vitals-widget-tooltip>
        </ng-container>

        <ng-container cardFooter>
          <op-box-plot-mini-chart (click)="widgetCumulativeLayoutShift.fullscreenChartHandler()"
                                  [dataLoaded]="sparklineDataLoaded"
                                  [config]="widgetCumulativeLayoutShift.boxPlotDataConfig"></op-box-plot-mini-chart>
        </ng-container>
      </split-card>
    </div>
  </div>
</div>
<div class="page-summary-charts">
  <div class="chart-container">
    <horizontal-bar-chart *ngIf="pageLoadTimeChartData"
                          [title]="pageLoadTimeChartTitle"
                          [data]="pageLoadTimeChartData"
                          [state]="pageLoadBarChartState"
                          (filterSelected)="toggleLoadTimeFilter($event)"
                          uniqueIdentifier="chart-1"
                          class="page-load-time-chart">
    </horizontal-bar-chart>
  </div>
  <div class="chart-container">
    <horizontal-bar-chart *ngIf="initialPageStatusCodesChartData"
                          [title]="initialPageStatusCodesChartTitle"
                          [data]="initialPageStatusCodesChartData"
                          [state]="initialPageStatusCodeBarChartState"
                          (filterSelected)="toggleInitialPageStatusCodeFilter($event)"
                          uniqueIdentifier="chart-2"
                          class="initial-page-status-codes-chart">
    </horizontal-bar-chart>
  </div>
  <div class="chart-container">
    <horizontal-bar-chart *ngIf="finalPageStatusCodesChartData"
                          [title]="finalPageStatusCodesChartTitle"
                          [data]="finalPageStatusCodesChartData"
                          [state]="finalPageStatusCodeBarChartState"
                          (filterSelected)="toggleFinalPageStatusCodeFilter($event)"
                          uniqueIdentifier="chart-3"
                          class="final-page-status-codes-chart">
    </horizontal-bar-chart>
  </div>
</div>

<div class="table-wrap" #pageSummaryTableScrollTo>
  <div class="section-title-wrap">
    <div class="title-spinner-wrap">
      <filter-spinner [state]="tableState"></filter-spinner>
      <p class="section-title">Pages Scanned</p>
      <op-audit-report-export-menu
        class="audit-report-export-menu"
        [auditId]="auditId"
        [runId]="runId"
        [tableCompletedState]="tableState"
        [transformSortingToSnakeCase]="false"
        [config]="exportPagesConfig">
      </op-audit-report-export-menu>
    </div>

    <op-switchable-column-menu [columnConfig]="PagesTableSettingItems"
                               [storageKey]="CommonPagesConfigLocalStorageKey"
                               [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
  </div>

  <div class="table-scroll-wrapper">
    <table mat-table
           matSort
           resizeableTable
           [dataSource]="dataSource"
           [class.updating]="tableState === EFilterSpinnerState.Loading"
           class="page-summary-table"
           [matSortActive]="TableColumn.PageUrl"
           matSortDirection="asc"
           matSortStart='desc'>
      <ng-container [matColumnDef]="TableColumn.PageUrl">
        <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>INITIAL PAGE URL</th>
        <td mat-cell
            *matCellDef="let page"
            class="no-left-padding"
        >
          <div class="url-cell-wrap">
            <div class="url-cell-text" [opTableCellTooltipPrefix]="getPageUrlTooltipPrefix(page)"
                 [opTableCellTooltip]="page.url">
              <div class="url-text">{{ page.url|hideProtocol }}</div>
            </div>
          </div>
          <div class="open-drawer-icon-wrapper">
            <mat-icon class="open-drawer-icon">ios_share</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
        <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>FINAL PAGE URL</th>
        <td mat-cell
            *matCellDef="let page"
            class="no-left-padding">
          <div class="url-cell-wrap">
            <div class="url-cell-text" [opTableCellTooltipPrefix]="getPageUrlTooltipPrefix(page)"
                 [opTableCellTooltip]="page.finalPageUrl">
              <div class="url-text">{{ page.finalPageUrl|hideProtocol }}</div>
            </div>
          </div>
          <div class="open-drawer-icon-wrapper">
            <mat-icon class="open-drawer-icon">ios_share</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.PageStatusCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="initial_page_status_code" class="center-text fixed-80"
        >INITIAL PAGE<br>STATUS CODE
        </th>
        <td mat-cell
            *matCellDef="let page"
            class="center-text"
            [matTooltip]="page.initialPageStatusCodeTooltip"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
            [ngClass]="page.initialPageStatusCodeClass">
          {{ page.initialPageStatusCode }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.RedirectCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-70"
        ># OF<br>REDIRECTS
        </th>
        <td mat-cell *matCellDef="let page" class="center-text"
            [ngClass]="page.redirectCountClass">{{ page.redirectCount }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-80"
        >FINAL PAGE<br>STATUS CODE
        </th>
        <td mat-cell
            *matCellDef="let page"
            class="center-text"
            [matTooltip]="page.finalPageStatusCodeTooltip"
            matTooltipPosition="above"
            matTooltipShowDelay="300"
            [ngClass]="page.finalPageStatusCodeClass">
          {{ page.finalPageStatusCode }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.PageLoadTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-70"
            [matTooltip]="PageLoadColumnTooltip"
            [matTooltipPosition]="'above'"
        >PAGE LOAD<br>TIME (sec)
        </th>
        <td mat-cell *matCellDef="let page" class="center-text" [ngClass]="page.loadTimeClass">{{ page.loadTime }}</td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.Size">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-70"
        >PAGE<br>SIZE (mb)
        </th>
        <td mat-cell *matCellDef="let page" class="center-text">{{ page.size }}</td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.LargestContentfulPaint">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-text fixed-110"
            [matTooltip]="WEB_VITALS_WIDGET_TOOLTIPS[EWebVitalsMetricType.LargestContentfulPaint]"
            [matTooltipPosition]="'above'"
        >LARGEST CONTENTFUL<br>PAINT (sec)
        </th>
        <td mat-cell *matCellDef="let page" class="center-text"
            [ngClass]="page.largestContentfulPaintClass">{{ page.largestContentfulPaint }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.FirstContentfulPaint">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-text fixed-110"
            [matTooltip]="WEB_VITALS_WIDGET_TOOLTIPS[EWebVitalsMetricType.FirstContentfulPaint]"
            [matTooltipPosition]="'above'"
        >FIRST CONTENTFUL<br>PAINT (sec)
        </th>
        <td mat-cell *matCellDef="let page" class="center-text"
            [ngClass]="page.firstContentfulPaintClass">{{ page.firstContentfulPaint }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.TimeToFirstByte">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-text fixed-100"
            [matTooltip]="WEB_VITALS_WIDGET_TOOLTIPS[EWebVitalsMetricType.TimeToFirstByte]"
            [matTooltipPosition]="'above'"
        >TIME TO FIRST<br>BYTE (ms)
        </th>
        <td mat-cell *matCellDef="let page" class="center-text"
            [ngClass]="page.timeToFirstByteClass">{{ page.timeToFirstByte }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="TableColumn.CumulativeLayoutShift">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-text fixed-110"
            [matTooltip]="WEB_VITALS_WIDGET_TOOLTIPS[EWebVitalsMetricType.CumulativeLayoutShift]"
            [matTooltipPosition]="'above'"
        >CUMULATIVE LAYOUT<br>SHIFT
        </th>
        <td mat-cell *matCellDef="let page" class="center-text"
            [ngClass]="page.cumulativeLayoutShiftPaintClass">{{ page.cumulativeLayoutShift }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
      <tr mat-row *matRowDef="let page; columns: displayedColumns$ | async;"
          [ngClass]="{ 'open-in-page-details': page.id === pageIdOpenInPageDetails }"
          (click)="openPageDetails(page)"></tr>
    </table>
  </div>

  <mat-paginator class="summary-paginator"
                 [length]="totalNumPages"
                 [pageSize]="pageSize"
                 [hidePageSize]="true">
  </mat-paginator>
</div>
