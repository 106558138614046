import * as angular from 'angular';

import { ILabel } from '@app/components/shared/services/label.service';
import {
  IRuleReportResult,
  IRuleReportSerializer
} from '@app/components/reporting/rules/rule.models';
import { AngularNames, Names } from '@app/moonbeamConstants';
import { IApiService } from '@app/components/api/apiService';
import { IExportData } from '@app/components/utilities/utilitiesService';
import { RuleResultType } from '@app/components/reporting/rules/rule.models';
import {
  IManualJourneyRule,
  RDCJourneyControlRequest
} from '../../creator/live-connect/manual-journey/manualJourneyRecorderController';
import { IRunRuleDetails } from '@app/components/manage/cards/report-card-list/report-card-list.models';
import { environment } from '@app/environments/environment';

export enum RDCManualJourneyStatuses {
  'initialized' = 5,
  'started' = 10,
  'stopped' = 15,
  'finished' = 16,
  'aborted' = 20,
  'failed' = 21,
  'processed' = 25,
  'rulesProcessing' = 27,
  'rulesReprocessing' = 28,
  'enforced' = 30
}

export interface IManualJourney {
  id: number;
  name: string;
  osOfDevice: string;
  versionOfOs: string;
  ruleDetails?: IRunRuleDetails;
  labels: Array<ILabel>;

  journeyId: number;
  proxyInfo: string;
  certificate: IManualJourneyCertificate;
  status: RDCManualJourneyStatuses;
  requestedAt: Date;
  startedAt: Date;
  completedAt: Date;
  manageSSL: boolean;
}

export interface IInitializedManualJourney {
  journeyId: number;
  runId: number;
  name: string;
  proxyInfo: IManualJourneyProxyInfo;
  certificate: IManualJourneyCertificate;
}

export interface IManualJourneyProxyInfo {
  interface: string;
  port: number;
  authorization: IManualJourneyAuthorization;
}

export interface IManualJourneyAuthorization {
  login: string;
  password: string;
}

export interface IManualJourneyCertificate {
  name: string;
  location: string;
}

export interface IManualJourneyStep {
  id: number;
  runId: number;
  sequence: number;
  actionType: number;
  name: string;
  note: string;
}

export interface IManualJourneyStepUpdateRequest {
  name: string;
  notes: string;
}

export interface IManualJourneyTag {
  id: string;
  tagId?: number;
  category?: number;
  version?: string;
  journeyRequestIds?: Array<string>;
  name: string;
  account: string;
  icon: string;
  stepId: number;
  variables: Array<IManualJourneyTagVariable>;
  stub?: boolean;
}

export interface IManualJourneyTagVariable {
  order: number;
  name: string;
  value: string;
  description?: string;
}

export interface IManualJourneyRequestLog {
  id: string;
  timestamp: number;
  sequenceNumber?: number; //generated
  stepId: number;
  journeyTagIds?: Array<string>;
  requestLog: string;
  enabledSsl?: boolean;
  requestInfo: Array<IManualJourneyRequestLogProp>;
  responseInfo: Array<IManualJourneyRequestLogProp>;
}

export interface IManualJourneyRequestLogProp {
  propName: string;
  propValue: any;
}

export interface IManualJourneyRunControlRequest {
  request: RDCJourneyControlRequest;
}

export interface IManualJourneyRulesReprocessingResponse {
  startedReprocessing: boolean;
}

export interface IManualJourneyRuleResults {
  globalRulesResult: Array<IRuleReportResult>;
  actionRulesResult: { [actionId: string]: Array<IRuleReportResult> };
}

export enum EManualJourneyIdentifiers {
  IncludeActions = 'includeActions',
  IncludeRequestLog = 'includeRequestLog',
  IncludeRuleResults = 'includeRuleResults',
  IncludeTagSummary = 'includeTagSummary',
  IncludeTagDetails = 'includeTagDetails'
}

export interface IManualJourneyExportParams {
  [EManualJourneyIdentifiers.IncludeActions]: boolean,
  [EManualJourneyIdentifiers.IncludeRequestLog]: boolean,
  [EManualJourneyIdentifiers.IncludeRuleResults]: boolean,
  [EManualJourneyIdentifiers.IncludeTagSummary]: boolean,
  [EManualJourneyIdentifiers.IncludeTagDetails]: boolean
}

export const name = 'ManualJourneyService';

export class ManualJourneyService {
  static $inject = [
    AngularNames.http,
    Names.Services.api,
    Names.Services.rulesReportSerializer
  ];

  lastStepIndex: number;

  constructor(
    private $http: angular.IHttpService,
    private apiService: IApiService,
    private ruleReportSerializer: IRuleReportSerializer
  ) {
  }

  root: string = environment.apiUploadAppUrl;
  rootV3: string = environment.apiV3Url;

  getManualJourneys(
    deviceProfileId: number,
    additionalFields?: string[]
  ): angular.IPromise<Array<IManualJourney>> {
    var params = {
      additionalFields: additionalFields ? additionalFields.join(',') : ''
    };
    return this.apiService.handleResponse(
      this.$http.get(`${this.root}manual-journeys/${deviceProfileId}/runs`, {
        params: additionalFields ? params : {}
      })
    );
  }

  initializeAManualJourney(
    deviceProfileId: number
  ): angular.IPromise<IInitializedManualJourney> {
    return this.apiService.handleResponse(
      this.$http.post(`${this.root}manual-journeys/${deviceProfileId}/`, {})
    );
  }

  controlAManualJourney(
    deviceProfileId: number,
    manualJourneyId: number,
    command: RDCJourneyControlRequest
  ): angular.IPromise<any> {
    return this.apiService.handleResponse(
      this.$http.post(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/control`,
        {request: command}
      )
    );
  }

  addManualJourneyStep(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<any> {
    return this.apiService.handleResponse(
      this.$http.post(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/actions`,
        null
      )
    );
  }

  deleteManualJourneyStep(
    deviceProfileId: number,
    manualJourneyId: number,
    stepId: number
  ): angular.IPromise<any> {
    return this.apiService.handleResponse(
      this.$http.delete(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/actions/${stepId}`
      )
    );
  }

  getAManualJourney(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<IManualJourney> {
    return this.apiService.handleResponse(
      this.$http.get(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}`
      )
    );
  }

  updateAManualJourneyName(
    deviceProfileId: number,
    manualJourneyId: number,
    name: string
  ): angular.IPromise<IManualJourney> {
    return this.apiService.handleResponse(
      this.$http.patch(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}`,
        {name: name}
      )
    );
  }

  updateAManualJourneyManageSSL(
    deviceProfileId: number,
    manualJourneyId: number,
    manageSSL: boolean
  ): angular.IPromise<IManualJourney> {
    return this.apiService.handleResponse(
      this.$http.patch(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}`,
        {manageSSL: manageSSL}
      )
    );
  }

  removeManualJourney(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<boolean> {
    return this.apiService
      .handleResponse(
        this.$http.delete(
          `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}`
        )
      )
      .then(() => true);
  }

  exportManualJourneyExcelV3(
    deviceProfileId: number,
    manualJourneyId: number,
    params: IManualJourneyExportParams
  ): angular.IPromise<IExportData> {

    return this.apiService.handleResponse<IExportData>(
      this.$http.post<IExportData>(
          `${this.rootV3}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/exports/full`,
          params
        ));
  }

  getManualJourneyLabels(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<Array<ILabel>> {
    return this.apiService.handleResponse(
      this.$http.get(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/labels`
      )
    );
  }

  updateManualJourneyLabels(
    deviceProfileId: number,
    manualJourneyId: number,
    labels: Array<ILabel>
  ): angular.IPromise<Array<ILabel>> {
    return this.apiService.handleResponse(
      this.$http.put(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/labels`,
        labels
      )
    );
  }

  deleteManualJourneyLabel(
    deviceProfileId: number,
    manualJourneyId: number,
    labelId: number
  ): angular.IPromise<any> {
    return this.apiService.handleResponse(
      this.$http.delete(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/labels/${labelId}`
      )
    );
  }

  getManualJourneyProcessedRules(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<Array<IManualJourneyRule>> {
    return this.apiService
      .handleResponse(
        this.$http.get(
          `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/rules/report`
        )
      )
      .then((ruleResult: IManualJourneyRuleResults) => {
        if (!ruleResult.globalRulesResult) return [];
        return ruleResult.globalRulesResult.map(r => {
          return {
            id: r.ruleId,
            name: r.name,
            resultType: r.resultType,
            report: this.serializeRuleReportResult(r)
          };
        });
      });
  }

  reprocessManualJourneyRules(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<IManualJourneyRulesReprocessingResponse> {
    return this.apiService.handleResponse(
      this.$http.post(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/rules/reprocess`,
        {}
      )
    );
  }

  getManualJourneyRequestLogs(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<Array<IManualJourneyRequestLog>> {
    return this.apiService
      .handleResponse(
        this.$http.get(
          `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/request-logs`
        )
      )
      .then((logs: Array<IManualJourneyRequestLog>) => {
        return logs.reduce(
          (
            newLogs: IManualJourneyRequestLog[],
            aLog: IManualJourneyRequestLog,
            index: number
          ) => {
            aLog.sequenceNumber = index + 1;
            newLogs.push(aLog);
            return newLogs;
          },
          []
        );
      });
  }

  getManualJourneySteps(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<Array<IManualJourneyStep>> {
    return this.apiService.handleResponse(
      this.$http.get(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/actions`
      )
    );
  }

  updateManualJourneyStep(
    deviceProfileId: number,
    manualJourneyId: number,
    stepId: number,
    stepUpdate: IManualJourneyStepUpdateRequest
  ): angular.IPromise<IManualJourneyStep> {
    return this.apiService.handleResponse(
      this.$http.patch(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/actions/${stepId}`,
        stepUpdate
      )
    );
  }

  getManualJourneyTags(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<Array<IManualJourneyTag>> {
    return this.apiService.handleResponse(
      this.$http.get(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/tags`
      )
    );
  }

  confirmUserPresence(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<Array<IManualJourneyTag>> {
    return this.apiService.handleResponse(
      this.$http.put(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/activity`,
        {}
      )
    );
  }

  getManualJourneyRequestFilters(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<Array<string>> {
    return this.apiService.handleResponse(
      this.$http.get(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/request-filters`
      )
    );
  }

  updateManualJourneyRequestFilters(
    deviceProfileId: number,
    manualJourneyId: number,
    filters: Array<string>
  ): angular.IPromise<Array<string>> {
    return this.apiService.handleResponse(
      this.$http.put(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/request-filters`,
        filters
      )
    );
  }

  private serializeRuleReportResult(r: IRuleReportResult) {
    switch (r.resultType) {
      case RuleResultType.notApplied:
        return this.ruleReportSerializer.serializeNotAppliedRuleReports([r])[0];
      case RuleResultType.passed:
        return this.ruleReportSerializer.serializePassedRuleReports([r])[0];
      case RuleResultType.failed:
        return this.ruleReportSerializer.serializeFailedRuleReports([r])[0];
    }
  }

  serializeRequestLogProps(
    props: Array<IManualJourneyRequestLogProp>
  ): Array<IManualJourneyRequestLogProp> {
    if (!props) return [];
    props = this.serializeRequestLogSpecificProp(props, 'Headers');
    props = this.serializeRequestLogSpecificProp(props, 'Cookies');
    return props;
  }

  private serializeRequestLogSpecificProp(
    props: Array<IManualJourneyRequestLogProp>,
    propName: string
  ): Array<IManualJourneyRequestLogProp> {
    if (!props) return [];
    var headersIndex = props.findIndex(p => p.propName == propName);
    if (headersIndex == -1) return props;
    var headersData = props[headersIndex];
    var headersProps = (<string>headersData.propValue).split('\n').map(h => {
      let data = h.split(': ');
      if (data.length != 2)
        return <IManualJourneyRequestLogProp>{
          propName: data.toString(),
          propValue: data.toString()
        };
      return <IManualJourneyRequestLogProp>{
        propName: data[0],
        propValue: data[1]
      };
    });
    props.splice(headersIndex, 1);
    return props.concat(headersProps);
  }

  demoManualJourneys = [
    {
      id: 1,
      name: 'Name1 Name1 Name1 Name1 Name1 Name1 Name1 ',
      osOfDevice: 'Android',
      versionOfOs: 'Version! Version! Version! Version! Version! Version!',
      ruleDetails: {
        globalRuleOverview: {passed: 0, failed: 0, notApplied: 0, broken: 0},
        actionRuleOverview: {passed: 0, failed: 0, notApplied: 0, broken: 0}
      },
      labels: [{id: 87, name: 'test'}, {id: 55, name: 'best1'}],
      journeyId: 1,
      proxyInfo: 'proxyInfo',
      certificate: {name: 'name', location: 'location'},
      status: 30,
      requestedAt: new Date(),
      startedAt: new Date(),
      completedAt: new Date(),
      manageSSL: true
    },
    {
      id: 2,
      name: 'Name2',
      osOfDevice: 'Android',
      versionOfOs: '10.1',
      ruleDetails: {
        globalRuleOverview: {passed: 0, failed: 1, notApplied: 0, broken: 0},
        actionRuleOverview: {passed: 0, failed: 0, notApplied: 0, broken: 0}
      },
      labels: [{id: 87, name: 'test'}],
      journeyId: 1,
      proxyInfo: 'proxyInfo',
      certificate: {name: 'name', location: 'location'},
      status: 30,
      requestedAt: new Date(),
      startedAt: new Date(),
      completedAt: new Date(),
      manageSSL: true
    },
    {
      id: 3,
      name: 'Name3',
      osOfDevice: 'Android',
      versionOfOs: '10.2',
      ruleDetails: {
        globalRuleOverview: {passed: 0, failed: 2, notApplied: 0, broken: 0},
        actionRuleOverview: {passed: 0, failed: 0, notApplied: 0, broken: 0}
      },
      labels: [],
      journeyId: 1,
      proxyInfo: 'proxyInfo',
      certificate: {name: 'name', location: 'location'},
      status: 30,
      requestedAt: new Date(),
      startedAt: new Date(),
      completedAt: new Date(),
      manageSSL: true
    },
    {
      id: 4,
      name: 'Name3',
      osOfDevice: 'Windows',
      versionOfOs: '10.3',
      ruleDetails: {
        globalRuleOverview: {passed: 0, failed: 1, notApplied: 0, broken: 0},
        actionRuleOverview: {passed: 0, failed: 0, notApplied: 0, broken: 0}
      },
      labels: [],
      journeyId: 1,
      proxyInfo: 'proxyInfo',
      certificate: {name: 'name', location: 'location'},
      status: 30,
      requestedAt: new Date(),
      startedAt: new Date(),
      completedAt: new Date(),
      manageSSL: true
    }
  ];

  demoSteps: Array<IManualJourneyStep> = [
    {
      id: 1,
      runId: 0,
      sequence: 0,
      actionType: 1,
      name: 'Step 1',
      note: 'Some note 1'
    },
    {
      id: 2,
      runId: 0,
      sequence: 1,
      actionType: 1,
      name: 'Step 2',
      note: 'Some note 2'
    },
    {id: 3, runId: 0, sequence: 2, actionType: 1, name: 'Step 3', note: ''}
  ];
}
