<div #frequency class="op-run-frequency" (mouseenter)="showHint(frequency)" (mouseleave)="hideHint(frequency)">
  <mat-form-field floatLabel="always" appearance="outline">
    <mat-label>How often should the audit run?</mat-label>
    <mat-select [formControl]="presetType" [matTooltip]="selectedOptionLabel" matTooltipPosition="above">
      <ng-container *ngFor="let option of scheduleOptions">
        <mat-divider *ngIf="option.isDivider"></mat-divider>
        <mat-option *ngIf="!option.isDivider" [value]="option.value">{{ option.label }}</mat-option>
      </ng-container>
    </mat-select>

    <!-- Added a <div> around the button because when the button is disabled the tooltip was not showing. -->
    <div
      class="btn-container"
      [matTooltip]="scheduleStateTooltip"
      matTooltipPosition="above"
      matSuffix
    >
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
        [disabled]="scheduleState === ERecurrenceScheduleState.NotScheduled"
      >
        <mat-icon *ngIf="scheduleStateIcon === 'pause_card'" [svgIcon]="scheduleStateIcon"></mat-icon>
        <mat-icon *ngIf="scheduleStateIcon !== 'pause_card'" #stateIcon>{{ scheduleStateIcon }}</mat-icon>
      </button>
    </div>
    <mat-menu #menu="matMenu" class="schedule-state-menu">
      <button mat-menu-item *ngIf="scheduleState === ERecurrenceScheduleState.Scheduled" (click)="pauseSchedule()">
        <mat-icon class="schedule-state-icon pause-icon" [svgIcon]="'pause_card'"></mat-icon>
        Pause Schedule
      </button>
      <button mat-menu-item *ngIf="scheduleState === ERecurrenceScheduleState.Paused" (click)="resumeSchedule()">
        <mat-icon class="schedule-state-icon">event_repeat</mat-icon>
        Resume Schedule
      </button>
    </mat-menu>

    <mat-hint class="frequency-hint">
      <mat-icon *ngIf="frequencyHint.showIcon" class="material-icons-outlined hint-icon">info</mat-icon>
      {{ frequencyHint.text }}
      <a *ngIf="frequencyHint.showLink" href="https://help.observepoint.com/en/articles/9101166-audit-best-practices" target="_blank">
        Learn more.
      </a>
    </mat-hint>
  </mat-form-field>
</div>