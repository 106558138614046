import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { AlertReportingService } from '../alert/alert-reporting.service';
import { EAlertSearchSortBy } from '../alert/alert.constants';
import { IAlertRequestItem, IAlertsSearchAssignmentBody, IAlertsSearchAssignmentsResponse, IAlertSummaryAlert, IAlertSummaryResponse } from '../alert/alert.models';
import { AlertService } from '../alert/alert.service';
import { ICommonTableState } from '../shared/components/export-report/export-reports.models';
import { EProductType } from '../shared/components/op-standards-selector/op-standards-selector.constants';
import { EAlertSummaryTableColumn } from './reports/alert-summary/alert-summary.constants';

export interface IAlertChangeState {
  alertsHaveChanged: boolean;
  hasSnapshotAlerts: boolean;
  hasAssignedAlerts: boolean;
  changes: string[];
}

@Injectable()
export class AuditReportChangesService {

  private alertChangeState: IAlertChangeState = {
    alertsHaveChanged: false,
    hasSnapshotAlerts: false,
    hasAssignedAlerts: false,
    changes: []
  }

  private params = {
    size: 1000,
    page: 0,
    sortBy: EAlertSearchSortBy.ALERT_NAME,
    sortDesc: false
  };

  private requestBody: IAlertsSearchAssignmentBody = {
    targetItem: {
      itemType: EProductType.AUDIT,
      itemId: null,
      isAssigned: true
    }
  };

  private alertsTablePaginationState: ICommonTableState = {
    page: 0,
    size: 1000,
    sortBy: EAlertSummaryTableColumn.alertName,
    sortDesc: false
  };

  onAlertsChangedSubject: BehaviorSubject<IAlertChangeState> = new BehaviorSubject(this.alertChangeState);
  onAlertsChanged$: Observable<IAlertChangeState> = this.onAlertsChangedSubject.asObservable();

  constructor(
    private alertService: AlertService,
    private alertReportingService: AlertReportingService,
  ) {}

  checkForAlertChanges(auditId: number, runId: number): void {
    this.getSnapshotAndCurrentAlerts(auditId, runId);
  }

  private getSnapshotAndCurrentAlerts(auditId: number, runId: number): void {
    this.alertChangeState.alertsHaveChanged = false;
    this.onAlertsChangedSubject.next(this.alertChangeState);
    this.requestBody.targetItem.itemId = auditId;

    forkJoin([
      this.alertReportingService.getAlertSummary(auditId, runId, this.alertsTablePaginationState, {}),
      this.alertService.getAlertsForAssignment(this.params, this.requestBody)
    ]).subscribe(([snapshot, current]: [IAlertSummaryResponse, IAlertsSearchAssignmentsResponse]) => {
      const snapshotAlerts = snapshot.alerts;
      const currentAlerts = current.alerts;

      this.alertChangeState.hasSnapshotAlerts = !!snapshotAlerts.length;
      this.alertChangeState.hasAssignedAlerts = !!currentAlerts.length;

      // initial sanity check: if lengths are different then
      // changes have taken place and audit should be re-run
      if (snapshotAlerts.length !== currentAlerts.length) {
        this.alertChangeState.alertsHaveChanged = true;
        this.onAlertsChangedSubject.next(this.alertChangeState);
        return;
      }

      for (var i = 0; i < snapshotAlerts.length; i++) {
        const snapshotAlert = snapshotAlerts[i];
        const currentAlert = currentAlerts.find(alert => alert.id === snapshotAlert.id);

        // can't find an alert that matches so we know a change occurred
        if (!currentAlert) {
          this.alertChangeState.alertsHaveChanged = true;
          this.onAlertsChangedSubject.next(this.alertChangeState);
          return;
        }
      }

      const currentAlertsRequests = currentAlerts.map(alert => this.alertService.getAlertById(alert.id));

      if (currentAlertsRequests.length) {
        forkJoin(currentAlertsRequests).subscribe((alerts: IAlertRequestItem[]) => {
          this.checkForSpecificDifferences(snapshotAlerts, alerts);
        });
      } else {
        this.onAlertsChangedSubject.next(this.alertChangeState);
      }
    });
  }

  private checkForSpecificDifferences(snapshot: IAlertSummaryAlert[], current: IAlertRequestItem[]) {
    for (var i = 0; i < snapshot.length; i++) {
      const snapshotAlert = snapshot[i];
      const currentAlert = current.find(alert => alert.id === snapshotAlert.id);

      if (this.alertIsDifferent(snapshotAlert, currentAlert)) {
        this.alertChangeState.alertsHaveChanged = true;
        this.onAlertsChangedSubject.next(this.alertChangeState);
        return;
      }
    }

    this.alertChangeState.alertsHaveChanged = false;
    this.onAlertsChangedSubject.next(this.alertChangeState);
  }

  private alertIsDifferent(snapshot: IAlertSummaryAlert, current: IAlertRequestItem): boolean {
    const filtersV0Sorted = this.sortObjectKeysRecursively(snapshot.config.filtersV0);
    const currentFiltersV0Sorted = this.sortObjectKeysRecursively(current.filtersV0);

    if (snapshot.config.name !== current.name) {
      this.alertChangeState.changes['name'] = `snapshot name: ${snapshot.config.name} <--> current name: ${current.name}`;
      return true;
    }

    if (snapshot.config.metricType !== current.metricType) {
      this.alertChangeState.changes['metricType'] = `snapshot metricType: ${snapshot.config.metricType} <--> current metricType: ${current.metricType}`;
      return true;
    }

    if (snapshot.config.metricChangeType !== current.metricChangeType) {
      this.alertChangeState.changes['metricChangeType'] = `snapshot metricChangeType: ${snapshot.config.metricChangeType} <--> current metricChangeType: ${current.metricChangeType}`;
      return true;
    }

    if (snapshot.config.targetValueComparator.operator !== current.targetValueComparator.operator) {
      this.alertChangeState.changes['operator'] = `snapshot operator: ${snapshot.config.targetValueComparator.operator} <--> current operator: ${current.targetValueComparator.operator}`;
      return true;
    }

    if (snapshot.config.targetValueComparator.targetValue !== current.targetValueComparator.targetValue) {
      this.alertChangeState.changes['targetValue'] = `snapshot targetValue: ${snapshot.config.targetValueComparator.targetValue} <--> current targetValue: ${current.targetValueComparator.targetValue}`;
      return true;
    }

    if (JSON.stringify(filtersV0Sorted) !== JSON.stringify(currentFiltersV0Sorted)) {
      this.alertChangeState.changes['filtersV0'] = `snapshot filtersV0: ${JSON.stringify(filtersV0Sorted)} <--> current filtersV0: ${JSON.stringify(currentFiltersV0Sorted)}`;
      return true;
    }

    return false;
  }

  private sortObjectKeysRecursively(obj: any): any {
    if (typeof obj != 'object' || obj instanceof Array) {
      return obj;
    }

    const keys = Object.keys(obj);
    keys.sort();

    let sortedObj = {};
    for (var i = 0; i < keys.length; i++) {
      sortedObj[keys[i]] = this.sortObjectKeysRecursively(obj[keys[i]])
    }

    return sortedObj;
  }
}