import { ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ECCEditTabs } from './cc-edit.constants';
import { ConsentCategoriesUIConstants } from '../consent-categories.constants';
import { ICCRequestDomainTableRow } from './cc-edit.models';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import {
  EConsentCategoryType,
  EDomainType,
  ENameType,
  ICmpData,
  IConsentCategory,
  IConsentCategoryBase,
  IConsentCategoryCookie,
  IConsentCategoryRequestDomain,
  IConsentCategoryTag,
  IRequestDomain
} from '@app/components/consent-categories/consent-categories.models';
import {
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { catchError, debounceTime, distinctUntilChanged, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, of, Subject } from 'rxjs';
import { ConsentCategoryCreateComponent } from '@app/components/consent-categories/cc-create/cc-create.component';
import { OpConfirmModalComponent, OpModalService } from '@app/components/shared/components/op-modal';
import { IButton } from '@app/models/commons';
import { ConsentCategoriesEditService } from '@app/components/consent-categories/cc-edit/cc-edit.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarErrorComponent } from '../../shared/components/snackbars/snackbar-error/snackbar-error.component';
import {
  SnackbarSuccessComponent
} from '@app/components/shared/components/snackbars/snackbar-success/snackbar-success.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { ModalWithHotkeySupport } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.models';
import { EKeyCodes } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';
import { OpStandardsSelectorService } from '@app/components/shared/components/op-standards-selector/op-standards-selector.service';

export interface IEditConsentCategoryModal {
  consentCategoryId: number;
  tab: ECCEditTabs;
  newCC?: boolean;
  newCopyCC?: boolean;
}

export class NameErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control?.dirty && control.invalid;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'edit',
  templateUrl: './cc-edit.component.html',
  styleUrls: ['./cc-edit.component.scss'],
  providers: [ConsentCategoriesEditService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsentCategoriesEditComponent implements OnInit, OnDestroy, ModalWithHotkeySupport {
  nameErrorStateMatcher = new NameErrorStateMatcher();
  EConsentCategoryType = EConsentCategoryType;
  ECCEditTabs = ECCEditTabs;
  CONSTANTS: any = { ...ConsentCategoriesUIConstants };
  consentCategory: IConsentCategory;
  consentCategoryId: number;
  consentCategoryHasBeenDeleted = false;
  ccType: string;
  ccReverseType: string;
  cmpData: ICmpData;
  isLoaded = false;
  ccTabs: { name: string, display: string, value: number }[];
  editForm: UntypedFormGroup;
  activeTab: ECCEditTabs = ECCEditTabs.cookies;
  originalName: string;

  nameFormTouched: boolean = false;
  cookiesFormTouched: boolean = false;
  tagsFormTouched: boolean = false;
  requestsFormTouched: boolean = false;

  rightFooterButtons: IButton[] = [
    {
      label: 'Save & Close',
      action: this.save.bind(this),
      primary: true,
      opSelector: 'cc-edit-save',
      disabled: true
    }
  ];

  existingName: string;
  existingNotes: string;
  existingCookies: IConsentCategoryCookie[];
  existingTags: IConsentCategoryTag[];
  existingRequestDomains: IConsentCategoryRequestDomain[];
  existingDefaultCC: boolean;
  accountTags: IUiTag[] = [];

  showAddNotesField: boolean = false;
  notesPlaceholder = 'Add a description about what this Consent Category is for...';

  newCcMode: boolean;
  newCopyCC: boolean;
  initialNameLoaded: boolean = false;

  private destroy$ = new Subject<void>();
  private nameSubject = new Subject();

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public payload: IEditConsentCategoryModal,
    private formBuilder: UntypedFormBuilder,
    private ccService: ConsentCategoriesService,
    private modalService: OpModalService,
    private ccEditService: ConsentCategoriesEditService,
    private uiTagService: UiTagService,
    private modalEscapeService: ModalEscapeService,
    private snackbar: MatSnackBar,
    private standardsSelectorService: OpStandardsSelectorService,
  ) {

    this.activeTab = this.payload.tab ?? ECCEditTabs.cookies;

    // make sure the save button is enabled when creating a new CC
    this.newCcMode = this.payload.newCC;

    this.newCopyCC = this.payload?.newCopyCC || false;

    this.rightFooterButtons[0].disabled = !this.newCcMode;

    if (dialogRef) {
      this.activeTab = payload.tab;
      this.consentCategoryId = payload.consentCategoryId;
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  initForm() {
    this.editForm = this.formBuilder.group({
      name: ['', Validators.required],
      type: [{ value: EConsentCategoryType.APPROVED, disabled: true }, Validators.required],
      notes: [''],
      cookies: [[]],
      tags: [[]],
      requestDomains: [[]],
      isDefaultCC: [false],
    });

    this.name.valueChanges.pipe(
      tap(() => {
        this.rightFooterButtons[0].disabled = true;

        if (this.editForm.pristine === false) {
          this.nameFormTouched = true;
        }
      }),
      debounceTime(500),
      takeUntil(this.destroy$)
    ).subscribe((value: string) => {
      if (this.initialNameLoaded) {
        if (this.originalName !== value) {
          this.ccService.isConsentCategoryNameUnique(value).subscribe((isValid: boolean) => {
            if (!isValid) {
              this.name.setErrors({duplicate: { value: this.name.value}});
              this.nameFormTouched = true;
            } else {
              this.name.setErrors(null);
              this.rightFooterButtons[0].disabled = false;
            }

            this.rightFooterButtons[0].disabled = this.name.hasError('duplicate') || this.name.value === '';
          });
        } else {
          this.name.setErrors(null);
        }
      }
      this.initialNameLoaded = true;
    });

    this.notes.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.editForm.pristine === false) {
        this.nameFormTouched = true;
        this.rightFooterButtons[0].disabled = false;
      }
    });

    this.editForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.rightFooterButtons[0].disabled = !this.editForm.valid;
    });
  }

  // Enable IsDefaultCC checkbox if:
  // 1. there are no other default CCs
  // 2. the existing default CCs have the same type as the new one
  async enableIsDefaultCheckbox(ccType: string) {
    let defaultCCtype: string = await this.standardsSelectorService.getConsentCatDefaultType();
    if(defaultCCtype && (ccType?.toLocaleLowerCase() !== defaultCCtype)) {
      this.editForm.get('isDefaultCC').setValue(false);
      this.editForm.get('isDefaultCC').disable();
    } else {
      this.editForm.get('isDefaultCC').enable();
    }
  }

  initData(): void {
    this.viewInit();
    this.uiTagService.getAllTagsData().subscribe(([accountTags]) => {
      this.accountTags = accountTags.sort((a: any, b: any) => (a.name?.toLowerCase() > b.name?.toLowerCase()) ? 1 : -1);
    });
  }

  initListeners(): void {
    // Update Consent Category name on input value change
    this.nameSubject.pipe(
      debounceTime(400),
      takeUntil(this.destroy$),
      distinctUntilChanged(),
    ).subscribe(ccData => {
      this.updateCC(ccData); 
    });

    this.ccEditService.existingConsentCategory$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((cc) => {
      this.isLoaded = true;

      if (cc) {
        this.consentCategoryHasBeenDeleted = false;
        this.consentCategory = cc;
        this.originalName = cc.name;
        this.name.setValue(cc.name);
        this.existingName = cc.name;
        this.type.setValue(cc.type);
        this.isDefaultCC.setValue(cc.isDefaultCC);
        this.existingDefaultCC = cc.isDefaultCC;
        this.cmpData = cc.cmpData;
        this.notes.setValue(cc.notes);
        this.existingNotes = cc.notes;
        this.ccType = cc.type === EConsentCategoryType.APPROVED ? 'approve' : 'unapprove';
        this.ccReverseType = cc.type === EConsentCategoryType.APPROVED ? 'unapprove' : 'approve';
        this.enableIsDefaultCheckbox(cc.type);
      } else {
        this.consentCategoryHasBeenDeleted = true;
      }
    });

    this.ccEditService.existingCookies$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(cookies => {
      this.ccTabs[0].value = cookies.length;
      this.existingCookies = cookies;
      let cookiesWithTypes = cookies.map(cookie => (
        {
          ...cookie,
          nameType: cookie.nameMatchAny ? ENameType.ANY : (cookie.nameRegex ? ENameType.REGEX : ENameType.EXACT),
          nameRegex: cookie.nameType === ENameType.REGEX,
          nameMatchAny: cookie.nameType === ENameType.ANY,
          domainType: cookie.domainRegex ? EDomainType.REGEX : cookie.domain?.length > 0 ? EDomainType.EXACT : EDomainType.ANY,
          domainRegex: cookie.domainType === EDomainType.REGEX
        }
      ));

      this.cookies.setValue(cookiesWithTypes);
    });

    this.ccEditService.existingTags$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(tags => {
      //This is ugly... i know.... i'm not happy about it either but it works
      //and is deep copying the array correctly
      this.ccTabs[1].value = tags.length;
      this.existingTags = JSON.parse(JSON.stringify(tags));
      this.tags.setValue(JSON.parse(JSON.stringify(tags)));
    });

    this.ccEditService.existingRequestDomains$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((requestDomains: IRequestDomain[]) => {
      this.ccTabs[2].value = requestDomains.length;
      this.existingRequestDomains = requestDomains;
      let requestDomainsWithTypes = requestDomains.map(domain => ({
        ...domain,
        domainType: domain.regex ? EDomainType.REGEX : domain.domain?.length > 0 ? EDomainType.EXACT : EDomainType.ANY,
        regex: domain.domainType === EDomainType.REGEX
      }));
      this.requestDomains.setValue(requestDomainsWithTypes);
    });
  }

  buildTabs(): void {
    this.ccTabs = [
      {
        name: ECCEditTabs.cookies,
        display: 'Cookies',
        value: this.cookies.value.length
      }, {
        name: ECCEditTabs.tags,
        display: 'Tags',
        value: this.tags.value.length
      }, {
        name: ECCEditTabs.requestDomains,
        display: 'Request Domains & Geolocations',
        value: this.requestDomains.value.length

      }
    ];
  }

  changeTab(name: ECCEditTabs): void {
    this.activeTab = name;
  }

  tabIsActive(name: ECCEditTabs): boolean {
    return this.activeTab === name;
  }

  confirmCancel(): void {
    this.dialogRef.close();
  }
  confirmCancelCopy(): boolean {

    if(!this.newCopyCC) return false;

    const rightFooterButtons = [
      {
        label: 'Yes, cancel',
        action: () => {
          this.removeCcCopy();
        },
        primary: false
      },
      {
        label: 'Continue Editing',
        action: () => {},
        primary: true
      },
    ];

    this.modalService.openConfirmModal({
      data: {
        title: 'Cancel Copy',
        messages: ['You are about to lose your changes. Cancel anyway?'],
        rightFooterButtons
      }
    });
  }
  async removeCcCopy(): Promise<void> {
    await this.ccService.deleteConsentCategory(this.consentCategoryId).toPromise();
    this.dialogRef.close();
  }

  @HostListener('window:keydown.escape')
  cancel(): void {

    // If the user is canceling a copy then prompt the user.
    if(this.newCopyCC) {
      this.confirmCancelCopy();
      return;
    }

    // If no changes then close the dialog
    if (!this.changes()) {
      this.confirmCancel();
      return;
    }

    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    const confirmConfig = {
      title: 'Unsaved Changes',
      messages: ['Changes to this consent category will be lost when leaving. Are you sure you want to stop editing?'],
      rightFooterButtons: [{
        label: 'Yes, discard changes',
        action: this.confirmCancel.bind(this),
        primary: true,
        opSelector: 'cc-edit-confirm-cancel',
      }]
    };
    this.modalService.openModal(OpConfirmModalComponent, { data: confirmConfig })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        this.modalEscapeService.remove(index);
        if (confirm) this.confirmCancel();
      });
  }

  changes(): boolean {
    return this.nameFormTouched
      || this.cookiesFormTouched
      || this.tagsFormTouched
      || this.requestsFormTouched;
  }

  openConsentCategoryModal(modalConfig = {}): void {
    const cachedCounts = {
      cookies: this.cookies.value.length,
      tags: this.tags.value.length,
      requestDomains: this.requestDomains.value.length
    };

    this.modalService.openFixedSizeModal(ConsentCategoryCreateComponent, modalConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res?.updateValues) {
          this.cookies.setValue(this.formatImportWizardCookies(res?.cookies));
          this.ccTabs[0].value = res?.cookies.length;

          this.tags.setValue(this.formatImportWizardTags(res?.tags));
          this.ccTabs[1].value = res?.tags.length;

          this.requestDomains.setValue(this.formatImportWizardRequestDomains(res?.requestDomains));
          this.ccTabs[2].value = res?.requestDomains.length;

          this.showItemsAddedSnackbar(cachedCounts, res);
        }
      });
  }

  formatImportWizardCookies(payload: IConsentCategoryCookie[]): any[] {
    this.cookiesFormTouched = true;
    this.rightFooterButtons[0].disabled = false;

    return payload.map(cookie => {
      return {
        name: cookie.name,
        domain: cookie.domain,
        nameRegex: !!cookie.nameRegex,
        nameMatchAny: !!cookie.nameMatchAny,
        domainRegex: !!cookie.domainRegex,
      };
    });
  }

  formatImportWizardTags(payload): any[] {
    this.tagsFormTouched = true;
    this.rightFooterButtons[0].disabled = false;

    return payload.map(tag => {
      let formattedTag = {
        tagName: tag.tagName,
        tagId: tag.tagId,
        accounts: tag.accounts
      };

      if (tag.hasOwnProperty('anyAccount')) {
        formattedTag['anyAccount'] = tag.anyAccount;
      }

      return formattedTag;
    });
  }

  formatImportWizardRequestDomains(payload): any[] {
    this.requestsFormTouched = true;
    this.rightFooterButtons[0].disabled = false;

    return payload.map(requestDomain => {
      return {
        domain: requestDomain.domain,
        regex: requestDomain.regex,
        locationIds: requestDomain.locationIds,
        anyLocation: requestDomain.anyLocation,
      };
    });
  }

  showItemsAddedSnackbar(cachedCounts, data): void {
    const numCookiesAdded = data.cookies.length - cachedCounts.cookies;
    const numTagsAdded = data.tags.length - cachedCounts.tags;
    const numRequestDomainsAdded = data.requestDomains.length - cachedCounts.requestDomains;
    const countStrings = [];

    if (numCookiesAdded) countStrings.push(`${numCookiesAdded} cookie${numCookiesAdded > 1 ? 's' : ''}`);
    if (numTagsAdded) countStrings.push(`${numTagsAdded} tag${numTagsAdded > 1 ? 's' : ''}`);
    if (numRequestDomainsAdded) {
      countStrings.push(
        `${numRequestDomainsAdded} request domain${numRequestDomainsAdded > 1 ? 's' : ''} & geolocation${numRequestDomainsAdded > 1 ? 's' : ''}`
      );
    }

    const message = `Added ${countStrings.join(', ')} to this consent category.`;

    this.snackbar.openFromComponent(SnackbarSuccessComponent, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {message}
    });
  }

  updateCC(body) {
    this.ccService.updateConsentCategory(this.consentCategoryId, body).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => { });
  }

  updateName(event) {
    this.name.setValue(event.target.value);
  }

  updateNotes(event) {
    this.notes.setValue(event.target.value);
  }

  getCCModalData() {
    return {
      id: this.consentCategoryId,
      name: this.name.value,
      type: this.type.value,
      editing: true,
      cookies: this.cookies.value,
      tags: this.tags.value,
      requestDomains: this.requestDomains.value,
      isDefaultCC: this.isDefaultCC.value,
      cmpData: this.cmpData,
    };
  }

  nameChanged(): boolean {
    return this.existingName !== this.name.value;
  }

  notesChanged(): boolean {
    return this.existingNotes !== this.notes.value;
  }

  isDefaultChanged(): boolean {
    return this.existingDefaultCC !== this.isDefaultCC.value;
  }

  save(): void {
    const updates = [];

    // general CC info
    if (this.nameChanged() || this.notesChanged() || this.isDefaultChanged() || this.nameFormTouched) {
      let updatedCC: IConsentCategoryBase = {
        name: this.editForm.value.name,
        type: this.editForm.value.type,
        notes: this.editForm.value.notes,
        id: this.consentCategoryId,
        isDefaultCC: this.editForm.value.isDefaultCC,
        cmpData: this.cmpData,
      };

      updates.push(
        this.ccService
          .updateConsentCategory(this.consentCategoryId, updatedCC)
          .pipe( 
            catchError(error => of(error)),
            finalize(() => {
              // if the default consent category is changed, then invalidate the CC cache so we pull from disk.
              if(this.isDefaultChanged()) {
                this.standardsSelectorService.invalidateConsentCatCache();
              }
            })
          )
      );
    }

    if (this.cookiesFormTouched) {
      const updatedCookies = this.cookies.value
        .sort((a: any, b: any) => (a.index > b.index) ? 1 : -1)
        .map(cookie => ({
          name: cookie.name || '',
          nameType: cookie.nameMatchAny ? ENameType.ANY : (cookie.nameRegex ? ENameType.REGEX : ENameType.EXACT),
          domain: cookie.domain || '',
          domainType: cookie.domainRegex ? EDomainType.REGEX : cookie.domain?.length > 0 ? EDomainType.EXACT : EDomainType.ANY,
        }))
        .map(cookie => {
          if (cookie.nameType === ENameType.ANY) delete cookie.name;
          if (cookie.domainType === EDomainType.ANY) delete cookie.domain;
          return cookie;
        });

      const cookiesPatch = this.ccService.compareConsentCategories(this.existingCookies, updatedCookies);

      updates.push(
        this.ccService
          .patchConsentCategoryCookies(this.consentCategoryId, cookiesPatch)
          .pipe(catchError(error => of(error)))
      );
    }

    // tags tab
    if (this.tagsFormTouched) {
      const existingTagsForPatch = this.existingTags.map(val => {
        let tag = {
          tagId: val.tagId,
          accounts: val.accounts
        };

        if (val.hasOwnProperty('anyAccount')) {
          tag['anyAccount'] = val.anyAccount;
        }

        return tag;
      });

      const updatedTagsForPatch = this.tags.value
        .sort((a: any, b: any) => a.index > b.index ? 1 : -1)
        .map(val => {
          let updatedTag = {
            tagId: val.tagId,
            accounts: val.accounts
          };

          if (val.hasOwnProperty('anyAccount')) {
            updatedTag['anyAccount'] = !val.accounts.length;
          }

          return updatedTag;
        });

      const tagsPatch = this.ccService.compareConsentCategories(existingTagsForPatch, updatedTagsForPatch);
      updates.push(
        this.ccService
          .patchConsentCategoryTags(this.consentCategoryId, tagsPatch)
          .pipe(catchError(error => of(error)))
      );
    }

    // request domains & geolocations tab
    if (this.requestsFormTouched) {
      const updatedRequestDomains = this.requestDomains.value
        .map((domain: ICCRequestDomainTableRow) => {
          const locationIds = domain.hasOwnProperty('locationIds') ?
            domain.locationIds :
            domain.locations.map(location => location.id);

          return {
            domain: !domain.domain && domain.regex ? '.*' : domain.domain?.trim(), // Set 'any' to be regex for any
            domainType: domain.regex ? EDomainType.REGEX : domain.domain?.length > 0 ? EDomainType.EXACT : EDomainType.ANY,
            anyLocation: locationIds.length === 0,
            locationIds: locationIds,
          };
        })
        .sort((a: any, b: any) => (a.index > b.index) ? 1 : -1)
        .map((request) => {
          if (request.domainType === EDomainType.ANY) delete request.domain;

          return request;
        });

      const requestDomainsPatch = this.ccService.compareConsentCategories(this.existingRequestDomains, updatedRequestDomains);

      updates.push(
        this.ccService
          .patchConsentCategoryRequestDomains(this.consentCategoryId, requestDomainsPatch)
          .pipe(catchError(error => of(error)))
      );
    }

    forkJoin(updates).subscribe(res => {
      // show snackbar with any errors
      const messages = res.filter(item => item.hasOwnProperty('errorCode')).map(error => error['errorCode'].message);

      if (messages?.length > 0) {
        this.snackbar.openFromComponent(SnackbarErrorComponent, {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: {message: messages.join(', ')},
        });
      }

      this.dialogRef.close(res);
    });

    // If no updates, handle closing the modal since the forkJoin won't ever fire
    if (updates.length === 0) this.closeCreateModalWhenNoUpdates();
  }

  closeCreateModalWhenNoUpdates(): void {
    this.dialogRef.close();
  }

  updateTabCount(event: { tabName: string, newTabCount: number }) {
    switch (event.tabName) {
      case ECCEditTabs.cookies: {
        this.ccTabs[0].value = event.newTabCount;
        break;
      }
      case ECCEditTabs.tags: {
        this.ccTabs[1].value = event.newTabCount;
        break;
      }
      case ECCEditTabs.requestDomains: {
        this.ccTabs[2].value = event.newTabCount;
        break;
      }
    }
  }

  updateSaveButtonState(emitTab: ECCEditTabs): void {
    switch (emitTab) {
      case ECCEditTabs.cookies:
        this.cookiesFormTouched = true;
        break;

      case ECCEditTabs.tags:
        this.tagsFormTouched = true;
        break;

      case ECCEditTabs.requestDomains:
        this.requestsFormTouched = true;
        break;
    }

    if (this.editForm.valid) {
      this.rightFooterButtons[0].disabled = false;
    }
  }

  getHotkeyHandler(key: EKeyCodes) {
    return key === EKeyCodes.KeyS
      ? () => this.save()
      : null;
  }

  get name(): UntypedFormControl {
    return this.editForm.get('name') as UntypedFormControl;
  }

  get type(): UntypedFormControl {
    return this.editForm.get('type') as UntypedFormControl;
  }

  get notes(): UntypedFormControl {
    return this.editForm.get('notes') as UntypedFormControl;
  }

  get cookies(): UntypedFormControl {
    return this.editForm.get('cookies') as UntypedFormControl;
  }

  get tags(): UntypedFormControl {
    return this.editForm.get('tags') as UntypedFormControl;
  }

  get requestDomains(): UntypedFormControl {
    return this.editForm.get('requestDomains') as UntypedFormControl;
  }

  get isDefaultCC(): UntypedFormControl {
    return this.editForm.get('isDefaultCC') as UntypedFormControl;
  }

  private viewInit() {
    this.ccService.setSelectedCCId(this.consentCategoryId);
    this.buildTabs();
    this.initListeners();
    this.ccEditService.loadExistingData(this.consentCategoryId);
  }
}
