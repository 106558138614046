import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'op-info-panel',
  templateUrl: './op-info-panel.component.html',
  styleUrls: ['./op-info-panel.component.scss']
})
export class OpInfoPanelComponent {

  @Input() expanded: boolean;
}
