<op-filter-bar-v2 [menuItems]="filterBarMenuItems"
                  [service]="emailInboxesFilterBarService"
                  [isSearchByTextEnabled]="true"
                  [validFilterTypes]="validFilterTypes"
                  [hideSearchByRegexOption]="true"
                  searchByTextPlaceholderSuffix="Name"
                  (searchByTextIsEntered)="handleSearchByName($event)"
                  (onMenuClosed)="menuClosed()"
                  menuClass="email-inboxes-filter-menu">
</op-filter-bar-v2>
