import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RuleSetupFormBuilderService } from '../../services/rule-setup-form-builder.service';
import { IUiTag } from '@app/components/tag-database';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-setup-conditions-tab',
  templateUrl: './rule-setup-conditions-tab.component.html',
  styleUrls: ['./rule-setup-conditions-tab.component.scss']
})
export class RuleSetupConditionsTabComponent implements OnInit {

  @Input() formData: UntypedFormGroup;
  @Input() allTags: IUiTag[];
  @Input() hidePageMatching: boolean;

  private destroy$ = new Subject<void>();

  constructor(private formBuilderService: RuleSetupFormBuilderService) {}

  ngOnInit() {
    this.formData.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formBuilderService.updateConditionsTabValidation(this.formData);
        this.formBuilderService.updateConditionsDisabling(this.formData);
      });
  }

  addIfCondition() {
    this.formBuilderService.addIfCondition(this.ifFiltersArray);
  }

  get matchAllFiltersControl(): UntypedFormControl {
    return this.formData?.get('matchAllFilters') as UntypedFormControl;
  }

  get checkTimesControl(): UntypedFormControl {
    return this.formData?.get('checkTimes') as UntypedFormControl;
  }

  get filtersGroup(): UntypedFormGroup {
    return this.formData?.get('filters') as UntypedFormGroup;
  }

  get ifFiltersArray(): UntypedFormArray {
    return this.filtersGroup?.get('If') as UntypedFormArray;
  }

}
