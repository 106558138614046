import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RuleSetupFormBuilderService } from "../../services/rule-setup-form-builder.service";
import { ILabel } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-setup-general-tab',
  templateUrl: './rule-setup-general-tab.component.html',
  styleUrls: ['./rule-setup-general-tab.component.scss']
})
export class RuleSetupGeneralTabComponent implements OnInit {

  @Input() formData: UntypedFormGroup;
  @Input() allLabels: ILabel[];

  @Output() onCreateLabel = new EventEmitter<string[]>();

  private destroy$ = new Subject<void>();

  constructor(private formBuilderService: RuleSetupFormBuilderService) {}

  ngOnInit() {
    this.formData.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.formBuilderService.updateGeneralTabValidation(this.formData);
      });
  }

  get nameControl(): UntypedFormControl {
    return this.formData?.get('name') as UntypedFormControl;
  }

  get isDefaultRuleControl(): UntypedFormControl {
    return this.formData?.get('isDefaultRule') as UntypedFormControl;
  }

}
