import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpModalModule } from '@app/components/shared/components/op-modal/op-modal.module';
import { ReprocessConfirmationSnackbarComponent } from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.component';
import { ReprocessConfirmationSnackbarService } from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.service';
import { SharedModule } from '@app/components/shared/shared.module';
import { MaterialModule } from '@app/components/material/material.module';

@NgModule({
  declarations: [
    ReprocessConfirmationSnackbarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    OpModalModule,
  ],
  providers: [
    ReprocessConfirmationSnackbarService
  ]
})
export class ReprocessConfirmationSnackbarModule {
}
