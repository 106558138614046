import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  CookiesPrivacyRelevantFilters,
  ICookiePrivacyRunOverview,
  ICookiePrivacySummary,
  ICookiesPrivacyRunOverviews
} from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.models';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import {
  AuditReportUrlBuilders,
  EConsentCategoryComplianceStatus
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import {
  UNAPPROVED_CHART_CONFIG
} from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.constants';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map } from 'rxjs/operators';
import { TagHealthTrendsByName } from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import { PrivacyCookiesService } from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.service';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { sortBy } from 'lodash-es';
import { formatNumber } from '@angular/common';
import { Router } from '@angular/router';
import { AuditSummaryRelevantFilters } from '@app/components/audit-reports/reports/audit-summary/audit-summary.models';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import {
  PrivacyWidgetsLoadingStateHandler
} from '@app/components/audit-reports/reports/audit-summary/privacy-section-in-audit-summary/loading-state-handler';

const RelevantFiltersOverlap = AuditSummaryRelevantFilters.filter(ft => CookiesPrivacyRelevantFilters.includes(ft));

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'widgets-cookies',
  templateUrl: './widgets-cookies.component.html',
  styleUrls: ['./widgets-cookies.component.scss']
})
export class WidgetsCookiesComponent extends PrivacyWidgetsLoadingStateHandler implements OnInit, OnChanges, OnDestroy {

  @Input() auditId: number;
  @Input() runId: number;
  @Input() apiFilters: IAuditReportApiPostBody;

  EConsentCategoryComplianceStatus = EConsentCategoryComplianceStatus;

  sparklineDataLoaded: boolean = false;

  private widgetSparklineRunsInfo: ISparklineRunInfo[] = [];

  cookieSummary: ICookiePrivacySummary;
  cookieTrends: ICookiePrivacyRunOverview[];

  widgetWithUnapprovedCookiesPages: ISplitCardChartData = {
    topLabel: 'Pages with Unapproved Cookies',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.explore()
  };
  pagesWithUnapprovedCookiesCount: number;

  widgetUnapprovedCookies: ISplitCardChartData = {
    topLabel: 'Unapproved Cookies',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.explore(),
    bottomHandler: this.openUnapprovedFullscreenChart.bind(this)
  };
  widgetUnapprovedCookiesSparklineData: ISparklineChartData[] = [];

  constructor(
    private router: Router,
    private cookiePrivacyService: PrivacyCookiesService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private modalService: OpModalService,
    private auditReportLoadingService: AuditReportLoadingService,
  ) {
    super(auditReportLoadingService, filterBarService);
  }

  ngOnInit(): void {
    let previousRelevantFilters = [...this.filterBarService.currentFilters.filter(f => RelevantFiltersOverlap.includes(f.type))];
    this.filterBarService.anyFiltersUpdates$.pipe(
      debounceTime(300),
      filter(newFilters => {
        const newRelevantFilters = newFilters.filter(f => RelevantFiltersOverlap.includes(f.type));
        const diff = ArrayUtils.diff(previousRelevantFilters, newRelevantFilters, (f1, f2) => f1.type === f2.type);
        previousRelevantFilters = [...newRelevantFilters];
        if (diff.added.length > 0 || diff.removed.length > 0) {
          return true;
        } else {
          const anyChanges = JSON.stringify(diff.same.array1) !== JSON.stringify(diff.same.array2);
          return anyChanges;
        }
      })
    ).subscribe(newFilters => {
      this.apiFilters = this.filterBarService.generateApiPostBody(newFilters.filter(f => RelevantFiltersOverlap.includes(f.type)));
      this.loadCookies();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let newAuditId = changes?.auditId?.currentValue;
    let newRunId = changes?.runId?.currentValue;
    if (newAuditId || newRunId) {
      this.auditId = newAuditId || this.auditId;
      this.runId = newRunId || this.runId;
      this.loadCookies();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private loadCookies() {
    this.startLoading();
    forkJoin([
      this.cookiePrivacyService.getCookiesPrivacySummary(this.auditId, this.runId, this.apiFilters)
        .pipe(catchError(() => EMPTY)),
      this.cookiePrivacyService.getCookiePrivacyTrends(this.auditId, this.runId, this.apiFilters).pipe(
        map((data: ICookiesPrivacyRunOverviews) => sortBy(data.runs, element => element.runId) as ICookiePrivacyRunOverview[]),
        catchError(() => EMPTY)
      )
    ]).pipe(finalize(() => {
      this.stopLoading();
      this.sparklineDataLoaded = true;
    }))
      .subscribe(([cookieSummary, cookieTrends]) => {
        this.cookieSummary = cookieSummary;
        this.cookieTrends = cookieTrends;
        this.updateWidgets(cookieSummary);
        this.updateSparklines(cookieTrends);
      });
  }

  private updateWidgets(summary: ICookiePrivacySummary) {
    this.pagesWithUnapprovedCookiesCount = summary.filteredPageWithUnapprovedCookieCount;

    this.widgetUnapprovedCookies.topChangeContent = this.formatWidgetContent(summary.filteredUnapprovedCookieCount, summary.totalUnapprovedCookieCount);
    this.widgetUnapprovedCookies.topChangeMeaning = summary.filteredUnapprovedCookieCount === 0
      ? ESplitCardChangeMeaning.NEUTRAL
      : ESplitCardChangeMeaning.NEGATIVE;

  }

  public explore() {
    return () => {
      this.filterBarService.addConsentCategoryStatusFilter(EConsentCategoryComplianceStatus.Unapproved);
      this.router.navigateByUrl(AuditReportUrlBuilders.privacyCookies(this.auditId, this.runId));
    };
  }

  public openUnapprovedFullscreenChart() {
    if (this.widgetUnapprovedCookiesSparklineData.length > 1) {
      this.openFullscreenChart(EConsentCategoryComplianceStatus.Unapproved, this.showTimelineInCharts());
    }
  }

  private showTimelineInCharts() {
    return this.widgetSparklineRunsInfo.length > 1 ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate : undefined;
  }

  private openFullscreenChart(
    trendName: EConsentCategoryComplianceStatus,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ) {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService
      .openFullscreenModal(
        FullscreenChartModalComponent,
        {
          data: {
            timeframeOriginRunCompletion: secondToLastCompletionDate,
            getData: (days: number) => this.getFullscreenChartData(trendName, days),
            getSummaryLines,
            chartConfig: UNAPPROVED_CHART_CONFIG
          }
        }
      )
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  private getFullscreenChartData(trendName: EConsentCategoryComplianceStatus, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.cookiePrivacyService
      .getCookiePrivacyTrend(this.auditId, trendName + '_cookies', days)
      .pipe(
        map((data: TagHealthTrendsByName) => {
          return {
            chartData: data.runs.map(item => {
              return {
                value: item.trendValue,
                date: item.completedAt
              };
            })
          };
        })
      );
  }

  private updateSparklines(dataPoints: ICookiePrivacyRunOverview[]) {
    const unapproved: ISparklineChartData[] = [];
    const runsInfo: ISparklineRunInfo[] = [];
    dataPoints.forEach((dataPoint, index: number) => {
      unapproved.push({value: dataPoint.totalUnapprovedCookieCount, sequence: index});
      runsInfo.push({runId: dataPoint.runId, runCompletionDate: dataPoint.completedAt});
    });
    this.widgetUnapprovedCookiesSparklineData = unapproved;
    this.widgetSparklineRunsInfo = runsInfo;
  }

  private formatWidgetContent(filteredCount: number, totalCount?: number): string {
    const format = (x: number) => formatNumber(x, 'en-US');
    if (!this.isFiltered() || (this.isFiltered() && !totalCount)) {
      return format(filteredCount);
    } else {
      return `${format(filteredCount)} of ${format(totalCount)}`;
    }
  }

  isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.filter(f => RelevantFiltersOverlap.includes(f.type)).length > 0;
  }

}
