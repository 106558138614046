import { Component, Input, SimpleChanges, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { EAlertBellStatus } from '@app/components/alert/alert-bell/alert-bell.enums';
import { AlertComponent } from '@app/components/alert/alert.component';
import {
  IAlertEditModalPayload,
  IAlertQuickCreatePayload,
  IAlertRequestItem,
} from '@app/components/alert/alert.models';
import { IAuditSummaryAlert } from '@app/components/audit/audit.models';
import { OpModalService } from '../op-modal';
import {
  EAlertFilterType,
  EAlertMenuContext,
  EAlertModalType,
  EAlertResultStatus,
  EQuickCreateMode
} from '@app/components/alert/alert.enums';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { AlertMetricType } from '@app/components/alert/alert-logic/alert-logic.enums';
import { AlertQuickCreateComponent } from '@app/components/alert/alert-quick-create/alert-quick-create.component';
import { AlertService } from '@app/components/alert/alert.service';
import { merge, Subject } from 'rxjs';
import { IUser } from '@app/moonbeamModels';
import { takeUntil } from 'rxjs/operators';
import { AuditReportChangesService } from '@app/components/audit-reports/audit-report-changes.service';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import {
  EFreeTrialAdModalType
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import {
  FreeTrialAdModalComponent
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import { IUsageRequestDTO } from '@app/components/usage-v2/usage-v2.models';
import {
  IOpFilterBarV2Filter,
  IOpFilterBarV2MenuItem
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EUsageFilterTypes } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  selector: 'op-widget-bell',
  templateUrl: './op-widget-bell.component.html',
  styleUrls: ['./op-widget-bell.component.scss']
})
export class OpWidgetBellComponent implements OnInit, OnChanges, OnDestroy {

  @Input() auditId: number;
  @Input() runId: number;
  @Input() user: IUser;
  @Input() widgetName: AlertMetricType;
  @Input() widgetIsHovered: boolean = false;
  @Input() disableHighlightFromClick?: boolean;
  // We might receive numbers like "1,251" of type string from report with comma separating thousands,
  // or some other variations of beautified strings.
  @Input() currentValue: number | string;
  @Input() filters: IOpFilterBarV2Filter<any>[] | IOpFilterBarFilter<any>[];
  @Input() menuContext: EAlertMenuContext = EAlertMenuContext.Audit;
  @Input() filterType: EAlertFilterType = EAlertFilterType.V1;

  private destroy$ = new Subject();
  EAlertBellStatus = EAlertBellStatus;
  alerts: IAuditSummaryAlert[] = [];
  status: EAlertBellStatus;
  hoverStatus: EAlertBellStatus;
  alertCount: number;
  tooltip: string;
  isVisitorMode: boolean;

  constructor(
    private modalService: OpModalService,
    private alertReportingService: AlertReportingService,
    private alertService: AlertService,
    private auditReportChangesService: AuditReportChangesService,
    private applicationChromeService: ApplicationChromeService,
  ) {}

  ngOnInit(): void {
    if (this.widgetName) {
      merge(
        this.alertReportingService.alertSummaryMap$,
        this.alertReportingService.accountAlertSummaryMap$,
      ).pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.alerts = this.alertReportingService.getAlertStatusByMetric(this.widgetName);
        this.alertCount = this.alerts.length;
        this.setBellStatuses();
      });
    }

    this.applicationChromeService.isVisitorMode$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((isVisitorMode: boolean) => {
      this.isVisitorMode = isVisitorMode;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.widgetIsHovered && changes.widgetIsHovered.currentValue !== changes.widgetIsHovered.previousValue) {
      this.setBellStatuses();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setBellStatuses(): void {
    const accountLevelAlert = this.alertReportingService.isAccountLevelMetric(this.widgetName);
    const userIsSubscribed = this.alerts.map((alert: IAuditSummaryAlert) => {
      return (alert as IAuditSummaryAlert).isSubscribed;
    }).includes(true);

    // no bell/plus bell - no alerts
    if (!this.alerts.length) {
      this.status = this.widgetIsHovered ? EAlertBellStatus.CreateAlert : EAlertBellStatus.NoConfiguredAlerts;
      this.hoverStatus = EAlertBellStatus.CreateAlert;
      this.tooltip = `Create an alert for ${this.widgetName ? AlertUtils.getMetricConfigByMetricType(this.widgetName).name : ''}`;
      return;
    }

    // outlined bell - alerts exist but user isn't subscribed
    if (this.alerts.length && !userIsSubscribed) {
      const status = (this.alerts[0] as IAuditSummaryAlert).status;
      this.status = status === EAlertResultStatus.Triggered
        ? EAlertBellStatus.ConfiguredAndTriggeredAlerts
        : EAlertBellStatus.NoSubscribedAlerts;
      this.hoverStatus = this.status;
      this.tooltip = `${accountLevelAlert ? '' : 'View History/'}Modify/Subscribe to ${this.alerts.length > 1 ? 'an' : 'this'} alert`;
      return;
    }

    // ringing bell - one or more (count of alerts doesn't matter here)
    if (this.alerts.length && userIsSubscribed) {
      // because we're getting alerts by metric each array will only contain
      // one object so it's safe to hardcode index 0 here
      const status = (this.alerts[0] as IAuditSummaryAlert).status;
      this.status = status === EAlertResultStatus.Triggered
        ? EAlertBellStatus.TriggeredAlerts
        : EAlertBellStatus.ConfiguredAndSubscribedAlerts;
      this.hoverStatus = this.status;
      this.tooltip = `${accountLevelAlert ? '' : 'View History/'}Modify ${this.alerts.length > 1 ? 'an' : 'this'} alert`;
      return;
    }
  }

  openQuickCreateModal(): void {
    // If we are a visitor, open the free trial ad modal
    if (this.isVisitorMode) {
      const data = {
        type: EFreeTrialAdModalType.ALERT
      };

      this.modalService.openModal(FreeTrialAdModalComponent, { data });
      return;
    }

    // If we have alerts for this metric, but are not subscribed to any, open
    // quick create modal to the subscribe view. Otherwise, open create view.
    // @ts-ignore
    const openCreateView = this.alerts.length === 0 || (this.alerts.length > 0 && this.alerts.find((alert: IAuditSummaryAlert) => alert.isSubscribed));
    const modalData = { data: this.makeQuickCreateModalPayload(!!openCreateView) };
    this.modalService.openModal(AlertQuickCreateComponent, modalData).afterClosed().subscribe(() => {
      if (this.auditId && this.runId) {
        this.auditReportChangesService.checkForAlertChanges(this.auditId, this.runId);
      }
    });
  }

  private makeQuickCreateModalPayload(openCreateView: boolean): IAlertQuickCreatePayload {
    return {
      auditId: this.auditId,
      runId: this.runId,
      metricType: this.widgetName,
      currentValue: this.currentValue as number,
      filters: this.filters,
      filterType: this.filterType,
      defaultTab: openCreateView ? EQuickCreateMode.Create : EQuickCreateMode.Subscribe,
    };
  }

  private normaliseCurrentValueToValidNumber(): number {
    if (typeof this.currentValue === 'number') {
      return this.currentValue;
    } else if (typeof this.currentValue === 'string') {
      // For cases when we receive numbers like "1,251" from report with comma separating thousands
      // and for other similar cases trying to remove all special characters
      const noSpecialCharactersString = this.currentValue.replace(/[^0-9.]/g, '');
      const castedNumber = Number(noSpecialCharactersString);
      if (Number.isNaN(castedNumber)) {
        this.logNumberParsingError();
        return 0;
      } else {
        return castedNumber;
      }
    } else {
      this.logNumberParsingError();
      return 0;
    }
  }

  private logNumberParsingError() {
    console.error(`Unexpected currentValue type '${typeof this.currentValue}'`
      + ` for OpWidgetBell: currentValue: '${this.currentValue}', metricType: '${this.widgetName}'`);
  }

  openAlertEditor(alert: IAuditSummaryAlert|IAlertRequestItem): void {
    let data: IAlertEditModalPayload = {
      type: EAlertModalType.Edit,
      alertId: alert.id,
      menuContext: this.menuContext,
      filterType: this.filterType,
      noReprocessOption: this.alertReportingService.isAccountLevelMetric(this.widgetName),
    };

    if (this.auditId) data.auditId = this.auditId;

    this.modalService.openFixedSizeModal(AlertComponent, { disableClose: true, data, autoFocus: false });
  }

  // Either reload the route to the alert and apply filters or edit the selected alert.
  handleNameClick(alert: IAuditSummaryAlert|IAlertRequestItem): void {
    if (!(alert as IAuditSummaryAlert).alertConfigExists) return;
    this.alertService.navigateToAlert(alert, this.auditId, this.runId, this.disableHighlightFromClick);
  }
}
