import { Route, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuditReportContainerFutureState } from './components/audit-reports/audit-report-container.routes';
import { WebJourneyReportFutureState } from './components/web-journey-report/web-journey-report.routes';
import { AdminPortalFutureState } from './components/admin-portal/admin-portal.routes';
import { ComparisonLibraryFutureState } from './components/comparison-library/comparison-library.routes';
import { AccountSettingsFutureState, ManageTagsState } from './components/account-settings/account-settings.routes';
import { LiveConnectFutureState } from './components/live-connect/live-connect.routes';
import { TagDatabaseFutureState } from './components/tag-database/tag-database.routes';
import { ScriptServicesFutureState } from './components/script-services/script-services.routes';
import { FeedbackPortalFutureState } from './components/feedback-portal/feedback-portal.routes';
import { BulkOperationsFutureState } from './components/bulk-operations/bulk-operations.routes';
import { MyCardsFutureState } from './components/manage/cards/manage-cards.routes';
import { LoginRoutes } from './components/login/login.routes';
import { AppComponent } from './components/app/app.component';
import { RulesLibratyFutureState } from './components/rules/rule-library/rule-library.routes';
import { ActionSetLibraryRoutes } from './components/action-set-library/action-set-library.routes';
import { RfmFutureState } from './components/rfm-library/rfm-library.routes';
import { CustomPreloadingStrategy } from './components/core/preloading-strategy';
import { SSOGuard } from './components/core/sso.guard';
import { MyProfileFutureState } from './components/my-profile/my-profile.routes';
import { ConsentCategoriesFutureState } from '@app/components/consent-categories/consent-categories.routes';
import { DataSourcesUrlBuilders } from './components/manage/cards/manage-cards.constants';
import { ExportCenterFutureState } from './components/export-center/export-center.routes';
import { AlertsLibraryState } from '@app/components/alerts-library/alerts-library.routes';
import { TriggeredAlertsState } from './components/triggered-alerts/triggered-alerts.routes';
import { ShareLinksFutureState } from './components/share-links/share-links.routes';
import { SetCookiesFutureState } from './components/set-cookies/set-cookies.routes';
import {
  SharedLinksLibraryState
} from '@app/components/shared-links/shared-links-library.routes';
import { NotificationCenterState } from '@app/components/notifications-center/notification-center.routes';
import { AgGridSampleState } from '@app/components/ag-grid-sample/ag-grid-sample.routes';
import { UsageV2FutureState } from '@app/components/usage-v2/usage-v2.routes';
import { EmailInboxesFutureState } from './components/email-inboxes/email-inboxes.routes';
import { AccountReportsFutureState } from './components/account-reports/account-reports.routes';

const routes: Route[] = [{
  path: '',
  canActivate: [SSOGuard],
  children: [
    LoginRoutes,
    ShareLinksFutureState,
    {
      path: '',
      component: AppComponent,
      children: [
        AgGridSampleState,
        AccountReportsFutureState,
        NotificationCenterState,
        MyCardsFutureState,
        AccountSettingsFutureState,
        ManageTagsState,
        ActionSetLibraryRoutes,
        AlertsLibraryState,
        TriggeredAlertsState,
        AdminPortalFutureState,
        AuditReportContainerFutureState,
        BulkOperationsFutureState,
        ComparisonLibraryFutureState,
        FeedbackPortalFutureState,
        LiveConnectFutureState,
        RfmFutureState,
        RulesLibratyFutureState,
        ScriptServicesFutureState,
        TagDatabaseFutureState,
        WebJourneyReportFutureState,
        MyProfileFutureState,
        ConsentCategoriesFutureState,
        ExportCenterFutureState,
        UsageV2FutureState,
        SetCookiesFutureState,
        SharedLinksLibraryState,
        EmailInboxesFutureState
      ],
    },
    { path: '**', redirectTo: DataSourcesUrlBuilders.sources() }
  ]
}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: CustomPreloadingStrategy,
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always'
    })],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
