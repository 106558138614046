<div class="pages-container" [class.loading]="spinnerState === EFilterSpinnerState.Loading">

  <table mat-table
         matSort
         resizeableTable
         [dataSource]="tableDataSource"
         multiTemplateDataRows
         [matSortActive]="TableColumn.FinalPageStatusCode"
         matSortDirection="desc"
         matSortStart="desc">

    <ng-container [matColumnDef]="TableColumn.PageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>
        INITIAL PAGE URL
      </th>
      <td mat-cell
          *matCellDef="let row"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ row.pageUrl }}">
            <div class="url-text">{{ row.pageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>
        FINAL PAGE URL
      </th>
      <td mat-cell
          *matCellDef="let row"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ row.finalPageUrl }}">
            <div class="url-text">{{ row.finalPageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageLoadTime">
      <th mat-header-cell class="center-text fixed-100"
          *matHeaderCellDef
          mat-sort-header
          [matTooltip]="PageLoadColumnTooltip"
          [matTooltipPosition]="'above'"
      >
        PAGE LOAD TIME (sec)
      </th>
      <td mat-cell class="center-text"
          *matCellDef="let row">
        <span [class]="row.loadTimeClass">{{ row.pageLoadTime }}</span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
      <th mat-header-cell class="center-text fixed-100" *matHeaderCellDef mat-sort-header>
        FINAL PAGE STATUS CODE
      </th>
      <td mat-cell class="center-text" *matCellDef="let row">
        <span [class]="row.statusCodeClass"
              [matTooltip]="PageStatusCodeTooltipMap[row.finalPageStatusCode] || null"
              matTooltipPosition="above"
              matTooltipShowDelay="250"
        >{{ row.finalPageStatusCode }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async;"
        class="item-row"
        [class.opened]="row.pageId === openedPageId"
        (click)="openPageDetails(row)">
    </tr>
  </table>
</div>
<mat-paginator
  [length]="tableState?.pagesTotal"
  [pageSize]="tableState?.pagination.size"
  [pageIndex]="tableState?.pagination.page"
  [hidePageSize]="true"
></mat-paginator>
