<div class="flex-col audits-picker-list">
  <div class="count">{{audits.length}} item{{audits?.length > 1 ? 's' : ''}}</div>

  <div class="flex-col flex-stretch list">
    <div class="flex-row audit-list-header">
      <span class="left-side">
        {{mode === auditsPickerListMode.ADD ? 'Audit Name' : 'To Be Assigned'}}
      </span>
      <span class="right-side">
        <span *ngIf="mode === auditsPickerListMode.ADD; else removeAll"
              class="btn-container"
              op-selector="add-all-btn"
              (click)="onAdd.emit(audits)">
          <span>Add All</span>
          <span class="icon icon-control-point"></span>
        </span>
        <ng-template #removeAll>
          <span class="btn-container" (click)="onRemove.emit()" op-selector="remove-all-btn">
            <span>Remove all</span>
            <span class="icon icon-close-bare"></span>
          </span>
        </ng-template>
      </span>
    </div>
    
    <div class="flex-col flex-stretch body">
      <div class="flex-row audit-info" *ngFor="let audit of audits" [attr.op-selector]="'audit-' + audit.id">
        <span class="left-side">
          {{audit.name}}
        </span>
        <span class="right-side">
          <span class="btn-container"
                *ngIf="mode === auditsPickerListMode.ADD; else removeAudit"
                op-selector="add-btn"
                (click)="onAdd.emit(audit)">
            <span class="icon icon-control-point"></span>
          </span>
          <ng-template #removeAudit>
            <span class="btn-container" op-selector="remove-btn">
              <span class="icon icon-close-bare" (click)="onRemove.emit(audit)"></span>
            </span>
          </ng-template>
        </span>
      </div>

      <div class="no-items" *ngIf="!audits.length && filtering">
        There are no items to be displayed with these filters
      </div>
    </div> 
  </div>
</div>
