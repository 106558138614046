import { environment } from '@app/environments/environment';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { Names } from '@app/moonbeamConstants';
import { HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { flatMap, tap } from 'rxjs/operators';
import {
  IHarJourneyRunCreateRequest,
  IHarJourneyRunCreateResponse,
  IS3PostUploadData
} from '@app/components/live-connect/har-file-ingestor/har-file-ingestor.models';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { isBefore } from 'date-fns';

@Injectable()
export class HarFileIngestorService {
  private rootApiUrl = environment.apiUrl;

  private storageType = StorageType.Local;

  constructor(private api: ApiService,
              private storageService: StorageService) {
  }

  getPostUploadData(manualJourneyId: number): Observable<IS3PostUploadData> {
    return of(
      this.getNotExpiredPostUploadData()
    ).pipe(
      flatMap(savedPostUploadData => {
        if (savedPostUploadData) {
          return of(savedPostUploadData);
        } else {
          return this.api.get(`${this.rootApiUrl}manual-journeys/${manualJourneyId}/har-runs/s3-post-policy`)
            .pipe(tap((data: IS3PostUploadData) => {
              this.savePostUploadData(data);
            }));
        }
      })
    );
  }

  uploadFileToS3(formDataEntries: any, uploadUrl: string): Observable<HttpEvent<Object>> {
    const formData = new FormData();
    for (let key in formDataEntries) {
      if (formDataEntries.hasOwnProperty(key)) {
        formData.append(key, formDataEntries[key]);
      }
    }
    return this.api.post(uploadUrl, formData, {
      observe: 'events',
      reportProgress: true
    });
  }

  saveJourneyRun(manualJourneyId: number, requestBody: IHarJourneyRunCreateRequest): Observable<IHarJourneyRunCreateResponse> {
    return this.api.post(`${environment.apiUrl}manual-journeys/${manualJourneyId}/har-runs`, requestBody);
  }

  private savePostUploadData(data: IS3PostUploadData): void {
    this.storageService.setValue(Names.GlobalStateKeys.fileUploadPostUploadData, data, this.storageType);
  }

  private getNotExpiredPostUploadData(): IS3PostUploadData | null {
    const savedPostUploadData = this.storageService.getValue<IS3PostUploadData>(
      Names.GlobalStateKeys.fileUploadPostUploadData,
      this.storageType
    );
    return savedPostUploadData && this.isNotExpired(savedPostUploadData)
      ? savedPostUploadData
      : null;
  }

  private isNotExpired(data: IS3PostUploadData): boolean {
    const expiresAt = new Date(data.expiresAt);
    const now = new Date();
    return isBefore(now, expiresAt);
  }

}
