import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { ECopyBoxColor } from '@app/components/copy-box/copy-box.enums';

export enum EEmailInboxSuccessType {
  Created,
  Edited,
  Deleted,
  Duplicated,
}

@Component({
  selector: 'op-email-inbox-change-success-snackbar',
  templateUrl: './email-inbox-change-success-snackbar.component.html',
  styleUrls: ['./email-inbox-change-success-snackbar.component.scss']
})
export class EmailInboxChangeSuccessSnackbarComponent {
  readonly EEmailInboxSuccessType = EEmailInboxSuccessType;
  readonly ECopyBoxColor = ECopyBoxColor;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data,
    private snackBarRef: MatSnackBarRef<EmailInboxChangeSuccessSnackbarComponent>,
  ) {
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
