import { Subject } from 'rxjs';
import { IAuditReportApiPostBody } from '../audit-report/audit-report.models';

export interface IFilterableAuditReport {
  /**
   * Inject @see AuditReportFilterBarService
   * Initialize by specifying supported filter list and subscribing to filter updates
   */
  initFilters: () => void;

  /**
   * Handle filter update events. This is usually when data is retrieved from the server,
   * state is updated, and the report template and charts are rebound and redrawn. NOTE:
   * This method can be the handler when subscribing to filter update events
   *
   * @param apiPostBody An object of all currently applied (and valid) filters
   * formatted for the API to be used as the post body
   */
  onFiltersChanged: (apiPostBody: IAuditReportApiPostBody) => void;
}

export abstract class AuditReportBase {

  constructor() {}

  protected onDestroy$: Subject<void> = new Subject();

  destroy() {
    this.onDestroy$.next();
  }
}
