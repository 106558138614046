import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { AccountsService } from '@app/components/account/account.service';
import { IButton } from '@app/models/commons';
import { forkJoin, from } from 'rxjs';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { IUser } from '@app/moonbeamModels';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'share-folders',
  templateUrl: './share-folders.component.html',
  styleUrls: ['./share-folders.component.scss'],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions },
  ]
})
export class ShareFoldersComponent implements OnInit {
  displayedColumns: string[] = ['select', 'name', 'createdAt', 'createdBy'];
  dataSource = new MatTableDataSource();
  selection = new SelectionModel(true, []);
  selectedUsers: any[];
  folders: IFolder[];
  users: IUser[];

  rightFooterButtons: IButton[] = [
    {
      label: 'Share Folders',
      action: () => {
        this.shareFolders();
      },
      primary: true
    }
  ];

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public payload: any[],
    private accountsService: AccountsService,
    private foldersService: IFoldersApiService,
    private dateService: DateService,
  ) {
    this.selectedUsers = payload;
  }

  ngOnInit(): void {
    this.initFolders();
  }

  initFolders(): void {
    forkJoin([
      from(this.foldersService.getFolders()),
      this.accountsService.getUsers()
    ]).subscribe(([folders, users]) => {
      this.folders = folders;
      this.users = users;

      this.dataSource.data = folders.map(folder => {
        let tempFolder = this.getFolderById(folder.id);
        const folderOwner = this.getUserById(tempFolder.createdByUserId);

        return {
          id: folder.id,
          name: folder.name,
          createdAt: this.dateService.formatDate(new Date(tempFolder.createdAt), EDateFormats.dateTwentyOne),
          createdBy: this.foldersService.getFolderOwnerDisplayName(folderOwner)
        };
      })
      .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

      this.dataSource.sortingDataAccessor = (data, attribute) => data[attribute];
      this.dataSource.filterPredicate = (data: any, filter: string) => {
        const filterLower = filter.toLowerCase();

        // Filter on all attributes except id
        const matchString = Object.keys(data)
          .filter(key => key !== 'id')
          .reduce((value, key) => `${value}${data[key]}`, '')
          .toLowerCase();

        return matchString.includes(filterLower);
      };
    });
  }

  shareFolders(): void {
    let requests = this.selectedUsers.map(user => {
      return this.accountsService.addUserFolders(user['id'], this.selection.selected.map(folder => folder.id));
    });

    forkJoin(requests).subscribe((folders: any[]) => {
      this.close(folders);
    });
  }

  private getUserById(userId: number): IUser {
    return this.users.find(user => user.id === userId);
  }

  close(folders?: any[]) {
    this.dialogRef.close(folders);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

   isAllFilteredSelected(): boolean {
    return <boolean> this.dataSource.filteredData.reduce(
      (allSelected: boolean, row) => allSelected && this.selection.isSelected(row),
      true
    );
  }

  masterToggle() {
    if (this.dataSource.filter) {
      this.isAllFilteredSelected() ?
        this.dataSource.filteredData.forEach(row => this.selection.deselect(row)) :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    } else {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  filterRows(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

   onCheckboxChecked(event: MouseEvent): void {
    event.stopPropagation();
  }

  private getFolderById(folderId: number): IFolder {
    return this.folders.filter(folder => folder.id === folderId)[0];
  }
}
