import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OpErrorMessageComponent } from './components/op-error-message/op-error-message.component';
import { ValidateFormDirective } from './directives/validate-form/validate-form.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  declarations: [
    OpErrorMessageComponent,
    ValidateFormDirective,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    OpErrorMessageComponent,
    ValidateFormDirective,
  ]
})  
export class OpFormModule { }
