<op-email-inbox-messages-header
  [title]="inbox?.name"
  [loading]="loading"
></op-email-inbox-messages-header>

<op-email-inbox-messages-table *ngIf="account && inbox"
                               [inboxId]="inbox.id"
                               [inbox]="inbox"
                               [accountId]="account.id">
</op-email-inbox-messages-table>
