<button class="copy-button"
        mat-icon-button
        ngxClipboard
        [disabled]="copyBtnDisabled"
        [cbContent]="copyValue"
        [matTooltip]="copyTooltipText"
        matTooltipPosition="above"
        #tooltip
        (click)="$event.stopPropagation();copyBtnClick()"
        (mouseleave)="resetTooltip()">
  <mat-icon>{{ copyBtnIcon }}</mat-icon>
</button>
