import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { IAuditReportParams } from '@app/components/audit-reports/audit-report-container.models';
import { auditExports } from './audit-export.constants';
import { AccountsService } from '@app/components/account/account.service';
import {
  AuditExportReportService
} from '@app/components/shared/components/audit-report-export/audit-export-report.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import {
  DiscoveryAuditService,
  IRunTagsResponseTag
} from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { ExportCenterUrlBuilders } from '@app/components/export-center/export-center.constants';
import { PAGE_SUMMARY_EXPORT_TYPE } from '../reports/page-summary/page-summary.constants';
import {
  AbstractExportReports
} from '@app/components/shared/components/export-report/export-reports-component.abstract.directive';
import { IAdvancedExportTag, IExportItem } from '@app/components/shared/components/export-report/export-reports.models';
import { EExportStatuses } from '@app/components/shared/components/export-report/export-reports.enums';
import { TagsService } from '@app/components/tags/tagsService';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import {
  FreeTrialAdModalComponent
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import {
  EFreeTrialAdModalType
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-export',
  templateUrl: './audit-export.component.html',
  styleUrls: ['./audit-export.component.scss']
})
export class AuditExportComponent extends AbstractExportReports {
  isVisitorMode: boolean;

  readonly exports: IExportItem[] = [
    {
      name: auditExports.tagAndVariableData.name,
      description: auditExports.tagAndVariableData.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/audits/tagAndVariables.png',
      export: () => this.openConfigModal()
    }, {
      name: auditExports.tagInventory.name,
      description: auditExports.tagInventory.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/audits/tagInventory.png',
      export: () => this.export('tag_inventory_page_tags', auditExports.tagInventory.name)
    }, {
      name: auditExports.tagMetadata.name,
      description: auditExports.tagMetadata.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/audits/tagMetaData.png',
      export: () => this.export('tag_metadata', auditExports.tagMetadata.name)
    }, {
      name: auditExports.cookiesInventory.name,
      description: auditExports.cookiesInventory.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/audits/cookieInventory.png',
      export: () => this.export('cookie_inventory_cookies', auditExports.cookiesInventory.name)
    }, {
      name: auditExports.browserConsoleLogs.name,
      description: auditExports.browserConsoleLogs.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/audits/browserConsoleLogs.png',
      export: () => this.export('browser_logs_page_logs', auditExports.browserConsoleLogs.name)
    }, {
      name: auditExports.requestLog.name,
      description: auditExports.requestLog.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/audits/networkRequestLog.png',
      export: () => this.export('network_request_log_page_requests', auditExports.requestLog.name)
    }, {
      name: auditExports.rules.name,
      description: auditExports.rules.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/audits/rules.png',
      export: () => this.export('rule_summary_rules', auditExports.rules.name)
    }, {
      name: auditExports.pageSummary.name,
      description: auditExports.pageSummary.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/audits/pageSummary.png',
      export: () => this.export(PAGE_SUMMARY_EXPORT_TYPE, auditExports.pageSummary.name, CommonReportsPagesTableColumns.PageUrl, false)
    }, {
      name: auditExports.brokenScannedPages.name,
      description: auditExports.brokenScannedPages.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/audits/brokenLinkDetails.png',
      export: () => this.export('page_summary_broken_scanned_links', auditExports.brokenScannedPages.name )
    }
  ];

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected accountsService: AccountsService,
    protected auditService: DiscoveryAuditService,
    protected service: AuditExportReportService,
    protected opModalService: OpModalService,
    protected modalEscapeService: ModalEscapeService,
    private discoveryAuditService: DiscoveryAuditService,
    protected tagService: TagsService,
    private applicationChromeService: ApplicationChromeService,
    protected snackbar: MatSnackBar,
    protected zone: NgZone
  ) {
    super();

    this.applicationChromeService.isVisitorMode$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(isVisitorMode => {
      this.isVisitorMode = isVisitorMode;
    });
  }

  getRouteParams() {
    this.route
      .params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: IAuditReportParams) => {
        this.itemId = parseInt(params.auditId);
        this.runId = parseInt(params.runId);
      });
  }

  async goToExportCenter() {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal(EFreeTrialAdModalType.EXPORT);
      return;
    }

    const audit = await this.auditService.getAudit(this.itemId);
    this.router.navigateByUrl(
      `${ExportCenterUrlBuilders.base()}?searchText=${encodeURIComponent(audit.name)}`
    );
  }

  handleExport(row) {
    this.isVisitorMode ?
      this.openFreeTrialAdModal(EFreeTrialAdModalType.EXPORT)
      : row.export();
  }

  openFreeTrialAdModal(type: EFreeTrialAdModalType) {
    const data = {
      type: EFreeTrialAdModalType.EXPORT
    };

    this.opModalService.openModal(FreeTrialAdModalComponent, { data });
  }

  private async openConfigModal() {
    const reportType = 'variable_inventory_tag_variables';
    const reportItem = this.exports.find(report => report.name === auditExports.tagAndVariableData.name);
    reportItem.status = EExportStatuses.generatingExport;
    const tagData = await this.discoveryAuditService
      .getRunTags(this.itemId, this.runId, 0);
    const pagesToLoadCount = tagData.metadata.pagination.totalPageCount - (tagData.metadata.pagination.currentPageNumber + 1);
    if (pagesToLoadCount > 0) {
      const loadedTags = await this.loadAdditionalRunTags(tagData.tags, pagesToLoadCount);
      this.openExportConfig(this.mapResponseTags(loadedTags), reportType, reportItem);
    } else {
      this.openExportConfig(this.mapResponseTags(tagData.tags), reportType, reportItem);
    }
  }

  private async loadAdditionalRunTags(
    firstPageTags: IRunTagsResponseTag[],
    pagesToLoadCount: number
  ): Promise<IRunTagsResponseTag[]> {
    const allTags = firstPageTags;
    const pagesToLoad = Array.from({length: pagesToLoadCount}, (v, i) => i + 1);
    const promises = pagesToLoad.map(pageIndex => this.discoveryAuditService
      .getRunTags(this.itemId, this.runId, pageIndex));
    const results = await Promise.all(promises);
    results.forEach(pageResponse => {
      allTags.push(...pageResponse.tags);
    });
    allTags.sort((t1, t2) => t1.tagName.localeCompare(t2.tagName));
    return allTags;
  }

  private mapResponseTags(tagData: IRunTagsResponseTag[]): IAdvancedExportTag[] {
    return tagData.map(tag => ({
      id: tag.tagId,
      name: tag.tagName,
      icon: UiTagService.getTagIconUrl(tag.tagId),
      accounts: tag.accounts,
      selected: false
    }));
  }
}
