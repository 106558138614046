<div class="filter-container" [formGroup]="formData">
  <!-- Main Filter -->
  <div class="main-filter">
    <mat-form-field class="styled-mat-input" appearance="outline">
      <mat-label>Select Tag</mat-label>
      <input matInput formControlName="tagId" [matAutocomplete]="auto" />
      <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTagIdFn">
        <mat-option class="slim-mat-option tag-id-option"
                    *ngFor="let tag of filteredTags"
                    [value]="tag.id">

          <div class="tag-option">
            <span class="tag-icon-container">
              <img [src]="tag.iconUrl" class="tag-icon" loading="lazy" />
            </span>
            <div class="tag-info">
              <div class="name" [innerHTML]="tag.name | highlightTextPipe: tagIdSearchText"></div>
              <div class="category" [innerHTML]="getTagCategory(tag.tagCategoryId).category | highlightTextPipe: tagIdSearchText"></div>
            </div>
          </div>

        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="tagIdControl.touched && tagIdControl.invalid">
        <mat-icon>warning_amber</mat-icon>
        Field can't be empty
      </mat-error>
    </mat-form-field>

    <mat-icon *ngIf="removable" class="remove-icon" (click)="onRemoveMainCondition.emit()">
      highlight_off
    </mat-icon>
  </div>

  <!-- Tag Account Filter -->
  <div *ngIf="accountGroup.enabled" class="tag-account-wrp">
      <rule-tag-account-filter [formData]="accountGroup"
                               (onRemoveAccount)="removeAccountCondition()">
      </rule-tag-account-filter>
  </div>

  <!-- Tag Variable Filters -->
  <div formArrayName="variables">
    <div *ngFor="let variableGroup of variablesArray.controls; let i = index"
         class="tag-variable-wrp">
      <rule-tag-variable-filter [formData]="variableGroup"
                                [allTags]="allTags"
                                (onRemoveVariable)="removeVariableCondition(i)">

      </rule-tag-variable-filter>
    </div>
  </div>

  <!-- Add Tag Conditions buttons -->
  <div class="add-tag-conditions-buttons">
    <a (click)="addAccountCondition()" [class.disabled]="formData.invalid" *ngIf="accountGroup.disabled">
      <mat-icon>add_circle_outline</mat-icon>
      <span>Add Account</span>
    </a>

    <span class="or-text" *ngIf="accountGroup.disabled">OR</span>

    <a (click)="addVariableCondition()" [class.disabled]="formData.invalid">
      <mat-icon>add_circle_outline</mat-icon>
      <span>Add Variable</span>
    </a>
  </div>
</div>
