import { RorPagesMissingComponent } from './ror-comparison-results/ror-pages-missing/ror-pages-missing.component';
import { RorComparisonVariablePresencePagesComponent } from './ror-comparison-variable-presence-pages/ror-comparison-variable-presence-pages.component';
import { RorComparisonResultsComponent } from './ror-comparison-results/ror-comparison-results.component';
import { RorReportPaths, RorReportStateNames } from './ror-comparison-report.contants';
import { RorComparisonReportComponent } from './ror-comparison-report.component';
import { RorComparisonTagPresenceComponent } from './ror-comparison-tag-presence/ror-comparison-tag-presence.component';
import { RorComparisonPagesComponent } from './ror-comparison-pages/ror-comparison-pages.component';
import { RorComparisonVariablePresenceComponent } from './ror-comparison-variable-presence/ror-comparison-variable-presence.component';
import { RorComparisonValuePresenceComponent } from './ror-comparison-value-presence/ror-comparison-value-presence.component';
import { RorComparisonValuePresencePagesComponent } from './ror-comparison-value-presence-pages/ror-comparison-value-presence-pages.component';
import { Route } from '@angular/router';

const tagPresence: Route = {
  path: RorReportPaths.tagPresence,
  data: {
    stateName: RorReportStateNames.tagPresence,
    breadcrumb: {
      title: 'Tag Presence Results'
    }
  },
  children: [{
    path: '',
    component: RorComparisonTagPresenceComponent,
    data: {
      breadcrumb: null
    }
  }, {
    path: RorReportPaths.tagPresencePages,
    component: RorComparisonPagesComponent,
    data: {
      stateName: RorReportStateNames.tagPresencePages,
      breadcrumb: {
        title: 'Pages'
      }
    }
  }]
};

const variablePresence: Route = {
  path: RorReportPaths.variablePresence,
  data: {
    stateName: RorReportStateNames.variablePresence,
    breadcrumb: {
      title: 'Variable Presence Results',
    },
  },
  children: [{
    path: '',
    component: RorComparisonVariablePresenceComponent,
    data: {
      breadcrumb: null
    }
  }, {
    path: RorReportPaths.variablePresencePages,
    component: RorComparisonVariablePresencePagesComponent,
    data: {
      stateName: RorReportStateNames.variablePresencePages,
      breadcrumb: {
        title: 'Pages'
      }
    }
  }]
};

const valuePresence: Route = {
  path: RorReportPaths.valuePresence,
  data: {
    stateName: RorReportStateNames.valuePresence,
    breadcrumb: {
      title: 'Value Presence Results'
    }
  },
  children: [{
    path: '',
    component: RorComparisonValuePresenceComponent,
    data: {
      breadcrumb: null
    }
  }, {
    path: RorReportPaths.valuePresencePages,
    component: RorComparisonValuePresencePagesComponent,
    data: {
      stateName: RorReportStateNames.valuePresencePages,
      breadcrumb: {
        title: 'Pages'
      }
    }
  }]
};

const missingPages: Route = {
  path: RorReportPaths.missingPages,
  component: RorPagesMissingComponent,
  data: {
    stateName: RorReportStateNames.missingPages,
    breadcrumb: {
      title: 'Pages Missing From Baseline'
    }
  }
};

// query params: comparisonSnapshotId
const results: Route = {
  path: RorReportPaths.results,
  data: {
    stateName: RorReportStateNames.results,
    breadcrumb: {
      title: 'Comparison Results'
    }
  },
  children: [
    { path: '', component: RorComparisonResultsComponent, data: { breadcrumb: null } },
    tagPresence,
    variablePresence,
    valuePresence,
    missingPages
  ]
};

export const comparisonReportRoutes: Route = {
  path: RorReportPaths.base,
  component: RorComparisonReportComponent,
  data: {
    title: 'Comparison Results',
    betaLabel: true,
    stateName: RorReportStateNames.base,
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: RorReportPaths.results },
    results
  ]
};
