<div class="file-changes-container" [class.loading]="spinnerState === EFilterSpinnerState.Loading">
  <div #fileChangesTableScrollTop></div>
  <table mat-table
         matSort
         [dataSource]="tableDataSource"
         multiTemplateDataRows
         [matSortActive]="TableColumn.LargestSizeDifference"
         matSortDirection="desc"
         matSortStart="desc">

    <ng-container [matColumnDef]="TableColumn.Tag">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span matTooltip="Identified tag associated with this file request."
              matTooltipPosition="above">
          TAG
        </span>
      </th>
      <td mat-cell *matCellDef="let row">
        <img
          class="tag"
          *ngIf="row[TableColumn.Tag].src"
          [matTooltip]="row[TableColumn.Tag].tagName"
          [src]="row[TableColumn.Tag].src"
          matTooltipShowDelay="500"
          matTooltipPosition="above"
        />
        <actions-menu *ngIf="row[TableColumn.Tag].src">
          <button mat-menu-item
                  (click)="setTagGlobalFilter(row[TableColumn.Tag].tagName, row[TableColumn.Tag].tagId)">
            <mat-icon>tune</mat-icon>
            Filter report by this item
          </button>
        </actions-menu>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.Filename">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header>
        <span matTooltip="Name of the Javascript file."
              matTooltipPosition="above">
          FILE NAME
        </span>
      </th>
      <td mat-cell
          *matCellDef="let row">
        <div class="text-wrap">
          <div class="text no-margin"
               opTableCellTooltip="{{ row[TableColumn.Filename] }}">
            {{ row[TableColumn.Filename] }}
          </div>
        </div>
        <actions-menu>
          <button mat-menu-item
                  (click)="setFilenameGlobalFilter(row[TableColumn.Filename])">
            <mat-icon>tune</mat-icon>
            Filter report by this item
          </button>
        </actions-menu>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.Domain">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
        <span matTooltip="Domain of the Javascript file."
              matTooltipPosition="above">
          FILE DOMAIN
        </span>
      </th>
      <td mat-cell
          *matCellDef="let row">
        <div class="text-wrap">
          <div class="text"
               opTableCellTooltip="{{ row[TableColumn.Domain] }}">
            {{ row[TableColumn.Domain] }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PartyType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span matTooltip="Domain relationship to page."
              matTooltipPosition="above">
          1st/3rd PARTY
        </span>
      </th>
      <td mat-cell
          *matCellDef="let row">
        {{ row[TableColumn.PartyType] }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.ChangeType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span matTooltip="The change type of change that took place from previous audit run to current audit  run."
              matTooltipPosition="above">
          CHANGE TYPE
        </span>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row[TableColumn.ChangeTypeFormatted] }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.DateDifferenceFormatted">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span matTooltip="The biggest time difference between file timestamp changes from previous audit run to current audit run for that given file."
              matTooltipPosition="above">
          LARGEST DATE DIFFERENCE
        </span>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row[TableColumn.DateDifferenceFormatted] }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.LargestSizeDifference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="The biggest file size changes from previous audit run to current audit run for that given file."
          matTooltipPosition="above">
        LARGEST SIZE DIFFERENCE (Bytes)
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row[TableColumn.SizeDifferenceFormatted] }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageCount">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header
          matTooltip="Pages affected by this message."
          matTooltipPosition="above">
        # OF PAGES
      </th>
      <td mat-cell *matCellDef="let row">
        <span [class.selected]="row === selectedRow" (click)="selectRow($event, row)">
          {{ row[TableColumn.PageCount] | number }}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns"
        [class.selected]="row === selectedRow"
        (click)="selectRow($event, row)"></tr>

    <tr *matNoDataRow>
      <td [colSpan]="tableDisplayedColumns.length">
        <div class="no-data-container">
          <span *ngIf="spinnerState !== EFilterSpinnerState.Loading">no file changes here</span>
        </div>
      </td>
    </tr>
  </table>
</div>
<mat-paginator
  class="paginator"
  [length]="tableState?.pagesTotal"
  [pageSize]="tableState?.pagination.size"
  [pageIndex]="tableState?.pagination.page"
  [hidePageSize]="true"
></mat-paginator>
