<div class="top-wrap">
  <filter-spinner [state]="state"></filter-spinner>
  <p class="title">Geolocation and Request Domain Compliance</p>
  <op-audit-report-export-menu
    [auditId]="auditId"
    [runId]="runId"
    [tableCompletedState]="state"
    [config]="exportReportConfig">
  </op-audit-report-export-menu>
</div>

<div class="table-wrap">
  <div #scrollTop></div>
  <table
    mat-table
    matSort
    class="request-table"
    matSortStart="desc"
    matSortActive="compliance_status"
    matSortDirection="desc"
    [dataSource]="dataSource"
    [class.updating]="state === 'loading'"
    [class.is-filtered]="isFiltered"
    (matSortChange)="onSort.emit($event)"
  >
    <ng-container matColumnDef="domain">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>REQUEST DOMAIN</th>
      <td mat-cell *matCellDef="let request">
        <div class="domain">
          <div class="domain__text"
               [matTooltip]="request.requestDomain"
               matTooltipPosition="above"
          >
            {{request.requestDomain}}
          </div>
          <actions-menu>
            <button mat-menu-item (click)="globalFilterByDomain(request)">
              <mat-icon>tune</mat-icon>
              Filter report by this item
            </button>
          </actions-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="geo">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>GEOLOCATION</th>
      <td mat-cell *matCellDef="let request">
        <ng-container *ngIf="request.geo; else unknownGeo">
          {{ request.geo }}
          <actions-menu>
            <button mat-menu-item (click)="globalFilterByCountry(request)">
              <mat-icon>tune</mat-icon>
              Filter report by this item
            </button>
          </actions-menu>
        </ng-container>
        <ng-template #unknownGeo>
          <i>Unknown</i>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="request_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text"># OF REQUESTS</th>
      <td mat-cell *matCellDef="let request" class="center-text">
        {{request.requestCount}}
      </td>
    </ng-container>

    <ng-container matColumnDef="compliance_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>STATUS</th>
      <td mat-cell *matCellDef="let request; let index;">
        <div class="status"
             [class.approved]="request.consentCategoryComplianceStatus && isStatusApproved(request)"
             [class.unapproved]="request.consentCategoryComplianceStatus && !isStatusApproved(request)"
        >
          <div class="status-with-cc-wrapper"
               *ngIf="request.consentCategoryComplianceStatus; else uncategoriezed">
            <mat-icon>{{isStatusApproved(request) ? 'check_circle' : 'warning'}}</mat-icon>
            {{request.consentCategoryComplianceStatus}}
            <button mat-icon-button
                    class="trigger-button"
                    [matMenuTriggerFor]="statusActionMenu"
                    (click)="statusActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
            >
              <mat-icon class="trigger-icon">more_vert</mat-icon>
            </button>
            <mat-menu #statusActionMenu="matMenu"
                      class="cc-action-menu"
                      (closed)="statusActionMenuOpen = false; ccActionIndex = null"
            >
              <button mat-menu-item (click)="addToConsentCategoryClicked(request, $event)">
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                Add this item to a Consent Category
              </button>
              <button mat-menu-item (click)="onManageConsentCategories.emit()">
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                Add multiple items to a new or existing Consent Category
              </button>
              <button mat-menu-item
                      (click)="globalFilterByStatus(request)">
                <mat-icon>tune</mat-icon>
                Filter report by all "{{ isStatusApproved(request) ? 'Approved' : 'Unapproved' }}" items
              </button>
            </mat-menu>
          </div>
          <ng-template #uncategoriezed>
            <i>Uncategorized</i>
            <button mat-icon-button
                    class="trigger-button"
                    [matMenuTriggerFor]="statusActionMenu"
                    (click)="statusActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
            >
              <mat-icon class="trigger-icon">more_vert</mat-icon>
            </button>
            <mat-menu #statusActionMenu="matMenu"
                      class="cc-action-menu"
                      (closed)="statusActionMenuOpen = false; ccActionIndex = null"
            >
              <button mat-menu-item
                      [disabled]="userIsReadOnly"
                      (click)="addToConsentCategoryClicked(request, $event)"
              >
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                Add this item to a Consent Category
              </button>
              <button mat-menu-item
                      [disabled]="userIsReadOnly"
                      (click)="onManageConsentCategories.emit()"
              >
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                Add multiple items to a new or existing Consent Category
              </button>
            </mat-menu>
          </ng-template>

        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="consent_category_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>CONSENT CATEGORY</th>
      <td mat-cell *matCellDef="let request; let index;">
        <div class="category-name" *ngIf="request.consentCategoryName; else addCategory">
          {{request.consentCategoryName}}

          <button mat-icon-button
                  class="trigger-button"
                  [matMenuTriggerFor]="ccActionMenu"
                  (click)="ccActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
          >
            <mat-icon class="trigger-icon">more_vert</mat-icon>
          </button>
          <mat-menu #ccActionMenu="matMenu"
                    class="cc-action-menu"
                    (closed)="ccActionMenuOpen = false; ccActionIndex = null"
          >
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    (click)="addToConsentCategoryClicked(request, $event)"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} & Add this to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} multiple items & Assign to existing or new Consent Category
            </button>
            <button mat-menu-item
                    (click)="globalFilterByCategoryName(request)">
              <mat-icon>tune</mat-icon>
              Filter report by this Consent Category
            </button>
          </mat-menu>
        </div>

        <ng-template #addCategory>
          <div class="cc-action-icon"
               *ngIf="!userIsReadOnly && !runConsentCategories?.length"
               (click)="openAuditEditorToConsentCategoryStandard($event)"
          >
            <mat-icon class="add-category">add_circle_outline</mat-icon>
            <span class="cc-action-text">Assign</span>
          </div>
          <div class="cc-action-icon"
               [class.cc-action-menu-open]="ccActionMenuOpen && ccActionIndex === index"
               *ngIf="!userIsReadOnly && runConsentCategories?.length"
               (click)="addToConsentCategoryClicked(request, $event)"
          >
            <mat-icon class="add-category">add_circle_outline</mat-icon>
            <span class="cc-action-text">
              {{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve & Add' : 'Unapprove & Add'}}
            </span>
          </div>
          <button mat-icon-button
                  class="trigger-button"
                  [matMenuTriggerFor]="ccActionMenu"
                  (click)="ccActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
          >
            <mat-icon class="trigger-icon">more_vert</mat-icon>
          </button>
          <mat-menu #ccActionMenu="matMenu"
                    class="cc-action-menu"
                    (closed)="ccActionMenuOpen = false; ccActionIndex = null"
          >
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="!runConsentCategories?.length"
                    (click)="addToConsentCategoryClicked(request, $event)"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              Add this item to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="!runConsentCategories?.length"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              Add multiple items to a new or existing Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="runConsentCategories?.length"
                    (click)="addToConsentCategoryClicked(request, $event)"
            >
              <mat-icon>{{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve & ' : 'Unapprove & '}}Add this to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="runConsentCategories?.length"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}}multiple items & Assign to existing or a new Consent Category
            </button>
          </mat-menu>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="page_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef># OF PAGES</th>
      <td mat-cell *matCellDef="let request">
        <div class="chart-wrapper" [class.selected]="isLineSelected(request)">
          <horizontal-bar-chart [data]="request.onPages.chartData"
                                [barDirection]="request.onPages.barDirection"
                                [uniqueIdentifier]="request.onPages.uniqueIdentifier"
                                [state]="request.barChartSettings.state"
                                [displayPills]="request.barChartSettings.displayPills"
                                [calcAsPercentage]="request.barChartSettings.calcAsPercentage"
                                [displayPercentSymbol]="request.barChartSettings.displayPercentSymbol"
                                [textPosition]="request.barChartSettings.textPosition"
          ></horizontal-bar-chart>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let request; columns: columns;"
      (click)="filterByPage(request)"
      [class.row-selected]="isLineSelected(request)"
    ></tr>
  </table>
</div>

<mat-paginator
  class="paginator"
  [length]="paginationState.length"
  [pageSize]="paginationState.pageSize"
  [hidePageSize]="true"
></mat-paginator>
