<ng-container *ngIf="data">
  <div class="bar-chart-top-section" *ngIf="title || displayPills">
    <div class="bar-chart-title-wrap">
      <div *ngIf="title" class="bar-chart-title" [matTooltip]="title.tooltip">
        {{ title.title }}
        <filter-spinner [state]="state"></filter-spinner>
      </div>
    </div>
    <div *ngIf="displayPills" class="bar-chart-pill-wrap" [class.bar-chart-loading]="state === 'loading'">
      <filter-pill *ngFor="let pill of data" [data]="pill" (onClick)="filterChart($event.mouseEvent, $event.item)"></filter-pill>
    </div>
  </div>
  <div class="bar-chart-svg-container svg-container-{{ uniqueIdentifier }}"
       [class.filtered]="hasActiveFilter"
       [class.bar-chart-loading]="state === 'loading'">
  </div>
</ng-container>