import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sample-modal',
  templateUrl: './sample-modal.component.html',
  styleUrls: ['./sample-modal.component.scss']
})
export class SampleModalComponent {

  constructor(private dialogRef: MatDialogRef<SampleModalComponent>) {}

  rightFooterButtons: IButton[] = [
    {
      label: 'Previous',
      action: () => {},
      primary: false
    },
    {
      label: 'Next',
      action: () => {},
      primary: false
    },
    {
      label: 'Save',
      action: () => {},
      primary: true
    }
  ];

  close(): void {
    this.dialogRef.close();
  }
}
