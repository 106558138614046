<div class="product-limits">
  <form [formGroup]="productLimitsForm">
    <div *ngIf="retentionPolicy" class="retention-policy section">
      <op-section-divider class="section-divider" text="Retention Policy"></op-section-divider>
      <div class="cols">
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Retention Policy</mat-label>
            <mat-select formControlName="retentionPolicy">
              <mat-option *ngFor="let policy of dataRetentionPolicies"
                          [value]="policy">
                {{ ERetentionPolicyNameToDisplayValue[policy] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="web-journey-limits section field">
      <op-section-divider class="section-divider" text="Web Journeys"></op-section-divider>
      <div class="cols">
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Concurrent Journeys</mat-label>
            <input matInput placeholder="Concurrent Journeys" formControlName="concurrentJourneys">
            <mat-error *ngIf="concurrentJourneys.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Max Actively Monitored Journeys</mat-label>
            <input matInput placeholder="Max Actively Monitored Journeys" formControlName="maxMonitoredJourneys">
            <mat-error *ngIf="maxMonitoredJourneys.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Web Journey Support Fixes</mat-label>
            <input matInput placeholder="Web Journey Support Fixes" formControlName="journeyFixes">
            <mat-error *ngIf="journeyFixes.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="comparisons-limits section">
      <op-section-divider class="section-divider" text="Comparisons"></op-section-divider>
      <div class="cols">
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Max Tags for Comparisons</mat-label>
            <input matInput placeholder="Max Tags for Comparisons" formControlName="maxComparisonTags">
            <mat-error *ngIf="maxComparisonTags.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="user-limits section">
      <op-section-divider class="section-divider" text="Users"></op-section-divider>
      <div class="cols">
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Max Users</mat-label>
            <input matInput placeholder="Max Users" formControlName="maxUsers">
            <mat-error *ngIf="maxUsers.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="audit-limits section">
      <op-section-divider class="section-divider" text="Web Audits"></op-section-divider>
      <div class="cols">
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Concurrent Audits</mat-label>
            <input matInput placeholder="Concurrent Audits" formControlName="concurrentAudits">
            <mat-error *ngIf="concurrentAudits.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="domain-limits section">
      <op-section-divider class="section-divider" text="Domains"></op-section-divider>
      <div class="cols">
        <div class="field domain-count">
          <mat-form-field appearance="outline">
            <mat-label>Max Domains</mat-label>
            <input matInput placeholder="Max Domains" (blur)="handleMaxDomains($event)" formControlName="maxDomains">
            <mat-error *ngIf="maxDomains.errors?.required">This field is required</mat-error>
            <mat-error *ngIf="maxDomains.errors?.max">This field is limited to {{MAX_DOMAINS}} domains</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="outline" *ngFor="let control of domains.controls; let i = index">
            <mat-label>Domain</mat-label>
            <input matInput placeholder="Domain" [formControl]="domains.controls[i]">
            <mat-error *ngIf="domains.controls[i].errors?.url">You must enter a valid URL with a protocol</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="productLimitsForm.invalid" class="error-banner">
    You must correct the errors on this page before any changes will be saved.
  </div>
</div>
