import { RRule } from 'rrule';

export enum EFrequencyType {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH_BY_DAY = 'MONTH_BY_DAY',
  MONTH_BY_DATE = 'MONTH_BY_DATE',
}

export const RRuleToEFrequencyTypeMap = {
  [RRule.MINUTELY.valueOf()]: EFrequencyType.MINUTE,
  [RRule.HOURLY.valueOf()]: EFrequencyType.HOUR,
  [RRule.DAILY.valueOf()]: EFrequencyType.DAY,
  [RRule.WEEKLY.valueOf()]: EFrequencyType.WEEK,
  [RRule.MONTHLY.valueOf()]: EFrequencyType.MONTH_BY_DAY,
  // one is missing but we have to figure it out on load
};

export const EFrequencyTypeToRRuleMap = {
  [EFrequencyType.MINUTE]: RRule.MINUTELY,
  [EFrequencyType.HOUR]: RRule.HOURLY,
  [EFrequencyType.DAY]: RRule.DAILY,
  [EFrequencyType.WEEK]: RRule.WEEKLY,
  [EFrequencyType.MONTH_BY_DAY]: RRule.MONTHLY,
  [EFrequencyType.MONTH_BY_DATE]: RRule.MONTHLY,
};

