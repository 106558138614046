import 'jquery'; //should come before angular
import 'bootstrap';
import 'jquery-ui/ui/widget.js';
import 'jquery-ui/ui/data.js';
import 'jquery-ui/ui/scroll-parent.js';
import 'jquery-ui/ui/widgets/mouse.js';
import 'jquery-ui/ui/widgets/sortable.js';
import 'angular';
// angular modules
import 'rx-angular';
import 'angular-ui-sortable';
import 'angular-local-storage';
import 'angular-strap';
import 'angular-strap/dist/angular-strap.tpl.js';
import 'angular-formly';
import 'angular-formly-templates-bootstrap';
import 'ng-webworker/src/ng-webworker';
import 'angular-animate';
import 'angular-bootstrap-toggle-switch';
import 'angular-spinner';
import 'ng-infinite-scroll';
import 'ng-fittext';
import 'angular-messages';
import 'angular-websocket';
import '@lordfriend/nya-bootstrap-select/dist/js/nya-bs-select.js';
import 'angular-sanitize';
import 'angular-ui-bootstrap';
import 'ng-redux';
import 'ng-toast';
import 'angular-vs-repeat';
import 'ng-scrollbars';
import 'angular-cookies';
import 'eonasdan-bootstrap-datetimepicker';

require('jquery-mousewheel')($); //ng-scrollbars fix
require('malihu-custom-scrollbar-plugin')($); //ng-scrollbars fix
