<op-accordion>
  <mat-accordion displayMode="flat">
    <mat-expansion-panel *ngFor="let report of reports" hideToggle="true" [disabled]="true"
      op-selector="passed-rule-expansion-panel">
      <mat-expansion-panel-header>
        <span class="rule-title">
          {{ report.ruleName }}
        </span>
        <div class='icons' *ngIf='showEditButtons'>
          <span class="icon edit-icon" (click)="handleEdit($event, report.ruleId)" op-selector="easy-rule-edit-button">
            <mat-icon class="edit">edit</mat-icon>
          </span>
          <span class="icon delete-icon" (click)="handleRemove($event, report.ruleId)"
            op-selector="easy-rule-remove-button">
            <mat-icon class="delete">delete</mat-icon>
          </span>
        </div>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
</op-accordion>