import {
  PageNotSetPagesTableColumns,
  PageSetPagesTableColumns,
  UniqueValuesPagesTableColumns,
  VariableInventoryRelevantFilters
} from './variable-inventory.constants';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuditReportBase, IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { ActivatedRoute } from '@angular/router';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import {
  VariableInventoryService
} from '@app/components/audit-reports/reports/variable-inventory/variable-inventory.service';
import { IPageListPage } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import {
  EVariableInventoryPagesType,
  EVariableItemSelection,
  IVariableInventoryPagesItem,
  IVariableInventoryPagination,
  IVariableInventoryQueryParams,
  IVariableInventorySpecificTrendValueItem,
  IVariableInventorySpecificTrendValuesDTO,
  IVariableInventorySummaryDTO,
  IVariableInventoryTagAccount,
  IVariableInventoryTagAccountItem,
  IVariableInventoryTagAccountVariablesDTO,
  IVariableInventoryTagItem,
  IVariableInventoryTagsAndAccountsMenuItem,
  IVariableInventoryTagsDTO,
  IVariableInventoryTrendsDTO,
  IVariableInventoryTrendsItem,
  IVariableInventoryUniqueVariableValuesItem,
  IVariableInventoryVariableItem,
  IVariableInventoryVariableNotSetPagesItem,
  IVariableInventoryVariableValuePageItem
} from '@app/components/audit-reports/reports/variable-inventory/variable-inventory.models';
import {
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  EVariableInventoryTrendNames,
  TAG_INSTANCE_CHART_CONFIG,
  UNIQUE_TAGS_CHART_CONFIG,
  UNIQUE_VARIABLE_NAME_CHART_CONFIG,
  UNIQUE_VARIABLE_VALUE_CHART_CONFIG
} from '@app/components/audit-reports/reports/variable-inventory/variable-inventory.constants';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatMenuTrigger } from '@angular/material/menu';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { sortBy } from 'lodash-es';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import { ClipboardService } from 'ngx-clipboard';
import { DecimalPipe } from '@angular/common';
import {
  formatPaginator,
  PageLoadColumnTooltip,
  TAGS_SEARCH_TEXT_KEY
} from '../../audit-report/audit-report.constants';
import {
  IAuditReportExportMenuData,
  ICopyConfigCell
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import {
  OpClearableInputComponent
} from '@app/components/shared/components/op-clearable-input/op-clearable-input.component';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { AlertMetricType, EAlertVariableInventoryMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { PageStatusCodeTooltipMap } from '../../audit-report-container.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from '@app/components/shared/services/storage.service';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  CommonPagesColumnConfigWarningMessage,
  CommonPagesConfigLocalStorageKey,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';

const EMPTY_VARIABLE = {
  variableName: null,
  friendlyName: null,
  uniqueValueCount: null,
  variableSetPageCount: null,
  variableNotSetPageCount: null,
  selected: null,
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'variable-inventory',
  templateUrl: './variable-inventory.component.html',
  styleUrls: ['./variable-inventory.component.scss'],
  providers: [ResizeableTableService],
  animations: [
    trigger('valueExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ]
})
export class VariableInventoryComponent
  extends AuditReportBase
  implements IFilterableAuditReport, OnInit, OnDestroy, AfterViewInit {
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  readonly getTagIconUrl = UiTagService.getTagIconUrl;
  protected readonly PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;
  PagesTableColumns;

  EFilterSpinnerState = EFilterSpinnerState;
  CommonReportsPagesTableColumns = CommonReportsPagesTableColumns;
  // General report configuration
  private globalFilters: IAuditReportApiPostBody = {};
  destroy$ = new Subject();
  auditId: number;
  runId: number;
  runHistory: ISparklineRunInfo[];
  pageIdOpenInPageDetails: string;
  isLoaded = false;
  relevantFiltersApplied = false;
  PageLoadColumnTooltip = PageLoadColumnTooltip;

  // Widgets
  pageCountLabel = 'Pages Scanned';
  filteredPageCount: string;
  totalPageCount: string;
  sparklineDataLoaded = false;
  pageMetricType = EAlertVariableInventoryMetric.PagesScanned;

  uniqueTagsLabel = 'Unique Tags';
  filteredUniqueTagsCount: string;
  totalUniqueTagsCount: string;
  uniqueTagsSparklineData: ISparklineChartData[] = [];
  uniqueTagsCountBottomHandler = this.openUniqueTagsFullscreenChart.bind(this);
  uniqueTagsMetricType = EAlertVariableInventoryMetric.UniqueTags;

  tagRequestsEvaluatedLabel = 'Total Tag Requests';
  filteredTagRequestsCountFormatted: string;
  filteredTagRequestsCount: number;
  totalTagRequestsEvaluatedCount: string;
  tagRequestsEvaluatedSparklineData: ISparklineChartData[] = [];
  tagRequestsEvaluatedBottomHandler = this.openTagRequestsEvaluatedFullscreenChart.bind(this);
  tagRequestsEvaluatedMetricType = EAlertVariableInventoryMetric.TagRequestsEvaluated;

  uniqueVariableNameLabel = 'Unique Variables';
  filteredUniqueVariableNameCount: string;
  totalUniqueVariableNameCount: string;
  uniqueVariableNameSparklineData: ISparklineChartData[] = [];
  uniqueVariableNameBottomHandler = this.openUniqueVariableNameFullscreenChart.bind(this);
  uniqueVariableMetricType = EAlertVariableInventoryMetric.UniqueVariables;

  uniqueVariableValueLabel = 'Unique Values';
  filteredUniqueVariableValueCountFormatted: string;
  filteredUniqueVariableValueCount: number;
  totalUniqueVariableValueCount: string;
  uniqueVariableValueSparklineData: ISparklineChartData[];
  uniqueVariableValueBottomHandler = this.openUniqueVariableValueFullscreenChart.bind(this);
  uniqueVariableValueMetric = EAlertVariableInventoryMetric.UniqueValues;

  // Spinner state management
  widgetState;
  tagTableState;
  variableTableState;
  pageTableState;

  // Shared table state
  selectedTag$: Observable<IVariableInventoryTagAccount>;
  selectedTagName: string;
  selectedPageFilter$: Observable<EVariableInventoryPagesType>;

  // Tags & Accounts table
  tagsAndAccountsTableScrolled = false;
  tagsAndAccountsTableScrolled$ = new Subject<boolean>();
  tagAndAccountsColumnsToDisplay: string[] = ['tagAccount'];
  tagsAndAccountsDataSource: MatTableDataSource<IVariableInventoryTagAccount> = new MatTableDataSource();
  tagsAndAccountsMenuOptions = this.getTagsAndAccountsMenuOptions();

  // Tag Accounts Variable table
  selectedVariableRowIndex: number = null;
  tagAccountVariablesTableScrolled = false;
  tagAccountVariablesTableScrolled$ = new Subject<boolean>();
  eVariableItemSelection = EVariableItemSelection;
  tagAccountVariablesColumnsToDisplay: string[] = ['friendlyName', 'uniqueValueCount', 'variableSetPageCount', 'variableNotSetPageCount'];
  tagAccountVariablesDataSource = new MatTableDataSource<IVariableInventoryVariableItem>();
  tagAccountVariablesLoading = false;

  // Pages Tables shared
  defaultPagesPagination: IVariableInventoryPagination = {
    currentPageNumber: 0,
    currentPageSize: 200,
    totalCount: 0,
    totalPageCount: 0,
    pageSize: 200,
  };
  pagesTablePagination = this.defaultPagesPagination;

  // Variable Pages table
  displayPagesScannedSection: boolean = false; // Flag to set visibility on pages scanned section
  displayPagesTable: boolean = false; // Flag to set visibility on pages table
  selectedVariable: IVariableInventoryVariableItem = EMPTY_VARIABLE;
  pagesTableDataSource: MatTableDataSource<IVariableInventoryPagesItem|IVariableInventoryVariableNotSetPagesItem> = new MatTableDataSource();

  pagesTableDisplayedColumns$ = this.tableService.displayedColumns$;
  variablePagesQueryParams: IVariableInventoryQueryParams = {
    // defaults
    sortBy: CommonReportsPagesTableColumns.PageUrl,
    sortDesc: false
  };

  // Variable Unique Values table
  displayUniqueValuesTable = false;  // Flag to set visibility on unique values table
  uniqueValuesPagesDataSource = new MatTableDataSource<IVariableInventoryUniqueVariableValuesItem>();
  expandedValue;
  uniqueValuesPagesExpandedColumnsToDisplay: string[] = ['expandedValue'];
  expandedValuePages: IVariableInventoryVariableValuePageItem[];
  variableValuesQueryParams: IVariableInventoryQueryParams = {
    // defaults
    sortBy: CommonReportsPagesTableColumns.PageCount,
    sortDesc: false
  };

  pagesTableSort: MatSort;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('tagAndAccountsTable', { read: ElementRef }) tagAndAccountsTable: ElementRef;
  @ViewChild('tagAndAccountsTableSort') tagAndAccountsTableSort: MatSort;
  @ViewChild('tagAccountVariablesTableSort') tagAccountVariablesTableSort: MatSort;
  @ViewChild('uniqueValuesTableSort') uniqueValuesTableSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('pagesTableSectionScrollTo', { read: ElementRef }) pagesTableSectionScrollTo: ElementRef;
  @ViewChild(OpClearableInputComponent) variablesFilter: OpClearableInputComponent;

  @ViewChild('pagesTableSort') set content(sort: MatSort) {
    if (sort) {
      this.pagesTableSort = sort;
      this.initSorting();
    }
  }

  exportReportConfig: IAuditReportExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: 'variable_inventory_tag_account_pages',
    totalRows: 0,
    filteredRows: 0,
    tableState: {
      sortBy: this.variablePagesQueryParams.sortBy,
      sortDesc: this.variablePagesQueryParams.sortDesc,
      page: this.pagesTablePagination.currentPageNumber,
      size: this.pagesTablePagination.pageSize
    },
    filters: this.globalFilters,
    dataToCopy: {
      config: null,
      data: null,
      displayedColumns$: this.pagesTableDisplayedColumns$
    }
  };

  private setVariablesPagesConfig: ICopyConfigCell<IVariableInventoryPagesItem>[] = [
    {
      property: 'pageUrl',
      tableColumnName: CommonReportsPagesTableColumns.PageUrl
    },
    {
      property: 'finalPageUrl',
      tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
    },
    {
      property: 'pageLoadTime',
      tableColumnName: CommonReportsPagesTableColumns.PageLoadTime,
      displayLike: row => row.pageLoadTime
    },
    {
      property: 'finalPageStatusCode',
      tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
    },
    {
      title: '# VARIABLES SET',
      property: 'variableCount',
      tableColumnName: CommonReportsPagesTableColumns.VariableCount
    }
  ];

  private noVariablesPagesConfig: ICopyConfigCell<IVariableInventoryVariableNotSetPagesItem>[] = [
    {
      property: 'pageUrl',
      tableColumnName: CommonReportsPagesTableColumns.PageUrl
    },
    {
      property: 'finalPageUrl',
      tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
    },
    {
      property: 'loadTime',
      tableColumnName: CommonReportsPagesTableColumns.PageLoadTime,
      displayLike: row => row.pageLoadTime
    },
    {
      title: 'FINAL PAGE STATUS CODE',
      property: 'pageStatusCode',
      tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode,
    }
  ];

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  readonly TableColumn = CommonReportsPagesTableColumns;

  constructor(
    private route: ActivatedRoute,
    private auditReportLoadingSvc: AuditReportLoadingService,
    private variableInventoryService: VariableInventoryService,
    private filterBarService: AuditReportFilterBarService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: OpModalService,
    private modalEscapeService: ModalEscapeService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private scrollService: AuditReportScrollService,
    private clipboardService: ClipboardService,
    private decimalPipe: DecimalPipe,
    private alertReportingService: AlertReportingService,
    private snackbar: MatSnackBar,
    private storageService: StorageService,
    private tableService: ResizeableTableService,
  ) {
    super();

    this.route.params
      .subscribe(params => {
        this.auditId = +params.auditId;
        this.runId = +params.runId;

        const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
        const alertId = queryParams?.alertId;
        const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
        this.preventHighlight = !highlight;
        // When loading report from an alert, first override filters with alert
        // filters before loading report data
        if (alertId) {
          this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
            this.filterBarService.overrideFilters(alert.config.filtersV0);
            this.highlightMetricType = highlight && alert.config.metricType;

            this.alertCheckComplete$.next(true);
          });
        } else {
          this.alertCheckComplete$.next(true);
        }

      if (this.isLoaded) {
        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          this.onFiltersChanged(this.globalFilters);
        });
      }
    });
  }

  ngOnInit() {
    this.initResizeableTable();

    this.initFilters();
    this.selectedTag$ = this.variableInventoryService.selectedTag$;
    this.selectedPageFilter$ = this.variableInventoryService.selectedPageFilter$;

    this.addTableScrollListeners();

    // Update report whenever a tag account is selected in the Tag & Accounts table
    this.selectedTag$.pipe(takeUntil(this.destroy$)).subscribe((tag: IVariableInventoryTagAccount) => {
      this.setReportLevelFiltersAndUpdateData(tag);
    });

    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.Tags);
    this.pageDetailsDrawerService.pageDrawerClosed$.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handlePageDetailsClosed());

    // Update pages table whenever a page type cell is clicked in the Tags & Variables table
    this.selectedPageFilter$.pipe(takeUntil(this.destroy$)).subscribe(this.updatePages.bind(this));

    // subscribe to subject so we get updates when tags are selected for use in filter page details
    this.selectedTag$.pipe(takeUntil(this.destroy$)).subscribe((tag: IVariableInventoryTagAccount) => {
      this.selectedTagName = tag?.tagName;
    });

    this.isLoaded = true;
  }

  ngAfterViewInit() {
    this.formatPaginator();
    this.initSorting();
  }

  initSorting(): void {
    this.tagsAndAccountsDataSource.sort = this.tagAndAccountsTableSort;
    this.tagAccountVariablesDataSource.sort = this.tagAccountVariablesTableSort;

    const onSortChange = (columnToSortColumnMapper: (tableColName: CommonReportsPagesTableColumns) => CommonReportsPagesTableColumns) => (sort: Sort) => {
      const pageType = this.variableInventoryService.getPageFilter();

      const queryParamsToUpdate = pageType === EVariableInventoryPagesType.UNIQUE
        ? this.variableValuesQueryParams
        : this.variablePagesQueryParams;

      queryParamsToUpdate.sortBy = columnToSortColumnMapper(sort.active as any);
      queryParamsToUpdate.sortDesc = sort.direction === 'desc';

      // reset back to the first page if the user changes the sort order
      this.resetPagesTablePagination(0);

      this.updatePages(pageType);
    };

    this.pagesTableSort?.sortChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(onSortChange(i => i).bind(this));

    this.uniqueValuesTableSort?.sortChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(onSortChange(this.mapUniqueValuesTableColumnToSortColumn).bind(this));

    this.tagsAndAccountsDataSource.sortingDataAccessor = (data) => data['tagName'];
    this.tagAccountVariablesDataSource.sortingDataAccessor = (data, property) => {
      switch (property) {
        case 'friendlyName': {
          if (data['friendlyName'] === undefined || data['friendlyName'] === '') {
            return data['variableName'];
          } else {
            return data['friendlyName'];
          }
        }
        case 'uniqueValueCount':
        case 'variableSetPageCount':
        case 'variableNotSetPageCount':
          return data[property];
      }
    };

    this.tagAccountVariablesDataSource.sortData = (data: IVariableInventoryVariableItem[], sort: MatSort) => {
      if (sort.direction === '') {
        return data;
      }

      const accessor = this.tagAccountVariablesDataSource.sortingDataAccessor;

      return ArrayUtils.collatorCompare(data, a => accessor(a, sort.active), sort.direction as ESortDirection)
    };

  }

  initResizeableTable(): void {
    switch (this.variableInventoryService.getPageFilter()) {
      case EVariableInventoryPagesType.TAG_ACCOUNTS:
      case EVariableInventoryPagesType.SET:
        this.PagesTableColumns = PageSetPagesTableColumns;
        break;
      case EVariableInventoryPagesType.NOT_SET:
        this.PagesTableColumns = PageNotSetPagesTableColumns;
        break;
      case EVariableInventoryPagesType.UNIQUE:
        this.PagesTableColumns = UniqueValuesPagesTableColumns;
        break;
      default:
        this.PagesTableColumns = PageSetPagesTableColumns;
    }
  }

  findItem(columns: string[], item): boolean {
    return columns.includes(item);
  }

  ngOnDestroy() {
    this.variableInventoryService.resetServiceState();
    this.destroy$.next();

    this.pageDetailsDrawerService.closePageDetails();
  }

  // General Report Setup
  // setup filters
  initFilters() {
    this.filterBarService.updateSupportedFiltersList(VariableInventoryRelevantFilters);

    this.filterBarService.apiPostBody$
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(300)
        )
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody){
    this.resetPagesTablePagination(0);
    this.resetPagination();
    this.globalFilters = apiPostBody;
    this.exportReportConfig.filters = {
      ...apiPostBody
    };
    this.variableInventoryService.clearReportLevelFilters();
    this.clearAccountVariableSelection();
    this.clearPagesSelection();
    this.variableInventoryService.clearSelectedTagAndVariables();

    this.relevantFiltersApplied = !!this.filterBarService.currentRelevantFilters.find((filter) => VariableInventoryRelevantFilters.includes(filter.type));
    this.fetchGlobalWidgetData();
    this.fetchTagAccountsList();

    this.updateCurrentFilters();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  resetPagination() {
    this.defaultPagesPagination = {
      currentPageNumber: 0,
      currentPageSize: 200,
      totalCount: 0,
      totalPageCount: 0,
      pageSize: 200,
    };
  }

  /**
   * Listen for table scrolling to display drop shadow when scrolled
   */
  addTableScrollListeners() {
    this.tagsAndAccountsTableScrolled$.pipe(
      debounceTime(25),
      takeUntil(this.destroy$)
    ).subscribe(scrolled => {
      this.tagsAndAccountsTableScrolled = scrolled;
      this.changeDetectorRef.detectChanges();
    });

    this.tagAccountVariablesTableScrolled$.pipe(
      debounceTime(25),
      takeUntil(this.destroy$)
    ).subscribe(scrolled => {
      this.tagAccountVariablesTableScrolled = scrolled;
      this.changeDetectorRef.detectChanges();
    });
  }

  tagsAndAccountsTableScroll(event) {
    this.tagsAndAccountsTableScrolled$.next(event.target.scrollTop > 0);
  }

  tagAccountVariablesTableScroll(event) {
    this.tagAccountVariablesTableScrolled$.next(event.target.scrollTop > 0);
  }

  /**
   * Fetch global-level report data (widgets/split-card)
   */
  fetchGlobalWidgetData() {
    const selectedTagAccount = this.variableInventoryService.getSelectedTagAccount();
    let body = {...this.globalFilters};

    // Turn on report loading bar
    this.auditReportLoadingSvc.addLoadingToken();

    // update loading states for widgets and tables
    const filtering = this.filterBarService.currentRelevantFilters.length > 0;
    this.widgetState = EFilterSpinnerState.Loading;
    this.sparklineDataLoaded = false;

    // Update global filters if report-level filters are set (tag account selected)
    if (selectedTagAccount.tagId !== null && selectedTagAccount.tagId >= 0) {
      body.tagId = selectedTagAccount.tagId;
      body.tagAccount = selectedTagAccount.tagAccount;
    }

    forkJoin([
      this.variableInventoryService.getVariableInventorySummaryForAuditRun(
        this.auditId,
        this.runId,
        body,
      ),
      this.variableInventoryService.getVariableInventoryTrends(this.auditId, this.runId)
        .pipe(
          map((data: IVariableInventoryTrendsDTO) => sortBy(data.runs, element => element.runId))
        ),
    ]).pipe(
      take(1),
    ).subscribe(([summary, trends]) => {
      // Load widget data
      this.formatDataForTopSectionOfWidgets(summary);
      this.formatDataForWidgetSparklines(trends);
      this.runHistory = trends.map((run: IVariableInventoryTrendsItem) => ({runId: run.runId, runCompletionDate: run.completedAt}));
      this.widgetState = filtering ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
      this.sparklineDataLoaded = true;
      this.auditReportLoadingSvc.removeLoadingToken();
    }, (error) => {
      console.log('error loading summary and trends data: ', error);

      const filtering = this.filterBarService.currentRelevantFilters.length > 0;
      this.widgetState = filtering ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
      this.auditReportLoadingSvc.removeLoadingToken();
    });
  }

  fetchTagAccountsList() {
    // Turn on report loading bar
    this.auditReportLoadingSvc.addLoadingToken();

    // update loading states for widgets and tables
    const filtering = this.filterBarService.currentRelevantFilters.length > 0;
    this.tagTableState = EFilterSpinnerState.Loading;

    // Load data for tag & accounts table
    this.variableInventoryService.getVariableInventoryTagAccounts(
      this.auditId,
      this.runId,
      this.globalFilters,
    ).pipe(
      take(1)
    ).subscribe((tags: IVariableInventoryTagsDTO) => {
      this.tagsAndAccountsDataSource.data = this.transformTagAccounts(tags.tags);
      this.tagTableState = filtering ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
      this.auditReportLoadingSvc.removeLoadingToken();
    }, (error) => {
      console.log('error retrieving tag and accounts data: ', error);
      this.tagsAndAccountsDataSource.data = [];
      this.auditReportLoadingSvc.removeLoadingToken();

      this.tagTableState = filtering ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
    });
  }

  /**
   * Retrieves report data whenever a report-level filter is updated
   */
  fetchTagAccountVariables() {
    // update loading states for widgets and tables
    const filtering = this.filterBarService.currentRelevantFilters.length > 0;
    this.variableTableState = EFilterSpinnerState.Loading;
    this.pageTableState = EFilterSpinnerState.Loading;

    // If a tag is selected, update the list of tag variables
    // and update the pages list for that tag account
    const selectedTag = this.variableInventoryService.getSelectedTagAccount();
    if (selectedTag?.tagId !== null) {
      this.auditReportLoadingSvc.addLoadingToken();
      const postBody = {
        ...this.globalFilters,
        tagId: selectedTag.tagId,
        tagAccount: selectedTag.tagAccount,
        tagCategoryId: selectedTag.tagCategoryId,
      };

      // Update page filter to kick off pages table load
      this.variableInventoryService.setPageFilter(EVariableInventoryPagesType.TAG_ACCOUNTS);

      this.tagAccountVariablesLoading = true;
      this.tagAccountVariablesDataSource.data = [];
      // Get data and populate the Tag Variable table
      this.variableInventoryService.getVariableInventoryTagAccountsVariableList(
        this.auditId,
        this.runId,
        selectedTag.tagId,
        selectedTag.tagAccount,
        postBody,
      ).pipe(
        take(1)
      ).subscribe(
        (tagVariables: IVariableInventoryTagAccountVariablesDTO): void => {
          // By default, sort tag account variables alphabetically by friendlyName or variableName (if no friendly)
          this.tagAccountVariablesDataSource.data = tagVariables.variables.sort((a: IVariableInventoryVariableItem, b: IVariableInventoryVariableItem) => {
            let aName = a.friendlyName ? a.friendlyName.toLowerCase() : a.variableName.toLowerCase();
            let bName = b.friendlyName ? b.friendlyName.toLowerCase() : b.variableName.toLowerCase();
            if (aName < bName) return -1;
            if (aName > bName) return 1;
          });
          this.tagTableState = this.variableTableState = filtering ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
          this.auditReportLoadingSvc.removeLoadingToken();
        }, (error) => {
          this.tagAccountVariablesDataSource.data = [];
          this.tagTableState = this.variableTableState = filtering ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
          console.log('error retrieving tag account variable data: ', error);
          this.auditReportLoadingSvc.removeLoadingToken();
        },
        () => this.tagAccountVariablesLoading = false);
    } else {
      this.variableTableState = filtering ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
    }
  }

  /**
   * Handles updating the three page tables whenever the pagesType is changed
   */
  updatePages(pagesType: EVariableInventoryPagesType) {
    if (pagesType) {
      this.displayPagesScannedSection = true;
      // Start loading spinner
      // Turn on report loading bar
      this.auditReportLoadingSvc.addLoadingToken();
      this.pageTableState = EFilterSpinnerState.Loading;

      // Get query params
      const queryParams = pagesType === EVariableInventoryPagesType.UNIQUE
        ? {...this.variableValuesQueryParams, ...this.pagesTablePagination}
        : {...this.variablePagesQueryParams, ...this.pagesTablePagination};

      // Pages Not Set does not support sorting by VariableCount
      if (
        pagesType === EVariableInventoryPagesType.NOT_SET &&
        queryParams.sortBy === CommonReportsPagesTableColumns.VariableCount
      ) {
        queryParams.sortBy = CommonReportsPagesTableColumns.PageUrl;
      }

      this.exportReportConfig.tableState.sortDesc = queryParams.sortDesc;
      this.exportReportConfig.tableState.sortBy = queryParams.sortBy;

      if (pagesType != EVariableInventoryPagesType.TAG_ACCOUNTS) {
        this.exportReportConfig.filters = {
          ...this.exportReportConfig.filters,
          variableName: this.selectedVariable.variableName
        };
      }

      switch (pagesType) {
        case EVariableInventoryPagesType.TAG_ACCOUNTS:
          this.exportReportConfig.exportType = 'variable_inventory_tag_account_pages';
          break;
        case EVariableInventoryPagesType.SET:
          this.exportReportConfig.exportType = 'variable_inventory_tag_account_variable_pages';
          break;
        case EVariableInventoryPagesType.NOT_SET:
          this.exportReportConfig.exportType = 'variable_inventory_tag_account_variable_not_set_pages';
          break;
        case EVariableInventoryPagesType.UNIQUE:
          this.exportReportConfig.exportType = 'variable_inventory_tag_account_variable_value_pages';
          break;
      }
      this.variableInventoryService.getVariablePages(
        pagesType,
        this.auditId,
        this.runId,
        this.selectedVariable?.variableName,
        queryParams,
        this.globalFilters,
      ).pipe(
        take(1)
      ).subscribe((data: any) => {
        this.pagesTablePagination = data.metadata.pagination;

        // Set flag to determine which pages table is displayed
        this.displayPagesTable = pagesType === EVariableInventoryPagesType.SET || pagesType === EVariableInventoryPagesType.NOT_SET || pagesType === EVariableInventoryPagesType.TAG_ACCOUNTS;
        this.displayUniqueValuesTable = pagesType === EVariableInventoryPagesType.UNIQUE;

        this.exportReportConfig.tableState.page = this.pagesTablePagination.currentPageNumber;
        this.exportReportConfig.tableState.size = this.pagesTablePagination.pageSize;

        this.exportReportConfig.filteredRows = this.pagesTablePagination.totalCount;

        switch (pagesType) {
          case EVariableInventoryPagesType.TAG_ACCOUNTS:
          case EVariableInventoryPagesType.SET:
            this.PagesTableColumns = PageSetPagesTableColumns;

            data.pages = data.pages.map(page => {
              page.pageLoadTime = this.variableInventoryService.formatToOneDecimalPlace(page.pageLoadTime / 1000);
              return page;
            });
            this.pagesTableDataSource = new MatTableDataSource<any>(data.pages);
            this.exportReportConfig.dataToCopy.data = this.pagesTableDataSource.data;
            this.exportReportConfig.dataToCopy.config = this.setVariablesPagesConfig;
            break;
          case EVariableInventoryPagesType.NOT_SET:
            this.PagesTableColumns = PageNotSetPagesTableColumns;

            data.pages = data.pages.map(page => {
              page.pageLoadTime = this.variableInventoryService.formatToOneDecimalPlace(page.pageLoadTime / 1000);
              return page;
            });
            this.pagesTableDataSource = new MatTableDataSource<any>(data.pages);
            this.exportReportConfig.dataToCopy.data = this.pagesTableDataSource.data;
            this.exportReportConfig.dataToCopy.config = this.noVariablesPagesConfig;
            break;
          case EVariableInventoryPagesType.UNIQUE:
            this.PagesTableColumns = UniqueValuesPagesTableColumns;

            this.uniqueValuesPagesDataSource = new MatTableDataSource<any>(data.variableValues);
            this.exportReportConfig.dataToCopy.data = null;
            break;
        }

        this.auditReportLoadingSvc.removeLoadingToken();
        this.pageTableState = EFilterSpinnerState.Filtered;
      });
    }
  }

  // Flatten list of tag accounts and sort by tag name > tag account name
  transformTagAccounts(tags: IVariableInventoryTagItem[]): IVariableInventoryTagAccount[] {
    let tagAccounts = tags.map((tag: IVariableInventoryTagItem) => {
      return tag.tagAccounts.map((account: IVariableInventoryTagAccountItem) =>  ({
          tagId: tag.tagId,
          tagName: tag.tagName,
          tagCategoryId: tag.tagCategoryId,
          tagCategoryName: tag.tagName,
          tagAccount: account.tagAccount,
          totalPageCount: account.totalPageCount,
          totalVariableCount: account.totalVariableCount,
        })
      );
    }).flat().sort((a, b) => {
      if (a.totalPageCount > b.totalPageCount) return -1;
      if (a.totalPageCount < b.totalPageCount) return 1;
      if (a.tagName.toLowerCase() < b.tagName.toLowerCase()) return -1;
      if (a.tagName.toLowerCase() > b.tagName.toLowerCase()) return 1;
    });

    return tagAccounts;
  }

  // Update number format to be comma separated
  formatDataForTopSectionOfWidgets(summary: IVariableInventorySummaryDTO) {
    this.filteredPageCount = this.variableInventoryService.addCommas(summary.filteredPageCount);
    this.totalPageCount = this.variableInventoryService.addCommas(summary.totalPageCount);

    this.filteredUniqueTagsCount = this.variableInventoryService.addCommas(summary.filteredUniqueTagCount);
    this.totalUniqueTagsCount = this.variableInventoryService.addCommas(summary.totalUniqueTagCount);

    this.filteredTagRequestsCountFormatted = this.variableInventoryService.addCommas(summary.filteredTagInstanceCount);
    this.filteredTagRequestsCount = summary.filteredTagInstanceCount || 0;
    this.totalTagRequestsEvaluatedCount = this.variableInventoryService.addCommas(summary.totalTagInstanceCount);

    this.filteredUniqueVariableNameCount = this.variableInventoryService.addCommas(summary.filteredUniqueVariableNameCount);
    this.totalUniqueVariableNameCount = this.variableInventoryService.addCommas(summary.totalUniqueVariableNameCount);

    this.filteredUniqueVariableValueCountFormatted = this.variableInventoryService.addCommas(summary.filteredUniqueVariableValueCount);
    this.filteredUniqueVariableValueCount = summary.filteredUniqueVariableValueCount || 0;
    this.totalUniqueVariableValueCount = this.variableInventoryService.addCommas(summary.totalUniqueVariableValueCount);
  }

  // Setup data for widget sparkline config
  formatDataForWidgetSparklines(history: IVariableInventoryTrendsItem[]) {
    let uniqueTagsSparklineData = [];
    let tagInstanceSparklineData = [];
    let uniqueVariableNameSparklineData = [];
    let uniqueVariableValueSparklineData = [];

    history.forEach((dataPoint: IVariableInventoryTrendsItem, index: number) => {
      uniqueTagsSparklineData.push({value: dataPoint.totalUniqueTagCount, sequence: index});
      tagInstanceSparklineData.push({value: dataPoint.totalTagInstanceCount, sequence: index});
      uniqueVariableNameSparklineData.push({value: dataPoint.totalUniqueVariableNameCount, sequence: index});
      uniqueVariableValueSparklineData.push({value: dataPoint.totalUniqueVariableValueCount, sequence: index});
    });

    this.uniqueTagsSparklineData = uniqueTagsSparklineData;
    this.tagRequestsEvaluatedSparklineData = tagInstanceSparklineData;
    this.uniqueVariableNameSparklineData = uniqueVariableNameSparklineData;
    this.uniqueVariableValueSparklineData = uniqueVariableValueSparklineData;
  }

  private openFullscreenChart(trendName: EVariableInventoryTrendNames,
                              secondToLastCompletionDate: string,
                              getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]) {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName)
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  getFullscreenChartConfig(trendName: EVariableInventoryTrendNames) {
    switch (trendName) {
      case EVariableInventoryTrendNames.UNIQUE_TAGS:
        return UNIQUE_TAGS_CHART_CONFIG;
      case EVariableInventoryTrendNames.TAG_INSTANCE:
        return TAG_INSTANCE_CHART_CONFIG;
      case EVariableInventoryTrendNames.UNIQUE_VARIABLE_NAME:
        return UNIQUE_VARIABLE_NAME_CHART_CONFIG;
      case EVariableInventoryTrendNames.UNIQUE_VARIABLE_VALUE:
        return UNIQUE_VARIABLE_VALUE_CHART_CONFIG;
      default:
        return null;
    }
  }

  getFullscreenChartData(trendName: EVariableInventoryTrendNames, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.variableInventoryService.getVariableInventoryTrend(
      this.auditId,
      trendName,
      days,
    ).pipe(
      map((trendData: IVariableInventorySpecificTrendValuesDTO) => {
        return {
          chartData: trendData.runs.map((dataPoint: IVariableInventorySpecificTrendValueItem) => ({
            value: dataPoint.trendValue,
            date: dataPoint.completedAt,
          }))
        };
      })
    );
  }

  openUniqueTagsFullscreenChart() {
    if (this.uniqueTagsSparklineData?.length > 1) {
      this.openFullscreenChart(
        EVariableInventoryTrendNames.UNIQUE_TAGS,
        this.runHistory.length > 1 ? this.runHistory[this.runHistory.length - 2].runCompletionDate : undefined,
      );
    }
  }

  openTagRequestsEvaluatedFullscreenChart() {
    if (this.tagRequestsEvaluatedSparklineData?.length > 1) {
      this.openFullscreenChart(
        EVariableInventoryTrendNames.TAG_INSTANCE,
                this.runHistory.length > 1 ? this.runHistory[this.runHistory.length - 2].runCompletionDate : undefined,
      );
    }
  }

  openUniqueVariableNameFullscreenChart() {
    if (this.uniqueVariableNameSparklineData?.length > 1) {
      this.openFullscreenChart(
        EVariableInventoryTrendNames.UNIQUE_VARIABLE_NAME,
                this.runHistory.length > 1 ? this.runHistory[this.runHistory.length - 2].runCompletionDate : undefined,
      );
    }
  }

  openUniqueVariableValueFullscreenChart() {
    if (this.uniqueVariableValueSparklineData?.length > 1) {
      this.openFullscreenChart(
        EVariableInventoryTrendNames.UNIQUE_VARIABLE_VALUE,
                this.runHistory.length > 1 ? this.runHistory[this.runHistory.length - 2].runCompletionDate : undefined,
      );
    }
  }

  /**
   * Menu options for each tag in the Tag & Accounts table
   */
  getTagsAndAccountsMenuOptions(): IVariableInventoryTagsAndAccountsMenuItem[] {
    return [
      {
        label: 'Filter All Reports by this item',
        icon: 'tune',
        handler: (tag) => this.updateGlobalFilters(tag),
      },
    ];
  }

  /**
   * When clicking a tag in Tag & Accounts table, add the class to force the ellipsis menu
   * to remain visible
   */
  handleTagAndAccountsMenuClick(event: any) {
    event.stopPropagation();

    const menu = event.target;

    menu.parentElement.classList.add('force-show-hover-menu');
  }

  /**
   * On menu close, remove the class from all tags in the Tag & Accounts table that
   * keep the ellipsis menu visible
   */
  menuClosed(e) {
    // Remove show hover menu class from all tag accounts
    if (e === undefined) { // when the menu closes, this sends back undefined
      this.tagAndAccountsTable.nativeElement.querySelectorAll('.tag-and-accounts-menu-hover').forEach(node => node.classList.remove('force-show-hover-menu'));
    }
  }

  /**
   * Clear current selections from all tables and update the selected tag account.
   */
  selectTag(tag: IVariableInventoryTagAccount) {
    this.selectedVariable = EMPTY_VARIABLE;

    this.exportReportConfig.filters = {
      ...this.globalFilters,
      tagId: tag.tagId,
      tagAccount: tag.tagAccount,
    };
    this.variablesFilter.clearInput();
    this.clearAccountVariableSelection();
    this.clearPagesSelection();
    this.resetPagesTablePagination(0);
    this.variableInventoryService.selectTagAccount(tag);
  }

  /**
   * Clear account variable selections and reset all rows to not selected
   */
  clearAccountVariableSelection() {
    this.selectedVariableRowIndex = null;
    this.selectedVariable = EMPTY_VARIABLE;

    this.tagAccountVariablesDataSource.data.forEach((item) => {
      item.selected = EVariableItemSelection.NOT_SELECTED;
    });
  }

  /**
   * Clear pages table selections and hide it. Reset dataSource values for all pages tables.
   */
  clearPagesSelection() {
    this.displayPagesScannedSection = false;
    this.displayPagesTable = false;
    this.displayUniqueValuesTable = false;
    this.pagesTableDataSource.data = [];
    this.uniqueValuesPagesDataSource.data = [];
  }

  /**
   * Toggle the selected property for a tag account variable table row
   * @param i
   */
  handleTagAccountVariableRowClick(i) {
    this.selectedVariableRowIndex = this.tagAccountVariablesDataSource.data[i].selected !== EVariableItemSelection.NOT_SELECTED ? i : null;
  }

  /**
   * Handle updating table state and retrieving unique values when clicking a
   * row's '# Unique Values' column
   */
  filterVariablePagesByUniqueCount(variable: IVariableInventoryVariableItem) {
    this.resetPagesTablePagination(0);

    // Toggle off selected variable if already selected
    if (this.selectedVariable === variable && this.variableInventoryService.getPageFilter() === EVariableInventoryPagesType.UNIQUE) {
      this.deselectUniqueValues();
      return;
    }

    // Clear out old selection
    this.clearAccountVariableSelection();

    // Update reference to selected variable
    this.selectedVariable = variable;

    // Get index in dataSource of new selected variable
    let foundIndex = this.tagAccountVariablesDataSource.data
      .findIndex((item) => item.variableName === variable.variableName);

    // Set the selected state on row
    this.selectedVariableRowIndex = foundIndex;
    // Set the selected state on cell
    this.tagAccountVariablesDataSource.data[foundIndex].selected = EVariableItemSelection.UNIQUE_VALUE_COUNT;

    // Update page filter to kick off pages table load
    this.variableInventoryService.setPageFilter(EVariableInventoryPagesType.UNIQUE);
  }

  /**
   * Handle updating table state and retrieving unique values when clicking a row's '...Variable is Set' column
   */
  filterVariablePagesByVariableSet(variable: IVariableInventoryVariableItem) {
    this.resetPagesTablePagination(0);

    // Toggle off selected variable if already selected
    if (this.selectedVariable === variable && this.variableInventoryService.getPageFilter() === EVariableInventoryPagesType.SET) {
      this.deselectVariableIsSet();
      return;
    }
    // Clear out old variable and row selection
    this.clearAccountVariableSelection();

    // Update reference to selected variable
    this.selectedVariable = variable;

    // Get index in dataSource of new selected variable
    let foundIndex = this.tagAccountVariablesDataSource.data
      .findIndex((item) => item.variableName === variable.variableName);

    // Set the selected state on row
    this.selectedVariableRowIndex = foundIndex;
    // Set the selected state on cell
    this.tagAccountVariablesDataSource.data[foundIndex].selected = EVariableItemSelection.VALUE_SET_COUNT;

    // Update page filter to kick off pages table load
    this.variableInventoryService.setPageFilter(EVariableInventoryPagesType.SET);
  }

  deselectUniqueValues() {
    this.clearAccountVariableSelection();
    this.clearPagesSelection();
    this.variableInventoryService.setPageFilter(null);
    this.uniqueValuesPagesDataSource.data = null;

    // Load tag accounts pages if toggling off current selection
    this.variableInventoryService.setPageFilter(EVariableInventoryPagesType.TAG_ACCOUNTS);
  }

  deselectVariableIsSet() {
    this.clearAccountVariableSelection();
    this.clearPagesSelection();
    this.variableInventoryService.setPageFilter(null);
    this.pagesTableDataSource.data = null;

    // Load tag accounts pages if toggling off current selection
    this.variableInventoryService.setPageFilter(EVariableInventoryPagesType.TAG_ACCOUNTS);
  }

  deselectVariableNotSet() {
    this.clearAccountVariableSelection();
    this.clearPagesSelection();
    this.variableInventoryService.setPageFilter(null);
    this.pagesTableDataSource.data = null;

    // Load tag accounts pages if toggling off current selection
    this.variableInventoryService.setPageFilter(EVariableInventoryPagesType.TAG_ACCOUNTS);
  }

  /**
   * Handle updating table state and retrieving unique values when clicking a
   * row's '...Variable is Not Set' column
   */
  filterVariablePagesByVariableNotSet(variable: IVariableInventoryVariableItem) {
    this.resetPagesTablePagination(0);

    // Toggle off selected variable if already selected
    if (this.selectedVariable === variable && this.variableInventoryService.getPageFilter() === EVariableInventoryPagesType.NOT_SET) {
      this.deselectVariableNotSet();
      return;
    }
    // Clear out old selection
    this.clearAccountVariableSelection();
    this.clearPagesSelection();

    // Update reference to selected variable
    this.selectedVariable = variable;

    // Get index in dataSource of new selected variable
    let foundIndex = this.tagAccountVariablesDataSource.data
      .findIndex((item) => item.variableName === variable.variableName);

    // Set the selected state on row
    this.selectedVariableRowIndex = foundIndex;
    // Set the selected state on cell
    this.tagAccountVariablesDataSource.data[foundIndex].selected = EVariableItemSelection.VALUE_NOT_SET_COUNT;

    // Update page filter to kick off pages table load
    this.variableInventoryService.setPageFilter(EVariableInventoryPagesType.NOT_SET);
  }

  /**
   * Any time a tag is selected, update the filters and fetch data
   */
  setReportLevelFiltersAndUpdateData(tag: IVariableInventoryTagAccount) {
    if (tag) {
      // Get global filters, apply report filters, call API
      this.variableInventoryService.setReportLevelFilters(tag);
      this.fetchTagAccountVariables();
    }
  }

  /**
   * Update global filters
   */
  updateGlobalFilters(tag: IVariableInventoryTagAccount) {
    if (tag) {
      this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);
      this.filterBarService.addTagAccountFilter(tag.tagAccount);
    }
  }

  /**
   * Set class on statusCode elements for color coding
   */
  getStatusClass(statusCode: number): string {
    return statusCode === 0 || statusCode >= 400 ?
      'vi-fail' : statusCode >= 300 ?
        'vi-warning' : statusCode >= 200 ?
          'vi-pass' : '';
  }

  /**
   * Set class on loadTime elements for color coding
   */
  getLoadTimeClass(loadTime: number) {
    return loadTime > 10 ?
      'vi-fail' : loadTime > 6 ?
        'vi-warning' : 'vi-pass';
  }

  expandUniqueValueRow(value) {
    this.expandedValue = value === this.expandedValue ? null : value;
    if (this.expandedValue) {
      const tag = this.variableInventoryService.getSelectedTagAccount();
      // Start loading spinner
      this.pageTableState = EFilterSpinnerState.Loading;
      this.expandedValuePages = [];

      // Get query params
      const queryParams = {...this.variableValuesQueryParams, ...this.pagesTablePagination};

      // Get pages table data
      this.variableInventoryService.getVariableInventoryTagAccountVariableValuePageList(
        this.auditId,
        this.runId,
        tag.tagId,
        tag.tagAccount,
        this.selectedVariable.variableName,
        value.variableValue,
        queryParams,
        this.globalFilters,
      ).pipe(
        take(1)
      ).subscribe(data => {
        const filtering = this.filterBarService.currentRelevantFilters.length > 0;
        this.pageTableState = filtering ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
        data.pages = data.pages.map(page => {
          page.pageLoadTime = this.variableInventoryService.formatToOneDecimalPlace(page.pageLoadTime / 1000);
          return page;
        });
        this.expandedValuePages = data.pages.sort((a, b) => a.tagWithVariableValueCount > b.tagWithVariableValueCount ? -1 : 1);
      }, error => {
        const filtering = this.filterBarService.currentRelevantFilters.length > 0;
        this.pageTableState = filtering ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
        console.log('Failed to load variable value pages: ', error);
      });
    }
  }

  /**
   * Handle loading next page of variable pages data
   */
  loadPage(pageEvent: PageEvent) {
    const pageType = this.variableInventoryService.getPageFilter();
    this.pagesTablePagination.currentPageNumber = pageEvent.pageIndex;
    this.scrollService.scrollByElement(this.pagesTableSectionScrollTo.nativeElement);
    this.updatePages(pageType);
  }

  // @ts-ignore
  openPageDetails(page: IVariableInventoryVariableNotSetPagesItem|IVariableInventoryPagesItem|IVariableInventoryVariableValuePageItem) {
    const state = {
      [TAGS_SEARCH_TEXT_KEY]: this.selectedTagName
    };

    let selectedPage: IPageListPage = { id: page.pageId, url: page.pageUrl };
    this.pageIdOpenInPageDetails = page.pageId;
    this.pageDetailsDrawerService.openPageDetails(selectedPage, this.auditId, this.runId, EPageDetailsTabs.Tags, null, state);
  }

  handlePageDetailsClosed() {
    this.pageIdOpenInPageDetails = null;
  }

  copyToClipboard(value: string) {
    this.clipboardService.copy(value);
  }

  copyToClipboardTooltipText(text: string): string {
    return `${text}\n(click to copy)`;
  }

  filteringVariables(searchedVariable: string) {
    this.tagAccountVariablesDataSource.filter = searchedVariable;
  }

  private mapUniqueValuesTableColumnToSortColumn(tableColName: CommonReportsPagesTableColumns): CommonReportsPagesTableColumns {
    if (tableColName === CommonReportsPagesTableColumns.PageUrl) {
      return CommonReportsPagesTableColumns.PageCount;
    } else if (tableColName === CommonReportsPagesTableColumns.VariableValue) {
      return CommonReportsPagesTableColumns.VariableValue;
    } else {
      return undefined;
    }
  }

  private resetPagesTablePagination(newPage?: number) {
    const p = newPage || 0;
    if (this.pagesTablePagination) {
      this.pagesTablePagination.currentPageNumber = p;
    }
    if (this.paginator) {
      this.paginator.pageIndex = p;
    }
  }

  private formatPaginator() {
    if (!this.paginator) return;

    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }

  handleVariableValueClick(event: KeyboardEvent) {
    event.stopPropagation();
    this.snackbar.open('Copied to clipboard', null, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
