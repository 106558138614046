import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { IUser } from '@app/moonbeamModels';
import { ComponentChanges } from '@app/models/commons';
import { EExportCenterTableColumn } from './export-center-table.constants';
import { IExport, IExportCenterTableState, IExportsResponse } from '../../export-center.models';
import {
  EExportCenterDataStatus,
  EExportCenterItemType,
  EExportStatus, ERunExportType
} from '../../export-center.enums';
import { AuditReportUrlBuilders } from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IExportCenterRouterLink,
  IExportCenterTableRow
} from '@app/components/export-center/components/table/export-center-table.models';
import { WebJourneyUrlBuilders } from '@app/components/web-journey/web-journey.constants';
import { EWJResultsTab } from '@app/components/web-journey-report/web-journey-results/web-journey-results.enums';
import { LiveConnectUrlBuilders } from '@app/components/live-connect/live-connect.constants';
import { ConsentCategoriesUrlBuilders } from '@app/components/consent-categories/consent-categories.constants';
import { ExportCenterUrlBuilders } from '@app/components/export-center/export-center.constants';
import { UsageUrlBuilders } from '@app/components/usage-v2/usage-v2.constants';
import { DateService, EDateFormats } from '@app/components/date/date.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'export-center-table',
  templateUrl: './export-center-table.component.html',
  styleUrls: ['./export-center-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportCenterTableComponent implements OnInit, OnChanges {

  @Input() exportsDTO?: IExportsResponse;
  @Input() userIdToUserMap?: Map<number, IUser>;
  @Input() tableState: IExportCenterTableState;
  @Input() dataStatus: EExportCenterDataStatus;
  @Input() showLoading: boolean;

  @Output() onSort = new EventEmitter<MatSort>();
  @Output() onPagination = new EventEmitter<PageEvent>();
  @Output() onCopyLink = new EventEmitter<IExport>();
  @Output() onDownloadExport = new EventEmitter<IExport>();
  @Output() onSendReport = new EventEmitter<IExport>();

  @ViewChild(MatSort, {static: true}) tableSort: MatSort;
  @ViewChild(MatPaginator, {static: true}) tablePaginator: MatPaginator;

  tableDataSource = new MatTableDataSource<IExportCenterTableRow>();
  readonly columnsToDisplay = [
    EExportCenterTableColumn.dateExported,
    EExportCenterTableColumn.itemName,
    EExportCenterTableColumn.itemType,
    EExportCenterTableColumn.runDate,
    EExportCenterTableColumn.exportName,
    EExportCenterTableColumn.requestedByUserId,
    EExportCenterTableColumn.options
  ];

  readonly dateFormat = 'MMM d, y';
  readonly timeFormat = 'hh:mm:ss a';

  readonly tableColumn = EExportCenterTableColumn;
  readonly exportStatus = EExportStatus;
  readonly dataStatusType = EExportCenterDataStatus;

  readonly copiedIconTimeout = 2000;
  copiedIconTimeoutId: ReturnType<typeof setTimeout>;
  copiedExportLink: string = null;

  constructor(private dateService: DateService) {
  }

  ngOnInit(): void {
    this.prepareData();
  }

  ngOnChanges({exportsDTO, userIdToUserMap}: ComponentChanges<ExportCenterTableComponent>) {
    if (exportsDTO?.currentValue !== exportsDTO?.previousValue ||
        userIdToUserMap?.currentValue !== userIdToUserMap?.previousValue) {
      this.prepareData();
    }
  }

  get pagination() {
    return this.exportsDTO?.metadata?.pagination ?? null;
  }

  copyLink(exportDetails: IExportCenterTableRow) {
    this.copiedExportLink = exportDetails.exportDownloadLink;
    clearTimeout(this.copiedIconTimeoutId);
    this.copiedIconTimeoutId = setTimeout(
      () => this.copiedExportLink = null,
      this.copiedIconTimeout
    );

    this.onCopyLink.emit(exportDetails);
  }

  private prepareData() {
    if (this.dataStatus === EExportCenterDataStatus.loaded) {
      this.tableDataSource.data = this.exportsDTO.exports.map(exportDetails => ({
        ...exportDetails,
        itemName: exportDetails.itemName || exportDetails.exportName,
        itemLabel: ExportCenterTableComponent.itemTypeToExportLabel(exportDetails.itemType),
        runDate: this.formatRunDate(exportDetails.runDate),
        user: this.userIdToUserMap.get(exportDetails.requestedByUserId),
        routerLink: ExportCenterTableComponent.buildLink(exportDetails)
      }));
    }
  }

  private formatRunDate(runDate: string) {
    if (runDate) {
      const dateObj = new Date(runDate);
      const date = this.dateService.formatDate(dateObj, this.dateFormat as EDateFormats);
      const time = this.dateService.formatDate(dateObj, this.timeFormat as EDateFormats);
      return `${date}</br>${time}`;
    } else {
      return '---';
    }
  }

  private static buildLink({specificExportType, itemId, runId}: IExport): IExportCenterRouterLink {
    switch (specificExportType) {
      case ERunExportType.Audit_AuditSummaryDetectedTags:
        return {url: AuditReportUrlBuilders.auditSummary(itemId, runId)};
      case ERunExportType.Audit_RuleSummaryPageResults:
      case ERunExportType.Audit_RuleSummaryPageRuleResults:
      case ERunExportType.Audit_RuleSummaryRules:
        return {url: AuditReportUrlBuilders.ruleSummary(itemId, runId)};
      case ERunExportType.Audit_PageSummaryPages:
      case ERunExportType.Audit_PageSummaryBrokenPages:
      case ERunExportType.Audit_NetworkRequestLogPageRequests:
        return {url: AuditReportUrlBuilders.pageSummary(itemId, runId)};
      case ERunExportType.Audit_TagInventoryPages:
      case ERunExportType.Audit_TagInventoryPageTags:
      case ERunExportType.Audit_TagMetadata:
        return {url: AuditReportUrlBuilders.tagInventory(itemId, runId)};
      case ERunExportType.Audit_TagHealthPages:
        return {url: AuditReportUrlBuilders.tagHealth(itemId, runId)};
      case ERunExportType.Audit_TagDuplicatesAndMultiplesPages:
        return {url: AuditReportUrlBuilders.duplicatesAndMultiples(itemId, runId)};
      case ERunExportType.Audit_VariableInventoryTagAccountPages:
      case ERunExportType.Audit_VariableInventoryTagAccountVariablePages:
      case ERunExportType.Audit_VariableInventoryTagAccountVariableNotSetPages:
      case ERunExportType.Audit_VariableInventoryTagAccountVariableValuePages:
      case ERunExportType.Audit_VariableInventoryTagVariables:
        return {url: AuditReportUrlBuilders.variableInventory(itemId, runId)};
      case ERunExportType.Audit_CookieInventoryPages:
      case ERunExportType.Audit_CookieInventoryCookiePages:
      case ERunExportType.Audit_CookieInventoryCookies:
      case ERunExportType.Audit_CookieInventoryCookiesAggregated:
        return {url: AuditReportUrlBuilders.cookieInventory(itemId, runId)};
      case ERunExportType.Audit_BrowserLogsPages:
      case ERunExportType.Audit_BrowserLogsPageLogs:
        return {url: AuditReportUrlBuilders.browserConsoleLogs(itemId, runId)};
      case ERunExportType.Audit_TagPrivacyCompliance:
      case ERunExportType.Audit_TagPrivacyPages:
      case ERunExportType.Audit_TagPrivacyTagPages:
        return {url: AuditReportUrlBuilders.privacyTags(itemId, runId)};
      case ERunExportType.Audit_CookiePrivacyCompliance:
      case ERunExportType.Audit_CookiePrivacyPages:
      case ERunExportType.Audit_CookiePrivacyCookiePages:
        return {url: AuditReportUrlBuilders.privacyCookies(itemId, runId)};
      case ERunExportType.Audit_RequestPrivacyCompliance:
      case ERunExportType.Audit_RequestPrivacyPages:
      case ERunExportType.Audit_RequestPrivacyRequestPages:
        return {url: AuditReportUrlBuilders.privacyRequests(itemId, runId)};
      case ERunExportType.Audit_FileChangesPages:
      case ERunExportType.Audit_FileChangesFilePages:
        return {url: AuditReportUrlBuilders.privacyFileChanges(itemId, runId)};

      case ERunExportType.WebJourney_ActionTags:
      case ERunExportType.WebJourney_ActionSummary:
        return {url: WebJourneyUrlBuilders.results(itemId, runId)};
      case ERunExportType.WebJourney_ActionTagVariables:
        return {
          url: WebJourneyUrlBuilders.results(itemId, runId),
          queryParams: {
            action: 1,
            tab: EWJResultsTab.TagsComparison
          }
        };
      case ERunExportType.WebJourney_ActionRuleSummary:
        return {
          url: WebJourneyUrlBuilders.results(itemId, runId),
          queryParams: {
            action: 1,
            tab: EWJResultsTab.Rules
          }
        };
      case ERunExportType.WebJourney_ActionNetworkRequests:
      case ERunExportType.WebJourney_ActionCookies:
      case ERunExportType.WebJourney_ActionBrowserLogs:
        return {
          url: WebJourneyUrlBuilders.results(itemId, runId),
          queryParams: {
            action: 1,
            tab: EWJResultsTab.ActionDetails
          }
        };

      case ERunExportType.LiveConnect_Full:
        return {url: LiveConnectUrlBuilders.manualJourneyEdit(itemId, runId)};

      case ERunExportType.ConsentCategories:
        return {url: ConsentCategoriesUrlBuilders.base()};

      case ERunExportType.AccountUsageAuditRuns:
      case ERunExportType.AccountUsageWebJourneyRuns:
        return {url: UsageUrlBuilders.base()};

      default: return { url: ExportCenterUrlBuilders.base()};
    }
  }

  private static itemTypeToExportLabel(itemType): string {
    switch (itemType) {
      case EExportCenterItemType.LiveConnect: return 'LiveConnect';
      case EExportCenterItemType.Audit: return 'Audit';
      case EExportCenterItemType.WebJourney: return 'Web Journey';
      case EExportCenterItemType.ConsentCategories: return 'Consent Categories';
      case EExportCenterItemType.AppJourney: return 'App Journey';
    }
  }
}
