import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

export interface ISnackbarSuccessData {
  message?: string;
  messages?: string[];
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'snackbar-success',
  templateUrl: './snackbar-success.component.html',
  styleUrls: ['./snackbar-success.component.scss']
})
export class SnackbarSuccessComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarSuccessData,
              public snackBarRef: MatSnackBarRef<SnackbarSuccessComponent>) {}

  close() {
    this.snackBarRef.dismiss();
  }
}
