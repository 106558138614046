import { ERuleConditionClause } from '@app/components/rules/rule-setup/rule-setup.enums';
import { EPageFilterType, ERuleMatchType, ERuleTagVariableSelectorType } from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';
export interface IRule {
  id?: number;
  name: string;
  recipients: string[];
  labels: IRuleLabel[];
  checkTimes: number;
  matchAllFilters: boolean;
  pageFilters: IRulePageFilter[];
  tags: IRuleTag[];
  fromTemplate?: boolean; // true if pre-defined rule
  isDefaultRule?: boolean; // true if default rule
  accountId?: number;
}

export interface IRuleLabel {
  id: number;
  name: string;
}
export interface IRulePageFilter {
  id?: number;
  ruleId?: number;
  value: string;
  type: EPageFilterType;
  matchType: ERuleMatchType;
}

export interface IRuleTag {
  id?: number;
  ruleId?: number;
  tagId: number;
  matchType: ERuleMatchType;
  clause: ERuleConditionClause;
  validationDescription?: string;
  account?: string;
  variables: IRuleTagVariable[];
  statusCode?: IRuleTagStatusCode;
}

export enum ERuleTagStatusCodeValueType {
  Specific = 'Specific',
  SuccessAndRedirect = 'SuccessAndRedirect',
  Broken = 'Broken',
}

export interface IRuleTagStatusCode {
  matchType: ERuleMatchType;
  valueType: ERuleTagStatusCodeValueType;
  value?: number;
}

export interface IRuleTagVariable {
  id?: number;
  ruleTagId?: number;
  variable: string;
  matchType: ERuleMatchType;
  value?: string;
  valueTagId?: number;
  selectorType?: ERuleTagVariableSelectorType;
  validationDescription?: string;
}

export interface IRuleV3 {
  id: number;
  name: string;
  accountId: number;
  createdBy: number;
  createdAt: string;
  updatedAt: string;
  recipients: string[];
  checkTimes: number;
  matchAllFilters: boolean;
  pageFilters: IRuleV3PageFilters[];
  tags: IRuleV3Tag[];
  labels: IRuleV3Label[];
  fromTemplate: boolean;
}

interface IRuleV3PageFilters {
  id: number;
  ruleId: number;
  value: string;
  type: string;
  matchType: string;
}

interface IRuleV3Tag {
  id: number;
  ruleId: number;
  tagId: number;
  matchType: string;
  account: string;
  validationDescription: string;
  clause: string;
  variables: IRuleV3TagVariable[];
}

interface IRuleV3TagVariable {
  id: number;
  ruleTagId: number;
  variable: string;
  value: string;
  validationDescription: string;
  matchType: string;
  valueTagId: number;
  selectorType: string;
}

export interface IRuleV3Label {
  id: number;
  name: string;
}

export interface IRuleV3WebJourneyRules {
  webJourneyRules: IRuleV3[];
  actionRules: IRuleV3ActionRules[];
}

export interface IRuleV3ActionRules {
  actionId: number;
  rules: IRuleV3[];
}

export interface IRuleV3UpdateRequest {
  webJourneyRules: number[];
  actionRules: IRuleV3ActionRulesUpdate[];
}

interface IRuleV3ActionRulesUpdate {
  actionId: number,
  rules: number[]
}

export interface IRulePreview {
  id?: number;
  name: string;
  isDefaultRule?: boolean;
  labels: IRuleLabel[];
}

export interface ITagVariableSnapshotId {
  originalRuleId: number;
  originalConditionId: number;
}

export interface ITagVariableConditionMetadata {
  snapshotId: number;
  originalRuleId: number;
  originalConditionId: number;
  validationDescription: string;
}
