import { Component, Inject, Input, Renderer2, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ManageCardsService } from '@app/components/manage/cards/manage-cards.service';
import { IAuditFrequency, IAuditSetupFormOptionData } from '@app/components/audit/audit-setup-form/audit-setup-form.models';
import { WebJourneyFrequencies } from '@app/components/web-journey/web-journey.constants';
import { IWebJourneyFrequency } from '@app/components/web-journey/web-journey.models';
import { EAuditSetupOpSelectors, EFieldLabels } from '@app/components/audit/audit-setup-form/audit-setup-form.constants';
import { EDateFormats, updateTime } from '@app/components/date/date.service';
import * as dateUtils from '@app/components/date/date.service';
import { isSafari } from '@app/components/utilities/browser.utils';
import { WindowRef } from '@app/components/core/services/window.service';
import { IUser } from '@app/moonbeamModels';
import { IGeoLocation, LocationsService } from '@app/components/creator/webJourney/locationsService';
import { IUserAgent, IUserAgentService } from '@app/components/domains/userAgentService.models';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AccountsService } from '@app/components/account/account.service';
import { Observable, forkJoin, from } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { StorageService } from '@app/components/shared/services/storage.service';
import { IOpRecurrenceResponse, IRecurrenceForm } from '@app/components/shared/components/op-recurrence/op-recurrence.models';
import { RecurrenceService } from '@app/components/shared/components/op-recurrence/op-recurrence.service';
import { ERecurrenceItemType, ERecurrencePresetNames } from '@app/components/shared/components/op-recurrence/op-recurrence.constants';
import { isPast } from 'date-fns';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bulk-run-frequency',
  templateUrl: './bulk-run-frequency.component.html',
  styleUrls: ['./bulk-run-frequency.component.scss'],
})
export class BulkRunFrequencyComponent implements OnInit {

  OP_SELECTORS = EAuditSetupOpSelectors;
  FIELD_LABELS = EFieldLabels;

  runFrequencyForm: FormGroup;

  journeyFrequencies: IWebJourneyFrequency[] = WebJourneyFrequencies;
  auditFrequencies: IAuditFrequency[] = [];
  processCompleted = false;

  @Input() selectedCards: any[] = [];

  snackBarDuration: number = 4000;
  haveSelectedAudits: boolean = false;
  haveSelectedJourneys: boolean = false;
  showDateTimePicker: boolean = true;
  showDateTimePickerLabel: boolean = true;
  auditsSelectedCount: number;
  journeysSelectedCount: number;
  minNextRunDate: Date;
  maxNextRunDate: Date;
  user: IUser;
  locations: IGeoLocation[] = [];
  userAgents: IUserAgent[] = [];

  recurrenceEnabled: boolean = false;
  recurrenceSchedule: IOpRecurrenceResponse;
  itemType: ERecurrenceItemType;
  showItemsWillRunWarning: boolean = false;
  showNewScheduleHasEndedWarning: boolean = false;

  private dataPromise: Promise<IAuditSetupFormOptionData>;

  rightFooterButtons = [
    {
      label: 'Save',
      action: () => {
        this.setNewFrequencies();
      },
      primary: true
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<BulkRunFrequencyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private manageCardsService: ManageCardsService,
    private snackBar: MatSnackBar,
    private window: WindowRef,
    private locationsService: LocationsService,
    private userAgentService: IUserAgentService,
    private authenticationService: AuthenticationService,
    private accountsService: AccountsService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private recurrenceService: RecurrenceService
  ) {
    this.selectedCards = this.data.selectedCards;
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
  }

  ngOnInit() {
    this.manageCardsService.loadAuditFrequencies()
      .then((frequencies: IAuditFrequency[]) => {
        this.auditFrequencies = frequencies;
      })
      .catch(error => {
        console.error(error);
    });

    this.runFrequencyForm = this.formBuilder.group({
      runJourneyFrequencyField: new FormControl([]),
      runAuditFrequencyField: new FormControl([]),
      nextRunDateField: new FormControl([]),
      nextRunTimeField: new FormControl([]),
      recurrence: new FormControl('')
    });

    this.configureDialog();

    this.dataPromise = this.getFormOptionData().toPromise();
    this.dataPromise.then((data: IAuditSetupFormOptionData) => {
      this.user = data.user;
      this.locations = data.locations;
      this.userAgents = data.userAgents;
    }, err => {
      console.error(err);
      alert('There was an error obtaining the data for this page. Please reload and try again');
    });

    this.initListeners();
  }

  private initListeners(): void {
    this.recurrenceField.valueChanges.subscribe((value: IRecurrenceForm) => {
      const selectedDateTime = updateTime(new Date(value.runDate.date), value.runTime.time);
      const dateIsInThePast = isPast(selectedDateTime);

      this.showItemsWillRunWarning = dateIsInThePast && value.frequency.presetType === ERecurrencePresetNames.RunOnce;
      this.showNewScheduleHasEndedWarning = dateIsInThePast && value.frequency.presetType === ERecurrencePresetNames.Custom;
    });
  }

  private configureDialog(): void {
    if (this.recurrenceEnabled) {
      this.recurrenceService.getDefaultSchedule().subscribe(schedule => {
        this.recurrenceSchedule = schedule;
      });

      if (this.haveSelectedAudits && this.haveSelectedJourneys) {
        this.itemType = ERecurrenceItemType.Generic;
      } else if (this.haveSelectedAudits) {
        this.itemType = ERecurrenceItemType.Audit;
      } else if (this.haveSelectedJourneys) {
        this.itemType = ERecurrenceItemType.Journey;
      } else {
        // default
        this.itemType = ERecurrenceItemType.Generic;
      }
    } else {
      this.minNextRunDate = new Date();
      this.maxNextRunDate = new Date();
      this.maxNextRunDate.setFullYear(this.minNextRunDate.getFullYear()+20);

      this.nextRunDateField.setValue(this.minNextRunDate);
      const use24Hour = (this.window) ? !isSafari(this.window) : true;  // This odd statement is for jasmine tests.
      const nextRunTime = dateUtils.formatDate(this.minNextRunDate, use24Hour ? EDateFormats.timeTwo : EDateFormats.timeThree);
      this.nextRunTimeField.setValue(nextRunTime);
    }

    this.haveSelectedAudits = this.manageCardsService.haveAuditsSelected(this.selectedCards);
    this.haveSelectedJourneys = this.manageCardsService.haveJourneysSelected(this.selectedCards);
    this.auditsSelectedCount = this.manageCardsService.auditsSelectedCount(this.selectedCards);
    this.journeysSelectedCount = this.manageCardsService.journeysSelectedCount(this.selectedCards);
  }

  private getFormOptionData(): Observable<IAuditSetupFormOptionData> {
    const promises = [];
    const locationPromise = this.locationsService.getAllLocations();
    const userAgentPromise = this.userAgentService.getUserAgents();

    promises.push(locationPromise, userAgentPromise);
    const allPromise = from(Promise.all(promises));
    return this.authenticationService.getFeaturesWithCache()
      .pipe(
        mergeMap(features =>
          forkJoin(
            allPromise,
            this.authenticationService.getAccountWithCache(),
            this.loadUser(),
            ([locations, userAgents], account, user) => ({
              account, locations, features, userAgents, user
              })
            )
        )
      );
  }
  private loadUser(): Observable<IUser> {
    return this.accountsService.getUser().pipe(
      tap((user: IUser) => this.user = user)
    );
  }

  onSelectChange(event: any): void {
    if(event.value === this.journeyFrequencies[0].key) {
      if(!this.haveSelectedAudits) {
        this.showDateTimePicker = false;
        this.showDateTimePickerLabel = true;
      } else {
        this.showDateTimePicker = true;
        this.showDateTimePickerLabel = false;
      }
    } else {
      this.showDateTimePicker = true;
      this.showDateTimePickerLabel = true;
    }
  }

  // Set frequencies on selected cards
  private setNewFrequencies() {
    if (this.recurrenceEnabled) {
      const combinedDateTime = this.recurrenceService.combineDateAndTime(
        this.recurrenceField.value.runDate.date,
        this.recurrenceField.value.runTime.time
      );

      const schedule = {
        dtStart: combinedDateTime,
        tzId: this.recurrenceField.value.runTime.timeZone,
        recurrenceRule: this.recurrenceField.value.frequency.recurrenceRule,
        isPaused: this.recurrenceField.value.frequency.isPaused
      }

      this.dialogRef.close({ schedule, recurrenceEnabled: true });
    } else {
      if((this.haveSelectedAudits && this.runAuditFrequencyField().value.length === 0) ||
        (this.haveSelectedJourneys && this.runJourneyFrequencyField().value.length === 0)) {
        this.showSnackbarError('Please select a frequency.');
        return;
      }

      if(this.nextRunDateField.errors) {
        this.showSnackbarError('Please enter a valid date.');
        return;
      }

      this.rightFooterButtons[0]['disabled'] = true;

      // Combine the date and time fields into one
      let newDate: Date = new Date(this.nextRunDateField.value);
      const hourMin: string = this.nextRunTimeField.value;
      const newHour: number = +hourMin.substring(0, hourMin.indexOf(":"));
      const newMinute: number = +hourMin.substring(hourMin.indexOf(":")+1);
      newDate.setHours(newHour, newMinute);

      const frequencies = [
        this.runAuditFrequencyField().value,
        this.runJourneyFrequencyField().value,
        newDate,
      ];

      this.dialogRef.close(frequencies);
    }
  }

  onTimeFocus(control: string) {
    const c = this.runFrequencyForm.get(control);
    const timeValue = c.value ? dateUtils.formatDate(dateUtils.parseDate(c.value, EDateFormats.timeTwo), EDateFormats.timeTwo) : dateUtils.timeStringToInputValue(new Date(), !isSafari(this.window));

    c.setValue(timeValue);
  }

  private showSnackbarError(errorMessage: string): void {
    this.snackBar.open(
      errorMessage,
      '',
      { duration: this.snackBarDuration, horizontalPosition: 'center', verticalPosition: 'top' }
    );
  }

  displayHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.addClass(hint, 'show-hint');
  }

  hideHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.removeClass(hint, 'show-hint');
  }

  runAuditFrequencyField(): FormArray {
     return this.runFrequencyForm.get('runAuditFrequencyField') as FormArray;
  }

  runJourneyFrequencyField(): FormArray {
     return this.runFrequencyForm.get('runJourneyFrequencyField') as FormArray;
  }

  get nextRunDateField(): AbstractControl {
    return this.runFrequencyForm.get('nextRunDateField');
  }

  get nextRunTimeField(): AbstractControl {
    return this.runFrequencyForm.get('nextRunTimeField');
  }

  get recurrenceField(): AbstractControl {
    return this.runFrequencyForm.get('recurrence');
  }

  closeModal() {
    this.dialogRef.close();
  }
}
