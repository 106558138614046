import { AngularNames, Names, ServerErrorCodes } from '@app/moonbeamConstants';
import {DeleteControllerBase, IDeleteScope, IDisplayItem} from '../deleteControllerBase';
import {IDomain, IDomainsService} from '../../domains/domainsService';
import {IDomainActionsService} from '../../../store/actions/domainActions';
import * as ngRedux from 'ng-redux';
import * as angular from 'angular';
import { TerminateActiveRunsModalService } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';
import { ModalType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import { AccountsService } from '@app/components/account/account.service';

export class DeleteDomainController extends DeleteControllerBase<IDomain> {

    static $inject = [
      AngularNames.scope,
      AngularNames.ngRedux,
      DeleteDomainController.Dependencies.deleteHandler,
      AngularNames.q,
      Names.Services.domains,
      Names.Services.domainActions,
      Names.NgServices.accountsService,
      Names.NgServices.terminateActiveRunsModalService
    ];

    content: Array<IDisplayItem> = [];

    constructor(protected $scope: IDeleteScope<IDomain>,
                private $ngRedux: ngRedux.INgRedux,
                protected onDeleteHandler: Function,
                private $q: angular.IQService,
                private domainsService: IDomainsService,
                private domainActions: IDomainActionsService,
                private accountsService: AccountsService,
                private terminateActiveRunsModalService: TerminateActiveRunsModalService) {
        super($scope, onDeleteHandler);
        this.init();
    }

    protected createDisplayItem(item: IDomain): IDisplayItem {
      return {
        name: item.name,
        createdAt: item.createdAt,
        type: 'Domain'
      };
    }

    protected delete(item: IDomain): angular.IPromise<boolean> {
      return this.$ngRedux.dispatch(this.domainActions.deleteDomain(item.id, item.folderId));
    }

    private init(): void {
      this.loadContents();
    }

    private loadContents(): void {
      this.accountsService.getUser().subscribe(currentUser => {
        this.$q.all([this.domainsService.getAuditsByDomain(this.item.id),
                    this.domainsService.getSimulationsByDomain(this.item.id)]).then((data) => {
          this.content = data[0].map((a) => {
            return {
              type: 'Audit',
              name: a.name,
              createdAt: a.created,
              authorized: this.checkUser(a.ownerId, currentUser)
            };
          }).concat(data[1].map((wj) => {
            return {
              type: 'Web Journey',
              name: wj.name,
              createdAt: wj.createdAt,
              authorized: this.checkUser(wj.userId, currentUser)
            };
          })).sort((item1, item2) => {
            return item1.name ? item1.name.localeCompare(item2.name) : -1;
          });
        });
      });
    }

    protected handleError(error: any): void {
      if (error.errorCode === ServerErrorCodes.alreadyRunning) {
        this.close();
        this.terminateActiveRunsModalService.showTerminateActiveRunsModal(ModalType.Domain, error.items);
      }
    }
  }

