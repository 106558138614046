<div class="op-selector-with-icons">
  <ng-select [class.hiddenSelectedValue]="searchText || searchText === ''"
             [placeholder]="label"
             [items]="data"
             [groupBy]="groupBy"
             [virtualScroll]="true"
             [(ngModel)]="selectedItem"
             (ngModelChange)="onChange($event)"
             appendTo="body"
             [searchFn]="searchItem.bind(this)"
             [clearable]="clearable"
             (blur)="onTouched()"
             (open)="onOpen()"
             (close)="onClose()">
    <ng-template ng-label-tmp let-item="item" >
      <div class="selected-option flex-row flex-center-items">
        <img *ngIf="item.iconUrl" [src]="item.iconUrl" class="item-icon"/>
        <i *ngIf="item.iconClass" class="item-icon icon-class {{item.iconClass}}"></i>
        <span class="item-name">{{item.name}}</span>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm" >
      <div class="option-tmp flex-row flex-center-items">
        <img *ngIf="item.iconUrl" [src]="item.iconUrl" class="item-icon"/>
        <i *ngIf="item.iconClass" class="item-icon icon-class {{item.iconClass}}"></i>
        <span [innerHTML]="item.name | highlightTextPipe : search"></span>
      </div>
    </ng-template>
  </ng-select>
</div>
