<div #menuTrigger="matMenuTrigger"
     [matMenuTriggerFor]="menu"
     (mouseenter)="mouseEnter(menuTrigger)"
     (mouseleave)="mouseLeave(menuTrigger)"
     [ngClass]="setClassForActiveMenu()"
>
  <ng-content select="[trigger]"></ng-content>
</div>
<mat-menu #menu="matMenu" [hasBackdrop]="false" [class]="menuClass">
  <div (mouseenter)="mouseEnter(menuTrigger)" (mouseleave)="mouseLeave(menuTrigger)">
    <ng-content select="[content]"></ng-content>
  </div>
</mat-menu>