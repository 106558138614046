import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
  CommonReportsPagesFiltersConfig,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';

import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export const placeholderConst: string = 'placeholder';

export enum ERuleSummaryExportType {
  Pages = 'rule_summary_page_results',
  RulePages = 'rule_summary_page_rule_results'
}

export const RuleSummaryRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,

  EAuditReportFilterTypes.TagId,
  EAuditReportFilterTypes.TagVendorId,
  EAuditReportFilterTypes.TagCategory,
  EAuditReportFilterTypes.TagAccount,
  EAuditReportFilterTypes.TagPrimaryTagsOnly,

  EAuditReportFilterTypes.RuleStatus,
  EAuditReportFilterTypes.RuleId
];

export const PagesSpecificTableColumns: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.ConditionResult]: {
    title: 'Result',
    checked: true,
  }
};

export const PagesTableColumns: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.RuleFailureCount]: {
    title: 'Rule Failures',
    checked: true,
  }
};
