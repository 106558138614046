import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, pipe, ReplaySubject, Subject } from 'rxjs';
import { IPagesTableLocalStorageConfig } from '@app/components/audit-reports/audit-report-container.models';
import { CommonPagesConfigLocalStorageKey } from '@app/components/audit-reports/reports/general-reports.constants';
import { StorageService } from '@app/components/shared/services/storage.service';
import {
  IResizeableColumnProps
} from '@app/components/shared/directives/resizeable-table-column/resizeable-columns.model';
import { debounceTime } from 'rxjs/operators';
import { ResizeableTableUtils } from '@app/components/utilities/resizeable-table.utils';
import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

@Injectable()
export class ResizeableTableService {

  tableCheckboxConfig = new ReplaySubject<ISwitchableMenuItems>(1);
  displayedColumns = new BehaviorSubject<string[]>([]);
  checkboxConfigChanged = new Subject<ISwitchableMenuItems>();
  columnsResized = new Subject<HTMLElement>();

  tableCheckboxConfig$ = this.tableCheckboxConfig.asObservable();
  displayedColumns$ = this.displayedColumns.asObservable();
  checkboxConfigChanged$ = this.checkboxConfigChanged.asObservable();

  constructor(private storage: StorageService) {
    this.columnsResized.asObservable()
      .pipe(debounceTime(500))
      .subscribe((tHeadElement: HTMLElement) => {
        this.saveColumnSizes(this.getAllColumnSizes(tHeadElement));
      })
  }

  set pagesConfigInLocalStorage(value: IPagesTableLocalStorageConfig) {
    this.storage.setValue(CommonPagesConfigLocalStorageKey, value);
  }

  get pagesConfigInLocalStorage(): IPagesTableLocalStorageConfig {
    return this.storage.getValue(CommonPagesConfigLocalStorageKey);
  }

  setTableConfigInLocalStorage(key: string, value: IPagesTableLocalStorageConfig): void {
    if (!key) {
      console.error('Table config key is not provided');
      return;
    }
    this.storage.setValue(key, value);
  }

  getTableConfigInLocalStorage(key: string): IPagesTableLocalStorageConfig {
    if (!key) {
      console.error('Table config key is not provided');
      return;
    }
    return this.storage.getValue(key);
  }

  private getAllColumnSizes(tHeadElement: HTMLElement): IResizeableColumnProps[] {
    return ResizeableTableUtils.getAllColumns(tHeadElement)
      .map((cell, index) => ({
        width: Math.ceil(cell.getBoundingClientRect().width),
        name: ResizeableTableUtils.cleanTableClassName(cell.classList),
      }));
  }

  private saveColumnSizes(columns: IResizeableColumnProps[]): void {
    this.pagesConfigInLocalStorage = {
      ...this.pagesConfigInLocalStorage,
      sizes: columns
    };
  }
}
