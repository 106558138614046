import { Component, Inject } from '@angular/core';
import { IButton } from '../../../models/commons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ECCAssignView, CC_ASSIGN_STRINGS } from './cc-assign.constants';
import { ICCAssignWebAudits } from './cc-assign.models';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { ECIAppearance } from '@app/components/shared/components/op-clearable-input/op-clearable-input.constants';
import { EConsentCategoryType, IConsentCategory, IConsentCategories } from '@app/components/consent-categories/consent-categories.models';
import { IDataSource } from '@app/components/shared/services/data-sources/data-sources.models';
import { CCDataSourcesFilterBarService } from '../cc-data-sources-filter-bar/cc-data-sources-filter-bar.service';
import { ConsentCategoriesService } from '../consent-categories.service';
import { catchError } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-assign',
  templateUrl: './cc-assign.component.html',
  styleUrls: ['./cc-assign.component.scss']
})
export class CCAssignComponent  {

  ECCAssignView = ECCAssignView;
  ECIAppearance = ECIAppearance;
  ccAssignView: ECCAssignView = ECCAssignView.SELECT_CC;
  ccAssignStrings = CC_ASSIGN_STRINGS;
  assignedWebAudits: ICCAssignWebAudits[];

  selectedConsentCategories: IConsentCategory[] = [];
  selectedConsentCategoryNames: string;
  selectedType: EConsentCategoryType;
  selectedDataSources: IDataSource[] = [];

  rightFooterButtons: IButton[] = [
    {
      label: this.ccAssignStrings.nextBtn,
      action: this.modalNext.bind(this),
      primary: true,
      disabled: true,
      hidden: false,
      opSelector: this.ccAssignStrings.nextBtnOpSelector
    },
    {
      label: this.ccAssignStrings.prevBtn,
      action: this.modalPrev.bind(this),
      primary: false,
      disabled: false,
      hidden: true,
      opSelector: this.ccAssignStrings.prevBtnOpSelector
    },
    {
      label: this.ccAssignStrings.doneBtn,
      action: this.modalSave.bind(this),
      primary: true,
      disabled: true,
      hidden: true,
      opSelector: this.ccAssignStrings.doneBtnOpSelector
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<CCAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { labels: ILabel[], newImports?: IConsentCategory[] },
    private ccService: ConsentCategoriesService,
    private labelService: LabelService,
    private ccDataSourcesFilterBarSvc: CCDataSourcesFilterBarService
  ) {
    if(this.data.newImports) {
      this.getLabels().then(labels => {
        this.data.labels = labels;
      });
    }
  }

  ngOnInit(): void {
    this.selectedConsentCategories = this.data.newImports || [];
  }

  private async getLabels(): Promise<ILabel[]> {
    const labels = await this.labelService.getLabels().toPromise();
    return labels;
  }

  handleNextBtnState(): void {
    this.rightFooterButtons[0].disabled = !this.selectedConsentCategories.length;
  }

  handleDoneBtnState(): void {
    this.rightFooterButtons[2].disabled = !this.selectedDataSources.length;
  }

  modalNext(): void {
    this.ccAssignView = ECCAssignView.SELECT_REPORT;
    this.rightFooterButtons[0].hidden = true;
    this.rightFooterButtons[1].hidden = false;
    this.rightFooterButtons[2].hidden = false;
  }

  modalPrev(): void {
    this.ccAssignView = ECCAssignView.SELECT_CC;
    this.rightFooterButtons[0].hidden = false;
    this.rightFooterButtons[1].hidden = true;
    this.rightFooterButtons[2].hidden = true;
  }

  modalSave(): void {
    const assignedConsentCategories = this.selectedDataSources.map((dataSource: IDataSource) => {
      return this.ccService
        .getConsentCategoriesAssignedToAudit(dataSource.itemId)
        .pipe(catchError(() => of({})));
    });

    forkJoin(assignedConsentCategories).subscribe((res: IConsentCategories[][]) => {
      const patchRequests = this.selectedDataSources.map((dataSource: IDataSource, index: number) => {
        const oldConsentCategoryIDs = res[index].map(cc => cc.id);
        const newConsentCategoryIDs = this.selectedConsentCategories.map(cc => cc.id);

        return this.ccService
          .patchWebAuditCcs(dataSource.itemId, oldConsentCategoryIDs, newConsentCategoryIDs)
          .pipe(catchError(() => of({})));
      });

      forkJoin(patchRequests).subscribe(res => {
        this.closeModal(this.selectedConsentCategories.length, this.selectedDataSources.length);
      });
    });
  }

  updateCcSelection(selectedConsentCategories: IConsentCategory[]): void {
    this.selectedConsentCategories = selectedConsentCategories;
    this.selectedConsentCategoryNames = this.selectedConsentCategories.map(cc => cc.name).join(', ');
    this.selectedType = this.selectedConsentCategories.length
      ? this.selectedConsentCategories[0].type
      : null;
    this.handleNextBtnState();
  }

  updateDataSourceSelection(selectedDataSources: IDataSource[]): void {
    this.selectedDataSources = selectedDataSources;
    this.handleDoneBtnState();
  }

  closeModal(ccCount?: number, itemCount?: number): void {
    const returnValue = ccCount && itemCount ? { ccCount, itemCount } : null;
    this.dialogRef.close(returnValue);
  }
}
