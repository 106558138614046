import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuditReportBase, IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { BehaviorSubject, EMPTY, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  EAuditReportFilterTypes,
  ECookiePartyType,
  ECookieSameSiteTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { ActivatedRoute } from '@angular/router';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import {
  CookieInventoryService
} from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.service';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import {
  CookieFilterVariables,
  CookieInventoryCookiePagesRequestBody,
  CookieInventoryCookies,
  CookieInventoryPages,
  CookieInventoryPagesResponse,
  CookieInventoryPagesRow,
  CookieInventoryPagesTablePagination,
  CookieInventorySummary,
  CookieInventoryTrendRunOverview,
  CookieInventoryTrendRunOverviews,
} from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.models';
import { catchError, debounceTime, finalize, map, pluck, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { DecimalPipe, formatNumber } from '@angular/common';
import {
  IFullscreenChartData, IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import {
  CookieInventoryRelevantFilters,
  CookieInventoryTrendName,
  ECookieInventoryExportType,
  ECookiePartyTypeConverter,
  FIRST_PARTY_CHART_CONFIG,
  NON_SECURE_CHART_CONFIG,
  PagesTableSettingItems,
  SAME_SITE_EMPTY_CHART_CONFIG,
  THIRD_PARTY_CHART_CONFIG,
  UNIQUE_COOKIES_CHART_CONFIG
} from './cookie-inventory.constants';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  TagHealthPagesPagination,
  TagHealthTrendsByName
} from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import { sortBy } from 'lodash-es';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { isEmpty } from 'lodash';
import {
  ECookieInventoryFilterType
} from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.constants';
import {
  COOKIES_SEARCH_TEXT_KEY,
  formatPaginator,
  PageLoadColumnTooltip
} from '../../audit-report/audit-report.constants';
import {
  IAuditReportExportMenuData
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import { IAuditReportPageDetailsDrawerService } from '../../audit-report/audit-report-page-details-drawer.models';
import { AlertMetricType, EAlertCookieInventoryMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  CommonPagesColumnConfigWarningMessage,
  CommonPagesConfigLocalStorageKey,
  CommonReportsPagesTableColumns,
} from '@app/components/audit-reports/reports/general-reports.constants';
import { ICommonTableState } from '@app/components/shared/components/export-report/export-reports.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cookie-inventory',
  templateUrl: './cookie-inventory.component.html',
  styleUrls: [ './cookie-inventory.component.scss' ],
  providers: [ResizeableTableService]
})
export class CookieInventoryComponent extends AuditReportBase
  implements IFilterableAuditReport, OnInit, AfterViewInit, OnDestroy {
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  readonly PagesTableSettingItems = PagesTableSettingItems;
  readonly EFilterSpinnerState = EFilterSpinnerState;
  readonly ECookiePartyType = ECookiePartyType;

  // GENERAL
  auditId: number;
  runId: number;
  apiFilters: IAuditReportApiPostBody = {};
  totalPageCount: number = null;
  filteredPageCount: number = null;
  filteredFirstPartyCookieCount: number = null;
  filteredUniqueCookieCount: number = null;
  PageLoadColumnTooltip = PageLoadColumnTooltip;

  cookiesList$: Observable<CookieInventoryCookies>;
  cookiesTableState: EFilterSpinnerState;

  private filtersUpdated$ = new Subject<void>();

  //PAGES
  pagesTablePagination: CookieInventoryPagesTablePagination = {
    page: 0,
    size: 200,
    sortBy: CommonReportsPagesTableColumns.CookieCount,
    sortDesc: true
  };
  paginationState = {
    length: 0,
    pageSize: 0
  };
  pagesTableState: EFilterSpinnerState;
  dataSource = new MatTableDataSource<CookieInventoryPagesRow>();
  displayedColumns$ = this.tableService.displayedColumns$;
  pageIdOpenInPageDetails: string;
  cookiesVariablesUpdated$ = new Subject<void>();
  cookieVariables: CookieFilterVariables;

  //cookies
  private sortPaginateCookies$ = new Subject<null>();
  cookiesTablePagination: ICommonTableState = {
    sortDesc: true,
    sortBy: CommonReportsPagesTableColumns.PageCount,
    page: 0,
    size: 100
  };

  // WIDGETS
  widgetsState: EFilterSpinnerState;
  widgetSparklineRunsInfo: ISparklineRunInfo[];

  widgetPagesScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    metricType: EAlertCookieInventoryMetric.PagesScanned,
  };

  widgetUniqueCookies: ISplitCardChartData = {
    topLabel: 'Unique Cookies',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openUniqueCookiesFullscreenChart.bind(this),
    metricType: EAlertCookieInventoryMetric.UniqueCookies,
  };
  widgetUniqueCookiesSparklineData: ISparklineChartData[];

  widgetFirstParty: ISplitCardChartData = {
    topLabel: '1st Party',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.toggleCookieFilter(ECookiePartyType.first),
    bottomHandler: this.openFirstPartyFullscreenChart.bind(this),
    metricType: EAlertCookieInventoryMetric.FirstParty,
  };
  widgetFirstPartySparklineData: ISparklineChartData[];

  widgetThirdParty: ISplitCardChartData = {
    topLabel: '3rd Party',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.toggleCookieFilter(ECookiePartyType.third),
    bottomHandler: this.openThirdPartyFullscreenChart.bind(this),
    metricType: EAlertCookieInventoryMetric.ThirdParty,
  };
  widgetThirdPartySparklineData: ISparklineChartData[];

  widgetNonSecure: ISplitCardChartData = {
    topLabel: 'Non-Secure',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: () => {
      if (this.isFilteredByNonSecure) {
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.CookieSecure);
      } else {
        this.filterBarService.addCookieSecureFilter(false);
      }
    },
    bottomHandler: this.openNonSecureFullscreenChart.bind(this),
    metricType: EAlertCookieInventoryMetric.NonSecure,
  };
  widgetNonSecureSparklineData: ISparklineChartData[];
  sparklineDataLoaded = false;

  widgetSameSiteEmpty: ISplitCardChartData = {
    topLabel: 'SameSite Empty',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: () => {
      if (this.isFilteredBySameSite) {
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.CookieSameSite);
      } else {
        this.filterBarService.addCookieSameSiteFilter(ECookieSameSiteTypes.empty);
      }
    },
    bottomHandler: this.openSameSiteEmptyFullscreenChart.bind(this),
    metricType: EAlertCookieInventoryMetric.SameSiteEmpty,
  };
  widgetSameSiteEmptySparklineData: ISparklineChartData[];

  exportReportConfig: IAuditReportExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: ECookieInventoryExportType.Pages,
    totalRows: this.totalPageCount,
    filteredRows: this.filteredPageCount,
    tableState: this.pagesTablePagination,
    filters: this.apiFilters,
    specificExportTypes: {
      all: ECookieInventoryExportType.Pages
    },
    dataToCopy: {
      config: [
        {
          property: 'pageUrl',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl
        },
        {
          property: 'finalPageUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
        },
        {
          property: 'pageLoadTime',
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
        },
        {
          title: '# OF COOKIES',
          property: 'cookieCount',
          tableColumnName: CommonReportsPagesTableColumns.CookieCount
        },
        {
          title: 'TOTAL COOKIE SIZE (BYTES)',
          property: 'totalCookieSize',
          tableColumnName: CommonReportsPagesTableColumns.TotalCookieSize
        }
      ],
      data: null,
      displayedColumns$: this.tableService.displayedColumns$
    }
  };

  cookiesExportConfig: IAuditReportExportMenuData = {
    tableName: 'Cookies',
    exportType: ECookieInventoryExportType.Cookies,
    totalRows: 0,
    filteredRows: 0,
    tableState: {
      ...this.cookiesTablePagination
    },
    filters: this.apiFilters,
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('scrollTopPages', { static: false }) scrollTopPages: ElementRef;
  @ViewChild('scrollTopCookies', { static: false }) scrollTopCookies: ElementRef;
  @ViewChild('cookiesList') cookiesList: any;

  readonly TableColumn = CommonReportsPagesTableColumns;

  constructor(
    private route: ActivatedRoute,
    private auditReportService: AuditReportService,
    private cookieInventoryService: CookieInventoryService,
    private auditReportLoadingService: AuditReportLoadingService,
    private modalService: OpModalService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private scrollService: AuditReportScrollService,
    private decimalPipe: DecimalPipe,
    private alertReportingService: AlertReportingService,
    private tableService: ResizeableTableService,
  ) {
    super();

    this.route.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;

      const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
      const alertId = queryParams?.alertId;
      const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
      this.preventHighlight = !highlight;
      // When loading report from an alert, first override filters with alert
      // filters before loading report data
      if (alertId) {
        this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
          this.filterBarService.overrideFilters(alert.config.filtersV0);
          this.highlightMetricType = highlight && alert.config.metricType;

          this.alertCheckComplete$.next(true);
        });
      } else {
        this.alertCheckComplete$.next(true);
      }

      this.alertCheckComplete$.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
        this.onFiltersChanged(this.apiFilters);
      });
    });

    this.widgetsState = EFilterSpinnerState.Loading;
    this.pagesTableState = EFilterSpinnerState.Loading;
    this.cookiesTableState = EFilterSpinnerState.Loading;
  }

  ngOnInit(): void {
    this.initFilters();
    this.handleCookiesList();
    this.handleSparklines();
    this.handleWidgets();

    this.filterBarService.apiPostBody$
      .pipe(
        debounceTime(1000),
        takeUntil(this.onDestroy$)
      )
      .subscribe(this.onFiltersChanged.bind(this));

    // setup for page details
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.Cookies);
    this.pageDetailsDrawerService.pageDrawerClosed$.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handlePageDetailsClosed());
  }

  ngAfterViewInit(): void {
    this.sort.sortChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.pagesTablePagination.page = 0;
      });

    this.handlePagesTable();
    this.formatPaginator();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.pageDetailsDrawerService.closePageDetails();
    this.auditReportLoadingService.forceOff();
    this.destroy();
  }

  initFilters(): void {
    this.filterBarService.updateSupportedFiltersList(CookieInventoryRelevantFilters);
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody): void {
    this.exportReportConfig.filters = this.apiFilters = apiPostBody;
    this.exportReportConfig.tableState.page = this.pagesTablePagination.page = this.cookiesTablePagination.page = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.cookieVariables = undefined;
    this.setFirstPagePaginator();
    this.filtersUpdated$.next();
    this.updateCurrentFilters();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  handleWidgets(): void {
    this.filtersUpdated$.pipe(
      tap(() => {
        this.auditReportLoadingService.addLoadingToken();
        this.widgetsState = EFilterSpinnerState.Loading;
      }),
      switchMap(() => this.cookieInventoryService.getCookieInventorySummary(this.auditId, this.runId, this.apiFilters)),
      catchError(() => EMPTY),
      tap(() => {
        this.auditReportLoadingService.removeLoadingToken();
      })
    ).subscribe((data: CookieInventorySummary | undefined) => {
      if (data) {
        this.updateWidgets(data);
      } else {
        alert('Sorry! Some elements failed to update. Refresh your browser to try again.');
      }

      this.widgetsState = this.isFiltered() ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
    });
  }

  private updateWidgets(summary: CookieInventorySummary): void {
    this.totalPageCount = summary.totalPageCount;
    this.filteredPageCount = summary.filteredPageCount;
    this.filteredFirstPartyCookieCount = summary.filteredFirstPartyCookieCount;
    this.filteredUniqueCookieCount = summary.filteredUniqueCookieCount;
    this.exportReportConfig = {
      ...this.exportReportConfig,
      totalRows: this.totalPageCount,
      filteredRows: this.filteredPageCount
    };
    this.cookiesExportConfig = {
      ...this.cookiesExportConfig,
      totalRows: summary.totalUniqueCookieCount > 0 ? Infinity : 0,
    };
    this.widgetUniqueCookies.topChangeContent = this.formatWidgetContent(summary.filteredUniqueCookieCount, summary.totalUniqueCookieCount);
    this.widgetFirstParty.topChangeContent = this.formatWidgetContent(
      summary.filteredFirstPartyCookieCount,
      summary.totalFirstPartyCookieCount
    );

    this.widgetThirdParty.topChangeContent = this.formatWidgetContent(
      summary.filteredThirdPartyCookieCount,
      summary.totalThirdPartyCookieCount
    );
    this.widgetThirdParty.topChangeMeaning = summary.filteredThirdPartyCookieCount > 0
      ? ESplitCardChangeMeaning.NEGATIVE
      : ESplitCardChangeMeaning.POSITIVE;

    this.widgetNonSecure.topChangeContent = this.formatWidgetContent(summary.filteredNonSecureCookieCount);
    this.widgetNonSecure.topChangeMeaning = summary.filteredNonSecureCookieCount > 0
      ? ESplitCardChangeMeaning.NEGATIVE
      : ESplitCardChangeMeaning.POSITIVE;

    this.widgetSameSiteEmpty.topChangeContent = this.formatWidgetContent(summary.filteredSameSiteEmptyCookieCount);
    this.widgetSameSiteEmpty.topChangeMeaning = summary.filteredSameSiteEmptyCookieCount > 0
      ? ESplitCardChangeMeaning.NEGATIVE
      : ESplitCardChangeMeaning.POSITIVE;
  }

  handleSparklines(): void {
    this.filtersUpdated$.pipe(
      tap(() => {
        this.sparklineDataLoaded = false;
        this.auditReportLoadingService.addLoadingToken();
      }),
      switchMap(() => {
        return this.cookieInventoryService.getCookieInventoryTrends(this.auditId, this.runId);
      }),
      catchError(() => EMPTY),
      map((data: CookieInventoryTrendRunOverviews) => sortBy(data.runs, element => element.runId)),
      tap(() => {
        this.auditReportLoadingService.removeLoadingToken();
      })
    ).subscribe((data: CookieInventoryTrendRunOverview[] | undefined) => {
      if (data) {
        this.updateSparklines(data);
      }
      this.sparklineDataLoaded = true;
    });
  }

  private updateSparklines(dataPoints: CookieInventoryTrendRunOverview[]): void {
    const uniqueCookies: ISparklineChartData[] = [];
    const firstParty: ISparklineChartData[] = [];
    const thirdParty: ISparklineChartData[] = [];
    const nonSecure: ISparklineChartData[] = [];
    const sameSiteEmpty: ISparklineChartData[] = [];
    const runsInfo: ISparklineRunInfo[] = [];

    dataPoints.forEach((dataPoint, index: number) => {
      uniqueCookies.push({ value: dataPoint.totalUniqueCookieCount, sequence: index });
      firstParty.push({ value: dataPoint.totalFirstPartyCookieCount, sequence: index });
      thirdParty.push({ value: dataPoint.totalThirdPartyCookieCount, sequence: index });
      nonSecure.push({ value: dataPoint.totalNonSecureCookieCount, sequence: index });
      sameSiteEmpty.push({ value: dataPoint.totalSameSiteEmptyCookieCount, sequence: index });
      runsInfo.push({ runId: dataPoint.runId, runCompletionDate: dataPoint.completedAt });
    });

    this.widgetUniqueCookies.bottomHandler = uniqueCookies.length < 2 ? null : this.widgetUniqueCookies.bottomHandler;
    this.widgetFirstParty.bottomHandler = firstParty.length < 2 ? null : this.widgetFirstParty.bottomHandler;
    this.widgetThirdParty.bottomHandler = thirdParty.length < 2 ? null : this.widgetThirdParty.bottomHandler;
    this.widgetNonSecure.bottomHandler = nonSecure.length < 2 ? null : this.widgetNonSecure.bottomHandler;
    this.widgetSameSiteEmpty.bottomHandler = sameSiteEmpty.length < 2 ? null : this.widgetSameSiteEmpty.bottomHandler;

    this.sparklineDataLoaded = true;
    this.widgetUniqueCookiesSparklineData = uniqueCookies;
    this.widgetFirstPartySparklineData = firstParty;
    this.widgetThirdPartySparklineData = thirdParty;
    this.widgetNonSecureSparklineData = nonSecure;
    this.widgetSameSiteEmptySparklineData = sameSiteEmpty;

    this.widgetSparklineRunsInfo = runsInfo;
  }

  private formatWidgetContent(filteredCount: number, totalCount?: number): string {
    const format = (x: number) => formatNumber(x, 'en-US');
    if (!this.isFiltered() || (this.isFiltered() && !totalCount)) {
      return format(filteredCount);
    } else {
      return `${ format(filteredCount) } of ${ format(totalCount) }`;
    }
  }

  private isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.length > 0;
  }

  openFullscreenChart(
    trendName: CookieInventoryTrendName,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName)
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  getFullscreenChartData(trendName: CookieInventoryTrendName, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.cookieInventoryService
      .getCookieInventoryTrend(this.auditId, trendName, days)
      .pipe(
        map((data: TagHealthTrendsByName) => {
          return {
            chartData: data.runs.map(item => {
              return {
                value: item.trendValue,
                date: item.completedAt
              };
            })
          };
        })
      );
  }

  getFullscreenChartConfig(trendName: CookieInventoryTrendName) {
    switch (trendName) {
      case CookieInventoryTrendName.UniqueCookies:
        return UNIQUE_COOKIES_CHART_CONFIG;
      case CookieInventoryTrendName.FirstParty:
        return FIRST_PARTY_CHART_CONFIG;
      case CookieInventoryTrendName.ThirdParty:
        return THIRD_PARTY_CHART_CONFIG;
      case CookieInventoryTrendName.NonSecure:
        return NON_SECURE_CHART_CONFIG;
      case CookieInventoryTrendName.SameSiteEmpty:
        return SAME_SITE_EMPTY_CHART_CONFIG;
      default:
        return null;
    }
  }

  openUniqueCookiesFullscreenChart(): void {
    if (this.widgetUniqueCookiesSparklineData.length > 1) {
      this.openFullscreenChart(
        CookieInventoryTrendName.UniqueCookies,
        this.widgetSparklineRunsInfo.length > 1
          ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate
          : undefined
      );
    }
  }

  openFirstPartyFullscreenChart(): void {
    if (this.widgetFirstPartySparklineData.length > 1) {
      this.openFullscreenChart(
        CookieInventoryTrendName.FirstParty,
        this.widgetSparklineRunsInfo.length > 1
          ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate
          : undefined
      );
    }
  }

  openThirdPartyFullscreenChart(): void {
    if (this.widgetThirdPartySparklineData.length > 1) {
      this.openFullscreenChart(
        CookieInventoryTrendName.ThirdParty,
        this.widgetSparklineRunsInfo.length > 1
          ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate
          : undefined
      );
    }
  }

  openNonSecureFullscreenChart(): void {
    if (this.widgetNonSecureSparklineData.length > 1) {
      this.openFullscreenChart(
        CookieInventoryTrendName.NonSecure,
        this.widgetSparklineRunsInfo.length > 1
          ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate
          : undefined
      );
    }
  }

  openSameSiteEmptyFullscreenChart(): void {
    if (this.widgetSameSiteEmptySparklineData.length > 1) {
      this.openFullscreenChart(
        CookieInventoryTrendName.SameSiteEmpty,
        this.widgetSparklineRunsInfo.length > 1
          ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate
          : undefined
      );
    }
  }

  handleCookiesList() {
    this.cookiesList$ = merge(
      this.filtersUpdated$,
      this.sortPaginateCookies$
    ).pipe(
      debounceTime(300),
      tap((data) => {
        this.auditReportLoadingService.addLoadingToken();
        this.cookiesTableState = EFilterSpinnerState.Loading;
      }),
      switchMap(() => {
        return this.cookieInventoryService.getCookieInventoryCookies(this.auditId, this.runId, this.cookiesTablePagination, this.apiFilters)
          .pipe(
            tap(({ metadata }) => {
              this.cookiesExportConfig = {
                ...this.cookiesExportConfig,
                filters: this.apiFilters,
                filteredRows: metadata.pagination.totalCount,
                tableState: this.cookiesTablePagination,
              };
            }),
            tap(() => this.cookiesTableState = this.filterBarService.currentRelevantFilters.length ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None),
            finalize(() => this.auditReportLoadingService.removeLoadingToken())
          );
      })
    );
  }

  toggleCookieFilter(cookieType: ECookiePartyType) {
    return () => {
      if (this.isFilteredByCookie(cookieType)) {
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.CookiePartyType);
      } else {
        this.filterBarService.addCookiePartyFilter(cookieType);
      }
    };
  }

  isFilteredByCookie(cookieType: ECookiePartyType) {
    return this.filterBarService.isFilteredByTypeAndValue(EAuditReportFilterTypes.CookiePartyType, cookieType);
  }

  get isFilteredByNonSecure() {
    return this.filterBarService.isFilteredByTypeAndValue(EAuditReportFilterTypes.CookieSecure, false);
  }

  get isFilteredBySameSite() {
    return this.filterBarService.isFilteredByTypeAndValue(EAuditReportFilterTypes.CookieSameSite, ECookieSameSiteTypes.empty);
  }

  onScrollTop() {
    this.scrollService.scrollByElement(this.scrollTopPages.nativeElement);
  }

  handlePagesTable(): void {
    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.filtersUpdated$,
      this.cookiesVariablesUpdated$,
    ).pipe(
      debounceTime(500),
      tap(() => {
        this.auditReportLoadingService.addLoadingToken();
        this.pagesTableState = EFilterSpinnerState.Loading;
      }),
      switchMap((filter: any) => {
        if (filter !== undefined) {
          if (typeof filter.pageIndex === 'number') {
            this.pagesTablePagination.page = filter.pageIndex;
          }
          if (filter.active) {
            this.pagesTablePagination.sortBy = filter.active;
            this.pagesTablePagination.sortDesc = filter.direction === 'desc';
          }
        }

        if (!isEmpty(this.cookieVariables)) {
          return this.cookieInventoryService.getCookieInventoryCookiePages(
            this.auditId,
            this.runId,
            this.pagesTablePagination,
            { ...this.cookieVariables, ...this.apiFilters } as CookieInventoryCookiePagesRequestBody
          );
        }

        return this.cookieInventoryService.getCookieInventoryPages(this.auditId, this.runId, this.pagesTablePagination, this.apiFilters);
      }),
      catchError(() => {
        this.pagesTableState = EFilterSpinnerState.None;
        return of({ pages: [] });
      }),
      tap(() => {
        this.auditReportLoadingService.removeLoadingToken();
      })
    ).subscribe(({ pages, metadata }: CookieInventoryPagesResponse) => {
      this.initTable(pages);
      if (metadata?.pagination) {
        this.setPaginationData(metadata?.pagination);
      }
      this.updateExportButtonConfig();

      this.pagesTableState = this.filterBarService.currentRelevantFilters.length || this.cookieVariables
        ? EFilterSpinnerState.Filtered
        : EFilterSpinnerState.None;
    });
  }

  private setPaginationData(pagination: TagHealthPagesPagination) {
    this.paginationState.length = pagination.totalCount;
    this.paginationState.pageSize = pagination.pageSize;
  }

  private initTable(pages: CookieInventoryPages[]) {
    this.exportReportConfig.dataToCopy.data = this.dataSource.data = pages.map(item => {
      const loadTime = parseFloat((item.pageLoadTime / 1000).toFixed(1));
      return {
        ...item,
        pageLoadTime: loadTime,
        pageLoadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(loadTime),
        finalPageStatusCode: item.finalPageStatusCode,
        pageStatusCodeClass: this.auditReportService.getStatusCodeClass(item.finalPageStatusCode),
      };
    });
  }

  openPageDetails({ pageId, pageUrl }) {
    this.pageIdOpenInPageDetails = pageId;
    const cookieNameState = {
      [COOKIES_SEARCH_TEXT_KEY]: this.cookieVariables?.cookie?.name || '',
    };
    this.pageDetailsDrawerService.openPageDetails({ id: pageId, url: pageUrl }, this.auditId, this.runId, EPageDetailsTabs.Cookies, false, cookieNameState);
  }

  handlePageDetailsClosed() {
    this.pageIdOpenInPageDetails = null;
  }

  handleFilterByPage($event: CookieFilterVariables) {
    this.cookieVariables = $event;
    this.pagesTablePagination.page = 0;
    this.paginator.pageIndex = 0;
    this.cookiesVariablesUpdated$.next();
    this.onScrollTop();
  }

  handleSort(data: { active: string; direction?: string }) {
    this.cookiesTablePagination.sortBy = data.active;
    this.cookiesTablePagination.sortDesc = data.direction === 'desc';
    this.setFirstPagePaginator();
    this.sortPaginateCookies$.next();
  }

  handleGlobalFilter({ value, column }: { value: any, column: ECookieInventoryFilterType }) {
    switch (column) {
      case ECookieInventoryFilterType.Name:
        this.filterBarService.addCookieNameFilter(value);
        break;

      case ECookieInventoryFilterType.Party:
        this.filterBarService.addCookiePartyFilter(ECookiePartyTypeConverter[value]);
        break;

      case ECookieInventoryFilterType.SameSite:
        value = value === '---' ? 'Empty' : value;
        this.filterBarService.addCookieSameSiteFilter(value as ECookieSameSiteTypes);
        break;

      case ECookieInventoryFilterType.Domain:
        this.filterBarService.addCookieDomainFilter(true, value as ECookieSameSiteTypes);
        break;

      case ECookieInventoryFilterType.HttpOnly:
        this.filterBarService.addCookieHttpOnlyFilter(value as boolean);
        break;

      case ECookieInventoryFilterType.Secure:
        this.filterBarService.addCookieSecureFilter(value);
        break;

      default:
        console.error('Column type did not match any known filters.');
    }
  }

  handlePagination(event: { pageIndex: number; }) {
    this.cookiesTablePagination.page = event.pageIndex;
    this.sortPaginateCookies$.next();
  }

  private setFirstPagePaginator() {
    this.cookiesTablePagination.page = 0;

    if (this.cookiesList) {
      this.cookiesList.paginator.pageIndex = 0;
    }
  }

  private formatPaginator(): void {
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }

  private updateExportButtonConfig() {
    this.exportReportConfig.tableState = {
      ...this.exportReportConfig.tableState,
      page: this.paginator.pageIndex,
      sortBy: this.sort.active,
      sortDesc: this.sort.direction === 'desc'
    };

    // if there is a selected row from cookies table
    if (!isEmpty(this.cookieVariables)) {
      this.exportReportConfig.filters = { ...this.apiFilters, ...this.cookieVariables };
      this.exportReportConfig.filteredRows = this.paginationState.length;
      this.exportReportConfig.exportType = ECookieInventoryExportType.CookiePages;
    } else {
      this.exportReportConfig.filters = this.apiFilters;
      this.exportReportConfig.filteredRows = this.filteredPageCount;
      this.exportReportConfig.exportType = ECookieInventoryExportType.Pages;
    }
  }

  protected readonly PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;
}
