import { Injectable } from '@angular/core';
import { EChartDateRange, IFullscreenChartData } from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import { EOrientation } from '@app/components/audit-reports/audit-report-container.models';
import { EChartChangeTypes } from '@app/components/audit-reports/page-information/page-information.constants';

/**
 * This service manages retrieving data to be used in the FullscreenChartModalComponent.
 */
@Injectable()
export class FullscreenChartModalService {
  constructor() {}

  // Return an array of summary line data used to draw the summary lines on the area chart
  // This data is specific to each trend widget's chart and calculated here.
  static getPageLoadTimeSummaryLines(data: IFullscreenChartData[]): ISummaryLine[] {
    let summaryLines: ISummaryLine[] = [];
    let avgPageLoadTime = parseFloat(
      (data.reduce((acc, dataPoint) => acc + dataPoint.value, 0) / data.length).toFixed(1)
    );
    let lineMeaning = avgPageLoadTime < 3 ? EChartChangeTypes.positive :
      avgPageLoadTime < 6 ? EChartChangeTypes.neutral :
        avgPageLoadTime < 10 ? EChartChangeTypes.warning
          : EChartChangeTypes.negative;
    let avgPageLoadSummaryLineActual: ISummaryLine = {
      direction: EOrientation.horizontal,
      meaning: lineMeaning,
      tooltipText: 'avg. page load time',
      class: 'summary-line-avg-page-load-time',
      lineValue: avgPageLoadTime,
    };
    let avgPageLoadSummaryLineSuggested: ISummaryLine = {
      direction: EOrientation.horizontal,
      tooltipText: 'suggested avg. page load time',
      class: 'summary-line-suggested-page-load-time',
      lineValue: 3,
    };
    summaryLines.push(avgPageLoadSummaryLineSuggested);
    summaryLines.push(avgPageLoadSummaryLineActual);

    return summaryLines;
  }

  static getTagLoadTimeSummaryLines(data: IFullscreenChartData[]): ISummaryLine[] {
    let summaryLines: ISummaryLine[] = [];
    let avgTagLoadTime = parseFloat(
      (data.reduce((acc, dataPoint) => acc + dataPoint.value, 0) / data.length).toFixed(1)
    );
    let lineMeaning = avgTagLoadTime < 500 ? EChartChangeTypes.positive :
      avgTagLoadTime < 1000 ? EChartChangeTypes.neutral :
        avgTagLoadTime < 2000 ? EChartChangeTypes.warning
          : EChartChangeTypes.negative;
    let avgTagLoadSummaryLineActual: ISummaryLine = {
      direction: EOrientation.horizontal,
      meaning: lineMeaning,
      tooltipText: 'avg. tag load time',
      class: 'summary-line-avg-page-load-time',
      lineValue: avgTagLoadTime,
    };
    summaryLines.push(avgTagLoadSummaryLineActual);
    return summaryLines;
  }
}
