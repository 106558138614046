import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { WebJourneyReportUrlBuilders } from '@app/components/web-journey-report/web-journey-report.constants';
import { Router } from '@angular/router';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { IDailyUsageV2 } from '@app/components/usage-v2/usage-v2.models';
import {
  EUsageTabTitleChartTypes
} from '@app/components/usage-v2/components/usage-tab-title-chart/usage-tab-title.enums';

interface IFormattedDailyUsage extends IDailyUsageV2 {
  id: string;
  lastColumnHeader: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-v2-rolling-date-table',
  templateUrl: './usage-rolling-date-table.component.html',
  styleUrls: ['./usage-rolling-date-table.component.scss']
})
export class UsageRollingDateTableComponent implements OnInit {
  @ViewChild('scroller', { read: ElementRef }) scroller: ElementRef;

  @Input() openTabType: EUsageTabTitleChartTypes;
  @Input() tableData: IDailyUsageV2[];

  EUsageTabTitleChartTypes = EUsageTabTitleChartTypes;

  selectedDay: string;
  formattedData: IFormattedDailyUsage[];

  constructor(private router: Router, private dateService: DateService) {}

  ngOnInit(): void {
    if (!this.tableData) return;

    this.formattedData = this.formatTableData(this.tableData);
  }

  private formatTableData(data: IDailyUsageV2[]): IFormattedDailyUsage[] {
    const nowInUtc = this.dateService.toUTC(new Date());
    const today = this.dateService.formatDate(nowInUtc, EDateFormats.dateThree);
    const todayFull = parseInt(this.dateService.formatDate(nowInUtc, EDateFormats.dateTwentyFour));

    return data.map((day: IDailyUsageV2) => {
      // By default, Javascript Date will apply a timezone when creating a new date using a string in the format 'YYYY-MM-DD'. By switching the hyphens to backslashes, it ignores the timezone and uses the provided date directly.
      const dayWithSlashes = day.day.replace(/-/g, '\/');
      const formattedDay = this.dateService.formatDate(new Date(dayWithSlashes), EDateFormats.dateThree);
      const dayFull = parseInt(this.dateService.formatDate(nowInUtc, EDateFormats.dateTwentyFour));

      return {
        ...day,
        day: today === formattedDay ? `${formattedDay} - Today` : formattedDay,
        id: day['id'],
        lastColumnHeader: this.getLastColumnHeader(todayFull, dayFull),
        completedItems: day.completedItems ? day.completedItems : [],
        scheduledItems: day.scheduledItems ? day.scheduledItems : []
      };
    });
  }

  private getLastColumnHeader(todayFull: number, dayFull: number): string {
    if (todayFull > dayFull) {
      return 'Last Run';
    } else if (todayFull === dayFull) {
      return 'Last/Next Run';
    } else if (todayFull < dayFull) {
      return 'Next Run';
    }
  }

  public navToRun(reportId: number, runId: number): void {
    this.openTabType === EUsageTabTitleChartTypes.Audit
      ? this.router.navigateByUrl(AuditReportContainerUrlBuilders.base(reportId, runId))
      : this.router.navigateByUrl(WebJourneyReportUrlBuilders.results(reportId, runId));
  }

  public scrollToDay(day: string): void {
    this.selectedDay = day;

    const scroller = this.scroller.nativeElement;
    const dayToScroll = scroller.querySelector(`#${day}`);
    console.log(this.openTabType + day);
    const scrollDistance = dayToScroll.offsetTop - scroller.offsetTop;

    scroller.scrollTo({ top: scrollDistance,  left: 0, behavior: 'smooth' });
  }
}
