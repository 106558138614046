import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '@app/components/core/services/api.service';
import { IAuditNotes } from './audit-notes.models';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuditNotesService {

  apiRoot: string = `${environment.apiV3Url}web-audits`;
  private arrowsStateSubject = new BehaviorSubject<boolean>(false);
  arrowState$ = this.arrowsStateSubject.asObservable();

  constructor(private apiService: ApiService) {}

  setArrowState(value: boolean): void {
    this.arrowsStateSubject.next(value);
  }

  clean(): void {
    this.arrowsStateSubject.next(false);
  }

  getNotes(auditId: number): Observable<IAuditNotes> {
    return this.apiService.get(`${this.apiRoot}/${auditId}`);
  }

  saveNotes(auditId: number, notes: string): Observable<IAuditNotes> {
    return this.apiService.put(`${this.apiRoot}/${auditId}`, { notes: notes });
  }
}
