<div class="notification-container">
  <ng-container *ngIf="bars?.length > 0; else noRuns">
    <div class="notification warning" *ngIf="lastFailedRun">
      <mat-icon>warning_amber</mat-icon>
      <div class="right-side">
        <div class="title">
          This alert would have triggered on the previous {{runDate}} run for {{dataSource?.name}}.
        </div>
        <div class="subtitle">
          If there are too many runs that are over the rule threshold then you might want to modify the threshold for this alert.
        </div>
      </div>
    </div>

    <div class="notification info" *ngIf="!lastFailedRun">
      <mat-icon>info_outline</mat-icon>
      <div class="right-side">
        <div class="title">
          Based on this alerts rule logic, anything over the threshold line below would send an alert email for previous runs in the {{dataSource?.name}}.
        </div>
        <div class="subtitle">
          If there are too many items over the threshold for each of the previous runs then you might want to modify the alert threshold.
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #noRuns>
    <div class="notification warning">
      <mat-icon>warning_amber</mat-icon>
      <div class="right-side">
        <div class="title">
          The current data source has not yet run. Select a different data source or revisit this preview after at least one run has completed.
        </div>
      </div>
    </div>
  </ng-template>
</div>
