import { NgModule } from '@angular/core';
import {
  FolderAndSubFolderModalModule
} from '@app/components/folder-and-sub-folder-modal/folder-and-sub-folder-modal.module';
import {
  FolderAndSubFolderFieldComponent
} from '@app/components/folder-and-sub-folder-field/folder-and-sub-folder-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OpFormModule } from '@app/components/shared/op-form.module';
import { MaterialModule } from '@app/components/material/material.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    FolderAndSubFolderFieldComponent
  ],
  imports: [
    CommonModule,
    FolderAndSubFolderModalModule,
    MaterialModule,
    ReactiveFormsModule,
    OpFormModule,
  ],
  exports: [
    FolderAndSubFolderFieldComponent,
  ],
})
export class CreateFolderAndSubFolderFieldModule {}
