import { ElementRef } from '@angular/core';
import {
  IOpFilterBarV2Filter,
  IOpFilterBarV2MenuItem,
  IOpFilterBarV2PrintViewItem
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { CalculateWordWidth } from '@app/components/domains/discoveryAudits/reporting/services/calculateWordWidthService/calculateWordWidthService';
import { IAccountReportFilter } from '@app/components/account-reports/account-report/components/account-report-filter-bar/filter-bar.models';

export function calculateHiddenFilterChips(
    wide: boolean,
    filterBarComponent: ElementRef,
    chipBag: ElementRef,
    filters: IOpFilterBarV2Filter<string>[],
    validFilterTypes: string[]
): {
  visibleFilters: IOpFilterBarV2Filter<string>[],
  printViewFilters: IOpFilterBarV2PrintViewItem[],
  hiddenFilters: IOpFilterBarV2Filter<string>[],
  hiddenContainsValidTypes: boolean
} {
  const spaceForChips = calculateSpaceForChips(wide, filterBarComponent, chipBag);

  // NOTE: config should match font style properties for chips
  const config = {
    font: 'Open Sans',
    fontSize: '13px'
  };

  const chipChromeExtraWidth = 94; // includes margin. NOTE: Updating the style means this needs to be updated
  const calculateWordWidthService = new CalculateWordWidth();
  const visible = [];
  const hidden = [];
  let hiddenHasValidTypes = false;
  let runningWidth = 0;

  filters
      .sort((a, b) => a.order > b.order ? 1 : -1) // If an order is provided, sort the chips using it
      .forEach(f => {
        runningWidth += calculateWordWidthService.calculate(f.display, config).width + chipChromeExtraWidth;
        if (runningWidth > spaceForChips) {
          hidden.push(f);
          if (validFilterTypes.includes(f.type)) hiddenHasValidTypes = true;
        } else {
          visible.push(f);
        }
      });

  const filtersFlattened = filters.flatMap(i => i.menuItems?.map(e => ({
    ...e,
    printViewLabel: i.printViewLabel,
    icon: i.icon,
    ...(e.children ? {
      children: e.children?.map(c => ({
        ...c,
        printViewLabel: i.printViewLabel,
        icon: i.icon,
      }))
    } : {})
  })));

  return {
    visibleFilters: visible,
    printViewFilters: getPrintViewItems(filtersFlattened),
    hiddenFilters: hidden.filter(h => !!h.value.length),
    hiddenContainsValidTypes: hiddenHasValidTypes
  };
}

export function calculateGridFilterHiddenChips(
    wide: boolean,
    filterBarComponent: ElementRef,
    chipBag: ElementRef,
    filters: IAccountReportFilter[],
): {
  visibleFilters: IAccountReportFilter[],
  hiddenFilters: IAccountReportFilter[]
} {
  const spaceForChips = calculateSpaceForChips(wide, filterBarComponent, chipBag);

  // NOTE: config should match font style properties for chips
  const config = {
    font: 'Open Sans',
    fontSize: '13px'
  };

  const chipChromeExtraWidth = 94; // includes margin. NOTE: Updating the style means this needs to be updated
  const calculateWordWidthService = new CalculateWordWidth();
  const visible = [];
  const hidden = [];
  let runningWidth = 0;

  filters
      .forEach(f => {
        runningWidth += calculateWordWidthService.calculate(f.display, config).width + chipChromeExtraWidth;
        if (runningWidth > spaceForChips) {
          hidden.push(f);
        } else {
          visible.push(f);
        }
      });

  return {
    visibleFilters: visible,
    hiddenFilters: hidden,
  };
}

function calculateSpaceForChips(
    wide: boolean,
    filterBarComponent: ElementRef,
    chipBag: ElementRef
): number {
  const filterBarComponentWidth = !wide ? filterBarComponent.nativeElement.offsetWidth : filterBarComponent.nativeElement.offsetWidth - 350;
  const filterChipBagLeft = chipBag.nativeElement.offsetLeft;
  const reservedSpaceForMoreChip = 100;

  return filterBarComponentWidth - filterChipBagLeft - reservedSpaceForMoreChip;
}

function getPrintViewItems(menuItems: IOpFilterBarV2MenuItem[]): IOpFilterBarV2PrintViewItem[] {
  return menuItems.reduce((acc, curr) => {
    if (curr?.children) {
      acc.push(...getPrintViewItems(curr.children));
    }

    if (curr?.checked && !curr?.duplicated) {
      acc.push({
        startLabel: curr.printViewLabel,
        menuItemNameTemplate: curr.menuItemNameTemplate,
        icon: curr.icon,
        printViewLabel: curr.printViewLabel,
      });
    }
    return acc;
  }, []);
}
