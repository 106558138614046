import * as angular from "angular";

import {IWebsocketService} from "@app/components/websockets/websocketService";
import {AngularNames, Names} from "@app/moonbeamConstants";
import {IWebsocketConnectionService} from "@app/components/websockets/websocketConnectionService";
import { IManualJourneyRequestLog, IManualJourneyTag } from "@app/components/live-connect/manual-journey/manualJourneyService";

export interface IRdcWsIdentifier {
  journeyId: number;
  runId: number;
}

export class RdcWsTopics {
  static liveRequests = '/rdc/live/requests';
  static liveTags = '/rdc/live/tags';
  static runState = '/rdc/live/state';
  static presenceCheck = 'rdc/user/presence/check';
  static accountManualJourneyState = '/rdc/live/state/account';
}


export const name = 'ManualJourneySocketsService';

export class ManualJourneySocketsService {

    private conn: IWebsocketService;

    static $inject = [
      Names.Services.websocketConnection,
      AngularNames.q
    ];

    constructor(
      private websocketConnection: IWebsocketConnectionService,
      private $q: angular.IQService
    ) {}

    private getWsConn(): angular.IPromise<IWebsocketService> {
      return this.conn
        ? this.$q.resolve(this.conn)
        : this.websocketConnection.getConnection().then(conn => {
          return (this.conn = conn);
        });
    }

    public subscribeOnManualJourneyStateUpdate(accountId: number, handler: (T) => void): angular.IPromise<IWebsocketService>{
      let deffered: angular.IDeferred<IWebsocketService> = this.$q.defer();
      this.getWsConn().then( (ws: IWebsocketService) => {
        ws.subscribe(RdcWsTopics.accountManualJourneyState, {accountId: accountId}).then(
          (subscription: any) => {
            subscription.on((update: any) => {
              handler(update);
            });
          },
        error => {
              console.error('Failed to subscribe on manual journey state update, error: ', error);
        });
        deffered.resolve(ws);
      }, error => {
          console.error('Failed to get WS connection when subscribing on manual journey state update, error: ', error);
      });
      return deffered.promise;
    }
    public unsubscribeFromManualJourneyStateUpdate(accountId: number) {
      this.getWsConn().then((ws: IWebsocketService) => {
        ws.unsubscribe(RdcWsTopics.accountManualJourneyState, {accountId: accountId});
      });
    }

    public subscribeOnLiveTagUpdates(deviceProfileId: number, manualJourneyId: number, handler: (T) => void): void {
      let identifier = this.buildWsIdentifier(deviceProfileId, manualJourneyId);
      this.getWsConn().then((ws: IWebsocketService) => {
        ws.subscribe(RdcWsTopics.liveTags, identifier).then(
          (subscription: any) => {
            subscription.on((tags: Array<IManualJourneyTag>) => handler(tags));
          },
          error => {
              console.error('Failed to subscribe LiveTagUpdates: ', error);
          });
      }, error => {
          console.error('Failed to get WS connection when subscribing on LiveTagUpdates, error: ', error);
      });
    }
    public subscribeOnLiveRequestUpdates(deviceProfileId: number, manualJourneyId: number, handler: (T) => void): void {
      let identifier = this.buildWsIdentifier(deviceProfileId, manualJourneyId);
      this.getWsConn().then((ws: IWebsocketService) => {
        ws.subscribe(RdcWsTopics.liveRequests, identifier).then(
          (subscription: any) => {
            subscription.on((logs: Array<IManualJourneyRequestLog>) => handler(logs));
          },
            error => {
                console.error('Failed to subscribe LiveRequestUpdates: ', error);
            });
      }, error => {
          console.error('Failed to get WS connection when subscribing on LiveRequestUpdates, error: ', error);
      });
    }
    public subscribeOnLivePresenseCheck(deviceProfileId: number, manualJourneyId: number, handler: (T) => void): void {
      let identifier = this.buildWsIdentifier(deviceProfileId, manualJourneyId);
      this.getWsConn().then((ws: IWebsocketService) => {
        ws.subscribe(RdcWsTopics.presenceCheck, identifier).then(
          (subscription: any) => {
            subscription.on((update: any) => handler(update));
          },
        error => {
            console.error('Failed to subscribe LivePresenseCheck: ', error);
        });
      }, error => {
          console.error('Failed to get WS connection when subscribing on LivePresenseCheck, error: ', error);
      });
    }

    public unsubscribeFromLiveTagUpdates(deviceProfileId: number, manualJourneyId: number): void {
      this.getWsConn().then((ws: IWebsocketService) => {
        ws.unsubscribe(RdcWsTopics.liveTags, this.buildWsIdentifier(deviceProfileId, manualJourneyId))
      });
    }
    public unsubscribeFromLiveRequestUpdates(deviceProfileId: number, manualJourneyId: number): void {
      this.getWsConn().then((ws: IWebsocketService) => {
        ws.unsubscribe(RdcWsTopics.liveRequests, this.buildWsIdentifier(deviceProfileId, manualJourneyId))
      });
    }
    public unsubscribeFromLivePresenceCheck(deviceProfileId: number, manualJourneyId: number): void {
      this.getWsConn().then((ws: IWebsocketService) => {
        ws.unsubscribe(RdcWsTopics.presenceCheck, this.buildWsIdentifier(deviceProfileId, manualJourneyId))
      });
    }

    private buildWsIdentifier(deviceProfileId: number, manualJourneyId: number): IRdcWsIdentifier {
      return {journeyId: deviceProfileId, runId: manualJourneyId};
    }

  }

