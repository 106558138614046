import { AngularNames, Events, Messages, Names, ServerErrorCodes } from '@app/moonbeamConstants';

import { IAuditModel } from '../../../../../modals/modalData';
import {
  IAuditRunSummary,
  IRunInfoSerializer
} from '../../../discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { IAuditRun, IAuditSummaryStats, IFormattedRun } from '../../summaryReports/auditSummary/auditSummaryData';
import { IRunPickerRun } from '@app/components/run-picker-ng/run-picker-ng.models';
import { IProgressStatusWrapper } from '../../../../../reporting/statusBanner/statusBannerService';
import { IApiService, ISerializer } from '../../../../../api/apiService';
import { IHttpCallOnceService } from '../../../../../api/httpCallOnceService';
import { IEventManager } from '../../../../../eventManager/eventManager';
import { DiscoveryAuditService } from '../../../discoveryAuditService';
import {
  IAuditSummariesJson,
  IAuditSummaryStatsSerializer
} from '../../summaryReports/auditSummary/auditSummaryStatsSerializer';
import * as angular from 'angular';
import { EOrigin } from '../../../../../../moonbeamRoutes';
import { ToastService } from '../../../../../utilities/toastService';
import { IEditAudit } from '@app/components/audit/audit.models';
import { SwitchAccountService, EDataSelectorType } from '@app/components/account/admin/switch-account.service';
import {
  SnackbarErrorComponent
} from '@app/components/shared/components/snackbars/snackbar-error/snackbar-error.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';

export abstract class IAuditDataService {
    abstract getAudit(auditId: number): angular.IPromise<IAuditModel>;
    abstract updateAudit(audit: IEditAudit): angular.IPromise<IAuditModel>;
    abstract getAuditRuns(auditId: number): angular.IPromise<Array<IAuditRunSummary>>;
    abstract getAuditRunsNonCached(auditId: number): angular.IPromise<Array<IAuditRunSummary>>;
    abstract getAuditRunsFormattedNonCached(auditId: number): angular.IPromise<Array<IFormattedRun>>;
    abstract getAuditRunsFormatted(auditId: number): angular.IPromise<Array<IFormattedRun>>;
    abstract getAuditRunsForRunPicker(auditId: number): angular.IPromise<Array<IRunPickerRun>>;
    abstract getRun(auditId: number, runId: number): angular.IPromise<IAuditRunSummary>;
    abstract getLastRun(auditId: number): angular.IPromise<IAuditRunSummary>;
    abstract getAuditStatusProgress(auditId: number, runId: number): angular.IPromise<IProgressStatusWrapper>;
    abstract getStatsForRun(auditId: number, runId: number): angular.IPromise<IAuditSummaryStats>;
    abstract getSummaryData(auditId: number): angular.IPromise<Array<IAuditRun>>;
    abstract getCached<T>(auditId: number, url: string, serializer?: ISerializer<T>, spinnerKey?: string): angular.IPromise<T>;
    abstract runNow(audit: IAuditModel): angular.IPromise<IAuditModel>;
    abstract reprocessRules(auditId: number, runId: number): angular.IPromise<boolean>;
    abstract reinitStore(id: number): void;
  }

  interface IAuditStore {
    key: number;
  }

  export class AuditDataService extends IAuditDataService {
    static $inject = [
      AngularNames.q,
      Names.Services.api,
      Names.Services.httpCallOnce,
      Names.Services.eventManager,
      Names.Services.discoveryAudit,
      Names.Services.runInfoSerializer,
      Names.Services.auditSummaryStatsSerializer,
      Names.NgServices.switchAccount,
      AngularNames.timeout,
      Names.NgServices.snackbarService,
      Names.Services.ngToast,
      Names.Services.toastService
    ];

    constructor(private $q: angular.IQService,
                private apiService: IApiService,
                private httpCallOnce: IHttpCallOnceService,
                private eventManager: IEventManager,
                private auditApiService: DiscoveryAuditService,
                private formattedRunSerializer: IRunInfoSerializer,
                private auditSummarySerializer: IAuditSummaryStatsSerializer,
                private switchAccountService: SwitchAccountService,
                private timeout: angular.ITimeoutService,
                private snackbarService: SnackbarService,
                private ngToast: any,
                private toastService: ToastService) {
      super();
    }

    private auditStore: IAuditStore;
    private NO_RUNS_ERROR: string = 'No runs';
    private runsKey: string = 'runs';

    getAudit(auditId: number): angular.IPromise<IAuditModel> {
      var key = 'audit';
      return this.getCachedIfExists(auditId, key) ||
        this.handleAuditResponse(this.auditApiService.getAudit(auditId).then((audit: IAuditModel) => {
          this.verifySameKey(auditId);
          this.auditStore[key] = audit;
          return audit;
        }, error => {
          if (error.code === 403) {
            this.switchAccountService.attemptSwitchAccounts(auditId, EDataSelectorType.audits).subscribe();
          }
          if (error.code === 404) {
            this.eventManager.publish(Events.noDataFound);
          }
          throw error;
        })) as any;
    }

    updateAudit(audit: IEditAudit): angular.IPromise<IAuditModel> {
      var key = 'audit';
      return this.handleAuditResponse(this.auditApiService.updateAudit(audit).then((audit: IAuditModel) => {
        this.verifySameKey(audit.id);
        this.auditStore[key] = audit;
        return audit;
      }));
    }

    getAuditRuns(auditId: number): angular.IPromise<Array<IAuditRunSummary>> {
      var key = this.runsKey;
      return this.getCachedIfExists(auditId, key) ||
        this.handleAuditResponse(this.getAuditRunsNonCached(auditId).then((runs: Array<IAuditRunSummary>) => {
          this.verifySameKey(auditId);
          this.auditStore[key] = runs;
          return this.auditStore[key];
        }));
    }

    getAuditRunsNonCached(auditId: number): angular.IPromise<Array<IAuditRunSummary>> {
      return this.auditApiService.getAuditRuns(auditId);
    }

    getAuditRunsFormattedNonCached(auditId: number): angular.IPromise<Array<IFormattedRun>> {
      return this.handleAuditResponse(this.auditApiService.getAuditRuns(auditId).then((runs: Array<IAuditRunSummary>) => {
        return this.formattedRunSerializer.serialize(runs);
      }));
    }

    getAuditRunsFormatted(auditId: number): angular.IPromise<Array<IFormattedRun>> {
      var key = this.runsKey;
      var getFormattedRuns = (): Array<IFormattedRun> => {
        return this.formattedRunSerializer.serialize(this.auditStore[key]);
      };

      return this.getCachedIfExists(auditId, key, getFormattedRuns) ||
        this.handleAuditResponse(this.auditApiService.getAuditRuns(auditId).then((runs: Array<IAuditRunSummary>) => {
          this.verifySameKey(auditId);
          this.auditStore[key] = runs;
          return getFormattedRuns();
        }));
    }

    getAuditRunsForRunPicker(auditId: number): angular.IPromise<Array<IRunPickerRun>> {
      var key = this.runsKey;
      var getRunPickerRuns = (): angular.IPromise<Array<IRunPickerRun>> => {
        return this.formattedRunSerializer.serializeForRunPicker(this.auditStore[key]);
      };

      return this.getCachedIfExists(auditId, key, getRunPickerRuns) ||
        this.handleAuditResponse(this.auditApiService.getAuditRuns(auditId).then((runs: Array<IAuditRunSummary>) => {
          this.verifySameKey(auditId);
          this.auditStore[key] = runs;
          return getRunPickerRuns();
        }))
          .catch(() => {
            throw new Error('Error getting Audit runs');
          })
        .then((runs: any) => {
          return runs;
        });
    }

    getRun(auditId: number, runId: number): angular.IPromise<IAuditRunSummary> {
      var defer = this.$q.defer<IAuditRunSummary>();

      if (this.isCurrentKeyValueSet(auditId, this.runsKey)) {
        defer.resolve(this.findRunById(runId));
      } else {
        this.getAuditRuns(auditId).then(() => {
          defer.resolve(this.findRunById(runId));
        });
      }

      return defer.promise;
    }

    getLastRun(auditId: number): angular.IPromise<IAuditRunSummary> {
      var find = (): IAuditRunSummary => {
        return this.findLastRun();
      };
      return this.getCachedIfExists(auditId, this.runsKey, find) || this.getAuditRuns(auditId).then(() => {
        return find();
      });
    }

    getAuditStatusProgress(auditId: number, runId: number): angular.IPromise<IProgressStatusWrapper> {
      return this.auditApiService.getAuditStatusProgress(auditId, runId);
    }

    getStatsForRun(auditId: number, runId: number): angular.IPromise<IAuditSummaryStats> {
      const key = 'stats';
      return this.getCachedIfExists(auditId, key, () => this.findSummaryStatsById(runId)) ||
        this.handleAuditResponse(this.auditApiService.getAuditRunSummary(runId).then((summaries: IAuditSummariesJson) => {
          this.verifySameKey(auditId);
          this.initAuditStoreKey(key, []);
          this.auditStore[key].push(this.auditSummarySerializer.serialize(summaries)[0]);
          return this.findSummaryStatsById(runId);
        }));
    }

    private initAuditStoreKey(key: string, value: any): void {
      if (!this.auditStore[key]) {
        this.auditStore[key] = value;
      }
    }

    getSummaryData(auditId: number): angular.IPromise<Array<IAuditRun>> {
      const key = EOrigin.TagSummary;
      return this.getCachedIfExists(auditId, key) ||
        this.handleAuditResponse(this.auditApiService.getAuditScoring(auditId).then((summary: Array<IAuditRun>) => {
          this.verifySameKey(auditId);
          this.auditStore[key] = summary;
          return summary;
        }));
    }

    getCached<T>(auditId: number, url: string, serializer?: ISerializer<T>, spinnerKey?: string): angular.IPromise<T> {
      var key = url;
      if (serializer) {
        key += 'serialized';
      }
      return this.getCachedIfExists(auditId, key) ||
        this.handleAuditResponse(this.apiService.handleResponse(this.httpCallOnce.get(url), serializer, spinnerKey).then((data: T) => {
          this.verifySameKey(auditId);
          this.auditStore[key] = data;
          return data;
        }));
    }

    runNow(audit: IAuditModel): angular.IPromise<IAuditModel> {
      this.initializeAuditStore(-1);
      return this.auditApiService.runAudit(audit.id).then((data) => {

        // API is returning successfull response in case concurrency has been reached.
        if (data && (data as any).errorCode === ServerErrorCodes.accountConcurrencyReached) {
          return Promise.reject(data);
        }

        return data;
      }).catch( error => {
        if (error.errorCode === ServerErrorCodes.overageLimitExceeded) {
          this.snackbarService.openFromComponent(SnackbarErrorComponent, {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              message: `The audit limit has been reached. The audit "${audit.name}" cannot be run.`
            }
          });
        } else if (error.errorCode === ServerErrorCodes.alreadyRunning) {
          this.snackbarService.openInfoSnackbar(`The audit "${audit.name}" is not able to run again because it is currently running`);
        } else if (error.errorCode === ServerErrorCodes.accountConcurrencyReached) {
          this.snackbarService.openInfoSnackbar(`Your concurrency is reached. The audit "${audit.name}" was queued to run as soon as possible`);
        } else if (error.code === 500) {
          this.snackbarService.openErrorSnackbar('Something went wrong');
        } else if (error.code === 400) {
          const message = `<span class="bold">${audit.name}</span> could not start because there are no starting URLs.
            This audit may be used to validate URLs from other sources such as email messages or saved views.`;
          this.snackbarService.openErrorSnackbar(message);
        }
        return error;
      });
    }

    reprocessRules(auditId: number, runId: number): angular.IPromise<boolean> {
      return this.auditApiService.reprocessRules(auditId, runId).then((needReprocess) => {
        return this.getRun(auditId, runId).then((run: IAuditRunSummary) => {
          run.needReprocess = needReprocess;
          return needReprocess;
        });
      });
    }

    private handleAuditResponse<T>(promise: angular.IPromise<T>): angular.IPromise<T> {
      return promise.then((data: T) => { return data; }, (error) => {
        switch (error.code) {
          case 403:
            this.sendAlertIfIsNotNoRunsError(error, Messages.auditForbidden);
            break;
          case 405:
          case 404:
            break;
          default:
            this.sendAlertIfIsNotNoRunsError(error, Messages.auditError);
            break;
        }
        throw error;
      }) as any;
    }

    private sendAlertIfIsNotNoRunsError(error: any, alert: string): void {
      if (error.message != this.NO_RUNS_ERROR) {
        this.sendAlert(alert);
      }
    }

    private getCachedIfExists<T>(id: number, key: string, cacheLookup?: () => T): angular.IPromise<T> {
      if (this.isCurrentKeyValueSet(id, key)) {
        const res = cacheLookup ? cacheLookup() : this.auditStore[key];
        if (res) this.$q.resolve(res);
      }

      return null;
    }

    private findSummaryStatsById(runId: number): IAuditSummaryStats {
      return (this.auditStore['stats'] as IAuditSummaryStats[])?.find(summary => summary.runId == runId) || null;
    }

    private findRunById(id: number): IAuditRunSummary {
      return (this.auditStore[this.runsKey] as IAuditRunSummary[])?.find(run => run.id == id) || null;
    }

    private findLastRun(): IAuditRunSummary {
      var runs = this.auditStore[this.runsKey];
      if (runs) {
        for (var i = 0; i < runs.length; i++) {
          if (runs[i].completed) {
            return runs[i];
          }
        }
      }
      return <IAuditRunSummary>{
        id: 0
      };
    }

    private verifySameKey(id: number): void {
      if (!id) {
        throw 'id cannot be null';
      }
      if (!this.auditStore || this.auditStore.key != id) {
        this.eventManager.publish(Events.userAlertClearAll);
        this.initializeAuditStore(id);
      }
    }

    public reinitStore(id: number): void {
      this.initializeAuditStore(id);
    }

    private initializeAuditStore(id: number): void {
      this.auditStore = {
        key: id,
      };
    }

    private isCurrentKeyValueSet(id: number, value: string): boolean {
      if (!this.auditStore) {
        return false;
      }
      return this.auditStore.key == id && this.auditStore[value] !== undefined;
    }

    private sendAlert(key: string): void {
      // TODO: send alert, implementation with old routing below
      // this.eventManager.publish<IUserAlertDefinition>(Events.userAlert, {
      //   originUIState: Routes.discoveryAuditsReports.state,
      //   definition: key
      // });
    }

}
