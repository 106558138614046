import { Component, Input } from '@angular/core';
import { EUseCaseBannerColor } from '../use-cases.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'use-case-report-section',
  templateUrl: './use-case-report-section.component.html',
  styleUrls: ['./use-case-report-section.component.scss']
})
export class UseCaseReportSectionComponent {

  @Input() sectionIcon: string;
  @Input() customIcon: boolean = false;
  @Input() sectionTitle: string;
  @Input() bannerText: string;
  @Input() bannerClickAction: Function;
  @Input() bannerColor: EUseCaseBannerColor;

  EUseCaseBannerColor = EUseCaseBannerColor;
}
