import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable()
export class ValidateMessages {

  /**
   * Map of all ng forms which should be validated
   */
  forms: Map<string, UntypedFormGroup> = new Map();

  registerForm(formName: string, formGroup: UntypedFormGroup) {
    this.forms.set(formName, formGroup);
  }

  getFormByName(formName: string): UntypedFormGroup {
    return this.forms.get(formName);
  }

  static defaultMessages = {
    required: 'Please enter this field',
    unique: 'Identifier already exists',
    minlength: 'Value seems to be too short',
    maxlength: 'Value seems to be too long',
    date: 'Should be a date',
    number: 'Should be a number',
    validNumber: 'Should be a number',
    numberInteger: 'Should be a natural number',
    max: 'The number is greater than expected',
    exceedFreeTrialPagesLimit: 'Free Trial audits are limited to 1,000 pages',
    min: 'The number is less than expected',
    emailsListValidator: 'Please enter a valid email address',
    email: 'Please enter a valid email address',
    url: 'Please enter a valid URL including the protocol',
    urlWithOptionalProtocol: 'Please enter a valid URL',
    urlsListValidator: 'Please enter a valid URL including the protocol',
    validRegexes: 'Please enter a valid regular expression',
    duplicate: 'Identifier already exists',
    compareTo: 'The passwords do not match',
    invalidFile: 'You are trying to upload an unsupported file type',
    deepLink: 'Please enter a valid deep link URL',
    validIdentifier: 'JavaScript variable names may not start with a numerical character.',
    maxFileSize: 'Exceeded the maximum file size',
    minDateTimeNow: 'Please enter a time in the future',
    validTime: 'Please enter a valid time (ex. 10:30 AM)',
    maskedValue: 'This field is required'
  };

  // messages for angular forms
  static ngMessages = {
    rfmValueForm: {
      fileNameUrl: {
        maxFileSize: 'Warning: Resource exceeds the max file size of 2MB',
        resourceSizeUnknown: 'Warning: Could not determine the size of the remote resource',
        fileUpload: 'Upload failed, please try again',
      },
      fileNameUpload: {
        maxFileSize: 'Warning: Resource exceeds the max file size of 2MB',
      }
    },
    deviceProfileForm: {
      deviceModel: {
        required: 'Please enter a device model'
      },
      osVersion: {
        required: 'Please enter a version of OS'
      },
      folder: {
        required: 'Please select or enter a folder'
      },
      deviceOs: {
        required: 'Please select device operating system'
      },
    },
    forgotPasswordForm: {
      username: {
        required: 'Please enter a username'
      },
    },
    auditBasicForm: {
      name: {
        required: 'Please enter a name for the audit',
      },
      folder: {
        required: 'Please select or enter a folder'
      },
      domain: {
        required: 'Please select or enter a domain',
        url: 'Please enter a valid URL'
      },
      startingUrls: {
        required: 'Please enter a URL(s) including the protocol',
        urlsListValidator: 'Please enter valid URL(s). Multiple URLs separated by a new line'
      },
      recipients: {
        emailsListValidator: 'Please enter a valid email address, additional addresses separated by a new line'
      },
      limit: {
        required: 'Please enter a number'
      }
    },
    webJourneySetupForm: {
      name: {
        required: 'Please enter a name for the journey'
      },
      browserWidth: {
        required: 'Please enter a number'
      }
    },
    intervalForm: {
      time: {
        min: 'Interval is too small',
        max: 'Interval is too large'
      }
    },
    selectorsForm: {
      value: {
        invalidHtmlAttrs: 'You must provide a tag name or attributes',
      },
    },
    folderAndSubfolderForm: {
      folder: {
        required: 'Please select or enter a folder',
      },
      subFolder: {
        required: 'Please select or enter a sub-folder',
      },
    }
  };

  // messages for old angularjs forms
  static messages = {
    folderForm: {
      name: {
        required: 'Please enter a name for the folder',
        unique: 'Folder with given name already exist'
      }
    },
    saveManualJourneyForm: {
      name: {
        required: 'Please enter a name for the journey'
      }
    },
    appForm: {
      name: {
        required: 'Please enter a name for the app'
      },
      platform: {
        required: 'Please pick a platform'
      },
      upload: {
        required: 'Please select an apk or zip file to upload',
        invalidFile: 'You are trying to upload an unsupported file type'
      }
    },
    userBasicInfo: {
      userData: {
        firstName: {
          required: 'Please enter a first name'
        },
        lastName: {
          required: 'Please enter a last name'
        },
        username: {
          required: 'Please enter a username',
          duplicate: 'Username already exists. Please enter another'
        },
        timezone: {
          required: 'Please specify a timezone'
        }
      },
      userPass: {
        currentPasswordF: {
          server: [
            {type: 'passwordIncorrect', message: 'Current password is incorrect'}
          ]
        },
        newPasswordF: {
          minlength: 'Password length needs to be 8 or more characters',
          server: [
            {type: 'passwordNotAllowed', message: 'New password cannot be a previously used password'}
          ]
        }
      }
    },
    intervalForm: {
      time: {
        min: 'Interval is too small',
        max: 'Interval is too large'
      }
    },
    forgotPasswordForm: {
      username: {
        duplicate: 'Username does not exist'
      }
    },
    resetPasswordForm: {
      currentPassword: {
        required: 'Please enter your current password',
        server: [
          {type: 'passwordIncorrect', message: 'Entered password is incorrect'}
        ]
      },
      newPassword: {
        required: 'Please enter new password',
        server: [
          {type: 'passwordNotAllowed', message: 'New password cannot be a previously used password'}
        ]
      },
      confirmPassword: {
        required: 'Please enter new password again'
      }
    },
    accountInfoState: {
      accountName: {
        required: 'Please enter an account name'
      },
      maxUsers: {
        min: 'Max users should be 1 or greater',
        max: 'Max Users should be 10000 or less'
      },
      concurrentAudits: {
        min: 'Concurrent Audits should be 1 or greater',
        max: 'Concurrent Audits should be 30 or less'
      },
      simulationMinutes:{
        min: 'Web Journey Minutes should be 0 or greater',
        max: 'Web Journey Minutes should be 1000 or less'
      },
      primaryUsersName: {
        required: 'Please enter a username',
        duplicate: 'Username already exists. Please enter another'
      },
      primaryUsersEmail: {
        required: 'Please enter an email'
      },
      maxDomains: {
        required: 'Please enter max domains count',
        min: 'Max Domains should be 1 or greater',
        max: 'Max Domains should should be 100 or less'
      },
      domain: {
        url: 'Please enter a valid domain URL',
        required: 'Please enter a domain'
      }
    },
    accountSupportState: {
      maxDomains: {
        required: 'Please enter max domains count',
        min: 'Max Domains should be 1 or greater',
        max: 'Max Domains should should be 100 or less'
      },
      domains: {
        url: 'Please enter a valid URL including the protocol'
      }
    },
    primaryUserState: {
      username: {
        required: 'Please enter a username',
        duplicate: 'Username already exists. Please enter another'
      },
      timezone: {
        required: 'Please specify a timezone'
      },
      email: {
        required: 'Please enter a valid email',
        valid: 'Please valid email enter',
        invalid: 'invalid email here'
      }
    },
    ruleCreatorForm: {
      checkTimes: {
        required: 'Should be a natural number'
      }
    },
    adminUserCreatorForm: {
      firstName: {
        required: 'Please enter a first name'
      },
      lastName: {
        required: 'Please enter a last name'
      },
      username: {
        required: 'Please enter a username',
        duplicate: 'Username already exists. Please enter another'
      },
      email: {
        required: 'Please enter a valid email'
      },
      userType: {
        required: 'Please select a valid user type'
      },
      timezone: {
        required: 'Please select a valid timezone'
      }
    }
  };

}
