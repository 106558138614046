<div class="monitored">
  <div class="name">Monitored Web Journeys</div>
  <div class="value">{{monitored}} of {{total}}</div>
  <div class="chart">
    <horizontal-stacked-limit-bar-chart
      class="op-chart"
      [chartHeight]="17"
      [getTooltipMessageFn]="getTooltipFn"
      [additionalTooltipClass]="additionalTooltipClass"
      [data]="data">
    </horizontal-stacked-limit-bar-chart>
  </div>
</div>
<div class="requests">
  <div class="name">Remaining Web Journey Support Requests in {{currentMonth}}</div>
  <div class="value">{{requests}}</div>
</div>
