import { IWebJourney } from '@app/components/web-journey/web-journey.models';
import {
  Component,
  OnInit,
  OnDestroy,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  Validator,
  NG_VALIDATORS,
} from '@angular/forms';
import { InputMode } from '@app/components/shared/components/op-select-create/op-select-create.enum';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { forkJoin, Observable, Subject, ReplaySubject } from 'rxjs';
import { IUserAgent, IUserAgentService } from '@app/components/domains/userAgentService.models';
import { EWebJourneyFrequency, WebJourneyFrequencies } from '../web-journey.constants';
import { IWebJourneyFrequency } from '../web-journey.models';
import { IWebJourneySetupForm } from './web-journey-setup-form.models';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { isSafari } from '@app/components/utilities/browser.utils';
import { WindowRef } from '@app/components/core/services/window.service';
import { IUser } from '@app/moonbeamModels';
import {
  DEFAULT_LOCATION,
  DEFAULT_BROWSER_WIDTH,
  DEFAULT_USER_AGENT,
  DEFAULT_LIVE_VIDEO,
  DEFAULT_VPN_ENABLED,
  DEFAULT_GPC_ENABLED,
  DEFAULT_FREQUENCY,
  DEFAULT_BLACKOUT_ENABLED,
  DEFAULT_HAS_RECIPIENTS,
  JourneyCenterText,
  EJourneyFieldLabels,
  EJourneySetupOpSelectors,
  LEARN_MORE_LINKS,
  DEFAULT_BROWSER_HEIGHT,
  DEFAULT_BLOCK_3RD_PARTY_COOKIES_ENABLED
} from './web-journey-setup-form.constants';
import * as dateUtils from '@app/components/date/date.service';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { takeUntil, debounceTime, mergeMap, tap } from 'rxjs/operators';
import { RemoteFileMapService } from '@app/components/creator/services/remote-file-map.service';
import {
  IRFMConfigV3,
  IRFMConfigV3CreationRequest
} from '../../creator/shared/remoteFileMapping/remote-file-mapping.component';
import { AccountsService } from '@app/components/account/account.service';
import { blackoutToUIModelWithOffset } from '@app/components/utilities/blackoutPeriodUtils';
import { EDateFormats } from '@app/components/date/date.service';
import { IWebJourneyEditorModalData } from '../web-journey-editor/web-journey-editor.models';
import {
  IAuditSetupFormOptionData,
  IFolderAndSubFolderData
} from '@app/components/audit/audit-setup-form/audit-setup-form.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { RfmEditorComponent } from '@app/components/rfm-library/rfm-editor/rfm-editor.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Features } from '@app/moonbeamConstants';
import { EDataSourceType } from '@app/components/shared/services/data-sources/data-sources.constants';
import { IGeoLocation } from '@app/components/shared/services/locations.models';
import { LocationsService } from '@app/components/shared/services/locations.service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { IEasyBlockTags } from '@app/components/domains/discoveryAudits/discoveryAuditModels';
import { MatSelect } from '@angular/material/select';
import { MatSelectChange } from '@angular/material/select';
import { DEFAULT_AUDIT_LOCATION } from '@app/components/audit/audit.constants';
import { StorageService } from "@app/components/shared/services/storage.service";
import { ERecurrenceItemType, ERecurrencePresetNames } from '@app/components/shared/components/op-recurrence/op-recurrence.constants';
import { IOpRecurrenceResponse, IOpRecurrenceScheduleResponse } from '@app/components/shared/components/op-recurrence/op-recurrence.models';
import { RecurrenceService } from '@app/components/shared/components/op-recurrence/op-recurrence.service';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => WebJourneySetupFormComponent),
  multi: true
};

const FORM_CONTROL_VALIDATION = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => WebJourneySetupFormComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'web-journey-setup-form',
  templateUrl: './web-journey-setup-form.component.html',
  styleUrls: ['./web-journey-setup-form.component.scss'],
  providers: [FORM_CONTROL_VALUE_ACCESSOR, FORM_CONTROL_VALIDATION]
})
export class WebJourneySetupFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  EDataSourceType = EDataSourceType;
  webJourneySetupForm: UntypedFormGroup;
  labels: ILabel[] = [];
  frequencies: IWebJourneyFrequency[] = WebJourneyFrequencies;

  locations: IGeoLocation[] = [];
  locationObj: IGeoLocation;
  cachedLocationObj: IGeoLocation;
  filteredLocations: ReplaySubject<IGeoLocation[]> = new ReplaySubject<IGeoLocation[]>(1);

  userAgents: IUserAgent[] = [];
  remoteFileMappings: IRFMConfigV3[];
  webhookUrl: string = '';
  monitoredJourneysLeft: number = 0;
  selectedLabels: ILabel[] = [];
  selectedLocation: IGeoLocation;

  vpnSupport: boolean = false;
  privacyFeatureSupport: boolean = false;
  customProxySupport: boolean = true;

  monitorJourneyTooltip = {
    description: `
      ObservePoint will monitor this journey and remediate issues within 48 hrs. Use names for each action to document the expected results.
    `,
    noMore: 'Contact your consultant or Account Executive to add more.'
  };

  browserList: { name: string, value: boolean }[] = [
    { name: 'Chrome - Supports all licensed audio/video codecs', value: true },
    { name: 'Chromium - Only supports open-source audio/video codecs', value: false },
  ];

  folderId: number;
  domainId: number;

  FIELD_LABELS = EJourneyFieldLabels;
  OP_SELECTORS = EJourneySetupOpSelectors;
  EWebJourneyFrequency = EWebJourneyFrequency;
  LEARN_MORE = LEARN_MORE_LINKS;
  showAdditionalSetupOptions: boolean = false;

  folders: IFolder[] = [];
  domains: IDomain[] = [];
  availableDomains: Array<IDomain>;

  isEditMode: boolean = false;

  cachedNextRun: Date;

  tagsToBlock: IEasyBlockTags;

  JourneyCenterText = JourneyCenterText;

  recurrenceEnabled: boolean = false;
  recurrenceSchedule: IOpRecurrenceResponse;

  readonly ERecurrenceItemType = ERecurrenceItemType;

  @Input() modalData: IWebJourneyEditorModalData;
  @Input() submitted: boolean = false;

  @Output() onSelectDomain = new EventEmitter<IDomain | string>();
  @Output() onFrequencyChanged = new EventEmitter<void>();
  @Output() onLocationChanged = new EventEmitter<string>();
  @Output() onVPNChanged = new EventEmitter<Boolean>();
  @Output() onRunDateChanged = new EventEmitter<void>();
  @Output() onRunTimeChanged = new EventEmitter<void>();
  @Output() onAddCmpAction = new EventEmitter<void>();

  @ViewChild('locationSelect', { static: true }) locationSelect: MatSelect;

  onTouched: () => void;
  onChange: (model: IWebJourneySetupForm) => void = () => { };

  private destroy$ = new Subject();
  private dataPromise: Promise<IAuditSetupFormOptionData>;

  minStartDate: Date;
  user: IUser;

  constructor(
    private window: WindowRef,
    private formBuilder: UntypedFormBuilder,
    private labelsService: LabelService,
    private foldersService: IFoldersApiService,
    private domainsService: IDomainsService,
    private locationService: LocationsService,
    private userAgentService: IUserAgentService,
    private authenticationService: AuthenticationService,
    private accountsService: AccountsService,
    private webJourneyAPIService: IWebJourneyApiService,
    private rfmService: RemoteFileMapService,
    private modalService: OpModalService,
    private renderer: Renderer2,
    private snackbar: MatSnackBar,
    private storageService: StorageService,
    private dateService: dateUtils.DateService,
    private recurrenceService: RecurrenceService
    ) {
    // TODO: Remove once recurrence component goes live
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
    this.minStartDate = new Date();
    this.initForm();
    this.initListeners();
    this.initValidation();
  }

  ngOnInit() {
    if (this.modalData?.journeyId) {
      this.isEditMode = true;
    }

    this.loadData();
  }

  loadData() {
    this.loadLabels();

    this.dataPromise = this.getFormOptionData().toPromise();
    this.dataPromise.then((data: IAuditSetupFormOptionData) => {
      this.user = data.user;
      this.locations = this.sortLocations(data.locations);
      this.userAgents = data.userAgents;
      this.customProxySupport = data.account.customProxySupport;
      this.vpnSupport = data.account.vpnSupport;
      this.vpnSupport ? this.vpn.enable() : this.vpn.disable();
      this.privacyFeatureSupport = data.features.includes(Features.productLinePrivacy);
      this.privacyFeatureSupport ? this.gpc.enable() : this.gpc.disable();
      this.folders = data.folders.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
      this.domains = data.domains.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
      this.tagsToBlock = data.easyBlockTags;

      if (this.recurrenceEnabled && !this.isEditMode) {
        this.recurrenceService.getDefaultSchedule().subscribe(schedule => {
          this.recurrenceSchedule = schedule;
        });
      }

      this.loadScriptServicesCounts();

      if (!this.isEditMode) {
        this.assignLocations();
      }
    }, err => {
      console.error(err);
      alert('There was an error obtaining the data for this page. Please reload and try again');
    });
    this.loadRfms();
  }

  private getFormOptionData(): Observable<IAuditSetupFormOptionData> {
    return this.authenticationService.getFeaturesWithCache()
      .pipe(
        mergeMap(features => {
          const requests = [
            this.userAgentService.getUserAgents(),
            this.locationService.getAllLocations(),
            this.authenticationService.getAccountWithCache(),
            this.loadFolders(),
            this.loadDomains(),
            this.loadUser(),
          ] as any;

          if (this.modalData.journeyId) {
            requests.push(this.webJourneyAPIService.getEasyBlockTagIds(this.modalData.journeyId));
          }

          return forkJoin(
            requests,
            (userAgents, locations, account, folders, domains, user, easyBlockTags) => ({
              account, locations, features, userAgents, folders, domains, user, easyBlockTags
            })
          );
        })
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private initForm() {
    this.locationObj = this.selectedLocation = this.cachedLocationObj = DEFAULT_AUDIT_LOCATION;

    const use24Hour = !isSafari(this.window);
    this.webJourneySetupForm = this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.required, Validators.maxLength(255)]),
      labels: this.formBuilder.control([]),
      frequency: this.formBuilder.control(DEFAULT_FREQUENCY),
      location: this.formBuilder.control(DEFAULT_LOCATION, Validators.required),
      locationFilterCtrl: this.formBuilder.control(''),
      customProxy: false,
      browserWidth: this.formBuilder.control(
        DEFAULT_BROWSER_WIDTH,
        [Validators.min(250), Validators.max(3840), Validators.required, OPValidators.integer]
      ),
      browserHeight: this.formBuilder.control(
        DEFAULT_BROWSER_HEIGHT,
        [Validators.min(250), Validators.max(4000), Validators.required, OPValidators.integer]
      ),
      userAgent: this.formBuilder.control(DEFAULT_USER_AGENT),
      rfmConfig: this.formBuilder.control([]),
      easyBlockTags: this.formBuilder.control({ tagCategoryIds: [], tagIds: [] }),
      webhook: this.formBuilder.control(this.webhookUrl, [OPValidators.url]),
      blackoutEnabled: this.formBuilder.control(DEFAULT_BLACKOUT_ENABLED),
      blackoutStart: new UntypedFormControl({ value: '', disabled: true }, [OPValidators.validTime]),
      blackoutEnd: new UntypedFormControl({ value: '', disabled: true }, [OPValidators.validTime]),
      startingDate: this.formBuilder.control(this.minStartDate, [OPValidators.validDate]),
      startingTime: this.formBuilder.control(
        dateUtils.timeStringToInputValue(this.minStartDate, use24Hour),
        [OPValidators.validTime]
      ),
      flashLiveVideo: this.formBuilder.control(DEFAULT_LIVE_VIDEO),
      vpn: this.formBuilder.control(DEFAULT_VPN_ENABLED),
      gpc: this.formBuilder.control(DEFAULT_GPC_ENABLED),
      blockThirdPartyCookies: this.formBuilder.control(DEFAULT_BLOCK_3RD_PARTY_COOKIES_ENABLED),
      hasRecipients: this.formBuilder.control(DEFAULT_HAS_RECIPIENTS),
      recipients: this.formBuilder.control('', [OPValidators.emails]),
      monitorJourney: this.formBuilder.control(false),
      notes: this.formBuilder.control(''),
      folderData: this.formBuilder.control({
        folder: null,
        subFolder: null,
        dataLayer: '',
      }),
      recurrence: this.formBuilder.control({}),
    });
  }

  private initListeners() {
    this.webJourneySetupForm.valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.destroy$)
    ).subscribe(model => this.emitChanges(model));

    this.folderData.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((folderData: IFolderAndSubFolderData) => this.onSelectDomain.emit(this.folderData.value.subFolder));

    this.blackoutEnabled.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(this.blackoutEnabledChanged.bind(this));

    this.frequency.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.frequencyChanged(value);
      });

    this.startingDate.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.startingDateChanged(value);
        setTimeout(() => this.onRunDateChanged.emit());
      });

    this.startingTime.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => this.onRunTimeChanged.emit());
      });

    this.location.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(loc => {
        if (!this.customProxy.value && loc) {
          // Set the selected location, so we know what to display in the input
          // for map/label
          this.selectedLocation = this.locations.find(location => location.name === loc);
          this.setCachedLocation(loc);
        }
      });

    // listen for search field value changes
    this.locationFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterLocations();
      });
  }

  protected filterLocations() {
    if (!this.locations) {
      return;
    }
    // get the search keyword
    let search = this.locationFilterCtrl.value;
    if (!search) {
      this.filteredLocations.next(this.locations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the locations
    this.filteredLocations.next(
      this.sortLocations(this.locations.filter(loc => {
        const match = loc.label.toLowerCase().indexOf(search) > -1;
        const currentlySelected = loc.name.toLowerCase() === this.location.value.toLowerCase();
        return match || currentlySelected;
      }))
    );
  }

  private initValidation() {
    this.webJourneySetupForm.controls.folderData.setValidators(
      [
        Validators.required
      ]);
  }

  // Filter and sort the locations with US at the top of list
  sortLocations(locations: IGeoLocation[]): IGeoLocation[] {
    return locations.sort((a, b) => {
      // Sort 'us' countryCode first
      if (a.countryCode === 'us' && b.countryCode !== 'us') {
        return -1;
      } else if (a.countryCode !== 'us' && b.countryCode === 'us') {
        return 1;
      } else {
        // Sort alphabetically by label
        return a.label.localeCompare(b.label);
      }
    });
  }

  private loadUser(): Observable<IUser> {
    return this.accountsService.getUser()
      .pipe(
        tap((user: IUser) => {
          this.user = user;

          // only add the users email when creating a new journey
          if (!this.isEditMode) this.recipients.patchValue(this.user.email);
        })
      );
  }

  private loadLabels(): void {
    this.labelsService.getLabels().subscribe(labels => {
      this.labels = labels;
    });
  }

  private loadRfms(): void {
    this.rfmService.getRfmConfigs().subscribe((configs: IRFMConfigV3[]) => {
      this.remoteFileMappings = configs
        .filter((rfmConfig: IRFMConfigV3) => !!rfmConfig.name)
        .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    });
  }

  private loadScriptServicesCounts(): void {
    this.webJourneyAPIService.getScriptServicesCounts().then(counts => {
      this.monitoredJourneysLeft = (counts.maxMonitoredJourneys === -1)
        ? counts.maxMonitoredJourneys
        : Math.max(counts.maxMonitoredJourneys - counts.usedMonitoredJourneys, 0);

      // disable monitor checkbox when no monitors left and this journey isn't already  monitored
      if (counts.maxMonitoredJourneys <= counts.usedMonitoredJourneys && !this.monitorJourney.value) {
        this.monitorJourney.disable();
      }
    });
  }

  private loadFolders(): Observable<IFolder[]> {
    return this.foldersService.getFoldersObservable(false);
  }

  private loadDomains(): Observable<IDomain[]> {
    return this.domainsService.getDomainsObservable(false);
  }

  private fillForm(webJourney: Partial<IWebJourney>): void {
    let location;
    let customProxy;
    if (this.customProxySupport && webJourney.options.customProxy) {
      location = webJourney.options.customProxy;
      customProxy = true;
    } else {
      location = webJourney.options.location;
    }
    this.assignLocations();

    this.cachedNextRun = webJourney.options.nextRun;

    this.folderId = webJourney.folderId;
    this.domainId = webJourney.domainId;

    const folder = this.folders.find(f => f.id === webJourney.folderId);
    const domain = this.domains.find(d => d.id === webJourney.domainId);

    const blackout = blackoutToUIModelWithOffset({
      start: webJourney.options.blackoutPeriod?.start,
      end: webJourney.options.blackoutPeriod?.end
    },
      this.user.timezone,
      isSafari(this.window)
    );

    const blackoutControls = blackout ?
      {
        blackoutEnabled: !!blackout.start,
        blackoutStart: blackout.start,
        blackoutEnd: blackout.end
      }
      : {};

    const valueToWrite = {

      name: webJourney.name,
      labels: webJourney.labels,
      frequency: webJourney.options.frequency,
      rfmConfig: webJourney.options.rfmConfig,
      location,
      customProxy,
      browserWidth: webJourney.options.browserWidth,
      browserHeight: webJourney.options.browserHeight || DEFAULT_BROWSER_HEIGHT,
      userAgent: webJourney.options.userAgent,

      startingDate: webJourney.options.nextRun ? new Date(webJourney.options.nextRun) : new Date(),
      startingTime: dateUtils.timeStringToInputValue(webJourney.options.nextRun ? new Date(webJourney.options.nextRun) : new Date(), !isSafari(this.window)),

      hasRecipients: DEFAULT_HAS_RECIPIENTS,
      recipients: webJourney.emails ? webJourney.emails.join('\n') : null,

      flashLiveVideo: webJourney.options.flashLiveVideoEnabled,
      vpn: webJourney.options.vpnEnabled,
      gpc: webJourney.options.gpcEnabled,
      blockThirdPartyCookies: webJourney.options.blockThirdPartyCookies,
      webhook: webJourney.options.webHookUrl,
      monitorJourney: webJourney.options.monitoredByScriptServices,
      ...blackoutControls,
      notes: webJourney.notes,
      folderData: {
        folder,
        subFolder: domain,
        dataLayer: domain?.dataLayer || '',
      },
      easyBlockTags: this.tagsToBlock,
    } as Partial<IWebJourneySetupForm>;

    if (valueToWrite.monitorJourney) {
      this.monitorJourney.enable();
    }

    this.webJourneySetupForm.patchValue(valueToWrite);

    // MatDatePicker is adding validation error `matDatepickerMin` causing the form to be invalid
    // If we are editing a journey that has a starting date less than now we should reset the validators
    // to avoid this issue.
    if (this.minStartDate.getTime() > valueToWrite.startingDate.getTime()) {
      this.minStartDate = valueToWrite.startingDate;
      this.startingDate.setValidators([OPValidators.validDate]);
      this.startingDate.updateValueAndValidity();
    }

    this.checkLocationAndVPNSettings(webJourney.options.location, webJourney.options.vpnEnabled);
  }

  // CVA implementation
  writeValue(webJourney: Partial<IWebJourney>): void {
    if (!webJourney || !Object.getOwnPropertyNames(webJourney).length) return;

    this.isEditMode = true;
    this.selectedLabels = webJourney.labels;
    this.recurrenceSchedule = {
      schedule: webJourney.options.schedule as IOpRecurrenceScheduleResponse
    };
    this.dataPromise.then(() => {
      this.fillForm(webJourney);
    });
  }

  private async assignLocations(): Promise<void> {
    // Set the location country codes to lowercase
    this.locations = this.locations?.map(location => ({
      ...location,
      countryCode: location.countryCode.toLowerCase()
    }));

    this.setLocation();

    // Sort locations alphabetically
    this.locations = this.sortLocations(this.locations);
    this.filteredLocations.next(this.locations.slice());
  }

  private setCachedLocation(locationName: string): void {
    if (this.locations?.length > 0) {
      this.cachedLocationObj = this.locationObj = this.locations.find(location => location.name === locationName);
    } else {
      this.cachedLocationObj = this.locationObj;
    }
  }

  private setLocation(): void {
    if (!this.location) {
      this.location.setValue(DEFAULT_LOCATION);
    }
    const curLocationName = this.location.value;
    this.locationObj = this.locations.find(location => location.name === curLocationName);
    this.cachedLocationObj = this.locationObj;
  }

  registerOnChange(fn: (value: IWebJourneySetupForm) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Validator implementation
  validate(c: AbstractControl): ValidationErrors {
    return this.webJourneySetupForm.valid ?
      null : {
        setupForm: { valid: false, message: 'Invalid web journey setup form' }
      };
  }

  markAsTouched() {
    Object.values(this.webJourneySetupForm.controls).forEach(control => {
      control.markAsTouched();
    });

    this.onTouched();
  }

  createNewFileSubstition(): void {
    this.modalService.openModal(RfmEditorComponent, { data: null })
      .afterClosed()
      .subscribe((rfmConfig?: IRFMConfigV3CreationRequest) => {
        if (rfmConfig) {
          this.rfmService.createRfmConfig(rfmConfig).subscribe(
            (config: IRFMConfigV3) => {
              this.loadRfms();
            },
            error => {
              console.log(error);
              this.showSnackbarError('There was a problem saving the remote file mapping configuration.');
            }
          );
        }
      });
  }

  private showSnackbarError(errorMessage: string): void {
    this.snackbar.open(
      errorMessage,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom' }
    );
  }

  get name(): AbstractControl {
    return this.webJourneySetupForm.get('name');
  }

  get folderData(): AbstractControl {
    return this.webJourneySetupForm.get('folderData');
  }

  get customProxy(): AbstractControl {
    return this.webJourneySetupForm.get('customProxy');
  }

  get location(): AbstractControl {
    return this.webJourneySetupForm.get('location');
  }

  get locationFilterCtrl(): AbstractControl {
    return this.webJourneySetupForm.get('locationFilterCtrl');
  }

  get frequency(): AbstractControl {
    return this.webJourneySetupForm.get('frequency');
  }

  get rfmConfig(): AbstractControl {
    return this.webJourneySetupForm.get('rfmConfig');
  }

  get flashLiveVideo(): AbstractControl {
    return this.webJourneySetupForm.get('flashLiveVideo');
  }

  get vpn(): AbstractControl {
    return this.webJourneySetupForm.get('vpn');
  }

  get gpc(): UntypedFormControl {
    return this.webJourneySetupForm.get('gpc') as UntypedFormControl;
  }

  get blockThirdPartyCookies(): AbstractControl {
    return this.webJourneySetupForm.get('blockThirdPartyCookies') as UntypedFormControl;
  }

  get blackoutEnabled(): AbstractControl {
    return this.webJourneySetupForm.get('blackoutEnabled');
  }

  get blackoutStart(): AbstractControl {
    return this.webJourneySetupForm.get('blackoutStart');
  }

  get blackoutEnd(): AbstractControl {
    return this.webJourneySetupForm.get('blackoutEnd');
  }

  get startingDate(): AbstractControl {
    return this.webJourneySetupForm.get('startingDate');
  }

  get startingDateValue(): string {
    return this.webJourneySetupForm ? this.startingDate.value : '';
  }

  get startingTime(): AbstractControl {
    return this.webJourneySetupForm.get('startingTime');
  }

  get monitorJourney(): AbstractControl {
    return this.webJourneySetupForm.get('monitorJourney');
  }

  get hasRecipients(): AbstractControl {
    return this.webJourneySetupForm.get('hasRecipients');
  }

  get recipients(): AbstractControl {
    return this.webJourneySetupForm.get('recipients');
  }

  get browserWidth(): AbstractControl {
    return this.webJourneySetupForm.get('browserWidth');
  }

  get browserHeight(): AbstractControl {
    return this.webJourneySetupForm.get('browserHeight');
  }

  get webhook(): AbstractControl {
    return this.webJourneySetupForm.get('webhook');
  }

  get easyBlockTags(): AbstractControl {
    return this.webJourneySetupForm.get('easyBlockTags');
  }

  get recurrence(): AbstractControl {
    return this.webJourneySetupForm.get('recurrence');
  }

  private blackoutEnabledChanged(value: boolean): void {
    if (value) {
      this.blackoutStart.enable();
      this.blackoutEnd.enable();
    } else {
      this.blackoutStart.disable();
      this.blackoutEnd.disable();
    }
  }

  private frequencyChanged(frequency: string): void {
    const startingDate = (frequency === EWebJourneyFrequency.PAUSED) && this.cachedNextRun
      ? new Date(this.cachedNextRun)
      : new Date();

    this.startingDate.patchValue(startingDate);
  }

  // Updating Location/VPN to be mutually exclusive. However, some legacy data
  // sources may have both a non-Oregon location and VPN enabled. In cases like
  // this, disable the location field and leave the VPN enabled. Otherwise, if
  // the location is Oregon, disable the VPN field.
  private checkLocationAndVPNSettings(location: string, vpnEnabled: boolean): void {
    if (vpnEnabled) {
      this.location.disable();
    } else if (location !== DEFAULT_LOCATION) {
      this.vpn.disable();
    }
  }

  locationChange(location: MatSelectChange) {
    this.onLocationChanged.emit(location.value as string);
  }

  vpnChange(vpn: MatSlideToggleChange) {
    this.onVPNChanged.emit(vpn.checked);
  }

  private startingDateChanged(value: Date): void {
    this.startingTime.markAsTouched();
    this.startingTime.updateValueAndValidity();
  }

  private emitChanges(changes: IWebJourneySetupForm): void {
    const rawValue = this.webJourneySetupForm.getRawValue() as IWebJourneySetupForm;

    this.onChange({
      ...changes,
      vpn: rawValue.vpn && this.vpnSupport,
      gpc: rawValue.gpc && this.privacyFeatureSupport,
      flashLiveVideo: rawValue.flashLiveVideo && false,
      monitorJourney: rawValue.monitorJourney,
    });
  }

  createLabel(labelName: string): void {
    this.labelsService.createLabel(labelName).subscribe(label => {
      this.labels.push(label);
      const oldLabelsList = this.webJourneySetupForm.controls.labels.value;
      this.webJourneySetupForm.controls.labels.setValue([...oldLabelsList, label]);
    });
  }

  onTimeFocus(control: string) {
    const c = this.webJourneySetupForm.get(control);
    const timeValue = c.value ? dateUtils.formatDate(dateUtils.parseDate(c.value, EDateFormats.timeTwo), EDateFormats.timeTwo) : dateUtils.timeStringToInputValue(new Date(), !isSafari(this.window));

    c.setValue(timeValue);
  }

  isCreateMode(mode: InputMode): boolean {
    return mode === InputMode.Create;
  }

  onLocationChange(customProxy: boolean): void {
    this.customProxy.setValue(customProxy);

    if (customProxy) {
      this.location.setValue('');
      this.location.setValidators([Validators.required, OPValidators.url]);
    } else {
      if (this.cachedLocationObj) {
        this.location.setValue(this.cachedLocationObj.name);
      } else {
        this.setCachedLocation(DEFAULT_LOCATION);
        this.location.setValue(DEFAULT_LOCATION);
      }

      this.location.setValidators(Validators.required);
    }

    this.location.updateValueAndValidity();
  }

  monitorJourneyChanged(event: MatSlideToggleChange) {
    if (this.monitoredJourneysLeft === -1) return;

    if (event.checked) {
      this.monitoredJourneysLeft -= 1;
    } else {
      this.monitoredJourneysLeft += 1;
    }
  }

  displayHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.addClass(hint, 'show-hint');
  }

  hideHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.removeClass(hint, 'show-hint');
  }

  toggleAdditionalSetupView(): void {
    this.showAdditionalSetupOptions = !this.showAdditionalSetupOptions;
  }

  handleLabelCreated(label: ILabel): void {
    this.selectedLabels.push({ id: label.id, name: label.name });
    this.webJourneySetupForm.controls.labels.patchValue([...this.selectedLabels]);
    this.selectedLabels = [...this.selectedLabels];
  }

  handleLabelSelected(label: ILabel): void {
    this.webJourneySetupForm.controls.labels.patchValue([...this.selectedLabels]);
    this.selectedLabels = [...this.selectedLabels];
  }

  handleLabelRemoved(label: ILabel): void {
    this.selectedLabels = this.selectedLabels.filter((l: ILabel) => l.id !== label.id);
    this.selectedLabels = [...this.selectedLabels];
    this.webJourneySetupForm.controls.labels.patchValue([...this.selectedLabels]);
  }
}
