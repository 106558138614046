export enum EAuditConsoleLogCounts {
  errorLogCount = 'errorLogCount',
  warnLogCount = 'warnLogCount',
  debugLogCount = 'debugLogCount',
  infoLogCount = 'infoLogCount',
  otherLogCount = 'otherLogCount'
}

export enum EAuditExportType {
  error = 'error',
  warn = 'warn',
  debug = 'debug',
  info = 'info',
  other = 'other'
}

export interface IAuditConsoleLogSummary {
  [EAuditConsoleLogCounts.errorLogCount]: number;
  [EAuditConsoleLogCounts.warnLogCount]: number;
  [EAuditConsoleLogCounts.debugLogCount]: number;
  [EAuditConsoleLogCounts.infoLogCount]: number;
  [EAuditConsoleLogCounts.otherLogCount]: number;
}

export interface IAuditConsoleLog {
  timestamp: number;
  message: string;
  source: string;
  level: EAuditExportType;
  count: number;
}

export interface IAuditConsoleLogs {
  metadata: {
    pagination: {
      currentPageNumber: number,
      currentPageSize: number,
      pageSize: number,
      totalCount: number,
      totalPageCount: number
    }
  };
  consoleLogs: IAuditConsoleLog[];
}
