import { Interval } from './Interval';

export interface IInterval {
    startInclusive: boolean;
    start: number;
    end?: number;
    endInclusive: boolean;
    unit?: string;
  }

  export class TimeUnit {
    static days = 'DAYS';
    static daysShort = 'd';
    static hours = 'HOURS';
    static hoursShort = 'h';
    static microseconds = 'MICROSECONDS';
    static microsecondsShort = 'µs';
    static milliseconds = 'MILLISECONDS';
    static millisecondsShort = 'ms';
    static minutes = 'MINUTES';
    static minutesShort = 'm';
    static nanoseconds = 'NANOSECONDS';
    static nanosecondsShort = 'ns';
    static seconds = 'SECONDS';
    static secondsShort = 's';
  }

  export interface IIntervalService {
    stringify: (intervals: IInterval | IInterval[]) => string;
    isEndMax: (interval: IInterval) => boolean;
    isStartMin: (interval: IInterval) => boolean;
  }

  export class IntervalService implements IIntervalService {

    static $inject = [];

    isEndMax = (interval: IInterval): boolean => {
      if (interval.unit) {
        switch (interval.unit.toLocaleLowerCase()) {
          case TimeUnit.nanoseconds.toLowerCase():
            return interval.end === Interval.maxNanoseconds;
          case TimeUnit.days.toLowerCase():
            return interval.end === Interval.maxDays;
          case TimeUnit.hours.toLowerCase():
            return interval.end === Interval.maxHours;
          case TimeUnit.microseconds.toLowerCase():
            return interval.end === Interval.maxMicroseconds;
          case TimeUnit.milliseconds.toLowerCase():
            return interval.end === Interval.maxMilliseconds;
          case TimeUnit.minutes.toLowerCase():
            return interval.end === Interval.maxMinutes;
          case TimeUnit.seconds.toLowerCase():
            return interval.end === Interval.maxSeconds;
          default:
            return false;
        }
      }
      return interval.end === Interval.max;
    }

    isStartMin = (interval: IInterval): boolean => {
      if (interval.unit) {
        switch (interval.unit.toLocaleLowerCase()) {
          case TimeUnit.nanoseconds.toLowerCase():
            return interval.start === -Interval.maxNanoseconds;
          case TimeUnit.days.toLowerCase():
            return interval.start === -Interval.maxDays;
          case TimeUnit.hours.toLowerCase():
            return interval.start === -Interval.maxHours;
          case TimeUnit.microseconds.toLowerCase():
            return interval.start === -Interval.maxMicroseconds;
          case TimeUnit.milliseconds.toLowerCase():
            return interval.start === -Interval.maxMilliseconds;
          case TimeUnit.minutes.toLowerCase():
            return interval.start === -Interval.maxMinutes;
          case TimeUnit.seconds.toLowerCase():
            return interval.start === -Interval.maxSeconds;
          default:
            return false;
        }
      }
      return interval.start === Interval.min;
    }

    private toShortTimeUnit = (interval: IInterval) => {
      if (interval.unit) {
        switch (interval.unit.toLocaleLowerCase()) {
          case TimeUnit.nanoseconds.toLowerCase():
          case TimeUnit.nanosecondsShort.toLowerCase():
            return TimeUnit.nanosecondsShort;

          case TimeUnit.days.toLowerCase():
          case TimeUnit.daysShort.toLowerCase():
            return TimeUnit.daysShort;

          case TimeUnit.hours.toLowerCase():
          case TimeUnit.hoursShort.toLowerCase():
            return TimeUnit.hoursShort;

          case TimeUnit.microseconds.toLowerCase():
          case TimeUnit.microsecondsShort.toLowerCase():
            return TimeUnit.microsecondsShort;

          case TimeUnit.milliseconds.toLowerCase():
          case TimeUnit.millisecondsShort.toLowerCase():
            return TimeUnit.millisecondsShort;

          case TimeUnit.minutes.toLowerCase():
          case TimeUnit.minutesShort.toLowerCase():
            return TimeUnit.minutesShort;

          case TimeUnit.seconds.toLowerCase():
          case TimeUnit.secondsShort.toLowerCase():
            return TimeUnit.secondsShort;
          default:
            return '';
        }
      }
      return '';
    }

    stringify = (intervals: IInterval | IInterval[]): string => {

      var intervalString = (interval: IInterval) => {
        var timeUnit = this.toShortTimeUnit(interval);
        var start = `${interval.startInclusive ? '[' : '('}${this.isStartMin(interval) ? '' : interval.start}${timeUnit}`;
        var end = `${this.isEndMax(interval) ? '' : interval.end}${timeUnit}${interval.endInclusive ? ']' : ')'}`;
        return `${start},${end}`;
      };

      if (intervals instanceof Array) {
        return intervals.reduce(
          (query: string, interval: IInterval) => `${query} ${intervalString(interval)}`,
          ''
        ).trim();
      } else {
        return intervalString(<IInterval>intervals);
      }
    }

}
