<div class="table-wrap">
  <table mat-table
         class="styled-mat-table"
         [class.loading]="loadingRules"
         [dataSource]="dataSource"
         matSort
         multiTemplateDataRows>
    <ng-container [matColumnDef]="ESortColumnsV3.Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell
          *matCellDef="let item"
          [matTooltip]="item.name"
          matTooltipPosition="above"
          class="truncated">
        <span class="hover-link" (click)="editRule.emit(item)">
          {{ item.name }}
        </span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="ESortColumnsV3.Type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let item" (click)="$event.stopPropagation()">
        {{ item?.fromTemplate ? 'Built-In' : 'Custom' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="labels">
      <th mat-header-cell *matHeaderCellDef>Labels</th>
      <td mat-cell *matCellDef="let item" (click)="$event.stopPropagation()">
        <op-label-manager [selectedLabels]="item.labels"
                          (onLabelCreated)="createLabel(item, $event)"
                          (onLabelSelected)="addLabel(item)"
                          (onLabelRemoved)="removeLabel(item, $event)"
        ></op-label-manager>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="ESortColumnsV3.Audits">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Audits</th>
      <td mat-cell
          class="usage"
          [class.disabled]="!item.usage?.auditCount"
          [class.opened]="selectedRule === item && selectedItemType === RuleDetailsTypes.audit"
          *matCellDef="let item"
          (click)="item.usage?.auditCount && selectRule(item, RuleDetailsTypes.audit)">
        <div class="flex-row">
          <mat-icon>explore</mat-icon>
          <span>{{ item.usage?.auditCount || 0 }}</span>
          <mat-icon>expand_more</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="ESortColumnsV3.WebJourneys">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Journeys</th>
      <td mat-cell
          class="usage"
          [class.disabled]="!item.usage?.webJourneyCount || !isWebJourneyAccessible"
          [class.opened]="selectedRule === item && selectedItemType === RuleDetailsTypes.webJourney"
          *matCellDef="let item"
          (click)="item.usage?.webJourneyCount && isWebJourneyAccessible && selectRule(item, RuleDetailsTypes.webJourney)">
        <div class="flex-row">
          <mat-icon>map</mat-icon>
          <span>{{ item.usage?.webJourneyCount || 0 }}</span>
          <mat-icon>expand_more</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="ESortColumnsV3.Owner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Owner</th>
      <td mat-cell *matCellDef="let item">
        {{userIdToUserMap?.get(item.ownedByUserId)?.firstName}} {{userIdToUserMap?.get(item.ownedByUserId)?.lastName}}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="ESortColumnsV3.ModifiedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified</th>
      <td mat-cell *matCellDef="let item">
        <div class="date-format">
          <span>{{item.updatedAt | date: dateFormat}} </span>
          <span>{{item.updatedAt | date: timeFormat}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="notification">
      <th mat-header-cell *matHeaderCellDef>Notification Options</th>
      <td mat-cell *matCellDef="let item">
        <mat-icon [matTooltip]="item.recipients.join(', ') ||  'No email configured'"
                  matTooltipPosition="above"
                  [class.recipients]="item.recipients.length">local_post_office</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
        <mat-menu #menu="matMenu" class="rules-library-menu">
          <button *ngFor="let menuItem of menuItems"
                  mat-menu-item
                  class="table-action-icon"
                  [class.hidden]="menuItem.hidden && menuItem.hidden(item)"
                  [disabled]="menuItem.disable && menuItem.disable(item)"
                  (click)="$event.stopPropagation(); menuItem.onClick(item)">
            <mat-icon class="mat-icon">{{menuItem.icon}}</mat-icon> {{ menuItem.label }}
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandableRow">
      <td mat-cell *matCellDef="let item" [attr.colspan]="displayedColumns.length">
        <div #container
             [@expand]="{
               value: item === selectedRule ? (loading ? 'loading' : 'expanded') : 'collapsed',
               params: {
                height: this.lastOpenedNestedTableHeight ? this.lastOpenedNestedTableHeight + 'px' : '*'
               }
             }"
             (@expand.done)="animationDone($event, container)">
          <div class="details">
            <rule-details class="flex-col flex-stretch"
                          [rule]="visibleRuleDetails"
                          [type]="selectedItemType"
                          [users]="users"
                          [loading]="loading">
            </rule-details>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row class="hoverable" *matRowDef="let row; columns: displayedColumns" [class.expanded]="selectedRule === row"></tr>
    <tr mat-row class="expandable" *matRowDef="let row; columns: ['expandableRow']" [class.expanded]="selectedRule === row"></tr>

    <tr *matNoDataRow>
      <td [colSpan]="displayedColumns.length">
        <div *ngIf="!loadingRules" class="no-data">
          <span>No rules matched applied filters.</span>
        </div>
      </td>
    </tr>
  </table>
  <mat-paginator class="styled-mat-paginator"
                 [class.loading]="loadingRules"
                 [length]="pagination?.totalCount"
                 [pageSize]="pagination?.pageSize"
                 [pageIndex]="pagination?.currentPageNumber"
                 [hidePageSize]="true">
  </mat-paginator>
  <div *ngIf="loadingRules">
    <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
  </div>
</div>
