<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="25"
               [strokeWidth]="4">
  </mat-spinner>
</div>
<ng-container *ngIf="!loading">
  <div class="console-log-tools" [class.in-audit]="reportType === 'audit'" *ngIf="logTypeCounts" [ngClass]="{ shadowed: isScrolled }">
    <div class="top-section" >
      <op-clearable-input [placeholderText]="'Filter Console Log'"
                          (onUserInput)="filterTable($event)"
                          [defaultValue]="searchString"
                          class="clearable-input">
      </op-clearable-input>
      <share-report-btn *ngIf="reportType === 'audit'"
                        [auditId]="itemId"
                        [runId]="runId"
                        [pageId]="pageId"
                        [activeTab]="activeTab">
      </share-report-btn>
    </div>
    <div class="filter-icons-wrap">
      <div class="filter-icons">
        <button class="filter-icon filter-icon-severe"
                [class.filter-icon-disabled]="!logTypeVisibility.SEVERE"
                (click)="toggleView('SEVERE')"
                matTooltip="Errors"
                matTooltipPosition="above">
          <mat-icon>cancel</mat-icon>
          <span class="monospaced">{{ logTypeCounts.SEVERE }}</span>
        </button>
        <button class="filter-icon filter-icon-warning"
                [class.filter-icon-disabled]="!logTypeVisibility.WARNING"
                (click)="toggleView('WARNING')"
                matTooltip="Warnings"
                matTooltipPosition="above">
          <mat-icon>warning</mat-icon>
          <span class="monospaced">{{ logTypeCounts.WARNING }}</span>
        </button>
        <button class="filter-icon filter-icon-bug"
                [class.filter-icon-disabled]="!logTypeVisibility.FINE"
                (click)="toggleView('FINE')"
                matTooltip="Debugs"
                matTooltipPosition="above">
          <mat-icon>bug_report</mat-icon>
          <span class="monospaced">{{ logTypeCounts.FINE }}</span>
        </button>
        <button class="filter-icon filter-icon-info"
                [class.filter-icon-disabled]="!logTypeVisibility.INFO"
                (click)="toggleView('INFO')"
                matTooltip="Info"
                matTooltipPosition="above">
          <mat-icon>info</mat-icon>
          <span class="monospaced">{{ logTypeCounts.INFO }}</span>
        </button>
        <button class="filter-icon filter-icon-verbose"
                [class.filter-icon-disabled]="!logTypeVisibility.verbose"
                (click)="toggleView('verbose')"
                matTooltip="Text"
                matTooltipPosition="above">
          <mat-icon>chat</mat-icon>
          <span class="monospaced">{{ logTypeCounts.verbose }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="log-wrap">
    <ng-container *ngFor="let log of filteredLogs">
      <div class="log-message log-message-{{ log.level | lowercase }}" *ngIf="logTypeVisibility[log.level]">
        <div class="log-message-main">
          <mat-icon *ngIf="log.level === 'SEVERE'">cancel</mat-icon>
          <mat-icon *ngIf="log.level === 'WARNING'">warning</mat-icon>
          <mat-icon *ngIf="log.level === 'FINE'">bug_report</mat-icon>
          <mat-icon *ngIf="log.level === 'INFO'">info</mat-icon>
          <mat-icon *ngIf="log.level === 'verbose'">chat</mat-icon>
          <div class="log-message-text">
            <p>{{ log.message }}</p>
            <small *ngIf="log.source">{{ log.source }}</small>

            <div *ngIf="log.relatedTag" class="related-tag">
              <small>
                <span>Related tag:</span>
                <img
                  class="tag"
                  [alt]="log.relatedTag.tagName"
                  [src]="getTagIconUrl(log.relatedTag.tagId)"
                />
                <span>{{log.relatedTag.tagName}}</span>
              </small>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <p class="no-logs-message" *ngIf="!this.logs?.length">No logs found</p>
</ng-container>
