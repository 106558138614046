import { EAuditReportFilterTypes } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';

export interface IAuditSummaryTagBase {
  tagId: number;
  tagName: string;
  tagCategoryId: number;
  tagCategoryName: string;
  pageCount: number;
  pageNotPresentCount: number;
  tagAccountCount: number;
  tagLoadTimeAverage: number;
}

export interface IAuditSummaryTag extends IAuditSummaryTagBase {
  uniqueVariableCount: number;
}

export interface IAuditSummaryTags {
  tags: IAuditSummaryTag[];
}

export interface IAuditSummaryDetectedTagsTableRow extends IAuditSummaryTag {
  menuOpen: boolean;
  tagLoadTimeClass: string;
}

export interface IAuditSummaryPrimaryTag extends IAuditSummaryTagBase {
  tagVersionCount: number;
  statusCodeDistribution: IAuditSummaryPrimaryTagStatusCodes;
}

export interface IAuditSummaryPrimaryTags {
  tags: IAuditSummaryPrimaryTag[];
}

export interface IAuditSummaryPrimaryTagStatusCodes {
  good: number;
  redirect: number;
  broken: number;
}

export interface IAuditSummaryHeaderData {
  totalPageCount: number;
  filteredPageCount: number;
  totalUniquePrimaryTagCount: number;
  filteredUniquePrimaryTagCount: number;
  ruleSummary: {
    totalFailedRuleCount: number;
    totalPassedRuleCount: number;
    totalNotAppliedRuleCount: number;
    filteredRuleCount: number;
  },
  filteredBrokenPageCount: number;
  filteredUniqueTagCount: number;
  filteredBrokenTagCount: number;
}

export interface IAuditSummaryTrendsDatum {
  runs: IAuditSummaryTrendItems[]
}
export interface IAuditSummaryTrendItems {
  runId: number;
  completedAt: Date;
  totalFailedRuleCount: number;
  totalPassedRuleCount: number;
  totalNotAppliedRuleCount: number;
  totalBrokenPageCount: number;
  totalUniqueTagCount: number;
  totalBrokenTagCount: number;
}

export interface IAuditScoreDatum {
  total: IAuditScoreValue;
  tagPresence: IAuditScoreValue;
  tagPerformance: IAuditScoreValue;
  rules: IAuditScoreValue;
  pagePerformance: IAuditScoreValue;
  cookies: IAuditScoreValue;
}

export interface IAuditSummaryScoresTrendsDatum {
  runs: IAuditSummaryScoreTrendItems[];
}

export interface IAuditSummaryScoreTrendItems {
  runId: number;
  completedAt: Date;
  scores: IAuditScoreDatum;
}

export interface IAuditScoreValue {
  score: number;
  max: number;
}

export interface IAuditScoreTrendsDatum {
  runs: IAuditScoreTrendItems[]
}
export interface IAuditScoreTrendItems {
  runId: number;
  completedAt: string;
  scores: IAuditScoreDatum;
}

export interface IAuditSummaryTrendsRuleResults {
  completedAt: Date;
  totalFailedRuleCount: number;
  totalPassedRuleCount: number;
  totalNotAppliedRuleCount: number
}

export interface IAuditSummaryTrend {
  runId: number;
  completedAt: Date;
  trendValue: number;
}

export const AuditSummaryRelevantFilters = [
  EAuditReportFilterTypes.TagPrimaryTagsOnly,
  EAuditReportFilterTypes.TagId,
  EAuditReportFilterTypes.TagCategory,
  EAuditReportFilterTypes.TagVendorId,
];

export enum EAuditSummaryScoreTrendNames {
  AuditScore = 'total',
  TagPresenceScore = 'tag_presence',
  TagPerformanceScore = 'tag_performance',
  RulesScore = 'rules',
  PagePerformanceScore = 'page_performance',
  CookiesScore = 'cookies',
}

export enum EAuditSummaryTrendType {
  tagInventory = 'tag_inventory',
  auditScore = 'audit_score',
}

export interface IAuditSummaryScoreTrendByName {
  runs: IAuditSummaryScoreTrend[];
}

export interface IAuditSummaryScoreTrend {
  runId: number;
  completedAt: string;
  score: IAuditScoreValue;
}
