import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { AccountsService } from '@app/components/account/account.service';
import { IKeyValue, IUser } from '@app/moonbeamModels';
import { AdminAccountsService } from '../admin-accounts.service';
import { mergeMap, startWith, tap, takeUntil, debounceTime, auditTime } from 'rxjs/operators';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import {
  IAccountsTableRow,
  IAdminAccountDTO,
  IAdminAccount,
  EManageAccountStatusStringToNumber, AccountTypeIdToDisplayValue,
} from './manage-accounts.constants';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { Router } from '@angular/router';
import { Names } from '@app/moonbeamConstants';
import { IOriginalStateInfo, StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { AdminPortalUrlBuilders } from '../admin-portal.constants';
import { ILabelsMap } from '@app/components/manage/shared/services/manage-cards-data/manage-cards-data.service';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { IEventManager } from '@app/components/eventManager/eventManager';
import * as moonbeam from '@app/moonbeamConstants';
import { UserPermissions } from '@app/authUtils';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { CreateNewAccountComponent } from './create-new-account/create-new-account.component';
import { EditAccountComponent } from './edit-account/edit-account.component';
import { AdminService } from '@app/components/admin-portal/system-status/admin.service';
import {
  EManageAccountsFilterTypes,
  IManageAccountsFilter
} from '@app/components/admin-portal/manage-accounts/manage-accounts-filter-bar/manage-accounts-filter-bar.constants';
import { ManageAccountsFilterBarService } from '@app/components/admin-portal/manage-accounts/manage-accounts-filter-bar/manage-accounts-filter-bar.service';
import { IPaginationMetaData } from '@app/components/consent-categories/consent-categories.models';
import { EAccountType } from '@app/components/core/services/authentication.enums';

interface ISearchQuery {
  searchQuery: string;
}

@Component({
  selector: 'op-manage-accounts',
  templateUrl: './manage-accounts.component.html',
  styleUrls: ['./manage-accounts.component.scss']
})
export class ManageAccountsComponent implements OnInit, AfterViewInit, OnDestroy {
  // general variables
  statuses: IKeyValue<number, string>[];
  user: IUser;
  accounts: IAccountsTableRow[] = [];
  accountId: number;
  loading: boolean = true;
  displayedColumns: string[] = ['id', 'company', 'type', 'status', 'name', 'username', 'email', 'login'];

  filters: IManageAccountsFilter[] = [];
  filtersCount = 0; // -1 is a valid number representing going from >0 to 0
  manageAccountsFilters: EManageAccountsFilterTypes[] = [
    EManageAccountsFilterTypes.Type,
    EManageAccountsFilterTypes.Search,
    EManageAccountsFilterTypes.Status,
  ];
  pagination: IPaginationMetaData = {
    currentPageNumber: 0,
    currentPageSize: 100,
    pageSize: 100,
    totalCount: 0,
    totalPageCount: 0,
  };

  // query params
  sortColumn: string = 'id';
  sortAscending: boolean = true;

  // private variables
  private updateTable$ = new Subject();
  private destroy$: Subject<void> = new Subject();
  private filtersUpdated$ = new Subject();
  private typeahead$ = new BehaviorSubject('');
  private searchQuery$ = new BehaviorSubject({ searchQuery: '' });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private adminAccountsService: AdminAccountsService,
    private accountsService: AccountsService,
    private adminService: AdminService,
    private storageService: StorageService,
    private authenticationStorageService: AuthenticationStorageService,
    private eventManager: IEventManager,
    private modalService: OpModalService,
    private filterBarService: ManageAccountsFilterBarService,
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getStatuses();
  }

  ngAfterViewInit(): void {
    this.subscribeToTypeahead();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(this.manageAccountsFilters);

    this.filterBarService.filters$
      .pipe(
        debounceTime(100),
        takeUntil(this.destroy$),
      ).subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(filters: IManageAccountsFilter[]){
    // filtersCount will take into consideration previous count as well
    // because we don't want the filter bar blinking off and on when clearing filters
    this.filtersCount = this.filtersCount > 0 && !filters.length ? -1 : filters.length;
    this.filters = filters;
    this.resetPagination();
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }

    this.filtersUpdated$.next();
  }

  resetPagination(): void {
    this.pagination = {
      totalCount: 0,
      totalPageCount: 0,
      pageSize: 100,
      currentPageSize: 0,
      currentPageNumber: 0
    };
    this.paginator.pageIndex = 0;
  }

  private getUser(): void {
    this.accountsService.getUser().subscribe((user: IUser) => {
      this.user = user;
      this.accountId = user.accountId;
    });
  }

  private getStatuses(): void {
    this.adminAccountsService.getStatuses().subscribe((statuses: IKeyValue<number, string>[]) => {
      this.statuses = statuses;
      this.initFilters();
      this.handleTableEvents();
    });
  }

  private handleTableEvents(): void {
    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.updateTable$,
      this.filtersUpdated$,
      this.searchQuery$
    )
    .pipe(
      startWith({}),
      auditTime(500),
      tap(() => {
        this.loading = true;
      }),
      mergeMap((mergedEvent: Sort & PageEvent & ISearchQuery) => {
        let filters = this.filters;

        if (mergedEvent && mergedEvent.hasOwnProperty('active')) {
          this.sortColumn = mergedEvent.active;
          this.resetPagination();
        }

        if (mergedEvent && mergedEvent.hasOwnProperty('direction')) {
          this.sortAscending = mergedEvent.direction === 'asc';
          if (mergedEvent.direction === '') this.sortAscending = undefined;
          this.resetPagination();
        }

        if (mergedEvent && mergedEvent.hasOwnProperty('pageIndex')) {
          this.pagination.currentPageNumber = mergedEvent.pageIndex;
        }

        if (mergedEvent?.searchQuery) {
          filters = this.filters.concat([{
            display: `Search contains '${mergedEvent.searchQuery}'`,
            type: EManageAccountsFilterTypes.Search,
            value: mergedEvent.searchQuery
          }]);
        }

        return this.adminAccountsService.getV3AccountsPaged(
          this.pagination.pageSize,
          this.pagination.currentPageNumber,
          this.sortColumn,
          this.sortAscending,
          filters
        );
      })
    )
    .subscribe((accountsDTO: IAdminAccountDTO) => {
      const { accounts, metadata } = accountsDTO;
      this.initTable(accounts);
      this.pagination = metadata.pagination;
      this.loading = false;
    });
  }

  private initTable(data: IAdminAccount[]): void {
    if (!this.statuses) return;

    this.accounts = data.map((account: IAdminAccount) => {
        return {
          id: account.id,
          company: account.company,
          status: this.getStatusType(account.status as string),
          name: `${account.primaryUser.firstName} ${account.primaryUser.lastName}`,
          username: account.primaryUser.username,
          email: account.primaryUser.email,
          canBeLoggedInAs: this.determineCanBeLoggedInAs(account),
          accountIsActive: this.accountIsActive(account.status),
          accountType: AccountTypeIdToDisplayValue[account.accountType],
          account: account
        } as IAccountsTableRow;
      }
    );
  }

  private getStatusType(statusType: string): string {
    const statusKey = EManageAccountStatusStringToNumber[statusType];
    return this.statuses.find(status => status.key === statusKey).value;
  }

  private determineCanBeLoggedInAs(account: IAdminAccount): boolean {
    return this.getPermissionNumber(this.user?.permissions) >= account.primaryUser.permissions;
  }

  getPermissionNumber(userPermission: string|number): number {
    return typeof userPermission === 'number'
      ? userPermission
      : UserPermissions.find(permission => permission.value === userPermission)?.number;
  }

  getStatusNumber(statusType: string): number {
    return EManageAccountStatusStringToNumber[statusType];
  }

  private accountIsActive(statusType: string|number): boolean {
    const activeStatuses = [2, 3, 7];
    let status = typeof statusType === 'number'
      ? statusType
      : this.getStatusNumber(statusType);
    return activeStatuses.includes(status);
  }

  loginAsAccount(account: IAdminAccount): void {
    const url = DataSourcesUrlBuilders.sources();
    const accountId = +account.id;

    if (accountId == this.accountId) {
      this.router.navigateByUrl(url);
    } else {
      this.adminService.loginAsUser(accountId).then((tokenInfo) => {
        const authorizationData = {token: tokenInfo.accessToken, id: tokenInfo.userId, expiresAt: Date.now() + 10 * 60 * 60 * 1000 };
        const originalState: IOriginalStateInfo = {
          url: AdminPortalUrlBuilders.accounts()
        };
        this.authenticationStorageService.impersonateUser(authorizationData);
        this.storageService.setOriginalState(originalState);
        this.storageService.removeParamsForAudits();
        this.storageService.removeParamsForWebJourneys();

        this.router.navigateByUrl(url).then(() => {
          this.eventManager.publish(moonbeam.Events.loggedInAsAnother);
        });
      }, (error) => {
        console.log('Can\'t log in', error);
      });
    }
  }

  openCreateNewAccountModal(): void {
    this.modalService.openModal(CreateNewAccountComponent, {
      data: {
        statuses: this.statuses
      },
      autoFocus: false
    })
    .afterClosed()
    .subscribe((closeObj?: { account: IAdminAccount, editWhenSaved: boolean}) => {
      if (closeObj) {
        this.updateTable$.next(closeObj.account);
        if (closeObj.editWhenSaved) {
          this.openEditAccountModal(closeObj.account);
        }
      }
    });
  }

  editAccount(row: IAccountsTableRow): void {
    // Transform data back into a v2 account model
    let accountV2 = {
      ...row.account,
      name: row.account.company,
      status: EManageAccountStatusStringToNumber[row.account.status],
    };

    this.openEditAccountModal(accountV2);
  }

  openEditAccountModal(accountV2: IAdminAccount): void {
    this.modalService.openFullscreenModal(EditAccountComponent, {
      data: {
        account: accountV2,
        statuses: this.statuses
      },
      autoFocus: false
    })
    .afterClosed()
    .subscribe(() => {
      // Reload accounts
      this.filtersUpdated$.next();
    });
  }

  subscribeToTypeahead(): void {
    this.typeahead$
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((value) => this.searchQuery$.next({ searchQuery: value }));
  }

  handleTypeahead(searchValue: string): void {
    this.typeahead$.next(searchValue);
  }

  getAccountTypeDisplayValue(accountType: EAccountType): string {
    const displayValue = AccountTypeIdToDisplayValue[accountType];
    return displayValue ? displayValue : 'Unknown';
  }
}
