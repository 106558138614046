import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import { Observable } from 'rxjs';
import {
  IStackedBarChartBarInput,
  IStackedBarChartInput
} from '@app/components/shared/components/viz/vertical-stacked-bar-chart/vertical-stacked-bar-chart.models';

export enum EChartDateRange {
  oneMonth = 'oneMonth',
  threeMonth = 'threeMonth',
  sixMonth = 'sixMonth',
  nineMonth = 'nineMonth',
  thirteenMonth = 'thirteenMonth',
}

export interface IFullscreenChartData {
  value: number;
  date: string;
}

export interface IFullscreenChartDataFormatted {
  value: number;
  date: Date;
}

export interface IFullscreenVerticalStackedBarChartModalConfig {
  // the run completion date from which to compute the default timeframe
  currentRunCompletedAt: string;

  // function for returning data to be displayed in area graph
  getData: (days: number) => Observable<IStackedBarChartInput>;

  // chart configuration (e.g. title, tooltip, etc)
  chartConfig: IFullscreenVerticalStackedBarChartChartConfig;
}

export interface IFullscreenVerticalStackedBarChartChartConfig {
  title: string;

  // Data to plot
  data: IStackedBarChartInput;
}
