import { EAuditReportFilterTypes } from './../../audit-report-filter-bar/audit-report-filter-bar.models';
import {
  IFullscreenChartConfig
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import {
  IHorizontalBarChartTitle
} from '../../../shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.models';
import {
  CommonReportsPagesFiltersConfig,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import { ISparklineChartColorizedBoundaries } from '@app/components/shared/components/viz/sparkline-chart-colorized/sparkline-chart-colorized.models';
import {
    ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export const PAGE_SUMMARY_EXPORT_TYPE = 'page_summary_pages';

export const pageLoadTimeChartTitle: IHorizontalBarChartTitle = {
  title: 'Page Load Time (sec)',
  tooltip: 'The total number of pages within each interval.'
};

export const initialPageStatusCodesChartTitle: IHorizontalBarChartTitle = {
  title: 'Initial Status Codes',
  tooltip: 'A grouping of the total pages having Good (200 series), Redirect (300 series) or Broken (0, 400 or 500 series) HTTP status codes.'
};

export const finalPageStatusCodesChartTitle: IHorizontalBarChartTitle = {
  title: 'Final Status Codes',
  tooltip: 'A grouping of the total pages having Good (200 series), Redirect (300 series) or Broken (0, 400 or 500 series) HTTP status codes.'
};

export enum EPageSummaryTrendNames {
  AVG_PAGE_LOAD_TIME = 'avg_page_load_time',
  BROKEN_INITIAL_PAGES = 'broken_initial_page',
  BROKEN_FINAL_PAGES = 'broken_final_page',
  PAGES_WITH_BROKEN_LINKS = 'pages_with_broken_links',

  LARGEST_CONTENTFUL_PAINT = 'largest_contentful_paint',
  FIRST_CONTENTFUL_PAINT = 'first_contentful_paint',
  TIME_TO_FIRST_BYTE = 'time_to_first_byte',
  CUMULATIVE_LAYOUT_SHIFT = 'cumulative_layout_shift'
}

export const BROKEN_INITIAL_PAGES_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'Broken Initial Pages',
  data: [],
  sideLabel: 'broken initial pages',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => { return `${value} broken initial page${value === 1 ? '' : 's'}`; },
};

export const BROKEN_FINAL_PAGES_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'Broken Final Pages',
  data: [],
  sideLabel: 'broken final pages',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => { return `${value} broken final page${value === 1 ? '' : 's'}`; },
};

export const PAGES_WITH_BROKEN_LINKS_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'Pages With Broken Links',
  data: [],
  sideLabel: 'pages with broken links',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => { return `${value} page${value === 1 ? '' : 's'} with  broken links`; },
};

export const AVG_LOAD_TIME_CHART_CONFIG = {
  title: 'Average Load Time',
  data: [],
  sideLabel: 'seconds',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => { return `${value} second avg. load time`; },
};

export const WIDGET_LARGEST_CONTENTFUL_PAINT_BOUNDARIES: ISparklineChartColorizedBoundaries = { warnThreshold: 2.5, failThreshold: 4 }
export const WIDGET_FIRST_CONTENTFUL_PAINT_BOUNDARIES: ISparklineChartColorizedBoundaries = { warnThreshold: 1.8, failThreshold: 3 }
export const WIDGET_TIME_TO_FIRST_BYTE_BOUNDARIES: ISparklineChartColorizedBoundaries = { warnThreshold: 800, failThreshold: 1800 }
export const WIDGET_CUMULATIVE_LAYOUT_SHIFT_BOUNDARIES: ISparklineChartColorizedBoundaries = { warnThreshold: 0.1, failThreshold: 0.25 }

export const LARGEST_CONTENTFUL_PAINT_CHART_CONFIG = {
  title: 'Largest Contentful Paint',
  data: [],
  sideLabel: 'seconds',
  shortLabel: 'sec',
  tooltipValueLabel: 'Pages',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  boundaries: WIDGET_LARGEST_CONTENTFUL_PAINT_BOUNDARIES,
  tooltip: (value: number) => { return `${value} second med.`; },
};

export const FIRST_CONTENTFUL_PAINT_CHART_CONFIG = {
  title: 'First Contentful Paint',
  data: [],
  sideLabel: 'seconds',
  shortLabel: 'sec',
  tooltipValueLabel: 'Pages',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  boundaries: WIDGET_FIRST_CONTENTFUL_PAINT_BOUNDARIES,
  tooltip: (value: number) => { return `${value} second med.`; },
};

export const TIME_TO_FIRST_BYTE_CHART_CONFIG = {
  title: 'Time To First Byte',
  data: [],
  sideLabel: 'ms',
  shortLabel: 'ms',
  tooltipValueLabel: 'Pages',
  truncate: true,
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  boundaries: WIDGET_TIME_TO_FIRST_BYTE_BOUNDARIES,
  tooltip: (value: number) => { return `${value} second med.`; },
};

export const CUMULATIVE_LAYOUT_SHIFT_CHART_CONFIG = {
  title: 'Cumulative Layout Shift',
  data: [],
  sideLabel: '',
  shortLabel: '',
  tooltipValueLabel: 'Pages',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  boundaries: WIDGET_CUMULATIVE_LAYOUT_SHIFT_BOUNDARIES,
  tooltip: (value: number) => `${value}`,
};

export enum EPageLoadTimePills {
  'Below3' = 'Below 3',
  '3to6' = '3 to 6',
  '6to10' = '6 to 10',
  '10andAbove' = '10 and above'
}

export enum EPageSummaryChartTypes {
  PageLoad = 'pageLoad',
  InitialPageStatusCode = 'initialPageStatusCode',
  FinalPageStatusCode = 'finalPageStatusCode',
}

export const pageLoadTimeFilterOptions = {
  [EPageLoadTimePills['Below3']]: {
    min: 0,
    max: 3000
  },
  [EPageLoadTimePills['3to6']]: {
    min: 3000,
    max: 6000
  },
  [EPageLoadTimePills['6to10']]: {
    min: 6000,
    max: 10000
  },
  [EPageLoadTimePills['10andAbove']]: {
    min: 10000,
    max: null
  }
};

export const chartTypeToFilterTypeMap = new Map([
  [EPageSummaryChartTypes.PageLoad, EAuditReportFilterTypes.PageLoadTime],
  [EPageSummaryChartTypes.InitialPageStatusCode, EAuditReportFilterTypes.InitialPageStatusCode],
  [EPageSummaryChartTypes.FinalPageStatusCode, EAuditReportFilterTypes.FinalPageStatusCode],
]);

export const PageSummaryRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,
  EAuditReportFilterTypes.TagId,
  EAuditReportFilterTypes.TagCategory,
  EAuditReportFilterTypes.TagAccount,
  EAuditReportFilterTypes.TagVendorId,
  EAuditReportFilterTypes.TagPrimaryTagsOnly,
  EAuditReportFilterTypes.Errors,
  EAuditReportFilterTypes.LargestContentfulPaint,
  EAuditReportFilterTypes.FirstContentfulPaint,
  EAuditReportFilterTypes.TimeToFirstByte,
  EAuditReportFilterTypes.CumulativeLayoutShift,
];

export const PagesTableSettingItems: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageStatusCode]: {
    title: 'Initial URL Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.RedirectCount]: {
    title: '# Of Redirects',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final URL Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.Size]: {
    title: 'Page Size',
    checked: true,
  },
  [CommonReportsPagesTableColumns.LargestContentfulPaint]: {
    title: 'Largest Contentful Paint',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FirstContentfulPaint]: {
    title: 'First Contentful Paint',
    checked: true,
  },
  [CommonReportsPagesTableColumns.TimeToFirstByte]: {
    title: 'Time To First Byte',
    checked: true,
  },
  [CommonReportsPagesTableColumns.CumulativeLayoutShift]: {
    title: 'Cumulative Layout Shift',
    checked: true,
  },
};
