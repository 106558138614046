import { NgModule } from '@angular/core';
import { OpSelectCreateComponent } from './op-select-create.component';
import { OpFormModule } from '../../op-form.module';
import { SharedModule } from '../../shared.module';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/components/material/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule, SharedModule, OpFormModule],
  exports: [OpSelectCreateComponent],
  declarations: [OpSelectCreateComponent]
})
export class OpSelectCreateModule { }
