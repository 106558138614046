import { Component, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'live-connect-base',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./live-connect-base.component.scss']
})
export class LiveConnectBaseComponent {

  constructor() { }

}
