import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Type, Injectable } from '@angular/core';
import { OpSuccessModalComponent } from '@app/components/shared/components/op-modal/op-success-modal/op-success-modal.component';
import { ISuccessModalPayload } from '@app/components/shared/components/op-modal/op-success-modal/op-success-modal.models';
import { IConfirmModalPayload } from '../op-confirm-modal/op-confirm-modal.models';
import { OpConfirmModalComponent } from '../op-confirm-modal/op-confirm-modal.component';
import { IDeleteModalPayload } from '../op-delete-modal/op-delete-modal.models';
import { OpDeleteModalComponent } from '../op-delete-modal/op-delete-modal.component';
import { Subject } from 'rxjs';

@Injectable()
export class OpModalService {

  constructor(private dialog: MatDialog) { }

  /**
   * Opens simple modal
   * @param contentComponent - component, that will be used as modal's content
   * @param config - modal's config. Should be used for sending payload
   * @return MatDialogRef, that gives an ability to subscribe on various modal's events (on close etc)
   */
  openModal<T, D>(contentComponent: Type<T>, config: MatDialogConfig<Partial<D>>, panelClass?: string): MatDialogRef<T> {
    return this.dialog.open(
      contentComponent,
      { ...config, panelClass: ['op-modal-ng', `${panelClass ? panelClass : ''}`] }
    );
  }

  /**
   * Opens fixed size modal
   * @param contentComponent - component, that will be used as modal's content
   * @param config - modal's config. Should be used for sending payload
   * @return MatDialogRef, that gives an ability to subscribe on various modal's events (on close etc)
   */
  openFixedSizeModal<T, D>(contentComponent: Type<T>, config: MatDialogConfig<Partial<D>>, panelClass?: string): MatDialogRef<T> {
    return this.openModal(
      contentComponent,
      {
        ...config,
        width: '80%',
        height: 'calc(100vh - 100px)'
      },
      panelClass
    );
  }

  /**
   * Opens confirmation modal
   * Confirmation modal's markup is predefined, can be changed only dynamic parts (title, body messages and buttons)
   * @param config - modal's config. Should be used for sending payload
   */
  openConfirmModal(config: MatDialogConfig<IConfirmModalPayload>): MatDialogRef<OpConfirmModalComponent> {
    return this.dialog.open(
      OpConfirmModalComponent,
      { ...config, panelClass: 'op-modal-ng' }
      );
  }

  /**
   * Opens delete item modal
   * Delete item modal's markup is predefined, can be changed only dynamic parts (title, buttons text and item data)
   * @param config - modal's config. Should be used for sending payload
   */
  openDeleteModal(config: MatDialogConfig<IDeleteModalPayload>): MatDialogRef<OpDeleteModalComponent> {
    return this.dialog.open(
      OpDeleteModalComponent,
      { ...config, panelClass: 'op-delete-modal-ng' }
      );
  }

  /**
   * Opens fullscreen modal
   * @param contentComponent - component, that will be used as modal's content
   * @param config - modal's config. Should be used for sending payload
   * @return MatDialogRef, that gives an ability to subscribe on various modal's events (on close etc)
   */
  openFullscreenModal<T, D>(contentComponent: Type<T>, config: MatDialogConfig<Partial<D>>): MatDialogRef<T> {
    return this.dialog.open(
      contentComponent,
      { ...config, panelClass: 'op-full-screen-modal-ng' }
    );
  }

  /**
   * Opens fullscreen modals with transparent background
   * @param contentComponent - component, that will be used as modal's content
   * @param config - modal's config. Should be used for sending payload
   * @return MatDialogRef, that gives an ability to subscribe on various modal's events (on close etc)
   */
  openFullscreenModalTransparent<T, D>(contentComponent: Type<T>, config: MatDialogConfig<Partial<D>>): MatDialogRef<T> {
    return this.dialog.open(
      contentComponent,
      { ...config, panelClass: 'op-full-screen-modal-transparent-ng'}
    );
  }

  /**
   * Opens success modal
   * @param config - modal's config. Should be used for sending payload
   * @return MatDialogRef, that gives an ability to subscribe on various modal's events (on close etc)
   */
  openSuccessModal(config: MatDialogConfig<ISuccessModalPayload>): MatDialogRef<OpSuccessModalComponent> {
    return this.dialog.open(
      OpSuccessModalComponent,
      { ...config, panelClass: 'op-modal-ng' }
    );
  }

  afterModalOpened(): Subject<MatDialogRef<any>> {
    return this.dialog.afterOpened;
  }
}
