<div class="clearable-input" [ngClass]="{
  'clearable-input-filled': appearance === ECIAppearance.FILLED,
  'clearable-input-outlined': appearance === ECIAppearance.OUTLINED
}">
  <mat-icon class="op-input-search-icon">search</mat-icon>
  <input #clearableInput
    class="op-input"
    [(ngModel)]="inputValue"
    (keyup)="onInputChange($event)"
    (blur)="onInputBlurred($event)"
    [placeholder]="placeholderText"
    autocomplete="off"
    spellcheck="false">
  <button #clearButton class="op-input-clear-btn" (click)="clearInput()" *ngIf="inputValue">
    <mat-icon class="op-input-clear-icon">close</mat-icon>
  </button>
</div>
