<div class="keyboard-shortcuts-modal">
  <div class="ksm-header">
    <div class="ksm-header-left">
      <button mat-icon-button (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="ksm-header-center">
      <h1>Keyboard Shortcuts</h1>
    </div>
    <div class="ksm-header-right ksm-header-toggle">
      <span [class.ksm-toggle-text-visible]="!shortcutsToggledOn" class="ksm-header-toggle-text">
        OFF
      </span>
      <mat-slide-toggle
        [checked]="shortcutsToggledOn"
        (change)="onShortcutToggleChange($event.checked)"
        color="primary">
        <span [class.ksm-toggle-text-visible]="shortcutsToggledOn" class="ksm-header-toggle-text">
          ON
        </span>
      </mat-slide-toggle>
    </div>
  </div>
  <div class="ksm-body">
    <div class="ksm-section" *ngFor="let section of shortcuts">
      <op-section-divider class="ksm-section-heading" [text]="section.sectionName"></op-section-divider>
      <div class="ksm-row" *ngFor="let row of section.rows">
        <div class="ksm-row-left">{{ row.description }}</div>
        <div class="ksm-row-right">{{ row.shortcut }}</div>
      </div>
    </div>
  </div>
</div>