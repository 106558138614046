<mat-divider></mat-divider>

<div class="notes-header">
  <button mat-icon-button (click)="toggleShowHideNotes()" class="notes-toggle">
    NOTES
    <mat-icon *ngIf="showNotes">keyboard_arrow_up</mat-icon>
    <mat-icon *ngIf="!showNotes">keyboard_arrow_down</mat-icon>
  </button>
</div>
<ng-container *ngIf="showNotes">
  <textarea #notesBox
            [(ngModel)]="notes"
            (keyup)="onNotesUpdated()"
            (paste)="onNotesUpdated()"
            class="notes-box"
            maxlength="2000">
  </textarea>
  <div class="notes-footer">
    <div class="saving save-state-message" *ngIf="edited && !saved">
      <mat-spinner [diameter]="15" [strokeWidth]="2"></mat-spinner>
      <span class="save-state-text">Saving...</span>
    </div>
    <div class="saved save-state-message" *ngIf="edited && saved">
      <mat-icon>done</mat-icon>
      <span class="save-state-text">Saved</span>
    </div>
  </div>
</ng-container>