import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'create-edit-label',
  templateUrl: './create-edit-label.component.html',
  styleUrls: ['./create-edit-label.component.scss']
})
export class CreateEditLabelComponent implements OnInit {

  createMode: boolean;
  labelForm: UntypedFormGroup;
  label: ILabel;
  labelName: string;
  editing = true;
  saving = false;
  error = false;

  rightFooterButtons = [
    {
      label: 'Save',
      action: () => {
        this.save();
      },
      primary: true
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<CreateEditLabelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private labelService: LabelService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.createMode = !(data && data.label);
    this.label = !this.createMode ? data.label : null;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.labelForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    if (!this.createMode) {
      this.name.patchValue(this.label.name);
    }
  }

  save(): void {
    this.labelForm.markAllAsTouched();
    if (this.labelForm.invalid) return;

    this.editing = false;
    this.saving = true;

    this.createMode ? this.createLabel() : this.updateLabel();
  }

  createLabel(): void {
    this.labelService.createLabel(this.name.value)
      .subscribe(
        (label: ILabel) => {
          this.closeModal(label);
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
        });
  }

  updateLabel(): void {
    this.labelService.updateLabel(this.label.id, this.name.value)
      .subscribe(
        (label: ILabel) => {
          this.closeModal(label);
        },
        (error: HttpErrorResponse) => {
          this.handleError(error);
        }
      );
  }

  handleError(error: HttpErrorResponse): void {
    console.error(error);
    this.saving = false;
    this.error = true;
    this.rightFooterButtons[0].label = 'OK';
    this.rightFooterButtons[0].action = () => {
      this.dialogRef.close();
    };
  }

  closeModal(label?: ILabel): void {
    this.dialogRef.close(label);
  }

  get name(): AbstractControl {
    return this.labelForm.get('name');
  }
}
