<div class="op-modal flex-col flex-stretch">
  <div class="op-modal-content flex-col flex-stretch">
    <ng-container *ngTemplateOutlet="headerTemplate || defaultHeaderTemplate"></ng-container>

    <div class="flex-col flex-stretch op-modal-body">

      <div class="flex-col flex-stretch">
        <ng-container *ngTemplateOutlet="bodyTemplate" ></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="footerTemplate || defaultFooterTemplate"></ng-container>
    </div>
  </div>
</div>

<ng-template #defaultHeaderTemplate>
  <op-modal-header [title]="title" (onClose)="onClose.emit()"></op-modal-header>
</ng-template>

<ng-template #defaultFooterTemplate>
  <op-modal-footer [leftFooterButtons]="leftFooterButtons"
                   [rightFooterButtons]="rightFooterButtons">
  </op-modal-footer>
</ng-template>
