<span class="header-container" [formGroup]="formGroup" [class.read-only]="readOnly">
  <mat-icon>notifications</mat-icon>

  <alert-name-control [control]="nameControl"
                      [initialMode]="modalType !== EAlertModalType.ReadOnly ? EAlertNameControlMode.Edit : EAlertNameControlMode.View"
                      [modalType]="modalType"
                      (modeChanged)="triggerLabelsRerendering()">
  </alert-name-control>

  <op-label-manager [isReadOnly]="modalType === EAlertModalType.ReadOnly"
                    [selectedLabels]="labelsArray.value"
                    (onLabelCreated)="createLabel($event)"
                    (onLabelSelected)="addLabel($event)"
                    (onLabelRemoved)="removeLabel($event)">
  </op-label-manager>
</span>
