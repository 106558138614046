import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import {
  EVariableInventoryPagesType,
  IVariableInventoryPagesDTO,
  IVariableInventoryQueryParams,
  IVariableInventoryReportFilter, IVariableInventorySpecificTrendValuesDTO,
  IVariableInventorySummaryDTO,
  IVariableInventoryTagAccount, IVariableInventoryTagAccountsPagesDTO,
  IVariableInventoryTagAccountVariablesDTO,
  IVariableInventoryTagsDTO,
  IVariableInventoryTrendsDTO,
  IVariableInventoryUniqueVariableValuesDTO,
  IVariableInventoryVariableNotSetPagesDTO,
  IVariableInventoryVariableValuePagesDTO,
} from '@app/components/audit-reports/reports/variable-inventory/variable-inventory.models';
import { EVariableInventoryTrendNames } from '@app/components/audit-reports/reports/variable-inventory/variable-inventory.constants';
import { takeUntil, tap } from 'rxjs/operators';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';

const EMPTY_TAG = {
  tagId: null,
  tagName: null,
  tagCategoryId: null,
  tagCategoryName: null,
  tagAccount: null,
  totalPageCount: null,
  totalVariableCount: null,
};

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class VariableInventoryService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();
  private selectedTagAccountSubject: BehaviorSubject<IVariableInventoryTagAccount> = new BehaviorSubject(EMPTY_TAG);
  selectedTag$: Observable<IVariableInventoryTagAccount> = this.selectedTagAccountSubject.asObservable();
  selectedPageFilterSubject: BehaviorSubject<EVariableInventoryPagesType> = new BehaviorSubject(null);
  selectedPageFilter$: Observable<EVariableInventoryPagesType> = this.selectedPageFilterSubject.asObservable();
  reportLevelFilters: IVariableInventoryReportFilter;

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  resetServiceState(): void {
    this.clearSelectedTagAndVariables();
    this.clearSelectedPageFilter();
  }

  clearSelectedTagAndVariables(): void {
    this.selectTagAccount(EMPTY_TAG);
  }

  clearSelectedPageFilter(): void {
    this.setPageFilter(null);
  }

  selectTagAccount(selected: IVariableInventoryTagAccount): void {
    this.selectedTagAccountSubject.next(selected);
  }

  getSelectedTagAccount(): IVariableInventoryTagAccount {
    return this.selectedTagAccountSubject.getValue();
  }

  setPageFilter(filter: EVariableInventoryPagesType): void {
    this.selectedPageFilterSubject.next(filter);
  }

  getPageFilter(): EVariableInventoryPagesType {
    return this.selectedPageFilterSubject.getValue();
  }

  /**
   * Filters managed by the report. These are set when selecting a tag account from the Tag & Accounts table
   */
  setReportLevelFilters(tag: IVariableInventoryTagAccount): void {
    this.reportLevelFilters = {
      tagId: tag.tagId,
      tagAccount: tag.tagAccount
    };
  }

  clearReportLevelFilters(): void {
    this.reportLevelFilters = {};
  }

  /**
   *  Format a string to use commas as separators
   */
  addCommas(value: number): string {
    return Number(value).toLocaleString();
  }

  /**
   * Format and round number to a single decimal place
   */
  formatToOneDecimalPlace(num: number): number {
    let wholeNum = Math.trunc(num);
    let radix = +((num - wholeNum) % 1).toFixed(1);
    return radix === 0 ? wholeNum : wholeNum + radix;
  }

  /**
   * API CALLS
   */
  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getVariableInventorySummaryForAuditRun(
    auditId: number,
    runId: number,
    body: IAuditReportApiPostBody
  ): Observable<IVariableInventorySummaryDTO> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/variable-inventory`;
    return this.apiService.post(`${baseRequestUrl}`, body);
  }

  getVariableInventoryTrends(
    auditId: number,
    runId: number,
  ): Observable<IVariableInventoryTrendsDTO> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/variable-inventory/trends`;

    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IVariableInventoryTrendsDTO>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getVariableInventoryTrend(
    auditId: number,
    trendName: EVariableInventoryTrendNames,
    days: number,
  ): Observable<IVariableInventorySpecificTrendValuesDTO> {
    const trendTypes = {
      'uniqueTags': 'unique_tags',
      'tagInstance': 'tag_requests',
      'uniqueVariableName': 'unique_variables',
      'uniqueVariableValue': 'unique_values',
    };
    const requestUrl = `${this.apiRoot}/${auditId}/reports/variable-inventory/trends/${trendTypes[trendName]}?days=${days}`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IVariableInventorySpecificTrendValuesDTO>(`${requestUrl}`)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getVariableInventoryTagAccounts(
    auditId: number,
    runId: number,
    body: IAuditReportApiPostBody
  ): Observable<IVariableInventoryTagsDTO> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/variable-inventory/tags`;
    return this.apiService.post(`${baseRequestUrl}`, body);
  }

  getVariableInventoryTagAccountsPages(
    auditId: number,
    runId: number,
    tagId: number,
    tagAccount: string,
    params: IVariableInventoryQueryParams,
    body: IAuditReportApiPostBody,
  ): Observable<IVariableInventoryTagAccountsPagesDTO> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/variable-inventory/tag-account-pages`;
    const queryParams = `?page=${params.currentPageNumber}&size=${params.pageSize}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`;
    const postBody = {
      ...body,
      tagId,
      tagAccount,
    };

    return this.apiService.post(`${requestUrl}${queryParams}`, postBody);
  }

  getVariableInventoryTagAccountsVariableList(
    auditId: number,
    runId: number,
    tagId: number,
    tagAccount: string,
    body: IAuditReportApiPostBody
  ): Observable<IVariableInventoryTagAccountVariablesDTO> {
    let postBody = {
      ...body,
      tagId,
      tagAccount,
    };

    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/variable-inventory/tag-account-variables`;

    return this.apiService
      .post<IVariableInventoryTagAccountVariablesDTO>(`${requestUrl}`, postBody);
  }

  getVariablePages(
    pagesType: EVariableInventoryPagesType,
    auditId: number,
    runId: number,
    variableName: string,
    params: IVariableInventoryQueryParams,
    globalFilters: IAuditReportApiPostBody,
  ): Observable<IVariableInventoryPagesDTO | IVariableInventoryVariableNotSetPagesDTO | IVariableInventoryUniqueVariableValuesDTO> {
    const { tagId, tagAccount } = this.getSelectedTagAccount();

    switch (pagesType) {
      case EVariableInventoryPagesType.TAG_ACCOUNTS:
        return this.getVariableInventoryTagAccountsPages(
          auditId,
          runId,
          tagId,
          tagAccount,
          params,
          { ...globalFilters, ...this.reportLevelFilters },
        );
      case EVariableInventoryPagesType.SET:
        return this.getVariableInventoryTagAccountVariablePageList(
          auditId,
          runId,
          tagId,
          tagAccount,
          variableName,
          params,
          { ...globalFilters, ...this.reportLevelFilters },
        );
      case EVariableInventoryPagesType.NOT_SET:
        return this.getVariableInventoryTagAccountVariableNotSetPageList(
          auditId,
          runId,
          tagId,
          tagAccount,
          variableName,
          params,
          { ...globalFilters, ...this.reportLevelFilters },
        );
      case EVariableInventoryPagesType.UNIQUE:
        return this.getVariableInventoryTagAccountVariableUniqueValuesList(
          auditId,
          runId,
          tagId,
          tagAccount,
          variableName,
          params,
          { ...globalFilters, ...this.reportLevelFilters }
        );
    }
  }

  getVariableInventoryTagAccountVariablePageList(
    auditId: number,
    runId: number,
    tagId: number,
    tagAccount: string,
    variableName: string,
    params: IVariableInventoryQueryParams,
    body: IAuditReportApiPostBody
  ): Observable<IVariableInventoryPagesDTO> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/variable-inventory/tag-account-variable-pages`;
    const queryParams = `?page=${params.currentPageNumber}&size=${params.pageSize}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`;
    const postBody = {
      ...body,
      tagId,
      tagAccount,
      variableName,
    };

    return this.apiService.post<IVariableInventoryPagesDTO>(`${requestUrl}${queryParams}`, postBody);
  }

  getVariableInventoryTagAccountVariableNotSetPageList(
    auditId: number,
    runId: number,
    tagId: number,
    tagAccount: string,
    variableName: string,
    params: IVariableInventoryQueryParams,
    body: IAuditReportApiPostBody
  ): Observable<IVariableInventoryVariableNotSetPagesDTO> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/variable-inventory/tag-account-variable-not-set-pages`;
    const queryParams = `?page=${params.currentPageNumber}&size=${params.pageSize}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`;
    const postBody = {
      ...body,
      tagId,
      tagAccount,
      variableName,
    };

    return this.apiService.post<IVariableInventoryVariableNotSetPagesDTO>(`${requestUrl}${queryParams}`, postBody);
  }

  getVariableInventoryTagAccountVariableUniqueValuesList(
    auditId: number,
    runId: number,
    tagId: number,
    tagAccount: string,
    variableName: string,
    params: IVariableInventoryQueryParams,
    body: IAuditReportApiPostBody
  ): Observable<IVariableInventoryUniqueVariableValuesDTO> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/variable-inventory/tag-account-variable-values`;
    const queryParams = `?page=${params.currentPageNumber}&size=${params.pageSize}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`;
    const postBody = {
      ...body,
      tagId,
      tagAccount,
      variableName,
    };

    return this.apiService.post<IVariableInventoryUniqueVariableValuesDTO>(`${requestUrl}${queryParams}`, postBody);
  }

  getVariableInventoryTagAccountVariableValuePageList(
    auditId: number,
    runId: number,
    tagId: number,
    tagAccount: string,
    variableName: string,
    variableValue: string,
    params: IVariableInventoryQueryParams,
    body: IAuditReportApiPostBody
  ): Observable<IVariableInventoryVariableValuePagesDTO> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/variable-inventory/tag-account-variable-value-pages`;
    const queryParams = `?page=0&size=200&sortBy=variable_count`;
    const postBody = {
      ...body,
      tagId,
      tagAccount,
      variableName,
      variableValue,
    };

    return this.apiService.post<IVariableInventoryVariableValuePagesDTO>(`${requestUrl}${queryParams}`, postBody);
  }
}
