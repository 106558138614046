import { IWebJourneyRunProgress } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { webJourneyHasStarted, webJourneyIsFinished, webJourneyVerifyingRules } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.enum';
import {
  IStatusBarItem,
  StatusBarItemState,
  StatusBarItemType
} from '@app/components/status-banner/status-progress-bar/status-progress-bar.models';
import {
  IAuditStatus,
  IJourneyStatus,
  StatusUpdateType
} from './statusBannerService';

export class StatusBannerProcessor {

    inQueueLabel: string = 'In Queue';
    rulesValidationLabel: string = 'Standards Validation';
    finishingReportLabel: string = 'Finishing Report';
    pagesVerifiedLabel: string = 'Verification';
    pagesProcessingLabel: string = 'Pages';
    actionsProcessingLabel: string = 'Actions';

    constructor(){}

    public generateProgressBarItems(updateType: StatusUpdateType): Array<IStatusBarItem> {
      var progressBar: Array<IStatusBarItem> = [
        { type: StatusBarItemType.inQueue, label: this.inQueueLabel, state: StatusBarItemState.inProgress},
        { type: StatusBarItemType.pagesProcessing, label: this.getProcessingLabel(updateType), state: StatusBarItemState.notStarted}];
      if (updateType && updateType == StatusUpdateType.Audit){
        progressBar.push({type: StatusBarItemType.pagesVerified, label: this.pagesVerifiedLabel, state: StatusBarItemState.notStarted});
      }
      progressBar.push({ type: StatusBarItemType.rulesValidation, label: this.rulesValidationLabel, state: StatusBarItemState.notStarted});
      progressBar.push({ type: StatusBarItemType.finishing, label: this.finishingReportLabel, state: StatusBarItemState.notStarted});
      return progressBar;
    }

    public processWebJourneyUpdate(progress: IWebJourneyRunProgress, items: Array<IStatusBarItem>): Array<IStatusBarItem> {
      let update: IJourneyStatus =  {
        started: webJourneyHasStarted(progress.status),
        rulesVerified: webJourneyIsFinished(progress.status),
        finished: webJourneyIsFinished(progress.status),
        actionsProcessed: webJourneyVerifyingRules(progress.status) || webJourneyIsFinished(progress.status) ? 1 : 0, // current status endpoint does not update actionsProcessed. Fake it.
        totalActions: 1,
        deletion: 0
      };
      return this.processJourneyUpdate(update, items).map((item) => {
        item.label = item.type === StatusBarItemType.pagesProcessing ? 'Executing Actions' : item.label;
        return item;
      });
    }

    private processJourneyUpdate(update: IJourneyStatus, items: Array<IStatusBarItem>): Array<IStatusBarItem> {
      for (var i = 0; i < items.length; i++){
        var item: IStatusBarItem = items[i];
        var previousItem: IStatusBarItem = i > 0 ? items[i - 1] : null;
        switch (item.type) {
          case StatusBarItemType.inQueue:
            item.label = this.inQueueLabel;
            item.state = update.started
              ? StatusBarItemState.done
              : StatusBarItemState.inProgress;
            break;
          case StatusBarItemType.pagesProcessing:
            item.label = `${this.displayedItems(update.actionsProcessed)}/${this.displayedItems(update.totalActions)} ${this.actionsProcessingLabel}`;
            item.state = this.calcStandardState(update.actionsProcessed != undefined && update.actionsProcessed == update.totalActions, previousItem);
            break;
          case StatusBarItemType.rulesValidation:
            item.label = this.rulesValidationLabel;
            item.state = this.calcStandardState(update.rulesVerified, previousItem);
            break;
          case StatusBarItemType.finishing:
            item.label = this.finishingReportLabel;
            item.state = this.calcStandardState(update.finished, previousItem);
            break;
        }
      }
      return items;
    }

    public processAuditUpdate(update: IAuditStatus, items: Array<IStatusBarItem>): Array<IStatusBarItem> {
      for (var i = 0; i < items.length; i++){
        var item: IStatusBarItem = items[i];
        var previousItem: IStatusBarItem = i > 0 ? items[i - 1] : null;
        switch (item.type) {
          case StatusBarItemType.inQueue:
            item.label = this.inQueueLabel;
            item.state = update.started
            ? StatusBarItemState.done
            : StatusBarItemState.inProgress;
            break;
          case StatusBarItemType.pagesProcessing:
            item.label = `${this.displayedItems(update.processedPagesCount)}/${this.displayedItems(update.pageLimit)} ${this.pagesProcessingLabel}`;
            item.state = this.calcStandardState(this.isPagesProcessed(update), previousItem);
            break;
          case StatusBarItemType.pagesVerified:
            item.label = this.pagesVerifiedLabel;
            item.state = this.calcStandardState(update.pagesVerified, previousItem);
            break;
          case StatusBarItemType.rulesValidation:
            item.label = this.rulesValidationLabel;
            item.state = this.calcStandardState(update.rulesVerified, previousItem);
            break;
          case StatusBarItemType.finishing:
            item.label = this.finishingReportLabel;
            item.state = this.calcStandardState(update.finished, previousItem);
            break;
        }
      }
      return items;
    }

    private isPagesProcessed(update: IAuditStatus): boolean {
      return update.processedPagesCount != undefined && (update.processedPagesCount == update.pageLimit || update.pagesProcessed);
    }

    private calcStandardState(condition: boolean, previousItem: IStatusBarItem): StatusBarItemState {
      return condition
      ? StatusBarItemState.done
      : previousItem && previousItem.state == StatusBarItemState.done
      ? StatusBarItemState.inProgress
      : StatusBarItemState.notStarted;
    }

    private getProcessingLabel(updateType: StatusUpdateType): string {
      return updateType == StatusUpdateType.Audit ?
        this.pagesProcessingLabel :
        this.actionsProcessingLabel;
    }

    private displayedItems(items: number): number {
      return items || 0;
    }
}
