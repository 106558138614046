<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="close()">
  <ng-template #headerTemplate>
    <div class="flex-row flex-spread-apart rule-creator-header flex-center-items">
      <div class="flex-row flex-stretch flex-center-content flex-center-items">
        <op-tag-img class="tag" [tagId]="tag?.tagId"></op-tag-img>
        <div class="tag-name">
          <span>{{tag?.tagName}}</span>
          <span *ngIf="payload.account">Account: {{payload.account}}</span>
        </div>
      </div>
      <op-close-btn (onClose)="close()"></op-close-btn>
    </div>
  </ng-template>
  <ng-template #bodyTemplate>
    <form [formGroup]="ruleForm" class="flex-col" formName="ruleForm" validate-form [submitted]="submitted">
      <div class="form-controls">
        <div class="form-input">
          <mat-form-field>
            <mat-label>Rule Name</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
          <op-error-message formControlPath="ruleForm.name"></op-error-message>
        </div>

        <div class="form-input">
          <mat-form-field>
            <mat-label>Variables search</mat-label>
            <input matInput (keyup)="filterVariables($event)"/>
          </mat-form-field>
        </div>
      </div>

      <div class="table-container flex-col flex-stretch mat-elevation-z2">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row;">
              <mat-checkbox (click)="$event.stopPropagation(); handleCheckboxClick(row)"
                            [checked]="selection.isSelected(row)" color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Variable </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef> Value </th>
            <td mat-cell *matCellDef="let element"> {{element.value}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
        </table>
      </div>

      <div class="no-variables" *ngIf="!dataSource.data.length">No variables</div>

      <button class="btn btn-default customize-btn" (click)="customizeSelectedVariables()">Customize</button>

    </form>
  </ng-template>
</op-modal>
