import { Component, Inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import { ISnackBarDataObject } from '../consent-categories.models'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'import-categorized-cookies-snackbar',
  templateUrl: './import-categorized-cookies-snackbar.component.html',
  styleUrls: ['./import-categorized-cookies-snackbar.component.scss']
})

export class ImportCategorizedCookiesSnackbarComponent {
  display: ISnackBarDataObject;

  constructor(@Inject(MAT_SNACK_BAR_DATA) private snackbarData: { data: ISnackBarDataObject }) {
    this.display = snackbarData.data
  }
}