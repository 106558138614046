import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IActionSelectorAction } from '../action-selector/action-selector.models';
import { IJourneyImagePreviewPayload } from './journey-image-preview.models';
import { EKeyCodes } from '../shared/services/keyboard-shortcuts/keyboard-shortcuts.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'journey-image-preview',
  templateUrl: './journey-image-preview.component.html',
  styleUrls: ['./journey-image-preview.component.scss']
})
export class JourneyImagePreviewComponent {

  currentStep: number;
  numActions: number;
  currentAction: IActionSelectorAction;
  label: string;
  private changeStep: (stepNumber: number) => void;

  constructor(
    public dialogRef: MatDialogRef<JourneyImagePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IJourneyImagePreviewPayload
  ) {
    this.numActions = data.numActions;
    this.currentStep = data.currentStep;
    this.currentAction = data.currentAction;
    this.label = data.label;
    this.changeStep = data.changeStep;
  }

  @HostListener('window:keydown', ['$event'])
  keyDown(event: KeyboardEvent): void {
    switch (event.key.toLowerCase()) {
      case EKeyCodes.Escape:
        this.onModalClose();
        break;
      case EKeyCodes.ArrowUp:
      case EKeyCodes.ArrowLeft:
        event.preventDefault();
        this.onLeftArrowClick();
        break;
      case EKeyCodes.ArrowDown:
      case EKeyCodes.ArrowRight:
        event.preventDefault();
        this.onRightArrowClick();
        break;
    }
  }

  onLeftArrowClick(): void {
    const stepNumber = (this.currentStep > 1) ? this.currentStep - 1 : this.currentStep;
    this.changeStep(stepNumber);
  }

  onRightArrowClick(): void {
    const stepNumber = (this.currentStep < this.numActions) ? this.currentStep + 1 : this.currentStep;
    this.changeStep(stepNumber);
  }

  onModalClose(): void {
    this.dialogRef.close();
  }

  setDefaultImage() {
    this.currentAction.screenshot = '/images/no-img.png';
  }
}
