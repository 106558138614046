import { Component, Input, Output, EventEmitter } from '@angular/core';

export enum EOpButtonMenuTypes {
  Button,
  Hr,
  Header
}
export type MenuItems = {
  type?: EOpButtonMenuTypes;
  icon?: string,
  itemClass?: string,
  action?: () => void,
  label?: string,
  labelIcon?: string,
  labelAfterIcon?: string,
  hidden?: boolean,
  postLabel?: string
};

@Component({
  selector: 'op-button-2021',
  templateUrl: './op-button-2021.component.html',
  styleUrls: ['./op-button-2021.component.scss']
})
export class OpButton2021Component {
  @Input() matIcon: string;
  @Input() customMatIcon: string;
  @Input() customMatIconClass: string;
  @Input() buttonType: 'primary' | 'accent' | 'transparent' | 'filled';
  @Input() menuItems?: MenuItems[];
  @Input() labelText: string;
  @Input() disabled?: boolean;
  @Input() hiddenIcon = false;
  @Input() showDefaultMenuIcon = true;

  @Output() buttonAction: EventEmitter<Event> = new EventEmitter();

  EOpButtonMenuTypes = EOpButtonMenuTypes;

  handleClick(event: MouseEvent) {
    if (!this.disabled) this.buttonAction.emit(event);
  }
}
