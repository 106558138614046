import { EColor } from './ror-comparison-report.contants';

export const chartColor = (value: number): EColor => {
  if (value > 0.89) {
    return EColor.Good;
  } else if (value >= 0.49) {
    return EColor.Normal;
  } else {
    return EColor.Bad;
  }
}