import { Component, Input } from '@angular/core';

const DEFAULT_SELECTED_LIMIT = 1000;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'selectable-table-selection-info',
  templateUrl: './selectable-table-selection-info.component.html',
  styleUrls: ['./selectable-table-selection-info.component.scss']
})
export class SelectableTableSelectionInfoComponent<D> {
  @Input() selectedItems: D[];
  @Input() selectedItemText: string;
  @Input() selectedLimit = DEFAULT_SELECTED_LIMIT;
  @Input() displaySelection = false;
  @Input() showSelectedLimit = true;
}
