import { AfterViewInit, Component, EventEmitter, HostListener, Inject, Input, OnDestroy, Output } from '@angular/core';
import {
  IUsageContractTerm,
  IUsageTrendsCurrentPeriod,
  IUsageTrendsDTO,
  IUsageTrendsFuturePeriod,
  IUsageTrendsPastPeriod
} from '@app/components/usage-v2/usage-v2.models';
import * as d3 from 'd3';
import { Selection } from 'd3';
import {
  attachSvgToContainer,
  hide,
  scaleBandAxisGenerator,
  scaleLinearAxisGenerator
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import {
  ChartClassName,
  ChartType,
  DefaultDisplayedCharts,
  EChartType,
  IChartConfig,
  IChartsConfig,
  IChartToggleBtn,
  IDisplayedCharts
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import { debounceTime, delay, switchMap, take, takeUntil } from 'rxjs/operators';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { SidebarService } from '@app/components/navigation/sidebar/sidebar.service';
import { BaseType } from 'd3-selection';

import {
  createTooltip,
  drawHoverUnderlays,
  drawTooltip,
  getDate
} from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';
import {
  addCurrentPeriodChartToConfig,
  addCurrentPeriodFilteredChartToConfig,
  addFuturePeriodsCumulativeProjectedTotalHistoricalUsageEstimateChartToConfig,
  addFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateChartToConfig,
  addFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateFilteredChartToConfig,
  addFuturePeriodsPeriodProjectedTotalChartToConfig,
  addFuturePeriodsPeriodProjectedTotalFilteredChartToConfig,
  addMonthlyPacingChartToConfig,
  addPastPeriodsCumulativeTotalChartToConfig,
  addPastPeriodsCumulativeTotalFilteredChartToConfig,
  addPastPeriodsPeriodTotalChartToConfig,
  addPastPeriodsPeriodTotalFilteredChartToConfig,
  addTermLimitChartToConfig,
  drawCurrentPeriodPeriodProjectedTotalChart,
  drawCurrentPeriodPeriodProjectedTotalFilteredChart,
  drawDecorativeLineForFuturePeriod,
  drawDecorativeLineForFuturePeriodFiltered,
  drawFuturePeriodsCumulativeProjectedTotalHistoricalUsageEstimateChart,
  drawFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateChart,
  drawFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateFilteredChart,
  drawFuturePeriodsPeriodProjectedTotalChart,
  drawFuturePeriodsPeriodProjectedTotalFilteredChart,
  drawMonthlyPacingChart,
  drawPastPeriodsCumulativeTotalChart,
  drawPastPeriodsCumulativeTotalFilteredChart,
  drawPastPeriodsPeriodTotalChart,
  drawPastPeriodsPeriodTotalFilteredChart,
  drawTermLimitChart
} from '@app/components/usage-v2/components/usage-chart/datasets';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import { DOCUMENT } from '@angular/common';
import { EStraightDatePipeFormats, StraightDatePipe } from '@app/components/usage-v2/pipes/straight-date.pipe';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-chart',
  templateUrl: './usage-chart.component.html',
  styleUrls: ['./usage-chart.component.scss']
})
export class UsageChartComponent implements AfterViewInit, OnDestroy {
  @Input() svgBoxSelectorName = 'usage-trends-multi-chart';
  readonly tooltipClass = 'usage-chart-tooltip';
  readonly circleClass = 'usage-chart-circle';
  readonly circleRadius = 6;
  readonly margin = { top: 30, right: 8, bottom: 45, left: 30 };
  // overlayLineStroke is used to make the transparent line wider for better hover experience
  readonly overlayLineStroke: number = 14;
  readonly barChartPadding = 8;
  readonly sidebarClassName = 'app-sidebar';
  readonly sidebarPrintModeClassName = 'hidden';
  readonly pageClassName = 'usage-page';
  readonly pagePrintModeClassName = 'print-mode';

  private x: d3.ScaleBand<string>;
  private y: d3.ScaleLinear<number, number>;

  private height: number;
  private width: number;
  private windowWidth: number;

  private tooltip: Selection<HTMLDivElement, any, any, any>;
  private circle: Selection<SVGCircleElement, any, any, any>;
  private container: Selection<BaseType, unknown, HTMLElement, any>;
  private svg: Selection<SVGElement, any, any, any>;
  // Main group of the svg where all the charts are drawn
  private svgG: Selection<SVGElement, any, any, any>;

  private config: IChartsConfig;

  private resize$ = new Subject();
  private destroy$ = new Subject();
  private config$ = new ReplaySubject(1);

  private _trends: IUsageTrendsDTO;

  private dateRangeStartLabel = '';
  private dateRangeEndLabel = '';
  private chartAnimationsEnabled = true;

  dateRange: string = '';

  @Input() set currentTerm(term: IUsageContractTerm) {
    if (!term || !term.startDateInclusive || !term.endDateInclusive) {
      return;
    }
    const start = this.straightDatePipe.transform(term.startDateInclusive, EStraightDatePipeFormats.dateSeven2);
    const end = this.straightDatePipe.transform(term.endDateInclusive, EStraightDatePipeFormats.dateSeven2);
    this.dateRangeStartLabel = this.straightDatePipe.transform(term.startDateInclusive, EStraightDatePipeFormats.dateTwentyEight);
    this.dateRangeEndLabel = this.straightDatePipe.transform(term.endDateInclusive, EStraightDatePipeFormats.dateTwentyEight);
    this.dateRange = start + ' - ' + end;
  }

  @Input() title = 'Pages Scanned Per Month';
  @Input() isFiltered = false;
  @Input() displayedCharts: IDisplayedCharts = DefaultDisplayedCharts;
  @Input() chartType: EChartType = EChartType.AUDIT;

  @Output() periodExport: EventEmitter<IUsageTrendsPastPeriod | IUsageTrendsCurrentPeriod | IUsageTrendsFuturePeriod> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.resize$.next();
  }

  @HostListener('window:beforeprint', ['$event']) beforePrint(): void {
    this.prepareForPrint();
  }

  @HostListener('window:afterprint', ['$event']) afterPrint(): void {
    this.restoreScreenSettings();
  }

  @Input() set trends(trends: IUsageTrendsDTO) {
    if (!trends) {
      return;
    }
    this._trends = trends;
    this.createConfig(trends);
  }

  get sidebarElement(): Element {
    return this.document.getElementsByClassName(this.sidebarClassName)[0];
  }

  get pageElement(): Element {
    return this.document.getElementsByClassName(this.pageClassName)[0];
  }

  get trends(): IUsageTrendsDTO {
    return this._trends;
  }

  get charts(): IChartConfig[] {
    return this.config?.charts && Object.values(this.config.charts);
  }

  get toggleButtons(): IChartToggleBtn[] {
    return this.config?.charts && Object.values(this.charts?.map(c => c.toggleBtn)
      .reduce((acc: { [key: string]: IChartToggleBtn }, b: IChartToggleBtn) => {
        if (!acc[b.chartClassName]) {
          acc[b.chartClassName] = b;
        }
        return acc;
      }, {}));
  }

  constructor(
    private sidebarService: SidebarService,
    private straightDatePipe: StraightDatePipe,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngAfterViewInit(): void {
    this.initCharts();
  }

  private prepareForPrint(): void {
    // Hide sidebar before printing for proper chart size
    this.sidebarElement.classList.add(this.sidebarPrintModeClassName);
    // Adding a class for the whole page container to gain fixed width
    // this helps to overcome that charts are trying to take all the available space
    // and hence do not fit into a print page
    this.pageElement.classList.add(this.pagePrintModeClassName);
    this.toggleChartAnimations(false);
    this.createCharts();
  }

  private restoreScreenSettings(): void {
    // Restore sidebar after printing
    this.sidebarElement.classList.remove(this.sidebarPrintModeClassName);
    this.pageElement.classList.remove(this.pagePrintModeClassName);
    this.toggleChartAnimations(true);
    setTimeout(() => this.createCharts(), 1000);
  }

  private toggleChartAnimations(enable: boolean): void {
    this.chartAnimationsEnabled = enable;
  }

  private initCharts(): void {
    // We need to wait until the sidebar has finished resizing to draw the chart with correct size
    this.config$
      .pipe(
        delay(300),
        switchMap(() => this.sidebarService.isClosed),
        take(1),
        switchMap(() => this.chartRedrawSubscription()),
      )
      .subscribe(() => this.createCharts());
  }

  private chartRedrawSubscription(): Observable<any> {
    return merge(this.resize$, this.sidebarService.isClosed, this.config$)
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$),
      );
  }

  private getPeriodItemDataPoint(period: IUsageTrendsPastPeriod | IUsageTrendsFuturePeriod): Date | string {
    return period.startDateInclusive;
  }

  private createTimeline(trends: IUsageTrendsDTO): string[] {
    return [
      ...new Set([
        ...(trends.pastPeriods ? trends.pastPeriods.map(period => this.getPeriodItemDataPoint(period)) : []),
        ...(trends.currentPeriod ? [this.getPeriodItemDataPoint(trends.currentPeriod)] : []),
        ...(trends.futurePeriods ? trends.futurePeriods.map(period => this.getPeriodItemDataPoint(period)) : []),
      ])
    ]
      .sort((a, b) => a > b ? 1 : -1)
      .map((d) => getDate(d as string));
  }

  private createValueLine(trends: IUsageTrendsDTO, displayedCharts): number[] {
    return [
      ...new Set([
        ...(trends.pastPeriods ? [
          ...(displayedCharts.blueBars && !this.isFiltered ? trends.pastPeriods.map((period) => period.periodTotal.total) : []),
          ...(displayedCharts.blueBars ? trends.pastPeriods.map((period) => period.periodTotal.filtered) : []),
          ...(displayedCharts.blueLine && !this.isFiltered ? trends.pastPeriods.map((period) => period.cumulativeTotal.total) : []),
          ...(displayedCharts.blueLine ? trends.pastPeriods.map((period) => period.cumulativeTotal.filtered) : []),
        ] : []),
        ...(trends.currentPeriod ?
          [
            ...(displayedCharts.blueBars && !this.isFiltered ? [trends.currentPeriod.periodTotal.total] : []),
            ...(displayedCharts.blueBars ? [trends.currentPeriod.periodTotal.filtered] : []),
            ...(displayedCharts.blueLine && !this.isFiltered ? [trends.currentPeriod.cumulativeTotal.total] : []),
            ...(displayedCharts.blueLine ? [trends.currentPeriod.cumulativeTotal.filtered] : []),
            ...(displayedCharts.greyBars && !this.isFiltered ? [trends.currentPeriod.periodProjectedTotal.scheduledUsageEstimate.total] : []),
            // region Summing up the actual usage and projected usage
            ...(displayedCharts.greyBars ? [trends.currentPeriod.periodProjectedTotal.scheduledUsageEstimate.filtered + trends.currentPeriod.periodTotal.filtered] : []),
            // endregion Summing up the actual usage and projected usage
            ...(displayedCharts.greyBars && !this.isFiltered ? [trends.currentPeriod.periodProjectedTotal.historicalUsageEstimate.total] : []),
            ...(displayedCharts.greyBars ? [trends.currentPeriod.periodProjectedTotal.historicalUsageEstimate.filtered] : []),
            ...(displayedCharts.greyLines && !this.isFiltered ? [trends.currentPeriod.cumulativeProjectedTotal.scheduledUsageEstimate.total] : []),
            ...(displayedCharts.greyLines ? [trends.currentPeriod.cumulativeProjectedTotal.scheduledUsageEstimate.filtered] : []),
            ...(displayedCharts.greyLines && !this.isFiltered ? [trends.currentPeriod.cumulativeProjectedTotal.historicalUsageEstimate.total] : []),
            ...(displayedCharts.greyLines ? [trends.currentPeriod.cumulativeProjectedTotal.historicalUsageEstimate.filtered] : []),
          ] : []),
        ...(trends.futurePeriods ? [
          ...(displayedCharts.greyBars && !this.isFiltered ? trends.futurePeriods.map((period) => period.periodProjectedTotal.scheduledUsageEstimate.total) : []),
          ...(displayedCharts.greyBars ? trends.futurePeriods.map((period) => period.periodProjectedTotal.scheduledUsageEstimate.filtered) : []),
          ...(displayedCharts.greyBars && !this.isFiltered ? trends.futurePeriods.map((period) => period.periodProjectedTotal.historicalUsageEstimate.total) : []),
          ...(displayedCharts.greyBars ? trends.futurePeriods.map((period) => period.periodProjectedTotal.historicalUsageEstimate.filtered) : []),
          ...(displayedCharts.greyLines && !this.isFiltered ? trends.futurePeriods.map((period) => period.cumulativeProjectedTotal.scheduledUsageEstimate.total) : []),
          ...(displayedCharts.greyLines ? trends.futurePeriods.map((period) => period.cumulativeProjectedTotal.scheduledUsageEstimate.filtered) : []),
          ...(displayedCharts.greyLines && !this.isFiltered ? trends.futurePeriods.map((period) => period.cumulativeProjectedTotal.historicalUsageEstimate.total) : []),
          ...(displayedCharts.greyLines ? trends.futurePeriods.map((period) => period.cumulativeProjectedTotal.historicalUsageEstimate.filtered) : []),
        ] : []),
        ...(displayedCharts.termLimit ? [trends.termLimit] : []),
        ...(displayedCharts.monthlyPacing ? [trends.monthlyPacing] : []),
      ])
    ].sort((a, b) => a - b);
  }

  static getChartToggleBtnClassName(chartClassName: string): string {
    return `dataset-toggle-btn ${chartClassName}-btn`;
  }

  private getPeriods(trends: IUsageTrendsDTO): Array<IUsageTrendsPastPeriod | IUsageTrendsCurrentPeriod | IUsageTrendsFuturePeriod> {
    return [
      ...new Set([
        ...trends.pastPeriods,
        trends.currentPeriod,
        ...trends.futurePeriods,
      ])
    ];
  }

  private createConfig(trends: IUsageTrendsDTO): void {
    if (!trends) {
      this.updateConfig(null);
      return;
    }

    this.config = {
      timeline: this.createTimeline(trends),
      valueLine: this.createValueLine(trends, this.displayedCharts),
      periods: this.getPeriods(trends),
      charts: {}
    };

    if (this.displayedCharts.blueLine) {
      // Blue area
      addPastPeriodsCumulativeTotalChartToConfig(trends, this.config, this.chartType);
    }

    if (this.displayedCharts.blueBars) {
      // Blue bars
      addPastPeriodsPeriodTotalChartToConfig(trends, this.config, this.chartType);
    }

    if (this.displayedCharts.greyLines) {
      // Gray dashed line 1
      addFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateChartToConfig(trends, this.config, this.chartType);
      // Gray dashed line 2
      addFuturePeriodsCumulativeProjectedTotalHistoricalUsageEstimateChartToConfig(trends, this.config, this.chartType);
    }

    if (this.displayedCharts.greyBars) {
      // Gray dashed bars
      addFuturePeriodsPeriodProjectedTotalChartToConfig(trends, this.config, this.chartType);
    }

    // Current period
    addCurrentPeriodChartToConfig(trends, this.config, this.chartType, this.displayedCharts);
    addCurrentPeriodFilteredChartToConfig(trends, this.config, this.chartType, this.displayedCharts);

    if (this.displayedCharts.blueLine) {
      // Yellow line
      addPastPeriodsCumulativeTotalFilteredChartToConfig(trends, this.config, this.chartType);
    }

    if (this.displayedCharts.blueBars) {
      // Yellow bars
      addPastPeriodsPeriodTotalFilteredChartToConfig(trends, this.config, this.chartType);
    }

    if (this.displayedCharts.greyLines) {
      // Yellow dashed line
      addFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateFilteredChartToConfig(trends, this.config, this.chartType);
    }

    if (this.displayedCharts.greyBars) {
      // Yellow dashed bars
      addFuturePeriodsPeriodProjectedTotalFilteredChartToConfig(trends, this.config, this.chartType);
    }

    if (this.displayedCharts.monthlyPacing) {
      // Purple line
      addMonthlyPacingChartToConfig(trends, this.config, this.chartType);
    }

    if (this.displayedCharts.termLimit) {
      // Green line
      addTermLimitChartToConfig(trends, this.config, this.chartType);
    }

    this.updateConfig(this.config);
  }

  updateConfig(config: IChartsConfig): void {
    this.config = config;
    this.config$.next(config);
  }

  private createCharts(): void {
    if (this.config) {
      this.createSvgBox();
      this.createAxis();
      this.drawCharts();
    }
  }

  private createSvgBox(): void {
    this.windowWidth = window.innerWidth;
    // Clear old svg before redrawing if necessary
    if (this.svgG) {
      this.svgG.remove();
    }

    // Reset container contents for the redrawing case and then get the container again
    this.container = d3.select(`.${this.svgBoxSelectorName}`).html('');
    this.container = d3.select(`.${this.svgBoxSelectorName}`);

    // If selection is empty, any methods called on it will result in error
    if (this.container.empty()) {
      return;
    }

    this.height = parseInt(this.container.style('height'));
    this.width = parseInt(this.container.style('width'));
    this.svg = attachSvgToContainer(this.svgBoxSelectorName).attr('style', `width: ${this.width}px`);
    this.svgG = this.svg.append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`);
  }

  private createAxis(): void {
    this.addXAxis();
    this.addYAxis();
  }

  private addXAxis(): void {
    const xAxisLowerPadding = 15;
    // region Standard X axis
    // this.x = d3
    //   .scaleTime()
    //   .range([0, this.width + this.margin.left + this.margin.right])
    //   .domain(d3.extent(this.config.timeline, (d: any) => d));
    // endregion Standard X axis
    this.x = scaleBandAxisGenerator([0, this.width - this.margin.left - this.margin.right], this.config.timeline.map((d: any) => d));

    this.svgG.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${this.height - this.margin.top - this.margin.bottom + xAxisLowerPadding})`)
      .call(
        d3.axisBottom(this.x)
          .tickSize(0)
          .tickFormat((d, i) => this.formatXAxisTickText(d, i, this.config))
      )
      // region Removing the axis line
      .call(g => g.select('.domain').remove())
      // endregion Removing the axis line
      .selectAll('.tick text')
      .attr('class', 'x-axis-tick-text');
  }

  private formatXAxisTickText(d: any, i: number, config: IChartsConfig): string {
    if (i === 0) {
      return this.dateRangeStartLabel;
    }

    if (i === config.timeline.length - 1) {
      return this.dateRangeEndLabel;
    }

    return d.slice(0, 3);
  }

  private addYAxis(): void {
    this.y = scaleLinearAxisGenerator([this.height - this.margin.top - this.margin.bottom, 0], [0, d3.max(this.config.valueLine, d => d)]);
    this.svg.append('g')
      .attr('class', 'y-axis')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .call(
        d3.axisRight(this.y)
          .ticks(5)
          .tickSize(this.width - this.margin.left - this.margin.right)
          .tickFormat(d => VizUtilsService.formatChartNumbers(d as number))
      )
      // region Removing the axis line
      .call(g => g.select('.domain').remove())
      // endregion Removing the axis line
      .selectAll('.tick text')
      .attr('class', 'y-axis-tick-text')
      // A bit of a magic number, but it's only to position the tick labels
      .attr('transform', `translate(-${this.width * .99}, 0)`);
  }

  exportPeriod(index: number): void {
    this.periodExport.emit(this.config.periods[index]);
  }

  highlightPeriods(shouldHighlight: boolean, line: ChartClassName) {
    const charts = this.svg.selectAll(`.${line}`);
    if (!charts?.size()) {
      return;
    }
    charts.classed('highlighted', shouldHighlight);
  }

  private drawGreyLineCharts(startFromCenter = true): void {
    drawFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateChart(
      this.config,
      this.svgG,
      this.x,
      this.y,
      this.overlayLineStroke,
      this.windowWidth,
      this.circleClass,
      this.circleRadius,
      this.tooltipClass,
      startFromCenter,
      this.chartAnimationsEnabled,
      shouldHighlight => this.highlightPeriods(shouldHighlight, ChartClassName.scheduledFuture)
    );
    drawFuturePeriodsCumulativeProjectedTotalHistoricalUsageEstimateChart(
      this.config,
      this.svgG,
      this.x,
      this.y,
      this.overlayLineStroke,
      this.windowWidth,
      this.circleClass,
      this.circleRadius,
      this.tooltipClass,
      startFromCenter,
      this.chartAnimationsEnabled,
      shouldHighlight => this.highlightPeriods(shouldHighlight, ChartClassName.historicalFuture)
    );
    // Draw the decorative vertical connecting line
    drawDecorativeLineForFuturePeriod(
      this.config,
      this.svgG,
      this.x,
      this.y,
      this.chartAnimationsEnabled,
    );
  }

  private drawCharts(): void {
    drawHoverUnderlays(
      this.svgG,
      this.container,
      this.x,
      this.height,
      this.margin,
      this.windowWidth,
      this.circleClass,
      this.circleRadius,
      this.tooltipClass,
      this.exportPeriod.bind(this),
      this.highlightPeriods.bind(this)
    );

    const pastPeriodExists = !!this.trends.pastPeriods.length;
    const futurePeriodExists = !!this.trends.futurePeriods.length;
    const shouldDrawFilteredCharts =
      this.config.charts[ChartType.pastPeriodsCumulativeTotalFiltered] ||
      this.config.charts[ChartType.pastPeriodsPeriodTotalFiltered] ||
      this.config.charts[ChartType.futurePeriodsCumulativeProjectedTotalScheduledUsageEstimateFiltered] ||
      this.config.charts[ChartType.futurePeriodsPeriodProjectedTotalFiltered] ||
      this.config.charts[ChartType.currentPeriodPeriodTotalFiltered] ||
      this.config.charts[ChartType.currentPeriodPeriodProjectedTotalFiltered];

    if (!shouldDrawFilteredCharts) {
      this.drawRegularCharts(pastPeriodExists, futurePeriodExists);
      this.drawConstantCharts();
      return;
    }

    // Filtered charts
    this.drawFilteredCharts(pastPeriodExists, futurePeriodExists);
    this.drawConstantCharts();
  }

  private drawConstantCharts(): void {
    if (this.displayedCharts.monthlyPacing) {
      // Display monthly pacing and term limit charts on top layer
      drawMonthlyPacingChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.height,
        this.margin,
        this.barChartPadding,
        this.overlayLineStroke,
        this.windowWidth,
        this.circleClass,
        this.circleRadius,
        this.tooltipClass,
        this.chartType,
      );
    }

    if (this.displayedCharts.termLimit) {
      // Term limit
      drawTermLimitChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.barChartPadding,
        this.overlayLineStroke,
        this.windowWidth,
        this.circleClass,
        this.circleRadius,
        this.tooltipClass,
        this.chartType,
      );
    }
  }

  private drawRegularCharts(pastPeriodExists: boolean, futurePeriodExists: boolean): void {
    if (this.displayedCharts.blueLine) {
      // Blue area chart
      drawPastPeriodsCumulativeTotalChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.height,
        this.margin,
        this.barChartPadding,
        this.overlayLineStroke,
        this.windowWidth,
        this.circleClass,
        this.circleRadius,
        this.tooltipClass,
        futurePeriodExists,
        this.chartAnimationsEnabled,
        this.chartType,
        shouldHighlight => this.highlightPeriods(shouldHighlight, ChartClassName.pastPeriods)
      );
    }

    if (this.displayedCharts.blueBars) {
      // Blue bar chart
      drawPastPeriodsPeriodTotalChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.height,
        this.margin,
        this.barChartPadding,
        this.windowWidth,
        this.circleClass,
        this.tooltipClass,
        this.chartAnimationsEnabled,
      );
    }

    if (this.displayedCharts.greyLines) {
      // Grey line charts
      this.drawGreyLineCharts(pastPeriodExists);
    }

    if (this.displayedCharts.greyBars) {
      // Grey bar chart
      drawFuturePeriodsPeriodProjectedTotalChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.height,
        this.margin,
        this.windowWidth,
        this.circleClass,
        this.tooltipClass,
        this.chartAnimationsEnabled,
      );

      // Current period
      drawCurrentPeriodPeriodProjectedTotalChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.height,
        this.margin,
        this.windowWidth,
        this.circleClass,
        this.tooltipClass,
        this.chartAnimationsEnabled,
      );
    }
  }

  private drawFilteredCharts(pastPeriodExists, futurePeriodExists): void {
    if (this.displayedCharts.blueLine) {
      drawPastPeriodsCumulativeTotalFilteredChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.height,
        this.margin,
        this.barChartPadding,
        this.overlayLineStroke,
        this.windowWidth,
        this.circleClass,
        this.circleRadius,
        this.tooltipClass,
        this.chartType,
        this.chartAnimationsEnabled,
        futurePeriodExists,
      );
    }

    if (this.displayedCharts.blueBars) {
      drawPastPeriodsPeriodTotalFilteredChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.height,
        this.margin,
        this.barChartPadding,
        this.windowWidth,
        this.circleClass,
        this.tooltipClass,
        this.chartAnimationsEnabled,
      );
    }

    if (this.displayedCharts.greyBars) {
      drawCurrentPeriodPeriodProjectedTotalFilteredChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.height,
        this.margin,
        this.windowWidth,
        this.circleClass,
        this.tooltipClass,
        this.chartAnimationsEnabled,
      );
    }

    if (this.displayedCharts.greyBars) {
      drawFuturePeriodsPeriodProjectedTotalFilteredChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.height,
        this.margin,
        this.windowWidth,
        this.circleClass,
        this.tooltipClass,
        this.chartAnimationsEnabled,
      );
    }

    if (this.displayedCharts.greyLines) {
      drawFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateFilteredChart(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.overlayLineStroke,
        this.windowWidth,
        this.circleClass,
        this.circleRadius,
        this.tooltipClass,
        this.chartAnimationsEnabled,
        pastPeriodExists,
      );
      // Draw the decorative vertical connecting line
      drawDecorativeLineForFuturePeriodFiltered(
        this.config,
        this.svgG,
        this.x,
        this.y,
        this.chartAnimationsEnabled,
      );
    }
  }

  drawButtonTooltip(event: MouseEvent, btn: IChartToggleBtn): void {
    const { x, y, width } = (event.target as HTMLElement).getBoundingClientRect();
    const tooltip = createTooltip(this.tooltipClass);
    // Make tooltip width constant for edge cases
    if (btn.tooltipClass) {
      tooltip.attr('class', `${this.tooltipClass} ${btn.tooltipClass}`);
    }

    this.tooltip = drawTooltip(
      this.svgG,
      btn.tooltipText,
      x + width / 2,
      y,
      null,
      tooltip,
      this.windowWidth,
      false,
      true,
    );
  }

  hideButtonTooltip(): void {
    hide(this.tooltip);
    // Reset tooltip class to default, because tooltip is a global reusable element
    d3.select(`.${this.tooltipClass}`).attr('class', this.tooltipClass);
  }

  toggleChart(chartClassName: ChartClassName): void {
    const charts = this.svgG?.selectAll(`.${chartClassName}`);
    if (!charts?.size()) {
      return;
    }
    const isHighlighted = charts.classed('highlighted');
    charts.classed('highlighted', !isHighlighted);
    this.charts.filter(c => c.chartClassName === chartClassName).forEach(c => c.highlighted = !isHighlighted);
  }

  ngOnDestroy() {
    this.circle?.remove();
    this.tooltip?.remove();
    this.destroy$.next();
  }
}
