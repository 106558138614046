import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IOpTableCellTooltipData {
  bottom: number;
  left: number;
  maxWidth: number;
  text: string;
  prefix?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OpTableCellTooltipService {

  private tooltipState$: Subject<IOpTableCellTooltipData | null> = new Subject();

  get tooltipStateChanges$(): Observable<IOpTableCellTooltipData | null> {
    return this.tooltipState$.asObservable();
  }

  showTooltip(details: IOpTableCellTooltipData): void {
    this.tooltipState$.next(details);
  }

  hideTooltip(): void {
    this.tooltipState$.next(null);
  }
}
