import { IAuditModel } from '../../components/modals/modalData';
import { filterOrGiven, firstOrGiven, toJSOrGiven } from './selectorHelpers';
import * as Immutable from 'immutable';

export interface IAuditSelector {
  selectAudit(state, auditId: number): IAuditModel;
  selectAudits(state): Array<IAuditModel>;
  selectAuditsByDomain(state, domainId): Array<IAuditModel>;
}

export class AuditSelector implements IAuditSelector {
  private getAudit(state, auditId: number) {
    return filterOrGiven(this.getAudits(state), auditMap => auditMap.get('id') == auditId, undefined);
  }

  private getAudits(state): Immutable.List<Immutable.Map<string, any>> {
    return state.audits.get('all');
  }

  private getAuditsByDomain(state, domainId: number): Immutable.Iterable<any, any> {
    return filterOrGiven(this.getAudits(state), auditMap => auditMap.get('domainId') == domainId, undefined);
  }

  selectAudit(state, auditId: number): IAuditModel {
    return firstOrGiven(toJSOrGiven<Array<IAuditModel>>(this.getAudit(state, auditId), undefined), undefined);
  }

  selectAudits(state): Array<IAuditModel> {
    return toJSOrGiven<Array<IAuditModel>>(this.getAudits(state), undefined);
  };

  selectAuditsByDomain(state, domainId): Array<IAuditModel> {
    return toJSOrGiven<Array<IAuditModel>>(this.getAuditsByDomain(state, domainId), undefined);
  };
}

