import { EAuditReportFilterTypes } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
  CommonReportsPagesFiltersConfig,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export enum ETagInventoryTrendNames {
  UniqueTags = 'unique_tags',
  BrokenTags = 'broken_tags',
  BrokenPages = 'broken_pages',
  RuleResults = 'rule_results'
}

export const AverageUniqueTagsChartConfig = {
  title: 'Average Number of Unique Tags',
  data: [],
  sideLabel: 'unique tags',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `${value} unique tags`; },
};

export const AverageBrokenTagsChartConfig = {
  title: 'Average Number of Broken Tags',
  data: [],
  sideLabel: 'broken tags',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `${value} broken tags`; },
};

export const AverageBrokenPagesChartConfig = {
  title: 'Average Number of Broken Pages',
  data: [],
  sideLabel: 'broken pages',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `${value} broken pages`; },
};

export enum ETagInventoryReportFilterType {
  PagesWithoutTag = 'pagesWithoutTag',
  TagPresent = 'pagesWithTag',
  AccountMissing = 'pagesMissingAccount',
  AccountPresent = 'pagesWithAccount'
}

export const TAG_INVENTORY_EXPORT_TYPE = 'tag_inventory_pages';

export const TagInventoryRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,

  EAuditReportFilterTypes.TagVendorId,
  EAuditReportFilterTypes.TagPrimaryTagsOnly,
  EAuditReportFilterTypes.TagId,
  EAuditReportFilterTypes.TagCategory,
  EAuditReportFilterTypes.PagesWithoutTagId,
  EAuditReportFilterTypes.PagesWithoutTagCategoryId,
  EAuditReportFilterTypes.PagesWithoutTagVendorId,
  EAuditReportFilterTypes.TagAccount
];

export const PagesTableColumns: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.TagInstanceCount]: {
    title: '# of Tag Requests',
    checked: true,
  },
  [CommonReportsPagesTableColumns.UniqueTagCount]: {
    title: '#|% of Unique Tags',
    checked: true,
  },
  [CommonReportsPagesTableColumns.BrokenTagCount]: {
    title: '# of Broken Tags',
    checked: true,
  },
};
