import { Names } from '@app/moonbeamConstants';
import { ISerializer } from '../../../../../api/apiService';
import { ITag, ITagReport } from '../../../../../../models/tagModels/tag';
import { TagsService } from '../../../../../tags/tagsService';
import { IDetail } from '../../../../../../models/tagModels/detail';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

interface ITagPresenceAccountJson {
    name: string;
    percentTagged: number;
    pagesTagged: number;
  }

  interface ITagPresenceJson {
    name: string;
    id: number;
    percentTagged: number;
    pagesTagged: number;
    accounts: Array<ITagPresenceAccountJson>;
  }

  interface ITagPresenceDetailJson {
    runId: number;
    tags: Array<ITagPresenceJson>;
  }

  interface ITagReportJson {
    totalPages: number;
    runIds: Array<Number>;
    runTags: Array<ITagPresenceDetailJson>
  }

  export class TagPresenceSerializer implements ISerializer<ITagReport> {

    static $inject = [
      Names.Services.tags
    ];

    constructor(private tagsService: TagsService) {
    }

    serialize(json: ITagReportJson): ITagReport {
      return {
        total: json.totalPages,
        tags: (json.runTags && json.runTags[0]) ?
          json.runTags[0]?.tags?.map(json => this.jsonToITag(json)) :
          []
      };
    }

    private jsonToITag = (json: ITagPresenceJson): ITag => {
      return <ITag>{
        id: String(json.id),
        name: json.name,
        iconUrl: UiTagService.getTagIconUrl(json.id),
        value: json.pagesTagged,
        details: json.accounts.map(json => this.jsonToIDetail(json))
      };
    }

    private jsonToIDetail = (json: ITagPresenceAccountJson): IDetail  => {
      return <IDetail>{
        name: json.name,
        value: json.pagesTagged
      };
    }
}

