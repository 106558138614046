import {
  Component,
  ElementRef, EventEmitter, Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren, OnChanges,
} from '@angular/core';
import { LiveConnectUrlBuilders } from './../../live-connect/live-connect.constants';
import { RfmLibraryUrlBuilders } from './../../rfm-library/rfm-library.constants';
import { ActionSetLibraryUrlBuilders } from './../../action-set-library/action-set-library.constants';
import { ComparisonLibraryUrlBuilders } from './../../comparison-library/comparison-library.constants';
import { AccountSearchComponent } from '@app/components/account-search/account-search.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { NewDataModalComponent } from '@app/components/new-data-modal/new-data-modal.component';
import { SidebarService } from './sidebar.service';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, shareReplay, filter, tap, startWith, mergeMap } from 'rxjs/operators';
import { AccountSearchModalConfig, ETooltipOptions } from './sidebar.constants';
import { ISidebarLink } from '@app/components/navigation/sidebar/sidebar.models';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { ThemeService } from '@app/services/theme-service/theme.service';
import { IUser } from '@app/moonbeamModels';
import { userIsAdmin, userIsGuest, userIsOPAdmin, userIsOPSysAdmin } from '@app/authUtils';
import { Features, Names } from '@app/moonbeamConstants';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { opLinkSelectorMap, opSelectors } from './sidebar.constants';
import { CacheResetService, ECacheResetEvent } from '@app/components/core/services/cache-reset.service';
import { userIsStandard } from '@app/authUtils';
import { ConsentCategoriesUrlBuilders } from '@app/components/consent-categories/consent-categories.constants';
import { AccountsService } from '@app/components/account/account.service';
import { KeyboardShortcutsModalComponent } from '@app/components/keyboard-shortcuts-modal/keyboard-shortcuts-modal.component';
import { KeyboardShortcutsService } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.service';
import { MatDialogRef } from '@angular/material/dialog';
import { StorageService } from '@app/components/shared/services/storage.service';
import { ExportCenterUrlBuilders } from '@app/components/export-center/export-center.constants';
import { RuleLibraryUrlBuilders } from '@app/components/rules/rule-library/rule-library.constants';
import { AlertsLibraryUrlBuilders } from '@app/components/alerts-library/alerts-library.constants';
import { CookieService } from 'ngx-cookie-service';
import { TriggeredAlertsUrlBuilder } from '@app/components/triggered-alerts/triggered-alerts.constants';
import { BulkOperationsUrlBuilders } from '@app/components/bulk-operations/bulk-operations.const';
import { SidebarLinkComponent } from '@app/components/navigation/sidebar/sidebar-link/sidebar-link.component';
import { DOCUMENT } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TriggeredAlertsService } from '@app/components/triggered-alerts/triggered-alerts.service';
import { IAccountTriggeredAlertsSummaryDTO } from '@app/components/triggered-alerts/triggered-alerts.models';
import { SharedLinksLibraryBuilders } from '@app/components/shared-links/shared-links-library.constants';
import { MatSidenav } from '@angular/material/sidenav';
import { EmailInboxesUrlBuilders } from '@app/components/email-inboxes/email-inboxes.constants';
import { UsageUrlBuilders } from '@app/components/usage-v2/usage-v2.constants';
import { AdminPortalUrlBuilders } from '@app/components/admin-portal/admin-portal.constants';

@Component({
  selector: 'op-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnChanges {

  @Input() isMobile: boolean = false;
  @Input() drawer: MatSidenav;
  @Input() isVisible: boolean = false;

  @Output() mobileFlyoutIsVisible: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('settingsItem') sidebarLinkComponent: SidebarLinkComponent;
  @ViewChildren(SidebarLinkComponent) sidebarLinkComponents: QueryList<SidebarLinkComponent>;

  CONSTANTS = { ...opLinkSelectorMap, ...opSelectors };
  user: IUser;
  collapseButtonTooltipMessage: string;
  destroy$ = new Subject<void>();
  sidebarIsClosed: boolean;
  profileMenuClass = 'sidebar-link-profile';
  keyboardShortcutsModalRef: MatDialogRef<KeyboardShortcutsModalComponent>;
  addNewDataLink = {
    displayText: 'Create New',
    icon: 'add_box',
    routePath: '/',
    opSelector: this.getOpLinkAttr('create new'),
    subitems: [],
    clickAction: this.createDataSource.bind(this),
  };

  private profileWelcomeUserMenuItem = {
    routePath: '/',
    displayText: '',
    opSelector: this.getOpLinkAttr('profile'),
    clickAction: () => { /* This item displays information and does not require any action */ },
    isSectionHeader: true
  };

  private navigateToProfileItem = {
    routePath: '/my-profile',
    displayText: 'Profile',
    opSelector: this.getOpLinkAttr('profile')
  };

  hasAnnouncement: boolean = false;
  themeToggled = false;
  mobileFlyoutVisible: boolean = false;
  sectionLink: ISidebarLink;

  private cacheResetEventTimestamp: number = 0;

  private readonly staticSidebarSectionLinks: ISidebarLink[][] = [
    [
      {
        displayText: 'Data Sources',
        icon: 'library_books',
        opSelector: this.getOpLinkAttr('data sources'),
        routePath: '/',
        subitems: [
          [
            { routePath: '/', displayText: 'Data Sources', clickAction: () => this.router.navigateByUrl(DataSourcesUrlBuilders.sources()), opSelector: this.getOpLinkAttr('data sources') },
            { routePath: DataSourcesUrlBuilders.sources(), displayText: 'Audits & Journeys', opSelector: this.getOpLinkAttr('audits & journeys') },
            { routePath: LiveConnectUrlBuilders.base(), displayText: 'LiveConnect', featureKey: Features.realDeviceConnect, opSelector: this.getOpLinkAttr('liveconnect') },
            { routePath: '/', displayText: 'HAR Upload', featureKey: Features.harFileIngestor, clickAction: () => this.router.navigateByUrl(LiveConnectUrlBuilders.base()), opSelector: this.getOpLinkAttr('har upload') }
          ]
        ],
        clickAction: null
      },
      {
        displayText: 'Triggered Alerts',
        icon: 'notifications_active',
        notified: false,
        routePath: TriggeredAlertsUrlBuilder.base(),
        opSelector: this.getOpLinkAttr('alerts'),
        clickAction: () => this.router.navigateByUrl(TriggeredAlertsUrlBuilder.base()),
      },
      {
        displayText: 'Usage',
        icon: 'multiline_chart',
        routePath: '/',
        opSelector: this.getOpLinkAttr('usage'),
        subitems: [
          [
            { displayText: 'Usage', icon: 'data_usage', routePath: '/', opSelector: this.getOpLinkAttr('usage-heading'), clickAction: this.handleUsageClick.bind(this) },
            { displayText: 'Usage Dashboard', routePath: UsageUrlBuilders.base(), opSelector: this.getOpLinkAttr('usage'), clickAction: this.handleUsageClick.bind(this) },
            { routePath: AccountSettingsUrlBuilders.userEvents(), displayText: 'Event Log', opSelector: this.getOpLinkAttr('event log') },
            { routePath: ExportCenterUrlBuilders.base(), displayText: 'Requested Exports', opSelector: this.getOpLinkAttr('requested exports') }
          ]
        ],
        clickAction: null
      }
    ],
    [
      {
        displayText: 'Standards',
        icon: 'architecture',
        routePath: '/',
        opSelector: this.getOpLinkAttr('standards'),
        subitems: [
          [
            { routePath: '/', displayText: 'Standards', opSelector: this.getOpLinkAttr('standards'), clickAction: () => { /* This link intentionally ignored */ }, isSectionHeader: true },
            { routePath: AlertsLibraryUrlBuilders.library(), opSelector: this.getOpLinkAttr('alerts'), displayText: 'Alerts' },
            { routePath: RuleLibraryUrlBuilders.library(), opSelector: this.getOpLinkAttr('rules'), displayText: 'Tag & Variable Rules' },
            { routePath: ConsentCategoriesUrlBuilders.base(), opSelector: this.getOpLinkAttr('consent-categories'), displayText: 'Consent Categories', featureKey: Features.productLinePrivacy },
          ]
        ],
        clickAction: null
      },
      {
        displayText: 'Configurations',
        icon: 'construction',
        opSelector: this.getOpLinkAttr('configurations'),
        routePath: '/',
        subitems: [
          [
            { routePath: '/', displayText: 'Configurations', opSelector: this.getOpLinkAttr('configurations'), clickAction: () => { /* This link intentionally ignored */ }, isSectionHeader: true },
            { routePath: ActionSetLibraryUrlBuilders.base(), displayText: 'Action Sets', opSelector: this.getOpLinkAttr('action sets') },
            { routePath: RfmLibraryUrlBuilders.base(), displayText: 'File Substitutions', opSelector: this.getOpLinkAttr('remote file mapping') },
            { routePath: ComparisonLibraryUrlBuilders.library(), displayText: 'Comparisons', opSelector: this.getOpLinkAttr('comparisons') },
            { routePath: AccountSettingsUrlBuilders.manageTags(), displayText: 'Tags - Primary and Comparisons Setup', opSelector: this.getOpLinkAttr('manage tags') },
            { routePath: EmailInboxesUrlBuilders.base(), displayText: 'Email Inboxes', opSelector: this.getOpLinkAttr('email inboxes') }
          ]
        ],
        clickAction: null
      },
    ]
  ];

  sidebarSectionLinks = [...this.staticSidebarSectionLinks];

  sidebarBottomLinks: { [key: string]: ISidebarLink } = {
    search: {
      displayText: 'Search',
      icon: 'search',
      routePath: '/',
      subitems: [],
      actionText: 'Search audits, journeys, folders, domains',
      opSelector: this.getOpLinkAttr('search'),
      clickAction: () => this.showSearch()
    },
    support: {
      displayText: 'Support',
      icon: 'help_outline',
      opSelector: this.getOpLinkAttr('support-top-level'),
      routePath: '/',
      subitems: [
        [
          { routePath: '/', displayText: 'Support', opSelector: this.getOpLinkAttr('support'), clickAction: () => { /* This link intentionally ignored */ }, isSectionHeader: true },
          { routePath: '/', displayText: 'Chat/Email', opSelector: this.getOpLinkAttr('chat/email'), clickAction: () => { /* This link intentionally ignored. Other code attaches here to open chat window. */ } },
          { routePath: '/', displayText: 'Help Documentation', opSelector: this.getOpLinkAttr('help documentation'), clickAction: () => { /* This link intentionally ignored. Other code attaches here to open contextual help. */ } },
          { routePath: '/', displayText: 'Keyboard Shortcuts', opSelector: this.getOpLinkAttr('keyboard shortcuts'), clickAction: () => this.openKeyboardShortcutsModal() },
          { routePath: '/', displayText: 'Academy Training', opSelector: this.getOpLinkAttr('academy training'), clickAction: () => window.open('https://academy.observepoint.com') },
          { routePath: '/tag-database', displayText: 'Tag Database', opSelector: this.getOpLinkAttr('tag database') },
          { routePath: '/feedback-portal', displayText: 'Product Updates & Feedback', opSelector: this.getOpLinkAttr('feedback portal') },
          { routePath: '/', displayText: 'API Documentation', opSelector: this.getOpLinkAttr('api documentation'), clickAction: () => window.open('https://help.observepoint.com/en/articles/9106323-getting-started-with-the-observepoint-api') },
          { routePath: '/web-journey-support', displayText: 'Web Journey Support Request', opSelector: this.getOpLinkAttr('script services') }
        ]
      ],
      clickAction: null
    },
    settings: {
      displayText: 'Settings',
      icon: 'settings',
      opSelector: this.getOpLinkAttr('settings'),
      routePath: '/',
      subitems: [
        [
          { routePath: AccountSettingsUrlBuilders.manageUsers(), displayText: 'Settings', opSelector: this.getOpLinkAttr('settings') },
          { routePath: AccountSettingsUrlBuilders.manageUsers(), displayText: 'Users', opSelector: this.getOpLinkAttr('users') },
          { routePath: AccountSettingsUrlBuilders.eventLog(), displayText: 'Event Log', opSelector: this.getOpLinkAttr('event-log') },
          { routePath: AccountSettingsUrlBuilders.notificationCenter(), displayText: 'Notification Center', opSelector: this.getOpLinkAttr('notification center') },
          { routePath: BulkOperationsUrlBuilders.manageFolders(), displayText: 'Folders', opSelector: this.getOpLinkAttr('folders') },
          { routePath: BulkOperationsUrlBuilders.manageSubfolders(), displayText: 'Sub-Folders - Data Layer(s)', opSelector: this.getOpLinkAttr('subfolders') },
          { routePath: BulkOperationsUrlBuilders.manageLabels(), displayText: 'Labels', opSelector: this.getOpLinkAttr('labels') },
          { routePath: SharedLinksLibraryBuilders.base(), requiredPermission: 'admin', displayText: 'Shared Links', opSelector: this.getOpLinkAttr('shared links') },
        ],
        [
          {
            routePath: '',
            displayText: this.themeToggled ? 'Switch to Light Mode' : 'Switch to Dark Mode',
            icon: this.themeToggled ? 'light_mode' : 'dark_mode',
            opSelector: this.getOpLinkAttr('theme toggle'),
            clickAction: () => this.changeTheme()
          }
        ]
      ],
    },
    announcement: {
      displayText: 'Announcements',
      icon: 'campaign_outline',
      routePath: '/',
      opSelector: this.getOpLinkAttr('announcement'),
      clickAction: this.handleAnnouncementClick.bind(this),
    },
    checklist: {
      displayText: 'Checklist',
      icon: 'checklist',
      routePath: '/',
      opSelector: this.getOpLinkAttr('checklist'),
      clickAction: () => { },
    },
    profileStandard: {
      displayText: 'My Profile',
      icon: 'account_circle',
      opSelector: this.getOpLinkAttr('profile-top-level'),
      routePath: '/',
      subitems: [
        [
          this.profileWelcomeUserMenuItem,
          this.navigateToProfileItem,
          { routePath: '/', displayText: 'Sign Out', clickAction: () => this.logOut(), opSelector: this.getOpLinkAttr('sign out') },
        ]
      ],
    },
    profileAdmin: {
      displayText: 'My Profile',
      icon: 'account_circle',
      opSelector: this.getOpLinkAttr('profile-admin-top-level'),
      routePath: '/',
      subitems: [
        [
          this.profileWelcomeUserMenuItem,
          this.navigateToProfileItem,
          { routePath: AdminPortalUrlBuilders.accounts(), displayText: 'Admin Portal', opSelector: this.getOpLinkAttr('admin portal') },
          { routePath: '/', displayText: 'Sign Out', clickAction: () => this.logOut(), opSelector: this.getOpLinkAttr('sign out') },
        ]
      ],
    }
  };

  accountFeatures$ = new BehaviorSubject<string[]>([]);
  topSectionScrollable = false;
  isReadOnly: boolean;
  isAdmin: boolean;
  isAnyAdmin: boolean;
  showNotificationDot = false;

  @ViewChild('sidebarTopSection') sidebarTopSectionElement: ElementRef;

  constructor(
    private modalService: OpModalService,
    private sidebarService: SidebarService,
    private router: Router,
    private themeService: ThemeService,
    private authenticationService: AuthenticationService,
    private accountsService: AccountsService,
    private cacheResetService: CacheResetService,
    private keyboardShortcutsService: KeyboardShortcutsService,
    private storageService: StorageService,
    private cookieService: CookieService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private triggeredAlertsService: TriggeredAlertsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.hideItemsByCookies();
    this.initRouterListener();
    this.getUserData();
    this.checkForAnnouncement();

    fromEvent(this.document.body, 'click').subscribe(() => this.sidebarLinkComponents.forEach(item => item.closeMenu()));

    this.matIconRegistry.addSvgIcon(
      'expand-sidebar',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`images/custom-mat-icons/expand-sidebar.svg`)
    );
  }

  ngOnInit(): void {
    this.themeService.isDarkTheme.pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.themeToggled = val;
      this.initSwitcherConfig();
    });
    this.initSidebarService();

    const resetFeatures = () => this.authenticationService.getFeaturesWithCache()
      .pipe(
        shareReplay()
      ).subscribe(features => {
        this.accountFeatures$.next(features); // used by template
        this.updateDataSourcesMenuItem(features);

        this.sidebarSectionLinks = [];
        this.staticSidebarSectionLinks.forEach(section => this.sidebarSectionLinks.push([...section]));
      });
    resetFeatures();
    this.cacheResetService.reset$
      .pipe(
        filter(event => event !== ECacheResetEvent.login),
        tap(_ => this.cacheResetEventTimestamp = Date.now())
      )
      .subscribe(_ => {
        resetFeatures();
        this.checkForTriggeredAlerts();
      });

    this.keyboardShortcutsService.registerOpenShortcutsModalMethod(this.openKeyboardShortcutsModal.bind(this));
    this.checkForTriggeredAlerts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isVisible) {
      this.mobileFlyoutVisible = changes.isVisible.currentValue;
    }
  }

  checkForTriggeredAlerts(): void {
    this.triggeredAlertsService.getAlertsSummary().subscribe((alertsSummary: IAccountTriggeredAlertsSummaryDTO) => {
      this.staticSidebarSectionLinks[0][1].notified = alertsSummary.auditsWithTriggeredAlertCount > 0;
    });
  }

  checkForAnnouncement(): void {
    this.hasAnnouncement = this.storageService.getAnnouncementValue();
  }

  handleAnnouncementClick(): void {
    if (this.hasAnnouncement) {
      this.storageService.clearAnnouncement();
      this.hasAnnouncement = false;
    }
  }

  handleUsageClick(): void {
    this.router.navigateByUrl(UsageUrlBuilders.base());
  }

  routeTo(path = '/') {
    this.router.navigateByUrl(path);
  }

  getUserData(): void {
    this.authenticationService.getUserInfoUpdatedNotifications()
      .pipe(
        startWith(true),
        mergeMap(() => this.accountsService.getUser())
      ).subscribe((user: IUser) => {
        this.user = user;
        this.setProfileName();
        this.isReadOnly = userIsGuest(this.user);

        if (!this.isReadOnly && !this.sidebarSectionLinks[0].includes(this.addNewDataLink)) {
          // add the "Create New" button in the left-nav
          this.sidebarSectionLinks[0].push(this.addNewDataLink);
        }

        if (this.isReadOnly && this.sidebarSectionLinks[0].includes(this.addNewDataLink)) {
          this.sidebarSectionLinks[0] = this.sidebarSectionLinks[0].filter(sl => sl !== this.addNewDataLink);
        }

        const alertsReportExists = this.sidebarSectionLinks[0].findIndex(link => link.routePath === TriggeredAlertsUrlBuilder.base()) > -1;
        if (this.isReadOnly && !alertsReportExists) {
          this.sidebarSectionLinks[0].push(this.staticSidebarSectionLinks[0][1]);
        }

        this.setSettingsMenuItems();
      });
  }

  setSettingsMenuItems(): void {
    if (userIsOPSysAdmin(this.user) || userIsOPAdmin(this.user) || userIsAdmin(this.user)) {
      this.isAnyAdmin = true;
    }

    if (userIsOPAdmin(this.user)) {
      this.isAdmin = true;
      this.profileMenuClass += ' is-op-admin';
    }

    if ((userIsStandard(this.user) || userIsGuest(this.user)) && !userIsAdmin(this.user)) {
      //------- USAGE MENU EDITS
      const usageSection = this.findItemByOpSelector(this.getOpLinkAttr('usage'));
      const usageHeading = this.findItemByOpSelector(this.getOpLinkAttr('usage-heading'), usageSection.subitems);

      // adding this property removes the click action from the usage heading since
      // standard users don't have access to the usage dashboard
      if (usageHeading) usageHeading['isSectionHeader'] = true;

      // remove the usage Dashboard Menu Link
      // TODO: Uncomment when we ship Usage V2 // standard users with the added permission can see the usage dashboard
      if (userIsStandard(this.user) && !this.user.fullUsageDashboardAccess) {
        const usageDashboardIndex = usageSection.subitems[0].findIndex(item => item['opSelector'] === this.getOpLinkAttr('usage'));
        if (usageDashboardIndex > -1) usageSection.subitems[0].splice(usageDashboardIndex, 1);
      }

      // remove the event log menu link from usage menu
      const eventLogMenuItemIndex = usageSection.subitems[0].findIndex(item => item['opSelector'] === this.getOpLinkAttr('event log'));
      if (eventLogMenuItemIndex > -1) usageSection.subitems[0].splice(eventLogMenuItemIndex, 1);

      //-------

      // adding this property removes the click action from the settings heading since
      // standard users don't have access to the users page
      if (this.sidebarBottomLinks) this.sidebarBottomLinks.settings['isSectionHeader'] = true;

      // remove the users menu link from settings menu
      const usersMenuItemIndex = this.sidebarBottomLinks.settings.subitems[0].findIndex(item => item['opSelector'] === this.getOpLinkAttr('users'));
      if (usersMenuItemIndex > -1) this.sidebarBottomLinks.settings.subitems[0].splice(usersMenuItemIndex, 1);
    }

    if (userIsGuest(this.user) && !userIsStandard(this.user)) {
      // remove standards menu link from sidebar
      const standardsMenuItemIndex = this.sidebarSectionLinks[1].findIndex(link => link.opSelector === this.getOpLinkAttr('standards'));
      if (standardsMenuItemIndex > -1) this.sidebarSectionLinks[1].splice(standardsMenuItemIndex, 1);

      // remove configurations menu link from sidebar
      const configurationsMenuItemIndex = this.sidebarSectionLinks[1].findIndex(link => link.opSelector === this.getOpLinkAttr('configurations'));
      if (configurationsMenuItemIndex > -1) this.sidebarSectionLinks[1].splice(configurationsMenuItemIndex, 1);
    }
  }

  initSidebarService(): void {
    this.sidebarService.isClosed.pipe(takeUntil(this.destroy$)).subscribe((status: boolean) => {
      this.sidebarIsClosed = status;
      this.collapseButtonTooltipMessage = this.sidebarIsClosed
        ? ETooltipOptions.Expand
        : '';
    });
  }

  /**
   * this method will ensure that when OP admins switch
   * between accounts that the name in the menu is updated
   */
  initRouterListener(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
      if (e instanceof NavigationEnd) this.getUserData();
    });
  }

  hideItemsByCookies() {
    this.sidebarSectionLinks.forEach(section => section.forEach(item => {
      if (item.hiddenByCookie) {
        item.hidden = !this.cookieService.get(item.hiddenByCookie);
      }

      item.subitems?.forEach(subsection => {
        subsection.forEach(subitem => {
          if (item.hiddenByCookie) {
            subitem.hidden = !this.cookieService.get(item.hiddenByCookie);
          }
        });
      });
    }));
  }
  getOpLinkAttr(name: string): string {
    return this.CONSTANTS[name.toLowerCase()] || '';
  }

  setProfileName(): void {
    if (this.user.firstName && this.user.lastName) {
      this.sidebarBottomLinks.profileStandard.displayText = `${this.user.firstName} ${this.user.lastName}`;
      this.sidebarBottomLinks.profileAdmin.displayText = `${this.user.firstName} ${this.user.lastName}`;
      this.profileWelcomeUserMenuItem.displayText = `Hi, ${this.user.firstName} ${this.user.lastName}`;
    } else if (this.user.username) {
      this.sidebarBottomLinks.profileStandard.displayText = this.user.username;
      this.sidebarBottomLinks.profileAdmin.displayText = this.user.username;
      this.profileWelcomeUserMenuItem.displayText = `Hi, ${this.user.username}`;
    } else {
      this.sidebarBottomLinks.profileStandard.displayText = 'My Profile';
      this.sidebarBottomLinks.profileAdmin.displayText = 'My Profile';
      this.profileWelcomeUserMenuItem.displayText = 'Hi!';
    }
  }

  showSearch(): void {
    this.modalService.openFullscreenModal(AccountSearchComponent, AccountSearchModalConfig);
  }

  private changeTooltipTimeout = null;
  toggleSidebarState(state: boolean): void {
    this.sidebarService.toggleSidebarState(state);
    this.collapseButtonTooltipMessage = '';

    if (this.changeTooltipTimeout) {
      clearTimeout(this.changeTooltipTimeout);
    }

    setTimeout(() => {
      this.collapseButtonTooltipMessage = ETooltipOptions.Expand;
      this.changeTooltipTimeout = null;
    }, 500);
  }

  logOut(): void {
    this.authenticationService.logout();
  }

  createDataSource(): void {
    this.modalService.openModal(NewDataModalComponent, {});
  }

  sidebarLinkAction(link: ISidebarLink) {
    if (this.isMobile) {
      this.sectionLink = { ...link };
      if (this.sectionLink?.subitems?.length) {
        this.mobileFlyoutVisible = true;
        this.mobileFlyoutIsVisible.emit(true);
      }

      if (typeof link.clickAction === 'function') {
        link.clickAction();
      }
      return;
    } else if (typeof link.clickAction === 'function') {
      link.clickAction();
    }
  }

  private initSwitcherConfig() {
    this.sidebarBottomLinks['settings'].subitems[1][0].displayText = this.themeToggled ? 'Switch to Light Mode' : 'Switch to Dark Mode';
    this.sidebarBottomLinks['settings'].subitems[1][0].icon = this.themeToggled ? 'light_mode' : 'dark_mode';
  }

  changeTheme() {
    this.themeToggled = !this.themeToggled;
    this.themeService.toggleTheme(this.themeToggled);
  }

  openKeyboardShortcutsModal(): void {
    if (this.keyboardShortcutsModalRef) {
      this.keyboardShortcutsModalRef.close();
    } else {
      this.keyboardShortcutsModalRef = this.modalService.openModal(KeyboardShortcutsModalComponent, {
        disableClose: false,
        closeOnNavigation: true,
        autoFocus: false,
        id: 'keyboard-shortcuts-modal'
      });

      this.keyboardShortcutsModalRef.afterClosed().subscribe(() => {
        this.keyboardShortcutsModalRef = undefined;
      });
    }
  }

  updateDataSourcesMenuItem(features: string[]): void {
    if (!features.includes(Features.realDeviceConnect) && !features.includes(Features.harFileIngestor)) {
      this.sidebarSectionLinks[0][0] = {
        displayText: 'Data Sources',
        icon: 'library_books',
        opSelector: this.getOpLinkAttr('data sources'),
        routePath: '/',
        clickAction: () => this.router.navigateByUrl(DataSourcesUrlBuilders.sources())
      };
    }
  }

  closeSideNav(): void {
    if (!this.drawer) return;
    this.drawer.close();
  }

  private findItemByOpSelector(opSelector: string, items: ISidebarLink[][] = this.staticSidebarSectionLinks): ISidebarLink | undefined {
    for (const sectionLinks of items) {
      for (const link of sectionLinks) {
        if (link.opSelector === opSelector) {
          return link;
        }

        if (link.subitems) {
          const foundItem = this.findItemByOpSelector(opSelector, link.subitems);
          if (foundItem) {
            return foundItem;
          }
        }
      }
    }

    return undefined;
  }
}
