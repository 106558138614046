import { CacheResetService } from './../../core/services/cache-reset.service';
import * as angular from 'angular';

import { AngularNames, Names } from '@app/moonbeamConstants';
import { TagsService } from '../../tags/tagsService';
import { ITagBase } from '../../../models/tagModels/tag';
import { IManualJourneyTag } from '../../live-connect/manual-journey/manualJourneyService';
import { IPrimaryTagSettings } from '@app/components/account-settings/manage-tags/manage-tags.models';
import { StorageService } from '@app/components/shared/services/storage.service';
import { ReplaySubject, Observable } from 'rxjs';

export interface IPrimaryTag {
    id?: number;
    tagId: number;
    name: string;
    icon: string;
    categoryName: string;
    enabled: boolean;
    owners?: Array<ITagContact>;
    technicalSpecialists?: Array<ITagContact>;
    vendorContacts?: Array<ITagContact>;
    cost?: ITagCost;
    notes?: string;
    comparisonsSettings?: IPrimaryTagSettings
  }

  export interface ITagContact {
    name: string;
    email: string;
    phone: string;
  }

  export interface ITagCost {
    cost: string;
    period: TagCostPeriod;
  }

  export type TagCostPeriod = 'month' | 'quarter' | 'year';
  export class TagCostPeriods {
    static month: TagCostPeriod = 'month';
    static quarter: TagCostPeriod = 'quarter';
    static year: TagCostPeriod = 'year';
  }
  export interface ITagCostPeriod {
    key: TagCostPeriod;
    label: string;
  }

  export interface IPrimaryTagsMessageParams {
    dontShowAuditMessage: {[user: string]: boolean};
  }

  export interface IPrimaryTagParams {
    toggleFilterOn: boolean;
  }

  interface IJourneyTagBase {
    tagId: number;
    tagName?: string;
    tagIcon?: string;
    stub?: true;
    variables: Array<any>;
  }

  export class ManageTagsService {

    getPrimaryTagsRequest: angular.IPromise<Array<IPrimaryTag>>;
    areGetPrimaryTagsRequested: boolean = false;

    private activeReportToggleChanges$ = new ReplaySubject<boolean>(1);

    primaryTags: Array<IPrimaryTag>;

    static $inject = [
      AngularNames.q,
      Names.Services.tags,
      Names.Services.storageService,
      Names.NgServices.cacheReset
    ];

    constructor(
      private $q: angular.IQService,
      private tagsService: TagsService,
      private storageService: StorageService,
      private cacheResetService: CacheResetService
    ) {
      this.getReportToggleValue().then(savedState => {
        this.activeReportToggleChanges$.next(savedState);
      });

      this.cacheResetService.reset$.subscribe(_ => {
        this.getPrimaryTagsRequest = null;
        this.areGetPrimaryTagsRequested = false;
        this.primaryTags = null;
      });
    }

    getPrimaryTags(): angular.IPromise<Array<IPrimaryTag>> {
      if (this.primaryTags) return this.$q.resolve(this.primaryTags);
      if (this.areGetPrimaryTagsRequested) return this.getPrimaryTagsRequest;
      this.areGetPrimaryTagsRequested = true;
      this.getPrimaryTagsRequest = this.tagsService.getPrimaryTags().then( (primaryTags: Array<IPrimaryTag>) => {
          this.primaryTags = primaryTags;
          return primaryTags;
        });
      return this.getPrimaryTagsRequest;
    }

    addPrimaryTag(primaryTag: IPrimaryTag): angular.IPromise<IPrimaryTag> {
      return this.tagsService.addPrimaryTag(primaryTag).then( (tag: IPrimaryTag) => {
        if (this.primaryTags) this.primaryTags.push(tag);
        return tag;
      });
    }

    updatePrimaryTag(primaryTag: IPrimaryTag): angular.IPromise<IPrimaryTag> {
      return this.tagsService.updatePrimaryTag(primaryTag).then( (tag: IPrimaryTag) => {
        if (this.primaryTags) this.primaryTags[this.primaryTags.findIndex(t => t.id == tag.id)] = tag;
        return tag;
      });
    }

    updatePrimaryTagFilter(primaryTagId: number, enabled: boolean): angular.IPromise<IPrimaryTag> {
      return this.tagsService.updatePrimaryTagFilter(primaryTagId, enabled).then( (tag: IPrimaryTag) => {
        if (this.primaryTags) this.primaryTags[this.primaryTags.findIndex(t => t.id == tag.id)] = tag;
        return tag;
      });
    }

    deletePrimaryTag(primaryTagId: number): angular.IPromise<any> {
      return this.tagsService.deletePrimaryTag(primaryTagId).then( () => {
        if (this.primaryTags) this.primaryTags.splice(this.primaryTags.findIndex(t => t.id == primaryTagId), 1);
        return;
      });
    }

    filterTagsByPrimaryTags(tags: Array<ITagBase>, primaryTags: Array<IPrimaryTag>): Array<ITagBase> {
        return this.abstractFilterTagsByPrimaryTags<ITagBase>(
          tags,
          primaryTags,
          (t: ITagBase) => +t.id,
          (prTag: IPrimaryTag) => <ITagBase>{
            id: '' + prTag.tagId,
            name: prTag.name,
            iconUrl: prTag.icon,
            value: 0,
            details: [],
            stub: true
          }
        );
    }

    filterJourneyTagsByPrimaryTags(tags: Array<IJourneyTagBase>, primaryTags: Array<IPrimaryTag>): Array<IJourneyTagBase> {
        return this.abstractFilterTagsByPrimaryTags<IJourneyTagBase>(
          tags,
          primaryTags,
          (t: IJourneyTagBase) => +t.tagId,
          (prTag: IPrimaryTag) => <IJourneyTagBase>{
            tagId: prTag.tagId,
            tagName: prTag.name,
            tagIcon: prTag.icon,
            variables: [],
            stub: true
          }
        );
    }

    filterManualJourneyTagsByPrimaryTags(tags: Array<IManualJourneyTag>, primaryTags: Array<IPrimaryTag>): Array<IManualJourneyTag> {
        return this.abstractFilterTagsByPrimaryTags<IManualJourneyTag>(
          tags,
          primaryTags,
          (t: IManualJourneyTag) => t.tagId,
          (prTag: IPrimaryTag) => <IManualJourneyTag>{
            tagId: prTag.tagId,
            name: prTag.name,
            icon: prTag.icon,
            stepId: -1,
            variables: [],
            stub: true
          }
        );
    }

    private abstractFilterTagsByPrimaryTags<T>(tags: T[], prTags: IPrimaryTag[], tagIdGetter: (T) => number, stubBuilder: (IPrimaryTag) => T): T[] {
      var primaryTagIds: Array<number> = prTags.filter(pt => pt.enabled).map(pt => pt.tagId );
      var filteredTags: Array<T> = tags.filter(tag => primaryTagIds.indexOf(tagIdGetter(tag)) != -1);
      var filteredTagIds: Array<number> = filteredTags.map(ft => tagIdGetter(ft));
      return prTags.filter( pt => pt.enabled && filteredTagIds.indexOf(pt.tagId) == -1)
        .reduce((tags, prTag) => {
          tags.push(stubBuilder(prTag));
          return tags;
        }, filteredTags);
    }

    hasEnabledPrimaryTags(primaryTags: Array<IPrimaryTag>): boolean {
      return primaryTags.filter(tag => tag.enabled).length > 0;
    }

    getReportToggleValue(): angular.IPromise<boolean> {
      var ptParams: IPrimaryTagParams = this.storageService.getPrimaryTagsParam();
      if (ptParams) return this.$q.resolve(ptParams.toggleFilterOn);
      return this.getPrimaryTags().then((tags: Array<IPrimaryTag>) => {
        return this.hasEnabledPrimaryTags(tags);
      });
    }
    setActiveReportToggle(value: boolean): void {
      var ptParams: IPrimaryTagParams = this.storageService.getPrimaryTagsParam();
      if (!ptParams) ptParams = {
        toggleFilterOn: false,
      };
      ptParams.toggleFilterOn = value;
      this.storageService.setPrimaryTagsParam(ptParams);
      this.activeReportToggleChanges$.next(ptParams.toggleFilterOn);
    }
    resetStoredReportToggleValues(): void {
      this.storageService.setPrimaryTagsParam({
        toggleFilterOn: false,
      });
    }
    getReportToggleValueChanges(): Observable<boolean> {
      return this.activeReportToggleChanges$.asObservable();
    }
  }
