<div class="inbox-header">
  <div class="left">
    <button class="back-btn">
      <mat-icon (click)="closeInboxMessages()"
                matTooltip="Close Inbox"
                class="back-icon">navigate_before</mat-icon>
    </button>
    <div class="title-date-wrp">
      <div class="inbox-name-wrp">
        <mat-icon>mail_outline</mat-icon>
        <h2 *ngIf="title" class="inbox-name" title="Email messages for {{ title }}">Email messages for {{ title }}</h2>
      </div>
    </div>
  </div>
</div>
<mat-progress-bar [style.opacity]="+loading" mode="indeterminate"></mat-progress-bar>
