import { Injectable } from '@angular/core';
import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Observable } from 'rxjs';
import {
  EManageAccountsFilterTypes,
  EManageAccountStatus, EManageAccountStatusDisplayValue,
  IManageAccountsFilter
} from '@app/components/admin-portal/manage-accounts/manage-accounts-filter-bar/manage-accounts-filter-bar.constants';
import { map } from 'rxjs/operators';
import { StorageService } from '@app/components/shared/services/storage.service';
import {
  AccountTypeIdToDisplayValue
} from '@app/components/admin-portal/manage-accounts/manage-accounts.constants';
import { EAccountType } from '@app/components/core/services/authentication.enums';

const FILTERS_STORAGE_KEY = 'manage_accounts_filters';

@Injectable()
export class ManageAccountsFilterBarService extends OpFilterBarService<EManageAccountsFilterTypes> {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService
  ) {
    super(storage, FILTERS_STORAGE_KEY, cacheReset, new Map([ [EManageAccountsFilterTypes.Type, true], [EManageAccountsFilterTypes.Status, true] ]));
    this.updateSupportedFiltersList([
      EManageAccountsFilterTypes.Search,
      EManageAccountsFilterTypes.Type,
      EManageAccountsFilterTypes.Status,
    ]);
  }

  get filters$(): Observable<any[]> {
    return this.anyFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters
          .filter((filter: IManageAccountsFilter) => this.validTypes.includes(filter.type))
          .reduce((acc, curr) => {
            acc.push(curr);
            return acc;
          }, [])
      )
    );
  }

  addSearchContainsFilter(contains: string) {
    this.addFilter({
      type: EManageAccountsFilterTypes.Search,
      display: `Search contains: '${contains}'`,
      value: contains,
      order: 1,
    });
  }

  addTypeFilter(type: EAccountType): void {
    this.addFilter({
      type: EManageAccountsFilterTypes.Type,
      display: `Type is: ${AccountTypeIdToDisplayValue[type]}`,
      value: type,
      order: 2,
    });
  }

  addStatusFilter(status: EManageAccountStatus): void {
    this.addFilter({
      type: EManageAccountsFilterTypes.Status,
      display: `Status is: ${EManageAccountStatusDisplayValue[status]}`,
      value: status,
      order: 3,
    });
  }
}
