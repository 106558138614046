<div class="rfm-container flex-col">
  <div class="box-header">
    <div *ngIf="!hideTitle">Mapping #{{ index + 1 }}</div>
    <button *ngIf="multi" mat-icon-button (click)="onConfigDelete.emit(index)" class="remove-rfmConfig">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form validate-form
    formName="rfmValueForm"
    [formGroup]="rfmValueForm">

    <div class="rfm-value-container flex-col">
      <mat-form-field floatLabel="never">
        <input matInput placeholder="Enter the value to match against" formControlName="matchValue">
        <mat-radio-group matSuffix formControlName="matchType"
          (change)="onChangeMatchType($event)">
          <mat-radio-button color="primary" [value]="matchTypes.Equals">URL</mat-radio-button>
          <mat-radio-button color="primary" [value]="matchTypes.Regex">Regex</mat-radio-button>
        </mat-radio-group>
      </mat-form-field>
      <op-error-message formControlPath="rfmValueForm.matchValue"></op-error-message>
    </div>

    <div class="rfm-file-picker flex-col">
      <div class="flex-col" [style.display]="uploadMode ? 'none' : 'inherit'">
        <mat-form-field>
          <mat-label>Enter the URL of the substitute file</mat-label>
          <input matInput #fileUrlInput formControlName="fileNameUrl" />
        </mat-form-field>
        <op-error-message relativePosition="true"
          formControlPath="rfmValueForm.fileNameUrl">
        </op-error-message>
      </div>
      <div class="flex-col" [style.display]="!uploadMode ? 'none' : 'inherit'">
        <mat-form-field>
          <mat-label>Upload substitute file</mat-label>
          <span matPrefix class="icon-download-cloud">&nbsp;</span>
          <input
            matInput
            formControlName="fileNameUpload"
            class="upload-file-input"
            (click)="switchToUploadMode()" />
        </mat-form-field>
        <op-error-message relativePosition="true"
          formControlPath="rfmValueForm.fileNameUpload">
        </op-error-message>
      </div>
      <div class="flex-row flex-spread-apart picker-hint">
        <span> or,
          <a class="picker-link" (click)="onClickLink()">
            {{uploadMode ? 'Enter URL ' : 'Upload substitute file'}}
          </a>
        </span>
        <div class="checking-url-spinner" *ngIf="urlCheckPending">
          <mat-spinner [diameter]="15"[strokeWidth]="3"></mat-spinner>
          Checking URL...
        </div>
        <span class="size-limit" align="end">2MB maximum</span>
      </div>
      <input style="display: none" type="file" #fileInput (change)="fileEvent($event)">
    </div>
    <div *ngIf="uploadProgress !== 0">
      <span>{{uploadProgress === 100 ? 'Verifying Upload...' : 'Uploading File...'}}</span>
      <mat-progress-bar mode="determinate" [value]="uploadProgress"></mat-progress-bar>
    </div>
  </form>

</div>
