import { WindowRef } from '@app/components/core/services/window.service';
import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategy implements PreloadingStrategy {

  constructor(private windowRef: WindowRef) {}

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return this.shouldPreload() ? load() : EMPTY;
  }

  private shouldPreload(): boolean {
    const connection: NetworkInformation = this.windowRef.nativeWindow.navigator.connection;
    if (!connection) return true; // connection is not supported by all browsers, so allow preloading if not supported
    return !connection.saveData && !this.isSlowConnection(connection);
  }

  private isSlowConnection(connection: NetworkInformation): boolean {
    return ['slow-2g', '2g', '3g'].includes(connection.effectiveType);
  }

}
