import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IActionSelectorAction } from '@app/components/action-selector/action-selector.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { IWebJourneyServices } from '@app/components/web-journey/web-journey.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-failure-message',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './action-failure-message.component.html',
  styleUrls: ['./action-failure-message.component.scss']
})
export class ActionFailureMessageComponent {

  @Input() currentAction: IActionSelectorAction;
  @Input() journeyId: number;
  @Input() runId: number;

  remainingJourneyFixes: number = 0;
  failedActionData = {
    showFixJourneySpinner: false,
    fixRequested: false,
    outOfFixes: false
  };

  constructor(
    private modalService: OpModalService,
    private webJourneyAPIService: IWebJourneyApiService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.getScriptServicesCounts();
  }

  private getScriptServicesCounts(): void {
    this.webJourneyAPIService.getScriptServicesCounts().then((data: IWebJourneyServices) => {
      this.remainingJourneyFixes = data.journeyFixes;
      this.failedActionData.outOfFixes = this.remainingJourneyFixes === 0;
    });
  }

  requestJourneyFix(): void {
    // Check if fixes are available (either remaining or unlimited)
    if (this.remainingJourneyFixes <= 0 && this.remainingJourneyFixes !== -1) return;

    const fixesMessage = this.remainingJourneyFixes === -1
      ? 'unlimited journey fixes'
      : `${this.remainingJourneyFixes} journey fix${this.remainingJourneyFixes === 1 ? '' : 'es'}`;

    let modalRef = this.modalService.openConfirmModal({
      data: {
        title: 'Fix Journey',
        messages: [
          `You have ${fixesMessage} left. This action will use one.`,
          `Do you want to continue?`
        ],
        rightFooterButtons: [
          {
            label: 'No', action: () => {
              modalRef.close();
            },
            primary: false,
            opSelector: 'no-fix-journey'
          },
          {
            label: 'Yes', action: () => {
              this.sendFixJourneyRequest();
            },
            primary: true,
            opSelector: 'fix-journey'
          }
        ]
      }
    });
  }

  private sendFixJourneyRequest(): void {
    this.failedActionData.showFixJourneySpinner = true;
    this.webJourneyAPIService.fixJourney(this.journeyId, this.runId)
      .then(
        success => {
          this.failedActionData.showFixJourneySpinner = false;
          this.failedActionData.fixRequested = true;
          this.snackbarService.openSuccessSnackbar('Your request to fix this journey has been received.');
          this.getScriptServicesCounts();
        },
        error => {
          this.failedActionData.showFixJourneySpinner = false;
          this.failedActionData.fixRequested = false;
          this.snackbarService.openErrorSnackbar('An unknown error occurred while requesting this fix. Please try again.');
        }
      );
  }
}
