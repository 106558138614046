import { HttpParams } from '@angular/common/http';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { Observable } from 'rxjs';
import { ApiService } from '../core/services/api.service';
import {
  IAlertAssignmentPatchObj,
  IAlertRequestItem,
  IAlertResultsDTO,
  IAlertsRequestDTO,
  IAlertsSearchAssignmentBody,
  IAlertsSearchAssignmentParams,
  IAlertsSearchAssignmentsResponse, IAuditSummaryAlert,
  ISearchAlertAssignmentsRequest,
  ISearchAlertAssignmentsResponse
} from './alert.models';
import {
  AlertMetricThresholdOperators,
  AlertReportsToAllMetrics,
} from './alert-logic/alert-logic.constants';
import {
  AlertMetricType,
  EAlertMetricChangeType,
  EAlertMetricValueComparator as EMetricValueComparator, EAlertPageSummaryMetric, EAlertPageSummaryWebVitalsMetric,
} from './alert-logic/alert-logic.enums';
import {
  IAlertMetricDefaultOperatorWithValue, IAlertOperator,
  IAlertReportMetricsConfig,
  IAlertReportToMetricsConfig
} from './alert-logic/alert-logic.models';
import { tap } from 'rxjs/operators';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { EAlertSearchSortBy, DefaultAlertAssignmentsPagination, DefaultAlertAssignmentsSorting } from './alert.constants';
import { IAlert } from '@app/components/alerts-library/alerts-library.models';
import { Router } from '@angular/router';
import { IPagination } from '../shared/components/selectable-table/selectable-table.models';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Injectable()
export class AlertService {

  private apiRoot = `${environment.apiV3Url}`;

  constructor(
    private apiService: ApiService,
    private alertReportingService: AlertReportingService,
    private router: Router,
  ) { }

  @CacheApiResponse({ liveTime: 5000 })
  getAlerts(auditId: number, runId: number, body: IAlertsRequestDTO): Observable<IAlertResultsDTO> {
    const requestUrl = `${this.apiRoot}web-audits/${auditId}/runs/${runId}/alerts`;
    return this.apiService.post(requestUrl, body);
  }

  @CacheApiResponse({ liveTime: 5000 })
  getAlertById(alertId: number): Observable<IAlertRequestItem> {
    const requestUrl = `${this.apiRoot}alerts/${alertId}`;
    return this.apiService.get(requestUrl);
  }

  createAlert(body: IAlertRequestItem, cacheKey?: string) {
    const requestUrl = `${this.apiRoot}alerts`;
    return this.apiService.post<{ id: number }>(requestUrl, body).pipe(
      tap(() => {
        cacheKey ? this.alertReportingService.removeFromCache(cacheKey) : null;
      })
    );
  }

  updateAlert(body: IAlertRequestItem, cacheKey?: string): Observable<void> {
    const requestUrl = `${this.apiRoot}alerts/${body.id}`;
    return this.apiService.put<void>(requestUrl, body).pipe(
      tap(() => {
        cacheKey ? this.alertReportingService.removeFromCache(cacheKey) : null;
      })
    );
  }

  deleteAlert(body: IAlert, cacheKey?: string): Observable<IAlertRequestItem> {
    const requestUrl = `${this.apiRoot}alerts/${body.id}`;
    return this.apiService.delete<IAlertRequestItem>(requestUrl, body);
  }

  // Subscribe to alert and add current user email to
  // list of subscribers
  subscribeToAlert(alert, alertId, email: string, cacheKey?: string) {
    const requestUrl = `${this.apiRoot}alerts/${alertId}`;
    const body = {
      ...alert,
      emails: [...alert.emails, email],
    };

    if (body.logicComparator) delete body.logicComparator;

    return this.apiService.put(requestUrl, body).pipe(
      tap(() => {
        cacheKey ? this.alertReportingService.removeFromCache(cacheKey) : null;
      })
    );
  }

  // Search data sources (audits, journeys etc) that may have specified Alert assigned or not assigned
  searchDataSources(assignments: ISearchAlertAssignmentsRequest,
    { sortBy, sortDesc } = DefaultAlertAssignmentsSorting,
    { pageSize, currentPageNumber } = DefaultAlertAssignmentsPagination): Observable<ISearchAlertAssignmentsResponse> {

    const itemType = 'AUDIT';

    const queryParams = new HttpParams()
      .set('sortBy', sortBy)
      .set('sortDesc', sortDesc)
      .set('size', pageSize)
      .set('page', currentPageNumber);

    const requestUrl = `${this.apiRoot}alerts/target-items/${itemType}/search?${queryParams.toString()}`;
    return this.apiService.post(requestUrl, assignments);
  }

  getAllAssignedDataSources(assignments: ISearchAlertAssignmentsRequest): Promise<ISearchAlertAssignmentsResponse> {
    return new Promise(async (resolve, reject) => {
      const pageSize = 1000;
      let currentPage = 0;
      let lastPage = 1;
      let dataSources: ISearchAlertAssignmentsResponse = { items: [], metadata: { pagination: {} } } as any;

      while (currentPage < lastPage) {
        const batchOfDataSources: ISearchAlertAssignmentsResponse = await this.searchDataSources(assignments, DefaultAlertAssignmentsSorting, {
          pageSize,
          currentPageNumber: currentPage
        } as IPagination).toPromise();

        if (batchOfDataSources.metadata.pagination.totalPageCount) {
          lastPage = batchOfDataSources.metadata.pagination.totalPageCount;
        } else {
          lastPage += batchOfDataSources.metadata.pagination.currentPageSize < pageSize ? 0 : 1;
        }

        dataSources.items = dataSources.items.concat(batchOfDataSources.items);
        currentPage++;
      }

      resolve(dataSources);
    });
  }

  getMetricDefaultAlertValue(metricConfig: IAlertReportMetricsConfig, currentValue: number): IAlertMetricDefaultOperatorWithValue {
    let defaultAlertValue: IAlertMetricDefaultOperatorWithValue = {
      metricType: metricConfig.value,
      value: metricConfig.defaultValue.useCurrent ? +currentValue : metricConfig.defaultValue.value,
      operator: metricConfig.defaultValue.operator,
    };

    return defaultAlertValue;
  }

  getOperatorByComparator(comparator: EMetricValueComparator): IAlertOperator {
    return AlertMetricThresholdOperators.find(operator => operator.comparator === comparator);
  }

  getAlertsForAssignment(
    { size, page, sortBy, sortDesc }: IAlertsSearchAssignmentParams,
    body: IAlertsSearchAssignmentBody
  ): Observable<IAlertsSearchAssignmentsResponse> {
    const requestUrl = `${this.apiRoot}alerts/search/assignments?`;
    const params = [];

    size ? params.push(`size=${size}`) : params.push('size=50');
    page ? params.push(`page=${page}`) : params.push('page=0');
    sortBy ? params.push(`sortBy=${sortBy}`) : params.push(EAlertSearchSortBy.ALERT_NAME);
    sortDesc !== undefined ? params.push(`sortDesc=${sortDesc}`) : params.push('sortDesc=false');

    const stringifiedParams = params.join('&');

    return this.apiService.post(`${requestUrl}${stringifiedParams}`, body);
  }

  patchAssignedAlerts(itemType: 'AUDIT', itemId: number, alerts: IAlertAssignmentPatchObj[]) {
    const requestUrl = `${this.apiRoot}alerts/assignments/${itemType}/${itemId}`;
    return this.apiService.patch(requestUrl, { assignmentUpdates: alerts });
  }

  navigateToAlert(alert: IAuditSummaryAlert | IAlertRequestItem, auditId?: number, runId?: number, preventHighlight?: boolean): void {
    const reportConfig = AlertUtils.getReportConfigByMetricType(alert.metricType);
    const noHighlight = preventHighlight === true;
    // Forcing a route change to reload the component even if we're already on the same base route. Need
    // to force the reload to match filters and highlight for the selected alert id.
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      auditId ?
        this.router.navigateByUrl(`/audit/${auditId}/run/${runId}/${reportConfig.link}?alertId=${alert.id}${noHighlight ? '&highlight=false' : ''}`)
        : this.router.navigateByUrl(`${reportConfig.link}?alertId=${alert.id}${noHighlight ? '&highlight=false' : ''}`);
    });
  }
}
