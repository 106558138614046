<div class="audit-report-header-popover" [class.popover-mode]="popoverMode" [class.mat-elevation-z5]="popoverMode">
  <canvas #confettiCanvas class="confetti-canvas"></canvas>
  <div class="popover-main-wrp">
    <no-alerts-not-subscribed *ngIf="(this.popoverView === EAuditPopoverStates.NoneConfigured
                                     || this.popoverView === EAuditPopoverStates.NotSubscribed
                                     || this.popoverView === EAuditPopoverStates.NoAlertsInAccount)
                                     && !hideNoAlertsNotAssignedMessage"
                              [viewToShow]="this.popoverView"
                              (routeToAlertsSummary)="routeToAlertsSummary()">
    </no-alerts-not-subscribed>

    <ng-container *ngIf="(isVisitorMode$ | async) === false">

      <button *ngIf="this.popoverView === EAuditPopoverStates.HasTriggeredAlerts"
        (click)="routeToAlertsSummary(true)"
        class="route-to-alerts-btn"
        type="button"
        >
        View {{ triggeredAndSubscribedAlertsCount }} Triggered Alerts I'm Subscribed to
      </button>
      
      <no-triggered-alerts *ngIf="this.popoverView === EAuditPopoverStates.NoTriggeredAlerts"
        [canvas]="confettiCanvas"
        [popoverOpen]="popoverOpen"
        [alertsCount]="alertsCount"
        (routeToAlertsSummary)="routeToAlertsSummary()">
      </no-triggered-alerts>
      
      <ng-container *ngIf="accountHasAlerts && this.popoverView === EAuditPopoverStates.NoneConfigured">
        <button class="create-new-alert-btn expanded-text" (click)="assignAlert()">
          <mat-icon>add_circle_outline</mat-icon>
          Add Existing Alerts To This Audit
        </button>
        <div class="popover-button-link-container">or <a class="popover-button-link" (click)="addAlert()">Create New Alert</a></div>
      </ng-container>
      
      <ng-container *ngIf="!accountHasAlerts || accountHasAlerts && this.popoverView !== EAuditPopoverStates.NoneConfigured">
        <button class="create-new-alert-btn" (click)="addAlert()">
          <mat-icon>add_circle_outline</mat-icon>
          Create New Alert
        </button>
      </ng-container>
    </ng-container>

  </div>
</div>
