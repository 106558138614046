import {AngularNames} from '@app/moonbeamConstants';
import * as angular from 'angular';


export class FileDropperDirective implements angular.IDirective {

    constructor(private $timeout: angular.ITimeoutService) {}

    restrict = 'A';
    scope = {
      onDrop: '&'
    };
    link = (scope, element) => {

      let fileInput = element.parent().find('#upload');
      let browseButton = element.find('.browse');

      scope.setDropEffect = (e, value) => {
        if (e.originalEvent) {
          e.originalEvent.dataTransfer.dropEffect = value;
        }
      };

      scope.getFiles = (e) => {
        if (e.originalEvent) {
          return e.originalEvent.dataTransfer.files;
        } else {
          return [];
        }
      };

      browseButton.on('click', () => {
        fileInput.trigger('click');
      });

      element.on('dragover', (event) => {
        event.preventDefault();
        scope.setDropEffect(event, 'copy');
        element.addClass('file-hover');
      });

      element.on('dragleave', (event) => {
        element.removeClass('file-hover');
        scope.setDropEffect('');
      });

      element.on('drop', (event) => {
        event.preventDefault();
        let fileList = scope.getFiles(event);
        element.removeClass('file-hover');
        this.$timeout(() => {
          scope.onDrop({ fileList });
        });
      });

    }

    static factory(): angular.IDirectiveFactory {
      const directive = ($timeout: angular.ITimeoutService) => {
        return new FileDropperDirective($timeout);
      };
      directive.$inject = [
        AngularNames.timeout
      ];
      return directive;
    };
  }


