import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const BackToMainAppRouteName = 'main_app';

export const AdminPortalRouteName = 'admin_portal';

export const ManageAccountsRouteName = `${AdminPortalRouteName}.accounts`;
export const ItemFinderRouteName = `${AdminPortalRouteName}.item_finder`;

export const TagDatabaseRouteName = `${AdminPortalRouteName}.tag_database`;
export const TagDatabaseLibraryRouteName = `${TagDatabaseRouteName}.library`;
export const TagDatabaseRequestCheckRouteName = `${TagDatabaseRouteName}.request_check`;
export const TagDatabaseViewTagRouteName = `${AdminPortalRouteName}.view_tag`;

export const VerifyAccessRouteName = `${AdminPortalRouteName}.verify_access`;
export const CleanUpRouteName = `${AdminPortalRouteName}.clean_up`;

export const SystemStatusRouteName = `${AdminPortalRouteName}.system_status`;
export const SystemStatusAuditsRouteName = `${SystemStatusRouteName}.audits`;
export const SystemStatusWebJourneysRouteName = `${SystemStatusRouteName}.web_journeys`;

export const ComponentLibraryRouteName = `${AdminPortalRouteName}.component_library`;

export const AdminPortalPaths = {
  base: 'admin',
  mainApp: 'manage/cards',
  accounts: 'accounts',
  itemFinder: 'item-finder',
  tagDatabase: 'tag-database',
  tagDatabaseLibrary: 'library',
  tagDatabaseRequestCheck: 'request-check',
  tagDatabaseViewTag: ':tagId',
  verifyAccess: 'verify-access',
  cleanUp: 'clean-up',
  systemStatus: 'system-status',
  systemStatusAudits: 'audits',
  systemStatusWebJourneys: 'web-journeys',
  componentLibrary: 'component-library',
};

export const AdminPortalUrlBuilders = {
  base: () => RouteBuilder.buildUrl([AdminPortalPaths.base]),
  mainApp: () => RouteBuilder.buildUrl([AdminPortalPaths.mainApp]),
  accounts: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.accounts]),
  itemFinder: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.itemFinder]),
  tagDatabase: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.tagDatabase]),
  tagDatabaseLibrary: () => {
    return RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.tagDatabase, AdminPortalPaths.tagDatabaseLibrary]);
  },
  tagDatabaseRequestCheck: () => {
    return RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.tagDatabase, AdminPortalPaths.tagDatabaseRequestCheck]);
  },
  tagDatabaseViewTag: (tagId: number) => {
    return RouteBuilder.buildUrl(
      [AdminPortalPaths.base, AdminPortalPaths.tagDatabase, AdminPortalPaths.tagDatabaseViewTag],
      String(tagId)
    );
  },
  verifyAccess: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.verifyAccess]),
  cleanUp: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.cleanUp]),
  systemStatus: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.systemStatus]),
  systemStatusAudits: () => {
    return RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.systemStatus, AdminPortalPaths.systemStatusAudits]);
  },
  systemStatusWebJourneys: () => {
    return RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.systemStatus, AdminPortalPaths.systemStatusWebJourneys]);
  },
};

export const AdminPortalTitles = {
  base: 'Admin Portal',
  mainApp: 'Back to Main App',
  manageAccounts: 'Admin Accounts',
  itemFinder: 'Item Finder',
  tagDatabase: 'Tag Database',
  tagDatabaseLibrary: 'Library',
  tagDatabaseRequestCheck: 'Request check',
  tagDatabaseViewTag: 'Tag Database',
  verifyAccess: 'Verify Access',
  cleanUp: 'Clean Up',
  systemStatus: 'System Status',
  systemStatusAudits: 'Audits',
  systemStatusWebJourneys: 'Web Journeys',
  home: 'Home',
  general: 'General',
  misc: 'Miscellaneous',
  componentLibrary: 'Component Library',
};
