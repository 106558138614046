import { auditReducer } from './auditReducer';
import { domainReducer } from './domainReducer';
import { folderReducer } from './folderReducer';
import { labelReducer } from './labelReducer';
import { auditPageDataReducer } from './auditPageDataReducer';

export interface IReduxAction<T> {
  type: string,
  payload: T
}

export const getReducers = (): any => {
  return {
    audits: auditReducer,
    domains: domainReducer,
    folders: folderReducer,
    labels: labelReducer,
    auditPageData: auditPageDataReducer
  };
};
