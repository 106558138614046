<div class="section-title-wrap">
  <div class="title-spinner-wrap">
    <p class="section-title">Tag Load Times (ms)</p>
    <filter-spinner [state]="tableState"></filter-spinner>
  </div>

  <div class="tag-view-toggle">
    <percent-numeric-toggle tooltipPercent="Show percentage view"
                            tooltipNumber="Show numerical view"
                            (toggleChanged)="viewSelected($event)"
    ></percent-numeric-toggle>
  </div>
</div>
<div class="tags-table-wrap">
  <table
    class="tags-table"
    mat-table
    matSort
    multiTemplateDataRows
    matSortActive="numberOfRequests"
    matSortDirection="desc"
    matSortStart="desc"
    [class.updating]="tableState === 'loading'"
    [dataSource]="dataSource"
  >
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let tag" (click)="expand(tag)">
        <mat-icon
          class="expand-arrow"
        >keyboard_arrow_right
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>TAG NAME</th>
      <td mat-cell *matCellDef="let tag" (click)="expand(tag)">
        <div class="name-container">
          <div class="tag-image">
            <img [src]="tag.tagIcon"/>
          </div>
          <div>{{ tag.name }}</div>
          <div class="tag-actions">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu="matMenu" class="tags-list-menu">
            <button
              mat-menu-item
              (click)="filterByTag(tag)"
              class="tag-menu-item"
            >
              <mat-icon>tune</mat-icon>
              Filter all reports w/ {{tag.name}} tag
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="numberOfAccounts">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt"># OF<br>ACCOUNTS</th>
      <td mat-cell *matCellDef="let tag" class="center-txt" (click)="expand(tag)">
        {{ tag.numberOfAccounts | number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="averageRequestSize">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt">AVG. TAG SIZE<br>(bytes)</th>
      <td mat-cell *matCellDef="let tag" class="center-txt" (click)="expand(tag)">
        {{ tag.averageRequestSize | number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="numberOfRequests">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt"># OF TAG<br>REQUESTS</th>
      <td mat-cell *matCellDef="let tag" class="center-txt" (click)="expand(tag)">
        {{ tag.numberOfRequests | number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="below500">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt green">BELOW 500<br>(ms)</th>
      <td mat-cell *matCellDef="let tag"
          class="center-txt load-time-bucket-clickable-cell"
          (click)="onLoadTimeBucketSelected(tag, undefined, 'Below500')"
          [class.load-time-bucket-selected-cell]="isTagLoadTimeBucketSelected(tag, 'Below500')">
        <div class="load-time-bucket-value">
          {{tag.below500}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="from500to1000">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt yellow">500-999<br>(ms)</th>
      <td mat-cell *matCellDef="let tag"
          class="center-txt load-time-bucket-clickable-cell"
          (click)="onLoadTimeBucketSelected(tag, undefined, '500to1000')"
          [class.load-time-bucket-selected-cell]="isTagLoadTimeBucketSelected(tag, '500to1000')">
        <div class="load-time-bucket-value">
          {{tag.from500to1000}}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="from1000to2000">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt orange">1000-1999<br>(ms)</th>
      <td mat-cell *matCellDef="let tag"
          class="center-txt load-time-bucket-clickable-cell"
          (click)="onLoadTimeBucketSelected(tag, undefined, '1000to2000')"
          [class.load-time-bucket-selected-cell]="isTagLoadTimeBucketSelected(tag, '1000to2000')">
        <div class="load-time-bucket-value">
          {{ tag.from1000to2000 }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="above2000">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt red">ABOVE 2000<br>(ms)</th>
      <td mat-cell *matCellDef="let tag"
          class="center-txt load-time-bucket-clickable-cell"
          (click)="onLoadTimeBucketSelected(tag, undefined, '2000andAbove')"
          [class.load-time-bucket-selected-cell]="isTagLoadTimeBucketSelected(tag, '2000andAbove')">
        <div class="load-time-bucket-value">
          {{ tag.above2000 }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="statusCodeDistribution">
      <th mat-header-cell *matHeaderCellDef class="center-txt">TAG STATUS CODES</th>
      <td mat-cell *matCellDef="let tag" class="center-txt">
        <status-code-indicators
          [payload]="formatDataForStatusCodeIndicators(tag.statusCodeDistribution)"
          [tooltip]="true"
          (filter)="filterByStatusCode($event, tag)"
        ></status-code-indicators>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let tag" [attr.colspan]="columns.length">
        <div class="element-detail" [@detailExpand]="tag === expandedElement ? 'expanded' : 'collapsed'">
        <span class="spinner-wrap" *ngIf="accountListLoading">
          <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
        </span>
          <div class="accounts" *ngIf="!!tag.tagAccounts && !accountListLoading">
            <div class="account expanded-row"
                  *ngFor="let account of tag.tagAccounts; trackBy: trackFn"
                  [class.row-selected]="isTagAccountRowSelected(tag, account)"
            >
              <div class="account-name">

                <div class="name-container">
                  <div class="acc">
                    <span class="account-name-item"
                          [matTooltip]="account.name"
                          matTooltipPosition="above">{{ account.name }}</span>
                    <div class="tag-actions tag-actions-small">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    </div>
                  </div>

                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      class="tag-menu-item"
                      (click)="filterByAccAndTag(tag, account)"
                    >
                      <mat-icon>tune</mat-icon>
                      Filter report by this item
                    </button>
                  </mat-menu>

                </div>
              </div>
              <div class="account-cell"></div>
              <div class="account-cell">{{ account.averageRequestSize | number }}</div>
              <div class="account-cell">{{ account.numberOfRequests | number }}</div>
              <div class="load-time-bucket-clickable-cell account-cell"
                   (click)="onLoadTimeBucketSelected(tag, account, 'Below500')"
                   [class.load-time-bucket-selected-cell]="isTagAccountLoadTimeBucketSelected(tag, account, 'Below500')">
                <div class="load-time-bucket-value">
                  {{ account.below500 }}
                </div>
              </div>
              <div class="load-time-bucket-clickable-cell account-cell"
                   (click)="onLoadTimeBucketSelected(tag, account, '500to1000')"
                   [class.load-time-bucket-selected-cell]="isTagAccountLoadTimeBucketSelected(tag, account, '500to1000')">
                <div class="load-time-bucket-value">
                  {{ account.from500to1000 }}
                </div>
              </div>
              <div class="load-time-bucket-clickable-cell account-cell"
                   (click)="onLoadTimeBucketSelected(tag, account, '1000to2000')"
                   [class.load-time-bucket-selected-cell]="isTagAccountLoadTimeBucketSelected(tag, account, '1000to2000')">
                <div class="load-time-bucket-value">
                  {{ account.from1000to2000 }}
                </div>
              </div>
              <div class="load-time-bucket-clickable-cell account-cell"
                   (click)="onLoadTimeBucketSelected(tag, account, '2000andAbove')"
                   [class.load-time-bucket-selected-cell]="isTagAccountLoadTimeBucketSelected(tag, account, '2000andAbove')">
                <div class="load-time-bucket-value">
                  {{ account.above2000 }}
                </div>
              </div>
              <div>
                <status-code-indicators
                  [payload]="formatDataForStatusCodeIndicators(account.statusCodeDistribution)"
                  [tooltip]="true"
                  (filter)="filterByStatusCode($event, tag, account)"
                ></status-code-indicators>
              </div>
            </div>
            <div class="accounts-more" *ngIf="accountListLength > 20">
              Use an export to see the complete list.
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let tag; columns: columns;"
        class="request-row"
        [class.expanded-row]="expandedElement === tag"
        [class.row-selected]="isTagRowSelected(tag) || isTagAccountRowSelectedAndCollapsed(tag)"
    ></tr>
    <tr mat-row *matRowDef="let tag; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
</div>
