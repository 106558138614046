import { IEventManager } from '@app/components/eventManager/eventManager';
import { Injectable } from '@angular/core';
import { Names, Events } from '@app/moonbeamConstants';
import { userIsOPAdmin } from '../../../authUtils';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import {
  AuthenticationStorageService,
  IAuthorizationData
} from '@app/components/core/services/authentication-storage.service';
import { IUser } from '@app/moonbeamModels';
import { AdminPortalUrlBuilders } from '@app/components/admin-portal/admin-portal.constants';
import { Router } from '@angular/router';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { AccountsService } from '@app/components/account/account.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  AdminService,
  IAuditResult,
  ISimulationResult
} from '@app/components/admin-portal/system-status/admin.service';

export enum EDataSelectorType {
  audits,
  web,
}

enum EItemFinderResultField {
  audits = 'audits',
  simulations = 'simulations',
}

@Injectable({
  providedIn: 'root'
})
export class SwitchAccountService {

  itemTypeToResultFieldMap: Map<EDataSelectorType, EItemFinderResultField> = new Map([
    [EDataSelectorType.audits, EItemFinderResultField.audits],
    [EDataSelectorType.web, EItemFinderResultField.simulations],
  ]);

  itemTypeToIdFieldMap: Map<EDataSelectorType, string> = new Map([
    [EDataSelectorType.audits, 'id'],
    [EDataSelectorType.web, 'id'],
  ]);

  constructor(private router: Router,
              private authService: AuthenticationService,
              private accountsService: AccountsService,
              private authStorageService: AuthenticationStorageService,
              private adminService: AdminService,
              private storageService: StorageService,
              private eventManager: IEventManager) {
  }

  attemptSwitchAccounts(id: number, type: EDataSelectorType): Observable<boolean> {
    const isLoggedInAsAnother = this.storageService.isLoggedInAsAnother();
    if (isLoggedInAsAnother) {
      this.returnToOriginalAuth();
      this.eventManager.publish(Events.loggedInAsAnother);

      return of(false);
    }

    return this.accountsService
      .getUser()
      .pipe(switchMap(user => this.loginAsAnother(user, id, type)));
  }

  private returnToOriginalAuth(): void {
    const original = this.storageService.getValue<IAuthorizationData>(
      Names.GlobalStateKeys.authorization, StorageType.Local
    );
    this.authStorageService.returnToOriginalAuth();
    this.authService.setAuthInfoCookies(original);
  }

  private loginAsAnother(user: IUser, id: number, type: EDataSelectorType): Observable<boolean> {
    if (!userIsOPAdmin(user)) {
      this.authService.logout();
    }

    const resultField = this.itemTypeToResultFieldMap.get(type);
    const idField = this.itemTypeToIdFieldMap.get(type);

    return this.adminService.findItems(String(id))
      .pipe(
        switchMap(results => {
          const items = results[resultField] as Array<IAuditResult | ISimulationResult>;

          if (items) {
            const found = items.find(x => x[idField] === id);
            if (found) {
              const accountId = found.accountId;
              return this.adminService.login(
                user.accountId,
                accountId,
                this.router.url,
                AdminPortalUrlBuilders.accounts()
              );
            }
          }

          return of(false);
        }));
  }

}
