import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {
  ETagColumnNames,
  ETagColumnSortDirection,
  ETagsExpansionOPData,
  ETagView,
  ITagInfoDTO,
  ITagInfoDTOWithInsights,
  ITagInfoRelatedCookie,
  ITagInsights,
  ITagInstance,
  ITagInstanceOPData,
  ITagVariable,
} from '@app/components/audit-reports/page-details-tags/page-details-tags.constants';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { PageDetailsReportService } from '../page-details/page-details.service';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { SimpleRuleCreatorComponent } from '@app/components/rules/simple-rule-creator/simple-rule-creator.component';
import { ISimpleRuleCreatorPayload } from '@app/components/rules/simple-rule-creator/simple-rule-creator.model';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Features, Names } from '@app/moonbeamConstants';
import { AccountsService } from '@app/components/account/account.service';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { TAGS_SEARCH_TEXT_KEY } from '../audit-report/audit-report.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-details-tags',
  templateUrl: './page-details-tags.component.html',
  styleUrls: ['./page-details-tags.component.scss'],
})
export class PageDetailsTagsComponent implements OnInit, OnChanges, OnDestroy {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;
  private destroy$ = new Subject();

  ETagsExpansionOPData = ETagsExpansionOPData;
  ETagColumnNames = ETagColumnNames;
  ETagView = ETagView;

  @Input() itemId: number;
  @Input() runId: number;
  @Input() pageId: string;
  @Input() reportType: EReportType;
  @Input() activeTab?: EPageDetailsTabs; // specific to audits
  @Input() isScrolled = false;
  @Input() accountFeatures: Features[] = [];
  @Input() state?: any;
  @Output() navToTab: EventEmitter<{ tab: EPageDetailsTabs, searchValue: string, updateActiveTab?: boolean }> = new EventEmitter();

  private allTagData: ITagInfoDTO[] = []; // All tag data existing for audit for tag view
  private allTagInstances: ITagInstance[] = []; // All tag instances flattened out into single array for the request view
  private filterChanged$ = new ReplaySubject<string>();
  private readonly requestViewFilteredOPHeaders = {
    showAccount: true,
    showStatusCode: true,
    showTagSize: true,
    showLoadTime: true,
    showDuplicateRequest: true,
    showMultipleRequest: true,
  };

  loading: boolean;
  sortingBy = ETagColumnNames.tagName;
  sortDirection = ETagColumnSortDirection.asc;
  tagInsights: ITagInsights;
  currentView = ETagView.tagView;

  filteredTagsData: ITagInfoDTO[] = []; // All tag data filtered by search string
  filteredInstanceData: ITagInstance[] = [];  // All tag instance data filtered by search string
  filterString = '';

  searchPlaceholder = 'Filter by Tag, Variable or Variable Value';
  userIsReadOnly: boolean;

  constructor(
    private pageDetailsService: PageDetailsReportService,
    private changeDetectorRef: ChangeDetectorRef,
    private discoveryAuditService: DiscoveryAuditService,
    private opModalService: OpModalService,
    private modalEscapeService: ModalEscapeService,
    private accountsService: AccountsService,
    private storageService: StorageService
  ) {
    this.userIsReadOnly = this.accountsService.userIsReadOnly();
  }

  ngOnInit() {
    this.filterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.filterData(filter);
      this.filterString = filter;
      this.changeDetectorRef.detectChanges();
    });

    this.checkForPreferredView();
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    const {itemId, runId, pageId, reportType, activeTab, state} = simpleChanges;

    if (itemId || runId || pageId || reportType || activeTab || state) {

      this.loading = true;

      this.getTagsData()
        .pipe(finalize(() => this.loading = false))
        .subscribe(tagsInfo => {
          this.tagInsights = tagsInfo.pageTagInsights;
          tagsInfo.pageTags = ArrayUtils.sortBy(tagsInfo.pageTags, 'name');

          this.allTagData = PageDetailsTagsComponent.processTagsData(tagsInfo.pageTags)
            .map(tag => {
              tag.requestViewFilteredOPHeaders = this.requestViewFilteredOPHeaders;
              tag.pageTagInstances = tag.pageTagInstances.map(instance => {
                return {...instance, originalDuplicates: instance.duplicates};
              });

              return tag;
            });

          this.filteredTagsData = [...this.allTagData];
          this.allTagInstances = this.getAllTagInstances();

          this.filterData(this.filterString);
          this.externalToInternalState();
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  debounceFilterData(value) {
    this.filterChanged$.next(value);
  }

  /**
   * Used to sort data by a particular column heading
   * @param columnName - the column header we want to sort by
   */
  sortTagData(columnName: ETagColumnNames) {
    this.updateSortTagState(columnName);

    this.filteredTagsData.sort(this.filteredTagsDataSort.bind(this));
    this.filteredInstanceData.sort(this.filteredInstanceDataSort.bind(this));
  }

  /**
   * Sort the filtered tag data in ascending or descending value for the
   * current sortingBy column
   */
  filteredTagsDataSort(a: ITagInfoDTO, b: ITagInfoDTO) {
    switch (this.sortingBy) {
      // Alphabetical sort on tag name
      case ETagColumnNames.tagName:
        if (this.sortDirection === ETagColumnSortDirection.asc) {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        }
        return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;

      // Alphabetical sort on category name
      case ETagColumnNames.tagCategory:
        if (this.sortDirection === ETagColumnSortDirection.asc) {
          return a.category.toLowerCase() < b.category.toLowerCase() ? -1 : 1;
        }
        return a.category.toLowerCase() < b.category.toLowerCase() ? 1 : -1;

      // Sort status codes in this order:
      //  Ascending = Green, Yellow, Red
      //  Descending = Red, Yellow, Green
      case ETagColumnNames.tagStatusCode:
        if (this.currentView === ETagView.tagView) {
          if (this.sortDirection === ETagColumnSortDirection.asc) {
            return a.statusCounts.fail < b.statusCounts.fail ? -1 :
              a.statusCounts.warning < b.statusCounts.warning ? -1 : 1;
          }
          return a.statusCounts.fail < b.statusCounts.fail ? 1 :
            a.statusCounts.warning < b.statusCounts.warning ? 1 : -1;

          // Need to sort at the page instance level for request view
        } else {
          if (this.sortDirection === ETagColumnSortDirection.asc) {
            return a.statusCounts.fail < b.statusCounts.fail ? -1 :
              a.statusCounts.warning < b.statusCounts.warning ? -1 : 1;
          }
          return a.statusCounts.fail < b.statusCounts.fail ? 1 :
            a.statusCounts.warning < b.statusCounts.warning ? 1 : -1;
        }

      // Sort by total number of requests
      case ETagColumnNames.tagRequests:
        if (this.sortDirection === ETagColumnSortDirection.asc) {
          return a.tagRequestCount < b.tagRequestCount ? -1 : 1;
        }
        return a.tagRequestCount < b.tagRequestCount ? 1 : -1;
    }
  }

  /**
   * Sort the flattened instance data in ascending or descending value for the
   * current sortingBy column
   */
  filteredInstanceDataSort(a: ITagInstance, b: ITagInstance) {
    switch (this.sortingBy) {
      // Alphabetical sort on tag name
      case ETagColumnNames.tagName:
        if (this.sortDirection === ETagColumnSortDirection.asc) {
          return a.tagName < b.tagName ? -1 : 1;
        }
        return a.tagName < b.tagName ? 1 : -1;

      // Alphabetical sort on category name
      case ETagColumnNames.tagCategory:
        if (this.sortDirection === ETagColumnSortDirection.asc) {
          return a.category < b.category ? -1 : 1;
        }
        return a.category < b.category ? 1 : -1;

      // Sort status codes in this order:
      //  Ascending = Green, Yellow, Red
      //  Descending = Red, Yellow, Green
      case ETagColumnNames.tagStatusCode:
        if (this.sortDirection === ETagColumnSortDirection.asc) {
          return a.statusCode < b.statusCode ? -1 : 1;
        } else if (this.sortDirection === ETagColumnSortDirection.desc) {
          return a.statusCode < b.statusCode ? 1 : -1;
        }

        break;
    }
  }

  updateSortTagState(columnName) {
    if (this.sortingBy === columnName) {
      this.sortDirection = this.sortDirection === ETagColumnSortDirection.asc
        ? ETagColumnSortDirection.desc
        : ETagColumnSortDirection.asc;

      return;
    } else {
      this.sortDirection = ETagColumnSortDirection.asc;
    }

    this.sortingBy = columnName;
  }

  // Filter the sorted tag data by search string
  //  - any tag names that match are returned with all their variables included
  //  - any tag names that do not match are returned with any of their variables that match name or value
  filterData(value) {
    if (!value) {
      // Used to populate Tag View
      this.filteredTagsData = [...this.allTagData];

      // Used to populate Request View, flattened pageInstances from this.filteredTagsData
      this.filteredInstanceData = [...this.allTagInstances];
      return;
    }

    // Filter all tags by tags with matching tag name or matching variables
    let filteredTagsData = JSON.parse(JSON.stringify(this.allTagData)).map(tag => {
      // If tag name matches, return entire tag with all variables
      if (tag.name?.toLowerCase().includes(value.toLowerCase())) {
        tag.requestViewFilteredOPHeaders = {
          showAccount: true,
          showStatusCode: true,
          showTagSize: true,
          showLoadTime: true,
          showDuplicateRequest: true,
          showMultipleRequest: true,
        };
        return tag;
      }

      /**
       * this code allows for searching for variables, not just tag name
       * --
       * previously a bug was filed (https://observepoint.atlassian.net/browse/WORK-25957)
       * because this code allows for tags to appear in search results whose names don't
       * match the search term -- this is expected behavior.
       */

      if (tag.pageTagInstances?.length) {
        // Filter each pageTagInstance by filter string (op data and variable data)
        tag.requestViewFilteredOPHeaders = {
          showAccount: false,
          showStatusCode: false,
          showTagSize: false,
          showLoadTime: false,
          showDuplicateRequest: false,
          showMultipleRequest: false,
        };

        // Filter tag instances (requests) using filter string.
        //  - Check if OP Data header or value matches
        //  - Check if Variable name or value matches
        //  - Set flag at tag level to show any matching instance level OP data rows
        let filteredPageTagInstancesVariableData = tag.pageTagInstances.map((instance: ITagInstance) => {

          // Filter OP Data by filter string
          let filteredInstanceOPData = this.filterInstanceOPDataForTagView(value, instance);
          let hasFilteredInstanceOPData = Object
            .values(filteredInstanceOPData)
            .filter(item => item)
            .length > 0;

          tag.requestViewFilteredOPHeaders.showAccount = !tag.requestViewFilteredOPHeaders.showAccount
            && filteredInstanceOPData.account !== null;

          tag.requestViewFilteredOPHeaders.showStatusCode = !tag.requestViewFilteredOPHeaders.showStatusCode
            && filteredInstanceOPData.statusCode !== null;

          tag.requestViewFilteredOPHeaders.showTagSize = !tag.requestViewFilteredOPHeaders.showTagSize
            && filteredInstanceOPData.bytes !== null;

          tag.requestViewFilteredOPHeaders.showLoadTime = !tag.requestViewFilteredOPHeaders.showLoadTime
            && filteredInstanceOPData.loadTime !== null;

          tag.requestViewFilteredOPHeaders.showDuplicateRequest = !tag.requestViewFilteredOPHeaders.showDuplicateRequest
            && filteredInstanceOPData.duplicates !== null;

          tag.requestViewFilteredOPHeaders.showMultipleRequest = !tag.requestViewFilteredOPHeaders.showMultipleRequest
            && filteredInstanceOPData.multiples !== null;

          // Filter variable names and values by filter string
          let filteredTagInstanceVariables = instance.tagInstanceVariables && instance.tagInstanceVariables.filter((variable: ITagVariable) => {
            const nameIncludesFilter = !!variable.name && variable.name.toLowerCase().includes(value.toLowerCase());
            const valueIncludesFilter = !!variable.value && variable.value.toLowerCase().includes(value.toLowerCase());

            return nameIncludesFilter || valueIncludesFilter;
          });

          let filteredInstance = {
            ...instance,
            ...filteredInstanceOPData,
            account: instance.account,
            tagInstanceVariables: filteredTagInstanceVariables || []
          };

          return hasFilteredInstanceOPData || filteredTagInstanceVariables && filteredTagInstanceVariables.length > 0 ? filteredInstance : null;
        }).filter(item => !!item);

        if (filteredPageTagInstancesVariableData?.length) {
          tag.pageTagInstances = filteredPageTagInstancesVariableData;
        }

        return filteredPageTagInstancesVariableData?.length > 0 ? tag : null;
      }
    }).filter(item => !!item);

    this.filteredTagsData = PageDetailsTagsComponent.processTagsData(filteredTagsData);
    this.filteredInstanceData = this.getAllTagInstances();
  }

  filterInstanceOPDataForTagView(filterString: string, instance: ITagInstanceOPData): ITagInstanceOPData {
    return {
      account: instance.account && instance.account.toLowerCase().includes(filterString.toLowerCase()) || this.ETagsExpansionOPData.ACCOUNT.includes(filterString) ? instance.account : null,
      statusCode: ('' + instance.statusCode).includes(filterString) || this.ETagsExpansionOPData.STATUS_CODE.includes(filterString) ? instance.statusCode : null,
      bytes: ('' + instance.bytes).includes(filterString) || this.ETagsExpansionOPData.TAG_SIZE.includes(filterString) ? instance.bytes : null,
      loadTime: ('' + instance.loadTime).includes(filterString) || this.ETagsExpansionOPData.LOAD_TIME.includes(filterString) ? instance.loadTime : null,
      duplicates: ('' + instance.duplicates).includes(filterString) || this.ETagsExpansionOPData.DUPLICATES.includes(filterString) ? instance.duplicates : null,
      multiples: ('' + instance.multiples).includes(filterString) || this.ETagsExpansionOPData.MULTIPLES.includes(filterString) ? instance.multiples : null,
    };
  }

  getTagsData(): Observable<ITagInfoDTOWithInsights> {
    return this.pageDetailsService.getAuditPageTags(this.itemId, this.runId, this.pageId);
  }

  viewSelected(view: ETagView) {
    // set the view
    this.currentView = view;

    // save the selected view to localStorage
    this.storageService.setValue(Names.GlobalStateKeys.pageDetailsTagsView, view, StorageType.Local);
  }

  /**
   * Return a single array with all instances across all tags for use in requests view
   */
  getAllTagInstances(): ITagInstance[] {
    return this.filteredTagsData.map(tag => {
      return tag.pageTagInstances = tag.pageTagInstances.map(instance => ({
        ...instance,
        tagName: tag.name,
        tagId: tag.tagId,
        category: tag.category,
      }));
    }).flat();
  }

  createRule(event: MouseEvent, tag: ITagInstance) {
    event.stopPropagation();

    const originTag = this.getOriginTagByInstanceId(tag.tagInstanceId);
    fromPromise(this.discoveryAuditService.getAudit(this.itemId))
      .subscribe((rules) => {
        const index = this.modalEscapeService.getLast() + 1;
        this.modalEscapeService.add(index);

        this.opModalService
          .openModal(SimpleRuleCreatorComponent, {
            data: {
              journeyId: this.itemId,
              tag: {
                account: tag.account,
                tagId: tag.tagId,
                tagName: tag.tagName,
                tagInstanceId: tag.tagInstanceId,
                variables: tag.tagInstanceVariables,
              },
              rules: rules.rules,
              variables: tag.tagInstanceVariables,
              bindTo: 'AUDIT'
            } as ISimpleRuleCreatorPayload
          })
          .afterClosed()
          .subscribe(() => this.modalEscapeService.remove(index));
      });
  }

  makeStatusCodeIndicatorPayload(tag: ITagInfoDTO) {
    return {
      broken: tag.statusCounts?.fail,
      redirect: tag.statusCounts?.warning,
      good: tag.statusCounts?.pass
    };
  }

  getOriginTagByInstanceId(tagInstanceId: string) {
    return this.allTagInstances.find(t => t.tagInstanceId === tagInstanceId);
  }

  navToCookiesTab(cookie: ITagInfoRelatedCookie): void {
    this.navToTab.emit({
      tab: EPageDetailsTabs.Cookies,
      searchValue: `${cookie.name} ${cookie.domain}`,
      updateActiveTab: true,
    });
  }

  /**
   * Main transform of the tagsData response
   *  - Calculates requests and status values for each instance
   *  - Builds titles using these counts
   *  - Builds a variable map to use when display expanded view of each tag/request
   */
  private static processTagsData(tagsData: ITagInfoDTO[]): ITagInfoDTO[] {

    // Calculate request type and status totals for each tag
    return PageDetailsTagsComponent
      .sortTags(tagsData)
      .map(tag => {
        let statusCounts = {pass: 0, fail: 0, warning: 0};

        const variableSet = new Set();
        const accountSet = new Set();
        const accountList = [];
        let variableList = [];
        let multipleRequestTypeCounter = 1;

        tag.pageTagInstances.forEach((request, i) => {
          accountSet.add(request.account);
          let tagInstanceVariableValueMap = {};

          // Add a title (with count) property for use within column display
          // Duplicate request processing
          if (request.duplicates > 0) {
            request.title = `${request.duplicates} Duplicate Request${request.duplicates === 1 ? '' : 's'}`;
          }

          // Unique request processing
          if (request.duplicates === 0 && request.multiples === 0) {
            request.title = `Unique Request`;
          }

          // Multiple request processing
          if (request.multiples > 0) {
            const previousAccountIsMultiple = i && tag.pageTagInstances[i - 1].multiples;
            const previousAccountIsSame = i && tag.pageTagInstances[i - 1].account === request.account;

            // Reset counter if prior request wasn't a multiple or had a different account
            if (!previousAccountIsMultiple || !previousAccountIsSame) {
              multipleRequestTypeCounter = 1;
            }

            request.title = `Multiple Request ${multipleRequestTypeCounter}`;
            multipleRequestTypeCounter++;
          }

          // Pull out each variable name to create a deduped list of all variables for each tag
          // Sort the variables for tag single instance
          request.tagInstanceVariables = ArrayUtils.collatorCompare(request.tagInstanceVariables, a => a['name'], ESortDirection.asc);
          request.tagInstanceVariables.forEach(variable => {
            if (!tagInstanceVariableValueMap.hasOwnProperty(variable.name)) {
              tagInstanceVariableValueMap[variable.name] = {};
            }

            tagInstanceVariableValueMap[variable.name][request.tagInstanceId] = variable.value === '' ? 'No value' : variable.value;

            return variableSet.add(variable.name);
          })

          request.tagInstanceVariableValueMap = tagInstanceVariableValueMap;

          statusCounts.pass += request.statusCode === null || (request.statusCode >= 200 && request.statusCode < 300) ? 1 : 0;
          statusCounts.warning += request.statusCode >= 300 && request.statusCode < 400 ? 1 : 0;
          statusCounts.fail += request.statusCode >= 400 || request.statusCode === 0 ? 1 : 0;
          request.statusCodeTooltip = request.statusCode === 0
            ? `${PageStatusCodeTooltipMap[request.statusCode]} Some status code 0s indicate that a tag did not finish loading before we completed scanning the page.`
            : PageStatusCodeTooltipMap[request.statusCode] || null;
        });

        // Add an array of all variable names found in all request instances for each tag
        for (let entry of variableSet) variableList.push(entry);
        for (let entry of accountSet) accountList.push(entry);

        // Sort the variables for the whole tag (not single instance)
        variableList = ArrayUtils.collatorCompare(variableList, a => a, ESortDirection.asc);
        tag.variableList = variableList;
        tag.accountList = accountList;

        return {...tag, statusCounts};
      });
  }

  /**
   * Sort all instances by duplicate -> multiple -> neither (unique)
   * @param tags
   */
  static sortTags(tags: ITagInfoDTO[]): ITagInfoDTO[] {
    tags.forEach((tag: ITagInfoDTO) => {
      tag.pageTagInstances.sort((a, b) => {
        if (a.duplicates < b.duplicates) {
          return 1;
        } else if (a.duplicates > b.duplicates) {
          return -1;
        } else {
          // if multiples, then sort by multiples, then account
          if (a.multiples && !b.multiples) {
            return -1;
          }
          if (b.multiples && !a.multiples) {
            return 1;
          }
          // if both NOT or both ARE multiples, then by account
          if (a.account < b.account) return -1;
          if (a.account > b.account) return 1;
          else return 0;
        }
      });
    });

    return tags;
  }

  private externalToInternalState(): void {
    this.state = this.state || {};

    if (this.state.hasOwnProperty(TAGS_SEARCH_TEXT_KEY)) {
      this.debounceFilterData(this.state[TAGS_SEARCH_TEXT_KEY] || '');
    }
  }

  checkForPreferredView(): void {
    const preferredView: ETagView = this.storageService.getValue(Names.GlobalStateKeys.pageDetailsTagsView, StorageType.Local);
    if (preferredView) {
      this.currentView = preferredView;
    }
  }
}
