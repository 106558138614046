import { Injectable, OnDestroy } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '@app/components/core/services/api.service';
import {
  ITagPrivacyComplianceResponse,
  ITagPrivacyPagesResponse,
  ITagPrivacyPagesTagApiBody,
  ITagPrivacySorting,
  ITagPrivacySummary,
  ITagPrivacyTrends,
  ITagPrivacyTrendsOverviews
} from './privacy-tags.models';
import { TagPrivacyTrendName } from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.constants';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { takeUntil } from 'rxjs/operators';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable({ providedIn: 'root' })
export class PrivacyTagsService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getTagPrivacySummary(auditId: number, runId: number, body?: IAuditReportApiPostBody): Observable<ITagPrivacySummary> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-privacy`, body);
  }

  getTagPrivacyTrends(auditId: number, runId: number): Observable<ITagPrivacyTrendsOverviews> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-privacy/trends`);
  }

  getTagPrivacyTrend(auditId: number, trendName: TagPrivacyTrendName, days: number): Observable<ITagPrivacyTrends> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/reports/tag-privacy/trends/${trendName}?days=${days}`);
  }

  getCompliance(
    auditId: number,
    runId: number,
    metadata: ITagPrivacySorting,
    body?: IAuditReportApiPostBody
  ): Observable<ITagPrivacyComplianceResponse> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-privacy/compliance`;
    const queryParams = `?page=${metadata.page}&size=${metadata.size}&sortBy=${metadata.sortBy}&sortDesc=${metadata.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }

  getPages(
    auditId: number,
    runId: number,
    metadata: ITagPrivacySorting,
    body?: IAuditReportApiPostBody
  ): Observable<ITagPrivacyPagesResponse> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-privacy/pages`;
    const queryParams = `?page=${metadata.page}&size=${metadata.size}&sortBy=${metadata.sortBy}&sortDesc=${metadata.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }

  getTagPages(
    auditId: number,
    runId: number,
    metadata: ITagPrivacySorting,
    body?: ITagPrivacyPagesTagApiBody
  ): Observable<ITagPrivacyPagesResponse> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-privacy/tag-pages`;
    const queryParams = `?page=${metadata.page}&size=${metadata.size}&sortBy=${metadata.sortBy}&sortDesc=${metadata.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }
}

