<ng-container *ngFor="let button of buttons">
  <!-- Transparent button without borders -->
  <button mat-button
          *ngIf="button.transparent"
          (click)="button.action()"
          [class.hidden]="button.hidden"
          [attr.op-selector]="button.opSelector"
          [disabled]="button.disabled">
    <span *ngIf="button.icon" class="modal-footer-button-icon" [ngClass]="button.icon"></span>
    <span>{{ button.label }}</span>
  </button>

  <!-- Yellow button with borders -->
  <button mat-flat-button
          color="primary"
          *ngIf="button.primary"
          (click)="button.action()"
          [class.hidden]="button.hidden"
          [attr.op-selector]="button.opSelector"
          [disabled]="button.disabled"
          class="modal-footer-button">
    <span *ngIf="button.icon" [ngClass]="button.icon"></span>
    <span>{{ button.label }}</span>
  </button>

  <!-- Grey button with borders -->
  <button mat-flat-button
          *ngIf="!button.transparent && !button.primary"
          (click)="button.action()"
          [class.hidden]="button.hidden"
          [attr.op-selector]="button.opSelector"
          [disabled]="button.disabled"
          class="modal-footer-button modal-footer-button-secondary">
    <span *ngIf="button.icon" class="modal-footer-button-icon" [ngClass]="button.icon"></span>
    <span>{{ button.label }}</span>
  </button>
</ng-container>