import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAlertDataSourcesTableItem } from '../../alert-data-sources/alert-data-sources.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-preview-data-source-selector',
  templateUrl: './alert-preview-data-source-selector.component.html',
  styleUrls: ['./alert-preview-data-source-selector.component.scss']
})
export class AlertPreviewDataSourceSelectorComponent {

  @Input() dataSources: IAlertDataSourcesTableItem[];
  @Input() selectedDataSource: IAlertDataSourcesTableItem;

  @Output() dataSourceSelected = new EventEmitter<number>();

}
