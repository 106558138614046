import { Component, Input, OnChanges } from '@angular/core';
import { EBrowserRedirectType } from '../page-details/page-details.enums';
import { IAuditPageInfo } from '../page-details/page-details.models';
import { IPageRedirectPage } from './page-redirects.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-redirects',
  templateUrl: './page-redirects.component.html',
  styleUrls: ['./page-redirects.component.scss']
})
export class PageRedirectsComponent implements OnChanges {

  @Input() pageInfo: IAuditPageInfo;

  EBrowserRedirectType = EBrowserRedirectType;
  isExpanded = false;

  initialPage: IPageRedirectPage;
  redirects: IPageRedirectPage[];
  finalPage: IPageRedirectPage;

  ngOnChanges() {
    if (this.pageInfo) {
      const titleWidth = this.pageInfo.redirects?.length ? 76 : 23;

      this.initialPage = {
        title: this.pageInfo.redirects?.length ? 'INITIAL PAGE:' : 'URL',
        titleWidth,
        statusCode: this.pageInfo.initialStatusCode,
        url: this.pageInfo.initialPageUrl,
        isRedirect: false
      };
      this.redirects = (this.pageInfo.redirects ?? [])
        .filter((_, index) => index !== 0)
        .map(({ statusCode, url, type: redirectType }) => ({
          title: 'REDIRECT:',
          titleWidth,
          statusCode,
          url,
          isRedirect: true,
          redirectType
        }));
      if (this.pageInfo.redirects?.length) {
        this.finalPage = {
          title: 'FINAL PAGE:',
          titleWidth,
          statusCode: this.pageInfo.finalStatusCode,
          url: this.pageInfo.finalPageUrl,
          isRedirect: false
        };
      }

      this.isExpanded = this.redirects.length === 1;
    }
  }

  getToggleRedirectsTooltipText(): string {
    const mode = this.isExpanded ? 'Hide' : 'View';
    return `${mode} all ${this.pageInfo.redirects.length} redirects`;
  }

  copyAllRedirects() {
    let buffer = this.initialPage.url;
    this.redirects.forEach(redirect => buffer += '\n' + redirect.url);
    buffer += '\n' + this.finalPage.url;

    navigator.clipboard.writeText(buffer);
  }
}
