<div class="file-mapping-results flex-col">
  <div class="mapping-count">Mapping #{{ index + 1 }}</div>
  <div class="file-link" *ngIf="!!remoteFile">
    <mat-chip-listbox class="file-link">
      <mat-chip-option>
        <mat-icon>insert_drive_file</mat-icon> <a (click)="goToLink(remoteFile)">{{ fileName }}</a>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  <div class="match-status flex-row flex-center-items" [ngClass]="statusClass">
    <mat-icon>{{statusIcon}}</mat-icon> {{ statusText }}
  </div>
  <div class="results flex-col">
    <div *ngFor="let request of requests">
      {{ request }}
    </div>
    <div *ngIf="!!remoteFile && (!requests || requests.length < 1)">
      {{ matchValue }}
    </div>
  </div>
</div>