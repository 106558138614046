<div [formGroup]="formGroup">
  <alert-trigger
    [modalType]="modalType"
    [readOnlyLabel]="readOnlyLabel"
    [formGroup]="triggerFormGroup"
    [menuContext]="menuContext">
  </alert-trigger>
  <op-alert-filters
    *ngIf="filterType === EAlertFilterType.V1"
    [modalType]="modalType"
    [readOnlyLabel]="readOnlyLabel"
    [formControl]="filtersFormControl"
    [metricType]="metricTypeFormControl.value">
  </op-alert-filters>
  <usage-alert-filters
    *ngIf="filterType === EAlertFilterType.V2"
    [modalType]="modalType"
    [readOnlyLabel]="readOnlyLabel"
    [formControl]="filtersFormControl">
  </usage-alert-filters>
</div>
