<div class="flex-row action-preview"
     [ngClass]="{'not-dragged': !draggedMode}"
     [class.isAuditAction]="[actionCreatorMode.AuditActions, actionCreatorMode.AuditUserSession].includes(mode)"
     (click)="onSelect.emit(action)"
     (mouseover)="onHover()">
  <span class="sequence">{{index}}</span>
  <div class="flex-col flex-stretch" [matTooltip]="actionTypesWithTooltip.includes(action.type) ? (action | actionPreviewInfo) : null" matTooltipPosition="above" matTooltipShowDelay="1000">
    <span class="action-info flex-stretch">
      {{action | actionPreviewInfo}}
    </span>
    <span class="action-label flex-stretch" [class.default]="!action.label">
      {{action.label || 'unnamed'}}
    </span>
  </div>
  <mat-icon *ngIf="!draggedMode && !actionSetCreationMode && canDelete" class="delete-action" (click)="deleteAction($event, action)">delete</mat-icon>
  <mat-checkbox (click)="$event.stopPropagation()"
                (change)="onActionSelected.emit($event.checked)"
                [disabled]="action.type === 'actionSet'"
                color="primary"
                *ngIf="actionSetCreationMode && (action.type !== actionType.ActionSet)"
                class="select-action-checkbox">
  </mat-checkbox>
</div>
<span *ngIf="!draggedMode && !actionSetCreationMode" class="insert-action toggle-fade">
  <mat-icon mat-flat-button (click)="insertAction($event, action)">add</mat-icon>
</span>
