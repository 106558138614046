import { Injectable, OnDestroy } from '@angular/core';
import { forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { IUser } from '@app/moonbeamModels';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { IAccountPreview } from '@app/components/core/services/authentication.models';
import { ExternalLinksService } from '@app/components/shared/services/external-links.service';
import { AccountsService } from '@app/components/account/account.service';
import { ApiService } from '@app/components/core/services/api.service';
import { environment } from '@app/environments/environment';
import { IAccountOverview } from '@app/components/upgrade-user-banner/upgrade-user-banner.models';

@Injectable()
export class UpgradeUserBannerService implements OnDestroy {

  accountPreview$: Observable<IAccountPreview>;
  apiRoot = environment.apiV3Url;
  isVisitorMode: boolean;

  private userSubject = new ReplaySubject<IUser>(1);
  private destroySubject = new Subject<void>();

  constructor(private externalLinksService: ExternalLinksService,
              private applicationChromeService: ApplicationChromeService,
              private apiService: ApiService,
              private accountsService: AccountsService) {

    this.accountPreview$ = this.applicationChromeService.accountPreview$.pipe(
      filter(accountPreview => !!accountPreview),
      takeUntil(this.destroySubject)
    );

    this.applicationChromeService.isVisitorMode$.pipe(
      takeUntil(this.destroySubject)
    ).subscribe(isVisitorMode => {
      this.isVisitorMode = isVisitorMode;
    });

    this.accountsService.getUser().subscribe(user => this.userSubject.next(user));

  }
  
  ngOnDestroy() {
    this.userSubject.complete();

    this.destroySubject.next();
    this.destroySubject.complete();
  }

  openUpgradeUserPage() {
    forkJoin([
      this.applicationChromeService.accountPreview$.pipe(filter(accountPreview => !!accountPreview), take(1)),
      this.userSubject.pipe(take(1))
    ]).subscribe(([accountPreview, user]) => {
      if (accountPreview.accountType === EAccountType.SAMPLE) {
        this.externalLinksService.openUpgradeToFreeTrial(accountPreview.id, accountPreview.accountType, user.id, accountPreview.partner?.id);
      } else if (accountPreview.accountType === EAccountType.FREETRIAL) {
        this.externalLinksService.openUpgradeToPaid(accountPreview.id, accountPreview.accountType, user.id, accountPreview.partner?.id);
      } else if (this.isVisitorMode) {
        this.externalLinksService.openStartFreeTrial();
      }
    });
  }

  getUsageData(): Observable<any> {
    return this.apiService.get(this.apiRoot + 'usage-summary');
  }

  getUsageDataV3(): Observable<IAccountOverview> {
    return this.apiService.get(this.apiRoot + 'usage/overview');
  }
}
