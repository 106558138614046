import { ComponentChanges } from '@app/models/commons';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, OnDestroy } from '@angular/core';
import { forkJoin, Subject, ReplaySubject } from 'rxjs';
import { debounceTime, skip, take, takeUntil } from 'rxjs/operators';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { ILabel } from '@app/components/shared/services/label.service';
import { IOpFilterBarV2Filter, IOpFilterBarV2MenuItem } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EFilterBarV2MenuTypes, FilterRemovedEvent } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.constants';
import { ISearchByTextEmissionData } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { AlertDataSourcesFilterBarService } from './alert-data-sources-filter-bar.service';
import { EAlertDataSourcesFilterTypes } from './alert-data-sources-filter-bar.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-data-sources-filter-bar',
  templateUrl: './alert-data-sources-filter-bar.component.html',
  styleUrls: ['./alert-data-sources-filter-bar.component.scss']
})
export class AlertDataSourcesFilterBarComponent implements OnInit, OnChanges, OnDestroy {

  @Input() searchByTextPlaceholderSuffix: string;
  @Input() labels: ILabel[];
  @Input() readOnlyLabel: string;

  @Output() filtersChanged = new EventEmitter<IOpFilterBarV2Filter<EAlertDataSourcesFilterTypes>[]>();

  validFilterTypes: EAlertDataSourcesFilterTypes[];
  filterBarMenuItems: IOpFilterBarV2MenuItem[] = [];
  filters: IOpFilterBarV2Filter<string>[];

  folders: IFolder[] = [];
  domains: IDomain[] = [];
  private labelsSubject = new ReplaySubject<ILabel[]>(1);
  folderMenuItems: IOpFilterBarV2MenuItem[];
  labelMenuItems: IOpFilterBarV2MenuItem[];

  folderSearchTextUpdated: number;
  labelSearchTextUpdated: number;

  folderSubFolderCheckedStatusMap: { [id: number]: boolean } = {};
  labelCheckedStatusMap: { [id: number]: boolean } = {};

  filterRemovedEventSubscription: number;
  private destroySubject = new Subject<void>();

  constructor(public filterBarService: AlertDataSourcesFilterBarService,
              private foldersService: IFoldersApiService,
              private domainsService: IDomainsService,
              private eventManager: IEventManager) { }

  async ngOnInit(): Promise<void> {
    this.getValidFilterTypes();
    await this.getFilters();
    await this.getData();
    this.buildMenu();
    this.subscribeToEvents();
  }

  ngOnChanges(changes: ComponentChanges<AlertDataSourcesFilterBarComponent>) {
    if (changes.labels.currentValue && changes.labels.currentValue !== changes.labels.previousValue) {
      this.labelsSubject.next(changes.labels.currentValue);
    }
  }

  ngOnDestroy() {
    this.labelsSubject.complete();

    this.destroySubject.next();
    this.destroySubject.complete();

    this.eventManager.unSubscribe(FilterRemovedEvent, this.filterRemovedEventSubscription);
  }

  subscribeToEvents(): void {
    this.filterRemovedEventSubscription = this.eventManager.subscribe(FilterRemovedEvent, () => {
      setTimeout(() => {
        this.generateMenuItems();
        this.buildMenu();
      });
    });

    this.filterBarService.filters$
      .pipe(
        takeUntil(this.destroySubject),
        skip(1),
        debounceTime(100)
      )
      .subscribe(filters => this.filtersChanged.emit(filters));
  }

  getValidFilterTypes(): void {
    this.filterBarService.validFilterTypesUpdated$
      .pipe(takeUntil(this.destroySubject))
      .subscribe((validFilterTypes: EAlertDataSourcesFilterTypes[]) => {
        this.validFilterTypes = validFilterTypes;
      });
  }

  getFilters(): Promise<void> {
    return new Promise<void>(resolve => {
      this.filterBarService.filters$
        .pipe(takeUntil(this.destroySubject))
        .subscribe((filters: IOpFilterBarV2Filter<EAlertDataSourcesFilterTypes>[]) => {
          this.filters = filters;
          this.filtersChanged.emit(filters)
          this.createFolderCheckedStatusMap();
          this.createLabelCheckedStatusMap();
          resolve();
        });
    });
  }

  private getData(): Promise<void> {
    return new Promise<void>((resolve) => {
      forkJoin([
        this.foldersService.getFolders(true, true),
        this.domainsService.getDomains(true),
        this.labelsSubject.pipe(take(1))
      ])
      .subscribe(([folders, domains, _]) => {
        this.folders = folders;
        this.domains = domains;

        this.generateMenuItems();
        resolve();
      });
    });
  }

  private buildMenu() {
    this.filterBarMenuItems = [
      {
        name: 'Folder & Sub-folder',
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'folder',
        children: [
          {
            name: 'search',
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search by folder or sub-folder',
            searchAllowsMultipleSelections: true,
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleFolderAndSubfolderSearch(event, 0, el),
            children: []
          } as IOpFilterBarV2MenuItem
        ].concat(this.folderMenuItems)
      },
      {
        name: 'Labels',
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'label',
        children: [
          {
            name: 'Labels',
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search by label name',
            searchAllowsMultipleSelections: true,
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleLabelSearch(event, el),
            children: []
          } as IOpFilterBarV2MenuItem
        ].concat(this.labelMenuItems)
      }
    ]
  }

  createFolderCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.folderSubFolderCheckedStatusMap = {};
      return;
    }

    this.filters.forEach((filter: IOpFilterBarV2Filter<string>) => {
      if (filter.type !== EAlertDataSourcesFilterTypes.Folder) return;

      if (filter.menuItems) {
        // filter.menuItems is the folder
        // menuItems.children is the subfolder (domain)
        filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
          if (item.type !== EFilterBarV2MenuTypes.Checkbox) return;
          this.folderSubFolderCheckedStatusMap[item.id] = item.checked;

          if (item.children?.length) {
            item.children.forEach((child: IOpFilterBarV2MenuItem) => {
              this.folderSubFolderCheckedStatusMap[child.id] = child.checked;
            });
          }
        });
      }
    });
  }

  createLabelCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.labelCheckedStatusMap = {};
      return;
    }

    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.type === EAlertDataSourcesFilterTypes.Label);

    if (!filter) {
      this.labelCheckedStatusMap = {};
      return;
    }

    filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
      this.labelCheckedStatusMap[item.id] = item.checked;
    });
  }

  generateMenuItems(): void {
    if (!this.labels) return;

    this.labelMenuItems = this.generateLabelMenuItems();
    this.folderMenuItems = this.organizeDomainsAndFolders(this.domains, this.folders);
  }

  generateLabelMenuItems(): IOpFilterBarV2MenuItem[] {
    return this.labels.map((label: ILabel) => {
      return {
        id: label.id,
        name: label.name,
        checked: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
        type: EFilterBarV2MenuTypes.Checkbox,
        displayWhen: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
          item.displayWhen = checked;
          this.filterBarService.handleLabelFilter(checked, item, menuItems);
        },
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.filterBarService.handleLabelFilter(item.checked, item, menuItems, true);
        }
      }
    });
  }

  private organizeDomainsAndFolders(domains: IDomain[], folders: IFolder[]): IOpFilterBarV2MenuItem[] {
    const domainsToFoldersMap = {};

    domains.forEach((domain: IDomain) => {
      if (!domainsToFoldersMap[domain.folderId]) {
        domainsToFoldersMap[domain.folderId] = [];
      }

      domainsToFoldersMap[domain.folderId].push({
        id: domain.id,
        folderId: domain.folderId,
        name: domain.name,
        type: EFilterBarV2MenuTypes.Checkbox,
        hideSelectedOnSearch: false,
        displayWhen: true,
        checked: this.getCheckedStateById(this.folderSubFolderCheckedStatusMap, domain.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, parent: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
          this.handleFolderCheckedState(item, checked, parent);
          this.filterBarService.handleFolderFilter(checked, item, menuItems, false, this.folders, domainsToFoldersMap);
        },
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.filterBarService.handleFolderFilter(item.checked, item, menuItems, true, this.folders, domainsToFoldersMap);
        }
      });
    });

    const grouped = folders
      .map((folder: IFolder) => {
        return {
          id: folder.id,
          name: folder.name,
          type: EFilterBarV2MenuTypes.Checkbox,
          checked: this.getCheckedStateById(this.folderSubFolderCheckedStatusMap, folder.id),
          hideSelectedOnSearch: false,
          children: domainsToFoldersMap[folder.id] || [],
          displayWhen: true,
          action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
            this.handleDomainCheckedState(checked, item);
            this.filterBarService.handleFolderFilter(checked, item, menuItems, false, this.folders, domainsToFoldersMap);
          },
          onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
            this.handleOnlyButtons(item, menuItems, parent);
            this.filterBarService.handleFolderFilter(item.checked, item, menuItems, true, this.folders, domainsToFoldersMap);
          }
        };
      })
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    return grouped;
  }

  private getCheckedStateById(mapName: { [id: number]: boolean }, itemId: number): boolean {
    return mapName[itemId] !== undefined ? mapName[itemId] : false;
  }

  handleLabelSearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.labelSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.labelSearchTextUpdated + 200) {
      try {
        el.focus();
      } catch(e) {}

      return;
    }

    // filter labels that include search value
    const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.labelMenuItems.map((item: IOpFilterBarV2MenuItem) => {
      item.displayWhen = value
        ? item.name.toLowerCase().includes(value)
        : item.checked;

        return item;
    });
  }

  handleSearchByName({ value}: ISearchByTextEmissionData): void {
    this.filterBarService.handleNameContainsFilter(value);
  }

  handleFolderAndSubfolderSearch(event: KeyboardEvent, index: number, el?: HTMLElement): void {
    this.folderSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.folderSearchTextUpdated + 200) {
      el.focus();
      return;
    }

    const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

    this.folderMenuItems.forEach((folder: IOpFilterBarV2MenuItem) => {
      folder.children.forEach((subFolder: IOpFilterBarV2MenuItem) => {
        subFolder.displayWhen = value ? subFolder.name.toLowerCase().includes(value) : true;
      });

      const folderNameMatchesSearchValue = folder.name.toLowerCase().includes(value);
      const folderHasVisibleSubfolder = !!folder.children.map(domain => domain.displayWhen).filter(domain => domain).length;
      const folderHasSubfolders = !!folder.children.length;

      folder.displayWhen = value
        ? folderNameMatchesSearchValue || folderHasVisibleSubfolder
        : folderHasSubfolders;
    });

    // i don't like this but it's the only way to update a nested array
    // that's also being passed to a child component as an input
    this.filterBarMenuItems[index].children = [this.filterBarMenuItems[index].children[0]].concat(this.folderMenuItems);

    // trigger change detection
    this.filterBarMenuItems = [ ...this.filterBarMenuItems ];
  }

  handleOnlyButtons(item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem): void {
    // deselect everything
    this.deselectAllMenuItems(menuItems);

    // set checked = true to item
    item.checked = true;

    // handle child elements if they exist
    if (item.children?.length) {
      item.children.forEach((child: IOpFilterBarV2MenuItem) => child.checked = true);
    }
  }

  deselectAllMenuItems(arr: IOpFilterBarV2MenuItem[]): void {
    arr.forEach((menuItem: IOpFilterBarV2MenuItem) => {
      menuItem.checked = false;

      if (menuItem.children?.length) {
        this.deselectAllMenuItems(menuItem.children);
      }
    });
  }

  // Triggered when checking a subfolder's checkbox
  handleFolderCheckedState(item, checked: boolean, parent: IOpFilterBarV2MenuItem): void {
    // Update the check state for clicked subfolder checkbox
    parent.children.find(subfolder => {
      return subfolder.id === item.id;
    }).checked = checked;

    // Update folder check state to reflect if all subfolders are selected or not
    let allSubfoldersChecked = true;
    if (checked) {
      parent.children.forEach(subfolder => {
        if (!subfolder.checked) {
          allSubfoldersChecked = false;
        }
      });
    } else {
      allSubfoldersChecked = false;
    }

    parent.checked = allSubfoldersChecked;
  }

  handleDomainCheckedState(checked: boolean, item: IOpFilterBarV2MenuItem): void {
    item.children?.forEach(child => {
      child.checked = checked;
    });
  }

}
