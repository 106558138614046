import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { IAuditDataService } from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { Router } from '@angular/router';
import { WebJourneyReportUrlBuilders } from '@app/components/web-journey-report/web-journey-report.constants';
import { EWebJourneyStatus } from './web-journey-success-snackbar.constants';
import { EWebJourneyFrequency } from '@app/components/web-journey/web-journey.constants';
import { StorageService } from '@app/components/shared/services/storage.service';
import { ERecurrencePresetNames } from '@app/components/shared/components/op-recurrence/op-recurrence.constants';

@Component({
  selector: 'op-web-journey-success-snackbar',
  templateUrl: './web-journey-success-snackbar.component.html',
  styleUrls: ['./web-journey-success-snackbar.component.scss']
})
export class WebJourneySuccessSnackbarComponent implements OnInit {
  EWebJourneyStatus = EWebJourneyStatus;
  message: string;
  showRunNowBtn: boolean;
  webJourneyStatus: EWebJourneyStatus;

  recurrenceEnabled: boolean = false;

  constructor(
    private router: Router,
    private snackBarRef: MatSnackBarRef<WebJourneySuccessSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public journeyData,
    private dateService: DateService,
    private storageService: StorageService
  ) {
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
  }

  ngOnInit(): void {
    this.setData();
  }

  runWebJourneyNow(): void {
    this.journeyData.rightFooterButtons[1].action(this.journeyData.journey);
    this.closeSnackbar();
    this.router.navigateByUrl(this.router.url);
  }

  createAnotherWebJourney(): void {
    this.journeyData?.rightFooterButtons[0]?.action();
  }

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }

  setData(): void {
    if (this.recurrenceEnabled) {
      if (this.journeyData.isRunning) { // web journey is scheduled to run now
        this.webJourneyStatus = EWebJourneyStatus.Running;
        this.showRunNowBtn = false;
      } else if (this.journeyData.journey?.schedule?.isPaused) { // web journey is paused
        this.webJourneyStatus = EWebJourneyStatus.Paused;
        this.showRunNowBtn = true;
      } else if (this.journeyData.journey?.schedule?.presetType === ERecurrencePresetNames.Unscheduled) { // web journey is unscheduled
        this.webJourneyStatus = EWebJourneyStatus.Unscheduled;
        this.showRunNowBtn = true;
      } else { // web journey is scheduled to run future
        this.webJourneyStatus = EWebJourneyStatus.Scheduled;
        this.showRunNowBtn = true;
      }
    } else {
      if (this.journeyData.isRunning) { // web journey is scheduled to run now
        this.webJourneyStatus = EWebJourneyStatus.Running;
        this.showRunNowBtn = false;
      } else if (this.journeyData.journey.frequency === EWebJourneyFrequency.PAUSED) { // web journey is paused
        this.webJourneyStatus = EWebJourneyStatus.Paused;
        this.showRunNowBtn = true;
      } else { // web journey is scheduled to run future
        this.webJourneyStatus = EWebJourneyStatus.Scheduled;
        this.showRunNowBtn = true;
      }
    }
  }

  getRunDate(): string {
    return this.dateService.formatDate(new Date(this.journeyData.journey.nextCheck), EDateFormats.dateNineteen);
  }

  goToWebJourney() {
    this.router.navigateByUrl(WebJourneyReportUrlBuilders.base(this.journeyData.journey.id, 0));
    this.closeSnackbar();
  }
}
