<div class="op-standards-selector">

  <!-- Available Standards (left side) -->
  <div class="left-col column">
    <div class="above-col-text">All {{ templateStrings.plural }} ({{ availableStandards.length }})</div>
    <div class="col-border">

      <!-- left column header -->
      <div class="left-col-header">
        <div class="left-col-search">
          <mat-icon class="search-icon">search</mat-icon>
          <input class="search-input"
                 type="text"
                 placeholder="Search by {{ templateStrings.singular }} or label name"
                 [(ngModel)]="searchValue"
                 (keyup)="searchStandards()" />
        </div>
        <div class="left-col-table-header">
          <button (click)="toggleSort()" class="left-side">
            {{ templateStrings.singular }} Name
            <mat-icon class="sort-arrow-icon" [class.sort-asc]="sortAsc">arrow_right_alt</mat-icon>
          </button>
          <div class="right-side">
            Labels
          </div>
        </div>
      </div>

      <!-- loading spinner -->
      <div *ngIf="loading" class="loading-state">
        <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
      </div>

      <!-- zero state: no standards in account -->
      <div *ngIf="!standards.length && !loading" class="zero-state">
        <div class="no-items-text">
          Your account currently doesn't have any {{ templateStrings.plural }} created.
          Add {{ templateStrings.plural }} to this {{ productContextStrings.singular }}
          and they can be reused on any other audits and journeys.
        </div>
        <button *ngIf="!standardsWithCreateMenu.includes(standardsType)"
                (click)="createStandard()"
                class="new-item-btn">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="btn-text">Create New {{ templateStrings.createBtn }}</span>
        </button>
        <button *ngIf="standardsWithCreateMenu.includes(standardsType)"
                [matMenuTriggerFor]="menu"
                class="new-item-btn">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="btn-text">Create New {{ templateStrings.createBtn }}</span>
        </button>
      </div>

      <!-- zero state: no match when searching -->
      <div *ngIf="standards.length && !loading && !availableStandards.length" class="zero-state">
        <div class="no-items-text">
          There are no items to be displayed with these filters
        </div>
      </div>

      <!-- left column scroll area-->
      <div *ngIf="standards.length && !loading && this.availableStandards.length" class="left-col-scroll-area scroll-area">
        <div *uiScroll="let item of availableStandardsDatasource;">
          <op-standards-selector-row [standard]="item"
                                     [availableConsentCatType]="availableConsentCatType"
                                     (addToList)="addToList($event)"
                                     (editStandard)="editStandard($event)">
          </op-standards-selector-row>
        </div>
      </div>

      <!-- left column footer -->
      <div class="left-col-footer col-footer">
        <button (click)="createStandard()"
                *ngIf="!standardsWithCreateMenu.includes(standardsType)"
                class="create-new-btn"
        >
          <mat-icon class="create-new-plus-icon">add_circle_outline</mat-icon>
          Create New {{ templateStrings.createBtn }}
        </button>
        <button *ngIf="standardsWithCreateMenu.includes(standardsType)"
                class="create-new-btn"
                [matMenuTriggerFor]="menu"
        >
          <mat-icon class="create-new-plus-icon">add_circle_outline</mat-icon>
          Create New {{ templateStrings.createBtn }}
        </button>
        <button (click)="addAll()" class="add-all-standards-btn" [disabled]="!availableStandards.length || hasConflictingConsentCatTypes ? true : null">
          add all
          <mat-icon class="add-all-arrow-icon">
            arrow_circle_up
          </mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- Selected Standards (right side) -->
  <div class="right-col column">
    <div class="above-col-text">{{ templateStrings.plural }} To Be Assigned ({{ selectedStandards.length }})</div>
    <div class="col-border">

      <!-- zero state -->
      <div class="zero-state" *ngIf="!selectedStandards.length">
        <div class="no-items-text">
          Add {{ templateStrings.plural }} to this {{ productContextStrings.singular }} by choosing them from the left or
        </div>
        <button *ngIf="!standardsWithCreateMenu.includes(standardsType)"
                (click)="createStandard()"
                class="new-item-btn">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="btn-text">Create New {{ templateStrings.createBtn }}</span>
        </button>
        <button *ngIf="standardsWithCreateMenu.includes(standardsType)"
                [matMenuTriggerFor]="menu"
                class="new-item-btn">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="btn-text">Create New {{ templateStrings.createBtn }}</span>
        </button>
      </div>

      <!-- right column scroll area -->
      <div *ngIf="selectedStandards.length" class="right-col-scroll-area scroll-area">
        <div *uiScroll="let item of selectedStandardsDatasource;">
          <op-standards-selector-row [standard]="item"
                                     [isSelected]="true"
                                     [availableConsentCatType]="availableConsentCatType"
                                     (removeFromList)="removeFromList($event)"
                                     (editStandard)="editStandard($event)">
          </op-standards-selector-row>
        </div>
      </div>

      <!-- right column footer -->
      <div class="right-col-footer col-footer">
        <button (click)="removeAll()" class="remove-all-btn" [disabled]="!selectedStandards.length ? true : null">
          Remove all
          <mat-icon class="remove-all-icon">highlight_off</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item
          *ngFor="let button of createStandardMenus[standardsType]"
          (click)="button.action()"
  >
    <span>{{button.label}}</span>
  </button>
</mat-menu>
