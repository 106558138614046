<component-template componentName="Chip Manager">
  <component-library-section sectionName="Empty Example" selector="<op-chip-manager>">
    <p>This is the chip manager component. While primarily used as the backbone of the label manager, it can accomodate other item types besides labels.</p>

    <div class="chip-manager-example">
      <op-chip-manager [allChips]="chips"
                       [selectedChips]="example1SelectedChips"
                       chipType="User"
                       (onChipSelected)="example1OnChipSelected($event)"
                       (onChipRemoved)="example1OnChipRemoved($event)"
                       (onChipCreated)="example1OnChipCreated($event)"
      ></op-chip-manager>
    </div>
  </component-library-section>

  <component-library-section sectionName="Filled Example" selector="<op-chip-manager>">
    <op-chip-manager [allChips]="chips"
                     [selectedChips]="example2SelectedChips"
                     chipType="User"
                     (onChipSelected)="example2OnChipSelected($event)"
                     (onChipRemoved)="example2OnChipRemoved($event)"
                     (onChipCreated)="example2OnChipCreated($event)"
    ></op-chip-manager>
  </component-library-section>

  <component-library-section sectionName="Compact Empty Example" selector='<op-chip-manager [compactView]="true">'>
    <op-chip-manager [allChips]="chips"
                     [selectedChips]="example3SelectedChips"
                     [compactView]="true"
                     chipType="User"
                     (onChipSelected)="example3OnChipSelected($event)"
                     (onChipRemoved)="example3OnChipRemoved($event)"
                     (onChipCreated)="example3OnChipCreated($event)"
    ></op-chip-manager>
  </component-library-section>

  <component-library-section sectionName="Compact Filled Example" selector='<op-chip-manager [compactView]="true">'>
    <op-chip-manager [allChips]="chips"
                     [selectedChips]="example4SelectedChips"
                     [compactView]="true"
                     chipType="User"
                     (onChipSelected)="example3OnChipSelected($event)"
                     (onChipRemoved)="example3OnChipRemoved($event)"
                     (onChipCreated)="example3OnChipCreated($event)"
    ></op-chip-manager>
  </component-library-section>

  <component-library-section sectionName="Read-only Example" selector='<op-chip-manager [compactView]="true" [isReadOnly]="true">'>
    <op-chip-manager [allChips]="chips"
                     [selectedChips]="example5SelectedChips"
                     [compactView]="true"
                     [isReadOnly]="true"
                     chipType="User"
                     (onChipSelected)="example3OnChipSelected($event)"
                     (onChipRemoved)="example3OnChipRemoved($event)"
                     (onChipCreated)="example3OnChipCreated($event)"
    ></op-chip-manager>
  </component-library-section>

  <component-library-section sectionName="Chip Count Only Example" selector='<op-chip-manager [compactView]="true" [isReadOnly]="true" [chipCountOnly]="true">'>
    <op-chip-manager [allChips]="chips"
                     [selectedChips]="example5SelectedChips"
                     [compactView]="true"
                     [isReadOnly]="true"
                     [chipCountOnly]="true"
                     chipType="User"
                     (onChipSelected)="example3OnChipSelected($event)"
                     (onChipRemoved)="example3OnChipRemoved($event)"
                     (onChipCreated)="example3OnChipCreated($event)"
    ></op-chip-manager>
  </component-library-section>
</component-template>