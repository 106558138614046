import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ISidebarLink, ISidebarLinkChildRoute } from '../sidebar.models';
import { Router } from '@angular/router';
import { ComponentChanges } from '@app/models/commons';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { ExportCenterUrlBuilders } from '@app/components/export-center/export-center.constants';
import { MatMenuTrigger } from '@angular/material/menu';
import { IUser } from '@app/moonbeamModels';
import {
  admin, guest,
  opAdmin,
  opSysAdmin,
  standardUser,
  userIsAdmin, userIsGuest,
  userIsOPAdmin,
  userIsOPSysAdmin,
  userIsStandard
} from '@app/authUtils';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sidebar-link',
  templateUrl: './sidebar-link.component.html',
  styleUrls: ['./sidebar-link.component.scss']
})
export class SidebarLinkComponent implements OnInit, OnChanges {
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  enteredSidebarLink = false;
  isMatMenuOpen = false;
  prevSidebarLinkTrigger;
  menuPositioningClass = '';
  isOpened = false;
  isJustOpened = false;

  private readonly RoutePathsToAvoidMatchingExactly = [
    DataSourcesUrlBuilders.sources(),
    ExportCenterUrlBuilders.base()
  ];

  @Input() sectionLink: ISidebarLink;
  @Input() accountFeatures: string[];
  @Input() sidebarIsClosed: boolean;
  @Input() user: IUser;
  @Input() shiftUp: boolean = false;
  @Input() customLinkClass: string = '';
  @Input() customMenuLinkClass: string = '';
  @Input() darkTheme: boolean = true;
  @Input() isMobile: boolean = false;
  @Input() showFlyout: boolean = false;
  @Input() drawer: MatSidenav;

  @Output() openMenu = new EventEmitter<SidebarLinkComponent>();

  constructor(private router: Router, public cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.overrideSidebarStateOnMobile();
    this.menuPositioningClass = this.getMenuPositioning(this.sidebarIsClosed);
  }

  ngOnChanges(changes: ComponentChanges<SidebarLinkComponent>){
    if (changes.sidebarIsClosed) {
      this.menuPositioningClass = this.getMenuPositioning(changes.sidebarIsClosed.currentValue);
    }
  }

  private overrideSidebarStateOnMobile(): void {
    if (this.isMobile) {
      this.sidebarIsClosed = false;
    }
  }

  getMenuPositioning(sidebarIsClosed) {
    let menuClass = `${sidebarIsClosed ? 'sidebar-closed' : ''}`;

    menuClass = `${this.customMenuLinkClass ? this.customMenuLinkClass + ' ' + menuClass : menuClass}`;

    return `sidebar-mat-menu ${menuClass}`;
  }

  menuEnter() {
    this.isMatMenuOpen = true;
  }

  menuLeave(trigger) {
    setTimeout(() => {
      if (!this.enteredSidebarLink) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80);
  }

  sidebarLinkEnter(trigger?) {

    if (this.isOpened) {
      this.isOpened = false;
      this.matMenuTrigger.closeMenu();
      return;
    }

    this.isJustOpened = true;
    setTimeout(() => this.isJustOpened = false, 500);

    this.isOpened = true;
    this.matMenuTrigger.openMenu();
    // Don't do any menu interactions when no subitems if the sidebar is open
    if ((!this.sectionLink.subitems || this.sectionLink.subitems.length === 0) && !this.sidebarIsClosed) {
      return;
    }

    setTimeout(() => {

      if (this.prevSidebarLinkTrigger && this.prevSidebarLinkTrigger != trigger) {
        this.prevSidebarLinkTrigger.closeMenu();
        this.prevSidebarLinkTrigger = trigger;
        this.isMatMenuOpen = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen) {
        this.enteredSidebarLink = true;
        this.prevSidebarLinkTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredSidebarLink = true;
        this.prevSidebarLinkTrigger = trigger;
      }
    });
  }

  menuItemClick(link?: ISidebarLinkChildRoute): void {
    if (link) {
      if (typeof link.clickAction === 'function') {
        link.clickAction();
      } else {
        this.router.navigateByUrl(link.routePath);
      }
    }

    this.drawer?.close();
  }

  isActive(path: string): boolean {
    return this.router.isActive(path, !this.RoutePathsToAvoidMatchingExactly.includes(path));
  }

  hasActiveChild(subitems: ISidebarLinkChildRoute[][]): boolean {
    if (!subitems) return;
    return subitems.map(subsection =>
      subsection.map(subitem => this.router.isActive(subitem.routePath, !this.RoutePathsToAvoidMatchingExactly.includes(subitem.routePath))).includes(true)
    ).includes(true);
  }

  // Used to get the correct color of icon that was injected into mat-icon registry
  getCustomIcon(customIconBase, active) {
    const gray = 'gray';
    const darkGray = 'dark_gray';
    const white = 'white';
    const yellow = 'yellow';

    return `${customIconBase}_${active ? yellow : (this.darkTheme ? (this.isOpened ? white : gray) : darkGray)}`;
  }

  closeMenu() {
    if (!this.isJustOpened) {
      this.isOpened = false;
      this.matMenuTrigger?.closeMenu();
    }
  }

  hasRequiredPermission(link: ISidebarLinkChildRoute) {
    switch (link.requiredPermission) {
      case guest: return userIsGuest(this.user);
      case standardUser: return userIsStandard(this.user);
      case admin: return userIsAdmin(this.user);
      case opAdmin: return userIsOPAdmin(this.user);
      case opSysAdmin: return userIsOPSysAdmin(this.user);
      default: return true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.overrideSidebarStateOnMobile();
  }
}
