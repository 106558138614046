import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICreateNewAccountModalData } from './create-new-account.models';
import { IKeyValue } from '@app/moonbeamModels';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AccountsService } from '@app/components/account/account.service';
import { AdminAccountsService } from '../../admin-accounts.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  IAdminAccount,
  ICreateAccountRequest,
  IScriptServicesConfig
} from '@app/components/admin-portal/manage-accounts/manage-accounts.constants';
import { EAccountType } from '@app/components/core/services/authentication.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'create-new-account',
  templateUrl: './create-new-account.component.html',
  styleUrls: ['./create-new-account.component.scss']
})
export class CreateNewAccountComponent implements OnInit {

  newAccountForm: UntypedFormGroup;
  statuses: IKeyValue<number, string>[];
  account: IAdminAccount;
  saving: boolean = false;
  editWhenSaved: boolean = false;

  rightFooterButtons: IButton[] = [
    {
      label: 'Create Account & Close',
      action: () => this.createAccount(),
      primary: true
    },
    {
      label: 'Create Account & Edit',
      action: () => this.createAccount(true),
      primary: true
    }
  ];

  private destroy$: Subject<void> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<CreateNewAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICreateNewAccountModalData,
    private fb: UntypedFormBuilder,
    private accountsService: AccountsService,
    private adminAccountsService: AdminAccountsService,
    private snackbar: MatSnackBar
  ) {
    this.statuses = this.data.statuses;
  }

  ngOnInit(): void {
    this.initForm();
    this.handleUsernameCheck();
  }

  private initForm(): void {
    this.newAccountForm = this.fb.group({
      name: ['', Validators.required],
      accountType: [EAccountType.CONTRACT],
      firstName: [''],
      lastName: [''],
      username: ['', Validators.required],
      email: ['', [Validators.required, OPValidators.emails]],
      domain: ['', [OPValidators.url]],
      dataLayer: [''],
      status: [2, Validators.required],
      maxDomains: [1, Validators.required],
      maxMonitoredJourneys: [2, Validators.required],
      ssJourneyFixes: [10, Validators.required],
      auditPageLimit: [1000, [Validators.required, Validators.min(1), Validators.max(2 ** 31)]],
      webJourneyRunLimit: [500, [Validators.required, Validators.min(1), Validators.max(2 ** 31)]],
      customProxy: [false],
      strongPassword: [false],
      templateData: [false],
      dataWarehousing: [false]
    });
  }

  private handleUsernameCheck(): void {
    this.username.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500)
      )
      .subscribe((username: string) => {
        this.accountsService.checkDuplicates(username).catch(() => {
          this.username.setErrors({ duplicate: true });
        });
      });
  }

  createAccount(edit?: boolean): void {
    if (this.newAccountForm.invalid) {
      this.newAccountForm.markAllAsTouched();
      return;
    }

    this.editWhenSaved = edit;
    this.saving = true;

    const newAccount: ICreateAccountRequest = {
      account: this.getNewDefaultAccount(this.newAccountForm.value),
      templateData: null
    };

    if (this.newAccountForm.value.templateData) {
      newAccount.templateData = {
        dataLayer: this.newAccountForm.value.dataLayer
      };
    }

    this.saveAccount(newAccount);
  }

  private saveAccount(newAccount: ICreateAccountRequest): void {
    this.adminAccountsService.saveAccount(newAccount).subscribe(
      (account: IAdminAccount) => {
        this.account = account;
        this.saveScriptServicesSettings();
      },
      error => {
        this.saving = false;
        this.snackbar.open(error.message, null, {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    );
  }

  private saveScriptServicesSettings(): void {
    const config: IScriptServicesConfig = {
      journeyFixes: this.newAccountForm.value.ssJourneyFixes,
      maxMonitoredJourneys: this.newAccountForm.value.maxMonitoredJourneys
    };

    this.adminAccountsService.updateScriptServicesConfig(this.account.id, config).subscribe(
      () => {
        this.closeModal({account: this.account, editWhenSaved: this.editWhenSaved });
      },
      error => {
        this.saving = false;
        this.snackbar.open(error.message, null, {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    );
  }

  private getNewDefaultAccount(values: any): IAdminAccount {
    return {
      name: values.name,
      accountType: values.accountType,
      status: values.status || 2,
      maxUsers: 10,
      domains: [values.domain],
      dataWareHouse: values.dataWarehousing,
      maxDomains: +values.maxDomains || 1,
      maxMobileApps: 0,
      concurrentAudits: 10,
      concurrentJourneys: 5,
      auditPageLimit: +values.auditPageLimit,
      webJourneyRunLimit: +values.webJourneyRunLimit,
      primaryUser: {
        username: values.username,
        timezone: 'America/Denver',
        permissions: 32,
        firstName: values.firstName || 'first name',
        lastName: values.lastName || 'last name',
        email: values.email
      },
      videoSupport: true,
      authSupport: true,
      strongPasswordSupport: values.strongPassword || false,
      deviceSupport: true,
      reportSupport: true,
      customProxySupport: values.customProxy || false,
      auditActionSupport: true,
      notes: null,
      vpn: {
        vpnSupport: false
      }
    } as IAdminAccount;
  }

  closeModal(closeObj?: { account: IAdminAccount, editWhenSaved: boolean }): void {
    this.dialogRef.close(closeObj);
  }

  get name(): AbstractControl {
    return this.newAccountForm.get('name');
  }

  get firstName(): AbstractControl {
    return this.newAccountForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.newAccountForm.get('lastName');
  }

  get username(): AbstractControl {
    return this.newAccountForm.get('username');
  }

  get email(): AbstractControl {
    return this.newAccountForm.get('email');
  }

  get domain(): AbstractControl {
    return this.newAccountForm.get('domain');
  }

  get dataLayer(): AbstractControl {
    return this.newAccountForm.get('dataLayer');
  }

  get status(): AbstractControl {
    return this.newAccountForm.get('status');
  }

  get maxDomains(): AbstractControl {
    return this.newAccountForm.get('maxDomains');
  }

  get maxMonitoredJourneys(): AbstractControl {
    return this.newAccountForm.get('maxMonitoredJourneys');
  }
  get ssJourneyFixes(): AbstractControl {
    return this.newAccountForm.get('ssJourneyFixes');
  }

  get customProxy(): AbstractControl {
    return this.newAccountForm.get('customProxy');
  }

  get strongPassword(): AbstractControl {
    return this.newAccountForm.get('strongPassword');
  }

  get templateData(): AbstractControl {
    return this.newAccountForm.get('templateData');
  }

  get dataWarehousing(): AbstractControl {
    return this.newAccountForm.get('dataWarehousing');
  }

  get auditPageLimit(): AbstractControl {
    return this.newAccountForm.get('auditPageLimit');
  }

  get webJourneyRunLimit(): AbstractControl {
    return this.newAccountForm.get('webJourneyRunLimit');
  }
}
