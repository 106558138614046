import { Component, OnInit } from '@angular/core';
import { ESplitCardChangeMeaning } from '@app/components/shared/components/split-card/split-card.models';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IPageSummaryInsightsByPage } from '../../reports/page-summary/page-summary.models';
import { UseCaseService } from '../use-case.service';
import {
  EUseCaseNavToKeys,
  EUseCaseWidgetType,
  IAuditUseCaseData,
  IUseCasePageSummaryInsight,
  IUseCaseSection,
  IUseCaseWebVitalsWidget,
  IUseCaseWidget
} from '../use-cases.models';
import {
  LandingPageAveragePageLoadTimeWidget,
  LandingPageBrokenPagesWidget,
  LandingPagePagesMissingAnalyticsWidget,
  LandingPagePagesMissingQueryStringsWidget,
  LandingPagePagesMissingTagManagerWidget,
  LandingPagePagesScannedWidget,
  LandingPagePagesWithRedirectsWidget,
  LandingPageUniqueTagsWidget
} from './use-case-landing-page.constants';
import { userIsGuest } from '@app/authUtils';
import { IUser } from '@app/moonbeamModels';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { ITagInventorySummary } from '../../reports/tag-inventory/tag-inventory.models';
import { OP_SELECTORS } from '@app/components/audit-reports/use-cases/use-cases.constants';
import {
  OverviewLCPWidget,
} from '@app/components/audit-reports/use-cases/use-case-overview/use-case-overview.constants';
import {
  EWebVitalsMetricType
} from '@app/components/shared/components/viz/web-vitals-chart/web-vitals-chart.constants';

@Component({
  selector: 'op-use-case-landing-page',
  templateUrl: './use-case-landing-page.component.html',
  styleUrls: ['./use-case-landing-page.component.scss']
})
export class UseCaseLandingPageComponent implements OnInit {
  readonly EWebVitalsMetricType = EWebVitalsMetricType;
  CONSTANTS = { ...OP_SELECTORS };
  destroy$ = new Subject();
  auditInfo: IAuditUseCaseData;
  pageInfo: IUseCasePageSummaryInsight;
  analyticsTagsInfo: any;
  redirectInfo: IPageSummaryInsightsByPage;
  pagesMissingQueryStringsInfo: IPageSummaryInsightsByPage;
  pagesMissingTagManagerInfo: IPageSummaryInsightsByPage;
  tagInventorySummary: ITagInventorySummary;
  user: IUser;
  accountType: EAccountType;
  isVisitorMode: boolean;
  isReadOnly: boolean;

  pagesScannedWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_SCANNED,
    loading: true,
    splitCard: false,
    topText: LandingPagePagesScannedWidget.topText,
    topValue: '---',
    topTooltip: LandingPagePagesScannedWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bodyContent: {
      bodyText: '',
      navToText: LandingPagePagesScannedWidget.bodyContent.navToText,
    }
  };

  pagesSection: IUseCaseSection = {
    sectionIcon: 'web',
    sectionTitle: 'Pages'
  };

  brokenPagesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.BROKEN_PAGES,
    loading: true,
    splitCard: true,
    topText: LandingPageBrokenPagesWidget.topText,
    topValue: 0,
    topValueMeaning: null,
    topIconName: null,
    topIconMeaning: ESplitCardChangeMeaning.POSITIVE,
    topTooltip: LandingPageBrokenPagesWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryBrokenFinalPages),
    bottomText: LandingPageBrokenPagesWidget.bottomText,
    bottomValue: 0,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryBrokenFinalPages),
    bodyContent: {
      bodyText: '',
      navToText: LandingPageBrokenPagesWidget.bodyContent.navToText,
    },
    chartData: []
  };

  averagePageLoadTimeWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.AVG_PAGE_LOAD_TIME,
    loading: true,
    splitCard: true,
    topText: LandingPageAveragePageLoadTimeWidget.topText,
    topValue: '',
    topValueMeaning: ESplitCardChangeMeaning.NONE,
    topIconName: null,
    topIconMeaning: null,
    topTooltip: LandingPageAveragePageLoadTimeWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummarySlowLoading),
    bottomText: LandingPageAveragePageLoadTimeWidget.bottomText,
    bottomValue: LandingPageAveragePageLoadTimeWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummarySlowLoading),
    bodyContent: {
      bodyText: '',
      navToText: LandingPageAveragePageLoadTimeWidget.bodyContent.navToText,
    },
    chartData: []
  };

  overviewLCPWidget: IUseCaseWebVitalsWidget = {
    opSelector: this.CONSTANTS.LCP,
    loading: true,
    splitCard: true,
    topIconName: OverviewLCPWidget.topIconName,
    topText: OverviewLCPWidget.topText,
    topValue: 0,
    topTooltip: OverviewLCPWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bottomText: OverviewLCPWidget.bottomText,
    bottomValue: OverviewLCPWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bodyContent: {
      bodyText: '',
      navToText: OverviewLCPWidget.bodyContent.navToText,
    },
    metricType: EWebVitalsMetricType.LargestContentfulPaint,
  };

  pagesMissingQueryStringsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_WITHOUT_QUERY_STRINGS,
    loading: true,
    splitCard: true,
    topText: LandingPagePagesMissingQueryStringsWidget.topText,
    topValue: '',
    topTooltip: LandingPagePagesMissingQueryStringsWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryPagesMissingQueryStrings),
    bottomText: LandingPagePagesMissingQueryStringsWidget.bottomText,
    bottomValue: LandingPagePagesMissingQueryStringsWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryPagesMissingQueryStrings),
    bodyContent: {
      bodyText: '',
      navToText: LandingPagePagesMissingQueryStringsWidget.bodyContent.navToText,
    },
    chartData: []
  };

  pagesWithRedirectsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_WITH_REDIRECTS,
    loading: true,
    splitCard: true,
    topText: LandingPagePagesWithRedirectsWidget.topText,
    topValue: '',
    topTooltip: LandingPagePagesWithRedirectsWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryPagesWithRedirects),
    statsData: LandingPagePagesWithRedirectsWidget.statsData,
    bottomLeftCustomContent: LandingPagePagesWithRedirectsWidget.bottomLeftCustomContent,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryPagesWithRedirects),
    bodyContent: {
      bodyText: '',
      navToText: LandingPagePagesWithRedirectsWidget.bodyContent.navToText,
    },
    chartData: []
  };

  tagsSection: IUseCaseSection = {
    sectionIcon: 'local_offer',
    sectionTitle: 'Tags'
  };

  uniqueTagsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNIQUE_TAGS,
    loading: true,
    splitCard: true,
    topText: LandingPageUniqueTagsWidget.topText,
    topValue: '',
    topTooltip: LandingPageUniqueTagsWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventory),
    statsData: LandingPageUniqueTagsWidget.statsData,
    bottomLeftCustomContent: LandingPageUniqueTagsWidget.bottomLeftCustomContent,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventory),
    bodyContent: {
      bodyText: '',
      navToText: LandingPageUniqueTagsWidget.bodyContent.navToText,
    },
    chartData: []
  };

  pagesMissingAnalyticsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_MISSING_ANALYTICS,
    loading: true,
    splitCard: true,
    topText: LandingPagePagesMissingAnalyticsWidget.topText,
    topValue: '',
    topTooltip: LandingPagePagesMissingAnalyticsWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryPagesMissingAnalytics),
    bottomText: LandingPagePagesMissingAnalyticsWidget.bottomText,
    bottomValue: LandingPagePagesMissingAnalyticsWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryPagesMissingAnalytics),
    bodyContent: {
      bodyText: '',
      navToText: LandingPagePagesMissingAnalyticsWidget.bodyContent.navToText,
    },
    chartData: []
  };

  pagesMissingTagManagerWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_MISSING_TAG_MANAGER,
    loading: true,
    splitCard: true,
    topText: LandingPagePagesMissingTagManagerWidget.topText,
    topValue: '',
    topTooltip: LandingPagePagesMissingTagManagerWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryPagesMissingTagManager),
    bottomText: LandingPagePagesMissingTagManagerWidget.bottomText,
    bottomValue: LandingPagePagesMissingTagManagerWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryPagesMissingTagManager),
    bodyContent: {
      bodyText: '',
      navToText: LandingPagePagesMissingTagManagerWidget.bodyContent.navToText,
    },
    chartData: []
  };

  constructor(private useCaseService: UseCaseService) {}

  ngOnInit(): void {
    this.registerListeners();

    if (!this.useCaseService.isLandingPageDataLoaded) {
      this.useCaseService.loadLandingPage();
    }

  }

  private registerListeners() {
    combineLatest([
      this.useCaseService.accountType$,
      this.useCaseService.isVisitorMode$,
      this.useCaseService.user$,
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([accountType, isVisitorMode, user]) => {
      this.user = user;
      this.accountType = accountType;
      this.isVisitorMode = isVisitorMode;
      this.isReadOnly = userIsGuest(user) || isVisitorMode;

      this.useCaseService.audit$.pipe(takeUntil(this.destroy$)).subscribe((auditInfo: IAuditUseCaseData) => {
        this.auditInfo = auditInfo;
        this.updatePagesScannedWidget();
      });

      combineLatest([
        this.useCaseService.pageSummary$,
        this.useCaseService.pagesWithRedirects$
      ])
      .subscribe(([pageSummaryInfo, redirectInfo]) => {
        this.pageInfo = pageSummaryInfo;
        this.redirectInfo = redirectInfo;

        this.updatePagesWithRedirectsWidget();
        this.updatePagesScannedWidget();
        this.updateBrokenPagesWidget();
        this.updateAveragePageLoadTimeWidget();
        this.updateLCPWidget();
        this.updatePagesWithRedirectsWidget();
      });

      this.useCaseService.pagesMissingQueryStrings$.pipe(takeUntil(this.destroy$)).subscribe((pagesMissingQueryStringsInfo: IPageSummaryInsightsByPage) => {
        this.pagesMissingQueryStringsInfo = pagesMissingQueryStringsInfo;
        this.updatePagesMissingQueryStringsWidget();
      });

      combineLatest([
        this.useCaseService.tagInventorySummary$,
        this.useCaseService.analyticsTags$
      ]).pipe(
        takeUntil(this.destroy$)
      ).subscribe(([tagInventorySummary, analyticsTagsInfo]) => {
        this.tagInventorySummary = tagInventorySummary;
        this.analyticsTagsInfo = analyticsTagsInfo;

        this.updateUniqueTagsWidget();
        this.updatePagesMissingAnalyticsWidget();
      });

      this.useCaseService.pagesMissingTagManager$.pipe(takeUntil(this.destroy$)).subscribe((pagesMissingTagManagerInfo) => {
        this.pagesMissingTagManagerInfo = pagesMissingTagManagerInfo;
        this.updatePagesMissingTagManagerWidget();
      });
    });
  }

  updateLCPWidget(): void {
    if (!this.pageInfo?.webVitals?.p75LargestContentfulPaint) {
      return;
    }

    const widget = this.overviewLCPWidget;
    widget.topValue = this.pageInfo?.webVitals?.p75LargestContentfulPaint
      ? `${(this.pageInfo.webVitals.p75LargestContentfulPaint / 1000)} sec`
      : '---';
    widget.topIconName = this.pageInfo.webVitals.p75LargestContentfulPaint > OverviewLCPWidget.bestPracticeValue * 1000
      ? 'error'
      : OverviewLCPWidget.topIconName;
    widget.topValueMeaning = this.getLargestContentfulPaintWidgetTopMeaning();
    widget.topIconMeaning = this.getLargestContentfulPaintWidgetTopMeaning();
    widget.chartData = this.pageInfo.overviewLargestContentfulPaintChartData as any;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.OverviewLargestContentfulPaint);
    widget.loading = false;
  }

  private getLargestContentfulPaintWidgetTopMeaning(): ESplitCardChangeMeaning {
    const overviewLCP = this.pageInfo.webVitals.p75LargestContentfulPaint;
    if (overviewLCP < OverviewLCPWidget.bestPracticeValue * 1000) {
      return ESplitCardChangeMeaning.POSITIVE;
    } else if (overviewLCP > OverviewLCPWidget.bestPracticeValue * 1000 && overviewLCP < 4000) {
      return ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else if (overviewLCP > 4000 && overviewLCP < 6000) {
      return ESplitCardChangeMeaning.SORT_OF_NEGATIVE;
    } else if (overviewLCP > 6000) {
      return ESplitCardChangeMeaning.NEGATIVE;
    }
  }

  private updatePagesScannedWidget(): void {
    const widget = this.pagesScannedWidget;

    widget.topValue = this.pageInfo?.totalPages?.toLocaleString();
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.LandingPagePagesScanned);
    widget.bodyContent.bodyText = this.useCaseService.replaceVariableWithValue(widget.bodyContent.bodyText, this.auditInfo?.domain);

    widget.loading = false;
  }

  private updateBrokenPagesWidget(): void {
    const widget = this.brokenPagesWidget;
    const chartData = this.useCaseService.formatDataForBrokenPagesChart(this.pageInfo);

    widget.chartData = chartData;
    widget.topValue = this.pageInfo.pagesWithBrokenFinalStatusCode?.toLocaleString();
    widget.topValueMeaning = this.getBrokenPagesWidgetTopMeaning();
    widget.topIconName = this.getBrokenPagesWidgetTopIconName();
    widget.topIconMeaning = this.getBrokenPagesWidgetTopMeaning();
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.LandingPageBrokenPages);

    widget.loading = false;
  }

  private getBrokenPagesWidgetTopMeaning(): ESplitCardChangeMeaning {
    return this.pageInfo.pagesWithBrokenFinalStatusCode > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;
  }

  private getBrokenPagesWidgetTopIconName(): string {
    return this.pageInfo.pagesWithBrokenFinalStatusCode > 0 ? 'error' : 'check_circle';
  }

  private updateAveragePageLoadTimeWidget(): void {
    const widget = this.averagePageLoadTimeWidget;
    const chartData = this.useCaseService.formatDataForAveragePageLoadTimeChart(this.pageInfo);

    widget.chartData = chartData;
    widget.topValue = `${(this.pageInfo?.averagePageLoadTime / 1000).toFixed(1)} sec`;
    widget.topValueMeaning = this.getAveragePageLoadTimeWidgetTopMeaning();
    widget.topIconName = this.getAveragePageLoadTimeWidgetTopIconName();
    widget.topIconMeaning = this.getAveragePageLoadTimeWidgetTopMeaning();
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.LandingPageAveragePageLoadTime);

    widget.loading = false;
  }

  private getAveragePageLoadTimeWidgetTopMeaning(): ESplitCardChangeMeaning {
    if (this.pageInfo.averagePageLoadTime < 3000) {
      return ESplitCardChangeMeaning.POSITIVE;
    } else if (this.pageInfo.averagePageLoadTime > 3000 && this.pageInfo.averagePageLoadTime < 6000) {
      return ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else if (this.pageInfo.averagePageLoadTime > 6000 && this.pageInfo.averagePageLoadTime < 10000) {
      return ESplitCardChangeMeaning.SORT_OF_NEGATIVE;
    } else if (this.pageInfo.averagePageLoadTime > 10000) {
      return ESplitCardChangeMeaning.NEGATIVE;
    }
  }

  private getAveragePageLoadTimeWidgetTopIconName(): string {
    return this.pageInfo.averagePageLoadTime < 3000 ? 'check_circle' :  'error';
  }

  private updatePagesMissingQueryStringsWidget(): void {
    const widget = this.pagesMissingQueryStringsWidget;
    const chartData = this.useCaseService.formatDataForPagesMissingQueryStringsChart(this.pageInfo.totalPages, this.pagesMissingQueryStringsInfo?.metadata.pagination.totalCount);

    widget.chartData = chartData;
    widget.topValue = this.pagesMissingQueryStringsInfo?.metadata.pagination.totalCount;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.LandingPagePagesMissingQueryStrings);

    widget.loading = false;
  }

  private updatePagesWithRedirectsWidget(): void {
    const widget = this.pagesWithRedirectsWidget;
    const chartData = this.useCaseService.formatDataForPagesWithRedirectsChart(this.pageInfo.totalPages, this.redirectInfo);

    widget.chartData = chartData;
    widget.topValue = this.redirectInfo?.metadata?.pagination?.totalCount?.toLocaleString();
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.LandingPagePagesWithRedirects);

    widget.loading = false;
  }

  private updateUniqueTagsWidget(): void {
    const widget = this.uniqueTagsWidget;
    const chartData = this.analyticsTagsInfo?.uniqueAnalyticsChartData || [];

    widget.chartData = chartData;
    widget.topValue = this.tagInventorySummary?.filteredUniqueTagCount?.toLocaleString();
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.LandingPageUniqueTags);

    widget.loading = false;
  }

  private updatePagesMissingAnalyticsWidget(): void {
    const widget = this.pagesMissingAnalyticsWidget;
    const chartData = this.useCaseService.formatDataForPagesMissingAnalyticsChart(this.analyticsTagsInfo?.pageCountTotal, this.analyticsTagsInfo?.pageCountWithAnalytics);

    widget.chartData = chartData;
    widget.topValue = (this.analyticsTagsInfo?.pageCountTotal - this.analyticsTagsInfo?.pageCountWithAnalytics).toLocaleString();
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.LandingPagePagesMissingAnalytics);

    widget.loading = false;
  }

  private updatePagesMissingTagManagerWidget(): void {
    const widget = this.pagesMissingTagManagerWidget;
    const chartData = this.useCaseService.formatDataForPagesMissingTagManagerChart(this.pageInfo.totalPages, this.pagesMissingTagManagerInfo?.metadata.pagination.totalCount);

    widget.chartData = chartData;
    widget.topValue = this.pagesMissingTagManagerInfo?.metadata.pagination.totalCount;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.LandingPagePagesMissingTagManager);

    widget.loading = false;
  }
}
