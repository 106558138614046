import { Component, Input } from '@angular/core';
import { MdePopoverPositionX, MdePopoverPositionY } from '@app/components/popover/popover-types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'notification-channels-horizontal-list',
  templateUrl: './notification-channels-horizontal-list.component.html',
  styleUrls: ['./notification-channels-horizontal-list.component.scss'],
})

export class NotificationChannelsHorizontalListComponent {
  @Input() mdePopoverPositionX: MdePopoverPositionX = 'before';
  @Input() mdePopoverPositionY: MdePopoverPositionY = 'above';
  @Input() mdePopoverOffsetX = -35;
  @Input() mdePopoverOffsetY = 0;
}
