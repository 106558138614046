<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Add To Action Set Library"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="save-new-action-set">
      <form [formGroup]="actionSetForm">
        <mat-form-field class="action-set-name">
          <input matInput placeholder="Name" formControlName="name">
          <mat-error *ngIf="name.invalid">This field is required</mat-error>
        </mat-form-field>
        <div class="hint">
          Would you like us to find and replace these same actions in other audits and journeys?
          <mat-checkbox color="primary" class="add-similar" formControlName="addSimilar">Yes, but let me choose which audits and journeys to update.</mat-checkbox>
        </div>
      </form>
    </div>
  </ng-template>
</op-modal>