import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, skip, takeUntil, tap } from 'rxjs/operators';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { AuditReportBase, IFilterableAuditReport } from '../general-reports.models';
import { AuditReportLoadingService } from '../../audit-report-loading.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { IRuleSummaryPagination, RuleSummaryReportService } from './rule-summary.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import {
  IAlertAuditRunTagAndVariableRulesTrendMetrics,
  IAuditRunAllRuleResultsItem,
  IAuditRunPageRuleResultsItem,
  IAuditRunRuleResultsAllTrendsDTO,
  IAuditRunRuleResultsPageCountTrendItem,
  IAuditRunRuleResultsTrendItem,
  IAuditRunRuleSummaryRequestDTO,
  IAuditRunRuleSummaryTrend,
  IAuditRunRuleSummaryTrendFilters,
  IAuditRunRuleSummaryTrendNoDiff,
  IAuditRunSpecificRulePageRuleResultsItem,
  IAuditRunSpecificRuleResultsDTO,
  IRuleSummaryPagesTableState,
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.models';
import {
  IStackedBarChartInput
} from '@app/components/shared/components/viz/vertical-stacked-bar-chart/vertical-stacked-bar-chart.models';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import {
  SummaryCardFilterType
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-trends/rule-summary-trends.component';
import {
  FullscreenVerticalStackedBarChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-vertical-stacked-bar-chart-modal/fullscreen-vertical-stacked-bar-chart-modal.component';
import {
  IFullscreenVerticalStackedBarChartModalConfig
} from '@app/components/shared/components/viz/fullscreen-vertical-stacked-bar-chart-modal/fullscreen-vertical-stacked-bar-chart-modal.constants';
import { EChartColor } from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IRuleDetailRowIdentifier
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-rules-table/rule-summary-rules-table.models';
import { RulesService } from '@app/components/rules/rules.service';
import { IAuditModel } from '@app/components/modals/modalData';
import { EPageDetailsTabs } from '../../page-details/page-details.constants';
import {
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import {
  EColorHighlightMeaning
} from '@app/components/shared/components/viz/color-highlight/color-highlight.directive';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import {
  IReprocessService,
  ReprocessBannerStatus
} from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import {
  ReprocessConfirmationSnackbarService
} from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.service';
import { EasyRuleEditService } from '@app/components/shared-services/easy-rule-edit-service';
import { AccountsService } from '@app/components/account/account.service';
import {
  IAuditReportExportMenuData,
  ICopyConfigCell
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import {
  ERuleSummaryExportType,
  PagesSpecificTableColumns,
  PagesTableColumns,
  RuleSummaryRelevantFilters
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.constants';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { IRule } from '@app/components/rules/rules.models';
import {
  mkConditionResultSegmentsToCopy
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-pages-specific/rule-summary-pages-specific.utils';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { IAuditEditorCloseOptions } from '@app/components/audit/audit-editor/audit-editor.models';
import { AlertMetricType, EAlertTagAndVariableRulesMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { FreeTrialAdModalComponent } from '../../audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import { EFreeTrialAdModalType } from '../../audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import {
  RuleSummaryPagesComponent
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-pages/rule-summary-pages.component';
import {
  RuleSummaryPagesSpecificComponent
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-pages-specific/rule-summary-pages-specific.component';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  CommonPagesColumnConfigWarningMessage,
  CommonPagesConfigLocalStorageKey,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-summary',
  templateUrl: './rule-summary.component.html',
  styleUrls: ['./rule-summary.component.scss'],
  providers: [ResizeableTableService]
})
export class RuleSummaryComponent extends AuditReportBase implements IFilterableAuditReport, OnDestroy, OnInit {
  @ViewChild(RuleSummaryPagesComponent) ruleSummaryPagesComponent: RuleSummaryPagesComponent;
  @ViewChild(RuleSummaryPagesSpecificComponent) ruleSummaryPagesSpecificComponent: RuleSummaryPagesSpecificComponent;
  PagesTableColumns = PagesTableColumns;

  private readonly xAxisLabelDateFormat = EDateFormats.dateSix;
  private readonly xAxisLabelTimeFormat = EDateFormats.timeOne;
  private ruleResultsSub: Subscription;
  private ruleSummaryDataSub: Subscription;
  readonly spinnerStates = EFilterSpinnerState;
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  // GENERAL
  auditId: number;
  runId: number;
  audit: IAuditModel;
  apiFilters: IAuditRunRuleSummaryRequestDTO = {};
  userIsReadOnly: boolean;

  private currentRun = {
    id: 0 as number,
    completedAt: '' as string
  };

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('ruleDetailsByPage') ruleDetailsByPage: ElementRef;
  @ViewChild('pagesSection') pagesSection: ElementRef;
  widgetState: EFilterSpinnerState;

  private opTags: Map<number, IUiTag> = new Map();
  private readonly defaultSortBy: string = 'page_url';

  // -------------- SUMMARY CARDS ---------------- //
  pagesFilteredCount: number = null;
  pagesTotalCount: number = null;
  pagesScannedMetricType: EAlertTagAndVariableRulesMetric = EAlertTagAndVariableRulesMetric.PagesScanned;

  rulesFilteredCount: number;
  rulesTotalCount: number;
  rulesAssignedMetricType: EAlertTagAndVariableRulesMetric = EAlertTagAndVariableRulesMetric.RulesAssigned;

  totalRuleFailureFilteredCount: number;
  totalRuleFailureTotalCount: number;
  totalRuleFailureMetricType: EAlertTagAndVariableRulesMetric = EAlertTagAndVariableRulesMetric.RuleFailures;

  // -------------- TREND CARDS ---------------- //
  chartState: EFilterSpinnerState = EFilterSpinnerState.None;

  currentRunRuleStatusSummary: IAuditRunRuleSummaryTrendNoDiff = this.emptyTrend();
  currentRunPagesByRuleStatusSummary: IAuditRunRuleSummaryTrendNoDiff = this.emptyTrend();

  ruleInstancesTrendChartData: IStackedBarChartInput = this.prepareRuleInstancesTrendChartData([]);
  ruleInstancesTrendData: IAuditRunRuleResultsTrendItem[] = [];
  ruleInstancesTrend: IAuditRunRuleSummaryTrend = this.emptyTrend();
  ruleInstancesAlertTrendMetricTypes: IAlertAuditRunTagAndVariableRulesTrendMetrics = {
    failedRuleMetricType: EAlertTagAndVariableRulesMetric.FailedInstances,
    passedRuleMetricType: EAlertTagAndVariableRulesMetric.PassedInstances,
    notAppliedRuleMetricType: EAlertTagAndVariableRulesMetric.NotAppliedInstances,
  };
  rulePagesTrendChartData: IStackedBarChartInput = this.prepareRulePagesTrendChartData([]);
  rulePagesTrendData: IAuditRunRuleResultsPageCountTrendItem[] = [];
  rulePagesTrend: IAuditRunRuleSummaryTrend = this.emptyTrend();
  rulePagesAlertTrendMetricTypes: IAlertAuditRunTagAndVariableRulesTrendMetrics = {
    failedRuleMetricType: EAlertTagAndVariableRulesMetric.FailedPages,
    passedRuleMetricType: EAlertTagAndVariableRulesMetric.PassedPages,
    notAppliedRuleMetricType: EAlertTagAndVariableRulesMetric.NotAppliedPages,
  };

  trendFilters: IAuditRunRuleSummaryTrendFilters = { cards: 'failed' };
  fromAuditSummary: SummaryCardFilterType;

  // -------------- RULE RESULTS TABLE ---------------- //
  ruleResultsSpinnerState: EFilterSpinnerState;
  ruleResults: IAuditRunAllRuleResultsItem[] = [];
  rulesById: Map<number, IRule> = new Map();

  selectedRuleConditionIdentifier: IRuleDetailRowIdentifier;

  // -------------- PAGES TABLE ---------------- //
  pageRuleResultsSpinnerState: EFilterSpinnerState;

  defaultPagination: IRuleSummaryPagination = { size: 200, page: 0 };

  pageRuleResults: IAuditRunPageRuleResultsItem[] = [];
  pageRuleResultsTableState: IRuleSummaryPagesTableState = {
    sort: { sortBy: CommonReportsPagesTableColumns.PageUrl, sortDesc: false },
    pagination: this.defaultPagination,
    pagesTotal: 0
  };

  pageRuleResultsSpecific: IAuditRunSpecificRulePageRuleResultsItem[] = [];
  pageRuleResultsSpecificTableState: IRuleSummaryPagesTableState = {
    sort: { sortBy: CommonReportsPagesTableColumns.PageUrl, sortDesc: false },
    pagination: this.defaultPagination,
    pagesTotal: 0
  };

  exportReportConfig: IAuditReportExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: ERuleSummaryExportType.Pages,
    totalRows: this.pagesTotalCount,
    filteredRows: this.pagesFilteredCount,
    tableState: {
      ...this.pageRuleResultsTableState.pagination,
      ...this.pageRuleResultsTableState.sort
    },
    filters: this.apiFilters,
    specificExportTypes: {
      all: ERuleSummaryExportType.Pages
    },
    dataToCopy: {
      data: null,
      config: null,
      displayedColumns$: this.tableService.displayedColumns$
    }
  };

  private dataToCopyPages: ICopyConfigCell[] = [
    {
      property: 'pageUrl',
      tableColumnName: CommonReportsPagesTableColumns.PageUrl
    },
    {
      property: 'finalPageUrl',
      tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
    },
    {
      property: 'pageLoadTime',
      tableColumnName: CommonReportsPagesTableColumns.PageLoadTime,
      displayLike: (row) => (row.pageLoadTime / 1000).toFixed(1)
    },
    {
      property: 'finalPageStatusCode',
      tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
    },
    {
      title: 'RULE FAILURE',
      property: 'ruleFailureCount',
      tableColumnName: CommonReportsPagesTableColumns.RuleFailureCount
    }
  ];

  private dataToCopySpecificPages: ICopyConfigCell[] = [
    {
      property: 'pageUrl',
      tableColumnName: CommonReportsPagesTableColumns.PageUrl
    },
    {
      property: 'finalPageUrl',
      tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
    },
    {
      property: 'pageLoadTime',
      tableColumnName: CommonReportsPagesTableColumns.PageLoadTime,
      displayLike: (row) => (row.pageLoadTime / 1000).toFixed(1)
    },
    {
      property: 'finalPageStatusCode',
      tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
    },
    {
      title: 'RESULT',
      tableColumnName: CommonReportsPagesTableColumns.ConditionResult,
      displayLike: (row, additionalParams) => mkConditionResultSegmentsToCopy(row.conditionResult, additionalParams.ruleResultType),
      additionalParams: {
        ruleResultType: null
      }
    }
  ];

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  private isVisitorMode: boolean;

  constructor(
    private route: ActivatedRoute,
    private ruleSummaryService: RuleSummaryReportService,
    private auditReportLoadingService: AuditReportLoadingService,
    private modalService: OpModalService,
    private filterBarService: AuditReportFilterBarService,
    private uiTagService: UiTagService,
    private rulesService: RulesService,
    private modalEscapeService: ModalEscapeService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private snackbarService: SnackbarService,
    private reprocessConfirmationSnackbarService: ReprocessConfirmationSnackbarService,
    private auditDataService: IAuditDataService,
    private reprocessService: IReprocessService,
    private scrollService: AuditReportScrollService,
    private easyRuleEditService: EasyRuleEditService,
    private accountsService: AccountsService,
    private dateService: DateService,
    private alertReportingService: AlertReportingService,
    private tableService: ResizeableTableService,
    private applicationChromeService: ApplicationChromeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(
      tap(({ auditId, runId }) => {
        this.auditId = +auditId;
        this.runId = +runId;

        const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
        const alertId = queryParams?.alertId;
        const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
        this.preventHighlight = !highlight;
        // When loading report from an alert, first override filters with alert
        // filters before loading report data
        if (alertId) {
          this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
            this.filterBarService.overrideFilters(alert.config.filtersV0);
            this.highlightMetricType = highlight && alert.config.metricType;

            this.alertCheckComplete$.next(true);
          });
        } else {
          this.alertCheckComplete$.next(true);
        }
      }),
      skip(1), // only fire when calendar control updated
      tap(_ => {
        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          this.onFiltersChanged(this.apiFilters);
        });
      })
    ).subscribe();

    this.userIsReadOnly = this.accountsService.userIsReadOnly();
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.Rules);

    this.initFilters();

    this.uiTagService.getTags().subscribe(
      tags => tags.forEach(t => this.opTags.set(t.id, t))
    );
    this.auditDataService.getAudit(this.auditId).then(audit => {
      this.audit = audit;
      const routerState = history.state;
      if (routerState && routerState.areRulesUpdated && routerState.isOpenedByBanner) {
        this.showReprocessToast();
      }
    });

    if (history.state.auditSummaryTrendCardClicked) {
      this.fromAuditSummary = history.state.auditSummaryTrendCardClicked;
    }

    this.applicationChromeService.isVisitorMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: isVisitorMode => this.isVisitorMode = isVisitorMode
      });
  }

  ngOnDestroy() {
    this.auditReportLoadingService.forceOff();
    this.destroy();
    this.onDestroy$.next();

    this.pageDetailsDrawerService.closePageDetails();
  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(RuleSummaryRelevantFilters);

    // ReplaySubject in filterBarService means this executes immediately
    this.filterBarService.apiPostBody$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(filters: IAuditRunRuleSummaryRequestDTO) {
    // trigger table update
    this.apiFilters = {...filters};
    this.selectedRuleConditionIdentifier = undefined;
    this.PagesTableColumns = PagesTableColumns;

    this.exportReportConfig.filters = this.selectedRuleConditionIdentifier
      ? this.apiFilters
      : {
        ...this.apiFilters,
        ...this.selectedRuleConditionIdentifier
      };

    this.updateCurrentFilters();
    this.handleData();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  handleData(): void {
    this.auditReportLoadingService.addLoadingToken();
    this.widgetState = EFilterSpinnerState.Loading;

    if (this.ruleSummaryDataSub) {
      this.ruleSummaryDataSub.unsubscribe();
      this.auditReportLoadingService.removeLoadingToken();
    }
    this.ruleSummaryDataSub = forkJoin([
      this.ruleSummaryService.getRuleSummary(this.auditId, this.runId, this.apiFilters),
      this.ruleSummaryService.getRuleSummaryTrends(this.auditId, this.runId),
      this.rulesService.getRulesCached(),
    ]).subscribe(
      ([summary, data, rules]) => {
        rules.forEach(rule => this.rulesById.set(rule.id, rule));
        this.exportReportConfig.filteredRows = this.pagesFilteredCount = summary.filteredPageCount;
        this.exportReportConfig.totalRows = this.pagesTotalCount = summary.totalPageCount;

        this.rulesFilteredCount = summary.filteredRuleCount;
        this.rulesTotalCount = summary.totalRuleCount;

        this.totalRuleFailureTotalCount = summary.totalRuleFailureCount;
        this.totalRuleFailureFilteredCount = summary.filteredRuleFailureCount;

        this.currentRunRuleStatusSummary = {
          failedRulesCount: summary.failedRuleCount,
          passedRulesCount: summary.passedRuleCount,
          notAppliedRulesCount: summary.notAppliedRuleCount
        };
        this.currentRunPagesByRuleStatusSummary = {
          failedRulesCount: summary.failedRulePageCount,
          passedRulesCount: summary.passedRulePageCount,
          notAppliedRulesCount: summary.notAppliedRulePageCount
        };
        // trends
        this.prepareCurrentRunTrend(data);
        this.ruleInstancesTrendData = data.runs;
        this.rulePagesTrendData = data.runs;
        this.ruleInstancesTrendChartData = this.prepareRuleInstancesTrendChartData(this.ruleInstancesTrendData);
        this.rulePagesTrendChartData = this.prepareRulePagesTrendChartData(this.rulePagesTrendData);
      },
      this.handleApiError('Failed to load rule summary or trends.'),
      this.handleApiComplete(() => {
        this.widgetState = this.calcSpinnerState();
      })
    );

    this.auditReportLoadingService.addLoadingToken();
    this.ruleResultsSpinnerState = EFilterSpinnerState.Loading;

    // Have an issue where the API responses are not coming back in the order they are requested. Canceling any previous
    // subscriptions to ensure we only receive the latest response.
    if (this.ruleResultsSub) {
      this.ruleResultsSub.unsubscribe();
      this.auditReportLoadingService.removeLoadingToken();
    }
    this.ruleResultsSub = this.ruleSummaryService.getRuleResults(this.auditId, this.runId, this.apiFilters).subscribe(
      data => {
        if (data) {
          this.ruleResults = data.rules;
        } else {
          this.ruleResults = [];
        }
      },
      this.handleApiError('Failed to load rule results.'),
      this.handleApiComplete(() => {
        this.ruleResultsSpinnerState = this.calcSpinnerState();
      })
    );

    if (this.selectedRuleConditionIdentifier) {
      this.loadPageRuleResultsSpecific(this.selectedRuleConditionIdentifier, this.pageRuleResultsSpecificTableState, true);
    } else {
      this.loadPageRuleResults(this.pageRuleResultsTableState, true);
    }
  }

  loadPageRuleResults(tableState?: IRuleSummaryPagesTableState, resetPagination?: boolean) {
    const ts = tableState || this.pageRuleResultsTableState;
    if (resetPagination) {
      ts.pagination = this.defaultPagination;
    }

    this.exportReportConfig.tableState = {
      ...ts.pagination,
      ...ts.sort,
      sortBy: this.convertSortingToV3(ts.sort.sortBy)
    };

    this.auditReportLoadingService.addLoadingToken();
    this.pageRuleResultsSpinnerState = EFilterSpinnerState.Loading;
    this.ruleSummaryService.getPageRuleResults(
      this.auditId,
      this.runId,
      ts.pagination,
      ts.sort,
      this.apiFilters
    ).subscribe(
      data => {
        this.exportReportConfig.exportType = ERuleSummaryExportType.Pages;
        this.exportReportConfig.filteredRows = this.pagesFilteredCount = data.metadata.pagination.totalCount;
        this.exportReportConfig.dataToCopy.data = this.pageRuleResults = data.pages;
        this.exportReportConfig.dataToCopy.config = this.dataToCopyPages;
      },
      this.handleApiError('Failed to load page rule results!'),
      this.handleApiComplete(() => {
        this.pageRuleResultsSpinnerState = this.calcSpinnerState();
      })
    );
  }

  handleRuleConditionMetricClicked(
    ruleConditionIdentifier: IRuleDetailRowIdentifier,
    tableState?: IRuleSummaryPagesTableState
  ) {
    if (JSON.stringify(this.selectedRuleConditionIdentifier) === JSON.stringify(ruleConditionIdentifier)) {
      this.selectedRuleConditionIdentifier = undefined;
      this.PagesTableColumns = PagesTableColumns;
      this.exportReportConfig.filters = this.apiFilters;
      this.pageRuleResultsSpecific = [];
      this.pageRuleResultsSpecificTableState.pagesTotal = 0;
      this.exportReportConfig.dataToCopy.data = this.pageRuleResults;
      this.exportReportConfig.dataToCopy.config = this.dataToCopyPages;
    } else {
      this.selectedRuleConditionIdentifier = {...ruleConditionIdentifier};
      this.PagesTableColumns = PagesSpecificTableColumns;
      this.exportReportConfig.filters = {...this.apiFilters, ...ruleConditionIdentifier};
      this.loadPageRuleResultsSpecific(ruleConditionIdentifier, tableState, true);
    }

    this.scrollService.scrollByElement(this.pagesSection.nativeElement);
  }

  private loadPageRuleResultsSpecific(
    ruleConditionIdentifier: IRuleDetailRowIdentifier,
    tableState?: IRuleSummaryPagesTableState,
    resetPagination?: boolean
  ) {
    const ts = tableState || this.pageRuleResultsSpecificTableState;
    if (resetPagination) {
      ts.pagination = this.defaultPagination;
    }

    this.exportReportConfig.tableState = {
      ...ts.pagination,
      ...ts.sort,
      sortBy: this.convertSortingToV3(ts.sort?.sortBy || this.defaultSortBy)
    };

    this.auditReportLoadingService.addLoadingToken();
    this.pageRuleResultsSpinnerState = EFilterSpinnerState.Loading;

    forkJoin([
      this.ruleSummaryService.getPageRuleResultsByRule(
        this.auditId,
        this.runId,
        ruleConditionIdentifier.ruleSnapshotId,
        ts.pagination,
        ts.sort,
        {
          ...this.apiFilters,
          ruleFilter: ruleConditionIdentifier.ruleFilter,
          ruleResultType: ruleConditionIdentifier.ruleResultType
        }
      ),
      this.ruleSummaryService.getRuleResultsSpecific(
        this.auditId,
        this.runId,
        ruleConditionIdentifier.ruleSnapshotId,
        this.apiFilters
      )
    ])
    .subscribe(([data, ruleResultsSpecific]) => {
        data.pages.forEach(page => {
          const tagResult = ruleResultsSpecific.tags.find(tag => tag.tagName === page.conditionResult.name);
          page.conditionResult['account'] = tagResult?.account;
          page.conditionResult['accountMatchType'] = tagResult?.matchType;
        });
        this.exportReportConfig.dataToCopy.data = this.pageRuleResultsSpecific = data.pages;
        this.exportReportConfig.filteredRows = this.pageRuleResultsSpecificTableState.pagesTotal = data.metadata.pagination.totalCount;
        this.exportReportConfig.exportType = ERuleSummaryExportType.RulePages;
        this.exportReportConfig.dataToCopy.config = this.dataToCopySpecificPages;
        this.dataToCopySpecificPages[3].additionalParams = {
          ruleResultType: ruleConditionIdentifier.ruleResultType
        };
      },
      this.handleApiError('Failed to load page rule results!'),
      this.handleApiComplete(() => {
        this.pageRuleResultsSpinnerState = this.calcSpinnerState();
      })
    );
  }

  loadSpecificRuleResults: (ruleSnapshotId: number) => Observable<IAuditRunSpecificRuleResultsDTO> = ruleSnapshotId => {
    return this.ruleSummaryService.getRuleResultsSpecific(this.auditId, this.runId, ruleSnapshotId, this.apiFilters);
  }

  filterByClickedSummaryCard(dataType: SummaryCardFilterType) {
    this.trendFilters.cards = this.trendFilters.cards === dataType ? undefined : dataType;
    this.ruleInstancesTrendChartData = this.prepareRuleInstancesTrendChartData(this.ruleInstancesTrendData);
    this.rulePagesTrendChartData = this.prepareRulePagesTrendChartData(this.rulePagesTrendData);
  }

  openRuleResultsFullScreenChart() {
    this.openFullscreenChart(
      'Rule Instances by Status',
      (days: number) => this.ruleSummaryService.getRuleResultsTrends(this.auditId, days)
        .pipe(
          map(data => this.prepareRuleInstancesTrendChartData(data.runs))
        ),
      {
        ...this.ruleInstancesTrendChartData,
        bars: []
      }
    );
  }

  openRuleResultsPageCountFullScreenChart() {
    this.openFullscreenChart(
      'Pages by Rule Status',
      (days: number) => this.ruleSummaryService.getPageCountTrends(this.auditId, days)
        .pipe(
          map(data => this.prepareRulePagesTrendChartData(data.runs))
        ),
      {
        ...this.rulePagesTrendChartData,
        bars: []
      }
    );
  }

  getTagNameByTagId: (tagId: number) => string = (tagId: number) => {
    return this.opTags.get(tagId)?.name;
  }

  editRuleClicked(ruleSnapshotId: number) {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      this.editRule(ruleSnapshotId);
    }
  }

  private editRule(ruleSnapshotId: number): void {
    const promptReprocessRules = () => {
      this.auditDataService.getAudit(this.auditId).then(audit => {
        this.audit = audit;
        this.showReprocessToast();
      }, this.onError('Failed to load audit data'));
    };

    this.rulesService.getOriginalRuleId(ruleSnapshotId).subscribe(response => {
      const ruleId = response?.originalRuleId;
      if (ruleId) {
        this.rulesService.getRule(ruleId).subscribe(oldRule => {
          if (!oldRule) this.onError()();
          this.openRuleEditor(ruleId).subscribe(changedRule => {
            if (!changedRule) return;
            const ruleUpdated = this.easyRuleEditService.isRuleChanged(oldRule, changedRule);
            if (ruleUpdated) {
              promptReprocessRules();
            }
          });
        }, this.onError());
      } else {
        this.onError()();
      }
    }, this.onError());
  }

  private openFreeTrialAdModal() {
    const data = {
      type: EFreeTrialAdModalType.RULE
    };

    this.modalService.openModal(FreeTrialAdModalComponent, { data });
  }

  editRuleEmailClicked(snapShotId: number) {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      this.editRuleEmail(snapShotId);
    }
  }

  async editRuleEmail(snapShotId: number): Promise<void> {
    try {
      const { originalRuleId } = await this.rulesService.getOriginalRuleId(snapShotId).toPromise();
      this.openRuleEditor(originalRuleId).subscribe();
    } catch (_) {
      this.onError()();
    }
  }

  private openRuleEditor(ruleId: number): Observable<IRule> {
    return this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.edit,
        ruleId
      }
    }, 'rule-setup-modal')
    .afterClosed();
  }

  private onError = (msg: string = 'Failed to load rule data') => (error?: any) => {
    error && console.error(error);
    this.errorToast(msg);
  }

  private showReprocessToast() {
    this.reprocessConfirmationSnackbarService.showReprocessRules(this.audit.rules, () => {
      this.auditDataService
        .reprocessRules(this.auditId, this.runId)
        .then(() => {
          this.reprocessService.reprocessComplete();
          this.reprocessService.subscribeOnAuditReprocessingRulesUpdates(this.auditId, this.runId, this.audit.name);
          this.reprocessService.updateAuditReprocessRulesBannerStatus(this.auditId, this.runId, ReprocessBannerStatus.inProgress);
        });
    });
  }

  pagesTableConfigChanged(ts: IRuleSummaryPagesTableState) {
    this.loadPageRuleResults(ts);
  }

  pagesSpecificTableConfigChanged(ts: IRuleSummaryPagesTableState) {
    if (this.selectedRuleConditionIdentifier) {
      this.loadPageRuleResultsSpecific(this.selectedRuleConditionIdentifier, ts, false);
    }
  }

  assignRules() {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    const data = {
      domainId: this.audit.domainId,
      auditId: this.audit.id,
      runId: this.runId,
      step: 1,
      standardsTab: EStandardsTabs.Rules
    };
    this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor')
      .afterClosed()
      .subscribe((options?: IAuditEditorCloseOptions) => {
        this.modalEscapeService.remove(index);

        if (options) {
          this.audit = options.audit;
          if (options.areRulesUpdated) this.showReprocessToast();
        }
      });
  }

  get totalFailureStatus() {
    return this.totalRuleFailureFilteredCount > 0 ? EColorHighlightMeaning.NEGATIVE : EColorHighlightMeaning.NONE;
  }

  get totalAssignedStatus() {
    return this.rulesTotalCount === 0 ? EColorHighlightMeaning.NEGATIVE : EColorHighlightMeaning.NONE;
  }

  private openFullscreenChart(title: string,
                              getData: (days: number) => Observable<IStackedBarChartInput>,
                              emptyChartData: IStackedBarChartInput): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenVerticalStackedBarChartModalComponent, {
      data: {
        currentRunCompletedAt: this.currentRun.completedAt,
        getData: getData,
        chartConfig: {
          title: title,
          data: emptyChartData
        }
      } as IFullscreenVerticalStackedBarChartModalConfig
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  private dateTimeToFormat(date: string, format: EDateFormats): string {
    return this.dateService.formatToUTC(new Date(date), format);
  }

  private prepareCurrentRunTrend(data: IAuditRunRuleResultsAllTrendsDTO): void {
    data.runs.sort((a, b) => a.runId - b.runId);
    const currentRunTrend = data.runs[data.runs.length - 1];
    const previousRunTrend = data.runs[data.runs.length - 2];

    this.currentRun.completedAt = currentRunTrend?.completedAt;
    this.ruleInstancesTrend = {
      failedRulesCount: this.currentRunRuleStatusSummary.failedRulesCount,
      failedRulesCountDiffWithPrev: currentRunTrend?.failedRuleCount - previousRunTrend?.failedRuleCount,
      passedRulesCount: this.currentRunRuleStatusSummary.passedRulesCount,
      passedRulesCountDiffWithPrev: currentRunTrend?.passedRuleCount - previousRunTrend?.passedRuleCount,
      notAppliedRulesCount: this.currentRunRuleStatusSummary.notAppliedRulesCount,
      notAppliedRulesCountDiffWithPrev: currentRunTrend?.notAppliedRuleCount - previousRunTrend?.notAppliedRuleCount
    };
    this.rulePagesTrend = {
      failedRulesCount: this.currentRunPagesByRuleStatusSummary.failedRulesCount,
      failedRulesCountDiffWithPrev: currentRunTrend?.failedRulePageCount - previousRunTrend?.failedRulePageCount,
      passedRulesCount: this.currentRunPagesByRuleStatusSummary.passedRulesCount,
      passedRulesCountDiffWithPrev: currentRunTrend?.passedRulePageCount - previousRunTrend?.passedRulePageCount,
      notAppliedRulesCount: this.currentRunPagesByRuleStatusSummary.notAppliedRulesCount,
      notAppliedRulesCountDiffWithPrev: currentRunTrend?.notAppliedRulePageCount - previousRunTrend?.notAppliedRulePageCount
    };

    this.trendFilters = {
      cards: this.fromAuditSummary ? this.fromAuditSummary : undefined
    };
  }

  private prepareRulePagesTrendChartData(data: IAuditRunRuleResultsPageCountTrendItem[]): IStackedBarChartInput {
    return {
      layerDefinitions: this.barLayerDefinitions(),
      yAxisLabel: undefined,
      emptyBarText: 'ø',
      bars: (data || []).sort((a, b) => a.runId - b.runId).map(t => ({
        layerValues: {
          failedRuleCount: t.failedRulePageCount,
          passedRuleCount: t.passedRulePageCount,
          notAppliedRuleCount: t.notAppliedRulePageCount
        },
        label: this.dateTimeToFormat(t.completedAt, this.xAxisLabelDateFormat),
        subLabel: this.dateTimeToFormat(t.completedAt, this.xAxisLabelTimeFormat),
        id: '' + t.runId
      }))
    };
  }

  private prepareRuleInstancesTrendChartData(data: IAuditRunRuleResultsTrendItem[]): IStackedBarChartInput {
    return {
      layerDefinitions: this.barLayerDefinitions(),
      yAxisLabel: undefined,
      emptyBarText: 'ø',
      bars: (data || []).sort((a, b) => a.runId - b.runId)
        .map(t => ({
          layerValues: {
            failedRuleCount: t.failedRuleCount,
            passedRuleCount: t.passedRuleCount,
            notAppliedRuleCount: t.notAppliedRuleCount
          },
          label: this.dateTimeToFormat(t.completedAt, this.xAxisLabelDateFormat),
          subLabel: this.dateTimeToFormat(t.completedAt, this.xAxisLabelTimeFormat),
          id: '' + t.runId
        }))
    };
  }

  private errorToast(message: string) {
    this.snackbarService.openErrorSnackbar(message);
  }

  private handleApiError = (message: string, handler?: (error: any) => void) => {
    return error => {
      handler && handler(error);
      console.error(error);
      this.errorToast(message);
    };
  }

  private handleApiComplete = (handler?: () => void) => {
    return () => {
      handler && handler();
      this.auditReportLoadingService.removeLoadingToken();
    };
  }

  private calcSpinnerState(): EFilterSpinnerState {
    return this.filterBarService.currentRelevantFilters.length
    || Object.keys(this.apiFilters).length
    || this.selectedRuleConditionIdentifier
      ? EFilterSpinnerState.Filtered
      : EFilterSpinnerState.None;
  }

  private emptyTrend(): IAuditRunRuleSummaryTrend {
    return {
      failedRulesCount: 0,
      failedRulesCountDiffWithPrev: 0,
      passedRulesCount: 0,
      passedRulesCountDiffWithPrev: 0,
      notAppliedRulesCount: 0,
      notAppliedRulesCountDiffWithPrev: 0
    };
  }

  private barLayerDefinitions() {
    return [
      {
        key: 'failedRuleCount',
        label: 'Rules Failed',
        colorClass: !this.trendFilters?.cards || this.trendFilters?.cards === 'failed'
          ? EChartColor.Red : EChartColor.TransparentRed
      },
      {
        key: 'passedRuleCount',
        label: 'Rules Passed',
        colorClass: !this.trendFilters?.cards || this.trendFilters?.cards === 'passed'
          ? EChartColor.Green : EChartColor.TransparentGreen
      },
      {
        key: 'notAppliedRuleCount',
        label: 'Rules Not Applied',
        colorClass: !this.trendFilters?.cards || this.trendFilters?.cards === 'notApplied'
          ? EChartColor.Yellow : EChartColor.TransparentYellow
      }
    ];
  }

  convertSortingToV3(str: string) {
    switch (str) {
      case 'load_time': return 'pageLoadTime';
      case 'status_code': return 'pageStatusCode';
    }
    return str;
  }
}
