import { RorReportPaths } from './../../ror-comparison-report.contants';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-parity-summary',
  templateUrl: './ror-parity-summary.component.html',
  styleUrls: ['./ror-parity-summary.component.scss']
})
export class RorParitySummaryComponent {

  @Input() tagLevelScore: number;
  @Input() variableLevelScore: number;
  @Input() valueLevelScore: number;
  @Input() pagesMissing: number;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  openMissingTags(): void {
    this.router.navigate([RorReportPaths.tagPresence], {queryParamsHandling: 'merge', relativeTo: this.activatedRoute});
  }

  openMissingVariables(): void {
    this.router.navigate([RorReportPaths.variablePresence], {queryParamsHandling: 'merge', relativeTo: this.activatedRoute});
  }

  openIncorrectValues(): void {
    this.router.navigate([RorReportPaths.valuePresence], {queryParamsHandling: 'merge', relativeTo: this.activatedRoute});
  }

  openMissingPages(): void {
    this.router.navigate([RorReportPaths.missingPages], {queryParamsHandling: 'merge', relativeTo: this.activatedRoute});
  }

}
