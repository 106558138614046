<div class="flex-col" [formGroup]="parentForm">
  
  <div formArrayName="selectors">
    <div *ngFor="let selector of formArray.controls; let i = index" [formGroupName]="i">
        <active-selector-details *ngIf="i === activeSelectorIndex"
                                 [formGroup]="selector"
                                 [selectorsCount]="selectors.length"
                                 (removeSelector)="removeSelector(activeSelectorIndex)">
        </active-selector-details>
    </div>
  </div>
  
  <multiple-selectors class="multiple-selectors"
                      [selectors]="formArray.value"
                      [activeSelectorIndex]="activeSelectorIndex"
                      (updateSelector)="updateSelector($event)"
                      (addSelector)="addSelector()">
  </multiple-selectors>
</div>
