import { Component, forwardRef, Input, Renderer2, SimpleChanges, OnInit, OnChanges, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {
  IFolderAndSubFolderData,
  ISubfolderGroupedByFolderId
} from '@app/components/audit/audit-setup-form/audit-setup-form.models';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import {
  FolderAndSubFolderModalComponent
} from '@app/components/folder-and-sub-folder-modal/folder-and-sub-folder-modal.component';
import { forkJoin, Observable, Subject } from 'rxjs';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { map, startWith, takeUntil } from 'rxjs/operators';
import {
  EFolderMode,
  ESubFolderMode
} from '@app/components/folder-and-sub-folder-modal/folder-and-sub-folder-modal.models';
import { EDataSourceType } from '@app/components/shared/services/data-sources/data-sources.constants';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FolderAndSubFolderFieldComponent),
  multi: true
};

const FORM_CONTROL_VALIDATION = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => FolderAndSubFolderFieldComponent),
  multi: true
};

@Component({
  selector: 'op-folder-and-sub-folder-field',
  templateUrl: './folder-and-sub-folder-field.component.html',
  styleUrls: ['./folder-and-sub-folder-field.component.scss'],
  providers: [FORM_CONTROL_VALUE_ACCESSOR, FORM_CONTROL_VALIDATION],
})
export class FolderAndSubFolderFieldComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$ = new Subject<void>();
  EDataSourceType = EDataSourceType;
  foldersById: { (id: number): IFolder };
  subfoldersById: { (id: number): IDomain };
  folders: Array<IFolder> = [];
  subfolders: Array<IDomain> = [];
  folderId: number;
  subFolderId: number;
  subFoldersByFolderId: ISubfolderGroupedByFolderId;
  folderMap: { [id: number]: IFolder } = {};
  hideFolderName: boolean = false;
  folderSelectPlaceholder: string = '';
  loading: boolean = true;
  filteredFolders: Observable<any[]>;
  filteredSubFoldersByFolderId: ISubfolderGroupedByFolderId | {};
  tempCreatedFolderName: string;  // Copy of folder name if we are creating a new folder
  tempCreatedSubFolderName: string; // Copy of sub-folder name if we are creating a new sub-folder
  tempCreatedDataLayer: string; // Copy of data layer if we are creating a new sub-folder
  subFolderSearch: FormControl = new FormControl('');
  filterValue: string = '';
  folderAndSubfolderForm: UntypedFormGroup;
  folderMode: EFolderMode = EFolderMode.Select;
  subFolderMode: ESubFolderMode = ESubFolderMode.Create;

  onTouched: () => void;
  onChange: (model: IFolderAndSubFolderData) => void;

  @Input() itemType: EDataSourceType;
  @Input() submitted: boolean;

  constructor(
    private renderer: Renderer2,
    private formBuilder: UntypedFormBuilder,
    private foldersService: IFoldersApiService,
    private domainsService: IDomainsService,
    private modalService: OpModalService,
  ) { }

  ngOnInit(): void {
    this.initForm();

    this.filteredFolders = this.subFolderSearch.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSubFolderOptions(value || ''))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.submitted && changes.submitted.currentValue) {
      this.folderAndSubfolderForm.markAllAsTouched();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * This is a custom filter that returns which folder ids should be displayed and sets the filtered
   * options inside each sub-folder mapping that should be displayed in the mat-select results.
   * @param value: string or sub-folder object
   * @private
   */
  private _filterSubFolderOptions(value): any[] {
    this.filteredSubFoldersByFolderId = {};

    // If we don't have a search string manually typed (initial load or after selecting an option
    // from the dropdown), we'll display all folders and their subfolders in the dropdown.
    if (typeof value !== 'string') {
      this.filterValue = '';
      return this.folders;
      // If we have a search string, filter the list by the search string.
    } else {
      let splitValues = value.split(' / ');
      this.filterValue = splitValues.length > 1 ? splitValues[1] : value;
      this.filterValue = this.filterValue?.toLowerCase() || '';

      // We have a folder name in the autocomplete (denoted by "folder.name / ", so display only
      // sub-folder names that match the search string after the folder name. Ignore folders without any matching sub-folders.
      if (splitValues.length > 1) {
        return this.folders.filter((folder, index) => {
          let filteredSubFolders = [];
          this.getFilteredSubfolders(filteredSubFolders, folder.id);

          return filteredSubFolders.length > 0;
        });
        // No folder name in the autocomplete, so match folder name (display full sub-folder content if found) or sub-folder name
      } else {
        return this.folders.filter((folder, index) => {
          let filteredSubFolders = [];
          if (folder.name.toLowerCase().includes(this.filterValue)) {
            this.filteredSubFoldersByFolderId[folder.id] = this.subFoldersByFolderId[folder.id];
            filteredSubFolders = this.subFoldersByFolderId[folder.id];
          } else {
            this.getFilteredSubfolders(filteredSubFolders, folder.id);
          }

          return filteredSubFolders.length > 0;
        });
      }
    }
  }

  getFilteredSubfolders(filteredSubfolders, folderId): void {
    this.subFoldersByFolderId[folderId].forEach(subFolder => {
      if (subFolder.name.toLowerCase().includes(this.filterValue)) {
        filteredSubfolders.push(subFolder);
        if (!this.filteredSubFoldersByFolderId[folderId]) {
          this.filteredSubFoldersByFolderId[folderId] = [];
        }

        this.filteredSubFoldersByFolderId[folderId].push(subFolder);
      }
    });
  }

  // Custom format method for how we display Folder / Sub-folder names in the autocomplete input
  displayFn(): string {
    // Handle zero state where an account doesn't have any folders created yet.
    if (this.folders.length === 0 && this.folder.value === '') {
      this.folderSelectPlaceholder = this.getSubfolderSelectPlaceholder();
      return '';
    }

    let folder = '';
    let subFolder = '';

    if (this.folderMode === EFolderMode.Create) {
      folder = this.folder.value;
    } else {
      folder = this.folder.value?.name;
    }

    if (this.subFolderMode === ESubFolderMode.Create) {
      subFolder = this.subFolder.value;
    } else {
      subFolder = (this.subFolder as UntypedFormControl).value?.name;
    }

    return `${folder} / ${subFolder}`;
  }

  private initForm(): void {
    this.folderAndSubfolderForm = this.formBuilder.group({
      folder: this.formBuilder.control(null, Validators.required),
      subFolder: this.formBuilder.control(null, Validators.required),
      dataLayer: this.formBuilder.control(''),
    });
  }

  loadData() {
    return forkJoin([
      this.loadFolders(),
      this.loadSubfolders(),
    ])
      .pipe(
        takeUntil(this.destroy$),
      );
  }

  processData(folders, subfolders) {
    this.folders = folders.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    this.createFoldersById();
    this.subfolders = subfolders.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    this.createSubfoldersById();
    this.createFolderMap();
    this.createFoldersAndSubfolderGrouping();
  }

  displayHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.addClass(hint, 'show-hint');
  }

  hideHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.removeClass(hint, 'show-hint');
  }

  getSubfolderSelectPlaceholder() {
    return `${this.folders.length === 0 || this.folderAndSubfolderForm.controls.subFolder.value ? '' : 'SELECT '} FOLDER & SUB-FOLDER`;
  }

  onSubfolderSelectOpened(): void {
    this.hideFolderName = true;
  }

  onSubfolderSelectClosed(): void {
    this.hideFolderName = false;
  }

  onSubfolderChange(change: MatAutocompleteSelectedEvent): void {
    if ((change.option.value as IDomain)) {
      this.subFolderId = change.option.value.id;
      this.folderId = change.option.value.folderId;

      this.selectSubfolder(change.option.value);
    }
  }

  // Open new modal and fill with correct values depending on whether we
  // are creating or editing
  createFolderOrSubfolder($event): void {
    this.modalService
      .openModal(FolderAndSubFolderModalComponent, {
        data: {
          folders: this.folders,
          subFolders: this.subfolders,
          subFoldersByFolderId: this.subFoldersByFolderId,
          currentFolderId: this.folderAndSubfolderForm.value?.folder?.id,
          currentSubFolderId: this.folderAndSubfolderForm.value?.subFolder?.id,
          useDefaultDomain: true,
          mode: EFolderMode.Create,
          itemType: this.itemType,
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe((data) => {
        if (!data) return;

        this.fillForm(data);

        if (this.onChange) {
          this.onChange({
            folder: this.folder.value,
            subFolder: this.subFolder.value,
            dataLayer: this.dataLayer.value
          });
        }

        this.folderSelectPlaceholder = this.getSubfolderSelectPlaceholder();
        this.subFolderSearch.setValue('');
      });
  }

  private loadFolders(): Observable<IFolder[]> {
    return this.foldersService.getFoldersObservable(true);
  }

  private loadSubfolders(): Observable<IDomain[]> {
    return this.domainsService.getDomainsObservable(true);
  }

  createFolderMap(): void {
    this.folders.forEach((folder: IFolder) => {
      this.folderMap[folder.id] = folder;
    });
  }

  createFoldersAndSubfolderGrouping(): void {
    // Handle case where we may have folders without any subfolders
    let emptyFolders: ISubfolderGroupedByFolderId = this.subFoldersByFolderId = this.folders.reduce((acc: ISubfolderGroupedByFolderId, folder: IFolder) => {
      if (!acc[folder.id]) { acc[folder.id] = []; }
      return acc;
    }, {} as ISubfolderGroupedByFolderId);

    let foldersWithSubfolders: ISubfolderGroupedByFolderId = this.subfolders.reduce((acc: ISubfolderGroupedByFolderId, subfolder: IDomain) => {
      if (!acc[subfolder.folderId]) { acc[subfolder.folderId] = []; }

      acc[subfolder.folderId].push(subfolder);
      return acc;
    }, {} as ISubfolderGroupedByFolderId);

    this.subFoldersByFolderId = { ...emptyFolders, ...foldersWithSubfolders } as ISubfolderGroupedByFolderId;
  }

  createFoldersById(): void {
    this.foldersById = this.folders.reduce((acc, folder, index) => {
      acc[folder.id] = { ...folder, subfolders: [] };
      return acc;
    }, {} as { (id: number): IFolder });
  }

  createSubfoldersById(): void {
    this.subfoldersById = this.subfolders.reduce((acc, subfolder, index) => {
      acc[subfolder.id] = subfolder;

      return acc;
    }, {} as { (id: number): IDomain });
  }

  // CVA implementation
  writeValue(folderData: IFolderAndSubFolderData): void {
    // writeValue only passes in an audit when editing or cloning an existing audit
    this.loadData().subscribe(([allFolders, allSubfolders]) => {
      this.processData(allFolders, allSubfolders);
      let data = { ...folderData };
      this.folderMode = EFolderMode.Select;
      this.subFolderMode = ESubFolderMode.Select;

      // Select first sub-folder by default if not editing an existing datasource.
      if (!data.folder) {
        // If we have any sub-folders, default to the first one in the list
        if (this.subfolders?.length > 0) {
          data.subFolder = this.subfolders[0];
          data.folder = this.foldersById[(data.subFolder as IDomain).folderId];
        } else {
          data.subFolder = '';
          data.folder = '';
        }
      }

      this.fillForm(data);
      this.folderSelectPlaceholder = this.getSubfolderSelectPlaceholder();

      if (this.onChange) {
        this.onChange({
          folder: this.folder.value,
          subFolder: this.subFolder.value,
          dataLayer: this.dataLayer.value
        });
      }

      this.loading = false;
    });
  }

  private selectSubfolder(selectedSubFolder): void {
    let folder;
    let subFolder;

    // Create mode - If selecting a subfolder that was just "created" (temp copy until saved), we use the string values.
    if (this.subFolderMode === ESubFolderMode.Create && selectedSubFolder === this.tempCreatedSubFolderName) {
      this.folderMode = EFolderMode.Create;
      this.subFolderMode = ESubFolderMode.Create;
      this.subFolder.setValue(selectedSubFolder);
      // Folder will only exist as a string
      this.folder.setValue(this.tempCreatedFolderName);
      // If we are using an existing folder/sub-folder, we can set the values directly.
    } else {
      this.folderMode = EFolderMode.Select;
      this.subFolderMode = ESubFolderMode.Select;
      // If we have a subfolderId assigned, use that. Otherwise, default to first subfolder in the list
      subFolder = ((selectedSubFolder as IDomain)?.id && this.subfolders.find(d => d.id === (selectedSubFolder as IDomain)?.id)) || this.subfolders[0];
      folder = this.folders.find(f => f.id === subFolder.folderId);

      this.subFolder.setValue(subFolder);
      this.folder.setValue(folder);
      this.dataLayer.setValue(subFolder?.dataLayer || '');
    }

    this.subFolder.updateValueAndValidity();
    this.folder.updateValueAndValidity();

    this.folderSelectPlaceholder = this.getSubfolderSelectPlaceholder();

    if (this.onChange) {
      this.onChange({
        folder: this.folder.value,
        subFolder: this.subFolder.value,
        dataLayer: this.dataLayer.value
      });
    }

    this.subFolderSearch.setValue('');
  }

  fillForm(data: IFolderAndSubFolderData): void {
    // We need to keep a string reference to the last created folder name to populate options in autocomplete
    // if a user clicks away, so we don't lose it.
    if (data?.folderMode === EFolderMode.Create) {
      this.tempCreatedFolderName = data.folder as string;
    }

    // We need to keep a string reference to the last created sub-folder name to populate options in autocomplete
    // if a user clicks away, so we don't lose it (along with the associated datalayer value).
    if (data?.subFolderMode === ESubFolderMode.Create) {
      this.tempCreatedSubFolderName = data.subFolder as string;
      this.tempCreatedDataLayer = data.dataLayer;
    }

    if (data?.folderMode === EFolderMode.Create || data?.folderMode === EFolderMode.Select) {
      this.folderMode = data.folderMode;
    }

    if (data?.subFolderMode === ESubFolderMode.Create || data?.subFolderMode === ESubFolderMode.Select) {
      this.subFolderMode = data.subFolderMode;
    }

    const updatedValue = {
      folder: data.folder,
      subFolder: data.subFolder,
      dataLayer: typeof data.dataLayer === 'string' ? data.dataLayer : (data.subFolder as IDomain)?.dataLayer || '',
    };

    this.folderAndSubfolderForm.patchValue(updatedValue);
    this.folderAndSubfolderForm.updateValueAndValidity();

    // This is a HACK to update the DISPLAYED value in the autocomplete
    // (the actual value is fine)
    // because the formControl of the component CVA (folderAndSubfolderForm)
    // and the search (subFolderSearch) have no connection
    // and the autocomplete is bound to the (subFolderSearch) but it's value's not being updated
    // in the process of substitution of the folder/subfolder part
    // the [displayWith] fn is not being triggered
    // When we manually trigger the (subFolderSearch) formControl update - the displayed value is
    // being updated because [displayWith] fn is being triggered
    this.subFolderSearch.setValue('');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Validator implementation
  validate(c: AbstractControl): ValidationErrors {
    return this.folderAndSubfolderForm.valid ? null : { folderAndSubfolderForm: { valid: false, message: 'Invalid folder values' } };
  }

  get folder(): AbstractControl {
    return this.folderAndSubfolderForm.get('folder');
  }

  get subFolder(): AbstractControl {
    return this.folderAndSubfolderForm.get('subFolder');
  }

  get dataLayer(): AbstractControl {
    return this.folderAndSubfolderForm.get('dataLayer');
  }

  protected readonly EFolderMode = EFolderMode;
  protected readonly ESubFolderMode = ESubFolderMode;
}
