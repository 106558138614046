<div class="title">Tag Database</div>

<div class="spinner-wrap" *ngIf="!dataSource.data.length">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>
<ng-container [class.hidden]="!dataSource.data.length">
  <div class="tag-db-tools">
    <op-clearable-input [placeholderText]="'Filter Tags'"
                        (onUserInput)="filterTags($event)"
                        class="filter-input">
    </op-clearable-input>
    <mat-icon matTooltip="Download CSV" matTooltipPosition="above" (click)="downloadCsv()">cloud_download</mat-icon>
  </div>
  <div class="tag-table-wrap">
    <table mat-table [dataSource]="dataSource" class="styled-mat-table" matSort>

      <ng-container matColumnDef="tagId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag ID</th>
        <td mat-cell *matCellDef="let tag">{{ tag.tagId }}</td>
      </ng-container>

      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef>Icon</th>
        <td mat-cell *matCellDef="let tag">
          <img class="tag-icon" src="{{ tag.icon }}" loading="lazy" />
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let tag">{{ tag.name }}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let tag">{{ tag.category }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</ng-container>
