import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { ApiService } from '../core/services/api.service';
import { environment } from '@app/environments/environment';
import {
  EUsageItemType,
  IUsageTrendsDTO,
  IUsageRequestDTO,
  IUsageContractTerm,
  IAccountUsageV2SummaryResponseDTO,
  IAccountUsageV2ItemSpecificSummaryResponseDTO,
  IAuditUsage,
  IUsageContractTermDTO,
  IWebJourneyUsage,
  IDailyUsageV2,
  EUsageExportType, IUsageTrendsPastPeriod, IUsageTrendsCurrentPeriod, IUsageTrendsFuturePeriod
} from '@app/components/usage-v2/usage-v2.models';
import { catchError, map } from 'rxjs/operators';
import { MockUsageFullSummaryDTO } from '@app/components/usage-v2/api.mocks';
import { Features } from '@app/moonbeamConstants';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import {
  EUsageTabTitleChartTypes
} from '@app/components/usage-v2/components/usage-tab-title-chart/usage-tab-title.enums';
import { DateService, toUTC } from '@app/components/date/date.service';
import { IUsageSummaryDTO } from '@app/components/usage-v2/usage.models';

@Injectable()
export class UsageV2ApiService {

  apiRoot: string = `${environment.apiV3Url}usage`;

  constructor(private apiService: ApiService,
              private authenticationService: AuthenticationService,
              private dateService: DateService) {
  }

  getUsageSummary(): Observable<IAccountUsageV2SummaryResponseDTO> {
    return this.apiService.get<IAccountUsageV2SummaryResponseDTO>(`${this.apiRoot}/summary`);
  }

  getUsageItemSpecificSummary(
    itemType: EUsageItemType,
    filters: IUsageRequestDTO,
    contractTerm?: IUsageContractTerm
  ): Observable<IAccountUsageV2ItemSpecificSummaryResponseDTO> {
    if (contractTerm) {
      filters.contractTerm = {
        startDateInclusive: contractTerm.startDateInclusive,
        endDateInclusive: contractTerm.endDateInclusive
      };
    }
    return this.apiService.post<IAccountUsageV2ItemSpecificSummaryResponseDTO>(
      `${this.apiRoot}/${itemType}/summary`, filters);
  }

  getUsageSummaryAudit(filters: IUsageRequestDTO,
                       contractTerm?: IUsageContractTerm): Observable<IAuditUsage> {
    return this.getUsageItemSpecificSummary(EUsageItemType.AUDIT, filters, contractTerm)
      .pipe(map(auditSummary => {
        const auditContractTerms = this.prepareContractTerms(auditSummary.contractTerms, auditSummary.currentContractTerm);
        const auditUsage: IAuditUsage = {
          usage: auditSummary.usage,
          currentContractTerm: auditContractTerms.find(t => t.isCurrent),
          contractTerms: auditContractTerms,
          concurrentAudits: MockUsageFullSummaryDTO.auditUsage.concurrentAudits
        };
        return auditUsage;
      }));
  }

  getUsageSummaryWebJourney(
    filters: IUsageRequestDTO,
    contractTerm?: IUsageContractTerm
  ): Observable<{ webJourneyUsage: IWebJourneyUsage, isWebJourneyFeatureEnabled: boolean }> {
    return forkJoin([
      this.getUsageItemSpecificSummary(EUsageItemType.WEB_JOURNEY, filters, contractTerm)
        .pipe(catchError(err => of(null as IAccountUsageV2ItemSpecificSummaryResponseDTO))),
      this.authenticationService.isFeatureAllowed(Features.webJourneys)
    ]).pipe(map(([webJourneySummary, isWebJourneyFeatureEnabled]) => {
      const normalisedWebJourneySummary = isWebJourneyFeatureEnabled ? webJourneySummary || this.defaultSpecificSummary() : null;
      let webJourneyUsage: IWebJourneyUsage = null;
      if (normalisedWebJourneySummary) {
        const webJourneyCTs = this.prepareContractTerms(normalisedWebJourneySummary.contractTerms, normalisedWebJourneySummary.currentContractTerm);
        webJourneyUsage = {
          usage: normalisedWebJourneySummary.usage,
          currentContractTerm: webJourneyCTs.find(t => t.isCurrent),
          contractTerms: webJourneyCTs,
          supportUsage: normalisedWebJourneySummary.usage.supportUsage,
          concurrentWebJourneys: MockUsageFullSummaryDTO.webJourneyUsage.concurrentWebJourneys,
        };
      }
      return {
        webJourneyUsage: webJourneyUsage,
        isWebJourneyFeatureEnabled
      };
    }));
  }

  sendReportToEmail(exportType: EUsageExportType, contractTerm?: IUsageContractTerm, filters: IUsageRequestDTO = {}) {
    const body = {
      ...filters,
      ...(contractTerm ? {
        contractTerm: {
          startDateInclusive: contractTerm.startDateInclusive,
          endDateInclusive: contractTerm.endDateInclusive
        }
      } : {})
    };

    return this.apiService.post(this.apiRoot + `/exports/${exportType}`, body);
  }

  sendPeriodReportToEmail(
    exportType: EUsageExportType,
    period: IUsageTrendsPastPeriod | IUsageTrendsCurrentPeriod | IUsageTrendsFuturePeriod,
    filters: IUsageRequestDTO = {},
  ) {
    const body = {
      ...filters,
      runDateRange: {
        startDateInclusive: period.startDateInclusive,
        endDateExclusive: period.endDateExclusive,
      }
    };

    return this.apiService.post(this.apiRoot + `/exports/${exportType}`, body);
  }

  private prepareContractTerms(terms: IUsageContractTermDTO[], currentTerm: IUsageContractTermDTO): IUsageContractTerm[] {
    return terms
      .sort((a, b) => this.compareContractTerms(a, b))
      .map((ct, index) => {
        const isCurrent = ct.startDateInclusive === currentTerm.startDateInclusive
          && ct.endDateInclusive === currentTerm.endDateInclusive;
        return {
          sequence: index + 1,
          isCurrent,
          startDateInclusive: ct.startDateInclusive,
          endDateInclusive: ct.endDateInclusive
        };
      });
  }

  private defaultSpecificSummary(): IAccountUsageV2ItemSpecificSummaryResponseDTO {
    const nowInUtc = this.dateService.toUTC(new Date());
    const currentTerm = {
      startDateInclusive: nowInUtc.toISOString().slice(0, 10),
      endDateInclusive: new Date(nowInUtc.getTime() + 365 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10) // 1 year
    } as IUsageContractTermDTO;
    return { //TODO Hacked a mock until we figure out why API returns 404 for web journey summary
      currentContractTerm: currentTerm,
      contractTerms: [currentTerm],
      usage: {
        cumulativeTotal: { total: 0 },
        termLimit: 0,
        cumulativePacing: 0,
        overLimitPrice: { amount: 0, currency: 'USD' },
        monthlyUsage: []
      }
    };
  }

  getDailyTrends(itemType: EUsageTabTitleChartTypes): Observable<{ dailyUsageItems: IDailyUsageV2[] }> {
    return this.apiService.get(this.apiRoot + `/${itemType}/daily-trends`);
  }

  getUsageTrends(
    itemType: EUsageItemType,
    filters: IUsageRequestDTO,
    contractTerm?: IUsageContractTerm
  ): Observable<IUsageTrendsDTO> {

    const body = {
      ...filters,
      ...(contractTerm ? {
        contractTerm: {
          startDateInclusive: contractTerm.startDateInclusive,
          endDateInclusive: contractTerm.endDateInclusive
        }
      } : {})
    };

    return this.apiService.post<IUsageTrendsDTO>(`${this.apiRoot}/${itemType}/trends`, body);
  }

  getUsageOverview(): Observable<IUsageSummaryDTO> {
    return this.apiService.get(`${this.apiRoot}/overview`);
  }

  private compareContractTerms(a: IUsageContractTermDTO, b: IUsageContractTermDTO): number {
    return this.toUtcDate(a.startDateInclusive) > this.toUtcDate(b.startDateInclusive) ? 1 : -1;
  }

  /**
   * @param date format: "2024-01-01"
   * @private
   */
  private toUtcDate(date: string): Date {
    return toUTC(new Date(date));
  }

  private toUtcDateString(date: Date): string {
    return date.toISOString().slice(0, 10);
  }
}
