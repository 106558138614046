import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';

export enum ECacheResetEvent {
  login,
  loginAsOriginal,
  loginAsVisitor,
  impersonate
}

/**
 * This service is responsible for notifying listeners to clean cached data based on reset event type
 */
@Injectable({
  providedIn: 'root'
})
export class CacheResetService {

  private resetSubject = new Subject<ECacheResetEvent>();
  reset$ = this.resetSubject.asObservable().pipe(shareReplay());

  raiseEvent(event: ECacheResetEvent) {
    this.resetSubject.next(event);
  }

}
