<div class="report-header" (keydown.esc)="runPickerVisible = false">

  <div class="flex-row self-baseline">
    <div class="back-action" (click)="closeReport()">
      <mat-icon>close</mat-icon>
      <div class="divider-vertical"></div>
    </div>
    <div class="data-wrp">
      <div class="container-title-wrp flex-col">
        <span class="container-title" *ngIf="folderName && domainName">
          {{folderName}} / {{domainName}}
        </span>
      </div>

      <div class="flex-row">
        <div class="name-wrp flex-col">
          <span class="report-name" title="{{ journeyName }}">{{ journeyName}}</span>
        </div>
        <div class="run-picker-wrp" *ngIf="!noJourney">
          <button *ngIf="this.runId"
                  class="btn btn-link"
                  mdePopoverTriggerOn="click"
                  [mdePopoverTriggerFor]="appPopover"
                  (opened)="runPickerVisible = true"
                  (closed)="runPickerVisible = false"
                  (click)="isTimeSeriesVisible = !isTimeSeriesVisible">
            <span>{{date}}</span>
            <span class="caret"></span>
          </button>

          <mde-popover #appPopover="mdePopover"
                      [mdePopoverOverlapTrigger]="false"
                      [mdePopoverCloseOnClick]="false">
            <run-picker-ng  *ngIf="runPickerVisible"
                            [runs]="runPickerRuns"
                            (onClickRun)="onClickRun($event)">
            </run-picker-ng>
          </mde-popover>
        </div>
      </div>
    </div>
    <status-banner *ngIf="!journeyHasNotRunYet"
      [updatesType]="statusBannerUpdateType.WebJourney"
      [scheduledToScan]="scheduledToScan"
      [runCancelled]="runCancelled"
      (viewReport)="updateRun($event)"
      (runFinished)="journeyRunning = false">
    </status-banner>
    <mat-icon id="JourneyPauseIcon" *ngIf="showPauseIconFlag" class="report-header-icon-paused-state" svgIcon="pause_icon"></mat-icon>
  </div>

    <div class="menu-wrp">
      <status-popover-button *ngIf="!loadingRunInfo"
                             [leadingIcon]="journeyStatus.icon"
                             [buttonText]="journeyStatus.text"
                             [trailingIcon]="'info_outline'"
                             [buttonColor]="journeyStatus.color"
                             [leadingIconColor]="journeyStatus.leadingIconColor"
                             #journeyInfoTrigger
                             mdePopoverTriggerOn="click"
                             matTooltip="Journey Run Information"
                             matTooltipPosition="below"
                             [mdePopoverTriggerFor]="infoPopover"
      >
        <mde-popover #infoPopover="mdePopover"
                     class="journey-info-popover"
                     [mdePopoverOverlapTrigger]="false"
                     [mdePopoverArrowColor]="'rgba(0,0,0,0)'"
                     [mdePopoverEnterDelay]="400"
                     [mdePopoverCloseOnClick]="false"
        >
          <web-journey-report-info [data]="runInfoPanelData"
                                   [advancedJourneyFeatures]="advancedJourneyFeatures"
                                   [failedRulesData]="failedRulesData"
                                    [failedActionData]="failedActionData"
                                   (jumpToAction)="handleJumpToAction($event)"
                                   (jumpToGlobalRules)="handleJumpToGlobalRules()"
                                   (requestJourneyFix)="handleRequestJourneyFix()"
          ></web-journey-report-info>
        </mde-popover>
      </status-popover-button>

      <op-menu class="flex-row action-menu"
               *ngIf="!noJourney && !isReadOnly"
               [items]="menuOptions"
               icon="icon-gear">
      </op-menu>
    </div>

</div>
