import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { from, Observable, of, Subscription } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { CreateEditFolderComponent } from './create-edit-folder/create-edit-folder.component';
import { IUser } from '@app/moonbeamModels';
import { ServerErrorCodes } from '@app/moonbeamConstants';
import { ModalType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import { BulkActionProgressService } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.service';
import { BulkActionProgressComponent } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.component';
import { TerminateActiveRunsModalService } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';
import { AccountsService } from '@app/components/account/account.service';
import { OpDeleteItemWarningComponent } from '@app/components/shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { BulkActionBarComponent } from '@app/components/manage/cards/bulk-action-bar/bulk-action-bar.component';
import { ManageCardsService } from '@app/components/manage/cards/manage-cards.service';
import { BulkOperationsDeleteMessaging } from '@app/components/bulk-operations/bulk-operations.const';

export interface IManageFoldersTablRow {
  name: string;
  id: number;
  domains: number;
  createdBy: string;
  createdDate: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-folders',
  templateUrl: './manage-folders.component.html',
  styleUrls: ['./manage-folders.component.scss'],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions },
    ManageCardsService, BulkActionBarComponent,
  ]
})
export class ManageFoldersComponent implements OnInit {

  displayedColumns: string[] = ['select', 'name', 'domains', 'createdBy', 'createdDate', 'id', 'editDelete'];
  dataSource: MatTableDataSource<IManageFoldersTablRow> = new MatTableDataSource;
  selection = new SelectionModel(true, []);
  deleteButtonDisabled: boolean = true;
  loading: boolean = true;
  user$: Observable<IUser>;
  duration: number = 5000;
  processCompleted = false;

  @ViewChild(MatSort) sort: MatSort;

  readonly maxParallel: number = 1;

  constructor(
    private accountsService: AccountsService,
    private folderService: IFoldersApiService,
    private modalService: OpModalService,
    private terminateActiveRunsModalService: TerminateActiveRunsModalService,
    private dateService: DateService,
    private snackBar: MatSnackBar,
    private bulkActionProgressService: BulkActionProgressService,
    private bulkActionBar: BulkActionBarComponent,
    private manageCardsService: ManageCardsService,
  ) {}

  ngOnInit(): void {
    this.folderService.getFolders(true).then((folders: IFolder[]) => {
      this.loading = false;
      this.initDataSource(folders);
    });

    this.user$ = this.accountsService.getUser().pipe(shareReplay());
    this.user$.subscribe();
  }

  initDataSource(folders: IFolder[]) {
    this.dataSource.data = folders.map((folder: IFolder) => {
      return {
        name: folder.name,
        id: folder.id,
        domains: folder.domainsCount,
        createdBy: folder.createdByUserName,
        createdDate: this.dateService.formatDate(new Date(folder.createdAt), EDateFormats.dateTwentyThree),
      };
    }).sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, attribute) => {
      if (typeof data[attribute] === 'number') {
        return data[attribute];
      }

      return data[attribute].toLowerCase();
    };
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isAllFilteredSelected(): boolean {
    return <boolean> this.dataSource.filteredData.reduce(
      (allSelected: boolean, row) => allSelected && this.selection.isSelected(row),
      true
    );
  }

  masterToggle(): void {
    if (this.dataSource.filter) {
      this.isAllFilteredSelected() ?
        this.dataSource.filteredData.forEach(row => this.selection.deselect(row)) :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    } else {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onCheckboxChecked(event: MouseEvent): void {
    event.stopPropagation();
    this.updateButtonStatus();
  }

  onCreateFolder(): void {
    this.modalService.openModal(CreateEditFolderComponent, { })
    .afterClosed()
    .subscribe((newFolder: IFolder) => {
      if (newFolder) {
        const newTableRow = {
          name: newFolder.name,
          id: newFolder.id,
          domains: newFolder.domainsCount,
          createdBy: newFolder.createdByUserName,
          createdDate: this.dateService.formatDate(new Date(newFolder.createdAt), EDateFormats.dateTwentyThree)
        };

        this.dataSource.data.push(newTableRow);
        this.dataSource.data.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        // forces table to update
        this.dataSource.data = this.dataSource.data;
      }
    });
  }

  onEditFolder(event: MouseEvent, folder: IFolder): void {
    event.stopPropagation();
    this.modalService.openModal(CreateEditFolderComponent, { data: { folder: folder } })
    .afterClosed()
    .subscribe((updatedFolder: IFolder) => {
      if (updatedFolder) folder.name = updatedFolder.name;
      this.dataSource.data.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
      // forces table to update
      this.dataSource.data = this.dataSource.data;
    });
  }

  onDeleteFolder(event: MouseEvent, folder: IManageFoldersTablRow): void {
    event.stopPropagation();

    let messages = [];

    if (folder.domains) {
      const plural = folder.domains > 1 ? 's' : '';
      messages.push(`<b>${folder.domains}</b> domain${plural}`);
    }

    const joinedMessages = messages.join(' and ');
    const fullMessage = joinedMessages ? `and will also delete ${joinedMessages}` : `but won't delete any domains`;

    this.modalService.openModal(OpDeleteItemWarningComponent, {
      data: {
        itemType: 'Folder',
        name: folder.name,
        customMessage: `Doing so is irreversible ${fullMessage}.`
      }
    })
    .afterClosed()
    .subscribe((confirmDelete: boolean) => {
      if (confirmDelete) {
        this.deleteFolder(folder);
      }
    });
  }

  deleteFolder(folder: IManageFoldersTablRow): void {
    // perform optimistic update
    const index = this.dataSource.data.indexOf(folder);
    this.dataSource.data.splice(index, 1);
    // forces table to update
    this.dataSource.data = this.dataSource.data;

    this.folderService.removeFolder(folder.id).catch(error => {
      if (error.errorCode === ServerErrorCodes.alreadyRunning) {
        this.terminateActiveRunsModalService.showTerminateActiveRunsModal(ModalType.Folders, error.items);
      }

      // put deleted row back if error
      this.dataSource.data.splice(index, 0);
      // forces table to update
      this.dataSource.data = this.dataSource.data;
    });
  }

  // Delete multiple folders
  //  NOTE: Folders contain subfolders (domains) but not audits or journeys (directly)
  //  If user chooses to move items first (before delete), then nothing happens below after the dialog is closed
  onDeleteMultiFolders(): void {

    // Disable the delete button while loading dialogbox
    this.deleteButtonDisabled = true;
    setTimeout(() => { this.deleteButtonDisabled = false; }, 2500);
    
    const folders = this.selection.selected;
    const numFolders = folders.length;
    const numDomains = folders.map(folder => folder.domains).reduce((a, b) => a + b);
    const message = `Are you sure you want to permanently delete the ${numFolders > 1 ? numFolders : ''} folder${numFolders > 1 ? 's' : ''} as well as all items in the folder${numFolders > 1 ? 's' : ''}? `;

    // get audit and journey counts for all selected folders to be deleted
    let auditCounts: number = 0;
    let journeyCounts: number = 0;
    this.manageCardsService.getAuditJourneyCountsBySubfolder(folders, 'folders').then((subFolderInfo: number[]) => {
        auditCounts += subFolderInfo[0];
        journeyCounts += subFolderInfo[1];

      this.modalService.openModal(OpDeleteItemWarningComponent, {
        data: {
          itemType: 'Folders',
          customMessage: message,
          defaultMessage: false,
          Folders: folders,
          firstDomainID: subFolderInfo[2],
          haveCounts: true,
          FoldersCount: numFolders,
          SubfoldersCount: numDomains,
          AuditsCount: auditCounts,
          JourneysCount: journeyCounts,
          sourcePageTab: 'manage-folders',
        }
      })
      .afterClosed()
      .subscribe((confirmDelete: string) => {

        // Handle moving items
        if (confirmDelete === 'move') {
          this.handleMoveAndDeleteResponse(folders).then(confirmDelete => {

            if(BulkOperationsDeleteMessaging.Success !== confirmDelete) {
              let respMessage = "";
              if (BulkOperationsDeleteMessaging.Cancel === confirmDelete) {
                // Reload the first dialog box after move-cancel as if we hit the back-button.
                this.onDeleteMultiFolders();
                return;
              } else if (BulkOperationsDeleteMessaging.MoveFailure === confirmDelete) {
                respMessage = 'The move request did not succeed for some items';
              } else if (BulkOperationsDeleteMessaging.DeleteFailure === confirmDelete) {
                respMessage = 'The delete request did not succeed for some items';
              } else if(BulkOperationsDeleteMessaging.MoveToSameFolder === confirmDelete) {
                respMessage = 'The move request was cancelled because both source and destination folders were the same';
              } else {
                respMessage = 'Unknown response from processing. Please verify your request was completed.';
              }
              this.snackBar.open(respMessage, "", {
                duration: this.duration,
                horizontalPosition: "center",
                verticalPosition: "top",
              });
            } else {

              // On successful move, re-init the table - show new folders that may have been created during move
              this.ngOnInit();
              this.selection.clear();
            }
          });

        // Handle deleting without moving
        } else if (confirmDelete === 'delete') {
          
          // Show progress-bar and start deleting items
          this.showProgressDlg(folders.length);
          this.deleteMultiFolders(folders);

        } else {

          // User cancelled delete operation
          this.snackBar.open("Delete was cancelled. No items removed.", "", {
            duration: this.duration,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      });
    });
  }

  async handleMoveAndDeleteResponse(folders: any[]): Promise<number> {

    // Move items first
    const res = await this.moveToFolderConfirmation(folders);
    if((res !== BulkOperationsDeleteMessaging.Success) && (res !== BulkOperationsDeleteMessaging.NoItemsToMove)) {
      return res;
    }

    // Ensure move is completed in the database (re-query the db to see if any items are still there)
    let filteredCards: any[];
    let loop: number = 1;

    do {
      await this.waitForMoveToComplete(1000);

      filteredCards = await this.buildCards(folders);
      if(filteredCards.length > 0) {
        console.log('manage-folders -> handleMoveAndDeleteResponse() -> Cards FOUND after move! ', filteredCards, loop);
      } else {
        console.log('manage-folders -> handleMoveAndDeleteResponse() -> no cards remaining after move: ', loop);
      }
    } 
    while((filteredCards.length > 0) && (loop++ < 3))

    // Let's abort the delete if items are still pending a move to new folder/subfolder
    if(filteredCards.length > 0) {
      return BulkOperationsDeleteMessaging.MoveFailure;
    }

    // Delete folders after moving items
    this.showProgressDlg(folders.length);
console.log('manage-folders -> handleMoveAndDeleteResponse() -> Starting Delete')
    await this.deleteMultiFolders(folders);

    return BulkOperationsDeleteMessaging.Success;
  }

  // Delete multiple folders
  async deleteMultiFolders(folders: any[]): Promise<any> {

    let runningItems = [];
    const failures = [];
    let processedCount = 0;

    const result = await from(folders).pipe(
        mergeMap(folder => {
          return fromPromise(this.folderService.removeFolder(folder.id)
              .then(success => {

                let index = this.dataSource.data.indexOf(folder);
                this.dataSource.data.splice(index, 1);
              }))
              .pipe(
                mergeMap(result => of({ folder, success: true })),
                catchError(error => {
                  console.log(error);
                  if (error.errorCode === ServerErrorCodes.alreadyRunning) {
                    error.items.forEach(item => runningItems.push(item));
                  }
                  return of({ folder, success: false});
                })
              )
        }, this.maxParallel),
        tap(result => {

          // record failures for informing user
          if (!result.success) {
            failures.push(result.folder);
          }

          this.bulkActionProgressService.publishProgressbar(++processedCount);
        })

      ).toPromise().then(() => {

        // forces table to update
        this.dataSource.data = this.dataSource.data;
        this.selection.clear();
        this.updateButtonStatus();
        if (runningItems.length > 0) {
          this.terminateActiveRunsModalService.showTerminateActiveRunsModal(ModalType.Folders, runningItems);

          // Close the progress dialog if we are terminating runs
          this.snackBar.dismiss();
        }
      });
  }

  async moveToFolderConfirmation(folders: any[]): Promise<number> {

    // If there are no items to move then return and start deleting
    let filteredCards = await this.buildCards(folders);
    if(filteredCards.length < 1) {
      return BulkOperationsDeleteMessaging.NoItemsToMove;
    }

    //Open the Move dialog box
    this.bulkActionBar.selectedCards = filteredCards;
    const excludeFolderIDs: number[] = folders.map(({ id }) => id);
    const res = await this.bulkActionBar.moveToFolderConfirmation(excludeFolderIDs, null);

    return res;
  }
  async buildCards(folders: any[]): Promise<any[]> {

    let cards = await this.manageCardsService.loadCardData();

    this.manageCardsService.selectedCards = cards;
    let filteredCards = await this.manageCardsService.filterCardsBySubfolder(folders, 'folders');

    return filteredCards;
  }

  showProgressDlg(itemsCount: number, maxCountOverride?: number): boolean {

    const formattedMessage: string = "Selected folders are deleted.";
    const formattedMessage2: string = "Don't close this banner, or leave this page until finished or remaining data sources won't be completed.";
    const finalMessage: string = "All selected folders have been deleted.";
    this.processCompleted = false;

    const confirmConfig = {
      maxCount: (maxCountOverride) ? maxCountOverride : itemsCount,
      messages: [
        formattedMessage,
        formattedMessage2,
        finalMessage,
      ],
      showFinalMessage: false,
      showSecondBtn: false,
      showProgressBar: true,
      rightFooterButtons: [
        {
          label: "Yes, delete them",
          opSelector: "bulk-confirm-delete-yes",
          hidden: false,
          primary: false,
        },
        {
          label: "Cancel",
          opSelector: "bulk-confirm-delete-cancel",
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionProgressComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
    .subscribe((observer?: MatSnackBarDismiss) => {
      if(observer.dismissedByAction) {
        this.processCompleted = true;
        return false;
      }
    });

    return true;
  }

  updateButtonStatus(): void {
    setTimeout(() => {
      this.deleteButtonDisabled = !this.selection.hasValue();
    });
  }

  // Without this delay, the delete process deletes the last moved item before the API is done updating the db.
  async waitForMoveToComplete(milli: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, milli);
    });
  }
}
