<div class="flex-col flex-stretch inside-table-wrap">
  <div *ngIf="loading" class="inside-table-loader">
    <mat-spinner [diameter]="40" [strokeWidth]="3"></mat-spinner>
  </div>
  <div *ngIf="!loading && !dataSource.data.length" class="no-items">
    No rule details yet
  </div>
  <table *ngIf="!loading && dataSource.data.length" class="inside-table mat-table">
    <thead>
      <tr class="mat-header-row">
        <th class="mat-header-cell">Name:</th>
        <th class="mat-header-cell">Created By:</th>
        <th class="mat-header-cell">Last Run:</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of dataSource.data" class="mat-row">
      <td class="mat-cell truncated">
        <a class="hover-link" (click)="onItemClick(item)">
          {{ item.name }}
        </a>
      </td>
      <td class="mat-cell">{{ item.createdBy }}</td>
      <td class="mat-cell">{{ item.lastRunDate | date: dateTimeFormat }}</td>
    </tr>
    </tbody>

  </table>
</div>
