import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IRageFilterModalConfigData, IRangeFilterClosedData } from './range-filter-modal.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'range-filter-modal',
  templateUrl: './range-filter-modal.component.html',
  styleUrls: [ './range-filter-modal.component.scss' ]
})
export class RangeFilterModalComponent {
  min: string;
  max: string;
  validationMessage: string;
  hintMessage: string;

  rightFooterButtons = [
    {
      label: 'Done',
      action: () => this.submitFilter(),
      primary: true,
      disabled: true
    }
  ];

  constructor(public dialogRef: MatDialogRef<RangeFilterModalComponent, IRangeFilterClosedData>, @Inject(MAT_DIALOG_DATA) public data: IRageFilterModalConfigData) { }

  submitFilter() {
    this.closeModal({ min: this.min, max: this.max });
  }

  closeModal(range?: IRangeFilterClosedData) {
    this.dialogRef.close(range);
  }

  onInputChanged() {
    this.rightFooterButtons[0].disabled = true;
    this.validationMessage = this.data.validation(this.min, this.max);
    this.rightFooterButtons[0].disabled = (!this.min && !this.max) || !!this.validationMessage;
    this.hintMessage = this.data.hintMessage(this.min, this.max);
  }
}
