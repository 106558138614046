import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-beta-label',
  templateUrl: './cl-beta-label.component.html',
  styleUrls: ['./cl-beta-label.component.scss']
})
export class ComponentLibraryBetaLabelComponent {
}
