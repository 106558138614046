import {ISerializer} from '../../../../../api/apiService';


export interface ISelectionUpdates {
    pages: number;
    reportDetail: number;
    otherPages?: number;
    pagesFailing?: number;
    tagsFailing?: number;
    compliantPercentage?: number;
  }

  interface ISelectionSummaryJson {
    hits: number;
    uniqueVariableValues?: number;
    failingTags?: number;
    duplicateTags?: number;
    multipleTags?: number;
  }

  export class SelectionSummarySerializer implements ISerializer<ISelectionUpdates> {
    serialize(json: ISelectionSummaryJson): ISelectionUpdates {
      if (json) {
        return <ISelectionUpdates>{
          pages: json.hits,
          reportDetail: this.serializeReportDetail(json)
        };
      } else {
        return null;
      }
    };

    private serializeReportDetail(data: ISelectionSummaryJson): number {
      for (var key in data) {
        if (key && key !== 'hits') {
          return data[key];
        }
      }
    }

}

