export const OP_SELECTORS = {
  // Overview
  PAGES_SCANNED: 'pagesScanned',
  BROKEN_PAGES: 'brokenPages',
  AVG_PAGE_LOAD_TIME: 'avgPageLoadTime',
  LCP: 'largestContentfulPaint',
  QUERY_STRINGS: 'queryStrings',
  REDIRECT_PAGES: 'redirectPages',
  UNIQUE_TAGS: 'uniqueTags',
  BROKEN_TAG_REQUESTS: 'brokenTagRequests',
  AVG_TAG_LOAD_TIME: 'avgTagLoadTime',
  UNIQUE_COOKIES: 'uniqueCookies',
  TAG_INITIATORS: 'tagInitiators',
  NON_SECURE_COOKIES: 'nonSecureCookies',
  PAGES_MISSING_ANALYTICS: 'pagesMissingAnalytics',
  PAGES_MISSING_TMS: 'pagesMissingTMS',
  // Analytics
  TOP_ANALYTICS: 'topAnalytics',
  TOP_TAG_MANAGER: 'topTagManager',
  ANALYTICS_TAG_REQUESTS: 'analyticsTagRequests',
  BROKEN_ANALYTICS_TAG_REQUESTS: 'brokenAnalyticsTagRequests',
  DUPLICATE_ANALYTICS_TAG_REQUESTS: 'duplicateAnalyticsTagRequests',
  TAG_AND_VARIABLE_RULE_FAILURES: 'tagAndVariableRuleFailures',
  UNIQUE_ANALYTICS_TAG_VARIABLES: 'uniqueAnalyticsTagVariables',
  UNIQUE_ANALYTICS_TAG_VALUES: 'uniqueAnalyticsTagValues',
  DATA_LAYER_COVERAGE: 'dataLayerCoverage',
  // Privacy
  THIRD_PARTY_COOKIE_DOMAINS: 'thirdPartyCookieDomains',
  UNIQUE_GEOLOCATIONS: 'uniqueGeolocations',
  PAGES_MISSING_CONSENT_MANAGER: 'pagesMissingConsentManager',
  PAGES_MISSING_TAG_MANAGER: 'pagesMissingTagManager',
  PAGES_MISSING_PRIVACY_POLICY_LINK: 'pagesMissingPrivacyPolicyLink',
  PAGES_MISSING_DO_NOT_SELL_LINK: 'pagesMissingDoNotSellLink',
  NEW_JAVASCRIPT_FILES: 'newJavascriptFiles',
  UNAPPROVED_COOKIES: 'unapprovedCookies',
  UNAPPROVED_TAGS: 'unapprovedTags',
  UNAPPROVED_GEOLOCATIONS: 'unapprovedGeolocations',
  // Landing Page
  PAGES_WITHOUT_QUERY_STRINGS: 'pagesWithoutQueryStrings',
  PAGES_WITH_REDIRECTS: 'pagesWithRedirects',
};
