export interface IStatusBarItem {
  type: StatusBarItemType;
  label: string;
  state: StatusBarItemState;
}

export type StatusBarItemState = 
  'notStarted' | 'inProgress' | 'done';

export const StatusBarItemState = {
  notStarted: 'notStarted' as StatusBarItemState,
  inProgress: 'inProgress' as StatusBarItemState,
  done: 'done' as StatusBarItemState
};

export type StatusBarItemType = 
  'inQueue' | 'pagesProcessing' | 'pagesVerified' | 'rulesValidation' | 'finishing';

export const StatusBarItemType = {
  inQueue: 'inQueue' as StatusBarItemType,
  pagesProcessing: 'pagesProcessing' as StatusBarItemType,
  pagesVerified: 'pagesVerified' as StatusBarItemType,
  rulesValidation: 'rulesValidation' as StatusBarItemType,
  finishing: 'finishing' as StatusBarItemType,
};