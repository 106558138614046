<div class="console-log-tools"
     [ngClass]="{ shadowed: isScrolled, 'in-audit': reportType === 'audit' }">
  <div class="top-section">
    <op-clearable-input [placeholderText]="'Filter Console Log'"
                        [defaultValue]="search"
                        (onUserInput)="searchingStr$.next($event)"
                        class="clearable-input">
    </op-clearable-input>
    <share-report-btn *ngIf="reportType === 'audit'"
                      [auditId]="itemId"
                      [runId]="runId"
                      [pageId]="pageId"
                      [activeTab]="activeTab">
    </share-report-btn>
  </div>
</div>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="25"
               [strokeWidth]="4">
  </mat-spinner>
</div>
<div class="table-wrap" [class.loading]="loading">
  <table mat-table [dataSource]="dataSource" class="styled-mat-table" matSort (matSortChange)="$event">
    <ng-container matColumnDef="count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>INSTANCES</th>
      <td mat-cell *matCellDef="let log">
        {{ log.count }}
      </td>
    </ng-container>

    <ng-container matColumnDef="related_tag">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>TAG</th>
      <td mat-cell *matCellDef="let log">
        <img
          *ngIf="log.relatedTag?.tagId"
          [matTooltip]="log.relatedTag.tagName"
          matTooltipPosition="above"
          class="tag"
          [src]="getTagIconUrl(log.relatedTag.tagId)"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="level">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>TYPE</th>
      <td mat-cell *matCellDef="let log">
        <mat-icon *ngIf="log.level === EAuditExportType.error">cancel</mat-icon>
        <mat-icon *ngIf="log.level === EAuditExportType.warn">warning</mat-icon>
        <mat-icon *ngIf="log.level === EAuditExportType.debug">bug_report</mat-icon>
        <mat-icon *ngIf="log.level === EAuditExportType.info">info</mat-icon>
        <mat-icon *ngIf="log.level === EAuditExportType.other">chat</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        MESSAGE
      </th>
      <td mat-cell *matCellDef="let log" colspan="2">
        <div class="content">
          <p>{{ log.message }}</p>
          <p><small *ngIf="log.source">{{ log.source }}</small></p>
          <p>
            <small>{{log.timestamp | date: dateFormat}} | </small>
            <small>{{log.timestamp | date: timeFormat}}</small>
          </p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="filters">
      <th mat-header-cell *matHeaderCellDef>
        <div class="filter-icons" *ngIf="logTypeCounts">
          <button class="filter-icon filter-icon-error"
                  [class.filter-icon-disabled]="!logTypeVisibility.errorLogCount"
                  (click)="toggleView(AuditConsoleLogCounts.errorLogCount)"
                  matTooltip="Errors"
                  matTooltipPosition="above">
            <mat-icon>cancel</mat-icon>
            <span class="monospaced">{{ logTypeCounts.errorLogCount }}</span>
          </button>
          <button class="filter-icon filter-icon-warning"
                  [class.filter-icon-disabled]="!logTypeVisibility.warnLogCount"
                  (click)="toggleView(AuditConsoleLogCounts.warnLogCount)"
                  matTooltip="Warnings"
                  matTooltipPosition="above">
            <mat-icon>warning</mat-icon>
            <span class="monospaced">{{ logTypeCounts.warnLogCount }}</span>
          </button>
          <button class="filter-icon filter-icon-debug"
                  [class.filter-icon-disabled]="!logTypeVisibility.debugLogCount"
                  (click)="toggleView(AuditConsoleLogCounts.debugLogCount)"
                  matTooltip="Debugs"
                  matTooltipPosition="above">
            <mat-icon>bug_report</mat-icon>
            <span class="monospaced">{{ logTypeCounts.debugLogCount }}</span>
          </button>
          <button class="filter-icon filter-icon-info"
                  [class.filter-icon-disabled]="!logTypeVisibility.infoLogCount"
                  (click)="toggleView(AuditConsoleLogCounts.infoLogCount)"
                  matTooltip="Info"
                  matTooltipPosition="above">
            <mat-icon>info</mat-icon>
            <span class="monospaced">{{ logTypeCounts.infoLogCount }}</span>
          </button>
          <button class="filter-icon filter-icon-other"
                  [class.filter-icon-disabled]="!logTypeVisibility.otherLogCount"
                  (click)="toggleView(AuditConsoleLogCounts.otherLogCount)"
                  matTooltip="Text"
                  matTooltipPosition="above">
            <mat-icon>chat</mat-icon>
            <span class="monospaced">{{ logTypeCounts.otherLogCount }}</span>
          </button>
        </div>
      </th>
      <td mat-cell *matCellDef="let log"></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let item; columns: displayedColumns" class="{{ item.level | lowercase }}"></tr>
  </table>
  <mat-paginator
    *ngIf="dataSource.data?.length && reportType === EReportType.AUDIT"
    [length]="tableState.pagesTotal"
    [pageSize]="tableState.pagination.size"
    [pageIndex]="tableState.pagination.page"
    (page)="onChangePage($event)"
    [hidePageSize]="true"
  ></mat-paginator>
</div>
