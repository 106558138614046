// op-filter-bar.component.ts

import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  IOpFilterBarFilterSearchOption,
  IOpFilterBarInvertableFilter,
  IOpFilterBarMenuItem,
  ISearchByTextEmissionData
} from './op-filter-bar.models';
import { CalculateWordWidth } from '@app/components/domains/discoveryAudits/reporting/services/calculateWordWidthService/calculateWordWidthService';
import { OpFilterBarService } from './op-filter-bar.service';
import { ActivatedRoute } from '@angular/router';
import { IOpFilterBarFilter } from './op-filter-bar.models';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { EFilterBarMenuTypes } from './op-filter-bar.constants';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { EAuditReportFilterTypes } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';

@Component({
  selector: 'op-filter-bar',
  templateUrl: './op-filter-bar.component.html',
  styleUrls: ['./op-filter-bar.component.scss'],
  providers: [{
    provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
    useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
  }]
})
export class OpFilterBarComponent implements AfterViewInit, OnDestroy {
  @ViewChild('searchByTextInput') searchByTextInput: ElementRef;

  @Input() menuItems: IOpFilterBarMenuItem[];
  @Input() service: OpFilterBarService<any>;
  @Input() isSearchByTextEnabled: boolean;
  @Input() isReadOnly: boolean;
  @Input() readOnlyLabel: string;
  @Input() searchByTextPlaceholderSuffix: string;
  @Input() validFilterTypes: any[];
  @Input() matMenuClass: string | string[];
  @Input() additionalSearchOptions: IOpFilterBarFilterSearchOption[];

  @Input() hideSearchByRegexOption = false;
  @Input() keepWide = false; // Set true to always display input in wide mode
  @Input() enableTypeahead = false;
  @Input() collapsablePills = true;
  @Input() disabled = false;

  @Output() searchByTextIsFocused = new EventEmitter<void>();
  @Output() searchByTextIsEntered = new EventEmitter<ISearchByTextEmissionData>();
  @Output() invertableFilterToggled = new EventEmitter<IOpFilterBarInvertableFilter<string>>();

  // filters
  @ViewChild('filterChipBag') filterChipBag: ElementRef;
  // search parent
  @ViewChild('parentDivSearch') parentDivSearch: ElementRef;

  visibleFilters: IOpFilterBarFilter<string>[] = [];
  hiddenFilters: IOpFilterBarFilter<string>[] = [];
  hiddenContainsValidTypes = false;
  auditId: number;
  runId: number;
  modalServiceIndex: number;

  EAuditReportFilterTypes = EAuditReportFilterTypes;

  // search by url
  showTextSearchByRegexOpt = false;
  searchByTextAsRegex = false;
  setToHideRegexOpt: NodeJS.Timeout;
  ignoreSearchByUrlBlur = false;
  searchByTextInputValue = '';

  private filters: IOpFilterBarFilter<string>[] = [];
  private destroy = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateFilterChips();
  }

  constructor(
    private self: ElementRef,
    private calculateWordWidthService: CalculateWordWidth,
    private activatedRoute: ActivatedRoute,
    private modalEscapeService: ModalEscapeService,
    private snackbarService: SnackbarService
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;
    });
  }

  ngAfterViewInit(): void {
    // AfterViewInit ensures that all sizes are accurate
    // setTimeout ensures we avoid the modified-after-checked Angular error
    this.service.anyFiltersUpdates$.pipe(takeUntil(this.destroy)).subscribe(filters => {
      this.filters = [ ...new Set(filters) ];
      setTimeout(this.calculateFilterChips.bind(this));
    });

    this.service.validFilterTypesUpdated$.pipe(takeUntil(this.destroy)).subscribe(validFilterTypes => {
      setTimeout(this.calculateFilterChips.bind(this));
    });

    // ensure the filter chips are measured correctly and display properly
    setTimeout(this.calculateFilterChips.bind(this));
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  // filters
  calculateFilterChips(wide = false) {
    this.collapsablePills
      ? this.calculateCollapsableFilterChips(wide)
      : this.calculateNonCollapsableFilterChips();
  }

  private calculateCollapsableFilterChips(wide = false): void {
    const filterBarComponentWidth = !wide ? this.self.nativeElement.offsetWidth : this.self.nativeElement.offsetWidth - 350;
    const filterChipBagLeft = this.filterChipBag.nativeElement.offsetLeft;
    const reservedSpaceForMoreChip = 100;
    const spaceForChips = filterBarComponentWidth - filterChipBagLeft - reservedSpaceForMoreChip;

    // NOTE: config should match font style properties for chips
    const config = {
      font: 'Open Sans',
      fontSize: '13px'
    };

    const visible = [];
    const hidden = [];
    let hiddenHasValidTypes = false;
    let runningWidth = 0;
    [ ...this.filters ]
      .sort((a, b) => a.order > b.order ? 1 : -1)
      .forEach(f => {

        /**
         * chipChromeExtraWidth includes margin. NOTE: Updating the style means this needs to be updated
         *
         * Below is how we're calculating the width of the invertible chips
         * 47px (margins and X button) + 62px (invertible toggle) = 109px
         *
         * if chip is not invertible then it's just the margins and X button (47px)
         */
        const chipChromeExtraWidth = f['postDisplay'] ? 109 : 47;
        const chipText = f['postDisplay'] ? `${f.display} ${f['postDisplay']}` : f.display;
        runningWidth += this.calculateWordWidthService.calculate(chipText, config).width + chipChromeExtraWidth;
        if (runningWidth > spaceForChips) {
          hidden.push(f);
          if (this.validFilterTypes.includes(f.type)) hiddenHasValidTypes = true;
        } else {
          visible.push(f);
        }
      });

    this.visibleFilters = visible;
    this.hiddenFilters = hidden;
    this.hiddenContainsValidTypes = hiddenHasValidTypes;
  }

  private calculateNonCollapsableFilterChips(): void {
    this.visibleFilters = [ ...this.filters ].sort((a, b) => a.order > b.order ? 1 : -1);
    this.hiddenFilters = [];
    this.hiddenContainsValidTypes = false;
  }

  removeFilter(filter: IOpFilterBarFilter<string>): void {
    this.service.removeFilter(filter);
    this.calculateFilterChips();
  }

  // URL Filtering
  searchByTextMouseDown(): void {
    this.ignoreSearchByUrlBlur = true;
  }

  searchByTextFocused(): void {
    this.searchByTextIsFocused.emit();

    this.parentDivSearch.nativeElement.classList.add('wide');
    this.calculateFilterChips(true);
    if (this.setToHideRegexOpt) {
      clearTimeout(this.setToHideRegexOpt);
      this.setToHideRegexOpt = null;
    }
    this.showTextSearchByRegexOpt = true;
  }

  focusout(event: KeyboardEvent): void {
    const searchByTextInputValue = (event.target as HTMLInputElement).value;
    if (searchByTextInputValue == '') {
      if (!this.keepWide) {
        this.parentDivSearch.nativeElement.classList.remove('wide');
      }

      // gives the animation time to complete so it's calculated correctly
      setTimeout(() => this.calculateFilterChips(false), 350);
    }
  }

  searchByTextBlurred(): void {
    if (!this.ignoreSearchByUrlBlur) {
      this.setToHideRegexOpt = setTimeout(() => this.showTextSearchByRegexOpt = false, 0);
    }
    this.ignoreSearchByUrlBlur = false;
  }

  async searchByTextKeyUp(e: KeyboardEvent): Promise<void> {
    const searchByTextInputValue = (e.target as HTMLInputElement).value;
    const hasSearchFilter = !!this.filters.filter(filter => filter.type === EFilterBarMenuTypes.Search).length;

    if (!hasSearchFilter && this.enableTypeahead && !(e.key === 'Enter')) {
      this.handleTypeahead(searchByTextInputValue);
      return;
    }

    switch (e.key) {
      case 'Enter':
        const value = (e.target as HTMLInputElement).value;
        if (value) {

          if (!await this.service.searchableValueValidator(value, this.searchByTextAsRegex)) {
            return;
          }

          const searchByTextData = {
            value: (e.target as HTMLInputElement).value,
            regex: this.searchByTextAsRegex
          };

          this.searchByTextIsEntered.emit(searchByTextData);
          this.searchByTextInputValue = '';
          this.showTextSearchByRegexOpt = false;
          this.searchByTextAsRegex = false;
          this.parentDivSearch.nativeElement.classList.remove('wide');
          this.searchByTextInput.nativeElement.blur();
          this.focusout(e);
        }
        break;
      case 'Escape':
        if (!this.showTextSearchByRegexOpt) {
          if (searchByTextInputValue) {
            // regex opt box hidden and value present in text box... clear it.
            this.searchByTextInputValue = '';
            e.stopPropagation();
          }
        } else {
          // hide the regex opt box
          this.showTextSearchByRegexOpt = false;
          e.stopPropagation();
        }
        break;
      default:
        // re-show the regex opt box
        this.showTextSearchByRegexOpt = true;
        break;
    }
  }

  handleTypeahead(searchValue: string): void {
    const searchByTextData = {
      value: searchValue,
      regex: this.searchByTextAsRegex,
      isTypeahead: true
    };

    this.searchByTextIsEntered.emit(searchByTextData);
    this.showTextSearchByRegexOpt = false;
    this.searchByTextAsRegex = false;
  }

  toggleInvertableFilter(filter: IOpFilterBarInvertableFilter<string>): void {
    filter.state = !filter.state;
    this.invertableFilterToggled.emit(filter);
  }

  clearFilters(): void {
    this.service.clear();
  }

  onMenuOpened(): void {
    this.modalServiceIndex = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(this.modalServiceIndex);
  }

  onMenuClosed(): void {
    setTimeout(() => {
      this.modalEscapeService.remove(this.modalServiceIndex);
      this.modalServiceIndex = undefined;
    });
  }

  clearSearchByUrl(): void {
    this.searchByTextInputValue = '';

    if ((!this.visibleFilters.length && !this.hiddenFilters.length) && this.enableTypeahead) {
      this.handleTypeahead('');
    }
  }

  changeSearchByTextAsRegex() {
    this.searchByTextAsRegex = !this.searchByTextAsRegex;
  }
}
