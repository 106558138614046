<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Add to a consent category"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="add-consent-categories-container">
      <ng-container *ngIf="!loading; else loadingTpl">
        <p class="information-text">
          Select one or more Consent Categories that the
          {{selectedItemsText}} should be added to, or <span class="clickable-link" (click)="createNewCCWithSelection()" matTooltip="Create a new Consent Category using the selected {{selectedItemsText}}" matTooltipPosition="above" matTooltipShowDelay="200">create a new Consent Category</span> with this selection applied.
        </p>

        <div class="applied-table-wrapper">
          <table mat-table [dataSource]="appliedAuditCCs" class="consent-categories-table">
            <!-- Checkbox Column -->
            <ng-container matColumnDef="checkbox">
              <th mat-header-cell [attr.colspan]="2" *matHeaderCellDef class="radio-column">Applied to this audit</th>
              <td mat-cell *matCellDef="let cc">
                <mat-checkbox class="select-radio-button"
                              color="primary"
                              (change)="onAssignedCheckboxChanged($event, cc)"
                              [checked]="appliedSelection.isSelected(cc)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}" class="name-column"></th>
              <td mat-cell *matCellDef="let cc" class="name-column wrap-text"> {{cc.name}} </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef class="type-column">TYPE</th>
              <td mat-cell *matCellDef="let cc" class="type-column consent-category-type"> {{cc.type}} </td>
            </ng-container>

            <!-- Labels Column -->
            <ng-container matColumnDef="labels">
              <th mat-header-cell *matHeaderCellDef class="labels-column">LABELS</th>
              <td mat-cell *matCellDef="let cc" class="labels-column wrap-text"> {{cc.labels}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <!-- No CCs assigned to audit -->
          <ng-container *ngIf="!appliedAuditCCs?.length">
            <p class="no-categories-assigned">
              No consent categories have been assigned to this audit. <span class="clickable-link" (click)="openAuditEditorToConsentCategoryStandard($event)">Assign one now</span>.
            </p>
          </ng-container>
        </div>

        <mat-expansion-panel [expanded]="notAppliedSectionExpanded"
                             (click)="notAppliedSectionExpanded = !notAppliedSectionExpanded;"
                             hideToggle
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{notAppliedSectionExpanded ? 'Hide' : 'See'}} full list of Consent Categories in your account.
              <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <div class="panel-body"
                 *ngIf="allAccountCCsLoading"
                 (click)="$event.stopImmediatePropagation()">
              <mat-spinner class="spinner" [diameter]="30" [strokeWidth]="4"></mat-spinner>
            </div>
            <div class="panel-body"
                 *ngIf="!allAccountCCsLoading"
                 (click)="$event.stopImmediatePropagation()"
            >
              <op-clearable-input placeholderText="Search by Consent Category name, type, or label"
                                  [emitKeyboardEvent]="true"
                                  class="table-filter-input"
                                  (onUserInput)="debounceFilterData($event)">
              </op-clearable-input>
              <div class="all-ccs-table-wrapper" [class.no-cc-assigned]="appliedAuditCCs?.length === 0">
                <table mat-table
                       #auditCCsTable
                       [dataSource]="filteredNotAppliedCCs"
                       class="consent-categories-table"
                >
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="checkbox">
                    <th mat-header-cell [attr.colspan]="2" *matHeaderCellDef class="radio-column">Not applied to this audit</th>
                    <td mat-cell *matCellDef="let cc">
                      <mat-checkbox class="select-radio-button"
                                    color="primary"
                                    (change)="onNotAppliedCheckboxChanged($event, cc)"
                                    [checked]="notAppliedSelection.isSelected(cc)"
                      >
                      </mat-checkbox>
                    </td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}" class="name-column"></th>
                    <td mat-cell *matCellDef="let cc" class="name-column wrap-text"> {{cc.name}} </td>
                  </ng-container>

                  <!-- Type Column -->
                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef class="type-column">TYPE</th>
                    <td mat-cell *matCellDef="let cc" class="type-column consent-category-type"> {{cc.type}} </td>
                  </ng-container>

                  <!-- Labels Column -->
                  <ng-container matColumnDef="labels">
                    <th mat-header-cell *matHeaderCellDef class="labels-column">LABELS</th>
                    <td mat-cell *matCellDef="let cc" class="labels-column wrap-text"> {{cc.labels}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
              </div>
            </div>
          </ng-template>
        </mat-expansion-panel>
        <div class="full-list-text"></div>
      </ng-container>
    </div>
    <ng-template #loadingTpl>
      <mat-spinner class="spinner" [diameter]="30" [strokeWidth]="4"></mat-spinner>
    </ng-template>
  </ng-template>
</op-modal>
