import { Component, EventEmitter, Input, Output, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { IAuditModel } from '@app/components/modals/modalData';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Features } from '@app/moonbeamConstants';
import { DiscoveryAuditService, IAuditRunExecutionInfo } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { AccountsService } from '@app/components/account/account.service';
import { IUser } from '@app/moonbeamModels';
import { LocationsService } from '@app/components/shared/services/locations.service';
import { IGeoLocation } from '@app/components/shared/services/locations.models';
import { EAuditRunStatus, EAuditStatusPillText } from '../audit-report-header/audit-report-header.constants';
import { IAuditRunStatus } from '../audit-report-header/audit-report-header.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AuditReportInfoModalComponent } from './audit-report-info-modal/audit-report-info-modal.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-report-info',
  templateUrl: './audit-report-info.component.html',
  styleUrls: ['./audit-report-info.component.scss']
})
export class AuditReportInfoComponent implements OnInit, OnChanges {
  ccCount: number = 0;
  isCCEnabled: boolean = true;
  recipientWarningHidden: boolean = false;
  ccWarningHidden: boolean = false;
  labelNames: string;
  runSnapshot: IAuditRunExecutionInfo;
  ownerName: string;
  browserViewport: string = 'Unknown';
  loginActionsFound: boolean;
  runDuration: string;
  location: string;
  locationCountryCode: string;
  scanSpeed: string;
  fullBrowserVersion: string;
  enabledInfoItems: string[] = [];
  locationsById: Map<number, IGeoLocation>;
  EAuditRunStatus = EAuditRunStatus;
  EAuditStatusPillText = EAuditStatusPillText;
  sampleOfFailures: any[] = [];

  private users: IUser[];

  @Input() folderName: string;
  @Input() domainName: string;
  @Input() audit: IAuditModel;
  @Input() runId: number;
  @Input() auditRunStatus: IAuditRunStatus;
  @Input() auditHasNotRunYet?: boolean = false;
  @Output() disableWarning = new EventEmitter();
  @Output() editAudit = new EventEmitter();
  @Output() viewPagesWithFailedActions = new EventEmitter();

  constructor(
    private accountsService: AccountsService,
    private auditService: DiscoveryAuditService,
    private authenticationService: AuthenticationService,
    private locationsService: LocationsService,
    private modalService: OpModalService
  ) {}

  ngOnInit() {
    this.authenticationService.getFeaturesWithCache().subscribe(features => {
      this.isCCEnabled = features.includes(Features.productLinePrivacy);
    });

    this.locationsService.locationsMapById$.subscribe(locationsById => {
      this.locationsById = locationsById;
    });
    }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.audit && changes.audit.currentValue?.id !== undefined) {
        this.labelNames = this.audit.labels.reduce((acc, label, i) =>
        acc + label.name + (i < this.audit.labels.length - 1 ? ', ' : '')
        , '');

      if (!this.users) {
        this.accountsService.getUsers().subscribe(users => {
          this.users = users;
          this.setOwner();
        });
      } else {
        this.setOwner();
      }
    }

    if (this.audit && this.runId) {
      this.auditService.getAuditRunInfo(this.audit.id, this.runId)
        .then(runSnapshot => {
            this.runSnapshot = runSnapshot;
            this.loginActionsFound = runSnapshot.loginActions;
            this.browserViewport = `${runSnapshot.width} x ${runSnapshot.height}`;
            this.runDuration = this.getRunDuration();
            this.location = this.locationsById?.get(runSnapshot.locationId)?.label || 'Unknown';
            this.locationCountryCode = this.locationsById?.get(runSnapshot.locationId)?.countryCode || null;
            this.scanSpeed = this.getScanSpeed();
            this.fullBrowserVersion = this.getFullBrowserVersion();
            this.enabledInfoItems = this.auditService.getEnabledInfoItems(runSnapshot, this.locationsById);
          },
          error => {
            if (error.code === 404) {
              // ignore - expected for older runs where snapshot information not available
            } else {
              console.log('Audit Run Snapshot Info request failed', error);
            }
          }
        );
    }

    if (changes.auditRunStatus) {
      this.sampleOfFailures = this.auditRunStatus?.errors?.onPagesActionResults?.resultsSample?.map(result => {
        const actionId = result.results[0].auditActionSnapshotId;
        const actionName = this.auditRunStatus?.snapshot?.onPageActionSnapshots?.find(action => action.actionSnapshotId === actionId)?.description ?? '';
        const errorDetails = {
          url: result.pageUrl,
          actionName: `${actionId}`,
          message: result.results[0].failureMessage,
        }

        if (actionName) {
          errorDetails.actionName += ` ${actionName}`; // need a space here if name exists
        }

        return errorDetails;
      });
    }
  }

  hideRecipientWarning(event) {
    event.stopPropagation();
    this.recipientWarningHidden = true;
    const ccWarningHiddenOrNotPresent = this.isCCEnabled ||
      !this.isCCEnabled && this.ccWarningHidden;

    // Disable warning highlight in parent if cp is also in a good state or already toggled off
    if (ccWarningHiddenOrNotPresent) this.disableWarning.emit();
  }

  hideCCWarning(event) {
    event.stopPropagation();
    this.ccWarningHidden = true;
    const recipientWarningHiddenOrNotPresent = this.audit?.recipients?.length > 0
      || !(this.audit?.recipients?.length > 0) && this.recipientWarningHidden;

    // Disable warning highlight in parent if recipient field is also in a good state or already toggled off
    if (recipientWarningHiddenOrNotPresent) this.disableWarning.emit();
  }

  openInfoModal(): void {
    this.modalService.openFixedSizeModal(AuditReportInfoModalComponent, {
      data: this.auditRunStatus
    })
    .afterClosed()
    .subscribe(response => {
      if (response?.editAudit) {
        this.editAudit.emit();
      }
    });
  }

  private setOwner(): void {
    const owner = this.users.find(u => u.id === this.audit.ownerId);
    this.ownerName = `${owner?.firstName} ${owner?.lastName}`;
  }

  private getRunDuration(): string {
    if (!this.runSnapshot?.runDuration) {
      return 'Unknown';
    }

    let runDurationToString = '';

    const hours = Math.floor(this.runSnapshot.runDuration / 3600);
    if (hours > 0) runDurationToString += `${hours} hr. `;
    const minutes = Math.floor((this.runSnapshot.runDuration % 3600) / 60);
    if (minutes > 0) runDurationToString += `${minutes} min. `;
    const seconds = this.runSnapshot.runDuration - hours * 3600 - minutes * 60;
    if (seconds > 0) runDurationToString += `${seconds} sec.`;

    return runDurationToString.trimEnd();
  }

  private getScanSpeed(): string {
    if (!this.runSnapshot?.scanSpeed) {
      return 'Unknown';
    } else {
      return `${this.runSnapshot.scanSpeed} concurrent browsers`;
    }
  }

  private getFullBrowserVersion(): string {
    if (!this.runSnapshot?.browserName && !this.runSnapshot?.browserVersion) {
      return 'Only available on newer runs';
    } else if (!this.runSnapshot?.browserName && this.runSnapshot?.browserVersion) {
      return this.runSnapshot.browserVersion;
    } else if (this.runSnapshot?.browserName && !this.runSnapshot?.browserVersion) {
      return this.runSnapshot.browserName;
    } else {
      return `${this.runSnapshot.browserName} ${this.runSnapshot.browserVersion}`;
    }
  }
}
