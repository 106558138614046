<div class="library-wrap">
  <div class="title-container">
    <h1 class="title">File Substitutions</h1>
    <op-button-2021 labelText="Create New"
                    matIcon="control_point"
                    (buttonAction)="createNewConfig()"
                    buttonType="filled">
    </op-button-2021>
  </div>
  <div class="spinner-container" *ngIf="loading">
    <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
  </div>
  <div class="filter-input" [class.hidden]="loading">
    <op-clearable-input
      placeholderText="Filter File Substitution Configs"
      (onUserInput)="filterRfmConfigs($event)">
    </op-clearable-input>
  </div>

  <div class="rfm-library-table-wrap">
    <table class="styled-mat-table" [class.loading]="loading" mat-table matSort [dataSource]="dataSource">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let rfmConfig">
          <span class="hover-link" (click)="editConfig(rfmConfig)">
            {{ rfmConfig.name }}
          </span>
        </td>
      </ng-container>

      <!-- Match Type Column -->
      <ng-container matColumnDef="matchTypeFormatted">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Match Type</th>
        <td mat-cell *matCellDef="let rfmConfig">{{ rfmConfig.matchTypeFormatted }}</td>
      </ng-container>

      <!-- File Location Column -->
      <ng-container matColumnDef="fileLocation">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>File Location</th>
        <td mat-cell *matCellDef="let rfmConfig">{{ rfmConfig.fileLocation }}</td>
      </ng-container>

      <!-- Create By Column -->
      <ng-container matColumnDef="createdByName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Created By</th>
        <td mat-cell *matCellDef="let rfmConfig">{{ rfmConfig.createdByName }}</td>
      </ng-container>

      <!-- Created Date Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Created Date</th>
        <td mat-cell *matCellDef="let rfmConfig">{{ rfmConfig.createdAt | date:headerDateFormat }}</td>
      </ng-container>

      <!-- Last Updated Column -->
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Updated</th>
        <td mat-cell *matCellDef="let rfmConfig">{{ rfmConfig.updatedAt | date:headerDateFormat }}</td>
      </ng-container>

      <!-- Edit/Delete Column -->
      <ng-container matColumnDef="editDelete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let rfmConfig" align="right">
          <div class="table-actions">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon class="more-menu-btn">more_vert</mat-icon>
            </button>
          </div>
          <mat-menu #menu="matMenu" class="rfm-lib-menu">
            <button mat-menu-item class="table-action-icon" (click)="editConfig(rfmConfig)" matTooltip="Edit" matTooltipPosition="above">
              <mat-icon title="Edit">edit</mat-icon>
              Edit
            </button>
            <button mat-menu-item class="table-action-icon" (click)="copyConfig(rfmConfig)" matTooltip="Copy" matTooltipPosition="above">
              <mat-icon title="Copy">content_copy</mat-icon>
              Copy
            </button>
            <button mat-menu-item class="table-action-icon" *ngIf="currentUser.id === rfmConfig.createdBy || userIsAdmin"
                 (click)="deleteConfig(rfmConfig)"
                 matTooltip="Delete" matTooltipPosition="above">
              <mat-icon title="Delete">delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="hoverable" *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr *matNoDataRow>
        <td [colSpan]="displayedColumns.length">
          <div *ngIf="!loading" class="no-data">
            <span>No file substitutions matched applied filters.</span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
