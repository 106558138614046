import { Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import {
  IPagesDetailsAccessibilitySeverityFilter
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.models';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  PageDetailsAccessibilitySeverityTooltips
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-details-accessibility-widget',
  templateUrl: './page-details-accessibility-widget.component.html',
  styleUrls: ['./page-details-accessibility-widget.component.scss']
})
export class PageDetailsAccessibilityWidgetComponent implements OnDestroy {
  protected readonly PageDetailsAccessibilitySeverityTooltips = PageDetailsAccessibilitySeverityTooltips;
  private _widget: IPagesDetailsAccessibilitySeverityFilter;
  private subscription: Subscription;
  formControl: FormControl;

  @Input() set widget(item: IPagesDetailsAccessibilitySeverityFilter) {
    this._widget = item;
    this.formControl = new FormControl(item.checked);
    this.subscription?.unsubscribe();
    this.subscription = this.formControl.valueChanges
      .subscribe(checked => this.onClick(checked));
  }

  get widget(): IPagesDetailsAccessibilitySeverityFilter {
    return this._widget;
  }

  @Output() valueChanged: EventEmitter<IPagesDetailsAccessibilitySeverityFilter> = new EventEmitter<IPagesDetailsAccessibilitySeverityFilter>();

  @HostBinding('class.checked') get isChecked(): boolean {
    return this.formControl?.value;
  }

  onClick(checked: boolean): void {
    if (!this.widget) {
      return;
    }
    this.valueChanged.emit({
      ...this.widget,
      checked,
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
