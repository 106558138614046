import { INavSection } from '@app/components/navigation/modal-sidebar/modal-sidebar.models';
import { AdminPortalUrlBuilders } from '@app/components/admin-portal/admin-portal.constants';
import { Component } from '@angular/core';
import { AdminPortalTitles } from './admin-portal.constants';
import { userIsOPSysAdmin } from '@app/authUtils';
import { AccountsService } from '@app/components/account/account.service';
import { componentLibrarySection, ComponentLibraryTitles, ComponentLibraryUrlBuilders } from './component-library/component-library.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.scss']
})
export class AdminPortalComponent {
  navSections: INavSection[] = [{
    sectionName: AdminPortalTitles.home,
    navs: [{
      title: AdminPortalTitles.mainApp,
      link: AdminPortalUrlBuilders.mainApp()
    }]
  }, {
    sectionName: AdminPortalTitles.general,
    navs: [{
      title: AdminPortalTitles.manageAccounts,
      link: AdminPortalUrlBuilders.accounts()
    }, {
      title: AdminPortalTitles.itemFinder,
      link: AdminPortalUrlBuilders.itemFinder()
    }, {
      title: AdminPortalTitles.verifyAccess,
      link: AdminPortalUrlBuilders.verifyAccess()
    }]
  }, {
    sectionName: AdminPortalTitles.tagDatabase,
    navs: [{
      title: AdminPortalTitles.tagDatabaseLibrary,
      link: AdminPortalUrlBuilders.tagDatabaseLibrary()
    }, {
      title: AdminPortalTitles.tagDatabaseRequestCheck,
      link: AdminPortalUrlBuilders.tagDatabaseRequestCheck()
    }]
  }, {
    sectionName: AdminPortalTitles.systemStatus,
    navs: [{
      title: AdminPortalTitles.systemStatusAudits,
      link: AdminPortalUrlBuilders.systemStatusAudits()
    }, {
      title: AdminPortalTitles.systemStatusWebJourneys,
      link: AdminPortalUrlBuilders.systemStatusWebJourneys()
    }]
  }];

  cleanUpSection = {
    sectionName: AdminPortalTitles.misc,
    navs: [
      {
        title: AdminPortalTitles.cleanUp,
        link: AdminPortalUrlBuilders.cleanUp(),
      }
    ]
  };

  constructor(private accountsService: AccountsService) {
    // alphabetize component library navs
    componentLibrarySection.navs.sort((a, b) => a.title.localeCompare(b.title));

    this.accountsService.getUser().subscribe(user => {
      if (userIsOPSysAdmin(user)) {
        this.navSections.push(this.cleanUpSection);
        this.navSections.push(componentLibrarySection);
      }
    });
  }
}
