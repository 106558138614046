<div class="section-title-wrap">
  <div class="title-spinner-wrap">
    <filter-spinner class="left-side" [state]="spinnerState"></filter-spinner>
    <p class="section-title">Cookie Compliance</p>
    <op-audit-report-export-menu
      [auditId]="auditId"
      [runId]="runId"
      [tableCompletedState]="spinnerState"
      [config]="exportConfig">
    </op-audit-report-export-menu>
  </div>

  <op-switchable-column-menu [columnConfig]="CookiesTableColumns"
                             [storageKey]="PrivacyCookiesConfigLocalStorageKey"></op-switchable-column-menu>
</div>

<div class="compliance-table-container" [class.loading]="spinnerState === EFilterSpinnerState.Loading">
  <table
    mat-table
    matSort
    multiTemplateDataRows
    matSortDirection="desc"
    matSortStart="desc"
    [class.is-filtered]="isFiltered"
    [matSortActive]="CookiesListTableColumns.ConsentCategoryComplianceStatus"
    [dataSource]="tableDataSource"
  >
    <ng-container [matColumnDef]="CookiesListTableColumns.CookieName">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        COOKIE NAME
      </th>
      <td mat-cell
          *matCellDef="let row">
        <div class="cookie-name-container">
          <div class="text-wrap">
            <div class="text"
                 opTableCellTooltip="{{ row[CookiesListTableColumns.CookieName] }}">
              {{ row[CookiesListTableColumns.CookieName] }}
            </div>
          </div>
          <actions-menu>
            <button mat-menu-item
                    (click)="setCookieNameGlobalFilter(row[CookiesListTableColumns.CookieName])">
              <mat-icon>tune</mat-icon>
              Filter report by this item
            </button>
            <button mat-menu-item
                    (click)="handleSetFilterAndNavigate(row[CookiesListTableColumns.CookieName])">
              <mat-icon>tune</mat-icon>
              View additional details on Cookie Inventory
            </button>
          </actions-menu>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="CookiesListTableColumns.CookieDomain">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        COOKIE DOMAIN
      </th>
      <td mat-cell
          *matCellDef="let row">
        <div class="text-wrap">
          <div class="text"
               opTableCellTooltip="{{ row[CookiesListTableColumns.CookieDomain] }}">
            {{ row[CookiesListTableColumns.CookieDomain] }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="CookiesListTableColumns.ConsentCategoryComplianceStatus">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header>
        STATUS
      </th>
      <td mat-cell
          [class.color-graph-red]="row[CookiesListTableColumns.ConsentCategoryComplianceStatus] === 'unapproved'"
          [class.color-graph-green]="row[CookiesListTableColumns.ConsentCategoryComplianceStatus] === 'approved'"
          *matCellDef="let row; let index;"
      >
        <div *ngIf="row[CookiesListTableColumns.ConsentCategoryComplianceStatus]; else noConsentCategories">
          <mat-icon>{{row[CookiesListTableColumns.ConsentCategoryComplianceStatus] === 'unapproved' ? 'warning' : 'check_circle'}}</mat-icon>
          {{ row[CookiesListTableColumns.ConsentCategoryComplianceStatus] }}

          <button mat-icon-button
                  class="trigger-button"
                  [matMenuTriggerFor]="statusActionMenu"
                  (click)="statusActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
          >
            <mat-icon class="trigger-icon">more_vert</mat-icon>
          </button>
          <mat-menu #statusActionMenu="matMenu"
                    class="cc-action-menu"
                    (closed)="statusActionMenuOpen = false; ccActionIndex = null"
          >
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    (click)="addToConsentCategory(row, $event)"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} & Add this to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} multiple items & Assign to existing or new Consent Category
            </button>
            <button mat-menu-item
                    (click)="setConsentCategoryTypeGlobalFilter(row[CookiesListTableColumns.ConsentCategoryComplianceStatus])">
              <mat-icon>tune</mat-icon>
              Filter report by all "{{ row[CookiesListTableColumns.ConsentCategoryComplianceStatus]?.charAt(0).toUpperCase()
            + row[CookiesListTableColumns.ConsentCategoryComplianceStatus]?.slice(1) }}" items
            </button>
          </mat-menu>
        </div>
        <ng-template #noConsentCategories>
          <i>Uncategorized</i>
          <button mat-icon-button
                  class="trigger-button"
                  [matMenuTriggerFor]="statusActionMenu"
                  (click)="statusActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
          >
            <mat-icon class="trigger-icon">more_vert</mat-icon>
          </button>
          <mat-menu #statusActionMenu="matMenu"
                    class="cc-action-menu"
                    (closed)="statusActionMenuOpen = false; ccActionIndex = null"
          >
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    (click)="addToConsentCategory(row, $event)"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              Add this item to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              Add multiple items to a new or existing Consent Category
            </button>
          </mat-menu>
        </ng-template>

      </td>
    </ng-container>

    <ng-container [matColumnDef]="CookiesListTableColumns.ConsentCategoryName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        CONSENT CATEGORY
      </th>
      <td mat-cell
          *matCellDef="let row; let index;">
        <div *ngIf="row[CookiesListTableColumns.ConsentCategoryName] else addCategory">
          <div class="consent-category-name-container">
            <div class="text-wrap">
              <div class="text"
                   opTableCellTooltip="{{ row[CookiesListTableColumns.ConsentCategoryName] }}">
                {{ row[CookiesListTableColumns.ConsentCategoryName] }}
              </div>
            </div>
            <button mat-icon-button
                    class="trigger-button"
                    [matMenuTriggerFor]="ccActionMenu"
                    (click)="ccActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
            >
              <mat-icon class="trigger-icon">more_vert</mat-icon>
            </button>
            <mat-menu #ccActionMenu="matMenu"
                      class="cc-action-menu"
                      (closed)="ccActionMenuOpen = false; ccActionIndex = null"
            >
              <button mat-menu-item
                      [disabled]="userIsReadOnly"
                      (click)="addToConsentCategory(row, $event)"
              >
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} & Add this to a Consent Category
              </button>
              <button mat-menu-item
                      [disabled]="userIsReadOnly"
                      (click)="onManageConsentCategories.emit()"
              >
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} multiple items & Assign to existing or new Consent Category
              </button>
              <button mat-menu-item
                      (click)="setConsentCategoryNameGlobalFilter(row)">
                <mat-icon>tune</mat-icon>
                Filter report by this Consent Category
              </button>
            </mat-menu>
          </div>
        </div>
        <ng-template #addCategory>
          <div class="cc-action-icon"
               *ngIf="!userIsReadOnly && !runConsentCategories?.length"
               (click)="openAuditEditorToConsentCategoryStandard($event)"
          >
            <mat-icon class="add-category">add_circle_outline</mat-icon>
            <span class="cc-action-text">Assign</span>
          </div>
          <div class="cc-action-icon"
               [class.cc-action-menu-open]="ccActionMenuOpen && ccActionIndex === index"
               *ngIf="!userIsReadOnly && runConsentCategories?.length"
               (click)="addToConsentCategory(row, $event)"
          >
            <mat-icon class="add-category">add_circle_outline</mat-icon>
            <span class="cc-action-text">
              {{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve & Add' : 'Unapprove & Add'}}
            </span>
          </div>
          <button mat-icon-button
                  class="trigger-button"
                  [matMenuTriggerFor]="ccActionMenu"
                  (click)="ccActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
          >
            <mat-icon class="trigger-icon">more_vert</mat-icon>
          </button>
          <mat-menu #ccActionMenu="matMenu"
                    class="cc-action-menu"
                    (closed)="ccActionMenuOpen = false; ccActionIndex = null"
          >
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="!runConsentCategories?.length"
                    (click)="addToConsentCategory(row, $event)"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              Add this item to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="!runConsentCategories?.length"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              Add multiple items to a new or existing Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="runConsentCategories?.length"
                    (click)="addToConsentCategory(row, $event)"
            >
              <mat-icon>{{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve & ' : 'Unapprove & '}}Add this to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="runConsentCategories?.length"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}}multiple items & Assign to existing or a new Consent Category
            </button>
          </mat-menu>
        </ng-template>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="CookiesListTableColumns.Origin">
      <th mat-header-cell
          mat-sort-header
          [start]="'asc'"
          *matHeaderCellDef
          class="center-text">
        ORIGINS
      </th>
      <td mat-cell *matCellDef="let cookie" class="center-text">
        <div class="cookie-origins"
             [matTooltip]="cookie.origin.tooltip"
             matTooltipPosition="above">
          {{ cookie.origin.text }}
          <mat-icon class="cookie-origins-icon"
                    *ngIf="cookie.origin.icon">
            {{ cookie.origin.icon }}
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="CookiesListTableColumns.FilteredPageCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span># OF PAGES</span>
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="chart-wrapper" [class.selected]="isLineSelected(row)">
          <horizontal-bar-chart [data]="row.onPages.chartData"
                                [barDirection]="row.onPages.barDirection"
                                [uniqueIdentifier]="row.onPages.uniqueIdentifier"
                                [state]="row.barChartSettings.state"
                                [displayPills]="row.barChartSettings.displayPills"
                                [calcAsPercentage]="row.barChartSettings.calcAsPercentage"
                                [displayPercentSymbol]="row.barChartSettings.displayPercentSymbol"
                                [textPosition]="row.barChartSettings.textPosition"
          ></horizontal-bar-chart>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async;"
        [class.selected]="row === selectedRow"
        (click)="selectRow(row)">
    </tr>
  </table>
</div>

<mat-paginator
  [length]="tableState?.pagesTotal"
  [pageSize]="tableState?.pagination.size"
  [pageIndex]="tableState?.pagination.page"
  [hidePageSize]="true"
></mat-paginator>
