import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Selection } from 'd3-selection';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IVerticalBarsChartBar, IVerticalBarsChartColumn } from './vertical-bars-chart.models';
import * as d3 from 'd3';
import { ScaleBand, ScaleLinear, Series } from 'd3';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'vertical-bars-chart',
  templateUrl: './vertical-bars-chart.component.html',
  styleUrls: ['./vertical-bars-chart.component.scss']
})
export class VerticalBarsChartComponent<D extends IVerticalBarsChartBar> implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() data: D[];
  @Input() subgroups: Array<keyof D> = [];
  @Input() limit: number;
  @Input() limitPlaceholder?: string;
  @Input() uniqueIdentifier: string;
  @Input() showYAxis: boolean = true;
  @Input() uppercaseText = false;
  @Input() isClickable = false;
  @Input() tooltipText = 'View this day';

  @Output() clicked = new EventEmitter<D>();

  container: Selection<any, any, any, any>;
  svg: Selection<any, any, any, any>;
  svgHeight: number;
  svgWidth: number;
  columns: IVerticalBarsChartColumn[];
  values: number[];
  bars: Selection<any, any, any, any>;
  barLabels: Selection<any, any, any, any>;
  barValues: Selection<any, any, any, any>;
  limitLine: Selection<any, any, any, any>;
  x: ScaleBand<any>;
  y: ScaleLinear<any, any, any>;
  yAxis: Selection<any, any, any, any>;
  formattedData: Series<{ [key: string]: number; }, string>[];
  loopCounter = 0;
  inTheFuture = false;
  tooltip: Selection<any, any, any, any>;

  protected normalisedBandWidth: number;
  protected readonly BAR_MAX_WIDTH = 100; //px

  protected resize$ = new Subject();
  protected destroy$ = new Subject();

  protected readonly MIN_CHART_HEIGHT = 150;
  protected readonly MIN_BAR_HEIGHT = 1;
  protected TOOLTIP_CLASS = '';
  protected readonly MARGIN = {
    TOP: 35,
    RIGHT: 0,
    BOTTOM: 25,
    LEFT: 10
  };

  ngOnInit(): void {
    this.resize$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.drawChart();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.drawChart(), 0);
  }

  ngOnChanges(): void {
    setTimeout(() => this.drawChart(), 0);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  drawChart(): void {
    // ensure we have data
    if (!this.data?.length) return;

    // format the data
    this.data = this.formatChartData(this.data);

    // get the dimensions of our container element
    this.container = d3.select(`.svg-container-${this.uniqueIdentifier}`).html('');
    if (this.container.empty()) return;

    // get dimensions of svg container
    this.svgHeight = Math.max(parseInt(this.container.style('height')), this.MIN_CHART_HEIGHT);
    this.svgWidth = parseInt(this.container.style('width'));

    // append svg element and set dimensions
    this.svg = this.container
      .append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    // get column labels and ids
    this.getColumns();

    // get all values to set y scale (we need to know the largest value that could be displayed)
    this.values = this.data.map(d => this.subgroups.reduce(
      (acc, subgroup) => acc += d[subgroup as string],
      0
    ));

    // y scale
    this.y = d3
      .scaleLinear()
      // if domain is [0, 0], the bars are drawn in the middle of y axis
      // in order to draw them at the bottom, the max domain value should be > than the min (in this cases, the domain is [0, 1e-9])
      .domain([0, d3.max([...this.values, this.limit ? (this.limit + Math.floor(this.limit / 10)) : 0, 1e-9])])
      .range([this.svgHeight - this.MARGIN.BOTTOM - this.MARGIN.TOP, 0]);

    // y axis
    this.yAxis = this.svg
      .append('g')
      .attr('dominant-baseline', 'ideographic')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(this.y).ticks(2));

    const { width: yAxisWidth } = this.yAxis.node().getBBox();

    this.yAxis.attr('transform', `translate(${this.showYAxis ? yAxisWidth + this.MARGIN.LEFT : 0}, ${this.MARGIN.TOP + 3})`);

    // x scale
    this.x = d3
      .scaleBand()
      .domain(this.columns.map(column => column.id))
      .range([0, this.svgWidth - (yAxisWidth + this.MARGIN.LEFT)])
      .paddingInner(0.1);

    // by default scaleBand() stretches bands to all range, there is no way to configure max width of the band
    // hence we have to adapt it ourselves considering the custom max band width
    this.normalisedBandWidth = Math.min(this.x.bandwidth(), this.BAR_MAX_WIDTH);

    // format data for chart
    this.formattedData = d3.stack().keys(this.subgroups as string[])(this.data as any);

    // bars
    this.buildBar(yAxisWidth);

    // bar labels (underneath bars)
    this.buildBarLabels(yAxisWidth);

    // bar values (on top of bars)
    this.buildBarValues(yAxisWidth);

    if (this.isClickable) {
      // tooltip
      // only create if it's not already on the page
      if (!d3.select(`.${this.TOOLTIP_CLASS}`).node()) this.createTooltip();

      // get reference to the DOM element
      this.tooltip = d3.select(`.${this.TOOLTIP_CLASS}`);

      this.bars.on('mouseover', (e: MouseEvent, d: any) => this.onBarsMouseOver(e, d));
      this.bars.on('mousemove', (e: MouseEvent) => this.handleTooltipPosition(e));
      this.bars.on('mouseout', () => this.onBarsMouseOut());
    }

    this.buildLimitLine();
  }

  protected getColumns() {
    this.columns = this.data.map(d => ({
      label: d.label,
      id: d.id
    }));
  }

  protected buildBar(yAxisWidth: number) {
    this.bars = this.svg
      .append('g')
      .attr('class', `${this.isClickable ? 'bars-clickable' : 'bars-not-clickable'} bars`)
      .attr('transform', `translate(${this.showYAxis ? yAxisWidth + this.MARGIN.LEFT : 0}, ${this.MARGIN.TOP})`)
      .selectAll('g')
      .data(this.formattedData)
      .join('g')
      .attr('class', (d: any) => d.key)
      .selectAll('rect')
      .data((d: any) => d)
      .join('rect')
      .attr('x', (d: any) => this.xBandStartNormalised(this.x(d.data.id)))
      .attr('y', (d: any) => this.y(d[1]))
      .attr('height', (d: any) => this.getBarHeight(d))
      .attr('width', this.normalisedBandWidth)
      .on('click', (e: MouseEvent, d: any) => {
        this.clicked.emit(d.data);
        this.barLabels.classed('bar-selected', (bar: any, i: number, n: any[]) => {
          const alreadySelected = d3.select(n[i]).attr('class').includes('bar-selected');
          return bar === d.data.label && !alreadySelected;
        });
      });
  }

  protected buildBarLabels(yAxisWidth: number) {
    this.barLabels = this.svg
      .append('g')
      .attr('transform', `translate(${this.showYAxis ? yAxisWidth + this.MARGIN.LEFT : 0}, 0)`)
      .selectAll('g')
      .data(this.columns)
      .join('text')
      .text((d: { label: string; id: string }) => d.label)
      .attr('x', (d: { label: string; id: string }) => this.xBandStartNormalised(this.x(d.id)) + (this.normalisedBandWidth / 2))
      .attr('y', this.svgHeight - 5)
      .attr('text-anchor', 'middle')
      .attr('class', `${this.uppercaseText ? 'uppercase' : 'lowercase'} bar-label`);
  }

  protected buildBarValues(yAxisWidth: number) {
    this.barValues = this.svg
      .append('g')
      .attr('transform', `translate(${this.showYAxis ? yAxisWidth + this.MARGIN.LEFT : 0}, 0)`)
      .data(this.formattedData)
      .selectAll('text')
      .data((d: any) => d)
      .join('text')
      .attr('x', (d: any) => this.xBandStartNormalised(this.x(d.data.id)) + (this.normalisedBandWidth / 2))
      .attr('y', (d: any) => {
        const barValue = this.subgroups.reduce((acc, subgroup) => acc += d.data[subgroup], 0);
        return this.y(barValue) + this.MARGIN.TOP - 10;
      })
      .attr('text-anchor', 'middle')
      .text((d: any) => VizUtilsService.formatChartNumbers(
        this.subgroups.reduce((acc, subgroup) => acc += d.data[subgroup], 0)
      ))
      .attr('class', 'bar-value');
  }

  protected getBarHeight(d: any): number {
    return this.subgroups.reduce((acc, subgroup) => acc += d.data[subgroup], 0) === 0
      ? this.MIN_BAR_HEIGHT
      : this.y(d[0]) - this.y(d[1]);
  }

  protected xBandStartNormalised = xBandStartOriginal => {
    const xBandCenterOriginal = xBandStartOriginal + this.x.bandwidth() / 2;
    return xBandCenterOriginal - this.normalisedBandWidth / 2;
  }

  protected buildLimitLine() {
    if (!this.limit) return;

    const lineX = this.showYAxis ? (this.MARGIN.LEFT + 18) : 0;
    const lineY = this.y(this.limit) + this.MARGIN.TOP - 1;

    const lineWidth = this.x.range()[1];
    const lineHeight = 2;

    const gElem = this.svg
      .append('g')
      .attr('transform', `translate(${lineX}, ${lineY})`)
      .attr('class', 'limit-line');

    gElem
      .append('rect')
      .attr('width', lineWidth)
      .attr('height', lineHeight);

    if (this.limitPlaceholder) {
      const rectElm = gElem.append('rect');
      const textElm = gElem.append('text');

      textElm
        .attr('x', lineWidth / 2)
        .attr('y', -5)
        .attr('class', 'limit-tooltip-text')
        .style('text-anchor', 'middle')
        .text(this.limitPlaceholder);

      const textSizes = textElm.node().getBoundingClientRect();
      const textWidthWithPaddings = textSizes.width + 20;
      const startTooltipBgPosition = lineWidth / 2 - textWidthWithPaddings / 2;

      rectElm
        .attr('class', 'limit-tooltip-background')
        .attr('width', textWidthWithPaddings)
        .attr('x', startTooltipBgPosition);
    }
  }

  protected createTooltip(): void {
    d3
      .select('body')
      .append('div')
      .attr('class', this.TOOLTIP_CLASS);
  }

  protected onBarsMouseOver(e: MouseEvent, d: any): void {
    this.tooltip
      .text(this.tooltipText)
      .style('display', 'flex')
      .transition()
      .duration(150)
      .style('opacity', 1);
  }

  protected handleTooltipPosition(e: MouseEvent): void {
    const horizPos = e.clientX;
    const vertPos = e.clientY;

    this.tooltip
      .style('left', `${horizPos}px`)
      .style('top', `${vertPos - 40}px`);
  }

  protected onBarsMouseOut(): void {
    this.tooltip
      .style('opacity', 0)
      .style('display', 'none');
  }

  protected formatChartData(data): D[] {
    return data.map((bar, index) => ({
      ...bar,
      id: bar.id ?? `${bar.label}-${index}`
    }));
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
  }
}
