export enum ERuleSetupMode {
  create = 'create',
  edit = 'edit',
  copy = 'copy'
}

export enum ERuleSetupStep {
  General,
  Conditions
}

export enum ERuleConditionClause {
  If = 'If',
  Then = 'Then'
}