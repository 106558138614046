import { EAuditReportFilterTypes } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
  CommonReportsPagesFiltersConfig,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export enum EVariableInventoryTrendNames {
  UNIQUE_TAGS = 'uniqueTags',
  TAG_INSTANCE = 'tagInstance',
  UNIQUE_VARIABLE_NAME = 'uniqueVariableName',
  UNIQUE_VARIABLE_VALUE = 'uniqueVariableValue',
}

export const UNIQUE_TAGS_CHART_CONFIG = {
  title: 'Unique Tags',
  data: [],
  sideLabel: 'tags',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => `${value} unique tag${value == 1 ? '' : 's'}`,
};

export const TAG_INSTANCE_CHART_CONFIG = {
  title: 'Tag Requests Evaluated',
  data: [],
  sideLabel: 'Tags',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => `${value} tag instance${value == 1 ? '' : 's'}`,
};

export const UNIQUE_VARIABLE_NAME_CHART_CONFIG = {
  title: 'Unique Variable Names',
  data: [],
  sideLabel: 'Unique names',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => `${value} unique variable name${value == 1 ? '' : 's'}`,
};

export const UNIQUE_VARIABLE_VALUE_CHART_CONFIG = {
  title: 'Unique Variable Values',
  data: [],
  sideLabel: 'Unique values',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => `${value} unique variable value${value == 1 ? '' : 's'}`,
};

export const VariableInventoryRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,
  EAuditReportFilterTypes.TagVendorId,
  EAuditReportFilterTypes.TagPrimaryTagsOnly,
  EAuditReportFilterTypes.TagId,
  EAuditReportFilterTypes.TagCategory,
  EAuditReportFilterTypes.TagAccount,
];

export const PageSetPagesTableColumns: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.VariableCount]: {
    title: '# Variables Set',
    checked: true,
  },
};
export const UniqueValuesPagesTableColumns: ISwitchableMenuItems = {
  expand: {
    title: 'expand',
    checked: true,
    hiddenInMenu: true,
  },
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.RequestCount]: {
    title: '# of Requests',
    checked: true,
  },
  [CommonReportsPagesTableColumns.VariableValue]: {
    title: 'Variable Value',
    checked: true,
  },
};

export const PageNotSetPagesTableColumns: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
};
