import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { EAlertBellStatus, EAlertBellType } from './alert-bell.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-bell',
  templateUrl: './alert-bell.component.html',
  styleUrls: ['./alert-bell.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertBellComponent {
  EAlertBellType = EAlertBellType;

  /**
   * if the component is in the hovered state - defaultStatus is used for determining which icon should be displayed
   * otherwise, hoverStatus is used (if any)
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('status') defaultStatus: EAlertBellStatus;
  @Input() hoverStatus?: EAlertBellStatus;

  @Input() showCount?: boolean = false;
  @Input() count?: number;
  @Input() tooltipPlaceholder?: string;
  @Input() showBellAlways?: boolean = false;
  @Input() colorRedOnTriggered: boolean = true;
  @Input() menuName: string;
  @Input() type: EAlertBellType = EAlertBellType.Icon;

  // is used for setting the hovered state from outside
  @HostBinding('class.active') @Input() active = false;
  // if the icon is hovered
  @HostBinding('class.hovered') hovered = false;

  @Output() onClick = new EventEmitter<void>();

  readonly EAlertBellStatus = EAlertBellStatus;

  get status(): EAlertBellStatus {
    const isActive = this.hovered || this.active;
    return isActive ? (this.hoverStatus ?? this.defaultStatus) : this.defaultStatus;
  }
}
