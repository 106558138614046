import { IUsageTrendsDTO } from '@app/components/usage-v2/usage-v2.models';
import {
  ChartClassName,
  ChartType,
  EChartType,
  IChartData,
  IChartsConfig
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import {
  createCircle,
  createTooltip,
  drawTooltip,
  getChartData
} from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';
import {
  attachBarChartToSvg,
  attachLineChartToSvg,
  hide,
  nearestDataPointCoordinates
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import * as d3 from 'd3';
import { stepAfter } from '@app/components/shared/components/viz/utils/stepAfterCurve';
import { EDateFormats, formatDate, toUTC } from '@app/components/date/date.service';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import {
  getActualUsageToggleBtn
} from '@app/components/usage-v2/components/usage-chart/datasets/past-periods/utils/toggle-buttons';

export function addPastPeriodsCumulativeTotalChartToConfig(trends: IUsageTrendsDTO, config: IChartsConfig, chartType: EChartType): void {
  if (!trends.pastPeriods) {
    return;
  }
  const currentPeriod = trends.currentPeriod ? [trends.currentPeriod] : [];
  config.charts[ChartType.pastPeriodsCumulativeTotal] = {
    data: getChartData([...trends.pastPeriods, ...currentPeriod], 'cumulativeTotal.total', true),
    chartClassName: ChartClassName.pastPeriods,
    toggleBtn: getActualUsageToggleBtn(trends, chartType),
  };
}

const actionTextVariants = {
  [EChartType.AUDIT]: 'PAGES SCANNED',
  [EChartType.WEB_JOURNEY]: 'JOURNEY RUNS',
};

const generateTooltipText = (date: string, value: number, actionText: string): string => {
  return '<div class="usage-chart-tooltip-body">' +
    `<h3 class="title">${actionText} AS OF ` + '<br>' +
    formatDate(toUTC(new Date(date)), EDateFormats.dateEight) +
    '</h3>' +
    '<div class="value">' +
    '<span class="value-data">' + VizUtilsService.formatChartNumbers(value) + '</span>' +
    '</div>' +
    '</div>';
};

export function drawPastPeriodsCumulativeTotalChart(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
  barChartPadding: number,
  overlayLineStroke: number,
  windowWidth: number,
  circleClass: string,
  circleRadius: number,
  tooltipClass: string,
  endInCenter = false,
  animationEnabled: boolean = true,
  chartType: EChartType,
  highlight: (shouldHighlight: boolean) => void
): void {
  const cumulativeTotal = config.charts[ChartType.pastPeriodsCumulativeTotal];

  if (!cumulativeTotal) {
    return;
  }

  attachBarChartToSvg(
    {
      // We don't need the last item in the array for the bar charts
      data: (cumulativeTotal.data as IChartData[]).slice(0, -1),
      x: xAxis,
      y: yAxis,
      height: height - margin.top - margin.bottom,
      gap: 0,
      containerClassName: `cumulative-total ${cumulativeTotal.chartClassName}`,
      barClassName: 'blue-area',
      barLabelClassName: `bar-label bar-label-hidden`,
      leftPadding: barChartPadding,
      rightPadding: endInCenter ? xAxis.bandwidth() / 2 : 0,
      animationDuration: animationEnabled ? 750 : 0,
    },
    svg,
  );

  attachLineChartToSvg(
    {
      data: endInCenter ? cumulativeTotal.data : (cumulativeTotal.data as IChartData[]).slice(0, -1),
      className: 'blue-line',
      strokeWidth: 2,
      x: xAxis,
      y: yAxis,
      leftPadding: barChartPadding,
      // To make the last item end in center (for currentPeriod)
      rightPadding: endInCenter ? -xAxis.bandwidth() / 2 : 0,
      animationType: 'run',
      animationDuration: animationEnabled ? 1500 : 0,
      curve: endInCenter ? d3.curveStepAfter : stepAfter,
    },
    svg.select(`.${cumulativeTotal.chartClassName}`)
  );

  attachLineChartToSvg(
    {
      data: endInCenter ? cumulativeTotal.data : (cumulativeTotal.data as IChartData[]).slice(0, -1),
      className: 'transparent-line',
      strokeWidth: overlayLineStroke,
      x: xAxis,
      y: yAxis,
      leftPadding: barChartPadding,
      // To make the last item end in center (for currentPeriod)
      rightPadding: endInCenter ? -xAxis.bandwidth() / 2 : 0,
      curve: endInCenter ? d3.curveStepAfter : stepAfter,
      animationDuration: animationEnabled ? 2000 : 0,
    },
    svg.select(`.${cumulativeTotal.chartClassName}`)
  )
    .on('mousemove', (e) => {
      const {
        x,
        y,
        dataPoint
      } = nearestDataPointCoordinates(e, svg.node(), cumulativeTotal.data, xAxis, yAxis);

      const circle = createCircle(svg, circleClass, circleRadius);
      const tooltip = createTooltip(tooltipClass);
      circle.attr('class', circleClass);

      drawTooltip(
        svg,
        generateTooltipText(dataPoint.date, dataPoint.value, actionTextVariants[chartType]),
        x,
        y,
        circle,
        tooltip,
        windowWidth,
      );

      highlight(true);
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
      highlight(false);
    });
}
