import { IManualJourneyExportModalData } from '@app/components/modals/modalData';
import { ButtonSet } from '@app/models/commons';
import { AngularNames, Names } from '@app/moonbeamConstants';
import { ExportToEmailModalService } from '@app/components/domains/discoveryAudits/reporting/selectionSummary/selectionPagesSummaryMenu/exportToEmailModalService';
import { IExportData, IUtilitiesService } from '@app/components/utilities/utilitiesService';
import {
  name as MJS_NAME,
  ManualJourneyService, IManualJourneyExportParams, EManualJourneyIdentifiers
} from '@app/components/live-connect/manual-journey/manualJourneyService';

export interface IManualJourneyExportScope extends mgcrea.ngStrap.modal.IModalScope, IManualJourneyExportModalData {}

export interface IMJExportConfigItem {
  enabled: boolean;
  title: string;
  identifier: string;
}

export const name = 'ManualJourneyExportModalController';

export class ManualJourneyExportModalController {

  dpId: number;
  mjId: number;

  configItems: Array<IMJExportConfigItem> = [
    {enabled: true, title: 'Actions', identifier: EManualJourneyIdentifiers.IncludeActions},
    {enabled: true, title: 'Request Log', identifier: EManualJourneyIdentifiers.IncludeRequestLog},
    {enabled: true, title: 'Rules Pass/Fail', identifier: EManualJourneyIdentifiers.IncludeRuleResults},
    {enabled: true, title: 'Tag Summary', identifier: EManualJourneyIdentifiers.IncludeTagSummary},
    {enabled: true, title: 'Tag Details', identifier: EManualJourneyIdentifiers.IncludeTagDetails}
  ];

  modalButtons: Array<ButtonSet> = [[{
    label: 'Export',
    action: 'export',
    primary: true
  }]];

  static $inject = [
    AngularNames.scope,
    Names.Services.exportToEmailModal,
    MJS_NAME,
    Names.Services.utilities
  ];
  constructor(
    private $scope: IManualJourneyExportScope,
    private exportToEmailModalService: ExportToEmailModalService,
    private manualJourneyService: ManualJourneyService,
    private utilitiesService: IUtilitiesService,
  ){
    this.dpId = this.$scope.deviceProfileId;
    this.mjId = this.$scope.manualJourneyId;
  }

  export(): void {
    this.disableSaveButtons();
    const contentIdentifiers = this.configItems.reduce((object, i) => {
      object[i.identifier] = i.enabled;
      return object;
    }, {}) as IManualJourneyExportParams;

    this.manualJourneyService.exportManualJourneyExcelV3(this.dpId, this.mjId, contentIdentifiers)
      .then(
        (exportData: IExportData) => {
          this.$scope.$hide();
          this.exportToEmailModalService.showExportToEmailAlertModal();
        }
      );
  }

  private disableSaveButtons(): void {
    this.modalButtons[0].forEach(b => b.disabled = true);
  }

}
