import { Injectable, Inject } from '@angular/core';
import { AngularNames } from '@app/moonbeamConstants';
import { IFolderActionsService } from '@app/store/actions/folderActions';
import { IUser } from '@app/moonbeamModels';
import { IFolder } from './foldersApiService';
import { Observable, of, from } from 'rxjs';

@Injectable()
export class FolderService {
    
  constructor(@Inject(AngularNames.ngRedux) private ngRedux: ngRedux.INgRedux,
              private folderActionsService: IFolderActionsService) {}

  handleFolder(user: IUser, folder: IFolder | string): Observable<IFolder> {
    return this.isFolderCreateMode(folder) ? 
        from(this.createFolder(folder, user)) :
        of(folder);
  }

  isFolderCreateMode(folder: IFolder | string): folder is string {
    return typeof folder === 'string';
  }

  private createFolder(folderName: string, user: IUser): angular.IPromise<IFolder> {
    const newFolder = {
      accountId: user.accountId,
      name: folderName
    };
    return this.ngRedux.dispatch(this.folderActionsService.createFolder(newFolder));
  }

}
