import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { EUserStep, userRoles } from '@app/components/create-edit-user-modal/create-edit-user-modal.constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IUserProfileForm } from '@app/components/create-edit-user-modal/create-edit-user-modal.models';
import { UtilitiesService } from '@app/components/utilities/utilitiesService';
import { AccountsService } from '@app/components/account/account.service';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { IUser } from '@app/moonbeamModels';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/components/core/services/authentication.service';

@Component({
  selector: 'op-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {
  timezones: string[];
  filteredOptions: Observable<string[]>;
  editingPassword: boolean = false;
  editingSelf: boolean = false;
  readOnly: boolean = false;
  userRoles = userRoles;
  isReadOnlyUser: boolean = false;
  isStandardUser: boolean = false;
  isAdminUser: boolean = false;
  isOpAdminUser: boolean = false;
  isOpSysAdminUser: boolean = false;
  canChangePassword: boolean = false;
  strongPasswordPolicy: boolean = false;

  @Input() currentStep: EUserStep;
  @Input() currentUser: IUser;
  @Input() userBeingEdited: IUser;
  @Input() editMode: boolean = false;
  @Input() formGroup: FormGroup<IUserProfileForm>;
  @Output() profileUpdated: EventEmitter<void> = new EventEmitter;

  constructor(
    private utilitiesService: UtilitiesService,
    private accountService: AccountsService,
    private authenticationService: AuthenticationService,
  ) {
    this.fetchAccountPasswordPolicy();
    this.readOnly = this.accountService.userIsReadOnly();
    this.timezones = this.utilitiesService.getTimezones();
  }

  ngOnInit(): void {
    this.timeZone.setValidators([Validators.required, OPValidators.timeZoneValidator(this.timezones)]);
    this.filteredOptions = this.timeZone.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = value;
        return name ? this._filter(name as string) : this.timezones.slice();
      }),
    );
  }

  private _filter(name: string): string[] {
    const filterValue = name.toLowerCase();

    return this.timezones.filter(option => option.toLowerCase().includes(filterValue));
  }

  // The order and timing of Input's being changed isn't consistent, so had to handle
  // the three possible orders separately to make sure we update the form properly.
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser?.currentValue && changes.userBeingEdited?.currentValue) {
      this.initializeFormFieldsBasedOnUser();
    } else if (changes.currentUser?.currentValue) {
      if (this.userBeingEdited) {
        this.initializeFormFieldsBasedOnUser();
      }
    } else if (changes.userBeingEdited?.currentValue) {
      if (this.currentUser) {
        this.initializeFormFieldsBasedOnUser();
      }
    }
  }

  private fetchAccountPasswordPolicy(): void {
    this.authenticationService.getAccount().subscribe((account) => {
      this.strongPasswordPolicy = account.strongPasswordPolicy;
    });
  }

  initializeFormFieldsBasedOnUser(): void {
    this.editingSelf = this.currentUser.id === this.userBeingEdited.id;
    this.updateCurrentUserRole();
    this.updateUserCanChangePassword();
    this.handleFormFieldsStatus();
  }

  updateUserCanChangePassword() {
    this.canChangePassword = this.editingSelf || this.isAdminUser || this.isOpAdminUser || this.isOpSysAdminUser;
  }

  updateCurrentUserRole(): void {
    this.isReadOnlyUser = this.currentUser.permissions === this.userRoles.guest;
    this.isStandardUser = this.currentUser.permissions === this.userRoles.standardUser;
    this.isAdminUser = this.currentUser.permissions === this.userRoles.admin;
    this.isOpAdminUser = this.currentUser.permissions === this.userRoles.opAdmin;
    this.isOpSysAdminUser = this.currentUser.permissions === this.userRoles.opSysAdmin;
  }

  handleFormFieldsStatus(): void {
    if (this.isReadOnlyUser || this.isStandardUser) {
      this.firstName.disable();
      this.lastName.disable();
      this.email.disable();
      this.username.disable();
      this.timeZone.disable();
    } else {
      this.firstName.enable();
      this.lastName.enable();
      this.email.enable();
      this.username.enable();
      this.timeZone.enable();
    }
  }

  changePassword() {
    this.editingPassword = true;
    this.enablePasswordFields();
  }

  cancelChangePassword(): void {
    // Turn validation off for password fields
    this.newPassword.setValue('');
    this.newPasswordConfirm.setValue('');
    this.currentPassword.setValue('');
    this.editingPassword = false;
    this.disablePasswordFields();
  }

  enablePasswordFields() {
    if (this.editingSelf || this.isAdminUser || this.isOpAdminUser || this.isOpSysAdminUser) {
      this.newPassword.enable();
      this.newPasswordConfirm.enable();
      if (this.strongPasswordPolicy) {
        this.newPassword.setValidators([Validators.required, OPValidators.strongPassword()]);
        this.newPasswordConfirm.setValidators([Validators.required, OPValidators.strongPassword()]);
      } else {
        this.newPassword.setValidators([Validators.required, Validators.minLength(8)]);
        this.newPasswordConfirm.setValidators([Validators.required, Validators.minLength(8)]);
      }
      this.formGroup.setValidators(OPValidators.passwordsMatch);
    } else {
      this.newPassword.disable();
      this.newPasswordConfirm.disable();
      this.newPassword.clearValidators();
      this.newPasswordConfirm.clearValidators();
    }

    this.formGroup.updateValueAndValidity();
  }

  disablePasswordFields() {
    this.newPassword.clearValidators();
    this.newPassword.disable();
    this.newPasswordConfirm.clearValidators();
    this.newPasswordConfirm.disable();
    this.formGroup.updateValueAndValidity();
  }

  get firstName(): FormControl<string | null> {
    return this.formGroup.get('firstName') as FormControl<string | null>;
  }

  get lastName(): FormControl<string | null> {
    return this.formGroup.get('lastName') as FormControl<string | null>;
  }

  get email(): FormControl<string | null> {
    return this.formGroup.get('email') as FormControl<string | null>;
  }

  get username(): FormControl<string | null> {
    return this.formGroup.get('username') as FormControl<string | null>;
  }

  get password(): FormControl<string | null> {
    return this.formGroup.get('password') as FormControl<string | null>;
  }

  get currentPassword(): FormControl<string | null> {
    return this.formGroup.get('currentPassword') as FormControl<string | null>;
  }

  get newPassword(): FormControl<string | null> {
    return this.formGroup.get('newPassword') as FormControl<string | null>;
  }

  get newPasswordConfirm(): FormControl<string | null> {
    return this.formGroup.get('newPasswordConfirm') as FormControl<string | null>;
  }

  get timeZone(): FormControl<string | null> {
    return this.formGroup.get('timeZone') as FormControl<string | null>;
  }
}
