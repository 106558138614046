import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IUseCaseTabs } from '../use-cases.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nav-arrow-buttons',
  templateUrl: './nav-arrow-buttons.component.html',
  styleUrls: ['./nav-arrow-buttons.component.scss']
})
export class NavArrowButtonsComponent implements OnInit {

  @Input() tabs: IUseCaseTabs[] = [];
  @Input() activeTab: string;
  @Output() onActiveTabChange: EventEmitter<string> = new EventEmitter<string>();

  leftBtn: { deskTopViewLabel: string, mobileViewLabel: string, path: string } = { deskTopViewLabel: '', mobileViewLabel: '', path: '' };
  rightBtn: { deskTopViewLabel: string, mobileViewLabel: string, path: string } = { deskTopViewLabel: '', mobileViewLabel: '', path: '' };

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.handleRouteChanges();
    this.getPreviousButtonData();
    this.getNextButtonData();
  }

  getActiveRoute(): string {
    const paths = this.router.url.split('/');
    return paths[paths.length - 1];
  }

  handleRouteChanges(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveTab(this.getActiveRoute());
        this.getPreviousButtonData();
        this.getNextButtonData();
      }
    });
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
    this.onActiveTabChange.emit(tab);
  }

  getPreviousButtonData(): void {
    const index = this.tabs.findIndex((tab) => tab.path === this.activeTab);

    if (index > -1) {
      const prevTab = (index === 0) ? this.tabs[this.tabs.length - 1] : this.tabs[index - 1];
      this.leftBtn = {
        deskTopViewLabel: `View ${prevTab.label} Use-Case`,
        mobileViewLabel: prevTab.label,
        path: prevTab.path
      }
    }
  }

  getNextButtonData(): void {
    const index = this.tabs.findIndex((tab) => tab.path === this.activeTab);

    if (index > -1) {
      const nextTab = (index === (this.tabs.length - 1)) ? this.tabs[0] : this.tabs[index + 1];
      this.rightBtn = {
        deskTopViewLabel: `View ${nextTab.label} Use-Case`,
        mobileViewLabel: nextTab.label,
        path: nextTab.path
      }
    }
  }
}
