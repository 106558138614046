import {
  AfterViewInit,
  Component,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  EReportType,
  IAuditRunRequestDomain,
  IConsentCategoryGeos,
  IConsentCategoryRequestDomain,
  IPaginationMetaData, IRequestDomain,
  IRequestDomainsHash,
  IRunInfo,
} from '@app/components/consent-categories/consent-categories.models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { SelectionModel } from '@angular/cdk/collections';
import {
  EConsentCategoryCreateStep,
  EConsentCategorySourceSortBy
} from '@app/components/consent-categories/cc-create/cc-create.enums';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ConsentCategoriesUIConstants } from '@app/components/consent-categories/consent-categories.constants';
import { IDataSource } from '@app/components/shared/services/data-sources/data-sources.models';
import { MatPaginator } from '@angular/material/paginator';

const REPORT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ConsentCategoryCreateRequestDomainsComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-create-request-domains',
  templateUrl: './cc-create-request-domains.component.html',
  styleUrls: ['./cc-create-request-domains.component.scss'],
  providers: [
    REPORT_CONTROL_VALUE_ACCESSOR
  ],
})

export class ConsentCategoryCreateRequestDomainsComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  CONSTANTS: any = { ...ConsentCategoriesUIConstants };
  destroy$: Subject<void> = new Subject();
  onChange: (value) => void;
  selection = new SelectionModel(true, []);
  searchText: string = '';
  selectAll: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource;
  selectedDataSource: MatTableDataSource<any> = new MatTableDataSource;
  displayedColumns: string[] = ['select', 'requestDomains', 'geoLocations'];
  pagination: IPaginationMetaData = {
    totalCount: 0,
    totalPageCount: 0,
    pageSize: 1500,
    currentPageSize: 0,
    currentPageNumber: -1
  };
  requestDomainsHash: IRequestDomainsHash = {};
  requestDomains: IAuditRunRequestDomain[] = [];
  loading: boolean = true;
  queryParams = {
    page: 0,
    size: 1500,
    sortBy: EConsentCategorySourceSortBy.RequestDomain,
    sortDesc: false,
    search: '',
  };
  requestDomainsFilter: string = '';
  geos: IConsentCategoryGeos;
  selectedLimit: number = 1500;
  displaySelection: boolean = false;
  selectedRequestDomains: string[] = [];
  allRequestDomainsSelected: boolean;
  editableRequestDomains: IRequestDomain[] = [];
  filterChanged$ = new Subject<string>();

  unselectedRequestDomainsInResponse: number = 0;

  shiftKeyPressed: boolean = false;
  lastSelectedIndex: number = null;

  @Input() formControl: UntypedFormControl;
  @Input() noSelectionDisplay: boolean = false;
  @Input() currentStep: EConsentCategoryCreateStep;
  @Input() reports: IDataSource[]; // Selected audits to populate tabs data
  @Input() continueToSource$: Observable<boolean>;
  @Input() existingRequestDomains: IConsentCategoryRequestDomain[];
  @ViewChild('resultsTableSort', { static: true }) resultsTableSort: MatSort;
  @ViewChild('selectedTableSort', { static: true }) selectedTableSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('window:keydown.shift')
  onKeyDown() {
    this.shiftKeyPressed = true;
  }

  @HostListener('window:keyup.shift')
  onKeyUp() {
    this.shiftKeyPressed = false;
  }

  constructor(private ccService: ConsentCategoriesService) {}

  ngOnInit() {
    this.resultsTableSort.sortChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe((sort: Sort) => {
      this.queryParams.sortBy = EConsentCategorySourceSortBy.RequestDomain;
      this.queryParams.sortDesc = sort.direction === 'asc' ? false : true;
      this.resetPagination();
      this.loadRequestDomains();
    });
    this.dataSource.sort = this.resultsTableSort;

    this.selectedDataSource.sort = this.selectedTableSort;
    this.selectedDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'requestDomains':
          return item['domain'].toLowerCase();
      }
    };

    this.selectedDataSource.filterPredicate = (requestDomain: any, filter: string) => {
      const filterLower = filter.toLowerCase();
      const domain = requestDomain['domain'].toLowerCase();
      const geos = this.getGeoString(requestDomain['locationIds']).toLowerCase();
      const matchString = `${domain}~~${geos}`; // Filter on domain name and geo names

      return matchString.includes(filterLower);
    };

    this.addExistingRequestDomainsToHashAndSelectedRequestDomains();

    this.filterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.queryParams.search = encodeURI(filter);
      this.resetPagination();
      this.loadRequestDomains();
    });
  }

  getGeoString(locationIds: number[]): string {
    return locationIds.reduce((acc, id) => {
      return `${acc}~~${this.geos.countriesById[id].countryName}`;
    }, '');
  }

  ngAfterViewInit() {
    // Initial request domains load is triggered from the base component after selecting
    // 1+ existing audit run
    this.continueToSource$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((selectedRunsChanged) => {
      if (selectedRunsChanged) {
        this.selectedRequestDomains = [];
        this.addExistingRequestDomainsToHashAndSelectedRequestDomains();
        this.resetPagination();

        if (this.reports.length === 0) {
          this.dataSource.data = [];
          this.loading = false;
        } else {
          this.loadRequestDomains();
        }
      }
    });

    this.ccService.geo$.pipe(
      take(1)
    ).subscribe(geos => {
      this.geos = geos;
    });

    this.paginator.page.subscribe(({ pageIndex }) => {
      this.pagination.currentPageNumber = pageIndex;
      this.loadRequestDomains();
    });
  }

  resetPagination(): void {
    this.pagination = {
      totalCount: 0,
      totalPageCount: 0,
      pageSize: 1500,
      currentPageSize: 0,
      currentPageNumber: 0
    };
  }

  /**
   * Component will receive an array of existing values on init if there are any. These
   * array items are the initial values in requestDomainsHash and selectedItems.
   */
  addExistingRequestDomainsToHashAndSelectedRequestDomains(): void {
    if (!this.existingRequestDomains?.length) return;
    this.editableRequestDomains = [...this.existingRequestDomains];
    this.addToRequestDomainsHash(this.existingRequestDomains);
    this.editableRequestDomains.forEach(requestDomain => this.addToSelectedItems(requestDomain.domain));

    this.updateSelectedRequestDomainsTable();

    // Update parent formControl with new selections
    this.setSelectedRequestDomainsInForm(this.selectedRequestDomains);
  }

  setSelectedRequestDomainsInForm(selectedDomains: string[]): void {
    let selectedRequestDomains = selectedDomains.map(domain => this.requestDomainsHash[domain]);
    this.updateSelectedRequestDomainsTable();

    this.onChange && this.onChange(selectedRequestDomains);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  loadRequestDomains(): void {
    this.loading = true;

    // Format list of runs to be included when fetching existing run data
    const runInfo: IRunInfo[] = this.reports.map(report => ({
      itemType: EReportType.AUDIT,
      itemId: report.itemId,
      runId: report.lastCompletedRun.runId
    }));

    // Fetch request domains for selected reports
    this.ccService.getRunRequestDomains(runInfo, this.pagination, this.queryParams).pipe(
      take(1)
    ).subscribe(requestDomainsDTO => {
      const formattedRequestDomains = this.ccService.formatRunRequestDomainsResponseForUI(requestDomainsDTO.requestDomains);
      this.addToRequestDomainsHash(formattedRequestDomains);

      this.dataSource.data = formattedRequestDomains;
      this.pagination = requestDomainsDTO.metadata.pagination;
      this.paginator.pageIndex = this.pagination.currentPageNumber;

      this.unselectedRequestDomainsInResponse = 0;
      this.allRequestDomainsSelected = this.areAllSelected();
      this.filterSelectedRequestDomains(this.requestDomainsFilter);

      this.loading = false;
    }, (err) => {
      this.loading = false;
      console.log('Error retrieving requested request domain data');
    });
  }

  areAllSelected(): boolean {
    if (this.dataSource.data.length === 0) {
      return false;
    }

    let allSelected = true;

    this.dataSource.data.forEach(requestDomain => {
      const requestDomainId = requestDomain.domain;
      if (!this.requestDomainsHash[requestDomainId] || (!this.requestDomainsHash[requestDomainId]?.existing && !this.requestDomainsHash[requestDomainId]?.selected)) {
        allSelected = false;
        this.unselectedRequestDomainsInResponse++;
      }
    });

    return allSelected;
  }

  filterSelectedRequestDomains(filterValue: string): void {
    this.selectedDataSource.filter = filterValue;
  }

  openedSelection() {
    this.displaySelection = true;
  }

  closedSelection() {
    this.displaySelection = false;
  }

  toggleSelectAll(checked) {
    this.allRequestDomainsSelected = checked;
    // add all request domains in the list that aren't currently selected
    this.dataSource.data.forEach((requestDomain) => {
      // if not already selected, toggle select property and push to selected array
      const existing = this.requestDomainsHash[requestDomain.domain].existing;
      const selected = this.requestDomainsHash[requestDomain.domain].selected;

      if (!existing) {
        // Select all checked
        if (this.allRequestDomainsSelected) {
          // Select any current values that are deselected
          if (!selected) {
            this.requestDomainsHash[requestDomain.domain].selected = true;
            this.addToSelectedItems(requestDomain.domain);
          }
          // Deselect all current values
        } else {
          if (selected) {
            this.requestDomainsHash[requestDomain.domain].selected = false;
            this.removeFromSelectedItems(requestDomain.domain);
          }
        }
      }
    });

    this.updateSelectedRequestDomainsTable();

    // Update parent formControl with new selections
    this.setSelectedRequestDomainsInForm(this.selectedRequestDomains);
  }

  /**
   * Manually toggles the selected property of a request domain row in requestDomainsHash
   */
  toggleSelectOne(checked, row, updateExisting = false) {
    if (this.requestDomainsHash[row.domain]?.existing) return;

    if (checked) {
      this.requestDomainsHash[row.domain].selected = true;
      this.addToSelectedItems(row.domain);
    } else {
      this.requestDomainsHash[row.domain].selected = false;

      if (updateExisting && this.requestDomainsHash[row.domain].existing) {
        this.requestDomainsHash[row.domain].existing = false;
      }

      this.removeFromSelectedItems(row.domain);
      this.allRequestDomainsSelected = false;
    }

    this.updateSelectedRequestDomainsTable();

    // Update parent formControl with new selections
    this.setSelectedRequestDomainsInForm(this.selectedRequestDomains);
  }

  debounceFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.filterChanged$.next(filter);
  }

  removeFromSelectedItems(domain: string): void {
    const index = this.selectedRequestDomains.findIndex(selectedDomain => domain === selectedDomain);
    this.selectedRequestDomains.splice(index, 1);
  }

  addToSelectedItems(domain: string): void {
    this.selectedRequestDomains.push(domain);
  }

  updateSelectedRequestDomainsTable(): void {
    this.selectedDataSource.data = this.selectedRequestDomains.map(requestDomain => this.requestDomainsHash[requestDomain]);
  }

  /**
   * Remove a country from the list of locationIds assigned to a request domain
   * @param requestDomain
   * @param idToRemove
   */
  removeCountry(requestDomain, idToRemove) {
    let assignedCountryIds = this.requestDomainsHash[requestDomain.domain].locationIds;
    let indexToRemove = assignedCountryIds.findIndex(id => id === idToRemove);

    this.requestDomainsHash[requestDomain.domain].locationIds.splice(indexToRemove, 1);
  }

  /**
   * Remove all locationIds for the provided request domain
   */
  removeAllRequestDomainCountries(requestDomain) {
    this.requestDomainsHash[requestDomain.domain].locationIds = [];
  }

  openRequestDomainsCountriesDropdown(requestDomain){
    console.log('open other request domains locationIds dropdown');
  }

  page(event) {
    this.pagination.currentPageNumber = event.pageIndex;
    this.loadRequestDomains();
  }

  /**
   * A request domains hash map is created to manage and display state
   * (i.e. existing, selected) for the overall list of request domains and geos. Any time
   * new data is retrieved we add values to this hash if they weren't already loaded. Once
   * added they maintain their state for subsequent loads and are not added again.
   */
  addToRequestDomainsHash(requestDomains) {
    requestDomains.forEach(requestDomain => {
      if (!this.requestDomainsHash[requestDomain.domain]) {
        this.requestDomainsHash[requestDomain.domain] = requestDomain;
      }
    });
  }

  /**
   * If given an array of request domains, create the initial requestDomainHash
   */
  writeValue(value: IRequestDomain[]) {
    const hashFromValue = {};
    value.forEach(requestDomain => {
      hashFromValue[requestDomain.domain] = requestDomain;
    });

    this.requestDomainsHash = hashFromValue;
  }

  registerOnChange(fn: (value: IRequestDomain[]) => void) { this.onChange = fn; }

  registerOnTouched(fn) {}

  handleCheckboxClick(checked: boolean, index: number): void {
    if (this.shiftKeyPressed) {
      if (this.lastSelectedIndex < index) {
        for (let i = this.lastSelectedIndex + 1; i <= index; i++) {
          this.toggleSelectOne(checked, this.dataSource.data[i]);
        }
      } else {
        for (let i = this.lastSelectedIndex - 1; i >= index; i--) {
          this.toggleSelectOne(checked, this.dataSource.data[i]);
        }
      }
    } else {
      this.toggleSelectOne(checked, this.dataSource.data[index]);
    }

    this.lastSelectedIndex = index;
  }
}
