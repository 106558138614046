<split-card [topLabel]="widgetWithUnapprovedCookiesPages.topLabel"
            [topChangeMeaning]="widgetWithUnapprovedCookiesPages.topChangeMeaning"
            [topHandler]="widgetWithUnapprovedCookiesPages.topHandler"
            [state]="loadingState"
            [topTooltip]="'Pages with a cookie marked as unapproved in one or more consent categories.'">
  <div class="widget-pages-details">
    <div class="page-counts">
              <span class="pages-filtered-count"
                    [class.color-graph-red]="cookieSummary?.filteredPageWithUnapprovedCookieCount">
                {{ cookieSummary?.filteredPageWithUnapprovedCookieCount | number }}
              </span>
    </div>
    <filter-spinner [state]="loadingState"></filter-spinner>
  </div>
</split-card>
<split-card [topLabel]="widgetUnapprovedCookies.topLabel"
            [topChangeContent]="widgetUnapprovedCookies.topChangeContent"
            [topChangeMeaning]="widgetUnapprovedCookies.topChangeMeaning"
            [topHandler]="widgetUnapprovedCookies.topHandler"
            [bottomHandler]="widgetUnapprovedCookies.bottomHandler"
            [state]="loadingState"
            [haveEnoughData]="widgetUnapprovedCookiesSparklineData.length > 1"
            [topTooltip]="'Cookies discovered during this audit/run and unapproved in one or more consent categories. ' + (loadingState === 'filtered' ? ' (Filtered)' : '')">
  <sparkline-chart-insight [meaningOfUp]="'negative'"
                           [meaningOfNoChange]="'none'"
                           [label]="'since last run'"
                           [dataLoaded]="sparklineDataLoaded"
                           [dataPoints]="widgetUnapprovedCookiesSparklineData">
  </sparkline-chart-insight>
</split-card>
