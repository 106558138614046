<div class="cc-source-selected-table-wrapper">
  <div class="selection-count-wrapper"
       [class.count-only]="countOnly"
       [ngClass]="{'selection-warning': selectedItems.length > selectedLimit}">
    <div class="selection-count" [ngClass]="{'selection-open': displaySelection}">
      <span class="selected-count">{{selectedItems.length | number}}</span>  &nbsp;{{selectedItemText | titlecase}}&nbsp;Selected&nbsp;
      <span [class.selected-unemphasized-text]="selectedItems.length > 0">(Max of 1,500)</span>
      <mat-icon *ngIf="!countOnly">expand_more</mat-icon>
    </div>
    <div class="selection-count-warning" *ngIf="selectedItems.length > selectedLimit">
      <mat-icon>warning_amber</mat-icon>
      Each consent category is limited to {{selectedLimit | number}} {{selectedItemText | lowercase}}
    </div>
  </div>
</div>
