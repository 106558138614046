import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConfirmModalPayload } from '@app/components/shared/components/op-modal';
import { IButton } from 'models/commons';
import { modifyButtonsActions } from '../shared/op-modal.utils';

@Component({
  selector: 'op-confirm-modal',
  templateUrl: './op-confirm-modal.component.html',
  styleUrls: ['./op-confirm-modal.component.scss']
})
export class OpConfirmModalComponent {
  title: string;
  messages: string[];
  rightFooterButtons: IButton[];
  leftFooterButtons: IButton[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) private data: IConfirmModalPayload,
              private dialogRef: MatDialogRef<OpConfirmModalComponent>) {

    this.title = data.title;
    this.messages = data.messages;
    this.leftFooterButtons = data.leftFooterButtons
      ? modifyButtonsActions(data.leftFooterButtons, () => this.close(false))
      : [];
    this.rightFooterButtons = modifyButtonsActions(data.rightFooterButtons, () => this.close(true));
  }

  close(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }
}
