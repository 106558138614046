import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { EComparisonType } from '../comparisons.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'comparison-type-picker',
  templateUrl: './comparison-type-picker.component.html',
  styleUrls: ['./comparison-type-picker.component.scss']
})
export class ComparisonTypePickerComponent {

  readonly comparisonType = EComparisonType;
  selectedOption: string = this.comparisonType.baseline;

  constructor(public dialogRef: MatDialogRef<ComparisonTypePickerComponent>) {}

  rightFooterButtons: IButton[] = [
    {
      label: 'next',
      action: this.nextHandler.bind(this),
      primary: true,
    }
  ];

  nextHandler() {
    this.dialogRef.close(this.selectedOption);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
