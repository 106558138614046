export const modalInfo = {
  modalTitle: 'Create New',
  data: 'Data Source',
  standards: 'Standards',
  configurations: 'Configurations and Settings',
  websiteAuditsAndJourneys: 'Website Audit or Journey',
  externalDataSources: 'External Data Source',
  addStructure: null,
  organizeInformation: null,
};

export const modalOptionTypes = {
  webAudit: 'Web Audit',
  webJourney: 'Web Journey',
  webHARUpload: 'Web HAR Upload',
  comparisons: 'Comparisons',
  appHARUpload: 'App HAR Upload',
  liveConnectJourney: 'LiveConnect Journey',
  rules: 'Rules',
  consentCategories: 'Consent Categories',
  actionSets: 'Action Sets',
  remoteFileMapping: 'File Substitutions',
  emailInboxes: 'Email Inboxes',
  folders: 'Folders',
  subfolders: 'Sub-Folders - Data Layer(s)',
  labels: 'Labels',
  users: 'Users',
  alerts: 'Alerts'
};
