import {
  WIDGET_CUMULATIVE_LAYOUT_SHIFT_BOUNDARIES,
  WIDGET_FIRST_CONTENTFUL_PAINT_BOUNDARIES,
  WIDGET_LARGEST_CONTENTFUL_PAINT_BOUNDARIES, WIDGET_TIME_TO_FIRST_BYTE_BOUNDARIES
} from '@app/components/audit-reports/reports/page-summary/page-summary.constants';

export interface IPageInformation {
  pageStatusCode: number;
  pageSize: string;
  pageLoadTime: number;
  consoleLogErrors: any[];
  rfm: IRFMInfo[];
  unapprovedOrMissing: number;
  ruleFailures: number;
  tagCount: number;
  cookieCount: number;
  imgURL: string;
  startingURL: string;
}

export interface IRFMInfo {
  matchFound: boolean;
  matchType: string;
  matchValue: string;
  replacementFileName: string;
  matchedRequest: string;
}

export enum EChartChangeTypes {
  positive = 'positive',
  neutral = 'neutral',
  warning = 'warning',
  negative = 'negative',
  none = 'none',
}

export enum EChartChangeDirection {
  up = 'up',
  down = 'down',
  unchanged = 'unchanged',
}

export enum EPageInfoTrendNames {
  PAGE_LOAD_TIME = 'pageLoadTime',
  PAGE_SIZE = 'pageSize',
  LARGEST_CONTENTFUL_PAINT = 'largest_contentful_paint',
  FIRST_CONTENTFUL_PAINT = 'first_contentful_paint',
  TIME_TO_FIRST_BYTE = 'time_to_first_byte',
  CUMULATIVE_LAYOUT_SHIFT = 'cumulative_layout_shift'
}

export const EPageInfoTrendNamesToDigitsInfo = {
  [EPageInfoTrendNames.LARGEST_CONTENTFUL_PAINT]: '1.3',
  [EPageInfoTrendNames.FIRST_CONTENTFUL_PAINT]: '1.3',
  [EPageInfoTrendNames.TIME_TO_FIRST_BYTE]: '1.0',
  [EPageInfoTrendNames.CUMULATIVE_LAYOUT_SHIFT]: '1.3',
}

export const PAGE_LOAD_TIME_CHART_CONFIG = {
  title: 'Page Load Time',
  data: [],
  sideLabel: 'seconds',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => { return `${value} second load time`; },
};

export const PAGE_SIZE_CHART_CONFIG = {
  title: 'Page Size',
  data: [],
  sideLabel: 'MB',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => { return `${value} MB`; },
};

export const LARGEST_CONTENTFUL_PAINT_CHART_CONFIG = {
  title: 'Largest Contentful Paint',
  data: [],
  sideLabel: 'seconds',
  shortLabel: 'sec',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  boundaries: WIDGET_LARGEST_CONTENTFUL_PAINT_BOUNDARIES,
  tooltip: (value: number) => { return `${value} second med.`; },
};

export const FIRST_CONTENTFUL_PAINT_CHART_CONFIG = {
  title: 'First Contentful Paint',
  data: [],
  sideLabel: 'seconds',
  shortLabel: 'sec',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  boundaries: WIDGET_FIRST_CONTENTFUL_PAINT_BOUNDARIES,
  tooltip: (value: number) => { return `${value} second med.`; },
};

export const TIME_TO_FIRST_BYTE_CHART_CONFIG = {
  title: 'Time To First Byte',
  data: [],
  sideLabel: 'ms',
  shortLabel: 'ms',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  boundaries: WIDGET_TIME_TO_FIRST_BYTE_BOUNDARIES,
  tooltip: (value: number) => { return `${value} second med.`; },
};

export const CUMULATIVE_LAYOUT_SHIFT_CHART_CONFIG = {
  title: 'Cumulative Layout Shift',
  data: [],
  sideLabel: '',
  shortLabel: '',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  boundaries: WIDGET_CUMULATIVE_LAYOUT_SHIFT_BOUNDARIES,
  tooltip: (value: number) => `${value}`,
};
