import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'op-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss']
})
export class CopyButtonComponent {
  private TOOLTIP_DEFAULT_TRANSITION_TIME: number = 200;
  @ViewChild('tooltip', { read: MatTooltip }) private tooltip: MatTooltip;

  @Input() copyBtnDisabled: boolean = false;
  @Input() copyBtnIcon: string = 'content_copy';
  @Input() copyValue: string = '';
  @Input() copyTooltipText: string = 'Copy to clipboard';
  @Input() copiedTooltipText: string = 'Copied to clipboard';
  @Input() @HostBinding('class') bgColor: 'yellow' | '' = '';

  copyBtnClick(): void {
    setTimeout(() => {
      this.tooltip.message = this.copiedTooltipText;
      this.tooltip.show();
    });
  }

  resetTooltip(): void {
    setTimeout(() => { this.tooltip.message = this.copyTooltipText }, this.TOOLTIP_DEFAULT_TRANSITION_TIME);
  }
}
