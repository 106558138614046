import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Features } from '@app/moonbeamConstants';
import { EventEmitter } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IAdminAccountFeature } from '@app/components/admin-portal/admin-accounts.service';

export interface IFeaturesTableRow {
  name: string;
  displayName: string;
  enabled: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'admin-features',
  templateUrl: './admin-features.component.html',
  styleUrls: ['./admin-features.component.scss']
})
export class AdminFeaturesComponent implements OnInit {

  displayedColumns: string[] = ['select', 'feature'];
  dataSource = new MatTableDataSource<IFeaturesTableRow>();
  selection = new SelectionModel(true, []);

  @Input() features: IAdminAccountFeature[];
  @Input() extraFeatures: IFeaturesTableRow[];

  @Output() featuresUpdated: EventEmitter<IFeaturesTableRow[]> = new EventEmitter();

  ngOnInit(): void {
    this.initTable();
    this.setSelected();
  }

  private initTable(): void {
    this.dataSource.data = this.features
      .map((feature: { name: string, enabled: boolean }) => ({
        name: feature.name,
        displayName: this.getFeatureDisplayName(feature.name),
        enabled: feature.enabled
      }))
      .concat(this.extraFeatures)
      .sort((a: any, b: any) => (a.displayName.toLowerCase() > b.displayName.toLowerCase()) ? 1 : -1);
  }

  private setSelected(): void {
    this.dataSource.data.forEach((row: IFeaturesTableRow) => {
      if (row.enabled) {
        this.selection.select(row);
      }
    });
  }

  private getFeatureDisplayName(name: string): string {
    return Features.values.find(feature => feature.name === name).displayName;
  }

  emitSelection(): void {
    this.featuresUpdated.emit(this.dataSource.data);
  }

  checkboxToggled(event: MatCheckboxChange, row: IFeaturesTableRow): void {
    if (event) this.selection.toggle(row);
    row.enabled = !row.enabled;
    this.emitSelection();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dataSource.data.forEach((row: IFeaturesTableRow) => row.enabled = false);
      this.emitSelection();
      return;
    }

    this.selection.select(...this.dataSource.data);
    this.dataSource.data.forEach((row: IFeaturesTableRow) => row.enabled = true);
    this.emitSelection();
  }
}
