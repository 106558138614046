<div class="audit-report-info-container">
  <div class="report-info-section" *ngIf="!auditHasNotRunYet">
    <div class="report-info-section-columns">
      <div class="report-info-section-header">AUDIT RUN INFORMATION</div>
    </div>


    <div *ngIf="(!!runSnapshot && enabledInfoItems?.length > 0) || auditRunStatus?.status !== EAuditRunStatus.Basic"
         class="report-info-section-columns report-info-warning-banner gpc-banner"
         [ngClass]="{
          'status-stopped': auditRunStatus?.status === EAuditRunStatus.Stopped,
          'status-issues': auditRunStatus?.status === EAuditRunStatus.Issues,
          'status-advanced': auditRunStatus?.status === EAuditRunStatus.Advanced
         }"
    >
      <div class="description-section">
        <div class="icon"><mat-icon>{{ auditRunStatus?.icon }}</mat-icon></div>
        <div class="description">
          <p>
            {{ auditRunStatus?.issueDescription }}
          </p>
        </div>
      </div>
      <!-- advanced audit template -->
      <div *ngIf="auditRunStatus?.status === EAuditRunStatus.Advanced" class="enabled-feature-list">
        <ul>
          <li *ngFor="let infoItemProperty of enabledInfoItems">{{infoItemProperty}}</li>
        </ul>
      </div>
      <!-- generic template for most statuses -->
      <div *ngIf="auditRunStatus?.status !== EAuditRunStatus.Advanced && !auditRunStatus?.pillText.includes(EAuditStatusPillText.OnPageActionFailures)" class="status-body generic-status-template">
        <p class="status-text status-reason"><strong>REASON:</strong> {{ auditRunStatus?.reason }}</p>
        <button mat-flat-button (click)="openInfoModal()">{{ auditRunStatus?.btnText }}</button>
        <p class="status-text status-solution"><strong>SOLUTION:</strong> {{ auditRunStatus?.solution }}</p>
      </div>

      <!-- one-off template for on-page action failures -->
      <div *ngIf="auditRunStatus?.status !== EAuditRunStatus.Advanced && auditRunStatus?.pillText.includes(EAuditStatusPillText.OnPageActionFailures)" class="status-body on-page-actions-failed">
        <p class="status-text status-reason">
          <strong>REASON:</strong> {{ auditRunStatus?.reason }}
          <strong> Sample of failure messages:</strong>
        </p>
        <ul class="failure-messages">
          <li *ngFor="let error of sampleOfFailures" class="message">
            <div class="url"><strong>URL:</strong> <div class="truncate-url">{{ error.url }}</div></div>
            On <strong>action #{{ error.actionName }} with error:</strong> {{ error.message }}
          </li>
        </ul>
        <button mat-flat-button (click)="viewPagesWithFailedActions.emit()">
          <div class="btn-wrapper">
            {{ auditRunStatus?.btnText }}
            <mat-icon>arrow_forward</mat-icon>
          </div>
        </button>
        <p class="status-text status-solution"><strong>SOLUTION:</strong> {{ auditRunStatus?.solution }}</p>
      </div>
    </div>

    <!-- don't show if it's an advanced audit or else it will be duplicated because of the above -->
    <div *ngIf="!!runSnapshot && enabledInfoItems?.length > 0 && auditRunStatus?.status !== EAuditRunStatus.Advanced"
         class="report-info-section-columns report-info-warning-banner gpc-banner status-advanced"
    >
      <div class="description-section">
        <div class="icon"><mat-icon>shuffle</mat-icon></div>
        <div class="description">
          <p>
            All pages scanned in this audit run used settings that may affect collected data in tags, cookies, and other technologies. Report data reflects these changes.
          </p>
        </div>
      </div>
      <div class="enabled-feature-list">
        <ul>
          <li *ngFor="let infoItemProperty of enabledInfoItems">{{infoItemProperty}}</li>
        </ul>
      </div>
    </div>

    <div class="report-info-section-columns" *ngIf="!!runSnapshot">
      <div class="report-info-section-left">
        <div class="info-item">
          <div class="info-item-label">URLs Scanned</div>
          <div class="info-item-text">{{ runSnapshot?.urlsScanned || 'Unknown' }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">Run Duration</div>
          <div class="info-item-text">{{ runDuration }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">Browser Version</div>
          <div class="info-item-text">{{ fullBrowserVersion }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">User Agent Used</div>
          <div class="info-item-text">{{ runSnapshot?.userAgent || 'Unknown' }}</div>
        </div>
      </div>
      <div class="report-info-section-right">
        <div class="info-item" *ngIf="runSnapshot?.customProxy">
          <div class="info-item-label">Custom Proxy</div>
          <div class="info-item-text">{{ runSnapshot?.customProxy }}</div>
        </div>
        <div class="info-item" *ngIf="!runSnapshot?.customProxy">
          <div class="info-item-label">Location</div>
          <div class="info-item-text">
            <ng-container *ngIf="locationCountryCode">
              <span class="flag-icon flag-icon-{{ locationCountryCode | lowercase }}"></span>&nbsp;
            </ng-container>
            {{ location }}
          </div>
        </div>
        <div class="info-item">
          <div class="info-item-label">Scan Speed</div>
          <div class="info-item-text">{{ scanSpeed }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">Browser Viewport</div>
          <div class="info-item-text">{{ browserViewport }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">Session Info</div>
          <div class="info-item-text">{{ loginActionsFound
            ? 'Pre-audit actions detected: session maintained for 100 pages, then reset'
            : 'No Pre-audit actions detected: New session for each scanned page'
          }}</div>
        </div>
      </div>
    </div>
    <div class="snapshot-zero-state" *ngIf="!runSnapshot">
      Some run info is only available on newer runs. Please re-run now or schedule this Audit to take advantage of new information.
    </div>
  </div>
  <hr class="info-divider" *ngIf="!auditHasNotRunYet">
  <div class="report-info-section" [class.no-top-margin]="auditHasNotRunYet">
    <div class="report-info-section-columns">
      <div class="report-info-section-header">AUDIT SETTINGS</div>
    </div>
    <div class="report-info-section-columns">
      <div class="report-info-section-left">
        <div class="info-item">
          <div class="info-item-label">Folder/Sub-Folder</div>
          <div class="info-item-text">{{ folderName }} / {{ domainName }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">Run Frequency</div>
          <div class="info-item-text">{{ audit?.frequency | titlecase }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">Scan Limit</div>
          <div class="info-item-text">{{ audit?.limit }} pages</div>
        </div>
      </div>
      <div class="report-info-section-right">
        <div class="info-item">
          <div class="info-item-label">Creator</div>
          <div class="info-item-text">{{ ownerName }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">Settings Last Changed</div>
          <div class="info-item-text">{{ audit?.lastUpdated | date }}</div>
        </div>
        <div class="info-item">
          <div class="info-item-label">Labels</div>
          <div class="info-item-text">{{ labelNames || 'No labels' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
