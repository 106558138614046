<h2 class="chart-title">
  <mat-icon>explore</mat-icon>
  {{ title }}
  <mat-icon *ngIf="isFiltered" class="filter-icon">filter_alt</mat-icon>
  <p class="date-range">{{ dateRange }}</p>
</h2>

<div class="dataset-toggle-btns">
  <ng-container *ngFor="let btn of toggleButtons">
    <button *ngIf="btn.showAlways || (isFiltered && btn.showWithFiltered) || (!isFiltered && !btn.showWithFiltered)"
            [className]="btn.className"
            (mouseenter)="toggleChart(btn.chartClassName); drawButtonTooltip($event, btn)"
            (mouseleave)="toggleChart(btn.chartClassName); hideButtonTooltip()">
      {{ btn.text }}
    </button>
  </ng-container>
</div>

<div [class]="svgBoxSelectorName" class="usage-trends-multi-chart"></div>

<div *ngIf="!charts" class="spinner-overlay">
  <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
</div>
