<div class="user-permissions" [formGroup]="formGroup">
  <section class="access">
    <div class="section-heading" [class.disabled]="editingSelf">{{ accessSectionHeading }} <a *ngIf="!this.editMode" class="learn-more-link" target="_blank" href="https://help.observepoint.com/en/articles/9095523-user-permissions">Learn more</a></div>
    <div class="access-levels">
      <div *ngFor="let level of accessLevels"
           class="level"
           [class.selected]="selectedAccessLevel === level.value"
           [class.error]="permissionLevel.errors?.required && permissionLevel.touched"
           [class.disabled]="editingSelf"
           (click)="setUserAccessLevel(level.value)"
      >
        <div class="circle">
          <div class="circle-inner"></div>
        </div>

        <div class="level-name">{{ level.label }}</div>
        <div class="level-description">{{ level.description }}</div>
      </div>
    </div>
    <mat-error *ngIf="permissionLevel.errors?.required && permissionLevel.touched" class="access-level-error">
      Please select a user access level
    </mat-error>
    <div *ngIf="editingSelf" class="note">
      <mat-icon class="warn-icon">warning</mat-icon>
      You cannot edit your own permissions
    </div>
  </section>

  <section class="access-controls">
    <div class="section-heading">
      Specify this user's feature access controls.
    </div>
    <div class="access-controls-wrap">
      <div class="page-limit">
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>AUDIT PAGE LIMIT (0 = Unlimited)</mat-label>
          <input formControlName="auditPageLimit" type="number" placeholder="Enter Number" matInput>
          <mat-hint>
            Limit for how many pages a user can configure in an audit.
          </mat-hint>
        </mat-form-field>
        <mat-error *ngIf="auditPageLimit.errors?.required && auditPageLimit.touched">
          This field is required
        </mat-error>
        <mat-error *ngIf="auditPageLimit?.errors?.min">Minimum value is 0</mat-error>
        <mat-error *ngIf="auditPageLimit?.errors?.max">Maximum value is 250,000</mat-error>
      </div>
      <div class="usage-dashboard-access">
        <mat-slide-toggle
          color="primary"
          [disabled]="selectedAccessLevel !== StandardPermissions"
          [checked]="usageDashboardAccess.value"
          (change)="toggleDashboardAccess($event.checked)"
        >
          <div class="dashboard-access-messaging">
            <div><strong>Account Usage Dashboard Access</strong></div>
            <div class="deemphasize">(Only applicable to standard users)</div>
          </div>
        </mat-slide-toggle>
      </div>
    </div>
  </section>

  <div class="bottom-section" [class.column]="!!this.apiKey && !!editMode && hasApiKeyFeature">
    <mat-spinner *ngIf="!apiKeyInfoLoaded" [diameter]="20" [strokeWidth]="3"></mat-spinner>
    <ng-container *ngIf="apiKeyInfoLoaded">
      <section class="api-key" [class.half-width]="inAdminPortal && !this.apiKey">
        <div class="section-sub-heading"><strong>API Key -</strong>
          <span *ngIf="!editMode" class="not-yet-available">
            This API key will be available upon request for this user.
          </span>
          <span *ngIf="editMode" class="api-docs-link">
            <a href="https://help.observepoint.com/en/articles/9106323-getting-started-with-the-observepoint-api" target="_blank">View API Documentation</a>
          </span>
        </div>
        <ng-container *ngIf="editMode">
          <div *ngIf="apiKey" class="api-key-display">
            <div class="api-key-value">{{ apiKey.accessToken }}</div>
            <button mat-flat-button
                    class="copy-api-key-btn"
                    color="primary"
                    ngxClipboard
                    [cbContent]="apiKey.accessToken"
            >
              <mat-icon>content_copy</mat-icon>
              Copy
            </button>
          </div>
          <button (click)="generateApiKey()" class="generate-api-key-btn" mat-stroked-button color="primary">
            {{ !!this.apiKey ? 'Revoke & Generate New API Key' : 'Generate API Key' }}
          </button>
          <span *ngIf="!this.apiKey" class="not-generated-stmnt">API Key hasn't been generated.</span>
        </ng-container>
      </section>

      <section *ngIf="inAdminPortal" class="account-status">
        <div class="section-sub-heading">
          <strong>Account Status</strong>
        </div>
        <mat-radio-group class="radio-btns" aria-label="Lock or unlock account" formControlName="accountStatus">
          <mat-radio-button color="primary" [value]="false" class="unlocked-btn">Unlocked</mat-radio-button>
          <mat-radio-button color="primary" [value]="true">Locked</mat-radio-button>
        </mat-radio-group>
      </section>
    </ng-container>
  </div>
</div>
