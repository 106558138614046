<op-modal (onClose)="close()" [rightFooterButtons]="rightFooterButtons">
  <ng-template #headerTemplate>
    <op-modal-header-base title="Alert" (onClose)="close()">
      <alert-header  [formGroup]="alertForm"
                     [labels]="labels"
                     (onCreateLabel)="onCreateLabel($event)"
                     [readOnly]="isReadOnlyMode(payload)"
                     [modalType]="modalType">
      </alert-header>
    </op-modal-header-base>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="form-container" [formGroup]="alertForm">
      <alert-steps [formGroup]="alertForm"
                   [currentStep]="currentStep"
                   [noDataSourcesSupport]="noDataSourcesSupport"
                   [noHistorySupport]="noHistorySupport"
                   [filterType]="filterType"
                   (stepSelected)="goToStep($event)">
      </alert-steps>

      <div class="content">
        <alert-logic *ngIf="currentStep === EAlertStep.Logic"
                     [modalType]="modalType"
                     [readOnlyLabel]="readOnlyLabel"
                     [filterType]="filterType"
                     [formGroup]="logicFormGroup"
                     [menuContext]="menuContext">
        </alert-logic>
        <alert-notification *ngIf="currentStep === EAlertStep.Notification"
                            [modalType]="modalType"
                            [readOnlyLabel]="readOnlyLabel"
                            [formGroup]="notificationFormGroup">
        </alert-notification>
        <alert-data-sources *ngIf="currentStep === EAlertStep.DataSources"
                            [modalType]="modalType"
                            [readOnlyLabel]="readOnlyLabel"
                            [formGroup]="dataSourcesFormGroup"
                            [labelsMap]="labelsMap">
        </alert-data-sources>
        <alert-preview *ngIf="currentStep === EAlertStep.Preview"
                       [payload]="payload"
                       [formGroup]="alertForm">
        </alert-preview>
      </div>
    </div>
  </ng-template>
</op-modal>
