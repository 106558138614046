<button mat-icon-button
        [mdePopoverTriggerFor]="appPopover"
        mdePopoverTriggerOn="click"
        mdePopoverPositionX="before"
        mdePopoverPositionY="below">
  <div class="button-container">
    <mat-icon
      class="broken-icon"
      [style.left.px]="getBrokenIconOffset()"
      *ngIf="brokenPresent">circle</mat-icon>
    <mat-icon
      class="redirect-icon"
      [style.left.px]="getRedirectIconOffset()"
      *ngIf="redirectPresent">circle</mat-icon>
    <mat-icon
      class="good-icon"
      [style.left.px]="getGoodIconOffset()"
      *ngIf="goodPresent">circle</mat-icon>
    <mat-icon
      class="arrow-icon"
      [style.left.px]="getArrowIconOffset()">keyboard_arrow_down</mat-icon>
  </div>
</button>
<mde-popover #appPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverCloseOnClick]="false">
  <mat-card>
    <mat-card-content
      class="status-code-indicators-popover"
      [matTooltip]="tooltip ? 'Filter by these status codes' : ''"
      matTooltipPosition="above"
    >
      <div class="outer-flex-container">

        <div class="inner-flex-container" (click)="filterBy('broken')">
          <div class="broken-icon" *ngIf="brokenPresent">
            <mat-icon class="broken-icon flex-item">circle</mat-icon>
          </div>
          <div class="flex-item" *ngIf="brokenPresent">
            {{ payload.broken | number }} tag{{ payload.broken > 1 ? 's' : '' }} w/ series 0, 400 or 500 - Broken
          </div>
        </div>

        <div class="inner-flex-container" (click)="filterBy('redirects')">
          <div class="redirect-icon flex-item" *ngIf="redirectPresent">
            <mat-icon class="redirect-icon">circle</mat-icon>
          </div>
          <div class="flex-item" *ngIf="redirectPresent">
            {{ payload.redirect | number }} tag{{ payload.redirect > 1 ? 's' : '' }} w/ series 300 - Redirect
          </div>
        </div>

        <div class="inner-flex-container" (click)="filterBy('good')">
          <div class="good-icon flex-item" *ngIf="goodPresent">
            <mat-icon class="good-icon">circle</mat-icon>
          </div>
          <div class="flex-item" *ngIf="goodPresent">
            {{ payload.good | number }} tag{{ payload.good > 1 ? 's' : ''}} w/ series 200 - Successes
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</mde-popover>
