import { Component, Inject, OnInit } from '@angular/core';
import {
  IChangedStandardsData
} from '@app/components/audit/standards-changed-snackbar/standards-changed-snackbar.constants';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { RulesService } from '@app/components/rules/rules.service';
import {
  IReprocessService,
  ReprocessBannerStatus
} from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import {
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import {
  IAuditRunSummary
} from '@app/components/domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'standards-changed-snackbar',
  templateUrl: './standards-changed-snackbar.component.html',
  styleUrls: ['./standards-changed-snackbar.component.scss']
})
export class StandardsChangedSnackbarComponent implements OnInit {
  message: string;
  standardsCountText: string;
  totalStandardsChanged: number;
  runId: number;
  loading: boolean = true;
  disableReprocess: boolean = false;
  disableRunNow: boolean = false;

  constructor(
    private consentCategoriesService: ConsentCategoriesService,
    private rulesService: RulesService,
    private snackBarRef: MatSnackBarRef<StandardsChangedSnackbarComponent>,
    private auditDataService: IAuditDataService,
    private reprocessService: IReprocessService,

    @Inject(MAT_SNACK_BAR_DATA) public standardsData: IChangedStandardsData,
  ) {}

  ngOnInit(): void {
    this.standardsCountText = this.updateSnackbarBodyText();

    // If a run id is not provided, get the latest run if it exists
    if (!this.standardsData.runId) {
      this.auditDataService.getAuditRuns(this.standardsData?.audit?.id).then((runs: IAuditRunSummary[]) => {
        this.runId = runs.length > 0 ? runs[0].id : null;
        this.loading = false;
      });
      // Otherwise, use the run id that was provided to reprocess standards
    } else {
      this.runId = this.standardsData.runId;
      this.loading = false;
    }
  }

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }

  runAudit(): void {
    this.disableRunNow = true;
    this.auditDataService.runNow(this.standardsData.audit).then(() => {
      this.closeSnackbar();
    });
  }

  updateSnackbarBodyText(): string {
    let {rules, alerts, consentCategories} = this.standardsData.changedStandards;
    this.totalStandardsChanged = rules + alerts + consentCategories;
    let rulesText = `${rules === 0 ? '' : rules + ' Rule' + (rules > 1 ? 's' : '')}`;
    let alertsText = `${alerts === 0 ? '' : alerts + ' Alert' + (alerts > 1 ? 's' : '')}`;
    let ccText = `${consentCategories === 0 ? '' : consentCategories + ' Consent Categor' + (consentCategories === 1 ? 'y' : 'ies')}`;

    let changeCountTextParts = [rulesText, alertsText, ccText].filter((value) => value?.length > 0);
    let changeCountText;

    if (changeCountTextParts?.length === 3) {
      changeCountText = `${changeCountTextParts[0]}, ${changeCountTextParts[1]} and ${changeCountTextParts[2]} have`;
    } else if (changeCountTextParts?.length === 2) {
      changeCountText = `${changeCountTextParts[0]} and ${changeCountTextParts[1]} have`;
    } else if (changeCountTextParts?.length === 1){
      const changeCountParts = changeCountTextParts[0].split(' ');
      changeCountText = `${+changeCountParts[0]} ${consentCategories > 0 ? changeCountParts[1] + ' ' + changeCountParts[2] : changeCountParts[1]} ${+changeCountParts[0] === 1 ? 'has' : 'have'}`;
    }

    return `${changeCountText} been updated.`;
  }

  reprocessStandards(): void {
    this.disableReprocess = true;

    if (this.standardsData.changedStandards.rules > 0) {
      this.reprocessRules();
    }

    if (this.standardsData.changedStandards.consentCategories > 0) {
      this.reprocessConsentCategories();
    }

    // Only needs to fire when alerts were the only standard to change -- Otherwise, alerts will reprocess automatically whenever
    // cc's or rules are reprocessed. Disable the rule reprocessing banner when they didn't actually change and it is only
    // to trigger the alerts reprocessing.
    if (this.standardsData.changedStandards.alerts > 0 && !(this.standardsData.changedStandards.rules > 0 || this.standardsData.changedStandards.consentCategories > 0)) {
      this.reprocessRules(true);
    }

    this.closeSnackbar();
  }

  reprocessRules(skipMonitoring?: boolean): void {
    const auditId = this.standardsData.audit.id;
    const auditName = this.standardsData.audit.name;

    this.auditDataService
      .reprocessRules(auditId, this.runId)
      .then(() => {
        if (!skipMonitoring) {
          this.reprocessService.reprocessComplete();
          this.reprocessService.subscribeOnAuditReprocessingRulesUpdates(auditId, this.runId, auditName);
          this.reprocessService.updateAuditReprocessRulesBannerStatus(auditId, this.runId, ReprocessBannerStatus.inProgress);
        }
      });
  }

  reprocessConsentCategories(): void {
    const auditId = this.standardsData.audit.id;

    this.consentCategoriesService
      .reprocessConsentCategories(auditId, this.runId, this.standardsData.changedStandards.ccIds).subscribe(() => {
      this.reprocessService.reprocessComplete();
      this.reprocessService.subscribeOnAuditReprocessingConsentCategoriesUpdates(auditId, this.runId, this.standardsData.audit.name);
      this.reprocessService.updateAuditReprocessConsentCategoriesBannerStatus(auditId, this.runId, ReprocessBannerStatus.inProgress);
    });
  }
}
