import { Route } from '@angular/router';
import { TriggeredAlertsComponent } from './triggered-alerts.component';
import { TriggeredAlertsNames, TriggeredAlertsPaths } from './triggered-alerts.constants';

export const TriggeredAlertsState: Route = {
  path: TriggeredAlertsPaths.base,
  loadChildren: () => import('./triggered-alerts.module').then(mod => mod.TriggeredAlertsModule)
}

export const TriggeredAlertsRoutes: Route = {
  path: '',
  component: TriggeredAlertsComponent,
  data: {
    stateName: TriggeredAlertsNames.base,
    title: 'Triggered Alerts'
  }
}