export const ShortcutsConfig = [
  {
    sectionName: 'Global',
    rows: [
      {
        description: 'toggle light/dark mode',
        shortcut: 't'
      }
    ]
  },
  {
    sectionName: 'Global (Except edit screens)',
    rows: [
      {
        description: 'global search',
        shortcut: 'f'
      },
      {
        description: 'navigate to home (sources) screen',
        shortcut: 'h'
      },
      {
        description: 'view keyboard shortcuts',
        shortcut: 'shift + ?'
      }
    ]
  },
  {
    sectionName: 'Home (Cards) Screen',
    rows: [
      {
        description: 'create new',
        shortcut: 'c'
      }
    ]
  },
  {
    sectionName: 'Audit Report',
    rows: [
      {
        description: 'open audit edit screen',
        shortcut: 'e'
      },
      {
        description: 'focus on notes box',
        shortcut: 'n'
      },
      {
        description: 'view next audit report in left nav',
        shortcut: 'shift + right arrow / shift + down arrow'
      },
      {
        description: 'view previous audit report in left nav',
        shortcut: 'shift + left arrow / shift + up arrow'
      },
      {
        description: 'view audit info',
        shortcut: 'i'
      }
    ]
  },
  {
    sectionName: 'Web Journey Report',
    rows: [
      {
        description: 'open web journey edit screen',
        shortcut: 'e'
      },
      {
        description: 'focus on notes box',
        shortcut: 'n'
      }
    ]
  },
  {
    sectionName: 'Audit / Web Journey / Consent Categories Edit Screens',
    rows: [
      {
        description: 'save current setup screen',
        shortcut: 'ctrl / cmd + s'
      }
    ]
  },
  {
    sectionName: 'Anywhere global sidebar is visible',
    rows: [
      {
        description: 'toggle open/close the global side navigation',
        shortcut: '['
      }
    ]
  }
];

export const KeyboardShortcutsModalId: string = 'keyboard-shortcuts-modal';
