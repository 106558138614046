<div class="form-container" [formGroup]="formGroup">
  <div *ngIf="!editMode" class="header-text">This new user will be sent a welcome email with a way for them to create a password.</div>
  <div class="double-input">
    <mat-form-field appearance="outline"
                    floatLabel="always"
    >
      <mat-label>First Name</mat-label>
      <input matInput
             formControlName="firstName" placeholder="Enter first name"
             (blur)="profileUpdated.emit()"
      >
      <mat-error *ngIf="firstName.invalid">This field is required</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline"
                    floatLabel="always"
    >
      <mat-label>Last Name</mat-label>
      <input matInput
             formControlName="lastName"
             placeholder="Enter last name"
             (blur)="profileUpdated.emit()"
      >
      <mat-error *ngIf="lastName.invalid">This field is required</mat-error>
    </mat-form-field>
  </div>
  <div class="single-input">
    <mat-form-field appearance="outline"
                    floatLabel="always"
    >
      <mat-label>Work Email</mat-label>
      <input matInput
             formControlName="email"
             placeholder="Enter work email address"
             (blur)="profileUpdated.emit()"
      >
      <mat-error *ngIf="email?.errors?.emailsListValidator">Please enter a valid email address</mat-error>
      <mat-error *ngIf="email?.errors?.required">This field is required</mat-error>
    </mat-form-field>
  </div>
  <div class="single-input">
    <mat-form-field appearance="outline"
                    floatLabel="always"
    >
      <mat-label>Username</mat-label>
      <input matInput
             formControlName="username"
             placeholder="Enter username"
             (blur)="profileUpdated.emit()"
      >
      <mat-error *ngIf="username?.errors?.required">This field is required</mat-error>
      <mat-error *ngIf="username?.errors?.duplicate">Username is already taken</mat-error>
    </mat-form-field>
  </div>
  <div class="single-input">
    <ng-container>
      <div class="password-placeholder"
           [class.hidden]="editingPassword"
      >
        <mat-form-field appearance="outline"
                        floatLabel="always"
        >
          <mat-label>Password</mat-label>
          <input matInput
                 [readonly]="true"
                 formControlName="password"
                 placeholder="{{editMode ? ' (Password not shown for privacy) ' : 'Password will be created and emailed to this user'}}"
          >
        </mat-form-field>
        <div class="change-password"
             *ngIf="editMode && canChangePassword"
             (click)="changePassword()"
        >Change Password</div>
      </div>
      <div class="update-password"
           [class.hidden]="!editingPassword"
           [class.slide-in]="editingPassword"
           [class.slide-out]="!editingPassword"
      >
        <mat-form-field appearance="outline"
                        floatLabel="always"
                        *ngIf="editingSelf"
        >
          <mat-label>Current Password</mat-label>
          <input matInput
                 formControlName="currentPassword"
                 type="password"
                 autocomplete="current-password"
                 placeholder="Enter current password"
          >
          <mat-error *ngIf="currentPassword?.errors?.strongPassword">Password must be at least 8 characters long, contain at least one symbol, and at least one number. Unable to reuse a password from the last 12 months.</mat-error>
          <mat-error *ngIf="currentPassword?.errors?.minlength">Password length needs to be 8 or more characters</mat-error>
          <mat-error *ngIf="currentPassword?.errors?.required && !currentPassword?.errors?.minlength">This field is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        floatLabel="always"
                        [class.strong-password]="strongPasswordPolicy"
        >
          <mat-label>New Password</mat-label>
          <input matInput
                 formControlName="newPassword"
                 type="password"
                 autocomplete="new-password"
                 placeholder="Enter new password"
          >
          <mat-error *ngIf="newPassword?.errors?.strongPassword">Password must be at least 8 characters long and include one number/special character, one lowercase, and one uppercase letter. Unable to reuse a password from the last 12 months.</mat-error>
          <mat-error *ngIf="newPassword?.errors?.minlength">Password length needs to be 8 or more characters</mat-error>
          <mat-error *ngIf="newPassword?.errors?.unableToUseSamePassword">Unable to use same password from last 12 months</mat-error>
          <mat-error *ngIf="newPassword?.errors?.required && !newPassword?.errors?.minlength">This field is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline"
                        floatLabel="always"
        >
          <mat-label>Confirm New Password</mat-label>
          <input matInput
                 formControlName="newPasswordConfirm"
                 type="password"
                 autocomplete="new-password"
                 placeholder="Confirm new password"
          >
          <mat-error *ngIf="newPasswordConfirm?.errors?.strongPassword">Password must be at least 8 characters long and include one number/special character, one lowercase, and one uppercase letter.</mat-error>
          <mat-error *ngIf="newPasswordConfirm?.errors?.minlength">Password length needs to be 8 or more characters</mat-error>
          <mat-error *ngIf="newPasswordConfirm?.errors?.unableToUseSamePassword">Unable to use same password from last 12 months</mat-error>
          <mat-error *ngIf="newPasswordConfirm?.errors?.required">This field is required</mat-error>
        </mat-form-field>
        <mat-error class="external-error-message" *ngIf="formGroup.hasError('noMatch') && !(newPasswordConfirm?.errors?.strongPassword || newPasswordConfirm?.errors?.minlength || newPasswordConfirm?.errors?.required)">Passwords do not match</mat-error>
        <div class="button-footer">
          <button mat-flat-button
                  class="cancel-edit-password bordered-button"
                  (click)="cancelChangePassword()"
          >
            Cancel
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="single-input">
    <mat-form-field appearance="outline"
                    floatLabel="always"
    >
      <mat-label>User Time Zone</mat-label>
      <input type="text"
             matInput
             placeholder="Select Time Zone"
             formControlName="timeZone"
             [matAutocomplete]="auto"
      >
      <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="profileUpdated.emit()"
      >
        <mat-option *ngFor="let tz of filteredOptions | async" [value]="tz">
          {{ tz }}
        </mat-option>

      </mat-autocomplete>
      <mat-error *ngIf="timeZone.errors?.required">This field is required</mat-error>
      <mat-error *ngIf="timeZone.errors?.invalidTimeZone">Select a valid timezone</mat-error>
    </mat-form-field>
  </div>
</div>
