<op-modal [rightFooterButtons]="rightFooterButtons" [ignoreEscape]="true" (onClose)="cancel()" class="cc-edit-modal-contents">
  <ng-template #headerTemplate>
    <div class="cc-edit-header">
      <div class="top-section">
        <form class="cc-edit-form" [formGroup]="editForm">
          <mat-form-field class="cc-name" appearance="outline">
            <mat-label>NAME OF {{ type?.value === EConsentCategoryType.UNAPPROVED ? 'UNAPPROVED' : 'APPROVED' }} CONSENT
              CATEGORY</mat-label>
            <input matInput (keyup)="updateName($event)" formControlName="name" [errorStateMatcher]="nameErrorStateMatcher">
            <mat-error *ngIf="name.hasError('duplicate')">A Consent Category with this name already exists.</mat-error>
          </mat-form-field>
          <mat-form-field class="cc-edit-type" appearance="outline" matTooltip="Type is set when consent category is created and cannot be changed.">
            <mat-label>{{'type' | uppercase}}</mat-label>
            <input matInput appearance="outline" formControlName="type" />
          </mat-form-field>
          <mat-icon class="notes-icon" [class.clicked]="showAddNotesField"
                    (click)="showAddNotesField = !showAddNotesField">
            description
            <mat-icon class="expand-notes-icon">{{ showAddNotesField ? "expand_less" : "expand_more" }}</mat-icon>
          </mat-icon>
        </form>
        <button class="close-btn" (click)="cancel()">
          <mat-icon class="close-btn-icon">close</mat-icon>
        </button>
      </div>
      <div class="notes-field-wrap">
        <textarea matInput
                  [formControl]="notes"
                  [hidden]="!showAddNotesField"
                  class="notes-field"
                  [placeholder]="notesPlaceholder"
                  (keyup)="updateNotes($event)"
        >
        </textarea>
      </div>

      <div>
        <mat-checkbox
          color="primary"
          [formControl]="isDefaultCC"
          class="default-cc-checkbox"
          matTooltipPosition="below">
          Add this as a default consent category to all audits I create <span class="cc-note">(note: all default CCs must be of the same type)</span>.
        </mat-checkbox>
      </div>
    </div>
  </ng-template>
  <ng-template #bodyTemplate>
    <mat-spinner *ngIf="!isLoaded" [diameter]="40" [strokeWidth]="4"></mat-spinner>
    <div class="cc-edit" *ngIf="consentCategory">
      <p class="explanation">
        This Consent Category will {{ ccType }} these cookies, tags and request domains & geolocations, and {{ ccReverseType }} any others found.
      </p>
      <div class="tabs-btn-group">
        <button class="cc-add-new-items"
              (click)="openConsentCategoryModal({data: getCCModalData(), autoFocus: false})">
          <mat-icon>add_circle</mat-icon>
          ADD {{ type?.value === EConsentCategoryType.UNAPPROVED ? 'UNAPPROVED' : 'APPROVED' }} ITEMS FROM AN AUDIT
        </button>
        <div class="op-tabs">
          <ng-container *ngFor="let tab of ccTabs; let i = index">
            <span class="op-tab" [class.selected]="tabIsActive(tab.name)" (click)="changeTab(tab.name)">{{ tab.display }} ({{ tab.value }})</span>
            <span class="op-tab-divider" *ngIf="i < (ccTabs.length - 1)"></span>
          </ng-container>
        </div>
      </div>
      <div class="op-tabs-content">
        <cc-edit-cookies *ngIf="activeTab === ECCEditTabs.cookies"
                         [formControl]="cookies"
                         (tabDataCount)="updateTabCount($event)"
                         (formTouched)="updateSaveButtonState($event)">
        </cc-edit-cookies>
        <cc-edit-tags *ngIf="activeTab === ECCEditTabs.tags"
                      [accountTags]="accountTags"
                      [formControl]="tags"
                      (tabDataCount)="updateTabCount($event)"
                      (formTouched)="updateSaveButtonState($event)">
        </cc-edit-tags>
        <cc-edit-request-domains *ngIf="activeTab === ECCEditTabs.requestDomains"
                                 [formControl]="requestDomains"
                                 (tabDataCount)="updateTabCount($event)"
                                 (formTouched)="updateSaveButtonState($event)">
        </cc-edit-request-domains>
      </div>
    </div>
    <ng-container *ngIf="isLoaded && consentCategoryHasBeenDeleted">
      <div class="not-found-container">
        <div class="not-found">
          <p>The Consent Category you requested is not found. <a (click)="dialogRef.close()">Return to main screen.</a></p>
        </div>
      </div>
    </ng-container>

  </ng-template>
</op-modal>
