<split-card [topLabel]="widgetPagesWithUnapproved.topLabel"
            [topHandler]="widgetPagesWithUnapproved.topHandler"
            [state]="loadingState"
            [topTooltip]="widgetPagesWithUnapproved.tooltip"
>
  <div class="widget-inside-wrap">
    <div class="counts">
      <span class="count red">{{ pagesWithUnapprovedRequestCount | number }}</span>
    </div>
    <filter-spinner [state]="loadingState"></filter-spinner>
  </div>
</split-card>
<split-card [topLabel]="widgetUnapproved.topLabel"
            [topTooltip]="widgetUnapproved.tooltip"
            [topHandler]="widgetUnapproved.topHandler"
            [state]="loadingState"
            [topChangeContent]="widgetUnapproved.topChangeContent"
            [bottomHandler]="widgetUnapproved.bottomHandler"
            [topChangeMeaning]="widgetUnapproved.topChangeMeaning"
            [haveEnoughData]="widgetUnapprovedData?.length > 1"
>
  <sparkline-chart-insight meaningOfUp="negative"
                           meaningOfNoChange="none"
                           label="since last run"
                           [dataLoaded]="sparklineDataLoaded"
                           [dataPoints]="widgetUnapprovedData">
  </sparkline-chart-insight>
</split-card>
