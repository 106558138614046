export interface IConsentGroup extends ICmpGroup {
  updateType: IConsentGroupType;
  id?: number;
  selected: boolean;
  expanded: boolean;
  geo: string;
  domain: string;
  cookies: ICmpCookie[];
  cookieChangeCount?: number;
}

export interface ICmpGroup {
  vendor: string;
  groupName: string;
  groupId: string;
  cmpId: string;
  status: string;
  domain: string;
  cookies: ICmpCookie[];
}
export interface ICmpCookie {
  domain: string;
  regexDomain?: string;
  name: string;
  nameIsRegex: boolean;
  description: string;
  groupId: string;
  updateType: IConsentGroupType;
}
export enum ICmpVendors {
  OneTrust = 'ONE_TRUST',
  TrustArc = 'TRUST_ARC',
  Unknown = 'UNKNOWN',
}

export enum IConsentGroupType {
  Insert = 'Insert',
  Update = 'Update',
  Delete = 'Delete',
  NoChange = 'NoChange',
  NoChangeCookies = 'NoChangeCookies',  // Even though the CC didn't change, one or more cookies changed.
  NoAction_ExistingNotUpdated = 'NoAction_ExistingNotUpdated',
  NoAction_NewlyDetectedNotAdded = 'NoAction_NewlyDetectedNotAdded',
  NoAction_NotFoundNotDeleted = 'NoAction_NotFoundNotDeleted',
}

export interface ICountryCodes {
  id: number;
  localeName: string;
  localeCode: string;
}

export interface ICmpDetect {
  results: {
    requestId: string,
    locale: string,
    resultLocation: string,
  }[]
}

export interface ICmpDetectResponse {
  importRequestIds: string[];
}

export interface ICmpDetectProcessing {
  importResults: CmpRequestMap;
}
type CmpRequestMap = {
  [key: string]: ICmpDetectProcessingDetails;
};
export interface ICmpDetectProcessingDetails {
    "accountId": number,
    "cmpProvider": string,
    "created": number,
    "domain": string,
    "groups": ICmpGroup[],
    "locale": string,
    "requestId": string,
    "sourceUrl": string,
    "status": string,
}

export const countryCodes: ICountryCodes[] = [
    {
        "id": 1,
        "localeName": "Afghanistan",
        "localeCode": "af"
    },
    {
        "id": 2,
        "localeName": "Åland Islands",
        "localeCode": "ax"
    },
    {
        "id": 3,
        "localeName": "Albania",
        "localeCode": "al"
    },
    {
        "id": 4,
        "localeName": "Algeria",
        "localeCode": "dz"
    },
    {
        "id": 5,
        "localeName": "American Samoa",
        "localeCode": "as"
    },
    {
        "id": 6,
        "localeName": "Andorra",
        "localeCode": "ad"
    },
    {
        "id": 7,
        "localeName": "Angola",
        "localeCode": "ao"
    },
    {
        "id": 8,
        "localeName": "Anguilla",
        "localeCode": "ai"
    },
    {
        "id": 9,
        "localeName": "Antarctica",
        "localeCode": "aq"
    },
    {
        "id": 10,
        "localeName": "Antigua and Barbuda",
        "localeCode": "ag"
    },
    {
        "id": 11,
        "localeName": "Argentina",
        "localeCode": "ar"
    },
    {
        "id": 12,
        "localeName": "Armenia",
        "localeCode": "am"
    },
    {
        "id": 13,
        "localeName": "Aruba",
        "localeCode": "aw"
    },
    {
        "id": 14,
        "localeName": "Australia",
        "localeCode": "au"
    },
    {
        "id": 15,
        "localeName": "Austria",
        "localeCode": "at"
    },
    {
        "id": 16,
        "localeName": "Azerbaijan",
        "localeCode": "az"
    },
    {
        "id": 17,
        "localeName": "Bahamas",
        "localeCode": "bs"
    },
    {
        "id": 18,
        "localeName": "Bahrain",
        "localeCode": "bh"
    },
    {
        "id": 19,
        "localeName": "Bangladesh",
        "localeCode": "bd"
    },
    {
        "id": 20,
        "localeName": "Barbados",
        "localeCode": "bb"
    },
    {
        "id": 21,
        "localeName": "Belarus",
        "localeCode": "by"
    },
    {
        "id": 22,
        "localeName": "Belgium",
        "localeCode": "be"
    },
    {
        "id": 23,
        "localeName": "Belize",
        "localeCode": "bz"
    },
    {
        "id": 24,
        "localeName": "Benin",
        "localeCode": "bj"
    },
    {
        "id": 25,
        "localeName": "Bermuda",
        "localeCode": "bm"
    },
    {
        "id": 26,
        "localeName": "Bhutan",
        "localeCode": "bt"
    },
    {
        "id": 27,
        "localeName": "Bolivia",
        "localeCode": "bo"
    },
    {
        "id": 28,
        "localeName": "Bonaire\n Sint Eustatius\n Saba",
        "localeCode": "bq"
    },
    {
        "id": 29,
        "localeName": "Bosnia and Herzegovina",
        "localeCode": "ba"
    },
    {
        "id": 30,
        "localeName": "Botswana",
        "localeCode": "bw"
    },
    {
        "id": 31,
        "localeName": "Bouvet Island",
        "localeCode": "bv"
    },
    {
        "id": 32,
        "localeName": "Brazil",
        "localeCode": "br"
    },
    {
        "id": 33,
        "localeName": "British Indian Ocean Territory",
        "localeCode": "io"
    },
    {
        "id": 34,
        "localeName": "Brunei Darussalam",
        "localeCode": "bn"
    },
    {
        "id": 35,
        "localeName": "Bulgaria",
        "localeCode": "bg"
    },
    {
        "id": 36,
        "localeName": "Burkina Faso",
        "localeCode": "bf"
    },
    {
        "id": 37,
        "localeName": "Burundi",
        "localeCode": "bi"
    },
    {
        "id": 38,
        "localeName": "Cambodia",
        "localeCode": "kh"
    },
    {
        "id": 39,
        "localeName": "Cameroon",
        "localeCode": "cm"
    },
    {
        "id": 40,
        "localeName": "Canada",
        "localeCode": "ca"
    },
    {
        "id": 42,
        "localeName": "Cayman Islands",
        "localeCode": "ky"
    },
    {
        "id": 43,
        "localeName": "Central African Republic",
        "localeCode": "cf"
    },
    {
        "id": 44,
        "localeName": "Chad",
        "localeCode": "td"
    },
    {
        "id": 45,
        "localeName": "Chile",
        "localeCode": "cl"
    },
    {
        "id": 46,
        "localeName": "China",
        "localeCode": "cn"
    },
    {
        "id": 47,
        "localeName": "Christmas Island",
        "localeCode": "cx"
    },
    {
        "id": 48,
        "localeName": "Cocos (Keeling) Islands",
        "localeCode": "cc"
    },
    {
        "id": 49,
        "localeName": "Colombia",
        "localeCode": "co"
    },
    {
        "id": 50,
        "localeName": "Comoros",
        "localeCode": "km"
    },
    {
        "id": 51,
        "localeName": "Democratic Republic of the Congo",
        "localeCode": "cd"
    },
    {
        "id": 52,
        "localeName": "Congo",
        "localeCode": "cg"
    },
    {
        "id": 53,
        "localeName": "Cook Islands",
        "localeCode": "ck"
    },
    {
        "id": 54,
        "localeName": "Costa Rica",
        "localeCode": "cr"
    },
    {
        "id": 55,
        "localeName": "Côte d'Ivoire",
        "localeCode": "ci"
    },
    {
        "id": 56,
        "localeName": "Croatia",
        "localeCode": "hr"
    },
    {
        "id": 57,
        "localeName": "Cuba",
        "localeCode": "cu"
    },
    {
        "id": 58,
        "localeName": "Curaçao",
        "localeCode": "cw"
    },
    {
        "id": 59,
        "localeName": "Cyprus",
        "localeCode": "cy"
    },
    {
        "id": 60,
        "localeName": "Czechia",
        "localeCode": "cz"
    },
    {
        "id": 61,
        "localeName": "Denmark",
        "localeCode": "dk"
    },
    {
        "id": 62,
        "localeName": "Djibouti",
        "localeCode": "dj"
    },
    {
        "id": 63,
        "localeName": "Dominica",
        "localeCode": "dm"
    },
    {
        "id": 64,
        "localeName": "Dominican Republic",
        "localeCode": "do"
    },
    {
        "id": 65,
        "localeName": "Ecuador",
        "localeCode": "ec"
    },
    {
        "id": 66,
        "localeName": "Egypt",
        "localeCode": "eg"
    },
    {
        "id": 67,
        "localeName": "El Salvador",
        "localeCode": "sv"
    },
    {
        "id": 68,
        "localeName": "Equatorial Guinea",
        "localeCode": "gq"
    },
    {
        "id": 69,
        "localeName": "Eritrea",
        "localeCode": "er"
    },
    {
        "id": 70,
        "localeName": "Estonia",
        "localeCode": "ee"
    },
    {
        "id": 71,
        "localeName": "Ethiopia",
        "localeCode": "et"
    },
    {
        "id": 72,
        "localeName": "Falkland Islands",
        "localeCode": "fk"
    },
    {
        "id": 73,
        "localeName": "Faroe Islands",
        "localeCode": "fo"
    },
    {
        "id": 74,
        "localeName": "Fiji",
        "localeCode": "fj"
    },
    {
        "id": 75,
        "localeName": "Finland",
        "localeCode": "fi"
    },
    {
        "id": 76,
        "localeName": "France",
        "localeCode": "fr"
    },
    {
        "id": 77,
        "localeName": "French Guiana",
        "localeCode": "gf"
    },
    {
        "id": 78,
        "localeName": "French Polynesia",
        "localeCode": "pf"
    },
    {
        "id": 79,
        "localeName": "French Southern Territories",
        "localeCode": "tf"
    },
    {
        "id": 80,
        "localeName": "Gabon",
        "localeCode": "ga"
    },
    {
        "id": 81,
        "localeName": "Gambia",
        "localeCode": "gm"
    },
    {
        "id": 82,
        "localeName": "Georgia",
        "localeCode": "ge"
    },
    {
        "id": 83,
        "localeName": "Germany",
        "localeCode": "de"
    },
    {
        "id": 84,
        "localeName": "Ghana",
        "localeCode": "gh"
    },
    {
        "id": 85,
        "localeName": "Gibraltar",
        "localeCode": "gi"
    },
    {
        "id": 86,
        "localeName": "Greece",
        "localeCode": "gr"
    },
    {
        "id": 87,
        "localeName": "Greenland",
        "localeCode": "gl"
    },
    {
        "id": 88,
        "localeName": "Grenada",
        "localeCode": "gd"
    },
    {
        "id": 89,
        "localeName": "Guadeloupe",
        "localeCode": "gp"
    },
    {
        "id": 90,
        "localeName": "Guam",
        "localeCode": "gu"
    },
    {
        "id": 91,
        "localeName": "Guatemala",
        "localeCode": "gt"
    },
    {
        "id": 92,
        "localeName": "Guernsey",
        "localeCode": "gg"
    },
    {
        "id": 93,
        "localeName": "Guinea",
        "localeCode": "gn"
    },
    {
        "id": 94,
        "localeName": "Guinea-Bissau",
        "localeCode": "gw"
    },
    {
        "id": 95,
        "localeName": "Guyana",
        "localeCode": "gy"
    },
    {
        "id": 96,
        "localeName": "Haiti",
        "localeCode": "ht"
    },
    {
        "id": 97,
        "localeName": "Heard Island and McDonald Islands",
        "localeCode": "hm"
    },
    {
        "id": 98,
        "localeName": "Vatican City",
        "localeCode": "va"
    },
    {
        "id": 99,
        "localeName": "Honduras",
        "localeCode": "hn"
    },
    {
        "id": 100,
        "localeName": "Hong Kong",
        "localeCode": "hk"
    },
    {
        "id": 101,
        "localeName": "Hungary",
        "localeCode": "hu"
    },
    {
        "id": 102,
        "localeName": "Iceland",
        "localeCode": "is"
    },
    {
        "id": 103,
        "localeName": "India",
        "localeCode": "in"
    },
    {
        "id": 104,
        "localeName": "Indonesia",
        "localeCode": "id"
    },
    {
        "id": 105,
        "localeName": "Iran",
        "localeCode": "ir"
    },
    {
        "id": 106,
        "localeName": "Iraq",
        "localeCode": "iq"
    },
    {
        "id": 107,
        "localeName": "Ireland",
        "localeCode": "ie"
    },
    {
        "id": 108,
        "localeName": "Isle of Man",
        "localeCode": "im"
    },
    {
        "id": 109,
        "localeName": "Israel",
        "localeCode": "il"
    },
    {
        "id": 110,
        "localeName": "Italy",
        "localeCode": "it"
    },
    {
        "id": 111,
        "localeName": "Jamaica",
        "localeCode": "jm"
    },
    {
        "id": 112,
        "localeName": "Japan",
        "localeCode": "jp"
    },
    {
        "id": 113,
        "localeName": "Jersey",
        "localeCode": "je"
    },
    {
        "id": 114,
        "localeName": "Jordan",
        "localeCode": "jo"
    },
    {
        "id": 115,
        "localeName": "Kazakhstan",
        "localeCode": "kz"
    },
    {
        "id": 116,
        "localeName": "Kenya",
        "localeCode": "ke"
    },
    {
        "id": 117,
        "localeName": "Kiribati",
        "localeCode": "ki"
    },
    {
        "id": 118,
        "localeName": "Democratic People's Republic of Korea",
        "localeCode": "kp"
    },
    {
        "id": 119,
        "localeName": "Republic of Korea",
        "localeCode": "kr"
    },
    {
        "id": 121,
        "localeName": "Kuwait",
        "localeCode": "kw"
    },
    {
        "id": 122,
        "localeName": "Kyrgyzstan",
        "localeCode": "kg"
    },
    {
        "id": 123,
        "localeName": "Lao People's Democratic Republic",
        "localeCode": "la"
    },
    {
        "id": 124,
        "localeName": "Latvia",
        "localeCode": "lv"
    },
    {
        "id": 125,
        "localeName": "Lebanon",
        "localeCode": "lb"
    },
    {
        "id": 126,
        "localeName": "Lesotho",
        "localeCode": "ls"
    },
    {
        "id": 127,
        "localeName": "Liberia",
        "localeCode": "lr"
    },
    {
        "id": 128,
        "localeName": "Libya",
        "localeCode": "ly"
    },
    {
        "id": 129,
        "localeName": "Liechtenstein",
        "localeCode": "li"
    },
    {
        "id": 130,
        "localeName": "Lithuania",
        "localeCode": "lt"
    },
    {
        "id": 131,
        "localeName": "Luxembourg",
        "localeCode": "lu"
    },
    {
        "id": 132,
        "localeName": "Macao",
        "localeCode": "mo"
    },
    {
        "id": 134,
        "localeName": "Madagascar",
        "localeCode": "mg"
    },
    {
        "id": 135,
        "localeName": "Malawi",
        "localeCode": "mw"
    },
    {
        "id": 136,
        "localeName": "Malaysia",
        "localeCode": "my"
    },
    {
        "id": 137,
        "localeName": "Maldives",
        "localeCode": "mv"
    },
    {
        "id": 138,
        "localeName": "Mali",
        "localeCode": "ml"
    },
    {
        "id": 139,
        "localeName": "Malta",
        "localeCode": "mt"
    },
    {
        "id": 140,
        "localeName": "Marshall Islands",
        "localeCode": "mh"
    },
    {
        "id": 141,
        "localeName": "Martinique",
        "localeCode": "mq"
    },
    {
        "id": 142,
        "localeName": "Mauritania",
        "localeCode": "mr"
    },
    {
        "id": 143,
        "localeName": "Mauritius",
        "localeCode": "mu"
    },
    {
        "id": 144,
        "localeName": "Mayotte",
        "localeCode": "yt"
    },
    {
        "id": 145,
        "localeName": "Mexico",
        "localeCode": "mx"
    },
    {
        "id": 146,
        "localeName": "Micronesia",
        "localeCode": "fm"
    },
    {
        "id": 147,
        "localeName": "Moldova",
        "localeCode": "md"
    },
    {
        "id": 148,
        "localeName": "Monaco",
        "localeCode": "mc"
    },
    {
        "id": 149,
        "localeName": "Mongolia",
        "localeCode": "mn"
    },
    {
        "id": 150,
        "localeName": "Montenegro",
        "localeCode": "me"
    },
    {
        "id": 151,
        "localeName": "Montserrat",
        "localeCode": "ms"
    },
    {
        "id": 152,
        "localeName": "Morocco",
        "localeCode": "ma"
    },
    {
        "id": 153,
        "localeName": "Mozambique",
        "localeCode": "mz"
    },
    {
        "id": 154,
        "localeName": "Myanmar",
        "localeCode": "mm"
    },
    {
        "id": 155,
        "localeName": "Namibia",
        "localeCode": "na"
    },
    {
        "id": 156,
        "localeName": "Nauru",
        "localeCode": "nr"
    },
    {
        "id": 157,
        "localeName": "Nepal",
        "localeCode": "np"
    },
    {
        "id": 158,
        "localeName": "Netherlands",
        "localeCode": "nl"
    },
    {
        "id": 160,
        "localeName": "New Caledonia",
        "localeCode": "nc"
    },
    {
        "id": 161,
        "localeName": "New Zealand",
        "localeCode": "nz"
    },
    {
        "id": 162,
        "localeName": "Nicaragua",
        "localeCode": "ni"
    },
    {
        "id": 163,
        "localeName": "Niger",
        "localeCode": "ne"
    },
    {
        "id": 164,
        "localeName": "Nigeria",
        "localeCode": "ng"
    },
    {
        "id": 165,
        "localeName": "Niue",
        "localeCode": "nu"
    },
    {
        "id": 166,
        "localeName": "Norfolk Island",
        "localeCode": "nf"
    },
    {
        "id": 167,
        "localeName": "Northern Mariana Islands",
        "localeCode": "mp"
    },
    {
        "id": 168,
        "localeName": "Norway",
        "localeCode": "no"
    },
    {
        "id": 169,
        "localeName": "Oman",
        "localeCode": "om"
    },
    {
        "id": 170,
        "localeName": "Pakistan",
        "localeCode": "pk"
    },
    {
        "id": 171,
        "localeName": "Palau",
        "localeCode": "pw"
    },
    {
        "id": 172,
        "localeName": "Palestine",
        "localeCode": "ps"
    },
    {
        "id": 173,
        "localeName": "Panama",
        "localeCode": "pa"
    },
    {
        "id": 174,
        "localeName": "Papua New Guinea",
        "localeCode": "pg"
    },
    {
        "id": 175,
        "localeName": "Paraguay",
        "localeCode": "py"
    },
    {
        "id": 176,
        "localeName": "Peru",
        "localeCode": "pe"
    },
    {
        "id": 177,
        "localeName": "Philippines",
        "localeCode": "ph"
    },
    {
        "id": 178,
        "localeName": "Pitcairn",
        "localeCode": "pn"
    },
    {
        "id": 179,
        "localeName": "Poland",
        "localeCode": "pl"
    },
    {
        "id": 180,
        "localeName": "Portugal",
        "localeCode": "pt"
    },
    {
        "id": 181,
        "localeName": "Puerto Rico",
        "localeCode": "pr"
    },
    {
        "id": 182,
        "localeName": "Qatar",
        "localeCode": "qa"
    },
    {
        "id": 184,
        "localeName": "Réunion",
        "localeCode": "re"
    },
    {
        "id": 185,
        "localeName": "Romania",
        "localeCode": "ro"
    },
    {
        "id": 186,
        "localeName": "Russian Federation",
        "localeCode": "ru"
    },
    {
        "id": 187,
        "localeName": "Rwanda",
        "localeCode": "rw"
    },
    {
        "id": 188,
        "localeName": "Saint Barthélemy",
        "localeCode": "bl"
    },
    {
        "id": 189,
        "localeName": "Saint Helena",
        "localeCode": "sh"
    },
    {
        "id": 190,
        "localeName": "Saint Kitts and Nevis",
        "localeCode": "kn"
    },
    {
        "id": 191,
        "localeName": "Saint Lucia",
        "localeCode": "lc"
    },
    {
        "id": 192,
        "localeName": "Saint Martin",
        "localeCode": "mf"
    },
    {
        "id": 193,
        "localeName": "Saint Pierre and Miquelon",
        "localeCode": "pm"
    },
    {
        "id": 194,
        "localeName": "Saint Vincent and the Grenadines",
        "localeCode": "vc"
    },
    {
        "id": 195,
        "localeName": "Samoa",
        "localeCode": "ws"
    },
    {
        "id": 196,
        "localeName": "San Marino",
        "localeCode": "sm"
    },
    {
        "id": 197,
        "localeName": "Sao Tome and Principe",
        "localeCode": "st"
    },
    {
        "id": 198,
        "localeName": "Saudi Arabia",
        "localeCode": "sa"
    },
    {
        "id": 199,
        "localeName": "Senegal",
        "localeCode": "sn"
    },
    {
        "id": 200,
        "localeName": "Serbia",
        "localeCode": "rs"
    },
    {
        "id": 201,
        "localeName": "Seychelles",
        "localeCode": "sc"
    },
    {
        "id": 202,
        "localeName": "Sierra Leone",
        "localeCode": "sl"
    },
    {
        "id": 203,
        "localeName": "Singapore",
        "localeCode": "sg"
    },
    {
        "id": 204,
        "localeName": "Sint Maarten",
        "localeCode": "sx"
    },
    {
        "id": 205,
        "localeName": "Slovakia",
        "localeCode": "sk"
    },
    {
        "id": 206,
        "localeName": "Slovenia",
        "localeCode": "si"
    },
    {
        "id": 207,
        "localeName": "Solomon Islands",
        "localeCode": "sb"
    },
    {
        "id": 208,
        "localeName": "Somalia",
        "localeCode": "so"
    },
    {
        "id": 209,
        "localeName": "South Africa",
        "localeCode": "za"
    },
    {
        "id": 210,
        "localeName": "South Georgia and the South Sandwich Islands",
        "localeCode": "gs"
    },
    {
        "id": 211,
        "localeName": "South Sudan",
        "localeCode": "ss"
    },
    {
        "id": 212,
        "localeName": "Spain",
        "localeCode": "es"
    },
    {
        "id": 213,
        "localeName": "Sri Lanka",
        "localeCode": "lk"
    },
    {
        "id": 214,
        "localeName": "Sudan",
        "localeCode": "sd"
    },
    {
        "id": 215,
        "localeName": "Suriname",
        "localeCode": "sr"
    },
    {
        "id": 216,
        "localeName": "Svalbard\n Jan Mayen",
        "localeCode": "sj"
    },
    {
        "id": 218,
        "localeName": "Sweden",
        "localeCode": "se"
    },
    {
        "id": 219,
        "localeName": "Switzerland",
        "localeCode": "ch"
    },
    {
        "id": 220,
        "localeName": "Syrian Arab Republic",
        "localeCode": "sy"
    },
    {
        "id": 221,
        "localeName": "Taiwan",
        "localeCode": "tw"
    },
    {
        "id": 222,
        "localeName": "Tajikistan",
        "localeCode": "tj"
    },
    {
        "id": 223,
        "localeName": "Tanzania",
        "localeCode": "tz"
    },
    {
        "id": 224,
        "localeName": "Thailand",
        "localeCode": "th"
    },
    {
        "id": 225,
        "localeName": "Timor-Leste",
        "localeCode": "tl"
    },
    {
        "id": 226,
        "localeName": "Togo",
        "localeCode": "tg"
    },
    {
        "id": 227,
        "localeName": "Tokelau",
        "localeCode": "tk"
    },
    {
        "id": 228,
        "localeName": "Tonga",
        "localeCode": "to"
    },
    {
        "id": 229,
        "localeName": "Trinidad and Tobago",
        "localeCode": "tt"
    },
    {
        "id": 230,
        "localeName": "Tunisia",
        "localeCode": "tn"
    },
    {
        "id": 232,
        "localeName": "Türkiye",
        "localeCode": "tr"
    },
    {
        "id": 233,
        "localeName": "Turkmenistan",
        "localeCode": "tm"
    },
    {
        "id": 234,
        "localeName": "Turks and Caicos Islands",
        "localeCode": "tc"
    },
    {
        "id": 235,
        "localeName": "Tuvalu",
        "localeCode": "tv"
    },
    {
        "id": 236,
        "localeName": "Uganda",
        "localeCode": "ug"
    },
    {
        "id": 237,
        "localeName": "Ukraine",
        "localeCode": "ua"
    },
    {
      "id": 238,
      "localeName": "United Arab Emirates",
        "localeCode": "ae"
    },
    {
        "id": 239,
        "localeName": "United Kingdom of Great Britain and Northern Ireland",
        "localeCode": "gb"
    },
    {
        "id": 241,
        "localeName": "United States Minor Outlying Islands",
        "localeCode": "um"
    },
    {
        "id": 242,
        "localeName": "Uruguay",
        "localeCode": "uy"
    },
    {
        "id": 243,
        "localeName": "Uzbekistan",
        "localeCode": "uz"
    },
    {
        "id": 244,
        "localeName": "Vanuatu",
        "localeCode": "vu"
    },
    {
        "id": 245,
        "localeName": "Venezuela",
        "localeCode": "ve"
    },
    {
        "id": 246,
        "localeName": "Viet Nam",
        "localeCode": "vn"
    },
    {
        "id": 247,
        "localeName": "Virgin Islands",
        "localeCode": "vg"
    },
    {
        "id": 248,
        "localeName": "Virgin Islands (U.S.)",
        "localeCode": "vi"
    },
    {
        "id": 249,
        "localeName": "Wallis and Futuna",
        "localeCode": "wf"
    },
    {
        "id": 250,
        "localeName": "Western Sahara",
        "localeCode": "eh"
    },
    {
        "id": 251,
        "localeName": "Yemen",
        "localeCode": "ye"
    },
    {
        "id": 252,
        "localeName": "Zambia",
        "localeCode": "zm"
    },
    {
        "id": 253,
        "localeName": "Zimbabwe",
        "localeCode": "zw"
    },
    {
        "id": 240,
        "localeName": "USA, Alabama",
        "localeCode": "us,al"
    },
    {
        "id": 240,
        "localeName": "USA, Alaska",
        "localeCode": "us,ak"
    },
    {
        "id": 240,
        "localeName": "USA, Arizona",
        "localeCode": "us,az"
    },
    {
        "id": 240,
        "localeName": "USA, Arkansas",
        "localeCode": "us,ar"
    },
    {
        "id": 240,
        "localeName": "USA, California",
        "localeCode": "us,ca"
    },
    {
        "id": 240,
        "localeName": "USA, Colorado",
        "localeCode": "us,co"
    },
    {
        "id": 240,
        "localeName": "USA, Connecticut",
        "localeCode": "us,ct"
    },
    {
        "id": 240,
        "localeName": "USA, Delaware",
        "localeCode": "us,de"
    },
    {
        "id": 240,
        "localeName": "USA, District of Columbia",
        "localeCode": "us,dc"
    },
    {
        "id": 240,
        "localeName": "USA, Florida",
        "localeCode": "us,fl"
    },
    {
        "id": 240,
        "localeName": "USA, Georgia",
        "localeCode": "us,ga"
    },
    {
        "id": 240,
        "localeName": "USA, Hawaii",
        "localeCode": "us,hi"
    },
    {
        "id": 240,
        "localeName": "USA, Idaho",
        "localeCode": "us,id"
    },
    {
        "id": 240,
        "localeName": "USA, Illinois",
        "localeCode": "us,il"
    },
    {
        "id": 240,
        "localeName": "USA, Indiana",
        "localeCode": "us,in"
    },
    {
        "id": 240,
        "localeName": "USA, Iowa",
        "localeCode": "us,ia"
    },
    {
        "id": 240,
        "localeName": "USA, Kansas",
        "localeCode": "us,ks"
    },
    {
        "id": 240,
        "localeName": "USA, Kentucky",
        "localeCode": "us,ky"
    },
    {
        "id": 240,
        "localeName": "USA, Louisiana",
        "localeCode": "us,la"
    },
    {
        "id": 240,
        "localeName": "USA, Maine",
        "localeCode": "us,me"
    },
    {
        "id": 240,
        "localeName": "USA, Maryland",
        "localeCode": "us,md"
    },
    {
        "id": 240,
        "localeName": "USA, Massachusetts",
        "localeCode": "us,ma"
    },
    {
        "id": 240,
        "localeName": "USA, Michigan",
        "localeCode": "us,mi"
    },
    {
        "id": 240,
        "localeName": "USA, Minnesota",
        "localeCode": "us,mn"
    },
    {
        "id": 240,
        "localeName": "USA, Mississippi",
        "localeCode": "us,ms"
    },
    {
        "id": 240,
        "localeName": "USA, Missouri",
        "localeCode": "us,mo"
    },
    {
        "id": 240,
        "localeName": "USA, Montana",
        "localeCode": "us,mt"
    },
    {
        "id": 240,
        "localeName": "USA, Nebraska",
        "localeCode": "us,ne"
    },
    {
        "id": 240,
        "localeName": "USA, Nevada",
        "localeCode": "us,nv"
    },
    {
        "id": 240,
        "localeName": "USA, New Hampshire",
        "localeCode": "us,nh"
    },
    {
        "id": 240,
        "localeName": "USA, New Jersey",
        "localeCode": "us,nj"
    },
    {
        "id": 240,
        "localeName": "USA, New Mexico",
        "localeCode": "us,nm"
    },
    {
        "id": 240,
        "localeName": "USA, New York",
        "localeCode": "us,ny"
    },
    {
        "id": 240,
        "localeName": "USA, North Carolina",
        "localeCode": "us,nc"
    },
    {
        "id": 240,
        "localeName": "USA, North Dakota",
        "localeCode": "us,nd"
    },
    {
        "id": 240,
        "localeName": "USA, Ohio",
        "localeCode": "us,oh"
    },
    {
        "id": 240,
        "localeName": "USA, Oklahoma",
        "localeCode": "us,ok"
    },
    {
        "id": 240,
        "localeName": "USA, Oregon",
        "localeCode": "us,or"
    },
    {
        "id": 240,
        "localeName": "USA, Pennsylvania",
        "localeCode": "us,pa"
    },
    {
        "id": 240,
        "localeName": "USA, Rhode Island",
        "localeCode": "us,ri"
    },
    {
        "id": 240,
        "localeName": "USA, South Carolina",
        "localeCode": "us,sc"
    },
    {
        "id": 240,
        "localeName": "USA, South Dakota",
        "localeCode": "us,sd"
    },
    {
        "id": 240,
        "localeName": "USA, Tennessee",
        "localeCode": "us,tn"
    },
    {
        "id": 240,
        "localeName": "USA, Texas",
        "localeCode": "us,tx"
    },
    {
        "id": 240,
        "localeName": "USA, Utah",
        "localeCode": "us,ut"
    },
    {
        "id": 240,
        "localeName": "USA, Vermont",
        "localeCode": "us,vt"
    },
    {
        "id": 240,
        "localeName": "USA, Virginia",
        "localeCode": "us,va"
    },
    {
        "id": 240,
        "localeName": "USA, Washington",
        "localeCode": "us,wa"
    },
    {
        "id": 240,
        "localeName": "USA, West Virginia",
        "localeCode": "us,wv"
    },
    {
        "id": 240,
        "localeName": "USA, Wisconsin",
        "localeCode": "us,wi"
    },
    {
        "id": 240,
        "localeName": "USA, Wyoming",
        "localeCode": "us,wy"
    }
  ];
