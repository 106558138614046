import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const TriggeredAlertsNames = {
  base: 'alerts'
}

export const TriggeredAlertsPaths = {
  base: 'alerts'
}

export const TriggeredAlertsUrlBuilder = {
  base: () => RouteBuilder.buildUrl([TriggeredAlertsPaths.base])
}