import { LiveConnectUrlBuilders } from './../live-connect.constants';
import { HarFileIngestorComponent } from './../har-file-ingestor/har-file-ingestor.component';
import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { from } from 'rxjs';
import {
  IDeviceCategory,
  IDeviceProfile,
  DeviceProfileService,
  EDeviceProfile,
} from '../device-profile.service';
import {
  IManualJourney,
  ManualJourneyService,
} from '../manual-journey/manualJourneyService';
import { ModalTemplates } from '../../modals/modalTemplates';
import { IDeleteItemModalData } from '../../modals/modalData';
import { IModalService } from '../../modals/modalService';
import { IMenuItem } from '../../shared/components/op-menu/op-menu.component';
import { IAdvancedConfigs } from '@app/components/shared/components/op-chip-selector/op-chip-selector.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Features } from '@app/moonbeamConstants';
import { Router, ActivatedRoute } from '@angular/router';
import { ILiveConnectStateParams } from '../live-connect.models';
import { HttpParams } from '@angular/common/http';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';

/*
FIXME: this component is a direct port of an old angularjs controller.
Nothing has been refactored to idiomatic Angular patterns.
*/

export interface ILiveConnectRouterState {
  deviceProfileType: EDeviceProfile;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'live-connect',
  templateUrl: './live-connect.component.html',
  styleUrls: ['./live-connect.component.scss'],
})
export class LiveConnectComponent implements OnInit {

  params: ILiveConnectStateParams;
  routerState: ILiveConnectRouterState;

  loaded = false;
  categoriesDropdownVisible = false;
  activeCategoryFilter: IDeviceCategory;

  categories: { [deviceProfileType: string]: IDeviceCategory };
  deviceProfiles: IDeviceProfile[] = [];
  filteredDeviceProfiles: IDeviceProfile[] = [];
  activeDeviceProfile: IDeviceProfile;
  deviceProfileJourneys: { [profileId: number]: any[] } = {};
  versionOfOsEditMode = false;

  allLabels: ILabel[];
  selectedLabels: ILabel[] = [];
  activeDeviceFilterLabels: ILabel[];

  isHarFileIngestorEnabled: boolean;

  activeOsFilter: { [os: string]: boolean };
  objectKeys = Object.keys;
  private readonly otherOsFilter = 'Other';

  private readonly activeDeviceProfileIdKey = 'live_connect_activeDeviceProfileId';
  private readonly deviceProfileTypeKey = 'live_connect_deviceProfileTypeKey';

  readonly chipsAdvancedConfig: IAdvancedConfigs = {
    collapsible: true,
    lines: 1,
    readOnly: false,
  };

  readonly journeyMenu: IMenuItem[] = [
    {
      icon: 'icon-delete',
      label: 'Delete',
      onClick: journey => this.deleteManualJourney(journey),
    },
  ];

  readonly deviceMenu: IMenuItem[] = [
    {
      icon: 'icon-edit',
      label: 'Edit',
      onClick: dp => this.editDeviceProfile(dp),
    },
    {
      icon: 'icon-delete',
      label: 'Delete',
      onClick: dp => this.deleteDeviceProfile(dp),
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dps: DeviceProfileService,
    private mjs: ManualJourneyService,
    private labelsApi: LabelService,
    private modal: IModalService,
    private modalNgService: OpModalService,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
  ) { }

  ngOnInit() {
    this.params = this.route.snapshot.params as ILiveConnectStateParams;

    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (state) {
      this.routerState = state as ILiveConnectRouterState;
    }

    this.categories = this.dps.getDeviceCategories();
    this.fetchDeviceProfiles();
    this.loadLabels();
    this.authenticationService.getFeaturesWithCache().subscribe(features => {
      this.isHarFileIngestorEnabled = features.includes(Features.harFileIngestor);
    });
  }

  private loadLabels(): void {
    this.labelsApi.getLabels().subscribe(labels => {
      this.allLabels = labels;
    });
  }

  private fetchDeviceProfiles(): void {
    this.dps.getDeviceProfiles(['labels']).subscribe(this.setDeviceProfiles);
  }

  private setDeviceProfiles = profiles => {
    this.deviceProfiles = profiles.map(p => {
      p.labels = p.labels || [];
      return p;
    });
    this.filteredDeviceProfiles = profiles;
    this.deviceCategoryFilterChanged(
      this.categories[this.params.dpType || this.routerState?.deviceProfileType || this.getDefaultDeviceProfileType()],
      profiles
    );
    this.loaded = true;
  }

  private getDefaultDeviceProfileIndex(
    deviceProfiles: Array<IDeviceProfile>
  ): number {
    const deviceProfileIdToActivate = this.params && this.params.dpId
      || this.storageService.getValue<number>(this.activeDeviceProfileIdKey, StorageType.Session);
    if (!deviceProfileIdToActivate) {
      return 0;
    } else {
      let dpIdex: number = deviceProfiles.findIndex(
        dp => dp.id == deviceProfileIdToActivate
      );
      return dpIdex > -1 ? dpIdex : 0;
    }
  }

  activateDeviceProfile(profile: IDeviceProfile) {
    if (this.activeDeviceProfile && this.activeDeviceProfile.id == profile.id) {
      return;
    } else {
      this.activeDeviceProfile = profile;
      this.storageService.setValue(this.activeDeviceProfileIdKey, profile.id, StorageType.Session);
      if (this.deviceProfileJourneys[profile.id]) {
        return;
      } else {
        from(this.mjs.getManualJourneys(profile.id, ['labels'])).subscribe(
          journeys => {
            this.deviceProfileJourneys[profile.id] = journeys;
          }
        );
      }
    }
  }

  activateDefaultDeviceProfile(deviceProfiles: Array<IDeviceProfile>) {
    this.activateDeviceProfile(
      deviceProfiles[this.getDefaultDeviceProfileIndex(deviceProfiles)]
    );
  }

  deviceCategoryFilterChanged(
    category: IDeviceCategory,
    profiles: IDeviceProfile[]
  ) {
    this.toggleCategoryDropdown(false);
    this.activeCategoryFilter = category;
    this.storageService.setValue(this.deviceProfileTypeKey, category.deviceProfileType, StorageType.Session);
    this.determineVisibleOsFilters(category, profiles);
    this.filterDeviceProfiles(profiles);
    if (this.filteredDeviceProfiles.length) {
      this.activateDefaultDeviceProfile(this.filteredDeviceProfiles);
    } else {
      this.activeDeviceProfile = null;
    }
  }

  toggleCategoryDropdown(visible: boolean) {
    this.categoriesDropdownVisible = visible;
  }

  osFilterChanged(os: string): void {
    setTimeout(() => {
      // FIXME: race condition here, reliant on the value of this.activeOsFilter
      // and value is bound in template which updates value too late for proper filtering.
      this.filterDeviceProfiles(this.deviceProfiles);
    });
  }

  private filterDeviceProfiles(deviceProfiles: IDeviceProfile[]): void {
    if (!deviceProfiles) return;
    this.filteredDeviceProfiles = deviceProfiles.filter(dp => {
      return (
        this.deviceProfileHasLabels(dp.labels, this.activeDeviceFilterLabels) &&
        dp.journeyType == this.activeCategoryFilter.deviceProfileType &&
        (this.activeOsFilter[dp.osOfDevice] ||
          (this.activeOsFilter[this.otherOsFilter] &&
            typeof this.activeOsFilter[dp.osOfDevice] === 'undefined'))
      );
    });
    if (this.dps.hasNewDeviceProfileId()) {
      let newDp = this.filteredDeviceProfiles.find(
        dp => dp.id == this.dps.getNewDeviceProfileId()
      );
      if (newDp) {
        newDp.isNew = true;
        setTimeout(() => (newDp.isNew = false), 2500);
      }
      this.dps.clearNewDeviceProfileId();
    }
  }

  private deviceProfileHasLabels(
    deviceLabels: Array<ILabel>,
    selectedLabels: Array<ILabel>
  ): boolean {
    if (!selectedLabels || selectedLabels.length == 0) return true;
    let hasAllSelectedLabels = selectedLabels.map(selectedLabel => {
      let hasSelectedLabel = false;
      for (let i = 0; i < deviceLabels.length; i++) {
        if (!selectedLabel.id || selectedLabel.id == deviceLabels[i].id) {
          hasSelectedLabel = true;
          break;
        }
      }
      return hasSelectedLabel;
    });
    return hasAllSelectedLabels.reduce((acc, val) => acc && val);
  }

  private determineVisibleOsFilters(
    category: IDeviceCategory,
    profiles: IDeviceProfile[]
  ) {
    let allOsTypes: { [os: string]: boolean } = {};
    this.activeOsFilter = {};
    category.osTypes.forEach(osType => (allOsTypes[osType] = true));
    profiles
      .filter(dp => dp.journeyType == category.deviceProfileType)
      .forEach(dp => {
        if (allOsTypes[dp.osOfDevice]) {
          this.activeOsFilter[dp.osOfDevice] = true;
        } else {
          this.activeOsFilter[this.otherOsFilter] = true;
        }
      });
  }

  createFirstDeviceProfile(deviceProfileType: EDeviceProfile): void {
    this.router.navigateByUrl(LiveConnectUrlBuilders.deviceProfile(deviceProfileType));
  }

  setVersionOfOsEditMode(flag: boolean): void {
    this.versionOfOsEditMode = flag;
  }

  editDeviceProfile(deviceProfile: IDeviceProfile): void {
    const queryParams = new HttpParams().set('dpId', String(deviceProfile.id));
    this.router.navigateByUrl(LiveConnectUrlBuilders.deviceProfile(this.activeCategoryFilter.deviceProfileType) + '?' + queryParams.toString());
  }

  editDeviceProfileVersion(): void {
    this.setVersionOfOsEditMode(false);
    this.dps
      .updateDeviceProfile(this.activeDeviceProfile.id, {
        name: this.activeDeviceProfile.name,
        journeyType: this.activeDeviceProfile.journeyType,
        folderId: this.activeDeviceProfile.folderId,
        emails: this.activeDeviceProfile.emails,
        osOfDevice: this.activeDeviceProfile.osOfDevice,
        versionOfOs: this.activeDeviceProfile.versionOfOs,
        useTransparentProxy: this.dps.shouldUseTransparentProxy(
          this.activeDeviceProfile.osOfDevice
        ),
      })
      .subscribe(dp => {
        this.activeDeviceProfile = dp;
      });
  }

  createDeviceProfile(): void {
    this.router.navigateByUrl(LiveConnectUrlBuilders.deviceProfile(this.activeCategoryFilter.deviceProfileType));
  }

  deleteDeviceProfile(deviceProile: IDeviceProfile): void {
    const template = ModalTemplates.initTemplate<
      IDeleteItemModalData<IDeviceProfile>
    >(ModalTemplates.DeleteDeviceProfile);
    template.modalData = {
      item: deviceProile,
    };
    this.modal.showDelete<IDeleteItemModalData<IDeviceProfile>, boolean>(
      template,
      isDeleted => {
        if (isDeleted) {
          var dpIdex: number = this.deviceProfiles.findIndex(
            dp => dp.id == deviceProile.id
          );
          if (dpIdex > -1) this.deviceProfiles.splice(dpIdex, 1);
          this.filterDeviceProfiles(this.deviceProfiles);
          if (this.filteredDeviceProfiles.length)
            this.activateDefaultDeviceProfile(this.filteredDeviceProfiles);
        }
      }
    );
  }

  deviceFilterLabelsChanged(): void {
    this.activeDeviceFilterLabels = this.selectedLabels;
    this.filterDeviceProfiles(this.deviceProfiles);
    this.setActiveDeviceProfile();
  }

  private setActiveDeviceProfile(): void {
    if (!this.filteredDeviceProfiles.length) return;
    const deviceProfileNotExistInFiltered = !this.filteredDeviceProfiles.find(
      fdp => fdp.id === this.activeDeviceProfile.id
    );
    deviceProfileNotExistInFiltered &&
      this.activateDefaultDeviceProfile(this.filteredDeviceProfiles);
  }

  createManualJourney(dp: IDeviceProfile): void {
    this.openManualJourneySetup({
      dpId: dp.id
    });
  }
  
  openManualJourneyReport(dp: IDeviceProfile, mj: IManualJourney): void {
    this.openManualJourneySetup({
      dpId: dp.id,
      mjId: mj.id
    });

  }

  private openManualJourneySetup({dpId, mjId}: {dpId: number, mjId?: number}) {
    this.router.navigateByUrl(mjId ?
      LiveConnectUrlBuilders.manualJourneyEdit(dpId, mjId) :
      LiveConnectUrlBuilders.manualJourneyCreate(dpId)
    );
  }

  deleteManualJourney(mj: IManualJourney): void {
    const template = ModalTemplates.initTemplate<
      IDeleteItemModalData<IManualJourney>
    >(ModalTemplates.DeleteManualJourney);
    template.modalData = {
      item: mj,
    };
    this.modal.showDelete<IDeleteItemModalData<IManualJourney>, boolean>(
      template,
      isDeleted => {
        if (isDeleted) {
          var mjIdex: number = this.deviceProfileJourneys[
            this.activeDeviceProfile.id
          ].findIndex(j => j.id == mj.id);
          if (mjIdex > -1)
            this.deviceProfileJourneys[this.activeDeviceProfile.id].splice(
              mjIdex,
              1
            );
        }
      }
    );
  }

  createJourneyLabel(name: string, journey: IManualJourney) {
    this.labelsApi.createLabel(name).subscribe(label => {
      this.allLabels.push(label);
      this.selectJourneyLabel(label, journey);
    });
  }

  selectJourneyLabel(label: ILabel, journey: IManualJourney) {
    if (!journey.labels) journey.labels = [];

    from(
      this.mjs.updateManualJourneyLabels(
        this.activeDeviceProfile.id,
        journey.id,
        [...journey.labels, label]
      )
    ).subscribe(labels => {
      journey.labels = labels;
    });
  }

  removeJourneyLabel(label: ILabel, journey: IManualJourney) {
    this.mjs.deleteManualJourneyLabel(
      this.activeDeviceProfile.id,
      journey.id,
      label.id
    );
  }

  createDeviceLabel(name: string, device: IDeviceProfile) {
    this.labelsApi.createLabel(name).subscribe(label => {
      this.allLabels.push(label);
      this.selectDeviceLabel(label, device);
    });
  }

  selectDeviceLabel(label: ILabel, device: IDeviceProfile) {
    this.dps
      .updateDeviceProfileLabels(device.id, [...device.labels, label])
      .subscribe(labels => {
        device.labels = labels;
      });
  }

  removeDeviceLabel(label: ILabel, device: IDeviceProfile) {
    this.dps.deleteDeviceProfileLabel(device.id, label.id).subscribe();
  }

  openUploadHarModal(dp: IDeviceProfile): void {
    const data = {
      deviceId: dp.id
    };
    this.modalNgService.openModal(HarFileIngestorComponent, {data});
  }

  private getDefaultDeviceProfileType(): string {
    const storageValue = this.storageService.getValue<string>(this.deviceProfileTypeKey, StorageType.Session);
    return storageValue || EDeviceProfile.Mobile;
  }
}
