<div class="calendar mat-elevation-z2">
  <div class="calendar-header">
    <span class="calendar-left-arrow glyphicon glyphicon-menu-left"
          (click)="handleLeftArrow()">
    </span>
    <span class="calendar-title">{{ currentMonth | date:titleDateFormat }}</span>
    <span class="calendar-right-arrow glyphicon glyphicon-menu-right"
          (click)="handleRightArrow()"
          [class.disabled]="!rightArrowEnabled">
    </span>
  </div>
  <div class="calendar-wrp">
    <div class="calendar-body">
      <div class="calendar-week" *ngFor="let week of weeks">
        <div class="calendar-day"
              [ngClass]="{'current-month': day.date, 'selected-date': day.selected, 'has-events': day.numOfEventsTotal > 0}"
              *ngFor="let day of week.days"
              (click)="handleClickDate(day, true)">
          <span class="calendar-day-number">{{ day.date !== 0 ? day.date : '' }}</span>

          <span class="cal-day-events-collapsed"
                [class.has-flag]="containsFlaggedEvent(day)"
                *ngIf="day.numOfEventsTotal">
                {{ day.numOfEventsTotal }}
          </span>
          <span class="cal-day-events-expanded" *ngIf="day.numOfEventsTotal">
            <div class="events-count-by-status">
                <mat-icon class="calendar-icon calendar-icon-success" matTooltip="Success" matTooltipPosition="above">check_circle</mat-icon>
                <span>{{day.numOfEventsByStatus.get(0)}}</span>
            </div>
            <div class="events-count-by-status">
                <mat-icon class="calendar-icon calendar-icon-action-failure" matTooltip="Action Failure" matTooltipPosition="above">cancel</mat-icon>
                <span>{{day.numOfEventsByStatus.get(1)}}</span>
            </div>
            <div class="events-count-by-status">
                <mat-icon class="calendar-icon calendar-icon-rule-failure" matTooltip="Rule Failure" matTooltipPosition="above">error</mat-icon>
                <span>{{day.numOfEventsByStatus.get(2)}}</span>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<upgrade-user-banner *ngIf="showUpgradeUserBanner"
                     [forceHidePartnerLogo]="true"
                     [forceHidePagesScanned]="true"
                     textOverride="Start FREE trial to schedule a future run and see trending data comparisons ">
</upgrade-user-banner>
