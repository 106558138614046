<component-template componentName="OP Section Divider">
  <component-library-section sectionName="Overview" selector='&lt;op-section-divider [text]="dividerText"&gt;'>
    <p>This is the op-section-divider component. It's primarily used in the admin portal on the product limits tab. Additionally, it's used in the keyboard shortcuts modal.</p>

    <div class="op-section-divider-example">
      <op-section-divider text="Sample Text"></op-section-divider>
    </div>

    <div class="op-section-divider-example">
      <op-section-divider text="Even Longer Sample Text That Showcases This Component"></op-section-divider>
    </div>
  </component-library-section>
</component-template>