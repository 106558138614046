import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { OpRunDateComponent } from './op-run-date/op-run-date.component';
import { OpRunTimeComponent } from './op-run-time/op-run-time.component';
import { OpRunFrequencyComponent } from './op-run-frequency/op-run-frequency.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IOpRecurrenceResponse } from './op-recurrence.models';
import { MatFormFieldModule } from '@angular/material/form-field';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ERecurrenceItemType } from './op-recurrence.constants';
import { CustomFrequencyModalComponent } from './op-run-frequency/custom-frequency-modal/custom-frequency-modal.component';

@Component({
  selector: 'op-recurrence',
  standalone: true,
  imports: [
    CommonModule,
    OpRunDateComponent,
    OpRunTimeComponent,
    OpRunFrequencyComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    CustomFrequencyModalComponent
  ],
  templateUrl: './op-recurrence.component.html',
  styleUrls: ['./op-recurrence.component.scss']
})
export class OpRecurrenceComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * Instructions for using this component:
   *
   * 1. Add the <op-recurrence> component to your template
   * 2. In the parent form, add a form control that represents this recurrence component
   *    NOTE: You don't need to patch the recurrence form control in the
   *    parent form – this component will do that for you!
   * 3. Pass in the form control from the parent form to the parentFormField input
   * 4. Pass in the recurrence object (response from the API should look like IOpRecurrenceResponse) as an input
   */

  @Input() itemType: ERecurrenceItemType;
  @Input() parentFormField: FormControl;
  @Input() recurrence: IOpRecurrenceResponse;

  /**
   * Optional input for the number of items that are being scheduled.
   * This is only used when the item type is 'Generic'
   */
  @Input() itemCount?: number;

  private destroy$: Subject<void> = new Subject<void>();

  recurrenceForm: FormGroup;
  runDateTimeHint: string = '';
  fieldLabel: string = 'Frequency';

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    this.initForm();
    this.createRunDateTimeHint();
    this.setFieldLabel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.recurrence.currentValue && this.recurrenceForm) {
      this.patchForm();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createRunDateTimeHint(): void {
    this.runDateTimeHint = this.itemType === ERecurrenceItemType.Generic
      ? `Specify the date and time when the item${this.itemCount > 1 ? 's' : ''} should start.`
      : `Specify the date and time when the ${this.itemType}${this.itemCount > 1 ? 's' : ''} should start.`;
  }

  private setFieldLabel(): void {
    this.fieldLabel = this.itemType === ERecurrenceItemType.Generic
      ? `How often should the item${this.itemCount > 1 ? 's' : ''} run?`
      : `How often should the ${this.itemType} run?`;
  }

  private initForm(): void {
    this.recurrenceForm = new FormGroup({
      runDate: new FormGroup({
        date: new FormControl(null),
      }),
      runTime: new FormGroup({
        time: new FormControl(null, Validators.required),
        timeZone: new FormControl(null),
      }),
      frequency: new FormGroup({
        recurrenceRule: new FormControl(null),
        isPaused: new FormControl(null),
        description: new FormControl(null),
        presetType: new FormControl(null),
      }),
    });

    if (this.recurrence) {
      this.patchForm();
    }

    this.recurrenceForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.parentFormField.patchValue(value);
    });
  }

  private patchForm(): void {
    const formValue = {
      runDate: {
        date: new Date(this.recurrence.schedule.dtStart),
      },
      runTime: {
        time: this.extractTimeFromDate(this.recurrence.schedule.dtStart),
        timeZone: this.recurrence.schedule.tzId,
      },
      frequency: {
        recurrenceRule: this.recurrence.schedule.recurrenceRule,
        isPaused: this.recurrence.schedule.isPaused,
        description: this.recurrence.schedule.description,
        presetType: this.recurrence.schedule.presetType,
      }
    }

    this.recurrenceForm.patchValue(formValue);
    this.parentFormField.patchValue(this.recurrenceForm.value);
  }

  private extractTimeFromDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toTimeString().slice(0, 5); // Returns time in HH:MM format
  }

  showHint(el: HTMLElement): void {
    this.renderer.addClass(el, 'show-hint');
  }

  hideHint(el: HTMLElement): void {
    setTimeout(() => {
      this.renderer.removeClass(el, 'show-hint');
    }, 200);
  }

  get runDateFormGroup(): FormGroup {
    return this.recurrenceForm.get('runDate') as FormGroup;
  }

  get runTimeFormGroup(): FormGroup {
    return this.recurrenceForm.get('runTime') as FormGroup;
  }

  get frequencyFormGroup(): FormGroup {
    return this.recurrenceForm.get('frequency') as FormGroup;
  }
}
