import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { takeUntil } from 'rxjs/operators';
import { AuditReportBase } from '@app/components/audit-reports/reports/general-reports.models';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  ICookiePrivacyPagesTableRow,
  ICookiePrivacyPagesTableState
} from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.models';
import { ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import {
  formatPaginator,
  PageLoadColumnTooltip,
  PRIVACY_COOKIES_SEARCH_TEXT_KEY
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'privacy-cookies-pages-logs-table',
  templateUrl: './privacy-cookies-pages-table.component.html',
  styleUrls: ['./privacy-cookies-pages-table.component.scss'],
})
export class PrivacyCookiesPagesTableComponent
  extends AuditReportBase
  implements OnInit, OnChanges {

  @Input() inputData: ICookiePrivacyPagesTableRow[];
  @Input() spinnerState: EFilterSpinnerState;
  @Input() tableState: ICookiePrivacyPagesTableState;
  @Input() selectedCookie;
  @Input() loadingRuleEdit = false;
  @Input() getTagNameByTagId: (tagId: number) => string;
  @Output() updateTableState = new EventEmitter<ICookiePrivacyPagesTableState>();

  @ViewChild(MatSort, {static: true}) tableSort: MatSort;
  @ViewChild(MatPaginator, {static: true}) tablePaginator: MatPaginator;

  auditId: number;
  runId: number;

  PageLoadColumnTooltip = PageLoadColumnTooltip;

  readonly TableColumn = CommonReportsPagesTableColumns;

  tableDataSource = new MatTableDataSource<ICookiePrivacyPagesTableRow>();
  displayedColumns$ = this.tableService.displayedColumns$;

  openedPageId: string = null;
  readonly EFilterSpinnerState = EFilterSpinnerState;
  protected readonly PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;

  constructor(
    private auditReportService: AuditReportService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private activatedRoute: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private tableService: ResizeableTableService,
    ) {
    super();

    this.activatedRoute.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;
    });
  }

  ngOnInit() {
    this.handleInputData(this.inputData);
    this.formatPaginator();
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.Cookies);

    this.pageDetailsDrawerService
      .pageDrawerClosed$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.openedPageId = null);

    this.tableSort.sortChange.subscribe((sort: Sort) => {
      this.tableState.sort.sortBy = sort.active as CommonReportsPagesTableColumns;
      this.tableState.sort.sortDesc = sort.direction === 'desc';
      this.tableState.pagination.page = 0;
      this.updateTableState.emit(this.tableState);
    });

    this.tablePaginator.page.subscribe((pagination: PageEvent) => {
      this.tableState.pagination.page = pagination.pageIndex;
      this.updateTableState.emit(this.tableState);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputData']) {
      this.handleInputData(changes['inputData'].currentValue);
    }
  }

  openPageDetails(page: ICookiePrivacyPagesTableRow) {
    const selectedCookieSearchState = {
      name: this.selectedCookie?.cookieName,
      domain: this.selectedCookie?.cookieDomain,
    };

    const privacyCookiesState = {
      [PRIVACY_COOKIES_SEARCH_TEXT_KEY]: this.selectedCookie ? `${this.selectedCookie.cookieName} ${this.selectedCookie.cookieDomain}` : '',
    };

    this.openedPageId = page.pageId;
    this.pageDetailsDrawerService
      .openPageDetails({ id: page.pageId, url: page[CommonReportsPagesTableColumns.PageUrl] }, this.auditId, this.runId, EPageDetailsTabs.Cookies, false, privacyCookiesState);
  }

  private handleInputData(inputData: ICookiePrivacyPagesTableRow[]) {
    if (inputData && Array.isArray(inputData)) {
      this.tableDataSource.data = inputData.map(i => {
        const loadTime = parseFloat((i.pageLoadTime / 1000).toFixed(1));
        return {
          ...i,
          pageLoadTime: loadTime,
          loadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(loadTime),
          finalPageStatusCode: i.finalPageStatusCode,
          statusCodeClass: this.auditReportService.getStatusCodeClass(i.finalPageStatusCode)
        };
      });
    }
  }

  private formatPaginator(): void {
    this.tablePaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }
}
