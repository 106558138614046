import { Component, Input, ViewChild, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'op-clipboard',
  templateUrl: './op-clipboard.component.html',
  styleUrls: ['./op-clipboard.component.scss'],
})
export class OpClipboardComponent {

  @Input() text: string;
  @Input() label?: string;

  @ViewChild('clipboardInput') input: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  copyText() {
    this.input.nativeElement.select();
    this.document.execCommand('copy');
  }

}