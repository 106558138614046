import { Component, Input, OnInit } from '@angular/core';
import { IUsersUsage } from '@app/components/usage-v2/usage-v2.models';
import { AccountsService } from '@app/components/account/account.service';
import { Observable } from 'rxjs';
import { IUser } from '@app/moonbeamModels';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { filter } from 'rxjs/operators';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  EditAuditPageLimitComponent
} from '@app/components/usage-v2/components/usage-top-widgets/components/usage-users-widget/components/edit-audit-page-limit/edit-audit-page-limit.component';
import { UsageFilterBarService } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.service';
import { EUsageFilterApiFilters } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-users-overlay',
  templateUrl: './usage-users-overlay.component.html',
  styleUrls: ['./usage-users-overlay.component.scss']
})
export class UsageUsersOverlayComponent implements OnInit {
  loggedIn30DaysUsers: IUser[];
  allOtherUsers: IUser[];
  @Input() usersUsage: IUsersUsage;
  readonly manageUsersUrl = AccountSettingsUrlBuilders.manageUsers();

  constructor(
    private accountsService: AccountsService,
    private modalService: OpModalService,
    private filterBarService: UsageFilterBarService,
  ) {
  }

  ngOnInit(): void {
    this.updateView();
  }

  updateView() {
    this.getUsers().subscribe(users => this.processUsers(users));
  }

  getUsers(): Observable<IUser[]> {
    return this.accountsService.getUsers();
  }

  processUsers(users: IUser[]): void {
    const loggedInUserIdsSet = new Set(this.usersUsage.usersLoggedInLast30Days);

    const { loggedIn30DaysUsers, allOtherUsers } = users.reduce(
      (result, user) => {
        if (loggedInUserIdsSet.has(user.id)) {
          result.loggedIn30DaysUsers.push(user);
        } else {
          result.allOtherUsers.push(user);
        }
        return result;
      },
      { loggedIn30DaysUsers: [], allOtherUsers: [] }
    );
    this.loggedIn30DaysUsers = loggedIn30DaysUsers;
    this.allOtherUsers = allOtherUsers;
  }

  applyFilter(user: IUser): void {
    this.filterBarService.addFilterByApiPostBody( EUsageFilterApiFilters.DataSourceCreators, [user.id]);
  }

  editAuditLimit(user: IUser): void {
    this.modalService.openModal(EditAuditPageLimitComponent, { data: user })
      .afterClosed()
      .pipe(
        filter(v => !!v),
      )
      .subscribe(data => {
        if (data) {
          this.accountsService.updateUser({...data.user, maxPagesPerAudit: data.limit}).then(() => this.updateView());
        }
      });
  }
}
