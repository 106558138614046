<!-- WIDGETS -->
<div class="tag-duplicates-and-multiples-charts">
  <div class="widget-container">
    <split-card [topLabel]="widgetPagesScanned.topLabel"
                [topChangeMeaning]="widgetPagesScanned.topChangeMeaning"
                [state]="widgetsState"
                [topTooltip]="'Count of pages scanned in this audit/run.'"
                [metricType]="widgetPagesScanned.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="filteredPageCount"
                [highlight]="highlightMetricType === widgetPagesScanned.metricType"
                [disableHighlightFromClick]="true"
                [filters]="currentFilters">
      <div class="widget-pages-details">
        <div class="page-counts">
          <span class="pages-filtered-count">{{ filteredPageCount | number }}</span>
          <span *ngIf="widgetsState === 'filtered'">&nbsp;of {{ totalPageCount | number }}</span>
        </div>
        <filter-spinner [state]="widgetsState"></filter-spinner>
      </div>
    </split-card>
    <split-card [topLabel]="widgetUniqueTags.topLabel"
                [topChangeContent]="widgetUniqueTags.topChangeContent"
                [topChangeMeaning]="widgetUniqueTags.topChangeMeaning"
                [bottomHandler]="widgetUniqueTags.bottomHandler"
                [haveEnoughData]="widgetUniqueTagsSparklineData.length > 1"
                [state]="widgetsState"
                [topTooltip]="'Total number of unique tag types found in this audit/run.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')"
                [metricType]="widgetUniqueTags.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="widgetUniqueTags.currentValue"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetUniqueTags.metricType"
                [disableHighlightFromClick]="true"
    >
      <sparkline-chart-insight meaningOfUp="none"
                               meaningOfNoChange="none"
                               label="since last run"
                               [dataLoaded]="sparklineDataLoaded"
                               [dataPoints]="widgetUniqueTagsSparklineData">
      </sparkline-chart-insight>
    </split-card>
    <split-card [topLabel]="widgetTotalTagRequests.topLabel"
                [topChangeContent]="widgetTotalTagRequests.topChangeContent"
                [topChangeMeaning]="widgetTotalTagRequests.topChangeMeaning"
                [bottomHandler]="widgetTotalTagRequests.bottomHandler"
                [haveEnoughData]="widgetTotalTagRequestsSparklineData.length > 1"
                [state]="widgetsState"
                [topTooltip]="'Total number of tag instances represented in the data below.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')"
                [metricType]="widgetTotalTagRequests.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="widgetTotalTagRequests.currentValue"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetTotalTagRequests.metricType"
                [disableHighlightFromClick]="true"
    >
      <sparkline-chart-insight meaningOfUp="none"
                               meaningOfNoChange="none"
                               label="since last run"
                               [dataLoaded]="sparklineDataLoaded"
                               [dataPoints]="widgetTotalTagRequestsSparklineData">
      </sparkline-chart-insight>
    </split-card>
    <split-card [topLabel]="widgetTotalDuplicates.topLabel"
                [topChangeContent]="widgetTotalDuplicates.topChangeContent"
                [topChangeMeaning]="widgetTotalDuplicates.topChangeMeaning"
                [bottomHandler]="widgetTotalDuplicates.bottomHandler"
                [state]="widgetsState"
                [haveEnoughData]="widgetTotalDuplicatesSparklineData.length > 1"
                [topTooltip]="'Count of tag request instances that are exact copies of each other and have the same ' +
                   'account that loads on the same page.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')"
                [metricType]="widgetTotalDuplicates.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="widgetTotalDuplicates.currentValue"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetTotalDuplicates.metricType"
                [disableHighlightFromClick]="true"
    >
      <sparkline-chart-insight meaningOfUp="negative"
                               meaningOfNoChange="none"
                               label="since last run"
                               [dataLoaded]="sparklineDataLoaded"
                               [dataPoints]="widgetTotalDuplicatesSparklineData">
      </sparkline-chart-insight>
    </split-card>
    <split-card [topLabel]="widgetTotalMultiples.topLabel"
                [topChangeContent]="widgetTotalMultiples.topChangeContent"
                [topChangeMeaning]="widgetTotalMultiples.topChangeMeaning"
                [bottomHandler]="widgetTotalMultiples.bottomHandler"
                [haveEnoughData]="widgetTotalMultiplesSparklineData.length > 1"
                [state]="widgetsState"
                [topTooltip]="'Count of tag request instances that are not exact copies of each other and have the same ' +
                   'account.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')"
                [metricType]="widgetTotalMultiples.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="widgetTotalMultiples.currentValue"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetTotalMultiples.metricType"
                [disableHighlightFromClick]="true"
    >
      <sparkline-chart-insight meaningOfUp="none"
                               meaningOfNoChange="none"
                               label="since last run"
                               [dataLoaded]="sparklineDataLoaded"
                               [dataPoints]="widgetTotalMultiplesSparklineData">
      </sparkline-chart-insight>
    </split-card>
  </div>
</div>


<!-- TAGS TABLE -->
<div class="tags-table">
  <tag-duplicates-and-multiples-tags-table
    [auditId]="auditId"
    [runId]="runId"
    [items]="tagsTableData$ | async"
    [tableState]="tagsTableState"
    [apiFilters]="apiFilters"
    (globalFilterCreated)="addGlobalFilter($event)"
    (reportFilterCreated)="addReportFilter($event)"
  ></tag-duplicates-and-multiples-tags-table>
  <div class="shadow-bottom"></div>
</div>

<!-- PAGES TABLE -->
<div class="table-wrap" #scrollToTop>
  <div class="section-title-wrap">
    <div class="title-spinner-wrap">
      <filter-spinner [state]="pagesTableState"></filter-spinner>
      <p class="section-title">Pages Scanned</p>
      <op-audit-report-export-menu
        [auditId]="auditId"
        [runId]="runId"
        [tableCompletedState]="pagesTableState"
        [config]="exportReportConfig">
      </op-audit-report-export-menu>
    </div>

    <op-switchable-column-menu [columnConfig]="PagesTableSettingItems"
                               [storageKey]="CommonPagesConfigLocalStorageKey"
                               [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
  </div>
  <table mat-table
         matSort
         resizeableTable
         [dataSource]="dataSource"
         [class.updating]="pagesTableState === stateOptions.Loading"
         class="tag-duplicates-and-multiples-pages-table"
         [matSortActive]="TableColumn.TagDuplicates"
         matSortDirection="desc"
         matSortStart="desc">
    <ng-container [matColumnDef]="TableColumn.PageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>INITIAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ page.pageUrl }}">
            <div class="url-text">{{ page.pageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>FINAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ page.finalPageUrl }}">
            <div class="url-text">{{ page.finalPageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageLoadTime">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="center-text fixed-90"
          [matTooltip]="PageLoadColumnTooltip"
          [matTooltipPosition]="'above'"
      >PAGE LOAD TIME (SEC)
      </th>
      <td mat-cell *matCellDef="let page"
          class="center-text {{ page.pageLoadTimeClass }}">{{ page.pageLoadTime | number }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90">FINAL PAGE<br>STATUS CODE
      </th>
      <td mat-cell *matCellDef="let page"
          class="center-text {{ page.pageStatusCodeClass }}"
          [matTooltip]="PageStatusCodeTooltipMap[page.finalPageStatusCode] || null"
          matTooltipPosition="above"
          matTooltipShowDelay="250"
      >{{ page.finalPageStatusCode }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.TagDuplicates">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-100">DUPLICATES</th>
      <td mat-cell *matCellDef="let page" class="center-text"
          [class.has-duplicates]="page.duplicates > 0">{{ page.duplicates | number }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.TagMultiples">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-100">MULTIPLES</th>
      <td mat-cell *matCellDef="let page" class="center-text"
          [class.has-multiples]="page.multiples > 0">{{ page.multiples | number }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row
        *matRowDef="let page; columns: displayedColumns$ | async;"
        [class.open-in-page-details]="page.pageId === pageIdOpenInPageDetails"
        (click)="openPageDetails(page)"></tr>
  </table>
  <mat-paginator
    class="pages-paginator"
    [length]="paginationState.length"
    [pageSize]="paginationState.pageSize"
    [hidePageSize]="true"
    (page)="onScrollTop()"
  ></mat-paginator>
</div>
