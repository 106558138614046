<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="25"
               [strokeWidth]="4">
  </mat-spinner>
</div>
<div class="top-section" [class.hidden]="loading || failedToLoadMessage">
  <share-report-btn *ngIf="reportType === 'audit'"
                    [auditId]="auditId"
                    [runId]="runId"
                    [pageId]="pageId"
                    [activeTab]="activeTab">
  </share-report-btn>
</div>
<div *ngIf="!loading && failedToLoadMessage">
  <p class="failed-to-load-message">{{failedToLoadMessage}}</p>
</div>
<div *ngIf="!loading && initiatorsData">
  <tag-initiators-svg [initiatorsData]="initiatorsData"
                      [showFullScreenButton]="showFullScreenButton"
                      (openInitiatorsFullScreen)="openInitiatorsFullScreen()">
  </tag-initiators-svg>
  <div class="tag-initators-list-wrap">
    <tag-initiators-list [initiatorsData]="[initiatorsData]" *ngIf="initiatorsData"></tag-initiators-list>
  </div>
</div>
