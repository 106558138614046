import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAuditRunStatus } from '../../audit-report-header/audit-report-header.models';
import { EAuditRunStatus, EStartingUrlFailureType } from '../../audit-report-header/audit-report-header.constants';
import {
  IAuditRunErrorsPreAuditActionFailures,
  IAuditRunErrorsStartingUrlFailure
} from '@app/components/domains/discoveryAudits/discoveryAuditModels';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-report-info-modal',
  templateUrl: './audit-report-info-modal.component.html',
  styleUrls: ['./audit-report-info-modal.component.scss']
})
export class AuditReportInfoModalComponent implements OnInit {

  EStartingUrlFailureType = EStartingUrlFailureType;
  auditRunStatus: IAuditRunStatus;
  EAuditRunStatus = EAuditRunStatus;
  preAuditActionFailures: any[] = [];
  error403Count: number = 0;
  error500Count: number = 0;
  startingUrlFailures: any[] = [];

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public payload: IAuditRunStatus
  ) {
    this.auditRunStatus = payload;
  }

  ngOnInit(): void {
    const errors = this.auditRunStatus.errors;
    if (errors.preAuditActionResults.zeroAndOut) {
      this.handlePreAuditActionFailures();
    } else if (errors.startingUrlResults.failureType === EStartingUrlFailureType.AllFailedOneAndOut) {
      this.handleStartingUrlsFailures();
    }
  }

  editAuditConfiguration(): void {
    this.dialogRef.close({ editAudit: true });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private handlePreAuditActionFailures(): void {
    const preAuditFailures = this.auditRunStatus.errors.preAuditActionResults.preAuditActionFailures;

    if (preAuditFailures?.length) {
      preAuditFailures.forEach((failure: IAuditRunErrorsPreAuditActionFailures) => {
        const actionId = failure.auditActionSnapshotId;
        const actionName = this.auditRunStatus?.snapshot?.preAuditActionSnapshots?.find(action => action.actionSnapshotId === actionId)?.description ?? '';
        this.preAuditActionFailures.push({
          errorSummary: `Pre-audit failed on action #${actionId}${actionName ? ' ' + actionName : ''} with error:`,
          errorMessage: failure.failureMessage
        });
      });
    }
  }

  private handleStartingUrlsFailures(): void {
    const startingFailures = this.auditRunStatus.errors.startingUrlResults.startingUrlFailures;

    if (startingFailures?.length) {
      startingFailures.forEach((failure: IAuditRunErrorsStartingUrlFailure) => {
        if (failure.statusCode === 403) {
          this.error403Count = failure.pageCount;
        } else if (failure.statusCode === 500) {
          this.error500Count = failure.pageCount;
        } else {
          const statusCode = `${failure.statusCode} Error Status Code`;
          this.startingUrlFailures.push(`${failure.pageCount} page${failure.pageCount > 1 ? 's' : ''} with: ${failure.browserError ? `${statusCode}, ${failure.browserError}` : statusCode}`);
        }
      });
    }
  }
}
