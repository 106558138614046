import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ISidebarRoute } from '../modal-sidebar.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'modal-sidebar-link',
  templateUrl: './modal-sidebar-link.component.html',
  styleUrls: ['./modal-sidebar-link.component.scss']
})
export class ModalSidebarLinkComponent implements OnInit {

  @Input() nav: ISidebarRoute;
  @Input() enabledFeatures: string[];

  private destroy$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private modalService: OpModalService
  ) {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkIfLinkIsActive();
      }
    });
  }

  ngOnInit(): void {
    this.checkIfLinkIsActive();
  }

  checkIfLinkIsActive(): void {
    const currentUrl = this.router.url.split('?')[0]; // Remove query parameters
    const navLink = this.nav.link.split('?')[0]; // Remove query parameters from nav link

    // Check if the current URL exactly matches the nav link
    // or if it's a child route (i.e., nav link plus additional segments)
    this.nav.active = currentUrl === navLink ||
                      (currentUrl.startsWith(navLink) &&
                       (navLink.endsWith('/') || currentUrl[navLink.length] === '/'));
  }

  navigate(): void {
    // check if the window size is less than 500px
    const isSmallScreen = window.innerWidth < 500;

    // check if they are leaving a use case report
    const isLeavingUseCaseReport = this.router.url.includes('/use-cases/') && !this.nav.link.includes('/use-cases/');

    if (isSmallScreen && isLeavingUseCaseReport) {
      const modalRef = this.modalService.openConfirmModal({
        data: {
          title: 'Continue?',
          messages: ['The report you\'re about to view is not currently optimized for mobile.'],
          rightFooterButtons: [
            {
              primary: false,
              label: 'Go back',
              action: () => {
                modalRef.close();
              }
            }, {
              primary: true,
              label: 'Continue anyway',
              action: () => {
                this.router.navigateByUrl(this.nav.link);
              }
            }
          ]
        } as any
      });
    } else {
      this.router.navigateByUrl(this.nav.link);
    }
  }
}
