export enum EComparisonType {
  baseline = 'baseline',
  runOverRun = 'runOverRun',
  oneTime = 'oneTime'
}

export enum EDatasourceTagType {
  SelectedVariables = 'selectedVariables',
  AllVariablesExceptExcluded = 'allVariablesExceptExcluded',
  AllVariables = 'allVariables',
  TagPresence = 'tagPresence'
}

export enum EDatasourcesUseType {
  specifiedTags = 'specifiedTags',
  allEnabledTagsPresence = 'allEnabledTagsPresence'
}
