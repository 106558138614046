<div class="use-case-tabs">
  <ng-container *ngIf="!!tabs.length">
    <a *ngFor="let tab of tabs"
       [routerLink]="tab.path"
       [class.active]="tab.path === activeTab"
       (click)="setActiveTab(tab.path)"
       class="tab"
    >
      <mat-icon *ngIf="tab.matIcon" [class.material-icon]="tab.matIcon">
        {{ tab.matIcon }}
      </mat-icon>
      <mat-icon *ngIf="tab.svgIcon" [class.svg-icon]="tab.svgIcon" [svgIcon]="tab.svgIcon"></mat-icon>
      <span class="tab-label">{{ tab.label }}</span>
    </a>
  </ng-container>
</div>