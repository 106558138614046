import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
import {
  EConsentCategoryType,
  EReportType,
  IConsentCategory,
  IConsentCategoryType
} from '@app/components/consent-categories/consent-categories.models';

export const ConsentCategoriesStateNames = {
  base: 'consent_categories',
};

export const ConsentCategoriesPaths = {
  base: 'consent-categories',
};

export const ConsentCategoriesUrlBuilders = {
  base: () => RouteBuilder.buildUrl([ConsentCategoriesPaths.base]),
};

export const ConsentCategoriesUIConstants = {
  maxSelectedItems: 1500,
  pageTitle: 'Consent Categories',
  createNew: 'Create a New Consent Category',
  createNewZero: 'Create a consent category',
  createConsentCategories: 'CREATE, ASSIGN & UPDATE',
  importCategorizedCookies: 'Import Cookies from a CMP export',
  syncWithOneTrust_Part1: 'Import/Update',
  syncWithOneTrust_Part2: 'Consent Categories',
  assign: 'Assign Consent Categories to Data Sources',
  zeroBody: [
    'Create individual user consent categories for each of the',
    'different groupings that you have across your domains and apply',
    'them to your different audits and journeys.',
    'You can have approved Tags, Cookies, and Request Domains & Geolocations',
  ],
  auditType: EReportType.AUDIT,
  webJourneyType: EReportType.WEB_JOURNEY,
  ccTable: {
    ccFilterPlaceholder: 'Filter by name or label',
    nameHeader: 'Category Name',
    typeHeader: 'Type',
    notesHeader: 'Notes',
    labelsHeader: 'Labels',
    tagsHeader: 'Tags',
    cookiesHeader: 'Cookies',
    domainsHeader: 'Domains & Geos',
    auditsHeader: 'Audits',
    webJourneysHeader: 'Web Journeys',
    lastUpdatedHeader: 'Last Updated',
    editCC: 'Edit consent category',
    copyCC: 'Copy consent category',
    deleteCC: 'Delete consent category',
    changeLogCC: 'View change log',
  },
  ccSkipCreate: {
    titleAssign: 'Add Items from this audit',
    titleApproved: 'Add Approved Items from this audit',
    titleUnapproved: 'Add Unapproved Items from this audit',
  },
  ccCreate: {
    setupTitle: 'Create new consent category',
    approveTitle: 'Approved List Setup',
    giveAName: 'Give a name and labels that best describe what scenario will be covered in this consent category.',
    namePlaceholder: 'Name of your new consent category',
    nameLabel: 'NAME OF CONSENT CATEGORY',
    labelsLabel: 'LABEL OF CONSENT CATEGORY',
    selectDataTypeCreate: 'Select a web audit(s) below and we will start this consent category with the data we find in that report. You can then add, delete, and decide if the tags, cookies, and request domains should be approved or unapproved.',
    selectDataTypeUpdate: 'Select a web audit(s) below and we will display the cookies, tags,and request domains we find in that report. You can then decide which cookies, tags, and request domains you wish to add to this consent category from the selected web audit(s).',
    reportFilterLabel: 'Search by audit or label name',
    reportFilterPlaceholder: 'Filter audits',
    labelsFilterPlaceholder: 'Filter by label(s)',
    reachedLimit: 'This list is limited to 100 items. To find an item not listed use the search above.',
  },
  ccAddToExisting: {
    titleApproved: 'Add Approved Items From an Audit',
    titleUnapproved: 'Add Unapproved Items From an Audit',
    instruction: 'Select a web audit(s) below and we will display the cookies, tags,and request domains we find in that report. You can then decide which cookies, tags, and request domains you wish to add to this consent category from the selected web audit(s).'
  },
  ccRowTable: {
    auditName: 'Audit Name',
    journeyName: 'Journey Name',
    domain: 'Domain',
    createdBy: 'Created By',
    lastRun: 'Last Run',
  },
  attachModal: {
    assignToAudit: 'Assign this Consent Category to one or more Audits',
    assignToAuditTitle: 'ASSIGN CONSENT CATEGORY TO AUDIT(S)',
    assignToWebJourney: 'Assign this Consent Category to one or more Web Journeys',
    assignToWebJourneyTitle: 'ASSIGN CONSENT CATEGORY TO WEB JOURNEY(S)',
  },
  pickerList: {
    zeroText: 'There are no items to be displayed with these filters',
  },
  selectors: {
    name: 'cc-name',
    labels: 'cc-labels',
    reportFilter: 'cc-report-filter',
    labelsFilter: 'cc-labels-filter',
    ccFilter: 'cc-table-filter',
    editLabels: 'cc-edit-labels',
    ccCreate: 'cc-create-save',
    ccCreateWithoutReport: 'cc-create-without-report',
    ccCreateNext: 'cc-create-next',
    ccCreatePrev: 'cc-create-prev',
    ccUpdateCCWithExistingData: 'cc-update-with-existing-data',
    ccReturnSelectedData: 'cc-return-selected-data',
    ccCreateWithSelectedData: 'cc-create-with-selected-data',
    ccCreateWithSelectedDataAndReprocess: 'cc-create-with-selected-data-and-reprocess',
  },
};

export const DefaultConsentCategory: IConsentCategory = {
  name: '',
  notes: '',
  type: EConsentCategoryType.APPROVED,
  labelIds: [],
  id: 0,
  itemCounts: {
    tags: 0,
    cookies: 0,
    requestDomainsAndGeos: 0,
  },
  auditCount: 0,
  isDefaultCC: false
};

export const ConsentCategoryTypes: IConsentCategoryType[] = [
  {
    name: 'Approved',
    value: 'approved'
  }, {
    name: 'Unapproved',
    value: 'unapproved'
  }, {
    name: 'Approved & Required',
    value: 'required'
  }
];


export const AssignDatasetsModal: string = 'assignDatasetsModal'
export const ImportCookieGroupNameDefault: string = 'Unnamed OneTrust Cookie Group';

export enum CCType {
  Approved = 'Approved',
  Unapproved = 'Unapproved'
}

export const ImportUploadOverOneMB = 'Invalid file uploaded, 1mb limit exceeded. Please select a smaller CSV and try again.';

export const ImportUploadEmpty = 'Invalid file upload, cannot upload empty file. Please select a different CSV and try again.';

export const ImportUploadNotCSV = "Invalid file uploaded, please upload a CSV and try again.";

export enum ECCSortBy {
  Name = 'name',
  Type = 'type'
}
