import { Pipe, PipeTransform } from '@angular/core';
import { IWJActionTagSummary } from './wj-results-tag-comparison.models';

@Pipe({
  name: 'duplicateTagError'
})
export class DuplicateTagErrorPipe implements PipeTransform {

  transform(tag: IWJActionTagSummary): string {
    const duplicateScoringMessage = tag.duplicateScoring ? ` - This probably shouldn't be happening` : '';
    return `Requests shows up ${tag.duplicates} times${duplicateScoringMessage}`;
  }

}
