import { Pipe, PipeTransform } from '@angular/core';
import {
  IAccessibilityRuleResult,
  IAuditPageInfoAccessibilityChecksResults
} from '@app/components/audit-reports/page-details/page-details.models';
import {
  EPageDetailsAccessibilityFilterName,
  EPageDetailsAccessibilityWCAGIssueType,
  IPagesDetailsAccessibilityFilterBarFilters
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.models';

@Pipe({
  name: 'filterRuleResults',
})
export class FilterRuleResultsPipe implements PipeTransform {

  transform(
    value: IAuditPageInfoAccessibilityChecksResults,
    filters: IPagesDetailsAccessibilityFilterBarFilters,
  ): IAccessibilityRuleResult[] {
    let resultingItems: IAccessibilityRuleResult[] = [];

    if (!filters || !value) {
      return resultingItems;
    }

    const typesFilters = filters.topFilters[EPageDetailsAccessibilityFilterName.Types];
    const versionsFilters = filters.topFilters[EPageDetailsAccessibilityFilterName.Versions];
    const levelsFilters = filters.topFilters[EPageDetailsAccessibilityFilterName.Levels];

    if (typesFilters) {
      if (typesFilters.menuOptions) {
        const addConclusive: boolean = typesFilters.menuOptions
          .find((option) => option.label === EPageDetailsAccessibilityWCAGIssueType.Conclusive).checked;
        const addInConclusive: boolean = typesFilters.menuOptions
          .find((option) => option.label === EPageDetailsAccessibilityWCAGIssueType.Inconclusive).checked;


        if (addConclusive) {
          resultingItems.push(...value.issues);
        }

        if (addInConclusive) {
          resultingItems.push(...value.inconclusive.map(i => ({...i, inspectionNeeded: true})));
        }
      }
    }

    if (versionsFilters) {
      if (versionsFilters.menuOptions) {
        const enabledItems = versionsFilters.menuOptions
          .filter((option) => option.checked)
          .map((option) => option.label);

        resultingItems = resultingItems.filter((item) => enabledItems.some((value) => item.wcagVersion === value));
      }
    }

    if (levelsFilters) {
      if (levelsFilters.menuOptions) {
        const enabledItems = levelsFilters.menuOptions
          .filter((option) => option.checked)
          .map((option) => option.label);

        resultingItems = resultingItems.filter((item) => enabledItems.some((value) => item.wcagConformanceLevel === value));
      }
    }

    return resultingItems;
  }
}
