<div class="table-container">
  <h6>{{itemsCount}} item{{itemsCount > 1 ? 's' : ''}}</h6>

  <div class="table-details">
    <div class="table-header">
      <span class="tag-name-header">Tag Name</span>
      <span class="tag-details-header">
        <span class="flex-stretch">Variable</span>
        <span class="flex-stretch">Pages with differences</span>
      </span>
    </div>

    <div class="no-results" *ngIf="loaded && !tagsVariables?.length">No variable value differences were found</div>

    <div class="table-content" *ngFor="let tagVars of tagsVariables">
      <span class="tag-name-cell">
        <img [src]="getTagIconUrl(tagIdToTagMap.get(tagVars.tagId)?.id)" class="tag-icon" />
        <span>{{ tagIdToTagMap.get(tagVars.tagId)?.name }}</span>
      </span>
      <div class="flex-col flex-stretch">
        <div class="tag-details" *ngFor="let variable of tagVars.variables">
          <a class="variable-col link" (click)="openPages(tagVars.tagId, variable.variableName)">{{ variable.variableName }}</a>
          <mat-icon class="exclude-btn" 
            matTooltip="{{ variable.excluded 
                            ? 'Variable is excluded in account settings' 
                            : (isAllComparisonsSelected ? 'Exclude Variable in account settings' : 'Exclude Variable for Selected Comparison')}}" 
            matTooltipPosition="above" 
            matTooltipShowDelay="250"
            [ngClass]="{'excluded': variable.excluded}" 
            (click)="!variable.excluded && excludeVariable(tagVars.tagId, variable)">visibility_off</mat-icon>
          <a class="flex-stretch link" (click)="openPages(tagVars.tagId, variable.variableName)">{{ variable.pagesCount }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
