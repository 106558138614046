import { AngularNames } from '@app/moonbeamConstants';

export enum ToastMessageType {
  success,
  failure
}

  export class ToastService {

    defaultDismissButtonHtml: string = '<i class="icon-close-bare"></i>';

    static $inject = [
      AngularNames.sce
    ];

    constructor(private $sce) {
    }

    generateToastConfig(messageText: string, timeout: number = 5000, messageType: ToastMessageType = ToastMessageType.success) {
      var cnfg = this.generateDefaultConfig();
      cnfg.content = this.generateContentWithIconCheck(messageText, messageType);
      cnfg.timeout = timeout;
      return cnfg;
    }

    generateTwoLinesToastConfig(title: string, subTitle: string, timeout = 4000, customClass?: string, dismissOnClick?: boolean,) {
      var cnfg = this.generateDefaultConfig(dismissOnClick);
      cnfg.content = this.$sce.trustAsHtml(this.generateContentWithTwoLines(title, subTitle));
      cnfg.timeout = timeout;
      if (customClass) cnfg.className = customClass;

      return cnfg;
    }

    generateSingleMessageWithWrapperToastConfig(message: string,
                                                messageClass: string = '',
                                                wrapperClass: string = '') {
      return {
        content: `<span class="qb-toast-wrapper ${wrapperClass}">
                    <span class="qb-toast ${messageClass}">${message}</span>
                  </span>`,
          className: 'qb-content',
        dismissOnTimeout: true,
        dismissOnClick: true,
        dismissButton: true,
        timeout: 7000,
      };
    }

    generateMessagesToastConfig(message: string) {
      return {
        content: `<span class="qb-toast">${message}</span>`,
        className: 'qb-content',
        dismissOnTimeout: false,
        dismissOnClick: true,
        dismissButton: true,
        dismissButtonHtml: this.defaultDismissButtonHtml
      };
    }

    private generateContentWithIconCheck(messageText: string, messageType: ToastMessageType): string {
      const icon = (messageType === ToastMessageType.success) ? 'icon-check-empty' : 'icon-highlight_off';
      return `<div class="tooltip-content">
                <i class="${icon}"></i>
                <span>${messageText}</span>
              </div>`;
    }

    private generateContentWithTwoLines(title: string, subTitle: string): string {
      return `<div class="two-lines-tooltip-content">
                <div class="tooltip-title">${title}</div>
                <div class="tooltip-subtitle">${subTitle}</div>
              </div>`;
    }

    private generateDefaultConfig(dismissOnClick: boolean = false): any {
      return {
        dismissOnClick: dismissOnClick,
        dismissButton: true,
        dismissButtonHtml: this.defaultDismissButtonHtml,
        compileContent: true
      };
    }
}
