<div class="tag-variables-table-wrapper" (click)="$event.stopPropagation()">
  <table mat-table
         class="tag-variables-table"
         [dataSource]="variables"
         matSort
         multiTemplateDataRows
  >
    <ng-container matColumnDef="variable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Variable
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.variable}}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Description
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.description}}
      </td>
    </ng-container>
    <ng-container matColumnDef="validation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Validation
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.validation}}
      </td>
    </ng-container>
    <ng-container matColumnDef="byteLength">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Byte Length
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.byteLength}}
      </td>
    </ng-container>
    <ng-container matColumnDef="validationDescription">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Validation Description
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.validationDescription}}
      </td>
    </ng-container>
    <ng-container matColumnDef="validationImpact">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Impact
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.validationImpact}}
      </td>
    </ng-container>
    <ng-container matColumnDef="section">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Section
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.section}}
      </td>
    </ng-container>
    <ng-container matColumnDef="dbmap">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        DB Mapping
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        {{row.dbmap}}
      </td>
    </ng-container>
    <tr mat-header-row
        class="tag-database-library-row"
        *matHeaderRowDef="displayedColumns; sticky: true;">
    </tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
</div>
