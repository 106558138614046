import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OpSnackbarComponent } from '@app/components/shared/components/op-snackbar/op-snackbar.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-snackbar',
  templateUrl: './cl-snackbar.component.html',
  styleUrls: ['./cl-snackbar.component.scss']
})
export class ComponentLibrarySnackbarComponent {

  constructor(private snackbar: MatSnackBar) {}

  showSnackbar() {
    this.snackbar.openFromComponent(OpSnackbarComponent, {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        icon: 'info',
        message: 'This is a message to display to the user in the snackbar.',
        closeBtnLabel: 'Close'
      }
    });
  }
}
