<h1 class="heading">
  Great News!
</h1>

<div *ngIf="filterCount <= 1" class="message">
  Out of the {{ alertsCount }} alerts to which you are subscribed, none were triggered in their latest runs of the associated data sources.
</div>

<div *ngIf="filterCount > 1" class="message">
  There were no triggered alerts in the associated data sources.
</div>
