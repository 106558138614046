<mat-menu [classList]="matMenuClass"
          #childMenu="matMenu"
          class="filter-bar-menu"
          (closed)="menuClosed()"
          backdropClass="no-filter">
  <ng-container *ngFor="let item of menuItems">
    <ng-container *ngIf="item.displayWhen === undefined ? true : item.displayWhen">

      <!-- flyout menu item type -->
      <ng-container *ngIf="item.type === EFilterBarMenuTypes.Flyout && item.children && item.children.length">
        <button mat-menu-item [matMenuTriggerFor]="menu.childMenu" [ngClass]="item.itemClasses" class="filter-bar-menu-item">
          {{ item.name }}
        </button>
        <op-filter-bar-menu-item #menu [matMenuClass]="matMenuClass" [menuItems]="item.children"></op-filter-bar-menu-item>
      </ng-container>


      <!-- button menu item type -->
      <ng-container *ngIf="item.type === EFilterBarMenuTypes.Button">
        <button mat-menu-item (click)="item.action(service)" [ngClass]="item.itemClasses" class="filter-bar-menu-item">
          {{ item.name }}
        </button>
      </ng-container>


      <!-- search menu item type -->
      <ng-container *ngIf="item.type === EFilterBarMenuTypes.Search">
        <div mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()" [ngClass]="item.itemClasses" class="clearable-input-container">
          <op-clearable-input #opClearableInput
                              [placeholderText]="item.searchPlaceholder"
                              [emitKeyboardEvent]="true"
                              (onUserInput)="handleUserInput($event, opClearableInput, item)"
                              (onBlur)="item.action($event, opClearableInput)">
          </op-clearable-input>
        </div>
        <button *ngFor="let button of item.children" mat-menu-item [ngClass]="item.itemClasses" (click)="button.action(service)" class="filter-bar-menu-item">
          {{ button.name }}
        </button>
      </ng-container>


      <!-- divider menu item type -->
      <ng-container *ngIf="item.type === EFilterBarMenuTypes.Divider" [ngClass]="item.itemClasses">
        <mat-divider class="filter-menu-divider"></mat-divider>
      </ng-container>


      <!-- clear button item type -->
      <ng-container *ngIf="item.type === EFilterBarMenuTypes.ClearBtn" [ngClass]="item.itemClasses">
        <button mat-menu-item (click)="item.action(service)" class="filter-bar-menu-item filter-menu-clear-btn">
          <mat-icon>close</mat-icon>
          {{ item.name }}
        </button>
      </ng-container>

    </ng-container>
  </ng-container>
</mat-menu>
