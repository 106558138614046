<div class="manage-tags-header">
  <div class="title-btn-wrap">
    <div class="title-wrap">
      <div class="title">
        <span class="title-text">Tags - Primary and Comparisons Setup</span>
        <mat-icon class="title-icon" [class.highlight]="infoIsExpanded" (click)="infoIsExpanded = !infoIsExpanded">info_outline</mat-icon>
      </div>
    </div>
    <op-button-2021 labelText="Add New Tags"
                    matIcon="control_point"
                    (buttonAction)="openTagPickerModal()"
                    buttonType="filled">
    </op-button-2021>
  </div>

  <op-info-panel [expanded]="infoIsExpanded" class="info-panel">
    <p>
      Audit and web journey reports can be filtered by primary tags defined by your organization. Primary tags are the ones you use most frequently or expect to see most often on your pages. Most customers typically set 5-10 primary tags.
      <a href="https://help.observepoint.com/article/180-primary-tag-filtering-in-reports" target="_blank">Learn more.</a>
    </p>
    <p>
      To enable tags for variable level comparison you need to specify Match Criteria which is used to identify tags that should be compared. This will help to eliminate false positives because non-like tags are being compared.
      <a href="https://help.observepoint.com/article/346-comparison-match-criteria" target="_blank">Learn more.</a>
    </p>
  </op-info-panel>
</div>

<div class="accordion-wrap" *ngIf="primaryTags">
  <p class="item-count">{{ numTags }} items</p>
  <op-accordion>
    <mat-accordion displayMode="flat" multi="true">
      <mat-expansion-panel *ngFor="let tag of primaryTags" hideToggle="true">
        <mat-expansion-panel-header>
          <div class="tag-icon-name">
            <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
            <span class="tag-icon-container">
              <img class="tag-icon"
                   [src]="tag.icon"
                   [alt]="tag.name"
              />
            </span>
            {{ tag.name }}
          </div>
          <div class="toggles">
            <mat-slide-toggle class="toggle-filtering"
                            [(ngModel)]="tag.enabled"
                            (change)="tagFilteringChanged(tag)"
                            (click)="preventExpanderToggle($event)"
                            color="primary">
              Primary Tag
            </mat-slide-toggle>
            <mat-slide-toggle (click)="preventExpanderToggle($event)"
                              (change)="updateComparisonState(tag, $event.checked)"
                              *ngIf="userIsAdmin"
                              [checked]="tag.comparisonsSettings?.comparisonEnabled"
                              [disabled]="disableComparisonToggle && !tag.comparisonsSettings?.comparisonEnabled"
                              matTooltip="Account limit reached"
                              matTooltipPosition="right"
                              [matTooltipDisabled]="!disableComparisonToggle || tag.comparisonsSettings?.comparisonEnabled"
                              color="primary">
              Enabled for Comparison
            </mat-slide-toggle>
          </div>
          <div class="icons">
            <span class="icon delete-icon" *ngIf="userIsAdmin">
                <mat-icon class="delete" (click)="confirmDelete($event, tag)"
                          matTooltip="Delete" matTooltipPosition="above">delete</mat-icon>
            </span>
          </div>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <mat-tab-group mat-stretch-tabs="false" class="outer-group">
            <mat-tab label="Tag Info" labelClass="outer-group-tab">
              <tag-info [tagInfo]="tag"
                        [readOnly]="!userIsAdmin"
                        (formData)="onTagInfoSave($event)">
              </tag-info>
            </mat-tab>
            <mat-tab label="Comparison Configuration"
                     labelClass="outer-group-tab"
                     *ngIf="userIsAdmin"
            >
              <manage-tag-comparison-config [tagSettings]="tag.comparisonsSettings" (onUpdate)="onUpdateTagSettings($event)"></manage-tag-comparison-config>
            </mat-tab>
          </mat-tab-group>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </op-accordion>
</div>
