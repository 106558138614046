import { AngularNames, Names } from '@app/moonbeamConstants';
import { IPrimaryTag } from '../account/manageTags/manageTagsService';
import * as angular from 'angular';
import { IApiService } from '../api/apiService';
import { environment } from '@app/environments/environment';
import { CacheResetService } from '../core/services/cache-reset.service';

export interface IAccountTag {
  id: number;
  label: string;
  type: string;
  signatures: Array<ITagSignature>;
  identifiers: Array<string>;
  numSignatures?: number,
  numVariables?: number,
  sections: Array<ITagSection>;
  name: string;
  category: ITagCategory;
  icon: string;
  duplicateScoring: boolean;
  checked?: boolean;
}

export interface IAllTagInfo {
  id: number;
  name: string;
  icon: string;
  category: ITagCategory;
  duplicateScoring: boolean;
  signatures: Array<ITagSignature>;
  variables: Array<ITagVariable>;
}

export interface ITagSignature {
  id: number;
  tagId: number;
  signature: string;
  variableDelimiter?: string;
  accountDelimiter: string;
  batchDelimiter: string;
  customParser: number;
  matches: string;
}

export interface ITagVariable {
  id: number;
  tagId: number;
  validationDescription: string;
  description: string;
  validationImpact: string;
  section: string;
  variable: string;
  byteLength: number;
  dbmap: string;
  validation: string;
}

export interface ITagSection {
  name: string;
  variables: IVariableMap;
}

export interface IVariableMap {
  [variableKey: string]: string;
}

export interface ITagCategory {
  id: number;
  name: string;
}

export class TagsService {
  static $inject = [
    AngularNames.http,
    AngularNames.q,
    Names.Services.api,
    Names.NgServices.cacheReset
  ];

  /**
   * Caching below based on the following:
   * https://medium.com/@dilanthaprasanjith/caching-http-requests-with-rxjs-in-angular-9f7fa44387ce
   */

  public responseCache = new Map();

  constructor(
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private apiService: IApiService,
    private cacheResetService: CacheResetService
  ) {
    this.cacheResetService.reset$.subscribe(_ => {
      this.responseCache.clear();
    });
  }

  root: string = environment.apiUrl;

  getAllTags(): angular.IPromise<Array<IAccountTag>> {
    const requestUrl = this.root + 'tags/all';
    const cached = this.responseCache.get(requestUrl);

    if (cached) return Promise.resolve(cached);

    const response: angular.IPromise<Array<IAccountTag>> = this.apiService.handleResponse(this.$http.get(requestUrl), null, 'tags-spinner');
    response.then(data => this.responseCache.set(requestUrl, data));
    return response;
  }

  getAllTagInfo(tagId: number): angular.IPromise<IAllTagInfo> {
    return this.apiService.handleResponse(this.$http.get(`${this.root}tags/all/${tagId}`));
  }

  getPrimaryTags(): angular.IPromise<Array<IPrimaryTag>> {
    return this.apiService.handleResponse(this.$http.get(this.root + 'tags/primary'));
  }

  addPrimaryTag(primaryTag: IPrimaryTag): angular.IPromise<IPrimaryTag> {
    return this.apiService.handleResponse(this.$http.post(this.root + 'tags/primary', primaryTag));
  }

  updatePrimaryTag(primaryTag: IPrimaryTag): angular.IPromise<IPrimaryTag> {
    return this.apiService.handleResponse(this.$http.put(`${this.root}tags/primary/${primaryTag.id}`, primaryTag));
  }

  updatePrimaryTagFilter(primaryTagId: number, enabled: boolean): angular.IPromise<IPrimaryTag> {
    return this.apiService.handleResponse(this.$http.patch(`${this.root}tags/primary/${primaryTagId}`, { enabled: enabled }));
  }

  deletePrimaryTag(primaryTagId: number): angular.IPromise<any> {
    return this.apiService.handleResponse(this.$http.delete(`${this.root}tags/primary/${primaryTagId}`));
  }
}
