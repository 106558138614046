<div class="sidebar">
  <div *ngIf="isMobile" class="mobile-menu-header">
    <button (click)="mobileFlyoutVisible = false" class="back-btn mmh-btn" [class.back-btn-visible]="mobileFlyoutVisible">
      <mat-icon class="back-btn-icon">navigate_before</mat-icon>
    </button>
    <i class="icon-op-logo"></i>
    <button (click)="closeSideNav()" class="close-btn mmh-btn">
      <mat-icon class="close-btn-icon">close</mat-icon>
    </button>
  </div>
  <div class="sidebar-container" [ngClass]="{ 'closed': sidebarIsClosed && !isMobile, 'open': !sidebarIsClosed, 'mobile': isMobile }">
    <div #sidebarTopSection class="sidebar-top-section">
      <div *ngIf="!isMobile" class="op-logo-wrap">
        <i [ngClass]="{ 'icon-OP': sidebarIsClosed, 'icon-op-logo': !sidebarIsClosed }"
          [routerLink]="'/data-sources'"
          matTooltip="Nav to Data Sources"
          matTooltipPosition="right"
          matTooltipClass="sidebar-logo-tooltip">
        </i>
      </div>

      <ng-container *ngFor="let section of sidebarSectionLinks; let index = index;">
        <mat-divider *ngIf="index > 0 && index < sidebarSectionLinks.length"
                    style="margin: 10px 25px;">
        </mat-divider>

        <ng-container *ngFor="let sectionLink of section">
          <sidebar-link [sectionLink]="sectionLink"
                        [accountFeatures]="accountFeatures$ | async"
                        [sidebarIsClosed]="sidebarIsClosed"
                        [darkTheme]="themeToggled"
                        [isMobile]="isMobile"
                        [drawer]="drawer"
                        (click)="sidebarLinkAction(sectionLink)">
          </sidebar-link>
        </ng-container>
      </ng-container>
    </div>

    <mat-divider *ngIf="isMobile" style="margin: 10px 25px;"></mat-divider>

    <div class="sidenav-bottom-section" [class.scrollable]="topSectionScrollable">

      <sidebar-link [sectionLink]="sidebarBottomLinks['search']"
                    [accountFeatures]="accountFeatures$ | async"
                    [sidebarIsClosed]="sidebarIsClosed"
                    [darkTheme]="themeToggled"
                    [isMobile]="isMobile"
                    [drawer]="drawer"
                    (click)="sidebarLinkAction(sidebarBottomLinks['search'])">
      </sidebar-link>
      <sidebar-link [sectionLink]="sidebarBottomLinks['checklist']"
                    [customLinkClass]="'sidebar-checklist'"
                    [accountFeatures]="accountFeatures$ | async"
                    [sidebarIsClosed]="sidebarIsClosed"
                    [darkTheme]="themeToggled"
                    [isMobile]="isMobile"
                    [drawer]="drawer"
                    (click)="sidebarLinkAction(sidebarBottomLinks['checklist'])">
      </sidebar-link>
      <sidebar-link [customMenuLinkClass]="'sidebar-link-support'"
                    [sectionLink]="sidebarBottomLinks['support']"
                    [accountFeatures]="accountFeatures$ | async"
                    [sidebarIsClosed]="sidebarIsClosed"
                    [darkTheme]="themeToggled"
                    [isMobile]="isMobile"
                    [drawer]="drawer"
                    (click)="sidebarLinkAction(sidebarBottomLinks['support'])">
      </sidebar-link>
      <sidebar-link *ngIf="!isReadOnly"
                    [user]="user"
                    [customMenuLinkClass]="'sidebar-link-settings'"
                    [sectionLink]="sidebarBottomLinks['settings']"
                    [accountFeatures]="accountFeatures$ | async"
                    [sidebarIsClosed]="sidebarIsClosed"
                    [darkTheme]="themeToggled"
                    [isMobile]="isMobile"
                    [drawer]="drawer"
                    #settingsItem
                    (click)="sidebarLinkAction(sidebarBottomLinks['settings'])">
      </sidebar-link>
      <sidebar-link [sectionLink]="sidebarBottomLinks['announcement']"
                    [customLinkClass]="hasAnnouncement ? 'sidebar-announcement' : ''"
                    [accountFeatures]="accountFeatures$ | async"
                    [sidebarIsClosed]="sidebarIsClosed"
                    [darkTheme]="themeToggled"
                    [isMobile]="isMobile"
                    [drawer]="drawer"
                    (click)="sidebarLinkAction(sidebarBottomLinks['announcement'])">
      </sidebar-link>
      <sidebar-link *ngIf="!isAdmin"
                    [customMenuLinkClass]="profileMenuClass"
                    [sectionLink]="sidebarBottomLinks['profileStandard']"
                    [accountFeatures]="accountFeatures$ | async"
                    [sidebarIsClosed]="sidebarIsClosed"
                    [darkTheme]="themeToggled"
                    [isMobile]="isMobile"
                    [drawer]="drawer"
                    (click)="sidebarLinkAction(sidebarBottomLinks['profileStandard'])">
      </sidebar-link>
      <sidebar-link *ngIf="isAdmin"
                    [customMenuLinkClass]="profileMenuClass"
                    [sectionLink]="sidebarBottomLinks['profileAdmin']"
                    [accountFeatures]="accountFeatures$ | async"
                    [sidebarIsClosed]="sidebarIsClosed"
                    [darkTheme]="themeToggled"
                    [isMobile]="isMobile"
                    [drawer]="drawer"
                    (click)="sidebarLinkAction(sidebarBottomLinks['profileAdmin'])">
      </sidebar-link>
      <div *ngIf="!isMobile"
          class="collapse-wrapper"
          [matTooltip]="collapseButtonTooltipMessage"
          [matTooltipDisabled]="!sidebarIsClosed"
          matTooltipPosition="right"
          matTooltipClass="sidebar-expand-tooltip"
          (click)="toggleSidebarState(!sidebarIsClosed)"
          [attr.op-selector]="sidebarIsClosed ? CONSTANTS['expandNav'] : CONSTANTS['collapseNav']"
          [class.collapsed]="sidebarIsClosed">
        <p>
          <mat-icon svgIcon="expand-sidebar"></mat-icon>
          <span *ngIf="!sidebarIsClosed">Collapse Nav Bar</span>
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="isMobile" class="mobile-flyout" [class.visible]="mobileFlyoutVisible">
    <ng-container *ngIf="sectionLink">
      <sidebar-link [sectionLink]="sectionLink"
                    [accountFeatures]="accountFeatures$ | async"
                    [sidebarIsClosed]="sidebarIsClosed"
                    [darkTheme]="themeToggled"
                    [isMobile]="isMobile"
                    [drawer]="drawer"
                    [showFlyout]="true"
                    (click)="sidebarLinkAction(sectionLink)">
      </sidebar-link>
    </ng-container>
  </div>
</div>