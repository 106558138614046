<div *ngIf="showActionButtons" class="action-icons">
  <mat-icon ngxClipboard
            [cbContent]="code"
            [matTooltip]="'Copy to clipboard'"
            class="icon">
    content_copy</mat-icon>
  <mat-icon *ngIf="showFullScreenButton"
            (click)="openFullScreen()"
            [matTooltip]="'Open in fullscreen'"
            class="icon fullscreen-icon">
    fullscreen
  </mat-icon>
</div>

<div class="form-group-div" [formGroup]="formGroup">
  <mat-form-field floatLabel="always" appearance="outline" class="form-field" #matFormField>
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <div class="scroll-area" [style.maxHeight.px]="scrollAreaMaxHeight">
      <div
        #editor
        class="code-editor"
        [class.inner-padding]="withInnerPadding"
        [style.minHeight.px]="editorMinHeight"
      ></div>
    </div>

    <!--
      This is only here to because we need a form control present to
      use <mat-form-field> and it will be invisible to the user
      --
      ...I'm lazy and don't want to implement CVA  ¯\_(ツ)_/¯
    -->
    <textarea matInput [formControl]="formControl" type="text" class="hidden"></textarea>
    <!-- ^^^ -->

  </mat-form-field>
</div>
