<div class="title-container">
  <h1 class="title">Alerts
    <span class="icon-wrapper" [class.highlight]="infoIsExpanded" (click)="infoIsExpanded = !infoIsExpanded">
      <mat-icon class="title-icon">help_outline</mat-icon>
      <mat-icon class="expansion-icon">expand_more</mat-icon>
    </span>
  </h1>
  <div class="controls">
    <op-button-2021 *ngIf="!isReadOnly"
                    labelText="Create Alert"
                    matIcon="control_point"
                    (buttonAction)="alertCreation()"
                    buttonType="filled">
    </op-button-2021>
  </div>
</div>

<op-info-panel [expanded]="infoIsExpanded" class="info-panel">
  <p>
    Create or manage data alerts by setting thresholds for when data is out of compliance. Alerts are based on report
    widget data and can include relevant report filters, various numerical logic operators, and allow for sending to
    specific notification destinations. <a href="https://help.observepoint.com/article/455-alert-library"
                                           target="_blank">Learn more</a>.
  </p>
</op-info-panel>

<op-filter-bar [menuItems]="filterBarMenuItems"
               [service]="alertsLibraryFilterBarService"
               [isSearchByTextEnabled]="true"
               [validFilterTypes]="alertsLibraryFilterBarService.validFilterTypesUpdated$ | async"
               [hideSearchByRegexOption]="true"
               (searchByTextIsEntered)="alertsLibraryFilterBarService.addNameContainsFilter($event.value)"
               searchByTextPlaceholderSuffix="Name">
</op-filter-bar>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>
<alerts-table [loading]="loading"
              [isReadOnly]="isReadOnly"
              [alerts]="alerts"
              [pagination]="pagination"
              [sortOptions]="sortOptions"
              (editAlert)="alertEdit($event)"
              (deleteAlert)="alertDelete($event)"
              (duplicateAlert)="alertDuplication($event)"
              (openDataSourcesAlert)="alertEdit($event, EAlertStep.DataSources)"
              (openSubscribersAlert)="alertEdit($event, EAlertStep.Notification)"
              (updateTableState)="updateTableState($event)"
              (onCreateLabel)="createLabel($event)"
              (createAlert)="alertCreation()"
              (onAddLabel)="assignLabelToAlert($event)"
              (onRemoveLabel)="removeLabel($event)">
</alerts-table>

