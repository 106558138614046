<form class="flex-form" [formGroup]="formGroup" validate-form formName="setupForm" [submitted]="submitted">

  <div *ngIf="showAuditDeletedWarning" class="audit-deleted-warn">
    <div class="warn-heading">
      <mat-icon class="warn-icon">warning</mat-icon>
      Associated audit has been deleted
    </div>
    <p>
      We could not load all the data because the audit that was associated with this comparison was deleted.
      Please select new audits below or feel free to delete this comparison if no longer needed.
    </p>
  </div>

  <div class="title">
    Select Audits & Runs to Compare
  </div>

  <div class="flex-col flex-stretch form-input">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" op-selector="comparison-name"/>
    </mat-form-field>
    <op-error-message formControlPath="setupForm.name"></op-error-message>
  </div>

  <div class="flex-stretch">
    <op-chip-selector class="flex-col flex-stretch labels"
                         formControlName="labels"
                         label="Labels"
                         bindValue="id"
                          appearance="fill"
                         op-selector="comparison-labels"
                         [placeholder]="{ hasSelected: '+ Label', hasNoSelected: '+ Label' }"
                         [allItems]="allLabels">
    </op-chip-selector>
  </div>

  <div formGroupName="baselineAudit" class="flex-row">
    <div class="form-input left-input absolute-error">
      <op-select #baselineAuditId
                  formControlName="id"
                  bindValue="id"
                  bindLabel="name"
                  [data]="audits"
                  placeholder="Baseline Audit*"
                  op-selector="baseline-audit-id"
                  (onSelectionChanged)="resetAllSelectedTags()"
                  [clearable]="false">
      </op-select>
      <op-error-message formControlPath="setupForm.baselineAudit.id"></op-error-message>
    </div>

    <div class="form-input right-input absolute-error">
      <op-select #baselineAuditRun
                  formControlName="run"
                  bindValue="id"
                  bindLabel="name"
                  [data]="auditIdToRunsMap?.get(baselineAuditValue?.id)"
                  placeholder="Select Specific Run*"
                  op-selector="baseline-audit-run"
                  [clearable]="false">
      </op-select>
      <op-error-message formControlPath="setupForm.baselineAudit.run"></op-error-message>
    </div>
  </div>

  <div formGroupName="destinationAudit" class="flex-row">
    <div class="form-input left-input absolute-error">
      <op-select #destinationAuditId
                  formControlName="id"
                  bindValue="id"
                  bindLabel="name"
                  [data]="audits"
                  placeholder="Destination Audit*"
                  op-selector="destination-audit-id"
                  [clearable]="false">
      </op-select>
      <op-error-message formControlPath="setupForm.destinationAudit.id"></op-error-message>
    </div>

    <div class="form-input right-input absolute-error">
      <op-select #destinationAuditRun
                  formControlName="run"
                  bindValue="id"
                  bindLabel="name"
                  [data]="auditIdToRunsMap?.get(destinationAuditValue?.id)"
                  placeholder="Select Specific Run*"
                  op-selector="destination-audit-run"
                  [clearable]="false">
      </op-select>
      <op-error-message formControlPath="setupForm.destinationAudit.run"></op-error-message>
    </div>
  </div>

  <div class="flex-col">
    <op-drag-list [data]="tagsData"
                  [draggable]="true"
                  formArrayName="tags">
      <ng-template #row let-item="item" let-index="index">
        <div class="tag-container" [formGroupName]="index">
          <comparison-variable-selector
            [attr.op-selector]="'dataSource-' + index"
            [allTags]="runIdToTagsMap.get(baselineAuditValue.run)"
            [formGroup]="tags.get(index.toString())"
            (onAddTag)="addTag(index + 1)"
            (onDeleteTag)="deleteTag(index)"
            [tagToAllVariablesMap]="tagToAllVariablesMap"
            [tagToExcludedVariablesMap]="tagToExcludedVariablesMap"
            [isTagPresenceOnly]="isTagPresenceOnly"
            (onTagPresenceOnlyChange)="isTagPresenceOnly = $event">
          </comparison-variable-selector>
        </div>
      </ng-template>
    </op-drag-list>

    <button class="btn btn-primary add-additional-tag-btn"
            op-selector="add-tag-btn"
            [style.visibility]="isTagPresenceOnly ? 'hidden' : 'visible'"
            (click)="addTag(tags.length)">
      Add Additional tag
    </button>

  </div>

  <div class="flex-row">
    <div class="form-input left-input">
      <mat-checkbox formControlName="ignoreUrlDomain"
                    matTooltip="Checking this box will ignore domain names when comparing data. For example: www.observepoint.com/blog/validate-tags and observepoint.com/blog/validate-tags would be evaluated as the same URL"
                    matTooltipClass="ignore-url-domain-tooltip"
                    matTooltipPosition="above"
                    op-selector="ignore-url-domain-checkbox"
                    color="primary">
        Ignore Url Domain
      </mat-checkbox>
    </div>

    <div class="form-input right-input">
      <mat-checkbox formControlName="ignoreQueryParameters"
                    matTooltip="Checking this box will ignore query strings when comparing data. For example: www.observepoint.com/blog/search?q=validate and www.observepoint.com/blog/search?q=case+study would be evaluated as the same URL"
                    matTooltipClass="ignore-query-parameters-tooltip"
                    matTooltipPosition="above"
                    op-selector="ignore-query-params-checkbox"
                    color="primary">
        Ignore Query Parameters
      </mat-checkbox>
    </div>
  </div>

</form>
