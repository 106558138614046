import { Component, Input, ViewChild, ElementRef, EventEmitter, Output, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { IActionSelectorAction } from './action-selector.models';
import { EKeyCodes } from '../shared/services/keyboard-shortcuts/keyboard-shortcuts.constants';
import { MatDialog } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-selector',
  templateUrl: './action-selector.component.html',
  styleUrls: ['./action-selector.component.scss'],
})
export class ActionSelectorComponent implements OnInit, AfterViewInit {

  @Input() actions: IActionSelectorAction[];
  @Input() defaultActions: Object;
  @Input() arrowKeysEnabled: boolean = true;
  @Input() currentStep: number = 1;
  @Input() appActions?: boolean;

  @Output() stepSelected: EventEmitter<{ stepNumber: number, stepEventItem: any }> = new EventEmitter();
  @Output() clickToEnlarge: EventEmitter<number> = new EventEmitter();

  @ViewChild('actionScroller') actionScroller: ElementRef;
  @ViewChild('actionWrap') actionWrap: ElementRef;

  showArrows: boolean = false;
  actionItemWidth: number = 127;

  constructor(private matDialog: MatDialog) { }

  ngOnInit() {
    this.showArrows = this.actions.length > 1;
  }

  ngAfterViewInit() {
    this.scrollSteps();
  }

  @HostListener('window:keydown', ['$event'])
  keyDown(event: KeyboardEvent): void {
    const activeElement = document.activeElement;
    const isInput = activeElement instanceof HTMLInputElement;
    const isTextArea = activeElement instanceof HTMLTextAreaElement;
    const isDialogOpen = this.matDialog.openDialogs.length > 0;

    if (isInput || isTextArea || isDialogOpen) return;

    switch (event.key.toLowerCase()) {
      case EKeyCodes.ArrowUp:
      case EKeyCodes.ArrowLeft:
        event.preventDefault();
        this.leftClick();
        break;
      case EKeyCodes.ArrowDown:
      case EKeyCodes.ArrowRight:
        event.preventDefault();
        this.rightClick();
        break;
    }
  }

  onStepSelected(stepNumber: number, stepEventItem?: any): void {
    this.currentStep = stepNumber;
    this.scrollSteps();
    this.stepSelected.emit({ stepNumber, stepEventItem });
  }

  leftClick(): void {
    this.currentStep -= this.currentStep > 1 ? 1 : 0;
    this.scrollSteps();
    this.onStepSelected(this.currentStep);
  }

  rightClick(): void {
    this.currentStep += this.currentStep < this.actions.length ? 1 : 0;
    this.scrollSteps();
    this.onStepSelected(this.currentStep);
  }

  private scrollSteps(): void {
    let steps = [].slice.call(this.actionWrap.nativeElement.childNodes).filter((node: HTMLElement) => node.nodeName === 'ACTION-SELECTOR-ITEM');
    let step = steps[this.currentStep - 1];
    if (step) {
      step.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  }

  onClickToEnlarge() {
    this.clickToEnlarge.emit(this.currentStep);
  }
}
