import { IPaginationMetaData } from '@app/components/consent-categories/consent-categories.models';
import {
  IAuditReportFilter
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { ILabel } from '@app/components/shared/services/label.service';
import { IAuditReportApiPostBody } from '../audit-reports/audit-report/audit-report.models';
import { IMetadata } from '../alerts-library/alerts-library.models';
import {
  AlertMetricType,
  EAlertMetricChangeType,
  EAlertMetricValueComparator,
  EAlertPageSummaryMetric,
  EAlertPageSummaryWebVitalsMetric,
  EAlertUsageMetricV2
} from './alert-logic/alert-logic.enums';
import { IAlertOperator } from './alert-logic/alert-logic.models';
import {
  EAlertFilterType,
  EAlertMenuContext,
  EAlertModalType,
  EAlertNotificationPolicy,
  EAlertResultStatus,
  EAlertStep,
  EQuickCreateMode
} from './alert.enums';
import { EAlertDataSourcesTableSortColumn } from './alert-data-sources/alert-data-sources.enums';
import { EAlertAssignmentOperation, EAlertSearchSortBy } from './alert.constants';
import { EProductType } from '../shared/components/op-standards-selector/op-standards-selector.constants';
import { IPagination } from '@app/components/shared/components/selectable-table/selectable-table.models';
import { IUsageRequestDTO } from '@app/components/usage-v2/usage-v2.models';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EUsageFilterTypes } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { AlertUtils } from '@app/components/alert/alert.utils';

export type IAlertModalPayload = IAlertPartialModalPayload
  | IAlertCreateForAuditModalPayload
  | IAlertCreateUndefinedModalPayload
  | IAlertCreateWithFiltersModalPayload
  | IAlertEditModalPayload
  | IAlertReadOnlyModalPayload
  | IAlertDuplicateModalPayload;

export interface IAlertPartialModalPayload extends IAlertModalPayloadBase {
  type: EAlertModalType.CreatePrefilled;
  auditId: number;
  runId: number;
  metricType: AlertMetricType;
  name: string;
  emails: string[];
  operator: IAlertOperator;
  currentValue: number;
  filters: IAuditReportApiPostBody | IUsageRequestDTO;
}

export interface IAlertReadOnlyModalPayload extends IAlertModalPayloadBase {
  type: EAlertModalType.ReadOnly;
  alertId: number;
  currentStep?: EAlertStep;
}

export interface IAlertEditModalPayload extends IAlertModalPayloadBase {
  type: EAlertModalType.Edit;
  alertId: number;
  auditId?: number;
  currentStep?: EAlertStep;
}

export interface IAlertDuplicateModalPayload extends IAlertModalPayloadBase {
  type: EAlertModalType.Duplicate;
  alertId: number;
}

export interface IAlertCreateForAuditModalPayload extends IAlertModalPayloadBase {
  type: EAlertModalType.CreateForAudit;
  auditId: number;
  runId: number;
  emails: string[];
}

export interface IAlertCreateUndefinedModalPayload extends IAlertModalPayloadBase {
  type: EAlertModalType.CreateFromLibrary;
}

export interface IAlertCreateWithFiltersModalPayload extends IAlertModalPayloadBase {
  type: EAlertModalType.CreateWithFilters;
  filters: IAuditReportApiPostBody | IUsageRequestDTO;
}

export interface IAlertModalPayloadBase {
  type: EAlertModalType;
  simpleVersion?: boolean; // No filters, data sources, or alert history
  noReprocessOption?: boolean;
  filterType: EAlertFilterType;
  menuContext: EAlertMenuContext;
}

export interface IAlertQuickCreatePayload {
  auditId: number;
  runId: number;
  metricType: AlertMetricType;
  currentValue: number;
  filters: IOpFilterBarFilter<unknown>[] | IOpFilterBarV2Filter<unknown>[];
  filterType: EAlertFilterType;
  defaultTab?: EQuickCreateMode;
}

export interface IAlertFormData {
  id?: number;
  name: string;
  labels: ILabel[];
  logic: {
    trigger: IAlertTriggerFormData;
    filters: IAuditReportApiPostBody;
  };
  notification: {
    emails: string[];
    message: string;
    policy: EAlertNotificationPolicy;
  };
  dataSources: {
    isDefaultAlert: boolean;
    items: { id: number }[];
  }
}

export interface IAlertTriggerFormData {
  metricType: AlertMetricType;
  operator: IAlertOperator;
  targetValue?: number;
}

export interface IAlertCreateModalPayload {
  auditId: number;
  runId: number;
  metric: AlertMetricType;
  currentValue: number;
  filters: IAuditReportFilter[];
}

export class AlertFormData {
  static toAlertRequestItem({ name, logic, dataSources, labels, notification, id }: IAlertFormData, filterType?: EAlertFilterType): IAlertRequestItem {
    // chips are coming back undefined and/or null from the API for some reason
    labels = labels.filter((chip: any) => chip !== undefined && chip !== null);

    const { metricType, operator, targetValue } = logic.trigger;
    const { changeType, comparator } = operator;

    // True if operator = Decreased by value or percentage
    const isInvertedValue = comparator === EAlertMetricValueComparator.LessOrEqual && [EAlertMetricChangeType.ValueChange, EAlertMetricChangeType.RelativeValueChange].includes(changeType);

    const formattedValue = AlertUtils.convertToApiValue(metricType, changeType, targetValue);

    const isUsageMetric = usageMetricTypes.includes(metricType as EAlertUsageMetricV2);

    return {
      id,
      name,
      metricType,
      customAlertMessage: notification.message,
      isDefaultForNewDataSources: dataSources.isDefaultAlert,
      labels: labels.map(l => l.id),
      metricChangeType: operator.changeType,
      notificationPolicy: notification.policy,
      emails: notification.emails,
      targetValueComparator: {
        operator: operator.comparator,
        targetValue: isInvertedValue ? -formattedValue : formattedValue
      },
      filtersV0: isUsageMetric ? (filterType === EAlertFilterType.V2 ? logic.filters : {}) : logic.filters,
      assignments: isUsageMetric ? [] : dataSources.items.map(item => {
        return {
          itemType: 'AUDIT',
          itemId: item.id
        };
      })
    };
  }
}

export const usageMetricTypes: EAlertUsageMetricV2[] = [
  EAlertUsageMetricV2.AccountUsageCurTermAuditPageScannedCount,
  EAlertUsageMetricV2.AccountUsageMonthPaceAuditPageScannedCount,
  EAlertUsageMetricV2.AccountUsageCurTermWebJourneyRunsCount,
  EAlertUsageMetricV2.AccountUsageMonthPaceWebJourneyRunsCount,
  EAlertUsageMetricV2.UsersLoggedInLast30Days,
];

// Request
export interface IAlertsRequestDTO {
  alerts: IAlertRequestItem[];
}

export interface IAlertRequestItem {
  id?: number;
  emails?: string[];
  name: string;
  labels: number[];
  metricType: AlertMetricType;
  customAlertMessage: string;
  notificationPolicy: string;
  isDefaultForNewDataSources: boolean;
  metricChangeType?: EAlertMetricChangeType;
  targetValueComparator: IAlertValueComparatorSaveDTO;
  filtersV0: IAuditReportApiPostBody | IUsageRequestDTO;
  assignments: {
    itemType: string,
    itemId: number
  }[];
}

export interface IAlertValueComparatorSaveDTO {
  operator: EAlertMetricValueComparator;
  targetValue: number;
}

// Response
export interface IAlertResultsDTO {
  alerts: IAlertResultsItem[];
  metadata?: IPaginationMetaData;
}

export interface IAlertResultsItem {
  alert: IAlertRequestItem;
  result: IAlertResultDetails;
}

export interface IAlertsResponseItemConfig {
  emails?: string[];
  name: string;
  metricType: AlertMetricType;
  metricChangeType?: EAlertMetricChangeType;
  targetValueComparator: IAlertValueComparatorSaveDTO;
  filtersV0: IAuditReportApiPostBody;
  subscriberCount: number;
  isUserSubscribed: boolean;
}

export interface IAlertResultDetails {
  status: EAlertResultStatus;
  actualValue: number;
  currentRunValue: number;
  previousRunValue: number;
}

export interface IAlertSummaryByMetricMap {
  (key: AlertMetricType): IAuditSummaryAlert[];
}

export interface IAuditSummaryAlert {
  id: number;
  name: string;
  metricType: AlertMetricType;
  status: EAlertResultStatus;
  isSubscribed: boolean;
  alertConfigExists: boolean;
}

export interface IAuditSummaryAlerts {
  alerts: IAuditSummaryAlert[];
}

export interface ISearchAlertAssignmentsRequest {
  alertId?: number;
  itemId?: number;
  itemName?: string;
  itemLabels?: number[];
  assignedToAlert?: boolean;
  notification?: {
    emails?: string[];
  }
}

export interface ISearchAlertAssignmentsResponse {
  items: ISearchAlertAssignment[];
  metadata: {
    pagination: IPagination;
  }
}

export interface ISearchAlertAssignment {
  isAssigned: boolean;
  itemType: 'AUDIT';
  itemId: number;
  itemName: string;
  itemLastRunAt?: Date;
  itemLabels: number[];
}

export interface ISearchAlertAssignmentsSorting {
  sortBy: EAlertDataSourcesTableSortColumn;
  sortDesc: boolean;
}

export interface ISpecificAlertSummaryDTO {
  id: number;
  config: IAlertsResponseItemConfig;
  result: IAlertResultDetails;
}

export interface IAlertsSummaryDTO {
  alerts: ISpecificAlertSummaryDTO[];
}

export interface IAlertsSearchAssignmentParams {
  size?: number;
  page?: number;
  sortBy?: EAlertSearchSortBy;
  sortDesc?: boolean;
}

export interface IAlertsSearchAssignmentsResponse {
  metadata: IMetadata;
  alerts: IAlertAssignment[];
}

export interface IAlertAssignment {
  id: number;
  name: string;
  metricType: AlertMetricType;
  labels: number[];
}

export interface IAlertsSearchAssignmentBody {
  targetItem?: IAlertsSearchAssignmentBodyTargetItem;
  search?: string;
  labels?: number[];
  isDefaultForNewDataSources?: boolean;
}

export interface IAlertsSearchAssignmentBodyTargetItem {
  itemType: EProductType;
  itemId: number;
  isAssigned: boolean;
}

export interface IAlertAssignmentPatch {
  assignmentUpdated: IAlertAssignmentPatchObj[];
}

export interface IAlertAssignmentPatchObj {
  alertId: number;
  operation: EAlertAssignmentOperation;
}

export interface IAlertSummaryRequestBody {
  name?: string;
  metricType?: AlertMetricType[];
  createdByUserId?: number;
  status?: EAlertResultStatus;
  labels?: number[];
  subscribedOnly?: boolean;
  alertName?: string;
}

export interface IAlertSummaryResponse {
  metadata: IPaginationMetaData;
  alerts: IAlertSummaryAlert[];
}

export interface IAlertSummaryAlert {
  id: number;
  config: IAlertsResponseItemConfig;
  result: IAlertResultDetails;
  history: IAlertSummaryAlertHistory[];
  alertConfigExists: boolean;
}

export interface IAlertSummaryAlertHistory extends IAlertResultDetails {
  runId: number;
  completedAt: Date;
}

export interface IAlertFilterBarService {
  loadFiltersFromLocalStorage(): void;
  appendFiltersToView(): void;
  updateSupportedFiltersList(list: unknown[]): void;
}
