<h3>NOTIFICATION DESTINATIONS</h3>
<div class="body">
  <p>Send completion summary & alert emails to specific individuals or channels/groups on Slack and/or Microsoft Teams
     by including the unique channel/group email address in this address field.</p>
  <div class="notification-channels">
    <p>
      <a [href]="SlackHelpUrl" target="_blank">
        <img class="channel-icon" src="images/slack_icon.svg" alt="Slack Help">Learn how to setup in Slack</a>
    </p>
    <p>
      <a [href]="TeamsHelpUrl" target="_blank">
        <img class="channel-icon" src="images/microsoftTeams_icon.svg" alt="Teams Help">Learn how to setup in Microsoft Teams</a>
    </p>
    <p>
      <a [href]="JiraHelpUrl" target="_blank">
        <img class="channel-icon" src="images/jira_icon.svg" alt="Jira Help">Learn how to send to create a Jira ticket</a>
    </p>
  </div>
</div>
