<form validate-form
      *ngIf="detailsFormGroup"
      formName="actionDetails"
      [formGroup]="detailsFormGroup"
      [submitted]="submitted"
      (click)="onActivate.emit()"
      [ngClass]="{
        'audit-actions': mode === actionCreatorMode.AuditActions,
        'user-session-actions': mode === actionCreatorMode.AuditUserSession,
        'wj-actions': mode === actionCreatorMode.WebJourney,
        'action-set-actions': mode === actionCreatorMode.ActionSet,
        'selected': selected
      }"
>

  <div class="action-header">
    <div class="action-index">{{index + 1}}.</div>
    <div class="action-name">
      <input matInput [placeholder]="actionNamePlaceholder" formControlName="label" [attr.op-selector]="'action-name'"/>
    </div>
    <mat-icon *ngIf="canDelete" class="close-btn" (click)="onDelete.emit()" [attr.op-selector]="'delete-action-btn'">close</mat-icon>
  </div>

  <div class="card-body">
    <div class="flex-stretch">
      <div class="flex-row">
        <op-select
          class="flex-stretch"
          formControlName="type"
          bindValue="key"
          bindLabel="label"
          [data]="actionTypes"
          placeholder="Action Type"
          [clearable]="false"
          appearance="outline"
        >
        </op-select>
        <div class="form-input prevent-nav" *ngIf="controlsConfig.preventNavigation.show(type.value)">
          <mat-checkbox color="primary" formControlName="preventNavigation">Prevent Navigation</mat-checkbox>
        </div>
      </div>

      <div class="form-input" *ngIf="controlsConfig.url.show(type.value)">
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>URL</mat-label>
          <input matInput formControlName="url" (blur)="addProtocolIfMissing()" />
        </mat-form-field>
        <op-error-message formControlPath="actionDetails.url"></op-error-message>
      </div>

      <div class="form-input" *ngIf="controlsConfig.value.show(type.value)">
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>Text Value</mat-label>
          <input matInput formControlName="value" />
        </mat-form-field>
        <op-error-message formControlPath="actionDetails.value"></op-error-message>
      </div>

      <div class="form-input masked-value" *ngIf="controlsConfig.maskedValue.show(type.value)">
        <masked-input [formControl]="maskedValue"></masked-input>
        <op-error-message formControlPath="actionDetails.maskedValue"></op-error-message>
      </div>

      <div class="form-input" *ngIf="controlsConfig.js.show(type.value)">
        <op-code-highlighter
          [formGroup]="detailsFormGroup"
          controlName="js"
          label="JavaScript"
          [fullScreenModalTitle]="actionLabel"
        >
        </op-code-highlighter>
      </div>

      <div class="form-input action-set" *ngIf="controlsConfig.actionSet.show(type.value)">
        <div class="select-edit" *ngIf="actionSets?.length">
          <mat-form-field floatLabel="always" class="select-action-set" appearance="outline">
            <mat-label>Select an Action Set</mat-label>
            <mat-select formControlName="actionSet" (selectionChange)="initActionSetTable()">
              <mat-option *ngFor="let set of actionSets" [value]="set">
                {{ set.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="actionSet.hasError('required')">Please choose an action set</mat-error>
          </mat-form-field>
          <button mat-button class="edit-action-set" [disabled]="!actionSet.value" (click)="editActionSet(actionSet.value)">
            <mat-icon>edit</mat-icon>
            Edit Action Set
          </button>
        </div>
        <div class="action-set-table-wrap mat-elevation-z3" [class.hidden]="!actionSet.value">
          <table mat-table [dataSource]="dataSource" class="actions-table">
            <ng-container matColumnDef="number">
              <th mat-header-cell *matHeaderCellDef>Action #</th>
              <td mat-cell *matCellDef="let action" class="center-text">{{ action.number }}</td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let action">{{ action.type }}</td>
            </ng-container>

            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef>Value</th>
              <td mat-cell *matCellDef="let action" class="value">{{ action.value }}</td>
            </ng-container>

            <ng-container matColumnDef="selector">
              <th mat-header-cell *matHeaderCellDef>Selector</th>
              <td mat-cell *matCellDef="let action">
                <span class="selector" *ngIf="action.selectorType">
                  {{ action.selectorType }}
                </span>
                {{ action.selectorValue }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <div class="no-action-sets" *ngIf="!actionSets?.length">
          No Action Sets have been created
        </div>
      </div>

      <div class="form-input cmp-action" *ngIf="controlsConfig.cmp.show(type.value)">
        <mat-hint class="hint">RECOMMENDATION: Keep this as the very first action.</mat-hint>
        <div class="opt-out-url">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>URL WITH CONSENT MANAGER</mat-label>
            <input matInput placeholder="Enter URL" formControlName="cmpUrl">
            <mat-error *ngIf="cmpUrl.invalid">Please enter a valid URL</mat-error>
          </mat-form-field>
        </div>

        <div class="explanation">
          <p>
            Automatically Accept or Reject All cookies/tracking for the
            <button class="cmp-list-popover-trigger"
                    mdePopoverTriggerOn="click"
                    [mdePopoverTriggerFor]="popover"
            >
              Supported CMP List <mat-icon>expand_more</mat-icon>
            </button>
          </p>
        </div>

        <mat-radio-group aria-label="CMP Options" color="primary" formControlName="cmpType">
          <mat-radio-button [value]="ECmpOption.OPT_IN">Accept All</mat-radio-button>
          <mat-radio-button [value]="ECmpOption.OPT_OUT">Reject All</mat-radio-button>
        </mat-radio-group>

        <div class="explanation">
          <p>
            If ObservePoint cannot find your CMP:
          </p>
        </div>

        <mat-radio-group aria-label="If ObservePoint cannot find your CMP:" color="primary" formControlName="isRequired">
          <mat-radio-button [value]="true">Stop the run</mat-radio-button>
          <mat-radio-button [value]="false">Proceed to the next action</mat-radio-button>
        </mat-radio-group>

        <div class="cmp-small-text">
          <small>
            Additional consent can be configured for other domains, subdomains and paths on a separate action.
            ObservePoint best practice is to set up new audits/journeys for each consent configuration.
            <a href="https://help.observepoint.com/article/553-automated-cmp-states" target="_blank">Learn More.</a>
          </small>
        </div>

        <mde-popover #popover="mdePopover"
                    [mdePopoverOverlapTrigger]="false">
          <mat-card class="cmp-popover-card">
            {{ supportedCmps }}
          </mat-card>
        </mde-popover>
      </div>

      <div class="form-input" *ngIf="controlsConfig.selectors.show(type.value)">
        <active-selector-picker [parentForm]="detailsFormGroup"
                                [formArray]="selectors"
                                [toValidate]="controlsConfig.selectors.show(type.value)"
                                [submitted]="submitted">
        </active-selector-picker>
      </div>

      <div class="form-input" *ngIf="controlsConfig.seconds.show(type.value)">
        <mat-form-field floatLabel="always" appearance="outline">
          <mat-label>Seconds</mat-label>
          <input matInput formControlName="seconds" type="number" min="0" />
        </mat-form-field>
        <op-error-message formControlPath="actionDetails.seconds"></op-error-message>
      </div>
    </div>

    <div class="wait-time-wrp" *ngIf="controlsConfig.waitDuration.show(type.value, mode)">
      <action-wait-time class="flex-row" formControlName="waitDuration"></action-wait-time>
      <op-error-message class="wait-time-error" formControlPath="actionDetails.waitDuration"></op-error-message>
    </div>

    <div *ngIf="type.value !== actionType.CmpOptInOptOut">
      <div *ngIf="controlsConfig.filter.show(type.value, mode)"
          [ngClass]="{
            'boxed': mode === actionCreatorMode.ActionSet
          }">
        <p class="heading" *ngIf="mode === actionCreatorMode.ActionSet">AUDIT SETTINGS</p>
        <div class="regex-filter">
          <div class="form-input">
            <mat-form-field floatLabel="always" appearance="outline">
              <mat-label>Regex filter</mat-label>
              <input matInput formControlName="filter" />
            </mat-form-field>
            <op-error-message formControlPath="actionDetails.filter"></op-error-message>
          </div>
          <mat-checkbox color="primary" (change)="handleMatchAllPagesChange($event)" formControlName="matchAllPages" >Match All Pages</mat-checkbox>
        </div>
      </div>

      <div [ngClass]="{ 'boxed': mode === actionCreatorMode.ActionSet }">
        <p *ngIf="mode === actionCreatorMode.ActionSet" class="heading">JOURNEY SETTINGS</p>

        <div *ngIf="(mode === actionCreatorMode.WebJourney && type.value !== actionType.ActionSet) || mode === actionCreatorMode.ActionSet">
          <span class="add-rules-btn" (click)="addRules()" *ngIf="rules.value.length === 0">
            <mat-icon>add_circle_outline</mat-icon>
            <span class="btn-text">Add Tag & Variable Rule(s) to this Action</span>
          </span>
          <div class="assigned-rules">
            <div class="action-rules" *ngIf="rules.value.length > 0">
              <div class="action-rules-label">Action Rules ({{rules.value.length}})</div>
              <span class="action-rule" *ngFor="let r of rules.value; let i = index" [hidden]="i >= rulesInPreview">{{r?.name}}</span>
              <span class="action-rule-plus" [hidden]="(rules.value.length - rulesInPreview) <= 0">
                + {{rules.value.length - rulesInPreview}}
              </span>
            </div>
            <button (click)="addRules()" class="btn btn-link edit-rules" *ngIf="rules.value.length > 0">
              <div class="icon-mode-edit"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</form>


