<div id="verify-access" class="flex-col flex-stretch">
  <form class="flex-row flex-align-center toolbar" [formGroup]="blockingForm" (submit)="go()" novalidate>
    <mat-form-field floatLabel="always" class="url-search-wrapper">
      <mat-label>URL</mat-label>
      <input type="text"
             matInput
             placeholder="Enter a url"
             name="url"
             formControlName="url"
      >
      <mat-hint>Verify engines can access customer site (ping from an engine server)</mat-hint>
      <mat-error *ngIf="url.hasError('url')">Enter a valid URL</mat-error>
    </mat-form-field>

    <button type="submit"
            [disabled]="url.invalid"
            class="btn btn-default"
            (click)="go()">
      Go
    </button>
  </form>
  <div class="spinner-wrapper">
    <mat-spinner [diameter]="60" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </div>
  <div class="flex-col flex-stretch">
    <div class="scroll-vertically responses">
      <ul *ngFor="let response of responses">
        <li>
          <p class="response-title">{{response.name}}</p>
          <p>{{response.url}}</p>
          <p>Status: {{response.status}} -
            <a href="https://httpstatuses.com/{{response.statusCode}}" target="_blank">https://httpstatuses.com/{{response.statusCode}}</a>
          </p>
          <p>Details:</p>
          <ul *ngFor="let header of response.headers | keyvalue">
            <li>
              <span class="header-label">{{header.key}}:</span><span *ngFor="let v of header.value">{{v}}</span>
            </li>
          </ul>
        </li>
        <hr/>
      </ul>
      <div *ngIf="error">
        <div class="no-content flex-col flex-center-items">
          <h5>{{error.message}}</h5>
        </div>
      </div>
    </div>
  </div>
</div>
