import { AngularNames, Names } from '@app/moonbeamConstants';
import { IAuditModel } from '../../modals/modalData';
import { IAuditRunSummary } from './discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { IProgressStatusWrapper } from '../../reporting/statusBanner/statusBannerService';
import * as angular from 'angular';
import {
  DomainAuditActionArray,
  IAudit,
  IAuditRunErrors,
  IAuditRunSnapshot,
  IDomainAuditAction,
  IDomainAuditUserSession,
  IEasyBlockTags,
  IV3AuditWithLatestRun,
} from './discoveryAuditModels';
import { IAuditFilters } from '@app/components/audit/audit.models';
import { IAuditSummariesJson } from './reporting/summaryReports/auditSummary/auditSummaryStatsSerializer';
import { IAuditRun } from './reporting/summaryReports/auditSummary/auditSummaryData';
import { IDomain } from '../domainsService';
import { IFolder } from '../../folder/foldersApiService';
import { IExportData } from '../../utilities/utilitiesService';
import { ILabel } from '@app/components/shared/services/label.service';
import { IHttpCallOnceService } from '../../api/httpCallOnceService';
import { IApiService, ISerializer } from '../../api/apiService';
import { EDateFormats, IDateService } from '../../date/date.service';
import * as ngRedux from 'ng-redux';
import { AuditActions } from '../../../store/actions/auditActions';
import { ISortingService } from './reporting/services/sortingService/sortingService';
import { INewAudit } from '@app/components/audit/audit.models';
import { environment } from '@app/environments/environment';
import { IActionSetAction } from '@app/components/action-set-library/action-set-library.models';
import { RuleResultType } from '../../reporting/rules/rule.models';
import { ERuleFilterType, ERuleMatchType } from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';
import { IRule } from '@app/components/rules/rules.models';
import { IAPIResponseMetadata } from '@app/components/audit-reports/audit-report/audit-report.models';
import { ItemType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import {
  AuditInfoListItems,
  EAuditInfoItemNames
} from '@app/components/audit-reports/audit-report-info/audit-report-info.constants';
import { IGeoLocation } from '@app/components/creator/webJourney/locationsService';
import { CachePromise } from '@app/components/core/decorators/cache-promise.decorator';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';

export interface IAuditRunExecutionInfo {
  browserVersion: string;
  userAgent: string;
  width: number;
  height: number;
  loginActions: boolean;
  runDuration: number;
  urlsScanned: number;
  scanSpeed: number;
  gpcEnabled: boolean;
  locationId: number;
  customProxy: string;
  browserName: string;
}

export interface IExportModel {
  vendor: number;
  accounts: Array<string>;
  accountId: number;
  auditId: number;
  runId: number;
}

export interface IExportMultiModel {
  vendors: number[];
  accounts: string[];
  accountId: number;
  auditId: number;
  runId: number;
}

export interface IPageListPage {
  id: string;
  url: string;
}

export interface IAuditPageRules {
  summary: IAuditPageRulesSummary;
  results: IAuditPageRulesResult[];
}

export interface IAuditPageRulesSummary {
  totalNoOfRulesApplied: number;
  totalNoOfRulesFailed: number;
  totalNoOfRulesPassed: number;
  totalNoOfRulesNotApplied: number;
}

export interface IAuditPageRulesResult {
  resultType: RuleResultType;
  name: string;
  pageFilterResults: IAuditPageRulesResultFilter[];
  tagResults: IAuditPageRulesResultTagResult[];
  noOfChecks: {
    expected: number;
    actual: number;
  };
  ruleId: number;
}

export interface IAuditPageRulesResultFilter {
  actual: string;
  expected: string;
  filterType: ERuleFilterType.URL | ERuleFilterType.StatusCode;
  matchType: ERuleMatchType;
}

export interface IAuditPageRulesResultTagResult {
  conditionId: number;
  resultType: string;
  tag: IAuditPageRulesResultTag;
}

export interface IAuditPageRulesResultTag {
  id: number;
  name: string;
  tagAccount: string;
  tagAccountMatchType: string;
  variables: IAuditPageRulesResultTagVariable[];
}

export interface IAuditPageRulesResultTagVariable {
  resultType: string;
  name: string;
  matchType: ERuleMatchType;
  expectedValues: string[];
  expectedParamName: string;
  variableTag: string;
  selectorType: string;
  actualValue: string;
  snapshotId: number;
}

export interface IRunTagsResponse {
  metadata: IAPIResponseMetadata;
  tags: IRunTagsResponseTag[]
}

export interface IRunTagsResponseTag {
  accounts: string[];
  tagId: number;
  tagName: string;
}

export interface IDiscoveryAuditService {
    getAudit(id: number): angular.IPromise<IAuditModel>
    getAudits(): angular.IPromise<IAuditModel[]>
    getLatestAudit(): angular.IPromise<IAuditModel>
    getAuditRuns(id: number): angular.IPromise<Array<IAuditRunSummary>>
    getAuditStatusProgress(auditId: number, runId: number): angular.IPromise<IProgressStatusWrapper>
    createAudit(audit: INewAudit): angular.IPromise<IAuditModel>
    updateAudit(audit: IAudit): angular.IPromise<IAuditModel>
    runAudit(id: number): angular.IPromise<IAuditModel>
    reprocessRules(auditId: number, runId: number): angular.IPromise<boolean>
    removeAudit(id: number): angular.IPromise<boolean>
    getAuditFrequencies(): angular.IPromise<string[]>
    getAuditSummary(id: number): angular.IPromise<IAuditSummariesJson>
    getAuditRunSummary(runId: number): angular.IPromise<IAuditSummariesJson>
    getAuditRunInfo(id: number, runId: number): angular.IPromise<IAuditRunExecutionInfo>
    getAuditScoring(id: number): angular.IPromise<Array<IAuditRun>>
    getDefaultFilters(urls: Array<string>): angular.IPromise<IAuditFilters>
    getDomain(id: number): angular.IPromise<IDomain>
    getFolder(folderId: number): angular.IPromise<IFolder>
    getActionTypes(): angular.IPromise<string[]>
    getActions(auditId: number): angular.IPromise<IDomainAuditAction[]>
    getActionsV3(auditId: number): angular.IPromise<IActionSetAction[]>
    saveActions(auditId: number, actions: IDomainAuditAction[]): angular.IPromise<any>
    saveActionsV3(auditId: number, actions: IActionSetAction[]): angular.IPromise<IActionSetAction[]>
    getUserSession(auditId: number): angular.IPromise<any>
    getUserSessionV3(auditId: number): angular.IPromise<IActionSetAction[]>
    saveUserSession(auditId: number, userSession: IDomainAuditUserSession): angular.IPromise<any>
    saveUserSessionV3(auditId: number, userSession: IActionSetAction[]): angular.IPromise<IActionSetAction[]>
    deleteUserSession(auditId: number): angular.IPromise<any>
    updateRulesForAudit(auditId: number, ruleIds: number[]): angular.IPromise<Array<number>>
    deleteRulesForAudit(auditId: number): angular.IPromise<boolean>
    updateFiltersForAudit(auditId: number, filters: IAuditFilters): angular.IPromise<IAuditFilters>
    deleteFiltersForAudit(auditId: number): angular.IPromise<boolean>
    getTagSummaries(auditId: number): angular.IPromise<any>
    getRunTags(auditId: number, runId: number): angular.IPromise<IRunTagsResponse>
    getAccountSummaries(auditId: number, tagId: number): angular.IPromise<any>
    getVendorExportDownloadLink(exportModel: IExportModel): angular.IPromise<IExportData>
    getAuditLabels(auditId: number): angular.IPromise<Array<ILabel>>
    updateAuditLabels(auditId: number, labels: Array<ILabel>): angular.IPromise<Array<ILabel>>
    deleteAuditLabel(auditId: number, labelId: number): angular.IPromise<any>
    stopActiveAuditRun(auditId: number): angular.IPromise<boolean>
    getUniqueTags(auditId: number, runId: number): angular.IPromise<Array<IUniqueTagResponseItem>>;
    getUniqueVariables(auditId: number, runId: number): angular.IPromise<Array<ITagAccountVariables>>;
  }

  export enum EUniqueResponseTypes {
    TAGS = 'tags',
    GEO_LOCATIONS = 'geo locations',
    REQUEST_DOMAINS = 'request domains',
    COOKIES = 'cookies',
  }

  export interface IUniqueTagResponseItem {
    tagId: number;
    tagName: string;
    accounts: Array<string>;
    selected?: boolean;
  }

  export interface IUniqueCookieResponseItem {
    name: string;
    domain: string;
    selected?: boolean;
  }

  export interface IUniqueGeoLocationResponseItem {
    id: number;
    countryCode: number;
    countryName: string;
    selected?: boolean;
  }

  export interface IUniqueRequestDomainsResponseItem {
    domain: string;
    selected?: boolean;
    isValid?: boolean;
  }

  export class ITagIdAccountVariable {
    tagId: number;
    account: string;
    key: string;
  }

  export class ITagIdAccountVariableValue extends ITagIdAccountVariable {
    value: string;
  }

  export interface ITagAccountVariables {
    tagId: number;
    tagName: string;
    account: string;
    variables: Array<{
      key: string;
      uniqueValuesCount: number
    }>
  }

  export interface IDiscoveryAuditDashboardTab {
    url: string;
    name: string;
    state: string;
    isSelected: boolean;
  }

  interface IUserSessionRequest {
    url: string;
    actions: IAuditAction[];
  }

  interface IAuditActionRequest {
    filter: string;
    action: IAuditAction;
  }

  interface IAuditAction {
    sequence: number;
    preventNavigation: boolean;
    url?: string;
    identifier?: string;
    value?: string;
    js?: string;
  }

  interface IAuditRunForReprocess {
    id: number;
    auditId: number;
    needReprocess: boolean;
  }

  const SCORING_MONTHS_AGO = 5;

  export class DiscoveryAuditService {
    dashboardTabs: IDiscoveryAuditDashboardTab[];

    static $inject = [
      Names.Services.httpCallOnce,
      Names.Services.api,
      Names.Services.domains,
      Names.Services.foldersApi,
      Names.Services.auditRunSerializer,
      Names.Services.date,
      Names.Services.sorting,
      AngularNames.q,
      AngularNames.ngRedux,
      Names.NgServices.authentication,
      Names.NgServices.router,
      Names.NgServices.snackbarService,
    ];

    constructor(
      private $http: IHttpCallOnceService,
      private apiService: IApiService,
      private domainsService,
      private foldersService,
      private scoringSerializer: ISerializer<Array<IAuditRun>>,
      private dateService: IDateService,
      private sortingService: ISortingService,
      private $q: angular.IQService,
      private $ngRedux: ngRedux.INgRedux,
      private authService: AuthenticationService,
      private router: Router,
      private snackbar: SnackbarService,
    ) {
      this.dashboardTabs = [];
    }

    root: string = environment.apiUrl;

    static path =  environment.apiUrl + 'web-audits';
    static pathUpload = environment.apiUploadAppUrl + 'web-audits';
    static pathV3WebAudits = environment.apiV3Url + 'web-audits';
    static pathV3 = environment.apiV3Url;
    static numAuditRuns = 3;
    static dataWarehousing = 'data-lake';

    private structureAuditDates(auditPromise): angular.IPromise<IAuditModel> {
      var defer = this.$q.defer<IAuditModel>();
      auditPromise.then((audit: any) => {
        audit.created = (audit.created) ? new Date(audit.created) : null;
        audit.lastRun = (audit.lastRun) ? new Date(audit.lastRun) : null;
        audit.lastUpdated = (audit.lastUpdated) ? new Date(audit.lastUpdated) : null;
        audit.nextRun = (audit.nextRun) ? new Date(audit.nextRun) : null;
        defer.resolve(audit);
      }, (response) => {
        defer.reject(response);
      });
      return defer.promise;
    }

    openRunDoesNotExistSnackbar() {
      this.snackbar.openInfoSnackbar('The Audit run you requested does not exist or is outside of your data retention window. We\'ve redirected you to the most recent run.', { duration: 150000, horizontalPosition: 'center', verticalPosition: 'top' });
    }

    openAuditDoesNotExistSnackbar() {
      this.snackbar.openInfoSnackbar('The Audit you requested does not exist or is not accessible', { duration: 150000, horizontalPosition: 'center', verticalPosition: 'top' });
    }

    getAudit(id: number): angular.IPromise<IAuditModel> {
      return this.structureAuditDates(this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.path}/${id}`))).catch((e) => {
        console.error('Error getting Audit', e);

        if (e?.code === 404) {
          this.snackbar.openInfoSnackbar('The Audit you requested does not exist or is not accessible', { duration: 150000, horizontalPosition: 'center', verticalPosition: 'top' });
          this.router.navigateByUrl(DataSourcesUrlBuilders.sources());
        }

        throw e;
      });
    }

    async getAudits(): Promise<IAuditModel[]> {
      let rules;

      try {
        rules = await this.getAuditHelper(DiscoveryAuditService.path);
      } catch {
        rules = await this.getAuditHelper(DiscoveryAuditService.pathUpload);
      }

      return rules;
    }

    /**
     * Return an array of strings with the enabled info items
     * @param runSnapshot
     * @param locationsById
     */
    getEnabledInfoItems(runSnapshot: IAuditRunExecutionInfo, locationsById: Map<number, IGeoLocation>): string[] {
      const listItems = AuditInfoListItems.reduce((acc: string[], infoProperty: string) => {
        // Handle custom proxy
        if (infoProperty === 'customProxy' && runSnapshot.customProxy) {
          acc.push(runSnapshot.customProxy);
        } else if (!runSnapshot.customProxy && infoProperty === 'locationId' && runSnapshot[infoProperty] !== 1) {
          // Handle non-default location
          const locationLabel = locationsById?.get(runSnapshot[infoProperty])?.label || 'Unknown';
          acc.push(locationLabel);
        } else if (infoProperty !== 'customProxy' && infoProperty !== 'locationId') {
          // Handle all other standard info items
          runSnapshot[infoProperty] ? acc.push(EAuditInfoItemNames[infoProperty]) : null;
        }
        return acc;
      }, []);

      return listItems;
    }

    private  async getAuditHelper(path: string): Promise<IAuditModel[]> {
      let accountHasDataWarehousing;
      await this.authService.isFeatureAllowed(DiscoveryAuditService.dataWarehousing).subscribe((isAllowed: boolean) => {
        accountHasDataWarehousing = isAllowed;
      });
      const numAuditRuns = accountHasDataWarehousing ? '' : `&runsLimit=${DiscoveryAuditService.numAuditRuns}`;

      return this.apiService.handleResponse(this.$http.get(`${path}?withRuns=true${numAuditRuns}`), null, 'audits-spinner');
    }

    getAuditsV3(): angular.IPromise<any[]> {
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}`), null, 'audits-spinner');
    }

    getLatestRunsForAllAudits(): angular.IPromise<IV3AuditWithLatestRun[]> {
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/latest-runs`), null, 'audits-spinner');
    }

    getLatestAudit(): angular.IPromise<IAuditModel> {
      return this.structureAuditDates(this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.path}/latest`)));
    }

    getAuditRuns(id: number): angular.IPromise<Array<IAuditRunSummary>> {
      if (!id) {
        return this.$q.reject('id is not defined');
      }
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.path}/${id}/runs`));
    }

    getAuditStatusProgress(auditId: number, runId: number): angular.IPromise<IProgressStatusWrapper> {
      if (!auditId) {
        return this.$q.reject('auditId is not defined');
      }
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.path}/${auditId}/runs/${runId}/progress`));
    }

    createAudit(audit: INewAudit): angular.IPromise<IAuditModel> {
      return this.structureAuditDates(this.apiService.handleResponse(this.$http.post(DiscoveryAuditService.path, audit)));
    }

    updateAudit(audit: IAudit): angular.IPromise<IAuditModel> {
      return this.structureAuditDates(this.apiService.handleResponse(this.$http.put(`${DiscoveryAuditService.path}/${audit.id}`, audit)));
    }

    runAudit(id: number): angular.IPromise<IAuditModel> {
      return this.structureAuditDates(this.apiService.handleResponse(this.$http.post(`${DiscoveryAuditService.path}/${id}/runs`, {})));
    }

    reprocessRules(auditId: number, runId: number): angular.IPromise<boolean> {
      let auditRunForReprocess: IAuditRunForReprocess = {
        id: runId,
        auditId: auditId,
        needReprocess: true
      };
      return this.apiService.handleResponse(this.$http.post(`${DiscoveryAuditService.path}/reprocess-rules`, auditRunForReprocess))
        .then((auditRun: IAuditRunForReprocess) => {
          return auditRun.needReprocess;
        });
    }

    removeAudit(id: number): angular.IPromise<boolean> {
      return this.apiService.handleResponse(this.$http.delete(`${DiscoveryAuditService.path}/${id}`)).then(() => {
        return true;
      });
    }

    getAuditFrequencies(): angular.IPromise<string[]> {
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.path}/frequencies`));
    }

    getAuditSummary(id: number): angular.IPromise<IAuditSummariesJson> {
      var auditId = isNaN(id) ? 0 : id;
      return this.apiService.handleResponse(this.$http.get(this.root + 'report/summary/audit?audit_id=' + auditId), null, 'audit-tag-spinner');
    }

    getAuditRunSummary(runId: number): angular.IPromise<IAuditSummariesJson> {
      return this.apiService.handleResponse(this.$http.get(this.root + `report/summary/audit?run_id=${runId}`), null, 'audit-tag-spinner');
    }

    @CachePromise()
    getAuditRunInfo(id: number, runId: number): angular.IPromise<IAuditRunExecutionInfo> {
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/${id}/runs/${runId}/info`));
    }

    getAuditScoring(id: number): angular.IPromise<Array<IAuditRun>> {
      var auditId = isNaN(id) ? 0 : id;
      var date = new Date();
      date.setMonth(date.getMonth() - SCORING_MONTHS_AGO);
      var dateQuery = this.dateService.formatDate(date, EDateFormats.dateTwentyOne);
      return this.apiService.handleResponse(this.$http.get(this.root + 'report/summary/scoring?audit_id=' + auditId + '&start_date=' + dateQuery), this.scoringSerializer);
    }

    getDefaultFilters(urls: Array<string>): angular.IPromise<IAuditFilters> {
      return this.apiService.handleResponse(this.$http.post(`${DiscoveryAuditService.path}/default-filters`, urls));
    }

    getDomain(id: number): angular.IPromise<IDomain> {
      return this.domainsService.getDomain(id);
    }

    getFolder(folderId: number): angular.IPromise<IFolder> {
      return this.foldersService.getFolder(folderId);
    }

    getActionTypes(): angular.IPromise<string[]> {
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.path}/action-types`));
    }

    getActions(auditId: number): angular.IPromise<IDomainAuditAction[]> {
      return this.apiService.handleResponse<IDomainAuditAction[]>(this.$http.get(`${DiscoveryAuditService.path}/${auditId}/actions`));
    }

    getActionsV3(auditId: number): angular.IPromise<IActionSetAction[]> {
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/actions`));
    }

    saveActions(auditId: number, actions: IDomainAuditAction[]): angular.IPromise<any> {
      const data = new DomainAuditActionArray(actions);
      return this.apiService.handleResponse(this.$http.put(`${DiscoveryAuditService.path}/${auditId}/actions`, data));
    }

    saveActionsV3(auditId: number, actions: IActionSetAction[]): angular.IPromise<IActionSetAction[]> {
      return this.apiService.handleResponse(this.$http.put(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/actions`, actions));
    }

    getUserSession(auditId: number): angular.IPromise<IDomainAuditUserSession> {
      return this.apiService.handleResponse<IDomainAuditUserSession>(this.$http.get(`${DiscoveryAuditService.path}/${auditId}/login-actions`));
    }

    getUserSessionV3(auditId: number): angular.IPromise<IActionSetAction[]> {
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/login-actions`));
    }

    saveUserSession(auditId: number, userSession: IDomainAuditUserSession): angular.IPromise<any> {
      return this.apiService.handleResponse(this.$http.put(`${DiscoveryAuditService.path}/${auditId}/login-actions`, userSession));
    }

    saveUserSessionV3(auditId: number, userSession: IActionSetAction[]): angular.IPromise<any> {
      return this.apiService.handleResponse(this.$http.put(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/login-actions`, userSession));
    }

    deleteUserSession(auditId: number): angular.IPromise<any> {
      return this.apiService.handleResponse(this.$http.delete(`${DiscoveryAuditService.path}/${auditId}/login-actions`));
    }

    updateRulesForAudit(auditId: number, ruleIds: number[]): angular.IPromise<Array<IRule>> {
      return this.apiService.handleResponse(this.$http.put(`${DiscoveryAuditService.path}/${auditId}/rules`, ruleIds));
    }

    deleteRulesForAudit(auditId: number): angular.IPromise<boolean> {
      return this.apiService.handleResponse(this.$http.delete(`${DiscoveryAuditService.path}/${auditId}/rules`));
    }

    updateFiltersForAudit(auditId: number, filters: IAuditFilters): angular.IPromise<IAuditFilters> {
      return this.apiService.handleResponse(this.$http.put(`${DiscoveryAuditService.path}/${auditId}/filters`, filters));
    }

    deleteFiltersForAudit(auditId: number): angular.IPromise<boolean> {
      return this.apiService.handleResponse(this.$http.delete(`${DiscoveryAuditService.path}/${auditId}/filters`));
    }

    getTagSummaries(auditId: number, runId?: number): angular.IPromise<any> {
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.path}/${auditId}/tag-summaries?${this.runIdParam(runId)}`));
    }

    getRunTags(auditId: number, runId: number, page: number): angular.IPromise<IRunTagsResponse> {
      const requestBody = [{
        itemType: ItemType.Audit,
        itemId: auditId,
        runId: runId
      }];
      return this.apiService.handleResponse(
        this.$http.post(`${DiscoveryAuditService.pathV3}runs/tags?page=${page}&size=500&sortBy=tag_name`, requestBody)
      );
    }

    getAccountSummaries(auditId: number, tagId: number, runId?: number): angular.IPromise<any> {
      return this.apiService.handleResponse(this.$http.get(`${DiscoveryAuditService.path}/${auditId}/account-summaries/${tagId}?${this.runIdParam(runId)}`));
    }

    getVendorExportDownloadLink(exportModel: IExportModel): angular.IPromise<IExportData> {
      return this.apiService.handleResponse(this.$http.post(
        `${this.root}report/advanced/full-vendor?tag_id=${exportModel.vendor}&account=${exportModel.accountId}&audit_id=${exportModel.auditId}&${this.runIdParam(exportModel.runId)}`,
        { identifiers: exportModel.accounts }
      ));
    }

    getAuditLabels(auditId: number): angular.IPromise<Array<ILabel>> {
      return this.apiService.handleResponse<Array<ILabel>>(this.$http.get(`${DiscoveryAuditService.path}/${auditId}/labels`)).then(labels => {
        this.$ngRedux.dispatch(AuditActions.setAuditLabels(auditId, labels));
        return labels;
      });
    }

    updateAuditLabels(auditId: number, labels: Array<ILabel>): angular.IPromise<Array<ILabel>> {
      return this.apiService.handleResponse<Array<ILabel>>(this.$http.put(`${DiscoveryAuditService.path}/${auditId}/labels`, labels)).then(newLabels => {
        this.$ngRedux.dispatch(AuditActions.setAuditLabels(auditId, newLabels));
        return newLabels;
      });
    }

    deleteAuditLabel(auditId: number, labelId: number): angular.IPromise<any> {
      return this.apiService.handleResponse(this.$http.delete(`${DiscoveryAuditService.path}/${auditId}/labels/${labelId}`)).then(() => {
        this.$ngRedux.dispatch(AuditActions.deleteAuditLabel(auditId, labelId));
      });
    }

    stopActiveAuditRun(auditId: number): angular.IPromise<boolean> {
      return this.apiService
        .handleResponse(this.$http.delete(`${DiscoveryAuditService.path}/${auditId}/runs/current`))
        .then(() => true, () => false);
    }

    getUniqueCookies(auditId: number, runId: number): angular.IPromise<Array<IUniqueCookieResponseItem>> {
      return this.apiService
        .handleResponse<Array<IUniqueCookieResponseItem>>(this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/runs/${runId}/cookies`))
        .then(uniqueCookies => uniqueCookies);
    }

    getUniqueGeoLocations(auditId: number, runId: number): angular.IPromise<Array<IUniqueGeoLocationResponseItem>> {
      return this.apiService
        .handleResponse<Array<IUniqueGeoLocationResponseItem>>(this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/runs/${runId}/geo-locations`))
        .then(uniqueGeoLocations => uniqueGeoLocations);
    }

    getUniqueRequestDomains(auditId: number, runId: number): angular.IPromise<Array<IUniqueRequestDomainsResponseItem>> {
      return this.apiService
        .handleResponse<Array<IUniqueRequestDomainsResponseItem>>(this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/runs/${runId}/request-domains`))
        .then(uniqueRequestDomains => uniqueRequestDomains.filter(domain => domain?.isValid));
    }

    getUniqueTags(auditId: number, runId: number): angular.IPromise<Array<IUniqueTagResponseItem>> {
      return this.apiService
        .handleResponse<Array<IUniqueTagResponseItem>>(this.$http.get(`${DiscoveryAuditService.path}/${auditId}/runs/${runId}/results/unique-tags`))
        .then(uniqueTags => {
          uniqueTags.sort((a, b) => this.sortingService.defaultCompare(a.tagName, b.tagName));
          return uniqueTags;
        });
    }

    getUniqueVariables(auditId: number, runId: number): angular.IPromise<Array<ITagAccountVariables>> {
      return this.apiService
        .handleResponse(this.$http.get(`${DiscoveryAuditService.path}/${auditId}/runs/${runId}/results/unique-variables`));
    }

    getUniqueVariableValues(auditId: number, runId: number, variables: Array<ITagIdAccountVariable>): angular.IPromise<Array<ITagIdAccountVariableValue>> {
      return this.apiService
        .handleResponse(this.$http.post(`${DiscoveryAuditService.path}/${auditId}/runs/${runId}/results/unique-variables`, variables));
    }

    private runIdParam(runId: number): string {
      return runId ? `run_id=${runId}` : '';
    }

    getAdvancedExportTagSummary(auditId: number, runId: number) {
      return this.apiService.handleResponse(
        this.$http.get(`${DiscoveryAuditService.path}/${auditId}/account-summaries?run_id=${runId}`)
      );
    }

    sendMultiVendorExportRequest(exportData: IExportMultiModel) {
      return this.apiService.handleResponse(
        this.$http.post(`${this.root}report/advanced/multi-vendor?tag_id=${exportData.vendors.join(',')}&account=${exportData.accountId}&audit_id=${exportData.auditId}&run_id=${exportData.runId}`, { identifiers: exportData.accounts})
      );
    }

    getPageList(auditId: number, runId: number): angular.IPromise<IPageListPage[]> {
      return this.apiService.handleResponse(
        this.$http.get(`${DiscoveryAuditService.path}/${auditId}/runs/${runId}/results/page-list`)
      );
    }

    getAuditPageRules(auditId: number, runId: number, pageId: string): angular.IPromise<IAuditPageRules> {
      return this.apiService.handleResponse(
        this.$http.get(`${DiscoveryAuditService.path}/${auditId}/runs/${runId}/pages/${pageId}/rule-results`)
      );
    }

    @CachePromise()
    getAuditRunActionSnapshots(auditId: number, runId: number): angular.IPromise<IAuditRunSnapshot> {
      return this.apiService.handleResponse(
        this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/runs/${runId}/action-snapshots`)
      );
    }

    @CachePromise()
    getAuditRunFailures(auditId: number, runId: number): angular.IPromise<IAuditRunErrors> {
      return this.apiService.handleResponse(
        this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/runs/${runId}/reports/failures`)
      );
    }

    getEasyBlockTagIds(auditId: number): angular.IPromise<IEasyBlockTags> {
      return this.apiService.handleResponse(
        this.$http.get(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/blocking-configuration`)
      );
    }

    updateEasyBlockTagIds(auditId: number, tagIds: IEasyBlockTags): angular.IPromise<IEasyBlockTags> {
      return this.apiService.handleResponse(
        this.$http.put(`${DiscoveryAuditService.pathV3WebAudits}/${auditId}/blocking-configuration`, tagIds)
      );
    }
}
