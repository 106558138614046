import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { forkJoin, Subject } from 'rxjs';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { Router } from '@angular/router';
import { modalInfo, modalOptionTypes } from '@app/components/new-data-modal/new-data-modal.constants';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Features } from '@app/moonbeamConstants';
import { ComparisonLibraryUrlBuilders } from '@app/components/comparison-library/comparison-library.constants';
import { LiveConnectUrlBuilders } from '@app/components/live-connect/live-connect.constants';
import { RuleLibraryUrlBuilders } from '@app/components/rules/rule-library/rule-library.constants';
import { ActionSetLibraryUrlBuilders } from '@app/components/action-set-library/action-set-library.constants';
import { BulkOperationsUrlBuilders } from '@app/components/bulk-operations/bulk-operations.const';
import { INewDataOption } from './new-data-option/new-data-option.component';
import { userIsStandard } from '../../authUtils';
import { userIsAdmin } from '@app/authUtils';
import { ConsentCategoriesUrlBuilders } from '@app/components/consent-categories/consent-categories.constants';
import { AccountsService } from '@app/components/account/account.service';
import { WebJourneyEditorComponent } from '../web-journey/web-journey-editor/web-journey-editor.component';
import { IAuditEditorCloseOptions } from '../audit/audit-editor/audit-editor.models';
import { ExternalLinksService } from '@app/components/shared/services/external-links.service';
import { RfmLibraryUrlBuilders } from '../rfm-library/rfm-library.constants';
import { AlertsLibraryPaths, AlertsLibraryUrlBuilders, OPEN_ALERT_DESIGNER } from '../alerts-library/alerts-library.constants';
import { IEventManager } from '../eventManager/eventManager';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { takeUntil } from 'rxjs/operators';
import { IRuleSetupModalData } from '../rules/rule-setup/rule-setup.models';
import { RuleSetupModalComponent } from '../rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleSetupMode } from '../rules/rule-setup/rule-setup.enums';
import { ConsentCategoryCreateComponent } from '../consent-categories/cc-create/cc-create.component';
import { ILabel, LabelService } from '../shared/services/label.service';
import { ActionSetEditorComponent } from '../action-set-library/action-set-editor/action-set-editor.component';
import { IActionSet } from '../action-set-library/action-set-library.models';
import { RfmEditorComponent } from '../rfm-library/rfm-editor/rfm-editor.component';
import { IRFMConfigV3, IRFMConfigV3CreationRequest } from '../creator/shared/remoteFileMapping/remote-file-mapping.component';
import { RemoteFileMapService } from '../creator/services/remote-file-map.service';
import { SnackbarService } from '../shared/services/snackbar-service';
import { CreateEditFolderComponent } from '../bulk-operations/manage-folders/create-edit-folder/create-edit-folder.component';
import { IFolder, IFoldersApiService } from '../folder/foldersApiService';
import { CreateEditDomainComponent } from '../bulk-operations/manage-domains/create-edit-domain/create-edit-domain.component';
import { EManageDomainsMode } from '../bulk-operations/manage-domains/create-edit-domain/create-edit-domain.constants';
import { ITableDomain } from '../bulk-operations/manage-domains/manage-domains.models';
import { CreateEditLabelComponent } from '../bulk-operations/manage-labels/create-edit-label/create-edit-label.component';
import { IUser } from '@app/moonbeamModels';
import { AccountSettingsUrlBuilders } from '../account-settings/account-settings.const';
import { EEmailInboxModalType } from '@app/components/email-inboxes/email-inbox-editor/email-inbox-editor.constants';
import { EmailInboxEditorComponent } from '@app/components/email-inboxes/email-inbox-editor/email-inbox-editor.component';
import { EmailInboxesPaths } from '@app/components/email-inboxes/email-inboxes.constants';
import { CreateEditUserModalComponent } from '@app/components/create-edit-user-modal/create-edit-user-modal.component';

@Component({
  selector: 'op-new-data-modal',
  templateUrl: './new-data-modal.component.html',
  styleUrls: ['./new-data-modal.component.scss']
})
export class NewDataModalComponent implements OnInit, OnDestroy {
  readonly EAccountType = EAccountType;

  dataLoaded: boolean = false;
  CONSTANTS = { ...modalInfo, ...modalOptionTypes };
  allLabels: ILabel[] = [];
  allFolders: IFolder[] = [];
  currentUser: IUser;

  webAuditsAndJourneyTypes: INewDataOption[] = [
    {
      type: this.CONSTANTS.webAudit,
      icon: 'explore',
      onClick: this.createWebAudit.bind(this),
    },
    {
      type: this.CONSTANTS.webJourney,
      icon: 'map',
      onClick: this.createWebJourney.bind(this),
    },
    {
      type: this.CONSTANTS.webHARUpload,
      icon: 'note_add',
      onClick: this.gotoHARUpload.bind(this),
    },
    {
      type: this.CONSTANTS.comparisons,
      icon: 'layers',
      onClick: this.gotoComparisons.bind(this),
    },
  ];

  externalDataSourceTypes: INewDataOption[] = [
    {
      type: this.CONSTANTS.appHARUpload,
      icon: 'note_add',
      onClick: this.gotoHARUpload.bind(this),
    },
    {
      type: this.CONSTANTS.liveConnectJourney,
      icon: 'phonelink_setup',
      onClick: this.gotoLiveConnect.bind(this),
    },
  ];

  standardTypes: INewDataOption[] = [
    {
      type: this.CONSTANTS.alerts,
      icon: 'add_alert',
      onClick: this.openAlertDesigner.bind(this),
    },
    {
      type: this.CONSTANTS.rules,
      icon: 'straighten',
      onClick: this.createNewRule.bind(this),
    },
    {
      type: this.CONSTANTS.consentCategories,
      icon: 'thumbs_up_down',
      onClick: this.createNewConsentCategory.bind(this),
    }
  ];

  addStructureTypes: INewDataOption[] = [
    {
      type: this.CONSTANTS.emailInboxes,
      icon: 'email',
      onClick: this.createNewEmailInbox.bind(this),
    },
    {
      type: this.CONSTANTS.actionSets,
      icon: 'dynamic_form',
      onClick: this.createNewActionSet.bind(this),
    },
    {
      type: this.CONSTANTS.remoteFileMapping,
      icon: 'alt_route',
      onClick: this.createNewFileSubstitution.bind(this),
    },
    {
      type: this.CONSTANTS.labels,
      icon: 'more',
      onClick: this.createNewLabel.bind(this),
    }
  ];

  organizeInformationTypes: INewDataOption[] = [
    {
      type: this.CONSTANTS.folders,
      icon: 'create_new_folder',
      onClick: this.createNewFolder.bind(this),
    },
    {
      type: this.CONSTANTS.subfolders,
      icon: 'source',
      onClick: this.createNewSubFolder.bind(this),
    },
    {
      type: this.CONSTANTS.users,
      icon: 'group_add',
      onClick: this.createNewUser.bind(this),
    }
  ];

  accountType: EAccountType = EAccountType.INTERNAL;
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data,
    private modalService: OpModalService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private accountsService: AccountsService,
    public externalLinksService: ExternalLinksService,
    private eventManager: IEventManager,
    private applicationChromeService: ApplicationChromeService,
    private labelService: LabelService,
    private rfmService: RemoteFileMapService,
    private snackbarService: SnackbarService,
    private folderService: IFoldersApiService,
  ) {}

  ngOnInit(): void {
    this.determineMenuItemsByFeature();

    this.applicationChromeService.accountPreview$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((accountPreview) => {
      if (!!accountPreview) {
        this.accountType = accountPreview.accountType;
      }
    });

    this.loadLabels();
    this.loadFolders();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  determineMenuItemsByFeature(): void {
    forkJoin([
      this.authenticationService.getFeaturesWithCache(),
      this.accountsService.getUser(),
    ]).subscribe(([features, user]) => {
      this.currentUser = user;

      if (!features.includes(Features.productLinePrivacy)) {
        this.addStructureTypes.splice(1, 1); // consent categories
      }

      if (!features.includes(Features.harFileIngestor)) {
        this.webAuditsAndJourneyTypes.splice(2, 1); // web
        this.externalDataSourceTypes.splice(0, 1); // app
      }

      if (!features.includes(Features.realDeviceConnect)) {
        const liveConnectIndex = this.externalDataSourceTypes.findIndex((option) => option.type === this.CONSTANTS.liveConnectJourney);
        this.externalDataSourceTypes.splice(liveConnectIndex, 1);  // Live Connect
      }

      if (!features.includes(Features.webJourneys)) {
        this.webAuditsAndJourneyTypes[1].disabled = true; // web-journeys
      }

      if (userIsStandard(user) && !userIsAdmin(user)) {
        this.organizeInformationTypes.splice(3, 1); // users
      }

      this.dataLoaded = true;
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  createWebAudit() {
    this.close();
    this.modalService.openFixedSizeModal(AuditEditorComponent, {disableClose: true, data: ''}, 'op-audit-editor')
      .afterClosed()
      .subscribe((options?: IAuditEditorCloseOptions) => {
        if (options) this.router.navigateByUrl(this.router.url);
      });
  }

  createWebJourney() {
    this.close();
    this.modalService.openFixedSizeModal(WebJourneyEditorComponent, {disableClose: true, data: ''}, 'op-webjourney-editor')
      .afterClosed()
      .subscribe(webJourney => {
        if (webJourney) this.router.navigateByUrl(this.router.url);
      });
  }

  gotoComparisons() {
    this.close();
    this.router.navigateByUrl(ComparisonLibraryUrlBuilders.library());
  }

  gotoHARUpload() {
    this.close();
    this.router.navigateByUrl(LiveConnectUrlBuilders.base());
  }

  gotoLiveConnect() {
    this.close();
    this.router.navigateByUrl(LiveConnectUrlBuilders.base());
  }

  openAlertDesigner() {
    this.close();
    this.router.url.includes(AlertsLibraryPaths.base)
      ? this.eventManager.publish(OPEN_ALERT_DESIGNER)
      : this.router.navigateByUrl(AlertsLibraryUrlBuilders.library(), { state: { openAlertDesigner: true } });
  }

  createNewRule() {
    this.close();
    this.openRuleSetupModal({
      mode: ERuleSetupMode.create
    });
  }

  createNewConsentCategory() {
    this.close();
    this.openConsentCategoryModal({
      data: {
        allLabels: this.allLabels,
        cookies: [],
        tags: [],
        type: 'approved',
        editing: false,
        requestDomains: [],
      }
    });
  }

  createNewActionSet() {
    this.close();
    this.openCreateActionSetModal();
  }

  createNewFileSubstitution() {
    this.close();
    this.openCreateFileSubstitution();
  }

  createNewEmailInbox(): void {
    this.close();
    const data = {
      name: '',
      notes: '',
      labelIds: [],
      labels: [],
      type: EEmailInboxModalType.AdvancedCreate,
    };

    this.modalService.openFixedSizeModal(EmailInboxEditorComponent, {
      disableClose: true,
      data,
      autoFocus: false
    }).afterClosed()
        .subscribe(inbox => {
          if (inbox) {
            this.router.navigateByUrl(EmailInboxesPaths.base);
          }
        });
  }

  createNewFolder() {
    this.close();
    this.openCreateFolderModal();
  }

  createNewSubFolder() {
    this.close();
    this.openCreateSubFolderModal();
  }

  createNewLabel() {
    this.close();
    this.openCreateLabelModal();
  }

  createNewUser() {
    this.close();
    this.openCreateUserModal();
  }

  private loadLabels(): void {
    this.labelService.getLabels().subscribe((labels) => {
      this.allLabels = labels;
    });
  }

  private loadFolders(): void {
    this.folderService.getFolders().then((folders) => {
      this.allFolders = folders;
    });
  }

  private openRuleSetupModal(data: IRuleSetupModalData) {
    this.modalService.openFixedSizeModal(RuleSetupModalComponent, { disableClose: true, data }, 'rule-setup-modal')
    .afterClosed()
    .subscribe((rule) => {
      if (rule) {
        this.router.navigateByUrl(RuleLibraryUrlBuilders.library());
      }
    });
  }

  private openConsentCategoryModal(modalConfig = {}): void {
    this.modalService.openFixedSizeModal(ConsentCategoryCreateComponent, modalConfig)
      .afterClosed()
      .subscribe((data = {allLabels: []}) => {
        if (data.cc) {
          this.router.navigateByUrl(ConsentCategoriesUrlBuilders.base());
        }
      });
  }

  private openCreateActionSetModal(): void {
    this.modalService.openFixedSizeModal(ActionSetEditorComponent, { disableClose: true, data: null })
    .afterClosed()
    .subscribe((actionSet: IActionSet) => {
      if (actionSet) {
        this.router.navigateByUrl(ActionSetLibraryUrlBuilders.base());
      }
    });
  }

  private openCreateFileSubstitution(): void {
    this.modalService.openModal(RfmEditorComponent, {data: null})
      .afterClosed()
      .subscribe((configRequest: IRFMConfigV3CreationRequest) => {
        if (configRequest) {
          this.rfmService.createRfmConfig(configRequest).subscribe(
            (config: IRFMConfigV3) => {
              if (config) {
                this.router.navigateByUrl(RfmLibraryUrlBuilders.base());
              }
            },
            error => {
              console.log(error);
              this.snackbarService.openErrorSnackbar('There was a problem saving the remote file mapping configuration.');
            }
          );
        }
      });
  }

  private openCreateFolderModal(): void {
    this.modalService.openModal(CreateEditFolderComponent, { })
    .afterClosed()
    .subscribe((newFolder: IFolder) => {
      if (newFolder) {
        this.router.navigateByUrl(BulkOperationsUrlBuilders.manageFolders());
      }
    });
  }

  private openCreateSubFolderModal(): void {
    this.modalService.openModal(CreateEditDomainComponent, {
      data: { domains: [], folders: this.allFolders, mode: EManageDomainsMode.Create }
    })
    .afterClosed()
    .subscribe((newDomain: ITableDomain) => {
      if (newDomain) {
        this.router.navigateByUrl(BulkOperationsUrlBuilders.manageSubfolders());
      }
    });
  }

  private openCreateLabelModal(): void {
    this.modalService.openModal(CreateEditLabelComponent, { })
    .afterClosed()
    .subscribe((label: ILabel) => {
      if (label) {
        this.router.navigateByUrl(BulkOperationsUrlBuilders.manageLabels());
      }
    });
  }

  private openCreateUserModal(): void {
    this.modalService.openFixedSizeModal(CreateEditUserModalComponent, {
      disableClose: true,
      data: {
        editMode: false,
      }
    })
    .afterClosed()
    .subscribe((user) => {
      if (user) {
        this.router.navigateByUrl(AccountSettingsUrlBuilders.manageUsers());
      }
    });
  }
}
