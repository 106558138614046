import { Component, OnInit } from '@angular/core';
import { IUser } from '@app/moonbeamModels';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { forkJoin } from 'rxjs';
import { Features } from '@app/moonbeamConstants';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { opSysAdmin, opAdmin, admin, standardUser, guest } from '@app/authUtils';
import { AccountsService } from '@app/components/account/account.service';
import { CreateEditUserModalComponent } from '@app/components/create-edit-user-modal/create-edit-user-modal.component';
import { ICreateEditUserModalData } from '@app/components/create-edit-user-modal/create-edit-user-modal.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  user: IUser;
  hasApiKeyFeature: boolean = false;
  userRoles = {
    opSysAdmin: opSysAdmin,
    opAdmin: opAdmin,
    admin: admin,
    standardUser: standardUser,
    guest: guest
  };

  constructor(
    private authService: AuthenticationService,
    private accountsService: AccountsService,
    private modalService: OpModalService,
  ) { }

  ngOnInit(): void {
    this.getUserInfo();
  }

  getUserInfo() {
    forkJoin([
      this.accountsService.getUser(),
      this.authService.getFeatures()
    ]).subscribe(([user, features]) => {
      this.user = user;
      this.hasApiKeyFeature = features.indexOf(Features.apiKey) > -1;
    });
  }

  editUserInfo() {
    this.modalService.openFixedSizeModal<CreateEditUserModalComponent, ICreateEditUserModalData>(CreateEditUserModalComponent, {
      disableClose: true,
      data: {
        editMode: true,
        userId: this.user.id,
      }
    })
      .afterClosed()
      .subscribe((user) => {
        this.accountsService.resetUserCache();
        this.getUserInfo();
      });
  }
}
