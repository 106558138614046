<div class="flex-col flex-stretch">
  <h6>{{pagesCount}} item{{pagesCount > 1 ? 's' : ''}}</h6>

  <div class="table-wrp" *ngIf="pagesCount > 0; else noResults">
    <cdk-virtual-scroll-viewport [itemSize]="43" class="virtual-scroll-viewport">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="pageUrl">
          <th mat-header-cell *matHeaderCellDef>Page Url</th>
          <td mat-cell *matCellDef="let page"> {{page?.pageUrl}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>

  <ng-template #noResults>
    <div class="no-results">
      No pages missing from baseline
    </div>
  </ng-template>
</div>
