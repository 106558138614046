import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import {
  IActionDetailsConsoleLogObject,
  WebJourneyActionDetailsService
} from '@app/components/domains/webJourneys/webJourneyAPI/web-journey-action-details.service';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { IAuditConsoleLogData } from '@app/components/audit-reports/page-details/page-details.models';
import { PageDetailsReportService } from '@app/components/audit-reports/page-details/page-details.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'console-log-table',
  templateUrl: './console-log-table.component.html',
  styleUrls: ['./console-log-table.component.scss']
})
export class ConsoleLogTableComponent implements OnInit, OnChanges, OnDestroy {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;
  searchString: string = '';
  logs: IActionDetailsConsoleLogObject[];
  filteredLogs: IActionDetailsConsoleLogObject[];
  logTypeCounts: any;
  logTypeVisibility: any = {
    INFO: true,
    FINE: true,
    WARNING: true,
    SEVERE: true,
    verbose: true
  };

  private destroy$ = new Subject();

  @Input() itemId: number;
  @Input() loading: boolean = true;
  @Input() runId: number;
  @Input() reportType: EReportType;
  @Input() pageId?: string; // specific to audits
  @Input() activeTab?: EPageDetailsTabs; // specific to audits
  @Input() actionIndex?: number; // specific to web journeys
  @Input() success?: boolean; // specific to web journeys
  @Input() isScrolled: boolean = false;

  constructor(
    private actionDetailsService: WebJourneyActionDetailsService,
    private pageDetailsService: PageDetailsReportService
  ) {}

  ngOnInit(): void {
    this.actionDetailsService.updatedLogCounts$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.success
        ? this.getConsoleLogData()
        : this.handleJourneyActionFailureState();
    });
  }

  ngOnChanges(): void {
    // init for audits
    if (this.reportType === EReportType.AUDIT) {
      this.getConsoleLogData();
    }

    // Init for web journeys -
    //   If web-journey action step failed, we get a 404 if we attempt to retrieve console log data, so just
    //   clearing the table in that instance.
    if (this.reportType === EReportType.WEB_JOURNEY) {
      this.success
        ? this.getConsoleLogData()
        : this.handleJourneyActionFailureState();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getConsoleLogData(): void {
    this.loading = true;
    if (this.reportType === EReportType.AUDIT) {
      this.pageDetailsService
      .getAuditPageConsoleLog(this.itemId, this.runId, this.pageId)
      .subscribe((consoleLogs: IAuditConsoleLogData) => {
        this.formatConsoleLogData(consoleLogs.consoleLogs);
        this.filterTable(this.searchString);
      });
    } else {
      this.actionDetailsService
        .getConsoleLogs(this.itemId, this.runId, this.actionIndex)
        .subscribe((response: IActionDetailsConsoleLogObject[]) => {
          this.formatConsoleLogData(response);
          this.filterTable(this.searchString);
        });
    }
  }

  formatConsoleLogData(data: IActionDetailsConsoleLogObject[] | any[]): void {
    this.logs = [];

    data.forEach((log: IActionDetailsConsoleLogObject) => {
      if (log.count === 1) {
        this.logs.push(log);
      } else {
        for (let i = 0; i < log.count; i++) {
          this.logs.push({ ...log, count: 1 });
        }
      }
    });

    this.logs.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1);
    this.filteredLogs = this.logs;
    this.setLogTypeCounts();

    if (this.reportType === EReportType.WEB_JOURNEY) {
      this.actionDetailsService.consoleLogCountSubject.next(this.logs.length);
    }

    this.loading = false;
  }

  handleJourneyActionFailureState(): void {
    this.formatConsoleLogData([]);
    this.loading = false;
    this.actionDetailsService.consoleLogCountSubject.next(0);
  }

  filterTable(value: string): void {
    this.searchString = value;

    if (!value || value === '') this.filteredLogs = this.logs;
    this.filteredLogs = this.logs.filter(
      (log: IActionDetailsConsoleLogObject) => {
        const isInMessage = log.message.toLowerCase().includes(value.toLowerCase());
        const isInSource = log.source?.toLowerCase().includes(value.toLowerCase());
        return isInMessage || isInSource;
      }
    );
  }

  setLogTypeCounts(): void {
    this.logTypeCounts = {
      INFO: 0,
      FINE: 0,
      WARNING: 0,
      SEVERE: 0,
      verbose: 0
    };

    this.logs.forEach((log: IActionDetailsConsoleLogObject) => {
      this.logTypeCounts[log.level] += log.count;
    });
  }

  toggleView(logType: string): void {
    this.logTypeVisibility[logType] = !this.logTypeVisibility[logType];
  }
}
