import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { AuditReportBaseService } from '../../audit-report/audit-report.service';
import {
  IAuditRunAllRuleResultsOverviewDTO,
  IAuditRunPageRuleResultsDTO,
  IAuditRunPagesRuleResultsRequestDTO,
  IAuditRunRuleResultsAllTrendsDTO,
  IAuditRunRuleResultsPageCountTrendDTO,
  IAuditRunRuleResultsSummaryDTO,
  IAuditRunRuleResultsSummaryRequestDTO,
  IAuditRunRuleResultsTrendDTO,
  IAuditRunRuleSummaryRequestDTO,
  IAuditRunSpecificRulePageRuleResultsDTO,
  IAuditRunSpecificRuleResultsDTO
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.models';
import { Observable, Subject } from 'rxjs';
import { environment } from '@app/environments/environment';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { takeUntil } from 'rxjs/operators';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';

export interface IRuleSummaryPagination {
  page: number;
  size: number;
}

export interface IRuleSummarySort<SortColumn> {
  sortBy: SortColumn;
  sortDesc: boolean;
}

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class RuleSummaryReportService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();
  protected apiV3Root = `${environment.apiV3Url}web-audits`;

  /**
   * Caching below based on the following:
   * https://medium.com/@dilanthaprasanjith/caching-http-requests-with-rxjs-in-angular-9f7fa44387ce
   */

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getRuleSummary(auditId: number, runId: number, body: IAuditRunRuleSummaryRequestDTO): Observable<IAuditRunRuleResultsSummaryDTO> {
    const requestUrl = `${this.apiV3Root}/${auditId}/runs/${runId}/reports/rule-summary`;
    return this.apiService.post(requestUrl, body);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getRuleSummaryTrends(auditId: number, runId: number): Observable<IAuditRunRuleResultsAllTrendsDTO> {
    const requestUrl = `${this.apiV3Root}/${auditId}/runs/${runId}/reports/rule-summary/trends`;
    return this.apiService.get(requestUrl);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getRuleResultsTrends(auditId: number, days: number): Observable<IAuditRunRuleResultsTrendDTO> {
    const requestUrl = `${this.apiV3Root}/${auditId}/reports/rule-summary/trends/rule-results?days=${days}`;
    return this.apiService.get(requestUrl);
  }

  getPageCountTrends(auditId: number, days: number): Observable<IAuditRunRuleResultsPageCountTrendDTO> {
    const requestUrl = `${this.apiV3Root}/${auditId}/reports/rule-summary/trends/page-count?days=${days}`;
    return this.apiService.get(requestUrl);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess, liveTime: 60000 }) getRuleResults(auditId: number, runId: number, filter?: IAuditRunRuleResultsSummaryRequestDTO): Observable<IAuditRunAllRuleResultsOverviewDTO> {
    const requestUrl = `${this.apiV3Root}/${auditId}/runs/${runId}/reports/rule-summary/rule-results`;
    return this.apiService.post(requestUrl, filter);
  }

  getRuleResultsSpecific(auditId: number, runId: number, ruleSnapshotId: number, filter?: IAuditRunRuleSummaryRequestDTO): Observable<IAuditRunSpecificRuleResultsDTO | undefined> {
    const requestUrl = `${this.apiV3Root}/${auditId}/runs/${runId}/reports/rule-summary/rule-results/${ruleSnapshotId}`;
    return this.apiService.post(requestUrl, filter);
  }

  getPageRuleResults(
    auditId: number,
    runId: number,
    pagination?: IRuleSummaryPagination,
    sort?: IRuleSummarySort<CommonReportsPagesTableColumns>,
    filter?: IAuditRunRuleSummaryRequestDTO
  ): Observable<IAuditRunPageRuleResultsDTO | undefined> {
    const paginationParams = pagination ? `?page=${pagination.page}&size=${pagination.size}` : '';
    const sortParams = sort ? `&sortBy=${sort.sortBy}&sortDesc=${sort.sortDesc || false}` : '';
    const requestUrl = `${this.apiV3Root}/${auditId}/runs/${runId}/reports/rule-summary/page-rule-results${paginationParams}${sortParams}`;
    return this.apiService.post(requestUrl, filter);
  }

  getPageRuleResultsByRule(
    auditId: number,
    runId: number,
    ruleSnapshotId: number,
    pagination?: IRuleSummaryPagination,
    sort?: IRuleSummarySort<CommonReportsPagesTableColumns>,
    filter?: IAuditRunPagesRuleResultsRequestDTO
  ): Observable<IAuditRunSpecificRulePageRuleResultsDTO | undefined> {
    const paginationParams = pagination ? `?page=${pagination.page}&size=${pagination.size}` : '';
    const sortParams = sort ? `&sortBy=${sort.sortBy}&sortDesc=${sort.sortDesc || false}` : '';
    const requestUrl = `${this.apiV3Root}/${auditId}/runs/${runId}/reports/rule-summary/page-rule-results/${ruleSnapshotId}${paginationParams}${sortParams}`;
    return this.apiService.post(requestUrl, filter);
  }

}
