import { UsageChartComponent } from '@app/components/usage-v2/components/usage-chart/usage-chart.component';
import {
  ChartClassName,
  EChartType,
  IChartToggleBtn
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';

const projectedUsageToggleBtnTooltipTextSecondParagraph = '<br>' +
  '<div class="value">' +
  '<div class="value-label-thin">PROJECTION BASED ON HISTORICAL RUNS:</div>' +
  '<div class="value-label">Based on preceding 3 months of historical runs</div>' +
  '</div>' +
  '</div>';

const basedOnTextVariants = {
  [EChartType.AUDIT]: 'Based on the page limit in scheduled audits',
  [EChartType.WEB_JOURNEY]: 'Based on scheduled journey runs',
};

const generateTooltipText = (basedOnText: string, showSecondParagraph: boolean): string => {
  return '<div class="usage-chart-tooltip-body">' +
    '<h3 class="title">PROJECTED USAGE</h3>' +
    '<div class="value">' +
    '<div class="value-label-thin">PROJECTION BASED ON SCHEDULED RUNS:</div>' +
    `<div class="value-label">${basedOnText}</div>` +
    '</div>' +
    (showSecondParagraph ? projectedUsageToggleBtnTooltipTextSecondParagraph : '');
};

export function getProjectedUsageToggleBtn(chartType: EChartType, showSecondParagraph: boolean): IChartToggleBtn {
  return {
    className: UsageChartComponent.getChartToggleBtnClassName(ChartClassName.futurePeriods),
    text: 'Projected Usage',
    tooltipText: generateTooltipText(basedOnTextVariants[chartType], showSecondParagraph),
    chartClassName: ChartClassName.futurePeriods,
    tooltipClass: 'fixed-width-280',
  };
}

export function getProjectedUsageFilteredToggleBtn(chartType: EChartType, showSecondParagraph: boolean): IChartToggleBtn {
  return {
    className: UsageChartComponent.getChartToggleBtnClassName(ChartClassName.filteredProjectedUsage),
    text: 'Projected Usage',
    tooltipText: generateTooltipText(basedOnTextVariants[chartType], showSecondParagraph),
    chartClassName: ChartClassName.filteredProjectedUsage,
    showWithFiltered: true,
    tooltipClass: 'fixed-width-280',
  };
}
