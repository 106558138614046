import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import {
  AccountTypeIdToDisplayValue,
  EAccountTypeIds,
  IAdminAccount
} from '../../manage-accounts.constants';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IAccountDetailsFormValue } from './account-details.models';
import { IKeyValue } from '@app/moonbeamModels';
import { Subject } from 'rxjs';
import {
  EManageAccountStatusDisplayValue,
} from '@app/components/admin-portal/manage-accounts/manage-accounts-filter-bar/manage-accounts-filter-bar.constants';
import { EAccountType } from '@app/components/core/services/authentication.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit, OnDestroy {
  EStatuses = EManageAccountStatusDisplayValue;
  ETypes = AccountTypeIdToDisplayValue;

  accountDetailsForm: UntypedFormGroup;
  types: EAccountType[] = EAccountTypeIds;

  private destroy$: Subject<void> = new Subject();

  @Input() accountV2: IAdminAccount;
  @Input() statuses: IKeyValue<number, string>[];

  @Output() updated: EventEmitter<IAccountDetailsFormValue> = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): void {
    this.accountDetailsForm = this.fb.group({
      name: [this.accountV2.name, Validators.required],
      type: [this.accountV2.accountType],
      status: [this.accountV2.status],
      notes: [this.accountV2.notes]
    });

    this.accountDetailsForm
      .valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe((value: IAccountDetailsFormValue) => {
        if (!this.accountDetailsForm.invalid) {
          this.updated.emit(value);
        }
      });
  }

  get name(): AbstractControl {
    return this.accountDetailsForm.get('name');
  }

  get type(): AbstractControl {
    return this.accountDetailsForm.get('type');
  }

  get status(): AbstractControl {
    return this.accountDetailsForm.get('status');
  }

  get notes(): AbstractControl {
    return this.accountDetailsForm.get('notes');
  }
}
