import { datasourceAllTagsOnly } from './../../shared/comparison-variable-selector/comparison-variable-selector.constants';
import { IComparisonVariableSelectorForm } from './../../shared/comparison-variable-selector/comparison-variable-selector.models';
import { ITagVariableAlignment } from '@app/components/comparison-library/comparison-library.model';
import { tagWarningMessages } from '../../comparisons.constants';
import { ITagAccountVariables, IUniqueTagResponseItem } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IAuditModel } from '@app/components/modals/modalData';
import { OpSelectComponent } from '@app/components/shared/components/op-select/op-select.component';
import { Subject, forkJoin, from, BehaviorSubject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { DiscoveryAuditService } from './../../../domains/discoveryAudits/discoveryAuditService';
import { IAuditInfo, IAuditInfoRun, IComparisonSourceFormModel } from './one-time-component-setup.models';
import { TagsService, IAllTagInfo } from '@app/components/tags/tagsService';
import { IAccountTagSettings, IComparisonTag, IComparison } from '../../comparisons.models';
import { ComparisonVariableSelectorService } from '../../shared/comparison-variable-selector/comparison-variable-selector.service';
import { ITagWithVariables } from '@app/components/match-criteria-picker/match-criteria-picker.models';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'one-time-comparison-setup',
  templateUrl: './one-time-comparison-setup.component.html',
  styleUrls: ['./one-time-comparison-setup.component.scss']
})
export class OneTimeComparisonSetupComponent implements OnInit, OnDestroy {

  @Input() accountTagsSubject: BehaviorSubject<IAccountTagSettings[]>;
  @Input() comparison?: IComparison;
  @Input() formGroup: UntypedFormGroup;
  @Input() submitted: boolean;

  @ViewChild('baselineAuditId') baselineAuditIdComp: OpSelectComponent;
  @ViewChild('baselineAuditRun') baselineAuditRunComp: OpSelectComponent;

  @ViewChild('destinationAuditId') destinationAuditIdComp: OpSelectComponent;
  @ViewChild('destinationAuditRun') destinationAuditRunComp: OpSelectComponent;

  @ViewChildren('variables') variablesComps: QueryList<OpSelectComponent>;

  audits: IAuditInfo[];
  allLabels: ILabel[] = [];

  noAccountTags = false;
  runWithoutEnabledTags = false;
  tagsData: IComparisonSourceFormModel[];

  auditIdToRunsMap = new Map<number, IAuditInfoRun[]>();
  runIdToTagsMap = new Map<number, IComparisonTag[]>();
  tagIdToTagMap = new Map<number, IAccountTagSettings>();

  tagToAllVariablesMap = new Map<number, string[]>();
  tagToExcludedVariablesMap = new Map<number, string[]>();

  isTagPresenceOnly = false;

  showAuditDeletedWarning: boolean = false;

  destroy$ = new Subject<void>();

  constructor(private auditService: DiscoveryAuditService,
              private labelsService: LabelService,
              private tagsService: TagsService,
              private comparisonVariableSelectorService: ComparisonVariableSelectorService) { }

  ngOnInit(): void {
    this.loadData();
    this.initFormListeneres();
    this.updateDragList();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private loadData(): void {
    this.auditService.getAudits().then(audits => {
      this.audits =  audits.map(this.areRunsCompleted)
                           .filter(this.isAuditCompleted)
                           .map(IAuditInfo.buildFromIAuditModel);
      this.auditIdToRunsMap = this.buildAuditIdToRunsMap(this.audits);
    });

    this.labelsService.getLabels().subscribe(labels => {
      this.allLabels = labels;
    });
  }

  private initFormListeneres(): void {
    this.baselineAudit.get('id')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newAuditId: number) => {
        this.setBaselineAuditRun(newAuditId);
      });

    this.baselineAudit.get('run')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newRunId: number) => {
        if (newRunId && this.destinationAuditValue.run === newRunId) {
          this.destinationAudit.get('run').setValue('');
          this.destinationAuditRunComp.clear();
        }

        this.isTagPresenceOnly = false;

        setTimeout(() => {
          this.getRunTags(this.baselineAuditValue.id, newRunId);
        });
      });

    this.destinationAudit.get('id')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newAuditId: number) => {
        this.setDestinationAuditRun(newAuditId);
      });

    this.destinationAudit.get('run')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newRunId: number) => {
        if (newRunId && this.baselineAuditValue.run === newRunId) {
          this.baselineAudit.get('run').setValue('');
          this.baselineAuditRunComp.clear();
        }
      });

    this.tags.valueChanges
      .pipe(
        debounceTime(100),
        takeUntil(this.destroy$)
      )
      .subscribe((tags: IComparisonVariableSelectorForm[]) => {
        this.isTagPresenceOnly = !!tags.find(t => t.tagId === datasourceAllTagsOnly.key);
      });
  }

  private getRunTags(auditId: number, runId: number): void {
    Promise.all([
      this.auditService.getUniqueTags(auditId, runId),
      this.auditService.getUniqueVariables(auditId, runId)
    ]).then(
      ([uniqueTags, uniqueVars]) => {
        this.buildRunIdToTagsMap(uniqueTags, runId);
        this.buildTagIdToVarsMaps(uniqueVars);
      },
      error => {
        if (error.code === 404) {
          this.showAuditDeletedWarning = true;
        }
      }
    );
  }

  private buildRunIdToTagsMap(uniqueTags: IUniqueTagResponseItem[], runId: number): void {
    let tagIdToAlignmentMap = new Map();
    if (this.comparison) {
      tagIdToAlignmentMap = this.comparison.alignments.reduce(
        (map, alignment) => map.set(alignment.tagId, alignment),
        new Map()
      );
    }

    this.accountTagsSubject.subscribe(accountTags => {
      const getTags$ = uniqueTags
        .filter(tag => {
          const accountTag = accountTags.find(accountTag => accountTag.tagId === tag.tagId);
          return accountTag && (accountTag.comparisonEnabled || !!tagIdToAlignmentMap[tag.tagId]);
        }).map(
          tag => from(this.tagsService.getAllTagInfo(tag.tagId))
        );

      if (getTags$.length === 0) {
        this.runIdToTagsMap.set(runId, []);
        return;
      }

      forkJoin(getTags$).subscribe(comparisonTags => {
        this.tagIdToTagMap = accountTags.reduce(
          (acc, tag) => acc.set(tag.tagId, tag),
          new Map()
        );
        this.noAccountTags = !accountTags.find(t => t.comparisonEnabled);
        this.runWithoutEnabledTags = !comparisonTags.find(t => this.tagIdToTagMap.get(t.id).comparisonEnabled);
        this.runIdToTagsMap.set(runId, this.buildTags(comparisonTags, this.tagIdToTagMap, tagIdToAlignmentMap));
        this.updateDragList();
      });
    });
  }

  private buildTags(tags: IAllTagInfo[],
                    tagIdToAccountTagMap: Map<number, IAccountTagSettings>,
                    tagIdToAlignmentMap: Map<number, ITagVariableAlignment>): IComparisonTag[] {
    return tags.map(t => {
      const accountTag = tagIdToAccountTagMap.get(t.id);
      const alignment = tagIdToAlignmentMap.get(t.id);
      return {
        id: t.id,
        icon: t.icon,
        name: t.name,
        variables: t.variables.map(v => v.variable),
        accountExcludedVariables: accountTag.excludedVariables,
        excludedVariables: accountTag.excludedVariables,
        variableAlignment: alignment ? alignment.variables : [],
        comparisonEnabled: accountTag.comparisonEnabled
      };
    });
  }

  private buildTagIdToVarsMaps(tagsVars: ITagAccountVariables[]): void {
    this.accountTagsSubject.subscribe(accountTags => {
      const filteredTags = tagsVars.filter(
        tag => accountTags.find(accountTag => accountTag.tagId === tag.tagId)
      );

      filteredTags.forEach(tag => {
        const vars = tag.variables.map(v => v.key);
        const accountTag = accountTags.find(t => t.tagId === tag.tagId);

        this.tagToAllVariablesMap.set(tag.tagId, vars);
        this.tagToExcludedVariablesMap.set(tag.tagId, vars.filter(v => accountTag.excludedVariables.includes(v)));
      });
    });
  }

  private buildAuditIdToRunsMap(audits: IAuditInfo[]): Map<number, IAuditInfoRun[]> {
    return audits.reduce(
      (acc: Map<number, IAuditInfoRun[]>, audit: IAuditInfo) => acc.set(audit.id, audit.runs),
      new Map()
    );
  }

  private isAuditCompleted(audit: IAuditModel): boolean {
    return audit.runs && audit.runs[0] && !!audit.runs[0].completed;
  }

  private areRunsCompleted(audit: IAuditModel): IAuditModel {
    const filteredRuns = audit.runs && audit.runs.filter(a => !!a.completed);
    return {...audit, runs: filteredRuns};
  }

  private setBaselineAuditRun(auditId: number): void {
    const runs = this.auditIdToRunsMap.get(auditId);
    if (runs && runs[0]) this.baselineAudit.get('run').setValue(runs[0].id);
    else this.baselineAuditRunComp.clear();
  }

  private setDestinationAuditRun(auditId: number): void {
    const runs = this.auditIdToRunsMap.get(auditId);
    if (runs && runs[0]) this.destinationAudit.get('run').setValue(runs[0].id);
    else this.destinationAuditRunComp.clear();
  }

  resetAllSelectedTags(): void {
    this.tags.controls.forEach(tagControl => {
      tagControl.get('tagId').setValue('');
      tagControl.get('type').updateValueAndValidity();
      tagControl.get('selectedVariables').setValue([]);
    });
    this.variablesComps.forEach(comp => comp.clear());
  }

  addTag(index: number): void {
    this.tags.insert(index, this.comparisonVariableSelectorService.createTagGroup(this.destroy$));
    this.updateDragList();
  }

  deleteTag(index: number): void {
    this.tags.removeAt(index);
    this.updateDragList();
  }

  getTagId(index: number): number {
    const tagGroup = this.tags.at(index) as UntypedFormGroup;
    return tagGroup.get('tagId').value;
  }

  setVariables(index: number, filteredVars): void {
    const tagGroup = this.tags.at(index) as UntypedFormGroup;
    (tagGroup.get('selectedVariables') as UntypedFormControl).setValue(filteredVars);
  }

  private updateDragList(): void {
    this.tagsData = this.formGroup.value.tags.map(data => {
      const tag = this.tagIdToTagMap.get(data.tagId);
      return {
        ...data,
        comparisonEnabled: tag ? tag.comparisonEnabled : true
      };
    });
  }

  getTagWarning(tag: IComparisonSourceFormModel): string {
    const tagNoLongerAvail = tag.tagId && !tag.comparisonEnabled;

    if (tagNoLongerAvail) return tagWarningMessages.noLongerAvailable;
    else if (this.noAccountTags) return tagWarningMessages.noAccountTags;
    else if (this.runWithoutEnabledTags) return tagWarningMessages.runWithoutEnabled;

    return '';
  }

  // is used by parent component
  getTagsWithVariables(): ITagWithVariables[] {
    const filteredTags = this.runIdToTagsMap.get(this.baselineAuditValue.run).filter(ct =>
      this.tagsValue.find(t => t.tagId === ct.id)
    );
    return filteredTags.map(t => ({
      id: t.id,
      name: t.name,
      icon: t.icon,
      variables: t.variableAlignment
    }));
  }

  get labels(): UntypedFormGroup {
    return this.formGroup.get('labels') as UntypedFormGroup;
  }

  get baselineAudit(): UntypedFormGroup {
    return this.formGroup.get('baselineAudit') as UntypedFormGroup;
  }

  get destinationAudit(): UntypedFormGroup {
    return this.formGroup.get('destinationAudit') as UntypedFormGroup;
  }

  get baselineAuditValue(): {id: number, run: number} {
    return this.baselineAudit ? this.baselineAudit.value : {};
  }

  get destinationAuditValue(): {id: number, run: number} {
    return this.destinationAudit ? this.destinationAudit.value : {};
  }

  get tags(): UntypedFormArray {
    return this.formGroup.get('tags') as UntypedFormArray;
  }

  get tagsValue(): IComparisonSourceFormModel[] {
    return this.tags ? this.tags.value : [];
  }
}
