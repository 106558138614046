import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  AuditReportUrlBuilders,
  EChartColor,
  EConsentCategoryComplianceStatus,
  EPageUrlFilterType,
  EStatusCodeCategories
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  EUseCaseNavToKeys,
  EUseCaseWidgetType,
  IAuditUseCaseData,
  IUseCaseAnalyticsSummaryInsight,
  IUseCaseCookiesSummaryInsight,
  IUseCaseDuplicatesAndMultiplesWidgetData,
  IUseCaseMostUsedTag,
  IUseCaseMostUsedTagsSummaryInsight,
  IUseCasePageSummaryInsight,
  IUseCaseTagHealthSummaryInsight
} from '@app/components/audit-reports/use-cases/use-cases.models';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { IUser } from '@app/moonbeamModels';
import { AccountsService } from '@app/components/account/account.service';
import {
  ITagInventoryCategories,
  ITagInventoryCategory,
  ITagInventoryPages,
  ITagInventoryQueryParams,
  ITagInventorySummary,
  ITagInventoryTags
} from '@app/components/audit-reports/reports/tag-inventory/tag-inventory.models';
import { formatNumber } from '@angular/common';
import { TagInventoryService } from '@app/components/audit-reports/reports/tag-inventory/tag-inventory.service';
import { RuleSummaryReportService } from '@app/components/audit-reports/reports/rule-summary/rule-summary.service';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { TagHealthService } from '@app/components/audit-reports/reports/tag-health/tag-health.service';
import {
  TagHealthSummary,
  TagLoadTimeDistribution,
  TagStatusCodeDistribution
} from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import {
  IDonutChartDataPoint
} from '@app/components/shared/components/viz/donut-chart-with-key/donut-chart-with-key.models';
import {
  AnalyticsPagesScannedWidget,
  BrokenTagRequestsWidget,
  DuplicateTagRequestsWidget,
  EUseCaseTagLoadTimePills,
  TagAndVariableRuleFailuresWidget,
  TagRequestsWidget,
  TopAnalyticsWidget,
  TopTagManagerWidget,
  UniqueAnalyticsTagsWidget,
  UniqueTagVariablesWidget,
  UniqueTagVariableValuesWidget
} from '@app/components/audit-reports/use-cases/use-case-analytics/use-case-analytics.constants';
import {
  TagDuplicatesAndMultiplesService
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.service';
import {
  TagDuplicatesAndMultiplesSummary
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.models';
import {
  VariableInventoryService
} from '@app/components/audit-reports/reports/variable-inventory/variable-inventory.service';
import { PageSummaryReportService } from '@app/components/audit-reports/reports/page-summary/page-summary.service';
import {
  IPageSummaryInsights,
  IPageSummaryInsightsByPage,
  IPageSummaryLoadTimeChart,
  IPageSummaryStatusCodeChart
} from '@app/components/audit-reports/reports/page-summary/page-summary.models';
import {
  IHorizontalBarChartDataPoint
} from '@app/components/shared/components/viz/horizontal-bar-chart-rounded/horizontal-bar-chart-rounded.models';
import {
  LandingPageAveragePageLoadTimeWidget,
  LandingPageBrokenPagesWidget,
  LandingPagePagesMissingAnalyticsWidget,
  LandingPagePagesMissingQueryStringsWidget,
  LandingPagePagesMissingTagManagerWidget,
  LandingPagePagesScannedWidget,
  LandingPagePagesWithRedirectsWidget,
  LandingPageUniqueTagsWidget
} from './use-case-landing-page/use-case-landing-page.constants';
import { AuditReportFilterBarService } from '../audit-report-filter-bar/audit-report-filter-bar.service';
import {
  OverviewAveragePageLoadTimeWidget,
  OverviewAverageTagLoadTimeWidget,
  OverviewBrokenPagesWidget,
  OverviewBrokenTagRequestsWidget, OverviewLCPWidget,
  OverviewNonSecureCookiesWidget,
  OverviewPagesScannedWidget,
  OverviewTagInitiatorWidget,
  OverviewUniqueCookiesWidget,
  OverviewUniqueTagsWidget
} from './use-case-overview/use-case-overview.constants';
import {
  CookieInventoryService
} from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.service';
import {
  CookieInventoryCookies,
  CookieInventorySummary
} from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.models';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { IAccountPreview } from '@app/components/core/services/authentication.models';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { OpConfirmModalComponent, OpModalService } from '@app/components/shared/components/op-modal';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { ICommonTableState } from '@app/components/shared/components/export-report/export-reports.models';
import { EPageLoadTimeCategory } from '@app/components/audit-reports/reports/page-summary/page-summary.enums';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { IAuditReportApiPostBody } from '../audit-report/audit-report.models';
import { PrivacyCookiesService } from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.service';
import { PrivacyTagsService } from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.service';
import {
  PrivacyRequestsService
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.service';
import { WindowRef } from '@app/components/core/services/window.service';
import {
  EditDataLayerModalComponent
} from '@app/components/shared/components/edit-data-layer-modal/edit-data-layer-modal.component';
import { IDomain } from '@app/components/domains/domainsService';
import { fromPromise } from 'rxjs/internal-compatibility';
import { IAuditModel } from '@app/components/modals/modalData';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { TagInitiatorsService } from '@app/components/shared/components/tag-initiators/tag-initiators.service';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import {
  TagInitiatorsFullScreenComponent
} from '@app/components/shared/components/tag-initiators-full-screen/tag-initiators-full-screen.component';
import { ITagInitiatorNode } from '@app/components/shared/components/tag-initiators/tag-initiators.models';
import {
  DoNotShareRuleName,
  NewJavascriptFilesWidget,
  PagesMissingConsentManagerWidget,
  PagesMissingPrivacyPolicyLinksWidget,
  PagesMissingShareSellLinksWidget,
  PagesMissingTagManagerWidget,
  Privacy3rdPartyCookiesWidget,
  PrivacyPagesScannedWidget,
  PrivacyPolicyFoundRuleName,
  PrivacyUniqueCookiesWidget,
  PrivacyUniqueGEOWidget,
  PrivacyUniqueTagsWidget
} from '@app/components/audit-reports/use-cases/use-case-privacy/use-case-privacy.constants';
import {
  EAuditReportFilterTypes,
  ECookiePartyType,
  EJSFileChangeType
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { JsFileChangesService } from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.service';
import { groupBy, head } from 'lodash';
import {
  IPrivacyRequestsLocation,
  IPrivacyRequestsLocations
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.models';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { ApiService } from '@app/components/core/services/api.service';
import { environment } from '@app/environments/environment';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';
import { IUiTagCategory } from '@app/components/tag-database';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import {
  IAuditPageInfoWebVitalsSummary,
  IAuditPageInfoWebVitalsSummaryItem
} from '@app/components/audit-reports/page-details/page-details.models';

@Injectable()
export class UseCaseService implements OnDestroy {
  private destroy$ = new Subject();
  private auditId: number;
  private runId: number;
  private categoryMap: { [id: number]: string } = {};

  isAllSharedDataLoaded: boolean = false;
  isAuditDataLoaded: boolean = false;
  isDataLayerPagesLoaded: boolean = false;
  isOverviewDataLoaded: boolean = false;
  isAnalyticsDataLoaded: boolean = false;
  isPrivacyDataLoaded: boolean = false;
  isLandingPageDataLoaded: boolean = false;
  isOverviewAndPrivacyDataLoaded: boolean = false;

  accountType: IAccountPreview['accountType'];
  account;
  audit: IAuditModel;
  doNotShareSellLinkRuleId: number;
  privacyPolicyRuleId: number;
  domains: IDomain[] = [];
  auditInfo: IAuditUseCaseData;
  readonly domainLoadingString = '---';

  readonly analyticsTagsCategoryId = 42;

  // TODO: split into multiple services, use @CacheApiResponse instead of BehaviorSubject for caching data
  private auditSubject = new BehaviorSubject<IAuditUseCaseData>({ domain: this.domainLoadingString } as IAuditUseCaseData);
  audit$: Observable<IAuditUseCaseData> = this.auditSubject.asObservable();
  private pagesWithRedirectsSubject = new BehaviorSubject<IPageSummaryInsightsByPage>({} as IPageSummaryInsightsByPage);
  pagesWithRedirects$: Observable<IPageSummaryInsightsByPage> = this.pagesWithRedirectsSubject.asObservable();
  private userSubject = new BehaviorSubject<IUser>({} as IUser);
  user$: Observable<IUser> = this.userSubject.asObservable();
  private analyticsTagsSubject = new BehaviorSubject<IUseCaseAnalyticsSummaryInsight>({} as IUseCaseAnalyticsSummaryInsight);
  analyticsTags$ = this.analyticsTagsSubject.asObservable();

  private tagHealthSubject = new BehaviorSubject<IUseCaseTagHealthSummaryInsight>({} as IUseCaseTagHealthSummaryInsight);
  tagHealth$ = this.tagHealthSubject.asObservable();
  private analyticsTagsHealthSubject = new BehaviorSubject<IUseCaseTagHealthSummaryInsight>({} as IUseCaseTagHealthSummaryInsight);
  analyticsTagsHealth$ = this.analyticsTagsHealthSubject.asObservable();

  private duplicatesAndMultiplesSubject = new BehaviorSubject<IUseCaseDuplicatesAndMultiplesWidgetData>({} as IUseCaseDuplicatesAndMultiplesWidgetData);
  duplicatesAndMultiples$ = this.duplicatesAndMultiplesSubject.asObservable();
  private analyticsTagsDuplicatesAndMultiplesSubject = new BehaviorSubject<IUseCaseDuplicatesAndMultiplesWidgetData>({} as IUseCaseDuplicatesAndMultiplesWidgetData);
  analyticsTagsDuplicatesAndMultiples$ = this.analyticsTagsDuplicatesAndMultiplesSubject.asObservable();

  private tagAndVariableSubject = new BehaviorSubject<any>([]);
  tagAndVariable$: Observable<any> = this.tagAndVariableSubject.asObservable();
  private variableInventorySubject = new BehaviorSubject<any>([]);
  variableInventory$: Observable<any> = this.variableInventorySubject.asObservable();
  private pageSummarySubject = new BehaviorSubject<IUseCasePageSummaryInsight>({} as IUseCasePageSummaryInsight);
  pageSummary$ = this.pageSummarySubject.asObservable();
  private cookiesSummarySubject = new BehaviorSubject<IUseCaseCookiesSummaryInsight>({} as IUseCaseCookiesSummaryInsight);
  cookiesSummary$ = this.cookiesSummarySubject.asObservable();
  private cookiesSummaryFilteredByThirdPartySubject = new BehaviorSubject<IUseCaseCookiesSummaryInsight>({} as IUseCaseCookiesSummaryInsight);
  cookiesSummaryFilteredByThirdPartySubject$ = this.cookiesSummaryFilteredByThirdPartySubject.asObservable();
  private isVisitorModeSubject = new BehaviorSubject<any>(null);
  isVisitorMode$: Observable<any> = this.isVisitorModeSubject.asObservable();
  private accountTypeSubject = new BehaviorSubject<EAccountType>(null);
  accountType$: Observable<any> = this.accountTypeSubject.asObservable();
  private pagesMissingShareSellLinkInfoSubject = new BehaviorSubject<any>(null);
  pagesMissingShareSellLinkInfo$: Observable<any> = this.pagesMissingShareSellLinkInfoSubject.asObservable();
  private newJavascriptFilesSubject = new BehaviorSubject<any>(null);
  newJavascriptFiles$: Observable<any> = this.newJavascriptFilesSubject.asObservable();
  private cookiePrivacySubject = new BehaviorSubject<any>(null);
  cookiePrivacy$: Observable<any> = this.cookiePrivacySubject.asObservable();
  private tagPrivacySubject = new BehaviorSubject<any>(null);
  tagPrivacy$: Observable<any> = this.tagPrivacySubject.asObservable();
  private requestPrivacySubject = new BehaviorSubject<any>(null);
  requestPrivacy$: Observable<any> = this.requestPrivacySubject.asObservable();
  private dataLayerPagesSubject = new BehaviorSubject<any>(null);
  dataLayerPages$: Observable<any> = this.dataLayerPagesSubject.asObservable();
  private tagInitiatorsSubject = new BehaviorSubject<any>(null);
  tagInitiators$: Observable<any> = this.tagInitiatorsSubject.asObservable();
  private mostUsedTagsSubject = new BehaviorSubject<IUseCaseMostUsedTagsSummaryInsight>({} as IUseCaseMostUsedTagsSummaryInsight);
  mostUsedTags$ = this.mostUsedTagsSubject.asObservable();
  private requestsLocationsSubject = new BehaviorSubject<IPrivacyRequestsLocation[]>([]);
  requestsLocations$ = this.requestsLocationsSubject.asObservable();
  private pagesMissingQueryStringsSubject = new BehaviorSubject<any>(null);
  pagesMissingQueryStrings$: Observable<any> = this.pagesMissingQueryStringsSubject.asObservable();
  private pagesMissingTagManagerSubject = new BehaviorSubject<any>(null);
  pagesMissingTagManager$: Observable<any> = this.pagesMissingTagManagerSubject.asObservable();
  private pagesMissingConsentManagerSubject = new BehaviorSubject<any>(null);
  pagesMissingConsentManager$: Observable<any> = this.pagesMissingConsentManagerSubject.asObservable();
  private pagesMissingPrivacyPolicyLinksSubject = new BehaviorSubject<any>(null);
  pagesMissingPrivacyPolicyLinks$: Observable<any> = this.pagesMissingPrivacyPolicyLinksSubject.asObservable();
  private tagInventorySummaryMissingTagManagerSubject = new BehaviorSubject<any>(null);
  tagInventorySummaryMissingTagManager$: Observable<any> = this.tagInventorySummaryMissingTagManagerSubject.asObservable();
  private tagInventorySummarySubject = new BehaviorSubject<any>(null);
  tagInventorySummary$: Observable<any> = this.tagInventorySummarySubject.asObservable();
  private auditHeaderDoneLoadingSubject = new Subject<boolean>();
  auditHeaderDoneLoading$: Observable<boolean> = this.auditHeaderDoneLoadingSubject.asObservable();

  constructor(
    private router: Router,
    private discoveryAuditService: DiscoveryAuditService,
    private accountsService: AccountsService,
    private tagInventoryService: TagInventoryService,
    private ruleSummaryService: RuleSummaryReportService,
    private auditReportLoadingService: AuditReportLoadingService,
    private tagHealthService: TagHealthService,
    private duplicatesAndMultiplesService: TagDuplicatesAndMultiplesService,
    private variableInventoryService: VariableInventoryService,
    private pageSummaryService: PageSummaryReportService,
    private filterBarService: AuditReportFilterBarService,
    private cookieInventoryService: CookieInventoryService,
    private applicationChromeService: ApplicationChromeService,
    private modalService: OpModalService,
    private cookiePrivacyService: PrivacyCookiesService,
    private tagPrivacyService: PrivacyTagsService,
    private requestPrivacyService: PrivacyRequestsService,
    private windowRef: WindowRef,
    private modalEscapeService: ModalEscapeService,
    private tagInitiatorsService: TagInitiatorsService,
    private ccService: ConsentCategoriesService,
    private jsFileChangesService: JsFileChangesService,
    private apiService: ApiService,
    private uiTagService: UiTagService,
  ) {
    this.applicationChromeService.isVisitorMode$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((isVisitorMode) => {
        this.isVisitorModeSubject.next(isVisitorMode);
      });

    this.applicationChromeService.accountPreview$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((accountPreview) => {
        if (!!accountPreview) {
          this.accountType = accountPreview.accountType;
          this.accountTypeSubject.next(accountPreview.accountType);
        }
      });

    this.accountsService.getUser().subscribe((user) => {
      this.userSubject.next(user);
    });

    // Handle reloading data when the run changes through run-picker
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((next: NavigationEnd) => {
        const paths = next.url.split('/');
        const auditId = Number(paths[paths.indexOf('audit') + 1]) === 0
          ? undefined
          : Number(paths[paths.indexOf('audit') + 1]);
        const runId = Number(paths[paths.indexOf('run') + 1]) === 0
          ? undefined
          : Number(paths[paths.indexOf('run') + 1]);

        // Reset data flags when switching between audits
        if (this.auditId === undefined || this.runId === undefined) {
          this.resetDataLoadedFlags();
          this.resetOverviewTabInitialValues();
        }

        const isUseCaseRoute = this.router.url.includes('/use-cases/');
        const runOrAuditChanged = this.runId && this.runId !== runId || this.auditId && this.auditId !== auditId;

        // Set the auditId and runId if selected run changes
        this.auditId = auditId;
        this.runId = runId;

        if (auditId && runId && isUseCaseRoute && runOrAuditChanged) {
          this.resetDataLoadedFlags();
          this.resetOverviewTabInitialValues();
          this.loadCurrentReportData();
        }
      });

    // Load initial data and set values after the audit header has finished it's check
    // for a 403 error and handled switching accounts if logging in as another user
    this.auditHeaderDoneLoading$.subscribe(() => {
      this.setAuditAndRunIds();
    });
  }

  setAuditAndRunIds() {
    // Get auditId and runId from the URL
    const paths = this.router.url.split('/');
    const auditId = Number(paths[paths.indexOf('audit') + 1]);
    const runId = Number(paths[paths.indexOf('run') + 1]);

    // Set the auditId and runId on initial report load
    this.auditId = auditId;
    this.runId = runId;
  }

  // These flags are used to track whether we have already loaded data for a specific report.
  // If we need to reload data, we need to reset these flags, otherwise we skip calls to reload
  // data. If we want to completely refactor and stop using BehaviorSubjects, these could be removed
  // and we could move data calls for each report into their own services and then cache the endpoints
  // using CacheApiResponse instead.
  resetDataLoadedFlags() {
    this.isAllSharedDataLoaded = false;
    this.isAuditDataLoaded = false;
    this.isDataLayerPagesLoaded = false;
    this.isOverviewDataLoaded = false;
    this.isAnalyticsDataLoaded = false;
    this.isPrivacyDataLoaded = false;
    this.isLandingPageDataLoaded = false;
    this.isOverviewAndPrivacyDataLoaded = false;
  }

  resetOverviewTabInitialValues(): void {
    this.pageSummarySubject.next({} as IUseCasePageSummaryInsight);
    this.duplicatesAndMultiplesSubject.next({} as IUseCaseDuplicatesAndMultiplesWidgetData);
    this.tagHealthSubject.next({} as IUseCaseTagHealthSummaryInsight);
    this.auditSubject.next({ domain: this.domainLoadingString } as IAuditUseCaseData);
    this.cookiesSummarySubject.next({} as IUseCaseCookiesSummaryInsight);
  }

  auditHeaderDoneLoading() {
    this.auditHeaderDoneLoadingSubject.next(true);
  }

  confirmReportNavigation(navTo: Function) {
    this.modalService.openModal(OpConfirmModalComponent, {
      data: {
        title: 'Continue?',
        messages: ['The report you\'re about to view is not currently optimized for mobile.'],
        leftFooterButtons: [
          {
            label: 'Go back',
            primary: false,
            action: () => { },
          }
        ],
        rightFooterButtons: [
          {
            label: 'Continue anyway',
            primary: true,
            action: () => {
              // Perform the navigation
              navTo();
            }
          }
        ],
      }
    })
      .afterClosed()
      .subscribe(
        (res) => {

        });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadSharedDataForAllReports() {
    if (!this.isAllSharedDataLoaded) {
      this.getPageInfo();
      this.getAnalyticsTags();
      return this.getAuditInfo().toPromise().then((results) => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!results) { return; }

        const [audit, startingUrls] = results;

        this.audit = audit;

        let auditForUseCase: IAuditUseCaseData;
        if (startingUrls.pages[0]) {
          auditForUseCase = {
            domain: new URL(startingUrls.pages[0].pageUrl).hostname,
            domainFull: startingUrls.pages[0].pageUrl,
            pageTitle: startingUrls.pages[0].pageTitle
          };
        } else {
          auditForUseCase = {
            domain: '',
            domainFull: '',
            pageTitle: ''
          };
        }

        this.auditInfo = auditForUseCase;
        this.isAuditDataLoaded = true;
        this.isAllSharedDataLoaded = true;

        this.auditSubject.next(auditForUseCase);  // Used in all use case reports
      });
    } else {
      return Promise.resolve(this.auditInfo);
    }
  }

  async loadSharedDataForOverviewAndPrivacy() {
    // Domain is needed to get/update the data layer information
    if (!this.isOverviewAndPrivacyDataLoaded && this.auditId && this.runId) {
      this.getDuplicatesAndMultiplesInfo();
      this.getCookiesInfo();
      return await this.getDomains().toPromise().then(domains => {
        this.domains = domains;
        this.isOverviewAndPrivacyDataLoaded = true;
      });
    }
  }

  loadDataForOverview() {
    if (this.auditId && this.runId) {
      this.getTagInitiatorsData();
      this.getTagHealthInfo();
    }

    this.isOverviewDataLoaded = true;
  }

  loadDataForAnalytics() {
    this.getDataLayerInfo();
    this.getAnalyticsTagsHealthInfo();
    this.getAnalyticsTagsDuplicatesAndMultiplesInfo();
    this.getTagAndVariableData();
    this.getTagVariableInfo();

    this.isAnalyticsDataLoaded = true;
  }

  loadDataForPrivacy() {
    // First, check if data used in multiple reports has already been loaded. If not, load it.
    if (!this.isOverviewAndPrivacyDataLoaded) {
      this.loadSharedDataForOverviewAndPrivacy();
    }

    // Then load data specific to only the Privacy report
    this.getCookiePrivacyInfo();
    this.getCookiesFilteredByThirdPartyInfo();
    this.getTagPrivacyInfo();
    this.getRequestPrivacyInfo();
    this.getNewJavascriptFilesInfo();
    this.getMostUsedTags();
    this.getRequestLocations();
    this.getTagInventoryMissingTagManagerData();
    this.getPagesMissingConsentManagerData();
    this.getPagesMissingPrivacyPolicyLinksData();
    this.getPagesMissingShareSellLinkInfo();

    this.isPrivacyDataLoaded = true;
  }

  loadDataForLandingPage() {
    this.getPagesMissingQueryStringsData();
    this.getPagesMissingTagManagerData();
    this.getTagInventorySummaryData();
    this.getPagesWithRedirectsData();

    this.isLandingPageDataLoaded = true;
  }

  loadCurrentReportData() {
    const isUseCaseReport = this.router.url.includes('/use-cases/');

    if (isUseCaseReport) {
      const isOverview = this.router.url.includes('/use-cases/overview');
      const isAnalytics = this.router.url.includes('/use-cases/analytics');
      const isPrivacy = this.router.url.includes('/use-cases/privacy');
      const isLandingPage = this.router.url.includes('/use-cases/landing-page');

      if (isOverview) {
        this.loadOverview();
      } else if (isAnalytics) {
        this.loadAnalytics();
      } else if (isPrivacy) {
        this.loadPrivacy();
      } else if (isLandingPage) {
        this.loadLandingPage();
      }
    }
  }

  async loadOverview() {
    await this.loadAllSharedData();

    if (!this.isOverviewAndPrivacyDataLoaded) {
      await this.loadSharedDataForOverviewAndPrivacy();
    }

    if (!this.isOverviewDataLoaded) {
      this.loadDataForOverview();
    }
  }

  async loadPrivacy() {
    await this.loadAllSharedData();

    if (!this.isOverviewAndPrivacyDataLoaded) {
      await this.loadSharedDataForOverviewAndPrivacy();
    }

    if (!this.isPrivacyDataLoaded) {
      this.loadDataForPrivacy();
    }
  }

  async loadAnalytics() {
    await this.loadAllSharedData();

    if (!this.isAnalyticsDataLoaded) {
      this.loadDataForAnalytics();
    }
  }

  async loadLandingPage() {
    await this.loadAllSharedData();

    if (!this.isLandingPageDataLoaded) {
      this.loadDataForLandingPage();
    }
  }

  async loadAllSharedData() {
    if (!this.isAllSharedDataLoaded) {
      // Need to set manually here when deep-linking into a use case report
      this.setAuditAndRunIds();

      return await this.loadSharedDataForAllReports();
    }
  }

  replaceVariableWithValue(text: string, value: string): string {
    return text.replace(/{{.*}}/, value);
  }

  getDataLayerInfo() {
    const pagination: ITagInventoryQueryParams = {
      page: 0, // API paginates starting with 0
      size: 200,
      sortBy: CommonReportsPagesTableColumns.PageUrl,
      sortDesc: false
    };

    forkJoin([
      // Data Layer is determined by pages that have tagId 211, so filtering
      // by that tagId will get a count of all pages that have a data layer
      this.tagInventoryService.getTagInventoryPages(this.auditId, this.runId, pagination, { tagId: 211 }),
      this.pageSummaryService.getAuditPageSummaryInsights(this.auditId, this.runId, {}),
    ])
      .pipe(catchError(e => {
        console.error('Error getting audit info for privacy report', e);
        return of(undefined);
      }))
      .subscribe((results) => {
        if (!results) { return; }

        const [dataLayerPages, pageSummary] = results;

        // Get data layer information for an audit
        const domain = this.domains.find((domain) => domain.id === this.audit.domainId);
        const pagesWithDataLayer = dataLayerPages.metadata.pagination.totalCount;
        const allPages = pageSummary.totalPages;
        const dataLayerPagesPercent = `${Math.round((pagesWithDataLayer / allPages) * 100)}%`;

        const dataLayerInfo = {
          domain,
          dataLayerPagesPercent,
        };

        this.dataLayerPagesSubject.next(dataLayerInfo);

        this.isDataLayerPagesLoaded = true;
      });
  }

  /**
   * Handles navigation to any needed routes for use case reports. The key is used to
   * determine the route to navigate to.
   * @param navKey - Enum to manage possible routes.
   * @param custom - Any custom data needed for the route. Don't use this for filters, use the filters param instead.
   * @param filters - Any filters that need to be applied to the report.
   */
  navTo(navKey: EUseCaseNavToKeys,
    custom?: any,
    filters?: IOpFilterBarFilter<EAuditReportFilterTypes>[]): void {
    this.filterBarService.clear();

    if (filters) this.addFilters(filters);

    switch (navKey) {
      case EUseCaseNavToKeys.PageSummary:
        this.router.navigateByUrl(AuditReportUrlBuilders.pageSummary(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PageSummarySlowLoading:
        this.filterBarService.addPageLoadTimeFilter(10000);
        this.router.navigateByUrl(AuditReportUrlBuilders.pageSummary(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PageSummaryBrokenFinalPages:
        this.filterBarService.addFinalPageStatusCodeFilter(EStatusCodeCategories.Broken);
        this.router.navigateByUrl(AuditReportUrlBuilders.pageSummary(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PageSummaryAveragePageLoadTime:
        this.filterBarService.addPageLoadTimeFilter(10000, null);
        this.router.navigateByUrl(AuditReportUrlBuilders.pageSummary(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PageSummaryPagesWithRedirects:
        this.filterBarService.addPageStatusCodeFilter(EStatusCodeCategories.Redirects);
        this.router.navigateByUrl(AuditReportUrlBuilders.pageSummary(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PageSummaryPagesMissingQueryStrings:
        this.filterBarService.addFinalUrlDoesNotContainFilter('?', false);
        this.router.navigateByUrl(AuditReportUrlBuilders.pageSummary(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventoryAnalytics:
        this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventoryDataLayer:
        this.filterBarService.addPagesWithoutTagIdFilter('Data Layer', 211);
        this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventory:
        this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventoryMissingTag:
        this.filterBarService.addPagesWithoutTagIdFilter(custom?.tagName, custom?.tagId);
        this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagHealth:
        this.router.navigateByUrl(AuditReportUrlBuilders.tagHealth(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventorySlowLoadingTags:
        this.filterBarService.addTagLoadTimeFilter(2000);
        this.router.navigateByUrl(AuditReportUrlBuilders.tagHealth(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventoryBrokenTags:
        this.filterBarService.addTagStatusCodeFilter(EStatusCodeCategories.Broken);
        this.router.navigateByUrl(AuditReportUrlBuilders.tagHealth(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventoryDuplicateTags:
        this.router.navigateByUrl(AuditReportUrlBuilders.duplicatesAndMultiples(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventoryRuleFailures:
        this.router.navigateByUrl(AuditReportUrlBuilders.ruleSummary(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventoryPagesMissingAnalytics:
        this.filterBarService.addPagesWithoutTagCategoryIdFilter('Web Analytics', this.analyticsTagsCategoryId);
        this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventoryPagesMissingTagManager:
        this.filterBarService.addPagesWithoutTagCategoryIdFilter('Tag Management', 6);
        this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.TagInventoryPagesMissingConsentManager:
        this.filterBarService.addPagesWithoutTagCategoryIdFilter('Privacy & Consent', 14);
        this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.VariableInventory:
        this.router.navigateByUrl(AuditReportUrlBuilders.variableInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.UniqueTagVariables:
        this.router.navigateByUrl(AuditReportUrlBuilders.variableInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.UniqueTagVariableValues:
        this.router.navigateByUrl(AuditReportUrlBuilders.variableInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.CookiesInventory:
        this.router.navigateByUrl(AuditReportUrlBuilders.cookieInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.CookiesInventory3rdParty:
        this.filterBarService.addCookiePartyFilter(ECookiePartyType.third);
        this.router.navigateByUrl(AuditReportUrlBuilders.cookieInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.CookiesInventoryNonSecureCookies:
        this.filterBarService.addCookieSecureFilter(false);
        this.router.navigateByUrl(AuditReportUrlBuilders.cookieInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.UnapprovedCookies:
        this.filterBarService.addConsentCategoryStatusFilter('unapproved');
        this.router.navigateByUrl(AuditReportUrlBuilders.privacyCookies(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.UnapprovedTags:
        this.filterBarService.addConsentCategoryStatusFilter('unapproved');
        this.router.navigateByUrl(AuditReportUrlBuilders.privacyTags(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.UnapprovedGeoLocations:
        this.filterBarService.addConsentCategoryStatusFilter('unapproved');
        this.router.navigateByUrl(AuditReportUrlBuilders.privacyRequests(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PagesMissingPrivacyPolicyLinks:
        this.privacyPolicyRuleId ? this.filterBarService.addRuleNameFilter(this.privacyPolicyRuleId, PrivacyPolicyFoundRuleName) : null;
        this.router.navigateByUrl(AuditReportUrlBuilders.ruleSummary(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PagesMissingShareSellLinks:
        this.doNotShareSellLinkRuleId ? this.filterBarService.addRuleNameFilter(this.doNotShareSellLinkRuleId, DoNotShareRuleName) : null;
        this.router.navigateByUrl(AuditReportUrlBuilders.ruleSummary(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PrivacyCookies:
        this.router.navigateByUrl(AuditReportUrlBuilders.privacyCookies(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PrivacyTags:
        this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.PrivacyGeoLocations:
        this.router.navigateByUrl(AuditReportUrlBuilders.privacyRequests(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.NewJavascriptFiles:
        this.filterBarService.addJSFileChangeTypeFilter(EJSFileChangeType.newFile);
        this.router.navigateByUrl(AuditReportUrlBuilders.privacyFileChanges(this.auditId, this.runId));
        break;
      case EUseCaseNavToKeys.FreeTrial:
        const freeTrialUrl = `https://www.observepoint.com/upgrade-your-trial-account?aid=${this.userSubject.value.accountId}&at=${this.accountTypeSubject.value}&uid=${this.userSubject.value.id}`;
        this.windowRef.nativeWindow.open(freeTrialUrl, '_blank');
        break;
      case EUseCaseNavToKeys.SampleAccount:
        const sampleAccountUrl = `https://www.observepoint.com/upgrade-your-account?aid=${this.userSubject.value.accountId}&at=${this.accountTypeSubject.value}&uid=${this.userSubject.value.id}`;
        this.windowRef.nativeWindow.open(sampleAccountUrl, '_blank');
        break;
      default:
        this.router.navigateByUrl(AuditReportUrlBuilders.useCaseOverview(this.auditId, this.runId));
        break;
    }
  }

  private addFilters(filters?: IOpFilterBarFilter<EAuditReportFilterTypes>[]) {
    filters?.forEach(filter => {
      switch (filter.type) {
        case EAuditReportFilterTypes.TagCategory:
          this.filterBarService.addTagCategoryFilter(filter.display, filter.value as number);
          break;
        default:
          console.error('Unknown filter type: ', filter.type);
          break;
      }
    });
  }

  /**
   * Get any needed audit data for use case reports. This will set the value of the
   * auditSubject which can be subscribed to by the use case components.
   */
  getAuditInfo() {
    this.auditReportLoadingService.addLoadingToken();

    const pagination: ITagInventoryQueryParams = {
      page: 0, // API paginates starting with 0
      size: 200,
      sortBy: CommonReportsPagesTableColumns.PageUrl,
      sortDesc: false
    };

    const body = {
      hasParentPage: false
    } as IAuditReportApiPostBody;

    return forkJoin([
      fromPromise(this.discoveryAuditService.getAudit(this.auditId)),
      this.pageSummaryService.getAuditPageSummaryInsightsByPage(this.auditId, this.runId, pagination, body)
    ])
      .pipe(catchError(e => {
        console.error('Error getting audit info for use case reports', e);
        return of(undefined);
      }));
  }

  getPagesWithRedirectsData(): void {
    this.auditReportLoadingService.addLoadingToken();

    const params: ICommonTableState = {
      page: 0,
      size: 200,
      sortBy: CommonReportsPagesTableColumns.PageUrl,
      sortDesc: false
    };

    const body = {
      redirectCount: {
        min: 1,
        max: null
      }
    } as IAuditReportApiPostBody;

    this.pageSummaryService.getAuditPageSummaryInsightsByPage(this.auditId, this.runId, params, body).subscribe((res: IPageSummaryInsightsByPage) => {
      this.pagesWithRedirectsSubject.next(res);
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  getTagData(summary) {
    const tags = {
      uniqueTags: formatNumber(summary.filteredUniqueTagCount || 0, 'en-us'),
      brokenTags: formatNumber(summary.filteredBrokenTagCount || 0, 'en-us'),
      tagRequests: formatNumber(summary.filteredTagInstanceCount || 0, 'en-us'),
      duplicateTagRequests: 3,
      tagAndVariableRuleFailures: 4,
      uniqueTagVariables: 5,
      uniqueTagValues: 6,
    };

    return tags;
  }

  getTagAndVariableData() {
    this.ruleSummaryService.getRuleSummary(this.auditId, this.runId, {})
      .pipe(catchError(e => {
        console.error('Error getting tag and variable data', e);
        return of(undefined);
      }))
      .subscribe(ruleSummary => {
        if (!ruleSummary) { return; }

        const tagAndVariableInfo = this.handleTagAndVariableInfo(ruleSummary);

        this.tagAndVariableSubject.next(tagAndVariableInfo);
      });
  }

  handleTagAndVariableInfo(summary) {
    return {
      totalRuleCount: formatNumber(summary?.totalRuleCount || 0, 'en-us'),
      totalRuleFailures: formatNumber(summary?.failedRuleCount || 0, 'en-us'),
    };
  }

  getAnalyticsTags() {
    this.auditReportLoadingService.addLoadingToken();

    forkJoin([
      this.tagInventoryService
        .getTagInventorySummary(this.auditId, this.runId, { tagCategoryId: this.analyticsTagsCategoryId })
        .pipe(catchError(e => {
          console.error('Error getting tagInvSummary for analytics tags', e);
          return of(undefined);
        })),
      this.tagInventoryService
        .getTagInventoryTags(this.auditId, this.runId, { tagCategoryId: this.analyticsTagsCategoryId })
        .pipe(catchError(e => {
          console.error(`Error getting tagInvTags (category ${this.analyticsTagsCategoryId}) for analytics tags`, e);
          return of(undefined);
        })),
      this.tagInventoryService.getTagInventoryCategories(this.auditId, this.runId, {})
        .pipe(catchError(e => {
          console.error('Error getting tagInvCats for analytics tags', e);
          return of(undefined);
        })),
      this.uiTagService.getTagCategories().pipe(
        catchError(e => {
          console.error('Error getting tag categories', e);
          return of(undefined);
        }
        )),
      this.tagInventoryService
        .getTagInventoryTags(this.auditId, this.runId, { tagCategoryId: 6 })
        .pipe(catchError(e => {
          console.error('Error getting tagInvTags (category 6) for analytics tags', e);
          return of(undefined);
        })),
    ]).subscribe(([tagSummary, analyticsTags, tagCategories, tagCategoryConfigs, tagManagement]) => {
      tagCategoryConfigs?.forEach((cat: IUiTagCategory) => {
        this.categoryMap[cat.id] = cat.category;
      });
      this.auditReportLoadingService.removeLoadingToken();

      if (!tagSummary || !analyticsTags || !tagCategories || !tagManagement) { return; }

      const analyticsTagInfo = this.handleAnalyticsTagsInfo(tagSummary, analyticsTags, tagCategories, tagManagement);
      this.analyticsTagsSubject.next(analyticsTagInfo);
    });
  }

  handleAnalyticsTagsInfo(
    tagSummary: ITagInventorySummary,
    analyticsTags: ITagInventoryTags,
    tagCategories: ITagInventoryCategories,
    tmsTags: ITagInventoryTags,
  ): IUseCaseAnalyticsSummaryInsight {
    const tagCategoryChartData = tagCategories.tagCategories
      .filter((cat: ITagInventoryCategory) => cat.tagFilterStatus)
      .map((cat: ITagInventoryCategory) => {
        return {
          id: cat.tagCategoryId,
          value: cat.totalTagInstanceCount,
          name: this.categoryMap[cat.tagCategoryId],
          colorClass: `tag-cat-${cat.tagCategoryId}`,
          filtered: false
        };
      }
      );

    const tagLoadSpeedChartData = [];

    const topAnalyticsTag = analyticsTags.tags.sort((a, b) => b.tagPresentPageCount - a.tagPresentPageCount)[0];
    const topTms = tmsTags.tags.sort((a, b) => b.tagPresentPageCount - a.tagPresentPageCount)[0];

    const analyticsTagsInfo: IUseCaseAnalyticsSummaryInsight = {
      brokenAnalyticsTagCount: tagSummary?.filteredBrokenTagCount,
      uniqueAnalyticsTagCount: tagSummary?.filteredUniqueTagCount,
      tagInstanceCount: tagSummary?.totalTagInstanceCount,
      pageCountWithAnalytics: tagSummary?.filteredPageCount,
      pageCountTotal: tagSummary?.totalPageCount,
      uniqueAnalyticsChartData: tagCategoryChartData,
      tagRequestsChartData: tagLoadSpeedChartData,
      topTms,
      topAnalyticsTag,
    };

    return analyticsTagsInfo;
  }

  /**
   * Retrieve data for tags health
   */
  getTagHealthInfo() {
    this.auditReportLoadingService.addLoadingToken();

    this.tagHealthService.getTagHealthSummary(this.auditId, this.runId, {})
      .pipe(catchError(e => {
        console.error('Error getting tag health summary', e);
        return of(undefined);
      }))
      .subscribe(tagHealthSummary => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!tagHealthSummary) { return; }

        const tagHealthInfo = this.handleTagHealthInfo(tagHealthSummary);
        this.tagHealthSubject.next(tagHealthInfo);
      });
  }

  /**
   * Retrieve data for analytics tags health
   */
  getAnalyticsTagsHealthInfo() {
    this.auditReportLoadingService.addLoadingToken();

    this.tagHealthService.getTagHealthSummary(this.auditId, this.runId, { tagCategoryId: this.analyticsTagsCategoryId })
      .pipe(catchError(e => {
        console.error('Error getting analytics tags health summary', e);
        return of(undefined);
      }))
      .subscribe(tagHealthSummary => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!tagHealthSummary) { return; }

        const tagHealthInfo = this.handleTagHealthInfo(tagHealthSummary);
        this.analyticsTagsHealthSubject.next(tagHealthInfo);
      });
  }

  /**
   * Retrieve data for broken pages
   */
  getPageInfo() {
    this.auditReportLoadingService.addLoadingToken();

    this.pageSummaryService.getAuditPageSummaryInsights(this.auditId, this.runId, {}).subscribe(pageSummary => {

      const pageSummaryInfo = this.handlePageSummaryInfo(pageSummary);

      this.pageSummarySubject.next(pageSummaryInfo);
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  /**
   * Retrieve data for cookies
   */
  getCookiesInfo() {
    this.auditReportLoadingService.addLoadingToken();

    this.cookieInventoryService.getCookieInventorySummary(this.auditId, this.runId, {}).subscribe(pageSummary => {

      const cookieSummaryInfo = this.handleCookiesSummaryInfo(pageSummary);

      this.cookiesSummarySubject.next(cookieSummaryInfo);
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  /**
   * Retrieve data for cookies
   */
  getCookiesFilteredByThirdPartyInfo() {
    this.auditReportLoadingService.addLoadingToken();

    this.cookieInventoryService.getCookieInventorySummary(this.auditId, this.runId, {
      partyType: ECookiePartyType.third
    }).subscribe(pageSummary => {
      const cookieSummaryInfo = this.handleCookiesSummaryInfo(pageSummary);

      this.cookiesSummaryFilteredByThirdPartySubject.next(cookieSummaryInfo);
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  getMostUsedTags() {
    this.auditReportLoadingService.addLoadingToken();

    forkJoin([
      this.cookieInventoryService.getCookieInventoryCookies(this.auditId, this.runId, {
        sortDesc: true,
        sortBy: 'page_count',
        page: 0,
        size: 100
      }, {
        allThirdPartyCookies: true
      }),
      this.pageSummaryService.getAuditPageSummaryInsights(this.auditId, this.runId, {})
    ]).subscribe(([cookieInfo, pageSummary]) => {

      const mostUsedTags = this.handleMostUsedTagsInfo(cookieInfo, pageSummary);

      this.mostUsedTagsSubject.next(mostUsedTags);
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  handleMostUsedTagsInfo(cookieInventory: CookieInventoryCookies, pageSummary: IPageSummaryInsights): IUseCaseMostUsedTagsSummaryInsight {
    const mostUsedTags = this.getMostUsedTagsPercent(cookieInventory, pageSummary);

    return {
      ...cookieInventory,
      mostUsedTags
    };
  }

  getRequestLocations() {
    this.auditReportLoadingService.addLoadingToken();

    this.requestPrivacyService.getPrivacyRequestsLocations(this.auditId, this.runId, {}).subscribe(locations => {

      const groupedLocations = this.handleGetRequestLocations(locations);
      this.requestsLocationsSubject.next(groupedLocations);
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  handleGetRequestLocations({ locations }: IPrivacyRequestsLocations): IPrivacyRequestsLocation[] {
    const groups = groupBy(locations, 'requestGeo.countryCode');
    const keys = Object.keys(groups);
    const locationsNew = [];
    keys.forEach(k => {
      if (groups[k].length !== 1) {
        const approvedCount = groups[k]
          .filter(item => item.status === 'approved')
          .reduce((sum, acc) => sum + acc.requestCount, 0);
        locationsNew.push({ ...head(groups[k].filter(item => item.status === 'unapproved')), approvedCount });
      } else {
        locationsNew.push(head(groups[k]));
      }
    });

    return locationsNew;
  }

  handleCookiesSummaryInfo(pageSummary: CookieInventorySummary): IUseCaseCookiesSummaryInsight {
    const partCookiesChartData = this.getCookiesPartsChartData(pageSummary);
    const securityCookiesChartData = this.getCookieSecurityChartData(pageSummary);

    return {
      ...pageSummary,
      partCookiesChartData,
      securityCookiesChartData
    };
  }

  handlePageSummaryInfo(pageSummary: IPageSummaryInsights): IUseCasePageSummaryInsight {
    const brokenPagesChartData = this.getBrokenPagesChartData(pageSummary.pageCountsByFinalStatusCodes);
    const averagePageLoadTimeChartData = this.getAveragePageLoadTimeChartData(pageSummary.pageCountByLoadTimes);
    const overviewLargestContentfulPaintChartData = this.getLargestContentfulPaintChartData(pageSummary.webVitals.p75LargestContentfulPaint);

    return {
      ...pageSummary,
      averagePageLoadTimeChartData,
      brokenPagesChartData,
      overviewLargestContentfulPaintChartData,
    };
  }

  handleTagHealthInfo(tagHealthSummary: TagHealthSummary): IUseCaseTagHealthSummaryInsight {
    const tagLoadingTimesChartData = this.getTagLoadTimeChartData(tagHealthSummary.tagLoadTimeDistribution);
    const brokenTagsChartData = this.getBrokenTagsChartData(tagHealthSummary.tagStatusCodeDistribution);

    return {
      ...tagHealthSummary,
      brokenTagsChartData,
      tagRequestsChartData: tagLoadingTimesChartData,
    };
  }

  getBrokenPagesChartData = (data: IPageSummaryStatusCodeChart): IDonutChartDataPoint[] => {
    return [
      {
        value: data.good || 0,
        colorClass: EChartColor.Green,
        name: `Successful pages`,
      },
      {
        value: data.broken || 0,
        colorClass: EChartColor.Red,
        name: `Broken pages`,
      }
    ];
  }

  getLargestContentfulPaintChartData = (data: number): IAuditPageInfoWebVitalsSummaryItem => {
    return {
      value: data,
      warnThreshold: 2500,
      failThreshold: 4000,
      pass: false,
    };
  }

  getCookiesPartsChartData = (data: CookieInventorySummary): IDonutChartDataPoint[] => {
    return [
      {
        value: data.totalFirstPartyCookieCount || 0,
        colorClass: EChartColor.Green,
        name: `1st Party Cookies`,
      },
      {
        value: data.totalThirdPartyCookieCount || 0,
        colorClass: EChartColor.Yellow,
        name: `3rd Party Cookies`,
      }
    ];
  }

  getCookieSecurityChartData = (data: CookieInventorySummary): IDonutChartDataPoint[] => {
    return [
      {
        value: (data.totalUniqueCookieCount - data.filteredNonSecureCookieCount) || 0,
        colorClass: EChartColor.Green,
        name: `Secure`,
      },
      {
        value: data.filteredNonSecureCookieCount || 0,
        colorClass: EChartColor.Red,
        name: `Non-Secure`,
      }
    ];
  }

  getMostUsedTagsPercent = (data: CookieInventoryCookies, pageSummary: IPageSummaryInsights): IUseCaseMostUsedTag[] => {
    let totalPagesCount = 0;
    const mostUsedDomains: { [s: string]: number } = data?.cookies?.reduceRight((acc, cookie) => {
      const domain = cookie.domain;
      acc[domain] = cookie.filteredPageCount;
      return acc;
    }, {});

    const sorted = Object.entries(mostUsedDomains).sort((a, b) => b[1] - a[1]);
    return sorted.map(([domain, count]) => ({
      domain,
      percent: Math.round((count / pageSummary.totalPages) * 100)
    }));
  }

  getAveragePageLoadTimeChartData(statusCodes: IPageSummaryLoadTimeChart): IHorizontalBarChartDataPoint[] {
    return [
      {
        value: statusCodes?.below3,
        name: '< 3 sec',
        filtered: false,
        colorClass: EChartColor.Green,
      },
      {
        value: statusCodes['3to6'],
        name: '3-6 sec',
        filtered: false,
        colorClass: EChartColor.Yellow,
      },
      {
        value: statusCodes['6to10'],
        name: '6-10 sec',
        filtered: false,
        colorClass: EChartColor.Orange,
      },
      {
        value: statusCodes['10andAbove'],
        name: '10+ sec',
        filtered: false,
        colorClass: EChartColor.Red,
      },
    ];
  }

  getTagLoadTimeChartData = (data: TagLoadTimeDistribution = undefined): IDonutChartDataPoint[] => {
    const totalForPercentage = Object.keys(data).reduce((acc, curr) => acc + data[curr], 0);

    return [
      {
        value: data ? data['below500'] : 0,
        colorClass: EChartColor.Green,
        name: `${EUseCaseTagLoadTimePills['Below500']}`,
        totalForPercentage,
      },
      {
        value: data ? data['500to1000'] : 0,
        colorClass: EChartColor.Yellow,
        name: EUseCaseTagLoadTimePills['500to1000'],
        totalForPercentage,
      },
      {
        value: data ? data['1000to2000'] : 0,
        colorClass: EChartColor.Orange,
        name: EUseCaseTagLoadTimePills['1000to2000'],
        totalForPercentage,
      },
      {
        value: data ? data['above2000'] : 0,
        colorClass: EChartColor.Red,
        name: `${EUseCaseTagLoadTimePills['2000andAbove']}`,
        totalForPercentage,
      }
    ];
  }

  getBrokenTagsChartData(statusCodes: TagStatusCodeDistribution): IDonutChartDataPoint[] {
    return [
      {
        value: statusCodes?.good,
        name: 'Success',
        colorClass: EChartColor.Green,
      },
      {
        value: statusCodes?.redirect,
        name: 'Redirects',
        colorClass: EChartColor.Yellow,
      },
      {
        value: statusCodes?.broken,
        name: 'Broken',
        colorClass: EChartColor.Red,
      },
    ];
  }

  /**
   * Retrieve duplicates and multiples
   */
  getDuplicatesAndMultiplesInfo() {
    this.auditReportLoadingService.addLoadingToken();

    this.duplicatesAndMultiplesService.getTagDuplicatesAndMultiplesSummary(this.auditId, this.runId, {})
      .pipe(catchError(e => {
        console.error('Error retrieving duplicates and multiples summary', e);
        return of(undefined);
      }))
      .subscribe(duplicatesAndMultiplesSummary => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!duplicatesAndMultiplesSummary) { return; }

        const duplicatesAndMultiplesInfo = this.handleDuplicatesAndMultiplesInfo(duplicatesAndMultiplesSummary);
        this.duplicatesAndMultiplesSubject.next(duplicatesAndMultiplesInfo);
      });
  }

  /**
   * Retrieve analytics tags duplicates and multiples
   */
  getAnalyticsTagsDuplicatesAndMultiplesInfo() {
    this.auditReportLoadingService.addLoadingToken();

    this.duplicatesAndMultiplesService.getTagDuplicatesAndMultiplesSummary(this.auditId, this.runId, { tagCategoryId: this.analyticsTagsCategoryId })
      .pipe(catchError(e => {
        console.error('Error retrieving analytics tags duplicates and multiples summary', e);
        return of(undefined);
      }))
      .subscribe(duplicatesAndMultiplesSummary => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!duplicatesAndMultiplesSummary) { return; }

        const duplicatesAndMultiplesInfo = this.handleDuplicatesAndMultiplesInfo(duplicatesAndMultiplesSummary);
        this.analyticsTagsDuplicatesAndMultiplesSubject.next(duplicatesAndMultiplesInfo);
      });
  }

  handleDuplicatesAndMultiplesInfo(duplicatesAndMultiplesSummary: TagDuplicatesAndMultiplesSummary) {
    const duplicatesAndMultiplesChartData = this.getDuplicatesAndMultiplesChartData(duplicatesAndMultiplesSummary);

    return {
      ...duplicatesAndMultiplesSummary,
      duplicatesAndMultiplesChartData,
    };
  }

  getDuplicatesAndMultiplesChartData = (data: TagDuplicatesAndMultiplesSummary = undefined): IDonutChartDataPoint[] => {
    const totalForPercentage = Object.keys(data).reduce((acc, curr) => acc + data[curr], 0);

    return [
      {
        value: data ? data.totalTagInstanceCount - data.filteredTagDuplicateCount : 0,
        colorClass: EChartColor.Green,
        name: 'Non-Duplicates',
        totalForPercentage,
      },
      {
        value: data ? data.filteredTagDuplicateCount : 0,
        colorClass: EChartColor.Yellow,
        name: 'Duplicates',
        totalForPercentage,
      },
    ];
  }

  /**
   * Retrieve data for duplicates and multiples
   */
  getTagVariableInfo() {
    this.auditReportLoadingService.addLoadingToken();

    this.variableInventoryService.getVariableInventorySummaryForAuditRun(this.auditId, this.runId, { tagCategoryId: this.analyticsTagsCategoryId })
      .pipe(catchError(e => {
        console.error('Error retrieving tag variable inventory summary data', e);
        return of(undefined);
      }))
      .subscribe(variableInventorySummary => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!variableInventorySummary) { return; }

        const variableInventoryInfo = this.handleTagVariableInfo(variableInventorySummary);
        this.variableInventorySubject.next(variableInventoryInfo);
      });
  }

  handleTagVariableInfo(variableInventorySummary) {
    return {
      ...variableInventorySummary,
    };
  }

  getBodyText(widgetType: EUseCaseWidgetType): string {
    const widgets = {
      [EUseCaseWidgetType.AnalyticsPagesScanned]: AnalyticsPagesScannedWidget,
      [EUseCaseWidgetType.OverviewPagesScanned]: OverviewPagesScannedWidget,
      [EUseCaseWidgetType.LandingPagePagesScanned]: LandingPagePagesScannedWidget,
      [EUseCaseWidgetType.UniqueAnalytics]: UniqueAnalyticsTagsWidget,
      [EUseCaseWidgetType.TopAnalytics]: TopAnalyticsWidget,
      [EUseCaseWidgetType.TopTms]: TopTagManagerWidget,
      [EUseCaseWidgetType.TagRequests]: TagRequestsWidget,
      [EUseCaseWidgetType.BrokenTagRequests]: BrokenTagRequestsWidget,
      [EUseCaseWidgetType.DuplicateTagRequests]: DuplicateTagRequestsWidget,
      [EUseCaseWidgetType.TagAndVariableRuleFailures]: TagAndVariableRuleFailuresWidget,
      [EUseCaseWidgetType.UniqueTagVariables]: UniqueTagVariablesWidget,
      [EUseCaseWidgetType.UniqueTagVariableValues]: UniqueTagVariableValuesWidget,
      [EUseCaseWidgetType.OverviewAveragePageLoadTime]: OverviewAveragePageLoadTimeWidget,
      [EUseCaseWidgetType.OverviewLargestContentfulPaint]: OverviewLCPWidget,
      [EUseCaseWidgetType.OverviewBrokenPages]: OverviewBrokenPagesWidget,
      [EUseCaseWidgetType.OverviewUniqueCookies]: OverviewUniqueCookiesWidget,
      [EUseCaseWidgetType.OverviewNonSecureCookies]: OverviewNonSecureCookiesWidget,
      [EUseCaseWidgetType.OverviewUniqueTags]: OverviewUniqueTagsWidget,
      [EUseCaseWidgetType.OverviewBrokenTags]: OverviewBrokenTagRequestsWidget,
      [EUseCaseWidgetType.OverviewAverageTagLoadTime]: OverviewAverageTagLoadTimeWidget,
      [EUseCaseWidgetType.OverviewTagInitiator]: OverviewTagInitiatorWidget,
      [EUseCaseWidgetType.LandingPageBrokenPages]: LandingPageBrokenPagesWidget,
      [EUseCaseWidgetType.LandingPageAveragePageLoadTime]: LandingPageAveragePageLoadTimeWidget,
      [EUseCaseWidgetType.LandingPagePagesMissingQueryStrings]: LandingPagePagesMissingQueryStringsWidget,
      [EUseCaseWidgetType.LandingPagePagesWithRedirects]: LandingPagePagesWithRedirectsWidget,
      [EUseCaseWidgetType.LandingPageUniqueTags]: LandingPageUniqueTagsWidget,
      [EUseCaseWidgetType.LandingPagePagesMissingAnalytics]: LandingPagePagesMissingAnalyticsWidget,
      [EUseCaseWidgetType.LandingPagePagesMissingTagManager]: LandingPagePagesMissingTagManagerWidget,
      [EUseCaseWidgetType.PrivacyPagesMissingShareSellLinks]: PagesMissingShareSellLinksWidget,
      [EUseCaseWidgetType.PrivacyNewJavascriptFiles]: NewJavascriptFilesWidget,
      [EUseCaseWidgetType.PrivacyPagesScanned]: PrivacyPagesScannedWidget,
      [EUseCaseWidgetType.PrivacyUniqueCookies]: PrivacyUniqueCookiesWidget,
      [EUseCaseWidgetType.Privacy3rdPartyCookies]: Privacy3rdPartyCookiesWidget,
      [EUseCaseWidgetType.PrivacyUniqueTags]: PrivacyUniqueTagsWidget,
      [EUseCaseWidgetType.PrivacyUniqueGeolocations]: PrivacyUniqueGEOWidget,
      [EUseCaseWidgetType.PagesMissingConsentManager]: PagesMissingConsentManagerWidget,
      [EUseCaseWidgetType.PagesMissingTagManager]: PagesMissingTagManagerWidget,
      [EUseCaseWidgetType.PagesMissingPrivacyPolicyLinks]: PagesMissingPrivacyPolicyLinksWidget,
    };

    switch (this.accountType) {
      case EAccountType.SAMPLE:
        return widgets[widgetType].bodyContent.bodyText.guest;
      case EAccountType.FREETRIAL:
        return widgets[widgetType].bodyContent.bodyText.trial;
      default:
        return widgets[widgetType].bodyContent.bodyText.paid;
    }
  }

  formatDataForBrokenPagesChart(data: IPageSummaryInsights): IDonutChartDataPoint[] {
    return [
      {
        name: 'Successful Final Pages',
        colorClass: EChartColor.Green,
        value: data.totalPages - data.pagesWithBrokenFinalStatusCode,
      }, {
        name: 'Broken Final Pages',
        colorClass: EChartColor.Red,
        value: data.pagesWithBrokenFinalStatusCode,
      }
    ];
  }

  formatDataForAveragePageLoadTimeChart(data: IPageSummaryInsights): IHorizontalBarChartDataPoint[] {
    if (!Object.keys(data).length) return;

    return [
      {
        name: '< 3 sec',
        colorClass: EChartColor.Green,
        value: data.pageCountByLoadTimes[EPageLoadTimeCategory.BELOW3],
        filtered: false
      }, {
        name: '3-6 sec',
        colorClass: EChartColor.Yellow,
        value: data.pageCountByLoadTimes[EPageLoadTimeCategory.BETWEEN3AND6],
        filtered: false
      }, {
        name: '6-10 sec',
        colorClass: EChartColor.Orange,
        value: data.pageCountByLoadTimes[EPageLoadTimeCategory.BETWEEN6AND10],
        filtered: false
      }, {
        name: '10+ sec',
        colorClass: EChartColor.Red,
        value: data.pageCountByLoadTimes[EPageLoadTimeCategory.ABOVE10],
        filtered: false
      }
    ];
  }

  formatDataForPagesMissingQueryStringsChart(numPagesScanned: number, numPagesMissingQueryStrings: number): IDonutChartDataPoint[] {
    return [
      {
        name: 'With Query Strings',
        colorClass: EChartColor.Green,
        value: numPagesScanned - numPagesMissingQueryStrings
      }, {
        name: 'Without Query Strings',
        colorClass: EChartColor.Yellow,
        value: numPagesMissingQueryStrings
      }
    ];
  }

  formatDataForPagesWithRedirectsChart(numPagesScanned: number, data: IPageSummaryInsightsByPage): IDonutChartDataPoint[] {
    return [
      {
        name: 'Pages without Redirects',
        colorClass: EChartColor.Green,
        value: numPagesScanned - data?.metadata?.pagination.totalCount
      }, {
        name: 'Pages with Redirects',
        colorClass: EChartColor.Yellow,
        value: data?.metadata?.pagination.totalCount
      }
    ];
  }

  formatDataForPagesMissingAnalyticsChart(numPagesScanned: number, numPagesWithAnalytics: number): IDonutChartDataPoint[] {
    return [
      {
        name: 'With Analytics Tags',
        colorClass: EChartColor.Green,
        value: numPagesWithAnalytics
      }, {
        name: 'Without Analytics Tags',
        colorClass: EChartColor.Red,
        value: numPagesScanned - numPagesWithAnalytics
      }
    ];
  }

  formatDataForPagesMissingTagManagerChart(numPagesScanned: number, numPagesMissingTagManager: number): IDonutChartDataPoint[] {
    return [
      {
        name: 'With Tag Manager',
        colorClass: EChartColor.Green,
        value: numPagesScanned - numPagesMissingTagManager
      }, {
        name: 'Without Tag Manager',
        colorClass: EChartColor.Red,
        value: numPagesMissingTagManager
      }
    ];
  }

  /**
   * Retrieve data for cookie privacy
   */
  getCookiePrivacyInfo() {
    this.auditReportLoadingService.addLoadingToken();

    forkJoin([
      this.cookiePrivacyService.getCookiesPrivacySummary(this.auditId, this.runId, {})
        .pipe(catchError(e => {
          console.error('Error retrieving cookie privacy summary', e);
          return of(undefined);
        })),
      this.ccService.getConsentCategoriesAssignedToRun(this.auditId, this.runId)
        .pipe(catchError(e => {
          console.error('Failed to load consent cats assoc with cookie privacy summary', e);
          return of(undefined);
        })),
    ]).subscribe(([cookiePrivacySummary, assignedCCs]) => {

      this.auditReportLoadingService.removeLoadingToken();

      if (!cookiePrivacySummary || !assignedCCs) return;

      this.cookiePrivacySubject.next({
        ...cookiePrivacySummary,
        assignedCCCount: assignedCCs?.length || 0
      });
    });
  }

  /**
   * Retrieve data for cookie privacy
   */
  getNewJavascriptFilesInfo() {
    const apiFilter = {
      changeType: EJSFileChangeType.newFile,
    };

    this.auditReportLoadingService.addLoadingToken();

    this.discoveryAuditService.getAuditRuns(this.auditId)
      .then((runs) => {
        const getNewJavascriptFilesInfoRequest: Observable<any> = runs?.length === 0 || runs[runs?.length - 1]?.id == this.runId
          ? of('---')
          : this.jsFileChangesService.getSummary(this.auditId, this.runId, apiFilter);

        getNewJavascriptFilesInfoRequest
          .pipe(catchError(e => {
            console.error('Error retrieving new javascript files summary', e);
            return of(undefined);
          }))
          .subscribe((fileSummary) => {
            this.auditReportLoadingService.removeLoadingToken();

            if (!fileSummary) return;

            this.newJavascriptFilesSubject.next(fileSummary);
          });
      });
  }

  /**
   * Retrieve data for tag privacy
   */
  getTagPrivacyInfo() {
    this.auditReportLoadingService.addLoadingToken();

    forkJoin([
      this.tagPrivacyService.getTagPrivacySummary(this.auditId, this.runId, {})
        .pipe(catchError(e => {
          console.error('Error retrieving tag privacy summary', e);
          return of(undefined);
        })),
      this.ccService.getConsentCategoriesAssignedToRun(this.auditId, this.runId)
        .pipe(
          catchError(e => {
            console.error('Failed to load consent cats assoc with tag privacy summary', e);
            return of(undefined);
          })
        )
    ]).subscribe(([tagPrivacySummary, assignedCCs]) => {
      this.auditReportLoadingService.removeLoadingToken();

      if (!tagPrivacySummary || !assignedCCs) return;

      const tagPrivacyInfo = {
        ...tagPrivacySummary,
        assignedCCCount: assignedCCs?.length || 0,
      };

      this.tagPrivacySubject.next(tagPrivacyInfo);

    });
  }

  /**
   * Retrieve data for requests privacy
   */
  getRequestPrivacyInfo() {
    this.auditReportLoadingService.addLoadingToken();

    forkJoin([
      this.requestPrivacyService.getPrivacyRequestsSummary(this.auditId, this.runId, {})
        .pipe(catchError(e => {
          console.error('Error retrieving request privacy summary', e);
          return of(undefined);
        })),
      this.requestPrivacyService.getPrivacyRequestsSummary(this.auditId, this.runId, { consentCategoryComplianceStatus: EConsentCategoryComplianceStatus.Unapproved })
        .pipe(catchError(e => {
          console.error('Error retrieving unapproved request privacy summary', e);
          return of(undefined);
        })),
      this.ccService.getConsentCategoriesAssignedToRun(this.auditId, this.runId)
        .pipe(catchError(e => {
          console.error('Failed to load consent cats assoc with req privacy summary', e);
          return of(undefined);
        }))
    ]).subscribe(([totalRequestPrivacySummary, unapprovedRequestPrivacySummary, assignedCCs]) => {
      this.auditReportLoadingService.removeLoadingToken();

      if (!totalRequestPrivacySummary || !unapprovedRequestPrivacySummary || !assignedCCs) return;

      const totalUnapprovedRequestGeos = unapprovedRequestPrivacySummary?.filteredUniqueRequestGeoCount;
      const requestPrivacySummary = {
        ...totalRequestPrivacySummary,
        totalUnapprovedRequestGeos,
        assignedCCCount: assignedCCs?.length || 0
      };
      this.requestPrivacySubject.next(requestPrivacySummary);
    });
  }

  /**
   * Retrieve data for tag initiators
   */
  getTagInitiatorsData(): void {
    this.auditReportLoadingService.addLoadingToken();

    this.tagInitiatorsService
      .getTagInitiators(this.auditInfo?.domainFull, EReportType.AUDIT, this.runId)
      .pipe(catchError(e => {
        console.error('Error retrieving tag initiators data', e);
        return of(undefined);
      }))
      .subscribe((tagInitiatorsData) => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!tagInitiatorsData) return;

        const filteredInitiatorData = this.tagInitiatorsService.prepareTagInitiators(tagInitiatorsData);
        this.tagInitiatorsSubject.next(filteredInitiatorData);
      });
  }

  /**
   * Retrieve data for pages missing query strings
   */
  getPagesMissingQueryStringsData(): void {
    this.auditReportLoadingService.addLoadingToken();

    const params: ICommonTableState = {
      page: 0,
      size: 200,
      sortBy: CommonReportsPagesTableColumns.FinalPageUrl,
      sortDesc: false
    };

    const body = {
      finalPageUrl: {
        filterType: EPageUrlFilterType.Contains,
        filterValue: '?',
        negated: true
      }
    } as IAuditReportApiPostBody;

    this.pageSummaryService.getAuditPageSummaryInsightsByPage(this.auditId, this.runId, params, body).subscribe((res: IPageSummaryInsightsByPage) => {
      this.pagesMissingQueryStringsSubject.next(res);
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  /**
   * Retrieve data for pages missing a tag manager
   */
  getPagesMissingTagManagerData(): void {
    this.auditReportLoadingService.addLoadingToken();

    const params: ICommonTableState = {
      page: 0,
      size: 200,
      sortBy: CommonReportsPagesTableColumns.PageUrl,
      sortDesc: false
    };

    const body = {
      pagesWithoutTagCategoryId: 6,
    } as IAuditReportApiPostBody;

    this.tagInventoryService.getTagInventoryPages(this.auditId, this.runId, params, body).subscribe((res: ITagInventoryPages) => {
      this.pagesMissingTagManagerSubject.next(res);
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  /**
   * Retrieve summary for pages missing a tag manager
   */
  getTagInventoryMissingTagManagerData(): void {
    this.auditReportLoadingService.addLoadingToken();

    const body = {
      pagesWithoutTagCategoryId: 6,
    } as IAuditReportApiPostBody;

    this.tagInventoryService.getTagInventorySummary(this.auditId, this.runId, body)
      .pipe(catchError(e => {
        console.error('Error retrieving tag inventory missing tag manager data', e);
        return of(undefined);
      }))
      .subscribe((pagesMissingTagManagerSummary) => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!pagesMissingTagManagerSummary) return;

        this.tagInventorySummaryMissingTagManagerSubject.next(pagesMissingTagManagerSummary);
      });
  }

  /**
   * Retrieve data for pages missing a consent manager
   */
  getPagesMissingConsentManagerData(): void {
    this.auditReportLoadingService.addLoadingToken();

    const body = {
      pagesWithoutTagCategoryId: 14,
    } as IAuditReportApiPostBody;

    this.tagInventoryService.getTagInventorySummary(this.auditId, this.runId, body)
      .pipe(catchError(e => {
        console.error('Error retrieving tag inventory missing consent manager data', e);
        return of(undefined);
      }))
      .subscribe((pagesMissingConsentManagerSummary) => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!pagesMissingConsentManagerSummary) return;

        this.pagesMissingConsentManagerSubject.next(pagesMissingConsentManagerSummary);
      });
  }

  /**
   * Retrieve data for pages missing privacy policy links
   */
  getPagesMissingPrivacyPolicyLinksData(): void {
    this.auditReportLoadingService.addLoadingToken();

    this.ruleSummaryService.getRuleResults(this.auditId, this.runId, {})
      .pipe(catchError(e => {
        console.error('Error retrieving pages missing privacy policy links data', e);
        return of(undefined);
      }))
      .subscribe((res) => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!res) return;

        /**
         failedRulePageCount  // Page Count
         name // Rule name
         originalRuleId // Rule ID
         */
        let rule = res?.rules?.find((rule) => rule.name === PrivacyPolicyFoundRuleName);
        let failedRulePageCount = rule?.failedRulePageCount || '---';
        this.privacyPolicyRuleId = rule?.originalRuleId;
        this.pagesMissingPrivacyPolicyLinksSubject.next(failedRulePageCount);
      });
  }

  /**
   * Retrieve data for Pages missing Share/Sell Link Info
   */
  getPagesMissingShareSellLinkInfo() {
    this.auditReportLoadingService.addLoadingToken();

    this.ruleSummaryService.getRuleResults(this.auditId, this.runId, {})
      .pipe(catchError(e => {
        console.error('Error retrieving pages missing share/sell link info data', e);
        return of(undefined);
      }))
      .subscribe((res) => {
        this.auditReportLoadingService.removeLoadingToken();

        if (!res) return;

        /**
         failedRulePageCount  // Page Count
         name // Rule name
         originalRuleId // Rule ID
         */
        let rule = res?.rules?.find((rule) => rule.name === DoNotShareRuleName);
        let failedRulePageCount = rule?.failedRulePageCount || '---';
        this.doNotShareSellLinkRuleId = rule?.originalRuleId;
        this.pagesMissingShareSellLinkInfoSubject.next(failedRulePageCount);
      });
  }

  /**
   * Retrieve data for tag inventory summary
   */
  getTagInventorySummaryData(): void {
    this.auditReportLoadingService.addLoadingToken();

    this.tagInventoryService.getTagInventorySummary(this.auditId, this.runId, {}).subscribe((res: ITagInventorySummary) => {
      this.tagInventorySummarySubject.next(res);
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  openConsentCategoryManager(step: number, standardsTab?: EStandardsTabs): void {
    const data = {
      auditId: this.auditId,
      step,
      standardsTab,
    };

    this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor');
  }

  openAuditStandardsToCC(step: number, standardsTab?: EStandardsTabs): void {
    const data = {
      auditId: this.auditId,
      step,
      standardsTab,
    };

    this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor');
  }

  openDataLayerEditor(domain: IDomain): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);
    this.modalService.openModal(EditDataLayerModalComponent, { disableClose: true, data: { domain } }, 'edit-data-layer-modal')
      .afterClosed()
      .subscribe((updated: boolean) => {
        if (updated) this.getAuditInfo();
        this.modalEscapeService.remove(index);
      });
  }

  openInitiatorsFullScreen(data: ITagInitiatorNode): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(TagInitiatorsFullScreenComponent, {
      data: {
        initiatorsData: data
      }
    })
      .afterClosed()
      .subscribe(() => {
        this.modalEscapeService.remove(index);
      });
  }

  openAuditEditorToConsentCategories(): void {
    const data = {
      auditId: this.auditId,
      step: 1,
      standardsTab: EStandardsTabs.ConsentCategories
    };

    this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor');
  }

  @CacheApiResponse()
  getDomains(): Observable<IDomain[]> {
    const url = `${environment.apiUrl}domains`;
    return this.apiService.get<IDomain[]>(url);
  }
}
