<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="close()"
          [preventSimultaneousClose]="true">

  <ng-template #headerTemplate>
    <op-modal-header
      [title]="title"
      (onClose)="close()"
    ></op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <op-tabs [tabs]="tabs"
             [activeTab]="currentTab"
             [useRouting]="false"
             (tabClicked)="goToTab($event)"
    ></op-tabs>

    <form cdkScrollable [formGroup]="auditForm"
          class="audit-editor-form"
          [class.editor-setup-tab]="currentTab === auditTabs.testScenario"
          [class.editor-url-sources-tab]="currentTab === auditTabs.urlSources">
      <audit-setup-form formControlName="auditSetup"
                        [areUrlsLocked]="!!auditSetupValue?.scanPreviousRunUrlsOnly && hasRuns"
                        [class.hidden]="currentTab !== auditTabs.testScenario"
                        [submitted]="submitted"
                        [auditHasRuns]="hasRuns"
                        [sameUrlRunId]="sameUrlRunId"
                        [labels]="labels"
                        [auditId]="auditId"
                        [currentTab]="currentTab"
                        (onLocationChanged)="onLocationChanged($event)"
                        (onVPNChanged)="onVPNChanged($event)"
                        (onAddCmpAction)="handleAddCmpAction()"
      ></audit-setup-form>

      <op-url-sources formControlName="urlSources"
                      [class.hidden]="currentTab !== auditTabs.urlSources"
                      [areUrlsLocked]="!!urlSourcesValue?.scanPreviousRunUrlsOnly && hasRuns"
                      [loading]="loading"
                      [lockUrlsPrevRunDate]="lockUrlsPrevRunDate"
                      [runDateFormat]="runDateFormat"
                      [submitted]="submitted"
                      [auditHasRuns]="hasRuns"
                      [sameUrlRunId]="sameUrlRunId"
                      [auditId]="auditId"
                      (onFrequencyChanged)="onFrequencyChanged($event)"
                      (onLocationChanged)="onLocationChanged($event)"
                      (onVPNChanged)="onVPNChanged($event)"
                      (onUpdateScanLimit)="onUpdateScanLimit($event)"
                      (onRunDateChanged)="handleSaveBtnText()"
                      (onRunTimeChanged)="handleSaveBtnText()"
                      (onShowInvalidUrls)="showWrongUrls()"
      ></op-url-sources>

      <standards-tab *ngIf="audit"
                     [activeTab]="standardsTab"
                     [class.hidden]="currentTab !== auditTabs.standards"
                     [selectedConsentCats]="assignedConsentCategories"
                     [selectedRules]="assignedRules"
                     [selectedAlerts]="assignedAlerts"
                     [privacyEnabled]="privacyEnabled"
                     [explanationText]="explanationText"
                     [learnMoreLink]="learnMoreLink"
                     [auditId]="auditId || 0"
                     [runId]="audit.runId"
                     [user]="user"
                     (consentCatsUpdated)="updateConsentCats($event)"
                     (rulesUpdated)="updateTagAndVariableRules($event)"
                     (alertsUpdated)="updateAlerts($event)">
      </standards-tab>

      <div class="pre-audit-actions-tab" *ngIf="currentTab === auditTabs.userSession">
        <div class="help-text">
          Specify any actions that should be executed before the audit starts visiting pages. Use this to set cookies for privacy testing,
          automatically set Consent Management (CMP) state, configure personalization parameters, perform user logins, or
          configure various user states. The first action must navigate to a page so the audit knows where
          to perform the actions. <a class="help-text-link" [href]="LearnMoreLinks.PreAuditActionsLearnMore" target="_blank">Learn more.</a>
        </div>
        <actions-creator #userSessionCreator
                         formControlName="userSession"
                         [mode]="actionsModes.AuditUserSession"
                         [submitted]="submitted"
                         [leftColHeading]="'Pre-action List (Optional)'"
                         (onActionSetCreated)="updateActionsForm($event, 'userSession')">
        </actions-creator>
      </div>

      <div class="audit-actions-tab" *ngIf="currentTab === auditTabs.actions">
        <div class="help-text">
          Specify actions that should be executed after loading pages. This is typically used to verify that
          on click events are being properly measured or collecting custom data using the <a class="help-text-link" [href]="LearnMoreLinks.ObservepointCustomTagLearnMore" target="_blank">ObservePoint Custom Tag.</a> Learn more on how to use <a class="help-text-link" [href]="LearnMoreLinks.AuditActionsLearnMore" target="_blank">On-Page Actions.</a>
        </div>
        <actions-creator #actionsCreator
                         formControlName="actions"
                         [mode]="actionsModes.AuditActions"
                         [submitted]="submitted"
                         [leftColHeading]="'Action List (Optional)'"
                         (onActionSetCreated)="updateActionsForm($event, 'actions')">
        </actions-creator>
      </div>
    </form>
    <div class="form-error" *ngIf="auditForm.invalid && this.submitted">
      <div class="form-error-bg">
        <div class="form-error-content">
          <mat-icon class="error-icon">warning_amber</mat-icon>
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </ng-template>
</op-modal>
