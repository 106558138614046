<div class="top-bar-wrap">
  <div class="top-bar-group top-bar-group-left">
    <button mat-icon-button (click)="sidebarToggle.emit()" class="left-menu-icon-button button-icon">
      <mat-icon class="left-menu-icon">menu</mat-icon>
    </button>
    <button mat-icon-button (click)="goToManageSection()" class="op-logo-button button-icon">
      <i class="icon-op-logo"></i>
    </button>
  </div>
  <div class="top-bar-group top-bar-group-middle" *ngIf="!isInAdminPortal">
    <account-search></account-search>
  </div>
  <div class="top-bar-group top-bar-group-right">
    <button mat-icon-button class="button-icon" *ngIf="!isInAdminPortal && userIsOpAdmin" (click)="goToAdminPortal()">
      <mat-icon>web</mat-icon>
    </button>

    <!-- menu 1 (question mark icon) -->
    <button mat-icon-button class="button-icon" [matMenuTriggerFor]="menu1" *ngIf="!isInAdminPortal">
      <mat-icon>help</mat-icon>
    </button>
    <mat-menu #menu1="matMenu" class="top-bar-menu">
      <button mat-menu-item class="button-icon" (click)="openHelpDocumentation()">
        <mat-icon>menu_book</mat-icon> Help Documentation
      </button>
      <button mat-menu-item class="button-icon" (click)="openAcademyTraining()">
        <mat-icon>school</mat-icon> Academy Training
      </button>
      <button mat-menu-item class="button-icon" (click)="goToFeedbackPortal()">
        <mat-icon>emoji_objects</mat-icon> Product Updates & Feedback
      </button>
    </mat-menu>

    <!-- menu 2 (rubiks cube icon) -->
    <button mat-icon-button class="button-icon" [matMenuTriggerFor]="menu2" *ngIf="!isInAdminPortal">
      <mat-icon>apps</mat-icon>
    </button>
    <mat-menu #menu2="matMenu" class="top-bar-menu">
      <button mat-menu-item class="button-icon" (click)="goToAccountUsers()" *ngIf="userIsAdmin">
        <mat-icon>group</mat-icon> Account Users
      </button>
      <button mat-menu-item class="button-icon" (click)="goToTagSetup()">
        <mat-icon>loyalty</mat-icon> Tag Setup
      </button>
      <button mat-menu-item class="button-icon" (click)="goToBulkOperations()" *ngIf="userIsAdmin">
        <mat-icon>dashboard</mat-icon> Bulk Operations
      </button>
      <button mat-menu-item class="button-icon" (click)="goToEventLog()" *ngIf="userIsAdmin">
        <mat-icon>data_usage</mat-icon> Event Log
      </button>
      <mat-divider class="top-bar-menu-divider"></mat-divider>
      <button mat-menu-item class="button-icon" (click)="goToTagDatabase()">
        <mat-icon>local_offer</mat-icon> Tag Database
      </button>
      <button mat-menu-item class="button-icon" (click)="goToScriptServices()">
        <mat-icon>code</mat-icon> Web Journey Support
      </button>
    </mat-menu>

    <!-- menu 3 (user icon) -->
    <button mat-icon-button class="button-icon" [matMenuTriggerFor]="menu3">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu3="matMenu" class="top-bar-menu">
      <button mat-menu-item class="button-icon" (click)="goToUserProfile()" *ngIf="!isInAdminPortal">
        <mat-icon>person</mat-icon> Hi, {{ userGreeting }}
      </button>
      <button mat-menu-item class="button-icon" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon> Sign Out
      </button>
    </mat-menu>

  </div>
</div>
