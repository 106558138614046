export enum EManageAccountsFilterTypes {
  Search = 'search',
  Type = 'type',
  Status = 'status',
  Order = 'order',
}

export enum EManageAccountStatusV2Value {
  'Active' = 'ACTIVE',
  'Active Billing Problem' = 'ACTIVE_BILLING_PROBLEM',
  'Inactive Billing Failed' = 'INACTIVE_BILLING_FAILED',
  'Inactive User Cancelled' = 'INACTIVE_USER_CANCELLED',
  'Inactive' = 'INACTIVE',
  'Demo' = 'DEMO',
}

export enum EManageAccountStatusDisplayValue {
  ACTIVE = 'Active',
  ACTIVE_BILLING_PROBLEM = 'Active Billing Problem',
  INACTIVE_BILLING_FAILED = 'Inactive Billing Failed',
  INACTIVE_USER_CANCELLED = 'Inactive User Cancelled',
  INACTIVE = 'Inactive',
  DEMO = 'Demo',
}

export enum EManageAccountStatus {
  Active = 'ACTIVE',
  ActiveBillingProblem = 'ACTIVE_BILLING_PROBLEM',
  InactiveBillingFailed = 'INACTIVE_BILLING_FAILED',
  InactiveUserCancelled = 'INACTIVE_USER_CANCELLED',
  Inactive = 'INACTIVE',
  Demo = 'DEMO',
}

export interface IManageAccountsFilter {
  type: EManageAccountsFilterTypes;
  display: string;
  value: number | string | object | boolean;
}
