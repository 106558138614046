<form [formGroup]="auditSetupForm"
      formName="auditSetupForm"
      validate-form
      class="audit-setup-form"
      [submitted]="submitted">
  <div class="audit-setup-center-text">Use an Audit to run a repeatable test across a website to look for incorrect implementations of marketing technologies, broken or poor experiences, privacy issues, and many other use cases. <a class="learn-more-link" href="{{LEARN_MORE.audit}}" target="_blank">Learn more.</a></div>
  <div class="two-controls">
    <div class="form-input name-wrp"
         #nameInput
         (mouseleave)="hideHint(nameInput)"
         (mouseenter)="displayHint(nameInput)">
      <mat-form-field appearance="outline" floatLabel="always"
                      [attr.op-selector]="OP_SELECTORS.name">
        <mat-label>{{FIELD_LABELS.NAME}}</mat-label>
        <input matInput formControlName="name"/>
        <mat-hint *ngIf="!auditSetupForm?.controls?.name?.invalid" class="field-hint">Name what this audit will be validating</mat-hint>
      </mat-form-field>
      <op-error-message formControlPath="auditSetupForm.name"></op-error-message>
    </div>
    <div class="form-input no-bottom-padding">
      <op-folder-and-sub-folder-field formControlName="folderData"
                                   [itemType]="EDataSourceType.Audit"
                                   [submitted]="submitted"
      >
      </op-folder-and-sub-folder-field>
    </div>
  </div>

  <div class="form-input request-rate"
       #requestRateSelect
       (mouseleave)="hideHint(requestRateSelect)"
       (mouseenter)="displayHint(requestRateSelect)">
    <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.auditSpeed">
      <mat-label>{{FIELD_LABELS.REQUEST_RATE}}</mat-label>
      <mat-select formControlName="requestRate" >
        <mat-option *ngFor="let rate of REQUEST_RATES" [value]="rate.rate">{{ rate.label }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-hint class="field-hint">Make sure our IP addresses have been whitelisted for your domain. Additional browsers may trigger your IT security alarms. <a class="learn-more-link" href="{{LEARN_MORE.requestRate}}" target="_blank">Learn more.</a></mat-hint>
  </div>

  <div class="additional-setup-options-container">
    <div class="two-controls">
      <div class="form-input labels-control"
           [attr.op-selector]="OP_SELECTORS.labels"
      >
        <div class="label-manager-wrap">
          <div class="faux-form-label">Labels</div>
          <op-label-manager [selectedLabels]="selectedLabels"
                            (onLabelCreated)="handleLabelCreated($event)"
                            (onLabelSelected)="handleLabelSelected($event)"
                            (onLabelRemoved)="handleLabelRemoved($event)"
          >
          </op-label-manager>
          <div class="field-hint">Add labels to quickly categorize and identify your audit. <a class="learn-more-link" href="{{LEARN_MORE.labels}}" target="_blank">Learn more.</a></div>
        </div>
      </div>

      <div class="form-input"
           #proxySupportSelect
           (mouseleave)="hideHint(proxySupportSelect)"
           (mouseenter)="displayHint(proxySupportSelect)"
           [matTooltip]="vpn.value ? 'This Audit is configured to use your VPN location.' : null"
           matTooltipPosition="above"
      >
        <mat-form-field floatLabel="always"
                        class="no-hint-container"
                        appearance="outline"
                        [attr.op-selector]="OP_SELECTORS.customVpn"
                        *ngIf="customProxySupport && customProxy.value"
          >
          <mat-label>{{FIELD_LABELS.CUSTOM_PROXY}}</mat-label>
          <input matInput formControlName="location" placeholder="ie, http://90.90.90.90:8080">
        </mat-form-field>

        <!-- LOCATION FIELD (proxy) -->
        <mat-form-field appearance="outline"
                        class="no-hint-container"
                        [attr.op-selector]="OP_SELECTORS.vpn"
                        *ngIf="!customProxySupport || (customProxySupport && !customProxy.value)"
                        floatLabel="always"
        >
          <mat-label>{{FIELD_LABELS.RUN_FROM}}</mat-label>
          <mat-select [formControl]="location" #locationSelect panelClass="location-drop-down-panel">
            <mat-select-trigger class="location-input">
              <div class="flag-icon flag-icon-{{selectedLocation?.countryCode}}"></div>
              <div class="location-label">{{selectedLocation?.label}}</div>
            </mat-select-trigger>
            <mat-option class="location-search-field">
              <ngx-mat-select-search [formControl]="locationFilterCtrl" placeholderLabel="Search"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let location of filteredLocations | async" [value]="location.name" class="proxy-autocomplete">
              <div>
                <div class="flag-icon flag-icon-{{location.countryCode}}"></div>
                <div class="location-label">{{ location.label }}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span class="field-hint" [class.feature-disabled]="vpn.value">Changing this is useful if there are geo-based content or restrictions on the site. <span class="learn-more-link" (click)="onLocationChange(true)" *ngIf="customProxySupport && !this.customProxy.value">A custom proxy can also be used.</span><span class="learn-more-link" (click)="onLocationChange(false)" *ngIf="customProxySupport && this.customProxy.value">Select an existing location.</span>&nbsp;&nbsp;<a *ngIf="!customProxySupport || !this.customProxy.value"class="learn-more-link" href="{{LEARN_MORE.vpn}}" target="_blank">Learn more.</a><a *ngIf="customProxySupport && this.customProxy.value" class="learn-more-link" href="{{LEARN_MORE.customProxy}}" target="_blank">Learn more.</a></span>
      </div>
    </div>

    <div class="two-controls user-agent-width-height">
      <div class="form-input"
           #userAgentSelect
           (mouseleave)="hideHint(userAgentSelect)"
           (mouseenter)="displayHint(userAgentSelect)">
        <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.userAgent">
          <mat-label>{{FIELD_LABELS.USER_AGENT}}</mat-label>
          <mat-select formControlName="userAgent"
                      [panelClass]="'user-agent-popover'"
          >
            <mat-option *ngFor="let agent of userAgents" [value]="agent.name">{{ agent.label }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-hint class="field-hint">Specify a user agent. <a class="learn-more-link" [href]="LEARN_MORE.userAgents" target="_blank">Learn More</a>.</mat-hint>
      </div>
      <div class="form-input"
           #browserHeightInput
           (mouseleave)="hideHint(browserHeightInput)"
           (mouseenter)="displayHint(browserHeightInput)">
        <div class="two-controls size-inputs">
          <div class="form-input nested-form-input override-input">
            <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.browserWidth">
              <mat-label>{{FIELD_LABELS.WIDTH}}</mat-label>
              <input matInput type="number" formControlName="browserWidth" min="0" max="3840">
            </mat-form-field>
            <op-error-message *ngIf="browserWidth.errors" formControlPath="auditSetupForm.browserWidth"></op-error-message>
          </div>

          <div class="form-input nested-form-input override-input">
            <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.browserHeight">
              <mat-label>{{FIELD_LABELS.HEIGHT}}</mat-label>
              <input matInput type="number" formControlName="browserHeight" min="300" max="4000">
            </mat-form-field>
            <op-error-message *ngIf="browserHeight.errors" formControlPath="auditSetupForm.browserHeight"></op-error-message>
          </div>
        </div>
        <mat-hint *ngIf="!auditSetupForm.controls.browserWidth.invalid && !auditSetupForm.controls.browserHeight.invalid" class="field-hint">Specify custom width and height of the browser window. This is typically used in conjunction with a mobile browser user agent.</mat-hint>
      </div>
    </div>

    <easy-block-tags class="easy-block-tags" formControlName="easyBlockTags"></easy-block-tags>

    <div class="two-controls">
      <div class="form-input no-bottom-padding file-substitution-grouping"
           #rfmSelect
           (mouseleave)="hideHint(rfmSelect)"
           (mouseenter)="displayHint(rfmSelect)">
        <mat-form-field appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.rfm">
          <mat-label>{{FIELD_LABELS.RFM}}</mat-label>
          <mat-select formControlName="rfmConfig"
                      [compareWith]="compareObjectsWithIds"
                      multiple
                      [matTooltip]="getRfmTooltip()"
                      matTooltipPosition="above"
                      matTooltipShowDelay="500"
          >
            <mat-option *ngFor="let mapping of remoteFileMappings" [value]="mapping">{{ mapping.name }}</mat-option>
          </mat-select>
          <mat-hint class="field-hint">Substitute one or more files on pages visited. <button class="learn-more-link" type="button" (click)="createNewFileSubstition()">Create New File Substitution</button>.  <a class="learn-more-link" [href]="LEARN_MORE.rfm" target="_blank">Learn More</a>.</mat-hint>
        </mat-form-field>
      </div>
      <div class="form-input option-toggles"
           [attr.op-selector]="OP_SELECTORS.vpnToggle"
           [matTooltip]="!vpnSupport ? 'This feature requires VPN support to be enabled' : location.value !== 'mountain' && !vpn.value ? 'VPN can only be enabled on Audits running from USA, Oregon' : null"
           matTooltipPosition="above">
        <mat-slide-toggle formControlName="vpn"
                          (change)="vpnChange($event)"
                          color="primary"
                          class="feature-toggle">
          <span class="toggle-label">VPN Mode</span> (Allows outside access to secure content on your website) <a class="learn-more-link" href="{{LEARN_MORE.vpn}}" target="_blank">Learn more.</a></mat-slide-toggle>
      </div>
    </div>

    <div class="privacy-section more-top-margin">
      <h2 class="section-title">Privacy Settings</h2>
      <div class="two-controls ">
        <div class="form-input"
             [attr.op-selector]="OP_SELECTORS.gpcToggle"
             matTooltip="This feature requires 'Product Line: Privacy' to be enabled"
             [matTooltipDisabled]="privacyFeatureEnabled"
             matTooltipPosition="above">
          <mat-slide-toggle formControlName="gpc"
                            color="primary"
                            class="feature-toggle">
            <span class="toggle-label">Send GPC Signal</span> (On all pages visited, the ObservePoint browser will send GPC signal of true.) <a class="learn-more-link" href="{{LEARN_MORE.gpc}}" target="_blank">Learn more.</a></mat-slide-toggle>
        </div>
        <div class="cmp-config">
          <span class="toggle-label">CMP Accept All / Reject All</span> Consent management "Accept All" or "Reject All" is configured on the Pre-Audit Actions tab.
          <button (click)="onAddCmpAction.emit()" class="add-cmp-action-btn">
            Add the action now <mat-icon class="arrow-icon">arrow_forward</mat-icon>
          </button>
        </div>
      </div>
      <div class="two-controls">
        <div class="form-input"
             [attr.op-selector]="OP_SELECTORS.blockThirdPartyCookiesToggle"
        >
          <mat-slide-toggle formControlName="blockThirdPartyCookies"
                            color="primary"
                            class="feature-toggle">
            <span class="toggle-label">Block 3rd Party Cookies</span> (Enable this setting to block all 3rd party cookies.)</mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>

  <div class="form-input no-bottom-padding">
    <mat-label class="form-label">Audit Notifications</mat-label>
    <div class="form-text"><em>Who should be notified when audit runs complete? One email per line.</em> (Any added rules with notifications will also get emails sent out when failures are found.)</div>
    <mat-form-field appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.notificationList">
      <mat-label>{{FIELD_LABELS.NOTIFICATIONS}}</mat-label>
      <textarea class="vertical-resize" matInput rows="2" formControlName="recipients"></textarea>
    </mat-form-field>
    <op-error-message formControlPath="auditSetupForm.recipients" relativePosition="true"></op-error-message>
  </div>

  <div class="form-input webhook-container"
       #webHookInput
       (mouseleave)="hideHint(webHookInput)"
       (mouseenter)="displayHint(webHookInput)">
    <mat-form-field appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.webhook">
      <mat-label>Complete Webhook URL</mat-label>
      <input matInput
             type="text"
             formControlName="webHookUrl">
      <mat-hint *ngIf="!auditSetupForm.controls.webHookUrl.invalid" class="field-hint">Add a webhook URL.&nbsp;<a class="learn-more-link" href="{{LEARN_MORE.webhook}}" target="_blank">Learn more.</a></mat-hint>
    </mat-form-field>
    <op-error-message formControlPath="auditSetupForm.webHookUrl"></op-error-message>
  </div>
</form>
