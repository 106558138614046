import { switchMap } from 'rxjs/operators';
import { ComparisonLibraryUrlBuilders } from './../../comparison-library/comparison-library.constants';
import { Component, OnInit, Input } from '@angular/core';
import { RorComparisonReportService } from '../ror-comparison-report.service';
import { IAuditDataService } from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-comparison-no-results',
  templateUrl: './ror-comparison-no-results.component.html',
  styleUrls: ['./ror-comparison-no-results.component.scss']
})
export class RorComparisonNoResultsComponent implements OnInit {

  @Input() auditId: number;
  @Input() runId: number;

  loaded = false;

  comparisonAssigned = false;
  hasComparisonRun = false; 

  constructor(private router: Router,
              private comparisonReportService: RorComparisonReportService,
              private auditDataService: IAuditDataService) { }

  ngOnInit() {
    forkJoin([
      this.comparisonReportService.getAssignedComparisons(this.auditId),
      this.comparisonReportService.getAssignedComparisonRuns(this.auditId, this.runId)
    ]).subscribe(([assignedComparisons, comparisonRuns]) => {
      this.comparisonAssigned = assignedComparisons.length > 0; 
      this.hasComparisonRun = comparisonRuns.length > 0;
      this.loaded = true;
    });
  }

  runAudit(): void {
    this.auditDataService.getAudit(this.auditId).then(audit => {
      this.auditDataService.runNow(audit).then(res => {
        this.router.navigateByUrl(this.router.url);
      });
    });
  }

  goToComparisonLibrary(): void {
    this.router.navigateByUrl(ComparisonLibraryUrlBuilders.library());
  }

}
