import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionSetLibraryService } from '../action-set-library.service';
import { SelectionModel } from '@angular/cdk/collections';
import { forkJoin } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { IActionSet, IMigrateActionsItem, IMigratedActions } from '../action-set-library.models';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import { IAuditModel } from '@app/components/modals/modalData';
import { IWebJourneyV3 } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { AccountsService } from '@app/components/account/account.service';
import { IUser } from '@app/moonbeamModels';
import { itemTypes } from './migrate-actions.constants';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IMigrateActionsTableRow, IMigrateActionsPayload } from './migrate-actions.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'migrate-actions',
  templateUrl: './migrate-actions.component.html',
  styleUrls: ['./migrate-actions.component.scss']
})
export class MigrateActionsComponent implements OnInit {

  showTable = false;
  showNoItems = false;
  showError = false;
  displayedColumns: string[] = ['select', 'name', 'ownerName', 'itemType'];
  dataSource = new MatTableDataSource;
  selection = new SelectionModel(true, []);
  actionSet: IActionSet;
  confirmDelete = false;

  numAuditActions: number;
  numAuditUserSessions: number;
  numJourneys:number

  rightFooterButtons = [
    {
      label: 'Save',
      action: () => {
        this.saveMigration();
      },
      primary: true,
      disabled: true
    }
  ];

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<MigrateActionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private actionSetService: ActionSetLibraryService,
    private discoveryAuditService: DiscoveryAuditService,
    private webJourneyService: WebJourneyV3Service,
    private accountsService: AccountsService
  ) {
    this.actionSet = { ...this.data };
  }

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    forkJoin(
      this.actionSetService.getMigration(this.actionSet.id),
      this.discoveryAuditService.getAudits(),
      this.webJourneyService.getJourneys(),
      this.accountsService.getUsers()
    ).subscribe(
      ([{auditsWithMatchedActions, auditsWithMatchedLoginActions, journeys}, allAudits, allJourneys, users]) => {
        const matchingAuditActions = auditsWithMatchedActions
          ? this.filterForMatchingAudits(auditsWithMatchedActions, allAudits)
          : [];

        const matchingAuditUserSessions = auditsWithMatchedLoginActions
          ? this.filterForMatchingAudits(auditsWithMatchedLoginActions, allAudits)
          : [];

        const matchingJourneys = journeys ? this.filterForMatchingJourneys(journeys, allJourneys) : [];

        this.initTableData(matchingAuditActions, matchingAuditUserSessions, matchingJourneys, users);
      },
      error => {
        this.showError = true;
        this.updateModalButtons();
      }
    );
  }

  initTableData(auditActions: IAuditModel[], auditUserSessions: IAuditModel[], journeys: IWebJourneyV3[], users: IUser[]): void {
    this.numAuditActions = auditActions.length;
    this.numAuditUserSessions = auditUserSessions.length;
    this.numJourneys = journeys.length;

    const auditActionsData: IMigrateActionsTableRow[] = auditActions.map((audit: IAuditModel) => {
      return {
        id: audit.id,
        name: audit.name,
        ownerName: this.getOwnerName(audit.ownerId, users),
        itemType: itemTypes.AuditActions
      };
    });

    const auditUserSessionsData: IMigrateActionsTableRow[] = auditUserSessions.map((audit: IAuditModel) => {
      return {
        id: audit.id,
        name: audit.name,
        ownerName: this.getOwnerName(audit.ownerId, users),
        itemType: itemTypes.AuditUserSessions
      };
    });

    const journeyData: IMigrateActionsTableRow[] = journeys.map((journey: IWebJourneyV3) => {
      return {
        id: journey.id,
        name: journey.name,
        ownerName: this.getOwnerName(journey.userId, users),
        itemType: itemTypes.Journey
      };
    });

    this.dataSource.data = journeyData
      .concat(auditActionsData, auditUserSessionsData)
      .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    this.dataSource.data.forEach(row => { this.selection.select(row); });
    this.dataSource.sort = this.sort;

    if(this.dataSource.data.length) {
      this.showTable = true
    } else {
      this.showNoItems = true;
      this.updateModalButtons();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  filterForMatchingAudits(audits: IMigrateActionsItem[], allAudits: IAuditModel[]): IAuditModel[] {
    const auditIds = audits.map((audit: IMigrateActionsItem) => audit.id);
    return allAudits.filter((audit: IAuditModel) => auditIds.includes(audit.id));
  }

  filterForMatchingJourneys(journeys: IMigrateActionsItem[], allJourneys: IWebJourneyV3[]): IWebJourneyV3[] {
    const journeyIds = journeys.map((journey: IMigrateActionsItem) => journey.id);
    return allJourneys.filter((journey: IWebJourneyV3) => journeyIds.includes(journey.id));
  }

  getOwnerName(userId: number, users: IUser[]): string {
    const user = users.find(user => user.id === userId);

    if(user && user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }

    return (user && user.username) ? user.username : 'Unavailable';
  }

  updateSaveButtonStatus(): void {
    this.rightFooterButtons[0].disabled = !this.confirmDelete || !this.selection.hasValue();
  }

  saveMigration(): void {
    this.dialogRef.close({
      actionSetId: this.actionSet.id,
      itemsToMigrate: this.formatItemsToMigrate()
    });
  }

  formatItemsToMigrate(): IMigratedActions {
    const auditsWithActions: IMigrateActionsTableRow[] = this.selection.selected.filter(item => item.itemType === itemTypes.AuditActions);
    const auditsWithUserSessions: IMigrateActionsTableRow[] = this.selection.selected.filter(item => item.itemType === itemTypes.AuditUserSessions);
    const journeys: IMigrateActionsTableRow[] = this.selection.selected.filter(item => item.itemType === itemTypes.Journey);

    return {
      auditsWithMatchedActions: auditsWithActions.map((audit: IMigrateActionsTableRow) => audit.id),
      auditsWithMatchedLoginActions: auditsWithUserSessions.map((audit: IMigrateActionsTableRow) => audit.id),
      journeys: journeys.map((journey: IMigrateActionsTableRow) => journey.id)
    };
  }

  onMasterToggleChange($event: MatCheckboxChange): void {
    if($event) this.masterToggle();
    this.updateSaveButtonStatus();
  }

  onCheckboxChange($event: MatCheckboxChange, row: IMigrateActionsTableRow): void {
    if($event) this.selection.toggle(row);
    this.updateSaveButtonStatus();
  }

  closeModal(migrationData?: IMigrateActionsPayload): void {
    this.dialogRef.close(migrationData);
  }

  private updateModalButtons() {
    this.rightFooterButtons[0].disabled = false;
    this.rightFooterButtons[0].label = 'OK';
    this.rightFooterButtons[0].action = () => {
      this.closeModal();
    }
  }
}
