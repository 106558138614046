import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import { IWebJourneyV3 } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { showNotesKey } from './web-journey-notes.constants';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { KeyboardShortcutsService } from '../../shared/services/keyboard-shortcuts/keyboard-shortcuts.service';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { WebJourneyReportService } from '@app/components/web-journey-report/web-journey-report.service';
import { DEFAULT_CUSTOM_PROXY } from '../web-journey-setup-form/web-journey-setup-form.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'web-journey-notes',
  templateUrl: './web-journey-notes.component.html',
  styleUrls: ['./web-journey-notes.component.scss']
})
export class WebJourneyNotesComponent implements OnInit, AfterViewInit {

  showNotes = true;
  notes: string = '';
  journey: IWebJourneyV3;
  saveNotes$ = new Subject<string>();
  edited = false;
  saved = true;
  saveError = false;
  isKBShortcutRegistered = false;

  private destroy$ = new Subject();

  @Input() journeyId: number;

  @ViewChild('notesBox') notesBox: ElementRef;

  constructor(
    private webJourneyService: WebJourneyV3Service,
    private snackbarService: SnackbarService,
    private storageService: StorageService,
    private keyboardShortcutsService: KeyboardShortcutsService,
    private webJourneyReportService: WebJourneyReportService
  ) {
    const localStorageShowNotes: boolean = this.storageService.getValue(showNotesKey, StorageType.Local);

    localStorageShowNotes === null
      ? this.setShowNotesInLocalStorage(this.showNotes)
      : this.showNotes = localStorageShowNotes;
  }

  ngOnInit(): void {
    this.webJourneyReportService.webJourney$.pipe(takeUntil(this.destroy$)).subscribe((journey: IWebJourneyV3) => {
      this.journey = journey;
      this.notes = this.journey.notes || '';
    });

    this.saveNotes$.pipe(debounceTime(750)).subscribe(() => {
      this.saveJourneyNotes();
    });
  }

  ngAfterViewInit(): void {
    this.registerKBShortcut();
  }

  saveJourneyNotes(): void {
    const journey = {
      ...this.journey,
      notes: this.notes,
      location: this.journey.customProxy ? DEFAULT_CUSTOM_PROXY : this.journey.location
    };


    this.webJourneyService.updateJourney(journey).subscribe(
      (journey: IWebJourneyV3) => {
        this.saved = true;
      },
      _ => {
        this.saveError = true;

        this.snackbarService.openErrorSnackbar('Error: Could not save notes. Please refresh the page and try again.');
      }
    );
  }

  onNotesUpdated(): void {
    this.saved = false;
    this.edited = true;
    this.saveNotes$.next();
  }

  toggleShowHideNotes() {
    this.showNotes = !this.showNotes;
    this.setShowNotesInLocalStorage(this.showNotes);
    this.registerKBShortcut();
  }

  registerKBShortcut(): void {
    if (this.isKBShortcutRegistered || !this.showNotes) return;
    this.isKBShortcutRegistered = true;
    setTimeout(() => this.keyboardShortcutsService.registerNotesBox(this.notesBox.nativeElement), 0);
  }

  setShowNotesInLocalStorage(value: boolean): void {
    this.storageService.setValue(showNotesKey, value, StorageType.Local);
  }
}
