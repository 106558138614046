import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { userIsOPAdmin } from '@app/authUtils';
import { IUser } from '@app/moonbeamModels';
import { AccountsService } from '../account/account.service';
import { DataSourcesUrlBuilders } from '../manage/cards/manage-cards.constants';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ERecurrenceItemType, ERecurrencePresetNames } from '../shared/components/op-recurrence/op-recurrence.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'set-cookies',
  templateUrl: './set-cookies.component.html',
  styleUrls: ['./set-cookies.component.scss']
})
export class SetCookiesComponent implements OnInit {

  frequencyFieldLabel: string = 'How often should the journey run?';
  dummyForm: FormGroup;
  dummySchedule = {
    schedule: {
      tzId: "America/Denver",
      isPaused: false,

      /**
       * custom recurring monthly
       */
      // dtStart: new Date('2024-09-17T07:15:00'), // Set to September 17, 2024
      // recurrenceRule: "FREQ=MONTHLY;BYDAY=3TU",
      // presetType: ERecurrencePresetNames.Custom,
      // description: "Every 3rd Tuesday of the month",

      /**
       * custom recurring by minute
       */
      // dtStart: new Date('2024-09-17T07:15:00'), // Set to September 17, 2024
      // recurrenceRule: "FREQ=MINUTELY;INTERVAL=27",
      // presetType: ERecurrencePresetNames.Custom,
      // description: "Every 27 minutes",

      /**
       * custom one-time in the past
       */
      // dtStart: new Date('2023-01-01T00:00:00'), // Set to a past date
      // recurrenceRule: "FREQ=DAILY;COUNT=1;UNTIL=20230102T000000Z",
      // presetType: ERecurrencePresetNames.Custom,
      // description: "Once on January 1, 2023",

      /**
       * custom monthly by day
       */
      // dtStart: new Date(),
      // recurrenceRule: "FREQ=MONTHLY;BYDAY=2FR",
      // presetType: ERecurrencePresetNames.Custom,
      // description: "every month on the 2nd Friday",

      /**
       * custom monthly by date
       */
      // dtStart: new Date(),
      // recurrenceRule: "FREQ=MONTHLY;BYMONTHDAY=14",
      // presetType: ERecurrencePresetNames.Custom,
      // description: "every month on the 14th",

      /**
       * preset daily - custom weekly
       */
      // dtStart: new Date(),
      // recurrenceRule: "FREQ=WEEKLY;BYDAY=MO,WE,FR",
      // presetType: ERecurrencePresetNames.Custom,
      // description: "every week on Monday, Wednesday, and Friday",

      /**
       * preset daily - preset
       */
      // dtStart: new Date(),
      // recurrenceRule: "WKST=SU;FREQ=DAILY;INTERVAL=1",
      // presetType: ERecurrencePresetNames.Daily,
      // description: `Every day at ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,

      /**
       * create mew
       */
      dtStart: new Date(),
      presetType: ERecurrencePresetNames.Daily,
    }
  };

  readonly ERecurrenceItemType = ERecurrenceItemType;

  constructor(
    private accountsService: AccountsService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
    this.accountsService.getUser().subscribe((user: IUser) => {
      if (!userIsOPAdmin(user)) {
        this.router.navigateByUrl(DataSourcesUrlBuilders.base());
      }
    });
  }

  ngOnInit(): void {
    // this represents the audit or journey form that will use the recurrence component
    this.dummyForm = new FormGroup({
      recurrence: new FormControl(null)
    });
  }

  submit(): void {
    console.log(this.dummyForm.value);
    console.table(this.recurrenceFormField.value.frequency);
  }

  setUseCasesCookie(): void {
    document.cookie = 'show_use_case_overviews = true; max-age=8640000';
    this.snackbar.open('Cookie set!', null, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  deleteUseCasesCookie(): void {
    document.cookie = 'show_use_case_overviews = ""; Max-Age=-99999999;';
    this.snackbar.open('Cookie deleted!', null, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  get recurrenceFormField(): AbstractControl {
    return this.dummyForm.get('recurrence');
  }
}
