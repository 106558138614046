import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDeviceCategory } from '@app/components/live-connect/device-profile.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'device-categories',
  templateUrl: './device-categories.component.html',
  styleUrls: ['./device-categories.component.scss']
})
export class DeviceCategoriesComponent {
  hoveredCategoryKey: string;
  objectKeys = Object.keys;

  @Input() categories: { [deviceProfileType: string]: IDeviceCategory };
  @Input() categoryImgs: { [deviceProfileType: string]: string };
  @Input() activeCategoryKey;
  @Output() activateNewCategory: EventEmitter<IDeviceCategory> = new EventEmitter();

  clickActivateCategory(deviceCategory: IDeviceCategory): void {
    this.hoveredCategoryKey = deviceCategory.deviceProfileType;
    this.activateNewCategory.emit(deviceCategory);
  }

  getHoverBarClass(hoveredCategoryKey: string): string {
    if (!hoveredCategoryKey) return;
    return this.categories[hoveredCategoryKey].barClass;
  }
}
