<div class="audit-report-info-modal">
  <div class="audit-report-info-modal-header">
    <button mat-icon-button (click)="closeModal()" class="close-btn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="audit-report-info-modal-body">
    <div class="banner"
         [ngClass]="{
          'status-stopped': auditRunStatus?.status === EAuditRunStatus.Stopped,
          'status-issues': auditRunStatus?.status === EAuditRunStatus.Issues
         }"
    >
      <div class="left-side">
        <mat-icon class="status-icon">{{ auditRunStatus.icon }}</mat-icon>
        {{ auditRunStatus.issueDescription }}
      </div>
      <button class="edit-btn" mat-flat-button (click)="editAuditConfiguration()" *ngIf="!auditRunStatus.userIsReadOnly">Edit Audit Configuration</button>
    </div>
    <div class="errors">
      <p class="reason">
        <strong class="upper">Reason:</strong> {{ auditRunStatus.reason }}
        <ng-container *ngIf="auditRunStatus?.errors?.preAuditActionResults?.zeroAndOut ||
                             auditRunStatus?.errors?.startingUrlResults?.failureType === EStartingUrlFailureType.AllFailedOneAndOut"
        >
          Failure messages:
        </ng-container>
      </p>

      <!-- template for pre-audit action failure -->
      <ng-container *ngIf="auditRunStatus?.errors?.preAuditActionResults?.zeroAndOut">
        <div class="pre-audit-error" *ngFor="let error of preAuditActionFailures">
          <p class="summary"><strong>{{ error.errorSummary }}</strong></p>
          <p class="message">{{ error.errorMessage }}</p>
        </div>
      </ng-container>

      <!-- template for all starting URLs failing -->
      <div *ngIf="auditRunStatus?.errors?.startingUrlResults?.failureType === EStartingUrlFailureType?.AllFailedOneAndOut" class="starting-urls-failing">
        <div class="error error-403" *ngIf="error403Count">
          <p><strong>Error: {{ error403Count }} starting URL{{ error403Count > 1 ? 's' : '' }} responded with an HTTP status code of 403: Access Denied</strong></p>
          <p><strong>SOLUTION:</strong> This typically means that ObservePoint IP addresses are being blocked by your web server, load balancer, CDN, or that access to pre-production website has not been granted.</p>
          <a href="https://help.observepoint.com/article/418-browser-error-messages" target="_blank">Learn more about what to do with access denied errors.</a>
        </div>

        <div class="error error-500" *ngIf="error500Count">
          <p><strong>Error: {{ error500Count }} starting URL{{ error403Count > 1 ? 's' : '' }} responded with an HTTP status code of 500: Internal Server Error</strong></p>
          <a href="https://help.observepoint.com/article/418-browser-error-messages" target="_blank">Learn about how to handle internal server errors.</a>
        </div>

        <div *ngIf="startingUrlFailures?.length" class="errors-other">
          <p class="summary"><strong>Error: Starting URLs failed to load correctly for the following reason{{ startingUrlFailures.length > 1 ? 's' : '' }}:</strong></p>
          <div class="error-other" *ngFor="let error of startingUrlFailures">
            - {{ error }}
          </div>
          <a href="https://help.observepoint.com/article/418-browser-error-messages" target="_blank">Learn about {{ startingUrlFailures.length > 1 ? 'these' : 'this' }} browser message{{ startingUrlFailures.length > 1 ? 's' : '' }}.</a>
        </div>
      </div>

      <!-- template for all include/exclude filters not matching any URLs -->
      <div *ngIf="auditRunStatus?.errors?.startingUrlResults?.failureType === EStartingUrlFailureType.NoLinksOneAndOut" class="pages-excluded">
        <section>
          <p><strong class="upper">Audit setup filters look like this:</strong></p>
          <div class="include-filters">
            <p class="heading"><strong>Site Sections Included:</strong></p>
            <p *ngFor="let filter of auditRunStatus.filters.include">{{ filter.value }}</p>
          </div>
          <div class="exclude-filters">
            <p class="heading"><strong>Site Sections Excluded:</strong></p>
            <p *ngFor="let filter of auditRunStatus.filters.exclude">{{ filter }}</p>
          </div>
        </section>
        <section>
          <div class="excluded-link-examples">
            <p><strong class="upper">Excluded links found look like this:</strong></p>
            <p *ngFor="let link of auditRunStatus.errors.startingUrlResults.linksExcludedSample">{{ link }}</p>
          </div>
        </section>
        <section>
          <div class="solution">
            <p><strong>SOLUTION:</strong> Update starting URLs or modify the setup filters to match appropriate URLs.</p>
          </div>
        </section>
      </div>

    </div>
  </div>
</div>
