import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { IAuditEditorCloseOptions } from '@app/components/audit/audit-editor/audit-editor.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';

export interface ISnackbarErrorData {
  domainId?: number;
  auditId?: number;
  runId?: number;
  snackbarClasses?: string[];
  snackbarContainerClasses?: string[];
  message?: string;
  allUnsupportedSequences?: string[];
  unsupportedSequence?: string;
  includedNotApplied?: string[];
  excludedNotApplied?: string[];
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'regexp-snackbar-error',
  templateUrl: './regexp-snackbar-error.component.html',
  styleUrls: ['./regexp-snackbar-error.component.scss']
})
export class SnackbarRegExpErrorComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarErrorData,
              public snackBarRef: MatSnackBarRef<any>,
              private opModalService: OpModalService,
              private modalEscapeService: ModalEscapeService) {}

  close() {
    this.snackBarRef.dismiss();
  }

  goToSettings() {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    const data = {
      domainId: this.data.domainId,
      auditId: this.data.auditId,
      runId: this.data.runId
    };
    this.opModalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor')
      .afterClosed()
      .subscribe((options?: IAuditEditorCloseOptions) => {
        this.modalEscapeService.remove(index);
        this.close();
      });
  }
}
