<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header  [title]="ccAssignStrings.modalTitle"
                      (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="cc-assign-wrap">
      <cc-assign-select-cc *ngIf="ccAssignView === ECCAssignView.SELECT_CC"
                           [labels]="data.labels"
                           [selectedConsentCategories]="selectedConsentCategories"
                           (selectionChanged)="updateCcSelection($event)">
      </cc-assign-select-cc>

      <cc-assign-select-data-source *ngIf="ccAssignView === ECCAssignView.SELECT_REPORT"
                                    [selectedType]="selectedType"
                                    [selectedConsentCategories]="selectedConsentCategories"
                                    [selectedConsentCategoryNames]="selectedConsentCategoryNames"
                                    [selectedDataSources]="selectedDataSources"
                                    (selectionChanged)="updateDataSourceSelection($event)">
      </cc-assign-select-data-source>
    </div>
  </ng-template>
</op-modal>
