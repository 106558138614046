<div class="title">
  Alert Logic
</div>

<div class="subtitle">
  What should the data look like to trigger an alert?
</div>

<div class="form-container" [formGroup]="formGroup" [class.read-only]="modalType === EAlertModalType.ReadOnly">
  <mat-form-field appearance="outline" floatLabel="always" class="styled-mat-input report-metric-control"
                  [matTooltip]="readOnlyLabel"
                  matTooltipPosition="above"
                  [matTooltipDisabled]="modalType !== EAlertModalType.ReadOnly">
    <mat-label>Report Metric</mat-label>

    <op-report-metric-selector formControlName="metricType"
                               placeholder="Select Report Metric"
                               [menuContext]="menuContext">
    </op-report-metric-selector>

    <mat-icon matSuffix>arrow_drop_down</mat-icon>
    <mat-error *ngIf="metricTypeFormControl.invalid">
      This field is required
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" class="styled-mat-input operator-control" [class.visible]="metricTypeFormControl.value"
                  [matTooltip]="readOnlyLabel"
                  matTooltipPosition="above"
                  floatLabel="always"
                  [matTooltipDisabled]="modalType !== EAlertModalType.ReadOnly">
    <mat-label>Operator</mat-label>
    <mat-select formControlName="operator"
                panelClass="alert-operator-selector"
                disableOptionCentering
                placeholder="Select operator"
    >
      <mat-optgroup *ngFor="let group of displayedAlertOperatorGroups" [label]="group.name | uppercase">
        <mat-option class="slim-option" *ngFor="let operator of group.operators" [value]="operator">
          {{ operator.title }} ({{ operator.sign }})
        </mat-option>
      </mat-optgroup>
    </mat-select>
    <mat-error *ngIf="operatorFormControl.invalid">
      This field is required
    </mat-error>
  </mat-form-field>

  <span class="value-control" [class.visible]="operatorFormControl.value && targetValueFormControl.enabled">
    <mat-form-field appearance="outline" class="styled-mat-input"
                    [matTooltip]="readOnlyLabel"
                    matTooltipPosition="above"
                    floatLabel="always"
                    [matTooltipDisabled]="modalType !== EAlertModalType.ReadOnly">
      <mat-label>{{valuePlaceholder}}</mat-label>
      <input matInput type="number" placeholder="Enter value" formControlName="targetValue"/>
      <mat-error *ngIf="targetValueFormControl.errors?.required">
        This field is required
      </mat-error>
      <mat-error *ngIf="targetValueFormControl.errors?.min || targetValueFormControl.errors?.max">
        Enter 0-100
      </mat-error>
    </mat-form-field>

    <span class="value-control-post-text"
          *ngIf="[EMetricValueComparator.GreaterOrEqual, EMetricValueComparator.LessOrEqual].includes(operatorFormControl.value?.comparator)
          && !percentageChangeTypes.includes(operatorFormControl.value?.changeType)
          && !accountLimitMetricTypes.includes(metricTypeFormControl.value)">
      or more
    </span>
    <!-- Only display for operators that trigger on percent changes -->
    <span class="value-control-post-text"
          *ngIf="percentageChangeTypes.includes(operatorFormControl.value?.changeType)
          && !accountLimitMetricTypes.includes(metricTypeFormControl.value)">
      %
    </span>
    <!-- Only display for Usage alert types -->
    <span class="value-control-post-text"
          *ngIf="accountLimitMetricTypes.includes(metricTypeFormControl.value)">
      % {{ UsagePostScriptsByMetric[metricTypeFormControl.value] }}
    </span>
  </span>
</div>
