import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickToEnlargeScreenshotModalComponent } from './click-to-enlarge-screenshot-modal/click-to-enlarge-screenshot-modal.component';
import { OpModalService } from '../shared/components/op-modal';
import { ModalEscapeService } from '../ui/modalEscape/modalEscapeService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'click-to-enlarge-screenshot',
  templateUrl: './click-to-enlarge-screenshot.component.html',
  styleUrls: ['./click-to-enlarge-screenshot.component.scss']
})
export class ClickToEnlargeScreenshotComponent {

  @Input() screenshotUrl: string;
  @Input() altText?: string;
  @Output() afterCloseCallback = new EventEmitter();

  constructor(
    private opModalService: OpModalService,
    private modalEscapeService: ModalEscapeService
  ) {}

  openImageInModal(): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.opModalService.openFullscreenModalTransparent(ClickToEnlargeScreenshotModalComponent, {
      data: {
        screenshotUrl: this.screenshotUrl
      }
    })
    .afterClosed()
    .subscribe(() => {
      this.modalEscapeService.remove(index);
      this.afterCloseCallback.emit();
    });
  }
}
