<mat-button-toggle-group
  name="displayType"
  class="chart-value-display-options"
  [hideSingleSelectionIndicator]="true"
  (change)="displayAs($event.value)"
>
  <mat-button-toggle value="percentage"
                     checked="true"
                     [matTooltip]="tooltipPercent"
                     matTooltipPosition="above">
    %
  </mat-button-toggle>
  <mat-button-toggle value="count"
                     [matTooltip]="tooltipNumber"
                     matTooltipPosition="above">
    #
  </mat-button-toggle>
</mat-button-toggle-group>
