<div class="primary-tag-health-card" *ngIf="tag">
  <div class="pthc-header">
    <div class="pthc-header-left">
      <img class="tag-icon" [src]="tag.tagIconUrl">
      <div class="tag-name">{{ tag.tagName }}</div>
      <div class="ellipse-menu" [class.show-menu]="tag.menuOpen">
        <button mat-icon-button [matMenuTriggerFor]="menu" (click)="tag.menuOpen = true">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="pthc-menu" (closed)="tag.menuOpen = false;">
          <button mat-menu-item class="primary-tag-health-filter-menu-item" (click)="menuItemClicked.emit({ tag: tag, event: menuOptions.TagInventory })">
            <mat-icon>north_east</mat-icon>
            Explore this item in Tag Inventory
          </button>
          <button mat-menu-item class="primary-tag-health-filter-menu-item" (click)="menuItemClicked.emit({ tag: tag, event: menuOptions.TagHealth })">
            <mat-icon>north_east</mat-icon>
            Explore this item in Tag Health
          </button>
          <button mat-menu-item class="primary-tag-health-filter-menu-item" (click)="menuItemClicked.emit({ tag: tag, event: menuOptions.TagSetup })">
            <mat-icon>edit</mat-icon>
            Edit Primary Tags in Tag Setup
          </button>
          <button mat-menu-item class="primary-tag-health-filter-menu-item" (click)="addGlobalTagFilter(tag)">
            <mat-icon>tune</mat-icon>
            Filter reports by this item
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="pthc-header-right" (click)="accountCountClicked.emit(tag)" matTooltip="Explore accounts for this tag" matTooltipPosition="above">
      {{ tag.tagAccountCount | number }} accounts
    </div>
  </div>
  <div class="pthc-body-wrap">
    <div class="pthc-body">
      <div class="metric pages-with-tag">
        Pages with Tag
        <div class="chart-wrap" *ngIf="pagesWithTagsChartData?.length">
          <donut-chart [data]="pagesWithTagsChartData"
                       uniqueIdentifier="{{ tag.tagId }}-pagesWithTags"
                       (donutSectionClicked)="navToTagInventory($event, tag)">
          </donut-chart>
        </div>
      </div>

      <div class="metric average-load-time">
        Avg Tag Load Time
        <div class="load-time-number-wrap">
          <div class="load-time-number {{ tag.tagLoadTimeClass }}"
               matTooltip="Explore load times for this tag"
               matTooltipPosition="above"
               (click)="navToTagHealth(tag)">
            {{ tag.tagLoadTimeAverage | number }}<span class="suffix">ms</span>
          </div>
        </div>
      </div>

      <div class="metric status-codes">
        Status Codes
        <div class="chart-wrap" *ngIf="statusCodesChartData.length">
          <donut-chart [data]="statusCodesChartData"
                       uniqueIdentifier="{{ tag.tagId }}-statusCodes"
                       (donutSectionClicked)="navToTagHealthWithStatusCode($event, tag)">
          </donut-chart>
        </div>
      </div>
    </div>
  </div>
</div>
