import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IPrimaryTagSettings } from '../manage-tags/manage-tags.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-tag-comparison-config',
  templateUrl: './manage-tag-comparison-config.component.html',
  styleUrls: ['./manage-tag-comparison-config.component.scss']
})
export class ManageTagComparisonConfigComponent implements OnInit {

    @Input() tagSettings: IPrimaryTagSettings;
    @Output() onUpdate: EventEmitter<IPrimaryTagSettings> = new EventEmitter();

    excludedVariables: string[] = [];
    alignmentCriteria: string[] = [];

    ngOnInit(): void {
      this.excludedVariables = this.tagSettings.excludedVariables;
      this.alignmentCriteria = this.tagSettings.variableAlignment;
    }

    updatePrimaryTagSettings(): void {
      const settings = {
        ...this.tagSettings,
        excludedVariables: this.excludedVariables,
        variableAlignment: this.alignmentCriteria,
      };
      this.onUpdate.emit(settings)
    }

}
