import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'op-list-editor',
  templateUrl: './op-list-editor.component.html',
  styleUrls: ['./op-list-editor.component.scss'],
})
export class OpListEditorComponent implements OnChanges {
  
  @Input() items: string[];
  @Input() options: string[];
  @Input() placeholder: string = 'Type Variable';
  @Output() onChange: EventEmitter<string[]> = new EventEmitter();
  @ViewChild('list') private listElement: ElementRef;

  listEditorForm: UntypedFormGroup;

  filteredOptions: Observable<string[]>;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.listEditorForm = this.formBuilder.group({
      input: this.formBuilder.control('', (c: AbstractControl) => {
        if (c.value && this.items.indexOf(c.value.trim()) > -1) {
          return {
            duplicate: `${c.value.trim()} already exists`
          };
        }

        return null;
      })
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && changes.options.currentValue) {
      this.filteredOptions = this.inputControl.valueChanges
      .pipe(
        startWith(''),
        map(searchText => this.filterOptions(searchText))
      );
    }
  }

  private filterOptions(searchText: string): string[] {
    const filterValue = searchText.toLowerCase();
    return this.options && this.options.filter(option => {
      const isContainSearchText = option.toLowerCase().includes(filterValue);
      const isNotSelected = !this.items.some(i => i === option)
      return isContainSearchText && isNotSelected
    });
  }

  get inputControl() {
    return this.listEditorForm.controls.input;
  }
  get input() {
    return this.listEditorForm.controls.input && this.listEditorForm.controls.input.value;
  }

  remove(item: string): void {
    this.items.splice(this.items.indexOf(item), 1);
    this.onChange.emit(this.items);
  }

  add(): void {
    if (this.input && this.input.trim() && this.listEditorForm.valid) {
      this.items.push(this.input.trim());
      this.listEditorForm.patchValue({
        input: ''
      });

      this.onChange.emit(this.items);

      setTimeout(() => {
        this.listElement.nativeElement.scrollTop = this.listElement.nativeElement.scrollHeight;
      });
    }
  }
}
