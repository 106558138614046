import { ModalWithHotkeySupport } from './keyboard-shortcuts.models';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarService } from '@app/components/navigation/sidebar/sidebar.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { EKeyCodes, IgnoreKeyboardShortcuts, KeyRoutesInclusive, KeyRoutesExclusive, AuditPathRegex, KeyboardShortcutsPrefStorageKey, KeysIgnoredByModal } from './keyboard-shortcuts.constants';
import { takeUntil } from 'rxjs/operators';
import { OpModalService } from '../../components/op-modal';
import { NewDataModalComponent } from '@app/components/new-data-modal/new-data-modal.component';
import { AccountSearchComponent } from '@app/components/account-search/account-search.component';
import { AccountSearchModalConfig } from '@app/components/navigation/sidebar/sidebar.constants';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { ThemeService } from '@app/services/theme-service/theme.service';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class KeyboardShortcutsService implements OnDestroy {

  timer: number;
  currentlyPressedKeys: string[] = [];
  sidebarIsClosed: boolean;
  themeState: boolean;
  notesBox: HTMLElement;
  auditReportsList: string[];
  auditInfoIcon: HTMLElement;

  editMethod: Function;
  saveMethod: Function;
  openShortcutsModalMethod: Function;

  public shortcutsAreEnabled$ = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private sidebarService: SidebarService,
    private modalService: OpModalService,
    private themeService: ThemeService,
    private storageService: StorageService
  ) {
    this.getSidebarState();
    this.getThemeState();
    this.getShortcutEnabledState();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  keyDown(event: KeyboardEvent): void {
    // only proceed if the user has enabled shortcuts
    if (!this.shortcutsAreEnabled$.value) return;

    // store variables
    const key = event?.key?.toLowerCase();
    const route = this.router.url;
    const currentlyFocusedElement = document.activeElement.localName;

    // return if current element is input or textarea
    if (IgnoreKeyboardShortcuts.includes(currentlyFocusedElement)) return;

    // store the currently pressed key (unless already stored)
    if (!this.currentlyPressedKeys.includes(key)) {
      this.currentlyPressedKeys.push(key);
    }

    // handle for the opened dialog, if any
    const dialogsCount = this.dialog.openDialogs.length;
    const isIgnoredByModal = KeysIgnoredByModal.includes(key as EKeyCodes);
    if (dialogsCount && !isIgnoredByModal) {
      const dialogInstance = this.dialog.openDialogs[dialogsCount - 1].componentInstance;
      if (this.isModalWithHotkeySupport(dialogInstance)) {
        const handler = dialogInstance.getHotkeyHandler(key as EKeyCodes);
        handler?.();
      }
      return;
    }

    // handle for the route
    switch (key) {
      case EKeyCodes.KeyC:
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.KeyC], route, true)) break;
        this.handleKeyC();
        break;

      case EKeyCodes.KeyE:
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.KeyE], route, true)) break;
        this.handleKeyE();
        break;

      case EKeyCodes.KeyF:
        if (!this.canDoShortcut(KeyRoutesExclusive[EKeyCodes.KeyF], route, false)) break;
        this.handleKeyF();
        break;

      case EKeyCodes.KeyH:
        if (!this.canDoShortcut(KeyRoutesExclusive[EKeyCodes.KeyH], route, false)) break;
        this.handleKeyH();
        break;

      case EKeyCodes.KeyI:
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.KeyI], route, true)) break;
        this.handleKeyI();
        break;

      case EKeyCodes.KeyN:
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.KeyN], route, true)) break;
        this.handleKeyN(event);
        break;

      case EKeyCodes.KeyS:
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.KeyS], route, true)) break;
        this.handleKeyS(event);
        break;

      case EKeyCodes.KeyT:
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.KeyT], route, false)) break;
        this.handleKeyT();
        break;

      case EKeyCodes.BracketLeft:
        if (!this.canDoShortcut(KeyRoutesExclusive[EKeyCodes.BracketLeft], route, false)) break;
        this.handleBracketLeft();
        break;

      case EKeyCodes.ArrowRight:
      case EKeyCodes.ArrowDown:
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.ArrowRight], route, true)) break;
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.ArrowDown], route, true)) break;
        this.handleArrowsRightAndDown();
        break;

      case EKeyCodes.ArrowLeft:
      case EKeyCodes.ArrowUp:
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.ArrowLeft], route, true)) break;
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.ArrowUp], route, true)) break;
        this.handleArrowsLeftAndUp();
        break;

      case EKeyCodes.QuestionMark:
        if (!this.canDoShortcut(KeyRoutesInclusive[EKeyCodes.QuestionMark], route, false)) break;
        this.handleQuestionMark();
        break;
    }
  }

  keyUp(event: KeyboardEvent): void {
    // only proceed if the user has enabled shortcuts
    if (!this.shortcutsAreEnabled$) return;

    const key = event?.key?.toLowerCase();
    const currentlyFocusedElement = document.activeElement.localName;
    if (IgnoreKeyboardShortcuts.includes(currentlyFocusedElement)) return;

    // remove the key that was just released
    const index = this.currentlyPressedKeys.indexOf(key);
    this.currentlyPressedKeys.splice(index, 1);
  }

  // create new modal opens
  private handleKeyC(): void {
    // allows user to press cmd/ctrl + c to copy without create modal opening
    if (this.currentlyPressedKeys.length > 1) return;

    this.modalService.openModal(NewDataModalComponent, {});
  }

  // opens edit screen when viewing audit/journey report
  private handleKeyE(): void {
    this.editMethod
      ? this.editMethod()
      : console.error('You must register an edit method for this keyboard shortcut to work!');
  }

  // global account search modal opens
  private handleKeyF(): void {
    // allows user to press cmd/ctrl + f to find on page without global search opening
    if (this.currentlyPressedKeys.length > 1) return;

    this.modalService.openModal(AccountSearchComponent, AccountSearchModalConfig);
  }

  // navigates back to sources screen
  private handleKeyH(): void {
    // allows user to press cmd/ctrl + h to hide browser without navigating to sources screen
    if (this.currentlyPressedKeys.length > 1) return;

    this.router.navigateByUrl(DataSourcesUrlBuilders.sources());
  }

  // opens info popover on audit report screens
  private handleKeyI(): void {
    this.auditInfoIcon.click();
  }

  // gives focus to notes when viewing audit/journey report
  private handleKeyN(event: KeyboardEvent): void {
    // allows user to press cmd/ctrl + n for new window without focusin on notes box
    if (this.currentlyPressedKeys.length > 1) return;

    // prevent keypress from typing 'n' in notes box
    event.preventDefault();

    this.notesBox
      ? this.notesBox.focus()
      : console.error('You must register a notes box for this keyboard shortcut to work!');
  }

  // allows user to save from audit, web journey, or consent cat edit screen
  private handleKeyS(event: KeyboardEvent): void {
    // prevent browser from opening save dialog
    event.preventDefault();

    const commandIsPressed = this.currentlyPressedKeys.includes(EKeyCodes.Command);
    const controlIsPressed = this.currentlyPressedKeys.includes(EKeyCodes.Control);

    if (commandIsPressed || controlIsPressed) {
      this.saveMethod
        ? this.saveMethod()
        : console.error('You must register a save method for this keyboard shortcut to work!');
    }
  }

  private handleKeyT(): void {
    this.themeService.toggleTheme(!this.themeState);
  }

  // toggles left side nav collapsed state
  private handleBracketLeft(): void {
    this.sidebarService.toggleSidebarState(!this.sidebarIsClosed);
  }

  // navs to next audit report in left nav
  private handleArrowsRightAndDown(): void {
    const shiftIsPressed = this.currentlyPressedKeys.includes(EKeyCodes.Shift);
    const nextReportIndex = this.getCurrentReportIndex() + 1;

    if (shiftIsPressed && nextReportIndex < this.auditReportsList.length) {
      const basePath = this.router.url.match(AuditPathRegex);
      this.router.navigateByUrl(`${basePath}${this.auditReportsList[nextReportIndex]}`);
    }
  }

  // navs to previous audit report in left nav
  private handleArrowsLeftAndUp(): void {
    const shiftIsPressed = this.currentlyPressedKeys.includes(EKeyCodes.Shift);
    const prevReportIndex = this.getCurrentReportIndex() - 1;

    if (shiftIsPressed && prevReportIndex >= 0) {
      const basePath = this.router.url.match(AuditPathRegex);
      this.router.navigateByUrl(`${basePath}${this.auditReportsList[prevReportIndex]}`);
    }
  }

  private handleQuestionMark(): void {
    const shiftIsPressed = this.currentlyPressedKeys.includes(EKeyCodes.Shift);
    if (shiftIsPressed) {
      this.openShortcutsModalMethod();
    }
  }

  private canDoShortcut(routes: string[] = [], currentRoute: string, inclusive: boolean): boolean {
    // if the route has any dynamic ids we replace them with zeros for easier matching
    currentRoute = currentRoute.replace(/\/\d{1,8}/g, '/0');

    return inclusive
      ? !!routes.filter((route: string) => currentRoute.includes(route)).length
      : !routes.filter((route: string) => currentRoute.includes(route)).length;
  }

  private getSidebarState(): void {
    this.sidebarService.isClosed.pipe(takeUntil(this.destroy$)).subscribe((state: boolean) => {
      this.sidebarIsClosed = state;
    });
  }

  private getThemeState(): void {
    this.themeService.isDarkTheme.pipe(takeUntil(this.destroy$)).subscribe((state: boolean) => {
      this.themeState = state;
    });
  }

  private getCurrentReportIndex(): number {
    return this.auditReportsList.findIndex((path: string) => this.router.url.includes(path));
  }

  private getShortcutEnabledState(): void {
    this.shortcutsAreEnabled$.next(this.storageService.getValue(KeyboardShortcutsPrefStorageKey, StorageType.Local));
  }

  registerNotesBox(element: HTMLElement): void {
    this.notesBox = element;
  }

  registerEditMethod(method: Function): void {
    this.editMethod = method;
  }

  registerSaveMethod(method: Function): void {
    this.saveMethod = method;
  }

  registerAuditReportList(auditReportsList: string[]): void {
    this.auditReportsList = auditReportsList;
  }

  registerAuditInfoIcon(element: HTMLElement): void {
    this.auditInfoIcon = element;
  }

  registerOpenShortcutsModalMethod(method: Function): void {
    this.openShortcutsModalMethod = method;
  }

  private isModalWithHotkeySupport(modal: ModalWithHotkeySupport | any): modal is ModalWithHotkeySupport {
    return typeof modal.getHotkeyHandler !== 'undefined';
  }
}
