import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ApiService } from '@app/components/core/services/api.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { ICommonTableState } from '@app/components/shared/components/export-report/export-reports.models';
import {
  IAbstractExportReportService
} from '@app/components/shared/components/export-report/export-reports.abstract.service';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { HttpParams } from '@angular/common/http';
import {
  ICopyConfigCell
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';

enum EDefaultColumnNames {
  url = 'INITIAL PAGE URL',
  pageUrl = 'INITIAL PAGE URL',
  finalPageUrl = 'FINAL PAGE URL',
  finalUrl = 'FINAL PAGE URL',
  loadTime = 'PAGE LOAD TIME (sec)',
  pageLoadTime = 'PAGE LOAD TIME (sec)',
  statusCode = 'PAGE STATUS CODE',
  initialPageStatusCode = 'INITIAL STATUS CODE',
  finalPageStatusCode= 'FINAL STATUS CODE',
  redirectCount = '# OF REDIRECTS',
  pageStatusCode = 'PAGE STATUS CODE',
}
@Injectable()
export class AuditExportReportService
  extends AuditReportBaseService
  implements IAbstractExportReportService {

  constructor(private clipboard: Clipboard,
              protected apiService: ApiService,
              cacheResetService: CacheResetService) {
    super(cacheResetService);
  }

  send(auditId: number, runId: number, type: string, tableState: ICommonTableState, body: IAuditReportApiPostBody = {}, allData = true) {
    let baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/exports/${type}?`;

    let params = new HttpParams();
    if (tableState.sortBy) {
      params = params.set('sortBy', tableState.sortBy).set('sortDesc', tableState.sortDesc);
    }

    if (allData) {
      params = params.set('allData', true);
    } else {
      params = params.set('page', tableState.page).set('size', tableState.size);
    }

    return this.apiService.post(baseRequestUrl + params.toString(), body);
  }

  copyView<PageRow = any>(data: PageRow[], configs: ICopyConfigCell<PageRow>[], displayedColumns: string[] = null) {
    const deleteSymbol = Symbol('delete');
    let titles = configs.filter(conf => this.isCellAvailable(conf, displayedColumns)).map(conf => conf.title || EDefaultColumnNames[conf.property]).join('\t') + '\n';
    let tableClipboard = data.map(row => {
      return configs.map(cellConfig => {
        if (this.isCellAvailable(cellConfig, displayedColumns)) {
          if (cellConfig.displayLike) {
            return cellConfig.displayLike(row, cellConfig.additionalParams);
          }
          return row[cellConfig.property];
        }

        return deleteSymbol;
      }).filter(value => value !== deleteSymbol).join('\t');
    })
      .join('\n');

    this.clipboard.copy(titles + tableClipboard);
  }

  private isCellAvailable(cellConfig, displayedColumns: string[] = null) {
    if (!displayedColumns) {
      return true;
    } else {
      return cellConfig.tableColumnName ? displayedColumns.includes(cellConfig.tableColumnName) : displayedColumns.includes(cellConfig.property);
    }
  }
}

