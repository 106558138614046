import { CacheControls, ICacheApiResponseOptions } from '@app/components/core/decorators/global-decorators.models';
import { from, Observable, throwError, } from 'rxjs';
import { ApiObservable } from '../services/api.service';
import { catchError } from 'rxjs/operators';

export class CacheApiResponseOptionsWithTestHelpers implements ICacheApiResponseOptions {
  liveTime?: number;
  resetCache?: any;

  cacheDeleteOrResetTriggered = false;
}

/**
 * Decorator to simplify caching for API calls
 *
 * @param options is an optional object that can have two optional properties:
 *    liveTime: a property to set time before cache auto clears
 *    cacheReset: a subject to subscribe to for event driven clearing.
 *    if neither are provided, the cache will live indefinitely
 */
export function CacheApiResponse<T>(options?: ICacheApiResponseOptions) {
  const cachedData = new Map<string, {
    promise: Promise<T>,
    liveTime: number
  }>();

  // To reset on an event, pass in a subject as part of options. When emitted, the cache for this instance
  // will be cleared.
  if (options?.resetCache) {
    options?.resetCache.subscribe(() => {
      cachedData.clear();

      if (options instanceof CacheApiResponseOptionsWithTestHelpers) {
        options.cacheDeleteOrResetTriggered = true;
      }
    });
  }

  interface ICachableMethodMetadata {
    class: string;
    method: string;
    argCount: number;
    caller: string;
  }

  return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod: (...args) => Observable<T> = descriptor.value;

    const wrapPromise = (promise: Promise<T>, cacheKey: string, metadata: ICachableMethodMetadata) => {
      return new ApiObservable(
        from(promise).pipe(
          // If the promise rejects, we want to clear the cache so that the next call will be fresh
          // We do this on the inside of ApiObservable so that subscribers attach to the ApiObservable
          //   directly instead of the observable returned from the pipe
          catchError(err => {
            cachedData.delete(cacheKey);
            // mark for unit tests - TODO: it would be great if TS offered a way to conditionally compile this
            if (options instanceof CacheApiResponseOptionsWithTestHelpers) {
              options.cacheDeleteOrResetTriggered = true;
            }
            // enrich the error object and rethrow
            if (typeof err === 'object') {
              err['metadata'] = metadata;
            } else {
              err = { metadata, error: err };
            }
            return throwError(err);
          })
        ),
        {
          reqMethod: 'Unknown',
          url: `${metadata.class}.${metadata.method}(${metadata.argCount ? '...' : ''})${metadata.caller}`
        }
      );
    }

    const getCaller = (callstack: string[]) => {
      // callstack frames look like this: "    at Function.getCaller (<filepath>:<line>:<column>)"
      let frame = getCaller(callstack);
      frame = frame.replace(/\s+at\s/, '');
      return frame;
    }

    // wrap the original method with the caching logic
    descriptor.value = function (...args) {
      const callstack = (new Error()).stack.split('\n');
      const callstackFrame = callstack.length >= 3 ? callstack[2] : 'Unknown';

      const key = JSON.stringify(args);
      const metadata: ICachableMethodMetadata = { // useful for instrumentation
        class: target.constructor.name,
        method: propertyKey,
        argCount: args.length,
        caller: callstackFrame
      };

      // test the cache
      const cachedVal = cachedData.get(key);
      if (cachedVal?.promise && Date.now() < cachedVal?.liveTime) {
        // cache hit! return the cache value if not expired
        return wrapPromise(cachedVal.promise, key, metadata);
      }

      // Cache miss! Call the original method and store in cache
      // We convert to promise to
      // 1. Avoid dealing with multiple subscriptions in RxJS.
      // 2. Ensure we know the observable that subscribers will get. Downstream subscribers
      //    will still get the same value but we have more control over the observable that they
      //    will subscribe to. Using a ReplaySubject won't return an ApiObservable and using
      //    shareReplay(1) operator creates a hidden subject with the same problem.
      try {
        const p: Promise<T> = originalMethod.apply(this, args).toPromise();
        cachedData.set(key, { promise: p, liveTime: options?.liveTime ? Date.now() + options?.liveTime : Infinity });
        // Convert back to observable
        return wrapPromise(p, key, metadata);
      } catch (err) {
        // converting to an observable still, but intentionally not putting it in the cache.
        return wrapPromise(Promise.reject(err), undefined, metadata);
      }
    };

    descriptor.value.cacheControls = new CacheControls(cachedData);
  };
}
