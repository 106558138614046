import { WebJourneyGlobalRulesReportComponent } from './web-journey-global-rules-report/web-journey-global-rules-report.component';
import { WebJourneyReportHeaderComponent } from './web-journey-report-header/web-journey-report-header.component';
import { WebJourneyReportComponent } from './web-journey-report.component';
import { WebJourneyResultsComponent } from './web-journey-results/web-journey-results.component';
import { WebJourneyReportPaths, WebJourneyReportStateNames, WebJourneyTitles } from './web-journey-report.constants';
import { Route } from '@angular/router';
import {
  WebJourneyExportComponent
} from '@app/components/web-journey-report/web-journey-exports/web-journey-export.component';

export const WebJourneyReportFutureState: Route = {
  path: WebJourneyReportPaths.base,
  loadChildren: () => import('./web-journey-report.module').then(mod => mod.WebJourneyReportModule),
  runGuardsAndResolvers: 'always'
};

// query param: action, tab
const results: Route = {
  path: WebJourneyReportPaths.results,
  component: WebJourneyResultsComponent,
  data: {
    stateName: WebJourneyReportStateNames.results,
    title: WebJourneyTitles.results
  }
};

const rules: Route = {
  path: WebJourneyReportPaths.rules,
  component: WebJourneyGlobalRulesReportComponent,
  data: {
    stateName: WebJourneyReportStateNames.rules,
    title: WebJourneyTitles.rules
  }
};

const exports: Route = {
  path: WebJourneyReportPaths.exports,
  component: WebJourneyExportComponent,
  data: {
    stateName: WebJourneyReportStateNames.exports,
    title: WebJourneyTitles.exports
  }
};

export const WebJourneyReportPrimaryRoutes: Route = {
  path: '',
  component: WebJourneyReportComponent,
  data: {
    stateName: WebJourneyReportStateNames.base,
    fullScreen: true
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: results.path },
    results,
    rules,
    exports
  ]
};

export const WebJourneyReportTopBarRoutes: Route = {
  path: '',
  component: WebJourneyReportHeaderComponent,
  outlet: 'navTopBar',
  data: {
    stateName: WebJourneyReportStateNames.base,
  },
};
