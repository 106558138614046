import * as angular from 'angular';
import * as ngRedux from 'ng-redux';

import { AngularNames, Names, ServerErrorCodes } from '@app/moonbeamConstants';
import {
  DeleteControllerBase,
  IDeleteScope,
  IDisplayItem
} from '../deleteControllerBase';
import { IFolder } from '../../folder/foldersApiService';
import { IFolderActionsService } from '../../../store/actions/folderActions';
import { IDomainsService } from '../../domains/domainsService';
import { ModalType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import { TerminateActiveRunsModalService } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';
import { AccountsService } from '@app/components/account/account.service';

export interface IDeleteFolderScope extends IDeleteScope<IFolder> {}

export class DeleteFolderController extends DeleteControllerBase<IFolder> {
  static $inject = [
    AngularNames.scope,
    DeleteFolderController.Dependencies.deleteHandler,
    AngularNames.q,
    AngularNames.ngRedux,
    Names.Services.folderActions,
    Names.Services.domains,
    Names.NgServices.accountsService,
    Names.NgServices.terminateActiveRunsModalService
  ];

  content: Array<IDisplayItem> = [];

  constructor(
    protected $scope: IDeleteFolderScope,
    protected onDeleteHandler: Function,
    private $q: angular.IQService,
    private $ngRedux: ngRedux.INgRedux,
    private folderActions: IFolderActionsService,
    private domainService: IDomainsService,
    private accountsService: AccountsService,
    private terminateActiveRunsModalService: TerminateActiveRunsModalService
  ) {
    super($scope, onDeleteHandler);
    this.loadData();
  }

  protected createDisplayItem(item: IFolder): IDisplayItem {
    return {
      name: item.name,
      type: 'Folder'
    };
  }

  protected delete(item: IFolder): angular.IPromise<boolean> {
    return this.$ngRedux.dispatch(this.folderActions.deleteFolder(item.id));
  }

  private loadData(): void {
    this.accountsService.getUser().subscribe(currentUser => {
      this.domainService.getDomains(false, this.item.id)
        .then(domains => {
          domains.forEach(item => {
            let promises: Array<angular.IPromise<any>> = [
              this.domainService.getAuditsByDomain(item.id),
              this.domainService.getSimulationsByDomain(item.id)
            ];

            this.$q.all(promises).then(data => {
              let au = data[0];
              let sims = data[1];

              this.content.push({
                type: 'Domain',
                name: item.name,
                createdAt: item.createdAt,
                authorized: this.checkUser(item.userId, currentUser)
              });

              this.content = this.content.concat(
                au.map(audit => {
                  return {
                    type: 'Audit',
                    name: audit.name,
                    createdAt: audit.created,
                    authorized: this.checkUser(audit.ownerId, currentUser)
                  };
                })
              );

              this.content = this.content.concat(
                sims.map(journey => {
                  return {
                    type: 'Web Journey',
                    name: journey.name,
                    createdAt: journey.createdAt,
                    authorized: this.checkUser(journey.userId, currentUser)
                  };
                })
              );
            });
          });
        });
    });
  }

  protected handleError(error: any): void {
    if (error.errorCode === ServerErrorCodes.alreadyRunning) {
      this.close();
      this.terminateActiveRunsModalService.showTerminateActiveRunsModal(ModalType.Folder, error.items);
    }
  }
}
