import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  EUseCaseNavToKeys,
  EUseCaseWidgetType,
  IAuditUseCaseData,
  IUseCaseAnalyticsSummaryInsight,
  IUseCaseCookiePrivacyInfo,
  IUseCaseCookiesSummaryInsight,
  IUseCaseDuplicatesAndMultiplesWidgetData,
  IUseCaseMostUsedTagsSummaryInsight,
  IUseCasePageSummaryInsight,
  IUseCaseRequestPrivacyInfo,
  IUseCaseSection, IUseCaseTagPrivacyInfo,
  IUseCaseWidget
} from '@app/components/audit-reports/use-cases/use-cases.models';
import {
  NewJavascriptFilesWidget,
  PagesMissingShareSellLinksWidget,
  UnapprovedCookiesWidget,
  UnapprovedGeoLocationsWidget,
  UnapprovedItemsSection,
  UnapprovedTagsWidget,
  Privacy3rdPartyCookiesWidget,
  PrivacyPagesScannedWidget,
  PrivacyUniqueCookiesWidget,
  PrivacyUniqueTagsWidget,
  PrivacyUniqueGEOWidget,
  PagesMissingConsentManagerWidget,
  PagesMissingTagManagerWidget,
  PagesMissingPrivacyPolicyLinksWidget
} from '@app/components/audit-reports/use-cases/use-case-privacy/use-case-privacy.constants';
import { UseCaseService } from '@app/components/audit-reports/use-cases/use-case.service';
import { takeUntil } from 'rxjs/operators';
import { userIsGuest } from '@app/authUtils';
import { ESplitCardChangeMeaning } from '@app/components/shared/components/split-card/split-card.models';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { combineLatest, Subject } from 'rxjs';
import { IAccountPreview } from '@app/components/core/services/authentication.models';
import { IUser } from '@app/moonbeamModels';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import {
  IPrivacyRequestsLocation
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.models';
import {
  PrivacyRequestsMapComponent
} from '@app/components/audit-reports/reports/privacy-requests/components/map/privacy-requests-map.component';
import { OP_SELECTORS } from '@app/components/audit-reports/use-cases/use-cases.constants';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'use-case-privacy',
  templateUrl: './use-case-privacy.component.html',
  styleUrls: ['./use-case-privacy.component.scss']
})
export class UseCasePrivacyComponent implements OnInit, OnDestroy {
  CONSTANTS = { ...OP_SELECTORS };

  @ViewChild(PrivacyRequestsMapComponent) privacyRequestsMapComponent: PrivacyRequestsMapComponent;

  destroy$ = new Subject();
  accountType: IAccountPreview['accountType'];
  isVisitorMode: boolean;
  auditInfo: IAuditUseCaseData;
  cookiePrivacyInfo: IUseCaseCookiePrivacyInfo;
  tagPrivacyInfo: IUseCaseTagPrivacyInfo;
  requestPrivacyInfo: IUseCaseRequestPrivacyInfo;
  pagesMissingTagManagerInfo;
  pagesMissingPrivacyPolicyLinksInfo;
  pagesMissingConsentManagerInfo;
  pagesMissingShareSellLinkInfo;
  newJavascriptFilesInfo;
  pagesInfo: IUseCasePageSummaryInsight;
  cookieInfo: IUseCaseCookiesSummaryInsight;
  cookieFilteredByThirdPartyInfo: IUseCaseCookiesSummaryInsight;
  analyticsTagsInfo: IUseCaseAnalyticsSummaryInsight;
  duplicatesAndMultiplesInfo: IUseCaseDuplicatesAndMultiplesWidgetData;
  mostUsedTags: IUseCaseMostUsedTagsSummaryInsight;
  user: IUser;
  isGuestOrVisitor: boolean;
  openCookieEditAuditCCEventListener;
  openCookieCCManagerEventListener;
  openTagEditAuditCCEventListener;
  openTagCCManagerEventListener;
  openRequestsEditAuditCCEventListener;
  openRequestsCCManagerEventListener;

  pagesScannedWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_SCANNED,
    loading: true,
    splitCard: true,
    topText: PrivacyPagesScannedWidget.topText,
    topValue: 0,
    topTooltip: PrivacyPagesScannedWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bottomText: PrivacyPagesScannedWidget.bottomText,
    bottomValue: 0,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bodyContent: {
      bodyText: '',
      navToText: PrivacyPagesScannedWidget.bodyContent.navToText,
    }
  };

  uniqueCookiesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNIQUE_COOKIES,
    loading: true,
    splitCard: true,
    topText: PrivacyUniqueCookiesWidget.topText,
    topValueMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topIconName: PrivacyUniqueCookiesWidget.topIconName,
    topValue: 0,
    topTooltip: PrivacyUniqueCookiesWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.CookiesInventory),
    bottomLeftCustomContent: PrivacyUniqueCookiesWidget.bottomLeftCustomContent,
    statsData: PrivacyUniqueCookiesWidget.statsData,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.CookiesInventory),
    bodyContent: {
      bodyText: '',
      navToText: PrivacyUniqueCookiesWidget.bodyContent.navToText,
    }
  };

  thirdPartyCookiesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.THIRD_PARTY_COOKIE_DOMAINS,
    loading: true,
    splitCard: true,
    topText: Privacy3rdPartyCookiesWidget.topText,
    topValueMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topValue: 0,
    topTooltip: Privacy3rdPartyCookiesWidget.topTooltip,
    topIconName: Privacy3rdPartyCookiesWidget.topIconName,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.CookiesInventory3rdParty),
    bottomLeftCustomContent: Privacy3rdPartyCookiesWidget.bottomLeftCustomContent,
    statsData: Privacy3rdPartyCookiesWidget.statsData,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.CookiesInventory3rdParty),
    bodyContent: {
      bodyText: '',
      navToText: Privacy3rdPartyCookiesWidget.bodyContent.navToText,
    }
  };

  uniqueTagsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNIQUE_TAGS,
    loading: true,
    splitCard: true,
    topText: PrivacyUniqueTagsWidget.topText,
    topValueMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topValue: 0,
    topTooltip: PrivacyUniqueTagsWidget.topTooltip,
    topIconName: PrivacyUniqueTagsWidget.topIconName,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventory),
    bottomLeftCustomContent: PrivacyUniqueTagsWidget.bottomLeftCustomContent,
    statsData: PrivacyUniqueTagsWidget.statsData,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventory),
    bodyContent: {
      bodyText: '',
      navToText: PrivacyUniqueTagsWidget.bodyContent.navToText,
    }
  };

  uniqueGEOWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNIQUE_GEOLOCATIONS,
    loading: true,
    splitCard: true,
    topText: PrivacyUniqueGEOWidget.topText,
    topValueMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topValue: 0,
    topTooltip: PrivacyUniqueGEOWidget.topTooltip,
    topIconName: PrivacyUniqueGEOWidget.topIconName,
    bottomLeftCustomContent: PrivacyUniqueGEOWidget.bottomLeftCustomContent,
    statsData: PrivacyUniqueGEOWidget.statsData,
    bodyContent: {
      bodyText: '',
      navToText: PrivacyUniqueGEOWidget.bodyContent.navToText,
    }
  };

  unapprovedItemsSection: IUseCaseSection = {
    sectionIcon: UnapprovedItemsSection.sectionIcon,
    sectionTitle: UnapprovedItemsSection.sectionTitle,
  };

  unapprovedCookiesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNAPPROVED_COOKIES,
    loading: true,
    splitCard: true,
    topTooltip: UnapprovedCookiesWidget.topTooltip,
    topText: UnapprovedCookiesWidget.topText,
    topIconName: UnapprovedCookiesWidget.topIconName,
    topIconOutline: UnapprovedCookiesWidget.topIconOutline,
    bottomText: UnapprovedCookiesWidget.bottomText,
    bottomValue: UnapprovedCookiesWidget.bottomValue,
    bodyContent: {
      bodyText: ''
    },
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.UnapprovedCookies),
  };

  pagesMissingConsentManagerWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_MISSING_CONSENT_MANAGER,
    loading: true,
    splitCard: true,
    topTooltip: PagesMissingConsentManagerWidget.topTooltip,
    topText: PagesMissingConsentManagerWidget.topText,
    topIconName: PagesMissingConsentManagerWidget.topIconName,
    bottomText: PagesMissingConsentManagerWidget.bottomText,
    bottomValue: PagesMissingConsentManagerWidget.bottomValue,
    bodyContent: {
      bodyText: '',
      navToText: PagesMissingConsentManagerWidget.bodyContent.navToText,
    },
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryPagesMissingConsentManager),
  };

  pagesMissingTagManagerWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_MISSING_TAG_MANAGER,
    loading: true,
    splitCard: true,
    topTooltip: PagesMissingTagManagerWidget.topTooltip,
    topText: PagesMissingTagManagerWidget.topText,
    topIconName: PagesMissingTagManagerWidget.topIconName,
    bottomText: PagesMissingTagManagerWidget.bottomText,
    bottomValue: PagesMissingTagManagerWidget.bottomValue,
    bodyContent: {
      bodyText: '',
      navToText: PagesMissingTagManagerWidget.bodyContent.navToText,
    },
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryPagesMissingTagManager),
  };

  pagesMissingPrivacyPolicyLinksWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_MISSING_PRIVACY_POLICY_LINK,
    loading: true,
    splitCard: true,
    topTooltip: PagesMissingPrivacyPolicyLinksWidget.topTooltip,
    topText: PagesMissingPrivacyPolicyLinksWidget.topText,
    topIconName: PagesMissingPrivacyPolicyLinksWidget.topIconName,
    bottomText: PagesMissingPrivacyPolicyLinksWidget.bottomText,
    bottomValue: PagesMissingPrivacyPolicyLinksWidget.bottomValue,
    bodyContent: {
      bodyText: '',
      navToText: PagesMissingPrivacyPolicyLinksWidget.bodyContent.navToText,
    },
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryRuleFailures),
  };

  pagesMissingShareSellLinksWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_MISSING_DO_NOT_SELL_LINK,
    loading: true,
    splitCard: true,
    topTooltip: PagesMissingShareSellLinksWidget.topTooltip,
    topText: PagesMissingShareSellLinksWidget.topText,
    topIconName: PagesMissingShareSellLinksWidget.topIconName,
    bottomText: PagesMissingShareSellLinksWidget.bottomText,
    bottomValue: PagesMissingShareSellLinksWidget.bottomValue,
    bodyContent: {
      bodyText: '',
      navToText: PagesMissingShareSellLinksWidget.bodyContent.navToText,
    },
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PagesMissingShareSellLinks),
  };

  newJavascriptFilesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.NEW_JAVASCRIPT_FILES,
    loading: true,
    splitCard: true,
    topTooltip: NewJavascriptFilesWidget.topTooltip,
    topText: NewJavascriptFilesWidget.topText,
    topIconName: NewJavascriptFilesWidget.topIconName,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.NewJavascriptFiles),
    bottomText: NewJavascriptFilesWidget.bottomText,
    bottomValue: NewJavascriptFilesWidget.bottomValue,
    bodyContent: {
      bodyText: ''
    },
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.NewJavascriptFiles),
  };

  unapprovedTagsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNAPPROVED_TAGS,
    loading: true,
    splitCard: true,
    topTooltip: UnapprovedTagsWidget.topTooltip,
    topText: UnapprovedTagsWidget.topText,
    topIconName: UnapprovedTagsWidget.topIconName,
    bottomText: UnapprovedTagsWidget.bottomText,
    bottomValue: UnapprovedTagsWidget.bottomValue,
    bodyContent: {
      bodyText: ''
    },
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.UnapprovedTags),
  };

  unapprovedGeoLocationsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNAPPROVED_GEOLOCATIONS,
    loading: true,
    splitCard: true,
    topTooltip: UnapprovedGeoLocationsWidget.topTooltip,
    topText: UnapprovedGeoLocationsWidget.topText,
    topIconName: UnapprovedGeoLocationsWidget.topIconName,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.UnapprovedGeoLocations),
    bottomText: UnapprovedGeoLocationsWidget.bottomText,
    bottomValue: UnapprovedGeoLocationsWidget.bottomValue,
    bodyContent: {
      bodyText: ''
    },
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.UnapprovedGeoLocations),
  };
  focusedLocations: IPrivacyRequestsLocation[];

  constructor(
    private useCaseService: UseCaseService
  ) {}

  ngOnInit(): void {
    this.registerListeners();

    if (!this.useCaseService.isPrivacyDataLoaded) {
      this.useCaseService.loadPrivacy();
    }

  }

  ngOnDestroy() {
    if (this.openCookieEditAuditCCEventListener) this.openCookieEditAuditCCEventListener.removeEventListener();
    if (this.openCookieCCManagerEventListener) this.openCookieCCManagerEventListener.removeEventListener();
    if (this.openTagEditAuditCCEventListener) this.openTagEditAuditCCEventListener.removeEventListener();
    if (this.openTagCCManagerEventListener) this.openTagCCManagerEventListener.removeEventListener();
    if (this.openRequestsEditAuditCCEventListener) this.openRequestsEditAuditCCEventListener.removeEventListener();
    if (this.openRequestsCCManagerEventListener) this.openRequestsCCManagerEventListener.removeEventListener();

    this.destroy$.next();
    this.destroy$.complete();
  }

  registerListeners() {
    combineLatest([
      this.useCaseService.accountType$,
      this.useCaseService.isVisitorMode$,
      this.useCaseService.user$,
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([accountType, isVisitorMode, user]) => {
      this.user = user;
      this.accountType = accountType;
      this.isVisitorMode = isVisitorMode;
      this.isGuestOrVisitor = userIsGuest(user) || isVisitorMode;

      this.useCaseService.audit$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((auditInfo) => {
        this.auditInfo = auditInfo;

        this.updatePagesScannedWidget();
      });

      this.useCaseService.cookiePrivacy$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((cookiePrivacyInfo) => {
          if (cookiePrivacyInfo !== null) {
            this.cookiePrivacyInfo = cookiePrivacyInfo;

            this.updateCookiePrivacyWidgetValues();
          }
      });

      this.useCaseService.tagPrivacy$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((tagPrivacyInfo) => {
        if (tagPrivacyInfo !== null) {
          this.tagPrivacyInfo = tagPrivacyInfo;

          this.updateTagPrivacyWidgetValues();
        }
      });

      this.useCaseService.requestPrivacy$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((requestPrivacyInfo) => {
          if (requestPrivacyInfo !== null) {
            this.requestPrivacyInfo = requestPrivacyInfo;

            this.updateRequestPrivacyWidgetValues();
          }
      });

      this.useCaseService.pagesMissingShareSellLinkInfo$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((pagesMissingShareSellLinkInfo) => {
        if (pagesMissingShareSellLinkInfo !== null) {
          this.pagesMissingShareSellLinkInfo = pagesMissingShareSellLinkInfo;

          this.updatePagesMissingShareSellWidgetValues();
        }
      });

      this.useCaseService.newJavascriptFiles$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((newJavascriptFilesInfo) => {
        if (newJavascriptFilesInfo !== null) {
          this.newJavascriptFilesInfo = newJavascriptFilesInfo;

          this.updateNewJavascriptFilesWidgetValues();
        }
      });
    });

    this.useCaseService.pageSummary$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(pagesInfo => {
      this.pagesInfo = pagesInfo;
      this.updatePagesScannedWidget();
    });

    this.useCaseService.cookiesSummary$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(cookieInfo => {
      this.cookieInfo = cookieInfo;

      this.updateUniqueCookiesWidget();
    });

    this.useCaseService.cookiesSummaryFilteredByThirdPartySubject$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(cookieInfo => {
      this.cookieFilteredByThirdPartyInfo = cookieInfo;

      this.update3rdPartyCookiesWidget();
    });

    this.useCaseService.duplicatesAndMultiples$.pipe(
      takeUntil(this.destroy$)
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(duplicatesAndMultiplesInfo => {
      this.duplicatesAndMultiplesInfo = duplicatesAndMultiplesInfo;

      this.updateUniqueTagsWidget();
    });

    this.useCaseService.analyticsTags$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(analyticsTagsInfo => {
      this.analyticsTagsInfo = analyticsTagsInfo;
      this.updateUniqueTagsWidget();
    });

    this.useCaseService.mostUsedTags$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(analyticsTagsInfo => {
      this.mostUsedTags = analyticsTagsInfo;
      this.update3rdPartyCookiesWidget();
    });

    this.useCaseService.requestsLocations$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(focusedLocations => {
      this.focusedLocations = focusedLocations;
      this.updateUniqueGeoLocationsWidget();
    });

    this.useCaseService.tagInventorySummaryMissingTagManager$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(pagesMissingTagManagerInfo => {
      this.pagesMissingTagManagerInfo = pagesMissingTagManagerInfo;
      this.updatePagesMissingTagManagerWidgetValues();
    });

    this.useCaseService.pagesMissingPrivacyPolicyLinks$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(pagesMissingPrivacyPolicyLinksInfo => {
      this.pagesMissingPrivacyPolicyLinksInfo = pagesMissingPrivacyPolicyLinksInfo;
      this.updatePagesMissingPrivacyPolicyLinksWidgetValues();
    });

    this.useCaseService.pagesMissingConsentManager$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(pageMissingConsentManagerInfo => {
      this.pagesMissingConsentManagerInfo = pageMissingConsentManagerInfo;
      this.updatePagesMissingConsentManagerWidgetValues();
    });
  }

  updatePagesScannedWidget(): void {
    const widget = this.pagesScannedWidget;

    widget.topValue = this.pagesInfo?.totalPages?.toLocaleString() || '---';
    widget.bottomValue = this.auditInfo?.domain;
    widget.bodyContent.bodyText = `<div class="domain-block"><span class="domain-block-label">DOMAIN AUDITED: </span><span class="domain-block-value">${this.auditInfo?.domain}</span></div><div>${this.useCaseService.getBodyText(EUseCaseWidgetType.PrivacyPagesScanned)}</div>`;
    widget.loading = false;
  }

  updateUniqueCookiesWidget(): void {
    const widget = this.uniqueCookiesWidget;
    widget.topValue = this.cookieInfo?.totalUniqueCookieCount?.toLocaleString() || '---';
    widget.chartData = this.cookieInfo.partCookiesChartData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.PrivacyUniqueCookies);
    widget.loading = false;
  }

  update3rdPartyCookiesWidget(): void {
    const widget = this.thirdPartyCookiesWidget;
    widget.topValue = this.cookieFilteredByThirdPartyInfo?.filteredUniqueCookieDomainCount?.toLocaleString() || '---';
    widget.chartData = this.mostUsedTags?.mostUsedTags?.slice(0, 3) || [];
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.Privacy3rdPartyCookies);
    widget.loading = false;
  }

  updateUniqueTagsWidget(): void {
    const widget = this.uniqueTagsWidget;
    widget.topValue = this.duplicatesAndMultiplesInfo?.filteredUniqueTagCount?.toLocaleString() || '---';
    widget.chartData = this.analyticsTagsInfo?.uniqueAnalyticsChartData || [];
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.PrivacyUniqueTags);
    widget.loading = false;
  }

  updateUniqueGeoLocationsWidget(): void {
    const widget = this.uniqueGEOWidget;
    widget.topValue = this.focusedLocations?.length || '<span class="small-value">Not configured</span>';
    widget.chartData = this.focusedLocations || [];
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.PrivacyUniqueGeolocations);

    if (!this.focusedLocations?.length) {
      widget.bodyContent.navToText = 'Configure your Consent Preferences';
      widget.navTo = () => this.useCaseService.openAuditEditorToConsentCategories();
    } else {
      widget.bodyContent.navToText = 'View all geolocations';
      widget.navTo = widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.PrivacyGeoLocations);
    }

    widget.loading = false;

    if (this.focusedLocations.length) {
      setTimeout(() => {
        this.privacyRequestsMapComponent.scrollToTheMostRequestedCountry();
      }, 1000);
    }
  }

  // unapproved cookies
  updateCookiePrivacyWidgetValues(): void {
    const widget = this.unapprovedCookiesWidget;
    const assignedCCCount = this.cookiePrivacyInfo?.assignedCCCount;
    const cookieCount = this.cookiePrivacyInfo?.totalUnapprovedCookieCount;

    widget.topValue = assignedCCCount === 0 ? '<span class="small-value">Not configured</span>' : cookieCount?.toLocaleString();
    widget.topValueMeaning = cookieCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.topIconName = UnapprovedCookiesWidget.topIconName;
    widget.topIconMeaning = cookieCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.NEUTRAL;

    if (this.accountType === EAccountType.SAMPLE) {
      widget.bodyContent.bodyText = `Continuously monitoring for new and unapproved cookies is foundational to web privacy governance. After starting a free trial or purchasing a full account, you will be able to quickly import "approved" cookies from your consent manager. We will then monitor for and flag any cookies found on your website that are not approved, enabling you to quickly update standards and/or address violations as they occur.`;
    } else {
      widget.bodyContent.bodyText = assignedCCCount === 0
        ? `Continuously monitoring for new and unapproved cookies is foundational to web privacy governance. You can quickly import "approved" cookies from your consent manager or from any ObservePoint audit. We will then monitor for and flag any cookies found on your website that are not approved, enabling you to quickly update standards and/or address violations as they occur.<br><br><span class="use-case-privacy-cookies-edit-audit-cc-link use-case-clickable-link">Apply a Consent Category to this audit</span>.`
        : `Continuously monitoring for new and unapproved cookies is foundational to web privacy governance. We monitor for and flag any cookies found on your website that are not included in the Consent Categories assigned to this audit, enabling you to quickly update standards and/or address violations as they occur. <span class="use-case-privacy-cookies-edit-audit-cc-link use-case-clickable-link">Add, update, or remove Consent Categories assigned to this audit</span>.`;
    }

    if (!this.isVisitorMode) {
      if (!this.isGuestOrVisitor && this.accountType !== EAccountType.SAMPLE) {
        setTimeout(() => {
          if (this.openCookieEditAuditCCEventListener) {
            this.openCookieEditAuditCCEventListener.removeEventListener();
          }

          if (this.openCookieCCManagerEventListener) {
            this.openCookieCCManagerEventListener.removeEventListener();
          }
          this.openCookieEditAuditCCEventListener = document.querySelector('.use-case-privacy-cookies-edit-audit-cc-link')?.addEventListener('click', () => {
            this.openAuditStandardsToCC();
          });

          this.openCookieCCManagerEventListener = document.querySelector('.use-case-privacy-consent-manager-link')?.addEventListener('click', () => {
            this.openCCManager();
          });
        });
      }
    }

    if (this.accountType === EAccountType.SAMPLE) {
      widget.bodyContent.navToText = `Start free trial to configure`;
      widget.navTo = () => this.useCaseService.navTo(EUseCaseNavToKeys.SampleAccount);
      widget.skipNavigationConfirm = true;
    } else {
      if (assignedCCCount === 0) {
        widget.bodyContent.navToText = undefined;
        widget.navTo = undefined;
        widget.topClickAction = undefined;
      } else {
        widget.bodyContent.navToText = `View Cookies`;
        widget.navTo = widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.UnapprovedCookies);
        widget.topTooltip = `${widget.topTooltip}  Click to view additional details.`;
      }
    }

    widget.loading = false;
  }

  updatePagesMissingConsentManagerWidgetValues(): void {
    const widget = this.pagesMissingConsentManagerWidget;

    widget.topValue = this.pagesMissingConsentManagerInfo?.filteredPageCount?.toLocaleString() || '---';
    widget.topValueMeaning = this.pagesMissingConsentManagerInfo?.filteredPageCount > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.topIconName = this.pagesMissingConsentManagerInfo?.filteredPageCount > 0 ? PagesMissingConsentManagerWidget.topIconName : '';
    widget.topIconMeaning = this.pagesMissingConsentManagerInfo?.filteredPageCount > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.PagesMissingConsentManager);
    widget.navTo = widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryPagesMissingConsentManager);
    widget.bodyContent.navToText = PagesMissingConsentManagerWidget.bodyContent.navToText;

    widget.loading = false;
  }

  updatePagesMissingTagManagerWidgetValues(): void {
    const widget = this.pagesMissingTagManagerWidget;

    widget.topValue = this.pagesMissingTagManagerInfo?.filteredPageCount?.toLocaleString() || '---';
    widget.topValueMeaning = this.pagesMissingTagManagerInfo?.filteredPageCount > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.topIconName = this.pagesMissingTagManagerInfo?.filteredPageCount > 0 ? PagesMissingTagManagerWidget.topIconName : '';
    widget.topIconMeaning = this.pagesMissingTagManagerInfo?.filteredPageCount > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.PagesMissingTagManager);
    widget.navTo = widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryPagesMissingTagManager);
    widget.bodyContent.navToText = PagesMissingTagManagerWidget.bodyContent.navToText;

    widget.loading = false;
  }

  updatePagesMissingPrivacyPolicyLinksWidgetValues(): void {
    const widget = this.pagesMissingPrivacyPolicyLinksWidget;

    if (this.pagesMissingPrivacyPolicyLinksInfo === '---') {
      widget.topValue = '<div class="small-value long-value">Not Configured Yet</div>';
      widget.topClickAction = undefined;
      widget.topTooltip = PagesMissingPrivacyPolicyLinksWidget.topTooltip;
    } else {
      widget.topValue = this.pagesMissingPrivacyPolicyLinksInfo?.toLocaleString();
      widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.PagesMissingPrivacyPolicyLinks);
      widget.topTooltip = `${this.pagesMissingPrivacyPolicyLinksWidget.topTooltip} Click to view additional details.`;
    }

    widget.topValueMeaning = this.pagesMissingPrivacyPolicyLinksInfo > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.topIconName = this.pagesMissingPrivacyPolicyLinksInfo > 0 ? PagesMissingPrivacyPolicyLinksWidget.topIconName : '';
    widget.topIconMeaning = this.pagesMissingPrivacyPolicyLinksInfo > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.bodyContent.bodyText = this.accountType === EAccountType.SAMPLE
      ? PagesMissingPrivacyPolicyLinksWidget.bodyContent.bodyText.guest
      : 'Privacy Policy links are required on all pages in many jurisdictions. Any pages missing this link should be evaluated for compliance with applicable standards. <a class="use-case-clickable-link" href="https://help.observepoint.com/article/175-checking-the-privacy-policy-link" target="_blank">Learn how to add or update the configuration of the links for which ObservePoint should search</a>.';
    widget.navTo = () => this.useCaseService.navTo(EUseCaseNavToKeys.PagesMissingPrivacyPolicyLinks);
    widget.bodyContent.navToText = PagesMissingPrivacyPolicyLinksWidget.bodyContent.navToText;

    widget.loading = false;
  }

  updatePagesMissingShareSellWidgetValues(): void {
    const widget = this.pagesMissingShareSellLinksWidget;

    if (this.pagesMissingShareSellLinkInfo === '---') {
      widget.topValue = '<div class="small-value long-value">Not Configured Yet</div>';
      widget.topClickAction = undefined;
      widget.topTooltip = PagesMissingShareSellLinksWidget.topTooltip;
    } else {
      widget.topValue = this.pagesMissingShareSellLinkInfo?.toLocaleString();
      widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.PagesMissingShareSellLinks);
      widget.topTooltip = `${this.pagesMissingShareSellLinksWidget.topTooltip}  Click to view additional details.`;
    }

    widget.topValueMeaning = this.pagesMissingShareSellLinkInfo > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.topIconName = this.pagesMissingShareSellLinkInfo > 0 ? PagesMissingShareSellLinksWidget.topIconName : '';
    widget.topIconMeaning = this.pagesMissingShareSellLinkInfo > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.bodyContent.bodyText = this.accountType === EAccountType.SAMPLE
      ? PagesMissingShareSellLinksWidget.bodyContent.bodyText.guest
      : `The presence of a "Do Not Sell/Share My Personal Information" link on certain (potentially all) web pages is often deemed required to comply with California privacy law. Any pages missing this link may need to be evaluated for compliance with applicable standards. <a class="use-case-clickable-link" href="https://help.observepoint.com/article/491-setting-up-do-not-sell-share-links" target="_blank">Learn how to add or update the configuration of the links for which ObservePoint should search</a>.`;
    widget.navTo = () => this.useCaseService.navTo(EUseCaseNavToKeys.PagesMissingShareSellLinks);
    widget.bodyContent.navToText = PagesMissingShareSellLinksWidget.bodyContent.navToText;

    widget.loading = false;
  }

  updateNewJavascriptFilesWidgetValues(): void {
    const widget = this.newJavascriptFilesWidget;

    widget.topValue = this.newJavascriptFilesInfo === '---'
      ? '<div class="small-value long-value">Additional audit run needed</div>'
      : this.newJavascriptFilesInfo?.filteredNewFileCount?.toLocaleString();
    widget.topValueMeaning = this.newJavascriptFilesInfo?.filteredNewFileCount > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.topIconName = this.newJavascriptFilesInfo?.filteredNewFileCount > 0 ? NewJavascriptFilesWidget.topIconName : '';
    widget.topIconMeaning = this.newJavascriptFilesInfo?.filteredNewFileCount > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.PrivacyNewJavascriptFiles);

    if (this.accountType === EAccountType.SAMPLE) {
      widget.bodyContent.navToText = `Start free trial to initiate recurring audit runs`;
      widget.navTo = () => this.useCaseService.navTo(EUseCaseNavToKeys.SampleAccount);
      widget.skipNavigationConfirm = true;
    } else {
      widget.bodyContent.navToText = `View new Javascript files`;
      widget.navTo = () => this.useCaseService.navTo(EUseCaseNavToKeys.NewJavascriptFiles);
    }

    widget.loading = false;
  }

  // unapproved tags
  updateTagPrivacyWidgetValues(): void {
    const widget = this.unapprovedTagsWidget;
    const assignedCCCount = this.tagPrivacyInfo?.assignedCCCount;
    const tagCount = this.tagPrivacyInfo?.totalUnapprovedTagCount;

    widget.topValue = assignedCCCount === 0 ? '<span class="small-value">Not configured</span>' : tagCount.toLocaleString();
    widget.topValueMeaning = tagCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.topIconName = UnapprovedTagsWidget.topIconName;
    widget.topIconOutline = UnapprovedTagsWidget.topIconOutline;
    widget.topIconMeaning = tagCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.NEUTRAL;

    if (this.accountType === EAccountType.SAMPLE) {
      widget.bodyContent.bodyText = `Keeping track of the tags on your website should also be included in the continuous monitoring of your web privacy governance. Once you have launched a free trial or licensed a full account, you will be able to quickly build out a list of web technologies approved for use on your website. Most consent managers don't manage approved tags; therefore, ObservePoint will become a critical component in your ongoing compliance efforts.`;
    } else {
      widget.bodyContent.bodyText = assignedCCCount === 0
        ? `Keeping track of the tags on your website should also be included in the continuous monitoring of your web privacy governance. You can quickly build out a list of web technologies approved for use on your website. Most consent managers don't manage approved tags; therefore, ObservePoint will be a critical component in your ongoing compliance efforts.<br><br><span class="use-case-privacy-tags-edit-audit-cc-link use-case-clickable-link">Apply a Consent Category to this audit.</span>`
        : `Keeping track of the tags on your website should also be included in the continuous monitoring of your web privacy governance. We monitor for and flag any rogue tags found on your website that are not included in the Consent Categories assigned to this audit, enabling you to quickly update standards and/or address violations as they occur. <span class="use-case-privacy-tags-edit-audit-cc-link use-case-clickable-link">Add, update, or remove Consent Categories assigned to this audit.</span>`;
    }

    if (!this.isVisitorMode) {
      // Set navto link and action to signup
      if (!this.isGuestOrVisitor && this.isGuestOrVisitor !== undefined) {
        setTimeout(() => {
          if (this.openTagEditAuditCCEventListener) {
            this.openTagEditAuditCCEventListener.removeEventListener();
          }

          this.openTagEditAuditCCEventListener = document.querySelector('.use-case-privacy-tags-edit-audit-cc-link')?.addEventListener('click', () => {
            this.openAuditStandardsToCC();
          });
        });
      }
    }

    if (this.accountType === EAccountType.SAMPLE) {
      widget.bodyContent.navToText = `Start free trial to set up`;
      widget.navTo = () => this.useCaseService.navTo(EUseCaseNavToKeys.SampleAccount);
      widget.skipNavigationConfirm = true;
    } else {
      if (tagCount === 0) {
        widget.bodyContent.navToText = undefined;
        widget.navTo = widget.topClickAction = undefined;
        widget.topTooltip = UnapprovedTagsWidget.topTooltip;
      } else {
        widget.bodyContent.navToText = 'View Tags';
        widget.navTo = widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.UnapprovedTags);
        widget.topTooltip = `${widget.topTooltip} Click to view additional details.`;
      }
    }

    widget.loading = false;
  }

  // unapproved geos
  updateRequestPrivacyWidgetValues(): void {
    const widget = this.unapprovedGeoLocationsWidget;
    const assignedCCCount = this.requestPrivacyInfo?.assignedCCCount;
    const requestGeosCount = this.requestPrivacyInfo?.totalUnapprovedRequestGeos;

    widget.topValue = assignedCCCount === 0 ? '<span class="small-value">Not configured</span>' : requestGeosCount?.toLocaleString() || '--';
    widget.topValueMeaning = requestGeosCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.topIconName = UnapprovedGeoLocationsWidget.topIconName;
    widget.topIconMeaning = requestGeosCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.NEUTRAL;

    if (this.accountType === EAccountType.SAMPLE) {
      widget.bodyContent.bodyText = `Identifying cross-border data transfers in violation of your established standards is vital to data privacy governance. Upon upgrading to a free trial or purchasing a full account, you will be able to specify which countries or regions should be allowed to receive data from your website. We will then let you know if data is going anywhere it shouldn't.`;
    } else {
      widget.bodyContent.bodyText = assignedCCCount === 0
        ? `Identifying cross-border data transfers in violation of your established standards is vital to data privacy governance. You can specify which countries or regions should be allowed to receive data from your website, and then we will let you know if data is going anywhere it shouldn't.<br><br><span class="use-case-privacy-request-cc-edit-link use-case-clickable-link">Apply a Consent Category to this audit.</span>`
        : `Identifying cross-border data transfers in violation of your established standards is vital to data privacy governance. We monitor for and flag any locations receiving data from your website that are not included in the Consent Categories assigned to this audit, enabling you to quickly address violations as they occur. <span class="use-case-privacy-request-cc-edit-link use-case-clickable-link">Add, update, or remove Consent Categories assigned to this audit.</span>`;
    }

    if (!this.isGuestOrVisitor && this.accountType !== EAccountType.SAMPLE) {
      setTimeout(() => {
        if (this.openRequestsEditAuditCCEventListener) {
          this.openRequestsEditAuditCCEventListener.removeEventListener();
        }

        this.openRequestsEditAuditCCEventListener = document.querySelector('.use-case-privacy-request-cc-edit-link')?.addEventListener('click', () => {
          this.openAuditStandardsToCC();
        });
      });
    }

    if (this.accountType === EAccountType.SAMPLE) {
      widget.bodyContent.navToText = `Start free trial to enable`;
      widget.navTo = () => this.useCaseService.navTo(EUseCaseNavToKeys.SampleAccount);
      widget.skipNavigationConfirm = true;
    } else {
      if (assignedCCCount === 0) {
        widget.bodyContent.navToText = undefined;
        widget.navTo = widget.topClickAction = undefined;
        widget.topTooltip = UnapprovedGeoLocationsWidget.topTooltip;
      } else {
        widget.bodyContent.navToText = 'View Request Domains & Geos';
        widget.navTo = widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.UnapprovedGeoLocations);
        widget.topTooltip = `${widget.topTooltip} Click to view additional details.`;
      }
    }

    widget.loading = false;
  }

  openAuditStandardsToCC(): void {
    this.useCaseService.openAuditStandardsToCC(1, EStandardsTabs.ConsentCategories);
  }

  openCCManager(): void {
    this.useCaseService.openConsentCategoryManager(1, EStandardsTabs.ConsentCategories);
  }
}
