import { IFullscreenChartConfig } from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';

export enum TagPrivacyTrendName {
  UniqueTags = 'unique_tags',
  ApprovedTags = 'approved_tags',
  UnApprovedTags = 'unapproved_tags',
}

function getChartConfig(title: string): IFullscreenChartConfig {
  return {
    title,
    data: [],
    sideLabel: 'number',
    showXAxis: true,
    showYAxis: true,
    showSummaryLines: true,
    tooltip: (value: number) => (`${ value } tag${ value === 1 ? '' : 's' }`)
  };
}

export const UNIQUE_TAGS_CHART_CONFIG = getChartConfig('Unique Tags');
export const APPROVED_TAGS_CHART_CONFIG = getChartConfig('Approved Tags');
export const UNAPPROVED_TAGS_CHART_CONFIG = getChartConfig('Unapproved Tags');
