<component-template componentName="OP Select">
  <component-library-section sectionName="Overview" selector="<op-select>">
    <p>The op-select component provides a user-friendly interface for selecting one or more items from a predefined list. It offers various customization options, including single or multiple selection modes, search functionality, and the ability to handle large datasets efficiently.</p>

    <div class="op-select-example">
      Default appearance:
      <op-select [data]="data"></op-select>
    </div>

    <div class="op-select-example">
      Outline appearance:
      <op-select [data]="data" [appearance]="'outline'"></op-select>
    </div>

    <div class="op-select-example">
      Disabled example:
      <op-select [data]="data" [disabled]="true"></op-select>
    </div>
  </component-library-section>
</component-template>