import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { EChartColor } from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IStackedBarChartDataPoint,
  IStackedLimitBarChartFormattedDataPoint
} from '@app/components/shared/components/viz/horizontal-stacked-limit-bar-chart/horizontal-stacked-limit-bar-chart.models';
import { EUsageTabTitleChartIds, EUsageTabTitleChartTypes } from './usage-tab-title.enums';
import { IAuditUsage } from '@app/components/usage-v2/usage-v2.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-tab-title-chart',
  templateUrl: './usage-tab-title-chart.component.html',
  styleUrls: ['./usage-tab-title-chart.component.scss']
})
export class UsageTabTitleChartComponent implements OnChanges {
  EUsageTabTitleChartIds = EUsageTabTitleChartIds;
  EUsageTabTitleChartTypes = EUsageTabTitleChartTypes;

  @ViewChild('usedLabel') usedLabel: ElementRef;
  @ViewChild('usedTooltip') usedTooltip: ElementRef;
  @ViewChild('availableLabel') availableLabel: ElementRef;
  @ViewChild('availableTooltip') availableTooltip: ElementRef;

  @Input() type: EUsageTabTitleChartTypes;
  @Input() used: number;
  @Input() total: number;
  @Input() filteredTo: number;
  @Input() pacingFrom: number;
  @Input() pacingTo: number;
  @Input() auditUsage: IAuditUsage;

  data: [IStackedBarChartDataPoint, IStackedBarChartDataPoint];
  limits: IStackedBarChartDataPoint[];
  filtered: IStackedBarChartDataPoint;
  tooltipClass = 'usage-tab-title-tooltip';

  ngOnChanges() {
    const ratio = this.calcUsageRatio(this.total, this.used);
    this.data = [
      {
        id: EUsageTabTitleChartIds.Available,
        value: this.total ? this.total : this.used,
        filtered: false,
        name: 'Scans Available',
        textOnHover: ratio.remainingPercent + '%',
        colorClass: EChartColor.Gray
      },
      {
        id: EUsageTabTitleChartIds.Scanned,
        value: this.used,
        filtered: false,
        textOnHover: ratio.usedPercent + '%',
        name: 'Page Scanned',
        colorClass: EChartColor.Blue,
      }
    ];

    if (this.filteredTo !== undefined) {
      this.filtered = {
        id: EUsageTabTitleChartIds.Filtered,
        value: this.filteredTo,
        filtered: false,
        name: 'Filtered',
        colorClass: EChartColor.OPYellow
      };
    } else {
      this.filtered = null;
    }

    this.limits = [];
    if (this.pacingFrom) {
      this.limits.push({
          id: EUsageTabTitleChartIds.Pacing,
          value: this.pacingFrom,
          filtered: false,
          name: 'Page Scanned',
          colorClass: EChartColor.Purple,
      });
    }
  }

  private calcUsageRatio(total: number, used: number): { usedPercent: number, remainingPercent: number } {
    const usedPercent = total ? Math.round(used / total * 100) : 0;
    const remainingPercent = 100 - usedPercent;
    return { usedPercent, remainingPercent };
  }

  private getDaysBetweenDates(startDate: string | Date, endDate: string | Date): number {
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    const millisecondInDay = 1000 * 60 * 60 * 24;

    return (end - start) / millisecondInDay;
  }

  getTooltipFn = (data: IStackedLimitBarChartFormattedDataPoint, auditUsage: IAuditUsage = this.auditUsage): string => {
    const startDate = auditUsage.currentContractTerm.startDateInclusive;
    const endDate = auditUsage.currentContractTerm.endDateInclusive;
    const currentDate = new Date();

    const totalDuration = this.getDaysBetweenDates(startDate, endDate);
    const elapsedDuration = this.getDaysBetweenDates(startDate, currentDate);
    const percentage =  ((elapsedDuration / totalDuration) * 100).toFixed(0);

    if (data.id === EUsageTabTitleChartIds.Scanned) {
      const ratio = this.calcUsageRatio(this.total, this.used);
      return this.getTooltipTemplate(
        this.type === EUsageTabTitleChartTypes.Audit ? 'PAGES SCANNED TO-DATE' : 'JOURNEY RUNS TO-DATE',
        this.used.toLocaleString(),
        this.total ? this.total.toLocaleString() : '&infin;',
        ratio.usedPercent,
        data.colorClass);
    } else if (data.id === EUsageTabTitleChartIds.Available) {
      const ratio = this.calcUsageRatio(this.total, this.used);
      return this.getTooltipTemplate(
        this.type === EUsageTabTitleChartTypes.Audit ? 'PAGE SCANS REMAINING' : 'JOURNEY RUNS REMAINING',
        this.total ? (this.total - this.used).toLocaleString() : '&infin;',
        this.total ? this.total.toLocaleString() : '&infin;',
        ratio.remainingPercent,
        data.colorClass
      );
    } else if (data.id === EUsageTabTitleChartIds.Filtered) {
      const ratio = this.calcUsageRatio(this.total, this.filteredTo);
      return this.getTooltipFilteredTemplate(
        this.filteredTo.toLocaleString(),
        this.total ? this.total.toLocaleString() : '&infin;',
        ratio.usedPercent,
        data.colorClass
      );
    } else if (data.id === EUsageTabTitleChartIds.Pacing) {
      return this.getTooltipPacingTemplate('RECOMMENDED PACING', this.pacingFrom, data.colorClass, percentage);
    } else if (data.id === EUsageTabTitleChartIds.Total) {
      return this.getTooltipPacingTemplate('TERMS LIMIT', this.pacingTo, data.colorClass, percentage);
    }
  }

  private getTooltipTemplate(header: string, a: string, b: string, ratio: number, valueClass: string) {
    return `<div class="tooltip-header">${header}</div>
            <div class="tooltip-status"><span class="first-value ${valueClass}">${a}</span> of ${b}</div>
            <div class="tooltip-ratio">(${ratio}%)</div>`;
  }

  private getTooltipFilteredTemplate(a: string, b: string, ratio: number, valueClass: string) {
    return `<div class="tooltip-header">PAGES SCANNED TO-DATE</div>
            <div class="tooltip-status"><span class="first-value filtered ${valueClass}">${a}(<i></i>)</span> of ${b} Total</div>
            <div class="tooltip-ratio filtered">(${ratio}%)</div>`;
  }

  private getTooltipPacingTemplate(header: string, pacing: number, valueClass: string, percentage: string): string {
    return `<div class="tooltip-header mb-10">${header}</div>
            <div class="limit-data">
              <div>As of the current date, ${percentage}% of the subscription period has elapsed. 
              To fully utilize the purchased volume, it is recommended to have ${this.type === EUsageTabTitleChartTypes.Audit ? 'scanned' : 'run'} approximately</div>
              <span class="tooltip-pacing-total ${valueClass}">${pacing.toLocaleString()}</span>
              ${this.type === EUsageTabTitleChartTypes.Audit ? 'pages' : 'journeys'}
              </div>
            </div>`;
  }

  usedTooltipShow() {
    const referenceElementRect = this.usedLabel.nativeElement.getBoundingClientRect();
    const tooltipElementRect = this.usedTooltip.nativeElement.getBoundingClientRect();

    this.usedTooltip.nativeElement.style.top = referenceElementRect.top - tooltipElementRect.height + 'px';
    this.usedTooltip.nativeElement.style.left = referenceElementRect.left + 'px';
  }

  tooltipShow(tooltipType: EUsageTabTitleChartIds) {
    let tooltip = tooltipType === EUsageTabTitleChartIds.Scanned ? this.usedTooltip.nativeElement : this.availableTooltip.nativeElement;
    let label = tooltipType === EUsageTabTitleChartIds.Scanned ? this.usedLabel.nativeElement : this.availableLabel.nativeElement;

    const referenceElementRect = label.getBoundingClientRect();
    const tooltipElementRect = tooltip.getBoundingClientRect();

    tooltip.style.top = referenceElementRect.top - tooltipElementRect.height + 'px';
    tooltip.style.left = referenceElementRect.left + 'px';
  }
}
