import { Route } from '@angular/router';
import { AcctAdminOnlyGuard } from '../core/permissions.guard';
import { SharedLinksLibraryComponent } from './shared-links-library.component';
import { SharedLinksLibraryPaths, SharedLinksLibraryNames } from './shared-links-library.constants';

export const SharedLinksLibraryState: Route = {
  path: SharedLinksLibraryPaths.base,
  loadChildren: () => import('./shared-links-library.module').then(mod => mod.SharedLinksLibraryModule),
};

export const SharedLinksLibraryRoutes: Route = {
  path: '',
  canLoad: [ AcctAdminOnlyGuard ],
  component: SharedLinksLibraryComponent,
  data: {
    stateName: SharedLinksLibraryNames.base,
    title: 'Shared Links',
    betaLabel: false,
  },
};
