import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IAccessibilityRuleResult,
  IAuditPageInfoAccessibilityChecksResults
} from '@app/components/audit-reports/page-details/page-details.models';
import {
  PageDetailsAccessibilityService
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { ExportService } from '@app/components/shared/services/export-service';

@Component({
  selector: 'op-page-details-accessibility',
  templateUrl: './page-details-accessibility.component.html',
  styleUrls: ['./page-details-accessibility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PageDetailsAccessibilityService]
})
export class PageDetailsAccessibilityComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();
  @Input() loading: boolean;
  @Input() accessibilityChecksSummary: IAuditPageInfoAccessibilityChecksResults;
  @Input() finalStatusCode: number;
  @Input() itemId: number;
  @Input() runId: number;
  @Input() pageId: string;
  @Input() reportType: EReportType;
  @Input() activeTab?: EPageDetailsTabs; // specific to audits
  filteredItems: IAccessibilityRuleResult[];
  filters$ = this.accessibilityService.filters;

  constructor(
    private accessibilityService: PageDetailsAccessibilityService,
    private exportService: ExportService,
  ) {
  }

  ngOnInit(): void {
    this.accessibilityService.filters
      .pipe(
        filter(() => !!this.accessibilityChecksSummary),
        takeUntil(this.destroy$),
      )
      .subscribe((filters) => {
        this.filteredItems = this.accessibilityChecksSummary.issues.filter((issue) => {
          return true;
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updateFilter(filter): void {
    this.accessibilityService.updateFilters(filter);
  }

  exportAllIssues(): void {
    this.exportService.downloadAllAccessibilityIssues(this.accessibilityChecksSummary, this.finalStatusCode);
  }
}
