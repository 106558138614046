import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Features } from '../../../moonbeamConstants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'live-connect-wrapper',
  templateUrl: './live-connect-wrapper.component.html',
  styleUrls: ['./live-connect-wrapper.component.scss'],
})

export class LiveConnectWrapperComponent implements OnInit {
  isLiveConnectAllowed = false;
  isLoaded = false;

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.authenticationService.getFeaturesWithCache().subscribe(features => {
      this.isLoaded = true;
      this.isLiveConnectAllowed = features.includes(Features.realDeviceConnect);
    });
  }
}
