<ng-container *ngIf="inProgress">
  <div class="headerWrp" [class.in-progress]="inProgress">
  <span class="iconWrp">
    <i class="icon icon-run spinner-icon" [class.in-progress]="inProgress"></i>
  </span>
    <div class="firstRun titleWrp" *ngIf="settings.firstRun">
      <span class="title">
        {{ !settings.lastRunDate ? 'First' : '' }} <span [class.type]="settings.lastRunDate">{{ typeLabel }}</span> run currently in progress
      </span>
      <span class="subTitle">
        Started {{ settings.startedDate }}
      </span>
    </div>
  </div>
  <div class="contentWrp">
    <status-progress-bar [items]="progressBarItems" barSize="lg"></status-progress-bar>
  </div>
</ng-container>
<ng-container *ngIf="(updatesType === 'Audit' && !runNowPending && !inProgress) || (updatesType === 'WebJourney' && !inProgress)">
  <div class="headerWrp">
    <ng-container *ngIf="settings.notRunYet">
<!--
  - Audits that have had runs deleted by the data retention policy will also have 'notRunYet' set to true.
  - If the first run is stopped after it starts processing, it will have a lastRunDate, so this can't
be used reliably to determine whether the run results have been deleted or if it was just stopped.
  - Checking whether the audit was created in the last 13 months as a way to differentiate from an audit that may have simply had the last run deleted by the data retention policy.
-->
      <div class="notRun titleWrp" *ngIf="settings.lastRunDate && settings.finished === null && settings.createdOutsideDataWarehouseWindow">
        <span class="title">The run results have been deleted by your account’s data retention policy</span>
        <span class="subTitle">
          <span *ngIf="isScheduledFlag && isPausedFlag"><span class="type">{{ typeLabel }}</span> is paused</span>
          <span *ngIf="isScheduledFlag && !isPausedFlag">Scheduled for {{ scheduledDate }}</span>
          <span *ngIf="!isScheduledFlag">Not scheduled to run</span>
        </span>
        <span *ngIf="settings.lastRunDate"
              class="subTitle">Last run: {{ settings.lastRunDate | date: dateFormat }}</span>
      </div>
      <div class="notRun titleWrp" *ngIf="!settings.lastRunDate ||
      (settings.lastRunDate && settings.finished !== null)
      || (settings.lastRunDate && settings.finished === null && !settings.createdOutsideDataWarehouseWindow)"
      >
        <span class="title">
          <span class="type">{{ typeLabel }}</span> has not run yet
        </span>
        <span class="subTitle">
          <span *ngIf="isScheduledFlag && isPausedFlag"><span class="type">{{ typeLabel }}</span> is paused</span>
          <span *ngIf="isScheduledFlag && !isPausedFlag">{{ 'Scheduled for ' + scheduledDate }}</span>
          <span *ngIf="!isScheduledFlag">Not scheduled to run</span>
        </span>
      </div>
      <button mat-flat-button class="run-now-btn" (click)="runNow()">
        <mat-icon [class.in-progress]="runNowPending">loop</mat-icon>
        Run Now
      </button>
    </ng-container>
  </div>
</ng-container>
<!--
Displays the status banner as "In Queue" when in a queued state after
initial audit creation, but before the run actually starts running
-->
<ng-container *ngIf="updatesType === 'Audit' && settings.queued && runNowPending && !inProgress">
  <div class="headerWrp" [class.in-progress]="runNowPending">
    <span class="iconWrp">
      <i class="icon icon-run spinner-icon" [class.in-progress]="runNowPending"></i>
    </span>
    <div class="firstRun titleWrp" *ngIf="settings.firstRun || (runNowPending && !activeRunId)">
      <span class="title">
        First {{ typeLabel }} run currently in progress
      </span>
        <span class="subTitle" *ngIf="settings.startedDate && !settings.queued">
          Started {{ settings.startedDate }}
        </span>
        <span class="subTitle" *ngIf="settings.queued">
          Queued
        </span>
    </div>
  </div>
  <div class="contentWrp">
    <status-progress-bar [items]="progressBarItems" barSize="lg"></status-progress-bar>
  </div>
</ng-container>
