import { ERuleMatchType } from '../../rule-setup-conditions-tab.enums';
import { ERuleTagStatusCodeValueType } from '@app/components/rules/rules.models';
import {
  ITagStatusCodeOperator
} from '@app/components/rules/rule-setup/tabs/conditions-tab/shared/rule-tag-status-code-filter/rule-tag-status-code-filter.models';

export const TagStatusCodeOperators = [
  { name: 'equals', value: ERuleMatchType.Equals },
  { name: 'does not equal', value: ERuleMatchType.DoesNotEqual },
];

export const TagStatusCodeValues: ITagStatusCodeOperator[] = [
  { name: 'Success (200s) & Redirect (300s)', valueType: ERuleTagStatusCodeValueType.SuccessAndRedirect },
  { name: 'Broken (0, 400s, 500s)', valueType: ERuleTagStatusCodeValueType.Broken },
];
