import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, skip, takeUntil } from 'rxjs/operators';
import {
  IOpFilterBarV2Filter,
  IOpFilterBarV2MenuItem
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import {
  FilterRemovedEvent
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.constants';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { UsageFilterBarService } from './usage-filter-bar.service';
import { EUsageFilterTypes } from './usage-filter-bar.enums';
import { Features } from '@app/moonbeamConstants';
import { AuthenticationService } from '@app/components/core/services/authentication.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-filter-bar',
  templateUrl: './usage-filter-bar.component.html',
  styleUrls: ['./usage-filter-bar.component.scss']
})
export class UsageFilterBarComponent implements OnInit, OnDestroy {

  @Input() filterBarService: UsageFilterBarService;
  @Input() filters: IOpFilterBarV2Filter<EUsageFilterTypes>[];

  @Output() filtersChanged = new EventEmitter<IOpFilterBarV2Filter<EUsageFilterTypes>[]>();

  validFilterTypes: EUsageFilterTypes[];
  filterBarMenuItems: IOpFilterBarV2MenuItem[] = [];
  showItemsInFilters = 10;

  filterRemovedEventSubscription: number;
  private destroySubject = new Subject<void>();

  constructor(
    filterBarService: UsageFilterBarService,
    private eventManager: IEventManager,
    private authenticationService: AuthenticationService
  ) {
    this.filterBarService = filterBarService;
  }

  async ngOnInit(): Promise<void> {
    this.getValidFilterTypes();
    this.getFilters();
    const webJourneysEnabled = await this.getWebJourneysEnabled();
    this.filterBarService.updateFilterItems(webJourneysEnabled).subscribe(filterBarMenuItems => {
      this.filterBarMenuItems = filterBarMenuItems;
    });

    this.subscribeToEvents();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();

    this.eventManager.unSubscribe(FilterRemovedEvent, this.filterRemovedEventSubscription);
  }

  subscribeToEvents(): void {
    this.filterRemovedEventSubscription = this.eventManager.subscribe(FilterRemovedEvent, () => {
      setTimeout(() => {
        this.filterBarService.initMenu();
      });
    });

    this.filterBarService.filters$
      .pipe(
        takeUntil(this.destroySubject),
        skip(1),
        debounceTime(100)
      )
      .subscribe(filters => this.filtersChanged.emit(filters));
  }

  getValidFilterTypes(): void {
    this.filterBarService.validFilterTypesUpdated$
      .pipe(takeUntil(this.destroySubject))
      .subscribe((validFilterTypes: EUsageFilterTypes[]) => {
        this.validFilterTypes = validFilterTypes;
      });
  }

  getFilters(): Promise<void> {
    return new Promise<void>(resolve => {
      this.filterBarService.filters$
        .pipe(takeUntil(this.destroySubject))
        .subscribe((filters: IOpFilterBarV2Filter<EUsageFilterTypes>[]) => {
          this.filters = filters;
          this.filtersChanged.emit(filters);

          resolve();
        });
    });
  }

  private getWebJourneysEnabled(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authenticationService.isFeatureAllowed(Features.webJourneys).subscribe((allowed: boolean) => {
        resolve(allowed);
      })
    });
  }
}
