import { Names } from '@app/moonbeamConstants';
import { IAmount } from '../../models/utils/amount';
import * as angular from 'angular';
import { IUser } from '../../moonbeamModels';
import { IApiService } from '../api/apiService';
import { IEventManager } from '../eventManager/eventManager';
import { environment } from '@app/environments/environment';
import { IDomain } from '@app/components/domains/domainsService';
import { from, Observable } from 'rxjs';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';

export interface IFolder {
  id: number;
  name: string;
  createdAt?: string;
  createdByUserName?: string;
  createdByUserId?: number;
  domainsCount: number;
  accountId: number;
  auditsRunning?: number;
  simulationsRunning?: number;
  simulationErrors?: number;
  isNew?: boolean;
  subfolders?: IDomain[];
}

export interface IFolderSelected extends IFolder {
  selected: boolean;
  indeterminate: boolean;
}

export interface INewFolder {
  name: string;
  accountId: number;
}

export interface IAllowableResponse {
  method: string;
  responseCodes: Array<number>;
}

export interface IUserFolder {
  id: number;
  name: string;
  accountId: number;
}

export abstract class IFoldersApiService {
  abstract getFolder(id: number): angular.IPromise<IFolder>;
  abstract getFolders(withItemCounts?: boolean, withTotals?: boolean): angular.IPromise<Array<IFolder>>;
  abstract getFoldersObservable(withItemCounts?: boolean, withTotals?: boolean): Observable<Array<IFolder>>;
  abstract getFoldersCount(): angular.IPromise<IAmount>;
  abstract getUsers(folderId: number): angular.IPromise<any>;
  abstract createFolder(newFolder: INewFolder): angular.IPromise<IFolder>;
  abstract updateFolder(folder: IFolder): angular.IPromise<IFolder>;
  abstract addUserFolders(folderId: number, userIds: Array<number>): angular.IPromise<Array<IUser>>;
  abstract removeFolder(id: number): angular.IPromise<any>;
  abstract getFolderOwnerDisplayName(owner: IUser): string;
}

interface IFolderRequest<T> {
  <T>(arg: T): T;
}

const Call = {
  get: 'get',
  post: 'post',
  put: 'put',
  del: 'delete'
};

export class FoldersApiService extends IFoldersApiService {
  spinnerKey: string = 'folders-spinner';
  private allowableResponses: Array<IAllowableResponse> = [
    { method: Call.del, responseCodes: [403] }
  ];

  static $inject = [
    '$http',
    '$q',
    Names.Services.api,
    Names.Services.eventManager
  ];

  constructor(private $http: angular.IHttpService,
    private $q: angular.IQService,
    private apiService: IApiService,
    private eventManager: IEventManager) {
    super();
  }

  root: string = environment.apiUrl;

  getFolder(id: number): angular.IPromise<IFolder> {
    var url = `${this.root}folders/${id}`;
    return this.callApiService(Call.get, url);
  }

  getFolders(withItemCounts: boolean = false, withTotals: boolean = false): angular.IPromise<Array<IFolder>> {
    var url = this.root + `folders?withTotals=${withTotals}&withItemCounts=${withItemCounts}`;
    return this.callApiService(Call.get, url);
  }

  @CacheApiResponse({ liveTime: 5000 })
  getFoldersObservable(withItemCounts: boolean = false, withTotals: boolean = false): Observable<IFolder[]> {
    const url = this.root + `folders?withTotals=${withTotals}&withItemCounts=${withItemCounts}`;
    return from<Observable<IFolder[]>>(this.callApiService(Call.get, url) as any);
  }

  getFoldersCount(): angular.IPromise<any> {
    var url = this.root + 'folders/count';
    return this.callApiService(Call.get, url);
  }

  getUsers(folderId: number): angular.IPromise<any> {
    var url = `${this.root}folders/${folderId}/users`;
    return this.callApiService(Call.get, url);
  }

  createFolder(newFolder: INewFolder): angular.IPromise<IFolder> {
    var url = `${this.root}folders`;
    return this.callApiService(Call.post, url, newFolder);
  }

  addUserFolders(folderId: number, userIds: Array<number>): angular.IPromise<Array<IUser>> {
    var url = `${this.root}folders/${folderId}/users`;
    return this.callApiService(Call.post, url, userIds);
  }

  updateFolder(folder: IFolder): angular.IPromise<IFolder> {
    var url = `${this.root}folders/${folder.id}`;
    return this.callApiService(Call.put, url, folder);
  }

  removeFolder(id: number): angular.IPromise<any> {
    var url = `${this.root}folders/${id}`;
    return this.callApiService(Call.del, url).then(() => { return true; });
  }

  private callApiService<T>(call: string, url: string, data?: any, serializer?: IFolderRequest<T>): angular.IPromise<T> {
    var http = this.$http[call](url, data);
    return this.handleFolderResponse(this.apiService.handleResponse(http, null, this.spinnerKey), serializer, call);
  }

  private handleFolderResponse<T>(promise: angular.IPromise<T>, serializer: IFolderRequest<T>, method: string): angular.IPromise<T> {
    return promise.then((data: T) => {
      if (serializer) {
        return serializer(data);
      }
      return data;
    }, (error) => {
      return this.$q.reject(error);
    });
  }

  private isAlertNeeded(method: string, code: number): boolean {
    let call = method.toLowerCase();
    var isAlertNeeded = true;
    for (let i = 0; i < this.allowableResponses.length && isAlertNeeded; i++) {
      isAlertNeeded =
        !(this.allowableResponses[i].method == call && this.allowableResponses[i].responseCodes.indexOf(code) >= 0);
    }
    return isAlertNeeded;
  }

  getFolderOwnerDisplayName(owner: IUser): string {
    if (!owner) return '—';

    if (owner.firstName && owner.lastName) {
      return `${owner.firstName} ${owner.lastName}`;
    } else if (owner.firstName) {
      return owner.firstName;
    } else if (owner.lastName) {
      return owner.lastName;
    } else {
      return '—';
    }
  }
}
