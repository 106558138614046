import { UseCaseUtils } from '@app/components/audit-reports/use-cases/use-case.utils';
import {
  PrivacyUniqueCookiesStats
} from '@app/components/audit-reports/use-cases/use-case-privacy/use-case-privacy.constants';

export const LandingPagePagesScannedWidget = {
  topText: 'Pages Scanned',
  topTooltip: 'Count of all unique pages scanned in this audit run. Click to view additional details.',
  bodyContent: {
    navToText: 'View Pages report to get more detail',
    bodyText: {
      paid: 'Most sites have critical campaign pages that are not accessible by crawling. Pages like these are often only known to ad vendors like Google or Microsoft, Facebook or LinkedIn social ads, or used in marketing emails. We recommend at least daily validation of these critical pages, many site owners will opt for even more frequent checking. <a href="https://help.observepoint.com/article/48-create-or-edit-an-audit" target="_blank">Learn how to setup a landing page Audit</a>.',
      guest: 'Most sites have critical campaign pages that are not accessible by crawling, these can quickly be setup and scanned with a Free Trial. Pages like these are often only known to ad vendors like Google or Microsoft, Facebook or LinkedIn social ads, or used in marketing emails. We recommend at least daily validation of these critical pages, many site owners will opt for even more frequent checking.',
      trial: 'Most sites have critical campaign pages that are not accessible by crawling. Pages like these are often only known to ad vendors like Google or Microsoft, Facebook or LinkedIn social ads, or used in marketing emails. We recommend at least daily validation of these critical pages, many site owners will opt for even more frequent checking. <a href="https://help.observepoint.com/article/48-create-or-edit-an-audit" target="_blank">Learn how to setup a landing page Audit</a>.',
    }
  }
}

export const LandingPageBrokenPagesWidget = {
  topText: 'Broken Pages',
  topTooltip: 'Count of all pages that resolved to an HTTP status code of 400+ or 500+. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bodyContent: {
    navToText: 'View final broken pages',
    bodyText: {
      paid: 'Broken landing pages lead to bad user experiences and wasted marketing spend. If this number is ever over zero you\'ll want to take quick action to remedy the problem. At a minimum you would want to pause any ads sending traffic to the broken pages.',
      guest: 'Broken landing pages lead to bad user experiences and wasted marketing spend. If this number is ever over zero you\'ll want to take quick action to remedy the problem. At a minimum you would want to pause any ads sending traffic to the broken pages.',
      trial: 'Broken landing pages lead to bad user experiences and wasted marketing spend. If this number is ever over zero you\'ll want to take quick action to remedy the problem. At a minimum you would want to pause any ads sending traffic to the broken pages.',
    }
  }
};

export const LandingPageAveragePageLoadTimeWidget = {
  topText: 'Average Page Load Time',
  topTooltip: 'Average load time in seconds of all pages scanned. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: '< 3 sec',
  bodyContent: {
    navToText: 'View slow loading pages',
    bodyText: {
      paid: 'Google recommends that all pages load in three seconds or less. Pages notably over this threshold can not only lead to bad user experiences, but they can also impact things like your quality score of paid ads, leading to higher costs. SEO is also negatively affected by slow loading pages.',
      guest: 'Google recommends that all pages load in three seconds or less. Pages notably over this threshold can not only lead to bad user experiences, but they can also impact things like your quality score of paid ads, leading to higher costs. SEO is also negatively affected by slow loading pages.',
      trial: 'Google recommends that all pages load in three seconds or less. Pages notably over this threshold can not only lead to bad user experiences, but they can also impact things like your quality score of paid ads, leading to higher costs. SEO is also negatively affected by slow loading pages.',
    }
  }
};

export const LandingPagePagesMissingQueryStringsWidget = {
  topText: 'Pages without<br>Query Strings',
  topTooltip: 'Count of pages without any query strings. May be an indicator that campaign tracking isn\'t working correctly. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: '0',
  bodyContent: {
    navToText: 'View pages without query strings',
    bodyText: {
      paid: 'Tag & Variable rules can be used to automatically verify that query string values are correctly being transferred to analytics tags. <a href="https://help.observepoint.com/article/246-creating-user-defined-rules" target="_blank">Learn how to setup a Tag & Variable Rule</a>.',
      guest: 'Landing pages without proper campaign tracking make measuring performance nearly impossible. Pages missing query string parameters (utm, cid, etc.) are a good place to start addressing poor measurement. Once you become a customer, simple rules can be configured to look for specific query strings.',
      trial: 'Tag & Variable rules can be used to automatically verify that query string values are correctly being transferred to analytics tags. <a href="https://help.observepoint.com/article/246-creating-user-defined-rules" target="_blank">Learn how to setup a Tag & Variable Rule</a>.',
    }
  }
};

export const LandingPagePagesWithRedirectsStats = {
  min: '0',
  max: '50',
  median: '4',
  average: '10',
  ninetyFifthPercentile: '49',
  ninetyNinthPercentile: '50',
};

export const LandingPagePagesWithRedirectsWidget = {
  topText: 'Pages with Redirects',
  topTooltip: 'Pages that pass through one or more redirects before resolving to a final URL. Click to view additional details.',
  statsData: {
    headerText: '<div class="header-text-top">PAGES WITH REDIRECTS</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...LandingPagePagesWithRedirectsStats,
  },
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    LandingPagePagesWithRedirectsStats.median,
    LandingPagePagesWithRedirectsStats.average,
    LandingPagePagesWithRedirectsStats.min,
    LandingPagePagesWithRedirectsStats.max,
  ),
  bodyContent: {
    navToText: 'View pages with redirects',
    bodyText: {
      paid: 'While redirects in and of themselves are not necessarily "bad" – depending on your tracking methodology they may even be necessary – but excessive use of redirects can have direct impact on both user experience and measurement systems. ',
      guest: 'While redirects in and of themselves are not necessarily "bad" – depending on your tracking methodology they may even be necessary – but excessive use of redirects can have direct impact on both user experience and measurement systems. ',
      trial: 'While redirects in and of themselves are not necessarily "bad" – depending on your tracking methodology they may even be necessary – but excessive use of redirects can have direct impact on both user experience and measurement systems. ',
    }
  }
};

export const LandingPageUniqueTagsStats = {
  min: '1',
  max: '613',
  median: '34',
  average: '50',
  ninetyFifthPercentile: '143',
  ninetyNinthPercentile: '293',
};

export const LandingPageUniqueTagsWidget = {
  topText: 'Unique Tags',
  topTooltip: 'Total number of unique tags (identified technologies) found in this audit run. Click to view additional details.',
  statsData: {
    headerText: '<div class="header-text-top">UNIQUE TAGS</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...LandingPageUniqueTagsStats,
  },
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    LandingPageUniqueTagsStats.median,
    LandingPageUniqueTagsStats.average,
    LandingPageUniqueTagsStats.min,
    LandingPageUniqueTagsStats.max,
  ),
  bodyContent: {
    navToText: 'View tags',
    bodyText: {
      paid: 'Accurate campaign measurement is a key component of all marketing strategies. With just a little configuration in Tag & Variable Rules we can ensure that proper tracking codes are being correctly sent to analytics tags. <a href="https://help.observepoint.com/article/246-creating-user-defined-rules" target="_blank">Learn how to setup a rule for this use case</a>.',
      guest: 'Accurate campaign measurement is a key component of all marketing strategies. With just a little configuration we can ensure that proper tracking codes are being correctly sent to analytics tags.',
      trial: 'Accurate campaign measurement is a key component of all marketing strategies. With just a little configuration in Tag & Variable Rules we can ensure that proper tracking codes are being correctly sent to analytics tags. <a href="https://help.observepoint.com/article/246-creating-user-defined-rules" target="_blank">Learn how to setup a rule for this use case</a>.',
    }
  }
};

export const LandingPagePagesMissingAnalyticsWidget = {
  topText: 'Pages Missing Analytics',
  topTooltip: 'Count of pages where no analytics tag was found. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: '0',
  bodyContent: {
    navToText: 'View Pages without Analytics tags',
    bodyText: {
      paid: 'All landing pages should have some form of analytics technology on them to ensure accurate measurement of marketing campaigns. We recommend setting up an Alert for all of your critical measurement technologies.',
      guest: 'All landing pages should have some form of analytics technology on them to ensure accurate measurement of marketing campaigns. We recommend setting up an Alert for all of your critical measurement technologies. Start your free trial to setup alerts.',
      trial: 'All landing pages should have some form of analytics technology on them to ensure accurate measurement of marketing campaigns. We recommend setting up an Alert for all of your critical measurement technologies.',
    }
  }
};

export const LandingPagePagesMissingTagManagerWidget = {
  topText: 'Pages Missing a<br>Tag Manager',
  topTooltip: 'Count of pages where no tag manager was found. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: '0',
  bodyContent: {
    navToText: 'View Pages without a Tag Manager',
    bodyText: {
      paid: 'Tag Management Systems (TMS) form the backbone of accurate and reliable data collection. A tag manager allows easy remediation if tagging errors are found. These technologies are also key to compliance with privacy regulations. As a best practice you\'ll want to be alerted when pages are missing your tag manager.',
      guest: 'Tag Management Systems (TMS) form the backbone of accurate and reliable data collection. A tag manager allows easy remediation if tagging errors are found. These technologies are also key to compliance with privacy regulations. As a best practice you\'ll want to be alerted when pages are missing your tag manager. Start your free trial to setup these critical alerts.',
      trial: 'Tag Management Systems (TMS) form the backbone of accurate and reliable data collection. A tag manager allows easy remediation if tagging errors are found. These technologies are also key to compliance with privacy regulations. As a best practice you\'ll want to be alerted when pages are missing your tag manager.',
    }
  }
};
