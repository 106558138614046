import { Component } from '@angular/core';
import { EHistoryType } from '@app/components/shared/components/breadcrumb/breadcrumb.enums';
import { BreadcrumbHistory } from '@app/components/shared/components/breadcrumb/breadcrumb.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-breadcrumb',
  templateUrl: './cl-breadcrumb.component.html',
  styleUrls: ['./cl-breadcrumb.component.scss']
})
export class ComponentLibraryBreadcrumbComponent {

  dummyBreadcrumb: BreadcrumbHistory = [
    {
      type: EHistoryType.Link,
      text: 'Home',
      url: '/home'
    },
    {
      type: EHistoryType.Link,
      text: 'Products',
      url: '/products'
    },
    {
      type: EHistoryType.Link,
      text: 'Electronics',
      url: '/products/electronics'
    },
    {
      type: EHistoryType.Link,
      text: 'Smartphones',
      url: '/products/electronics/smartphones'
    },
    {
      type: EHistoryType.Link,
      text: 'iPhone 13',
      url: '/products/electronics/smartphones/iphone-13'
    }
  ];

  dummyDropdownBreadcrumb: BreadcrumbHistory = [
    {
      type: EHistoryType.Dropdown,
      selectedOption: 'Electronics',
      options: [
        { type: EHistoryType.Link, text: 'Home', url: '/home' },
        { type: EHistoryType.Link, text: 'Products', url: '/products' },
        { type: EHistoryType.Link, text: 'Electronics', url: '/products/electronics' },
        { type: EHistoryType.Link, text: 'Smartphones', url: '/products/electronics/smartphones' },
        { type: EHistoryType.Link, text: 'iPhone 13', url: '/products/electronics/smartphones/iphone-13' }
      ]
    }
  ];

}
