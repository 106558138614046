import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAdminTag } from '../../../admin-accounts.service';
import { IAccountTag, TagsService } from '@app/components/tags/tagsService';
import { IAdminTagObj, IAdminTagsEmitter } from './admin-tags.models';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

enum ETagsTabView {
  Regular,
  Customer
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'admin-tags',
  templateUrl: './admin-tags.component.html',
  styleUrls: ['./admin-tags.component.scss']
})
export class AdminTagsComponent implements OnInit {

  readonly CUSTOMER_TAG_CATEGORY_ID: number = 7;
  readonly views = ETagsTabView;

  allRegularTags: IAdminTagObj[];
  allCustomerTags: IAdminTagObj[];

  @Input() allTags: IAccountTag[];
  @Input() enabledTags: IAdminTag[];

  @Output() saveTags: EventEmitter<IAdminTagsEmitter> = new EventEmitter;

  constructor(private tagsService: TagsService) {}

  ngOnInit(): void {
    this.createTagArrays();
  }

  private createTagArrays(): void {
    const enabledTagIds = this.enabledTags.map((tag: IAdminTag) => tag.id);

    this.allRegularTags = this.allTags
      .filter((tag: IAccountTag) => tag.category.id !== this.CUSTOMER_TAG_CATEGORY_ID)
      .map((tag: IAccountTag) => ({
        id: tag.id,
        name: tag.name,
        icon: UiTagService.getTagIconUrl(tag.id),
        enabled: enabledTagIds.includes(tag.id)
      }))
      .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    this.allCustomerTags = this.allTags
      .filter((tag: IAccountTag) => tag.category.id === this.CUSTOMER_TAG_CATEGORY_ID)
      .map((tag: IAccountTag) => ({
        id: tag.id,
        name: tag.name,
        icon: UiTagService.getTagIconUrl(tag.id),
        enabled: enabledTagIds.includes(tag.id)
      }))
      .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
  }
}
