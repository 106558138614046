import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EAlertFilterType, EAlertMenuContext } from '@app/components/alert/alert.enums';
import {
  EUsageTabTitleChartTypes
} from '@app/components/usage-v2/components/usage-tab-title-chart/usage-tab-title.enums';
import {
  IAuditUsage,
  IUsageContractTerm,
  IWebJourneyUsage
} from '@app/components/usage-v2/usage-v2.models';
import { IUser } from '@app/moonbeamModels';
import { EAlertUsageMetricV2 } from '@app/components/alert/alert-logic/alert-logic.enums';
import { Observable } from 'rxjs';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import {
  IOpFilterBarV2Filter
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EUsageFilterTypes } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-main-content-tabs',
  templateUrl: './usage-main-content-tabs.component.html',
  styleUrls: ['./usage-main-content-tabs.component.scss']
})
export class UsageMainContentTabsComponent {
  EUsageTabTitleChartTypes = EUsageTabTitleChartTypes;
  EAlertMenuContext = EAlertMenuContext;
  EAlertFilterType = EAlertFilterType;

  currentTab: EUsageTabTitleChartTypes = EUsageTabTitleChartTypes.Audit;
  @Input() isWebJourneysAllowed = false;
  @Input() user: IUser;
  @Input() webJourneys = [];
  @Input() audits = [];

  @Input() auditUsage: IAuditUsage;

  @Input() webJourneyUsage: IWebJourneyUsage;
  @Input() filters: IOpFilterBarV2Filter<EUsageFilterTypes>[];

  usageAlertMetric = {
    AUDIT_12_MONTH: EAlertUsageMetricV2.AccountUsageCurTermAuditPageScannedCount,
    AUDIT_CURRENT_MONTH: EAlertUsageMetricV2.AccountUsageMonthPaceAuditPageScannedCount,
    WEB_JOURNEY_12_MONTH: EAlertUsageMetricV2.AccountUsageCurTermWebJourneyRunsCount,
    WEB_JOURNEY_CURRENT_MONTH: EAlertUsageMetricV2.AccountUsageMonthPaceWebJourneyRunsCount,
    USERS_LOGGED_IN_LAST_30_DAYS: EAlertUsageMetricV2.UsersLoggedInLast30Days,
  };

  isVisitorMode$: Observable<boolean>;

  constructor(private applicationChromeService: ApplicationChromeService) {
    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
  }

  @Output() tabChanged = new EventEmitter<EUsageTabTitleChartTypes>();
  @Output() auditTermSelected = new EventEmitter<IUsageContractTerm>();
  @Output() webJourneyTermSelected = new EventEmitter<IUsageContractTerm>();

  changeTab(tab: EUsageTabTitleChartTypes) {
    if (!this.isWebJourneysAllowed) return;
    this.currentTab = tab;
    this.tabChanged.emit(tab);
  }

  onAuditTermSelected(term: IUsageContractTerm) {
    if (this.auditUsage.currentContractTerm.sequence !== term.sequence) {
      this.auditUsage.currentContractTerm = term;
      this.auditTermSelected.emit(term);
    }
  }

  onWebJourneyTermSelected(term: IUsageContractTerm) {
    if (this.webJourneyUsage.currentContractTerm.sequence !== term.sequence) {
      this.webJourneyUsage.currentContractTerm = term;
      this.webJourneyTermSelected.emit(term);
    }
  }
}
