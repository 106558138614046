import * as angular from 'angular';

import { AngularNames, Events } from '@app/moonbeamConstants';
/**
 * @ngdoc directive
 * @name moonbeam.directive:NavigateToMessageDirective
 * @restrict AE
 * @scope
 *
 * @see inputMaterialDirrective
 *
 * @description
 * This directive used to scroll to invalid (top or bottom) first invalid input, if it's not visible in current viewport.
 * It supports only vertical scrolling.
 *
 * Usage example:
 *
 * <div>                                      // parent container with vertical scroll
 *   <form>
 *     <div scroll-to>                        // form input with validation
 *       <input input-material ...>
 *       <div validate-messages ...></div>
 *     </div>
 *
 *     <div scroll-to>                        // form input with validation
 *       <input input-material ...>
 *       <div validate-messages ...></div>
 *     </div>
 *   </form>
 * </div>
 *
 * To trigger scrolling to first invalid input in form you need to send broadcast event : `Events.scrollToValidation`
 *
 * How it works :
 *  1) Dirrective receives `Events.scrollToValidation broadcast` event;
 *  2) Checking if current element is invalid and not visible;
 *  3) Looking for a nearest parent with scroll;
 *  4) Calculates scroll dirrection and offset to make current ivalid input visible;
 *  5) In case if current invalid item is visible scroll will stay the same;
 *
 * If form has more then one invalid elements, dirrective will scroll to only first from top element.
 *
 * Scroll dirrective defines current element as invalid if it has 'ng-invalid' or 'has-error' class.
 */

const SCROLL_ANIMATION_DURATION = 500;

const SCROLLING_BLOCK_CLASS = 'scrolling-to-invalid';
const SCROLL_PARENT_CLASS = 'scrollable-container';

const SCROLL_PARENT_SELECTOR = '.' + SCROLL_PARENT_CLASS;

export class ScrollToInvalidFormDirective implements angular.IDirective {
  restrict = 'AE';
  replace = true;
  link = (
    scope: angular.IScope,
    element: JQuery,
    attributes: ng.IAttributes
  ) => {
    var self = this;

    var getScrollableParent = function() {
      var scrollableParent = element.closest(SCROLL_PARENT_SELECTOR);
      var scrollableParentNotExists = elementNotExists(scrollableParent);

      if (scrollableParentNotExists) {
        scrollableParent = findScrollableParent();

        if (elementNotExists(scrollableParent)) {
          console.warn(
            "scroll-to : Can't find scrollable parent, scroll to invalid input won't work!"
          );
          return null;
        }

        scrollableParent.addClass(SCROLL_PARENT_CLASS);
      }

      return scrollableParent;
    };

    var elementNotExists = function(element: JQuery) {
      return element.length <= 0;
    };

    var findScrollableParent = function() {
      return element
        .parents()
        .filter(hasScrollFilter)
        .first();
    };

    var hasScrollFilter = function(index: number, parentElement: Element) {
      var parent = $(parentElement);
      var overflow = parent.css('overflow');
      var overflowY = parent.css('overflow-y');
      var scrollable =
        isScrollOverflow(overflow) || isScrollOverflow(overflowY);
      var scrollVisible =
        parentElement.scrollHeight > parentElement.clientHeight;
      return scrollable && scrollVisible;
    };

    var isScrollOverflow = function(overflow: string) {
      return overflow == 'auto' || overflow == 'scroll';
    };

    var blockScrolling = function(scrollableParent: JQuery) {
      scrollableParent.addClass(SCROLLING_BLOCK_CLASS);
    };

    var unblockScrolling = function(scrollableParent: JQuery) {
      scrollableParent.removeClass(SCROLLING_BLOCK_CLASS);
    };

    var isBlockedScrolling = function(scrollableParent: JQuery) {
      return scrollableParent.hasClass(SCROLLING_BLOCK_CLASS);
    };

    var doScrollAnimation = function(
      scrollTargetPosition: number,
      scrollableParent: JQuery
    ) {
      var animation = { scrollTop: scrollTargetPosition };
      blockScrolling(scrollableParent);
      scrollableParent.animate(animation, SCROLL_ANIMATION_DURATION, () =>
        unblockScrolling(scrollableParent)
      );
    };

    var doScrollToValidation = function() {
      var scrollableParent = getScrollableParent();
      if (scrollableParent == null) {
        return;
      }

      if (isBlockedScrolling(scrollableParent)) {
        return;
      }

      var topOffset = element.offset().top;
      var parentHeight = scrollableParent.height();
      var scrollTop = scrollableParent.scrollTop();

      var scrollToTop = topOffset < 0;
      var scrollToBottom = topOffset > scrollTop + parentHeight;

      var paddingTop = parseInt(element.css('padding-top'));
      var paddingBottom = parseInt(element.css('padding-bottom'));
      var marginTop = parseInt(element.css('margin-top'));
      var marginBottom = parseInt(element.css('margin-bottom'));
      var height = element.height();
      var totalHeight =
        height + paddingTop + paddingBottom + marginTop + marginBottom;

      if (scrollToTop) {
        var scrollTarget = scrollTop + topOffset - totalHeight;
        doScrollAnimation(scrollTarget, scrollableParent);
      } else if (scrollToBottom) {
        var scrollTarget = topOffset - totalHeight;
        doScrollAnimation(scrollTarget, scrollableParent);
      } else {
        blockScrolling(scrollableParent);
        self.$timeout(
          () => unblockScrolling(scrollableParent),
          SCROLL_ANIMATION_DURATION
        );
      }
    };

    var doScrollTo = function() {
      var scrollableParent = getScrollableParent();
      var parentOffset = scrollableParent.offset().top;
      var topOffset = element.offset().top;
      var marginTop = parseInt(element.css('margin-top'));
      var scrollTarget = topOffset - parentOffset - marginTop;
      doScrollAnimation(scrollTarget, scrollableParent);
    };

    var scrollToValidation = function() {
      var isInvalid =
        element.hasClass('ng-invalid') || element.hasClass('has-error');
      var isVisible = element.is(':visible');
      if (isInvalid && isVisible) {
        self.$timeout(doScrollToValidation, 50);
      }
    };

    var scrollTo = function() {
      self.$timeout(doScrollTo, 50);
    };

    scope.$on(Events.scrollToValidation, () => {
      self.$timeout(() => scrollToValidation());
    });

    scope.$on(Events.scrollTo, () => {
      if (attributes.flat !== undefined && scope.$eval(attributes.flat)) {
        self.$timeout(() => scrollTo());
      }
    });
  }

  static factory(): angular.IDirectiveFactory {
    const directive = ($timeout: angular.ITimeoutService) => {
      return new ScrollToInvalidFormDirective($timeout);
    };
    directive.$inject = [AngularNames.timeout];
    return directive;
  }

  constructor(private $timeout: angular.ITimeoutService) {}
}
