import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccountsService } from '@app/components/account/account.service';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import {
  EFilterBarV2MenuTypes,
  FilterRemovedEvent,
  MenuClosedEvent
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.constants';
import {
  IOpFilterBarV2Filter,
  IOpFilterBarV2MenuItem,
  IOpFilterBarV2MenuItemCustomComponent
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { ISearchByTextEmissionData } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ETriggeredAlertsFilterTypes,
  ETriggeredAlertsFilterBarMenuStrings
} from './triggered-alerts-filter-bar.constants';
import { TriggeredAlertsFilterBarService } from './triggered-alerts-filter-bar.service';
import { Features } from '@app/moonbeamConstants';
import {
  OpFilterBarItemDatepickerComponent
} from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.component';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { AlertMetricType } from '@app/components/alert/alert-logic/alert-logic.enums';
import { MetricTypeFilterUtils } from '@app/components/alert/alert-logic/metric-type-filter/metric-type-filter.utils';
import {
  EOpFilterBarItemDatapickerRanges
} from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.models';
import {
  EManageCardsFilterBarMenuStrings,
  EManageCardsFilterTypes
} from '@app/components/manage/cards/manage-cards-filter-bar/manage-cards-filter-bar.constants';
import {
  OpFilterBarItemDatepickerService
} from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.service';
import { OpFilterBarItemDatepickerUtils } from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.utils';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

const DATEPICKER_COMPONENT_REF = 'datepicker';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'triggered-alerts-filter-bar',
  templateUrl: './triggered-alerts-filter-bar.component.html',
  styleUrls: ['./triggered-alerts-filter-bar.component.scss']
})
export class TriggeredAlertsFilterBarComponent implements OnInit, OnDestroy {

  filterBarMenuItems: IOpFilterBarV2MenuItem[] = [];
  validFilterTypes: ETriggeredAlertsFilterTypes[];
  labelSearchTextUpdated: number;
  folderSearchTextUpdated: number;
  folders: IFolder[] = [];
  dataSourcesLabelSearchTextUpdated: number;
  domains: IDomain[];
  labels: ILabel[];
  labelMenuItems: IOpFilterBarV2MenuItem[];
  folderMenuItems: IOpFilterBarV2MenuItem[];
  dataSourcesLabelMenuItems: IOpFilterBarV2MenuItem[];
  showSubscribedOnly: boolean = false;
  filters: IOpFilterBarV2Filter<string>[] = [];
  menuClosedEventSubscription: number;
  filterRemovedEventSubscription: number;
  labelCheckedStatusMap: { [id: number]: boolean } = {};
  dataSourcesLabelCheckedStatusMap: { [id: number]: boolean } = {};
  dataSourcesFolderSubFolderCheckedStatusMap: { [id: number]: boolean } = {};
  datepickerCustomComponent: IOpFilterBarV2MenuItemCustomComponent = {
    name: ETriggeredAlertsFilterBarMenuStrings.RunDateRange,
    type: EFilterBarV2MenuTypes.CustomComponent,
    value: {
      type: EOpFilterBarItemDatapickerRanges.CustomDateRange,
      dates: OpFilterBarItemDatepickerUtils.getDefaultDateRange()
    },
    customComponentRef: DATEPICKER_COMPONENT_REF,
    action: value => {
      this.datepickerCustomComponent.value = value;
      this.triggeredAlertsFilterBarService.handleDataRangePickerFilter(value, [this.datepickerCustomComponent]);
    }
  };

  reportMetricFilterMenuItems: IOpFilterBarV2MenuItem[] =
    MetricTypeFilterUtils.buildReportMetricFilterMenuItemsV2(
      (reportName: string, metricName: string, metricType: AlertMetricType) =>
        this.setReportMetricFilter(reportName, metricName, metricType));

  private destroy$ = new Subject<void>();

  constructor(
    public triggeredAlertsFilterBarService: TriggeredAlertsFilterBarService,
    public opFilterBarItemDatepickerService: OpFilterBarItemDatepickerService,
    private labelService: LabelService,
    private accountsService: AccountsService,
    private foldersService: IFoldersApiService,
    private eventManager: IEventManager,
    private domainsService: IDomainsService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.triggeredAlertsFilterBarService.addCustomComponentRef(DATEPICKER_COMPONENT_REF, this.datepickerCustomComponent.action, OpFilterBarItemDatepickerComponent);

    this.getValidFilterTypes();
    await this.getFilters();
    await this.updateDomains();
    await this.getData();
    this.buildMenu();
    this.subscribeToEvents();
    this.triggeredAlertsFilterBarService.rebuildChipMenusOnPageLoad(this.filterBarMenuItems);
    this.subscribeToAllFilterChanges();
  }

  subscribeToAllFilterChanges(): void {
    this.triggeredAlertsFilterBarService.filterChipRemoved$.subscribe((filter: ETriggeredAlertsFilterTypes) => {
      // this ensures that when a filter is removed, the menu is rebuilt with the correct checked statuses
      if (filter === ETriggeredAlertsFilterTypes.Folder) {
        this.resetFolderCheckedStatus(this.folderMenuItems);
        this.createFolderCheckedStatusMap();
        this.generateMenuItems();
        this.buildMenu();
      }
    });
  }

  resetFolderCheckedStatus(menuItems: IOpFilterBarV2MenuItem[]) {
    menuItems.forEach((menuItem: IOpFilterBarV2MenuItem) => {
      menuItem.checked = false;
      if (menuItem.children) {
        this.resetFolderCheckedStatus(menuItem.children);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.eventManager.unSubscribe(MenuClosedEvent, this.menuClosedEventSubscription);
    this.eventManager.unSubscribe(FilterRemovedEvent, this.filterRemovedEventSubscription);
  }

  async updateDomains() {
    return this.domains = await this.domainsService.getDomains(true);
  }

  subscribeToEvents(): void {
    this.menuClosedEventSubscription = this.eventManager.subscribe(MenuClosedEvent, () => {
      this.triggeredAlertsFilterBarService.onMenuClosed();
    });

    this.filterRemovedEventSubscription = this.eventManager.subscribe(FilterRemovedEvent, () => {
      setTimeout(() => {
        this.generateMenuItems();
        this.buildMenu();
      });
    });
  }

  getData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      forkJoin([
        this.labelService.getLabels(),
        this.accountsService.getUsers(),
        this.foldersService.getFolders(true, true)
      ])
        .subscribe(([labels, creators, folders]) => {
          this.labels = labels.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
          this.folders = folders;
          this.generateMenuItems();
          resolve();
        });
    });
  }

  getFilters(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.triggeredAlertsFilterBarService.filters$
        .pipe(takeUntil(this.destroy$))
        .subscribe((filters: IOpFilterBarV2Filter<string>[]) => {

          const dateRange = filters.find(f => f.type === ETriggeredAlertsFilterTypes.RunDateRange);

          this.createLabelCheckedStatusMap();
          this.createFolderCheckedStatusMap();

          if (dateRange && !this.filters.length) {
            this.opFilterBarItemDatepickerService.sync(this.opFilterBarItemDatepickerService.getSyncObj(
              dateRange,
              DATEPICKER_COMPONENT_REF,
              this.triggeredAlertsFilterBarService.getCustomComponentByRef(DATEPICKER_COMPONENT_REF).action
            ));
          }
          this.filters = filters;


          if (!filters.find(filter => filter.type === ETriggeredAlertsFilterTypes.RunDateRange)) {
            this.triggeredAlertsFilterBarService.handleDataRangePickerFilter(this.datepickerCustomComponent.value, [this.datepickerCustomComponent]);
          }
          resolve();
        });
    });
  }

  isFolder(id: number): boolean {
    return !!this.folders.find(folder => folder.id === id);
  }

  generateMenuItems(): void {
    if (!this.labels) return;

    this.labelMenuItems = this.generateLabelMenuItems();
    this.dataSourcesLabelMenuItems = this.generateDataSourcesLabelMenuItems();
    this.folderMenuItems = this.organizeDomainsAndFolders(this.domains, this.folders);
  }

  createFolderCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.dataSourcesFolderSubFolderCheckedStatusMap = {};
      return;
    }

    this.filters.forEach((filter: IOpFilterBarV2Filter<string>) => {
      if (filter.type !== ETriggeredAlertsFilterTypes.Folder) return;

      if (filter.menuItems) {
        // filter.menuItems is the folder
        // menuItems.children is the subfolder (domain)
        filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
          if (item.type !== EFilterBarV2MenuTypes.Checkbox) return;
          this.dataSourcesFolderSubFolderCheckedStatusMap[item.id] = item.checked;

          if (item.children?.length) {
            item.children.forEach((child: IOpFilterBarV2MenuItem) => {
              this.dataSourcesFolderSubFolderCheckedStatusMap[child.id] = child.checked;
            });
          }
        });
      }
    });
  }

  organizeDomainsAndFolders(domains: IDomain[], folders: IFolder[], searchValue?: string): IOpFilterBarV2MenuItem[] {
    const domainsToFoldersMap = {};

    domains.forEach((domain: IDomain) => {
      if (!domainsToFoldersMap[domain.folderId]) {
        domainsToFoldersMap[domain.folderId] = [];
      }

      domainsToFoldersMap[domain.folderId].push({
        id: domain.id,
        folderId: domain.folderId,
        name: domain.name,
        type: EFilterBarV2MenuTypes.Checkbox,
        hideSelectedOnSearch: false,
        displayWhen: true,
        checked: this.getCheckedStateById(this.dataSourcesFolderSubFolderCheckedStatusMap, domain.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, parent: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
          this.handleFolderCheckedState(item, checked, parent);
          this.triggeredAlertsFilterBarService.handleFolderFilter(checked, item, menuItems, false, this.folders, domainsToFoldersMap);
        },
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.triggeredAlertsFilterBarService.handleFolderFilter(item.checked, item, menuItems, true, this.folders, domainsToFoldersMap);
        }
      });
    });

    const grouped = folders
      .map((folder: IFolder) => {
        return {
          id: folder.id,
          name: folder.name,
          type: EFilterBarV2MenuTypes.Checkbox,
          checked: this.getCheckedStateById(this.dataSourcesFolderSubFolderCheckedStatusMap, folder.id),
          hideSelectedOnSearch: false,
          children: domainsToFoldersMap[folder.id] || [],
          displayWhen: true,
          action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
            this.handleDomainCheckedState(checked, item);
            this.triggeredAlertsFilterBarService.handleFolderFilter(checked, item, menuItems, false, this.folders, domainsToFoldersMap);
          },
          onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
            this.handleOnlyButtons(item, menuItems, parent);
            this.triggeredAlertsFilterBarService.handleFolderFilter(item.checked, item, menuItems, true, this.folders, domainsToFoldersMap);
          }
        };
      })
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    return grouped;
  }

  handleDomainCheckedState(checked: boolean, item: IOpFilterBarV2MenuItem): void {
    item.children?.forEach(child => {
      child.checked = checked;
    });
  }

  // Triggered when checking a subfolder's checkbox
  handleFolderCheckedState(item, checked: boolean, parent: IOpFilterBarV2MenuItem): void {
    // Update the check state for clicked subfolder checkbox
    parent.children.find(subfolder => {
      return subfolder.id === item.id;
    }).checked = checked;

    // Update folder check state to reflect if all subfolders are selected or not
    let allSubfoldersChecked = true;
    if (checked) {
      parent.children.forEach(subfolder => {
        if (!subfolder.checked) {
          allSubfoldersChecked = false;
        }
      });
    } else {
      allSubfoldersChecked = false;
    }

    parent.checked = allSubfoldersChecked;
  }

  generateLabelMenuItems(): IOpFilterBarV2MenuItem[] {
    return this.labels.map((label: ILabel) => {
      return {
        id: label.id,
        name: label.name,
        checked: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
        type: EFilterBarV2MenuTypes.Checkbox,
        displayWhen: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
          item.displayWhen = checked;
          this.triggeredAlertsFilterBarService.handleAlertsLabelsFilter(checked, item, menuItems);
        },
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.triggeredAlertsFilterBarService.handleAlertsLabelsFilter(item.checked, item, menuItems, true);
        }
      };
    });
  }

  generateDataSourcesLabelMenuItems(): IOpFilterBarV2MenuItem[] {
    return this.labels.map((label: ILabel) => {
      return {
        id: label.id,
        name: label.name,
        checked: this.getCheckedStateById(this.dataSourcesLabelCheckedStatusMap, label.id),
        type: EFilterBarV2MenuTypes.Checkbox,
        displayWhen: this.getCheckedStateById(this.dataSourcesLabelCheckedStatusMap, label.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
          item.displayWhen = checked;
          this.triggeredAlertsFilterBarService.handleDataSourcesLabelFilter(checked, item, menuItems);
        },
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.triggeredAlertsFilterBarService.handleDataSourcesLabelFilter(item.checked, item, menuItems, true);
        }
      };
    });
  }

  getValidFilterTypes(): void {
    this.triggeredAlertsFilterBarService.validFilterTypesUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe((validFilterTypes: ETriggeredAlertsFilterTypes[]) => {
        this.validFilterTypes = validFilterTypes;
      });
  }

  buildMenu(): void {
    this.filterBarMenuItems = [
      {
        name: ETriggeredAlertsFilterBarMenuStrings.AlertsLabels,
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'label',
        children: [
          {
            name: ETriggeredAlertsFilterBarMenuStrings.AlertsLabels,
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search by label name',
            searchAllowsMultipleSelections: true,
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleLabelSearch(event, el),
            children: []
          } as IOpFilterBarV2MenuItem
        ].concat(this.labelMenuItems)
      },
      {
        name: ETriggeredAlertsFilterBarMenuStrings.RunDateRange,
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'date_range',
        children: [
          this.datepickerCustomComponent
        ]
      },
      {
        name: ETriggeredAlertsFilterBarMenuStrings.DataSources,
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'library_books',
        children: [
          {
            name: ETriggeredAlertsFilterBarMenuStrings.DataSourceName,
            type: EFilterBarV2MenuTypes.Flyout,
            icon: 'search',
            children: [
              {
                name: ETriggeredAlertsFilterBarMenuStrings.DataSourceName,
                type: EFilterBarV2MenuTypes.Search,
                searchPlaceholder: 'Search data source by name',
                action: (event: KeyboardEvent, el?: HTMLElement) => {
                  if (event.code === 'Enter') {
                    this.triggeredAlertsFilterBarService.handleDataSourceNameContainsFilter((event.target as HTMLInputElement)?.value.trim().toLowerCase() || '');
                  }
                },
                children: []
              } as IOpFilterBarV2MenuItem,
            ]
          },
          {
            name: ETriggeredAlertsFilterBarMenuStrings.DataSourceLabels,
            type: EFilterBarV2MenuTypes.Flyout,
            icon: 'label',
            children: [
              {
                name: ETriggeredAlertsFilterBarMenuStrings.DataSourceLabels,
                type: EFilterBarV2MenuTypes.Search,
                searchPlaceholder: 'Search data source by label name',
                searchAllowsMultipleSelections: true,
                action: (event: KeyboardEvent, el?: HTMLElement) => this.handleDataSourcesLabelSearch(event, el),
                children: []
              } as IOpFilterBarV2MenuItem
            ].concat(this.dataSourcesLabelMenuItems)
          }
        ]
      },
      {
        name: ETriggeredAlertsFilterBarMenuStrings.FolderAndSubFolder,
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'folder',
        children: [
          {
            name: 'search',
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search by folder or sub-folder',
            searchAllowsMultipleSelections: true,
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleFolderAndSubfolderSearch(event, el),
            children: []
          } as IOpFilterBarV2MenuItem
        ].concat(this.folderMenuItems)
      },
      {
        requiredFeature: Features.webJourneys,
        name: this.showSubscribedOnly
          ? ETriggeredAlertsFilterBarMenuStrings.ShowAllMonitored
          : ETriggeredAlertsFilterBarMenuStrings.OnlyShowMonitored,
        type: EFilterBarV2MenuTypes.Button,
        icon: 'visibility',
        action: () => this.toggleShowMonitoredJourneys(1)
      },
      {
        name: ETriggeredAlertsFilterBarMenuStrings.ReportMetric,
        type: EFilterBarV2MenuTypes.Flyout,
        icon: 'assessment',
        children: this.reportMetricFilterMenuItems
      },
    ];
  }

  handleFolderAndSubfolderSearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.folderSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.folderSearchTextUpdated + 200) {
      el.focus();
      return;
    }

    const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

    this.folderMenuItems.forEach((folder: IOpFilterBarV2MenuItem) => {
      folder.children.forEach((subFolder: IOpFilterBarV2MenuItem) => {
        subFolder.displayWhen = value ? subFolder.name.toLowerCase().includes(value) : true;
      });

      const folderNameMatchesSearchValue = folder.name.toLowerCase().includes(value);
      const folderHasVisibleSubfolder = !!folder.children.map(domain => domain.displayWhen).filter(domain => domain).length;
      const folderHasSubfolders = !!folder.children.length;

      folder.displayWhen = value
        ? folderNameMatchesSearchValue || folderHasVisibleSubfolder
        : folderHasSubfolders;
    });

    // i don't like this but it's the only way to update a nested array
    // that's also being passed to a child component as an input
    this.filterBarMenuItems[3].children = [this.filterBarMenuItems[3].children[0]].concat(this.folderMenuItems);

    // trigger change detection
    this.filterBarMenuItems = [...this.filterBarMenuItems];
  }

  handleSearchByName({ value }: ISearchByTextEmissionData): void {
    this.triggeredAlertsFilterBarService.handleAlertNameContainsFilter(value);
  }

  handleLabelSearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.labelSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.labelSearchTextUpdated + 200) {
      try {
        el.focus();
      } catch (e) {
      }

      return;
    }

    // filter labels that include search value
    const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.labelMenuItems.map((item: IOpFilterBarV2MenuItem) => {
      item.displayWhen = value
        ? item.name.toLowerCase().includes(value)
        : item.checked;

      return item;
    });
  }

  handleDataSourcesLabelSearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.dataSourcesLabelSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.dataSourcesLabelSearchTextUpdated + 200) {
      try {
        el.focus();
      } catch (e) {
      }

      return;
    }

    // filter labels that include search value
    const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.dataSourcesLabelMenuItems.map((item: IOpFilterBarV2MenuItem) => {
      item.displayWhen = value
        ? item.name.toLowerCase().includes(value)
        : item.checked;

      return item;
    });
  }

  handleOnlyButtons(item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem): void {
    // deselect everything
    this.deselectAllMenuItems(menuItems);

    // set checked = true to item
    item.checked = true;

    // handle child elements if they exist
    if (item.children?.length) {
      item.children.forEach((child: IOpFilterBarV2MenuItem) => child.checked = true);
    }
  }

  toggleShowMonitoredJourneys(index: number): void {
    this.showSubscribedOnly = !this.showSubscribedOnly;

    // i don't like this but it's the only way to update a nested array
    // that's also being passed to a child component as an input
    this.filterBarMenuItems[index].name = this.showSubscribedOnly
      ? ETriggeredAlertsFilterBarMenuStrings.ShowAllMonitored
      : ETriggeredAlertsFilterBarMenuStrings.OnlyShowMonitored;

    // trigger change detection
    this.filterBarMenuItems = [...this.filterBarMenuItems];
    this.triggeredAlertsFilterBarService.handleSubscribedAlertsFilter(this.showSubscribedOnly);
  }

  setReportMetricFilter(reportName: string, metricName: string, metricType: AlertMetricType): void {
    this.triggeredAlertsFilterBarService.handleReportMetricFilter(
      reportName,
      metricName,
      metricType,
      this.reportMetricFilterMenuItems
    );
  }

  deselectAllMenuItems(arr: IOpFilterBarV2MenuItem[]): void {
    arr.forEach((menuItem: IOpFilterBarV2MenuItem) => {
      menuItem.checked = false;

      if (menuItem.children?.length) {
        this.deselectAllMenuItems(menuItem.children);
      }
    });
  }

  getCheckedStateByName(parentMenuName: ETriggeredAlertsFilterBarMenuStrings, childMenuName: ETriggeredAlertsFilterBarMenuStrings | string): boolean {
    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.display?.includes(parentMenuName));
    if (!filter) return false;

    let menuItem = filter.menuItems?.find(item => item.name?.includes(childMenuName));
    return menuItem ? menuItem.checked : false;
  }

  getCheckedStateById(mapName: { [id: number]: boolean }, itemId: number): boolean {
    return mapName[itemId] !== undefined ? mapName[itemId] : false;
  }

  createLabelCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.labelCheckedStatusMap = {};
      return;
    }

    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.type === ETriggeredAlertsFilterTypes.Label);

    if (!filter) {
      this.labelCheckedStatusMap = {};
      return;
    }

    filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
      this.labelCheckedStatusMap[item.id] = item.checked;
    });
  }
}
