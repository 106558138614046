import { RouteBuilder } from './../shared/services/route-builder.service';
import { IWebJourneyFrequency, ISelectOption, EActionTypeV3 } from './web-journey.models';

export const WebJourneyPaths = {
  base: 'web-journey',
  results: 'web-journey/:journeyId/run/:runId/results'
};

export const WebJourneyUrlBuilders = {
  results: (journeyId: number, runId: number) => RouteBuilder.buildUrl([WebJourneyPaths.results], String(journeyId), String(runId)),
};

export enum EWebJourneyFrequency {
  PAUSED = 'paused',
  ONCE = 'once',
  EVERY_15_MINUTES = '15 minutes',
  HOURLY = '1 hour',
  EVERY_6_HOURS = '6 hours',
  EVERY_12_HOURS = '12 hours',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly'
}

export const WebJourneyFrequencyFriendlyNames = {
  [EWebJourneyFrequency.PAUSED]: 'Not scheduled',
  [EWebJourneyFrequency.ONCE]: 'Once',
  [EWebJourneyFrequency.EVERY_15_MINUTES]: 'Every 15 Minutes',
  [EWebJourneyFrequency.HOURLY]: 'Hourly',
  [EWebJourneyFrequency.EVERY_6_HOURS]: 'Every 6 hours',
  [EWebJourneyFrequency.EVERY_12_HOURS]: 'Every 12 hours',
  [EWebJourneyFrequency.DAILY]: 'Daily',
  [EWebJourneyFrequency.WEEKLY]: 'Weekly',
  [EWebJourneyFrequency.BIWEEKLY]: 'Biweekly',
  [EWebJourneyFrequency.MONTHLY]: 'Monthly'
};

export const WebJourneyFrequencies: Array<IWebJourneyFrequency> = Object.keys(WebJourneyFrequencyFriendlyNames)
  .map(k => ({ key: k, label: WebJourneyFrequencyFriendlyNames[k] }));

export const possibleActions: Array<ISelectOption> = [
  {
    key: EActionTypeV3.NavTo,
    label: 'Navigate To'
  },
  {
    key: EActionTypeV3.Click,
    label: 'Click'
  },
  {
    key: EActionTypeV3.Input,
    label: 'Input'
  },
  {
    key: EActionTypeV3.MaskedInput,
    label: 'Masked Input'
  },
  {
    key: EActionTypeV3.Select,
    label: 'Select'
  },
  {
    key: EActionTypeV3.Check,
    label: 'Check'
  },
  {
    key: EActionTypeV3.Uncheck,
    label: 'Uncheck'
  },
  {
    key: EActionTypeV3.Execute,
    label: 'Execute JavaScript'
  },
  {
    key: EActionTypeV3.Watch,
    label: 'Watch'
  },
  {
    key: EActionTypeV3.SwitchToIFrame,
    label: 'Switch to iFrame'
  },
  {
    key: EActionTypeV3.LeaveIFrame,
    label: 'Leave iFrame'
  },
  {
    key: EActionTypeV3.ActionSet,
    label: 'Action Set'
  },
  {
    key: EActionTypeV3.ClearCookies,
    label: 'Clear All Cookies'
  },
  {
    key: EActionTypeV3.CmpOptInOptOut,
    label: 'Consent Manager State (CMP) - Accept All/Reject All'
  }
];
