import {
  ETargetItemsSortColumns,
  ITargetItemsSort
} from '@app/components/notifications-center/notification-center.models';
import { IPagination } from '@app/components/shared/components/selectable-table/selectable-table.models';

export const NOTIFICATION_CENTER_MODAL_FILTERS_STORAGE_KEY = 'notification_center_modal_filters';

export const DefaultNotificationCenterAssignmentsPagination: IPagination = {
  totalCount: 0,
  totalPageCount: 0,
  pageSize: 100,
  currentPageSize: 100,
  currentPageNumber: 0
};

export const DefaultNotificationCenterAssignmentsSorting: ITargetItemsSort = {
  sortBy: ETargetItemsSortColumns.itemName,
  sortDesc: false,
};
