<op-modal [rightFooterButtons]="payload?.rightFooterButtons"
          (onClose)="close()">

  <ng-template #bodyTemplate>
    <div class="success-modal-body">
      <span class="icon-check-full"></span>
      <h1 class="success-text">Success!</h1>
      <p class="main-text" *ngIf="payload?.message">
        {{payload.message}}
      </p>
    </div>
  </ng-template>

</op-modal>
