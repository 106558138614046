import { RouteDataService } from '@app/components/shared/services/route-data.service';
import { RouteHistoryService } from '@app/components/shared/services/route-history.service';
import { Injectable } from '@angular/core';
import { RouteReloadService } from '@app/components/shared/services/route-reload.service';
import * as Sentry from "@sentry/angular";

@Injectable()
export class AppInitService {

  constructor(private routeHistoryService: RouteHistoryService,
    private routeDataService: RouteDataService,
    private routeReloadService: RouteReloadService) {

    this.routeHistoryService.init();
    this.routeDataService.init();
    this.routeReloadService.init();

  }

  onAppInit() {
    // TODO: Formerly with New Relic we called nr.interaction().end() here to prevent external
    //       loading scripts/images from making page load time seem high. We may need to do something
    //       similar with Sentry.

    Sentry.addBreadcrumb({
      category: 'load',
      message: 'Angular app initialized',
      level: 'info'
    });
  }

}
