import { Names } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { AuditSelector } from './auditSelectorService';
import { DomainSelector } from './domainSelectorService';
import { FolderSelector } from './folderSelectorService';
import { LabelSelector } from './labelSelectorService'

export default angular.module('selectorsModule', [])
  .service(Names.Services.auditSelector, AuditSelector)
  .service(Names.Services.domainSelector, DomainSelector)
  .service(Names.Services.folderSelector, FolderSelector)
  .service(Names.Services.labelSelector, LabelSelector)
  .name;
