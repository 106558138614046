<div class="cc-zero-state" *ngIf="!loading && showZeroState">
  <p>{{CONSTANTS.zeroBody[0]}}</p>
  <p>{{CONSTANTS.zeroBody[1]}}</p>
  <p>{{CONSTANTS.zeroBody[2]}}</p>
  <br/>
  <p>{{CONSTANTS.zeroBody[3]}}</p>
  <p>{{CONSTANTS.zeroBody[4]}}</p>
  <p>{{CONSTANTS.zeroBody[5]}}</p>

  <div class="button-wrap">
      <op-button-2021
      [labelText]="CONSTANTS.createNewZero"
      matIcon="control_point"
      buttonType="primary"
      [menuItems]="createCCMenu"></op-button-2021>
  </div>
</div>
