import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable } from 'rxjs';
import { IWebJourneyV3NotificationConfig } from './web-journey-v3.models';

@Injectable()
export class WebJourneyV3NotificationsService {

  apiRoot: string = `${environment.apiV3Url}web-journeys`;

  constructor(private apiService: ApiService) {}

  getNotificationsConfig(journeyId: number): Observable<IWebJourneyV3NotificationConfig> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}/notificationconfig`);
  }

  updateNotificationsConfig(journeyId: number, config: IWebJourneyV3NotificationConfig): Observable<IWebJourneyV3NotificationConfig> {
    return this.apiService.put(`${this.apiRoot}/${journeyId}/notificationconfig`, config);
  }
}