import { OpModalService } from './../../shared/components/op-modal/shared/op-modal.service';
/**
 * These CC Create tabs are a bit convoluted. Each tab keeps track of a temporary selection from it's list of cookies, tags, or request domains, with the ability to load new options using the filter. Some things to note:
 *
 *  - Each tab creates a hash of all items that have been loaded and adds a property 'selected' and 'existing'
 *    - Unique key (cookies = name + domain, tags = tagId, request domains = domain
 *  - Any display to the UI is a lookup against the hash
 *  - The list of search results is limited to 100 items
 *  - The filter applies to both the selected table and the api results table
 *  - Any existing items are disabled and marked as existing in the hash
 *
 * The cc-create component holds a list of all selected items for each tab. Each tab implements CVA and handles updating
 * it's value in this form.
 *
 * When creating, these values are used to generate a patch to each tab's type endpoint and update their values.
 * When editing, these values are returned when saved as an object to the opener of the create modal.
 */
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit, Optional, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ConsentCategoriesUIConstants } from '@app/components/consent-categories/consent-categories.constants';
import {
  EConsentCategoryType,
  EDomainType,
  ENameType,
  IConsentCategoryBase,
  IConsentCategoryCookie,
  IConsentCategoryModalData,
  IConsentCategoryRequestDomain,
  IConsentCategoryTag,
  IRequestDomain,
} from '@app/components/consent-categories/consent-categories.models';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { EUniqueResponseTypes } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { forkJoin, merge, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { Router } from '@angular/router';
import { EConsentCategoryCreateStep } from '@app/components/consent-categories/cc-create/cc-create.enums';
import { IV3AuditWithLatestRun } from '@app/components/domains/discoveryAudits/discoveryAuditModels';
import { ECCEditTabs } from '@app/components/consent-categories/cc-edit/cc-edit.constants';
import { CCDataSourcesFilterBarService } from '../cc-data-sources-filter-bar/cc-data-sources-filter-bar.service';
import {
  EConsentCategoriesFilterTypes
} from '../consent-categories-filter-bar/consent-categories-filter-bar.constants';
import { DataSourcesService } from '@app/components/shared/services/data-sources/data-sources.service';
import { EDataSourcesSortBy } from '@app/components/shared/services/data-sources/data-sources.constants';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  IDataSource,
  IDataSourceQueryParams,
  IDataSources
} from '@app/components/shared/services/data-sources/data-sources.models';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { ConsentCategoriesEditComponent, IEditConsentCategoryModal } from '../cc-edit/cc-edit.component';
import { ModalWithHotkeySupport } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.models';
import { EKeyCodes } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.constants';
import { defaultCCDataSourceQueryParams } from './cc-create.constants';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { OpStandardsSelectorService } from '@app/components/shared/components/op-standards-selector/op-standards-selector.service';
import { EStandardsSelectorType } from '@app/components/shared/components/op-standards-selector/op-standards-selector.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-create',
  templateUrl: './cc-create.component.html',
  styleUrls: ['./cc-create.component.scss']
})
export class ConsentCategoryCreateComponent implements OnInit, ModalWithHotkeySupport, AfterViewInit, OnDestroy {
  readonly ccCreateSteps = EConsentCategoryCreateStep;
  private destroy$ = new Subject();

  saving: boolean = false;
  modalTitle: string;
  CONSTANTS = { ...ConsentCategoriesUIConstants, EUniqueResponseTypes };
  loading: boolean = true;
  ccForm: UntypedFormGroup;
  allLabels: ILabel[] = [];
  displayedColumns: string[] = ['select', 'itemName', 'lastRun', 'labels'];
  dataSource = new MatTableDataSource;
  selection = new SelectionModel(true, []);
  allAuditsSorted: IV3AuditWithLatestRun[] = [];
  duplicateError: boolean = false;
  currentStep: EConsentCategoryCreateStep = EConsentCategoryCreateStep.SETUP;
  currentSelectionIds: number[] = [];
  existingTags: IConsentCategoryTag[] = [];
  existingCookies: IConsentCategoryCookie[] = [];
  existingRequestDomains: IConsentCategoryRequestDomain[] = [];
  loadingTabData: boolean = false;
  queryParams: IDataSourceQueryParams = { ...defaultCCDataSourceQueryParams };
  paginationState: { length: number, pageSize: number } = {
    length: 0,
    pageSize: 0
  };
  ccTypes = [
    {
      displayName: 'Approved',
      value: 'approved',
    },
    {
      displayName: 'Unapproved',
      value: 'unapproved',
    }
  ];
  rightFooterButtons: IButton[] = [
    {
      label: 'Create without selecting a report',
      action: this.save.bind(this),
      primary: true,
      disabled: true,
      hidden: true,
      opSelector: this.CONSTANTS.selectors.ccCreateWithoutReport,
    },
    {
      label: 'Prev',
      action: this.back.bind(this),
      disabled: false,
      hidden: true,
      opSelector: this.CONSTANTS.selectors.ccCreatePrev,
    },
    {
      label: 'Next',
      action: this.continue.bind(this),
      disabled: true,
      hidden: true,
      opSelector: this.CONSTANTS.selectors.ccCreateNext,
      primary: true
    },
    {
      label: 'Create & Close',
      action: this.saveWithExistingData.bind(this),
      primary: true,
      disabled: true,
      hidden: true,
      opSelector: this.CONSTANTS.selectors.ccCreate,
    },
    {
      label: 'Pull In Data From Selected Report',
      action: this.continue.bind(this),
      primary: true,
      disabled: true,
      hidden: false,
      opSelector: this.CONSTANTS.selectors.ccCreate,
    },
    {
      label: 'Select Consent Categories To Update',
      action: this.updateEditWithSelections.bind(this),
      primary: true,
      disabled: true,
      hidden: false,
      opSelector: this.CONSTANTS.selectors.ccReturnSelectedData,
    },
    {
      label: 'Save & Reprocess',
      action: this.createNewWithSelectedDataAndReprocess.bind(this),
      primary: true,
      disabled: true,
      hidden: true,
      opSelector: this.CONSTANTS.selectors.ccCreateWithSelectedDataAndReprocess,
    },
    {
      label: 'Save',
      action: this.createNewWithSelectedData.bind(this),
      primary: true,
      disabled: true,
      hidden: true,
      opSelector: this.CONSTANTS.selectors.ccCreateWithSelectedData,
    }
  ];

  continueToSourceSubject = new Subject<boolean>();
  continueToSource$ = this.continueToSourceSubject.asObservable();

  shiftKeyPressed: boolean = false;
  lastSelectedIndex: number = null;

  initialLoad: boolean = true;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('window:keydown.shift')
  onKeyDown() {
    this.shiftKeyPressed = true;
  }

  @HostListener('window:keyup.shift')
  onKeyUp() {
    this.shiftKeyPressed = false;
  }

  private filtersUpdated$ = new ReplaySubject<{ filters: IOpFilterBarFilter<EConsentCategoriesFilterTypes>[] }>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) @Optional() public data: IConsentCategoryModalData,
    private labelService: LabelService,
    private ccService: ConsentCategoriesService,
    private router: Router,
    private modalService: OpModalService,
    private ccDataSourcesFbSvc: CCDataSourcesFilterBarService,
    private dataSourceService: DataSourcesService,
    private dateService: DateService,
    private standardsSelectorService: OpStandardsSelectorService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.setModalStep();
    this.setModalTitle();
    this.initFooterButtons();
    this.initFilters();
    this.initForm();
    this.initListeners();
    this.debouncedFilter();
    this.setExistingInfo();
    this.checkCcName();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.data.skipCreate
      ? this.handleSkipCreate()
      : setTimeout(() => this.handleTable(), 0);

    this.dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'name': {
          return item[property];
        }
      }
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setModalStep(): void {
    if (this.data.skipCreate && this.data.step) {
      this.currentStep = this.data.useSelectedDataOnlyCreate ? EConsentCategoryCreateStep.SETUP : this.data.step;
    }
  }

  setModalTitle(): void {
    if (this.data.skipCreate) {
      this.modalTitle = this.data.type === EConsentCategoryType.ASSIGN
        ? this.CONSTANTS.ccSkipCreate.titleAssign
        : this.data.type === EConsentCategoryType.APPROVED
        ? this.CONSTANTS.ccSkipCreate.titleApproved
        : this.CONSTANTS.ccSkipCreate.titleUnapproved;
    } else if (this.data.editing) {
      this.modalTitle = this.data.type === EConsentCategoryType.APPROVED
        ? this.CONSTANTS.ccAddToExisting.titleApproved
        : this.CONSTANTS.ccAddToExisting.titleUnapproved;
    } else {
      this.modalTitle = this.currentStep === this.ccCreateSteps.SETUP
        ? this.CONSTANTS.ccCreate.setupTitle
        : this.CONSTANTS.ccCreate.approveTitle;
    }
  }

  initFooterButtons() {
    const [
      createWithoutReportBtn,
      prevBtn,
      nextBtn,
      saveBtn,
      pullInExisting,
      skipCreateSelect,
      saveFromSelectionAndReprocess,
      saveFromSelection
    ] = this.rightFooterButtons;
    if (this.data.skipCreate) {
      if (this.data.useSelectedDataOnlyCreate) {
        createWithoutReportBtn.hidden = saveBtn.hidden = pullInExisting.hidden = nextBtn.hidden = prevBtn.hidden = skipCreateSelect.hidden = saveFromSelectionAndReprocess.hidden = true;
        saveFromSelection.hidden = false;
      } else {
        createWithoutReportBtn.hidden = saveBtn.hidden =  pullInExisting.hidden = true;
        nextBtn.hidden = this.currentStep === EConsentCategoryCreateStep.REQUEST_DOMAINS;
        nextBtn.disabled = false;
        prevBtn.hidden = this.currentStep === EConsentCategoryCreateStep.COOKIES;
        skipCreateSelect.disabled = true;
      }
    } else if (this.data.editing) {
      saveBtn.label = 'Save & Close';
      createWithoutReportBtn.hidden = prevBtn.hidden = nextBtn.hidden = saveBtn.hidden = true;
      pullInExisting.hidden = false;
    } else {
      nextBtn.hidden = prevBtn.hidden = saveBtn.hidden = true;
      createWithoutReportBtn.hidden = pullInExisting.hidden = false;
    }
  }

  initFilters(): void {
    this.ccDataSourcesFbSvc.updateSupportedFiltersList([
      EConsentCategoriesFilterTypes.Name,
      EConsentCategoriesFilterTypes.Label
    ]);

    this.ccDataSourcesFbSvc
      .currentFilters$
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  initListeners(): void {
      // disable footer save & close button when more than 1000 selected items on any tab
      merge(
        this.selectedCookies.valueChanges,
        this.selectedTags.valueChanges,
        this.selectedRequestDomains.valueChanges,
      ).pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        const [createWithoutReportBtn, prevBtn, nextBtn, saveBtn, pullInExisting, skipCreateSelect] = this.rightFooterButtons;
        if (this.selectedTags.value.length > this.CONSTANTS.maxSelectedItems
          || this.selectedCookies.value.length > this.CONSTANTS.maxSelectedItems
          || this.selectedRequestDomains.value.length > this.CONSTANTS.maxSelectedItems) {
          if (this.data.skipCreate) {
            skipCreateSelect.disabled = true;
          } else {
            saveBtn.disabled = true;
          }
        } else {
          if (this.data.skipCreate) {
            // Something should be selected to enable select button
            skipCreateSelect.disabled = this.selectedTags.value.length === 0
              && this.selectedCookies.value.length === 0
              && this.selectedRequestDomains.value.length === 0;
          } else {
            saveBtn.disabled = false;
          }
        }
    });
  }

  /**
   * Set existing values with a sequence value to remember original order and
   * set selected property to true.
   */
  setExistingInfo(): void {
    this.name.setValue(this.data?.name);
    this.type.setValue(this.data?.type);
    this.isDefaultCC.setValue(this.data?.isDefaultCC || false);
    this.enableIsDefaultCheckbox();

    this.existingCookies = this.data?.cookies?.map((cookie, index) => {
      return { ...cookie, sequence: index, selected: true, existing: true };
    });
    this.existingTags = this.data?.tags?.map((tag, index) => {
      return { ...tag, sequence: index, selected: true, existing: true };
    });
    this.existingRequestDomains = this.data?.requestDomains?.map((requestDomain, index) => {
      return { ...requestDomain, sequence: index, selected: true, existing: true };
    });
  }

  initForm() {
    this.ccForm = this.formBuilder.group({
      name: ['', [Validators.required, this.duplicateValidator.bind(this)]],
      labels: [[]],
      type: ['', [Validators.required]],
      reportFilter: [''],
      reportList: [''],
      selectedCookies: [[]],
      selectedTags: [[]],
      selectedRequestDomains: [[]],
      isDefaultCC: [false],
    });

    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'name': {
          return item[property];
        }
      }
    };

    this.dataSource.filterPredicate = (data, filter): boolean => {
      let nameCol = data['name'].trim().toLowerCase();
      let labelCol = data['labels'].map(label => label.name).join(', ').toLowerCase();
      let filterVal = filter.trim().toLowerCase();

      return nameCol.includes(filterVal) || labelCol.includes(filterVal);
    };
  }

  duplicateValidator(): {[key: string]: any} | null {
    return this.duplicateError ? {duplicate: { value: this.name.value}} : null;
  }

  onFiltersChanged(apiPostBody: IOpFilterBarFilter<EConsentCategoriesFilterTypes>[]): void {
    if (this.paginator) this.paginator.pageIndex = 0;
    this.resetQueryParams();
    this.filtersUpdated$.next({ filters: apiPostBody });
  }

  checkCcName(): void {
    this.name.valueChanges.pipe(
      // Disable the save buttons until the duplicate name check returns and we update status again at that point
      tap(() => {
        const [
          createWithoutReportBtn,
          prevBtn,
          nextBtn,
          saveBtn,
          pullInExisting,
          skipCreateSelect,
          saveFromSelectionAndReprocess,
          saveFromSelection
        ] = this.rightFooterButtons;

        saveBtn.disabled = pullInExisting.disabled = saveFromSelectionAndReprocess.disabled = saveFromSelection.disabled = true;
      }),
      debounceTime(500),
      takeUntil(this.destroy$)
    ).subscribe((value: string) => {
      if (value === '') {
        this.name.setErrors({required: {}});
        return;
      }

      this.ccService.isConsentCategoryNameUnique(value).subscribe((isValid: boolean) => {
        this.name.setErrors(isValid ? null : {duplicate: { value: this.name.value}});
        this.updateFooterButtonsDisabledStatus();
      });
    });
  }

  handleSkipCreate(): void {
    this.dataSourceService.getAllDataSources({
      itemType: this.queryParams.itemType,
      sortBy: this.queryParams.sortBy,
      sortDesc: this.queryParams.sortDesc,
      withRuns: this.queryParams.withRuns
    }).then((data: IDataSource[]) => {
      this.dataSource.data = data;
      this.handleDataPostLoad();
    });
  }

  async handleDataPostLoad(): Promise<void> {
    this.initialLoad = false;
    await this.selectInitiatingAudit();

    if (this.data.skipCreate) {
      this.setCurrentSelectionIds();
      this.loadingTabData = true;
      this.continueToSourceSubject.next(true);
    }
  }

  handleTable(): void {
    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.filtersUpdated$
    )
    .pipe(
      switchMap((mergedEvent: Sort & PageEvent & { filters: IOpFilterBarFilter<EConsentCategoriesFilterTypes>[] }) => {
        if (mergedEvent) {
          // sorting
          if (mergedEvent.hasOwnProperty('direction')) {
            this.paginator.pageIndex = 0;
            this.queryParams.sortBy = mergedEvent.active as EDataSourcesSortBy;
            this.queryParams.sortDesc = mergedEvent.direction === 'desc';
          }

          // pagination
          if (mergedEvent.hasOwnProperty('pageIndex')) {
            this.queryParams.page = mergedEvent.pageIndex;
          }

          // filter bar
          if (mergedEvent.hasOwnProperty('filters')) {
            const filters = mergedEvent['filters'];
            let labelIds = [];

            filters.forEach((filter: IOpFilterBarFilter<EConsentCategoriesFilterTypes>) => {
              // label filters
              if (filter.type === EConsentCategoriesFilterTypes.Label) {
                labelIds.push(filter.value);
              }

              // name filter
              if (filter.type === EConsentCategoriesFilterTypes.Name) {
                this.queryParams.itemName = filter.value['filterValue'];
              }
            });

            this.queryParams.labelIds = labelIds.length ? labelIds : null;
          }
        }

        return this.dataSourceService.getDataSources(this.queryParams);
      }),
      map(({ metadata, dataSources }: IDataSources) => {
        // update loading state
        this.loading = false;

        // update pagination
        const pagination = metadata.pagination;
        this.paginationState.length = dataSources.length;
        this.paginationState.pageSize = pagination.pageSize;

        return dataSources;
      })
    )
    .subscribe((data: IDataSource[]) => {
      this.initTable(data);
      if (this.initialLoad && this.data?.auditId) {
        this.handleDataPostLoad();
      }
    });
  }

  // If creating a CC from an audit, select the audit immediately as a datasource to load data from
  selectInitiatingAudit(): Promise<void> {
    return new Promise((resolve, reject) => {
      const auditIndex = this.dataSource.data.findIndex((item: any) => item?.itemId === this.data?.auditId);
      if (auditIndex > -1) this.handleCheckboxClick(auditIndex);
      resolve();
    });
  }

  initTable(data: IDataSource[]): void {
    this.dataSource.data = [ ...data ];
  }

  resetQueryParams(): void {
    this.queryParams = { ...defaultCCDataSourceQueryParams };
  }

  getLabels(labels) {
    let combinedLabel = '';
    labels.forEach((label, index) => {
      combinedLabel += label.name ?
        index < labels.length - 1 ?
          (label.name + ', ')
          : label.name
        : '';
    });
    return combinedLabel;
  }

  back(): void {
    this.goToStep(this.currentStep - 1);
  }

  continue(): void {
    if (this.currentStep === 0 &&
      this.selection?.selected?.length > 0 &&
      (this.data.editing || this.selectionChanged())
    ) {
      this.continueToSourceSubject.next(true);
      this.setCurrentSelectionIds();
      this.loadingTabData = true;
    }

    this.goToStep(this.currentStep + 1);
  }

  // Sets the currentSelectionIds to selected report ID or -1 if nothing selected
  setCurrentSelectionIds(): void {
    this.currentSelectionIds = this.selection.selected.length > 0 ? this.selection.selected.map(item => item.itemId) : [];
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    let sortedA = [...a].sort();
    let sortedB = [...b].sort();

    return sortedA.every((val, index) => val === sortedB[index]);
  }

  selectionChanged(): boolean {
    const selectedIds = this.selection.selected.map(report => report.id);

    return !this.arraysEqual(selectedIds, this.currentSelectionIds);
  }

  goToStep(stepId: EConsentCategoryCreateStep): void {
    this.currentStep = stepId;
    this.updateFooterButtonVisibility(stepId);
  }

  private updateFooterButtonVisibility(stepId: EConsentCategoryCreateStep): void {
    const [
      createWithoutReportBtn,
      prevBtn,
      nextBtn,
      saveBtn,
      pullInExisting,
      skipCreateSelect,
      saveFromSelectionAndReprocess,
      saveFromSelection
    ] = this.rightFooterButtons;

    if (this.data.useSelectedDataOnlyCreate) {
      switch (stepId) {
        case this.ccCreateSteps.SETUP:
          createWithoutReportBtn.hidden = true;
          pullInExisting.hidden = true;
          saveBtn.hidden = true;
          prevBtn.hidden = true;
          nextBtn.hidden = true;
          skipCreateSelect.hidden = true;
          saveFromSelectionAndReprocess.hidden = true;
          saveFromSelection.hidden = false;
          break;
      }
    }

    if (this.data.skipCreate) {
      switch (stepId) {
        case this.ccCreateSteps.COOKIES:
          createWithoutReportBtn.hidden = true;
          pullInExisting.hidden = true;
          saveBtn.hidden = true;
          prevBtn.hidden = true;
          nextBtn.hidden = false;
          skipCreateSelect.hidden = false;
          break;
        case this.ccCreateSteps.TAGS:
          createWithoutReportBtn.hidden = true;
          pullInExisting.hidden = true;
          saveBtn.hidden = true;
          prevBtn.hidden = false;
          nextBtn.hidden = false;
          nextBtn.disabled = false;
          skipCreateSelect.hidden = false;
          break;
        case this.ccCreateSteps.REQUEST_DOMAINS:
          createWithoutReportBtn.hidden = true;
          pullInExisting.hidden = true;
          saveBtn.hidden = true;
          prevBtn.hidden = false;
          nextBtn.hidden = true;
          skipCreateSelect.hidden = false;
          break;
      }
    } else {
      switch (stepId) {
        case this.ccCreateSteps.SETUP:
          createWithoutReportBtn.hidden = false;
          pullInExisting.hidden = false;
          prevBtn.hidden = true;
          nextBtn.hidden = true;
          saveBtn.hidden = true;
          skipCreateSelect.hidden = true;
          break;
        case this.ccCreateSteps.COOKIES:
          createWithoutReportBtn.hidden = true;
          pullInExisting.hidden = true;
          prevBtn.hidden = false;
          nextBtn.hidden = false;
          saveBtn.hidden = false;
          skipCreateSelect.hidden = true;
          break;
        case this.ccCreateSteps.TAGS:
          createWithoutReportBtn.hidden = true;
          pullInExisting.hidden = true;
          prevBtn.hidden = false;
          nextBtn.hidden = false;
          saveBtn.hidden = false;
          skipCreateSelect.hidden = true;
          break;
        case this.ccCreateSteps.REQUEST_DOMAINS:
          createWithoutReportBtn.hidden = true;
          pullInExisting.hidden = true;
          prevBtn.hidden = false;
          nextBtn.hidden = true;
          saveBtn.hidden = false;
          skipCreateSelect.hidden = true;
          break;
      }
    }
  }

  save() {
    /**
     * Access sorted data using:
     * this.dataSource.sortData(this.dataSource.filteredData,this.dataSource.sort)
     */
    const payload = {
      name: this.name.value,
      notes: '',
      type: this.type.value,
      isDefaultCC: this.isDefaultCC.value,
    };

    this.loading = true;
    this.ccService.createConsentCategory(payload).subscribe(category => {
      this.ccService.addConsentCategoryLabels(category.id, this.labels.value)
        .subscribe(() => {
          this.loading = false;
          //if this is the first default CC to be added, then reload the service data to ensure consistancy CC types.
          if(this.isDefaultCC.value === true && this.standardsSelectorService.noDefaultCCs()) {
            this.standardsSelectorService.invalidateConsentCatCache();
          }
          this.closeModal(false, true);
          this.navigateToEdit(category.id);
        }, err => { this.loading = false; });
      }, err => {
        this.loading = false;
        if (err.errorCode?.message?.includes('A Consent Category with the same name')) {
          this.name.setErrors({duplicate: { value: this.name.value}});
          this.rightFooterButtons[0].disabled = true;
          this.goToStep(0);
        }
      });
  }

  saveWithExistingData() {
    this.data.editing ? this.updateEditWithSelections() : this.createNewWithExistingData();
  }

  updateEditWithSelections() {
    const selectedPayload = {
      updateValues: true,
      allLabels: this.allLabels,
      cookies: this.getSelectedCookies(),
      tags: this.getSelectedTags(),
      requestDomains: this.getSelectedRequestDomains(), // Returns an array of all selected request domains
    };

    this.dialogRef.close(selectedPayload);
  }

  disableFooterButtons() {
    this.rightFooterButtons.forEach(button => button.disabled = true);
  }

  createNewWithSelectedDataAndReprocess(): void {
    this.createNewWithSelectedData(true);
  }

  createNewWithSelectedData(reprocess: boolean = false) {
    const payload = {
      name: this.name.value,
      notes: '',
      type: this.type.value,
      isDefaultCC: this.isDefaultCC.value,
    };

    this.loading = true;
    this.saving = true;
    this.disableFooterButtons();
    this.ccService.createConsentCategory(payload).subscribe(cc => {
      const patchObservables = [];
      const cookies = this.data.cookies;
      const tags = this.data.tags;
      const requestDomains = this.data.requestDomains;

      patchObservables.push(this.ccService.patchConsentCategoryCookies(cc.id, this.ccService.compareConsentCategories([], cookies)));
      patchObservables.push(this.ccService.patchConsentCategoryTags(cc.id, this.ccService.compareConsentCategories([], tags)));
      patchObservables.push(this.ccService.patchConsentCategoryRequestDomains(cc.id, this.ccService.compareConsentCategories([], requestDomains)));
      patchObservables.push(this.ccService.addConsentCategoryLabels(cc.id, this.labels.value));

      forkJoin(patchObservables).subscribe(([cookies, tags, requestDomains, labels]) => {
        this.loading = false;
        this.closeModal(false, true, false, cc);
        this.saving = false;
      }, err => {
        this.loading = false;
        this.saving = false;
        this.updateFooterButtonsDisabledStatus();
      });
    }, err => {
      this.loading = false;
      this.saving = false;
      this.updateFooterButtonsDisabledStatus();

      if (err.errorCode?.message?.includes('A Consent Category with the same name')) {
        this.name.setErrors({duplicate: { value: this.name.value}});
        this.rightFooterButtons[0].disabled = this.rightFooterButtons[6].disabled = this.rightFooterButtons[7].disabled = true;
        this.goToStep(0);
      }

      if (err.errorCode?.message?.includes('Current account plan is not allowed to create')) {
        this.name.setErrors({limitExceeded: { value: 'Account limit reached. Delete a Consent Category to create a new one.'}});
        this.rightFooterButtons[0].disabled = this.rightFooterButtons[6].disabled = this.rightFooterButtons[7].disabled = true;
        this.goToStep(0);
      }
    });
  }

  createNewWithExistingData() {
    const payload = {
      name: this.name.value,
      notes: '',
      type: this.type.value,
      isDefaultCC: this.isDefaultCC.value,
    };

    this.loading = true;
    this.saving = true;
    this.disableFooterButtons();
    this.ccService.createConsentCategory(payload).subscribe(cc => {
      const patchObservables = [];
      const cookies = this.cleanUpSelectedItemForPatch(this.getSelectedCookies());
      const tags = this.cleanUpSelectedItemForPatch(this.getSelectedTags());
      const requestDomains = this.cleanUpSelectedItemForPatch(this.getSelectedRequestDomains());

      patchObservables.push(this.ccService.patchConsentCategoryCookies(cc.id, this.ccService.compareConsentCategories([], cookies)));
      patchObservables.push(this.ccService.patchConsentCategoryTags(cc.id, this.ccService.compareConsentCategories([], tags)));
      patchObservables.push(this.ccService.patchConsentCategoryRequestDomains(cc.id, this.ccService.compareConsentCategories([], requestDomains)));
      patchObservables.push(this.ccService.addConsentCategoryLabels(cc.id, this.labels.value));

      forkJoin(patchObservables).subscribe(([cookies, tags, requestDomains, labels]) => {
        this.loading = false;
        this.closeModal(false, true, false, cc);
        this.saving = false;
        this.navigateToEdit(cc.id);
      }, err => {
        this.loading = false;
        this.saving = false;
        this.updateFooterButtonsDisabledStatus();
      });
    }, err => {
      this.loading = false;
      this.saving = false;
      this.updateFooterButtonsDisabledStatus();

      if (err.errorCode?.message?.includes('A Consent Category with the same name')) {
        this.name.setErrors({duplicate: { value: this.name.value}});
        this.rightFooterButtons[0].disabled = true;
        this.goToStep(0);
      }
    });
  }

  navigateToEdit(id: number, tab: ECCEditTabs = ECCEditTabs.cookies): void {
    const data: IEditConsentCategoryModal = {
      consentCategoryId: id,
      tab,
      newCC: true
    };
    this.modalService.openFixedSizeModal(ConsentCategoriesEditComponent, { disableClose: true, data })
      .afterClosed()
      .subscribe(() => {
        this.router.navigateByUrl(this.router.url);
      });
  }

  cleanUpSelectedItemForPatch(items) {
    return items.map(item => {
      delete item.selected; // Removing flag used for the checkbox state
      return item;
    });
  }

  closeModal(cancel: boolean, isSaved = false, reprocess: boolean = false, cc?: IConsentCategoryBase) {
    // Don't pass any data if canceling data selection without creating a new consent category
    if (this.data.skipCreate && cancel) {
      this.dialogRef.close();
    } else {
      this.dialogRef.close({
        allLabels: isSaved ? this.allLabels : [],
        cc: isSaved ? cc : null,
        reprocess,
      });
    }
  }

  getSelectedCookies() {
    let allSelectedCookieObjects: IConsentCategoryCookie[] = Object.values(this.selectedCookies.value);

    let selectedCookies = allSelectedCookieObjects.map((cookie: IConsentCategoryCookie) => {
      cookie.nameType = ENameType.EXACT; // always exact match when importing from existing cookies
      cookie.domainType = EDomainType.EXACT; // always exact match when importing from existing cookies

      return cookie;
    });

    return selectedCookies;
  }

  getSelectedTags() {
    let allSelectedTagObjects: IConsentCategoryTag[] = Object.values(this.selectedTags.value);

    return allSelectedTagObjects.map((tag: IConsentCategoryTag) => tag);
  }

  createLabel(labelName: string) {
    this.labelService.createLabel(labelName).subscribe(label => {
      this.allLabels.push(label);

      const oldLabelsList = this.labels.value;
      this.labels.setValue([...oldLabelsList, label]);
    });
  }

  formatDate(date: string): string {
    return this.dateService.formatDate(new Date(date), EDateFormats.dateTwentyTwo);
  }

  debouncedFilter() {
    this.reportFilter.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(value => {
        this.applyFilter(value);
      });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  updateFooterButtonsDisabledStatus(): void {
    const [
      createWithoutReportBtn,
      prevBtn,
      nextBtn,
      saveBtn,
      pullInDataFromSelectedReportBtn,
      skipCreateSelect,
      saveFromSelectionAndReprocess,
      saveFromSelection,
    ] = this.rightFooterButtons;

    const nameFieldEmpty = !this.name?.value;
    const nameFieldDuplicate = this.name?.hasError('duplicate');
    const typeSelected = this.type?.value === 'approved' || this.type?.value === 'unapproved';
    const noAuditSelected = this.selection.hasValue() === false ||
      (this.selection.hasValue() && this.selection?.selected?.length === 0);
    const auditSelected = !noAuditSelected;

    // Create without report button
    createWithoutReportBtn.disabled = nameFieldEmpty || auditSelected || !typeSelected || nameFieldDuplicate;

    // Next button
    nextBtn.disabled = !this.data.skipCreate
      ? nameFieldEmpty || noAuditSelected || !typeSelected || nameFieldDuplicate
      : this.currentStep === EConsentCategoryCreateStep.REQUEST_DOMAINS;

    // Create button
    saveBtn.disabled = nameFieldEmpty || noAuditSelected || !typeSelected || nameFieldDuplicate;

    // Update existing button
    pullInDataFromSelectedReportBtn.disabled = nameFieldEmpty || noAuditSelected || !typeSelected || nameFieldDuplicate;

    // Update no create select button
    skipCreateSelect.disabled = (this.selectedCookies.value.length === 0 && this.selectedTags.value.length === 0 && this.selectedTags.value.length === 0) || (this.selectedTags.value.length > this.CONSTANTS.maxSelectedItems || this.selectedCookies.value.length > this.CONSTANTS.maxSelectedItems || this.selectedRequestDomains.value.length > this.CONSTANTS.maxSelectedItems);

    // Update saveFromSelection buttons
    saveFromSelectionAndReprocess.disabled = saveFromSelection.disabled = nameFieldEmpty || !typeSelected || nameFieldDuplicate;

    this.enableIsDefaultCheckbox();
  }

  // Enable IsDefaultCC checkbox if:
  // 1. there are no other default CCs
  // 2. the existing default CCs have the same type as the new one
  async enableIsDefaultCheckbox() {
    let defaultCCtype: string = await this.standardsSelectorService.getConsentCatDefaultType();
    if(defaultCCtype && (this.type?.value?.toLocaleLowerCase() !== defaultCCtype)) {
      this.ccForm.get('isDefaultCC').setValue(false);
      this.ccForm.get('isDefaultCC').disable();
    } else {
      this.ccForm.get('isDefaultCC').enable();
    }
  }

  /**
   * Returns an array of all request domains that are currently selected. The class property
   * selectedRequestDomains is an array of all domain names.
   */
  private getSelectedRequestDomains(): IRequestDomain[] {
    let allLoadedRequestDomainObjects: IRequestDomain[] = Object.values(this.selectedRequestDomains.value);

    let selectedRequestDomains = allLoadedRequestDomainObjects.map((requestDomain: IRequestDomain) => {
      delete requestDomain.countries;

      requestDomain.anyLocation = !!(requestDomain?.locationIds?.length === 0); // Any location === true if none selected
      requestDomain.regex = requestDomain.regex ? requestDomain.regex : false; // Regex will always be false if importing an existing request domain
      requestDomain.domainType = EDomainType.EXACT;

      return requestDomain;
    });

    return selectedRequestDomains;
  }

  getHotkeyHandler(key: EKeyCodes) {
    return key === EKeyCodes.KeyS
      ? () => this.save()
      : null;
  }

  handleCheckboxClick(index: number): void {
    if (this.shiftKeyPressed) {
      if (this.lastSelectedIndex < index) {
        for (let i = this.lastSelectedIndex + 1; i <= index; i++) {
          this.selection.toggle(this.dataSource.data[i]);
        }
      } else {
        for (let i = this.lastSelectedIndex - 1; i >= index; i--) {
          this.selection.toggle(this.dataSource.data[i]);
        }
      }
    } else {
      this.selection.toggle(this.dataSource.data[index]);
    }

    this.lastSelectedIndex = index;
    this.updateFooterButtonsDisabledStatus();
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Form Getters
   */
  get name(): UntypedFormControl {
    return this.ccForm.get('name') as UntypedFormControl;
  }

  get type(): UntypedFormControl {
    return this.ccForm.get('type') as UntypedFormControl;
  }

  get labels(): UntypedFormControl {
    return this.ccForm.get('labels') as UntypedFormControl;
  }

  get isDefaultCC(): UntypedFormControl {
    return this.ccForm.get('isDefaultCC') as UntypedFormControl;
  }

  get reportFilter(): UntypedFormControl {
    return this.ccForm.get('reportFilter') as UntypedFormControl;
  }

  get selectedCookies(): UntypedFormControl {
    return this.ccForm.get('selectedCookies') as UntypedFormControl;
  }

  get selectedTags(): UntypedFormControl {
    return this.ccForm.get('selectedTags') as UntypedFormControl;
  }

  get selectedRequestDomains(): UntypedFormControl {
    return this.ccForm.get('selectedRequestDomains') as UntypedFormControl;
  }
}
