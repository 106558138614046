export enum EConsentCategoriesFilterTypes {
  Name = 'name',
  Type = 'type',
  Label = 'label'
}

export interface IConsentCategoryFilter {
  type: EConsentCategoriesFilterTypes;
  display: string;
  value: number | string | object | boolean;
}
