import { Component, Input, ContentChild, TemplateRef, Output, EventEmitter, OnInit } from '@angular/core';
import { ILabel } from '@app/components/shared/services/label.service';
import { IRule } from '@app/components/rules/rules.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-selector-row',
  templateUrl: './rule-selector-row.component.html',
  styleUrls: ['./rule-selector-row.component.scss']
})
export class RuleSelectorRowComponent implements OnInit {

  @ContentChild('buttonTemplate') buttonTemplate?: TemplateRef<any>;

  @Input() rule?: IRule;
  @Input() label?: ILabel;
  @Input() labels?: ILabel[];
  @Input() includeLabels: boolean;
  @Input() rulesById?: {[id: number]: IRule};
  @Input() labelsById?: {[id: number]: ILabel};
  @Input() labelRules?: IRule[];
  @Input() isReadOnly: boolean = false;
  @Input() chipCountOnly: boolean = false;
  @Output() openRuleEditor: EventEmitter<IRule> = new EventEmitter();

  ruleWithLabels;

  ngOnInit() {
    // Convert minimal label info into full label values
    this.ruleWithLabels = { ...this.rule, labels: Array.isArray(this.rule?.labels) ? this.rule.labels : [] };
    this.rule.labels = this.ruleWithLabels?.labels?.map(label => {
      if (this.labelsById && this.labelsById[label.id]) {
        return this.labelsById[label.id];
      }
      return [];
    });
  }
}
