import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { actionWaitTime } from './action-wait-time.constants';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ActionWaitTimeComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-wait-time',
  templateUrl: './action-wait-time.component.html',
  styleUrls: ['./action-wait-time.component.scss'],
  providers: [FORM_CONTROL_VALUE_ACCESSOR]
})
export class ActionWaitTimeComponent implements ControlValueAccessor {

  readonly waitTime = actionWaitTime;

  onChanged: (value: number) => void;
  onTouched: () => void;

  waitDuration: number;

  onModelChange(time: number): void {
    this.onChanged && this.onChanged(time);
    this.onTouched && this.onTouched();
  }
  
  writeValue(time: number): void {
    if (Number.isInteger(time)) this.waitDuration = time;
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

}
