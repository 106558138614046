import { IUsageTrendsFuturePeriod, IUsageTrendsPastPeriod } from '@app/components/usage-v2/usage-v2.models';
import { IChartData } from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import { EDateFormats, formatDate, toUTC } from '@app/components/date/date.service';
import {
  attachHoverableUnderlaysToSvg,
  display,
  getItemIndexByCursorPosition,
  positionCircle,
  positionTooltip,
  setTooltipHTML
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import * as d3 from 'd3';
import { Selection } from 'd3';
import { BaseType } from 'd3-selection';

export function getDate(dateString: string): string {
  return formatDate(
    toUTC(new Date(dateString)),
    EDateFormats.dateEight,
  );
}

export function getChartData(
  periods: Array<IUsageTrendsPastPeriod | IUsageTrendsFuturePeriod>,
  property: string,
  addLastPoint = false,
): IChartData[] {
  return [
    ...periods
      .map((period, i, arr) => ({
        value: getValueByPath(period, property),
        date: period.startDateInclusive,
        dateString: getDate(period.startDateInclusive),
      })),
    ...((addLastPoint && periods.length) ?
      [periods[periods.length - 1]]
        .map((period, i, arr) => ({
          value: getValueByPath(period, property),
          date: period.endDateExclusive,
          dateString: getDate(period.endDateExclusive),
        })) :
      []),
  ]
    .filter((period) => period.value >= 0 && period.date && period.dateString)
    .sort((a, b) => a.date > b.date ? 1 : -1);
}

export function getValueByPath(obj: any, path: string): number {
  return +path.split('.').reduce((o, p) => (o && o[p] !== undefined) ? o[p] : undefined, obj);
}

export function createTooltip(
  tooltipClass: string,
): d3.Selection<HTMLDivElement, unknown, HTMLElement, undefined> {
  if (!d3.select(`.${tooltipClass}`).node()) {
    return d3.select('body')
      .append('div')
      .attr('class', tooltipClass);
  }

  return d3.select(`.${tooltipClass}`);
}

export function createCircle(
  svg: d3.Selection<SVGElement, any, any, any>,
  circleClass: string,
  circleRadius: number,
): d3.Selection<SVGCircleElement, unknown, HTMLElement, undefined> {
  if (!svg.select(`.${circleClass}`).node()) {
    return svg?.append('circle')
      .attr('r', circleRadius)
      .attr('class', circleClass);
  }

  return svg.select(`.${circleClass}`);
}

export function drawTooltip(
  svg: d3.Selection<SVGElement, any, any, any>,
  tooltipHTML: string,
  x,
  y,
  circle: d3.Selection<SVGCircleElement, unknown, HTMLElement, undefined>,
  tooltip: d3.Selection<HTMLDivElement, unknown, HTMLElement, undefined>,
  windowWidth: number,
  positionBelowCursor = false,
  positionAbsolute = false,
): d3.Selection<HTMLDivElement, unknown, HTMLElement, undefined> {
  if (!svg) {
    return;
  }

  if (circle) {
    positionCircle(
      positionBelowCursor
        ? x - svg.node().getBoundingClientRect().left
        : x,
      y,
      circle,
    );
    display(circle);
  }

  setTooltipHTML(tooltip, tooltipHTML);

  positionTooltip(
    positionAbsolute ? x : ((positionBelowCursor ? 0 : svg.node().getBoundingClientRect().left) + x),
    positionAbsolute
      ? y
      : circle
        // If circle exists use it's y position, otherwise use the y position passed in
        ? circle.node().getBoundingClientRect().y
        : ((positionBelowCursor ? 0 : svg.node().getBoundingClientRect().top) + y),
    windowWidth,
    tooltip.node().getBoundingClientRect().width,
    tooltip,
  );

  display(tooltip, 'flex');
  return tooltip;
}

export function getPercentageOfTotal(value: number, total: number): string {
  const percentage = (value / total) * 100;
  return `(${((isNaN(percentage) || !isFinite(percentage)) ? 0 : percentage).toFixed(0)}%)`;
}

export function drawHoverUnderlays(
  svg: d3.Selection<SVGElement, any, any, any>,
  container: Selection<BaseType, unknown, HTMLElement, any>,
  xAxis: d3.ScaleBand<string>,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
  windowWidth: number,
  circleClass: string,
  circleRadius: number,
  tooltipClass: string,
  exportHandler: (data: IChartData) => void,
  highlight: (shouldHighlight: boolean) => void,
): void {
  attachHoverableUnderlaysToSvg(
    {
      data: xAxis.domain(),
      x: xAxis,
      overlayRectHeight: height - margin.top,
      containerClassName: 'hoverable-underlays',
    },
    svg,
    windowWidth,
    circleClass,
    circleRadius,
    tooltipClass,
    exportHandler,
  );

  const items = svg.selectAll('.overlay-group-rect');
  const ticks = svg.selectAll('.x-axis .tick');

  container
    .on('mousemove', (mouseEvent) => {
      const [xCoord] = d3.pointer(mouseEvent, svg.node());
      const index = getItemIndexByCursorPosition(xAxis, xCoord);
      items.filter((d, i) => i === index)
        .classed('hovered', true);
      items.filter((d, i) => i !== index)
        .classed('hovered', false);
      ticks.filter((d, i) => i === index)
        .classed('hovered', true);
      ticks.filter((d, i) => i !== index)
        .classed('hovered', false);

      highlight(true);

    })
    .on('mouseleave', () => {
      items.each((d, i) => i)
        .classed('hovered', false);
      ticks.each((d, i) => i)
        .classed('hovered', false);
      highlight(false);
    });
}
