import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INewRequestDomain } from '@app/components/consent-categories/consent-categories.models';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ICCGeoSelectorItem } from '../../../cc-geo-selector/cc-geo-selector.models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-new-request-domain',
  templateUrl: './add-new-request-domain.component.html',
  styleUrls: ['./add-new-request-domain.component.scss']
})
export class AddNewRequestDomainComponent implements OnInit, OnDestroy {

  autoCompleteList: ICCGeoSelectorItem[] = [];
  newRequestDomainForm: UntypedFormGroup;
  rightFooterButtons = [
    {
      label: 'Done',
      action: () => {
        this.save();
      },
      primary: true,
      disabled: false
    }
  ];

  private destroy$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<AddNewRequestDomainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICCGeoSelectorItem[],
    private formBuilder: UntypedFormBuilder
  ) {
    this.autoCompleteList = this.data;
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  initForm(): void {
    this.newRequestDomainForm = this.formBuilder.group({
      domain: ['', this.getDomainValidator(false)],
      regex: [false, []],
      locations: [[]]
    });

    this.newRequestDomainForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.rightFooterButtons[0].disabled = !this.newRequestDomainForm.valid;
    });
  }

  save(): void {
    this.newRequestDomainForm.markAllAsTouched();
    if (this.newRequestDomainForm.invalid) return;

    const domain = this.domain.value.indexOf('http') > -1
      ? this.domain.value.split('/')[2]
      : this.domain.value.split('/')[0];

    this.closeModal({
      domain: domain,
      regex: this.regex.value,
      locationIds: this.locations.value.map(location => location.id),
      anyLocation: !this.locations.value.length
    });
  }

  onChangeMatchRegexCheckbox(ev: MatCheckboxChange) {
    this.domain.setValidators([this.getDomainValidator(ev.checked)]);
    this.domain.updateValueAndValidity();
  }

  getDomainValidator(matchRegex: boolean) {
    return matchRegex ? OPValidators.regexSnowflake : OPValidators.urlWithOptionalProtocol;
  }

  updateLocationFormControl(locations: ICCGeoSelectorItem[]) {
    this.locations.setValue(locations);
  }

  closeModal(domain?: INewRequestDomain): void {
    this.dialogRef.close(domain);
  }

  get domain(): AbstractControl {
    return this.newRequestDomainForm.get('domain');
  }

  get regex(): AbstractControl {
    return this.newRequestDomainForm.get('regex');
  }

  get locations(): AbstractControl {
    return this.newRequestDomainForm.get('locations');
  }
}
