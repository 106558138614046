<component-template componentName="OP Snackbar">
  <component-library-section sectionName="Overview" selector="<op-splash-page-basic>">
    <p>This component is used as a splash page skeleton. It receives different parts of splash page (header text, image src, and body template) and injects them in the appropriate parts of the page. Currently this is only being used for LiveConnect.</p>

    <div class="op-splash-page-basic-example">
      <op-splash-page-basic header="Splash Page Basic" imageSrc="/images/liveconnect.png">
        <ng-template #bodyTemplate>
          <p>This is the body template of the splash page.</p>
          <ul>
            <li>This is a list item in the body template.</li>
            <li>This is another list item in the body template.</li>
            <li>This is a third list item in the body template.</li>
          </ul>
        </ng-template>
      </op-splash-page-basic>
    </div>
  </component-library-section>
</component-template>