import { Component, Inject, ViewChild } from '@angular/core';
import { ILabel } from '@app/components/shared/services/label.service';
import { IButton } from '@app/models/commons';
import { IRuleSelection } from '@app/components/account/rules/rule-selector/rule-selector.models';
import { IRule, IRulePreview } from '@app/components/rules/rules.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RuleSelectorComponent } from '@app/components/account/rules/rule-selector/rule-selector.component';

export interface ICurtainedRuleSelectorComponentData {
  rules: IRulePreview[];
  labels: ILabel[];
  title: string;
  newActionRule: IRule | IRulePreview;
  selectedRules: IRuleSelection;
  openRuleCreation: () => void;
  openRuleEditor: (rule: IRule) => void;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'curtained-rule-selector',
  templateUrl: './curtained-rule-selector.component.html',
  styleUrls: ['./curtained-rule-selector.component.scss']
})

export class CurtainedRuleSelectorComponent {
  @ViewChild(RuleSelectorComponent) ruleSelectorComponent: RuleSelectorComponent;
  buttons: IButton[] = [{
    label: 'Assign',
    action: this.onAssign.bind(this),
    primary: true
  }];

  constructor(public dialogRef: MatDialogRef<CurtainedRuleSelectorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ICurtainedRuleSelectorComponentData) {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onAssign(): void {
    this.dialogRef.close(this.data.selectedRules.selectedRuleIds);
  }

  public addRule(rule: IRule) {
    this.data.selectedRules.selectedRuleIds.push(rule.id);
    this.data.selectedRules.selectedItems.push({rule});
    this.data.rules.push(rule);
    this.ruleSelectorComponent.updateView();
  }

}
