import { AfterViewInit, Component, forwardRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import {
  EReportType,
  IAuditRunTag,
  IConsentCategoryTag,
  IPaginationMetaData,
  IRunInfo,
} from '@app/components/consent-categories/consent-categories.models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import {
  EConsentCategoryCreateStep,
  EConsentCategorySourceSortBy
} from '@app/components/consent-categories/cc-create/cc-create.enums';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ConsentCategoriesUIConstants } from '@app/components/consent-categories/consent-categories.constants';
import { IDataSource } from '@app/components/shared/services/data-sources/data-sources.models';
import { ThemeService } from '@app/services/theme-service/theme.service';
import { MatPaginator } from '@angular/material/paginator';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

const REPORT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ConsentCategoryCreateTagComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-create-tags',
  templateUrl: './cc-create-tags.component.html',
  styleUrls: ['./cc-create-tags.component.scss'],
  providers: [
    REPORT_CONTROL_VALUE_ACCESSOR
  ],
})

export class ConsentCategoryCreateTagComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;
  CONSTANTS: any = { ...ConsentCategoriesUIConstants };
  destroy$: Subject<void> = new Subject();
  onChange: (value) => void;
  selection = new SelectionModel(true, []);
  searchText: string = '';
  selectAll: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource;
  selectedDataSource: MatTableDataSource<any> = new MatTableDataSource;
  displayedColumns: string[] = ['select', 'tagName', 'tagAccounts'];
  pagination: IPaginationMetaData = {
    totalCount: 0,
    totalPageCount: 0,
    pageSize: 1500,
    currentPageSize: 0,
    currentPageNumber: -1
  };
  tagsHash = {};
  tags: IAuditRunTag[] = [];
  loading: boolean = true;
  pagesLoadedCount: number = 0;
  queryParams = {
    // defaults
    page: 0, // API paginates starting with 0
    size: 1500,
    sortBy: EConsentCategorySourceSortBy.TagName,
    sortDesc: false,
    search: '',
  };
  tagFilter: string = '';
  selectedLimit: number = 1500;
  displaySelection: boolean = false;
  selectedTags: number[] = [];
  allTagsSelected: boolean;
  editableExistingTags: IConsentCategoryTag[] = [];
  filterChanged$ = new Subject<string>();
  moreTagAccounts: string[] = [];
  isDarkTheme: boolean;

  unselectedTagsInResponse: number = 0;

  shiftKeyPressed: boolean = false;
  lastSelectedIndex: number = null;

  @Input() formControl: UntypedFormControl;
  @Input() noSelectionDisplay: boolean = false;
  @Input() currentStep: EConsentCategoryCreateStep;
  @Input() reports: IDataSource[]; // Selected audits to populate tabs data
  @Input() continueToSource$: Observable<boolean>;
  @Input() existingTags: IConsentCategoryTag[];
  @ViewChild('selectedTableSort', { read: MatSort, static: true }) selectedTableSort: MatSort;
  @ViewChild('resultsTableSort', { read: MatSort, static: true }) resultsTableSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('window:keydown.shift')
  onKeyDown() {
    this.shiftKeyPressed = true;
  }

  @HostListener('window:keyup.shift')
  onKeyUp() {
    this.shiftKeyPressed = false;
  }

  constructor(
    private ccService: ConsentCategoriesService,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.resultsTableSort.sortChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe((sort: Sort) => {
      this.queryParams.sortBy = EConsentCategorySourceSortBy.TagName;
      this.queryParams.sortDesc = sort.direction === 'asc' ? false : true;
      this.resetPagination();
      this.loadTags();
    });
    this.dataSource.sort = this.resultsTableSort;

    this.selectedDataSource.sort = this.selectedTableSort;
    this.selectedDataSource.sortingDataAccessor = (data, property) => {
      switch (property) {
        case 'tagName':
          return data[property];
        case 'tagAccount':
          return data['accounts'].length;
      }
    };

    this.selectedDataSource.filterPredicate = (tag: any, filter: string) => {
      const filterLower = filter.toLowerCase();

      // Filter on all tag name and account names
      const matchString = `${tag['tagName']}~~${tag['accounts'].join('~~')}`.toLowerCase();

      return matchString.includes(filterLower);
    };

    this.addExistingTagsToHashAndSelectedTags();

    this.filterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.tagFilter = filter;
      this.queryParams.search = encodeURI(filter);
      this.resetPagination();
      this.loadTags();
    });

    this.themeService.isDarkTheme.pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.isDarkTheme = val;
    });
  }

  ngAfterViewInit() {
    // Initial tag load is triggered from the base component after selecting
    // 1+ existing audit run
    this.continueToSource$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((selectedRunsChanged) => {
      if (selectedRunsChanged) {
        this.tagsHash = {};
        this.addExistingTagsToHashAndSelectedTags();
        this.pagesLoadedCount = 0;
        this.resetPagination();

        if (this.reports.length === 0) {
          this.dataSource.data = [];
          this.loading = false;
        } else {
          this.loadTags();
        }
      }
    });

    this.paginator.page.subscribe(({pageIndex}) => {
      this.pagination.currentPageNumber = pageIndex;
      this.loadTags();
    });
  }

  resetPagination(): void {
    this.pagination = {
      totalCount: 0,
      totalPageCount: 0,
      pageSize: 1500,
      currentPageSize: 0,
      currentPageNumber: 0
    };
  }

  /**
   * Component will receive an array of existing values on init if there are any. These
   * array items are the initial values in requestDomainsHash and selectedItems.
   */
  addExistingTagsToHashAndSelectedTags(): void {
    if (!this.existingTags?.length) return;
    this.selectedTags = [];
    this.editableExistingTags = [...this.existingTags];
    this.addToTagsHash(this.existingTags);
    this.editableExistingTags.forEach(tag => this.addToSelectedItems(tag.tagId));

    this.updateSelectedTagsTable();

    // Update parent formControl with new selections
    this.setSelectedTagsInForm(this.selectedTags);
  }

  setSelectedTagsInForm(selectedTags: number[]): void {
    let selectedTagsObjects = selectedTags.map(tagName => this.tagsHash[tagName]);
    this.updateSelectedTagsTable();
    this.onChange && this.onChange(selectedTagsObjects);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  loadTags(): void {
    this.loading = true;

    // Create post body using selected reports that we are retrieving tab data for
    const runInfo: IRunInfo[] = this.reports.map(report => ({
      itemType: EReportType.AUDIT,
      itemId: report.itemId,
      runId: report.lastCompletedRun.runId
    }));

    // Fetch tags for selected reports
    this.ccService.getRunTags(runInfo, this.pagination, this.queryParams).pipe(
        take(1)
      ).subscribe(tagsDTO => {
      this.addToTagsHash(tagsDTO.tags);
      this.setAnyAccountsForNewTags(tagsDTO.tags);

      this.dataSource.data = tagsDTO.tags;
      this.pagination = tagsDTO.metadata.pagination;
      this.paginator.pageIndex = this.pagination.currentPageNumber;

      this.unselectedTagsInResponse = 0;
      this.allTagsSelected = this.areAllSelected();
      this.filterSelectedTags(this.tagFilter);

      this.loading = false;
    }, (err) => {
      this.loading = false;
      console.log('Error retrieving requested tag data');
    });
  }

  areAllSelected(): boolean {
    if (this.dataSource.data.length === 0) {
      return false;
    }

    let allSelected = true;

    this.dataSource.data.forEach(tag => {
      if (!this.tagsHash[tag.tagId] || (!this.tagsHash[tag.tagId]?.existing && !this.tagsHash[tag.tagId]?.selected)) {
        allSelected = false;
        this.unselectedTagsInResponse++;
      }
    });

    return allSelected;
  }

  setAnyAccountsForNewTags(tags: IAuditRunTag[]): void {
    tags.forEach(tag => {
      const existing = this.tagsHash[tag.tagId].existing;
      const anyAccount = this.tagsHash[tag.tagId].accounts.length >= 20;
      if (!existing && anyAccount) {
        this.tagsHash[tag.tagId].anyAccount = true;
        this.tagsHash[tag.tagId].accounts = [];
      }
    });
  }

  filterSelectedTags(filterValue: string): void {
    this.selectedDataSource.filter = filterValue;
  }

  openedSelection() {
    this.displaySelection = true;
  }

  closedSelection() {
    this.displaySelection = false;
  }

  toggleSelectAll(checked) {
    this.allTagsSelected = checked;
    // add all request domains in the list that aren't currently selected
    this.dataSource.data.forEach((tag) => {
      // if not already selected, toggle select property and push to selected array
      const existing = this.tagsHash[tag.tagId].existing;
      const selected = this.tagsHash[tag.tagId].selected;

      if (!existing) {
        // Select all checked
        if (this.allTagsSelected) {
          // Select any current values that are deselected
          if (!selected) {
            this.tagsHash[tag.tagId].selected = true;
            this.addToSelectedItems(tag.tagId);
          }
          // Deselect all current values
        } else {
          if (selected) {
            this.tagsHash[tag.tagId].selected = false;
            this.removeFromSelectedItems(tag.tagId);
          }
        }
      }
    });

    this.updateSelectedTagsTable();

    // Update parent formControl with new selections
    this.setSelectedTagsInForm(this.selectedTags);
  }

  /**
   * Manually toggles the selected property of a request domain row in requestDomainsHash
   */
  toggleSelectOne(checked, row, updateExisting = false) {
    if (this.tagsHash[row.tagId]?.existing) return;

    if (checked) {
      this.tagsHash[row.tagId].selected = true;
      this.addToSelectedItems(row.tagId);
    } else {
      this.tagsHash[row.tagId].selected = false;

      if (updateExisting && this.tagsHash[row.tagId].existing) {
        this.tagsHash[row.tagId].existing = false;
      }

      this.removeFromSelectedItems(row.tagId);
      this.allTagsSelected = false;
    }

    this.updateSelectedTagsTable();

    // Update parent formControl with new selections
    this.setSelectedTagsInForm(this.selectedTags);
  }

  debounceFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.filterChanged$.next(filter);
  }

  removeFromSelectedItems(tagId: number): void {
    const index = this.selectedTags.findIndex(selectedTagId => tagId === selectedTagId);
    this.selectedTags.splice(index, 1);
  }

  addToSelectedItems(tagId: number): void {
    this.selectedTags.push(tagId);
  }

  updateSelectedTagsTable(): void {
    this.selectedDataSource.data = this.selectedTags.map(tagId => this.tagsHash[tagId]);
  }

  removeAccount(tag, accountNameToRemove) {
    let accounts = this.tagsHash[tag.tagId].accounts;
    let indexToRemove = accounts.findIndex(accountName => accountName === accountNameToRemove);

    this.tagsHash[tag.tagId].accounts.splice(indexToRemove, 1);
  }

  /**
   * Remove all accounts for the provided tag
   */
  removeAllTagAccounts(tag) {
    this.tagsHash[tag.tagId].accounts = [];
  }

  openOtherTagAccountsDropdown(tag){
    const tagAccounts = this.tagsHash[tag.tagId].accounts;
    const numLabelsHidden = tagAccounts.length - 3; // IMPORTANT: must match what's in the template
    this.moreTagAccounts = tagAccounts.slice(-numLabelsHidden);
  }

  page(event) {
    this.pagination.currentPageNumber = event.pageIndex;
    this.loadTags();
  }

  /**
   * A tags hash map is created to manage and display state
   * (i.e. existing, selected) for the overall list of tags. Any time
   * new data is retrieved we add values to this hash if they weren't already loaded. Once
   * added they maintain their state for subsequent loads and are not added again.
   */
  addToTagsHash(tags) {
    tags.forEach(tag => {
      if (!this.tagsHash[tag.tagId]) {
        this.tagsHash[tag.tagId] = tag;
      }
    });
  }

  writeValue(value: IConsentCategoryTag[]) {
    const hashFromValue = {};
    value.forEach(tag => {
      hashFromValue[tag.tagId] = tag;
    });

    this.tagsHash = hashFromValue;
  }

  registerOnChange(fn: (value: IConsentCategoryTag[]) => void) { this.onChange = fn; }

  registerOnTouched(fn) {}

  handleCheckboxClick(checked: boolean, index: number): void {
    if (this.shiftKeyPressed) {
      if (this.lastSelectedIndex < index) {
        for (let i = this.lastSelectedIndex + 1; i <= index; i++) {
          this.toggleSelectOne(checked, this.dataSource.data[i]);
        }
      } else {
        for (let i = this.lastSelectedIndex - 1; i >= index; i--) {
          this.toggleSelectOne(checked, this.dataSource.data[i]);
        }
      }
    } else {
      this.toggleSelectOne(checked, this.dataSource.data[index]);
    }

    this.lastSelectedIndex = index;
  }
}
