import { ColDef } from '@ag-grid-community/core';
import { EGridReportType } from '@app/components/shared/services/grid-api/grid-api.constants';

export const AccountReportsBaseUrl = 'reports';

export const ValidReportIds: EGridReportType[] = [
  EGridReportType.WebAuditRuns,
  EGridReportType.Pages,
  EGridReportType.Cookies,
];

export const ReportDefaultNames: { [key in EGridReportType]: string } = {
  [EGridReportType.WebAuditRuns]: 'Web Audit Runs',
  [EGridReportType.Pages]: 'Pages',
  [EGridReportType.Cookies]: 'Cookies',
};

export const ReportIcons: { [key in EGridReportType]: string } = {
  [EGridReportType.WebAuditRuns]: 'explore',
  [EGridReportType.Pages]: 'description',
  [EGridReportType.Cookies]: 'cookie',
};

export const DefaultAgGridColumnDefinition: ColDef = {
  flex: 1,
  minWidth: 100,
  // allow every column to be aggregated
  // enableValue: false,
  // allow every column to be grouped
  // enableRowGroup: false,
  // allow every column to be pivoted
  // enablePivot: false,
  filter: false,
  wrapHeaderText: true,
  autoHeaderHeight: true,
};
