<form [formGroup]="formData">
  <span class="status-code-text">Status Code</span>

  <mat-form-field class="styled-mat-input" appearance="outline">
    <mat-label>Operator</mat-label>
    <mat-select formControlName="matchType">
      <mat-option class="slim-mat-option"
                  *ngFor="let operator of TagStatusCodeOperators"
                  [value]="operator.value">
        {{ operator.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="matchTypeControl.touched && matchTypeControl.invalid">
      <mat-icon>warning_amber</mat-icon>
      Field can't be empty
    </mat-error>
  </mat-form-field>

  <mat-form-field class="styled-mat-input last-field"
                  appearance="outline"
  >
    <mat-label>Value</mat-label>
    <input matInput
           formControlName="value"
           [matAutocomplete]="auto"
           (change)="setValue($event.target.value)">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayStatusCodeFn">
      <mat-option class="slim-mat-option" disabled>
        <op-clearable-input [placeholderText]="'Specify exact status code'"
                            (onUserInput)="setValue($event)"
                            class="clearable-input">
        </op-clearable-input>
      </mat-option>
      <mat-option class="slim-mat-option"
                  *ngFor="let statusCode of TagStatusCodeValues"
                  [value]="statusCode">
        {{ statusCode.name }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="valueControl.touched && valueControl.invalid">
      <mat-icon>warning_amber</mat-icon>
      Field can't be empty
    </mat-error>
  </mat-form-field>

  <mat-icon class="remove-icon" (click)="onRemoveStatusCode.emit()">
    highlight_off
  </mat-icon>
</form>
