import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import { Observable } from 'rxjs';
import {
  IWebVitalsChartColorizedBoundaries,
  IWebVitalsChartColorizedStats
} from '../web-vitals-full-screen-chart/web-vitals-full-screen-chart.models';
import {
  EWebVitalsFullScreenChartTooltipType
} from '@app/components/shared/components/viz/web-vitals-full-screen-chart/web-vitals-full-screen-chart.constants';
import { EPageSummaryTrendNames } from '@app/components/audit-reports/reports/page-summary/page-summary.constants';
import { EPageInfoTrendNames } from '@app/components/audit-reports/page-information/page-information.constants';
import { IWebVitalsTrendDataDistribution } from '@app/components/audit-reports/reports/page-summary/page-summary.models';

export enum EChartDateRange {
  oneMonth = 'oneMonth',
  threeMonth = 'threeMonth',
  sixMonth = 'sixMonth',
  nineMonth = 'nineMonth',
  thirteenMonth = 'thirteenMonth',
}

export interface IFullscreenChartData {
  value: number;
  data?: {
    stats: Record<string, number>,
    distribution: IWebVitalsTrendDataDistribution,
  },
  date: string;
}

export interface IFullscreenChartDataWithStats {
  chartData: IFullscreenChartData[];
  stats?: IFullscreenChartStatsItem[];
}

export interface IFullscreenChartDataFormatted {
  value: number;
  data?: {
    stats: Record<string, number>,
    distribution: IWebVitalsTrendDataDistribution,
  },
  date: Date;
}

export interface IFullscreenChartStatsItem {
  title: string;
  value: number;
}

export interface IFullscreenChartModalConfig {
  // the run completion date from which to compute the default timeframe
  timeframeOriginRunCompletion: string;

  // function for returning data to be displayed in area graph
  getData: (days: number) => Observable<IFullscreenChartDataWithStats>;

  // Additional summary line data to draw on the graph
  getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[];

  // chart configuration (e.g. title, tooltip, etc)
  chartConfig: IFullscreenChartConfig;
  chartType?: EChartType;

  tooltipType: EWebVitalsFullScreenChartTooltipType;
  metricType?: EPageSummaryTrendNames | EPageInfoTrendNames;
  digitsInfo?: string;
}

export type IFullscreenChartConfig = IFullscreenChartConfigStandard | IFullscreenChartConfigWebVitals;

export interface IFullscreenChartConfigStandard {
  title?: string;

  // Data to plot
  data: IFullscreenChartData[] | IFullscreenChartDataFormatted[];

  limit?: number;
  limitPlaceholder?: string;

  // Gradient color
  gradientColor?: string;

  // Axis info
  showXAxis?: boolean;
  showYAxis?: boolean;
  sideLabel?: string;
  shortLabel?: string;
  bottomLabel?: string;
  fixedYAxis?: number;
  xAxisTimeFormat?: string;
  xAxisNoRotate?: boolean;
  xTickPadding?: number;
  yTickPadding?: number;
  xTickIntervalOptions?: IChartTickIntervalOptions;

  // Summary lines
  showSummaryLines?: boolean;
  summaryLines?: ISummaryLine[];

  tooltip?: any;
  tooltipValueLabel?: string,
  noTooltip?: boolean;
  showTopValues?: boolean;
  yAxisTickCount?: number;
  smallCircles?: boolean;
  noHoverEffect?: boolean;

  margin?: { top: number, right: number, bottom: number, left: number };
  digitsInfo?: string;
  truncate?: boolean;
}

export interface IFullscreenChartConfigWebVitals {
  title: string;
  data: IFullscreenChartData[] | IFullscreenChartDataFormatted[];
  sideLabel: string;
  tooltipValueLabel?: string,
  shortLabel?: string;
  boundaries: IWebVitalsChartColorizedBoundaries;
  stats: IWebVitalsChartColorizedStats;
  metricType?: EPageSummaryTrendNames | EPageInfoTrendNames;
  digitsInfo?: string;
  truncate?: boolean;
}

export interface IChartTickIntervalOptions {
  interval: EChartInterval;
  countPerInterval: number;
}

export enum EChartInterval {
  daily = 'daily',
  monthly = 'monthly',
  yearly = 'yearly'
}

export enum EChartType {
  Area = 'area',
  WebVitals = 'webVitals',
  WebVitalsBoxPlot = 'webVitalsBoxPlot'
}
