<form [formGroup]="exportOptionsForm">
  <p>EXPORT OPTIONS</p>

  <mat-checkbox color="primary" [formControlName]="EPageInfoLinkDownloadOption.ToInternalPages">
    Links to Audited Internal Pages ({{linksToInternalPages}})
  </mat-checkbox>
  <mat-checkbox color="primary" [formControlName]="EPageInfoLinkDownloadOption.ToExternalPages">
    Links to Audited External Pages ({{linksToExternalPages}})
  </mat-checkbox>
  <mat-checkbox color="primary" [formControlName]="EPageInfoLinkDownloadOption.ToUrlsNotAudited">
    Links to URLs Not Audited ({{linksToNotAuditedPages}})
  </mat-checkbox>

  <button mat-flat-button color="primary" (click)="submit()" [disabled]="totalAcceptedLinks === 0">
    Export {{ totalAcceptedLinks > 0 ? '(' + totalAcceptedLinks + ' Links)' : '' }}
  </button>

  <p>
    <a [routerLink]="['../audit-exports']">Go to the audit exports</a> to get all of the broken links across all pages in this audit.
  </p>
</form>
