<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="{{ title }} Action Set"
                     [disabled]="assigningRule"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <form [formGroup]="actionSetForm" class="action-set-form">
      <div class="action-set-name">
        <mat-form-field>
          <mat-label>Action Set Name</mat-label>
          <input matInput formControlName="name">
          <mat-error *ngIf="name.invalid">This field is required</mat-error>
        </mat-form-field>
      </div>
      <div class="action-set-editor">
        <actions-creator formControlName="actions"
                         class="actions-creator"
                         [mode]="actionsModes.ActionSet"
                         [submitted]="submitted"
                         (openRuleSelector)="openRuleSelector($event)">
        </actions-creator>
      </div>
    </form>
  </ng-template>
</op-modal>
