import { AuditReportBase } from '@app/components/audit-reports/reports/general-reports.models';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { OpModalService } from '@app/components/shared/components/op-modal';

export abstract class PrivacyReportsAbstract extends AuditReportBase {
  abstract auditId: number;
  abstract runId: number;
  protected abstract modalService: OpModalService;

  openConsentCategoryManager(): void {
    const data = {
      auditId: this.auditId,
      runId: this.runId,
      step: 2,
      standardsTab: EStandardsTabs.ConsentCategories,
      disableNavigationButtons: true
    };

    this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor');
  }

}
