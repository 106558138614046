import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { userIsOPAdmin } from '@app/authUtils';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AccountsService } from '@app/components/account/account.service';
import { SystemStatusService } from '@app/components/admin-portal/system-status/system-status.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['./system-status.component.scss']
})
export class SystemStatusComponent implements OnInit {

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private accountsService: AccountsService,
              private systemStatusService: SystemStatusService) { }

  ngOnInit(): void {
    const url = AccountSettingsUrlBuilders.user();

    this.accountsService.getUser().subscribe(user => {
      if (!userIsOPAdmin(user)) {
        this.router.navigateByUrl(url);
      }
    }, () => {
      this.router.navigateByUrl(url);
    });
  }

  // TODO: this logic causes ExpressionChangedAfterItHasBeenCheckedError
  // We should subscribe on and listen to RxJS Subject instead
  // For more details, see SystemStatusService
  timeUntilRefresh(): number {
    return this.systemStatusService.timeUntilRefresh();
  }

  // TODO: this logic causes ExpressionChangedAfterItHasBeenCheckedError
  // We should subscribe on and listen to RxJS Subject instead
  // For more details, see SystemStatusService
  isPaused(): boolean {
    return this.systemStatusService.isPaused();
  }

  // It should trigger RxJS Subject's next(), that will pause interval observable
  // For more details, see SystemStatusService
  pause() {
    this.systemStatusService.pause();
  }

  // It should trigger RxJS Subject's next(), that will resume interval observable
  // For more details, see SystemStatusService
  resume() {
    this.systemStatusService.resume();
  }

  // It should trigger RxJS Subject's next(), that will refresh interval observable
  // For more details, see SystemStatusService
  refresh() {
    this.systemStatusService.refresh();
  }

}
