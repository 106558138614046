<div class="device-categories-wrp">
  <div class="categories-title-wrp">
      <span class="categories-title">
        Which category best describes your device?
      </span>
  </div>
  <div class="categories-list-wrp">
    <div class="categories-list">
      <div class="category-wrp" *ngFor="let cat of objectKeys(categories)">
        <div class="category"
             (click)="clickActivateCategory(categories[cat])"
             [ngClass]="{'active': cat === activeCategoryKey}"
             (mouseenter)="hoveredCategoryKey = cat"
             (mouseleave)="hoveredCategoryKey = cat">
          <img class="category-img"
               [src]="cat === activeCategoryKey ? categories[cat]?.imgActive : categories[cat]?.imgDefault"/>
          <span class="category-name">{{categories[cat]?.name}}</span>
        </div>
        <div class="category-active-bar" *ngIf="cat === activeCategoryKey"></div>
        </div>
      <div class="category-hover-bar" [ngClass]="getHoverBarClass(hoveredCategoryKey)"></div>
    </div>
  </div>
</div>
