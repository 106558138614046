import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ShortcutsConfig } from './keyboard-shortcuts-modal.constants';
import { StorageService, StorageType } from '../shared/services/storage.service';
import { KeyboardShortcutsPrefStorageKey } from '../shared/services/keyboard-shortcuts/keyboard-shortcuts.constants';
import { KeyboardShortcutsService } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'keyboard-shortcuts-modal',
  templateUrl: './keyboard-shortcuts-modal.component.html',
  styleUrls: ['./keyboard-shortcuts-modal.component.scss']
})
export class KeyboardShortcutsModalComponent {

  shortcutsToggledOn: boolean = false;
  shortcuts = ShortcutsConfig;

  constructor(
    private dialogRef: MatDialogRef<KeyboardShortcutsModalComponent>,
    private storageService: StorageService,
    private keyboardShortcutsService: KeyboardShortcutsService
  ) {
    this.keyboardShortcutsService.shortcutsAreEnabled$.subscribe((value: boolean) => {
      this.shortcutsToggledOn = value;
    });
  }

  onShortcutToggleChange(value: boolean): void {
    this.keyboardShortcutsService.shortcutsAreEnabled$.next(value);
    this.storageService.setValue(KeyboardShortcutsPrefStorageKey, value, StorageType.Local);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
