import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { ECIAppearance } from '@app/components/shared/components/op-clearable-input/op-clearable-input.constants';

/**
 * This is a very basic wrapper around the material input
 * that is used to create a clearable field that is used
 * throught the ObservePoint app. The full API of the input
 * is not available but this wrapper component could be
 * extended to support the full API at a later date
 * if necessary.
 */

@Component({
  selector: 'op-clearable-input',
  templateUrl: './op-clearable-input.component.html',
  styleUrls: ['./op-clearable-input.component.scss']
})
export class OpClearableInputComponent implements OnInit, OnChanges {

  inputValue: string;
  ECIAppearance = ECIAppearance;

  @Input() placeholderText: string;
  @Input() emitKeyboardEvent?: boolean = false;
  @Input() appearance?: ECIAppearance = ECIAppearance.FILLED;
  @Input() defaultValue?: string;
  @Input() autofocus = true;

  @Output() onUserInput: EventEmitter<string | KeyboardEvent> = new EventEmitter();
  @Output() onBlur: EventEmitter<FocusEvent> = new EventEmitter();

  @ViewChild('clearableInput') clearableInput: ElementRef<HTMLInputElement>;
  @ViewChild('clearButton') clearButton: ElementRef<HTMLButtonElement>;

  ngOnInit(): void {
    if (this.defaultValue) {
      this.inputValue = this.defaultValue;
      this.onUserInput.emit(this.inputValue);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultValue) {
      this.defaultValue = changes.defaultValue.currentValue;
      this.inputValue = this.defaultValue;
    }
  }

  onInputChange(e: KeyboardEvent): void {
    this.inputValue = (e.target as HTMLInputElement).value;

    this.emitKeyboardEvent
      ? this.onUserInput.emit(e)
      : this.onUserInput.emit(this.inputValue);
  }

  onInputBlurred(e: FocusEvent) {
    if (this.clearButton && e.relatedTarget !== this.clearButton.nativeElement) {
      this.onBlur.emit(e);
    }
  }

  clearInput(): void {
    this.inputValue = '';
    this.onUserInput.emit(this.inputValue);

    if (this.autofocus) {
      this.clearableInput.nativeElement.focus();
    }
  }

  focus(): void {
    if (this.autofocus) {
      this.clearableInput.nativeElement.focus();
    }
  }

  get value(): string {
    return this.clearableInput.nativeElement.value;
  }
}
