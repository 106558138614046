import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  ViewChild
} from '@angular/core';
import { IRuleDetailsItem } from '@app/components/account/rules/rules.models';
import { RuleDetailsTypes } from '@app/components/account/rules/rules.enums';
import { IRuleDetailsCache } from './rule-details.models';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IUser } from '@app/moonbeamModels';
import { ComponentChanges } from '@app/models/commons';
import { EDateFormats } from '@app/components/date/date.service';
import { IRuleJourneyUsage } from '../rule-library.models';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { IWebJourneyRun } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { WebJourneyReportUrlBuilders } from '@app/components/web-journey-report/web-journey-report.constants';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-details',
  templateUrl: './rule-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class RuleDetailsComponent implements AfterContentInit, OnChanges {

  @Input() rule: IRuleDetailsCache;
  @Input() type: RuleDetailsTypes;
  @Input() users: IUser[];
  @Input() loading: boolean;

  @ViewChild(MatSort) sort: MatSort;

  readonly displayedColumns: Array<keyof IRuleDetailsItem> = ['name', 'createdBy', 'lastRunDate'];
  readonly dateTimeFormat = EDateFormats.dateThirteen;

  usernameToUser = new Map<string, IUser>();
  dataSource = new MatTableDataSource<IRuleDetailsItem>();

  constructor(private router: Router,
              private journeyApiService: IWebJourneyApiService,){}

  ngOnChanges(changes: ComponentChanges<RuleDetailsComponent>) {
    if (changes.users?.currentValue !== changes.users?.previousValue) {
      this.usernameToUser = ArrayUtils.toMap(changes.users.currentValue, 'username');
    }
    this.prepareRulesList();
  }

  ngAfterContentInit() {
    this.prepareRulesList();
  }

  private prepareRulesList(): void {
    const usages = this.getRuleItemsDependOnType() || [];
    this.dataSource.data = usages.map(usage => {
      const createdByUser = this.usernameToUser.get(usage.journey.createdBy);
      return {
        name: usage.journey.name,
        createdBy: `${createdByUser?.firstName} ${createdByUser?.lastName}`,
        lastRunDate: usage.journey.lastRunDate,
        journey: usage.journey
      };
    });
    this.dataSource.sort = this.sort;
  }

  private getRuleItemsDependOnType(): Array<IRuleJourneyUsage>{
    return this.rule && [RuleDetailsTypes.audit, RuleDetailsTypes.webJourney].includes(this.type) ?
      this.rule[this.type] :
      [];
  }

  onItemClick = (item: IRuleDetailsItem): void => {
    switch (this.type){
      case RuleDetailsTypes.audit:
        return this.openAudit(item.journey.id, item.journey.lastRunId);
      case RuleDetailsTypes.webJourney:
        return this.openGuidedJourney(item.journey);
    }
  }

  private openAudit(auditId: number, runId: number): void {
    this.router.navigateByUrl(AuditReportContainerUrlBuilders.base(auditId, runId));
  }

  private openGuidedJourney(journey: any): void {
    const queryParams = new HttpParams()
      .set('folderId', journey.folderId)
      .set('domain', journey.domainId);

    this.journeyApiService.getJourneyLastRun(journey.id).then((run: IWebJourneyRun) => {
      this.router.navigateByUrl(WebJourneyReportUrlBuilders.results(journey.id, run.id) + '?' + queryParams.toString());
    },
    () => {
      this.router.navigateByUrl(WebJourneyReportUrlBuilders.results(journey.id, journey.lastRunId) + '?' + queryParams.toString());
    });
  }

}
