import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { IOpTableCellTooltipData, OpTableCellTooltipService } from './op-table-cell-tooltip.service';

@Directive({
  selector: '[opTableCellTooltip]'
})
export class OpTableCellTooltipDirective {
  @Input() opTableCellTooltip: string;
  @Input() opTableCellTooltipPrefix?: string;

  constructor(
    private el: ElementRef,
    private pageTableUrlCellTooltipSvc: OpTableCellTooltipService) { }

  @HostListener('mouseenter') onMouseEnter() {
    const bounds: DOMRect = this.el.nativeElement.getBoundingClientRect();
    const update: IOpTableCellTooltipData = {
      bottom: window.innerHeight - bounds.top + 6,
      left: bounds.left,
      maxWidth: window.innerWidth - bounds.left - 20,
      text: this.opTableCellTooltip
    };
    if (this.opTableCellTooltipPrefix) {
      update['prefix'] = this.opTableCellTooltipPrefix;
    }
    this.pageTableUrlCellTooltipSvc.showTooltip(update);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.pageTableUrlCellTooltipSvc.hideTooltip();
  }
}
