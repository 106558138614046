import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MdePopoverTrigger } from '@app/components/popover';

@Component({
  selector: 'op-tooltip',
  templateUrl: './op-tooltip.component.html',
  styleUrls: ['./op-tooltip.component.scss']
})
export class OpTooltipComponent<T> {

  @Input() openIcon: string;
  @Input() openText: string;

  @Input() title: string;
  @Input() acceptText: string;
  @Input() cancelText: string;
  @Input() payload: T;
  @Output() onAccepted = new EventEmitter<T>();
  @Output() onCanceled = new EventEmitter<void>();

  @ViewChild(MdePopoverTrigger) popover: MdePopoverTrigger;

  accept() {
    this.close();
    this.onAccepted.emit(this.payload);
  }

  cancel() {
    this.close();
    this.onCanceled.emit();
  }

  private close() {
    this.popover.closePopover();
  }

}
