import { Route } from '@angular/router';
import { NoReadOnlyGuard } from '../core/permissions.guard';
import { ConsentCategoriesComponent } from './consent-categories.component';
import {
  ConsentCategoriesPaths,
  ConsentCategoriesStateNames,
} from './consent-categories.constants';

export const ConsentCategoriesFutureState: Route = {
  path: ConsentCategoriesPaths.base,
  canLoad: [ NoReadOnlyGuard ],
  loadChildren: () => import('./consent-categories.module').then(mod => mod.ConsentCategoriesModule),
};

export const ConsentCategoriesRoutes: Route = {
  path: '',
  data: {
    stateName: ConsentCategoriesStateNames.base,
    title: 'Consent Categories',
    betaLabel: false,
  },
  children: [
    { path: '', component: ConsentCategoriesComponent },
  ]
};
