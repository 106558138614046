import { Names } from '@app/moonbeamConstants';
import * as angular from 'angular';

export class RulesReport implements angular.IDirective {
  bindToController = {
    failedRuleReports: '=failed',
    notAppliedRuleReports: '=notApplied',
    passedRuleReports: '=passed',
    enableRuleEditing: '='
  };
  controller = Names.Controllers.rulesReport;
  controllerAs = 'reportCtrl';
  template = require('@app/components/reporting/rules/rulesReport.html');
  transclude = true;

  constructor() {
  }

  static Factory(): angular.IDirectiveFactory {
    const directive = () => {
      return new RulesReport();
    };
    return directive;
  }

}
