<usage-chart [trends]="trends"
             [currentTerm]="currentTerm"
             [isFiltered]="isFiltered"
             [svgBoxSelectorName]="'usage-audit-top-chart'"
             [displayedCharts]="topChartDisplayedCharts"
             [title]="'Audit Pages Scanned Per Month'"
             (periodExport)="exportPeriod($event)"
></usage-chart>

<usage-chart [trends]="trends"
             [currentTerm]="currentTerm"
             [isFiltered]="isFiltered"
             [svgBoxSelectorName]="'usage-audit-bottom-chart'"
             [displayedCharts]="bottomChartDisplayedCharts"
             [title]="'Audited Pages Per Term'"
             (periodExport)="exportPeriod($event)"
></usage-chart>
