<ng-select
    [class.hiddenSelectedValue]="searchText || searchText === ''"
    class="op-tag-picker"
    [items]="tags"
    [virtualScroll]="true"
    [searchFn]="searchItem.bind(this)"
    (change)="onChange($event)"
    (close)="onClose()"
    (open)="onOpen()"
    appendTo="body"
    [appearance]="appearance"
    [placeholder]="placeholder">
  <ng-template ng-label-tmp let-item="item" >
    <div class="tag-label flex-row flex-center-items">
      <img [src]="getTagIconUrl(item.id)" class="tag-icon"/>
      <div class="tag-info">
        <div class="name" [innerHTML]="item.name | highlightTextPipe : searchText"></div>
      </div>
    </div>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div class="tag-option flex-row flex-center-items">
      <span class="tag-icon-container">
        <img [src]="item.iconUrl" class="tag-icon"/>
      </span>
      <div class="tag-info">
        <div class="name" [innerHTML]="item.name | highlightTextPipe : search"></div>
        <div class="category" [innerHTML]="getTagCategory(item.tagCategoryId).category | highlightTextPipe : search"></div>
      </div>
    </div>
  </ng-template>
</ng-select>
