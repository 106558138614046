import { Component, Input } from '@angular/core';
import { EAssignmentType } from '@app/models/commons';
import { IComparisonRun } from '../comparison-library.model';
import { EComparisonRunStatusType } from '../comparison-library.enums';
import { Router } from '@angular/router';
import { RorReportUrlBuilders } from '@app/components/ror-comparison-report/ror-comparison-report.contants';
import { formatDefs } from '@app/components/date/date.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'comparison-history',
  templateUrl: './comparison-library-history.component.html',
  styleUrls: ['./comparison-library-history.component.scss']
})
export class ComparisonHistoryComponent {
  dateFormat: string = formatDefs.dateTwelve;
  
  @Input() runs: IComparisonRun[] = [];
  @Input() loading = false;

  readonly statuses = EComparisonRunStatusType;

  displayedColumns = ['history', 'time', 'status'];

  constructor(private router: Router) {}

  openRun(item: IComparisonRun): void {
    switch (item.itemType) {
      case EAssignmentType.audit:
        this.router.navigateByUrl(RorReportUrlBuilders.base(item.itemId, item.runId));
        break;
      case EAssignmentType.webJourney:
      case EAssignmentType.liveConnect:
        //todo
        break;
      default:
        break;
    }
  }
}
