import { IRuleV3, IRuleV3ActionRules } from '@app/components/rules/rules.models';
import { Injectable } from '@angular/core';
import { IActionSetAction, IActionSetSelector } from '@app/components/action-set-library/action-set-library.models';
import { IWebElementSelector } from '@app/components/shared/components/multi-selectors/multi-selectors.models';
import { MultiSelectorsService } from '../shared/components/multi-selectors/multi-selectors.service';
import { IActionDetails } from '../actions/action-details/action-details.models';
import { EActionCreatorMode } from '@app/components/actions/actions-creator/actions-creator.enum';
import { EActionTypeV3 } from '../web-journey/web-journey.models';

@Injectable()
export class TransformActionsService {

  constructor(private multiSelectorService: MultiSelectorsService) {}

  convertUiActionsToApi(actions: any[], mode?: EActionCreatorMode, copy?: boolean): IActionSetAction[] {
    return actions && actions.length ? actions.map((action: any, index: number) => {
      let newAction: IActionSetAction = {
        sequence: index,
        actionType: action.type === EActionTypeV3.CmpOptInOptOut ? action.cmpType : action.type,
        waitDuration: action.waitDuration ? action.waitDuration : 0
      };

      if (action.selectors) newAction['selectors'] = this.convertUiSelectorsToApi(action.selectors);
      if (action.value) newAction['value'] = action.value;
      if (action.label || action.label === null || action.label === '') newAction['description'] = action.label ?? '';
      if (action.hasOwnProperty('preventNavigation')) newAction['preventNavigation'] = action.preventNavigation;
      if (action.actionSetId) newAction['actionSetId'] = action.actionSetId;
      if (action.url) newAction['url'] = action.url;
      if (action.cmpUrl) newAction['url'] = action.cmpUrl;
      if (action.hasOwnProperty('isRequired')) newAction['isRequired'] = action.isRequired;
      if (action.js) newAction['js'] = action.js;
      if (action.hasOwnProperty('seconds')) newAction['seconds'] = action.seconds;
      if (action.actionId && !copy) newAction['id'] = action.actionId;
      if (action.actionSet) newAction['actionSetId'] = action.actionSet.id;

      // check if action is a masked input action to begin with
      if (action.maskedValue) {
        // sometimes the form provides an object and other times
        // a string so we're handling both cases here
        newAction['maskedValue'] = action.maskedValue.maskedValue
        ? action.maskedValue.maskedValue
        : action.maskedValue;
      }

      mode !== undefined && mode === EActionCreatorMode.AuditActions
        ? newAction['pageFilter'] = action.filter ? action.filter : '.*'
        : newAction['pageFilter'] = action.filter;

      return newAction;
    }) : [];
  }

  private convertUiSelectorsToApi(selectors: IWebElementSelector[]): IActionSetSelector[] {
    if(!selectors || !selectors.length) return;

    const uiSelectors = {
      1: 'id',
      2: 'name',
      3: 'css',
      4: 'xpath',
      5: 'htmlattributes'
    }

    return selectors.map((selector: IWebElementSelector) => {
      return {
        selectorType: uiSelectors[selector.selectorType],
        value: selector.selectorType === 5
          ? this.multiSelectorService.parseAttributes(selector.value as string)
          : selector.value
      } as IActionSetSelector;
    });
  }

  convertApiActionsToUi(actions: IActionSetAction[], actionRules?: IRuleV3ActionRules[]): IActionDetails[] {
    return actions.map((action: IActionSetAction) => {

      let newAction: any = {
        sequence: action.sequence,
        type: this.handleActionType(action.actionType),
        label: action.description ? action.description : null,
        matchAllPages: !!(action.pageFilter && action.pageFilter === '.*'),
        filter: action.pageFilter || '',
        waitDuration: action.waitDuration || 0,
        actionId: action.id,
        preventNavigation: !!action.preventNavigation,
        rules: this.getActionRules(action, actionRules)
      }

      if (action.value) newAction['value'] = action.value;
      if (action.js) newAction['js'] = action.js;
      if (action.hasOwnProperty('seconds')) newAction['seconds'] = action.seconds;
      if (action.selectors) newAction['selectors'] = this.convertApiSelectorsToUi(action.selectors);
      if (action.maskedValue) newAction['maskedValue'] = action.maskedValue;
      if (action.actionSetId) {
        newAction['actionSet'] = {
          id: action.actionSetId
        }
      }

      if (action.url) {
        if (newAction.type === EActionTypeV3.CmpOptInOptOut) {
          newAction['cmpType'] = action.actionType;
          newAction['cmpUrl'] = action.url;
          newAction['isRequired'] = action.isRequired;
        } else {
          newAction['url'] = action.url
        }
      }

      return newAction;
    });
  }

  private handleActionType(type: EActionTypeV3): string {
    return type === EActionTypeV3.CmpOptIn || type === EActionTypeV3.CmpOptOut
      ? EActionTypeV3.CmpOptInOptOut
      : type;
  }

  private convertApiSelectorsToUi(selectors: IActionSetSelector[]): IWebElementSelector[] {
    if(!selectors || !selectors.length) return;

    const apiSelectors = {
      id: 1,
      name: 2,
      css: 3,
      xpath: 4,
      htmlattributes: 5
    }

    return selectors.map((selector: IActionSetSelector) => {
      return {
        selectorType: apiSelectors[selector.selectorType],
        value: selector.value
      } as IWebElementSelector;
    });
  }

  private getActionRules(action: IActionSetAction, actionRules?: IRuleV3ActionRules[]): IRuleV3[] {
    if(!actionRules) return [];
    const rulesForAction = actionRules.filter(rule => rule.actionId === action.id);
    return rulesForAction.length ? rulesForAction[0].rules : [];
  }
}
