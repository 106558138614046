import { ActivatedRoute } from '@angular/router';
import { allComparisonsPlaceholder } from './../../ror-comparison-report.contants';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { RorComparisonReportService } from './../../ror-comparison-report.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPage, IComparisonReportStateParams } from '../../ror-comparison-report.models';
import { combineLatest, Subject } from 'rxjs';
import { RorComparisonReportScoresService } from '../../shared/ror-comparison-report-scores.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-pages-missing',
  templateUrl: './ror-pages-missing.component.html',
  styleUrls: ['./ror-pages-missing.component.scss']
})
export class RorPagesMissingComponent implements OnInit, OnDestroy {

  pages: IPage[] = [];

  auditId: number;
  runId: number;
  pagesCount = 0;
  comparisonSnapshotId: number;

  loaded = false;

  readonly limit = 100;
  totalPages: number;
  displayedColumns: string[] = ['pageUrl'];
  dataSource: MatTableDataSource<IPage>;

  private destroySubject = new Subject();

  constructor(private activatedRoute: ActivatedRoute,
              private comparisonReportService: RorComparisonReportService,
              private comparisonReportScoresService: RorComparisonReportScoresService) {
    this.dataSource = new MatTableDataSource<IPage>(this.pages);
  }

  ngOnInit() {
    combineLatest(
      [this.activatedRoute.params, this.activatedRoute.queryParams]
    ).pipe(
      map(([params, queryParams]) => ({...params, ...queryParams})),
      takeUntil(this.destroySubject)
    ).subscribe((stateParams: IComparisonReportStateParams) => {
      this.auditId = parseInt(stateParams.auditId);
      this.runId = parseInt(stateParams.runId);
      this.comparisonSnapshotId = stateParams.comparisonSnapshotId ? 
        parseInt(stateParams.comparisonSnapshotId) : allComparisonsPlaceholder.comparisonSnapshotId;

      this.fetchData();
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  private loadPages(reload?: boolean): void {
    const offset = reload ? 0 : this.pages.length;
    
    this.comparisonReportService.getPagesMissingFromBaseline(
      this.auditId, this.runId, this.comparisonSnapshotId, offset, this.limit,
    ).pipe(
      catchError(err => {
        return err;
      })
    ).subscribe((pages: IPage[]) => {
      this.pages = reload ? pages : this.pages.concat(pages);
      this.dataSource.data = this.pages; // Update the datasource with the new pages
    });
  }

  private fetchData() {
    this.comparisonReportScoresService.auditScores$
    .pipe(takeUntil(this.destroySubject))
    .subscribe(scores => {
      const filteredScores = scores.filter(s => s.runId === this.runId);
      if (this.comparisonSnapshotId !== allComparisonsPlaceholder.comparisonSnapshotId) {
        const s = filteredScores.find(s => s.comparisonSnapshotId === this.comparisonSnapshotId);
        this.pagesCount = s ? s.notComparedPages : 0;
      } else {
        const allTags = this.comparisonReportScoresService.calcAllTags(filteredScores);
        this.pagesCount = allTags ? allTags.notComparedPages : 0;
      }
      this.loadPages(true);
    });
  }
}
