
import { throwError as observableThrowError, Observable, Subscriber, PartialObserver, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { ISerializer } from '../../api/apiService';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IOPSpinnerService } from '../../ui/spinner/spinnerService';
import { map, catchError, finalize } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { ApiErrorHandlerFactory } from './api.utils';

export interface IApiErrorResponse {
  code?: number // not sure what this is used for but haven't seen it populated, kept for back compatibility
  /**
   * Populated by an API to indicate a specific problem with this request.
   * Usually used when multiple different situations need to be handled within one error response status code
   */
  errorCode?: number
  /**
   * More or less user friendly error message.
   * Not always can be shown to the end user.
   */
  message: string | 'Unknown error'

  /**
   * Standard HTTP status code 400, 404, 500 ...
   */
  statusCode: number

  /**
   * Actual HTTP request URL
   */
  url: string
}

export class ApiObservable extends Observable<any> {
  constructor(src: Observable<any>, private logContext: { reqMethod: string; url: string }) {
    // ApiObservable both extends and wraps a standard Observable<any>.
    // When constructing ApiObservable, we pass to the parent class constructor
    // a method that wires up ApiObservable's subscriber to the
    // source Observable (the one we're wrapping)
    const onSubscribed = (subscriber: Subscriber<any>) => {
      // This is called when the outer Observable (ApiObservable) is subscribed to.
      // We subscribe to the inner and route the results to the outer subscription
      const sub = src.subscribe({
        next: (value) => subscriber.next(value),
        error: (err) => subscriber.error(err),
        complete: () => subscriber.complete()
      });

      // Return the handler called when the subscription is unsubscribed
      return () => {
        // unsubscribe from the inner Observable
        sub.unsubscribe();
      };
    };
    super(onSubscribed)
  }

  override subscribe(observer?: PartialObserver<any>): Subscription;
  /** @deprecated Use an observer instead of a complete callback */
  override subscribe(next: null | undefined, error: null | undefined, complete: () => void): Subscription;
  /** @deprecated Use an observer instead of an error callback */
  override subscribe(next: null | undefined, error: (error: any) => void, complete?: () => void): Subscription;
  /** @deprecated Use an observer instead of a complete callback */
  override subscribe<T>(next: (value: T) => void, error: null | undefined, complete: () => void): Subscription;
  override subscribe<T>(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Subscription;
  override subscribe(nextOrObserver?: any, error?: any, complete?: any): Subscription {

    // In our application a network call goes through the following RxJS observable layers
    //   httpClient -> response interceptor -> apiService -> ApiObservable -> [CacheApiResponse] -> caller
    // Everything left of here (ApiObservable) has a pipe operation which means that the nextOrObserver
    // should always be a subscriber. Some methods COULD be marked with @CacheApiResponse() which will wrap
    // that method in another function that attempts to manage a cache for that call. CacheApiResponse should
    // also return an instance of ApiObservable.
    // Ultimately, the goal is for any API callers in the application to subscribe to an ApiObservable and NOT
    // a plain observable. We WANT the following code to apply a "safety net" to calls that are not configured
    // to handle errors.

    if (typeof nextOrObserver === 'object') {
      // If the first argument is an object, we assume it's an observer of some kind: either a full blown instance
      // of Subscriber or a partial observer (like a plain JSON object with next, error, and/or complete properties).
      // Lets ensure it has an error handler
      nextOrObserver.error = nextOrObserver.error ?? this.setupSafetyNet().bind(this);
      return super.subscribe(nextOrObserver);
    }
    else if (typeof nextOrObserver === 'function') {
      // If the error argument is null/undefined, we need to ensure it has an error handler. Upgrade to observer.
      return super.subscribe({ next: nextOrObserver, error: error ?? this.setupSafetyNet().bind(this), complete });
    } else {
      Sentry.captureMessage(`Subscribe called with no handlers in ${this.logContext?.reqMethod}, ${this.logContext?.url}`, {
        level: 'error' as Sentry.SeverityLevel,
      });
      return super.subscribe({
        error: this.setupSafetyNet().bind(this)
      });
    }
  }

  // see also api.utils.ts factory
  private setupSafetyNet() {
    const { reqMethod, url } = this.logContext ?? { reqMethod: '<unknown>', url: '<unknown url>' };
    return ApiErrorHandlerFactory.getSafetyNet(reqMethod, url);
  }
}

@Injectable()
export class ApiService {

  constructor(private http: HttpClient, private spinnerService: IOPSpinnerService) { }

  get<T>(url: string, options?: any, serializer?: ISerializer<T>, spinnerKey?: string): Observable<T> {
    return new ApiObservable(
      this.handleResponse<T>(
        this.http.get<T>(url, options) as any as Observable<T>,
        serializer,
        spinnerKey
      ),
      { reqMethod: 'GET', url: this.cleanUrl(url) }
    );
  }

  post<T>(url: string, body?: any | null, options?: any, serializer?: ISerializer<T>, spinnerKey?: string): Observable<T> {
    return new ApiObservable(
      this.handleResponse<T>(
        this.http.post<T>(url, body, options) as any as Observable<T>,
        serializer,
        spinnerKey
      ),
      { reqMethod: 'POST', url: this.cleanUrl(url) }
    );
  }

  put<T>(url: string, body?: any | null, options?: any, serializer?: ISerializer<T>, spinnerKey?: string): Observable<T> {
    return new ApiObservable(
      this.handleResponse<T>(
        this.http.put<T>(url, body, options) as any as Observable<T>,
        serializer,
        spinnerKey
      ),
      { reqMethod: 'PUT', url: this.cleanUrl(url) }
    );
  }

  /**
   * For use with JSON Patch endpoints
   */
  patch<T>(url: string, body?: any | null, options?: any, serializer?: ISerializer<T>, spinnerKey?: string): Observable<T> {
    return new ApiObservable(
      this.handleResponse<T>(
        this.http.patch<T>(url, body, options) as any as Observable<T>,
        serializer,
        spinnerKey
      ),
      { reqMethod: 'PATCH', url: this.cleanUrl(url) }
    );
  }

  delete<T>(url: string, options?: any, serializer?: ISerializer<T>, spinnerKey?: string): Observable<T> {
    return new ApiObservable(
      this.handleResponse<T>(
        this.http.delete<T>(url, options) as any as Observable<T>,
        serializer,
        spinnerKey
      ),
      { reqMethod: 'DELETE', url: this.cleanUrl(url) }
    );
  }

  private handleResponse<T>(observable: Observable<T>,
    serializer?: ISerializer<T>,
    spinnerKey?: string): Observable<T> {
    const spinnerState = this.spinnerService.getSpinnerState(spinnerKey);
    this.spinnerService.spin(spinnerState);
    return observable.pipe(
      map(response => {
        if (!response) return null;
        return serializer ? serializer.serialize(response) : response;
      }),
      catchError((error: HttpErrorResponse & IApiErrorResponse) => {
        // reconstruct the object just in case the interceptor didn't do it
        const customError: IApiErrorResponse = {
          code: error.code || error.status,
          errorCode: error.errorCode || error.error,
          message: error.message || error.error?.message || 'Unknown error',
          statusCode: error.statusCode || error.status,
          url: error.url
        };

        return observableThrowError(customError);
      }),
      finalize(() => {
        this.spinnerService.stop(spinnerState);
      })
    );
  }

  private cleanUrl(url: string): string {
    if (!url) return '';
    try {
      let path = new URL(url).pathname;
      path = path.replaceAll(/\/(\d+)\//g, '/{id}/');
      return `'${path}'`;
    } catch {
      return '<invalid url>';
    }
  }

}
