import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LoginService {
  
  constructor(private http: HttpClient) { }

  getBackgroundImages() {
    return this.http.get('https://s3.amazonaws.com/img.observepoint.com/app-login/app-login.json');
  }

}