import { RouteHistoryService } from '@app/components/shared/services/route-history.service';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';

import {
  name as UPMC_NAME,
  UserPresenceModalController,
} from './manual-journey/modals/userPresence/userPresenceModalController';
import {
  name as MJEMC_NAME,
  ManualJourneyExportModalController,
} from './manual-journey/modals/export/manualJourneyExportModalController';
import {
  ManualJourneyRecorderController,
} from './manual-journey/manualJourneyRecorderController';

import {
  name as DPS_NAME,
  DeviceProfileService
} from '../../live-connect/device-profile.service';
import { Names } from '@app/moonbeamConstants';
import { manualJourneyRecorderDirective } from './manual-journey/manualJourneyRecorderDirective';

export default angular
  .module('liveConnectCreate', [])
  .controller(UPMC_NAME, UserPresenceModalController)
  .controller(MJEMC_NAME, ManualJourneyExportModalController)
  .controller(Names.Controllers.manualJourneyRecorder, ManualJourneyRecorderController)
  .directive(Names.Directives.manualJourneyRecorder, manualJourneyRecorderDirective)
  .service(DPS_NAME, downgradeInjectable(DeviceProfileService))
  .service(Names.NgServices.routerHistory, downgradeInjectable(RouteHistoryService))
  .name;
