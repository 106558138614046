import { Injectable } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { Observable } from 'rxjs';
import {
  IBrowserConsoleLogsSummary,
  IBrowserConsoleLogsRunOverviews,
  IBrowserConsoleLogsTrends,
  IBrowserLogsResponse,
  IBrowserLogsPagesResponse, IBrowserLogsSummaryPagination, IMessagesTableState, IPagesTableState,
} from '@app/components/audit-reports/reports/browser-console-logs/browser-console-logs.models';
import { BrowserConsoleLogsTrendName } from '@app/components/audit-reports/reports/browser-console-logs/browser-console-logs.constants';

@Injectable()
export class BrowserConsoleLogsService extends AuditReportBaseService {

  constructor(
    private apiService: ApiService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);
  }

  getBrowserLogsSummary(auditId: number, runId: number, body?: IAuditReportApiPostBody): Observable<IBrowserConsoleLogsSummary> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/browser-logs`, body);
  }

  getBrowserConsoleLogsTrends(auditId: number, runId: number, filters?: IAuditReportApiPostBody): Observable<IBrowserConsoleLogsRunOverviews> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/runs/${runId}/reports/browser-logs/trends`, filters);
  }

  getBrowserLogsTrend(auditId: number, trendName: BrowserConsoleLogsTrendName, days: number): Observable<IBrowserConsoleLogsTrends> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/reports/browser-logs/trends/${trendName}?days=${days}`);
  }

  getTopMessages(auditId: number, runId: number, pagination: IMessagesTableState, filters?: IAuditReportApiPostBody): Observable<IBrowserLogsResponse> {
    const queryParams = `?page=${ pagination.pagination.page }&size=${ pagination.pagination.size }&sortBy=${ pagination.sort.sortBy }&sortDesc=${ pagination.sort.sortDesc }`;
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/browser-logs/messages${queryParams}`, filters);
  }
  
  getPages(auditId: number, runId: number, pagination: IPagesTableState, apiFilters?: IAuditReportApiPostBody & {message?: { message?: string, level?: string }, tagId?: number}): Observable<IBrowserLogsPagesResponse> {
    const queryParams = `?page=${ pagination.pagination.page }&size=${ pagination.pagination.size }&sortBy=${ pagination.sort.sortBy }&sortDesc=${ pagination.sort.sortDesc }`;
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/browser-logs/pages${queryParams}`, apiFilters);
  }
}
