import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { Events } from '@app/moonbeamConstants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Validators } from '@angular/forms';
import { ManageCardsService } from '@app/components/manage/cards/manage-cards.service';
import { CardTypes } from '@app/components/manage/cards/report-card-list/report-card-list.constants';
import { MAX_PAGE_SCAN_LIMIT } from '@app/components/audit/audit.constants';
import { BulkActionProgressComponent } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-audit-scan-limits',
  templateUrl: './manage-audit-scan-limits.component.html',
  styleUrls: ['./manage-audit-scan-limits.component.scss']
})
export class ManageAuditScanLimitsComponent implements OnInit {
  scanLimitForm = new FormGroup({
    scanLimit: new FormControl(null, [
    Validators.required,
    Validators.pattern('^[0-9]{1,6}$'), // only numbers
    Validators.min(1),
    Validators.max(MAX_PAGE_SCAN_LIMIT),
    Validators.minLength(1),
  ]),
    name: new FormControl(''),
  });
  processCompleted = false;
  sourceNames: string;

  @Input() selectedCards: any[] = [];
  @Input() selectedCardsCount: number = 0;
  maxScanLimit = MAX_PAGE_SCAN_LIMIT.toLocaleString("en-US");
  error = false;
  snackBarDuration: number = 4000;

  rightFooterButtons = [
    {
      label: 'Save',
      action: () => {
        this.setScanLimits();
      },
      primary: true
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<ManageAuditScanLimitsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private eventManager: IEventManager,
    private manageCardsService: ManageCardsService,
  ) {
    this.selectedCardsCount = this.data.selectedCardsCount;
    this.selectedCards = this.data.selectedCards;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    //Filter to just audits
    if(Array.isArray(this.selectedCards)) {
      const filteredSelectedCard = this.selectedCards.filter( card =>
        ( card.type === CardTypes.audit ));
      this.selectedCards = filteredSelectedCard;
    }
  }

  /////////////////////////////////////////////////////
  // Bulk method: Set Page Scan Limit
  /////////////////////////////////////////////////////
  setScanLimitConfirmation(): void {
    this.sourceNames = undefined;

    if(this.selectedCards.length < 1) {
      this.snackBar.open("No selected items are audits.", "", {
        duration: this.snackBarDuration,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
      this.closeModal();
      return;
    }

    // Setting limits is started
    this.showProgressDlg("are now set.")
    this.manageCardsService.setAuditScanLimits(this.selectedCards, +this.scanLimit.value);
    this.closeModal();  // close the first modal immediately so there is only on dialog showing
  }
  showProgressDlg(msgSegment: string): boolean {

    this.processCompleted = false;
    let formattedMessage: string = `Selected data sources ${msgSegment}`;
    let formattedMessage2: string =
      "Don't close this banner, or leave this page until finished or remaining data sources won't be completed.";

    const confirmConfig = {
      maxCount: this.selectedCards.length,
      messages: [
        formattedMessage,
        formattedMessage2,
      ],
      showSecondBtn: false,
      showProgressBar: true,
      rightFooterButtons: [
        {
          label: "Yes, set them",
          opSelector: "bulk-confirm-pause-yes",
          hidden: false,
          primary: false,
        },
        {
          label: "Cancel",
          opSelector: "bulk-confirm-pause-cancel",
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionProgressComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
    .subscribe((observer?: MatSnackBarDismiss) => {
      if(observer.dismissedByAction) {
        // Abort process
        this.processCompleted = true;
        return false;
      } else {
        // Process is completed and now we close the dialog
        setTimeout(() => {
          this.processCompleted = true;
          this.closeModal();
        }, 50);
      }
    });

    return true;
  }
  setScanLimits() {

    this.scanLimitForm.markAllAsTouched();
    if(this.scanLimitForm.invalid) {
      return;
    }

    this.setScanLimitConfirmation();
  }
  get scanLimit() { return this.scanLimitForm.get('scanLimit') as FormControl; }

  deselectAllItems(): void {
    this.eventManager.publish(Events.deselectAllCards);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
