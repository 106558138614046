<div class="section-title-wrap">
  <div class="title-spinner-wrap">
    <filter-spinner [state]="tableState"></filter-spinner>
    <p class="title">Cookies</p>
    <op-audit-report-export-menu
      class="audit-report-export-menu"
      [auditId]="auditId"
      [runId]="runId"
      [tableCompletedState]="tableState"
      [config]="exportReportConfig">
    </op-audit-report-export-menu>
  </div>

  <op-switchable-column-menu [storageKey]="CookiesListTableColumnsStorageKey"
                             [columnConfig]="CookiesTableColumns"></op-switchable-column-menu>
</div>

<div class="cookies-wrap">
  <div #scrollTop></div>
  <table
    mat-table
    matSort
    class="cookies-list"
    matSortStart="desc"
    multiTemplateDataRows
    [matSortActive]="sorting.sortBy"
    [matSortDirection]="sorting.sortDesc"
    [dataSource]="dataSource"
    [class.updating]="tableState === 'loading'"
    [class.is-filtered]="isFiltered"
    [class.large-layout]="largeLayout"
    [class.medium-layout]="mediumLayout"
    [class.small-layout]="smallLayout"
    [class.very-small-layout]="verySmallLayout"
    (matSortChange)="onSort.emit($event)"
  >
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let cookie">
        <div class="icon-wrap">
          <mat-icon class="expand-arrow" [class.expanded]="cookie === expandedCookie">
            keyboard_arrow_right
          </mat-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell
          mat-sort-header
          [start]="'asc'"
          *matHeaderCellDef
      >COOKIE NAME</th>
      <td mat-cell
          class="name-cell"
          *matCellDef="let cookie"
      >
        <div class="cell-wrap">
          <p [matTooltip]="cookie.name"
             matTooltipPosition="above"
             matTooltipShowDelay="250"
             class="name-cell-text"
          >{{cookie.name}}</p>
          <div class="actions">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="filterReport(cookie.name, ECookieInventoryFilterType.Name)"
              class="tag-menu-item"
            >
              <mat-icon>tune</mat-icon>
              Filter this report by this item
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="domain">
      <th mat-header-cell
          mat-sort-header
          [start]="'asc'"
          *matHeaderCellDef>DOMAIN</th>
      <td mat-cell
          *matCellDef="let cookie"
          class="domain-cell"
          [matTooltip]="cookie.domain"
          matTooltipPosition="above"
          matTooltipShowDelay="250">
        <div class="cell-wrap">
          <p class="name-cell-text"
          >{{cookie.domain}}</p>
          <div class="actions">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="filterReport(cookie.domain, ECookieInventoryFilterType.Domain)"
              class="tag-menu-item"
            >
              <mat-icon>tune</mat-icon>
              Filter this report by this item
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="unique_initiator_count">
      <th mat-header-cell  mat-sort-header *matHeaderCellDef>INITIATOR(S)</th>
      <td mat-cell
          *matCellDef="let cookie"
          class="initiators">
        <ng-container *ngIf="cookie.uniqueTagsCount">

          <span [matTooltip]="cookie.firstTagName + (cookie.uniqueTagsCount > 1 ? (' and ' + (cookie.uniqueTagsCount - 1) + ' other ' + (cookie.uniqueTagsCount > 2 ? 'tags' : 'tag')) : '') + ' (' + cookie.initiatorTotalCount + ' total initiators)'"
                matTooltipPosition="above">
              {{cookie.initiatorTotalCount}}
              <ng-container *ngFor="let initiator of cookie.uniqueTagsList; let i = index">
                <img *ngIf="i < 3" [src]="initiator.iconUrl" [attr.alt]="initiator.tagName" [class.smaller]="!initiator.tagVendorId" />
              </ng-container>
          </span>

        </ng-container>

        <ng-container *ngIf="!cookie.uniqueTagsCount">


          <ng-container *ngIf="cookie.initiatorTotalCount; else noInitiators">

            <ng-container *ngIf="cookie.uniqueTagsList.length; else noKnownInitiators">
              <span [matTooltip]="cookie.initiatorTotalCount + ' total initiators'"
                    matTooltipPosition="above">
                {{cookie.initiatorTotalCount}}
                <ng-container *ngFor="let initiator of cookie.uniqueTagsList; let i = index">
                  <img *ngIf="i < 3" [src]="initiator.iconUrl" [attr.alt]="initiator.tagName" [class.smaller]="!initiator.tagVendorId" />
                </ng-container>
              </span>
            </ng-container>

            <ng-template #noKnownInitiators>
              <ng-container *ngIf="cookie.initiatorTotalCount > 1; else emptyInitiators">{{cookie.initiatorTotalCount}}</ng-container>
              <ng-template #emptyInitiators>
              <span *ngIf="isThisSingleInitiatorUnknown(cookie); else singleInitiatorIsKnown"
                    matTooltip="We were unable to detect how this cookie was created or modified. This can sometimes mean the cookie was set via shadow DOM or loaded from an iFrame."
                    matTooltipPosition="above">---</span>
                <ng-template #singleInitiatorIsKnown>1</ng-template>
              </ng-template>
            </ng-template>

          </ng-container>


          <ng-template #noInitiators>{{cookie.initiatorTotalCount === undefined ? '-' : 0}}</ng-template>


        </ng-container>

      </td>
    </ng-container>

    <ng-container matColumnDef="party_type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">
        <div class="cell-header">
          <div class="cell-header-nowrap">1ST/3RD</div>
          <div>PARTY</div>
        </div>
      </th>
      <td mat-cell *matCellDef="let cookie" class="center-text">
        <div class="cell-wrap cell-wrap-centered">
          {{cookie.partyType}}
          <div class="actions">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu="matMenu">
            <button mat-menu-item class="tag-menu-item"
                    (click)="filterReport(cookie.partyType, ECookieInventoryFilterType.Party)">
              <mat-icon>tune</mat-icon>
              Filter this report by this item
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="net_duration">
      <th mat-header-cell mat-sort-header="min_duration_in_minutes" *matHeaderCellDef class="center-text">DURATION</th>
      <td mat-cell *matCellDef="let cookie" class="mat-column-min_duration">
        <ng-container *ngIf="cookie.expirationType !== 'session' else durationTypeSession">{{cookie.netDurationInMinutes}}</ng-container>
        <ng-template #durationTypeSession><span>Session</span></ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="partition_key">
      <th mat-header-cell
          mat-sort-header
          [start]="'asc'"
          *matHeaderCellDef class="center-text">PARTITION KEY</th>
      <td mat-cell *matCellDef="let cookie" class="mat-column-partition-key">
        <div class="cell-wrap cell-wrap-centered">
          {{ cookie.partitionKey || '---' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="secure">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">SECURE</th>
      <td mat-cell *matCellDef="let cookie" class="center-text small-cell">
        <div class="cell-wrap cell-wrap-centered">
          {{cookie.secure}}
          <div class="actions">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu="matMenu">
            <button mat-menu-item class="tag-menu-item"
                    (click)="filterReport(cookie.secure, ECookieInventoryFilterType.Secure)">
              <mat-icon>tune</mat-icon>
              Filter this report by this item
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="same_site">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">SAMESITE</th>
      <td mat-cell *matCellDef="let cookie" class="center-text small-cell">
        <div class="cell-wrap cell-wrap-centered">
          {{cookie.sameSite}}
          <div class="actions">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu="matMenu">
            <button mat-menu-item class="tag-menu-item"
                    (click)="filterReport(cookie.sameSite, ECookieInventoryFilterType.SameSite)">
              <mat-icon>tune</mat-icon>
              Filter this report by this item
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="http_only">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text small-cell">HTTPONLY</th>
      <td mat-cell *matCellDef="let cookie" class="center-text small-cell">
        <div class="cell-wrap cell-wrap-centered">
          {{cookie.httpOnly}}
          <div class="actions">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu="matMenu">
            <button mat-menu-item class="tag-menu-item"
                    (click)="filterReport(cookie.httpOnly, ECookieInventoryFilterType.HttpOnly)">
              <mat-icon>tune</mat-icon>
              Filter this report by this item
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="avg_size">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">
        <div class="cell-header">
          <div class="cell-header-nowrap">AVG SIZE</div>
          <div>(BYTES)</div>
        </div>
      </th>
      <td mat-cell *matCellDef="let cookie" class="center-text">{{ cookie.averageSize }}</td>
    </ng-container>

    <ng-container matColumnDef="cookie_changed_during">
      <th mat-header-cell
          mat-sort-header
          [start]="'asc'"
          *matHeaderCellDef
          class="center-text">
        ORIGINS
      </th>
      <td mat-cell *matCellDef="let cookie" class="center-text">
        <div class="cookie-origins"
             [matTooltip]="cookie.origin.tooltip"
             matTooltipPosition="above">
          {{ cookie.origin.text }}
          <mat-icon class="cookie-origins-icon"
                    *ngIf="cookie.origin.icon">
            {{ cookie.origin.icon }}
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="page_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">
        <div class="cell-header">
          <div class="cell-header-nowrap">PRESENT ON</div>
          <div># PAGES</div>
        </div>
      </th>
      <td mat-cell *matCellDef="let cookie" class="center-text" (click)="filterByPage($event, cookie)">
        <ng-container>
          <div class="chart-wrapper" [class.selected]="isLineSelected(cookie)">
            <horizontal-bar-chart [data]="cookie.onPages.chartData"
                                  [state]="cookie.barChartSettings.state"
                                  [uniqueIdentifier]="cookie.onPages.uniqueIdentifier"
                                  [displayPills]="cookie.barChartSettings.displayPills"
                                  [calcAsPercentage]="cookie.barChartSettings.calcAsPercentage"
                                  [displayPercentSymbol]="cookie.barChartSettings.displayPercentSymbol"
                                  [textPosition]="cookie.barChartSettings.textPosition"
                                  [barDirection]="cookie.onPages.barDirection"
            ></horizontal-bar-chart>
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let cookie"
          [attr.colspan]="(displayedColumns$ | async)?.length"
          class="cookie-detail-row">
        <div class="cookie-detail" [@detailExpand]="cookie === expandedCookie ? 'expanded' : 'collapsed'">
          <div class="cookie-detail-wrap">
            <div class="column column-1">
              <p class="detail-label">Name: <span class="clickable detail-value"
                                                  (click)="filterBarService.addCookieNameFilter(cookie.name)"
                                                  matTooltip="Filter to view all pages with this Cookie Name"
                                                  matTooltipPosition="above">
              {{ cookie.name }}</span>
              </p>
              <p class="detail-label">Domain: <span class="clickable detail-value"
                                                    (click)="filterBarService.addCookieDomainFilter(true, cookie.domain)"
                                                    matTooltip="Filter to view all pages with this Cookie Domain"
                                                    matTooltipPosition="above">
              {{ cookie.domain }}</span>
              </p>
              <p class="detail-label">1ST/3RD Party: <span class="clickable detail-value"
                                                           (click)="filterBarService.addCookiePartyFilter(ECookiePartyTypeConverter[cookie.partyType])"
                                                           matTooltip="Filter to view all pages with this Cookie Party Type"
                                                           matTooltipPosition="above">
              {{ cookie.partyType }}</span>
              </p>
              <p class="detail-label">Expiration Type: <span class="clickable detail-value"
                                                             (click)="filterBarService.addCookieExpirationTypeFilter(cookie.expirationType)"
                                                             matTooltip="Filter to view all pages with this Cookie Expiration Type"
                                                             matTooltipPosition="above">
              {{ cookie.expirationType }}</span>
              </p>
            </div>
            <div class="column column-2">
              <p class="detail-label">Secure: <span class="clickable detail-value"
                                                    (click)="filterBarService.addCookieSecureFilter(cookie.secure)"
                                                    matTooltip="Filter to view all pages with this Cookie Secure"
                                                    matTooltipPosition="above">
              {{ cookie.secure }}</span>
              </p>
              <p class="detail-label">HttpOnly: <span class="clickable detail-value"
                                                      (click)="filterBarService.addCookieHttpOnlyFilter(cookie.httpOnly)"
                                                      matTooltip="Filter to view all pages with this Cookie HttpOnly"
                                                      matTooltipPosition="above">
              {{ cookie.httpOnly }}</span>
              </p>
              <p class="detail-label">SameSite: <span class="clickable detail-value"
                                                      (click)="addCookieSameSiteFilter(cookie.sameSite)"
                                                      matTooltip="Filter to view all pages with this Cookie SameSite"
                                                      matTooltipPosition="above">
              {{ cookie.sameSite }}</span>
              </p>
              <p class="detail-label">Avg Size(Bytes): <span class="detail-value">{{ cookie.averageSize }}</span></p>
            </div>
            <div class="column column-3">
              <p class="detail-label">Present on # Pages: <span class="detail-value">{{ cookie.filteredPageCount }}</span>
              </p>
              <p class="detail-label">Duration MIN: <span class="detail-value">{{ cookie.minDurationInMinutes }}</span>
              </p>
              <p class="detail-label">Duration MAX: <span class="detail-value">{{ cookie.maxDurationInMinutes }}</span>
              </p>
              <p class="detail-label">Initiator(s): <span
                class="detail-value">{{ cookie.initiatorTotalCount === undefined ? '-' : cookie.initiatorTotalCount }}</span>
              </p>
            </div>
          </div>
          <table class="initiators-table">
            <thead>
            <tr>
              <th>INITIATOR</th>
              <th>INSTANCES</th>
              <th>DOMAIN</th>
              <th>TAG CATEGORY</th>
              <th>TAG VENDOR</th>
              <th>SET METHOD</th>
              <th>ORIGINS</th>
            </tr>
            </thead>
            <tbody>
            <!-- Repeat this <tr> for each initiator in your dataset -->
            <tr *ngFor="let initiator of cookie.initiators">
              <td [class.clickable]="initiator.tagId"
                  (click)="initiator.tagId && addTagIdFilter(initiator.tagName, initiator.tagId)"
                  matTooltip="Filter to view all pages with this Tag Name"
                  matTooltipPosition="above"
                  [matTooltipDisabled]="!initiator.tagId || initiator.initiatorType === 'UNKNOWN'">
                <div>
                  <ng-container *ngIf="initiator.initiatorType !== 'UNKNOWN'; else unknownInitiator">
                    <div *ngIf="initiator.tagId" class="flex-row">
                      <img [src]="initiator.tagImageUrl" [alt]="initiator.tagId" />
                      <div>{{initiator.tagName}}</div>
                    </div>
                    <div class="flex-row" *ngIf="!initiator.tagId">
                      <img *ngIf="initiator.initiatorType === 'HTTP'" class="smaller" src="/images/http-logo.png" alt="HTTP" />
                      <img *ngIf="initiator.initiatorType !== 'HTTP'" class="smaller" src="/images/js-logo.png" alt="JavaScript" />
                      <div>{{initiator.initiatorType === 'HTTP' ? 'HTTP' : 'JavaScript'}}</div>
                    </div>
                  </ng-container>
                  <ng-template #unknownInitiator>
                    <div class="flex-row">
                      <span>
                        Unknown
                      </span>
                      <mat-icon
                        class="info-tooltip-icon"
                        matTooltip="We were unable to detect how this cookie was created or modified.
                                    This can sometimes mean the cookie was set via shadow DOM or loaded from an iFrame."
                        matTooltipPosition="above"
                      >
                        info_outline
                      </mat-icon>
                    </div>
                  </ng-template>
                </div>
              </td>
              <td>
                <div class="instances">
                  {{initiator.instanceCount}}
                </div>
              </td>
              <td (click)="initiator.urlDomain && filterBarService.addCookieInitiatorDomainFilter(initiator.urlDomain)"
                  [class.clickable]="initiator.urlDomain"
                  [matTooltipDisabled]="!initiator.urlDomain"
                  matTooltip="Filter to view all pages with this Initiator Domain"
                  matTooltipPosition="above">
                <ng-container *ngIf="initiator.urlDomain">
                  <span>{{ initiator.urlDomain }}</span>
                </ng-container>
                <ng-container *ngIf="!initiator.urlDomain">
                  ---
                </ng-container>
              </td>
              <td (click)="initiator.tagCategory.id && addTagCategoryFilter(initiator.tagCategory.category, initiator.tagCategory.id)"
                  [class.clickable]="initiator.tagCategory.id"
                  [matTooltipDisabled]="!initiator.tagCategory.id"
                  matTooltip="Filter to view all pages with this Tag Category"
                  matTooltipPosition="above">
                <ng-container *ngIf="initiator.tagCategory.id">
                  <span>
                    {{ initiator.tagCategory.category }}
                  </span>
                </ng-container>
                <ng-container *ngIf="!initiator.tagCategory.id">
                  ---
                </ng-container>
              </td>
              <td (click)="initiator.tagVendor.id && addTagVendorFilter(initiator.tagVendor.name, initiator.tagVendor.id)"
                  matTooltip="Filter to view all pages with this Tag Vendor"
                  matTooltipPosition="above"
                  [class.clickable]="initiator.tagVendor.id"
                  [matTooltipDisabled]="!initiator.tagVendor.id">
                <ng-container *ngIf="initiator.tagVendor.id">
                  <span>
                    {{ initiator.tagVendor.name }}
                  </span>
                </ng-container>
                <ng-container *ngIf="!initiator.tagVendor.id">
                  ---
                </ng-container>
              </td>
              <td>
                <div class="set-method flex-row">
                  <ng-container *ngIf="initiator.initiatorType">
                    <span>{{ initiator.setMethodLabel }}</span>
                    <mat-icon
                      class="info-tooltip-icon"
                      [matTooltip]="initiator.setMethodTooltip"
                      matTooltipPosition="above"
                    >
                      info_outline
                    </mat-icon>
                  </ng-container>
                  <ng-container *ngIf="!initiator.initiatorType">
                    ---
                  </ng-container>
                </div>
              </td>
              <td>
                {{ initiator.origin.text }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </td>
    </ng-container>

    <tr mat-header-row class="header" *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr
      mat-row
      *matRowDef="let cookie; columns: displayedColumns$ | async;"
      (click)="expandCookiePage(cookie, $event)"
      class="cookie-row"
      [class.expanded-row]="expandedCookie === cookie"
      [class.row-selected]="isLineSelected(cookie)"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
</div>

<mat-paginator
  class="paginator"
  [length]="paginationState.length"
  [pageSize]="paginationState.pageSize"
  [hidePageSize]="true"
></mat-paginator>
