<div class="rule-summary-trends-header">
  <span matTooltip="{{tooltipText}}"
        matTooltipShowDelay="500"
        matTooltipPosition="above"
  >{{ headerText }} &nbsp;</span>
  <filter-spinner [state]="widgetState"></filter-spinner>
</div>
<div class="rule-summary-trends-cards">
  <op-summary-card
    headerText="Rules Failed"
    [noDataLabel]="!totalRules ? 'no rules applied this run' : null"
    [ngClass]="{ 'dimmed': filters.cards && filters.cards !== 'failed' }"
    [filteredValue]="trendData.failedRulesCount"
    [valueColorHighlight]="trendData.failedRulesCount > 0 ? colorEnum.NEGATIVE : colorEnum.NONE"
    (cardClicked)="summaryCardClicked.emit('failed')"
    [widgetState]="widgetState"
    [metricType]="alertMetricTypes?.failedRuleMetricType"
    [auditId]="auditId"
    [runId]="runId"
    [filters]="filterBarFilters"
    [highlight]="highlightMetricType === alertMetricTypes?.failedRuleMetricType"
    [disableHighlightFromClick]="true"
    class="rule-summary-failed-rules"
  ></op-summary-card>
  <op-summary-card
    headerText="Rules Passed"
    [noDataLabel]="!totalRules ? 'no rules applied this run' : null"
    [ngClass]="{ 'dimmed': filters.cards && filters.cards !== 'passed' }"
    [filteredValue]="trendData.passedRulesCount"
    [valueColorHighlight]="trendData.passedRulesCount > 0 ? colorEnum.POSITIVE : colorEnum.NEUTRAL"
    (cardClicked)="summaryCardClicked.emit('passed')"
    [widgetState]="widgetState"
    [metricType]="alertMetricTypes?.passedRuleMetricType"
    [auditId]="auditId"
    [runId]="runId"
    [currentValue]="trendData?.passedRulesCount"
    [filters]="filterBarFilters"
    [highlight]="highlightMetricType === alertMetricTypes?.passedRuleMetricType"
    [disableHighlightFromClick]="true"
    class="rule-summary-passed-rules"
  ></op-summary-card>
  <op-summary-card
    headerText="Rules Not Applied"
    [noDataLabel]="!totalRules ? 'no rules applied this run' : null"
    [ngClass]="{ 'dimmed': filters.cards && filters.cards !== 'notApplied' }"
    [filteredValue]="trendData.notAppliedRulesCount"
    [valueColorHighlight]="trendData.notAppliedRulesCount > 0 ? colorEnum.SORTOFPOSITIVE : colorEnum.NEUTRAL"
    (cardClicked)="summaryCardClicked.emit('notApplied')"
    [widgetState]="widgetState"
    [metricType]="alertMetricTypes?.notAppliedRuleMetricType"
    [auditId]="auditId"
    [runId]="runId"
    [filters]="filterBarFilters"
    [highlight]="highlightMetricType === alertMetricTypes?.notAppliedRuleMetricType"
    [disableHighlightFromClick]="true"
    class="rule-summary-not-applied-rules"
  ></op-summary-card>
</div>
<div>
  <ng-container *ngIf="isEnoughData else notEnoughData">
    <div class="history-container"
         [class.no-data-for-chart]="this.totalRules === 0"
         (click)="handleHistoryClicked()"
    >
      <div class="chart-container">
        <vertical-stacked-bar-chart *ngIf="chartData"
                                    [marginTop]="20"
                                    [marginLeft]="20"
                                    [marginRight]="20"
                                    [marginBottom]="20"
                                    [inputData]="chartData"
                                    [drawXAxis]="false"
                                    [drawYAxis]="false"
                                    [drawBarSegmentLabels]="false"
                                    [uniqueIdentifier]="chartId">
        </vertical-stacked-bar-chart>
      </div>
      <div class="trends-container">
        <trend-indicator
          [class.dimmed]="filters.cards && filters.cards !== 'failed'"
          label="FAILURES"
          zeroLabel="No Change"
          [value]="trendData.failedRulesCountDiffWithPrev"
          [colorHighlight]="failedRulesStatus"
          [renderWhenValueIs0]="true"
        ></trend-indicator>
        <trend-indicator
          [class.dimmed]="filters.cards && filters.cards !== 'passed'"
          label="PASSING"
          zeroLabel="No Change"
          [value]="trendData.passedRulesCountDiffWithPrev"
          [colorHighlight]="passedRulesStatus"
          [renderWhenValueIs0]="true"
        ></trend-indicator>
        <trend-indicator
          [class.dimmed]="filters.cards && filters.cards !== 'notApplied'"
          label="NOT APPLIED"
          zeroLabel="No Change"
          [value]="trendData.notAppliedRulesCountDiffWithPrev"
          [renderWhenValueIs0]="true"
        ></trend-indicator>
      </div>
      <div class="open-fullscreen-icon-container">
        <div class="square">
          <mat-icon class="open-fullscreen-icon">open_in_full</mat-icon>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #notEnoughData>
    <p class="not-enough-data">initial run, more than one run is needed for trending graphs</p>
  </ng-template>
</div>
