import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  CookieFilterVariables,
  CookieInventoryCookie,
  CookieInventoryCookieRow,
  CookieInventoryCookies,
  CookieInventoryPagesTablePagination,
} from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  EBarChartDirection,
  EBarChartTextPosition,
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.constants';
import { EChartColor } from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  ECookieInventoryFilterType,
  ECookiePartyTypeConverter,
  party,
} from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.constants';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { WindowRef } from '@app/components/core/services/window.service';
import { SidebarService } from '@app/components/navigation/sidebar/sidebar.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { JsFileChangesReportUtils } from '@app/components/utilities/js-file-changes-report.utils';
import {
  IAuditReportExportMenuData,
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import {
  AuditReportFilterBarService,
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import {
  ECookieSameSiteTypes,
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { ECookieInitiatorTypeSetMethod } from '@app/components/audit-reports/page-details/page-details.constants';
import { ECookieInitiatorType } from '@app/components/audit-reports/page-details/page-details.enums';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  CookiesListTableColumnsStorageKey,
  CookiesTableColumns,
} from '@app/components/audit-reports/reports/cookie-inventory/components/cookies-list/cookies-list.constants';
import {
  getCookieOriginData,
  getTagInitiatorOriginData,
} from '@app/components/audit-reports/reports/general-reports.utils';

@Component({
  selector: 'op-cookies-list',
  templateUrl: './cookies-list.component.html',
  styleUrls: ['./cookies-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ResizeableTableService],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ]
})
export class CookiesListComponent implements OnChanges, AfterViewInit {
  readonly ECookiePartyTypeConverter = ECookiePartyTypeConverter;
  readonly CookiesListTableColumnsStorageKey = CookiesListTableColumnsStorageKey;
  readonly CookiesTableColumns = CookiesTableColumns;
  ECookieInventoryFilterType = ECookieInventoryFilterType;

  @ViewChild('mainElement') mainElement: ElementRef<HTMLDivElement>;
  @ViewChild('secondElement') secondElement: ElementRef<HTMLDivElement>;

  @Input() set items(data: CookieInventoryCookies) {
    if (data) {
      this.setPagination(data.metadata);
      this.scrollToTop();
      setTimeout(async () => this.dataSource.data = await this.prepareItems(data.cookies));
    }
  }

  @Input() set defaultSorting(data: CookieInventoryPagesTablePagination) {
    if (data) {
      this.sorting.sortBy = data.sortBy;
      this.sorting.sortDesc = data.sortDesc ? 'desc' : 'asc';
    }
  }

  @Input() auditId: number;
  @Input() runId: number;
  @Input() tableState: EFilterSpinnerState;
  @Input() filteredPageCount: number = 0;
  @Input() apiFilters: IAuditReportApiPostBody = {};
  @Input() exportReportConfig: IAuditReportExportMenuData;

  @Output() localFilter = new EventEmitter<CookieFilterVariables | null>();
  @Output() globalFilter = new EventEmitter<{ value: string, column: ECookieInventoryFilterType }>();
  @Output() onSort = new EventEmitter<{ active: string, direction?: string }>();
  @Output() onPaginate = new EventEmitter<{ pageIndex: number }>();

  totalPageCount: number = null;
  isFiltered = false;
  sorting: { sortBy?: string, sortDesc?: SortDirection } = {};
  selectedItem: CookieInventoryCookieRow;
  dataSource = new MatTableDataSource<CookieInventoryCookieRow>();
  paginationState: { length: number, pageSize: number } = {
    length: 0,
    pageSize: 0
  };
  columns: string[] = [
    'expand',
    'name',
    'domain',
    'unique_initiator_count',
    'party_type',
    'partition_key',
    'net_duration',
    'same_site',
    'secure',
    'http_only',
    'avg_size',
    'page_count'
  ];

  displayedColumns$ = this.tableService.displayedColumns$;
  expandedCookie: CookieInventoryCookieRow;
  windowWidth: number;
  isSidebarClosed: boolean;
  largeLayout: boolean;
  mediumLayout: boolean;
  smallLayout: boolean;
  verySmallLayout: boolean;

  resizeSubject: Subject<void> = new Subject();
  onResize$: Observable<void> = this.resizeSubject.asObservable();
  private destroy$ = new Subject();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('scrollTop', { read: ElementRef }) scrollTop: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeSubject.next();
  }

  constructor(
    private window: WindowRef,
    private uiTagService: UiTagService,
    public filterBarService: AuditReportFilterBarService,
    private sidebarService: SidebarService,
    private tableService: ResizeableTableService,
  ) {
    this.onResize$.pipe(
      debounceTime(500),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.windowWidth = this.getWindowWidth();
      this.updateLayoutSize();
    });

    this.sidebarService.isClosed.subscribe(isClosed => {
      this.isSidebarClosed = isClosed;
      this.windowWidth = this.getWindowWidth();
      this.updateLayoutSize();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.sort && changes.defaultSorting && changes.defaultSorting.currentValue !== changes.defaultSorting.previousValue) {
      this.sort.sort({
        id: this.sorting.sortBy,
        start: this.sorting.sortDesc ? 'desc' : 'asc',
        disableClear: true,
      });
      const activeSortHeader = this.sort.sortables.get(this.sorting.sortBy);
      activeSortHeader['_setAnimationTransitionState']({
        fromState: this.sorting.sortDesc ? 'desc' : 'asc',
        toState: 'active',
      });
    }

    if (this.apiFilters) {
      this.selectedItem = null;
      this.isFiltered = false;
    }
  }

  ngAfterViewInit() {
    this.paginator.page.subscribe(({ pageIndex }) => {
      this.onPaginate.emit({ pageIndex: pageIndex });
    });
  }

  getWindowWidth() {
    return this.window.nativeWindow.innerWidth;
  }

  updateLayoutSize(): void {
    this.smallLayout = this.mediumLayout = this.largeLayout = false;
    let sidebarWidth = this.isSidebarClosed ? 0 : 200;

    // Set general classes to handle max column widths in the cookies table
    if (this.windowWidth > 1800 + sidebarWidth) {
      this.largeLayout = true;
      this.mediumLayout = this.smallLayout = this.verySmallLayout = false;
    } else if (this.windowWidth > 1000 + sidebarWidth) {
      this.mediumLayout = true;
      this.largeLayout = this.smallLayout = this.verySmallLayout = false;
    } else if (this.windowWidth > 500 + sidebarWidth) {
      this.smallLayout = true;
      this.largeLayout = this.mediumLayout = this.verySmallLayout = false;
    } else {
      this.verySmallLayout = true;
      this.largeLayout = this.mediumLayout = this.smallLayout = false;
    }
  }

  expandCookiePage(item: CookieInventoryCookieRow, event: MouseEvent) {
    event.stopPropagation();
    this.expandedCookie = this.expandedCookie === item ? null : item;
  }

  filterByPage($event: MouseEvent, item: CookieInventoryCookieRow) {
    $event.stopPropagation();
    if (this.selectedItem === item) {
      this.localFilter.emit(null);
      this.selectedItem = null;
      this.isFiltered = false;

      return;
    }
    this.selectedItem = item;
    const cookie = {
      name: item.name,
      domain: item.domain,
      expirationType: item.expirationType,
      sameSite: item.sameSite,
      secure: item.secure,
      httpOnly: item.httpOnly,
      partyType: this.getKeyByValue(party, item.partyType),
      ...(item.partitionKey ? { partitionKey: { value: item.partitionKey } } : {}),
    };

    this.isFiltered = true;
    this.localFilter.emit({ cookie });
  }

  private getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  async prepareItems(data: CookieInventoryCookie[]): Promise<CookieInventoryCookieRow[]> {
    const [tags] = await this.uiTagService.getAllTagsData().toPromise();
    const initiatorLogos = [
      {
        id: 'HTTP',
        iconUrl: '/images/http-logo.png',
        tagName: 'HTTP'
      },
      {
        id: 'APP',
        iconUrl: '/images/js-logo.png',
        tagName: 'JavaScript'
      }
    ];
    return data.map(item => {
      const uniqueTagsList = [];
      let uniqueTagsCount: number = 0;

      item.initiators?.forEach((initiator) => {

        if (initiator.tagId) {
          const tag = tags.find(tag => initiator.tagId === tag.id);

          if (tag && !uniqueTagsList.includes(tag)) {
            uniqueTagsList.push(tag);
            uniqueTagsCount++;
          }
        } else {

          const initiatorLogo = initiatorLogos.find(i => i.id === initiator.initiatorType);
          if ( initiatorLogo && !uniqueTagsList.includes(initiatorLogo) ) {
            uniqueTagsList.push(initiatorLogo);
          }

        }

      });

      const firstTagId = item.initiators?.find(initiator => initiator.tagId)?.tagId;
      const initiators = item.initiators?.map((initiator) => {
        const tag = tags.find(tag => initiator.tagId === tag.id);

        return {
          ...initiator,
          origin: getTagInitiatorOriginData(initiator.foundDuring),
          tagImageUrl: tag ? UiTagService.getTagIconUrl(tag.id) : null,
          tagName: tag?.name,
          tagCategory: this.uiTagService.getTagCategory(tag?.tagCategoryId) ?? { category: '---', id: null },
          tagVendor: this.uiTagService.getTagVendor(tag?.tagVendorId) ?? { name: '---', id: null },
          tag: tags.find(tag => firstTagId === tag.id) ?? { name: '---', id: null },
          setMethodLabel: ECookieInitiatorTypeSetMethod[initiator.initiatorType],
          setMethodTooltip: this.getCookieInitiatorTooltip(initiator.initiatorType)
        };
      });

      return {
        ...item,
        origin: getCookieOriginData(item.cookieChangedDuring),
        initiators,
        sameSite: item.sameSite ?? '---',
        firstTagUrl: firstTagId ? UiTagService.getTagIconUrl(firstTagId) : null,
        uniqueTagsCount,
        uniqueTagsList,
        firstTagName: firstTagId ? tags.find(tag => firstTagId === tag.id)?.name : null,
        partyType: party[item.partyType],
        partitionKey: item.partitionKey ?? null,
        barChartSettings: {
          state: EFilterSpinnerState.None,
          displayPills: false,
          calcAsPercentage: true,
          displayPercentSymbol: false,
          textPosition: EBarChartTextPosition.Start
        },
        onPages: {
          chartData: [
            {
              name: `${ item.name }`,
              colorClass: EChartColor.Blue,
              filtered: false,
              value: this.getPercentage(item.filteredPageCount, this.filteredPageCount),
              displayValue: item.filteredPageCount
            }
          ],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `cookie-${ this.getRandomId() }-chart`,
        },
        netDurationInMinutes: this.getNetDuration(item.minDurationInMinutes, item.maxDurationInMinutes),
      };
    });
  }

  // Return the net duration of min and max duration (it's usually the same number). If different then show the range.
  getNetDuration(minDurationInMinutes: number, maxDurationInMinutes: number): string {
    const noResult = '---';
    let min = minDurationInMinutes ?? 0;
    let max = maxDurationInMinutes ?? 0;

    if(min === max) {
      return (max !== 0) ? JsFileChangesReportUtils.formatDateDifference(max) || noResult : noResult;
    } else {
      return (JsFileChangesReportUtils.formatDateDifference(min) || noResult) + ' - ' + (JsFileChangesReportUtils.formatDateDifference(max) || noResult);
    }
  }

  addCookieSameSiteFilter(value: string): void {
    this.filterBarService.addCookieSameSiteFilter((value === '---' ? 'Empty' : value) as ECookieSameSiteTypes);
  }

  addTagCategoryFilter(label: string, id: number): void {
    this.filterBarService.addTagCategoryFilter((label === '---' ? 'Empty' : label), label === '---' ? null : id);
  }

  addTagVendorFilter(label: string, id: number): void {
    this.filterBarService.addTagVendorFilter((label === '---' ? 'Empty' : label), label === '---' ? null : id);
  }

  addTagIdFilter(label: string, id: number): void {
    this.filterBarService.addTagIdFilter((label === '---' ? 'Empty' : label), label === '---' ? null : id);
  }

  private getRandomId(): string {
    //since API doesn't return unique data, then we generate it itself for graphs
    return Math.random().toString(36).substr(2, 9);
  }

  private getPercentage(a: number, b: number): number {
    // ensures fractional percentages don't round to
    // 0% or 100% and instead display as 1% and 99%
    const percentage = (a / b) * 100;

    if (percentage < 100 && percentage > 99) {
      return 99;
    }
    if (percentage < 1 && percentage > 0) {
      return 1;
    }

    return Math.round(percentage);
  }

  isLineSelected(cookie: CookieInventoryCookieRow) {
    return this.selectedItem === cookie;
  }

  private scrollToTop() {
    this.scrollTop.nativeElement.scrollIntoView(false);
  }

  filterReport(value: string, column: ECookieInventoryFilterType) {
    this.globalFilter.emit({ value, column });
  }

  private setPagination({ pagination }) {
    this.paginationState.length = pagination.totalCount;
    this.paginationState.pageSize = pagination.pageSize;
  }

  isThisSingleInitiatorUnknown(cookie: CookieInventoryCookieRow) {
    return cookie.initiators[0].initiatorType === 'UNKNOWN';
  }

  private getCookieInitiatorTooltip(initiatorType: string): string {
    switch (initiatorType) {
      case ECookieInitiatorType.HTTP:
        return `Server code that loaded a network request which had a set-cookie directive.`;
      case ECookieInitiatorType.APP:
        return `JavaScript running in the browser which came from the web server that loaded the file above.`;
      case ECookieInitiatorType.OP_ACTION:
        return `Cookie affected when executing configured actions.`;
      case ECookieInitiatorType.UNKNOWN:
        return `We're constantly evolving our cookie detection methods — sometimes we can't currently detect how a cookie was created or modified. This can sometimes mean the cookie was set via a shadow DOM or an iFrame.`;
    }
  }
}
