import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuditReportBase, IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject } from 'rxjs';
import {
  EAuditReportFilterTypes,
  EJSFileChangeType,
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { ActivatedRoute } from '@angular/router';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import { catchError, takeUntil } from 'rxjs/operators';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import { ELogLevels } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { JsFileChangesService } from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.service';
import {
  IAuditReportJSFileChangesApiPostBody,
  IJSFileChangesItem,
  IJSFileChangesPagesResponse,
  IJSFileChangesPagesTableRow,
  IJSFileChangesPagesTableState,
  IJSFileChangesSummary,
  IJSFileChangesTableState,
  JsFileChangesExportMenuData,
  PartyType
} from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.models';
import { EnumUtils } from '@app/components/utilities/enumUtils';
import {
  IJSFileChangesTableRow
} from '@app/components/audit-reports/reports/js-files-changes/components/js-file-changes-table/js-file-changes-table.models';
import {
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { IRunPickerRun } from '@app/components/run-picker-ng/run-picker-ng.models';
import { EJsFileChangesExportType } from './js-file-changes.enums';
import { formatDefs } from '@app/components/date/date.service';
import { JsFileChangesRelevantFilters } from './js-file-changes.constants';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import {
  AlertMetricType,
  EAlertJavaScriptFileChangesMetric
} from '@app/components/alert/alert-logic/alert-logic.enums';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import {
  JsFileChangesPagesTableComponent
} from '@app/components/audit-reports/reports/js-files-changes/components/js-file-changes-pages-table/js-file-changes-pages-table.component';
import { StorageService } from '@app/components/shared/services/storage.service';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
    CommonPagesColumnConfigWarningMessage, CommonPagesConfigLocalStorageKey,
    CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import { PagesTableColumns } from './components/js-file-changes-pages-table/js-file-changes-pages-table.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'js-file-changes',
  templateUrl: './js-file-changes.component.html',
  styleUrls: ['./js-file-changes.component.scss'],
  providers: [ResizeableTableService]
})
export class JsFileChangesComponent
  extends AuditReportBase
  implements IFilterableAuditReport, OnInit, OnDestroy {
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  readonly PagesTableColumns = PagesTableColumns;
  @ViewChild(JsFileChangesPagesTableComponent) jsFileChangesPagesTableComponent: JsFileChangesPagesTableComponent;
  // GENERAL
  auditId: number;
  runId: number;
  selectedFile: IJSFileChangesTableRow;

  comparedRun: IRunPickerRun | null;
  comparedRunLabel = 'LOADING...';

  apiFilters: IAuditReportJSFileChangesApiPostBody = {};

  private isLoaded: boolean;

  @ViewChild('fileChangesSection') topMessageSection: ElementRef;
  @ViewChild('pagesSection') pagesSection: ElementRef;

  readonly EFilterSpinnerState = EFilterSpinnerState;
  readonly EJSFileChangeType = EJSFileChangeType;

  // WIDGETS
  widgetsState: EFilterSpinnerState = EFilterSpinnerState.Loading;

  widgetPagesScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    values: {
      filtered: 0,
      total: 0,
    },
    metricType: EAlertJavaScriptFileChangesMetric.PagesScanned,
  };

  widgetChangedFiles: ISplitCardChartData = {
    topLabel: 'Changed Files',
    topHandler: () => this.filterByJSFilesChangeType(EJSFileChangeType.changedFile),
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    values: {
      filtered: 0,
      total: 0,
    },
    metricType: EAlertJavaScriptFileChangesMetric.ChangedFiles,
  };

  widgetNewFiles: ISplitCardChartData = {
    topLabel: 'New Files',
    topHandler: () => this.filterByJSFilesChangeType(EJSFileChangeType.newFile),
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    values: {
      filtered: 0,
      total: 0,
    },
    metricType: EAlertJavaScriptFileChangesMetric.NewFiles,
  };

  widgetFileDateChanges: ISplitCardChartData = {
    topLabel: 'File Date Changes',
    topHandler: () => this.filterBarService.handleEnterJSFileDateDifference(),
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    values: {
      filtered: 0,
      total: 0,
    },
    metricType: EAlertJavaScriptFileChangesMetric.FileDateChanges,
  };

  widgetFileSizeChanges: ISplitCardChartData = {
    topLabel: 'File Size Changes',
    topHandler: () => this.filterBarService.handleEnterJSFileSizeDifference(),
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    values: {
      filtered: 0,
      total: 0,
    },
    metricType: EAlertJavaScriptFileChangesMetric.FileSizeChanges,
  };

  filteredWidget: ELogLevels;
  ELogLevels = ELogLevels;
  sparklineDataLoaded: boolean = false;

  fileChangesLoaderState: EFilterSpinnerState = EFilterSpinnerState.Loading;
  fileChangesItems: IJSFileChangesItem[];
  fileChangesTableState: IJSFileChangesTableState = {
    sort: {sortBy: 'largest_size_diff', sortDesc: true},
    pagination: {size: 50, page: 0},
    pagesTotal: 0
  };

  //Pages
  pagesTableState: IJSFileChangesPagesTableState = {
    sort: {sortBy: CommonReportsPagesTableColumns.PageStatusCode, sortDesc: true},
    pagination: {size: 200, page: 0},
    pagesTotal: 0
  };
  pagesData: IJSFileChangesPagesTableRow[];
  pagesSpinnerState: EFilterSpinnerState;

  exportReportConfig: JsFileChangesExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: EJsFileChangesExportType.pages,
    totalRows: this.pagesTableState.pagesTotal,
    filteredRows: this.pagesTableState.pagesTotal,
    tableState: {
      ...this.pagesTableState.sort,
      ...this.pagesTableState.pagination
    },
    filters: this.apiFilters,
    specificExportTypes: {
      all: EJsFileChangesExportType.pages
    },
    dataToCopy: {
      config: [
        {
          property: 'pageUrl',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl
        },
        {
          property: 'finalPageUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
        },
        {
          property: 'pageLoadTime',
          displayLike: row => parseFloat((row.pageLoadTime / 1000).toFixed(1)),
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
        }
      ],
      data: null,
      displayedColumns$: this.tableService.displayedColumns$
    }
  };

  dateFormat: string = formatDefs.dateTwelve;

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  constructor(
    private route: ActivatedRoute,
    private jsFileChangesService: JsFileChangesService,
    private auditReportLoadingService: AuditReportLoadingService,
    private storageService: StorageService,
    private filterBarService: AuditReportFilterBarService,
    private snackbarService: SnackbarService,
    private scrollService: AuditReportScrollService,
    private auditDataService: IAuditDataService,
    private alertReportingService: AlertReportingService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private tableService: ResizeableTableService,
  ) {
    super();
    this.route.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;

      const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
      const alertId = queryParams?.alertId;
      const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
      this.preventHighlight = !highlight;
      // When loading report from an alert, first override filters with alert
      // filters before loading report data
      if (alertId) {
        this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
          this.filterBarService.overrideFilters(alert.config.filtersV0);
          this.highlightMetricType = highlight && alert.config.metricType;

          this.alertCheckComplete$.next(true);
        });
      } else {
        this.alertCheckComplete$.next(true);
      }

      if (this.isLoaded) {
        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          // only fire when calendar control updated
          this.onFiltersChanged(this.apiFilters);
        });

        this.loadLastRuns();
      }

      this.isLoaded = true;
    });
  }

  ngOnInit() {
    this.initFilters();
    this.loadLastRuns();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  loadFileChanges() {
    this.fileChangesLoaderState = EFilterSpinnerState.Loading;
    this.jsFileChangesService
      .getFileChanges(this.auditId, this.runId, this.fileChangesTableState, this.apiFilters)
      .subscribe(
        data => {
          if (data) {
            this.fileChangesItems = data.files;
          } else {
            this.fileChangesItems = [];
          }

          this.fileChangesTableState.pagesTotal = data.metadata.pagination.totalCount;
          this.fileChangesLoaderState = this.calcFileChangesSpinnerState();
        },
        this.handleApiError('Failed to load file changes.'),
        this.handleApiComplete(() => {
            this.fileChangesLoaderState = this.calcFileChangesSpinnerState();
          }
        )
      );
  }

  loadLastRuns() {
    this.auditDataService
      .getAuditRunsForRunPicker(this.auditId)
      .then((runs: IRunPickerRun[]) => {
        const lastCompletedRun = runs.find((run, idx) => {
          return run.id < this.runId && idx;
        });

        if (!lastCompletedRun || runs.length < 2) {
          this.comparedRun = null;
          this.comparedRunLabel = 'COMPARISONS FOR “CHANGES” AND “DIFFERENCES” WILL BE AVAILABLE ON LATER RUNS';
        } else {
          this.comparedRun = lastCompletedRun;
          this.comparedRunLabel = 'COMPARED TO LAST RUN ON ';
        }
      })
      .catch(() => this.handleApiError('Failed to load completed runs.'));
  }

  ngOnDestroy() {
    this.auditReportLoadingService.forceOff();
    this.destroy();
    this.onDestroy$.next();
    this.pageDetailsDrawerService.closePageDetails();

  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(JsFileChangesRelevantFilters);

    // ReplaySubject in filterBarService means this executes immediately
    this.filterBarService
      .apiPostBody$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAuditReportJSFileChangesApiPostBody) {
    this.selectedFile = null;
    this.exportReportConfig.filters = this.apiFilters = apiPostBody;
    this.updateCurrentFilters();

    this.loadWidgets();

    this.fileChangesTableState.pagination.page = 0;
    this.loadFileChanges();

    this.pagesTableState.pagination.page = 0;
    this.loadPages();
  }

  handleChangeClicked(selectedFile: IJSFileChangesTableRow) {
    const mkComparisonStr = (f: IJSFileChangesTableRow) => `${f.tag.tagId}_${f.filename}_${f.requestDomain}_${f.partyType}_${f.fileChangeType}`;
    if (this.selectedFile && mkComparisonStr(this.selectedFile) === mkComparisonStr(selectedFile)) {
      this.selectedFile = null;
    } else {
      this.selectedFile = selectedFile;
    }

    this.pagesTableState.pagination.page = 0;
    this.loadPages();
  }

  updatePagesTableState(newState: IJSFileChangesPagesTableState) {
    this.pagesTableState = newState;
    this.exportReportConfig.tableState = {
      ...newState.pagination,
      ...newState.sort
    };
    this.loadPages();
    this.scrollService.scrollByElement(this.pagesSection.nativeElement);
  }

  updateFileChangesState() {
    this.loadFileChanges();
  }

  private updateWidgets(summary: IJSFileChangesSummary) {
    this.exportReportConfig.totalRows = summary.totalPageCount;
    this.exportReportConfig.filteredRows = summary.filteredPageCount;

    this.widgetPagesScanned.values = {
      filtered: summary.filteredPageCount,
      total: summary.totalPageCount
    };
    this.widgetChangedFiles.values = {
      filtered: summary.filteredChangedFileCount,
      total: summary.totalChangedFileCount
    };

    this.widgetNewFiles.values = {
      filtered: summary.filteredNewFileCount,
      total: summary.totalNewFileCount
    };

    this.widgetFileDateChanges.values = {
      filtered: summary.filteredFileDateDifferenceCount,
      total: summary.totalFileDateDifferenceCount
    };

    this.widgetFileSizeChanges.values = {
      filtered: summary.filteredFileSizeDifferenceCount,
      total: summary.totalFileSizeDifferenceCount
    };
  }

  private errorToast(message: string) {
    this.snackbarService.openErrorSnackbar(message, {
      duration: 10000
    });
  }

  private handleApiError = (message: string, handler?: (error: any) => void) => {
    return error => {
      handler && handler(error);
      console.error(error);
      this.errorToast(message);
    };
  }

  private handleApiComplete = (handler?: () => void) => {
    return () => {
      handler && handler();
      this.auditReportLoadingService.removeLoadingToken();
    };
  }

  private calcFileChangesSpinnerState(): EFilterSpinnerState {
    return this.filterBarService.currentFilters.length || Object.keys(this.apiFilters).length || this.filteredWidget
      ? EFilterSpinnerState.Filtered
      : EFilterSpinnerState.None;
  }

  private calcPageSpinnerState(): EFilterSpinnerState {
    return this.calcFileChangesSpinnerState() === EFilterSpinnerState.Filtered || this.selectedFile
      ? EFilterSpinnerState.Filtered
      : EFilterSpinnerState.None;
  }

  private loadPages() {
    this.pagesSpinnerState = EFilterSpinnerState.Loading;
    let pagesObs: Observable<IJSFileChangesPagesResponse>;
    if (this.selectedFile) {
      this.exportReportConfig.filters = {
        ...this.apiFilters,
        file: {
          tagId: this.selectedFile.tag.tagId || null,
          filename: this.selectedFile.filename,
          fileChangeType: this.selectedFile.fileChangeType,
          requestDomain: this.selectedFile.requestDomain,
          partyType: EnumUtils.getKeyByValue(PartyType, this.selectedFile.partyType) as PartyType
        }
      };
      this.exportReportConfig.exportType = EJsFileChangesExportType.filePages;
      pagesObs = this.jsFileChangesService.getSpecificPages(this.auditId, this.runId, this.pagesTableState, this.exportReportConfig.filters);
    } else {
      this.exportReportConfig.filters = {...this.apiFilters};
      this.exportReportConfig.exportType = EJsFileChangesExportType.pages;
      pagesObs = this.jsFileChangesService.getPages(this.auditId, this.runId, this.pagesTableState, this.exportReportConfig.filters);
    }
    pagesObs.subscribe(({pages, metadata}) => {
      this.exportReportConfig.dataToCopy.data = this.pagesData = pages;
      this.exportReportConfig.filteredRows = this.pagesTableState.pagesTotal = metadata.pagination.totalCount;
      this.pagesSpinnerState = this.calcPageSpinnerState();
      if (!!this.selectedFile) {
        this.scrollService.scrollByElement(this.pagesSection.nativeElement);
      }
    });
  }

  private loadWidgets() {
    this.auditReportLoadingService.addLoadingToken();
    this.widgetsState = EFilterSpinnerState.Loading;

    this.jsFileChangesService
      .getSummary(this.auditId, this.runId, this.apiFilters)
      .pipe(catchError(() => EMPTY))
      .subscribe((data: IJSFileChangesSummary | undefined) => {
        if (data) {
          this.updateWidgets(data);
        } else {
          alert('Sorry! Some elements failed to update. Refresh your browser to try again.');
        }

        this.widgetsState = this.isFiltered() ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
        this.auditReportLoadingService.removeLoadingToken();
      });
  }

  private isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.length > 0;
  }

  private filterByJSFilesChangeType(changeType: EJSFileChangeType) {
    if (this.isFilteredByFilesChangeType(changeType)) {
      this.filterBarService.removeFilterByType(EAuditReportFilterTypes.JSFilesChangeType);
    } else {
      this.filterBarService.addJSFileChangeTypeFilter(changeType);
    }
  }

  isFilteredByFilesChangeType(changeType: EJSFileChangeType): boolean {
    return this.filterBarService.isFilteredByTypeAndValue(EAuditReportFilterTypes.JSFilesChangeType, changeType);
  }

  isFilteredByFilesDateDifference(): boolean {
    return this.filterBarService.isFilteredByType(EAuditReportFilterTypes.JSFilesDateDifference);
  }

  isFilteredByFilesSizeDifference(): boolean {
    return this.filterBarService.isFilteredByType(EAuditReportFilterTypes.JSFilesSizeDifference);
  }
}
