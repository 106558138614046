import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'op-success-toast',
  templateUrl: './op-error-toast.component.html',
  styleUrls: ['./op-error-toast.component.scss'],
})
export class OpErrorToastComponent {
  message: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data
  ) {
    this.message = data.message;
  }


}
