import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild, OnInit, OnChanges } from '@angular/core';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  ChangeType,
  IJSFileChangesItem,
  IJSFileChangesSortColumn,
  IJSFileChangesTableState,
  PartyType
} from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  IJSFileChangesTableRow
} from '@app/components/audit-reports/reports/js-files-changes/components/js-file-changes-table/js-file-changes-table.models';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { JsFileChangesReportUtils } from '@app/components/utilities/js-file-changes-report.utils';
import { DecimalPipe } from '@angular/common';
import { formatPaginator } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

enum TableColumn {
  Tag = 'tag',
  Filename = 'filename',
  Domain = 'requestDomain',
  PartyType = 'partyType',
  ChangeTypeFormatted = 'fileChangeTypeFormatted',
  ChangeType = 'fileChangeType',
  DateDifferenceFormatted = 'dateDifferenceFormatted',
  SizeDifferenceFormatted = 'sizeDifferenceFormatted',
  LargestDateDifference = 'largestDateDifference',
  LargestSizeDifference = 'largestSizeDifference',
  PageCount = 'pageCount',
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'js-file-changes-table',
  templateUrl: './js-file-changes-table.component.html',
  styleUrls: ['./js-file-changes-table.component.scss'],
  providers: [DecimalPipe]
})
export class JsFileChangesTableComponent implements OnInit, OnChanges {
  @Input() inputData: IJSFileChangesItem[];
  @Input() tableState: IJSFileChangesTableState;
  @Input() spinnerState: EFilterSpinnerState;
  @Input() getTagNameByTagId: (tagId: number) => string;
  @Input() apiFilters: IAuditReportApiPostBody;

  @Output() onChangeClicked = new EventEmitter<IJSFileChangesTableRow>();
  @Output() updateTableState = new EventEmitter<IJSFileChangesTableState>();

  @ViewChild(MatSort, {static: true}) tableSort: MatSort;
  @ViewChild(MatPaginator, {static: true}) tablePaginator: MatPaginator;
  @ViewChild('fileChangesTableScrollTop', {read: ElementRef}) fileChangesTableElement: ElementRef;

  readonly TableColumn = TableColumn;
  readonly EFilterSpinnerState = EFilterSpinnerState;
  readonly ChangeType = ChangeType;

  tableDataSource = new MatTableDataSource<IJSFileChangesTableRow>();
  tableDisplayedColumns = [
    TableColumn.Tag,
    TableColumn.Filename,
    TableColumn.Domain,
    TableColumn.PartyType,
    TableColumn.ChangeType,
    TableColumn.DateDifferenceFormatted,
    TableColumn.LargestSizeDifference,
    TableColumn.PageCount,
  ];
  selectedRow: IJSFileChangesTableRow;

  constructor(
    private filterBarService: AuditReportFilterBarService,
    private decimalPipe: DecimalPipe
  ) {
  }

  ngOnInit() {
    this.handleInputData(this.inputData);
    this.formatPaginator();

    this.tablePaginator.page.subscribe((pagination: PageEvent) => {
      this.tableState.pagination.page = pagination.pageIndex;
      this.updateTableState.emit(this.tableState);
    });

    this.tableSort
      .sortChange
      .subscribe((sort: Sort) => {
        this.tableState.sort.sortBy = this.tableColumnToSort(sort.active as TableColumn);
        this.tableState.sort.sortDesc = sort.direction === 'desc';
        this.tableState.pagination.page = 0;
        this.updateTableState.emit(this.tableState);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputData']) {
      this.handleInputData(changes['inputData'].currentValue);
    }

    if (this.apiFilters) {
      this.selectedRow = undefined;
    }
  }

  private handleInputData(inputData: IJSFileChangesItem[]) {
    if (inputData && Array.isArray(inputData)) {
      this.tableDataSource.data = inputData.map(i => this.dataItemToTableRow(i));
      this.fileChangesTableElement.nativeElement.scrollIntoView(false);
    }
  }

  private dataItemToTableRow(i: IJSFileChangesItem): IJSFileChangesTableRow {
    const changeTypeFormatted = ChangeType[i.fileChangeType];
    return {
      ...i,
      dateDifferenceFormatted: JsFileChangesReportUtils.formatDiffValueAccordingToChangeType(
        changeTypeFormatted,
        i.largestDateDifference,
        JsFileChangesReportUtils.formatDateDifference(i.largestDateDifference)
      ),
      sizeDifferenceFormatted: JsFileChangesReportUtils.formatDiffValueAccordingToChangeType(
        changeTypeFormatted,
        i.largestSizeDifference,
        JsFileChangesReportUtils.formatSizeDifference(
          i.largestSizeDifference,
          value => this.decimalPipe.transform(value)
        )
      ),
      fileChangeTypeFormatted: changeTypeFormatted,
      partyType: PartyType[i.partyType],
      tag: {
        ...i.tag,
        src: i.tag && UiTagService.getTagIconUrl(i.tag.tagId)
      }
    };
  }

  selectRow($event: MouseEvent, row: IJSFileChangesTableRow) {
    $event.stopPropagation();
    this.selectedRow = this.selectedRow === row ? null : row;
    this.onChangeClicked.emit(row);
  }

  private tableColumnToSort(c: TableColumn): IJSFileChangesSortColumn {
    switch (c) {
      case TableColumn.Filename: return 'filename';
      case TableColumn.Tag: return 'tag';
      case TableColumn.Domain: return 'domain';
      case TableColumn.ChangeType: return 'change_type';
      case TableColumn.PartyType: return 'party_type';
      case TableColumn.DateDifferenceFormatted: return 'largest_date_diff';
      case TableColumn.LargestSizeDifference: return 'largest_size_diff';
      case TableColumn.PageCount: return 'page_count';
      default: return 'largest_size_diff';
    }
  }

  setFilenameGlobalFilter(name: string) {
    this.filterBarService.addJSFilenameFilter(name);
  }

  setTagGlobalFilter(tagName: string, tagId: number) {
    this.filterBarService.addTagIdFilter(tagName, tagId);
  }

  private formatPaginator(): void {
    this.tablePaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }
}
