<div class="snackbar-container">
  <ng-container *ngIf="display.totalNewCategories">
    <p>
      {{ display.totalNewCategories | number }} new consent categor{{ display.totalNewCategories > 1 ? 'ies' : 'y' }}
      have been added including {{ display.totalNewCookies | number }} new
      cookie{{ display.totalNewCookies > 1 ? 's' : '' }}.
    </p>
    <ul>
      <li *ngFor="let row of display.topXNew">{{ row }}</li>
      <li *ngIf="display.numAdditional > 0">+ {{ display.numAdditional | number }} more</li>
    </ul>
  </ng-container>

  <ng-container *ngIf="display.totalExistingCategories">
    <br>
    <p>
      {{ display.totalExistingCategories | number }} existing
      categor{{ display.totalExistingCategories > 1 ? 'ies' : 'y' }} with
      {{ display.totalExistingCookies | number }} new
      cookie{{ display.totalExistingCookies > 1 ? 's' : '' }} have been updated.
    </p>
  </ng-container>
</div>