import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'op-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent {

  @Input() source: string;
  @Input() overlayText: string;
  @Output() clickHandler: EventEmitter<any> = new EventEmitter();

}