import { FileHandoffController } from './fileHandoffController';
import * as angular from 'angular';

interface IMyAttributes extends angular.IAttributes {
  fileHandoff: string;
}

export class FileHandoff implements angular.IDirective {
  link: (
    $scope: angular.IScope,
    $element: angular.IAugmentedJQuery,
    $attrs: IMyAttributes,
    $ctrl: FileHandoffController
  ) => void;

  restrict = 'A';
  scope = {};
  bindToController = {
    fileHandoff: '&'
  };
  controller = 'fileHandoffController';
  controllerAs = 'notBeingUsedButRequired';

  constructor(private $log: angular.ILogService) {
    this.$log.debug('constructed', $log);

    FileHandoff.prototype.link = (
      $scope: angular.IScope,
      $element: angular.IAugmentedJQuery,
      $attrs: IMyAttributes,
      $ctrl: FileHandoffController
    ): void => {
      this.$log.debug('linked', $scope, $element, $attrs, $ctrl);

      $element.bind('change', event => {
        $ctrl.onChangeHandler(event);
      });
    };
  }

  static Factory(): angular.IDirectiveFactory {
    const directive = ($log: angular.ILogService) => {
      return new FileHandoff($log);
    };
    directive['$inject'] = ['$log'];
    return directive;
  }
}
