import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'component-library-section',
  templateUrl: './component-library-section.component.html',
  styleUrls: ['./component-library-section.component.scss']
})
export class ComponentLibrarySectionComponent {
  @Input() sectionName: string;
  @Input() selector: string;
}
