<div class="splash-page-content flex-col flex-stretch">
  <div class="ca-header flex-row">
    <h2>{{header}}</h2>
  </div>
  <div class="flex-row">
    <div class="flex-col flex-stretch left-side">
      <ng-container *ngTemplateOutlet="bodyTemplate"></ng-container>
    </div>
    <div class="flex-col flex-stretch right-side">
      <img [src]="imageSrc">
    </div>
  </div>
  <button class="btn btn-sort" (click)="onClick.emit()">I'm interested</button>
</div>
