import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  IPagesDetailsAccessibilityFilter,
  IPagesDetailsAccessibilityFilterMenuOption
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.models';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-details-accessibility-filter',
  templateUrl: './page-details-accessibility-filter.component.html',
  styleUrls: ['./page-details-accessibility-filter.component.scss']
})
export class PageDetailsAccessibilityFilterComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();

  @Input() filter: IPagesDetailsAccessibilityFilter;
  @Output() valueChanged: EventEmitter<IPagesDetailsAccessibilityFilter> = new EventEmitter();
  formControl: UntypedFormGroup;

  get checkboxes(): UntypedFormArray {
    return this.formControl.get('checkboxes') as UntypedFormArray;
  }

  controlAt(i: number): UntypedFormControl {
    return this.checkboxes.at(i).get('checked') as UntypedFormControl;
  }

  get anyItemsChecked(): boolean {
    return this.checkboxes.getRawValue().map((checkbox) => checkbox.checked).some((checked) => checked);
  }

  get checkedItemsAmount(): number {
    return this.checkboxes.controls.filter((checkbox) => checkbox.value.checked)?.length;
  }

  get checkedItemsNames(): string {
    return this.checkboxes.getRawValue().filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.label).join(', ');
  }

  get label(): string {
    if (this.anyItemsChecked) {
      return `${this.filter.label}: ${this.checkedItemsNames}`;
    }
    return this.filter.label;
  }

  constructor(private fb: UntypedFormBuilder) {
  }

  filterChanged(value: IPagesDetailsAccessibilityFilterMenuOption[]): void {
    this.valueChanged.emit({ ...this.filter, menuOptions: value });
  }

  private createForm(): void {
    this.formControl = this.fb.group({
      checkboxes: this.fb.array(
        this.filter.menuOptions.map(
          (option: IPagesDetailsAccessibilityFilterMenuOption) =>
            this.fb.group({
              label: [option.label],
              checked: [option.checked],
            })
        )
      )
    });

    this.formControl.valueChanges
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.destroy$),
        tap(() => {
          const checkedItemsAmount = this.checkedItemsAmount;

          if (checkedItemsAmount === 1) {
            this.disableSelectedOne();
          }
          if (checkedItemsAmount > 1) {
            this.enableAll();
          }
        })
      )
      .subscribe((value) => this.filterChanged(this.formControl.getRawValue().checkboxes));
  }

  private disableSelectedOne(): void {
    this.checkboxes.controls
      .find((c => c.value.checked))
      ?.disable();
  }

  private enableAll(): void {
    this.checkboxes.controls
      .forEach((c) => c.enable());
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
