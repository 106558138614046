import { Component, Input } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'open-menu-on-hover',
  templateUrl: './open-menu-on-hover.component.html'
})
export class OpenMenuOnHoverComponent {
  /**
   * this component allows you to easily open
   * a material menu on hover
   *
   * create by https://stackoverflow.com/users/3804977/pierre-henri-sudre
   * and can be found here https://stackoverflow.com/a/59576853/1588904
   */

  @Input() menuClass: string;
  @Input() activeClass: string;

  timedOutCloser: NodeJS.Timeout;
  active: boolean = false;

  mouseEnter(trigger: MatMenuTrigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
    this.active = true;
  }

  mouseLeave(trigger: MatMenuTrigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
      this.active = false;
    }, 100);
  }

  setClassForActiveMenu() {
    if (this.active) {
      return this.activeClass;
    }
  }
}