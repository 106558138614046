<div class="notification-channels"
     mdePopoverTriggerOn="hover"
     [mdePopoverTriggerFor]="expandedNotificationChannels">
  <img class="channel-icon" src="images/slack_icon.svg">
  <img class="channel-icon" src="images/microsoftTeams_icon.svg">
  <img class="channel-icon" src="images/jira_icon.svg">
</div>
<mde-popover #expandedNotificationChannels
             [mdePopoverPositionX]="mdePopoverPositionX"
             [mdePopoverPositionY]="mdePopoverPositionY"
             [mdePopoverOffsetX]="mdePopoverOffsetX"
             [mdePopoverOffsetY]="mdePopoverOffsetY"
             [mdePopoverOverlapTrigger]="false"
             [mdePopoverCloseOnClick]="false">
  <notification-channels-popover></notification-channels-popover>
</mde-popover>
