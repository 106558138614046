import { Component, EventEmitter, Input, NgZone, Output, OnInit, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { IDragModel } from '@app/components/actions/actions-preview/actions-preview.models';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { IBaseActionDetails } from '@app/components/actions/action-details/action-details.models';
import { EActionCreatorMode } from '@app/components/actions/actions-creator/actions-creator.enum';
import { EActionType } from '@app/components/web-journey/web-journey.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  CreateActionSetComponent
} from '@app/components/action-set-library/create-action-set/create-action-set.component';
import { ICreateActionSetObject, IActionSet } from '@app/components/action-set-library/action-set-library.models';
import { MigrateActionsComponent } from '../../action-set-library/migrate-actions/migrate-actions.component';
import { ActionSetLibraryService } from '@app/components/action-set-library/action-set-library.service';
import { IMigrateActionsPayload } from '@app/components/action-set-library/migrate-actions/migrate-actions.models';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'actions-preview',
  templateUrl: './actions-preview.component.html',
  styleUrls: ['./actions-preview.component.scss']
})
export class ActionsPreviewComponent implements OnInit, OnChanges, OnDestroy {

  @Input() actions: IBaseActionDetails[];
  @Input() activeActionIndex: number = 0;
  @Input() formIsValid: boolean;
  @Input() mode: EActionCreatorMode;
  @Input() heading: string;

  @Output() onSelect = new EventEmitter<number>();
  @Output() onDrag = new EventEmitter<IDragModel>();
  @Output() onDelete = new EventEmitter<number>();
  @Output() addAction = new EventEmitter<number>();
  @Output() onActionSetCreated = new EventEmitter();

  draggedMode: boolean = false; // when some action is dragged
  ACTIONS_GROUP_NAME: string;

  subs = new Subscription();

  // allows or prevents first action being draggable. Prevents by default
  firstActionDraggable: boolean;

  readonly eActionType = EActionType;
  readonly actionCreatorMode = EActionCreatorMode;

  actionSetCreationMode: boolean = false;
  selectedActions: IBaseActionDetails[] = [];
  createActionSetDisabled: boolean = true;
  saveNewActionSetDisabled: boolean = true;

  constructor(
    private dragulaService: DragulaService,
    private ngZone: NgZone,
    private modalService: OpModalService,
    private actionSetService: ActionSetLibraryService,
    private snackbarService: SnackbarService
  ) {
  }

  ngOnInit() {
    this.ACTIONS_GROUP_NAME = 'actions_' + this.mode;
    this.firstActionDraggable = [
      EActionCreatorMode.AuditActions,
      EActionCreatorMode.WebJourney,
      EActionCreatorMode.ActionSet].includes(this.mode);
    this.initDragAndDrop();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.formIsValid) {
      this.evalCreateActionSetButton();
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.dragulaService.destroy(this.ACTIONS_GROUP_NAME);
  }

  get buttonText(): string {
    return this.mode === EActionCreatorMode.AuditUserSession && this.actions.length === 0
      ? 'Create Pre-Audit Action'
      : 'Add An Action';
  }

  selectAction(action: IBaseActionDetails, index: number): void {
    this.onSelect.emit(index);
  }

  isDraggable(action: IBaseActionDetails) {
    return this.firstActionDraggable ? true : action !== this.actions[0];
  }

  onAddAction(): void {
    this.addAction.emit(this.actions.length);
    setTimeout(() => {
      this.evalCreateActionSetButton();
    });
  }

  deleteAction(action: IBaseActionDetails, index: number) {
    this.onDelete.emit(index);
    this.evalCreateActionSetButton();
  }

  insertAction(action: IBaseActionDetails, index: number) {
    this.addAction.emit(index + 1);
  }

  private initDragAndDrop() {
    this.dragulaService.createGroup(this.ACTIONS_GROUP_NAME, {
      direction: 'vertical',
      moves: (el: Element, source: Element, handle: Element, sibling: Element) => {
        return el.classList.contains('draggable');
      },
      accepts: (sourceElem: Element, targetContainer: Element, sourceContainer: Element, siblingElem: Element) => {
        // if siblingElem === null - sourceElem would be placed as the last element in the container
        const isSiblingDraggable = siblingElem ? siblingElem.classList.contains('draggable') : true;

        const isTargetFirstAction = targetContainer.children[0] === siblingElem;
        const isSourceFirstAction = sourceContainer.children[0] === sourceElem;

        return (isTargetFirstAction || isSourceFirstAction) ?
          isSiblingDraggable && this.checkFirstAction(sourceElem, targetContainer, isTargetFirstAction, isSourceFirstAction) :
          isSiblingDraggable;
      },
    });

    this.subs.add(this.dragulaService.drag(this.ACTIONS_GROUP_NAME)
      .subscribe(() => {
        this.ngZone.run(() => {
          this.draggedMode = true;
        });
      })
    );

    this.subs.add(this.dragulaService.cancel(this.ACTIONS_GROUP_NAME)
      .subscribe(() => {
        this.draggedMode = false;
      })
    );

    this.subs.add(this.dragulaService.dropModel(this.ACTIONS_GROUP_NAME)
      .subscribe(({targetModel, targetIndex}) => {
        this.draggedMode = false;
        this.onDrag.emit({targetModel, targetIndex});
      })
    );
  }

  /**
   * Checks that 1st action can be draggable or not
   */
  private checkFirstAction(sourceElem: Element, targetContainer: Element, isTargetFirstAction: boolean, isSourceFirstAction: boolean): boolean {
    return (this.mode === EActionCreatorMode.WebJourney) ?
      this.checkWebJourneyFirstAction(sourceElem, targetContainer, isTargetFirstAction, isSourceFirstAction) :
      this.firstActionDraggable;
  }

  /**
   * Web Journey's 1st action can be replaced by another NavTo action
   */
  private checkWebJourneyFirstAction(sourceElem: Element, targetContainer: Element, isTargetFirstAction: boolean, isSourceFirstAction: boolean): boolean {
    if (isTargetFirstAction) return this.isNavToAction(sourceElem) || this.isCMPAction(sourceElem);
    if (isSourceFirstAction) return this.isNavToAction(targetContainer.children[1]) || this.isCMPAction(targetContainer.children[1]);
    return true;
  }

  private isNavToAction(elem: Element): boolean {
    return elem.classList.contains('nav-to');
  }

  private isCMPAction(elem: Element): boolean {
    return elem.classList.contains('cmp-action');
  }

  createActionSet(): void {
    this.actionSetCreationMode = true;
  }

  saveActionSet(): void {
    this.modalService.openModal(CreateActionSetComponent, {
      data: this.selectedActions
    })
      .afterClosed()
      .subscribe((data: ICreateActionSetObject) => {
        if (data) {
          if (data.addSimilar) this.onMigrateActions(data.actionSet);
          this.onActionSetCreated.emit({actionSet: data.actionSet, selectedActions: this.selectedActions});
          this.cancelCreateActionSet();
        }
      });
  }

  onMigrateActions(actionSet: IActionSet): void {
    this.modalService.openModal(MigrateActionsComponent, {
      maxHeight: 'calc(100vh - 100px)',
      height: 'auto',
      data: actionSet
    })
      .afterClosed()
      .subscribe((migrationData: IMigrateActionsPayload) => {
        if (migrationData) {
          this.actionSetService
            .executeMigration(migrationData.actionSetId, migrationData.itemsToMigrate)
            .subscribe(
              success => {
              },
              error => {
                console.error(error);
                this.snackbarService.openErrorSnackbar('Error: Could not migrate actions to action set');
              }
            );
        }
      });
  }

  cancelCreateActionSet(): void {
    this.actionSetCreationMode = false;
    this.selectedActions = [];
    this.saveNewActionSetDisabled = true;
  }

  addActionToSet(checked: boolean, action: IBaseActionDetails, index: number): void {
    let actionWithIndex = {...action, index: index + 1};

    if (checked) {
      this.selectedActions.push(actionWithIndex);
    } else {
      let indexToRemove: number;

      this.selectedActions.forEach((selectedAction: IBaseActionDetails, loopIndex: number) => {
        if (selectedAction.index === (index + 1)) {
          indexToRemove = loopIndex;
        }
      });

      this.selectedActions.splice(indexToRemove, 1);
    }

    this.saveNewActionSetDisabled = !this.selectedActions.length || !this.actionsAreConsecutive();
  }

  actionsAreConsecutive(): boolean {
    let indexes = this.selectedActions
      .sort((a: any, b: any) => (a.index > b.index) ? 1 : -1)
      .map(action => action.index);

    let length = indexes.length;
    let diff = (indexes[length - 1] - indexes[0]) + 1;

    if (length === diff) return true;
    return false;
  }

  private evalCreateActionSetButton(): void {
    this.createActionSetDisabled = !this.actions.length || !this.formIsValid;
  }
}
