<div class="flex-row">
  <span class="filter-input-wrp">
    <span class="icon icon-search"></span>
    <mat-form-field>
      <mat-label>Filter by Name</mat-label>
      <input matInput [(ngModel)]="filteredName" (input)="calcAuditsLists()" op-selector="search-audit-by-name">
    </mat-form-field>
  </span>
  
  <span class="filter-input-wrp">
    <span class="icon icon-search"></span>
    <mat-form-field>
      <mat-label>Filter by Label</mat-label>
      <input matInput [(ngModel)]="filteredLabel" (input)="calcAuditsLists()" op-selector="search-audit-by-label">
    </mat-form-field>
  </span>
</div>

<div class="flex-row audits-picker">
  <div class="flex-col allowed-audits" op-selector="allowed-audits">
    <audits-picker-list [audits]="allowedAudits" 
                        [mode]="auditsPickerListMode.ADD"
                        [filtering]="!!filteredName || !!filteredLabel"
                        (onAdd)="add($event)">
    </audits-picker-list>
  </div>

  <div class="flex-col selected-audits" op-selector="selected-audits">
    <audits-picker-list [audits]="selectedAudits" 
                        [mode]="auditsPickerListMode.REMOVE"
                        (onRemove)="remove($event)">
    </audits-picker-list>
  </div>
</div>
