<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header
      title="Custom Schedule"
      (onClose)="close()">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="custom-frequency-modal">
      <div class="heading">
        Repeat runs every...
      </div>
      <div class="count-and-freq section">
        <mat-form-field class="count-field" appearance="outline">
          <input matInput type="number" value="1" [formControl]="count">
          <mat-error *ngIf="count.errors?.min">Cannot be lower than 1</mat-error>
        </mat-form-field>

        <mat-form-field class="frequency-field" appearance="outline">
          <mat-select [formControl]="frequency">
            <mat-option *ngFor="let freq of supportedFrequencies" [value]="freq.value">
              {{ freq.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container [ngSwitch]="frequencyType">
        <div *ngSwitchCase="EFrequencyType.WEEK" class="weekday-selector section">
          <div class="heading">Repeat On</div>
          <weekday-selector
            [daysOfWeek]="daysOfWeek.value"
            [startDate]="startDate"
            (selectionChanged)="weekdaySelectionChanged($event)"
          ></weekday-selector>
        </div>

        <div *ngSwitchCase="EFrequencyType.MONTH_BY_DAY" class="monthy-by-day-selector section">
          <month-by-day-selector
            [selectedDate]="startDate"
            (selectedOptionChange)="monthByDaySelectionChanged($event)"
          ></month-by-day-selector>
        </div>

        <div *ngSwitchCase="EFrequencyType.MONTH_BY_DATE" class="monthy-by-date-selector section">
          <month-by-date-selector
            [selectedDate]="startDate"
            (selectedOptionChange)="monthByDateSelectionChanged($event)"
          ></month-by-date-selector>
        </div>
      </ng-container>

      <div class="end-runs">
        <div class="heading">End Runs</div>
        <mat-radio-group [formControl]="endRuns" color="primary">
          <mat-radio-button [value]="false">
            Never
          </mat-radio-button>
          <mat-radio-button [value]="true">
            <div class="date-wrp">
              <div class="radio-btn-txt">On Date</div>
              <op-run-date
                class="date-selector"
                [runDateForm]="customFrequencyForm"
                [controlName]="'endRunsDate'"
                [placeholder]="'Specify Date'"
                [showLabel]="false"
              >
              </op-run-date>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </ng-template>
</op-modal>
