import { NgModule } from '@angular/core';

import { FileMappingResultComponent } from './file-mapping-result.component';
import { MaterialModule } from '@app/components/material/material.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, MaterialModule],
  exports: [FileMappingResultComponent],
  declarations: [FileMappingResultComponent]
})
export class FileMappingResultModule { }
