import { ERuleMatchType } from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';

export interface IRuleReport{
    ruleId: number;
    ruleName: string;
  }

  export interface IPassedRuleReport extends IRuleReport{}

  export interface IFailedRuleReport extends IRuleReport{
    failedTags: Array<IFailedRuleTagReport>;
    checkTimes?: IRuleReportCheckTimes;
  }

  export interface INotAppliedRuleReport extends IRuleReport{
    items: Array<IRuleReportItem>;
  }

  export interface IFailedRuleTagReport{
    tagName: string;
    items: Array<IRuleReportItem>;
    missed?: boolean;
  }

  export interface IRuleReportItem{
    name: string;
    expected: string;
    actual: string;
    validationDescription?: string;
    conditionId?: number;
    snapshotId?: number;
    tagId?: number;
  }

  export interface IRuleReportCheckTimes {
    actual: number,
    expected: number
  }
  
  export type RuleResultType =
    'Passed' | 'Failed' | 'NotApplied' | 'Broken';

  export const RuleResultType = {
    passed: 'Passed' as RuleResultType,
    failed: 'Failed' as RuleResultType,
    notApplied: 'NotApplied' as RuleResultType,
    broken: 'Broken' as RuleResultType
  };

  export type RuleNotApplyingReason =
    'TagMissing' | 'IncorrectVariables' | 'Passed';

  export const RuleNotApplyingReason = {
    missingTag: 'TagMissing' as RuleNotApplyingReason,
    incorrectVariables: 'IncorrectVariables' as RuleNotApplyingReason,
    passed: 'Passed' as RuleNotApplyingReason
  };

export interface IRuleReportSerializer {
  serializeFailedRuleReports(
    results: Array<IRuleReportResult>
  ): Array<IFailedRuleReport>;
  serializeNotAppliedRuleReports(
    results: Array<IRuleReportResult>
  ): Array<INotAppliedRuleReport>;
  serializePassedRuleReports(
    results: Array<IRuleReportResult>
  ): Array<IPassedRuleReport>;
}

export interface IRuleReportResult {
  runId: number;
  actionId: number;
  ruleId: number;
  resultType: RuleResultType;
  name: string;
  tagResults: Array<IRuleReportTagResult>;
  pageFilterResults: Array<IRuleReportPageFilterResult>;
  checkTimes: IRuleReportCheckTimesResult;
  errorMessage: string;
}

export interface IRuleReportCheckTimesResult {
  actual: number;
  expected: number;
}

export interface IRuleReportTagResult {
  tag: IRuleReportTagResultDetails;
  tagAccount: string;
  tagAccountMatchType: string;
  resultType: RuleNotApplyingReason;
  variables: Array<IRuleReportVariableResult>;
  conditionId: number;
}

export interface IRuleReportTagResultDetails {
  id: number;
  name: string;
}

export interface IRuleReportPageFilterResult {
  actual: string;
  expected: string;
  filterType: string;
  matchType: string;
}

export interface IRuleReportVariableResult {
  name: string;
  matchType: ERuleMatchType;
  selectorType: string;
  tag: IRuleReportTagResultDetails;
  expectedValues: Array<string>;
  expectedParamName: string;
  actualValue: string;
  resultType: string;
  snapshotId: number;
}
