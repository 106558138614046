<div *ngIf="view === ETagView.requestView" class="requests-expansion-row">
<!--  OP data section  -->
  <div class="request-expansion-cell requests-expansion-header cell-border-top">
    <div class="request-expansion-cell-property request-expansion-meta-header">Observepoint Data</div>
    <div class="request-expansion-cell-value">
      <div *ngIf="data.duplicates > 0" class="fail-status">{{data.duplicates}} Duplicate Request{{data.duplicates > 1 ? 's' : ''}}</div>
      <div *ngIf="data.duplicates === 0 && data.multiples > 0" class="warning-status">{{data.multiples}} Multiple Request{{data.multiples > 1 ? 's' : ''}}</div>
      <div *ngIf="data.duplicates === 0 && data.multiples === 0">Unique Request</div>
    </div>
  </div>
  <div class="request-expansion-cell" *ngIf="showAccount">
    <div class="request-expansion-cell-property">{{ETagsExpansionOPData.ACCOUNT}}</div>
    <div class="request-expansion-cell-value">{{data.account}}</div>
  </div>
  <div class="request-expansion-cell" *ngIf="showStatusCode">
    <div class="request-expansion-cell-property">{{ETagsExpansionOPData.STATUS_CODE}}</div>
    <div class="request-expansion-cell-value"
         [matTooltip]="data.statusCodeTooltip"
         matTooltipPosition="above"
         matTooltipShowDelay="300">{{data.statusCode === null ? '---' : data.statusCode}}</div>
  </div>
  <div class="request-expansion-cell" *ngIf="showTagSize">
    <div class="request-expansion-cell-property">{{ETagsExpansionOPData.TAG_SIZE}}</div>
    <div class="request-expansion-cell-value">{{data.bytes}}</div>
  </div>
  <div class="request-expansion-cell" *ngIf="showLoadTime">
    <div class="request-expansion-cell-property">{{ETagsExpansionOPData.LOAD_TIME}}</div>
    <div class="request-expansion-cell-value">{{data.loadTime}}</div>
  </div>
  <div class="request-expansion-cell" *ngIf="showDuplicateRequest">
    <div class="request-expansion-cell-property">{{ETagsExpansionOPData.DUPLICATES}}</div>
    <div class="request-expansion-cell-value">{{data.duplicates}}</div>
  </div>
  <div class="request-expansion-cell" *ngIf="showMultipleRequest">
    <div class="request-expansion-cell-property">{{ETagsExpansionOPData.MULTIPLES}}</div>
    <div class="request-expansion-cell-value">{{data.multiples}}</div>
  </div>
  <!--  Variable Data section  -->
  <div class="request-expansion-cell requests-expansion-header">
    <div class="request-expansion-cell-property request-expansion-meta-header">Variable Data</div>
    <div class="request-expansion-cell-value"></div>
  </div>
  <div class="request-expansion-cell" *ngFor="let variable of data.tagInstanceVariables">
    <div class="request-expansion-cell-property">{{variable.name}}</div>
    <div class="request-expansion-cell-value"
         #requestVariableTooltip="matTooltip"
         matTooltip="{{getRequestViewTooltip(variable.value)}}"
         matTooltipPosition="above"
         [matTooltipShowDelay]="1000"
         (click)="copyToClipboard(variable.value, requestVariableTooltip, false); showTooltip(requestVariableTooltip);">{{variable.value}}</div>
  </div>
</div>
<!--OP Data section and other row names need to live outside this component for the Request view -->
<div *ngIf="view === ETagView.tagView" class="tags-expansion-column">
  <div class="tag-expansion-column vertical-orientation">
    <div class="tag-expansion-cell tags-expansion-header">
      <div class="tag-title" [ngClass]="{'duplicate-alert': +data.duplicates > 0, 'multiple-alert': data.duplicates === 0 && +data.multiples > 0}">{{data.title}}</div>
      <button mat-icon-button
              class="add-rule-button"
              (click)="openRuleAddModal()"
              matTooltip="Create Rule"
              matTooltipPosition="above"
              *ngIf="!userIsReadOnly">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>
    <div #accountTooltip="matTooltip"
         *ngIf="opDataRowsForTag.showAccount"
         matTooltip="{{data.account}}"
         matTooltipPosition="above"
         class="tag-expansion-cell account-name-row"
         (mouseenter)="opDataRowHighlight('enter', 'account-name-row')"
         (mouseleave)="opDataRowHighlight('leave', 'account-name-row')"
         (click)="copyToClipboard(data.account, accountTooltip, false); showTooltip(accountTooltip);">
      <div class="tag-cell-value">{{data.account}}</div>
    </div>
    <div class="tag-expansion-cell status-code-row"
         *ngIf="opDataRowsForTag.showStatusCode"
         (mouseenter)="opDataRowHighlight('enter', 'status-code-row')"
         (mouseleave)="opDataRowHighlight('leave', 'status-code-row')">
      <div class="tag-cell-value"[matTooltip]="data.statusCodeTooltip"
           matTooltipPosition="above"
           matTooltipShowDelay="300"
      >{{data.statusCode === null ? '---' : data.statusCode}}</div>
    </div>
    <div class="tag-expansion-cell tag-size-row"
         *ngIf="opDataRowsForTag.showTagSize"
         (mouseenter)="opDataRowHighlight('enter', 'tag-size-row')"
         (mouseleave)="opDataRowHighlight('leave', 'tag-size-row')">
      <div class="tag-cell-value">{{data.bytes}}</div>
    </div>
    <div class="tag-expansion-cell load-time-row"
         *ngIf="opDataRowsForTag.showLoadTime"
         (mouseenter)="opDataRowHighlight('enter', 'load-time-row')"
         (mouseleave)="opDataRowHighlight('leave', 'load-time-row')">
      <div class="tag-cell-value">{{data.loadTime}}</div>
    </div>
    <div class="tag-expansion-cell duplicate-request-row"
         *ngIf="opDataRowsForTag.showDuplicateRequest"
         (mouseenter)="opDataRowHighlight('enter', 'duplicate-request-row')"
         (mouseleave)="opDataRowHighlight('leave', 'duplicate-request-row')">
      <div class="tag-cell-value">{{data.duplicates}}</div>
    </div>
    <div class="tag-expansion-cell multiple-request-row"
         *ngIf="opDataRowsForTag.showMultipleRequest"
         (mouseenter)="opDataRowHighlight('enter', 'multiple-request-row')"
         (mouseleave)="opDataRowHighlight('leave', 'multiple-request-row')">
      <div class="tag-cell-value">{{data.multiples}}</div>
    </div>
    <div class="tag-expansion-cell" [ngClass]="{ 'tags-expansion-header': variableList?.length > 0}">
      <div class="tag-cell-value" [ngClass]="{'no-variable-value': variableList?.length === 0}">{{
        variableList?.length === 0 ? 'No variables' : ''}}</div></div>
    <!--  Variable Data section  -->
    <div class="tag-expansion-cell highlight-{{ variableName }}"
         *ngFor="let variableName of variableList"
         (mouseenter)="variableDataRowHighlight(variableName, 'enter')"
         (mouseleave)="variableDataRowHighlight(variableName, 'leave')"
         >
      <div class="tag-cell-value tag-expansion-tooltip-cell"
           #variableTooltip="matTooltip"
           matTooltip="{{getTagViewTooltip(variableName, true)}}"
           matTooltipPosition="above"
           [matTooltipShowDelay]="1000"
           (click)="copyToClipboard(variableName, variableTooltip, true); showTooltip(variableTooltip);"
           [ngClass]="{'no-variable-value': !data.tagInstanceVariableValueMap.hasOwnProperty(variableName) ||
           !data.tagInstanceVariableValueMap[variableName].hasOwnProperty(data.tagInstanceId) ||
           !data.tagInstanceVariableValueMap[variableName][data.tagInstanceId]}"
      >{{data.tagInstanceVariableValueMap.hasOwnProperty(variableName)
        ? data.tagInstanceVariableValueMap[variableName].hasOwnProperty(data.tagInstanceId)
          ? data.tagInstanceVariableValueMap[variableName][data.tagInstanceId]
          : 'No variable'
        : 'No variable'}}
      </div>
    </div>
  </div>
</div>
