import { ComponentChanges } from '@app/models/commons';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  AfterViewInit, OnInit
} from '@angular/core';
import { ILabel } from '@app/components/shared/services/label.service';
import { IAdvancedConfigs } from '@app/components/shared/components/op-chip-selector/op-chip-selector.models';
import { IUser } from '@app/moonbeamModels';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { EAlertsLibraryColumns } from '../alerts-library.enums';
import {
  IAddAlertLabel,
  IAlert,
  IChangeAlertLabel, ISort
} from '@app/components/alerts-library/alerts-library.models';
import { StringUtils } from '@app/components/utilities/StringUtils';
import { EAlertsLibraryAssignmentType } from './alerts-table.enums';
import { IPagination } from '@app/components/shared/components/selectable-table/selectable-table.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alerts-table',
  templateUrl: './alerts-table.component.html',
  styleUrls: ['./alerts-table.component.scss']
})

export class AlertsTableComponent implements OnChanges, AfterViewInit, OnInit {
  readonly EAlertsLibraryAssignmentType = EAlertsLibraryAssignmentType;
  readonly EAlertsLibraryColumns = EAlertsLibraryColumns;

  allLabels: ILabel[] = [];

  @Input() isReadOnly: boolean;
  @Input() alerts: IAlert[];
  @Input() loading = true;

  @Input() pagination: IPagination;
  @Input() sortOptions: ISort;

  @Output() onCreateLabel = new EventEmitter<IAddAlertLabel>();
  @Output() onAddLabel = new EventEmitter<IChangeAlertLabel>();
  @Output() onRemoveLabel = new EventEmitter<IChangeAlertLabel>();

  @Output() createAlert = new EventEmitter<void>();
  @Output() editAlert = new EventEmitter<IAlert>();
  @Output() deleteAlert = new EventEmitter<IAlert>();
  @Output() viewChangeLog = new EventEmitter<IAlert>();
  @Output() duplicateAlert = new EventEmitter<IAlert>();

  @Output() openDataSourcesAlert = new EventEmitter<IAlert>();
  @Output() openSubscribersAlert = new EventEmitter<IAlert>();

  @Output() updateTableState = new EventEmitter<IPagination & ISort>();

  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild(MatPaginator) matPaginator: MatPaginator;

  readonly displayedColumns = [
    EAlertsLibraryColumns.Name,
    EAlertsLibraryColumns.MetricType,
    EAlertsLibraryColumns.Labels,
    EAlertsLibraryColumns.UpdatedAt,
    EAlertsLibraryColumns.TargetItemCount,
    EAlertsLibraryColumns.SubscribedCount,
    EAlertsLibraryColumns.Edit
  ];

  readonly dateFormat = 'MMM d, yyyy';
  readonly timeFormat = 'hh:mm a';

  userIdToUserMap: Map<number, IUser>;
  dataSource = new MatTableDataSource<IAlert>();

  chipsConfig: IAdvancedConfigs;

  isTableInitiated = false;
  constructor(public authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    this.chipsConfig = {
      collapsible: true,
      lines: 1,
      readOnly: this.isReadOnly,
      resizable: false,
      availableWidth: 200
    };
  }

  ngOnChanges(changes: ComponentChanges<AlertsTableComponent>) {
    if (changes.alerts?.currentValue !== changes.alerts?.previousValue) {
      this.dataSource.data = changes.alerts.currentValue;
    }

    if (changes.isReadOnly?.currentValue !== changes.isReadOnly?.previousValue) {
      this.chipsConfig = {
        ...this.chipsConfig,
        readOnly: changes.isReadOnly.currentValue
      };
    }
  }

  ngAfterViewInit() {
    this.matSort?.sortChange.subscribe(sort => {
      this.sortOptions.sortBy = StringUtils.camelCaseToSnakeCase(sort.active) as EAlertsLibraryColumns;
      this.sortOptions.sortDesc = sort.direction === 'desc';
      this.pagination.currentPageNumber = 0;
      this.updateTableState.emit({...this.sortOptions, ...this.pagination});
    });

    this.matPaginator?.page.subscribe(pagination => {
      this.pagination.currentPageNumber = pagination.pageIndex;
      this.updateTableState.emit({...this.sortOptions, ...this.pagination});
    });
  }

  createLabel(alert: IAlert, label: ILabel) {
    this.onCreateLabel.emit({
      alertId: alert.id,
      label
    });
  }

  addLabel(alert: IAlert, label: ILabel) {
    this.onAddLabel.emit({
      alertId: alert.id,
      labelId: label.id
    });
  }

  removeLabel(alert: IAlert, label: ILabel) {
    this.onRemoveLabel.emit({
      alertId: alert.id,
      labelId: label.id
    });
  }

}
