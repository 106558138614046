export enum EAlertsLibraryFilterTypes {
  Name = 'alertName',
  ReportMetric = 'metricType',
  Labels = 'labels',
  IsSubscribedOnly = 'isSubscribed',
  CreatedBy = 'createdByUserId'
}

export enum EAlertsLibrarySetupMode {
  Create = 'Create',
  Edit = 'Edit'
}

export enum EAlertsLibraryAssignmentType {
  Subscribers = 'subscribers',
  DataSources = 'datasources',
}

export enum EAlertsLibraryColumns {
  Name = 'alert_name',
  MetricType = 'report_metric',
  Labels = 'labels',
  TargetItemCount = 'target_items',
  UpdatedAt = 'updated_at',
  SubscribedCount = 'subscribers',
  Edit = 'edit'
}

