import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSourceEditorService {
  scrollToCmpAction: ReplaySubject<number> = new ReplaySubject<number>(1);
  scrollToCmpAction$ = this.scrollToCmpAction.asObservable();
}
