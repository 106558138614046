import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { CalculateWordWidth } from '@app/components/domains/discoveryAudits/reporting/services/calculateWordWidthService/calculateWordWidthService';
import { chipFontOptions } from './op-chip-manager.constants';
import { IChipConfig } from './op-chip-manager.models';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

const CHIP_DISPLAY_PROPERTY = 'name';

@Component({
  selector: 'op-chip-manager',
  templateUrl: './op-chip-manager.component.html',
  styleUrls: ['./op-chip-manager.component.scss']
})
export class OpChipManagerComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() compactView: boolean = false;
  @Input() allChips: any[] = [];
  @Input() selectedChips: any[] = [];
  @Input() chipType: string = 'Chip';
  @Input() numberOfRows: number = 1;
  @Input() isReadOnly: boolean = false;
  @Input() chipCountOnly: boolean = false;

  // chip doesn't exist and will be created through the API
  @Output() onChipCreated: EventEmitter<string> = new EventEmitter();
  // chip is being removed from item
  @Output() onChipRemoved: EventEmitter<any> = new EventEmitter();
  // chip already exists and was selected from autopopulated list
  @Output() onChipSelected: EventEmitter<any> = new EventEmitter();
  @Output() addingLabel: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('container') container: ElementRef;
  @ViewChild('addChip') addChip: ElementRef;
  @ViewChild('chipInput') chipInput: ElementRef;

  containerWidth: number;
  addChipBtnWidth: number;
  isAddingNewChip: boolean = false;
  unselectedChips: any[] = [];
  showMoreBtn: boolean = false;
  noOfHiddenChips: number = 0;
  chipList: any[] = [];
  chipListConfigs: IChipConfig[];
  hiddenChips: any[] = [];
  hiddenChipsConfigs: any[] = [];
  inputSize: number = 1;
  autocompleteChips: any[] = [];
  popoverOpen: boolean = false;
  newChipForm: UntypedFormGroup;
  exactMatch: boolean = false;
  alreadyApplied: boolean = false;
  splitChipText: string = '';

  // temparary arrays we'll use to populate the real ones after iterating
  // to prevent any performance issues from issues changes to Angular
  // in rapid succession
  tempChipList: any[] = [];
  tempChipListConfigs: any[] = [];
  tempHiddenChips: any[] = [];
  tempHiddenChipsConfigs: any[] = [];

  // --- this must match CSS!
  readonly chipRemoveButtonAndMargin: number = 40; // this is kind of a magic number
  readonly moreChipIconWidth: number = 20; // 18px wide + 2px left margin
  readonly addLabelTextMargin: number = 5; // 5px left margin
  // ---

  readonly maxNoOfCharacters: number = 256; // chips cannot be longer than this
  readonly minChipWidth: number = 70;
  readonly maxAutocompleteWidth = 400;

  private resize$ = new Subject();
  private destroy$ = new Subject();

  constructor(
    private calcWordWidthService: CalculateWordWidth,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.compactView)  {
      this.filterOutSelectedChips();
    } else {
      if (this.numberOfRows < 1) this.numberOfRows = 1;

      this.resize$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
        this.renderChips();
      });
    }
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.selectedChips) {
      // chips are coming back undefined and/or null from the API for some reason
      this.selectedChips = this.selectedChips?.filter((chip: any) => chip !== undefined && chip !== null);
    }

    this.renderChips();
  }

  ngAfterViewInit(): void {
    this.renderChips();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private renderChips(): void {
    this.filterOutSelectedChips();

    if (this.compactView) {
      this.displayCompactChips();
      this.handleSplitChipText();
    } else {
      this.getElementSizes();
      this.displayChips();
    }
  }

  private initForm(): void {
    this.newChipForm = this.fb.group({
      chipName: ['']
    });

    this.chipName.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string) => {
      this.setInputSize(value);
      this.handleAutoComplete(value);
      this.exactMatch = this.autocompleteChips.map(chip => chip.name).includes(value);
      this.alreadyApplied = this.selectedChips.map(chip => chip.name).includes(value);
    });
  }

  private displayChips(): void {
    if (!this.containerWidth) return;
    if (!this.addChipBtnWidth) return;

    this.resetArraysAndVars();

    const singleRowWidthWithAddBtn = this.containerWidth - this.addChipBtnWidth;
    const allChipsWidth = this.calcAllChipsWidth(this.selectedChips);

    // all chips fit so just let them render and move on
    if (allChipsWidth <= singleRowWidthWithAddBtn) {
      this.selectedChips.forEach((chip: any) => this.addChipForDisplay(chip, this.calcChipWidth(chip[CHIP_DISPLAY_PROPERTY])));

      this.chipList = [ ...this.tempChipList ];
      this.chipListConfigs = [ ...this.tempChipListConfigs ];

      this.changeDetectorRef.detectChanges();

      return;
    }

    let index = 0;
    let currentRow = 1;
    let availableSpaceInRow = this.containerWidth;

    // use calcChipsOnLastRow() if only one row
    if (this.numberOfRows > 1) {
      // while we're on any row but the last AND we still have chips to render
      while ((currentRow < this.numberOfRows) && (index < this.selectedChips.length)) {
        const chip = this.selectedChips[index];
        const actualChipWidth = this.calcChipWidth(chip[CHIP_DISPLAY_PROPERTY]);
        const displayChipWidth = (actualChipWidth > availableSpaceInRow && availableSpaceInRow > this.minChipWidth)
        ? availableSpaceInRow - 1
        : actualChipWidth;

        // if there's enough space to display this chip...
        if (displayChipWidth <= availableSpaceInRow) {
          // ...subtract this chips display width from the available space
          availableSpaceInRow -= displayChipWidth;

          // add chip to chipList
          this.addChipForDisplay(chip, displayChipWidth);

          ++index;
        } else {
          // we ran out of space and need to go to the next row
          availableSpaceInRow = this.containerWidth - displayChipWidth;
          ++currentRow;

          if (currentRow < this.numberOfRows) {
            this.addChipForDisplay(chip, displayChipWidth);

            ++index;
          }
        }
      }

      // only calc last row if we're out of rows
      if (currentRow >= this.numberOfRows) {
        this.calcChipsOnLastRow(index);
      } else {
        this.chipList = [ ...this.tempChipList ];
        this.chipListConfigs = [ ...this.tempChipListConfigs ];

        this.changeDetectorRef.detectChanges();
      }

    } else {
      this.calcChipsOnLastRow(index);
    }
  }

  private calcChipsOnLastRow(currentIndex: number): void {
    const remainingChips = this.selectedChips.slice(currentIndex);
    let availableSpaceInRow = this.containerWidth - this.addChipBtnWidth;
    const remainingChipsWidth = this.calcAllChipsWidth(remainingChips);

    // if remaining chips fit just let them render and move on
    if (remainingChipsWidth <= availableSpaceInRow) {
      remainingChips.forEach((chip: any) => this.addChipForDisplay(chip, this.calcChipWidth(chip[CHIP_DISPLAY_PROPERTY])));

      this.chipList = [ ...this.tempChipList ];
      this.chipListConfigs = [ ...this.tempChipListConfigs ];

      this.changeDetectorRef.detectChanges();
      return;
    }

    // if they don't all fit we know we'll need a see more button
    const moreBtnWidth = this.calcWordWidthService
      .calculate(`${remainingChips.length} more`, chipFontOptions).width + this.moreChipIconWidth;

    // subtract more button from available space
    availableSpaceInRow -= moreBtnWidth;

    for (let i = 0; i < remainingChips.length; i++) {
      const chip = remainingChips[i];
      const actualChipWidth = this.calcChipWidth(chip[CHIP_DISPLAY_PROPERTY]);
      const displayChipWidth = (actualChipWidth > availableSpaceInRow && availableSpaceInRow > this.minChipWidth)
        ? availableSpaceInRow
        : actualChipWidth;

      if (displayChipWidth <= availableSpaceInRow) {
        // ...subtract this chips display width from the available space
        availableSpaceInRow -= displayChipWidth;

        // add chip to chipList
        this.addChipForDisplay(chip, displayChipWidth);
      } else {
        // add the rest of the chips to the hidden chips array
        const moreBtnChips = remainingChips.slice(i);
        this.tempHiddenChips = moreBtnChips.map((chip: any) => chip);
        this.tempHiddenChipsConfigs = moreBtnChips.map((chip: any) => {
          const actualChipWidth = this.calcChipWidth(chip[CHIP_DISPLAY_PROPERTY]);
          const displayChipWidth = (actualChipWidth > this.containerWidth) ? this.containerWidth : actualChipWidth;

          return {
            chipDisplayProperty: CHIP_DISPLAY_PROPERTY,
            maxWidth: displayChipWidth
          };
        });

        // update the more button with the correct count
        this.noOfHiddenChips = this.tempHiddenChips.length;

        // exit the loop
        break;
      }
    }

    this.showMoreBtn = !!this.noOfHiddenChips;

    this.chipList = [ ...this.tempChipList ];
    this.chipListConfigs = [ ...this.tempChipListConfigs ];

    this.hiddenChips = [ ...this.tempHiddenChips ];
    this.hiddenChipsConfigs = [ ...this.tempHiddenChipsConfigs ];

    this.changeDetectorRef.detectChanges();
  }

  private resetArraysAndVars(): void {
    this.chipList = [];
    this.tempChipList = [];

    this.chipListConfigs = [];
    this.tempChipListConfigs = [];

    this.hiddenChips = [];
    this.tempHiddenChips = [];

    this.hiddenChipsConfigs = [];
    this.tempHiddenChipsConfigs = [];

    this.noOfHiddenChips = 0;
    this.showMoreBtn = false;
  }

  private addChipForDisplay(chip: any, chipWidth: number): void {
    this.tempChipList.push(chip);
    this.tempChipListConfigs.push({
      chipDisplayProperty: CHIP_DISPLAY_PROPERTY,
      maxWidth: chipWidth
    });
  }

  private filterOutSelectedChips(): void {
    const selectedChips = this.selectedChips?.map((chip: any) => chip[CHIP_DISPLAY_PROPERTY]);

    this.unselectedChips = this.allChips?.filter((chip: any) => {
      return !selectedChips?.includes(chip[CHIP_DISPLAY_PROPERTY]);
    });
  }

  private calcChipWidth(chipText: string): number {
    const textWidth = this.calcWordWidthService.calculate(chipText, chipFontOptions);
    return textWidth.width + this.chipRemoveButtonAndMargin;
  }

  private calcAllChipsWidth(chips: any[]): number {
    return chips.map((chip: any) => this.calcChipWidth(chip[CHIP_DISPLAY_PROPERTY]))
      .reduce((a: number, b: number) => a + b, 0);
  }

  addNewChip(): void {
    this.addingLabel.emit(true);
    this.isAddingNewChip = true;
    setTimeout(() => this.chipInput?.nativeElement.focus());
  }

  handleCreateNewChip(): void {
    this.addingLabel.emit(false);
    // don't emit a "created" event if the user selects
    // an existing chip from the autocomplete
    if (!(typeof this.chipName.value === 'string')) return;

    // don't emit a "created" event if the user types an
    // exact match for a chip that's already applied
    if (this.alreadyApplied) this.handleRemoveNewChip();

    this.onChipCreated.emit(this.chipName.value);
    this.handleRemoveNewChip();
  }

  handleChipSelected(value: any) {
    this.addingLabel.emit(false);
    // don't emit a "selected" event if the user selcts
    // the "create new" option from the autocomplete
    if (typeof value === 'string') return;
    this.onChipSelected.emit(value);
    this.handleRemoveNewChip();
  }

  private getElementSizes(): void {
    if (!this.container && !this.addChip) return;
    const addLabelTextSize = this.calcWordWidthService.calculate(`add ${this.chipType}`, {
      font: 'Open Sans',
      fontSize: '12px'
    });

    this.containerWidth = this.container?.nativeElement.offsetWidth;
    this.addChipBtnWidth = this.selectedChips.length
      ? this.addChip?.nativeElement.offsetWidth
      : this.addChip?.nativeElement.offsetWidth - (addLabelTextSize.width + this.addLabelTextMargin);

    this.changeDetectorRef.detectChanges();
  }

  private setInputSize(chipValue: string): void {
    setTimeout(() => this.inputSize = this.calcWordWidthService.calculate(chipValue, chipFontOptions).width + 10);
  }

  private handleAutoComplete(value: string): void {
    if (!value) {
      this.autocompleteChips = [];
      return;
    }

    this.autocompleteChips = this.unselectedChips
      .filter((chip: any) => {
        if (typeof value !== 'string') return false;
        const chipValue = chip[CHIP_DISPLAY_PROPERTY];
        return typeof chipValue === 'string' && chipValue.toLowerCase().includes(value.toLowerCase());
      })
      .sort((a: any, b: any) => {
        const first = a[CHIP_DISPLAY_PROPERTY].toLowerCase();
        const second = b[CHIP_DISPLAY_PROPERTY].toLowerCase();
        return first.localeCompare(second, undefined, { numeric: true });
      });

    this.changeDetectorRef.detectChanges();
  }

  handleRemoveNewChip(): void {
    this.addingLabel.emit(false);
    this.isAddingNewChip = false;
    this.chipName.setValue('');
    this.changeDetectorRef.detectChanges();
  }

  handleNewChipBlurred(): void {
    // adding a timeout so other events have time to fire before this one
    setTimeout(() => this.handleRemoveNewChip(), 200);
  }

  get chipName(): AbstractControl {
    return this.newChipForm.get('chipName');
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
  }

  handleSplitChipText(): void {
    this.splitChipText = this.chipList.length
      ? this.chipCountOnly ? `${this.chipList.length}` : `${this.chipList.length} ${this.chipType}${this.chipList.length > 1 ? 's' : ''}`
      : `add ${this.chipType}`;
  }

  displayCompactChips(): void {
    this.resetArraysAndVars();
    this.selectedChips?.forEach((chip: any) => this.addChipForDisplay(chip, this.calcChipWidth(chip[CHIP_DISPLAY_PROPERTY])));
    this.chipList = [ ...this.tempChipList ];
    this.chipListConfigs = [ ...this.tempChipListConfigs ];
    this.changeDetectorRef.detectChanges();
  }
}
