<p class="help-notice">
  Quickly validate your marketing emails - get started with these easy steps.
  <a href="https://help.observepoint.com/en/articles/9369053-validate-critical-links-from-marketing-emails"
     target="_blank">Learn more.</a>
</p>

<ol class="help-rects">
  <li class="help-rect">
    <div class="help-rect-wrapper">
      <div class="top-row">
        Setup inbox
        <ng-template [ngTemplateOutlet]="toggleBtn"></ng-template>
      </div>
      <div class="expandable-content-wrapper"
           [class.expanded]="isExpanded">
        <div class="expandable-content">
          <ul class="list">
            <li class="list-item">Get a unique email inbox address</li>
            <li class="list-item">Assign test scenarios (Audits) that should be executed.</li>
            <li class="list-item"> Assign people to be notified of results via email.</li>
          </ul>
        </div>
      </div>
    </div>
  </li>

  <div class="spacer">
    <div class="row top"></div>
    <div class="row"></div>
  </div>

  <li class="help-rect">
    <div class="help-rect-wrapper">
      <div class="top-row">
        Send your Email Messages
        <ng-template [ngTemplateOutlet]="toggleBtn"></ng-template>
      </div>
      <div class="expandable-content-wrapper"
           [class.expanded]="isExpanded">
        <div class="expandable-content">
          <p class="paragraph">Add your unique email address to your test send group(s) or forward any email
                               message.</p>
          <p class="paragraph">Any message sent to the inbox will automatically run all associated Audits.</p>
        </div>
      </div>
    </div>
  </li>

  <div class="spacer">
    <div class="row top"></div>
    <div class="row"></div>
  </div>

  <li class="help-rect">
    <div class="help-rect-wrapper">
      <div class="top-row">
        Review Results
        <ng-template [ngTemplateOutlet]="toggleBtn"></ng-template>
      </div>
      <div class="expandable-content-wrapper"
           [class.expanded]="isExpanded">
        <div class="expandable-content">
          After completion, a summary email notification with the results will be sent to all users assigned to the
          associated Audits or Alerts. You can also come back here and click in the MESSAGES RECEIVED column.
        </div>
      </div>
    </div>
  </li>
</ol>

<ng-template #toggleBtn>
  <button class="help-more"
          (click)="toggle()">
    <div class="help-more-text">
      {{ isExpanded ? 'less' : 'more' }}
    </div>
    <mat-icon class="chevron"
              [class.expanded]="isExpanded">
      keyboard_arrow_down
    </mat-icon>
  </button>
</ng-template>
