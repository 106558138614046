import { Component, Input, Output, EventEmitter } from '@angular/core'

enum EColors {
  Primary = 'primary',
  Accent = 'accent',
}

@Component({
  selector: 'op-button',
  template: `
    <button (click)="handleClick($event)" mat-flat-button [color]="color" [disabled]="disabled">
      <ng-content></ng-content>
    </button>
  `,
  styleUrls: ['./op-button.component.scss']
})
export class OpButtonComponent {
  @Input() disabled: boolean
  @Input() color: EColors = EColors.Primary
  // can't override native DOM events ie: "click" to control behavior
  // overriding here allows delegation to the native button element click event
  @Output() onClick: EventEmitter<Event> = new EventEmitter()

  handleClick(ev: MouseEvent) {
    this.onClick.emit(ev)
  }
}
