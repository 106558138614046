import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';

@Injectable()
export class WebJourneyEditorGuard  {

  constructor(private webJourneyV3Service: WebJourneyV3Service,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const journeyId = parseInt(route.params.journeyId);
    if (!journeyId) return true;
    return this.webJourneyV3Service.getJourney(journeyId).pipe(
      mapTo(true),
      catchError<boolean, Observable<false>>(error => {
        console.error(error.message);
        this.router.navigateByUrl(DataSourcesUrlBuilders.sources());
        return of(false);
      })
    );

  }

}
