import { Component, Input, OnInit } from '@angular/core';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  selector: 'op-tag-img',
  templateUrl: './tag-img.component.html',
  styleUrls: ['./tag-img.component.scss'],
})
export class TagImgComponent implements OnInit {
  @Input() tagId: number;
  imgSrc: string;

  ngOnInit() {
    this.imgSrc = UiTagService.getTagIconUrl(this.tagId);
  }
}
