<form class="flex-row" *ngIf="actionsCreatorForm" [formGroup]="actionsCreatorForm" formName="actionsCreatorForm" [submitted]="submitted" validate-form>

  <actions-preview [actions]="actions.value"
                   [activeActionIndex]="activeActionIndex"
                   [mode]="mode"
                   [heading]="leftColHeading"
                   [formIsValid]="formIsValid"
                   (onSelect)="onSelect($event, true)"
                   (onDrag)="onDrag($event)"
                   (onDelete)="onDelete($event)"
                   (addAction)="addAction($event)"
                   (onActionSetCreated)="addToActionSets($event)"
  >
  </actions-preview>

  <div class="action-details-wrp" formArrayName="actions">
    <div class="viewport" *ngIf="initialLoadComplete && actions.controls.length > 0">
      <div *uiScroll="let action of actionsDatasource; let i = index" class="action-scroll-item">
        <action-details [parentForm]="actionsCreatorForm"
                        [detailsFormGroup]="actions.controls[i]"
                        [mode]="mode"
                        [index]="i"
                        [submitted]="submitted"
                        [selected]="activeActionIndex === i"
                        [allActionSets]="allActionSets"
                        [navToActionSets]="navToActionSets"
                        (onActivate)="onSelect(i)"
                        (openRuleSelector)="openRuleSelector.emit({rules: actions.controls[i].controls.rules, index: i + 1})"
                        (onTypeChange)="onTypeChange($event)"
                        (onDelete)="onDelete(i)">
        </action-details>
      </div>
    </div>
    <div class="no-actions" *ngIf="actions.controls.length === 0">
      No actions have been added yet.
    </div>
  </div>
</form>
