<div class="use-case-report-section">
  <div class="section-heading" *ngIf="sectionTitle">
    <mat-icon *ngIf="!customIcon" class="section-icon">{{ sectionIcon }}</mat-icon>
    <mat-icon *ngIf="customIcon" svgIcon="{{sectionIcon}}" class="custom-icon section-icon"></mat-icon>
    <div class="section-title">{{ sectionTitle }}</div>
  </div>
  <div *ngIf="bannerText"
       class="banner"
       [class.clickable]="bannerClickAction"
       (click)="bannerClickAction()"
       [class.banner-yellow]="bannerColor === EUseCaseBannerColor.Yellow"
       [class.banner-gray]="bannerColor === EUseCaseBannerColor.Gray"
  >
    {{ bannerText }}
    <mat-icon>keyboard_double_arrow_right</mat-icon>
  </div>
  <ng-content></ng-content>
</div>
