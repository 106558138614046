<op-modal [leftFooterButtons]="leftFooterButtons"
          [rightFooterButtons]="rightFooterButtons"
          class="cc-import-modal-wrapper"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="title" (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="cc-import-modal-body modal-body" *ngIf="currentStep === 'import'">

      <div class="first-instructions">
        <p>Use the ObservePoint consent category template and input data from your CMP into all of the fields. </p>
        <span class="download-upload-instructions" ><a [href]="templateDownloadLink"
            download>Download Template</a> &nbsp; | &nbsp; <a (click)="toggleInstructions()">{{ !showInstructions ? "Show" : "Hide" }} Instructions
          </a>
          <mat-icon class="show-instructions-icon" (click)="toggleInstructions()">{{ showInstructions ?
            "expand_less" : "expand_more" }}</mat-icon>
        </span>
      </div>

      <div *ngIf="showInstructions" class="dropdown-instructions">
        <ul>
          <li *ngFor="let row of instructions">
            {{ row }}
          </li>
        </ul>
      </div>

      <div class="upload-template-container" *ngIf="!fileDropped">
        <div *ngIf="showUpload">
          <op-file-drop #fileDrop acceptedFileTypes="csv" (fileDropped)="onFileDrop($event)">
            <div class="file-drop-content">
              <button mat-flat-button class="op-upload-button" (click)="fileDrop.openFileSelector()">Upload Filled out ObservePoint
                Template</button>
                  <div class="top-margin">or</div>
                  <div class="top-margin">drag & drop a file</div>
            </div>
          </op-file-drop>
        </div>
      </div>



      <div class="data-uploaded-container" *ngIf="fileDropped">
        <op-file-drop #fileDrop acceptedFileTypes="csv" (fileDropped)="onFileDrop($event)">
          <div class="file-drop-content">
            <span class="filename">{{ uploadedFile?.name }}</span>
            <a class="updated-template-upload-link" (click)="fileDrop.openFileSelector()">Upload updated template.</a>
          </div>
        </op-file-drop>

      </div>
      <p *ngIf="errorMsg" class="error-message">{{ errorMsg }}</p>

      <div *ngIf="importErrors.length" class="import-errors">
        <div class="error-title"><mat-icon>warning_amber</mat-icon> &nbsp; {{ importErrors.length }} Error{{ importErrors.length > 1 ? "s" : "" }} found in the uploaded file</div>
        <div class="error-title-two">Fix the issues and upload the file again.</div>
        <p class="error-text" *ngFor="let error of importErrors">{{ error }}</p>
      </div>

      <div *ngIf="fileParseSuccess" class="parsed-data-table-container">
        IMPORT PREVIEW
        <table mat-table *ngIf="newCookies.length" [dataSource]="newCookies" class="parsed-data-table">

          <!-- Consent Category Name Column-->
          <ng-container matColumnDef="consentCategoryName">
            <th mat-header-cell *matHeaderCellDef>New Categories Will Be Created</th>
            <td mat-cell class="cc-name-td" *matCellDef="let row">{{ row.consentCategoryName }}</td>
          </ng-container>

          <!-- Number of New Cookies Column-->
          <ng-container matColumnDef="noOfNewCookies">
            <th mat-header-cell *matHeaderCellDef>New Cookies</th>
            <td mat-cell class="cc-no-of-new-cookies-td" *matCellDef="let row">{{ row.noOfNewCookies }}</td>
          </ng-container>

          <!-- Labels Column-->
          <ng-container matColumnDef="labels">
            <th mat-header-cell *matHeaderCellDef>Labels</th>
            <td mat-cell class="cc-labels-td" *matCellDef="let row">
              <op-chip-selector class="new-cookies-label-selector"
                                [allItems]="allLabels"
                                [(ngModel)]="row.labels"
                                (onRemoveChip)="onRemoveChip($event, row, 'newCookies')"
                                (onSelectChip)="onSelectChip($event, row, 'newCookies')"
                                (onCreateChip)="createLabel($event, row, 'newCookies')"
                                [placeholder]="{ hasSelected: '+ Add Label', hasNoSelected: '+ Add Label' }"
                                [advancedConfigs]="chipsConfig"
              ></op-chip-selector>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Existing Cookies Table-->
        <table mat-table *ngIf="existingCookies.length" [dataSource]="existingCookies" class="new-cookies-parsed-data-table">

          <!-- Consent Category Name Column-->
          <ng-container matColumnDef="consentCategoryName">
            <th mat-header-cell *matHeaderCellDef>Existing Categories to be Updated</th>
            <td mat-cell class="cc-name-td" *matCellDef="let row">{{ row.consentCategoryName }}</td>
          </ng-container>

          <!-- Number of New Cookies Column-->
          <ng-container matColumnDef="noOfNewCookies">
            <th mat-header-cell *matHeaderCellDef>New Cookies</th>
            <td mat-cell class="cc-no-of-new-cookies-td" *matCellDef="let row">{{ row.noOfNewCookies }}</td>
          </ng-container>

          <!-- Labels Column-->
          <ng-container matColumnDef="labels">
            <th mat-header-cell *matHeaderCellDef>Labels</th>
            <td mat-cell class="cc-labels-td" *matCellDef="let row">
              <op-chip-selector class="new-cookies-label-selector"
                                [allItems]="allLabels"
                                [(ngModel)]="row.labels"
                                (onRemoveChip)="onRemoveChip($event, row, 'existingCookies')"
                                (onSelectChip)="onSelectChip($event, row, 'existingCookies')"
                                (onCreateChip)="createLabel($event, row, 'existingCookies')"
                                [placeholder]="{ hasSelected: '+ Add Label', hasNoSelected: '+ Add Label' }"
                                [advancedConfigs]="chipsConfig"
              ></op-chip-selector>

            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>

    <div class="cc-import-modal-body modal-body" *ngIf="currentStep === 'assign'">
      <cc-assign-select-data-source [selectedConsentCategories]="importedCCList"
                                    [selectedConsentCategoryNames]="importedCCNames"
                                    [selectedType]="importedCCType"
                                    [headerText]="importSuccessMessage"
                                    [auditId]="auditId"
                                    (selectionChanged)="ccSelectionChanged($event)">
      </cc-assign-select-data-source>
    </div>
  </ng-template>
</op-modal>
