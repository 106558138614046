import { Component, Inject } from '@angular/core';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AlertComponent } from '@app/components/alert/alert.component';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { QuickCreateOpSelectors } from '@app/components/alert/alert-quick-create/alert-quick-create.constants';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-quick-create-success-snackbar',
  templateUrl: './alert-quick-create-success-snackbar.component.html',
  styleUrls: ['./alert-quick-create-success-snackbar.component.scss']
})
export class AlertQuickCreateSuccessSnackbarComponent {
  opSelector = QuickCreateOpSelectors;
  accountLevelAlert: boolean = false;

  constructor(
    private modalService: OpModalService,
    private snackBarRef: MatSnackBarRef<AlertQuickCreateSuccessSnackbarComponent>,
    private alertReportingService: AlertReportingService,
    @Inject(MAT_SNACK_BAR_DATA) public data,
  ){
    this.accountLevelAlert = this.alertReportingService.isAccountLevelMetric(this.data.metricType);
  }

  editAlert() {
    this.modalService.openFixedSizeModal(AlertComponent, { disableClose: true, data: this.data });
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
