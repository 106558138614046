<op-modal [title]="title"
          [leftFooterButtons]="leftFooterButtons"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="close(false)">

  <ng-template #bodyTemplate>
    <div class="messages">
      <div class="message" *ngFor="let message of messages">
        {{ message }}
      </div>
    </div>
  </ng-template>

</op-modal>
