<div *ngIf="loading">
  <mat-spinner class="centered" [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>

<div *ngIf="noResults && !loading">
  <ror-comparison-no-results [auditId]="auditId" [runId]="runId"></ror-comparison-no-results>
</div>

<div *ngIf="!noResults && !loading">

  <div class="compare-info" *ngIf="currentRun && previousRun">
    Comparing <span class="compare-date">{{currentRun?.completed | date: dateFormat}}</span>
    vs <span class="compare-date">{{previousRun?.completed | date: dateFormat}}</span>
  </div>

  <div class="flex-row">
      <div class="circle-progress">
        <op-circle-progress [percentage]="comparisonScores?.averageScore / 100"
                            [strokeRadiusPercentage]="averageScoreChartOpts.strokeRadiusPercentage"
                            showPercentageSign="false"
                            [bgArcColor]="averageScoreChartOpts.bgArcColor"
                            [fgArcColor]="averageScoreChartOpts.fgArcColor"
                            [valueColor]="averageScoreChartOpts.valueColor">
        </op-circle-progress>
        <div class="chart-name">{{averageScoreChartOpts.message}}</div>
      </div>

      <div class="time-series-section flex-row flex-stretch">
        <time-series-ng class="time-series flex-row flex-stretch"
                        [data]="timeSeriesData"
                        [tipDisabled]="false"
                        seriesTitle="Comparison Scores Over Time"
                        [currentId]="comparisonSnapshotId"
                        [user]="user">
        </time-series-ng>
      </div>
    </div>
    <h3>Parity Summary</h3>
    <ror-parity-summary [tagLevelScore]="comparisonScores?.tagParityScore"
                        [variableLevelScore]="comparisonScores?.variableParityScore"
                        [valueLevelScore]="comparisonScores?.variableValueParityScore"
                        [pagesMissing]="comparisonScores?.notComparedPages">
    </ror-parity-summary>
</div>

<router-outlet></router-outlet>
