import { Component, HostListener, Input } from '@angular/core';
import { VerticalBarsChartComponent } from '@app/components/shared/components/viz/vertical-bars-chart/vertical-bars-chart.component';
import { IUsageBarsChartBar } from './usage-bar-chart.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-v2-bar-chart',
  templateUrl: './usage-bar-chart.component.html',
  styleUrls: ['./usage-v2-bar-chart.component.scss']
})
export class UsageV2BarChartComponent extends VerticalBarsChartComponent<IUsageBarsChartBar> {
  @HostListener('window:beforeprint', ['$event']) beforePrint(): void {
    this.drawChart();
  }

  @HostListener('window:afterprint', ['$event']) afterPrint(): void {
    setTimeout(() => this.drawChart(), 1000);
  }

  @Input() subgroups: Array<keyof IUsageBarsChartBar> = ['scheduled', 'completed']; // order of these matters
  
  protected readonly TOOLTIP_CLASS = 'vertical-bars-chart-tooltip';
}
