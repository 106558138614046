import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IExportTriggeredAlertsSnackbarPayload } from './export-triggered-alerts-snackbar.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'export-triggered-alerts-snackbar',
  templateUrl: './export-triggered-alerts-snackbar.component.html',
  styleUrls: ['./export-triggered-alerts-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportTriggeredAlertsSnackbarComponent {
  email: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: IExportTriggeredAlertsSnackbarPayload) {
    this.email = data.email;
  }

}
