import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  IResizeableColumnProps
} from '@app/components/shared/directives/resizeable-table-column/resizeable-columns.model';
import { IPagesTableLocalStorageConfig } from '@app/components/audit-reports/audit-report-container.models';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { Subscription } from 'rxjs';
import { MatColumnDef } from '@angular/material/table';
import { ResizeableTableUtils } from '@app/components/utilities/resizeable-table.utils';

@Directive({
  selector: '[resizeableColumn]',
})
export class ResizeableColumnDirective implements OnInit, OnDestroy {

  /**
   * Minimum allowed width of the column, any positive integer number. Default {{ResizeableTableUtils.DEFAULT_MIN_WIDTH}}.
   *
   * It has to be named the same way as the directive selector.
   * Type 'number' would be ideal here, but in case if the value is not provided - it's being treated as an empty string
   * and doesn't work well with TS
   */
  @Input() resizeableColumn: string = '';
  private currentColumnMinWidth: number;

  private readonly currentColumn: Element;
  private nextColumn: Element;
  private table: HTMLElement;
  private thead: HTMLElement;
  private resizer: HTMLElement;

  private pressed: boolean;

  private mouseUpListener: () => void;
  private mouseMoveListener: () => void;
  private mouseDownListener: () => void;

  private columnsSubscription: Subscription;

  private startX: number;
  private currentColumnStartWidth: number;
  private nextColumnMinWidth: number;
  private nextColumnStartWidth: number;

  private readonly RESIZING_CLASS: string = 'resizing';
  private readonly ACTIVE_CLASS: string = 'active';

  constructor(
    private renderer: Renderer2,
    private tableService: ResizeableTableService,
    private el: ElementRef,
    private matColumnDef: MatColumnDef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.currentColumn = this.el.nativeElement;
  }

  ngOnInit(): void {
    this.nextColumn = this.currentColumn.nextElementSibling;

    if (!ResizeableTableUtils.hasResizeableAttribute(this.nextColumn)) {
      // Resizing is possible only between 2 resizeable columns
      return;
    }

    this.currentColumnMinWidth = ResizeableTableUtils.parseResizeableColumnMinWidth(this.resizeableColumn)
      || ResizeableTableUtils.DEFAULT_MIN_WIDTH;
    this.nextColumnMinWidth = ResizeableTableUtils.getResizeableColumnMinWidth(this.nextColumn)
      || ResizeableTableUtils.DEFAULT_MIN_WIDTH;

    const row = this.renderer.parentNode(this.currentColumn);
    this.thead = this.renderer.parentNode(row);
    this.table = this.renderer.parentNode(this.thead);

    // The draggable bar is being injected into the <th>
    this.resizer = this.renderer.createElement('span');
    this.renderer.addClass(this.resizer, 'resize-holder');
    this.renderer.appendChild(this.currentColumn, this.resizer);

    this.mouseDownListener = this.renderer.listen(this.resizer, 'mousedown', e => this.onMouseDown(e));
    this.mouseMoveListener = this.renderer.listen(this.table, 'mousemove', e => this.onMouseMove(e));
    this.mouseUpListener = this.renderer.listen(this.document, 'mouseup', () => this.onMouseUp());
  }

  onMouseDown(event: MouseEvent): void {
    this.pressed = true;

    this.startX = event.pageX;
    this.currentColumnStartWidth = this.currentColumn.getBoundingClientRect().width;
    this.nextColumnStartWidth = this.currentColumn.nextElementSibling.getBoundingClientRect().width;

    // Adding class to table to prevent ugly user-select highlighting etc.
    // Adding class to the column to prevent mat-sort from activation on mouseUp
    this.renderer.addClass(this.table, this.RESIZING_CLASS);
    this.renderer.addClass(this.currentColumn, this.RESIZING_CLASS);
    this.renderer.addClass(this.resizer, this.ACTIVE_CLASS);
  }

  onMouseUp(): void {
    if (this.pressed) {
      this.pressed = false;
      this.renderer.removeClass(this.table, this.RESIZING_CLASS);
      this.renderer.removeClass(this.currentColumn, this.RESIZING_CLASS);
      this.renderer.removeClass(this.resizer, this.ACTIVE_CLASS);
    }
  }

  onMouseMove(event: MouseEvent): void {
    if (this.pressed && event.buttons) {
      const cursorShiftX = Math.abs(event.pageX - this.startX);

      if (cursorShiftX !== 0) {
        // Resizing bar is always positioned on the right side of a column,
        // so width of the current column is always increased if dragging the resizer to the right
        const isResizingToRight = event.pageX > this.startX;

        const currentColumnNewWidth = isResizingToRight
          ? this.currentColumnStartWidth + cursorShiftX
          : this.currentColumnStartWidth - cursorShiftX;

        const nextColumnNewWidth = isResizingToRight
          ? this.nextColumnStartWidth - cursorShiftX
          : this.nextColumnStartWidth + cursorShiftX;

        if (currentColumnNewWidth > this.currentColumnMinWidth && nextColumnNewWidth > this.nextColumnMinWidth) {
          this.renderer.setStyle(this.currentColumn, 'width', `${currentColumnNewWidth}px`);

          // Resizeable column that has another resizeable column to the left and non resizeable to the right
          // will always have width set to 'auto', so it lets the table resize
          const columnAfterNextIsResizeable = ResizeableTableUtils.hasResizeableAttribute(this.nextColumn.nextElementSibling);
          const nextColumnWidth = columnAfterNextIsResizeable ? `${nextColumnNewWidth}px` : 'auto';
          this.renderer.setStyle(this.nextColumn, 'width', nextColumnWidth);

          // Let the table know that sizes have changed, and we need to save those to localStorage
          this.tableService.columnsResized.next(this.thead);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.mouseUpListener && this.mouseUpListener();
    this.mouseMoveListener && this.mouseMoveListener();
    this.mouseDownListener && this.mouseDownListener();
    this.columnsSubscription && this.columnsSubscription.unsubscribe();
  }
}
