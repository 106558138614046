import _isEqual from 'lodash-es/isEqual';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { IOpFilterBarFilter, IOpFilterBarFilterValue, IOpFilterBarInvertableFilter } from './op-filter-bar.models';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { CacheResetService, ECacheResetEvent } from '@app/components/core/services/cache-reset.service';
import {
  EAuditReportFilterTypes,
  EPageErrorFilterType
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { EPageFilterType } from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';

export class OpFilterBarService<EFilterType> {

  protected filters: IOpFilterBarFilter<EFilterType>[] = [];
  protected validTypes: EFilterType[] = [];
  private storeFiltersInStorage = true;

  // All filters
  anyFiltersUpdates$ = new BehaviorSubject<IOpFilterBarFilter<EFilterType>[]>([]);

  // Filter types, supported by the page
  validFilterTypesUpdated$ = new BehaviorSubject<EFilterType[]>([]);

  scroll$ = new Subject<number>();

  constructor(private storage: StorageService,
              private readonly storageKey: string,
              private cacheReset: CacheResetService,
              private readonly typeConfiguration: Map<EFilterType, boolean> // specify which can have multiples
            ) {
    this.initializeFiltersFromLocalStorage();
  }

  protected initializeFiltersFromLocalStorage() {
    this.cacheReset.reset$.subscribe(this.loadFiltersFromLocalStorage.bind(this));
    this.loadFiltersFromLocalStorage();
    this.appendFiltersToView();
  }

  loadFiltersFromLocalStorage(event: ECacheResetEvent = ECacheResetEvent.login) {
    if (event === ECacheResetEvent.login || event === ECacheResetEvent.loginAsOriginal) {
      if (this.storageKey) {
        const savedFilters = this.storage.getValue<IOpFilterBarFilter<EFilterType>[]>(this.storageKey, StorageType.Local);
        const uniqueFilters = this.createUniqueArrayOfFilters(savedFilters);
        this.filters = uniqueFilters || [];
      } else {
        this.filters = [];
      }
      this.storeFiltersInStorage = true;
      this.updateSubscribers();
    } else {
      this.filters = [];
      this.storeFiltersInStorage = false;
      this.updateSubscribers();
    }
  }

  get currentFilters() {
    return this.filters;
  }

  get currentRelevantFilters() {
    return this.filters.filter((filter: IOpFilterBarFilter<EFilterType>) => this.validTypes.includes(filter.type));
  }

  // Filters, relevant to the current page
  get relevantFilterUpdates$(): Observable<IOpFilterBarFilter<EFilterType>[]> {
    return this.anyFiltersUpdates$.pipe(
      map(newFilters => newFilters.filter(filter => this.validTypes.includes(filter.type))),
    );
  }

  isFilteredByType(type: EFilterType): boolean {
    return !!this.filters.find(filter => filter.type === type);
  }

  isFilteredByTypeAndValue(type: EFilterType, value: IOpFilterBarFilterValue): boolean {
    return !!this.filters.find(filter => {
      const sameType = filter.type === type;
      const sameValue = typeof value === 'object' ? _isEqual(value, filter.value) : value === filter.value;
      return sameType && sameValue;
    });
  }

  appendFiltersToView(): void {
    this.filters.forEach((filter: IOpFilterBarFilter<EFilterType>) => this.addFilter(filter));
  }

  addFilter(filter: IOpFilterBarFilter<EFilterType> | IOpFilterBarInvertableFilter<EFilterType>) {
    let existingType = this.filters.find(f => f.type === filter.type);
    const exactMatch = this.filters.find(f => JSON.stringify(f) === JSON.stringify(filter));
    const multipleAllowed = this.typeConfiguration.get(filter.type);

    // filter type doesn't exist -- add the filter
    if (!existingType) {
      this.filters.push(filter);
    }

    // filter type already exists
    else if (existingType && !exactMatch) {
      // multiple filters of this type are allowed -- add the filter
      if (multipleAllowed) {
        this.filters.push(filter);
      }

      // multiple filters of this type aren't allowed -- replace the values
      else {
        existingType.value = filter.value;
        existingType.display = filter.display;
        existingType.data = filter.data;

        if (existingType['postDisplay']) {
          existingType['postDisplay'] = filter['postDisplay'];
        }
      }
    }

    this.updateSubscribers();
  }

  replaceFilter(replaceType: EFilterType, withFilter: IOpFilterBarFilter<EFilterType>) {
    const toReplace = this.currentFilters.filter(f => f.type === replaceType);
    let insertIndex = this.filters.length;
    toReplace.forEach((filter, index) => {
      const replaceIndex = this.filters.findIndex(f => f === filter);
      if (replaceIndex >= 0 && index === 0) {
        insertIndex = replaceIndex; // insert new filter at the same index as the first filter to be replaced
      }
      this.filters.splice(replaceIndex, 1);
    });
    this.filters.splice(insertIndex, 0, withFilter);
    this.updateSubscribers();
  }

  removeFilter(filter: IOpFilterBarFilter<EFilterType>) {
    const remove = this.filters.findIndex(f => JSON.stringify(f) === JSON.stringify(filter));
    if (remove >= 0) {
      this.filters.splice(remove, 1);
      this.updateSubscribers();
    }
  }

  removeFilterByType(filterType: EFilterType) {
    const toRemove = this.currentFilters.filter(f => f.type === filterType);

    toRemove.forEach(filter => {
      const remove = this.filters.findIndex(f => f === filter);
      if (remove >= 0) {
        this.filters.splice(remove, 1);
        this.updateSubscribers();
      }
    });
  }

  clear() {
    this.filters = [];
    this.updateSubscribers();
  }

  updateSupportedFiltersList(list: EFilterType[]): void {
    this.validTypes = list;
    this.validFilterTypesUpdated$.next(list);
  }

  updateSubscribers(): void {
    const uniqueFilters = this.createUniqueArrayOfFilters(this.filters);
    this.anyFiltersUpdates$.next(uniqueFilters);
    this.updateStorage(uniqueFilters);
  }

  updateStorage(uniqueFilters: IOpFilterBarFilter<EFilterType>[]) {
    if (this.storeFiltersInStorage && this.storageKey) {
      OpFilterBarService.setFiltersInStorage(this.storage, this.storageKey, uniqueFilters);
    }
  }

  static setFiltersInStorage(
    storage: StorageService,
    storageKey: string,
    filters: IOpFilterBarFilter<any>[] // any because generic parameters are not available in static methods
  ) {
    storage.setValue(storageKey, filters, StorageType.Local);
  }

  protected createUniqueArrayOfFilters(filters: IOpFilterBarFilter<EFilterType>[]): IOpFilterBarFilter<EFilterType>[] {
    if (!filters || !filters.length) return [];

    // Unique filters
    const filterStringsToObjects = filters.reduce<{[key: string]: IOpFilterBarFilter<EFilterType>}>((acc, filter) => {
      acc[JSON.stringify(filter)] = filter;
      return acc;
    }, {});

    return Object.values(filterStringsToObjects);
  }

  // This method should be overwritten if searchable value needs to be validated
  searchableValueValidator(value: string, regexpFlag: boolean): Promise<boolean> {
    return Promise.resolve(true);
  }

}
