import { RouteBuilder } from '../shared/services/route-builder.service';

export const EmailInboxesStateNames = {
  base: 'email_inboxes',
  messages: 'messages'
};

export const EmailInboxesPaths = {
  base: 'email-inboxes'
};

export const EmailInboxesUrlBuilders = {
  base: () => RouteBuilder.buildUrl([EmailInboxesPaths.base])
};

export const EmailInboxesUIConstants = {
  table: {
    name: 'Inbox Name',
    emailAddress: 'Inbox Email Address',
    lastReceivedMessage: 'Last Received Message',
    notes: 'Notes',
    labels: 'Labels',
    testScenariosCount: 'Associated Audits',
    messagesReceived: 'Messages Received',
    duplicateInbox: 'Duplicate Inbox',
    changeLog: 'View Change Log',
    delete: 'Delete',
    edit: 'Edit'
  },
  zeroState: {
    createNewInbox: 'Create new inbox',
    body: [
      'A default inbox has been configured in your account. Use this inbox for easy testing of emails containing links that require validatino.',
      'or',
      'Create a  new  inbox to generate and apply one or more audits (test criteria) to get validating today!',
    ]
  }
};

export enum EEmailInboxAlertStatus {
  GOOD = 'GOOD',
  BAD = 'BAD',
  UNCONFIGURED = 'UNCONFIGURED',
}

export enum EEmailInboxMessageStatus {
  'RECEIVED',
  'PROCESSING',
  'AUDITING',
  'NOT_AUDITING',
  'NOTIFYING',
  'COMPLETE',
  'FAILED',
}
