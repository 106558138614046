import { Component, HostBinding, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  EOpButtonMenuTypes,
  MenuItems
} from '@app/components/shared/components/op-button-2021/op-button-2021.component';
import { UsageV2ApiService } from '@app/components/usage-v2/usage-v2-api.service';
import {
  EUsageExportType,
  IAccountUsageV2SummaryResponseDTO,
  IAuditUsage,
  IDailyUsageV2,
  ISupportContact,
  IUsageContractTerm,
  IUsageRequestDTO,
  IWebJourneyUsage,
  IExportResponse
} from '@app/components/usage-v2/usage-v2.models';
import { SupportContactData, USAGE_OVERAGES_POPOVER } from '@app/components/usage-v2/usage-v2.constants';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import {
  IAuditReportCard,
  IWebJourneyReportCard
} from '@app/components/manage/cards/report-card-list/report-card-list.models';
import {
  ReportCardsApiService
} from '@app/components/manage/shared/services/report-cards-api/report-cards-api.service';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Features } from '@app/moonbeamConstants';
import {
  EUsageTabTitleChartTypes
} from '@app/components/usage-v2/components/usage-tab-title-chart/usage-tab-title.enums';
import { AccountsService } from '@app/components/account/account.service';
import { IUser } from '@app/moonbeamModels';
import { debounceTime, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { StorageService } from '@app/components/shared/services/storage.service';
import {
  UsageChartWithTableComponent
} from '@app/components/usage-v2/components/usage-chart-with-table/usage-chart-with-table.component';
import {
  UsageWebJourneySummaryChartComponent
} from '@app/components/usage-v2/components/usage-webjourney-summary/usage-webjourney-summary-chart.component';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EUsageFilterTypes } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.enums';
import { UsageFilterBarService } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.service';
import { UsageV2Service } from '@app/components/usage-v2/usage-v2.service';
import { OpModalService } from '../shared/components/op-modal';
import { UsageOveragesModalComponent } from './components/usage-overages-modal/usage-overages-modal.component';
import { EUsageOveragesModalType } from './components/usage-overages-modal/usage-overages-modal.constants';
import { ExportReportModalComponent } from '../shared/components/export-report/export-report-modal/export-report-modal.component';
import { ExportModal } from '../shared/components/export-report/export-report-modal/export-report-modal.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-v2',
  templateUrl: './usage-v2.component.html',
  styleUrls: ['./usage-v2.component.scss']
})
export class UsageV2Component implements OnInit, OnDestroy {
  EUsageTabTitleChartTypes = EUsageTabTitleChartTypes;
  EUsageOveragesModalType = EUsageOveragesModalType;
  USAGE_OVERAGES_POPOVER = USAGE_OVERAGES_POPOVER;

  @ViewChildren(UsageChartWithTableComponent) usageChartWithTableComponent: QueryList<UsageChartWithTableComponent>;
  @ViewChild(UsageWebJourneySummaryChartComponent) usageWebJourneySummaryChartComponent: UsageWebJourneySummaryChartComponent;
  @HostBinding('class') className = 'usage-page';

  isLoggedInAsAnother: boolean = true;
  infoIsExpanded = false;

  exportsMenu: MenuItems[] = [
    {
      label: 'Export Usage Information As Emailed .CSV File',
      type: EOpButtonMenuTypes.Header
    },
    {
      icon: 'attach_email',
      label: 'Export All Audit Items',
      type: EOpButtonMenuTypes.Button,
      action: () => {
        this.usageApiService.sendReportToEmail(EUsageExportType.AuditRuns, this.auditUsage?.currentContractTerm)
          .subscribe(
            (exportResponse: IExportResponse) => {
              if(exportResponse && exportResponse.exportId) {
                this.showExportProgress(exportResponse.exportId);
              } else {
                this.usageV2Service.showExportErrorSnackbar();
              }
            },
            (error) => {
              this.usageV2Service.showExportErrorSnackbar();
            }
          );
      }
    },
    {
      icon: 'attach_email',
      label: 'Export All Audit Items as Filtered',
      type: EOpButtonMenuTypes.Button,
      action: () => {
        this.usageApiService.sendReportToEmail(EUsageExportType.AuditRuns, this.auditUsage?.currentContractTerm, this.filters)
          .subscribe(
            (exportResponse: IExportResponse) => {
              if(exportResponse && exportResponse.exportId) {
                this.showExportProgress(exportResponse.exportId);
              } else {
                this.usageV2Service.showExportErrorSnackbar();
              }
            },
            (error) => {
              this.usageV2Service.showExportErrorSnackbar();
            }
          );
      }
    },
    {
      type: EOpButtonMenuTypes.Hr
    },
    {
      icon: 'attach_email',
      label: 'Export All Journey Items',
      type: EOpButtonMenuTypes.Button,
      action: () => {
        this.usageApiService.sendReportToEmail(EUsageExportType.WebJourneyRuns, this.webJourneyUsage?.currentContractTerm)
          .subscribe(
            (exportResponse: IExportResponse) => {
              if(exportResponse && exportResponse.exportId) {
                this.showExportProgress(exportResponse.exportId);
              } else {
                this.usageV2Service.showExportErrorSnackbar();
              }
            },
            (error) => {
              this.usageV2Service.showExportErrorSnackbar();
            }
          );
      }
    },
    {
      icon: 'attach_email',
      label: 'Export Journey Items as Filtered',
      type: EOpButtonMenuTypes.Button,
      action: () => {
        this.usageApiService.sendReportToEmail(EUsageExportType.WebJourneyRuns, this.webJourneyUsage?.currentContractTerm, this.filters)
          .subscribe(
            (exportResponse: IExportResponse) => {
              if(exportResponse && exportResponse.exportId) {
                this.showExportProgress(exportResponse.exportId);
              } else {
                this.usageV2Service.showExportErrorSnackbar();
              }
            },
            (error) => {
              this.usageV2Service.showExportErrorSnackbar();
            }
          );
      }
    }
  ];
  canViewAllowedOverages: boolean = false;
  isAllowedOverages = true;
  labels: ILabel[];
  audits: IAuditReportCard[];
  webJourneys: IWebJourneyReportCard[];
  isWebJourneyAllowed: boolean;
  accountId: number;
  user: IUser;
  isFiltered = false;

  summary: IAccountUsageV2SummaryResponseDTO;

  activeTab: EUsageTabTitleChartTypes = EUsageTabTitleChartTypes.Audit;

  auditUsage: IAuditUsage;
  auditDailyTrends: IDailyUsageV2[];

  webJourneyUsage?: IWebJourneyUsage;
  webJourneyDailyTrends: IDailyUsageV2[];

  currentFilters: IOpFilterBarV2Filter<EUsageFilterTypes>[];
  private onFiltersChanged$ = new BehaviorSubject<IOpFilterBarV2Filter<EUsageFilterTypes>[]>([]);
  get onFiltersChanged() { return this.onFiltersChanged$.pipe(debounceTime(500)); }

  onAuditTermChanged$ = new BehaviorSubject<IUsageContractTerm>(null);
  get onAuditTermChanged() { return this.onAuditTermChanged$.pipe(debounceTime(500)); }

  onWebJourneyTermChanged$ = new BehaviorSubject<IUsageContractTerm>(null);
  get onWebJourneyTermChanged() { return this.onWebJourneyTermChanged$.pipe(debounceTime(500)); }

  private destroySubject = new Subject<void>();
  filters: IUsageRequestDTO;

  constructor(
    private usageApiService: UsageV2ApiService,
    private labelsService: LabelService,
    private reportCardsService: ReportCardsApiService,
    private authenticationService: AuthenticationService,
    private accountsService: AccountsService,
    private applicationChromeService: ApplicationChromeService,
    private storageService: StorageService,
    private usageFilterBarService: UsageFilterBarService,
    private usageV2Service: UsageV2Service,
    private modalService: OpModalService
    ) {
  }

  ngOnInit(): void {
    this.isLoggedInAsAnother = this.storageService.isLoggedInAsAnother();

    this.usageFilterBarService.updateSupportedFiltersList([
      EUsageFilterTypes.Audits,
      EUsageFilterTypes.WebJourney,
      EUsageFilterTypes.Frequency,
      EUsageFilterTypes.Folder,
      EUsageFilterTypes.Creator,
      EUsageFilterTypes.Label,
    ]);

    forkJoin([
      this.reportCardsService.getAudits(),
      this.authenticationService.isFeatureAllowed(Features.webJourneys)
        .pipe(
          tap<boolean>((isWebJourneyAllowed: boolean) => this.isWebJourneyAllowed = isWebJourneyAllowed),
          switchMap(isAllowed => isAllowed ? this.reportCardsService.getWebJourneys() : [])
        ),
      this.labelsService.getLabels()
    ])
    .subscribe(([audits, webJourneys, labels]) => {
      this.audits = audits;
      this.webJourneys = webJourneys;
      this.labels = labels;
    });

    this.initAllowedOverages();

    this.loadNonFilterableData();

    this.onFiltersChanged
      .pipe(takeUntil(this.destroySubject))
      .subscribe(filters => {
        this.isFiltered = !!filters.length;
        this.currentFilters = filters;
        this.filters = this.usageFilterBarService.generateApiPostBody(filters);
        this.loadAuditUsage(this.filters, this.auditUsage?.currentContractTerm);
        this.loadWebJourneyUsage(this.filters, this.webJourneyUsage?.currentContractTerm);
      });

    this.onAuditTermChanged
      .pipe(takeUntil(this.destroySubject))
      .subscribe(term => {
        this.loadAuditUsage(this.filters || {}, term);
      });

    this.onWebJourneyTermChanged
      .pipe(takeUntil(this.destroySubject))
      .subscribe(term => {
        this.loadWebJourneyUsage(this.filters || {}, term);
      });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private loadAuditUsage(filters: IUsageRequestDTO, selectedContractTerm?: IUsageContractTerm) {
    this.usageApiService.getUsageSummaryAudit(filters, selectedContractTerm)
      .subscribe(auditUsage => {
        if (!this.auditUsage) {
          this.onAuditTermChanged$.next(auditUsage.currentContractTerm);
        }
        this.auditUsage = auditUsage;
      });
  }

  private loadWebJourneyUsage(filters: IUsageRequestDTO, selectedContractTerm?: IUsageContractTerm) {
    this.usageApiService.getUsageSummaryWebJourney(filters, selectedContractTerm)
      .subscribe(data => {
        if (!this.webJourneyUsage && data.webJourneyUsage) {
          this.onWebJourneyTermChanged$.next(data.webJourneyUsage.currentContractTerm);
        }
        this.webJourneyUsage = data.webJourneyUsage;
        this.isWebJourneyAllowed = data.isWebJourneyFeatureEnabled;
      });
  }

  private loadNonFilterableData() {
    this.usageApiService.getUsageSummary().subscribe((summary) => {
      this.summary = {
        ...summary,
        contacts: this.enrichSupportContacts(summary.contacts)
      };
    });
    forkJoin([
      this.usageApiService.getDailyTrends(EUsageTabTitleChartTypes.Audit),
      this.usageApiService.getDailyTrends(EUsageTabTitleChartTypes.WebJourney),
    ]).subscribe(([auditTrends, webJourneyTrends]) => {
      this.auditDailyTrends = auditTrends.dailyUsageItems;
      this.webJourneyDailyTrends = webJourneyTrends.dailyUsageItems;
    });
  }

  initAllowedOverages(): void {
    this.applicationChromeService.accountPreview$
      .pipe(
        filter(accountPreview => !!accountPreview), take(1)
      )
      .subscribe(({ accountType }) => {
        this.canViewAllowedOverages = accountType !== EAccountType.FREETRIAL && accountType !== EAccountType.SAMPLE;

        if (this.canViewAllowedOverages) this.getAllowOverages();
      });
  }

  getAllowOverages(): void {
    this.accountsService.getUser().pipe(
      tap(user => this.user = user),
      switchMap(user => this.accountsService.getAccountById(this.user?.accountId))
    )
      .subscribe((account) => this.isAllowedOverages = account.allowOverages);
  }

  updateAllowOverages(): void {
    if (this.isLoggedInAsAnother) {
      return;
    }
    this.modalService.openModal(UsageOveragesModalComponent, { data: {
      modalType: this.isAllowedOverages ? EUsageOveragesModalType.Prevent : EUsageOveragesModalType.Allow
    }})
    .afterClosed()
    .subscribe((confirmed: boolean) => {
      if (this.isLoggedInAsAnother || !confirmed) return;
      this.isAllowedOverages = !this.isAllowedOverages;
      this.accountsService.resetAccountCache();
      this.accountsService.updateAccountOverages(this.user?.accountId, this.isAllowedOverages).subscribe(
        () => {},
        err => {
          console.error('Error updating overages', err);
        }
      );
    });
  }

  private enrichSupportContacts(contacts: ISupportContact[]): ISupportContact[] {
    return contacts.map(contact => ({
      ...contact,
      imageUrl: this.getContactImageUrl(contact.email),
      title: SupportContactData[contact.type].title,
      description: SupportContactData[contact.type].description,
    }));
  }

  filterChanged($event: IOpFilterBarV2Filter<EUsageFilterTypes>[]) {
    this.onFiltersChanged$.next($event);
  }

  private getContactImageUrl(email: string): string {
    return `https://observepoint-moonbeam-prod.s3.us-east-1.amazonaws.com/revenue_team_photos/${email.replace(/@.*/, '')}.png`;
  }

  tabChanged($event: EUsageTabTitleChartTypes) {
    this.activeTab = $event;
  }

  exportThisReport() {
    this.usageApiService.sendReportToEmail(
      this.activeTab === EUsageTabTitleChartTypes.Audit ? EUsageExportType.AuditRuns : EUsageExportType.WebJourneyRuns,
      this.activeTab === EUsageTabTitleChartTypes.Audit ?  this.auditUsage?.currentContractTerm : this.webJourneyUsage?.currentContractTerm
    )
      .subscribe(
        () => this.usageV2Service.showExportSuccessSnackbar(),
        () => this.usageV2Service.showExportErrorSnackbar()
      );
  }

  protected showExportProgress(exportId: number) {
    const message = this.user.email;
    this.modalService.openModal(ExportReportModalComponent, {
        width: ExportModal.width,
        height: ExportModal.height,
        disableClose: true,
        data: {
          message: message,
          reportItem: {},
          exportId: exportId,
        }
      }, 'exported-vendors-modal');
  }
}
