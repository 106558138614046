import {AngularNames, Names} from '@app/moonbeamConstants';
import * as angular from 'angular';

/**
 * @ngdoc directive
 * @name moonbeam.directive:ShadeWindowDataDirective
 * @restrict A
 *
 * @description
 * This directive is used for opening shade modal inside another modal.
 * It takes received value from another directive (ShadeWindowDirective) and inserts into html at compile phase.
 * Also this directive adds ng-class to modal-dialog.
 *
 */


interface IShadeWindowAttributes extends angular.IAttributes {
    shadeWindowData: string;
  }

  export class ShadeWindowDataDirective implements angular.IDirective {

    template = require('@app/components/ui/shadeWindow/shadeWindow.html');
    restrict = 'A';

    controller = Names.Controllers.shadeWindowData;
    controllerAs = 'directiveCtrl';

    compile = (element: JQuery, attrs: IShadeWindowAttributes) => {
      var modalDialog = element.find('.modal-dialog');
      modalDialog.attr('ng-class', "{'open': directiveCtrl.shadeWindowDataService.showShadeModal}");

      var modalContent = element.find('.modal-content');
      modalContent.attr('ng-controller', (attrs.shadeWindowData + ' as ctrl'));

      var background = element.find('.modal-shade-background');
      background.attr('ng-class', "{'open': directiveCtrl.shadeWindowDataService.showShadeModal}");
    }

    static factory(): angular.IDirectiveFactory {
      const directive = () => {
        return new ShadeWindowDataDirective();
      };
      directive.$inject = [
        AngularNames.compile,
        AngularNames.timeout
      ];
      return directive;
    };

    constructor() { };
  }

