import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUseCaseTabs } from '../use-cases.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'use-case-tabs',
  templateUrl: './use-case-tabs.component.html',
  styleUrls: ['./use-case-tabs.component.scss']
})
export class UseCaseTabsComponent {

  @Input() tabs: IUseCaseTabs[] = [];
  @Input() activeTab: string;
  @Output() onActiveTabChange: EventEmitter<string> = new EventEmitter<string>();

  setActiveTab(tab: string): void {
    this.activeTab = tab;
    this.onActiveTabChange.emit(tab);
  }
}
