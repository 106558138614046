import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAuditReportPageDetailsDrawerService } from '../../audit-report/audit-report-page-details-drawer.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-details-wrapper',
  templateUrl: './page-details-wrapper.component.html',
  styleUrls: ['./page-details-wrapper.component.scss']
})
export class PageDetailsWrapperComponent implements OnInit, OnDestroy {

  drawerIsOpen: boolean = false;
  onDestroy$: Subject<void> = new Subject();

  constructor(
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService
  ) {}

  ngOnInit() {
    this.pageDetailsDrawerService.pageDrawerClosed$.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.drawerIsOpen = false);
    this.pageDetailsDrawerService.pageDrawerOpenedOrUpdated$.pipe(takeUntil(this.onDestroy$))
      .subscribe((page: any) => { 
        if (page) { this.drawerIsOpen = true; }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  handleMatDrawerOpenedChanged(open: boolean) {
    if (!open && this.drawerIsOpen) {
      this.pageDetailsDrawerService.closePageDetails();
    }
  }
}
