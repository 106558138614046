import { Component, HostBinding, Input } from '@angular/core';

export interface INewDataOption {
  type: string;
  icon: string;
  onClick: Function;
  disabled?: boolean;
  disabledReason?: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'new-data-option',
  templateUrl: './new-data-option.component.html',
  styleUrls: ['./new-data-option.component.scss']
})
export class NewDataOptionComponent {

  private _option: INewDataOption;
  
  @Input() set option(value: INewDataOption) {
    this._option = value;
    this.disabled = value.disabled;
  }
  get option(): INewDataOption {
    return this._option;
  }
  
  @HostBinding('class.disabled') disabled = false;

}
