<op-modal (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="modalTitle"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="summary-history-preview-container">
      <div class="date-range-buttons-container">
        <op-date-range-buttons
          [loading]="loading"
          [dateRange]="dateRange"
          [greaterThanOneMonthDisabled]="greaterThanOneMonthDisabled"
          [greaterThanThreeMonthsDisabled]="greaterThanThreeMonthsDisabled"
          [greaterThanSixMonthsDisabled]="greaterThanSixMonthsDisabled"
          [greaterThanNineMonthsDisabled]="greaterThanNineMonthsDisabled"
          [greaterThanThirteenMonthsDisabled]="greaterThanThirteenMonthsDisabled"
          (dateRangeChange)="getDataForDateRange($event)">
        </op-date-range-buttons>
      </div>

      <ng-container *ngIf="loading; else chart">
        <div class="spinner-container">
          <mat-spinner [diameter]="40" [strokeWidth]="3"></mat-spinner>
        </div>
      </ng-container>

      <ng-template #chart>
        <alert-preview-chart [class.dimmed]="loading"
                             [data]="bars"
                             [uniqueIdentifier]="uniqueIdentifier"
                             [showYAxis]="false">
        </alert-preview-chart>
      </ng-template>
    </div>
  </ng-template>
</op-modal>
