import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IAuditPageInfoWebVitalsSummaryItem } from '@app/components/audit-reports/page-details/page-details.models';
import { ReplaySubject, Subject } from 'rxjs';
import { EWebVitalsMetricType, EWebVitalsWidgetStatus, WEB_VITALS_METRIC_NAMES, WEB_VITALS_TOOLTIPS, WEB_VITALS_WIDGET_TOOLTIPS } from './web-vitals-chart.constants';
import { ThemeService } from '@app/services/theme-service/theme.service';
import { takeUntil } from 'rxjs/operators';
import { trimDecimals } from '@app/components/utilities/number.utils';

@Component({
  selector: 'op-web-vitals-chart',
  templateUrl: './web-vitals-chart.component.html',
  styleUrls: ['./web-vitals-chart.component.scss']
})
export class WebVitalsChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  // required inputs
  @Input() metricType: EWebVitalsMetricType;
  @Input() data: IAuditPageInfoWebVitalsSummaryItem;
  @Input() webVitalsAvailable: boolean;
  @Input() loading: boolean;
  @Input() showTitle: boolean = true;
  @Input() displayValueFractionDigits: number = 3;
  @Input() displayValueUnits: string = 'sec';
  @Input() showLeftThresholdValue: boolean = true;
  @Input() showRightThresholdValue: boolean = true;
  @Input() displayValueFollowsCircle: boolean = false;
  @Input() biggerCircle: boolean = false;

  // optional inputs
  @Input() backgroundColorLt: string = '#F2F2F2'; // used for "cut-out" border around circle
  @Input() backgroundColorDk: string = '#3E3E3E'; // used for "cut-out" border around circle

  // gets the circle element so we can dynamically set the css left percentage
  @ViewChild('circle', { read: ElementRef }) circle: ElementRef;
  @ViewChild('valueLabel', { read: ElementRef }) valueLabel: ElementRef;

  warnThreshold: number;
  failThreshold: number;
  rightThreshold: number;
  greenBarWidth: number;
  yellowBarWidth: number;
  redBarWidth: number;
  displayValue: string | number;
  borderColor: string;
  status: EWebVitalsWidgetStatus;
  tooltip: string;

  EWebVitalsWidgetStatus = EWebVitalsWidgetStatus;
  WEB_VITALS_METRIC_NAMES = WEB_VITALS_METRIC_NAMES;
  WEB_VITALS_WIDGET_TOOLTIPS = WEB_VITALS_WIDGET_TOOLTIPS;

  private destroy = new Subject();
  private dataPointPosition = new ReplaySubject<number>();

  constructor(private themeService: ThemeService) { }

  ngOnInit(): void {
    this.initListeners();
  }

  ngOnChanges(): void {
    if (!this.loading) {
      this.handleStatusAndTooltip();
      this.handleValueAndThresholds();
    }
  }

  ngAfterViewInit(): void {
    this.dataPointPosition.asObservable().pipe(takeUntil(this.destroy)).subscribe((position: number) => {
      setTimeout(() => {
        if (!this.circle) return;
        this.circle.nativeElement.style.left = `${position}%`;
        if (this.displayValueFollowsCircle) {
          this.valueLabel.nativeElement.style.left = `${position}%`;
        }
      }, 500);
    });
  }

  ngOnDestroy(): void {
    this.destroy.next();
  }

  private initListeners(): void {
    this.themeService.isDarkTheme.pipe(takeUntil(this.destroy)).subscribe((isDarkTheme: boolean) => {
      this.borderColor = isDarkTheme ? this.backgroundColorDk : this.backgroundColorLt;
    });
  }

  private handleStatusAndTooltip(): void {
    if (!this.webVitalsAvailable) {
      this.status = EWebVitalsWidgetStatus.NotAvailable;
      this.tooltip = WEB_VITALS_TOOLTIPS.NOT_AVAILABLE;
      return;
    }

    if (this.data?.value === undefined || this.data?.value === null) {
      this.status = EWebVitalsWidgetStatus.NoData;
      this.tooltip = WEB_VITALS_TOOLTIPS.NO_DATA;
      return;
    }

    if (this.data?.value < this.data?.warnThreshold) {
      this.status = EWebVitalsWidgetStatus.Pass;
    } else if (this.data?.value < this.data?.failThreshold) {
      this.status = EWebVitalsWidgetStatus.Warn;
    } else {
      this.status = EWebVitalsWidgetStatus.Fail;
    }
  }

  private convertFromMsToS(milliseconds: number): number {
    return milliseconds / 1000;
  }

  private getRightThresholdValue(): number {
    const firstDiff = this.data?.warnThreshold;
    const secondDiff = this.data?.failThreshold - this.data?.warnThreshold;
    const average = (firstDiff + secondDiff) / 2;

    return this.data?.failThreshold + average;
  }

  private handleValueAndThresholds(): void {
    switch (this.metricType) {
      case EWebVitalsMetricType.CumulativeLayoutShift:
        this.handleDataForCls();
        break;

      case EWebVitalsMetricType.TimeToFirstByte:
        this.handleDataForTtfb();
        break;

      case EWebVitalsMetricType.FirstContentfulPaint:
      case EWebVitalsMetricType.LargestContentfulPaint:
        this.handleDataForFcpAndLcp();
        break;
    }
  }

  private handleDataForCls(): void {
    // display value
    this.displayValue = trimDecimals(this.data?.value ?? 0, this.displayValueFractionDigits);

    // warn and failure thresholds
    this.warnThreshold = this.data?.warnThreshold;
    this.failThreshold = this.data?.failThreshold;

    // right threshold value
    this.rightThreshold = +this.getRightThresholdValue()?.toFixed(3);
    this.rightThreshold = this.rightThreshold > +this.displayValue ? this.rightThreshold : +this.data.value?.toFixed(1) + 0.1;
    this.rightThreshold = +this.rightThreshold.toFixed(3);

    // bar sizes
    this.greenBarWidth = Math.ceil((this.warnThreshold / this.rightThreshold) * 100);
    this.yellowBarWidth = Math.ceil(((this.failThreshold - this.warnThreshold) / this.rightThreshold) * 100);
    this.redBarWidth = 100 - this.greenBarWidth - this.yellowBarWidth;

    // calculate percentage
    const percentage = (+this.displayValue / this.rightThreshold) * 100;
    this.dataPointPosition.next(percentage);
  }

  private handleDataForTtfb(): void {
    // display value
    this.displayValue = `${(+this.data?.value?.toFixed(0)).toLocaleString()} ms`;

    // raw value
    const value = +this.data?.value?.toFixed(0);

    // warn and failure thresholds
    this.warnThreshold = this.data?.warnThreshold;
    this.failThreshold = this.data?.failThreshold;

    // right threshold value
    this.rightThreshold = +this.getRightThresholdValue()?.toFixed(0);
    this.rightThreshold = this.rightThreshold > value ? this.rightThreshold : Math.ceil(value / 1000) * 1000;
    this.rightThreshold = +this.rightThreshold.toFixed(3);

    // bar sizes
    this.greenBarWidth = Math.ceil((this.warnThreshold / this.rightThreshold) * 100);
    this.yellowBarWidth = Math.ceil(((this.failThreshold - this.warnThreshold) / this.rightThreshold) * 100);
    this.redBarWidth = 100 - this.greenBarWidth - this.yellowBarWidth;

    // calculate percentage
    const percentage = (value / this.rightThreshold) * 100;
    this.dataPointPosition.next(percentage);
  }

  private handleDataForFcpAndLcp(): void {
    // display value
    this.displayValue = `${this.convertFromMsToS(this.data?.value).toFixed(this.displayValueFractionDigits)} ${this.displayValueUnits}`.trim();

    // raw value
    const value = this.convertFromMsToS(this.data?.value);

    if (this.webVitalsAvailable) {
      // warn and failure thresholds
      this.warnThreshold = this.convertFromMsToS(this.data?.warnThreshold);
      this.failThreshold = this.convertFromMsToS(this.data?.failThreshold);

      // right threshold value
      this.rightThreshold = +this.convertFromMsToS(this.getRightThresholdValue())?.toFixed(3);
      this.rightThreshold = this.rightThreshold > value ? this.rightThreshold : Math.ceil(value);
      this.rightThreshold = +this.rightThreshold.toFixed(3);

      // bar sizes
      this.greenBarWidth = Math.ceil((this.warnThreshold / this.rightThreshold) * 100);
      this.yellowBarWidth = Math.ceil(((this.failThreshold - this.warnThreshold) / this.rightThreshold) * 100);
      this.redBarWidth = 100 - this.greenBarWidth - this.yellowBarWidth;
    }

    // calculate percentage
    const percentage = (value / this.rightThreshold) * 100;
    this.dataPointPosition.next(percentage);
  }
}
