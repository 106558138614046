import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const TagDatabaseStateNames = {
  base: 'tag_database'
};

export const TagDatabasePaths = {
  base: 'tag-database'
};

export const TagDatabaseUrlBuilders = {
  base: () => RouteBuilder.buildUrl([TagDatabasePaths.base])
};
