import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IUserEvent, IUserEventsDTO, UserEventsService } from '@app/components/userEvents/userEventsService';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IUser } from '@app/moonbeamModels';
import { AccountsService } from '@app/components/account/account.service';
import { EventTypes, ItemTypes } from './event-log.constants';
import { HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DateService, EDateFormats } from '@app/components/date/date.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss']
})
export class EventLogComponent implements OnInit, AfterViewInit {

  loading = true;
  events: IUserEvent[];
  displayedColumns: string[] = ['itemType', 'userId', 'username', 'eventType', 'itemId', 'itemName', 'timestamp'];
  dataSource = new MatTableDataSource;
  users: IUser[];

  nextPage = false;
  pageIndex = 0;

  eventTypes = EventTypes;
  itemTypes = ItemTypes;

  user: string;
  username: string;
  eventType: string;
  itemType = 'audit';
  itemId: string;
  from: Date;
  to: Date;

  state$: Observable<any>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('scrollTopUserEvents', { static: false }) scrollTopUserEvents: ElementRef;

  constructor(
    private userEventsService: UserEventsService,
    private accountsService: AccountsService,
    private activatedRoute: ActivatedRoute,
    private dateService: DateService,
  ) { }

  ngOnInit() {
    this.getUsers();

    this.state$ = this.activatedRoute.paramMap
      .pipe(map(() => window.history.state));

    this.state$.subscribe(state => {
      if (state.itemType && state.itemId) {
        this.itemType = state.itemType;
        this.itemId = state.itemId;
        this.getFilteredEvents();
      } else {
        this.getEvents();
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource
      .sort
      .sortChange
      .subscribe(() => this.getFilteredEvents());
  }

  getEvents() {
    this.userEventsService
      .getUserEvents(`?itemType=audit`, {
        sortBy: this.getActiveSort(),
        sortDesc: this.sort.direction === 'desc'
      })
      .then((eventsDTO: IUserEventsDTO) => {
        this.nextPage = eventsDTO.nextPage;
        this.events = eventsDTO.userEvents;

        this.initTableData(this.events);
      });
  }

  initTableData(data: IUserEvent[] = []) {
    this.dataSource.data = data.map((event: IUserEvent) => {
      return {
        ...event,
        itemType: event.itemType,
        timestamp: this.dateService.formatDate(new Date(event.eventTimeStamp), EDateFormats.dateTwentyThree),
      };
    });

    this.loading = false;
  }

  getUsers() {
    this.accountsService.getUsers().subscribe((users: IUser[]) => {
      this.users = users.sort((a: any, b: any) => (a.firstName.toLowerCase() > b.firstName.toLowerCase()) ? 1 : -1);
    });
  }

  createQueryStringParams() {
    let queryParams = new HttpParams();

    if (this.user) queryParams = queryParams.set('userId', this.user);
    if (this.eventType) queryParams = queryParams.set('eventType', this.eventType);
    if (this.itemType) queryParams = queryParams.set('itemType', this.itemType);
    if (this.itemId) queryParams = queryParams.set('itemId', this.itemId);
    if (this.from) queryParams = queryParams.set('fromTimeStamp', this.dateService.isoWithoutTimezone(this.from));
    if (this.to) queryParams = queryParams.set('toTimeStamp', this.dateService.isoWithoutTimezone(this.to));

    const queryParamsString = queryParams.toString();

    return `?page=${this.pageIndex}${queryParamsString.length > 0 ? '&' : ''}${queryParamsString}`;
  }

  getFilteredEvents(resetPageIndex: boolean = true) {
    // Reset pagination and load first page if not paging existing data
    if (resetPageIndex) this.pageIndex = 0;

    this.loading = true;
    let params = this.createQueryStringParams();

    this.userEventsService.getUserEvents(params, {
      sortBy: this.getActiveSort(),
      sortDesc: this.sort.direction === 'desc'
    }).then((eventsDTO: IUserEventsDTO) => {
      this.nextPage = eventsDTO.nextPage;
      this.initTableData(eventsDTO.userEvents);
    });
  }

  clearDateField(field: string) {
    (field === 'from') ? this.from = null : this.to = null;
  }

  loadPreviousPage() {
    if (this.pageIndex === 0) return;

    this.pageIndex--;
    this.getFilteredEvents(false);
    this.scrollToTopOfUserEvents();
  }

  loadNextPage() {
    if (!this.nextPage) return;

    this.pageIndex++;
    this.getFilteredEvents(false);
    this.scrollToTopOfUserEvents();
  }

  scrollToTopOfUserEvents() {
    this.scrollTopUserEvents.nativeElement.scrollTop = 0;
  }

  getActiveSort(): string {
    switch (this.sort.active) {
      case 'timestamp': return 'eventTimeStamp';
      case 'username': return 'userName';
      default: return this.sort.active;
    }
  }
}
