import { Injectable, OnDestroy } from '@angular/core';
import { IPageListPage } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { PageDetailsComponent } from '../page-details/page-details.component';
import { EPageDetailsTabs } from '../page-details/page-details.constants';
import { StorageService } from '@app/components/shared/services/storage.service';
import { PAGE_DETAILS_FULL_SCREEN_KEY } from './audit-report.constants';
import { IAuditReportPageDetailsDrawerService } from './audit-report-page-details-drawer.models';
import { MatDialogRef } from '@angular/material/dialog';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { fromEvent, Observable, Subject } from 'rxjs';

export interface IOpenPage {
  page: IPageListPage;
  tab?: EPageDetailsTabs;
  state?: any;
  updateActiveTab?: boolean;
}

@Injectable()
export class AuditReportPageDetailsDrawerService extends IAuditReportPageDetailsDrawerService implements OnDestroy {

  private modalIndex: number;
  private defaultPageDetailsTab: EPageDetailsTabs = EPageDetailsTabs.PageInformation;
  private isFullScreen: boolean;
  private modalReference: MatDialogRef<PageDetailsComponent>;
  private resize$: Observable<any>;
  private destroy$ = new Subject();
  mobileView: boolean = false;

  constructor(
    protected modalEscapeService: ModalEscapeService,
    private opModalService: OpModalService,
    private storageService: StorageService
  ) {
    super();

    this.mobileView = window.innerWidth < 1000;

    this.resize$ = fromEvent(window, 'resize');
    this.resize$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.mobileView = window.innerWidth < 1000;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setDefaultPageDetailsTab(defaultTab: EPageDetailsTabs) {
    this.defaultPageDetailsTab = defaultTab;
  }

  openPageDetails(page: IPageListPage, auditId: number, runId: number, tab?: EPageDetailsTabs, fullScreen?: boolean, state?: any, updateActiveTab?: boolean) {
    if (!this.modalIndex) {
      this.modalIndex = this.modalEscapeService.getLast() + 1;
      this.modalEscapeService.add(this.modalIndex);
    }

    if (fullScreen || this.mobileView) {
      this.storageService.setValue(PAGE_DETAILS_FULL_SCREEN_KEY, true);
    }

    const userPrefersFullScreen = this.storageService.getValue(PAGE_DETAILS_FULL_SCREEN_KEY);

    // open in full screen if argument is sent, user preference is set to this, or
    // if the user is on a view 1000px or less
    if (fullScreen || this.isFullScreen || userPrefersFullScreen || this.mobileView) {
      this.isFullScreen = true;
      this.closePageDetails();
      this.openPageDetailsFullScreen(page, auditId, runId, tab, state);
    }

    // if preference isn't set, or they prefer the drawer, check if full screen
    // button was clicked and then decide how to open page details
    else {
      this.isFullScreen = false;
      this.openPageDetailsInDrawer(page, tab, state, updateActiveTab);
    }
  }

  private openPageDetailsFullScreen(page: IPageListPage, auditId: number, runId: number, tab?: EPageDetailsTabs, state?: any): void {
    const previousPageDetails = this.modalReference;

    this.modalReference = this.opModalService.openFullscreenModal(PageDetailsComponent, {
      data: {
        pageId: page.id,
        pageUrl: page.url,
        auditId,
        runId,
        tab,
        state
      }
    });

    // close the old modal after opening the new one.
    // add a delay so we don't see the old one close.
    if (previousPageDetails) {
      setTimeout(() => {
        previousPageDetails.close();
      }, 1000);
    }

    this.modalReference
      .afterClosed()
      .subscribe(() => {
        this.isFullScreen = false;
      });
  }

  private openPageDetailsInDrawer(page: IPageListPage, tab?: EPageDetailsTabs, state?: any, updateActiveTab?: boolean): void {
    this.drawerOpen = true;
    this.openPageChanged$.next({
      page,
      tab: tab ? tab : this.defaultPageDetailsTab,
      state,
      updateActiveTab
    });
  }

  private removePageDetailsModalIndex(): void {
    this.modalEscapeService.remove(this.modalIndex);
    this.modalIndex = 0;
  }

  closePageDetails() {
    if (!this.isFullScreen) {
      this.removePageDetailsModalIndex();
    }

    // close drawer and reset fullscreen flag
    this.drawerOpen = false;
    this.isFullScreen = false;
    // notify of closed drawer
    this.pageClosed$.next();
    // reset open trigger so drawer doesn't accidentally open later
    this.openPageChanged$.next(null);
  }
}
