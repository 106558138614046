import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const RULE_LIBRARY_FILTERS_STORAGE_KEY = 'rules_filters';

export const RuleLibraryStateNames = {
  rules: 'rules',
  rulesLibrary: 'rules.library',
};

export const RuleLibraryPaths = {
  base: 'rules',
  library: 'library',
};

export const RuleLibraryUrlBuilders = {
  base: () => RouteBuilder.buildUrl([RuleLibraryPaths.base]),
  library: () => RouteBuilder.buildUrl([RuleLibraryPaths.base, RuleLibraryPaths.library]),
};
