import { IWebJourney } from '@app/components/web-journey/web-journey.models';
import { AngularNames, Names, startsWith } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { EDateFormats, IDateService } from '../../date/date.service';
import { IAuditRunSummary } from '../../domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { IAuditDataService } from '../../domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { IWebJourneyApiService } from '../../domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { IWebJourneyRun } from '../../domains/webJourneys/webJourneyDefinitions';
import { IAuditCardModel, IAuditModel } from '../../modals/modalData';
import { IWebsocketConnectionService } from '../../websockets/websocketConnectionService';
import { WebJourneyReportStateNames } from '@app/components/web-journey-report/web-journey-report.constants';
import { timer, Subject, Observable, of, from } from 'rxjs';
import { catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import {
  webJourneyIsFinished,
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.enum';
import {
  IWebJourneyRunProgress,
  IWebJourneyV3Status
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { AuditReportStateNames } from '@app/components/audit-reports/audit-report/audit-report.constants';

export class StatusBannerService {

  dateFormat: string = EDateFormats.dateFifteen; //Jul 06, 2016 7:00am
  private pauseStatus: boolean = false;
  progressSubscriptions: any[] = [];

  static $inject = [
    Names.Services.websocketConnection,
    Names.Services.auditData,
    Names.Services.webJourneyAPI,
    Names.Services.date,
    AngularNames.q,
    Names.Services.webJourneyV3Service
  ];

  private updateSubscription: Subject<void> ;
  private pauseStatusSub: Subject<boolean>;

  runStoppedSubject: Subject<void> = new Subject<void>();
  runStopped$: Observable<void> = this.runStoppedSubject.asObservable();

  constructor(private websocketConnection: IWebsocketConnectionService,
              private auditDataService: IAuditDataService,
              private webJourneyAPIService: IWebJourneyApiService,
              private dateService: IDateService,
              private $q,
              private webJourneyV3Service: WebJourneyV3Service
  ) {
    this.updateSubscription = new Subject<void>();
    this.pauseStatusSub = new Subject<boolean>();
  }

  public determineTypeLabel(type: StatusUpdateType): string {
    switch (type) {
      case StatusUpdateType.Audit:
        return 'audit';
      case StatusUpdateType.WebJourney:
        return 'web journey';
      default:
        return '';
    }
  }

  public addProgressSubscription(subscription): void {
    this.progressSubscriptions.push(subscription);
  }

  public reinitStore(auditId: number): void {
    this.auditDataService.reinitStore(auditId);
  }

  getUpdateSubscription(): Subject<void> {
    return this.updateSubscription;
  }

  updateStatusBanner() {
    this.updateSubscription.next();
  }

  public publishPauseStatus(pause: boolean) {
    this.pauseStatus = pause;
    this.pauseStatusSub.next(this.pauseStatus);
  }
  public getPauseStatus(): Observable<boolean> {
    return this.pauseStatusSub.asObservable();
  }
  destroyPauseStatusSubscription(): void {
    this.pauseStatusSub.unsubscribe();
  }

  //WebJourney
  public getWebJourneyStatusProgress(journeyId: number, runId: number): Observable<IWebJourneyRunProgress> {
    return this.webJourneyV3Service.getJourneyRunStatus(journeyId, runId);
  }

  public getWebJourneyNextCheckDate(journeyId: number): angular.IPromise<string>  {
    return this.webJourneyAPIService.getJourney(journeyId).then((webJourey: IWebJourney) => {
      if (!webJourey) return null;
      return webJourey.nextCheck ? this.dateService.formatDate(new Date(webJourey.nextCheck), this.dateFormat) : null;
    });
  }

  public getWebJourney(journeyId: number): angular.IPromise<IWebJourney>  {
    return this.webJourneyAPIService.getJourney(journeyId).then((webJourey: IWebJourney) => {
      if (!webJourey) return null;
      return webJourey;
    });
  }

  public getSettingsForWebJourney(journeyId: number): angular.IPromise<IStatusBannerSettings> {
    return Promise.all([
      this.webJourneyAPIService.getWebJourneyRuns(journeyId),
      this.webJourneyV3Service.getJourneyStatus(journeyId).toPromise(),
    ]).then(([runs, status]: [Array<IWebJourneyRun>, IWebJourneyV3Status]) => {
      var lastRun: IWebJourneyRun = !runs || !runs[0] ? null : this.findLastRun(runs, 'id');
      return {
        notRunYet: !runs || runs.length == 0,
        firstRun: runs && runs.length == 1 && runs[0] && runs[0].completedAt == null,
        finished: lastRun && lastRun.completedAt != null,
        startedDate: lastRun ? this.dateService.formatDate(new Date(lastRun.startedAt), this.dateFormat) : null,
        lastRunId: lastRun ? lastRun.id : null,
        lastRunDate: status.lastCheck ? status.lastCheck : null,
      };
    });
  }

  //Audit
  public getAuditStatusProgress(auditId: number, runId: number): angular.IPromise<IAuditStatus> {
    return this.auditDataService.getAuditStatusProgress(auditId, runId).then( update => update.object as IAuditStatus);
  }

  public getAuditNextCheckDate(auditId: number): angular.IPromise<string>  {
    return this.auditDataService.getAudit(auditId).then((audit: IAuditModel) => {
      if (!audit) return null;
      return audit.nextRun ? this.dateService.formatDate(new Date(audit.nextRun), this.dateFormat) : null;
    });
  }

  public getAuditConfig(auditId: number): angular.IPromise<IAuditModel>  {
    return this.auditDataService.getAudit(auditId);
  }

  public getSettingsForAudit(auditId: number): angular.IPromise<IStatusBannerSettings> {
    this.reinitStore(auditId);
    return Promise.all([
      this.auditDataService.getAuditRuns(auditId),
      this.auditDataService.getAudit(auditId),
    ])
      .then(([runs, audit]: [Array<IAuditRunSummary>, IAuditCardModel]) => {
        const lastRun: IAuditRunSummary = !runs || !runs[0] ? null : this.findLastRun(runs, 'id');
        return {
          createdOutsideDataWarehouseWindow: !this.createdInLast13Months(new Date(audit.created)),
          queued: audit.queued,
          notRunYet: !runs || runs.length == 0,
          firstRun: runs && runs.length == 1 && runs[0] && runs[0].completed == null,
          finished: !audit.queued && lastRun && lastRun.completed != null,
          startedDate: lastRun ? this.dateService.formatDate(new Date(lastRun.started), this.dateFormat) : null,
          lastRunId: lastRun ? lastRun.id : null,
          lastRunDate: audit.lastRun ? audit.lastRun : null,
        };
      });
  }

  public createdInLast13Months(created: Date): boolean {
    // Get the current date
    let retentionDate = new Date();

    // Subtract 13 months from the current date
    let targetMonth = retentionDate.getMonth() - 13;
    let targetYear = retentionDate.getFullYear();

    // Adjust the year if needed
    if (targetMonth < 0) {
      targetYear--;
      targetMonth += 12;
    }

    // Set the new month and year
    retentionDate.setMonth(targetMonth);
    retentionDate.setFullYear(targetYear);

    return this.dateService.isWithinInterval(created, { start: retentionDate, end: new Date()});
  }

  /**
   * Will poll every 30 seconds until audit completes or observable is
   * unsubscribed from
   */
  public subscribeForStatusUpdates(type: StatusUpdateType, auditId: number, runId: number): Observable<any> { // IAuditStatus
    // Return observable that is polling every 30 seconds and emitting response for consumption externally
    const stopPolling$ = new Subject();

    return timer(0, 30_000)
      .pipe(takeUntil(stopPolling$))
      .pipe(
        mergeMap(() => {
          return from(this.auditDataService.getAuditStatusProgress(auditId, runId))
            .pipe(
              map((status) => status.object as IAuditStatus),
              catchError(err => {
                setTimeout(() => stopPolling$.next());
                return of({});
              })
            );
        })
      )
      .pipe(tap(update => {
        if (type === StatusUpdateType.ConsentCategory) return;

        // Stop polling for audit status updates when audit completes
        if ((update as IAuditStatus).finished) {
          setTimeout(() => stopPolling$.next());
        }
      }));
  }

  /**
   * Will poll every 30 seconds until web journey completes or observable is
   * unsubscribed from
   */
  public subscribeForWebJourneyUpdates(journeyId: number, runId: number) {
    const stopPolling$ = new Subject();

    return timer(0, 30_000)
      .pipe(takeUntil(stopPolling$))
      .pipe(
        mergeMap(() => {
          return this.webJourneyV3Service.getJourneyRunStatus(journeyId, runId)
            .pipe(catchError(err => {
              setTimeout(() => stopPolling$.next());
              return of({} as IWebJourneyRunProgress);
            }));
        })
      )
      .pipe(tap(update => {
        // Stop polling for journey status updates when journey completes
        if (webJourneyIsFinished(update.status)) {
          setTimeout(() => stopPolling$.next());
        }
      }));
  }

  public unsubscribeFromProgressPolling(): void {
    this.progressSubscriptions.forEach(s => s.unsubscribe());
    this.progressSubscriptions = [];
  }

  public stopUpdatesOnExit(stateName: string, updatesType: StatusUpdateType): void {
    if ((updatesType == StatusUpdateType.WebJourney && this.exitedFromWebJourneyReport(stateName)) ||
      (updatesType == StatusUpdateType.Audit && this.exitedFromAuditReport(stateName))) {
      this.unsubscribeFromProgressPolling();
    }
  }

  public exitedFromWebJourneyReport(exitedRoute: any): boolean {
    return startsWith(exitedRoute, WebJourneyReportStateNames.base);
  }

  public exitedFromAuditReport(exitedRoute: any): boolean {
    return AuditReportStateNames.base.startsWith(exitedRoute);
  }

  private findLastRun(runs: Array<any>, filterByKey: string): any{
    return runs.reduce(function(prev, curr){ return prev[filterByKey] > curr[filterByKey] ? prev : curr; });
  }
}

export interface IStatusBannerSettings {
  createdOutsideDataWarehouseWindow?: boolean;
  queued?: boolean;
  notRunYet: boolean;
  firstRun: boolean;
  finished: boolean;
  startedDate?: string;
  lastRunId?: number;
  lastRunDate?: string | Date;
}

export enum DeletionStatus {
  NotStarted = 0,
  Started    = 1,
  Finished   = 2
}

export interface IProgressStatus {
  started: boolean;
  rulesVerified: boolean;
  finished: boolean;
  deletion: DeletionStatus
}

export interface IAuditStatus extends IProgressStatus {
  processedPagesCount: number;
  pageLimit: number;
  pagesProcessed: boolean;
  pagesVerified: boolean;
  consentCategoriesVerificationStarted: boolean;
  consentCategoriesVerified: boolean;
}

export interface IJourneyStatus extends IProgressStatus {
  actionsProcessed: number;
  totalActions: number;
}

export interface IWebJourneyStatus extends IJourneyStatus {}

export interface IProgressStatusWrapper {
  type: string;
  object: IProgressStatus
}

export type StatusUpdateType = 'Audit' | 'WebJourney' | 'ConsentCategory';

export const StatusUpdateType = {
  Audit: 'Audit' as StatusUpdateType,
  WebJourney: 'WebJourney' as StatusUpdateType,
  ConsentCategory: 'ConsentCategory' as StatusUpdateType
};

export class StatusUpdateSubscription { // -> server
  id: number;
  operationType: StatusUpdateType;
  constructor(id: number, operationType: StatusUpdateType) {
    this.id = id;
    this.operationType = operationType;
  }
}
