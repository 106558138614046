import { Component, OnInit, Input, HostListener, OnChanges, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as d3 from 'd3';
import { Selection } from 'd3-selection';
import { IStackedBarChartDataPoint } from '@app/components/audit-reports/reports/tag-inventory/tag-inventory.models';
import { ScaleLinear } from 'd3-scale';
import { IStackedBarChartFormattedDataPoint } from './horizontal-stacked-bar-chart.models';
import { Transition } from 'd3';
import { EChartTooltip } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { SidebarService } from '@app/components/navigation/sidebar/sidebar.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'horizontal-stacked-bar-chart',
  templateUrl: './horizontal-stacked-bar-chart.component.html',
  styleUrls: ['./horizontal-stacked-bar-chart.component.scss']
})
export class HorizontalStackedBarChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  @Input() data: IStackedBarChartDataPoint[];
  @Input() uniqueIdentifier: string;
  @Input() state: EFilterSpinnerState = EFilterSpinnerState.None;
  @Input() chartHeight?: number = 43; // measured in pixels
  @Input() showHeading?: boolean = true;
  @Input() roundedCorners?: boolean = false;
  @Input() sortData?: boolean = true;
  @Input() showPills?: boolean = true;
  @Input() tooltipMessage?: string;
  @Input() additionalTooltipClass?: string;
  @Input() hideTooltip?: boolean;
  @Input() getTooltipMessageFn?: (data: IStackedBarChartFormattedDataPoint) => string;
  @Input() clickAction?: Function;

  @Output() filterSelected: EventEmitter<{mouseEvent: MouseEvent, item: IStackedBarChartFormattedDataPoint}> = new EventEmitter();

  formattedData: IStackedBarChartFormattedDataPoint[] = [];
  hasActiveFilter: boolean = false;
  container: Selection<any, any, any, any>;
  svg: Selection<any, any, any, any>;
  svgGroup: Selection<any, any, any, any>;
  svgHeight: number;
  svgWidth: number;
  x: ScaleLinear<any, any, any>;
  tooltip: Selection<any, any, any, any>;
  tooltipClass: string = 'horizontal-stacked-bar-chart-tooltip';
  tooltipWidth: number;
  tooltipHeight: number;
  numUniqueCats: number;
  windowWidth: number = window.innerWidth;

  private resize$ = new Subject();
  private destroy$ = new Subject();
  private readonly transitionDuration: number = 500;

  constructor(private sidebarService: SidebarService) {}

  ngOnInit(): void {
    this.resize$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.initChart();
    });

    this.sidebarService.isClosed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // timeout is to allow sidebar to toggle before redrawing chart
      setTimeout(() => this.initChart(), 250);
    });
  }

  ngAfterViewInit(): void {
    this.setActiveFilterState();
    setTimeout(() => this.initChart(), 0);
  }

  ngOnChanges(): void {
    this.formatData();
    this.updateChart();
    this.setActiveFilterState();
    this.numUniqueCats = this.formattedData.length;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private formatData(): void {
    if (this.sortData) {
      this.data.sort((a: any, b: any) => (a.value > b.value) ? -1 : 1);
    }

    const total = d3.sum(this.data, (d: IStackedBarChartDataPoint) => d.value);
    let value = 0;

    this.formattedData = this.data.map((d: IStackedBarChartDataPoint): IStackedBarChartFormattedDataPoint => {
      let roundedPercentage: number;

      // ensures fractional percentages don't round to
      // 0% or 100% and instead display as 0.xx% and 99%
      const percentage = (d.value / total) * 100;

      if (percentage < 100 && percentage > 99) {
        roundedPercentage = 99;
      }

      else if (percentage < 1 && percentage > 0) {
        roundedPercentage = parseFloat(percentage.toFixed(2)) || 0.01;
      }

      else {
        roundedPercentage = Math.round(percentage);
      }

      return {
        id: d.id,
        name: `${d.name} (${[roundedPercentage]}%)`,
        tooltipDisplayName: d.name,
        value: d.value / total,
        startValue: value / total,
        endValue: (value += d.value) / total,
        colorClass: d.colorClass,
        filtered: d.filtered
      };
    });
  }

  private getTransition(): Transition<any, any, any, any> {
    return this.svg.transition().duration(this.transitionDuration);
  }

  private setActiveFilterState(): void {
    const dataFiltered = !!this.data.find((d: IStackedBarChartFormattedDataPoint) => d.filtered);
    const formattedFiltered = !!this.formattedData.find((d: IStackedBarChartFormattedDataPoint) => d.filtered);
    this.hasActiveFilter = dataFiltered || formattedFiltered;
  }

  filterChart(e: MouseEvent, d: IStackedBarChartFormattedDataPoint): void {
    if(d.filtered) {
      this.formattedData.forEach((d: IStackedBarChartFormattedDataPoint) => d.filtered = false);
    } else {
      this.formattedData.forEach((d: IStackedBarChartFormattedDataPoint) => d.filtered = false);
      d.filtered = !d.filtered;
    }

    this.setTooltipTextValue(d);

    // send to bottom of the stack so we can get dimensions
    setTimeout(() => this.setTooltipBoxDimensions(), 0);

    this.updateChart();
    this.setActiveFilterState();
    this.filterSelected.emit({ mouseEvent: e, item: d });
  }

  initChart(): void {
    // svg element
    this.container = d3.select(`.svg-container-${this.uniqueIdentifier}`).html('');
    this.svgHeight = this.chartHeight;
    this.svgWidth = parseInt(this.container.style('width'));

    this.svg = this.container
      .append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    this.svgGroup = this.svg.append('g');

    // tooltip
    // only create if it's not already on the page
    if(!d3.select(`.${this.tooltipClass}`).node()) this.createTooltip();

    // get reference to the DOM element
    this.tooltip = d3.select(`.${this.tooltipClass}`);

    // start the drawing process
    this.updateChart();
  }

  updateChart(): void {
    if(!this.data.length || !this.svgGroup) return;

    this.x = d3.scaleLinear([0, 1], [0, this.svgWidth]);

    this.svgGroup
      .selectAll('rect')
      .data(this.formattedData)
      .join(
        // new category added to chart
        (enter: Selection<any, any, any, any>) => this.categoryEnter(enter),
        // update value of existing category in chart
        (update: Selection<any, any, any, any>) => this.categoryUpdate(update),
        // remove category from chart
        (exit: Selection<any, any, any, any>) => this.categoryExit(exit)
      );
  }

  private createTooltip(): void {
    d3
      .select('body')
      .append('div')
      .attr('class', `${this.tooltipClass} ${this.additionalTooltipClass || ''}`);
  }

  private categoryEnter(enter: Selection<any, any, any, any>) {
    return enter
      .append('rect')
      .attr('class', (d: IStackedBarChartFormattedDataPoint) => `stacked-bar-rect ${d.colorClass}`)
      .attr('height', this.svgHeight)
      .attr('x', this.x(0))
      .attr('y', '0')
      .classed('selected', (d: IStackedBarChartFormattedDataPoint) => d.filtered)
      .call(
        (enter: Selection<any, any, any, any>) => enter
          .transition(this.getTransition())
          .attr('x', (d: IStackedBarChartFormattedDataPoint) => this.x(d.startValue))
          .attr('width', (d: IStackedBarChartFormattedDataPoint) => this.x(d.endValue) - this.x(d.startValue))
      )
      .on('mouseover', (e: MouseEvent, d: IStackedBarChartFormattedDataPoint) => {
        if (!this.hideTooltip) {
          this.setTooltipTextValue(d);

          // send to bottom of the stack so we can get dimensions
          setTimeout(() => this.setTooltipBoxDimensions(), 0);

          this.tooltip
            .style('display', 'flex')
            .transition()
            .duration(150)
            .style('opacity', 1);
        }
      })
      .on('mousemove', (e: MouseEvent) => this.handleTooltipPosition(e))
      .on('mouseout', () => {
        this.tooltip
          .transition()
          .delay(25)
          .style('opacity', 0)
          .style('display', 'none');
      })
      .on('click', (e: MouseEvent, d: IStackedBarChartFormattedDataPoint) => {
        if (this.clickAction) return this.clickAction();

        this.svgGroup.selectAll('rect').classed('selected', (rect: IStackedBarChartFormattedDataPoint) => d === rect);
        this.filterChart(e, d);
        this.handleTooltipPosition(e);
      });
  }

  private categoryUpdate(update: Selection<any, any, any, any>) {
    return update
      .attr('class', (d: IStackedBarChartFormattedDataPoint) => `stacked-bar-rect ${d.colorClass}`)
      .classed('selected', (d: IStackedBarChartFormattedDataPoint) => d.filtered)
      .call(
        (update: Selection<any, any, any, any>) => update
          .transition(this.getTransition())
          .attr('x', (d: IStackedBarChartFormattedDataPoint) => this.x(d.startValue))
          .attr('width', (d: IStackedBarChartFormattedDataPoint) => this.x(d.endValue) - this.x(d.startValue))
      );
  }

  private categoryExit(exit: Selection<any, any, any, any>) {
    return exit
      .attr('x', (d: IStackedBarChartFormattedDataPoint) => this.x(d.startValue))
      .call((exit: Selection<any, any, any, any>) => exit.remove());
  }

  private setTooltipTextValue(d: IStackedBarChartFormattedDataPoint): void {
    if (!this.hideTooltip) {
      if (this.tooltipMessage) {
        this.tooltip.html(this.tooltipMessage);
      } else {
        if (!this.getTooltipMessageFn) {
          d.filtered
            ? this.tooltip.html(EChartTooltip.Clear)
            : this.tooltip.html(`${EChartTooltip.Filter} ${d.tooltipDisplayName} tags`);
        } else {
          this.tooltip.html(this.getTooltipMessageFn(d));
        }
      }
    }
  }

  private setTooltipBoxDimensions(): void {
    this.tooltipWidth = this.tooltip.node().getBoundingClientRect().width + 14;
    this.tooltipHeight = this.tooltip.node().getBoundingClientRect().height + 8;

    this.tooltip
      .attr('width', this.tooltipWidth)
      .attr('height', this.tooltipHeight);
  }

  private handleTooltipPosition(e: MouseEvent): void {
    const horizPos = e.clientX;
    const vertPos = e.clientY;
    const leftEdge = 0;
    const rightEdge = this.windowWidth;
    const edgePadding = 5;

    // left edge of window
    if ((horizPos - (this.tooltipWidth / 2)) <= leftEdge + edgePadding) {
      this.tooltip
        .style('left', `${(this.tooltipWidth / 2) + edgePadding}px`)
        .style('top', `${vertPos - 35}px`);
    }

    // right edge of window
    else if ((horizPos + (this.tooltipWidth / 2)) >= rightEdge - edgePadding) {
      this.tooltip
        .style('left', `${rightEdge - (this.tooltipWidth)}px`)
        .style('top', `${vertPos - 35}px`);
    }

    // default behavior in middle of window
    else {
      this.tooltip
        .style('left', `${horizPos - (this.tooltipWidth / 2)}px`)
        .style('top', `${vertPos - 35}px`);
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
    this.windowWidth = window.innerWidth;
  }
}
