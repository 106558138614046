import {
  IAuditRequestLogContent,
  IAuditRequestLogRelatedCookiesDisplay
} from '@app/components/audit-reports/page-details/page-details.models';
import { IActionDetailsRequestLogObject } from '@app/components/domains/webJourneys/webJourneyAPI/web-journey-action-details.service';

export enum EPageDetailsRequestOriginLabels {
  ON_PAGE = 'Page Load',
  PRE_AUDIT_ACTION = 'Pre-Audit Action',
}

export enum IPageDetailsRequestLogTableColumn {
  EXPAND = 'expand',
  REQUEST_METHOD = 'requestMethod',
  URL = 'url',
  TAG = 'tag',
  ORIGIN = 'origin',
  DATE_DIFFERENCE = 'largestDateDifference',
  SIZE_DIFFERENCE = 'largestSizeDifference',
  RESPONSE_SIZE = 'responseSize',
  LOAD_TIME = 'loadTime',
  STARTED_AT = 'startedAt',
  STATUS_CODE = 'statusCode',
  GEO_LOCATION = 'geoLocation',
  EXPAND_DETAIL = 'expandedDetail',
  COOKIE_INITIATORS = 'cookieInitiators',
}

export interface IPageDetailsRequestLogTableAuditRow {
  dataItem: IAuditRequestLogContent;

  tagName: string;
  tagCategory: string;
  tagIcon: string;
  tooltip: string;

  fileChangeType?: string;
  largestDateDifference?: string;
  largestSizeDifference?: string;

  mimeType: string;
  origin: EPageDetailsRequestOriginLabels;
  responseSize: string;

  lastModifiedDate?: string;
  filename?: string;
  requestDomain?: string;

  url: string;
  statusCode: number;
  statusCodeClass: string;
  loadTime: string;
  loadTimeClass: string;
  geoLocation: string;
  startedAt: number;

  cookiesInitiated: IAuditRequestLogRelatedCookiesDisplay[]
}

export interface IPageDetailsRequestLogTableWebJourneyRow {
  dataItem: IActionDetailsRequestLogObject;

  url: string;
  statusCode: number;
  statusCodeClass: string;
  loadTime: string;
  loadTimeClass: string;
  geoLocation: string;
}

export type IPageDetailsRequestLogTableRow = IPageDetailsRequestLogTableAuditRow | IPageDetailsRequestLogTableWebJourneyRow;

export type IPageDetailsRequestLogSortColumn = 'request_url'
  | 'tag_name'
  | 'cookie_initiators'
  | 'mime_type'
  | 'response_size'
  | 'load_time'
  | 'started_at'
  | 'status_code'
  | 'geo'
  | 'date_difference'
  | 'size_difference';
