import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { StatusCodeIndicatorsPayload } from '@app/components/audit-reports/status-code-indicators/status-code-indicators.models';
import { TagTableFilterType } from '@app/components/audit-reports/reports/tag-health/tags-list/tag-list.constants';

export const BASE_OFFSET_COEFFICIENT = 6;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'status-code-indicators',
  templateUrl: './status-code-indicators.component.html',
  styleUrls: ['./status-code-indicators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusCodeIndicatorsComponent implements OnInit, OnChanges {
  @Input() payload: StatusCodeIndicatorsPayload = { broken: 0, redirect: 0, good: 0 };
  @Input() tooltip = false;
  @Output() filter = new EventEmitter<{type: TagTableFilterType, value: string}>();

  brokenPresent: boolean;
  redirectPresent: boolean;
  goodPresent: boolean;

  private redirectIconOffset = 0;
  private goodIconOffset = 0;
  private startOffset = 0;

  constructor() {
  }

  ngOnInit(): void {
    this.init(this.payload);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.payload) {
      this.init(this.payload)
    }
  }

  private init(payload: StatusCodeIndicatorsPayload): void {
    this.brokenPresent = payload?.broken > 0;
    this.redirectPresent = payload?.redirect > 0;
    this.goodPresent = payload?.good > 0;

    if (this.redirectPresent && this.brokenPresent) this.redirectIconOffset = BASE_OFFSET_COEFFICIENT;
    if (this.goodPresent) this.goodIconOffset = this.redirectPresent ? BASE_OFFSET_COEFFICIENT : (this.brokenPresent ? BASE_OFFSET_COEFFICIENT : 0);
    this.startOffset = ((this.brokenPresent ? 0 : 1) + (this.redirectPresent ? 0 : 1) + (this.goodPresent ? 0 : 1)) * BASE_OFFSET_COEFFICIENT;
  }

  getBrokenIconOffset(): number {
    return this.startOffset;
  }

  getRedirectIconOffset(): number {
    return this.getBrokenIconOffset() + this.redirectIconOffset;
  }

  getGoodIconOffset(): number {
    return this.getRedirectIconOffset() + this.goodIconOffset;
  }

  getArrowIconOffset(): number {
    return this.getGoodIconOffset() + BASE_OFFSET_COEFFICIENT * 3;
  }

  filterBy(status: string): void {
    this.filter.emit({type: TagTableFilterType.TagStatus, value: status});
  }
}
