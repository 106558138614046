import { Router, ActivatedRoute } from '@angular/router';
import { userIsOPAdmin, userIsOPSysAdmin } from '../../../authUtils';
import { ISort } from '@app/components/manage/shared/services/manage-communication/manage-communication.service';
import { WebJourneyReportUrlBuilders } from '@app/components/web-journey-report/web-journey-report.constants';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { AccountsService } from '@app/components/account/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component } from '@angular/core';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  AdminService,
  IAuditResult,
  IItemFinderResults,
  ISimulationResult
} from '@app/components/admin-portal/system-status/admin.service';
import { Observable } from 'rxjs';

interface IItemFinderRouteParams {
  search: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'item-finder',
  templateUrl: './item-finder.component.html',
  styleUrls: ['./item-finder.component.scss'],
})
export class ItemFinderComponent {
  itemSearch: string = '';
  results: IItemFinderResults;
  currentTab: string = 'audits';
  loading: boolean = false;
  error: boolean = false;

  isSysAdmin: boolean = false;
  auditSort: ISort = { sortOn: 'id', reverse: false };
  simulationSort: ISort = { sortOn: 'id', reverse: false };
  accountId: number;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private adminService: AdminService,
              private accountsService: AccountsService,
              private snackBar: MatSnackBar,
              private modalService: OpModalService,
  ) {
    this.activatedRoute.queryParams.subscribe((queryParams: IItemFinderRouteParams) => {
      if (queryParams.search) {
        this.itemSearch = queryParams.search;
        this.search();
      }
    });

    this.accountsService.getUser().subscribe(user => {
      if (!userIsOPAdmin(user)) {
        this.router.navigateByUrl(AccountSettingsUrlBuilders.user());
      }
      this.accountId = user.accountId;
      this.isSysAdmin = userIsOPSysAdmin(user);
    });
  }

  doSearch() {
    if (this.itemSearch != '') {
      const queryParams = {
        search: this.itemSearch
      };
      this.router.navigate([], { queryParams });
      this.search();
    }
  }

  search() {
    if (this.itemSearch != '') {
      this.results = null;
      this.loading = true;
      this.error = false;
      this.adminService.findItems(this.itemSearch).subscribe((results) => {
        this.results = results;
        this.loading = false;
      }, () => {
        this.loading = false;
        this.error = true;
      });
    }
  }

  goToAudit(audit: IAuditResult) {
    const url = AuditReportContainerUrlBuilders.base(audit.id, audit.mostRecentRunId);
    this.loginAsAccount(audit.accountId, url);
  }

  goToSimulation(sim: ISimulationResult) {
    const url = WebJourneyReportUrlBuilders.results(sim.id, sim.mostRecentRunId);
    this.loginAsAccount(sim.accountId, url);
  }

  private loginAsAccount(loginAsAccountId: number, navigationUrl: string) {
    const currentAccountId = this.accountId;
    const currentUrl = this.router.url;
    this.adminService?.login(currentAccountId, loginAsAccountId, navigationUrl, currentUrl)?.subscribe();
  }

  setSort(column: string, sort: ISort): ISort {
    var reverse = (sort.sortOn == column) ? !sort.reverse : false;
    // Sort audits by selected column

    return { sortOn: column, reverse: reverse };
  }

  genericRemove(event, itemId: number,
                confirmation: string,
                remover: (ids: Array<number>) => Observable<any>,
                servicesToMention: string): void {
    event.stopPropagation();
    this.modalService.openConfirmModal({
      data: {
        title: 'Delete',
        messages: [confirmation],
        rightFooterButtons: [
          {
            label: 'Continue',
            action: () => {
              // snackbar on success/fail
              let items = [];
              items.push(itemId);
              remover(items).subscribe(() => {
                // success snackbar
                const message = 'Deletion process started. See progress in logs for ${servicesToMention} services.';
                this.snackBar.open(message, '', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'top' });
              }, () => {
                // error snackbar
                const message = 'Cannot start delete process. See error log';
                this.snackBar.open(message, '', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'top' });
              });
            },
            primary: false
          }
        ]
      }
    });
  }

  removeAllRunsOfAudit(event, audit: IAuditResult): void {
    this.genericRemove(
      event,
      audit.id,
      `Are you sure you want to remove all runs of audit "${audit.description}"?`,
      (ids: Array<number>) => this.adminService.removeAllRunsOfAudits(ids),
      'Starter, Manager, Processor, Enforcer, Indexer, Summariser'
    );
  }

  removeAllRunsOfWebJourney(event, webJourney: ISimulationResult): void {
    this.genericRemove(
      event,
      webJourney.id,
      `Are you sure you want to remove all runs of web-journey "${webJourney.description}"?`,
      (ids: Array<number>) => this.adminService.removeAllRunsOfWebJourneys(ids),
      'Starter, Manager, Processor, Enforcer, Indexer, Summariser'
    );
  }

}
