import { Component, AfterViewInit, OnChanges, Input, EventEmitter, Output, HostListener, OnInit, OnDestroy } from '@angular/core';
import { ScaleBand, ScaleLinear, Selection } from 'd3';
import { EBarChartDirection, EBarChartTextPosition } from './horizontal-bar-chart-rounded.constants';
import { IHorizontalBarChartDataPoint } from './horizontal-bar-chart-rounded.models';
import * as d3 from 'd3';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EChartTooltip } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { SidebarService } from '@app/components/navigation/sidebar/sidebar.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'horizontal-bar-chart-rounded',
  templateUrl: './horizontal-bar-chart-rounded.component.html',
  styleUrls: ['./horizontal-bar-chart-rounded.component.scss']
})
export class HorizontalBarChartRoundedComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() data: IHorizontalBarChartDataPoint[];
  // in order to have multiple instances on a page we
  // need a unique identifier to draw multiple SVGs
  @Input() uniqueIdentifier: string;

  // optional inputs
  @Input() calcAsPercentage?: boolean = false;
  @Input() displayPercentSymbol?: boolean = false;
  @Input() displayActualValue?: boolean = true;
  @Input() textPosition?: EBarChartTextPosition = EBarChartTextPosition.Start;
  @Input() barDirection?: EBarChartDirection = EBarChartDirection.LTR;
  @Input() fontSize?: number = 16;
  @Input() textPadding?: number = 5; // amount of padding to the left and right of text
  @Input() labelPadding?: number = 13; // amount of padding to the right of y axis labels
  @Input() tooltipMessage?: string;
  @Input() hideTooltip?: boolean;
  @Output() onBarSelected: EventEmitter<{ mouseEvent: MouseEvent, item: IHorizontalBarChartDataPoint }> = new EventEmitter();

  container: Selection<any, any, any, any>;
  svg: Selection<any, any, any, any>;
  svgHeight: number;
  svgWidth: number;
  svgGroup: Selection<any, any, any, any>;
  x: ScaleLinear<any, any, any>;
  y: ScaleBand<any>;
  bars: Selection<any, any, any, any>;
  barLabels: Selection<any, any, any, any>;
  yAxisLabels: Selection<any, any, any, any>;
  tooltip: Selection<any, any, any, any>;
  tooltipClass: string = 'horizontal-bar-chart-tooltip';
  tooltipWidth: number;
  tooltipHeight: number;
  hasActiveFilter: boolean;
  windowWidth: number = window.innerWidth;
  widestYAxisLabel: number = 0;
  widestBarLabel: number = 0;
  minBarWidth: number = 1;

  private resize$ = new Subject();
  private destroy$ = new Subject();

  constructor(private sidebarService: SidebarService) { }

  ngOnInit(): void {
    this.resize$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.drawChart();
    });

    this.sidebarService.isClosed.pipe(takeUntil(this.destroy$)).subscribe(() => {
      // timeout is to allow sidebar to toggle before redrawing chart
      setTimeout(() => this.drawChart(), 250);
    });
  }

  ngAfterViewInit(): void {
    this.setActiveFilterState();
    setTimeout(() => this.drawChart(), 0);
  }

  ngOnChanges(): void {
    this.setActiveFilterState();
    this.updateChart();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setActiveFilterState(): void {
    this.hasActiveFilter = !!this.data.find((d: IHorizontalBarChartDataPoint) => d.filtered);
  }

  getBarWidthCalculation(): number {
    return this.calcAsPercentage
      ? 100
      : d3.max(this.data, (d: IHorizontalBarChartDataPoint) => d.value) || 1;
  }

  getBarXPositionInitial(): number {
    return this.barDirection === EBarChartDirection.LTR
      ? this.x(0)
      : this.svgWidth;
  }

  getBarXPositionForAnimation(d: IHorizontalBarChartDataPoint): number {
    const xVal = this.x(d.value);
    const rtlXpos = this.svgWidth - xVal > this.minBarWidth ? xVal : xVal - this.minBarWidth;
    return this.barDirection === EBarChartDirection.LTR
      ? this.x(0)
      : rtlXpos;
  }

  getBarWidth(d: IHorizontalBarChartDataPoint) {
    return this.barDirection === EBarChartDirection.LTR
      ? this.x(d.value)
      : this.svgWidth - this.x(d.value);
  }

  getTextPositionBarLTR(barWidth: number, textWidth: number): number {
    if (this.textPosition === EBarChartTextPosition.Start) {
      return barWidth + this.textPadding;
    } else {
      // two possible positions for text to be inside or outside bar depending on bar width
      return barWidth < (textWidth + (this.textPadding * 2)) // adds padding to BOTH sides of text
        ? barWidth - this.textPadding
        : barWidth + this.textPadding;
    }
  }

  getTextPositionBarRTL(barWidth: number, textWidth: number): number {
    if (this.textPosition === EBarChartTextPosition.Start) {
      // two possible positions for text to be inside or outside bar depending on bar width
      return barWidth > (textWidth + this.textPadding) // adds padding to BOTH sides of text
        ? this.svgWidth - this.textPadding
        : (this.svgWidth - barWidth) - this.textPadding;
    } else {
      // two possible positions for text to be inside or outside bar depending on bar width
      return barWidth > (textWidth + this.textPadding) // adds padding to BOTH sides of text
        ? this.svgWidth - (barWidth - this.textPadding)
        : this.svgWidth - (barWidth + this.textPadding);
    }
  }

  getTextAnchorBarLTR(barWidth: number, textWidth: number): string {
    if (this.textPosition === EBarChartTextPosition.Start) {
      return 'start';
    } else {
      // 'end' if inside bar, 'start' if outside
      return barWidth > (textWidth + (this.textPadding * 2)) // adds padding to BOTH sides of text
        ? 'end'
        : 'start';
    }
  }

  getTextAnchorBarRTL(barWidth: number, textWidth: number): string {
    if (this.textPosition === EBarChartTextPosition.Start) {
      return 'end';
    } else {
      // 'start' if inside bar, 'end' if outside
      return barWidth > (textWidth + this.textPadding) // adds padding to BOTH sides of text
        ? 'start'
        : 'end';
    }
  }

  getXScale(): void {
    const xDomain: [number, number] = this.barDirection === EBarChartDirection.LTR
      ? [0, this.getBarWidthCalculation()]
      : [this.getBarWidthCalculation(), 0];

    this.x = d3
      .scaleLinear()
      .domain(xDomain)
      .range([0, this.svgWidth - (this.widestYAxisLabel + this.widestBarLabel + this.textPadding + this.labelPadding)]);
  }

  getWidthOfWidestYAxisLabel(): void {
    const labels = this.svgGroup
      .selectAll('text')
      .data(this.data)
      .enter()
      .append('text')
      .style('font-size', this.fontSize)
      .text((d: IHorizontalBarChartDataPoint) => d.name);

    this.widestYAxisLabel = this.data.length > 0 ? d3.max(labels.nodes(), n => n.getComputedTextLength()) : 0;

    labels.remove();
  }

  getWidthOfWidestBarLabel(): void {
    const labels = this.svgGroup
      .selectAll('text')
      .data(this.data)
      .enter()
      .append('text')
      .style('font-size', this.fontSize)
      .style('font-weight', 700)
      .text((d: IHorizontalBarChartDataPoint) => d.value);

    this.widestBarLabel = d3.max(labels.nodes(), n => n.getComputedTextLength());

    labels.remove();
  }

  drawChart(): void {
    // get the dimensions of our container element
    this.container = d3.select(`.svg-container-${this.uniqueIdentifier}`).html('');
    if (this.container.empty()) { // If selection is empty, any methods called on it will result in error
      return;
    }
    this.svgHeight = parseInt(this.container.style('height'));
    this.svgWidth = parseInt(this.container.style('width'));

    this.svg = this.container
      .append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    this.svgGroup = this.svg
      .append('g')
      .attr('class', 'main-svg-group');

    this.getWidthOfWidestYAxisLabel();
    this.getWidthOfWidestBarLabel();
    this.getXScale();

    this.svgGroup.attr('transform', `translate(${this.widestYAxisLabel + this.labelPadding}, 0)`)

    const isFiltered = !!this.data.find((d: IHorizontalBarChartDataPoint) => d.filtered);

    this.y = d3
      .scaleBand()
      .range([0, this.svgHeight])
      .domain(this.data.map((d: IHorizontalBarChartDataPoint) => d.colorClass))
      .padding(this.data.length === 1 ? 0 : 0.15);

    this.minBarWidth = this.y.bandwidth();

    // bars
    this.bars = this.svgGroup
      .selectAll('.bar-chart-bar')
      .data(this.data)
      .enter()
      .append('rect')
      .attr('class', (d: IHorizontalBarChartDataPoint) => `bar-chart-bar ${d.colorClass} ${this.hideTooltip ? 'hide-tooltip' : ''}`)
      .attr('y', (d: IHorizontalBarChartDataPoint) => this.y(d.colorClass))
      .attr('x', this.getBarXPositionInitial())
      .attr('rx', this.y.bandwidth() / 2) // this controls the rounding of the corners
      .attr('height', this.y.bandwidth())
      .attr('width', 0)
      .classed('dimmed', (bar: IHorizontalBarChartDataPoint) => isFiltered && !bar.filtered)
      .on('click', (e: MouseEvent, d: IHorizontalBarChartDataPoint) => {
        this.onBarSelected.emit({ mouseEvent: e, item: d })
      });

    // animate bars after page loads
    this.bars
      .transition()
      .duration(750)
      .attr('x', (d: IHorizontalBarChartDataPoint) => this.getBarXPositionForAnimation(d))
      .attr('width', (d: IHorizontalBarChartDataPoint) => this.getBarWidth(d));

    // y axis labels (left side)
    this.yAxisLabels = this.svg
      .append('g')
      .selectAll('.y-axis-label')
      .data(this.data)
      .enter()
      .append('text')
      .text((d: IHorizontalBarChartDataPoint) => d.name)
      .style('font-size', this.fontSize)
      .attr('dominant-baseline', 'middle')
      .attr('text-anchor', 'end')
      .attr('class', 'y-axis-label')
      .attr('x', this.widestYAxisLabel)
      .attr('y', (d: IHorizontalBarChartDataPoint) => this.y(d.colorClass) + 1)
      .attr('dy', this.y.bandwidth() / 2);

    // bar value labels (right side)
    this.barLabels = this.svgGroup
      .selectAll('.bar-chart-label')
      .data(this.data)
      .enter()
      .append('text')
      .text((d: IHorizontalBarChartDataPoint) => `${d3.format(',')(d.displayValue || d.value)}${this.displayPercentSymbol ? '%' : ''}`)
      .style('font-size', this.fontSize)
      .style('font-weight', 700)
      .attr('dominant-baseline', 'middle')
      .attr('class', `bar-chart-label outside ${this.hideTooltip ? 'hide-tooltip' : ''}`)
      .attr('x', this.barDirection === EBarChartDirection.LTR ? 0 : this.svgWidth)
      .attr('y', (d: IHorizontalBarChartDataPoint) => this.y(d.colorClass) + 1)
      .attr('dy', this.y.bandwidth() / 2)
      .on('click', (e: MouseEvent, d: IHorizontalBarChartDataPoint) => {
        this.onBarSelected.emit({ mouseEvent: e, item: d })
      });

    // animate text along with bars
    this.barLabels
      .transition()
      .duration(750)
      .attr('text-anchor', 'start')
      .attr('x', (d: IHorizontalBarChartDataPoint, i: number, n: any[]) => {
        const barWidth: number = this.getBarWidth(d);
        const textWidth: number = d3.select(n[i]).node().getBoundingClientRect().width + this.textPadding;
        return this.barDirection === EBarChartDirection.LTR
          ? this.getTextPositionBarLTR(barWidth, textWidth)
          : this.getTextPositionBarRTL(barWidth, textWidth);
      });

    // tooltip

    if (!this.hideTooltip) {
      // only create if it's not already on the page
      if (!d3.select(`.${this.tooltipClass}`).node()) this.createTooltip();

      // get reference to the DOM element
      this.tooltip = d3.select(`.${this.tooltipClass}`);

      this.bars.on('mouseover', (e: MouseEvent, d: IHorizontalBarChartDataPoint) => this.onBarsMouseOver(e, d));
      this.barLabels.on('mouseover', (e: MouseEvent, d: IHorizontalBarChartDataPoint) => this.onBarsMouseOver(e, d));

      this.bars.on('mousemove', (e: MouseEvent) => this.handleTooltipPosition(e));
      this.barLabels.on('mousemove', (e: MouseEvent) => this.handleTooltipPosition(e));

      this.bars.on('mouseout', () => this.onBarsMouseOut());
      this.barLabels.on('mouseout', () => this.onBarsMouseOut());
    }
  }

  private createTooltip(): void {
    d3
      .select('body')
      .append('div')
      .attr('class', this.tooltipClass);
  }

  private onBarsMouseOver(e: MouseEvent, d: IHorizontalBarChartDataPoint): void {
    if (!this.hideTooltip) {
      if (this.tooltipMessage) {
        this.tooltip.html(this.tooltipMessage);
      } else {
        d.filtered
          ? this.tooltip.html(EChartTooltip.Clear)
          : this.tooltip.html(EChartTooltip.Filter);
      }

      // send to bottom of the stack so we can get dimensions
      setTimeout(() => this.setTooltipBoxDimensions(), 0);

      this.tooltip
        .style('display', 'flex')
        .transition()
        .duration(150)
        .style('opacity', 1);
    }
  }

  private handleTooltipPosition(e: MouseEvent): void {
    const horizPos = e.clientX;
    const vertPos = e.clientY;
    const leftEdge = 0;
    const rightEdge = this.windowWidth;
    const edgePadding = 5;

    // left edge of window
    if ((horizPos - (this.tooltipWidth / 2)) <= leftEdge + edgePadding) {
      this.tooltip
        .style('left', `${(this.tooltipWidth / 2) + edgePadding}px`)
        .style('top', `${vertPos - 35}px`);
    }

    // right edge of window
    else if ((horizPos + (this.tooltipWidth / 2)) >= rightEdge - edgePadding) {
      this.tooltip
        .style('left', `${rightEdge - (this.tooltipWidth)}px`)
        .style('top', `${vertPos - 35}px`);
    }

    // default behavior in middle of window
    else {
      this.tooltip
        .style('left', `${horizPos - (this.tooltipWidth / 2)}px`)
        .style('top', `${vertPos - 35}px`);
    }
  }

  private onBarsMouseOut(): void {
    this.tooltip
      .style('opacity', 0)
      .style('display', 'none');
  }

  updateChart(): void {
    if (!this.bars || !this.barLabels) return;
    this.getXScale();

    const isFiltered = !!this.data.find((d: IHorizontalBarChartDataPoint) => d.filtered);

    this.bars
      .data(this.data)
      .classed('dimmed', (bar: IHorizontalBarChartDataPoint) => isFiltered && !bar.filtered)
      .transition()
      .duration(750)
      .attr('x', (d: IHorizontalBarChartDataPoint) => this.getBarXPositionForAnimation(d))
      .attr('width', (d: IHorizontalBarChartDataPoint) => this.getBarWidth(d));

    this.barLabels
      .data(this.data)
      .attr('class', 'bar-chart-label outside')
      .transition()
      .duration(750)
      .text((d: IHorizontalBarChartDataPoint) => `${d3.format(',')(d.displayValue || d.value)}${this.displayPercentSymbol ? '%' : ''}`)
      .attr('text-anchor', 'start')
      .attr('x', (d: IHorizontalBarChartDataPoint, i: number, n: any[]) => {
        const barWidth: number = this.getBarWidth(d);
        const textWidth: number = d3.select(n[i]).node().getBoundingClientRect().width + this.textPadding;
        return this.barDirection === EBarChartDirection.LTR
          ? this.getTextPositionBarLTR(barWidth, textWidth)
          : this.getTextPositionBarRTL(barWidth, textWidth);
      });
  }

  setTooltipBoxDimensions(): void {
    this.tooltipWidth = this.tooltip.node().getBoundingClientRect().width + 14;
    this.tooltipHeight = this.tooltip.node().getBoundingClientRect().height + 8;

    this.tooltip
      .attr('width', this.tooltipWidth)
      .attr('height', this.tooltipHeight);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
    this.windowWidth = window.innerWidth;
  }
}
