export enum EAlertAuditSummaryMetric {
  AuditScore = 'SCORE_TOTAL',
  TagPresenceScore = 'SCORE_TAG_PRESENCE',
  TagPerformanceScore = 'SCORE_TAG_PERFORMANCE',
  RuleScore = 'SCORE_RULES',
  PagePerformanceScore = 'SCORE_PAGE_PERFORMANCE',
  CookieScore = 'SCORE_COOKIE'
}

export enum EAlertPageSummaryMetric {
  PagesScanned = 'PAGE_SUMMARY_PAGE_COUNT',
  AveragePageLoadTime = 'PAGE_SUMMARY_AVG_PAGE_LOAD_TIME',
  BrokenInitialPages = 'PAGE_SUMMARY_BROKEN_INITIAL_PAGE_COUNT',
  BrokenFinalPages = 'PAGE_SUMMARY_BROKEN_FINAL_PAGE_COUNT',
  PagesWithBrokenLinks = 'PAGE_SUMMARY_PAGE_WITH_BROKEN_LINKS_COUNT',
}

export enum EAlertPageSummaryWebVitalsMetric {
  LargestContentfulPaint = 'PAGE_SUMMARY_WEB_VITALS_P75_LARGEST_CONTENTFUL_PAINT',
  FirstContentfulPaint = 'PAGE_SUMMARY_WEB_VITALS_P75_FIRST_CONTENTFUL_PAINT',
  TimeToFirstByte = 'PAGE_SUMMARY_WEB_VITALS_P75_TIME_TO_FIRST_BYTE',
  CumulativeLayoutShift = 'PAGE_SUMMARY_WEB_VITALS_P75_CUMULATIVE_LAYOUT_SHIFT',
}

export enum EAlertTagInventoryMetric {
  PagesScanned = 'TAG_INVENTORY_PAGE_COUNT',
  UniqueTags = 'TAG_INVENTORY_UNIQUE_TAG_COUNT',
  BrokenTags = 'TAG_INVENTORY_BROKEN_TAG_COUNT',
  BrokenPages = 'TAG_INVENTORY_BROKEN_PAGE_COUNT'
}

export enum EAlertTagHealthMetric {
  PagesScanned = 'TAG_HEALTH_PAGE_COUNT',
  AvgTagLoadTime = 'TAG_HEALTH_AVG_TAG_LOAD_TIME',
  AvgTagRequestSize = 'TAG_HEALTH_AVG_TAG_REQUEST_SIZE',
  PercentageOfSlowLoadingTags = 'TAG_HEALTH_SLOW_TAG_PERCENTAGE',
  PercentageOfOfBrokenTags = 'TAG_HEALTH_BROKEN_TAG_PERCENTAGE',
  TotalTagRequests = 'TAG_HEALTH_TAG_REQUEST_COUNT'
}

export enum EAlertVariableInventoryMetric {
  PagesScanned = 'VARIABLE_INVENTORY_PAGE_COUNT',
  UniqueTags = 'VARIABLE_INVENTORY_UNIQUE_TAG_COUNT',
  TagRequestsEvaluated = 'VARIABLE_INVENTORY_TAG_REQUEST_COUNT',
  UniqueVariables = 'VARIABLE_INVENTORY_UNIQUE_VARIABLE_COUNT',
  UniqueValues = 'VARIABLE_INVENTORY_UNIQUE_VARIABLE_VALUE_COUNT'
}

export enum EAlertTagAndVariableRulesMetric {
  PagesScanned = 'RULE_RESULTS_PAGE_COUNT',
  RulesAssigned = 'RULE_RESULTS_RULE_COUNT',
  RuleFailures = 'RULE_RESULTS_RULE_FAILURE_COUNT',
  FailedInstances = 'RULE_RESULTS_FAILED_RULE_COUNT',
  PassedInstances = 'RULE_RESULTS_PASSED_RULE_COUNT',
  NotAppliedInstances = 'RULE_RESULTS_NOT_APPLIED_RULE_COUNT',
  FailedPages = 'RULE_RESULTS_FAILED_RULE_PAGE_COUNT',
  PassedPages = 'RULE_RESULTS_PASSED_RULE_PAGE_COUNT',
  NotAppliedPages = 'RULE_RESULTS_NOT_APPLIED_RULE_PAGE_COUNT'
}

export enum EAlertDuplicatesAndMultiplesMetric {
  PagesScanned = 'TAG_DUPLICATES_AND_MULTIPLES_PAGE_COUNT',
  UniqueTags = 'TAG_DUPLICATES_AND_MULTIPLES_UNIQUE_TAG_COUNT',
  TotalTagRequests = 'TAG_DUPLICATES_AND_MULTIPLES_TAG_REQUEST_COUNT',
  TotalDuplicates = 'TAG_DUPLICATES_AND_MULTIPLES_TAG_DUPLICATE_COUNT',
  TotalMultiples = 'TAG_DUPLICATES_AND_MULTIPLES_TAG_MULTIPLE_COUNT'
}

export enum EAlertCookieInventoryMetric {
  PagesScanned = 'COOKIE_INVENTORY_PAGE_COUNT',
  UniqueCookies = 'COOKIE_INVENTORY_UNIQUE_COOKIE_COUNT',
  FirstParty = 'COOKIE_INVENTORY_FIRST_PARTY_COOKIE_COUNT',
  ThirdParty = 'COOKIE_INVENTORY_THIRD_PARTY_COOKIE_COUNT',
  NonSecure = 'COOKIE_INVENTORY_NON_SECURE_COOKIE_COUNT',
  SameSiteEmpty = 'COOKIE_INVENTORY_SAME_SITE_EMPTY_COOKIE_COUNT'
}

export enum EAlertBrowserConsoleLogsMetric {
  PagesScanned = 'BROWSER_LOGS_PAGE_COUNT',
  Errors = 'BROWSER_LOGS_ERROR_LOG_COUNT',
  Warnings = 'BROWSER_LOGS_WARN_LOG_COUNT',
  Info = 'BROWSER_LOGS_INFO_LOG_COUNT',
  Debug = 'BROWSER_LOGS_DEBUG_LOG_COUNT',
  Other = 'BROWSER_LOGS_OTHER_LOG_COUNT'
}

export enum EAlertPrivacyCookiesMetric {
  PagesScanned = 'PRIVACY_COOKIES_PAGE_COUNT',
  PagesWithUnapprovedCookies = 'PRIVACY_COOKIES_PAGE_WITH_UNAPPROVED_COOKIES_COUNT',
  UniqueCookies = 'PRIVACY_COOKIES_UNIQUE_COOKIE_COUNT',
  ApprovedCookies = 'PRIVACY_COOKIES_APPROVED_COOKIE_COUNT',
  UnapprovedCookies = 'PRIVACY_COOKIES_UNAPPROVED_COOKIE_COUNT'
}

export enum EAlertPrivacyTagsMetric {
  PagesScanned = 'PRIVACY_TAGS_PAGE_COUNT',
  PagesWithUnapprovedTags = 'PRIVACY_TAGS_PAGE_WITH_UNAPPROVED_TAGS_COUNT',
  UniqueTags = 'PRIVACY_TAGS_UNIQUE_TAG_COUNT',
  ApprovedTags = 'PRIVACY_TAGS_APPROVED_TAG_COUNT',
  UnapprovedTags = 'PRIVACY_TAGS_UNAPPROVED_TAG_COUNT'
}

export enum EAlertRequestsDomainsAndGeosMetric {
  PagesScanned = 'PRIVACY_REQUESTS_PAGE_COUNT',
  PagesWithUnapprovedItems = 'PRIVACY_REQUESTS_PAGE_WITH_UNAPPROVED_REQUESTS_COUNT',
  NetworkRequestsEvaluated = 'PRIVACY_REQUESTS_REQUEST_COUNT',
  UniqueDomains = 'PRIVACY_REQUESTS_UNIQUE_DOMAIN_COUNT',
  UniqueGeolocations = 'PRIVACY_REQUESTS_UNIQUE_LOCATION_COUNT',
  ApprovedDomains = 'PRIVACY_REQUESTS_APPROVED_REQUEST_COUNT',
  UnapprovedDomains = 'PRIVACY_REQUESTS_UNAPPROVED_REQUEST_COUNT'
}

export enum EAlertJavaScriptFileChangesMetric {
  PagesScanned = 'FILE_CHANGES_PAGE_COUNT',
  ChangedFiles = 'FILE_CHANGES_CHANGED_FILE_COUNT',
  NewFiles = 'FILE_CHANGES_NEW_FILE_COUNT',
  FileDateChanges = 'FILE_CHANGES_FILE_DATE_CHANGE_COUNT',
  FileSizeChanges = 'FILE_CHANGES_FILE_SIZE_CHANGE_COUNT'
}

export enum EAlertUsageMetricV2 {
  AccountUsageCurTermAuditPageScannedCount = 'ACCOUNT_USAGE_V2_CURRENT_TERM_PAGE_USAGE_PERCENTAGE',
  AccountUsageMonthPaceAuditPageScannedCount = 'ACCOUNT_USAGE_V2_THIS_MONTH_AUDIT_PAGE_USAGE_PERCENTAGE',
  AccountUsageCurTermWebJourneyRunsCount = 'ACCOUNT_USAGE_V2_CURRENT_TERM_WJ_RUN_USAGE_PERCENTAGE',
  AccountUsageMonthPaceWebJourneyRunsCount = 'ACCOUNT_USAGE_V2_THIS_MONTH_WJ_RUN_USAGE_PERCENTAGE',
  UsersLoggedInLast30Days = 'ACCOUNT_USAGE_V2_LAST_30_DAYS_LOGIN_USER_COUNT',
}

export type AlertMetricType = EAlertAuditSummaryMetric
| EAlertPageSummaryMetric
| EAlertTagInventoryMetric
| EAlertTagHealthMetric
| EAlertVariableInventoryMetric
| EAlertTagAndVariableRulesMetric
| EAlertDuplicatesAndMultiplesMetric
| EAlertCookieInventoryMetric
| EAlertBrowserConsoleLogsMetric
| EAlertPrivacyCookiesMetric
| EAlertPrivacyTagsMetric
| EAlertRequestsDomainsAndGeosMetric
| EAlertJavaScriptFileChangesMetric
| EAlertUsageMetricV2
| EAlertPageSummaryWebVitalsMetric;

export enum EAlertMetricValueComparator {
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  LessOrEqual = 'LESS_OR_EQUAL',
  Less = 'LESS',
}

export enum EAlertMetricChangeType {
  ValueChange = 'VALUE_CHANGE',
  ValueChangeAbs = 'VALUE_CHANGE_ABS',
  RelativeValueChange = 'RELATIVE_VALUE_CHANGE',
  RelativeValueChangeAbs = 'RELATIVE_VALUE_CHANGE_ABS',
}
