import { IUsageTrendsDTO } from '@app/components/usage-v2/usage-v2.models';
import {
  ChartClassName,
  ChartType,
  EChartType,
  IChartData,
  IChartsConfig
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import {
  createCircle,
  createTooltip,
  drawTooltip,
  getChartData,
  getPercentageOfTotal
} from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';
import {
  attachConstantLineChartToSvg,
  attachLineChartToSvg,
  hide,
  nearestDataPointCoordinates
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import { stepAfter } from '@app/components/shared/components/viz/utils/stepAfterCurve';
import * as d3 from 'd3';
import { EDateFormats, formatDate, toUTC } from '@app/components/date/date.service';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import {
  getProjectedUsageFilteredToggleBtn
} from '@app/components/usage-v2/components/usage-chart/datasets/future-periods/utils/toggle-buttons';

export function addFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateFilteredChartToConfig(trends: IUsageTrendsDTO, config: IChartsConfig, chartType: EChartType): void {
  if (!trends.futurePeriods?.length) {
    return;
  }
  const currentPeriod = trends.currentPeriod ? [trends.currentPeriod] : [];
  const data = getChartData([...currentPeriod, ...trends.futurePeriods], 'cumulativeProjectedTotal.scheduledUsageEstimate.filtered');
  if (!data.length) {
    return;
  }
  config.charts[ChartType.futurePeriodsCumulativeProjectedTotalScheduledUsageEstimateFiltered] = {
    data,
    chartClassName: ChartClassName.filteredProjectedUsage,
    toggleBtn: getProjectedUsageFilteredToggleBtn(chartType, true),
  };
}

export function drawFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateFilteredChart(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  overlayLineStroke: number,
  windowWidth: number,
  circleClass: string,
  circleRadius: number,
  tooltipClass: string,
  animationEnabled: boolean = true,
  startFromCenter = true,
): void {
  const scheduledUsageEstimateFiltered = config.charts[ChartType.futurePeriodsCumulativeProjectedTotalScheduledUsageEstimateFiltered];
  const scheduledUsageEstimateData = config.charts[ChartType.futurePeriodsCumulativeProjectedTotalScheduledUsageEstimate]?.data;

  if (!scheduledUsageEstimateFiltered || !scheduledUsageEstimateData) {
    return;
  }

  attachLineChartToSvg(
    {
      data: scheduledUsageEstimateFiltered.data,
      className: 'dashed-yellow-line',
      strokeWidth: 3,
      x: xAxis,
      y: yAxis,
      curve: stepAfter,
      // To make the first item start in center (for currentPeriod)
      leftPadding: startFromCenter ? xAxis.bandwidth() / 2 : 0,
      animationDuration: animationEnabled ? 2000 : 0,
    },
    svg.append('g')
      .attr(
        'class',
        `cumulative-projected-total-scheduled-usage-estimate-filtered ${scheduledUsageEstimateFiltered.chartClassName}`,
      )
  );

  attachLineChartToSvg(
    {
      data: scheduledUsageEstimateFiltered.data,
      className: 'transparent-line',
      strokeWidth: overlayLineStroke,
      x: xAxis,
      y: yAxis,
      curve: stepAfter,
      // To make the first item start in center (for currentPeriod)
      leftPadding: startFromCenter ? xAxis.bandwidth() / 2 : 0,
      animationDuration: animationEnabled ? 2000 : 0,
    },
    d3.select('.cumulative-projected-total-scheduled-usage-estimate-filtered'))
    .on('mousemove', (e) => {
      const {
        x,
        y,
        dataPoint,
        dataPointIndex,
      } = nearestDataPointCoordinates(e, svg.node(), scheduledUsageEstimateFiltered.data, xAxis, yAxis);

      const circle = createCircle(svg, circleClass, circleRadius);
      const tooltip = createTooltip(tooltipClass);
      circle.attr('class', `${circleClass} yellow-circle`);

      const percentage = getPercentageOfTotal(dataPoint.value, scheduledUsageEstimateData[dataPointIndex].value);

      drawTooltip(
        svg,
        '<div class="usage-chart-tooltip-body">' +
        '<h3 class="title">SCHEDULED USAGE ESTIMATE' + '<br>' +
        'AS OF ' +
        formatDate(toUTC(new Date(dataPoint.date)), EDateFormats.dateEight) +
        '</h3>' +
        '<div class="value">' +
        '<span class="value-data filtered">' + VizUtilsService.formatChartNumbers(dataPoint.value) + ' ' +
        '(<span class="material-icons icon">filter_alt</span>)' +
        '</span>' +
        '<span class="value-label"> of </span>' +
        '<span class="value-data estimated">' + VizUtilsService.formatChartNumbers(scheduledUsageEstimateData[dataPointIndex].value) + '</span>' +
        '</div>' +
        '<span class="value-label">' + percentage + '</span>' +
        '</div>',
        x,
        y,
        circle,
        tooltip,
        windowWidth,
      );
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
    });
}

// This is just a decorative element
export function drawDecorativeLineForFuturePeriodFiltered(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  animationEnabled: boolean = true,
): void {
  const scheduledUsageEstimate = config.charts[ChartType.futurePeriodsCumulativeProjectedTotalScheduledUsageEstimateFiltered];
  const point1 = config.charts[ChartType.pastPeriodsCumulativeTotalFiltered]?.data[(config.charts[ChartType.pastPeriodsCumulativeTotalFiltered].data as IChartData[]).length - 1];
  const point2 = config.charts[ChartType.futurePeriodsCumulativeProjectedTotalScheduledUsageEstimateFiltered]?.data[0];

  if (!point1 || !point2) {
    return;
  }

  const x = xAxis(point1.dateString) - xAxis.bandwidth() / 2;
  const y1 = yAxis(point2.value);
  const y2 = yAxis(point1.value);

  if (
    (!y1 || !y2 || !x)
    && typeof y1 !== 'number'
    && typeof y2 !== 'number'
    && typeof x !== 'number'
  ) {
    return;
  }

  attachConstantLineChartToSvg({
    groupClassName: scheduledUsageEstimate.chartClassName,
    className: 'dashed-yellow-line',
    x1: x,
    x2: x,
    y1,
    y2,
    animationDuration: animationEnabled ? 1750 : 0,
  }, svg);
}
