import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AdminAccountsService, ISsoConfig, IV3AdminAccount } from '@app/components/admin-portal/admin-accounts.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountCreatorMode } from '@app/components/creator/account/accountCreatorModels';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '@app/components/shared/components/snackbars/snackbar-success/snackbar-success.component';
import { IAdminAccount } from '@app/components/admin-portal/manage-accounts/manage-accounts.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'admin-sso',
  templateUrl: './admin-sso.component.html',
  styleUrls: ['./admin-sso.component.scss']
})
export class AdminSSOComponent implements OnInit, AfterViewInit {
  ssoForm: UntypedFormGroup;
  downloadMetadataDisabled: boolean;
  mode: AccountCreatorMode;
  account: IAdminAccount;
  dataLayer: string;
  templateData: boolean = true;
  subDomainIsDuplicate: boolean;
  authenticationType = 'Password';

  @Input() accountV2: IAdminAccount;
  @Input() accountV3: IV3AdminAccount;
  @Input() sso: ISsoConfig;
  @Input() ssoCreateMode: boolean;
  @Input() closeModal: any;

  constructor(
    private fb: UntypedFormBuilder,
    private adminAccountsService: AdminAccountsService,
    private snackbar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {
    this.createNewUser.setValue(this.sso.createNewUser);
    this.subDomainIdentifier.setValue(this.accountV3.subDomainIdentifier);
    this.authenticationType = this.sso.authenticationType;

    if (this.sso.authenticationType === 'SAML') {
      this.certificate.setValue(this.sso.certificate);
      this.idpLoginURL.setValue(this.sso.idpLoginUrl);
      this.idpLogoutURL.setValue(this.sso.idpLogoutUrl);
    }

    this.changeDetector.detectChanges();
  }

  initForm() {
    this.ssoForm = this.fb.group({
      subDomainIdentifier: ['', Validators.required],
      certificate: ['', Validators.required],
      idpLoginURL: ['', Validators.required],
      idpLogoutURL: ['', Validators.required],
      createNewUser: [false],
    });
  }

  onDownloadMetadataClick(): void {
    this.saveSSO(true);
  }

  saveSSO(downloadMetaDataConfig?: boolean) {
    if (this.ssoForm.invalid) {
      this.ssoForm.markAllAsTouched();
      return;
    }

    let subDomain = { subdomain: this.authenticationType === 'Password' ? '' : this.subDomainIdentifier.value };

    this.adminAccountsService.updateAccountById(this.accountV2.id, subDomain).subscribe(
      success => {
        this.saveSSOConfig(downloadMetaDataConfig);
      },
      error => {
        // Set validation error for subdomain if request errors out
        this.subDomainIsDuplicate = true;
      }
    );
  }

  saveSSOConfig(downloadMetaDataConfig?: boolean): void {
    let ssoConfig = {
      authenticationType: this.authenticationType,
      createNewUser: this.createNewUser.value
    };

    if (this.authenticationType === 'SAML') {
      ssoConfig['certificate'] = this.certificate.value;
      ssoConfig['identityId'] = 'observepoint';
      ssoConfig['idpLoginUrl'] = this.idpLoginURL.value;
      ssoConfig['idpLogoutUrl'] = this.idpLogoutURL.value;
    }

    let request = this.ssoCreateMode ?
      this.adminAccountsService.createAuthConfig(this.accountV2.id, ssoConfig) :
      this.adminAccountsService.updateAuthConfig(this.accountV2.id, ssoConfig);

    request.subscribe(res => {
      downloadMetaDataConfig ?
        this.downloadMetadataConfig() :
        this.showSnackbar('SSO settings saved successfully!');
    });
  }

  downloadMetadataConfig(): void {
    this.adminAccountsService.getAuthConfigXml(this.accountV2.id).subscribe(response => {
      this.downloadXML(response);
    }, err => {
      if (err.message?.includes('Http failure during parsing')) {
        this.downloadXML(err.errorCode.text);
      } else {
        console.log(err);
      }
    });
  }

  private downloadXML(xmlString: string): void {
    /**
     *
     * The following is based off:
     * https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
     *
     */
    let dataStr = 'data:text/xml;charset=utf-8,' + xmlString;
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', 'sso_metadata_config.xml');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  private showSnackbar(message: string) {
    this.snackbar.openFromComponent(SnackbarSuccessComponent, {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {message}
    });
  }

  get subDomainIdentifier(): AbstractControl {
    return this.ssoForm.get('subDomainIdentifier');
  }

  get certificate(): AbstractControl {
    return this.ssoForm.get('certificate');
  }

  get idpLoginURL(): AbstractControl {
    return this.ssoForm.get('idpLoginURL');
  }

  get idpLogoutURL(): AbstractControl {
    return this.ssoForm.get('idpLogoutURL');
  }

  get createNewUser(): AbstractControl {
    return this.ssoForm.get('createNewUser');
  }
}
