import { EAlertDataSourcesTableSortColumn } from './alert-data-sources/alert-data-sources.enums';
import { ISearchAlertAssignmentsSorting } from './alert.models';
import { IPagination } from '@app/components/shared/components/selectable-table/selectable-table.models';

export const DefaultAlertAssignmentsPagination: IPagination = {
  totalCount: 0,
  totalPageCount: 0,
  currentPageSize: 0,
  pageSize: 100,
  currentPageNumber: 0,
};

export const DefaultAlertAssignmentsSorting: ISearchAlertAssignmentsSorting = {
  sortBy: EAlertDataSourcesTableSortColumn.ItemName,
  sortDesc: false,
};

export enum EAlertSearchSortBy {
  ALERT_NAME = 'ALERT_NAME',
  REPORT_METRIC = 'REPORT_METRIC'
}

export enum EAlertAssignmentOperation {
  ASSIGN = 'ASSIGN',
  UNASSIGN = 'UNASSIGN'
}
