import { Component, TemplateRef, ElementRef, ContentChild, Input, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'op-drag-list',
  templateUrl: './op-drag-list.component.html',
  styleUrls: ['./op-drag-list.component.scss']
})
export class OpDragListComponent implements OnInit, OnDestroy {
  
  dragulaName: string;
  selectedIndex: number;

  @Input() data: any[];
  @Input() draggable: boolean;
  @Input() dragulaContainer?: string;
  @ContentChild('row') rowTemplate: TemplateRef<ElementRef>;

  @Output() dropped: EventEmitter<any[]> = new EventEmitter();
  @Output() selected: EventEmitter<number> = new EventEmitter();

  private destroy$ = new Subject<void>();
  
  constructor(private dragulaService: DragulaService) {
    dragulaService.dropModel(this.dragulaName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({targetModel, targetIndex}) => {
        this.dropped.emit({...targetModel});
        this.select(targetIndex);
      });
  }

  ngOnInit() {
    this.dragulaName = this.dragulaContainer ? this.dragulaContainer : 'DRAG_LIST';

    try {
      this.dragulaService.createGroup(this.dragulaName, {
        moves: (el, container, handle) => {
          return handle.className.indexOf('handle') > -1;
        }
      });
    } catch (error) {
      console.error(`Can't create group due to: ${error}`);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.dragulaService.destroy(this.dragulaName);
  }

  select(index: number) {
    this.selectedIndex = index;
    this.selected.emit(index);
  }

  unselect() {
    this.selectedIndex = null;
  }
}
