import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-open-menu-on-hover',
  templateUrl: './cl-open-menu-on-hover.component.html',
  styleUrls: ['./cl-open-menu-on-hover.component.scss']
})
export class ComponentLibraryOpenMenuOnHoverComponent {

  menuItems: any[] = [
    { label: 'Item 1', icon: 'home' },
    { label: 'Item 2', icon: 'settings' },
    { label: 'Item 3', icon: 'help' }
  ];
}
