import { Route } from "@angular/router";
import { ExportCenterComponent } from "./export-center.component";
import { ExportCenterPaths, ExportCenterStateNames } from "./export-center.constants";

export const ExportCenterFutureState: Route = {
  path: ExportCenterPaths.base,
  loadChildren: () => import('./export-center.module').then(mod => mod.ExportCenterModule)
};

export const ExportCenterRoutes: Route = {
  path: '',
  component: ExportCenterComponent,
  data: {
    title: 'Export Center',
    stateName: ExportCenterStateNames.base,
  }
};
