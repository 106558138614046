import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDeleteActionModalPayload, IDeleteActionModalResponse } from './delete-action-modal.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'delete-action-modal',
  templateUrl: './delete-action-modal.component.html',
  styleUrls: ['./delete-action-modal.component.scss'],
})
export class DeleteActionModalComponent {
  deleteFollowingSteps: boolean;
  deleteFollowingRecommended: boolean;
  optionalDeleteFollowing: boolean;
  title = '';
  rightFooterButtons = [
    {
      label: 'Delete',
      primary: true,
      action: () => {
        this.close({
          confirmDelete: true,
          deleteFollowingSteps: this.deleteFollowingSteps
        });
      },
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<DeleteActionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public payload: IDeleteActionModalPayload
  ) {
    this.deleteFollowingSteps = !!payload.deleteFollowingSteps;
    this.deleteFollowingRecommended = !!payload.deleteFollowingSteps;
    this.optionalDeleteFollowing = !!payload.optionalDeleteFollowing;
  }

  close(response: IDeleteActionModalResponse) {
    this.dialogRef.close(response);
  }

}
