import { UseCaseUtils } from '@app/components/audit-reports/use-cases/use-case.utils';

export const DoNotShareRuleName = 'Sample: Do Not Sell (is found)';
export const PrivacyPolicyFoundRuleName = 'Sample: Privacy Policy (is found)';

export const PagesMissingConsentManagerWidget = {
  topText: 'Pages Missing Consent Manager',
  topTooltip: 'Count of pages where no tag in the consent manager category were found. Click to view additional details.',
  topIconName: 'error',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'View pages missing Consent Manager',
    bodyText: {
      paid: 'Consent banners are required in many jurisdictions and are fast becoming a data privacy best practice worldwide. Any pages without a detected Consent Management Platform (CMP) should be evaluated for compliance with applicable standards.',
      guest: 'Consent banners are required in many jurisdictions and are fast becoming a data privacy best practice worldwide. Any pages without a detected Consent Management Platform (CMP) should be evaluated for compliance with applicable standards.',
      trial: 'Consent banners are required in many jurisdictions and are fast becoming a data privacy best practice worldwide. Any pages without a detected Consent Management Platform (CMP) should be evaluated for compliance with applicable standards.',
    }
  }
};

export const PagesMissingTagManagerWidget = {
  topText: 'Pages Missing Tag Manager',
  topTooltip: 'Count of pages where no tag in the tag manager category were found. Click to view additional details.',
  topIconName: 'error',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'View pages missing Tag Manager',
    bodyText: {
      paid: 'One of the best practices for ongoing governance of data collected on any website is centralized management of all tags via a Tag Management System (TMS). Every page should have a TMS present. Any pages without a detected TMS should be reviewed.',
      guest: 'One of the best practices for ongoing governance of data collected on any website is centralized management of all tags via a Tag Management System (TMS). Every page should have a TMS present. Any pages without a detected TMS should be reviewed.',
      trial: 'One of the best practices for ongoing governance of data collected on any website is centralized management of all tags via a Tag Management System (TMS). Every page should have a TMS present. Any pages without a detected TMS should be reviewed.',
    }
  }
};

export const PagesMissingPrivacyPolicyLinksWidget = {
  topText: 'Pages Missing Privacy Policy Link',
  topTooltip: 'Count of pages that were missing a privacy policy link.',
  topIconName: 'error',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'View pages missing Privacy Policy Link',
    bodyText: {
      paid: 'dynamic',
      guest: `Privacy Policy links are required on all pages in many jurisdictions. Any pages missing this link should be evaluated for compliance with applicable standards. This sample audit simply looked for a link called "Privacy Policy" on every page. For more accurate results based on your website's exact language, this phrase can be customized after starting a free trial or purchasing a full account.`,
      trial: 'dynamic',
    }
  }
};

export const PagesMissingShareSellLinksWidget = {
  topText: 'Pages Missing Do Not Sell/Share Link',
  topTooltip: 'Count of pages that were missing a do not share/sell link.',
  topIconName: 'error',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'View pages missing Do Not Sell/Share Link',
    bodyText: {
      paid: 'dynamic',
      guest: `The presence of a "Do Not Sell/Share My Personal Information" link on certain (potentially all) web pages is often deemed required to comply with California privacy law. Any pages missing this link may need to be evaluated for compliance with applicable standards. This sample audit simply looked for a link containing the phrase "Do Not Sell" on every page. For more accurate results based on your website's exact language, this phrase can be customized after starting a free trial or purchasing a full account.`,
      trial: 'dynamic',
    }
  }
};

export const NewJavascriptFilesWidget = {
  topText: 'New JavaScript Files',
  topTooltip: 'Count of new JavaScript files found since the last time this audit was run. Click to view additional details.',
  topIconName: 'error',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'dynamic',
    bodyText: {
      paid: 'Unauthorized JavaScript file additions or changes have the potential to introduce data privacy and security risk. We automatically monitor for new JavaScript files added to or changed on your web pages over time. All subsequent audit runs will be compared to previous runs.',
      guest: 'Unauthorized JavaScript file additions or changes have the potential to introduce data privacy and security risk. Upon upgrading to a free trial or a full paid account, we will automatically monitor for new JavaScript files added to or changed on your web pages over time.',
      trial: 'Unauthorized JavaScript file additions or changes have the potential to introduce data privacy and security risk. We automatically monitor for new JavaScript files added to or changed on your web pages over time. All subsequent audit runs will be compared to previous runs.',
    }
  }
};

export const UnapprovedItemsSection = {
  sectionIcon: 'hide_source',
  sectionTitle: 'Unapproved Items',
};

export const PrivacyPagesScannedWidget = {
  topText: 'Pages Scanned',
  topTooltip: 'Count of all unique pages scanned in this audit run. Click to view additional details.',
  bottomText: 'Audited URL',
  bodyContent: {
    navToText: 'View Pages report to get more detail',
    bodyText: {
      paid: 'The data privacy landscape is constantly changing. Monitor compliance with privacy standards by scanning a broad set of pages frequently. As a best practice, you should audit your full website at least quarterly.',
      guest: 'The data privacy landscape is constantly changing. Monitor compliance with privacy standards by scanning a broad set of pages frequently. As a best practice, you should audit your full website at least quarterly.',
      trial: 'The data privacy landscape is constantly changing. Monitor compliance with privacy standards by scanning a broad set of pages frequently. As a best practice, you should audit your full website at least quarterly.',
    }
  }
};

export const PrivacyUniqueCookiesStats = {
  min: '1',
  max: '3,178,469',
  median: '66',
  average: '3,173',
  ninetyFifthPercentile: '1,707',
  ninetyNinthPercentile: '45,972',
};

export const PrivacyUniqueCookiesWidget = {
  topText: 'Unique Cookies',
  topIconName: 'cookie',
  topTooltip: 'Count of cookies discovered during this audit run. Uniqueness is determined by all cookie parameters except path and value. Click to view additional details.',
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    PrivacyUniqueCookiesStats.median,
    PrivacyUniqueCookiesStats.average,
    PrivacyUniqueCookiesStats.min,
    PrivacyUniqueCookiesStats.max,
  ),
  bodyContent: {
    navToText: 'View cookies',
    bodyText: {
      paid: 'We will identify all cookies found on your web properties so you can ensure only approved cookies are being set. 3rd party cookies are created by domains that are not the domain being visited.',
      guest: 'We will identify all cookies found on your web properties so you can ensure only approved cookies are being set. 3rd party cookies are created by domains that are not the domain being visited.',
      trial: 'We will identify all cookies found on your web properties so you can ensure only approved cookies are being set. 3rd party cookies are created by domains that are not the domain being visited.',
    },
  },
  statsData: {
    headerText: '<div class="header-text-top">UNIQUE COOKIES</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...PrivacyUniqueCookiesStats,
  },
};

export const Privacy3rdPartyCookiesStats = {
  min: '1',
  max: '14,807',
  median: '25',
  average: '78',
  ninetyFifthPercentile: '707',
  ninetyNinthPercentile: '253',
};

export const Privacy3rdPartyCookiesWidget = {
  topText: '3rd Party<br> Cookie Domains',
  topIconName: 'cookie',
  topTooltip: 'Count of all unique 3rd party cookie domains. Click to view additional details.',
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    Privacy3rdPartyCookiesStats.median,
    Privacy3rdPartyCookiesStats.average,
    Privacy3rdPartyCookiesStats.min,
    Privacy3rdPartyCookiesStats.max,
  ),
  bodyContent: {
    navToText: 'View all 3rd party cookie domains',
    bodyText: {
      paid: 'Top 3rd party cookie domains found across all pages scanned:',
      guest: 'Top 3rd party cookie domains found across all pages scanned:',
      trial: 'Top 3rd party cookie domains found across all pages scanned:',
    },
  },
  statsData: {
    headerText: '<div class="header-text-top">3RD PARTY COOKIE DOMAINS</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...Privacy3rdPartyCookiesStats,
  }
};

export const PrivacyUniqueTagsStats = {
  min: '1',
  max: '613',
  median: '34',
  average: '50',
  ninetyFifthPercentile: '143',
  ninetyNinthPercentile: '293',
};

export const PrivacyUniqueTagsWidget = {
  topText: 'Unique Tags',
  topTooltip: 'Total number of unique tags (identified technologies) found in this audit run. Click to view additional details.',
  topIconName: 'local_offer',
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    PrivacyUniqueTagsStats.median,
    PrivacyUniqueTagsStats.average,
    PrivacyUniqueTagsStats.min,
    PrivacyUniqueTagsStats.max,
  ),
  bodyContent: {
    navToText: 'View tags found in this audit',
    bodyText: {
      paid: 'Most of the time, cookies and tags go hand in hand. JavaScript-based web technologies ("tags") typically set one or more cookies as part of their loading process. Which tags are loading on your site?',
      guest: 'Most of the time, cookies and tags go hand in hand. JavaScript-based web technologies ("tags") typically set one or more cookies as part of their loading process. Which tags are loading on your site?',
      trial: 'Most of the time, cookies and tags go hand in hand. JavaScript-based web technologies ("tags") typically set one or more cookies as part of their loading process. Which tags are loading on your site?',
    },
  },
  statsData: {
    headerText: '<div class="header-text-top">UNIQUE TAGS</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...PrivacyUniqueTagsStats,
  },
};

export const PrivacyUniqueGEOStats = {
  min: '1',
  max: '59',
  median: '3',
  average: '6',
  ninetyFifthPercentile: '30',
  ninetyNinthPercentile: '18',
};

export const PrivacyUniqueGEOWidget = {
  topText: 'Unique Geolocations',
  topTooltip: 'Count of unique geolocations where network requests resolved. Click to view additional details.',
  topIconName: 'language',
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    PrivacyUniqueGEOStats.median,
    PrivacyUniqueGEOStats.average,
    PrivacyUniqueGEOStats.min,
    PrivacyUniqueGEOStats.max,
  ),
  bodyContent: {
    navToText: 'View all geolocations',
    bodyText: {
      paid: 'Data privacy standards differ across jurisdictions, including those related to cross-border data transfers. Be sure you know where all data collected on your website is being sent.',
      guest: 'Data privacy standards differ across jurisdictions, including those related to cross-border data transfers. Be sure you know where all data collected on your website is being sent.',
      trial: 'Data privacy standards differ across jurisdictions, including those related to cross-border data transfers. Be sure you know where all data collected on your website is being sent.',
    },
  },
  statsData: {
    headerText: '<div class="header-text-top">UNIQUE GEOLOCATIONS</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...PrivacyUniqueGEOStats,
  },
};

export const UnapprovedCookiesWidget = {
  topText: 'Unapproved Cookies',
  topTooltip: 'Cookies discovered during this audit run and unapproved in one or more consent categories.',
  topIconOutline: true,
  topIconName: 'cookie',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'dynamic',
    bodyText: {
      paid: 'dynamic',
      guest: 'dynamic',
      trial: 'dynamic',
    }
  }
};

export const UnapprovedTagsWidget = {
  topText: 'Unapproved Tags',
  topIconOutline: true,
  topIconName: 'local_offer',
  topTooltip: 'Tags discovered during this audit run and unapproved in one or more consent categories.',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'dynamic',
    bodyText: {
      paid: 'dynamic',
      guest: 'dynamic',
      trial: 'dynamic',
    }
  }
};

export const UnapprovedGeoLocationsWidget = {
  topText: 'Unapproved Geolocations',
  topIconName: 'language',
  topTooltip: 'Geolocations discovered during this audit run and unapproved in one or more consent categories.',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'dynamic',
    bodyText: {
      paid: 'dynamic',
      guest: 'dynamic',
      trial: 'dynamic',
    }
  }
};
