import { CacheResetService } from './../core/services/cache-reset.service';
import { Injectable } from '@angular/core';

import { ILabel } from '@app/components/shared/services/label.service';

import { ApiService } from '../core/services/api.service';
import { environment } from '@app/environments/environment';

export enum EPcOs {
  Linux = 'Linux',
  Windows = 'Windows',
  Mac = 'MAC'
}

export enum EMobileOs {
  Android = 'Android',
  Ios = 'IOS',
  Windows = 'Windows'
}

export enum ETvOs {
  GoogleTv = 'Google TV',
  AppleTv = 'Apple TV',
  Lg = 'LG Electronics',
  FireTv = 'Fire TV',
  Xbox = 'xbox',
  Playstation = 'playstation',
  SmartTv = 'smarttv',
  Roku = 'Roku'
}

export type OsType = EPcOs | EMobileOs | ETvOs | string;

export enum EDeviceProfile {
  Mobile = 'mobile',
  Tv = 'tv',
  Pc = 'pc'
}

export interface IDeviceProfile {
  id: number;
  name: string;
  journeyType: EDeviceProfile;
  userId: number;
  folderId: number;
  createdAt: Date;
  updatedAt: Date;
  emails: string[];
  labels: ILabel[];
  osOfDevice: EMobileOs | ETvOs | EPcOs | string;
  versionOfOs: string;
  isNew?: boolean;
}

export interface IDeviceProfileRequest {
  name: string;
  journeyType: EDeviceProfile;
  folderId: number;
  emails: Array<string>;
  osOfDevice: EMobileOs | ETvOs | EPcOs | string;
  versionOfOs: string;
  useTransparentProxy: boolean;
}

export interface IDeviceCategory {
  name: string;
  imgDefault: string;
  imgActive: string;
  barClass: string;
  deviceProfileType: EDeviceProfile;
  osTypes: Array<EMobileOs | ETvOs | EPcOs>;
}

export const name = 'DeviceProfileService';

const apiUrl: string = environment.apiUrl + 'manual-journeys';

@Injectable({
  providedIn: 'root'
})
export class DeviceProfileService {
  categories: { [deviceProfileType: string]: IDeviceCategory } = {
    mobile: {
      name: 'Phone & Tablet',
      imgDefault: 'images/phone-tablet.png',
      imgActive: 'images/phone-tablet-active.png',
      barClass: 'bar-left',
      deviceProfileType: EDeviceProfile.Mobile,
      osTypes: Object.values(EMobileOs)
    },
    tv: {
      name: 'Smart TV & Game Console',
      imgDefault: 'images/smarttv-game-console.png',
      imgActive: 'images/smarttv-game-console-active.png',
      barClass: 'bar-center',
      deviceProfileType: EDeviceProfile.Tv,
      osTypes: Object.values(ETvOs)
    },
    pc: {
      name: 'PC & Desktop',
      imgDefault: 'images/pc-desktop.png',
      imgActive: 'images/pc-desktop-active.png',
      barClass: 'bar-right',
      deviceProfileType: EDeviceProfile.Pc,
      osTypes: Object.values(EPcOs)
    }
  };

  newDeviceProfileId: number;

  constructor(private api: ApiService, private cacheResetService: CacheResetService) {
    this.cacheResetService.reset$.subscribe(_ => {
      this.clearNewDeviceProfileId();
    });
  }

  getDeviceProfiles(additionalFields?: string[]) {
    var params = {
      additionalFields: additionalFields ? additionalFields.join(',') : ''
    };
    return this.api.get<IDeviceProfile[]>(`${apiUrl}/`, {
      params: params
    });
  }

  getDeviceCategories(): { [deviceProfileType: string]: IDeviceCategory } {
    return this.categories;
  }

  hasNewDeviceProfileId(): boolean {
    return typeof this.newDeviceProfileId !== 'undefined';
  }

  getNewDeviceProfileId(): number {
    return this.newDeviceProfileId;
  }

  clearNewDeviceProfileId(): void {
    this.newDeviceProfileId = undefined;
  }

  updateDeviceProfile(deviceProfileId: number, request: IDeviceProfileRequest) {
    return this.api.put<IDeviceProfile>(
      `${apiUrl}/${deviceProfileId}`,
      request
    );
  }

  updateDeviceProfileLabels(deviceProfileId: number, labels: ILabel[]) {
    return this.api.put<ILabel[]>(
      `${apiUrl}/${deviceProfileId}/labels`,
      labels
    );
  }

  updateDeviceProfileRules(deviceProfileId: number, ruleIds: number[]) {
    return this.api.put<number[]>(
      `${apiUrl}/${deviceProfileId}/rules`,
      ruleIds
    );
  }

  deleteDeviceProfileLabel(deviceProfileId: number, labelId: number) {
    return this.api.delete(
      `${apiUrl}/${deviceProfileId}/labels/${labelId}`
    );
  }

  removeDeviceProfile(deviceProfileId: number) {
    return this.api.delete(`${apiUrl}/${deviceProfileId}`);
  }

  shouldUseTransparentProxy(osOfDevice: string): boolean {
    return osOfDevice === ETvOs.FireTv || osOfDevice === ETvOs.Roku;
  }

  getADeviceProfile(deviceProfileId: number) {
    return this.api.get<IDeviceProfile>(`${apiUrl}/${deviceProfileId}`);
  }

  getDeviceProfileLabels(deviceProfileId: number) {
    return this.api.get(`${apiUrl}/${deviceProfileId}/labels`);
  }

  getDeviceProfileRules(deviceProfileId: number) {
    return this.api.get<number[]>(
      `${apiUrl}/${deviceProfileId}/rules`
    );
  }

  saveNewDeviceProfileId(newId: number): void {
    this.newDeviceProfileId = newId;
  }

  createDeviceProfile(request: IDeviceProfileRequest) {
    return this.api.post<IDeviceProfile>(`${apiUrl}`, request);
  }
}
