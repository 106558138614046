import { Injectable, OnDestroy } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Observable, Subject } from 'rxjs';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import {
  TagHealthPages,
  TagHealthPagesTablePagination,
  TagHealthSummary,
  TagHealthTagAccounts,
  TagHealthTags,
  TagHealthTrends,
  TagHealthTrendsByName
} from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { takeUntil } from 'rxjs/operators';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class TagHealthService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  /**
   * Caching below based on the following:
   * https://medium.com/@dilanthaprasanjith/caching-http-requests-with-rxjs-in-angular-9f7fa44387ce
   */

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getTagHealthSummary(auditId: number, runId: number, body?: IAuditReportApiPostBody): Observable<TagHealthSummary> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-health`, body);
  }

  getTagHealthTrends(auditId: number, runId: number): Observable<TagHealthTrends> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-health/trends`);
  }

  getTagHealthTrendsByName(auditId: number, trendName: string, days: number): Observable<TagHealthTrendsByName> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/reports/tag-health/trends/${trendName}?days=${days}`);
  }

  getTagHealthPages(auditId: number, runId: number, pagination: TagHealthPagesTablePagination, body?: IAuditReportApiPostBody): Observable<TagHealthPages> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-health/pages`;
    const queryParams = `?page=${pagination.page}&size=${pagination.size}&sortBy=${pagination.sortBy}&sortDesc=${pagination.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getTagHealthTags(auditId: number, runId: number, body?: IAuditReportApiPostBody): Observable<TagHealthTags> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-health/tags`, body);
  }

  getTagHealthTagAccounts(auditId: number, runId: number, tagId: number, body?: IAuditReportApiPostBody): Observable<TagHealthTagAccounts> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-health/tags/${tagId}/accounts`, body);
  }

}
