import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EConsentCategoryType } from '@app/components/consent-categories/consent-categories.models';
import { IStandardsSelectorItem } from '../op-standards-selector.models';

@Component({
  selector: 'op-standards-selector-row',
  templateUrl: './op-standards-selector-row.component.html',
  styleUrls: ['./op-standards-selector-row.component.scss']
})
export class OpStandardsSelectorRowComponent {
  @Input() standard: IStandardsSelectorItem;
  @Input() availableConsentCatType: EConsentCategoryType;
  @Input() isSelected: boolean = false;
  @Output() addToList: EventEmitter<IStandardsSelectorItem> = new EventEmitter();
  @Output() removeFromList: EventEmitter<IStandardsSelectorItem> = new EventEmitter();
  @Output() editStandard: EventEmitter<IStandardsSelectorItem> = new EventEmitter();
}
