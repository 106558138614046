import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const ActionSetStateNames = {
  base: 'action_library'
};

export const ActionSetLibraryPaths = {
  base: 'action-set-library'
};

export const ActionSetLibraryUrlBuilders = {
  base: () => RouteBuilder.buildUrl([ActionSetLibraryPaths.base])
};
