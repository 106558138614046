import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { BehaviorSubject, EMPTY, iif, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import {
  AuditReportFilterBarService,
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData,
} from '@app/components/shared/components/split-card/split-card.models';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import {
  ISparklineChartData,
  ISparklineRunInfo,
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { formatNumber } from '@angular/common';
import {
  IFullscreenChartData,
  IFullscreenChartDataWithStats,
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent,
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import {
  APPROVED_CHART_CONFIG,
  UNAPPROVED_CHART_CONFIG,
  UNIQUE_CHART_CONFIG,
} from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.constants';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { TagHealthTrendsByName } from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import { sortBy } from 'lodash-es';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import { PrivacyCookiesService } from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.service';
import {
  CookiesPrivacyPagesExportMenuData,
  CookiesPrivacyRelevantFilters,
  ICookiePrivacyComplianceItem,
  ICookiePrivacyPagesTableRow,
  ICookiePrivacyPagesTableState,
  ICookiePrivacyRunOverview,
  ICookiePrivacySpecificFilters,
  ICookiePrivacySummary,
} from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.models';
import { EConsentCategoryComplianceStatus } from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  AddConsentCategoriesModalComponent,
} from '@app/components/audit-reports/reports/privacy/add-consent-categories-modal/add-consent-categories-modal.component';
import {
  ReprocessConfirmationSnackbarService,
} from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.service';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import {
  EConsentCategoryType,
  EDomainType,
  ENameType,
  IConsentCategories,
  IConsentCategorySnapshot,
} from '@app/components/consent-categories/consent-categories.models';
import {
  ICookiePrivacyComplianceRow,
} from '@app/components/audit-reports/reports/privacy-cookies/components/privacy-cookies-compliance-table/privacy-cookies-compliance-table.models';
import { ECCEditTabs } from '@app/components/consent-categories/cc-edit/cc-edit.constants';
import { ConsentCategoriesEditComponent } from '@app/components/consent-categories/cc-edit/cc-edit.component';
import {
  IReprocessService,
  ReprocessBannerStatus,
} from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { IAuditModel } from '@app/components/modals/modalData';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { EPrivacyCookiesExportType } from './privacy-cookies.enums';
import {
  EAuditReportFilterTypes,
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { RouteReloadService } from '@app/components/shared/services/route-reload.service';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { AlertMetricType, EAlertPrivacyCookiesMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import {
  IAuditReportPageDetailsDrawerService,
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { EFreeTrialAdModalType } from '../../audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import { FreeTrialAdModalComponent } from '../../audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import {
  PrivacyCookiesPagesTableComponent,
} from '@app/components/audit-reports/reports/privacy-cookies/components/privacy-cookies-pages-table/privacy-cookies-pages-table.component';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  CommonPagesColumnConfigWarningMessage,
  CommonPagesConfigLocalStorageKey,
  CommonReportsPagesTableColumns,
} from '@app/components/audit-reports/reports/general-reports.constants';
import { PrivacyReportsAbstract } from '@app/components/audit-reports/reports/abstracts/privacy-reports.abstract';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { ConsentCategoryCreateComponent } from '@app/components/consent-categories/cc-create/cc-create.component';
import { EConsentCategoryCreateStep } from '@app/components/consent-categories/cc-create/cc-create.enums';
import { PagesTableColumns } from './components/privacy-cookies-pages-table/privacy-cookies-pages-table.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'privacy-cookies',
  templateUrl: './privacy-cookies.component.html',
  styleUrls: ['./privacy-cookies.component.scss'],
  providers: [ResizeableTableService],
})
export class PrivacyCookiesComponent
  extends PrivacyReportsAbstract
  implements IFilterableAuditReport, OnInit, OnDestroy {
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  readonly PagesTableColumns = PagesTableColumns;
  @ViewChild(PrivacyCookiesPagesTableComponent) privacyCookiesPagesTableComponent: PrivacyCookiesPagesTableComponent;

  // GENERAL
  audit: IAuditModel;
  auditId: number;
  runId: number;
  private isLoaded: boolean;
  private apiFilters: IAuditReportApiPostBody = {};
  private filtersUpdated$ = new Subject();
  private widgetSparklineRunsInfo: ISparklineRunInfo[] = [];

  selectedCookie: ICookiePrivacyComplianceItem;

  //Consent Categories
  isLoadedAuditConsentCategories = false;
  isLoadedRunConsentCategories = false;
  auditConsentCategories: IConsentCategories[] = [];
  ccsAssignedToRun: IConsentCategorySnapshot[] = [];
  categoriesType: ECCEditTabs = ECCEditTabs.cookies;

  cookiePrivacyPagesTableState: ICookiePrivacyPagesTableState = {
    sort: { sortBy: CommonReportsPagesTableColumns.CookieCount, sortDesc: true },
    pagination: { size: 200, page: 0 },
    pagesTotal: 0,
  };

  cookiePrivacyPages: ICookiePrivacyPagesTableRow[];
  cookiePrivacyPagesSpinnerState: EFilterSpinnerState;

  totalPageCount = 0;
  filteredPageCount = 0;

  unapprovedCookiesCount: number;

  @ViewChild('pagesSection') pagesSection: ElementRef;

  readonly EFilterSpinnerState = EFilterSpinnerState;

  // WIDGETS
  widgetsState = EFilterSpinnerState.Loading;

  widgetPagesScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    metricType: EAlertPrivacyCookiesMetric.PagesScanned,
  };

  widgetWithUnapprovedCookiesPages: ISplitCardChartData = {
    topLabel: 'Pages with Unapproved Cookies',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.addGlobalFilterByStatus(EConsentCategoryComplianceStatus.Unapproved),
    metricType: EAlertPrivacyCookiesMetric.PagesWithUnapprovedCookies,
  };

  widgetUniqueCookies: ISplitCardChartData = {
    topLabel: 'Unique Cookies',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openUniqueCookiesFullscreenChart.bind(this),
    metricType: EAlertPrivacyCookiesMetric.UniqueCookies,
  };
  widgetUniqueCookiesSparklineData: ISparklineChartData[] = [];

  widgetApprovedCookies: ISplitCardChartData = {
    topLabel: 'Approved Cookies',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.addGlobalFilterByStatus(EConsentCategoryComplianceStatus.Approved),
    bottomHandler: this.openApprovedCookiesFullscreenChart.bind(this),
    metricType: EAlertPrivacyCookiesMetric.ApprovedCookies,
  };
  widgetApprovedCookiesSparklineData: ISparklineChartData[] = [];

  widgetUnapprovedCookies: ISplitCardChartData = {
    topLabel: 'Unapproved Cookies',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.addGlobalFilterByStatus(EConsentCategoryComplianceStatus.Unapproved),
    bottomHandler: this.openUnapprovedFullscreenChart.bind(this),
    metricType: EAlertPrivacyCookiesMetric.UnapprovedCookies,
  };
  widgetUnapprovedCookiesSparklineData: ISparklineChartData[] = [];

  filteredWidget: EConsentCategoryComplianceStatus;
  EConsentCategoryComplianceStatus = EConsentCategoryComplianceStatus;
  sparklineDataLoaded: boolean = false;

  // EXPORTS
  pagesExportConfig: CookiesPrivacyPagesExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: EPrivacyCookiesExportType.pages,
    totalRows: this.totalPageCount,
    filteredRows: this.filteredPageCount,
    tableState: {
      ...this.cookiePrivacyPagesTableState.pagination,
      ...this.cookiePrivacyPagesTableState.sort,
    },
    filters: this.apiFilters,
    specificExportTypes: {
      all: EPrivacyCookiesExportType.pages,
    },
    dataToCopy: {
      config: [
        {
          property: 'pageUrl',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl,
        },
        {
          property: 'finalPageUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl,
        },
        {
          property: 'pageLoadTime',
          displayLike: row => parseFloat((row.pageLoadTime / 1000).toFixed(1)),
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime,
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode,
        },
        {
          title: '# OF COOKIES',
          property: 'cookieCount',
          tableColumnName: CommonReportsPagesTableColumns.CookieCount,
        },
      ],
      data: null,
      displayedColumns$: this.tableService.displayedColumns$,
    },
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;
  allLabels: ILabel[];

  private isVisitorMode: boolean;

  constructor(
    private route: ActivatedRoute,
    private routeReloadService: RouteReloadService,
    private cookiePrivacyService: PrivacyCookiesService,
    private auditReportLoadingService: AuditReportLoadingService,
    protected modalService: OpModalService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private scrollService: AuditReportScrollService,
    private ccService: ConsentCategoriesService,
    private reprocessConfirmationSnackbarService: ReprocessConfirmationSnackbarService,
    private reprocessService: IReprocessService,
    private auditService: DiscoveryAuditService,
    private alertReportingService: AlertReportingService,
    private tableService: ResizeableTableService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private applicationChromeService: ApplicationChromeService,
    private labelService: LabelService,
  ) {
    super();
  }

  ngOnInit() {
    this.labelService.getLabels().subscribe(labels => {
      this.allLabels = labels;
    });

    this.route.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ auditId, runId }) => {
        this.auditId = +auditId;
        this.runId = +runId;

        const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
        const alertId = queryParams?.alertId;
        const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
        this.preventHighlight = !highlight;
        // When loading report from an alert, first override filters with alert
        // filters before loading report data
        if (alertId) {
          this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
            this.filterBarService.overrideFilters(alert.config.filtersV0);
            this.highlightMetricType = highlight && alert.config.metricType;

            this.alertCheckComplete$.next(true);
          });
        } else {
          this.alertCheckComplete$.next(true);
        }

        this.getConsentCategoriesList();

        if (this.isLoaded) {
          // only fire when calendar control updated
          this.alertCheckComplete$.pipe(
            takeUntil(this.onDestroy$),
          ).subscribe(() => {
            this.onFiltersChanged(this.apiFilters);
          });
        }
      });

    this.routeReloadService.reloadRouteEvents$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getAudit();
        this.getAuditConsentCategoriesList();
      });

    this.applicationChromeService.isVisitorMode$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: isVisitorMode => this.isVisitorMode = isVisitorMode,
      });

    this.getAudit();
    this.getAuditConsentCategoriesList();
    this.initFilters();

    this.isLoaded = true;
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  ngOnDestroy() {
    this.auditReportLoadingService.forceOff();
    this.destroy();
    this.onDestroy$.next();
    this.pageDetailsDrawerService.closePageDetails();
  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(CookiesPrivacyRelevantFilters);

    // ReplaySubject in filterBarService means this executes immediately
    this.filterBarService
      .apiPostBody$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody) {
    this.selectedCookie = null;

    this.apiFilters = apiPostBody;
    this.filtersUpdated$.next();
    this.updateCurrentFilters();

    this.filteredWidget = apiPostBody.consentCategoryComplianceStatus;

    this.loadWidgets();
    this.loadSparklines();

    this.cookiePrivacyPagesTableState.pagination.page = 0;
    this.loadPages();
  }

  handleSelectedCookieClicked(selectedCookie: ICookiePrivacyComplianceItem): void {
    this.selectedCookie = (this.selectedCookie === selectedCookie) ? null : selectedCookie;
    this.cookiePrivacyPagesTableState.pagination.page = 0;
    this.loadPages();
  }

  updatePagesState() {
    this.loadPages();
    this.scrollService.scrollByElement(this.pagesSection.nativeElement);
  }

  editConsentCategories(item: IConsentCategorySnapshot) {
    this.modalService
      .openFixedSizeModal(ConsentCategoriesEditComponent, {
        disableClose: true,
        data: {
          tab: this.categoriesType,
          consentCategoryId: item.consentCategoryId,
        },
      })
      .afterClosed()
      .subscribe(isUpdated => {
        if (isUpdated) {
          this.showReprocessModal();
        }
      });
  }

  handleSetConsentCategoryNameFilterFromCompliance(row: ICookiePrivacyComplianceRow): void {
    const cc = this.ccsAssignedToRun.find(cc => cc.consentCategorySnapshotId === row.consentCategorySnapshotId);
    if (cc) {
      this.filterBarService.addConsentCategoryNameFilter(cc.consentCategoryId, cc.name);
    }
  }

  public openUniqueCookiesFullscreenChart() {
    if (this.widgetUniqueCookiesSparklineData.length > 1) {
      this.openFullscreenChart(EConsentCategoryComplianceStatus.Unique, this.showTimelineInCharts());
    }
  }

  public openApprovedCookiesFullscreenChart() {
    if (this.widgetApprovedCookiesSparklineData.length > 1) {
      this.openFullscreenChart(EConsentCategoryComplianceStatus.Approved, this.showTimelineInCharts());
    }
  }

  public openUnapprovedFullscreenChart() {
    if (this.widgetUnapprovedCookiesSparklineData.length > 1) {
      this.openFullscreenChart(EConsentCategoryComplianceStatus.Unapproved, this.showTimelineInCharts());
    }
  }

  public toggleWidgetFilter(cookieType: EConsentCategoryComplianceStatus) {
    return () => {
      let widget = this.getWidgetConfig(cookieType);

      if (this.filteredWidget) {
        let activatedWidget = this.getWidgetConfig(this.filteredWidget);

        if (widget !== activatedWidget) {
          activatedWidget.menuOptions[0].isActivated = false;
        }
      }

      if (!widget.menuOptions[0].isActivated) {
        widget.menuOptions[0].isActivated = true;
        this.filteredWidget = cookieType;
      } else {
        widget.menuOptions[0].isActivated = false;
        this.filteredWidget = null;
      }

      this.onFiltersChanged(this.apiFilters);
    };
  }

  public addGlobalFilterByStatus(status: EConsentCategoryComplianceStatus) {
    return () => {
      if (this.filteredWidget === status) {
        this.filteredWidget = null;
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.ConsentCategoryComplianceStatus);
      } else {
        this.filteredWidget = status;
        this.filterBarService.addConsentCategoryStatusFilter(status);
      }
    };
  }

  addToConsentCategoryClicked(item: ICookiePrivacyComplianceRow) {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      this.addToConsentCategories(item);
    }
  }

  private openFreeTrialAdModal() {
    const data = {
      type: EFreeTrialAdModalType.CONSENT_CATEGORY,
    };

    this.modalService.openModal(FreeTrialAdModalComponent, { data });
  }

  private addSelectionToConsentCategories(items): void {
    this.modalService
      .openModal(AddConsentCategoriesModalComponent, {
        data: {
          runId: this.runId,
          auditId: this.auditId,
          auditName: this.audit.name,
          items,
        },
        autoFocus: false,
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
      });
  }

  private addToConsentCategories(item: ICookiePrivacyComplianceRow) {
    const ccItem = {
      domain: item.cookieDomain || '',
      name: item.cookieName || '',
      nameType: ENameType.EXACT,
      domainType: EDomainType.EXACT,
    };

    this.modalService
      .openModal(AddConsentCategoriesModalComponent, {
        data: {
          runId: this.runId,
          auditId: this.auditId,
          auditName: this.audit.name,
          items: {
            cookies: [ccItem],
            tags: [],
            requestDomains: [],
          },
        },
        autoFocus: false,
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
      });
  }

  openConsentCategoryManager(): void {
    const assignToCCConfig = {
      data: {
        skipCreate: true,
        step: EConsentCategoryCreateStep.COOKIES,
        noSelectionDisplay: true,
        type: !this.ccsAssignedToRun?.length ? 'assign' : this.ccsAssignedToRun[0]?.type === EConsentCategoryType.UNAPPROVED ? 'unapproved' : 'approved',
        editing: true,
        cookies: [],
        tags: [],
        requestDomains: [],
        auditId: this.auditId,
      },
    };

    this.modalService.openFixedSizeModal(ConsentCategoryCreateComponent, assignToCCConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe((ccData) => {
        if (ccData) {
          this.addSelectionToConsentCategories(ccData);
        }
      });
  }

  private openFullscreenChart(
    trendName: EConsentCategoryComplianceStatus,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[],
  ) {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService
      .openFullscreenModal(
        FullscreenChartModalComponent,
        {
          data: {
            timeframeOriginRunCompletion: secondToLastCompletionDate,
            getData: (days: number) => this.getFullscreenChartData(trendName, days),
            getSummaryLines,
            chartConfig: this.getFullscreenChartConfig(trendName),
          },
        },
      )
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  private getFullscreenChartData(trendName: EConsentCategoryComplianceStatus, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.cookiePrivacyService
      .getCookiePrivacyTrend(this.auditId, trendName + '_cookies', days)
      .pipe(
        map((data: TagHealthTrendsByName) => {
          return {
            chartData: data.runs.map(item => {
              return {
                value: item.trendValue,
                date: item.completedAt,
              };
            }),
          };
        }),
      );
  }

  private updateWidgets(summary: ICookiePrivacySummary) {
    this.totalPageCount = summary.totalPageCount;
    this.filteredPageCount = summary.filteredPageCount;

    this.pagesExportConfig = {
      ...this.pagesExportConfig,
      totalRows: this.totalPageCount,
      filteredRows: this.filteredPageCount,
    };

    this.unapprovedCookiesCount = summary.filteredPageWithUnapprovedCookieCount;

    this.widgetUniqueCookies.topChangeContent = this.formatWidgetContent(summary.filteredUniqueCookieCount, summary.totalUniqueCookieCount);
    this.widgetUniqueCookies.rawValue = summary.filteredUniqueCookieCount;
    this.widgetUniqueCookies.topChangeMeaning = ESplitCardChangeMeaning.NEUTRAL;

    this.widgetApprovedCookies.topChangeContent = this.formatWidgetContent(summary.filteredApprovedCookieCount, summary.totalApprovedCookieCount);
    this.widgetApprovedCookies.rawValue = summary.filteredApprovedCookieCount;
    this.widgetApprovedCookies.topChangeMeaning = summary.filteredApprovedCookieCount === 0
                                                  ? ESplitCardChangeMeaning.NEUTRAL
                                                  : ESplitCardChangeMeaning.POSITIVE;

    this.widgetUnapprovedCookies.topChangeContent = this.formatWidgetContent(summary.filteredUnapprovedCookieCount, summary.totalUnapprovedCookieCount);
    this.widgetUnapprovedCookies.topChangeMeaning = summary.filteredUnapprovedCookieCount === 0
                                                    ? ESplitCardChangeMeaning.NEUTRAL
                                                    : ESplitCardChangeMeaning.NEGATIVE;

  }

  private updateSparklines(dataPoints: ICookiePrivacyRunOverview[]) {
    const approved: ISparklineChartData[] = [];
    const unapproved: ISparklineChartData[] = [];
    const unique: ISparklineChartData[] = [];

    const runsInfo: ISparklineRunInfo[] = [];

    dataPoints.forEach((dataPoint, index: number) => {
      approved.push({ value: dataPoint.totalApprovedCookieCount, sequence: index });
      unapproved.push({ value: dataPoint.totalUnapprovedCookieCount, sequence: index });
      unique.push({ value: dataPoint.totalUniqueCookieCount, sequence: index });
      runsInfo.push({ runId: dataPoint.runId, runCompletionDate: dataPoint.completedAt });
    });

    this.widgetUniqueCookies.bottomHandler = unique.length < 2 ? null : this.widgetUniqueCookies.bottomHandler;
    this.widgetApprovedCookies.bottomHandler = approved.length < 2 ? null : this.widgetApprovedCookies.bottomHandler;
    this.widgetUnapprovedCookies.bottomHandler = unapproved.length < 2 ? null : this.widgetUnapprovedCookies.bottomHandler;

    this.widgetUniqueCookiesSparklineData = unique;
    this.widgetApprovedCookiesSparklineData = approved;
    this.widgetUnapprovedCookiesSparklineData = unapproved;

    this.widgetSparklineRunsInfo = runsInfo;
  }

  private formatWidgetContent(filteredCount: number, totalCount?: number): string {
    const format = (x: number) => formatNumber(x, 'en-US');
    if (!this.isFiltered() || (this.isFiltered() && !totalCount)) {
      return format(filteredCount);
    } else {
      return `${format(filteredCount)} of ${format(totalCount)}`;
    }
  }

  private isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.length > 0 || !!this.filteredWidget;
  }

  private getFullscreenChartConfig(trendName: EConsentCategoryComplianceStatus) {
    switch (trendName) {
      case EConsentCategoryComplianceStatus.Unique:
        return UNAPPROVED_CHART_CONFIG;
      case EConsentCategoryComplianceStatus.Approved:
        return APPROVED_CHART_CONFIG;
      case EConsentCategoryComplianceStatus.Unapproved:
        return UNIQUE_CHART_CONFIG;
      default:
        return null;
    }
  }

  private getWidgetConfig(level: EConsentCategoryComplianceStatus) {
    switch (level) {
      case EConsentCategoryComplianceStatus.Unique:
        return this.widgetUnapprovedCookies;
      case EConsentCategoryComplianceStatus.Approved:
        return this.widgetUniqueCookies;
      case EConsentCategoryComplianceStatus.Unapproved:
        return this.widgetApprovedCookies;
    }
  }

  private totalFilters() {
    return {
      ...this.apiFilters,
    };
  }

  private calcSpinnerState(): EFilterSpinnerState {
    return this.filterBarService.currentFilters.length || Object.keys(this.apiFilters).length || this.filteredWidget || this.selectedCookie
           ? EFilterSpinnerState.Filtered
           : EFilterSpinnerState.None;
  }

  private loadPages() {
    this.cookiePrivacyPagesSpinnerState = EFilterSpinnerState.Loading;
    const filters: ICookiePrivacySpecificFilters & IAuditReportApiPostBody = {
      ...this.totalFilters(),
    };

    if (this.selectedCookie) {
      filters.cookieName = this.selectedCookie.cookieName;
      filters.cookieDomain = this.selectedCookie.cookieDomain;
    }

    of(!!this.selectedCookie)
      .pipe(
        switchMap(isSelectedCookie => iif(
            () => isSelectedCookie,
            this.cookiePrivacyService
              .getCookiePrivacySpecificPages(
                this.auditId,
                this.runId,
                this.cookiePrivacyPagesTableState,
                filters,
              ),
            this.cookiePrivacyService
              .getCookiePrivacyPages(
                this.auditId,
                this.runId,
                this.cookiePrivacyPagesTableState,
                filters,
              ),
          ),
        ),
      )
      .subscribe(({ pages, metadata }) => {
        this.pagesExportConfig.dataToCopy.data = this.cookiePrivacyPages = pages;
        this.cookiePrivacyPagesTableState.pagesTotal = metadata.pagination.totalCount;

        if (!!this.selectedCookie) {
          this.scrollService.scrollByElement(this.pagesSection.nativeElement);
        }

        this.pagesExportConfig = {
          ...this.pagesExportConfig,
          filters,
          filteredRows: metadata.pagination.totalCount,
          exportType: this.selectedCookie ? EPrivacyCookiesExportType.cookiePages : EPrivacyCookiesExportType.pages,
          tableState: {
            ...this.cookiePrivacyPagesTableState.pagination,
            ...this.cookiePrivacyPagesTableState.sort,
          },
        };

        this.cookiePrivacyPagesSpinnerState = this.calcSpinnerState();
      });
  }

  private loadWidgets() {
    this.auditReportLoadingService.addLoadingToken();
    this.widgetsState = EFilterSpinnerState.Loading;

    this.cookiePrivacyService
      .getCookiesPrivacySummary(this.auditId, this.runId, this.totalFilters())
      .pipe(catchError(() => EMPTY))
      .subscribe((data: ICookiePrivacySummary | undefined) => {
        if (data) {
          this.updateWidgets(data);
        } else {
          console.error('Sorry! Some elements failed to update. Refresh your browser to try again.');
        }

        this.widgetsState = this.isFiltered() ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
        this.auditReportLoadingService.removeLoadingToken();
      });
  }

  private loadSparklines() {
    this.auditReportLoadingService.addLoadingToken();
    this.sparklineDataLoaded = false;

    this.cookiePrivacyService.getCookiePrivacyTrends(this.auditId, this.runId, this.apiFilters)
      .pipe(
        map(data => sortBy(data.runs, element => element.runId)),
        catchError(() => EMPTY),
        tap(() => {
          this.auditReportLoadingService.removeLoadingToken();
        }),
      )
      .subscribe((data: ICookiePrivacyRunOverview[] | undefined) => {
        if (data) {
          this.updateSparklines(data);
        }

        this.sparklineDataLoaded = true;
      });
  }

  private showTimelineInCharts() {
    return this.widgetSparklineRunsInfo.length > 1 ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate : undefined;
  }

  private getConsentCategoriesList() {
    this.ccService
      .getConsentCategoriesAssignedToRun(this.auditId, this.runId)
      .subscribe(ccs => {
        this.ccsAssignedToRun = ccs;
        this.isLoadedRunConsentCategories = true;
      });
  }

  private getAuditConsentCategoriesList() {
    this.ccService
      .getConsentCategoriesAssignedToAudit(this.auditId)
      .subscribe(ccs => {
        this.auditConsentCategories = ccs;
        this.isLoadedAuditConsentCategories = true;
      });
  }

  private getAudit() {
    this.auditService
      .getAudit(this.auditId)
      .then(audit => this.audit = audit);
  }

  private showReprocessModal() {
    this.ccService.getConsentCategoriesAssignedToAudit(this.auditId)
      .pipe(
        catchError(err => {
          console.error(`Failed to load consent category snapshots for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.message}`);
          return EMPTY;
        }),
      )
      .subscribe((consentCategories) => {
        this.reprocessConfirmationSnackbarService.showReprocessConsentCategories(consentCategories, () => {
          const ids = consentCategories.map(i => i.id);
          this.ccService.reprocessConsentCategories(this.auditId, this.runId, ids).subscribe(
            () => {
              this.reprocessService.reprocessComplete();
              this.reprocessService.subscribeOnAuditReprocessingConsentCategoriesUpdates(this.auditId, this.runId, this.audit.name);
              this.reprocessService.updateAuditReprocessConsentCategoriesBannerStatus(this.auditId, this.runId, ReprocessBannerStatus.inProgress);
            },
            error => {
              if (error.code === 423) this.reprocessService.displayAuditConsentCategoriesReprocessInProgressToast();
            },
          );
        });
      });
  }
}
