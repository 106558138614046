import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  IStatsPopupPanelData
} from '@app/components/audit-reports/use-cases/stats-popup-panel/stats-popup-panel.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'stats-popup-panel',
  templateUrl: './stats-popup-panel.component.html',
  styleUrls: ['./stats-popup-panel.component.scss']
})
export class StatsPopupPanelComponent {
  @Input() data: IStatsPopupPanelData;

  constructor() {}
}
