export enum EUsageTabTitleChartIds {
  Scanned,
  Available,
  Pacing,
  Total,
  Filtered
}

export enum EUsageTabTitleChartTypes {
  WebJourney = 'WEB_JOURNEY',
  Audit = 'AUDIT'
}
