import { combineLatest, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  IPage,
  IComparisonReportPagesStateParams
} from '../ror-comparison-report.models';
import { RorComparisonReportService } from '../ror-comparison-report.service';
import { allComparisonsPlaceholder } from '../ror-comparison-report.contants';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-comparison-pages',
  templateUrl: './ror-comparison-pages.component.html',
  styleUrls: ['./ror-comparison-pages.component.scss']
})
export class RorComparisonPagesComponent implements OnInit, OnDestroy {

  pages: IPage[] = [];

  private destroySubject = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute,
              private comparisonReportService: RorComparisonReportService
  ) {}

  ngOnInit() {
    combineLatest(
      [this.activatedRoute.params, this.activatedRoute.queryParams]
    ).pipe(
      map(([params, queryParams]) => ({...params, ...queryParams})),
      takeUntil(this.destroySubject)
    ).subscribe((params: IComparisonReportPagesStateParams) => {
      const comparisonSnapshotId = params.comparisonSnapshotId ? 
        parseInt(params.comparisonSnapshotId) : 
        allComparisonsPlaceholder.comparisonSnapshotId;
      this.comparisonReportService.getTagPresencePages(
          +params.auditId,
          +params.runId,
          comparisonSnapshotId,
          +params.tagId,
          +params.tagPresenceDiff
        )
        .subscribe(pages => {
          this.pages = pages;
          this.comparisonReportService.setTagPresencePagesCache(pages);
        });
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  onClick(page: IPage) {
    // TODO: implement
    // this.stateService.go(Routes.discoveryAuditsPageInformationView.state, {
    //   origin: EOrigin.ComparisonsTagPresense,
    //   runId: String(page.runId),
    //   pageId: String(page.pageId),
    // });
  }
}
