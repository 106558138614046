import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IEmailInbox } from '@app/components/email-inboxes/email-inboxes.models';

@Component({
  selector: 'op-email-inbox-editor-notifications',
  templateUrl: './email-inbox-editor-notifications.component.html',
  styleUrls: ['./email-inbox-editor-notifications.component.scss']
})
export class EmailInboxEditorNotificationsComponent {
  private _emailInboxData: IEmailInbox;

  onProcessedSubscribers: UntypedFormControl;
  onReceivedSubscribers: UntypedFormControl;
  confirmationCheckbox: UntypedFormControl = new UntypedFormControl(false);

  @Input() set emailInboxData(data: IEmailInbox) {
    this.onReceivedSubscribers = new UntypedFormControl(data?.subscribers.onReceived);
    this.onProcessedSubscribers = new UntypedFormControl(data?.subscribers.onProcessed);
    this.confirmationCheckbox = new UntypedFormControl(!!data?.subscribers.onReceived?.length);
    this._emailInboxData = data;

    this.updateShouldSaveReceivedSubscribers();
  }

  @Output() processedSubscribersChanged = new EventEmitter<string[]>();
  @Output() receivedSubscribersChanged = new EventEmitter<string[]>();
  @Output() shouldSaveReceivedSubscribersChanged = new EventEmitter<boolean>();

  get emailInboxData(): IEmailInbox {
    return this._emailInboxData;
  }

  get confirmationCheckboxValue(): boolean {
    return this.confirmationCheckbox?.value;
  }

  updateProcessedSubscribers(emails: string[]): void {
    this.processedSubscribersChanged.emit(emails);
  }

  updateReceivedSubscribers(emails: string[]): void {
    this.receivedSubscribersChanged.emit(emails);
  }

  updateShouldSaveReceivedSubscribers(): void {
    this.shouldSaveReceivedSubscribersChanged.emit(this.confirmationCheckbox.value);
  }
}
