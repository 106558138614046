import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable ,  of ,  timer } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ApiService } from '@app/components/core/services/api.service';
import { ServerErrorCodes } from '@app/moonbeamConstants';
import { CustomEncoder } from '@app/components/core/services/api.utils';
import { environment } from '@app/environments/environment';
import { IRFMConfigV3, IRFMConfigV3CreationRequest } from '../shared/remoteFileMapping/remote-file-mapping.component';

const root: string = environment.apiUrl;
const rootV3: string = `${environment.apiV3Url}remote-file-mappings`;
const getFileSizeEndpoint = root + 'files/size-check';

@Injectable()
export class RemoteFileMapService {

  constructor(private apiService: ApiService) { }

  getRfmConfigs(): Observable<IRFMConfigV3[]> {
    return this.apiService.get(rootV3);
  }

  getRfmConfig(rfmId: number): Observable<IRFMConfigV3> {
    return this.apiService.get(`${rootV3}/${rfmId}`);
  }

  createRfmConfig(config: IRFMConfigV3CreationRequest): Observable<IRFMConfigV3> {
    return this.apiService.post(rootV3, config);
  }

  updateRfmConfig(config: IRFMConfigV3): Observable<IRFMConfigV3> {
    return this.apiService.put(`${rootV3}/${config.id}`, config);
  }

  deleteRfmConfig(rfmId: number): Observable<IRFMConfigV3> {
    return this.apiService.delete(`${rootV3}/${rfmId}`);
  }

  remoteResourceSizeValidator(options: { debounce: number; }): AsyncValidatorFn {
    return (c: AbstractControl): Observable<{ [key: string]: boolean }> => {
      return timer(options.debounce).pipe(
        switchMap(() => {
          if (!c.value) return of(null);
          return this.checkRemoteResourceSize(c.value);
        })
      );
    };
  }

  private checkRemoteResourceSize(url: string): Observable<ValidationErrors> {
    const params = new HttpParams({encoder: new CustomEncoder()}).set('url', url);
    return this.apiService.get(getFileSizeEndpoint, {params}).pipe(
      map(size => null),
      catchError(err => (err.errorCode === ServerErrorCodes.validationError) ?
        of({maxFileSize: true} as ValidationErrors) :
        of({resourceSizeUnknown: true} as ValidationErrors)
      )
    );
  }
}
