import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TagVariableOperators, TagVariableSelectors } from './rule-tag-variable-filter.constants';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { ERuleMatchType, ERuleTagVariableSelectorType } from '../../rule-setup-conditions-tab.enums';
import { IUiTag } from '@app/components/tag-database';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ITagVariableSelector
} from '@app/components/rules/rule-setup/tabs/conditions-tab/shared/rule-tag-variable-filter/rule-tag-variable-filter.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-tag-variable-filter',
  templateUrl: './rule-tag-variable-filter.component.html',
  styleUrls: ['./rule-tag-variable-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleTagVariableFilterComponent implements OnInit, OnDestroy {

  @Input() formData: UntypedFormGroup;
  @Input() allTags: IUiTag[];
  @Output() onRemoveVariable = new EventEmitter<void>();
  @ViewChild('virtualScrollSelect', {static: true}) virtualScrollSelect: CdkVirtualScrollViewport;

  TagVariableOperators = TagVariableOperators;

  private tagIdToTagMap = new Map<number, IUiTag>();

  staticSelectors$ = new BehaviorSubject<ITagVariableSelector[]>(TagVariableSelectors);
  tagSelectors$ = new BehaviorSubject<IUiTag[]>([]);
  selectors$ = new BehaviorSubject<(ITagVariableSelector | IUiTag)[]>([]);
  selectorSearchText: string;

  private destroy$ = new ReplaySubject<void>(1);

  ngOnInit() {
    this.tagIdToTagMap = ArrayUtils.toMap(this.allTags, 'id');
    this.initListeners();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  panelOpened() {
    if (this.virtualScrollSelect) {
      this.virtualScrollSelect.checkViewportSize();
    }
  }

  operatorChanged(): void {
    // Set selector type to `String` for `Regex` match type
    if (this.matchTypeControl.value === ERuleMatchType.Regex) {
      this.selectorTypeControl.setValue(ERuleTagVariableSelectorType.String);
    }
  }

  private initListeners() {
    this.matchTypeControl.valueChanges.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      takeUntil(this.destroy$)
    ).subscribe(_ => {
      this.selectorSearchText = this.selectorTypeControl.value
        ? this.displaySelectorTypeFn(this.selectorTypeControl.value)
        : '';
      this.buildSelectors();

      // Workaround. We should manually reset value after Selectors list update. Otherwise, selected item isn't updates.
      // For example, if `String` was selected and then `String` changed to `Number` in Selectors list
      // (due to Match Type change to '>=' or '<='), selected item should be changed to `Number` as well.
      this.selectorTypeControl.setValue(this.selectorTypeControl.value, { emitEvent: false });
    });

    this.selectorTypeControl.valueChanges.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      takeUntil(this.destroy$),
      startWith(''),
      map((selectorType: string | number) => {
        if (selectorType === null) return ''; // selector type is disabled
        if (this.selectorTypeIsTag(selectorType)) return this.tagIdToTagMap.get(selectorType)?.name;
        return TagVariableSelectors.find(selector => selector.value === selectorType)?.name ?? selectorType;
      })
    ).subscribe(searchText => {
      this.selectorSearchText = searchText;
      this.buildSelectors();
    });
  }

  private buildSelectors() {
    if (this.matchTypeControl.value === ERuleMatchType.Regex) {
      this.staticSelectors$.next(TagVariableSelectors.filter(selector => selector.value === ERuleTagVariableSelectorType.String));
      this.tagSelectors$.next([]);
    } else {
      const staticSelectors = TagVariableSelectors
        .map(selector => this.shouldChangeStringToNumber(selector.value)
          ? { ...selector, name: 'Number' }
          : selector)
        .filter(({ name }) => name.toLowerCase().includes(this.selectorSearchText.toLowerCase()));
      const tagSelectors = this.allTags.filter(tag => tag.name.toLowerCase().includes(this.selectorSearchText.toLowerCase()));
      this.staticSelectors$.next(staticSelectors);

      this.tagSelectors$.next(tagSelectors);

      this.selectors$.next([...staticSelectors, ...tagSelectors]);
    }
  }

  displaySelectorTypeFn = (selectorType: ERuleTagVariableSelectorType | number) => {
    if (this.selectorTypeIsTag(selectorType)) {
      return this.allTags.find(tag => tag.id === selectorType)?.name;
    }
    if (this.shouldChangeStringToNumber(selectorType)) {
      return 'Number';
    }
    return TagVariableSelectors.find(selector => selector.value === selectorType)?.name;
  }

  selectorTypeIsTag(selectorType: string | number): selectorType is number {
    return typeof selectorType === 'number';
  }

  shouldChangeStringToNumber(selectorType: ERuleTagVariableSelectorType): boolean {
    const isStringSelectorType = selectorType === ERuleTagVariableSelectorType.String;
    const shouldBeNumberSelector = [
      ERuleMatchType.LessThanOrEqualTo,
      ERuleMatchType.GreaterThanOrEqualTo,
    ].includes(this.matchTypeControl.value);
    return isStringSelectorType && shouldBeNumberSelector;
  }

  get variableControl(): UntypedFormControl {
    return this.formData?.get('variable') as UntypedFormControl;
  }

  get matchTypeControl(): UntypedFormControl {
    return this.formData?.get('matchType') as UntypedFormControl;
  }

  get selectorTypeControl(): UntypedFormControl {
    return this.formData?.get('selectorType') as UntypedFormControl;
  }

  get valueControl(): UntypedFormControl {
    return this.formData?.get('value') as UntypedFormControl;
  }

  get valueTagIdControl(): UntypedFormControl {
    return this.formData?.get('valueTagId') as UntypedFormControl;
  }

}
