import { Component, ElementRef, Inject, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  switchMap,
  take,
  takeUntil
} from 'rxjs/operators';
import { IAddConsentCategoriesModalData, IConsentCategoryUpdateItems } from './add-consent-categories-modal.models';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import {
  EConsentCategoriesType,
  EDomainType,
  ENameType,
  IConsentCategories,
} from '@app/components/consent-categories/consent-categories.models';
import { Router } from '@angular/router';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { EConsentCategoryCreateStep } from '@app/components/consent-categories/cc-create/cc-create.enums';
import { ConsentCategoryCreateComponent } from '@app/components/consent-categories/cc-create/cc-create.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {
  IReprocessService,
  ReprocessBannerStatus
} from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import {
  ReprocessConfirmationSnackbarService
} from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.service';
import {
  SnackbarSuccessComponent
} from '@app/components/shared/components/snackbars/snackbar-success/snackbar-success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IAuditEditorModalData } from '@app/components/audit/audit-editor/audit-editor.models';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import {
  EFreeTrialAdModalType
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import {
  FreeTrialAdModalComponent
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';

export type ConsentCategoryRow = IConsentCategories;
export type Items = IAddConsentCategoriesModalData;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-consent-categories-modal',
  templateUrl: './add-consent-categories-modal.component.html',
  styleUrls: [ './add-consent-categories-modal.component.scss' ]
})
export class AddConsentCategoriesModalComponent implements OnInit, OnDestroy {
  private readonly auditId: number;
  private readonly runId: number;
  private readonly auditName: string;
  private destroy$ = new Subject();
  selectedItemsText: string = '';
  items: IConsentCategoryUpdateItems;
  itemType: EConsentCategoriesType;
  selectedId: number;
  count: number;
  allAccountCCs: ConsentCategoryRow[];
  ccTotalCount: number;
  displayedColumns: string[] = [ 'checkbox', 'name', 'type', 'labels' ];
  rightFooterButtons = [
    {
      label: 'Save & Reprocess',
      action: () => this.saveConsentCategories(true),
      primary: true,
      disabled: true
    },
    {
      label: 'Save',
      action: () => this.saveConsentCategories(),
      primary: true,
      disabled: true
    }
  ];
  appliedAuditCCs: ConsentCategoryRow[]; // Current CCs assigned to the audit
  appliedSelection = new SelectionModel(true, []);
  notAppliedSelection = new SelectionModel(true, []);
  notAppliedSectionExpanded: boolean = false;
  filteredNotAppliedCCs = new MatTableDataSource<ConsentCategoryRow>([]); // All CCs on the account
  notAppliedSectionFilter: string = '';
  filterChanged$ = new Subject<string>();
  loading: boolean = true;
  allAccountCCsLoading: boolean = true;
  private isVisitorMode: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('auditCCsTable', { static: false }) auditCCsTable: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<AddConsentCategoriesModalComponent>,
    private ccService: ConsentCategoriesService,
    private router: Router,
    private applicationChromeService: ApplicationChromeService,
    @Inject(MAT_DIALOG_DATA){
      auditId,
      auditName,
      runId,
      items,
    }: IAddConsentCategoriesModalData,
    private modalService: OpModalService,
    private reprocessConfirmationSnackbarService: ReprocessConfirmationSnackbarService,
    private reprocessService: IReprocessService,
    private snackbar: MatSnackBar,
  ) {
    this.auditId = auditId;
    this.auditName = auditName;
    this.runId = runId;
    this.items = items;
    this.selectedItemsText = this.getSelectedItemsText();

    this.applicationChromeService.isVisitorMode$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: isVisitorMode => this.isVisitorMode = isVisitorMode
      });
  }

  ngOnInit(): void {
    this.filterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.notAppliedSectionFilter = filter;
      this.filteredNotAppliedCCs.filterPredicate = (data: any, filter: string) => {
        const filterLower = filter.toLowerCase();
        // Filter only on name, type, and labels attributes
        const matchString = Object.keys(data)
          .filter(key => key === 'name' || key === 'type' || key === 'labels')
          .reduce((value, key) => `${value}${data[key]}`, '')
          .toLowerCase();

        return matchString.includes(filterLower);
      };

      this.filteredNotAppliedCCs.filter = this.notAppliedSectionFilter;
    });

    this.loadAssignedCCs();
  }

  loadAssignedCCs(): void {
    this.loading = true;
    this.allAccountCCsLoading = true;

    this.ccService.getConsentCategoriesAssignedToAudit(this.auditId)
      .pipe(
        take(1),
        switchMap((consentCategories) => {
          this.itemType = consentCategories?.length > 0 ? consentCategories[0].type : undefined;
          if (consentCategories.length === 0) {
            return of([]);
          }

          return forkJoin(consentCategories.map(item =>
            this.ccService.getConsentCategoryAssignedLabels(item.id).pipe(map(({ labels }) =>
              ({ ...item, tagId: item.id, labels: labels.map(l => l.name).join(', ') })
            ))));
        }),
        finalize(() => {
          this.loading = false;
        }),
      ).subscribe(auditCCs => {
      this.appliedAuditCCs = [...auditCCs];
      this.notAppliedSectionExpanded = this.appliedAuditCCs?.length === 0;
      this.appliedSelection = new SelectionModel(true, auditCCs);

      this.ccService.getConsentCategorySummary()
        .pipe(
          switchMap(({consentCategories}) => {
            return forkJoin(consentCategories.map(item =>
              this.ccService.getConsentCategoryAssignedLabels(item.id).pipe(map(({ labels }) =>
                ({ ...item, tagId: item.id, labels: labels.map(l => l.name).join(', ') })
              ))));
          }),
          finalize(() => {
            this.loading = false;
            this.allAccountCCsLoading = false;
          }),
        ).subscribe(ccSummary => {
        this.ccTotalCount = ccSummary?.length || 0;
        this.allAccountCCs = ccSummary;
        this.filterNotAssignedCCs();
        this.setFooterButtonState();
      });
    });
  }

  filterNotAssignedCCs(): void {
    this.filteredNotAppliedCCs.data = this.allAccountCCs.filter(cc => {
      const found = this.appliedAuditCCs.find(auditCC => auditCC.id === cc.id);
      return !found;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNotAppliedCheckboxChanged(event, cc): void {
    this.notAppliedSelection.toggle(cc);

    this.setFooterButtonState();
  }

  onAssignedCheckboxChanged(event, cc): void {
    this.appliedSelection.toggle(cc);

    this.setFooterButtonState();
  }

  setFooterButtonState(): void {
    if (this.appliedSelection.selected.length === 0 && this.notAppliedSelection.selected.length === 0) {
      this.rightFooterButtons[0].disabled = true;
      this.rightFooterButtons[1].disabled = true;
    } else {
      this.rightFooterButtons[0].disabled = false;
      this.rightFooterButtons[1].disabled = false;
    }
  }

  saveSelectedItemsToAppliedCCs(): Observable<any>[] {
    const updates = [];

    if (this.items.cookies?.length > 0) {
      const patchBody = [];
      this.items.cookies.map(item => {
        const value = {
          domain: item.domain,
          nameType: ENameType.EXACT,
          domainType: EDomainType.EXACT,
          name: item.name
        };

        if (value.name === '') delete value.name;

        const patchItem = this.patchAddBody(value);
        patchBody.push(patchItem);
      });

      this.appliedSelection.selected.forEach(({id}) => {
        updates.push(
          this.ccService.patchConsentCategoryCookies(id, patchBody)
            .pipe(catchError(error => of(error)))
        );
      });

      this.notAppliedSelection.selected.forEach(({id}) => {
        updates.push(
          this.ccService.patchConsentCategoryCookies(id, patchBody)
            .pipe(catchError(error => of(error)))
        );
      });
    }

    if (this.items.tags?.length > 0) {
      const patchBody = [];

      this.items.tags.map(item => {
        const value = {
          tagId: item.tagId,
          anyAccount: !(item.accounts?.length > 0),
          accounts: item.accounts ? item.accounts : []
        };

        const patchItem = this.patchAddBody(value);
        patchBody.push(patchItem);
      });

      this.appliedSelection.selected.forEach(({id}) => {
        updates.push(
          this.ccService.patchConsentCategoryTags(id, patchBody)
            .pipe(catchError(error => of(error)))
        );
      });

      this.notAppliedSelection.selected.forEach(({id}) => {
        updates.push(
          this.ccService.patchConsentCategoryTags(id, patchBody)
            .pipe(catchError(error => of(error)))
        );
      });
    }

    if (this.items.requestDomains?.length > 0) {
      const patchBody = [];

      this.items.requestDomains.map(item => {
        const value = {
          anyLocation :  item.anyLocation,
          domain : item.domain,
          domainType : item.domainType,
          locationIds : item.locationIds || [],
        };

        const patchItem = this.patchAddBody(value);
        patchBody.push(patchItem);
      });

      this.appliedSelection.selected.forEach(({id}) => {
        updates.push(
          this.ccService.patchConsentCategoryRequestDomains(id, patchBody)
            .pipe(catchError(error => of(error)))
        );
      });

      this.notAppliedSelection.selected.forEach(({id}) => {
        updates.push(
          this.ccService.patchConsentCategoryRequestDomains(id, patchBody)
            .pipe(catchError(error => of(error)))
        );
      });
    }

    return updates;
  }

  saveConsentCategories(reprocess: boolean = false): void {
    const updates = [];

    updates.push(...this.saveSelectedItemsToAppliedCCs());

    forkJoin(updates)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (reprocess) {
          const ids = this.appliedSelection.selected.map(i => i.id);
          this.ccService.reprocessConsentCategories(this.auditId, this.runId, ids).subscribe(
            () => {
              this.reprocessService.reprocessComplete();
              this.reprocessService.subscribeOnAuditReprocessingConsentCategoriesUpdates(this.auditId, this.runId, this.auditName);
              this.reprocessService.updateAuditReprocessConsentCategoriesBannerStatus(this.auditId, this.runId, ReprocessBannerStatus.inProgress);
            },
            error => {
              if (error.code === 423) this.reprocessService.displayAuditConsentCategoriesReprocessInProgressToast();
            }
          );
        }

        const allSelectedCCs = [...this.appliedSelection.selected, ...this.notAppliedSelection.selected];

        const consentCategoryList = allSelectedCCs.map(cc => cc.name);
        // Concatenate all consent categories with a comma and space and account for plurality
        const ccNamesString = consentCategoryList.join(', ').replace(/, ([^,]*)$/, ' and $1');

        const numOfItems = this.items?.cookies?.length + this.items?.tags?.length + this.items?.requestDomains?.length;

        const message = reprocess
          ? `The ${this.selectedItemsText} ${numOfItems > 1 ? 'have' : 'has'} been added to ${ccNamesString} and data in this audit snapshot will be reprocessed.`
          : `The ${this.selectedItemsText} ${numOfItems > 1 ? 'have' : 'has'} been added to ${ccNamesString} and will be applied to the next run.`;

        this.showSnackbar(message);

        this.closeModal(true);
      });
  }

  private showSnackbar(message: string) {
    this.snackbar.openFromComponent(SnackbarSuccessComponent, {
      duration: 12000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {message}
    });
  }

  getSelectedItemsText(): string {
    const cookiesSelected = this.items?.cookies?.length > 0;
    const tagsSelected = this.items?.tags?.length > 0;
    const requestsSelected = this.items?.requestDomains?.length > 0;
    let selectedText = [];

    if (cookiesSelected) {
      const cookieSelectedCount = this.items.cookies.length;
      const selectedCookieText = `cookie${cookieSelectedCount > 1 ? 's' : ''}(${cookieSelectedCount})`;

      selectedText.push(selectedCookieText);
    }

    if (tagsSelected) {
      const tagSelectedCount = this.items.tags.length;
      const selectedTagText = `tag${tagSelectedCount > 1 ? 's' : ''}(${tagSelectedCount})`;

      selectedText.push(selectedTagText);
    }

    if (requestsSelected) {
      const requestSelectedCount = this.items.requestDomains.length;
      const selectedRequestText = `request domain${requestSelectedCount > 1 ? 's' : ''}(${requestSelectedCount})`;

      selectedText.push(selectedRequestText);
    }

    if (selectedText.length > 2) {
      selectedText[2] = 'and ' + selectedText[2];
      return selectedText.join(', ');
    } else if (selectedText.length > 1) {
      return selectedText.join(' and ');
    } else {
      return selectedText[0];
    }
  }

  createNewCCWithSelection(): void {
    const assignToCCConfig = {
      data: {
        skipCreate: true,
        useSelectedDataOnlyCreate: true,
        step: EConsentCategoryCreateStep.COOKIES,
        type: this.itemType || EConsentCategoriesType.approved,
        editing: true,
        auditId: this.auditId,
        ...this.items,
      }
    };

    this.modalService.openModal(ConsentCategoryCreateComponent, assignToCCConfig, 'selected-data-create-modal')
      .afterClosed()
      .pipe(take(1))
      .subscribe((ccData) => {
        this.closeModal(ccData?.reprocess);
      });
  }

  closeModal(b?: boolean): void {
    this.dialogRef.close(b);
  }

  private patchAddBody(value: any) {
      return {
        'op': 'add',
        'path': `/0`,
        'value': value
      };
  }

  debounceFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.filterChanged$.next(filter);
  }

  openAuditEditorToConsentCategoryStandard(event): void {
    event.stopPropagation();

    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      const data: IAuditEditorModalData = {
        auditId: this.auditId,
        runId: this.runId,
        step: 1,
        standardsTab: EStandardsTabs.ConsentCategories,
        disableNavigationButtons: true
      };

      this.modalService.openFixedSizeModal(AuditEditorComponent, {disableClose: true, data}, 'op-audit-editor')
      .afterClosed()
      .subscribe((results) => {
        if (results) {
          this.loadAssignedCCs();
        }
      });
    }
  }

  private openFreeTrialAdModal() {
    const data = {
      type: EFreeTrialAdModalType.CONSENT_CATEGORY
    };

    this.modalService.openModal(FreeTrialAdModalComponent, { data });
  }
}
