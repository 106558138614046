import { SortDirection } from '@angular/material/sort';
import { ENotificationCenterColumns } from '@app/components/notifications-center/notification-center.enums';
import { IAPIResponseMetadata } from '@app/components/audit-reports/audit-report/audit-report.models';
import { ILabel } from '@app/components/shared/services/label.service';
import { Observable } from 'rxjs';
import {
  INotificationProfileSetupModalResultDiffItem
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.models';
import { EmailsUpdateOperation } from '@app/components/notifications-center/notification-center.service';

export interface INotificationCenterEmailsResponse {
  emails: INotificationProfile[];
}

export interface INotificationProfile {
  email: string,
  usage: {
    auditCount: number,
    webJourneyCount: number,
    ruleCount: number,
    alertCount: number,
    usageAlertCount: number,
    emailInboxMessageReceivedCount: number,
    emailInboxMessageProcessedCount: number,
  }
}

export interface INotificationCenterSearchTargetItemsResponse {
  metadata?: IAPIResponseMetadata,
  items: INotificationCenterTargetItem[]
}

export interface INotificationCenterTargetItem {
  isAssigned: boolean,
  isEditable: boolean,
  itemType: ENotificationCenterTargetItemType,
  itemId: number,
  itemName: string,
  itemLastRunAt?: string, // example 2023-08-03T12:39:11.399Z
  itemLabels?: number[] | ILabel[],
  description?: string // only for audits and web journeys
}

export enum ENotificationCenterTargetItemType {
  AUDIT = 'AUDIT',
  INBOX = 'INBOX',
  WEB_JOURNEY = 'WEB_JOURNEY',
  RULE = 'RULE',
  ALERT = 'ALERT',
  USAGE_ALERT = 'USAGE_ALERT',
  EMAIL_INBOX_MESSAGE_RECEIVED = 'EMAIL_INBOX_MESSAGE_RECEIVED',
  EMAIL_INBOX_MESSAGE_PROCESSED = 'EMAIL_INBOX_MESSAGE_PROCESSED',
}

export const NotificationCenterTargetItemTypes = Object.keys(ENotificationCenterTargetItemType)
  .map(key => ENotificationCenterTargetItemType[key])
  .filter(k => typeof k === 'string');

export interface INotificationCenterSearchTargetItemsRequest {
  email: string,
  itemName?: string,
  itemLabels?: number[],
  isAssigned?: boolean
}

export interface ITargetItemsSort {
  sortBy: ETargetItemsSortColumns;
  sortDesc: boolean;
}

export enum ETargetItemsSortColumns {
  itemName = 'ITEM_NAME',
  isAssigned = 'IS_ASSIGNED',
  runDate = 'RUN_DATE'
}

export interface INotificationCenterSort {
  sortBy: ENotificationCenterColumns;
  sortDesc: boolean;
  sortDir: SortDirection;
}

export interface IChangeEmailDialogData {
  title: string,
  helpText: string,
  profile: INotificationProfile,
}

export interface IChangeEmailPayload {
  emails: string[],
  profile: INotificationProfile,
}

export interface IBulkActionItem {
  value: Observable<Array<INotificationCenterTargetItem | INotificationProfileSetupModalResultDiffItem>>,
  itemType: ENotificationCenterTargetItemType,
}

export interface IBulkActionOperation {
  items: IBulkActionItem[],
  operation: EmailsUpdateOperation,
}
