<div class="top-widget-section-wrap">
  <div class="widget-container">
    <div class="widget-container">
      <split-card [topLabel]="widgetPageScanned.topLabel"
                  [topChangeContent]="widgetPageScanned.topChangeContent"
                  [topChangeMeaning]="widgetPageScanned.topChangeMeaning"
                  [state]="widgetState"
                  [topTooltip]="'A count of pages scanned in this audit/run.'"
                  [metricType]="widgetPageScanned.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="pagesFilteredCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPageScanned.metricType"
                  [disableHighlightFromClick]="true"
                  class="pages-scanned-card">
        <div class="widget-pages-details">
          <div class="page-counts">
            <span class="pages-filtered-count">{{ pagesFilteredCount | number }}</span>
            <span *ngIf="widgetState === 'filtered'">&nbsp; of {{ pagesTotalCount | number }}</span>
          </div>
          <filter-spinner [state]="widgetState"></filter-spinner>
        </div>
      </split-card>
      <split-card [topLabel]="widgetUniqueTags.topLabel"
                  [topChangeContent]="widgetUniqueTags.topChangeContent"
                  [topChangeMeaning]="widgetUniqueTags.topChangeMeaning"
                  [bottomHandler]="widgetUniqueTags.bottomHandler"
                  [haveEnoughData]="widgetUniqueTagsSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="'Total number of unique tag types found in this audit/run.' + (widgetState === 'filtered' ? ' (Filtered)' : '')"
                  [metricType]="widgetUniqueTags.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="widgetUniqueTags.topChangeContent"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetUniqueTags.metricType"
                  [disableHighlightFromClick]="true"
                  class="unique-tags-card">
        <sparkline-chart-insight [meaningOfUp]="'none'"
                                 [meaningOfNoChange]="'none'"
                                 [label]="'since last run'"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetUniqueTagsSparklineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
    <div class="widget-container">
      <split-card [topLabel]="widgetBrokenTags.topLabel"
                  [topChangeContent]="widgetBrokenTags.topChangeContent"
                  [topChangeMeaning]="widgetBrokenTags.topChangeMeaning"
                  [topChangeFilteredContent]="widgetBrokenTags.topChangeFilteredContent"
                  [topHandler]="widgetBrokenTags.topHandler"
                  [bottomHandler]="widgetBrokenTags.bottomHandler"
                  [haveEnoughData]="widgetBrokenTagsSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="'Total number of tags which have a 0, 400+ or 500+ HTTP status code.' + (widgetState === 'filtered' ? ' (Filtered)' : '')"
                  [metricType]="widgetBrokenTags.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetBrokenTags.metricType"
                  [disableHighlightFromClick]="true"
                  class="broken-tags-card">
        <sparkline-chart-insight [meaningOfUp]="'negative'"
                                 [meaningOfNoChange]="'none'"
                                 [label]="'since last run'"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetBrokenTagsSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetBrokenPages.topLabel"
                  [topChangeContent]="widgetBrokenPages.topChangeContent"
                  [topChangeMeaning]="widgetBrokenPages.topChangeMeaning"
                  [topHandler]="widgetBrokenPages.topHandler"
                  [bottomHandler]="widgetBrokenPages.bottomHandler"
                  [state]="widgetState"
                  [activated]="filterByBrokenPages"
                  [haveEnoughData]="widgetBrokenPagesSparklineData.length > 1"
                  [topTooltip]="'Total number of pages which have a 0, 400+ or 500+ HTTP status code.' + (widgetState === 'filtered' ? ' (Filtered)' : '')"
                  [metricType]="widgetBrokenPages.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetBrokenPages.metricType"
                  [disableHighlightFromClick]="true"
                  class="broken-pages-card">
        <sparkline-chart-insight [meaningOfUp]="'negative'"
                                 [meaningOfNoChange]="'none'"
                                 [label]="'since last run'"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetBrokenPagesSparklineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
  </div>
</div>
<div class="tag-category-chart-wrap">
  <horizontal-stacked-bar-chart [data]="catChartData"
                                [uniqueIdentifier]="1"
                                [state]="categoryChartState"
                                (filterSelected)="toggleCategoryFilter($event)">
  </horizontal-stacked-bar-chart>
</div>
<div class="tags-table-section" #tagsTableScrollTo>
  <div class="section-title-wrap section-title-wrap-tags">
    <percent-numeric-toggle
      tooltipPercent="When viewing tags by %, the bars represent the relative implementation rate accross all tags."
      tooltipNumber="When viewing tags by #, the bars represent the implementation rate for the specific tag/account relative only to itself."
      (toggleChanged)="percentNumericToggleChanged($event)"
    ></percent-numeric-toggle>
    <div class="title-spinner-wrap">
      <p class="section-title">Pages With & Without Tags</p>
      <filter-spinner [state]="tagsTableState"></filter-spinner>
    </div>
  </div>
  <div class="tags-table-wrap">
    <table mat-table
           matSort
           #tagsTable
           [dataSource]="tagsTableDataSource"
           multiTemplateDataRows
           class="tags-table"
           matSortActive="tagPresence"
           matSortDirection="desc"
           matSortStart="desc"
           [class.is-filtered]="isFiltered"
           [class.updating]="tagsTableState === stateOptions.Loading">

      <ng-container matColumnDef="tagName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="tag-name-th">
          TAG NAME
        </th>
        <td mat-cell *matCellDef="let tag">
          <div class="tag-icon-name-wrap">
            <mat-icon class="expand-arrow">keyboard_arrow_right</mat-icon>
            <div class="tag-icon">
              <img [src]="tag.tagIcon"/>
            </div>
            <div class="tag-name">{{ tag.tagName }}</div>
            <div class="ellipse-menu" [class.show-menu]="tag.menuOpen">
              <button mat-icon-button [matMenuTriggerFor]="menu"
                      (click)="tag.menuOpen = true; $event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu" (closed)="tag.menuOpen = false;">
                <button mat-menu-item class="tag-inventory-filter-menu-item" (click)="addGlobalTagFilter(tag)">
                  <mat-icon>tune</mat-icon>
                  Filter report by this item
                </button>
              </mat-menu>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="numAccounts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text accounts-th">
          ACCOUNTS
        </th>
        <td mat-cell *matCellDef="let tag" class="center-text">
          {{ tag.numAccounts }}
        </td>
      </ng-container>

      <ng-container matColumnDef="missingTags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="pages-missing-tags-th">
          PAGES MISSING TAGS (BY ACCOUNT)
        </th>
        <td mat-cell *matCellDef="let tag">
          <div class="chart-wrapper"
               #chartWrapperLeft
               [class.selected]="tag.pagesMissingTag.chartData[0].filtered">
            <horizontal-bar-chart [data]="tag.pagesMissingTag.chartData"
                                  [state]="tag.barChartSettings.state"
                                  [uniqueIdentifier]="tag.pagesMissingTag.uniqueIdentifier"
                                  [displayPills]="tag.barChartSettings.displayPills"
                                  [calcAsPercentage]="tag.barChartSettings.calcAsPercentage"
                                  [displayPercentSymbol]="tag.barChartSettings.displayPercentSymbol"
                                  [textPosition]="tag.barChartSettings.textPosition"
                                  [barDirection]="tag.pagesMissingTag.barDirection"
                                  (filterSelected)="toggleReportFilter(tag, tagFilterTypes.PagesWithoutTag, $event)">
            </horizontal-bar-chart>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tagPresence">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="pages-with-tags-th">
          PAGES WITH TAGS (BY ACCOUNT)
        </th>
        <td mat-cell *matCellDef="let tag">
          <div class="chart-wrapper"
               #chartWrapperRight
               [class.selected]="tag.pagesWithTag.chartData[0].filtered">
            <horizontal-bar-chart [data]="tag.pagesWithTag.chartData"
                                  [state]="tag.barChartSettings.state"
                                  [uniqueIdentifier]="tag.pagesWithTag.uniqueIdentifier"
                                  [displayPills]="tag.barChartSettings.displayPills"
                                  [calcAsPercentage]="tag.barChartSettings.calcAsPercentage"
                                  [displayPercentSymbol]="tag.barChartSettings.displayPercentSymbol"
                                  [textPosition]="tag.barChartSettings.textPosition"
                                  [barDirection]="tag.pagesWithTag.barDirection"
                                  (filterSelected)="toggleReportFilter(tag, tagFilterTypes.TagPresent, $event)">
            </horizontal-bar-chart>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let tag" [attr.colspan]="tagsTableDisplayedColumns.length">
          <div class="tag-accounts-expander-container" [@detailExpand]="tag.expanded ? 'expanded' : 'collapsed'">
            <div class="tag-accounts-expander-pad">
              <div class="spinner-wrap" *ngIf="tag.expanded && !tag.tagAccounts">
                <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
              </div>
              <ng-container *ngIf="tag.tagAccounts">
                <div *ngFor="let account of tag.tagAccounts"
                     class="account-row"
                     [ngClass]="{ 'row-selected': account.pagesMissingAccount.chartData[0].filtered || account.pagesWithAccount.chartData[0].filtered }">
                  <div class="account-name">
                    {{ account.name }}
                    <div class="ellipse-menu ellipse-menu-small" [class.show-menu]="account.menuOpen">
                      <button mat-icon-button [matMenuTriggerFor]="menu"
                              (click)="account.menuOpen = true; $event.stopPropagation()">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" (closed)="account.menuOpen = false;">
                        <button mat-menu-item class="tag-inventory-filter-menu-item"
                                (click)="addGlobalAccountFilter(account)">
                          <mat-icon>tune</mat-icon>
                          Filter report by this item
                        </button>
                      </mat-menu>
                    </div>
                  </div>
                  <div class="chart-wrapper chart-wrapper-left"
                       [style.width.px]="chartWidthLeft"
                       [class.selected]="account.pagesMissingAccount.chartData[0].filtered">
                    <horizontal-bar-chart [data]="account.pagesMissingAccount.chartData"
                                          [state]="account.barChartSettings.state"
                                          [uniqueIdentifier]="account.pagesMissingAccount.uniqueIdentifier"
                                          [displayPills]="account.barChartSettings.displayPills"
                                          [calcAsPercentage]="account.barChartSettings.calcAsPercentage"
                                          [displayPercentSymbol]="account.barChartSettings.displayPercentSymbol"
                                          [textPosition]="account.barChartSettings.textPosition"
                                          [barDirection]="account.pagesMissingAccount.barDirection"
                                          (filterSelected)="toggleReportFilter(account, tagFilterTypes.AccountMissing, $event)">
                    </horizontal-bar-chart>
                  </div>
                  <div class="chart-wrapper chart-wrapper-right"
                       [style.width.px]="chartWidthRight"
                       [class.selected]="account.pagesWithAccount.chartData[0].filtered">
                    <horizontal-bar-chart [data]="account.pagesWithAccount.chartData"
                                          [state]="account.barChartSettings.state"
                                          [uniqueIdentifier]="account.pagesWithAccount.uniqueIdentifier"
                                          [displayPills]="account.barChartSettings.displayPills"
                                          [calcAsPercentage]="account.barChartSettings.calcAsPercentage"
                                          [displayPercentSymbol]="account.barChartSettings.displayPercentSymbol"
                                          [textPosition]="account.barChartSettings.textPosition"
                                          [barDirection]="account.pagesWithAccount.barDirection"
                                          (filterSelected)="toggleReportFilter(account, tagFilterTypes.AccountPresent, $event)">
                    </horizontal-bar-chart>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tagsTableDisplayedColumns; sticky: true"></tr>
      <tr mat-row
          *matRowDef="let tag; columns: tagsTableDisplayedColumns;"
          class="tag-row"
          [ngClass]="{
            'expanded': tag.expanded,
            'highlighted': tag.menuOpen,
            'row-selected': tag.pagesMissingTag.chartData[0].filtered || tag.pagesWithTag.chartData[0].filtered
          }"
          (click)="expandTagRow(tag)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="tag-accounts-expander-row"></tr>
    </table>
  </div>
</div>
<div class="pages-table-wrap" #pagesTableScrollTo>
  <div class="section-title-wrap section-title-wrap-pages">
    <div class="title-spinner-wrap">
      <filter-spinner [state]="pagesTableState"></filter-spinner>
      <p class="section-title">Pages Scanned</p>
      <op-audit-report-export-menu
        [auditId]="auditId"
        [runId]="runId"
        [tableCompletedState]="pagesTableState"
        [config]="exportReportConfig">
      </op-audit-report-export-menu>
    </div>

    <op-switchable-column-menu [columnConfig]="PagesTableColumns"
                               [storageKey]="CommonPagesConfigLocalStorageKey"
                               [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
  </div>
  <table mat-table
         matSort
         resizeableTable
         #pagesTable
         [dataSource]="pagesTableDataSource"
         [class.updating]="pagesTableState === stateOptions.Loading"
         class="pages-table"
         [matSortActive]="TableColumn.PageUrl"
         matSortDirection="asc"
         matSortStart="desc">

    <ng-container [matColumnDef]="TableColumn.PageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>INITIAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text" opTableCellTooltipPrefix="View Page Details:" opTableCellTooltip="{{ page.url }}">
            <div class="url-text">{{ page.url|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>FINAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text" opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ page.finalUrl }}">
            <div class="url-text">{{ page.finalUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageLoadTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90"
          [matTooltip]="PageLoadColumnTooltip"
          [matTooltipPosition]="'above'"
      >PAGE LOADTIME (sec)
      </th>
      <td mat-cell *matCellDef="let page" class="center-text {{ page.loadTimeClass }}">{{ page.loadTime }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90">FINAL PAGE STATUS CODE</th>
      <td mat-cell *matCellDef="let page"
          class="center-text {{ page.statusCodeClass }}"
          [matTooltip]="PageStatusCodeTooltipMap[page.statusCode] || null"
          matTooltipPosition="above"
          matTooltipShowDelay="250"
      >{{ page.finalPageStatusCode }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.TagInstanceCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90"># OF TAG REQUESTS</th>
      <td mat-cell *matCellDef="let page" class="center-text">{{ page.tagInstances }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.UniqueTagCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90">#|% OF UNIQUE TAGS</th>
      <td mat-cell *matCellDef="let page" class="center-text">
        {{ page.uniqueTags.count }} | {{ page.uniqueTags.percentage }}%
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.BrokenTagCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-80">BROKEN TAGS</th>
      <td mat-cell *matCellDef="let page" class="center-text {{ page.brokenTagsClass }}">{{ page.brokenTags }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="pagesTableDisplayedColumns$ | async"></tr>
    <tr mat-row
        *matRowDef="let page; columns: pagesTableDisplayedColumns$ | async;"
        [class.open-in-page-details]="page.id === pageIdOpenInPageDetails"
        (click)="openPageDetails(page)"></tr>
  </table>
  <mat-paginator class="summary-paginator"
                 [length]="paginationState.length"
                 [pageSize]="paginationState.pageSize"
                 [hidePageSize]="true">
  </mat-paginator>
</div>
