import { filterOrGiven, firstOrGiven, givenOrList, toJSOrGiven, toJSOrGivenIfEmpty } from './selectorHelpers';
import { IDomain } from '../../components/domains/domainsService';

const DOMAIN_FOLDER_KEY = 'folderId';

export abstract class IDomainSelector {
  abstract selectDomains(state): Array<IDomain>;
  abstract selectDomainById(state, domainId: number): IDomain;
  abstract selectDomainsByFolderId(state, folderId: number): Array<IDomain>;
}

export class DomainSelector extends IDomainSelector {
  private getDomainsByFolder(state, folderId) {
    return givenOrList(state.domains.get('all')).filter(domainMap => domainMap.get(DOMAIN_FOLDER_KEY) == folderId);
  }

  private getDomainById(state, domainId) {
    return filterOrGiven(state.domains.get('all'), domainMap => domainMap.get('id') == domainId, undefined);
  }

  selectDomains(state) {
    return toJSOrGiven<Array<IDomain>>(state.domains.get('all'), undefined);
  }

  selectDomainById(state, domainId: number) {
    return firstOrGiven(toJSOrGiven<Array<IDomain>>(this.getDomainById(state, domainId), undefined), undefined);
  }

  selectDomainsByFolderId(state, folderId = 0): Array<IDomain> {
    return toJSOrGivenIfEmpty(this.getDomainsByFolder(state, folderId), undefined);
  };
}

