<div class="modal-header-text">
  Create this alert by entering an alert name and the email address(es) to be notified for this alert logic. You can
  also customize this alerts logic or select from other existing alerts.
</div>

<form class="two-controls"
      [formGroup]="createForm">
  <div class="form-input">
    <mat-form-field appearance="outline"
                    floatLabel="always"
                    [attr.op-selector]="opSelectors.createName">
      <mat-label>Name Alert</mat-label>
      <input matInput placeholder="Enter a name"
             formControlName="name">
    </mat-form-field>
  </div>

  <div class="form-input"
       [attr.op-selector]="opSelectors.createEmails">
    <op-recipients-form-control formControlName="emails"
                                floatLabel="always"
                                class="op-recipients-form-control"
    ></op-recipients-form-control>
  </div>
</form>

<div class="alert-logic">
  <mat-label class="form-label">Default alert logic</mat-label>

  <div class="default-alert-container">
    <alert-logic-details *ngIf="defaultAlertValue"
                         [metricType]="defaultAlertValue?.metricType"
                         [operator]="defaultAlertValue?.operator"
                         [targetValue]="defaultAlertValue?.value"
                         [showFiltersList]="true"
                         [filters]="payload.filters">
    </alert-logic-details>
  </div>
</div>

<button mat-flat-button
        class="button-link"
        [attr.op-selector]="opSelectors.createCustomizeLink"
        (click)="openCustomizeAlert()">
  Customize Alert Logic & Setup
</button>
