import { ISidebarLinkChildRoute } from '@app/components/navigation/sidebar/sidebar.models';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enabledFeatures'
})
export class EnabledFeaturesPipe implements PipeTransform {

  transform(links: ISidebarLinkChildRoute[], accountFeatures: string[] = []) {
    return links.filter(link => !link.featureKey || accountFeatures.includes(link.featureKey));
  }

}
