<div class="section-title-wrap"
  matTooltip="Tags and Tag Accounts with counts of duplicates, multiples, and counts of pages they were found upon">
  <div class="title-spinner-wrap">
    <p class="section-title">Tag Duplicates & Multiples</p>
    <filter-spinner [state]="tableState"></filter-spinner>
  </div>
</div>
<div class="tags-table-wrap">
  <table
    class="tags-table"
    mat-table
    matSort
    multiTemplateDataRows
    matSortActive="duplicateCount"
    matSortDirection="desc"
    matSortStart="desc"
    [dataSource]="dataSource"
    [class.is-filtered]="isFiltered"
  >
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let tag" (click)="expand(tag)">
        <mat-icon
          class="expand-arrow"
        >keyboard_arrow_right
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>TAGS BY ACCOUNT</th>
      <td mat-cell *matCellDef="let tag" (click)="expand(tag)">
        <div class="tag-name-flex-container">
          <div class="tag-image">
            <img [src]="tag.tagIcon"/>
          </div>
          <div>{{ tag.name }}</div>
          <div class="tag-actions">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              (click)="$event.stopPropagation()"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              class="tag-menu-item"
              (click)="filterByTag(tag)"
            >
              <mat-icon>tune</mat-icon>
              Filter all reports by this item
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="numberOfRequests">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt"># OF<br>REQUESTS</th>
      <td mat-cell *matCellDef="let tag" class="center-txt" (click)="expand(tag)">
        {{ tag.numberOfRequests | number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="duplicateCount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt"># OF<br>DUPLICATES</th>
      <td mat-cell *matCellDef="let tag" class="center-txt">
        <div class="chart-wrapper" [class.selected]="isTagColumnSelected(tag, 'duplicates')">
          <horizontal-bar-chart [data]="tag.tagDuplicateBarChartPayload.chartData"
                                [state]="tag.barChartConfig.state"
                                [uniqueIdentifier]="tag.tagDuplicateBarChartPayload.uniqueIdentifier"
                                [displayPills]="tag.barChartConfig.displayPills"
                                [calcAsPercentage]="tag.barChartConfig.calcAsPercentage"
                                [displayPercentSymbol]="tag.barChartConfig.displayPercentSymbol"
                                [textPosition]="tag.barChartConfig.textPosition"
                                [barDirection]="tag.tagDuplicateBarChartPayload.barDirection"
                                (filterSelected)="addReportLevelFilter('duplicates', tag)"
                                (mouseenter)="mouseEnteredBarChart('duplicates', tag)"
                                (mouseleave)="mouseLeftBarChart('duplicates', tag)">
          </horizontal-bar-chart>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tagDuplicatePageCount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt"># OF<br>PAGES</th>
      <td mat-cell *matCellDef="let tag"
          class="center-txt clickable-cell"
          (click)="addReportLevelFilter('duplicates', tag)"
          [class.selected-cell]="isTagColumnSelected(tag, 'duplicates')"
          [class.bar-chart-hovered]="tag.tagDuplicateBarChartHovered">
        <div class="clickable-cell-value">
          {{ tag.tagDuplicatePageCount | number }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="empty">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let tag" (click)="expand(tag)"></td>
    </ng-container>

    <ng-container matColumnDef="multipleCount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt"># OF<br>MULTIPLES</th>
      <td mat-cell *matCellDef="let tag" class="center-txt">
        <div class="chart-wrapper" [class.selected]="isTagColumnSelected(tag, 'multiples')">
          <horizontal-bar-chart [data]="tag.tagMultipleBarChartPayload.chartData"
                                [state]="tag.barChartConfig.state"
                                [uniqueIdentifier]="tag.tagMultipleBarChartPayload.uniqueIdentifier"
                                [displayPills]="tag.barChartConfig.displayPills"
                                [calcAsPercentage]="tag.barChartConfig.calcAsPercentage"
                                [displayPercentSymbol]="tag.barChartConfig.displayPercentSymbol"
                                [textPosition]="tag.barChartConfig.textPosition"
                                [barDirection]="tag.tagMultipleBarChartPayload.barDirection"
                                (filterSelected)="addReportLevelFilter('multiples', tag)"
                                (mouseenter)="mouseEnteredBarChart('multiples', tag)"
                                (mouseleave)="mouseLeftBarChart('multiples', tag)">
          </horizontal-bar-chart>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tagMultiplePageCount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-txt"># OF<br>PAGES</th>
      <td mat-cell *matCellDef="let tag"
          class="center-txt clickable-cell"
          (click)="addReportLevelFilter('multiples', tag)"
          [class.selected-cell]="isTagColumnSelected(tag, 'multiples')"
          [class.bar-chart-hovered]="tag.tagMultipleBarChartHovered">
        <div class="clickable-cell-value">
          {{ tag.tagMultiplePageCount | number }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let tag" [attr.colspan]="columns.length">
        <div class="element-detail" [@detailExpand]="tag.expanded ? 'expanded' : 'collapsed'">
        <span class="spinner-wrap" *ngIf="!tag.tagAccounts">
          <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
        </span>
          <div class="accounts" *ngIf="!!tag.tagAccounts">
            <div class="account expanded-row"
                 *ngFor="let account of tag.tagAccounts; trackBy: trackFn"
                 [class.row-selected]="isTagAccountRowSelected(tag, account)"
            >
              <div class="account-name">
                <div class="tag-name-flex-container">
                  <div class="acc">
                    <span class="account-name-item">{{ account.name }}</span>
                    <div class="tag-actions tag-actions-small">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    </div>
                  </div>

                  <mat-menu #menu="matMenu">
                    <button
                      mat-menu-item
                      class="tag-menu-item"
                      (click)="filterByTagAndAccount(tag, account)"
                    >
                      <mat-icon>tune</mat-icon>
                      Filter report by this item
                    </button>
                  </mat-menu>
                </div>
              </div>
              <div>{{ account.numberOfRequests | number }}</div>
              <div>
                <div class="chart-wrapper" [class.selected]="isTagAccountColumnSelected(tag, account, 'duplicates')">
                  <horizontal-bar-chart [data]="account.tagDuplicateBarChartPayload.chartData"
                                        [state]="account.barChartConfig.state"
                                        [uniqueIdentifier]="account.tagDuplicateBarChartPayload.uniqueIdentifier"
                                        [displayPills]="account.barChartConfig.displayPills"
                                        [calcAsPercentage]="account.barChartConfig.calcAsPercentage"
                                        [displayPercentSymbol]="account.barChartConfig.displayPercentSymbol"
                                        [textPosition]="account.barChartConfig.textPosition"
                                        [barDirection]="account.tagDuplicateBarChartPayload.barDirection"
                                        (filterSelected)="addReportLevelFilter('duplicates', tag, account)"
                                        (mouseenter)="mouseEnteredBarChart('duplicates', account)"
                                        (mouseleave)="mouseLeftBarChart('duplicates', account)">
                  </horizontal-bar-chart>
                </div>
              </div>
              <div class="clickable-cell"
                   [class.selected-cell]="isTagAccountColumnSelected(tag, account, 'duplicates')"
                   [class.bar-chart-hovered]="account.tagDuplicateBarChartHovered"
                   (click)="addReportLevelFilter('duplicates', tag, account)">
                <div class="clickable-cell-value">
                  {{ account.tagDuplicatePageCount | number }}
                </div>
              </div>
              <div></div>
              <div>
                <div class="chart-wrapper" [class.selected]="isTagAccountColumnSelected(tag, account, 'multiples')">
                  <horizontal-bar-chart [data]="account.tagMultipleBarChartPayload.chartData"
                                        [state]="account.barChartConfig.state"
                                        [uniqueIdentifier]="account.tagMultipleBarChartPayload.uniqueIdentifier"
                                        [displayPills]="account.barChartConfig.displayPills"
                                        [calcAsPercentage]="account.barChartConfig.calcAsPercentage"
                                        [displayPercentSymbol]="account.barChartConfig.displayPercentSymbol"
                                        [textPosition]="account.barChartConfig.textPosition"
                                        [barDirection]="account.tagMultipleBarChartPayload.barDirection"
                                        (filterSelected)="addReportLevelFilter('multiples', tag, account)"
                                        (mouseenter)="mouseEnteredBarChart('multiples', account)"
                                        (mouseleave)="mouseLeftBarChart('multiples', account)">
                  </horizontal-bar-chart>
                </div>
              </div>
              <div class="clickable-cell"
                   [class.selected-cell]="isTagAccountColumnSelected(tag, account, 'multiples')"
                   [class.bar-chart-hovered]="account.tagMultipleBarChartHovered"
                   (click)="addReportLevelFilter('multiples', tag, account)">
                <div class="clickable-cell-value">
                  {{ account.tagMultiplePageCount | number }}
                </div>
              </div>
            </div>
            <div class="accounts-more" *ngIf="tag.tagAccounts && tag.tagAccounts.length > 20">
              Use an export to see the complete list.
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let tag; columns: columns;"
        class="request-row"
        [class.expanded-row]="tag.expanded"
        [class.row-selected]="isTagRowSelected(tag)"
    ></tr>
    <tr mat-row *matRowDef="let tag; columns: ['expandedDetail']" class="detail-row"></tr>
  </table>
</div>
