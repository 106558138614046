import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EUserStep } from '@app/components/create-edit-user-modal/create-edit-user-modal.constants';
import { UntypedFormGroup } from '@angular/forms';
import {
  ENotificationCenterModalFilterTypes,
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.enums';
import {
  ENotificationCenterTargetItemType,
  ETargetItemsSortColumns,
  INotificationCenterSearchTargetItemsResponse,
  INotificationCenterTargetItem,
  INotificationProfile,
  NotificationCenterTargetItemTypes
} from '@app/components/notifications-center/notification-center.models';
import { BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import {
  INotificationCenterCurrentState,
  INotificationCenterCurrentStateItems,
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import {
  ISimpleTableColumn
} from '@app/components/shared/components/selectable-table/simple-table/simple-table.models';
import {
  ESimpleTableColumnType
} from '@app/components/shared/components/selectable-table/simple-table/simple-table.enums';
import {
  NotificationCenterModalFilterBarService
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal-filter-bar.service';
import { RulesService } from '@app/components/rules/rules.service';
import {
  NotificationCenterService
} from '@app/components/notifications-center/notification-center.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';
import {
  DefaultNotificationCenterAssignmentsPagination,
  DefaultNotificationCenterAssignmentsSorting
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.constants';
import {
  ISelectableTableSelectionChange
} from '@app/components/shared/components/selectable-table/selectable-table.models';
import { IUser } from '@app/moonbeamModels';

  const ITEM_TYPE = ENotificationCenterTargetItemType;
  const SELECT_ITEMS_LIMIT = 1000;

@Component({
  selector: 'op-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent {
  SELECT_ITEMS_LIMIT = SELECT_ITEMS_LIMIT;
  ENotificationCenterTargetItemType = ENotificationCenterTargetItemType;

  destroy = new Subject<void>();

  hidePageMatching: boolean;
  modalTitle: string;

  currentState: INotificationCenterCurrentState;
  title: string = 'ASSIGN EMAIL ADDRESSES TO ITEMS';

  filterBarMenuItems = [
    {
      name: 'Labels',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'Label Search',
          type: EFilterBarMenuTypes.Search,
          searchPlaceholder: 'Search for label',
          action: (event: KeyboardEvent, el?: HTMLElement) => this.handleSearch(true, event, el),
          children: []
        }
      ]
    },
  ];
  validFilterTypes: EAuditReportFilterTypes[] = [];
  itemsToSelect = {
    items: [],
    loading: true
  };
  labels: ILabel[] = [];

  readonly columns: {
    [key in ENotificationCenterTargetItemType]: ISimpleTableColumn<any>[]
  } = {
    [ENotificationCenterTargetItemType.INBOX]:[{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Alert Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Alert Name',
      type: ESimpleTableColumnType.Text
    }],
    [ENotificationCenterTargetItemType.ALERT]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Alert Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.AUDIT]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Audit Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.WEB_JOURNEY]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Web Journey Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLastRunAt',
      sortKey: ETargetItemsSortColumns.runDate,
      title: 'Run Date',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.USAGE_ALERT]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Usage Alert Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLastRunAt',
      sortKey: ETargetItemsSortColumns.runDate,
      title: 'Run Date',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.RULE]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Rule Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED]: [
      {
        propName: 'itemName',
        sortKey: ETargetItemsSortColumns.itemName,
        title: 'Inbox name',
        type: ESimpleTableColumnType.Text
      },
      {
        propName: 'itemLabels',
        title: 'Labels',
        type: ESimpleTableColumnType.Chips
      }
    ],
    [ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED]: [
      {
        propName: 'itemName',
        sortKey: ETargetItemsSortColumns.itemName,
        title: 'Inbox name',
        type: ESimpleTableColumnType.Text
      },
      {
        propName: 'itemLabels',
        title: 'Labels',
        type: ESimpleTableColumnType.Chips
      }
    ],
  };

  itemType = ENotificationCenterTargetItemType.ALERT;
  displaySelectedTable: {[itemType in ENotificationCenterTargetItemType]?: boolean} = {};
  emails: string[];

  labels$ = new BehaviorSubject<ILabel[]>(null);
  labelById: Map<number, ILabel>;
  isAppliedFilters: boolean;

  selectionChangesMade: boolean = false;
  loading: boolean = true;

  @Input() currentStep: EUserStep;
  @Input() formGroup: UntypedFormGroup;
  @Input() currentUser: IUser;
  @Input() userBeingEdited: IUser;
  @Input() set notificationProfile(value: INotificationProfile) {
    this._notificationProfile = value;
    if (value) {
      this.loadAssignedItems();
    }
  }
  get notificationProfile(): INotificationProfile {
    return this._notificationProfile;
  }
  private _notificationProfile: INotificationProfile;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public filterBarService: NotificationCenterModalFilterBarService,
    public rulesService: RulesService,
    private labelService: LabelService,
    private notificationCenterService: NotificationCenterService,
  ) {}

  async ngOnInit() {
    this.currentState = {
      filters: {
        // when email is not specified, to workaround API validation
        // sending a space sign to the API to get all the target items
        email: this.userBeingEdited?.email || ' '
      },
      items: NotificationCenterTargetItemTypes.reduce((acc, type) => {
        acc[type] = this.initDefaultState();
        return acc;
      }, {} as Record<ENotificationCenterTargetItemType, INotificationCenterCurrentStateItems>)
    };

    await this.getLabels();
    this.changeItemType(this.itemType);

    this.filterBarService
      .updateSupportedFiltersList([
        ENotificationCenterModalFilterTypes.Name,
        ENotificationCenterModalFilterTypes.Label
      ]);

    this.loadAssignedItems();

    this.filterBarService
      .filters$
      .pipe(takeUntil(this.destroy))
      .subscribe((filters) => {
        this.currentState.filters = filters.reduce((formattedFilters, filter) => {
          const filterType = filter.type;
          const filterValue = filter.value;

          if (filterType === ENotificationCenterModalFilterTypes.Label) {

            if (formattedFilters[filterType]) {
              formattedFilters[filterType] = [...formattedFilters[filterType], filterValue];
            } else {
              formattedFilters[filterType] = [filterValue];
            }

          } else {
            formattedFilters[filterType] = filterValue;
          }

          return formattedFilters;
        }, {
          email: this.currentState.filters.email
        });

        this.isAppliedFilters = Object.keys(this.currentState.filters).length > 1;

        this.currentState.items[this.itemType].pagination.currentPageNumber = 0;

        this.updateCurrentTable();
      });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  changeItemType(type: ENotificationCenterTargetItemType) {
    this.itemType = type;

    this.updateCurrentTable();
  }

  changePagination(currentPage: number) {
    this.currentState.items[this.itemType].pagination.currentPageNumber = currentPage;

    this.updateCurrentTable();
  }

  changeSorting(sorting: {active: ETargetItemsSortColumns, direction: ESortDirection}) {
    this.currentState.items[this.itemType].sorting = {
      sortBy: sorting.active,
      sortDesc: sorting.direction === ESortDirection.desc
    };

    this.updateCurrentTable();
  }

  updateCurrentTable() {
    this.itemsToSelect.loading = true;

    this.notificationCenterService.searchTargetItems(
      this.itemType,
      this.currentState.items[this.itemType].sorting,
      this.currentState.items[this.itemType].pagination,
      this.currentState.filters
    )
      .subscribe(response => {
        this.itemsToSelect = {
          loading: false,
          items: this.attachLabelsToItems(response)
        };
        this.currentState.items[this.itemType].pagination = response.metadata.pagination;
        this.currentState.items[this.itemType].pagination.currentPageSize = 100;
      });
  }

  public loadAssignedItems() {
    if (!this.notificationProfile) return;

    const pagination = {
      totalCount: 0,
      totalPageCount: 0,
      pageSize: 1000,
      currentPageSize: 1000,
      currentPageNumber: 0
    };

    const filters = {
      email: this.notificationProfile.email,
      isAssigned: true
    };

    const emptyList = {
      items: []
    };

    forkJoin([
      this.notificationProfile.usage.alertCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.ALERT,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.notificationProfile.usage.auditCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.AUDIT,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.notificationProfile.usage.usageAlertCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.USAGE_ALERT,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.notificationProfile.usage.ruleCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.RULE,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.notificationProfile.usage.webJourneyCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.WEB_JOURNEY,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.notificationProfile.usage.emailInboxMessageReceivedCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.notificationProfile.usage.emailInboxMessageProcessedCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
    ]).subscribe(([alerts, audits, usageAlerts, rules, webJourneys, emailInboxReceived, emailInboxProcessed]) => {
      this.currentState.items[ENotificationCenterTargetItemType.ALERT].selectedItems = this.attachLabelsToItems(alerts);
      this.currentState.items[ENotificationCenterTargetItemType.ALERT].initialSelectedItemIds = alerts.items.map(item => item.itemId);
      this.currentState.items[ENotificationCenterTargetItemType.WEB_JOURNEY].selectedItems = this.attachLabelsToItems(webJourneys);
      this.currentState.items[ENotificationCenterTargetItemType.WEB_JOURNEY].initialSelectedItemIds = webJourneys.items.map(item => item.itemId);
      this.currentState.items[ENotificationCenterTargetItemType.RULE].selectedItems = this.attachLabelsToItems(rules);
      this.currentState.items[ENotificationCenterTargetItemType.RULE].initialSelectedItemIds = rules.items.map(item => item.itemId);
      this.currentState.items[ENotificationCenterTargetItemType.AUDIT].selectedItems = this.attachLabelsToItems(audits);
      this.currentState.items[ENotificationCenterTargetItemType.AUDIT].initialSelectedItemIds = audits.items.map(item => item.itemId);
      this.currentState.items[ENotificationCenterTargetItemType.USAGE_ALERT].selectedItems = this.attachLabelsToItems(usageAlerts);
      this.currentState.items[ENotificationCenterTargetItemType.USAGE_ALERT].initialSelectedItemIds = usageAlerts.items.map(item => item.itemId);

      this.currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED].selectedItems = this.attachLabelsToItems(emailInboxReceived);
      this.currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED].initialSelectedItemIds = emailInboxReceived.items.map(item => item.itemId);

      this.currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED].selectedItems = this.attachLabelsToItems(emailInboxProcessed);
      this.currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED].initialSelectedItemIds = emailInboxProcessed.items.map(item => item.itemId);

      this.itemsToSelect = {
        ...this.itemsToSelect,
        loading: false
      };

      // visually switching tab in constructor to avoid UI blinking, because of async ngOnInit
      if (this.notificationProfile) {
        this.emails = [this.notificationProfile.email];

        this.currentState.items[ITEM_TYPE.ALERT].initialSelectedCount = this.notificationProfile.usage?.alertCount;
        this.currentState.items[ITEM_TYPE.USAGE_ALERT].initialSelectedCount = this.notificationProfile.usage?.usageAlertCount;
        this.currentState.items[ITEM_TYPE.RULE].initialSelectedCount = this.notificationProfile.usage?.ruleCount;
        this.currentState.items[ITEM_TYPE.AUDIT].initialSelectedCount = this.notificationProfile.usage?.auditCount;
        this.currentState.items[ITEM_TYPE.WEB_JOURNEY].initialSelectedCount = this.notificationProfile.usage?.webJourneyCount;
        this.currentState.items[ITEM_TYPE.EMAIL_INBOX_MESSAGE_PROCESSED].initialSelectedCount = this.notificationProfile.usage?.emailInboxMessageProcessedCount;
        this.currentState.items[ITEM_TYPE.EMAIL_INBOX_MESSAGE_RECEIVED].initialSelectedCount = this.notificationProfile.usage?.emailInboxMessageReceivedCount;
      }

      this.selectionChanged.emit(this.currentState);
    });
  }

  onSelectionChanged($event: ISelectableTableSelectionChange<any>) {
    if ($event.added) {
      this.currentState.items[this.itemType].selectedItems = [
        ...this.currentState.items[this.itemType].selectedItems,
        ...$event.added
      ];
    }

    if ($event.removed) {
      this.currentState.items[this.itemType].selectedItems =
        this.currentState.items[this.itemType].selectedItems
          .filter(item => !$event.removed.find(removedItem => removedItem.itemId === item.itemId));
    }

    // Check that initially selected items haven't changed (lengths are the same and itemIds coincide)
    const selectedItems = this.currentState.items[this.itemType].selectedItems;
    const initialSelectedItems = this.currentState.items[this.itemType].initialSelectedItemIds;
    this.selectionChangesMade = !((selectedItems.length === initialSelectedItems.length)
      && initialSelectedItems.every(i => selectedItems.map(el => el.itemId).includes(i)));
    this.selectionChanged.emit(this.currentState);
  }

  get isLimitsExceeded() {
    return Object.keys(this.currentState.items).find(itemType => {
      const item: INotificationCenterCurrentStateItems = this.currentState.items[itemType];
      return item.selectedItems.length > this.SELECT_ITEMS_LIMIT;
    });
  }

  private handleSearch(isLabel: boolean, event: KeyboardEvent, el?: HTMLElement) {
    if (el) {
      el.focus();
      return;
    } else {
      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

      this.updateFilteredLabels(value);

      this.filterBarMenuItems = [...this.filterBarMenuItems];
    }
  }

  getLabels() {
    this.labels$.next(null);
    return this.labelService.getLabels().subscribe(labels => {
      this.labelById = new Map;
      labels.forEach(label => this.labelById.set(label.id, label));
      this.labels$.next(labels);
    });
  }

  private updateFilteredLabels(value: string) {

    this.labels$
      .pipe(
        filter(v => !!v),
        map(labels => labels
          .filter(label => label.name.toLowerCase().includes(value))
          .map(label => ({
            name: label.name,
            type: EFilterBarMenuTypes.Button,
            action: () => this.filterBarService.addLabelIdFilter({name: label.name, id: label.id})
          }))
        )
      ).subscribe(labels => {
      this.filterBarMenuItems[0].children[0].children = value
        ? labels
        : [];
    });
  }

  get totalItems() {
    return Object.keys(this.currentState.items).reduce((totalCount, itemType) => {
      const item: INotificationCenterCurrentStateItems = this.currentState.items[itemType];
      const itemCount = this.selectionChangesMade
        ? item.selectedItems.length
        : 0;
      return totalCount + itemCount;
    }, 0);
  }

  private attachLabelsToItems(response: INotificationCenterSearchTargetItemsResponse): INotificationCenterTargetItem[] {
    return response.items.map(item => {
      return {
        ...item,
        itemLabels: this.labels$.value?.filter(label => (item.itemLabels as number[]).includes(label.id))
      };
    });
  }

  private initDefaultState(): INotificationCenterCurrentStateItems {
    return {
      pagination: {...DefaultNotificationCenterAssignmentsPagination},
      sorting: {...DefaultNotificationCenterAssignmentsSorting},
      selectedItems: [],
      initialSelectedItemIds: [],
      initialSelectedCount: 0
    };
  }
}