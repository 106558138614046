<mat-button-toggle-group name="dateRangeFilter"
                         class="date-range-filter-buttons"
                         [disabled]="loading"
                         [value]="dateRange"
                         [hideSingleSelectionIndicator]="true"
                         (change)="onDateRangeChange($event.value)"
                         aria-label="Date Range Filter">
  <mat-button-toggle
    [disabled]="greaterThanOneMonthDisabled"
    [matTooltip]="greaterThanOneMonthDisabled ? 'Not enough run data for this time range' : ''"
    matTooltipPosition="above"
    [value]="EChartDateRange.oneMonth">Month
  </mat-button-toggle>
  <mat-button-toggle
    [disabled]="greaterThanThreeMonthsDisabled"
    [matTooltip]="greaterThanThreeMonthsDisabled ? 'Not enough run data for this time range' : ''"
    matTooltipPosition="above"
    [value]="EChartDateRange.threeMonth">3 Months
  </mat-button-toggle>
  <mat-button-toggle
    [disabled]="greaterThanSixMonthsDisabled"
    [matTooltip]="greaterThanSixMonthsDisabled ? 'Not enough run data for this time range' : ''"
    matTooltipPosition="above"
    [value]="EChartDateRange.sixMonth">6 Months
  </mat-button-toggle>
  <mat-button-toggle
    [disabled]="greaterThanNineMonthsDisabled"
    [matTooltip]="greaterThanNineMonthsDisabled ? 'Not enough run data for this time range' : ''"
    matTooltipPosition="above"
    [value]="EChartDateRange.nineMonth">9 Months
  </mat-button-toggle>
  <mat-button-toggle
    [disabled]="greaterThanThirteenMonthsDisabled"
    [matTooltip]="greaterThanThirteenMonthsDisabled ? 'Not enough run data for this time range' : ''"
    matTooltipPosition="above"
    [value]="EChartDateRange.thirteenMonth">13 Months
  </mat-button-toggle>
</mat-button-toggle-group>
