import { StorageService } from '@app/components/shared/services/storage.service';
import { AuthenticationStorageService } from './../core/services/authentication-storage.service';
import { AuthenticationService } from './../core/services/authentication.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthApiAccountStrategy } from '../core/services/authentication.models';
import { WindowRef } from '../core/services/window.service';
import { HttpParams } from '@angular/common/http';
import { LoginUrlBuilders } from '../login/login.constants';
import { ShareLinksPaths } from '../share-links/share-links.constants';

@Injectable({
  providedIn: 'root'
})
export class SSOGuard  {

  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private authStorageService: AuthenticationStorageService,
    private storageService: StorageService,
    private windowRef: WindowRef) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // The `/share/{shareToken}` page can be opened by anyone, including users without a valid token
    if (state.url.startsWith(`/${ShareLinksPaths.base}`)) {
      return true;
    }

    // Does user have a valid token?
    // Yes --> carry on
    // No:
    //    Is there a subdomain in the host name?
    //    No --> Go to login page
    //    Yes --> Use auth service to request strategy for subdomain
    //            Redirect to strategy result

    const authData = this.authStorageService.get();
    if (authData && !authData.tokenExpired) return true;

    const results = /(\w[\w\d-_]*)\.(app|next)\.observepoint(qa|staging)?\.com/.exec(this.windowRef.nativeWindow.location.hostname);
    if (!results) return this.goToLogin(state);

    const params = route.queryParams;
    // no need to get login strategy during logout
    if (params && params.hasOwnProperty('signout')) {
      this.authenticationService.logout(/* skipNav */ true);
      return true;
    } else {
      return this.authenticationService.getLoginStrategy(results[1])
        .toPromise()
        .then((strategy: AuthApiAccountStrategy) => {
          if (strategy.authenticationType === 'SAML') {
            this.windowRef.nativeWindow.location.href = strategy.idpLoginUrl;
            return false
          } else {
            return this.goToLogin(state);
          }
        });
    }
  }

  private goToLogin(state: RouterStateSnapshot): boolean {
    const loginPageIsActive = state.url.startsWith(LoginUrlBuilders.base());
    // don't redirect to login if we're already there
    if (loginPageIsActive) return true;

    let queryParams = new HttpParams();
    if (state.url && state.url !== '/') queryParams = queryParams.set('redirect', encodeURIComponent(state.url));
    this.router.navigateByUrl(LoginUrlBuilders.base() + '?' + queryParams.toString());
    return false;
  }
}
