import { EWebJourneyFrequency } from '@app/components/web-journey/web-journey.constants';
import { EAuditFrequency } from '@app/components/audit/audit.constants';
import { IScanModel } from '../manage/cards/manage-cards.constants';
import { ERecurrencePresetNames } from '../shared/components/op-recurrence/op-recurrence.constants';
import { CardTypes } from '../manage/cards/report-card-list/report-card-list.constants';

export const PAUSED_YEAR = '2038';

export class DataSourcesUtils {

  static isAuditScheduled(auditFrequency: EAuditFrequency): boolean {
    if (!auditFrequency) return false;
    return auditFrequency !== EAuditFrequency.ONCE;
  }

  static isAuditScheduledAndNotPaused(auditFrequency: EAuditFrequency, nextRunDate?: string | Date): boolean {
    return DataSourcesUtils.isAuditScheduled(auditFrequency) && !DataSourcesUtils.isPausedDate(nextRunDate);
  }

  static isJourneyScheduled(journeyFrequency: EWebJourneyFrequency): boolean {
    if (!journeyFrequency) return false;
    return journeyFrequency !== EWebJourneyFrequency.PAUSED && journeyFrequency !== EWebJourneyFrequency.ONCE;
  }

  // static isDataSourceScheduled
  static isDataSourceScheduled(model: IScanModel): boolean {
    /**
     * Two things will determine if a card is scheduled:
     *
     * 1. The preset type is unscheduled
     * 2. The preset type is run once AND the model has a nextRun or nextCheck
     */
    const presetTypeIsUnscheduled = model.schedule.presetType === ERecurrencePresetNames.Unscheduled;
    const presetTypeIsRunOnce = model.schedule.presetType === ERecurrencePresetNames.RunOnce;

    if (presetTypeIsUnscheduled) {
      return false;
    } else if (presetTypeIsRunOnce) {
      const nextRun = model.type === CardTypes.audit ? model.nextRun : model.nextCheck;
      return !!nextRun;
    } else {
      return true;
    }
  }

  static isJourneyScheduledAndNotPaused(journeyFrequency: EWebJourneyFrequency, nextRunDate?: string | Date): boolean {
    return DataSourcesUtils.isJourneyScheduled(journeyFrequency) && !DataSourcesUtils.isPausedDate(nextRunDate);
  }

  static isPausedDate(nextRunDate?: string | Date): boolean {
    if (!nextRunDate) return false;
    return (typeof nextRunDate === 'string')
      ? nextRunDate && (new Date(nextRunDate).getUTCFullYear() >= (+PAUSED_YEAR))
      : nextRunDate.getUTCFullYear() >= +PAUSED_YEAR;
  }

  static isOnceAndScheduledInFuture(model: IScanModel): boolean {
    const next = model.type === 'audit' ? model.nextRun : model.nextCheck;
    if (!next) return false;

    const now = new Date();
    // product has specified that this should be a rolling 2 year cut off from now to be considered scheduled
    const twoYearsFromNow = new Date(now.getFullYear() + 2, now.getMonth(), now.getDate()).getTime();
    const nextRun = new Date(next).getTime();

    return !(nextRun > twoYearsFromNow);
  }
}
