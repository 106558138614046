import { IFullscreenChartConfig } from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';

export enum EPrimaryTagHealthCardMenuItem {
  TagHealth = 'tag_health',
  TagInventory = 'tag_inventory',
  TagSetup = 'tag_setup'
}

export const TotalAuditScoreChartConfig: IFullscreenChartConfig = {
  title: 'Summation of all sub-scores, total possible score is 100.',
  data: [],
  sideLabel: 'Total score',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  fixedYAxis: 100,
  tooltip: (value: number) => { return `total score: ${value}`; },
};

export const CookiesScoreChartConfig: IFullscreenChartConfig = {
  title: 'Evaluation of both non-secure and empty SameSite 3rd party cookies',
  data: [],
  sideLabel: 'Cookie score',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `cookie score: ${value}`; },
};

export const PagePerformanceScoreChartConfig: IFullscreenChartConfig = {
  title: 'Evaluation of the number of pages that took longer than three seconds to load',
  data: [],
  sideLabel: 'Page Performance score',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `page performance score: ${value}`; },
};

export const RulesScoreChartConfig: IFullscreenChartConfig = {
  title: 'Evaluation of the total failed rules across all pages scanned',
  data: [],
  sideLabel: 'Rules score',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `rules score: ${value}`; },
};

export const TagPerformanceScoreChartConfig: IFullscreenChartConfig = {
  title: 'Evaluation of Tags That Take Longer Than 500ms To Load',
  data: [],
  sideLabel: 'Tag Performance score',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `tag performance score: ${value}`; },
};

export const TagPresenceScoreChartConfig: IFullscreenChartConfig = {
  title: 'Average Implementation Rate Of Tags',
  data: [],
  sideLabel: 'Tag Presence score',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `tag presence score: ${value}`; },
};
