import {
  IOpFilterBarFilter,
  IOpFilterBarInvertableFilter
} from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { Observable } from 'rxjs';

export enum EAuditReportFilterTypes {
  InitialPageUrlContains = 'pageUrl',
  InitialPageUrlDoesNotContain = 'pageUrlDoesNotContain',
  FinalPageUrlContains = 'finalPageUrl',
  FinalPageUrlDoesNotContain = 'finalPageUrlDoesNotContain',
  PageLoadTime = 'pageLoadTime',
  InitialPageStatusCode = 'initialPageStatusCode',
  FinalPageStatusCode = 'finalPageStatusCode',
  CombinedPageStatusCode = 'combinedPageStatusCode',
  ShowAuditConfigured = 'pageUrlMatchingAuditFilterConfiguration',
  PageTitle = 'pageTitle',
  PagesWithBrokenLinks = 'hasBrokenLinks',
  PagesWithoutTagId = 'pagesWithoutTagId',
  PagesWithoutTagCategoryId = 'pagesWithoutTagCategoryId',
  PagesWithoutTagVendorId = 'pagesWithoutTagVendorId',
  PageSize = 'pageSize',
  RedirectCount = 'redirectCount',

  LargestContentfulPaint = 'largestContentfulPaint',
  FirstContentfulPaint = 'firstContentfulPaint',
  TimeToFirstByte = 'timeToFirstByte',
  CumulativeLayoutShift = 'cumulativeLayoutShift',

  TagId = 'tagId',
  TagAccount = 'tagAccount',
  TagCategory = 'tagCategoryId',
  TagPrimaryTagsOnly = 'primaryTagsOnly',
  TagVendorId = 'tagVendorId',
  TagLoadTime = 'tagLoadTime',
  TagStatusCode = 'tagStatusCode',
  TagAnyRelated = 'anyRelatedTag',

  ConsoleLogMessage = 'messageText',

  RuleStatus = 'ruleStatus',
  RuleId = 'ruleId',

  CookieName = 'cookieName',
  CookieDomain = 'cookieDomain',
  CookieInitiator = 'cookieInitiator',
  CookieInitiatorDomain = 'initiatorUrlDomain',
  CookieInitiatorUrl = 'initiatorUrl',
  CookiePartyType = 'partyType',
  CookieSameSite = 'sameSite',
  CookieSecure = 'secure',
  CookieHttpOnly = 'httpOnly',
  CookieAllThirdParty = 'allThirdPartyCookies',
  CookieExpirationType = 'expirationType',
  CookieSize = 'cookieSize',
  CookieDuration = 'cookieDuration',
  CookiePartitioned = 'partitionKeyIsSet',
  CookieOriginInclusion = 'cookieOriginInclusion',

  ConsentCategoryId = 'consentCategoryId',
  ConsentCategoryComplianceStatus = 'consentCategoryComplianceStatus',

  Geolocation = 'countryCode',

  RequestDomain = 'requestDomain',
  RequestSize = 'tagRequestSize',

  JSFilesSizeDifference = 'sizeDifference',
  JSFilesDateDifference = 'dateDifference',
  JSFilesFilename = 'filename',
  JSFilesChangeType = 'changeType',

  AlertName = 'alertName',
  AlertReportMetric = 'metricTypes',
  AlertStatus = 'status',
  AlertSubscribed = 'subscribedOnly',

  Errors = 'errors',

  DeletedItem = 'deletedItem',
}

export interface IAuditReportFilter extends IOpFilterBarFilter<EAuditReportFilterTypes>{
}

export interface IAuditReportInvertableFilter extends IOpFilterBarInvertableFilter<EAuditReportFilterTypes>{
}

export interface IConsentCategoryReportFilterData {
  categoryId: number;
  name: string;
}

export interface IPageSummaryPageErrorFilter {
  filterType: EPageErrorFilterType[];
  errors: EPageErrorType[];
}

export enum EPageErrorFilterType {
  All = 'ALL',
  Any = 'ANY',
}

export enum EPageErrorType {
  OnPageActionError = 'ON_PAGE_ACTION_ERROR', // Only supported type currently.
  // Below types expected to be supported in the future
  // StatusCodeError = 'STATUS_CODE_ERROR',
  // BrowserError = 'BROWSER_ERROR',
  // JSError = 'JS_ERROR',
  // NetworkRequestError = 'NETWORK_REQUEST_ERROR',
}

export enum ECookiePartyType {
  first = '1st_party',
  third = '3rd_party',
}

export enum ERuleStatus {
  NotApplied = 'not_applied',
  Passed = 'passed',
  Failed = 'failed',
}

export enum ECookieSameSiteTypes {
  lax = 'Lax',
  none = 'None',
  strict = 'Strict',
  empty = 'Empty'
}

export enum ECookieExpirationTypeTypes {
  session = 'session',
  timestamp = 'timestamp',
}

export enum ECookieOriginInclusionTypes {
  exclude = 'exclude_pre_audit_action_only_cookies',
}

export enum EJSFileChangeType {
  newFile = 'new_file',
  notChanged = 'not_changed',
  changedFile = 'changed_file',
  deletedFile = 'deleted_file',
  unknown = 'unknown'
}

export type GetRulesFn = (ruleName: string) => Observable<{ ruleId: number, ruleName: string }[]>;
