import {DeepLinkValidator, UrlListValidator} from './listValidators';

export const validUrl = function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attributes, ngModel) {
        function validate(modelValue: string) {
          if (attributes.ignoreProtocol !== undefined) {
            ngModel.$setValidity('validUrl', UrlListValidator.validateWithOptionalProtocolOne(modelValue));
          } else {
            ngModel.$setValidity('validUrl', UrlListValidator.validateOne(modelValue));
          }
          return modelValue;
        }
        ngModel.$formatters.push(validate.bind(this));
        ngModel.$parsers.push(validate.bind(this));
      }
    };
  };
