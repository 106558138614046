export enum ERuleFilterType {
  URL = 'URL',
  StatusCode = 'StatusCode',
  Tag = 'Tag',
}

export enum EPageFilterType {
  URL = 1,
  StatusCode = 2,
  FinalUrl ,
  FinalStatusCode ,
  Tag
}

export enum ERuleMatchType {
  Equals = 'Equals',
  Equal = 'Equal',
  DoesNotEqual = 'DoesNotEqual',
  // not sure if the above 'DoesNotEqual' is a typo or
  // not but we need this other option elsewhere so it's
  // being added although technically a duplicate
  NotEqual = 'NotEqual',
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  IsSet = 'IsSet',
  IsNotSet = 'IsNotSet',
  NotSet = 'NotSet',
  Regex = 'Regex',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  GreaterThanOrEquals = 'GreaterThanOrEquals',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  LessThanOrEquals = 'LessThanOrEquals',
  EqualIgnoreCase = 'EqualIgnoreCase',
  NotEqualIgnoreCase = 'NotEqualIgnoreCase',
  ContainsIgnoreCase = 'ContainsIgnoreCase',
  DoesNotContainIgnoreCase = 'DoesNotContainIgnoreCase'
}

export enum ERuleTagVariableSelectorType {
  String = 'String',
  UrlParameter = 'UrlParameter',
  FinalUrlParams = 'FinalUrlParameter',
  DataLayer = 'DataLayer',
  Tag = 'Tag'
}
