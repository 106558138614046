import { EAuditReportFilterTypes } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { IFullscreenChartConfig } from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import {
  CommonReportsPagesFiltersConfig,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export enum TagDuplicatesAndMultiplesTrendName {
  UniqueTags = 'unique_tags',
  TagRequests = 'tag_requests',
  TagDuplicates = 'tag_duplicates',
  TagMultiples = 'tag_multiples'
}

export const UNIQUE_TAGS_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'Unique Tags',
  data: [],
  sideLabel: 'tags',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `${value} tag${value === 1 ? '' : 's'}`; }
};

export const TOTAL_TAG_REQUESTS_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'Total Tags Requests',
  data: [],
  sideLabel: 'requests',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `${value} request${value === 1 ? '' : 's'}`; }
};

export const TOTAL_DUPLICATES_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'Total Duplicates',
  data: [],
  sideLabel: 'duplicates',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `${value} duplicate${value === 1 ? '' : 's'}`; }
};

export const TOTAL_MULTIPLES_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'Total Multiples',
  data: [],
  sideLabel: 'multiples',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => { return `${value} multiple${value === 1 ? '' : 's'}`; }
};

export const TagDuplicatesAndMultiplesExportType = 'tag_duplicates_and_multiples_pages';

export const TagDuplicatesAndMultiplesRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,
  EAuditReportFilterTypes.TagVendorId,
  EAuditReportFilterTypes.TagPrimaryTagsOnly,
  EAuditReportFilterTypes.TagId,
  EAuditReportFilterTypes.TagAccount,
  EAuditReportFilterTypes.TagCategory
];

export const PagesTableSettingItems: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.TagDuplicates]: {
    title: 'Duplicates',
    checked: true,
  },
  [CommonReportsPagesTableColumns.TagMultiples]: {
    title: 'Multiples',
    checked: true,
  },
};
