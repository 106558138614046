import { Component, Inject, OnInit } from '@angular/core';
import { IButton } from '@app/models/commons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { IComparisonItemPreview } from '@app/components/comparison-library/comparison-library.model';
import { RorComparisonReportService } from '@app/components/ror-comparison-report/ror-comparison-report.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IReprocessComparisonsModalData } from '@app/components/reprocess-comparisons-modal/reprocess-comparisons-modal.models';
import { IApiErrorResponse } from '@app/components/core/services/api.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'reprocess-comparisons-modal',
  templateUrl: './reprocess-comparisons-modal.component.html',
  styleUrls: ['./reprocess-comparisons-modal.component.scss']
})
export class ReprocessComparisonsModalComponent implements OnInit {

  comparisons: IComparisonItemPreview[];

  destroy$ = new Subject();
  reprocessBtn: IButton = {
    label: 'Reprocess Comparisons',
    action: this.reprocessAssignedComparisons.bind(this),
    primary: true,
    disabled: true
  };
  rightFooterButtons: IButton[];

  constructor(private dialogRef: MatDialogRef<any>,
              private snackBar: MatSnackBar,
              private rorComparisonReportService: RorComparisonReportService,
              @Inject(MAT_DIALOG_DATA) private data: IReprocessComparisonsModalData) {
    this.rightFooterButtons = [this.reprocessBtn];
  }

  ngOnInit() {
    this.rorComparisonReportService.getAssignedComparisons(this.data.auditId).subscribe(comparisonPreviews => {
      this.comparisons = comparisonPreviews || [];
      this.reprocessBtn.disabled = this.comparisons.length < 1;
    });
  }

  closeModal(reprocessInitiated: boolean = false): void {
    this.dialogRef.close({ reprocessInitiated });
  }

  reprocessAssignedComparisons() {
    this.reprocessBtn.disabled = true;
    this.rorComparisonReportService.reprocessAssignedComparisons(this.data.auditId, this.data.runId, false).subscribe(response => {
      this.closeModal(true);
      this.showSnackbar(`Comparisons are being processed.`);
      this.reprocessBtn.disabled = false;
    }, (error: IApiErrorResponse) => {
      console.error(error);
      if (error.statusCode === 422) {
        this.showSnackbar(`Failed to initiate comparisons reprocessing. \n Make sure selected audit run has at least one previous run \n and at least one already applied and processed comparison.`);
      } else {
        this.showSnackbar(`Failed to initiate comparisons reprocessing. Please contact support.`);
      }
      this.reprocessBtn.disabled = false;
    });
  }
  
  private showSnackbar(message: string) {
    this.snackBar.open(
      message,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom', panelClass: 'reprocess-comparisons-snackbar' }
    );
  }
}
