import { 
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnInit,
  NgZone,
  OnDestroy,
  HostListener,
  Input
} from '@angular/core';
import { Events } from '@app/moonbeamConstants';
import { ThemeService } from '@app/services/theme-service/theme.service';
import { first } from 'rxjs/operators';

/**
 * This directive is used for truncating text and showing the full version of it on hover.
 * Important! Host element shouldn't have display="inline" because width can't be set for such elements
 * 
 * @param {string} position. Initial position of the host element. Default is relative
 * @param {string} maxWidth. Initial max-width of the host element. Default is 300px
 * @param {string} position. Initial background-color of the host element. Default is transparent
 */
@Directive({
  selector: '[overflowFormatter]',
})
export class OverflowFormatterDirective implements OnInit, AfterViewInit, OnDestroy {

  @Input() position: string = 'relative';
  @Input() maxWidth: string = '300px';
  @Input() backgroundColor: string = 'transparent';

  windowResizeStream: Rx.Observable<void>;
  windowResizeSubscription: Rx.IDisposable;

  constructor(private hostElement: ElementRef,
              private renderer: Renderer2,
              private themeService: ThemeService,
              private zone: NgZone) {}

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.windowResizeStream = Rx.Observable.fromEvent<void>(window, Events.resize).debounce(1000);
      this.windowResizeSubscription = this.windowResizeStream.subscribe(() => {
        this.zone.run(() => this.setToDefaults());
      });
    });
  }

  ngAfterViewInit(): void {
    this.setToDefaults();
  }

  ngOnDestroy(): void {
    this.windowResizeSubscription.dispose();
  }

  @HostListener('mouseenter') onMouseHover(): void {
    if (this.hasWidthOverflow()) this.showOverflowText();
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    this.hideOverflowText();
  }

  private setToDefaults(): void {
    const elem = this.hostElement.nativeElement;
    this.truncateText(elem);
    this.hideOverflowText(elem);
  }

  private truncateText(elem: ElementRef): void {
    this.renderer.setStyle(elem, 'text-overflow', 'ellipsis');
    this.renderer.setStyle(elem, 'overflow', 'hidden');
  }

  private hideOverflowText(elem: ElementRef = this.hostElement.nativeElement): void {
    this.renderer.setStyle(elem, 'position', this.position);
    this.renderer.setStyle(elem, 'max-width', this.maxWidth);
    this.renderer.setStyle(elem, 'background-color', this.backgroundColor);
    this.renderer.setStyle(elem, 'z-index', 1);
    this.renderer.setStyle(elem, 'white-space', 'nowrap');
  }

  private showOverflowText(): void {
    this.themeService.isDarkTheme.pipe(first()).subscribe(isDark => {
      const elem = this.hostElement.nativeElement;
      this.renderer.setStyle(elem, 'position', 'absolute');
      this.renderer.setStyle(elem, 'max-width', this.maxWidth);
      this.renderer.setStyle(elem, 'background-color', isDark ? '#4A4A4A' : '#E2E2E2');
      this.renderer.setStyle(elem, 'z-index', 10);
      this.renderer.setStyle(elem, 'white-space', 'initial');
    })

  }

  private hasWidthOverflow(): boolean {
    return this.hostElement.nativeElement.scrollWidth > this.hostElement.nativeElement.clientWidth;
  }

}
