import { AfterViewInit, Directive, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IUser } from '@app/moonbeamModels';
import { AccountsService } from '@app/components/account/account.service';
import { MatTableDataSource } from '@angular/material/table';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { MatSort } from '@angular/material/sort';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';
import { IAdvancedExportTag, ICommonTableState, IExportItem } from './export-reports.models';
import { ClickToEnlargeScreenshotModalComponent } from '@app/components/click-to-enlarge-screenshot/click-to-enlarge-screenshot-modal/click-to-enlarge-screenshot-modal.component';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IAbstractExportReportService } from '@app/components/shared/components/export-report/export-reports.abstract.service';
import { Subject } from 'rxjs';
import { ExportReportErrorSnackbarComponent } from '@app/components/shared/components/export-report/export-report-error-snackbar/export-report-error-snackbar.component';
import { EExportStatuses, TableColumns } from '@app/components/shared/components/export-report/export-reports.enums';
import { ExportedVendorsModalComponent } from '@app/components/shared/components/export-report/export-report-vendors-modal/exported-vendors-modal.component';
import { ExportReportModalComponent } from '@app/components/shared/components/export-report/export-report-modal/export-report-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExportModal } from '@app/components/shared/components/export-report/export-report-modal/export-report-modal.constants';

@Directive()
export abstract class AbstractExportReports implements OnInit, AfterViewInit, OnDestroy {
  protected abstract exports: IExportItem[];
  protected abstract route: ActivatedRoute;
  protected abstract router: Router;
  protected abstract service: IAbstractExportReportService;
  protected abstract modalEscapeService: ModalEscapeService;
  protected abstract opModalService: OpModalService;
  protected abstract snackbar: MatSnackBar;
  protected abstract accountsService: AccountsService;
  protected abstract zone: NgZone;

  protected runId: number;
  protected itemId: number;

  private accountId: number;
  private defaultTableSorting: IExportItem[];

  protected destroy$ = new Subject;

  protected abstract getRouteParams();
  abstract goToExportCenter();

  @ViewChild(MatSort) sort: MatSort;

  tableDataSource = new MatTableDataSource<IExportItem>();

  TableColumns = TableColumns;
  EAuditExportStatuses = EExportStatuses;

  tableDisplayedColumns = this.getAllTableColumns();

  ngOnInit() {
    this.defaultTableSorting = [...this.exports];
    this.tableDataSource.data = this.exports;

    this.getAccountId();
    this.getRouteParams();
  }

  ngAfterViewInit() {
    this.sort
      .sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(sort => {
        if (sort.direction) {
          this.tableDataSource.data = ArrayUtils.sortBy(this.exports, 'name', sort.direction as ESortDirection);
        } else {
          this.tableDataSource.data = this.exports = this.defaultTableSorting.map(v => v);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openImageInModal(name: string, url: string) {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.opModalService
      .openFullscreenModalTransparent(ClickToEnlargeScreenshotModalComponent, {
        data: {
          screenshotUrl: url,
          title: name
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  getAccountId() {
    this.accountsService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IUser) => this.accountId = user.accountId);
  }

  private getAllTableColumns(): string[] {
    return Object.values(this.TableColumns);
  }

  protected export(
    exportType: string,
    reportName: string,
    sortBy?: string,
    sortDesc?: boolean,
    page?: number,
    size?: number
  ) {
    const reportItem = this.exports.find(report => report.name === reportName);
    reportItem.status = EExportStatuses.generatingExport;

    return this.service
      .send(
        this.itemId,
        this.runId,
        exportType,
        { sortBy, sortDesc, page, size },
        {}
      )
      .pipe(
        takeUntil(this.destroy$),
        tap(exportResponse => {
          this.showExportProgress(reportItem, exportResponse.exportId);
        })
      )
      .subscribe({
        error: () => this.showErrorSnackbar(reportItem)
      });
  }


  protected showExportProgress(reportItem: IExportItem, exportId?: number) {
    reportItem.status = EExportStatuses.generatingExport;

    this.accountsService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        const message = user.email;

        this.opModalService
          .openModal(ExportReportModalComponent, {
            width: ExportModal.width,
            height: ExportModal.height,
            disableClose: true,
            data: {
              message: message,
              reportItem: reportItem,
              exportId: exportId,
            }
          }, 'exported-vendors-modal').afterClosed().subscribe(() => {
            reportItem.status = EExportStatuses.emailSent;
          })
        }
      )
  }

  protected showErrorSnackbar(reportItem: IExportItem) {
    reportItem.status = EExportStatuses.notExported;

    this.snackbar
      .openFromComponent(
        ExportReportErrorSnackbarComponent,
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: {},
          panelClass: ['ngx-toastr', 'w-responsive', 'email-report-error-snackbar'],
          duration: 3000,
        }
      );
  }

  protected openExportConfig(tags: IAdvancedExportTag[], reportType: string, reportItem: IExportItem) {
    this.opModalService.openModal(ExportedVendorsModalComponent, {
        width: '800px',
        height: '80%',
        data: {
          tags: tags
        }
      }, 'exported-vendors-modal')
      .afterClosed()
      .pipe(
        tap(selectedTags => {
          if (!selectedTags) {
            reportItem.status = EExportStatuses.notExported;
          }
        }),
        filter(selectedTags => !!selectedTags),
        switchMap(selectedTags => {
          return this.service.send(
            this.itemId,
            this.runId,
            reportType,
            {} as ICommonTableState,
            selectedTags
          ).pipe(
            takeUntil(this.destroy$),
            tap(exportResponse => {
              this.showExportProgress(reportItem, exportResponse.exportId);
            })
          );
        })
      )
      .subscribe({
         error: () => this.showErrorSnackbar(reportItem)
      });
  }
}
