import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ITagWithVariables } from '@app/components/match-criteria-picker/match-criteria-picker.models';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MatchCriteriaPickerComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'match-criteria-picker',
  templateUrl: './match-criteria-picker.component.html',
  styleUrls: ['./match-criteria-picker.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class MatchCriteriaPickerComponent implements ControlValueAccessor {

  @Input() tagOptionsMap: Map<number, string[]> = new Map();
  tags: ITagWithVariables[];

  onChange: (value: ITagWithVariables[]) => void;

  constructor() {}

  writeValue(tags: ITagWithVariables[]): void {
    this.tags = tags || [];
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  trackByFn(tag: ITagWithVariables) {
    return tag.id;
  }

  updateVariables() {
    this.onChange(this.tags);
  }
}
