export const DEFAULT_FREQUENCY: string = 'paused';
export const DEFAULT_LOCATION: string = 'mountain';
export const DEFAULT_CUSTOM_PROXY: string = 'customProxy';
export const DEFAULT_BROWSER_WIDTH: number = 1366;
export const DEFAULT_BROWSER_HEIGHT: number = 1200;
export const DEFAULT_USER_AGENT: string = 'Chrome - Linux';
export const DEFAULT_BLACKOUT_ENABLED: boolean = false;
export const DEFAULT_RFM_ENABLED: boolean = false;
export const DEFAULT_LOAD_FLASH: boolean = true;
export const DEFAULT_LIVE_VIDEO: boolean = false;
export const DEFAULT_VPN_ENABLED: boolean = false;
export const DEFAULT_GPC_ENABLED: boolean = false;
export const DEFAULT_HAS_RECIPIENTS: boolean = true;
export const DEFAULT_BLOCK_3RD_PARTY_COOKIES_ENABLED: boolean = false;

export const JourneyCenterText = 'Automate a series of actions to monitor and test more complex and critical pages on a website.';

export const LEARN_MORE_LINKS = {
  centerText: 'https://help.observepoint.com/en/articles/9101987-creating-journeys',
  proxy: 'https://help.observepoint.com/en/articles/9101494-scanning-secure-content',
  userAgent: 'https://help.observepoint.com/en/articles/9100800-user-agents',
  vpn: 'https://help.observepoint.com/en/articles/9101494-scanning-secure-content',
  gpc: 'https://help.observepoint.com/en/articles/9101148-gpc-signal\n' +
    '\n',
  browser: 'https://help.observepoint.com/en/articles/9100800-user-agents',
  webhook: 'https://help.observepoint.com/en/articles/9101509-webhooks',
  blackout: 'http://help.observepoint.com/en/articles/9101206-blackout-periods',
  monitored: 'https://help.observepoint.com/en/articles/9102002-journey-support',
  rfm: 'https://help.observepoint.com/en/articles/9094817-file-substitutions',
  labels: 'https://help.observepoint.com/en/articles/9090897-labels',
};

export enum EJourneyFieldLabels {
  NAME = 'Journey Name',
  FREQUENCY = 'How often should the journey run?',
  RUN_FROM = 'Where should the journey run from?',
  NEXT_DATE = 'Next run date',
  INITIAL_DATE = 'Initial run date',
  NEXT_TIME = 'Next run time',
  INITIAL_TIME = 'Initial run time',
  BROWSER = 'Browser',
  USER_AGENT = 'User agent',
  WIDTH = 'Width (pixels)',
  HEIGHT = 'Height (pixels)',
  LABELS = 'Labels',
  CUSTOM_PROXY = 'Custom Proxy',
  START_TIME = 'Start time',
  END_TIME = 'End time',
}

export enum EJourneySetupOpSelectors {
  name = 'journey-setup-name',
  subfolder = 'journey-setup-subfolder',
  frequency = 'journey-setup-frequency',
  nextRunDate = 'journey-setup-next-run-date',
  nextRunTime = 'journey-setup-next-run-time',
  labels = 'journey-setup-labels',
  vpn = 'journey-setup-vpn',
  customVpn = 'journey-setup-custom-vpn',
  browser = 'journey-setup-browser',
  userAgent = 'journey-setup-user-agent',
  browserWidth = 'journey-setup-browser-width',
  browserHeight = 'journey-setup-browser-height',
  webhook = 'journey-setup-webhook',
  blackoutToggle = 'journey-setup-journey-blackout-toggle',
  blackoutStart = 'journey-setup-journey-blackout-start',
  blackoutEnd = 'journey-setup-journey-blackout-end',
  monitorJourney = 'journey-setup-monitor-journey',
  vpnToggle = 'journey-setup-vpn-toggle',
  gpcToggle = 'journey-setup-gpc-toggle',
  notifications = 'journey-setup-notifications',
  fileSubstitution = 'journey-setup-file-substitution',
  blockThirdPartyCookies = 'journey-setup-block-third-party-cookies',
}
