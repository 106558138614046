import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { IAuditReportParams } from './audit-report-container.models';
import { INavSection } from '../navigation/modal-sidebar/modal-sidebar.models';
import {
  AuditReportContainerTitles,
  AuditReportContainerUrlBuilders,
  opSelectorMap
} from './audit-report-container.constants';
import { IStatusBannerSettings, StatusBannerService } from '../reporting/statusBanner/statusBannerService';
import { Subject } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Features } from '@app/moonbeamConstants';
import { AuditReportLoadingService } from './audit-report-loading.service';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AuditReportPaths, AuditReportUrlBuilders } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { IAlertsSearchAssignmentBody } from '../alert/alert.models';
import { EProductType } from '../shared/components/op-standards-selector/op-standards-selector.constants';
import { EAlertSearchSortBy } from '../alert/alert.constants';
import { IEventManager } from '../eventManager/eventManager';
import { RUN_AUDIT_NOW } from '../audit/audit.constants';
import { ICommonTableState } from '../shared/components/export-report/export-reports.models';
import { EAlertSummaryTableColumn } from './reports/alert-summary/alert-summary.constants';
import { IAuditDataService } from '../domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { IAuditRunSummary } from '../domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { AccountsService } from '../account/account.service';
import { IUser } from '@app/moonbeamModels';
import { userIsGuest } from '@app/authUtils';
import { AuditReportChangesService, IAlertChangeState } from './audit-report-changes.service';
import { RorReportUrlBuilders } from '@app/components/ror-comparison-report/ror-comparison-report.contants';
import { IAuditModel } from '@app/components/modals/modalData';
import { RouteReloadService } from '@app/components/shared/services/route-reload.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-report-container',
  templateUrl: './audit-report-container.component.html',
  styleUrls: ['./audit-report-container.component.scss']
})
export class AuditReportContainerComponent implements OnInit, OnDestroy {
  CONSTANTS = {opSelectorMap};

  navSections: INavSection[] = [];

  auditId: number;
  runId: number;
  runs: IAuditRunSummary[];
  selectedRunIsMostRecent: boolean = true;
  showFullScreenStatusBanner: boolean;
  hideLoading: boolean;
  features;
  mobileMenuBtn: ElementRef;

  menuItemsMap: { [key: string]: string };
  currentReportName: string = '';

  user: IUser;
  userIsReadOnly: boolean;

  params = {
    size: 1000,
    page: 0,
    sortBy: EAlertSearchSortBy.ALERT_NAME,
    sortDesc: false
  };

  requestBody: IAlertsSearchAssignmentBody = {
    targetItem: {
      itemType: EProductType.AUDIT,
      itemId: null,
      isAssigned: true
    }
  };

  alertsTablePaginationState: ICommonTableState = {
    page: 0,
    size: 1000,
    sortBy: EAlertSummaryTableColumn.alertName,
    sortDesc: false
  };

  showAlertsChangedBanner: boolean = false;

  subscriptionToken: number;

  showUseCaseOverviewReports: boolean = false;
  outlined: boolean = false;

  mobileMenuWidth: number;
  isVisitorMode: boolean;
  isLoading$ = this.auditReportLoading.isLoadingAsync;

  runNowPending: boolean = false;

  private destroy$ = new Subject();

  @ViewChild('mobileMenuBtn') set content(mobileMenuBtn: ElementRef) {
    this.mobileMenuBtn = mobileMenuBtn;
    setTimeout(() => {
      this.mobileMenuWidth = mobileMenuBtn?.nativeElement.offsetWidth;
    });
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private routeReload: RouteReloadService,
              private auditReportLoading: AuditReportLoadingService,
              private statusBannerService: StatusBannerService,
              private authenticationService: AuthenticationService,
              private eventManager: IEventManager,
              private auditDataService: IAuditDataService,
              private accountsService: AccountsService,
              private auditReportChangeService: AuditReportChangesService) {}

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: IAuditReportParams) => {
        this.auditId = +params.auditId;
        this.runId = +params.runId;

        this.buildMenu();
        this.authenticationService.getFeaturesWithCache().toPromise().then((features) => this.features = features);
        this.checkFullScreenBannerState();
      });

    this.routeReload.reloadRouteEvents$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showFullScreenStatusBanner = false;
        this.destroy$.next();
        this.ngOnInit();
      });

    this.createMenuMap();

    // set report name on initial page load
    this.setCurrentReportName();

    // set report name on route change
    this.router.events
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setCurrentReportName();
      }
    });

    this.requestBody.targetItem.itemId = this.auditId;
    this.getAllRunIds();

    this.auditReportChangeService.onAlertsChanged$.pipe(takeUntil(this.destroy$)).subscribe((alertChangeState: IAlertChangeState) => {
      this.showAlertsChangedBanner = alertChangeState.alertsHaveChanged;
      if (this.runs?.length) {
        this.selectedRunIsMostRecent = this.runs[0].id === this.runId;
      }
    });

    this.accountsService.getUser().subscribe((user: IUser) => {
      this.user = user;
      this.userIsReadOnly = userIsGuest(this.user);
    });

    this.statusBannerService.runStopped$.subscribe(() => {
      this.runNowPending = false;
      this.checkFullScreenBannerState();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.eventManager.unSubscribe(RUN_AUDIT_NOW, this.subscriptionToken);
  }

  setMobileMenuWidthAfterResize(): void {
    this.mobileMenuWidth = this.mobileMenuBtn?.nativeElement.offsetWidth;
  }

  createMenuMap(): void {
    this.menuItemsMap = this.navSections.reduce((acc, section) => {
      section.navs.forEach((nav) => {
        const linkSections = nav.link.split('/');
        const lastSection = linkSections[linkSections.length - 1];
        acc[lastSection] = nav.title;
      });
      return acc;
    }, {});
  }

  setCurrentReportName(): void {
    const urlSections = this.router.url.split('/');
    const lastSection = urlSections[urlSections.length - 1];

    if (lastSection === 'results') {
      this.currentReportName = AuditReportContainerTitles.comparisons;
      return;
    }

    this.currentReportName = this.router.url.includes(AuditReportPaths.useCases)
      ? this.menuItemsMap[AuditReportPaths.useCases]
      : this.menuItemsMap[lastSection];
  }

  checkStatus(): boolean {
    return this.showFullScreenStatusBanner !== undefined;
  }

  private checkFullScreenBannerState(): void {
    this.statusBannerService.getSettingsForAudit(this.auditId).then(
      (settings: IStatusBannerSettings) => {
        if (settings.queued && this.runs?.length === 0) this.runNowPending = true;

        this.showFullScreenStatusBanner = settings.notRunYet || settings.firstRun || (this.runs?.length === 0 && settings.queued);
      },
      () => {
        this.showFullScreenStatusBanner = false;
      }
    );
  }

  private getOpSelector(name: string): string {
    return this.CONSTANTS.opSelectorMap[name] || '';
  }

  private getAllRunIds(): void {
    this.auditDataService.getAuditRuns(this.auditId).then((runs: IAuditRunSummary[]) => {
      this.runs = runs;
      this.selectedRunIsMostRecent = this.runs[0]?.id === this.runId;
    });
  }

  runNow(): void {
    if (this.runNowPending) {
      return;
    }
    this.runNowPending = true;
    this.eventManager.publish(RUN_AUDIT_NOW);
  }

  buildMenu(): void {
    this.navSections = [
      {
        sectionName: 'Audit Overview',
        navs: [
          {
            link: AuditReportUrlBuilders.useCases(this.auditId, this.runId),
            title: 'Use-Cases',
            active: false,
            opSelector: this.getOpSelector('Use-Cases')
          },
          {
            link: AuditReportUrlBuilders.auditSummary(this.auditId, this.runId),
            title: 'Summary',
            active: false,
            opSelector: this.getOpSelector('Audit Summary')
          },
          {
            link: AuditReportUrlBuilders.alertSummary(this.auditId, this.runId),
            title: 'Alerts',
            active: false,
            opSelector: this.getOpSelector('Alert Summary'),
          },
          {
            link: AuditReportUrlBuilders.pageSummary(this.auditId, this.runId),
            title: 'Pages (Web Vitals)',
            active: false,
            opSelector: this.getOpSelector('Page Summary'),
            new: true,
          }
        ]
      },
      {
        sectionName: 'Technology',
        navs: [
          {
            link: AuditReportUrlBuilders.tagInventory(this.auditId, this.runId),
            title: 'Tag Inventory',
            active: false,
            opSelector: this.getOpSelector('Tag Inventory')
          },
          {
            link: AuditReportUrlBuilders.tagHealth(this.auditId, this.runId),
            title: 'Tag Health',
            active: false,
            opSelector: this.getOpSelector('Tag Health')
          },
          {
            link: AuditReportUrlBuilders.variableInventory(this.auditId, this.runId),
            title: 'Variable Inventory',
            active: false,
            opSelector: this.getOpSelector('Variable Inventory')
          },
          {
            link: AuditReportUrlBuilders.ruleSummary(this.auditId, this.runId),
            title: 'Tag & Variable Rules',
            active: false,
            opSelector: this.getOpSelector('Rule Summary')
          },
          {
            title: AuditReportContainerTitles.comparisons,
            active: false,
            link: RorReportUrlBuilders.base(this.auditId, this.runId),
            opSelector: this.getOpSelector(AuditReportContainerTitles.comparisons)
          },
          {
            link: AuditReportUrlBuilders.duplicatesAndMultiples(this.auditId, this.runId),
            title: 'Duplicates & Multiples',
            active: false,
            opSelector: this.getOpSelector('Duplicates & Multiples')
          },
          {
            link: AuditReportUrlBuilders.cookieInventory(this.auditId, this.runId),
            title: 'Cookie Inventory',
            active: false,
            opSelector: this.getOpSelector('Cookie Inventory')
          },
          {
            link: AuditReportUrlBuilders.browserConsoleLogs(this.auditId, this.runId),
            title: 'Browser Console Logs',
            active: false,
            opSelector: this.getOpSelector('Browser Console Logs')
          }
        ]
      },
      {
        sectionName: 'Privacy',
        featureKey: Features.productLinePrivacy,
        navs: [
          {
            link: AuditReportUrlBuilders.privacyCookies(this.auditId, this.runId),
            title: 'Cookies',
            active: false,
            opSelector: this.getOpSelector('Cookies')
          },
          {
            link: AuditReportUrlBuilders.privacyTags(this.auditId, this.runId),
            title: 'Tags',
            active: false,
            opSelector: this.getOpSelector('Tags')
          },
          {
            link: AuditReportUrlBuilders.privacyRequests(this.auditId, this.runId),
            title: 'Request Domains & Geos',
            active: false,
            opSelector: this.getOpSelector('Request Domains & Geos')
          },
          {
            link: AuditReportUrlBuilders.privacyFileChanges(this.auditId, this.runId),
            title: 'JavaScript File Changes',
            active: false,
            opSelector: this.getOpSelector('JavaScript File Changes')
          }
        ]
      },
      {
        sectionName: '',
        navs: [
          {
            title: AuditReportContainerTitles.auditExports,
            active: false,
            link: AuditReportContainerUrlBuilders.auditExports(this.auditId, this.runId),
            opSelector: this.getOpSelector(AuditReportContainerTitles.auditExports),
          }
        ]
      },
    ];
  }

  @HostListener('window:resize')
  onResize(): void {
    this.setMobileMenuWidthAfterResize();
  }
}
