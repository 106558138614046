<div class="pages-container" [class.loading]="spinnerState === EFilterSpinnerState.Loading">

  <table mat-table
         matSort
         resizeableTable
         [dataSource]="tableDataSource"
         multiTemplateDataRows
         [matSortActive]="TableColumn.ErrorLogCount"
         matSortDirection="desc"
         matSortStart="desc">

    <ng-container [matColumnDef]="TableColumn.PageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>
        INITIAL PAGE URL
      </th>
      <td mat-cell
          *matCellDef="let row"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ row.pageUrl }}">
            <div class="url-text">{{ row.pageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>
        FINAL PAGE URL
      </th>
      <td mat-cell
          *matCellDef="let row"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ row.finalPageUrl }}">
            <div class="url-text">{{ row.finalPageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageLoadTime">
      <th mat-header-cell class="center-text fixed-70"
          *matHeaderCellDef
          mat-sort-header
          [matTooltip]="PageLoadColumnTooltip"
          [matTooltipPosition]="'above'"
      >
        PAGE LOAD TIME (sec)
      </th>
      <td mat-cell class="center-text"
          *matCellDef="let row">
        <span [class]="row.loadTimeClass">{{ row.pageLoadTime }}</span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
      <th mat-header-cell class="center-text fixed-70" *matHeaderCellDef mat-sort-header>
        FINAL PAGE STATUS CODE
      </th>
      <td mat-cell class="center-text" *matCellDef="let row">
        <span [class]="row.statusCodeClass"
              [matTooltip]="PageStatusCodeTooltipMap[row.finalPageStatusCode] || null"
              matTooltipPosition="above"
              matTooltipShowDelay="250"
        >{{ row.finalPageStatusCode }}</span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.ErrorLogCount" class="color-graph-red">
      <th mat-header-cell class="center-text fixed-100" *matHeaderCellDef mat-sort-header>
        TOTAL ERRORS
      </th>
      <td mat-cell class="center-text" *matCellDef="let row">
        {{ row.errorLogCount === null ? '---' : (row.errorLogCount | number ) }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.WarningLogCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text color-graph-orange fixed-100">
        TOTAL WARNINGS
      </th>
      <td mat-cell class="center-text" *matCellDef="let row">
        <span [class.not-empty]="row.warnLogCount">
          {{ row.warnLogCount === null ? '---' : (row.warnLogCount | number ) }}
        </span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.InfoLogCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text color-graph-blue fixed-80">
        TOTAL INFO
      </th>
      <td mat-cell class="center-text" *matCellDef="let row">
        {{ row.infoLogCount === null ? '---' : (row.infoLogCount | number ) }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.DebugLogCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text color-graph-purple fixed-80">
        TOTAL DEBUG
      </th>
      <td mat-cell class="center-text" *matCellDef="let row">
        {{ row.debugLogCount === null ? '---' : (row.debugLogCount | number ) }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.OtherLogCount">
      <th mat-header-cell class="center-text fixed-80" *matHeaderCellDef mat-sort-header>
        TOTAL OTHER
      </th>
      <td mat-cell class="center-text" *matCellDef="let row">
        {{ row.otherLogCount === null ? '---' : (row.otherLogCount | number ) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async;"
        class="item-row"
        [class.opened]="row.pageId === openedPageId"
        (click)="openPageDetails(row)">
    </tr>
    <tr *matNoDataRow>
      <td [colSpan]="(displayedColumns$ | async)?.length">
        <div class="no-pages-message-container">
          <span *ngIf="spinnerState !== 'loading'">no scanned pages here</span>
        </div>
      </td>
    </tr>
  </table>
</div>
<mat-paginator
  class="pages-paginator"
  [length]="tableState?.pagesTotal"
  [pageSize]="tableState?.pagination.size"
  [pageIndex]="tableState?.pagination.page"
  [hidePageSize]="true"
></mat-paginator>
