export enum ERecurrenceItemType {
  Audit = 'Audit',
  Journey = 'Journey',
  Generic = 'Generic', // used when applies to both audits and journeys
}

export enum ERecurrenceScheduleState {
  Scheduled = 'Scheduled',
  NotScheduled = 'Not Scheduled',
  Paused = 'Paused',
}

export enum ERecurrencePresetNames {
  FifteenMinutes = '15_MIN',
  Hourly = 'HOURLY',
  SixHours = '6_HOUR',
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  RunOnce = 'RUN_ONCE',
  Custom = 'CUSTOM',
  Unscheduled = 'UNSCHEDULED',
}

export const CUSTOM_FREQUENCY_PRESET = 'CREATE_CUSTOM_FREQUENCY_PRESET';

export enum EFrequencyTooltip {
  IsScheduled = 'is scheduled to run',
  IsPaused = 'has been paused',
  IsNotScheduled = 'is not scheduled to run',
}
