export interface ISelectableTableSelectionChange<D> {
  added?: D[];
  removed?: D[];
}

export interface IPagination {
  totalCount: number;
  totalPageCount: number;
  pageSize: number;
  currentPageSize: number;
  currentPageNumber: number;
}

export enum ESelectableTableSelectionMode {
  Single = 'single',
  Multiple = 'multiple',
}
