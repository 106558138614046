export enum EFilterBarMenuNames {
  Tag = 'Tag',
  TagName = 'Name',
  TagSearchName = 'Name Search',
  Geolocations = 'Geolocations',
  GeolocationsCountryName = 'Country Name',
  GeolocationsCountryNameSearch = 'Name Search',
  ConsentCategory = 'Consent Category',
  ConsentCategoryName = 'Name',
  ConsentCategoryNameSearch = 'Name Search'
}