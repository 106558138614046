import {Names} from '@app/moonbeamConstants';

import {ApiService} from './apiService';
import * as angular from 'angular';
import {HttpCallOnceService} from './httpCallOnceService';

export default angular.module('apiModule', [])
  .service(Names.Services.api, ApiService)
  .service(Names.Services.httpCallOnce, HttpCallOnceService)
  .name
