import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  IBrowserLogsTableRow
} from '@app/components/audit-reports/reports/browser-console-logs/components/browser-messages-table/browser-messages-table.models';
import {
  IBrowserLogsResultsItem,
  IMessagesTableState
} from '@app/components/audit-reports/reports/browser-console-logs/browser-console-logs.models';
import { StringUtils } from '@app/components/utilities/StringUtils';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { DecimalPipe } from '@angular/common';
import { formatPaginator } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

enum TableColumn {
  Expanded = 'expanded',
  Message = 'message',
  Type = 'level',
  RelatedTag = 'relatedTag',
  LogCount = 'logCount',
  PageCount = 'pageCount',
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'browser-common-logs-table',
  templateUrl: './browser-messages-table.component.html',
  styleUrls: ['./browser-messages-table.component.scss'],
})
export class BrowserMessagesTableComponent implements OnInit, OnChanges {

  @Input() inputData: IBrowserLogsResultsItem[];
  @Input() tableState: IMessagesTableState;
  @Input() spinnerState: EFilterSpinnerState;
  @Input() getTagNameByTagId: (tagId: number) => string;
  @Input() apiFilters: IAuditReportApiPostBody;

  @Output() onLogConditionMetricClicked = new EventEmitter<IBrowserLogsTableRow>();
  @Output() updateTableState = new EventEmitter<IMessagesTableState>();

  @ViewChild(MatSort, {static: true}) tableSort: MatSort;
  @ViewChild(MatPaginator, {static: true}) tablePaginator: MatPaginator;
  @ViewChild('browserMessagesTableScrollTop', {read: ElementRef}) browserMessagesTableElement: ElementRef;

  readonly TableColumn = TableColumn;
  readonly EFilterSpinnerState = EFilterSpinnerState;

  tableDataSource = new MatTableDataSource<IBrowserLogsTableRow>();
  tableDisplayedColumns = Object.values(this.TableColumn);
  selectedMetricRow: IBrowserLogsTableRow;

  constructor(private decimalPipe: DecimalPipe) {}

  ngOnInit() {
    this.handleInputData(this.inputData);
    this.formatPaginator();

    this.tablePaginator.page.subscribe((pagination: PageEvent) => {
      this.tableState.pagination.page = pagination.pageIndex;
      this.updateTableState.emit(this.tableState);
    });

    this.tableSort
      .sortChange
        .subscribe((sort: Sort) => {
          sort.active = sort.active === 'logCount' ? 'instancesCount' : sort.active;
          sort.active = sort.active === 'pageCount' ? 'pagesCount' : sort.active;
          this.tableState.sort.sortBy = StringUtils.camelCaseToSnakeCase(sort.active) as any;
          this.tableState.sort.sortDesc = sort.direction === 'desc';
          this.tableState.pagination.page = 0;
          this.updateTableState.emit(this.tableState);
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputData']) {
      this.handleInputData(changes['inputData'].currentValue);
    }

    if (this.apiFilters) {
      this.selectedMetricRow = undefined;
    }
  }

  rowClicked(row: IBrowserLogsTableRow) {
    row.expanded = !row.expanded;
  }

  isExpanded(row: IBrowserLogsTableRow) {
    return row.expanded;
  }

  private static dataItemToTableRow(i: IBrowserLogsResultsItem): IBrowserLogsTableRow {
    return {
      log: {...i},
      message: i.message,
      expanded: false,
      level: i.level,
      logCount: i.logCount,
      pageCount: i.pageCount,
      relatedTag: {
        ...i.relatedTag,
        src: i.relatedTag && UiTagService.getTagIconUrl(i.relatedTag.tagId)
      }
    };
  }

  private handleInputData(inputData: IBrowserLogsResultsItem[]) {
    if (inputData && Array.isArray(inputData)) {
      this.tableDataSource.data = inputData.map(i => BrowserMessagesTableComponent.dataItemToTableRow(i));
      this.browserMessagesTableElement.nativeElement.scrollIntoView(false);
    }
  }

  selectRow($event: MouseEvent, row: IBrowserLogsTableRow) {
    $event.stopPropagation();
    this.selectedMetricRow = this.selectedMetricRow === row ? null : row;
    this.onLogConditionMetricClicked.emit(row);
  }

  levelToStatus(row: IBrowserLogsTableRow) {
    return row[TableColumn.Type] === 'warn' ? 'warning' : row[TableColumn.Type];
  }

  private formatPaginator(): void {
    this.tablePaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }
}
