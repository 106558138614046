<div class="instruction">
  <div *ngIf="headerText" class="optional-assign-header-text">
    {{headerText}}
  </div>
  Select one or more reports below to which you would like to apply the {{ selectedConsentCategories.length }}
  selected Consent Categor{{ selectedConsentCategories.length > 1 ? 'ies' : 'y' }} ({{ selectedConsentCategoryNames }}).
</div>
<cc-data-sources-filter-bar></cc-data-sources-filter-bar>
<div *ngIf="loading" class="spinner-wrap">
  <mat-spinner class="loading-spinner" [diameter]="75" [strokeWidth]="5"></mat-spinner>
</div>
<div [hidden]="loading" class="select-data-source-wrap">
  <table mat-table [dataSource]="dataSource" matSort class="select-data-source-table">

    <ng-container matColumnDef="itemName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DATA SOURCE NAME</th>
      <td mat-cell *matCellDef="let item; let i = index;">
        <div class="dimming-wrap" [class.dimmed]="item.consentCategoryType && (selectedType !== item.consentCategoryType)">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="handleCheckboxClick(i)"
                        [checked]="selection.isSelected(item)"
                        [disabled]="item.consentCategoryType && (selectedType !== item.consentCategoryType)"
                        [matTooltip]="item?.itemName?.length > 25 ? item.itemName : null"
                        matTooltipShowDelay="250"
                        matTooltipPosition="above"
                        color="primary"
                        class="data-source-row-checkbox">
            {{ item.itemName }}
          </mat-checkbox>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastRun">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Run Date</th>
      <td mat-cell *matCellDef="let item">
        <div class="dimming-wrap"
             [class.dimmed]="item.consentCategoryType && (selectedType !== item.consentCategoryType)"
             [class.not-yet-run]="!item?.lastCompletedRun">
          {{ item?.lastCompletedRun ? formatDate(item.lastCompletedRun.completedAt) : 'Not yet run' }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="labels">
      <th mat-header-cell *matHeaderCellDef>LABEL</th>
      <td mat-cell *matCellDef="let item">
        <div class="dimming-wrap line-clamp" [class.dimmed]="item.consentCategoryType && (selectedType !== item.consentCategoryType)">
          {{ getLabelNames(item.labels) }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="conflict">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item" class="conflicting-type-cell">
        <div class="conflicting-type-message"
             [class.conflicting-visible]="item.consentCategoryType && (selectedType !== item.consentCategoryType)"
             matTooltip="This data set already has other Consent Categories applied to it with a differing status type."
             matTooltipPosition="above">
          <mat-icon class="conflicting-icon">info_outline</mat-icon>
          Conflict
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="paginationState.length" [pageSize]="paginationState.pageSize" [hidePageSize]="true"></mat-paginator>
</div>
