export const dateRunFormat = 'MMMM d';
export const timeRunFormat = 'h:mm:ss a';

/**
 * If comparison data is unavailable due to a bug or an outage, user should be still able to see actions, tags, variables, rules, info etc.
 * So, this error is used for denoting that Comparison API isn't working well and then UI can show Web Journey report w/o comparisons
 */
export class ComparisonError extends Error {
  
  constructor(message = 'Comparison data is unavailable due to an error. Please try again later or contact support') {
    super(message);

    /**
     * We need to set the prototype explicitly, because 
     * constructor function for Error use ES6's 'new.target' to adjust the prototype chain; 
     * however, there is no way to ensure a value for 'new.target' when invoking a constructor in ES5.
     * Hence, this row can be deleted when TS target is set to ES6+
     */
    Object.setPrototypeOf(this, ComparisonError.prototype);

    this.name = this.constructor.name;
  }
}
