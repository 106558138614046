import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { IButton } from '@app/models/commons';
import { IDialogInfo } from '../../../shared/components/bulk-action-progress/bulk-action-progress.models';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bulk-action-confirm-modal',
  templateUrl: './bulk-action-confirm-modal.component.html',
  styleUrls: ['./bulk-action-confirm-modal.component.scss']
})
export class BulkActionConfirmModalComponent {

  title: string;
  message: string;
  message2: string;
  leftBtnLabel: string;
  rightBtnLabel: string;
  dialog: IDialogInfo;
  showSecondBtn: boolean;
  showSecondMessage: boolean = false;
  showFinalMessage: boolean = true;
  showProgressBar: boolean = false;

  constructor(
    private snackBarRef: MatSnackBarRef<BulkActionConfirmModalComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public msgConfig: IDialogInfo,
    private fb: UntypedFormBuilder
    ) { 
      this.dialog = {
        ...this.msgConfig,
      };
  
      if(this.dialog.rightFooterButtons) {
        this.leftBtnLabel = this.msgConfig.rightFooterButtons[0].label;
        this.rightBtnLabel = this.msgConfig.rightFooterButtons[1].label;
      }
      if(this.dialog.messages) {
        this.message = this.dialog.messages[0];
        if(this.dialog.messages[1]) {
          this.message2 = this.dialog.messages[1];
          this.showSecondMessage = true;
       }
      }
      this.showSecondBtn =  (this.msgConfig.showSecondBtn) ? this.msgConfig.showSecondBtn : false;
      this.showProgressBar = (this.msgConfig.showProgressBar) ? this.msgConfig.showProgressBar : false;
    }

  actionSnackbar(): void {
    this.snackBarRef.dismissWithAction();
  }

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
