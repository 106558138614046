<div class="user" *ngIf="user">
  <h3>{{user.firstName}} {{user.lastName}}</h3>
  <div class="btn-group pull-right">
    <button mat-flat-button color="primary" class="edit-user-profile" (click)="editUserInfo()">
      Edit <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div *ngIf="user.permissions === userRoles.opSysAdmin">
    <div>ObservePoint System Admin</div>
    <small>You can do anything and monitor system status!</small>
  </div>
  <div *ngIf="user.permissions === userRoles.opAdmin">
    <div>ObservePoint Admin</div>
    <small>You can do anything!</small>
  </div>
  <div *ngIf="user.permissions === userRoles.admin">
    <div>Account Admin</div>
    <small>May create, view, and edit all users and their folders for an account</small>
  </div>
  <div *ngIf="user.permissions === userRoles.standardUser">
    <div>Standard User</div>
    <small>May create, view, and edit anything within shared folder(s)</small>
  </div>
  <div *ngIf="user.permissions === userRoles.guest">
    <div>Read-Only User</div>
    <small>May only view items within shared folder(s)</small>
  </div>
  <table class="table table-noborder">
    <tbody>
      <tr>
        <td>Username:</td>
        <td>{{user.username}}</td>
      </tr>
      <tr class="striped">
        <td>Password:</td>
        <td><i>(Not shown for security reasons)</i></td>
      </tr>
      <tr>
        <td>Email:</td>
        <td>{{user.email || 'none'}}</td>
      </tr>
      <tr class="striped">
        <td>TimeZone:</td>
        <td>{{user.timezone}}</td>
      </tr>
    </tbody>
  </table>

  <api-key *ngIf="hasApiKeyFeature"></api-key>
</div>
