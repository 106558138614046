import { darkCircleProgress, lightCircleProgress } from './../../../../shared/components/op-circle-progress/circle-progress.constants';
import { takeUntil } from 'rxjs/operators';
import { ThemeService } from './../../../../../services/theme-service/theme.service';
import { ComponentChanges } from './../../../../../models/commons';
import { Component, Input, EventEmitter, Output, OnChanges, OnInit } from '@angular/core';
import { IParityChartOptions } from '@app/components/ror-comparison-report/ror-comparison-report.models';
import { chartColor } from '@app/components/ror-comparison-report/ror-comparison-report.utils';
import { EColor } from '@app/components/ror-comparison-report/ror-comparison-report.contants';
import { Subject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-parity-chart',
  templateUrl: './ror-parity-chart.component.html',
  styleUrls: ['./ror-parity-chart.component.scss']
})
export class RorParityChartComponent implements OnInit, OnChanges {

  @Input() boxTitle: string;
  @Input() boxSubtitle: string;
  @Input() percentage?: number;
  @Input() rawValue?: number;
  @Output() onClickHandler = new EventEmitter();

  chartOptions: IParityChartOptions;

  private destroySubject = new Subject<void>();

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.themeService.isDarkTheme
      .pipe(takeUntil(this.destroySubject))
      .subscribe(isDark => {
        const colors = isDark ? darkCircleProgress : lightCircleProgress;
        this.chartOptions = {
          fgArcColor: this.percentage ? chartColor(this.percentage) : EColor.Normal,
          ...colors
        };
      });
  }

  ngOnChanges(changes: ComponentChanges<RorParityChartComponent>) {
    if (changes.percentage && changes.percentage.currentValue !== changes.percentage.previousValue) {
      if (this.chartOptions) this.chartOptions.fgArcColor = chartColor(this.percentage);
    }
  }

  openReport = (event) => {
    this.onClickHandler.emit(event);
  }

}
