import { Pipe, PipeTransform } from '@angular/core';

// Strip the protocol and return the rest of the URL
@Pipe({ name: 'hideProtocol' })
export class HideProtocolPipe implements PipeTransform {
  constructor() { }

  transform(url: string): string {
    return url.replace(/(^\w+:|^)\/\//, '');
  }
}

// Strip the protocol, subdomain, and domain, returning only the path after URL domains
@Pipe({ name: 'hideProtocolAndDomains' })
export class HideProtocolAndDomainsPipe implements PipeTransform {
  constructor() { }

  transform(url: string): string {
    let a = document.createElement('a');
    a.setAttribute('href', url);
    return `${a.pathname}${a.search}${a.hash}`;
  }
}

// Display only the domain, no protocol or path
@Pipe({ name: 'domainsOnly' })
export class DomainsOnlyPipe implements PipeTransform {
  constructor() { }

  transform(url: string): string {
    let a = document.createElement('a');
    a.setAttribute('href', url);
    return a.hostname;
  }
}
