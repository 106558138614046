import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'op-modal-header-base',
  templateUrl: './op-modal-header-base.component.html',
  styleUrls: ['./op-modal-header-base.component.scss']
})
export class OpModalHeaderBaseComponent {

  @Input() disabled = false;
  @Output() onClose = new EventEmitter<void>();

}
