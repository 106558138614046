import { Route } from '@angular/router';
import { ManageComponent } from './../manage.component';
import { ManageCardsComponent } from './manage-cards.component';
import { ManageCardsPaths, ManageCardsStateNames } from './manage-cards.constants';

export const MyCardsFutureState: Route = {
  path: ManageCardsPaths.base,
  loadChildren: () => import('../manage.module').then(mod => mod.ManageModule),
};

export const MyCardsRoutes: Route = {
  path: ManageCardsPaths.base,
  component: ManageComponent,
  data: {
    stateName: ManageCardsStateNames.cardsParent,
    title: 'Data Sources'
  },
  children: [{
    path: ManageCardsPaths.base,
    pathMatch: 'full',
    redirectTo: ManageCardsPaths.sources
  }, {
    path: ManageCardsPaths.sources,
    component: ManageCardsComponent,
    data: {
      stateName: ManageCardsStateNames.manageSources,
    }
  }]
};
