<div *ngIf="!loaded" class="loading">
  Loading...
</div>
<div class="lc-header" *ngIf="loaded">
  <h4>LiveConnect</h4>
  <button class="btn btn-default" (click)="createDeviceProfile()">ADD NEW DEVICE</button>
</div>
<div class="lc-report-wrp" *ngIf="loaded">
  <div class="empty-lc-report" *ngIf="!deviceProfiles.length">
    <div class="title">
      Perform a Real-time Test of Your Real-world Device.
    </div>
    <div class="sub-title-wrp">
      <div class="line"></div>
      <div class="sub-title">
        To get started, choose a device type
      </div>
      <div class="line"></div>
    </div>
    <div class="categories-list-wrp">
      <div class="categories-list">
        <div class="category-wrp" *ngFor="let key of objectKeys(categories)">
          <div class="category" (click)="createFirstDeviceProfile(categories[key].deviceProfileType)">
            <span class="category-name">{{categories[key].name}}</span>
            <img class="category-img" src="{{categories[key].imgDefault}}"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lc-report" *ngIf="deviceProfiles.length">
    <div class="filters-wrp">
      <div class="left-side">
        <div class="category-filter-wrp">
          <div class="active-category">
            <div class="category-wrp" (click)="toggleCategoryDropdown(true)">
              <img class="category-img" src="{{activeCategoryFilter.imgDefault}}"/>
              <span class="category-name">{{activeCategoryFilter.name}}</span>
            </div>
            <span class="caret"></span>
          </div>
          <div class="categories-dropdown" *ngIf="categoriesDropdownVisible">
            <div class="category-wrp"
              *ngFor="let catKey of objectKeys(categories)"
              (click)="deviceCategoryFilterChanged(categories[catKey], deviceProfiles)">
              <img class="category-img" src="{{categories[catKey].imgDefault}}"/>
              <span class="category-name">{{categories[catKey].name}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="labels-filter-wrp">
          <op-chip-selector
            class="no-underline"
            [additionalFormFieldClasses]="'no-border'"
            [placeholder]="{hasSelected:'+ Label name',hasNoSelected:'Filter by label'}"
            [allItems]="allLabels"
            [(ngModel)]="selectedLabels"
            (onSelectChip)="deviceFilterLabelsChanged()"
            (onRemoveChip)="deviceFilterLabelsChanged()"
            (onCreateChip)="deviceFilterLabelsChanged()">
          </op-chip-selector>
        </div>
        <div class="os-filters-wrp">
          <div class="os-search-checkbox" *ngFor="let os of objectKeys(activeOsFilter)">
            <input
              (click)="osFilterChanged(os)"
              [(ngModel)]="activeOsFilter[os]"
              type="checkbox"
              class="input-checkbox blue-checkbox"
              id="lc-os-{{os}}">
            <label for="lc-os-{{os}}">
              <span class="filter-label">{{os}}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="lc-report-content">
      <div class="device-profiles-list-wrp">
        <div class="device-profiles-list">
          <div class="device-profile-wrp" *ngFor="let dp of filteredDeviceProfiles"
            (click)="activateDeviceProfile(dp)"
            [ngClass]="{'active': dp.id === activeDeviceProfile.id, 'highlight-new-item': dp.isNew}">
            <div class="device-profile">{{dp.name}}</div>
            <op-menu
              [items]="deviceMenu"
              [model]="dp">
            </op-menu>
          </div>
          <div class="new-device-profile-wrp">
            <button class="btn btn-default" (click)="createDeviceProfile()">
              Add new device
            </button>
          </div>
        </div>
      </div>
      <div class="device-profile-data-wrp no-devices" *ngIf="deviceProfiles && deviceProfiles.length && !activeDeviceProfile">
        <p>
          There are no devices for this category.
        </p>
      </div>
      <div class="device-profile-data-wrp" *ngIf="activeDeviceProfile">
        <div class="device-container-wrp">
          <div class="device-container">
            <img src="{{categories[activeDeviceProfile.journeyType].imgDefault}}"/>
          </div>
        </div>
        <div class="device-profile-content-wrp">
          <div class="device-profile-info-wrp">
            <div class="common-info-wrp">
              <div class="common-info-data">
                <div class="device-name">{{activeDeviceProfile.name}}</div>
                <div class="device-version-info" [ngClass]="{'edit-mode': versionOfOsEditMode}">
                  <span class="device-type">{{activeDeviceProfile.osOfDevice}}</span>
                </div>
              </div>

              <div>
                <button class="btn btn-default"
                        [style.marginRight]="'30px'"
                        *ngIf="isHarFileIngestorEnabled"
                        (click)="openUploadHarModal(activeDeviceProfile)">
                  Upload HAR
                </button>
              </div>

              <div>
                <button class="btn btn-default new-journey-btn" (click)="createManualJourney(activeDeviceProfile)">New Journey</button>
                <op-menu
                  [items]="deviceMenu"
                  [model]="activeDeviceProfile">
                </op-menu>
              </div>
            </div>
            <div class="labels-wrp">
              <op-chip-selector
                [placeholder]="{hasSelected:'+ Label',hasNoSelected:'+ Label'}"
                [allItems]="allLabels"
                [appearance]="'fill'"
                [(ngModel)]="activeDeviceProfile.labels"
                (onRemoveChip)="removeDeviceLabel($event, activeDeviceProfile)"
                (onSelectChip)="selectDeviceLabel($event, activeDeviceProfile)"
                (onCreateChip)="createDeviceLabel($event, activeDeviceProfile)">
              </op-chip-selector>
            </div>
          </div>
          <div class="no-journey-wrp" *ngIf="deviceProfileJourneys[activeDeviceProfile.id] && !deviceProfileJourneys[activeDeviceProfile.id].length">
            <span class="no-journey-title">No Journeys Created for Device.</span>
            <button class="btn btn-default new-journey-btn" (click)="createManualJourney(activeDeviceProfile)">New Journey</button>
          </div>
          <div class="journey-list-table" *ngIf="deviceProfileJourneys[activeDeviceProfile.id] && deviceProfileJourneys[activeDeviceProfile.id].length">
            <div class="journey-list-header">
              <div class="journey-name-header">Journeys</div>
              <div class="journey-version-header">OS Vers.</div>
              <div class="journey-rule-failures-header">Rule Failure</div>
              <div class="journey-labels-header">Labels</div>
              <div class="elipses-wrp-header"></div>
            </div>
            <div class="journey-list-content">
              <div class="journey-item" *ngFor="let journey of deviceProfileJourneys[activeDeviceProfile.id]">
                <span class="journey-name text-in-line" title="{{journey.name}}" (click)="openManualJourneyReport(activeDeviceProfile, journey)">
                  {{journey.name}}
                </span>
                <span class="journey-version text-in-line" title="{{journey.versionOfOs}}" (click)="openManualJourneyReport(activeDeviceProfile, journey)">
                  {{journey.versionOfOs}}
                </span>
                <span class="journey-rule-failures text-in-line" (click)="openManualJourneyReport(activeDeviceProfile, journey)">
                  {{journey.rulesDetails ? journey.rulesDetails.globalRuleOverview.failed : 0}}
                </span>
                <div class="journey-labels-wrp">
                  <op-chip-selector
                    class="no-underline"
                    [placeholder]="{hasSelected:'+ Label',hasNoSelected:'+ Label'}"
                    [additionalFormFieldClasses]="['no-border']"
                    [allItems]="allLabels"
                    [(ngModel)]="journey.labels"
                    [advancedConfigs]="chipsAdvancedConfig"
                    (onRemoveChip)="removeJourneyLabel($event, journey)"
                    (onSelectChip)="selectJourneyLabel($event, journey)"
                    (onCreateChip)="createJourneyLabel($event, journey)">
                  </op-chip-selector>
                </div>
                <op-menu
                  class="elipses-wrp"
                  [items]="journeyMenu"
                  [model]="journey">
                </op-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
