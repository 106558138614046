import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IWebElementSelector, EWebElementSelectorType } from '../multi-selectors.models';
export interface ISelectorChange {
  selector: IWebElementSelector,
  index: number,
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'multiple-selectors',
  templateUrl: './multiple-selectors.component.html',
  styleUrls: ['./multiple-selectors.component.scss']
})
export class MultipleSelectorsComponent {
  @Input() selectors: IWebElementSelector[] = [];
  @Input() maxSelectors?: number = 15;
  @Input() activeSelectorIndex?: number = 0;

  @Output() updateSelector: EventEmitter<ISelectorChange> = new EventEmitter();
  @Output() addSelector: EventEmitter<null> = new EventEmitter();

  selectorTypeName = {
    [EWebElementSelectorType.Id]: 'ID',
    [EWebElementSelectorType.Name]: 'Name',
    [EWebElementSelectorType.Css]: 'CSS',
    [EWebElementSelectorType.XPath]: 'XPath',
    [EWebElementSelectorType.HtmlAttrs]: 'HTML'
  };

  select(selector: IWebElementSelector, index: number) {
    this.updateSelector.emit({
      selector,
      index
    });
  }

  add() {
    this.addSelector.emit();
  }

  hasMultipleSelectors() {
    return this.selectors.length > 1;
  }

  getTooltip(sel: IWebElementSelector): string {
    return this.selectorTypeName[sel.selectorType] + ': ' + sel.value;
  }
}
