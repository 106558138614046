<div class="filters">
  <div class="title">
    <b>FILTER PAGES</b>
    (Not Required)
  </div>
  <div class="subtitle">
    <span>Which report filter(s) need to be present for this rule to be evaluated. Only certain filters are available per report metric. </span>
    <span class="description">(For example, a regular expression can be used to restrict this rule to only product pages or only apply this rule if a specific page status code is present.)</span>
  </div>
  <usage-filter-bar
    [filters]="filters"
    [filterBarService]="filterBarService"
  ></usage-filter-bar>
</div>
