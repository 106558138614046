import { Component, Input, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { IAuditModel } from '@app/components/modals/modalData';
import {
  AssignmentRow,
  EActionSetAssignmentType,
  IActionSetTableRow,
  IAssignmentRow
} from '../action-set-library.models';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import { forkJoin, from, Observable } from 'rxjs';
import { IWebJourneyV3 } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { AccountsService } from '@app/components/account/account.service';
import { IUser } from '@app/moonbeamModels';
import { formatDefs } from '@app/components/date/date.service';
import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { HttpParams } from '@angular/common/http';
import { IWebJourneyRun } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { WebJourneyReportUrlBuilders } from '@app/components/web-journey-report/web-journey-report.constants';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { Router } from '@angular/router';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-set-assignment-table',
  templateUrl: './action-set-assignment-table.component.html'
})
export class ActionSetAssignmentTableComponent implements OnChanges {
  @Input() type: EActionSetAssignmentType;
  @Input() actionSet: IActionSetTableRow;

  users: IUser[];
  items: IAssignmentRow[];
  dateFormat: string = formatDefs.dateTwentyThree;
  loading = true;

  constructor(
    private router: Router,
    private discoveryAuditService: DiscoveryAuditService,
    private webJourneyService: WebJourneyV3Service,
    private accountsService: AccountsService,
    private journeyApiService: IWebJourneyApiService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']) {
      this.getAuditsAndJourneys();
    }
  }

  getAuditsAndJourneys() {
    this.loading = true;
    let observables: [
      Observable<IUser[]>,
      Observable<Array<IAuditModel | IWebJourneyV3>>
    ] = [
      this.accountsService.getUsers(),
      this.type === EActionSetAssignmentType.WebAudits ? from(this.discoveryAuditService.getAudits()) : this.webJourneyService.getJourneys()
    ];

    forkJoin(observables)
      .subscribe(([users, items]) => {
        this.users = users;
        this.items = items
          .filter((item) => this.actionSet[this.type].find((type) => type.id === item.id))
          .map(item => {
            return ActionSetAssignmentTableComponent.isAudit(item)
              ? AssignmentRow.fromAudit(item, this.type, this.users)
              : AssignmentRow.fromWebJourney(item, this.type, this.users);
          })
          .sort((a, b) => a.name.localeCompare(b.name));

        this.loading = false;
        this.cdr.detectChanges();
      });
  }

  private static isAudit(item: IAuditModel | IWebJourneyV3): item is IAuditModel {
    return !!(item as IAuditModel).ownerId;
  }

  goToItem(item: IAssignmentRow) {
    this.type === EActionSetAssignmentType.WebAudits
      ? this.openAudit(item.id, item.lastRunId)
      : this.openGuidedJourney(item);
  }

  private openAudit(auditId: number, runId: number) {
    this.router.navigateByUrl(AuditReportContainerUrlBuilders.base(auditId, runId));
  }

  private openGuidedJourney(journey: IAssignmentRow) {
    const queryParams = new HttpParams()
      .set('folderId', journey.folderId)
      .set('domain', journey.domainId);

    this.journeyApiService.getJourneyLastRun(journey.id)
      .then((run: IWebJourneyRun) => this.router.navigateByUrl(WebJourneyReportUrlBuilders.results(journey.id, run.id) + '?' + queryParams.toString()),
      () => {
        this.router.navigateByUrl(WebJourneyReportUrlBuilders.results(journey.id, journey.lastRunId) + '?' + queryParams.toString());
      });
  }
}
