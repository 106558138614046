import {
  ChartClassName,
  ChartType,
  EChartType,
  IChartData,
  IChartsConfig
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import {
  attachConstantLineChartToSvg,
  attachLineChartToSvg,
  hide,
  nearestDataPointCoordinates
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import { stepAfter } from '@app/components/shared/components/viz/utils/stepAfterCurve';
import * as d3 from 'd3';
import { EDateFormats, formatDate, toUTC } from '@app/components/date/date.service';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import {
  createCircle,
  createTooltip,
  drawTooltip,
  getChartData
} from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';
import { IUsageTrendsDTO } from '@app/components/usage-v2/usage-v2.models';
import {
  getProjectedUsageToggleBtn
} from '@app/components/usage-v2/components/usage-chart/datasets/future-periods/utils/toggle-buttons';

export function addFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateChartToConfig(
  trends: IUsageTrendsDTO,
  config: IChartsConfig,
  chartType: EChartType,
): void {
  if (!trends.futurePeriods?.length) {
    return;
  }
  const currentPeriod = trends.currentPeriod ? [trends.currentPeriod] : [];
  config.charts[ChartType.futurePeriodsCumulativeProjectedTotalScheduledUsageEstimate] = {
    data: getChartData([...currentPeriod, ...trends.futurePeriods], 'cumulativeProjectedTotal.scheduledUsageEstimate.total'),
    chartClassName: ChartClassName.futurePeriods,
    toggleBtn: getProjectedUsageToggleBtn(chartType, true),
  };
}

export function drawFuturePeriodsCumulativeProjectedTotalScheduledUsageEstimateChart(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  overlayLineStroke: number,
  windowWidth: number,
  circleClass: string,
  circleRadius: number,
  tooltipClass: string,
  startFromCenter = true,
  animationEnabled: boolean = true,
  highlight: (shouldHighlight: boolean) => void
): void {
  const scheduledUsageEstimate = config.charts[ChartType.futurePeriodsCumulativeProjectedTotalScheduledUsageEstimate];

  if (!scheduledUsageEstimate) {
    return;
  }

  attachLineChartToSvg(
    {
      data: scheduledUsageEstimate.data,
      className: 'dashed-gray-line',
      strokeWidth: 2,
      x: xAxis,
      y: yAxis,
      curve: stepAfter,
      // To make the first item start in center (for currentPeriod)
      leftPadding: startFromCenter ? xAxis.bandwidth() / 2 : 0,
      animationDuration: animationEnabled ? 2000 : 0,
    },
    svg.append('g')
      .attr('class',
        `cumulative-projected-total-scheduled-usage-estimate ${scheduledUsageEstimate.chartClassName}`,
      )
  );

  attachLineChartToSvg(
    {
      data: scheduledUsageEstimate.data,
      className: 'transparent-line',
      strokeWidth: overlayLineStroke,
      x: xAxis,
      y: yAxis,
      curve: stepAfter,
      // To make the first item start in center (for currentPeriod)
      leftPadding: startFromCenter ? xAxis.bandwidth() / 2 : 0,
      animationDuration: animationEnabled ? 2000 : 0,
    },
    svg.select(`.cumulative-projected-total-scheduled-usage-estimate`)
  )
    .on('mousemove', (e) => {
      const {
        x,
        y,
        dataPoint
      } = nearestDataPointCoordinates(e, svg.node(), scheduledUsageEstimate.data, xAxis, yAxis);

      const circle = createCircle(svg, circleClass, circleRadius);
      const tooltip = createTooltip(tooltipClass);
      circle.attr('class', `${circleClass} gray-circle`);

      drawTooltip(
        svg,
        '<div class="usage-chart-tooltip-body">' +
        '<h3 class="title">SCHEDULED USAGE ESTIMATE' + '<br>' +
        'AS OF ' +
        formatDate(toUTC(new Date(dataPoint.date)), EDateFormats.dateEight) +
        '</h3>' +
        '<div class="value">' +
        '<span class="value-data">' + VizUtilsService.formatChartNumbers(dataPoint.value) + '</span>' +
        '</div>' +
        '</div>',
        x,
        y,
        circle,
        tooltip,
        windowWidth,
      );

      highlight(true);
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
      highlight(false);
    });
}

// Second chart
export function addFuturePeriodsCumulativeProjectedTotalHistoricalUsageEstimateChartToConfig(
  trends: IUsageTrendsDTO,
  config: IChartsConfig,
  chartType: EChartType,
): void {
  if (!trends.futurePeriods?.length) {
    return;
  }
  const currentPeriod = trends.currentPeriod ? [trends.currentPeriod] : [];
  config.charts[ChartType.futurePeriodsCumulativeProjectedTotalHistoricalUsageEstimate] = {
    data: getChartData([...currentPeriod, ...trends.futurePeriods], 'cumulativeProjectedTotal.historicalUsageEstimate.total'),
    chartClassName: ChartClassName.futurePeriods,
    toggleBtn: getProjectedUsageToggleBtn(chartType, true),
  };
}

export function drawFuturePeriodsCumulativeProjectedTotalHistoricalUsageEstimateChart(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  overlayLineStroke: number,
  windowWidth: number,
  circleClass: string,
  circleRadius: number,
  tooltipClass: string,
  startFromCenter = true,
  animationEnabled: boolean = true,
  highlight: (shouldHighlight: boolean) => void
): void {
  const historicalUsageEstimate = config.charts[ChartType.futurePeriodsCumulativeProjectedTotalHistoricalUsageEstimate];

  if (!historicalUsageEstimate) {
    return;
  }

  attachLineChartToSvg(
    {
      data: historicalUsageEstimate.data,
      className: 'dashed-gray-line',
      strokeWidth: 2,
      x: xAxis,
      y: yAxis,
      curve: stepAfter,
      // To make the first item start in center (for currentPeriod)
      leftPadding: startFromCenter ? xAxis.bandwidth() / 2 : 0,
      animationDuration: animationEnabled ? 2000 : 0,
    },
    svg.append('g')
      .attr('class',
        `cumulative-projected-total-historical-usage-estimate ${historicalUsageEstimate.chartClassName}`)
  );

  attachLineChartToSvg(
    {
      data: historicalUsageEstimate.data,
      className: 'transparent-line',
      strokeWidth: overlayLineStroke,
      x: xAxis,
      y: yAxis,
      curve: stepAfter,
      // To make the first item start in center (for currentPeriod)
      leftPadding: startFromCenter ? xAxis.bandwidth() / 2 : 0,
      animationDuration: animationEnabled ? 2000 : 0,
    },
    svg.select(`.cumulative-projected-total-historical-usage-estimate`)
  )
    .on('mousemove', (e) => {
      const {
        x,
        y,
        dataPoint
      } = nearestDataPointCoordinates(e, svg.node(), historicalUsageEstimate.data, xAxis, yAxis);

      const circle = createCircle(svg, circleClass, circleRadius);
      const tooltip = createTooltip(tooltipClass);
      circle.attr('class', `${circleClass} gray-circle`);

      drawTooltip(
        svg,
        '<div class="usage-chart-tooltip-body">' +
        '<h3 class="title">HISTORICAL USAGE ESTIMATE' + '<br>' +
        'AS OF ' +
        formatDate(toUTC(new Date(dataPoint.date)), EDateFormats.dateEight) +
        '</h3>' +
        '<div class="value">' +
        '<span class="value-data">' + VizUtilsService.formatChartNumbers(dataPoint.value) + '</span>' +
        '</div>' +
        '</div>',
        x,
        y,
        circle,
        tooltip,
        windowWidth,
      );

      highlight(true);
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
      highlight(false);
    });
}

// This is just a decorative element
export function drawDecorativeLineForFuturePeriod(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  animationEnabled: boolean = true,
): void {
  const historicalUsageEstimate = config.charts[ChartType.futurePeriodsCumulativeProjectedTotalHistoricalUsageEstimate];
  const point1 = config.charts[ChartType.pastPeriodsCumulativeTotal].data[(config.charts[ChartType.pastPeriodsCumulativeTotal]?.data as IChartData[]).length - 1];
  const point2 = config.charts[ChartType.futurePeriodsCumulativeProjectedTotalHistoricalUsageEstimate]?.data[0];

  if (!point1 || !point2) {
    return;
  }

  const x = xAxis(point1.dateString) - xAxis.bandwidth() / 2;
  const y1 = yAxis(point2.value);
  const y2 = yAxis(point1.value);

  if (
    (!y1 || !y2 || !x)
    && typeof y1 !== 'number'
    && typeof y2 !== 'number'
    && typeof x !== 'number'
  ) {
    return;
  }

  attachConstantLineChartToSvg({
    groupClassName: historicalUsageEstimate.chartClassName,
    className: 'dashed-gray-line',
    x1: x,
    x2: x,
    y1,
    y2,
    animationDuration: animationEnabled ? 1750 : 0,
  }, svg);
}
