import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  IBrowserLogsTableRow
} from '@app/components/audit-reports/reports/browser-console-logs/components/browser-messages-table/browser-messages-table.models';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import {
  IBrowserLogsPerPageTableRow,
  IPagesTableState
} from '@app/components/audit-reports/reports/browser-console-logs/browser-console-logs.models';
import { takeUntil } from 'rxjs/operators';
import { AuditReportBase } from '@app/components/audit-reports/reports/general-reports.models';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import {
  BROWSER_CONSOLE_LOGS_SEARCH_TEXT_KEY,
  formatPaginator,
  PageLoadColumnTooltip
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'browser-pages-logs-table',
  templateUrl: './browser-pages-table.component.html',
  styleUrls: ['./browser-pages-table.component.scss'],
})
export class BrowserPagesTableComponent extends AuditReportBase implements OnInit, OnChanges, OnDestroy {
  @Input() inputData: IBrowserLogsPerPageTableRow[];
  @Input() spinnerState: EFilterSpinnerState;
  @Input() tableState: IPagesTableState;
  @Input() loadingRuleEdit: boolean = false;
  @Input() getTagNameByTagId: (tagId: number) => string;
  @Input() selectedConsoleMessage: string;
  @Output() onRuleConditionMetricClicked = new EventEmitter<IBrowserLogsTableRow>();
  @Output() updateTableState = new EventEmitter<IPagesTableState>();

  @ViewChild(MatSort, {static: true}) tableSort: MatSort;
  @ViewChild(MatPaginator, {static: true}) tablePaginator: MatPaginator;

  PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;
  auditId: number;
  runId: number;

  PageLoadColumnTooltip = PageLoadColumnTooltip;

  TableColumn = CommonReportsPagesTableColumns;

  displayedColumns$ = this.tableService.displayedColumns$;

  tableDataSource = new MatTableDataSource<IBrowserLogsPerPageTableRow>();

  openedPageId: string = null;
  readonly EFilterSpinnerState = EFilterSpinnerState;

  constructor(
    private auditReportService: AuditReportService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private activateRoute: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private tableService: ResizeableTableService,
    ) {
    super();

    this.activateRoute.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;
    });
  }

  ngOnInit() {
    this.handleInputData(this.inputData);
    this.formatPaginator();
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.ConsoleLog);

    this.pageDetailsDrawerService
      .pageDrawerClosed$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.openedPageId = null);

    this.tableSort.sortChange.subscribe((sort: Sort) => {
      this.tableState.sort.sortBy = sort.active as any;
      this.tableState.sort.sortDesc = sort.direction === 'desc';
      this.tableState.pagination.page = 0;
      this.updateTableState.emit(this.tableState);
    });

    this.tablePaginator.page.subscribe((pagination: PageEvent) => {
      this.tableState.pagination.page = pagination.pageIndex;
      this.updateTableState.emit(this.tableState);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputData']) {
      this.handleInputData(changes['inputData'].currentValue);
    }
  }

  ngOnDestroy() {
    this.pageDetailsDrawerService.closePageDetails();
  }

  openPageDetails(page: IBrowserLogsPerPageTableRow) {
    const browserConsoleLogsState = {
      [BROWSER_CONSOLE_LOGS_SEARCH_TEXT_KEY]: this.selectedConsoleMessage || '',
    };

    this.openedPageId = page.pageId;
    this.pageDetailsDrawerService
      .openPageDetails({ id: page.pageId, url: page[this.TableColumn.PageUrl] }, this.auditId, this.runId, EPageDetailsTabs.ConsoleLog, null, browserConsoleLogsState);
  }

  private handleInputData(inputData: IBrowserLogsPerPageTableRow[]) {
    if (inputData && Array.isArray(inputData)) {
      this.tableDataSource.data = inputData.map(i => {
        const loadTime = parseFloat((i.pageLoadTime / 1000).toFixed(1));
        return {
          ...i,
          pageLoadTime: loadTime,
          loadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(loadTime),
          finalPageStatusCode: i.finalPageStatusCode,
          statusCodeClass: this.auditReportService.getStatusCodeClass(i.finalPageStatusCode)
        };
      });
    }
  }

  private formatPaginator(): void {
    this.tablePaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }
}
