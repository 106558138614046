<mat-drawer-container [hasBackdrop]="false">
  <mat-drawer [disableClose]="true" mode="over" position="end" [opened]="drawerIsOpen" class="audit-report-drawer" (openedChange)="handleMatDrawerOpenedChanged($event)">
    <op-page-details *ngIf="drawerIsOpen"></op-page-details>
  </mat-drawer>
  <mat-drawer-content>

    <ng-content></ng-content>

  </mat-drawer-content>
</mat-drawer-container>
<op-table-cell-tooltip></op-table-cell-tooltip>
