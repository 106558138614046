<op-modal [rightFooterButtons]="buttons"
          [preventSimultaneousClose]="true">

  <ng-template #headerTemplate>
    <op-modal-header [title]="data.title"
                     (onClose)="dialogRef.close()">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <rule-selector
      class="scroll-vertically"
      [rules]="data.rules"
      [labels]="data.labels"
      [selectedItemsAndRules]="data.selectedRules"
      (openRuleCreation)="data.openRuleCreation()"
      (openRuleEditor)="data.openRuleEditor($event)">
    </rule-selector>
  </ng-template>
</op-modal>
