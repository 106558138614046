import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { IButton } from '@app/models/commons';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { startWith } from 'rxjs/operators';
import { IUser } from '@app/moonbeamModels';
import {
  IEditAuditPageLimit
} from '@app/components/usage-v2/components/usage-top-widgets/components/usage-users-widget/components/edit-audit-page-limit/edit-audit-page-limit.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'edit-audit-page-limit',
  templateUrl: './edit-audit-page-limit.component.html',
  styleUrls: ['./edit-audit-page-limit.component.scss']
})
export class EditAuditPageLimitComponent implements OnInit, OnDestroy {
  rightFooterButtons: IButton[] = [{
    label: 'Save',
    action: () => this.save(),
    primary: true,
    disabled: true,
  }];

  limit: FormControl;
  formValiditySubscription: Subscription;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.limit.value.length) {
      $event.preventDefault();
      $event.returnValue = true;
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUser,
    private dialogRef: MatDialogRef<EditAuditPageLimitComponent>,
  ) {
    console.log(data);
  }

  private initForm(): void {
    this.limit = new FormControl<number>(
      this.data.maxPagesPerAudit || 0,
      [Validators.required]
    );
    this.formValiditySubscription = this.limit.statusChanges
      .pipe(startWith(this.limit.invalid))
      .subscribe(() => this.rightFooterButtons[0].disabled = this.limit.invalid);
  }

  close(payload?: IEditAuditPageLimit): void {
    this.dialogRef.close(payload);
  }

  save(): void {
    this.close({
      user: this.data,
      limit: +this.limit.value,
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.formValiditySubscription?.unsubscribe();
  }
}
