import { Names } from '@app/moonbeamConstants';
import { DiscoveryAuditService } from './discoveryAudits/discoveryAuditService';
import { ExportToEmailModalService } from './discoveryAudits/reporting/selectionSummary/selectionPagesSummaryMenu/exportToEmailModalService';
import { AuditDataService } from './discoveryAudits/reporting/services/auditDataService/auditDataService';
import { SortingService } from './discoveryAudits/reporting/services/sortingService/sortingService';
import { WebJourneyAPIService } from './webJourneys/webJourneyAPI/webJourneyAPIService';
import * as angular from 'angular';
import { RunInfoSerializer } from './discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { SelectionSummarySerializer } from './discoveryAudits/reporting/selectionSummary/selectionSummarySerializer/selectionSummarySerializer';
import { AuditSummaryStatsSerializer } from './discoveryAudits/reporting/summaryReports/auditSummary/auditSummaryStatsSerializer';
import { WebJourneyRunsSerializer } from './webJourneys/webJourneyAPI/webJourneyRunsSerializer';
import { JourneyResultSerializer } from './webJourneys/webJourneySerializers/getJourneyResultSerializer';
import { WebJourneyResultsSerializer } from './webJourneys/webJourneySerializers/webJourneyResultsSerializer';
import { CalculateWordWidth } from './discoveryAudits/reporting/services/calculateWordWidthService/calculateWordWidthService';
import { NumberFormatter } from './discoveryAudits/reporting/services/numberFormatting/numberFormatService';
import { userAgentService } from './UserAgentService';
import { NgModule } from '@angular/core';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/components/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomainsService } from './domainsService';
import { RorComparisonReportService } from '../ror-comparison-report/ror-comparison-report.service';
import { FileMappingResultModule } from '../shared/components/file-mapping-result/file-mapping-result.module';
import { FileMappingResultComponent, fileMappingResultComponentNameNg } from '../shared/components/file-mapping-result/file-mapping-result.component';
import { LabeledMenuModule } from '../shared/components/labeled-menu/labeled-menu.module';
import { LabeledMenuComponent, labeledMenuComponentNameNg } from '../shared/components/labeled-menu/labeled-menu.component';
import { SwitchAccountService } from '../account/admin/switch-account.service';
import { WebJourneyV3Service } from './webJourneys/web-journey-v3-api/web-journey-v3.service';
import { PageProfileSerializer } from '@app/components/domains/discoveryAudits/reporting/pageView/pageViewSerializer/pageProfileSerializer';
import { AuditRunSerializer } from '@app/components/domains/discoveryAudits/reporting/summaryReports/auditSummary/auditRunSerializer';
import { TagPresenceSerializer } from "@app/components/domains/discoveryAudits/reporting/tagReports/tagPresence/tagPresenceSerializer";

export default angular.module('domainsModule', [])
  .service(Names.Services.discoveryAudit, DiscoveryAuditService)
  .service(Names.Services.runInfoSerializer, RunInfoSerializer)
  .service(Names.Services.exportToEmailModal, ExportToEmailModalService)
  .service(Names.Services.selectionSummarySerializer, SelectionSummarySerializer)
  .service(Names.Services.auditData, AuditDataService)
  .service(Names.Services.calculateWordWidth, CalculateWordWidth)
  .service(Names.Services.numberFormat, NumberFormatter)
  .service(Names.Services.sorting, SortingService)
  .service(Names.Services.auditSummaryStatsSerializer, AuditSummaryStatsSerializer)
  .service(Names.Services.webJourneyAPI, WebJourneyAPIService)
  .service(Names.Services.webJourneyRunsSerializer, WebJourneyRunsSerializer)
  .service(Names.Services.journeyResultSerializer, JourneyResultSerializer)
  .service(Names.Services.webJourneyResultsSerializer, WebJourneyResultsSerializer)
  .service(Names.Services.domains, DomainsService)
  .factory(Names.Services.userAgent, userAgentService)
  .service(Names.Services.rorComparisonReport, downgradeInjectable(RorComparisonReportService))
  .directive(fileMappingResultComponentNameNg, downgradeComponent({ component: FileMappingResultComponent }))
  .directive(labeledMenuComponentNameNg, downgradeComponent({ component: LabeledMenuComponent }))
  .service(Names.NgServices.switchAccount, downgradeInjectable(SwitchAccountService))
  .service(Names.NgServices.webJourneyV3Service, downgradeInjectable(WebJourneyV3Service))
  .service(Names.Services.pageProfileSerializer, PageProfileSerializer)
  .service(Names.Services.auditRunSerializer, AuditRunSerializer)
  .service(Names.Services.tagPresenceSerializer, TagPresenceSerializer)
  .name;

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FileMappingResultModule,
    LabeledMenuModule,
  ]
})
export class DomainsNgModule { }
