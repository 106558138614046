import { AngularNames, Names } from '@app/moonbeamConstants';
import { IWebsocketService } from './websocketService';
import { IWebsocketProvider } from './websocketProviderService';
import * as angular from 'angular';
import { AuthenticationStorageService } from '../core/services/authentication-storage.service';
import { environment } from '@app/environments/environment';

export interface IWebsocketConnectionService {
  getConnection(): angular.IPromise<IWebsocketService>;
}

export class WebsocketConnectionService implements IWebsocketConnectionService {
  static $inject = [
    AngularNames.http,
    Names.NgServices.authenticationStorage,
    Names.Services.websocketProvider,
    AngularNames.q
  ];

  constructor(private $http: angular.IHttpService,
              private authenticationStorageService: AuthenticationStorageService,
              private websocketProvider: IWebsocketProvider,
              private $q: angular.IQService) {
  }

  getConnection(): angular.IPromise<IWebsocketService> {
    var authorizationData = this.authenticationStorageService.get();
    if (authorizationData) {
      var token: string = authorizationData.token.replace(new RegExp('=', 'g'), '+');
      var url = `${environment.webSocketUrl}`;
    }
    return this.$q.resolve(this.websocketProvider.connection(url, ['wsauth.observepoint.com', token]));
  }
}
