import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ILabel } from '@app/components/shared/services/label.service';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import {
  EAlertDataSourcesFilterTypes
} from '@app/components/alert/alert-data-sources/alert-data-sources-filter-bar/alert-data-sources-filter-bar.enums';
import {
  ISimpleTableColumn,
  ISimpleTableItemsState
} from '@app/components/shared/components/selectable-table/simple-table/simple-table.models';
import { ISearchAlertAssignmentsRequest } from '@app/components/alert/alert.models';
import {
  DefaultAlertAssignmentsPagination,
  DefaultAlertAssignmentsSorting
} from '@app/components/alert/alert.constants';
import { EAlertDataSourcesTableSortColumn } from '@app/components/alert/alert-data-sources/alert-data-sources.enums';
import {
  ESimpleTableColumnType
} from '@app/components/shared/components/selectable-table/simple-table/simple-table.enums';
import { AlertService } from '@app/components/alert/alert.service';
import { ComponentChanges, IButton } from '@app/models/commons';
import { format } from 'date-fns';
import {
  ESelectableTableSelectionMode,
  ISelectableTableSelectionChange
} from '@app/components/shared/components/selectable-table/selectable-table.models';
import { Sort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  EmailInboxEditorDataSourcesFilterBarService
} from '@app/components/email-inboxes/email-inbox-editor/email-inbox-editor-data-sources/email-inbox-editor-data-sources-filter-bar/email-inbox-editor-data-sources-filter-bar.service';
import { finalize } from 'rxjs/operators';

interface IAuditDataSourcesTableItem {
  auditId: number,
  auditName: string,
  auditLastRunAt: string | null,
  auditLabels: ILabel[],
}

@Component({
  selector: 'op-email-inbox-audit-selector',
  templateUrl: './email-inbox-audit-selector.component.html',
  styleUrls: ['./email-inbox-audit-selector.component.scss'],
  providers: [EmailInboxEditorDataSourcesFilterBarService]
})
export class EmailInboxAuditSelectorComponent implements OnInit, OnChanges {

  formGroup: UntypedFormGroup;
  readOnlyLabel: string = '';
  labelsMap: Map<number, ILabel>;
  @Output() filtersUpdated = new EventEmitter<IOpFilterBarV2Filter<EAlertDataSourcesFilterTypes>[]>();

  dataSourcesState: ISimpleTableItemsState<IAuditDataSourcesTableItem>;
  labels: ILabel[] = [];

  assignments: ISearchAlertAssignmentsRequest = {};
  sorting = { ...DefaultAlertAssignmentsSorting };
  pagination = { ...DefaultAlertAssignmentsPagination };

  loading = false;
  selectionMode: ESelectableTableSelectionMode = ESelectableTableSelectionMode.Multiple;

  leftFooterButtons: IButton[] = [{
    label: 'CANCEL',
    action: () => this.closeSilently(),
    transparent: true,
  }];

  rightFooterButtons: IButton[] = [{
    label: 'ASSIGN AUDITS TO INBOX',
    action: () => this.close(),
    primary: true,
  }];

  readonly columns: ISimpleTableColumn<IAuditDataSourcesTableItem>[] = [
    {
      propName: 'auditName',
      sortKey: EAlertDataSourcesTableSortColumn.ItemName,
      title: 'Audit Testing Scenario',
      type: ESimpleTableColumnType.Text
    },
    {
      propName: 'auditLastRunAt',
      sortKey: EAlertDataSourcesTableSortColumn.RunDate,
      title: 'Last Run Date',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'auditLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }];

  close(): void {
    const selectedAudits = this.itemsControl.value
      .map(a => ({
        auditId: a.auditId,
        auditName: a.auditName,
        auditLastRunAt: a.auditLastRunAt,
        auditLabels: a.auditLabels.map(l => l.id)
      }));
    this.dialogRef.close(selectedAudits);
  }

  closeSilently(): void {
    this.dialogRef.close();
  }

  get modalTitle(): string {
    return `SELECT EXISTING AUDIT${this.selectionMode === ESelectableTableSelectionMode.Single ? '' : '(s)'}`;
  }

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EmailInboxAuditSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      labels: Map<number, any>,
      associatedAudits: IAuditDataSourcesTableItem[],
      selectionMode: ESelectableTableSelectionMode,
    }
  ) {
    this.setLabels();
  }

  ngOnInit(): void {
    this.initForm();
    this.fetchDataSources();
    this.setSelectionMode();
  }

  private setSelectionMode(): void {
    this.selectionMode = this.data.selectionMode;
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      items: this.formBuilder.control([])
    });

    if (this.data.associatedAudits) {
      this.itemsControl.setValue(this.data.associatedAudits);
    }
  }

  private setLabels(): void {
    this.labels = [...this.data.labels.values()];
  }

  ngOnChanges(changes: ComponentChanges<EmailInboxAuditSelectorComponent>): void {
    if (changes.labelsMap?.currentValue && changes.labelsMap?.currentValue !== changes.labelsMap?.previousValue) {
      this.labels = [...this.labelsMap.values()];
    }
  }

  private fetchDataSources(): void {
    this.dataSourcesState = { items: [], loading: true };

    this.alertService.searchDataSources(this.assignments, this.sorting, this.pagination)
      .pipe(finalize(() => this.dataSourcesState.loading = false))
      .subscribe(({ items, metadata: { pagination } }) => {
        this.pagination = pagination;

        this.dataSourcesState = {
          loading: false,
          items: items.map(({ itemId, itemName, itemLastRunAt, itemLabels }) => ({
            auditId: itemId,
            auditName: itemName,
            auditLastRunAt: itemLastRunAt ? format(new Date(itemLastRunAt), 'yyyy/MM/dd, hh:mm aaa') : null,
            auditLabels: itemLabels.map(id => this.data.labels.get(id)),
          }))
        };
      });
  }

  selectionChanged({ added, removed }: ISelectableTableSelectionChange<IAuditDataSourcesTableItem>): void {
    let selectedItems = this.itemsControl.value as IAuditDataSourcesTableItem[];
    if (removed) {
      selectedItems = selectedItems.filter(
        selectedItem => !removed.find(r => r.auditId === selectedItem.auditId)
      );
    }

    if (added) {
      selectedItems = [...selectedItems, ...added];
    }

    this.itemsControl.setValue([...new Set(selectedItems)]);
  }

  sortChanged(sort: Sort): void {
    this.sorting = {
      sortBy: sort.active as EAlertDataSourcesTableSortColumn,
      sortDesc: sort.direction === 'desc',
    };
    this.pagination.currentPageNumber = 0;
    this.fetchDataSources();
  }

  paginationChanged(pageIndex: number): void {
    this.pagination.currentPageNumber = pageIndex;
    this.fetchDataSources();
  }

  filtersChanged(filters: IOpFilterBarV2Filter<EAlertDataSourcesFilterTypes>[]): void {
    this.assignments = filters.reduce((acc: ISearchAlertAssignmentsRequest, filter) => {
      switch (filter.type) {
        case EAlertDataSourcesFilterTypes.Name:
          const itemName = (filter.value as string[])[0];
          return { ...acc, itemName };
        case EAlertDataSourcesFilterTypes.Label:
          const labelIds = filter.value as number[];
          const itemLabels = acc.itemLabels ? [...acc.itemLabels, ...labelIds] : labelIds;
          return { ...acc, itemLabels };
        case EAlertDataSourcesFilterTypes.Folder:
          const checkedItems = filter.menuItems
            .map(folder => {
              const checkedDomains = folder.children
                .filter(({ checked }) => checked)
                .map(({ id }) => id);

              if (!folder.checked && checkedDomains.length === 0) return null;

              const allDomainsChecked = checkedDomains.length === folder.children.length;
              return {
                folderId: folder.id,
                domains: allDomainsChecked ? null : checkedDomains
              };
            })
            .filter(folder => !!folder);
          return { ...acc, ...(checkedItems?.length ? { folders: checkedItems } : undefined) };
        default:
          return acc;
      }
    }, {});
    this.pagination.currentPageNumber = 0;
    this.fetchDataSources();
  }

  get itemsControl(): UntypedFormControl {
    return this.formGroup.get('items') as UntypedFormControl;
  }
}
