import {AngularNames} from '@app/moonbeamConstants';
import * as angular from 'angular';


let $timeout;
  focusGuardDirective.$inject = [
    AngularNames.timeout
  ];
  export function focusGuardDirective(timeout: angular.ITimeoutService): angular.IDirective {
    $timeout = timeout;
    return {
      scope: false,
      compile: compileDirective
    };
  }

  const compileDirective = (el: angular.IAugmentedJQuery) => {
    const startElement = angular.element('<div id="focusguard-start" tabIndex="0"></div>');
    const endElement = angular.element('<div id="focusguard-end" tabIndex="0"></div>');

    el.prepend(startElement);
    el.append(endElement);

    return {
      pre: undefined,
      post: linkDirective
    };
  };

  const linkDirective = ($scope: angular.IScope, element: angular.IAugmentedJQuery, attr: angular.IAttributes) => {
    $timeout(() => {
      const autofocusElement = element.find('[autofocus]');
      if (autofocusElement) {
        return;
      }
      const focusChildren = element.find(':focusable');
      focusChildren[1].focus();
      $('#focusguard-end').on('focus', () => {
        focusChildren[1].focus();
      });
      $('#focusguard-start').on('focus', () => {
        focusChildren[focusChildren.length - 2].focus();
      });
    })
  }


