<div class="cc-create-tab-content-wrapper">
  <div class="source-selection-description" *ngIf="!noSelectionDisplay">Select the request domains and geolocations from your domain(s) that you would like to add to this Consent Category.</div>
  <div class="source-selection-description" *ngIf="noSelectionDisplay">The following is a list of all request domains and geolocations present in this audit. Please select request domains and geolocations you want to add to any consent category.</div>
  <div class="source-item-search">
    <op-clearable-input placeholderText="Search by request domain or geolocation"
                        [emitKeyboardEvent]="true"
                        appearance="OUTLINED"
                        class="table-filter-input"
                        (onUserInput)="debounceFilterData($event)">
    </op-clearable-input>
  </div>
  <div class="cc-tables-scroll-container">
    <div class="cc-selected-table-wrapper" [class.no-selection-display]="noSelectionDisplay">
      <mat-expansion-panel hideToggle
                           [disabled]="selectedDataSource.data?.length === 0"
                           (opened)="openedSelection()"
                           (closed)="closedSelection()">
        <mat-expansion-panel-header>
          <cc-create-selection-info [selectedItems]="selectedRequestDomains"
                                    selectedItemText="Requests and Geos"
                                    [displaySelection]="displaySelection"
                                    [countOnly]="noSelectionDisplay"
                                    [selectedLimit]="selectedLimit">
          </cc-create-selection-info>
        </mat-expansion-panel-header>
        <div class="cc-selected-request-domains-table-wrapper" [hidden]="loading || this.selectedDataSource.data?.length === 0 || noSelectionDisplay">
          <table mat-table
                 [dataSource]="selectedDataSource"
                 class="cc-selected-request-domains-table"
                 #selectedTableSort="matSort"
                 matSort
                 multiTemplateDataRows
          >
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let row" valign="middle">
                <mat-checkbox (change)="toggleSelectOne($event.checked, row, true)"
                              [disabled]="requestDomainsHash[row.domain]?.existing"
                              [checked]="requestDomainsHash[row.domain]?.selected"
                              color="primary"
                              [class.checkbox-disabled]="requestDomainsHash[row.domain]?.existing"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="requestDomains">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Request Domain</th>
              <td mat-cell
                  (click)="toggleSelectOne(!requestDomainsHash[requestDomain.domain]?.selected, requestDomain, true)"
                  *matCellDef="let requestDomain">
                <div class="request-domain-name" [class.clickable]="!requestDomainsHash[requestDomain.domain]?.existing">{{ requestDomainsHash[requestDomain.domain]?.domain }}</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="geoLocations">
              <th mat-header-cell *matHeaderCellDef>GEOLOCATIONS</th>
              <td mat-cell *matCellDef="let requestDomain">
                <div class="cc-source-simple-chip-wrapper" *ngIf="requestDomainsHash[requestDomain.domain]?.existing">
                  <div class="cc-source-any-location" *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length === 0">Any location</div>
                  <div class="cc-source-simple-chip"
                       *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length >= 1">
                    <div class="simple-chip-text">
                      {{requestDomainsHash[requestDomain.domain]?.locationIds?.length}} more
                    </div>
                    <mat-icon>expand_more</mat-icon>
                  </div>
                  <div class="existing-message">
                    <mat-icon>info</mat-icon>
                    Item already included in this consent category
                  </div>
                </div>
                <div class="cc-source-simple-chip-wrapper" *ngIf="!requestDomainsHash[requestDomain.domain]?.existing">
                  <div class="cc-source-simple-chip"
                       *ngFor="let countryId of requestDomainsHash[requestDomain.domain]?.locationIds | slice:0:4; let index = index;"
                       [matTooltip]="geos.countriesById[countryId].countryName"
                       matTooltipPosition="above"
                       matTooltipShowDelay="750">
                    <div class="simple-chip-text">
                      {{ geos.countriesById[countryId].countryName }}
                    </div>
                    <mat-icon (click)="removeCountry(requestDomain, countryId)">close</mat-icon>
                  </div>
                  <div class="cc-source-any-location" *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length === 0">Any location</div>
                  <div class="cc-source-simple-chip simple-chip-inverted"
                       *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length > 1 && requestDomainsHash[requestDomain.domain]?.locationIds?.length <= 4">
                    <div class="simple-chip-text">
                      clear all
                    </div>
                    <mat-icon (click)="removeAllRequestDomainCountries(requestDomain)">close</mat-icon>
                  </div>
                  <div class="cc-source-simple-chip"
                       *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length >= 5">
                    <div class="simple-chip-text">
                      {{requestDomainsHash[requestDomain.domain]?.locationIds?.length - 3}} more
                    </div>
                    <mat-icon (click)="openRequestDomainsCountriesDropdown(requestDomain)">expand_more</mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row
                class="cc-source-table-row"
                *matHeaderRowDef="displayedColumns; sticky: true">
            </tr>
            <tr mat-row
                class="cc-source-table-row"
                [class.existing]="requestDomainsHash[row.domain]?.existing"
                *matRowDef="let row; columns: displayedColumns;">
            </tr>
          </table>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="cc-import-tables-wrapper">
      <div class="cc-request-domains-table-wrapper" [hidden]="loading || this.dataSource.data?.length === 0">
        <div class="source-zero-state" *ngIf="!loading && this.dataSource.data?.length === 0 && requestDomainsFilter !== ''">No tags or accounts match the current filter.</div>
        <div class="source-zero-state" *ngIf="!loading && this.dataSource.data?.length === 0 && requestDomainsFilter === ''">No request domains or geolocations were discovered on the selected run(s).</div>
        <table mat-table
               [hidden]="loading || this.dataSource.data?.length === 0"
               [dataSource]="dataSource"
               class="cc-source-table"
               #resultsTableSort="matSort"
               matSort
               multiTemplateDataRows
        >
          <ng-container matColumnDef="select-all">
            <th mat-header-cell *matHeaderCellDef
                valign="middle"
                [attr.colspan]="displayedColumns.length">
              <div class="select-all-wrapper"
                   [class.disabled-select-all]="!allRequestDomainsSelected && ((pagination.totalCount > selectedLimit) || unselectedRequestDomainsInResponse > (selectedLimit - selectedRequestDomains.length))"
              >
                <div class="checkbox-wrapper">
                  <mat-checkbox (change)="toggleSelectAll($event.checked)"
                                [checked]="allRequestDomainsSelected"
                                [disabled]="!allRequestDomainsSelected && ((pagination.totalCount > selectedLimit) || unselectedRequestDomainsInResponse > (selectedLimit - selectedRequestDomains.length))"
                                color="primary"
                                aria-label="Select All Request Domains">
                  </mat-checkbox>
                </div>
                <div class="select-all-text">Select All ({{pagination.totalCount}})
                  <span class="disabled-select-all-text" *ngIf="!allRequestDomainsSelected && ((pagination.totalCount > selectedLimit) || unselectedRequestDomainsInResponse > (selectedLimit - selectedRequestDomains.length))">The items listed are greater than the limit of {{selectedLimit}}, please filter your results to enable select all.</span>
                </div>
              </div>
            </th>
          </ng-container>
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let row; let i = dataIndex;" valign="middle">
              <div class="checkbox-wrapper">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="handleCheckboxClick($event.checked, i)"
                              [checked]="requestDomainsHash[row.domain]?.selected"
                              [disabled]="requestDomainsHash[row.domain]?.existing"
                              color="primary">
                </mat-checkbox>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="requestDomains">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Request Domain</th>
            <td mat-cell
                (click)="toggleSelectOne(!requestDomainsHash[requestDomain.domain]?.selected, requestDomain)"
                *matCellDef="let requestDomain">
              <div class="request-domain-name" [class.clickable]="!requestDomainsHash[requestDomain.domain]?.existing">{{ requestDomainsHash[requestDomain.domain]?.domain }}</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="geoLocations">
            <th mat-header-cell *matHeaderCellDef>GEOLOCATIONS</th>
            <td mat-cell *matCellDef="let requestDomain">
              <div class="cc-source-simple-chip-wrapper" *ngIf="requestDomainsHash[requestDomain.domain]?.existing">
                <div class="cc-source-any-location" *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length === 0">Any location</div>
                <div class="cc-source-simple-chip"
                     *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length >= 1">
                  <div class="simple-chip-text">
                    {{requestDomainsHash[requestDomain.domain]?.locationIds?.length}} more
                  </div>
                  <mat-icon>expand_more</mat-icon>
                </div>
                <div class="existing-message">
                  <mat-icon>info</mat-icon>
                  Item already included in this consent category
                </div>
              </div>
              <div class="cc-source-simple-chip-wrapper" *ngIf="!requestDomainsHash[requestDomain.domain]?.existing">
                <div class="cc-source-simple-chip"
                     *ngFor="let countryId of requestDomainsHash[requestDomain.domain]?.locationIds | slice:0:4; let index = index;"
                     [matTooltip]="geos.countriesById[countryId].countryName"
                     matTooltipPosition="above"
                     matTooltipShowDelay="750">
                  <div class="simple-chip-text">
                    {{ geos.countriesById[countryId].countryName }}
                  </div>
                  <mat-icon (click)="removeCountry(requestDomain, countryId)">close</mat-icon>
                </div>
                <div class="cc-source-any-location" *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length === 0">Any location</div>
                <div class="cc-source-simple-chip simple-chip-inverted"
                     *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length > 1 && requestDomainsHash[requestDomain.domain]?.locationIds?.length <= 4">
                  <div class="simple-chip-text">
                    clear all
                  </div>
                  <mat-icon (click)="removeAllRequestDomainCountries(requestDomain)">close</mat-icon>
                </div>
                <div class="cc-source-simple-chip"
                     *ngIf="requestDomainsHash[requestDomain.domain]?.locationIds?.length >= 5">
                  <div class="simple-chip-text">
                    {{requestDomainsHash[requestDomain.domain]?.locationIds?.length - 3}} more
                  </div>
                  <mat-icon (click)="openRequestDomainsCountriesDropdown(requestDomain)">expand_more</mat-icon>
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row class="cc-source-table-row" *matHeaderRowDef="displayedColumns; sticky: true">
          </tr>
          <tr mat-header-row
              class="cc-source-table-row"
              *matHeaderRowDef="['select-all']">
          </tr>
          <tr mat-row class="cc-source-table-row" [class.existing]="requestDomainsHash[row.domain]?.existing" *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
        <mat-paginator [class.hidden]="loading"
                       [length]="pagination.totalCount"
                       [pageSize]="pagination.pageSize"
                       [hidePageSize]="true"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" class="spinner-wrapper">
  <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
</div>
