<mat-icon [matMenuTriggerFor]="menu"
          #menuTrigger="matMenuTrigger"
          class="material-symbols-outlined"
          @.disabled>
  splitscreen_vertical_add
</mat-icon>

<mat-menu @.disabled
          class="switchable-column-menu"
          backdropClass="no-filter"
          #menu="matMenu">
  Columns:
  <ul class="item-list">
    <li *ngFor="let item of pinnedMenuItems"
        [matTooltip]="item.checked && showPinnedItemTooltip ? warningMessage : null"
        [matTooltipPosition]="'left'"
        class="item">
      <mat-checkbox (click)="$event.stopImmediatePropagation(); emitStateUpdates(item)"
                    [checked]="item.checked"
                    color="primary">{{item.title}}</mat-checkbox>
    </li>
  </ul>

  <mat-divider class="divider"></mat-divider>

  <ul class="item-list">
    <li *ngFor="let item of menuItems">
      <mat-checkbox (click)="$event.stopImmediatePropagation(); emitStateUpdates(item)"
                    [checked]="item.checked"
                    color="primary"
                    class="item">{{item.title}}</mat-checkbox>
    </li>
  </ul>

  <mat-divider class="divider"></mat-divider>

  <button mat-menu-item
          (click)="reset()"
          class="reset-btn">
    <mat-icon>rotate_left</mat-icon>
    Reset to Default
  </button>
</mat-menu>
