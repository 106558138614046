<div class="flex-col flex-stretch inside-table-wrap">
  <div *ngIf="loading; else mainContent"
       class="spinner">
    <mat-spinner [diameter]="40" [strokeWidth]="3"></mat-spinner>
  </div>
  <mat-paginator class="styled-mat-paginator"
                 [class.hide-paginator]="loading || pagination.totalCount <= 100"
                 [length]="pagination?.totalCount"
                 [pageSize]="pagination?.pageSize"
                 [hidePageSize]="true">
  </mat-paginator>
</div>

<ng-template #dash>---</ng-template>

<ng-template #zeroState>
  <op-email-inbox-messages-zero-state [inbox]="inbox"></op-email-inbox-messages-zero-state>
</ng-template>

<ng-template #mainContent>
  <ng-container *ngIf="dataSource?.data?.length; else zeroState">
    <div class="table-container">
      <table mat-table
             matSort
             matSortActive="receivedAt"
             [matSortDirection]="sortOptions.sortDir"
             [matSortStart]="sortOptions.sortDir"
             [dataSource]="dataSource"
             (matSortChange)="handleSort(sort)"
             class="inside-table mat-table">
        <ng-container matColumnDef="screenshotUrl">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell
              *matCellDef="let row"
              (click)="openScreenshot(row)"
          >
            <img [src]="row.screenshotThumbnailPath"
                 onError="this.src='/images/no-img-b-rect.jpg'"
                 class="screenshot-thumbnail"
                 [alt]="row.subject">
          </td>
        </ng-container>

        <ng-container matColumnDef="subject">
          <th mat-header-cell
              *matHeaderCellDef>
            <div class="text-left">
              MESSAGE SUBJECT
            </div>
          </th>
          <td mat-cell
              *matCellDef="let row"
          >
            <div class="subject"
                 [matTooltip]="row.subject"
                 [matTooltipPosition]="'above'">
              {{ row.subject }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="receivedAt">
          <th mat-header-cell
              mat-sort-header
              *matHeaderCellDef>
            <div class="text-left">
              DATE RECEIVED
            </div>
          </th>
          <td mat-cell
              *matCellDef="let row"
          >
            <div class="date-received text-left"
                 [matTooltip]="row.receivedAtTooltipText"
                 [matTooltipPosition]="'above'">
              {{ row.receivedAtText }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="senderEmailAddress">
          <th mat-header-cell *matHeaderCellDef>
            <div class="text-left">
              SENDER
            </div>
          </th>
          <td mat-cell
              *matCellDef="let row"
          >
            {{ row.senderEmailAddress }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="center-text">
            <div class="center-text">
              STATUS
            </div>
          </th>
          <td mat-cell
              *matCellDef="let row"
          >
            <div class="cell-content-centered text-capitalize">
              {{ row.status }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="uniqueLinks">
          <th mat-header-cell *matHeaderCellDef class="center-text">
            <div class="center-text">
              UNIQUE LINKS
            </div>
          </th>
          <td mat-cell
              *matCellDef="let row"
          >
            <div class="cell-content-centered">
              <ng-container *ngIf="row.uniqueLinks; else dash">
                {{ row.uniqueLinks }}
              </ng-container>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="testScenarioRuns">
          <th mat-header-cell *matHeaderCellDef class="center-text">
            <div class="center-text">
              TEST SCENARIO RUNS
            </div>
          </th>
          <td mat-cell
              *matCellDef="let row"
          >
            <div class="cell-content-centered">
              <div *ngIf="row.runs?.length > 0; else dash"
                   [matMenuTriggerFor]="runsMenu"
                   class="audit-runs"
              >
                {{ row.runs?.length }}
                <mat-icon class="audit-runs-icon">keyboard_arrow_down</mat-icon>
              </div>
              <mat-menu #runsMenu="matMenu"
                        backdropClass="no-filter">
                <button *ngFor="let run of row.runs"
                        mat-menu-item
                        matTooltip="View Audit Summary"
                        matTooltipShowDelay="500"
                        matTooltipPosition="above"
                        class="menu-btn"
                        (click)="gotoRun(run)">
                  <div class="menu-btn-wrapper">
                    <div class="menu-btn-text">
                      {{ run.auditName }}
                    </div>
                    <mat-icon *ngIf="run.isCompleted; else menuItemSpinner" class="menu-btn-icon">arrow_forward</mat-icon>
                  </div>
                </button>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="readyToSend">
          <th mat-header-cell *matHeaderCellDef class="center-text">
            <div class="center-text">
              READY TO SEND BASED OFF TRIGGERED ALERTS
            </div>
          </th>
          <td mat-cell
              *matCellDef="let row"
          >
            <div class="cell-content-centered">
              <ng-container *ngIf="displayReadyToSend(row); else dash">
                <button [matMenuTriggerFor]="readyToSendMenu" class="ready-to-send"
                        [matTooltip]="getReadyToSendTooltipText(row)">
                  <mat-icon
                    [class]="'material-icons-outlined material-symbols-outlined ready-to-send-icon' + ' ' + row.readyToSend.status.toLowerCase()"
                  >
                    {{ row.readyToSend.iconName }}
                  </mat-icon>

                  <div>{{ row.readyToSend.label }}</div>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
                <mat-menu
                  #readyToSendMenu="matMenu"
                          backdropClass="no-filter">
                  <button *ngFor="let audit of row.audits"
                          mat-menu-item
                          matTooltip="View Audit Summary"
                          matTooltipShowDelay="500"
                          matTooltipPosition="above"
                          class="menu-btn"
                          (click)="gotoAudit(audit)"
                          >
                    <div class="menu-btn-wrapper">
                      <ng-container *ngIf="audit.assignedAlertCount === 0; else thumbsSection">
                        <mat-icon class="material-symbols-outlined ready-to-send-icon">indeterminate_question_box</mat-icon>
                      </ng-container>
                      <ng-template #thumbsSection>
                        <mat-icon *ngIf="audit.triggeredAlertCount > 0" class="material-icons-outlined ready-to-send-icon bad">thumb_down</mat-icon>
                        <mat-icon *ngIf="audit.triggeredAlertCount <= 0" class="material-icons-outlined ready-to-send-icon good">thumb_up</mat-icon>
                      </ng-template>
                      <div class="menu-btn-text">
                        {{ audit.auditName }}
                      </div>
                      <mat-icon *ngIf="audit.isCompleted; else menuItemSpinner" class="menu-btn-icon">arrow_forward</mat-icon>
                    </div>
                  </button>
                </mat-menu>
              </ng-container>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true;">
        </tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"
            class="inbox-messages-table-row"
        >
        </tr>
      </table>
    </div>
  </ng-container>
</ng-template>

<ng-template #menuItemSpinner>
  <mat-spinner diameter="18"></mat-spinner>
</ng-template>
