<!-- WIDGETS -->
<div class="cookie-inventory-charts">
  <div class="widget-container">
    <div class="widget-container widget-nowrap">
      <split-card [topLabel]="widgetPagesScanned.topLabel"
                  [topChangeMeaning]="widgetPagesScanned.topChangeMeaning"
                  [state]="widgetsState"
                  [metricType]="widgetPagesScanned.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="filteredPageCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPagesScanned.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of pages scanned in this audit/run.'">
        <div class="widget-pages-details">
          <div class="page-counts">
            <span class="pages-filtered-count">{{ filteredPageCount | number }}</span>
            <span *ngIf="widgetsState === 'filtered'">&nbsp;of {{ totalPageCount | number }}</span>
          </div>
          <filter-spinner [state]="widgetsState"></filter-spinner>
        </div>
      </split-card>
      <split-card [topLabel]="widgetUniqueCookies.topLabel"
                  [topChangeContent]="widgetUniqueCookies.topChangeContent"
                  [topChangeMeaning]="widgetUniqueCookies.topChangeMeaning"
                  [bottomHandler]="widgetUniqueCookies.bottomHandler"
                  [haveEnoughData]="widgetUniqueCookiesSparklineData?.length > 1"
                  [state]="widgetsState"
                  [metricType]="widgetUniqueCookies.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="filteredUniqueCookieCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetUniqueCookies.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of unique cookies discovered during this audit/run. Uniqueness is determined by ' +
                     'all cookie parameters except path.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight meaningOfUp="none"
                                 meaningOfNoChange="none"
                                 [dataLoaded]="sparklineDataLoaded"
                                 label="since last run"
                                 [dataPoints]="widgetUniqueCookiesSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetFirstParty.topLabel"
                  [topChangeContent]="widgetFirstParty.topChangeContent"
                  [topChangeMeaning]="widgetFirstParty.topChangeMeaning"
                  [activated]="isFilteredByCookie(ECookiePartyType.first)"
                  [topHandler]="widgetFirstParty.topHandler"
                  [bottomHandler]="widgetFirstParty.bottomHandler"
                  [haveEnoughData]="widgetFirstPartySparklineData?.length > 1"
                  [state]="widgetsState"
                  [metricType]="widgetFirstParty.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="filteredFirstPartyCookieCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetFirstParty.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of all unique 1st-party cookies. 1st-party cookies are directly created by the ' +
                   'domain being visited.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight meaningOfUp="none"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetFirstPartySparklineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
    <div class="widget-container widget-nowrap">
      <split-card [topLabel]="widgetThirdParty.topLabel"
                  [topChangeContent]="widgetThirdParty.topChangeContent"
                  [topChangeMeaning]="widgetThirdParty.topChangeMeaning"
                  [topHandler]="widgetThirdParty.topHandler"
                  [activated]="isFilteredByCookie(ECookiePartyType.third)"
                  [bottomHandler]="widgetThirdParty.bottomHandler"
                  [state]="widgetsState"
                  [haveEnoughData]="widgetThirdPartySparklineData?.length > 1"
                  [metricType]="widgetThirdParty.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetThirdParty.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of all unique 3rd party cookies. 3rd party cookies are created by domains that are ' +
                   'not the domain being visited.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetThirdPartySparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetNonSecure.topLabel"
                  [topChangeContent]="widgetNonSecure.topChangeContent"
                  [topChangeMeaning]="widgetNonSecure.topChangeMeaning"
                  [topHandler]="widgetNonSecure.topHandler"
                  [bottomHandler]="widgetNonSecure.bottomHandler"
                  [haveEnoughData]="widgetNonSecureSparklineData?.length > 1"
                  [activated]="isFilteredByNonSecure"
                  [state]="widgetsState"
                  [metricType]="widgetNonSecure.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetNonSecure.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of unique cookies where the secure attribute is false or empty. It is recommended that all cookies are secure. ' +
                     'Give your cookies a confidence boost by making them secure!' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetNonSecureSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetSameSiteEmpty.topLabel"
                  [topChangeContent]="widgetSameSiteEmpty.topChangeContent"
                  [topChangeMeaning]="widgetSameSiteEmpty.topChangeMeaning"
                  [topHandler]="widgetSameSiteEmpty.topHandler"
                  [bottomHandler]="widgetSameSiteEmpty.bottomHandler"
                  [haveEnoughData]="widgetSameSiteEmptySparklineData?.length > 1"
                  [activated]="isFilteredBySameSite"
                  [state]="widgetsState"
                  [metricType]="widgetSameSiteEmpty.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetSameSiteEmpty.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of unique cookies where the SameSite attribute is empty. It is recommended that all ' +
                   'cookies have a value for this attribute.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetSameSiteEmptySparklineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
  </div>
</div>

<div class="cookies-list" #scrollTopCookies>
  <op-cookies-list
    #cookiesList
    [auditId]="auditId"
    [runId]="runId"
    [items]="cookiesList$ | async"
    [defaultSorting]="cookiesTablePagination"
    [exportReportConfig]="cookiesExportConfig"
    [tableState]="cookiesTableState"
    [filteredPageCount]="filteredPageCount"
    [apiFilters]="apiFilters"
    (localFilter)="handleFilterByPage($event)"
    (onSort)="handleSort($event)"
    (onPaginate)="handlePagination($event)"
    (globalFilter)="handleGlobalFilter($event)"
  ></op-cookies-list>
</div>

<div class="pages-wrap" #scrollTopPages>
  <div class="section-title-wrap">
    <div class="title-spinner-wrap">
      <filter-spinner [state]="pagesTableState"></filter-spinner>
      <p class="section-title">Pages Scanned</p>
      <op-audit-report-export-menu
        class="audit-report-export-menu"
        [auditId]="auditId"
        [runId]="runId"
        [tableCompletedState]="pagesTableState"
        [config]="exportReportConfig">
      </op-audit-report-export-menu>
    </div>

    <op-switchable-column-menu [columnConfig]="PagesTableSettingItems"
                               [storageKey]="CommonPagesConfigLocalStorageKey"
                               [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
  </div>

  <table
    mat-table
    matSort
    resizeableTable
    [dataSource]="dataSource"
    [class.updating]="pagesTableState === 'loading'"
    class="pages-table"
    [matSortActive]="TableColumn.CookieCount"
    matSortDirection="desc"
    matSortStart="desc"
  >
    <ng-container [matColumnDef]="TableColumn.PageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>INITIAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ page.pageUrl }}">
            <div class="url-text">{{ page.pageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>FINAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ page.finalPageUrl }}">
            <div class="url-text">{{ page.finalPageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageLoadTime">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="center-text fixed-90"
          [matTooltip]="PageLoadColumnTooltip"
          [matTooltipPosition]="'above'"
      >PAGE LOAD TIME (sec)</th>
      <td mat-cell *matCellDef="let page" class="center-text {{ page.pageLoadTimeClass }}">{{ page.pageLoadTime }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90">FINAL PAGE STATUS CODE</th>
      <td mat-cell *matCellDef="let page"
          class="center-text {{ page.pageStatusCodeClass }}"
          [matTooltip]="PageStatusCodeTooltipMap[page.finalPageStatusCode] || null"
          matTooltipPosition="above"
          matTooltipShowDelay="250"
      >{{ page.finalPageStatusCode }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.CookieCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90"># OF COOKIES</th>
      <td mat-cell *matCellDef="let page" class="center-text">{{ page.cookieCount }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.TotalCookieSize">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90">TOTAL COOKIE SIZE (BYTES)</th>
      <td mat-cell *matCellDef="let page" class="center-text">{{ page.totalCookieSize }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row
        *matRowDef="let page; columns: displayedColumns$ | async;"
        [ngClass]="{ 'open-in-page-details': page.pageId === pageIdOpenInPageDetails }"
        (click)="openPageDetails(page)"></tr>
  </table>

  <mat-paginator
    class="pages-paginator"
    [length]="paginationState.length"
    [pageSize]="paginationState.pageSize"
    [hidePageSize]="true"
    (page)="onScrollTop()"
  ></mat-paginator>
</div>
