import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'new-user-confirm',
  templateUrl: './new-user-confirm.component.html',
  styleUrls: ['./new-user-confirm.component.scss']
})
export class NewUserConfirmComponent {
  
  title: string = '';
  name: string;

  leftFooterButtons: IButton[] = [{
    label: 'Close',
    action: () => this.close(),
    transparent: true,
  }];

  rightFooterButtons: IButton[] = [{
    label: 'Create Another User',
    action: () => {
      this.close(true);
    },
    primary: true
  }];
  
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public payload: string
  ) {
    this.name = payload;
  }

  close(createNew?: boolean): void {
    this.dialogRef.close(createNew);
  }
}
