export enum EEmailInboxesFilterTypes {
  Name = 'name',
  Label = 'labelIds',
  EmailAddress = 'emailAddress',
  AssociatedAuditName = 'auditIds',
  CreatedBy = 'createdBy',
}

export enum EEmailInboxesFilterBarMenuStrings {
  InboxAddress = 'Inbox Email Address',
  Name = 'Inbox Name',
  Label = 'Label',
  AuditName = 'Associated Audit Name',
  CreatedBy = 'Created By'
}

export const EMAIL_INBOXES_SUPPORTED_FILTER_TYPES = [
  EEmailInboxesFilterTypes.Name,
  EEmailInboxesFilterTypes.Label,
  EEmailInboxesFilterTypes.EmailAddress,
  EEmailInboxesFilterTypes.AssociatedAuditName,
  EEmailInboxesFilterTypes.CreatedBy
];

export enum EEmailInboxesSupportedQueryParams {
  InboxName = 'inboxName'
}

export const EMAIL_INBOXES_SUPPORTED_QUERY_PARAMS = [
  EEmailInboxesSupportedQueryParams.InboxName
];
