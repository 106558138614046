import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { Router } from '@angular/router';
import {
  IWebJourneyApiService,
} from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { FeatureFlagHelper } from '@app/environments/feature-flag-helper';
import { FeatureFlags } from '@app/environments/feature-flags.enum';
import { AccountsService } from '@app/components/account/account.service';
import { Component, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { userIsOPAdmin, userIsOPSysAdmin } from '@app/authUtils';
import { WebJourneyReportUrlBuilders } from '@app/components/web-journey-report/web-journey-report.constants';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import {
  IQueuedWebJourney,
  IWebJourneyStatusRun
} from '@app/components/admin-portal/system-status/system-status.models';
import { SystemStatusService } from '@app/components/admin-portal/system-status/system-status.service';
import { AdminService } from '@app/components/admin-portal/system-status/admin.service';
import { LocationsService } from '@app/components/creator/webJourney/locationsService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'system-status-web-journeys',
  templateUrl: './system-status-web-journeys.component.html',
})
export class SystemStatusWebJourneysComponent implements OnDestroy, AfterViewInit {
  private destroy$: Subject<void> = new Subject();
  TIMER_INTERVAL = 60000;
  selected = 'Column Toggle';
  userIsOpSysAdmin: boolean = false;
  webJourneysInQueue: Array<IQueuedWebJourney>;
  webJourneysRunning: Array<IWebJourneyStatusRun>;
  areAllWebJourneysSelected: boolean = false;
  loadingRunning: boolean = false;
  loadingQueued: boolean = false;
  locationsMap: { [name: string]: { countryCode: string; label: string; } };

  runningDataSource = new MatTableDataSource();
  runningDisplayedColumns = ['select', 'id', 'simulationId', 'accountId', 'company', 'location', 'name', 'frequency', 'userAgent', 'createdAt', 'actions', 'waitTime'];

  queuedDataSource = new MatTableDataSource();
  queuedDisplayedColumns = ['id', 'accountId', 'company', 'location', 'name', 'frequency', 'userAgent', 'actions', 'waitTime'];

  runningFilter: string = '';
  queuedFilter: string = '';

  runningFilterChanged$ = new Subject<string>();
  queuedFilterChanged$ = new Subject<string>();
  selection = new SelectionModel(true, []);

  goToWebJourneyRunHandler = webJourney => this.goToWebJourneyRun(webJourney.accountId, webJourney.id, webJourney.simulationId);
  goToAccountHandler = webJourney => this.goToAccount(webJourney.accountId);

  runningColumns = [
    { header: 'Select', property: 'select', title: 'Select one or more audits', show: true },
    { header: 'Run ID', property: 'id', title: 'The current web journey ID', show: true },
    { header: 'Journey ID', property: 'simulationId', title: 'The web journey ID', show: true },
    { header: 'Account ID', property: 'accountId', title: 'The account ID', show: true },
    { header: 'Account', property: 'company', title: 'The description of the account', show: true },
    { header: 'Journey Name', property: 'name', title: 'The name of the web journey', show: true },
    { header: 'Frequency', property: 'frequency', title: 'How often the journey will run', show: true },
    { header: 'User Agent', property: 'userAgent', title: 'User agent for web journey run', show: true },
    { header: 'Started At', property: 'createdAt', title: 'How long ago the web journey started running', show: true },
    { header: 'Actions', property: 'actions', title: 'The total number of actions', show: true },
    { header: 'Total Wait Time', property: 'waitTime', title: 'The total wait time in seconds', show: true },
    { header: 'Location', property: 'location', title: 'Location of web journey run', show: true },
  ];

  queuedColumns = [
    { header: 'Journey ID', property: 'id', title: 'The web journey ID', show: true },
    { header: 'Account ID', property: 'accountId', title: 'The account ID', show: true },
    { header: 'Account', property: 'company', title: 'The description of the account', show: true },
    { header: 'Journey Name', property: 'name', title: 'The name of the web journey', show: true },
    { header: 'Frequency', property: 'frequency', title: 'How often the journey will run', show: true },
    { header: 'User Agent', property: 'userAgent', title: 'User agent for web journey run', show: true },
    { header: 'Actions', property: 'actions', title: 'The total number of actions', show: true },
    { header: 'Total Wait Time', property: 'waitTime', title: 'The total wait time in seconds', show: true },
    { header: 'Location', property: 'location', title: 'Location of web journey run', show: true },
  ];

  @ViewChild('runningTable', { read: MatSort, static: true }) runningSort: MatSort;
  @ViewChild('queuedTable', { read: MatSort, static: true }) queuedSort: MatSort;

  constructor(  private router: Router,
                private accountsService: AccountsService,
                private adminService: AdminService,
                private systemStatusService: SystemStatusService,
                private webJourneyApiService: IWebJourneyApiService,
                private locationsService: LocationsService,
              ) {
    this.accountsService.getUser().subscribe(user => {
      if (!userIsOPAdmin(user)) {
        const url = AccountSettingsUrlBuilders.user();
        this.router.navigateByUrl(url);
      }

      this.userIsOpSysAdmin = userIsOPSysAdmin(user);
    });

    this.locationsService.getAllLocations().then((locations) => {
      this.locationsMap = locations.reduce((acc, location) => {
        acc[location.name] = { countryCode: location.countryCode.toLowerCase(), label: location.label };
        return acc;
      }, {}
      );

      this.systemStatusService.setRefreshFunction(() => { this.getWebJourneys(); });
      this.systemStatusService.registerTimer(() => { this.getWebJourneys(); }, this.TIMER_INTERVAL);
      this.getWebJourneys();
    });

    this.runningFilterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.runningFilter = filter;
      this.runningDataSource.filter = this.runningFilter;
    });

    this.queuedFilterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.queuedFilter = filter;
      this.queuedDataSource.filter = this.queuedFilter;
    });
  }

  ngAfterViewInit() {
    this.runningDataSource.sort = this.runningSort;
    this.runningDataSource.sortingDataAccessor = this.tableSortingDataAccessor;
    this.queuedDataSource.sort = this.queuedSort;
  }

  ngOnDestroy(): void {
    this.systemStatusService.unregisterTimer();
    this.destroy$.next();
  }

  tableSortingDataAccessor(data, property) {
    switch (property) {
    case 'createdAt':
      return data['originalCreatedAt'];
    case 'location':
      return data['location'].label
    default:
      return data[property];
    }
  }

  showSnackbar() {
    this.systemStatusService.copyToClipboard();
  }

  getWebJourneys() {
    this.loadingRunning = true;
    this.loadingQueued = true;
    this.adminService.getWebJourneysInQueue()
      .pipe(
        finalize(() => {
          this.loadingQueued = false;
        })
      ).subscribe((journeys) => {
        this.webJourneysInQueue = journeys.map((j: any) => {
          j.createdAt = this.systemStatusService.getRecentDate(j.createdAt);
          j.location = this.locationsMap[j.location];
          return j;
        });

        this.queuedDataSource.data = this.webJourneysInQueue;
      }, this.systemStatusService.handleError );

    this.adminService.getWebJourneysRunning().pipe(
      finalize(() => {
        this.loadingRunning = false;
      })
    ).subscribe((journeys) => {
        this.webJourneysRunning = journeys.map((j: any) => {
          j.originalCreatedAt = j.createdAt;
          j.createdAt = this.systemStatusService.getRecentDate(j.createdAt);
          j.location = this.locationsMap[j.location];
          return j;
        });
        this.selection.clear();
        this.areAllWebJourneysSelected = false;
        this.runningDataSource.data = this.webJourneysRunning;
      }, this.systemStatusService.handleError );
  }

  goToAccount(accountId: number) {
    if (!this.userIsOpSysAdmin) {
      return;
    }

    this.systemStatusService.goToAccount(accountId);
  }

  goToWebJourneyRun(accountId: number, runId: number, journeyId: number) {
    if (!this.userIsOpSysAdmin) {
      return;
    }

    this.systemStatusService.loginAsAccount(
      accountId,
      WebJourneyReportUrlBuilders.results(journeyId, runId)
    );
  }

  restartWebJourneys(): void  {
    let webJourneys: number[] = [];
    for (let i = 0; i < this.selection.selected.length; i++){
      webJourneys.push(this.selection.selected[i].id);
    }
    if (webJourneys.length != 0 && window.confirm(`Are you sure you want to restart ${webJourneys.length} web journeys? This will REMOVE the running web journeys.`)){
      this.adminService.restartWebJourneys(webJourneys).subscribe(() => {this.getWebJourneys(); }, () => {this.getWebJourneys(); });
    }
  }

  removeWebJourneysRuns(): void {
    let webJourneys: IWebJourneyStatusRun[] = [];
    for (let i = 0; i < this.selection.selected.length; i++) {
      let runId = this.selection.selected[i].id;
      let webJourneyRunStatus = this.webJourneysRunning.find(run => run.id === runId);
      webJourneys.push(webJourneyRunStatus);
    }
    if (webJourneys.length && window.confirm(`Are you sure you want to remove ${webJourneys.length} run(s)?`)){
      let callback = () => {
        this.selection.clear();
        this.getWebJourneys();
      };
      let positiveCallback = () => {
        callback();
        window.alert(
          'Deletion process started. See progress in logs for Starter, Manager, Processor, Enforcer, Indexer, Summariser services.'
        );
      };
      let negativeCallback = () => {
        callback();
        window.alert('Cannot start delete process. See error log');
      };

      if (FeatureFlagHelper.isEnabled(FeatureFlags.SQSJourneyEngines)) {
        let promises = webJourneys.map(wj => this.webJourneyApiService.stopActiveWebJourneyRun(wj.simulationId, wj.id));
        Promise.all(promises)
          .then(positiveCallback)
          .catch(negativeCallback);
      } else {
        this.adminService.removeWebJourneyRuns(webJourneys.map(run => run.id))
          .subscribe(positiveCallback, negativeCallback);
      }
    }
  }

  prepareRunningCols(): void {
    const cols = this.runningColumns.filter(col => col.show).map(col => col.property);
    this.runningDisplayedColumns = cols;
  }

  prepareQueuedCols(): void {
    const cols = this.queuedColumns.filter(col => col.show).map(col => col.property);
    this.queuedDisplayedColumns = cols;
  }

  toggleAllColumns(type: 'running'|'queued', showAll: boolean) {
    if (type === 'running') {
      this.runningColumns.forEach(col => col.show = showAll);
      this.prepareRunningCols();
    } else {
      this.queuedColumns.forEach(col => col.show = showAll);
      this.prepareQueuedCols();
    }
  }

  debounceRunningFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.runningFilterChanged$.next(filter);
  }

  debounceQueuedFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.queuedFilterChanged$.next(filter);
  }

  // Running Table
  masterToggle(): void {
    setTimeout(() => {
      if (this.runningDataSource.filter) {
        this.isAllFilteredSelected() ?
          this.runningDataSource.filteredData.forEach(row => this.selection.deselect(row)) :
          this.runningDataSource.filteredData.forEach(row => this.selection.select(row));
      } else {
        this.isAllSelected() ?
          this.selection.clear() :
          this.runningDataSource.data.forEach(row => this.selection.select(row));
      }
    });
  }

  onCheckboxChecked(event: MouseEvent, row): void {
    event.stopPropagation();
    setTimeout(() => {
      this.selection.toggle(row);
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.runningDataSource.data.length;
    return numSelected === numRows;
  }

  isAllFilteredSelected(): boolean {
    return <boolean> this.runningDataSource.filteredData.reduce(
      (allSelected: boolean, row) => allSelected && this.selection.isSelected(row),
      true
    );
  }
}
