import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { takeUntil } from 'rxjs/operators';
import { IConsentCategory, IConsentCategoryCookie } from '@app/components/consent-categories/consent-categories.models';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Injectable()
export class ConsentCategoriesEditService implements OnDestroy {
  private destroy$ = new Subject();

  existingConsentCategorySubject = new Subject<IConsentCategory>();
  existingCookiesSubject: BehaviorSubject<IConsentCategoryCookie[]> = new BehaviorSubject([]);
  existingTagsSubject = new BehaviorSubject([]);
  existingRequestDomainsSubject = new BehaviorSubject([]);
  existingConsentCategory$: Observable<IConsentCategory> = this.existingConsentCategorySubject.asObservable();
  existingCookies$ = this.existingCookiesSubject.asObservable();
  existingTags$ = this.existingTagsSubject.asObservable();
  existingRequestDomains$ = this.existingRequestDomainsSubject.asObservable() ;
  accountTags: IUiTag[] = [];

  constructor(
    private ccService: ConsentCategoriesService,
    private uiTagService: UiTagService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  setExistingConsentCategory(cc): void {
    this.existingConsentCategorySubject.next(cc);
  }

  setExistingCookies(cookies: IConsentCategoryCookie[]): void {
    this.existingCookiesSubject.next(cookies);
  }

  setExistingTags(tags): void {
    // CC Tags don't return the tag name, so we need to update each tag and add the name from accountTags list
    tags.forEach(tag => {
      let foundTag = this.accountTags.find(accountTag => accountTag.id === tag.tagId);
      if (foundTag) {
        tag.tagName = foundTag.name;
      }
    });

    this.existingTagsSubject.next(tags);
  }

  setExistingRequestDomains(requestDomains): void {
    this.existingRequestDomainsSubject.next(requestDomains);
  }

  loadExistingData(id: number): void {
    forkJoin([
      this.ccService.getConsentCategoryById(id),
      this.ccService.getConsentCategoryCookies(id),
      this.ccService.getConsentCategoryTags(id),
      this.ccService.getConsentCategoryRequestDomains(id),
      this.uiTagService.getTags(),
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([consentCategory, {cookies}, {tags}, {requestDomains}, accountTags]) => {
      this.accountTags = accountTags;
      this.setExistingConsentCategory(consentCategory);
      this.setExistingCookies(cookies);
      this.setExistingTags(tags);
      this.setExistingRequestDomains(requestDomains);
    });
  }
}
