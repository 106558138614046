<component-template componentName="File Upload">
  <component-library-section sectionName="Overview" selector="<op-file-drop>">
    <p>This file upload component is a blank slate. The behavior when a file is dropped on it must be implemented by the consumer. It accepts a list of file types so you can specify which file types are allowed.</p>

    <div class="op-file-drop-example">
      <op-file-drop></op-file-drop>
    </div>

    <p>In the future we could potentially standardize all interactions so it's a simple process to add it to other places in the app. Things to standardize:
    <ul>
      <li>Text telling user they can also drag and drop files</li>
      <li>Button to trigger file selection</li>
      <li>Show file(s) that have been added</li>
      <li>Option to delete file(s)</li>
      <li>Small text to show what file types are accepted and any other instructions</li>
    </ul>
  </component-library-section>
</component-template>