import { IRuleV3 } from '@app/components/rules/rules.models';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/services/api.service';
import { IActionSet, IActionSetLibraryItem, IActionSetCreationRequest, IMigrateActions, IMigratedActions } from './action-set-library.models';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActionSetLibraryService {

  apiRoot: string = `${environment.apiV3Url}action-sets`;

  constructor(private apiService: ApiService) {}

  getAllActionSets(): Observable<IActionSet[]> {
    return this.apiService.get(this.apiRoot);
  }

  getActionSet(actionSetId: number): Observable<IActionSet> {
    return this.apiService.get(`${this.apiRoot}/${actionSetId}`);
  }

  getActionSetLibrary(): Observable<IActionSetLibraryItem[]> {
    return this.apiService.get(`${this.apiRoot}/library`);
  }

  getActionSetActionRules(actionSetId: number, actionId: number): Observable<IRuleV3[]> {
    return this.apiService.get(`${this.apiRoot}/${actionSetId}/actions/${actionId}/rules`);
  }

  getMigration(actionSetId: number): Observable<IMigrateActions> {
    return this.apiService.get(`${this.apiRoot}/${actionSetId}/migrate`);
  }

  createActionSet(actionSet: IActionSetCreationRequest): Observable<IActionSet> {
    return this.apiService.post(this.apiRoot, actionSet);
  }

  updateActionSet(actionSet: IActionSet): Observable<IActionSet> {
    return this.apiService.put(`${this.apiRoot}/${actionSet.id}`, actionSet);
  }

  updateActionSetActionRules(actionSetId: number, actionId: number, rules: number[]): Observable<IRuleV3[]> {
    return this.apiService.put(`${this.apiRoot}/${actionSetId}/actions/${actionId}/rules`, rules);
  }

  deleteActionSet(actionSetId: number): Observable<IActionSet> {
    return this.apiService.delete(`${this.apiRoot}/${actionSetId}`);
  }

  executeMigration(actionSetId: number, migration: IMigratedActions): Observable<IMigratedActions> {
    return this.apiService.post(`${this.apiRoot}/${actionSetId}/migrate`, migration);
  }
}
