import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleConditionClause, ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { ERuleMatchType, ERuleTagVariableSelectorType } from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';
import { OpModalService } from '@app/components/shared/components/op-modal/shared/op-modal.service';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActionSetLibraryService } from '@app/components/action-set-library/action-set-library.service';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { IJourneyResultsTagVariables, IJourneyResultsTag } from '@app/components/tag-report/tag-report.models';
import { RulesService } from '../rules.service';
import { ISimpleRuleCreatorPayload } from './simple-rule-creator.model';
import KeyUpEvent = JQuery.KeyUpEvent;
import { IRuleV3 } from '@app/components/rules/rules.models';
import { IRule } from '@app/components/rules/rules.models';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { Observable, of } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'simple-rule-creator',
  templateUrl: './simple-rule-creator.component.html',
  styleUrls: ['./simple-rule-creator.component.scss']
})
export class SimpleRuleCreatorComponent {
  ruleForm: UntypedFormGroup;
  rightFooterButtons = [
    {
      label: 'Add Rules',
      primary: true,
      action: () => {
        this.saveRule();
      }
    }
  ];
  submitted: boolean = false;
  displayedColumns: string[] = ['select', 'name', 'value'];
  tag: IJourneyResultsTag;
  dataSource: MatTableDataSource<IJourneyResultsTagVariables>;
  selection = new SelectionModel<IJourneyResultsTagVariables>(true, []);
  lastSelectedIndex: number = null;
  shiftKeyPressed: boolean = false;

  rule: IRule;

  @HostListener('window:keydown.shift')
  onKeyDown() {
    this.shiftKeyPressed = true;
  }

  @HostListener('window:keyup.shift')
  onKeyUp() {
    this.shiftKeyPressed = false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public payload: ISimpleRuleCreatorPayload,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SimpleRuleCreatorComponent>,
    private modalService: OpModalService,
    private rulesService: RulesService,
    private actionSetService: ActionSetLibraryService,
    private discoveryAuditService: DiscoveryAuditService,
    private snackbarService: SnackbarService,
  ) {
    const variables = payload.variables?.sort((a, b) => a.name
      .localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: 'base'
      }));

    this.tag = this.payload.tag;
    this.dataSource = new MatTableDataSource<IJourneyResultsTagVariables>(
      variables
    );

    this.initForm();
    this.initRuleModel();
  }

  private initForm() {
    this.ruleForm = this.formBuilder.group({
      name: this.formBuilder.control('', Validators.required)
    });
  }

  private initRuleModel() {
    this.rule = {
      id: null,
      accountId: null,
      name: '',
      labels: [],
      tags: [{
        id: null,
        ruleId: null,
        tagId: this.tag.tagId,
        matchType: ERuleMatchType.Equals,
        account: this.payload.tag.account,
        clause: ERuleConditionClause.Then,
        variables: []
      }],
      matchAllFilters: true,
      pageFilters: [],
      recipients: [],
      checkTimes: 1
    };
  }

  private updateRuleModel() {
    this.rule.name = this.ruleForm.get('name').value;
    this.rule.tags[0].variables = [];
    this.selection.selected.forEach(item => {
      this.rule.tags[0].variables.push({
        id: null,
        ruleTagId: this.tag.tagId,
        variable: item.name,
        value: item.value,
        matchType: ERuleMatchType.Equals,
        selectorType: ERuleTagVariableSelectorType.String
      });
    });
  }

  saveRule() {
    if (this.ruleForm.valid) {
      this.submitted = false;
      this.updateRuleModel();
      this.rulesService.createRule(this.rule).subscribe(rule => {
        const { bindTo, journeyActions, journeyId, actionId } = this.payload;
        switch (bindTo) {
          case 'WEB_JOURNEY':
            this.bindRuleToWebJourneyAction({ journeyId, actionId, rule, journeyActions }).subscribe(() => this.close());
            break;
          case 'ACTION_SET':
            this.actionSetService
              .getActionSetActionRules(this.payload.actionSet.id, this.payload.actionId)
              .subscribe((rules: IRuleV3[]) => {
                let ruleIds: number[] = rules.map((rule: IRuleV3) => rule.id);
                ruleIds.push(rule.id);

                this.actionSetService
                  .updateActionSetActionRules(this.payload.actionSet.id, this.payload.actionId, ruleIds)
                  .subscribe(() => this.close());
              });
            break;
          case 'AUDIT':
            let payloadRules = this.payload.rules?.length > 0 ? this.payload.rules : [];
            const rules = [...payloadRules, rule];
            const ruleIds = rules.map(r => r.id);

            this.discoveryAuditService.updateRulesForAudit(this.payload.journeyId, ruleIds);
            this.close();
            break;
        }
      });
    } else {
      this.submitted = true;
    }
  }

  close() {
    this.dialogRef.close();
  }

  private bindRuleToWebJourneyAction({ journeyId, actionId, rule, journeyActions }): Observable<number[]> {
    return this.rulesService.getRulesForWebJourneyAction(journeyId, actionId)
      .pipe(
        switchMap(rules => {
          rules.push(rule.id);
          return this.rulesService.bindRuleToWebJourneyAction(journeyId, rules, actionId)
        }),
        tap(() => {
          const actionNumber = journeyActions.find(a => a.id === actionId)?.sequence + 1;
          this.snackbarService.openInfoSnackbar(`Rule ${rule.name} has been added to action ${!isNaN(actionNumber) ? `#${actionNumber}` : ''}`)
        }),
        catchError(() => {
          this.snackbarService.openErrorSnackbar('Error adding rule');
          return of([]);
        }),
      )
  }

  customizeSelectedVariables() {
    this.updateRuleModel();

    this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        rule: this.rule,
        mode: ERuleSetupMode.create
      }
    }, 'rule-setup-modal').afterClosed()
      .subscribe(rule => {
        if (!rule) return;
        const { bindTo, journeyId, actionId, actionSet, journeyActions } = this.payload;
        switch (bindTo) {
          case 'WEB_JOURNEY':
            this.bindRuleToWebJourneyAction({ journeyId, actionId, rule, journeyActions }).subscribe();
            break;
          case 'ACTION_SET':
            this.actionSetService
              .getActionSetActionRules(actionSet.id, actionId)
              .subscribe(rules => {
                let ruleIds = rules.map(rule => rule.id);
                ruleIds.push(rule.id);

                this.actionSetService
                  .updateActionSetActionRules(actionSet.id, actionId, ruleIds)
                  .subscribe(() => this.close());
              });
            break;
          case 'AUDIT':
            this.discoveryAuditService
              .getAudit(journeyId)
              .then(audit => {
                const ruleIds = [...(audit.rules ?? []), rule].map(r => r.id);
                this.discoveryAuditService.updateRulesForAudit(journeyId, ruleIds);
              });
            break;
        }
      });

    this.close();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  filterVariables($event: KeyUpEvent) {
    this.lastSelectedIndex = null;

    this.dataSource.filter = $event.target.value.trim();
  }

  handleSelection(index: number, alreadySelected: boolean): void {
    !alreadySelected
      ? this.selection.select(this.dataSource.data[index])
      : this.selection.deselect(this.dataSource.data[index]);
  }

  handleCheckboxClick(item): void {
    let clickedIndex;
    let alreadySelected;
    const activeFilter = this.dataSource.filter.length > 0;
    const toggleOne = !this.shiftKeyPressed || this.lastSelectedIndex === null;

    // Handle multi-select using the filtered datasource
    if (activeFilter) {
      clickedIndex = this.dataSource.filteredData.findIndex(filteredItem => filteredItem.name === item.name);
      const clickedDatasourceIndex = this.dataSource.data.findIndex(dataItem => dataItem.name === item.name);
      alreadySelected = this.selection.isSelected(this.dataSource.data[clickedDatasourceIndex]);

      if (toggleOne) {
        this.selection.toggle(this.dataSource.data[clickedDatasourceIndex]);
      } else {
        if (this.lastSelectedIndex < clickedIndex) {
          for (let i = this.lastSelectedIndex; i <= clickedIndex; i++) {
            const datasourceIndexOfFilteredItem = this.dataSource.data.findIndex(dataItem => dataItem.name === this.dataSource.filteredData[i].name);
            this.handleSelection(datasourceIndexOfFilteredItem, alreadySelected);
          }
        } else {
          for (let i = this.lastSelectedIndex; i >= clickedIndex; i--) {
            const datasourceIndexOfFilteredItem = this.dataSource.data.findIndex(dataItem => dataItem.name === this.dataSource.filteredData[i].name);
            this.handleSelection(datasourceIndexOfFilteredItem, alreadySelected);
          }
        }
      }
    } else {
      // Handle multi-select directly from the datasource with no filter
      clickedIndex = this.dataSource.data.findIndex(dataItem => dataItem.name === item.name);
      alreadySelected = this.selection.isSelected(this.dataSource.data[clickedIndex]);

      if (toggleOne) {
        this.selection.toggle(this.dataSource.data[clickedIndex]);
      } else {
        if (this.lastSelectedIndex < clickedIndex) {
          for (let i = this.lastSelectedIndex; i <= clickedIndex; i++) {
            this.handleSelection(i, alreadySelected);
          }
        } else {
          for (let i = this.lastSelectedIndex; i >= clickedIndex; i--) {
            this.handleSelection(i, alreadySelected);
          }
        }
      }
    }

    this.lastSelectedIndex = clickedIndex;
  }
}
