import { Component, Inject, ChangeDetectorRef, OnInit  } from '@angular/core';
import { LabelService } from '@app/components/shared/services/label.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { IButton } from '@app/models/commons';
import { IConsentGroup, ICountryCodes, IConsentGroupType, ICmpVendors, ICmpCookie } from '@app/components/consent-categories/sync-onetrust-categorized-cookies-modal/sync-onetrust-categorized-cookies-modal.models';
import { ConsentCategoriesService } from '../consent-categories.service';
import { ManageCardsService } from '@app/components/manage/cards/manage-cards.service';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { countryCodes } from '@app/components/consent-categories/sync-onetrust-categorized-cookies-modal/sync-onetrust-categorized-cookies-modal.models';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { BulkActionProgressComponent } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.component';
import { ECCSortColumns, ICCLibraryQueryParams, ICCSort, ICmpData, IConsentCategory, IConsentCategoryCookie, IPaginationMetaData } from '@app/components/consent-categories/consent-categories.models';
import { AssignDatasetsModal, ImportCookieGroupNameDefault } from '../consent-categories.constants';
import { OpDeleteItemWarningComponent } from '@app/components/shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { IDialogInfo } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sync-onetrust-categorized-cookies-modal',
  templateUrl: './sync-onetrust-categorized-cookies-modal.component.html',
  styleUrls: ['./sync-onetrust-categorized-cookies-modal.component.scss']
})
export class SyncOnetrustCategorizedCookiesModalComponent implements OnInit {

  title = 'Consent Category Import/Update';
  titleIcon = 'onetrust-logo';

  TypeMessaging = {
    Inserted: "Newly Detected - Will Be Added",
    Updated: "Existing - Will Be Updated",
    Deleted: "Not Found - Will Be Deleted",
    NotChanged: "Existing - No Change",
    NoChangeCookies: "Existing - {cookieCount} Cookies Will Be Updated",
    NoAction_ExistingNotUpdated: 'Existing - WILL NOT BE Updated',
    NoAction_NewlyDetectedNotAdded: 'Newly Detected - WILL NOT BE Added',
    NoAction_NotFoundNotDeleted: 'Not Found - WILL NOT BE Deleted',
  };

  errorMessages: { title: string; message: string }[] = [
    { 
      title: 'OneTrust Detection Issue', 
      message: 'Detection is not currently available. Try again in a moment. ' 
    },
    {
      title: 'OneTrust Detection Issue', 
      message: 'Detection failed for the following reason: ' 
    },
    {
      title: 'Could not detect OneTrust on the target URL.', 
      message: 'Possible reasons: The website blocked ObservePoint\'s scan, OneTrust is not installed on the website, or the website is unavailable. '
    },
    {
      title: 'OneTrust Detection Issue', 
      message: 'Detection never completed. Try again in a moment. '
    },
  ];

  readonly MAX_COOKIE: number = 26;
  readonly MAX_DOMAIN: number = 48;
  readonly MAX_GROUP_NAME: number = 60;

  IConsentGroupType = IConsentGroupType;

  groupsReady: boolean = true;
  groupsReadyNF: boolean = true;  // Groups previously imported (e.g.; OneTrust) but not found (e.g.; NF) in the new import (will be deleted during import)
  detectingCategories: boolean = false;
  showResults: boolean = false;
  showResultsNF: boolean = false;
  importingRecords: boolean = false;
  importSuccessful: boolean = false;
  initializingImport: boolean = false;
  loadingCCs: boolean = false;
  formImportCookies: FormGroup;
  groups: IConsentGroup[];
  groupsNF: IConsentGroup[];
  showErrorMessage: boolean = false;
  errorMessageTitle: string = '';
  errorMessageBody: string = '';

  // We need to cache the url and geo in case the user changes them after detecting the categories but before importing them
  cacheGeo: ICountryCodes;
  detectDomain: string; // The domain that was detected from the api (not what the user entered)

  cookieCount: number = 0;

  countryCodes = countryCodes;
  selectedLocation: ICountryCodes;
  filteredLocations: ReplaySubject<ICountryCodes[]> = new ReplaySubject<ICountryCodes[]>(1);
  private destroy$ = new Subject<void>();

  consentCategories: IConsentCategory[] = [];

  sortOptions: ICCSort = {
    sortBy: ECCSortColumns.NAME,
    sortDesc: false,
    sortDir: 'asc'
  };
  pagination: IPaginationMetaData = {
    pageSize: 100,
    currentPageNumber: 0,
  };

  leftFooterButtons: IButton[] = [{
    label: 'CANCEL',
    action: () => this.closeModal(),
    transparent: true,
  }];

  rightFooterButtons: IButton[] = [
    {
      label: 'Sync Categorized Cookies',
      action: this.importCookies.bind(this),
      disabled: true,
      primary: true,
      hidden: false
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<SyncOnetrustCategorizedCookiesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
    private labelService: LabelService,
    private modalService: OpModalService,
    private ccService: ConsentCategoriesService,
    public mCardSvc: ManageCardsService,
    private cdRef: ChangeDetectorRef,
    private snackBar: MatSnackBar,
  ) {}

  async ngOnInit() {
    this.initLocation();
    this.intControls();
    this.initListeners();

    this.loadConsentCategories();

    setTimeout(() => {
      const californiaLocation = this.countryCodes.find(location => location.localeName === 'USA, California');
      this.formImportCookies.controls['ctlLocation'].setValue(californiaLocation.localeName);
      this.toggleImportButtons();
    }, 10);
  }

  ngOnDestroy(): void {
    this.loadingCCs = false;
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initListeners(): void {

    this.ctlLocation.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(loc => {
        this.selectedLocation = this.countryCodes.find(location => location.localeName === loc);
      });

    // listen for search field value changes
    this.ctlLocationFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterLocations();
      });
  }

  protected filterLocations() {
    if (!this.countryCodes) {
      return;
    }
    // get the search keyword
    let search = this.ctlLocationFilter.value;
    if (!search) {
      this.filteredLocations.next(this.countryCodes.slice());
      return;
    } else {
      search = search?.toLowerCase();
    }

    this.filteredLocations.next(
      this.sortLocations(this.countryCodes.filter(loc => {
        const match = loc.localeName?.toLowerCase().indexOf(search) > -1;
        const currentlySelected = loc.localeName?.toLowerCase() === this.ctlLocation.value?.toLowerCase();
        return match || currentlySelected;
      }))
    );
  }

  // Filter and sort the locations with US at the top of list
  sortLocations(locations: ICountryCodes[]): ICountryCodes[] {
    return locations.sort((a, b) => {
      // Sort 'us' countryCode first
      if (a.localeCode.slice(0, 2) === 'us' && b.localeCode.slice(0, 2) !== 'us') {
        return -1;
      } else if (a.localeCode.slice(0, 2) !== 'us' && b.localeCode.slice(0, 2) === 'us') {
        return 1;
      } else {
        // Sort alphabetically by name
        return a.localeName.localeCompare(b.localeName);
      }
    });
  }

  initLocation() {
    // Sort locations alphabetically with US at the top
    this.countryCodes = this.sortLocations(this.countryCodes);
    this.filteredLocations.next(this.countryCodes.slice());
  }
  private intControls() {
    this.formImportCookies = this.fb.group({
      ctlUrl: [''],
      ctlLocation: [''],
      ctlLocationFilter: [''],
      ctlGroups: this.fb.array([]),
      ctlGroupsNF: this.fb.array([]),
    });
  }
  onEnterKeyPressed(event: KeyboardEvent): void {
    if ((event.key === 'Enter') && (this.urlValidator(this.ctlUrl.value))) {
      this.detectNewCCsToImport();
    }
  }
  urlValidator(value: any): boolean {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    if (!urlPattern.test(value)) {
      this.showSnack("Please enter a valid domain");
      return false;
    } else {
      return true;
    }
  }
  getCookieName(cookie: ICmpCookie): string {
    // Get the max length possible (one might be longer than the other but both are less than the max)
    if(cookie?.name?.length + cookie?.domain?.length > this.MAX_DOMAIN + this.MAX_COOKIE) {
      return cookie?.name?.slice(0, this.MAX_COOKIE) + '...';
    } else {
      return cookie?.name || '';
    }
  }
  getCookieDomain(cookie: ICmpCookie): string {
    // Get the max length possible (one might be longer than the other but both are less than the max)
    if(cookie?.name?.length + cookie?.domain?.length > this.MAX_DOMAIN + this.MAX_COOKIE) {
      return cookie?.domain?.slice(0, this.MAX_DOMAIN) + '...';
    } else {
      return cookie?.domain || '';
    }
  }

  someSelected(): boolean {
    const atLeastOneSelected = this.groups?.some(group => group.selected);
    const atLeastOneNotSelected = this.groups?.some(group => !group.selected);

    return atLeastOneSelected && atLeastOneNotSelected;
  }
  someSelectedNF(): boolean {
    const atLeastOneSelected = this.groupsNF?.some(group => group.selected);
    const atLeastOneNotSelected = this.groupsNF?.some(group => !group.selected);

    return atLeastOneSelected && atLeastOneNotSelected;
  }

  toggleImportButtons() {
    if(this.isAtleastOneGroupSelected()) {
      this.rightFooterButtons[0].disabled = false;
    } else {
      this.rightFooterButtons[0].disabled = true;
    }
  }
  isAtleastOneGroupSelected(): boolean {
    if(!this.groups && !this.groupsNF) return false;
    return this.groups?.some(group => group.selected) || this.groupsNF?.some(group => group.selected);
  }

  ////////////////////////////////////////////////
  // Detect/find OneTrust Cookies (before import)
  async detectNewCCsToImport() {

    if(!this.ctlUrl.value || !this.ctlLocation.value) {
      this.showSnack("Please enter a domain and select a location");
      return;
    }

    this.detectingCategories = true;
    this.showResults = false;
    this.showResultsNF = false;
    this.showErrorMessage = false;
    this.groupsReady = false;
    this.groupsReadyNF = false;
    this.detectDomain = '';
    this.groupsNF = [];
    this.ctlUrl.disable();
    this.cdRef.detectChanges();
    let unnamedGroupCount: number = 1;
    this.formImportCookies.controls.ctlGroups = this.fb.array([]); // required if user clicks on 'Detect Categories' again with a different domain.

    // Detect OneTrust consent categories based only on the current domain and location selected.
    this.cacheGeo = this.selectedLocation;
    const geo = this.cacheGeo.localeCode?.trim();
    let detectedGroups = await this.mCardSvc.detectCategories(this.ctlUrl.value?.trim(), geo);
    detectedGroups = this.checkDetectedGroupsForError(detectedGroups);

    this.groups = await Promise.all(detectedGroups.map(async group => {
      this.cookieCount = 0;
      const groupId = group.groupId?.toLocaleLowerCase()?.trim();
      if(!this.detectDomain) this.detectDomain = group?.domain?.trim();
      const cmpId = groupId + this.detectDomain + geo;
      group.cmpId = cmpId;
      const groupName = (group.groupName) ? group.groupName?.trim() : ImportCookieGroupNameDefault + " " + unnamedGroupCount++;
      group.groupName = groupName;
      
      const deleteCookies = await this.findCookesToDelete(group);
      const cookies = [
        ...await Promise.all(group.cookies.map(async cookie => {
          return {
            ...cookie,
            groupId: groupId,
            selected: true,
            updateType: await this.determineType_CookieStatus(cookie, cmpId),
          };
        })),
        ...deleteCookies,
      ].sort((a, b) => a.name.localeCompare(b.name));

      group.cookies = cookies;

      return {
        ...group,
        name: group.groupName,
        domain: group.domain,
        geo: geo,
        cmpId: cmpId,
        vendor: ICmpVendors.OneTrust,
        updateType: await this.determineType_GroupStatus(group, cmpId),
        selected: true,
        expanded: false,
        cookieChangeCount: this.cookieCount,
      };
    }));
    
    this.groups = this.groups
      .filter(group => group.cookies.length > 0)
      .sort((a, b) => a.groupName?.localeCompare(b.groupName));

    // Add any CCs that need to be deleted
    const deleteGroups = this.findCCsToDelete();
    if (deleteGroups && deleteGroups.length > 0) {
      this.groupsNF = deleteGroups;
    }

    // Continue waiting for the consent categories to load if they haven't already. Give up if it takes too long.
    if(!(await this.checkIfCCsLoaded())) return;

    this.updateUIControls();

    setTimeout(() => {
      this.initializingImport = false;
      this.groupsReady = true;
      if(this.groups.length !== 0) {
        this.showResults = true;
      }

      if(this.groupsNF && this.groupsNF?.length !== 0) {
        this.groupsReadyNF = true;
        this.showResultsNF = true;
      }

      this.ctlUrl.enable();
      this.toggleImportButtons();
      this.cdRef.detectChanges();
    }, 0);
  }

  checkDetectedGroupsForError(detectedGroups: IConsentGroup[]): IConsentGroup[] {
    if(detectedGroups && detectedGroups.length === 1 && detectedGroups[0].status?.toLocaleLowerCase() === 'error') {
      const error = (detectedGroups[0].groupName) ? detectedGroups[0].groupName?.trim() : '';
      this.errorMessageTitle = this.errorMessages[detectedGroups[0].groupId].title;
      this.errorMessageBody = this.errorMessages[detectedGroups[0].groupId].message + error;
      this.loadingCCs = false;
      this.showErrorMessage = true;
      return [];
    }

    return detectedGroups;
  }

  async checkIfCCsLoaded(): Promise<boolean> {

    //wait here for the consent categories to load for a maximum of 20 seconds
    if(this.loadingCCs) {
      this.groupsReady = true;
      this.initializingImport = true;

      let count = 0;
      while(this.loadingCCs && count < 200) {
        await new Promise(resolve => setTimeout(resolve, 100));
        count++;
      }

      if(this.loadingCCs) {
        this.showSnack("There was a problem loading Consent Categories. Please close this modal and try again in a few moments.", 10000);
        this.detectingCategories = false;
        this.groupsReady = true;
        this.initializingImport = false;
        this.showResults = false;
        this.ctlUrl.enable();
        this.cdRef.detectChanges();
        return false;
      }
    }

    return true;
  }

  // Search for matches in the consent categories from CMP data field
  // * UPDATE: If BOTH import and CC are found
  // * DELETE: Else If CC exists but not found in the import [NOTE: done after the fact]
  // * INSERT: Else you can't find the import in existing CCs
  async determineType_GroupStatus(group: IConsentGroup, cmpId: string): Promise<IConsentGroupType> {
    const cc = this.consentCategories.find(cc => cmpId === cc.cmpId);
    if(cc) {
      return await (this.mCardSvc.getCmpGroupName(group.groupName?.trim(), [group.domain], [this.cacheGeo]) === cc.name?.trim()) 
        ? await this.determineType_GroupStatus_NoChange(group)
        : IConsentGroupType.Update;
    } else {
      return IConsentGroupType.Insert;
    }
  }
  async determineType_GroupStatus_NoChange(group: IConsentGroup) : Promise<IConsentGroupType> {
    const groupCookiesWhichChanged = group.cookies.filter(cookie => {
      return (cookie.updateType !== IConsentGroupType.NoChange);
    });

    if(groupCookiesWhichChanged && groupCookiesWhichChanged?.length > 0) {
      this.cookieCount = groupCookiesWhichChanged.length;
      return IConsentGroupType.NoChangeCookies;
    } else {
      return IConsentGroupType.NoChange;
    }
  }
  getNoChangeCookieMessage(group: IConsentGroup): string {
    const count = group.cookies.filter(cookie => {
      return (cookie.updateType !== IConsentGroupType.NoChange);
    });
    const message = this.TypeMessaging.NoChangeCookies;
    return message.replace("{cookieCount}", count?.length?.toString() || "0");
  }
  // If new CC (group) then cookies are all new
  async determineType_CookieStatus(groupCookie: ICmpCookie, cmpId: string): Promise<IConsentGroupType> {
    const ccCookies = this.consentCategories.find(cc => cmpId === cc.cmpId)?.cookies;
    if(ccCookies) {
      const ccCookie = ccCookies?.find(ck => 
        ck?.name?.toLocaleLowerCase() === groupCookie.name?.toLocaleLowerCase() 
        && ck?.domain?.trim() === groupCookie.regexDomain?.trim());
      return (ccCookie) 
        ? this.cookieUpdateType(ccCookie, groupCookie) 
          // If the user is re-selecting the group checkbox then put back the original status
        : (groupCookie.updateType === IConsentGroupType.NoAction_NotFoundNotDeleted) 
          ? IConsentGroupType.Delete 
          : IConsentGroupType.Insert;
    } else {
      return IConsentGroupType.Insert;
    }
  }
  cookieUpdateType(ccCookie: IConsentCategoryCookie, groupCookie: ICmpCookie): IConsentGroupType {
    if( groupCookie.updateType === IConsentGroupType.NoAction_NotFoundNotDeleted ) {
      return IConsentGroupType.Delete;
    } else if(ccCookie.domain === groupCookie.regexDomain && ccCookie.name === groupCookie.name) {
      return IConsentGroupType.NoChange;
    } else {
      return IConsentGroupType.Update;
    }
  }
  updateUIControls() {

    // Add to UI controls (top section)
    this.groups?.forEach((group, index) => {
      const groupCookies = group.cookies.map(cookie => this.fb.group({
        name: cookie.name,
        groupId: group.groupId,
      }));

      const groupForm = this.fb.group({
        groupId: group.groupId,
        expanded: group.expanded,
        selected: group.selected,
        ctlCookies: this.fb.array(groupCookies)
      });
      this.ctlGroups.push(groupForm);
    });

    // Add to UI controls (bottom section)
    this.groupsNF?.forEach((groupNF, index) => {
      const groupCookiesNF = groupNF.cookies.map(cookie => this.fb.group({
        name: cookie.name,
        groupId: groupNF.groupId,
      }));

      const groupFormNF = this.fb.group({
        groupId: groupNF.groupId,
        expanded: groupNF.expanded,
        selectedNF: groupNF.selected,
        ctlCookiesNF: this.fb.array(groupCookiesNF)
      });
      this.ctlGroupsNF.push(groupFormNF);
    });

    this.detectingCategories = false;
  }

  findCCsToDelete(): IConsentGroup[] {

    const ccToDelete: IConsentGroup[] = this.consentCategories
      .filter(cc => cc.cmpId)
      .filter(cc => this.groups.find(group => group.domain === cc.cmpData?.oneTrustCookieGroupDomain && group.geo === cc.cmpData?.oneTrustCookieGroupGeo))
      .filter(cc => !this.groups.find(group => group.cmpId === cc.cmpId))
      .map(cc => ({
        id: cc.id,
        groupId: cc.cmpData.oneTrustCookieGroupId,
        cmpId: cc.cmpId,
        vendor: ICmpVendors.OneTrust,
        domain: cc.cmpData?.oneTrustCookieGroupDomain ?? '',
        geo: cc.cmpData?.oneTrustCookieGroupGeo ?? '',
        updateType: IConsentGroupType.Delete,
        selected: true,
        expanded: false,
        status: "",
        groupName: cc.name,
        cookies: this.convertCookiesToCmpCookies(cc.cookies),
    })) || [];

    return ccToDelete;
  }
  async findCookesToDelete(group: IConsentGroup): Promise<ICmpCookie[]> {
    const groupCookies = group.cookies;
    const ccCookies = this.consentCategories.find(cc => (cc.cmpId === group.cmpId))?.cookies

    // Find ccCookies that are not found in groupCookies
    const cookiesToDelete: ICmpCookie[] = ccCookies?.filter(ccCookie => 
      !groupCookies.some(groupCookie => groupCookie.name?.toLocaleLowerCase() === ccCookie.name?.toLocaleLowerCase())
    ).map(ccCookie => ({
      domain: ccCookie.domain,
      name: ccCookie.name,
      nameIsRegex: ccCookie?.nameRegex ?? false,
      description: ccCookie.description,
      groupId: group.groupId,
      updateType: IConsentGroupType.Delete
    })) || [];

    cookiesToDelete.forEach(cookie => {
      cookie.groupId = group.groupId;
      cookie.updateType = IConsentGroupType.Delete;
    });

    return cookiesToDelete;
  }
  convertCookiesToCmpCookies(ccCookies: IConsentCategoryCookie[]): ICmpCookie[] {
    return ccCookies.map(ccCookie => ({
      domain: ccCookie.domain,
      name: ccCookie.name,
      nameIsRegex: ccCookie?.nameRegex ?? false,
      description: ccCookie.description,
      groupId: "",
      updateType: IConsentGroupType.Delete,
    }));
  }

  async loadConsentCategories(): Promise<void> {
    this.loadingCCs = true;
    const allConsentCategories = [];
    this.pagination.currentPageNumber = 0;
    this.pagination.totalPageCount = 0;

    do {
      try {
        const response = await this.ccService.getConsentCategoriesLibrary(this.getCCLibraryOptions()).toPromise();
        const { metadata: { pagination }, consentCategories } = response;

        const consentCategoriesWithCookies = await Promise.all(consentCategories.map(async cc => {
          cc.cmpData = {
            cmpVendor: cc.cmpData?.cmpVendor ?? ICmpVendors.Unknown,
            oneTrustCookieGroupDomain: cc.cmpData?.oneTrustCookieGroupDomain?.toLocaleLowerCase()?.trim() ?? "",
            oneTrustCookieGroupGeo: cc.cmpData?.oneTrustCookieGroupGeo?.toLocaleLowerCase()?.trim() ?? "",
            oneTrustCookieGroupId: cc.cmpData?.oneTrustCookieGroupId ?? "",
          } as ICmpData;
          cc.cmpId = ((cc.cmpData?.oneTrustCookieGroupId ?? "")?.trim()?.toLocaleLowerCase() + (cc.cmpData?.oneTrustCookieGroupDomain ?? "")?.trim()?.toLocaleLowerCase() + (cc.cmpData?.oneTrustCookieGroupGeo ?? "")?.trim()?.toLocaleLowerCase());

          const cookies = await this.ccService.getConsentCategoryCookies(cc.id).toPromise();
          cc.cookies = cookies.cookies;
          cc.name = cc.name?.trim();
          return cc;
        }));

        allConsentCategories.push(...consentCategoriesWithCookies);
        this.pagination.totalCount = pagination.totalPageCount;
        this.pagination.pageSize = pagination.pageSize;
        this.pagination.currentPageNumber++;
        this.pagination.totalPageCount = pagination.totalPageCount;
      } catch (error) {
        console.log(error);
        this.snackBar.open('There was a problem loading Consent Categories. Please try again in a few moments.', '', { duration: 5000 });
        return;
      }
    } while (this.pagination.currentPageNumber < this.pagination.totalPageCount && this.loadingCCs);

    this.consentCategories = allConsentCategories;
    this.loadingCCs = false;
  }
  getCCLibraryOptions(): ICCLibraryQueryParams {
    const { sortBy, sortDesc } = this.sortOptions;
    const page = this.pagination.currentPageNumber;
    const { pageSize } = this.pagination;
    const cmpVendor = ICmpVendors.OneTrust.toString();
    let name, type, labels;

    return { page, pageSize, name, type, labels, sortBy, sortDesc, cmpVendor };
  }

  toggleGroupExpanded(group: IConsentGroup) {
    group.expanded = !group.expanded;
    this.cdRef.detectChanges();
  }
  triggerChangeDetection() {
    this.cdRef.detectChanges();
  }
  async groupSelectedChanged(group: IConsentGroup) {
    group.selected = !group.selected;
    if(!group.selected) {
      if( group.updateType === IConsentGroupType.Insert) group.updateType = IConsentGroupType.NoAction_NewlyDetectedNotAdded;
      else if( group.updateType === IConsentGroupType.Delete) group.updateType = IConsentGroupType.NoAction_NotFoundNotDeleted;
      else if( group.updateType === IConsentGroupType.Update) group.updateType = IConsentGroupType.NoAction_ExistingNotUpdated;
    } else {
      group.updateType = await this.determineType_GroupStatus(group, group.cmpId);
    }

    group.cookies.map(cookie => {
      this.cookiesGroupSelectedChanged(group, cookie);
    });
  }
  async cookiesGroupSelectedChanged(group: IConsentGroup, cookie: ICmpCookie) {
    if(!group.selected) {
      if( cookie.updateType === IConsentGroupType.Insert) cookie.updateType = IConsentGroupType.NoAction_NewlyDetectedNotAdded;
      else if( cookie.updateType === IConsentGroupType.Delete) cookie.updateType = IConsentGroupType.NoAction_NotFoundNotDeleted;
      else if( cookie.updateType === IConsentGroupType.Update) cookie.updateType = IConsentGroupType.NoAction_ExistingNotUpdated;
    } else {
      cookie.updateType = await this.determineType_CookieStatus(cookie, group.cmpId);
    }
  }
  async groupSelectedChangedNF(groupNF: IConsentGroup) {
        groupNF.selected = !groupNF.selected;
    if(!groupNF.selected) {
      if( groupNF.updateType === IConsentGroupType.Insert) groupNF.updateType = IConsentGroupType.NoAction_NewlyDetectedNotAdded;
      else if( groupNF.updateType === IConsentGroupType.Delete) groupNF.updateType = IConsentGroupType.NoAction_NotFoundNotDeleted;
      else if( groupNF.updateType === IConsentGroupType.Update) groupNF.updateType = IConsentGroupType.NoAction_ExistingNotUpdated;
    } else {
      groupNF.updateType = await this.determineType_GroupStatus(groupNF, groupNF.cmpId);
    }

    groupNF.cookies.map(cookie => {
      this.cookiesGroupSelectedChanged(groupNF, cookie);
    });
  }

  get ctlUrl(): FormArray {
    return this.formImportCookies.get('ctlUrl') as FormArray;
  }
  get ctlLocation(): FormArray {
    return this.formImportCookies.get('ctlLocation') as FormArray;
  }
  get ctlLocationFilter(): FormArray {
    return this.formImportCookies.get('ctlLocationFilter') as FormArray;
  }
  get ctlGroups(): FormArray {
    return this.formImportCookies.get('ctlGroups') as FormArray;
  }
  set ctlGroups(value: FormArray) {
    this.ctlGroups = value;
  }
  get ctlGroupsNF(): FormArray {
    return this.formImportCookies.get('ctlGroupsNF') as FormArray;
  }
  get ctlCookies(): FormArray {
    return this.formImportCookies.get('ctlCookies') as FormArray;
  }
  get ctlCookiesNF(): FormArray {
    return this.formImportCookies.get('ctlCookiesNF') as FormArray;
  }

  closeModal(counts?: { ccCount: number, itemCount: number }) {
    this.loadingCCs = false;
    if(counts) this.dialogRef.close(counts);
    if(this.importSuccessful) this.dialogRef.close(true);
    else this.dialogRef.close();
  }

  async importCookiesAndClose() {
    await this.importCookies();
    this.closeModal();
  }

  async importCookies(): Promise<void> {

    if(!this.consentCategories && this.consentCategories?.length < 1) {
      this.snackBar.open('Consent Categories we not loaded. Please refresh your page and try again.', '', { duration: 5000 });
      return;
    }
    if(!await this.deleteConfirmationModal()) {
      return;
    }

    // Disable screen while importing data
    this.showResults = false;
    this.showResultsNF = false;
    this.groupsReady = false;
    this.groupsReadyNF = false;
    this.importingRecords = true;
    this.ctlUrl.disable();
    this.ctlLocation.disable();
    this.rightFooterButtons[0].disabled = true;
    this.cdRef.detectChanges();

    // Import selected coolies
    await this.bulkImportConsentCategories();

    // Reload the Consent Categories
    this.consentCategories = [];
    this.loadConsentCategories();

    // Enable the screen upon completion
    this.groupsNF = [];
    const ctlGroups = this.formImportCookies.get('ctlGroups') as FormArray;
    ctlGroups.clear();
    const ctlGroupsNF = this.formImportCookies.get('ctlGroupsNF') as FormArray;
    ctlGroupsNF.clear();
    this.groupsReady = true;
    this.groupsReadyNF = true;
    this.importingRecords = false;
    this.ctlUrl.enable();
    this.ctlLocation.enable();
  }

  /////////////////////////////////
  // Bulk Import OneTrust Cookies
  async bulkImportConsentCategories(): Promise<void> {
    const selectedGroups: IConsentGroup[] = this.groups
      .filter(group => group.selected)
      .map(group => ({...group, cookies: group.cookies}));
    if(this.groupsNF) {
      const selectedGroupsNF: IConsentGroup[] = this.groupsNF
        .filter(groupNF => groupNF.selected)
        .map(groupNF => ({...groupNF, cookies: groupNF.cookies}));
      selectedGroups.push(...selectedGroupsNF);
    }
    selectedGroups.map(group => 
      group.cookies.map(cookie => {
        if (cookie.regexDomain) {
          cookie.domain = cookie.regexDomain;
        }
      })
    );

    this.showProgressDlg('Synchronizing cookies', 'Cookies are now synchronized', selectedGroups, false, '', selectedGroups.length);
    await this.mCardSvc.importAllCCs(selectedGroups, [this.detectDomain], [this.cacheGeo], this.consentCategories);
    this.importSuccessful = true;

    this.detectDomain = '';
  }

  async deleteConfirmationModal(): Promise<boolean> {

    // See if there are any groups to delete (that are selected by the user). If so, prompt the user to confirm deletion.
    const selectedGroupsNF: IConsentGroup[] = this.groupsNF?.filter(groupNF => groupNF?.selected);
    if(!selectedGroupsNF || selectedGroupsNF?.length === 0) {
      return true;
    }

    const confirmDelete = await new Promise<boolean>((resolve) => {
      this.modalService.openModal(OpDeleteItemWarningComponent, {
        data: {
          itemType: 'Consent Category',
          names: selectedGroupsNF.map(ccNF => ccNF.groupName),
        }
      })
      .afterClosed()
      .subscribe((confirmDelete: boolean) => {
        resolve(confirmDelete);
      });
    });

    return confirmDelete;
  }

  async showProgressDlg(preMessage: string, postMessage: string, importCCs: IConsentGroup[], showFinalMessage: boolean = true, finalMessage?: string, maxCountOverride?: number) {

    let processCompleted = false;
    let formattedMessage2: string =
      'Do not close this banner, or leave this page until finished or remaining data sources will not be completed.';
    const selectedInsertGroups: IConsentGroup[] = importCCs?.filter(group => group?.updateType === IConsentGroupType.Insert);
    const confirmConfig = {
      maxCount: maxCountOverride,
      messages: [
        preMessage,
        formattedMessage2,
        finalMessage,
        postMessage,
      ],
      showFinalMessage: showFinalMessage,
      showSecondBtn: false,
      showProgressBar: true,
      rightFooterButtons: [
        {
          label: 'Yes',
          opSelector: 'bulk-confirm-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-cancel',
          hidden: false,
          primary: false,
        },
      ],
    } as IDialogInfo;

    if (selectedInsertGroups && selectedInsertGroups.length > 0) {
      confirmConfig.records = selectedInsertGroups;
      confirmConfig.labels = [];
    }

    const snackBarRef = this.snackBar.openFromComponent(BulkActionProgressComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });

    snackBarRef.afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        processCompleted = true;

        if (observer.dismissedByAction) {
          this.dialogRef.close({ func: AssignDatasetsModal, labels: [], newImports: selectedInsertGroups });
        }
      });

    return true;
  }

  showSnack(message: string, duration: number = 5000) {
    this.snackBar.open(message, '', {
      duration: duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  enableImportButtons(enable: boolean) {
    if (enable) {
      this.rightFooterButtons[0].disabled = false;
    } else {
      this.rightFooterButtons[0].disabled = true;
    }
  }
}
