export class NumberFormatter {

  formatWithComma(value: number): string {
    if (!value) {
      return '0';
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  convertLoadTime(milliseconds: number, skipFractionDigits?: boolean): string {
    const fractionDigits = skipFractionDigits ? 0 : 2;

    if (0 <= milliseconds && milliseconds < 1000) {
      return milliseconds + ' ms';
    } else if (1000 <= milliseconds && milliseconds < 60000) {
      let seconds = (milliseconds / 1000).toFixed(fractionDigits);
      return parseFloat(seconds) + ' sec';
    } else if (60000 <= milliseconds) {
      let minutes = (milliseconds / (1000 * 60)).toFixed(fractionDigits);
      return parseFloat(minutes) + ' min';
    }
    return milliseconds + ' ms';
  }

  bytesToSize(bytes: number, decimals?: number): string {
    var sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
    var posttxt = 0;
    if (bytes == 0) return 'n/a';
    if (bytes < 1024) {
      return Number(bytes) + ' ' + sizes[posttxt];
    }
    while (bytes >= 1024) {
      posttxt++;
      bytes = bytes / 1024;
    }
    if (decimals) {
      return bytes.toFixed(decimals) + ' ' + sizes[posttxt];
    } else {
      return Math.round(bytes) + ' ' + sizes[posttxt];
    }
  }

  shortenLargeNumber(num: number, digits: number): string {
    var units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
      decimal;

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1);

      if (num <= -decimal || (num >= decimal && num.toString().length >= 5)) {
        return +(num / decimal).toFixed(digits) + units[i];
      }
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  
}
