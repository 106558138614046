import { Route } from '@angular/router';
import { TagDatabaseComponent } from './tag-database.component';
import { TagDatabasePaths, TagDatabaseStateNames } from './tag-database.constants';

export const TagDatabaseFutureState: Route = {
  path: TagDatabasePaths.base,
  loadChildren: () => import('./tag-database.module').then(mod => mod.TagDatabaseModule),
};

export const TagDatabaseRoutes: Route = {
  path: '',
  component: TagDatabaseComponent,
  data: {
    stateName: TagDatabaseStateNames.base
  }
};
