<component-template componentName="Label Manager">
  <component-library-section sectionName="Empty Example" selector="<op-label-manager>">
    <p>This is the label manager component. It is a wrapper around the chip manager that handles label specific tasks.</p>

    <div class="label-manager-example">
      <op-label-manager [selectedLabels]="example1selectedLabels"
                        (onLabelCreated)="handleLabelCreated($event, example1selectedLabels)"
                        (onLabelSelected)="handleLabelSelected($event, example1selectedLabels)"
                        (onLabelRemoved)="handleLabelRemoved($event, example1selectedLabels)"
      ></op-label-manager>
    </div>
  </component-library-section>

  <component-library-section sectionName="Filled Example" selector="<op-label-manager>">
    <div class="label-manager-example">
      <op-label-manager [selectedLabels]="example2selectedLabels"
                        (onLabelCreated)="handleLabelCreated($event, example2selectedLabels)"
                        (onLabelSelected)="handleLabelSelected($event, example2selectedLabels)"
                        (onLabelRemoved)="handleLabelRemoved($event, example2selectedLabels)"
      ></op-label-manager>
    </div>
  </component-library-section>

  <component-library-section sectionName="Compact Empty Example" selector='<op-label-manager [compactView]="true">'>
    <div class="label-manager-example">
      <op-label-manager [selectedLabels]="example3selectedLabels"
                        [compactView]="true"
                        (onLabelCreated)="handleLabelCreated($event, example3selectedLabels)"
                        (onLabelSelected)="handleLabelSelected($event, example3selectedLabels)"
                        (onLabelRemoved)="handleLabelRemoved($event, example3selectedLabels)"
      ></op-label-manager>
    </div>
  </component-library-section>

  <component-library-section sectionName="Compact Filled Example" selector='<op-label-manager [compactView]="true">'>
    <div class="label-manager-example">
      <op-label-manager [selectedLabels]="example4selectedLabels"
                        [compactView]="true"
                        (onLabelCreated)="handleLabelCreated($event, example4selectedLabels)"
                        (onLabelSelected)="handleLabelSelected($event, example4selectedLabels)"
                        (onLabelRemoved)="handleLabelRemoved($event, example4selectedLabels)"
      ></op-label-manager>
    </div>
  </component-library-section>

  <component-library-section sectionName="Read-only Example" selector='<op-label-manager [compactView]="true" [isReadOnly]="true">'>
    <div class="label-manager-example">
      <op-label-manager [selectedLabels]="example5selectedLabels"
                        [compactView]="true"
                        [isReadOnly]="true"
                        (onLabelCreated)="handleLabelCreated($event, example5selectedLabels)"
                        (onLabelSelected)="handleLabelSelected($event, example5selectedLabels)"
                        (onLabelRemoved)="handleLabelRemoved($event, example5selectedLabels)"
      ></op-label-manager>
    </div>
  </component-library-section>

  <component-library-section sectionName="Label Count Only Example" selector='<op-label-manager [compactView]="true" [isReadOnly]="true" [chipCountOnly]="true">'>
    <div class="label-manager-example">
      <op-label-manager [selectedLabels]="example5selectedLabels"
                        [compactView]="true"
                        [isReadOnly]="true"
                        [chipCountOnly]="true"
                        (onLabelCreated)="handleLabelCreated($event, example5selectedLabels)"
                        (onLabelSelected)="handleLabelSelected($event, example5selectedLabels)"
                        (onLabelRemoved)="handleLabelRemoved($event, example5selectedLabels)"
      ></op-label-manager>
    </div>
  </component-library-section>
</component-template>

<!--

<div class="label-manager-example">
  Read-only example:
  <op-label-manager [selectedLabels]="example5selectedLabels"
                    [compactView]="true"
                    [isReadOnly]="true"
                    (onLabelCreated)="handleLabelCreated($event, example2selectedLabels)"
                    (onLabelSelected)="handleLabelSelected($event, example2selectedLabels)"
                    (onLabelRemoved)="handleLabelRemoved($event, example2selectedLabels)"
  ></op-label-manager>
</div>

<div class="label-manager-example">
  Label count only example:
  <op-label-manager [selectedLabels]="example5selectedLabels"
                    [compactView]="true"
                    [isReadOnly]="true"
                    [chipCountOnly]="true"
                    (onLabelCreated)="handleLabelCreated($event, example2selectedLabels)"
                    (onLabelSelected)="handleLabelSelected($event, example2selectedLabels)"
                    (onLabelRemoved)="handleLabelRemoved($event, example2selectedLabels)"
  ></op-label-manager>
</div> -->