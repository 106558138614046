import { Component, Inject } from '@angular/core';
import { IButton } from '@app/models/commons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { IResendExportEmailRequest } from '../../export-center.models';
import { OPValidators } from '@app/components/shared/validators/op-validators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'send-report-modal',
  templateUrl: './send-report-modal.component.html',
  styleUrls: ['./send-report-modal.component.scss']
})

export class SendReportModalComponent {
  readonly MAX_EMAILS_LENGTH = 10;
  readonly MAX_MESSAGE_LENGTH = 1000;

  emailsInput = '';
  emails: string[] = [];
  message = '';
  redEmailsHint = '';
  redMessagesHint = '';

  separatorKeysCodes = [COMMA, ENTER];
  destroy$ = new Subject<void>();
  rightFooterButtons: IButton[] = [{
    label: 'SEND',
    action: () => this.closeModal(true),
    primary: true,
    disabled: true
  }];

  constructor(private dialogRef: MatDialogRef<SendReportModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { title: string }) {
  }

  closeModal(send = false) {
    const request: IResendExportEmailRequest = {
      toAddresses: this.emails,
      customEmailMessage: this.message || undefined
    };
    this.dialogRef.close(send && request);
  }

  remove(email: string) {
    const idx = this.emails.findIndex(e => e === email);

    if (idx !== -1) {
      this.emails.splice(idx, 1);

      this.validEmail('');

      this.isReadyToBeSent();
    }
  }

  removeAll() {
    this.emails = [];
    this.isReadyToBeSent();
  }

  isReadyToBeSent() {
    this.rightFooterButtons[0].disabled = (this.emails.length === 0 || this.emails.length > this.MAX_EMAILS_LENGTH)
      || (this.message.length > this.MAX_MESSAGE_LENGTH);
  }

  onKeyUp(ev: KeyboardEvent) {
    const inputValue = (ev.target as HTMLInputElement).value;
    if (this.separatorKeysCodes.includes(ev.keyCode)) {
      this.parseBuffer(inputValue);
    } else {
      const emails = inputValue.split(',');
      this.validEmail(emails[0].trim());
    }
  }
  
  onBlur(ev: FocusEvent) {
    const inputValue = (ev.target as HTMLInputElement).value;
    this.parseBuffer(inputValue);
  }

  onPaste(ev: ClipboardEvent) {
    const inputValue = ev.clipboardData.getData('text');
    this.parseBuffer(inputValue);
  }

  private parseBuffer(inputValue: string) {
    /* 
      We should update Chip's input in the next digest loop. Otherwise, it isn't updated correctly.
      For examle, input isn't cleared when pasting emails.
    */
    setTimeout(() => {
      const emails = inputValue.split(',');

      for (let i = 0; i < emails.length; i++) {
        const email = emails[i].trim();
        if (this.validEmail(email)) {
          this.emails.push(email);
          this.emailsInput = '';
        } else {
          this.emailsInput = emails.splice(i).join(',');
          break;
        }
      }
  
      this.isReadyToBeSent();
    });
  }

  validateMessage() {
    if (this.message.length > this.MAX_MESSAGE_LENGTH) {
      this.redMessagesHint = 'Maximum message length is 1,000 characters';
    }
    this.isReadyToBeSent();
  }

  private validEmail(email: string): boolean {
    if (email) {
      if (!OPValidators.isInvalidEmail(email)) {
        const idx = this.emails.findIndex(e => e === email);

        if (idx === -1) {
          this.redEmailsHint = '';

          if (this.emails.length > this.MAX_EMAILS_LENGTH) {
            this.redEmailsHint = 'Maximum number of emails is ' + this.MAX_EMAILS_LENGTH;
          }

          return true;
        } else {
          this.redEmailsHint = 'This email is already in use';
        }
      } else {
        this.redEmailsHint = 'Invalid email address';
      }
    } else {
      this.redEmailsHint = '';
      if (this.emails.length > this.MAX_EMAILS_LENGTH) {
        this.redEmailsHint = 'Maximum number of emails is ' + this.MAX_EMAILS_LENGTH;
      }
    }

    return false;
  }
}
