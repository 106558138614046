import {Names} from '@app/moonbeamConstants';
import {IModalService} from '../../../../../modals/modalService';
import {ModalTemplates} from '../../../../../modals/modalTemplates';
import {IConfirmationModalData} from '../../../../../modals/modalData';


export class ExportToEmailModalService{

    static $inject = [
      Names.Services.modal
    ];

    constructor(
      private modalService: IModalService
    ) {}

    showExportToEmailAlertModal(): void {
      const template = ModalTemplates.initTemplate<IConfirmationModalData>(ModalTemplates.ExportToEmailAlert);
      template.modalData = {
        messages: [
          'Your export is being created now. As soon as the file ',
          'is ready you will receive an email with a download link. ',
        ]
      };
      this.modalService.showConfirmation<IConfirmationModalData, boolean>(template, () => {});
    }
}

