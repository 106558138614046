import { Component, OnDestroy, OnInit } from '@angular/core';
import { ESplitCardChangeMeaning } from '@app/components/shared/components/split-card/split-card.models';
import { UseCaseService } from '@app/components/audit-reports/use-cases/use-case.service';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import {
  EUseCaseNavToKeys,
  EUseCaseWidgetType,
  IAuditUseCaseData, IUseCaseAnalyticsSummaryInsight,
  IUseCaseCookiesSummaryInsight,
  IUseCaseDuplicatesAndMultiplesWidgetData,
  IUseCasePageSummaryInsight,
  IUseCaseSection, IUseCaseTagHealthSummaryInsight, IUseCaseWebVitalsWidget,
  IUseCaseWidget
} from '@app/components/audit-reports/use-cases/use-cases.models';
import {
  PagesSection,
  OverviewAveragePageLoadTimeWidget,
  OverviewPagesScannedWidget,
  OverviewBrokenPagesWidget,
  CookiesSection,
  OverviewUniqueCookiesWidget,
  OverviewNonSecureCookiesWidget,
  TagsSection,
  OverviewUniqueTagsWidget,
  OverviewBrokenTagRequestsWidget,
  OverviewAverageTagLoadTimeWidget,
  OverviewTagInitiatorWidget, OverviewLCPWidget
} from '@app/components/audit-reports/use-cases/use-case-overview/use-case-overview.constants';
import { IUser } from '@app/moonbeamModels';
import { userIsGuest } from '@app/authUtils';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { ITagInitiatorNode } from '@app/components/shared/components/tag-initiators/tag-initiators.models';
import { OP_SELECTORS } from '@app/components/audit-reports/use-cases/use-cases.constants';
import {
  EWebVitalsMetricType
} from '@app/components/shared/components/viz/web-vitals-chart/web-vitals-chart.constants';

@Component({
  selector: 'op-use-case-overview',
  templateUrl: './use-case-overview.component.html',
  styleUrls: ['./use-case-overview.component.scss']
})
export class UseCaseOverviewComponent implements OnInit, OnDestroy {
  readonly EWebVitalsMetricType = EWebVitalsMetricType;
  CONSTANTS = { ...OP_SELECTORS };
  destroy$ = new Subject();
  auditInfo: IAuditUseCaseData;
  user: IUser;
  isReadOnly: boolean;
  accountType: EAccountType;
  isVisitorMode: boolean;
  tagInitiatorsData: ITagInitiatorNode;

  pagesSection: IUseCaseSection = {
    sectionIcon: PagesSection.sectionIcon,
    sectionTitle: PagesSection.sectionTitle,
  };

  cookiesSection: IUseCaseSection = {
    sectionIcon: CookiesSection.sectionIcon,
    sectionTitle: CookiesSection.sectionTitle,
  };

  tagsSection: IUseCaseSection = {
    sectionIcon: TagsSection.sectionIcon,
    sectionTitle: TagsSection.sectionTitle,
  };

  pagesScannedWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_SCANNED,
    loading: true,
    splitCard: true,
    topText: OverviewPagesScannedWidget.topText,
    topValue: '---',
    topTooltip: OverviewPagesScannedWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bodyContent: {
      bodyText: '',
      navToText: OverviewPagesScannedWidget.bodyContent.navToText,
    }
  };

  brokenPagesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.BROKEN_PAGES,
    loading: true,
    splitCard: true,
    topText: OverviewBrokenPagesWidget.topText,
    topTooltip: OverviewBrokenPagesWidget.topTooltip,
    topIconName: OverviewBrokenPagesWidget.topIconName,
    topValue: 0,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryBrokenFinalPages),
    bottomText: OverviewBrokenPagesWidget.bottomText,
    bottomValue: OverviewBrokenPagesWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryBrokenFinalPages),
    bodyContent: {
      bodyText: '',
      navToText: OverviewBrokenPagesWidget.bodyContent.navToText,
    }
  };

  averagePageLoadTimeWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.AVG_PAGE_LOAD_TIME,
    loading: true,
    splitCard: true,
    topIconName: OverviewAveragePageLoadTimeWidget.topIconName,
    topText: OverviewAveragePageLoadTimeWidget.topText,
    topValue: 0,
    topTooltip: OverviewAveragePageLoadTimeWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryAveragePageLoadTime),
    bottomText: OverviewAveragePageLoadTimeWidget.bottomText,
    bottomValue: OverviewAveragePageLoadTimeWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummaryAveragePageLoadTime),
    bodyContent: {
      bodyText: '',
      navToText: OverviewAveragePageLoadTimeWidget.bodyContent.navToText,
    }
  };

  overviewLCPWidget: IUseCaseWebVitalsWidget = {
    opSelector: this.CONSTANTS.LCP,
    loading: true,
    splitCard: true,
    topIconName: OverviewLCPWidget.topIconName,
    topText: OverviewLCPWidget.topText,
    topValue: 0,
    topTooltip: OverviewLCPWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bottomText: OverviewLCPWidget.bottomText,
    bottomValue: OverviewLCPWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bodyContent: {
      bodyText: '',
      navToText: OverviewLCPWidget.bodyContent.navToText,
    },
    metricType: EWebVitalsMetricType.LargestContentfulPaint,
  };

  uniqueTagsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNIQUE_TAGS,
    loading: true,
    splitCard: true,
    chartData: [],
    topText: OverviewUniqueTagsWidget.topText,
    topValueMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topValue: 0,
    topTooltip: OverviewUniqueTagsWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventory),
    statsData: OverviewUniqueTagsWidget.statsData,
    bottomLeftCustomContent: OverviewUniqueTagsWidget.bottomLeftCustomContent,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventory),
    bodyContent: {
      bodyText: '',
      navToText: OverviewUniqueTagsWidget.bodyContent.navToText,
    }
  };

  brokenTagRequestsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.BROKEN_TAG_REQUESTS,
    loading: true,
    splitCard: true,
    topText: OverviewBrokenTagRequestsWidget.topText,
    topIconName: OverviewBrokenTagRequestsWidget.topIconName,
    topValue: 0,
    topTooltip: OverviewBrokenTagRequestsWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryBrokenTags),
    bottomText: OverviewBrokenTagRequestsWidget.bottomText,
    bottomValue: OverviewBrokenTagRequestsWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryBrokenTags),
    bodyContent: {
      bodyText: '',
      navToText: OverviewBrokenTagRequestsWidget.bodyContent.navToText,
    }
  };

  averageTagLoadTimeWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.AVG_TAG_LOAD_TIME,
    loading: true,
    splitCard: true,
    topIconName: OverviewAverageTagLoadTimeWidget.topIconName,
    topText: OverviewAverageTagLoadTimeWidget.topText,
    topValue: 0,
    topTooltip: OverviewAverageTagLoadTimeWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagHealth),
    bottomText: OverviewAverageTagLoadTimeWidget.bottomText,
    bottomValue: OverviewAverageTagLoadTimeWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagHealth),
    bodyContent: {
      bodyText: '',
      navToText: OverviewAverageTagLoadTimeWidget.bodyContent.navToText,
    }
  };

  tagInitiatorWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.TAG_INITIATORS,
    loading: true,
    splitCard: true,
    topText: OverviewTagInitiatorWidget.topText,
    bottomValue: 0,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bodyContent: {
      bodyText: '',
    }
  };

  uniqueCookiesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNIQUE_COOKIES,
    loading: true,
    splitCard: true,
    topText: OverviewUniqueCookiesWidget.topText,
    topValueMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topValue: 0,
    topTooltip: OverviewUniqueCookiesWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.CookiesInventory),
    statsData: OverviewUniqueCookiesWidget.statsData,
    bottomLeftCustomContent: OverviewUniqueCookiesWidget.bottomLeftCustomContent,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.CookiesInventory),
    bodyContent: {
      bodyText: '',
      navToText: OverviewUniqueCookiesWidget.bodyContent.navToText,
    }
  };

  nonSecureCookiesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.NON_SECURE_COOKIES,
    loading: true,
    splitCard: true,
    topText: OverviewNonSecureCookiesWidget.topText,
    topValue: 0,
    topTooltip: OverviewNonSecureCookiesWidget.topTooltip,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.CookiesInventoryNonSecureCookies),
    bottomText: OverviewNonSecureCookiesWidget.bottomText,
    bottomValue: OverviewNonSecureCookiesWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.CookiesInventoryNonSecureCookies),
    bodyContent: {
      bodyText: '',
      navToText: OverviewNonSecureCookiesWidget.bodyContent.navToText,
    }
  };

  private pagesInfo: IUseCasePageSummaryInsight;
  private cookieInfo: IUseCaseCookiesSummaryInsight;
  private duplicatesAndMultiplesInfo: IUseCaseDuplicatesAndMultiplesWidgetData;
  private analyticsTagsInfo: IUseCaseAnalyticsSummaryInsight;
  private tagHealthInfo: IUseCaseTagHealthSummaryInsight;
  private tagInitiatorsToggleClass = 'open-initiators-btn';

  constructor(private useCaseService: UseCaseService) {}

  ngOnInit(): void {
    this.registerListeners();

    if (!this.useCaseService.isOverviewAndPrivacyDataLoaded || !this.useCaseService.isOverviewDataLoaded) {
      this.useCaseService.loadOverview();
    }
  }

  registerListeners() {
    combineLatest([
      this.useCaseService.accountType$,
      this.useCaseService.isVisitorMode$,
      this.useCaseService.user$,
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([accountType, isVisitorMode, user]) => {
      this.user = user;
      this.accountType = accountType;
      this.isVisitorMode = isVisitorMode;
      this.isReadOnly = userIsGuest(user) || isVisitorMode;

      this.useCaseService.audit$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((auditInfo) => {
        this.auditInfo = auditInfo;
        this.updatePagesScannedWidget();
        this.updateTagInitiatorWidget();
      });

      this.useCaseService.pageSummary$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(pagesInfo => {
        this.pagesInfo = pagesInfo;
        this.updatePagesScannedWidget();
        this.updatePagesWidgetValues();
      });

      this.useCaseService.cookiesSummary$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(cookieInfo => {
        this.cookieInfo = cookieInfo;

        this.updateCookiesWidgetValues();
      });
    });

    this.useCaseService.duplicatesAndMultiples$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(duplicatesAndMultiplesInfo => {
      this.duplicatesAndMultiplesInfo = duplicatesAndMultiplesInfo;

      this.updateTagsWidgetValues();
    });

    this.useCaseService.analyticsTags$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(analyticsTagsInfo => {
      this.analyticsTagsInfo = analyticsTagsInfo;
      this.updateTagsWidgetValues();
    });

    this.useCaseService.tagHealth$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(tagHealthInfo => {
      this.tagHealthInfo = tagHealthInfo;

      this.updateBrokenTagRequestsWidget();
      this.updateAverageTagLoadTimeWidget();
    });

    this.useCaseService.tagInitiators$.subscribe((tagInitiatorsData: ITagInitiatorNode) => {
      if (!tagInitiatorsData) return;

      this.tagInitiatorsData = tagInitiatorsData;
      this.updateTagInitiatorWidget();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updatePagesWidgetValues(): void {
    this.updateBrokenPagesWidget();
    this.updateAveragePageLoadTimeWidget();
    this.updateLCPWidget();
  }

  updateCookiesWidgetValues(): void {
    this.updateUniqueCookiesWidget();
    this.updateNonSecureCookiesWidget();
  }

  updateTagsWidgetValues(): void {
    this.updateUniqueTagsWidget();
  }

  updatePagesScannedWidget(): void {
    const widget = this.pagesScannedWidget;

    widget.topValue = this.pagesInfo?.totalPages?.toLocaleString() || '---';
    widget.bottomValue = this.auditInfo?.domain;
    widget.bodyContent.bodyText = `<div class="domain-block"><span class="domain-block-label">DOMAIN AUDITED: </span><span class="domain-block-value">${this.auditInfo?.domain}</span></div><div>${this.useCaseService.getBodyText(EUseCaseWidgetType.OverviewPagesScanned)}</div>`;
    widget.loading = false;
  }

  updateBrokenPagesWidget(): void {
    const widget = this.brokenPagesWidget;
    const brokenFinalStatusPages = this.pagesInfo.pagesWithBrokenFinalStatusCode;

    widget.topValue = brokenFinalStatusPages >= 0 ? brokenFinalStatusPages : '---';
    widget.topValueMeaning = (widget.topValue as number) > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;
    widget.topIconMeaning = (widget.topValue as number) > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;
    widget.topIconName = (widget.topValue as number) > 0 ? OverviewBrokenPagesWidget.topIconName : 'check_circle';

    widget.bottomValue = OverviewBrokenPagesWidget.bottomValue;
    widget.chartData = this.pagesInfo.brokenPagesChartData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.OverviewBrokenPages);
    widget.loading = false;
  }

  updateAveragePageLoadTimeWidget(): void {
    const widget = this.averagePageLoadTimeWidget;
    widget.topValue = this.pagesInfo?.averagePageLoadTime ? (this.pagesInfo.averagePageLoadTime / 1000).toFixed(1) : '---';
    widget.topIconName = this.pagesInfo.averagePageLoadTime > 3000 ? 'error' : OverviewAveragePageLoadTimeWidget.topIconName;
    widget.topValueMeaning = this.getAveragePageLoadTimeWidgetTopMeaning();
    widget.topIconMeaning = this.getAveragePageLoadTimeWidgetTopMeaning();
    widget.chartData = this.pagesInfo.averagePageLoadTimeChartData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.OverviewAveragePageLoadTime);
    widget.loading = false;
  }

  updateLCPWidget(): void {
    if (!this.pagesInfo?.webVitals?.p75LargestContentfulPaint) {
      return;
    }

    const widget = this.overviewLCPWidget;
    widget.topValue = this.pagesInfo?.webVitals?.p75LargestContentfulPaint
      ? `${(this.pagesInfo.webVitals.p75LargestContentfulPaint / 1000)} sec`
      : '---';
    widget.topIconName = this.pagesInfo.webVitals.p75LargestContentfulPaint > OverviewLCPWidget.bestPracticeValue * 1000
      ? 'error'
      : OverviewLCPWidget.topIconName;
    widget.topValueMeaning = this.getLargestContentfulPaintWidgetTopMeaning();
    widget.topIconMeaning = this.getLargestContentfulPaintWidgetTopMeaning();
    widget.chartData = this.pagesInfo.overviewLargestContentfulPaintChartData as any;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.OverviewLargestContentfulPaint);
    widget.loading = false;
  }

  updateUniqueTagsWidget(): void {
    const widget = this.uniqueTagsWidget;
    widget.topValue = this.duplicatesAndMultiplesInfo?.filteredUniqueTagCount;
    widget.chartData = this.analyticsTagsInfo?.uniqueAnalyticsChartData || [];
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.OverviewUniqueTags);
    widget.loading = false;
  }

  updateBrokenTagRequestsWidget(): void {
    const widget = this.brokenTagRequestsWidget;
    const chartData = this.tagHealthInfo?.brokenTagsChartData || [];
    widget.topValue = this.tagHealthInfo?.filteredBrokenTagCount?.toLocaleString()
      || 0;
    widget.topValueMeaning = this.tagHealthInfo?.filteredBrokenTagCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;
    widget.topIconMeaning = this.tagHealthInfo?.filteredBrokenTagCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;
    widget.topIconName = this.tagHealthInfo?.filteredBrokenTagCount > 0 ? 'error' : '';
    widget.chartData = chartData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.BrokenTagRequests);
    widget.loading = false;
  }

  updateAverageTagLoadTimeWidget(): void {
    const widget = this.averageTagLoadTimeWidget;
    const chartData = this.tagHealthInfo?.tagRequestsChartData || [];
    widget.chartData = chartData;

    widget.topValue = this.tagHealthInfo?.filteredTagLoadTimeAverage + ' ms';
    widget.topValueMeaning = this.getAverageTagLoadTimeWidgetTopMeaning();
    widget.topIconMeaning = this.getAverageTagLoadTimeWidgetTopMeaning();
    widget.topIconName = this.tagHealthInfo?.filteredTagLoadTimeAverage > 500 ? 'error' : OverviewAverageTagLoadTimeWidget.topIconName;
    widget.chartData = chartData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.TagRequests);
    widget.loading = false;
  }

  updateTagInitiatorWidget(): void {
    if (this.auditInfo.domain === this.useCaseService.domainLoadingString) return;

    const widget = this.tagInitiatorWidget;
    const pageTitle = this.auditInfo?.pageTitle || this.auditInfo?.domain || '';
    const noTagsFound = !this.tagInitiatorsData;

    widget.topText = `Tag Initiators ${pageTitle ? 'on<br><b>' + pageTitle : ''}</b>`;
    widget.bodyContent.bodyText = `${noTagsFound ? 'No' : 'Here is how'} tags loaded ${pageTitle ? 'on<br><b>' + pageTitle + '</b>' : ''}. To view tag initiators for other pages click a URL from any report to open a detailed view. ${noTagsFound ? '' : '<div class="' + this.tagInitiatorsToggleClass + '">View Tag Initiators Full Screen</div>'}`;
    widget.loading = false;
  }

  updateUniqueCookiesWidget(): void {
    const widget = this.uniqueCookiesWidget;
    widget.topValue = this.cookieInfo.totalUniqueCookieCount;
    widget.chartData = this.cookieInfo.partCookiesChartData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.OverviewUniqueCookies);
    widget.loading = false;
  }

  updateNonSecureCookiesWidget(): void {
    const widget = this.nonSecureCookiesWidget;
    widget.topValue = this.cookieInfo.filteredNonSecureCookieCount;
    widget.topValueMeaning = this.cookieInfo.filteredNonSecureCookieCount ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;
    widget.chartData = this.cookieInfo.securityCookiesChartData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.OverviewNonSecureCookies);
    widget.loading = false;
  }

  private getAveragePageLoadTimeWidgetTopMeaning(): ESplitCardChangeMeaning {
    if (this.pagesInfo.averagePageLoadTime < 3000) {
      return ESplitCardChangeMeaning.POSITIVE;
    } else if (this.pagesInfo.averagePageLoadTime > 3000 && this.pagesInfo.averagePageLoadTime < 6000) {
      return ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else if (this.pagesInfo.averagePageLoadTime > 6000 && this.pagesInfo.averagePageLoadTime < 10000) {
      return ESplitCardChangeMeaning.SORT_OF_NEGATIVE;
    } else if (this.pagesInfo.averagePageLoadTime > 10000) {
      return ESplitCardChangeMeaning.NEGATIVE;
    }
  }

  private getLargestContentfulPaintWidgetTopMeaning(): ESplitCardChangeMeaning {
    const overviewLCP = this.pagesInfo.webVitals.p75LargestContentfulPaint;
    if (overviewLCP < OverviewLCPWidget.bestPracticeValue * 1000) {
      return ESplitCardChangeMeaning.POSITIVE;
    } else if (overviewLCP > OverviewLCPWidget.bestPracticeValue * 1000 && overviewLCP < 4000) {
      return ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else if (overviewLCP > 4000 && overviewLCP < 6000) {
      return ESplitCardChangeMeaning.SORT_OF_NEGATIVE;
    } else if (overviewLCP > 6000) {
      return ESplitCardChangeMeaning.NEGATIVE;
    }
  }

  private getAverageTagLoadTimeWidgetTopMeaning(): ESplitCardChangeMeaning {
    if (this.tagHealthInfo?.filteredTagLoadTimeAverage < 500) {
      return ESplitCardChangeMeaning.POSITIVE;
    } else if (this.tagHealthInfo?.filteredTagLoadTimeAverage > 500 && this.tagHealthInfo?.filteredTagLoadTimeAverage < 999) {
      return ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else if (this.tagHealthInfo?.filteredTagLoadTimeAverage > 1000 && this.tagHealthInfo?.filteredTagLoadTimeAverage < 1999) {
      return ESplitCardChangeMeaning.SORT_OF_NEGATIVE;
    } else if (this.tagHealthInfo?.filteredTagLoadTimeAverage > 2000) {
      return ESplitCardChangeMeaning.NEGATIVE;
    }
  }

  openInitiatorsFullScreen($event: MouseEvent): void {
    if ($event.target['classList'].contains(this.tagInitiatorsToggleClass)) {
      this.tagInitiatorsData ?
        this.useCaseService.openInitiatorsFullScreen(this.tagInitiatorsData)
        : null;
    }
  }
}
