import { EAccessibilityImpact } from '@app/components/audit-reports/page-details/page-details.models';

export interface IPagesDetailsAccessibilityFilter {
  label: string;
  name: EPageDetailsAccessibilityFilterName;
  menuTitle: string;
  menuOptions: IPagesDetailsAccessibilityFilterMenuOption[];
}

export interface IPagesDetailsAccessibilitySeverityFilter {
  label: string;
  name: EAccessibilityImpact;
  className: EAccessibilityImpact;
  checked: boolean;
}

export interface IPagesDetailsAccessibilityFilterMenuOption {
  label: string;
  checked: boolean;
}

export enum EPageDetailsAccessibilityFilterName {
  Versions = 'versions',
  Levels = 'levels',
  Types = 'types',
}

export interface IPagesDetailsAccessibilityFilterBarFilters {
  topFilters: Record<EPageDetailsAccessibilityFilterName, IPagesDetailsAccessibilityFilter>;
  severity: Record<EAccessibilityImpact, IPagesDetailsAccessibilitySeverityFilter>;
}

export enum EPageDetailsAccessibilityWCAGConformanceLevel {
  A = 'A',
  AA = 'AA',
  AAA = 'AAA',
}

export enum EPageDetailsAccessibilityWCAGVersion {
  'v2.0' = '2.0',
  'v2.1' = '2.1',
  'v2.2' = '2.2',
}

export enum EPageDetailsAccessibilityWCAGIssueType {
  Conclusive = 'Conclusive Issues',
  Inconclusive = 'Manual Inspection Needed',
}
