<op-splash-page-basic header="LiveConnect" imageSrc="/images/liveconnect.png" (onClick)="goToMarketingSite()">
  <ng-template #bodyTemplate>
    <b class="caption">Create and run test scripts on the fly with any WiFi-enabled device.</b>
    <div class="text">
      With LiveConnect, you can connect to any device over WiFi and begin running automated test scripts that
      capture real-time data as you interact with that device.
    </div>
    <div class="text">Key features include:</div>
    <ul>
      <li><b>Live Journey Debugging</b></li>
      <li><b>Reusable Test Cases</b></li>
      <li><b>Custom Rules</b></li>
      <li><b>Selenium Integration</b></li>
      <li><b>Real Device Testing</b></li>
      <li><b>Request Log</b></li>
      <li><b>Secure Testing Environment</b></li>
      <li><b>Custom Exports</b></li>
    </ul>
    <div class="text">Interested in learning more? Let your ObservePoint consultant know!</div>
  </ng-template>
</op-splash-page-basic>
