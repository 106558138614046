import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface IReprocessConfirmationItem {
  name: string;
}

export interface IReprocessConfirmationData {
  itemType: string;
  items: IReprocessConfirmationItem[];
  onReprocess?: () => void;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'reprocess-confirmation-snackbar',
  templateUrl: './reprocess-confirmation-snackbar.component.html',
  styleUrls: ['./reprocess-confirmation-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReprocessConfirmationSnackbarComponent {

  items: IReprocessConfirmationItem[] = [];

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: IReprocessConfirmationData,
    private snackBarRef: MatSnackBarRef<ReprocessConfirmationSnackbarComponent>
  ) {
    this.items = data.items;
  }

  reprocessRules() {
    this.data.onReprocess && this.data.onReprocess();
    this.snackBarRef.dismiss();
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
