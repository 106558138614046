import { Names } from '@app/moonbeamConstants';
import { IPageDetails, IPageProfile, IRFMInfo, IVendor } from '@app/models/tagModels/pageDetails';
import { ISerializer } from '@app/components/api/apiService';
import { TagsService } from '@app/components/tags/tagsService';
import { IPageDetail } from '@app/models/tagModels/pageDetail';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

interface IPageProfileTagCategoryDataJson {
  id: number;
  name: string;
}

interface IPageProfileTagVendorDataJson {
  id: number;
  name: string;
  icon: string;
  category: IPageProfileTagCategoryDataJson;
}

interface IPageProfileTagsDataJson {
  duplicate: number;
  tag: IPageProfileTagVendorDataJson;
  loadtime: number;
  variables: any;
  invalidVariables: Array<any>;
  version: string;
  bytes: number;
  valid: boolean;
  position: string;
  account: string;
  multipleRequest: number;
}

export interface IPageProfileDataJson {
  statusCode: number;
  parent: any;
  size: number;
  url: string;
  tags: Array<IPageProfileTagsDataJson>;
  loadtime: number;
  jsErrors: number;
  auditRun: number;
  id: string;
  dataCollectionUuid: string;
  fileMappingResults: IRFMInfo[]
}

export class PageProfileSerializer implements ISerializer<IPageProfile> {

  static $inject = [
    Names.Services.tags
  ];

  constructor(private tagsService: TagsService) {
  }

  serialize(json: IPageProfileDataJson): IPageProfile {
    var pageVendors: Array<IVendor> = this.serializePageVendors(json.tags);
    return <IPageProfile>{
      url: json.url,
      id: json.id,
      linkFrom: {
        parent: json.parent,
        url: null,
        runId: null
      },
      lastAuditDate: null,
      value: null,
      loadTime: json.loadtime,
      statusCode: json.statusCode,
      consoleLogs: json.jsErrors,
      pageVendors: pageVendors,
      dataCollectionUuid: json.dataCollectionUuid,
      fileMappingResults: json.fileMappingResults
    };

  }

  private serializePageVendors(tags: Array<IPageProfileTagsDataJson>): Array<IVendor> {
    if (tags && tags[0]) {
      return Object.entries(ArrayUtils.groupByAsObject(tags, t => t.tag.id as any))
        .map(([tagId, groupedTags]) => {
          const details = groupedTags.map(item => this.serializePageAccounts(item));
          const k = groupedTags[0];

          // Find extra requests. There are duplicate - 1 extra requests when duplicate > 0.
          const extra = groupedTags.reduce(
            (sum, current) => sum + Math.max(current.duplicate - 1, 0), 
            0
          );

          return {
            id: (k.tag.id).toString(),
            name: k.tag.name,
            iconUrl: UiTagService.getTagIconUrl(k.tag.id),
            value: null,
            category: k.tag.category,
            details: details, //account
            totalRequests: details.length + extra
          };
        });
    }
    return [];
  }

  private serializePageAccounts(tag: IPageProfileTagsDataJson): IPageDetails {
    if (tag) {
      var pageDetails: Array<IPageDetail> = this.serializePageDetails(tag);
      return {
        name: tag.account,
        value: null,
        category: tag.tag.category.name,
        pageDetails: pageDetails
      };
    } else {
      return null;
    }
  }

  private serializePageDetails(tag: IPageProfileTagsDataJson): Array<IPageDetail> {
    var pageDetailsDefault: Array<IPageDetail> = [];
    var pageDetailsCustom: Array<IPageDetail> = [];
    for (var key in tag) {
      if (key === 'variables' && key as string != 'tag') {
        for (var variable in tag.variables) {
          pageDetailsCustom.push(<IPageDetail>{
            name: variable,
            value: tag.variables[variable].toString(),
            category: 'custom'
          });
        }
      } else if (key != 'tag' && key != 'invalidVariables' && key != 'account') {
        pageDetailsDefault.push(<IPageDetail>{
          name: key,
          value: tag[key].toString(),
          category: 'default'
        });
      }
    }
    return pageDetailsDefault.concat(pageDetailsCustom);
  }
}
