<mat-expansion-panel [hideToggle]="true"
                     [expanded]="isExpanded"
                     (opened)="handleOpened()"
                     (closed)="isExpanded = false">
  <mat-expansion-panel-header>
    <div class="arrow">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </div>
    {{ title }}
  </mat-expansion-panel-header>

  <ng-content></ng-content>
</mat-expansion-panel>
