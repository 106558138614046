import { Router } from '@angular/router';
import { Events } from '@app/moonbeamConstants';
import { ISort } from '@app/components/manage/shared/services/manage-communication/manage-communication.service';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { Injectable } from '@angular/core';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { AdminService } from '@app/components/admin-portal/system-status/admin.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarSuccessComponent } from '@app/components/shared/components/snackbars/snackbar-success/snackbar-success.component';
import { DateService } from '@app/components/date/date.service';

// TODO: rewrite interval feature on RxJS
// We should have interval observable, that's stopped, resumed, refreshed etc by other subjects
@Injectable()
export class SystemStatusService {
  refreshTimer: any;
  refreshFunction: Function;
  currentCallback: Function;
  currentTime: number;
  counter: any;
  currentCount: number;

  constructor(private router: Router,
              private adminService: AdminService,
              private authenticationStorageService: AuthenticationStorageService,
              private eventManager: IEventManager,
              private snackbar: MatSnackBar,
              private storageService: StorageService,
              private dateService: DateService,
  ) {
  }

  registerTimer(callback: Function, time: number) {
    this.currentCallback = callback;
    this.currentTime = time;
    this.currentCount = time / 1000;
    if (this.refreshTimer){
      this.unregisterTimer();
    }
    this.refreshTimer = window.setInterval(() => {
      callback();
    }, time);
    this.counter = window.setInterval(() => {
      if (this.currentCount == 0){
        this.currentCount = this.currentTime / 1000;
      }
      this.currentCount--;
    }, 1000);
  }

  unregisterTimer() {
    window.clearInterval(this.refreshTimer);
    window.clearInterval(this.counter);
    this.counter = null;
    this.currentCount = this.currentTime / 1000;
    this.refreshTimer = null;
  }

  copyToClipboard(): void {
    const message = `Copied!`;

    this.snackbar.openFromComponent(SnackbarSuccessComponent, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {message},
      panelClass: 'system-status-snackbar',
    });
  }

  setRefreshFunction(refreshFunction) {
    this.refreshFunction = refreshFunction;
  }

  refresh() {
    if (this.refreshFunction) {
      this.refreshFunction();
      this.registerTimer(this.refreshFunction, 60000);
    }
  }

  pause() {
    this.unregisterTimer();
  }

  resume() {
    this.registerTimer(this.currentCallback, this.currentTime);
  }

  isPaused() {
    return this.refreshTimer == null;
  }

  timeUntilRefresh() {
    return this.currentCount;
  }

  getRecentDate(date: Date): string {
    if (date){
      return this.dateService.distanceToNow(new Date(date));
    }
    return '';
  }

  setSort(column: string, sort: ISort): ISort {
    var reverse = (sort.sortOn == column) ? !sort.reverse : false;
    return {sortOn: column, reverse: reverse};
  }

  goToAccount(accountId: number) {
    this.loginAsAccount(accountId, AccountSettingsUrlBuilders.user());
  }

  loginAsAccount(accountId: number, url: string) {
    this.adminService.loginAsUser(accountId).then((tokenInfo) => {
      var authorizationData = {token: tokenInfo.accessToken, id: tokenInfo.userId, expiresAt: Date.now() + 10 * 60 * 60 * 1000 };

      this.authenticationStorageService.impersonateUser(authorizationData);

      this.router.navigateByUrl(url).then(() => {
        this.eventManager.publish(Events.loggedInAsAnother);
      });
    }, this.handleError);
  }

  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  handleError(error) {
    console.error(error);
  }
}
