import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import {
  IPrivacyMetadata,
  IPrivacyRequestsPage,
  IPrivacyRequestsPageRow,
  IPrivacyRequestsPages
} from '../../privacy-requests.models';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { takeUntil } from 'rxjs/operators';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import {
  formatPaginator,
  PageLoadColumnTooltip,
  REQUEST_LOG_SEARCH_TEXT_KEY
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IAuditReportExportMenuData
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import _isEmpty from 'lodash-es/isEmpty';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { PagesTableColumns } from './privacy-requests-pages-table.constants';
import {
  CommonPagesColumnConfigWarningMessage,
  CommonPagesConfigLocalStorageKey,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'privacy-requests-table',
  templateUrl: './privacy-requests-pages-table.component.html',
  styleUrls: ['./privacy-requests-pages-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PrivacyRequestsPagesTableComponent implements OnInit, OnDestroy, AfterViewInit  {
  protected readonly PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;
  readonly PagesTableColumns = PagesTableColumns;
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  auditId: number;
  runId: number;

  PageLoadColumnTooltip = PageLoadColumnTooltip;

  @Input() state: EFilterSpinnerState;
  @Input() exportReportConfig: IAuditReportExportMenuData;
  @Input() requestDomainToAutofill?: string = '';
  @Input() set items(data: IPrivacyRequestsPages) {
    if (data) {
      this.initTable(data.pages);
      this.setPaginationData(data.metadata);
    }
  }

  @Output() onSort = new EventEmitter<Sort>();
  @Output() onPaginate = new EventEmitter<number>();

  dataSource = new MatTableDataSource<IPrivacyRequestsPageRow>();
  paginationState: { length: number, pageSize: number } = {
    length: 0,
    pageSize: 0
  };
  displayedColumns$ = this.tableService.displayedColumns$;
  openedPageDetailsId: string;
  destroy$ = new Subject<void>();

  @ViewChild('scrollTop', { read: ElementRef }) scrollTop: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  readonly TableColumn = CommonReportsPagesTableColumns;

  constructor(
    private auditReportService: AuditReportService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private scrollService: AuditReportScrollService,
    private activatedRoute: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private tableService: ResizeableTableService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;
    });
  }

  ngOnInit(): void {
    this.pageDetailsSetup();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(sort => {
      this.exportReportConfig.tableState.sortBy = sort.active;
      this.exportReportConfig.tableState.sortDesc = sort.direction === 'desc';
      this.onSort.emit(sort);
    });
    this.paginator.page.subscribe(({pageIndex}) => this.onPaginate.emit(pageIndex));
    this.formatPaginator();
  }

  private initTable(pages: IPrivacyRequestsPage[]) {
    this.dataSource.data = pages.map(item => {
      const loadTime = parseFloat((item.pageLoadTime / 1000).toFixed(1));
      return {
        ...item,
        pageLoadTime: loadTime,
        pageLoadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(loadTime),
        finalPageStatusCode: item.finalPageStatusCode,
        pageStatusCodeClass: this.auditReportService.getStatusCodeClass(item.finalPageStatusCode),
      };
    });
  }

  private setPaginationData({pagination}: IPrivacyMetadata) {
    if (_isEmpty(this.exportReportConfig.filters)) {
      this.exportReportConfig.totalRows = this.paginationState.length = pagination.totalCount;
    } else {
      this.exportReportConfig.filteredRows = this.paginationState.length = pagination.totalCount;
    }

    this.exportReportConfig.tableState.size = this.paginationState.pageSize = pagination.pageSize;
    this.exportReportConfig.tableState.page = pagination.currentPageNumber;
  }

  private pageDetailsSetup() {
    // setup for page details
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.RequestLog);
    this.pageDetailsDrawerService.pageDrawerClosed$.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.handlePageDetailsClosed());
  }

  openPageDetails({ pageId, pageUrl }) {
    const state = {
      [REQUEST_LOG_SEARCH_TEXT_KEY]: this.requestDomainToAutofill || ''
    };

    this.openedPageDetailsId = pageId;
    this.pageDetailsDrawerService.openPageDetails({ id: pageId, url: pageUrl }, this.auditId, this.runId, EPageDetailsTabs.RequestLog, null, state);
  }

  private handlePageDetailsClosed() {
    this.openedPageDetailsId = null;
  }

  onScrollTop() {
    this.scrollService.scrollByElement(this.scrollTop.nativeElement);
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private formatPaginator(): void {
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }
}
