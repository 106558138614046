import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WindowRef } from '@app/components/core/services/window.service';
import { EAccountType } from "@app/components/core/services/authentication.enums";

@Injectable()
export class ExternalLinksService {
  private readonly additionalProductsLink = 'https://www.observepoint.com/additional-solutions';
  constructor(private windowRef: WindowRef) {}

  openAdditionalProducts() {
    this.windowRef.nativeWindow.open(this.additionalProductsLink, '_blank');
  }

  openUpgradeToFreeTrial(accountId: number,
                         accountType: EAccountType,
                         userId: number,
                         partnerId?: number) {
    let queryParams = new HttpParams({
      fromObject: {
        aid: accountId,
        at: accountType,
        uid: userId,
      }
    });
    if (typeof partnerId === 'undefined') queryParams = queryParams.set('pid', partnerId);
    this.windowRef.nativeWindow.open(`https://www.observepoint.com/upgrade-your-account?${queryParams.toString()}`, '_blank');
  }

  openUpgradeToPaid(accountId: number,
                    accountType: EAccountType,
                    userId: number,
                    partnerId?: number) {
    let queryParams = new HttpParams({
      fromObject: {
        aid: accountId,
        at: accountType,
        uid: userId,
      }
    });
    if (typeof partnerId === 'undefined') queryParams = queryParams.set('pid', partnerId);
    this.windowRef.nativeWindow.open(`https://www.observepoint.com/upgrade-your-trial-account?${queryParams.toString()}`, '_blank');
  }

  openStartFreeTrial(): void {
    this.windowRef.nativeWindow.open('https://www.observepoint.com/free-trial', '_blank');
  }
}
