import { Names } from '@app/moonbeamConstants';
import { IJourneyRunJson } from './webJourneyAPIService';
import { ERunPickerStatus } from '@app/components/run-picker-ng/run-picker-ng.enums';
import { IRunPickerRun } from '@app/components/run-picker-ng/run-picker-ng.models';

import { DateService } from '../../../date/date.service';
import { IFormattedRun } from '../../discoveryAudits/reporting/summaryReports/auditSummary/auditSummaryData';
import { ITimeSeriesRuns } from '@app/components/reporting/widgets/timeSeries/timeSeries';

const JOURNEY_RUN_OK = 'Ok';
  const MAX_SCORE = 100;

  export abstract class IWebJourneyRunsSeriazlier {
    abstract serialize(json: Array<IJourneyRunJson>): Array<ITimeSeriesRuns>;
    abstract serializeForRunPicker(json: Array<IJourneyRunJson>): Array<IRunPickerRun>;
  }

  export class WebJourneyRunsSerializer extends IWebJourneyRunsSeriazlier {

    static $inject = [
      Names.Services.date
    ];

    constructor(
      private dateService: DateService
    ) {
      super();
    }

    private journeyRunScore = (status: string): number => (status == JOURNEY_RUN_OK) ? 100 : 0;

    serialize(json: Array<IJourneyRunJson>): Array<ITimeSeriesRuns> {
      var currentlyRunning = json[0] && !json[0].completedAt ? true : false;
      var runs = json
        .filter((journeyRun: IJourneyRunJson) => {
          return journeyRun.completedAt != null;
        })
        .map((journeyRun: IJourneyRunJson) => {
          var score = this.journeyRunScore(journeyRun.status);
          return <IFormattedRun>{
            id: journeyRun.id,
            date: this.dateService.dateFromString(journeyRun.completedAt),
            className: null,
            name: null,
            possibleScore: MAX_SCORE,
            score: score,
            value: score / MAX_SCORE,
          };
        });
      return [
        <ITimeSeriesRuns>{
          name: '',
          values: runs,
          running: currentlyRunning
        }
      ];
    }

    serializeForRunPicker(json: Array<IJourneyRunJson>): Array<IRunPickerRun> {
      return json.map(run => ({
          id: run.id,
          date: this.dateService.dateFromString(run.completedAt),
          selected: false,
          status: this.getRunPickerRunStatus(run.status),
          completed: !!run.completedAt
      }));
    }

    private getRunPickerRunStatus(status: string): ERunPickerStatus {
      switch (status) {
        case 'Ok':
          return ERunPickerStatus.Success;
        case 'ActionFailure':
          return ERunPickerStatus.ActionFailed;
        case 'RulesFailure':
          return ERunPickerStatus.RuleFailure;
        default:
          console.error(`Incorrect Web Journey's status ${status}`);
      }
    } 

}
