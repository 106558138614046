import * as angular from 'angular';
import { AngularNames, Names } from '@app/moonbeamConstants';
import { IApiService } from '@app/components/api/apiService';
import { environment } from '@app/environments/environment';

export interface IManualJourneyFileMetadata {
  name: string;
  title?: string;
}

export interface IManualJourneyFile extends IManualJourneyFileMetadata {
  id: number;
  url: string;
  previewUrl?: string;
}

export type IManualJourneyFileUpdate = Partial<IManualJourneyFileMetadata>;

export const name = 'LiveConnectAttachedFilesService';

export class LiveConnectAttachedFilesService {
  static $inject = [AngularNames.http, Names.Services.api];

  constructor(
    private $http: angular.IHttpService,
    private apiService: IApiService
  ) {}
  
  root: string = environment.apiUrl;

  getManualJourneyAttachedFiles(
    deviceProfileId: number,
    manualJourneyId: number
  ): angular.IPromise<Array<IManualJourneyFile>> {
    return this.apiService.handleResponse(
      this.$http.get(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/files`
      )
    );
  }

  updateManualJourneyFile(
    deviceProfileId: number,
    manualJourneyId: number,
    fileId: number,
    fileInfo: IManualJourneyFileUpdate
  ): angular.IPromise<IManualJourneyFile> {
    return this.apiService.handleResponse(
      this.$http.patch(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/files/${fileId}`,
        fileInfo
      )
    );
  }

  uploadManualJourneyFile(
    deviceProfileId: number,
    manualJourneyId: number,
    file: File,
    metadata: IManualJourneyFileMetadata,
    onProgress?: (event) => void
  ): angular.IPromise<IManualJourneyFile> {
    var payload = new FormData();
    payload.append('metadata', JSON.stringify(metadata));
    payload.append('file', file, metadata.name);
    var config = {
      headers: { 'Content-Type': undefined },
      transformRequest: angular.identity,
      uploadEventHandlers: { progress: onProgress }
    };
    return this.apiService.handleResponse(
      this.$http.post(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/files`,
        payload,
        config
      )
    );
  }

  removeManualJourneyFile(
    deviceProfileId: number,
    manualJourneyId: number,
    fileId: number
  ): angular.IPromise<any> {
    return this.apiService.handleResponse(
      this.$http.delete(
        `${this.root}manual-journeys/${deviceProfileId}/runs/${manualJourneyId}/files/${fileId}`
      )
    );
  }
}
