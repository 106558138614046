<overage-banner></overage-banner>
<div class="title-container">
  <h1 class="title">Email Inboxes
    <span class="icon-wrapper"
          [class.highlight]="infoIsExpanded"
          (click)="toggleHelpTextExpanded()">
      <mat-icon class="title-icon">help_outline</mat-icon>
      <mat-icon class="expansion-icon">expand_more</mat-icon>
    </span>
  </h1>
  <div class="controls">
    <op-button-2021 labelText="Create New Inbox"
                    matIcon="control_point"
                    (buttonAction)="onCreate()"
                    [disabled]="isReadOnly"
                    buttonType="filled">
    </op-button-2021>
  </div>
</div>

<op-info-panel [expanded]="infoIsExpanded" class="info-panel">
  <op-email-inbox-help-guide></op-email-inbox-help-guide>
</op-info-panel>

<op-email-inboxes-filter-bar
  [class.disabled]="loading || (inboxes.length === 0 && currentApiFilters.length === 0)"></op-email-inboxes-filter-bar>

<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>

<div class="flex-col flex-stretch inbox-table-overflow-wrapper"
     [class.hidden]="loading || inboxes.length === 0">
  <div class="inbox-table-container">

    <table mat-table
           matSort
           [matSortActive]="sortOptions.sortBy"
           [matSortDirection]="sortOptions.sortDir"
           [matSortStart]="sortOptions.sortDir"
           class="styled-mat-table"
           [dataSource]="dataSource"
           (matSortChange)="handleSort(sort)"
           multiTemplateDataRows>

      <!-- Inbox Name column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>{{ CONSTANTS.table.name }}
        </th>
        <td mat-cell *matCellDef="let inbox">
          <div class="name-wrapper truncate-text"
               (click)="onEditInbox($event, inbox)"
          >
            <span class="hover-link" matTooltip="{{inbox.name}}"
                  matTooltipPosition="above"
                  [matTooltipDisabled]="inbox.name.length < 21">
              {{ inbox.name }}
            </span>
            <mat-icon class="name-wrapper-warning-icon"
                      *ngIf="!inbox.testScenariosCount"
                      [matTooltip]="'No audits have been assigned to this inbox. Edit this inbox to add them so received messages can be processed.'"
                      matTooltipPosition="above"
            >warning
            </mat-icon>
            <op-copy-button
              class="copy-to-clipboard-icon"
              [copyValue]="inbox.emailAddress"
              copyTooltipText="Copy Inbox Email Address"
              copyBtnIcon="copy_all"
            ></op-copy-button>
          </div>
        </td>
      </ng-container>

      <!-- Last Received Message column -->
      <ng-container matColumnDef="lastMessageReceived">
        <th mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            class="center-text">{{ CONSTANTS.table.lastReceivedMessage }}
        </th>
        <td mat-cell
            *matCellDef="let inbox"
            class="center-text">
          <div class="flex-row last-received-message">
            {{ inbox.lastMessageReceivedText ?? '&mdash;' }}
          </div>
        </td>
      </ng-container>

      <!-- Notes column -->
      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef class="center-text">{{ CONSTANTS.table.notes }}</th>
        <td mat-cell
            *matCellDef="let inbox"
            [class.notes-expanded]="clicked === 'notes' && expandedInbox === inbox"
            [class.empty]="!inbox.notes"
            (click)="onClickNote($event, inbox)"
            class="center-text">
          <mat-icon matTooltip="Edit note"
                    matTooltipPosition="above"
                    matTooltipShowDelay="500">description
          </mat-icon>
          <mat-icon class="caret">expand_more</mat-icon>
        </td>
      </ng-container>

      <!-- Labels column -->
      <ng-container matColumnDef="labels">
        <th mat-header-cell *matHeaderCellDef>{{ CONSTANTS.table.labels }}</th>
        <td mat-cell *matCellDef="let inbox" (click)="$event.stopPropagation()">
          <op-label-manager [compactView]="true"
                            [selectedLabels]="inbox.labels"
                            (onLabelCreated)="createChip($event, inbox)"
                            (onLabelSelected)="selectChip($event, inbox)"
                            (onLabelRemoved)="removeChip($event, inbox)"
          ></op-label-manager>
        </td>
      </ng-container>

      <!-- Audit Runs column -->
      <ng-container matColumnDef="testScenariosCount">
        <th mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            class="center-text">{{ CONSTANTS.table.testScenariosCount }}
        </th>
        <td mat-cell
            *matCellDef="let inbox"
            [class.disabled]="!inbox.testScenariosCount"
            (click)="clickAuditCount($event, inbox)">
          <div class="flex-row">
            <span class="content-wrapper hoverable-text test-scenarios-count">
              {{ inbox.testScenariosCount }}
              <mat-icon class="edit-icon">edit</mat-icon>
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Messages Received column -->
      <ng-container matColumnDef="messagesReceived">
        <th mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            class="center-text">{{ CONSTANTS.table.messagesReceived }}
        </th>

        <td mat-cell
            *matCellDef="let inbox"
            class="messages-received"
            (click)="onViewMessages($event, inbox)"
        >
          <div class="flex-row">
            <span class="content-wrapper">
              <span class="messages-received-content">
                {{ inbox.messagesReceived }}
                <mat-icon class="messages-received-content-icon">chevron_right</mat-icon>
              </span>
            </span>
          </div>
        </td>
      </ng-container>

      <!-- Edit options column -->
      <ng-container matColumnDef="editOptions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let inbox" align="right">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="more-menu-btn">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="email-option-menu"
                    backdropClass="no-filter">
            <button mat-menu-item
                    class="table-action-icon"
                    (click)="onEditInbox($event, inbox)"
                    matTooltip="{{CONSTANTS.table.edit}}"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250">
              <mat-icon>edit</mat-icon>
              {{ CONSTANTS.table.edit }}
            </button>
            <button mat-menu-item
                    class="table-action-icon"
                    (click)="copyInbox($event, inbox)"
                    matTooltip="{{CONSTANTS.table.duplicateInbox}}"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250">
              <mat-icon>content_copy</mat-icon>
              {{ CONSTANTS.table.duplicateInbox }}
            </button>
            <button mat-menu-item
                    class="table-action-icon"
                    *ngIf="isAdmin"
                    (click)="onViewChangeLog($event, inbox)"
                    matTooltip="{{CONSTANTS.table.changeLog}}"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250">
              <mat-icon>restore</mat-icon>
              {{ CONSTANTS.table.changeLog }}
            </button>
            <button mat-menu-item
                    class="table-action-icon"
                    (click)="onDeleteInbox(inbox)"
                    matTooltip="{{CONSTANTS.table.delete}}"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250">
              <mat-icon>delete</mat-icon>
              {{ CONSTANTS.table.delete }}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <!-- Expanded Content Column -->
      <ng-container matColumnDef="expanded-detail">
        <td mat-cell *matCellDef="let inbox" [attr.colspan]="displayedColumns.length">
          <div class="expanded-row-detail"
               #details
               [@expand]="{
                 value: inbox === expandedInbox ? (loading ? 'loading' : 'expanded') : 'collapsed',
                 params: {
                  height: this.lastOpenedNestedTableHeight ? this.lastOpenedNestedTableHeight + 'px' : '*'
                 }
               }"
               (@expand.done)="animationDone($event, details)"
          >

            <textarea *ngIf="clicked === 'notes' && inbox === expandedInbox"
                      (keyup)="debouncedUpdateNote($event, inbox)"
                      placeholder="Notes could include:
- a description of what email inbox is used for
- changes to settings
- a list of to do items"
            >{{ inbox.notes }}</textarea>
            <div class="notes-saved-indicator" *ngIf="noteSaved">
              <mat-icon>check</mat-icon>
              Saved
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row class="hoverable"
          *matRowDef="let inbox; columns: displayedColumns"
          [class.expanded]="expandedInbox === inbox"
          [class.notes-active]="expandedInbox === inbox"
      ></tr>
      <tr mat-row *matRowDef="let inbox; columns: ['expanded-detail']" [class.expanded]="expandedInbox === inbox"
          class="expandable"></tr>

      <tr *matNoDataRow>
        <td [colSpan]="displayedColumns.length">
          <div *ngIf="!loading" class="no-data">
            <span>No email inboxes matched applied filters.</span>
          </div>
        </td>
      </tr>

    </table>
    <mat-paginator class="styled-mat-paginator"
                   [class.hide-paginator]="loading || pagination.totalCount <= 100"
                   [length]="pagination?.totalCount"
                   [pageSize]="pagination?.pageSize"
                   [hidePageSize]="true">
    </mat-paginator>
  </div>
</div>
<div class="filtering-no-matches-zero-state" *ngIf="currentApiFilters.length > 0 && !loading && inboxes.length === 0">No
                                                                                                                      email
                                                                                                                      inboxes
                                                                                                                      match
                                                                                                                      the
                                                                                                                      current
                                                                                                                      filters.
</div>
<email-inboxes-zero-state [loading]="loading"
                          [showZeroState]="!loading && inboxes.length === 0 && currentApiFilters.length === 0"
                          [isReadOnly]="isReadOnly"
                          (onCreate)="onCreate()"
>
</email-inboxes-zero-state>
