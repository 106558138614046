import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { arc } from 'd3-shape';

export const opCircleProgressNgName = 'opCircleProgressNg';

@Component({
  selector: 'op-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
})
export class CircleProgressComponent implements OnChanges {
  DEFAULT_TEXT_COLOR = '#5B5B5B';
  OUTER_RADIUS = 45;

  arcGenerator = arc();
  progressArc: string;
  value: number;

  @Input() percentage: number; // should be a floating point number between 0 and 1
  @Input() rawValue?: number; // if the value should be represented by something other than the percentage
  @Input() message?: string;
  @Input() showValue: boolean = true;
  @Input() showPercentageSign: boolean = true;
  @Input() strokeRadiusPercentage: number = 20; // How much of the radius the stroke width will consume
  @Input() bgArcColor: string;
  @Input() fgArcColor: string;
  @Input() valueColor?: string;
  @Input() messageColor?: string;

  ngOnChanges(changes: SimpleChanges) {
    this.update();
  }

  update(): void {
    this.progressArc = this.getProgressArc(this.percentage);
    if (this.rawValue >= 0 || this.percentage >= 0) {
      this.value = Number.isInteger(this.rawValue)
        ? this.rawValue : Math.round(this.percentage * 100);
      }
  }

  private getProgressArc(percentage: number): string {
    return this.arcGenerator({
      innerRadius: (1 - this.strokeRadiusPercentage / 100) * this.OUTER_RADIUS,
      outerRadius: this.OUTER_RADIUS,
      startAngle: 0,
      endAngle: Math.PI * 2 * percentage,
      padAngle: 0,
    });
  }
}
