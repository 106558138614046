<div class="usage-chart-with-table">
  <div class="usage-chart">
    <div class="usage-chart-heading">
      <div class="usage-chart-heading-main">
        Completed or Scheduled {{openTabType === EUsageTabTitleChartTypes.Audit ? 'Pages Audited Per Day' : 'Web Journey Runs'}}
        <mat-icon class="info-icon" matTooltip="In-progress audits will not show in this report">
          info_outline
        </mat-icon>
      </div>
      <!--
        Commenting out for WORK-28753
        -
        This can be added back in once the backend provides the data
      -->
      <!-- <div class="usage-chart-subheading">
        {{ amount }} concurrent {{openTabType === EUsageTabTitleChartTypes.Audit ? 'audit' : 'web journey'}} runs allowed for your account
      </div> -->
    </div>
    <div class="seven-day-audit-runs-chart">
      <usage-v2-bar-chart uniqueIdentifier="completed-or-scheduled-audit-runs-{{openTabType}}"
                       [data]="completedOrScheduledItems"
                       [showYAxis]="false"
                       [uppercaseText]="true"
                       [isClickable]="true"
                       tooltipText="View {{openTabType | lowercase}} on this day"
                       (clicked)="scrollUsageTable($event)">
      </usage-v2-bar-chart>
    </div>
  </div>
  <div class="divider"></div>
  <div class="usage-table">
    <div class="usage-chart-heading">
      <div class="usage-chart-heading-main">
        Completed or Scheduled {{openTabType === EUsageTabTitleChartTypes.Audit ? 'Audits' : 'Web Journeys'}}
      </div>
      <div class="usage-chart-subheading">
        Showing only the next 100 scheduled or completed {{openTabType === EUsageTabTitleChartTypes.Audit ? 'audits' : 'web journeys'}} per day
      </div>
    </div>
    <usage-v2-rolling-date-table [openTabType]="openTabType" [tableData]="dailyUsageData" #scrollable></usage-v2-rolling-date-table>
  </div>
</div>
