<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header
      title="Share Folders"
      (onClose)="close()">
    </op-modal-header>
  </ng-template>
  
  <ng-template #bodyTemplate>
    <div class="share-folders">
      <div class="top-table-items">
        <p class="folder-access-message">
          Which folders would you like to share?
        </p>
        <mat-form-field>
          <mat-label>Search Folders</mat-label>
          <input matInput (keyup)="filterRows($event.target.value)">
        </mat-form-field>
      </div>
      <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z3">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (click)="masterToggle(); onCheckboxChecked($event)"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          color="primary">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="selection.toggle(row); onCheckboxChecked($event)"
                          [checked]="selection.isSelected(row)"
                          color="primary">
            </mat-checkbox>
          </td>
        </ng-container>
        <!-- Folder Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Folder Name</th>
          <td mat-cell *matCellDef="let folder">{{ folder.name }}</td>
        </ng-container>
        <!-- Created At Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let folder">{{ folder.createdAt }}</td>
        </ng-container>
        <!-- Created By Column -->
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
          <td mat-cell *matCellDef="let folder">{{ folder.createdBy }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </ng-template>
</op-modal>
