import {AngularNames, Events, Names} from '@app/moonbeamConstants';


import {IReprocessRulesScope} from './reprocessRulesBanner';
import {IDataSelectorStateParams} from '../../../../moonbeamModels';
import {IReprocessBannerUpdate, IReprocessService, ReprocessBannerStatus} from './reprocessService';
import {IAuditDataService} from '../../../domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import {IEventManager} from '../../../eventManager/eventManager';
import {IAuditStatus} from '../statusBannerService';


export class ReprocessRulesBannerController {

    viewReport: (id) => void;
    auditId: number;
    runId: number;
    isReprocessRulesInProgress: boolean = false;
    isReprocessRulesDone: boolean = false;

    statusUpdatesSubscriptionID: number;

    static $inject = [
      AngularNames.scope,
      AngularNames.stateParams,
      Names.Services.reprocess,
      Names.Services.auditData,
      Names.Services.eventManager
    ];

    constructor(
      private $scope: IReprocessRulesScope,
      private $stateParams: IDataSelectorStateParams,
      private reprocessService: IReprocessService,
      private auditDataService: IAuditDataService,
      private eventManager: IEventManager
    ) {
      // this.init();
    }

    private init(): void {
      this.subscribeOnAuditStatusUpdates();
      this.unSubscribeFromAuditStatusUpdatesOnExit();
      this.checkAuditStatusProgress(parseInt(this.$stateParams.auditId), this.$scope.runId);
      this.processRunIdChanging();
    }

    private processRunIdChanging(): void {
      this.$scope.$watch(() => {
        return this.$scope.runId;
      }, (newRunId, oldRunId) => {
        if (typeof oldRunId == 'undefined' || newRunId == oldRunId) return;
        this.processStatusUpdates(ReprocessBannerStatus.hidden);
        this.checkAuditStatusProgress(parseInt(this.$stateParams.auditId), newRunId);
      });
    }

    private checkAuditStatusProgress(auditId: number, runId: number): void {
      this.reprocessService.getAuditStatusProgress(auditId, runId).then((update: IAuditStatus) => {
        if (!update.finished && update.pagesVerified && !update.rulesVerified) {
          this.processStatusUpdates(ReprocessBannerStatus.inProgress);
        } else {
          this.processStatusUpdates(ReprocessBannerStatus.hidden);
        }
      });
    }

    private subscribeOnAuditStatusUpdates(): void {
      this.statusUpdatesSubscriptionID = this.eventManager.subscribe(Events.reprocessRulesBannerUpdated, (update: IReprocessBannerUpdate) => {
        if (update.auditId != parseInt(this.$stateParams.auditId) || update.runId != this.$scope.runId) return;
        this.processStatusUpdates(update.status);
      });
    }

    private unSubscribeFromAuditStatusUpdatesOnExit(): void {
       this.$scope.$on(Events.destroy, () => {
        this.eventManager.unSubscribe(Events.reprocessRulesBannerUpdated, this.statusUpdatesSubscriptionID);
      });
    }

    private processStatusUpdates(status: ReprocessBannerStatus): void {
      switch (status) {
        case ReprocessBannerStatus.inProgress:
          this.showInProgressBanner();
          break;
        case ReprocessBannerStatus.done:
          this.showDoneBanner();
          break;
        case ReprocessBannerStatus.hidden:
          this.hideBanner();
          break;
      }
    }

    private hideBanner(): void {
      this.isReprocessRulesDone = false;
      this.isReprocessRulesInProgress = false;
    }
    private showInProgressBanner(): void {
      this.isReprocessRulesDone = false;
      this.isReprocessRulesInProgress = true;
    }
    private showDoneBanner(): void {
      this.isReprocessRulesDone = true;
      this.isReprocessRulesInProgress = false;
    }

    showReport(): void {
      this.auditDataService.reinitStore(this.auditId);
      this.viewReport({id: this.runId});
    }

}

