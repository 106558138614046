import { downgradeInjectable } from '@angular/upgrade/static';
import { Names } from '@app/moonbeamConstants';
import { authenticationInterceptor } from './authenticationInterceptor';
import * as angular from 'angular';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { AuthenticationService } from '@app/components/core/services/authentication.service';

export default angular.module('authenticationModule', [])
  .factory(Names.Services.authenticationInterceptor, authenticationInterceptor)
  .service(Names.NgServices.authenticationStorage, downgradeInjectable(AuthenticationStorageService))
  .service(Names.NgServices.authentication, downgradeInjectable(AuthenticationService))
  .name;
