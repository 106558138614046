<div class="status-list-container" [class.hide]="!currentStatusList">
  <div class="status-list-unavailable" *ngIf="currentStatusList?.length === 0">Status unavailable for this audit</div>
  <ul>
    <li *ngFor="let status of currentStatusList">{{status}}</li>
  </ul>
</div>
<div class="flex-col flex-stretch" (click)="setStatusList(null, $event)">
  <mat-tab-group disableRipple [mat-stretch-tabs]="false">
    <mat-tab label="Running ({{auditsRunning?.length}})">
      <!-- Running -->
      <div class="system-status-zero-state" *ngIf="auditsRunning?.length === 0">
        No audits running
      </div>
      <div class="flex-col flex-stretch system-status-tab-content-wrapper"
           [class.hide-element]="auditsRunning?.length === 0">
        <div class="control-panel">
          <div class="table-filter-input">
            <op-clearable-input placeholderText="Enter a value to search by..."
                                [emitKeyboardEvent]="true"
                                appearance="OUTLINED"
                                (onUserInput)="debounceRunningFilterData($event)"></op-clearable-input>
            <div class="show-columns-container">
            </div>
            <mat-form-field class="icon-dropdown-wrapper no-underline no-select-arrow">
              <mat-label class="icon-dropdown-label"><mat-icon style="font-size: 30px;" class="dropdown-icon">visibility</mat-icon></mat-label>
              <mat-select #selector class="hide-column-trigger" [panelClass]="'hide-column-panel'">
                <mat-option (click)="toggleAllColumns('running', true); selector.open();">Show all</mat-option>
                <mat-option (click)="toggleAllColumns('running', false); selector.open();">Hide all</mat-option>
                <mat-option *ngFor="let col of runningColumns"
                            (click)="col.show = !col.show; prepareRunningCols(); selector.open();">
                  <mat-icon>{{col.show ? 'visibility' : 'visibility_off'}}</mat-icon>&nbsp;{{col.header}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="action-button-group" *ngIf="userIsSysAdmin">
            <button mat-stroked-button
                    color="primary"
                    class="action-button"
                    [class.disabled]="selection.selected.length === 0"
                    [disabled]="selection.selected.length === 0"
                    (click)="pauseAudits()">Pause</button>
            <button mat-stroked-button
                    color="primary"
                    class="action-button"
                    [class.disabled]="selection.selected.length === 0"
                    [disabled]="selection.selected.length === 0"
                    (click)="resumeAudits()">Resume</button>
            <button mat-stroked-button
                    color="primary"
                    class="action-button"
                    [class.disabled]="selection.selected.length === 0"
                    [disabled]="selection.selected.length === 0"
                    (click)="verifyAudits()">Verify</button>
            <button mat-stroked-button
                    color="primary"
                    class="action-button"
                    [class.disabled]="selection.selected.length === 0"
                    [disabled]="selection.selected.length === 0"
                    (click)="stopAudits()">Stop</button>
            <button mat-stroked-button
                    color="primary"
                    class="action-button"
                    [class.disabled]="selection.selected.length === 0"
                    [disabled]="selection.selected.length === 0"
                    (click)="restartAudits()">Restart</button>
            <button mat-stroked-button
                    color="primary"
                    class="action-button"
                    [class.disabled]="selection.selected.length === 0"
                    [disabled]="selection.selected.length === 0"
                    (click)="removeAuditsRuns()">Remove runs</button>
          </div>
        </div>
        <div class="system-status-table-wrapper">
          <table mat-table
               matSort
               #runningTable
               [dataSource]="runningDataSource"
               multiTemplateDataRows
               [class.hide-element]="runningDisplayedColumns.length === 0"
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <div class="no-sort-column-header-content-wrapper">
                <mat-checkbox (click)="masterToggle(); $event.stopPropagation();"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              color="primary">
                </mat-checkbox>
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <mat-checkbox (click)="onCheckboxChecked($event, row)"
                            [checked]="selection.isSelected(row)"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="runId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Run Id
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                ngxClipboard
                [cbContent]="row.runId"
                (click)="showSnackbar()"
                [matTooltip]="'Click to copy to clipboard'"
                matTooltipPosition="above"
                class="copy-message"
            >
              <span>
                {{row.runId}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="auditId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Audit Id
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                ngxClipboard
                [cbContent]="row.auditId"
                (click)="showSnackbar()"
                [matTooltip]="'Click to copy to clipboard'"
                matTooltipPosition="above"
                class="copy-message"
            >
              <span>
                {{row.auditId}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="accountId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Account Id
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                ngxClipboard
                [cbContent]="row.accountId"
                (click)="showSnackbar()"
                [matTooltip]="'Click to copy to clipboard'"
                matTooltipPosition="above"
                class="copy-message"
            >
              <span>
                {{row.accountId}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Account
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle" (click)="goToAccountHandler(row, $event)">
              <span class="is-clickable">
                {{row.company}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Name
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                class="med-column"
                matTooltip="{{row.description}}"
                matTooltipShowDelay="1200"
                matTooltipPosition="above"
                class="copy-message"
                (click)="goToAuditHandler(row, $event)">
              <span class="is-clickable">
                {{row.description}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Status
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle" (click)="setStatusListHandler(row, $event)">
              <span class="is-clickable">
                {{row.status}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="open">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Open
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.open}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="pending">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Pending
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.pending}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="visited">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Completed
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.visited}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="pageLimit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Page Limit
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.pageLimit}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="speed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Engines
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.speed}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Started At
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.createdAt}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="frequency">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Frequency
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.frequency}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="dataLayer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Data Layer
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.dataLayer}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Actions
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle" (click)="goToAuditHandler(row, $event)">
              <span class="is-clickable">
                {{row.actions}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="loginActions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Pre-audit Actions
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              {{row.loginActions}}
            <
          </ng-container>
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Location
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <ng-container>
                <span class="flag-icon flag-icon-{{ row?.location?.countryCode }}"></span>
                <span class="location-label">{{ row?.location?.label }}</span>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row
              class="system-status-table-row"
              *matHeaderRowDef="runningDisplayedColumns; sticky: true;">
          </tr>
          <tr mat-row *matRowDef="let row; columns: runningDisplayedColumns;">
          </tr>
        </table>
        </div>
        <div *ngIf="loadingRunning" class="spinner-wrapper">
          <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Queued ({{auditsInQueue?.length}})">
      <!-- In Queue -->
      <div class="system-status-zero-state" *ngIf="auditsInQueue?.length === 0">
        No audits queued
      </div>
      <div class="flex-col flex-stretch"
           [class.hide-element]="auditsInQueue?.length === 0">
        <div class="control-panel">
          <op-clearable-input placeholderText="Enter a value to search by..."
                              [emitKeyboardEvent]="true"
                              appearance="OUTLINED"
                              class="table-filter-input"
                              (onUserInput)="debounceQueuedFilterData($event)"></op-clearable-input>

          <mat-form-field floatLabel="never" class="icon-dropdown-wrapper no-underline no-select-arrow">
            <mat-label class="icon-dropdown-label"><mat-icon style="font-size: 30px;" class="dropdown-icon">visibility</mat-icon></mat-label>
            <mat-select #queuedSelector class="hide-column-trigger" [panelClass]="'hide-column-panel'">
              <mat-option (click)="toggleAllColumns('queued', true); queuedSelector.open();">Show all</mat-option>
              <mat-option (click)="toggleAllColumns('queued', false); queuedSelector.open();">Hide all</mat-option>
              <mat-option *ngFor="let col of queuedColumns"
                          (click)="col.show = !col.show; prepareQueuedCols(); queuedSelector.open();">
                <mat-icon>{{col.show ? 'visibility' : 'visibility_off'}}</mat-icon>&nbsp;{{col.header}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="system-status-table-wrapper">
          <table mat-table
               matSort
               #queuedTable
               [dataSource]="queuedDataSource"
               multiTemplateDataRows
               class="system-status-table"
        >
          <ng-container matColumnDef="auditId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Audit Id
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                ngxClipboard
                [cbContent]="row.auditId"
                (click)="showSnackbar()"
                [matTooltip]="'Click to copy to clipboard'"
                matTooltipPosition="above"
                class="copy-message"
            >
              <span>
                {{row.auditId}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="accountId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Account Id
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                ngxClipboard
                [cbContent]="row.accountId"
                (click)="showSnackbar()"
                [matTooltip]="'Click to copy to clipboard'"
                matTooltipPosition="above"
                class="copy-message"
            >
              <span>
                {{row.accountId}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="company">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Account
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                (click)="goToAccountHandler(row, $event)"
            >
              <span class="is-clickable">
                {{row.company}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Name
              </div>
            </th>
            <td mat-cell
                *matCellDef="let row"
                valign="middle"
                (click)="goToAuditHandler(row, $event)"
            >
              <span class="is-clickable">
                {{row.description}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="pageLimit">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Page Limit
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.pageLimit}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="runningCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                # Of Items Currently Running
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.runningCount}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="maxConcAudits">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Account Run Limit
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.maxConcAudits}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="run">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Times run
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.run}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="dataLayer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Data Layer
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.dataLayer}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Actions
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.actions}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="loginActions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Pre-audit Actions
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <span>
                {{row.loginActions}}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="column-header-content-wrapper">
                Location
              </div>
            </th>
            <td mat-cell *matCellDef="let row" valign="middle">
              <ng-container>
                <span class="flag-icon flag-icon-{{ row?.location?.countryCode }}"></span>
                <span class="location-label">{{ row?.location?.label }}</span>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row
              class="system-status-table-row"
              *matHeaderRowDef="queuedDisplayedColumns; sticky: true;">
          </tr>
          <tr mat-row *matRowDef="let row; columns: queuedDisplayedColumns;">
          </tr>
        </table>
        </div>
        <div *ngIf="loadingRunning" class="spinner-wrapper">
          <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
