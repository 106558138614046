import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConsentCategoriesUIConstants } from '@app/components/consent-categories/consent-categories.constants';
import { StorageService } from '@app/components/shared/services/storage.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-zero-state',
  templateUrl: './cc-zero-state.component.html',
  styleUrls: ['./cc-zero-state.component.scss']
})
export class ConsentCategoriesZeroStateComponent {
  CONSTANTS = {...ConsentCategoriesUIConstants};

  @Input() loading: boolean = false;
  @Input() showZeroState: boolean = false;

  @Output() openConsentCategoryModal: EventEmitter<any> = new EventEmitter();
  @Output() openConsentCategoryImportModal: EventEmitter<any> = new EventEmitter();
  @Output() openConsentCategorySyncOneTrust: EventEmitter<any> = new EventEmitter();

  createCCMenu = [
    {
      label: this.CONSTANTS.importCategorizedCookies,
      action: () => this.openImportModal()
    },
    {
      label: this.CONSTANTS.syncWithOneTrust_Part1,
      labelIcon: 'onetrust-logo',
      labelAfterIcon: this.CONSTANTS.syncWithOneTrust_Part2,
      postLabel: 'NEW',
      hidden: false,
      action: () => this.openOneTrustImportModal(),
    },
    {
      label: this.CONSTANTS.createNew,
      action: () => this.openCCModal()
    }
  ];

  constructor(private storageService: StorageService,
  ) {
  }

  openCCModal() {
    if (this.openConsentCategoryModal) {
      this.openConsentCategoryModal.emit({});
    }
  }

  openOneTrustImportModal(){
    if(this.openConsentCategorySyncOneTrust) {
      this.openConsentCategorySyncOneTrust.emit({})
    }
  }

  openImportModal(){
    if(this.openConsentCategoryImportModal) {
      this.openConsentCategoryImportModal.emit({})
    }
  }
}
