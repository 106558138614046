import { MaterialModule } from '@app/components/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TerminateActiveRunsModalComponent } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.component';
import { SharedModule } from '@app/components/shared/shared.module';
import { OpModalModule } from '@app/components/shared/components/op-modal/op-modal.module';
import { TerminateActiveRunsModalService } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';

@NgModule({
  declarations: [
    TerminateActiveRunsModalComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    OpModalModule
  ],
  providers: [
    TerminateActiveRunsModalService
  ]
})
export class TerminateActiveRunsModalModule {}
