<op-filter-bar
  [class.shadowed]="isScrolled"
  [menuItems]="menuConfig"
  [service]="filterBarSvc"
  [isSearchByTextEnabled]="pageUrlInputEnabled"
  [validFilterTypes]="validFilterTypes"
  searchByTextPlaceholderSuffix="URL"
  (searchByTextIsFocused)="handlePageDetailsDrawer()"
  (searchByTextIsEntered)="handleSearchByUrl($event)"
  (invertableFilterToggled)="handleInvertableFilterToggled($event)">
  <mat-checkbox [checked]="searchInInitialURLs"
                color="primary"
                (click)="onClickUrlOptions($event, 'searchInInitialURLs')"
                (focusin)="filterBar.searchByTextFocused()"
                (focusout)="filterBar.searchByTextBlurred()"
                (mousedown)="filterBar.searchByTextMouseDown()"
                tabindex="2"
  >Initial URL</mat-checkbox>
  <mat-checkbox [checked]="searchInFinalURLs"
                color="primary"
                (click)="onClickUrlOptions($event, 'searchInFinalURLs')"
                (focusin)="filterBar.searchByTextFocused()"
                (focusout)="filterBar.searchByTextBlurred()"
                (mousedown)="filterBar.searchByTextMouseDown()"
                tabindex="2"
  >Final URL</mat-checkbox>
  <div class="and-operator">
    <span>AND</span>
  </div>
  <hr>
</op-filter-bar>
