<component-template componentName="OP Menu">
  <component-library-section sectionName="Overview" selector="<op-menu>">
    <p>This is the op menu component. It's an older component that is only used in a few places in the app.</p>

    <op-menu [items]="menuItems"></op-menu>
  </component-library-section>

  <component-library-section sectionName="Horizontal 3 Dot Icon" selector='<op-menu [horizontal]="true">'>
    <op-menu [items]="menuItems" [horizontal]="true"></op-menu>
  </component-library-section>

  <component-library-section sectionName="Custom Icon" selector='<op-menu icon="settings">'>
    <op-menu [items]="menuItems" icon="settings"></op-menu>
  </component-library-section>
</component-template>
