import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheResetService, ECacheResetEvent } from '@app/components/core/services/cache-reset.service';
import { ApiService } from '@app/components/core/services/api.service';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import {
  ISendSharingLinkRequest,
  ISharingRequest,
  ISharingResponse,
  IVisitorLoginRequest,
  IVisitorLoginResponse
} from './share-links.models';
import { ShareLinksApiUrl } from './share-links.constants';

@Injectable({
  providedIn: 'root'
})
export class ShareLinksService {

  constructor(
    private apiService: ApiService,
    private authenticationStorageService: AuthenticationStorageService,
    private storageService: StorageService,
    private cacheResetService: CacheResetService
  ) { }

  getSharedOPToken(request: IVisitorLoginRequest): Observable<IVisitorLoginResponse> {
    this.storageService.clearSessionStorage();
    this.storageService.resetDefaults();

    return this.apiService.post(ShareLinksApiUrl.visitorLogin, request).pipe(
      tap((sharedToken: IVisitorLoginResponse) => {
        const expiresAt = Date.now() + sharedToken.bearerTokenExpiresInSeconds * 1000;
        const authorizationData = {
          token: sharedToken.bearerToken,
          id: sharedToken.visitorUserId,
          credentialsExpired: false,
          tokenExpired: false,
          expiresAt
        };
        this.authenticationStorageService.setVisitorAuth(authorizationData);
        this.cacheResetService.raiseEvent(ECacheResetEvent.loginAsVisitor);
      })
    );
  }

  sendSharedLink(sharedLinkId: string, sharingRequest: ISendSharingLinkRequest): Observable<ISharingResponse> {
    return this.apiService.post(ShareLinksApiUrl.sendSharedLinkUrl + `/${sharedLinkId}/send-email`, sharingRequest);
  }

  deleteSharedLink(sharedLinkId: string): Observable<ISharingResponse> {
    return this.apiService.delete(ShareLinksApiUrl.deleteSharedLinkUrl + `/${sharedLinkId}`);
  }

  getSharedLink(sharingRequest: ISharingRequest): Observable<ISharingResponse> {
    return this.apiService.post(ShareLinksApiUrl.sharedLinkUrl, sharingRequest);
  }

  updateSessionStorage(visitorLogin: IVisitorLoginResponse) {
    const expiresAt = Date.now() + visitorLogin.bearerTokenExpiresInSeconds * 1000;
    const authorizationData = {
      token: visitorLogin.bearerToken,
      id: visitorLogin.visitorUserId,
      credentialsExpired: false,
      tokenExpired: false,
      expiresAt
    };
    this.authenticationStorageService.setVisitorAuth(authorizationData);
    this.cacheResetService.raiseEvent(ECacheResetEvent.loginAsVisitor);
  }
}
