<form [formGroup]="formData">
  
  <div class="check-times-container">
    <div class="title-text">EVALUATION SETTING</div>
    <div class="input-container">
      <div class="input-placeholder">
        How many times should this rule be evaluated per audit page or journey action?
      </div>
  
      <mat-form-field class="styled-mat-input" appearance="outline">
        <input matInput formControlName="checkTimes" type="number" min="1" max="99">
      </mat-form-field>

      <span *ngIf="checkTimesControl.touched && checkTimesControl.invalid"
            class="input-error">
        <mat-icon>warning_amber</mat-icon>
        <span *ngIf="checkTimesControl.hasError('numberInteger'); else checkTimesRangeError">
          Should be a natural number
        </span>
        <ng-template #checkTimesRangeError>
          <span>Value must be a number greater than 0 and less than 100</span>
        </ng-template>
      </span>
    </div>
  </div>

  <ng-container *ngIf="allTags; else loader">
    <!-- IF -->
    <div class="conditions-container">
      <div class="title-text">
        <span>CONDITIONS </span>
        <span class="neutral-grey">(OPTIONAL)</span>
      </div>
      <div class="sub-title-text">
        Criteria that needs to be present for this rule to be evaluated.
        For example, use a regular expression to restrict this rule to product pages or only apply this rule if a specific page status code is present.
        For criteria that is not matched it will show up in the not applied section for reporting.
      </div>
    </div>

    <op-button-2021 labelText="Add condition"
                    matIcon="control_point"
                    buttonType="filled"
                    *ngIf="ifFiltersArray?.length < 1; else ifFilters"
                    (buttonAction)="addIfCondition()">
    </op-button-2021>

    <ng-template #ifFilters>
      <if-conditions [formData]="formData"
                    [allTags]="allTags"
                    [hidePageMatching]="hidePageMatching">
      </if-conditions>
    </ng-template>

    <!-- THEN -->
    <div class="then-conditions-container">
      <div class="conditions-container">
        <div class="title-text">EXPECTATIONS</div>
        <div class="sub-title-text">
          Data that is expected to be present in the specified tag(s) and variable(s).
        </div>
      </div>

      <then-conditions [formData]="formData" [allTags]="allTags"></then-conditions>
    </div>
  </ng-container>
  <ng-template #loader>
    <mat-spinner class="centered" [diameter]="75" [strokeWidth]="8"></mat-spinner>
  </ng-template>

</form>