<div class="step"
     [class.active]="currentStep === EEmailInboxStep.Inbox"
     (click)="onSelectStep1()">
  <div class="top">
    <span class="step-id">1. </span>
    <span class="step-title">Email Inbox
        <mat-icon class="step-icon"
                  matTooltipPosition="above"
                  [class.completed]="!step1Incomplete"
                  [class.visited]="isStepVisited(EEmailInboxStep.Inbox)"
                  [class.uncompleted]="step1Incomplete"
                  [matTooltip]="step1Tooltip"
        >{{ step1Incomplete && !isStepVisited(EEmailInboxStep.Inbox) ? 'warning' : 'check_circle_outline' }}</mat-icon>
      </span>
  </div>
  <div class="bottom">
    <div class="email-address">
      <div class="email-address-value">
        {{ emailInboxAddress }}
      </div>
      <op-copy-button
        class="copy-button"
        *ngIf="emailInboxAddress"
        [copyValue]="emailInboxAddress"
      ></op-copy-button>
    </div>
  </div>
</div>

<div class="step testing-step"
     [class.active]="currentStep === EEmailInboxStep.Testing"
     (click)="onSelectStep2()">
  <div class="top">
    <span class="step-id">2. </span>
    <span class="step-title">Testing Scenario(s)
        <mat-icon class="step-icon"
                  matTooltipPosition="above"
                  [matTooltip]="step2Tooltip"
                  [class.uncompleted]="step2Incomplete"
                  [class.visited]="isStepVisited(EEmailInboxStep.Testing)"
                  [class.completed]="!step2Incomplete"
        >{{ step2Incomplete && !isStepVisited(EEmailInboxStep.Testing) ? 'warning' : 'check_circle_outline' }}</mat-icon>
      </span>
  </div>
  <div *ngIf="configuredAudits > 0 && displayAuditsCount" class="bottom">
    {{ configuredAudits }} Configured Email Audit{{ configuredAudits > 1 ? 's' : ''}}
  </div>
</div>

<div class="step"
     [class.active]="currentStep === EEmailInboxStep.Notifications"
     (click)="onSelectStep3()">
  <div class="top">
    <span class="step-id">3. </span>
    <span class="step-title">Notifications
        <mat-icon class="step-icon"
                  matTooltipPosition="above"
                  [matTooltip]="step3Tooltip"
                  [class.uncompleted]="step3Incomplete"
                  [class.visited]="isStepVisited(EEmailInboxStep.Notifications)"
                  [class.completed]="!step3Incomplete"
        >{{ step3Incomplete && !isStepVisited(EEmailInboxStep.Notifications) ? 'warning' : 'check_circle_outline' }}</mat-icon>
      </span>
  </div>
  <div *ngIf="notificationEmailsCount" class="bottom">
    Single Audit Summary, and any triggered alerts will be sent to
    {{ notificationEmailsCount + (notificationEmailsCount > 1 ? ' people' : ' person') }}.
  </div>
</div>
