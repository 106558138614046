import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { IChipConfig } from '../op-chip-manager.models';

@Component({
  selector: 'op-chip',
  templateUrl: './op-chip.component.html',
  styleUrls: ['./op-chip.component.scss']
})
export class OpChipComponent implements OnInit {

  @Input() chip: any;
  @Input() chipConfig: IChipConfig;
  @Input() isReadOnly: boolean = false;

  // chip is being removed from list
  @Output() onChipRemoved: EventEmitter<any> = new EventEmitter();

  displayProperty: string;
  chipValue: string = '';

  constructor() {}

  ngOnInit(): void {
    this.chipValue = this.chip[this.chipConfig.chipDisplayProperty];
  }

  handleRemoveChip(): void {
    this.onChipRemoved.emit(this.chip);
  }
}