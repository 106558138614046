<!-- no alerts -- open alert designer -->
<ng-container *ngIf="alerts.length === 0">
  <alert-bell [status]="status"
              [showBellAlways]="true"
              [type]="EAlertBellType.Menu"
              [menuName]="'ALERTS'"
              (click)="$event.preventDefault(); $event.stopPropagation();"
              (onClick)="openCreateModal()">
  </alert-bell>
</ng-container>

<!-- if there are >= 1 alerts then show a menu -->
<ng-container *ngIf="alerts.length >= 1">
  <alert-bell [matMenuTriggerFor]="menu"
              [status]="status"
              [showCount]="true"
              [showBellAlways]="true"
              [count]="alertCount"
              [type]="EAlertBellType.Menu"
              [menuName]="'ALERTS'"
              (click)="$event.preventDefault(); $event.stopPropagation();"
              [tooltipPlaceholder]="tooltip">
  </alert-bell>
  <mat-menu #menu="matMenu"
            class="full-width-menu-dropdown usage-header-bell-menu-dropdown"
            backdropClass="no-filter">
    <button *ngFor="let alert of alerts" mat-menu-item type="button">
      <div class="widget-bell-menu-contents" [class.triggered-red]="alert.result?.status === 'TRIGGERED' && alert.config?.isUserSubscribed">
        <div class="widget-bell-menu-left" (click)="handleNameClick(alert)">
          <!-- exclamation ringing bell -->
          <mat-icon *ngIf="alert.result?.status === 'TRIGGERED' && alert.config?.isUserSubscribed"
                    svgIcon="bell-filled-triggered"
                    [matTooltip]="'You are subscribed to this triggered alert.'"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    class="custom-icon">
          </mat-icon>

          <!-- empty exclamation ringing bell -->
          <mat-icon *ngIf="alert.result?.status === 'TRIGGERED' && !alert.config?.isUserSubscribed"
                    svgIcon="bell-empty-triggered"
                    [matTooltip]="'You are not subscribed to this triggered alert.'"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    class="custom-icon">
          </mat-icon>
          <!-- checked bell -->
          <mat-icon *ngIf="alert.result?.status !== 'TRIGGERED' && alert.config?.isUserSubscribed"
                    svgIcon="bell-check"
                    [matTooltip]="'You are subscribed to this non-triggered alert.'"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
                    class="custom-icon">
          </mat-icon>
          <!-- empty bell -->
          <mat-icon *ngIf="alert.result?.status !== 'TRIGGERED' && !alert.config?.isUserSubscribed"
                    [matTooltip]="'You are not subscribed to this non-triggered alert.'"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
          >
            notifications_none
          </mat-icon>
          <div class="alert-name">
            {{ alert.config?.name }} alert
          </div>
          <mat-icon class="alert-redirect">arrow_forward</mat-icon>
        </div>
        <div class="widget-bell-menu-right">
          <span class="vertical-divider">|</span> <mat-icon class="alert-edit" (click)="openAlertEditor(alert)">edit</mat-icon>
        </div>
      </div>
    </button>
    <button class="create-new-alert-button" (click)="openCreateModal()" mat-menu-item type="button">
      <mat-icon>add_circle_outline</mat-icon>
      Create New Alert
    </button>
  </mat-menu>
</ng-container>
