<div class="web-vitals-chart">
  <div *ngIf="showTitle"
       class="chart-title">
    {{ WEB_VITALS_METRIC_NAMES[metricType] }}
    <mat-icon
      class="info-icon material-icons-outlined"
      [matTooltip]="WEB_VITALS_WIDGET_TOOLTIPS[metricType]"
      matTooltipPosition="above"
    >
      info
    </mat-icon>
  </div>
  <div *ngIf="loading" class="loading-area">
    <mat-spinner
      [diameter]="20"
      [strokeWidth]="3"
    >
    </mat-spinner>
  </div>
  <ng-container *ngIf="!loading">
    <div
      *ngIf="!(status === EWebVitalsWidgetStatus.NotAvailable || status === EWebVitalsWidgetStatus.NoData) else notCollected"
      class="chart-value status-{{ status }}"
    >
      <div #valueLabel
           class="chart-value-label"
           [class.absolute]="displayValueFollowsCircle"
      >{{ displayValue }}
      </div>
    </div>
    <ng-template #notCollected>
      <div class="chart-value">
        Not collected
        <mat-icon
          class="info-icon material-icons-outlined"
          [matTooltip]="tooltip"
          matTooltipPosition="above"
        >
          info
        </mat-icon>
      </div>
    </ng-template>
    <div class="chart-wrapper"
         [class.dimmed]="status === EWebVitalsWidgetStatus.NotAvailable || status === EWebVitalsWidgetStatus.NoData">
      <div *ngIf="showLeftThresholdValue"
           class="threshold-left">0
      </div>
      <div class="chart-bar-wrapper">
        <div class="rectangle-wrapper">
          <div class="rectangle rectangle-pass" [ngStyle]="{'width': 'calc('+ greenBarWidth + '% - 3px)'}">
            <div *ngIf="warnThreshold" class="threshold warn-threshold">{{ warnThreshold }}</div>
          </div>
          <div class="rectangle rectangle-warn" [ngStyle]="{'width': 'calc('+ yellowBarWidth + '% - 3px)'}">
            <div *ngIf="failThreshold" class="threshold fail-threshold">{{ failThreshold }}</div>
          </div>
          <div class="rectangle rectangle-fail" [ngStyle]="{'width': 'calc(' + redBarWidth + '% - 3px)'}"></div>
        </div>
        <div *ngIf="webVitalsAvailable"
             #circle
             class="data-point status-{{ status }}"
             [class.big]="biggerCircle"
             [style.borderColor]="borderColor"></div>
      </div>

      <ng-container *ngIf="showRightThresholdValue">
        <div *ngIf="rightThreshold" class="threshold-right">{{ rightThreshold }}</div>
      </ng-container>
    </div>
  </ng-container>
</div>
