import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { OpSelectCreateComponent } from '@app/components/shared/components/op-select-create/op-select-create.component';
import { InputMode } from '@app/components/shared/components/op-select-create/op-select-create.enum';
import { IButton } from '@app/models/commons';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'move-to-domain-modal',
  templateUrl: './move-to-domain-modal.component.html',
  styleUrls: ['./move-to-domain-modal.component.scss']
})
export class MoveToDomainModalComponent implements OnInit, OnDestroy {
  InputMode = InputMode;

  @ViewChild('selectCreateFolder') selectCreateFolder: OpSelectCreateComponent;
  @ViewChild('selectCreateDomain') selectCreateDomain: OpSelectCreateComponent;

  modalTitle: string = '';
  folders: IFolder[] = [];
  domains: IDomain[] = [];
  excludeFolderIDs: number[];
  excludeSubfolderIDs: number[];
  availableDomains: IDomain[] = [];
  moveDomainForm: UntypedFormGroup;

  rightFooterButtons: IButton[] = [
    {
      label: 'Move',
      action: () => this.closeModal(this.moveDomainForm.value),
      primary: true,
      disabled: true
    }
  ];

  private destroy$ = new Subject();

  constructor(
    private dialogRef: MatDialogRef<MoveToDomainModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private folderService: IFoldersApiService,
    private domainService: IDomainsService,
    private fb: UntypedFormBuilder
  ) {
    const plural = this.data.selectedCardsCount > 1 ? 's' : '';
    this.modalTitle = `Move ${this.data.selectedCardsCount} item${plural} to Folder/Sub-folder`;
    this.excludeFolderIDs = this.data.excludeFolderIDs;
    this.excludeSubfolderIDs = this.data.excludeSubfolderIDs;
  }

  ngOnInit(): void {
    forkJoin([
      this.folderService.getFolders(),
      this.domainService.getAllDomains()
    ])
      .subscribe(([folders, domains]) => {
        this.folders = folders;
        this.domains = domains;

        if(this.excludeFolderIDs) {
          this.folders = this.folders.filter(folder => !this.excludeFolderIDs.includes(folder.id));
        }
        if(this.excludeSubfolderIDs) {
          this.domains = this.domains.filter(domain => !this.excludeSubfolderIDs.includes(domain.id));
        }

        // If zero state or we filtered out all available folders then put in create folder mode.
        if(this.folders.length < 1) {
          this.onSwitchFolderMode(InputMode.Create);
        }

        this.folders.sort((a, b) => a.name.localeCompare(b.name));
        this.domains.sort((a, b) => a.name.localeCompare(b.name));
      });

    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  initForm(): void {
    this.moveDomainForm = this.fb.group({
      folder: [null, Validators.required],
      domain: [null, Validators.required],
      dataLayer: [null],
    });

    this.moveDomainForm.get('domain').disable();

    this.moveDomainForm.get('folder').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(folder => {
        if (folder) {
          this.moveDomainForm.get('domain').enable();
        } else {
          this.moveDomainForm.get('domain').disable();
        }
      });

    this.moveDomainForm
      .valueChanges
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe(() => {
        this.rightFooterButtons[0].disabled = this.moveDomainForm.invalid;
      });
  }

  onSwitchFolderMode(mode: InputMode): void {
    this.selectCreateFolder.mode = this.selectCreateDomain.mode = mode;
    this.domain.setValue(null);

    if (mode === InputMode.Create) {
      this.availableDomains = [];
    }
  }

  onFolderChange(folder: IFolder): void {
    this.getDomainsOfFolder(folder.id);

    this.selectCreateDomain.mode = !this.availableDomains.length ? InputMode.Create : InputMode.Select;
    this.domain.setValue('');
  }

  getDomainsOfFolder(folderId: number): void {
    this.availableDomains = this.domains.filter((domain: IDomain) => domain.folderId === folderId);
  }

  isCreateMode(mode: InputMode): boolean {
    return mode === InputMode.Create;
  }

  closeModal(values?: any): void {
    this.dialogRef.close(values);
  }

  get folder(): AbstractControl {
    return this.moveDomainForm.get('folder');
  }

  get domain(): AbstractControl {
    return this.moveDomainForm.get('domain');
  }
}
