import { ReplaySubject } from 'rxjs';
import { BehaviorSubject, forkJoin, Observable, Subject, from } from 'rxjs';
import { debounceTime, filter, first, take, takeUntil, tap } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { guest } from '@app/authUtils';
import { IUser } from '@app/moonbeamModels';
import { AlertsLibraryUrlBuilders } from '@app/components/alerts-library/alerts-library.constants';
import { AlertService } from '@app/components/alert/alert.service';
import { TriggeredAlertsFilterBarService } from '@app/components/triggered-alerts/triggered-alerts-filter-bar/triggered-alerts-filter-bar.service';
import { IAlertsSearchAssignmentsResponse } from '@app/components/alert/alert.models';
import { AccountsService } from '@app/components/account/account.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { EAlertFilterType, EAlertMenuContext, EAlertModalType } from '@app/components/alert/alert.enums';
import { AlertMetricChangeOperators, AlertMetricThresholdOperators } from '../alert/alert-logic/alert-logic.constants';
import { AuditReportFilterBarService } from '../audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { AlertComponent } from '../alert/alert.component';
import { IDomain, IDomainsService } from '../domains/domainsService';
import { IOpFilterBarV2Filter } from '../shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EFilterSpinnerState } from '../shared/components/filter-spinner/filter-spinner.constants';
import { EColorHighlightMeaning } from '../shared/components/viz/color-highlight/color-highlight.directive';
import { TriggeredAlertsService } from './triggered-alerts.service';
import { IAccountTriggeredAlertDTO, IAccountTriggeredAlertsDTO, IAccountTriggeredAlertsSummaryDTO, ITriggeredAlertsSearchBody } from './triggered-alerts.models';
import { ITriggeredAlertsTablePagination, ITriggeredAlertsTableSort, ITriggeredAlertTableEditPayload, ITriggeredAlertTableRow } from './triggered-alerts-table/triggered-alerts-table.models';
import { ETriggeredAlertsFilterTypes } from './triggered-alerts-filter-bar/triggered-alerts-filter-bar.constants';
import { IOpSummaryCardIconConfig } from '../shared/op-summary-card/op-summary-card.models';
import { ExportTriggeredAlertsSnackbarComponent } from './export-triggered-alerts-snackbar/export-triggered-alerts-snackbar.component';
import { IExportTriggeredAlertsSnackbarPayload } from './export-triggered-alerts-snackbar/export-triggered-alerts-snackbar.models';
import { triggeredAlertsTableDefaultPagination, triggeredAlertsTableDefaultSorting } from './triggered-alerts-table/triggered-alerts-table.constants';
import { IOpFilterBarItemDatepickerRangeState } from '../shared/components/date-range-filter/op-filter-bar-item-datepicker.models';
import { AlertReportingService } from '../alert/alert-reporting.service';
import { UsageUrlBuilders } from '@app/components/usage-v2/usage-v2.constants';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'triggered-alerts',
  templateUrl: './triggered-alerts.component.html',
  styleUrls: ['./triggered-alerts.component.scss']
})
export class TriggeredAlertsComponent implements OnInit, OnDestroy {

  dataLoadedSubject = new BehaviorSubject<boolean>(false);
  folderIdToDomainsIdsMap = new Map<number, number[]>();
  domainIdToFolderIdMap = new Map<number, number>();

  infoIsExpanded = false;
  userSubject = new ReplaySubject<IUser>();
  isReadOnly = false;

  filterableWidgetState: EFilterSpinnerState;
  nonFilterableWidgetState: EFilterSpinnerState;

  totalSummary: IAccountTriggeredAlertsSummaryDTO;
  filteredSummary: IAccountTriggeredAlertsSummaryDTO;

  configuredAlertsTotal: number;
  triggeredAlertsTotal: number;

  triggeredAlerts: ITriggeredAlertTableRow[];

  assignments: ITriggeredAlertsSearchBody = {};
  pagination = triggeredAlertsTableDefaultPagination;
  sortOptions = triggeredAlertsTableDefaultSorting;

  filterCount: number = 0;

  readonly usageAlertsWidgetIconConfig: IOpSummaryCardIconConfig = {
    iconClass: 'arrow_forward',
    appearOnHover: true
  };
  readonly EColorHighlightMeaning = EColorHighlightMeaning;

  private destroySubject = new Subject<void>();

  constructor(private router: Router,
              private snackbar: MatSnackBar,
              private modalService: OpModalService,
              private accountsService: AccountsService,
              private alertService: AlertService,
              private alertReportingService: AlertReportingService,
              private domainsService: IDomainsService,
              private auditReportFilterBarService: AuditReportFilterBarService,
              private triggeredAlertsFilterBarService: TriggeredAlertsFilterBarService,
              private triggeredAlertsService: TriggeredAlertsService) { }

  ngOnInit() {
    this.accountsService.getUser().subscribe(user => {
      this.userSubject.next(user);
      this.isReadOnly = user.permissions === guest;
    });

    // Folder & Subfolder has to be loaded before we subscribe to filters changed event
    forkJoin([
      this.getAlertsForAssignment(),
      this.getFoldersAndSubfolders()
    ]).subscribe(() => {
      this.subscribeToEvents();
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
    this.dataLoadedSubject.complete();
    this.userSubject.complete();
  }

  private reloadData() {
    this.filterableWidgetState = this.nonFilterableWidgetState = EFilterSpinnerState.Loading;

    forkJoin([
      this.getAlerts(),
      this.getAlertsSummary()
    ]).subscribe(() => {
      this.filterableWidgetState = EFilterSpinnerState.Filtered;
      this.nonFilterableWidgetState = EFilterSpinnerState.None;
    });
  }

  private getAlertsForAssignment(): Observable<IAlertsSearchAssignmentsResponse> {
    return this.alertService.getAlertsForAssignment({}, {}).pipe(
      tap(({ metadata }) => {
        this.configuredAlertsTotal = metadata.pagination.totalCount;
      })
    );
  }

  private getAlerts(): Observable<[IAccountTriggeredAlertsDTO, boolean]> {
    this.dataLoadedSubject.next(false);
    const payload = {
      size: this.pagination.pageSize,
      page: this.pagination.currentPageNumber,
      sortBy: this.sortOptions.sortBy,
      sortDesc: this.sortOptions.sortDesc
    };
    return forkJoin([
      this.triggeredAlertsService.getAlerts(payload, this.assignments),
      this.auditReportFilterBarService.loaded$.pipe(filter(v => !!v), first())
    ])
    .pipe(
      tap(([{ alerts, metadata: { pagination } }, _]) => {
        this.triggeredAlertsTotal = pagination.totalCount;
        this.triggeredAlerts = alerts.map(alert => this.mapToTableRow(alert));
        this.pagination = pagination;
        this.dataLoadedSubject.next(true);
      })
    );
  }

  private mapToTableRow(alert: IAccountTriggeredAlertDTO): ITriggeredAlertTableRow {
      let operator = AlertMetricChangeOperators.find(
        operator => operator.comparator === alert.targetValueComparator.operator && operator.changeType === alert.metricChangeType
      );
      if (!operator) operator = AlertMetricThresholdOperators.find(operator => operator.comparator === alert.targetValueComparator.operator);

      const targetValue = AlertUtils.convertToUIValue(alert.metricType, alert.metricChangeType, alert.targetValueComparator.targetValue);

      const filters = Object.entries(alert.filtersV0).map(([type, value]) => {
        return this.auditReportFilterBarService.getAuditFilterFromApiPostBodyFilter(type, value);
      }).filter(v => !!v);

      return {
        id: alert.id,
        name: alert.name,
        trigger: {
          metricType: alert.metricType,
          metricChangeType: alert.metricChangeType,
          operator,
          targetValue
        },
        filters,
        isUserSubscribed: alert.isUserSubscribed,
        subscriberCount: alert.subscriberCount,
        recentTriggeredDate: alert.recentTriggeredDate,
        dataSourceCount: alert.dataSourceCount,
        alertConfigExists: alert.alertConfigExists,
      };
  }

  private getAlertsSummary() {
    return forkJoin([
      this.triggeredAlertsService.getAlertsSummary(),
      this.triggeredAlertsService.getAlertsSummary(this.assignments)
    ]).pipe(
      tap(([totalSummary, filteredSummary]) => {
        this.totalSummary = totalSummary;
        this.filteredSummary = filteredSummary;
      })
    );
  }

  private getFoldersAndSubfolders(): Observable<IDomain[]> {
    return from(this.domainsService.getDomains()).pipe(
      tap(subfolders => {
        this.folderIdToDomainsIdsMap = subfolders.reduce((map, { id: domainId, folderId }) => {
          const domainsIds = map.get(folderId);
          return domainsIds
            ? map.set(folderId, [ ...domainsIds, domainId ])
            : map.set(folderId, [ domainId ]);
        }, new Map<number, number[]>());

        this.domainIdToFolderIdMap = subfolders.reduce((map, { id: domainId, folderId }) => {
          return map.set(domainId, folderId);
        }, new Map<number, number>());
      })
    );
  }

  private subscribeToEvents() {
    this.triggeredAlertsFilterBarService.filters$.pipe(
      takeUntil(this.destroySubject),
      debounceTime(100)
    ).subscribe(filters => this.filtersChanged(filters));
  }

  filtersChanged(filters: IOpFilterBarV2Filter<ETriggeredAlertsFilterTypes>[]) {
    this.assignments = filters.reduce((acc: ITriggeredAlertsSearchBody, filter) => {
      switch (filter.type) {
        case ETriggeredAlertsFilterTypes.Name:
          const alertName = filter.value[0] as string;
          return { ...acc, alertName };
        case ETriggeredAlertsFilterTypes.RunDateRange:
          const { dates } = filter.value[0] as IOpFilterBarItemDatepickerRangeState;
          return { ...acc, runDateRange: { from: dates.start, to: dates.end } };
        case ETriggeredAlertsFilterTypes.Label:
          const alertLabelIds = filter.value as number[];
          const alertLabels = acc.itemLabels ? [ ...acc.itemLabels, ...alertLabelIds ] : alertLabelIds;
          return { ...acc, alertLabels };
        case ETriggeredAlertsFilterTypes.DataSourceName:
          const itemName = filter.value[0] as string;
          return { ...acc, itemName };
        case ETriggeredAlertsFilterTypes.DataSourceLabel:
          const itemLabelIds = filter.value as number[];
          const itemLabels = acc.itemLabels ? [ ...acc.itemLabels, ...itemLabelIds ] : itemLabelIds;
          return { ...acc, itemLabels };
        case ETriggeredAlertsFilterTypes.Subscribed:
          const subscribedOnly = filter.value[0] as boolean;
          return { ...acc, subscribedOnly };
        case ETriggeredAlertsFilterTypes.Folder:
          /* we can't use this format directly in the op-filter-bar-v2 component because
           * it will be incompatible with other places where this filter is used (Home page)
           * for example, chosen filter on the Triggered Alerts page should be applied on the Home page as well
           */
          const domainIds = filter.value as number[];
          const filterMap = domainIds.reduce((map, domainId) => {
            const folderId = this.domainIdToFolderIdMap.get(domainId);
            const domainIds = map.get(folderId) ?? [];
            return map.set(folderId, [ ...domainIds, domainId ]);
          }, new Map<number, number[]>());
          const folders = [ ...filterMap.entries() ].map(([folderId, domains]) => ({
            folderId,
            // empty array if entire folder (all subfolders in a folder)
            domains: domains.length === this.folderIdToDomainsIdsMap.get(folderId).length ? [] : domains
          }));
          return { ...acc, folders };
        case ETriggeredAlertsFilterTypes.ReportMetric:
          const metricTypes = filter.value;
          return { ...acc, metricTypes };
        default:
          return acc;
      }
    }, {});
    this.pagination.currentPageNumber = 0;
    this.reloadData();
    this.filterCount = filters.length;
  }

  updateTableState(state: ITriggeredAlertsTablePagination & ITriggeredAlertsTableSort) {
    this.pagination.currentPageNumber = state.currentPageNumber;
    this.sortOptions.sortBy = state.sortBy;
    this.sortOptions.sortDesc = state.sortDesc;
    this.sortOptions.sortDir = state.sortDir;

    this.reloadData();
  }

  editAlert({ alert, step }: ITriggeredAlertTableEditPayload) {
    const data = {
      type: this.isReadOnly ? EAlertModalType.ReadOnly : EAlertModalType.Edit,
      alertId: alert.id,
      currentStep: step,
      menuContext: EAlertMenuContext.Triggered,
      filterType: this.alertReportingService.isAccountLevelMetric(alert.trigger.metricType) ? EAlertFilterType.V2 : EAlertFilterType.V1
    };

    this.modalService.openFixedSizeModal(AlertComponent, { disableClose: true, data, autoFocus: false })
      .afterClosed()
      .subscribe(updated => {
        if (updated) {
          this.updateTableState({...this.sortOptions, ...this.pagination});
        }
      });
  }

  goToAlertsLibrary() {
    this.router.navigateByUrl(AlertsLibraryUrlBuilders.library());
  }

  goToUsageReport() {
    this.router.navigateByUrl(UsageUrlBuilders.base());
  }

  exportTriggeredAlerts() {
    this.userSubject
      .pipe(take(1))
      .subscribe(({ email }) => {
        const searchParams = {
          size: this.pagination.pageSize,
          page: this.pagination.currentPageNumber,
          sortBy: this.sortOptions.sortBy,
          sortDesc: this.sortOptions.sortDesc
        };
        const snackbarPayload: IExportTriggeredAlertsSnackbarPayload = { email };

        this.triggeredAlertsService.exportTriggeredAlerts(searchParams, this.assignments).subscribe(() => {
          this.snackbar.openFromComponent(ExportTriggeredAlertsSnackbarComponent, {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: snackbarPayload,
            panelClass: 'triggered-alerts-export-snackbar',
          });
        });
      });
  }

  alertCreated() {
    this.configuredAlertsTotal += 1;
  }

}
