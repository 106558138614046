import {
  ChangeDetectionStrategy,
  Component
} from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'export-report-error-snackbar',
  templateUrl: './export-report-error-snackbar.component.html',
  styleUrls: ['./export-report-error-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportReportErrorSnackbarComponent {
}
