export enum EExportStatuses {
  notExported = 'notExported',
  generatingExport = 'generatingExport',
  emailSent = 'emailSent'
}

export enum TableColumns {
  Name = 'name',
  Description = 'description',
  Preview = 'preview',
  Export = 'export'
}
