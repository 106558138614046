import { WebJourneyReportUrlBuilders } from './../../web-journey-report/web-journey-report.constants';
import { IAuditStatus } from './../../reporting/statusBanner/statusBannerService';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { IWebJourneyRunProgress } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { IDataSelectorStateParams } from '@app/moonbeamModels';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StatusBannerProcessor } from '../../reporting/statusBanner/statusBannerProcessor';
import {
  IStatusBannerSettings,
  StatusBannerService,
  StatusUpdateType
} from '../../reporting/statusBanner/statusBannerService';
import { IStopRunService } from '../../stopRun/stopRunService';
import { Subscription } from '../../websockets/websocketService';
import { IStatusBarItem, StatusBarItemState } from '../status-progress-bar/status-progress-bar.models';
import { RouteDataService } from '@app/components/shared/services/route-data.service';
import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { IWebJourneyCard } from '@app/components/web-journey/web-journey.models';
import { CardTypes } from '@app/components/manage/cards/report-card-list/report-card-list.constants';
import { DataSourcesUtils } from '@app/components/utilities/dataSourcesUtils';
import { IAuditModel } from '@app/components/modals/modalData';
import { EAuditFrequency } from '@app/components/audit/audit.constants';
import { StorageService } from '@app/components/shared/services/storage.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'full-screen-status-banner',
  templateUrl: './full-screen-status-banner.component.html',
  styleUrls: ['./full-screen-status-banner.component.scss']
})
export class FullScreenStatusBannerComponent implements OnInit, OnChanges, OnDestroy {
  readonly dateFormat: string = EDateFormats.dateFifteen;
  audit: IAuditModel;

  @Input() updatesType: StatusUpdateType;
  @Input() shouldInit: boolean;
  @Input() runNowPending: boolean;
  @Output() runNowClicked: Subject<void> = new Subject<void>();

  resourceId: number;
  activeRunId: number;
  typeLabel: string;
  isPausedFlag: boolean = false;
  isScheduledFlag: boolean = false;
  @HostBinding('class.in-progress')
  inProgress: boolean = false;
  runStopped: boolean = false;

  recurrenceEnabled: boolean = false;

  settings: IStatusBannerSettings = {
    createdOutsideDataWarehouseWindow: false,
    queued: false,
    notRunYet: false,
    firstRun: false,
    finished: false
  };

  scheduledDate: string;
  nextDate: Date;

  progressBarItems: Array<IStatusBarItem>;

  private timeoutForPageReload: number = 2000; // timeout for reloading page when run is finished (in ms)
  private newRunsSubscription: Subscription;
  private initialized: boolean = false;
  private destroy$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private statusBannerService: StatusBannerService,
    private statusBannerProcessor: StatusBannerProcessor,
    private dateService: DateService,
    private stopRunsService: IStopRunService,
    private storageService: StorageService
  ) {
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
  }

  ngOnInit() {
    if (typeof this.shouldInit === 'undefined') this.shouldInit = true;

    if (this.shouldInit) {
      this.init();
    }

    this.statusBannerService.getUpdateSubscription().subscribe(() => this.init());
    this.statusBannerService.getPauseStatus().subscribe(nextValue => {
      this.isPausedFlag = nextValue;
    });
    this.statusBannerService.runStopped$.subscribe(() => {
      this.inProgress = false;
      this.runStopped = true;
      this.settings.queued = false;
    });
  }

  runNow(): void {
    this.runNowClicked.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.initialized && changes.shouldInit && changes.shouldInit.currentValue) {
      this.init();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private init(): void {
    this.initProgressBarItems();
    this.initTypeLabel();
    this.initialize();
    this.initialized = true;
  }

  private initProgressBarItems(): void {
    this.progressBarItems = this.statusBannerProcessor.generateProgressBarItems(this.updatesType);
  }

  private initTypeLabel(): void {
    this.typeLabel = this.statusBannerService.determineTypeLabel(this.updatesType);
  }

  private initialize(): void {
    switch (this.updatesType) {
      case StatusUpdateType.Audit:
        this.initAuditUpdates();
        break;
      case StatusUpdateType.WebJourney:
        this.initWebJourneyUpdates();
        break;
    }
  }

  public initAuditUpdates() {
    const auditId = +this.route.snapshot.params.auditId;
    this.resourceId = auditId;
    if (!auditId) return;

    this.statusBannerService.getSettingsForAudit(auditId).then((settings: IStatusBannerSettings) => {
      this.settings = settings;
      this.activeRunId = settings.lastRunId;
      this.subscribeOnRunsStoppedEvent();

      if (this.isNotSupported(this.settings)) {
        return;
      }

      if (this.needToGetNextCheckDate(this.settings)) {
        this.statusBannerService.getAuditConfig(auditId).then((audit: IAuditModel) => {
          const nextRunDateDate = audit?.nextRun;
          this.nextDate = nextRunDateDate;
          this.scheduledDate = this.dateService.formatDate(new Date(nextRunDateDate), EDateFormats.dateFifteen);
          this.isScheduledFlag = this.recurrenceEnabled
            ? DataSourcesUtils.isDataSourceScheduled(audit)
            : DataSourcesUtils.isAuditScheduled(audit?.frequency as EAuditFrequency);
          this.isPausedFlag = this.recurrenceEnabled
            ? audit.schedule.isPaused
            : DataSourcesUtils.isPausedDate(nextRunDateDate);
        });
        return;
      }

      if (!auditId) {
        return;
      }

      // When first creating an audit, there is a short time delay before the system
      // recognizes the audit and a lastRunId is generated. This will retry until the
      // lastRunId is available. We can then begin polling for status updates.
      if (!this.settings?.lastRunId) {
        setTimeout(() => {
          this.initAuditUpdates();
        }, 10000);

        return;
      }

      // Get Initial Progress status
      this.statusBannerService.getAuditStatusProgress(auditId, this.settings.lastRunId ).then((update: IAuditStatus) => {
        this.progressBarItems = this.statusBannerProcessor.processAuditUpdate(update, this.progressBarItems);
        this.inProgress = this.progressBarItems[0].state !== StatusBarItemState.notStarted;
      });

      // Poll every 30 seconds for continued progress updates
      const subscription = this.statusBannerService.subscribeForStatusUpdates(this.updatesType, auditId, this.settings.lastRunId).subscribe(update => {
        this.progressBarItems = this.statusBannerProcessor.processAuditUpdate(update, this.progressBarItems);
        if (!this.runStopped) {
          this.inProgress = this.progressBarItems[0].state !== StatusBarItemState.notStarted;
        }
        this.reloadReportIfRunIsFinished();
      });
      this.statusBannerService.addProgressSubscription(subscription);
    });
  }

  public initWebJourneyUpdates() {
    const journeyId = +this.route.snapshot.params.journeyId;
    this.resourceId = journeyId;
    this.statusBannerService.getSettingsForWebJourney(journeyId).then((settings: IStatusBannerSettings) => {
      this.settings = settings;
      this.activeRunId = settings.lastRunId;
      this.subscribeOnRunsStoppedEvent();
      if (this.isNotSupported(this.settings)) {
        return;
      }
      if (this.needToGetNextCheckDate(this.settings)){
        this.statusBannerService.getWebJourney(journeyId).then((journey: IWebJourneyCard) => {
          if (journey) {
            journey.type = CardTypes.webJourney;
            if (journey?.nextCheck) {
              this.nextDate = new Date(journey.nextCheck);
              this.scheduledDate = this.dateService.formatDate(new Date(journey.nextCheck), EDateFormats.dateFifteen);
              this.isPausedFlag = this.recurrenceEnabled
                ? journey.schedule.isPaused
                : DataSourcesUtils.isPausedDate(journey.nextCheck);
            }
            this.isScheduledFlag = this.recurrenceEnabled
              ? DataSourcesUtils.isDataSourceScheduled(journey)
              : DataSourcesUtils.isJourneyScheduled(journey?.options?.frequency);
          }
        });
        return;
      }
      this.statusBannerService.getWebJourneyStatusProgress(journeyId, this.settings.lastRunId).subscribe((update: IWebJourneyRunProgress) => {
        this.progressBarItems = this.statusBannerProcessor.processWebJourneyUpdate(update, this.progressBarItems);
        this.inProgress = this.progressBarItems[0].state !== StatusBarItemState.notStarted;
      });

      const subscription = this.statusBannerService.subscribeForWebJourneyUpdates(journeyId, this.activeRunId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(update => {
          this.progressBarItems = this.statusBannerProcessor.processWebJourneyUpdate(update, this.progressBarItems);
          this.reloadReportIfRunIsFinished();
        });
      this.statusBannerService.addProgressSubscription(subscription);
    });
  }

  private subscribeOnRunsStoppedEvent(): void {
    const runnableItem = this.stopRunsService.runnableItem(this.updatesType);
    this.stopRunsService.subscribeOnRunStoppedEvent(runnableItem, this.resourceId, this.activeRunId, () => {
      this.init();
    });
  }

  private reloadReportIfRunIsFinished(): void {
    if (this.runIsFinished()) {
      this.reloadReport();
    }
  }

  private runIsFinished(): boolean {
    return this.progressBarItems[this.progressBarItems.length - 1].state === StatusBarItemState.done;
  }

  private reloadReport(): void {
    setTimeout(() => {
      let stateParams = this.getStateParams();
      if (this.updatesType == StatusUpdateType.Audit) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(AuditReportContainerUrlBuilders.base(+stateParams.auditId, +stateParams.runId));
        });
      } else if (this.updatesType == StatusUpdateType.WebJourney) {
        const urlTree = this.router.parseUrl(this.router.url);
        const segments = RouteDataService.getUrlSegments(urlTree);
        this.router.navigateByUrl(WebJourneyReportUrlBuilders.base(+stateParams.journeyId, +stateParams.runId) + '/' + segments.pop());
      }
    }, this.timeoutForPageReload);
  }

  private getStateParams(): IDataSelectorStateParams {
    switch (this.updatesType) {
      case StatusUpdateType.Audit:
        return this.getStateParamsForAudit();
      case StatusUpdateType.WebJourney:
        return this.getStateParamsForWebJourney();
    }
  }

  private getStateParamsForAudit(): IDataSelectorStateParams {
    const auditId = this.route.snapshot.params.auditId;
    return {
      auditId,
      runId: String(this.activeRunId)
    };
  }

  private getStateParamsForWebJourney(): IDataSelectorStateParams {
    const journeyId = this.route.snapshot.params.journeyId;
    return {
      journeyId,
      runId: String(this.activeRunId)
    };
  }

  needToGetNextCheckDate(settings: IStatusBannerSettings): boolean {
    return (settings.notRunYet || !settings.lastRunId) && !settings.queued;
  }

  isNotSupported(settings: IStatusBannerSettings): boolean {
    return settings.finished || !settings.notRunYet && !settings.firstRun;
  }
}
