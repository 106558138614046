import { LiveConnectUrlBuilders } from './../../../live-connect/live-connect.constants';
import { Router } from '@angular/router';
import { AngularNames, Events, Names } from '@app/moonbeamConstants';
import { IAuditStatus, StatusBannerService, StatusUpdateType } from '../statusBannerService';
import { ToastService } from '../../../utilities/toastService';
import { IEventManager } from '../../../eventManager/eventManager';
import * as angular from 'angular';
import { RDCManualJourneyStatuses } from '@app/components/live-connect/manual-journey/manualJourneyService';
import { IWebsocketService } from '@app/components/websockets/websocketService';
import {
  ManualJourneySocketsService,
  name as MJSS_NAME
} from '@app/components/live-connect/manual-journey/manualJourneyWsService';
import { AuditReportUrlBuilders } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { ReprocessBannerType } from '@app/components/reprocess-banner/reprocess-banner.constants';
import { Observable, Subject } from 'rxjs';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';

type ReprocessRuleType = 'audit' | 'manualJourney';

export abstract class IReprocessService {
  abstract subscribeOnAuditReprocessingRulesUpdates(auditId: number, runId: number, auditName: string): void;

  abstract subscribeOnAuditReprocessingConsentCategoriesUpdates(auditId: number, runId: number, auditName: string): void;

  abstract getAuditStatusProgress(auditId: number, runId: number): angular.IPromise<IAuditStatus>;

  abstract updateAuditReprocessRulesBannerStatus(auditId: number, runId: number, status: ReprocessBannerStatus): void;

  abstract updateAuditReprocessConsentCategoriesBannerStatus(auditId: number, runId: number, status: ReprocessBannerStatus): void;

  abstract displayAuditConsentCategoriesReprocessInProgressToast();

  abstract reprocessComplete$: Observable<any>;
  abstract reprocessComplete();

  abstract subscribeOnManualJourneyReprocessingRulesUpdates(accountId: number,
                                                            deviceProfileId: number,
                                                            manualJourneyId: number,
                                                            manualJourneyName: string,
                                                            onReprocessingFinished?: (update: { state: number }) => void): angular.IPromise<IWebsocketService>;

  abstract displayManualJourneyRulesReprocessingStartedToast(manualJourneyName: string): void;
}

export interface IReprocessBannerUpdate {
  auditId: number;
  runId: number;
  status: ReprocessBannerStatus;
  type: ReprocessBannerStatus;
}

export class ReprocessBannerStatus {
  static hidden = 'hidden';
  static inProgress = 'inProgress';
  static done = 'done';
}

export class ReprocessService
  extends IReprocessService {

  reprocessCompleteSubject: Subject<any> = new Subject();
  reprocessComplete$: Observable<any> = this.reprocessCompleteSubject.asObservable();

  GO_TO_REPROCESSED_RUN_EVENT: string = 'GO_TO_REPROCESSED_RUN_EVENT';
  SHOW_REPROCESS_RULES_BANNER_EVENT: string = 'SHOW_REPROCESS_RULES_BANNER_EVENT';

  AUDIT_TYPE: ReprocessRuleType = 'audit';
  MANUAL_JOURNEY_TYPE: ReprocessRuleType = 'manualJourney';

  static $inject = [
    Names.Services.ngToast,
    AngularNames.rootScope,
    Names.Services.toastService,
    Names.Services.statusBanner,
    Names.Services.eventManager,
    Names.NgServices.router,
    MJSS_NAME,
    Names.NgServices.snackbarService
  ];

  constructor(
    private ngToast: any,
    private $rootScope: angular.IRootScopeService,
    private toastService: ToastService,
    private statusBannerService: StatusBannerService,
    private eventManager: IEventManager,
    private router: Router,
    private manualJourneySocketsService: ManualJourneySocketsService,
    private snackbarService: SnackbarService
  ) {
    super();
    this.subscribeOnGoToRunEvent();
  }

  //Audits
  public displayAuditRulesReprocessingStartedToast(auditName: string): void {
    this.displayRulesReprocessingStartedToast(this.AUDIT_TYPE, auditName);
  }

  private displayAuditRulesReprocessingFinishedToast(auditName: string, auditId: number, runId: number) {
    this.displayRulesReprocessingFinishedToast(this.AUDIT_TYPE, auditName, auditId, runId);
  }

  private displayAuditConsentCategoriesReprocessingFinishedToast(auditName: string, auditId: number, runId: number) {
    this.displayConsentCategoriesReprocessingFinishedToast(auditName, auditId, runId);
  }

  public reprocessComplete(): void {
    this.reprocessCompleteSubject.next();
  }

  public displayAuditConsentCategoriesReprocessInProgressToast(): void {
    this.displayActiveConsentCategoryReprocessToast();
  }

  public getAuditStatusProgress(auditId: number, runId: number): angular.IPromise<IAuditStatus> {
    return this.statusBannerService.getAuditStatusProgress(auditId, runId);
  }

  public subscribeOnAuditReprocessingRulesUpdates(auditId: number, runId: number, auditName: string): void {
    const subscription = this.statusBannerService.subscribeForStatusUpdates(StatusUpdateType.Audit, auditId, runId).subscribe((update: IAuditStatus) => {
      this.processReprocessRulesUpdates(update, auditId, runId, auditName);
    });

    this.statusBannerService.addProgressSubscription(subscription);
  }

  private processReprocessRulesUpdates(update: IAuditStatus, auditId: number, runId: number, auditName: string): void {
    if (this.isAuditReportActive(auditId, runId)) {
      let status: ReprocessBannerStatus = this.getRulesReprocessBannerStatus(update);
      this.updateAuditReprocessRulesBannerStatus(auditId, runId, status);
    } else if (update.finished && update.rulesVerified) {
      this.displayAuditRulesReprocessingFinishedToast(auditName, auditId, runId);
    }
  }

  public subscribeOnAuditReprocessingConsentCategoriesUpdates(auditId: number, runId: number, auditName: string): void {
    const subscription = this.statusBannerService.subscribeForStatusUpdates(StatusUpdateType.ConsentCategory, auditId, runId).pipe(
    ).subscribe((update: IAuditStatus) => {
      this.processReprocessConsentCategoriesUpdates(update, auditId, runId, auditName, subscription);
    });

    this.statusBannerService.addProgressSubscription(subscription);
  }

  private processReprocessConsentCategoriesUpdates(update: IAuditStatus, auditId: number, runId: number, auditName: string, subscription): void {
    if (this.isAuditReportActive(auditId, runId)) {
      let status: ReprocessBannerStatus = this.getConsentCategoriesReprocessBannerStatus(update);
      this.updateAuditReprocessConsentCategoriesBannerStatus(auditId, runId, status);
    } else if (update.consentCategoriesVerificationStarted && update.consentCategoriesVerified) {
      this.displayAuditConsentCategoriesReprocessingFinishedToast(auditName, auditId, runId);
    }

    if (update.consentCategoriesVerificationStarted && update.consentCategoriesVerified) subscription.unsubscribe();
  }

  public updateAuditReprocessRulesBannerStatus(auditId: number, runId: number, status: ReprocessBannerStatus): void {
    this.updateAuditBannerStatus(auditId, runId, status, ReprocessBannerType.Rules, Events.reprocessRulesBannerUpdated);
  }

  public updateAuditReprocessConsentCategoriesBannerStatus(auditId: number, runId: number, status: ReprocessBannerStatus): void {
    this.updateAuditBannerStatus(auditId, runId, status, ReprocessBannerType.ConsentCategories, Events.reprocessConsentCategoryBannerUpdated);
  }

  private updateAuditBannerStatus(auditId: number, runId: number, status: ReprocessBannerStatus, type: ReprocessBannerType, event: string): void {
    var update: IReprocessBannerUpdate = {auditId: auditId, runId: runId, status: status, type: type};
    this.eventManager.publish<IReprocessBannerUpdate>(event, update);
  }

  private getRulesReprocessBannerStatus(update: IAuditStatus): ReprocessBannerStatus {
    return update.finished && update.rulesVerified ? ReprocessBannerStatus.done : ReprocessBannerStatus.inProgress;
  }

  private getConsentCategoriesReprocessBannerStatus(update: IAuditStatus): ReprocessBannerStatus {
    return update.consentCategoriesVerificationStarted && update.consentCategoriesVerified ? ReprocessBannerStatus.done : ReprocessBannerStatus.inProgress;
  }

  private isAuditReportActive(auditId: number, runId: number): boolean {
    const urlPieces = this.router.routerState.snapshot.url.split('/');
    const routeAuditId = parseInt(urlPieces[2]);
    const routeRunId = parseInt(urlPieces[4]);

    return routeAuditId === auditId && routeRunId === runId;
  }

  //Manual Journey
  public displayManualJourneyRulesReprocessingStartedToast(manualJourneyName: string): void {
    this.displayRulesReprocessingStartedToast(this.MANUAL_JOURNEY_TYPE, manualJourneyName);
  }

  public displayManualJourneyRulesReprocessingFinishedToast(manualJourneyName: string, deviceProfileId: number, manualJourneyId: number): void {
    this.displayRulesReprocessingFinishedToast(this.MANUAL_JOURNEY_TYPE, manualJourneyName, deviceProfileId, manualJourneyId);
  }

  public subscribeOnManualJourneyReprocessingRulesUpdates(accountId: number,
                                                          deviceProfileId: number,
                                                          manualJourneyId: number,
                                                          manualJourneyName: string,
                                                          onReprocessingFinished?: (update: { state: number }) => void): angular.IPromise<IWebsocketService> {
    return this.manualJourneySocketsService.subscribeOnManualJourneyStateUpdate(accountId, (update: any) => {
      if (update.runId != manualJourneyId) return;
      if (update.state === RDCManualJourneyStatuses.enforced) {
        onReprocessingFinished && onReprocessingFinished(update);
        this.displayManualJourneyRulesReprocessingFinishedToast(manualJourneyName, deviceProfileId, manualJourneyId);
        this.manualJourneySocketsService.unsubscribeFromManualJourneyStateUpdate(accountId);
      }
    });
  }

  //Common
  private displayRulesReprocessingStartedToast(entityType: ReprocessRuleType, entityName: string): void {
    var entityTypeName: string = this.getEntityTypeName(entityType);
    var title: string = `${entityTypeName}: ${entityName}`;
    var subTitle: string = 'Rules are processing. We\'ll notify you when it\'s done';
    this.ngToast.create(this.toastService.generateTwoLinesToastConfig(title, subTitle));
  }

  private displayRulesReprocessingFinishedToast(entityType: ReprocessRuleType, entityName: string, entityId: number, runId: number) {
    var timeout = 7000;
    var customClass = 'reprocess-rules';
    var entityTypeName: string = this.getEntityTypeName(entityType);
    var title: string = `${entityTypeName}: ${entityName}`;
    var subTitle: string = `Rules have finished reprocessing for ${entityName}. <span class='toast-action'
        ng-click='$root.$broadcast("${this.GO_TO_REPROCESSED_RUN_EVENT}", {entityType: "${entityType}",entityId: ${entityId}, runId: ${runId}})'>GO TO UPDATED REPORT</span>`;
    // TODO: When we convert this to Angular and use a mat-snackbar instead, position these messages at the top-center.
    //  ngToast only allows app-wide configuration of the position, so unable to reposition only this particular toast.
    this.ngToast.create(this.toastService.generateTwoLinesToastConfig(title, subTitle, timeout, customClass));
  }

  private displayConsentCategoriesReprocessingFinishedToast(auditName: string, auditId: number, runId: number) {
    var timeout = 7000;
    var customClass = 'reprocess-rules';
    var title: string = `Audit: ${auditName}`;
    var subTitle: string = `Consent categories have finished reprocessing for ${auditName}. <span class='toast-action'
        ng-click='$root.$broadcast("${this.GO_TO_REPROCESSED_RUN_EVENT}", {entityType: "audit",entityId: ${auditId}, runId: ${runId}})'>GO TO UPDATED REPORT</span>`;
    // TODO: When we convert this to Angular and use a mat-snackbar instead, position these messages at the top-center.
    //  ngToast only allows app-wide configuration of the position, so unable to reposition only this particular toast.
    this.ngToast.create(this.toastService.generateTwoLinesToastConfig(title, subTitle, timeout, customClass, true));
  }

  private displayActiveConsentCategoryReprocessToast(): void {
    const message = 'Consent categories reprocess has already been started.';
    this.snackbarService.openErrorSnackbar(message, { duration: 5000 });
  }

  private getEntityTypeName(entityType: ReprocessRuleType): string {
    switch (entityType) {
      case 'audit':
        return 'Audit';
      case 'manualJourney':
        return 'Manual Journey';
      default:
        return 'Journey';
    }
  }

  private subscribeOnGoToRunEvent(): void {
    this.$rootScope.$on(this.GO_TO_REPROCESSED_RUN_EVENT, (event, {entityType, entityId, runId}) => {
      switch (entityType) {
        case 'audit':
          this.openAudit(entityId, runId);
          break;
        case 'manualJourney':
          this.openManualJourney(entityId, runId);
          break;
      }
    });
  }

  private openAudit(auditId: number, runId: number) {
    this.router.navigateByUrl(AuditReportUrlBuilders.useCasePrivacy(auditId, runId));
  }

  private openManualJourney(deviceProfileId: number, manualJourneyId: number) {
    this.router.navigateByUrl(LiveConnectUrlBuilders.manualJourneyEdit(deviceProfileId, manualJourneyId));
  }

}
