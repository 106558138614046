import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAccessibilityTestedElement } from '@app/components/audit-reports/page-details/page-details.models';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'op-page-issue-details',
  templateUrl: './page-issue-details.component.html',
  styleUrls: ['./page-issue-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageIssueDetailsComponent {
  private _issue: IAccessibilityTestedElement;
  private _selectors: string[];
  displayAll: boolean = false;

  constructor(private cb: ClipboardService) {}

  @Input() set issue(value: IAccessibilityTestedElement) {
    this._issue = value;
    if (!!value) {
      this.parseAndSetSelectors(value);
    }
  }

  get issue(): IAccessibilityTestedElement {
    return this._issue;
  }

  get selectors(): string[] {
    return this._selectors;
  }

  private parseAndSetSelectors(issue: IAccessibilityTestedElement): void {
    this._selectors = [...issue.htmlCssSelectors ?? [],
                       ...issue.shadowDomCssSelectors ?? []].flat(2);
  }

  copy(value: string | string[]): void {
    this.cb.copy(value.toString());
  }

  toggleDisplayAll(): void {
    this.displayAll = !this.displayAll;
  }
}
