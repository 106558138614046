<op-modal (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Edit Account | {{ accountName }}"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="edit-account flex-col flex-stretch">
      <mat-spinner *ngIf="loading" [diameter]="75" [strokeWidth]="5"></mat-spinner>
      <op-tabs *ngIf="!loading"
               [tabs]="tabs"
               [useRouting]="false"
               [activeTab]="activeTab"
               [class.loading]="loading"
               (tabClicked)="tabClicked($event)"
               class="flex-col flex-stretch">
        <account-details *ngIf="activeTab === tabs[0].path"
                         [accountV2]="accountV2"
                         [statuses]="statuses"
                         (updated)="accountTabUpdated($event)">
        </account-details>
        <account-users [accountV2]="accountV2"
                       *ngIf="activeTab === tabs[1].path">
        </account-users>
        <admin-tags *ngIf="activeTab === tabs[2].path"
                    [enabledTags]="enabledTags"
                    [allTags]="tags"
                    (saveTags)="saveTags($event)">
        </admin-tags>
        <admin-features *ngIf="activeTab === tabs[3].path"
                        [features]="features"
                        [extraFeatures]="extraFeatures"
                        (featuresUpdated)="featuresUpdated($event)">
        </admin-features>
        <product-limits *ngIf="activeTab === tabs[4].path"
                        [productLimits]="productLimits"
                        [account]="accountV2"
                        [accountV3]="accountV3"
                        [webJourneyFrequencies]="webJourneyFrequencies"
                        [scriptServicesConfig]="scriptServicesConfig"
                        (updated)="productLimitsUpdated($event)"
                        (retentionPolicyUpdated)="handleRetentionPolicyUpdated($event)"
        >
        </product-limits>
        <admin-sso *ngIf="activeTab === tabs[5].path"
                   [accountV3]="accountV3"
                   [accountV2]="accountV2"
                   [sso]="sso"
                   [ssoCreateMode]="ssoCreateMode"
        ></admin-sso>
      </op-tabs>
    </div>
  </ng-template>
  <ng-template #footerTemplate></ng-template>
</op-modal>
