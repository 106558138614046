import { admin, guest, opAdmin, opSysAdmin, standardUser } from '@app/authUtils';

export enum EUserStep {
  Profile,
  Permissions,
  FolderAccess,
  Notifications,
}

export const userRoles = {
  opSysAdmin: opSysAdmin,
  opAdmin: opAdmin,
  admin: admin,
  standardUser: standardUser,
  guest: guest
};
