import { BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
  EPageUrlFilterType,
  reportTypeToFilterTypes
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import { EAuditReportType } from '@app/components/audit-reports/audit-report/audit-report.enums';
import { IAuditGeoLocation } from '@app/components/consent-categories/consent-categories.models';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import {
  IOpFilterBarInvertableFilter,
  IOpFilterBarMenuItem,
  ISearchByTextEmissionData
} from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { ComponentChanges } from '@app/models/commons';
import { AlertService } from '../../alert.service';
import { AlertLogicFilterBarService } from '../alert-logic-filter-bar.service';
import { AlertMetricType } from '../alert-logic.enums';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import {
  EFilterBarMenuNames
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.enums';
import { EAlertModalType } from '@app/components/alert/alert.enums';
import { RulesService } from '@app/components/rules/rules.service';
import { IRulePaginationV3, IRuleSortV3 } from '@app/components/rules/rule-library/rule-library.models';
import { ESortColumnsV3 } from '@app/components/rules/rule-library/rule-library.enums';
import { OpFilterBarComponent } from '@app/components/shared/components/op-filter-bar/op-filter-bar.component';
import {
  SnackbarErrorComponent
} from '@app/components/shared/components/snackbars/snackbar-error/snackbar-error.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTagCategory } from '@app/components/tag-database';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  selector: 'op-alert-filters',
  templateUrl: './alert-filters.component.html',
  styleUrls: ['./alert-filters.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AlertFiltersComponent),
    multi: true
  }]
})
export class AlertFiltersComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @ViewChild(OpFilterBarComponent) filterBar: OpFilterBarComponent;

  @Input() metricType: AlertMetricType;
  @Input() modalType: EAlertModalType;
  @Input() readOnlyLabel: string;

  readonly EAlertModalType = EAlertModalType;

  private reportType: EAuditReportType;

  noFilters: boolean;
  pageUrlInputEnabled = false;
  filterBarMenuItems: IOpFilterBarMenuItem[] = [];
  validFilterTypes: EAuditReportFilterTypes[] = [];
  searchInInitialURLs = true;
  searchInFinalURLs = false;

  private tagDb: { id: number; name: string; searchName: string }[];
  private tagCategoryDb: IUiTagCategory[] = [];
  private ccsAssignedToCurrentRun: { categoryId: number; snapshotId: number, name: string; searchName: string }[];
  private countries: IAuditGeoLocation[];

  filters: IAuditReportApiPostBody;
  onChange: (value: IAuditReportApiPostBody) => void;

  private dataIsLoaded = new BehaviorSubject<boolean>(false);
  private destroySubject = new Subject<void>();

  constructor(public filterBarService: AlertLogicFilterBarService,
    private alertService: AlertService,
    private uiTagService: UiTagService,
    private rulesService: RulesService,
    private snackbar: MatSnackBar,
    private ccService: ConsentCategoriesService) {
  }

  ngOnInit(): void {
    this.fetchData();
    this.initListeners();
  }

  ngOnChanges(changes: ComponentChanges<AlertFiltersComponent>) {
    if (changes.metricType.currentValue) {
      const currentMetricType = changes.metricType.currentValue;
      const previousMetricType = changes.metricType.previousValue;

      if (currentMetricType !== previousMetricType) {
        this.buildReportType(currentMetricType);
        this.noFilters = this.isUsageReport();
        this.buildFilters();
      }

      // gets filters from the local storage when metric type is set the first time
      if (!previousMetricType && this.modalType === EAlertModalType.CreateForAudit) {
        this.filterBarService.loadFiltersFromLocalStorage();
        this.filterBarService.appendFiltersToView();
      }
    }
  }

  ngOnDestroy() {
    this.dataIsLoaded.complete();

    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private fetchData() {
    if (this.modalType === EAlertModalType.CreateForAudit) {
      this.filterBarService.loadFiltersFromLocalStorage();
    }

    forkJoin([
      this.fetchTagsDetails(),
      this.fetchGeolocations()
    ]).subscribe(() => {
      this.dataIsLoaded.next(true);
    });
  }

  private fetchTagsDetails() {
    return this.uiTagService
      .getAllTagsData()
      .pipe(
        tap(([accountTags]) => {

          const categoryMap = new Map<number, { id: number; category: string; }>();
          const tagIds = [];
          const categoryIds = [];

          this.tagDb = accountTags
            .filter(tag => tagIds.length ? tagIds.includes(tag.id) : true)
            .map(tag => {
              const category = this.uiTagService.getTagCategory(tag.tagCategoryId);
              categoryMap.set(category.id, category);
              return { id: tag.id, name: tag.name, searchName: tag.name.toLowerCase() };
            })
            .sort((a, b) => a.searchName < b.searchName ? -1 : 1);

          for (const cat of categoryMap.values()) {
            this.tagCategoryDb.push(cat);
          }

          this.tagCategoryDb = this.tagCategoryDb
            .filter(cat => categoryIds.length ? categoryIds.includes(cat.id) : true)
            .sort((c1, c2) => c1.category.toLowerCase() < c2.category.toLowerCase() ? -1 : 1);
        })
    );
  }

  private fetchGeolocations() {
    return this.ccService.getConsentCategoryCountries().pipe(
      tap(countries => this.countries = countries)
    );
  }

  private buildReportType(metricType: AlertMetricType) {
    const reportConfig = AlertUtils.getReportConfigByMetricType(metricType);
    this.reportType = reportConfig.type;
  }

  private buildFilters() {
    this.dataIsLoaded
      .pipe(
        filter(loaded => !!loaded),
        take(1)
      )
      .subscribe(() => {
        this.updateSupportedFiltersList();
        this.getValidFilterTypes();
        this.buildFilterMenu();
      });
  }

  private isUsageReport(): boolean {
    return this.reportType === EAuditReportType.Usage;
  }

  private updateSupportedFiltersList() {
    this.filterBarService.updateSupportedFiltersList(
      reportTypeToFilterTypes.get(this.reportType)
    );
  }

  private getValidFilterTypes() {
    this.validFilterTypes = reportTypeToFilterTypes.get(this.reportType) ?? [];
  }

  private buildFilterMenu() {
    this.pageUrlInputEnabled = this.validFilterTypes.includes(EAuditReportFilterTypes.InitialPageUrlContains);

    const sorting: IRuleSortV3 = {
      sortDesc: true,
      sortBy: ESortColumnsV3.Name,
      sortDir: 'asc'
    };

    const pagination: IRulePaginationV3 = {
      currentPageNumber: 0,
      pageSize: 50
    };

    this.filterBarMenuItems = this.filterBarService.buildFilterMenu(
      this.validFilterTypes,
      this.tagDb,
      this.tagCategoryDb,
      this.countries,
      this.ccsAssignedToCurrentRun || [],
      (ruleName) => this.rulesService
        .getRulesV3(sorting, pagination, { ruleName })
        .pipe(map(response => response.rules.map(rule => ({ ruleName: rule.name, ruleId: rule.id as number }))))
    );

    let ccIndex: number = this.filterBarMenuItems.findIndex(filter => filter.name === EFilterBarMenuNames.ConsentCategory);
    if (ccIndex) this.filterBarMenuItems[ccIndex] = this.addCCToFilters();
  }

  private initListeners() {
    this.filterBarService.anyFiltersUpdates$
      .pipe(
        takeUntil(this.destroySubject),
        debounceTime(100),
        map(newFilters => this.filterBarService.generateApiPostBody(newFilters))
      )
      .subscribe(filters => {
        this.filters = filters;
        this.onChange(filters);
      });
  }

  private addCCToFilters() {
    const consentCategorySnapshotId = this.validFilterTypes.includes(EAuditReportFilterTypes.ConsentCategoryId);
    const consentCategoryComplianceStatus = this.validFilterTypes.includes(EAuditReportFilterTypes.ConsentCategoryComplianceStatus);
    const consentCategoryTopLevel = consentCategorySnapshotId || consentCategoryComplianceStatus;

    const searchConfig = {
      name: EFilterBarMenuNames.ConsentCategoryNameSearch,
      type: EFilterBarMenuTypes.Search,
      searchPlaceholder: 'Consent Category Name',
      action: (event: KeyboardEvent, el?: HTMLElement) => handleCCNameSearch(searchConfig, event, el),
      children: []
    };

    const handleCCNameSearch = (searchConfig: IOpFilterBarMenuItem,
      event: KeyboardEvent,
      el?: HTMLElement) => {
      const ccNameSearchTextUpdated = Date.now();
      // stolen! https://github.com/angular/components/issues/7973
      // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
      if (el && Date.now() < ccNameSearchTextUpdated + 200) {
        el.focus();
        return;
      }

      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

      if (!value) return;

      this.ccService.getConsentCategoriesLibrary({ page: 0, pageSize: 100, name: value }).subscribe(ccs => {
        const ccNameSearchChildren = ccs.consentCategories
          .map(cc => ({
              name: cc.name,
              type: EFilterBarMenuTypes.Button,
              action: () => this.filterBarService.addConsentCategoryNameFilter(cc.id, cc.name)
            })
          );

        searchConfig.children = value ? ccNameSearchChildren : [];
      });

    };

    return {
      name: EFilterBarMenuNames.ConsentCategory,
      type: EFilterBarMenuTypes.Flyout,
      displayWhen: consentCategoryTopLevel,
      children: [
        {
          name: EFilterBarMenuNames.ConsentCategoryName,
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: consentCategorySnapshotId,
          children: [searchConfig]
        },
        {
          name: 'Status',
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: consentCategoryComplianceStatus,
          children: [
            {
              name: 'Unapproved',
              type: EFilterBarMenuTypes.Button,
              action: () => this.filterBarService.addConsentCategoryStatusFilter('unapproved')
            },
            {
              name: 'Approved',
              type: EFilterBarMenuTypes.Button,
              action: () => this.filterBarService.addConsentCategoryStatusFilter('approved')
            }
          ]
        }
      ]
    };
  }

  handleSearchByUrl({ value, regex }: ISearchByTextEmissionData): void {
    if (this.searchInInitialURLs) {
      this.filterBarService.addUrlContainsFilter(value, regex);
    }

    if (this.searchInFinalURLs) {
      this.filterBarService.addFinalUrlContainsFilter(value, regex);
    }
  }

  handleInvertableFilterToggled(filter: IOpFilterBarInvertableFilter<string>) {
    switch (filter.type) {
      case EAuditReportFilterTypes.PageTitle:
        this.filterBarService.addPageTitleFilter(filter.state, filter.value['filterValue']);
        break;
      case EAuditReportFilterTypes.ConsoleLogMessage:
        this.filterBarService.addConsoleLogMessageFilter(filter.state, filter.value['filterValue']);
        break;
      case EAuditReportFilterTypes.InitialPageUrlContains:
        this.filterBarService.addUrlDoesNotContainFilter(filter.value['filterValue'], filter.value['filterType'] === EPageUrlFilterType.Regex);
        break;
      case EAuditReportFilterTypes.InitialPageUrlDoesNotContain:
        this.filterBarService.addUrlContainsFilter(filter.value['filterValue'], filter.value['filterType'] === EPageUrlFilterType.Regex);
        break;
      case EAuditReportFilterTypes.FinalPageUrlContains:
        this.filterBarService.addFinalUrlDoesNotContainFilter(filter.value['filterValue'], filter.value['filterType'] === EPageUrlFilterType.Regex);
        break;
      case EAuditReportFilterTypes.FinalPageUrlDoesNotContain:
        this.filterBarService.addFinalUrlContainsFilter(filter.value['filterValue'], filter.value['filterType'] === EPageUrlFilterType.Regex);
        break;
      case EAuditReportFilterTypes.CookieDomain:
        this.filterBarService.addCookieDomainFilter(filter.state, filter.value['filterValue']);
        break;
      case EAuditReportFilterTypes.AlertStatus:
        this.filterBarService.addTriggeredAlertsFilter(filter.state);
        break;
      case EAuditReportFilterTypes.ShowAuditConfigured:
        this.filterBarService.addShowConfiguredUrlsOnlyFilter(filter.state);
        break;
      default:
        console.error(`Invertable filter toggled handler isn't implemented for the ${filter.type}`);
    }
  }

  /*** Control Value Accessor implementation ***/
  writeValue(filters: IAuditReportApiPostBody): void {
    this.filters = filters;

    if ([EAlertModalType.Edit, EAlertModalType.Duplicate, EAlertModalType.CreatePrefilled].includes(this.modalType)) {
      this.filterBarService.clear();
      let filtersCopy = JSON.parse(JSON.stringify(filters));
      // Consent Category name filter requires the name for display, which we don't get
      // from the response. We need to manually lookup the cc to get it's name and add
      // from outside the service
      let ccSnapshotId = filters?.consentCategorySnapshotId || filters?.consentCategoryId;
      if (ccSnapshotId) {
        // Need to retrieve matching CC and then add the filter and override filters
        this.ccService.getConsentCategoryById(ccSnapshotId)
          .pipe(catchError(() => {
            return of(undefined);
          }))
          .subscribe((cc) => {
            cc === undefined
              ? this.filterBarService.addConsentCategoryDeletedFilter()
              : this.filterBarService.addConsentCategoryNameFilter(cc.id, cc.name);

            this.filterBarService.overrideFilters(filtersCopy);
          });
      } else {
        this.filterBarService.overrideFilters(filtersCopy);
      }
    }
  }

  registerOnChange(fn: (value: IAuditReportApiPostBody) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(_: () => void): void {
  }

  onClickUrlOptions(event: MouseEvent, type: 'searchInInitialURLs' | 'searchInFinalURLs'): void {
    if (type === 'searchInInitialURLs') {

      if (this.searchInFinalURLs) {
        this.searchInInitialURLs = !this.searchInInitialURLs;
      } else {
        this.showUrlTypeError();
      }

    } else {
      if (this.searchInInitialURLs) {
        this.searchInFinalURLs = !this.searchInFinalURLs;
      } else {
        this.showUrlTypeError();
      }
    }
  }

  private showUrlTypeError() {
    this.snackbar.openFromComponent(SnackbarErrorComponent, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        message: 'You must select at least one URL option.'
      }
    });
  }
}
