import { AngularNames, Names } from '@app/moonbeamConstants';
import { ButtonSet, IButton } from '@app/models/commons';
import { IUser } from '../../moonbeamModels';
import { userIsAdmin } from '../../authUtils';
import * as angular from 'angular';
import { DateService, EDateFormats, formatDefs } from '@app/components/date/date.service';

export interface IDeleteScope<T> extends mgcrea.ngStrap.modal.IModalScope {
    item: T;
    disableDeleting: boolean;
  }

  export interface IDisplayItem {
    name: string;
    createdAt?: string;
    type?: string;
    authorized?: boolean;
  }

  export abstract class DeleteControllerBase<T> {

    protected static Dependencies = class {
      static deleteHandler = 'onDeleteHandler';
      static dateService = Names.Services.date;
    };

    item: T;

    displayItem: IDisplayItem;

    allAuthorized: boolean = true;

    hideDiscardButton: boolean = false;

    dateFormat: string = EDateFormats.dateSeven;
    altDateFormat: string = EDateFormats.dateTen;

    private deleteButton: IButton = {
      label: 'Yes, Delete',
      action: 'handleDelete',
      primary: true,
      disabled: false
    };

    private closeButton: IButton = {
      label: 'Close',
      action: 'close'
    };

    modalButtons: Array<ButtonSet> = [[ this.deleteButton ]];

    discardButtonText: String = 'Don\'t Delete';

    constructor (protected $scope: IDeleteScope<T>,
                 protected onDeleteHandler: Function,
                 protected dateService?: DateService,
    ) {
      this.item = $scope.item;
      this.displayItem = this.createDisplayItem(this.item);
      if (this.$scope.disableDeleting) this.disableDeleting();
    }

    private disableDeleting() {
      this.allAuthorized = false;
      this.modalButtons = [[ this.closeButton ]];
      this.hideDiscardButton = true;
    }

    protected abstract createDisplayItem(item: T): IDisplayItem;

    protected abstract delete(item: T): angular.IPromise<any>;

    protected abstract handleError(error: any): void;

    handleDelete(): void {
      this.deleteButton.disabled = true;

      this.delete(this.item).then(
        (isDeleted) => {
          if (isDeleted && this.onDeleteHandler) {
            this.onDeleteHandler(true);
          }
          this.deleteButton.disabled = isDeleted;
          this.close();
        }, (err) => {
          this.handleError(err);
          this.deleteButton.disabled = false;
        }
      );
    }

    protected checkUser(userId: number, currentUser: IUser): boolean {
      if (currentUser) {
        let allowed = userIsAdmin(currentUser) || currentUser.id === userId;
        if (this.allAuthorized && !allowed) this.disableDeleting();
        return allowed;
      }
      return false;
    }

    // close the modal - can we find a better way?
    protected close(): void {
      (<mgcrea.ngStrap.modal.IModalScope>this.$scope.$parent).$hide();
    }

  }

  export class TestableDeleteController extends DeleteControllerBase<any> {

    static $inject = [
      AngularNames.scope,
      TestableDeleteController.Dependencies.deleteHandler,
      AngularNames.q,
      TestableDeleteController.Dependencies.dateService,
    ];

    constructor(
      protected $scope: IDeleteScope<any>,
      protected onDeleteHandler: Function,
      private $q: angular.IQService,
      protected dateService: DateService,
    ) {
      super($scope, onDeleteHandler, dateService);
    }

    protected createDisplayItem(item: any): IDisplayItem {
      return {
        name: item.name,
        createdAt: item.createdAt
      };
    }

    protected delete(item: any): angular.IPromise<boolean> {
      return this.$q.resolve(true);
    }

    protected handleError(error: any): void {}
  }
