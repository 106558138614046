import { EAlertNotificationPolicy } from "../alert.enums";

export const NotificationPolicies = [
  {
    id: EAlertNotificationPolicy.AlwaysWhenTriggered,
    label: 'Each time it’s triggered'
  },
  {
    id: EAlertNotificationPolicy.WhenNewlyTriggered,
    label: 'Once when newly triggered (resets when alert is no longer triggered)'
  },
];
