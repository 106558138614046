import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EChartDateRange } from '../viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';

@Component({
  selector: 'op-date-range-buttons',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonToggleModule,
    MatTooltipModule
  ],
  templateUrl: './date-range-buttons.component.html',
  styleUrls: ['./date-range-buttons.component.scss']
})
export class DateRangeButtonsComponent {
  @Input() loading: boolean = false;
  @Input() greaterThanOneMonthDisabled: boolean = false;
  @Input() greaterThanThreeMonthsDisabled: boolean = false;
  @Input() greaterThanSixMonthsDisabled: boolean = false;
  @Input() greaterThanNineMonthsDisabled: boolean = false;
  @Input() greaterThanThirteenMonthsDisabled: boolean = false;
  @Input() dateRange?: EChartDateRange = EChartDateRange.oneMonth;

  @Output() dateRangeChange = new EventEmitter<EChartDateRange>();

  EChartDateRange = EChartDateRange;

  onDateRangeChange(value: EChartDateRange): void {
    this.dateRangeChange.emit(value);
  }
}
