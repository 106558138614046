import { IRorSetupForm } from './ror-setup-form/ror-setup-form.models';
import { IComparisonCreate, IAccountTagSettings } from '../comparisons.models';
import { IDatasourceTag } from '@app/components/comparison-library/comparison-library.model';
import { EComparisonType, EDatasourcesUseType } from '../comparisons.enums';
import { datasourceAllTagsOnly } from '../shared/comparison-variable-selector/comparison-variable-selector.constants';
import { IComparisonVariableSelectorForm } from '../shared/comparison-variable-selector/comparison-variable-selector.models';
import { ITagWithVariables } from '@app/components/match-criteria-picker/match-criteria-picker.models';
import { EAssignmentType } from '@app/models/commons';

export interface IRorFormModel {
  setup: IRorSetupForm;
  apply: number[];
  matchTags: ITagWithVariables[];
}

export namespace IRorFormModel {
  export function toComparisonCreate(formModel: IRorFormModel, 
                  tagIdToTagMap: Map<number, IAccountTagSettings>): IComparisonCreate {

    const datasources = formModel.setup.tags
      .filter(tag => tag.tagId !== datasourceAllTagsOnly.key)
      .map(tag => IComparisonVariableSelectorForm.toDatasourceTag(tag, tagIdToTagMap));

    const assignments = formModel.apply ?
      formModel.apply.map(itemId => ({itemId, itemType: EAssignmentType.audit})) :
      [];

    const tagPresenceOnly = !!formModel.setup.tags.find(
      tag => tag.tagId === datasourceAllTagsOnly.key
    );
    const details = {
      datasources,
      assignments,
      type: EComparisonType.runOverRun,
      datasourcesUseType: tagPresenceOnly ? EDatasourcesUseType.allEnabledTagsPresence : EDatasourcesUseType.specifiedTags
    } as IRorComparisonDetails;

    return {
      type: EComparisonType.runOverRun,
      name: formModel.setup.name,
      alignments: formModel.matchTags.map(ITagWithVariables.toTagVariableAlignment),
      labels: formModel.setup.labels ? formModel.setup.labels : [],
      details
    };
  }
}

export interface IRunOverRunModalData {
  id?: number;
}

export interface IRorComparisonDetails {
  type: EComparisonType.runOverRun;
  datasources?: IDatasourceTag[];
  assignments: IComparisonAssignment[];
  datasourcesUseType: EDatasourcesUseType;
}

interface IComparisonAssignment {
  itemType: EAssignmentType;
  itemId: number;
}
