import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'invalid-urls-snackbar',
  templateUrl: './invalid-urls-snackbar.component.html',
  styleUrls: ['./invalid-urls-snackbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvalidUrlsSnackbarComponent {
  urls: string[] = [];
  copyIcon: string = 'content_copy';

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: string[],
    private snackBarRef: MatSnackBarRef<InvalidUrlsSnackbarComponent>
  ) {
    this.urls = data;
  }

  copyUrl(url: string) {
    navigator.clipboard.writeText(url.trim());
    this.copyIcon = 'done';
    setTimeout(() => {
      this.copyIcon = 'content_copy';
    }, 1000);
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
