<div class="title-container">
  <h1 class="title">Action Sets</h1>
  <op-button-2021 labelText="Create New"
                  matIcon="control_point"
                  (buttonAction)="onCreate()"
                  buttonType="filled">
  </op-button-2021>
</div>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>
<div class="filter-button-section" [class.hidden]="loading">
  <div class="filter-input">
    <op-clearable-input
      placeholderText="Search by action set name"
      (onUserInput)="filterActionSets($event)">
    </op-clearable-input>
  </div>
  <div class="action-set-table-wrap">
    <table class="styled-mat-table" mat-table matSort multiTemplateDataRows [dataSource]="dataSource">

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let actionSet">
          <span class="hover-link" (click)="onEdit(actionSet)">
              {{ actionSet.name }}
          </span>
        </td>
      </ng-container>

      <!-- Web Audit Count Column -->
      <ng-container matColumnDef="webAuditCount">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">Web Audits</th>
        <td mat-cell
            class="usage center-text"
            [class.disabled]="!actionSet.webAuditCount"
            [class.opened]="selectedRow === actionSet && selectedRowType === EActionSetAssignmentType.WebAudits"
            *matCellDef="let actionSet"
            (click)="onAssignedClick(actionSet, EActionSetAssignmentType.WebAudits)">
          <div class="flex-row">
            <mat-icon>explore</mat-icon>
            <span>{{ actionSet.webAuditCount }}</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- Web Journey Count Column -->
      <ng-container matColumnDef="webJourneyCount">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">Web Journeys</th>
        <td mat-cell
            class="usage center-text"
            [class.disabled]="!actionSet.webJourneyCount || !webJourneyAccessible"
            [class.opened]="selectedRow === actionSet && selectedRowType === EActionSetAssignmentType.WebJourneys"
            *matCellDef="let actionSet"
            (click)="onAssignedClick(actionSet, EActionSetAssignmentType.WebJourneys)">
          <div class="flex-row">
            <mat-icon>map</mat-icon>
            <span>{{ actionSet.webJourneyCount }}</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- Edit/Delete Column -->
      <ng-container matColumnDef="editDelete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let actionSet" align="right">
          <div class="btn-wrap">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon class="more-menu-btn">more_vert</mat-icon>
            </button>
          </div>
          <mat-menu #menu="matMenu" class="action-set-lib-menu">
            <button mat-menu-item class="table-action-icon" (click)="onEdit(actionSet)">
              <mat-icon title="Edit">
                edit
              </mat-icon>
              Edit
            </button>
            <button mat-menu-item class="table-action-icon" (click)="onCopy(actionSet)">
              <mat-icon title="Copy">
                content_copy
              </mat-icon>
              Copy
            </button>
            <button mat-menu-item class="table-action-icon" (click)="onDelete(actionSet)">
              <mat-icon title="Delete">delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr *matNoDataRow>
        <td colSpan="7">
          <div *ngIf="!loading" class="no-data">
            <span>No action sets found.</span>
          </div>
        </td>
      </tr>

      <ng-container matColumnDef="detailsRow">
        <td mat-cell *matCellDef="let item" [attr.colspan]="displayedColumns.length">
          <div #container
               [@expand]="{
                 value: item === selectedRow ? (loading ? 'loading' : 'expanded') : 'collapsed',
                 params: {
                  height: this.lastOpenedNestedTableHeight ? this.lastOpenedNestedTableHeight + 'px' : '*'
                 }
               }"
               (@expand.done)="animationDone($event, container)">
            <div class="history" *ngIf="item === selectedRow">
              <action-set-assignment-table [type]="selectedRowType"
                                           [actionSet]="selectedRow"
              ></action-set-assignment-table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="expander hoverable"></tr>
      <tr mat-row *matRowDef="let row; columns: ['detailsRow']" [class.expanded]="selectedRow === row" class="details-row expandable"></tr>

    </table>
  </div>
</div>
