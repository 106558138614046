import { ERuleMatchType, ERuleTagVariableSelectorType } from "../../rule-setup-conditions-tab.enums";
import {
  ITagVariableSelector
} from '@app/components/rules/rule-setup/tabs/conditions-tab/shared/rule-tag-variable-filter/rule-tag-variable-filter.models';

export const TagVariableOperators = [
  { name: 'equals', value: ERuleMatchType.Equals },
  { name: 'equals (ignore case)', value: ERuleMatchType.EqualIgnoreCase },
  { name: 'does not equal', value: ERuleMatchType.DoesNotEqual },
  { name: 'does not equal (ignore case)', value: ERuleMatchType.NotEqualIgnoreCase },
  { name: 'contains', value: ERuleMatchType.Contains },
  { name: 'contains (ignore case)', value: ERuleMatchType.ContainsIgnoreCase },
  { name: 'does not contain', value: ERuleMatchType.DoesNotContain },
  { name: 'does not contain (ignore case)', value: ERuleMatchType.DoesNotContainIgnoreCase },
  { name: 'is set', value: ERuleMatchType.IsSet },
  { name: 'is not set', value: ERuleMatchType.IsNotSet },
  { name: 'regex', value: ERuleMatchType.Regex },
  { name: '>=', value: ERuleMatchType.GreaterThanOrEqualTo },
  { name: '<=', value: ERuleMatchType.LessThanOrEqualTo }
];

export const TagVariableSelectors: ITagVariableSelector[] = [
  { name: 'String', value: ERuleTagVariableSelectorType.String },
  { name: 'Initial Page URL Query String Parameter', value: ERuleTagVariableSelectorType.UrlParameter },
  { name: 'Final Page URL Query String Parameter', value: ERuleTagVariableSelectorType.FinalUrlParams },
  { name: 'Data Layer', value: ERuleTagVariableSelectorType.DataLayer },
];
