import { Route } from '@angular/router';
import { NoReadOnlyGuard } from '../core/permissions.guard';
import { RfmLibraryComponent } from './rfm-library.component';
import { RfmLibraryPaths, RfmLibraryStateNames } from './rfm-library.constants';

export const RfmFutureState: Route = {
  path: RfmLibraryPaths.base,
  canLoad: [ NoReadOnlyGuard ],
  loadChildren: () => import('./rfm-library.module').then(mod => mod.RfmLibraryModule),
};

export const RfmLibraryRoutes: Route = {
  path: '',
  component: RfmLibraryComponent,
  data: {
    stateName: RfmLibraryStateNames.base,
    title: 'File Substitutions',
    betaLabel: false,
  }
};
