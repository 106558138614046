import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpEventType,
  HttpEvent,
  HttpResponse,
  HttpProgressEvent,
} from '@angular/common/http';
import { Observable ,  merge ,  fromEvent } from 'rxjs';
import { filter, map, concatMap, catchError } from 'rxjs/operators';

type UploadParams = HttpParams | { [param: string]: string | string[] };

enum EContentType {
  OctetStream = 'application/octet-stream',
  MultipartForm = 'multipart/form-data'
}

interface IUploadOptions {
  progress: boolean;
  contentType?: EContentType;
}

interface ProgressEvent extends HttpProgressEvent {
  progressPercentage: number;
}

@Injectable()
export class FileUploadService {
  
  constructor(private http: HttpClient) {}

  uploadHarFile(url: string, data: FormData, params: HttpParams) {
    return this.http.post(url, data, {
      params,
      observe: 'events',
      reportProgress: true,
    });
  }

  uploadFile(data: File | ArrayBuffer,
             url: string,
             params: UploadParams = {},
             uploadOptions: IUploadOptions = {
               progress: false,
               contentType: EContentType.OctetStream
            }) {

    return this.http.post(url, data, {
      headers: { 'Content-Type': uploadOptions.contentType },
      params,
      observe: 'events',
      reportProgress: uploadOptions.progress,
    });
  }

  uploadFileWithProgress<T>(file: File,
                            url: string,
                            params: UploadParams = {}): Observable<ProgressEvent | HttpResponse<T>> {

    const eventFilter = (evType: HttpEventType) => ev => ev.type === evType;
    // TODO: this may need to change depending on the final backend implementation for the file
    // upload endpoint.
    const upload$ = this.uploadFile(file, url, params, {
      progress: true,
      contentType: EContentType.OctetStream,
    });
    const progress$ = upload$.pipe(
      filter(eventFilter(HttpEventType.UploadProgress)),
      map(this.addProgressPercentage)
    );
    const response$ = upload$.pipe(filter(eventFilter(HttpEventType.Response)));

    return merge<ProgressEvent, HttpResponse<T>>(progress$, response$);
  }

  uploadAppFile(url: string, data: FormData) {
    return this.http.put(url, data, {
      observe: 'events',
      reportProgress: true,
    });
  }

  private fileToArrayBuffer(file: File) {
    const reader = new FileReader();
    const loaded$ = fromEvent(reader, 'loadend');

    reader.readAsArrayBuffer(file);

    return loaded$.pipe(map(ev => reader.result));
  }

  private addProgressPercentage(ev: HttpProgressEvent) {
    return {
      ...ev,
      progressPercentage: ev.loaded / ev.total,
    };
  }
}
