import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import {
  IActionDetailsRequestLogObject,
  WebJourneyActionDetailsService
} from '@app/components/domains/webJourneys/webJourneyAPI/web-journey-action-details.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { statusCodeThresholdClass } from './request-log.constants';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import {
  IAuditRequestLogContent,
  IAuditRequestLogData,
  IAuditRequestLogParams,
  IAuditRequestLogRelatedCookies,
  IAuditRequestLogRelatedCookiesDisplay,
  IFileInfoData
} from '@app/components/audit-reports/page-details/page-details.models';
import { PageDetailsReportService } from '@app/components/audit-reports/page-details/page-details.service';
import { MatPaginator } from '@angular/material/paginator';
import { catchError, debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import {
  REQUEST_LOG_PAGE_INDEX_KEY,
  REQUEST_LOG_SEARCH_TEXT_KEY,
  REQUEST_LOG_SHOW_FILE_CHANGES_KEY,
  REQUEST_LOG_SHOW_PRE_AUDIT_ACTION_REQUESTS_KEY,
  REQUEST_LOG_SORT_BY_KEY,
  REQUEST_LOG_SORT_DESC_KEY
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import { EDateFormats, formatDate } from '@app/components/date/date.service';
import { ChangeType } from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.models';
import { Features } from '@app/moonbeamConstants';
import { JsFileChangesReportUtils } from '@app/components/utilities/js-file-changes-report.utils';
import { DecimalPipe } from '@angular/common';
import {
  EPageDetailsRequestOriginLabels,
  IPageDetailsRequestLogSortColumn,
  IPageDetailsRequestLogTableAuditRow,
  IPageDetailsRequestLogTableColumn,
  IPageDetailsRequestLogTableRow,
  IPageDetailsRequestLogTableWebJourneyRow
} from '@app/components/shared/components/request-log-table/request-log-table.models';
import { bytesToKB } from '@app/components/utilities/number.utils';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'request-log-table',
  templateUrl: './request-log-table.component.html',
  styleUrls: ['./request-log-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  providers: [DecimalPipe]
})
export class RequestLogTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  EReportType = EReportType;
  TableColumn = IPageDetailsRequestLogTableColumn;

  private mkColumnsToDisplay = (showJSFileDiffs: boolean): IPageDetailsRequestLogTableColumn[] => {
    const TC = IPageDetailsRequestLogTableColumn;
    if (this.reportType === EReportType.AUDIT) {
      const jsFileDiffsCols: IPageDetailsRequestLogTableColumn[] = showJSFileDiffs ? [TC.DATE_DIFFERENCE, TC.SIZE_DIFFERENCE] : [];
      return [TC.EXPAND, TC.URL, TC.TAG, TC.COOKIE_INITIATORS, TC.ORIGIN, ...jsFileDiffsCols, TC.RESPONSE_SIZE, TC.LOAD_TIME, TC.STARTED_AT, TC.STATUS_CODE, TC.GEO_LOCATION];
    } else {
      return [TC.EXPAND, TC.URL, TC.LOAD_TIME, TC.REQUEST_METHOD, TC.STATUS_CODE, TC.GEO_LOCATION];
    }
  }
  search: string;
  showJSFileDifferences: boolean = false;
  privacyFeatureEnabled: boolean = false;
  showPreAuditActionRequests: boolean = false;

  columnsToDisplay: string[] = [];
  dataSource = new MatTableDataSource<IPageDetailsRequestLogTableRow>();
  expandedRequest: IActionDetailsRequestLogObject;
  tableLoading: boolean = false;
  tags: IUiTag[];
  requestLogData: IActionDetailsRequestLogObject[] = [];

  // pages table paginator
  totalNumPages: number;

  sortColumn: IPageDetailsRequestLogTableColumn = IPageDetailsRequestLogTableColumn.STARTED_AT;
  requestLogQueryParams: IAuditRequestLogParams = {
    // defaults
    page: 0, // API paginates starting with 0
    size: 100,
    search: null,
    showFileChanges: false,
    showPreAuditActionRequests: false,
    sortBy: 'started_at',
    sortDesc: false
  };

  private tableStateUpdated$: Subject<IAuditRequestLogParams>;
  private auditSortSubscription: Subscription;
  private auditPageSubscription: Subscription;
  private searchSubscription: Subscription;

  private destroy$ = new Subject();
  private keyUp$ = new Subject<string>();

  readonly EPageDetailsRequestOriginLabels = EPageDetailsRequestOriginLabels;

  @Input() itemId: number;
  @Input() runId: number;
  @Input() loading: boolean = true;
  @Input() reportType: EReportType;
  @Input() pageId?: string; // specific to audits
  @Input() activeTab?: EPageDetailsTabs; // specific to audits
  @Input() actionIndex?: number; // specific to web journeys
  @Input() success?: boolean; // specific to web journeys
  @Input() state?: any;
  @Input() visitStartTimestamp?: string;
  @Input() renderTable?: boolean = true; // used to render the table conditionally to prevent slow loading in journeys
  @Input() isScrolled: boolean = false;
  @Input() accountFeatures: Features[] = [];
  @Output() stateUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() navToTab: EventEmitter<{ tab: EPageDetailsTabs, searchValue: string, updateActiveTab?: boolean }> = new EventEmitter();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private actionDetailsService: WebJourneyActionDetailsService,
    private pageDetailsService: PageDetailsReportService,
    private uiTagService: UiTagService,
    private decimalPipe: DecimalPipe,
  ) {
    this.tableStateUpdated$ = new Subject<IAuditRequestLogParams>();
    this.tags = [];
    this.externalToInternalState();
  }

  ngOnInit() {
    this.tableStateUpdated$
      .pipe(
        debounceTime(400),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.getRequestLogData();
      });
  }

  private reloadTable() {
    this.tableStateUpdated$.next({ ...this.requestLogQueryParams });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.reportType === EReportType.WEB_JOURNEY) {
      // triggers the loading spinner every time we change actions
      this.loading = true;

      this.initForWebJourneys();

      if (changes.renderTable) {
        this.formatRequestLogDataForWebJourneys(this.requestLogData);
      }
    }

    // `changes.pageId` prevents the `isScrolled` input from causing
    // the table data to be refreshed which collapses any open rows
    // and only gets data if the pageId changes
    if (this.reportType === EReportType.AUDIT && changes.pageId) {
      this.initForAudits();
    }

    if (this.reportType === EReportType.AUDIT && changes.visitStartTimestamp) {
      this.initForAudits();
    }
  }

  ngAfterViewInit(): void {
    if (this.reportType === EReportType.AUDIT) {
      this.initForAudits();
    } else if (this.reportType === EReportType.WEB_JOURNEY) {
      this.initForWebJourneys();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForAudits(): void {
    if (!this.visitStartTimestamp) return;
    this.externalToInternalState();
    this.handleSearching();
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: IPageDetailsRequestLogTableAuditRow, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case this.TableColumn.TAG: return data.dataItem.tagId;
        case this.TableColumn.COOKIE_INITIATORS: return data.dataItem.relatedCookies.length;
        case this.TableColumn.RESPONSE_SIZE: return data.dataItem.responseSizeBytes;
        case this.TableColumn.LOAD_TIME: return data.dataItem.loadTime;
        case this.TableColumn.STARTED_AT: return data.dataItem.startedAt;
        case this.TableColumn.DATE_DIFFERENCE:
          const isDateDiffDefined = typeof data.dataItem.fileChange?.largestDateDifference === 'number';
          return data.dataItem.fileChange
            ? isDateDiffDefined
              ? Math.abs(data.dataItem.fileChange.largestDateDifference)
              : this.calculatePrioAccordingToChangeType(data.fileChangeType)
            : -2; // it is not a JS file, set a lower prio for it
        case this.TableColumn.SIZE_DIFFERENCE:
          const isSizeDiffDefined = typeof data.dataItem.fileChange?.largestSizeDifference === 'number';
          return data.dataItem.fileChange
            ? isSizeDiffDefined
              ? Math.abs(data.dataItem.fileChange.largestSizeDifference)
              : this.calculatePrioAccordingToChangeType(data.fileChangeType)
            : -2; // it is not a JS file, set a lower prio for it
        case this.TableColumn.STATUS_CODE:
          // Force 0 status code requests to the bottom of the list, so they live next to error codes in terms of sorting
          return !data.dataItem.statusCode ? 999 : data.dataItem.statusCode;
        default: return String(data[sortHeaderId]).toLowerCase();
      }
    };

    this.privacyFeatureEnabled = this.accountFeatures.includes(Features.productLinePrivacy);
    this.columnsToDisplay = this.mkColumnsToDisplay(this.showJSFileDifferences && this.privacyFeatureEnabled);
    if (this.auditSortSubscription) { this.auditSortSubscription.unsubscribe(); }
    this.auditSortSubscription = this.sort && this.sort.sortChange.subscribe((sort: Sort) => {
      this.sortColumn = sort.active as IPageDetailsRequestLogTableColumn;
      this.requestLogQueryParams.sortBy = this.tableColumnToSortColumn(this.sortColumn);
      this.requestLogQueryParams.sortDesc = sort.direction === 'desc';
      this.requestLogQueryParams.page = 0;
      this.internalToExternalState();
      this.reloadTable();
    });
    if (this.auditPageSubscription) { this.auditPageSubscription.unsubscribe(); }
    this.auditPageSubscription = this.paginator && this.paginator.page.subscribe(({pageIndex}: {pageIndex: number}) => {
      if (typeof pageIndex === 'number') {
        this.requestLogQueryParams.page = pageIndex;
        this.internalToExternalState();
        this.reloadTable();
      }
    });
    this.uiTagService.getAllTagsData().subscribe(([tags]) => {
      this.tags = tags;
      this.reloadTable();
    });
  }

  private externalToInternalState(): void {
    this.state = this.state || {};
    if (this.state.hasOwnProperty(REQUEST_LOG_SEARCH_TEXT_KEY)) {
      this.search = this.state[REQUEST_LOG_SEARCH_TEXT_KEY] || '';
      this.requestLogQueryParams.search = this.search;
    }

    if (this.state.hasOwnProperty(REQUEST_LOG_SHOW_PRE_AUDIT_ACTION_REQUESTS_KEY)) {
      this.showPreAuditActionRequests = this.state[REQUEST_LOG_SHOW_PRE_AUDIT_ACTION_REQUESTS_KEY] === true || this.state[REQUEST_LOG_SHOW_PRE_AUDIT_ACTION_REQUESTS_KEY] === 'true';
      this.requestLogQueryParams.showPreAuditActionRequests = this.showPreAuditActionRequests;
    }

    if (this.state.hasOwnProperty(REQUEST_LOG_SHOW_FILE_CHANGES_KEY)) {
      this.showJSFileDifferences = this.state[REQUEST_LOG_SHOW_FILE_CHANGES_KEY] === true || this.state[REQUEST_LOG_SHOW_FILE_CHANGES_KEY] === 'true';
      this.requestLogQueryParams.showFileChanges = this.showJSFileDifferences;
    }
    if (this.state.hasOwnProperty(REQUEST_LOG_SORT_BY_KEY) && this.state[REQUEST_LOG_SORT_BY_KEY]) {
      this.requestLogQueryParams.sortBy = this.state[REQUEST_LOG_SORT_BY_KEY];
    }
    this.sortColumn = this.sortColumnToTableColumn(this.requestLogQueryParams.sortBy);
    if (this.state.hasOwnProperty(REQUEST_LOG_SORT_DESC_KEY)) {
      this.requestLogQueryParams.sortDesc = this.state[REQUEST_LOG_SORT_DESC_KEY] === true || this.state[REQUEST_LOG_SORT_DESC_KEY] === 'true';
    }
    if (this.state.hasOwnProperty(REQUEST_LOG_PAGE_INDEX_KEY) && this.state[REQUEST_LOG_PAGE_INDEX_KEY]) {
      this.requestLogQueryParams.page = this.state[REQUEST_LOG_PAGE_INDEX_KEY];
    }
  }

  private internalToExternalState(): void {
    const newState = {};
    if (this.search) {
      newState[REQUEST_LOG_SEARCH_TEXT_KEY] = this.search;
    }

    newState[REQUEST_LOG_SHOW_FILE_CHANGES_KEY] = this.showJSFileDifferences;
    newState[REQUEST_LOG_SHOW_PRE_AUDIT_ACTION_REQUESTS_KEY] = this.showPreAuditActionRequests;

    if (this.requestLogQueryParams?.sortBy) {
      newState[REQUEST_LOG_SORT_BY_KEY] = this.requestLogQueryParams.sortBy;
    }
    newState[REQUEST_LOG_SORT_DESC_KEY] = this.requestLogQueryParams?.sortDesc || false;
    if (this.requestLogQueryParams?.page) {
      newState[REQUEST_LOG_PAGE_INDEX_KEY] = this.requestLogQueryParams.page;
    }
    this.state = { ...this.state, ...newState };
    this.stateUpdated.emit(newState);
  }

  getRequestLogData(): void {
    this.loading = true;
    if (this.reportType === EReportType.AUDIT) {
      this.pageDetailsService.getAuditPageRequestLog(
        this.itemId,
        this.runId,
        this.pageId,
        this.requestLogQueryParams,
        this.privacyFeatureEnabled
      ).pipe(
        finalize(() => { this.tableLoading = false; })
      ).subscribe((response: IAuditRequestLogData) => {
        this.totalNumPages = response.metadata.pagination.totalCount;
        this.formatRequestLogDataForAudits(response.requests);
      });
    } else {
      this.actionDetailsService
        .getRequestLogs(this.itemId, this.runId, this.actionIndex)
        .pipe(finalize(() => { this.tableLoading = false; }), catchError((error) => {
          console.error('Error fetching request logs:', error);
          return of([]);
        }))
        .subscribe((response: IActionDetailsRequestLogObject[]) => {
          this.actionDetailsService.requestLogCountSubject.next(response.length);
          this.requestLogData = response;

          if (this.renderTable) {
            this.formatRequestLogDataForWebJourneys(response);
          }
        });
    }
  }

  private formatRequestLogDataForAudits(data: IAuditRequestLogContent[]): void {
    this.dataSource.data = data
      .map((request: IAuditRequestLogContent) => {
        const tag = this.tags.find((tag: IUiTag) => tag.id === request.tagId);
        return {
          dataItem: request,
          tagName: tag ? tag.name : null,
          tagCategory: tag ? this.uiTagService.getTagCategory(tag.tagCategoryId).category : null,
          tagIcon: tag ? UiTagService.getTagIconUrl(request.tagId) : null,
          tooltip: tag ? `${tag.name} | ${this.uiTagService.getTagCategory(tag.tagCategoryId).category}` : '',
          origin: EPageDetailsRequestOriginLabels[request.origin],
          originTooltip: request.origin === 'ON_PAGE' ? 'Page Load or On-Page Action' : EPageDetailsRequestOriginLabels[request.origin],
          mimeType: request.mimeType || '---',
          ...this.formatFileChangeFields(request),
          responseSize: request.responseSizeBytes.toLocaleString(),
          ...this.formatFileInfoFields(request),
          url: request.requestUrl || '',
          statusCode: request.statusCode || 0,
          statusCodeTooltip: PageStatusCodeTooltipMap[request.statusCode] || null,
          statusCodeClass: this.getStatusCodeClass(request.statusCode || 0),
          loadTime: `${request.loadTime} ms`,
          loadTimeClass: this.getLoadTimeClass(request.loadTime),
          startedAt: new Date(request.startedAt).getTime() - new Date(this.visitStartTimestamp).getTime(),
          startedAtFullDate: formatDate(new Date(request.startedAt), EDateFormats.dateThirtyOne),
          geoLocation: request?.geoLocationInfo?.countryCode,
          geolocationExpandedValue: request.geoLocation,
          cookiesInitiated: this.getCookiesInitiatedData(request.relatedCookies),
          blocked: !!request.blockingResults
        } as IPageDetailsRequestLogTableAuditRow;
      })
      .sort((a, b) => a.startedAt - b.startedAt);

    this.loading = false;
  }

  private getCookiesInitiatedData(cookies: IAuditRequestLogRelatedCookies[]): IAuditRequestLogRelatedCookiesDisplay[] {

    return cookies.map((cookie: IAuditRequestLogRelatedCookies, index: number) => {
      return {
        ...cookie,
        display: `${index + 1}. ${cookie.name} (${cookie.domain})`
      };
    });
  }

  private calculatePrioAccordingToChangeType(changeTypeValue: string) {
    switch (changeTypeValue) {
      case ChangeType.changed_file: return -1.1;
      case ChangeType.not_changed: return -1.2;
      case ChangeType.unknown: return -1.3;
      case ChangeType.new_file: return -1.4;
      case ChangeType.deleted_file: return -1.5;
      default: return -1.6;
    }
  }

  private formatFileChangeFields(request: IAuditRequestLogContent): {
    fileChangeType?: string;
    largestDateDifference?: string;
    largestSizeDifference?: string;
  } {
    if (!request.fileChange) {
      return { largestDateDifference: '---', largestSizeDifference: '---' };
    }
    const fileChangeType = request.fileChange?.fileChangeType && ChangeType[request.fileChange.fileChangeType];
    const largestDateDifference = JsFileChangesReportUtils.formatDiffValueAccordingToChangeType(
      fileChangeType,
      request.fileChange.largestDateDifference,
      JsFileChangesReportUtils.formatDateDifference(request.fileChange.largestDateDifference)
    );
    const largestSizeDifference = JsFileChangesReportUtils.formatDiffValueAccordingToChangeType(
      fileChangeType,
      request.fileChange.largestSizeDifference,
      JsFileChangesReportUtils.formatSizeDifference(
        request.fileChange.largestSizeDifference,
        value => this.decimalPipe.transform(value)
      )
    );
    return { fileChangeType, largestDateDifference, largestSizeDifference };
  }

  private formatFileInfoFields(request: IAuditRequestLogContent): IFileInfoData {
    if (!request.fileInfo) {
      return {} as any;
    }
    return {
      lastModifiedDate: request.fileInfo.lastModifiedDate
        ? formatDate(new Date(request.fileInfo.lastModifiedDate), EDateFormats.dateEleven)
        : undefined,
      filename: request.fileInfo.filename || undefined,
      requestDomain: request.fileInfo.requestDomain || undefined
    };
  }

  filterTable(value: string): void {
    this.reportType === EReportType.WEB_JOURNEY
    ? this.filterForWebJourney(value)
    : this.keyUp$.next(value);
  }

  filterForWebJourney(value: string): void {
    this.dataSource.filter = value?.trim()?.toLowerCase();
  }

  private handleSearching(): void {
    if (this.reportType === EReportType.WEB_JOURNEY && this.search) {
      this.filterTable(this.search);
    }

    if (this.reportType === EReportType.AUDIT) {
      if (this.searchSubscription) { this.searchSubscription.unsubscribe(); }
      this.searchSubscription = this.keyUp$.pipe(
        debounceTime(600),
        takeUntil(this.destroy$)
      ).subscribe((search: string) => {
        this.search = search;
        this.requestLogQueryParams.search = search;
        this.internalToExternalState();
        this.reloadTable();
      });
    }
  }

  setFileChangesColumnsAndLoadData(newValue: boolean) {
    this.showJSFileDifferences = newValue;
    this.requestLogQueryParams.showFileChanges = newValue;
    this.internalToExternalState();
    this.columnsToDisplay = this.mkColumnsToDisplay(this.showJSFileDifferences);
    this.reloadTable();
  }

  setPreAuditActionFlagAndLoadData(newValue: boolean) {
    this.showPreAuditActionRequests = newValue;
    this.requestLogQueryParams.showPreAuditActionRequests = newValue;
    this.internalToExternalState();
    this.reloadTable();
  }

  private tableColumnToSortColumn(
    tableColumnValue: IPageDetailsRequestLogTableColumn
  ): IPageDetailsRequestLogSortColumn | undefined {
    switch (tableColumnValue) {
      case IPageDetailsRequestLogTableColumn.URL: return 'request_url';
      case IPageDetailsRequestLogTableColumn.TAG: return 'tag_name';
      case IPageDetailsRequestLogTableColumn.ORIGIN: return 'mime_type';
      case IPageDetailsRequestLogTableColumn.RESPONSE_SIZE: return 'response_size';
      case IPageDetailsRequestLogTableColumn.LOAD_TIME: return 'load_time';
      case IPageDetailsRequestLogTableColumn.STARTED_AT: return 'started_at';
      case IPageDetailsRequestLogTableColumn.STATUS_CODE: return 'status_code';
      case IPageDetailsRequestLogTableColumn.GEO_LOCATION: return 'geo';
      case IPageDetailsRequestLogTableColumn.DATE_DIFFERENCE: return 'date_difference';
      case IPageDetailsRequestLogTableColumn.SIZE_DIFFERENCE: return 'size_difference';
      default: return undefined;
    }
  }

  private sortColumnToTableColumn(
    sortColumn: IPageDetailsRequestLogSortColumn
  ): IPageDetailsRequestLogTableColumn | undefined {
    switch (sortColumn) {
      case 'request_url': return IPageDetailsRequestLogTableColumn.URL;
      case 'tag_name': return IPageDetailsRequestLogTableColumn.TAG;
      case 'mime_type': return IPageDetailsRequestLogTableColumn.ORIGIN;
      case 'response_size': return IPageDetailsRequestLogTableColumn.RESPONSE_SIZE;
      case 'load_time': return IPageDetailsRequestLogTableColumn.LOAD_TIME;
      case 'started_at': return IPageDetailsRequestLogTableColumn.STARTED_AT;
      case 'status_code': return IPageDetailsRequestLogTableColumn.STATUS_CODE;
      case 'geo': return IPageDetailsRequestLogTableColumn.GEO_LOCATION;
      case 'date_difference': return IPageDetailsRequestLogTableColumn.DATE_DIFFERENCE;
      case 'size_difference': return IPageDetailsRequestLogTableColumn.SIZE_DIFFERENCE;
      default: return undefined;
    }
  }

  private getStatusCodeClass(code: number): string {
    const firstDigit = +code.toString().substr(0, 1);
    return statusCodeThresholdClass[firstDigit];
  }

  private getLoadTimeClass(time: number): string {
    if (time > 9000) {
      return 'load-time-red';
    } else if (time > 5000) {
      return 'load-time-orange';
    } else if (time > 2000) {
      return 'load-time-yellow';
    } else {
      return 'load-time-green';
    }
  }

  private sizeBytesToKb(fileSize: number): number {
    return fileSize && fileSize > 0
      ? fileSize >= 100 ? bytesToKB(fileSize) : 0.1
      : 0;
  }

  // ------------------------- WebJourney --------------------------
  private initForWebJourneys() {
    this.externalToInternalState();
    this.handleSearching();
    this.dataSource.sort = this.sort;

    this.columnsToDisplay = this.mkColumnsToDisplay(this.showJSFileDifferences && this.privacyFeatureEnabled);
    if (this.success) {
      this.reloadTable();
    } else if (this.success === false) {
      /**
       * explicitly looking for false condition so this doesn't fire during load because
       * we don't want to override the '...' with 0 while loading.
       */
      this.handleJourneyActionFailureState();
    }
  }

  private formatRequestLogDataForWebJourneys(data: IActionDetailsRequestLogObject[]): void {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'statusCode':
          // Force 0 status code requests to the bottom of the list, so they live next to error codes in terms of sorting
          return item.statusCode === 0 ? 999 : item.statusCode;
        case 'loadTime':
          const value = item.loadTime.split(' ')[0];
          return +value;
        default:
          return String(item[property]).toLowerCase();
      }
    };

    this.dataSource.data = data
      .map((request: IActionDetailsRequestLogObject) => {
        return {
          dataItem: request,
          url: request?.request?.url || '',
          method: request.request.method || '',
          responseSize: request.response?.content?.size.toLocaleString(),
          statusCode: request?.response?.status || 0,
          statusCodeTooltip: PageStatusCodeTooltipMap[request?.response?.status] || null,
          statusCodeClass: this.getStatusCodeClass(request?.response?.status || 0),
          loadTime: `${request?.time} ms` || '',
          loadTimeClass: this.getLoadTimeClass(request?.time),
          geoLocation: request?.locationData?.country || '',
          geolocationExpandedValue: request?.locationData?.country || '',
          requestHeaders: request?.request?.headers,
          responseHeaders: request?.response?.headers,
          blocked: !!request?.request?.requestBlocking

        } as IPageDetailsRequestLogTableWebJourneyRow;
      })
      .sort((a: any, b: any) => (a.url.toLowerCase() > b.url.toLowerCase()) ? 1 : -1);

    this.loading = false;
  }

  private handleJourneyActionFailureState(): void {
    this.dataSource.data = [];
    this.actionDetailsService.requestLogCountSubject.next(0);
    this.loading = false;
  }

  viewDetailsOnCookiesTab(cookie: IAuditRequestLogRelatedCookiesDisplay): void {
    this.navToTab.emit({ searchValue: `${cookie.name} ${cookie.domain}`, tab: EPageDetailsTabs.Cookies, updateActiveTab: true });
  }
}
