import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IDomain } from '@app/components/domains/domainsService';
import { IFolder } from '@app/components/folder/foldersApiService';
import { IButton } from '@app/models/commons';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EDataSourceType } from '@app/components/shared/services/data-sources/data-sources.constants';
import {
  EFolderMode,
  ESubFolderMode,
  ICreateFolderAndSubFolderModalData,
  IFolderAndSubFolderForm,
  ISubFoldersByFolderId
} from '@app/components/folder-and-sub-folder-modal/folder-and-sub-folder-modal.models';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'folder-and-sub-folder-modal',
  templateUrl: './folder-and-sub-folder-modal.component.html',
  styleUrls: ['./folder-and-sub-folder-modal.component.scss']
})
export class FolderAndSubFolderModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  dataLayerPlaceholder: string;
  EFolderMode = EFolderMode;
  ESubFolderMode = ESubFolderMode;

  itemType: EDataSourceType = EDataSourceType.Audit;
  modalTitle = `Create new folder / sub-folder`;

  subFoldersByFolderId: ISubFoldersByFolderId = {};
  folders: IFolder[] = [];
  subFolders: IDomain[] = [];
  availableSubFolders: IDomain[] = [];
  folderAndSubFolderForm: FormGroup<IFolderAndSubFolderForm>;
  currentFolderId: number;
  currentSubFolderId: number;
  useDefaultDomain: boolean;
  folderMode: EFolderMode;
  subFolderMode: ESubFolderMode;

  rightFooterButtons: IButton[] = [
    {
      label: 'Save',
      action: () => this.save(),
      primary: true,
      disabled: true
    }
  ];

  @ViewChild(MatInput) folderCreate: MatInput;
  @ViewChild(MatInput) subFolderCreate: MatInput;
  @ViewChild('folderSelect') folderSelect: MatSelect;
  @ViewChild('subFolderSelect') subFolderSelect: MatSelect;

  constructor(
    private dialogRef: MatDialogRef<FolderAndSubFolderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICreateFolderAndSubFolderModalData,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    const {
      folders,
      subFolders,
      subFoldersByFolderId,
      currentFolderId,
      currentSubFolderId,
      useDefaultDomain,
      mode,
      itemType,
    } = this.data;

    this.folders = folders;
    this.subFolders = subFolders;
    this.subFoldersByFolderId = subFoldersByFolderId;
    this.currentFolderId = currentFolderId;
    this.currentSubFolderId = currentSubFolderId;
    this.useDefaultDomain = useDefaultDomain;
    this.folderMode = mode;
    this.subFolderMode = mode === EFolderMode.Create ? ESubFolderMode.Create : ESubFolderMode.Select;
    this.itemType = itemType;
    this.dataLayerPlaceholder = this.itemType === EDataSourceType.Audit ? 'Enter data layer(s) you expect to find in the pages audited in this folder.' : 'Enter data layer(s) you expect to find in the pages scanned in this folder.';

    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  save(): void {
    const formValues = this.folderAndSubFolderForm.value;
    this.closeModal({
      ...formValues,
      folderMode: this.folderMode,
      subFolderMode: this.subFolderMode,
    });
  }

  initForm(): void {
    this.folderAndSubFolderForm = this.fb.group({
      folder: ['', [Validators.required, Validators.minLength(1)]],
      subFolder: ['', [Validators.required, Validators.minLength(1)]],
      dataLayer: [''],
    });

    this.subFolder.disable();

    this.folder.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(folder => {
        if (folder) {
          this.subFolder.enable();
        } else {
          this.subFolder.disable();
        }
      });

    this.folderAndSubFolderForm
      .valueChanges
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe(() => {
        this.rightFooterButtons[0].disabled = this.folderAndSubFolderForm.invalid;
      });
  }

  onSwitchFolderMode(mode: EFolderMode): void {
    this.folderMode = mode;
    this.folder.setValue('');

    if (mode === EFolderMode.Create) {
      this.subFolderMode = ESubFolderMode.Create;
      this.subFolder.setValue('');
      this.availableSubFolders = [];
      setTimeout(() => {
        this.folderCreate.focus();
      }, 100);
    } else {
      // Open the select dropdown
      setTimeout(() => {
        this.folderSelect.open();
      });
    }
  }

  onSwitchSubFolderMode(mode: ESubFolderMode): void {
    this.subFolderMode = mode;

    // Reset field if changing to create or no existing sub-folders
    if (this.availableSubFolders.length === 0 || this.subFolderMode === ESubFolderMode.Create) {
      this.subFolder.setValue('');
      setTimeout(() => {
        this.subFolderCreate.focus();
      }, 100);
    } else {
      this.subFolder.setValue(this.availableSubFolders[0]);
      setTimeout(() => {
        this.subFolderSelect.open();
      });
    }
  }

  onFolderChange(): void {
    const selectedFolderId = this.folder.value.id;
    this.getSubFoldersOfFolder(selectedFolderId);

    if (this.availableSubFolders.length > 0) {
      this.subFolderMode = ESubFolderMode.Select;
      this.subFolder.markAsTouched();
    } else {
      this.subFolderMode = ESubFolderMode.Create;
      this.subFolder.setValue('');

      setTimeout(() => {
        this.subFolderCreate.focus();
      }, 100);
    }
  }

  onSubFolderChange(): void {
    this.dataLayer.setValue(this.subFolder.value?.dataLayer);
  }

  getSubFoldersOfFolder(folderId: number): void {
    this.availableSubFolders = this.subFolders.filter((subFolder: IDomain) => subFolder.folderId === folderId);
  }

  closeModal(values?: any): void {
    this.dialogRef.close(values);
  }

  get folder(): AbstractControl {
    return this.folderAndSubFolderForm.get('folder');
  }

  get subFolder(): AbstractControl {
    return this.folderAndSubFolderForm.get('subFolder');
  }

  get dataLayer(): AbstractControl {
    return this.folderAndSubFolderForm.get('dataLayer');
  }
}
