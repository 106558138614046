import {
  EBarChartDirection,
  EBarChartTextPosition
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.constants';
import { EChartColor } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { EAuditReportFilterTypes } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { IAuditReportExportMenuData } from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import { IPageUrlFilter } from '@app/components/audit-reports/audit-report/audit-report.models';
import { CommonReportsPagesFiltersConfig } from '@app/components/audit-reports/reports/general-reports.constants';

export interface IPrivacyRequestsTableState {
  sortBy: string;
  sortDesc: boolean;
  page: number;
  size: number;
}

export interface IPrivacyRequestsSummary {
  totalPageCount: number;
  filteredPageCount: number;
  totalRequestCount: number;
  filteredRequestCount: number;
  filteredUniqueRequestDomainCount: number;
  filteredUniqueRequestGeoCount: number;
  filteredUniqueApprovedRequestDomainCount: number;
  filteredUniqueUnapprovedRequestDomainCount: number;
  filteredPageWithUnapprovedRequestCount: number
  totalUniqueApprovedRequestDomainCount: number;
  totalUniqueUnapprovedRequestDomainCount: number;
}

export interface IPrivacyRequestsTrend {
  runId: number;
  completedAt: string;
  totalRequestCount: number;
  totalUniqueRequestDomainCount: number;
  totalUniqueRequestGeoCount: number;
  totalUniqueApprovedRequestDomainCount: number;
  totalUniqueUnapprovedRequestDomainCount: number
}
export interface IPrivacyRequestsTrends {
  runs: IPrivacyRequestsTrend[];
}

interface IPrivacyRequestsSpecificTrend {
  runId: number;
  completedAt: string;
  trendValue: number;
}

export interface IPrivacyRequestsSpecificTrends {
  runs: IPrivacyRequestsSpecificTrend[];
}

export interface IPrivacyRequestComplianceRow {
  requestDomain: string;
  requestGeo: {
    countryCode: string;
    countryName: string
  };
  requestCount: number | string;
  consentCategoryComplianceStatus: string;
  consentCategorySnapshotId: number;
  consentCategoryName: string;
  filteredPageCount: string
}

export interface IPrivacyRequestComplianceItem extends IPrivacyRequestComplianceRow {
  geo?: string;
  barChartSettings: {
    state: EFilterSpinnerState;
    displayPills: boolean;
    calcAsPercentage: boolean;
    displayPercentSymbol: boolean;
    textPosition: EBarChartTextPosition
  };
  onPages: {
    chartData: [
      {
        name: string;
        colorClass: EChartColor;
        filtered: boolean;
        value: number;
        displayValue: string
      }
    ];
    barDirection: EBarChartDirection;
    uniqueIdentifier: string
  }
}

export interface IPrivacyMetadata {
  pagination: {
    totalCount: number;
    totalPageCount: number;
    pageSize: number;
    currentPageSize: number;
    currentPageNumber: number
  }
}

export interface IPrivacyRequestCompliance {
  metadata: IPrivacyMetadata;
  requests: IPrivacyRequestComplianceRow[];
}

export interface IPrivacyRequestsPage {
  pageId: string;
  pageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  finalPageStatusCode: number;
  geoCount: number;
  requestCount: number;
}

export interface IPrivacyRequestsPages {
  metadata: IPrivacyMetadata;
  pages: IPrivacyRequestsPage[];
}

export interface IPrivacyRequestsPageRow extends IPrivacyRequestsPage {
  pageLoadTime: number;
  pageLoadTimeClass: string;
  pageStatusCodeClass: string;
}

export interface IPrivacyRequestsPagesApiBody {
  pageUrl?: IPageUrlFilter;
  requestDomain: string;
  countryCode: string;
}

export interface IPrivacyRequestsGlobalFilterApiBody {
  countryCode: string;
  countryName: string;
}

export interface IPrivacyRequestsLocations {
  locations: IPrivacyRequestsLocation[]
}

export interface IPrivacyRequestsLocation {
  requestGeo: {
    countryCode: string;
    countryName: string;
  };
  requestCount: number;
  status: string;
  consentCategorySnapshotId: number;
  consentCategoryName: string;
  approvedCount: number;
}

export const RequestsPrivacyRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,
  EAuditReportFilterTypes.RequestDomain,
  EAuditReportFilterTypes.ConsentCategoryId,
  EAuditReportFilterTypes.ConsentCategoryComplianceStatus,
  EAuditReportFilterTypes.Geolocation,
];

export type PrivacyRequestsPagesExportMenuData = IAuditReportExportMenuData<IPrivacyRequestsPagesApiBody | {}>;
export type PrivacyRequestsComplianceExportMenuData = IAuditReportExportMenuData<{}>;
