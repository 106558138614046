import { OnDestroy } from '@angular/core';
import { trigger, transition, style, animate, state, AnimationEvent } from '@angular/animations';
import { Component, Input, TemplateRef, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { EOpTooltipVisibility } from './op-tooltip-with-custom-template.enums';

@Component({
  selector: 'op-tooltip-with-custom-template',
  templateUrl: './op-tooltip-with-custom-template.component.html',
  styleUrls: ['./op-tooltip-with-custom-template.component.scss'],
  animations: [
    trigger('state', [
      state('void, hidden', style({ opacity: 0 })),
      state('visible', style({ opacity: 1 })),
      transition('* => visible', animate('200ms cubic-bezier(0, 0, 0.2, 1)')),
      transition('* => hidden', animate('100ms cubic-bezier(0, 0, 0.2, 1)'))
    ])
  ]
})
export class OpTooltipWithCustomTemplateComponent implements OnDestroy {

  @Input() contentTemplate: TemplateRef<ElementRef>;
  
  visibility = EOpTooltipVisibility.visible;

  private closedSubject = new Subject<void>();
  closed$ = this.closedSubject.asObservable();

  ngOnDestroy() {
    this.closedSubject.complete();
  }

  animationDone(event: AnimationEvent) {
    if (event.toState === EOpTooltipVisibility.hidden) {
      this.closedSubject.next();
    }
  }

  close() {
    this.visibility = EOpTooltipVisibility.hidden;
  }

}
