import * as angular from 'angular';


export class FullScreenView implements angular.IDirective {
    restrict = 'E';
    template = require('@app/components/navigation/fullScreenView/fullScreenCreator.html');
    scope = false;
    constructor() {
    }

    static Factory(): angular.IDirectiveFactory {
      const directive = () => {
        return new FullScreenView();
      };
      directive.$inject = [
      ];
      return directive;
    }
}

