import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '@app/components/shared/services/storage.service';
import { SIDEBAR_IS_CLOSED } from './sidebar.constants';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private sidebarState = new BehaviorSubject<boolean>(false);
  isClosed = this.sidebarState.asObservable();

  constructor(private storageService: StorageService) {
    const isClosed: boolean = this.storageService.getValue(SIDEBAR_IS_CLOSED);

    if (isClosed !== undefined) {
      this.toggleSidebarState(isClosed);
    }
  }

  toggleSidebarState(isClosed: boolean): void {
    this.sidebarState.next(isClosed);
    this.saveToLocalStorage(isClosed);
  }

  private saveToLocalStorage(isClosed: boolean): void {
    this.storageService.setValue(SIDEBAR_IS_CLOSED, isClosed);
  }
}
