import { Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';
import { ISearchResult } from '@app/moonbeamModels';

@Injectable({ providedIn: 'root' })
export class AccountSearchService {

  apiRoot: string = environment.apiUrl;

  constructor(private apiService: ApiService) {}

  doSearch(query: string): Observable<ISearchResult> {
    return this.apiService.get(this.apiRoot + 'search?query=' + query);
  }
}