import {EmailListValidator} from './listValidators';

export const validEmail = function() {
  return {
    require: 'ngModel',
    link: function(scope, element, attributes, ngModel) {
      ngModel.$parsers.push(function(ngModelValue: string) {
        ngModel.$setValidity('oneEmail', EmailListValidator.validateOne(ngModelValue));
        return ngModelValue;
      });
    }
  };
};
