import { ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { IUser } from '@app/moonbeamModels';
import { AccountsService } from '@app/components/account/account.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IAdvancedExportTag } from '@app/components/shared/components/export-report/export-reports.models';

export interface IExportedVendorsModal {
  tags: IAdvancedExportTag[];
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'exported-vendors',
  templateUrl: './exported-vendors-modal.component.html',
  styleUrls: ['./exported-vendors-modal.component.scss']
})

export class ExportedVendorsModalComponent
  implements OnInit, AfterViewInit {

  userData: IUser;
  tagSummaryData: IAdvancedExportTag[];
  selectedTags: IAdvancedExportTag[] = [];

  accountsSelected: {
    [k: number]: string[]
  } = {};
  allSelected = false;
  searchText = '';

  rightFooterButtons: IButton[] = [
    {
      label: 'Export',
      action: () => this.onSubmit(),
      primary: true,
      opSelector: 'advanced-export',
      disabled: true
    }
  ];

  displayedColumns = ['tagName', 'tagAccounts'];

  tableDataSource = new MatTableDataSource<IAdvancedExportTag>();

  @ViewChild(MatSort) sort: MatSort;

  readonly MAX_AVAILABLE__ACCOUNTS_FOR_SELECTION = 100;

  constructor(public dialogRef: MatDialogRef<ExportedVendorsModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IExportedVendorsModal,
              private route: ActivatedRoute,
              private accountsService: AccountsService) {
  }

  ngOnInit() {
    this.tableDataSource.filterPredicate = (data: IAdvancedExportTag, filter: string) => data.name.toLowerCase().includes(filter);
    this.tableDataSource.data = this.tagSummaryData = this.data.tags;

    this.accountsService
      .getUser()
      .subscribe((user: IUser) => this.userData = user);

    this.tagSorting({
      active: 'name',
      direction: 'desc'
    });
  }

  ngAfterViewInit() {
    this.sort
      .sortChange
      .subscribe(this.tagSorting.bind(this));
  }

  filterTags(str: string) {
    this.tableDataSource.filter = str.trim().toLowerCase();
  }

  onSelectAll(checked: boolean): void {
    if (checked) {
      this.tagSummaryData.forEach(tag => {
        tag.selected = checked;

        this.accountsSelected[tag.id] = [];
        tag.accounts.forEach(account => this.accountsSelected[tag.id].push(account));
      });
      this.selectedTags = [...this.tagSummaryData];
      this.allSelected = checked;
    } else {
      this.selectedTags = [];
      this.tagSummaryData.forEach(tag => {
        tag.selected = checked;

        delete this.accountsSelected[tag.id];
      });
    }

    this.rightFooterButtons[0].disabled = !checked;
  }

  onTagSelected(checked: boolean, tag: IAdvancedExportTag, addAllTagAccounts = true): void {
    tag.selected = checked;

    if (checked) {
      this.selectedTags.push(tag);
      this.accountsSelected[tag.id] = [];

      if (addAllTagAccounts) {
        tag.accounts.forEach((account, index) => this.accountsSelected[tag.id].push(account));
      }

    } else {
      delete this.accountsSelected[tag.id];
      this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
    }

    ArrayUtils.sortBy(this.selectedTags, 'name');

    this.rightFooterButtons[0].disabled = !this.selectedTags.length;
  }

  onAccountSelected(account: string, tag: IAdvancedExportTag) {
    const accountIdx = this.getAccountIndex(tag, account);

    if (this.accountsSelected[tag.id]?.length === this.MAX_AVAILABLE__ACCOUNTS_FOR_SELECTION && accountIdx === -1) return;
    if (!tag.selected) this.onTagSelected(true, tag, false);

    if (accountIdx === -1 || accountIdx === undefined) {
      if (!this.accountsSelected[tag.id]) this.accountsSelected[tag.id] = [];
      this.accountsSelected[tag.id].push(account);
    } else {
      this.accountsSelected[tag.id].splice(accountIdx, 1);
      if (this.accountsSelected[tag.id].length === 0) {
        delete this.accountsSelected[tag.id];
        tag.selected = false;
        this.selectedTags.splice(this.selectedTags.indexOf(tag), 1);
      }
    }

    this.rightFooterButtons[0].disabled = !this.selectedTags.length;
  }

  onSubmit() {
    const selectedTags = {
      tags: []
    };

    this.selectedTags
      .forEach((selectedTag: IAdvancedExportTag) => {
        const allTagAccounts = this.accountsSelected[selectedTag.id].length === selectedTag.accounts.length;
        const tag = {
          tagId: selectedTag.id,
          tagAccounts: [],
          allTagAccounts
        };

        if (!allTagAccounts) {
          this.accountsSelected[selectedTag.id]
            .forEach(account => tag.tagAccounts.push(account));
        }

        selectedTags.tags.push(tag);
      });

    this.dialogRef.close(selectedTags);
  }

  isAccountSelected(account: string, tag: IAdvancedExportTag) {
    return this.accountsSelected[tag.id]?.includes(account);
  }

  private getAccountIndex(tag: IAdvancedExportTag, account: string): number {
    return this.accountsSelected[tag.id]?.indexOf(account);
  }

  private tagSorting (sort: Sort) {
    this.tableDataSource.data = this.tagSummaryData.sort((a, b) => {
      if (sort.direction === 'desc') {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      }

      return b.name.toLowerCase().localeCompare(a.name.toLowerCase());
    });
  }
}
