import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../account/account.service';
import { userIsAdmin, userIsGuest, userIsStandard } from '@app/authUtils';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IPaginationMetaData } from '@app/components/consent-categories/consent-categories.models';
import { IUser } from '@app/moonbeamModels';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import {
  OpDeleteItemWarningComponent
} from '@app/components/shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { EmailInboxesPaths, EmailInboxesUIConstants } from '@app/components/email-inboxes/email-inboxes.constants';
import {
  EEmailInboxesSortColumns,
  IEmailInbox,
  IEmailInboxEditorModalPayload,
  IEmailInboxesQueryParams,
  IEmailInboxesSort,
  IEmailInboxTableData
} from '@app/components/email-inboxes/email-inboxes.models';
import { Router } from '@angular/router';
import { EmailInboxesService } from '@app/components/email-inboxes/email-inboxes.service';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import {
  EmailInboxEditorComponent
} from '@app/components/email-inboxes/email-inbox-editor/email-inbox-editor.component';
import {
  EEmailInboxModalType,
  EEmailInboxStep
} from '@app/components/email-inboxes/email-inbox-editor/email-inbox-editor.constants';
import {
  EmailInboxesFilterBarService
} from '@app/components/email-inboxes/email-inboxes-filter-bar/email-inboxes-filter-bar.service';
import {
  IEmailInboxesFilter
} from '@app/components/email-inboxes/email-inboxes-filter-bar/email-inboxes-filter-bar.models';
import {
  EEmailInboxesFilterTypes
} from '@app/components/email-inboxes/email-inboxes-filter-bar/email-inboxes-filter-bar.constants';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { differenceInHours } from 'date-fns';
import { dateFromString, DateService, EDateFormats } from '@app/components/date/date.service';
import { StorageService } from '@app/components/shared/services/storage.service';

@Component({
  selector: 'op-email-inboxes',
  templateUrl: './email-inboxes.component.html',
  styleUrls: ['./email-inboxes.component.scss'],
  animations: [
    trigger('expand', [
      state('collapsed', style({maxHeight: '0', minHeight: '0'})),
      state('loading', style({maxHeight: '60px', minHeight: '60px'})),
      state('expanded', style({maxHeight: '250px', minHeight: '{{height}}'}), {params: {height: '*'}}),
      transition('collapsed <=> expanded, loading <=> *', animate('200ms'))
    ])
  ]
})
export class EmailInboxesComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly HELP_TEXT_EXPANDED_STORAGE_KEY = 'emailInboxHelpTextExpanded';
  private destroy$ = new Subject();
  private noteSubject: Subject<IEmailInboxTableData> = new Subject();
  private sortPaginateEmailInboxes$ = new Subject();
  apiFilters: IEmailInboxesFilter; // sent to the API
  currentApiFilters: IEmailInboxesFilter[] = []; // used in the template
  CONSTANTS = {...EmailInboxesUIConstants};
  filters: EEmailInboxesFilterTypes[] = [
    EEmailInboxesFilterTypes.Name,
    EEmailInboxesFilterTypes.EmailAddress,
    EEmailInboxesFilterTypes.Label,
    EEmailInboxesFilterTypes.AssociatedAuditName,
    EEmailInboxesFilterTypes.CreatedBy,
  ];
  infoIsExpanded: boolean = false;
  isReadOnly: boolean = false;
  isAdmin: boolean = false;
  isStandard: boolean = false;
  enabledFeatures: string [];
  currentUser: IUser;
  allLabels: ILabel[] = [];
  noteSaved: boolean = false;
  noteSavedTimeout;
  expandedInbox: IEmailInboxTableData | null;
  loading = true;
  inboxes: IEmailInboxTableData[] = [];
  dataSource = new MatTableDataSource<IEmailInboxTableData>;
  displayedColumns: string[] = ['name', 'lastMessageReceived', 'notes', 'labels', 'testScenariosCount', 'messagesReceived', 'editOptions'];
  sortOptions: IEmailInboxesSort = {
    sortBy: EEmailInboxesSortColumns.NAME,
    sortDesc: false,
    sortDir: 'asc'
  };
  pagination: IPaginationMetaData = {
    pageSize: 100,
    currentPageNumber: 0,
  };
  initialInboxValueReceived: boolean = false;
  clicked: 'messages'|'notes' = 'notes';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private accountsService: AccountsService,
    private labelService: LabelService,
    private cdr: ChangeDetectorRef,
    private modalService: OpModalService,
    private router: Router,
    private emailInboxesService: EmailInboxesService,
    private snackbarService: SnackbarService,
    private emailInboxesFilterBarService: EmailInboxesFilterBarService,
    private dateService: DateService,
    private storage: StorageService,
  ) {
  }

  ngOnInit(): void {
    this.initFilters();

    this.accountsService.getUser().subscribe(user => {
      this.currentUser = user;
      this.isReadOnly = userIsGuest(user);
      this.isAdmin = userIsAdmin(user);
      this.isStandard = userIsStandard(user);
    });

    this.noteSubject.pipe(
      debounceTime(250),
      takeUntil(this.destroy$),
      distinctUntilChanged(),
    ).subscribe((inbox) => {
      this.updateNote(inbox);
    });

    this.labelService.getLabels().subscribe(labels => {
      this.allLabels = labels;

      this.sortPaginateEmailInboxes$
        .pipe(takeUntil(this.destroy$))
        .subscribe((sortPaginate) => {
          this.loadEmailInboxes();
        });

      this.emailInboxesFilterBarService.filters$
        .pipe(takeUntil(this.destroy$))
        .subscribe((filters) => {
          this.currentApiFilters = filters;
          this.apiFilters = filters.reduce((filters, filter) => {
            if (filter.type === EEmailInboxesFilterTypes.Name || filter.type === EEmailInboxesFilterTypes.EmailAddress) {
              filters[filter.type] = filter.value[0];
            } else if (filter.type !== EEmailInboxesFilterTypes.Label) {
              filters[filter.type] = filter.value;
            } else {
              if (filters[filter.type]) {
                filters[filter.type].push(filter.value);
              } else {
                filters[filter.type] = filter.value;
              }
            }

            return filters;
          }, {});

          this.pagination.currentPageNumber = 0;
          this.loadEmailInboxes();
        });
    });
  }

  ngAfterViewInit(): void {
    this.paginator.page.subscribe(({pageIndex}) => this.handlePagination(pageIndex));
  }

  ngOnDestroy(): void {
    if (this.noteSavedTimeout) clearTimeout(this.noteSavedTimeout);

    this.destroy$.next();
  }

  initFilters() {
    this.emailInboxesFilterBarService.updateSupportedFiltersList(this.filters);
  }

  handleSort(sort: Sort) {
    let data: Sort = sort;
    this.sortOptions.sortBy = (data.active as EEmailInboxesSortColumns);
    this.sortOptions.sortDesc = data.direction === 'desc';
    this.sortOptions.sortDir = data.direction;
    this.setFirstPagePaginator();
    this.sortPaginateEmailInboxes$.next();
  }

  private setFirstPagePaginator() {
    this.pagination.currentPageNumber = 0;

    if (this.inboxes) {
      this.paginator.pageIndex = 0;
    }
  }

  toggleHelpTextExpanded(): void {
    this.infoIsExpanded = !this.infoIsExpanded;
    this.storage.setValue(this.HELP_TEXT_EXPANDED_STORAGE_KEY, this.infoIsExpanded);
  }

  handlePagination(pageIndex: number) {
    this.pagination.currentPageNumber = pageIndex;
    this.sortPaginateEmailInboxes$.next();
  }

  getEmailInboxesOptions(): IEmailInboxesQueryParams {
    const {sortBy, sortDesc} = this.sortOptions;
    const page = this.pagination.currentPageNumber;
    const {pageSize} = this.pagination;

    return {page, pageSize, sortBy, sortDesc};
  }

  private expandHelpText(emailInboxes: IEmailInbox[], forceExpandHelpText = false): void {
    // At least one inbox exists and all inboxes have received zero messages
    const fromStorage: boolean = this.storage.getValue(this.HELP_TEXT_EXPANDED_STORAGE_KEY) ?? true;
    this.infoIsExpanded = fromStorage || forceExpandHelpText || emailInboxes.length && emailInboxes.reduce((acc, inbox) => acc + inbox.messagesReceived, 0) === 0;
  }

  loadEmailInboxes(forceExpandHelpText = false): void {
    this.loading = true;

    this.emailInboxesService.getEmailInboxes(this.getEmailInboxesOptions(), this.apiFilters).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      ({emailInboxes, metadata: { pagination }}) => {
        this.expandHelpText(emailInboxes, forceExpandHelpText);
        this.inboxes = (emailInboxes as unknown as any[]).map(inbox => {
          let inboxData: IEmailInboxTableData = {...inbox, labels: []};
          inboxData.labels = inbox.labelIds.map(labelId => {
            const foundLabel = this.allLabels.find(label => label.id === labelId);
            return foundLabel ? {id: foundLabel.id, name: foundLabel.name} : undefined;
          });
          inboxData.lastMessageReceivedText = this.setLastReceivedAtText(inbox);

          return inboxData;
        });
        this.dataSource.data = this.inboxes as unknown as IEmailInboxTableData[];
        this.pagination = pagination;
        this.paginator.pageIndex = pagination.currentPageNumber;
        this.loading = false;
        this.initialInboxValueReceived = true;
      }, error => {
        this.loading = false;
        console.log(error);
        this.snackbarService.openErrorSnackbar('There was a problem loading Email Inboxes.');
      }
    );
  }

  private setLastReceivedAtText(inbox: IEmailInbox): string {
    if(!inbox?.lastMessageReceived) {
      return null;
    }

    if (differenceInHours(this.dateService.now(), dateFromString(inbox.lastMessageReceived)) < 24) {
      return `${this.dateService.distanceToNow(dateFromString(inbox.lastMessageReceived))} ago`;
    }

    return this.dateService.formatDate(dateFromString(inbox.lastMessageReceived), EDateFormats.dateEleven);
  }

  onEditInbox(event, inbox): void {
    event.stopPropagation();
    this.openEditInbox(inbox);
  }

  clickAuditCount(event, inbox) {
    event.stopPropagation();
    this.openEditInbox(inbox, null, EEmailInboxStep.Testing);
  }

  copyInbox(event: MouseEvent, inbox) {
    event.stopPropagation();
    this.clicked = undefined;
    this.expandedInbox = undefined;

    this.duplicateInbox({
      name: inbox.name + ' (copy)',
      notes: inbox.notes,
      labelIds: inbox.labelIds,
      labels: inbox.labels,
      type: EEmailInboxModalType.Duplicate,
      edit: this.openEditInbox.bind(this),
      id: inbox.id
    });
  }

  deleteInbox(inbox) {
    this.clicked = undefined;
    this.expandedInbox = undefined;

    this.emailInboxesService.deleteInbox(inbox.id).subscribe(
      () => {
        this.loadEmailInboxes();
        this.snackbarService.openSuccessSnackbar('Email inbox deleted');
      }, () => {
        this.snackbarService.openErrorSnackbar('Unable to delete inbox.');
      }
    );
  }

  onDeleteInbox(inbox): void {
    this.modalService.openModal(OpDeleteItemWarningComponent, {
      data: {
        itemType: 'Email Inbox',
        name: inbox.name
      }
    })
      .afterClosed()
      .subscribe((confirmDelete: boolean) => {
        if (confirmDelete) {
          this.deleteInbox(inbox);
        }
      });
  }

  updateNote(updated: IEmailInboxTableData) {
    this.noteSaved = false;
    this.emailInboxesService.updateEmailInboxNotes(updated).pipe(
      takeUntil(this.destroy$)
    ).subscribe((res) => {
      const updatedIndex = this.dataSource.data.findIndex(inbox => inbox.id === updated.id);
      this.dataSource.filteredData[updatedIndex].notes = res;
      this.noteSaved = true;
      this.noteSavedTimeout = setTimeout(() => {
        this.noteSaved = false;
      }, 5000);
    });
  }

  debouncedUpdateNote(event, inbox: IEmailInboxTableData) {
    this.noteSubject.next({...inbox, notes: event.target.value});
  }

  createChip(createdChip, inbox: IEmailInboxTableData) {
    let labels = inbox.labels;
    let createdLabelIndex = inbox.labels.findIndex(label => label.name === createdChip.name);
    inbox.labels[createdLabelIndex].id = createdChip.id;

    this.selectChip(createdChip, inbox);
  }

  selectChip(label, inbox: IEmailInboxTableData) {
    const labelIds = inbox.labels.map(label => label.id);

    this.emailInboxesService.updateEmailInboxLabels(inbox.id, labelIds).subscribe();

    this.updateInboxLabels(label, inbox, 'select');
  }

  removeChip(removedLabel, inbox: IEmailInboxTableData) {
    const removedLabelIndex = inbox.labels.findIndex(label => removedLabel.id === label.id);
    const removedLabelIdIndex = inbox.labelIds.findIndex(id => removedLabel.id === id);
    const labelIds = [...inbox.labelIds];
    const labels = [...inbox.labels];
    labelIds.splice(removedLabelIdIndex, 1);
    labels.splice(removedLabelIndex, 1);

    this.emailInboxesService.updateEmailInboxLabels(inbox.id, labelIds).subscribe();

    this.updateInboxLabels(removedLabel, inbox, 'remove');
  }

  updateInboxLabels(updatedLabel, inbox, action): void {
    let tempData = [...this.dataSource.data];
    const inboxIndex = tempData.findIndex(dataInbox => inbox.id === dataInbox.id);

    switch (action) {
      case 'remove':
        // Remove label from inbox in table
        const labelsIndex = tempData[inboxIndex].labels.findIndex(label => label.id === updatedLabel.id);
        const labelIdsIndex = tempData[inboxIndex].labelIds.findIndex(id => id === updatedLabel.id);
        tempData[inboxIndex].labels.splice(labelsIndex, 1);
        tempData[inboxIndex].labelIds.splice(labelIdsIndex, 1);
        break;
      case 'select':
        // Add label to inbox in table
        tempData[inboxIndex].labelIds.push(updatedLabel.id);
        break;
      default:
        break;
    }

    this.dataSource.data = tempData;
  }

  lastOpenedNestedTableHeight: number;
  lastOpenedNestedTable: HTMLDivElement;

  animationDone($event: AnimationEvent, container: HTMLDivElement) {
    if (($event.fromState === 'loading' && $event.toState === 'expanded')
      || ($event.fromState === 'collapsed' && $event.toState === 'expanded')) {
      this.lastOpenedNestedTable = container;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = container.getBoundingClientRect().height;
        this.cdr.detectChanges();
      }, 50);
    }

    if ($event.fromState === 'expanded' && $event.toState === 'collapsed') {
      this.lastOpenedNestedTable = null;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = 0;
        this.cdr.detectChanges();
      }, 300);
    }
  }

  onViewChangeLog(event, inbox) {
    event.stopPropagation();
    this.clicked = undefined;

    this.router.navigate([AccountSettingsUrlBuilders.userEvents()], {
      state: {
        itemType: 'email-inbox',
        itemId: inbox.id
      }
    });
  }

  onClickNote(event, inbox) {
    event.stopPropagation();
    if (this.expandedInbox === inbox && this.clicked === 'notes') {
      this.clicked = undefined;
      this.expandedInbox = undefined;
    } else {
      this.clicked = 'notes';
      this.expandedInbox = inbox;
    }
  }

  onViewMessages(event, inbox): void {
    event.stopPropagation();
    this.router.navigate([EmailInboxesPaths.base, inbox.id, 'messages'], { state: { inbox }});
  }

  createInbox({name, labelIds, notes}) {
    return this.emailInboxesService.createInbox({name, labelIds, notes});
  }

  openEditInbox({name, notes, labelIds, labels, id, emailAddress}: IEmailInboxTableData, forceReload?: boolean, step?: EEmailInboxStep) {
    const data = {
      name,
      notes,
      labelIds,
      labels: labelIds.length > 0 ? labelIds.map(id => this.allLabels.find(label => label.id === id)) : [],
      id,
      type: EEmailInboxModalType.Edit,
      emailAddress,
      edit: this.openEditInbox.bind(this),
      step
    };

    this.modalService.openFixedSizeModal(EmailInboxEditorComponent, {
      disableClose: true,
      data,
      autoFocus: false
    }).afterClosed()
      .subscribe(inbox => {
        if (inbox || forceReload) {
          this.loadEmailInboxes();
        }
      });
  }

  onCreate() {
    const data = {
      name: '',
      notes: '',
      labelIds: [],
      labels: [],
      type: EEmailInboxModalType.AdvancedCreate,
      edit: this.openEditInbox.bind(this),
    };

    this.modalService.openFixedSizeModal(EmailInboxEditorComponent, {
      disableClose: true,
      data,
      autoFocus: false
    }).afterClosed()
      .subscribe(inbox => {
        if (inbox) {
          this.loadEmailInboxes(true);
        }
      });
  }

  duplicateInbox(data: IEmailInboxEditorModalPayload) {
    this.modalService.openFixedSizeModal(EmailInboxEditorComponent, {
      disableClose: true,
      data,
      autoFocus: false
    }).afterClosed()
      .subscribe(inbox => {
        if (inbox) {
          this.loadEmailInboxes();
        }
      });
  }
}
