<div class="action-failure-message">
  <div class="icon-wrap">
    <mat-icon class="failure-icon">cancel</mat-icon>
  </div>
  <div class="message-wrap">
    <div class="message-title">Journey was stopped because there was a failure on this action.</div>
    <div class="message-description">{{ currentAction?.failureMessage }}</div>
    <span matTooltip="No more journey fixes available"
         matTooltipPosition="above"
         [matTooltipDisabled]="!failedActionData.outOfFixes"
         matTooltipPosition="above"
         class="fix-journey-btn-wrap"
    >
      <button mat-flat-button
              (click)="requestJourneyFix()"
              class="fix-journey-btn"
              [disabled]="failedActionData.fixRequested || failedActionData.outOfFixes"
      >
        <!-- if fix journey request hasn't been submitted -->
        <ng-container *ngIf="!failedActionData.fixRequested && !failedActionData.showFixJourneySpinner">
          <mat-icon class="btn-icon">flag</mat-icon>
          Have Journey Support Fix The Issue
        </ng-container>

        <!-- fix requested and waiting for API to respond -->
        <ng-container *ngIf="failedActionData.showFixJourneySpinner">
          <mat-spinner [diameter]="20" [strokeWidth]="3"></mat-spinner>
        </ng-container>

        <!-- fix successfully requested -- prevent user from clicking again -->
        <ng-container *ngIf="failedActionData.fixRequested && !failedActionData.showFixJourneySpinner">
          <mat-icon class="btn-icon check-icon">check_circle</mat-icon>
          Fix has been requested
        </ng-container>
      </button>
    </span>
  </div>
</div>