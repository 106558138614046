import { ElementRef, Injectable } from '@angular/core';

@Injectable()
export class AuditReportScrollService {

  private elementToScroll: ElementRef<any>;
  readonly filterBarOffset: number = 55;

  register(element: ElementRef<any>): void {
    this.elementToScroll = element;
  }

  scrollToTop(): void {
    // using behavior: auto because not sure if smooth works in mobile safari
    if (this.elementToScroll?.nativeElement) {
      this.elementToScroll.nativeElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }
  }

  scrollByElement(element: HTMLElement, offset?: number): void {
    if (!this.elementToScroll?.nativeElement) { return; }
    const scrollElement = this.elementToScroll.nativeElement;

    const scrollPosition = element.offsetTop;
    const offsetValue = offset ? offset : this.filterBarOffset;

    setTimeout(() => scrollElement.scrollTop = scrollPosition - offsetValue);
  }

  scrollByPixels(numPixels: number): void {
    if (!this.elementToScroll?.nativeElement) { return; }
    const scrollElement = this.elementToScroll.nativeElement;
    scrollElement.scrollTop = numPixels;
  }
}
