import { MatInput } from '@angular/material/input';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EAlertNameControlMode } from './alert-name-control.enums';
import { EAlertModalType } from '@app/components/alert/alert.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-name-control',
  templateUrl: './alert-name-control.component.html',
  styleUrls: ['./alert-name-control.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: AlertNameControlComponent,
    multi: true
  }]
})
export class AlertNameControlComponent implements ControlValueAccessor {

  @Input() control: UntypedFormControl;
  @Input() modalType: EAlertModalType;
  @Input() set initialMode(mode: EAlertNameControlMode) {
    this.mode = mode;
    if (this.mode === EAlertNameControlMode.Edit) {
      this.focusInput();
    }
  }
  @Output() modeChanged = new EventEmitter<void>();

  @ViewChild(MatInput) inputRef: MatInput;

  mode: EAlertNameControlMode;

  value: string;
  onChange: (value: string) => void;

  readonly EAlertNameControlMode = EAlertNameControlMode;
  readonly EAlertModalType = EAlertModalType;

  switchToEditMode() {
    if (this.modalType !== EAlertModalType.ReadOnly) {
      this.mode = EAlertNameControlMode.Edit;
      this.focusInput();
      this.modeChanged.emit();
    }
  }

  switchToViewMode(newName: string) {
    if (!newName) {
      this.control.markAsTouched();
      return;
    }
    this.mode = EAlertNameControlMode.View;
    this.modeChanged.emit();
  }

  private focusInput() {
    // we should wait for the input rendering at first
    setTimeout(() => this.inputRef.focus());
  }

  /*** Control Value Accessor implementation ***/
  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(_: () => void): void { }

}
