import { Component, OnInit, Input } from '@angular/core';
import { ApiKeyService, IApiKeyResponse } from '@app/components/account/keys/apiKeysService';
import { IUser } from '@app/moonbeamModels';
import { IApiKey } from '../../account/keys/apiKeysService';
import { AccountsService } from '@app/components/account/account.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'api-key',
  templateUrl: './api-key.component.html',
  styleUrls: ['./api-key.component.scss']
})
export class ApiKeyComponent implements OnInit {

  currentUser: IUser;
  targetUser: IUser;
  key: IApiKey;
  buttonLabel: string;
  showCopiedMessage: boolean = false;

  @Input() userId?: number;

  constructor(
    private accountsService: AccountsService,
    private apiKeyService: ApiKeyService
  ) { }

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.accountsService.getUser().subscribe((user: IUser) => {
      this.currentUser = user;

      if(this.userId) {
        this.accountsService.getUserById(this.userId).then((user: IUser) => {
          this.targetUser = user;
          this.getKey();
        });
      } else {
        this.getKey();
      }
    });
  }

  getKey(): void {
    this.targetUser ? this.getTargetUserKey() : this.getCurrentUserKey();
  }

  getCurrentUserKey(): void {
    this.apiKeyService.getCurrentUserKey().then((response: IApiKeyResponse) => {
      this.key = response.key;
      this.generateButtonLabel();
    });
  }

  getTargetUserKey(): void {
    this.apiKeyService.getUserKey(this.targetUser).then((response: IApiKeyResponse) => {
      this.key = response.key;
      this.generateButtonLabel();
    });
  }

  generateKey(): void {
    if (this.targetUser) {
      this.apiKeyService.createUserKey(this.targetUser).then((apiKey: IApiKey) => {
        this.key = apiKey;
      });
    } else {
      this.apiKeyService.createCurrentUserKey().then((apiKey: IApiKey) => {
        this.key = apiKey;
      });
    }
    
    this.generateButtonLabel();
  }

  generateButtonLabel(): void {
    this.buttonLabel = this.key === null ? 'Generate New API Key' : 'Revoke & Set New';
  }

  showCopyMessage(): void {
    this.showCopiedMessage = true;

    setTimeout(() => {
      this.showCopiedMessage = false;
    }, 1500);
  }
}
