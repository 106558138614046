import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { IOpFilterBarV2MenuItem } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { OpFilterBarV2Service } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { IFolder } from '@app/components/folder/foldersApiService';
import { EAlertDataSourcesFilterTypes } from './alert-data-sources-filter-bar.enums';

@Injectable()
export class AlertDataSourcesFilterBarService extends OpFilterBarV2Service<EAlertDataSourcesFilterTypes> {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService,
  ) {
    super(storage, null, cacheReset);
    this.updateSupportedFiltersList([
      EAlertDataSourcesFilterTypes.Name,
      EAlertDataSourcesFilterTypes.Folder,
      EAlertDataSourcesFilterTypes.Label,
    ]);
  }

  get filters$(): Observable<IOpFilterBarV2Filter<EAlertDataSourcesFilterTypes>[]> {
    return this.relevantFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters
          .filter(filter => this.validTypes.includes(filter.type))
          .reduce((acc, curr) => {
            acc.push(curr);
            return acc;
          }, [])
      )
    );
  }

  handleNameContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: EAlertDataSourcesFilterTypes.Name,
      display: `Name contains '${contains}'`,
      value: [contains],
      menuItems: [],
      icon: 'search',
      order: 1
    }, replaceAll);
  }

  addFolderFilter(checked: boolean, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false, value: number[], numChecked: number): void {
    this.addFilter(
      {
        type: EAlertDataSourcesFilterTypes.Folder,
        display: `Folders (${numChecked})`,
        value,
        icon: 'folder',
        menuName: 'Folder & Sub-folder',
        menuItems: menuItems,
        order: 2
      },
      replaceAll,
      numChecked,
      checked
    );
  }

  handleFolderFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false, folders: IFolder[], domains: any): void {
    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);
    const folderIds = folders.map(folder => folder.id);

    // When using the 'only' button, clear out the existing filter values before adding the new selection
    if (replaceAll) {
      this.addFolderFilter(checked, menuItems, replaceAll, [], numChecked);
      replaceAll = false;
    }

    // If user clicked a Folder checkbox
    if (folderIds.includes(item.id)) {
      // If checking a folder, add all subfolder ids to values array if not already there
      if (checked) {
        domains[item.id]?.forEach(domain => {
          this.addFolderFilter(checked, menuItems, replaceAll, [domain.id], numChecked);
        });

      // If unchecking a folder, remove all subfolder ids from values array
      } else {
        // Deselect all domains in the deselected folder
        domains[item.id]?.forEach(domain => {
          this.addFolderFilter(checked, menuItems, replaceAll, [domain.id], numChecked);
        });
      }
    } else {
      // Add subfolder
      this.addFolderFilter(checked, menuItems, replaceAll, [item.id], numChecked);
    }

    item.checked = checked;
  }

  handleLabelFilter(checked: boolean, label: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false): void {
    label.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    this.addFilter(
      {
        type: EAlertDataSourcesFilterTypes.Label,
        display: `Labels (${numChecked})`,
        value: [label.id],
        menuName: 'Label',
        menuItems: menuItems,
        icon: 'label',
        order: 3
      },
      replaceAll,
      numChecked,
      checked
    );
  }

  getNumChecked(menuItems: IOpFilterBarV2MenuItem[]): number {
    let count = 0;

    menuItems.forEach(item => {
      if (item.checked && !item.children) count++;
      if (item.children?.length) {
        item.children.forEach(child => {
          if (child.checked) count++;
        });
      }
    });

    return count;
  }

}
