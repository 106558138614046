import { Injectable, OnDestroy } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { Observable, Subject } from 'rxjs';
import {
  AuditData,
  CookieInventoryCookiePagesRequestBody,
  CookieInventoryCookies,
  CookieInventoryPagesRequestBody,
  CookieInventoryPagesResponse,
  CookieInventoryPagesTablePagination,
  CookieInventorySummary,
  CookieInventoryTrendRunOverviews,
  CookieInventoryTrends,
  CookiesTableSorting
} from './cookie-inventory.models';
import { CookieInventoryTrendName } from './cookie-inventory.constants';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import {
  IReprocessService,
} from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { takeUntil } from 'rxjs/operators';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class CookieInventoryService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getCookieInventorySummary(auditId: number, runId: number, body?: IAuditReportApiPostBody): Observable<CookieInventorySummary> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-inventory`, body);
  }

  getCookieInventoryTrends(auditId: number, runId: number): Observable<CookieInventoryTrendRunOverviews> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-inventory/trends`);
  }

  getCookieInventoryTrend(auditId: number, trendName: CookieInventoryTrendName, days: number): Observable<CookieInventoryTrends> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/reports/cookie-inventory/trends/${trendName}?days=${days}`);
  }

  getAuditData(auditId: number, runId: number): Observable<AuditData> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/runs/${runId}/info`);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getCookieInventoryCookies(
    auditId: number,
    runId: number,
    sorting: CookiesTableSorting,
    body?: IAuditReportApiPostBody
  ): Observable<CookieInventoryCookies> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-inventory/cookies`;
    const queryParams = `?page=${sorting.page}&size=${sorting.size}&sortBy=${sorting.sortBy}&sortDesc=${sorting.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }

  getCookieInventoryPages(
    auditId: number,
    runId: number,
    pagination: CookieInventoryPagesTablePagination,
    body?: CookieInventoryPagesRequestBody
  ):
    Observable<CookieInventoryPagesResponse> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-inventory/pages`;
    const queryParams = `?page=${pagination.page}&size=${pagination.size}&sortBy=${pagination.sortBy}&sortDesc=${pagination.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }

  getCookieInventoryCookiePages(
    auditId: number,
    runId: number,
    pagination: CookieInventoryPagesTablePagination,
    body?: CookieInventoryCookiePagesRequestBody
  ): Observable<CookieInventoryPagesResponse> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-inventory/cookie-pages`;
    const queryParams = `?page=${pagination.page}&size=${pagination.size}&sortBy=${pagination.sortBy}&sortDesc=${pagination.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }
}
