import { ActivatedRoute } from '@angular/router';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ComparisonError } from './../web-journey-results.constants';
import { ComponentChanges } from './../../../../models/commons';
import { IAccountTagSettings } from '@app/components/comparisons/comparisons.models';
import { ComparisonsService } from '@app/components/comparisons/comparisons.service';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { IActionSelectorAction } from '@app/components/action-selector/action-selector.models';
import { forkJoin, of, Subject } from 'rxjs';
import { IWJTagToAllDiffs, WJTagToAllDiffs } from '../tag-comparison-table/wj-results-tag-comparison-table.models';
import { IActionTagPresenceDiffs, IWJTagPresenceDiff } from './wj-results-tag-comparison.models';
import { IWJReportParams } from '../../web-journey-report.models';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { noActionTagsError, noMatchedActionError } from './wj-results-tag-comparison.constants';
import { WjResultsTagReportService } from '../tag-report/wj-results-tag-report.service';
import { IActionTagVariablesDiffCount } from '../tag-report/wj-results-tag-comparison.models';
import { WebJourneyReportService } from '../../web-journey-report.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'wj-results-tag-comparison',
  templateUrl: './wj-results-tag-comparison.component.html',
  styleUrls: ['./wj-results-tag-comparison.component.scss']
})
export class WjResultsTagComparisonComponent implements OnInit, OnChanges, OnDestroy {

  @Input() action: IActionSelectorAction;
  @Input() primaryTagFilteringDisabled: boolean;
  @Input() primaryTagsToggleValue: boolean;
  @Output() onPrimaryTagsToggleValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  journeyId: number;
  runId: number;

  loading = true;

  missingActionsFromBaseline: number[] = [];

  filterBy = '';
  showDiffsOnly = false;
  hasAccountTags = false;

  tagIdToTagDetailsMap = new Map<number, IUiTag & IAccountTagSettings>();
  actionIdToGroupedDiffs = new Map<number, IWJTagToAllDiffs[]>();

  isActionMissing: boolean;
  hasComparison: boolean;
  comparisonError: ComparisonError;

  groupedDiffs: IWJTagToAllDiffs[] = [];

  private destroy$ = new Subject();

  constructor(private route: ActivatedRoute,
    private uiTagService: UiTagService,
    private comparisonService: ComparisonsService,
    private webJourneyReportService: WebJourneyReportService,
    private tagReportService: WjResultsTagReportService) { }

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.loading = true)
      )
      .subscribe((params: IWJReportParams) => {
        this.journeyId = Number(params.journeyId);
        this.runId = Number(params.runId);

        this.init();
      });
  }

  ngOnChanges(changes: ComponentChanges<WjResultsTagComparisonComponent>) {
    if (changes.action?.currentValue !== changes.action?.previousValue && !changes.action?.firstChange) {
      this.groupedDiffs = this.actionIdToGroupedDiffs.get(this.action.actionId) || this.buildGroupedTags();
      this.isActionMissing = this.missingActionsFromBaseline?.includes(this.action.actionId);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private init() {
    forkJoin([
      this.uiTagService.getTags(),
      this.comparisonService.getAccountTags().pipe(catchError(_ => of(new ComparisonError()))),
      this.webJourneyReportService.getTagPresence(this.journeyId, this.runId).pipe(catchError(_ => of(new ComparisonError()))),
      this.webJourneyReportService.getActionsMissingFromBaseline(this.journeyId, this.runId).pipe(catchError(_ => of(new ComparisonError()))),
      this.tagReportService.getActionTagVariablesDiffCount(this.journeyId, this.runId)
    ])
      .pipe(
        tap(([_, tagsSettings, actionTagPresenceDiffs, missingActions, diffsCount]) => {
          if (tagsSettings instanceof ComparisonError ||
            actionTagPresenceDiffs instanceof ComparisonError ||
            missingActions instanceof ComparisonError ||
            diffsCount instanceof ComparisonError) this.comparisonError = new ComparisonError();
        })
      )
      .subscribe(([accountTags, tagsSettings, actionTagPresenceDiffs, missingActions, diffsCount]) => {
        this.prepareMissingActions(missingActions);
        this.prepareTagDetailsMap(accountTags, tagsSettings);
        this.prepareGroupedDiffs(actionTagPresenceDiffs);
        this.checkHasComparison(diffsCount);

        const actionTags = this.action.tags || [];
        this.hasAccountTags = !!actionTags.find(actionTag => this.tagIdToTagDetailsMap.get(actionTag.tagId));
        this.loading = false;
      });
  }

  private prepareTagDetailsMap(accountTags: IUiTag[],
    tagsSettings: IAccountTagSettings[] | ComparisonError): void {

    const accountTagsSettings = tagsSettings instanceof ComparisonError ? null : tagsSettings;

    this.tagIdToTagDetailsMap = accountTags.reduce((acc, tag) => {
      const tagSettings = accountTagsSettings?.find(settings => tag.id === settings.tagId) || {};
      return acc.set(tag.id, { ...tag, ...tagSettings });
    }, new Map());
  }

  private prepareGroupedDiffs(actionTagPresenceDiffs: IActionTagPresenceDiffs[] | ComparisonError): void {
    if (actionTagPresenceDiffs instanceof ComparisonError || !actionTagPresenceDiffs || actionTagPresenceDiffs.length === 0) {
      this.actionIdToGroupedDiffs = new Map<number, IWJTagToAllDiffs[]>();
      this.actionIdToGroupedDiffs.set(this.action.actionId, []);
      this.groupedDiffs = this.buildGroupedTags();
    } else {
      this.actionIdToGroupedDiffs = actionTagPresenceDiffs.reduce((map, tagPresenceDiffs) => {
        const actionId = tagPresenceDiffs.actionId;
        const tagDiffs = tagPresenceDiffs.tagAccountPresenceDifferences;
        return map.set(actionId, this.groupDiffsByTag(tagDiffs));
      }, new Map<number, IWJTagToAllDiffs[]>());
      this.groupedDiffs = this.actionIdToGroupedDiffs.get(this.action.actionId);
      this.webJourneyReportService.setTagCount(this.groupedDiffs?.length || 0);
    }
  }

  private prepareMissingActions(missingActions: number[] | ComparisonError): void {
    this.missingActionsFromBaseline = (missingActions instanceof ComparisonError) ? null : missingActions;
    this.isActionMissing = this.missingActionsFromBaseline?.includes(this.action.actionId);
  }

  private checkHasComparison(diffsCount: IActionTagVariablesDiffCount[] | ComparisonError): void {
    this.hasComparison = !(diffsCount instanceof ComparisonError) && diffsCount !== null;
  }

  private buildGroupedTags(): WJTagToAllDiffs[] {
    const groupedTagsSummary = ArrayUtils.groupByAsArray(this.action.tags, 'tagId').map(arr => arr[1]);

    return groupedTagsSummary.map(tagSummary => {
      const { tagId, tagName, tagIcon } = tagSummary[0];

      const groupedAccountsSummary = ArrayUtils.groupByAsArray(tagSummary, 'account').map(arr => arr[1]);

      const diffs = groupedAccountsSummary.map(accSummary => ({
        tagId,
        tagAccount: accSummary[0].account,
        expectedCount: null,
        actualCount: accSummary.length
      }));

      return new WJTagToAllDiffs(tagId, tagName, tagIcon, diffs);
    });
  }

  private groupDiffsByTag(tagDiffs: IWJTagPresenceDiff[]): IWJTagToAllDiffs[] {
    const groupedDiffsByTag: IWJTagPresenceDiff[][] = ArrayUtils.groupByAsArray(tagDiffs, 'tagId').map(arr => arr[1]);
    return groupedDiffsByTag.map(groupedDiffs => this.mergeDiffsByTagId(groupedDiffs));
  }

  private mergeDiffsByTagId(groupedDiffs: IWJTagPresenceDiff[]): WJTagToAllDiffs {
    return groupedDiffs.reduce((acc: WJTagToAllDiffs, diff: IWJTagPresenceDiff, index: number) => {
      const tag = this.tagIdToTagDetailsMap.get(diff.tagId);

      if (index === 0) return new WJTagToAllDiffs(tag?.id, tag?.name, tag?.iconUrl, [diff]);
      else {
        acc.diffs.push(diff);
        return acc;
      }
    }, new WJTagToAllDiffs());
  }

  getErrorMessage(): string {
    if (this.comparisonError) return this.comparisonError.message;

    const actionHasTags = this.action.tags?.length > 0;
    if (!actionHasTags) return noActionTagsError;

    if (this.isActionMissing) return noMatchedActionError;

    return '';
  }

  // Public API. Is used by parent component
  getTagsCount(): number {
    return this.groupedDiffs?.length || 0;
  }

}
