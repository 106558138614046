import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { IPrimaryTag } from '@app/components/account/manageTags/manageTagsService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-info',
  templateUrl: './tag-info.component.html',
  styleUrls: ['./tag-info.component.scss']
})
export class TagInfoComponent implements OnInit, OnChanges {

  tagInfoForm: UntypedFormGroup;
  tagOwnersData: Array<any>;
  techSpecialistsData: Array<any>;
  vendorContactsData: Array<any>;

  @Input() tagInfo: IPrimaryTag;
  @Input() readOnly: boolean;
  @Output() formData = new EventEmitter();

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.initForm();
    this.fillTagInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.readOnly && this.tagInfoForm) {
      changes.readOnly.currentValue ? this.disableForm() : this.enableForm();
    }
  }

  private initForm() {
    this.tagInfoForm = this.fb.group({
      tagOwners: this.fb.array([]),
      techSpecialists: this.fb.array([]),
      vendorContacts: this.fb.array([]),
      cost: this.fb.group({
        cost: [''],
        period: ['']
      }),
      notes: ['']
    });

    this.readOnly ? this.disableForm() : this.enableForm();
  }

  private enableForm() {
    this.tagInfoForm.enable();

    for (let control of [...this.tagOwners.controls, ...this.techSpecialists.controls, ...this.vendorContacts.controls]) {
      control.enable();
    }
  }
  
  private disableForm() {
    this.tagInfoForm.disable();

    for (let control of [...this.tagOwners.controls, ...this.techSpecialists.controls, ...this.vendorContacts.controls]) {
      control.disable();
    }
  }

  private fillTagInfo() {
    // tag owners
    if (this.tagInfo.owners.length) {
      this.tagInfo.owners.map(owner => {
        this.addItem(this.tagOwners, {
          name: owner.name,
          email: owner.email,
          phone: owner.phone
        });
      });
    } else {
      this.addItem(this.tagOwners);
    }

    if (this.tagInfo.technicalSpecialists.length) {
      this.tagInfo.technicalSpecialists.map(specialist => {
        this.addItem(this.techSpecialists, {
          name: specialist.name,
          email: specialist.email,
          phone: specialist.phone
        });
      });
    } else {
      this.addItem(this.techSpecialists);
    }

    if (this.tagInfo.vendorContacts.length) {
      this.tagInfo.vendorContacts.map(contact => {
        this.addItem(this.vendorContacts, {
          name: contact.name,
          email: contact.email,
          phone: contact.phone
        });
      });
    } else {
      this.addItem(this.vendorContacts);
    }
    
    this.costCost.patchValue(this.tagInfo.cost ? this.tagInfo.cost.cost : '');
    this.costPeriod.patchValue(this.tagInfo.cost ? this.tagInfo.cost.period : '');
    this.notes.patchValue(this.tagInfo.notes ? this.tagInfo.notes : '');

    this.updateDragList();
  }

  private createContact(contact?: any): UntypedFormGroup {
    const group = this.fb.group({
      name: contact ? contact.name : '',
      email: contact ? contact.email : '',
      phone: contact ? contact.phone : ''
    });

    if (this.readOnly) {
      group.disable();
    }

    return group;
  }

  private updateDragList() {
    this.tagOwnersData = this.tagInfoForm.value.tagOwners;
    this.techSpecialistsData = this.tagInfoForm.value.techSpecialists;
    this.vendorContactsData = this.tagInfoForm.value.vendorContacts;
  }

  addItem(field, contact?, index?): void {
    if (index) {
      field.insert(index, this.createContact(contact));
    } else {
      field.push(this.createContact(contact));
    }

    this.updateDragList();
  }

  deleteItem(field, index) {
    field.removeAt(index);
    this.updateDragList();
    this.tagInfoForm.markAsDirty();
  }

  addTagOwner(index) {
    this.addItem(this.tagOwners, null, index);
    this.tagInfoForm.markAsDirty();
  }

  addTechSpecialist(index) {
    this.addItem(this.techSpecialists, null, index);
    this.tagInfoForm.markAsDirty();
  }

  addVendorContact(index) {
    this.addItem(this.vendorContacts, null, index);
    this.tagInfoForm.markAsDirty();
  }

  deleteTagOwner(index) {
    this.deleteItem(this.tagOwners, index);
  }

  deleteTechSpecialist(index) {
    this.deleteItem(this.techSpecialists, index);
  }

  deleteVendorContact(index) {
    this.deleteItem(this.vendorContacts, index);
  }

  onSubmit() {
    this.tagInfoForm.markAsPristine();
    this.formData.emit({
      formData: this.tagInfoForm.value,
      tagData: this.tagInfo
    });
  }

  get tagOwners() {
    return this.tagInfoForm.get('tagOwners') as UntypedFormArray;
  }

  get techSpecialists() {
    return this.tagInfoForm.get('techSpecialists') as UntypedFormArray;
  }

  get vendorContacts() {
    return this.tagInfoForm.get('vendorContacts') as UntypedFormArray;
  }

  get cost() {
    return this.tagInfoForm.get('cost');
  }

  get costCost() {
    return this.cost.get('cost');
  }

  get costPeriod() {
    return this.cost.get('period');
  }

  get notes() {
    return this.tagInfoForm.get('notes');
  }
}
