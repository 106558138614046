import { AngularNames } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { ValidateMessages } from './validate-messages.service';

/**
 * @ngdoc directive
 * @name moonbeam.directive:ValidateMessagesDirective
 * @restrict AE
 * @scope
 *
 * @param {any} messagesFor. name of input field in form for which error message will be displayed
 * @param {any} submitted. reference to variable in your controller which flags whether the form is submitted
 * @param {boolead} simpleMode. flag to make validation more lightweight
 *
 * @description
 * This directive is used to display error messages for invalid fields in form
 * example of usage:
 * in view:
 *   <form name="ctrl.folderForm" novalidate>
 *     <div class="input-group">
 *       <input input-material label="Folder Name" type="text" name="name" ng-model="ctrl.folderModel.name"
 *         required unique="ctrl.checkUnique">
 *       <div validate-messages messages-for="ctrl.folderForm.name" messages="folderForm.name" submitted="ctrl.submitted"></div>
 *     </div>
 *   </form>
 * in controller:
 *   export class FolderCreatorController {
 *     ...
 *      submitted: boolean = false;
 *      folderForm: angular.IFormController;
 *      ...
 *      save(event): void {
 *        if (this.folderForm.$valid) {
 *         ... saving logic
 *        } else {
 *          this.submitted = true;
 *        }
 *      }
 *      ...
 *    }
 */

interface IValidateMessagesScope extends angular.IScope {
    messagesFor: any;
    submitted: any;
    simpleMode: boolean;
    getMessage: Function
  }

  interface IValidateMessagesAttributes extends angular.IAttributes {
    messagesFor: any;
  }

  export class ValidateMessagesDirective implements angular.IDirective {
    restrict = 'AE';
    replace = true;
    scope = {
      messagesFor: '<',
      submitted: '<',
      simpleMode: '<'

    };
    template = require('@app/components/form/validateMessages.html');

    link = (scope: IValidateMessagesScope, element: JQuery, attributes: IValidateMessagesAttributes) => {

      var self = this;
      var watchValue = 'messagesFor.$error';

      // Add 'form-to-validate'
      // for multiple forms on a page
      var ngForm = element.closest('ng-form');
      var ngFormAttr = element.closest('[ng-form]');
      if (ngForm.length > 0) {
        ngForm.addClass('form-to-validate');
      } else if (ngFormAttr.length > 0) {
        ngFormAttr.addClass('form-to-validate');
      } else {
        element.closest('form').addClass('form-to-validate');
      }

      var showOneMsg = function() {
        self.$timeout(function() {
          const DEFAULT_MESSAGE_HEIGHT_OFFSET: number = 20;
          var ele = element;
          var formToValidate = element.closest('.form-to-validate');
          // Check the DOM
          // to show error message for only the first invalid field in a form
          // to avoid messy and frustrating user experience
          formToValidate.find('.error-message').css('opacity', '0');
          var inputs = formToValidate.find('.input-group');
          for (var i = inputs.length - 1; i >= 0; i--) {
            inputs[i].style.removeProperty('margin-bottom');
          }

          var msg = formToValidate.find('.error-wrap .error-message').first().css('opacity', '1');

          // Move form-subtitle if it exists under error message
          formToValidate.find('.form-subtitle').css('transform', 'translateY(0px)');
          
          if (msg.length > 0) {
            let msgHeight = parseInt(msg.css('line-height'));
            let msgHeightOffset = msg[0].offsetHeight || DEFAULT_MESSAGE_HEIGHT_OFFSET;
            let inputGroup = msg.closest('.input-group');
            inputGroup.find('.error-wrap:not(.ng-hide)').closest('.input-group').find('.form-subtitle')
              .css('transform', 'translateY(' + msgHeightOffset + 'px)');
            if (msgHeightOffset > msgHeight) {
              inputGroup.css('margin-bottom', parseInt(inputGroup.css('margin-bottom')) + msgHeightOffset - msgHeight);
            } 
          } 

          // And if $error object is empty,
          // meaning that there is no more errors for this field,
          // remove 'has-error' class from 'input-group'
          if (scope.messagesFor && jQuery.isEmptyObject(scope.messagesFor.$error)) {
            element.parents('.input-group').removeClass('has-error');
            element.closest('.input-group').find('.error-message').css('opacity', '0');
            element.closest('.input-group').find('.form-subtitle').css('transform', 'translateY(0px)');
            element.closest('.input-group')[0].style.removeProperty('margin-bottom');
          }
        }, 300);
      };

      // On submit
      // check every required field in a form whether it's valid or not.
      scope.$watch('submitted', function(submitted) {
        if (submitted && scope.messagesFor && scope.messagesFor.$invalid) {

          // If this field is invalid,
          // add Bootstrap class 'has-error' to the 'input-group' it belongs to.
          // This will trigger CSS styling for notification icons
          element.parent('.input-group').addClass('has-error');

          // Show error message for only the first invalid field in a form
          // to avoid messy and frustrating user experience
          // element.closest('.form-to-validate').find('.error-message').css("opacity", "0").first().css("opacity", "1");
          showOneMsg();
        }
      }, true);

      // When watchValue for this field is being changed
      scope.$watch(watchValue, function(newValue) {
        showOneMsg();
        if (scope.submitted && scope.messagesFor && scope.messagesFor.$invalid) {
          element.parent('.input-group').addClass('has-error');
        }

      }, true);

      if (!scope.simpleMode) {
        scope.$watchGroup(['messagesFor.$touched', 'messagesFor.$dirty'], function(newValues, oldValues, scope) {
          showOneMsg();
        });
      }

      scope.getMessage = function(key) {
        var fields = attributes.messagesFor.split('.');
        var msgs = ValidateMessages.messages;
        for (var i = 1; i < fields.length - 1; i++) {
          var candidate = msgs[fields[i]];
          if (candidate !== undefined) {
            msgs = candidate;
          } else {
            break;
          }
        }
        return msgs[fields[i]] ? msgs[fields[i]][key] : ValidateMessages.defaultMessages[key];
      };
    }

    static factory(): angular.IDirectiveFactory {
      const directive = ($timeout: angular.ITimeoutService) => {
        return new ValidateMessagesDirective($timeout);
      };
      directive.$inject = [
        AngularNames.timeout,
      ];
      return directive;
    }

    constructor(
      private $timeout: angular.ITimeoutService) {
    }
  }
