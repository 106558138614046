<div class='header-wrapper'>
  <div class="static-header-cards">
    <op-summary-card class="left-summary-card"
                     headerText="Pages Scanned"
                     [filteredValue]="headerData?.filteredPageCount"
                     [totalValue]="headerData?.totalPageCount"
                     [showTotalValueWhenFiltered]="true"
                     [widgetState]="headerDataState"
                     tooltipText="A count of pages scanned in this audit/run.">
    </op-summary-card>

    <op-summary-card class="middle-summary-card"
                     headerText="Primary Tags"
                     [filteredValue]="headerData?.filteredUniquePrimaryTagCount"
                     [totalValue]="headerData?.totalUniquePrimaryTagCount"
                     [widgetState]="headerDataState"
                     [showTotalValueWhenFiltered]="true"
                     tooltipText="Count of primary tags vendors discovered during this audit/run.">
    </op-summary-card>

    <op-summary-card class="right-summary-card"
                     headerText="Rules Applied"
                     [filteredValue]="ruleSummaryData?.totalRuleCount"
                     [widgetState]="headerDataState"
                     tooltipText="Count of applied rules during this audit/run">
    </op-summary-card>
  </div>

  <div class="trends-header-title">
    <span>Audit Scores</span>
  </div>
  <div class="score-trends-section">
    <split-card [topLabel]="widgetAuditScore.topLabel"
                [topChangeContent]="formatTopWidgetValue(auditScoreData?.total?.score, auditScoreData?.total?.max)"
                [topTooltip]="widgetAuditScore.tooltip"
                [state]="auditScoreLoadingState"
                [bottomHandler]="widgetAuditScore.bottomHandler"
                [haveEnoughData]="auditScoreSparkLineData.length > 1"
                [metricType]="widgetAuditScore.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="auditScoreData?.total?.score"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetAuditScore.metricType"
                [disableHighlightFromClick]="true"
                class="audit-score-card">
      <sparkline-chart-insight
        [label]="'since last run'"
        [dataLoaded]="auditScoreSparklineDataLoaded"
        [dataPoints]="auditScoreSparkLineData"
        [meaningOfUp]="'positive'"
        [meaningOfNoChange]="'none'">
      </sparkline-chart-insight>
    </split-card>

    <split-card [topLabel]="widgetAuditTagPresenceScore.topLabel"
                [topHandler]="widgetAuditTagPresenceScore.topHandler"
                [topChangeContent]="formatTopWidgetValue(auditScoreData?.tagPresence?.score, auditScoreData?.tagPresence?.max)"
                [topTooltip]="widgetAuditTagPresenceScore.tooltip"
                [state]="auditScoreLoadingState"
                [bottomHandler]="widgetAuditTagPresenceScore.bottomHandler"
                [haveEnoughData]="auditTagPresenceScoreSparkLineData.length > 1"
                [metricType]="widgetAuditTagPresenceScore.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="auditScoreData?.tagPresence?.score"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetAuditTagPresenceScore.metricType"
                [disableHighlightFromClick]="true"
                class="tag-presence-score-card">
      <sparkline-chart-insight
        [label]="'since last run'"
        [dataLoaded]="auditScoreSparklineDataLoaded"
        [dataPoints]="auditTagPresenceScoreSparkLineData"
        [meaningOfUp]="'positive'"
        [meaningOfNoChange]="'none'">
      </sparkline-chart-insight>
    </split-card>

    <split-card [topLabel]="widgetAuditTagPerformanceScore.topLabel"
                [topHandler]="widgetAuditTagPerformanceScore.topHandler"
                [topChangeContent]="formatTopWidgetValue(auditScoreData?.tagPerformance?.score, auditScoreData?.tagPerformance?.max)"
                [topTooltip]="widgetAuditTagPerformanceScore.tooltip"
                [state]="auditScoreLoadingState"
                [bottomHandler]="widgetAuditTagPerformanceScore.bottomHandler"
                [haveEnoughData]="auditTagPerformanceScoreSparkLineData.length > 1"
                [metricType]="widgetAuditTagPerformanceScore.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="auditScoreData?.tagPerformance?.score"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetAuditTagPerformanceScore.metricType"
                [disableHighlightFromClick]="true"
                class="tag-performance-score-card">
      <sparkline-chart-insight
        [label]="'since last run'"
        [dataLoaded]="auditScoreSparklineDataLoaded"
        [dataPoints]="auditTagPerformanceScoreSparkLineData"
        [meaningOfUp]="'positive'"
        [meaningOfNoChange]="'none'">
      </sparkline-chart-insight>
    </split-card>

    <split-card [topLabel]="widgetAuditRulesScore.topLabel"
                [topChangeContent]="formatTopWidgetValue(auditScoreData?.rules?.score, auditScoreData?.rules?.max)"
                [topTooltip]="widgetAuditRulesScore.tooltip"
                [topHandler]="widgetAuditRulesScore.topHandler"
                [state]="auditScoreLoadingState"
                [bottomHandler]="widgetAuditRulesScore.bottomHandler"
                [haveEnoughData]="auditRulesScoreSparkLineData.length > 1"
                [metricType]="widgetAuditRulesScore.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="auditScoreData?.rules?.score"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetAuditRulesScore.metricType"
                [disableHighlightFromClick]="true"
                class="rules-score-card">
      <div class="no-rules-text" *ngIf="ruleSummaryData?.totalRuleCount === 0">No rules assigned to this run</div>
      <sparkline-chart-insight
        *ngIf="ruleSummaryData?.totalRuleCount > 0"
        [label]="'since last run'"
        [dataLoaded]="auditScoreSparklineDataLoaded"
        [dataPoints]="auditRulesScoreSparkLineData"
        [meaningOfUp]="'positive'"
        [meaningOfNoChange]="'none'">
      </sparkline-chart-insight>
    </split-card>

    <split-card [topLabel]="widgetAuditPagePerformanceScore.topLabel"
                [topChangeContent]="formatTopWidgetValue(auditScoreData?.pagePerformance?.score, auditScoreData?.pagePerformance?.max)"
                [topTooltip]="widgetAuditPagePerformanceScore.tooltip"
                [topHandler]="widgetAuditPagePerformanceScore.topHandler"
                [state]="auditScoreLoadingState"
                [bottomHandler]="widgetAuditPagePerformanceScore.bottomHandler"
                [haveEnoughData]="auditPagePerformanceScoreSparkLineData.length > 1"
                [metricType]="widgetAuditPagePerformanceScore.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="auditScoreData?.pagePerformance?.score"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetAuditPagePerformanceScore.metricType"
                [disableHighlightFromClick]="true"
                class="page-performance-score-card">
      <sparkline-chart-insight
        [label]="'since last run'"
        [dataLoaded]="auditScoreSparklineDataLoaded"
        [dataPoints]="auditPagePerformanceScoreSparkLineData"
        [meaningOfUp]="'positive'"
        [meaningOfNoChange]="'none'">
      </sparkline-chart-insight>
    </split-card>

    <split-card [topLabel]="widgetAuditCookiesScore.topLabel"
                [topChangeContent]="formatTopWidgetValue(auditScoreData?.cookies?.score, auditScoreData?.cookies?.max)"
                [topTooltip]="widgetAuditCookiesScore.tooltip"
                [topHandler]="widgetAuditCookiesScore.topHandler"
                [state]="auditScoreLoadingState"
                [bottomHandler]="widgetAuditCookiesScore.bottomHandler"
                [haveEnoughData]="auditCookiesScoreSparkLineData.length > 1"
                [metricType]="widgetAuditCookiesScore.metricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="auditScoreData?.cookies?.score"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === widgetAuditCookiesScore.metricType"
                [disableHighlightFromClick]="true"
                class="cookies-score-card">
      <sparkline-chart-insight
        [label]="'since last run'"
        [dataLoaded]="auditScoreSparklineDataLoaded"
        [dataPoints]="auditCookiesScoreSparkLineData"
        [meaningOfUp]="'positive'"
        [meaningOfNoChange]="'none'">
      </sparkline-chart-insight>
    </split-card>
  </div>

  <div class="trends-header-title">
    <span>Tech Validation</span>
    <filter-spinner [state]="techValidationState"></filter-spinner>
  </div>

  <div class="trends-section">
    <div class="trend-summary">

      <rule-summary-trends [totalRules]="ruleSummaryData?.totalRuleCount"
        [runId]="runId"
        [auditId]="auditId"
        [trendData]="ruleInstancesTrend"
        [chartData]="ruleInstancesTrendChartData"
        [widgetState]="chartState"
        [filters]="trendFilters"
        tooltipText="Total number of rules by status and run date"
        chartId="audit-summary-rule-chart"
        (summaryCardClicked)="summaryCardClicked($event)"
        (historyClicked)="openTrendsFullscreenChart($event)">
      </rule-summary-trends>
    </div>

    <div class="split-cards">
      <!-- Unique Tag Count Widget-->
      <split-card [topLabel]="widgetUniqueTags.topLabel"
                  [topChangeContent]="formatNumber(headerData?.filteredUniqueTagCount)"
                  [topHandler]="widgetUniqueTags.topHandler"
                  [topTooltip]="widgetUniqueTags.tooltip"
                  [bottomHandler]="widgetUniqueTags.bottomHandler"
                  [haveEnoughData]="uniqueTagsSparkLineData.length > 1"
                  class="unique-tags-card">
          <sparkline-chart-insight [label]="'since last run'"
                                   [dataLoaded]="sparklineDataLoaded"
                                   [dataPoints]="uniqueTagsSparkLineData"
                                   [meaningOfUp]="'none'"
                                   [meaningOfNoChange]="'none'">
          </sparkline-chart-insight>
      </split-card>

      <!-- Broken Tag Widget -->
      <split-card [topLabel]="widgetBrokenTags.topLabel"
                  [topChangeContent]="formatNumber(headerData?.filteredBrokenTagCount)"
                  [topHandler]="widgetBrokenTags.topHandler"
                  [bottomHandler]="widgetBrokenTags.bottomHandler"
                  [topChangeMeaning]="widgetBrokenTags.topChangeMeaning"
                  [topTooltip]="widgetBrokenTags.tooltip"
                  [haveEnoughData]="brokenTagsSparkLineData.length > 1"
                  class="broken-tags-card">
          <sparkline-chart-insight
            [label]="'since last run'"
            [dataLoaded]="sparklineDataLoaded"
            [dataPoints]="brokenTagsSparkLineData"
            [meaningOfUp]="'negative'"
            [meaningOfNoChange]="'none'">
          </sparkline-chart-insight>
      </split-card>

      <!-- Broken Pages Widget-->
      <split-card [topLabel]="widgetBrokenFinalPages.topLabel"
                  [topChangeContent]="formatNumber(headerData?.filteredBrokenPageCount)"
                  [topHandler]="widgetBrokenFinalPages.topHandler"
                  [bottomHandler]="widgetBrokenFinalPages.bottomHandler"
                  [topChangeMeaning]="widgetBrokenFinalPages.topChangeMeaning"
                  [topTooltip]="'Total number of final pages which have a 0, 400+ or 500+ HTTP status code.' + (techValidationState === stateOptions.Filtered ? ' (Filtered)' : '')"
                  [haveEnoughData]="brokenPagesSparkLineData.length > 1"
                  class="broken-pages-card">
          <sparkline-chart-insight
            [label]="'since last run'"
            [dataLoaded]="sparklineDataLoaded"
            [dataPoints]="brokenPagesSparkLineData"
            [meaningOfUp]="'negative'"
            [meaningOfNoChange]="'none'">
          </sparkline-chart-insight>
      </split-card>
    </div>
  </div>

</div>
<div class="privacy-section" *ngIf="privacyFeatureEnabled">
  <privacy-section-in-audit-summary [auditId]="auditId" [runId]="runId" [apiFilters]="apiFilters"></privacy-section-in-audit-summary>
</div>
<div class="primary-tags-health-section">
  <div class="title-spinner-wrap">
    <p class="section-title">Health of Primary Tags</p>
    <filter-spinner [state]="primaryTagsHealthState"></filter-spinner>
  </div>
  <div *ngIf="hasPrimaryTags && primaryTags?.length"
       class="primary-tags-wrap"
       [class.updating]="primaryTagsHealthState === 'loading'"
       [style.height.px]="primaryTagsViewportHeight">
    <cdk-virtual-scroll-viewport [itemSize]="primaryTagsCardHeight" class="pthc-virtual-viewport">
      <div *cdkVirtualFor="let group of primaryTags; trackBy: trackByFn" class="pthc-tag-group">
        <primary-tag-health-card *ngFor="let tag of group"
                                 [data]="tag"
                                 [pagesScanned]="pagesScanned"
                                 (menuItemClicked)="handleTagHealthEmit($event)"
                                 (accountCountClicked)="handleAccountCountClicked($event)">
        </primary-tag-health-card>
        <missing-tags-card *ngIf="group.length < 2 && missingTags?.length"
                           [tags]="missingTags"
                           [state]="missingTagsState.Some">
        </missing-tags-card>
      </div>
      <div class="pthc-tag-group" *ngIf="(primaryTagsCount % 2) === 0 && missingTags?.length">
        <missing-tags-card [tags]="missingTags" [state]="missingTagsState.Some"></missing-tags-card>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <!-- No primary tags found in audit (but they have been set up in the account) -->
  <div *ngIf="primaryTags && !primaryTags?.length && missingTags && missingTags?.length" class="zero-state">
    <missing-tags-card [tags]="missingTags" [state]="missingTagsState.All"></missing-tags-card>
  </div>

  <!-- Has primary tags but current global filter is not a primary tag -->
  <div *ngIf="hasPrimaryTags && hasTagFilterEnabled && !this.primaryTags?.length" class="zero-state">
    <div class="top-text">No primary tags to display based on your current filter state.</div>
  </div>

  <!-- No primary tags have been set up in the account -->
  <div *ngIf="!hasPrimaryTags && missingTags && !missingTags?.length" class="zero-state">
    <div class="top-text">You currently don't have any primary tags selected.</div>
    <div class="bottom-text" (click)="goToPrimaryTagsSetup()" *ngIf="!userIsReadOnly">
      Specify the tags you care about most.
    </div>
  </div>

</div>
<div class="detected-tags-section">
  <div class="title-spinner-wrap sticky-table-header">
    <filter-spinner class="left-side" [state]="detectedTagsState"></filter-spinner>
    <p class="section-title">Detected Tags</p>
    <op-audit-report-export-menu
      [auditId]="auditId"
      [runId]="runId"
      [tableCompletedState]="detectedTagsState"
      [config]="exportReportConfig">
    </op-audit-report-export-menu>
  </div>
  <table mat-table
         [dataSource]="detectedTagsDataSource"
         matSort
         class="detected-tags-table"
         matSortActive="tagName"
         matSortDirection="asc"
         matSortStart="desc"
  >
    <ng-container matColumnDef="tagName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>TAG NAME</th>
      <td mat-cell *matCellDef="let tag">
        <div class="tag-name-wrap">
          <img [src]="getTagIconUrl(tag.tagId)" class="tag-icon" alt="tag"/>
          <span class="is-clickable" (click)="navToTagInventory(tag)">{{ tag.tagName }}</span>
          <div class="ellipse-menu" [class.show-menu]="tag.menuOpen">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="tag.menuOpen = true">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" class="pthc-menu" (closed)="tag.menuOpen = false;">
              <button mat-menu-item class="primary-tag-health-filter-menu-item" (click)="navToTagInventory(tag)">
                <mat-icon>north_east</mat-icon>
                Explore this item in Tag Inventory
              </button>
              <button mat-menu-item class="primary-tag-health-filter-menu-item" (click)="navToTagHealth(tag)">
                <mat-icon>north_east</mat-icon>
                Explore this item in Tag Health
              </button>
              <button mat-menu-item class="primary-tag-health-filter-menu-item" (click)="filterReportByTag(tag)">
                <mat-icon>tune</mat-icon>
                Filter reports by this item
              </button>
            </mat-menu>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="tagCategoryName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>TAG CATEGORY</th>
      <td mat-cell *matCellDef="let tag">
        <span class="is-clickable" (click)="handleTagCategoryClick(tag)">
          {{ tag.tagCategoryName }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="pageCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-100">PAGES<br>TAGGED</th>
      <td mat-cell *matCellDef="let tag" class="center-text">
        <span class="is-clickable" (click)="navToTagInventoryWithReportFilter(tag, false)">
          {{ tag.pageCount | number }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="pageNotPresentCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-100">PAGES<br>NOT TAGGED</th>
      <td mat-cell *matCellDef="let tag" class="center-text">
        <span class="is-clickable" (click)="navToTagInventoryWithReportFilter(tag, true)">
          {{ tag.pageNotPresentCount | number }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="tagLoadTimeAverage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-100">AVERAGE TAG<br>LOAD (ms)</th>
      <td mat-cell *matCellDef="let tag" class="center-text">
        <span class="is-clickable {{ tag.tagLoadTimeClass }}" (click)="navToTagHealth(tag)">
          {{ tag.tagLoadTimeAverage | number }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="tagAccountCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-100">ACCOUNTS</th>
      <td mat-cell *matCellDef="let tag" class="center-text">
        <span class="is-clickable" (click)="navToTagInventory(tag)">
          {{ tag.tagAccountCount | number }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="uniqueVariableCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-100">VARIABLES</th>
      <td mat-cell *matCellDef="let tag" class="center-text">
        <span class="is-clickable" (click)="navToVariableInventory(tag)">
          <ng-container *ngIf="tag.uniqueVariableCount">{{ tag.uniqueVariableCount | number }}</ng-container>
          <ng-container *ngIf="!tag.uniqueVariableCount">ø</ng-container>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="detectedTagsDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: detectedTagsDisplayedColumns;"></tr>
  </table>
</div>
