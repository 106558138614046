export class ResizeableTableUtils {
  static readonly RESIZEABLE_COLUMN_ATTRIBUTE = 'resizeableColumn';
  static readonly DEFAULT_MIN_WIDTH = 120;

  static cleanTableClassName(classList: DOMTokenList): string {
    return Array.from(classList)
        .find(c => c.startsWith('mat-column-'))
        ?.replace('mat-column-', '');
  }

  static getAllColumns(element: HTMLElement): HTMLTableCellElement[] {
    return Array.from(element.querySelectorAll('th'))
  }

  static hasResizeableAttribute(el: Element): boolean {
    return ResizeableTableUtils.getResizeableColumnAttribute(el) !== null;
  }

  static getResizeableColumnMinWidth(el: Element): number | null {
    const attr = ResizeableTableUtils.getResizeableColumnAttribute(el);
    return attr
      ? ResizeableTableUtils.parseResizeableColumnMinWidth(attr.value)
      : null;
  }

  static parseResizeableColumnMinWidth(value: string): number | null {
    const parsedMinWidth = value && parseInt(value);
    return parsedMinWidth && !isNaN(parsedMinWidth) && parsedMinWidth > 0
      ? parsedMinWidth
      : null;
  }

  static getResizeableColumnAttribute(el: Element): Attr | null {
    return el
      ? el.attributes.getNamedItem(ResizeableTableUtils.RESIZEABLE_COLUMN_ATTRIBUTE)
      : null;
  }
}
