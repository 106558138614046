import { IWJReportParams } from './../../web-journey-report.models';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IWJResultsVariable } from '../tag-report-table/wj-results-tag-report-table.models';
import { WjResultsTagReportTableService } from '../tag-report-table/wj-results-tag-report-table.service';
import { dateRunFormat, timeRunFormat } from '../web-journey-results.constants';
import { IWebJourneyRun } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { userIsAdmin } from '@app/authUtils';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';
import { WebJourneyReportService } from '../../web-journey-report.service';
import { AccountsService } from '@app/components/account/account.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-report-table-header',
  templateUrl: './wj-results-tag-report-table-header.component.html',
  styleUrls: ['./wj-results-tag-report-table-header.component.scss']
})
export class WjResultsTagReportTableHeaderComponent implements OnInit, OnDestroy {

  @Input() showComparison: boolean;

  readonly dateTimeFormat = `${dateRunFormat}, ${timeRunFormat}`;
  readonly sortDirection = ESortDirection;

  journeyId: number;
  runId: number;

  runs: IWebJourneyRun[];
  previousRun: IWebJourneyRun;
  currentRun: IWebJourneyRun;

  isCurrentUserAdmin = false;

  sortVarsConfig: {propName, direction};

  private destroy$ = new Subject();

  constructor(private route: ActivatedRoute,
              private authService: AuthenticationService,
              private accountsService: AccountsService,
              private tagReportTableService: WjResultsTagReportTableService,
              private webJourneyReportService: WebJourneyReportService) { }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe((params: IWJReportParams) => {
        this.journeyId = Number(params.journeyId);
        this.runId = Number(params.runId);

        if (!this.runs) this.prepareRuns();
        else this.setCurrentAndPreviousRuns();
      });

    this.tagReportTableService.sortByProp$
      .pipe(takeUntil(this.destroy$))
      .subscribe(sortVarsConfig => this.sortVarsConfig = sortVarsConfig);

    this.accountsService.getUser().subscribe(user => {
      this.isCurrentUserAdmin = userIsAdmin(user);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private prepareRuns(): void {
    this.webJourneyReportService.getJourneyRuns(this.journeyId).subscribe(runs => {
      this.runs = runs.sort(
        (run1, run2) => new Date(run1.completedAt).getTime() - new Date(run2.completedAt).getTime()
      );

      this.setCurrentAndPreviousRuns();
    });
  }

  private setCurrentAndPreviousRuns() {
    const runIndex = this.runs.findIndex(r => r.id === this.runId);
    this.currentRun = this.runs[runIndex];
    this.previousRun = this.runs[runIndex - 1];
  }

  onSortChange(propName: keyof IWJResultsVariable): void {
    this.tagReportTableService.sortBy(propName);
  }

}
