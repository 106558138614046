// In our application, we define icons for inline use, such as a bell icon in grid reports, by storing them in an object
// (e.g., SvgIconElements). The icons can then be rendered using the innerHTML property,
// like this: innerHTML = SvgIconElements[iconName];
// This approach is necessary because, in some cases, we cannot use componentFactory or matIconRegistry to load SVGs
// from downloaded files. Thus, we rely on this method to directly insert the SVGs as HTML content when needed.
export const SvgIconElements = {
  arrowDown:`<svg width="9" height="11" viewBox="0 0 9 11" fill="inherit" xmlns="http://www.w3.org/2000/svg"><path d="M3.54297 8.53584L3.54297 0.740838C3.54297 0.464696 3.76683 0.240838 4.04297 0.240838C4.31912 0.240838 4.54297 0.464696 4.54297 0.740838L4.54297 8.53584L7.19297 5.88584C7.40393 5.70765 7.7194 5.7342 7.89759 5.94515C8.0567 6.13352 8.05474 6.40975 7.89298 6.59584L4.39297 10.0958C4.19855 10.2864 3.88739 10.2864 3.69297 10.0958L0.192971 6.59584C0.0118017 6.38743 0.0338797 6.07162 0.242284 5.89045C0.428371 5.72868 0.704608 5.72672 0.892969 5.88584L3.54297 8.54084L3.54297 8.53584Z" fill="inherit"/></svg>`,
  bellCheck: `<svg width="24" height="25" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" fill="inherit" clip-rule="evenodd" d="M18.034 10.728v5.193l1.329 1.34c.654.653.187 1.775-.738 1.775H4.95c-.924 0-1.381-1.122-.727-1.776l1.35-1.34v-5.192c0-3.198 1.693-5.857 4.673-6.563V3.46c0-.862.685-1.558 1.547-1.558.862 0 1.568.696 1.568 1.558v.706c2.97.706 4.674 3.375 4.674 6.563Zm-4.165 9.346a2.076 2.076 0 1 1-4.154 0h4.154Zm2.477-9.77a.953.953 0 0 0-1.347-1.347l-4.664 4.663-1.752-1.752a.953.953 0 0 0-1.347 1.347l2.426 2.426a.952.952 0 0 0 1.347 0l5.337-5.337Z"/></svg>`,
  bellEmpty: `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="inherit"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"/></svg>`,
  bellEmptyTriggered: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_6879_114499)"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.0253 10.4578V15.6484L19.3541 16.9876C20.0081 17.6416 19.5409 18.7628 18.617 18.7628H4.94486C4.02093 18.7628 3.56415 17.6416 4.21817 16.9876L5.56774 15.6484V10.4578C5.56774 7.26032 7.25989 4.60271 10.2393 3.89679V3.19086C10.2393 2.32921 10.9245 1.63367 11.7861 1.63367C12.6478 1.63367 13.3537 2.32921 13.3537 3.19086V3.89679C16.3227 4.60271 18.0253 7.2707 18.0253 10.4578ZM13.8624 19.8009C13.8624 20.9428 12.9281 21.8772 11.7861 21.8772C10.6338 21.8772 9.70987 20.9428 9.70987 19.8009H13.8624ZM6.39824 2.46417C6.81349 2.87942 6.80311 3.5542 6.3671 3.94869C4.9241 5.26711 3.9275 7.06307 3.61606 9.07704C3.53301 9.57534 3.09699 9.93869 2.59869 9.93869C1.95505 9.93869 1.46713 9.36772 1.56057 8.73446C1.95505 6.26372 3.18004 4.06289 4.95524 2.44341C5.36011 2.0593 6.00375 2.06968 6.39824 2.46417ZM17.1948 2.46417C17.5893 2.06968 18.2329 2.0593 18.6481 2.44341C20.413 4.07327 21.6276 6.26372 22.0221 8.72408C22.1259 9.35734 21.6276 9.92831 20.9943 9.92831C20.4856 9.92831 20.0496 9.56496 19.977 9.06666C19.6655 7.05269 18.6585 5.25673 17.2259 3.93831C16.8003 3.54382 16.7795 2.87942 17.1948 2.46417ZM11.7924 6C9.59031 6 7.80511 7.78519 7.80511 9.98734V16.5392H15.7798V9.98734C15.7798 7.78519 13.9946 6 11.7924 6Z" /><path d="M11.7925 15.625C12.2987 15.625 12.7091 15.2146 12.7091 14.7083C12.7091 14.2021 12.2987 13.7917 11.7925 13.7917C11.2862 13.7917 10.8758 14.2021 10.8758 14.7083C10.8758 15.2146 11.2862 15.625 11.7925 15.625Z" /><path d="M10.8758 7.375H12.7091V12.875H10.8758V7.375Z" /></g><defs><clipPath id="clip0_6879_114499"><rect width="24" height="24" /></clipPath></defs></svg>`,
  bellFilledTriggered: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path fill-rule="evenodd" clip-rule="evenodd" fill="inherit" d="M18.025 10.458v5.19l1.33 1.34c.653.654.186 1.775-.738 1.775H4.945c-.924 0-1.38-1.121-.727-1.775l1.35-1.34v-5.19c0-3.198 1.692-5.855 4.671-6.561V3.19c0-.862.685-1.557 1.547-1.557.862 0 1.568.695 1.568 1.557v.706c2.969.706 4.671 3.374 4.671 6.56ZM13.862 19.8a2.076 2.076 0 1 1-4.153 0h4.153ZM6.398 2.464c.415.415.405 1.09-.03 1.485a8.762 8.762 0 0 0-2.752 5.128c-.083.498-.519.862-1.017.862A1.05 1.05 0 0 1 1.56 8.734a10.822 10.822 0 0 1 3.394-6.29 1.038 1.038 0 0 1 1.443.02Zm10.797 0a1.05 1.05 0 0 1 1.453-.02 10.887 10.887 0 0 1 3.374 6.28 1.042 1.042 0 0 1-1.028 1.204c-.508 0-.944-.363-1.017-.861a8.82 8.82 0 0 0-2.751-5.129 1.02 1.02 0 0 1-.031-1.474ZM12.959 6.75h-2.333v7h2.333v-7Zm0 9.333a1.167 1.167 0 1 1-2.333 0 1.167 1.167 0 0 1 2.333 0Z"/></g><defs><clipPath id="a"><path d="M0 0h24v24H0z"/></clipPath></defs></svg>`,
};

export const SvgIconsForMatIconRegistry = [
  'bell-check',
  'bell-check-ringing',
  'bell-empty-ringing',
  'change-freq',
  'filter-alt-white',
  'note_alt_outline',
  'bell-empty-triggered',
  'bell-filled-triggered',
  'pause_icon',
  'pause_card',
  'use_case_analytics',
  'finance',
  'op-black-logo',
  'scan_limit',
  'datalayer_icon',
  'request_blocked',
  'onetrust-logo',
];
