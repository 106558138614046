import { ChangeDetectorRef, Component, EventEmitter, Input, Output, AfterViewInit } from '@angular/core';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AlertComponent } from '@app/components/alert/alert.component';
import { IAlertPartialModalPayload, IAlertQuickCreatePayload } from '@app/components/alert/alert.models';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '@app/components/alert/alert.service';
import {
  IAlertMetricDefaultOperatorWithValue,
  IAlertReportMetricsConfig,
  IAlertReportToMetricsConfig
} from '@app/components/alert/alert-logic/alert-logic.models';
import { EAlertMetricChangeType } from '@app/components/alert/alert-logic/alert-logic.enums';
import { AccountsService } from '@app/components/account/account.service';
import { IUser } from '@app/moonbeamModels';
import { QuickCreateOpSelectors } from '@app/components/alert/alert-quick-create/alert-quick-create.constants';
import { EAlertFilterType, EAlertMenuContext, EAlertModalType } from '@app/components/alert/alert.enums';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import {
  IAuditReportFilter
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { UsageFilterBarService } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.service';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EUsageFilterTypes } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.enums';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  selector: 'op-alert-quick-create-new',
  templateUrl: './alert-quick-create-new.component.html',
  styleUrls: ['./alert-quick-create-new.component.scss'],
})
export class AlertQuickCreateNewComponent implements AfterViewInit {
  reportConfig: IAlertReportToMetricsConfig;
  metricConfig: IAlertReportMetricsConfig;
  createForm: UntypedFormGroup;
  user: IUser;
  defaultAlertValue: IAlertMetricDefaultOperatorWithValue;
  opSelectors = QuickCreateOpSelectors;

  @Input() payload: IAlertQuickCreatePayload;
  @Output() onClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalService: OpModalService,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private alertReportingService: AlertReportingService,
    private accountsService: AccountsService,
    private changeDetectorRef: ChangeDetectorRef,
    private reportFilterBarService: AuditReportFilterBarService,
    private usageFilterBarService: UsageFilterBarService
  ) {
    this.initForm();
  }

  ngAfterViewInit() {
    this.accountsService.getUser().subscribe((user) => {
      this.user = user;
      this.reportConfig = AlertUtils.getReportConfigByMetricType(this.payload.metricType);
      this.metricConfig = AlertUtils.getMetricConfigByMetricType(this.payload.metricType);
      this.defaultAlertValue = this.getDefaultAlertValue();
      this.fillForm();
      this.changeDetectorRef.detectChanges();
    });
  }

  initForm(): void {
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required],
      value: [''],
      emails: [[]],
      metricType: [''],
      metricChangeType: [EAlertMetricChangeType.ValueChange],
      targetValueComparator: [''],
      filters: [[]],
    });
  }

  fillForm(): void {
    const name = `${this.reportConfig.name} - ${this.metricConfig.name}`;
    const value = this.defaultAlertValue.value;
    const metricType = this.defaultAlertValue.metricType;
    const metricChangeType = null;
    const targetValueComparator = this.defaultAlertValue.operator.comparator;
    const emails = [this.user.email];
    const filters = this.payload.filters;

    this.createForm.patchValue({
      name,
      value,
      metricType,
      metricChangeType,
      targetValueComparator,
      emails,
      filters,
    });
  }

  getDefaultAlertValue(): IAlertMetricDefaultOperatorWithValue {
    return this.alertService.getMetricDefaultAlertValue(this.metricConfig, this.payload.currentValue);
  }

  openCustomizeAlert() {
    const {
      auditId,
      runId,
      metricType,
      filters,
      filterType,
    } = this.payload;

    const data: IAlertPartialModalPayload = {
      type: EAlertModalType.CreatePrefilled,
      auditId,
      runId,
      metricType,
      filterType,
      name: this.createForm.get('name').value,
      emails: this.createForm.get('emails').value,
      operator: this.defaultAlertValue.operator,
      currentValue: this.defaultAlertValue.value,
      filters: filterType === EAlertFilterType.V1 ? this.reportFilterBarService.generateApiPostBody(filters as IAuditReportFilter[]) : this.usageFilterBarService.generateApiPostBody(filters as IOpFilterBarV2Filter<EUsageFilterTypes>[]),
      menuContext: this.alertReportingService.isAccountLevelMetric(metricType) ? EAlertMenuContext.Usage : EAlertMenuContext.Audit,
    };

    this.modalService.openFixedSizeModal(AlertComponent, {
      disableClose: true,
      data: {
        ...data,
      }, autoFocus: false
    });

    this.onClosed.emit();
  }

  get name(): UntypedFormControl {
    return this.createForm.get('name') as UntypedFormControl;
  }

  get value(): UntypedFormControl {
    return this.createForm.get('value') as UntypedFormControl;
  }

  get metricType(): UntypedFormControl {
    return this.createForm.get('metricType') as UntypedFormControl;
  }

  get metricChangeType(): UntypedFormControl {
    return this.createForm.get('metricChangeType') as UntypedFormControl;
  }

  get targetValueComparator(): UntypedFormControl {
    return this.createForm.get('targetValueComparator') as UntypedFormControl;
  }

  get emails(): UntypedFormControl {
    return this.createForm.get('emails') as UntypedFormControl;
  }

  get filters(): UntypedFormControl {
    return this.createForm.get('filters') as UntypedFormControl;
  }
}
