<div class="flex-col flex-stretch">
  <div class="flex-row comparison-variables">
    <div class="flex-stretch excluded-vars">
      <div class="flex-stretch title">Variables to Exclude:</div>
      <op-list-editor
        [items]="excludedVariables"
        (onChange)="updatePrimaryTagSettings()"
      ></op-list-editor>
    </div>

    <div class="flex-stretch alignment-criteria">
      <div class="flex-stretch title">Variables Used to Match Comparable Tags:</div>
      <op-list-editor
        [items]="alignmentCriteria"
        (onChange)="updatePrimaryTagSettings()"
      ></op-list-editor>
    </div>
  </div>
</div>
