import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOpTab } from '@app/components/shared/components/op-tabs/op-tabs.models';
import { EPageDetailsOPSelectors, EPageDetailsPlacement, EPageDetailsTabs } from './page-details.constants';
import {
  AuditReportUrlBuilders,
  BROWSER_CONSOLE_LOGS_SEARCH_TEXT_KEY,
  COOKIES_SEARCH_TEXT_KEY,
  PAGE_DETAILS_FULL_SCREEN_KEY,
  REQUEST_LOG_PAGE_INDEX_KEY,
  REQUEST_LOG_SEARCH_TEXT_KEY,
  REQUEST_LOG_SHOW_FILE_CHANGES_KEY,
  REQUEST_LOG_SHOW_PRE_AUDIT_ACTION_REQUESTS_KEY,
  REQUEST_LOG_SORT_BY_KEY,
  REQUEST_LOG_SORT_DESC_KEY,
  TAGS_SEARCH_TEXT_KEY
} from '../audit-report/audit-report.constants';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { IAuditPageInfo, IPageDetailsModalData } from '../page-details/page-details.models';
import { PageDetailsReportService } from './page-details.service';
import { forkJoin, fromEvent, Observable, Subject } from 'rxjs';
import { catchError, debounceTime, take, takeUntil } from 'rxjs/operators';
import { IOpenPage } from '../audit-report/audit-report-page-details-drawer.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DiscoveryAuditService, IPageListPage } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { Features } from '@app/moonbeamConstants';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { IAuditReportPageDetailsDrawerService } from '../audit-report/audit-report-page-details-drawer.models';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { IAuditRunErrors } from '@app/components/domains/discoveryAudits/discoveryAuditModels';

@Component({
  selector: 'op-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.scss']
})
export class PageDetailsComponent implements OnInit, OnDestroy {
  readonly EPageDetailsTabs = EPageDetailsTabs;
  readonly IS_ACTIVE_TAB_SET_BY_USER_PROPERTY = 'activeTabSetByUser';

  pageInfo: IAuditPageInfo;
  pageInfoLoading: boolean;
  pageErrors: IAuditRunErrors;

  loading = true;
  auditId: number;
  runId: number;
  pageId: string;
  tabs: IOpTab[];

  url: string;
  title: string;
  lastRunDate: number;

  componentPlacement: EPageDetailsPlacement;
  screenshotUrl: string;
  state: object = {};

  EPageDetailsPlacement = EPageDetailsPlacement;

  isScrolled: boolean;
  accountFeatures: Features[] = [];
  private pageDetailsScrolls$ = new Subject<number>();

  readonly reportType = EReportType.AUDIT;

  private onDestroy$ = new Subject();

  private resize$: Observable<any>;
  mobileView: boolean = false;

  activeTab: EPageDetailsTabs = EPageDetailsTabs.PageInformation;
  @ViewChild('pageUrlLink') pageUrlLink: ElementRef<HTMLAnchorElement>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pageDetailsService: PageDetailsReportService,
    private changeDetector: ChangeDetectorRef,
    public pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private storageService: StorageService,
    private authenticationService: AuthenticationService,
    private modalEscapeService: ModalEscapeService,
    private discoveryAuditService: DiscoveryAuditService,
    readonly filterBarService: AuditReportFilterBarService,
    @Optional() private dialogRef: MatDialogRef<PageDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public modalData: IPageDetailsModalData
  ) {
    this.getParams();
  }

  ngOnInit() {
    this.mobileView = window.innerWidth < 1000;

    this.resize$ = fromEvent(window, 'resize');
    this.resize$.pipe(debounceTime(300), takeUntil(this.onDestroy$)).subscribe(() => {
      this.mobileView = window.innerWidth < 1000;
    });

    // full screen mode
    if (this.modalData) {
      this.pageId = this.modalData.pageId;
      this.url = this.modalData.pageUrl;
      this.auditId = this.modalData.auditId;
      this.runId = this.modalData.runId;
      this.componentPlacement = EPageDetailsPlacement.FullScreen;
      this.setActiveTab(this.modalData.tab || EPageDetailsTabs.PageInformation);

      if (this.modalData?.state) {
        this.setState(this.modalData.state);
      }
      this.getPageInfo();
    }

    // deep link mode
    else if (this.componentPlacement === EPageDetailsPlacement.DeepLink) {
      this.getPageInfo();
      this.pageDetailsDrawerService.pageDrawerOpenedOrUpdated$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((newPage: IOpenPage) => {
          if (!newPage) return;
          this.setActiveTab(newPage.tab || EPageDetailsTabs.PageInformation);
          this.setState(newPage.state);
        });
    }

    // drawer mode
    else {
      this.componentPlacement = EPageDetailsPlacement.InDrawer;
      this.pageDetailsDrawerService.pageDrawerOpenedOrUpdated$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((newPage: IOpenPage) => {
          if (!newPage) return;
          // The active tab may already be set on the initial opening of page details drawer. We need a way to
          // update this through the event emission inside the drawer tabs, so just passing the instruction on the
          // new page and overwriting here before we check whether the active tab is set by a user already.
          if (newPage.updateActiveTab) this.activeTab = newPage.tab;
          this.setActiveTab(this.isActiveTabSetByUser(this.state) 
            ? this.activeTab
            : newPage.tab || EPageDetailsTabs.PageInformation);
          this.pageId = newPage.page.id;
          this.url = newPage.page.url;
          this.setState(newPage.state);
          this.pageInfo = null;
          this.getPageInfo();
        });
    }

    this.pageDetailsScrolls$.pipe(debounceTime(25), takeUntil(this.onDestroy$)).subscribe((offset: number) => {
      this.isScrolled = (offset !== 0);
      this.changeDetector.detectChanges();
    });

    this.authenticationService
      .getFeaturesWithCache()
      .pipe(take(1))
      .subscribe(features => {
        this.accountFeatures = features;
        this.buildTabs();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  setCookieNameFilter(name: string) {
    this.filterBarService.addCookieNameFilter(name);
  }

  private getParams() {
    this.activatedRoute.params.subscribe(params => {
      if (+params.auditId && +params.runId) {
        // close page details if user changes runs
        if ((this.auditId && (+params.auditId !== this.auditId)) || (this.runId && (+params.runId !== this.runId))) {
          this.pageDetailsDrawerService.closePageDetails();
        }

        this.auditId = +params.auditId;
        this.runId = +params.runId;
      }

      // if we find the pageId in the URL params we
      // know we've deep linked to this component
      if (params.pageId) {
        this.pageId = params.pageId;
        this.componentPlacement = EPageDetailsPlacement.DeepLink;
      }
    });

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.tab) {
        this.setActiveTab(queryParams.tab);
      }

      [
        REQUEST_LOG_SEARCH_TEXT_KEY,
        REQUEST_LOG_SHOW_FILE_CHANGES_KEY,
        REQUEST_LOG_SHOW_PRE_AUDIT_ACTION_REQUESTS_KEY,
        REQUEST_LOG_SORT_BY_KEY,
        REQUEST_LOG_SORT_DESC_KEY,
        REQUEST_LOG_PAGE_INDEX_KEY
      ].forEach(key => {
        if (queryParams.hasOwnProperty(key)) {
          this.state[key] = queryParams[key];
        }
      });
    });
  }

  private getPageInfo() {
    this.pageInfoLoading = true;
    forkJoin([
      this.pageDetailsService
        .getAuditPageInfo(this.auditId, this.runId, this.pageId),
      this.discoveryAuditService.getAuditRunFailures(this.auditId, this.runId),
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        catchError(() => {
          this.pageInfoLoading = false;
          return [];
        })
      )
      .subscribe(([pageInfo, pageErrors]) => {
        this.pageInfoLoading = false;
        this.pageInfo = pageInfo;
        this.pageErrors = pageErrors;
        this.url = pageInfo.initialPageUrl || pageInfo.url;
        this.title = pageInfo.title;
        this.lastRunDate = pageInfo.visitStartTimestamp;
      });
  }

  tabClicked(path: EPageDetailsTabs): void {
    this.setActiveTab(path, true);

    if (this.componentPlacement === EPageDetailsPlacement.DeepLink) {
      // update query param as user changes tabs
      this.router.navigate(
        [AuditReportUrlBuilders.pageDetailsDeepLink(this.auditId, this.runId, this.pageId)],
        {
          relativeTo: this.activatedRoute,
          queryParams: {
            tab: path
          }
        }
      );
    }
  }

  fullScreenModalClosed() {
    // Focus is lost or in a bad state after the tag initiator full screen modal closes
    // We focus on an element inside the page details view to allow ESC key to close the
    // page details flyout. Otherwise a click would be required first to replace the focus.
    this.pageUrlLink?.nativeElement?.focus();
  }

  onScroll(event: Event) {
    this.pageDetailsScrolls$.next((event.target as HTMLElement).scrollTop);
  }

  @HostListener('document:keydown.escape')
  handleClosePageDetails(): void {
    if (this.modalEscapeService.getLast() > 1) return;

    if (this.componentPlacement === EPageDetailsPlacement.InDrawer) {
      this.pageDetailsDrawerService.closePageDetails();
    }

    if (this.componentPlacement === EPageDetailsPlacement.FullScreen) {
      this.dialogRef.close();
      // x was clicked -- assume user prefers full screen view
      this.storageService.setValue(PAGE_DETAILS_FULL_SCREEN_KEY, 'true', StorageType.Local, false);
    }
  }

  togglePageDetailsFullScreen(): void {
    if (this.componentPlacement !== EPageDetailsPlacement.FullScreen) {
      const page: IPageListPage = {
        id: this.pageId,
        url: this.url
      };

      this.pageDetailsDrawerService.openPageDetails(page, this.auditId, this.runId, this.activeTab, true, this.state);
    }

    if (this.componentPlacement === EPageDetailsPlacement.FullScreen) {
      // shrink full screen button was clicked -- assume user prefers drawer view
      this.storageService.setValue(PAGE_DETAILS_FULL_SCREEN_KEY, 'false', StorageType.Local, false);

      // close modal and open page details in drawer
      this.dialogRef.close();
      this.pageDetailsDrawerService.openPageDetails({
        id: this.pageId,
        url: this.url
      }, this.auditId, this.runId, this.activeTab, false, this.state);
    }
  }

  onStateUpdated(newState: any) {
    this.setState(newState);
  }

  private buildTabs() {
    this.tabs = [
      {
        name: 'Page Information',
        path: EPageDetailsTabs.PageInformation,
        opSelector: EPageDetailsOPSelectors.PageInfo
      },
      {
        name: 'Tags',
        path: EPageDetailsTabs.Tags,
        opSelector: EPageDetailsOPSelectors.Tags
      },
      {
        name: 'Cookies',
        path: EPageDetailsTabs.Cookies,
        opSelector: EPageDetailsOPSelectors.Cookies
      },
      {
        name: 'Accessibility',
        path: EPageDetailsTabs.Accessibility,
        label: 'New',
        opSelector: EPageDetailsOPSelectors.Accessibility
      },
      {
        name: 'Request Log',
        path: EPageDetailsTabs.RequestLog,
        opSelector: EPageDetailsOPSelectors.RequestLog
      },
      {
        name: 'Console Log',
        path: EPageDetailsTabs.ConsoleLog,
        opSelector: EPageDetailsOPSelectors.ConsoleLog
      },
      {
        name: 'Tag Initiators',
        path: EPageDetailsTabs.TagInitators,
        opSelector: EPageDetailsOPSelectors.TagInitiators
      },
      {
        name: 'Rules',
        path: EPageDetailsTabs.Rules,
        opSelector: EPageDetailsOPSelectors.Rules
      }
    ];
  }

  handleNavToTab({ tab, searchValue, updateActiveTab, showPreAuditActions }): void {
    const keys = {
      [EPageDetailsTabs.RequestLog]: REQUEST_LOG_SEARCH_TEXT_KEY,
      [EPageDetailsTabs.Cookies]: COOKIES_SEARCH_TEXT_KEY,
      [EPageDetailsTabs.ConsoleLog]: BROWSER_CONSOLE_LOGS_SEARCH_TEXT_KEY,
      [EPageDetailsTabs.Tags]: TAGS_SEARCH_TEXT_KEY
    };

    const state = {
      [keys[tab]]: searchValue,
    };

    if (showPreAuditActions) {
      state[REQUEST_LOG_SHOW_PRE_AUDIT_ACTION_REQUESTS_KEY] = showPreAuditActions;
    }

    const page: IPageListPage = {
      id: this.pageId,
      url: this.url
    };

    this.pageDetailsDrawerService.openPageDetails(page, this.auditId, this.runId, tab, false, state, updateActiveTab);
  }

  activeTabIs(tab: EPageDetailsTabs): boolean {
    return this.activeTab === tab;
  }

  private setActiveTab(path: EPageDetailsTabs, setByUser: boolean = false): void  {
    this.activeTab = path;
    this.setState({
      ...this.state,
      [this.IS_ACTIVE_TAB_SET_BY_USER_PROPERTY]: setByUser
    });
  }

  private setState(newState: object): void {
    const isActiveTabSetByUser = this.isActiveTabSetByUser(this.state, newState);
    this.state = {
      ...this.state,
      ...newState,
      [this.IS_ACTIVE_TAB_SET_BY_USER_PROPERTY]: isActiveTabSetByUser
    };
  }
  
  private isActiveTabSetByUser(state1: object, state2?: object): boolean {
    return state1 && state1[this.IS_ACTIVE_TAB_SET_BY_USER_PROPERTY] === true
      || state2 && state2[this.IS_ACTIVE_TAB_SET_BY_USER_PROPERTY] === true;
  }
}
