import { AngularNames, AuthenticationEvents, Names, ServerErrorCodes } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';

export const authenticationInterceptor = [
      AngularNames.rootScope,
      AngularNames.q,
      AngularNames.log,
      Names.NgServices.authenticationStorage,

      ($rootScope: angular.IRootScopeService,
       $q: angular.IQService,
       $log: angular.ILogService,
       authenticationStorageService: AuthenticationStorageService) => {
        return {
          responseError: (response: angular.IHttpPromiseCallbackArg<any>) => {
            if (response.status === 401) {
              $rootScope.$broadcast(AuthenticationEvents.notAuthenticated, response);
            } else if (response.status === 403) {
              $rootScope.$broadcast(AuthenticationEvents.notAuthorized, response);
              const errorCode = response.data && ((response.data.error && response.data.error.errorCode) || response.data.errorCode);
              if (errorCode === ServerErrorCodes.credentialsExpired){
                $rootScope.$broadcast(AuthenticationEvents.credentialsExpired);
              }
            } else if (response.status === 419) {
              $rootScope.$broadcast(AuthenticationEvents.sessionTimeout, response);
            } else if (response.status === 440) {
              $rootScope.$broadcast(AuthenticationEvents.sessionTimeout, response);
            }
            return $q.reject(response);
          },
          request: (config) => {
            config.headers = config.headers || {};

            var authorizationData = authenticationStorageService.get();
            if (authorizationData) {
              config.headers.Authorization = `Bearer ${authorizationData.token}`;
            }
            return config;
          }
        };
      }
    ];
