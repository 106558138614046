import { IWebElementSelector } from '../../shared/components/multi-selectors/multi-selectors.models';
import { actionWaitTime } from './../action-wait-time/action-wait-time.constants';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  IActionDetailsRaw,
  INavActionDetails,
  IInputActionDetails,
  IExecuteActionDetails,
  IWatchActionDetails,
  IMaskedInputActionDetailsRaw,
  IControlConfigMap,
  IMaskedInputActionDetails,
  IActionSetActionDetails,
  ICmpActionDetails
} from './action-details.models';
import { EActionCreatorMode } from '../actions-creator/actions-creator.enum';
import { IMaskedInput } from '../actions-preview/masked-input/masked-input.models';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { EActionTypeV3 } from '@app/components/web-journey/web-journey.models';
import { MultiSelectorsService } from '@app/components/shared/components/multi-selectors/multi-selectors.service';
import { EWebElementSelectorType, IParsedOuterHTML } from '@app/components/shared/components/multi-selectors/multi-selectors.models';
import { ECmpOption } from './action-details.constants';
import { JSMaxLengthSymbols } from '@app/components/actions/action-details/action-details.constants';

export const config: IControlConfigMap = {
  url: {
    show: type => type === EActionTypeV3.NavTo,
    validate: type => type === EActionTypeV3.NavTo,
    validators: [Validators.required, OPValidators.startingUrls]
  },
  value: {
    show: type => [EActionTypeV3.Input, EActionTypeV3.Select].includes(type),
    validate: type => type === EActionTypeV3.Input || type === EActionTypeV3.Select,
    validators: [Validators.required]
  },
  maskedValue: {
    show: type => type === EActionTypeV3.MaskedInput,
    validate: type => type === EActionTypeV3.MaskedInput,
    validators: [OPValidators.maskedInput]
  },
  js: {
    show: type => type === EActionTypeV3.Execute,
    validate: type => type === EActionTypeV3.Execute,
    validators: [Validators.required, Validators.maxLength(JSMaxLengthSymbols)]
  },
  seconds: {
    show: type => type === EActionTypeV3.Watch,
    validate: type => type === EActionTypeV3.Watch,
    validators: [Validators.required, Validators.min(0)]
  },
  selectors: {
    show: type =>
      ![
        EActionTypeV3.NavTo,
        EActionTypeV3.Execute,
        EActionTypeV3.Watch,
        EActionTypeV3.LeaveIFrame,
        EActionTypeV3.ActionSet,
        EActionTypeV3.ClearCookies,
        EActionTypeV3.CmpOptInOptOut
      ].includes(type)
  },
  filter: {
    show: (type, mode) => ![ EActionTypeV3.ActionSet ].includes(type) && (mode === EActionCreatorMode.AuditActions || mode === EActionCreatorMode.ActionSet),
    validate: (type, mode) => mode === EActionCreatorMode.AuditActions || mode === EActionCreatorMode.ActionSet,
    validators: [OPValidators.regex]
  },
  preventNavigation: {
    show: type =>
      ![
        EActionTypeV3.NavTo,
        EActionTypeV3.SwitchToIFrame,
        EActionTypeV3.LeaveIFrame,
        EActionTypeV3.ActionSet,
        EActionTypeV3.Watch,
        EActionTypeV3.ClearCookies,
        EActionTypeV3.CmpOptInOptOut
      ].includes(type)
  },
  waitDuration: {
    show: (type, mode) =>
      ![
        EActionTypeV3.Watch,
        EActionTypeV3.ActionSet,
        EActionTypeV3.ClearCookies,
        EActionTypeV3.CmpOptInOptOut
      ].includes(type),
    validate: (type, mode) => type !== EActionTypeV3.Watch,
    validators: [Validators.min(actionWaitTime.min), Validators.max(actionWaitTime.max)]
  },
  actionSet: {
    show: type => type === EActionTypeV3.ActionSet,
    validate: type => type === EActionTypeV3.ActionSet,
    validators: [Validators.required]
  },

  // this block ensures the action card appears when action is selected
  cmp: {
    show: type => type === EActionTypeV3.CmpOptInOptOut,
    validate: type => type === EActionTypeV3.CmpOptInOptOut,
    validators: [Validators.required]
  },

  // this adds validation for the cmp type
  cmpType: {
    show: type => type === EActionTypeV3.CmpOptInOptOut,
    validate: type => type === EActionTypeV3.CmpOptInOptOut,
    validators: [Validators.required]
  },

  // this adds validation for the cmp url
  cmpUrl: {
    show: type => type === EActionTypeV3.CmpOptInOptOut,
    validate: type => type === EActionTypeV3.CmpOptInOptOut,
    validators: [Validators.required, OPValidators.url]
  }
};

@Injectable()
export class ActionDetailsFormBuilder {

  constructor(private multiSelectorsService: MultiSelectorsService) {}

  buildForm(action: IActionDetailsRaw, mode: EActionCreatorMode): UntypedFormGroup {
    const emptyForm = this.buildDefaultForm(mode);
    const filledForm = this.setValues(emptyForm, action);
    const filledFormWithValidation = this.updateValidation(filledForm, mode);
    return this.updateDisabling(filledFormWithValidation, mode);
  }

  buildDefaultForm(mode: EActionCreatorMode): UntypedFormGroup {
    const basicForm = {
      id: new UntypedFormControl(''),
      type: new UntypedFormControl(''),
      label: new UntypedFormControl(''),
      rules: new UntypedFormControl([]),
      url: new UntypedFormControl(''),
      value: new UntypedFormControl(''),
      maskedValue: new UntypedFormControl({}),
      js: new UntypedFormControl(''),
      seconds: new UntypedFormControl(''),
      selectors: new UntypedFormArray([]),
      filter: new UntypedFormControl(''),
      matchAllPages: new UntypedFormControl(false),
      preventNavigation: new UntypedFormControl(false),
      actionId: new UntypedFormControl(''),
      actionSet: new UntypedFormControl(''),
      waitDuration: new UntypedFormControl(''),
      cmpType: new UntypedFormControl(ECmpOption.OPT_OUT),
      cmpUrl: new UntypedFormControl(''),
      isRequired: new UntypedFormControl(true)
    };

    return new UntypedFormGroup(basicForm);
  }

  private setValues(form: UntypedFormGroup, action: IActionDetailsRaw): UntypedFormGroup {
    const filledAction: any = {
      type: action.type,
      label: action.label,
      matchAllPages: action.filter === '.*',
      preventNavigation: action.preventNavigation,
      actionId: action.actionId
    };

    if(action.id) filledAction.id = action.id;

    if (action.filter) filledAction.filter = action.filter;
    if (action.selectors) form.setControl('selectors', this.processSelectors(action.selectors));

    if (action.hasOwnProperty('waitDuration')) filledAction.waitDuration = action.waitDuration;

    if (action.rules) filledAction.rules = action.rules;

    switch (action.type) {
      case EActionTypeV3.NavTo:
          filledAction.url = (action as INavActionDetails).url;
        break;
      case EActionTypeV3.Input:
      case EActionTypeV3.Select:
          filledAction.value = (action as IInputActionDetails).value;
        break;
      case EActionTypeV3.MaskedInput:
        filledAction.maskedValue = this.processMaskedInput(action);
        break;
      case EActionTypeV3.Execute:
        filledAction.js = (action as IExecuteActionDetails).js;
        break;
      case EActionTypeV3.Watch:
        filledAction.seconds = (action as IWatchActionDetails).seconds;
        break;
      case EActionTypeV3.ActionSet:
        filledAction.actionSet = (action as IActionSetActionDetails).actionSet;
        break;
      case EActionTypeV3.CmpOptInOptOut:
        filledAction.cmpType = (action as ICmpActionDetails).cmpType;
        filledAction.cmpUrl = (action as ICmpActionDetails).cmpUrl;
        filledAction.isRequired = (action as ICmpActionDetails).isRequired;
        break;
    }
    form.patchValue(filledAction);
    return form;
  }

  private processSelectors(selectors: IWebElementSelector[]): UntypedFormArray {
    return new UntypedFormArray(
      selectors.map(selector => this.buildSelectorGroup(selector))
    );
  }

  buildSelectorGroup(selector: IWebElementSelector): UntypedFormGroup {
    const value = selector.selectorType === EWebElementSelectorType.HtmlAttrs && typeof selector.value !== 'string' ?
      this.multiSelectorsService.buildOuterHTML(selector.value as IParsedOuterHTML) :
      selector.value;
    const typeControl = new UntypedFormControl(selector.selectorType, Validators.required);
    return new UntypedFormGroup({
      selectorType: typeControl,
      value: new UntypedFormControl(value, valueControl => this.valueValidator.call(this, valueControl, typeControl)),
    });
  }

  private valueValidator(valueControl: AbstractControl, typeControl: AbstractControl): ValidationErrors | null {
    return typeControl.value === EWebElementSelectorType.HtmlAttrs ?
      this.htmlAttrsValidator(valueControl) :
      Validators.required(valueControl);
  }

  private htmlAttrsValidator(control: AbstractControl): ValidationErrors | null {
    const val = this.multiSelectorsService.parseAttributes(control.value);
    return (val && (val.attributes && val.attributes.length > 0 || !!val.tagName)) ?
      null : { invalidHtmlAttrs: 'invalidHtmlAttrs' };
  }

  private processMaskedInput(action: IMaskedInputActionDetailsRaw | IMaskedInputActionDetails): IMaskedInput {
    let actionDetails: IMaskedInput | IMaskedInputActionDetailsRaw;

    if (typeof action.maskedValue === 'object') {
      actionDetails = (<IMaskedInputActionDetails>action).maskedValue;
    } else {
      actionDetails = <IMaskedInputActionDetailsRaw>action;
    }

    return {
      maskedValue: actionDetails.maskedValue,
      viewedValue: actionDetails.viewedValue,
      isChangedValue: actionDetails.isChangedValue,
      maskedInputType: actionDetails.maskedInputType
    };
  }

  updateValidation(formGroup: UntypedFormGroup, mode: EActionCreatorMode): UntypedFormGroup {
    for (let key in formGroup.controls) {
      if (['type', 'selectors'].includes(key)) continue;
      if (!config[key] || !config[key].validate) continue;

      if (config[key].validate(formGroup.get('type').value, mode)) {
        const validators = typeof config[key].validators === 'function'
          ? (<any>config[key].validators)(mode)
          : config[key].validators;
        formGroup.get(key).setValidators(validators);
      } else {
        formGroup.get(key).clearValidators();
      }
      formGroup.get(key).updateValueAndValidity({ emitEvent: false });
    }

    return formGroup;
  }

  updateDisabling(formGroup: UntypedFormGroup, mode: EActionCreatorMode): UntypedFormGroup {
    for (let key in formGroup.controls) {
      if (key === 'type') continue;
      if (!config[key] || !config[key].show) continue;
      const type = formGroup.get('type').value;
      if (config[key].show(type, mode)) formGroup.get(key).enable({ emitEvent: false });
      else formGroup.get(key).disable({ emitEvent: false });
    }

    return formGroup;
  }

}
