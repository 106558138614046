import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';

export interface ICheckRequestResponse {
  tag: ICheckRequestTag;
  matchedSignatures: Array<string>;
}

interface ICheckRequestTag {
  name: string;
  icon: string;
  id: number;
  category: ICheckRequestTagCategory,
  duplicateScoring: boolean;
}

interface ICheckRequestTagCategory {
  id: number;
  name: string;
}

@Injectable()
export class RequestCheckService {
  constructor(private apiService: ApiService) {}

  root: string = environment.apiUrl;

  checkRequest(requestUrl: string): Promise<ICheckRequestResponse[]> {
    const params = {
      params: {
        request_url: encodeURIComponent(requestUrl)
      }
    };

    return this.apiService.get<ICheckRequestResponse[]>(`${this.root}tags`, params).toPromise<ICheckRequestResponse[]>();
  }
}
