import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpStandardsSelectorComponent } from './op-standards-selector.component';
import { OpStandardsSelectorService } from './op-standards-selector.service';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OpStandardsSelectorRowComponent } from './op-standards-selector-row/op-standards-selector-row.component';
import { OpLabelManagerModule } from '../op-label-manager/op-label-manager.module';
import { FormsModule } from '@angular/forms';
import { AlertService } from '@app/components/alert/alert.service';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { SelectableTableFilterBarService } from '../selectable-table/selectable-table-filter-bar/selectable-table-filter-bar.service';
import { MatMenuModule } from '@angular/material/menu';
import { UiScrollModule } from 'ngx-ui-scroll';

@NgModule({
  declarations: [
    OpStandardsSelectorComponent,
    OpStandardsSelectorRowComponent
  ],
    imports: [
      CommonModule,
      FormsModule,
      MatIconModule,
      MatProgressSpinnerModule,
      OpLabelManagerModule,
      MatMenuModule,
      UiScrollModule,
    ],
  exports: [
    OpStandardsSelectorComponent,
    OpStandardsSelectorRowComponent
  ],
  providers: [
    OpStandardsSelectorService,
    AlertService,
    AlertReportingService,
    UiTagService,
    SelectableTableFilterBarService
  ],
})
export class OpStandardsSelectorModule {}
