<div id="manage-nav-cards"
     class="flex-col flex-stretch"
     op-selector="cards-view-container">
  <div class="filter-toggle">
    <div class="op-filter-bar-v2-container left-side">
      <div class="page-title">Data Sources <mat-icon [attr.op-selector]="'data-sources-help-link'">help_outline</mat-icon></div>
      <op-manage-cards-filter-bar [domains]="domains"></op-manage-cards-filter-bar>
    </div>
    <div class="page-view-toggle right-side">
      <div class="list-options">
        <mat-button-toggle-group
          [value]="manageCardsDataService.reportCardViewMode"
          [hideSingleSelectionIndicator]="true"
          (change)="setViewMode($event)">
          <mat-button-toggle value="card">
            <mat-icon>grid_view</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="list">
            <mat-icon>list</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="calendar" *ngIf="calendarViewEnabled">
            <mat-icon>calendar_month</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
        <button *ngIf="!isReadOnly" class="create-new-data-src-btn" [matMenuTriggerFor]="createNewDataSrcMenu">
          <mat-icon>add</mat-icon>
        </button>
        <mat-menu #createNewDataSrcMenu="matMenu" class="create-new-data-src-menu">
          <button mat-menu-item class="create-new-data-src-menu-item" (click)="createWebAudit()">
            <mat-icon>explore</mat-icon>
            Create New Audit
          </button>
          <button mat-menu-item class="create-new-data-src-menu-item" (click)="createWebJourney()" *ngIf="isWebJourneyAllowed">
            <mat-icon>map</mat-icon>
            Create New Journey
          </button>
          <button mat-menu-item class="create-new-data-src-menu-item" (click)="openCreateNewFolderModal()">
            <mat-icon>create_new_folder</mat-icon>
            Create New Folder
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="bulk-action-sort-bar"
       *ngIf="!calendarViewEnabled || viewMode !== EManageCardsViewMode.Calendar"
  >
    <bulk-action-bar [totalCards]="totalCardsCount"
                     [filteredCards]="filteredCardsCount"
                     [domains]="domains"
                     [user]="user"
                     [allCardsExpanded]="allCardsExpanded"
                     [selectedCards]="selectedCards"
                     [selectedCardsCount]="selectedCardsCount"
                     [groupBy]="activeGroupByOption"
                     [isReadOnly]="isReadOnly"
                     [runRemoteMethods]="runMethodOnServices"
                     (toggleCardGroups)="toggleAllCardsGroups()"
                     (selectAllCards)="selectAll()"
                     (assignRules)="assignRules()"
                     (addBulkLabels)="addBulkLabels($event)"
                     (bulkDelete)="bulkDeleteConfirm()"
                     (updateDomains)="updateDomains()">
    </bulk-action-bar>
    <sort-and-group [selectedCardsCount]="selectedCardsCount"
                    [sortDirection]="sortDirection"
                    [sortBy]="activeSortByOption"
                    [groupBy]="activeGroupByOption"
                    (sortChanged)="setSort($event)"
                    (groupByChanged)="updateGroup($event)">
    </sort-and-group>
  </div>

  <home-card-list class="flex-stretch scroll-vertically report-card-list-component"
                  *ngIf="viewMode !== EManageCardsViewMode.Calendar"
                  type="all"
                  [class.nested-view]="activeGroupByOption === 'foldersAndDomains'"
                  [class.single-grouped-view]="activeGroupByOption === 'folders' || activeGroupByOption === 'domains'"
                  [(api)]="cardListApi"
                  [(filter)]="folderSearch"
                  (onCardsChanged)="cardsChanged()"
                  [(sort)]="cardSort"
                  [(groupBy)]="activeGroupByOption"
                  [(scrollTo)]="scrollTo"
                  [(allCardsExpanded)]="allCardsExpanded"
                  (updateDomains)="updateDomains()"
                  (updateScrollPosition)="setScrollToResource()"
                  (runMethodInManageCardsServices)="runMethodInManageCardsServices($event)"
                  (createAudit)="createWebAudit()"
                  (createJourney)="createWebJourney()"
                  [domains]="domains">
  </home-card-list>
  <calendar-view *ngIf="viewMode === EManageCardsViewMode.Calendar">
  </calendar-view>
</div>
