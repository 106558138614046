<div class="filters" [class.disabled]="!metricType" *ngIf="!noFilters">
  <div class="title">
    <b>FILTER PAGES</b>
    (Not Required)
  </div>
  <div class="subtitle">
    <span>Which report filter(s) need to be present for this rule to be evaluated. Only certain filters are available per report metric. </span>
    <span class="description">(For example, a regular expression can be used to restrict this rule to only product pages or only apply this rule if a specific page status code is present.)</span>
  </div>

  <op-filter-bar [class.disabled]="!metricType"
                 [menuItems]="filterBarMenuItems"
                 [service]="filterBarService"
                 [isSearchByTextEnabled]="pageUrlInputEnabled"
                 [validFilterTypes]="validFilterTypes"
                 [collapsablePills]="false"
                 [isReadOnly]="modalType === EAlertModalType.ReadOnly"
                 [readOnlyLabel]="readOnlyLabel"
                 searchByTextPlaceholderSuffix="URL"
                 (searchByTextIsEntered)="handleSearchByUrl($event)"
                 (invertableFilterToggled)="handleInvertableFilterToggled($event)">
    <mat-checkbox [checked]="searchInInitialURLs"
                  color="primary"
                  (click)="onClickUrlOptions($event, 'searchInInitialURLs')"
                  (focusin)="filterBar.searchByTextFocused()"
                  (focusout)="filterBar.searchByTextBlurred()"
                  (mousedown)="filterBar.searchByTextMouseDown()"
                  tabindex="2"
    >Initial URL</mat-checkbox>
    <mat-checkbox [checked]="searchInFinalURLs"
                  color="primary"
                  (click)="onClickUrlOptions($event, 'searchInFinalURLs')"
                  (focusin)="filterBar.searchByTextFocused()"
                  (focusout)="filterBar.searchByTextBlurred()"
                  (mousedown)="filterBar.searchByTextMouseDown()"
                  tabindex="2"
    >Final URL</mat-checkbox>
    <div class="and-operator">
      <span>AND</span>
    </div>
    <hr>
  </op-filter-bar>
</div>
