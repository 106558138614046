import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
  IAuditReportExportMenuData
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import {
  CommonReportsPagesFiltersConfig,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import { EOriginType } from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.models';

export interface ICookiesPrivacyComplianceTableState {
  sort: ICookiesComplianceSort<ICookiesComplianceSortColumn>
  pagination: ICookiePrivacyCompliancePagination
  pagesTotal: number
}

export type ICookiesComplianceSortColumn = 'cookie_name' | 'cookie_domain' | 'compliance_status' | 'consent_category_name' | 'page_count';

export interface ICookiePrivacySummary {
  totalPageCount: number;
  filteredPageCount: number;
  totalUniqueCookieCount: number;
  filteredUniqueCookieCount: number;
  filteredApprovedCookieCount: number;
  filteredUnapprovedCookieCount: number;
  filteredPageWithUnapprovedCookieCount: number;
  totalApprovedCookieCount: number;
  totalUnapprovedCookieCount: number;
}

export interface ICookiePrivacyComplianceItem {
  cookieName: string;
  cookieDomain: string;
  consentCategoryComplianceStatus: string;
  consentCategoryName: string;
  consentCategorySnapshotId: number;
  consentCategoryId: number;
  filteredPageCount: number;
  cookieChangedDuring: EOriginType[];
}

export interface ICookiePrivacyComplianceResponse {
  cookies: ICookiePrivacyComplianceItem[];
  metadata: any;
}

export interface ICookiesPrivacyRunOverviews {
  runs: ICookiePrivacyRunOverview[];
}

export interface ICookiePrivacyRunOverview {
  runId: number;
  completedAt: string;
  totalUniqueCookieCount: number;
  totalApprovedCookieCount: number;
  totalUnapprovedCookieCount: number;
}

export interface ICookiePrivacyTrends {
  runs: ICookiePrivacyTrend[];
}

export interface ICookiePrivacyTrend {
  runId: number;
  completedAt: string;
  trendValue: number;
}

export interface ICookiePrivacySpecificFilters {
  cookieName?: string;
  cookieDomain?: string;
}

export interface ICookiePrivacyPagesTableState {
  sort: ICookiesComplianceSort<CommonReportsPagesTableColumns>
  pagination: ICookiePrivacyCompliancePagination
  pagesTotal: number
}

export type ICookiePrivacyPagesSortColumn = 'page_url'
  | 'page_load_time'
  | 'page_status_code'
  | 'cookie_count'
  | 'total_cookie_size';

export interface ICookiePrivacyCompliancePagination {
  page: number;
  size: number;
}

export interface ICookiesComplianceSort<SortColumn> {
  sortBy: SortColumn;
  sortDesc: boolean;
}

export interface ICookiePrivacyPagesResponse {
  pages: ICookiePrivacyPagesTableRow[];
  metadata: {
    pagination: {
      totalCount: number,
      totalPageCount: number,
      pageSize: number,
      currentPageSize: number,
      currentPageNumber: number
    }
  };
}

export interface ICookiePrivacyPagesTableRow {
  pageId: string,
  pageUrl: string,
  pageLoadTime: number,
  pageStatusCode: number,
  finalPageStatusCode: number,
  cookieCount: number,
}

export const CookiesPrivacyRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,
  EAuditReportFilterTypes.CookieName,
  EAuditReportFilterTypes.CookieDomain,
  EAuditReportFilterTypes.ConsentCategoryComplianceStatus,
  EAuditReportFilterTypes.ConsentCategoryId,
  EAuditReportFilterTypes.CookieOriginInclusion,
];

export type CookiesPrivacyCookieComplianceExportMenuData = IAuditReportExportMenuData<{}>;
export type CookiesPrivacyPagesExportMenuData = IAuditReportExportMenuData<ICookiePrivacySpecificFilters>;
