import { Injectable } from '@angular/core';
import { IWJResultsVariable } from './wj-results-tag-report-table.models';
import { BehaviorSubject } from 'rxjs';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';

@Injectable()
export class WjResultsTagReportTableService {

  private sortByPropSubject = new BehaviorSubject<{propName: keyof IWJResultsVariable, direction: ESortDirection}>({
    propName: 'name', 
    direction: ESortDirection.asc
  });
  sortByProp$ = this.sortByPropSubject.asObservable();

  sortBy(propName: keyof IWJResultsVariable): void {
    const prevSortBy = this.sortByPropSubject.getValue();

    const direction = prevSortBy?.propName === propName ?
      prevSortBy.direction === ESortDirection.asc ? ESortDirection.desc : ESortDirection.asc :
      ESortDirection.asc;

    this.sortByPropSubject.next({propName, direction});
  }

}
