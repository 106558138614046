import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({name: 'highlightTextPipe'})
export class HighlightTextPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, phrase: string): SafeResourceUrl {
    const highlightedText = phrase ? text.replace(new RegExp('(' + phrase + ')', 'gi'), '<span class="highlighted">$1</span>') : text;
    return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
  }
}
