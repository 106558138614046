<div class="rule-summary-boxes">
  <div class="rule-summary-box">
    <div class="summary-label">Rules Assigned</div>
    <div class="summary-value">
      {{ (failedRuleReports?.length ?? 0) + (notAppliedRuleReports?.length ?? 0) + (passedRuleReports?.length ?? 0) }}
    </div>
  </div>
  <div class="rule-summary-box">
    <div class="summary-label">Failed Rules</div>
    <div class="summary-value failed">{{ failedRuleReports?.length }}</div>
  </div>
  <div class="rule-summary-box">
    <div class="summary-label">Rules Passing</div>
    <div class="summary-value passed">{{ passedRuleReports?.length }}</div>
  </div>
  <div class="rule-summary-box">
    <div class="summary-label">Rules Not Applied</div>
    <div class="summary-value">{{ notAppliedRuleReports?.length }}</div>
  </div>
</div>
<!-- Failed -->
<mat-expansion-panel class="mat-elevation-z0" displayMode="flat" hideToggle="true"
  op-selector="failed-rules-expansion-panel">
  <mat-expansion-panel-header expandedHeight="50px" collapsedHeight="50px">
    <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
    <mat-icon class="icon rule-icon-error">error</mat-icon>
    <span>FAILED RULES ({{failedRuleReports?.length || 0}})</span>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <failed-rules-report *ngIf="failedRuleReports && failedRuleReports.length > 0" [showEditButtons]="showEditButtons"
      [reports]="failedRuleReports" class="report-body" (removeRule)="removeRule.emit($event)"
      (editRule)="editRule.emit($event)" (setAsExpected)="setAsExpected.emit($event)" (deleteVariable)="deleteVariable.emit($event)"></failed-rules-report>
    <div *ngIf="!failedRuleReports || failedRuleReports.length === 0">
      There were no rules that failed
    </div>
  </ng-template>
</mat-expansion-panel>

<!-- Not Applied -->
<mat-expansion-panel class="mat-elevation-z0" displayMode="flat" hideToggle="true"
  op-selector="not-applied-rules-expansion-panel">
  <mat-expansion-panel-header expandedHeight="50px" collapsedHeight="50px">
    <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
    <mat-icon class="icon rule-icon-block">block</mat-icon>
    <span>RULES NOT APPLIED ({{notAppliedRuleReports?.length || 0}})</span>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <not-applied-rules-report *ngIf="notAppliedRuleReports && notAppliedRuleReports.length > 0"
      [reports]="notAppliedRuleReports" class="report-body" [showEditButtons]="showEditButtons"
      (removeRule)="removeRule.emit($event)" (editRule)="editRule.emit($event)"></not-applied-rules-report>
    <div *ngIf="!notAppliedRuleReports || notAppliedRuleReports.length === 0">
      There were no rules that did not apply
    </div>
  </ng-template>
</mat-expansion-panel>

<!-- Passed -->
<mat-expansion-panel class="mat-elevation-z0" displayMode="flat" hideToggle="true"
  op-selector="passed-rules-expansion-panel">
  <mat-expansion-panel-header expandedHeight="50px" collapsedHeight="50px">
    <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
    <mat-icon class="icon rule-icon-check_circle">check_circle</mat-icon>
    <span>PASSED RULES ({{passedRuleReports?.length || 0}})</span>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <passed-rules-report *ngIf="passedRuleReports && passedRuleReports.length > 0" [reports]="passedRuleReports"
      class="report-body" [showEditButtons]="showEditButtons" 
      (removeRule)="removeRule.emit($event)" (editRule)="editRule.emit($event)"></passed-rules-report>
    <div *ngIf="!passedRuleReports || passedRuleReports.length === 0">
      There were no rules that passed
    </div>
  </ng-template>
</mat-expansion-panel>
