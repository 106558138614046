import { MaterialModule } from '@app/components/material/material.module';
import { NgModule } from '@angular/core';
import {
  OpModalComponent,
  OpModalHeaderComponent,
  OpModalFooterComponent,
  OpModalService,
  OpCurtainModalComponent,
  OpConfirmModalComponent,
  OpDeleteModalComponent,
  OpSuccessModalComponent
} from './index';
import { OpCurtainModalHeaderComponent } from './op-curtain-modal-header/op-curtain-modal-header.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { OpCloseBtnModule } from '../op-close-btn/op-close-btn.nodule';
import { OpModalFooterButtonsComponent } from './op-modal-footer/op-modal-footer-buttons/op-modal-footer-buttons.component';
import { OpModalHeaderBaseComponent } from './op-modal-header-base/op-modal-header-base.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    OpCloseBtnModule,
  ],
  exports: [
    OpCloseBtnModule,
    OpModalComponent,
    OpModalHeaderComponent,
    OpModalHeaderBaseComponent,
    OpModalFooterComponent,
    OpModalFooterButtonsComponent,
    OpCurtainModalHeaderComponent,
    OpCurtainModalComponent,
    OpConfirmModalComponent,
    OpDeleteModalComponent,
    OpSuccessModalComponent,
  ],
  declarations: [
    OpModalComponent,
    OpModalHeaderComponent,
    OpModalHeaderBaseComponent,
    OpModalFooterComponent,
    OpModalFooterButtonsComponent,
    OpCurtainModalHeaderComponent,
    OpCurtainModalComponent,
    OpConfirmModalComponent,
    OpDeleteModalComponent,
    OpSuccessModalComponent,
  ],
  providers: [
    OpModalService,
  ],
})
export class OpModalModule { }
