import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';
import {
  CookiesListTableColumns
} from '@app/components/audit-reports/reports/privacy-cookies/components/privacy-cookies-compliance-table/privacy-cookies-compliance-table.enums';

export const PrivacyCookiesConfigLocalStorageKey = 'privacy-cookies-config';

export const CookiesTableColumns: ISwitchableMenuItems = {
  [CookiesListTableColumns.CookieName]: {
    title: 'Cookie Name',
    checked: true,
    pinned: true,
  },
  [CookiesListTableColumns.CookieDomain]: {
    title: 'Cookie Domain',
    checked: true,
    pinned: true,
  },
  [CookiesListTableColumns.ConsentCategoryComplianceStatus]: {
    title: 'Status',
    checked: true,
  },
  [CookiesListTableColumns.ConsentCategoryName]: {
    title: 'Consent Category',
    checked: true,
  },
  [CookiesListTableColumns.Origin]: {
    title: 'Origin',
    checked: true,
  },
  [CookiesListTableColumns.FilteredPageCount]: {
    title: '# OF PAGES',
    checked: true,
  },
};
