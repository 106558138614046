import { Component, Input, ViewChild, Output, EventEmitter, forwardRef, HostBinding } from '@angular/core';
import { InputMode } from './op-select-create.enum';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OpSelectComponent } from '../op-select/op-select.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@Component({
  selector: 'op-select-create',
  templateUrl: './op-select-create.component.html',
  styleUrls: ['./op-select-create.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => OpSelectCreateComponent) 
  }]
})
export class OpSelectCreateComponent implements ControlValueAccessor {

  @Input() label: string;
  @Input() createLabel?: string;
  @Input() createPlaceholder?: string;
  @Input() data: any[];
  @Input() bindValue?: string;
  @Input() bindLabel?: string;
  @Input() appearance?: MatFormFieldAppearance = 'fill';
  @Input() itemName: string;
  @Input() subItemName: string;
  @Input() prefix: string = '';
  @Input() createText: string = 'Create';
  @Input() cancelText: string = 'Choose Value';
  @Input() formControlPath: string;  // used for validation messages
  @Input() hideSwitch: boolean = false;
  @Input() appendTo?: string;

  @Output() onSwitchMode: EventEmitter<InputMode> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();

  @ViewChild('opSelect') opSelectPlaceholder: OpSelectComponent;

  @HostBinding('class.disabled') isDisabled = false;

  propogateChanges: (newValue: any) => void;
  onTouchedCallback: () => void;

  inputModes = InputMode;
  mode: InputMode = InputMode.Select;
  modelValue: string;

  onChange(newValue: any): void {
    const val = this.bindValue && newValue && newValue[this.bindValue] ? newValue[this.bindValue] : newValue;
    if (this.propogateChanges) {
      this.propogateChanges(val);
    }
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
  }

  onSelectChange(newValue: any): void {
    this.onSelect.next(newValue);
    this.onChange(newValue);
  }

  writeValue(newValue: any): void {
    this.modelValue = newValue;
    this.onChange(newValue);
  }

  registerOnChange(fn: any): void {
    this.propogateChanges = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  switchMode() {
    this.modelValue = null;
    this.onChange(this.modelValue);

    this.mode = this.mode === InputMode.Create 
      ? InputMode.Select
      : InputMode.Create;

    this.onSwitchMode.next(this.mode);
  }

  clear() {
    if (this.opSelectPlaceholder) {
      this.opSelectPlaceholder.clear();
    }
    this.modelValue = null;
    this.onChange(this.modelValue);
  }
  
  onFocus() {
    this.onTouchedCallback();
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

}
