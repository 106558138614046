import { RouteReloadService } from './../../shared/services/route-reload.service';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { OpModalService } from './../../shared/components/op-modal/shared/op-modal.service';
import { ModalEscapeService } from './../../ui/modalEscape/modalEscapeService';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { RulesService } from '@app/components/rules/rules.service';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { INotAppliedRuleReport, IPassedRuleReport } from '@app/components/reporting/rules/rule.models';
import { forkJoin, of, Subject } from 'rxjs';
import { IWebJourneyResults } from '../../domains/webJourneys/webJourneyDefinitions';
import { EasyRuleEditService } from '../../shared-services/easy-rule-edit-service';
import { IFailedRuleReport, IRuleReportItem } from '../../reporting/rules/rule.models';
import { IStatusBannerSettings, StatusBannerService } from '../../reporting/statusBanner/statusBannerService';
import { userIsGuest } from '@app/authUtils';
import { WebJourneyReportService } from '../web-journey-report.service';
import { AccountsService } from '@app/components/account/account.service';
import { IRuleV3WebJourneyRules } from '@app/components/rules/rules.models';
import { WebJourneyEditorComponent } from '@app/components/web-journey/web-journey-editor/web-journey-editor.component';
import { IWebJourneyV3 } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { IWebJourneyEditorModalData } from '@app/components/web-journey/web-journey-editor/web-journey-editor.models';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'web-journey-global-rules-report',
  templateUrl: './web-journey-global-rules-report.component.html',
  styleUrls: ['./web-journey-global-rules-report.component.scss']
})
export class WebJourneyGlobalRulesReportComponent implements OnInit, OnDestroy {

  @HostListener('document:keydown.escape')
  keyDown(): void {
    if (!this.modalEscapeService.getLast()) {
      this.webJourneyReportService.closeReport(this.journeyId);
    }
  }

  journeyId: number;
  runId: number;
  journeyResults: IWebJourneyResults;
  journeyRules: IRuleV3WebJourneyRules;
  canEditRules: boolean;
  failedRules: IFailedRuleReport[] = [];
  notAppliedRules: INotAppliedRuleReport[] = [];
  passedRules: IPassedRuleReport[] = [];
  showFullScreenStatusBanner: boolean = false;
  isReadOnly = true;
  runNowPending: boolean = false;
  showMainSpinner: boolean = false;

  destroySubject = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routeReload: RouteReloadService,
    private modalEscapeService: ModalEscapeService,
    private modalService: OpModalService,
    private webJourneyReportService: WebJourneyReportService,
    private webJourneyV3Service: WebJourneyV3Service,
    private easyRuleEditService: EasyRuleEditService,
    private statusBannerService: StatusBannerService,
    private accountsService: AccountsService,
    private rulesService: RulesService,
  ) { }

  ngOnInit() {
    this.showMainSpinner = true;

    this.routeReload.reloadRouteEvents$
      .pipe(
        startWith(null),
        switchMap(v => this.route.params),
        takeUntil(this.destroySubject)
      ).subscribe(params => {
        this.journeyId = +params.journeyId;
        this.runId = +params.runId;

        this.checkFullScreenBannerState();
        this.init();
      });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private init() {
    forkJoin([
      this.webJourneyReportService.getJourneyRules(this.journeyId),
      this.runId === 0 ? of(undefined) : this.webJourneyReportService.getJourneyResult(this.journeyId, this.runId),
      this.accountsService.getUser()
    ]).subscribe(([rules, results, user]) => {
      this.journeyRules = rules;
      this.journeyResults = results;
      if (results) {
        this.prepareRuleDetails(results);
        this.determineRuleEditOption();
      }
      this.isReadOnly = userIsGuest(user);
    });
  }

  private prepareRuleDetails(results: IWebJourneyResults): void {
    const snapshotIds = results.globalRuleResults.flatMap(
      result => result.tagResults.flatMap(
        tagResult => tagResult.variables.map(
          varResult => varResult.snapshotId
        )
      )
    );

    const uniqueSnapshotIds = Array.from(new Set(snapshotIds));

    this.rulesService.getOriginalRulesByConditionIds(uniqueSnapshotIds).subscribe(conditionSnapshot => {
      const conditionIdToDescription = ArrayUtils.toMap(conditionSnapshot, 'snapshotId');

      // mutation happens here
      results.failedRuleReports?.forEach(
        report => report.failedTags.forEach(
          tag => tag.items.forEach(
            item => item.validationDescription = conditionIdToDescription.get(item.snapshotId)?.validationDescription
          )
        )
      );

      this.failedRules = results.failedRuleReports;
      this.notAppliedRules = results.notAppliedRuleReports;
      this.passedRules = results.passedRuleReports;
    });
  }

  onEditRule(ruleId: number): void {
    this.easyRuleEditService.editRule(ruleId);
  }

  onRemoveRule(ruleId: number): void {
    this.easyRuleEditService.removeWebJourneyRule(this.journeyId, ruleId);
  }

  onSetAsExpected(ruleReportItem: IRuleReportItem): void {
    this.easyRuleEditService.setAsExpected(ruleReportItem);
  }

  assignRules() {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    const data: IWebJourneyEditorModalData = {
      step: 2,
      journeyId: this.journeyId
    };
    this.modalService.openFixedSizeModal(WebJourneyEditorComponent, { disableClose: true, data }, 'op-webjourney-editor')
      .afterClosed()
      .subscribe((webJourney?: IWebJourneyV3) => {
        this.modalEscapeService.remove(index);
        if (webJourney) this.router.navigateByUrl(this.router.url);
      });
  }

  runNow(): void {
    if (this.runNowPending) {
      return;
    }
    this.runNowPending = true;
    this.webJourneyV3Service.runWebJourneyNow(this.journeyId).subscribe(() => {
      this.runNowPending = false;
      this.router.navigateByUrl(this.router.url);
    }, (error) => this.webJourneyV3Service.handleWebJourneyRunError(error));
  }

  private determineRuleEditOption(): void {
    let journeyDate = this.journeyResults.actions[0].timestamp;
    let cutoffDate = new Date('2019-10-18').getTime();

    this.canEditRules = journeyDate > cutoffDate;
  }

  private checkFullScreenBannerState(): void {
    this.showMainSpinner = true;
    this.showFullScreenStatusBanner = false;

    this.statusBannerService.getSettingsForWebJourney(this.journeyId).then(
      (settings: IStatusBannerSettings) => {
        this.showMainSpinner = false;
        this.showFullScreenStatusBanner = settings.notRunYet || settings.firstRun;
      },
      () => {
        this.showMainSpinner = false;
        this.showFullScreenStatusBanner = false;
      }
    );
  }
}
