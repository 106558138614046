<div [matMenuTriggerFor]="menu"
     class="filter">
  <div class="filter-label">{{ label }}</div>
  <mat-icon>keyboard_arrow_down</mat-icon>
</div>

<mat-menu #menu="matMenu"
          backdropClass="no-filter"
          [formGroup]="formControl">
  <div class="options-menu"
       formArrayName="checkboxes"
  >
    <h5 class="options-menu-title">{{ filter.menuTitle }}</h5>

    <mat-checkbox *ngFor="let option of filter.menuOptions; let i = index;"
                  (click)="$event.stopPropagation()"
                  [formControl]="controlAt(i)"
                  color="primary"
                  class="options-menu-item"
                  [checked]="option.checked"
    >
      {{ option.label }}
    </mat-checkbox>
  </div>
</mat-menu>
