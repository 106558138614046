<div class="api-key">
  <p class="api-key-heading">API Key</p>
  <div class="api-key-container">
    <code>{{ key?.accessToken || 'No API key available. Use the button below to generate one!' }}</code>
  </div>
  <div class="api-key-buttons">
    <button mat-flat-button
            color="primary"
            class="generate-key"
            (click)="generateKey()">
      {{ buttonLabel }}
    </button>
    <button mat-flat-button
            *ngIf="key"
            color="primary"
            class="copy-to-clipboard"
            ngxClipboard
            [cbContent]="key.accessToken"
            (click)="showCopyMessage()">
      <mat-icon class="copy-icon">assignment_turned_in</mat-icon>
      Copy to Clipboard
    </button>
    <div class="copied-message" *ngIf="showCopiedMessage">Copied!</div>
  </div>
  <div class="api-docs-links">
    <a class="api-docs-link" href="https://help.observepoint.com/article/492-observepoint-api" target="_blank">
      View API Documentation
    </a>
  </div>
</div>