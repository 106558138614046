import { EActionTypeV3 } from '@app/components/web-journey/web-journey.models';
import { EWebElementSelectorTypeV3 } from '@app/components/shared/components/multi-selectors/multi-selectors.models';
import { IAuditModel } from '@app/components/modals/modalData';
import { IWebJourneyV3 } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { IUser } from '@app/moonbeamModels';

export interface IActionSet {
  id?: number;
  name: string;
  actions: IActionSetAction[];
}

export interface IActionSetCreationRequest {
  name: string;
  actions: IActionSetAction[];
}

export interface IActionSetAction {
  id?: number;
  sequence: number;
  actionType: EActionTypeV3;
  selectors?: IActionSetSelector[];
  value?: string;
  maskedValue?: string;
  description?: string;
  preventNavigation?: boolean;
  waitDuration: number;
  actionSetId?: number;
  pageFilter?: string;
  url?: string;
  js?: string;
  seconds?: number;
  isRequired?: boolean;
}

export interface IActionSetSelector {
  selectorType: EWebElementSelectorTypeV3;
  value: IActionSetDetailedSelectorValue | string;
}

export class AssignmentRow {
  static fromAudit(item: IAuditModel, type: EActionSetAssignmentType, users: IUser[]): IAssignmentRow {
    return {
      id: item.id,
      folderId: item.folderId,
      domainId: item.domainId,
      name: item.name,
      createdOn: item.created,
      lastRunId: AssignmentRow.getRunId(item, type),
      createdBy: AssignmentRow.getUserName(item.ownerId, users)
    };
  }

  static fromWebJourney(item: IWebJourneyV3, type: EActionSetAssignmentType, users: IUser[]): IAssignmentRow {
    return {
      id: item.id,
      folderId: item.folderId,
      domainId: item.domainId,
      name: item.name,
      createdOn: item.createdAt,
      lastRunId: AssignmentRow.getRunId(item, type),
      createdBy: AssignmentRow.getUserName(item.userId, users)
    };
  }

  static getRunId(item: IAuditModel | IWebJourneyV3, type: EActionSetAssignmentType): number | null {
    return (type === EActionSetAssignmentType.WebAudits) && (item['runs'].length > 0)
      ? item['runs'][0].id
      : null;
  }

  static getUserName(id: number, users): string {
    let user = users.find(user => user.id === id);

    if (user?.firstName && user?.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }

    return 'Unavailable';
  }
}

export interface IAssignmentRow {
  id: number,
  folderId: number,
  domainId: number,
  lastRunId: number,
  name: string,
  createdBy: string,
  createdOn: string
}

export interface IActionSetDetailedSelectorValue {
  tagName: string;
  innerHTML: string;
  attributes: [
    {
      name: string;
      value: string;
    }
  ];
}

export interface IActionSetLibraryItem {
  id: number;
  name: string;
  type: string;
  webAudits: IActionSetLibrarySingleItem[];
  webJourneys: IActionSetLibrarySingleItem[];
}

export enum EActionSetAssignmentType {
  WebAudits = 'webAudits',
  WebJourneys = 'webJourneys'
}

export interface IActionSetTableRow extends IActionSetLibraryItem {
  webAuditCount: number;
  webJourneyCount: number;
}

export interface IActionSetLibrarySingleItem {
  id: number;
  name: string;
  access: boolean;
}

export interface IActionSetLibraryItemCondensed {
  id: number;
  name: string;
  webAuditCount: number;
  webJourneyCount: number;
}

export interface IMigrateActions {
  auditsWithMatchedActions: IMigrateActionsItem[];
  auditsWithMatchedLoginActions: IMigrateActionsItem[];
  journeys: IMigrateActionsItem[];
}

export interface IMigrateActionsItem {
  id: number;
  name: string;
  access: boolean;
}

export interface IMigratedActions {
  auditsWithMatchedActions: number[];
  auditsWithMatchedLoginActions: number[];
  journeys: number[];
}

export interface ICreateActionSetObject {
  actionSet: IActionSet;
  addSimilar: boolean;
}
