import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ICCGeoSelectorItem } from './cc-geo-selector.models';
import { EGeoAutoCompleteType } from './cc-geo-selector.constants';
import { ConsentCategoriesService } from '../consent-categories.service';
import { IAuditGeoLocation, IConsentCategoryGeos } from '../consent-categories.models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-geo-selector',
  templateUrl: './cc-geo-selector.component.html',
  styleUrls: ['./cc-geo-selector.component.scss']
})
export class CCGeoSelectorComponent implements OnInit {

  placeholder: string;
  separatorKeysCodes: number[] = [];
  filteredList: ICCGeoSelectorItem[] = [];
  geoData: IConsentCategoryGeos;

  private destroy$ = new Subject();

  @Input() autoCompleteList: ICCGeoSelectorItem[] = [];
  @Input() selectedCountries: ICCGeoSelectorItem[] = [];
  @ViewChild('continentInput') continentInput: ElementRef<HTMLInputElement>;
  @Output() selectionChanged: EventEmitter<ICCGeoSelectorItem[]> = new EventEmitter();

  constructor(private ccService: ConsentCategoriesService) {}

  ngOnInit(): void {
    this.getGeoData();
    this.updatePlaceholderStatus();
  }

  getGeoData(): void {
    this.ccService.geo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((geoData: IConsentCategoryGeos) => {
        this.geoData = geoData;
      });
  }

  filterAutoCompleteList(val: string): void {
    const searchTerm = val.trim().toLocaleLowerCase();
    const selectedCountryIds = this.getSelectedCountriesById();

    this.filteredList = [ ...this.autoCompleteList ].filter((item: ICCGeoSelectorItem) => {
      if (selectedCountryIds.includes(item.id)) return false;

      // adding a split so continent names that include other text don't
      // match with the search term and pollute the results
      return item.name.toLocaleLowerCase().split('(')[0].trim().includes(searchTerm);
    });
  }

  addChip(item: ICCGeoSelectorItem): void {
    if (item.type === EGeoAutoCompleteType.CONTINENT) {
      this.handleContinentSelection(item);
      return;
    }

    this.selectedCountries.push(item);
    this.continentInput.nativeElement.value = '';
    this.updatePlaceholderStatus();
    this.selectionChanged.emit(this.selectedCountries);
  }

  removeChip(country: ICCGeoSelectorItem): void {
    const index = this.selectedCountries.indexOf(country);
    this.selectedCountries.splice(index , 1);
    this.updatePlaceholderStatus();
    this.selectionChanged.emit(this.selectedCountries);
  }

  updatePlaceholderStatus(): void {
    this.placeholder = this.selectedCountries.length
      ? ''
      : 'Select location (or leave empty for any location)';
  }

  handleContinentSelection(continent: ICCGeoSelectorItem) {
    const selectedCountryIds = this.getSelectedCountriesById();

    this.geoData.continentsById[continent.id].forEach((country: IAuditGeoLocation) => {
      if (!selectedCountryIds.includes(country.countryId)) {
        this.selectedCountries.push({
          id: country.countryId,
          name: country.countryName,
          type: EGeoAutoCompleteType.COUNTRY
        });
      }
    });

    this.continentInput.nativeElement.value = '';
    this.updatePlaceholderStatus();
    this.selectionChanged.emit(this.selectedCountries);
  }

  getSelectedCountriesById(): number[] {
    return  this.selectedCountries.map((country: ICCGeoSelectorItem) => country.id);
  }
}
