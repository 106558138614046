import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuditReportBase, IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { BehaviorSubject, EMPTY, Observable, ReplaySubject, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import {
  BrowserConsoleLogsService
} from '@app/components/audit-reports/reports/browser-console-logs/browser-console-logs.service';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import {
  IBrowserConsoleLogsRunOverview,
  IBrowserConsoleLogsRunOverviews,
  IBrowserConsoleLogsSummary,
  IBrowserLogsPerPageTableRow,
  IBrowserLogsResultsItem,
  IMessagesTableState,
  IPagesTableState
} from '@app/components/audit-reports/reports/browser-console-logs/browser-console-logs.models';
import { catchError, map, skip, takeUntil, tap } from 'rxjs/operators';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { formatNumber } from '@angular/common';
import {
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import {
  BROWSER_CONSOLE_LOGS,
  BrowserConsoleLogsRelevantFilters,
  BrowserConsoleLogsTrendName,
  DEBUG_CHART_CONFIG,
  ERRORS_CHART_CONFIG,
  INFO_CHART_CONFIG,
  OTHER_CHART_CONFIG,
  WARNINGS_CHART_CONFIG
} from '@app/components/audit-reports/reports/browser-console-logs/browser-console-logs.constants';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { TagHealthTrendsByName } from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import { sortBy } from 'lodash-es';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import {
  IBrowserLogsTableRow
} from '@app/components/audit-reports/reports/browser-console-logs/components/browser-messages-table/browser-messages-table.models';
import { ELogLevels } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { ThemeService } from '@app/services/theme-service/theme.service';
import {
  IAuditReportExportMenuData
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import _isEmpty from 'lodash-es/isEmpty';
import { AlertMetricType, EAlertBrowserConsoleLogsMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import {
  BrowserPagesTableComponent
} from '@app/components/audit-reports/reports/browser-console-logs/components/browser-pages-table/browser-pages-table.component';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  CommonPagesColumnConfigWarningMessage, CommonPagesConfigLocalStorageKey,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import { PagesTableColumns } from './components/browser-pages-table/browser-pages-table.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'browser-console-logs',
  templateUrl: './browser-console-logs.component.html',
  styleUrls: ['./browser-console-logs.component.scss'],
  providers: [ResizeableTableService]
})
export class BrowserConsoleLogsComponent
  extends AuditReportBase
  implements IFilterableAuditReport, OnInit, OnDestroy {
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  readonly PagesTableColumns = PagesTableColumns;

  @ViewChild(BrowserPagesTableComponent) browserPagesTableComponent: BrowserPagesTableComponent;

  // GENERAL
  auditId: number;
  runId: number;
  apiFilters: IAuditReportApiPostBody = {};
  private filtersUpdated$ = new Subject();
  private widgetSparklineRunsInfo: ISparklineRunInfo[] = [];
  private selectedLogConditionIdentifier: IBrowserLogsTableRow;

  selectedConsoleMessage: string;

  browserMessagesTableState: IMessagesTableState = {
    sort: { sortBy: 'level', sortDesc: true },
    pagination: { size: 50, page: 0 },
    pagesTotal: 0
  };

  browserMessages: IBrowserLogsResultsItem[];
  browserLogsSpinnerState: EFilterSpinnerState;

  browserPagesTableState: IPagesTableState = {
    sort: { sortBy: 'error_log_count', sortDesc: true },
    pagination: { size: 200, page: 0 },
    pagesTotal: 0
  };

  browserPagesLogs: IBrowserLogsPerPageTableRow[];
  browserPagesLogsSpinnerState: EFilterSpinnerState;

  infoLogCount: number = null;
  debugLogCount: number = null;
  otherLogCount: number = null;

  totalPageCount: number;
  filteredPageCount: number;
  @ViewChild('commonLogsSection') topMessageSection: ElementRef;
  @ViewChild('pagesSection') pagesSection: ElementRef;

  readonly EFilterSpinnerState = EFilterSpinnerState;

  // WIDGETS
  widgetsState = EFilterSpinnerState.Loading;

  widgetPagesScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    metricType: EAlertBrowserConsoleLogsMetric.PagesScanned,
  };

  widgetErrors: ISplitCardChartData = {
    topLabel: 'Errors',
    icon: 'cancel',
    iconColor: 'color-graph-darker-red',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.toggleWidgetFilter(ELogLevels.Error),
    bottomHandler: this.openErrorsFullscreenChart.bind(this),
    metricType: EAlertBrowserConsoleLogsMetric.Errors,
  };
  widgetErrorsSparklineData: ISparklineChartData[] = [];

  widgetWarnings: ISplitCardChartData = {
    topLabel: 'Warnings',
    icon: 'warning',
    iconColor: 'color-graph-orange',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.toggleWidgetFilter(ELogLevels.Warning),
    bottomHandler: this.openWarningsFullscreenChart.bind(this),
    metricType: EAlertBrowserConsoleLogsMetric.Warnings,
  };
  widgetWarningsSparklineData: ISparklineChartData[] = [];

  widgetInfo: ISplitCardChartData = {
    topLabel: 'Info',
    icon: 'info',
    iconColor: 'color-graph-blue',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.toggleWidgetFilter(ELogLevels.Info),
    bottomHandler: this.openInfosFullscreenChart.bind(this),
    metricType: EAlertBrowserConsoleLogsMetric.Info,
  };
  widgetInfosSparklineData: ISparklineChartData[] = [];

  widgetDebug: ISplitCardChartData = {
    topLabel: 'Debug',
    icon: 'bug_report',
    iconColor: 'color-graph-purple',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.toggleWidgetFilter(ELogLevels.Debug),
    bottomHandler: this.openDebugFullscreenChart.bind(this),
    metricType: EAlertBrowserConsoleLogsMetric.Debug,
  };
  widgetDebugSparklineData: ISparklineChartData[] = [];

  widgetOther: ISplitCardChartData = {
    topLabel: 'Other',
    icon: 'chat',
    iconColor: 'color-graph-white',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.toggleWidgetFilter(ELogLevels.Other),
    bottomHandler: this.openOtherFullscreenChart.bind(this),
    metricType: EAlertBrowserConsoleLogsMetric.Other,
  };
  widgetOtherSparklineData: ISparklineChartData[] = [];
  filteredWidget: ELogLevels;
  ELogLevels = ELogLevels;
  sparklineDataLoaded: boolean = false;

  exportReportConfig: IAuditReportExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: BROWSER_CONSOLE_LOGS,
    totalRows: 0,
    filteredRows: 0,
    tableState: {
      ...this.browserPagesTableState.sort,
      ...this.browserPagesTableState.pagination
    },
    filters: this.apiFilters,
    dataToCopy: {
      config: [
        {
          property: 'pageUrl',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl
        },
        {
          property: 'finalPageUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
        },
        {
          property: 'pageLoadTime',
          displayLike: row => parseFloat((row.pageLoadTime / 1000).toFixed(1)),
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
        },
        {
          title: 'TOTAL ERRORS',
          property: 'errorLogCount',
          tableColumnName: CommonReportsPagesTableColumns.ErrorLogCount
        },
        {
          title: 'TOTAL WARNINGS',
          property: 'warnLogCount',
          tableColumnName: CommonReportsPagesTableColumns.WarningLogCount
        },
        {
          title: 'TOTAL INFO',
          property: 'infoLogCount',
          tableColumnName: CommonReportsPagesTableColumns.InfoLogCount
        },
        {
          title: 'TOTAL DEBUG',
          property: 'debugLogCount',
          tableColumnName: CommonReportsPagesTableColumns.DebugLogCount
        },
        {
          title: 'TOTAL OTHER',
          property: 'otherLogCount',
          tableColumnName: CommonReportsPagesTableColumns.OtherLogCount
        }
      ],
      data: null,
      displayedColumns$: this.tableService.displayedColumns$
    }
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  constructor(
    private route: ActivatedRoute,
    private auditReportService: AuditReportService,
    private browserConsoleLogsService: BrowserConsoleLogsService,
    private auditReportLoadingService: AuditReportLoadingService,
    private modalService: OpModalService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private snackbarService: SnackbarService,
    private scrollService: AuditReportScrollService,
    private themeService: ThemeService,
    private alertReportingService: AlertReportingService,
    private tableService: ResizeableTableService
  ) {
    super();
  }

  ngOnInit() {
    this.route.params.pipe(
      tap(({ auditId, runId }) => {
        this.auditId = +auditId;
        this.runId = +runId;

        const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
        const alertId = queryParams?.alertId;
        const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
        this.preventHighlight = !highlight;
        // When loading report from an alert, first override filters with alert
        // filters before loading report data
        if (alertId) {
          this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
            this.filterBarService.overrideFilters(alert.config.filtersV0);
            this.highlightMetricType = highlight && alert.config.metricType;

            this.alertCheckComplete$.next(true);
          });
        } else {
          this.alertCheckComplete$.next(true);
        }
      }),
      skip(1), // only fire when calendar control updated
      tap(() => {
        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          this.onFiltersChanged(this.apiFilters);
        });
      })
    ).subscribe();

    this.initFilters();

    this.themeService.isDarkTheme
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(isDarkMode => {
        this.widgetOther.iconColor = isDarkMode ? 'color-graph-white' : 'color-graph-black';
      });
  }

  loadMessages() {
    this.browserLogsSpinnerState = EFilterSpinnerState.Loading;
    this.browserConsoleLogsService
      .getTopMessages(this.auditId, this.runId, this.browserMessagesTableState, this.totalFilters())
      .subscribe(
        data => {
          this.browserMessages = data ? data.messages : [];

          this.browserMessagesTableState.pagesTotal = data.metadata.pagination.totalCount;
          this.browserLogsSpinnerState = this.calcSpinnerState();
        },
        this.handleApiError('Failed to load browser logs.'),
        this.handleApiComplete(() => {
            this.browserLogsSpinnerState = this.calcSpinnerState();
          }
        )
      );
  }

  ngOnDestroy() {
    this.auditReportLoadingService.forceOff();
    this.destroy();
    this.onDestroy$.next();
  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(BrowserConsoleLogsRelevantFilters);

    this.filterBarService.apiPostBody$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody) {
    this.selectedLogConditionIdentifier = null;

    this.apiFilters = apiPostBody;
    this.exportReportConfig.filters = {...apiPostBody};
    this.selectedLogConditionIdentifier = null;
    this.filtersUpdated$.next();
    this.updateCurrentFilters();

    this.loadWidgets();
    this.loadSparklines();

    this.browserMessagesTableState.pagination.page = 0;
    this.loadMessages();

    this.exportReportConfig.tableState.page = this.browserPagesTableState.pagination.page = 0;
    this.loadPages();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  handleLogConditionMetricClicked(log: IBrowserLogsTableRow) {
    if (this.selectedLogConditionIdentifier === log) {
      this.selectedLogConditionIdentifier = null;
    } else {
      this.selectedLogConditionIdentifier = log;
    }

    this.exportReportConfig.tableState.page = this.browserPagesTableState.pagination.page = 0;
    this.selectedConsoleMessage = log?.message || '';

    this.loadPages();
    this.scrollService.scrollByElement(this.pagesSection.nativeElement);
  }

  updatePagesState() {
    this.loadPages();
    this.scrollService.scrollByElement(this.pagesSection.nativeElement);
  }

  updateMessagesState() {
    this.loadMessages();
  }

  public openErrorsFullscreenChart() {
    if (this.widgetErrorsSparklineData.length > 1) {
      this.openFullscreenChart(BrowserConsoleLogsTrendName.Errors, this.showTimelineInCharts());
    }
  }

  public openWarningsFullscreenChart() {
    if (this.widgetWarningsSparklineData.length > 1) {
      this.openFullscreenChart(BrowserConsoleLogsTrendName.Warnings, this.showTimelineInCharts());
    }
  }

  public openInfosFullscreenChart() {
    if (this.widgetInfosSparklineData.length > 1) {
      this.openFullscreenChart(BrowserConsoleLogsTrendName.Info, this.showTimelineInCharts());
    }
  }

  public openDebugFullscreenChart() {
    if (this.widgetDebugSparklineData.length > 1) {
      this.openFullscreenChart(BrowserConsoleLogsTrendName.Debug, this.showTimelineInCharts());
    }
  }

  public openOtherFullscreenChart() {
    if (this.widgetOtherSparklineData.length > 1) {
      this.openFullscreenChart(BrowserConsoleLogsTrendName.Other, this.showTimelineInCharts());
    }
  }

  public toggleWidgetFilter(level: ELogLevels) {
    return () => {
      if (this.filteredWidget !== level) {
        this.filteredWidget = level;
      } else {
        this.filteredWidget = null;
      }

      this.onFiltersChanged(this.apiFilters);
    };
  }

  private openFullscreenChart(
    trendName: BrowserConsoleLogsTrendName,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ) {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService
      .openFullscreenModal(
        FullscreenChartModalComponent,
        {
          data: {
            timeframeOriginRunCompletion: secondToLastCompletionDate,
            getData: (days: number) => this.getFullscreenChartData(trendName, days),
            getSummaryLines,
            chartConfig: this.getFullscreenChartConfig(trendName)
          }
        }
      )
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  private getFullscreenChartData(trendName: BrowserConsoleLogsTrendName, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.browserConsoleLogsService
      .getBrowserLogsTrend(this.auditId, trendName, days)
      .pipe(
        map((data: TagHealthTrendsByName) => {
          return {
            chartData: data.runs.map(item => {
              return {
                value: item.trendValue,
                date: item.completedAt
              };
            })
          };
        })
      );
  }

  private updateWidgets(summary: IBrowserConsoleLogsSummary) {
    this.infoLogCount = summary.infoLogCount;
    this.debugLogCount = summary.debugLogCount;
    this.otherLogCount = summary.otherLogCount;
    this.exportReportConfig.totalRows = this.totalPageCount = summary.totalPageCount;
    this.exportReportConfig.filteredRows = this.filteredPageCount = summary.filteredPageCount;
    this.widgetErrors.topChangeContent = this.formatWidgetContent(summary.errorLogCount, 0);
    this.widgetErrors.topChangeMeaning = ESplitCardChangeMeaning.NEGATIVE;

    this.widgetWarnings.topChangeContent = this.formatWidgetContent(summary.warnLogCount, 0);
    this.widgetWarnings.topChangeMeaning = ESplitCardChangeMeaning.NEGATIVE;

    this.widgetInfo.topChangeContent = this.formatWidgetContent(summary.infoLogCount, 0);

    this.widgetDebug.topChangeContent = this.formatWidgetContent(summary.debugLogCount);

    this.widgetOther.topChangeContent = this.formatWidgetContent(summary.otherLogCount);
  }

  private updateSparklines(dataPoints: IBrowserConsoleLogsRunOverview[]) {
    const errors: ISparklineChartData[] = [];
    const warnings: ISparklineChartData[] = [];
    const info: ISparklineChartData[] = [];
    const debug: ISparklineChartData[] = [];
    const other: ISparklineChartData[] = [];

    const runsInfo: ISparklineRunInfo[] = [];

    dataPoints.forEach((dataPoint, index: number) => {
      errors.push({ value: dataPoint.errorLogCount, sequence: index });
      warnings.push({ value: dataPoint.warnLogCount, sequence: index });
      info.push({ value: dataPoint.infoLogCount, sequence: index });
      debug.push({ value: dataPoint.debugLogCount, sequence: index });
      other.push({ value: dataPoint.otherLogCount, sequence: index });

      runsInfo.push({ runId: dataPoint.runId, runCompletionDate: dataPoint.completedAt });
    });

    this.widgetErrors.bottomHandler = errors.length >= 2 ? this.widgetErrors.bottomHandler : null;
    this.widgetWarnings.bottomHandler = warnings.length >= 2 ? this.widgetWarnings.bottomHandler : null;
    this.widgetInfo.bottomHandler = info.length >= 2 ? this.widgetInfo.bottomHandler : null;
    this.widgetDebug.bottomHandler = debug.length >= 2 ? this.widgetDebug.bottomHandler : null;
    this.widgetOther.bottomHandler = other.length >= 2 ? this.widgetOther.bottomHandler : null;

    this.widgetErrorsSparklineData = errors;
    this.widgetWarningsSparklineData = warnings;
    this.widgetInfosSparklineData = info;
    this.widgetDebugSparklineData = debug;
    this.widgetOtherSparklineData = other;

    this.widgetSparklineRunsInfo = runsInfo;
  }

  private formatWidgetContent(filteredCount: number, totalCount?: number): string {
    const format = (x: number) => formatNumber(x, 'en-US');
    if (!this.isFiltered() || (this.isFiltered() && !totalCount)) {
      return format(filteredCount);
    } else {
      return `${format(filteredCount)} of ${format(totalCount)}`;
    }
  }

  private isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.length > 0 || !!this.filteredWidget;
  }

  private errorToast(message: string) {
    this.snackbarService.openErrorSnackbar(message);
  }

  private handleApiError = (message: string, handler?: (error: any) => void) => {
    return error => {
      handler && handler(error);
      console.error(error);
      this.errorToast(message);
    };
  }

  private handleApiComplete = (handler?: () => void) => {
    return () => {
      handler && handler();
      this.auditReportLoadingService.removeLoadingToken();
    };
  }

  private getFullscreenChartConfig(trendName: BrowserConsoleLogsTrendName) {
    switch (trendName) {
      case BrowserConsoleLogsTrendName.Errors: return ERRORS_CHART_CONFIG;
      case BrowserConsoleLogsTrendName.Warnings: return WARNINGS_CHART_CONFIG;
      case BrowserConsoleLogsTrendName.Info: return INFO_CHART_CONFIG;
      case BrowserConsoleLogsTrendName.Debug: return DEBUG_CHART_CONFIG;
      case BrowserConsoleLogsTrendName.Other: return OTHER_CHART_CONFIG;
      default: return null;
    }
  }

  private getWidgetConfig(level: ELogLevels) {
    switch (level) {
      case ELogLevels.Error: return this.widgetErrors;
      case ELogLevels.Warning: return this.widgetWarnings;
      case ELogLevels.Info: return this.widgetInfo;
      case ELogLevels.Debug: return this.widgetDebug;
      case ELogLevels.Other: return this.widgetOther;
    }
  }

  private totalFilters() {
    return this.filteredWidget
      ? {...this.apiFilters, level: this.filteredWidget}
      : {...this.apiFilters};
  }

  private calcSpinnerState(): EFilterSpinnerState {
    return this.filterBarService.currentRelevantFilters.length || Object.keys(this.apiFilters).length || this.filteredWidget
      ? EFilterSpinnerState.Filtered
      : EFilterSpinnerState.None;
  }

  private loadPages() {
    let localFilter = {
      tagId: this.selectedLogConditionIdentifier?.relatedTag.tagId,
      message: {
        message: this.selectedLogConditionIdentifier?.message ?? null,
        level: this.selectedLogConditionIdentifier?.level ?? null,
      }
    };

    const appliedFilters = this.selectedLogConditionIdentifier
      ?  {...localFilter, ...this.totalFilters()}
      : this.totalFilters();

    this.browserPagesLogsSpinnerState = EFilterSpinnerState.Loading;
    this.browserConsoleLogsService
      .getPages(
        this.auditId,
        this.runId,
        this.browserPagesTableState,
        appliedFilters
      )
      .subscribe(data => {
        this.exportReportConfig.dataToCopy.data = this.browserPagesLogs = data.pages;
        this.browserPagesTableState.pagesTotal = data.metadata.pagination.totalCount;

        if (_isEmpty(appliedFilters)) {
          this.exportReportConfig.totalRows = data.metadata.pagination.totalCount;
          this.exportReportConfig.filteredRows = Infinity;
        } else {
          this.exportReportConfig.filteredRows = data.metadata.pagination.totalCount;
        }

        this.exportReportConfig.filters = appliedFilters;
        this.exportReportConfig.tableState = {
          ...this.browserPagesTableState.sort,
          ...this.browserPagesTableState.pagination
        };
        this.browserPagesLogsSpinnerState = this.calcSpinnerState();
      });
  }

  private loadWidgets() {
    this.auditReportLoadingService.addLoadingToken();
    this.widgetsState = EFilterSpinnerState.Loading;

    this.browserConsoleLogsService
      .getBrowserLogsSummary(this.auditId, this.runId, this.totalFilters())
      .pipe(
        catchError(() => EMPTY),
        tap(() => {
          this.auditReportLoadingService.removeLoadingToken();
        })
      )
      .subscribe((data: IBrowserConsoleLogsSummary | undefined) => {
        if (data) {
          this.updateWidgets(data);
        } else {
          alert('Sorry! Some elements failed to update. Refresh your browser to try again.');
        }

        this.widgetsState = this.isFiltered() ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
      });
  }

  private loadSparklines() {
    this.auditReportLoadingService.addLoadingToken();
    this.sparklineDataLoaded = false;

    this.browserConsoleLogsService.getBrowserConsoleLogsTrends(this.auditId, this.runId, this.apiFilters)
      .pipe(
        map((data: IBrowserConsoleLogsRunOverviews) => sortBy(data.runs, element => element.runId)),
        catchError(() => EMPTY),
        tap(() => {
          this.auditReportLoadingService.removeLoadingToken();
        })
      )
      .subscribe((data: IBrowserConsoleLogsRunOverview[] | undefined) => {
        if (data) {
          this.updateSparklines(data);
        }

        this.sparklineDataLoaded = true;
      });
  }

  private showTimelineInCharts() {
    return this.widgetSparklineRunsInfo.length > 1 ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate : undefined;
  }
}
