<mat-expansion-panel class="page-issue-details" hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="panel-title">
        <div class="expand-arrow">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
        <div class="title">
          <div class="title-container">
            {{ group?.help }}
          </div>
        </div>
        <div class="panel-right">
          <div *ngIf="group?.inspectionNeeded" class="panel-inconclusive">Manual inspection<br>needed</div>
          <div class="panel-number" [ngClass]="group?.impact">{{ group?.htmlElements.length }}</div>
          <div class="panel-separator"></div>
          <div class="panel-label">
            {{ group?.wcagVersion }} {{ group?.wcagConformanceLevel }}
          </div>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="panel-content">
    <p class="group-description" [innerHTML]="description"></p>
    <op-page-issue-details *ngFor="let issue of group?.htmlElements; let i = index" [issue]="issue">
      <ng-container current>
        {{ i + 1 }}
      </ng-container>
      <ng-container of-total>
        of {{ group?.htmlElements.length }}
      </ng-container>
    </op-page-issue-details>
  </div>
</mat-expansion-panel>
