import { MatInput } from '@angular/material/input';
import { UntypedFormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EEmailInboxNameControlMode } from './email-inbox-name-control.constants';
import { EEmailInboxModalType } from '../../email-inbox-editor.constants';

@Component({
  selector: 'op-email-inbox-name-control',
  templateUrl: './email-inbox-name-control.component.html',
  styleUrls: ['./email-inbox-name-control.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: EmailInboxNameControlComponent,
    multi: true
  }]
})
export class EmailInboxNameControlComponent implements ControlValueAccessor {

  @Input() control: UntypedFormControl;
  @Input() modalType: EEmailInboxModalType;
  @Input() set initialMode(mode: EEmailInboxNameControlMode) {
    this.mode = mode;
    if (this.mode === EEmailInboxNameControlMode.Edit) {
      this.focusInput();
    }
  }
  @Output() modeChanged = new EventEmitter<void>();

  @ViewChild(MatInput) inputRef: MatInput;

  mode: EEmailInboxNameControlMode;
  value: string;
  onChange: (value: string) => void;

  readonly EEmailInboxNameControlMode = EEmailInboxNameControlMode;
  readonly EEmailInboxModalType = EEmailInboxModalType;

  switchToEditMode() {
    this.mode = EEmailInboxNameControlMode.Edit;
    this.focusInput();
    this.modeChanged.emit();
  }

  switchToViewMode(newName: string) {
    if (!newName) {
      this.control.markAsTouched();
      return;
    }
    this.mode = EEmailInboxNameControlMode.View;
    this.modeChanged.emit();
  }

  private focusInput() {
    // we should wait for the input rendering at first
    setTimeout(() => this.inputRef.focus());
  }

  /*** Control Value Accessor implementation ***/
  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(_: () => void): void { }
}
