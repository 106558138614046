<op-chip-manager [allChips]="allLabels"
                 [selectedChips]="selectedLabels"
                 [chipCountOnly]="chipCountOnly"
                 [numberOfRows]="numberOfRows"
                 [chipType]="'Label'"
                 [compactView]="compactView"
                 [isReadOnly]="isReadOnly"
                 [class.read-only]="isReadOnly"
                 (onChipCreated)="createNewLabel($event)"
                 (onChipSelected)="handleLabelSelected($event)"
                 (onChipRemoved)="removeLabel($event)"
                 (addingLabel)="addingLabel.emit($event)">
</op-chip-manager>
