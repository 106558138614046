<div class="cc-create-tab-content-wrapper">
  <div class="source-selection-description" *ngIf="!noSelectionDisplay">Select the cookies from your domain(s) that you would like to add to this consent category.</div>
  <div class="source-selection-description" *ngIf="noSelectionDisplay">The following is a list of all cookies present in this audit. Please select cookies you want to add to any consent category.</div>
  <div class="source-item-search">
    <op-clearable-input placeholderText="Search by cookie name or domain"
                        [emitKeyboardEvent]="true"
                        appearance="OUTLINED"
                        class="table-filter-input"
                        (onUserInput)="debounceFilterData($event)">
    </op-clearable-input>
  </div>

  <div class="cc-tables-scroll-container">
    <div class="cc-selected-table-wrapper" [class.no-selection-display]="noSelectionDisplay">
      <mat-expansion-panel hideToggle
                           [disabled]="selectedDataSource.data?.length === 0"
                           (opened)="openedSelection()"
                           (closed)="closedSelection()">
        <mat-expansion-panel-header>
          <cc-create-selection-info [selectedItems]="selectedCookies"
                                    selectedItemText="Cookies"
                                    [displaySelection]="displaySelection"
                                    [countOnly]="noSelectionDisplay"
                                    [selectedLimit]="selectedLimit">
          </cc-create-selection-info>
        </mat-expansion-panel-header>
        <div class="cc-selected-cookies-table-wrapper" [hidden]="loading || this.selectedDataSource.data?.length === 0 || noSelectionDisplay">
          <table mat-table
                 [dataSource]="selectedDataSource"
                 class="cc-selected-cookies-table"
                 #selectedTableSort="matSort"
                 matSort
                 multiTemplateDataRows
          >
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
              </th>
              <td mat-cell *matCellDef="let row" valign="middle">
                <mat-checkbox (change)="toggleSelectOne($event.checked, row, true)"
                              [disabled]="cookiesHash[row?.name + row?.domain]?.existing"
                              [checked]="cookiesHash[row?.name + row?.domain]?.selected"
                              color="primary"
                              [class.checkbox-disabled]="cookiesHash[row?.name + row?.domain]?.existing">
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="cookieName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Cookie Name</th>
              <td mat-cell
                  *matCellDef="let cookie"
                  (click)="toggleSelectOne(!cookiesHash[cookie?.name + cookie?.domain]?.selected, cookie, true)">
                <div class="source-cookie-name-field" [class.clickable]="!cookiesHash[cookie?.name + cookie?.domain]?.existing">
                  {{cookiesHash[cookie?.name + cookie?.domain]?.name}}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="cookieDomain">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Cookie Domain</th>
              <td mat-cell *matCellDef="let cookie">
                <div class="cookie-domain-wrapper" [class.existing-domain]="cookiesHash[cookie?.name + cookie?.domain]?.existing">
                  <div class="cookie-domain"
                       matTooltip="{{cookiesHash[cookie?.name + cookie?.domain]?.domain}}"
                       matTooltipPosition="above"
                       matTooltipShowDelay="600">
                    {{cookiesHash[cookie?.name + cookie?.domain]?.domain}}
                  </div>
                  <div class="existing-message" *ngIf="cookiesHash[cookie?.name + cookie?.domain]?.existing">
                    <mat-icon>info</mat-icon>
                    Item already included in this consent category
                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row
                class="cc-source-table-row"
                *matHeaderRowDef="displayedColumns;">
            </tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"
                class="cc-source-table-row"
                [class.existing]="cookiesHash[row?.name + row?.domain]?.existing">
            </tr>
          </table>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="cc-import-tables-wrapper">
      <div class="cc-cookie-table-wrapper">
        <div class="source-zero-state" *ngIf="!loading && this.dataSource.data?.length === 0 && cookiesFilter !== ''">No cookie names or domains match the current filter.</div>
        <div class="source-zero-state" *ngIf="!loading && this.dataSource.data?.length === 0 && cookiesFilter === ''">No cookies were discovered on the selected run(s).</div>
        <table mat-table
               [hidden]="loading || this.dataSource.data?.length === 0"
               [dataSource]="dataSource"
               class="cc-source-table"
               #resultsTableSort="matSort"
               matSort
               multiTemplateDataRows
        >
          <ng-container matColumnDef="select-all">
            <th mat-header-cell *matHeaderCellDef
                valign="middle"
                [attr.colspan]="displayedColumns.length">
              <div class="select-all-wrapper"
                   [class.disabled-select-all]="!allCookiesSelected && ((pagination.totalCount > selectedLimit) || unselectedCookiesInResponse > (selectedLimit - selectedCookies.length))"
              >
                <div class="checkbox-wrapper">
                  <mat-checkbox (change)="toggleSelectAll($event.checked)"
                                [checked]="allCookiesSelected"
                                [disabled]="!allCookiesSelected && ((pagination.totalCount > selectedLimit) || unselectedCookiesInResponse > (selectedLimit - selectedCookies.length))"
                                color="primary"
                                aria-label="Select all cookies">
                  </mat-checkbox>
                </div>
                <div class="select-all-text">Select All ({{pagination.totalCount}})
                  <span class="disabled-select-all-text" *ngIf="!allCookiesSelected && ((pagination.totalCount > selectedLimit) || unselectedCookiesInResponse > (selectedLimit - selectedCookies.length))">The items listed are greater than the limit of {{selectedLimit}}, please filter your results to enable select all.</span>
                </div>
              </div>
            </th>
          </ng-container>
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let row; let i = dataIndex;" valign="middle">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="handleCheckboxClick($event.checked, i)"
                            [checked]="cookiesHash[row.name + row.domain]?.selected"
                            [disabled]="cookiesHash[row.name + row.domain]?.existing"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="cookieName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Cookie Name</th>
            <td mat-cell
                *matCellDef="let cookie"
                (click)="toggleSelectOne(!cookiesHash[cookie?.name + cookie?.domain]?.selected, cookie)">
              <div class="source-cookie-name-field" [class.clickable]="!cookiesHash[cookie?.name + cookie?.domain]?.existing">
                {{cookiesHash[cookie.name + cookie.domain]?.name}}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="cookieDomain">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Cookie Domain</th>
            <td mat-cell *matCellDef="let cookie">
              <div class="cookie-domain-wrapper" [class.existing-domain]="cookiesHash[cookie?.name + cookie?.domain]?.existing">
                <div class="cookie-domain"
                     matTooltip="{{cookiesHash[cookie?.name + cookie?.domain]?.domain}}"
                     matTooltipPosition="above"
                     matTooltipShowDelay="600">
                  {{cookiesHash[cookie.name + cookie.domain]?.domain}}
                </div>
                <div class="existing-message" *ngIf="cookiesHash[cookie.name + cookie.domain]?.existing">
                  <mat-icon>info</mat-icon>
                  Item already included in this consent category
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row
              class="cc-source-table-row"
              *matHeaderRowDef="displayedColumns; sticky: true">
          </tr>
          <tr mat-header-row
              class="cc-source-table-row"
              *matHeaderRowDef="['select-all']">
          </tr>
          <tr mat-row
              *matRowDef="let row; columns: displayedColumns;"
              class="cc-source-table-row"
              [class.existing]="cookiesHash[row.name + row.domain]?.existing">
          </tr>
        </table>
        <mat-paginator [class.hidden]="loading"
                       [length]="pagination.totalCount"
                       [pageSize]="pagination.pageSize"
                       [hidePageSize]="true"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" class="spinner-wrapper">
  <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
</div>
