import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  ICheckRequestResponse,
  RequestCheckService
} from '@app/components/admin-portal/tag-database/request-check/request-check.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

interface ICheckRequestTagMatch {
  tagId: number;
  tagName: string;
  tagIcon: string;
  signature: string;
  tagCategoryName: string;
  tagCategoryId: number;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'request-check',
  templateUrl: './request-check.component.html',
  styleUrls: ['./request-check.component.scss']
})
export class TagDatabaseRequestCheckComponent implements OnDestroy, OnInit {
  dataSource = new MatTableDataSource;
  displayedColumns = ['tagId', 'tagName', 'signature', 'tagCategoryName'];
  loading: boolean = false;

  requestUrl: string;

  subject: Subject<string> = new Subject();
  subjectSubscription: Subscription;

  @ViewChild(MatSort) set content(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }

  constructor(
    private requestCheckService: RequestCheckService) {
  }

  ngOnInit() {
    this.subjectSubscription = this.subject.pipe(debounceTime(500)).subscribe(url => {
      this.check(url);
    });
  }

  ngOnDestroy() {
    if (this.subjectSubscription) {
      this.subjectSubscription.unsubscribe();
    }
  }

  check(url: string) {
    this.loading = true;
    this.dataSource.data = [];
    this.requestCheckService.checkRequest(url)
      .then((response: ICheckRequestResponse[]) => {
        let data: Array<ICheckRequestTagMatch> = [];
        response.forEach((checkRequest => {
          checkRequest.matchedSignatures.forEach(signature => {
            checkRequest.tag && data.push({
              tagId: checkRequest.tag.id,
              tagName: checkRequest.tag.name,
              tagIcon: checkRequest.tag.icon,
              signature: signature,
              tagCategoryName: checkRequest.tag?.category?.name,
              tagCategoryId: checkRequest.tag?.category?.id,
            });
          });
        }));

        this.dataSource.data = data;
      }, (error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
