import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuditReportPaths } from '../audit-report/audit-report.constants';
import { IUseCaseTabs } from './use-cases.models';
import {
  IAuditRunExecutionInfo,
} from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { Subject } from 'rxjs';
import { AuditReportScrollService } from '../audit-report-scroll.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'use-cases',
  templateUrl: './use-cases.component.html',
  styleUrls: ['./use-cases.component.scss']
})
export class UseCasesComponent implements OnInit {
  audit: IAuditRunExecutionInfo;
  destroy$ = new Subject();
  tabs: IUseCaseTabs[] = [{
    label: 'Overview',
    path: AuditReportPaths.useCaseOverview,
    matIcon: 'radar',
    svgIcon: null
  }, {
    label: 'Analytics',
    path: AuditReportPaths.useCaseAnalytics,
    matIcon: null,
    svgIcon: 'use_case_analytics'
  }, {
    label: 'Privacy',
    path: AuditReportPaths.useCasePrivacy,
    matIcon: 'verified_user',
    svgIcon: null
  }, {
    label: 'Landing Page',
    path: AuditReportPaths.useCaseLandingPage,
    matIcon: 'important_devices',
    svgIcon: null
  }];

  activeTab: string = AuditReportPaths.useCaseOverview;

  constructor(
    private router: Router,
    private scrollService: AuditReportScrollService
  ) {}

  ngOnInit(): void {
    this.activeTab = this.getActiveTab();
  }

  getActiveTab(): string {
    const paths = this.router.url.split('/');
    return paths[paths.length - 1];
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
    this.scrollToTop();
  }

  scrollToTop(){
    this.scrollService.scrollToTop();
  }
}
