import { Component, EventEmitter, HostListener, Input, Output, SimpleChanges, OnInit, OnChanges, AfterViewInit, OnDestroy, } from '@angular/core';
import * as d3 from 'd3';
import { ScaleLinear, ScaleTime, Selection } from 'd3';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  EChartInterval,
  IFullscreenChartConfigStandard,
  IFullscreenChartData,
  IFullscreenChartDataFormatted
} from '../fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EChartChangeTypes } from '@app/components/audit-reports/page-information/page-information.constants';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import { OpD3SvgTooltip, OpD3SvgTooltipConfig } from '@app/components/shared/components/viz/utils/op-d3-svg-tooltip';
import { DateService, EDateFormats } from '@app/components/date/date.service';

@Component({
  selector: 'op-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private resize$ = new Subject();
  private destroy$ = new Subject();

  svgWidth: number;
  svgHeight: number;
  container;
  svg;  // main svg element that groups are all appended to
  line; // line graph of data points
  limitLine; // line graph of data points
  areaChart;
  margin;
  xScale: ScaleTime<any, any, never>;  // x-scale
  yScale: ScaleLinear<any, any, any>;  // y-scale
  xAxis;
  gXAxis;
  yAxis;
  gYAxis;
  g;
  // zoom;
  chartConfig: IFullscreenChartConfigStandard = null;
  darkTheme: boolean;
  focus;
  summaryLineTooltip: Selection<any, any, any, any>;
  summaryLineTooltipText: Selection<any, any, any, any>;

  windowWidth: number = window.innerWidth;
  datapointTooltipClass: string = 'areachart-datapoint-tooltip';
  datapointTooltip: OpD3SvgTooltip;
  datapointTooltipTextLabel: Selection<any, any, any, any>;
  datapointTooltipTextValue: Selection<any, any, any, any>;
  datapointTooltipTextDate: Selection<any, any, any, any>;

  @Input() config: IFullscreenChartConfigStandard;
  @Input() uniqueIdentifier?: string = '';
  @Output() sectionClicked: EventEmitter<any> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
  }

  constructor(
    private dateService: DateService,
  ) {}

  ngOnInit() {
    this.darkTheme = document.getElementsByTagName('body')[0].className.includes('dark-theme');
    this.resize$.pipe(debounceTime(200), takeUntil(this.destroy$)).subscribe(() => {
      this.createChart();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      this.chartConfig = changes.config.currentValue;
      this.processData();
      this.createChart();
    }
  }

  ngAfterViewInit() {
    this.processData();
    this.createChart();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  processData() {
    if (this.chartConfig === null) this.chartConfig = {...this.config};
    this.chartConfig.data = (this.config.data as IFullscreenChartData[]).map((item: IFullscreenChartData) => {
      const date = new Date(item.date);
      const value = item.value;

      return { date, value };
    }).sort((a, b) => a.date < b.date ? -1 : 1);
  }

  createChart(): void {
    if (this.chartConfig.data.length <= 1) return;
    this.createMargins();
    this.createSvg();

    this.createScales();
    this.createAreaChart();
    this.createLineChart();
    this.createLimitLineChart();
    this.createAreaChartGradient();
    if (this.chartConfig.showXAxis) this.createXAxis();
    if (this.chartConfig.showYAxis) this.createYAxis();
    if (this.chartConfig.showSummaryLines) this.createSummaryLines();
    // this.createZoom(this.zoomed);
    this.createDatapointCircles();
    this.createDatapointTooltip();
    this.createDatapointValues();
  }

  createMargins() {
    this.margin = this.config.margin ? this.config.margin : {
      top: 60,
      right: 60,
      bottom: 125,
      left: 100
    };
  }

  createSvg() {
    // Clear old svg before redrawing if necessary
    if (this.svg) this.svg.remove();

    const svgContainer = `.svg-container${this.uniqueIdentifier !== undefined ? '-' + this.uniqueIdentifier : ''}`;
    this.container = d3.select(svgContainer);
    if (this.container.empty()) { // If selection is empty, any methods called on it will result in error
      return;
    }
    this.svgHeight = parseInt(this.container.style('height'));
    this.svgWidth = parseInt(this.container.style('width'));

    this.svg = this.container.append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight)
      .attr('viewBox', [0, 0, this.svgWidth, this.svgHeight]);

    this.g = this.svg.append('g');
  }

  createScales() {
    // Set range to svg inner dimensions (adjusted for margins)
    const ranges = this.calculateScaleRanges();
    const fixedAxis = this.chartConfig.fixedYAxis;

    this.xScale = d3.scaleTime()
      .domain(d3.extent(this.chartConfig.data, (d: any) => d.date)) // add domain to time scale for min/max dates
      .rangeRound([ranges.x.start, ranges.x.end]); // range is width of graph

    this.yScale = d3.scaleLinear()
      .domain([0, fixedAxis ? fixedAxis : d3.max([...this.chartConfig.data, {value: this.chartConfig.limit || 0}], (d: any) => d.value)]) // add domain to value scale for min/max values
      .rangeRound([ranges.y.end, ranges.y.start]);  // range is height of graph
  }

  private calculateScaleRanges(): { x: { start: number; end: number; }, y: { start: number; end: number; } } {
    return {
      x: {
        start: this.margin.left,
        end: this.svgWidth - this.margin.right
      },
      y: {
        start: this.margin.top,
        end: this.svgHeight - this.margin.bottom
      }
    };
  }

  createXAxis() {
    const xTickPadding = this.config.xTickPadding || 20;

    this.xAxis = d3.axisBottom(this.xScale)
      .tickFormat(d3.timeFormat(this.config.xAxisTimeFormat ? this.config.xAxisTimeFormat : '%b %_d, %Y'))
      .tickPadding(xTickPadding)
      .tickSizeInner(0)
      .tickSizeOuter(0);

    const tickOptions = this.chartConfig?.xTickIntervalOptions;

    switch (tickOptions?.interval) {
      case EChartInterval.daily:
        this.xAxis.ticks(d3.timeDay.every(tickOptions.countPerInterval));
        break;
      case EChartInterval.monthly:
        this.xAxis.ticks(d3.timeMonth.every(tickOptions.countPerInterval));
        break;
      case EChartInterval.yearly:
        this.xAxis.ticks(d3.timeYear.every(tickOptions.countPerInterval));
        break;
    }

    this.gXAxis = this.g.append('g')
      .attr('transform', `translate(0, ${this.svgHeight - this.margin.bottom})`)
      .call(this.xAxis)
      .call(g => g.select('.domain').remove());

    const xAxisText = this.gXAxis.selectAll('text');

    xAxisText.attr('class', 'area-chart-x-axis');

    if (!this.config.xAxisNoRotate) {
      xAxisText
        .attr('transform', 'rotate(-65)')
        .attr('x', -35);
    }
  }

  createYAxis() {
    const yTickPadding = this.config.yTickPadding ? this.config.yTickPadding : 20;

    this.yAxis = d3.axisLeft(this.yScale)
      .tickSize(-this.svgWidth + this.margin.left + this.margin.right)
      .tickPadding(yTickPadding)
      .tickSizeOuter(0);

      if (this.config.yAxisTickCount) {
        this.yAxis.ticks(this.config.yAxisTickCount);
      }

    this.gYAxis = this.g.append('g')
      .attr('transform', `translate(${this.margin.left}, 0)`)
      .attr('class', 'area-chart-axis-line')
      .call(this.yAxis)
      .call(g => g.select('.domain').remove());

    // Add label
    this.g
      .append('text')
      .call(g => g.select('.domain').remove())
      .attr('class', 'area-chart-primary-text')
      .attr('transform', `translate(0, ${this.margin.top - 40})`)
      .attr('y', 6)
      .attr('dy', 0)
      .attr('dx', 65)
      .attr('text-anchor', 'start')
      .text(this.chartConfig.sideLabel);

    // Set opacity on horizontal grid lines
    this.gYAxis
      .selectAll('.tick line')
      .attr('opacity', 0.1);
    // Set color of tick marks
    this.gYAxis
      .selectAll('text')
      .attr('class', 'area-chart-y-axis');
  }

  createAreaChart() {
    // Plot each point from data, where x is the date and area is between 0 and data point value
    this.areaChart = d3.area()
      .x((d: any) => this.xScale(d.date))
      .y0(this.yScale(0))
      .y1((d: any) => this.yScale(d.value));

    // Attach gradient fill for area
    this.g
      .append('path')
      .attr('fill', 'url(#linear-gradient)')
      .attr('d', this.areaChart(this.chartConfig.data));
  }

  // Draw line at top of area chart
  createLineChart() {
    const lineClass = `line ${this.config.gradientColor ? 'line-' + this.config.gradientColor : ''}`;

    this.line = d3.line()
      .x((d: any) => this.xScale(d.date))
      .y((d: any) => this.yScale(d.value));

    this.g.append('path')
      .attr('class', lineClass)
      .attr('fill', 'none')
      .attr('stroke-width', '1px')
      .attr('d', this.line((this.chartConfig.data as any)));
  }

  // Draw line at top of area chart
  createLimitLineChart() {
    if (!this.config.limit) return;

    const lineX = this.margin.left;
    const lineY = this.yScale(this.config.limit);

    const lineWidth = this.svgWidth - this.margin.left - this.margin.right;
    const lineHeight = 2;

    const gElem = this.svg
      .append('g')
      .attr('transform', `translate(${lineX}, ${lineY})`)
      .attr('class', 'limit-line');

    gElem
      .append('rect')
      .attr('width', lineWidth)
      .attr('height', lineHeight);

    if (this.config.limitPlaceholder) {
      const rectElm = gElem.append('rect');
      const textElm = gElem.append('text');

      textElm
        .attr('x', lineWidth / 2)
        .attr('y', -5)
        .attr('class', 'limit-tooltip-text')
        .style('text-anchor', 'middle')
        .text(this.config.limitPlaceholder);

      const textSizes = textElm.node().getBoundingClientRect();
      const textWidthWithPaddings = textSizes.width + 20;
      const startTooltipBgPosition = lineWidth / 2 - textWidthWithPaddings / 2;

      rectElm
        .attr('class', 'limit-tooltip-background')
        .attr('width', textWidthWithPaddings)
        .attr('x', startTooltipBgPosition);

    }
  }

  createDatapointCircles() {
    const circleSize = this.config.smallCircles ? 3 : 5;
    const circleHoverSize = this.config.smallCircles ? 4 : 7.5;
    const lineCircleClass = `line-circle ${this.config.gradientColor ? 'line-circle-' + this.config.gradientColor : ''}`;
    const circles = this.g
      .selectAll('circle')
      .data(this.chartConfig.data)
      .enter()
      .append('circle')
      .attr('class', lineCircleClass)
      .attr('r', circleSize)
      .attr('cx', d => this.xScale(d.date))
      .attr('cy', d => this.yScale(d.value));

    if (this.config.noHoverEffect) return;

    const that = this;
    circles.on('mouseover', function (e: MouseEvent, d) {
      // highlight circles
      d3.select(this)
        .attr('r', circleHoverSize)
        .attr('class', `${lineCircleClass} line-circle-hover`);
      // draw tooltip
      that.showDatapointTooltip(d);
    });

    circles.on('mouseout', function (e: MouseEvent, d) {
      // back to regular circles
      d3.select(this)
        .attr('r', circleSize)
        .attr('class', lineCircleClass);
      // hide tooltip
      if (that.datapointTooltip) {
        that.datapointTooltip.hide();
      }
    });
  }

  private createDatapointTooltip(): void {
    if (this.config.noTooltip) return;

    const tooltipConfig = new OpD3SvgTooltipConfig(
      this.datapointTooltipClass
    );
    this.datapointTooltip = new OpD3SvgTooltip(this.svg, tooltipConfig, tooltipGroup => {
      //place all tooltip elements to (0, 0), so later it could be moved to the right spot together with tooltip container
      this.datapointTooltipTextLabel = tooltipGroup
        .append('text')
        .attr('class', `${tooltipConfig.cssClass}-text-label`)
        .attr('x', tooltipConfig.width / 2)
        .attr('y', 0)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .attr('dy', tooltipConfig.padding);

      this.datapointTooltipTextValue = tooltipGroup
        .append('text')
        .attr('class', `${tooltipConfig.cssClass}-text-value`)
        .attr('x', tooltipConfig.width / 2)
        .attr('y', tooltipConfig.height / 2)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle');

      this.datapointTooltipTextDate = tooltipGroup
        .append('text')
        .attr('class', `${tooltipConfig.cssClass}-text-date`)
        .attr('x', tooltipConfig.width / 2)
        .attr('y', tooltipConfig.height)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'baseline')
        .attr('dy', -tooltipConfig.padding);

      return [
        this.datapointTooltipTextLabel,
        this.datapointTooltipTextValue,
        this.datapointTooltipTextDate
      ];
    }, );
  }

  // Display value above each data point in graph
  private createDatapointValues(): void {
    if (this.config.showTopValues) {
      this.svg
        .append('g')
        .attr('class', 'data-point-text-group')
        .selectAll('text')
        .data(this.chartConfig.data)
        .enter()
        .append('text')
        .text(d => VizUtilsService.formatChartNumbers(d.value))
        .attr('font-size', 11)
        .attr('fill', '#fff')
        .attr('x', d => this.xScale(d.date))
        .attr('y', d => this.yScale(d.value) - 10)
        .attr('text-anchor', 'middle');
    }
  }

  private showDatapointTooltip(d: IFullscreenChartDataFormatted): void {
    const x = this.xScale((d.date as Date));
    const y = this.yScale(d.value);

    this.datapointTooltipTextLabel
      .text(this.chartConfig.sideLabel?.toUpperCase());

    this.datapointTooltipTextValue
      .text(`${d.value}`);

    this.datapointTooltipTextDate
      .text(`${this.dateService.formatDate(new Date(d.date), EDateFormats.dateOne)}`);

    this.datapointTooltip.show(x, y, this.calculateScaleRanges());
  }

  createAreaChartGradient() {
    const gradient = this.svg
      .append('linearGradient')
      .attr('id', 'linear-gradient')
      .attr('gradientTransform', 'rotate(90)');

    gradient
      .append('stop')
      .attr('class', `area-chart-gradient-stop-one${this.config.gradientColor ? ('-' + this.config.gradientColor) : ''}`)
      .attr('offset', '0%');

    gradient
      .append('stop')
      .attr('class', `area-chart-gradient-stop-two${this.config.gradientColor ? ('-' + this.config.gradientColor) : ''}`)
      .attr('offset', '100%');
  }

  createSummaryLines() {
    if (this.chartConfig.showSummaryLines && this.chartConfig.summaryLines.length > 0) {
      this.chartConfig.summaryLines.forEach((summaryLine: ISummaryLine, index) => {
        let summaryLineTooltip = this.setupSummaryLineTooltip(summaryLine);

        // Draw summary line
        const horizontalLine = d3.line()
          .x((d: any) => this.xScale(d.date))
          .y(() => this.yScale(summaryLine.lineValue));

        this.svg.append('g').append('path')
          .attr('class', `area-chart-summary-line ${this.getSummaryLineMeaning(summaryLine)}`)
          .attr('fill', 'none')
          .attr('stroke-width', '3px')
          .attr('d', horizontalLine((this.chartConfig.data as any)));

        // Adding an additional, transparent line over the trend line that is wider
        // to give the user a larger hit box for mouse events
        this.svg.append('g').append('path')
          .attr('class', `area-chart-summary-tooltip-event-line}`)
          .attr('stroke', 'transparent')
          .attr('stroke-width', '10px')
          .attr('d', horizontalLine((this.chartConfig.data as any)))
          .on('mouseover', () => {
            summaryLineTooltip
              .transition()
              .duration(150)
              .style('opacity', 1)
              .style('display', 'block');
          })
          .on('mouseout', () => {
            summaryLineTooltip
              .transition()
              .duration(150)
              .style('opacity', 0)
              .style('display', 'none');
          })
          .on('mousemove', (event) => {
            const coords = d3.pointer(event);
            summaryLineTooltip.attr('transform', `translate(${coords[0]}, ${coords[1] - 20})`);
          });
      });
    }
  }

  getSummaryLineMeaning(summaryLine: ISummaryLine) {
    if (summaryLine.meaning === EChartChangeTypes.positive) {
      return 'positive';
    } else if (summaryLine.meaning === EChartChangeTypes.neutral) {
      return 'neutral';
    } else if (summaryLine.meaning === EChartChangeTypes.warning) {
      return 'warning';
    } else if (summaryLine.meaning === EChartChangeTypes.negative) {
      return 'negative';
    } else {
      return 'default';
    }
  }

  setupSummaryLineTooltip(summaryLine) {
    let summaryLineTooltip = this.svg
      .append('g')
      .attr('class', 'area-chart-summary-line-tooltip')
      .style('opacity', 0)
      .style('display', 'none');

    let summaryLineTooltipBox = summaryLineTooltip
      .append('rect')
      .attr('class', 'area-chart-tooltip-rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('dominant-baseline', 'middle');

    let summaryLineTooltipText = summaryLineTooltip
      .append('text')
      .attr('class', 'area-chart-tooltip-text')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .text(`${summaryLine.tooltipText}`);

    let tooltipBoundingClientRect = summaryLineTooltipText.node().getBoundingClientRect();
    let width = tooltipBoundingClientRect.width + 14;
    let height = tooltipBoundingClientRect.height + 5;

    summaryLineTooltipBox
      .style('width', width)
      .style('height', height)
      .attr('transform', `translate(${-(width / 2)}, ${-(height / 2)})`);

    return summaryLineTooltip;
  }
}
