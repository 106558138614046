import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'op-select-custom-option',
  templateUrl: './op-select-custom-option.component.html',
  styleUrls: ['./op-select-custom-option.component.scss']
})
export class OpSelectCustomOptionComponent {

  @Input() list: string[];
  @Input() disabled: boolean;
  @Output() onAdd: EventEmitter<string> = new EventEmitter();

  inputMode: boolean;
  model: string;
  ready: boolean;

  constructor() {
    this.init();
  }

  isUnique(variable: string): boolean {
    return variable && this.list ? !this.list.includes(variable) : false;
  }

  init() {
    this.inputMode = false;
    this.model = '';
    this.ready = false;
  }

  add() {
    this.onAdd.emit(this.model);
    this.model = '';
    this.ready = false;
  }

}
