
export const auditExports = {
  tagInventory: {
    name: 'Tag Inventory',
    description: 'Inventory of all marketing technologies and their accounts found in this audit/run. Empty cells are pages that did not have a given technology.',
  },
  tagMetadata: {
    name: 'Tag Metadata',
    description: 'Tag Load Time, Tag Status Code, Duplicates, Multiples & Versions',
  },
  cookiesInventory: {
    name: 'Cookie Inventory',
    description: 'Inventory of all cookie data found in this audit/run.',
  },
  browserConsoleLogs: {
    name: 'Browser Console Logs',
    description: 'Inventory of all browser console log messages found in this audit/run.',
  },
  requestLog: {
    name: 'Request Log',
    description: 'Inventory of all network requests that occurred during this audit/run.',
  },
  rules: {
    name: 'Rules',
    description: 'All rule results for this audit/run in a multi-tab Excel file.',
  },
  tagAndVariableData: {
    name: 'Tag and Variable Data',
    description: 'All collected data for selected tags.',
  },
  pageSummary: {
    name: 'Page Summary',
    description: 'Inventory of all page data found in this audit/run, including parent URL.'
  },
  brokenScannedPages: {
    name: 'Broken Link Details',
    description: 'Inventory of all link data for broken links and pages, includes: ' +
      'Pages Linking to URL, and outerHTML. Only the first one million broken links will be included in the export.'
  }
};
