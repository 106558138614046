import { Injectable } from '@angular/core';
import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ESharedLinksFilterTypes,
  ISharedLinksFilter,
  SharedLinksFilterStorageKey
} from '@app/components/shared-links/shared-links-library.constants';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';

@Injectable()
export class SharedLinksLibraryFilterBarService extends OpFilterBarService<ESharedLinksFilterTypes> {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService
  ) {
    super(storage, SharedLinksFilterStorageKey, cacheReset, new Map([]));
    this.updateSupportedFiltersList([
      ESharedLinksFilterTypes.DatasourceName,
      ESharedLinksFilterTypes.FolderName,
      ESharedLinksFilterTypes.ReportName,
      ESharedLinksFilterTypes.Disabled,
      ESharedLinksFilterTypes.NonExpiredOnly
    ]);
  }

  get filters$(): Observable<IOpFilterBarFilter<ESharedLinksFilterTypes>[]> {
    return this.anyFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters
          .filter((filter: ISharedLinksFilter) => this.validTypes.includes(filter.type))
          .reduce((acc, curr) => {
            acc.push(curr);
            return acc;
          }, [])
      )
    );
  }

  handleDataSourceNameContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: ESharedLinksFilterTypes.DatasourceName,
      display: `Data Source Name contains '${contains}'`,
      value: contains,
      order: 1
    });
  }

  handleReportNameContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: ESharedLinksFilterTypes.ReportName,
      display: `Report Name contains '${contains}'`,
      value: contains,
      order: 2
    });
  }

  handleFolderNameContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: ESharedLinksFilterTypes.FolderName,
      display: `Folder Name contains '${contains}'`,
      value: contains,
      order: 3
    });
  }

  handleNonExpiredOnlyFilter(onlyNonExpired: boolean) {
    this.addFilter({
      type: ESharedLinksFilterTypes.NonExpiredOnly,
      display: onlyNonExpired ? `Non Expired Only` : 'Expired Only',
      value: !onlyNonExpired,
      order: 4
    });
  }

  handleDisabledOnlyFilter(onlyDisabled: boolean) {
    this.addFilter({
      type: ESharedLinksFilterTypes.Disabled,
      display: !onlyDisabled ? `Disabled Only` : 'Enabled Only',
      value: !onlyDisabled,
      order: 5
    });
  }
}
