import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'op-modal-header',
  templateUrl: './op-modal-header.component.html',
  styleUrls: ['./op-modal-header.component.scss']
})
export class OpModalHeaderComponent {

  @Input() title: string;
  @Input() titleIcon: string;
  @Input() disabled?: boolean = false;
  @Output() onClose = new EventEmitter<void>();

  @Input() steps?: Array<string>;
  @Input() currentStep?: number;
  @Output() goToStep = new EventEmitter<number>();

}
