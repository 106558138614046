<div class="tag-database flex-col flex-stretch">
  <div class="toolbar flex-row">
    <span>{{tagsCount}} Tag{{tagsCount !== 1 ? 's' : ''}}</span>
    <div class="flex-row search-bar">
      <op-clearable-input placeholderText="Enter a value to search tags by..."
                          [emitKeyboardEvent]="true"
                          appearance="OUTLINED"
                          class="table-filter-input"
                          (onUserInput)="debounceFilterData($event)">
      </op-clearable-input>
    </div>
  </div>
  <div class="tag-database-table-wrapper">
    <table mat-table
           class="styled-mat-table tag-database-table"
           [dataSource]="dataSource"
           matSort
           multiTemplateDataRows
    >
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          ID
        </th>
        <td mat-cell *matCellDef="let row" valign="middle">
          {{row.id}}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </th>
        <td mat-cell *matCellDef="let row" valign="middle" [ngClass]="{'clickable': isOpAdmin}" (click)="viewTag(row)">
          <div>
            <img [src]="row?.icon"
                 [alt]="row?.name"
                 class="tag-icon"
                 loading="lazy" />
            {{row?.name}}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="numSignatures">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Signatures
        </th>
        <td mat-cell *matCellDef="let row" valign="middle">
          {{row.numSignatures}}
        </td>
      </ng-container>
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Category
        </th>
        <td mat-cell *matCellDef="let row" valign="middle">
          {{row.category}}
        </td>
      </ng-container>
      <ng-container matColumnDef="numVariables">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Variables
        </th>
        <td mat-cell *matCellDef="let row" valign="middle">
          {{row.numVariables}}
        </td>
      </ng-container>
      <tr mat-header-row
          class="tag-database-library-row"
          *matHeaderRowDef="displayedColumns; sticky: true;">
      </tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
    <div *ngIf="loading" class="spinner-wrapper">
      <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>
</div>
