import { Selection } from 'd3';

export interface ISvgScaleRanges {
  x: { start: number; end: number; };
  y: { start: number; end: number; };
}

export class OpD3SvgTooltipConfig {
  constructor(
    public cssClass: string,
    public width: number = 180,
    public height: number = 80,
    public padding: number = 8,
    public margin: number = 15
  ) {
  }
}

export class OpWebVitalsD3SvgTooltipConfig {
  constructor(
    public cssClass: string,
    public width: number = 200,
    public height: number = 75,
    public padding: number = 8,
    public margin: number = 15
  ) {
  }
}

export class OpD3SvgTooltip {

  public renderedCssClasses = {
    group: `${this.config.cssClass}-group`,
    rect: this.config.cssClass
  };

  public rendered: {
    group: Selection<any, any, any, any>,
    rect: Selection<any, any, any, any>,
    content: Selection<any, any, any, any>[]
  } = {
    group: undefined,
    rect: undefined,
    content: undefined
  };

  constructor(
    private tooltipContainer: Selection<any, any, any, any>,
    public config: OpD3SvgTooltipConfig,
    private createContent: (tooltipGroup: Selection<any, any, any, any>) => Selection<any, any, any, any>[]
  ) {
    this.create(createContent);
  }

  private create(
    createContent: (tooltipGroup: Selection<any, any, any, any>) => Selection<any, any, any, any>[]
  ): void {
    this.rendered.group = this.tooltipContainer
      .append('g')
      .attr('class', this.renderedCssClasses.group)
      .style('opacity', 0)
      .style('display', 'none');

    this.rendered.rect = this.rendered.group
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('class', this.renderedCssClasses.rect)
      .style('width', `${this.config.width}px`)
      .style('height', `${this.config.height}px`)
      .attr('rx', 4)
      .attr('ry', 4);

    this.rendered.content = createContent(this.rendered.group);
  }

  private moveTo(x: number, y: number): void {
    this.rendered.group.attr(`transform`, `translate(${x}, ${y})`);
  }

  show(x: number, y: number, scaleRanges: ISvgScaleRanges): void {
    const defaultMargin = this.config.margin;

    let tx = x - this.config.width / 2;
    let ty = y - this.config.height - defaultMargin;

    const halfWidthWithMargin = this.config.width / 2 + defaultMargin;
    const minHeightWithMargin = this.config.height + defaultMargin;

    if (x - scaleRanges.x.start < halfWidthWithMargin) { // too close to left border
      tx = scaleRanges.x.start + defaultMargin;
    } else if (scaleRanges.x.end - x < halfWidthWithMargin) { // too close to right border
      tx = scaleRanges.x.end - this.config.width - defaultMargin;
    }

    if (y - scaleRanges.y.start < minHeightWithMargin) { // too close to top border
      ty = y + defaultMargin;
    }

    this.moveTo(tx, ty);

    this.rendered.group
      .style('display', 'flex')
      .transition()
      .duration(150)
      .style('opacity', 1);
  }

  hide(): void {
    this.rendered.group
      .style('opacity', 0)
      .style('display', 'none');
  }
}
