import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-splash-page-basic',
  templateUrl: './cl-splash-page-basic.component.html',
  styleUrls: ['./cl-splash-page-basic.component.scss']
})
export class ComponentLibrarySplashPageBasicComponent {
}
