import {
  EDateFormats,
  formatDate,
  formatToUTC,
  getTimeZoneOffset,
  parseDate,
  timeZoneAdjustmentToTimeString,
} from '../date/date.service';

export interface IBlackoutPeriodModel {
  start: string;
  end: string;
}

/**
 * Converts API model to UI model
 * For example, '15:00:00+0000' -> '09:00 AM' (user's timezone -06:00)
 */
export function blackoutToUIModel(blackoutPeriod: IBlackoutPeriodModel, timezone: string, format24Hour: boolean = true): IBlackoutPeriodModel | null {
  if (!blackoutPeriod) return null;

  let startDate: Date = parseDate(blackoutPeriod.start, EDateFormats.timeFive);
  startDate = parseDate(formatToUTC(startDate, EDateFormats.timeFour), EDateFormats.timeFour);

  const start: string = formatDate(new Date(startDate.toLocaleString('en-US', {timeZone: timezone})), EDateFormats.timeTwo);

  let endDate: Date = parseDate(blackoutPeriod.end, EDateFormats.timeFive);
  endDate = parseDate(formatToUTC(endDate, EDateFormats.timeFour), EDateFormats.timeFour);

  const end: string = formatDate(new Date(endDate.toLocaleString('en-US', {timeZone: timezone})), EDateFormats.timeTwo);

  return { start, end };
}

/**
 Apply the offset for the provided Timezone to the original date..
 if it is -0500, then subtract 5 hours from start and end.
 If it is +0200, then add 2 hours to start and end
 * @param blackoutPeriod
 * @param timezone
 * @param format24Hour
 * @param longForm - Audits use blackout time form like: '06:30:00+05:00',
 * while Journeys use '10:30Z'. This flag is used to format correctly depending on the format
*/
export function blackoutToUIModelWithOffset(blackoutPeriod: IBlackoutPeriodModel, timezone: string, format24Hour: boolean = true, longForm: boolean = false): IBlackoutPeriodModel | null {
  if (!blackoutPeriod || !blackoutPeriod.start || !blackoutPeriod.end) return null;

  let parsedStartTime = blackoutPeriod.start.replace('Z', '');
  let parsedEndTime = blackoutPeriod.end.replace('Z', '');

  if (longForm) {
    let startTimeParts = blackoutPeriod.start.split(':');
    let endTimeParts = blackoutPeriod.end.split(':');
    parsedStartTime = `${startTimeParts[0]}:${startTimeParts[1]}`;
    parsedEndTime = `${endTimeParts[0]}:${endTimeParts[1]}`;
  }

  const start = timeZoneAdjustmentToTimeString(parsedStartTime, timezone, format24Hour);
  const end = timeZoneAdjustmentToTimeString(parsedEndTime, timezone, format24Hour);

  return {
    start,
    end,
  };
}

/**
 *  Converts UI model for Audits to API model
 * For example, given a blackoutPeriod of {start: '03:17:00', end: '06:30:00'} and a timezone of 'Asia/Karachi' (+05:00),
 * the end value would be {start: '03:17:00+05:00', end: '06:30:00+05:00'}
 * @param blackoutPeriod
 * @param timezone: IANA timezone string, i.e. 'America/Denver'
 */
export function blackoutToApiModel(blackoutPeriod: IBlackoutPeriodModel, timezone: string): IBlackoutPeriodModel {
  const { start: startUTCString, end: endUTCString } = blackoutPeriod;

  const start = startUTCString + getTimeZoneOffset(timezone);
  const end = endUTCString + getTimeZoneOffset(timezone);

  return { start, end };
}
