export class UseCaseUtils {
  static roundAndAppend(value: string): string {
    let abbreviatedNumber = '';
    let valueAsNumber: number = +value.replace(/,/g, '');

    if (valueAsNumber >= 1000000000) { // Billion
      abbreviatedNumber = `${(Math.round(valueAsNumber / 10000000) / 100).toFixed(1)}B`;
    } else if (valueAsNumber >= 1000000) { // Million
      abbreviatedNumber = `${(Math.round(valueAsNumber / 10000) / 100).toFixed(1)}M`;
    } else if (valueAsNumber >= 1000) { // Thousand
      abbreviatedNumber = `${(Math.round(valueAsNumber / 10) / 100).toFixed(1)}K`;
    } else { // Less than a Thousand
      abbreviatedNumber = value;
    }

    return abbreviatedNumber;
  }

  static buildCustomBottomStatsContent(
    median: string,
    avg: string,
    min: string,
    max: string,
  ): string {

    return `
      <div class="bottom-left-custom-content">
        <div class="bottom-left-custom-header">
          ObservePoint CUSTOMER
        </div>
        <div class="bottom-left-custom-content-stats-section">
          <div class="bottom-left-custom-content-stat stat-median">
            <div class="bottom-left-custom-content-header">MEDIAN</div>
            <div class="bottom-left-custom-content-value">${this.roundAndAppend(median)}</div>
          </div>
          <div class="bottom-left-custom-content-stat stat-avg">
            <div class="bottom-left-custom-content-header">AVG</div>
            <div class="bottom-left-custom-content-value">${this.roundAndAppend(avg)}</div>
          </div>
          <div class="bottom-left-custom-content-stat stat-min">
            <div class="bottom-left-custom-content-header">MIN</div>
            <div class="bottom-left-custom-content-value">${this.roundAndAppend(min)}</div>
          </div>
          <div class="bottom-left-custom-content-stat stat-max">
            <div class="bottom-left-custom-content-header">MAX</div>
            <div class="bottom-left-custom-content-value">${this.roundAndAppend(max)}</div>
          </div>
        </div>
      </div>
    `;
  }
}
