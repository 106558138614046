import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { environment } from '@app/environments/environment';
import { ShareLinksApiUrl } from '@app/components/share-links/share-links.constants';

@Injectable()
export class AuthRequestInterceptor implements HttpInterceptor {
  apiHost: string = environment.apiHost;
  apiUploadAppHost: string = environment.apiUploadAppUrl;
  gatewayHost: string = environment.gatewayHost;

  constructor (private authenticationStorage: AuthenticationStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      request.url.indexOf(this.apiHost) < 0 &&
      request.url.indexOf(this.gatewayHost) < 0 &&
      request.url.indexOf(this.apiUploadAppHost) &&
      request.url.indexOf(ShareLinksApiUrl.sharedLinkUrl) < 0
    ) {
      return next.handle(request);
    }
    
    // Get the auth token from the service.
    const authorizationData = this.authenticationStorage.get();

    // send cloned request with header to the next handler.
    const authReq = authorizationData ? this.setAuthToken(request, authorizationData.token) : request;

    return next.handle(authReq);
  }

  /**
   * Clone the request and replace the original headers with cloned headers, updated with the authorization.
   */
  private setAuthToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }

}
