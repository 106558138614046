import { Route } from '@angular/router';
import { OpAdminOnlyGuard } from '../core/permissions.guard';
import { SetCookiesComponent } from './set-cookies.component';
import { SetCookiesPaths, SetCookiesStateNames } from './set-cookies.constants';

export const SetCookiesFutureState: Route = {
  path: SetCookiesPaths.base,
  loadChildren: () => import('./set-cookies.module').then(mod => mod.SetCookiesModule),
};

export const SetCookiesRoutes: Route = {
  path: '',
  canLoad: [ OpAdminOnlyGuard ],
  component: SetCookiesComponent,
  data: {
    stateName: SetCookiesStateNames.setCookies,
    title: 'Set Cookies'
  }
}
