<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="dialogRef.close()" class="cc-edit-modal-contents">
  <ng-template #headerTemplate>
    <op-modal-header (onClose)="dialogRef.close()" title="TAG AND VARIABLE DATA"></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="vendors-export">
      <label class="instructions">Export all collected data for selected tags.</label>
      <div class="tag-search">
        <op-clearable-input placeholderText="Search tags by name"
                            class="table-filter-input"
                            (onUserInput)="filterTags($event)"
        >
        </op-clearable-input>
      </div>

      <div class="table-container" [class.loading]="!tagSummaryData">
        <table mat-table
               matSort
               [dataSource]="tableDataSource"
               multiTemplateDataRows
               matSortDirection="desc"
               matSortStart="desc"
        >
          <ng-container matColumnDef="select-all">
            <th mat-header-cell *matHeaderCellDef
                [attr.colspan]="displayedColumns.length">
              <mat-checkbox (change)="onSelectAll($event.checked)"
                            [checked]="tagSummaryData?.length === selectedTags?.length"
                            [indeterminate]="!!selectedTags?.length && tagSummaryData?.length !== selectedTags?.length"
                            color="primary"
                            aria-label="Select all tags">
                Select All ({{tagSummaryData?.length || 0}})
              </mat-checkbox>
            </th>
          </ng-container>
          <ng-container matColumnDef="tagName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>TAG NAME</th>
            <td mat-cell *matCellDef="let tag">
              <mat-checkbox (change)="onTagSelected($event.checked, tag)"
                            [checked]="tag.selected"
                            color="primary">
                <img class="tag-icon" alt="{{tag.name}}" src="{{ tag.icon }}" />
                <div class="tag-name" [title]="tag.name">{{ tag.name }}</div>
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="tagAccounts">
            <th mat-header-cell *matHeaderCellDef>TAG ACCOUNT</th>
            <td mat-cell *matCellDef="let tag">
              <ng-container *ngIf="tag.accounts.length <= MAX_AVAILABLE__ACCOUNTS_FOR_SELECTION else exceededTag">
                <label *ngFor="let account of tag.accounts"
                       class="account-label"
                       [class.selected]="isAccountSelected(account, tag)"
                       (click)="onAccountSelected(account, tag)">{{account}} <mat-icon>close</mat-icon>
                </label>
              </ng-container>
              <ng-template #exceededTag>
                <span class="exceeded-tag-label">More than 100 accounts, all will be exported.</span>
              </ng-template>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;">
          </tr>
          <tr mat-header-row *matHeaderRowDef="['select-all']">
          </tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              class="cc-source-table-row"></tr>
        </table>
        <div class="loader">
          <mat-spinner class="spinner" [diameter]="170" [strokeWidth]="10"></mat-spinner>
        </div>
      </div>
    </div>
  </ng-template>
</op-modal>
