import { Injectable, OnDestroy } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { Observable, Subject } from 'rxjs';
import { ITagInventorySummary, ITagInventoryTrends, ITagInventoryTrendByName, ITagInventoryTagAccounts, ITagInventoryQueryParams, ITagInventoryPages, ITagInventoryCategories, ITagInventoryTags } from './tag-inventory.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { takeUntil } from 'rxjs/operators';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class TagInventoryService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,

    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTagInventorySummary(auditId: number, runId: number, body: IAuditReportApiPostBody): Observable<ITagInventorySummary> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-inventory`;
    return this.apiService.post(`${baseRequestUrl}`, body);
  }

  getTagInventoryTrends(auditId: number, runId: number): Observable<ITagInventoryTrends> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-inventory/trends`);
  }

  getTagInventoryTrendByName(auditId: number, trendName: string, dayCount: number): Observable<ITagInventoryTrendByName> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/reports/tag-inventory/trends/${trendName}?days=${dayCount}`);
  }

  @CacheApiResponse()
  getTagInventoryCategories(auditId: number, runId: number, body: IAuditReportApiPostBody): Observable<ITagInventoryCategories> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-inventory/tag-categories`;
    return this.apiService.post(`${baseRequestUrl}`, body);
  }

  @CacheApiResponse()
  getTagInventoryTags(auditId: number, runId: number, body: IAuditReportApiPostBody): Observable<ITagInventoryTags> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-inventory/tags`;
    return this.apiService.post(`${baseRequestUrl}`, body);
  }

  getTagInventoryTagAccounts(auditId: number, runId: number, tagId: number, body: IAuditReportApiPostBody): Observable<ITagInventoryTagAccounts> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-inventory/tags/${tagId}/accounts`;
    return this.apiService.post(`${baseRequestUrl}`, body);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getTagInventoryPages(auditId: number, runId: number, params: ITagInventoryQueryParams, body: IAuditReportApiPostBody): Observable<ITagInventoryPages> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-inventory/pages`;
    const queryParams = `?page=${params.page}&size=${params.size}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`;
    return this.apiService.post(`${baseRequestUrl}${queryParams}`, body);
  }
}
