<div class="flex-col flex-stretch inside-table-wrap">
  <div *ngIf="loading" class="inside-table-loader">
    <mat-spinner [diameter]="40" [strokeWidth]="3"></mat-spinner>
  </div>
  <ng-container *ngIf="!loading">
    <div class="headers">
      <div class="audit-name">
        {{ reportType === CONSTANTS.auditType ? CONSTANTS.ccRowTable.auditName : CONSTANTS.ccRowTable.journeyName }}
      </div>
      <div class="created-by">
        <span>{{CONSTANTS.ccRowTable.createdBy}}</span>
      </div>
      <div class="last-run">
        <span>{{CONSTANTS.ccRowTable.lastRun}}</span>
      </div>
      <div class="unassign"></div>
    </div>
    <div class="table-container">
      <table class="inside-table mat-table">
        <tbody>
        <tr *ngFor="let item of dataSource.data" class="mat-row">
          <td class="mat-cell truncated"
              (click)="gotoReport($event, item)"
              [matTooltipDisabled]="reportType === CONSTANTS.auditType ? item.auditName.length < 30 : item.webJourneyName.length < 30"
              matTooltip="{{item.auditName}}"
              matTooltipPosition="above"
          >
            <a class="hover-link">
              {{ reportType === CONSTANTS.auditType ? item.auditName : item.webJourneyName }}
            </a>
          </td>
          <td class="mat-cell">{{users[item.createdBy]?.firstName}} {{users[item.createdBy]?.lastName}}</td>
          <td class="mat-cell">{{ item.lastRun }}</td>
          <td class="mat-cell">
            <mat-icon matTooltip="Unassign audit"
                      (click)="unassignReport($event, item)"
                      matTooltipShowDelay="500"
                      matTooltipPosition="above">
              remove_circle_outline
            </mat-icon>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>
