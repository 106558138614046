import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpModalModule } from '@app/components/shared/components/op-modal/op-modal.module';
import { SharedModule } from '@app/components/shared/shared.module';
import { MaterialModule } from '@app/components/material/material.module';
import { InvalidUrlsSnackbarComponent } from '@app/components/invalid-urls-snackbar/invalid-urls-snackbar.component';
import { InvalidUrlsSnackbarService } from '@app/components/invalid-urls-snackbar/invalid-urls-snackbar.service';

@NgModule({
  declarations: [
    InvalidUrlsSnackbarComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    OpModalModule,
  ],
  providers: [
    InvalidUrlsSnackbarService
  ]
})
export class InvalidUrlsSnackbarModule {
}
