import {IWebJourneyRun} from '../webJourneyDefinitions';

export interface IWebJourneyResultsSerializer {
    serializeJourneyLastRun(json: Array<IWebJourneyRun>): IWebJourneyRun;
  }

  export class WebJourneyResultsSerializer implements IWebJourneyResultsSerializer {

    serializeJourneyLastRun(json: Array<IWebJourneyRun>): IWebJourneyRun {
      var filteredRuns: Array<IWebJourneyRun> = json.filter((run: IWebJourneyRun) => run.completedAt != null);
      if (filteredRuns.length > 0) {
        return filteredRuns[0];
      }
      throw new Error('journeyInitiated');
    }
}

