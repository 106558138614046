import { CacheControls } from '@app/components/core/decorators/global-decorators.models';

export function CachePromise<T extends any>() {
  let savedReturns = new Map;

  return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod: (...args) => Promise<T> = descriptor.value;

    descriptor.value = function (...args) {
      const key = JSON.stringify(args);
      let value = savedReturns.get(key);

      if (!value) {
        return originalMethod.apply(this, args).then(v => {
          savedReturns.set(key, v);
          return v;
        });
      }

      return Promise.resolve(value);
    };

    descriptor.value.cacheControls = new CacheControls(savedReturns);
  };
}
