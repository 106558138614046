import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EUseCaseNavToKeys,
  EUseCaseWidgetType,
  IAuditUseCaseData,
  IDataLayerInfo,
  IUseCaseSection,
  IUseCaseWidget
} from '@app/components/audit-reports/use-cases/use-cases.models';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import {
  AnalyticsPagesScannedWidget,
  AnalyticsTagsSection,
  BrokenTagRequestsWidget,
  DataLayerCoverageWidget,
  DuplicateTagRequestsWidget,
  TagAndVariableRuleFailuresWidget,
  TagRequestsWidget,
  TopAnalyticsWidget,
  TopTagManagerWidget,
  UniqueAnalyticsTagsWidget,
  UniqueTagVariablesWidget,
  UniqueTagVariableValuesWidget
} from '@app/components/audit-reports/use-cases/use-case-analytics/use-case-analytics.constants';
import { UseCaseService } from '@app/components/audit-reports/use-cases/use-case.service';
import { IUser } from '@app/moonbeamModels';
import { ESplitCardChangeMeaning } from '@app/components/shared/components/split-card/split-card.models';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { userIsGuest } from '@app/authUtils';
import { IPageSummaryInsights } from '@app/components/audit-reports/reports/page-summary/page-summary.models';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { EAuditReportFilterTypes } from '../../audit-report-filter-bar/audit-report-filter-bar.models';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { OP_SELECTORS } from '@app/components/audit-reports/use-cases/use-cases.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'use-case-analytics',
  templateUrl: './use-case-analytics.component.html',
  styleUrls: ['./use-case-analytics.component.scss']
})
export class UseCaseAnalyticsComponent implements OnInit, OnDestroy {
  CONSTANTS = { ...OP_SELECTORS };
  EAccountType = EAccountType;
  destroy$ = new Subject();
  accountType: EAccountType|null;
  isVisitorMode: boolean;
  auditInfo: IAuditUseCaseData;
  addDataLayerBtn;
  viewDataLayerReportBtn;
  pageInfo: IPageSummaryInsights;
  dataLayerInfo: IDataLayerInfo;
  analyticsTagsInfo: any;
  tagHealthInfo: any;
  duplicateAndMultiplesInfo: any;
  tagAndVariableInfo: any;
  uniqueTagVariableInfo: any;
  uniqueAnalyticsDataLoaded: boolean = false;
  openAuditEventListener;
  user: IUser;
  isReadOnly: boolean;
  dataLayerHasListener: boolean = false;

  analyticsTagsSection: IUseCaseSection = {
    sectionIcon: AnalyticsTagsSection.sectionIcon,
    customIcon: true,
    sectionTitle: AnalyticsTagsSection.sectionTitle,
  };

  pagesScannedWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.PAGES_SCANNED,
    loading: true,
    splitCard: true,
    topTooltip: AnalyticsPagesScannedWidget.topTooltip,
    topText: AnalyticsPagesScannedWidget.topText,
    topValue: '---',
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bottomText: AnalyticsPagesScannedWidget.bottomText,
    bottomValue: 0,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.PageSummary),
    bodyContent: {
      bodyText: '',
      navToText: AnalyticsPagesScannedWidget.bodyContent.navToText,
    }
  };

  uniqueAnalyticsTagsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNIQUE_TAGS,
    loading: true,
    splitCard: true,
    topTooltip: UniqueAnalyticsTagsWidget.topTooltip,
    chartData: [],
    topText: UniqueAnalyticsTagsWidget.topText,
    topValue: 5,
    topClickAction: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.TagInventoryAnalytics),
    navTo: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.TagInventoryAnalytics),
    bottomLeftCustomContent: UniqueAnalyticsTagsWidget.bottomLeftCustomContent,
    bodyContent: {
      bodyText: '',
      navToText: UniqueAnalyticsTagsWidget.bodyContent.navToText,
    }
  };

  topAnalyticsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.TOP_ANALYTICS,
    loading: true,
    splitCard: true,
    topTooltip: TopAnalyticsWidget.topTooltip,
    topText: TopAnalyticsWidget.topText,
    topValue: 0,
    topValueMeaning: ESplitCardChangeMeaning.POSITIVE,
    bottomText: TopAnalyticsWidget.bottomText,
    bottomValue: TopAnalyticsWidget.bottomValue,
    navTo: () => () => {},
    bodyContent: {
      bodyText: '',
      navToText: TopAnalyticsWidget.bodyContent.navToText,
    }
  };

  topTagManagerWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.TOP_TAG_MANAGER,
    loading: true,
    splitCard: true,
    topTooltip: TopTagManagerWidget.topTooltip,
    topText: TopTagManagerWidget.topText,
    topValue: 0,
    bottomText: TopTagManagerWidget.bottomText,
    bottomValue: TopTagManagerWidget.bottomValue,
    navTo: () => () => {},
    bodyContent: {
      bodyText: '',
      navToText: TopTagManagerWidget.bodyContent.navToText,
    }
  };

  tagRequestsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.ANALYTICS_TAG_REQUESTS,
    loading: true,
    splitCard: true,
    topTooltip: TagRequestsWidget.topTooltip,
    topText: TagRequestsWidget.topText,
    topValue: 0,
    topClickAction: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.TagInventorySlowLoadingTags),
    statsData: TagRequestsWidget.statsData,
    bottomLeftCustomContent: TagRequestsWidget.bottomLeftCustomContent,
    navTo: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.TagInventorySlowLoadingTags),
    bodyContent: {
      bodyText: '',
      navToText: TagRequestsWidget.bodyContent.navToText,
    }
  };

  brokenTagRequestsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.BROKEN_TAG_REQUESTS,
    loading: true,
    splitCard: true,
    topTooltip: BrokenTagRequestsWidget.topTooltip,
    topText: BrokenTagRequestsWidget.topText,
    topValue: 0,
    topClickAction: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.TagInventoryBrokenTags),
    bottomText: BrokenTagRequestsWidget.bottomText,
    bottomValue: BrokenTagRequestsWidget.bottomValue,
    navTo: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.TagInventoryBrokenTags),
    bodyContent: {
      bodyText: '',
      navToText: BrokenTagRequestsWidget.bodyContent.navToText,
    }
  };

  duplicateTagRequestsWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.DUPLICATE_ANALYTICS_TAG_REQUESTS,
    loading: true,
    splitCard: true,
    topTooltip: DuplicateTagRequestsWidget.topTooltip,
    topIconName: 'error',
    topText: DuplicateTagRequestsWidget.topText,
    topValue: 0,
    topClickAction: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.TagInventoryDuplicateTags),
    statsData: DuplicateTagRequestsWidget.statsData,
    bottomLeftCustomContent: DuplicateTagRequestsWidget.bottomLeftCustomContent,
    navTo: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.TagInventoryDuplicateTags),
    bodyContent: {
      bodyText: '',
      navToText: DuplicateTagRequestsWidget.bodyContent.navToText,
    }
  };

  tagAndVariableRuleFailuresWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.TAG_AND_VARIABLE_RULE_FAILURES,
    loading: true,
    splitCard: true,
    topTooltip: TagAndVariableRuleFailuresWidget.topTooltip,
    topText: TagAndVariableRuleFailuresWidget.topText,
    topValue: 0,
    topClickAction: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryRuleFailures),
    bottomText: TagAndVariableRuleFailuresWidget.bottomText,
    bottomValue: TagAndVariableRuleFailuresWidget.bottomValue,
    navTo: () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryRuleFailures),
    bodyContent: {
      bodyText: TagAndVariableRuleFailuresWidget.bodyContent.bodyText.paid,
      navToText: TagAndVariableRuleFailuresWidget.bodyContent.navToText,
    }
  };

  uniqueTagVariablesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNIQUE_ANALYTICS_TAG_VARIABLES,
    loading: true,
    splitCard: true,
    topTooltip: UniqueTagVariablesWidget.topTooltip,
    topText: UniqueTagVariablesWidget.topText,
    topValue: 0,
    topClickAction: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.UniqueTagVariables),
    statsData: UniqueTagVariablesWidget.statsData,
    bottomLeftCustomContent: UniqueTagVariablesWidget.bottomLeftCustomContent,
    navTo: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.UniqueTagVariables),
    bodyContent: {
      bodyText: UniqueTagVariablesWidget.bodyContent.bodyText.paid,
      navToText: UniqueTagVariablesWidget.bodyContent.navToText,
    }
  };

  uniqueTagVariableValuesWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.UNIQUE_ANALYTICS_TAG_VALUES,
    loading: true,
    splitCard: true,
    topTooltip: UniqueTagVariableValuesWidget.topTooltip,
    topText: UniqueTagVariableValuesWidget.topText,
    topValue: 0,
    topClickAction: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.UniqueTagVariableValues),
    statsData: UniqueTagVariableValuesWidget.statsData,
    bottomLeftCustomContent: UniqueTagVariableValuesWidget.bottomLeftCustomContent,
    navTo: () => this.navToReportWithWebAnalyticsFilter(EUseCaseNavToKeys.UniqueTagVariableValues),
    bodyContent: {
      bodyText: UniqueTagVariableValuesWidget.bodyContent.bodyText.paid,
      navToText: UniqueTagVariableValuesWidget.bodyContent.navToText,
    }
  };

  dataLayerCoverageWidget: IUseCaseWidget = {
    opSelector: this.CONSTANTS.DATA_LAYER_COVERAGE,
    topText: DataLayerCoverageWidget.topText,
    topTooltip: DataLayerCoverageWidget.topTooltip,
    bottomText: DataLayerCoverageWidget.bottomText,
    bottomValue: DataLayerCoverageWidget.bottomValue,
    bodyContent: {
      bodyText: ''
    },
    bannerText: '',
    bannerColor: DataLayerCoverageWidget.bannerColor,
    bannerClickAction: () => {},
  };

  constructor(
    private useCaseService: UseCaseService,
  ) {}

  ngOnInit(): void {
    this.registerListeners();

    if (!this.useCaseService.isAnalyticsDataLoaded) {
      this.useCaseService.loadAnalytics();
    }

  }

  registerListeners() {
    combineLatest([
      this.useCaseService.accountType$,
      this.useCaseService.isVisitorMode$,
      this.useCaseService.user$,
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([accountType, isVisitorMode, user]) => {
      this.user = user;
      this.accountType = accountType;
      this.isVisitorMode = isVisitorMode;
      this.isReadOnly = userIsGuest(user) || isVisitorMode;

      this.useCaseService.dataLayerPages$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((dataLayerInfo) => {
        if (dataLayerInfo !== null) {
          this.dataLayerInfo = dataLayerInfo;

          this.updateDataLayerWidget();
        }
      });

      this.useCaseService.audit$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((auditInfo) => {
          if (auditInfo !== null) {
            this.auditInfo = auditInfo;
            this.updatePagesScannedWidget();
          }
        });

      this.useCaseService.pageSummary$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((pageInfo) => {
          if (pageInfo !== null) {
            this.pageInfo = pageInfo;
            this.updatePagesScannedWidget();
          }
        });

      this.useCaseService.analyticsTags$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((analyticsTagsInfo) => {
          if (analyticsTagsInfo !== null) {
            this.analyticsTagsInfo = analyticsTagsInfo;

            this.updateTagsWidgetValues(); // UniqueAnalyticsTags, BrokenTagRequests
          }
        });

      this.useCaseService.analyticsTagsHealth$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((tagHealthInfo) => {
          if (tagHealthInfo !== null) {
            this.tagHealthInfo = tagHealthInfo;

            this.updateTagHealthWidgetValues();
          }
        });

      this.useCaseService.analyticsTagsDuplicatesAndMultiples$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((duplicatesAndMultiplesInfo) => {
          if (duplicatesAndMultiplesInfo !== null) {
            this.duplicateAndMultiplesInfo = duplicatesAndMultiplesInfo;

            this.updateDuplicateTagRequestsWidget();
          }
      });

      this.useCaseService.tagAndVariable$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((tagAndVariableInfo) => {
          if (tagAndVariableInfo !== null) {
            this.tagAndVariableInfo = tagAndVariableInfo;

            this.updateTagAndVariableRuleFailuresWidget();
          }
      });

      this.useCaseService.variableInventory$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((variableInfo) => {
          if (variableInfo !== null) {
            this.uniqueTagVariableInfo = variableInfo;

            this.updateUniqueTagVariablesWidget();
            this.updateUniqueTagVariableValuesWidget();
          }
      });
    });

  }

  ngOnDestroy() {
    if (this.openAuditEventListener) this.openAuditEventListener.removeEventListener();
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateTagsWidgetValues(): void {
    this.updateUniqueAnalyticsTagsWidget();
    this.updateTopAnalyticsWidget();
    this.updateTopTagManagerWidget();
  }

  updateTagHealthWidgetValues(): void {
    this.updateBrokenTagRequestsWidget();
    this.updateTagRequestsWidget();
  }

  updatePagesScannedWidget(): void {
    const widget = this.pagesScannedWidget;

    widget.topValue = this.pageInfo?.totalPages?.toLocaleString() || '---';
    widget.bottomValue = this.auditInfo?.domain;
    widget.bodyContent.bodyText = `<div class="domain-block"><span class="domain-block-label">DOMAIN AUDITED: </span><span class="domain-block-value">${this.auditInfo?.domain}</span></div><div>${this.useCaseService.getBodyText(EUseCaseWidgetType.AnalyticsPagesScanned)}</div>`;
    widget.loading = false;
  }

  updateDataLayerWidget(): void {
    const widget = this.dataLayerCoverageWidget;
    const dataLayerPagesPercent: number = parseInt(this.dataLayerInfo?.dataLayerPagesPercent);

    widget.topValue = dataLayerPagesPercent === 0 ? '<span class="small-value">Not configured</span>' : `${dataLayerPagesPercent}%`;

    if (this.accountType === EAccountType.SAMPLE) {
      widget.bannerText = 'Unlock this feature by starting a free trial';
      widget.bannerColor = DataLayerCoverageWidget.bannerColor;
      widget.bodyContent.bodyText = DataLayerCoverageWidget.bodyContent.bodyText.guest;
      widget.skipNavigationConfirm = true;
      widget.bannerClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.SampleAccount);
    } else {
      widget.bodyContent.bodyText = this.isReadOnly
        ? `For most sites, Data Layer is the source of truth for analytics implementations and it’s important to verify that values are feeding into your analytics correctly.`
        : `For most sites, Data Layer is the source of truth for analytics implementations and it’s important to verify that values are feeding into your analytics correctly.<br><br>`;

      if (!this.isReadOnly) {
        setTimeout(() => {
          this.addDataLayerBtn = document.querySelector('.use-case-analytics-data-layer-clickable-link-not-configured');
          this.viewDataLayerReportBtn = document.querySelector('.use-case-analytics-data-layer-clickable-link-configured');

          if (!this.dataLayerHasListener) {
            if (this.addDataLayerBtn) this.addDataLayerBtn.addEventListener('click', this.dataLayerHandler.bind(this), true);
            if (this.viewDataLayerReportBtn) this.viewDataLayerReportBtn.addEventListener('click', this.viewDataLayerHandler.bind(this), true);
            this.dataLayerHasListener = true;
          }
        });

        if (dataLayerPagesPercent > 0) {
          widget.bodyContent.bodyText += '<span class="use-case-analytics-data-layer-clickable-link-configured use-case-clickable-link">View pages missing your Data Layer</span>.';
          widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryDataLayer);
        } else {
          widget.bodyContent.bodyText += '<span class="use-case-analytics-data-layer-clickable-link-not-configured use-case-clickable-link">Add your data layer object to see its coverage in the next run</span>.';
        }
      }
    }

    widget.loading = false;
  }

  dataLayerHandler() {
    this.useCaseService.openDataLayerEditor(this.dataLayerInfo.domain);
  }

  viewDataLayerHandler() {
    this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryDataLayer);
  }

  updateUniqueAnalyticsTagsWidget(): void {
    const uniqueTags = this.analyticsTagsInfo?.uniqueAnalyticsTagCount?.toLocaleString()
      || 0;
    const chartData = this.analyticsTagsInfo?.uniqueAnalyticsChartData || [];

    const widget = this.uniqueAnalyticsTagsWidget;

    widget.topValue = uniqueTags;
    widget.chartData = chartData;
    widget.statsData = UniqueAnalyticsTagsWidget.statsData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.UniqueAnalytics);
    widget.loading = false;

    this.uniqueAnalyticsDataLoaded = true;
  }

  updateTopAnalyticsWidget(): void {
    const widget = this.topAnalyticsWidget;
    const topAnalyticsTag = this.analyticsTagsInfo.topAnalyticsTag || {};
    const topAnalyticsTagName = topAnalyticsTag.tagName || '---';
    const topAnalyticsPercentOfPages = Math.round(topAnalyticsTag.tagPresentPageCount / this.analyticsTagsInfo.pageCountTotal * 100) || 0;
    const bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.TopAnalytics);

    widget.topText = `<div class="top-multi-text-label">Top Analytics: </div>`;
    if (topAnalyticsPercentOfPages > 0) {
      widget.topText += `<div>${topAnalyticsTagName}</div>`;
      widget.navTo = widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryMissingTag, topAnalyticsTag);
      widget.topTooltip = `${widget.topTooltip} Click to view additional details.`;
    }

    widget.topValue = `${topAnalyticsPercentOfPages ? topAnalyticsPercentOfPages + '%' : '<span class="small-value">None found</span>'}`;
    widget.topValueMeaning = (topAnalyticsPercentOfPages === 100)
      ? ESplitCardChangeMeaning.POSITIVE
      : topAnalyticsPercentOfPages === 0
        ? ESplitCardChangeMeaning.SORT_OF_POSITIVE
        : ESplitCardChangeMeaning.NEUTRAL;

    if (topAnalyticsTag.tagId && topAnalyticsPercentOfPages > 0) {
      widget.topIconPath = UiTagService.getTagIconUrl(topAnalyticsTag.tagId);
      widget.topIconName = undefined;
    } else {
      widget.topIconPath = undefined;
      widget.topIconName = 'error';
      widget.topIconMeaning = ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    }

    widget.bodyContent.bodyText = bodyText;
    widget.bodyContent.navToText = topAnalyticsPercentOfPages === 0 ? undefined : `View pages missing ${topAnalyticsTagName}`;
    widget.loading = false;
  }

  updateTopTagManagerWidget(): void {
    const widget = this.topTagManagerWidget;
    const topTmsTag = this.analyticsTagsInfo.topTms || {};
    const topTmsName = topTmsTag.tagName || '---';
    const topTmsPercentOfPages = Math.round(topTmsTag.tagPresentPageCount / this.analyticsTagsInfo.pageCountTotal * 100) || 0;

    widget.topText = `<div class="top-multi-text-label">Top Tag Manager: </div>`;
    if (topTmsPercentOfPages > 0) {
      widget.topText += `<div>${topTmsName}</div>`;
      widget.navTo = widget.topClickAction = () => this.useCaseService.navTo(EUseCaseNavToKeys.TagInventoryMissingTag, topTmsTag);
      widget.topTooltip = `${widget.topTooltip} Click to view additional details.`;
    }

    widget.topValue = `${topTmsPercentOfPages ? topTmsPercentOfPages + '%' : '<span class="small-value">None found</span>'}`;
    widget.topValueMeaning = (topTmsPercentOfPages === 100)
      ? ESplitCardChangeMeaning.POSITIVE
      : topTmsPercentOfPages === 0
        ? ESplitCardChangeMeaning.SORT_OF_POSITIVE
        : ESplitCardChangeMeaning.NEUTRAL;

    if (topTmsTag.tagId && topTmsPercentOfPages > 0) {
      widget.topIconPath = UiTagService.getTagIconUrl(topTmsTag.tagId);
      widget.topIconName = undefined;
    } else {
      widget.topIconPath = undefined;
      widget.topIconName = 'error';
      widget.topIconMeaning = ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    }

    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.TopTms);
    widget.bodyContent.navToText = topTmsPercentOfPages === 0 ? undefined : `View pages missing ${topTmsName}`;
    widget.loading = false;
  }

  updateTagRequestsWidget(): void {
    const totalTags = this.tagHealthInfo?.filteredTagInstanceCount?.toLocaleString();
    const chartData = this.tagHealthInfo?.tagRequestsChartData || [];

    const widget = this.tagRequestsWidget;

    widget.topValue = totalTags?.toLocaleString() || 0;
    widget.chartData = chartData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.TagRequests);
    widget.bodyContent.navToText = TagRequestsWidget.bodyContent.navToText;
    widget.loading = false;
  }

  updateBrokenTagRequestsWidget(): void {
    const widget = this.brokenTagRequestsWidget;
    const chartData = this.tagHealthInfo?.brokenTagsChartData || [];

    widget.topValue = this.tagHealthInfo?.filteredBrokenTagCount?.toLocaleString()
      || 0;
    widget.topValueMeaning = this.tagHealthInfo?.filteredBrokenTagCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;
    widget.topIconMeaning = this.tagHealthInfo?.filteredBrokenTagCount > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;
    widget.topIconName = this.tagHealthInfo?.filteredBrokenTagCount > 0 ? 'error' : '';
    widget.chartData = chartData;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.BrokenTagRequests);
    widget.bodyContent.navToText = BrokenTagRequestsWidget.bodyContent.navToText;
    widget.loading = false;
  }

  updateDuplicateTagRequestsWidget(): void {
    const widget = this.duplicateTagRequestsWidget;
    const median = 1121;
    const duplicateRequests = {
      total: this.duplicateAndMultiplesInfo?.totalTagInstanceCount?.toLocaleString() || 0,
      duplicates: this.duplicateAndMultiplesInfo?.filteredTagDuplicateCount?.toLocaleString() || 0,
      nonDuplicates: (this.duplicateAndMultiplesInfo?.totalTagInstanceCount - this.duplicateAndMultiplesInfo?.filteredTagDuplicateCount)?.toLocaleString() || 0,
    };

    widget.topValue = duplicateRequests?.duplicates?.toLocaleString() || 0;
    widget.topValueMeaning = duplicateRequests?.duplicates > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.POSITIVE;
    widget.bottomValue = median.toLocaleString();
    widget.chartData = this.duplicateAndMultiplesInfo?.duplicatesAndMultiplesChartData || [];
    widget.topIconName = duplicateRequests?.duplicates > 0 ? 'error' : '';
    widget.topIconMeaning = duplicateRequests?.duplicates > 0 ? ESplitCardChangeMeaning.SORT_OF_POSITIVE : ESplitCardChangeMeaning.POSITIVE;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.DuplicateTagRequests);
    widget.loading = false;
  }

  updateTagAndVariableRuleFailuresWidget(): void {
    const widget = this.tagAndVariableRuleFailuresWidget;

    widget.topValue = this.tagAndVariableInfo?.totalRuleFailures?.toLocaleString() || 0;
    widget.topValueMeaning = this.tagAndVariableInfo?.totalRuleFailures > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.topIconName = this.tagAndVariableInfo?.totalRuleFailures > 0 ? 'error' : '';
    widget.topIconMeaning = this.tagAndVariableInfo?.totalRuleFailures > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.NEUTRAL;
    widget.bodyContent.bodyText = this.accountType === EAccountType.SAMPLE
      ? `In this sample audit we applied two Tag & Variable Rules expecting Adobe Analytics pageName to be set on all pages and Google Analytics 4 to be present. After creating an account you can quickly specify standards and alerts that matter to you.`
      : this.isReadOnly ? `In this audit we evaluated ${this.tagAndVariableInfo?.totalRuleCount} Tag & Variable rules. These rules evaluate the presence of Tag technologies, their variables and values.` : `In this audit we evaluated ${this.tagAndVariableInfo?.totalRuleCount} Tag & Variable ${this.tagAndVariableInfo?.totalRuleCount > 1 ? 'rules' : 'rule'}. ${this.tagAndVariableInfo?.totalRuleCount > 1 ? 'These rules' : 'This rule'} evaluate the presence of Tag technologies, their variables and values. <span class="use-case-analytics-clickable-link use-case-clickable-link">Update, add or remove rules</span>.`;
    widget.loading = false;

    if (!this.isReadOnly && this.accountType !== EAccountType.SAMPLE) {
      setTimeout(() => {
        if (this.openAuditEventListener) {
          this.openAuditEventListener.removeEventListener();
        }
        // Get the element with class clickable-link and add a click handler
        this.openAuditEventListener = document.querySelector('.use-case-analytics-clickable-link')?.addEventListener('click', () => {
          this.useCaseService.openAuditStandardsToCC(1, EStandardsTabs.Rules);
        });
      });
    }
  }

  updateUniqueTagVariablesWidget(): void {
    const widget = this.uniqueTagVariablesWidget;

    widget.topValue = this.uniqueTagVariableInfo?.filteredUniqueVariableNameCount?.toLocaleString() || 0;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.UniqueTagVariables);
    widget.loading = false;
  }

  updateUniqueTagVariableValuesWidget(): void {
    const widget = this.uniqueTagVariableValuesWidget;

    widget.topValue = this.uniqueTagVariableInfo?.filteredUniqueVariableValueCount?.toLocaleString() || 0;
    widget.bodyContent.bodyText = this.useCaseService.getBodyText(EUseCaseWidgetType.UniqueTagVariableValues);
    widget.loading = false;
  }

  private navToReportWithWebAnalyticsFilter(useCaseNavToKeys: EUseCaseNavToKeys) {
    const webAnalyticsFilter = {
      type: EAuditReportFilterTypes.TagCategory,
      display: 'Web Analytics',
      value: 42
    };
    this.useCaseService.navTo(
      useCaseNavToKeys,
      null,
      [ webAnalyticsFilter ]
    );
  }
}
