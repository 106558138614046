import { Component, Inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'
import { ISnackbarWithDataSourcesDataObject } from '../consent-categories.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'import-categorized-cookies-with-datasources-snackbar',
  templateUrl: './import-categorized-cookies-with-datasources-snackbar.component.html',
  styleUrls: ['./import-categorized-cookies-with-datasources-snackbar.component.scss']
})

export class ImportCategorizedCookiesWithDataSourcesSnackbarComponent {
  display: ISnackbarWithDataSourcesDataObject;

  constructor(@Inject(MAT_SNACK_BAR_DATA) private snackbarData: { data: ISnackbarWithDataSourcesDataObject }) {
    this.display = snackbarData.data
  }
}