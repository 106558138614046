import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EAlertModalType } from '@app/components/alert/alert.enums';
import {
  IOpFilterBarV2Filter
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EUsageFilterTypes } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.enums';
import { IUsageRequestDTO } from '@app/components/usage-v2/usage-v2.models';
import { UsageAlertFilterBarService } from '@app/components/alert/alert-logic/usage-alert-filter-bar.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-alert-filters',
  templateUrl: './usage-alert-filters.component.html',
  styleUrls: ['./usage-alert-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UsageAlertFiltersComponent),
      multi: true
    }
  ]
})
export class UsageAlertFiltersComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() modalType: EAlertModalType;
  @Input() readOnlyLabel: string;

  readonly EAlertModalType = EAlertModalType;

  pageUrlInputEnabled = false;

  filters: IOpFilterBarV2Filter<EUsageFilterTypes>[];
  apiFilters: IUsageRequestDTO;
  onChange: (value: IUsageRequestDTO) => void;

  private destroySubject = new Subject<void>();

  constructor(public filterBarService: UsageAlertFilterBarService) {
  }

  ngOnInit(): void {
    this.initListeners();

    this.filterBarService.updateSupportedFiltersList([
      EUsageFilterTypes.Audits,
      EUsageFilterTypes.WebJourney,
      EUsageFilterTypes.Frequency,
      EUsageFilterTypes.Folder,
      EUsageFilterTypes.Creator,
      EUsageFilterTypes.Label,
    ]);
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private initListeners() {
    this.filterBarService.anyFiltersUpdates$
      .pipe(
        takeUntil(this.destroySubject),
        debounceTime(100)
      )
      .subscribe(filters => {
        this.filters = filters;

        this.onChange(this.filterBarService.generateApiPostBody(filters));
      });
  }

  /*** Control Value Accessor implementation ***/
  writeValue = (filters: IUsageRequestDTO): void => {
    this.filterBarService.clear();
    let filtersCopy = JSON.parse(JSON.stringify(filters));

    this.filterBarService.overrideFilters(filtersCopy);
  }

  registerOnChange(fn: (value: IUsageRequestDTO) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(_: () => void): void {
  }
}
