import { takeUntil } from 'rxjs/operators';
import { IWJReportParams } from './../../web-journey-report.models';
import { ComponentChanges } from '@app/models/commons';
import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { IWJTagToAllDiffs } from './wj-results-tag-comparison-table.models';
import { IWebJourneyRun } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { Events } from '@app/moonbeamConstants';
import { ManageTagsService, IPrimaryTag } from '@app/components/account/manageTags/manageTagsService';
import { forkJoin, of, Subject } from 'rxjs';
import { WebJourneyReportService } from '../../web-journey-report.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '@app/components/shared/services/storage.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import {
  StatusCodeIndicatorsPayload
} from '@app/components/audit-reports/status-code-indicators/status-code-indicators.models';
import { EDateFormats } from '@app/components/date/date.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'wj-results-tag-comparison-table',
  templateUrl: './wj-results-tag-comparison-table.component.html',
  styleUrls: ['./wj-results-tag-comparison-table.component.scss']
})
export class WjResultsTagComparisonTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() loading: boolean;
  @Input() actionId: number;
  @Input() groupedDiffs: IWJTagToAllDiffs[];
  @Input() filterByText: string;
  @Input() showDiffsOnly: boolean;
  @Input() isActionMissing: boolean;

  readonly dateTimeFormat = `${EDateFormats.dateTwelve}`;

  journeyId: number;
  runId: number;

  primaryTags: IPrimaryTag[] = [];
  filterByPrimaryTags: boolean;
  primaryTagToggleSubscription: number;

  runs: IWebJourneyRun[];
  previousRun: IWebJourneyRun;
  currentRun: IWebJourneyRun;

  filteredDiffs: IWJTagToAllDiffs[] = [];
  showPreviousRun: boolean;
  previousActionTagStatuses: {
    [actionId: number]: {
      [tagId: number]: StatusCodeIndicatorsPayload
    }
  } = {};
  currentActionTagStatuses: {
    [actionId: number]: {
      [tagId: number]: StatusCodeIndicatorsPayload
    }
  } = {};

  private destroy$ = new Subject();

  constructor(private route: ActivatedRoute,
    private eventManager: IEventManager,
    private manageTagsService: ManageTagsService,
    private webJourneyReportService: WebJourneyReportService,
    private storageService: StorageService) {
  }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: IWJReportParams) => {
        this.journeyId = +params.journeyId;
        this.runId = +params.runId;

        if (!this.runs) this.loadData();
        else this.setCurrentAndPreviousRuns();

        this.filterByPrimaryTags = this.storageService.getPrimaryTagsParam().toggleFilterOn;
        this.prepareDiffs();
        this.checkPreviousRunVisibility();
      });

    this.initListener();
  }

  ngOnChanges(changes: ComponentChanges<WjResultsTagComparisonTableComponent>) {
    this.prepareDiffs();
    this.checkPreviousRunVisibility();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.eventManager.unSubscribe(Events.primaryTagWebJourneyToggleChanged, this.primaryTagToggleSubscription);
  }

  private initListener(): void {
    this.webJourneyReportService.tagFiltering$.pipe(takeUntil(this.destroy$)).subscribe((toggleValue: boolean) => {
      this.filterByPrimaryTags = toggleValue;
      this.prepareDiffs();
    });
  }

  private loadData(): void {
    this.webJourneyReportService.getJourneyRuns(this.journeyId).subscribe(runs => {
      this.runs = runs.sort(
        (run1, run2) => new Date(run1.completedAt).getTime() - new Date(run2.completedAt).getTime()
      );

      this.setCurrentAndPreviousRuns();
    });

    forkJoin([
      this.previousRun ?
        this.webJourneyReportService.getJourneyResult(this.journeyId, this.previousRun?.id)
        : of({}),
      this.webJourneyReportService.getJourneyResult(this.journeyId, this.runId)
    ]).subscribe(([previousResults, currentResults]) => {
      this.setActionTagStatuses(previousResults, this.previousActionTagStatuses);
      this.setActionTagStatuses(currentResults, this.currentActionTagStatuses);
    });

    forkJoin([
      this.manageTagsService.getPrimaryTags(),
      this.manageTagsService.getReportToggleValue()
    ]).subscribe(([primaryTags, toggleValue]) => {
      this.primaryTags = primaryTags;
      this.filterByPrimaryTags = toggleValue;
      this.prepareDiffs();
    });
  }

  private setActionTagStatuses(results, bucket): void {
    results.actions?.forEach(action => action.tags.forEach(tag => {

      if (!bucket[action.actionId]) {
        bucket[action.actionId] = {};
      }

      const uniqueKey = tag.tagId + tag.account;
      if (!bucket[action.actionId][uniqueKey]) {
        bucket[action.actionId][uniqueKey] = { broken: 0, redirect: 0, good: 0 };
      }

      if (tag.statusCode >= 200 && tag.statusCode < 300) {
        bucket[action.actionId][uniqueKey].good++;
      }

      if (tag.statusCode >= 300 && tag.statusCode < 400) {
        bucket[action.actionId][uniqueKey].redirect++;
      }

      if (tag.statusCode >= 400 || tag.statusCode === 0) {
        bucket[action.actionId][uniqueKey].broken++;
      }

    }));
  }

  private setCurrentAndPreviousRuns() {
    const runIndex = this.runs.findIndex(r => r.id === this.runId);
    this.currentRun = this.runs[runIndex];
    this.previousRun = this.runs[runIndex - 1];
  }

  private prepareDiffs(): void {
    if (!this.groupedDiffs) return;
    this.updateIconsToSvg();

    const diffs = this.showDiffsOnly ? this.getDiffsOnly() : this.groupedDiffs;

    const filteredByPrimaryTags = this.filterByPrimaryTags ?
      diffs.filter(diff => this.primaryTags.find(pTag => pTag.tagId === diff.tagId)) :
      diffs;

    const filterBy = this.filterByText?.toLowerCase();

    this.filteredDiffs = filterBy ?
      filteredByPrimaryTags.filter(diff => diff.tagName.toLowerCase().includes(filterBy)) :
      filteredByPrimaryTags;
  }

  private getDiffsOnly(): IWJTagToAllDiffs[] {
    return this.groupedDiffs
      .map(group => ({
        ...group,
        diffs: group.diffs.filter(diff => diff.expectedCount !== diff.actualCount)
      }))
      .filter(group => group.diffs.length > 0);
  }

  private checkPreviousRunVisibility(): void {
    this.showPreviousRun = !!this.previousRun;
  }

  isNumber(val?: number): boolean {
    return typeof val === 'number';
  }

  private updateIconsToSvg(): void {
    this.groupedDiffs.forEach((diff: IWJTagToAllDiffs) => {
      diff.tagIcon = UiTagService.getTagIconUrl(diff.tagId);
    });
  }
}
