<!-- WIDGETS -->
<div class="charts">
  <div class="widget-container">
    <div class="widget-container widget-nowrap">
      <split-card [topLabel]="widgetPagesScanned.topLabel"
                  [topChangeContent]="widgetPagesScanned.topChangeContent"
                  [topChangeMeaning]="widgetPagesScanned.topChangeMeaning"
                  [state]="widgetsState"
                  [metricType]="widgetPagesScanned.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="filteredPageCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPagesScanned.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'A count of pages scanned in this audit/run.'">
        <div class="widget-pages-details">
          <div class="page-counts">
            <span class="pages-filtered-count">{{ filteredPageCount | number }}</span>
            <span *ngIf="widgetsState === 'filtered'">&nbsp;of {{ totalPageCount | number }}</span>
          </div>
          <filter-spinner [state]="widgetsState"></filter-spinner>
        </div>
      </split-card>
      <split-card [topLabel]="widgetErrors.topLabel"
                  [icon]="widgetErrors.icon"
                  [iconColor]="widgetErrors.iconColor"
                  [topChangeContent]="widgetErrors.topChangeContent"
                  [topChangeMeaning]="widgetErrors.topChangeMeaning"
                  [topHandler]="widgetErrors.topHandler"
                  [bottomHandler]="widgetErrors.bottomHandler"
                  [haveEnoughData]="widgetErrorsSparklineData.length > 1"
                  [state]="widgetsState"
                  [activated]="filteredWidget === ELogLevels.Error"
                  [metricType]="widgetErrors.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetErrors.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of all console errors across all filtered pages.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight [meaningOfUp]="'none'"
                                 [meaningOfNoChange]="'none'"
                                 [label]="'since last run'"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetErrorsSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetWarnings.topLabel"
                  [icon]="widgetWarnings.icon"
                  [iconColor]="widgetWarnings.iconColor"
                  [topChangeContent]="widgetWarnings.topChangeContent"
                  [topChangeMeaning]="widgetWarnings.topChangeMeaning"
                  [topHandler]="widgetWarnings.topHandler"
                  [bottomHandler]="widgetWarnings.bottomHandler"
                  [haveEnoughData]="widgetWarningsSparklineData.length > 1"
                  [state]="widgetsState"
                  [activated]="filteredWidget === ELogLevels.Warning"
                  [metricType]="widgetWarnings.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetWarnings.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of all console warnings across all filtered pages.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight [meaningOfUp]="'none'"
                                 [meaningOfNoChange]="'none'"
                                 [label]="'since last run'"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetWarningsSparklineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
    <div class="widget-container widget-nowrap">
      <split-card [topLabel]="widgetInfo.topLabel"
                  [icon]="widgetInfo.icon"
                  [iconColor]="widgetInfo.iconColor"
                  [topChangeContent]="widgetInfo.topChangeContent"
                  [topChangeMeaning]="widgetInfo.topChangeMeaning"
                  [topHandler]="widgetInfo.topHandler"
                  [bottomHandler]="widgetInfo.bottomHandler"
                  [state]="widgetsState"
                  [activated]="filteredWidget === ELogLevels.Info"
                  [haveEnoughData]="widgetInfosSparklineData.length > 1"
                  [metricType]="widgetInfo.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="infoLogCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetInfo.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of all info messages across all filtered pages.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight [meaningOfUp]="'negative'"
                                 [meaningOfNoChange]="'none'"
                                 [label]="'since last run'"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetInfosSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetDebug.topLabel"
                  [icon]="widgetDebug.icon"
                  [iconColor]="widgetDebug.iconColor"
                  [topChangeContent]="widgetDebug.topChangeContent"
                  [topChangeMeaning]="widgetDebug.topChangeMeaning"
                  [topHandler]="widgetDebug.topHandler"
                  [bottomHandler]="widgetDebug.bottomHandler"
                  [haveEnoughData]="widgetDebugSparklineData.length > 1"
                  [state]="widgetsState"
                  [activated]="filteredWidget === ELogLevels.Debug"
                  [metricType]="widgetDebug.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="debugLogCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetDebug.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of all debug messages across all filtered pages.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight [meaningOfUp]="'negative'"
                                 [meaningOfNoChange]="'none'"
                                 [label]="'since last run'"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetDebugSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetOther.topLabel"
                  [icon]="widgetOther.icon"
                  [iconColor]="widgetOther.iconColor"
                  [topChangeContent]="widgetOther.topChangeContent"
                  [topChangeMeaning]="widgetOther.topChangeMeaning"
                  [topHandler]="widgetOther.topHandler"
                  [bottomHandler]="widgetOther.bottomHandler"
                  [haveEnoughData]="widgetOtherSparklineData.length > 1"
                  [state]="widgetsState"
                  [activated]="filteredWidget === ELogLevels.Other"
                  [metricType]="widgetOther.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="otherLogCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetOther.metricType"
                  [disableHighlightFromClick]="true"
                  [topTooltip]="'Count of all other console messages across all filtered pages.' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
        <sparkline-chart-insight [meaningOfUp]="'negative'"
                                 [meaningOfNoChange]="'none'"
                                 [label]="'since last run'"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetOtherSparklineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
  </div>
</div>
<div>
  <div class="section-title-wrap" #commonLogsSection>
    <div class="title-spinner-wrap">
      <h5 class="section-title">Browser Log Messages</h5>
      <filter-spinner [state]="browserLogsSpinnerState"></filter-spinner>
    </div>
  </div>

  <browser-common-logs-table
    [inputData]="browserMessages"
    [spinnerState]="browserLogsSpinnerState"
    [tableState]="browserMessagesTableState"
    [apiFilters]="apiFilters"
    (onLogConditionMetricClicked)="handleLogConditionMetricClicked($event)"
    (updateTableState)="updateMessagesState()"
  ></browser-common-logs-table>
</div>

<div #pagesSection>
  <div class="section-title-wrap pages">
    <div class="title-spinner-wrap">

      <div class="section-title">
        <filter-spinner [state]="browserPagesLogsSpinnerState"></filter-spinner>
        <span>Pages Scanned</span>
        <op-audit-report-export-menu
          [auditId]="auditId"
          [runId]="runId"
          [tableCompletedState]="browserPagesLogsSpinnerState"
          [config]="exportReportConfig">
        </op-audit-report-export-menu>
      </div>

      <op-switchable-column-menu [columnConfig]="PagesTableColumns"
                                 [storageKey]="CommonPagesConfigLocalStorageKey"
                                 [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
    </div>
  </div>

  <browser-pages-logs-table
    [inputData]="browserPagesLogs"
    [spinnerState]="browserPagesLogsSpinnerState"
    [tableState]="browserPagesTableState"
    [selectedConsoleMessage]="selectedConsoleMessage"
    (updateTableState)="updatePagesState()"
  ></browser-pages-logs-table>
</div>
