<component-template componentName="OP Tooltip">
  <component-library-section sectionName="Overview" selector="<op-tooltip>">
    <p>This is the op tooltip component. It is not currently being used anywhere in the application.</p>

    <div class="op-tooltip-example">
      <op-tooltip
        openIcon="settings"
        openText="Open Tooltip"
        title="Tooltip Title"
        acceptText="Accept"
        cancelText="Cancel"
        payload="Tooltip Payload"
      >
      </op-tooltip>
    </div>
  </component-library-section>
</component-template>