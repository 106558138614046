import { Inject, Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Observable, of } from 'rxjs';
import {
  IAuditConsoleLogData,
  IAuditCookies,
  IAuditCookiesParams,
  IAuditPageInfo,
  IAuditPageInfoSparklineData,
  IAuditPageInfoTrendData,
  IAuditPageInfoWebVitalsTrendsData,
  IAuditRequestLogData,
  IAuditRequestLogParams,
  IAuditRfm,
  IAuditRunPageDetailsSpecificWebVitalsTrend,
  IRequestCookieOrigin,
  IResponseCookieOriginDTO,
  IWebJourneyResponseCookieOriginDTO
} from './page-details.models';
import { tap } from 'rxjs/operators';
import { ITagInfoDTOWithInsights } from '@app/components/audit-reports/page-details-tags/page-details-tags.constants';
import { AuditReportBaseService } from '../audit-report/audit-report.service';
import { DOCUMENT } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import {
  EPageInfoLinkDownloadDirection,
  EPageInfoLinkDownloadLinkType,
  EPageInfoLinkDownloadOption
} from '../page-information/page-information.enums';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { environment } from '@app/environments/environment';
import { EPageInfoTrendNames } from '@app/components/audit-reports/page-information/page-information.constants';

@Injectable()
export class PageDetailsReportService extends AuditReportBaseService {

  /**
   * Caching below based on the following:
   * https://medium.com/@dilanthaprasanjith/caching-http-requests-with-rxjs-in-angular-9f7fa44387ce
   */

  constructor(
    private apiService: ApiService,
    cacheResetService: CacheResetService,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(cacheResetService);
  }

  getAuditPageInfo(auditId: number, runId: number, pageId: string): Observable<IAuditPageInfo> {
    const queryParams = `?withBlockedRequestSummary=true&withLinkSummary=true&withParentPage=true&withScreenshotUrl=true`;
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/info${queryParams}`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IAuditPageInfo>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditPageInfoSparklines(auditId: number, runId: number, pageId: string): Observable<IAuditPageInfoSparklineData[]> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/sparklines`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IAuditPageInfoSparklineData[]>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditPageInfoWebVitalsTrends(auditId: number, runId: number, pageId: string): Observable<IAuditPageInfoWebVitalsTrendsData> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/trends/web-vitals`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IAuditPageInfoWebVitalsTrendsData>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getPageDetailsPageWebVitalsTrend(auditId: number, pageId: string, trendName: EPageInfoTrendNames, dayCount: number): Observable<IAuditRunPageDetailsSpecificWebVitalsTrend> {
    const requestUrl = `${this.apiRoot}/${auditId}/pages/${pageId}/trends/web-vitals/${trendName}?days=${dayCount}`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IAuditRunPageDetailsSpecificWebVitalsTrend>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditPageInfoTrends(auditId: number, pageId: string, trendName: string, days: number): Observable<IAuditPageInfoTrendData[]> {
    const requestUrl = `${this.apiRoot}/${auditId}/pages/${pageId}/insight-trends/${trendName}?day=${days}`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IAuditPageInfoTrendData[]>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditRemoteFileMappings(auditId: number, runId: number, pageId: string): Observable<IAuditRfm[]> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/file-mapping`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IAuditRfm[]>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditPageCookies(auditId: number, runId: number, pageId: string, params: IAuditCookiesParams): Observable<IAuditCookies> {
    let requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/cookies?getInsights=true&getOrigins=true`;
    let queryParams = '';
    queryParams += `&hidePreAuditActionOnlyCookies=${params.showPreAuditActionCookies ? 'false' : 'true'}`;

    requestUrl += queryParams;

    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IAuditCookies>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditPageRequestLog(auditId: number, runId: number, pageId: string, params: IAuditRequestLogParams, privacyFeatureEnabled?: boolean): Observable<IAuditRequestLogData> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/request-log`;
    let queryParams = `?page=${params.page}&size=${params.size}`;
    if (params.search) queryParams += `&search=${encodeURIComponent(params.search)}`;
    if (params.showFileChanges && privacyFeatureEnabled) queryParams += `&showFileChanges=true`;
    if (params.showPreAuditActionRequests && privacyFeatureEnabled) queryParams += `&showPreAuditActionRequests=true`;
    if (params.sortBy) queryParams += `&sortBy=${params.sortBy}&sortDesc=${!!params.sortDesc}`;
    const cached = this.responseCache.get(`${requestUrl}${queryParams}`);

    if (cached) return of(cached);

    return this.apiService
      .get<IAuditRequestLogData>(`${requestUrl}${queryParams}`)
      .pipe(tap(data => this.responseCache.set(`${requestUrl}${queryParams}`, data)));
  }

  getAuditPageConsoleLog(auditId: number, runId: number, pageId: string): Observable<IAuditConsoleLogData> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/console-log`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<IAuditConsoleLogData>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditPageTags(auditId: number, runId: number, pageId: string): Observable<ITagInfoDTOWithInsights> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/tags?getInsights=true`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .get<ITagInfoDTOWithInsights>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getReportPageLinks(
    auditId: number,
    runId: number,
    pageId: string,
    direction: EPageInfoLinkDownloadDirection,
    downloadOptions?: EPageInfoLinkDownloadOption[],
    linkType?: EPageInfoLinkDownloadLinkType) {

    let anchorEl = this.document.createElement('a');
    anchorEl.target = '_self';

    let requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/links/${direction}/download`;
    if (direction === EPageInfoLinkDownloadDirection.FromThisPage && downloadOptions) {
      requestUrl += `?options=${downloadOptions.toString()}`;
    }

    if (linkType) {
      requestUrl += `${requestUrl.includes('?') ? '&' : '?'}thisUrlType=${linkType}`;
    }

    return this.apiService
      .get<HttpResponse<string>>(requestUrl, {
        responseType: 'text',
        observe: 'response'
      }).pipe(tap(response => {
        anchorEl.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response.body);

        // RegExp gets string -> finds substring inside the quotes(skip "found substring" skip) and return the filename
        const fileNameSource = response.headers.get('content-disposition');
        anchorEl.download = fileNameSource ? fileNameSource.match(/\"(.+[^"])/)[1] : 'report_pages_links.csv';
        anchorEl.click();
      }));
  }

  @CacheApiResponse()
  getWebJourneyCookieOrigin(webJourneyId: number, runId: number, actionId: number, body: IRequestCookieOrigin): Observable<IWebJourneyResponseCookieOriginDTO> {
    const requestUrl = `${environment.apiV3Url}web-journeys/${webJourneyId}/runs/${runId}/cookies/origin`;

    return this.apiService.post<IWebJourneyResponseCookieOriginDTO>(requestUrl, body);
  }

  @CacheApiResponse()
  getAuditCookieOrigin(auditId: number, runId: number, pageId: string, body: IRequestCookieOrigin): Observable<IResponseCookieOriginDTO> {
    return this.apiService
      .post<IResponseCookieOriginDTO>(`${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/cookies/origin`, body);
  }
}
