<div class="title-container">
  <h1 class="title">Comparisons</h1>
  <div class="controls">
    <button mat-flat-button
            *ngIf="isAdmin"
            (click)="openManageTags()">
      Comparison Settings
    </button>
    <op-button-2021 labelText="Create New"
                    matIcon="control_point"
                    (buttonAction)="openTypePickerModal()"
                    buttonType="filled">
    </op-button-2021>
  </div>
</div>

<div class="filter-input">
  <op-clearable-input
    placeholderText="Filter by name or label"
    (onUserInput)="onFilterChanging($event)">
  </op-clearable-input>
</div>

<div class="spinner-container" *ngIf="loadingTable">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>

<div class="table-wrap">
  <table matSort mat-table multiTemplateDataRows
         [dataSource]="filteredComparisons"
         class="styled-mat-table"
         [class.loading]="loadingTable">
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let item">
        <div class="name-wrap">
          <mat-icon class="arrow expand-button">keyboard_arrow_right</mat-icon>
          <div class="flex-col flex-stretch">
            <span class="hover-link" (click)="$event.stopPropagation(); openComparisonByType(item.type, item)">
              {{ item.name }}
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let item">{{ comparisonTypesMap.get(item.type) }}</td>
    </ng-container>

    <ng-container matColumnDef="labels">
      <th mat-header-cell *matHeaderCellDef class="labels-header">Labels</th>
      <td mat-cell *matCellDef="let item">
        <op-chip-selector
          (click)="$event.stopPropagation()"
          [allItems]="allLabels"
          [(ngModel)]="item.labels"
          (onCreateChip)="onCreateLabel($event, item)"
          (onSelectChip)="onAddLabel(item)"
          (onRemoveChip)="onRemoveLabel(item)"
          [placeholder]="{ hasSelected: '+ Label', hasNoSelected: '+ Label' }"
          [advancedConfigs]="chipsConfig"
        >
        </op-chip-selector>
      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell *matHeaderCellDef class="tags-header">Tags</th>
      <td mat-cell *matCellDef="let item">
        <op-preview-list [items]="item.tags" [itemWidth]="26" [maxPreview]="5" (click)="$event.stopPropagation()">
          <op-tag-img *opPreviewItem="let item" [tagId]="item"></op-tag-img>
        </op-preview-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="audits">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="audits-header">Web Audits</th>
      <td mat-cell class="usage" [ngClass]="{ 'light-gray': item.assignments?.audits === 0 }" *matCellDef="let item">
        <div class="flex-row">
          <mat-icon>explore</mat-icon>
          <span>{{ item.assignments?.audits }}</span>
        </div>
      </td>
    </ng-container>

    <!--
    Commnted according to https://observepoint.atlassian.net/browse/WORK-14140, will be uncommented later
    <ng-container matColumnDef="webjourneys">
      <th mat-header-cell *matHeaderCellDef>Web Journeys</th>
      <td mat-cell [ngClass]="{ usage: true, 'light-gray': item.assignments.webJourneys === 0 }" *matCellDef="let item">
        <div class="flex-row"><mat-icon>desktop_windows</mat-icon>{{ item.assignments.webJourneys }}</div>
      </td>
    </ng-container>-->

    <ng-container matColumnDef="options">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let item">
        <div class="table-actions">
          <div class="btn-wrap">
            <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
              <mat-icon class="more-menu-btn">more_vert</mat-icon>
            </button>
          </div>
          <mat-menu #menu="matMenu" class="comparison-lib-menu">
            <button mat-menu-item
                    class="table-action-icon"
                    *ngIf="item.type === comparisonType.oneTime"
                    (click)="$event.stopPropagation(); runComparison(item)"
            >
              <mat-icon>play_arrow</mat-icon>
              Run
            </button>

            <button mat-menu-item
                    class="table-action-icon"
                    (click)="$event.stopPropagation(); openComparisonByType(item.type, item)"
            >
              <mat-icon>edit</mat-icon>
              Edit
            </button>

            <button mat-menu-item
                    class="table-action-icon"
                    *ngIf="item.canDelete"
                    (click)="$event.stopPropagation(); deleteComparison(item)"
            >
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="detailsRow">
      <td mat-cell *matCellDef="let item" [attr.colspan]="displayedColumns.length">
        <div #container
             [@expand]="{
               value: item === selectedComparison ? (loading ? 'loading' : 'expanded') : 'collapsed',
               params: {
                height: this.lastOpenedNestedTableHeight ? this.lastOpenedNestedTableHeight + 'px' : '*'
               }
             }"
             (@expand.done)="animationDone($event, container)">
          <div class="history" *ngIf="item === selectedComparison">
            <comparison-history [runs]="comparisonRunMap?.get(item.id)"
                                [loading]="loading">
            </comparison-history>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let item; columns: displayedColumns"
      class="table-row hoverable"
      [class.expanded]="selectedComparison === item"
      (click)="selectComparison(item)"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['detailsRow']" class="details-row"></tr>
    <tr *matNoDataRow>
      <td [colSpan]="displayedColumns.length">
        <div *ngIf="!loadingTable" class="no-data">
          <span>No comparisons matched applied filters.</span>
        </div>
      </td>
    </tr>
  </table>
</div>
