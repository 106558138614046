<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Change Run Frequency for {{selectedCards.length}} Item{{selectedCards.length > 1 ? 's': ''}}"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="run-frequency">

      <form [formGroup]="runFrequencyForm" class="run-frequency-form">
        <p class="heading"><mat-label>Change the recurring schedule frequency for {{haveSelectedAudits ? auditsSelectedCount + ' audit' + ((auditsSelectedCount > 1) ? 's' : '') : ''}} {{haveSelectedAudits && haveSelectedJourneys ? 'and' : ''}} {{haveSelectedJourneys ? journeysSelectedCount + ' web journey' + ((journeysSelectedCount > 1) ? 's' : '') : ''}}.</mat-label></p>

        @if (recurrenceEnabled) {
          <op-recurrence [recurrence]="recurrenceSchedule" [parentFormField]="recurrenceField" [itemType]="itemType" [itemCount]="selectedCards.length"></op-recurrence>
          <div *ngIf="showItemsWillRunWarning" class="warning-message">
            <mat-icon class="warning-icon message-icon">warning</mat-icon> Warning: All selected items will attempt to begin immediately.
          </div>
          <div *ngIf="showNewScheduleHasEndedWarning" class="warning-message">
            <mat-icon class="info-icon message-icon">info</mat-icon> All selected items are being assigned a new schedule that has already ended.
          </div>
        } @else {
          <div *ngIf="haveSelectedJourneys" class="form-visuals">
            <p class="label-section">{{journeysSelectedCount}} Web Journey{{(journeysSelectedCount > 1) ? 's' : ''}}</p>
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label class="label-title">How Often Should The Journey Run?</mat-label>
              <mat-select formControlName="runJourneyFrequencyField" (selectionChange)="onSelectChange($event)">
                <mat-option *ngFor="let frequency of journeyFrequencies" [value]="frequency.key">{{ frequency.label }}</mat-option>
              </mat-select>
              <mat-hint>If set to run more than once, actions on pages with new code might need to be updated before any subsequent runs to avoid failures.</mat-hint>
            </mat-form-field>
          </div>

          <div *ngIf="haveSelectedAudits" class="form-visuals">
            <p class="label-section">{{auditsSelectedCount}} Audit{{(auditsSelectedCount > 1) ? 's' : ''}}</p>
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label class="label-title">How Often Should The Audit Run?</mat-label>
              <mat-select formControlName="runAuditFrequencyField">
                <mat-option *ngFor="let frequency of auditFrequencies" [value]="frequency">{{ frequency.label }}</mat-option>
              </mat-select>
              <mat-hint>Larger audits that run too frequently won't start until the previous run is completed.
                <a class="learn-more-link" href="https://help.observepoint.com/article/326-audit-best-practices" target="_blank">Learn more.</a></mat-hint>
            </mat-form-field>
          </div>

          <div *ngIf="showDateTimePicker" class="form-input"
              #startingTimeInput
              (mouseleave)="hideHint(startingTimeInput)"
              (mouseenter)="displayHint(startingTimeInput)">
            <div class="two-controls date-controls">
              <p *ngIf="showDateTimePickerLabel" class="label-section">When Should The {{auditsSelectedCount+journeysSelectedCount}} Item{{(auditsSelectedCount+journeysSelectedCount > 1) ? 's' : ''}} Start Runs</p>
              <div class="form-input nested-form-input">

                <mat-form-field appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.nextRunDate">
                  <mat-label class="label-title">{{FIELD_LABELS.NEXT_DATE}}</mat-label>
                  <input matInput [min]="minNextRunDate" [max]="maxNextRunDate" [matDatepicker]="picker" formControlName="nextRunDateField">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error>Please enter a valid date</mat-error>
                </mat-form-field>
              </div>

              <div class="form-input nested-form-input next-run-time-field">
                <mat-form-field appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.nextRunTime">
                  <mat-label class="label-title">{{FIELD_LABELS.NEXT_TIME}}</mat-label>
                  <input matInput type="time" formControlName="nextRunTimeField" (focus)="onTimeFocus('nextRunTimeField')">
                </mat-form-field>
              </div>
            </div>
            <p class="datetime-hint">Specify when the audits & journeys should start. Time zone is currently {{user.timezone}} and can be changed on your user account.</p>
          </div>
        }
      </form>

      <ng-container *ngIf="processCompleted">
        <div class="center-elements">
          <mat-spinner [diameter]="25" [strokeWidth]="4"></mat-spinner>
          <span>Setting run frequency...</span>
        </div>
      </ng-container>
    </div>
  </ng-template>
</op-modal>

