<form [formGroup]="formData">
  
  <div class="title-text">
    Rules verify that tag and variable data is set as expected (such as an individual page name) or a variable
    matches a specific pattern (like a campaign code format). Failed rules can be seen in the audit Rule Summary
    report or on the rules tab within journey reports. If configured, an email is also sent whenever a failure occurs.
  </div>

  <div class="rule-name">
    <mat-form-field class="styled-mat-input" appearance="outline">
      <mat-label>RULE NAME</mat-label>
      <input matInput formControlName="name">
      <mat-error *ngIf="nameControl.touched && nameControl.invalid">
        <mat-icon>warning_amber</mat-icon>
        Field can't be empty
      </mat-error>
    </mat-form-field>
    <mat-checkbox
      color="primary"
      formControlName="isDefaultRule"
      class="default-rule-checkbox"
      matTooltipPosition="below">
      Add this as a default rule to all data sources that I create.
    </mat-checkbox>
  </div>

  <div class="rule-labels">
    <op-chip-selector formControlName="labels"
                      label="ADD LABELS"
                      appearance="outline"
                      [allItems]="allLabels"
                      [placeholder]="{ hasSelected: '+ Label', hasNoSelected: '+ Label' }"
                      hint="Add labels to quickly categorize and identify this rule later."
                      (onCreateChip)="onCreateLabel.emit($event)">
    </op-chip-selector>
  </div>

  <op-recipients-form-control formControlName="recipients"></op-recipients-form-control>

</form>
