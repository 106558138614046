import { forkJoin, from, merge, Observable, of, Subject, throwError as observableThrowError } from 'rxjs';
import { DomainService } from './../../domain/domain.service';
import { FolderService } from './../../folder/folder.service';
import { ActionsCreatorComponent } from './../../actions/actions-creator/actions-creator.component';
import * as angular from 'angular';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { IAuditModel } from '@app/components/modals/modalData';
import { IButton } from '@app/models/commons';
import { IAuditFilter, IAuditFilters, IAuditOptions, IEditAudit, INewAudit } from '@app/components/audit/audit.models';
import { AuditService } from '@app/components/audit/audit.service';
import { Account, IUser } from '@app/moonbeamModels';
import { OpModalComponent, OpModalService, OpSuccessModalComponent } from '@app/components/shared/components/op-modal';
import { IAuditEditorCloseOptions, IAuditEditorModalData } from './audit-editor.models';
import {
  DEFAULT_CUSTOM_PROXY,
  EAuditFrequency,
  EAuditTab,
  IAuditTab,
  TAB_ID_TO_LABEL_MAP
} from '@app/components/audit/audit.constants';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import {
  AuditCreatorTitle,
  ErrorMessage,
  LearnMoreLinks
} from '@app/components/audit/audit-editor/audit-editor.constants';
import { EActionCreatorMode } from '@app/components/actions/actions-creator/actions-creator.enum';
import { IActionDetails } from '@app/components/actions/action-details/action-details.models';
import { blackoutToApiModel } from '@app/components/utilities/blackoutPeriodUtils';
import { RuleSelectorComponent } from '@app/components/account/rules/rule-selector/rule-selector.component';
import { IRuleSelection, ISelectedItem } from '@app/components/account/rules/rule-selector/rule-selector.models';
import * as dateUtils from '@app/components/date/date.service';
import { DateService, EDateFormats, formatDefs } from '@app/components/date/date.service';
import { EActionTypeV3 } from '@app/components/web-journey/web-journey.models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, switchMap, take, takeUntil } from 'rxjs/operators';
import { Features } from '@app/moonbeamConstants';
import { TransformActionsService } from '@app/components/action-set-library/transform-actions.service';
import { IActionSetAction } from '@app/components/action-set-library/action-set-library.models';
import { IRFMConfigV3 } from '../../creator/shared/remoteFileMapping/remote-file-mapping.component';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import {
  SnackbarErrorComponent
} from '@app/components/shared/components/snackbars/snackbar-error/snackbar-error.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountsService } from '@app/components/account/account.service';
import { InvalidUrlsSnackbarService } from '@app/components/invalid-urls-snackbar/invalid-urls-snackbar.service';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { AuditSuccessSnackbarComponent } from '../audit-success-snackbar/audit-success-snackbar.component';
import { IRule, IRulePreview } from '@app/components/rules/rules.models';
import { ModalWithHotkeySupport } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.models';
import { EKeyCodes } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.constants';
import { isSafari } from '@app/components/utilities/browser.utils';
import { WindowRef } from '@app/components/core/services/window.service';
import { userIsGuest } from '@app/authUtils';
import { IAuditSetupForm } from '@app/components/audit/audit-setup-form/audit-setup-form.models';
import { AuditSetupFormComponent } from '@app/components/audit/audit-setup-form/audit-setup-form.component';
import {
  IStandardsSelectorItem
} from '@app/components/shared/components/op-standards-selector/op-standards-selector.models';
import {
  ExplanationText,
  LearnMoreLink
} from '@app/components/web-journey/web-journey-editor/web-journey-editor.constants';
import { AlertService } from '@app/components/alert/alert.service';
import { EAlertAssignmentOperation, EAlertSearchSortBy } from '@app/components/alert/alert.constants';
import { IAlertAssignmentPatchObj, IAlertsSearchAssignmentBody } from '@app/components/alert/alert.models';
import {
  EProductType,
  EStandardsSelectorType
} from '@app/components/shared/components/op-standards-selector/op-standards-selector.constants';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { ECmpOption } from '@app/components/actions/action-details/action-details.constants';
import { DataSourceEditorService } from '../../data-source-editor/data-source-editor.service';
import {
  StandardsChangedSnackbarComponent
} from '@app/components/audit/standards-changed-snackbar/standards-changed-snackbar.component';
import {
  IChangedStandardCounts
} from '@app/components/audit/standards-changed-snackbar/standards-changed-snackbar.constants';
import {
  IAuditRunSummary
} from '@app/components/domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import {
  OpStandardsSelectorService
} from '@app/components/shared/components/op-standards-selector/op-standards-selector.service';
import { UrlSourcesComponent } from '@app/components/audit/url-sources/url-sources/url-sources.component';
import {
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { StorageService } from '@app/components/shared/services/storage.service';
import { IOpRecurrenceScheduleRequest } from '@app/components/shared/components/op-recurrence/op-recurrence.models';
import { RecurrenceService } from '@app/components/shared/components/op-recurrence/op-recurrence.service';

@Component({
  selector: 'op-audit-editor',
  templateUrl: './audit-editor.component.html',
  styleUrls: ['./audit-editor.component.scss'],
})
export class AuditEditorComponent implements OnInit, OnDestroy, ModalWithHotkeySupport {

  readonly actionsModes = EActionCreatorMode;
  readonly auditTabs = EAuditTab;
  private destroy$: Subject<void> = new Subject();

  originalCCIds: number[] = [];
  features: string[];
  auditForm: UntypedFormGroup;
  submitted: boolean = false;
  auditId: number;
  audit: IAuditModel;
  rules: IRulePreview[] = [];
  labels: ILabel[] = [];
  selectedRuleItems: Array<ISelectedItem>;
  user: IUser;
  isReadOnly: boolean;
  title: string;
  currentTab: number = this.auditTabs.testScenario;
  tabs: IAuditTab[] = this.generateTabs();
  LearnMoreLinks = LearnMoreLinks;
  rightFooterButtons: IButton[] = [
    {
      label: 'Back',
      action: this.back.bind(this),
      icon: 'icon-back-empty',
      hidden: true,
      primary: false,
      opSelector: 'web-audit-back',
    },
    {
      label: 'Continue',
      icon: 'icon-forward-empty',
      action: this.continue.bind(this),
      primary: false,
      opSelector: 'web-audit-continue',
    },
    {
      label: 'Save Audit',
      action: this.saveAudit.bind(this),
      primary: true,
      opSelector: 'web-audit-create-save',
    },
    {
      label: 'Save Audit & Run Now',
      action: this.saveAudit.bind(this, true),
      primary: true,
      opSelector: 'web-audit-create-save-and-run',
    },
  ];
  account: Account;
  runDateFormat: string = formatDefs.dateThirteen;

  successModalRef: MatDialogRef<OpSuccessModalComponent>;
  assignedConsentCategories: number[] = [];
  assignedRules: number[] = [];
  assignedAlerts: number[] = [];
  cachedAssignedAlerts: number[] = [];

  lockUrlsPrevRunDate: string;
  hasRuns: boolean = false;
  sameUrlRunId: number = null;

  loading: boolean = true;
  privacyEnabled: boolean;

  explanationText = ExplanationText;
  learnMoreLink = LearnMoreLink;
  errorMessage = ErrorMessage;

  recurrenceEnabled: boolean = false;

  requestBody: IAlertsSearchAssignmentBody = {
    targetItem: {
      itemType: EProductType.AUDIT,
      itemId: null,
      isAssigned: true
    }
  };

  getDefaultAssignmentRequestBody: IAlertsSearchAssignmentBody = {
    isDefaultForNewDataSources: true,
  };

  params = {
    size: 1000,
    page: 0,
    sortBy: EAlertSearchSortBy.ALERT_NAME,
    sortDesc: false
  };

  standardsTab: EStandardsTabs;
  scrollToActionIndex?: number;
  mostRecentRun: IAuditRunSummary;

  @ViewChild(OpModalComponent) opModal: OpModalComponent;
  @ViewChild(RuleSelectorComponent) ruleSelectorComponent: RuleSelectorComponent;
  @ViewChild(AuditSetupFormComponent) auditSetupForm: AuditSetupFormComponent;
  @ViewChild(UrlSourcesComponent) urlSourcesForm: UrlSourcesComponent;
  @ViewChild('userSessionCreator') userSessionCreatorForm: ActionsCreatorComponent;
  @ViewChild('actionsCreator') actionsCreatorForm: ActionsCreatorComponent;

  constructor(public dialogRef: MatDialogRef<AuditEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: IAuditEditorModalData,
    private window: WindowRef,
    private authenticationService: AuthenticationService,
    private accountsService: AccountsService,
    private auditService: AuditService,
    private auditDataService: IAuditDataService,
    private modalServiceNg: OpModalService,
    private discoveryAuditService: DiscoveryAuditService,
    private formBuilder: UntypedFormBuilder,
    private folderService: FolderService,
    private domainService: DomainService,
    private labelsService: LabelService,
    private snackbarService: SnackbarService,
    private transformActionsService: TransformActionsService,
    private snackbar: MatSnackBar,
    private consentCategoriesService: ConsentCategoriesService,
    private invalidUrlsService: InvalidUrlsSnackbarService,
    private dateService: DateService,
    private alertService: AlertService,
    private standardsSelectorService: OpStandardsSelectorService,
    private dataSourceEditorService: DataSourceEditorService,
    private storageService: StorageService,
    private recurrenceService: RecurrenceService
  ) {
      this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');

    if (typeof this.modalData.step !== 'undefined') {
      this.currentTab = this.modalData.step;
      if (this.modalData.standardsTab) this.standardsTab = this.modalData.standardsTab;
      this.updateButtons();
    }
    this.auditId = this.modalData.auditId;
    this.requestBody.targetItem.itemId = this.auditId;

    if (this.modalData.disableNavigationButtons) {
      this.rightFooterButtons[0].hidden = true;
      this.rightFooterButtons[1].hidden = true;
    }
  }

  ngOnInit(): void {
    this.createEmptyForm();
    this.authenticationService.getFeaturesWithCache().pipe(
      take(1)
    ).subscribe(features => {
      this.features = features;
      this.privacyEnabled = this.features.includes(Features.productLinePrivacy);
      this.auditId ? this.initEditAudit() : this.initCreateAudit();
    });

    this.accountsService.getUser().subscribe(user => {
      this.user = user;
      this.isReadOnly = userIsGuest(user);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private generateTabs(): IAuditTab[] {
    return [
      {
        name: TAB_ID_TO_LABEL_MAP.get(EAuditTab.testScenario),
        path: EAuditTab.testScenario
      },
      {
        name: TAB_ID_TO_LABEL_MAP.get(EAuditTab.urlSources),
        path: EAuditTab.urlSources
      },
      {
        name: TAB_ID_TO_LABEL_MAP.get(EAuditTab.standards),
        path: EAuditTab.standards
      },
      {
        name: TAB_ID_TO_LABEL_MAP.get(EAuditTab.userSession),
        path: EAuditTab.userSession
      },
      {
        name: TAB_ID_TO_LABEL_MAP.get(EAuditTab.actions),
        path: EAuditTab.actions
      },
    ];
  }

  private getTitle(auditName?: string): string {
    return (this.auditId && !this.modalData.copy) ? `${AuditCreatorTitle.Edit} - ${auditName}` : AuditCreatorTitle.Create;
  }

  // form logic
  private createEmptyForm() {
    this.auditForm = this.formBuilder.group({
      auditSetup: this.formBuilder.control({}),
      urlSources: this.formBuilder.control({}),
      rules: this.formBuilder.control(IRuleSelection.createDefault()),
      userSession: this.formBuilder.control([]),
      actions: this.formBuilder.control([]),
    });
  }

  get auditSetup(): AbstractControl {
    return this.auditForm.get('auditSetup');
  }

  get urlSources(): AbstractControl {
    return this.auditForm.get('urlSources');
  }

  get auditSetupValue(): IAuditSetupForm {
    return this.auditSetup.value;
  }

  get urlSourcesValue(): IAuditSetupForm {
    return this.urlSources.value;
  }

  private get actions(): AbstractControl {
    return this.auditForm.get('actions');
  }

  private get actionsValue(): Array<IActionDetails> {
    return this.actions.value;
  }

  get userSession(): AbstractControl {
    return this.auditForm.get('userSession');
  }

  private get userSessionValue(): Array<IActionDetails> {
    return this.userSession.value;
  }

  private initCreateAudit() {
    this.audit = {};
    forkJoin([
      from(this.labelsService.getLabels()),
      this.alertService.getAlertsForAssignment(this.params, this.getDefaultAssignmentRequestBody),
    ]).subscribe(([labels, defaultAssignedAlerts]) => {
      this.labels = labels;
      this.title = this.getTitle();
      this.loading = false;
      this.assignedAlerts = defaultAssignedAlerts.alerts.map(alert => alert.id);
      this.standardsSelectorService.getData(EStandardsSelectorType.RULES).then((standards: IStandardsSelectorItem[]) => {
        this.assignedRules = standards
          .filter(standard => standard.isDefaultForNewDataSource)
          .map(standard => standard.id);
      });
      this.standardsSelectorService.getData(EStandardsSelectorType.CONSENT_CATEGORIES).then((standards: IStandardsSelectorItem[]) => {
        this.assignedConsentCategories = standards
          .filter(standard => standard.isDefaultForNewDataSource)
          .map(standard => standard.id);
      });
    });
  }

  private initEditAudit(): void {
    let requests: Observable<any>[] = [
      from(this.discoveryAuditService.getAudit(this.auditId)),
      from(this.labelsService.getLabels()),
      this.privacyEnabled
        ? this.consentCategoriesService.getAuditConsentCategories(this.auditId)
        : of([]),
      this.alertService.getAlertsForAssignment(this.params, this.requestBody)
    ];

    forkJoin(requests).subscribe(([audit, labels, ccs, alerts]) => {
      this.audit = audit as IAuditModel;
      this.labels = labels as ILabel[];
      this.originalCCIds = ccs.consentCategories ? ccs.consentCategories?.map(cc => cc.id) : [];
      this.assignedConsentCategories = [...this.originalCCIds];
      this.assignedRules = this.audit.rules?.map(rule => rule.id) || [];
      this.assignedAlerts = alerts.alerts.map(alert => alert.id) || [];
      this.cachedAssignedAlerts = [...this.assignedAlerts];
      this.audit.options.consentCategories = [...this.assignedConsentCategories];
      if (this.modalData.copy) {
        (audit as IAuditModel).name = (audit as IAuditModel).name + ' copied ' + this.dateService.formatDate(new Date(), EDateFormats.dateTwo);
        this.loading = false;
      } else {
        this.getPreviousRunDate((audit as IAuditModel));
      }
      this.title = this.getTitle((audit as IAuditModel).name);
      this.fillForm((audit as IAuditModel));
      setTimeout(() => this.handleSaveBtnText());
    });

    from(this.discoveryAuditService.getActionsV3(this.auditId)).subscribe((actions) => {
      const sortedActions = actions.sort((a: any, b: any) => (a.sequence > b.sequence) ? 1 : -1);
      this.actions.setValue(this.transformActionsService.convertApiActionsToUi(sortedActions));
    });

    from(this.discoveryAuditService.getUserSessionV3(this.auditId)).subscribe((actions: IActionSetAction[]) => {
      const sortedUserSession = actions.sort((a: any, b: any) => (a.sequence > b.sequence) ? 1 : -1);
      this.userSession.setValue(this.transformActionsService.convertApiActionsToUi(sortedUserSession));
    }, (error) => {
      // Suppress 404 error. API throws 404 if audit does not have user session. This is acceptable behavior.
      if (error && error.code === 404) return;
      observableThrowError(error);
    });
  }

  private fillForm(audit: IAuditModel): void {
    this.auditForm.patchValue({
      auditSetup: audit,
      urlSources: audit
    });
  }

  private showValidation() {
    this.auditSetupForm.markAsTouched();
  }

  ruleSelectionChanged(selectedRules: IRuleSelection): void {
    this.selectedRuleItems = selectedRules.selectedItems;
    this.auditForm.controls.rules.patchValue(selectedRules);
  }

  back() {
    this.goToTab(this.currentTab - 1);
  }

  continue() {
    this.goToTab(this.currentTab + 1);
  }

  goToTab(tabId: EAuditTab): void {
    this.submitted = false;
    if (this.auditForm.invalid) {
      if (!this.getFormControlOfTab(this.currentTab).invalid) {
        if (this.auditSetup.invalid) this.currentTab = EAuditTab.testScenario;
        else if (this.urlSources.invalid) this.currentTab = EAuditTab.urlSources;
        else if (this.auditForm.get('rules').invalid) this.currentTab = EAuditTab.standards;
        else if (this.userSession.invalid) this.currentTab = EAuditTab.userSession;
        else if (this.actions.invalid) this.currentTab = EAuditTab.actions;
      }
      this.submitted = true;
      if (this.currentTab === EAuditTab.urlSources && this.urlSources.invalid) this.showWrongUrls();
      if (this.currentTab === EAuditTab.userSession) this.userSessionCreatorForm.handleValidationError();
      if (this.currentTab === EAuditTab.actions) this.actionsCreatorForm.handleValidationError();
      else this.showValidation();
      return;
    }
    this.currentTab = tabId;
    this.updateButtons();
  }

  private getFormControlOfTab(tab: EAuditTab): AbstractControl {
    switch (tab) {
      case EAuditTab.testScenario:
        return this.auditForm.get('auditSetup');
      case EAuditTab.urlSources:
        return this.auditForm.get('urlSources');
      case EAuditTab.standards:
        return this.auditForm.get('rules');
      case EAuditTab.userSession:
        return this.userSession;
      case EAuditTab.actions:
        return this.actions;
      default:
        throw new Error(`Cannot get form control of tab ${tab}. Mapping does not exist.`);
    }
  }

  private updateButtons(): void {
    if (!this.modalData.disableNavigationButtons) {
      this.rightFooterButtons[0].hidden = this.currentTab === EAuditTab.testScenario;
      this.rightFooterButtons[1].hidden = this.currentTab === EAuditTab.actions;
    }
  }

  private refreshLabels() {
    return this.labelsService.getLabels().subscribe((labels) => this.labels = labels);
  }

  saveAudit(runAfterSave: boolean = false): void {
    this.submitted = true;

    if (this.auditForm.invalid) {
      this.showValidation();

      if (this.urlSources.invalid) {
        this.showWrongUrls();
      }

      return;
    }

    this.rightFooterButtons[2].disabled = true;
    this.rightFooterButtons[3].disabled = true;

    // this ensures that the include filterd are generated before we continue
    this.auditService.includeFiltersGenerated$.pipe(takeUntil(this.destroy$)).subscribe((filtersGenerated) => {
      if (filtersGenerated) {
        this.folderService.handleFolder(this.user, this.auditSetupValue.folderData.folder)
          .pipe(
          switchMap(folder =>
            this.domainService.handleDomain(
              folder.id,
              this.auditSetupValue.folderData.subFolder,
              this.auditSetupValue.folderData.dataLayer,
              true,
            )
          )
        )
        .subscribe(
          domain => {
            const isCreate = !this.modalData.auditId || !!this.modalData.copy;
            isCreate
              ? this.createAudit(this.urlSourcesValue.includeFilters, domain.id, runAfterSave)
              : this.updateAudit(this.urlSourcesValue.includeFilters, domain.id, runAfterSave);
          },
          error => this.handleError(error),
        );
      }
    });
  }

  private handleError(error?) {
    if (error?.message?.includes('Invalid URL:')) {
      this.snackbar.openFromComponent(SnackbarErrorComponent, {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: {
          message: error.message
        }
      });
    } else {
      this.snackbarService.openErrorSnackbar('An unknown error occurred. Please refresh the page and try again.');
    }

    this.rightFooterButtons[2].disabled = false;
    this.rightFooterButtons[3].disabled = false;
  }

  openRuleCreation() {
    const mode = ERuleSetupMode.create;
    this.modalServiceNg.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: { mode }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => this.onCreateRule(rule));
  }

  openRuleEditor(rule: IRule): void {
    const mode = ERuleSetupMode.edit;
    this.modalServiceNg.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode,
        ruleId: rule.id
      }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => this.onUpdateRule(rule));
  }

  async onCreateRule(createdRule?: IRule): Promise<void> {
    if (!createdRule) return;
    if (createdRule.labels.length > 0) {
      await this.refreshLabels();
    }
    this.updateSelectedRuleAfterCreation(createdRule);
  }

  async onUpdateRule(updatedRule?: IRule): Promise<void> {
    if (!updatedRule) return;
    await this.refreshLabels();
    this.updateSelectedRuleAfterUpdation(updatedRule);
  }

  updateSelectedRuleAfterCreation(createdRule: IRule): void {
    this.rules.push(createdRule);

    let newSelectedRuleIds = [...this.ruleSelectorComponent.selectedItemsAndRules.selectedRuleIds, createdRule.id];
    let newSelectedItems = [...this.ruleSelectorComponent.selectedItemsAndRules.selectedItems, { rule: createdRule }];

    this.auditForm.controls.rules.patchValue({
      selectedRuleIds: newSelectedRuleIds,
      selectedItems: newSelectedItems
    });
  }

  private updateSelectedRuleAfterUpdation(updatedRule: IRule): void {
    this.rules = this.rules.map(rule => rule.id === updatedRule.id ? updatedRule : rule);

    const selectedItems = this.ruleSelectorComponent.selectedItemsAndRules.selectedItems.map(
      selectedItem => {
        if (selectedItem.rule.id !== updatedRule.id) return selectedItem;
        return {
          label: selectedItem.label,
          rule: { id: updatedRule.id, name: updatedRule.name }
        };
      }
    );
    const selectedRuleIds = this.ruleSelectorComponent.selectedItemsAndRules.selectedRuleIds ?? [];
    this.auditForm.controls.rules.patchValue({ selectedItems, selectedRuleIds });
  }

  private createAudit(
    includeList: IAuditFilter[],
    domainId: number,
    runAfterSave: boolean = false,
  ): void {
    const includeFilters = this.checkIncludeFilters(includeList);

    const filters = {
      include: includeFilters,
      exclude: this.urlSourcesValue.excludeFilters && this.urlSourcesValue.excludeFilters.filter(v => !!v),
    };

    const urlsNotSpecified = !this.urlSourcesValue.startingUrls.split('\n').filter(v => !!v).length;
    const auditWithoutUrlNextRun = new Date('2038-01-01T00:01:00.000Z');
    let nextRun;
    if (urlsNotSpecified) {
      nextRun = auditWithoutUrlNextRun;
    } else {
      if (this.urlSourcesValue.startingTime && this.urlSourcesValue.startingDate) {
        nextRun = this.dateService.changeTimeInDate(this.urlSourcesValue.startingTime, this.urlSourcesValue.startingDate);
      } else {
        nextRun = null;
      }
    }

    const frequency = urlsNotSpecified ? 'once' : this.urlSourcesValue.frequency;

    const audit: INewAudit = {
      domainId,
      name: this.auditSetupValue.name,
      limit: this.urlSourcesValue.limit,
      startingUrls: this.urlSourcesValue.startingUrls.split('\n').filter(v => !!v),
      frequency,
      recipients: this.auditSetupValue.recipients?.length > 0 ? this.auditService.recipientsStringToArray(this.auditSetupValue.recipients) : [],
      nextRun,
      filters,
      options: this.getOptions(),
    };

    if (this.recurrenceEnabled) {
      audit.schedule = this.getSchedule();
    }

    const alertsToUpdate = this.assignedAlerts.map(alert => ({
      alertId: alert,
      operation: EAlertAssignmentOperation.ASSIGN
    })) || [];

    this.auditService.createAudit(audit).then(
      audit => this.handleAudit(audit, filters, alertsToUpdate, true, runAfterSave),
      error => this.handleError(error)
    );
  }

  private getOptions(): IAuditOptions {
    const blackoutPeriodModel = {
      start: this.urlSourcesValue.blackoutStart + ':00',
      end: this.urlSourcesValue.blackoutEnd + ':00'
    };

    const urlsNotSpecified = !this.urlSourcesValue.startingUrls.split('\n').filter(v => !!v).length;

    const blackoutPeriodDatesRaw = this.urlSourcesValue.blackoutEnabled ?
      blackoutToApiModel(blackoutPeriodModel, this.user.timezone) :
      null;

    const blackoutPeriodDates = urlsNotSpecified
      ? null
      : blackoutPeriodDatesRaw;

    return {
      location: this.auditSetupValue.customProxy ? DEFAULT_CUSTOM_PROXY : this.auditSetupValue.location,
      customProxy: this.auditSetupValue.customProxy ? this.auditSetupValue.location : null,
      userAgent: this.auditSetupValue.userAgent,
      requestRate: this.auditSetupValue.requestRate,
      fireTags: false,
      clearCookies: this.auditSetupValue.clearCookies,
      stripQueryString: this.urlSourcesValue.templateMode,

      /**
       * loadFlash used to be a dynamic value that passed a users preference for the engines to use
       * Chrome or Chromium. At this time Chromium is not an option so we've removed the option
       * from the UI and are hardcoding the value to true.
       */
      loadFlash: true,

      browserWidth: this.auditSetupValue.browserWidth,
      browserHeight: this.auditSetupValue.browserHeight,
      vpnEnabled: this.auditSetupValue.vpn,
      gpcEnabled: this.auditSetupValue.gpc,
      blockThirdPartyCookies: this.auditSetupValue.blockThirdPartyCookies,
      adobeAuditor: this.auditSetupValue.adobeAuditor,
      blackoutPeriod: blackoutPeriodDates,
      webHookUrl: this.auditSetupValue.webHookUrl ? this.auditSetupValue.webHookUrl : null,
      remoteFileMapConfig: this.formatRfmConfig(),
      sameUrlRunId: this.urlSourcesValue.scanPreviousRunUrlsOnly ? this.sameUrlRunId : null,
    };
  }

  private getSchedule(): IOpRecurrenceScheduleRequest {
    const combinedDateTime = this.recurrenceService.combineDateAndTime(
      this.urlSourcesValue.recurrence.runDate.date,
      this.urlSourcesValue.recurrence.runTime.time
    );

    return {
      dtStart: combinedDateTime,
      tzId: this.urlSourcesValue.recurrence.runTime.timeZone,
      recurrenceRule: this.urlSourcesValue.recurrence.frequency.recurrenceRule,
      isPaused: this.urlSourcesValue.recurrence.frequency.isPaused
    }
  }

  private formatRfmConfig(): IRFMConfigV3[] {
    return this.auditSetupValue.rfmConfig ? this.auditSetupValue.rfmConfig.map(config => {
      return {
        id: config.id,
        name: config.name,
        fileId: config.fileId,
        fileUrl: config.fileUrl,
        matchType: config.matchType,
        matchValue: config.matchValue
      };
    }) : [];
  }

  private updateAudit(
    includeList: IAuditFilter[],
    domainId: number,
    runAfterSave: boolean = false,
  ): void {
    const includeFilters = this.checkIncludeFilters(includeList);

    const filters = {
      include: includeFilters,
      exclude: this.urlSourcesValue.excludeFilters && this.urlSourcesValue.excludeFilters.filter(v => !!v),
    };

    const urlsNotSpecified = !this.urlSourcesValue.startingUrls.split('\n').filter(v => !!v).length;
    const auditWithoutUrlNextRun = new Date('2038-01-01T00:01:00.000Z');
    let nextRun;
    if (urlsNotSpecified) {
      nextRun = auditWithoutUrlNextRun;
    } else {
      if (this.urlSourcesValue.startingTime && this.urlSourcesValue.startingDate) {
        nextRun = this.dateService.changeTimeInDate(this.urlSourcesValue.startingTime, this.urlSourcesValue.startingDate);
      } else {
        nextRun = null;
      }
    }

    const frequency = urlsNotSpecified ? 'once' : this.urlSourcesValue.frequency;

    const audit: IAuditModel = {
      id: this.auditId,
      domainId,
      ownerId: this.audit.ownerId,
      name: this.auditSetupValue.name,
      limit: this.urlSourcesValue.limit,
      startingUrls: this.urlSourcesValue.startingUrls.split('\n').filter(v => !!v),
      frequency,
      recipients: this.auditSetupValue.recipients?.length > 0 ? this.auditService.recipientsStringToArray(this.auditSetupValue.recipients) : [],
      nextRun,
      filters,
      options: this.getOptions(),
    };

    if (this.recurrenceEnabled) {
      audit.schedule = this.getSchedule();
    }

    const assignedAlerts = this.assignedAlerts.filter(alert => !this.cachedAssignedAlerts.includes(alert)).map(alert => ({
      alertId: alert,
      operation: EAlertAssignmentOperation.ASSIGN
    }));

    const unassignedAlerts = this.cachedAssignedAlerts.filter(alert => !this.assignedAlerts.includes(alert)).map(alert => ({
      alertId: alert,
      operation: EAlertAssignmentOperation.UNASSIGN
    }));

    if (!this.urlSourcesValue.startingUrls.split('\n').length) {
      console.log('not specified');
    }

    const alertsToUpdate = assignedAlerts.concat(unassignedAlerts);

    this.auditService.updateAudit(audit as IEditAudit).then(
      audit => this.handleAudit(audit, filters, alertsToUpdate, false, runAfterSave),
      error => this.handleError(error)
    );
  }

  private checkIncludeFilters(include: IAuditFilter[]): IAuditFilter[] {
    if (include?.every(f => !f.value)) {
      return [];
    }

    return include;
  }

  private handleAudit(
    audit: IAuditModel,
    filters: IAuditFilters,
    alertsToUpdate: IAlertAssignmentPatchObj[],
    isCreateAudit: boolean,
    runAfterSave: boolean,
  ): void {
    this.updateActions(audit.id);
    this.updateUserSession(audit.id);

    let requests = [
      from(this.discoveryAuditService.updateFiltersForAudit(audit.id, filters)).pipe(catchError(_ => Promise.resolve({}))),
      from(this.auditService.updateAuditLabels(audit.id, this.auditSetupValue.labels)).pipe(catchError(_ => Promise.resolve([]))),
      from(this.saveRules(audit.id)).pipe(catchError(_ => Promise.resolve([]))),
      from(this.discoveryAuditService.updateEasyBlockTagIds(audit.id, this.auditSetupValue.easyBlockTags)),
      this.alertService.patchAssignedAlerts(EProductType.AUDIT, audit.id, alertsToUpdate)
    ];

    if (this.privacyEnabled) {
      requests.push(this.consentCategoriesService.patchAuditConsentCategories(audit.id, this.originalCCIds, this.assignedConsentCategories).pipe(catchError(_ => of({}))));
    }

    if (runAfterSave) {
      requests.push(from(this.auditDataService.runNow(audit)));
    }

    let changedStandards: IChangedStandardCounts = {
      alerts: alertsToUpdate?.length || 0,
      consentCategories: this.countDifferences(this.originalCCIds, this.assignedConsentCategories),
      ccIds: this.assignedConsentCategories,
      rules: this.countDifferences(audit?.rules?.map(rule => rule.id), this.assignedRules),
    };

    forkJoin(requests).subscribe(() => {
      this.close({ audit, areRulesUpdated: true });

      if (isCreateAudit) {
        this.showSuccessSnackbar(audit, this.modalData);
      } else if (!this.modalData.disableReprocessProposition && (changedStandards.rules > 0 || changedStandards.consentCategories > 0 || changedStandards.alerts > 0)) {
        this.showStandardsUpdatedSnackbar(audit, changedStandards);
      }
    });
  }

  // Count the number of differences between arr1 and arr2. If a value is in
  // one array, but not the other, that counts as a difference.
  countDifferences(arr1: number[], arr2: number[]) {
    // Add all ids from the first array to the set
    const idSet = new Set(arr1);
    let count = 0;

    // Check each item in the second array
    for (const id of arr2) {
      if (!idSet.has(id)) {
        // If the id is not found in the set, it's a difference
        count++;
      } else {
        // If the id is found, remove it from the set
        idSet.delete(id);
      }
    }

    // Add the remaining items in the first array as differences
    count += idSet.size;

    return count;
  }

  private saveRules(auditId: number): angular.IPromise<Array<IRule>> {
    return this.discoveryAuditService.updateRulesForAudit(auditId, this.assignedRules);
  }

  private updateActions(auditId: number): void {
    const actions = this.transformActionsService.convertUiActionsToApi(this.actionsValue, EActionCreatorMode.AuditActions, this.modalData.copy);
    this.discoveryAuditService.saveActionsV3(auditId, actions).catch(error => {
      this.snackbarService.openErrorSnackbar('Unable to save audit actions. Please try again.');
    });
  }

  private updateUserSession(auditId: number): void {
    const actions = this.transformActionsService.convertUiActionsToApi(this.userSessionValue, EActionCreatorMode.AuditActions, this.modalData.copy);
    this.discoveryAuditService.saveUserSessionV3(auditId, actions).catch(error => {
      this.snackbarService.openErrorSnackbar('Unable to save audit user session. Please try again.');
    });
  }

  close(options?: IAuditEditorCloseOptions): void {
    this.dialogRef.close(options);
  }

  onLocationChanged(location: string) {
    if (location === 'mountain' && this.auditSetupForm.vpnSupport) {
      this.auditSetupForm.vpn.enable();
    } else {
      this.auditSetupForm.vpn.disable();
    }
  }

  onVPNChanged(vpnEnabled: boolean) {
    if (vpnEnabled) {
      this.auditSetupForm.location.disable();
    } else {
      this.auditSetupForm.location.enable();
    }
  }

  onFrequencyChanged(frequency: string) {
    if (this.urlSourcesForm.startingDate.value > new Date(new Date().setFullYear(new Date().getFullYear() + 10))) {
      this.urlSourcesForm.startingDate.setValue(new Date());
      this.urlSourcesForm.startingTime.setValue(dateUtils.timeStringToInputValue(new Date(), !isSafari(this.window)));
    }

    if (frequency === EAuditFrequency.ONCE) {
      this.urlSourcesForm.blackoutEnabled.disable();
      this.urlSourcesForm.blackoutEnabled.setValue(false);
    } else {
      this.urlSourcesForm.blackoutEnabled.enable();
    }

    this.handleSaveBtnText();
  }

  private showSuccessSnackbar(audit: IAuditModel, extras: IAuditEditorModalData): void {
    this.snackbar.openFromComponent(AuditSuccessSnackbarComponent, {
      data: { audit, extras },
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 5000,
    });
  }

  private showStandardsUpdatedSnackbar(audit: IAuditModel, changedStandards: IChangedStandardCounts): void {
    this.snackbar.openFromComponent(StandardsChangedSnackbarComponent, {
      data: {
        changedStandards,
        audit,
        runId: this.modalData.runId,
        mostRecentRun: this.mostRecentRun,
      },
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  onUpdateScanLimit(frequency: string) {
    this.urlSources.get('frequency').setValue(frequency);
  }

  /**
   * Update the sameUrlRunId and lockUrlsPrevRunDate for use in getting the run informationfor the
   * sameUrlRun and displaying its timestamp in the locked message
   * @param audit - Current Audit being edited
   * @param priorRuns - Array of runs
   */
  private updateSameUrlInfo(audit: IAuditModel, priorRuns): void {
    if (this.hasRuns) {
      const sameUrlRun = priorRuns.find(run => {
        return audit.options && audit.options.sameUrlRunId ?
          audit.options.sameUrlRunId === run.id
          : run.completed && run.completed.length > 0;
      });

      this.sameUrlRunId = sameUrlRun?.id;
      this.lockUrlsPrevRunDate = sameUrlRun?.completed;
    }
  }

  private getPreviousRunDate(audit: IAuditModel) {
    this.discoveryAuditService.getAuditRuns((audit as IAuditModel).id).then(response => {
      this.hasRuns = !!response.length;
      if (this.hasRuns) {
        this.mostRecentRun = response[0];
        this.updateSameUrlInfo({ ...audit }, [...response]);
      }
      this.loading = false;
    });
  }

  updateActionsForm(data: any, type: string): void {
    // Remove all selected actions that were made part of the new action set
    let indexes = data.selectedActions.map((action: any) => action.index - 1);
    let updatedActions = this.auditForm.value[type].filter((action: any, index: number) => !indexes.includes(index));

    let newActionSetAction = {
      type: EActionTypeV3.ActionSet,
      label: undefined,
      actionSet: data.actionSet,
      matchAllPages: false,
      filter: ''
    };

    // Add the newly created action set (contains all the selected actions that
    // were filtered out above)
    updatedActions.splice(indexes[0], 0, newActionSetAction);
    let form = this.auditForm.get(type);
    form.patchValue(updatedActions);
  }

  showWrongUrls() {
    // the logic for this needs to be kept in sync with the logic in
    // op-validators.ts in the `startingUrls()` method
    let startingUrls: any = this.urlSourcesValue?.startingUrls;

    if (startingUrls?.trim) {
      startingUrls = this.urlSources?.value?.startingUrls.split('\n').map(url => url.trim()).filter(url => url !== '');
    }

    let isValid: boolean;
    const invalidUrls = [];

    startingUrls?.forEach((url: string) => {
      let [protocol, remainder] = url.split(/:\/\/(.*)/s, 2);

      isValid = protocol && remainder
        ? !OPValidators.isInvalidProtocol(protocol) && !OPValidators.isInvalidStartingUrl(remainder)
        : !OPValidators.isInvalidStartingUrl(protocol);

      if (!isValid) invalidUrls.push(url);
    });

    if (invalidUrls?.length) {
      this.invalidUrlsService.showInvalidUrls(invalidUrls);
    }
  }

  getHotkeyHandler(key: EKeyCodes) {
    return key === EKeyCodes.KeyS
      ? () => this.saveAudit()
      : null;
  }

  updateConsentCats(standards: IStandardsSelectorItem[]): void {
    this.assignedConsentCategories = standards.map((standard: IStandardsSelectorItem) => standard.id);
  }

  updateTagAndVariableRules(standards: IStandardsSelectorItem[]): void {
    this.assignedRules = standards.map((standard: IStandardsSelectorItem) => standard.id);
  }

  updateAlerts(standards: IStandardsSelectorItem[]): void {
    this.assignedAlerts = standards.map((standard: IStandardsSelectorItem) => standard.id);
  }

  handleSaveBtnText(): void {
    // hate having to wrap this but otherwise
    // the values we get are out of date
    setTimeout(() => {
      if (!this.urlSourcesValue.startingDate || !this.urlSourcesValue.startingTime) {
        return;
      }

      const isEditMode = !!this.auditId;

      if (isEditMode) {
        this.rightFooterButtons[2].label = 'Save Changes';
        this.rightFooterButtons[3].label = 'Save Changes & Run Now';
      } else {
        this.rightFooterButtons[2].label = 'Save Audit';
        this.rightFooterButtons[3].label = 'Save Audit & Run Now';
      }
    });
  }

  handleAddCmpAction(): void {
    if (this.auditForm.invalid) {
      return this.showValidation();
    }

    if (this.hasCmpAction()) {
      // jump to pre-audit actions tab
      // and scroll to the first CMP action
      this.currentTab = EAuditTab.userSession;
      this.scrollToActionIndex = this.userSessionValue.findIndex(action => action.type === EActionTypeV3.CmpOptInOptOut);
      this.dataSourceEditorService.scrollToCmpAction.next(this.scrollToActionIndex);
    } else {
      // create new CMP action as first action
      // then jump to pre-audit actions tab
      this.userSessionValue.unshift({
        cmpType: ECmpOption.OPT_IN,
        cmpUrl: '',
        isRequired: true,
        label: 'Set Consent Manager State (CMP)',
        type: EActionTypeV3.CmpOptInOptOut
      });

      this.userSession.patchValue(this.userSessionValue);
      this.currentTab = EAuditTab.userSession;
    }
  }

  hasCmpAction(): boolean {
    return this.userSessionValue.some(action => action.type === EActionTypeV3.CmpOptInOptOut);
  }

  handleDisableSaveBtn(filtersAreGenerating: boolean): void {
    this.rightFooterButtons[2].disabled = filtersAreGenerating;
  }
}
