import * as angular from 'angular';

import { ButtonSet } from '@app/models/commons';
import { AngularNames, Events, Names } from '@app/moonbeamConstants';
import { IEventManager } from '@app/components/eventManager/eventManager';

export interface IRdcUserPresenceModalScope extends mgcrea.ngStrap.modal.IModalScope {
    countDown: number;
  }

export const name = 'UserPresenceModalController';

export class UserPresenceModalController {

  countdown: number;
  countdownControl: angular.IPromise<any>;

  messages: string[];
  modalButtons: Array<ButtonSet> = [[{
    label: 'OK',
    action: 'confirm',
    primary: false
  },
  {
    label: 'Cancel',
    action: 'cancel',
    primary: true
  }]];
  hideDiscardButton: boolean = true;

  static $inject = [
    AngularNames.scope,
    AngularNames.interval,
    Names.Services.eventManager,
    'answer'
  ];
  constructor(
    private $scope: IRdcUserPresenceModalScope,
    private $interval: angular.IIntervalService,
    private eventManager: IEventManager,
    private answer: Function
  ) {
    var presenceUpdateSubscrId: number = this.eventManager.subscribe(Events.rdcUserPresenceConfirmation, () => {
      this.close(false);
    });
    this.$scope.$on('$destroy', () => {
      this.$interval.cancel(this.countdownControl);
      this.eventManager.unSubscribe(Events.rdcUserPresenceConfirmation, presenceUpdateSubscrId);
    });

    this.countdown = this.$scope.countDown;
    this.messages = [
      this.buildSessionExpirationMsg(this.countdown),
      'Press OK if you want to continue recording.'
    ];
    this.countdownControl = this.$interval(() => {
      this.countdown--;
      if (this.countdown <= 0) this.cancel();
      this.messages[0] = this.buildSessionExpirationMsg(this.countdown);
    }, 1000);
  }

  private buildSessionExpirationMsg(countdown: number): string {
    return `In ${this.countdown} seconds your journey will be aborted due to inactivity timeout.`;
  }

  confirm(): void {
    this.close(false);
  }

  cancel(): void {
    this.close(true);
  }

  close(abortRecording?: boolean): void {
    this.answer(abortRecording);
    this.$scope.$hide();
  }

}
