import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import {
  AsyncValidatorFn,
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';

import { OPValidators } from '@app/components/shared/validators/op-validators';
import { RemoteFileMapService } from '../../services/remote-file-map.service';
import { FileUploadService } from '../../../shared/services/file-upload.service';
import { HttpEventType } from '@angular/common/http';
import { environment } from '@app/environments/environment';

export enum MatchType {
  Equals = 'equals',
  Regex = 'regex',
}

// fileId/fileName: the replacement. Either fileId *OR* fileName (which is a absolute URL)
// matchType/matchValue: the match strategy - either an absolute URL or a regex
export interface IRFMConfig {
  fileId?: string;
  fileUrl?: string;
  matchType: MatchType;
  matchValue: string;
  id?: number;
}

export interface IRFMConfigV3 extends IRFMConfig {
  name: string;
  accountId?: number;
  createdBy?: number;
  createdAt?: Date;
  updatedBy?: number;
  updatedAt?: Date;
}

export interface IRFMConfigV3CreationRequest extends IRFMConfig {
  name: string;
}

enum UploadingState {
  NotStarted,
  Started,
  Finished
}

interface IRFMFormChanges {
  fileId: string;
  fileNameUrl?: string;
  fileNameUpload?: string;
  matchType: MatchType;
  matchValue: string;
}

export interface IRFMUpdatedControl {
  index: number;
  config: IRFMConfig;
}

export interface IRFMUpdatedValidation {
  index: number;
  isValid: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rfm-picker',
  templateUrl: './remote-file-mapping.component.html',
  styleUrls: ['./remote-file-mapping.component.scss'],
})
export class RFMComponent implements OnInit, OnChanges {
  @Input() config: IRFMConfig;
  @Input() multi: boolean;
  @Input() index?: number = 0;
  @Input() hideTitle?: boolean = false;
  @Input() submitted?: boolean; // for the downgraded component. Can be removed when we no longer need it in angular js

  @Output() onConfigUpdate: EventEmitter<IRFMUpdatedControl> = new EventEmitter();
  @Output() onValidationUpdate: EventEmitter<IRFMUpdatedValidation> = new EventEmitter();
  @Output() onFileUploadChange: EventEmitter<boolean> = new EventEmitter();
  @Output() onConfigDelete: EventEmitter<number> = new EventEmitter();

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @ViewChild('fileUrlInput', { static: true }) fileUrlInput: ElementRef;

  uploadMode: boolean;
  matchTypes = MatchType;

  urlCheckPending: boolean = false;

  rfmValueForm: UntypedFormGroup;
  remoteResourceValidator: AsyncValidatorFn;
  uploadProgress = 0;
  uploadingState: UploadingState = UploadingState.NotStarted;

  fileSizeLimit = 2 * 1024 * 1024;
  uploadConfig = {
    url: environment.apiV3Url + 'remote-file-mappings/files',
    params: {},
  };

  constructor(private formBuilder: UntypedFormBuilder,
              private rfm: RemoteFileMapService,
              private fus: FileUploadService) {

    this.remoteResourceValidator = this.rfm.remoteResourceSizeValidator({debounce: 500});

  }

  private fileSizeValidator(c: AbstractControl): ValidationErrors | null {
    const file = (this.fileInput.nativeElement.files as FileList)[0];
    if (!file || file.size < this.fileSizeLimit) {
      return null;
    }

    return { maxFileSize: true };
  }

  private fileUploadedValidator(c: AbstractControl): ValidationErrors | null {
    return this.uploadingState === UploadingState.Started ? { fileUploadInProgress: true } : null;
  }

  private formChangesToConfig(changes: IRFMFormChanges): IRFMConfig {
    return {
      fileId: changes.fileId,
      fileUrl: this.uploadMode ? changes.fileNameUpload : changes.fileNameUrl,
      matchType: changes.matchType,
      matchValue: changes.matchValue,
    };
  }

  ngOnInit() {
    if (!this.config) {
      this.config = {
        fileId: null,
        fileUrl: '',
        matchType: MatchType.Equals,
        matchValue: '',
      };
    }

    this.uploadMode = !!this.config.fileId;
    this.rfmValueForm = this.formBuilder.group({
      matchType: this.formBuilder.control(this.config.matchType),
      matchValue: this.formBuilder.control(
        this.config.matchValue,
        [this.getMatchValueValidator(this.config.matchType), Validators.required]
      ),
      fileNameUrl: this.formBuilder.control(
        {
          value: this.config.fileUrl,
          disabled: this.uploadMode
        },
        [OPValidators.url, Validators.required],
        this.remoteResourceValidator
      ),
      fileNameUpload: this.formBuilder.control(
        {
          value: this.config.fileUrl,
          disabled: !this.uploadMode
        },
        [this.fileSizeValidator.bind(this), this.fileUploadedValidator.bind(this)]
      ),
      fileId: this.formBuilder.control(this.config.fileId),
    });

    this.rfmValueForm.valueChanges.subscribe(val => {
      this.onConfigUpdate.emit({ index: this.index, config: this.formChangesToConfig(val) });
    });
    this.rfmValueForm.statusChanges.subscribe(val => {
      this.urlCheckPending = val === 'PENDING';
      this.onValidationUpdate.emit({ index: this.index, isValid: this.isRfmConfigValid() });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.config) {
      this.multi
      ? this.config = changes.config.currentValue[this.index]
      : this.config = changes.config.currentValue;
    }

    if (
      changes.submitted &&
      changes.submitted.currentValue &&
      changes.submitted.previousValue != changes.submitted.currentValue) {
        // Triggers validation errors to show when AngularJS form submits
        this.rfmValueForm.get('matchValue').updateValueAndValidity();
        this.rfmValueForm.get('matchValue').markAsTouched();
        this.rfmValueForm.get('fileNameUrl').updateValueAndValidity();
        this.rfmValueForm.get('fileNameUrl').markAsTouched();
        this.rfmValueForm.get('fileNameUpload').updateValueAndValidity();
        this.rfmValueForm.get('fileNameUpload').markAsTouched();
    }
  }

  isRfmConfigValid(): boolean {
    const fileNameUrl = this.rfmValueForm.get('fileNameUrl');
    const matchval = this.rfmValueForm.get('matchValue');
    return this.rfmValueForm.valid
    || (
      this.rfmValueForm.get('matchType').valid &&
      this.rfmValueForm.get('fileId').valid &&
      matchval.valid &&
      fileNameUrl.valid &&
      Object.keys(fileNameUrl.errors).every(k => ['resourceSizeUnknown', 'maxFileSize'].includes(k))
    );
  }

  onChangeMatchType(ev: MatRadioChange) {
    const matchValue = this.rfmValueForm.get('matchValue');
    matchValue.setValidators([this.getMatchValueValidator(ev.value), Validators.required]);
    matchValue.updateValueAndValidity();
  }

  private getMatchValueValidator(type: MatchType) {
    return type === MatchType.Equals ? OPValidators.url : OPValidators.regex;
  }

  onClickLink() {
    this.uploadMode ? this.switchToUrlMode() : this.switchToUploadMode();
  }

  switchToUrlMode() {
    this.rfmValueForm.get('fileId').setValue(null);
    this.rfmValueForm.get('fileNameUpload').disable();

    this.uploadMode = false;
    this.uploadingState = UploadingState.NotStarted;

    const fileNameUrl = this.rfmValueForm.get('fileNameUrl');
    fileNameUrl.setValue('');
    fileNameUrl.enable();

    setTimeout(() => {
      this.fileUrlInput.nativeElement.focus();
    });
  }

  switchToUploadMode() {
    this.fileInput.nativeElement.value = '';
    this.rfmValueForm.controls.fileNameUpload.updateValueAndValidity();
    this.fileInput.nativeElement.click();
  }

  private updateUploadProgress(progress: number) {
    this.uploadProgress = Math.round(progress * 100);
  }

  fileEvent(event) {
    let file = event.target.files[0];
    if (!file) return;

    const fileNameUpload = this.rfmValueForm.get('fileNameUpload');
    fileNameUpload.enable();

    setTimeout(() => {
      fileNameUpload.setValue(file.name);
      fileNameUpload.markAsTouched();
      fileNameUpload.updateValueAndValidity();
    });

    this.uploadMode = true;
    this.rfmValueForm.get('fileNameUrl').disable();
    if (fileNameUpload.valid) {
      this.onFileUploadChange.emit(true);
      this.uploadingState = UploadingState.Started;
      fileNameUpload.updateValueAndValidity();
      this.upload(file);
    }
  }

  private upload(file: File) {
    this.uploadConfig.params['name'] = file.name;
    this.fus
      .uploadFileWithProgress<{ fileId: string }>(
        file,
        this.uploadConfig.url,
        this.uploadConfig.params
      )
      .pipe(finalize(this.finalizeUpload))
      .subscribe(
        this.handleUploadEvents,
        this.handleUploadError,
        this.completeUpload
      );
  }

  private handleUploadEvents = ev => {
    switch (ev.type) {
      case HttpEventType.UploadProgress:
        this.updateUploadProgress(ev.progressPercentage);
        break;
      case HttpEventType.Response:
        this.rfmValueForm.controls.fileId.patchValue(ev.body.fileId);
        break;
    }
  }

  private handleUploadError = err => {
    const fileName = this.rfmValueForm.get('fileNameUpload');
    fileName.markAsDirty();
    fileName.markAsTouched();
    fileName.setValue('');
    fileName.setErrors({
      fileUpload: true,
    });
  }

  private completeUpload = () => {
    this.uploadingState = UploadingState.Finished;
    this.rfmValueForm.controls.fileNameUpload.updateValueAndValidity();
  }

  private finalizeUpload = () => {
    this.updateUploadProgress(0);
    this.onFileUploadChange.emit(false);
  }
}
