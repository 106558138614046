import { ApiService } from '@app/components/core/services/api.service';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMultiSelectRequest, IMultiSelectAPIRequest, IMultiSelectRunNowResponse } from '@app/components/manage/shared/manage.models';
import { RunNowFailureReason } from '@app/components/manage/shared/manage.enum';
import { environment } from '@app/environments/environment';

interface IWebJourneyBulkStartResponse {
  failures: number[]
}

@Injectable({
  providedIn: 'root'
})
export class MultiSelectApiService {
  
  constructor(private apiService: ApiService) { }

  root: string = environment.apiUrl;
  rootV3: string = environment.apiV3Url;

  private selectionToApiRequest(selection: IMultiSelectRequest): IMultiSelectAPIRequest {
    return {
      auditIds: selection.audits.map(audit => audit.id),
      webJourneyIds: selection.webJourneys.map(webJourney => webJourney.id),
    };
  }

  assignRules(selection: IMultiSelectRequest, ruleIds: Array<number>): Observable<void> {
    let data = this.selectionToApiRequest(selection);
    return this.apiService.put(this.root + 'cards/rules', { selection: data, ruleIds });
  }
  
  addLabels(selection: IMultiSelectRequest, labelIds: Array<number>): Observable<void> {
    let data = this.selectionToApiRequest(selection);
    return this.apiService.put(this.root + 'cards/labels', { selection: data, labelIds });
  }
  
  runNow(selection: IMultiSelectRequest): Observable<IMultiSelectRunNowResponse> {
    const apiRequest = this.selectionToApiRequest(selection);
    const webJourneyIds = apiRequest.webJourneyIds; // <-- modernized web journeys have new V3 endpoint for starting WJ's in bulk
    delete apiRequest.webJourneyIds;

    return forkJoin([
      this.apiService.post<IMultiSelectRunNowResponse>(this.root + 'cards/run', apiRequest),                // original cards request
      this.apiService.post<IWebJourneyBulkStartResponse>(this.rootV3 + 'web-journeys/runs', webJourneyIds)  // new web-journey bulk startendpoint; no response body
    ]).pipe(
      map(([ cardRunResponse, wjRunResponse ]) => {
        cardRunResponse.failedToStartWebJourneys = wjRunResponse.failures.map(f => ({ itemId: f, reason: RunNowFailureReason.RunCreationFailed}));
        return cardRunResponse;
      })
    );
  }

}
