<mat-form-field appearance="outline" class="cc-geo-selector chips no-hint-container">
  <mat-chip-grid #chipList>
    <mat-chip-row
      *ngFor="let country of selectedCountries"
      selectable="true"
      removable="true"
      (removed)="removeChip(country)">
      {{ country.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <input
      [placeholder]="placeholder"
      #continentInput
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (keyup)="filterAutoCompleteList($event.target.value)">
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addChip($event.option.value)">
    <mat-option *ngFor="let item of filteredList" [value]="item">
      {{ item.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
