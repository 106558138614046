import { AngularNames, Names } from '@app/moonbeamConstants';
import { IUser } from '../../../../moonbeamModels';
import { ITimeSeries } from './timeSeries';
import * as angular from 'angular';
import { IWidgetSizeService } from '../widgetSizeService';
import { DateService, EDateFormats, IDateService } from '@app/components/date/date.service';

interface ITimeSeriesScope extends angular.IScope {
  data: any;
  parentClass: string;
  tipDisabled: boolean;
  seriesTitle: string;
  noData: boolean;
  focusLine: number;
  currentId: number;
  user: IUser;
  dataIdSelected({ id }): void;
}

interface ITimeSeriesAttributes extends angular.IAttributes {
  selectId: string;
}

export class TimeSeriesDirective implements ng.IDirective {
  template = require('@app/components/reporting/widgets/timeSeries/timeSeries.html');
  restrict = 'E';
  scope = {
    data: '=',
    tipDisabled: '=',
    seriesTitle: '=',
    focusLine: '=',
    currentId: '=',
    dataIdSelected: '&selectId',
    user: '='
  };

  seriesIndex: number = 0;

  link = (scope: ITimeSeriesScope, element: angular.IRootElementService, attrs: ITimeSeriesAttributes) => {
    var parentElement = element[0].getElementsByClassName('timeSeriesChart')[0];

    var timeSeries: ITimeSeries = this.timeSeriesFactory();
    timeSeries.create(parentElement, this.$location.absUrl(), this.seriesIndex++);

    if (typeof scope.dataIdSelected === 'function') {
      timeSeries.onDataPointSelected = (id: number) => {
        scope.dataIdSelected({ id: id });
      };
    }

    scope.$watch('currentId', (id: number) => {
      if (timeSeries.selectedId != id && timeSeries.isInitialized()) {
        timeSeries.setSelectedId(id);
      }
    });

    timeSeries.selectedId = scope.currentId;
    timeSeries.tipDisabled = scope.tipDisabled;
    timeSeries.seriesTitle = scope.seriesTitle;

    this.createTooltip(timeSeries);

    var titleListener = scope.$watch('seriesTitle', (newTitle: string) => {
      timeSeries.updateTitle(newTitle);
    });

    var lineListener = scope.$watch('focusLine', (focusLine: number, unfocusLine: number) => {
      if (unfocusLine >= 0) {
        timeSeries.unfocusLine(unfocusLine);
      }
      if (focusLine >= 0) {
        timeSeries.focusLine(focusLine);
      }
    });

    var dataListener;
    let windowResizeSubscription;
    this.$timeout(() => {
      dataListener = scope.$watchGroup(['data', 'user'], (newData: any) => {
        let data = newData[0];
        let user = newData[1];
        this.$timeout(() => {
          if (!scope.data || scope.data.length == 0) {
            scope.noData = true;
          }
        }, 3000);

        scope.noData = false;
        if (data && data.length && user) {
          this.$timeout(() => {
            timeSeries.setUser(user);
            timeSeries.setData(data);
            this.setSize(timeSeries, parentElement);
            timeSeries.drawGraph();
          });
        }
      });

      const windowResizeStream = Rx.Observable.fromEvent(<any>window, 'resize').debounce(10);
      windowResizeSubscription = windowResizeStream.subscribe(() => {
        var width = this.widgetSizeService.calculateElementWidth(parentElement);
        var height = this.widgetSizeService.calculateElementHeight(parentElement);
        if (width && height) {
          timeSeries.setSize(width, height);
        }
      });
    });

    this.destroyListenersWhenReady(scope, () => {
      timeSeries.closeTooltip();
      angular.element(window).off('resize');
      if (dataListener) {
        dataListener();
      }
      if (titleListener) {
        titleListener();
      }
      if (lineListener) {
        lineListener();
      }
      if (windowResizeSubscription) {
        windowResizeSubscription.dispose();
      }
    });
  }

  private setSize(timeSeries: ITimeSeries, parentElement) {
    var width = this.widgetSizeService.calculateElementWidth(parentElement);
    if (width) {
      timeSeries.width = width;
    }
    var height = this.widgetSizeService.calculateElementHeight(parentElement);
    if (height) {
      timeSeries.height = height;
    }
  }

  private destroyListenersWhenReady(scope: angular.IScope, destroyListener: Function): void {
    scope.$on('$destroy', () => {
      destroyListener();
    });
  }

  private createTooltip(timeSeries: ITimeSeries) {
    timeSeries.tooltipFunction = (d: any) => {
      var tooltipDate = this.dateService.formatDate(new Date(d.date), EDateFormats.dateOne);

      return "<span style='color: #e8ca14'>" + ((d.name != null) ? d.name : tooltipDate) + '</span>' +
        ((d.name != null) ? '<br/>' + tooltipDate : '') +
        '<br/>' + this.dateService.distanceToNow(new Date(d.date)) +
        '<br/>Score: ' + d.score + ' / ' + d.possibleScore + ' (' +
        (d.value * 100).toFixed(0) + '%)';
    };
  }

  static Factory(): angular.IDirectiveFactory {
    const directive = (timeSeriesFactory,
                      widgetSizeService: IWidgetSizeService,
                      $location: angular.ILocationService,
                      $timeout: angular.ITimeoutService,
                       dateService: DateService) => {
      return new TimeSeriesDirective(timeSeriesFactory, widgetSizeService, $location, $timeout, dateService);
    };
    directive.$inject = [
      Names.Services.timeSeriesChartFactory,
      Names.Services.widgetSize,
      AngularNames.location,
      AngularNames.timeout,
      Names.Services.date
    ];
    return directive;
  }

  constructor(
    private timeSeriesFactory,
    private widgetSizeService: IWidgetSizeService,
    private $location: angular.ILocationService,
    private $timeout: angular.ITimeoutService,
    private dateService: DateService,
  ) {
  }
}
