import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'op-close-btn',
  templateUrl: './op-close-btn.component.html',
  styleUrls: ['./op-close-btn.component.scss']
})
export class OpCloseBtnComponent {
  @Input() disabled?: boolean = false;
  @Output() onClose = new EventEmitter<void>();

}
