<div class="rule-row" [ngClass]="{'has-labels': label}">
  <div class="rule-name" (click)="openRuleEditor.emit(rule)">
    <div class="rule-name-text"
         [matTooltip]="rule.name?.length > 12 ? rule.name : null"
         [matTooltipPosition]="'above'"
         [matTooltipShowDelay]="400"
    >
      {{ rule.name }}
    </div>
    <mat-icon>edit</mat-icon>
  </div>

  <div class="label-and-button-wrapper" [class.no-labels]="!includeLabels">
    <div class="report-card-labels-wrapper"
         *ngIf="includeLabels"
         (click)="$event.stopPropagation()">
      <op-label-manager [compactView]="true"
                        [selectedLabels]="rule.labels"
                        [isReadOnly]="isReadOnly"
                        [chipCountOnly]="chipCountOnly"
                        numberOfRows="2">
      </op-label-manager>
    </div>
    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
  </div>
</div>
