<div class="failed-runs-message">
  <div class="failed-runs-title"><mat-icon>error</mat-icon>The account limit has been reached. The following runs were unable to be started:</div>
  <ng-container *ngIf="failedRuns?.audits?.length > 0">
    <div class="failed-runs-list-title">Audits:</div>
    <ul class="failed-runs-list">
      <li *ngFor="let failure of failedRuns?.audits"
          class="failed-run"
          [matTooltip]="failure?.name?.length > 70 ? failure.name : null"
          matTooltipPosition="above"
          matTooltipShowDelay="200">
        {{failure.name}}
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="failedRuns?.journeys?.length > 0">
    <div class="failed-runs-list-title">Web Journeys:</div>
    <ul class="failed-runs-list">
      <li *ngFor="let failure of failedRuns?.journeys"
          class="failed-run"
          [matTooltip]="failure?.name?.length > 70 ? failure.name : null"
          matTooltipPosition="above"
          matTooltipShowDelay="200"
      >
        {{failure.name}}
      </li>
    </ul>
  </ng-container>
</div>
