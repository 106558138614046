import { Component, Inject, ViewChild } from '@angular/core';
import { IButton } from '@app/models/commons';
import {
  EAlertFilterType,
  EAlertMenuContext,
  EAlertModalType,
  EQuickCreateMode,
  EQuickCreateTitle
} from '@app/components/alert/alert.enums';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAlertQuickCreatePayload, IAlertRequestItem } from '@app/components/alert/alert.models';
import { IAuditModel } from '@app/components/modals/modalData';
import {
  AlertQuickCreateNewComponent
} from '@app/components/alert/alert-quick-create/alert-quick-create-new/alert-quick-create-new.component';
import {
  AlertQuickCreateSubscribeComponent
} from '@app/components/alert/alert-quick-create/alert-quick-create-subscribe/alert-quick-create-subscribe.component';
import { AlertService } from '@app/components/alert/alert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AlertQuickCreateSuccessSnackbarComponent
} from '@app/components/alert/alert-quick-create/alert-quick-create-success-snackbar/alert-quick-create-success-snackbar.component';
import { IUser } from '@app/moonbeamModels';
import { AccountsService } from '@app/components/account/account.service';
import {
  EAlertMetricChangeType,
  EAlertUsageMetricV2
} from '@app/components/alert/alert-logic/alert-logic.enums';
import { forkJoin } from 'rxjs';
import { QuickCreateOpSelectors } from '@app/components/alert/alert-quick-create/alert-quick-create.constants';
import { environment } from '@app/environments/environment';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { AlertMetricChangeOperators } from '../alert-logic/alert-logic.constants';
import { UsageFilterBarService } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.service';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  selector: 'op-alert-quick-create',
  templateUrl: './alert-quick-create.component.html',
  styleUrls: ['./alert-quick-create.component.scss'],
})
export class AlertQuickCreateComponent {
  auditId: number;
  runId: number;
  user: IUser;
  mode: EQuickCreateMode = EQuickCreateMode.Create;
  EQuickCreateMode = EQuickCreateMode;
  audit: IAuditModel;
  title: EQuickCreateTitle = EQuickCreateTitle.Create;
  opSelectors = QuickCreateOpSelectors;
  filters: IAuditReportApiPostBody;

  private saveButton: IButton = {
    label: 'Create',
    primary: true,
    opSelector: this.opSelectors.saveBtn,
    action: () => this.saveAlert(),
  };
  readonly rightFooterButtons = [this.saveButton];

  private createButton: IButton = {
    label: 'Create a new alert',
    action: () => this.switchMode(EQuickCreateMode.Create),
    opSelector: this.opSelectors.toggleView,
    hidden: true,
  };

  private subscribeButton: IButton = {
    label: 'Subscribe to existing alerts',
    action: () => this.switchMode(EQuickCreateMode.Subscribe),
    opSelector: this.opSelectors.toggleView,
    hidden: false,
  };
  leftFooterButtons = [this.createButton, this.subscribeButton];

  @ViewChild(AlertQuickCreateNewComponent) createNew: AlertQuickCreateNewComponent;
  @ViewChild(AlertQuickCreateSubscribeComponent) subscribe: AlertQuickCreateSubscribeComponent;

  constructor(private dialogRef: MatDialogRef<AlertQuickCreateComponent>,
              @Inject(MAT_DIALOG_DATA) public payload: IAlertQuickCreatePayload,
              private alertService: AlertService,
              private snackBar: MatSnackBar,
              private accountsService: AccountsService,
              private reportFilterBarService: AuditReportFilterBarService,
              private usageFilterBarService: UsageFilterBarService,
  ) {
    this.auditId = payload.auditId;
    this.runId = payload.runId;

    if (this.payload.defaultTab) this.switchMode(this.payload.defaultTab);

    this.accountsService.getUser().subscribe((user: IUser) => {
      this.user = user;
    });
  }

  private saveAlert(): void {
    this.mode === EQuickCreateMode.Create ? this.createNewAlert() : this.subscribeToAlerts();
  }

  private createNewAlert(): void {
    if (this.createNew.createForm.invalid) {
      this.createNew.createForm.markAsTouched;
      return;
    }

    const usageAlert = this.isUsageAlert();
    const filters = usageAlert ? (this.payload.filterType === EAlertFilterType.V2 ? this.usageFilterBarService.generateApiPostBody(this.createNew.filters.value) : {}) : this.reportFilterBarService.generateApiPostBody(this.createNew.filters.value || []);
    const assignments = usageAlert ? [] : [{
      itemType: 'AUDIT',
      itemId: this.auditId
    }];

    const alertBody: IAlertRequestItem = {
      name: this.createNew.name.value,
      metricType: this.createNew.metricType.value,
      targetValueComparator: {
        targetValue: AlertUtils.convertToApiValue(this.createNew.metricType.value, this.createNew.metricChangeType.value, this.createNew.value.value),
        operator: this.createNew.targetValueComparator.value,
      },
      // Convert IAuditReportFilter to IAuditReportApiPostBody
      filtersV0: filters,
      emails: this.createNew.emails.value,
      isDefaultForNewDataSources: false,
      notificationPolicy: 'WHEN_NEWLY_TRIGGERED',
      customAlertMessage: '',
      labels: [],
      assignments
    };

    // Usage alerts do not permit a metric change type property
    if (!usageAlert) {
      const operators = AlertMetricChangeOperators.map(operator => operator.comparator);

      alertBody.metricChangeType = operators.includes(alertBody.targetValueComparator.operator)
        ? EAlertMetricChangeType.ValueChange
        : null;
    }

    const cacheKey = `${environment.apiV3Url}web-audits/${this.auditId}/runs/${this.runId}/reports/alert-summary/overview`;

    this.alertService.createAlert(alertBody, cacheKey).subscribe(alert => {
      this.close();
      this.showSuccessSnackbar({
        type: EAlertModalType.Edit,
        alertId: alert.id,
        menuContext: EAlertMenuContext.Audit,
        alertCreated: true,
        noReprocessOption: this.isUsageAlert(),
        ...alertBody,
        filterType: this.payload.filterType
      });
    });
  }

  private subscribeToAlerts(): void {
    if (this.subscribe.selection.selected.length === 0) {
      this.subscribe.invalid = true;
      this.subscribe.showValidation = true;
      return;
    }

    const cacheKey = `${environment.apiV3Url}web-audits/${this.auditId}/runs/${this.runId}/reports/alert-summary/overview`;

    let id: number;
    let requests = this.subscribe.selection.selected.map(alert => {
      id = alert.id;
      delete alert.id;
      // Convert filters to api post body from audit filter
      const usageAlert = this.isUsageAlert();
      alert.filtersV0 = usageAlert
        ? this.usageFilterBarService.generateApiPostBody(this.createNew.filters.value)
        : this.reportFilterBarService.generateApiPostBody(alert.filtersV0);

      return this.alertService.subscribeToAlert(alert, id, this.user.email, cacheKey);
    });

    forkJoin(requests).subscribe((v) => {
      this.close();
      this.showSuccessSnackbar({
        alertId: id,
        type: EAlertModalType.Edit,
        menuContext: EAlertMenuContext.Audit,
        subscribed: true,
        email: this.user.email,
        noReprocessOption: this.isUsageAlert(),
      });
    });
  }

  private isUsageAlert(): boolean {
    const usageMetricTypes: EAlertUsageMetricV2[] = [
      EAlertUsageMetricV2.AccountUsageCurTermAuditPageScannedCount,
      EAlertUsageMetricV2.AccountUsageMonthPaceAuditPageScannedCount,
      EAlertUsageMetricV2.AccountUsageCurTermWebJourneyRunsCount,
      EAlertUsageMetricV2.AccountUsageMonthPaceWebJourneyRunsCount,
      EAlertUsageMetricV2.UsersLoggedInLast30Days,
    ];

    return usageMetricTypes.includes(this.payload.metricType as EAlertUsageMetricV2);
  }

  private switchMode(mode: EQuickCreateMode): void {
    this.mode = mode;
    this.updateTitle();
    this.updateButtons();
  }

  private updateTitle(): void {
    this.title = this.mode === EQuickCreateMode.Create ?
      EQuickCreateTitle.Create
      : EQuickCreateTitle.Subscribe;
  }

  private updateButtons(): void {
    this.subscribeButton.hidden = this.mode === EQuickCreateMode.Subscribe;
    this.createButton.hidden = this.mode === EQuickCreateMode.Create;
    this.saveButton.label = this.mode === EQuickCreateMode.Subscribe ? 'Subscribe' : 'Create';
  }

  close() {
    this.dialogRef.close();
  }

  updateAlertCount(count: number): void {
    this.leftFooterButtons[1].label = `Subscribe to existing alerts (${count})`;
  }

  private showSuccessSnackbar(data): void {
    this.snackBar.openFromComponent(AlertQuickCreateSuccessSnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data,
      panelClass: 'snackbar-wide-width'
    });
  }
}
