<op-modal (onClose)="close()"
          [leftFooterButtons]="leftFooterButtons"
          [rightFooterButtons]="rightFooterButtons">
  <ng-template #headerTemplate>
    <op-modal-header [title]="title" (onClose)="close()"></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
      <div class="content">
        <!--  Create a new alert  -->
        <op-alert-quick-create-new #createNew
                                [class.hidden]="mode === EQuickCreateMode.Subscribe"
                                [payload]="payload"
                                (onClosed)="close()"
        ></op-alert-quick-create-new>
        <!-- Subscribe to existing alerts  -->
        <op-alert-quick-create-subscribe [class.hidden]="mode === EQuickCreateMode.Create"
                                      [payload]="payload"
                                      (updateAlertCount)="updateAlertCount($event)"
        ></op-alert-quick-create-subscribe>
      </div>
  </ng-template>
</op-modal>
