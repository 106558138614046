import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export enum ValueDisplayAs {
  Percentage = 'percentage',
  Count = 'count'
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'percent-numeric-toggle',
  templateUrl: './percent-numeric-toggle.component.html',
  styleUrls: ['./percent-numeric-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PercentNumericToggleComponent {
  @Input() default: ValueDisplayAs = ValueDisplayAs.Percentage;
  @Input() tooltipPercent: string;
  @Input() tooltipNumber: string;
  @Output() toggleChanged: EventEmitter<ValueDisplayAs> = new EventEmitter();

  displayAs(value) {
    value === 'percentage' ? this.toggleChanged.emit(ValueDisplayAs.Percentage) : this.toggleChanged.emit(ValueDisplayAs.Count);
  }
}
