<div id="item-finder" class="flex-col flex-stretch">
  <form class="flex-row item-finder-search-wrapper">
    <div class="item-finder-search">
      <input type="text" class="form-control" [(ngModel)]="itemSearch" [ngModelOptions]="{standalone: true}" placeholder="item to find">
    </div>
    <button type="submit" class="btn btn-default" (click)="doSearch()">Search</button>
  </form>

  <div [class.hidden-section]="!loading">Loading&hellip;</div>
  <div [class.hidden-section]="!error">Something went wrong searching for that item. Try again later.</div>
  <section class="flex-col flex-stretch" [class.hidden-section]="!results">
    <ul class="account-tabs">
      <li [ngClass]="{'active': currentTab === 'audits'}" (click)="currentTab = 'audits'">
        <a>Audits {{results?.audits.length}}</a>
      </li>
      <li [ngClass]="{'active': currentTab === 'sims'}" (click)="currentTab = 'sims'">
        <a>Web Journeys {{results?.simulations.length}}</a>
      </li>
    </ul>

    <div class="flex-col flex-stretch" [class.hidden-section]="currentTab !== 'audits'">

      <div class="flex-table-row flex-header item-finder-header">
        <div class="wrapper text-4">
          <div class="wrapper text-2">

            <div class="flex-table-cell num" (click)="auditSort = setSort('id', auditSort)" [ngClass]="{dropup: auditSort.reverse}">
              <span>Audit ID</span>
              <span class="caret" [class.hidden-section]="auditSort.sortOn !== 'id'"></span>
            </div>
            <div class="flex-table-cell text-xs" (click)="auditSort = setSort('recentRunId', auditSort)" [ngClass]="{dropup: auditSort.reverse}">
              <span>Run ID</span>
              <span class="caret" [class.hidden-section]="auditSort.sortOn !== 'recentRunId'"></span>
            </div>
            <div class="flex-table-cell num" (click)="auditSort = setSort('domainId', auditSort)" [ngClass]="{dropup: auditSort.reverse}">
              <span>Sub-Folder ID</span>
              <span class="caret" [class.hidden-section]="auditSort.sortOn !== 'domainId'"></span>
            </div>
            <div class="flex-table-cell text" (click)="auditSort = setSort('description', auditSort)" [ngClass]="{dropup: auditSort.reverse}">
              <span>Description</span>
              <span class="caret" [class.hidden-section]="auditSort.sortOn !== 'description'"></span>
            </div>
            <div class="flex-table-cell num" (click)="auditSort = setSort('pages', auditSort)" [ngClass]="{dropup: auditSort.reverse}">
              <span>Pages</span>
              <span class="caret" [class.hidden-section]="auditSort.sortOn !== 'pages'"></span>
            </div>
            <div class="flex-table-cell text" (click)="auditSort = setSort('company', auditSort)" [ngClass]="{dropup: auditSort.reverse}">
              <span>Company</span>
              <span class="caret" [class.hidden-section]="auditSort.sortOn !== 'company'"></span>
            </div>
            <div class="flex-table-cell num" (click)="auditSort = setSort('accountId', auditSort)" [ngClass]="{dropup: auditSort.reverse}">
              <span>Account ID</span>
              <span class="caret" [class.hidden-section]="auditSort.sortOn !== 'accountId'"></span>
            </div>
            <div class="flex-table-cell text" ng-if="isSysAdmin">
              <span>Remove all runs</span>
            </div>

          </div>
        </div>
      </div>
      <div class="flex-table-content item-finder-table-body">
        <div class="flex-table-row" *ngFor="let audit of results?.audits" (click)="goToAudit(audit)">
          <div class="wrapper text-2">

            <div class="flex-table-cell num"><span>{{audit.id}}</span></div>
            <div class="flex-table-cell text-xs"><span>{{audit.mostRecentRunId || "Hasn't been run yet"}}</span></div>
            <div class="flex-table-cell num"><span>{{audit.domainId}}</span></div>
            <div class="flex-table-cell text"><span>{{audit.description}}</span></div>
            <div class="flex-table-cell num"><span>{{audit.pages}}</span></div>
            <div class="flex-table-cell text"><span>{{audit.company}}</span></div>
            <div class="flex-table-cell num"><span>{{audit.accountId}}</span></div>
            <div class="flex-table-cell text remove-all-runs-column" ng-if="isSysAdmin">
              <i class="icon-delete remove-all-runs-button" (click)="removeAllRunsOfAudit($event, audit)"></i>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="flex-col flex-stretch" [class.hidden-section]="currentTab !== 'sims'">
      <div class="flex-table-row flex-header item-finder-header">
        <div class="wrapper text-2">

          <div class="flex-table-cell num" (click)="simulationSort = setSort('id', simulationSort)" [ngClass]="{dropup: simulationSort.reverse}">
            <span>Journey ID</span>
            <span class="caret" [class.hidden-section]="simulationSort.sortOn !== 'id'"></span>
          </div>
          <div class="flex-table-cell text-xs" (click)="simulationSort = setSort('recentRunId', simulationSort)" [ngClass]="{dropup: simulationSort.reverse}">
            <span>Run ID</span>
            <span class="caret" [class.hidden-section]="simulationSort.sortOn !== 'recentRunId'"></span>
          </div>
          <div class="flex-table-cell num" (click)="simulationSort = setSort('domainId', simulationSort)" [ngClass]="{dropup: simulationSort.reverse}">
            <span>Sub-Folder ID</span>
            <span class="caret" [class.hidden-section]="simulationSort.sortOn !== 'domainId'"></span>
          </div>
          <div class="flex-table-cell text" (click)="simulationSort = setSort('description', simulationSort)" [ngClass]="{dropup: simulationSort.reverse}">
            <span>Description</span>
            <span class="caret" [class.hidden-section]="simulationSort.sortOn !== 'description'"></span>
          </div>
          <div class="flex-table-cell text" (click)="simulationSort = setSort('company', simulationSort)" [ngClass]="{dropup: simulationSort.reverse}">
            <span>Company</span>
            <span class="caret" [class.hidden-section]="simulationSort.sortOn !== 'company'"></span>
          </div>
          <div class="flex-table-cell num" (click)="simulationSort = setSort('accountId', simulationSort)" [ngClass]="{dropup: simulationSort.reverse}">
            <span>Account ID</span>
            <span class="caret" [class.hidden-section]="simulationSort.sortOn !== 'accountId'"></span>
          </div>
          <div class="flex-table-cell text" ng-if="isSysAdmin">
            <span>Remove all runs</span>
          </div>

        </div>
      </div>
      <div class="flex-table-content item-finder-table-body">
        <div class="flex-table-row" *ngFor="let simulation of results?.simulations" (click)="goToSimulation(simulation)">
          <div class="wrapper text-2">

            <div class="flex-table-cell num"><span>{{simulation.id}}</span></div>
            <div class="flex-table-cell text-xs"><span>{{simulation.mostRecentRunId || "Hasn't been run yet"}}</span></div>
            <div class="flex-table-cell num"><span>{{simulation.domainId}}</span></div>
            <div class="flex-table-cell text"><span>{{simulation.description}}</span></div>
            <div class="flex-table-cell text"><span>{{simulation.company}}</span></div>
            <div class="flex-table-cell num"><span>{{simulation.accountId}}</span></div>
            <div class="flex-table-cell text remove-all-runs-column" ng-if="isSysAdmin">
              <i class="icon-delete remove-all-runs-button" (click)="removeAllRunsOfWebJourney($event, simulation)"></i>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>
