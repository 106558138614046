import {IUtilitiesService} from './utilitiesService';
import { ILabel } from '@app/components/shared/services/label.service';
import * as angular from 'angular';


export class QuickLabelsFilter {
    static factory(utilitiesService: IUtilitiesService) {
      function nullSafeLowerCase(str) {
        return str ? str.toLowerCase() : '';
      }

      function containsText(a: string = '', b: string = '') {
        return a.indexOf(b) > -1;
      }

      function labelsContainsQuery(labels: Array<ILabel>, query: string) {
        if (!angular.isArray(labels)) {
          return false;
        }

        return labels.reduce((prev: boolean, current) => {
          return prev || containsText(nullSafeLowerCase(current.name), query);
        }, false);
      }

      return (input: Array<any>, nameKey: string, labelsKey: string, q: string = '') => {
        if (!angular.isArray(input)) {
          return;
        }

        let query = q.toLowerCase();

        return input.filter(obj => {
          let name = nullSafeLowerCase(utilitiesService.getNestedObjectByKey(obj, nameKey));
          let labels: Array<ILabel> = utilitiesService.getNestedObjectByKey(obj, labelsKey);

          return containsText(name, query) || labelsContainsQuery(labels, query);
        });
      };
    }
}
