import { ExportCenterUrlBuilders } from '@app/components/export-center/export-center.constants';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  of,
  ReplaySubject,
  Subject,
  throwError
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  pluck,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AccountsService } from '@app/components/account/account.service';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { IUser } from '@app/moonbeamModels';
import { OpErrorToastComponent } from '../shared/components/toasts/op-error-toast/op-error-toast.component';
import { ExportCenterService } from './export-center.service';
import {
  IExport,
  IExportCenterApiError,
  IExportCenterTableState,
  IExportsResponse,
  IResendExportEmailRequest
} from './export-center.models';
import { EExportCenterDataStatus, EExportCenterTableColumn } from './export-center.enums';
import { SendReportModalComponent } from './components/send-report-modal/send-report-modal.component';
import { SendReportSuccessSnackbarComponent } from './components/send-report-success-snackbar/send-report-success-snackbar.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'export-center',
  templateUrl: './export-center.component.html',
  styleUrls: ['./export-center.component.scss'],
})
export class ExportCenterComponent implements OnInit, OnDestroy {

  userIdToUserMap: Map<number, IUser>;
  exportsDTO: IExportsResponse;
  dataStatus = EExportCenterDataStatus.notInitialized;

  private tableStateSubject = new BehaviorSubject<IExportCenterTableState>({
    page: 0,
    size: 100,
    sortBy: EExportCenterTableColumn.DateExported,
    sortDesc: true
  });

  initialSearchText: string;
  private typingSearchTextSubject = new ReplaySubject<string>(1);

  private destroy$ = new Subject<void>();

  readonly dataStatusType = EExportCenterDataStatus;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private exportCenterService: ExportCenterService,
    private modalNgService: OpModalService,
    private accountsService: AccountsService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    // 1. store searching text in the `searchText` query parameter
    this.typingSearchTextSubject.pipe(
      debounceTime(600),
      distinctUntilChanged(),
      filter(searchText => searchText.length === 0 || searchText.length > 2),
    ).subscribe(
      searchText => {
        const queryParam = searchText ? `?searchText=${encodeURIComponent(searchText)}` : '';
        this.router.navigateByUrl(ExportCenterUrlBuilders.base() + queryParam);
      }
    );

    // 2. listen for the `searchText` updates and reset page index
    const searchText$ = this.route.queryParams.pipe(
      pluck('searchText'),
      distinctUntilChanged(),
      tap(searchText => {
        // set the initial value for the OpClearableInput component
        this.initialSearchText = searchText;

        // we should start searching from the first page
        this.tableStateSubject.next({
          ...this.tableState,
          page: 0
        });
      })
    );

    // 3. fetch exports on updating pagination / sorting or searching
    combineLatest([this.tableStateSubject, searchText$])
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(0), // trigger only once if both Subjects are emitting values simultaneously
        switchMap(([tableState, searchText]) => {
          const fetcher = this.dataStatus === EExportCenterDataStatus.notInitialized
            ? this.fetchAllData.bind(this)
            : this.fetchExports.bind(this);
          return of(null).pipe(
            tap(() => this.dataStatus = EExportCenterDataStatus.loading),
            switchMap(() => fetcher(tableState, searchText)),
            finalize(() => this.dataStatus = EExportCenterDataStatus.loaded)
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.tableStateSubject.complete();
    this.typingSearchTextSubject.complete();

    this.destroy$.next();
    this.destroy$.complete();
  }

  handleSort(sort: MatSort) {
    this.tableStateSubject.next({
      page: 0,
      size: this.tableState.size,
      sortBy: sort.active as EExportCenterTableColumn,
      sortDesc: sort.direction === 'desc'
    });
  }

  handlePagination(page: PageEvent) {
    this.tableStateSubject.next({
      ...this.tableState,
      page: page.pageIndex
    });
  }

  handleSearch(searchText: string) {
    this.typingSearchTextSubject.next(searchText.trim());
  }

  private get tableState() {
    return this.tableStateSubject.getValue();
  }

  private fetchAllData(tableState: IExportCenterTableState, searchText: string) {
    return forkJoin([
      this.fetchUsers(),
      this.fetchExports(tableState, searchText),
    ]);
  }

  private fetchUsers() {
    return this.accountsService.getUsers().pipe(
      tap(users => {
        this.userIdToUserMap = ArrayUtils.toMap(users, 'id');
      })
    );
  }

  private fetchExports(tableState: IExportCenterTableState, searchText: string) {
    return this.exportCenterService.getExports(tableState, searchText).pipe(
      tap(exportsResponse => {
        this.exportsDTO = exportsResponse;
      }),
      catchError((error: IExportCenterApiError) => {
        const message = error?.message ?? 'Error: Could not get exports. Please refresh the page and try again.';
        this.showErrorSnackbar(message);
        return throwError(error);
      })
    );
  }

  copyLink({exportDownloadLink}: IExport) {
    this.clipboard.copy(exportDownloadLink);
  }

  downloadExport({exportDownloadLink}: IExport) {
    window.open(exportDownloadLink, '_self');
  }

  sendReport({exportId, exportName}: IExport) {
    this.modalNgService.openModal(
      SendReportModalComponent,
      {
        data: {
          title: exportName
        }
      })
      .afterClosed()
      .subscribe(
        (form: IResendExportEmailRequest) => {
          if (!form) return;
          this.exportCenterService.resendExportToEmails(exportId, form).subscribe(
            () => this.showSuccessSnackbar(form),
            error => this.showErrorSnackbar(error?.message ?? 'Error: Could not resend exports. Please try again.')
          )
        }
      );
  }

  private showSuccessSnackbar(form: IResendExportEmailRequest) {
    // running in the zone ensures snackbar is positioned correctly when it appears
    // Source: https://stackoverflow.com/a/53502137
    this.zone.run(() => {
      this.snackBar.openFromComponent(
        SendReportSuccessSnackbarComponent,
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: { emails: form.toAddresses },
          duration: 5000,
          panelClass: ['ngx-toastr', 'export-center-send-report-snackbar']
        }
      );
    });
  }

  private showErrorSnackbar(message: string) {
    // running in the zone ensures snackbar is positioned correctly when it appears
    // Source: https://stackoverflow.com/a/53502137
    this.zone.run(() => {
      this.snackBar.openFromComponent(OpErrorToastComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 5000,
        data: message
      });
    });
  }
}
