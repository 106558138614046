import { IComparisonVariableSelectorForm } from './../../shared/comparison-variable-selector/comparison-variable-selector.models';
import { IAuditModel } from '@app/components/modals/modalData';
import { IAuditRunSummary } from '@app/components/domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { EDateFormats, formatDate } from '@app/components/date/date.service';

export interface IAuditInfo { 
  id: number; 
  name: string; 
  runs: IAuditInfoRun[];
}

export interface IComparisonSetupModel {
  name: string;
  labels: number[];
  destinationAudit: IComparisonSetupAuditModel;
  baselineAudit: IComparisonSetupAuditModel;
  tags: IComparisonVariableSelectorForm[];
  ignoreUrlDomain: boolean;
  ignoreQueryParameters: boolean;
}

interface IComparisonSetupAuditModel {
  id: number;
  run: number;
}

export interface IComparisonSourceFormModel {
  tagId: number;
  variables?: string[];
  comparisonEnabled: boolean;
}

export namespace IAuditInfo {
  export function buildFromIAuditModel(audit: IAuditModel): IAuditInfo {
    return {
      id: audit.id,
      name: audit.name,
      runs: audit.runs.map(IAuditInfoRun.buildFromIAuditRunSummary)
    };
  }
}

export interface IAuditInfoRun {
  id: number; 
  name: string;
}

export namespace IAuditInfoRun {
  export function buildFromIAuditRunSummary(run: IAuditRunSummary): IAuditInfoRun {
    return {
      id: run.id,
      name: buildRunName(run)
    };
  }

  function buildRunName(run: IAuditRunSummary): string {
    return formatDate(new Date(run.completed), EDateFormats.dateEleven);
  }
}
