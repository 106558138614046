import * as angular from 'angular';

export class TagContactsDirective implements angular.IDirective {

    template = require('@app/components/account/manageTags/tagContactsPopover/tagContactsPopover.html');
    restrict = 'E';
    scope = {
      tagIcon: '@',
      tagName: '@',
      tagCategory: '@',
      buttonLabel: '@',
      contacts: '='

    };

    static factory(): angular.IDirectiveFactory {
      const directive = () => {
        return new TagContactsDirective();
      };
      return directive;
    };

  }


