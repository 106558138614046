import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { INavSection } from './modal-sidebar.models';
import { KeyboardShortcutsService } from '../../shared/services/keyboard-shortcuts/keyboard-shortcuts.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'modal-sidebar',
  templateUrl: './modal-sidebar.component.html',
  styleUrls: ['./modal-sidebar.component.scss']
})
export class ModalSidebarComponent implements OnChanges {

  @Input() title: string;
  @Input() navSections: INavSection[];

  enabledFeatures: string[] = [];

  constructor(
    private authentication: AuthenticationService,
    private keyboardShortcutsService: KeyboardShortcutsService
  ) {
    this.authentication.getFeaturesWithCache().subscribe(features => this.enabledFeatures = features);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('navSections') && this.navSections?.length) {
      const list = this.navSections.flatMap(item => item.navs.map(nav => nav.link));
      this.keyboardShortcutsService.registerAuditReportList(list);
    }
  }
}
