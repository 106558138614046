import { Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { environment } from '@app/environments/environment';
import { Observable } from 'rxjs';
import { ITagInitiatorNode } from './tag-initiators.models';

@Injectable({
  providedIn: 'root'
})
export class TagInitiatorsService {

  constructor(private apiService: ApiService) {}

  getTagInitiators(url: string, itemType: string, runId: number): Observable<ITagInitiatorNode> {
    return this.apiService.post(`${environment.apiV3Url}initiators/page`, {
      url,
      itemType,
      runId
    });
  }

  prepareTagInitiators(node: ITagInitiatorNode): ITagInitiatorNode {
    if (!node?.children) return node;

    return {
      ...node,
      children: node.children
        .map(child => this.prepareTagInitiators(child))
        .filter(child => child.tagId != 0 || (child.children && child.children.length > 0))
    };
  }

  /**
   * Returns the beginning and end of `url`, joined with ellipses (...), at most `maxLength` characters long
   */
  truncateMiddle(url: string, maxLength: number): string {
    if (url.length < maxLength) {
      return url;
    } else {
      const halfLength = Math.floor(maxLength / 2);
      return url.substring(0, halfLength) + '...' + url.substring(url.length - halfLength, url.length);
    }
  }

  getIconByFileExtension(name: any): string {
    if (!name) return '';
    if (name.match(/.js/)) {
      return '/images/javascript_icon.svg';
    } else if (name.match(/.css/)) {
      return '/images/css_icon.svg';
    } else if (name.match(/.html/)) {
      return '/images/html_icon.svg';
    }
  }
}
