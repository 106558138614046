<op-modal [rightFooterButtons]="rightFooterButtons" class="cc-create-modal-wrapper" (onClose)="closeModal(true)">
  <ng-template #headerTemplate>
    <op-modal-header  [title]="modalTitle"
                      (onClose)="closeModal(true)">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div *ngIf="saving" class="saving-spinner-overlay">
      <mat-spinner [diameter]="40" [strokeWidth]="4"></mat-spinner>
    </div>
    <form class="flex-col flex-stretch flex-form cc-form"
          [class.cc-setup]="currentStep === ccCreateSteps.SETUP"
          [formGroup]="ccForm"
          validate-form>

      <!--  When creating a Consent Category  -->
      <div [class.hidden]="currentStep !== ccCreateSteps.SETUP">
        <div *ngIf="!data.editing || (data.editing && data.useSelectedDataOnlyCreate)">
          <div class="cc-field-text">{{CONSTANTS.ccCreate.giveAName}}</div>
          <div class="cc-field-wrapper">
            <div class="cc-name">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>{{CONSTANTS.ccCreate.nameLabel}}</mat-label>
                <input matInput
                       formControlName="name"
                       [attr.op-selector]="CONSTANTS.selectors.name">
                <mat-error *ngIf="name.hasError('duplicate')">A Consent Category with this name already exists.</mat-error>
                <mat-error *ngIf="name.hasError('limitExceeded')">Account limit reached. Delete a Consent Category to create a new one.</mat-error>
                <mat-error *ngIf="name.hasError('required')">This field is required</mat-error>
              </mat-form-field>
            </div>
            <div class="cc-chip-wrapper">
              <op-chip-selector formControlName="labels"
                                [label]="CONSTANTS.ccCreate.labelsLabel"
                                appearance="outline"
                                class="cc-labels"
                                [attr.op-selector]="CONSTANTS.selectors.labels"
                                [allItems]="allLabels"
                                [placeholder]="{ hasSelected: '+ Label', hasNoSelected: '+ Label' }"
                                (onCreateChip)="createLabel($event)">
              </op-chip-selector>
            </div>
          </div>
          <div class="cc-field-wrapper cc-status-field">
            <div class="cc-status-selector">
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>STATUS TYPE OF ITEMS IN CONSENT CATEGORY</mat-label>
                <mat-select formControlName="type" (selectionChange)="updateFooterButtonsDisabledStatus()">
                  <mat-option value="approved">Approved</mat-option>
                  <mat-option value="unapproved">Unapproved</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="cc-default-checkbox-wrapper">
            <mat-checkbox
              color="primary"
              [formControl]="isDefaultCC"
              class="default-cc-checkbox"
              matTooltipPosition="below">
              Add this as a default consent category to all audits I create <span class="cc-note">(note: all default CCs must be of the same type)</span>.
            </mat-checkbox>
          </div>
        </div>

        <ng-container *ngIf="!data.useSelectedDataOnlyCreate">
          <div class="cc-field-text">
            {{
              data.editing
                ? CONSTANTS.ccAddToExisting.instruction
                : CONSTANTS.ccCreate.selectDataTypeCreate
            }}
          </div>
          <div class="cc-field-wrapper">
            <cc-data-sources-filter-bar></cc-data-sources-filter-bar>
          </div>
          <div *ngIf="loading" class="spinner-wrapper">
            <mat-spinner [diameter]="40" [strokeWidth]="4"></mat-spinner>
          </div>
        </ng-container>
        <div class="report-selection content-wrap" [class.hidden]="loading || data.useSelectedDataOnlyCreate">
          <div class="overflow-wrap">
            <table mat-table matSort [dataSource]="dataSource" class="cc-create-table">

              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let row; let i = index;" valign="middle">
                  <mat-checkbox (change)="handleCheckboxClick(i)"
                                [checked]="selection.isSelected(row)"
                                color="primary">
                  </mat-checkbox>
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="itemName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Audit Name</th>
                <td mat-cell
                    *matCellDef="let report"
                    matTooltip="{{report.itemName}}"
                    matTooltipPosition="above"
                    [matTooltipDisabled]="report.itemName?.length < 40">
                  {{ report.itemName }}
                </td>
              </ng-container>

              <!-- Run date Column -->
              <ng-container matColumnDef="lastRun">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">Run Date</th>
                <td mat-cell *matCellDef="let report; let index;" class="center-text">
                  {{ report?.lastCompletedRun ? formatDate(report.lastCompletedRun.completedAt) : '' }}
                </td>
              </ng-container>

              <!-- Labels Column -->
              <ng-container matColumnDef="labels">
                <th mat-header-cell *matHeaderCellDef class="center-text">Labels</th>
                <td mat-cell
                    *matCellDef="let report"
                    class="center-text"
                    matTooltip="{{getLabels(report.labels)}}"
                    matTooltipPosition="above"
                    [matTooltipDisabled]="report?.labels?.length && getLabels(report.labels)?.length < 20">{{ getLabels(report.labels) }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <mat-paginator [hidePageSize]="true" [length]="paginationState.length" [pageSize]="paginationState.pageSize"></mat-paginator>
        </div>
      </div>
      <div class="button-step-container" *ngIf="currentStep !== ccCreateSteps.SETUP">
        <button [class.active]="currentStep === ccCreateSteps.COOKIES"
                type="button"
                (click)="goToStep(1)">Cookies</button>
        <button [class.active]="currentStep === ccCreateSteps.TAGS"
                type="button"
                (click)="goToStep(2)">Tags</button>
        <button [class.active]="currentStep === ccCreateSteps.REQUEST_DOMAINS"
                type="button"
                (click)="goToStep(3)">Request Domains & Geolocations</button>
      </div>
      <cc-create-cookies [class.hidden]="currentStep !== ccCreateSteps.COOKIES"
                         [formControl]="selectedCookies"
                         [continueToSource$]="continueToSource$"
                         [reports]="selection?.selected"
                         [noSelectionDisplay]="data.noSelectionDisplay"
                         [existingCookies]="existingCookies">
      </cc-create-cookies>
      <cc-create-tags [class.hidden]="currentStep !== ccCreateSteps.TAGS"
                      [formControl]="selectedTags"
                      [noSelectionDisplay]="data.noSelectionDisplay"
                      [continueToSource$]="continueToSource$"
                      [reports]="selection?.selected"
                      [existingTags]="existingTags">
      </cc-create-tags>
      <cc-create-request-domains [class.hidden]="currentStep !== ccCreateSteps.REQUEST_DOMAINS"
                                 [formControl]="selectedRequestDomains"
                                 [continueToSource$]="continueToSource$"
                                 [noSelectionDisplay]="data.noSelectionDisplay"
                                 [reports]="selection?.selected"
                                 [existingRequestDomains]="existingRequestDomains">
      </cc-create-request-domains>
    </form>
  </ng-template>
</op-modal>
