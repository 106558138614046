import * as angular from 'angular';
import { IApiService } from '../api/apiService';
import { IUserAgentService, IUserAgent } from './userAgentService.models';
import { environment } from '@app/environments/environment';

interface ISortableUserAgent {
  name: string;
  label: string;
  desc: IUserAgentDescription;
}

interface IUserAgentDescription {
  browser: string;
  version: number;
  os: string;
}

const USER_AGENT_DESC_REGEXP = /(\w*)\s?\((\d*)\)\s?-\s?(.*)/;
const USER_AGENT_CAPTURE_GROUP_NUM = 3;

function parseAgentDesc(userAgentDesc: string): IUserAgentDescription {
  let match = USER_AGENT_DESC_REGEXP.exec(userAgentDesc);
  if (!match || match.length != USER_AGENT_CAPTURE_GROUP_NUM + 1) {
    return {
      browser: userAgentDesc,
      version: null,
      os: null
    };
  }

  let version = parseInt(match[2]) || 0;

  return {
    browser: match[1],
    os: match[3],
    version
  };
}

function compareUserAgent(a: ISortableUserAgent, b: ISortableUserAgent): number {
  if (a.desc.browser < b.desc.browser) {
    return -1;
  }

  if (a.desc.browser > b.desc.browser) {
    return 1;
  }

  return a.desc.version - b.desc.version || (a.desc.os < b.desc.os ? -1 : a.desc.os > b.desc.os ? 1 : 0);
}

function makeSortableUserAgent(agent: IUserAgent): ISortableUserAgent {
  let desc = parseAgentDesc(agent.label);
  return <ISortableUserAgent>{
    name: agent.name,
    label: desc.browser && desc.version && desc.os ? `${desc.browser} (${desc.version}) - ${desc.os}` : agent.label,
    desc
  };
}

export const userAgentService = ['$http', '$q', 'apiService',
  function ($http: angular.IHttpService, $q: angular.IQService, apiService: IApiService): IUserAgentService {
    return {
      getUserAgents: function () {
        return apiService.handleResponse($http.get(environment.apiUrl + 'user-agents')).then((userAgents: Array<IUserAgent>) => {
          return userAgents
            .map(agent => makeSortableUserAgent(agent))
            .sort(compareUserAgent);
        });
      }
    };
  }
];
