import * as angular from 'angular';

export class HighlightTextFilter {

    public static factory($sce: angular.ISCEService) {
      return (text, phrase) => {
        if (phrase) text = text.replace(new RegExp('(' + phrase + ')', 'gi'), '<span class="highlighted">$1</span>');
        return $sce.trustAsHtml(text);
      };
    }

}

