<div class="wj-tag-report" *ngIf="!loading">

  <div class="account-setup" *ngIf="showComparisonSetupBanned()">
    <div class="left-side">
      <mat-icon>warning</mat-icon>
      <span class="title">For comparison results setup your account comparison configuration.</span>
    </div>

    <button class="setup-btn" mat-raised-button color="primary" (click)="goToAccountSettings()">
      Setup Tag Comparisons
    </button>
  </div>

  <div class="header-controls" *ngIf="tags?.length > 0">
    <span class="search-ctrl">
      <mat-form-field [floatLabel]="'never'">
        <input matInput type="search" placeholder="Filter by Tag, Variable or Variable Value"
              [(ngModel)]="filterBy" (ngModelChange)="onFilterChanging($event)"
        />
        <button matSuffix mat-icon-button aria-label="Clear" (click)="onFilterChanging('')" *ngIf="filterBy">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </span>

    <span class="right-side-ctrl" *ngIf="!comparisonError" [class.no-comparisons]="showComparisonSetupBanned()">
      <span class="primary-tag-ctrl">
        <mat-slide-toggle color="primary"
                          [disabled]="primaryTagFilteringDisabled"
                          [checked]="primaryTagsToggleValue"
                          [matTooltip]="'Add primary tags to enable tag filtering'"
                          [matTooltipDisabled]="!primaryTagFilteringDisabled"
                          (change)="onPrimaryTagsToggleValueChange.emit($event.checked)">
          Primary Tags Only
        </mat-slide-toggle>
      </span>

      <mat-slide-toggle [(ngModel)]="showDiffsOnly" (ngModelChange)="filterTags()"
                        *ngIf="!showComparisonSetupBanned() && showComparison"
                        color="primary">
        Only Show Changes
      </mat-slide-toggle>
    </span>
  </div>

  <h4 class="no-tags">{{getErrorMessage()}}</h4>

  <div class="tag-report-accordion-wrap">
    <div class="accordion-header">
      <span class="cell tag">Vendor</span>
      <span class="cell load-time">
        <span>Tag&nbsp;</span>
        <span>Load Time</span>
      </span>
      <span class="cell status-code">
        <span>Tag&nbsp;</span>
        <span>Status Code</span>
      </span>
      <span class="cell comparison-results">Comparison Results</span>
      <span class="add-rule"></span>
    </div>
    <op-accordion *ngIf="filteredTags.length else noTags">
      <mat-accordion displayMode="flat" class="tag-report-accordion" #container [multi]="true">
        <mat-expansion-panel
            *ngFor="let tag of filteredTags; trackBy: trackTagResult"
            hideToggle="true"
            class="tag-report-expansion-panel"
            [class.not-found]="tag.stub"
            [disabled]="tag.stub"
            op-selector="tag-expansion-panel"
            [expanded]="tag.expanded"
            (opened)="opened(tag)"
            (closed)="closed(tag)">
          <mat-expansion-panel-header [class.has-variables]="showComparison || tag.variables?.length > 0">
            <div class="panel-header">

              <div class="arrow">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </div>

              <div class="cell tag-name-icon">
                <span class="tag-icon">
                  <img src="{{ tag.tagIcon }}">
                </span>
                <span class="tag-name-account">
                  <span class="tag-name-wrp">
                    <span class="tag-name" overflowFormatter>{{ tag.tagName }}</span>
                    <span class="duplicates-count"
                          [class.duplicate-scoring]="tag.duplicateScoring"
                          *ngIf="tag.duplicates > 1"
                          [matTooltip]="tag | duplicateTagError"
                          matTooltipPosition="above"
                          matTooltipClass="duplicate-tag-tooltip">
                      {{tag.duplicates}}
                    </span>
                  </span>
                  <span class="tag-account">
                    <div *ngIf="!tag.stub" overflowFormatter>Account ID: {{ tag.account }}</div>
                    <div *ngIf="tag.stub">–</div>
                  </span>
                </span>
              </div>

              <span class="time-status">
                <ng-container *ngIf="!tag.stub">
                  <span class="cell load-time">
                    <ng-container *ngIf="isNumber(tag.stoppedAt) && isNumber(tag.startedAt); else tagLoadTime">
                      {{ tag.stoppedAt - tag.startedAt }} ms
                    </ng-container>
                    <ng-template #tagLoadTime>---</ng-template>
                  </span>

                  <span class="cell status-code">
                    <ng-container *ngIf="tag.statusCode || tag.status; else tagStatusCode">{{ tag.statusCode || tag.status }}</ng-container>
                    <ng-template #tagStatusCode><span class="red">0</span></ng-template>
                  </span>
                </ng-container>
              </span>

              <ng-container *ngIf="showComparison && !tag.stub && !comparisonError; else notRunComparison">
                <span class="cell comparison-results"
                      *ngIf="tag.comparisonEnabled && tag.matchCriteriaEnabled; else noComparisonsEnabled"
                      [class.has-changes]="tag.varsDifferences > 0"
                      [matTooltip]="tagInstanceIdToDetailsMap?.get(tag.tagInstanceId)?.alignmentVariables | matchCriteriaFormatter"
                      [matTooltipDisabled]="comparisonError"
                      matTooltipPosition="above">
                  {{ tag.visibleOutcome }}
                </span>

                <ng-template #noComparisonsEnabled>
                  <span class="cell comparison-results not-enabled" [class.link]="!maxComparisonTags"
                        *ngIf="!tag.comparisonEnabled"
                        [matTooltip]="
                          maxComparisonTags ?
                          'You\'ve already enabled your account maximum number tags for comparison. To enable additional please contact your ObservePoint representative.' :
                          'Enable this tag for comparison in account settings'"
                        [matTooltipDisabled]="comparisonError"
                        matTooltipPosition="above"
                        (click)="!maxComparisonTags && goToAccountSettings($event)">
                    Not Enabled
                  </span>
                  <span class="cell comparison-results" *ngIf="tag.comparisonEnabled && !tag.matchCriteriaEnabled"
                        matTooltip="Enable this tag for variable level comparison by specifying match criteria for variables."
                        [matTooltipDisabled]="comparisonError"
                        matTooltipPosition="above">
                    <mat-icon color="primary">warning</mat-icon>
                    <span>Not Setup</span>
                  </span>
                </ng-template>
              </ng-container>

              <ng-template #notRunComparison>
                <span class="cell comparison-results"
                      matTooltip="Comparison results will be available after next run is complete"
                      [matTooltipDisabled]="!isFirstRun || tag.stub || comparisonError"
                      matTooltipPosition="above">
                      Missing Tag
                </span>
              </ng-template>

              <div [style.visibility]="tag.stub ? 'hidden' : 'visible'" class="add-rule" [class.no-add]="!canAddRule"
                  (click)="addRule($event, tag)" *ngIf="!isReadOnly"
                  [matTooltip]="canAddRule ?
                    'Add Rule' :
                    'A rule cannot be added because this action no longer exists. Please re-run the journey to update this report.'
                  "
                  matTooltipPosition="above"
                  op-selector="tag-add-rule-button">
                <mat-icon>add_circle_outline</mat-icon>
              </div>
            </div>

            <div class="panel-subheader" *ngIf="showComparison || tag.variables?.length > 0" (click)="$event.stopPropagation()">
              <tag-report-table-header [showComparison]="showComparisonForTag(tag)"></tag-report-table-header>
            </div>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <expansion-panel-table>
              <wj-results-tag-report-table [tagVariables]="tag.variables"
                                           [comparisonVariables]="tag.comparisonVariables"
                                           [excludedVars]="tag.excludedVars"
                                           [excludedVarsSnapshot]="tag.excludedVarsSnapshot"
                                           [showDiffsOnly]="showDiffsOnly"
                                           [showComparison]="showComparisonForTag(tag)"
                                           [tagId]="tag.tagId"
                                           [actionId]="action?.actionId">
              </wj-results-tag-report-table>
            </expansion-panel-table>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </op-accordion>
    <ng-template #noTags>
      <p class="no-tags">There are no tags {{ (showDiffsOnly || filterBy) && 'with applied search criteria' }}</p>
    </ng-template>
  </div>
</div>

<mat-spinner *ngIf="loading" [diameter]="75" [strokeWidth]="4"></mat-spinner>
