<div class="title-container">
  <h1 class="title">
    Triggered Alerts
    <span class="icon-wrapper" [class.highlight]="infoIsExpanded" (click)="infoIsExpanded = !infoIsExpanded">
      <mat-icon class="title-icon">help_outline</mat-icon>
      <mat-icon class="expansion-icon">expand_more</mat-icon>
    </span>
  </h1>
  <div class="controls">
    <button mat-flat-button (click)="goToAlertsLibrary()">
      <mat-icon>notifications</mat-icon>
      View Alert Library
    </button>

    <button mat-flat-button (click)="exportTriggeredAlerts()">
      <mat-icon>cloud_download</mat-icon>
    </button>
  </div>
</div>

<div class="subtitle-container">
  For most recent runs only
</div>

<op-info-panel [expanded]="infoIsExpanded" class="info-panel">
  <p class="info-paragraph">
    Triggered alerts have failing alert logic.
    For example, an audit found 5 unapproved cookies and the alert logic was set <b>unapproved cookies > 0</b>.
    All data sources to which you have access and have triggered alerts are displayed.
    Use filters to narrow the report by label, run date range, report metric, etc.
    Only the most recently completed runs are shown. <a href="https://help.observepoint.com/article/477-triggered-alerts-report" target="_blank">Learn more</a>.
  </p>
</op-info-panel>

<triggered-alerts-filter-bar></triggered-alerts-filter-bar>

<div class="static-header-cards" *ngIf="triggeredAlertsTotal !== 0">
  <op-summary-card headerText="Triggered Alerts"
                   [totalValue]="totalSummary?.triggeredAlertsInMostRecentRunCount"
                   [filteredValue]="filteredSummary?.triggeredAlertsInMostRecentRunCount"
                   [showTotalValueWhenFiltered]="true"
                   [widgetState]="filterableWidgetState"
                   [valueColorHighlight]="filteredSummary?.triggeredAlertsInMostRecentRunCount > 0 ? EColorHighlightMeaning.NEGATIVE : EColorHighlightMeaning.NEUTRAL"
                   tooltipText="Count of Triggered Alerts in the most recent run of all audits using specified alerts">
  </op-summary-card>

  <op-summary-card headerText="Audits with Triggered Alerts"
                   [totalValue]="totalSummary?.auditsWithTriggeredAlertCount"
                   [filteredValue]="filteredSummary?.auditsWithTriggeredAlertCount"
                   [showTotalValueWhenFiltered]="true"
                   [widgetState]="filterableWidgetState"
                   [valueColorHighlight]="filteredSummary?.auditsWithTriggeredAlertCount > 0 ? EColorHighlightMeaning.NEGATIVE : EColorHighlightMeaning.NEUTRAL"
                   tooltipText="Count of Audits with Triggered Alerts in most recent runs">
  </op-summary-card>

  <op-summary-card headerText="Triggered Usage Alerts"
                   [filteredValue]="totalSummary?.triggeredUsageAlertCount"
                   [valueColorHighlight]="filteredSummary?.triggeredUsageAlertCount > 0 ? EColorHighlightMeaning.NEGATIVE : EColorHighlightMeaning.NEUTRAL"
                   [widgetState]="nonFilterableWidgetState"
                   tooltipText="Count of Triggered Usage Alerts. Click to view on Usage Dashboard"
                   [rightSideIcon]="usageAlertsWidgetIconConfig"
                   (cardClicked)="goToUsageReport()">
  </op-summary-card>
</div>

<ng-container *ngIf="dataLoadedSubject | async; else loader">
  <div [hidden]="triggeredAlertsTotal === 0" class="triggered-alerts-table-wrapper">
    <div class="table-title">Triggered alerts in most recent runs</div>
    <triggered-alerts-table [data]="triggeredAlerts"
                            [pagination]="pagination"
                            [sortOptions]="sortOptions"
                            [assignments]="assignments"
                            (updateTableState)="updateTableState($event)"
                            (editAlertEvent)="editAlert($event)">
    </triggered-alerts-table>
  </div>

  <ng-container *ngIf="configuredAlertsTotal === 0 && triggeredAlertsTotal === 0">
    <no-alerts-configured (alertCreated)="alertCreated()"></no-alerts-configured>
  </ng-container>

  <ng-container *ngIf="configuredAlertsTotal > 0 && triggeredAlertsTotal === 0">
    <canvas #confettiCanvas class="confetti-canvas"></canvas>
    <no-alerts-triggered [canvas]="confettiCanvas" [alertsCount]="configuredAlertsTotal" [filterCount]="filterCount"></no-alerts-triggered>
  </ng-container>
</ng-container>

<ng-template #loader>
  <div class="spinner-container">
    <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
  </div>
</ng-template>
