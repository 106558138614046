import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { StorageService } from '@app/components/shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { ShareLinksPaths } from '@app/components/share-links/share-links.constants';
import { IAccountPreview } from './authentication.models';
import { ApiErrorHandlerFactory } from './api.utils';

@Injectable({
  providedIn: 'root'
})
export class ApplicationChromeService {

  private isVisitorModeSubject = new BehaviorSubject<boolean>(this.storageService.isVisitorUser() ?? false);
  isVisitorMode$ = this.isVisitorModeSubject.asObservable();

  private accountPreviewSubject = new BehaviorSubject<IAccountPreview>(null);
  accountPreview$ = this.accountPreviewSubject.asObservable();

  constructor(private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private cacheResetService: CacheResetService) { }

  init() {
    // fetch data if user is already logged in and not in the process of logging in as a visitor
    const loggingInAsVisitor = window.location.pathname.startsWith(`/${ShareLinksPaths.base}`);
    if (this.authenticationService.isAuthenticated() && !loggingInAsVisitor) {
      this.fetchData();
    }

    // is triggered after login, regardless of user type (original, impersonated or visitor)
    this.cacheResetService.reset$.subscribe(() => this.fetchData());
  }

  private fetchData() {
    this.isVisitorModeSubject.next(this.storageService.isVisitorUser() ?? false);

    this.updateAccountPreview();
  }

  updateAccountPreview() {
    this.authenticationService.getAccountPreview().subscribe(preview => {
      this.accountPreviewSubject.next(preview);
    }, ApiErrorHandlerFactory.noExpectedErrors('ApplicationChromeService.updateAccountPreview'));
  }

  updateVisitorMode(isVisitor: boolean) {
    this.isVisitorModeSubject.next(isVisitor);
  }
}
