<span class="used" #usedLabel [class.filtered]="filteredTo !== undefined" (mousemove)="tooltipShow(EUsageTabTitleChartIds.Scanned)">
  {{(filteredTo !== undefined ? filteredTo : used) | number}} <span>(<mat-icon>filter_alt</mat-icon>)</span>
  <div class="usage-tab-title-tooltip" #usedTooltip>
    <div class="tooltip-header">{{type === EUsageTabTitleChartTypes.Audit ? 'PAGES SCANNED' : 'JOURNEY RUNS'}} TO DATE</div>
  </div>
</span>
<div class="chart">
  <horizontal-stacked-limit-bar-chart
    class="op-chart"
    [data]="data"
    [limits]="limits"
    [filtered]="filtered"
    [additionalTooltipClass]="tooltipClass"
    [getTooltipMessageFn]="getTooltipFn">
  </horizontal-stacked-limit-bar-chart>
</div>
<span class="available" #availableLabel (mousemove)="tooltipShow(EUsageTabTitleChartIds.Available)">
  {{total ? (total | number) : '&infin;'}}
  <div class="usage-tab-title-tooltip" #availableTooltip>
    <div class="tooltip-header">{{type === EUsageTabTitleChartTypes.Audit ? 'PAGE SCANS' : 'JOURNEY RUNS'}} <br> PURCHASED VOLUME</div>
  </div>
</span>

