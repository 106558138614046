<label class="label"
       [matTooltip]="PageDetailsAccessibilitySeverityTooltips[widget?.name]"
       matTooltipPosition="above"
>
  <div class="title">
    <ng-content select="[title]"></ng-content>
  </div>
  <div class="value">
    <ng-content select="[value]"></ng-content>
    <ng-container *ngIf="formControl">
      <mat-icon *ngIf="formControl.value" class="filter">filter_alt</mat-icon>
      <input class="checkbox" type="checkbox" [formControl]="formControl">
    </ng-container>
  </div>
</label>
