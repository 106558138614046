import { ILabel } from '@app/components/shared/services/label.service';
import { IWebJourneyRun } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { IWebElementSelector } from '@app/components/shared/components/multi-selectors/multi-selectors.models';
import { IBlackoutPeriodModel } from '../utilities/blackoutPeriodUtils';
import { IActionSetSelector } from '../action-set-library/action-set-library.models';
import { EWebJourneyFrequency } from '@app/components/web-journey/web-journey.constants';
import { IOpRecurrenceScheduleRequest } from '../shared/components/op-recurrence/op-recurrence.models';

export interface IWebJourney {
  id: number;
  userId: number;
  folderId: number;
  domain: string;
  domainId: number;
  name: string;
  emails: Array<string>;
  status: string;
  options: IWebJourneyOptions;
  actions?: Array<IWebJourneyActionBase>;
  lastCheck?: string;
  nextCheck?: string;
  createdAt?: any;
  isNew?: boolean;
  runs?: Array<IWebJourneyRun>;
  labels?: Array<ILabel>;
  webJourneyRunning?: boolean;
  startingUrl?: string;
  queued?: boolean;
  notes?: string;
}

export interface IWebJourneyCreationRequest {
  name: string;
  domainId: number;
  emails: Array<string>;
  options: IWebJourneyOptions;
  actions: Array<IWebJourneyActionBase>;
  remoteFileMapConfig?: number[];
}
export interface IWebJourneyUpdateRequest extends IWebJourneyCreationRequest {
  id: number;
}

export interface IWebJourneyOptions {
  frequency: EWebJourneyFrequency;
  location: string;
  userAgent: string;
  userAgentDescription: string;
  browserWidth?: number;
  browserHeight?: number;
  alerts: boolean;
  vpnEnabled: boolean;
  gpcEnabled: boolean;
  blockThirdPartyCookies: boolean;
  customProxy?: string;
  flashLiveVideoEnabled: boolean;
  blackoutPeriod: IBlackoutPeriodModel;
  nextRun: Date;
  rfmConfig?: number[];
  webHookUrl: string;
  monitoredByScriptServices: boolean;
  notes?: string;
  schedule?: IOpRecurrenceScheduleRequest;
}

export interface IWebJourneyCard extends IWebJourney, IWebJourneyOptions {
  type: string;
}

export interface IWebJourneyActionBase {
  sequence: number;
  rules: Array<number>;
  action: EActionType;
  selectors: Array<IWebElementSelector>;
  label?: string;
  waitDuration?: number;
  actionId?: number;
}

export enum EActionType {
  NavTo = 'navto',
  Click = 'click',
  Input = 'input',
  MaskedInput = 'maskedInput',
  Select = 'select',
  Check = 'check',
  Uncheck = 'uncheck',
  Execute = 'execute',
  Watch = 'watch',
  SwitchToIFrame = 'enterIFrame',
  LeaveIFrame = 'exitIFrame',
  ActionSet = 'actionSet',
  ClearCookies = 'clearCookies',
  CmpOptInOptOut = 'cmpOptInOptOut',
  CmpOptIn = 'privacyoptin',
  CmpOptOut = 'privacyoptout'
}

export enum EActionTypeV3 {
  NavTo = 'navto',
  Click = 'click',
  Input = 'input',
  MaskedInput = 'maskedinput',
  Select = 'select',
  Check = 'check',
  Uncheck = 'uncheck',
  Execute = 'execute',
  Watch = 'watch',
  SwitchToIFrame = 'enteriframe',
  LeaveIFrame = 'exitiframe',
  ActionSet = 'actionset',
  ClearCookies = 'clearcookies',

  /**
   * The CMP action follows a different paradigm than all
   * other actions. It is a single action type in the UI
   * but passes through either 'privacyoptin' or 'privacyoptout'
   * depending on the users selection. For the UI to work properly
   * we need to unify these two under CmpOptInOptOut. When loading
   * a CMP action from the API we then need to know if it is
   * CmpOptIn or CmpOptOut, hence three options for a single action.
   */
  CmpOptInOptOut = 'cmpOptInOptOut',
  CmpOptIn = 'privacyoptin',
  CmpOptOut = 'privacyoptout'
}

export interface INavWebJourneyAction extends IWebJourneyActionBase {
  url: string;
}

export interface IClickWebJourneyAction extends IWebJourneyActionBase {
  preventNavigation: boolean;
}

export interface IInputWebJourneyAction extends IWebJourneyActionBase {
  value: string;
  preventNavigation: boolean;
}

export interface IMaskedInputWebJourneyAction extends IWebJourneyActionBase {
  value: string;
  maskedValue: string;
  viewedValue: string;
  isChangedValue: boolean,
  maskedInputType: string;
  preventNavigation: boolean;
}

export interface ISelectWebJourneyAction extends IWebJourneyActionBase {
  value: string;
  preventNavigation: boolean;
}

export interface ICheckWebJourneyAction extends IWebJourneyActionBase {
  preventNavigation: boolean;
}

export interface IUncheckWebJourneyAction extends IWebJourneyActionBase {
  preventNavigation: boolean;
}

export interface IExecuteWebJourneyAction extends IWebJourneyActionBase {
  js: string;
  preventNavigation: boolean;
}

export interface IWatchWebJourneyAction extends IWebJourneyActionBase {
  seconds: number;
}

export interface IWebJourneyAction extends IWebJourneyActionBase {
  id: number;
  simulationId: number;
  input?: string;
}

export interface ISelectOption {
  key: string;
  label: string;
}

export interface IWebJourneyFrequency {
  key: string;
  label: string;
}

export interface IWebJourneyServices {
  usedMonitoredJourneys: number,
  journeyFixes: number,
  maxMonitoredJourneys: number
}

export interface IV3WebJourneyAction {
  id: number;
  sequence: number;
  rules: number[];
  action: EActionType;
  selectors: IActionSetSelector[];
  value: string;
  maskedValue: string;
  description: string;
  preventNav: boolean;
  waitDuration: number;
  actionSetId: number;
}
