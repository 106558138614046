import { takeUntil } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { EPageInfoLinkDownloadOption } from '../page-information.enums';
import { IAuditPageInfoLinksFromThisPageV2 } from '../../page-details/page-details.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-information-export-links',
  templateUrl: './page-information-export-links.component.html',
  styleUrls: ['./page-information-export-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageInformationExportLinksComponent implements OnInit, OnDestroy {

  @Input()
  set links(links: IAuditPageInfoLinksFromThisPageV2) {
    this.linksToInternalPages = links.scannedInternalSuccessfulPageCount + links.scannedInternalBrokenPageCount;
    this.linksToExternalPages = links.scannedExternalSuccessfulPageCount + links.scannedExternalBrokenPageCount;
    this.linksToNotAuditedPages = links.unscannedInternaLinkCount + links.unscannedExternaLinkCount;
    this.totalAcceptedLinks = 0;
  }
  @Output() export = new EventEmitter<EPageInfoLinkDownloadOption[]>();

  exportOptionsForm = this.formBuilder.group({
    [EPageInfoLinkDownloadOption.ToInternalPages]: false,
    [EPageInfoLinkDownloadOption.ToExternalPages]: false,
    [EPageInfoLinkDownloadOption.ToUrlsNotAudited]: false,
  });

  EPageInfoLinkDownloadOption = EPageInfoLinkDownloadOption;

  linksToInternalPages: number;
  linksToExternalPages: number;
  linksToNotAuditedPages: number;
  totalAcceptedLinks: number;

  private destroySubject = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.exportOptionsForm.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe(_ => {
        const acceptedOptions = this.getAcceptedOptions();
        this.totalAcceptedLinks = acceptedOptions.reduce((sum, option) => {
          if (option === EPageInfoLinkDownloadOption.ToInternalPages) return sum + this.linksToInternalPages;
          if (option === EPageInfoLinkDownloadOption.ToExternalPages) return sum + this.linksToExternalPages;
          if (option === EPageInfoLinkDownloadOption.ToUrlsNotAudited) return sum + this.linksToNotAuditedPages;
        }, 0);
      });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  submit() {
    this.export.emit(this.getAcceptedOptions());
    this.exportOptionsForm.reset();
  }

  private getAcceptedOptions(): EPageInfoLinkDownloadOption[] {
    if (!this.exportOptionsForm) return [];

    return Object.entries(this.exportOptionsForm.value)
      .filter(([_, accepted]) => accepted)
      .map(([option]) => option as EPageInfoLinkDownloadOption);
  }

}
