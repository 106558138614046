import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';
import { format } from "date-fns";
import { BehaviorSubject, concat } from "rxjs";
import { finalize, map, tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import {
  IAuditRunSummary
} from "@app/components/domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer";
import {
  AlertFormData,
  IAlertFormData,
  IAlertRequestItem,
  IAlertResultDetails,
  IAlertTriggerFormData
} from "../alert.models";
import { AlertService } from '../alert.service';
import { EAlertResultStatus } from '../alert.enums';
import {
  EAlertMetricChangeType,
  EAlertPageSummaryMetric,
  EAlertPageSummaryWebVitalsMetric
} from '../alert-logic/alert-logic.enums';
import { IAlertPreviewState } from './alert-preview.models';
import { IAlertBarsChartBar } from './alert-preview-chart/alert-preview-chart.models';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { AlertReportsToAuditMetrics } from '@app/components/alert/alert-logic/alert-logic.constants';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Injectable()
export class AlertPreviewService {

  private stateSubject = new BehaviorSubject<IAlertPreviewState>({
    bars: null,
    loading: false,
    limit: null,
    lastFailedRun: null
  });
  state$ = this.stateSubject.asObservable();

  constructor(
    private alertService: AlertService,
    private dateService: DateService
  ) { }

  prepareChartData(
    runs: IAuditRunSummary[],
    formValue: IAlertFormData,
    auditId: number)
  {
    const { logic } = formValue;
    this.updateState({ limit: logic.trigger.targetValue });

    // this.updateTargetValueForApiCall(logic);

    this.prepareBarPlaceholders(runs);
    this.fillBars(runs, formValue, auditId);
  }

  reset() {
    this.updateState({
      bars: null,
      loading: false,
      limit: null,
      lastFailedRun: null
    })
  }

  /* Builds bars with empty values */
  private prepareBarPlaceholders(runs: IAuditRunSummary[]) {
    const runDateFormatted = run => format(new Date(run.completed), 'MMM yyyy');
    const day = run => this.dateService.formatDate(new Date(run.completed), EDateFormats.dateFour);
    const time = run => this.dateService.formatDate(new Date(run.completed), EDateFormats.timeOne);

    const groupedRunsMyMonths = _groupBy(runs, runDateFormatted);
    const bars = Object.entries(groupedRunsMyMonths).flatMap(([month, runs]) => {
      return runs.map((run, index) => ({
        id: String(run.id),
        auditId: String(run.webAuditId),
        label: `${day(run)} | ${time(run)}`,
        groupLabel: index === 0 ? month : null,
        actualValue: 0,
        triggered: false
      }))
    });

    this.updateState({
      bars,
      loading: true,
      limit: null,
      lastFailedRun: null
    });
  }

  /* Fills out bars with data, one by one */
  private fillBars(runs: IAuditRunSummary[],
                   formValue: IAlertFormData,
                   auditId: number) {
    const requestDTO = AlertFormData.toAlertRequestItem(formValue);
    const sortedRuns = _orderBy(runs, 'completed', 'desc');
    const requests = sortedRuns.map(run => {
      return this.alertService.getAlerts(auditId, run.id, { alerts: [requestDTO]}).pipe(
        map(results => {
          // Note - we don't use AlertUtils.convertToUIValue here
          // to be able to display the actual metric value for each bar
          // and relative change values are calculated separately and displayed right below the actual value
          const metricConfig = AlertUtils.getMetricConfigByMetricType(requestDTO.metricType);
          if (!metricConfig?.valueConverters) {
            return results;
          }

          const updatedAlerts = results.alerts.map(({ alert, result }) => {
            let formattedResult = { ...result };
            if (result.actualValue) formattedResult.actualValue = metricConfig.valueConverters.apiToUi(formattedResult.actualValue);
            if (result.currentRunValue) formattedResult.currentRunValue = metricConfig.valueConverters.apiToUi(formattedResult.currentRunValue);
            if (result.previousRunValue) formattedResult.previousRunValue = metricConfig.valueConverters.apiToUi(formattedResult.previousRunValue);
            return { alert, result: formattedResult };
          });
          return { ...results, alerts: updatedAlerts };
        }),
        tap(({ alerts }) => {
          const { alert, result } = alerts[0];
          this.calcLastFailedRun(run, result);
          this.calcUpdatedBars(run, alert, result);
        })
      )
    });

    concat(...requests).pipe(
      finalize(() => {
        return this.updateState({ loading: false });
      })
    ).subscribe();
  }

  private calcLastFailedRun(run: IAuditRunSummary,
                            result: IAlertResultDetails) {
    if (this.state.lastFailedRun) return;

    const triggered = result.status === EAlertResultStatus.Triggered;
    if (triggered) this.updateState({ lastFailedRun: run });
  }

  private calcUpdatedBars(run: IAuditRunSummary,
                          alert: IAlertRequestItem,
                          result: IAlertResultDetails) {
    const { bars } = this.state;
    const barIndex = (bars ?? []).findIndex(bar => bar.id === String(run.id));
    if (barIndex === -1) return;

    // nextBar is null if current bar is the last one
    const nextBar = barIndex === bars.length - 1 ? null : bars[barIndex + 1];

    const updatedCurrentBar = this.calcUpdatedCurrentBar(alert, result, bars[barIndex], barIndex);
    const updatedNextRun = this.calcUpdatedNextBar(alert, nextBar, updatedCurrentBar)

    const updatedBars = bars.map((bar, index) => {
      if (index === barIndex) return updatedCurrentBar;
      if (index === barIndex + 1 && updatedNextRun) return updatedNextRun;
      return bar;
    });

    this.updateState({ bars: updatedBars });
  }

  private calcUpdatedCurrentBar(alert: IAlertRequestItem,
                                result: IAlertResultDetails,
                                bar: IAlertBarsChartBar,
                                barIndex: number): IAlertBarsChartBar {

    const triggered = result.status === EAlertResultStatus.Triggered;
    const isRelativeChange = AlertUtils.isRelativeChange(alert.metricChangeType);
    const rorOperator = alert.metricChangeType;

    const actualValue = rorOperator ? result.currentRunValue : result.actualValue;

    // we should manually calculate multiplier for abs change types because API always returns a positive value
    const isValueChangeAbs = [EAlertMetricChangeType.ValueChangeAbs, EAlertMetricChangeType.RelativeValueChangeAbs].includes(alert.metricChangeType);
    const multiplier = (isValueChangeAbs && result.currentRunValue < result.previousRunValue) ? -1 : 1;

    const diffValue = rorOperator && barIndex !== 0
      ? Number((multiplier * result.actualValue).toFixed(1))
      : null;
    const diff = {
      value: diffValue,
      isRelative: isRelativeChange
    }

    return { ...bar, actualValue, diff, triggered };
  }

  private calcUpdatedNextBar(alert: IAlertRequestItem,
                             nextBar: IAlertBarsChartBar,
                             currentBar: IAlertBarsChartBar): IAlertBarsChartBar {
    const rorOperator = alert.metricChangeType;
    const isRelativeChange = [EAlertMetricChangeType.RelativeValueChange, EAlertMetricChangeType.RelativeValueChangeAbs].includes(alert.metricChangeType);

    // we should calculate diff manually only for Threshold operators. For ROR operators, diffs is returned from the API
    if (!nextBar || rorOperator) return nextBar;

    const diff = {
      value: Number((nextBar.actualValue - currentBar.actualValue).toFixed(1)),
      isRelative: isRelativeChange
    };

    return { ...nextBar, diff };
  }

  private updateState(updates: Partial<IAlertPreviewState>) {
    this.stateSubject.next({
      ...this.state,
      ...updates
    });
  }

  private get state(): IAlertPreviewState {
    return this.stateSubject.value;
  }

}
