<div class="file-drop-wrapper" [class.table-loaded]="rowData.length > 0">
  <op-file-drop #fileDrop acceptedFileTypes="csv" (fileDropped)="onFileDrop($event)">
    <span class="filename">{{ uploadedFile?.name }}</span>
    <div class="file-drop-content">
      <button mat-flat-button class="op-upload-button" (click)="fileDrop.openFileSelector()">Upload CSV</button>
      <div class="top-margin">or</div>
      <div class="top-margin">drag & drop a file</div>
    </div>
  </op-file-drop>
</div>

<div class="ag-grid-wrapper" *ngIf="rowData.length > 0">
  <div class="ag-quick-filter">
    <mat-icon class="search-icon">search</mat-icon>
    <input matInput
           class="quick-search-input"
           placeholder="Quick filter (any column)"
           autofocus
           autocomplete="off"
           [(ngModel)]="quickFilter"
           (ngModelChange)="doQuickFilter()"
    >
    <button *ngIf="quickFilter !== ''"
            class="clear-quick-search-btn"
            (click)="clearQuickFilter()"
            type="button">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <ag-grid-angular class="ag-theme-alpine-dark"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="defaultColDef"
                   [autoGroupColumnDef]="autoGroupColumnDef"
                   [rowData]="rowData"
                   [sideBar]="sideBar"
                   (gridReady)="onGridReady($event)"
  ></ag-grid-angular>
</div>
