<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="close()"
          [preventSimultaneousClose]="true">

  <ng-template #headerTemplate>
  <op-modal-header
      [title]="title"
      (onClose)="close()"
    ></op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <op-tabs [tabs]="tabs"
             [activeTab]="currentTab"
             [useRouting]="false"
             (tabClicked)="goToTab($event)"
    ></op-tabs>
    <form cdkScrollable [formGroup]="webJourneyForm" class="web-journey-editor-form" [class.editor-setup-tab]="currentTab === webJourneyTabs.setup">
        <web-journey-setup-form #webJourneySetupFormComponent
                                formControlName="setup"
                                [class.hidden]="currentTab !== webJourneyTabs.setup"
                                [modalData]="modalData"
                                [submitted]="submitted"
                                (onSelectDomain)="onSelectDomain($event)"
                                (onFrequencyChanged)="handleSaveBtnText('', false)"
                                (onLocationChanged)="onLocationChanged($event)"
                                (onVPNChanged)="onVPNChanged($event)"
                                (onRunDateChanged)="handleSaveBtnText('', false)"
                                (onRunTimeChanged)="handleSaveBtnText('', false)"
                                (onAddCmpAction)="handleAddCmpAction()"
        >
        </web-journey-setup-form>

        <div class="actions-tab"
             *ngIf="currentTab === webJourneyTabs.actions">
             <div class="help-text">
              Specify the actions and create tag & variable rules for each step to validate and/or monitor data tied to specific actions on your website.<br>
              (<mat-icon class="help-text-icon">warning</mat-icon> Actions will need to be updated from time to time as the website changes.) <a [href]="actionsLearnMoreLink" target="_blank">Learn More.</a>
             </div>
          <actions-creator #actionsCreator
                          formControlName="actions"
                          [mode]="actionsModes.WebJourney"
                          [startingUrl]="selectedDomain"
                          (openRuleSelector)="openRuleSelector($event)"
                          [submitted]="submitted"
                          (onActionSetCreated)="updateActionsForm($event)">
          </actions-creator>
        </div>

        <standards-tab *ngIf="isLoaded && currentTab === webJourneyTabs.standards"
                       [selectedRules]="selectedRuleIDs"
                       [productType]="EProductType.JOURNEY"
                       [journeyId]="journeyId || 0"
                       [explanationText]="explanationText"
                       [learnMoreLink]="learnMoreLink"
                       (rulesUpdated)="updateTagAndVariableRules($event)">
        </standards-tab>
    </form>
    <div class="form-error" *ngIf="webJourneyForm.invalid && this.submitted">
      <div class="form-error-bg">
        <div class="form-error-content">
          <mat-icon class="error-icon">warning_amber</mat-icon>
          {{ errorMessage }}
        </div>
      </div>
    </div>
  </ng-template>

</op-modal>
