import {AngularNames, Names} from '@app/moonbeamConstants';
import * as angular from 'angular';
import {IShadeWindowDataService} from './shadeWindowDataService';


export interface IShadeWindowScope extends angular.IScope {
    template: string;
    childCtrl: string;
    shadeWindowData: string;
    onCreateCallback: any;

    directiveCtrl: any;
  }

  export class ShadeWindowDataController {

    static $inject = [
      AngularNames.scope,
      Names.Services.shadeWindowData
    ];

    constructor(private $scope: IShadeWindowScope,
                private shadeWindowDataService: IShadeWindowDataService) {}

    close = (createdItem) => {
      this.shadeWindowDataService.setShadeWindowIsClosed();
      if (typeof createdItem != 'undefined') {
        this.$scope.onCreateCallback({createdItem: createdItem});
      }
    };


}
