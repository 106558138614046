<div id="audit-report-page-details-panel"
     class="page-details"
     [class.page-details-in-drawer]="componentPlacement === EPageDetailsPlacement.InDrawer"
     [class.page-details-full-screen]="componentPlacement === EPageDetailsPlacement.FullScreen">
  <div class="page-details-header">
    <div class="page-details-header-left">
      <button mat-button class="close-button"
              *ngIf="componentPlacement !== EPageDetailsPlacement.DeepLink"
              matTooltip="Close Page Details"
              [class.page-info-loaded]="pageInfo"
              [disabled]="!pageInfo"
              disableRipple
              (click)="handleClosePageDetails()">
        <mat-icon class="close-drawer-icon">close</mat-icon>
      </button>
      <div *ngIf="pageInfo; else pageInfoLoader" class="info">
        <ng-container *ngIf="pageInfo.initialPageUrl; else pageLinkOldRun">
          <label class="section-title">FINAL PAGE TITLE</label>
          <p class="section-data">
            <span>{{title}}</span>
          </p>
        </ng-container>
        <ng-template #pageLinkOldRun>
          <a #pageUrlLink [href]="url"
             matTooltip="View webpage in new window"
             target="_blank"
             class="page-link"
          >
            {{ url }}
            <mat-icon class="open-in-new-icon url-icon">open_in_new</mat-icon>
          </a>
        </ng-template>
      </div>
      <ng-template #pageInfoLoader>
        <div class="loader info">
          <mat-spinner [diameter]="20" [strokeWidth]="3"></mat-spinner>
        </div>
      </ng-template>
    </div>
    <div class="page-details-header-right">
      <div *ngIf="pageInfo?.initialPageUrl" class="info">
        <label class="section-title">SCANNED ON</label>
        <p class="section-data">
          <span>{{lastRunDate | date: 'MMM d, yyyy'}}</span>
          <span class="divider">|</span>
          <span>{{lastRunDate | date: 'h:mm a'}}</span>
        </p>
      </div>
      <button mat-icon-button
              *ngIf="!mobileView"
              class="open-full-screen-btn"
              [matTooltip]="componentPlacement === EPageDetailsPlacement.FullScreen ? 'Close full screen' : 'View full screen'"
              matTooltipPosition="above"
              (click)="togglePageDetailsFullScreen()">
        <mat-icon>{{ componentPlacement === EPageDetailsPlacement.FullScreen ? 'close_fullscreen' : 'open_in_full' }}</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <page-redirects *ngIf="pageInfo?.initialPageUrl" [pageInfo]="pageInfo"></page-redirects>
  </div>
  <div class="tabs-wrap">
    <op-tabs [tabs]="tabs" [useRouting]="false" [activeTab]="activeTab" (tabClicked)="tabClicked($event)"></op-tabs>
  </div>
  <div class="tab-content" (scroll)="onScroll($event)">
    <div class="page-details-tab-page-information" *ngIf="activeTabIs(EPageDetailsTabs.PageInformation)">
      <op-page-information [itemId]="auditId"
                           [runId]="runId"
                           [auditId]="auditId"
                           [pageId]="pageId"
                           [pageInfo]="pageInfo"
                           [pageErrors]="pageErrors"
                           [activeTab]="activeTab"
                           (screenshotClosed)="fullScreenModalClosed()"
      >
      </op-page-information>
    </div>
    <div class="page-details-tab-tags" *ngIf="activeTabIs(EPageDetailsTabs.Tags)">
      <page-details-tags [itemId]="auditId"
                         [runId]="runId"
                         [pageId]="pageId"
                         [reportType]="reportType"
                         [activeTab]="activeTab"
                         [isScrolled]="isScrolled"
                         [accountFeatures]="accountFeatures"
                         [state]="state"
                         (navToTab)="handleNavToTab($event)">
      </page-details-tags>
    </div>
    <div class="page-details-tab-cookies" *ngIf="activeTabIs(EPageDetailsTabs.Cookies)">
      <cookies-table [itemId]="auditId"
                     [runId]="runId"
                     [pageId]="pageId"
                     [reportType]="reportType"
                     [activeTab]="activeTab"
                     [isScrolled]="isScrolled"
                     [state]="state"
                     (stateUpdated)="onStateUpdated($event)"
                     (navToTab)="handleNavToTab($event)"
                     (setCookieNameFilter)="setCookieNameFilter($event)">
      </cookies-table>
    </div>
    <div class="page-details-tab-accessibility" *ngIf="activeTabIs(EPageDetailsTabs.Accessibility)">
      <op-page-details-accessibility
        [accessibilityChecksSummary]="pageInfo?.accessibilityChecksSummary"
        [finalStatusCode]="pageInfo?.finalStatusCode"
        [loading]="pageInfoLoading"
        [itemId]="auditId"
        [runId]="runId"
        [pageId]="pageId"
        [reportType]="reportType"
        [activeTab]="activeTab"
      ></op-page-details-accessibility>
    </div>
    <div class="page-details-tab-request-log" *ngIf="activeTabIs(EPageDetailsTabs.RequestLog)">
      <request-log-table [itemId]="auditId"
                         [runId]="runId"
                         [pageId]="pageId"
                         [reportType]="reportType"
                         [activeTab]="activeTab"
                         [isScrolled]="isScrolled"
                         [accountFeatures]="accountFeatures"
                         [state]="state"
                         [visitStartTimestamp]="lastRunDate"
                         (stateUpdated)="onStateUpdated($event)"
                         (navToTab)="handleNavToTab($event)">
      ></request-log-table>
    </div>
    <div class="page-details-tab-console-log" *ngIf="activeTabIs(EPageDetailsTabs.ConsoleLog)">
      <audit-console-log-table [itemId]="auditId"
                               [runId]="runId"
                               [pageId]="pageId"
                               [state]="state"
                               [reportType]="reportType"
                               [activeTab]="activeTab"
                               [isScrolled]="isScrolled">
      </audit-console-log-table>
    </div>
    <div class="page-details-tab-tag-initiators" *ngIf="activeTabIs(EPageDetailsTabs.TagInitators)">
      <tag-initiators [showFullScreenButton]="true"
                      [url]="url"
                      [auditId]="auditId"
                      [runId]="runId"
                      [reportType]="reportType"
                      [pageId]="pageId"
                      [activeTab]="activeTab"
                      (initiatorsFullScreenModalClosed)="fullScreenModalClosed()">
      </tag-initiators>
    </div>
    <div class="page-details-tab-rules" *ngIf="activeTabIs(EPageDetailsTabs.Rules)">
      <audit-page-rules-wrapper [auditId]="auditId"
                                [runId]="runId"
                                [reportType]="reportType"
                                [pageId]="pageId"
                                [activeTab]="activeTab">
      </audit-page-rules-wrapper>
    </div>
  </div>
</div>
