<ng-container *ngIf="tooltip === EWebVitalsMetricType.LargestContentfulPaint">
  <p>
    To classify Largest Contentful Paint (LCP) on all pages scanned we use the 75th percentile value from all pages.
  </p>
  <p>
    LCP is a stable Core Web Vital metric for measuring perceived load speed. It marks the point in the page load timeline when the page's main content has likely loaded. A fast LCP helps reassure the user that the page is useful.
  </p>
  <p>
    For example:
  </p>
  <ul class="list">
    <li class="list-item"><b>Good (<span class="green">Green</span>):</b> At least 75% of pages have an LCP of 2.5 seconds or less.</li>
    <li class="list-item"><b>Needs Improvement (<span class="orange">Orange</span>):</b> At least 75% of pages have an LCP of 2.6-4 sec.</li>
    <li class="list-item"><b>Poor (<span class="red">Red</span>):</b> The 75th percentile LCP is 4 seconds or more.</li>
  </ul>
</ng-container>


<ng-container *ngIf="tooltip === EWebVitalsMetricType.FirstContentfulPaint">
  <p>To classify First Contentful Paint (FCP) on all pages scanned we use the 75th percentile value from all pages.</p>

  <p>FCP metric measures the time from when the user first navigated to the page to when any part of the page's content is rendered on the screen. For this metric, "content" refers to text, images (including background images), &lt;svg&gt; elements, or non-white &lt;canvas&gt; elements.</p>

  <p>For example:</p>
  <ul class="list">
    <li class="list-item"><b>Good (<span class="green">Green</span>):</b> At least 75% of pages have an FCP of 1.8 seconds or less.</li>
    <li class="list-item"><b>Needs Improvement (<span class="orange">Orange</span>):</b> At least 75% of pages have an FCP of 1.8 to 3 seconds.</li>
    <li class="list-item"><b>Poor (<span class="red">Red</span>):</b> The 75th percentile FCP is 3 seconds or more.</li>
  </ul>
</ng-container>

<ng-container *ngIf="tooltip === EWebVitalsMetricType.TimeToFirstByte">
  <p>To classify Time to First Byte (TTFB) on all pages scanned we use the 75th percentile value from all pages.</p>

  <p>TTFB is a metric that measures the time between the request for a resource and when the first byte of a response begins to arrive.</p>

  <p>For example:</p>
  <ul class="list">
    <li class="list-item"><b>Good (<span class="green">Green</span>):</b> At least 75% of pages have an TTFB of 800 mil-seconds or less.</li>
    <li class="list-item"><b>Needs Improvement (<span class="orange">Orange</span>):</b> At least 75% of pages have an TTFB of 800 ms to 1,800 ms.</li>
    <li class="list-item"><b>Poor (<span class="red">Red</span>):</b> The 75th percentile TTFB is 1,800 ms or more.</li>
  </ul>
</ng-container>

<ng-container *ngIf="tooltip === EWebVitalsMetricType.CumulativeLayoutShift">
  <p>To classify Cumulative Layout Shift (CLS) on all pages scanned we use the 75th percentile value from all pages</p>

  <p>CLS is an important, user-centric metric for measuring
     visual stability because it helps quantify how often users experience unexpected layout shifts. A low CLS helps ensure that the page is delightful.</p>

  <p>For example:</p>
  <ul class="list">
    <li class="list-item"><b>Good (<span class="green">Green</span>):</b> At least 75% of pages have an CLS of .1 or less.</li>
    <li class="list-item"><b>Needs Improvement (<span class="orange">Orange</span>):</b> At least 75% of pages have an CLS of .1 to .25.</li>
    <li class="list-item"><b>Poor (<span class="red">Red</span>):</b> The 75th percentile CLS is .25 or more.</li>
  </ul>
</ng-container>
