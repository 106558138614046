import { RorReportPaths } from './../ror-comparison-report.contants';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { ITagPresenceDiffs } from './ror-comparison-tag-presence.models';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RorComparisonReportService } from '../ror-comparison-report.service';
import { IComparisonReportStateParams } from '../ror-comparison-report.models';
import { allComparisonsPlaceholder } from '../ror-comparison-report.contants';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
import { map, takeUntil } from 'rxjs/operators';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-comparison-tag-presence',
  templateUrl: './ror-comparison-tag-presence.component.html',
  styleUrls: ['./ror-comparison-tag-presence.component.scss']
})
export class RorComparisonTagPresenceComponent implements OnInit, OnDestroy {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;
  auditId: number;
  runId: number;
  comparisonSnapshotId: number;

  tags: ITagPresenceDiffs[];
  tagIdToTagMap: Map<number, IUiTag>;

  itemsCount: number = 0;

  private destroySubject = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private comparisonReportService: RorComparisonReportService,
    private uiTagService: UiTagService,
  ) {
  }

  ngOnInit() {
    combineLatest(
      [this.activatedRoute.params, this.activatedRoute.queryParams]
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams })),
      takeUntil(this.destroySubject)
    ).subscribe((stateParams: IComparisonReportStateParams) => {
      this.auditId = parseInt(stateParams.auditId);
      this.runId = parseInt(stateParams.runId);
      this.comparisonSnapshotId = stateParams.comparisonSnapshotId ? parseInt(stateParams.comparisonSnapshotId) : allComparisonsPlaceholder.comparisonSnapshotId;

      this.fetchData();
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  private fetchData() {
    forkJoin([
      this.comparisonReportService.getTagPresence(this.auditId, this.runId, this.comparisonSnapshotId),
      this.uiTagService.getTags()
    ]).subscribe(([tagPresenceDiffs, allTags]) => {
      this.tags = tagPresenceDiffs;
      this.tagIdToTagMap = allTags.reduce((map, tag) => map.set(tag.id, tag), new Map());
      this.itemsCount = tagPresenceDiffs.reduce(
        (count, tag) => count += tag.presenceDiffs.length,
        0
      );
    });
  }

  openPages(tagId: number, diff: number): void {
    const relativeUrl = '.' + RouteBuilder.buildUrl([RorReportPaths.tagPresencePages], String(tagId), String(diff));
    this.router.navigate(
      [relativeUrl],
      { queryParamsHandling: 'merge', relativeTo: this.activatedRoute }
    );
  }

}
