export enum EBrowserRedirectType {
  serverSide = 'SERVER_SIDE',
  clientSide = 'CLIENT_SIDE',
}

export enum ECookieOpChangeType {
  Add = 'ADD',
  Update = 'UPDATE',
  Delete = 'DELETE',
  NoChange = 'NO_CHANGE',
}

export enum ECookieInitiatorType {
  HTTP = 'HTTP',
  APP = 'APP',
  OP_ACTION = 'OP_ACTION',
  UNKNOWN = 'UNKNOWN',
}

export enum EBlockReason {
  TagId = 'tag_id',
  TagCategory = 'tag_category',
  UrlSignature = 'url_signature'
}
