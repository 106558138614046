<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="modalTitle"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="create-folder-and-sub-folder-content">
      <div class="instructions">
        Specify the folder and sub-folder that this {{itemType}} should be placed in.
      </div>
      <form validate-form
            formName="folderAndSubFolderForm"
            [formGroup]="folderAndSubFolderForm">
        <!-- Folder Section -->
        <ng-container>
          <div class="folder-input form-input" *ngIf="folderMode === EFolderMode.Create">
            <mat-form-field class="styled-mat-input" appearance="outline" floatLabel="always">
              <mat-label>NEW FOLDER</mat-label>
              <input matInput
                     #folderCreate
                     placeholder="Enter name of new folder"
                     formControlName="folder">
              <mat-hint>Create a new folder above
                <span *ngIf="folders.length > 0">
                  or
                  <button class="subtext-link" type="button" (click)="onSwitchFolderMode(EFolderMode.Select)">
                    select from an existing folder
                  </button>.
                </span>
              </mat-hint>
            </mat-form-field>
            <op-error-message formControlPath="folderAndSubFolderForm.folder"></op-error-message>
          </div>
          <div class="folder-input form-input" *ngIf="folderMode === EFolderMode.Select">
            <mat-form-field floatLabel="always" class="select-action-set" appearance="outline">
              <mat-label>FOLDER</mat-label>
              <mat-select #folderSelect
                          formControlName="folder"
                          (selectionChange)="onFolderChange()">
                <mat-option *ngFor="let folder of folders" [value]="folder">
                  {{ folder.name }} <span class="member-count">({{ folder.domainsCount }} sub-folder{{ folder.domainsCount !== 1 ? 's' : ''}})</span>
                </mat-option>
              </mat-select>
              <mat-hint>Select from an existing folder above or
                <span>
                  <button class="subtext-link" type="button" (click)="onSwitchFolderMode(EFolderMode.Create)">
                    create a new folder
                  </button>.
                </span>
              </mat-hint>
            </mat-form-field>
            <op-error-message formControlPath="folderAndSubFolderForm.folder"></op-error-message>
          </div>
        </ng-container>

        <!-- Sub-folder Section -->
        <ng-container>
          <div class="folder-input form-input" *ngIf="subFolderMode === ESubFolderMode.Create">
            <mat-form-field class="styled-mat-input" appearance="outline" floatLabel="always">
              <mat-label>NEW SUB-FOLDER</mat-label>
              <input matInput
                     #subFolderCreate
                     placeholder="Enter name of new sub-folder"
                     formControlName="subFolder">
              <mat-hint *ngIf="this.availableSubFolders.length">Create a new sub-folder above or
                <span>
                  <button class="subtext-link" type="button" ngNoForm (click)="onSwitchSubFolderMode(ESubFolderMode.Select)">
                    select from an existing sub-folder
                  </button>.
                </span>
              </mat-hint>
              <mat-hint *ngIf="!this.availableSubFolders.length">Create a new sub-folder above.</mat-hint>
            </mat-form-field>
            <op-error-message formControlPath="folderAndSubFolderForm.subFolder"></op-error-message>
          </div>
          <div class="folder-input form-input" *ngIf="subFolderMode === ESubFolderMode.Select">
            <mat-form-field floatLabel="always" class="select-action-set" appearance="outline">
              <mat-label>SUB-FOLDER</mat-label>
              <mat-select #subFolderSelect
                          formControlName="subFolder"
                          (selectionChange)="onSubFolderChange()"
              >
                <mat-option *ngFor="let subFolder of subFoldersByFolderId[folder.value['id']]" [value]="subFolder">
                  {{ subFolder.name }}
                </mat-option>
              </mat-select>
              <mat-hint>Select from an existing sub-folder above or
                <span>
                  <button class="subtext-link" type="button" (click)="onSwitchSubFolderMode(ESubFolderMode.Create)">
                    create a new sub-folder
                  </button>.
                </span>
              </mat-hint>
            </mat-form-field>
            <op-error-message formControlPath="folderAndSubFolderForm.subFolder"></op-error-message>
          </div>
        </ng-container>

        <!-- Data Layer Section -->
        <ng-container>
          <div class="datalayer-field-wrapper form-input">
            <mat-form-field appearance="outline"
                            floatLabel="always"
                            class="styled-mat-input">
              <mat-label>DATA LAYER</mat-label>
              <input matInput
                     formControlName="dataLayer"
                     [placeholder]="dataLayerPlaceholder"
              />
              <mat-hint>Enter a data layer object, for example: digitalData, dataLayer[1], etc. This field accepts a comma separated list of JavaScript objects or functions to be identified as data layers.</mat-hint>
            </mat-form-field>
          </div>
        </ng-container>
      </form>
    </div>
  </ng-template>
</op-modal>
