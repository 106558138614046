import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { guest } from '@app/authUtils';
import { AccountsService } from '@app/components/account/account.service';
import { AlertComponent } from '@app/components/alert/alert.component';
import { EAlertFilterType, EAlertMenuContext, EAlertModalType } from '@app/components/alert/alert.enums';
import { IAlertModalPayload } from '@app/components/alert/alert.models';
import { OpModalService } from '@app/components/shared/components/op-modal';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'no-alerts-configured',
  templateUrl: './no-alerts-configured.component.html',
  styleUrls: ['./no-alerts-configured.component.scss']
})
export class NoAlertsConfiguredComponent implements OnInit {

  @Output() alertCreated = new EventEmitter<void>();

  isReadOnly = false;

  constructor(private modalService: OpModalService,
              private accountsService: AccountsService) {}

  ngOnInit() {
    this.accountsService.getUser().subscribe(user => this.isReadOnly = user.permissions === guest);
  }

  addAlert(): void {
    if (this.isReadOnly) return;

    const data: IAlertModalPayload = {
      type: EAlertModalType.CreateFromLibrary,
      menuContext: EAlertMenuContext.Library,
      filterType: EAlertFilterType.V1
    };
    this.modalService.openFixedSizeModal(AlertComponent, { disableClose: true, data, autoFocus: false })
      .afterClosed()
      .subscribe(alert => {
        if (alert) {
          this.alertCreated.emit();
        }
      });
  }
}
