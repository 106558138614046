import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges } from '@angular/core';
import {
  AuditCookieOriginMap,
  CookieChangeActionStringMap,
  CookieOriginType,
  ECookieInitiatorTypeSetMethod,
  EPageDetailsTabs
} from '@app/components/audit-reports/page-details/page-details.constants';
import { ECookieInitiatorType } from '@app/components/audit-reports/page-details/page-details.enums';
import {
  ECookieChangeType,
  ECookieOrigin,
  IAuditCookie,
  IAuditCookieInitiator,
  ICookieOrigin
} from '@app/components/audit-reports/page-details/page-details.models';
import { ICookieTableRow } from '../cookies-table/cookies-table.models';
import { DatePipe } from '@angular/common';
import { CookiePropertyNames } from './cookie-origin-story.constant';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { EWJResultsTab } from '@app/components/web-journey-report/web-journey-results/web-journey-results.enums';
import { DateService } from '@app/components/date/date.service';
import {
  ECookieExpirationProperty
} from '@app/components/shared/components/cookie-origin-story/cookie-origin-story.models';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cookie-origin-story',
  templateUrl: './cookie-origin-story.component.html',
  styleUrls: ['./cookie-origin-story.component.scss']
})
export class CookieOriginStoryComponent implements OnChanges {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;
  readonly EReportType = EReportType;
  readonly ECookieInitiatorTypeSetMethod = ECookieInitiatorTypeSetMethod;
  actionTypesToShowMessageFor = [ECookieOrigin.PreAuditAction, ECookieOrigin.OnPageAction];
  previousCookieState: IAuditCookie;

  @Input() cookie: ICookieTableRow;
  @Input() actionIndex?: number;
  @Input() tags: IUiTag[] = [];
  @Input() dataSourceType?: EReportType = EReportType.AUDIT;
  @Output() navToTab: EventEmitter<{ tab: EPageDetailsTabs|EWJResultsTab, searchValue: string, showPreAuditActions: boolean }> = new EventEmitter();
  @Output() navToAction: EventEmitter<{ actionIndex: number, stepEventItem: ICookieTableRow }> = new EventEmitter();

  constructor(
    private datePipe: DatePipe,
    private dateService: DateService,
  ) {}

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.cookie) {
      this.handleCookieChanges();
    }
  }

  handleCookieChanges(): void {
    const filteredCookieOrigins = this.cookie.changes?.filter(cookie => cookie.cookieChangeType !== ECookieChangeType.NoChange);
    const sortedCookieOrigins = this.sortCookieOrigin(filteredCookieOrigins);

    sortedCookieOrigins.forEach((origin: ICookieOrigin, index: number) => {
      if (origin.initiators) {
        origin.initiators = this.formatCookieInitiators(origin.initiators, origin.cookieOriginType);
      }

      // format duration property
      const ttl = origin.cookie.ttlInMinutes || origin.cookie.cookieTTL;
      if (typeof ttl === 'number') {
        origin.cookie.duration = origin.cookie.expires === 'Session' ? origin.cookie.expires : this.formatCookieDuration(ttl);
      } else {
        origin.cookie.duration = 'Session';
      }

      // format expires property
      origin.cookie.expires = origin.cookie.expires === 'Session' ? origin.cookie.expires : this.formatCookieExpiry(origin.cookie.expires);

      // only check for changes if we've got more than 1 action
      if (index > 0) {
        origin['changes'] = this.compareObjects(this.previousCookieState, origin.cookie);
      }

      // cache this for the next time we loop to compare to the previous action
      this.previousCookieState = origin.cookie;
    });

    this.cookie.originStory = {
      uniqueInitiatorsCount: this.countInitiators(this.cookie.changes),
      actionCount: this.cookie.changes?.length || 0,
      actions: sortedCookieOrigins,
      totalCookieInitiatorCount: this.cookie.totalCookieInitiatorCount || 0,
      totalCookieActionCount: this.cookie.totalCookieActionCount || 0,
    };
  }

  // Returns the cookie duration in a human-readable format. Subtracts the time to live value
  // (returned in minutes) from the expiration date to get the creation date, then
  // formats the difference.
  formatCookieDuration(timeToLive: number): string {
    const timeToLiveInMS = +timeToLive * 60 * 1000;

    return this.dateService.getReadableDuration(timeToLiveInMS);
  }

  private countInitiators(arr) {
    let count = 0;
    arr.forEach(change => {
      if (change.initiators) {
        count += change.initiators.length;
      }
    });
    return count;
  }

  private sortCookieOrigin(changes) {
    if (this.dataSourceType === EReportType.AUDIT) {
      // split origins by origin type, sort, then recombine
      let {PRE_AUDIT_ACTION, PAGE_LOAD, ON_PAGE_ACTION} = changes.reduce((acc, cookie) => {
        acc[cookie.cookieOriginType].push(cookie);

        return acc;
      }, {
        [ECookieOrigin.PreAuditAction]: [],
        [ECookieOrigin.PageLoad]: [],
        [ECookieOrigin.OnPageAction]: [],
      });

      return [
        ...PRE_AUDIT_ACTION.sort((a, b) => a.action?.sequence - b.action?.sequence),
        ...PAGE_LOAD,
        ...ON_PAGE_ACTION.sort((a, b) => a.action?.sequence - b.action?.sequence),
      ];
    } else {
      // Web Journey treats all actions as OnPageAction, so no need to split and recombine
      return changes;
    }
  }

  private formatCookieInitiators(initiators: IAuditCookieInitiator[], originType: ECookieOrigin): any[] {
    return initiators.map((initiator: IAuditCookieInitiator) => {
      const row: any = {};

      if (initiator.tagId) {
        row.tagId = initiator.tagId;
        row.name = this.tags.find(tag => tag.id === initiator.tagId)?.name;
        row.line = initiator.line?.toLocaleString() || '---';
        row.column = initiator.column?.toLocaleString() || '---';
      } else if (initiator.initiatorType === ECookieInitiatorType.APP) {
        row.name = 'JavaScript';
        row.icon = '/images/js-logo.png';
        row.type = 'js';
        row.line = initiator.line?.toLocaleString() || '---';
        row.column = initiator.column?.toLocaleString() || '---';
      } else if (initiator.initiatorType === ECookieInitiatorType.HTTP) {
        row.name = 'HTTP';
        row.icon = 'http';
      } else if (initiator.initiatorType === ECookieInitiatorType.OP_ACTION) {
        row.name = ECookieInitiatorTypeSetMethod.OP_ACTION;
      } else {
        row.name = ECookieInitiatorTypeSetMethod.UNKNOWN;
      }

      if (originType === ECookieOrigin.PreAuditAction) {
        row.isPreauditAction = true;
      }

      if (initiator.requestUrl) {
        row.requestUrl = initiator.requestUrl;
      }

      row.initiatorType = initiator.initiatorType;
      row.setMethod = ECookieInitiatorTypeSetMethod[initiator.initiatorType];
      row.tooltip = this.getCookieInitiatorTooltip(initiator, originType);

      return row;
    });
  }

  private getCookieInitiatorTooltip(initiator: IAuditCookieInitiator, originType: ECookieOrigin): string {
    const type = CookieOriginType[originType];

    switch (initiator.initiatorType) {
      case ECookieInitiatorType.HTTP:
        return `Server code that loaded a network request which had a set-cookie directive.`;
      case ECookieInitiatorType.APP:
        return `JavaScript running in the browser which came from the web server that loaded the file above.`;
      case ECookieInitiatorType.OP_ACTION:
        return `Cookie affected when executing configured ${type} actions.`;
      case ECookieInitiatorType.UNKNOWN:
        return `We're constantly evolving our cookie detection methods — sometimes we can't currently detect how a cookie was created or modified. This can sometimes mean the cookie was set via a shadow DOM or an iFrame.`;
    }
  }

  getOriginStoryText(step: ICookieOrigin, index: number): string {
    const sequenceNumber = index + 1;
    const changeType = CookieChangeActionStringMap[step.cookieChangeType];
    const originType = AuditCookieOriginMap[step.cookieOriginType];
    const actionNumber = this.actionTypesToShowMessageFor.includes(step.cookieOriginType) ? '#' + (step.action.sequence + 1) : '';
    return this.dataSourceType === EReportType.AUDIT
      ? `${sequenceNumber}. Cookie ${changeType} ${originType} ${actionNumber}`
      : `Action ${step?.action?.sequence + 1}. Cookie ${changeType} ${originType}`;
  }

  navAndFilterRequests(params: { cookieName: string, showPreAuditActions: boolean }): void {
    const emission = {
      searchValue: params.cookieName,
      tab: this.dataSourceType === EReportType.AUDIT ? EPageDetailsTabs.RequestLog : EWJResultsTab.ActionDetails,
      updateActiveTab: true,
      showPreAuditActions: params.showPreAuditActions,
    };

    this.navToTab.emit(emission);
  }

  compareObjects(obj1: any, obj2: any): string {
    let htmlString = '';
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          const oldValue = obj1[key];
          const newValue = obj2[key];
          let diff = '';
          // Need special diff treatment in the case of TTL and expiration date that also includes the amount of time changed in human-readable format
          // TTL (duration) also treats diff slightly differently instead of
          // doing a character-by-character diff, it diffs the entire string as a whole
          if (
            key === ECookieExpirationProperty.CookieTTL ||
            key === ECookieExpirationProperty.TTLInMinutes
          ) {
            const timeDiffInMS = this.calcTimeDifferenceInMS(oldValue, newValue, key as ECookieExpirationProperty);
            const timeDiff = this.calcTimeDifference(oldValue, newValue, key as ECookieExpirationProperty, timeDiffInMS);
            const oldValueString = this.dateService.getReadableDuration(oldValue);
            const newValueString = this.dateService.getReadableDuration(newValue);
            diff = `<span class="origin-time-diff">${newValueString}${timeDiff}`;
            htmlString += `<div class="initiator-row" >${CookiePropertyNames[key]}: ${oldValue < 0 ? '-' : ''}${oldValueString} <span class="material-icons cookie-change-arrow">arrow_right_alt</span> <div class="diff-wrapper">${diff}</div></div>`;
          } else if (key === ECookieExpirationProperty.Expires){
            const timeDiffInMS = this.calcTimeDifferenceInMS(oldValue, newValue, key as ECookieExpirationProperty);
            const timeDiff = this.calcTimeDifference(oldValue, newValue, key as ECookieExpirationProperty, timeDiffInMS);
            diff = this.diffStrings(oldValue.toString(), newValue.toString(), true) + timeDiff;
            htmlString += `<div class="initiator-row" >${CookiePropertyNames[key]}: ${oldValue} <span class="material-icons cookie-change-arrow">arrow_right_alt</span> <div class="diff-wrapper">${diff}</div></div>`;
          } else if (key !== 'duration') {
            diff = this.diffStrings(oldValue.toString(), newValue.toString());
            htmlString += `<div class="initiator-row">${CookiePropertyNames[key]}: ${oldValue} <span class="material-icons cookie-change-arrow">arrow_right_alt</span> <div class="diff-wrapper">${diff}</div></div>`;
          }
        }
      }
    }

    return htmlString;
  }

  diffStrings(oldString: string, newString: string, timeStyle?: boolean): string {
    let result = '';
    const oldArr = oldString.split('');
    const newArr = newString.split('');
    let currentGroup = '';
    for (let i = 0; i < newArr.length; i++) {
      if (newArr[i] !== oldArr[i]) {
        currentGroup += newArr[i];
      } else {
        if (currentGroup) {
          const span = `<span class="${timeStyle ? 'origin-time-diff' : 'initiator-value'}">${currentGroup}</span>`;
          result += span;
          currentGroup = '';
        }
        result += newArr[i];
      }
    }
    if (currentGroup) {
      const span = `<span class="${timeStyle ? 'origin-time-diff' : 'initiator-value'}">${currentGroup}</span>`;
      result += span;
    }
    return result;
  }

  calcTimeDifferenceInMS(oldValue: string, newValue: string, key: ECookieExpirationProperty): number {
    let timeDiff = 0;

    switch (key) {
      case ECookieExpirationProperty.CookieTTL:
      case ECookieExpirationProperty.TTLInMinutes:
        return Math.abs(+oldValue - +newValue);
      case ECookieExpirationProperty.Expires:
        // Safari fix. It isn't able to handle the hyphens when creating a date.
        const oldDate = new Date(oldValue.replace(/-/g, '/'));
        const newDate = new Date(newValue.replace(/-/g, '/'));
        return Math.abs(oldDate.getTime() - newDate.getTime());
      default:
        return timeDiff;
    }
  }

  calcTimeDifference(oldValue: string, newValue: string, key: ECookieExpirationProperty, timeDiffInMS: number): string {
    let timeDiff = '';
    let  sign;

    switch (key) {
      case ECookieExpirationProperty.CookieTTL:
      case ECookieExpirationProperty.TTLInMinutes:
        sign = oldValue > newValue ? '-' : '+';
        return ` <span class="origin-time-diff">(${sign}${this.dateService.getReadableDuration(timeDiffInMS)})</span>`;
      case ECookieExpirationProperty.Expires:
        let oldDate = new Date(oldValue.replace(/-/g, '/')).getTime();
        let newDate = new Date(newValue.replace(/-/g, '/')).getTime();

        sign = oldDate > newDate ? '-' : '+';
        return ` <span class="origin-time-diff">(${sign}${this.dateService.getReadableDuration(timeDiffInMS)})</span>`;
      default:
        return timeDiff;
    }
  }

  private formatCookieExpiry(timestamp: number | string): string {
    if (!timestamp) return 'Session';

    let now = new Date();
    if (now.getTime() > (timestamp as number)) return 'Session';

    return this.datePipe.transform(new Date(timestamp), 'yyyy-MM-dd h:mm:ss aaa');
  }
}
