import * as angular from 'angular';

export class SpinnerDirective implements angular.IDirective {
    template = '<span class="ng-spinner" us-spinner="{lines: 12, radius: 17, width: 6, speed: .75, length: 22, color: \'#f2cd14\'}" spinner-key="{{key}}" spinner-start-active="false"></span>';
    restrict = 'E';
    scope = {
      key: '='
    };

    static factory(): angular.IDirectiveFactory {
      const directive = function() {
        return new SpinnerDirective();
      };
      return directive;
    }

    constructor() {
    }
}
