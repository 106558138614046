import { AngularNames, Names } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { environment } from '@app/environments/environment';
import { IApiService } from '@app/components/api/apiService';

export interface IUserEvent {
  userId: number,
  username: string,
  eventType: string,
  itemType: string,
  itemId: number,
  itemName: string,
  eventTimeStamp: string;
}

export interface IUserEventsDTO {
  nextPage: boolean;
  userEvents: IUserEvents[];
}

interface IUserEvents {
  userId: number;
  username: string;
  eventType: string;
  itemType: string;
  itemId: number;
  itemName: string;
  eventTimeStamp: string;
}

export class UserEventsService {

  private static USER_EVENTS_ENDPOINT = environment.apiV3Url + 'user-events';

  static $inject = [
    AngularNames.http,
    Names.Services.api
  ];

  constructor(private $http: angular.IHttpService,
              private apiService: IApiService) {
  }

  getUserEvents(params: string = `?itemType=audit`, sort: {
    sortBy: string,
    sortDesc: boolean
  }): angular.IPromise<IUserEventsDTO> {
    const queryParams = `${params}&sortBy=${ sort.sortBy }&sortDesc=${ sort.sortDesc }`;
    return this.apiService.handleResponse(this.$http.get(UserEventsService.USER_EVENTS_ENDPOINT + queryParams));
  }
}
