<nav class="flex-col flex-stretch">
  <div *ngIf="title" class="sidebar-title">{{ title }}</div>
  <div class="sidebar-section" *ngFor="let navSection of navSections">
    <ul *ngIf="!navSection.featureKey || enabledFeatures.includes(navSection.featureKey)">
      <li *ngIf="navSection.sectionName" class="sectionName">
        {{ navSection.sectionName | uppercase }}
      </li>
      <li *ngFor="let nav of navSection.navs">
        <modal-sidebar-link [nav]="nav" [enabledFeatures]="enabledFeatures"></modal-sidebar-link>
      </li>
    </ul>
  </div>
</nav>
