import { Router, ActivatedRoute } from '@angular/router';
import './libImports';
import * as moonbeam from '@app/moonbeamConstants';
import { AngularNames, Names } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { ActionTypes } from './store/constants/actionTypes';
import { getReducers } from './store/reducers/reducers';
import accountModule from './components/account/accountModule';
import authenticationModule from './components/authentication/authenticationModule';
import apiModule from './components/api/apiModule';
import creatorModule from './components/creator/creatorModule';
import deleteModule from './components/delete/deleteModule';
import eventManagerModule from './components/eventManager/eventManagerModule';
import folderModule from './components/folder/folderModule';
import formModule from './components/form/formModule';
import modalsModule from './components/modals/modalsModule';
import navigationModule from './components/navigation/navigationModule';
import reportingModule from './components/reporting/reportingModule';
import tagsModule from './components/tags/tagsModule';
import uiModule from './components/ui/uiModule';
import userEventsModule from './components/userEvents/userEventsModule';
import utilitiesModule from './components/utilities/utilitiesModule';
import websocketsModule from './components/websockets/websocketsModule';
import domainsModule from './components/domains/domainsModule';
import actionsModule from './store/actions/actionsModule';
import selectorsModule from './store/selectors/selectorsModule';
import { liveConnectModule } from './components/live-connect/liveConnectModule';
import * as Redux from 'redux';
import { thunk } from 'redux-thunk';
import * as ngRedux from 'ng-redux';
import ILocalStorageServiceProvider = angular.local.storage.ILocalStorageServiceProvider;
import stopRunModule from './components/stopRun/stopRunModule';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { globalExceptionHandler } from './globalExceptionHandler';
import { PromiseUtils } from '@app/components/utilities/promise.utils';
import { StorageService } from '@app/components/shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { ActionSetLibraryService } from './components/action-set-library/action-set-library.service';
import { AccountsService } from '@app/components/account/account.service';
import { AdminService } from '@app/components/admin-portal/system-status/admin.service';
import { DateService } from '@app/components/date/date.service';
import { RulesService } from './components/rules/rules.service';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { LabelService } from '@app/components/shared/services/label.service';

window.addEventListener('dragover', event => {
  event.preventDefault();
});

window.addEventListener('drop', event => {
  event.preventDefault();
});

angular.module(moonbeam.Names.config, [])
  .config([
    AngularNames.windowProvider,
    AngularNames.provide,
    ($windowProvider, $provide) => {
      var $window = $windowProvider.$get();

      var env = $window.__env || {};
      $provide.constant('DEBUG', env.debug || false);

      var apprecorder = env.apprecorder || {};
      $provide.constant(
        'APP_RECORDER_URL',
        apprecorder.url || 'https://apprecorder.observepoint.com'
      );
    }
  ]);

export var app = angular
  .module(Names.module, [
    Names.config,
    'rx',
    'ui.sortable',
    'LocalStorageModule',
    'mgcrea.ngStrap',
    'formly',
    'formlyBootstrap',
    'ngWebworker',
    'ngAnimate',
    'op.bootstrap',
    'toggle-switch',
    'angularSpinner',
    'infinite-scroll',
    'ngFitText',
    'ngMessages',
    'angular-websocket',
    'nya.bootstrap.select',
    'ngSanitize',
    'ui.bootstrap.tpls',
    'ui.bootstrap.dropdown',
    'ui.bootstrap.popover',
    'ui.bootstrap.pagination',
    'ngRedux',
    'ngToast',
    'vs-repeat',
    'ngScrollbars',
    'ngCookies',
    accountModule,
    apiModule,
    authenticationModule,
    creatorModule,
    deleteModule,
    domainsModule,
    eventManagerModule,
    folderModule,
    formModule,
    modalsModule,
    navigationModule,
    reportingModule,
    stopRunModule,
    tagsModule,
    uiModule,
    userEventsModule,
    utilitiesModule,
    websocketsModule,
    actionsModule,
    selectorsModule,
    liveConnectModule,
  ])

  .value('THROTTLE_MILLISECONDS', 500) // for angularInifiniteScroll

  // make NgZone accessible from AngularJS
  .factory('ngZone', downgradeInjectable(NgZone))
  .factory('titleService', downgradeInjectable(Title))
  .factory('$exceptionHandler', globalExceptionHandler)

  .service(Names.Services.storageService, downgradeInjectable(StorageService))

  .config([
    AngularNames.httpProvider,
    ($httpProvider: angular.IHttpProvider) => {
      if (!(<any>angular).mock) {
        $httpProvider.interceptors.push(
          Names.Services.authenticationInterceptor
        );
      }
    }
  ])

  .config([
    AngularNames.locationProvider,
    ($locationProvider: angular.ILocationProvider) => {
      $locationProvider.html5Mode(true);
    }
  ])

  .config([
    'DEBUG',
    AngularNames.logProvider,
    (DEBUG: boolean, $logProvider: angular.ILogProvider) => {
      $logProvider.debugEnabled(DEBUG);
    }
  ])

  .config([
    AngularNames.localStorageServiceProvider,
    (localStorageServiceProvider: ILocalStorageServiceProvider) => {
      localStorageServiceProvider.setPrefix(Names.localStoragePrefix);
      localStorageServiceProvider.setDefaultToCookie(false);
    }
  ])

  .config([
    '$ngReduxProvider',
    (ngReduxProvider: ngRedux.INgReduxProvider) => {
      let appReducer = Redux.combineReducers(getReducers());
      let rootReducer = (state, action) => {
        if (action.type === ActionTypes.USER_LOGOUT) {
          state = undefined;
        }

        return appReducer(state, action);
      };
      ngReduxProvider.createStoreWith(rootReducer, [thunk]);
    }
  ])

  .config([
    'ngToastProvider',
    (ngToastProvider: any) => {
      ngToastProvider.configure({
        animation: 'fade',
        verticalPosition: 'bottom',
        maxNumber: 0,
        newestOnTop: true,
        combineDuplications: true
      });
    }
  ])

  .run([
    AngularNames.rootScope,
    Names.Services.ngToast,
    (
      $rootScope: angular.IRootScopeService,
      ngToast: any
    ) => {
      // Begin code to close modal on back button
      // Adapted from https://github.com/mgcrea/angular-strap/issues/864#issuecomment-62595002
      let modals = [];

      $rootScope.$on('modal.show', (event: angular.IAngularEvent, $modal) => {
        // if modal is not already in list
        if (modals.indexOf($modal) === -1) {
          modals.push($modal);
        }
      });

      $rootScope.$on('modal.hide', (event, $modal) => {
        var modalIndex = modals.indexOf($modal);
        if (modalIndex > -1) {
          modals.splice(modalIndex, 1);
        }
      });

      // Utils to be used in templates
      ($rootScope as any).Utils = {
        keys: Object.keys
      };
    }
  ]);

export default angular.module('appWrapper', [Names.module])
  .service(Names.Services.promiseUtils, PromiseUtils)
  .service(Names.NgServices.snackbarService, downgradeInjectable(SnackbarService))
  .service(Names.NgServices.accountsService, downgradeInjectable(AccountsService))
  .service(Names.NgServices.adminService, downgradeInjectable(AdminService))
  .service(Names.NgServices.router, downgradeInjectable(Router))
  .service(Names.NgServices.activatedRoute, downgradeInjectable(ActivatedRoute))
  .service(Names.NgServices.cacheReset, downgradeInjectable(CacheResetService))
  .service(Names.NgServices.actionSet, downgradeInjectable(ActionSetLibraryService))
  .service(Names.Services.date, downgradeInjectable(DateService))
  .service(Names.NgServices.rules, downgradeInjectable(RulesService))
  .service(Names.NgServices.labelService, downgradeInjectable(LabelService))
  ;
