import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ISupportContact } from '@app/components/usage-v2/usage-v2.models';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import {
  UsageHelpOverlayComponent
} from '@app/components/usage-v2/components/usage-top-widgets/components/usage-help-widget/components/usage-help-overlay/usage-help-overlay.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-help-overlay-trigger',
  templateUrl: './usage-help-overlay-trigger.component.html',
  styleUrls: ['./usage-help-overlay-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsageHelpOverlayTriggerComponent {
  @Input() contact: ISupportContact;
  @ViewChild('vc', { read: ViewContainerRef, static: true })
  vc: ViewContainerRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private cd: ChangeDetectorRef,
  ) {
  }

  private outsideClickSubscription: Subscription | null = null;

  get overlayPresent(): boolean {
    return !!this.vc?.length;
  }

  @HostListener('keyup.escape')
  onKeyupEscape(): void {
    this.close();
  }

  close(): void {
    this.vc.clear();
    this.outsideClickSubscription?.unsubscribe();
    this.cd.markForCheck();
  }

  open(contact: ISupportContact): void {
    if (this.overlayPresent) {
      this.close();
      return;
    }
    const ref = this.vc.createComponent(UsageHelpOverlayComponent);
    ref.instance.contact = contact;
    this.subscribeToOutsideClick();
  }

  private subscribeToOutsideClick(): void {
    this.outsideClickSubscription?.unsubscribe();
    this.outsideClickSubscription = fromEvent(this.document, 'click').subscribe((event: Event) => {
      !this.el.nativeElement.contains(event.target) && this.close();
      this.cd.detectChanges();
    });
  }
}
