import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IRule } from '@app/components/rules/rules.models';
import { IRuleSetupModalData } from '../rule-setup.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-setup-modal',
  templateUrl: './rule-setup-modal.component.html',
  styleUrls: ['./rule-setup-modal.component.scss']
})
export class RuleSetupModalComponent implements OnInit {

  mode: ERuleSetupMode;
  hidePageMatching: boolean;
  ruleId?: number;
  rule?: Partial<IRule>;

  constructor(public dialogRef: MatDialogRef<RuleSetupModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: IRuleSetupModalData) {}

  ngOnInit() {
    this.mode = this.modalData.mode;
    this.hidePageMatching = !!this.modalData.hidePageMatching;
    this.ruleId = this.modalData.ruleId;
    this.rule = this.modalData.rule;
  }

  close(rule: IRule) {
    this.dialogRef.close(rule);
  }
  
}
