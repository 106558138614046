import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConsentCategoriesFilterBarService } from './consent-categories-filter-bar.service';
import { IOpFilterBarMenuItem } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EConsentCategoriesFilterTypes } from './consent-categories-filter-bar.constants';
import { CCType } from '../consent-categories.constants';
import { ISearchByTextEmissionData } from '../../shared/components/op-filter-bar/op-filter-bar.models';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'consent-categories-filter-bar',
  templateUrl: './consent-categories-filter-bar.component.html',
  styleUrls: ['./consent-categories-filter-bar.component.scss']
})
export class ConsentCategoriesFilterBarComponent implements OnInit, OnDestroy {

  ccFilterBarMenuItems: IOpFilterBarMenuItem[] = [];
  validFilterTypes: EConsentCategoriesFilterTypes[];
  labels: ILabel[];
  labelSearchTextUpdated: number;
  private destroy$: Subject<void> = new Subject();

  constructor(
    public ccFilterBarService: ConsentCategoriesFilterBarService,
    private labelService: LabelService
  ) {}

  ngOnInit(): void {
    this.getValidFilterTypes();
    this.getLabels();
    this.buildMenu();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getValidFilterTypes(): void {
    this.ccFilterBarService.validFilterTypesUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe((validFilterTypes: EConsentCategoriesFilterTypes[]) => {
        this.validFilterTypes = validFilterTypes;
      });
  }

  getLabels(): void {
    this.labelService.getLabels().subscribe((labels: ILabel[]) => {
      this.labels = labels.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    });
  }

  buildMenu(): void {
    this.ccFilterBarMenuItems = [
      {
        name: 'Consent Category Type',
        type: EFilterBarMenuTypes.Flyout,
        children: [
          {
            name: 'Approved',
            type: EFilterBarMenuTypes.Button,
            action: () => this.ccFilterBarService.addCcTypeFilter(CCType.Approved)
          },
          {
            name: 'Unapproved',
            type: EFilterBarMenuTypes.Button,
            action: () => this.ccFilterBarService.addCcTypeFilter(CCType.Unapproved)
          }
        ]
      },
      {
        name: 'Labels',
        type: EFilterBarMenuTypes.Flyout,
        children: [
          {
            name: 'Label Search',
            type: EFilterBarMenuTypes.Search,
            searchPlaceholder: 'Search for label',
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleLabelSearch(event, el),
            children: []
          }
        ]
      },
      {
        name: 'Divider',
        type: EFilterBarMenuTypes.Divider
      },
      {
        name: 'clear all filters',
        type: EFilterBarMenuTypes.ClearBtn,
        action: () => this.ccFilterBarService.clear()
      }
    ];
  }

  handleSearchByName({ value }: ISearchByTextEmissionData): void {
    this.ccFilterBarService.addNameContainsFilter(value);
  }

  handleLabelSearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.labelSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if(el && Date.now() < this.labelSearchTextUpdated + 200) {
      el.focus();
      return;
    }

    // handle normal search event
    else {
      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

      let labelSearchChildren = this.labels
        .filter(label => {
          const name = label.name.toLowerCase();
          return name.includes(value);
        })
        .map(label => {
          return {
            name: label.name,
            type: EFilterBarMenuTypes.Button,
            action: () => this.ccFilterBarService.addCcLabelIdFilter({ name: label.name, id: label.id })
          }
        });

      // i don't like this but it's the only way to update a nested array
      // that's also being passed to a child component as an input
      this.ccFilterBarMenuItems[1].children[0].children = value
        ? labelSearchChildren
        : [];

      // trigger change detection
      this.ccFilterBarMenuItems = [ ...this.ccFilterBarMenuItems ];
    }
  }
}
