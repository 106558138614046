import { Component } from '@angular/core';
import {
  ImportUploadEmpty, ImportUploadNotCSV,
  ImportUploadOverOneMB
} from '@app/components/consent-categories/consent-categories.constants';

// for enterprise features
import { ColDef } from '@ag-grid-community/core';
import { GridApi } from 'ag-grid-community';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ag-grid-sample',
  templateUrl: './ag-grid-sample.component.html',
  styleUrls: ['./ag-grid-sample.component.scss'],
})
export class AgGridSampleComponent {
  hoveringOverDropzone: boolean = false;
  fileDropped: boolean = false;
  uploadedFile: File;
  errorMsg: string;
  importErrors: string[] = [];
  reader: FileReader = new FileReader();
  dropZoneLabel = 'Drag and drop a CSV file containing your CMP export data here.';
  columnDefs: ColDef[] = [];
  rowData: {(key: string): string}[] = [];
  sideBar: boolean = true;
  private gridApi!: GridApi;
  private gridColumnApi;
  quickFilter: string = '';

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 200,
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true,
    filter: true,
    resizable: true,
  };
  public autoGroupColumnDef: ColDef = {
    minWidth: 200,
  };

  constructor() {
    this.reader.addEventListener('load', () => {
      const { rowHeaders, rowData } = this.importCSVData(this.reader.result as string);
      this.columnDefs = rowHeaders;
      this.rowData = rowData;
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    // this.gridApi.setRowData(this.rowData);
    this.gridColumnApi = params.columnApi;
  }

  importErrorHandler(error) {
    this.importErrors = [];

    if (typeof error === 'string') {
      this.importErrors.push(error);
    } else if (error?.errorCode?.message.split(';').length <= 1) {
      this.importErrors.push(error?.errorCode.message);
    } else {
      let errorMsgArray = error?.errorCode.message.split(';');
      this.importErrors = errorMsgArray.slice(1, errorMsgArray.length);
      this.uploadedFile = null;
      this.fileDropped = false;
    }
  }

  onFileDrop(files: FileList) {
    this.errorMsg = null;
    this.hoveringOverDropzone = false;

    if (files.length === 0) return;

    const file = files[0];
    const fileExtension = file.name.split('.').pop();

    if (fileExtension === 'csv') {
      if (file.size <= 44) { // 44b is headers only
        this.importErrorHandler(ImportUploadEmpty);
        this.fileDropped = false;
      } else if (file.size <= 50000000) { // 50mb
        this.fileDropped = true;
        this.reader.readAsText(file);
      } else {
        this.importErrorHandler(ImportUploadOverOneMB);
        this.fileDropped = false;
      }
    } else {
      this.importErrorHandler(ImportUploadNotCSV);
      this.fileDropped = false;
    }
  }

  clearQuickFilter(): void {
    this.quickFilter = '';
    // this.gridApi.setQuickFilter(this.quickFilter);
  }

  doQuickFilter(): void {
    // this.gridApi.setQuickFilter(this.quickFilter);
  }

  groupByColumn(groupings: string[], headers: string[]) {
    return groupings.reduce((acc, curr, index) => {
      if (!acc.hasOwnProperty(curr)) {
        acc[curr] = [];
      }

      acc[curr].push(headers[index]);
      return acc;
    }, {});
  }

  importCSVData(file: string): { rowHeaders: ColDef[], rowData: any[]} {
    const fileRows = file.split('\n');
    const groupings = fileRows[0].split(',');
    const headers = fileRows[1].split(',');
    const groupedByColumns = this.groupByColumn(groupings, headers);
    const topLevelColumnHeaderNames = Object.keys(groupedByColumns);
    let rowHeaders = topLevelColumnHeaderNames.map(headerName => {
      return {
        headerName,
        children: groupedByColumns[headerName].map(field => { return { field }; })
      };
    });

    const rowData = fileRows.splice(2).map(r => {
      let row = {};
      let splitRow = r.split(',');

      splitRow.forEach((value, index) => {
        row[headers[index]] = value;
      });

      return row;
    });

    return { rowHeaders, rowData };
  }
}
