import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmailInboxesUIConstants } from '@app/components/email-inboxes/email-inboxes.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'email-inboxes-zero-state',
  templateUrl: './email-inboxes-zero-state.component.html',
  styleUrls: ['./email-inboxes-zero-state.component.scss']
})
export class EmailInboxesZeroStateComponent {
  CONSTANTS = {...EmailInboxesUIConstants.zeroState};

  @Input() loading: boolean = false;
  @Input() showZeroState: boolean = false;
  @Input() isDefaultMode?: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Output() onCreate: EventEmitter<void> = new EventEmitter();
}
