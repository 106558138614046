import { CacheControls } from '@app/components/core/decorators/global-decorators.models';

export function CacheReturn() {
  const savedReturns = new Map;

  return function (target: Object, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args) {
      const key = JSON.stringify(args);
      let value = savedReturns.get(key);

      if (!value) {
        value = originalMethod.apply(this, args);
        savedReturns.set(key, value);
      }

      return value;
    };

    descriptor.value.cacheControls = new CacheControls(savedReturns);
  };
}
