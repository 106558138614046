import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@app/components/material/material.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuditsPickerComponent } from './audits-picker.component';
import { AuditsPickerListComponent } from './audits-picker-list/audits-picker-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
  ],
  declarations: [
    AuditsPickerComponent,
    AuditsPickerListComponent,
  ],
  exports: [AuditsPickerComponent]
})
export class AuditsPickerModule {

}
