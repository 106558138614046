import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IOpFilterBarV2Filter,
  IOpFilterBarV2MenuItem
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EmailInboxesFilterBarService } from './email-inboxes-filter-bar.service';
import {
  EEmailInboxesFilterBarMenuStrings,
  EEmailInboxesFilterTypes, EEmailInboxesSupportedQueryParams,
  EMAIL_INBOXES_SUPPORTED_FILTER_TYPES, EMAIL_INBOXES_SUPPORTED_QUERY_PARAMS
} from './email-inboxes-filter-bar.constants';
import { ISearchByTextEmissionData } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EFilterBarV2MenuTypes } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.constants';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUser } from '@app/moonbeamModels';
import { AccountsService } from '@app/components/account/account.service';
import { DataSourcesService } from '@app/components/shared/services/data-sources/data-sources.service';
import {
  EDataSourcesSortBy,
  EDataSourceType
} from '@app/components/shared/services/data-sources/data-sources.constants';
import { IDataSource } from '@app/components/shared/services/data-sources/data-sources.models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'op-email-inboxes-filter-bar',
  templateUrl: './email-inboxes-filter-bar.component.html',
  styleUrls: ['./email-inboxes-filter-bar.component.scss']
})
export class EmailInboxesFilterBarComponent implements OnInit, OnDestroy {

  filterBarMenuItems: IOpFilterBarV2MenuItem[] = [];
  filters: IOpFilterBarV2Filter<string>[] = [];
  validFilterTypes: EEmailInboxesFilterTypes[];
  labelMenuItems: IOpFilterBarV2MenuItem[];
  labels: ILabel[];
  labelCheckedStatusMap: { [id: number]: boolean } = {};
  labelSearchTextUpdated: number;
  createdByMenuItems: IOpFilterBarV2MenuItem[];
  createdByCheckedStatusMap: { [id: number]: boolean } = {};
  createdBySearchText: string = '';
  createdBySearchTextUpdated: number;
  users: IUser[];
  associatedAuditMenuItems: IOpFilterBarV2MenuItem[];
  associatedAuditCheckedStatusMap: { [id: number]: boolean } = {};
  associatedAuditSearchTextUpdated: number;
  associatedAuditSearchText: string = '';
  audits: IDataSource[];

  getDataSourcesQueryParams = {
    sortBy: EDataSourcesSortBy.ItemName,
    sortDesc: false,
    itemType: EDataSourceType.Audit,
    withRuns: false,
  };

  private destroy$ = new Subject<void>();

  constructor(
    public emailInboxesFilterBarService: EmailInboxesFilterBarService,
    private labelService: LabelService,
    private accountsService: AccountsService,
    private dataSourcesService: DataSourcesService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.getValidFilterTypes();
    await this.getFilters();
    await this.getData();
    this.generateMenuItems();
    this.buildMenu();
    this.subscribeToAllFilterChanges();
    this.emailInboxesFilterBarService.rebuildChipMenusOnPageLoad(this.filterBarMenuItems);
    this.applyFiltersFromQueryParams();
  }

  private applyFiltersFromQueryParams(): void {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: typeof EEmailInboxesSupportedQueryParams) => {
        // Query parameter is *InboxName*, but filter is *name*
        if (params[EEmailInboxesSupportedQueryParams.InboxName]) {
          this.emailInboxesFilterBarService.handleInboxNameContainsFilter(params[EEmailInboxesSupportedQueryParams.InboxName])
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  subscribeToAllFilterChanges(): void {
    this.emailInboxesFilterBarService.filterChipRemoved$.subscribe((filter: EEmailInboxesFilterTypes) => {
      switch (filter) {
        case EEmailInboxesFilterTypes.Label:
          this.labelCheckedStatusMap = {};
          break;
        case EEmailInboxesFilterTypes.AssociatedAuditName:
          this.associatedAuditCheckedStatusMap = {};
          break;
        case EEmailInboxesFilterTypes.CreatedBy:
          this.createdByCheckedStatusMap = {};
          break;
      }

      this.generateMenuItems();
      this.buildMenu();
    });
  }

  getFilters(): Promise<void> {
    return new Promise<void>(resolve => {
      this.emailInboxesFilterBarService.filters$
        .pipe(takeUntil(this.destroy$))
        .subscribe((filters: IOpFilterBarV2Filter<EEmailInboxesFilterTypes>[]) => {
          this.filters = filters;
          this.createLabelCheckedStatusMap();
          this.createAssociatedAuditCheckedStatusMap();
          this.createCreatedByCheckedStatusMap();
          resolve();
        });
    });
  }

  getValidFilterTypes(): void {
    this.emailInboxesFilterBarService.validFilterTypesUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe((validFilterTypes: EEmailInboxesFilterTypes[]) => {
        this.validFilterTypes = validFilterTypes;
      });
  }

  getData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      forkJoin([
        this.labelService.getLabels(),
        this.accountsService.getUsers(),
        this.dataSourcesService.getAllDataSources(this.getDataSourcesQueryParams)
      ])
        .subscribe(([labels, users, audits]: [ILabel[], IUser[], IDataSource[]]) => {
          this.labels = labels.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
          this.users = users;
          this.audits = audits;
          resolve();
        });
    });
  }

  generateMenuItems(): void {
    this.labelMenuItems = this.generateLabelMenuItems();
    this.associatedAuditMenuItems = this.generateAssociatedAuditMenuItems();
    this.createdByMenuItems = this.generateCreatedByMenuItems();
  }

  generateLabelMenuItems(): IOpFilterBarV2MenuItem[] {
    return this.labels.map((label: ILabel) => {
      return {
        id: label.id,
        name: label.name,
        checked: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
        type: EFilterBarV2MenuTypes.Checkbox,
        displayWhen: this.getCheckedStateById(this.labelCheckedStatusMap, label.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
          item.displayWhen = checked;
          this.emailInboxesFilterBarService.handleEmailInboxesLabelsFilter(checked, item, menuItems);
        },
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.emailInboxesFilterBarService.handleEmailInboxesLabelsFilter(item.checked, item, menuItems, true);
        }
      };
    });
  }

  generateAssociatedAuditMenuItems(): IOpFilterBarV2MenuItem[] {
    return this.audits.map((audit: IDataSource) => {
      return {
        id: audit.itemId,
        name: audit.itemName,
        type: EFilterBarV2MenuTypes.Checkbox,
        displayWhen: this.getCheckedStateById(this.associatedAuditCheckedStatusMap, audit.itemId),
        checked: this.getCheckedStateById(this.associatedAuditCheckedStatusMap, audit.itemId),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
          item.displayWhen = item.name.toLowerCase().includes(this.associatedAuditSearchText);
          this.emailInboxesFilterBarService.handleEmailInboxesAssociatedAuditFilter(checked, item, menuItems);
        },
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.emailInboxesFilterBarService.handleEmailInboxesAssociatedAuditFilter(item.checked, item, menuItems, true);
        }
      };
    });
  }

  generateCreatedByMenuItems(): IOpFilterBarV2MenuItem[] {
    return this.users.map((user: IUser) => {
      return {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        type: EFilterBarV2MenuTypes.Checkbox,
        displayWhen: this.getCheckedStateById(this.createdByCheckedStatusMap, user.id),
        checked: this.getCheckedStateById(this.createdByCheckedStatusMap, user.id),
        action: (checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[]) => {
          item.displayWhen = item.name.toLowerCase().includes(this.createdBySearchText);
          this.emailInboxesFilterBarService.handleEmailInboxesCreatedByFilter(checked, item, menuItems);
        },
        onlyButtonAction: (item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem) => {
          this.handleOnlyButtons(item, menuItems, parent);
          this.emailInboxesFilterBarService.handleEmailInboxesCreatedByFilter(item.checked, item, menuItems, true);
        }
      };
    });
  }

  buildMenu(): void {
    this.filterBarMenuItems = [
      {
        name: EEmailInboxesFilterBarMenuStrings.InboxAddress,
        type: EFilterBarV2MenuTypes.Flyout,
        children: [{
          name: EEmailInboxesFilterBarMenuStrings.InboxAddress,
          type: EFilterBarV2MenuTypes.Search,
          searchPlaceholder: 'Search by inbox email address',
          action: (event: KeyboardEvent) => this.handleSearchByInboxAddress(event),
        }]
      },
      {
        name: EEmailInboxesFilterBarMenuStrings.Name,
        type: EFilterBarV2MenuTypes.Flyout,
        children: [{
          name: EEmailInboxesFilterBarMenuStrings.Name,
          type: EFilterBarV2MenuTypes.Search,
          searchPlaceholder: 'Search by inbox name',
          action: (event: KeyboardEvent) => this.handleSearchByInboxName(event),
        }]
      },
      {
        name: EEmailInboxesFilterBarMenuStrings.Label,
        type: EFilterBarV2MenuTypes.Flyout,
        children: [
          {
            name: EEmailInboxesFilterBarMenuStrings.Label,
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search by label name',
            searchAllowsMultipleSelections: true,
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleLabelSearch(event, el),
            children: []
          } as IOpFilterBarV2MenuItem
        ].concat(this.labelMenuItems)
      },
      {
        name: EEmailInboxesFilterBarMenuStrings.AuditName,
        type: EFilterBarV2MenuTypes.Flyout,
        children: [
          {
            name: 'Associated Audit Name Search',
            searchAllowsMultipleSelections: true,
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search by associated audit name',
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleSearchByAuditName(event, el),
            children: []
          } as IOpFilterBarV2MenuItem
        ].concat(this.associatedAuditMenuItems)
      },
      {
        name: EEmailInboxesFilterBarMenuStrings.CreatedBy,
        type: EFilterBarV2MenuTypes.Flyout,
        children: [
          {
            name: 'Creator Search',
            searchAllowsMultipleSelections: true,
            type: EFilterBarV2MenuTypes.Search,
            searchPlaceholder: 'Search for creator',
            action: (event: KeyboardEvent, el?: HTMLElement) => this.handleCreatorSearch(event, el),
            children: []
          } as IOpFilterBarV2MenuItem
        ].concat(this.createdByMenuItems)
      }
    ];
  }

  handleSearchByName({ value }: ISearchByTextEmissionData): void {
    this.emailInboxesFilterBarService.handleEmailInboxNameContainsFilter(value);
  }

  handleSearchByInboxAddress(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
      this.emailInboxesFilterBarService.handleEmailAddressContainsFilter(value);
    }
  }

  handleSearchByInboxName(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
      this.emailInboxesFilterBarService.handleInboxNameContainsFilter(value);
    }
  }

  handleLabelSearch(event: KeyboardEvent, el?: HTMLElement): void {
    this.labelSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.labelSearchTextUpdated + 200) {
      try {
        el.focus();
      } catch (e) {
      }

      return;
    }

    // filter labels that include search value
    const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.labelMenuItems.map((item: IOpFilterBarV2MenuItem) => {
      item.displayWhen = value
        ? item.name.toLowerCase().includes(value)
        : item.checked;

      return item;
    });
  }

  private handleSearchByAuditName(event: KeyboardEvent, el?: HTMLElement, isLabel = false) {
    this.associatedAuditSearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.associatedAuditSearchTextUpdated + 200) {
      try {
        el.focus();
      } catch (e) {
      }

      return;
    }

    // filter labels that include search value
    this.associatedAuditSearchText = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.associatedAuditMenuItems.map((item: IOpFilterBarV2MenuItem) => {
      item.displayWhen = this.associatedAuditSearchText
        ? item.name.toLowerCase().includes(this.associatedAuditSearchText)
        : item.checked;

      return item;
    });
  }

  private handleCreatorSearch(event: KeyboardEvent, el?: HTMLElement, isLabel = false) {
    this.createdBySearchTextUpdated = Date.now();

    // stolen! https://github.com/angular/components/issues/7973
    // Material issue occassionally tries to steal the focus away from embedded textboxes to give to menu items
    if (el && Date.now() < this.createdBySearchTextUpdated + 200) {
      try {
        el.focus();
      } catch (e) {
      }

      return;
    }

    // filter labels that include search value
    this.createdBySearchText = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
    this.createdByMenuItems.map((item: IOpFilterBarV2MenuItem) => {
      item.displayWhen = this.createdBySearchText
        ? item.name.toLowerCase().includes(this.createdBySearchText)
        : item.checked;

      return item;
    });
  }

  getCheckedStateById(mapName: { [id: number]: boolean }, itemId: number): boolean {
    return mapName[itemId] !== undefined ? mapName[itemId] : false;
  }

  createLabelCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.labelCheckedStatusMap = {};
      return;
    }

    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.type === EEmailInboxesFilterTypes.Label);

    if (!filter) {
      this.labelCheckedStatusMap = {};
      return;
    }

    filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
      this.labelCheckedStatusMap[item.id] = item.checked;
    });
  }

  createAssociatedAuditCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.associatedAuditCheckedStatusMap = {};
      return;
    }

    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.type === EEmailInboxesFilterTypes.AssociatedAuditName);

    if (!filter) {
      this.associatedAuditCheckedStatusMap = {};
      return;
    }

    filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
      this.associatedAuditCheckedStatusMap[item.id] = item.checked;
    });
  }

  createCreatedByCheckedStatusMap(): void {
    if (!this.filters.length) {
      this.createdByCheckedStatusMap = {};
      return;
    }

    const filter = this.filters.find((filter: IOpFilterBarV2Filter<string>) => filter.type === EEmailInboxesFilterTypes.CreatedBy);

    if (!filter) {
      this.createdByCheckedStatusMap = {};
      return;
    }

    filter.menuItems.forEach((item: IOpFilterBarV2MenuItem) => {
      this.createdByCheckedStatusMap[item.id] = item.checked;
    });
  }

  handleOnlyButtons(item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], parent?: IOpFilterBarV2MenuItem): void {
    // deselect everything
    this.deselectAllMenuItems(menuItems);

    // set checked = true to item
    item.checked = true;

    // handle child elements if they exist
    if (item.children?.length) {
      item.children.forEach((child: IOpFilterBarV2MenuItem) => child.checked = true);
    }
  }

  deselectAllMenuItems(arr: IOpFilterBarV2MenuItem[]): void {
    arr.forEach((menuItem: IOpFilterBarV2MenuItem) => {
      menuItem.checked = false;

      if (menuItem.children?.length) {
        this.deselectAllMenuItems(menuItem.children);
      }
    });
  }

  menuClosed(): void {
    this.emailInboxesFilterBarService.onMenuClosed();
  }
}
