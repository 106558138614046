import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-chip-manager',
  templateUrl: './cl-chip-manager.component.html',
  styleUrls: ['./cl-chip-manager.component.scss']
})
export class ComponentLibraryChipManagerComponent {
  chips: any[] = [
    { id: 1, name: 'Skylar Rodriguez' },
    { id: 2, name: 'Devin Washington' },
    { id: 3, name: 'Harper Lee' },
    { id: 4, name: 'Avery Chen' },
    { id: 5, name: 'Morgan O\'Neill' },
    { id: 6, name: 'Taylor Nguyen' },
    { id: 7, name: 'Jordan Patel' },
    { id: 8, name: 'Casey Williams' },
    { id: 9, name: 'Riley Garcia' },
    { id: 10, name: 'Quinn Johnson' },
    { id: 11, name: 'Sawyer Kim' },
    { id: 12, name: 'Dakota Brown' },
  ];

  example1SelectedChips: any[] = [];

  example2SelectedChips: any[] = [
    { id: 3, name: 'Harper Lee' },
    { id: 5, name: 'Morgan O\'Neill' },
  ];

  example3SelectedChips: any[] = [];

  example4SelectedChips: any[] = [
    { id: 3, name: 'Harper Lee' },
    { id: 5, name: 'Morgan O\'Neill' },
  ];

  example5SelectedChips: any[] = [
    { id: 3, name: 'Harper Lee' },
    { id: 5, name: 'Morgan O\'Neill' },
  ];

  example1OnChipSelected(chip: any) {
    this.example1SelectedChips.push(chip);
    this.example1SelectedChips = [...this.example1SelectedChips];
  }

  example1OnChipRemoved(chip: any) {
    this.example1SelectedChips = this.example1SelectedChips.filter(c => c.id !== chip.id);
    this.example1SelectedChips = [...this.example1SelectedChips];
  }

  example1OnChipCreated(chip: any) {
    const newChip = { id: this.chips.length + 1, name: chip };
    this.chips.push(newChip);
    this.chips = [...this.chips];
    this.example1OnChipSelected(newChip);
  }

  example2OnChipSelected(chip: any) {
    this.example2SelectedChips.push(chip);
    this.example2SelectedChips = [...this.example2SelectedChips];
  }

  example2OnChipRemoved(chip: any) {
    this.example2SelectedChips = this.example2SelectedChips.filter(c => c.id !== chip.id);
    this.example2SelectedChips = [...this.example2SelectedChips];
  }

  example2OnChipCreated(chip: any) {
    const newChip = { id: this.chips.length + 1, name: chip };
    this.chips.push(newChip);
    this.chips = [...this.chips];
    this.example2OnChipSelected(newChip);
  }

  example3OnChipSelected(chip: any) {
    this.example3SelectedChips.push(chip);
    this.example3SelectedChips = [...this.example3SelectedChips];
  }

  example3OnChipRemoved(chip: any) {
    this.example3SelectedChips = this.example3SelectedChips.filter(c => c.id !== chip.id);
    this.example3SelectedChips = [...this.example3SelectedChips];
  }

  example3OnChipCreated(chip: any) {
    const newChip = { id: this.chips.length + 1, name: chip };
    this.chips.push(newChip);
    this.chips = [...this.chips];
    this.example3OnChipSelected(newChip);
  }

  example4OnChipSelected(chip: any) {
    this.example4SelectedChips.push(chip);
    this.example4SelectedChips = [...this.example4SelectedChips];
  }

  example4OnChipRemoved(chip: any) {
    this.example4SelectedChips = this.example4SelectedChips.filter(c => c.id !== chip.id);
    this.example4SelectedChips = [...this.example4SelectedChips];
  }

  example4OnChipCreated(chip: any) {
    const newChip = { id: this.chips.length + 1, name: chip };
    this.chips.push(newChip);
    this.chips = [...this.chips];
    this.example4OnChipSelected(newChip);
  }

  example5OnChipSelected(chip: any) {
    this.example5SelectedChips.push(chip);
    this.example5SelectedChips = [...this.example5SelectedChips];
  }

  example5OnChipRemoved(chip: any) {
    this.example5SelectedChips = this.example5SelectedChips.filter(c => c.id !== chip.id);
    this.example5SelectedChips = [...this.example5SelectedChips];
  }

  example5OnChipCreated(chip: any) {
    const newChip = { id: this.chips.length + 1, name: chip };
    this.chips.push(newChip);
    this.chips = [...this.chips];
    this.example5OnChipSelected(newChip);
  }
}
