import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/components/material/material.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ActionsCreatorComponent } from './actions-creator/actions-creator.component';
import { DragulaModule } from 'ng2-dragula';
import { ActionDetailsComponent } from '@app/components/actions/action-details/action-details.component';
import { ActionDetailsService } from '@app/components/actions/action-details/action-details.service';
import { ActionPreviewInfoPipe } from '@app/components/actions/actions-preview/action-preview-info/action-preview-info.pipe';
import { ActionsPreviewComponent } from '@app/components/actions/actions-preview/actions-preview.component';
import { ActionPreviewComponent } from '@app/components/actions/actions-preview/action-preview/action-preview.component';
import { MaskedInputComponent } from '@app/components/actions/actions-preview/masked-input/masked-input.component';
import { ActionDetailsFormBuilder } from './action-details/action-details-form-builder.service';
import { DeleteActionModalComponent } from './delete-action-modal/delete-action-modal.component';
import { ActionWaitTimeComponent } from './action-wait-time/action-wait-time.component';
import { OpModalModule } from '../shared/components/op-modal/op-modal.module';
import { MultiSelectorsModule } from '../shared/components/multi-selectors/multi-selectors.module';
import { OpFormModule } from '../shared/op-form.module';
import { OpAccordionModule } from '../shared/components/op-accordion/op-accordion.module';
import { ClipboardModule } from 'ngx-clipboard';
import { MdePopoverModule } from '../popover';
import { OpCodeHighlighterModule } from '../op-code-highlighter/op-code-highlighter.module';
import { UiScrollModule } from 'ngx-ui-scroll';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    OpModalModule,
    OpFormModule,
    MultiSelectorsModule,
    DragulaModule,
    OpAccordionModule,
    MdePopoverModule,
    ClipboardModule,
    OpCodeHighlighterModule,
    UiScrollModule
  ],
  declarations: [
    ActionsCreatorComponent,
    ActionsPreviewComponent,
    ActionPreviewInfoPipe,
    ActionPreviewComponent,
    MaskedInputComponent,
    ActionDetailsComponent,
    DeleteActionModalComponent,
    ActionWaitTimeComponent,
  ],
  providers: [
    ActionDetailsService,
    ActionDetailsFormBuilder,
  ],
  exports: [
    ActionsCreatorComponent,
    ActionsPreviewComponent,
    ActionPreviewComponent,
  ]
})
export class ActionsModule {}
