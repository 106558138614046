import { IBubbleProgressData } from '../../../../../reporting/widgets/bubbleProgress/bubbleProgressData';
import { ReportStatus } from '@app/components/manage/shared/manage.util';
import {
  IAuditReportCard,
  IWebJourneyReportCard
} from '@app/components/manage/cards/home-card-list/home-card-list.models';

export interface ISortingPriority {
    [key: string]: number
  }

  export abstract class ISortingService {
    abstract compareNullsLast(a: any, b: any, compare: (a, b) => number);
    abstract sortByFirstNumberInName(a: IBubbleProgressData, b: IBubbleProgressData): number;
    abstract defaultCompare(a: any, b: any, reverse?: boolean): number;
    abstract compareDates(a: Date, b: Date, desc: boolean): number;
    abstract compareAuditSize(a: IAuditReportCard | IWebJourneyReportCard, b: IAuditReportCard | IWebJourneyReportCard, desc: boolean): number;
    abstract compareReportCardStatus(a: ReportStatus, b: ReportStatus, priority?: ISortingPriority): number;
  }

  const NUMBER_REGEX = /(^\d+).*/;
  const FIRST_GROUP = '$1';

  export class SortingService extends ISortingService {
    public compareNullsLast(a: any, b: any, compare: (a, b) =>  number): number {
      if (!b && a) {
        return 1;
      }

      if (!a && b) {
        return -1;
      }

      if (!a && !b) {
        return 0;
      }

      return compare(a, b);
    }

    public sortByFirstNumberInName(a: IBubbleProgressData, b: IBubbleProgressData): number {
      return Number(a.name.replace(NUMBER_REGEX, FIRST_GROUP)) - Number(b.name.replace(NUMBER_REGEX, FIRST_GROUP));
    }

    /**
     * Default comarison between objects. Will compare strings with lowercase value
     */
    public defaultCompare(a: any, b: any, reverse?: boolean): number {
      let aValue = typeof a === 'string' ? a.toLowerCase() : a;
      let bValue = typeof b === 'string' ? b.toLowerCase() : b;

      if (aValue === bValue) return 0;
      const result = (aValue < bValue) ? -1 : 1;
      return reverse ? (result * -1) : result;
    }

    public compareDates(a: Date, b: Date, desc: boolean = true): number {
      return desc
        ? b.getTime() - a.getTime()
        : a.getTime() - b.getTime();
    }

    public compareAuditSize(a: IAuditReportCard | IWebJourneyReportCard, b: IAuditReportCard | IWebJourneyReportCard, desc: boolean = true): number {
      const date = new Date();
      return !desc
        ? (b.type === 'audit' ? b.pageCount * 1e14 + b.name.toLowerCase().charCodeAt(0) * 1e-3 : ((b.lastRunDate?.getTime() - date.getTime()) || (b.name.toLowerCase().charCodeAt(0) * -1e14))) - (a.type === 'audit' ? a.pageCount * 1e14 + a.name.toLowerCase().charCodeAt(0) * 1e-3 : ((a.lastRunDate?.getTime() - date.getTime()) || (a.name.toLowerCase().charCodeAt(0) * -1e14)))
        : (a.type === 'audit' ? a.pageCount * 1e14 + a.name.toLowerCase().charCodeAt(0) * 1e-3 : ((a.lastRunDate?.getTime() - date.getTime()) || (a.name.toLowerCase().charCodeAt(0) * -1e14))) - (b.type === 'audit' ? b.pageCount * 1e14 + b.name.toLowerCase().charCodeAt(0) * 1e-3 : ((b.lastRunDate?.getTime() - date.getTime()) || (b.name.toLowerCase().charCodeAt(0) * -1e14)));
    }

    public compareReportCardStatus(a: ReportStatus, b: ReportStatus, priority?: ISortingPriority) {
      if (!priority) {
        priority = {
          'failed': 1,
          'rule_failed': 2,
          'app_failed': 3,
          'done': 4,
          'running': 5,
          'in_queue': 6,
          'scheduled': 7
        };
      }

      return priority[a] - priority[b];
    }
  }
