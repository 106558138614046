import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { ECopyBoxColor } from '@app/components/copy-box/copy-box.enums';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'op-copy-box',
  templateUrl: './copy-box.component.html',
  styleUrls: ['./copy-box.component.scss']
})
export class CopyBoxComponent {
  @ViewChild('tooltip', { read: MatTooltip }) private tooltip: MatTooltip;
  private TOOLTIP_DEFAULT_TRANSITION_TIME: number = 200;

  @Input() showInfo: boolean = false;
  @Input() copyBtnDisabled: boolean = false;
  @Input() infoText: string = 'The custom email address for this inbox will be provided once this new inbox is saved.';
  @Input() infoIcon: string = 'info';
  @Input() copyBtnText: string = 'Copy';
  @Input() copyBtnIcon: string = 'content_copy';
  @Input() copyValue: string = '';
  @Input() compactCopyBtn: boolean = false;
  @Input() @HostBinding('class') color: ECopyBoxColor = ECopyBoxColor.Gray;
  @Input() copyTooltipText: string = 'Copy to clipboard';
  @Input() copiedTooltipText: string = 'Copied to clipboard';

  copyBtnClick(): void {
    setTimeout(() => {
      this.tooltip.message = this.copiedTooltipText;
      this.tooltip.show();
    });
  }

  resetTooltip(): void {
    setTimeout(() => {
      this.tooltip.message = this.copyTooltipText;
    }, this.TOOLTIP_DEFAULT_TRANSITION_TIME);
  }
}
