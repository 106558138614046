<div class="title-container">
  <h1 class="title">Shared Links
    <span class="icon-wrapper" [class.highlight]="infoIsExpanded" (click)="infoIsExpanded = !infoIsExpanded">
      <mat-icon class="title-icon">help_outline</mat-icon>
      <mat-icon class="expansion-icon">expand_more</mat-icon>
    </span>
  </h1>
  <div class="controls">
    <ng-container *ngIf="isSharingEnabled !== undefined">
      <span class="toggle-label">Account Link Sharing</span>
      <mat-slide-toggle color="primary" (change)="updateFeatureFlag()" [checked]="isSharingEnabled">
        {{isSharingEnabled ? 'Active' : 'Disabled' }}
      </mat-slide-toggle>
    </ng-container>

    <op-button-2021 matIcon="more_vert"
                    [disabled]="!isSharingEnabled || selected.length === 0"
                    [menuItems]="actionsMenu"
                    [showDefaultMenuIcon]="false"
                    buttonType="filled">
    </op-button-2021>
  </div>
</div>
<op-info-panel [expanded]="infoIsExpanded" class="info-panel">
  <p>
    Below are the links shared in your account. If needed, each link can be individually expired.
    Account admins can also disable sharing account wide, this will disable all current shares.
    Links automatically expire after 90 days. Anyone on the internet with the link can view all
    reports for the item shared.
  </p>
</op-info-panel>
<op-filter-bar [menuItems]="filterBarMenuItems"
               [service]="filterBarService"
               [isSearchByTextEnabled]="true"
               [validFilterTypes]="filterBarService.validFilterTypesUpdated$ | async"
               [hideSearchByRegexOption]="true"
               matMenuClass="shared-links-filter-bar-mat-menu"
               (searchByTextIsEntered)="filterBarService.handleDataSourceNameContainsFilter($event.value)"
               searchByTextPlaceholderSuffix="Data Source Name">
</op-filter-bar>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>
<div class="user-table-wrap">
  <table mat-table matSort
         [dataSource]="dataSource"
         class="styled-mat-table"
         [matSortActive]="sorting.sortBy"
         [matSortDirection]="sorting.sortDir === 'asc' ? 'desc' : 'asc'"
         [class.loading]="loading">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="selectAll($event)"
                      [disabled]="!isSharingEnabled"
                      [checked]="selected.length === dataSource.data.length && selected.length > 0"
                      color="primary">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <span [matTooltip]="row.expired ? 'Can\'t select - Link has expired' : !isSharingEnabled ? 'Account Link Sharing is disabled' : ''"
              [matTooltipDisabled]="!row.expired && isSharingEnabled">
          <mat-checkbox (change)="select($event, row)"
                        [disabled]="!isSharingEnabled || row.expired"
                        [checked]="selected.includes(row)"
                        color="primary">
          </mat-checkbox>
        </span>
      </td>
    </ng-container>

    <!-- Data Source Name Column -->
    <ng-container matColumnDef="ITEM_NAME">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Data Source Name</th>
      <td mat-cell *matCellDef="let sharedLink">{{sharedLink.itemName}}</td>
    </ng-container>

    <!-- Report Name Column -->
    <ng-container matColumnDef="REPORT_NAME">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Report</th>
      <td mat-cell *matCellDef="let sharedLink">{{sharedLink.reportName}}</td>
    </ng-container>

    <!-- Folder Column -->
    <ng-container matColumnDef="FOLDER_NAME">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Folder</th>
      <td mat-cell *matCellDef="let sharedLink">{{sharedLink.folderName}}</td>
    </ng-container>

    <!-- Shared Link Column -->
    <ng-container matColumnDef="shareLink">
      <th mat-header-cell *matHeaderCellDef>Share Link</th>
      <td mat-cell *matCellDef="let sharedLink" class="copy-container">
        <button mat-icon-button ngxClipboard [cbContent]="sharedLink.link" [matTooltip]="'Click to copy to clipboard'" matTooltipPosition="above">
          <mat-icon>content_copy</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Primary User Column -->
    <ng-container matColumnDef="CREATED_BY">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Shared By</th>
      <td mat-cell *matCellDef="let sharedLink">
          {{ sharedLink?.sharedByUser
            ? ( sharedLink.sharedByUser.firstName + ' ' + sharedLink.sharedByUser.lastName + ' (' + sharedLink.sharedByUser.email + ')' )
            : 'OP System' }}
      </td>
    </ng-container>

    <!-- Recent Activity Column -->
    <ng-container matColumnDef="sharedTo">
      <th mat-header-cell *matHeaderCellDef>Shared To</th>
      <td mat-cell *matCellDef="let sharedLink">
        {{sharedLink.sharedTo.join(', ') }}
      </td>
    </ng-container>

    <!-- Views Column -->
    <ng-container matColumnDef="VIEW_COUNT">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Views</th>
      <td mat-cell *matCellDef="let sharedLink">
        {{sharedLink.viewCount}}
      </td>
    </ng-container>

    <!-- Expiration Column -->
    <ng-container matColumnDef="EXPIRES">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Expiration</th>
      <td mat-cell *matCellDef="let sharedLink">
        {{sharedLink.expires | date: 'YYYY-MM-dd'}}
      </td>
    </ng-container>

    <!-- status Column -->
    <ng-container matColumnDef="STATUS">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let sharedLink">
        {{sharedLink.expired ? 'Expired' : sharedLink.disabled ? 'Disabled' : !isSharingEnabled ? 'Paused' : 'Enabled' }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr *matNoDataRow>
      <td [colSpan]="displayedColumns.length">
        <div *ngIf="!loading" class="no-data">
          <span>{{isFiltersApplied ? 'No shared links matched applied filters.' : 'No shared links.'}}</span>
        </div>
      </td>
    </tr>
  </table>
  <mat-paginator class="styled-mat-paginator"
                 [class.hide-paginator]="loading || pagination.totalCount < 101"
                 [length]="pagination?.totalCount"
                 [pageSize]="pagination?.pageSize"
                 [hidePageSize]="true">
  </mat-paginator>
</div>
