export interface IComparisonReportStateParams {
  auditId: string;
  runId: string;
  comparisonSnapshotId?: string; // if not specified - all comparisons
}

export interface IComparisonReportPagesStateParams extends IComparisonReportStateParams {
  tagId: string;
  tagPresenceDiff: string;
}

export interface IItemScoreResponse {
  runId: number;
  comparisonId: number;
  comparisonSnapshotId: number;
  finishedAt: string | Date;
  tagParityScore: number;
  variableParityScore: number;
  variableValueParityScore: number;
  evaluatedPages: number;
  notComparedPages: number;
}

export namespace IItemScoreResponse {
  export function toItemScore(score: IItemScoreResponse): IItemScore {
    return {
      ...score,
      finishedAt: new Date(score.finishedAt),
      averageScore: Math.round((score.tagParityScore + score.variableParityScore + score.variableValueParityScore) / 3)
    };
  }
}

export interface IItemScore extends IItemScoreResponse {
  averageScore: number;
}

export interface IPage {
  pageId: string;
  pageUrl: string;
  runId: number;
}

export interface IParityChartOptions {
  bgArcColor: string;
  fgArcColor: string;
  valueColor: string;
}
