import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ITerminateActiveRunsModalPayload,
  Item,
  ItemType
} from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import { IStopRunService } from '@app/components/stopRun/stopRunService';
import { RunnableItem } from '@app/components/stopRun/stopRunModalController';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'terminate-active-runs-modal',
  templateUrl: './terminate-active-runs-modal.component.html',
  styleUrls: ['./terminate-active-runs-modal.component.scss']
})
export class TerminateActiveRunsModalComponent {

  title: string;
  rightFooterButtons = [
    {
      label: 'Ok',
      primary: true,
      action: () => {
        this.close();
      },
    }
  ];
  type: string;
  items: Item[];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ITerminateActiveRunsModalPayload,
    private dialogRef: MatDialogRef<TerminateActiveRunsModalComponent>,
    private stopRunService: IStopRunService
  ) {
    this.title = 'Terminate active runs';
    this.type = data.type;
    this.items = data.items;
  }

  close(): void {
    this.dialogRef.close();
  }

  itemTypeToString(itemType: ItemType): string {
    switch (itemType) {
      case ItemType.Audit:
        return 'Audit';
      case ItemType.WebJourney:
        return 'Web Journey';
      case ItemType.LiveConnect:
        return 'Live Connect';
    }
  }

  terminateRun(item: Item): void {
    const itemTypeToRunnableItem = (itemType: ItemType) => {
      switch (itemType) {
        case ItemType.Audit:
          return RunnableItem.Audit;
        case ItemType.WebJourney:
          return RunnableItem.WebJourney;
        case ItemType.LiveConnect:
          return RunnableItem.LiveConnect;
      }
    }
    this.stopRunService.stopRun(itemTypeToRunnableItem(item.itemType), item.itemId, item.runId, () => {
      const index = this.items.findIndex(i => i.itemId === item.itemId && i.runId === item.runId);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    });
  }
}
