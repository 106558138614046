import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IButton } from '@app/models/commons';
import { ShareLinksService } from '@app/components/share-links/share-links.service';
import { IShareReportModalPayload } from './share-report-modal.models';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ISharingResponse } from '@app/components/share-links/share-links.models';
import {
  ShareReportSnackbarComponent
} from '@app/components/audit-reports/share-report-modal/success-snackbar/share-report-snackbar.component';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'share-report-modal',
  templateUrl: './share-report-modal.component.html',
  styleUrls: ['./share-report-modal.component.scss']
})
export class ShareReportModalComponent {
  private link: ISharingResponse;

  readonly maxMessageLength = 1000;
  readonly leftFooterButtons: IButton[] = [{
    icon: 'icon-link3',
    label: 'COPY PUBLIC LINK',
    action: () => {
      this.clipboard.copy(this.link.url);
      this.snackBar.openFromComponent(ShareReportSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: {
          auditName: this.payload.auditName,
          reportName: this.payload.reportName
        },
        panelClass: 'snackbar-wide-width',
        duration: 5000
      });
      this.close(true);
    },
    transparent: true,
    opSelector: 'share-report-modal-copy-link'
  }];

  readonly rightFooterButtons: IButton[] = [{
    label: 'SEND',
    action: () => {
      this.sendLink().subscribe(() => {
        this.close(true);

        this.snackBar.openFromComponent(ShareReportSnackbarComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          data: {
            auditName: this.payload.auditName,
            reportName: this.payload.reportName,
            emails: this.formGroup.get('emails').value
          },
          panelClass: 'snackbar-wide-width',
          duration: 5000
        });

      });
    },
    primary: true,
    hidden: true,
    disabled: true,
    opSelector: 'share-report-modal-ok-button',
  }];

  formGroup = new UntypedFormGroup({
    emails: this.formBuilder.control([], Validators.compose([Validators.required, Validators.minLength(1)])),
    message: this.formBuilder.control(null, [Validators.maxLength(this.maxMessageLength)]),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public payload: IShareReportModalPayload,
              private dialogRef: MatDialogRef<ShareReportModalComponent>,
              private clipboard: Clipboard,
              private formBuilder: UntypedFormBuilder,
              private shareLinksService: ShareLinksService,
              public applicationChromeService: ApplicationChromeService,
              private snackBar: MatSnackBar) {

    this.applicationChromeService.isVisitorMode$.subscribe((isVisitorMode) => {
      this.rightFooterButtons[0].hidden = isVisitorMode;
    });

    this.link = payload.link;

    this.formGroup.updateValueAndValidity();
    this.formGroup.statusChanges.subscribe(() => {
      this.rightFooterButtons[0].disabled = this.formGroup.invalid;
    });
  }

  private sendLink(toSend = false) {
    this.leftFooterButtons[0].disabled = this.rightFooterButtons[0].disabled = true;

    const sharedItemDetails = {
      emails: this.formGroup.get('emails').value || [],
      customMessage: this.formGroup.get('message').value || ''
    };

    return this.shareLinksService.sendSharedLink(this.link.id, sharedItemDetails);
  }

  close(toSend = false) {
    this.dialogRef.close(toSend);
  }

  get messageControl(): UntypedFormControl {
    return this.formGroup.get('message') as UntypedFormControl;
  }

}
