import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ESplitCardChangeMeaning } from '@app/components/shared/components/split-card/split-card.models';
import {
  IStatsPopupPanelData
} from '@app/components/audit-reports/use-cases/stats-popup-panel/stats-popup-panel.models';
import { EUseCaseBannerColor } from '../use-cases.models';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'use-case-widget',
  templateUrl: './use-case-widget.component.html',
  styleUrls: ['./use-case-widget.component.scss']
})
export class UseCaseWidgetComponent {
  /**
   * This component is meant to help build the use cases widget
   * since many of the widgets share the same or similar structure.
   *
   * The left side of the widget can be configured with the inputs
   * below or you can pass in a custom template for either top or
   * bottom (or both). It supports both split mode as well as one
   * singular value. In the case of a singular value, the bottom
   * text and bottom value are not needed.
   *
   * The right side (largest side) of the widget will always
   * have custom content passed in.
   */

  @Input() splitCard: boolean = true;
  @Input() opSelector: string = '';
  @Input() richTooltip: boolean = false;

  /**
   * SPLIT CARD TOP INPUTS
   */

  // if this is passed in then all other top inputs are not needed
  @Input() topLeftCustomContent: boolean = false;

  // optional if previous input is passed as true
  @Input() topIconName: string; // used for material icons
  @Input() topIconOutline?: boolean; // used for outlined material icons that don't have a "{{iconName}}_outline" version, but do support the "material-icons-outlined" class
  @Input() topIconPath: string; // used for tag svg icons: `https://s3.amazonaws.com/.../${iconPath}.svg`
  @Input() topIconMeaning?: ESplitCardChangeMeaning = ESplitCardChangeMeaning.NONE;
  @Input() topText: string; // supports html tags if needed for formatting
  @Input() topValue: string | number;
  @Input() topValueMeaning?: ESplitCardChangeMeaning = ESplitCardChangeMeaning.NONE;
  @Input() topTooltip: string;
  @Input() topClickAction: Function;
  @Input() statsData?: IStatsPopupPanelData;
  @Input() loading: boolean = false;
  @Input() bannerText: string;
  @Input() bannerClickAction: Function;
  @Input() bannerColor: EUseCaseBannerColor;

  EUseCaseBannerColor = EUseCaseBannerColor;
  widgetHover: boolean = false;
  widgetLabelHover: boolean = false;
  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 6,
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: null,
    },
  ];

  /**
   * SPLIT CARD BOTTOM INPUTS
   */

  // if this is passed in then all other bottom inputs are not needed
  @Input() bottomLeftCustomContent: boolean = false;

  // optional if previous input is passed as true
  @Input() bottomText: string; // supports html tags if needed for formatting
  @Input() bottomValue: string | number;
  @Input() bottomTooltip: string;
  @Input() hideBottom = false;

  tooltipDelay: number = 500;

  labelHover(): void {
    this.widgetLabelHover = true;
    this.widgetHover = true;
  }

  labelBlur(): void {
    this.widgetLabelHover = false;
    this.widgetHover = false;
    this.cd.detectChanges();
  }

  constructor(private cd: ChangeDetectorRef) {
  }
}
