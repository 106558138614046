import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { IDeleteItemWarningAndCounts } from './op-delete-item-warning.models';

@Component({
  selector: 'op-delete-item-warning',
  templateUrl: './op-delete-item-warning.component.html',
  styleUrls: ['./op-delete-item-warning.component.scss'],
})
export class OpDeleteItemWarningComponent implements OnInit {

  title: string;
  deleteHint: string;
  showWarning: boolean = true;
  deleteForm: UntypedFormGroup;
  item: IDeleteItemWarningAndCounts;
  destroy$ = new Subject();
  rightFooterButtons = [
    {
      label: 'Delete',
      action: () => {
        this.close(true);
      },
      primary: true,
      disabled: true,
      hidden: false,
    }
  ];

  additionalClasses: string | string[];
  haveCounts: boolean = false;
  auditCount: number = 0;
  journeyCount: number = 0;
  folders: any[];
  firstDomainID: number;
  sourcePageTab: string;
  makeDeleteCountsVisible: boolean = false;
  makeFolderDeleteCountsVisible: boolean = true;
  isManagingFolders = false;
  folderType = 'folders';
  duration = 5000;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: IDeleteItemWarningAndCounts,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.initData();
    this.initForm();
  }

  private initData(): void {
    this.item = {
      ...this.data,
      defaultMessage: this.data.defaultMessage !== undefined
        ? this.data.defaultMessage
        : true
    };

    //Hide the folders count if this dialog is being called from the domains tab.
    this.sourcePageTab = this.item.sourcePageTab;
    this.isManagingFolders = (this.sourcePageTab === 'manage-domains' || this.sourcePageTab === 'manage-folders') ? true : false;
    this.additionalClasses = this.item.additionalClasses;

    this.auditCount = this.item.AuditsCount ? this.item.AuditsCount : 0;
    this.journeyCount = this.item.JourneysCount ? this.item.JourneysCount : 0;

    // Use custom title only if provided
    this.title = this.item.customTitle ?? ((this.isManagingFolders) ? "Confirm Delete" : `Delete ${this.item.itemType}`);

    // Use custom confirm button text if provided
    this.rightFooterButtons[0].label = this.item.customConfirmBtnText ?? this.title;
    this.deleteHint = this.item.customDeleteHint ?? 'delete';
    this.showWarning = this.item.showWarning ?? true;

    // If were loaded from the manage-folders or manage-subfolders pages then add a second button.
    if(this.isManagingFolders) this.initManagingFolders();
  }

  initForm(): void {
    this.deleteForm = this.fb.group({
      understand: [false, Validators.requiredTrue],
      confirmDelete: ['', [Validators.required, OPValidators.confirmDelete(this.deleteHint)]]
    });

    this.deleteForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.rightFooterButtons[0].disabled = this.deleteForm.invalid;
      if(this.isManagingFolders) {
        this.rightFooterButtons[1].disabled = this.rightFooterButtons[0].disabled;
      }
    });

    // If we don't have any cards to delete (only folders) then hide the move button
    if(this.auditCount+this.journeyCount < 1 && this.rightFooterButtons[1]) {
      this.rightFooterButtons[0].hidden = true;
    }
  }

  // If we are loaded from the manage-folders or manage-subfolders (domains) pages then add a second button etc.
  private initManagingFolders(): void {

    this.rightFooterButtons.push({
      label: 'Yes, Delete',
      action: () => {
        this.closeManagingFolders('delete');
      },
      primary: true,
      disabled: true,
      hidden: false,
    });
    
    this.makeFolderDeleteCountsVisible = (this.sourcePageTab === 'manage-folders') ? true : false;
    this.folderType = (this.makeFolderDeleteCountsVisible) ? 'folders' : 'subfolders';
    let buttonLabel = ( this.folderType === 'folders') ? 'Delete & Move Items To New Folder' : 'Delete & Move Items To New Sub-folder';
    this.rightFooterButtons[0].label = this.item.customConfirmBtnText ?? buttonLabel;
    this.rightFooterButtons[0].action = () => {
      this.closeManagingFolders('move');
    }

    // Only show this section if deleting folders, subfolders and labels
    this.haveCounts = (this.item.haveCounts) ?? false;
    if(this.haveCounts) this.makeDeleteCountsVisible = true;

    this.folders = this.item.Folders;
    this.firstDomainID = this.item.firstDomainID;
    this.sourcePageTab = this.item.sourcePageTab;
  }

  close(confirmDelete?: boolean): void {
    this.dialogRef.close(confirmDelete);
  }
  closeManagingFolders(confirmDelete: string): void {

    // There are 3 possible outcomes: 1) delete, 2) move, 3) cancel
    if(confirmDelete === null || confirmDelete === undefined) {
      confirmDelete = 'cancel';
    }
    this.dialogRef.close(confirmDelete);
  }

  get understand(): AbstractControl {
    return this.deleteForm.get('understand');
  }

  get confirmDelete(): AbstractControl {
    return this.deleteForm.get('confirmDelete');
  }
}
