<div class="op-tabs" [class.sub-menu]="isSubMenu">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <div class="op-tab"
         [class.selected]="tabIsActive(tab.path)"
         (click)="changeTab(tab.path)"
         [attr.op-selector]="tab.opSelector">
      {{ tab.name }}
      <div *ngIf="tab.label"
           class="op-tab-label"
           [ngClass]="tab.labelClass">
        {{ tab.label }}
      </div>
    </div>
    <span class="op-tab-divider" *ngIf="i < (tabs.length - 1)">
      <span class="vertical-line"></span>
    </span>
  </ng-container>
</div>
<div class="op-tabs-content flex-col flex-stretch">
  <router-outlet *ngIf="useRouting"></router-outlet>
  <ng-content *ngIf="!useRouting"></ng-content>
</div>
