import { Injectable } from '@angular/core';
import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { StorageService } from '../../shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Observable } from 'rxjs';
import { EConsentCategoriesFilterTypes, IConsentCategoryFilter } from './consent-categories-filter-bar.constants';
import { CCType } from '../consent-categories.constants';
import { map } from 'rxjs/operators';

const FILTERS_STORAGE_KEY = 'consent_categories_filters';

@Injectable()
export class ConsentCategoriesFilterBarService extends OpFilterBarService<EConsentCategoriesFilterTypes> {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService
  ) {
    super(storage, FILTERS_STORAGE_KEY, cacheReset, new Map([ [EConsentCategoriesFilterTypes.Label, true] ]));
    this.updateSupportedFiltersList([
      EConsentCategoriesFilterTypes.Label,
      EConsentCategoriesFilterTypes.Name,
      EConsentCategoriesFilterTypes.Type
    ]);
  }

  get filters$(): Observable<any[]> {
    return this.anyFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters
          .filter((filter: IConsentCategoryFilter) => this.validTypes.includes(filter.type))
          .reduce((acc, curr) => {
            acc.push(curr);
            return acc;
          }, [])
      )
    );
  }

  addNameContainsFilter(contains: string) {
    this.addFilter({
      type: EConsentCategoriesFilterTypes.Name,
      display: `Name contains '${contains}'`,
      value: contains
    });
  }

  addCcTypeFilter(type: CCType): void {
    this.addFilter({
      type: EConsentCategoriesFilterTypes.Type,
      display: `Type is ${type}`,
      value: type
    });
  }

  addCcLabelIdFilter(label: { name: string, id: number }): void {
    this.addFilter({
      type: EConsentCategoriesFilterTypes.Label,
      display: `Label: ${label.name}`,
      value: label.id
    });
  }
}
