<div class="invalid-urls-snackbar">
  <button (click)="close()" class="icon-btn close-btn">
    <mat-icon>close</mat-icon>
  </button>
  <p class="message"><mat-icon class="warning">warning</mat-icon> These starting URLs are invalid</p>
  <div class="items-container">
    <ul>
      <li *ngFor="let url of urls" class="item">
        <div [attr.title]="url.length > 50 ? url : ''">{{ url }}</div>
        <button (click)="copyUrl(url)" title="Copy URL" class="icon-btn copy-url-btn">
          <mat-icon>{{ copyIcon }}</mat-icon>
        </button>
      </li>
    </ul>
  </div>
</div>