import * as d3 from 'd3';
import { Selection } from 'd3';
import { IChartData } from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import { BaseType } from 'd3-selection';
import {
  createTooltip,
  drawHoverUnderlays,
  drawTooltip
} from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';
import { NumberValue } from 'd3-scale';
import {
  IBoxPlotChartChartAxisItems, IBoxPlotChartDashedSegmentColorClass,
  IBoxPlotChartLineSegmentColorClass, IBoxPlotChartSegment, IBoxPlotChartSegmentColorClass
} from '@app/components/shared/components/viz/box-plot-mini-chart/box-plot-mini-chart.models';
import { IWebVitalsFullscreenChartData } from '@app/components/shared/components/viz/web-vitals-fullscreen-chart-modal/components/web-vitals-box-plot-fullscreen-chart/web-vitals-box-plot-fullscreen-chart.component';
import { EDateFormats, formatDate } from '@app/components/date/date.service';
import { IWebVitalsTrendDataDistribution } from '@app/components/audit-reports/reports/page-summary/page-summary.models';

// As we're using area and line generators with scaleBand axis,
// we need to correct the coordinates for the last value
//
// ScaleBand is designed to be used with bar charts, where a data point has width (width of the bar)
// whereas in area and line charts, the data point is just a point with no width
//
// The main difference here is that scaleBand (which is mostly used for bar charts) has a *bandwidth* property,
// which is the width of a region responsible for displaying a data point,
// where bar chart fills that width starting from the beginning of the region,
// and ending at end of the region which is the x(data) + axis.bandwidth(), or 'coordinate
// of the beginning of the region + the width of the region'
// Other charts don't have *bandwidth*, so we need to mimic that behavior
// Whereas for line and area charts, the data point is just a point with no width,
// so on a scaleBand axis, the data point ends at the beginning of the next data region,
// and we want it to have that *bandwidth* to fill the whole region and mimic the bar chart logic
//
// Example:
// assuming we are at x=0, and our data point is x=1, *bandwidth* = 10px
// so what we expect is that our area ends right before the x=2
// (because it should cover all the region for x=1, which ends right before x=2),
// which should be around 20px from 0,
// because we have 10px from x=0 to x=1, and 10px from x=1 to x=2, 20px in total.
// But in our case the area ends right before x=1, because it is not aware that region has width,
// it expects region to be a single point,
// so we need to add that *bandwidth* to the end of the area

export const scaleBandXAxisCorrector = (data: any, i: number, leftPadding = 0, rightPadding = 0) => {
  // Left padding is used to make the first bar start from the beginning of the chart
  // aligned with the bar chart
  if (leftPadding && i === 0) {
    return leftPadding;
  }

  // Right padding is used to make the last item in line chart dataset end earlier
  // specifically for currentPeriod, where we display pastPeriod & futurePeriod
  if (rightPadding && i === data.length - 1) {
    return rightPadding;
  }

  return 0;
};

export const areaGenerator = (
  x: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number>,
  height: number,
  leftPadding = 0,
  rightPadding = 0,
) => (data: any[]) => d3.area()
  .curve(d3.curveStepAfter)
  .x((d: any, i: number) => x(d.dateString) + scaleBandXAxisCorrector(data, i, leftPadding, rightPadding))
  .y0(height)
  .y1((d: any) => y(d.value))
  (data);

export const lineGenerator = (
  x: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number>,
  leftPadding = 0,
  rightPadding = 0,
  curve = d3.curveStepAfter,
) => (data: any[]) => d3.line()
  .curve(curve)
  .x((d: any, i: number) => x(d.dateString) + scaleBandXAxisCorrector(data, i, leftPadding, rightPadding))
  .y((d: any) => y(d.value))
  (data);

export const scaleLinearAxisGenerator = (range: [number, number], domain: Iterable<NumberValue>) => d3
  .scaleLinear()
  .range(range)
  .domain(domain);

export const scaleBandAxisGenerator = (range: [number, number], domain: string[], padding = 0) => d3
  .scaleBand()
  .domain(domain)
  .range(range)
  .padding(padding);

export const attachSvgToContainer = (svgBoxSelectorClassName: string) =>
  d3.select(`.${svgBoxSelectorClassName}`).append('svg');

export const attachAreaChartToSvg = (
  {
    x,
    y,
    height,
    data,
    groupClassName,
    pathClassName,
    leftPadding = 0,
    rightPadding = 0,
    animationDuration = 1000,
  }: AreaChartOptions,
  container: Selection<BaseType, unknown, HTMLElement, any>,
) => {
  const g = container.append('g')
    .attr('class', groupClassName)
    .append('path')
    .attr('class', pathClassName)
    .attr('d', areaGenerator(x, y, height, leftPadding, rightPadding)(data));

  g.attr('style', 'transform: scaleX(2)')
    .transition()
    .duration(animationDuration)
    .attr('style', 'transform: scaleX(1)');
  return g;
};

export type LineChartOptions = {
  x: d3.ScaleBand<string>;
  y: d3.ScaleLinear<number, number>;
  data: any;
  className: string;
  strokeWidth: number;
  leftPadding?: number;
  rightPadding?: number;
  curve?: d3.CurveFactory; // Type for curve functions
  animationType?: 'appear' | 'run';
  animationDuration?: number;
};

export type AreaChartOptions = {
  x: d3.ScaleBand<string>;
  y: d3.ScaleLinear<number, number>;
  height: number,
  data: any;
  groupClassName: string,
  pathClassName: string,
  leftPadding?: number;
  rightPadding?: number;
  animationDuration?: number;
};

export const attachLineChartToSvg = (
  {
    x,
    y,
    data,
    className,
    strokeWidth,
    leftPadding = 0,
    rightPadding = 0,
    curve = d3.curveStepAfter,
    animationType = 'appear',
    animationDuration = 2000,
  }: LineChartOptions,
  container: Selection<BaseType, unknown, HTMLElement, any>,
) => {
  const path = container.append('path')
    .attr('class', className)
    .attr('fill', 'none')
    .attr('stroke-width', strokeWidth)
    .attr('d', lineGenerator(x, y, leftPadding, rightPadding, curve)(data));

  if (animationType === 'appear') {
    path.attr('style', 'transform: scaleY(0)')
      .transition()
      .duration(animationDuration)
      .attr('style', 'transform: scaleY(1)');
  } else if (animationType === 'run') {
    let totalLength = path.node().getTotalLength();

    path.attr('stroke-dasharray', totalLength + ' ' + totalLength)
      .attr('stroke-dashoffset', totalLength)
      .transition() // Start the transition
      .duration(animationDuration) // Duration of the animation
      .attr('stroke-dashoffset', 0);
  }

  return path;
};

export const attachRectToSvg = (
  {
    lineX = 0,
    lineY = 0,
    lineWidth,
    lineHeight,
    groupClassName,
    className,
  },
  container: Selection<BaseType, unknown, HTMLElement, any>,
) =>
  container.append('g')
    .attr('class', groupClassName)
    .attr('transform', `translate(${lineX}, ${lineY})`)
    .append('rect')
    .attr('class', className)
    .attr('width', lineWidth)
    .attr('height', lineHeight);

export const attachConstantLineChartToSvg = (
  {
    x1 = 0,
    x2 = 0,
    y1 = 0,
    y2 = 0,
    groupClassName,
    className,
    animationDuration = 1750,
  },
  container: Selection<BaseType, unknown, HTMLElement, any>,
) =>
  container.append('g')
    .attr('class', groupClassName)
    .append('line')
    .attr('x1', 0)
    .attr('x2', 0)
    .attr('y1', y1)
    .attr('y2', y2)
    .transition() // Start transition
    .duration(animationDuration) // Duration in milliseconds
    .attr('x1', x1)
    .attr('x2', x2)
    .attr('class', className);

export const attachHoverableUnderlaysToSvg = (
  {
    x,
    overlayRectHeight,
    data,
    barClassName = 'bar',
    containerClassName = 'bars',
  },
  container: any,
  windowWidth: number,
  circleClass: string,
  circleRadius: number,
  tooltipClass: string,
  exportHandler: (d) => void,
  exportPeriodMatIcon = 'cloud_download',
  exportPeriodIconSize = 18,
) => {
  // region Creating separate group for the whole bar layer
  const g = container.append('g')
    .attr('class', containerClassName)
    .selectAll(barClassName)
    .data(data.map(d => ({ date: d })))
    .enter()
    .append('g')
    .attr('class', 'overlay-group');
  // endregion Creating separate group for the whole bar layer

  // region Creating overlay rect for hover
  g.append('rect')
    .attr('class', 'overlay-group-rect')
    .attr('width', x.bandwidth())
    .attr('x', d => x(d.date))
    .attr('height', overlayRectHeight);
  // endregion Creating overlay rect for hover

  const tooltip = createTooltip(tooltipClass);

  // region Creating export period container
  g.append('foreignObject')
    .attr('class', 'export-period-container')
    .attr('width', exportPeriodIconSize)
    .attr('x', d => x(d.date) + (x.bandwidth() - exportPeriodIconSize) / 2)
    .attr('y', overlayRectHeight - exportPeriodIconSize)
    .attr('height', exportPeriodIconSize)
    .append('xhtml:div')
    .html('<span class="material-icons export-period-icon">' + exportPeriodMatIcon + '</span>')
    .on('mouseover', (e, d) => {
      drawTooltip(
        container,
        '<div class="usage-chart-tooltip-body">' +
        '<div class="value">' +
        '<div class="value-data">' + 'Export ' + d.date + '</div>' +
        '</div>' +
        '</div>',
        x(d.date) + x.bandwidth() / 2,
        overlayRectHeight - exportPeriodIconSize,
        null,
        tooltip,
        windowWidth,
      );
    })
    .on('mouseleave', () => {
      hide(container.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
    })
    .on('click', (e, d) => {
      if (exportHandler) {
        exportHandler(data.findIndex(item => item === d.date));
      }
    });
  // endregion Creating export period container
  return g;
};

export const attachBarChartToSvg = (
  {
    x,
    y,
    height,
    data,
    gap = 16,
    barLabelTopPadding = 17,
    barLabelBottomPadding = 10,
    labelLocationThreshold = 20,
    barClassName = 'bar',
    barLabelClassName = 'bar-label',
    containerClassName = 'bars',
    fill = '#11A6D4',
    stackedOnData = null,
    barLabelTextFormatterFn = null,
    leftPadding = 0,
    rightPadding = 0,
    animationDuration = 750,
  },
  container: any,
) => {

  // region Creating separate group for the whole bar layer
  const g = container.append('g')
    .attr('class', containerClassName)
    .selectAll(barClassName)
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'overlay-group');
  // endregion Creating separate group for the whole bar layer

  // region Creating separate group for each bar
  const barContainer =
    g.append('g')
      .attr('class', `${containerClassName}-bar-container`)
      .attr('x', d => x(d.dateString))
      .attr('y', d => y(d.value))
      // region Making gap between bars
      .attr('width', x.bandwidth() - gap)
      // endregion Making gap between bars
      .attr('height', d => height - y(d.value))
      .attr('transform', `translate(${gap / 2},0)`);
  // endregion Creating separate group for each bar

  // region Creating bar
  barContainer
    .append('rect')
    .attr('class', barClassName)
    .attr('x', (d, i) => {
      // Left padding is used to move first item to the right
      if (leftPadding && i === 0) {
        return x(d.dateString) + leftPadding;
      }

      return x(d.dateString);
    })
    // region Making gap between bars
    .attr('width', (d, i) => {
      // Decrease the width of first item if the padding was added to keep consistent sizes
      if (leftPadding && i === 0) {
        return x.bandwidth() - leftPadding;
      }

      // Right padding is used to make the last item in line chart dataset end earlier
      if (rightPadding && i === data.length - 1) {
        return x.bandwidth() - rightPadding;
      }
      return x.bandwidth() - gap;
    })
    // endregion Making gap between bars
    .attr('y', y(0)) // Start animation from bottom of the chart
    .attr('height', 0) // Start height is 0
    .transition() // Start transition
    .duration(animationDuration) // Duration in milliseconds
    .attr('y', (d, i) => {
      if (stackedOnData) {
        // Position stacked bar on top of the previous one
        return y(d.value) - (height - y(stackedOnData[i].value));
      }

      return y(d.value);
    }) // End y position
    .attr('height', d => Math.max(height - y(d.value), 0))
    .attr('fill', fill);
  // endregion Creating bar

  // region Creating bar label
  barContainer
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('class', d => `${barLabelClassName} ${barClassName}-label ${y(0) - y(d.value) > labelLocationThreshold ? 'inside' : 'outside'}`)
    .text(d => barLabelTextFormatterFn ? barLabelTextFormatterFn(d.value) : d.value)
    .attr('x', d => x(d.dateString) + (x.bandwidth() - gap) / 2)
    .attr('y', y(0))
    .transition()
    .duration(animationDuration)
    .attr('y', (d, i) => {
      if (stackedOnData) {
        const actualY = y(d.value) - (height - y(stackedOnData[i].value));
        const heightDiff = Math.abs(y(stackedOnData[i].value) - actualY);
        const yDisplacement = heightDiff < 20 ? 15 : 0;
        // If the chart is stacked, add displacement to the label not to interfere with another label
        return actualY + (y(0) - y(d.value) > labelLocationThreshold ? barLabelTopPadding : -barLabelBottomPadding) - yDisplacement;
      }

      // If the label does not fit inside the bar, display it on top of the bar
      return y(d.value) + (y(0) - y(d.value) > labelLocationThreshold ? barLabelTopPadding : -barLabelBottomPadding);
    });
  // endregion Creating bar label

  return g;
};

export const attachCandleChartToSvg = (
    {
      x,
      y,
      height,
      data,
      gap = 16,
      barClassName = 'bar',
      containerClassName = 'bars',
      boundaries,
      whiskerWidth,
      barWidth,
      medianWidth,
      underlayWidth,
      max,
      datapointTooltipSelector,
      tooltipValueLabel,
      bodyContainerClassName,
    },
    container: any,
) => {

  const underlayRectClass: string = 'underlay-rect';

  // region Creating separate section for the whole bar layer
  const g = container.append('g')
      .attr('class', containerClassName)
      .selectAll(barClassName)
      .data(data, drawCandle)
      .enter()
      .append('g')
      .attr('class', 'overlay-group');
  // endregion Creating separate group for the whole bar layer

  // region Creating separate group for each section
      g.append('g')
          .attr('class', `${containerClassName}-bar-container`)
          .attr('x', d => x(d.sequence))
          .attr('y', d => y(d.value))
          // region Making gap between bars
          .attr('width', x.bandwidth() - gap)
          // endregion Making gap between bars
          .attr('height', d => height - y(d.value))
          .attr('transform', `translate(${gap / 2},0)`);

  function drawHoverUnderlays(d: IWebVitalsFullscreenChartData, group: Selection<any, any, any, any>): Selection<any, any, any, any>[] {
    return determineChartSegments(
        d.data.stats.min,
        d.data.stats.max,
        [...Object.values(boundaries).map(Number)],
    ).map(segment =>
        drawRectangle(
          x(d.sequence) + x.bandwidth() / 2 - underlayWidth / 2,
            underlayWidth,
          'underlay-rect ' + getValueDescription(segment.start, [boundaries.warnThreshold, boundaries.failThreshold], ["good", "needsImprovement", "poor"]),
          y(segment.start) - y(segment.end),
          y(segment.end),
          group
        )
    )
  }

  function drawCandle(d: IWebVitalsFullscreenChartData): void {
    const group = container.append('g');

    const globalUnderlay = drawGlobalUnderlay(d, group);

    attachEvent(d, globalUnderlay, 'mouseenter', (e) => showTooltip(e, d));
    attachEvent(d, globalUnderlay, 'mouseout', () => hideTooltip());

    // const underlays = drawHoverUnderlays(d, group);
    // Adding events for next hovers realization
    // underlays.forEach(underlay => {
    //   attachEvent(d, underlay, 'mouseenter', (e) => showTooltip(e, d));
    //   attachEvent(d, underlay, 'mouseout', () => hideTooltip());
    // });
    drawWhiskeredLines(d, group);
    drawWhiskers(d, group);
    drawColoredRectangles(d, group);
    drawMedian(d, group);
  }

  function showTooltip(e: MouseEvent, d: IWebVitalsFullscreenChartData): void {
    const labelCount = Object.values(d.data.distribution).reduce((prev, curr) => curr + prev, 0);

    const underlaySize = (e.target as HTMLElement).getBoundingClientRect();
    const chartSize = (d3.select(`.${bodyContainerClassName}`).node() as HTMLElement)?.getBoundingClientRect();

    const tooltipScreenGap: number = 10;

    const tooltip = d3.select(datapointTooltipSelector)
        .style('display', 'flex')
        .style('visibility', 'visible')
        .style('top', `${chartSize.height / 2}px`)
        .style('left', `${underlaySize.left + underlaySize.width + tooltipScreenGap}px`)
        .style('transform', `translate(0, -50%)`)
        .html(`
          <div class="wrapper">
              <div class="main">
                <p class="main-label">${labelCount} ${tooltipValueLabel}</p>
                ${getTooltipContent(d)}
              </div>
              <div class="separator"></div>
              <div class="item">
                <div class="item-label">
                    max
                </div>
                <div class="item-value">
                  ${d.data.stats['max']}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                    75th %
                </div>
                <div class="item-value">
                  ${d.data.stats['p75']}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                    median
                </div>
                <div class="item-value">
                  ${d.data.stats['median']}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                    average
                </div>
                <div class="item-value">
                  ${d.data.stats['average']}
                </div> 
              </div>
              <div class="item">
                <div class="item-label">
                    25th %
                </div>
                <div class="item-value">
                  ${d.data.stats['p25']}
                </div>
              </div>
              <div class="item">
                <div class="item-label">
                    min
                </div>
                <div class="item-value">
                  ${d.data.stats['min']}
                </div>
              </div>
              <div class="separator"></div>
              <div class="date">
                 ${ formatDate(new Date(d.date), EDateFormats.dateOne) }
              </div>
          </div>
        `);

    const tooltipSize = (tooltip.node() as HTMLElement).getBoundingClientRect();
    const overlapX = chartSize.width - (tooltipSize.left + tooltipSize.width);

    if (overlapX < 20) {
      tooltip
          .style('left', `${underlaySize.left - underlaySize.width - tooltipScreenGap}px`)
          .style('transform', `translate(-50%, -50%)`);
    }
  }

  function getTooltipContent(d: IWebVitalsFullscreenChartData): string {
    let content: string[] = [];

    if (d.data.distribution.poor) {
      content.push(`<div class="main-container poor">
            <div class="main-value">${d.data.distribution.poor}</div>
            <div class="main-description">Poor</div>
          </div>`)
    }

    if (d.data.distribution.needsImprovement) {
      content.push(`<div class="main-container needsImprovement">
            <div class="main-value">${d.data.distribution.needsImprovement}</div>
            <div class="main-description">Needs Improvement</div>
          </div>`)
    }

    if (d.data.distribution.good) {
      content.push(`<div class="main-container good">
            <div class="main-value">${d.data.distribution.good}</div>
            <div class="main-description">Good</div>
          </div>`)
    }

    // Append separator if there is any content
    if (content.length > 0) {
      content = ['<div class="separator"></div>', ...content];
    }

    return content.join('');
  }

  function hideTooltip(): void {
    hide(d3.select(datapointTooltipSelector));
  }

  function drawGlobalUnderlay(d: IWebVitalsFullscreenChartData, group: Selection<any, any, any, any>): Selection<any, any, any, any> {
    // It's a magic number. Could be an any big value, defines an underlay under X axis and upper bottom line of svg
    const bottomPadding = 1000;
    return drawRectangle(
        x(d.sequence) + x.bandwidth() / 2 - underlayWidth / 2,
        underlayWidth,
        underlayRectClass,
        y(0) - y(max) + bottomPadding,
        y(max),
        group
    )
  }

  function drawWhiskers(d: IWebVitalsFullscreenChartData, group: Selection<any, any, any, any>): void {
    const yMin = y(d.data.stats.min);
    const yMax = y(d.data.stats.max);

    const x1 = x(d.sequence) + (x.bandwidth() - whiskerWidth) / 2;
    const x2 = x(d.sequence) + (x.bandwidth() - (x.bandwidth() - whiskerWidth) / 2);

    // Bottom whisker
    drawLine(
        x1,
        x2,
        'no-pointer-events ' + getChartLineItemColorClass(d.data.stats.min),
        yMin,
        yMin,
        group,
    );

    // Top whisker
    drawLine(
        x1,
        x2,
        'no-pointer-events ' + getChartLineItemColorClass(d.data.stats.max),
        yMax,
        yMax,
        group,
    );
  }

  function drawWhiskeredLines(d: IWebVitalsFullscreenChartData, group: Selection<any, any, any, any>): void {
    determineChartSegments(
          d.data.stats.min,
          d.data.stats.max,
          [...Object.values(boundaries).map(Number)]
      )
        .forEach(
            segment => {
              drawLine(
                  x(d.sequence) + x.bandwidth() / 2,
                  x(d.sequence) + x.bandwidth() / 2,
                  'no-pointer-events ' + getChartLineItemColorClass(segment.start),
                  y(segment.start),
                  y(segment.end),
                  group,
              );
            }
        );
  }

  function drawMedian(d: IWebVitalsFullscreenChartData, group: Selection<any, any, any, any>): void {
    const x1 = x(d.sequence) + (x.bandwidth() - medianWidth) / 2;
    const x2 = x(d.sequence) + (x.bandwidth() - (x.bandwidth() - medianWidth) / 2);

    const yValue = y(d.data.stats.median);

    drawLine(
        x1,
        x2,
        'median-line',
        yValue,
        yValue,
        group,
        2,
    );
  }

  function drawColoredRectangles(d: IWebVitalsFullscreenChartData, group: Selection<any, any, any, any>): void {
    determineChartSegments(
        d.data.stats.p25,
        d.data.stats.p75,
        [...Object.values(boundaries).map(Number)]
    ).forEach(segment => {
      drawRectangle(
          x(d.sequence) + x.bandwidth() / 2 - barWidth / 2,
          barWidth,
          'colored-rect ' + getChartColorClass(segment.start),
          y(segment.start) - y(segment.end),
          y(segment.end),
          group,
      );
    });
  }

  function getChartLineItemColorClass(value: number): IBoxPlotChartLineSegmentColorClass {
    return getColorClass(
        value,
        [boundaries.warnThreshold, boundaries.failThreshold],
        ["green-line", "yellow-line", "red-line"]
    );
  }

  function getChartColorClass(value: number): IBoxPlotChartSegmentColorClass {
    return getColorClass(
        value,
        [boundaries.warnThreshold, boundaries.failThreshold],
        ["green", "yellow", "red"]
    );
  }

  return g;
};

function attachEvent(data: IWebVitalsFullscreenChartData, node: Selection<any, any, any, any>, event: string, callback: (e: any, d: any) => void): void {
  node.on(event, (e) => { callback(e, data);});
}

export function getColorClass<T>(value: number, boundaries: number[], colors: T[]): T {
  if (value < boundaries[0]) {
    return colors[0];
  } else if (value >= boundaries[0] && value < boundaries[1]) {
    return colors[1];
  } else {
    return colors[2];
  }
}

export function getValueDescription<T>(value: number, boundaries: number[], labels: T[]): T {
  if (value < boundaries[0]) {
    return labels[0];
  } else if (value >= boundaries[0] && value < boundaries[1]) {
    return labels[1];
  } else {
    return labels[2];
  }
}

export function drawRectangle(
    x: number,
    width: number,
    colorClass: IBoxPlotChartSegmentColorClass | IBoxPlotChartDashedSegmentColorClass | string = 'green',
    rectHeight: number = 0,
    y: number = 0,
    container: Selection<any, any, any, any>,
): Selection<any, any, any, any> {
  return container.append('rect')
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', rectHeight)
      .attr('class', colorClass);
}

export function drawLine(
    x1: number = 0,
    x2: number = 0,
    colorClass: string,
    y1: number = 0,
    y2: number = 0,
    container: Selection<any, any, any, any>,
    strokeWidth: number | undefined = undefined,
): Selection<any, any, any, any> {
  return container.append('line')
      .attr('class', colorClass)
      .style('stroke-width', strokeWidth)
      .attr('x1', x1)
      .attr('y1', y1)
      .attr('x2', x2)
      .attr('y2', y2);
}

export const getItemIndexByCursorPosition = (scale, value): number => {
  let eachBand = scale.step();
  let index = Math.floor(value / eachBand);
  return index < 0 ? 0 : index;
};

export const invertScale = (scale, value): number => scale.domain()[getItemIndexByCursorPosition(scale, value)];

export const nearestDataPointCoordinates = (mouseEvent, containerNode, data, x, y): {
  x: number,
  y: number,
  dataPoint: IChartData,
  dataPointIndex: number,
} => {
  const [xCoord] = d3.pointer(mouseEvent, containerNode);
  const x0 = data.find(d => d.dateString === invertScale(x, xCoord)).date;
  const i = data.findIndex(d => d.dateString === invertScale(x, xCoord));
  const d0 = data[i > 0 ? i - 1 : 0];
  const d1 = data[i];
  const d = 2 * new Date(x0).getTime() - new Date(d0?.date).getTime() > new Date(d1?.date).getTime() ? d1 : d0;
  const xPos = x(d.dateString) + x.bandwidth() / 2;
  const yPos = y(d.value);

  return { x: xPos, y: yPos, dataPoint: d, dataPointIndex: i };
};

export const positionTooltip = (
  xPos: number,
  yPos: number,
  windowWidth: number,
  tooltipWidth: number,
  tooltip: any,
  verticalDistanceFromCircle = 5,
  edgePadding = 5,
): void => {

  // left edge of window
  if (xPos === 0) {
    tooltip.style('left', `${edgePadding}px`);
    // right edge of window
  } else if ((xPos + (tooltipWidth / 2)) >= windowWidth - edgePadding) {
    tooltip.style('left', `${windowWidth - (tooltipWidth / 2)}px`);
    // other cases
  } else {
    tooltip.style('left', `${xPos}px`);
  }

  tooltip
    .style('top', `${yPos}px`)
    .style('transform', `translate(-50%, calc(-100% - ${verticalDistanceFromCircle}px))`);
};

export const positionCircle = (cx: number, cy: number, circle: any): void => circle.attr('cx', cx).attr('cy', cy);

export const display = (node: any, type = 'block'): void => node.style('display', type);

export const hide = (node: any): void => node?.style('display', 'none');

export const setTooltipHTML = (tooltip: any, html: string): void => tooltip.html(html);

export function determineChartSegments(
    start: number,
    end: number,
    boundaries: number[] = [],
): IBoxPlotChartSegment[] {
  // Check which sector the start and end points fall into
  const startSector = boundaries.findIndex((boundary, i) => start >= boundary && start < boundaries[i + 1]);
  const endSector = boundaries.findIndex((boundary, i) => end >= boundary && (!!boundaries[i + 1] ? end < boundaries[i + 1] : true));

  // Array to store the segments to draw
  const segments = [];

  // If the start and end are in the same sector
  if (startSector === endSector) {
    segments.push({
      start,
      end,
    });
  } else {
    // If the start and end span multiple sectors
    // First segment
    segments.push({
      start,
      end: boundaries[startSector + 1],
    });
    // Middle segments
    for (let i = startSector + 1; i < endSector; i++) {
      segments.push({
        start: boundaries[i],
        end: boundaries[i + 1],
      });
    }
    // Last segment
    segments.push({
      start: boundaries[endSector],
      end,
    });
  }

  return segments;
}
