import { AngularNames, ApiPrefix, Names } from '@app/moonbeamConstants';
import { IUser } from '../../../moonbeamModels';
import * as angular from 'angular';
import { IApiService } from '../../api/apiService';
import { environment } from '@app/environments/environment';


export interface IUpdateApiKey {
  id: number;
  description: string;
}

export interface IApiKeyResponse {
  key?: IApiKey;
}

export interface IApiKey {
  id: number;
  userId: number;
  createdBy: number;
  accessToken: string;
  description: string;
  createdAt: string;
  revoked: boolean;
}

export interface IApiKeyService {
  getCurrentUserKey(): angular.IPromise<IApiKeyResponse>;
  getUserKey(user: IUser): angular.IPromise<IApiKeyResponse>;
  getUserKeyById(userId: number): angular.IPromise<IApiKeyResponse>;

  createCurrentUserKey(): angular.IPromise<IApiKey>;
  createUserKey(user: IUser): angular.IPromise<IApiKey>;
  createUserKeyById(userId: number): angular.IPromise<IApiKey>;

  updateCurrentUserKey(apiKey: IUpdateApiKey): angular.IPromise<IApiKey>;
  updateUserKey(apiKey: IUpdateApiKey, user: IUser): angular.IPromise<IApiKey>;
  updateUserKeyById(apiKey: IApiKey, userId: number): angular.IPromise<IApiKey>;
}

export class ApiKeyService implements IApiKeyService {

  private usersUrl: string = environment.apiUrl + ApiPrefix.users;
  private apiKeysUrl: string = this.usersUrl + '/' + ApiPrefix.apiKey;

  static $inject = [
    AngularNames.http,
    Names.Services.api
  ];

  constructor(private $http: angular.IHttpService,
    private apiService: IApiService) {
  }

  getCurrentUserKey(): angular.IPromise<IApiKeyResponse> {
    return this.apiService.handleResponse(this.$http.get(this.apiKeysUrl));
  }

  getUserKey(user: IUser): angular.IPromise<IApiKeyResponse> {
    return this.apiService.handleResponse(this.$http.get(this.formatApiKeysURL(user.id)));
  }

  getUserKeyById(userId: number): angular.IPromise<IApiKeyResponse> {
    return this.apiService.handleResponse(this.$http.get(this.formatApiKeysURL(userId)));
  }

  createCurrentUserKey(): angular.IPromise<IApiKey> {
    return this.apiService.handleResponse(this.$http.post(this.apiKeysUrl, {}));
  }

  createUserKey(user: IUser): angular.IPromise<IApiKey> {
    return this.apiService.handleResponse(this.$http.post(this.formatApiKeysURL(user.id), null));
  }

  createUserKeyById(userId: number): angular.IPromise<IApiKey> {
    return this.apiService.handleResponse(this.$http.post(this.formatApiKeysURL(userId), null));
  }

  updateCurrentUserKey(apiKey: IUpdateApiKey): angular.IPromise<IApiKey> {
    return this.apiService.handleResponse(this.$http.put(this.apiKeysUrl, apiKey));
  }

  updateUserKey(apiKey: IApiKey, user: IUser): angular.IPromise<IApiKey> {
    return this.apiService.handleResponse(this.$http.put(this.formatApiKeysURL(user.id), apiKey));
  }

  updateUserKeyById(apiKey: IApiKey, userId: number): angular.IPromise<IApiKey> {
    return this.apiService.handleResponse(this.$http.put(this.formatApiKeysURL(userId), apiKey));
  }

  private formatApiKeysURL(userId: number): string {
    return this.usersUrl + '/' + userId + '/' + ApiPrefix.apiKey;
  }
}

