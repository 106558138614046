import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const SharedLinksLibraryNames = {
  base: 'shared_links'
};

export const SharedLinksFilterStorageKey = 'shared_links_filters';

export const SharedLinksLibraryPaths = {
  base: 'account/shared-links'
};

export const SharedLinksLibraryBuilders = {
  base: () => RouteBuilder.buildUrl([SharedLinksLibraryPaths.base])
};

export enum ESharedLinksFilterTypes {
  DatasourceName = 'itemName',
  ReportName = 'reportName',
  FolderName = 'folderName',
  NonExpiredOnly = 'isExpired',
  Disabled = 'isDisabled',
}

export enum ESharedLibraryColumns {
  DatasourceName = 'ITEM_NAME',
  ReportName =  'REPORT_NAME',
  FolderName = 'FOLDER_NAME',
  CreatedBy = 'CREATED_BY',
  SharedTo = 'sharedTo',
  Views = 'VIEW_COUNT',
  Expiration = 'EXPIRES',
  Status = 'STATUS',
  ShareLink = 'shareLink'
}

export interface ISharedLinksFilter {
  type: ESharedLinksFilterTypes;
  display: string;
  value: number | string | object | boolean;
}

