import {Names} from '@app/moonbeamConstants';
import * as angular from 'angular';
import {WebsocketConnectionService} from './websocketConnectionService';
import {WebsocketProviderService} from './websocketProviderService';


export default angular.module('websocketsModule', [])
  .service(Names.Services.websocketConnection, WebsocketConnectionService)
  .service(Names.Services.websocketProvider, WebsocketProviderService)
  .name;
