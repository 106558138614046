import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const ExportCenterStateNames = {
  base: 'export_center'
};

export const ExportCenterPaths = {
  base: 'export-center'
};

export const ExportCenterUrlBuilders = {
  base: () => RouteBuilder.buildUrl([ExportCenterPaths.base])
};
