import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'op-email-inbox-quick-setup-success-snackbar',
  templateUrl: './email-inbox-quick-setup-success-snackbar.component.html',
  styleUrls: ['./email-inbox-quick-setup-success-snackbar.component.scss']
})
export class EmailInboxQuickSetupSuccessSnackbarComponent {

  constructor(
      @Inject(MAT_SNACK_BAR_DATA) public data,
      private snackBarRef: MatSnackBarRef<EmailInboxQuickSetupSuccessSnackbarComponent>,
      private clipboard: Clipboard,
  ){
    this.data = {
      name: 'audit_name',
      emailAddress: 'emailtest+ut2zmjsc-rere1@email.observepointstaging.com',
      folder_name: 'folder_name',
      user_email_address: 'user_email_address'
    };
  }

  copyToClipboard(text: string): void {
    this.clipboard.copy(text);
  }

  close(): void {
    this.snackBarRef.dismiss();
  }
}
