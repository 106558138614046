export enum ETriggeredAlertsFilterTypes {
  Name = 'name',
  Label = 'label',
  DataSourceLabel = 'dataSourceLabel',
  DataSourceName = 'dataSourceName',
  Subscribed = 'subscribed',
  Folder = 'folder',
  RunDateRange = 'runDateRange',
  ReportMetric = 'reportMetric',
}

export enum ETriggeredAlertsFilterBarMenuStrings {
  AlertsLabels = 'Alerts Labels',
  DataSources = 'Data Sources',
  FolderAndSubFolder = 'Folder & Sub-folder',
  DataSourceLabels = 'Labels',
  DataSourceName = 'Search by Name',
  OnlyShowMonitored = 'Subscribed Alert Only',
  ShowAllMonitored = 'Show all Subscribed & Unsubscribed',
  RunDateRange = 'Run Date Range',
  ReportMetric = 'Report Metric',
}

export enum EDataSourceTypeVals {
  audit,
  webJourney
}

export enum EDataSourceScheduleVals {
  ScheduledToRun,
  CurrentlyRunning,
  NotScheduled
}

export enum EJourneyRunStatusVals {
  Successful,
  ActionFailure,
  RuleFailure,
  InQueueRunning
}
