import { IAuditReportFilter } from '../../audit-report-filter-bar/audit-report-filter-bar.models';
import {
  chartTypeToFilterTypeMap,
  EPageLoadTimePills,
  EPageSummaryChartTypes,
  EPageSummaryTrendNames
} from './page-summary.constants';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  IHorizontalBarChartDataPoint
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.models';
import {
  IPageSummaryLoadTimeChart,
  IPageSummaryStatusCodeChart,
  IStatusCodeHorizontalBarChartDataPoint,
  IWebVitalsTrendData
} from './page-summary.models';
import { EChartColor, EStatusCodeCategories } from '../../audit-report/audit-report.constants';
import { EPageLoadTimeCategory } from './page-summary.enums';
import { convertTimeWithPrecision } from '@app/components/utilities/number.utils';
import {
  IFullscreenChartData,
  IFullscreenChartStatsItem
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { EPageInfoTrendNames } from '@app/components/audit-reports/page-information/page-information.constants';
import {
  statsToChartStatsItems
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.utils';
import { DecimalPipe } from '@angular/common';

export const determineChartFilterState = (chart: EPageSummaryChartTypes, currentFilters: IAuditReportFilter[]): EFilterSpinnerState => {
  const filterType = chartTypeToFilterTypeMap.get(chart);
  const isFiltered = !!currentFilters.find(filter => filter.type === filterType);

  if (isFiltered && currentFilters.length === 0) {
    return EFilterSpinnerState.None;
  } else if (isFiltered && currentFilters.length > 1) {
    return EFilterSpinnerState.Filtered;
  } else if (!isFiltered && currentFilters.length > 0) {
    return EFilterSpinnerState.Filtered;
  } else {
    return EFilterSpinnerState.None;
  }
};

export const getPageLoadTimeChartData = (data: IPageSummaryLoadTimeChart = undefined): IHorizontalBarChartDataPoint[] => {
  return [
    {
      value: data ? data[EPageLoadTimeCategory.BELOW3] : 0,
      colorClass: EChartColor.Green,
      name: EPageLoadTimePills['Below3'],
      filtered: false
    },
    {
      value: data ? data[EPageLoadTimeCategory.BETWEEN3AND6] : 0,
      colorClass: EChartColor.Yellow,
      name: EPageLoadTimePills['3to6'],
      filtered: false
    },
    {
      value: data ? data[EPageLoadTimeCategory.BETWEEN6AND10] : 0,
      colorClass: EChartColor.Orange,
      name: EPageLoadTimePills['6to10'],
      filtered: false
    },
    {
      value: data ? data[EPageLoadTimeCategory.ABOVE10] : 0,
      colorClass: EChartColor.Red,
      name: EPageLoadTimePills['10andAbove'],
      filtered: false
    }
  ];
};

export const getPageStatusCodeChartData = (data: IPageSummaryStatusCodeChart = undefined): IStatusCodeHorizontalBarChartDataPoint[] => {
  return [
    {
      value: data ? data.good : 0,
      colorClass: EChartColor.Green,
      name: 'Successes',
      category: EStatusCodeCategories.Successes,
      filtered: false,
    },
    {
      value: data ? data.redirects : 0,
      colorClass: EChartColor.Yellow,
      name: 'Redirects',
      category: EStatusCodeCategories.Redirects,
      filtered: false,
    },
    {
      value: data ? data.broken : 0,
      colorClass: EChartColor.Red,
      name: 'Broken',
      category: EStatusCodeCategories.Broken,
      filtered: false,
    }
  ];
};

export function getWebVitalsColorClass(
  time: number,
  greenThreshold = 2.5,
  yellowThreshold = 4,
): string {
  if (isNaN(time) || time === null) {
    return '';
  }

  if (time < greenThreshold) {
    return 'load-time-green';
  } else if (time < yellowThreshold) {
    return 'load-time-yellow';
  } else {
    return 'load-time-red';
  }
}

export function convertStats(
  stats: IWebVitalsTrendData,
  divider = 1,
  decimalsCount = 1,
): IWebVitalsTrendData {
  return {
    average: convertTimeWithPrecision(stats.average, divider, decimalsCount),
    max: convertTimeWithPrecision(stats.max, divider, decimalsCount),
    min: convertTimeWithPrecision(stats.min, divider, decimalsCount),
    median: convertTimeWithPrecision(stats.median, divider, decimalsCount),
    p25: convertTimeWithPrecision(stats.p25, divider, decimalsCount),
    p75: convertTimeWithPrecision(stats.p75, divider, decimalsCount),
  };
}

export function getWebVitalsStats(data: IFullscreenChartData[], trendName: EPageInfoTrendNames | EPageSummaryTrendNames, decimalsCount = 3): IFullscreenChartStatsItem[] {
  const sortedItems = data.map(run => run.value).sort((a, b) => a - b);
  const min = sortedItems[0].toFixed(decimalsCount);
  const max = sortedItems[sortedItems.length - 1].toFixed(decimalsCount);
  const average = (sortedItems.reduce((sum, v) => sum + v, 0) / sortedItems.length).toFixed(decimalsCount);

  const halfPosition = sortedItems.length % 2 === 0
    ? sortedItems.length / 2
    : (sortedItems.length + 1) / 2;

  const median = halfPosition % 1 === 0
    ? ((sortedItems[halfPosition] + sortedItems[halfPosition - 1]) / 2).toFixed(decimalsCount)
    : sortedItems[halfPosition].toFixed(decimalsCount);

  return statsToChartStatsItems({ min, max, average, median });
}

export function formatWidgetTime(ms: number, digitsInfo = '1.0-3'): string {
  return `${new DecimalPipe('en-US').transform(ms / 1000, digitsInfo)} sec`;
}

export function formatWidgetTimeToMS(ms: number, digitsInfo = '1.0-3'): string {
  return `${new DecimalPipe('en-US').transform(ms, digitsInfo)} ms`;
}

export function getWebVitalsDivider(trendName: EPageInfoTrendNames | EPageSummaryTrendNames): number {
  let divider = 1;

  switch (trendName) {
    case EPageInfoTrendNames.CUMULATIVE_LAYOUT_SHIFT:
    case EPageInfoTrendNames.TIME_TO_FIRST_BYTE:
      divider = 1;
      break;
    default:
      divider = 1000;
  }

  return divider;
}

export function getWebVitalsDecimalsCount(trendName: EPageInfoTrendNames | EPageSummaryTrendNames): number {
  let decimalsCount = 1;
  switch (trendName) {
    case EPageInfoTrendNames.TIME_TO_FIRST_BYTE:
      decimalsCount = 0;
      break;
    default:
      decimalsCount = 3;
  }

  return decimalsCount;
}
