<form formName="selectorsForm" [formGroup]="formGroup" [submitted]="submitted" validate-form>
  <div class="selector-row">
    <mat-form-field floatLabel="always" class="selector-type" appearance="outline">
      <mat-label>Selector type</mat-label>
      <mat-select #selectorType formControlName="selectorType">
        <mat-option *ngFor="let type of elemSelectorTypes" [value]="type.id">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="selector-value">
      <mat-form-field floatLabel="always" appearance="outline">
        <mat-label>Selector Value</mat-label>
        <input matInput formControlName="value" />
        <button matSuffix mat-icon-button
                (click)="removeSelector.emit()"
                *ngIf="selectorsCount > 1">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </mat-form-field>
      <op-error-message formControlPath="selectorsForm.value"></op-error-message>
    </div>
  </div>
</form>
