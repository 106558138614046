<op-modal (onClose)="close()"
          [rightFooterButtons]="rightFooterButtons">
  <ng-template #headerTemplate>
    <op-modal-header [title]="data?.title"
                     (onClose)="close()"></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="content">
      <p [innerHTML]="data?.helpText"
         class="help-text"></p>

      <op-recipients-form-control [formControl]="emails"
                               floatLabel="always"
      ></op-recipients-form-control>
    </div>
  </ng-template>
</op-modal>
