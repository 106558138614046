import { Injectable } from '@angular/core';
import { IParsedOuterHTML, IAttribute } from './multi-selectors.models';

@Injectable()
export class MultiSelectorsService {

  parseAttributes(outerHTML: string): IParsedOuterHTML {
    if (typeof outerHTML !== 'string') return outerHTML;
    let matches = outerHTML.match(/^<([^\s>]+)(\s|>)+/);
    return (matches && matches[1]) ?
      this.parseAttributesWithTag(outerHTML, matches) :
      this.parseAttributesWithoutTag(outerHTML);
  }

  private parseAttributesWithTag(outerHTML: string, matches: string[]): IParsedOuterHTML {
    let parsed: IParsedOuterHTML = {
      attributes: [],
      tagName: matches[1]
    };

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(outerHTML, 'text/html');
    const element = htmlDoc.querySelector(parsed.tagName);
    if (!element) return parsed;

    Array.from(element.attributes).forEach(attribute => {
      parsed.attributes.push(<IAttribute>{ name: attribute.name, value: attribute.value });
    });

    parsed.innerHTML = element.innerHTML;
    return parsed;
  }

  private parseAttributesWithoutTag(outerHTML: string): IParsedOuterHTML {
    let parsed: IParsedOuterHTML = {
      attributes: []
    };
    const attributeParts = outerHTML.split(/\s/);
    for (const attributePart of attributeParts) {
      const matches = attributePart.match(/(.*)=\"(.*)\"/);
      if (matches) {
        parsed.attributes.push(<IAttribute>{ name: matches[1], value: matches[2] });
      }
    }
    return parsed;
  }

  buildOuterHTML(parsedOuterHTML: IParsedOuterHTML): string {
    return parsedOuterHTML.tagName ?
      this.buildOuterHTMLWithTag(parsedOuterHTML) :
      this.buildOuterHTMLWithoutTag(parsedOuterHTML);
  }

  private buildOuterHTMLWithTag(parsedOuterHTML: IParsedOuterHTML): string {
    let element = document.createElement(parsedOuterHTML.tagName);
    if (parsedOuterHTML.attributes) {
      parsedOuterHTML.attributes.forEach(attribute => {
        try {
          element.setAttribute(attribute.name, attribute.value);
        } catch (e) {
          console.warn(`Error setting attribute ${attribute.name} with value ${attribute.value}. This attribute will be ignored.`);
        }
      });
    }
    element.innerHTML = parsedOuterHTML.innerHTML ? parsedOuterHTML.innerHTML : '';
    return element.outerHTML;
  }

  private buildOuterHTMLWithoutTag(parsedOuterHTML: IParsedOuterHTML): string {
    return parsedOuterHTML.attributes.reduce(
      (acc: string, attr: IAttribute) => acc.concat(attr.name, '="', attr.value, '" '),
      ''
    ).trim();
  }

}
