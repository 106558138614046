import { DateRange } from '@angular/material/datepicker';

export enum EOpFilterBarItemDatapickerRanges {
  CustomDateRange,
  Last30Days,
  Last60Days,
  Last90Days,
  AllAvailableDays
}

export interface IOpFilterBarItemDatepickerRangeState {
  type: EOpFilterBarItemDatapickerRanges;
  dates: DateRange<Date>;
}
