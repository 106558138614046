import { forkJoin, Observable, of, ReplaySubject, Subject, throwError as observableThrowError } from 'rxjs';
import * as ngRedux from 'ng-redux';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { tap, finalize, catchError, concatMap, delayWhen, map } from 'rxjs/operators';
import { environment } from '@app/environments/environment';
import { Account, ICredentials, ITokenInformation, IUser } from '@app/moonbeamModels';
import { AuthApiAccountInfo, AuthApiAccountStrategy, AuthInfoInCookies, IAccountPreview, IAuthenticationEvent } from './authentication.models';
import { AngularNames, AuthenticationEvents, EAuthenticationEvent } from '@app/moonbeamConstants';
import { WindowRef } from './window.service';
import { AuthenticationActions } from '@app/store/actions/authenticationActions';
import { ApiService } from '@app/components/core/services/api.service';
import { AuthenticationStorageService, IAuthorizationData } from './authentication-storage.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import { CacheResetService, ECacheResetEvent } from './cache-reset.service';
import { EAccountType } from './authentication.enums';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import * as Sentry from '@sentry/angular';

const cacheResetNeeded: Subject<any> = new Subject();

interface ISetCookieOptions {
  noClobber?: boolean;
  expiresAt?: number;
}

@Injectable()
/**
 * The AuthenticationService is responsible for handling user authentication and authorization
 * within the application. It provides methods for logging in, logging out, and managing
 * user and account information.
 *
 * The service interacts with various APIs to retrieve and update user and account data,
 * and it manages the storage of authentication-related information in cookies and local
 * storage.
 *
 * The service also provides methods for checking user permissions and features, and for
 * handling password reset and forgot password functionality.
 */
export class AuthenticationService {

  root: string = environment.apiUrl;
  rootV3: string = environment.apiV3Url;
  authRoot: string = `${environment.authApiProtocol}://${environment.authApiHost}`;

  private apiLogin = this.root + 'login';
  private apiUsers = this.root + 'users';
  private apiAccount = this.root + 'account';
  private apiAccountV3 = this.rootV3 + 'accounts/current';
  private apiFeatures = this.root + 'users/current/features';
  private apiAuthAccounts = '/accounts';
  static readonly authAccessKeyCookie = 'accessKey';
  static readonly authUserIdCookie = 'uIdentifier';
  static readonly authAccountIdCookie = 'aIdentifier';
  static readonly checkUsageCookie = 'checkUsage';
  static readonly analyticsUserIdCookie = 'user_id';
  static readonly analyticsUserLevel = 'user_level';
  static readonly analyticsAccountIdCookie = 'account_id';
  static readonly analyticsAccountTypeCookie = 'account_type';

  private domain = {
    localhost: 'localhost',
    observepoint: '.' + environment.domain
  };

  private account: Account = null;
  private getAccountPending: Observable<Account>;

  private user: IUser = null;
  private previousUserId: number = -1;

  private userFeatures: Array<string>;

  setSecureFlag: boolean = environment.production;

  private userInfoUpdated = new Subject<void>();

  public responseCache = new Map();

  private authenticationEventsSubject = new ReplaySubject<IAuthenticationEvent>(1);
  authenticationEvents$ = this.authenticationEventsSubject.asObservable();

  constructor(@Inject(AngularNames.rootScope) private rootScope: angular.IRootScopeService,
    @Inject(AngularNames.ngRedux) private ngRedux: ngRedux.INgRedux,
    private windowRef: WindowRef,
    private cookieService: CookieService,
    private authenticationStorageService: AuthenticationStorageService,
    private storageService: StorageService,
    private apiService: ApiService,
    private http: HttpClient,
    private cacheResetService: CacheResetService) {

    this.cacheResetService.reset$
      .subscribe(_ => {
        this.account = this.getAccountPending = null;
        this.userFeatures = null;
        this.responseCache.clear();
        cacheResetNeeded.next();
      });
  }

  login(credentials: ICredentials): Observable<ITokenInformation> {
    this.authenticationStorageService.clear();
    this.storageService.resetDefaults();

    return this.http.post(`${environment.authApiProtocol}://${environment.authApiHost}/login`, credentials)
      .pipe(
        delayWhen((token: ITokenInformation) => {
          // if empty accessToken - account is configured for SAML Authentication, stop processing so user can be redirected to SSO Flow
          if (!token.accessToken) return of(token);

          const tokenExpiration = Date.now() + token.expires * 1000;

          this.setCookie(AuthenticationService.authAccessKeyCookie, token.accessToken);

          const inStateAuthorizationId = this.authenticationStorageService.getId();
          const authorizationData = {
            token: token.accessToken,
            id: token.userId,
            credentialsExpired: token.credentialsExpired,
            tokenExpired: token.credentialsExpired,
            expiresAt: tokenExpiration
          };
          if (authorizationData.id !== inStateAuthorizationId) this.storageService.clearSessionStorage();
          this.authenticationStorageService.set(authorizationData);
          this.authenticationStorageService.setId(token.userId);

          if (authorizationData.credentialsExpired) {
            this.rootScope.$broadcast(AuthenticationEvents.credentialsExpired);
            return observableThrowError('');
          }

          return forkJoin([this.apiService.get(`${this.apiUsers}/${token.userId}`), this.getAccountPreview()])
            .pipe(
              tap(([user, account]: [IUser, IAccountPreview]) => {
                this.user = user;
                this.setCookie(AuthenticationService.authUserIdCookie, user.id, { expiresAt: tokenExpiration });
                this.setCookie(AuthenticationService.authAccountIdCookie, user.accountId, { expiresAt: tokenExpiration });
                this.setAnalyticsCookies(user, user.accountId, account.accountType);
                this.rootScope.$broadcast(AuthenticationEvents.loginSuccess, this.user);
                this.cacheResetService.raiseEvent(ECacheResetEvent.login);
              }),
              catchError(
                error => {
                  return observableThrowError(typeof error === 'object' ? error.message : error)
                }
              )
            );
        }),
        catchError(error => {
          return observableThrowError({
            message: error
          })
        })
      );
  }

  logout(skipNav: boolean = false): void {
    if (this.user != null) {
      this.previousUserId = this.user.id;
    }

    this.invalidateSession()
      .pipe(
        finalize(() => {
          this.ngRedux.dispatch(AuthenticationActions.userLogout(null));
          this.removeAuthInfoCookies();
          this.authenticationStorageService.clear();
          this.user = null;

          if (!skipNav) {
            this.rootScope.$broadcast(AuthenticationEvents.logoutSuccess);
          }
        })
      ).subscribe();
  }

  goToLoginPage(): void {
    this.ngRedux.dispatch(AuthenticationActions.userLogout(null));
    this.removeAuthInfoCookies();
    this.authenticationStorageService.clear();
    this.user = null;
    this.authenticationEventsSubject.next({ type: EAuthenticationEvent.goToLogin });
  }

  invalidateSession(): Observable<any> {
    const authorizationData = this.authenticationStorageService.get();
    const headers = authorizationData ? { Authorization: `Bearer ${authorizationData.token}` } : {};

    return this.apiService.post(`${this.authRoot}/logout`, {}, { headers });
  }

  forgotPassword(username: string): Observable<any> {
    return this.apiService.post(`${this.authRoot}/forgot-password`, { username });
  }

  resetPassword(newPassword: string, token: string): Observable<any> {
    return this.apiService.post(`${this.authRoot}/reset-password`, { token, newPassword });
  }

  getAccount(): Observable<Account> {
    return this.apiService.get(this.apiAccount);
  }

  @CacheApiResponse({ resetCache: cacheResetNeeded })
  getAccountPreview(): Observable<IAccountPreview> {
    return this.apiService.get(this.apiAccountV3);
  }

  getAccountWithCache(): Observable<Account> {
    if (this.account) return of(this.account);
    if (this.getAccountPending) return this.getAccountPending;

    this.getAccountPending = this.getAccount().pipe(
      tap<Account>(account => this.account = account),
      finalize(() => this.getAccountPending = null)
    );
    return this.getAccountPending;
  }

  isAuthenticated(): boolean {
    this.checkIfNewBrowserSession();
    const authenticationData = this.authenticationStorageService.get();
    return !!authenticationData && !!authenticationData?.token;
  }

  getFeatures(): Observable<Array<string>> {
    return this.apiService.get(this.apiFeatures).pipe(
      tap<Array<string>>(features => this.userFeatures = features)
    );
  }

  getFeaturesWithCache(): Observable<Array<string>> {
    return this.userFeatures ? of(this.userFeatures) : this.getFeatures();
  }

  isFeatureAllowed(feature: string): Observable<boolean> {
    return this.getFeaturesWithCache().pipe(
      map(features => features.includes(feature))
    );
  }

  isUserCreedsExpired(): boolean {
    return this.authenticationStorageService.get().credentialsExpired;
  }

  setUserCreedsExpired(expired: boolean): void {
    const data = this.authenticationStorageService.get();
    if (typeof data.credentialsExpired !== 'undefined') {
      data.credentialsExpired = expired;
      this.authenticationStorageService.set(data);
    }
  }

  isUserTokenExpired() {
    return !this.cookieService.check(AuthenticationService.authAccessKeyCookie);
  }

  setUserTokenExpired() {
    let data = this.authenticationStorageService.get();
    if (data === null) {
      data = { token: null, id: null, expiresAt: Date.now() };
    }
    data.tokenExpired = true;
    this.authenticationStorageService.set(data);
    this.removeAuthInfoCookies(); // cookie value invalid too
  }

  getPreviousUserId(): number {
    return this.previousUserId;
  }

  private getDomain() {
    const host = this.windowRef.nativeWindow.location.hostname;
    return host === this.domain.localhost ? this.domain.localhost : this.domain.observepoint;
  }

  // USE-CASE: If the user is logged in as another user in one browser tab and they open another tab and load the OP app, return the logged-in user to the original user
  checkIfNewBrowserSession(): void {
    const hasBrowserSession = !!this.authenticationStorageService.getAuthImpersonate();
    if (!this.storageService.isLoggedInAsAnother() && !hasBrowserSession) {
      this.returnToOriginalAuth();
    }
  }

  public returnToOriginalAuth(): void {
    const original = this.authenticationStorageService.getAuth();
    if (!original) return;
    this.authenticationStorageService.returnToOriginalAuth();
    this.setAuthInfoCookies(original);
  }

  setAuthInfoCookies(authorizationData: IAuthorizationData) {
    if (!this.storageService.isLoggedInAsAnother()) {
      this.setCookie(AuthenticationService.authAccessKeyCookie, authorizationData.token);
      this.setCookie(AuthenticationService.authUserIdCookie, authorizationData.id, { expiresAt: authorizationData.expiresAt });
    }
  }

  removeAuthInfoCookies(): void {
    const domain = this.getDomain();
    this.cookieService.delete(AuthenticationService.analyticsUserIdCookie, '/', domain);
    this.cookieService.delete(AuthenticationService.analyticsAccountIdCookie, '/', domain);
    this.cookieService.delete(AuthenticationService.analyticsAccountTypeCookie, '/', domain);
    this.cookieService.delete(AuthenticationService.analyticsUserLevel, '/', domain);
    this.cookieService.delete(AuthenticationService.authAccessKeyCookie, '/', domain);
    this.cookieService.delete(AuthenticationService.authUserIdCookie, '/', domain);
    this.cookieService.delete(AuthenticationService.authAccountIdCookie, '/', domain);
  }

  getLoginStrategy(subdomain: string): Observable<AuthApiAccountStrategy> {
    const getAcctIdUrl = `${environment.authApiProtocol}://${environment.authApiHost}${this.apiAuthAccounts}?subdomain=${subdomain}`;
    const getAcctStratUrlTemplate = `${environment.authApiProtocol}://${environment.authApiHost}${this.apiAuthAccounts}/{acctId}/authenticationconfig`;
    return this.http.get(getAcctIdUrl).pipe(
      concatMap((acctInfo: AuthApiAccountInfo, index: number) => {
        return this.http.get(getAcctStratUrlTemplate.replace('{acctId}', acctInfo.id.toString()));
      }),
      catchError(error => observableThrowError({
        code: error.code,
        errorCode: error.status || error.errorCode,
        message: error.message || 'Unknown error'
      }))
    );
  }

  readAuthInformationFromCookies(): AuthInfoInCookies {
    let accessKey = this.cookieService.check(AuthenticationService.authAccessKeyCookie) ? this.cookieService.get(AuthenticationService.authAccessKeyCookie) : null;
    let userId = this.cookieService.check(AuthenticationService.authUserIdCookie) ? parseInt(this.cookieService.get(AuthenticationService.authUserIdCookie)) : 0;

    // We can't read expiration data on cookies so hard coding to 10 hours (currently what API sets this to)
    if (accessKey && userId) {
      return {
        accessKey: accessKey,
        userId: userId,
        expiration: Date.now() + 10 * 60 * 60 * 1000
      };
    }
    return null;
  }

  setAnalyticsCookies(user: IUser, accountId: number, accountType: EAccountType): void {
    this.setCookie(AuthenticationService.analyticsUserIdCookie, user.id, { noClobber: true });
    this.setCookie(AuthenticationService.analyticsAccountIdCookie, accountId, { noClobber: true });
    this.setCookie(AuthenticationService.analyticsAccountTypeCookie, accountType, { noClobber: true });
    this.setCookie(AuthenticationService.analyticsUserLevel, user.permissions, { noClobber: true });
  }

  private setCookie(name: string, value: any, options: ISetCookieOptions = {}) {
    if (options.noClobber && this.cookieService.check(name)) {
      // cookie already exists
      return;
    }

    const domain = this.getDomain();

    // Check if value is null or undefined
    if (value == null || value === undefined) {
      Sentry.captureMessage(`Attempted to set cookie '${name}' with null or undefined value`, {
        level: 'warning',
        extra: {
          cookieName: name,
          cookieValue: value,
          methodName: 'setCookie',
          className: 'AuthenticationService'
        }
      });
      return;
    }

    // Safely convert value to string
    const stringValue = typeof value === 'object' ? JSON.stringify(value) : String(value);

    if (options.expiresAt) {
      const expires = options.expiresAt ? Math.floor(options.expiresAt / 1000) : (Date.now() + 10 * 60 * 60);

      this.cookieService.set(name, stringValue, expires, '/', domain, this.setSecureFlag);
    } else {
      this.cookieService.set(name, stringValue, null, '/', domain, this.setSecureFlag);
    }
  }

  notifyUserInfoUpdated(): void {
    this.userInfoUpdated.next();
  }

  getUserInfoUpdatedNotifications(): Observable<void> {
    return this.userInfoUpdated.asObservable();
  }
}
