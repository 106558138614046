export enum ItemType {
  Audit = 'audit',
  WebJourney = 'web-journey',
  LiveConnect = 'live-connect'
}

export interface Item {
  itemType: ItemType;
  itemName: string;
  itemId: number;
  runId: number;
}

export interface ITerminateActiveRunsModalPayload {
  type: string;
  items: Item[];
}

export enum ModalType {
  Audit = 'audit',
  WebJourney = 'web journey',
  DeviceProfile = 'device profile',
  Folder = 'folder',
  Folders = 'folders',
  Domain = 'domain',
  Domains = 'domains',
  Selection = 'selection'
}
