import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  IAuditRunPageRuleResultsItem,
  IRuleSummaryPagesTableState
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.models';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { IRuleSummaryPagination } from '@app/components/audit-reports/reports/rule-summary/rule-summary.service';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import { ActivatedRoute } from '@angular/router';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { DecimalPipe } from '@angular/common';
import {
  formatPaginator,
  PageLoadColumnTooltip
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';
import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export interface IAuditRunPageRuleResultsRow extends IAuditRunPageRuleResultsItem {
  statusCodeClass: string;
  loadTimeClass: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-summary-pages',
  templateUrl: './rule-summary-pages.component.html',
  styleUrls: ['./rule-summary-pages.component.scss'],
})
export class RuleSummaryPagesComponent implements OnInit, AfterViewInit, OnChanges {
  protected readonly PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;

  auditId: number;
  runId: number;
  PageLoadColumnTooltip = PageLoadColumnTooltip;

  private onDestroy$: Subject<void> = new Subject();
  readonly TableColumn = CommonReportsPagesTableColumns;

  @Input() inputData: IAuditRunPageRuleResultsItem[];
  @Input() pagesFilteredCount: number;
  @Input() tableState: IRuleSummaryPagesTableState;
  @Input() spinnerState: EFilterSpinnerState;
  @Input() pagesTableSettings: ISwitchableMenuItems;
  @Output() tableStateChanged = new EventEmitter<IRuleSummaryPagesTableState>();
  @Output() pageChanged = new EventEmitter<IRuleSummaryPagination>();

  tableDataSource = new MatTableDataSource<IAuditRunPageRuleResultsRow>();
  displayedColumns$ = this.tableService.displayedColumns$;

  @ViewChild(MatSort, {static: true}) tableSort: MatSort;
  @ViewChild(MatPaginator, {static: true}) tablePaginator: MatPaginator;
  @ViewChild('tableScrollTo', {read: ElementRef}) tableScrollTo: ElementRef;

  pageIdOpenInPageDetails: string;

  constructor(
    private auditReportService: AuditReportService,
    private scrollService: AuditReportScrollService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private activatedRoute: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private tableService: ResizeableTableService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;
    });
  }

  ngOnInit(): void {
    this.handleInputData(this.inputData);
    this.pageDetailsDrawerService.pageDrawerClosed$.
      pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handlePageDetailsClosed());
  }

  ngAfterViewInit(): void {
    this.formatPaginator();

    this.tableSort.sortChange.subscribe((sort: Sort) => {
      // reset the paginator after sorting
      this.tableState.pagination.page = 0;
      this.tablePaginator.firstPage();

      this.tableState.sort = sort.direction ? {
        sortBy: sort.active as CommonReportsPagesTableColumns,
        sortDesc: sort.direction === 'desc'
      } : {
        sortBy: CommonReportsPagesTableColumns.PageUrl,
        sortDesc: false
      };

      this.tableStateChanged.emit(this.tableState);
    });

    this.tablePaginator.page.subscribe((page: PageEvent) => {
      // we only want to trigger tableStateChanged if page has been changed within this component
      // (i.e. user clicked next or prev. page), so data could be reloaded in rule-summary.component.ts
      const pageChangedManully = this.tableState.pagination.page !== page.pageIndex;
      // sync table state pagination with table paginator
      this.tableState.pagination = {
        size: page.pageSize,
        page: page.pageIndex
      };
      if (pageChangedManully) {
        this.tableStateChanged.emit(this.tableState);
      }

      // scrolls to the first row when the paginator fires
      this.scrollService.scrollByElement(this.tableScrollTo.nativeElement);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputData']) {
      this.handleInputData(changes['inputData'].currentValue);
    }
  }

  private handleInputData(inputData: IAuditRunPageRuleResultsItem[]) {
    if (inputData && Array.isArray(inputData)) {
      // sync table paginator with table state pagination
      // this allows to control pagination from outside
      // for example when rule-summary.component.ts needs to reset pagination to 1st page
      // after reloading data with sorting or filtering applied)
      this.tablePaginator.pageIndex = this.tableState.pagination.page;

      this.tableDataSource.data = inputData.map(i => ({
        ...i,
        pageLoadTime: i.pageLoadTime / 1000,
        loadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(i.pageLoadTime / 1000),
        finalPageStatusCode: i.finalPageStatusCode,
        statusCodeClass: this.auditReportService.getStatusCodeClass(i.finalPageStatusCode)
      }));
    }
  }

  openPageDetails(row: IAuditRunPageRuleResultsRow) {
    this.pageIdOpenInPageDetails = row.pageId;
    this.pageDetailsDrawerService.openPageDetails({
      id: row.pageId,
      url: row.pageUrl
    }, this.auditId, this.runId, EPageDetailsTabs.Rules);
  }

  handlePageDetailsClosed() {
    this.pageIdOpenInPageDetails = null;
  }

  destroy() {
    this.onDestroy$.next();
  }

  private formatPaginator(): void {
    this.tablePaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }
}
