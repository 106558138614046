import {Names} from '@app/moonbeamConstants';
import * as angular from 'angular';
import {ModalService} from './modalService';
import {ModalHelperService} from './modalHelperService';
import {ConfirmationController} from './confirmationController';


export default angular.module('modalsModule', [])
  .controller('confirmationController', ConfirmationController)
  .service(Names.Services.modalHelper, ModalHelperService)
  .service(Names.Services.modal, ModalService)
  .name;
