<div class="reprocess-rules-banner" *ngIf="reprocessStatus !== 'hidden'">
  <div class="reprocessing reprocessing-box" *ngIf="reprocessStatus === 'inProgress'">
    <mat-icon>autorenew</mat-icon>
    <span *ngIf="type === 'rules'">Reprocessing rules</span>
    <span *ngIf="type === 'consent-categories'">Reprocessing consent categories</span>
  </div>
  <div class="reprocessing-complete reprocessing-box" *ngIf="reprocessStatus === 'done'">
    <mat-icon>check_circle</mat-icon>
    <span *ngIf="type === 'rules'">Rules reprocessed successfully</span>
    <span *ngIf="type === 'consent-categories'">Consent categories reprocessed successfully</span>
    <button class="view-new-report-btn" mat-button (click)="reload()">View</button>
  </div>
</div>
