<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="DATA LAYER"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="edit-data-layer-wrapper">
      <ng-container *ngIf="editing">
        <form [formGroup]="dataLayerForm">
          <mat-form-field class="styled-mat-input" appearance="outline" floatLabel="always">
            <mat-label>Data Layer</mat-label>
            <input matInput placeholder="Enter data layer name" formControlName="dataLayer">
            <mat-hint>Enter a data layer object, for example: digitalData, dataLayer[1], etc. This field accepts a comma separated list of JavaScript objects or functions to be identified as data layers.</mat-hint>
          </mat-form-field>
        </form>
      </ng-container>
      <ng-container *ngIf="saving">
        <div class="center-elements">
          <mat-spinner [diameter]="25" [strokeWidth]="4"></mat-spinner>
          <span>Saving...</span>
        </div>
      </ng-container>
      <ng-container *ngIf="error">
        <div class="center-elements">
          <p>There was a problem updating the data layer.<br>Please refresh the page and try again.</p>
        </div>
      </ng-container>
    </div>
  </ng-template>
</op-modal>
