import { DateRange } from '@angular/material/datepicker';
import {
  EOpFilterBarItemDatapickerRanges,
  IOpFilterBarItemDatepickerRangeState
} from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.models';
import { addMinutes, addMonths, subDays, subMinutes, subMonths } from 'date-fns';

export class OpFilterBarItemDatepickerUtils {

  static getDefaultDateRange(): DateRange<Date> {
    const date = new Date();
    const end = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    const start = subDays(new Date(end), 6);
    const type = EOpFilterBarItemDatapickerRanges.CustomDateRange;
    const preparedRangeState = OpFilterBarItemDatepickerUtils.prepareRangeTimeState({
      type,
      dates: new DateRange(start, end)
    });

    return new DateRange(preparedRangeState.dates.start, preparedRangeState.dates.end);
  }

  static prepareRangeState(rangeState: IOpFilterBarItemDatepickerRangeState): IOpFilterBarItemDatepickerRangeState {
    const preparedRangeDate = OpFilterBarItemDatepickerUtils.prepareRangeDateState(rangeState);
    const preparedRangeTime = OpFilterBarItemDatepickerUtils.prepareRangeTimeState(preparedRangeDate);
    return preparedRangeTime;
  }

  /**
   * Prepare start and end dates for the datepicker, based on the range type
   */
  private static prepareRangeDateState({ type, dates }: IOpFilterBarItemDatepickerRangeState): IOpFilterBarItemDatepickerRangeState {
    const date = new Date();
    const todayUTC = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

    switch (type) {
      case EOpFilterBarItemDatapickerRanges.CustomDateRange: {
        return {
          type: EOpFilterBarItemDatapickerRanges.CustomDateRange,
          dates: dates.start ? new DateRange(dates.start, dates.end) : OpFilterBarItemDatepickerUtils.getDefaultDateRange()
        };
      }
      case EOpFilterBarItemDatapickerRanges.AllAvailableDays:
        return {
          type: EOpFilterBarItemDatapickerRanges.AllAvailableDays,
          dates: new DateRange<Date>(subMonths(new Date(todayUTC), 13), todayUTC)
        };
      case EOpFilterBarItemDatapickerRanges.Last30Days:
        return {
          type: EOpFilterBarItemDatapickerRanges.Last30Days,
          dates: new DateRange<Date>(subDays(new Date(todayUTC), 29), todayUTC)
        };
      case EOpFilterBarItemDatapickerRanges.Last60Days:
        return {
          type: EOpFilterBarItemDatapickerRanges.Last60Days,
          dates: new DateRange<Date>(subDays(new Date(todayUTC), 59), todayUTC)
        };
      case EOpFilterBarItemDatapickerRanges.Last90Days:
        return {
          type: EOpFilterBarItemDatapickerRanges.Last90Days,
          dates: new DateRange<Date>(subDays(new Date(todayUTC), 89), todayUTC)
        };
    }
  }

  /**
   * Convert time to the followind format:
   * from - 00:00:00, end - 23:59:59 (User's timezone)
   */
  private static prepareRangeTimeState({ type, dates }: IOpFilterBarItemDatepickerRangeState): IOpFilterBarItemDatepickerRangeState {
    const from = dates.start;
    from.setHours(0, 0, 0);

    const to = new Date(dates.end);
    to.setHours(23, 59, 59);

    return {
      type,
      dates: new DateRange<Date>(from, to)
    }
  }
}
