import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges, HostListener, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { EActionType, ISelectOption, EActionTypeV3 } from '@app/components/web-journey/web-journey.models';
import { Subject } from 'rxjs';
import { ActionDetailsService, ICmpProvider } from '@app/components/actions/action-details/action-details.service';
import { EActionCreatorMode } from '@app/components/actions/actions-creator/actions-creator.enum';
import { ActionDetailsFormBuilder, config } from './action-details-form-builder.service';
import { ComponentChanges } from '@app/models/commons';
import { CalculateWordWidth } from '@app/components/domains/discoveryAudits/reporting/services/calculateWordWidthService/calculateWordWidthService';
import { takeUntil } from 'rxjs/operators';
import { IActionSet, IActionSetAction, IActionSetSelector, IActionSetDetailedSelectorValue } from '../../action-set-library/action-set-library.models';
import { MatTableDataSource } from '@angular/material/table';
import { STEP_TYPE_TO_LABEL_MAP } from '@app/components/audit/audit.constants';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ActionSetEditorComponent } from '@app/components/action-set-library/action-set-editor/action-set-editor.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { EWebElementSelectorTypeV3 } from '@app/components/shared/components/multi-selectors/multi-selectors.models';
import { ECmpOption } from './action-details.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-details',
  templateUrl: './action-details.component.html',
  styleUrls: ['./action-details.component.scss']
})
export class ActionDetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() detailsFormGroup: UntypedFormGroup;

  @Input() mode: EActionCreatorMode;
  @Input() index: number;
  @Input() submitted: boolean;
  @Input() selected: boolean;

  @Input() allActionSets: IActionSet[];
  @Input() navToActionSets: IActionSet[];

  @Output() onActivate = new EventEmitter<void>();
  @Output() onTypeChange = new EventEmitter<number>();
  @Output() openRuleSelector: EventEmitter<void> = new EventEmitter();
  @Output() onDelete = new EventEmitter<void>();

  private destroy$ = new Subject();

  readonly actionType = EActionTypeV3;
  readonly actionCreatorMode = EActionCreatorMode;
  readonly controlsConfig = config;
  readonly actionNamePlaceholder = 'unnamed action';

  actionTypes: ISelectOption[] = [];

  onChanged = val => {};
  onTouched = () => {};

  cachedType: EActionTypeV3;

  containerWidth: number = 400; //default width
  rulesInPreview: number = 0;
  maxRuleWidth: number = 300;
  splitterWidth: number = 7;
  canDelete = false;

  actionSets: IActionSet[];
  displayedColumns: string[] = ['number', 'type', 'value', 'selector'];
  dataSource = new MatTableDataSource([]);

  selectorTypes = {
    id: 'ID',
    name: 'Name',
    css: 'CSS',
    xpath: 'XPath',
    htmlattributes: 'HTML Attribute'
  }

  ECmpOption = ECmpOption;
  supportedCmps: string;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calcContainerWidth();
    this.calculateHiddenRules();
  }

  @HostListener('document:keydown',['$event'])
  onKeydown($event: KeyboardEvent): void {
    $event.stopPropagation();
  }

  constructor(private actionDetailsService: ActionDetailsService,
              private actionDetailsFormBuilder: ActionDetailsFormBuilder,
              private calculateWordWidthService: CalculateWordWidth,
              private el: ElementRef,
              private modalService: OpModalService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.calcPossibleActions(null);

    this.detailsFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(action => {
      this.actionDetailsFormBuilder.updateDisabling(this.detailsFormGroup, this.mode);
      this.actionDetailsFormBuilder.updateValidation(this.detailsFormGroup, this.mode);

      if (this.cachedType !== action.type) {
        this.cachedType = action.type;
        this.onTypeChange.emit(this.index);

        // action set and cmp type actions cannot have rules
        if (action.type === EActionTypeV3.ActionSet || action.type === EActionTypeV3.CmpOptInOptOut) {
          setTimeout(() => this.rules.setValue([]), 0);
        }
      }
    });

    this.rules.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(rules => {
      this.calculateHiddenRules();
    });

    this.getSupportedCmps();
  }

  ngAfterViewInit(): void {
    this.calcContainerWidth();
    if (this.containerWidth) this.calculateHiddenRules();
  }

  addProtocolIfMissing(): void {
    if (this.url.value && !this.url.value.match(/:\/\//)) {
      this.url.setValue('https://' + this.url.value);
    }
  }

  initActionSetTable(): void {
    if (this.actionSets && this.actionSet?.value) {

      let selectedActionSet = this.actionSet.value.actions
        ? this.actionSet.value
        : this.actionSets.find(actionSet => actionSet.id === this.actionSet.value.id);

      this.actionSet.patchValue(selectedActionSet ? selectedActionSet : []);

      if(!selectedActionSet) return;

      selectedActionSet.actions.sort((a: any, b: any) => (a.sequence > b.sequence) ? 1 : -1);

      this.dataSource.data = selectedActionSet.actions.map((action: IActionSetAction, index: number) => {

        let type = action.actionType ? STEP_TYPE_TO_LABEL_MAP.get(action.actionType) : '';

        let selectorType = action.selectors && action.selectors[0]
          ? this.selectorTypes[action.selectors[0].selectorType]
          : '';

        let selectorValue: string | IActionSetDetailedSelectorValue;

        if(action.selectors && action.selectors[0].selectorType === EWebElementSelectorTypeV3.HtmlAttrs) {
          selectorValue = this.renderHtmlAttributeSelector(action.selectors[0]);
        } else {
          selectorValue = action.selectors && action.selectors[0]
            ? action.selectors[0].value
            : '-';
        }

        return {
          number: index + 1,
          type: type,
          value: this.determineActionValue(action),
          selectorType: selectorType,
          selectorValue: selectorValue
        };
      });
    }
  }

  determineActionValue(action: IActionSetAction): string {
    switch(action.actionType) {
      case EActionTypeV3.NavTo:
      case EActionTypeV3.CmpOptInOptOut:
      case EActionTypeV3.CmpOptIn:
      case EActionTypeV3.CmpOptOut:
        return action.url;
      case EActionTypeV3.Execute:
        return action.js;
      case EActionTypeV3.Watch:
        return action.seconds.toString();
      default:
        return action.value || '-';
    }
  }

  private getElement() {
    return this.el.nativeElement.querySelector('.action-rules-mock');
  }

  private setCanDelete() {
    switch (this.mode) {
      case EActionCreatorMode.AuditActions:
        this.canDelete = true;
        break;
      case EActionCreatorMode.AuditUserSession:
        this.canDelete = (this.index > 0 || this.actions.value.length <= 1 || this.actions.value[1].type === EActionType.NavTo || this.actions.value[1].type === EActionType.CmpOptInOptOut);
        break;
      case EActionCreatorMode.WebJourney:
        this.canDelete = this.index > 0;
        break;
      case EActionCreatorMode.ActionSet:
        this.canDelete = true;
        break;
    }
  }

  ngOnChanges(changes: ComponentChanges<ActionDetailsComponent>): void {
    if (changes.index && changes.index.currentValue !== changes.index.previousValue) {
      this.setCanDelete();
    }

    if (changes.parentForm && changes.parentForm.currentValue !== changes.parentForm.previousValue) {
      this.actions.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.setCanDelete());
    }

    if (changes.allActionSets) {
      const isUserSessionOrJourney = this.mode === EActionCreatorMode.AuditUserSession || this.mode === EActionCreatorMode.WebJourney;
      this.actionSets = this.index === 0 && isUserSessionOrJourney ? this.navToActionSets : this.allActionSets;
      this.initActionSetTable();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  calcPossibleActions(changedActionIndex: number = null): void {
    if (changedActionIndex !== this.index) {
      this.actionTypes = this.actionDetailsService.calcPossibleActionTypes(this.mode, this.index, this.actions.value);
    }
  }

  handleMatchAllPagesChange(event: MatCheckboxChange): void {
    if (event.checked) {
      this.filter.disable();
      this.filter.setValue('.*');
    } else {
      this.filter.enable();
      this.filter.setValue('');
    }
  }

  private calcContainerWidth(): void {
    const elem = this.getElement();
    if (elem && elem.offsetWidth)
      this.containerWidth = elem.offsetWidth;
  }

  private calculateHiddenRules(): void {
    this.calcContainerWidth();
    if (!this.rules.value) return;
    let lines = 1;
    let currentLine = 1;
    this.rulesInPreview = 0;
    let rowWidth = 0;
    for (var i = 0; i < this.rules.value.length; i++) {
      let textWidth = this.getTextWidth(this.rules.value[i].name);
      textWidth = textWidth > this.maxRuleWidth ? this.maxRuleWidth : textWidth;
      textWidth += this.splitterWidth;
      if ((rowWidth + textWidth + this.getTextWidth('+ ' + (this.rules.value.length - this.rulesInPreview + 1))) < this.containerWidth * currentLine) {
        rowWidth += textWidth;
        this.rulesInPreview++;
      } else if (lines != currentLine) {
        rowWidth = currentLine * this.containerWidth + textWidth;
        this.rulesInPreview++;
        currentLine++;
      } else {
        break;
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  private getTextWidth(text: string): number{
    return this.calculateWordWidthService.calculate(text, {
      font: 'Open Sans',
      fontSize: '12px'
    }).width;
  }

  addRules(): void {
    this.openRuleSelector.emit();
  }

  editActionSet(actionSet: IActionSet) {
    this.modalService.openFixedSizeModal(ActionSetEditorComponent, { disableClose: true, data: actionSet })
      .afterClosed()
      .subscribe((actionSet: IActionSet) => {
        if(actionSet) {
          this.actionSet.patchValue(actionSet);
          this.actionSets = this.actionSets.filter((set: IActionSet) => set.id !== actionSet.id);
          this.actionSets.push(actionSet);
          this.actionSets.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
          this.initActionSetTable();
        }
      });
  }

  private renderHtmlAttributeSelector(selector: IActionSetSelector): string {
    let tagName = selector.value['tagName'];
    let innerHTML = selector.value['innerHTML'] ? selector.value['innerHTML'] : '';
    let attributes = selector.value['attributes']
      .map(attribute => `${attribute.name}="${attribute.value}"`)
      .join(' ');

    return `<${tagName} ${attributes}>${innerHTML}</${tagName}>`;
  }

  private getSupportedCmps(): void {
    this.actionDetailsService.getSupportedCmpList().subscribe((supportedCmps: ICmpProvider[]) => {
      this.supportedCmps = supportedCmps.map(cmp => cmp.name).join(', ');
    });
  }

  get actions(): AbstractControl {
    return this.parentForm.get('actions');
  }

  get type(): AbstractControl {
    return this.detailsFormGroup.get('type');
  }

  get url(): AbstractControl {
    return this.detailsFormGroup.get('url');
  }

  get value(): AbstractControl {
    return this.detailsFormGroup.get('value');
  }

  get label(): AbstractControl {
    return this.detailsFormGroup.get('label');
  }

  get maskedValue(): AbstractControl {
    return this.detailsFormGroup.get('maskedValue');
  }

  get js(): AbstractControl {
    return this.detailsFormGroup.get('js');
  }

  get seconds(): AbstractControl {
    return this.detailsFormGroup.get('seconds');
  }

  get selectors(): AbstractControl {
    return this.detailsFormGroup.get('selectors');
  }

  get filter(): AbstractControl {
    return this.detailsFormGroup.get('filter');
  }

  get matchAllPages(): AbstractControl {
    return this.detailsFormGroup.get('matchAllPages');
  }

  get preventNavigation(): AbstractControl {
    return this.detailsFormGroup.get('preventNavigation');
  }

  get rules(): AbstractControl {
    return this.detailsFormGroup.get('rules');
  }

  get actionSet(): AbstractControl {
    return this.detailsFormGroup?.get('actionSet');
  }

  get cmpType(): AbstractControl {
    return this.detailsFormGroup.get('cmpType');
  }

  get cmpUrl(): AbstractControl {
    return this.detailsFormGroup.get('cmpUrl');
  }

  get isRequired(): AbstractControl {
    return this.detailsFormGroup.get('isRequired');
  }

  get actionLabel(): string {
    return `${this.index + 1}. ${this.label?.value || this.actionNamePlaceholder}`;
  }
}
