import * as angular from "angular";

export class OpClipboardDirective implements angular.IDirective {
    restrict = 'A';

    link = (scope: angular.IScope, element: angular.IRootElementService, attrs) => {
      element.append('<button class="copy-btn">Copy</button>');
      var input = element.find('input');
      var button = element.find('button');
      button.on('click', function() {
        input.select();
        document.execCommand('copy');
      });
    };

    static factory(): angular.IDirectiveFactory {
      const directive = () => {
        return new OpClipboardDirective();
      };
      return directive;
    }
  }
