export enum EStatusButtonColor {
  Green = 'green',
  Red = 'red',
  Yellow = 'yellow',
  Orange = 'orange',
  None = 'none',
}

export enum ELeadingIconColor {
  Green = 'green',
  Red = 'red',
  Yellow = 'yellow',
  Orange = 'orange',
}
