import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../alert.service';
import { AlertOperatorGroups, AnyChangeOperator, UsagePostScriptsByMetricV2 } from '../alert-logic.constants';
import {
  EAlertAuditSummaryMetric as EAuditSummaryMetric,
  EAlertMetricValueComparator as EMetricValueComparator,
  EAlertMetricChangeType, EAlertUsageMetricV2,
} from '../alert-logic.enums';
import { IAlertOperator } from '../alert-logic.models';
import { EAlertMenuContext, EAlertModalType } from '@app/components/alert/alert.enums';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-trigger',
  templateUrl: './alert-trigger.component.html',
  styleUrls: ['./alert-trigger.component.scss']
})
export class AlertTriggerComponent implements OnInit, OnDestroy {

  @Input() formGroup: UntypedFormGroup;
  @Input() modalType: EAlertModalType;
  @Input() readOnlyLabel: string;
  @Input() menuContext: EAlertMenuContext;

  valuePlaceholder: string;
  displayedAlertOperatorGroups = [...AlertOperatorGroups];
  accountLimitMetricTypes = [
    EAlertUsageMetricV2.AccountUsageCurTermAuditPageScannedCount,
    EAlertUsageMetricV2.AccountUsageMonthPaceAuditPageScannedCount,
    EAlertUsageMetricV2.AccountUsageCurTermWebJourneyRunsCount,
    EAlertUsageMetricV2.AccountUsageMonthPaceWebJourneyRunsCount,
  ];
  percentageChangeTypes = [
    EAlertMetricChangeType.RelativeValueChange,
    EAlertMetricChangeType.RelativeValueChangeAbs,
  ];

  UsagePostScriptsByMetric = UsagePostScriptsByMetricV2;

  private destroySubject = new Subject<void>();

  readonly EMetricValueComparator = EMetricValueComparator;
  readonly AlertOperatorGroups = AlertOperatorGroups;
  readonly EAlertModalType = EAlertModalType;

  constructor(
    private alertService: AlertService,
    private alertReportingService: AlertReportingService,
  ) {}

  ngOnInit() {
    // Need to set on init to make sure the label persists when switching between tabs, as well.
    if (this.metricTypeFormControl.value) {
      this.buildValuePlaceholder(this.metricTypeFormControl.value);
      this.updateVisibleOperators(this.metricTypeFormControl.value);
    }

    this.metricTypeFormControl.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe((metricType: EAuditSummaryMetric) => {
        if (metricType) {
          this.updateVisibleOperators(metricType);
          this.operatorFormControl.enable({ emitEvent: false });
        } else {
          this.operatorFormControl.disable({ emitEvent: false });
        }

        this.buildValuePlaceholder(metricType);
      });

    this.operatorFormControl.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe((operator: IAlertOperator) => {
        this.buildValuePlaceholder(this.metricTypeFormControl.value);
        const anyChangeOperator = operator?.comparator === AnyChangeOperator.comparator && operator?.changeType === AnyChangeOperator.changeType;
        if (anyChangeOperator) {
          this.targetValueFormControl.setValue(0);
          this.targetValueFormControl.disable({ emitEvent: false });
          return;
        }

        operator
          ? this.targetValueFormControl.enable({ emitEvent: false })
          : this.targetValueFormControl.disable({ emitEvent: false });
      });
  }

  updateVisibleOperators(metricType: EAuditSummaryMetric) {
    // Update type of operators depending on selected metric type. I.e. Usage metric types do not display
    // run over run operators.
    if (this.alertReportingService.isAccountLevelMetric(metricType)) {
      this.targetValueFormControl.setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
      const runOverRunIndex = this.displayedAlertOperatorGroups.findIndex(group => group.name === 'Change (Run Over Run)');
      if (runOverRunIndex > 0) this.displayedAlertOperatorGroups.splice(runOverRunIndex, 1);
    } else {
      this.targetValueFormControl.setValidators([Validators.required]);
      this.displayedAlertOperatorGroups = [...this.AlertOperatorGroups];
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private buildValuePlaceholder(metricType: EAuditSummaryMetric) {
    if (!metricType) return null;
    const metric = AlertUtils.getMetricConfigByMetricType(metricType);

    // This also needs to be operator-aware. If using an operator that is based on %, the label should say % and not
    // a specific unit like 'sec'
    const operatorChangeType = this.operatorFormControl.value?.changeType;
    if (this.percentageChangeTypes.includes(operatorChangeType)) {
      this.valuePlaceholder = `Value (%)`;
    } else {
      this.valuePlaceholder = metric.unit ? `Value (${metric.unit})` : 'Value';
    }
  }

  get metricTypeFormControl(): UntypedFormControl {
    return this.formGroup.get('metricType') as UntypedFormControl;
  }

  get operatorFormControl(): UntypedFormControl {
    return this.formGroup.get('operator') as UntypedFormControl;
  }

  get targetValueFormControl(): UntypedFormControl {
    return this.formGroup.get('targetValue') as UntypedFormControl;
  }
}
