<div class="missing-primary-tags">
  <div class="missing-primary-tags-wrap">
    <div class="missing-tag-text">
      {{ state === cardState.All ? 'All ' : '' }}{{ tags.length }} of your
      primary tags {{ tags.length > 1 ? "were not" : "was not" }} found in this audit
    </div>
    <div class="missing-tag-icons" #iconContainer>
      <ng-container *ngIf="tags && displayTags && tags?.length && displayTags?.length">
        <img *ngFor="let tag of displayTags"
            class="missing-tag-icon"
             [src]="getTagIconUrl(tag.tagId)"
            alt="{{ tag.tagName }}"
            [matTooltip]="tag.tagName"
            matTooltipPosition="above" />
        </ng-container>
    </div>
    <ng-container *ngIf="tags && displayTags && tags?.length > displayTags?.length">
      <div class="plus-more">+ {{ tags.length - displayTags.length }} more</div>
    </ng-container>
  </div>
</div>
