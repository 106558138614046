import { ETriggeredAlertsTableColumns } from "./triggered-alerts-table.enum";
import { ITriggeredAlertsTablePagination, ITriggeredAlertsTableSort } from "./triggered-alerts-table.models";

export const triggeredAlertsTableDefaultPagination: ITriggeredAlertsTablePagination = {
  totalCount: 0,
  totalPageCount: 0,
  currentPageSize: 0,
  pageSize: 50,
  currentPageNumber: 0,
};

export const triggeredAlertsTableDefaultSorting: ITriggeredAlertsTableSort = {
  sortBy: ETriggeredAlertsTableColumns.AlertName,
  sortDesc: false,
  sortDir: 'asc'
};
