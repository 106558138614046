export enum EWJResultsTab {
  ActionDetails,
  Tags,
  TagsComparison,
  Cookies,
  Rules,
}

export enum EJourneyFailureType {
  Action,
  Rule,
  Global,
  None
}
