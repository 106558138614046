import { Component, HostListener, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EExportStatus } from '@app/components/export-center/export-center.enums';
import { Router } from '@angular/router';
import { ExportCenterService } from '@app/components/export-center/export-center.service';

@Component({
  selector: 'op-export-report-modal',
  templateUrl: './export-report-modal.component.html',
  styleUrls: ['./export-report-modal.component.scss']
})
export class ExportReportModalComponent implements AfterViewInit, OnInit {

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  message: string;
  reportItem: any;
  exportId: number;
  exportDownloadLink: string;
  exportStatus: string = 'Starting...';
  failureMessage = "Export failed to download. Please try again later or contact support.";

  time: number = 0;
  timeElapsed: string = "1 second";
  isExporting: boolean = true;
  firstPoll: boolean = true;
  timeIntervalElapsed: number = 0;
  private updateIntervalId: any;
  private intervalId: any;
  private currentInterval: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ExportReportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected router: Router,
    private exportCenterService: ExportCenterService,
  ) {
    // Stop polling when dialog is closed
    dialogRef.beforeClosed().subscribe(() => this.stopPolling());
  }

  ngOnInit() {
    this.message = this.data.message;
    this.reportItem = this.data.reportItem;
    this.exportId = this.data.exportId;
  }

  ngAfterViewInit() {
    this.startPolling();
    this.startElapsedTimeCounter();
  }

  private startPolling() {
    this.firstPoll = true;
    this.pollAPI();
  }

  private startElapsedTimeCounter() {
    this.updateIntervalId = setInterval(() => {
      this.time++;
      if (this.time < 60) {
        // If less than a minute, show seconds only
        this.timeElapsed = `${this.time} second${this.time > 1 ? 's' : ''}`;
      } else {
        // If a minute or more, show minutes only, rounding down
        const minutes = Math.floor(this.time / 60);
        this.timeElapsed = `${minutes} minute${minutes > 1 ? 's' : ''}`;
      }
    }, 1000);
  }

  private pollAPI() {
    this.intervalId = setTimeout(() => {

      this.callExportService().then(response => {

        // If completed, failed or time-limit has expired then stop polling
        if (response.status === EExportStatus.completed || response.status === EExportStatus.failed || response.status === EExportStatus.noData) {
          this.stopPolling();
          return;
        }

        // Increase the interval by 2 second after 14 seconds, up to a max of 30 seconds
        if (this.firstPoll) {
          this.currentInterval = 2000;
          this.firstPoll = false;
        } else if (this.currentInterval < 30000 && this.time > 14) {
          this.currentInterval += 2000;
        }

        this.pollAPI(); // Continue polling API unil completed or error
      });
    }, this.currentInterval);
  }

  private stopPolling() {
    this.isExporting = false;
    clearTimeout(this.intervalId);
    clearInterval(this.updateIntervalId);
  }

  private callExportService(): Promise<{ status: string }> {
    return new Promise((resolve, reject) => {
      this.exportCenterService.getExportsById([this.exportId], null, null).subscribe(response => {

        // Display the status to the user.
        if (response.exports.length > 0) {
          this.exportStatus = response.exports[0].exportStatus;
        } else {
          this.exportStatus = "Unknown";
        }

        if (response.exports.length > 0) {
          if (response.exports[0].exportStatus === EExportStatus.inProgress) {
            this.exportStatus = "In Progress";
            resolve({ status: EExportStatus.inProgress });
            return;
          } else if (response.exports[0].exportStatus === EExportStatus.queued) {
            this.exportStatus = "Queued";
            resolve({ status: EExportStatus.inProgress });
            return;
          } else if (response.exports[0].exportStatus === EExportStatus.completed) {
            this.exportDownloadLink = response.exports[0].exportDownloadLink;
            this.message = "Your export has downloaded to your computer.";
            this.autoDownloadExport();
            resolve({ status: EExportStatus.completed });
            return;
          } else if (response.exports[0].exportStatus === EExportStatus.noData) {
            this.message = "No data available for this export";
            resolve({ status: EExportStatus.noData });
            return;
          } else {
            this.message = this.failureMessage;
            resolve({ status: EExportStatus.failed });
            return;
          }
        } else {
          this.message = this.failureMessage;
          resolve({ status: EExportStatus.failed });
          return;
        }
      });
    });
  }

  autoDownloadExport() {
    const downloadLink = document.createElement('a');
    downloadLink.href = this.exportDownloadLink;
    downloadLink.download = '';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  closeDialogOnLeftClick(event: MouseEvent): void {
    // If left-clicking
    if (event.button === 0) {
      this.close();
    }
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      event.stopPropagation();
      this.close();
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
