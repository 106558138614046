<op-modal (onClose)="close()"
          [rightFooterButtons]="rightFooterButtons">
  <ng-template #headerTemplate>
    <op-modal-header [title]="'Edit User Audit Page Limit'"
                     (onClose)="close()"></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="content">
      <p class="help-text">
        Set a limit for how many pages can be audited per audit run for {{ data.firstName }} {{ data.lastName }}
      </p>

      <mat-form-field class="limits-field"
                      appearance="outline"
                      floatLabel="always">
        <mat-label>PAGE AUDIT LIMIT PER RUN</mat-label>
        <input matInput [formControl]="limit">
        <mat-error *ngIf="limit.errors">
          <mat-icon>warning_amber</mat-icon>
          Enter valid value
        </mat-error>
      </mat-form-field>

      <p class="hint">
        If a limit is set below any audits that have been currently
        scheduled then those audits will be auto-paused
        until the page scan limit has been put below the set limit.
      </p>
    </div>
  </ng-template>
</op-modal>
