import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAudit } from '@app/components/audits-picker/audits-picker.models';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { IButton } from '@app/models/commons';
import { forkJoin, Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IAccountTagSettings, IComparison, IComparisonTag } from '../comparisons.models';
import { ComparisonsService } from './../comparisons.service';
import { ERorComparisonStep } from './ror-comparison.enums';
import { IRorComparisonDetails, IRorFormModel, IRunOverRunModalData } from './ror-comparison.models';
import { EDatasourceTagType } from '../comparisons.enums';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-comparison',
  templateUrl: './ror-comparison.component.html',
  styleUrls: ['./ror-comparison.component.scss']
})
export class RorComparisonComponent implements OnInit {
  
  showSpinner: boolean = true;
  
  title: string = 'Run Over Run Comparison';
  steps: string[] = ['Set Up', 'Apply', 'Match Tags'];
  currentStep: ERorComparisonStep = ERorComparisonStep.SETUP;
  rorComparisonForm: UntypedFormGroup;
  tagData: any[];
  submitted: boolean = false;

  comparisonTags: IComparisonTag[] = [];
  matchTagsOptions: Map<number, string[]> = new Map();

  accountTagSettingsMap: Map<number, IAccountTagSettings>;

  selectedAudits: number[] = [];
  availableAudits: IAudit[] = [];

  rightFooterButtons: IButton[] = [
    {
      label: 'Continue',
      action: this.continue.bind(this),
      primary: false
    },
    {
      label: 'Save & Complete',
      action: this.saveComparison.bind(this),
      primary: true
    }
  ];

  constructor(public dialogRef: MatDialogRef<RorComparisonComponent>,
              private formBuilder: UntypedFormBuilder,
              private auditService: DiscoveryAuditService,
              @Inject(MAT_DIALOG_DATA) @Optional() public data: IRunOverRunModalData,
              private uiTagService: UiTagService,
              private comparisonsService: ComparisonsService) {

    this.initForm();
  }

  ngOnInit() {
    this.auditService.getAudits().then(audits => {
      this.availableAudits = audits.map(audit => ({
        id: audit.id,
        name: audit.name,
        labels: audit.labels
      }));
    });
    
    forkJoin([
      this.comparisonsService.getAccountTags(), 
      this.fetchComparison(), 
      this.uiTagService.getTags()
    ]).pipe(
      finalize(() => {
        this.showSpinner = false;
      })
    ).subscribe(([accountTagSettings, comparison, allTags]) => {
      const comparisonTags = comparison 
        ? comparison.alignments.reduce((tags, alignment) => ({...tags, [alignment.tagId]: true}), {})
        : {};
      
      this.setTags(
        accountTagSettings.filter(t => t.comparisonEnabled || comparisonTags[t.tagId]), comparison, allTags
      );

      if (comparison) {
        comparison.alignments.forEach(al => {
          let tag = this.comparisonTags.find(ct => ct.id === al.tagId);
          tag.variableAlignment = al.variables;
        });
        this.fillForm(comparison);
      }
    });
  }

  private initForm() {
    this.rorComparisonForm = this.formBuilder.group({
      setup: this.formBuilder.control(''),
      apply: this.formBuilder.control([]),
      matchTags: this.formBuilder.control([])
    });
  }

  private setTags(accountTagSettings: IAccountTagSettings[], comparison: IComparison, allTags: IUiTag[]) {
    this.accountTagSettingsMap = new Map();
    this.comparisonTags = accountTagSettings.map(t => {
      this.accountTagSettingsMap.set(t.tagId, t);
      const tag = allTags.find(tag => tag.id === t.tagId);

      if (!tag) return null;
      return {
        id: tag.id,
        icon: UiTagService.getTagIconUrl(tag.id),
        name: tag.name,
        variables: [],
        accountExcludedVariables: [...this.accountTagSettingsMap.get(tag.id).excludedVariables],
        excludedVariables: [...this.accountTagSettingsMap.get(tag.id).excludedVariables],
        variableAlignment: [...this.accountTagSettingsMap.get(tag.id).variableAlignment],
        comparisonEnabled: this.accountTagSettingsMap.get(tag.id).comparisonEnabled
      };
    }).filter(tag => !!tag);

    // add to comparisonTags custom variables added before. Actual when edit comparison.
    comparison && (comparison.details as IRorComparisonDetails).datasources.forEach(datasourceTag => {
      const cTag = this.comparisonTags.find(
        t => t.id === datasourceTag.tagId
      );
      if (datasourceTag.selectedVariables) cTag.variables = [
        ...cTag.variables,
        ...datasourceTag.selectedVariables.filter(
          item => cTag.variables.indexOf(item) < 0
        )
      ];
      if (datasourceTag.type === EDatasourceTagType.AllVariablesExceptExcluded) {
        cTag.excludedVariables = [
          ...cTag.excludedVariables,
          ...datasourceTag.addedExclusions
        ];
      }
    });
  }

  private fetchComparison(): Observable<IComparison> {
    return (this.data && this.data.id) ? this.comparisonsService.get(this.data.id) : of(null);
  }

  private fillForm(comparison: IComparison): void {
    this.setupGroup.setValue(IComparison.toRORComparisonSetupForm(comparison, this.accountTagSettingsMap));
    this.rorComparisonForm.get('apply')
      .setValue(
        (<IRorComparisonDetails>comparison.details).assignments.map(assignment => assignment.itemId)
      );
  }

  continue() {
    this.goToStep(this.currentStep + 1);
  }

  goToStep(step: ERorComparisonStep): void {
    if (this.rorComparisonForm.invalid) {
      this.submitted = true;
      return;
    }
    this.updateMatchTags();
    this.currentStep = step;
    this.updateButtons();
  }

  updateMatchTags() {
    const filteredTags = this.comparisonTags.filter(ct =>
      this.setupGroup.value.tags.find(t => t.tagId === ct.id)
    );
    this.rorComparisonForm.get('matchTags').setValue(
      filteredTags.map(t => ({
          id: t.id,
          name: t.name,
          icon: t.icon,
          variables: t.variableAlignment
        })
      )
    );
    this.matchTagsOptions.clear();
    filteredTags.forEach(ft => this.matchTagsOptions.set(ft.id, ft.variables));
  }

  private updateButtons(): void {
    this.rightFooterButtons[0].hidden = this.currentStep === ERorComparisonStep.MATCH_TAGS;
  }

  saveComparison(): void {
    this.submitted = false;
    if (this.rorComparisonForm.invalid) {
      this.submitted = true;
      return;
    }

    this.updateMatchTags();
    const comparisonCreate = IRorFormModel.toComparisonCreate(
      this.rorComparisonForm.value,
      this.accountTagSettingsMap
    );
    if (this.data && this.data.id) {
      this.comparisonsService.update(comparisonCreate, this.data.id).subscribe(() => {
        this.closeModal();
      });
    } else {
      this.comparisonsService.create(comparisonCreate).subscribe(() => {
        this.closeModal();
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  private get setupGroup(): UntypedFormGroup {
    return this.rorComparisonForm.get('setup') as UntypedFormGroup;
  }
}
