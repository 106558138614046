import * as angular from 'angular';

import { AngularNames, Names } from '@app/moonbeamConstants';
import {
  DeleteControllerBase,
  IDeleteScope,
  IDisplayItem
} from '@app/components/delete/deleteControllerBase';
import {
  name as MJS_NAME,
  IManualJourney,
  ManualJourneyService
} from '@app/components/live-connect/manual-journey/manualJourneyService';
import { DateService, EDateFormats } from '@app/components/date/date.service';

export const name = 'DeleteManualJourneyController';

export class DeleteManualJourneyController extends DeleteControllerBase<
  IManualJourney
> {
  static $inject = [
    AngularNames.scope,
    DeleteManualJourneyController.Dependencies.deleteHandler,
    MJS_NAME,
    Names.Services.date,
  ];

  constructor(
    protected $scope: IDeleteScope<IManualJourney>,
    protected onDeleteHandler: Function,
    private manualJourneyService: ManualJourneyService,
    protected dateService: DateService,
  ) {
    super($scope, onDeleteHandler, dateService);
  }

  protected createDisplayItem(item: IManualJourney): IDisplayItem {
    return {
      name: item.name,
      createdAt: this.dateService.formatDate(new Date(item.startedAt), EDateFormats.dateTwo),
      type: 'Manual Journey'
    };
  }

  protected delete(item: IManualJourney): angular.IPromise<boolean> {
    return this.manualJourneyService.removeManualJourney(
      item.journeyId,
      item.id
    );
  }

  protected handleError(error: any) { }
}
