import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, Output, } from '@angular/core';
import * as d3 from 'd3';
import { ScaleLinear, ScaleTime, Selection } from 'd3';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  ISparklineChartColorizedBoundaries,
  ISparklineChartColorizedConfig,
  ISparklineChartColorizedData,
} from '@app/components/shared/components/viz/sparkline-chart-colorized/sparkline-chart-colorized.models';
import { SidebarService } from '@app/components/navigation/sidebar/sidebar.service';

@Component({
  selector: 'op-sparkline-chart-colorized',
  templateUrl: './sparkline-chart-colorized.component.html',
  styleUrls: ['./sparkline-chart-colorized.component.scss']
})
export class SparklineChartColorizedComponent implements AfterViewInit, OnDestroy {
  svgWidth: number;
  svgHeight: number;
  container: Selection<any, any, any, any>;
  svg: Selection<SVGSVGElement, unknown, HTMLElement, any>;  // main svg element that groups are all appended to
  line: d3.Line<ISparklineChartColorizedData>; // line graph of data points
  margin: { top: number; right: number; bottom: number; left: number };
  x: ScaleTime<number, number>;  // x-scale
  y: ScaleLinear<number, number>;  // y-scale
  g: Selection<SVGGElement, unknown, HTMLElement, any>;
  @Input() uniqueId: string;

  private config$ = new ReplaySubject(1);

  private _config: ISparklineChartColorizedConfig;

  get config(): ISparklineChartColorizedConfig {
    return this._config;
  }

  @Input() set config(config: ISparklineChartColorizedConfig) {
    const configData = this.processData(config);
    this._config = configData;
    this.config$.next(configData);
  };

  @Input() boundaries: ISparklineChartColorizedBoundaries;
  @Input() uniqueIdentifier?: string = '';
  @Output() sectionClicked: EventEmitter<any> = new EventEmitter();

  constructor(private sidebarService: SidebarService) {
  }

  private resize$ = new Subject();
  private destroy$ = new Subject();

  ngAfterViewInit(): void {
    this.initCharts();
  }

  private initCharts(): void {
    // We need to wait until the sidebar has finished resizing to draw the chart with correct size
    this.config$
      .pipe(
        delay(300),
        switchMap(() => this.sidebarService.isClosed),
        take(1),
        switchMap(() => this.chartRedrawSubscription()),
      )
      .subscribe(() => this.createChart());
  }

  private chartRedrawSubscription(): Observable<any> {
    return merge(this.resize$, this.sidebarService.isClosed, this.config$)
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$),
      );
  }

  processData(config: ISparklineChartColorizedConfig): ISparklineChartColorizedConfig {
    // Typically we just need a copy of this data (i.e. move data from this.config to this.chartConfig)
    // But we have an opportunity to manipulate the data here if needed. We are scaling the data up by 20
    // and will later leave gaps in the charting "2" wide to create a more visually appealing chart
    const SCALE = 20;

    return {
      data: [...config.data.map(d => ({ sequence: d.sequence + 1, value: d.value }))]
        .reduce((acc: ISparklineChartColorizedData[], item: ISparklineChartColorizedData) => {
          const sequence = item.sequence * SCALE;
          const nextSequence = sequence + 2;
          const value = item.value;

          acc.push({ sequence, value });
          acc.push({ sequence: nextSequence, value });

          return acc;
        }, [])
    };
  }

  createChart(): void {
    this.uniqueIdentifier = `sparkline-${this.uniqueId}`;
    if (!this.uniqueId) {
      console.error('Unique identifier is required for sparkline chart');
      return;
    }

    this.createMargins();
    this.createSvg();
    this.createScales();

    this.createIndividualLines();
    this.createDots();
    this.createThresholds();
  }

  createMargins() {
    this.margin = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    };
  }

  createSvg(): void {
    const svgContainer = `.svg-container${this.uniqueIdentifier !== undefined ? '-' + this.uniqueIdentifier : ''}`;

    this.container = d3.select(svgContainer).html('');
    this.container = d3.select(svgContainer);
    if (this.container.empty()) { // If selection is empty, any methods called on it will result in error
      return;
    }

    this.svgHeight = parseInt(this.container.style('height'));
    this.svgWidth = parseInt(this.container.style('width'));

    this.svg = this.container.append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    this.g = this.svg.append('g');
  }

  createScales(): void {
    const rangeXStart = 2;
    const rangeXEnd = this.svgWidth - this.margin.left - this.margin.right - 2;
    const rangeYStart = this.svgHeight - this.margin.top - this.margin.bottom;
    const rangeYEnd = 0;

    this.x = d3.scaleTime()
      .rangeRound([rangeXStart, rangeXEnd]);  // range is width of graph

    this.y = d3.scaleLinear()
      .rangeRound([rangeYStart, rangeYEnd]);  // range is height of graph

    const allValuesAreZero = (this.config.data as ISparklineChartColorizedData[]).every(p => p.value === 0); // checks to see if values are all zero

    if (allValuesAreZero) {
      this.config.data.map((p: ISparklineChartColorizedData) => ({ ...p, value: 0.1 })); //in order to see the vertical lines we cant plot straight zeros so we return a new array of points with a value of one
    }

    this.x.domain(d3.extent(this.config.data, (d: any) => d.sequence)); // add domain to time scale to display in sequence
    this.y.domain([0, allValuesAreZero ? 5 : d3.max(this.config.data, (d: any) => d.value * 1.05)]);  // add domain to value scale for min/max values
  }

  createIndividualLines() {
    if (!this.g) {
      console.error('Failed to create SVG group element');
      return;
    }

    // 1*2 --- 3*4 --- 5*6 --- 7*8
    // (config.data.length - 2) / 2 = number of sections between points

    const sectionCount = (this.config.data.length - 2) / 2;
    for (let i = 1; i <= sectionCount; i++) {

      const arrIndexFirstPoint = i * 2 - 1;
      const arrIndexSecondPoint = i * 2;
      const dataSlice = this.config.data.slice(arrIndexFirstPoint, arrIndexSecondPoint + 1);

      // interpret data point 2 if boundaries are defined
      const interpretation = this.interpretValue(dataSlice[1].value);

      this.line = d3.line<ISparklineChartColorizedData>()
        .x((d: any) => this.x(d.sequence))
        .y((d: any) => this.y(d.value));

      this.g.append('path')
        .attr('class', `colorized-sparkline-line-chart colorized-sparkline-path${interpretation}`)
        .attr('d', this.line(dataSlice));
    }
  }

  createDots() {
    for (let i = 1; i < this.config.data.length; i += 2) {
      const { sequence, value } = this.config.data[i];
      const interpretation = this.interpretValue(value);

      const dot = d3.arc()
        .innerRadius(0)
        .outerRadius(1)
        .startAngle(0)
        .endAngle(2 * Math.PI);

      this.g.append('path')
        .attr('d', dot)
        .attr("transform", d => `translate(${this.x(sequence - 1)},${this.y(value)})`)
        .attr('class', `sparkline-dot colorized-sparkline-path${interpretation}`);

      const wrap = d3.arc()
        .innerRadius(3)
        .outerRadius(4)
        .startAngle(0)
        .endAngle(2 * Math.PI);

      this.g.append('path')
        .attr('d', wrap)
        .attr("transform", d => `translate(${this.x(sequence - 1)},${this.y(value)})`)
        .attr('class', `sparkline-dot-wrap`);
    }
  }

  createThresholds(): void {
    if (this.boundaries) {
      this.createThreshold(this.boundaries.warnThreshold, 'warn-threshold');
      this.createThreshold(this.boundaries.failThreshold, 'fail-threshold');
    }
  }

  createThreshold(value: number, className: string) {
    if (!this.y || !this.g) {
      return;
    }

    // Calculate the y position for the line based on the input value
    const yPosition = this.y(value);

    // Append the line to the group element
    this.g.append('line')
      .attr('x1', 0)
      .attr('y1', yPosition)
      .attr('x2', this.svgWidth)
      .attr('y2', yPosition)
      .attr('stroke', 'black')
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '5,5')
      .attr('class', className);
  }

  private interpretValue(value: number): string {
    if (value < this.boundaries.warnThreshold) {
      return '-pass';
    } else if (value < this.boundaries.failThreshold) {
      return '-warn';
    } else {
      return '-fail';
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
