import { Account, IUser } from '../../moonbeamModels';
import { IFolder, IUserFolder } from '../folder/foldersApiService';
import { IAccountUsage } from './account.models';
import { environment } from '@app/environments/environment';
import { IPrimaryTagSettings } from '../account-settings/manage-tags/manage-tags.models';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { catchError, finalize, share, tap } from 'rxjs/operators';
import { Observable, of, Subject, throwError, throwError as observableThrowError } from 'rxjs';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { userIsGuest } from '@app/authUtils';
import { ICheckDuplicatesValidator } from '@app/components/shared/validators/op-validators';
import { IProductLimits } from '../admin-portal/admin-accounts.service';
import { CacheApiResponse } from '../core/decorators/cache-api-response.decorator';

const resetUsersCache: Subject<any> = new Subject();
const accountCacheReset: Subject<any> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class AccountsService implements ICheckDuplicatesValidator {
  protected responseCache = new Map();
  private getUserPending: Observable<IUser>;

  root: string = environment.apiUrl;
  v3Root: string = environment.apiV3Url;
  apiUsers: string = this.root + 'users';
  currentUser: IUser;

  constructor(
    private apiService: ApiService,
    private cacheResetService: CacheResetService,
    private authenticationStorageService: AuthenticationStorageService,
  ) {
    this.cacheResetService.reset$.subscribe(_ => {
      this.responseCache.clear();
      resetUsersCache.next();
    });

    this.getUser().subscribe(user => this.currentUser = user);
  }

  /**
   * this is deprecated!
   *
   * use getAccountPreview() in authentication.service.ts instead
   *
   */
  getAccount(): Observable<Account> {
    return this.apiService.get<Account>(this.root + 'account');
  }

  @CacheApiResponse({ resetCache: accountCacheReset })
  getAccountById(accountId: number): Observable<Account> {
    return this.apiService.get<Account>(`${this.v3Root}accounts/${accountId}`);
  }

  updateAccountOverages(accountId: number, overages: boolean) {
    return this.apiService.put(`${this.v3Root}accounts/${accountId}/allow-overages`, { allowOverages: overages });
  }

  @CacheApiResponse({ resetCache: resetUsersCache })
  getUsers(): Observable<IUser[]> {
    return this.apiService.get<IUser[]>(`${this.root}users`);
  }

  resetUserCache() {
    const authenticationData = this.authenticationStorageService.get();

    if (authenticationData) {
      const requestUrl = `${this.apiUsers}/${authenticationData?.id}`;

      this.responseCache.delete(requestUrl);
    }
  }

  getUser(): Observable<IUser> {
    const authenticationData = this.authenticationStorageService.get();
    if (!authenticationData) return observableThrowError('not authenticated');
    if (this.getUserPending) return this.getUserPending;

    const requestUrl = `${this.apiUsers}/${authenticationData.id}`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    this.getUserPending = this.apiService.get(requestUrl).pipe(
      tap<IUser>(user => this.responseCache.set(requestUrl, user)),
      share(),
      finalize(() => this.getUserPending = null)
    );

    this.getUserPending.subscribe(user => this.responseCache.set(requestUrl, user));

    return this.getUserPending;
  }

  getUserById(userId: number): Promise<IUser> {
    const requestUrl = `${this.root}users/${userId}`;

    const cached = this.responseCache.get(requestUrl);

    if (cached) return Promise.resolve(cached);

    return this.apiService.get<IUser>(requestUrl).toPromise();
  }

  checkDuplicates(username: string): Promise<any> {
    return this.apiService.post<any>(this.root + 'users/duplicate', username).toPromise();
  }

  createUser(user: IUser): Promise<IUser> {
    return this.apiService.post<IUser>(this.root + 'users', user).toPromise();
  }

  createUserWithAccount(accountId: number, user: IUser): Promise<IUser> {
    return this.apiService.post<IUser>(`${this.root}admin/accounts/${accountId}/users`, user).toPromise();
  }

  updateUser(user: IUser): Promise<IUser> {
    resetUsersCache.next();
    return this.apiService.put<IUser>(this.root + 'users/' + user.id, user).toPromise();
  }

  updateUserWithAccount(accountId: number, user: IUser): Promise<IUser> {
    return this.apiService.put<IUser>(`${this.root}admin/accounts/${accountId}/users/${user.id}`, user).toPromise();
  }

  getUserFolders(userId: number): Promise<IUserFolder[]> {
    return this.apiService.get<IUserFolder[]>(`${this.root}users/${userId}/folders`).toPromise();
  }

  updateUserFolders(userId: number, folderIds: Array<number>): Promise<IFolder> {
    return this.apiService.put<IFolder>(`${this.root}users/${userId}/folders`, folderIds).toPromise();
  }

  addUserFolders(userId: number, folderIds: Array<number>): Promise<IFolder> {
    return this.apiService.post<IFolder>(`${this.root}users/${userId}/folders`, folderIds).toPromise();
  }

  deleteUser(userId: number): Promise<any> {
    return this.apiService.delete<any>(`${this.root}users/${userId}`).toPromise();
  }

  changePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    return this.apiService.put<any>(`${this.root}users/password`, { old: oldPassword, newPassword: newPassword });
  }

  changePasswordWithAccount(accountId: number, userId: number, newPassword: string): Observable<boolean> {
    return this.apiService.put<any>(`${this.root}admin/accounts/${accountId}/users/${userId}/password`, newPassword)
      .pipe(
        catchError(error => {
          console.log(error);
          if (error.errorCode === 10) {
            const message = error.message;
          }

          return throwError(error);
        })
      );
  }

  changePasswordByAdmin(userId: number, newPassword: string): Observable<boolean> {
    return this.apiService.put<boolean>(`${this.root}users/${userId}/password`, { newPassword: newPassword });
  }

  changePasswordByAdminWithAccount(accountId: number, userId: number, newPassword: string): Observable<boolean> {
    return this.apiService.put<boolean>(`${this.root}admin/accounts/${accountId}/users/${userId}/password`, newPassword);
  }

  forgotPassword(username: string): Promise<any> {
    return this.apiService.post<any>(`${this.root}forgot-password`, username).toPromise();
  }

  // not used
  getUsage(accountId: number): Promise<IAccountUsage> {
    return this.apiService.get<IAccountUsage>(this.root + `account/${accountId}/usage`).toPromise();
  }

  getComparisonAccountSettings(accountId?: number): Promise<IProductLimits> {
    return this.apiService.get<IProductLimits>(this.v3Root + this.accountSettingsUrlPart(accountId)).toPromise();
  }

  updateComparisonAccountSettings(maxComparisonTags: number, accountId?: number): Promise<IProductLimits> {
    return this.apiService.put<IProductLimits>(this.v3Root + this.accountSettingsUrlPart(accountId), { maxComparisonTags: maxComparisonTags }).toPromise();
  }

  getComparisonAccountTags(): Promise<IPrimaryTagSettings[]> {
    return this.apiService.get<IPrimaryTagSettings[]>(this.v3Root + 'comparisons/account-settings/tags').toPromise();
  }

  updateComparisonAccountTag(tagId: number, settings): Promise<IPrimaryTagSettings[]> {
    return this.apiService.put<IPrimaryTagSettings[]>(this.v3Root + 'comparisons/account-settings/tags/' + tagId, settings).toPromise();
  }

  userIsReadOnly(): boolean {
    return userIsGuest(this.currentUser);
  }

  private accountSettingsUrlPart(accountId?: number): string {
    return accountId ? `comparisons/accounts/${accountId}/settings` : 'comparisons/account-settings';
  }

  resetAccountCache(): void {
    accountCacheReset.next();
  }
}
