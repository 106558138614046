<div class="mat-mdc-option flex-col"
     [ngClass]="{'disabled': disabled}" 
     (click)="!inputMode && !disabled && inputMode = true">
  <div class="flex-stretch">
    <div *ngIf="!inputMode">Create Custom</div>
    <mat-form-field *ngIf="inputMode" floatLabel="never" class="form-input">
      <input matInput
             placeholder="Type variable"
             op-selector="custom-variable-name"
             [ngModelOptions]="{ standalone: true }"
             [(ngModel)]="model"
             (keydown)="$event.stopPropagation()"
             (keyup.enter)="ready && add()"
             (ngModelChange)="ready = isUnique(model)"
             [disabled]="disabled"
      />
    </mat-form-field>
  </div>
  
  <mat-icon *ngIf="!ready" op-selector="add-custom-variable-action">
    add_circle_outline
  </mat-icon>
  <mat-icon *ngIf="ready"
            class="add-icon"
            (click)="add()"
  >add_circle</mat-icon>
</div>
