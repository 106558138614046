import { Route } from '@angular/router';
import { NoReadOnlyGuard } from '../core/permissions.guard';
import { NotificationCenterComponent } from './notification-center.component';
import { NotificationCenterPaths, NotificationCenterNames } from './notification-center.constants';

export const NotificationCenterState: Route = {
  path: NotificationCenterPaths.base,
  loadChildren: () => import('./notification-center.module').then(mod => mod.NotificationCenterModule),
};

export const NotificationCenterRoutes: Route = {
  path: '',
  canLoad: [ NoReadOnlyGuard ],
  component: NotificationCenterComponent,
  data: {
    stateName: NotificationCenterNames.base,
    title: 'Notification Center',
    betaLabel: false,
  },
};
