import { CacheResetService } from './../core/services/cache-reset.service';
import { allComparisonsPlaceholder } from './ror-comparison-report.contants';
import { IMissingVariableValue } from './ror-comparison-value-presence-pages/ror-comparison-value-presence-pages.models';
import { IMissingTagVariable } from './ror-comparison-variable-presence-pages/ror-comparison-variable-presence-pages.models';
import { ITagsVariablesResults } from './ror-comparison-variable-presence/ror-comparison-variable-presence.models';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { Observable } from 'rxjs';
import { IComparisonPreview, IComparisonRun } from '../comparison-library/comparison-library.model';
import { ApiService } from '../core/services/api.service';
import { IPage } from './ror-comparison-report.models';
import { ITagPresenceDiffs } from './ror-comparison-tag-presence/ror-comparison-tag-presence.models';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RorComparisonReportService {

  private readonly basePath = environment.apiV3Url + 'comparisons';

  private pagesCache: IPage[] = [];
  private runsCache: IComparisonRun[] = [];

  constructor(private apiService: ApiService, private cacheResetService: CacheResetService) {
    this.cacheResetService.reset$.subscribe(_ => {
      this.pagesCache = [];
      this.runsCache = [];
    });
  }

  getAssignedComparisonRuns(auditId: number, runId: number): Observable<IComparisonRun[]> {
    return this.apiService.get(`${this.basePath}/audits/${auditId}/runs/${runId}/assigned-comparison-runs`);
  }

  getAssignedComparisons(auditId: number): Observable<IComparisonPreview[]> {
    return this.apiService.get(`${this.basePath}/audits/${auditId}/assigned-comparisons/run-over-run`);
  }

  reprocessAssignedComparisons(auditId: number, runId: number, modifiedOnly?: Boolean, specificComparisonIds?: number[]): Observable<{ comparisonSnapshots: number[] }> {
    const modifiedOnlyParam = typeof modifiedOnly === 'boolean' ? `?modifiedOnly=${modifiedOnly}` : '';
    const requestBody = {
      comparisonIds: specificComparisonIds || []
    };
    return this.apiService.post(`${this.basePath}/audits/${auditId}/runs/${runId}/reprocess${modifiedOnlyParam}`, requestBody);
  }

  getTagPresence(itemId: number, runId: number, comparisonSnapshotId: number): Observable<ITagPresenceDiffs[]> {
    let params = new HttpParams();
    if (comparisonSnapshotId !== allComparisonsPlaceholder.comparisonSnapshotId) params = params.set('comparisonSnapshotId', comparisonSnapshotId.toString());
    return this.apiService.get(`${this.basePath}/audits/${itemId}/runs/${runId}/tag-presence`, { params });
  }

  getVariablePresence(itemId: number, runId: number, comparisonSnapshotId: number): Observable<ITagsVariablesResults[]> {
    let params = new HttpParams();
    if (comparisonSnapshotId !== allComparisonsPlaceholder.comparisonSnapshotId) params = params.set('comparisonSnapshotId', comparisonSnapshotId.toString());
    return this.apiService.get(`${this.basePath}/audits/${itemId}/runs/${runId}/variable-presence`, { params });
  }

  getValuePresence(itemId: number, runId: number, comparisonSnapshotId: number): Observable<ITagsVariablesResults[]> {
    let params = new HttpParams();
    if (comparisonSnapshotId !== allComparisonsPlaceholder.comparisonSnapshotId) params = params.set('comparisonSnapshotId', comparisonSnapshotId.toString());
    return this.apiService.get(`${this.basePath}/audits/${itemId}/runs/${runId}/variable-values`, { params });
  }

  getPagesMissingFromBaseline(itemId: number, 
                              runId: number, 
                              comparisonSnapshotId: number,
                              offset = 0,
                              limit = 100): Observable<IPage[]> {
    let params = new HttpParams();
    if (comparisonSnapshotId !== allComparisonsPlaceholder.comparisonSnapshotId) {
      params = params.set('comparisonSnapshotId', comparisonSnapshotId.toString());
    }
    params = params.set('offset', offset.toString())
                   .set('limit', limit.toString());

    return this.apiService.get(
      `${this.basePath}/audits/${itemId}/runs/${runId}/pages-missing-from-baseline`, 
      { params }
    );
  }

  getTagPresencePages(itemId: number, runId: number, comparisonSnapshotId: number, tagId: number, tagPresenceDiff: number): Observable<IPage[]> {
    let params = new HttpParams();
    if (comparisonSnapshotId !== allComparisonsPlaceholder.comparisonSnapshotId) params = params.set('comparisonSnapshotId', comparisonSnapshotId.toString());
    return this.apiService.get(`${this.basePath}/audits/${itemId}/runs/${runId}/tag-presence/tags/${tagId}/presence-diffs/${tagPresenceDiff}/pages`, { params });
  }

  getVariablePresencePages(itemId: number, runId: number, tagId: number, variableName: string, comparisonSnapshotId: number): Observable<IMissingTagVariable[]> {
    let params = new HttpParams();
    if (comparisonSnapshotId !== allComparisonsPlaceholder.comparisonSnapshotId) params = params.set('comparisonSnapshotId', comparisonSnapshotId.toString());
    return this.apiService.get(`${this.basePath}/audits/${itemId}/runs/${runId}/variable-presence/tags/${tagId}/variables/${variableName}`, { params });
  }

  getValuePresencePages(itemId: number, runId: number, tagId: number, variableName: string, comparisonSnapshotId: number): Observable<IMissingVariableValue[]> {
    let params = new HttpParams();
    if (comparisonSnapshotId !== allComparisonsPlaceholder.comparisonSnapshotId) params = params.set('comparisonSnapshotId', comparisonSnapshotId.toString());
    return this.apiService.get(`${this.basePath}/audits/${itemId}/runs/${runId}/variable-values/tags/${tagId}/variables/${variableName}`, { params });
  }

  excludeVariable(tagId: number, variableName: string, comparisonSnapshotId?: number): Observable<void> {
    const comparisonsRuns = this.getComparisonsRunsCache();
    let comparisonId: number;
    if (comparisonSnapshotId) {
      const run = comparisonsRuns.find(r => r.comparisonSnapshotId === comparisonSnapshotId);
      comparisonId = run.comparisonId;
    }
    let params = new HttpParams();
    if (comparisonId) params = params.set('comparisonId', comparisonId.toString());
    return this.apiService.delete(`${this.basePath}/datasources/tags/${tagId}/variables/${variableName}`, { params });
  }

  getTagPresencePagesCache(): IPage[] {
    return this.pagesCache;
  }
  setTagPresencePagesCache(pages: IPage[]): void {
    this.pagesCache = pages;
  }

  getComparisonsRunsCache(): IComparisonRun[] {
    return this.runsCache;
  }
  setComparisonsRunsCache(runs: IComparisonRun[]): void {
    this.runsCache = runs;
  }
}
