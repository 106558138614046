import { ComponentChange, ComponentChanges } from '@app/models/commons';
import { Component, Input, OnInit, EventEmitter, Output, OnChanges, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ILabel } from '@app/components/shared/services/label.service';
import { ISelectedItem, IRuleSelection } from './rule-selector.models';
import { IRule, IRulePreview } from '@app/components/rules/rules.models';
import { Datasource, IDatasource } from 'ngx-ui-scroll';

type RuleMap = {[ruleId: number]: IRulePreview};
type LabelMap = {[ruleId: number]: number[]};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-selector',
  templateUrl: './rule-selector.component.html',
  styleUrls: ['./rule-selector.component.scss']
})
export class RuleSelectorComponent implements OnInit, OnChanges {

  @Input() rules: IRulePreview[];
  @Input() labels: ILabel[];
  @Input() selectedItemsAndRules: IRuleSelection = IRuleSelection.createDefault();
  @Input() hideTitle = false;
  @Input() isReadOnly: boolean = false;

  @Output() selectionChanged = new EventEmitter<IRuleSelection>();
  @Output() openRuleCreation = new EventEmitter<void>();
  @Output() isGroupedByChanged = new EventEmitter<boolean>();
  @Output() openRuleEditor: EventEmitter<IRule> = new EventEmitter();

  searchText = '';
  labelRules: {[ labelId: number ]: IRulePreview[]};
  labelsById: {[ labelId: number ]: ILabel};
  rulesById: RuleMap;
  labelsByRuleId: LabelMap;
  selectedItems: ISelectedItem[];
  availableItemsDataSource: MatTableDataSource<IRulePreview>;
  rulesToBeAssigned = 0;
  ruleLabelIdsMap: {[ruleId: number]: ILabel[]};
  selectedItemsUIScrollDatasource: IDatasource;
  availableItemsUIScrollDatasource: IDatasource;
  availableItemsData: IRulePreview[];
  availableItemsMinIndex = 0;

  constructor(
    private cdr: ChangeDetectorRef,
  ){
    this.availableItemsData = [];
  }

  ngOnInit() {
    if (!this.selectedItemsAndRules) {
      this.selectedItemsAndRules = IRuleSelection.createDefault();
    }
    this.buildRulesAndLabels();
  }

  ngOnChanges(changes: ComponentChanges<RuleSelectorComponent>) {
    if (this.wasInputChanged(changes.selectedItemsAndRules) || this.wasInputChanged(changes.rules) || this.wasInputChanged(changes.labels)) {
      if (changes.selectedItemsAndRules && changes.selectedItemsAndRules.currentValue === null) {
        this.selectedItemsAndRules = IRuleSelection.createDefault();
      }

      this.updateView();
    }
  }

  public updateView() {
    this.alphaSortRules();
    this.buildRulesAndLabels();
  }

  setSelectedItemsVS() {
    this.selectedItemsUIScrollDatasource = new Datasource({
      get: (index, count, success) => {
        const start = Math.max(index, 0);
        const end = index + count - 1;
        success(start <= end
          ? this.selectedItems.slice(start, end + 1)
          : []
        );
      },
      settings: {
        startIndex: 0,
        minIndex: 0,
        bufferSize: 30,
      },
    });
  }

  setAvailableItemsVS() {
    this.availableItemsData = [...this.availableItemsDataSource.filteredData];
    this.availableItemsUIScrollDatasource = new Datasource({
      get: (index, count, success) => {
        const start = index;
        const end = index + count - 1;
        success(start <= end
          ? this.availableItemsDataSource.filteredData.slice(start, end + 1)
          : []
        );
      },
      settings: {
        startIndex: 0,
        minIndex: 0,
        bufferSize: 30,
      },
    });
  }

  alphaSortRules(): void {
    this.rules.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
  }

  buildRulesAndLabels() {
    this.rulesById = this.buildRuleMap(this.rules);
    this.labelRules = this.buildLabelMap(this.rulesById, this.labels);
    this.labelsByRuleId = this.buildRuleLabelsMap(this.labels);
    this.labelsById = this.buildLabelByIdMap(this.labels);
    this.buildRuleLabelIdsMap(this.labels);
    this.selectedItems = this.selectedItemsAndRules.selectedItems.length > 0
      ? this.selectedItemsAndRules.selectedItems
      : this.selectedItemsAndRules.selectedRuleIds.map(ruleId => ({ rule: this.rulesById[ruleId] }));
    this.setAssignedRules(this.selectedItems);

    this.availableItemsDataSource = new MatTableDataSource(this.removeSelectedItemsAndRules(this.rules));
    this.availableItemsDataSource.filterPredicate = this.filterAllItemsList;
    this.setAvailableItemsVS();
    this.setSelectedItemsVS();
  }

  async searchChanged() {
    const searchTerm = this.searchText.trim().toLowerCase();
    const nonSelectedItems = this.rules?.filter(rule => {
      // Filter out any rules that are already selected
      return !this.selectedItems?.find(selectedItem => selectedItem.rule.id === rule.id);
    });
    // Filter out any rules that don't have a name that matches the search term
    this.availableItemsDataSource.data = nonSelectedItems?.filter(rule => {
      let labelMatches = (rule as IRule)?.labels?.some((label) => {
        return label.name?.toLowerCase()?.includes(searchTerm);
      });
      const nameMatches = rule?.name?.toLowerCase()?.includes(searchTerm);
      return labelMatches || nameMatches;
    });

    await this.availableItemsUIScrollDatasource.adapter.relax();
    await this.availableItemsUIScrollDatasource.adapter.reload();
  }

  createNewRule() {
    this.openRuleCreation.emit();
  }

  async removeAllSelectedItems() {
    const noneAvailable = this.availableItemsDataSource.data.length === 0;
    this.selectedItems = [];
    if (noneAvailable) {
      this.availableItemsData = [];
      this.setAssignedRules(this.selectedItems);
      setTimeout(async () => {
        this.availableItemsDataSource = new MatTableDataSource(this.removeSelectedItemsAndRules(this.rules));
        this.availableItemsDataSource.filterPredicate = this.filterAllItemsList;
        this.setAvailableItemsVS();
      });
    } else {
      this.setAssignedRules(this.selectedItems);

      this.availableItemsDataSource.data = [...this.rules];
      await this.availableItemsUIScrollDatasource.adapter.reload();
      await this.selectedItemsUIScrollDatasource.adapter.reload();
    }

    this.cdr.detectChanges();
  }

  async addAll() {
    let itemsToSelect = <ISelectedItem[]> this.availableItemsDataSource.filteredData.map(rule => ({ rule }));

    this.addSelectedItems(itemsToSelect);

    this.availableItemsDataSource.data = [];
    await this.availableItemsUIScrollDatasource.adapter.reload();
    await this.addToSelectedItems(itemsToSelect);
  }

  async addRule(rule: IRulePreview) {
    if (this.canAddRule(rule)) {
      this.addSelectedItems({ rule });
      await this.removeFromAvailableItemsById(rule.id);
      await this.addToSelectedItems([{ rule }]);
    }
  }

  async addToSelectedItems(items: ISelectedItem[]) {
    await this.selectedItemsUIScrollDatasource.adapter.append(items);
  }

  removeFromSelectedItemsById(id: number) {
    const indexToRemove = this.selectedItems.findIndex(({ rule, label }) => rule && rule.id === id || label && label.id === id);
    this.selectedItems.splice(indexToRemove, 1);
  }

  removeFromAvailableItemsDatasource(toRemove: number, byIndex = false) {
    const indexToRemove = byIndex
      ? toRemove
      : this.availableItemsData.findIndex(({ id }) => id === toRemove);
    if (indexToRemove >= 0) {
      this.availableItemsData.splice(indexToRemove, 1);
    }
  }

  removeFromAvailableItemsFilteredItems(toRemove: number, byIndex = false) {
    const indexToRemove = byIndex
      ? toRemove - Math.min(this.availableItemsMinIndex, 0) // shift!
      : this.availableItemsDataSource.filteredData.findIndex(({ id }) => id === toRemove);
    if (indexToRemove >= 0) {
      this.availableItemsDataSource.filteredData.splice(indexToRemove, 1);
    }
  }

  async removeFromAvailableItemsById(id: number) {
    await this.availableItemsUIScrollDatasource.adapter.relax();
    this.removeFromAvailableItemsDatasource(id);
    this.removeFromAvailableItemsFilteredItems(id);
    await this.availableItemsUIScrollDatasource.adapter.remove({
      predicate: ({ data }) => (data as IRulePreview|ILabel).id === id
    });
  }

  async removeSelectedItem(item: ISelectedItem) {
    const noItemsInReceivingList = this.availableItemsDataSource.data.length === 0;

    // Special handling When inserting the item into the Scroll Datasource if the receiving list is empty. We re-initialize the list because removing the last item causes the VS adapter to drop into a state where the buffer values are set to null and can't be inserted directly again.
    if (noItemsInReceivingList) {
      this.availableItemsData = [];
      setTimeout(async () => {
        this.availableItemsDataSource = new MatTableDataSource([item.rule]);
        this.availableItemsDataSource.filterPredicate = this.filterAllItemsList;
        this.setAvailableItemsVS();
        await this.availableItemsUIScrollDatasource.adapter.reload();

        // Remove the item from selected items
        this.removeFromSelectedItemsById(item.rule.id);
        this.setAssignedRules(this.selectedItems);
        // Remove the item from virtual scroll list
        await this.selectedItemsUIScrollDatasource.adapter.remove({
          predicate: ({ data }) => (data as ISelectedItem).rule.id === item.rule.id
        });
      });
    } else {
      // Insert the rule into the allItemsDataSource
      this.availableItemsDataSource.data = [...this.availableItemsDataSource.data, item.rule];
      this.availableItemsDataSource.filteredData.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

      const insertIndex = this.availableItemsDataSource.filteredData.findIndex(({ id }) => id === item.rule.id);

      if (insertIndex === 0) {
        await this.availableItemsUIScrollDatasource.adapter.insert({
          beforeIndex: insertIndex,
          items: [item.rule]
        });
      } else {
        await this.availableItemsUIScrollDatasource.adapter.insert({
          afterIndex: insertIndex - 1,
          items: [item.rule]
        });
      }

      // Remove the item from selected items
      this.removeFromSelectedItemsById(item.rule.id);
      this.setAssignedRules(this.selectedItems);
      // Remove the item from virtual scroll list
      await this.selectedItemsUIScrollDatasource.adapter.remove({
        predicate: ({ data }) => (data as ISelectedItem).rule.id === item.rule.id
      });
    }

    this.cdr.detectChanges();
  }

  private wasInputChanged<T>(input: ComponentChange<T, keyof T>): boolean {
    return input && input.currentValue !== input.previousValue;
  }

  private buildRuleMap(rules: IRulePreview[]): RuleMap {
    return rules.reduce((aggregate: RuleMap, rule: IRulePreview) => {
      aggregate[rule.id] = rule;
      return aggregate;
    }, {});
  }

  // Returns a mapping of {rule id : labelIds[]}
  private buildRuleLabelIdsMap(labels: ILabel[]): {[ ruleId: number]: ILabel[]} {
    this.ruleLabelIdsMap = {};
    labels.forEach((label: ILabel) => {
      if (Array.isArray(label.rules)) {
        label.rules.forEach((ruleId: number) => {
          if (!this.ruleLabelIdsMap[ruleId]) this.ruleLabelIdsMap[ruleId] = [];
          this.ruleLabelIdsMap[ruleId].push(this.labelsById[label.id]);
        });
      }
    });

    return this.ruleLabelIdsMap;
  }

  private buildLabelByIdMap(labels: ILabel[]) {
    return labels.reduce((labelMap, label) => {
      labelMap[label.id] = label;
      return labelMap;
    }, {});
  }

  private buildRuleLabelsMap(labels: ILabel[]): LabelMap {
    let ruleLabelsMap = {};
    labels.forEach((label: ILabel) => {
      // Loop over each label and push label id into labelMap[ruleId]
      label.rules.forEach((ruleId: number) => {
        if (!ruleLabelsMap[ruleId]) ruleLabelsMap[ruleId] = [];
        if (!ruleLabelsMap[ruleId].includes(label.id)) ruleLabelsMap[ruleId].push(label.id);
      });
    });

    return ruleLabelsMap;
  }

  private buildLabelMap(rulesById: RuleMap, labels: ILabel[]): {[ labelId: number ]: IRulePreview[]} {
    return labels.reduce((aggregate: {[ labelId: number ]: IRulePreview[]}, label: ILabel) => {
      if (Array.isArray(label.rules)) {
        aggregate[label.id] = label.rules.map((ruleId) => rulesById[ruleId]).filter(rule => !!rule);
      }
      return aggregate;
    }, {});
  }

  private filterAllItemsList = (data: IRulePreview | ILabel, filter: string): boolean => {
    return data.name.toLowerCase().indexOf(filter) != -1 || this.labelMatchesFilter(data, filter);
  }

  private labelMatchesFilter(data, filter: string): boolean {
    let match = false;
    data?.labels?.forEach(label => {
      if (!match) match = label.name.toLowerCase().indexOf(filter) != -1;
    });

    return match;
  }

  private calculateAssignedRules(selectedItems: ISelectedItem[]): number[] {
    let assignedRules = new Set<number>();
    selectedItems.forEach(item => {
      if (item.rule) {
        assignedRules.add(item.rule.id);
      } else if (item.label) {
        item.label.rules.forEach(id => assignedRules.add(id));
      }
    });

    return Array.from(assignedRules);
  }

  private canAddRule(rule: IRulePreview): boolean {
    return this.selectedItems.every(item => {
      return !(item.rule && item.rule.id === rule.id);
    });
  }

  private removeSelectedItemsAndRules(rules: IRulePreview[]): IRulePreview[] {
    return rules.filter(rule => this.canAddRule(rule));
  }

  private setAssignedRules(selectedItems: ISelectedItem[]) {
    this.selectedItemsAndRules.selectedItems = selectedItems;
    this.selectedItemsAndRules.selectedRuleIds = this.calculateAssignedRules(selectedItems);
    this.rulesToBeAssigned = this.selectedItemsAndRules.selectedRuleIds.length;
    this.selectionChanged.emit(this.selectedItemsAndRules);
  }

  private addSelectedItems(items: ISelectedItem | ISelectedItem[]) {
    if (!Array.isArray(items)) {
      items = [items];
    }

    this.selectedItems = this.selectedItems.concat(items);
    this.setAssignedRules(this.selectedItems);
    this.cdr.detectChanges();
  }
}
