import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpDeleteItemWarningComponent } from './op-delete-item-warning.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { OpModalModule } from '../op-modal/op-modal.module';

@NgModule({
  declarations: [
    OpDeleteItemWarningComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatIconModule,
    OpModalModule,
  ]
})
export class OpDeleteItemWarningModule {}
