import {Names} from '@app/moonbeamConstants';
import {IApiService} from '../../api/apiService';
import * as angular from 'angular';
import { environment } from '@app/environments/environment';


export interface IGeoLocation {
    id: number;
    name: string;
    label: string;
    countryCode: string;
  }

  export class LocationsService {
    static $inject = [
      '$http',
      Names.Services.api
    ];

    constructor(private $http: angular.IHttpService, private apiService: IApiService) {
    }

    root: string = environment.apiUrl;

    getAllLocations(): angular.IPromise<Array<IGeoLocation>> {
      return this.apiService.handleResponse(this.$http.get(this.root + 'locations'));
    }

  }


