<page-details-wrapper>
  <div class="drawer-content-wrapper" [class.no-filter-bar]="!showFilterBar">
    <audit-report-filter-bar *ngIf="showFilterBar" class="filters"></audit-report-filter-bar>
    <div class="report-wrapper"
        #reportWrapper
        (scroll)="onScroll($event)">
      <router-outlet (activate)="reportActivated($event)"></router-outlet>
    </div>
  </div>
</page-details-wrapper>
