import * as angular from 'angular';


export class FileHandoffController {
    fileHandoff: (FileList) => void;

    static $inject = [
      '$log'
    ];

    constructor(private $log: angular.ILogService) {
      this.$log.debug('handoff controller constructed');
    }

    onChangeHandler(event) {
      this.fileHandoff({fileList: event.target.files});
    }

  }


