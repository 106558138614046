<use-case-report-section>
  <use-case-widget [splitCard]="pagesScannedWidget.splitCard"
                   [opSelector]="pagesScannedWidget.opSelector"
                   [topText]="pagesScannedWidget.topText"
                   [topValue]="pagesScannedWidget.topValue"
                   [topTooltip]="pagesScannedWidget.topTooltip"
                   [topClickAction]="pagesScannedWidget.topClickAction"
  >
    <use-case-widget-body right [widget]="pagesScannedWidget">
    </use-case-widget-body>
  </use-case-widget>
</use-case-report-section>

<use-case-report-section [sectionIcon]="pagesSection.sectionIcon"
                         [sectionTitle]="pagesSection.sectionTitle"
>
  <use-case-widget [splitCard]="brokenPagesWidget.splitCard"
                   [opSelector]="brokenPagesWidget.opSelector"
                   [topText]="brokenPagesWidget.topText"
                   [topValue]="brokenPagesWidget.topValue"
                   [topTooltip]="brokenPagesWidget.topTooltip"
                   [topValueMeaning]="brokenPagesWidget.topValueMeaning"
                   [topIconName]="brokenPagesWidget.topIconName"
                   [topIconMeaning]="brokenPagesWidget.topIconMeaning"
                   [topClickAction]="brokenPagesWidget.topClickAction"
                   [bottomText]="brokenPagesWidget.bottomText"
                   [bottomValue]="brokenPagesWidget.bottomValue"
  >
    <use-case-widget-body right [widget]="brokenPagesWidget">
      <div class="broken-pages-widget-chart-container">
        <donut-chart-with-key [data]="brokenPagesWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'broken-pages-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
  </use-case-widget>

  <use-case-widget [splitCard]="averagePageLoadTimeWidget.splitCard"
                   [opSelector]="averagePageLoadTimeWidget.opSelector"
                   [topText]="averagePageLoadTimeWidget.topText"
                   [topValue]="averagePageLoadTimeWidget.topValue"
                   [topTooltip]="averagePageLoadTimeWidget.topTooltip"
                   [topValueMeaning]="averagePageLoadTimeWidget.topValueMeaning"
                   [topIconName]="averagePageLoadTimeWidget.topIconName"
                   [topIconMeaning]="averagePageLoadTimeWidget.topIconMeaning"
                   [topClickAction]="averagePageLoadTimeWidget.topClickAction"
                   [bottomText]="averagePageLoadTimeWidget.bottomText"
                   [bottomValue]="averagePageLoadTimeWidget.bottomValue"
  >
    <use-case-widget-body right [widget]="averagePageLoadTimeWidget">
      <div *ngIf="averagePageLoadTimeWidget.chartData" class="average-page-load-time-chart-container">
        <horizontal-bar-chart-rounded [data]="averagePageLoadTimeWidget.chartData"
                                      [uniqueIdentifier]="'average-page-load-time-chart'"
                                      [hideTooltip]="true"
        >
        </horizontal-bar-chart-rounded>
      </div>
    </use-case-widget-body>
  </use-case-widget>

  <use-case-widget [topText]="overviewLCPWidget.topText"
                   [opSelector]="overviewLCPWidget.opSelector"
                   [topValue]="overviewLCPWidget.topValue"
                   [topValueMeaning]="overviewLCPWidget.topValueMeaning"
                   [topIconName]="overviewLCPWidget.topIconName"
                   [topIconMeaning]="overviewLCPWidget.topIconMeaning"
                   [topTooltip]="overviewLCPWidget.topTooltip"
                   [topClickAction]="overviewLCPWidget.topClickAction"
                   [bottomText]="overviewLCPWidget.bottomText"
                   [bottomValue]="overviewLCPWidget.bottomValue"
                   [richTooltip]="true">
    <ng-container overlayTooltip>
      <op-web-vitals-widget-tooltip
        [tooltip]="EWebVitalsMetricType.LargestContentfulPaint">
      </op-web-vitals-widget-tooltip>
    </ng-container>
    <use-case-widget-body right [widget]="overviewLCPWidget">
      <div class="web-vitals-chart-wrapper" *ngIf="overviewLCPWidget.chartData">
        <op-web-vitals-chart
          [data]="overviewLCPWidget.chartData"
          [metricType]="overviewLCPWidget.metricType"
          [webVitalsAvailable]="true"
          [loading]="overviewLCPWidget.loading"
          [showTitle]="false"
          [displayValueFractionDigits]="3"
          [displayValueUnits]="''"
          [showLeftThresholdValue]="false"
          [showRightThresholdValue]="false"
          [displayValueFollowsCircle]="true"
          [biggerCircle]="true"
        ></op-web-vitals-chart>
      </div>
    </use-case-widget-body>
  </use-case-widget>

  <use-case-widget [splitCard]="pagesMissingQueryStringsWidget.splitCard"
                   [opSelector]="pagesMissingQueryStringsWidget.opSelector"
                   [topText]="pagesMissingQueryStringsWidget.topText"
                   [topValue]="pagesMissingQueryStringsWidget.topValue"
                   [topTooltip]="pagesMissingQueryStringsWidget.topTooltip"
                   [topClickAction]="pagesMissingQueryStringsWidget.topClickAction"
                   [bottomText]="pagesMissingQueryStringsWidget.bottomText"
                   [bottomValue]="pagesMissingQueryStringsWidget.bottomValue"
  >
    <use-case-widget-body right [widget]="pagesMissingQueryStringsWidget">
      <div class="pages-missing-query-strings-chart-container">
        <donut-chart-with-key [data]="pagesMissingQueryStringsWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'pages-missing-query-strings-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
  </use-case-widget>

  <use-case-widget [splitCard]="pagesWithRedirectsWidget.splitCard"
                   [opSelector]="pagesWithRedirectsWidget.opSelector"
                   [topText]="pagesWithRedirectsWidget.topText"
                   [topValue]="pagesWithRedirectsWidget.topValue"
                   [topTooltip]="pagesWithRedirectsWidget.topTooltip"
                   [topClickAction]="pagesWithRedirectsWidget.topClickAction"
                   [statsData]="pagesWithRedirectsWidget.statsData"
                   [bottomLeftCustomContent]="true"
  >
    <use-case-widget-body right [widget]="pagesWithRedirectsWidget">
      <div class="pages-with-redirects-chart-container">
        <donut-chart-with-key [data]="pagesWithRedirectsWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'pages-with-redirects-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="pagesWithRedirectsWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
</use-case-report-section>

<use-case-report-section [sectionIcon]="tagsSection.sectionIcon"
                         [sectionTitle]="tagsSection.sectionTitle"
>
  <use-case-widget [splitCard]="uniqueTagsWidget.splitCard"
                   [opSelector]="uniqueTagsWidget.opSelector"
                   [topText]="uniqueTagsWidget.topText"
                   [topValue]="uniqueTagsWidget.topValue"
                   [topTooltip]="uniqueTagsWidget.topTooltip"
                   [topClickAction]="uniqueTagsWidget.topClickAction"
                   [statsData]="uniqueTagsWidget.statsData"
                   [bottomLeftCustomContent]="true"
  >
    <use-case-widget-body right [widget]="uniqueTagsWidget">
      <div class="horizontal-stacked-bar-chart-container">
        <horizontal-stacked-bar-chart [data]="uniqueTagsWidget.chartData"
                                      [uniqueIdentifier]="'unique-analytics-chart'"
                                      [showHeading]="false"
                                      [hideTooltip]="true"
                                      [roundedCorners]="true"
                                      [chartHeight]="20"
        ></horizontal-stacked-bar-chart>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="uniqueTagsWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>

  <use-case-widget [splitCard]="pagesMissingAnalyticsWidget.splitCard"
                   [opSelector]="pagesMissingAnalyticsWidget.opSelector"
                   [topText]="pagesMissingAnalyticsWidget.topText"
                   [topValue]="pagesMissingAnalyticsWidget.topValue"
                   [topTooltip]="pagesMissingAnalyticsWidget.topTooltip"
                   [topClickAction]="pagesMissingAnalyticsWidget.topClickAction"
                   [bottomText]="pagesMissingAnalyticsWidget.bottomText"
                   [bottomValue]="pagesMissingAnalyticsWidget.bottomValue"
  >
    <use-case-widget-body right [widget]="pagesMissingAnalyticsWidget">
      <div class="pages-missing-analytics-chart-container">
        <donut-chart-with-key [data]="pagesMissingAnalyticsWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'pages-missing-analytics-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
  </use-case-widget>

  <use-case-widget [splitCard]="pagesMissingTagManagerWidget.splitCard"
                   [opSelector]="pagesMissingTagManagerWidget.opSelector"
                   [topText]="pagesMissingTagManagerWidget.topText"
                   [topValue]="pagesMissingTagManagerWidget.topValue"
                   [topTooltip]="pagesMissingTagManagerWidget.topTooltip"
                   [topClickAction]="pagesMissingTagManagerWidget.topClickAction"
                   [bottomText]="pagesMissingTagManagerWidget.bottomText"
                   [bottomValue]="pagesMissingTagManagerWidget.bottomValue"
  >
    <use-case-widget-body right [widget]="pagesMissingTagManagerWidget">
      <div class="pages-missing-tag-manager-chart-container">
        <donut-chart-with-key [data]="pagesMissingTagManagerWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'pages-missing-tag-manager-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
  </use-case-widget>
</use-case-report-section>
