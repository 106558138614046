import { RouteReloadService } from './../../shared/services/route-reload.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActionSelectorComponent } from '@app/components/action-selector/action-selector.component';
import { MatDialogRef } from '@angular/material/dialog';
import { IJourneyStatus, IWebJourneyResultsParams } from './web-journey-results.models';
import { IWebJourneyAPIAction, IWebJourneyResults } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { IActionSelectorAction } from '@app/components/action-selector/action-selector.models';
import { JourneyImagePreviewComponent } from '@app/components/journey-image-preview/journey-image-preview.component';
import { combineLatest, forkJoin, Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { IStatusBannerSettings, StatusBannerService } from '@app/components/reporting/statusBanner/statusBannerService';
import { EasyRuleEditService } from '@app/components/shared-services/easy-rule-edit-service';
import { IJourneyResultsTag } from '@app/components/tag-report/tag-report.models';
import { SimpleRuleCreatorComponent } from '@app/components/rules/simple-rule-creator/simple-rule-creator.component';
import { ISimpleRuleCreatorPayload } from '@app/components/rules/simple-rule-creator/simple-rule-creator.model';
import { IRuleReportItem } from '@app/components/reporting/rules/rule.models';
import { map, pairwise, skip, startWith, takeUntil } from 'rxjs/operators';
import { RulesService } from '@app/components/rules/rules.service';
import {
  RuleSelectorModalComponent
} from '@app/components/account/rules/rule-selector-modal/rule-selector-modal.component';
import { IRuleSelection } from '@app/components/account/rules/rule-selector/rule-selector.models';
import {
  IRuleSelectorModalPayload
} from '@app/components/account/rules/rule-selector-modal/rule-selector-modal.models';
import { EJourneyFailureType, EWJResultsTab } from './web-journey-results.enums';
import { WjResultsTagReportService } from './tag-report/wj-results-tag-report.service';
import {
  IActionDetailsObject,
  WebJourneyActionDetailsService
} from '@app/components/domains/webJourneys/webJourneyAPI/web-journey-action-details.service';
import { WjResultsTagComparisonComponent } from './tag-comparison/wj-results-tag-comparison.component';
import { IAbsentTagSummary, IWJActionTagSummary } from './tag-report/wj-results-tag-comparison.models';
import { userIsGuest } from '@app/authUtils';
import {
  WebJourneyV3ActionsService
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3-actions.service';
import { IActionSet, IActionSetAction } from '@app/components/action-set-library/action-set-library.models';
import { WebJourneyReportService } from '../web-journey-report.service';
import {
  WebJourneyV3RulesService
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3-rules.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionSetLibraryService } from '@app/components/action-set-library/action-set-library.service';
import { EActionTypeV3 } from '@app/components/web-journey/web-journey.models';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { EWebJourneyTab } from '@app/components/web-journey/web-journey-editor/web-journey-editor.constants';
import { AccountsService } from '@app/components/account/account.service';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { IRule, IRulePreview, IRuleV3, IRuleV3WebJourneyRules } from '@app/components/rules/rules.models';
import { WebJourneyEditorComponent } from '@app/components/web-journey/web-journey-editor/web-journey-editor.component';
import { IWebJourneyV3 } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { IWebJourneyEditorModalData } from '@app/components/web-journey/web-journey-editor/web-journey-editor.models';
import { IUser } from '@app/moonbeamModels';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { REQUEST_LOG_SEARCH_TEXT_KEY, } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { IJourneyImagePreviewPayload } from '@app/components/journey-image-preview/journey-image-preview.models';
import { MatTabGroup } from '@angular/material/tabs';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { IPrimaryTag, ManageTagsService } from '@app/components/account/manageTags/manageTagsService';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'web-journey-results',
  templateUrl: './web-journey-results.component.html',
  styleUrls: ['./web-journey-results.component.scss'],
  providers: [WjResultsTagReportService]
})
export class WebJourneyResultsComponent implements OnInit {
  EReportType = EReportType;
  state: object = {};
  isRequestLogExpanded: boolean = false;
  stepEventItem: any;
  loadingConsoleLogs: boolean = true;
  loadingRequestLogs: boolean = true;

  @ViewChild(ActionSelectorComponent)
  private actionSelectorComponent: ActionSelectorComponent;

  @ViewChild(WjResultsTagComparisonComponent)
  tagComparisonComponent: WjResultsTagComparisonComponent;

  @ViewChild(MatTabGroup)
  tabGroup: MatTabGroup;

  @HostListener('document:keydown.escape')
  keyDown(): void {
    if (!this.modalEscapeService.getLast()) {
      this.webJourneyReportService.closeReport(this.journeyId);
    }
  }

  showMainSpinner = true;
  showFixJourneySpinner = false;
  showRulesSpinner = false;
  journeyActions: IActionSetAction[];
  journeyId: number;
  runId: number;
  isReadOnly = true;

  currentStep: number;
  currentTab = EWJResultsTab.ActionDetails;
  EWJResultsTab = EWJResultsTab;

  journeyStatus: IJourneyStatus;

  results: IWebJourneyResults;
  expandedJourneyActions: IActionSetAction[];

  actions: IActionSelectorAction[] = [];
  currentAction: IActionSelectorAction;
  actionSets: IActionSet[];
  actionSetMap: Record<number, IActionSet>;

  journeyRules: IRuleV3WebJourneyRules;
  canAddRule: boolean;
  canEditRules: boolean;
  totalRuleCount: number;

  dialogRef: MatDialogRef<JourneyImagePreviewComponent>;
  loadingJourneyState = true;
  showFullScreenStatusBanner = false;
  showLoadingErrorMessage = false;
  onReportLoadCookieCount: number; // this is set when the component loads and never again

  journeyType = 'WEB_JOURNEY';
  actionDetailsData: IActionDetailsObject;

  cookieTabTitle = 'Cookies (0)';
  requestExpanderTitle = 'Request Log (0)';
  consoleLogExpanderTitle = 'Console Log (0)';
  maxRowsJavascript = 50;

  stepLoadedSubject: Subject<string> = new Subject<string>();
  stepLoaded$: Observable<string> = this.stepLoadedSubject.asObservable();

  EJourneyFailureType = EJourneyFailureType;

  primaryTags: IPrimaryTag[] = [];
  primaryTagsToggleValue: boolean = false;
  primaryTagFilteringDisabled;

  runNowPending: boolean = false;

  private destroy$ = new Subject();

  readonly selectorTypes = {
    1: 'ID',
    2: 'Name',
    3: 'CSS',
    4: 'XPath',
    5: 'HTML Attributes'
  };

  readonly defaultActions = {
    'navto': 'Navigate To',
    'click': 'Click',
    'input': 'Input',
    'maskedInput': 'Masked Input',
    'select': 'Select',
    'check': 'Check',
    'uncheck': 'Uncheck',
    'execute': 'Execute JavaScript',
    'watch': 'Watch',
    'enterIFrame': 'Switch to iFrame',
    'exitIFrame': 'Leave iFrame',
    'clearCookies': 'Clear All Cookies',
    'privacyOptIn': 'CMP Accept All',
    'privacyOptOut': 'CMP Reject All'
  };

  tagCount$ = new BehaviorSubject<number>(0);

  constructor(private route: ActivatedRoute,
    private router: Router,
    private routeReload: RouteReloadService,
    private opModalService: OpModalService,
    private modalEscapeService: ModalEscapeService,
    private statusBannerService: StatusBannerService,
    private easyRuleEditService: EasyRuleEditService,
    private labelsApiService: LabelService,
    private rulesService: RulesService,
    private accountsService: AccountsService,
    private webJourneyReportService: WebJourneyReportService,
    private webJourneyV3Service: WebJourneyV3Service,
    private webJourneyV3ActionsService: WebJourneyV3ActionsService,
    private webJourneyV3RulesService: WebJourneyV3RulesService,
    private actionSetService: ActionSetLibraryService,
    private snackbarService: SnackbarService,
    private dateService: DateService,
    private actionDetailsService: WebJourneyActionDetailsService,
    private changeDetectorRef: ChangeDetectorRef,
    private manageTagsService: ManageTagsService
  ) {
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams]) // subscribe on path and query params changes
      .pipe(
        map(([params, queryParams]) => ({ ...params, ...queryParams })), // merge them into an object
        startWith({}), // initial value is needed for pairwise
        pairwise(), // emits the previous and current values
        takeUntil(this.destroy$)
      )
      .subscribe(([prevParams, currentParams]: [IWebJourneyResultsParams, IWebJourneyResultsParams]) => {
        this.journeyId = +currentParams.journeyId;
        this.runId = +currentParams.runId;
        this.currentStep = +currentParams.action || 1;
        this.currentTab = +currentParams.tab;

        if (this.runId > 0) {
          this.actionDetailsService.getCookieData(this.journeyId, this.runId, this.currentStep - 1).subscribe(response => {
            this.onReportLoadCookieCount = response?.create?.length;
            this.updateCookieCount(this.onReportLoadCookieCount);
          });
        }

        // checks only path params changes, w/o query params
        const paramsChanged = prevParams.journeyId !== currentParams.journeyId || prevParams.runId !== currentParams.runId;
        if (Number.isInteger(this.runId) && paramsChanged) this.init();
      });

    this.routeReload.reloadRouteEvents$
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => this.init());

    this.initListeners();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  runNow(): void {
    if (this.runNowPending) {
      return;
    }
    this.runNowPending = true;
    this.webJourneyV3Service.runWebJourneyNow(this.journeyId).subscribe(() => {
      this.runNowPending = false;
      this.router.navigateByUrl(this.router.url);
    }, error => {
      this.runNowPending = false;
      this.webJourneyV3Service.handleWebJourneyRunError(error);
    });
  }

  private async init() {
    this.showMainSpinner = true;
    this.showLoadingErrorMessage = false;

    this.checkFullScreenBannerState();

    await this.accountsService.getUser().toPromise().then((user: IUser) => {
      this.isReadOnly = userIsGuest(user);
    });

    // If we don't have any runs for this journey, we should just exit immediately and not try to populate
    // the results reports
    if (this.runId === 0) return;

    let requests: any = [
      this.webJourneyReportService.getJourneyResult(this.journeyId, this.runId),
      this.webJourneyReportService.getJourneyRules(this.journeyId),
      this.webJourneyV3ActionsService.getJourneyActions(this.journeyId),
      this.manageTagsService.getPrimaryTags(),
      this.manageTagsService.getReportToggleValue()
    ];

    if (!this.isReadOnly) requests.push(this.actionSetService.getAllActionSets());

    forkJoin(requests)
      .subscribe(([results, rules, actions, primaryTags, primaryTagsToggleValue, actionSets]) => {
        this.showMainSpinner = false;
        this.results = results as IWebJourneyResults; // Results of previous journey run
        this.journeyActions = actions as IActionSetAction[];  // Current journey actions
        this.journeyRules = rules as IRuleV3WebJourneyRules;  // Current journey rules
        this.actionSets = (actionSets as IActionSet[]) ?? [];
        this.actionSetMap = this.createActionSetMap(this.actionSets);
        this.expandedJourneyActions = this.getExpandedJourneyActions();

        this.primaryTags = primaryTags as IPrimaryTag[];
        this.primaryTagFilteringDisabled = !this.manageTagsService.hasEnabledPrimaryTags(this.primaryTags);
        this.primaryTagsToggleValue = (this.primaryTagFilteringDisabled && primaryTagsToggleValue) ? !primaryTagsToggleValue as boolean : primaryTagsToggleValue as boolean;
        this.manageTagsService.setActiveReportToggle(this.primaryTagsToggleValue);

        this.actions = this.results.actions;
        this.prepareValidationDescription(this.results.actions);
        this.onStepSelected({ stepNumber: this.currentStep });
        this.determineRuleEditOption();
        this.updateCookieCount(this.onReportLoadCookieCount);

        this.journeyStatus = this.webJourneyReportService.getJourneyStatus(this.results);

        // since this endpoint isn't cached we'll send the count
        // of configured actions to header for the info panel
        this.webJourneyReportService.webJourneyConfiguredActionsSubject.next(this.journeyActions.length);
      },
        response => {
          if (response.code !== 403) {
            this.showMainSpinner = false;
            this.showLoadingErrorMessage = true;
          }
        });
  }

  private initListeners(): void {
    this.webJourneyReportService.webJourneyJumpToAction$.pipe(takeUntil(this.destroy$)).subscribe(step => {
      this.jumpToFailure(step);
    });

    this.actionDetailsService.requestLogCount$.pipe(skip(1), takeUntil(this.destroy$)).subscribe(count => {
      this.updateRequestCount(count);
    });

    this.actionDetailsService.consoleLogCount$.pipe(skip(1), takeUntil(this.destroy$)).subscribe(count => {
      this.updateConsoleLogCount(count);
    });

    this.webJourneyReportService.tagCount$.pipe(takeUntil(this.destroy$)).subscribe(count => {
      this.tagCount$.next(count);
    });
  }

  // Unpackage any nested actions from current journey actions that are action sets so format is a single array of all actions
  // i.e. [ {action, actionSet (3 actions), action, ... }] expands out to:
  //  [ {action, action, action, action, action, ... }], matching the results actions response.
  private getExpandedJourneyActions() {
    return this.journeyActions.reduce((acc, action) => {
      if (action.actionType === EActionTypeV3.ActionSet) {
        const actionSet = this.actionSetMap[action.actionSetId];
        const actions = actionSet ? [...this.actionSetMap[action.actionSetId].actions] : [];
        return [...acc, ...actions];
      }

      return [...acc, action];
    }, []);
  }

  // mutation happens here
  private prepareValidationDescription(actions: IWebJourneyAPIAction[]): angular.IPromise<void> {
    const snapshotIds = actions.flatMap(
      action => action.failedRuleReports?.flatMap(
        failedRuleReport => failedRuleReport.failedTags.flatMap(
          failedTag => failedTag.items.map(item => item.snapshotId)
        )
      ) || []
    );

    const uniqueSnapshotIds = Array.from(new Set(snapshotIds));

    return this.rulesService.getOriginalRulesByConditionIds(uniqueSnapshotIds).toPromise().then(conditionSnapshot => {
      const conditionIdToDescription = ArrayUtils.toMap(conditionSnapshot, 'snapshotId');

      actions.forEach(
        action => action.failedRuleReports?.forEach(
          ruleReport => ruleReport.failedTags.forEach(
            tag => tag.items.forEach(
              item => item.validationDescription = conditionIdToDescription.get(item.snapshotId)?.validationDescription
            )
          )
        )
      );
    });
  }

  onTabSelected(tab: number): void {
    this.currentTab = tab;

    this.router.navigate([], {
      queryParams: { tab },
      queryParamsHandling: 'merge'
    });
  }

  onStepSelected(event: { stepNumber: number, stepEventItem?: any }): void {
    if (this.currentStep !== event.stepNumber) {
      // only update the counts if the step has changed
      this.actionDetailsService.requestLogCountSubject.next('...');
      this.actionDetailsService.consoleLogCountSubject.next('...');
    }

    this.updateCookieCount('...');
    this.loadingConsoleLogs = true;
    this.loadingRequestLogs = true;
    const { stepNumber, stepEventItem } = event;
    this.currentStep = stepNumber;
    // Get selected result action using selected index and flattened list of results actions
    this.currentAction = { ...this.actions[this.currentStep - 1] };
    this.totalRuleCount = this.calcTotalRules();

    this.canAddRule = this.isActionStillInJourney();
    this.updateJourneyImagePreviewData();

    const urlTree = this.router.parseUrl(this.router.url);
    if (urlTree.queryParams.action !== stepNumber.toString()) {
      this.router.navigate([], {
        queryParams: { action: stepNumber },
        queryParamsHandling: 'merge'
      }).then(() => {
        setTimeout(() => {
          this.stepLoadedSubject.next(stepEventItem);
        }, 750);
      });
    }

    this.updateTagCount();
  }

  isActionStillInJourney(): boolean {
    const selectedResultAction = this.actions[this.currentStep - 1];
    // Return whether the action itself is still present or if it's an action set, whether the action set is still preset
    return !!this.expandedJourneyActions.find(action => action.id === selectedResultAction?.actionId || action.id === this.currentAction.actionSetId);
  }

  isUrlSame(): boolean {
    const startPageUrl = this.currentAction?.startPageUrl?.trim().toLowerCase();
    const finishPageUrl = this.currentAction?.finishPageUrl?.trim().toLowerCase();
    return !!startPageUrl && startPageUrl === finishPageUrl;
  }

  goToUrl(url: string) {
    window.open(url, '_blank');
  }

  onAddRule(tag: IJourneyResultsTag | IAbsentTagSummary): void {
    if (!this.canAddRule) return;

    let index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.opModalService
      .openModal(SimpleRuleCreatorComponent, {
        data: {
          journeyId: this.journeyId,
          actionSequence: this.currentAction.sequence,
          tag: tag,
          variables: this.isActionTagSummary(tag) ? tag.variables : [],
          bindTo: this.currentAction.actionSetId ? 'ACTION_SET' : 'WEB_JOURNEY',
          actionId: this.currentAction.actionId,
          journeyActions: this.journeyActions,
          actionSet: this.actionSetMap[this.currentAction.actionSetId],
        } as ISimpleRuleCreatorPayload
      })
      .afterClosed()
      .subscribe(() => {
        this.modalEscapeService.remove(index);
      });
  }

  private isActionTagSummary(tag: IJourneyResultsTag | IAbsentTagSummary): tag is IWJActionTagSummary {
    return !!(tag as IWJActionTagSummary).variables;
  }

  /** Rules tab callbacks */
  onRemoveRule(ruleId: number): void {
    const actionSetId = this.actionSetMap[this.currentAction.actionId]?.id;

    actionSetId
      ? this.easyRuleEditService.removeWebJourneyRule(this.journeyId, ruleId, this.currentAction.actionId, actionSetId)
      : this.easyRuleEditService.removeWebJourneyRule(this.journeyId, ruleId, this.currentAction.actionId);
  }

  onEditRule(ruleId: number): void {
    this.easyRuleEditService.editRule(ruleId);
  }

  onSetAsExpected(ruleReportItem: IRuleReportItem): void {
    this.easyRuleEditService.setAsExpected(ruleReportItem);
  }

  onDeleteVariable(ruleReportItem: IRuleReportItem): void {
    const data = {
      displayItem: {
        type: 'Variable',
        name: ruleReportItem.name,
      },
      deleteButtonAction: () => {
        this.easyRuleEditService.deleteVariable(ruleReportItem);
      }
    };

    this.opModalService.openDeleteModal({ data });
  }

  private calcTotalRules(): number {
    const failed = this.currentAction.failedRuleReports?.length || 0;
    const notApplied = this.currentAction.notAppliedRuleReports?.length || 0;
    const passed = this.currentAction.passedRuleReports?.length || 0;

    return failed + notApplied + passed;
  }

  private determineRuleEditOption(): void {
    const journeyDate = this.currentAction.timestamp;
    const cutoffDate = new Date('2019-10-18').getTime();

    this.canEditRules = journeyDate > cutoffDate;
  }

  onClickToEnlarge() {
    const data: IJourneyImagePreviewPayload = {
      numActions: this.actions.length,
      currentStep: this.currentStep,
      currentAction: this.currentAction,
      label: this.currentAction.action?.label ?? '',
      changeStep: (stepNumber: number) => this.jumpToAction(stepNumber)
    };
    this.dialogRef = this.opModalService.openFullscreenModalTransparent(JourneyImagePreviewComponent, { data });

    let index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.dialogRef.afterClosed().subscribe(() => {
      this.modalEscapeService.remove(index);
    });
  }

  jumpToFailure(stepNumber: number) {
    if (this.journeyStatus?.failureType === EJourneyFailureType.Action) {
      this.tabGroup.selectedIndex = EWJResultsTab.ActionDetails;
    } else if (this.journeyStatus?.failureType === EJourneyFailureType.Rule) {
      this.tabGroup.selectedIndex = EWJResultsTab.Rules;
    }
    this.jumpToAction(stepNumber);
  }

  jumpToAction(stepNumber: number, stepEventItem?: any) {
    this.actionSelectorComponent?.onStepSelected(stepNumber, stepEventItem);
  }

  beautifyTimestamp(rawTime: number): string {
    return this.dateService.formatDate(new Date(rawTime), EDateFormats.dateEighteen);
  }

  private updateJourneyImagePreviewData(): void {
    if (this.dialogRef && this.dialogRef.componentInstance) {
      this.dialogRef.componentInstance.currentStep = this.currentStep;
      this.dialogRef.componentInstance.currentAction = this.currentAction;
      this.dialogRef.componentInstance.label = this.currentAction.action.label;
    }
  }

  private checkFullScreenBannerState(): void {
    this.showMainSpinner = true;
    this.showFullScreenStatusBanner = false;
    this.loadingJourneyState = true;

    this.statusBannerService.getSettingsForWebJourney(this.journeyId).then(
      (settings: IStatusBannerSettings) => {
        this.showMainSpinner = false;
        this.showFullScreenStatusBanner = settings.notRunYet || settings.firstRun;
        this.loadingJourneyState = false;
      },
      () => {
        this.showMainSpinner = false;
        this.showFullScreenStatusBanner = false;
        this.loadingJourneyState = false;
      }
    );
  }

  editAction() {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    const data: IWebJourneyEditorModalData = {
      journeyId: this.journeyId,
      step: EWebJourneyTab.actions,
      action: this.currentAction.sequence,
    };
    this.opModalService.openFixedSizeModal(WebJourneyEditorComponent, { disableClose: true, data }, 'op-webjourney-editor')
      .afterClosed()
      .subscribe((webJourney?: IWebJourneyV3) => {
        this.modalEscapeService.remove(index);
        if (webJourney) this.router.navigateByUrl(this.router.url);
      });
  }

  // Add rules to a Web Journey or Action Set, depending on selected action type
  assignRulesToAction(): void {
    // Show warning if selected action is no longer in the journey
    if (!this.canAddRule) {
      this.showCurrentActionWarning();
      return;
    }

    this.currentAction.actionSetId !== null
      ? this.assignRulesToActionSetAction(this.currentAction.actionSetId)
      : this.assignRulesToJourneyAction();
  }

  assignRulesToJourneyAction() {
    // Show warning if selected action is no longer in the journey
    if (!this.canAddRule) {
      this.showCurrentActionWarning();
      return;
    }

    this.showRulesSpinner = true;

    forkJoin([
      this.rulesService.getRulePreviews(),
      this.labelsApiService.getLabels(),
      this.rulesService.getRulesForWebJourneyAction(this.journeyId, this.currentAction.actionId)
    ]).subscribe(([rules, labels, actionRules]) => {
      this.showRulesSpinner = false;
      let index = this.modalEscapeService.getLast() + 1;
      this.modalEscapeService.add(index);

      this.openRuleSelectorModal(rules, labels, actionRules).afterClosed().subscribe(ruleSelection => {
        this.modalEscapeService.remove(index);
        if (ruleSelection) {
          this.updateJourneyActionRules(this.currentAction.actionId, ruleSelection.selectedRuleIds);
        }
      });
    }, () => {
      this.showRulesSpinner = false;
    });
  }

  assignRulesToActionSetAction(actionSetId: number) {
    let actionSetAction = this.actionSets
      .find((actionSet: IActionSet) => actionSet.id === actionSetId).actions
      .find((action: IActionSetAction) => action.id === this.currentAction.actionId);

    if (!actionSetAction) {
      this.showCurrentActionWarning();
      return;
    }

    this.showRulesSpinner = true;

    forkJoin([
      this.rulesService.getRulePreviews(),
      this.labelsApiService.getLabels(),
      this.actionSetService.getActionSetActionRules(actionSetId, this.currentAction.actionId)
    ]).subscribe(([rules, labels, actionRules]) => {
      let actionRuleIds = actionRules.map((rule: IRuleV3) => rule.id);
      this.showRulesSpinner = false;
      let index = this.modalEscapeService.getLast() + 1;
      this.modalEscapeService.add(index);

      this.openRuleSelectorModal(rules, labels, actionRuleIds).afterClosed().subscribe(ruleSelection => {
        this.modalEscapeService.remove(index);
        if (ruleSelection) {
          this.updateActionSetActionRules(actionSetId, actionSetAction.id, ruleSelection.selectedRuleIds);
        }
      });
    }, () => {
      this.showRulesSpinner = false;
    });
  }

  private showCurrentActionWarning() {
    this.opModalService.openConfirmModal({
      data: {
        messages: ['This action no longer exists.', 'Please re-run your journey for an updated report.'],
        rightFooterButtons: [{
          label: 'Ok',
          action: () => {
          }
        }]
      } as any
    });
  }

  private openRuleSelectorModal(rules: IRulePreview[], labels: ILabel[], actionRules: number[]): MatDialogRef<RuleSelectorModalComponent, IRuleSelection> {
    return this.opModalService.openFixedSizeModal<RuleSelectorModalComponent, IRuleSelectorModalPayload>(RuleSelectorModalComponent, {
      data: {
        rules,
        labels,
        selectedRules: actionRules
      }
    }, 'op-rule-selector');
  }

  private updateJourneyActionRules(actionId: number, ruleIds: number[]) {
    this.webJourneyV3RulesService.updateJourneyActionRules(this.journeyId, actionId, ruleIds).subscribe(
      () => {
        // do nothing if successful
      },
      (error: HttpErrorResponse) => {
        this.snackbarService.openErrorSnackbar('Error: Unable to update the rules assigned to this action.');
      }
    );
  }

  private updateActionSetActionRules(actionSetId: number, actionId: number, ruleIds: number[]) {
    this.actionSetService.updateActionSetActionRules(actionSetId, actionId, ruleIds).subscribe(
      () => {
        // do nothing if successful
      },
      (error: HttpErrorResponse) => {
        this.snackbarService.openErrorSnackbar('Error: Unable to update the rules assigned to this action.');
      }
    );
  }

  updateCookieCount(cookieCount: number | '...'): void {
    this.cookieTabTitle = `Cookies (${cookieCount})`;
  }

  updateRequestCount(toDisplay: number | string): void {
    this.requestExpanderTitle = `Request Log (${toDisplay})`;
    this.changeDetectorRef.detectChanges();
  }

  updateConsoleLogCount(consoleLogCount: number | string): void {
    this.consoleLogExpanderTitle = `Console Log (${consoleLogCount})`;
    this.changeDetectorRef.detectChanges();
  }

  private createActionSetMap(actionSets: IActionSet[]): Record<number, IActionSet> {
    let map = {};

    actionSets.forEach(actionSet => {
      map[actionSet.id] = actionSet;
    });

    return map;
  }

  handleNavToTab({ tab, searchValue }): void {
    if (searchValue) {
      this.state = {
        [REQUEST_LOG_SEARCH_TEXT_KEY]: searchValue,
      };
    }

    this.isRequestLogExpanded = true;
    this.currentTab = tab;
  }

  handleNavToAction(event: { actionIndex: number, stepEventItem?: any }): void {
    if (event.stepEventItem) {
      this.stepEventItem = event.stepEventItem;
      this.jumpToAction(event.actionIndex, event.stepEventItem);
    } else {
      this.jumpToAction(event.actionIndex);
    }
  }

  getActionLabel(currentAction: IActionSelectorAction['action']): string {
    return `${this.currentStep}. ${currentAction.label || 'unnamed action'}`;
  }

  handlePrimaryTagsToggleValueChange(checked: boolean) {
    this.manageTagsService.setActiveReportToggle(checked);
    this.primaryTagsToggleValue = checked;
    this.webJourneyReportService.tagFilteringSubject.next(checked);
  }

  updateTagCount() {
    setTimeout(() => {
      const count = this.tagComparisonComponent?.getTagsCount() || 0;
      this.tagCount$.next(count);
    });
  }

  handleRequestLogOpened() {
    this.isRequestLogExpanded = true;
  }
}
