import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { ISuccessModalPayload } from '@app/components/shared/components/op-modal/op-success-modal/op-success-modal.models';
import { modifyButtonsActions } from '../shared/op-modal.utils';

@Component({
  selector: 'op-success-modal',
  templateUrl: './op-success-modal.component.html',
  styleUrls: ['./op-success-modal.component.scss']
})
export class OpSuccessModalComponent {

  rightFooterButtons: IButton[];

  constructor(@Inject(MAT_DIALOG_DATA) public payload: ISuccessModalPayload,
              private dialogRef: MatDialogRef<OpSuccessModalComponent>) {

    this.rightFooterButtons = modifyButtonsActions(payload.rightFooterButtons, () => this.close);
  }

  close(): void {
    this.dialogRef.close();
  }

}
