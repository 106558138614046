import {Names} from '@app/moonbeamConstants';
import {ISerializer} from '../../../../../api/apiService';
import { DateService } from '../../../../../date/date.service';
import {IAuditRun} from './auditSummaryData';

interface IAuditSummaryDataJson {
    runId: number
    completed: string;
    auditScore: number;
    statusCode: number;
    duplicate: number;
    compliance: number;
    tagPresence: number;
    loadTime: number;
  }

  interface IAuditSummariesJson {
    summaries: Array<IAuditSummaryDataJson>;
  }

  export class AuditRunSerializer implements ISerializer<Array<IAuditRun>> {
    static $inject = [
      Names.Services.date,
    ];

    constructor(private dateService: DateService) {
    }

    serialize(json: IAuditSummariesJson): Array<IAuditRun> {
      if (json.summaries && json.summaries[0]) {
        return json.summaries
          .filter(
            (json: IAuditSummaryDataJson) => json.completed != null
          )
          .map((json: IAuditSummaryDataJson) => {
            return <IAuditRun>{
              id: +json.runId,
              date: this.dateService.dateFromString(json.completed),
              auditScore: +json.auditScore,
              tagPresence: +json.tagPresence,
              loadTime: +json.loadTime,
              statusCode: +json.statusCode,
              duplicateRequests: +json.duplicate,
              compliance: +json.compliance
            };
          });
      } else {
        return [];
      }
    }
}

