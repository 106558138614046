import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  IOpFilterBarV2Filter,
  IOpFilterBarV2MenuItemCustomComponent
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import {
  OpFilterBarItemDatepickerComponent
} from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.component';
import { EFilterBarV2MenuTypes } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.constants';
import {
  EOpFilterBarItemDatapickerRanges, IOpFilterBarItemDatepickerRangeState
} from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.models';

interface ISyncValue {
  item: IOpFilterBarV2MenuItemCustomComponent,
  component?: OpFilterBarItemDatepickerComponent
}
@Injectable()
export class OpFilterBarItemDatepickerService {

  private updates = new BehaviorSubject<ISyncValue>({
    item: null,
    component: null
  });

  private updatingDates = new Subject<EOpFilterBarItemDatapickerRanges>();

  sync(value: ISyncValue) {
    this.updates.next(value);
  }

  clearSelection(customComponentRef: string, action: (value: unknown) => void) {
    this.updates.next({
      item: {
        type: null,
        value: null,
        name: '',
        customComponentRef,
        action
      }
    });
  }

  subscribeToUpdates() {
    return this.updates.asObservable();
  }

  getSyncObj(dateRangeFilter: IOpFilterBarV2Filter<string>, DATEPICKER_COMPONENT_REF: string, action): ISyncValue {
    const { dates } = dateRangeFilter.value[0] as IOpFilterBarItemDatepickerRangeState;
    const { type } = dateRangeFilter.menuItems[0].value;
    const value = { type, dates };

    return {
      item: {
        type: EFilterBarV2MenuTypes.CustomComponent,
        name: '',
        customComponentRef: DATEPICKER_COMPONENT_REF,
        value,
        action
      }
    };
  }

  getUpdatePredefinedDates() {
    return this.updatingDates.asObservable();
  }

  updatePredefinedDates(type: EOpFilterBarItemDatapickerRanges) {
    this.updatingDates.next(type);
  }
}
