<div class="cc-edit-requests">
  <div class="search-add-wrap">
    <op-clearable-input placeholderText="Search by request domain or geolocation"
                        [emitKeyboardEvent]="true"
                        appearance="OUTLINED"
                        class="table-filter-input"
                        (onUserInput)="applyFilter($event)">
    </op-clearable-input>
    <div class="global-add-delete">
      <button class="add-request-and-geo add-request-and-geo-yellow"
              type="button"
              (click)="openAddRequestAndGeoModal()">
        <mat-icon>add_circle_outline</mat-icon>
        <span class="add-text">Add Request & Geo</span>
      </button>
      <mat-divider [vertical]="true" *ngIf="dataSource.data.length > 0"></mat-divider>
      <button class="add-request-and-geo"
              type="button"
              *ngIf="dataSource.data.length > 0"
              matTooltip="Delete All Requests & Geos"
              matTooltipPosition="above"
              (click)="openComfirmDeleteAllRequestDomains()">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <form [formGroup]="requestsAndGeosForm">
    <div class="table-wrap" formArrayName="requestDomains">
      <table mat-table [dataSource]="dataSource" class="cc-edit-requests-table" matSort>
        <ng-container matColumnDef="domain">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>REQUEST DOMAINS</th>
          <td mat-cell *matCellDef="let requestDomain; let i = index;" [formGroupName]="getActualIndex(requestDomain)">
            <div class="cell-wrap">
              <mat-form-field appearance="outline" class="in-table no-hint-container">
                <input matInput placeholder="Enter domain (or leave empty for any domain)" formControlName="domain" (blur)="updateDataSource()">
                <mat-error>
                  <span *ngIf="requestDomains.controls[i].errors?.urlWithOptionalProtocol">Invalid domain</span>
                  <span *ngIf="requestDomains.controls[i].errors?.validRegexes">Invalid regex</span>
                  <span *ngIf="requestDomains.controls[i].errors?.domainOrLocationRequired">Domain and location cannot both be empty</span>
                </mat-error>
              </mat-form-field>
              <mat-checkbox color="primary" formControlName="regex">Match RegEx</mat-checkbox>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="geolocation">
          <th mat-header-cell *matHeaderCellDef>GEOLOCATION</th>
          <td mat-cell *matCellDef="let requestDomain; let i = index;" [formGroupName]="getActualIndex(requestDomain)" class="geo-selector">
            <div class="cell-wrap">
              <cc-geo-selector [autoCompleteList]="autoCompleteList"
                               [selectedCountries]="requestDomain.locations"
                               (selectionChanged)="updateLocations($event, requestDomain)">
              </cc-geo-selector>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef class="last-col"></th>
          <td mat-cell *matCellDef="let requestDomain" align="right" class="last-col">
            <div class="icon-wrap">
              <mat-icon class="last-col-icon copy-icon"
                        matTooltip="Copy request & geo"
                        matTooltipPosition="above"
                        (click)="copyRequestDomain(requestDomain)">
                content_copy
              </mat-icon>
              <mat-icon class="last-col-icon delete-icon"
                        matTooltip="Delete request & geo"
                        matTooltipPosition="above"
                        (click)="deleteRequestDomain(requestDomain)">
                delete
              </mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div [hidden]="requestDomains.value.length <= minCookiesToShowPaginator">
        <mat-paginator [pageSize]="perPage"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       (page)="savePageSizeToStorage($event)"
                       aria-label="Select request domains table page size">
        </mat-paginator>
      </div>

      <div class="spinner-container" *ngIf="loading">
        <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
      </div>
    </div>
  </form>
</div>
