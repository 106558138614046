import { IButton } from '@app/models/commons';

export function modifyButtonsActions(buttons: IButton[], additionalLogic: Function): IButton[] {
  return buttons.map(button => {
    const cachedFunction = button.action as Function;
    button.action = () => {
      cachedFunction();
      additionalLogic();
    };
    return button;
  });
}