import { startWith, takeUntil, tap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { ComponentChanges } from '@app/models/commons';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { FilterTypes } from '../../rule-setup-conditions-tab.constants';
import { EPageFilterType, ERuleFilterType } from '../../rule-setup-conditions-tab.enums';
import { IFilterTypeConfig, IFilterTypeMatchTypeConfig } from '../../rule-setup-conditions-tab.models';
import { RuleSetupFormBuilderService } from '@app/components/rules/rule-setup/services/rule-setup-form-builder.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag, IUiTagCategory } from '@app/components/tag-database';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'if-condition',
  templateUrl: './if-condition.component.html',
  styleUrls: ['./if-condition.component.scss']
})
export class IfConditionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formData: UntypedFormGroup;
  @Input() groupName: UntypedFormGroup;
  @Input() allTags: IUiTag[];
  @Input() hidePageMatching: boolean;

  @Output() onRemoveMainCondition = new EventEmitter<void>();

  filterTypes: IFilterTypeConfig[];
  RuleFilterType = ERuleFilterType;

  filterTypeToMatchTypesMap: Map<EPageFilterType | ERuleFilterType.Tag, IFilterTypeMatchTypeConfig[]>;

  private tagIdToTagMap = new Map<number, IUiTag>();

  updateTagIdSelectorSubject = new ReplaySubject<string>(1);
  filteredTags: IUiTag[];
  tagIdSearchText: string;

  private destroy$ = new Subject<void>();

  constructor(private formBuilderService: RuleSetupFormBuilderService, private uiTagService: UiTagService) {}

  ngOnInit() {
    this.filterTypes = this.hidePageMatching
      ? FilterTypes.filter(filter => filter.value === ERuleFilterType.Tag)
      : FilterTypes;

    this.filterTypeToMatchTypesMap = FilterTypes.reduce(
      (acc, filterType) => acc.set(filterType.value, filterType.matchTypes),
      new Map()
    );

    const initialSearchText = this.getTagIdSearchText(this.tagIdControl.value);
    this.tagIdControl.valueChanges.pipe(
      startWith(initialSearchText),
      takeUntil(this.destroy$)
    ).subscribe((searchText: string | number) => {
      this.tagIdSearchText = this.getTagIdSearchText(searchText);
      this.updateTagIdSelectorSubject.next(this.tagIdSearchText);
    });

    this.updateTagIdSelectorSubject.pipe(
      takeUntil(this.destroy$),
    ).subscribe(searchText => {
      this.filteredTags = this.allTags.filter(tag => {
        return tag.name.toLowerCase().includes(searchText) ||
          this.getTagCategory(tag.tagCategoryId).category?.toLowerCase().includes(searchText);
      });
    });

    this.filterTypeControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap((type: EPageFilterType | ERuleFilterType.Tag) => {
        this.resetFieldsOnTypeChange();
        this.selectMatchType(type);
      })
    ).subscribe();
  }

  ngOnChanges(changes: ComponentChanges<IfConditionComponent>) {
    if (changes.allTags?.previousValue !== changes.allTags?.currentValue) {
      this.tagIdToTagMap = ArrayUtils.toMap(changes.allTags.currentValue, 'id');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTagCategory(id: number): IUiTagCategory {
    return this.uiTagService.getTagCategory(id);
  }

  tagSelected(tag: IUiTag) {
    this.tagIdControl.setValue(tag.id);
  }

  displayTagIdFn = (tagId: number) => this.tagIdToTagMap.get(tagId)?.name;

  addAccountCondition() {
    this.formBuilderService.addAccountCondition(this.accountGroup);
  }

  removeAccountCondition() {
    this.formBuilderService.removeAccountCondition(this.accountGroup);
  }

  addVariableCondition() {
    this.formBuilderService.addVariableCondition(this.variablesArray);
  }

  addStatusCodeCondition(): void {
    this.formBuilderService.addStatusCodeCondition(this.statusCodeGroup);
  }

  removeVariableCondition(index: number) {
    this.formBuilderService.removeVariableCondition(index, this.variablesArray);
  }

  removeStatusCodeCondition(): void {
    this.formBuilderService.removeStatusCodeCondition(this.statusCodeGroup);
  }

  private resetFieldsOnTypeChange() {
    Object.keys(this.formData.controls).forEach(key => {
      if (key === 'type') return;

      const control = this.formData.controls[key];
      control.reset();

      if (key === 'account') control.disable({ emitEvent: false });
      if (key === 'variables') {
        (control as UntypedFormArray).clear({ emitEvent: false });
      }
    });
  }

  private selectMatchType(type: EPageFilterType | ERuleFilterType.Tag) {
    // Auto select if only 1 item is available is Match Type selector
    const matchTypes = this.filterTypeToMatchTypesMap.get(type);
    if (matchTypes.length === 1) {
      this.matchTypeControl.setValue(matchTypes[0].value);
    }
  }

  private getTagIdSearchText(searchText?: string | number): string {
    if (!searchText) return ''; // tagId is disabled
    return typeof searchText === 'number' // selected tag, otherwise - searching text
      ? this.tagIdToTagMap.get(searchText)?.name.toLowerCase()
      : searchText.toLowerCase();
  }

  get filterTypeControl(): UntypedFormControl {
    return this.formData?.get('type') as UntypedFormControl;
  }

  get matchTypeControl(): UntypedFormControl {
    return this.formData?.get('matchType') as UntypedFormControl;
  }

  get valueControl(): UntypedFormControl {
    return this.formData?.get('value') as UntypedFormControl;
  }

  get tagIdControl(): UntypedFormControl {
    return this.formData?.get('tagId') as UntypedFormControl;
  }

  get accountGroup(): UntypedFormGroup {
    return this.formData?.get('account') as UntypedFormGroup;
  }

  get variablesArray(): UntypedFormArray {
    return this.formData?.get('variables') as UntypedFormArray;
  }

  get statusCodeGroup(): UntypedFormGroup {
    return this.formData?.get('statusCode') as UntypedFormGroup;
  }
}
