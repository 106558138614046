export interface IPageSummaryTagResponse {
  name: string;
  category: string;
  id: number;
  instances: IPageSummaryTagResponseInstance[];
}

export interface IPageSummaryTagResponseInstance {
  account: string;
  bytes: number;
  loadTime: number;
  duplicates: number;
  multiples: number;
  variables: ITagResponseVariable[];
}

export interface ITagResponseVariable {
  name: string;
  value: string;
}

export interface ITagOPDataHeaders {
  showTagSize: boolean;
  showMultipleRequest: boolean;
  showAccount: boolean;
  showStatusCode: boolean;
  showLoadTime: boolean;
  showDuplicateRequest: boolean
}

export interface ITagInfoDTO {
  requestViewFilteredOPHeaders?: ITagOPDataHeaders;
  name: string;
  category: string;
  tagId: number;
  statusCounts?: ITagStatusCounts;
  tagRequestCount: number;
  tagDuplicateRequestCount: number;
  tagMultipleRequestCount: number;
  tagUniqueRequestCount: number;
  variableList?: string[];
  accountList?: string[];
  pageTagInstances: ITagInstance[];
  relatedCookies: ITagInfoRelatedCookie[];
}

export interface ITagInfoRelatedCookie {
  name: string;
  domain: string;
}

export interface ITagInfoDTOWithInsights {
  pageTagInsights: ITagInsights;
  pageTags: ITagInfoDTO[];
}

export interface ITagInsights {
  noOfTagRequests: number;
  noOfUniqueTags: number;
  noOfBrokenTags: number;
}

export interface ITagInstance extends ITagInstanceOPData {
  tagName?: string;
  tagId?: number;
  category?: string;
  title?: string;
  tagInstanceId: string;
  tagInstanceVariables: ITagVariable[];
  tagInstanceVariableValueMap?: IInstanceVariableValueMap;
  relatedCookies?: ITagInfoRelatedCookie[];
  statusCodeTooltip?: string;
}

export interface ITagInstanceOPData {
  account: string;
  statusCode: number;
  bytes: number;
  loadTime: number;
  duplicates: number;
  multiples: number;
}

export interface ITagVariable {
  name: string;
  value: string;
}

export enum ETagView {
  requestView = 'requestView',
  tagView = 'tagView'
}

export interface ITagStatusCounts {
  pass: number;
  fail: number;
  warning: number;
}

export interface IInstanceVariableValueMap {
  [variableName: string]: { [tagInstanceId: string]: string}
}

export enum ETagColumnNames {
  tagName = 'tagName',
  tagCategory = 'tagCategory',
  tagRequests = 'tagRequests',
  tagStatusCode = 'tagStatusCode',
  relatedCookies = 'relatedCookies',
}

export enum ETagColumnSortDirection {
  asc = 'ascending',
  desc = 'descending',
}
export enum ETagsExpansionOPData {
  ACCOUNT = 'Account',
  STATUS_CODE = 'Status Code',
  TAG_SIZE = 'Tag size (bytes)',
  LOAD_TIME = 'Load time',
  DUPLICATES = 'Duplicate request',
  MULTIPLES = 'Multiple request',
}
