<div class="breadcrumbs">
  <ng-container
    *ngFor="let crumb of history; let index = index; let last = last;"
    [ngSwitch]="crumb.type">
    <span class="crumb" *ngSwitchCase="historyType.Link">
      <ng-container [ngSwitch]="last">
        <a *ngSwitchCase="false" (click)="selectStateLink(crumb)">{{crumb.text}}</a>
        <a class="current-state" *ngSwitchCase="true">{{crumb.text}}</a>
      </ng-container>
    </span>
    <span class="forward-slash" *ngIf="!last">/</span>
    <span class="crumb dropdown current-state" *ngSwitchCase="historyType.Dropdown">
      <mat-form-field>
        <mat-select [(value)]="crumb.selectedOption" (selectionChange)="selectStateOption($event)">
          <mat-option *ngFor="let link of crumb.options" [value]="link.text">
            {{link.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
  </ng-container>
</div>
