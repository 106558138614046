import { Component, Input, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ITagVariable } from '@app/components/tags/tagsService';
import { MatSort } from '@angular/material/sort';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-variables-table',
  templateUrl: './tag-variables-table.component.html',
  styleUrls: ['./tag-variables-table.component.scss'],
})

export class TagVariablesTableComponent implements AfterViewInit {
  displayedColumns: string[] = ['variable', 'description', 'validation', 'byteLength', 'validationDescription', 'validationImpact', 'section', 'dbmap'];
  dataSource = new MatTableDataSource();

  @Input() variables: MatTableDataSource<ITagVariable>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor() {
  }

  ngAfterViewInit(){
    this.variables.sort = this.sort;
  }
}
