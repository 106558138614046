export enum EAlertDataSourcesTableSortColumn {
  IsAssigned = 'IS_ASSIGNED',
  ItemName = 'ITEM_NAME',
  RunDate = 'RUN_DATE'
}

export enum EmailInboxEditorDataSourcesTableColumns {
  Name = 'name',
  Labels = 'labels',
  Remove = 'remove'
}
