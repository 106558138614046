import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const MyProfileStateNames = {
  base: 'my_profile'
};

export const MyProfilePaths = {
  base: 'my-profile'
};

export const MyProfileUrlBuilders = {
  base: () => RouteBuilder.buildUrl([MyProfilePaths.base])
};
