import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  IPageSummaryInsights,
  IPageSummaryInsightsByPage,
  IPageSummaryTrends,
  IAuditPageSummaryInsightTrend,
  IAuditPageSummaryWebVitalsTrends,
  IAuditPageSummaryWebVitalsTrend
} from '@app/components/audit-reports/reports/page-summary/page-summary.models';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { AuditReportBaseService } from '../../audit-report/audit-report.service';
import { EPageSummaryTrendNames } from './page-summary.constants';
import { ICommonTableState } from '@app/components/shared/components/export-report/export-reports.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class PageSummaryReportService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  /**
   * Caching below based on the following:
   * https://medium.com/@dilanthaprasanjith/caching-http-requests-with-rxjs-in-angular-9f7fa44387ce
   */

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getAuditPageSummaryInsights(auditId: number, runId: number, body: IAuditReportApiPostBody): Observable<IPageSummaryInsights> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/page-summary`, body);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getAuditPageSummaryInsightsByPage(auditId: number, runId: number, params: ICommonTableState, body: IAuditReportApiPostBody): Observable<IPageSummaryInsightsByPage> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/page-summary/pages`;
    const queryParams = `?page=${params.page}&size=${params.size}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}`;
    return this.apiService.post(`${baseRequestUrl}${queryParams}`, body);
  }

  getAuditPageSummaryTrends(auditId: number, runId: number): Observable<IPageSummaryTrends> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/page-summary/trends`;
    const cached = this.responseCache.get(requestUrl);
    if (cached) return of(cached);
    return this.apiService
      .get<IPageSummaryTrends>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditPageSummaryInsightTrends(auditId: number, trendName: EPageSummaryTrendNames, dayCount: number): Observable<IAuditPageSummaryInsightTrend> {
    const requestUrl = `${this.apiRoot}/${auditId}/reports/page-summary/trends/${trendName}?days=${dayCount}`;
    const cached = this.responseCache.get(requestUrl);
    if (cached) return of(cached);
    return this.apiService
      .get<IAuditPageSummaryInsightTrend>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditSummaryWebVitalsTrends(auditId: number, runId: number): Observable<IAuditPageSummaryWebVitalsTrends> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/page-summary/trends/web-vitals`;
    const cached = this.responseCache.get(requestUrl);
    if (cached) return of(cached);
    return this.apiService
      .get<IAuditPageSummaryWebVitalsTrends>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }

  getAuditSummaryWebVitalsTrend(auditId: number, trendName: EPageSummaryTrendNames, dayCount: number): Observable<IAuditPageSummaryWebVitalsTrend> {
    const requestUrl = `${this.apiRoot}/${auditId}/reports/page-summary/trends/web-vitals/${trendName}?days=${dayCount}`;
    const cached = this.responseCache.get(requestUrl);
    if (cached) return of(cached);
    return this.apiService
      .get<IAuditPageSummaryWebVitalsTrend>(requestUrl)
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }
}
