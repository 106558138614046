<op-modal-header-base [disabled]="disabled" (onClose)="onClose.emit()">
  <!-- Content is located on the left-side of the header -->
  <div class="left-side-content flex-row flex-center-items" [class.disabled]="disabled">
    <div class="modal-title">
      <mat-icon *ngIf="titleIcon" class="title-icon" svgIcon="{{ titleIcon }}"></mat-icon>
      <div *ngIf="titleIcon" class="vertical-bar"></div>
      <h4>
        {{ title }}
      </h4>
    </div>

    <ng-container *ngFor="let step of steps; let index = index; let last = last">
      <span class="modal-header-item" [ngClass]="{'active': currentStep === index}"
        (click)="goToStep.emit(index)">
        {{ step }}
      </span>
    </ng-container>
  </div>
</op-modal-header-base>
