<ng-container *ngIf="usersUsage">
  <button class="trigger"
          #menuTrigger="matMenuTrigger"
          [class.active]="menuTrigger.menuOpen"
          [matMenuTriggerFor]="overlay">
    {{ usersUsage.usersLoggedInLast30Days.length }} of {{ usersUsage.totalUsers }}
    <mat-icon>expand_more</mat-icon>
  </button>

  <mat-menu #overlay="matMenu"
            xPosition="before"
            class="usage-users-overlay"
            backdropClass="no-filter">
    <ng-template matMenuContent>
      <usage-users-overlay (click)="$event.stopPropagation()"
                           [usersUsage]="usersUsage"
      ></usage-users-overlay>
    </ng-template>
  </mat-menu>

  <div class="print-view">
    <usage-users-overlay (click)="$event.stopPropagation()"
                         [usersUsage]="usersUsage"
    ></usage-users-overlay>
  </div>
</ng-container>
