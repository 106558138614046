import { Component, Input } from '@angular/core';
import {
  ELeadingIconColor,
  EStatusButtonColor
} from '@app/components/shared/components/status-popover-button/status-popover-button.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'status-popover-button',
  templateUrl: './status-popover-button.component.html',
  styleUrls: ['./status-popover-button.component.scss']
})
export class StatusPopoverButtonComponent {
  @Input() advanced?: boolean = true;
  @Input() buttonText?: string;
  @Input() buttonColor?: EStatusButtonColor;
  @Input() leadingIcon?: string;
  @Input() trailingIcon?: string;
  @Input() leadingIconColor?: ELeadingIconColor;
}
