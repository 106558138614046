import { CacheResetService } from './../core/services/cache-reset.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../core/services/api.service';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from '@app/environments/environment';
import { map, tap } from 'rxjs/operators';
import { IUiTag, IUiTagCategory, IUiTagVendor } from '@app/components/tag-database/tag-database.model';

@Injectable()
export class UiTagService {
  readonly apiRootV3: string = environment.apiV3Url;
  readonly tagsUrl = this.apiRootV3 + 'tags';
  readonly tagCategoriesUrl = this.apiRootV3 + 'tag-categories';
  readonly tagVendorsUrl = this.apiRootV3 + 'tag-vendors';

  public responseCache = new Map();

  constructor(private apiService: ApiService, private cacheResetService: CacheResetService) {
    this.cacheResetService.reset$.subscribe(_ => {
      this.responseCache.clear();
    });
  }

  getTags(): Observable<IUiTag[]> {
    const url = this.tagsUrl;
    const cached = this.responseCache.get(url);

    return cached ? of(cached) : this.apiService.get<IUiTag[]>(url)
      .pipe(
        // Normalizing Tags to have IconUrl inside
        map((tags: IUiTag[]) => tags.map(t => ({ ...t, iconUrl: UiTagService.getTagIconUrl(t.id) }))),
        tap((tags: IUiTag[]) => this.responseCache.set(url, tags)),
      );
  }

  getTagVendors(): Observable<IUiTagVendor[]> {
    const url = this.tagVendorsUrl;
    const cached = this.responseCache.get(url);

    return cached ? of(cached) : this.apiService.get<IUiTagVendor[]>(url)
      .pipe(tap((tagVendors: IUiTagVendor[]) => this.responseCache.set(url, tagVendors)));
  }

  getTagCategories(): Observable<IUiTagCategory[]> {
    const url = this.tagCategoriesUrl;
    const cached = this.responseCache.get(url);

    return cached ? of(cached) : this.apiService.get<IUiTagCategory[]>(url)
      .pipe(tap((tagCategories: IUiTagCategory[]) => this.responseCache.set(url, tagCategories)));
  }

  getAllTagsData(): Observable<[IUiTag[], IUiTagVendor[], IUiTagCategory[]]> {
    return forkJoin([
      this.getTags(),
      this.getTagVendors(),
      this.getTagCategories(),
    ]);
  }

  /**
   * NOTE!!! This method should be called only if data is already loaded and cached, otherwise it will return undefined
   */
  getTagCategory(id: IUiTag['tagCategoryId']): IUiTagCategory | undefined {
    const cachedCategories = this.responseCache.get(this.tagCategoriesUrl);
    if (!cachedCategories) {
      this.getTagCategories().subscribe();
      return;
    }
    return cachedCategories.find(c => c.id === id);
  }

  /**
   * NOTE!!! This method should be called only if data is already loaded and cached, otherwise it will return undefined
   */
  getTagVendor(id: IUiTag['tagVendorId']): IUiTagVendor | undefined {
    const cachedVendors = this.responseCache.get(this.tagVendorsUrl);
    if (!cachedVendors) {
      this.getTagVendors().subscribe();
      return;
    }
    return cachedVendors.find(c => c.id === id);
  }

  static getTagIconUrl(id: IUiTag['id'] | string): string {
    return `${environment.tagIconUrl}${id}.svg`;
  }
}
