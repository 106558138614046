import { Route } from '@angular/router';
import { NoReadOnlyGuard } from '../core/permissions.guard';
import { AlertsLibraryComponent } from './alerts-library.component';
import { AlertsLibraryPaths, AlertsLibraryNames } from './alerts-library.constants';

export const AlertsLibraryState: Route = {
  path: AlertsLibraryPaths.base,
  loadChildren: () => import('./alerts-library.module').then(mod => mod.AlertsLibraryModule),
};

export const AlertsLibraryRoutes: Route = {
  path: '',
  canLoad: [ NoReadOnlyGuard ],
  component: AlertsLibraryComponent,
  data: {
    stateName: AlertsLibraryNames.base,
    title: 'Alerts Library',
    betaLabel: false,
  },
};
