import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-tag-image',
  templateUrl: './cl-tag-image.component.html',
  styleUrls: ['./cl-tag-image.component.scss']
})
export class ComponentLibraryTagImageComponent {
}
