<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Add Data Layer(s)"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="edit-domain-name">
      <ng-container *ngIf="editing">
        <mat-hint class="hint-desc">All subsequent Audit or Journey runs will automatically look for these 
            new data layers that show up as tags in the related reports.
        </mat-hint>
        <form [formGroup]="manageSubfolderForm">
          <mat-form-field appearance="outline" floatLabel="always">
            <mat-label class="label-title">Add Data Layer(s)</mat-label>
            <input matInput placeholder="Enter data layer(s)" formControlName="dataLayerField">
            <mat-hint>Enter a data layer object, for example: digitalData, dataLayer[1], etc. This field accepts a comma separated list of JavaScript objects or functions to be identified as data layers.</mat-hint>
          </mat-form-field>
        </form>
      </ng-container>
      <ng-container *ngIf="saving">
        <div class="center-elements">
          <mat-spinner [diameter]="25" [strokeWidth]="4"></mat-spinner>
          <span>Saving...</span>
        </div>
      </ng-container>
      <ng-container *ngIf="error">
        <div class="center-elements">
          <p>There was a problem updating the sub-folder.<br>Please refresh the page and try again.</p>
        </div>
      </ng-container>
    </div>
  </ng-template>
</op-modal>
