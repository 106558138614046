import * as angular from 'angular';
import { ScrollToInvalidFormDirective } from './scrollToInvalidFormDirective';
import { ValidateMessagesDirective } from './validateMessagesDirective';
import { ServerErrorDirective } from './validateUtils/serverErrorDirective';
import { validUrl } from './validateUtils/validUrlsDirective';
import { validEmail } from './validateUtils/validEmailsDirective';
import { validDuplicate } from './validateUtils/validDuplicate';
import { addProtocol } from './validateUtils/addProtocolDirective';
import { inputMaterial } from './inputMaterialDirective';

export default angular.module('formModule', [])
  .directive('inputMaterial', inputMaterial)
  .directive('scrollTo', ScrollToInvalidFormDirective.factory())
  .directive('validateMessages', ValidateMessagesDirective.factory())
  .directive('addProtocol', addProtocol)
  .directive('serverError', ServerErrorDirective.factory())
  .directive('validDuplicate', validDuplicate)
  .directive('validUrl', validUrl)
  .directive('validEmail', validEmail)
  .name;
