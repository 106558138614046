<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header
      title="Sample Modal"
      (onClose)="close()">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="sample-modal">
      <!-- Modal Content -->
    </div>
  </ng-template>
</op-modal>
