<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="onClosed.emit()">
  <ng-template #headerTemplate>
    <op-modal-header  [title]="modalTitle" (onClose)="onClosed.emit()"> </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="scroll-container">
      <form [formGroup]="ruleSetupForm">
        <op-tabs [tabs]="tabs" [useRouting]="false" [activeTab]="currentStep" (tabClicked)="goToStep($event)"></op-tabs>

        <rule-setup-general-tab [hidden]="currentStep !== ERuleSetupStep.General"
                                [formData]="generalGroup"
                                [allLabels]="allLabels"
                                (onCreateLabel)="createLabel($event)">
        </rule-setup-general-tab>

        <ng-container *ngIf="conditionsLoaded">
          <rule-setup-conditions-tab [hidden]="currentStep !== ERuleSetupStep.Conditions"
                                     [formData]="conditionsGroup"
                                     [allTags]="allTags"
                                     [hidePageMatching]="hidePageMatching">
          </rule-setup-conditions-tab>
        </ng-container>
      </form>
    </div>
  </ng-template>
</op-modal>
