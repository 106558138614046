<div class="table-wrap">
  <div class="top-section" [class.in-audit]="reportType === 'audit'" [ngClass]="{ shadowed: isScrolled }">
    <op-clearable-input [placeholderText]="'Filter Cookies'"
                        [defaultValue]="search"
                        (onUserInput)="filterTable($event)"
                        class="clearable-input">
    </op-clearable-input>
    <div class="switch-container">
      <mat-slide-toggle *ngIf="reportType === EReportType.AUDIT"
                        color="primary"
                        class="toggle-switch"
                        [checked]="showPreAuditActionRequests"
                        [disabled]="loading"
                        (change)="setPreAuditActionFlagAndLoadData($event.checked)">
        Show Pre-Audit Action Cookies
      </mat-slide-toggle>
    </div>
    <share-report-btn *ngIf="reportType === 'audit'"
                      [auditId]="itemId"
                      [runId]="runId"
                      [pageId]="pageId"
                      [activeTab]="activeTab">
    </share-report-btn>
  </div>

  <div class="spinner-container" *ngIf="loading">
    <mat-spinner [diameter]="25"
                 [strokeWidth]="4">
    </mat-spinner>
  </div>

  <div [class.hidden]="loading">
    <div class="insights">
      <div class="insight">
        <header>Unique Cookies</header>
        <div>{{ insightUniqueCookies }}</div>
      </div>
      <div class="insight">
        <header>Unique Domains</header>
        <div>{{ insightUniqueCookieDomains }}</div>
      </div>
      <div class="insight">
        <header>3rd Party Cookies</header>
        <div>{{ insightThirdPartyCookies }}</div>
      </div>
    </div>

    <table mat-table
           class="cookies-table"
           [class.hidden]="!this.dataSource.data.length"
           matSort
           [dataSource]="dataSource"
           multiTemplateDataRows
    >
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let cookie">
          <div class="icon-wrap">
            <mat-icon class="expand-arrow" [class.expanded]="cookie === expandedCookie">
              keyboard_arrow_right
            </mat-icon>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let cookie">
          {{ cookie.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="domain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
        <td mat-cell *matCellDef="let cookie">
          {{ cookie.domain }}
        </td>
      </ng-container>
      <ng-container matColumnDef="partyType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>1st/3rd<br> party</th>
        <td mat-cell *matCellDef="let cookie">{{cookie.partyType}}</td>
      </ng-container>
      <ng-container matColumnDef="partitionKey">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Partition</th>
        <td mat-cell *matCellDef="let cookie">{{cookie.partitionKey}}</td>
      </ng-container>
      <ng-container matColumnDef="initiators">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Initiator(s)</th>
        <td mat-cell *matCellDef="let cookie">{{cookie.initiators}}</td>
      </ng-container>
      <ng-container matColumnDef="origin">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
          MOST RECENT STATE
          <mat-icon [matTooltip]="originInfoTooltip"
                    matTooltipPosition="above"
                    matTooltipShowDelay="150">
          info_outline
          </mat-icon>
        </th>
        <td mat-cell *matCellDef="let cookie">{{cookie.origin}}</td>
      </ng-container>
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let cookie" [attr.colspan]="columnsToDisplay?.length">
          <div class="cookie-detail" [@detailExpand]="cookie === expandedCookie ? 'expanded' : 'collapsed'">
            <div class="cookie-values-heading">{{isAudit ? 'Final Existing Cookie Attributes' : 'Cookie Attributes When This Action Completed'}}</div>
            <div class="cookie-detail-wrap">
              <p class="detail-label">Name:
                <span class="detail-value"
                      [class.clickable-value]="isAudit"
                      [attr.op-selector]="'cookie-detail-name'"
                      (click)="isAudit ? navToCookieInventoryByName(cookie.name) : null"
                      [matTooltip]="isAudit ? 'View other pages in this audit where this cookie was set' : ''"
                      matTooltipPosition="above"
                      matTooltipShowDelay="150"
                >
                {{ cookie.name }}<mat-icon class="redirect-icon">east</mat-icon>
              </span>
              </p>
              <p class="detail-label">Value: <span class="detail-value">{{ cookie.value }}</span></p>
              <p class="detail-label">Domain: <span class="detail-value">{{ cookie.domain }}</span></p>
              <p class="detail-label">Path: <span class="detail-value">{{ cookie.path }}</span></p>
              <p class="detail-label" *ngIf="isAudit">Cookie Duration: <span class="detail-value">{{ cookie.duration }}</span></p>
              <p class="detail-label">
                Cookie Expiration Date:
                <span class="detail-value">{{ cookie.expires }}
                  <span matTooltip="Expiration calculated from time of page load"
                        [matTooltipPosition]="'above'"
                        [matTooltipShowDelay]="500"
                        *ngIf="cookie.duration && cookie.duration !== 'Session'"
                  >
                    ({{ cookie.duration }})
                  </span>
                </span>
              </p>
              <p class="detail-label">HttpOnly: <span class="detail-value">{{ cookie.httpOnly }}</span></p>
              <p class="detail-label">SameSite: <span class="detail-value">{{ cookie.sameSite }}</span></p>
              <p class="detail-label">Secure: <span class="detail-value">{{ cookie.secure }}</span></p>
              <p class="detail-label">1st/3rd Party: <span class="detail-value">{{ cookie.partyType }}</span></p>
              <p class="detail-label">Partition: <span class="detail-value">{{ cookie.partitionKey }}</span></p>
            </div>
            <div class="cookie-values-heading">
              ORIGIN STORY
            </div>
            <div class="cookie-detail-wrap">
              <div *ngIf="!cookie.loading && !cookie?.changes?.length">
                Unable to determine during page visit.
              </div>
              <div class="nested-grouping" [class.no-tab-grouping]="!isAudit">
                <cookie-origin-story *ngIf="!cookie.loading && cookie?.changes?.length"
                                     [cookie]="cookie"
                                     [dataSourceType]="reportType"
                                     [tags]="tags"
                                     [actionIndex]="actionIndex"
                                     (navToTab)="this.navToTab.emit($event)"
                                     (navToAction)="this.navToAction.emit($event)"
                ></cookie-origin-story>
                <div *ngIf="cookie.loading" class="spinner-wrapper">
                  <mat-spinner [diameter]="20" [strokeWidth]="3"></mat-spinner>
                </div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let cookie; columns: columnsToDisplay;"
          class="cookie-row"
          [class.expanded-row]="expandedCookie === cookie"
          (click)="toggleCookieDetailsView(cookie)">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </table>
    <p class="no-cookies-message" *ngIf="!this.dataSource.data.length">No cookies found</p>
  </div>
</div>
