import { EUsageFrequency } from '@app/components/usage-v2/usage-v2.models';

export enum EUsageFilterApiFilters {
  DataSources = 'dataSources',
  DataSourceFolders = 'dataSourceFolders',
  DataSourceLabels = 'dataSourceLabels',
  DataSourceCreators = 'dataSourceCreators',
  RunFrequencies = 'runFrequencies'
}

export enum EUsageFilterTypes {
  Folder = 'folder',
  Label = 'label',
  Frequency = 'frequency',
  Audits = 'audits',
  WebJourney = 'webJourney',
  Creator = 'creator',
}

export interface IUsageFrequency {
  id: EUsageFrequency;
  name: string;
  displayValue?: string;
}
export const UsageFrequencies = {
  NotScheduled: {
    id: EUsageFrequency.PAUSED,
    name: 'NotScheduled',
    displayValue: 'Not Scheduled'
  },
  Once: {
    id: EUsageFrequency.ONCE,
    name: 'Once'
  },
  Every15Minutes: {
    id: EUsageFrequency.EVERY_15_MINUTES,
    name: 'Every 15 Minutes'
  },
  Hourly: {
    id: EUsageFrequency.HOURLY,
    name: 'Hourly'
  },
  Every6Hours: {
    id: EUsageFrequency.EVERY_6_HOURS,
    name: 'Every 6 Hours'
  },
  Every12Hours: {
    id: EUsageFrequency.EVERY_12_HOURS,
    name: 'Every 12 Hours'
  },
  Daily: {
    id: EUsageFrequency.DAILY,
    name: 'Daily'
  },
  Weekly: {
    id: EUsageFrequency.WEEKLY,
    name: 'Weekly'
  },
  Biweekly: {
    id: EUsageFrequency.BIWEEKLY,
    name: 'Biweekly'
  },
  Monthly: {
    id: EUsageFrequency.MONTHLY,
    name: 'Monthly'
  },
  Quarterly: {
    id: EUsageFrequency.QUARTERLY,
    name: 'Quarterly'
  },
  SemiAnnually: {
    id: EUsageFrequency.SEMIANNUALLY,
    name: 'Semiannually'
  },
  Yearly: {
    id: EUsageFrequency.YEARLY,
    name: 'Yearly'
  }
};
