import { Route, Routes } from '@angular/router';
import { EmailInboxesComponent } from './email-inboxes.component';
import { EmailInboxesPaths, EmailInboxesStateNames } from './email-inboxes.constants';
import { EmailInboxMessagesComponent } from '@app/components/email-inboxes/email-inbox-messages/email-inbox-messages.component';

export const EmailInboxesFutureState: Route = {
  path: EmailInboxesPaths.base,
  loadChildren: () => import('./email-inboxes.module').then(mod => mod.EmailInboxesModule),
};

export const EmailInboxesRoutes: Routes = [
  {
    path: '',
    component: EmailInboxesComponent,
    data: {
      stateName: EmailInboxesStateNames.base,
      title: 'Email Inboxes'
    }
  },
  {
    path: ':id/messages',
    component: EmailInboxMessagesComponent,
    data: {
      stateName: EmailInboxesStateNames.base,
      title: 'Email Inbox'
    }
  }
];
