<div class="top-bar">
  <div class="title-container">Users</div>
  <div class="buttons-container">
    <button mat-flat-button
            color="primary"
            *ngIf="!adminPortalUsers"
            [disabled]="!selection.hasValue()"
            (click)="onShareFolders()">
      <mat-icon>share</mat-icon>
      Share Folders
    </button>
    <button mat-flat-button color="primary" [disabled]="!selection.hasValue()" (click)="onDeleteUsers()">
      <mat-icon>delete</mat-icon>
      Delete Users
    </button>
    <op-button-2021 labelText="New User"
                    matIcon="control_point"
                    (buttonAction)="onNewUser()"
                    buttonType="filled">
    </op-button-2021>
  </div>
</div>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>
<div class="user-table-wrap">
  <table mat-table matSort [dataSource]="dataSource" class="styled-mat-table" [class.loading]="loading">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      color="primary">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                      [disabled]="(adminPortalUsers && row.id === primaryUserId) || row.isMe || !row.isEditable"
                      [checked]="selection.isSelected(row)"
                      color="primary">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let user">{{ user.name }}</td>
    </ng-container>

    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Username</th>
      <td mat-cell *matCellDef="let user">{{ user.username }}</td>
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let user">{{ user.email }}</td>
    </ng-container>

    <!-- Page Limit Column -->
    <ng-container matColumnDef="pageLimit">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="page-limit-col-header">Audit Page Limit</th>
      <td mat-cell *matCellDef="let user" class="center-text">
        {{ user.pageLimit === 0 ? 'Unlimited' : user.pageLimit }}
      </td>
    </ng-container>

    <!-- Role Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let user">{{ user.role }}</td>
    </ng-container>

    <!-- Time Zone Column -->
    <ng-container matColumnDef="timezone">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Time Zone</th>
      <td mat-cell *matCellDef="let user">{{ user.timezone }}</td>
    </ng-container>

    <!-- Primary User Column -->
    <ng-container matColumnDef="isPrimary">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Primary User</th>
      <td mat-cell *matCellDef="let user">
        <mat-checkbox class="circle-checkbox"
                      [checked]="user.id === primaryUserId"
                      [disabled]="user.id === primaryUserId || user.role === userRoles['guest'] || user.role === userRoles['user']"
                      (click)="confirmChangePrimaryUser(user, $event)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Recent Activity Column -->
    <ng-container matColumnDef="activity">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Recent Activity</th>
      <td mat-cell *matCellDef="let user">{{ formatRecentActivity(user.activity) }}</td>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="created">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Created</th>
      <td mat-cell *matCellDef="let user">{{ user.created|date }}</td>
    </ng-container>

    <!-- Edit/Delete Column -->
    <ng-container matColumnDef="editDelete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user" align="right">
        <div class="btn-wrap">
          <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="user.isEditable">
            <mat-icon class="more-menu-btn">more_vert</mat-icon>
          </button>
        </div>
        <mat-menu #menu="matMenu" class="manage-users-menu">
          <button mat-menu-item
                  class="table-action-icon"
                  (click)="onEditUser(user)">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-menu-item
                  class="table-action-icon"
                (click)="onDeleteUser(user)"
                *ngIf="user.id !== primaryUserId">
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr *matNoDataRow>
      <td [colSpan]="7">
        <div *ngIf="!loading" class="no-data">
          <span>No users found.</span>
        </div>
      </td>
    </tr>
  </table>

  <mat-paginator class="styled-mat-paginator"
                 [pageSize]="20"
                 [hidePageSize]="true">
  </mat-paginator>

</div>
