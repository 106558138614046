<div class="op-summary-card-container"
     [class.highlight-widget]="highlight"
     [class.activated-widget]="activated"
     [matTooltip]="tooltipTextFormatted"
     [matTooltipDisabled]="!tooltipTextFormatted"
     matTooltipShowDelay="500"
     matTooltipPosition="above"
     [class.clickable]="cardClicked.observers.length"
     [class.widget-hovered]="widgetHover"
     (click)="cardClicked.emit()"
>
  <div class="header-container">
    <div class="label-wrapper">
      <mat-icon *ngIf="leftSideIcon"
                class="left-side-icon">
        {{ leftSideIcon }}
      </mat-icon>
      {{ headerText }}
      <mat-icon *ngIf="rightSideIcon"
                class="right-side-icon"
                [class.appear-on-hover]="rightSideIcon.appearOnHover">
        {{ rightSideIcon.iconClass }}
      </mat-icon>
    </div>
    <op-widget-bell *ngIf="metricType && (isVisitorMode$ | async) === false"
                    [auditId]="auditId"
                    [runId]="runId"
                    [widgetName]="metricType"
                    [widgetIsHovered]="widgetHover"
                    [currentValue]="currentValue"
                    [disableHighlightFromClick]="disableHighlightFromClick"
                    [user]="user"
                    [menuContext]="menuContext"
                    [filters]="filters"
    ></op-widget-bell>
  </div>
  <div class="value-container"
       [colorHighlight]="valueColorHighlight"
       [class.no-data]="noDataLabel">
    <span class="value result-filtered-value">{{ noDataLabel ? noDataLabel : filteredValue }}</span>
    <span class="value result-total-value"
          *ngIf="(widgetState === 'filtered' && showTotalValueWhenFiltered) || showBothNumbersAlways"
    >of {{ totalValue }}</span>
  </div>
  <filter-spinner [state]="widgetState"></filter-spinner>
</div>
