import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { IExportsResponse, IExportCenterTableState, IResendExportEmailRequest } from './export-center.models';

@Injectable()
export class ExportCenterService {

  private apiRoot = `${environment.apiV3Url}exports`;

  constructor(private apiService: ApiService) {}

  getExports(tableState: IExportCenterTableState, searchText: string): Observable<IExportsResponse> {
    let params = new HttpParams()
      .set('page', tableState.page.toString())
      .set('size', tableState.size.toString())
      .set('sortBy', tableState.sortBy)
      .set('sortDesc', tableState.sortDesc.toString());
    if (searchText) params = params.set('search', searchText);

    return this.apiService
      .get<IExportsResponse>(this.apiRoot, { params })
      .pipe(catchError(error => {
        console.error(error?.message ?? 'Failed to get exports');
        return throwError(error);
      }));
  }

  getExportsById(IDs: number[], tableState: IExportCenterTableState, searchText: string): Observable<IExportsResponse> {
    let params = new HttpParams();
    if (IDs) params = params.set('exportIds', IDs.join(','));
    if (tableState) {
      params = params.set('page', tableState.page.toString())
      .set('page', tableState.page.toString())
      .set('size', tableState.size.toString())
      .set('sortBy', tableState.sortBy)
      .set('sortDesc', tableState.sortDesc.toString());
    }
    if (searchText) params = params.set('search', searchText);

    return this.apiService
      .get<IExportsResponse>(this.apiRoot + "/", { params })
      .pipe(catchError(error => {
        console.error(error?.message ?? 'Failed to get exports');
        return throwError(error);
      }));
  }

  resendExportToEmails(exportId: number, body: IResendExportEmailRequest): Observable<void> {
    return this.apiService
      .post<void>(`${this.apiRoot}/${exportId}/email`, body)
      .pipe(catchError(error => {
        console.error(error?.message ?? 'Failed to resend export to the provided emails');
        return throwError(error);
      }));
  }

}
