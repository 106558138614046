import { ComponentChanges } from './../../models/commons';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, Input, forwardRef, OnChanges } from '@angular/core';
import { EAuditsPickerListMode } from './audits-picker-list/audits-picker-list.enums';
import { IAudit } from './audits-picker.models';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AuditsPickerComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audits-picker',
  templateUrl: './audits-picker.component.html',
  styleUrls: ['./audits-picker.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class AuditsPickerComponent implements OnChanges, ControlValueAccessor {

  @Input() audits: IAudit[] = [];

  onChange: (value: number[]) => void;

  readonly auditsPickerListMode = EAuditsPickerListMode;

  selectedAuditsIds: number[] = [];
  allowedAudits: IAudit[] = [];
  selectedAudits: IAudit[] = [];

  filteredName: string = '';
  filteredLabel: string = '';

  ngOnChanges(changes: ComponentChanges<AuditsPickerComponent>): void {
    if (changes.audits && changes.audits.currentValue !== changes.audits.previousValue) {
      this.calcAuditsLists();
    }
  }

  add(audit: IAudit | IAudit[]): void {
    this.selectedAuditsIds = this.isArray(audit) ?
      [...this.selectedAuditsIds, ...audit.map(a => a.id)] :
      [...this.selectedAuditsIds, audit.id];
    this.onChange(this.selectedAuditsIds);
    this.calcAuditsLists();
  }

  remove(audit?: IAudit): void {
    this.selectedAuditsIds = typeof audit === 'undefined' ?
      [] : this.selectedAuditsIds.filter(auditId => auditId !== audit.id);
    this.onChange(this.selectedAuditsIds);
    this.calcAuditsLists();
  }

  private isArray(item: IAudit | IAudit[]): item is IAudit[] {
    return Array.isArray(item);
  }

  calcAuditsLists(): void {
    this.selectedAudits = [];
    this.allowedAudits = []; 
    this.audits.forEach(audit => {
      const selected = this.selectedAuditsIds.includes(audit.id);
      if (!selected && (!this.hasSearchedName(audit) || !this.hasSearchedLabel(audit))) return;
      selected ? this.selectedAudits.push(audit) : this.allowedAudits.push(audit);
    });
  }

  private hasSearchedName(audit: IAudit): boolean {
    const searchedText = this.filteredName.trim().toLowerCase();
    return audit.name.toLowerCase().includes(searchedText);
  }

  private hasSearchedLabel(audit: IAudit): boolean {
    const searchedText = this.filteredLabel.trim().toLowerCase();
    return searchedText === '' || (audit.labels && !!audit.labels.find(label => label.name.toLowerCase().includes(searchedText)));
  }

  writeValue(selectedAuditsIds: number[]): void {
    if (!selectedAuditsIds) return;
    this.selectedAuditsIds = selectedAuditsIds;
    this.calcAuditsLists();
  }

  registerOnChange(fn: (value: number[]) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {}

}
