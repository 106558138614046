export enum EManageCardsFilterTypes {
  Name = 'name',
  Type = 'type',
  Schedule = 'schedule',
  Frequency = 'frequency',
  AuditStatus = 'auditStatus',
  JourneyStatus = 'journeyStatus',
  Folder = 'folder',
  Domain = 'domain',
  Label = 'label',
  Creator = 'creator',
  Monitored = 'monitored',
  RunDateRange = 'range',
}

export enum EManageCardsFilterBarMenuStrings {
  DataSourceType = 'Data Source Type',
  Audits = 'Audits',
  WebJourneys = 'Web Journeys',
  DataSourceSchedule = 'Data Source Schedule',
  DataSourceFrequency = 'Data Source Run Frequency',
  ScheduledToRun = 'Scheduled To Run',
  DataSourceFrequencyNotScheduled = 'Not Scheduled',
  DataSourceFrequencyOnce = 'Once',
  DataSourceFrequencyEvery15Minutes = 'Every 15 Minutes',
  DataSourceFrequencyHourly = 'Hourly',
  DataSourceFrequencyEvery6Hours = 'Every 6 Hours',
  DataSourceFrequencyEvery12Hours = 'Every 12 Hours',
  DataSourceFrequencyDaily = 'Daily',
  DataSourceFrequencyWeekly = 'Weekly',
  DataSourceFrequencyBiweekly = 'Biweekly',
  DataSourceFrequencyMonthly = 'Monthly',
  DataSourceFrequencyQuarterly = 'Quarterly',
  DataSourceFrequencySemiAnnually = 'Semiannually',
  DataSourceFrequencyYearly = 'Yearly',
  CurrentlyRunning = 'Currently Running',
  Paused = 'Paused',
  NotScheduled = 'Not Scheduled',
  AuditRunStatus = 'Audit Run Status',
  Successful = 'Successful',
  ActionFailure = 'Action Failure',
  RuleFailure = 'Rule Failure',
  InQueueRunning = 'In Queue/Running',
  AlertTriggers = 'Alert Triggers',
  JourneyRunStatus = 'Journey Run Status',
  FolderAndSubFolder = 'Folder & Sub-folder',
  Folders = 'Folders',
  Domain = 'Domain',
  Label = 'Label',
  RunDateRange = 'Run Date Range',
  Creator = 'Creator',
  Monitored = 'Monitored',
  OnlyShowMonitored = 'Only show Monitored Journeys',
  ShowAllMonitored = 'Show all Monitored & Unmonitored'
}

export enum EDataSourceTypeVals {
  audit,
  webJourney
}

export enum ECalendarDataSourceTypeVals {
  AUDIT,
  WEB_JOURNEY
}

export enum EDataSourceScheduleVals {
  ScheduledToRun,
  CurrentlyRunning,
  Paused,
  NotScheduled,
  Once
}

export enum EDataSourceFrequencyVals {
  NotScheduled = 'NotScheduled',
  Once = 'Once',
  Every15Minutes = 'Every 15 Minutes',
  Hourly = 'Hourly',
  Every6Hours = 'Every 6 Hours',
  Every12Hours = 'Every 12 Hours',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Biweekly = 'Biweekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  SemiAnnually = 'Semiannually',
  Yearly = 'Yearly',
}

export enum EJourneyRunStatusVals {
  Successful,
  ActionFailure,
  RuleFailure,
  InQueueRunning
}
