import { IWebJourneyV3 } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import * as ngRedux from 'ng-redux';
import * as angular from 'angular';

import {
  AngularNames,
  Events,
  Messages,
  Names,
  ServerErrorCodes
} from '@app/moonbeamConstants';
import {
  IWebJourneyAPIResponse,
  IWebJourneyResults,
  IWebJourneyRun
} from '../webJourneyDefinitions';
import {
  IWebJourney,
  IWebJourneyAction,
  IWebJourneyCreationRequest,
  IWebJourneyUpdateRequest,
  IWebJourneyServices
} from '@app/components/web-journey/web-journey.models';
import { IExportData, IResource } from '../../../utilities/utilitiesService';
import { ILabel } from '@app/components/shared/services/label.service';
import { IApiService, ISerializer } from '../../../api/apiService';
import { IJourneyResultSerializer } from '../webJourneySerializers/getJourneyResultSerializer';
import { IWebJourneyResultsSerializer } from '../webJourneySerializers/webJourneyResultsSerializer';
import { IEventManager } from '../../../eventManager/eventManager';
import { IHttpCallOnceService } from '../../../api/httpCallOnceService';
import { IProgressStatusWrapper } from '../../../reporting/statusBanner/statusBannerService';
import { ToastService } from '../../../utilities/toastService';
import { environment } from '@app/environments/environment';
import { StorageService } from '@app/components/shared/services/storage.service';
import { ITimeSeriesRuns } from '@app/components/reporting/widgets/timeSeries/timeSeries';
import { FeatureFlagHelper } from '@app/environments/feature-flag-helper';
import { FeatureFlags } from '@app/environments/feature-flags.enum';
import { IRule } from '@app/components/rules/rules.models';
import { IEasyBlockTags } from '../../discoveryAudits/discoveryAuditModels';
import {
  SnackbarErrorComponent
} from '@app/components/shared/components/snackbars/snackbar-error/snackbar-error.component';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';

export interface IJourneyRunJson {
  completedAt: string;
  simulationId: number;
  id: number;
  status: string;
}

export abstract class IWebJourneyApiService {
  abstract getAllWebJourneys(): angular.IPromise<Array<IWebJourney>>;
  abstract getWebJourneyExportDownloadLink(
    runId: number
  ): angular.IPromise<IExportData>;
  abstract updateJourney(
    data: IWebJourneyUpdateRequest
  ): angular.IPromise<IWebJourney>;
  abstract deleteJourney(journeyId: number): angular.IPromise<boolean>;
  abstract getWebJourneyStatusProgress(journeyId: number, runId: number);
  abstract updateJourneyRules(journeyId: number, rules: Array<number>);
  abstract createJourney(
    data: IWebJourneyCreationRequest
  ): angular.IPromise<IWebJourney>;
  abstract getJourney(journeyId: number): angular.IPromise<IWebJourney>;
  abstract getJourneyRules(journeyId: number): angular.IPromise<Array<IRule>>;
  abstract getJourneyLastRun(
    journeyId: number
  ): angular.IPromise<IWebJourneyRun>;
  abstract getJourneyRun(
    journeyId: number,
    runId: number
  ): angular.IPromise<IWebJourneyRun>;
  abstract getJourneyRuns(
    journeyId: number
  ): angular.IPromise<Array<ITimeSeriesRuns>>;
  abstract getWebJourneyRuns(
    journeyId: number
  ): angular.IPromise<Array<IWebJourneyRun>>;
  abstract getJourneyResult(
    journeyId: number,
    runId: number
  ): angular.IPromise<IWebJourneyResults>;
  abstract getActionsForWebJourney(
    webJourneyId: number
  ): angular.IPromise<Array<IWebJourneyAction>>;
  abstract getLatestWebJourney(): angular.IPromise<IWebJourney>;
  abstract runWebJourneyNow(webJourney: IWebJourney | IWebJourneyV3): angular.IPromise<any>;
  abstract getWebJourneyLabels(
    journeyId: number
  ): angular.IPromise<Array<ILabel>>;
  abstract updateWebJourneyLabels(
    journeyId: number,
    labels: Array<ILabel>
  ): angular.IPromise<Array<ILabel>>;
  abstract deleteWebJourneyLabel(
    journeyId: number,
    labelId: number
  ): angular.IPromise<any>;
  abstract getScreenshotsExportDownloadLink(
    journeyId: number,
    runId: number
  ): angular.IPromise<IResource>;
  abstract fixJourney(
    journeyId: number,
    runId: number
  ): angular.IPromise<IResource>;
  abstract stopActiveWebJourneyRun(
    journeyId: number,
    runId: number
  ): angular.IPromise<boolean>;
  abstract getScriptServicesCounts(): angular.IPromise<IWebJourneyServices>;
  abstract getEasyBlockTagIds(journeyId: number): angular.IPromise<IEasyBlockTags>;
  abstract updateEasyBlockTagIds(
    journeyId: number,
    tagIds: IEasyBlockTags
  ): angular.IPromise<IEasyBlockTags>;
}

interface IJourneyRequest<T> {
  (arg: T): T
}

const Call = {
  get: 'get',
  post: 'post',
  put: 'put',
  del: 'delete'
};

export class WebJourneyAPIService extends IWebJourneyApiService {
  apiUrl: string = environment.apiUrl + 'web-journeys';
  apiUploadUrl: string = environment.apiUploadAppUrl + 'web-journeys';
  apiV3Url: string = environment.apiV3Url + 'web-journeys';
  spinnerKey: string = 'web-journey-spinner';

  static $inject = [
    AngularNames.q,
    AngularNames.ngRedux,
    AngularNames.http,
    Names.Services.api,
    Names.Services.journeyResultSerializer,
    Names.Services.webJourneyResultsSerializer,
    Names.Services.eventManager,
    Names.Services.storageService,
    Names.Services.webJourneyRunsSerializer,
    Names.Services.httpCallOnce,
    Names.Services.ngToast,
    Names.Services.toastService,
    Names.NgServices.snackbarService,
  ];

  constructor(
    private $q: angular.IQService,
    private $ngRedux: ngRedux.INgRedux,
    private $http: angular.IHttpService,
    private apiService: IApiService,
    private journeyResultSerializer: IJourneyResultSerializer,
    private webJourneyResultsSerializer: IWebJourneyResultsSerializer,
    private eventManager: IEventManager,
    private storageService: StorageService,
    private webJourneyRunsSerializer: ISerializer<Array<ITimeSeriesRuns>>,
    private httpCallOnce: IHttpCallOnceService,
    private ngToast: any,
    private toastService: ToastService,
    private snackbarService: SnackbarService,
  ) {
    super();
  }

  getAllWebJourneys(): angular.IPromise<Array<IWebJourney>> {
    var url = this.apiUrl + `/all-info`;
    return this.callApiService(Call.get, url);
  }

  getJourney(journeyId: number): angular.IPromise<IWebJourney> {
    var url = `${this.apiUrl}/${journeyId}`;
    return this.callApiService(Call.get, url);
  }

  getJourneyRules(journeyId: number): angular.IPromise<Array<IRule>> {
    var url = `${this.apiUrl}/${journeyId}/rules`;
    return this.callApiService(Call.get, url);
  }

  getJourneyRun(
    journeyId: number,
    runId: number
  ): angular.IPromise<IWebJourneyRun> {
    var url = this.apiUrl + `/${journeyId}/runs/${runId}`;
    return this.callApiService(Call.get, url);
  }

  getWebJourneyStatusProgress(
    journeyId: number,
    runId: number
  ): angular.IPromise<IProgressStatusWrapper> {
    var url = this.apiUrl + '/' + journeyId + '/runs/' + runId + '/progress';
    return this.callApiService(Call.get, url);
  }

  getActionsForWebJourney(
    webJourneyId: number
  ): angular.IPromise<Array<IWebJourneyAction>> {
    var url = this.apiUrl + `/${webJourneyId}/actions`;
    return this.callApiService(Call.get, url);
  }

  getLatestWebJourney(): angular.IPromise<IWebJourney> {
    var url = this.apiUrl + '/latest';
    return this.callApiService(Call.get, url);
  }

  getWebJourneyRuns(
    journeyId: number
  ): angular.IPromise<Array<IWebJourneyRun>> {
    if (!journeyId) {
      return this.$q.reject('journeyId is not defined');
    }
    var url = this.apiUrl + `/${journeyId}/runs`;
    return this.callApiService(Call.get, url);
  }

  getJourneyResult(
    journeyId: number,
    runId: number
  ): angular.IPromise<IWebJourneyResults> {
    var url = `${this.apiUploadUrl}/${journeyId}/runs/${runId}/results`;
    return this.callApiService(
      Call.get,
      url,
      null,
      (data: any) => this.journeyResultSerializer.serialize(data) as any
    );
  }

  getJourneyRuns(journeyId: number): angular.IPromise<Array<ITimeSeriesRuns>> {
    var url = `${this.apiUrl}/${journeyId}/runs`;
    return this.handleSerializerResponse(
      this.callApiService(
        Call.get,
        url,
        null,
        (data: any) => this.webJourneyRunsSerializer.serialize(data) as any
      )
    );
  }

  getJourneyLastRun(journeyId: number): angular.IPromise<IWebJourneyRun> {
    var url = `${this.apiUrl}/${journeyId}/runs`;
    return this.handleSerializerResponse(
      this.callApiService(
        Call.get,
        url,
        null,
        (data: any) =>
          this.webJourneyResultsSerializer.serializeJourneyLastRun(data) as any
      )
    );
  }

  createJourney(
    data: IWebJourneyCreationRequest
  ): angular.IPromise<IWebJourney> {
    return this.callApiService(Call.post, this.apiUrl, data);
  }

  getWebJourneyExportDownloadLink(
    runId: number
  ): angular.IPromise<IExportData> {
    //TODO : Refactor to use new endpoint : {journeyId}/runs/{runId}/export
    var url = `${environment.apiUrl}report/web-journey/export?run_id=${runId}`;
    return this.callApiService(Call.post, url, {});
  }

  runWebJourneyNow(webJourney: IWebJourney | IWebJourneyV3): angular.IPromise<any> {
    var url = `${this.apiUrl}/${webJourney.id}/runs`;
    return this.callApiService(Call.post, url, {}).catch(error => {
      if (error.errorCode === ServerErrorCodes.overageLimitExceeded) {
        this.showErrorSnackbar(`The account run limit has been reached. The web journey ${webJourney.name} cannot be run.`);
      } else if (error.errorCode === ServerErrorCodes.alreadyRunning) {
        this.ngToast.create(
          this.toastService.generateTwoLinesToastConfig(
            `The journey "${
              webJourney.name
            }" is not able to run again because it is currently running`,
            ''
          )
        );
      } else if (
        error.errorCode === ServerErrorCodes.accountConcurrencyReached
      ) {
        this.ngToast.create(
          this.toastService.generateTwoLinesToastConfig(
            `The journey "${
              webJourney.name
            }" is not able to run because your account has reached the maximum number of concurrent journeys`,
            ''
          )
        );
      } else if (error.code === 500) {
        this.ngToast.create(
          this.toastService.generateTwoLinesToastConfig(
            'Something went wrong',
            ''
          )
        );
      }
      return error;
    });
  }

  private showErrorSnackbar(message: string) {
    this.snackbarService.openFromComponent(SnackbarErrorComponent, {
      duration: 7000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {message}
    });
  }

  updateJourney(data: IWebJourneyUpdateRequest): angular.IPromise<IWebJourney> {
    var url = `${this.apiUrl}/${data.id}`;
    return this.callApiService(Call.put, url, data);
  }

  updateJourneyRules(journeyId: number, rules: Array<number>) {
    var url = `${this.apiUrl}/${journeyId}/rules`;
    return this.callApiService(Call.put, url, rules);
  }

  deleteJourney(journeyId: number): angular.IPromise<boolean> {
    var url = `${this.apiV3Url}/${journeyId}`;
    return this.callApiService(Call.del, url).then(() => {
      this.storageService.removeParamsForWebJourneys();
      return true;
    });
  }

  getWebJourneyLabels(journeyId: number): angular.IPromise<Array<ILabel>> {
    return this.callApiService(Call.get, `${this.apiUrl}/${journeyId}/labels`);
  }

  updateWebJourneyLabels(
    journeyId: number,
    labels: Array<ILabel>
  ): angular.IPromise<Array<ILabel>> {
    return this.callApiService<ILabel[]>(
      Call.put,
      `${this.apiUrl}/${journeyId}/labels`,
      labels
    );
  }

  deleteWebJourneyLabel(
    journeyId: number,
    labelId: number
  ): angular.IPromise<any> {
    return this.callApiService(
      Call.del,
      `${this.apiUrl}/${journeyId}/labels/${labelId}`
    );
  }

  getScreenshotsExportDownloadLink(
    journeyId: number,
    runId: number
  ): angular.IPromise<IResource> {
    return this.callApiService(
      Call.get,
      `${this.apiUrl}/${journeyId}/runs/${runId}/screenshots`
    );
  }

  fixJourney(
    journeyId: number,
    runId: number
  ): angular.IPromise<IResource> {
    return this.callApiService(
      Call.post,
      `${this.apiUrl}/${journeyId}/runs/${runId}/actions/fix`
    );
  }

  stopActiveWebJourneyRun(journeyId: number, runId: number): angular.IPromise<boolean> {
    let endpoint = FeatureFlagHelper.isEnabled(FeatureFlags.SQSJourneyEngines)
      ? `${this.apiV3Url}/${journeyId}/runs/${runId}`
      : `${this.apiUrl}/${journeyId}/runs/current`;

    return this.callApiService(
      Call.del,
      endpoint
    ).then(() => true, () => false);
  }

  getScriptServicesCounts(): angular.IPromise<IWebJourneyServices> {
    return this.callApiService(Call.get, `${this.apiUrl}/services/counts`);
  }

  getEasyBlockTagIds(journeyId: number): angular.IPromise<IEasyBlockTags> {
    return this.apiService.handleResponse(
      this.$http.get(`${this.apiV3Url}/${journeyId}/blocking-configuration`)
    );
  }

  updateEasyBlockTagIds(journeyId: number, tagIds: IEasyBlockTags): angular.IPromise<IEasyBlockTags> {
    return this.apiService.handleResponse(
      this.$http.put(`${this.apiV3Url}/${journeyId}/blocking-configuration`, tagIds)
    );
  }

  private callApiService<T>(
    call: string,
    url: string,
    data?: any,
    serializer?: IJourneyRequest<T>
  ): angular.IPromise<T> {
    var http = this.httpCallOnce[call](url, data);
    return this.handleJourneyResponse(
      this.apiService.handleResponse(http, null, this.spinnerKey),
      serializer
    );
  }

  private handleJourneyResponse<T>(
    promise: angular.IPromise<T>,
    serializer: IJourneyRequest<T>
  ): angular.IPromise<T> {
    return promise.then(
      (data: T) => {
        if (serializer) {
          return serializer(data);
        }
        return data;
      },
      error => {
        if (error) {
          return this.$q.reject(error);
        }
      }
    );
  }

  private handleSerializerResponse<T>(
    promise: angular.IPromise<T>
  ): angular.IPromise<T> {
    return promise.then(
      (data: T) => {
        return data;
      },
      error => {
        return this.$q.reject(new Error(error.message));
      }
    );
  }
}
