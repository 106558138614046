import { EDatasourceTagType } from '../../comparisons.enums';

export const datasourceAllTagsOnly = {
  key: 0,
  name: 'All (Tag Presence Only)'
};

export const defaultDatasource = {
  tagId: -1, 
  type: EDatasourceTagType.AllVariables 
};
