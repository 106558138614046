import { Injectable } from '@angular/core';
import { ActivatedRoute, UrlTree, UrlSegmentGroup, PRIMARY_OUTLET, Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, share, shareReplay } from 'rxjs/operators';

export interface IRouteData {
  stateName: string;
  title?: string;
  fullScreen?: boolean;
  breadcrumb?: IRouteDataBreadcrumb;
}

export interface IRouteDataBreadcrumb {
  title: string;
}

/**
 * Provides helpful functions for getting route-specific data (path / query params, URL segments, etc)
 */
@Injectable({
  providedIn: 'root'
})
export class RouteDataService {

  /**
   * Notifies subscribers about new route's data on route change
   */
  routeData$: Observable<IRouteData>;

  constructor(private router: Router,
    private route: ActivatedRoute) { }

  init() {
    this.routeData$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd), // only successful navigation
      map(() => this.route), // get activated route
      map(route => { // get last activated route
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === PRIMARY_OUTLET), // only primary outlet
      mergeMap(route => route.data), // get data
      shareReplay(1)
    ) as Observable<IRouteData>;

    this.routeData$.subscribe();
  }

  /**
   * This function is used for getting url segments,
   * regardless of whether they belong to this route, or parent route
   * 
   * Example:
   *  - parent route: `container`
   *  - child route: `item`
   * This function will return the following [`container`, `item`]
   * 
   * @param urlTree - url can be parsed into URL Tree, that is used for getting url segments
   * @param outlet - outlet name, for which url segments are fetched
   */
  static getUrlSegments(urlTree: UrlTree, outlet = PRIMARY_OUTLET): string[] {
    let segments = [];
    if (!urlTree || !urlTree.root) return [];
    const root = urlTree.root;
    const rootSegments = root.segments.map(segment => segment.path);
    segments.push(rootSegments);

    if (root.children[outlet]) {
      const childSegments = RouteDataService.getChildRouteSegments(root.children[outlet]);
      segments.push(childSegments);
    }

    return segments.flat();
  }

  private static getChildRouteSegments(group: UrlSegmentGroup, outlet = PRIMARY_OUTLET): string[] {
    const segments = group.segments.map(segment => segment.path);
    if (group.children?.[outlet]) {
      segments.concat(
        RouteDataService.getChildRouteSegments(group.children[outlet])
      );
    }
    return segments;
  }

}
