<component-template componentName="OP Select Create">
  <component-library-section sectionName="Overview" selector="<op-select-create>">
    <p>This is the op-select-create component.</p>

    <div class="op-select-create-example">
      Default:
      <op-select-create [data]="data"></op-select-create>
    </div>

    <div class="op-select-create-example">
      Outlined:
      <op-select-create [data]="data" [appearance]="'outline'"></op-select-create>
    </div>
  </component-library-section>
</component-template>