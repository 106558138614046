import { Component, OnInit } from '@angular/core';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-label-manager',
  templateUrl: './cl-label-manager.component.html',
  styleUrls: ['./cl-label-manager.component.scss']
})
export class ComponentLibraryLabelManagerComponent implements OnInit {

  labels: ILabel[] = [];
  example1selectedLabels: ILabel[] = [];
  example2selectedLabels: ILabel[] = [];
  example3selectedLabels: ILabel[] = [];
  example4selectedLabels: ILabel[] = [];
  example5selectedLabels: ILabel[] = [];

  constructor(private labelService: LabelService) {}

  ngOnInit(): void {
    this.labelService.getLabels().subscribe((labels: ILabel[]) => {
      this.labels = labels;

      // pick 5 random labels and add to selectedLabels
      this.example2selectedLabels = this.labels.sort(() => 0.5 - Math.random()).slice(0, 3);
      this.example4selectedLabels = this.labels.sort(() => 0.5 - Math.random()).slice(0, 3);
      this.example5selectedLabels = this.labels.sort(() => 0.5 - Math.random()).slice(0, 3);
    });
  }

  handleLabelCreated(label: ILabel, selectedLabelsRef: ILabel[]) {
    selectedLabelsRef = selectedLabelsRef.filter((label: ILabel) => !!label.id);
    selectedLabelsRef.push(label);
    selectedLabelsRef = [ ...selectedLabelsRef ];
  }

  handleLabelSelected(label: ILabel, selectedLabelsRef: ILabel[]) {
    selectedLabelsRef = [ ...selectedLabelsRef ];
  }

  handleLabelRemoved(label: ILabel, selectedLabelsRef: ILabel[]) {
    selectedLabelsRef = selectedLabelsRef.filter((l: ILabel) => l.id !== label.id);
    selectedLabelsRef = [...selectedLabelsRef];
  }
}
