import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  IFullscreenChartConfig,
  IFullscreenChartConfigWebVitals,
  IFullscreenChartData,
  IFullscreenChartDataFormatted
} from '../fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { Selection } from 'd3-selection';
import * as d3 from 'd3';
import { ScaleLinear, ScaleTime } from 'd3';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  EWebVitalsFullScreenChartTooltipType,
  WebVitalsFullScreenChartTooltips
} from '@app/components/shared/components/viz/web-vitals-full-screen-chart/web-vitals-full-screen-chart.constants';
import { DecimalPipe } from '@angular/common';
import { EPageInfoTrendNames } from '@app/components/audit-reports/page-information/page-information.constants';

@Component({
  selector: 'op-web-vitals-full-screen-chart',
  templateUrl: './web-vitals-full-screen-chart.component.html',
  styleUrls: ['./web-vitals-full-screen-chart.component.scss']
})
export class WebVitalsFullScreenChartComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private resize$ = new Subject();
  private destroy$ = new Subject();

  svgWidth: number;
  svgHeight: number;
  container;
  svg;  // main svg element that groups are all appended to
  line; // line graph of data points
  limitLine; // line graph of data points
  areaChart;
  margin;
  xScale: ScaleTime<any, any, never>;  // x-scale
  yScale: ScaleLinear<any, any, any>;  // y-scale
  xAxis;
  gXAxis;
  yAxis;
  gYAxis;
  g;
  // zoom;
  chartConfig: IFullscreenChartConfig = null;
  darkTheme: boolean;
  focus;
  summaryLineTooltip: Selection<any, any, any, any>;
  summaryLineTooltipText: Selection<any, any, any, any>;

  windowWidth: number = window.innerWidth;
  tooltip: Selection<d3.BaseType, unknown, HTMLElement, any>;
  private readonly TOOLTIP_SCREEN_EDGE_GAP: number = 120;

  private _tooltipType: EWebVitalsFullScreenChartTooltipType = EWebVitalsFullScreenChartTooltipType.PAGE_SUMMARY;

  @Input() set tooltipType(type: EWebVitalsFullScreenChartTooltipType) {
    if (!type) {
      return;
    }
    this._tooltipType = type;
  }

  get tooltipType(): EWebVitalsFullScreenChartTooltipType {
    return this._tooltipType;
  }

  @Input() config: IFullscreenChartConfigWebVitals;
  // @Input() boundaries: IWebVitalsChartColorizedBoundaries;
  // @Input() stats: IWebVitalsChartColorizedStats;
  @Output() sectionClicked: EventEmitter<any> = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
  }

  ngOnInit(): void {
    this.darkTheme = document.getElementsByTagName('body')[0].className.includes('dark-theme');
    this.resize$.pipe(debounceTime(200), takeUntil(this.destroy$)).subscribe(() => {
      this.createChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.chartConfig = changes.config.currentValue;
      this.processData();
      this.createChart();
    }
  }

  ngAfterViewInit(): void {
    this.processData();

    // giving extra time so measurements are accurate
    setTimeout(() => this.createChart());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  processData(): void {
    this.chartConfig = { ...this.config };
    this.chartConfig.data = (this.config.data as IFullscreenChartData[]).map((item: IFullscreenChartData) => {
      const date = new Date(item.date);
      const value = item.value;
      return { date, value, data: item.data };
    }).sort((a, b) => a.date < b.date ? -1 : 1);
  }

  createChart(): void {
    if (this.chartConfig.data.length <= 1) return;
    this.createMargins();
    this.createSvg();
    this.createScales();
    this.createLineChart();
    this.createXAxis();
    this.createYAxis();
    this.createDatapointCircles();
    this.createThresholds();
  }

  createMargins(): void {
    this.margin = {
      top: 60,
      right: 60,
      bottom: 125,
      left: 100
    };
  }

  createSvg(): void {
    // Clear old svg before redrawing if necessary
    if (this.svg) this.svg.remove();

    const svgContainer = '.wv-chart-svg-container';
    this.container = d3.select(svgContainer);
    this.container.classed('web-vitals-full-screen-chart', true);
    if (this.container.empty()) { // if selection is empty, any methods called on it will result in error
      return;
    }
    this.svgHeight = parseInt(this.container.style('height'));
    this.svgWidth = parseInt(this.container.style('width'));

    this.svg = this.container.append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight)
      .attr('viewBox', [0, 0, this.svgWidth, this.svgHeight]);

    this.g = this.svg.append('g');
  }

  createScales(): void {
    // set range to svg inner dimensions (adjusted for margins)
    const ranges = this.calculateScaleRanges();

    this.xScale = d3.scaleTime()
      .domain(d3.extent(this.chartConfig.data, (d: any) => d.date)) // add domain to time scale for min/max dates
      .rangeRound([ranges.x.start, ranges.x.end]); // range is width of graph

    this.yScale = d3.scaleLinear()
      .domain([0, d3.max([...this.chartConfig.data, { value: 0 }], (d: any) => d.value)]) // add domain to value scale for min/max values
      .rangeRound([ranges.y.end, ranges.y.start]);  // range is height of graph
  }

  private calculateScaleRanges(): { x: { start: number; end: number; }, y: { start: number; end: number; } } {
    return {
      x: {
        start: this.margin.left,
        end: this.svgWidth - this.margin.right
      },
      y: {
        start: this.margin.top,
        end: this.svgHeight - this.margin.bottom
      }
    };
  }

  createXAxis(): void {
    const xTickPadding = 20;

    this.xAxis = d3.axisBottom(this.xScale)
      .tickFormat(d3.timeFormat('%b %_d, %Y'))
      .tickPadding(xTickPadding)
      .tickSizeInner(0)
      .tickSizeOuter(0);

    this.gXAxis = this.g.append('g')
      .attr('transform', `translate(0, ${this.svgHeight - this.margin.bottom})`)
      .call(this.xAxis)
      .call(g => g.select('.domain').remove());

    const xAxisText = this.gXAxis.selectAll('text');

    xAxisText
      .attr('class', 'area-chart-x-axis')
      .attr('transform', 'rotate(-65)')
      .attr('x', -35);
  }

  createYAxis(): void {
    const yTickPadding = 20;

    this.yAxis = d3.axisLeft(this.yScale)
      .tickSize(-this.svgWidth + this.margin.left + this.margin.right)
      .tickPadding(yTickPadding)
      .tickSizeOuter(0);

    this.gYAxis = this.g.append('g')
      .attr('transform', `translate(${this.margin.left}, 0)`)
      .attr('class', 'area-chart-axis-line')
      .call(this.yAxis)
      .call(g => g.select('.domain').remove());

    // Add label
    this.g
      .append('text')
      .call(g => g.select('.domain').remove())
      .attr('class', 'y-axis-label')
      .attr('transform', `translate(0, ${this.margin.top - 40})`)
      .attr('y', 6)
      .attr('dy', 0)
      .attr('dx', 65)
      .attr('text-anchor', 'start')
      .text(this.chartConfig.sideLabel);

    // set opacity on horizontal grid lines
    this.gYAxis
      .selectAll('.tick line')
      .attr('opacity', 0.1);

    // set color of tick marks
    this.gYAxis
      .selectAll('text')
      .attr('class', 'area-chart-y-axis');
  }

  // draw line at top of area chart
  createLineChart(): void {
    this.line = d3.line()
      .x((d: any) => this.xScale(d.date))
      .y((d: any) => this.yScale(d.value));

    const data = this.chartConfig.data as any;

    // create a path for each segment between points with the appropriate class
    data.forEach((d: any, i: number) => {
      if (i < data.length - 1) {
        const segment = [d, data[i + 1]];
        const valueToInterpret = data[i + 1].value;
        const classSuffix = this.interpretValue(valueToInterpret);
        const lineClass = `line${classSuffix}`;

        this.g.append('path')
          .attr('class', lineClass)
          .attr('fill', 'none')
          .attr('stroke-width', '3px')
          .attr('d', this.line(segment));
      }
    });
  }

  private interpretValue(value: number): string {
    if (value < this.config.boundaries.warnThreshold) {
      return '-pass';
    } else if (value < this.config.boundaries.failThreshold) {
      return '-warn';
    } else {
      return '-fail';
    }
  }

  createDatapointCircles = (): void => {
    const circleSize = 5;
    const circleHoverSize = 7.5;
    const lineCircleClass = 'line-circle';

    const circles = this.g
      .selectAll('circle')
      .data(this.chartConfig.data)
      .enter()
      .append('circle')
      .attr('class', d => `${lineCircleClass} ${lineCircleClass}${this.interpretValue(d.value)}`)
      .attr('r', circleSize)
      .attr('cx', d => this.xScale(d.date))
      .attr('cy', d => this.yScale(d.value));

    circles.on('mouseenter', (e, d) => {
      // draw tooltip
      this.showDatapointTooltip(d);
    });

    circles.on('mouseover', (e: MouseEvent, d) => {
      // grow circles
      d3.select(e.currentTarget as SVGCircleElement)
        .attr('r', circleHoverSize)
        .attr('class', `${lineCircleClass} line-circle-hover ${lineCircleClass}${this.interpretValue(d.value)}`);
    });

    circles.on('mouseout', (e: MouseEvent, d) => {
      // back to regular circles
      d3.select(e.currentTarget as SVGCircleElement)
        .attr('r', circleSize)
        .attr('class', `${lineCircleClass} ${lineCircleClass}${this.interpretValue(d.value)}`);

      d3.select('#datapoint-tooltip')
          .style('transform', 'unset')
          .style('visibility', 'hidden');
    });
  };

  private showDatapointTooltip(
    d: IFullscreenChartDataFormatted,
    tooltipType: EWebVitalsFullScreenChartTooltipType = this.tooltipType,
  ): void {
    const x = this.xScale((d.date as Date));
    const y = this.yScale(d.value);
    const pipe = new DecimalPipe('en-US');
    let valueTransformFn;

    switch (this.config.metricType) {
      case EPageInfoTrendNames.FIRST_CONTENTFUL_PAINT:
      case EPageInfoTrendNames.LARGEST_CONTENTFUL_PAINT:
        valueTransformFn = (value: number) => pipe.transform(value, '1.3');
        break;
      case EPageInfoTrendNames.TIME_TO_FIRST_BYTE:
        valueTransformFn = (value: number) => pipe.transform(value, '1.0-3');
        break;
    }

    d3.select('#datapoint-tooltip')
      .style('visibility', 'visible')
      .style('top', `${y}px`)
      .style('left', `${x}px`)
      .html(WebVitalsFullScreenChartTooltips[tooltipType](d, this.chartConfig, valueTransformFn));

    const tooltipSize = document.querySelectorAll('#datapoint-tooltip')[0]?.getBoundingClientRect();

    if (tooltipSize) {
      const overlapSizeY = window.screen.availHeight - (tooltipSize.top + tooltipSize.height);
      const overlapSizeX = window.screen.availWidth - (tooltipSize.left + tooltipSize.width);

      d3.select('#datapoint-tooltip').style('transform',
          `translate(${overlapSizeX < this.TOOLTIP_SCREEN_EDGE_GAP ? '-105%' : '5%'}, ${overlapSizeY < this.TOOLTIP_SCREEN_EDGE_GAP ? '-105%' : '5%'})`);
    }
  }

  createThreshold(value: number, className: string, thresholdLabel: string) {
    if (!this.yAxis || !this.g) {
      return;
    }

    const yPosition = this.yScale(value);

    // append the actual visible threshold line
    this.g.append('line')
      .attr('x1', this.margin.left)
      .attr('y1', yPosition)
      .attr('x2', this.svgWidth - this.margin.right)
      .attr('y2', yPosition)
      .attr('stroke', 'black')
      .attr('stroke-width', 2)
      .attr('stroke-dasharray', '5,5')
      .attr('class', `threshold-line ${className}`);

    // append an invisible line for easier hover interaction
    this.g.append('line')
      .attr('x1', this.margin.left)
      .attr('y1', yPosition)
      .attr('x2', this.svgWidth - this.margin.right)
      .attr('y2', yPosition)
      .attr('stroke', 'transparent')
      .attr('stroke-width', 10)
      .attr('class', 'threshold-line-wrapper')
      .attr('data-threshold-label', thresholdLabel);

    this.createThresholdTooltip();
  }

  createThresholds(): void {
    const pipe = new DecimalPipe('en-US');
    if (this.config.boundaries) {
      const warnTooltip = `Values below ${pipe.transform(this.config.boundaries.warnThreshold)} ${this.chartConfig.sideLabel} are considered good as defined by Google`;
      const failTooltip = `Values at or above ${pipe.transform(this.config.boundaries.failThreshold)} ${this.chartConfig.sideLabel} are considered poor as defined by Google`;

      this.createThreshold(this.config.boundaries.warnThreshold, 'warn-threshold', warnTooltip);
      this.createThreshold(this.config.boundaries.failThreshold, 'fail-threshold', failTooltip);
    }
  }

  createThresholdTooltip = (): void => {
    const tooltip = d3.select('#threshold-tooltip');

    this.g.selectAll('line.threshold-line-wrapper')
      .on('mouseover', (event: MouseEvent) => {
        const line = d3.select(event.currentTarget as SVGLineElement);
        const thresholdLabel = line.attr('data-threshold-label');

        tooltip
          .style('display', 'block')
          .html(thresholdLabel);
      })
      .on('mousemove', (event: MouseEvent) => {
        const tooltipWidth = parseInt(tooltip.style('width'));
        const tooltipHeight = parseInt(tooltip.style('height'));

        let xPosition = event.pageX - tooltipWidth / 2;
        let yPosition = event.pageY - tooltipHeight * 2 - 15;

        tooltip
          .style('left', `${xPosition}px`)
          .style('top', `${yPosition}px`);
      })
      .on('mouseout', () => {
        tooltip.style('display', 'none');
      });
  };
}
