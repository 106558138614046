import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/components/material/material.module';
import { NgModule } from '@angular/core';
import { RuleSelectorRowComponent } from './rule-selector-row/rule-selector-row.component';
import { RuleSelectorComponent } from './rule-selector.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/components/shared/shared.module';
import { CurtainedRuleSelectorComponent } from '../curtained-rule-selector/curtained-rule-selector.component';
import { OpModalModule } from '@app/components/shared/components/op-modal/op-modal.module';
import { OpLabelManagerModule } from '@app/components/shared/components/op-label-manager/op-label-manager.module';
import { UiScrollModule } from 'ngx-ui-scroll';

@NgModule({
  imports: [
    CommonModule,
    OpModalModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    OpLabelManagerModule,
    UiScrollModule,
  ],
  declarations: [
    RuleSelectorComponent,
    RuleSelectorRowComponent,
    CurtainedRuleSelectorComponent
  ],
  exports: [
    RuleSelectorComponent,
    RuleSelectorRowComponent,
    CurtainedRuleSelectorComponent
  ]
})
export class RuleSelectorModule {}
