export const validDuplicate = function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attributes, ngModel) {
        scope.$watch(attributes.validDuplicate, function(v) {
          ngModel.$setValidity('duplicate', v) ;
        });
      }
    };
  };

