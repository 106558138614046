export enum EUsageOveragesModalType {
  Allow,
  Prevent
}

export const CONFIRM_INPUT_TEXT = 'confirm';

export const USAGE_OVERAGES_QUESTION = {
  [EUsageOveragesModalType.Allow]: 'Are you sure you want to allow overages?',
  [EUsageOveragesModalType.Prevent]: 'Are you sure you want to prevent overages?'
};

export const USAGE_OVERAGES_EXPLANATIONS = {
  [EUsageOveragesModalType.Allow]: `Doing this will <b>allow</b> any Audit & Journey run to start that would cause the account to go over the annual contract limits.`,
  [EUsageOveragesModalType.Prevent]: `Doing this will <b>prevent</b> all Audit & Journey runs from starting that would cause the account to go over the annual contract limits.`
};