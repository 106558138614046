import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '@app/components/core/services/api.service';
import { environment } from '@app/environments/environment';
import { catchError, map } from 'rxjs/operators';

export interface IScriptServicesRequest {
  excelAttachmentUrl: string;
  anyAttachmentUrl: string;
  company: string;
  email: string;
  numberJourneys: number;
  journey: {
    custom: string;
    domain: string;
    folder: string;
  };
}

export interface IPreSignedUrlResponse {
  putPresignedUrl: string;
  getPresignedUrl: string;
}

export interface IScriptServicesResponse {
  success: boolean;
}

@Injectable()
export class ScriptServicesService {

  url: string = environment.apiScriptServices;
  v3Root: string = environment.apiV3Url;

  constructor(private apiService: ApiService) {}

  submitScriptServicesRequest(request: IScriptServicesRequest): Observable<IScriptServicesResponse> {
    return this.apiService.post(this.url, request, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
  }

  // Get presigned url that will be used to upload directly to S3.
  async getPresignedURLs(): Promise<IPreSignedUrlResponse> {
    try {
      const presignedUrls: IPreSignedUrlResponse = await this.apiService.get<IPreSignedUrlResponse>(`${this.v3Root}script-services/preuploadurl`).toPromise();

      return presignedUrls;

    } catch(error) {
console.error('Error getting presigned urls:', error);
      return new Promise<IPreSignedUrlResponse>((resolve, reject) => {
        reject(error);
      });
    }
  }

  async uploadFileToS3(presignedUrl: string, file: File): Promise<boolean> {

    try {
      await this.apiService.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type
        }
      }).toPromise();

      return true;
    }
    catch(error) {
      console.error('Error uploading file:', error);
      return false;
    }
  }
}
