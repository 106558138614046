<op-modal [leftFooterButtons]="leftFooterButtons" [rightFooterButtons]="rightFooterButtons" (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header title="Share Audit Report" (onClose)="close()"></op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="body-container" [formGroup]="formGroup">
      <p class="top-description">
        Sharing <strong>{{payload.auditName}}</strong> last run on <strong>{{payload.runDate}}</strong> {{payload.filters.length ? 'with ' + payload.filters.length + ' applied ' + (payload.filters.length > 1 ? 'filters' : 'filter') : '' }}.
      </p>
      <p>
        Link expires in <strong>1 year</strong>. Anyone on the internet with the link can view reports.
      </p>
      <ng-container *ngIf="!this.rightFooterButtons[0].hidden">
        <div>
          <op-recipients-form-control floatLabel="always" formControlName="emails"></op-recipients-form-control>
        </div>
        <div>
          <mat-form-field appearance="outline"
                          floatLabel="always"
                          class="multi-line">
            <mat-label>ENTER MESSAGE</mat-label>
            <textarea matInput rows="3"
                      class="message-input"
                      formControlName="message"
                      placeholder="Enter email message">
          </textarea>
            <mat-error *ngIf="messageControl?.errors?.maxlength">Character limit exceeded ({{ messageControl?.errors?.maxlength?.actualLength }} of {{ messageControl?.errors?.maxlength?.requiredLength }})</mat-error>
            <mat-hint class="counter" align="end" [class.red]="messageControl?.value?.length > maxMessageLength">{{messageControl?.value?.length || 0}}/{{maxMessageLength}}</mat-hint>
          </mat-form-field>
        </div>
        <p class="bottom-description">Sharing also grants access to other items in <strong>{{payload.folderName}}</strong> folder.</p>
      </ng-container>
    </div>
  </ng-template>
</op-modal>
