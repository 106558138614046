<overage-banner class="overage-banner"></overage-banner>

<div class="title-container" id="top">
  <h1 class="title">Usage Dashboard
    <span class="icon-wrapper" [class.highlight]="infoIsExpanded" (click)="infoIsExpanded = !infoIsExpanded">
      <mat-icon class="title-icon">help_outline</mat-icon>
      <mat-icon class="expansion-icon">expand_more</mat-icon>
    </span>
  </h1>
  <div class="controls">
    <usage-header-alerts
      [filters]="currentFilters"
    ></usage-header-alerts>
    <op-button-2021 labelText="Export"
                    matIcon="cloud_download"
                    [menuItems]="exportsMenu"
                    buttonType="filled">
    </op-button-2021>
    <op-button-2021 labelText="Allow Overages"
                    [class.logged-in-as-another]="isLoggedInAsAnother"
                    [disabled]="isLoggedInAsAnother"
                    *ngIf="canViewAllowedOverages"
                    [hiddenIcon]="true"
                    (click)="updateAllowOverages()"
                    buttonType="filled"
                    mdePopoverTriggerOn="hover"
                    [mdePopoverTriggerFor]="overagesPopover"
    >
      <span class="after-text">
        <div class="overages-toggle" [class.allowed]="isAllowedOverages">
          <span class="turned-on">YES</span>
          <span class="turned-off">NO</span>
          <span class="toggle"></span>
        </div>
      </span>
    </op-button-2021>
  </div>
</div>

<mde-popover
  #overagesPopover="mdePopover"
  [mdePopoverOverlapTrigger]="false"
>
  <mat-card class="overages-popover">
    <div class="heading">OVERAGE</div>
    <div class="message">
      {{ USAGE_OVERAGES_POPOVER[this.isAllowedOverages ? EUsageOveragesModalType.Allow : EUsageOveragesModalType.Prevent] }}
    </div>
  </mat-card>
</mde-popover>

<op-info-panel [expanded]="infoIsExpanded" class="info-panel">
  <p>
    All usage calculations for contractual purposes, including any overages where applicable, are based on UTC.
  </p>
</op-info-panel>

<div class="filter-container">
  <usage-filter-bar
    (filtersChanged)="filterChanged($event)">
  </usage-filter-bar>
</div>

<usage-top-widgets [summary]="summary" class="usage-top-widgets"></usage-top-widgets>

<div class="usage-main-content">
  <usage-main-content-tabs *ngIf="auditUsage"
                           [isWebJourneysAllowed]="isWebJourneyAllowed"
                           [audits]="audits"
                           [filters]="currentFilters"
                           [user]="user"
                           [webJourneys]="webJourneys"
                           [auditUsage]="auditUsage"
                           [webJourneyUsage]="webJourneyUsage"
                           (auditTermSelected)="onAuditTermChanged$.next($event);"
                           (webJourneyTermSelected)="onWebJourneyTermChanged$.next($event)"
                           (tabChanged)="tabChanged($event)"
  >
    <usage-tab-title-chart class="audits"
                           [total]="auditUsage.usage.termLimit"
                           [used]="auditUsage.usage.cumulativeTotal.total"
                           [pacingFrom]="auditUsage.usage.cumulativePacing"
                           [pacingTo]="auditUsage.usage.cumulativePacing"
                           [auditUsage]="auditUsage"
                           [filteredTo]="isFiltered ? auditUsage.usage.cumulativeTotal.filtered : undefined"
                           [type]="EUsageTabTitleChartTypes.Audit"></usage-tab-title-chart>
    <usage-tab-title-chart *ngIf="webJourneyUsage"
                           class="webjourneys"
                           [total]="webJourneyUsage.usage.termLimit"
                           [used]="webJourneyUsage.usage.cumulativeTotal.total"
                           [pacingFrom]="webJourneyUsage.usage.cumulativePacing"
                           [pacingTo]="webJourneyUsage.usage.cumulativePacing"
                           [auditUsage]="auditUsage"
                           [filteredTo]="isFiltered ? webJourneyUsage.usage.cumulativeTotal.filtered : undefined"
                           [type]="EUsageTabTitleChartTypes.WebJourney"></usage-tab-title-chart>
    <usage-tab-title-chart *ngIf="webJourneyUsage"
                           class="webjourneys to-print"
                           [total]="webJourneyUsage.usage.termLimit"
                           [used]="webJourneyUsage.usage.cumulativeTotal.total"
                           [pacingFrom]="webJourneyUsage.usage.cumulativePacing"
                           [pacingTo]="webJourneyUsage.usage.cumulativePacing"
                           [filteredTo]="isFiltered ? webJourneyUsage.usage.cumulativeTotal.filtered : undefined"
                           [type]="EUsageTabTitleChartTypes.WebJourney"></usage-tab-title-chart>
    <div class="tab-audits tab" [class.shrinked]="activeTab !== EUsageTabTitleChartTypes.Audit">
      <div class="tab-container">
        <usage-audit-chart-content [$filtersChanged]="onFiltersChanged"
                                   [$termChanged]="onAuditTermChanged"></usage-audit-chart-content>
        <usage-chart-with-table *ngIf="auditDailyTrends"
                                [amount]="auditUsage?.concurrentAudits"
                                [dailyUsageData]="auditDailyTrends"
                                [openTabType]="EUsageTabTitleChartTypes.Audit"></usage-chart-with-table>
      </div>
    </div>

    <div class="tab-webjourneys tab" *ngIf="webJourneyUsage" [class.shrinked]="activeTab !== EUsageTabTitleChartTypes.WebJourney">
      <div class="tab-container">
        <usage-web-journey-chart-content [$filtersChanged]="onFiltersChanged"
                                         [$termChanged]="onWebJourneyTermChanged"></usage-web-journey-chart-content>
        <usage-chart-with-table *ngIf="webJourneyDailyTrends"
                                [amount]="webJourneyUsage.concurrentWebJourneys"
                                [dailyUsageData]="webJourneyDailyTrends"
                                [openTabType]="EUsageTabTitleChartTypes.WebJourney"></usage-chart-with-table>
        <usage-webjourney-summary *ngIf="webJourneyUsage?.supportUsage"
                                  [total]="webJourneyUsage.supportUsage.maxMonitoredWebJourneys"
                                  [monitored]="webJourneyUsage.supportUsage.currentlyMonitoredWebJourneys.total"
                                  [requests]="webJourneyUsage.supportUsage.remainingWebJourneySupportRequests"></usage-webjourney-summary>
      </div>
    </div>
  </usage-main-content-tabs>
</div>

<div class="footer">
  <p>
    Use the chart above to identify usage that could lead to an overage or cause critical runs
    to be skipped. The <a (click)="exportThisReport()">export for this dashboard</a> contains all historical and projected
    usage for the current contract term. Use the export to investigate specific
    Audits or Journeys that could contribute.
  </p>
</div>
