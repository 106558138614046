import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EActionCreatorMode } from '@app/components/actions/actions-creator/actions-creator.enum';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, Validators } from '@angular/forms';
import { IWebJourneyActionRules } from '@app/components/web-journey/web-journey-editor/web-journey-editor.models';
import { IRuleSelection } from '@app/components/account/rules/rule-selector/rule-selector.models';
import { ECurtainModalClass, OpModalComponent, OpModalService } from '@app/components/shared/components/op-modal';
import { RuleSelectorComponent } from '@app/components/account/rules/rule-selector/rule-selector.component';
import { defaultWebJourneyAction } from '@app/components/actions/actions-creator/actions-creator.constants';
import { forkJoin, of } from 'rxjs';
import { ActionSetLibraryService } from '@app/components/action-set-library/action-set-library.service';
import { IActionSet, IActionSetAction } from '@app/components/action-set-library/action-set-library.models';
import { IBaseActionDetails } from '@app/components/actions/action-details/action-details.models';
import { catchError, tap } from 'rxjs/operators';
import { TransformActionsService } from '../transform-actions.service';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { IRule, IRulePreview, IRuleV3, IRuleV3ActionRules } from '@app/components/rules/rules.models';
import { RulesService } from '@app/components/rules/rules.service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import {
  CurtainedRuleSelectorComponent, ICurtainedRuleSelectorComponentData
} from '@app/components/account/rules/curtained-rule-selector/curtained-rule-selector.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-set-editor',
  templateUrl: './action-set-editor.component.html',
  styleUrls: ['./action-set-editor.component.scss']
})
export class ActionSetEditorComponent implements OnInit {
  
  readonly actionsModes = EActionCreatorMode;

  title: string;
  selectedDomain: string;
  submitted: boolean = false;
  actionSet: IActionSet;
  actionSetId: number;
  actionSetForm: UntypedFormGroup;
  assigningRule: boolean = false;
  actionRules: IWebJourneyActionRules;
  ruleSelectorTitle: string;
  selectedActionRules: IRuleSelection;
  rules: IRulePreview[] = [];
  labels: ILabel[] = [];
  newActionRule: IRule;
  actionSetName: string;
  createMode: boolean;
  isCopyingActionSet: boolean;

  @ViewChild(OpModalComponent, { static: false }) opModal: OpModalComponent;
  @ViewChild(RuleSelectorComponent, { static: false }) ruleSelectorComponent: RuleSelectorComponent;

  rightFooterButtons = [
    {
      label: 'Save',
      action: () => {
        this.saveActionSet();
      },
      primary: true,
      opSelector: 'action-set-create-save',
    }
  ];
  ruleSelectorComponentInstance: CurtainedRuleSelectorComponent;

  constructor(
    public dialogRef: MatDialogRef<ActionSetEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private modalServiceNg: OpModalService,
    private labelsService: LabelService,
    private rulesService: RulesService,
    private actionSetService: ActionSetLibraryService,
    private transformActionsService: TransformActionsService,
    private dateService: DateService,
  ) {
    this.createMode = !this.data;
    this.title = this.createMode ? 'Create' : 'Edit';
    this.isCopyingActionSet = !!(this.data && this.data.copy);
    if (!this.createMode) this.actionSetId = this.data.id ? this.data.id : this.data.actionSet.id;
  }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  private initForm(): void {
    this.actionSetForm = this.fb.group({
      name: ['', Validators.required],
      actions: []
    });

    if (this.createMode) this.actions.setValue([defaultWebJourneyAction()]);
  }

  private initData(): void {
    if (!this.createMode || this.isCopyingActionSet) {
      this.actionSetService.getActionSet(this.actionSetId).subscribe((actionSet: IActionSet) => {
        this.actionSet = actionSet;

        // get rules for each action in the action set
        const actionSetActionRules$ = this.actionSet.actions.map((action: IActionSetAction) => {
          return this.actionSetService.getActionSetActionRules(this.actionSet.id, action.id);
        });

        forkJoin(actionSetActionRules$).subscribe((rules: IRuleV3[][]) => {
          if (this.isCopyingActionSet) {
            this.actionSet.name = this.actionSet.name + ' copied ' + this.dateService.formatDate(new Date(), EDateFormats.dateTwo);
            delete this.actionSet.id;
          }

          this.actionSet.actions.sort((a: any, b: any) => (a.sequence > b.sequence) ? 1 : -1);
          let actionRules: IRuleV3ActionRules[] = this.actionSet.actions.map((action: IActionSetAction, index: number) => {
            return {
              actionId: action.id,
              rules: rules[index]
            };
          });
          
          this.name.patchValue(this.actionSet.name);
          this.actions.patchValue(this.transformActionsService.convertApiActionsToUi(this.actionSet.actions, actionRules));
        });
      });
    }

    forkJoin(
      this.rulesService.getRulePreviews(),
      this.labelsService.getLabels()
    ).subscribe(([rules, labels]) => {
      this.rules = rules;
      this.labels = labels;
    });
  }

  saveActionSet(): void {
    this.submitted = true;
    this.actionSetForm.markAllAsTouched();
    if (this.actionSetForm.invalid) return;

    let actionSet: IActionSet = {
      name: this.name.value,
      actions: this.transformActionsService.convertUiActionsToApi(this.actions.value)
    };

    if (this.actionSet && this.actionSet.id) actionSet['id'] = this.actionSet.id;
    
    const request = this.createMode || this.isCopyingActionSet
      ? this.actionSetService.createActionSet(actionSet)
      : this.actionSetService.updateActionSet(actionSet);

    request.subscribe((actionSet: IActionSet) => {
      let ruleObservables$ = [];
      
      this.actions.value.forEach((action: IBaseActionDetails, index: number) => {
        const actionSetId = actionSet.id;
        const actionId = actionSet.actions[index].id;
        const rules = action.rules.map((rule: IRule) => rule.id);

        ruleObservables$.push(
          this.actionSetService
            .updateActionSetActionRules(actionSetId, actionId, rules)
            .pipe(catchError(error => {
              console.log(error);
              return of({});
            }))
        );
      });

      if (ruleObservables$.length) {
        forkJoin(ruleObservables$).subscribe((rules: IRuleV3[]) => {
          this.closeModal(actionSet);
        });
      } else {
        this.closeModal(actionSet);
      }
    });
  }

  closeModal(actionSet?: IActionSet): void {
    this.dialogRef.close(actionSet);
  }

  openRuleSelector(actionRules: IWebJourneyActionRules): void {
    this.assigningRule = true;
    this.actionRules = actionRules;
    this.selectedActionRules = {
      selectedItems: this.actionRules.rules.value.map((rule) => ({ rule }) ),
      selectedRuleIds: this.actionRules.rules.value.map( rule => rule.id)
    };
    const data: ICurtainedRuleSelectorComponentData = {
      rules: this.rules,
      labels: this.labels,
      title: 'Add rules to Action ' + actionRules.index,
      newActionRule: this.newActionRule,
      selectedRules: this.selectedActionRules,
      openRuleCreation: this.openRuleCreation.bind(this),
      openRuleEditor: this.openRuleEditor.bind(this)
    };

    const modal = this.modalServiceNg.openFixedSizeModal(CurtainedRuleSelectorComponent, {data});
    this.ruleSelectorComponentInstance = modal.componentInstance;
    modal.afterClosed().subscribe(rules => this.closeRuleSelector(rules));
  }

  closeRuleSelector(value: number[]): void {
    this.assigningRule = false;
    if (value) this.actionRules.rules.patchValue(value.map(id => this.rules.find(r => r.id === id)));
    this.newActionRule = null;
  }

  openRuleCreation(): void {
    this.modalServiceNg.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.create
      }
    }, 'rule-setup-modal')
    .afterClosed()
    .subscribe(rule => this.closeRuleCreation(rule));
  }

  openRuleEditor(rule: IRule) {
    this.modalServiceNg.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.edit,
        ruleId: rule.id
      }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => this.onEditRule(rule));
  }

  async onEditRule(rule: IRule) {
    if (rule) {
      const updatedRule = this.rules.find(r => r.id === rule.id);

      // update all labels in case new ones were added
      if (rule.labels) {
        this.labelsService.getLabels().subscribe(labels => {
          this.labels = labels;
        });
      }

      // update the labels in case they have changed after being edited
      updatedRule.labels = rule.labels;

      // update the name in case it has changed after being edited
      updatedRule.name = rule.name;
    }
  }

  closeRuleCreation(newRule?: IRule): void {
    if (newRule && !this.assigningRule){
      // update labels for grouping if newRule has a label on it in case a new label was created
      if (newRule.labels.length > 0) {
        this.refreshLabels().subscribe(() => {
          this.updateSelectedRuleAfterCreation(newRule);
        });
      } else {
        this.updateSelectedRuleAfterCreation(newRule);
      }
    }

    if (newRule && this.assigningRule){
      this.newActionRule = newRule;
      if (newRule.labels.length > 0) {
        this.refreshLabels().subscribe(() => {
          this.rules = [ ...this.rules, newRule ];
        });
      } else {
        this.rules = [ ...this.rules, newRule ];
      }
    }

    if (newRule) {
      this.ruleSelectorComponentInstance.addRule(newRule);
    }
  }

  private refreshLabels() {
    return this.labelsService.getLabels().pipe(
        tap((labels) => this.labels = labels)
      );
  }

  updateSelectedRuleAfterCreation(rule: IRule): void {
    this.rules.push(rule);
    const newSelectedRuleIds = [...this.ruleSelectorComponent.selectedItemsAndRules.selectedRuleIds, rule.id];
    const newSelectedItems = [...this.ruleSelectorComponent.selectedItemsAndRules.selectedItems, { rule: rule }];

    this.actionSetForm.controls.rules.patchValue({
      selectedRuleIds: newSelectedRuleIds,
      selectedItems: newSelectedItems
    });
  }

  get name(): AbstractControl {
    return this.actionSetForm.get('name');
  }

  get actions(): AbstractControl {
    return this.actionSetForm.get('actions');
  }
}
