import { AdminPortalUrlBuilders } from '@app/components/admin-portal/admin-portal.constants';
import { RouteDataService } from '@app/components/shared/services/route-data.service';
import { LoginUrlBuilders } from './../login/login.constants';
import { Component, OnInit, Inject, OnDestroy, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { IBreadcrumb, IPageTitle, IUser, Account } from '@app/moonbeamModels';
import { Observable, NEVER, Subject, fromEvent } from 'rxjs';
import { INavSection } from '@app/components/navigation/modal-sidebar/modal-sidebar.models';
import { catchError, tap, filter, pairwise, startWith, takeUntil, debounceTime } from 'rxjs/operators';
import { userIsOPAdmin, userIsGuest, userIsStandard, userIsOPSysAdmin } from '@app/authUtils';
import { HttpParams } from '@angular/common/http';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { IAuthorizationData, AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { WindowRef } from '@app/components/core/services/window.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularNames, AuthenticationEvents, EAuthenticationEvent, Events, Names, startsWith } from '@app/moonbeamConstants';
import { Router, NavigationStart } from '@angular/router';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { ECacheResetEvent, CacheResetService } from '../core/services/cache-reset.service';
import { AccountsService } from '@app/components/account/account.service';
import { KeyboardShortcutsService } from '../shared/services/keyboard-shortcuts/keyboard-shortcuts.service';
import { SidebarService } from '../navigation/sidebar/sidebar.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import * as Sentry from "@sentry/angular";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  isFullScreen = false;
  searching: boolean;
  user: IUser;
  account: Account;
  dataSubmitted: boolean;
  pageTitle: IPageTitle;
  breadcrumbs: IBreadcrumb[];
  sidebarNav: INavSection[];
  isHelpOpen: boolean = false;
  isAnotherUser: boolean = false;
  isOPAdmin: boolean = false;
  isReadOnly: boolean = false;
  enabledFeatures: Array<string> = [];

  isMobileView: boolean;
  showSideBar: boolean = true;
  showMobileSideBar: boolean = false;

  MOBILE_VIEW_SIZE = 720;

  isDarkTheme: boolean;
  isVisitorMode$: Observable<boolean>;
  showUpgradeUserBanner = false;

  sidebarStatusClass: string = '';
  sidebarIsClosed: boolean = false;

  mobileFlyoutIsVisible: boolean = false;

  readonly EAccountType = EAccountType;

  private destroy$: Subject<void> = new Subject();

  private stateChangeStartUnsubscribe: Function;
  private stateChangeSuccessUnsubscribe: Function;

  private logoutUnsubscribe: Function;
  private notAuthUnsubscribe: Function;
  private credentialsExpiredUnsubscribe: Function;

  constructor(
    @Inject(AngularNames.rootScope) private $rootScope: angular.IRootScopeService,
    @Inject(AngularNames.location) private $location: angular.ILocationService,
    private router: Router,
    private routeDataService: RouteDataService,
    private windowRef: WindowRef,
    private titleService: Title,
    private accountsService: AccountsService,
    private authenticationService: AuthenticationService,
    private authenticationStorageService: AuthenticationStorageService,
    private storageService: StorageService,
    private eventManager: IEventManager,
    private snackBar: MatSnackBar,
    private cacheResetService: CacheResetService,
    private keyboardShortcutsService: KeyboardShortcutsService,
    private sidebarService: SidebarService,
    private applicationChromeService: ApplicationChromeService,
    private uiTagsService: UiTagService) { }

  ngOnInit(): void {
    this.initListeners();
    this.handleMobileView();
    this.initSidebarService();
    // Preloading all tags, categories and vendors to make them available cached
    this.uiTagsService.getAllTagsData().subscribe();

    var goToLogin = (event: angular.IAngularEvent) => {
      this.authenticationService.setUserCreedsExpired(true);
      this.goToLoginPage();
    };

    // TODO: Use authenticationService.authenticationEvents$ instead of $rootScope.$on for handling auth events (see below)
    this.logoutUnsubscribe = this.$rootScope.$on(AuthenticationEvents.logoutSuccess, (e) => this.doLogout(e, false));
    this.notAuthUnsubscribe = this.$rootScope.$on(AuthenticationEvents.notAuthenticated, (e) => this.doLogout(e, true));
    this.credentialsExpiredUnsubscribe = this.$rootScope.$on(AuthenticationEvents.credentialsExpired, goToLogin);

    this.authenticationService.authenticationEvents$.subscribe(event => {
      switch (event.type) {
        case EAuthenticationEvent.goToLogin:
          this.goToLoginPage();
          break;
        default:
          console.error('Unknown event type: ' + event.type);
          break;
      }
    });

    this.initialize();
    this.fetchEnabledFeatures();
    this.subscribeToUserEvent();
  }

  ngOnDestroy(): void {
    if (this.stateChangeStartUnsubscribe) this.stateChangeStartUnsubscribe();
    if (this.stateChangeSuccessUnsubscribe) this.stateChangeSuccessUnsubscribe();
    if (this.logoutUnsubscribe) this.logoutUnsubscribe();
    if (this.notAuthUnsubscribe) this.notAuthUnsubscribe();
    if (this.credentialsExpiredUnsubscribe) this.credentialsExpiredUnsubscribe();

    this.destroy$.next();
    this.destroy$.complete();
  }

  private initSidebarService(): void {
    this.sidebarService.isClosed.pipe(takeUntil(this.destroy$)).subscribe((status: boolean) => {
      this.sidebarIsClosed = status;
      this.sidebarStatusClass = this.sidebarIsClosed ? 'sidebar-closed' : 'sidebar-open';
    });
  }

  private initListeners(): void {
    this.routeDataService.routeData$.subscribe(data => {
      this.isFullScreen = data.fullScreen;
      this.setDocumentTitle(data.title);
    });

    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
    this.applicationChromeService.accountPreview$
      .pipe(filter(accountPreview => !!accountPreview), takeUntil(this.destroy$))
      .subscribe(({ accountType }) => {
        this.showUpgradeUserBanner = accountType === EAccountType.FREETRIAL || accountType === EAccountType.SAMPLE;
      });
  }

  private setDocumentTitle(title?: string): void {
    const defaultTitle = 'ObservePoint';
    this.titleService.setTitle(title ? `${title} - ${defaultTitle}` : defaultTitle);
  }

  private handleMobileView(): void {
    this.isMobileView = this.windowRef?.nativeWindow?.innerWidth < this.MOBILE_VIEW_SIZE;

    fromEvent(window, 'resize')
      .pipe(debounceTime(10))
      .subscribe((e: any) => {
        this.isMobileView = e.target && e.target.innerWidth < this.MOBILE_VIEW_SIZE;
      });
  }

  private fetchEnabledFeatures(): void {
    this.authenticationService.getFeatures().subscribe((features: Array<string>) => {
      this.enabledFeatures = features;
    });
  }

  private initialize(): void {
    this.searching = false;
    this.breadcrumbs = [];
    this.pageTitle = { title: 'Default Title' };

    // App.component is the root component for all pages and components protected by authentication.
    // Now that we're logged in, setup the sidebar and set app analytics cookies
    this.getLoggedInUserInfo().subscribe((user) => {
      this.authenticationService.getAccountPreview().subscribe(account => {
        this.authenticationService.setAnalyticsCookies(user, user.accountId, account.accountType);
        // Scenarios:
        // - normal login: user and account already known
        // - sso:          user ID is known. Other needed details are not known
        // - shared link:  user ID is known (same hardcoded user ID for guest user in all accounts). Other needed details are not known
        // TODO: In the future, this can move to authentication.service and we can cache user and account details so
        //       we this call only happens when it is needed (e.g. sso and shared link scenarios)
        const datalayer = this.windowRef.nativeWindow?.dataLayer;
        if (datalayer && datalayer.push) {
          datalayer.push({
            event: 'user-login-complete',
            userId: user.id,
            userFirstName: user.firstName,
            userLastName: user.lastName,
            userEmail: user.email,
            userPermission: user.permissions,
            accountId: account.id,
            accountType: account.accountType
          });
        } else {
          console.error('DataLayer not found, or push method not available');
        }
        Sentry.setContext('account_info', {
          accountType: account.accountType,
          userPermission: user.permissions,
        });
        Sentry.setUser({ id: user.id });
      });
    });
    this.secureRoutes();
  }

  private getLoggedInUserInfo(): Observable<IUser> {
    this.isAnotherUser = this.storageService.isLoggedInAsAnother();

    return this.accountsService.getUser().pipe(
      catchError(_ => {
        this.doLogout(null, true);
        return NEVER;
      }),
      tap((user: IUser) => {
        this.user = user;
        this.isOPAdmin = userIsOPAdmin(user);
        this.isReadOnly = userIsGuest(user);
      })
    );
  }

  doLogout(event: angular.IAngularEvent, unintentional: boolean) {
    this.user = null;
    var path = this.$location.path();
    if (path.indexOf(LoginUrlBuilders.base()) != -1) return;

    if (unintentional) {
      this.authenticationService.setUserTokenExpired();
    }

    // Reloading page with location.href because we need to clear out our services. Some (like tags service) keep state
    this.reloadPage(unintentional, path, true);
  }

  private goToLoginPage() {
    const path = this.$location.path();
    if (path.indexOf(LoginUrlBuilders.base()) != -1) return;

    // Reloading page with location.href because we need to clear out our services. Some (like tags service) keep state
    this.reloadPage(true, path);
  }

  private reloadPage(deepLink: boolean, path: string, signout: boolean = false) {
    let queryParams = new HttpParams();
    if (deepLink) queryParams = queryParams.set('redirect', encodeURI(path));
    if (signout) queryParams = queryParams.set('signout', true.toString());

    this.windowRef.nativeWindow.location.href = LoginUrlBuilders.base() + '?' + queryParams.toString();
  }

  isInAdminPortal(): boolean {
    return startsWith(this.router.url, AdminPortalUrlBuilders.base());
  }

  private checkUsageLimit(accountId: number): void {
    this.accountsService.getUsage(accountId)
      .then(usage => {
        const usagePercentage = usage.pageUsage.pagesUsed / usage.pageLimits.limit * 100;
        if (usagePercentage >= 90) this.showToast(usagePercentage);
      })
      .catch(error => {
        // Suppress 404 error. API throws 404 if account doesn't have usage limit. This is acceptable behavior.
        if (error && error.code === 404) return;
        console.error(error);
      });
  }

  private showToast(usagePercentage: number): void {
    const message = `Your account has reached ${usagePercentage}% of it's audit page limit`;

    this.snackBar.open(
      message,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom' }
    );
  }

  private secureRoutes(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      startWith(null),
      pairwise()
    ).subscribe(([previousState, newState]: [NavigationStart, NavigationStart]) => {
      const adminPortalUrl = AdminPortalUrlBuilders.base();
      const goFromAdminPortal = previousState ? startsWith(previousState.url, adminPortalUrl) : false;
      const goToAdminPortal = startsWith(newState.url, adminPortalUrl);
      if (goFromAdminPortal !== goToAdminPortal) {
        this.cacheResetService.raiseEvent(ECacheResetEvent.loginAsOriginal);
        if (!this.isOPAdmin) this.goToLoginPage();
      }
    });
  }

  private subscribeToUserEvent(): void {
    this.eventManager.subscribe(Events.loggedInAsAnother, () => {
      this.getLoggedInUserInfo().subscribe(() => {
        this.$rootScope.$broadcast(AuthenticationEvents.loginSuccess, this.user);
      });
      this.fetchEnabledFeatures();
    });
  }

  loginAsOriginal(): void {
    let original = this.storageService.getValue<IAuthorizationData>(Names.GlobalStateKeys.authorization, StorageType.Local);
    this.authenticationStorageService.returnToOriginalAuth();
    this.authenticationService.setAuthInfoCookies(original);

    this.getLoggedInUserInfo().subscribe(() => {
      this.$rootScope.$broadcast(AuthenticationEvents.loginSuccess, this.user);
      this.cacheResetService.raiseEvent(ECacheResetEvent.loginAsOriginal);

      let originalState = this.storageService.getOriginalState();
      this.fetchEnabledFeatures();
      this.router.navigateByUrl(originalState?.url || AdminPortalUrlBuilders.accounts());
    });
  }

  toggleSideBar(): void {
    if (this.isMobileView) {
      this.showMobileSideBar = !this.showMobileSideBar;
    } else {
      this.showSideBar = !this.showSideBar;
    }
  }

  showGlobalCreate(): boolean {
    return !this.isReadOnly && !this.isInAdminPortal();
  }

  handleOpenState(isOpen: boolean): void {
    if (!isOpen) {
      this.mobileFlyoutIsVisible = false;
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent) {
    this.keyboardShortcutsService.keyDown(event);
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    this.keyboardShortcutsService.keyUp(event);
  }
}
