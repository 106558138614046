<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Assign File Substitutions To {{selectedCards.length}} Data Sources"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="edit-domain-name">
      <ng-container *ngIf="editing">
        <mat-hint class="hint-desc">Add File Substitutes to one or more files on pages visited.</mat-hint>
        <form [formGroup]="manageSubstitutionsForm">
          <div>
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label class="label-title">File Substitutions (remote file mapping)</mat-label>
              <mat-select formControlName="rfmConfigField"
                          [compareWith]="compareObjectsWithIds"
                          multiple>
                <mat-option *ngFor="let mapping of remoteFileMappings" [value]="mapping">{{ mapping.name }}</mat-option>
              </mat-select>
              <mat-hint class="field-hint"><button class="learn-more-link" type="button" (click)="createNewFileSubstition()">Create New File Substitution.</button>  <a class="learn-more-link" [href]="LEARN_MORE.rfm" target="_blank">Learn More.</a></mat-hint>
            </mat-form-field>
          </div>
        </form>
      </ng-container>
      <ng-container *ngIf="saving">
        <div class="center-elements">
          <mat-spinner [diameter]="25" [strokeWidth]="4"></mat-spinner>
          <span>Saving...</span>
        </div>
      </ng-container>
      <ng-container *ngIf="error">
        <div class="center-elements">
          <p>There was a problem updating the File Substitution(s).<br>Please refresh the page and try again.</p>
        </div>
      </ng-container>
    </div>
  </ng-template>
</op-modal>
