import { Component, Inject, OnInit } from '@angular/core';
import { ILabel } from '@app/components/shared/services/label.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from '@app/components/alert/alert.service';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { IAlertSummaryAlertHistory } from '@app/components/alert/alert.models';
import { DateService, EDateFormats } from '../../../../date/date.service';
import { ISummaryHistoryPreviewBar, ISummaryHistoryPreviewModalPayload } from './summary-history-preview-modal.models';
import { EAlertResultStatus } from '@app/components/alert/alert.enums';
import { EChartDateRange } from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'summary-history-preview-modal',
  templateUrl: './summary-history-preview-modal.component.html',
  styleUrls: ['./summary-history-preview-modal.component.scss']
})
export class SummaryHistoryPreviewModalComponent implements OnInit {

  uniqueIdentifier = 'summary-history-preview';
  loading: boolean;
  modalTitle: string;
  bars: ISummaryHistoryPreviewBar[];
  history: IAlertSummaryAlertHistory[];

  dateRange: EChartDateRange = EChartDateRange.oneMonth;
  greaterThanOneMonthDisabled: boolean = false;
  greaterThanThreeMonthsDisabled: boolean = false;
  greaterThanSixMonthsDisabled: boolean = false;
  greaterThanNineMonthsDisabled: boolean = false;
  greaterThanThirteenMonthsDisabled: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<SummaryHistoryPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISummaryHistoryPreviewModalPayload,
    private alertService: AlertService,
    private alertReportingService: AlertReportingService,
    private dateService: DateService,
  ) { }

  ngOnInit() {
    this.modalTitle = `Alert History for ${this.data.alert?.name}`;

    if (this.data.bars) {
      this.bars = this.data.bars;
    } else if (this.data.barsConfig) {
      this.fetchHistoricalData();
    } else {
      throw new Error('You should provide either already fetched bars or config for fetching them');
    }
  }

  private updateButtonRangeDisabledStates(): void {
    const lastIndex = this.history.length - 1;
    const oldestDate = new Date(this.history[lastIndex].completedAt);
    const today = new Date();
    const daysDifference = Math.floor((today.getTime() - oldestDate.getTime()) / (1000 * 3600 * 24));

    const ranges = [
      { days: 30, button: 'greaterThanThreeMonths' },
      { days: 90, button: 'greaterThanSixMonths' },
      { days: 180, button: 'greaterThanNineMonths' },
      { days: 270, button: 'greaterThanThirteenMonths' }
    ];

    ranges.forEach(range => {
      this[`${range.button}Disabled`] = daysDifference <= range.days;
    });
  }

  private fetchHistoricalData() {
    const { itemId, runId } = this.data.barsConfig;
    const { name, id } = this.data.alert;
    this.loading = true;
    this.alertReportingService.getAlertSummary(itemId, runId, {}, { alertName: name }).subscribe(alertSummary => {
      this.loading = false;
      this.history = alertSummary.alerts.find(a => a.id === id).history;
      // default to showing one month of data
      this.bars = this.prepareHistoryChartData(this.getOneMonthOfData(), this.data.barsConfig.itemId);
      this.updateButtonRangeDisabledStates();
    });
  }

  private prepareHistoryChartData(history: IAlertSummaryAlertHistory[], itemId: number) {
    const runDateFormatted = run => this.dateService.formatDate(new Date(run.completedAt), EDateFormats.dateTwentyEight);
    const day = run => this.dateService.formatDate(new Date(run.completedAt), EDateFormats.dateFour);
    const time = run => this.dateService.formatDate(new Date(run.completedAt), EDateFormats.timeOne);

    let curMonth = '';
    const formattedHistory = history.reverse().map((run, index) => {
      const runMonth = this.dateService.formatDate(new Date(run.completedAt), EDateFormats.dateTwentySeven);
      const actualValue = run.currentRunValue ? run.currentRunValue : run.actualValue;
      const formattedRun = {
        id: String(run.runId),
        auditId: String(itemId),
        label: `${day(run)} | ${time(run)}`,
        groupLabel: runMonth !== curMonth ? runDateFormatted(run) : null,
        actualValue: AlertUtils.convertToUIValue(this.data.alert.metricType, this.data.alert.metricChangeType, actualValue),
        triggered: run.status === EAlertResultStatus.Triggered,
      };
      curMonth = runMonth;
      return formattedRun;
    });

    // Add diff property
    return formattedHistory.map((run, index) => {
      if (index === 0) {
        return {
          ...run,
          diff: {
            value: null,
            isRelative: false
          }
        };
      }

      const prevRun = formattedHistory[index - 1];
      let diffValue: number | string = '---';
      if (run.actualValue !== undefined && prevRun.actualValue !== undefined) {
        diffValue = Number((run.actualValue - prevRun.actualValue).toFixed(1));
      }
      return {
        ...run,
        diff: {
          value: diffValue,
          isRelative: false
        }
      };
    });
  }

  getDataForDateRange(dateRange: EChartDateRange): void {
    this.dateRange = dateRange;

    switch (dateRange) {
      case EChartDateRange.oneMonth:
        this.bars = this.prepareHistoryChartData(this.getOneMonthOfData(), this.data.barsConfig.itemId);
        break;
      case EChartDateRange.threeMonth:
        this.bars = this.prepareHistoryChartData(this.getThreeMonthsOfData(), this.data.barsConfig.itemId);
        break;
      case EChartDateRange.sixMonth:
        this.bars = this.prepareHistoryChartData(this.getSixMonthsOfData(), this.data.barsConfig.itemId);
        break;
      case EChartDateRange.nineMonth:
        this.bars = this.prepareHistoryChartData(this.getNineMonthsOfData(), this.data.barsConfig.itemId);
        break;
      case EChartDateRange.thirteenMonth:
        this.bars = this.prepareHistoryChartData(this.getThirteenMonthsOfData(), this.data.barsConfig.itemId);
        break;
    }
  }

  getOneMonthOfData(): IAlertSummaryAlertHistory[] {
    const oneMonthAgo = new Date();
    oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
    return this.history
      .filter(run => new Date(run.completedAt) >= oneMonthAgo)
      .sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime());
  }

  getThreeMonthsOfData(): IAlertSummaryAlertHistory[] {
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setDate(threeMonthsAgo.getDate() - 90);
    return this.history
      .filter(run => new Date(run.completedAt) >= threeMonthsAgo)
      .sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime());
  }

  getSixMonthsOfData(): IAlertSummaryAlertHistory[] {
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setDate(sixMonthsAgo.getDate() - 180);
    return this.history
      .filter(run => new Date(run.completedAt) >= sixMonthsAgo)
      .sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime());
  }

  getNineMonthsOfData(): IAlertSummaryAlertHistory[] {
    const nineMonthsAgo = new Date();
    nineMonthsAgo.setDate(nineMonthsAgo.getDate() - 270);
    return this.history
      .filter(run => new Date(run.completedAt) >= nineMonthsAgo)
      .sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime());
  }

  getThirteenMonthsOfData(): IAlertSummaryAlertHistory[] {
    return this.history.sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime());
  }

  closeModal(labels?: ILabel[]): void {
    this.dialogRef.close(labels);
  }
}
