import { Route } from '@angular/router';
import { OpAdminOnlyGuard } from '../core/permissions.guard';
import { EGridReportType } from '../shared/services/grid-api/grid-api.constants';
import { AccountReportsBaseUrl } from '@app/components/account-reports/account-report/account-report.constants';

export const AccountReportsFutureState: Route = {
  path: AccountReportsBaseUrl,
  canLoad: [OpAdminOnlyGuard], // TODO: remove this when available to all users
  loadChildren: () => import('./account-reports.module').then(mod => mod.AccountReportsModule),
}

export const AccountReportsSavedViewRoute: Route = {
  path: '',
  pathMatch: 'full',
  // component: AccountReportsSavedViewsComponent,
  // data: {
  //   stateName: 'account_reports_saved_views',
  //   title: 'Account Reports Saved Views',
  //   betaLabel: false,
  // },
  redirectTo: EGridReportType.WebAuditRuns,
};
