import {startsWith} from '@app/moonbeamConstants';

import {UrlListValidator} from './listValidators';

const HTTP = 'http://';

  export const addProtocol = function() {
    return {
      
      require: 'ngModel',
      link: function(scope, element, attributes, ngModel: angular.INgModelController) {
        element.on('blur', function(){
          scope.$apply(function(){
            var url = ngModel.$modelValue;
            if (startsWith(url, HTTP)) {
              return;
            }
            var urlWithProtocol = HTTP + url;
            if (UrlListValidator.validateOne(urlWithProtocol)){
              ngModel.$setViewValue(urlWithProtocol);
              ngModel.$commitViewValue();
              ngModel.$render();
            }
          })
        })
          }
  }
}

