import { Route } from '@angular/router';
import { AuditReportStateNames, AuditReportPaths } from './audit-report.constants';
import { AuditReportComponent } from './audit-report.component';
import { PageDetailsComponent } from '../page-details/page-details.component';
import { PageSummaryComponent } from '../reports/page-summary/page-summary.component';
import { RuleSummaryComponent } from '../reports/rule-summary/rule-summary.component';
import { TagInventoryComponent } from '../reports/tag-inventory/tag-inventory.component';
import { VariableInventoryComponent } from '@app/components/audit-reports/reports/variable-inventory/variable-inventory.component';
import { TagHealthComponent } from '../reports/tag-health/tag-health.component';
import { CookieInventoryComponent } from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.component';
import { TagDuplicatesAndMultiplesComponent } from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.component';
import { AuditSummaryComponent } from '@app/components/audit-reports/reports/audit-summary/audit-summary.component';
import { BrowserConsoleLogsComponent } from '@app/components/audit-reports/reports/browser-console-logs/browser-console-logs.component';
import { PrivacyTagsComponent } from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.component';
import { PrivacyCookiesComponent } from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.component';
import { PrivacyRequestsComponent } from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.component';
import { JsFileChangesComponent } from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.component';
import { AlertSummaryComponent } from '@app/components/audit-reports/reports/alert-summary/alert-summary.component';
import { UseCasesComponent } from '../use-cases/use-cases.component';
import { UseCaseOverviewComponent } from '../use-cases/use-case-overview/use-case-overview.component';
import { UseCaseAnalyticsComponent } from '../use-cases/use-case-analytics/use-case-analytics.component';
import { UseCasePrivacyComponent } from '../use-cases/use-case-privacy/use-case-privacy.component';
import { UseCaseLandingPageComponent } from '../use-cases/use-case-landing-page/use-case-landing-page.component';

const useCaseOverview: Route = {
  path: AuditReportPaths.useCaseOverview,
  component: UseCaseOverviewComponent,
  data: {
    stateName: AuditReportStateNames.useCaseOverview,
    title: 'Overview'
  }
};

const useCaseAnalytics: Route = {
  path: AuditReportPaths.useCaseAnalytics,
  component: UseCaseAnalyticsComponent,
  data: {
    stateName: AuditReportStateNames.useCaseAnalytics,
    title: 'Analytics'
  }
};

const useCasePrivacy: Route = {
  path: AuditReportPaths.useCasePrivacy,
  component: UseCasePrivacyComponent,
  data: {
    stateName: AuditReportStateNames.useCasePrivacy,
    title: 'Privacy'
  }
};

const useCaseLandingPage: Route = {
  path: AuditReportPaths.useCaseLandingPage,
  component: UseCaseLandingPageComponent,
  data: {
    stateName: AuditReportStateNames.useCaseLandingPage,
    title: 'Landing Page'
  }
};

const useCases: Route = {
  path: AuditReportPaths.useCases,
  component: UseCasesComponent,
  data: {
    stateName: AuditReportStateNames.useCases,
    title: 'Use-Cases'
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: AuditReportPaths.useCaseOverview },
    useCaseOverview,
    useCaseAnalytics,
    useCasePrivacy,
    useCaseLandingPage
  ]
};

const auditSummary: Route = {
  path: AuditReportPaths.auditSummary,
  component: AuditSummaryComponent,
  data: {
    stateName: AuditReportStateNames.auditSummary,
    title: 'Audit Summary'
  }
};

const alertSummary: Route = {
  path: AuditReportPaths.alertSummary,
  component: AlertSummaryComponent,
  data: {
    stateName: AuditReportStateNames.alertSummary,
    title: 'Alert Summary'
  }
};

const pageDetails: Route = {
  path: AuditReportPaths.pageDetails,
  component: PageDetailsComponent,
  data: {
    stateName: AuditReportStateNames.pageDetails,
  }
};

export const pageDetailsDeepLink: Route = {
  path: AuditReportPaths.pageDetailsDeepLink,
  component: PageDetailsComponent,
  data: {
    stateName: AuditReportStateNames.pageDetailsDeepLink,
    title: 'Page Details'
  }
};

const pageSummary: Route = {
  path: AuditReportPaths.pageSummary,
  component: PageSummaryComponent,
  data: {
    stateName: AuditReportStateNames.pageSummary,
    title: 'Page Summary'
  }
};

const ruleSummary: Route = {
  path: AuditReportPaths.ruleSummary,
  component: RuleSummaryComponent,
  data: {
    stateName: AuditReportStateNames.ruleSummary,
    title: 'Rule Summary'
  }
};

const tagInventory: Route = {
  path: AuditReportPaths.tagInventory,
  component: TagInventoryComponent,
  data: {
    stateName: AuditReportStateNames.tagInventory,
    title: 'Tag Inventory'
  }
};

const variableInventory: Route = {
  path: AuditReportPaths.variableInventory,
  component: VariableInventoryComponent,
  data: {
    stateName: AuditReportStateNames.variableInventory,
    title: 'Variable Inventory'
  }
};

const tagHealth: Route = {
  path: AuditReportPaths.tagHealth,
  component: TagHealthComponent,
  data: {
    stateName: AuditReportStateNames.tagHealth,
    title: 'Tag Health'
  }
};

const duplicatesAndMultiples: Route = {
  path: AuditReportPaths.duplicatesAndMultiples,
  component: TagDuplicatesAndMultiplesComponent,
  data: {
    stateName: AuditReportStateNames.duplicatesAndMultiples,
    title: 'Duplicates & Multiples'
  }
};

const cookieInventory: Route = {
  path: AuditReportPaths.cookieInventory,
  component: CookieInventoryComponent,
  data: {
    stateName: AuditReportStateNames.cookieInventory,
    title: 'Cookie Inventory'
  }
};

const browserConsoleLogs: Route = {
  path: AuditReportPaths.browserConsoleLogs,
  component: BrowserConsoleLogsComponent,
  data: {
    stateName: AuditReportStateNames.browserConsoleLogs,
    title: 'Browser Console Logs'
  }
};

const privacyTags: Route = {
  path: AuditReportPaths.privacyTags,
  component: PrivacyTagsComponent,
  data: {
    stateName: AuditReportStateNames.privacyTags,
    title: 'Privacy Tags'
  }
};

const privacyCookies: Route = {
  path: AuditReportPaths.privacyCookies,
  component: PrivacyCookiesComponent,
  data: {
    stateName: AuditReportStateNames.privacyCookies,
    title: 'Privacy Cookies'
  }
};

const privacyRequests: Route = {
  path: AuditReportPaths.privacyRequests,
  component: PrivacyRequestsComponent,
  data: {
    stateName: AuditReportStateNames.privacyRequests,
    title: 'Privacy Requests & Geos'
  }
};

const jsFileChanges: Route = {
  path: AuditReportPaths.privacyFileChanges,
  component: JsFileChangesComponent,
  data: {
    stateName: AuditReportStateNames.jsFileChanges,
    title: 'JS File changes'
  }
};

export const auditReportRoutes: Route = {
  path: AuditReportPaths.base,
  component: AuditReportComponent,
  data: {
    betaLabel: false,
    stateName: AuditReportStateNames.base,
  },
  children: [
    // TODO: Update this default route to use cases when going live
    { path: '', pathMatch: 'full', redirectTo: AuditReportPaths.useCases },
    useCases,
    auditSummary,
    alertSummary,
    pageDetails,
    pageSummary,
    ruleSummary,
    tagInventory,
    variableInventory,
    tagHealth,
    duplicatesAndMultiples,
    cookieInventory,
    browserConsoleLogs,
    privacyTags,
    privacyCookies,
    privacyRequests,
    jsFileChanges,
  ]
};
