import {
  ECookieSameSiteResponseTypes,
  EPageDetailsTabs
} from '@app/components/audit-reports/page-details/page-details.constants';
import { IChangeType } from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.models';
import {
  IPageDetailsRequestLogSortColumn
} from '@app/components/shared/components/request-log-table/request-log-table.models';
import { EBlockReason, EBrowserRedirectType, ECookieOpChangeType } from './page-details.enums';
import { ISparklineRunInfo } from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';

export interface IAuditPageInfo {
  url: string;
  loadTime: number;
  statusCode: number;
  size: number;
  screenshot: string;
  browserError?: string;
  parent: IAuditPageInfoParent;
  // NULL if page had no redirects. Does not include final page
  redirects?: IAuditPageInfoRedirect[];
  finalUrl?: string;
  finalStatusCode?: number;
  initialPageUrl?: string;
  initialStatusCode?: number;
  finalPageUrl?: string;
  title: string;
  visitStartTimestamp: number;
  links?: {
    linkToFinalUrlCount?: number;
    linkToInitialUrlCount?: number;
    linksFromThisPageV2: IAuditPageInfoLinksFromThisPageV2;
  };
  onPageActionResults: any[];
  blockedRequestSummary: { blockedRequests: boolean };
  webVitalsSummary?: IAuditPageInfoWebVitalsSummaryItem;
  accessibilityChecksSummary?: IAuditPageInfoAccessibilityChecksResults;
}

export interface IAuditPageInfoAccessibilityChecksResults {
  url: string;
  configurationTags: string[];
  issues: IAccessibilityRuleResult[];
  inconclusive?: IAccessibilityRuleResult[];
}

export enum EAccessibilityImpact {
  Minor = 'minor',
  Moderate = 'moderate',
  Serious = 'serious',
  Critical = 'critical'
}

export interface IAccessibilityRuleResult {
  id: string;
  impact: EAccessibilityImpact;
  description: string;
  help: string;
  wcagVersion: string;
  wcagConformanceLevel: string;
  successCriteria: IAccessibilitySuccessCriteria[];
  htmlElements: IAccessibilityTestedElement[];
}
export interface IAccessibilityRuleTableResult extends IAccessibilityRuleResult {
  inspectionNeeded?: boolean;
}

export interface IAccessibilitySuccessCriteria {
  wcagSuccessCriteria: string;
  w3Link: string;
}

export interface IAccessibilityTestedElement {
  failureSummary: string;
  fixOneOf: string[];
  fixAllOf: string[];
  fixAnyOf: string[];
  doNoneOf: string[];
  html: string;
  htmlCssSelectors?: string[];
  shadowDomCssSelectors?: Array<Array<string>>;
}

export interface IAuditPageInfoWebVitalsSummary {
  cumulativeLayoutShift: IAuditPageInfoWebVitalsSummary;
  firstContentfulPaint: IAuditPageInfoWebVitalsSummary;
  largestContentfulPaint: IAuditPageInfoWebVitalsSummary;
  timeToFirstByte: IAuditPageInfoWebVitalsSummary;
}

export interface IAuditPageInfoWebVitalsSummaryItem {
  failThreshold: number;
  pass: boolean;
  value: number;
  warnThreshold: number;
}

export interface IAuditPageInfoLinksFromThisPageV2 {
  scannedInternalSuccessfulPageCount: number;
  scannedInternalBrokenPageCount: number;
  scannedExternalSuccessfulPageCount: number;
  scannedExternalBrokenPageCount: number;
  unscannedInternaLinkCount: number;
  unscannedExternaLinkCount: number;
}

export interface IAuditPageInfoRedirect {
  statusCode: number;
  url: string;
  type: EBrowserRedirectType;
}

export interface IAuditPageInfoSparklineData {
  runId: number;
  runCompletionDate: string;
  pageInsights: IAuditPageInfoSparklineInsights;
}

export interface IAuditPageInfoWebVitalsTrends {
  completedAt: string;
  cumulativeLayoutShift: number
  firstContentfulPaint: number;
  largestContentfulPaint: number;
  timeToFirstByte: number;
  runId: number;
}

export interface IAuditPageInfoWebVitalsTrendsData {
  runs: IAuditPageInfoWebVitalsTrends[];
}

export interface IAuditRunPageDetailsWebVitalsTrendStats {
  description: string;
  min: number;
  max: number;
  average: number;
  median: number;
}

export interface IAuditRunPageDetailsWebVitalsTrendRun {
  runId: number;
  completedAt: string;
  trendValue: number;
}

export interface IAuditRunPageDetailsSpecificWebVitalsTrend {
  stats: IAuditRunPageDetailsWebVitalsTrendStats;
  runs: IAuditRunPageDetailsWebVitalsTrendRun[];
}

export interface IAuditPageInfoSparklineInsights {
  loadTime: number;
  statusCode: number;
  size: number;
}

export interface IAuditPageInfoTrendData {
  runId: number;
  runCompletionDate: string;
  trendName: string;
  trendValue: number;
}

export interface IAuditPageInfoParent {
  pageId: string;
  pageUrl: string;
}

export interface IAuditRfm {
  remoteFileMappingId: number,
  match: string,
  matchType: string,
  fileUrl: string,
  mappedRequests: [
    string
  ]
}

export interface IAuditCookie {
  name: string;
  value: string;
  domain: string;
  path: string;
  httpOnly: boolean;
  secure: boolean;
  partyType: string,
  samesite: ECookieSameSiteResponseTypes;
  origin: IAuditCookieOrigin;
  expires: string;
  opChangeType: ECookieOpChangeType;
  expandedInitiatorData?: boolean;
  initiators: IAuditCookieInitiator[];
  changes: ICookieOrigin[];
  ttlInMinutes?: number;
  cookieTTL?: number;
  duration?: string | number;
  partitionKey?: string;
}

export interface IAuditCookieInitiator {
  tagId: number;
  initiatorType: string;
  column?: number;
  line?: number;
  requestUrl?: string;
}

export interface IAuditCookieOrigin {
  action: ECookieChangeType,
  originType: ECookieOrigin,
}

export enum ECookieOrigin {
  PreAuditAction = 'PRE_AUDIT_ACTION',
  PageLoad = 'PAGE_LOAD',
  OnPageAction = 'ON_PAGE_ACTION'
}

export enum ECookieChangeType {
  Add = 'ADD',
  Update = 'UPDATE',
  Delete = 'DELETE',
  NoChange = 'NO_CHANGE',
}

export interface IAuditCookieChangeAction {
  sequence: number;
  label: string;
  actionSnapshotId: number;
}

export interface IResponseCookieOriginDTO {
  changes: ICookieOrigin[];
}

export interface IWebJourneyResponseCookieOriginDTO {
  actions: ICookieOrigin[];
  totalCookieInitiatorCount?: number;
  totalCookieActionCount?: number;
}

export interface ICookieOrigin {
  cookie: IAuditCookie;
  cookieChangeType: ECookieChangeType;
  cookieOriginType: ECookieOrigin;
  action?: IAuditCookieChangeAction;
  initiators: IAuditCookieInitiator[];
}

export interface IAuditCookies {
  cookies: IAuditCookie[];
  insights?: {
    noOfUniqueCookies?: number;
    noOfUniqueDomains?: number;
    noOf3rdPartyCookies?: number;
  };
}

export interface IAuditCookiesParams {
  showPreAuditActionCookies?: boolean
}

export interface IAuditRequestLogParams {
  page?: number;
  size?: number;
  search?: string;
  showFileChanges?: boolean;
  showPreAuditActionRequests?: boolean;
  sortBy?: IPageDetailsRequestLogSortColumn;
  sortDesc?: boolean;
}

export interface IAuditRequestLogData {
  metadata: IAuditRequestLogMetadata;
  requests: IAuditRequestLogContent[];
}

export interface IAuditRequestLogMetadata {
  pagination: IAuditRequestLogPagination;
}

export interface IAuditRequestLogPagination {
  currentPageNumber: number;
  currentPageSize: number;
  pageSize: number;
  totalCount: number;
  totalPageCount: number;
}

export interface IAuditRequestLogContent {
  requestUrl: string;
  statusCode: number;
  loadTime: number;
  geoLocation: string;
  origin: string;
  blockingResults: IAuditRequestBlockingResults;
  geoLocationInfo: {
    countryCode: string;
    countryName: string;
  };
  tagId: number;
  mimeType: string;
  fileChange?: IFileChangeData;
  fileInfo?: IFileInfoData;
  responseSizeBytes: number;
  relatedCookies: IAuditRequestLogRelatedCookies[];
  startedAt: string;
}

export interface IAuditRequestLogRelatedCookies {
  name: string;
  domain: string;
}

export interface IAuditRequestLogRelatedCookiesDisplay extends IAuditRequestLogRelatedCookies {
  display: string;
}

export interface IFileChangeData {
  fileChangeType: IChangeType;
  largestDateDifference?: number;
  largestSizeDifference?: number;
}

export interface IFileInfoData {
  lastModifiedDate: string;
  filename: string;
  requestDomain: string;
}

export interface IAuditConsoleLog {
  timestamp: number;
  message: string;
  source: string;
  level: string;
  count: number;
}

export interface IAuditConsoleLogData {
  consoleLogs: IAuditConsoleLog[];
  metadata: IAuditRequestLogMetadata;
}

export interface IPageDetailsModalData {
  pageId: string;
  pageUrl: string;
  tab?: EPageDetailsTabs;
  auditId?: number;
  runId?: number;
  state?: any;
}

export interface IRequestCookieOrigin {
  name: string;
  domain: string;
  path: string;
}

export interface IAuditRequestBlockingResults {
  urlSignature?: IAuditRequestBlockingUrlSignature;
  blockReason: EBlockReason;
}

export interface IAuditRequestBlockingUrlSignature {
  description: string;
  type: string;
}

export interface IJourneyRequestBlockingResults {
  blockReason: EBlockReason;
}
