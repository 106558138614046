import { Names } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { IntervalService } from './IntervalService';
import { NaturalCompareService } from './naturalCompareService';
import { ToastService } from './toastService';
import { UtilitiesService } from './utilitiesService';
import { OverflowClassDirective } from './overflowClassDirective';
import { NavTitleFilter } from './navTitleFilter';
import { QuickLabelsFilter } from './quickLabelsFilter';
import { NullsLastFilter } from './nullsLastFilter';

export default angular.module('utilitiesModule', [])
  .service(Names.Services.intervalService, IntervalService)
  .service(Names.Services.naturalCompare, NaturalCompareService)
  .directive('overflowClass', OverflowClassDirective.factory())
  .service(Names.Services.toastService, ToastService)
  .service(Names.Services.utilities, UtilitiesService)
  .filter(Names.Filters.navTitle, [NavTitleFilter.factory])
  .filter(Names.Filters.labelsFilter, [Names.Services.utilities, QuickLabelsFilter.factory])
  .filter(Names.Filters.nullsLast, [Names.Services.utilities, NullsLastFilter.factory])
  .name;
