import { Inject, Injectable } from '@angular/core';
import * as ngRedux from 'ng-redux';
import { AngularNames, Names } from '@app/moonbeamConstants';
import { AuthenticationActions } from '@app/store/actions/authenticationActions';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { SplitterStatusDataService } from '@app/components/manage/cards/report-card-list/services/splitter-status-data/splitter-status-data.service';
import { CacheResetService, ECacheResetEvent } from '@app/components/core/services/cache-reset.service';

export interface IAuthorizationData {
  token: string;
  id: number;
  expiresAt: number;
  credentialsExpired?: boolean;
  tokenExpired?: boolean;
}

@Injectable()
export class AuthenticationStorageService {

  constructor(@Inject(AngularNames.ngRedux) private $ngRedux: ngRedux.INgRedux,
    private storageService: StorageService,
    private cacheResetService: CacheResetService,
    private splitterStatusDataService: SplitterStatusDataService) { }

  get() {
    if (this.storageService.isLoggedInAsAnother()) {
      return this.storageService.getValue<IAuthorizationData>(Names.GlobalStateKeys.authImpersonate, StorageType.Session);
    } else {
      return this.storageService.getValue<IAuthorizationData>(Names.GlobalStateKeys.authorization, StorageType.Local);
    }
  }

  set(authorizationData: IAuthorizationData) {
    return this.storageService.setValue<IAuthorizationData>(Names.GlobalStateKeys.authorization, authorizationData, StorageType.Local);
  }

  /**
   * Clears all known and stored auth information
   * @param skipOriginalAuth If true, original auth information will not be cleared. Default is false. It should only be true when returning from impersonation
   */
  clear(skipOriginalAuth: boolean = false) {
    this.storageService.removeValue(Names.GlobalStateKeys.authImpersonate, StorageType.Session);

    if (!skipOriginalAuth) {
      this.storageService.removeValue(Names.GlobalStateKeys.authorization, StorageType.Local);
      this.storageService.removeValue(Names.GlobalStateKeys.authorizationId, StorageType.Local);
    }
  }

  private setImpersonateAuth(authorizationData: IAuthorizationData) {
    this.storageService.setValue<IAuthorizationData>(Names.GlobalStateKeys.authImpersonate, authorizationData, StorageType.Session);
  }

  impersonateUser(authorizationData: IAuthorizationData) {
    // If not yet impersonating then start impersonating
    if (!this.storageService.isLoggedInAsAnother()) {
      this.setImpersonateAuth(authorizationData);
      this.splitterStatusDataService.impersonateUser();
    }
    this.cacheResetService.raiseEvent(ECacheResetEvent.impersonate);
  }
  
  getAuth() {
    return this.storageService.getValue<IAuthorizationData>(Names.GlobalStateKeys.authorization, StorageType.Local);
  }
  getAuthImpersonate() {
    return this.storageService.getValue<IAuthorizationData>(Names.GlobalStateKeys.authImpersonate, StorageType.Session);
  }

  returnToOriginalAuth() {
    // wipe all stored auth information except for original op-admin auth information
    this.clear(true);
    this.$ngRedux.dispatch(AuthenticationActions.userLogout(null));
    this.splitterStatusDataService.stopImpersonating();
  }

  setVisitorAuth(authorizationData: IAuthorizationData) {
    this.set(authorizationData);
    this.setId(authorizationData.id);
    this.storageService.setValue<boolean>(Names.GlobalStateKeys.isVisitorUser, true, StorageType.Session);
  }

  setId(authorizationId: number, storageType: StorageType = StorageType.Local) {
    return this.storageService.setValue<number>(Names.GlobalStateKeys.authorizationId, authorizationId, storageType);
  }

  getId(storageType: StorageType = StorageType.Local) {
    return this.storageService.getValue<number>(Names.GlobalStateKeys.authorizationId, storageType);
  }
}
