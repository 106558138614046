import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UiTagService } from './tag-database.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ui-tag-database',
  templateUrl: './tag-database.component.html',
  styleUrls: ['./tag-database.component.scss']
})
export class TagDatabaseComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  accountId: number;
  tags: IUiTag[];
  displayedColumns: string[] = ['tagId', 'icon', 'name', 'category'];
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private uiTagService: UiTagService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.uiTagService.getAllTagsData().subscribe(([tags]) => {
      this.tags = this.getSvgTagIcons(tags);

      this.dataSource.data = this.tags.map((tag: IUiTag) => {
        return {
          tagId: tag.id,
          icon: tag.iconUrl,
          name: tag.name,
          category: this.uiTagService.getTagCategory(tag.tagCategoryId).category,
        };
      });

      this.dataSource.sort = this.sort;
    });

    this.dataSource.sortingDataAccessor = (data, attribute) => {
      if (typeof (data[attribute]) === 'string') {
        return data[attribute].toLowerCase();
      }
      return data[attribute];
    };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  filterTags(value: string): void {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  private getSvgTagIcons(tags: IUiTag[]): IUiTag[] {
    return tags.map((tag: IUiTag) => ({
      ...tag,
      icon: UiTagService.getTagIconUrl(tag.id)
    }));
  }

  /**
   * The following is based on
   * https://medium.com/@danny.pule/export-json-to-csv-file-using-javascript-a0b7bc5b00d2
   */

  downloadCsv() {
    const headers = {
      id: 'Tag ID',
      name: 'Name',
      category: 'Category'
    };

    const formattedItems = this.tags.map((tag: IUiTag) => {
      return {
        id: tag.id,
        name: tag.name.replace(/,/g, ''),
        category: this.uiTagService.getTagCategory(tag.tagCategoryId).category,
      };
    });

    const fileName = 'ObservePoint Tag Database';

    this.exportCSVFile(headers, formattedItems, fileName);
  }

  exportCSVFile(headers, items, fileName) {
    items.unshift(headers);

    let csv = this.convertToCSV(items);
    let exportedFilename = fileName + '.csv';
    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    let link = document.createElement('a');
    let url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', exportedFilename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  convertToCSV(items) {
    let str = '';

    items.forEach((item, index) => {
      let line = '';

      for (let prop in item) {
        if (line != '') line += ',';
        line += items[index][prop];
      }

      str += line + '\r\n';
    });

    return str;
  }
}
