import * as angular from 'angular';
import { AngularNames } from '@app/moonbeamConstants';

let DEFAULT_RETRIES: number = 1;

/**
 * Overflow Class Directive
 *
 * overflow-class: string - class to add to the element if it has overflow
 * retryTimes?: number - (Default: 1) number of times to check element width before stopping.
 *              This is used in cases where the element could be rendered and directive compiled
 *              but element is "display: none" so scrollWidth and clientWidth are both 0. Chances
 *              are we never will need to override this.
 */
export class OverflowClassDirective implements angular.IDirective {
  restrict = 'A';
  scope = {
    retryTimes: '=?'
  };

  constructor(private $timeout: angular.ITimeoutService) {}

  link = (scope, element, attributes) => {
    scope.retryTimes = scope.retryTimes || DEFAULT_RETRIES;

    scope.hasWidthOverflow = () => {
      return element[0].scrollWidth > element[0].clientWidth;
    };

    scope.checkOverflow = (retries: number) => {
      let target = attributes.overflowClass;

      if (scope.hasWidthOverflow()) {
        angular.element(element).addClass(target);
      } else if (retries > 0 && element[0].clientWidth == 0) {
        this.$timeout(() => {
          scope.checkOverflow(retries - 1);
        }, 200);
      } else {
        angular.element(element).removeClass(target);
      }
    };

    const windowResizeStream = Rx.Observable.fromEvent(
      <any>window,
      'resize'
    ).debounce(250);
    windowResizeStream.subscribe(() => {
      this.$timeout(() => {
        scope.checkOverflow(scope.retryTimes);
      });
    });

    this.$timeout(() => {
      scope.checkOverflow(scope.retryTimes);
    });
  }

  static factory(): angular.IDirectiveFactory {
    const directive = ($timeout: angular.ITimeoutService) => {
      return new OverflowClassDirective($timeout);
    };
    directive.$inject = [
      AngularNames.timeout
    ];
    return directive;
  }
}