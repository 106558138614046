import { Component, OnDestroy } from '@angular/core';
import { IAllTagInfo, ITagSignature, ITagVariable, TagsService } from '@app/components/tags/tagsService';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminPortalUrlBuilders } from '@app/components/admin-portal/admin-portal.constants';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'view-tag',
  templateUrl: './view-tag.component.html',
  styleUrls: ['./view-tag.component.scss'],
})

export class ViewTagComponent implements OnDestroy {
  tag: IAllTagInfo;
  tagSignaturesDataSource: MatTableDataSource<ITagSignature> = new MatTableDataSource([]);
  tagVariablesDataSource: MatTableDataSource<ITagVariable> = new MatTableDataSource([]);
  signaturesExpanded: boolean = true;
  variablesExpanded: boolean = true;
  variablesFilter: string = '';
  filterChanged$ = new Subject<string>();
  private destroy$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tagsService: TagsService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      if (!params.tagId) {
        this.goToTagDatabase();
        return;
      }

      this.tagsService.getAllTagInfo(+params.tagId).then(tag => {
        this.tag = tag;
        this.tag.icon = UiTagService.getTagIconUrl(tag.id);
        this.tagSignaturesDataSource.data = tag.signatures;
        this.tagVariablesDataSource.data = tag.variables;
      });
    });

    this.filterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.variablesFilter = filter;
      this.tagVariablesDataSource.filter = this.variablesFilter;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  debounceFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.filterChanged$.next(filter);
  }

  clickSearch(event): void {
    event.stopPropagation();
  }

  toggleSignatures() {
    this.signaturesExpanded = !this.signaturesExpanded;
  }

  toggleVariables() {
    this.variablesExpanded = !this.variablesExpanded;
  }

  goToTagDatabase() {
    this.router.navigateByUrl(AdminPortalUrlBuilders.tagDatabaseLibrary());
  }
}
