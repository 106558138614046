import { RouteReloadService } from './../shared/services/route-reload.service';
import { IWebJourneyEditorModalData } from '@app/components/web-journey/web-journey-editor/web-journey-editor.models';
import { IWebJourneyV3 } from './../domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { OpModalService } from './../shared/components/op-modal/shared/op-modal.service';
import { ModalEscapeService } from './../ui/modalEscape/modalEscapeService';
import {
  WebJourneyReportPaths,
  WebJourneyReportUrlBuilders,
  WebJourneyTitles
} from '@app/components/web-journey-report/web-journey-report.constants';
import { startWith, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { IWJReportParams } from './web-journey-report.models';
import { INavSection } from '../navigation/modal-sidebar/modal-sidebar.models';
import { WebJourneyReportService } from './web-journey-report.service';
import { Subject, combineLatest } from 'rxjs';
import { KeyboardShortcutsService } from '../shared/services/keyboard-shortcuts/keyboard-shortcuts.service';
import { WebJourneyEditorComponent } from '../web-journey/web-journey-editor/web-journey-editor.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'web-journey-report',
  templateUrl: './web-journey-report.component.html',
  styleUrls: ['./web-journey-report.component.scss']
})
export class WebJourneyReportComponent implements OnInit, OnDestroy {

  navSections: INavSection[] = [{
    navs: [{
      title: WebJourneyTitles.results,
      link: WebJourneyReportPaths.results
    }, {
      title: WebJourneyTitles.rules,
      link: WebJourneyReportPaths.rules
    }, {
      title: WebJourneyTitles.exports,
      link: WebJourneyReportPaths.exports
    }]
  }];

  journeyId: number;
  runId: number;

  private destroy$ = new Subject();

  constructor(private router: Router,
    private route: ActivatedRoute,
    private routeReload: RouteReloadService,
    private modalEscapeService: ModalEscapeService,
    private modalService: OpModalService,
    private webJourneyReportService: WebJourneyReportService,
    private keyboardShortcutsService: KeyboardShortcutsService) {
  }

  ngOnInit() {
    combineLatest([
      this.route.params,
      this.routeReload.reloadRouteEvents$.pipe(startWith({})),
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([params, _]: [IWJReportParams, NavigationEnd]) => {
        this.journeyId = +params.journeyId;
        this.runId = +params.runId;

        this.updateNavLinks();

        if (this.runId > 0) {
          this.getJourneyResult();
        }

        this.keyboardShortcutsService.registerEditMethod(() => this.editWebJourney());
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private updateNavLinks(): void {
    this.navSections = [{
      navs: [{
        title: WebJourneyTitles.results,
        link: WebJourneyReportUrlBuilders.results(this.journeyId, this.runId)
      }, {
        title: WebJourneyTitles.rules,
        link: WebJourneyReportUrlBuilders.rules(this.journeyId, this.runId)
      }, {
        title: WebJourneyTitles.exports,
        link: WebJourneyReportUrlBuilders.exports(this.journeyId, this.runId)
      }]
    }];
  }

  private getJourneyResult() {
    this.webJourneyReportService.getJourneyResult(this.journeyId, this.runId).subscribe(result => {
      const hasFailedRules = result?.failedRuleReports?.length > 0;
      this.navSections[0].navs[1].icon = hasFailedRules ? 'icon-error-circle' : '';
    });
  }

  private editWebJourney(): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    const data: IWebJourneyEditorModalData = {
      journeyId: this.journeyId,
    };
    this.modalService.openFixedSizeModal(WebJourneyEditorComponent, { disableClose: true, data }, 'op-webjourney-editor')
      .afterClosed()
      .subscribe((webJourney?: IWebJourneyV3) => {
        this.modalEscapeService.remove(index);
        if (webJourney) this.router.navigateByUrl(this.router.url);
      });
  }

}
