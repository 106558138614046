import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { MAPPED_DAYS_FORMAT, DAYS_IN_WEEK } from './calendar.constants';
import { ICalendarEvent, IMappedEvents, ICalendarDay, ISelectedDay, ICalendarWeek, ISelectedDayPayload } from './calendar.models';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ECalendarDayType } from './calendar.enums';
import { IRunPickerRun } from '@app/components/run-picker-ng/run-picker-ng.models';
import { ERunPickerStatus } from '@app/components/run-picker-ng/run-picker-ng.enums';
import { DateService, formatDefs } from '@app/components/date/date.service';
import { isSameDay, startOfMonth } from 'date-fns';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EAccountType } from '@app/components/core/services/authentication.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  today: Date;
  currentMonth: Date;
  titleDateFormat: string = formatDefs.dateEight;

  dayEvents: IMappedEvents;
  weeks: ICalendarWeek[];
  selectedDate: ISelectedDay;

  rightArrowEnabled: boolean;
  showUpgradeUserBanner = false;

  private destroySubject = new Subject<void>();

  @Input() events: ICalendarEvent<IRunPickerRun>[];
  @Input() startMonth: Date;
  @Output() onSelectDate: EventEmitter<ISelectedDayPayload> = new EventEmitter();

  constructor(
    private dateService: DateService,
    private applicationChromeService: ApplicationChromeService
  ) {
    this.today = new Date();

    this.currentMonth = new Date(this.dateService.getStartOfMonth(new Date(this.startMonth || this.today)));
    if (!this.dateService.isDateValid(this.currentMonth)) this.currentMonth = new Date();
  }

  ngOnInit() {
    this.dayEvents = this.buildEventsMap(this.events);
    this.initWeeks();
    this.findSelectedEvent();
    this.setArrowStatus();

    this.applicationChromeService.accountPreview$
      .pipe(filter(accountPreview => !!accountPreview), takeUntil(this.destroySubject))
      .subscribe(({ accountType }) => {
        this.showUpgradeUserBanner = accountType === EAccountType.SAMPLE;
      });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  handleLeftArrow(): void {
    this.currentMonth = this.dateService.subtractMonthsFromDate(this.currentMonth, 1);
    this.initWeeks();
    this.findSelectedEvent();
    this.setArrowStatus();
  }

  handleRightArrow(): void {
    if (!this.canGoToNextMonth()) return;

    this.currentMonth = this.dateService.addMonthsToDate(this.currentMonth, 1);
    this.initWeeks();
    this.findSelectedEvent();
    this.setArrowStatus();
  }

  containsFlaggedEvent(day: ICalendarDay): boolean {
    const mapKey = this.buildMapKey(day.moment);

    return this.dayEvents[mapKey] ?
      !!this.dayEvents[mapKey].find(event => event.status !== ERunPickerStatus.Success) :
      false;
  }

  handleClickDate(day: ICalendarDay, clickEvent: boolean = false): void {
    if (!this.isCurrentMonthDay(day)) return;

    if (this.selectedDate) {
      this.selectedDate.date.selected = false;
    }

    this.setSelectedDate(day);

    if (this.onSelectDate) {
      const mapKey = this.buildMapKey(this.selectedDate.moment);
      this.onSelectDate.emit({
        date: this.selectedDate.moment,
        events: this.dayEvents && this.dayEvents[mapKey] ? this.dayEvents[mapKey] : [],
        clickEvent
      });
    }
  }

  private buildEventsMap(events: ICalendarEvent<IRunPickerRun>[]): IMappedEvents {
    return events.reduce((mappedEvents: IMappedEvents, event: ICalendarEvent<IRunPickerRun>) => {
      const mapKey = this.buildMapKey(event.date);
      if (!mappedEvents[mapKey]) mappedEvents[mapKey] = [];

      mappedEvents[mapKey].push(event);
      return mappedEvents;
    }, {});
  }

  private buildMapKey(date: Date): string {
    return this.dateService.formatDate(date, MAPPED_DAYS_FORMAT);
  }

  private initWeeks(): void {
    const numberOfDays = this.dateService.getNumberOfDaysInMonth(this.currentMonth);
    const paddingBefore = this.dateService.getDayOfWeekForFirstDayOfMonth(this.currentMonth);
    const remainder = (numberOfDays + paddingBefore) % DAYS_IN_WEEK;
    const paddingAfter = remainder > 0 ? DAYS_IN_WEEK - (remainder) : 0;

    const days = this.createCalendarDays(paddingBefore, numberOfDays, paddingAfter);
    this.processCalendarDays(days);

    this.weeks = this.mapDaysToWeeks(days);
  }

  private createCalendarDays(prevMonthPadding: number, numberOfDays: number, nextMonthPadding: number): ICalendarDay[] {
    const dayConfigs = [{
      type: ECalendarDayType.PADDING,
      n: prevMonthPadding
    }, {
      type: ECalendarDayType.CURRENT_MONTH,
      n: numberOfDays
    }, {
      type: ECalendarDayType.PADDING,
      n: nextMonthPadding
    }];

    return dayConfigs.reduce(
      (days, val) => days.concat(this.createDays(val.n, val.type)),
      []
    );
  }

  private createDays(n: number, type: ECalendarDayType) {
    return Array.apply(null, new Array(n)).map((obj, index) => this.createDay(type, index + 1));
  }

  private createDay(type: ECalendarDayType, date?: number, selected?: boolean): ICalendarDay {
    switch (type) {
      case ECalendarDayType.CURRENT_MONTH:
        return {
          date,
          selected: selected || false,
          moment: this.createDayInCurrentMonth(date)
        };
      case ECalendarDayType.PADDING:
        return {
          date: 0
        };
    }
  }

  /**
   * Return a new date set to midnight. It will be the [day] passed in of the current month.
   * @param day - number representing day of the month
   */
  private createDayInCurrentMonth(day: number): Date {
    const clonedDate = new Date(this.currentMonth);
    clonedDate.setDate(day);
    return this.dateService.getStartOfDay(clonedDate);
  }

  private processCalendarDays(days: ICalendarDay[]): void {
    days.forEach((val: ICalendarDay) => {
      this.checkDayIfSelected(val);
      this.addEventsToCalendarDay(val);
    });
  }

  private checkDayIfSelected(day: ICalendarDay): void {
    if (!this.selectedDate) return;
    if (this.isCurrentMonthDay(day) && this.dateService.isDateSameDay(day.moment, this.selectedDate.moment)) {
      this.setSelectedDate(day);
    }
  }

  private isCurrentMonthDay(day: ICalendarDay): boolean {
    return day.date > 0;
  }

  private setSelectedDate(day: ICalendarDay): void {
    this.selectedDate = {
      moment: this.createDayInCurrentMonth(day.date),
      date: day
    };
    this.selectedDate.date.selected = true;
  }

  private addEventsToCalendarDay(day: ICalendarDay): void {
    if (this.isCurrentMonthDay(day)) {
      day.numOfEventsTotal = this.dayEvents && this.dayEvents[this.buildMapKey(day.moment)] ?
        this.dayEvents[this.buildMapKey(day.moment)].length :
        null;
      day.numOfEventsByStatus = this.dayEvents ?
        this.buildNumOfEventsByStatus(this.dayEvents[this.buildMapKey(day.moment)]) :
        null;
    }
  }

  private buildNumOfEventsByStatus(events: ICalendarEvent<IRunPickerRun>[]): Map<number, number> {
    const template = new Map<ERunPickerStatus, number>([
      [ERunPickerStatus.Success, 0],
      [ERunPickerStatus.ActionFailed, 0],
      [ERunPickerStatus.RuleFailure, 0]
    ]);
    if (!events) return template;
    return events.reduce((result: Map<ERunPickerStatus, number>, event: ICalendarEvent<IRunPickerRun>) => {
      const prevCount = result.get(event.status);
      return result.set(event.status, prevCount + 1);
    }, template);
  }

  private mapDaysToWeeks(days: ICalendarDay[]): ICalendarWeek[] {
    return days.map((day: ICalendarDay, index: number, arr: ICalendarDay[]) => {
      return index % DAYS_IN_WEEK === 0
        ? { days: arr.slice(index, index + DAYS_IN_WEEK) }
        : null;
    }).filter(e => e);
  }

  private findSelectedEvent(): void {
    if (!this.weeks) return;

    for (let i = 0; i < this.weeks.length; i++) {
      for (let j = 0; j < this.weeks[i].days.length; j++) {

        if (!this.isCurrentMonthDay(this.weeks[i].days[j])) continue;

        let day = this.weeks[i].days[j];
        let events = this.dayEvents[this.buildMapKey(day.moment)] || [];

        for (let y = 0; y < events.length; y++) {

          if (events[y].isSelected) {
            this.handleClickDate(day);
            return;
          }

        }
      }
    }
  }

  private setArrowStatus() {
    this.rightArrowEnabled = this.canGoToNextMonth();
  }

  private canGoToNextMonth() {
    return !isSameDay(startOfMonth(this.today), startOfMonth(this.currentMonth));
  }

}
