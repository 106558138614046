<component-template componentName="Button">
  <component-library-section sectionName="Basic button" selector="<button mat-flat-button>">
    <p>This is an Angular Material button and the most common button used in the app. This button's text has been overridden to always be uppercase. This override is found in <code>button-overrides.scss</code>.</p>
    <button mat-flat-button>Basic button</button><br><br>
    <button mat-flat-button color="primary">Basic Button Primary Color</button><br><br>
    <button mat-flat-button disabled>Basic button disabled</button><br><br>
  </component-library-section>

  <component-library-section sectionName="Additional button styles from Material" selector="<button mat-stroked-button>">
    <p>
      No overrides are added to this button – this is how it comes straight from Material.
      A version of this is used in the API Key section of the user editor modal but in that instance
      the stroke was changd to yellow instead of the default gray. We could create a reusable version like that at
      a future time.
    </p>
    <button mat-stroked-button>Basic Stroked Button</button><br><br>
    <button mat-stroked-button color="primary">Basic Stroked Button Primary Color</button><br><br>
    <button mat-stroked-button disabled>Basic Stroked Button Disabled</button><br><br>
  </component-library-section>

  <component-library-section sectionName="OP Button" selector="<op-button>">
    <p>This is a legacy button that isn't used anywhere in the app anymore. It's been added here so we can potentially rebuild and reuse it in the future.</p>
    <op-button>Example Button</op-button>
  </component-library-section>

  <component-library-section sectionName="OP Button 2021" selector="<op-button-2021>">
    <p>This button accepts an icon. Settings icon was used here just as an example.</p>
    <op-button-2021 labelText="Example Button - Primary" buttonType="primary" matIcon="settings"></op-button-2021>
    <op-button-2021 labelText="Example Button - Accent" buttonType="accent" matIcon="settings"></op-button-2021>
    <op-button-2021 labelText="Example Button - Transparent" buttonType="transparent" matIcon="settings"></op-button-2021>
    <op-button-2021 labelText="Example Button - Filled" buttonType="filled" matIcon="settings"></op-button-2021>
    <br>
    <br>
    <p>These instances show how it also accepts a menu.</p>
    <op-button-2021 labelText="Example Button - Primary" buttonType="primary" matIcon="settings" [menuItems]="menuItems"></op-button-2021>
    <op-button-2021 labelText="Example Button - Accent" buttonType="accent" matIcon="settings" [menuItems]="menuItems"></op-button-2021>
    <op-button-2021 labelText="Example Button - Transparent" buttonType="transparent" matIcon="settings" [menuItems]="menuItems"></op-button-2021>
    <op-button-2021 labelText="Example Button - Filled" buttonType="filled" matIcon="settings" [menuItems]="menuItems"></op-button-2021>
    <br>
    <br>
    <p>This instance shows a button without the icon on the left. The menu icon is still present.</p>
    <op-button-2021 labelText="Example Button - Primary" buttonType="primary" [hiddenIcon]="true" [menuItems]="menuItems"></op-button-2021>
    <br>
    <br>
    <p>This instance shows a button without either of the icons.</p>
    <op-button-2021 labelText="Example Button - Primary" buttonType="primary" [hiddenIcon]="true" [showDefaultMenuIcon]="false" [menuItems]="menuItems"></op-button-2021>
  </component-library-section>
</component-template>