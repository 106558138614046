import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'click-to-enlarge-screenshot-modal',
  templateUrl: './click-to-enlarge-screenshot-modal.component.html',
  styleUrls: ['./click-to-enlarge-screenshot-modal.component.scss']
})
export class ClickToEnlargeScreenshotModalComponent {

  constructor(
    public dialogRef: MatDialogRef<ClickToEnlargeScreenshotModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { screenshotUrl: string, title?: string },
  ) {}

  rightFooterButtons: IButton[] = [
    {
      label: 'Done',
      primary: true,
      action: () => this.closeModal()
    }
  ];

  closeModal(): void {
    this.dialogRef.close();
  }
}
