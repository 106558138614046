<div class="basic-export-wrap">
  <table mat-table
         matSort
         class="basic-export-table styled-mat-table"
         [dataSource]="tableDataSource"
         multiTemplateDataRows
         matSortDirection="desc"
         matSortStart="desc">

    <ng-container [matColumnDef]="TableColumns.Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        EXPORT NAME
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row[TableColumns.Name] }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumns.Description">
      <th mat-header-cell *matHeaderCellDef>
        DESCRIPTION
      </th>
      <td mat-cell
          *matCellDef="let row">
        {{ row[TableColumns.Description] }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumns.Preview">
      <th mat-header-cell *matHeaderCellDef>
        PREVIEW
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="table-actions">
          <button class="table-action-icon preview-button"
                  [matTooltip]="'Preview'"
                  [matTooltipPosition]="'above'"
                  (click)="openImageInModal(row[TableColumns.Name], row.previewUrl)"
                  disableRipple
                  mat-button>
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumns.Export">
      <th mat-header-cell *matHeaderCellDef>
        EXPORT
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="table-actions" *ngIf="row.status === EAuditExportStatuses.notExported">
          <button class="export-button"
                  [matTooltip]="'Export'"
                  [matTooltipPosition]="'above'"
                  disableRipple
                  mat-button
                  (click)="handleExport(row)">
            <mat-icon>cloud_download_filled</mat-icon>
          </button>
        </div>
        <mat-spinner *ngIf="row.status === EAuditExportStatuses.generatingExport" class="spinner" [diameter]="17"
                     [strokeWidth]="2"></mat-spinner>
        <span class="request-sent-label" *ngIf="row.status === EAuditExportStatuses.emailSent">Request Sent</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"
        class="item-row">
    </tr>
  </table>

  <div class="export-center-text">
    View all the previous exported reports from this and all other audits in the&nbsp;
    <a (click)="goToExportCenter()">Export Center</a>
    .
  </div>
</div>
