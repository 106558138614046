export enum EEmailInboxModalType {
  /**
   * Quick Create is a mode for creating using a default inbox and simple data to start.
   */
  QuickCreate,

  /**
   * CreatePrefilled is a mode for creating alert with some specified data
   * AuditId, Filters, Notification settings
   * It is used in QuickCreate modal
   */
  CreatePrefilled,

  /**
   * ReadOnly is mode for preview the alert for read-only users
   */
  ReadOnly,

  /**
   * Edit for editing from Alert Library
   */
  Edit,

  /**
   * Duplicate for duplicating from Alert Library
   */
  Duplicate,
  /**
   * Advanced Create is a mode for creating using a default inbox and customizing data to start.
   */
  AdvancedCreate = 5,
}

export enum EEmailInboxStep {
  Inbox,
  Testing,
  Notifications,
}
