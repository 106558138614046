import { MaterialModule } from '@app/components/material/material.module';
import { NgModule } from '@angular/core';
import { ActiveSelectorPickerComponent } from './active-selector-picker/active-selector-picker.component';
import { MultipleSelectorsComponent } from './multiple-selectors/multiple-selectors.component';
import { ActiveSelectorDetailsComponent } from './active-selector-details/active-selector-details.component';
import { SharedModule } from '../../shared.module';
import { CommonModule } from '@angular/common';
import { MultiSelectorsService } from './multi-selectors.service';
import { OpFormModule } from '../../op-form.module';

@NgModule({
  imports: [
    OpFormModule,
    CommonModule,
    MaterialModule,
    SharedModule,
  ],
  exports: [
    ActiveSelectorPickerComponent,
  ],
  declarations: [
    ActiveSelectorDetailsComponent,
    ActiveSelectorPickerComponent,
    MultipleSelectorsComponent
  ],
  providers: [
    MultiSelectorsService
  ],
})
export class MultiSelectorsModule { }
