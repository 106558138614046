import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IKeyValue, IUserBase } from '@app/moonbeamModels';
import { IAmount } from '@app/models/utils/amount';
import { IUserModel } from '../modals/modalData';
import {
  IAdminAccount,
  IAdminAccountDTO, ICreateAccountRequest
} from '@app/components/admin-portal/manage-accounts/manage-accounts.constants';

export interface IAdminTag {
  id: number;
  name: string;
  tagCategoryId: number;
}

export interface IAdminAccountFeature {
  name: string,
  enabled: boolean
}

export interface IAdminAccountFeatureRequest {
  name: string
}

export interface IProductLimits {
  maxComparisonTags: number;
}

export interface IScriptServicesConfig {
  journeyFixes: number,
  maxMonitoredJourneys: number
}

export interface ISsoConfig {
  authenticationType: string;
  idpLoginUrl?: string;
  idpLogoutUrl?: string;
  certificate?: string;
  createNewUser?: boolean;
  identityId?: string; // is always "observepoint"
}

export enum ERetentionPolicyName {
  STANDARD = 'STANDARD',
  STANDARD_PLUS = 'STANDARD_PLUS',
  EXTENDED = 'EXTENDED',
}

// Important to keep the order, because it defines whether the confirmation modal will appear
// when changing the retention policy to a lower one
export enum ERetentionPolicyNameToIndex {
  STANDARD,
  STANDARD_PLUS,
  EXTENDED,
}

export const ERetentionPolicyNameToDisplayValue: { [key: string]: string; } = {
  [ERetentionPolicyName.STANDARD]: 'Standard - 3 months',
  [ERetentionPolicyName.STANDARD_PLUS]: 'Standard Plus - 6 months',
  [ERetentionPolicyName.EXTENDED]: 'Extended - 13 months',
};

export enum ERetentionPolicyUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
}

export interface IV3AdminAccountRetentionPolicy {
  name: ERetentionPolicyName;
  unit: ERetentionPolicyUnit;
  duration: number;
}

export interface IV3AdminAccount {
  id: number;
  accountType: number;
  company: string;
  maxUsers: number;
  strongPasswordPolicy: boolean;
  subDomainIdentifier: string;
  primaryUser: IV3PrimaryUser;
  retentionPolicy?: IV3AdminAccountRetentionPolicy;
}

export interface IV3PrimaryUser {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  accountId: number;
  blockedUntil: string;
  deleteFlag: boolean;
  role: string;
}

export interface IAdminAccountSubdomain {
  subdomain: string;
}

@Injectable()
export class AdminAccountsService {

  root: string = environment.apiUrl + 'admin/accounts';
  authRoot: string = environment.apiV3Url + 'accounts';

  constructor(private apiService: ApiService) {
  }

  getAccountsPaged(limit: number, skip: number, searchTerm: string, sortColumn: string, sortAscending: boolean): Observable<IAdminAccount[]> {
    var sort = sortColumn + (sortAscending ? '-asc' : '-desc');
    var search = searchTerm ? searchTerm : '';
    const query = `?limit=${limit}&skip=${skip}&search=${search}&sort=${sort}`;
    return this.apiService.get(this.root + query);
  }

  getV3AccountsPaged(size: number, page: number, sortColumn: string, sortAscending: boolean, filter): Observable<IAdminAccountDTO> {
    let sort = sortAscending ? false : true;
    const query = `?size=${size}&page=${page}${sortColumn && sortAscending !== undefined ? '&sortBy=' + sortColumn + '&sortDesc=' + sort : ''}`;
    let filters = this.getFilters(filter);
    return this.apiService.post(`${this.authRoot}/search${query}`, filters);
  }

  getFilters(filter) {
    let filters = {
      search: '',
      types: [],
      statuses: [],
    };

    filter.forEach(f => {
      switch (f.type) {
        case 'search':
          filters.search = f.value;
          break;
        case 'type':
          filters.types.push(f.value);
          break;
        case 'status':
          filters.statuses.push(f.value);
          break;
      }
    });

    return filters;
  }

  getAccountsCount(search: string): Observable<IAmount> {
    return this.apiService.get(`${this.root}/count?search=${search}`);
  }

  saveAccount(account: ICreateAccountRequest): Observable<any> {
    return this.apiService.post(this.root, account);
  }

  updateAccount(account: IAdminAccount): Observable<any> {
    return this.apiService.put(this.root, account);
  }

  getUsersForAccount(accountId: number): Observable<IUserModel[]> {
    return this.apiService.get(`${this.root}/${accountId}/users`);
  }

  getPermissions(): Observable<IKeyValue<number, string>[]> {
    return this.apiService.get(this.root + '/permissions');
  }

  getStatuses(): Observable<IKeyValue<number, string>[]> {
    return this.apiService.get(this.root + '/statuses');
  }

  getProductLimits(accountId: number): Observable<IProductLimits> {
    return this.apiService.get(`${this.root}/${accountId}/limits`);
  }

  updateProductLimits(accountId: number, limits: IProductLimits): Observable<IProductLimits> {
    return this.apiService.put(`${this.root}/${accountId}/limits`, limits);
  }

  getFrequencies(): Observable<IKeyValue<number, string>[]> {
    return this.apiService.get(this.root + '/frequencies');
  }

  findTagsPagedForAccount(accountId: number, search: string = '', unassigned: boolean = false, limit: number = 100, skip: number = 0, spinnerKey: string = null, tagCategoryId: number = null): Observable<IAdminTag[]> {
    const query = `/${accountId}/tags?limit=${limit}&skip=${skip}&search=${search}&unassigned=${unassigned}&categoryId=${tagCategoryId}`;
    return this.apiService.get(this.root + query, null, null, spinnerKey);
  }

  getTagsForAccount(accountId: number, unassigned: boolean, spinnerKey: string): Observable<IAdminTag[]> {
    const query = `/${accountId}/tags?unassigned=${unassigned}&limit=2000`;
    return this.apiService.get(this.root + query);
  }

  saveAssignedTags(accountId: number, tags: number[]): Observable<void> {
    const query = `/${accountId}/tags`;
    return this.apiService.post(this.root + query, tags);
  }

  deleteAssignedTags(accountId: number, tags: number[]): Observable<any> {
    const query = `/${accountId}/tags`;
    return this.apiService.delete(this.root + query, { body: tags });
  }

  getFeaturesForAccount(accountId: number): Observable<IAdminAccountFeature[]> {
    const query = `/${accountId}/features`;
    return this.apiService.get(this.root + query);
  }

  enableFeaturesForAccount(accountId: number, features: IAdminAccountFeatureRequest[]): Observable<any> {
    const query = `/${accountId}/features`;
    return this.apiService.post(this.root + query, features);
  }

  disableFeaturesForAccount(accountId: number, features: IAdminAccountFeatureRequest[]): Observable<any> {
    const query = `/${accountId}/features`;
    return this.apiService.delete(this.root + query, { body: features });
  }

  createUser(accountId: number, user: IUserBase): Observable<any> {
    const query = `${this.root}/${accountId}/users`;
    return this.apiService.post(query, user);
  }

  updateUser(accountId: number, user: IUserBase): Observable<any> {
    const query = `${this.root}/${accountId}/users/${user.id}`;
    return this.apiService.put(query, user);
  }

  updateUserPassword(accountId: number, userId: number, newPassword: string): Observable<any> {
    const query = `${this.root}/${accountId}/users/${userId}/password`;
    return this.apiService.put(query, newPassword);
  }

  getScriptServicesConfig(accountId: number): Observable<IScriptServicesConfig> {
    const url = `${this.root}/${accountId}/services/config`;
    return this.apiService.get(url);
  }

  updateScriptServicesConfig(accountId: number, config: IScriptServicesConfig): Observable<void> {
    const url = `${this.root}/${accountId}/services/config`;
    return this.apiService.put(url, config);
  }

  getAccountById(accountId: number): Observable<IV3AdminAccount> {
    const query = `${this.authRoot}/${accountId}`;
    return this.apiService.get(query);
  }

  updateAccountById(accountId: number, subdomain: IAdminAccountSubdomain): Observable<IV3AdminAccount> {
    const query = `${this.authRoot}/${accountId}`;
    return this.apiService.put(query, subdomain);
  }

  updateAccountRetentionPolicy(accountId: number, retentionPolicy: ERetentionPolicyName): Observable<IV3AdminAccount> {
    const query = `${this.authRoot}/${accountId}/data-retention`;
    return this.apiService.put(query, { retentionPolicy });
  }

  getAuthConfig(accountId: number): Observable<any> {
    const query = `${this.authRoot}/${accountId}/authenticationconfig`;
    return this.apiService.get(query);
  }

  getAuthConfigXml(accountId: number): Observable<any> {
    const httpOptions = {
      headers: {
        'Accept': 'application/xml'
      }
    };

    const query = `${this.authRoot}/${accountId}/authenticationconfig`;
    return this.apiService.get(query, httpOptions);
  }

  createAuthConfig(accountId: number, ssoConfig: ISsoConfig): Observable<any> {
    const query = `${this.authRoot}/${accountId}/authenticationconfig`;
    return this.apiService.post(query, ssoConfig);
  }

  updateAuthConfig(accountId: number, ssoConfig: ISsoConfig): Observable<any> {
    const query = `${this.authRoot}/${accountId}/authenticationconfig`;
    return this.apiService.put(query, ssoConfig);
  }
}
