<div class="filter-bar-wrap">
  <div *ngIf="!searchByTextHidden">
    <div #parentDivSearch class="search-by-url-wrap"
         [class.disabled]="!isSearchByTextEnabled"
         [class.wide]="keepWide"
         matTooltip="No {{ searchByTextPlaceholderSuffix }}s to search for on the page"
         matTooltipPosition="above"
         [matTooltipDisabled]="isSearchByTextEnabled">
      <mat-icon class="search-by-url-icon">search</mat-icon>
      <input autocomplete="off"
             placeholder="Search By {{ searchByTextPlaceholderSuffix }}"
             [(ngModel)]="searchByTextInputValue"
             [attr.disabled]="!isSearchByTextEnabled ? 'true' : null"
             (keyup)="searchByTextKeyUp($event)"
             (focus)="searchByTextFocused()"
             (blur)="searchByTextBlurred()"
             (focusout)="focusout($event)"
             tabindex="1"
             class="search-by-url-input"
      />
      <button *ngIf="searchByTextInputValue !== ''"
              class="clear-search-by-url-btn"
              (click)="clearSearchByUrl()"
              type="button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="search-by-url-regex-opt">
      <div *ngIf="showTextSearchByRegexOpt && !hideSearchByRegexOption">
        <mat-checkbox [(ngModel)]="treatSearchByTextAsRegex"
          color="primary"
          (focusin)="searchByTextFocused()"
          (focusout)="searchByTextBlurred()"
          (mousedown)="searchByTextMouseDown()"
          tabindex="2"
          >Treat as regular expression</mat-checkbox>
      </div>
    </div>
  </div>
  <button mat-button class="add-filter-button"
    [class.always-highlighted]="alwaysHighlightedFiltersButton"
    [matMenuTriggerFor]="menu.childMenu"
    #matMenuTrigger="matMenuTrigger"
    tabindex="3"
    type="button"
    (menuOpened)="menuOpened()"
    (menuClosed)="menuClosed()">
    <mat-icon class="filter-icon">tune</mat-icon>
    Filters
    <mat-icon class="expand-icon" iconPositionEnd>expand_more</mat-icon>
  </button>
  <div #filterChipBag class="chip-bag">
    <div *ngIf="printViewFilters?.length" class="print-view">
      <h4 class="title">FILTERS:</h4>
      <mat-chip-listbox>
        <mat-chip-option *ngFor="let printChip of printViewFilters"
                         highlighted
                         class="print-view-chip"
        >
          <mat-icon class="chip-icon" *ngIf="printChip.icon">{{ printChip.icon }}</mat-icon>
          <p class="chip-label-html">{{ printChip.printViewLabel }}: &nbsp;
            <span [innerHTML]="printChip.menuItemNameTemplate"></span>
          </p>
        </mat-chip-option>
      </mat-chip-listbox>
    </div>

    <mat-chip-listbox aria-label="Currently applied filters" class="screen-view">
      <ng-container
        *ngFor="let vizFilter of visibleFilters">

        <!-- invertable chip template -->
        <mat-chip-option *ngIf="vizFilter.toggleOption1"
                  [removable]="true"
                  (removed)="removeFilter(vizFilter)"
                  [color]="validFilterTypes.includes(vizFilter.type) ? 'primary' : 'none'"
                  [matTooltip]="validFilterTypes.includes(vizFilter.type) ? 'Filter is active on this report' : 'Filter is inactive on this report'"
                  matTooltipPosition="above"
                  highlighted>
          <span class="chip-label-html" [innerHTML]="vizFilter.display"></span>
          <div class="chip-toggle" (click)="toggleInvertableFilter(vizFilter)">
            <ng-container *ngIf="vizFilter.state">
              <mat-icon class="chip-toggle-icon">toggle_on</mat-icon>
              <span class="chip-toggle-text">{{ vizFilter.toggleOption1.display }}</span>
            </ng-container>
            <ng-container *ngIf="!vizFilter.state">
              <mat-icon class="chip-toggle-icon">toggle_off</mat-icon>
              <span class="chip-toggle-text">{{ vizFilter.toggleOption2.display }}</span>
            </ng-container>
          </div>
          <span class="chip-label-html" [innerHTML]="vizFilter.postDisplay || ''"></span>
          <mat-icon matChipRemove *ngIf="!vizFilter.unremovable">close</mat-icon>
        </mat-chip-option>

        <!-- all other chips template -->
        <mat-chip-option *ngIf="!vizFilter.toggleOption1"
                  [removable]="true"
                  (removed)="removeFilter(vizFilter)"
                  [color]="validFilterTypes.includes(vizFilter.type) ? 'primary' : 'none'"
                  highlighted
                  [matMenuTriggerFor]="vizFilter.menuItems?.length ? chipMenu.childMenu : null">
          <mat-icon class="chip-icon" *ngIf="vizFilter.icon">{{ vizFilter.icon }}</mat-icon>
          <span class="chip-label-html" [innerHTML]="vizFilter.display"></span>
          <mat-icon class="chip-expand-icon" *ngIf="vizFilter.menuItems?.length">expand_more</mat-icon>
          <div class="close-divider" *ngIf="!vizFilter.unremovable"></div>
          <mat-icon matChipRemove *ngIf="!vizFilter.unremovable">close</mat-icon>
        </mat-chip-option>

        <op-filter-bar-menu-item-v2 #chipMenu [menuItems]="vizFilter.menuItems" [service]="service" [menuClass]="menuClass"></op-filter-bar-menu-item-v2>

      </ng-container>
      <mat-chip-option *ngIf="hiddenFilters.length"
        [removable]="false"
        [matMenuTriggerFor]="hiddenFiltersMenu"
        [color]="hiddenContainsValidTypes ? 'primary' : 'none'"
        highlighted>
        More ({{ hiddenFilters.length }})
        <mat-icon class="more-chip-arrow chip-expand-icon">expand_more</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
<!-- Hidden filters menu -->
<mat-menu #hiddenFiltersMenu="matMenu"
          xPosition="before"
          class="hidden-filter-menu"
          backdropClass="no-filter">
  <div class="filter-chip hidden-filter-menu-item" mat-menu-item *ngFor="let hidFilter of hiddenFilters">
    <mat-chip-listbox>
      <mat-chip-option [removable]="true"
                       (removed)="removeFilter(hidFilter)"
                       [color]="validFilterTypes.includes(hidFilter.type) ? 'primary' : 'none'"
                       highlighted>
        <mat-icon class="chip-icon" *ngIf="hidFilter.icon">{{ hidFilter.icon }}</mat-icon>
        <span class="chip-label-html" [innerHTML]="hidFilter.display"></span>
        <div class="close-divider" *ngIf="!hidFilter.unremovable"></div>
        <mat-icon matChipRemove *ngIf="!hidFilter.unremovable">close</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  <button class="filter-bar-menu-item" mat-menu-item (click)="clearFilters()" type="button">Clear All</button>
</mat-menu>
<!-- Base filter menu -->
<op-filter-bar-menu-item-v2 #menu [matMenuTrigger]="matMenuTrigger" [menuItems]="menuItems" [service]="service" [menuClass]="menuClass"></op-filter-bar-menu-item-v2>

