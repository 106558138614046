<div class="top">
  <div class="selection">
    <div class="search-wrap">
      <op-clearable-input placeholderText="Search Tags" (onUserInput)="filterTags($event)"></op-clearable-input>
    </div>
    <div class="selection-text">{{ tabSelection }} of {{ totalTags }} tags enabled</div>
    <div class="selection-btns">
      <button (click)="selectAll()">Select All</button> | <button (click)="selectNone()">Select None</button>
    </div>
  </div>
  <div class="select-button"></div>
</div>
<div class="grid flex-stretch">
  <div class="grid-scroll">
    <admin-tag *ngFor="let tag of tags" [tag]="tag" (clicked)="adminTagClicked($event)"></admin-tag>
  </div>
</div>