import { filter, map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BulkOperationsStateNames, BulkOperationsUrlBuilders } from './bulk-operations.const';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bulk-operations',
  templateUrl: './bulk-operations.component.html',
  styleUrls: ['./bulk-operations.component.scss']
})
export class BulkOperationsComponent implements OnInit, OnDestroy {
  
  tabs = [];
  activeState = BulkOperationsStateNames.manageFolders;

  private destroySubject = new Subject<void>();

  constructor(private router: Router) {
    this.tabs.push(
      { label: 'Folders', state: BulkOperationsUrlBuilders.manageFolders()},
      { label: 'Sub-Folders - Data Layer(s)', state: BulkOperationsUrlBuilders.manageSubfolders()},
      { label: 'Labels', state: BulkOperationsUrlBuilders.manageLabels()}
    );
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map((e: NavigationEnd) => e.urlAfterRedirects),
        takeUntil(this.destroySubject)
      )
      .subscribe(url => this.setCurrentTab(url));

    this.setCurrentTab(this.router.url);
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  setCurrentTab(url: string): void {
    const currentTab = this.tabs.find(tab => tab.state === url) || this.tabs[0];
    this.activeState = currentTab.state;
  }

  goToTab(tab): void {
    this.activeState = tab.state;
    this.router.navigateByUrl(this.activeState);
  }
}
