import { Injectable } from '@angular/core';
import { ERuleConditionClause } from '../rule-setup.enums';
import { IRuleSetupConditionsFormValue, IRuleSetupFormValue, IRuleSetupIfConditionFormValue, IRuleSetupThenConditionFormValue } from "../rule-setup.models";
import { IRule, IRulePageFilter, IRuleTag, IRuleTagVariable } from '@app/components/rules/rules.models';
import { EPageFilterType, ERuleFilterType, ERuleTagVariableSelectorType } from '../tabs/conditions-tab/rule-setup-conditions-tab.enums';

@Injectable()
export class RuleSetupDataSerializationService {

  formDataToSaveRequest(
    { general, conditions }: IRuleSetupFormValue,
    ruleId?: number
  ): IRule {
    return {
      id: ruleId,
      name: general.name,
      recipients: general.recipients,
      labels: general.labels.map(label => ({ id: label.id, name: label.name })),
      checkTimes: conditions.checkTimes,
      matchAllFilters: conditions.matchAllFilters,
      pageFilters: this.getPageFilters(conditions),
      tags: this.getTags(conditions),
      isDefaultRule: general.isDefaultRule || false,
    };
  }

  private getPageFilters(conditions: IRuleSetupConditionsFormValue): IRulePageFilter[] {
    return conditions.filters.If
      .filter(({ type }) =>
          type === EPageFilterType.URL ||
          type === EPageFilterType.FinalUrl ||
          type === EPageFilterType.StatusCode ||
          type === EPageFilterType.FinalStatusCode)
      .map(filter => ({
        id: filter.id,
        ruleId: filter.ruleId,
        value: filter.value,
        type: filter.type as EPageFilterType,
        matchType: filter.matchType
      }));
  }

  private getTags(conditions: IRuleSetupConditionsFormValue): IRuleTag[] {
    const ifFilters = conditions.filters.If
      .filter(({ type }) => type === ERuleFilterType.Tag)
      .map(filter => this.getRuleTag(filter, ERuleConditionClause.If));
    const thenFilters = conditions.filters.Then
      .map(filter => this.getRuleTag(filter, ERuleConditionClause.Then));
    return [...ifFilters, ...thenFilters];
  }

  private getRuleTag(condition: IRuleSetupIfConditionFormValue | IRuleSetupThenConditionFormValue,
                     clause: ERuleConditionClause): IRuleTag {
    return {
      id: condition.id,
      ruleId: condition.ruleId,
      tagId: condition.tagId,
      matchType: condition.account?.matchType,
      clause,
      validationDescription: condition.account?.validationDescription,
      account: condition.account?.value,
      variables: this.getVariables(condition),
      ...(
        (condition as IRuleSetupIfConditionFormValue).statusCode
          ? {
            statusCode: {
              matchType: (condition as IRuleSetupIfConditionFormValue).statusCode.matchType,
              valueType: (condition as IRuleSetupIfConditionFormValue).statusCode.value.valueType,
              ...(
                (condition as IRuleSetupIfConditionFormValue).statusCode.value.value !== null &&
                (condition as IRuleSetupIfConditionFormValue).statusCode.value.value >= 0
                  ? { value: +(condition as IRuleSetupIfConditionFormValue).statusCode.value.value }
                  : {}
              )
            }
          }
          : {}
      )
    };
  }

  private getVariables(filter: IRuleSetupIfConditionFormValue | IRuleSetupThenConditionFormValue): IRuleTagVariable[] {
    return filter.variables.map(variable => ({
      id: variable.id,
      ruleTagId: variable.ruleTagId,
      variable: variable.variableMatchAsRegex
        ? `regex:${variable.variable}`
        : variable.variable,
      matchType: variable.matchType,
      value: variable.value ?? null,
      valueTagId: this.isTagSelectorType(variable.selectorType)
        ? variable.selectorType
        : null,
      selectorType: this.isTagSelectorType(variable.selectorType)
        ? ERuleTagVariableSelectorType.Tag
        : (variable.selectorType ?? null),
      validationDescription: variable.validationDescription
    }));
  }

  private isTagSelectorType(selectorType: ERuleTagVariableSelectorType | number): selectorType is number {
    return typeof selectorType === 'number';
  }

  /**
   * Normally user should be able to GET /rules/{ruleId} and be able to PUT /rules/{ruleId} with the same data.
   * But it is not happening for rules due to inconsistency in the API.
   * This method modifies the data before PUT /rules/{ruleId} to make it pass the validation.
   */
  public static normaliseTagVariablesBeforeSaving(rule: IRule): IRule {
    rule.tags?.forEach(tag => {
      tag.variables?.forEach(variable => {
        // See https://github.com/observepoint/api/blob/28271de8f661d94b329203941f0a5336de752aad/api/src/main/scala/observepoint/api/rule/Validations.scala#L211
        if (variable.selectorType !== ERuleTagVariableSelectorType.Tag) {
          delete variable.valueTagId;
        }
      });
    });
    return rule;
  }
}
