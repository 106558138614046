export class RouteBuilder {

  private static placeholderRegex = /\:(\w+)/g;

  /**
   * Is used for building URL by taking URL paths, concatenating them and replacing placeholders with dynamic params
   * 
   * CAUTION: params and placeholders should be in the same order
   * 
   * @param paths - URL paths, can have placeholders (:placeholder)
   * @param params - params, that should replace placeholders in URL paths
   */
  static buildUrl(paths: string[], ...params: string[]): string {
    const fullPath = paths.join('/');
    const placeholders = RouteBuilder.getPlaceholders(fullPath);

    const placeholderToReplacementMap = placeholders.reduce(
      (map, placeholder, i) => map.set(placeholder, params[i]), 
      new Map<string, string>()
    );

    if (placeholderToReplacementMap.size !== params.length) {
      const errorMessage = this.incorrectParamsMessage(fullPath, placeholders, params);
      throw new SyntaxError(errorMessage);
    }

    const generatedPath = fullPath.replace(
      RouteBuilder.placeholderRegex, 
      placeholder => {
        const placeholderName = placeholder.substring(1);
        return placeholderToReplacementMap.get(placeholderName) || placeholder;
      }
    );

    return '/' + generatedPath;
  }

  private static getPlaceholders(str: string): string[] {
    const result = [];

    let match;
    while (match = RouteBuilder.placeholderRegex.exec(str)) {
      result.push(match[1]);    
    }

    return result;
  }

  private static incorrectParamsMessage(path: string, placeholders: string[], params: string[]): string {
    return `
      Number of params don't equal to number of placeholders.
      Path: ${path}.
      Placeholder(s): ${placeholders}.
      Param(s): ${params}.
    `;
  }

}
