export const ManageCardsSplitterStatusStoragePrefix = 'reports';

export class CardTypes {
  static audit: CardType = 'audit';
  static webJourney: CardType = 'webJourney';
}

export class CardListTypes extends CardTypes {
  static all = 'all';
}

export type CardType = 'audit' | 'webJourney';

export class CardSplitterTypes {	
  static folder: CardSplitterType = 'folder';	
  static domain: CardSplitterType = 'domain';	
}

export type CardSplitterType = 'folder' | 'domain';
