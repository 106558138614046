export class WebsocketRouter {
  static observers = new Map<string, Map<number, (T) => void>>();
  private static nextCallbackId: number = 0;

  /**
   * @return {number} id of registered callback
   */
  public static register<T>(
    topic: string,
    identifier: any,
    callback: (data: T) => void
  ): number {
    const callbackId = this.buildCallbackId();
    this.getCallbacks(topic, identifier).set(callbackId, callback);
    return callbackId;
  }

  /**
   * @return {number} count of remaining callbacks for specified topic and identifier
   */
  public static unregister(
    topic: string,
    identifier: any,
    callbackId: number
  ): number {
    const callbacks = this.getCallbacks(topic, identifier);
    callbacks.delete(callbackId);
    return callbacks.size;
  }

  public static handleMessages(
    topic: string,
    identifier: any,
    data: any
  ): void {
    this.getCallbacks(topic, identifier)
      .forEach(callback => callback(data));
  }

  public static getCallbacks(
    topic: string,
    identifier: any
  ): Map<number, (T) => void> {
    const key: string = this.buildObserverId(topic, identifier);
    return this.observers[key]
      ? this.observers[key]
      : (this.observers[key] = new Map());
  }

  public static buildObserverId(topic: string, identifier: any): string {
    return topic + JSON.stringify(identifier);
  }

  private static buildCallbackId(): number {
    return this.nextCallbackId++;
  }
}
