import { IComparisonReportStateParams } from '@app/components/ror-comparison-report/ror-comparison-report.models';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { RorComparisonReportService } from '../ror-comparison-report.service';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { allComparisonsPlaceholder, RorReportPaths } from '../ror-comparison-report.contants';
import { RorComparisonVariableReport } from '@app/components/ror-comparison-report/ror-comparison-variable-report';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ComparisonsService } from '@app/components/comparisons/comparisons.service';
import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
import { map, takeUntil } from 'rxjs/operators';
import { AccountsService } from '@app/components/account/account.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-comparison-variable-presence',
  templateUrl: './ror-comparison-variable-presence.component.html',
  styleUrls: ['./ror-comparison-variable-presence.component.scss']
})
export class RorComparisonVariablePresenceComponent extends RorComparisonVariableReport implements OnInit, OnDestroy {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;

  private destroySubject = new Subject<void>();

  constructor(snackBar: MatSnackBar,
              comparisonReportService: RorComparisonReportService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private accountsService: AccountsService,
              private uiTagService: UiTagService,
              private comparisonsService: ComparisonsService) {
    super(snackBar, comparisonReportService);
  }

  ngOnInit() {
    combineLatest(
      [this.activatedRoute.params, this.activatedRoute.queryParams]
    ).pipe(
      map(([params, queryParams]) => ({ ...params, ...queryParams })),
      takeUntil(this.destroySubject)
    ).subscribe((stateParams: IComparisonReportStateParams) => {
      this.auditId = parseInt(stateParams.auditId);
      this.runId = parseInt(stateParams.runId);
      if (stateParams.comparisonSnapshotId) {
        this.comparisonSnapshotId = parseInt(stateParams.comparisonSnapshotId);
      } else {
        this.comparisonSnapshotId = allComparisonsPlaceholder.comparisonSnapshotId;
        this.isAllComparisonsSelected = true;
      }

      this.fetchData();
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  private fetchData() {
    forkJoin([
      this.comparisonReportService.getVariablePresence(this.auditId, this.runId, this.comparisonSnapshotId),
      this.uiTagService.getTags(),
      this.comparisonsService.getAccountTags(),
      this.accountsService.getUser()
    ]).subscribe(([tagsVariables, allTags, comparisontags, user]) => {
      this.initialiseCommonData(tagsVariables, allTags, comparisontags, user);
    });
  }

  openPages(tagId: number, variableName: string): void {
    const relativeUrl = '.' + RouteBuilder.buildUrl([RorReportPaths.variablePresencePages], String(tagId), variableName);
    this.router.navigate(
      [relativeUrl],
      { queryParamsHandling: 'merge', relativeTo: this.activatedRoute }
    );
  }

}
