<div *ngIf="!nav.featureKey || (nav.featureKey && enabledFeatures.indexOf(nav.featureKey) >= 0)" [attr.op-selector]="nav.opSelector">

  <a (click)="navigate()"
     [class.disabled]="nav.enabledKey && !enabledFeatures.includes(nav.enabledKey)"
     [class.selected]="nav.active">
    <op-beta-label [showLabel]="nav.betaLabel">
      <span class="flex-row">
        {{ nav.title }}{{ nav.icon ? '&nbsp;' : '' }}
        <i class="icon {{ nav.icon }}" *ngIf="nav.icon"></i>
        <span class="new-link" *ngIf="nav.new">NEW</span>
      </span>
    </op-beta-label>
  </a>

  <ul>
    <li *ngFor="let subNav of nav.subStates" >
      <modal-sidebar-link
        *ngIf="!subNav.featureKey || (subNav.featureKey && enabledFeatures.indexOf(subNav.featureKey) > -1)"
        [nav]="subNav"
        [enabledFeatures]="enabledFeatures">
      </modal-sidebar-link>
    </li>
  </ul>
</div>
