import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Component, EventEmitter, HostListener, Input, Output, SimpleChanges, ViewChild, OnInit, AfterViewInit, OnChanges } from '@angular/core';
import { CreateEditDomainComponent } from '@app/components/bulk-operations/manage-domains/create-edit-domain/create-edit-domain.component';
import { EManageDomainsMode } from '@app/components/bulk-operations/manage-domains/create-edit-domain/create-edit-domain.constants';
import { CreateEditFolderComponent } from '@app/components/bulk-operations/manage-folders/create-edit-folder/create-edit-folder.component';
import { ICreateDomainRequest, IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { ILabel } from '@app/components/shared/services/label.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { Events, Messages } from '@app/moonbeamConstants';
import { AddLabelModalComponent } from '../../shared/add-label-modal/add-label-modal.component';
import { MoveToDomainModalComponent } from '../../shared/move-to-domain-modal/move-to-domain-modal.component';
import { EReportCardViewGroupByType } from '../manage-cards.component';
import { CardTypes } from '../report-card-list/report-card-list.constants';
import { IBulkAddLabels, IFolderMap } from './bulk-action-bar.models';
import { ManageCardsService } from '@app/components/manage/cards/manage-cards.service';
import { BulkActionConfirmModalComponent } from '../bulk-action-confirm-modal/bulk-action-confirm-modal.component';
import { BulkActionProgressComponent } from '../../../shared/components/bulk-action-progress/bulk-action-progress.component';
import { ReportCardService } from '@app/components/manage/cards/report-card/report-card.service';
import { ManageAuditScanLimitsComponent } from '@app/components/bulk-operations/manage-audit-scan-limits/manage-audit-scan-limits.component';
import { ManageDataLayersComponent } from '@app/components/bulk-operations/manage-folders/manage-data-layers/manage-data-layers.component';
import { ManageFileSubstitutionsComponent } from '@app/components/bulk-operations/manage-folders/manage-file-substitutions/manage-file-substitutions.component';
import { BulkActionProgressService } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.service';
import { BulkOperationsDeleteMessaging } from '@app/components/bulk-operations/bulk-operations.const';
import { BulkRunFrequencyComponent } from '@app/components/manage/cards/bulk-run-frequency/bulk-run-frequency.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { OpDeleteItemWarningComponent } from '@app/components/shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { IUser } from '@app/moonbeamModels';
import { environment } from '@app/environments/environment';
import { StorageService } from '@app/components/shared/services/storage.service';
import { DataSourcesUtils } from '@app/components/utilities/dataSourcesUtils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bulk-action-bar',
  templateUrl: './bulk-action-bar.component.html',
  styleUrls: ['./bulk-action-bar.component.scss']
})
export class BulkActionBarComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() totalCards: number;
  @Input() filteredCards: number;
  @Input() allCardsExpanded: boolean;
  @Input() selectedCards: any[] = [];
  @Input() selectedCardsCount: number = 0;
  @Input() groupBy: EReportCardViewGroupByType = EReportCardViewGroupByType.FoldersAndDomains;
  @Input() domains: IDomain[] = [];
  @Input() isReadOnly: boolean;
  @Input() runRemoteMethods: EventEmitter<string> = new EventEmitter<string>();
  @Input() user: IUser;

  @Output() toggleCardGroups: EventEmitter<void> = new EventEmitter();
  @Output() selectAllCards: EventEmitter<void> = new EventEmitter();
  @Output() deselectAllCards: EventEmitter<void> = new EventEmitter();
  @Output() assignRules: EventEmitter<void> = new EventEmitter();
  @Output() addBulkLabels: EventEmitter<IBulkAddLabels> = new EventEmitter();
  @Output() runNow: EventEmitter<void> = new EventEmitter();
  @Output() bulkDelete: EventEmitter<void> = new EventEmitter();
  @Output() updateDomains: EventEmitter<void> = new EventEmitter();

  @ViewChild('selectAllCheckbox') selectAllCheckbox: MatCheckbox;

  folders: IFolder[] = [];
  subFolderToFolderMap: IFolderMap = {};
  showBtnTooltips: boolean = false;
  auditIndex: number = 0;
  journeyIndex: number = 1;
  sourceNames: string[];
  processCompleted = false;
  foldersMap: { [id: number]: IFolder } = {};
  firstMessage: string = 'Selected data sources';

  readonly breakpoint: number = 1350;
  readonly duration: number = 4000;

  readonly UNLIMITED: number = Number.MAX_SAFE_INTEGER;
  readonly UNLIMITED_TEXT: string = 'unlimited';
  remainingFixesCount = 0;
  remainingFixesText: string = '0';

  recurrenceEnabled: boolean = false;

  constructor(
    public mCardSvc: ManageCardsService,
    private bulkActionProgressService: BulkActionProgressService,
    private foldersService: IFoldersApiService,
    private domainsService: IDomainsService,
    private eventManager: IEventManager,
    private modalService: OpModalService,
    private cardSvc: ReportCardService,
    private snackBar: MatSnackBar,
    private storageService: StorageService
  ) {
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
  }

  ngOnInit(): void {
    this.foldersService.getFolders().then((folders) => {
      this.folders = folders;
      this.mapDomainsToFolders(this.domains, folders);
      this.mCardSvc.getJourneyFixCounts().finally(() => {
        this.remainingFixesCount = this.mCardSvc.remainingJourneyFixes === -1 ? this.UNLIMITED : this.mCardSvc.remainingJourneyFixes;
        this.remainingFixesText = this.remainingFixesCount === this.UNLIMITED ? this.UNLIMITED_TEXT : this.remainingFixesCount.toString();
      });
    });
  }
  ngAfterViewInit(): void {
    this.showBtnTooltips = (window.innerWidth <= this.breakpoint);

    this.runRemoteMethods.subscribe((message: string) => {
      if (message === 'moveToFolderConfirmation') {
        this.moveToFolderConfirmation();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    // Whenever domains change, we need to rebuild the menu with updated values to reflect
    // any changes that were made elsewhere (edit, create, delete).
    if (changes.domains && changes.domains.currentValue !== changes.domains.previousValue && this.folders.length > 0) {
      this.mapDomainsToFolders(this.domains, this.folders);
    }
  }

  mapDomainsToFolders(domains: IDomain[], folders: IFolder[]): void {
    domains.forEach((domain: IDomain) => {
      this.subFolderToFolderMap[domain.id] = {
        folderName: folders.find((folder: IFolder) => folder.id === domain.folderId)?.name,
        subFolderName: domain.name
      };
    });
  }

  deselectItem(item: any): void {
    this.eventManager.publish(Events.cardDeselected, item);
  }
  selectItem(item: any): void {
    this.eventManager.publish(Events.cardSelected, item);
  }

  deselectAllItems(): void {
    this.eventManager.publish(Events.deselectAllCards);
    this.mCardSvc.selectAllIndeterminateState = false;
    this.mCardSvc.selectAllState = false;
  }
  selectAllItems(): void {
    this.eventManager.publish(Events.selectAllCards);
    this.mCardSvc.selectAllIndeterminateState = false;
    this.mCardSvc.selectAllState = true;
    this.selectAllCheckbox.checked = true;
  }
  toggleSelectAllItems(): void {
    if (this.isInSelectAllState()) {
      this.deselectAllItems();
    } else {
      this.selectAllItems();
    }
  }
  isInSelectAllState(): boolean {
    if ((this.mCardSvc.selectAllIndeterminateState === false && this.mCardSvc.selectAllState === true)) {
      return true;
    } else {
      return false;
    }
  }
  closeBulkActionBar() {
    this.deselectAllItems();
  }

  /////////////////////////////////////////////////////
  // Bulk methods
  /////////////////////////////////////////////////////
  runNowConfirmation(): void {
    if (this.cardsNotSelected()) return;
    let cardsWithPagesExceedingUserLimit = [];
    let cardsToRun = this.selectedCards;

    // Special handling in the case where audits selected are not permitted to
    // be run due to the page limit being higher than the user allowed limit.
    if (this.user?.maxPagesPerAudit > 0) {
      cardsWithPagesExceedingUserLimit = this.getAuditsWithPagesExceedingUserLimit();

      // Only run audits that are allowed. Display updated message informing that
      // they don't have permission to run anything that has too many pages.
      cardsToRun = this.selectedCards.filter((card, index) => {
        return !cardsWithPagesExceedingUserLimit.includes(index);
      });
    }

    let exceedingLimitNames = cardsWithPagesExceedingUserLimit.reduce((acc, selectedIndex) => {

      acc += `<li>${this.selectedCards[selectedIndex].name}</li>`;
      return acc;
    }, '');
    let exceedingLimitNote = cardsWithPagesExceedingUserLimit.length > 0
      ? `<p class="exceeding-limit-note">These audits are configured to scan more pages than you have permission to initiate and will not be run:<ul class="exceeding-limit-names ${cardsWithPagesExceedingUserLimit.length > 5 ? 'scrollable-list' : ''}">${exceedingLimitNames}</ul></p>`
      : '';

    //Get list of selected item descriptions
    let partialListNote: string = this.getListOfSelectedDataSources(true, cardsToRun);
    let formattedMessage: string =
      `
        ${exceedingLimitNote}
        <p>All ${cardsWithPagesExceedingUserLimit.length > 0 ? 'other' : ''} data sources, that aren't currently running, will start running now.
        <br><b>Are you sure?</b></p>
      ${partialListNote} ${this.listOfCardsToProcess()}`;

    const confirmConfig = {
      messages: [
        formattedMessage,
      ],
      showSecondBtn: true,
      showProgressBar: false,
      rightFooterButtons: [
        {
          label: 'Yes, run now',
          opSelector: 'bulk-confirm-run-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-run-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.showProgressDlg('are now running.');
          this.mCardSvc.runAllNow(this.selectedCards);
        } else {
          this.showSnack('No items were started.');
        }
      });
  }

  pauseSelectedRunsConfirmation(): void {
    let filteredSelectedCard = [];

    if (this.recurrenceEnabled) {
      filteredSelectedCard = this.selectedCards.filter(card =>
        (DataSourcesUtils.isDataSourceScheduled(card) && !card?.schedule?.isPaused));
    } else {
      // Filter to just the paused years -- we don't want to over-write the customer's schedule if we can avoid it
      filteredSelectedCard = this.selectedCards.filter(card =>
        (this.cardSvc.isScheduled(card) && !this.cardSvc.isPausedCard(card)));
    }

    if (filteredSelectedCard.length < 1) {
      this.showSnack('No selected items are scheduled.');
      return;
    }
    this.selectedCards = filteredSelectedCard;

    //Get list of selected item descriptions
    let partialListNote: string = (this.getListOfSelectedDataSources(true));
    let formattedMessage: string =
      `All future scheduled runs for ${this.selectedCards.length} data sources will be
      permanently paused (until you resume them).
      ${partialListNote} ${this.listOfCardsToProcess()}`;

    const confirmConfig = {
      messages: [
        formattedMessage,
      ],
      showSecondBtn: true,
      showProgressBar: false,
      rightFooterButtons: [
        {
          label: 'Yes, pause them',
          opSelector: 'bulk-confirm-pause-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-pause-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.showProgressDlg('have been paused.');
          this.mCardSvc.pauseSelectedRuns(this.selectedCards);
        } else {
          this.showSnack('No items were paused.');
        }
      });
  }
  resumeSelectedRunsConfirmation(): void {
    let filteredSelectedCard = [];

    if (this.recurrenceEnabled) {
      filteredSelectedCard = this.selectedCards.filter(card =>
        (DataSourcesUtils.isDataSourceScheduled(card) && card?.schedule?.isPaused));
    } else {
      //Filter to just the paused years -- we don't want to over-write the customer's schedule if we can avoid it
      filteredSelectedCard = this.selectedCards.filter(card =>
        (this.cardSvc.isScheduled(card) && this.cardSvc.isPausedCard(card)));
    }
    if (filteredSelectedCard.length < 1) {
      this.showSnack('No selected items are currently paused (or are scheduled).');
      return;
    }

    //Get list of selected item descriptions
    this.selectedCards = filteredSelectedCard;
    let partialListNote: string = (this.getListOfSelectedDataSources(true));
    let formattedMessage: string =
      `<b>Resume previously scheduled runs for the ${this.selectedCards.length} data sources?</b>
      ${partialListNote} ${this.listOfCardsToProcess()}`;

    const confirmConfig = {
      messages: [
        formattedMessage,
      ],
      showSecondBtn: true,
      showProgressBar: false,
      rightFooterButtons: [
        {
          label: 'Yes, resume runs',
          opSelector: 'bulk-confirm-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.showProgressDlg('have been resumed.');
          this.mCardSvc.resumeSelectedRuns(this.selectedCards);
        } else {
          this.showSnack('Resuming runs was cancelled.');
        }
      });
  }
  discardSelectedRunsConfirmation(): void {

    //Check to ensure we have at least one selected card that is running
    const filteredSelectedCard = this.selectedCards.filter(card =>
    ((card.type === CardTypes.audit && card.webAuditRunning === true) ||
      (card.type === CardTypes.webJourney && card.webJourneyRunning === true)
    ));
    if (filteredSelectedCard.length < 1) {
      this.showSnack('No selected items are currently running.');
      return;
    }

    this.selectedCards = filteredSelectedCard;
    let partialListNote: string = (this.getListOfSelectedDataSources(false));

    let formattedMessage: string =
      `<b>Are you sure you want to stop & discard these data sources that are currently running?</b> Doing so will discard all run data. The data sources will still continue with future scheduled runs. All data in runs will be discarded.
      ${partialListNote} ${this.listOfCardsToProcess()}`;

    const confirmConfig = {
      messages: [
        formattedMessage,
      ],
      showSecondBtn: true,
      showProgressBar: false,
      rightFooterButtons: [
        {
          label: 'Yes, stop & discard',
          opSelector: 'bulk-confirm-discard-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'No, don\'t stop',
          opSelector: 'bulk-confirm-discard-no',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.showProgressDlg('are stopped and discarded.');
          this.mCardSvc.discardSelectedRuns(this.selectedCards);
        } else {
          this.showSnack('Discarding runs was cancelled.');
        }
      });
  }

  reprocessConsentCategoriesConfirmation(): void {
    //Filter to just audits
    const filteredSelectedCard = this.selectedCards.filter(card =>
      (card.type === CardTypes.audit));
    this.selectedCards = filteredSelectedCard;
    if (filteredSelectedCard.length < 1) {
      this.showSnack('No selected items are audits.');
      return;
    }

    //Get list of selected item descriptions
    this.generateFullListOfSelectedDataSources(true);
    let formattedMessage: string =
      `<p>All selected audits will reprocess their consent categories.
        <br><b>Are you sure?</b></p>
      ${this.listOfCardsToProcess()}`;

    const confirmConfig = {
      messages: [
        formattedMessage,
      ],
      showSecondBtn: true,
      showProgressBar: false,
      rightFooterButtons: [
        {
          label: 'Yes, reprocess now',
          opSelector: 'bulk-confirm-run-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-run-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.showProgressDlg('are now being reprocessed.', false);
          this.mCardSvc.reprocessConsentCategories(this.selectedCards);
        } else {
          this.showSnack('No items were reprocessed.');
        }
      });
  }

  reprocessRulesConfirmation(): void {
    //Filter to just audits
    const filteredSelectedCard = this.selectedCards.filter(card =>
      (card.type === CardTypes.audit));
    this.selectedCards = filteredSelectedCard;
    if (filteredSelectedCard.length < 1) {
      this.showSnack('No selected items are audits.');
      return;
    }

    //Get list of selected item descriptions
    let partialListNote: string = (this.getListOfSelectedDataSources(true));
    let formattedMessage: string =
      `<p>All selected audits will reprocess their rules.
        <br><b>Are you sure?</b></p>
      ${partialListNote} ${this.listOfCardsToProcess()}`;

    const confirmConfig = {
      messages: [
        formattedMessage,
      ],
      showSecondBtn: true,
      showProgressBar: false,
      rightFooterButtons: [
        {
          label: 'Yes, reprocess now',
          opSelector: 'bulk-confirm-run-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-run-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.showProgressDlg('are now being reprocessed.', false);
          this.mCardSvc.reprocessRules(this.selectedCards);
        } else {
          this.showSnack('No items were reprocessed.');
        }
      });
  }

  async moveToFolderConfirmation(excludeFolderIDs?: number[], excludeSubfolderIDs?: number[]): Promise<number> {

    if (this.cardsNotSelected()) return;

    // Open the Move dialog box and get destination folder selections
    let destFolders: any[] = await this.openMoveModal(excludeFolderIDs, excludeSubfolderIDs);

    // If no folder was chosen or created then leave function.  NOTE: can't exit function within a promise.
    if ((destFolders === undefined || destFolders === null) || (!destFolders)) {
      return BulkOperationsDeleteMessaging.Cancel;
    }

    // See if a new destination folder needs to be created
    let folderFailure = false;
    let destUpdateFolders: any = {};
    await this.createNewFolders(destFolders).then((newFolders) => {
      destUpdateFolders = newFolders;
    })
      .catch(e => {
        // Can't leave function from a Promise to mark failure
        folderFailure = true;
        console.log(e);
      });
    if (folderFailure) {
      return BulkOperationsDeleteMessaging.MoveFailure;
    }

    ///////////////////////////////////
    // Show the Progress dialog box
    this.showProgressDlg('are moved.');

    // Move items and return result
    const res: boolean = await this.mCardSvc.moveToFolders(this.selectedCards, destUpdateFolders);

    return res ? BulkOperationsDeleteMessaging.Success : BulkOperationsDeleteMessaging.MoveFailure;
  }

  async createNewFolders(values: any): Promise<any> {
    let { folder, domain, dataLayer } = values;

    if (typeof folder === 'string') {
      folder = await this.createNewFolder(folder);
    }

    if (typeof domain === 'string') {
      domain = await this.createNewDomain(domain, folder.id, dataLayer);
    }

    return { folder, domain, dataLayer };
  }

  async openMoveModal(excludeFolderIDs?: number[], excludeSubfolderIDs?: number[]): Promise<any> {

    return this.modalService
      .openModal(MoveToDomainModalComponent, {
        data: {
          selectedCardsCount: this.selectedCards.length,
          excludeFolderIDs: excludeFolderIDs,
          excludeSubfolderIDs: excludeSubfolderIDs,
        },
        autoFocus: false
      }).afterClosed().toPromise();
  }

  addDataLayerConfirmation(): void {

    if (this.cardsNotSelected()) return;

    // Get a list of sub-folders
    const domainIDs: { id: number }[] = this.getDomains();

    this.modalService
      .openModal(ManageDataLayersComponent, {})
      .afterClosed()
      .subscribe((values: any) => {
        if (values) {
          this.firstMessage = 'Sub-Folders';
          this.showProgressDlg('were updated.', true, '', domainIDs.length);
          this.mCardSvc.updateDomainDataLayer(domainIDs, values);
          this.dataLayerSubscription();
        } else {
          this.showSnack('No data layers were saved.');
        }
      });
  }

  dataLayerSubscription() {
    this.bulkActionProgressService.subscribeProgressbar()
      .subscribe(nextValue => {
        if (nextValue === this.bulkActionProgressService.getMaxCount()) {
          // the domain's data layer was not updating on the UI (elipsis: edit/move sub-folder) after the bulk action was completed so thus the setTimeout
          setTimeout(() => this.updateUI(), 1250);
        }
      });
  }

  // Get sub-folers (domains)
  getDomains(): { id: number }[] {
    return this.selectedCards
      .map(card => ({ id: card.domainId }))
      .filter((value, index, self) =>
        self.findIndex(item => item.id === value.id) === index
      );
  }

  updateUI(): void {
    this.updateDomains.emit();
    this.eventManager.publish(Messages.refreshContent);
  }

  addFileSubstitutionsConfirmation(): void {

    if (this.cardsNotSelected()) return;

    this.modalService
      .openModal(ManageFileSubstitutionsComponent, {
        data: {
          selectedCards: this.selectedCards
        },
      })
      .afterClosed()
      .subscribe((values: any) => {
        if (values) {
          this.showProgressDlg('were updated.');
          this.mCardSvc.updateFileSubstitutions(this.selectedCards, values);
        } else {
          this.showSnack('No file substitutions were saved.');
        }
      });
  }

  async changeRunFrequencyConfirmation(): Promise<void> {

    if (this.cardsNotSelected()) return;

    this.modalService.openModal(BulkRunFrequencyComponent, {
      data: {
        selectedCards: this.selectedCards
      },
    })
      .afterClosed()
      .subscribe((values: any) => {
        if (values) {
          if (values.hasOwnProperty('recurrenceEnabled')) {
            this.showProgressDlg('were updated.');
            this.mCardSvc.updateRunSchedules(this.selectedCards, values.schedule);
          } else {
            this.showProgressDlg('were updated.');
            this.mCardSvc.updateRunFrequencies(this.selectedCards, values[0], values[1], values[2]);
          }
        } else {
          this.showSnack('No run frequencies were saved.');
        }
      });
  }

  removeLabelConfirmation(): void {

    if (this.cardsNotSelected()) return;

    //Get list of selected item descriptions
    let partialListNote: string = (this.getListOfSelectedDataSources(true));
    let formattedMessage: string =
      `<p>This action will remove (disassociate) all labels from the selected
        card${(this.sourceNames[0] && this.sourceNames[1]) ? 's' : ''}.</p>
      <p><b>Are you sure?</b></p>
      ${partialListNote} ${this.listOfCardsToProcess()}`;

    const confirmConfig = {
      messages: [
        formattedMessage,
      ],
      showSecondBtn: true,
      showProgressBar: false,
      rightFooterButtons: [
        {
          label: 'Yes, remove labels now',
          opSelector: 'bulk-confirm-run-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-run-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.showProgressDlg('were updated.');
          this.mCardSvc.removeLabels(this.selectedCards);
        } else {
          this.showSnack('No labels were removed.');
        }
      });
  }

  async requestJourneyFixConfirmation(): Promise<void> {
    if (this.cardsNotSelected()) return;

    const isProd = environment.domain === 'observepoint.com';
    if (!isProd) {
      this.showSnack('This feature is currently only available in production.');
      return;
    }

    if (this.remainingFixesCount === 0) {
      this.showSnack('No journey fixes available.');
      return;
    }

    //Filter to just journeys
    let filteredSelectedCards = this.selectedCards.filter(card =>
      (card.type === CardTypes.webJourney));
    if (filteredSelectedCards.length < 1) {
      this.showSnack('No selected items are Web Journeys.');
      return;
    }

    // Filter cards down to only action failures
    filteredSelectedCards = this.selectedCards.filter(card => (card.status === "ActionFailure"));
    if (filteredSelectedCards.length < 1) {
      this.showSnack('Journey Fix requests can only be applied to Journeys with Action Failures.');
      return;
    }

    // If the user has requested more fixes than are available, show a warning message
    if (filteredSelectedCards.length > this.remainingFixesCount) {
      this.showSnack(`You selected ${this.selectedCards.length} Web Journeys with Action Failures,
        but you only have ${this.remainingFixesCount} remaining Journey Support requests. Reduce your selection before submitting Journey Fix requests`);
      return;
    }
    this.selectedCards = filteredSelectedCards;

    // Create messaging to customer
    let listOfCards: string = `<p><div class="scrollable-fix-list">
        <ul>
          ${this.selectedCards.map(card => `<li>${card.name}</li>`).join('')}
        </ul>
      </div></p>`;
    let formattedMessage: string =
      `<b>Do you want to submit a fix request for the ${this.selectedCards.length}
      selected Web Journey${this.selectedCards.length < 2 ? '' : 's'}
      that ${this.selectedCards.length < 2 ? 'has' : 'have'} action failures?`;
    let formattedMessage2: string = `You will have ${this.remainingFixesCount < this.UNLIMITED ? this.remainingFixesCount - this.selectedCards.length : this.UNLIMITED_TEXT}
        journey support request${this.remainingFixesCount === 1 ? '' : 's'} remaining.
        ${listOfCards}`;
    let finalMessage: string =
      `<div class="final-message-title">You have successfully requested fixes for the following
        ${this.selectedCards.length} Journey${this.selectedCards.length < 2 ? '' : 's'}.</div>
        ${listOfCards}`;

    const confirmConfig = {
      messages: [
        formattedMessage,
        formattedMessage2,
      ],
      showSecondBtn: true,
      showProgressBar: false,
      rightFooterButtons: [
        {
          label: `Yes, submit ${this.selectedCards.length} request${this.selectedCards.length < 2 ? '' : 's'}`,
          opSelector: 'bulk-confirm-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {

          this.showProgressDlg('have been requested.', true, finalMessage);

          this.mCardSvc.sendFixJourneyRequest(this.selectedCards).then((count) => {
            this.remainingFixesCount = count === -1 ? this.UNLIMITED : this.mCardSvc.remainingJourneyFixes;
            this.remainingFixesText = this.remainingFixesCount === this.UNLIMITED ? this.UNLIMITED_TEXT : this.remainingFixesCount.toString();
          });

        } else {
          this.showSnack('Requesting journey fixes was cancelled.');
        }
      });
  }
  monitorSelectedRunsConfirmation(): void {

    //Filter to just journeys
    const filteredSelectedCard = this.selectedCards.filter(card =>
      (card.type === CardTypes.webJourney));
    if (filteredSelectedCard.length < 1) {
      this.showSnack('No selected items are Web Journeys.');
      return;
    }
    this.selectedCards = filteredSelectedCard;

    // Ensure we have enough slots to monitor the selected journeys
    this.mCardSvc.getMonitoredJourneysCounts().then((count) => {

      if (count < this.selectedCards.length) {
        const plural = this.selectedCards.length > 1 ? 's' : '';
        this.showSnack(`You are asking to monitor ${this.selectedCards.length} Web Journey${plural} but only have ${count} remaining slot${plural}. Please reduce the number of selected Journeys.`);
        return;
      }

      //Get list of selected item descriptions
      let partialListNote: string = (this.getListOfSelectedDataSources(true));
      let formattedMessage: string =
        `<p>All selected Web Journeys will now be monitored.
          <br><b>Are you sure?</b></p>
        ${partialListNote} ${this.listOfCardsToProcess()}`;

      const confirmConfig = {
        messages: [
          formattedMessage,
        ],
        showSecondBtn: true,
        showProgressBar: false,
        rightFooterButtons: [
          {
            label: 'Yes, monitor now',
            opSelector: 'bulk-confirm-monitor-yes',
            hidden: false,
            primary: false,
          },
          {
            label: 'Cancel',
            opSelector: 'bulk-confirm-monitor-cancel',
            hidden: false,
            primary: false,
          },
        ],
      };

      // Ask user to confirm the bulk action
      this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
        data: confirmConfig,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      }).afterDismissed()
        .subscribe((observer?: MatSnackBarDismiss) => {
          if (observer.dismissedByAction) {
            this.showProgressDlg('are now being monitored.', false);
            this.mCardSvc.monitorJourneys(this.selectedCards, true);
          } else {
            this.showSnack('No items were monitored.');
          }
        });
    });
  }
  unmonitorSelectedRunsConfirmation(): void {

    //Filter to just journeys
    const filteredSelectedCard = this.selectedCards.filter(card =>
      (card.type === CardTypes.webJourney));
    this.selectedCards = filteredSelectedCard;
    if (filteredSelectedCard.length < 1) {
      this.showSnack('No selected items are Web Journeys.');
      return;
    }

    //Get list of selected item descriptions
    let partialListNote: string = (this.getListOfSelectedDataSources(true));
    let formattedMessage: string =
      `<p>All selected Web Journeys will stop being monitored.
        <br><b>Are you sure?</b></p>
      ${partialListNote} ${this.listOfCardsToProcess()}`;

    const confirmConfig = {
      messages: [
        formattedMessage,
      ],
      showSecondBtn: true,
      showProgressBar: false,
      rightFooterButtons: [
        {
          label: 'Yes, stop monitoring',
          opSelector: 'bulk-confirm-unmonitor-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-unmonitor-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionConfirmModalComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.showProgressDlg('are now unmonitored.', false);
          this.mCardSvc.monitorJourneys(this.selectedCards, false);
        } else {
          this.showSnack('No items were unmonitored.');
        }
      });
  }

  setAuditScanLimit(): void {

    if (this.cardsNotSelected()) return;

    this.modalService
      .openModal(ManageAuditScanLimitsComponent, {
        data: {
          selectedCards: this.selectedCards,
          selectedCardsCount: this.selectedCardsCount
        }
      })
      .afterClosed()
      .subscribe((values: any) => {
      });
  }

  addLabel(): void {

    if (this.cardsNotSelected()) return;

    this.modalService
      .openModal(AddLabelModalComponent, {
        data: {
          selectedCardsCount: this.selectedCards.length
        }
      })
      .afterClosed()
      .subscribe((labels: ILabel[]) => {
        if (labels?.length) {
          this.showProgressDlg('were added.');
          this.mCardSvc.addLabels(this.selectedCards, labels);
        } else {
          this.showSnack('No labels were added.');
        }
      });
  }

  assignRulesConfirmation() {
    if (this.cardsNotSelected()) return;

    this.assignRules.emit();
  }

  // Delete multiple folders
  //  NOTE: Folders contain subfolders (domains) but not audits or journeys (directly)
  //  If user chooses to move items first (before delete), then nothing happens below after the dialog is closed
  deleteFoldersConfirmation(): void {

    const folders = this.mCardSvc.filteredFolders.filter(folder => (folder.selected === true && folder.indeterminate === false));
    const subFolders = this.mCardSvc.filteredSubfolders.filter(sub => (sub.selected === true && sub.indeterminate === false));

    if (folders.length === 0) {
      if (subFolders.length === 0) {
        this.showSnack('No folders or subfolders were selected. No items deleted.');
      } else {
        // We'll be nice and send them to the subfolder delete confirmation
        this.deleteSubfoldersConfirmation();
      }
      return;
    }

    const message = `Are you sure you want to permanently delete the ${folders.length + subFolders.length > 1 ? folders.length + subFolders.length : ''} folder${folders.length + subFolders.length > 1 ? 's' : ''} as well as all items in the folder${folders.length + subFolders.length > 1 ? 's' : ''}? `;

    // get audit and journey counts for all selected folders to be deleted
    let auditCounts: number = 0;
    let journeyCounts: number = 0;
    this.mCardSvc.getAuditJourneyCountsBySubfolder(folders, 'folders').then((subFolderInfo: number[]) => {
      auditCounts += subFolderInfo[0];
      journeyCounts += subFolderInfo[1];

      this.modalService.openModal(OpDeleteItemWarningComponent, {
        data: {
          itemType: 'Folders',
          customMessage: message,
          defaultMessage: false,
          Folders: folders,
          firstDomainID: subFolderInfo[2],
          haveCounts: true,
          FoldersCount: folders.length,
          SubfoldersCount: subFolders.length,
          AuditsCount: auditCounts,
          JourneysCount: journeyCounts,
          sourcePageTab: 'manage-folders',
        }
      })
        .afterClosed()
        .subscribe((confirmDelete: string) => {

          // Handle moving items
          if (confirmDelete === 'move') {
            this.handleFolderMoveAndDeleteResponse(folders).then(confirmDelete => {

              if (BulkOperationsDeleteMessaging.Success !== confirmDelete) {
                let respMessage = '';
                if (BulkOperationsDeleteMessaging.Cancel === confirmDelete) {
                  // Reload the first dialog box after move-cancel as if we hit the back-button.
                  this.deleteFoldersConfirmation();
                  return;
                } else if (BulkOperationsDeleteMessaging.MoveFailure === confirmDelete) {
                  respMessage = 'The move request did not succeed for some items';
                } else if (BulkOperationsDeleteMessaging.DeleteFailure === confirmDelete) {
                  respMessage = 'The delete request did not succeed for some items';
                } else if (BulkOperationsDeleteMessaging.MoveToSameFolder === confirmDelete) {
                  respMessage = 'The move request was cancelled because both source and destination folders were the same';
                } else {
                  respMessage = 'Unknown response from processing. Please verify your request was completed.';
                }
                this.showSnack(respMessage);
              } else {

                // On successful move, re-init the table - show new folders that may have been created during move
                this.mCardSvc.refreshContent();
              }
            });

            // Handle deleting without moving
          } else if (confirmDelete === 'delete') {

            // Show progress-bar and start deleting items
            this.showProgressDlg('were deleted.', false, '', folders.length);
            this.mCardSvc.deleteMultiFolders(folders);

          } else {

            // User cancelled delete operation
            this.showSnack('Delete was cancelled. No items removed.');
          }
        });
    });
  }
  deleteSubfoldersConfirmation(): void {

    const subFolders = this.mCardSvc.filteredSubfolders.filter(sub => (sub.selected === true && sub.indeterminate === false));
    if (subFolders.length === 0) {
      this.showSnack('No subfolders were selected. No items deleted.');
      return;
    }

    const message = `Are you sure you want to permanently delete the ${subFolders.length > 1 ? subFolders.length : ''} sub-folder${subFolders.length > 1 ? 's' : ''} as well as all items in the sub-folder${subFolders.length > 1 ? 's' : ''}? `;

    // get audit and journey counts for all selected folders to be deleted
    let auditCounts: number = 0;
    let journeyCounts: number = 0;
    this.mCardSvc.getAuditJourneyCountsBySubfolder(subFolders, 'subfolders').then((subFolderInfo: number[]) => {
      auditCounts += subFolderInfo[0];
      journeyCounts += subFolderInfo[1];

      this.modalService.openModal(OpDeleteItemWarningComponent, {
        data: {
          itemType: 'Sub-folders',
          customMessage: message,
          defaultMessage: false,
          Folders: subFolders,
          firstDomainID: subFolderInfo[2],
          haveCounts: true,
          FoldersCount: 0,
          SubfoldersCount: subFolders.length,
          AuditsCount: auditCounts,
          JourneysCount: journeyCounts,
          sourcePageTab: 'manage-domains',
        }
      })
        .afterClosed()
        .subscribe((confirmDelete: string) => {

          // Handle moving items
          if (confirmDelete === 'move') {

            ////////////////////////////////
            // Move items then delete them
            this.handleSubfolderMoveAndDeleteResponse(subFolders).then(confirmDelete => {

              if (BulkOperationsDeleteMessaging.Success !== confirmDelete) {
                let respMessage = '';
                if (BulkOperationsDeleteMessaging.Cancel === confirmDelete) {
                  // Reload the first dialog box after move-cancel as if we hit the back-button.
                  this.deleteSubfoldersConfirmation();
                  return;
                } else if (BulkOperationsDeleteMessaging.MoveFailure === confirmDelete) {
                  respMessage = 'The move request did not succeed for some items';
                } else if (BulkOperationsDeleteMessaging.DeleteFailure === confirmDelete) {
                  respMessage = 'The delete request did not succeed for some items';
                } else if (BulkOperationsDeleteMessaging.MoveToSameFolder === confirmDelete) {
                  respMessage = 'The move request was cancelled because both source and destination subfolders were the same';
                } else {
                  respMessage = 'Unknown response from processing. Please verify your request was completed.';
                }
                this.showSnack(respMessage);
              } else {

                // On successful move, re-init the table - show new folders that may have been created during move
                this.mCardSvc.refreshContent();
              }
            });

            // Handle deleting without moving
          } else if (confirmDelete === 'delete') {

            //////////////////////////////////////////////
            // Show progress-bar and start deleting items
            this.showProgressDlg('were deleted.', false, '', subFolders.length);
            this.mCardSvc.deleteMultiSubfolders(subFolders);

          } else {

            // User cancelled delete operation
            this.showSnack('Delete was cancelled. No items removed.');
          }
        });
    });
  }
  async handleFolderMoveAndDeleteResponse(folders: any[]): Promise<number> {

    // Move items first - we must know the move was successful before deleting
    const res = await this.moveToFolderConfirmationPrep(folders);
    if ((res !== BulkOperationsDeleteMessaging.Success) && (res !== BulkOperationsDeleteMessaging.NoItemsToMove)) {
      return res;
    }

    // Ensure move is completed in the database (re-query the db to see if any items are still there)
    let filteredCards: any[];
    let loop: number = 1;

    do {
      await this.mCardSvc.waitForMoveToComplete(1000);

      filteredCards = await this.mCardSvc.buildCardsByFolders(folders);
      if (filteredCards.length > 0) {
        console.log('manage-folders -> handleMoveAndDeleteResponse() -> Cards FOUND after move! ', filteredCards, loop);
      } else {
        console.log('manage-folders -> handleMoveAndDeleteResponse() -> no cards remaining after move: ', loop);
      }
    }
    while ((filteredCards.length > 0) && (loop++ < 3));

    // Let's abort the delete if items are still pending a move to new folder/subfolder
    if (filteredCards.length > 0) {
      return BulkOperationsDeleteMessaging.MoveFailure;
    }

    // Delete folders after moving items
    this.showProgressDlg('were deleted.', false, '', folders.length);
    await this.mCardSvc.deleteMultiFolders(folders);

    return BulkOperationsDeleteMessaging.Success;
  }
  async handleSubfolderMoveAndDeleteResponse(subFolders: any[]): Promise<number> {

    // Move items first - we must know the move was successful before deleting
    const res = await this.moveToSubfolderConfirmationPrep(subFolders);
    if ((res !== BulkOperationsDeleteMessaging.Success) && (res !== BulkOperationsDeleteMessaging.NoItemsToMove)) {
      return res;
    }

    // Ensure move is completed in the database (re-query the db to see if any items are still there)
    let filteredCards: any[];
    let loop: number = 1;

    do {
      await this.mCardSvc.waitForMoveToComplete(800);
      filteredCards = await this.mCardSvc.buildCardsBySubfolders(subFolders);
    }
    while ((filteredCards.length > 0) && (loop++ < 3));

    // Let's abort the delete if items are still pending a move to new folder/subfolder
    if (filteredCards.length > 0) {
      return BulkOperationsDeleteMessaging.MoveFailure;
    }

    // Delete subfolders after moving items
    this.showProgressDlg('were deleted.', false, '', subFolders.length);
    await this.mCardSvc.deleteMultiSubfolders(subFolders);

    return BulkOperationsDeleteMessaging.Success;
  }
  async moveToFolderConfirmationPrep(folders: any[]): Promise<number> {

    // If there are no items to move then return and start deleting
    let filteredCards = await this.mCardSvc.buildCardsByFolders(folders);
    if (filteredCards.length < 1) {
      return BulkOperationsDeleteMessaging.NoItemsToMove;
    }

    //Open the Move dialog box
    this.selectedCards = filteredCards;
    const excludeFolderIDs: number[] = folders.map(({ id }) => id);
    const res = await this.moveToFolderConfirmation(excludeFolderIDs, null);

    return res;
  }
  async moveToSubfolderConfirmationPrep(subfolders: any[]): Promise<number> {

    // If there are no items to move then return and start deleting
    let filteredCards = await this.mCardSvc.buildCardsBySubfolders(subfolders);
    if (filteredCards.length < 1) {
      return BulkOperationsDeleteMessaging.NoItemsToMove;
    }

    //Open the Move dialog box
    this.selectedCards = filteredCards;
    const excludeSubfolderIDs: number[] = subfolders.map(({ id }) => id);
    const res = await this.moveToFolderConfirmation(null, excludeSubfolderIDs);
    return res;
  }

  deleteCardsConfirmation(): void {
    if (this.cardsNotSelected()) return;

    this.modalService
      .openModal(OpDeleteItemWarningComponent, {
        data: {
          name: 'something',
          itemType: 'Items',
          defaultMessage: false,
          additionalClasses: 'manage-cards_bulk-delete-modal',
          customMessage: this.getCustomBulkDeleteMessage()
        }
      })
      .afterClosed()
      .subscribe((canDelete: boolean) => {
        if (canDelete) {
          this.showProgressDlg('were deleted.');
          this.mCardSvc.deleteSelectedAuditsJourneys(this.selectedCards);
        } else {
          this.showSnack('No items were deleted.');
        }
      });
  }
  getCustomBulkDeleteMessage(): string {
    let message = '<div>Are you sure you want to permanently delete the following ';
    let numAudits = 0;
    let numWebJourneys = 0;
    let counts = [];
    let list = '';

    this.selectedCards.forEach((card: any) => {
      switch (card.type) {
        case CardTypes.audit:
          list += `<li>Audit: ${card.name}</li>`;
          numAudits++;
          break;

        case CardTypes.webJourney:
          list += `<li>Web Journey: ${card.name}</li>`;
          numWebJourneys++;
          break;
      }
    });

    if (numAudits) counts.push(`${numAudits} ${numAudits > 1 ? 'audits' : 'audit'}`);
    if (numWebJourneys) counts.push(`${numWebJourneys} ${numWebJourneys > 1 ? 'web journeys' : 'web journey'}`);

    message += `${counts.join(', ')}. Please note that this is irreversible.</div><br><ul>${list}</ul><br>`;

    return message;
  }

  /**
   * Return an array of any selected audits where the audits total page count is greater
   * than the amount a user has been given permission to run
   */
  getAuditsWithPagesExceedingUserLimit(): any[] {
    return this.selectedCards.reduce((acc, card, index) => {
      if (card.type === CardTypes.audit) {
        if (this.user.maxPagesPerAudit > 0 && card.pageCount > this.user.maxPagesPerAudit) {
          acc.push(index);
        }
      }

      return acc;
    }, []);
  }

  ////////////////////////////////////////////////////////
  // Scan list of selected cards and gather the names of
  //    each audit/journey for messaging to the end user
  //    Return a message if we display fewer descriptions in the modal than will be processed
  ////////////////////////////////////////////////////////
  getListOfSelectedDataSources(ignoreRunningRequirement: boolean, filteredList?: any[]): string {
    let selectedCards = filteredList ? filteredList : this.selectedCards;

    //Initialize list of audit and journey names that will be presented to the user in a 'Are You Sure' dialog box.
    this.sourceNames = new Array(2);
    const maxCount: number = 10;
    let cardName: string;

    //Loop up-to Max number of selected cards
    let cards: any[] = selectedCards.slice(0, maxCount);
    cards.forEach((card) => {
      cardName = card.name.substring(0, 50);

      //Find all running audits/Journeys and collect name for results message
      if (ignoreRunningRequirement || card.webAuditRunning || card.webJourneyRunning) {

        switch (card.type) {

          case CardTypes.audit:
            this.sourceNames[this.auditIndex] =
              (!this.sourceNames[this.auditIndex]) ? cardName : this.sourceNames[this.auditIndex] + ', ' + cardName;
            break;

          case CardTypes.webJourney:
            this.sourceNames[this.journeyIndex] =
              (!this.sourceNames[this.journeyIndex]) ? cardName : this.sourceNames[this.journeyIndex] + ', ' + cardName;
        }
      }
    });

    //Add '...' if the number of selected cards is greater than we can display in our modal
    if (selectedCards.length > cards.length) {
      this.sourceNames[this.auditIndex] = (this.sourceNames[this.auditIndex])
        ? this.sourceNames[this.auditIndex] + '...' : '...';
      this.sourceNames[this.journeyIndex] = (this.sourceNames[this.journeyIndex])
        ? this.sourceNames[this.journeyIndex] + '...' : '...';

      //Return the additional note in the modal to explain why we are listing only some of the selected cards.
      return '<p></p><p><i>Note the list below is a partial list of selected data sources.<i></p>';
    }

    return '';
  }

  generateFullListOfSelectedDataSources(ignoreRunningRequirement: boolean) {
    this.sourceNames = [];

    this.selectedCards.forEach((card) => {
      //Find all running audits/Journeys and collect name for results message
      if (ignoreRunningRequirement || card.webAuditRunning || card.webJourneyRunning) {

        switch (card.type) {

          case CardTypes.audit:
            this.sourceNames[this.auditIndex] =
              (!this.sourceNames[this.auditIndex]) ? card.name : this.sourceNames[this.auditIndex] + ', ' + card.name;
            break;

          case CardTypes.webJourney:
            this.sourceNames[this.journeyIndex] =
              (!this.sourceNames[this.journeyIndex]) ? card.name : this.sourceNames[this.journeyIndex] + ', ' + card.name;
        }
      }
    });
  }

  listOfCardsToProcess(): string {
    let list: string = '<p><ul>';
    if (this.sourceNames[this.auditIndex])
      list = list + '<li>Audits: ' + this.sourceNames[this.auditIndex] + '</li>';
    if ((this.sourceNames[this.journeyIndex]))
      list = list + '<li>Journeys: ' + this.sourceNames[this.journeyIndex] + '</li>';
    list = list + '</ul></p>';

    return list;
  }

  showProgressDlg(msgSegment: string, showFinalMessage: boolean = true, finalMessage?: string, maxCountOverride?: number) {

    this.processCompleted = false;
    let formattedMessage: string = `${this.firstMessage} ${msgSegment}`;
    let formattedMessage2: string =
      'Don\'t close this banner, or leave this page until finished or remaining data sources won\'t be completed.';

    const confirmConfig = {
      maxCount: (maxCountOverride) ? maxCountOverride : this.selectedCards.length,
      messages: [
        formattedMessage,
        formattedMessage2,
        finalMessage,
      ],
      showFinalMessage: showFinalMessage,
      showSecondBtn: false,
      showProgressBar: true,
      rightFooterButtons: [
        {
          label: 'Yes',
          opSelector: 'bulk-confirm-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionProgressComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.processCompleted = true;
          return false;
        }
      });

    return true;
  }

  showSnack(message: string) {
    this.snackBar.open(message, '', {
      duration: this.duration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  createNewFolder(folderName: string): Promise<IFolder> {
    return new Promise((resolve, reject) => {
      this.foldersService.createFolder({ name: folderName } as IFolder)
        .then((newFolder: IFolder) => {
          resolve(newFolder);
        })
        .catch(() => {
          reject();
        });
    });
  }

  createNewDomain(name: string, folderId: number, dataLayer: string): Promise<IDomain> {
    const domain: ICreateDomainRequest = {
      name,
      dataLayer: dataLayer || '',
      folderId,
      domain: 'http://www.example.com'
    };

    return new Promise((resolve, reject) => {
      this.domainsService.createDomain(domain).then((newDomain: IDomain) => {
        resolve(newDomain);
      })
        .catch(() => {
          reject();
        });
    });
  }
  getFolderIdFromDomainId(domainId: number): number {
    return this.domains.find((domain: IDomain) => domainId === domain.id)?.folderId;
  }

  newFolder(): void {
    this.modalService
      .openModal(CreateEditFolderComponent, {})
      .afterClosed()
      .subscribe(() => {
        this.updateDomains.emit();
        this.eventManager.publish(Messages.refreshContent);
      });
  }

  newDomain(): void {
    this.modalService
      .openModal(CreateEditDomainComponent, {
        data: { domains: [], folders: this.folders, mode: EManageDomainsMode.Create, useDefaultDomain: true }
      })
      .afterClosed()
      .subscribe(() => {
        this.updateDomains.emit();
        this.eventManager.publish(Messages.refreshContent);
      });
  }

  //Ensure an item is selected before proceeding
  private cardsNotSelected(): boolean {
    if (this.selectedCards.length < 1) {
      this.showSnack('No data sources are selected.');
      return true;
    } else {
      return false;
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.showBtnTooltips = (window.innerWidth <= this.breakpoint);
  }
}
