import { MaterialModule } from '@app/components/material/material.module';
import * as angular from 'angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { Names } from '@app/moonbeamConstants';
import { FileDropperDirective } from './app/fileDropperDirective';
import { FileHandoff } from './fileHandoff/fileHandoff';
import { FileHandoffController } from './fileHandoff/fileHandoffController';
import { LocationsService } from './webJourney/locationsService';
import { unselectFile } from './app/unselectFileDirective';
import { HighlightTextFilter } from './rules/highlightTextFilter';
import { SharedModule } from '@app/components/shared/shared.module';
import { NavigationNgModule } from '../navigation/navigationModule';
import { RFMComponent } from './shared/remoteFileMapping/remote-file-mapping.component';
import { RemoteFileMapService } from './services/remote-file-map.service';
import liveConnectCreate from './live-connect/liveConnectCreateModule';
import { OpFormModule } from '../shared/op-form.module';
import { OpModalService } from '../shared/components/op-modal';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';

export default angular.module('creatorModule', [ liveConnectCreate ])
  .directive(Names.Directives.fileDropper, FileDropperDirective.factory())
  .directive('unselectFile', unselectFile)
  .directive('fileHandoff', FileHandoff.Factory())
  .controller('fileHandoffController', FileHandoffController)
  .service(Names.Services.locations, LocationsService)
  .filter(Names.Filters.highlightText, ['$sce', HighlightTextFilter.factory])
  .directive('rfmPickerNg', downgradeComponent({ component: RFMComponent }))
  .service(Names.NgServices.opModalService, downgradeInjectable(OpModalService))
  .directive(Names.NgComponents.ruleSetupModal, downgradeComponent({component: RuleSetupModalComponent}))
  .name;

@NgModule({
  declarations: [
    RFMComponent,
  ],
  imports: [
    MaterialModule,
    CommonModule,
    SharedModule,
    OpFormModule,
    NavigationNgModule,
  ],
  providers: [
    RemoteFileMapService
  ],
  exports: [
    RFMComponent,
  ]
})
export class CreatorNgModule {}
