import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenMenuOnHoverComponent } from './open-menu-on-hover.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    OpenMenuOnHoverComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule
  ],
  exports: [
    OpenMenuOnHoverComponent
  ],
  providers: [],
})
export class OpenMenuOnHoverModule {}