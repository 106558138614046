import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import {
  DiscoveryAuditService,
  IAuditPageRules,
  IAuditPageRulesResult,
  IAuditPageRulesResultFilter,
  IAuditPageRulesResultTagResult,
  IAuditPageRulesResultTagVariable
} from '@app/components/domains/discoveryAudits/discoveryAuditService';
import {
  IFailedRuleReport,
  IFailedRuleTagReport,
  INotAppliedRuleReport,
  IPassedRuleReport,
  IRuleReportItem,
  RuleNotApplyingReason,
  RuleResultType
} from '@app/components/reporting/rules/rule.models';
import { EPageDetailsTabs } from '../page-details/page-details.constants';
import { EasyRuleEditService } from '@app/components/shared-services/easy-rule-edit-service';
import { RulesService } from '@app/components/rules/rules.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { forkJoin, Subject } from 'rxjs';
import {
  RuleSelectorModalComponent
} from '@app/components/account/rules/rule-selector-modal/rule-selector-modal.component';
import {
  IRuleSelectorModalPayload
} from '@app/components/account/rules/rule-selector-modal/rule-selector-modal.models';
import {
  ReprocessConfirmationSnackbarService
} from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.service';
import {
  IReprocessService,
  ReprocessBannerStatus
} from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import {
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { IAuditModel } from '@app/components/modals/modalData';
import {
  IReprocessConfirmationItem
} from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.component';
import { AccountsService } from '@app/components/account/account.service';
import { EFreeTrialAdModalType } from '../audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import { FreeTrialAdModalComponent } from '../audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import { takeUntil } from 'rxjs/operators';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { LabelService } from '@app/components/shared/services/label.service';
import { matchTypeToLabel } from '@app/components/reporting/rules/rules.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-page-rules-wrapper',
  templateUrl: './audit-page-rules-wrapper.component.html',
  styleUrls: ['./audit-page-rules-wrapper.component.scss'],
})
export class AuditPageRulesWrapperComponent implements OnInit, OnChanges, OnDestroy {

  loading: boolean = true;
  userIsReadOnly: boolean;

  failedRuleReports: IFailedRuleReport[] = [];
  notAppliedRuleReports: INotAppliedRuleReport[] = [];
  passedRuleReports: IPassedRuleReport[] = [];

  private isVisitorMode: boolean;
  private destroySubject = new Subject<void>();

  @Input() auditId: number;
  @Input() runId: number;
  @Input() reportType?: EReportType;
  @Input() pageId?: string;
  @Input() activeTab?: EPageDetailsTabs;

  constructor(private auditService: DiscoveryAuditService,
    private accountsService: AccountsService,
    private easyRuleEditService: EasyRuleEditService,
    private opModalService: OpModalService,
    private applicationChromeService: ApplicationChromeService,
    private rulesService: RulesService,
    private labelsApiService: LabelService,
    private modalEscapeService: ModalEscapeService,
    private reprocessConfirmationSnackbarService: ReprocessConfirmationSnackbarService,
    private reprocessService: IReprocessService,
    private auditDataService: IAuditDataService,
  ) { }

  ngOnInit() {
    this.getUserPrivileges();

    this.applicationChromeService.isVisitorMode$
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: isVisitorMode => this.isVisitorMode = isVisitorMode
      });
  }

  ngOnChanges() {
    this.getRuleResults();
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private getUserPrivileges(): void {
    this.userIsReadOnly = this.accountsService.userIsReadOnly();
  }

  editRuleClicked(ruleId: number) {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      this.editRule(ruleId);
    }
  }

  private editRule(ruleId: number): void {
    const rules: IReprocessConfirmationItem[] = [];

    this.failedRuleReports.forEach(r => rules.push({
      name: r.ruleName
    }));
    this.notAppliedRuleReports.forEach(r => rules.push({
      name: r.ruleName
    }));
    this.passedRuleReports.forEach(r => rules.push({
      name: r.ruleName
    }));

    rules.sort((a, b) => a.name.localeCompare(b.name));

    this.easyRuleEditService.editRule(ruleId, isChanged => {
      if (isChanged) {
        this.reprocessConfirmationSnackbarService
          .showReprocessRules(rules, () => {
            this.auditDataService
              .reprocessRules(this.auditId, this.runId)
              .then(() => this.auditDataService.getAudit(this.auditId))
              .then((audit: IAuditModel) => {
                this.reprocessService.reprocessComplete();
                this.reprocessService.subscribeOnAuditReprocessingRulesUpdates(this.auditId, this.runId, audit.name);
                this.reprocessService.updateAuditReprocessRulesBannerStatus(this.auditId, this.runId, ReprocessBannerStatus.inProgress);
              });
          });
      }
    });
  }

  removeRuleClicked(ruleId: number): void {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      this.removeRule(ruleId);
    }
  }

  private removeRule(ruleId: number): void {
    this.easyRuleEditService.removeAuditRule(ruleId, this.auditId);
  }

  setAsExpected(ruleReportItem: IRuleReportItem): void {
    this.easyRuleEditService.setAsExpected(ruleReportItem);
  }

  deleteVariable(ruleReportItem: IRuleReportItem): void {
    this.easyRuleEditService.deleteVariable(ruleReportItem);
  }

  private getRuleResults(): void {
    this.loading = true;
    this.auditService.getAuditPageRules(this.auditId, this.runId, this.pageId).then((ruleResults: IAuditPageRules) => {
      this.sortRuleResults(ruleResults);
      this.loading = false;
    });
  }

  private sortRuleResults(ruleResults: IAuditPageRules): void {
    const failed: IAuditPageRulesResult[] = [];
    const notApplied: IAuditPageRulesResult[] = [];
    const passed: IAuditPageRulesResult[] = [];

    ruleResults.results.map((result: IAuditPageRulesResult) => {
      switch (result.resultType) {
        case RuleResultType.failed:
          failed.push(result);
          break;
        case RuleResultType.notApplied:
          notApplied.push(result);
          break;
        case RuleResultType.passed:
          passed.push(result);
          break;
      }
    });

    this.transformFailedRuleResults(failed);
    this.transformNotAppliedRuleResults(notApplied);
    this.transformPassedRuleResults(passed);
  }

  private transformFailedRuleResults(results: IAuditPageRulesResult[]): void {
    this.failedRuleReports = results.map((result: IAuditPageRulesResult) => {
      const failedTags: IFailedRuleTagReport[] = result.tagResults.map((tag: IAuditPageRulesResultTagResult) => {
        return {
          tagName: tag.tag.name,
          missed: tag.resultType == RuleNotApplyingReason.missingTag,
          items: tag.tag.variables.map((variable: IAuditPageRulesResultTagVariable) => {
            return {
              name: variable.name,
              expected: `${matchTypeToLabel(variable.matchType)}${variable.expectedValues[0] ?? ''}`,
              actual: variable.actualValue,
              conditionId: tag.conditionId,
              snapshotId: variable.snapshotId,
              tagId: tag.tag.id
            };
          })
        };
      });

      return {
        ruleId: result.ruleId,
        ruleName: result.name,
        failedTags: failedTags,
        checkTimes: result.noOfChecks
      };
    });
  }

  private transformNotAppliedRuleResults(results: IAuditPageRulesResult[]): void {
    this.notAppliedRuleReports = results.map((result: IAuditPageRulesResult) => {
      let items = [];

      result.pageFilterResults.forEach((filter: IAuditPageRulesResultFilter) => {
        items.push({
          name: `${filter.filterType} ${filter.matchType}`,
          expected: filter.expected,
          actual: filter.actual
        });
      });

      result.tagResults.forEach((tagResult: IAuditPageRulesResultTagResult) => {
        if (tagResult.resultType === RuleNotApplyingReason.missingTag) {
          items.push({
            name: tagResult.tag.name,
            expected: tagResult.resultType
          });
        }

        if (tagResult.resultType === RuleNotApplyingReason.incorrectVariables) {
          items = tagResult.tag.variables.map((variable: IAuditPageRulesResultTagVariable) => {
            return {
              actual: variable.actualValue,
              conditionId: tagResult.conditionId,
              expected: variable.expectedValues[0],
              name: variable.expectedParamName,
              snapshotId: variable.snapshotId,
              tagId: tagResult.tag.id
            };
          });
        }
      });

      return {
        ruleName: result.name,
        ruleId: result.ruleId,
        items: items
      };
    });
  }

  private transformPassedRuleResults(results: IAuditPageRulesResult[]): void {
    this.passedRuleReports = results.map((result: IAuditPageRulesResult) => {
      return {
        ruleName: result.name,
        ruleId: result.ruleId
      };
    });
  }

  addRuleClicked(): void {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      this.openAddRuleModal();
    }
  }

  private openAddRuleModal() {
    forkJoin([
      this.rulesService.getRulePreviews(),
      this.labelsApiService.getLabels(),
      this.auditService.getAudit(this.auditId)
    ]).subscribe(([rules, labels, actions]) => {
      const index = this.modalEscapeService.getLast() + 1;

      this.opModalService.openFixedSizeModal<RuleSelectorModalComponent, IRuleSelectorModalPayload>(RuleSelectorModalComponent, {
        data: {
          rules,
          labels,
          selectedRules: actions.rules ? actions.rules.map(r => r.id) : []
        }
      }, 'op-rule-selector').afterClosed().subscribe(ruleSelection => {
        this.modalEscapeService.remove(index);

        if (ruleSelection) {
          this.auditService.updateRulesForAudit(this.auditId, ruleSelection.selectedRuleIds);
        }
      });
    });
  }

  private openFreeTrialAdModal() {
    const data = {
      type: EFreeTrialAdModalType.RULE
    };

    this.opModalService.openModal(FreeTrialAdModalComponent, { data });
  }
}
