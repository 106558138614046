import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  SnackbarErrorComponent
} from '@app/components/shared/components/snackbars/snackbar-error/snackbar-error.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { Subscription } from 'rxjs';
import {
  ISwitchableMenuItem,
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';
import {
  TableColumnMenuWarningMessage
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.constants';

@Component({
  selector: 'op-switchable-column-menu',
  templateUrl: './switchable-column-menu.component.html',
  styleUrls: ['./switchable-column-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
    useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
  }]
})
export class SwitchableColumnMenuComponent
  implements OnInit, OnDestroy {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  @HostListener('document:click') click() {
    if (this.isClosingAvailable && this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
    }
  }

  @HostListener('click') openMenu() {
    if (this.menuTrigger.menuClosed) {
      this.menuTrigger.openMenu();
      this.isClosingAvailable = false;

      setTimeout(() => {
        this.isClosingAvailable = true;
      }, 500);
    }
  }

  @Input() storageKey: string = null;

  private _columnConfig: ISwitchableMenuItems;

  @Input() set columnConfig(config: ISwitchableMenuItems) {
    this._columnConfig = config;
    this.updateCheckboxConfig();
  };

  get columnConfig(): ISwitchableMenuItems {
    return this._columnConfig;
  }

  @Input() warningMessage = TableColumnMenuWarningMessage;

  checkboxes: ISwitchableMenuItems;
  defaultCheckboxes: ISwitchableMenuItems;

  get menuItems(): ISwitchableMenuItem[] {
    return this.checkboxes && Object.values(this.checkboxes)
      .filter(v => !v.hiddenInMenu)
      .filter(v => !v.pinned);
  }

  get pinnedMenuItems(): ISwitchableMenuItem[] {
    return this.checkboxes && Object.values(this.checkboxes)
      .filter(v => !v.hiddenInMenu)
      .filter(v => v.pinned);
  }

  get showPinnedItemTooltip(): boolean {
    return this.pinnedMenuItems?.filter(i => i.checked).length === 1;
  }

  private isClosingAvailable = false;

  private _subscriptions: Subscription[] = [];

  set subscriptions(subscription) {
    this._subscriptions.push(subscription);
  }

  get subscriptions(): any {
    return this._subscriptions;
  }

  constructor(
    private snackbar: MatSnackBar,
    private tableService: ResizeableTableService,
  ) {
  }

  reset(): void {
    this.checkboxes = JSON.parse(JSON.stringify(this.defaultCheckboxes));
    this.pushCheckboxConfig(this.checkboxes);
  }

  ngOnInit(): void {
    this.initCheckboxConfigChangeSubscription();
    this.initGetTableCheckboxConfig();
  }

  private updateCheckboxConfig(): void {
    if (!this.columnConfig) {
      console.error(`Table column config is not provided for ${this.storageKey}`);
      return;
    }
    this.tableService.tableCheckboxConfig.next(this.columnConfig);
  }

  loadTableSettingsConfig(): void {
    if (!this.storageKey) {
      console.error('Table column storage key is not provided');
      return;
    }
    const storageTableConfig = this.tableService.getTableConfigInLocalStorage(this.storageKey)?.items;

    // Push the checkbox config for exact table
    this.pushCheckboxConfig(
      storageTableConfig
      ? this.mergeCheckboxConfig(storageTableConfig)
      : this.checkboxes
    );
  }

  private updatePagesTableSettings(): void {
    if (!this.storageKey) {
      console.error('Table column storage key is not provided');
      return;
    }
    this.tableService.setTableConfigInLocalStorage(this.storageKey, {
      ...this.tableService.getTableConfigInLocalStorage(this.storageKey),
      items: {
        ...this.tableService.getTableConfigInLocalStorage(this.storageKey)?.items,
        ...this.checkboxes,
      },
    });

    // Push displayedColumns for table
    this.tableService.displayedColumns.next(
      Object.keys(this.checkboxes)
        .filter((key => this.checkboxes[key].checked))
    );
  }

  private mergeCheckboxConfig(localStorageConfig: ISwitchableMenuItems): ISwitchableMenuItems {
    // Get the values for needed checkboxes from localStorage and apply to the ones present in current table
    for (const key in localStorageConfig) {
      if (this.checkboxes.hasOwnProperty(key)) {
        this.checkboxes[key] = {
          ...localStorageConfig[key],
          title: this.checkboxes[key].title
        };
      }
    }

    return this.checkboxes;
  }

  private pushCheckboxConfig(checkboxConfig: ISwitchableMenuItems): void {
    this.tableService.checkboxConfigChanged.next(checkboxConfig);
  }

  initGetTableCheckboxConfig(): void {
    // Get the exact checkbox config for table (because we're storing all checkboxes in common config)
    this.subscriptions = this.tableService.tableCheckboxConfig$
      .subscribe(checkboxes => {
        // Save default items for *reset* button
        this.defaultCheckboxes = JSON.parse(JSON.stringify(checkboxes));

        this.checkboxes = JSON.parse(JSON.stringify(checkboxes));
        this.loadTableSettingsConfig();
      });
  }

  emitStateUpdates(item: ISwitchableMenuItem): void {
    const isFalse = !!item.checked;

    const anyOtherIsTrue = this.pinnedMenuItems.some((v) => v.checked && v.title !== item.title);

    if (isFalse) {
      if (!anyOtherIsTrue) {
        this.showErrorMessage();
      } else {
        item.checked = !item.checked;
        this.tableService.checkboxConfigChanged.next(this.checkboxes);
      }
    } else {
      item.checked = !item.checked;
      this.tableService.checkboxConfigChanged.next(this.checkboxes);
    }
  }

  private showErrorMessage() {
    this.snackbar.openFromComponent(SnackbarErrorComponent, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        message: this.warningMessage
      }
    });
  }

  private initCheckboxConfigChangeSubscription(): void {
    this.subscriptions = this.tableService.checkboxConfigChanged$.subscribe(() => this.updatePagesTableSettings());
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }
}
