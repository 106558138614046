import { Component, Input, OnChanges, AfterViewInit, Output, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import { Arc, PieArcDatum, DefaultArcObject, Pie } from 'd3';
import { Selection } from 'd3-selection';
import { backgroundChartData, shortFallName, totalMissingMessage, totalMustBeLargerMessage } from './donut-chart.constants';
import { IDonutChartDataPoint } from './donut-chart.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnChanges, AfterViewInit, OnDestroy {

  // donut chart based off of the following:
  // https://bl.ocks.org/Zhenmao/4a96cc5b296d9cfea270e5f20c60b222

  chartDidInit: boolean;
  isSingleValue: boolean;
  previousData: IDonutChartDataPoint[];
  currentData: IDonutChartDataPoint[];
  container: Selection<any, any, any, any>;
  svg: Selection<any, any, any, any>;
  svgHeight: number;
  svgWidth: number;
  radius: number;
  pie: Pie<any, any>;
  backgroundChart: any;
  paths: any;
  pathArc: Arc<any, DefaultArcObject>;
  text: any;
  textValue: any;
  tooltip: Selection<any, any, any, any>;
  tooltipClass: string = 'donut-chart-tooltip';
  tooltipWidth: number;
  tooltipHeight: number;
  windowWidth: number = window.innerWidth;
  key: (d: PieArcDatum<IDonutChartDataPoint>) => string;

  @Input() data: IDonutChartDataPoint[];
  @Input() uniqueIdentifier: string;

  // optional inputs
  @Input() displayPercentSymbol?: boolean = true;
  @Input() calcAsPercentage?: boolean = true;
  @Input() donutThickness?: number; // in pixels
  @Input() fontSize?: number; // in pixels

  @Output() donutSectionClicked: EventEmitter<{mouseEvent: MouseEvent, item: IDonutChartDataPoint}> = new EventEmitter();

  ngOnChanges(): void {
    if(this.data && this.chartDidInit) this.handleData();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.initChart());
  }

  ngOnDestroy(): void {
    this.hideTooltip();
  }

  initChart(): void {
    this.container = d3.select(`.donut-chart-svg-container-${this.uniqueIdentifier}`).html('');
    if (this.container.empty()) { // If selection is empty, any methods called on it will result in error
      return;
    }
    this.svgHeight = parseInt(this.container.style('height'));
    this.svgWidth = parseInt(this.container.style('width'));
    this.radius = Math.min(this.svgHeight, this.svgWidth) / 2;

    this.svg = this.container
      .append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight)
      .attr('class', 'op-chart')
      .append('g')
      .attr('transform', `translate(${this.radius}, ${this.radius})`);

    this.pie = d3
      .pie<IDonutChartDataPoint>()
      .sort(null)
      .value(d => d.value);

    this.pathArc = d3
      .arc()
      .innerRadius(this.donutThickness ? this.radius - this.donutThickness : this.radius - (this.radius / 3))
      .outerRadius(this.radius);

    this.backgroundChart = this.svg
      .append('g')
      .attr('class', 'background-chart');

    this.paths = this.svg
      .append('g')
      .attr('class', 'paths');

    this.text = this.svg
      .append('g')
      .attr('class', 'text');

    this.textValue = this.text
      .append('text')
      .attr('class', 'donut-chart-value')
      .text(0)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'middle')
      .style('font-size', this.fontSize ? this.fontSize : this.radius / 3.5)
      .style('font-weight', 600);

    this.key = (d: PieArcDatum<IDonutChartDataPoint>) => d.data.name;

    // tooltip
    // only create if it's not already on the page
    if(!d3.select(`.${this.tooltipClass}`).node()) this.createTooltip();

    // get reference to the DOM element
    this.tooltip = d3.select(`.${this.tooltipClass}`);

    // kick things off
    this.chartDidInit = true;
    if(this.data) this.handleData();
  }

  handleData(): void {
    this.isSingleValue = this.data.length === 1;

    if (this.isSingleValue && !this.data[0].totalForPercentage) {
      throw new Error(totalMissingMessage);
    }

    this.currentData = [ ...this.data ];

    if (this.isSingleValue) {
      this.drawBackgroundChart();
      this.formatDataForChart();
    }

    this.drawChart();
  }

  drawBackgroundChart(): void {
    this.backgroundChart
      .selectAll('path')
      .data(this.pie(backgroundChartData))
      .enter()
      .append('path')
      .attr('class', (d: PieArcDatum<IDonutChartDataPoint>) => d.data.colorClass)
      .attr('d', this.pathArc.bind(this));
  }

  formatDataForChart(): void {
    let roundedPercentage: number;

    const value = this.data[0].value;
    const total = this.data[0].totalForPercentage;

    if (total < value) {
      throw new Error(totalMustBeLargerMessage);
    }

    const percentage = (value / total) * 100;

    if (percentage < 100 && percentage > 99) {
      roundedPercentage = 99;
    }
    else if (percentage < 1 && percentage > 0) {
      roundedPercentage = 1;
    }
    else {
      roundedPercentage = Math.round(percentage);
    }

    this.currentData[0].value = roundedPercentage;
    if (!this.calcAsPercentage) this.currentData[0].displayValue = value;

    this.currentData.push({
      name: shortFallName,
      colorClass: 'chart-gray',
      value: 100 - roundedPercentage,
      tooltip: this.currentData[0].singleValueMissingSectionTooltip
    });
  }

  drawChart(): void {
    this.drawArcs();
    if (this.isSingleValue) this.drawText();
  }

  drawArcs(): void {
    const previousDataPie = this.paths.selectAll('path').data();

    this.previousData = !previousDataPie.length
       ? this.currentData.map((d: IDonutChartDataPoint) => ({ ...d, value: 0 }))
       : previousDataPie.map((d: PieArcDatum<IDonutChartDataPoint>) => d.data);

    const previousDataWithZeros = this.setMissingValuesToZero(this.previousData, this.currentData);
    const currentDataWithZeros = this.setMissingValuesToZero(this.currentData, this.previousData);

    let path = this.paths
      .selectAll('path')
      .data(this.pie(previousDataWithZeros), this.key);

    path
      .enter()
      .append('path')
      .merge(path)
      .attr('class', (d: PieArcDatum<IDonutChartDataPoint>) => `${d.data.colorClass} slice`)
      .each((d: PieArcDatum<IDonutChartDataPoint>, i: number, n: any) => n[i]._current = d);

    path = this.paths
      .selectAll('path')
      .data(this.pie(currentDataWithZeros), this.key);

    path
      .transition()
      .duration(750)
      .attrTween('d', this.arcTween.bind(this));

    path = this.paths
      .selectAll('path')
      .data(this.pie(this.currentData), this.key);

    path
      .exit()
      .transition()
      .delay(750)
      .duration(0)
      .remove();

    this.handleHovering();
    this.handleClicks();
  }

  handleHovering(): void {
    const slice = this.svg.selectAll('.slice');

    slice.on('mouseover', (e: MouseEvent, d: PieArcDatum<IDonutChartDataPoint>) => {
      setTimeout(() => this.getTooltipDimensions(), 0);
      this.showTooltip(d.data);

      if (!this.isSingleValue) {
        const value = this.calcAsPercentage ? this.getPercentage(d.value) : d.value;
        const suffix = this.getSuffix();

        this.svg.select('.donut-chart-value').text(value + suffix);
        this.svg.classed('hovered', true);
      }
    });

    slice.on('mousemove', (e: MouseEvent, d: PieArcDatum<IDonutChartDataPoint>) => {
      this.handleTooltipPosition(e);
    });

    slice.on('mouseout', (e: MouseEvent, d: PieArcDatum<IDonutChartDataPoint>) => {
      this.hideTooltip();

      if (!this.isSingleValue) this.svg.classed('hovered', false);
    });
  }

  handleClicks(): void {
    const slice = this.svg.selectAll('.slice');

    slice.on('click', (e: MouseEvent, d: PieArcDatum<IDonutChartDataPoint>) => {
      this.donutSectionClicked.emit({ mouseEvent: e, item: d.data });
    });
  }

  getTooltipDimensions(): void {
    this.tooltipWidth = this.tooltip.node().getBoundingClientRect().width + 14;
    this.tooltipHeight = this.tooltip.node().getBoundingClientRect().height + 8;
  }

  showTooltip(d: IDonutChartDataPoint): void {
    this.tooltip.html(d.tooltip ? d.tooltip : d.name);

    this.tooltip
      .style('display', 'flex')
      .transition()
      .duration(150)
      .style('opacity', 1);
  }

  hideTooltip(): void {
    this.tooltip
      .style('opacity', 0)
      .style('display', 'none');
  }

  handleTooltipPosition(e: MouseEvent): void {
    const horizPos = e.clientX;
    const vertPos = e.offsetY < (this.svgHeight / 2) ? e.clientY - 35 : e.clientY + 25;
    const leftEdge = 0;
    const rightEdge = this.windowWidth;
    const edgePadding = 5;

    // left edge of window
    if ((horizPos - (this.tooltipWidth / 2)) <= leftEdge + edgePadding) {
      this.tooltip
        .style('left', `${(this.tooltipWidth / 2) + edgePadding}px`)
        .style('top', `${vertPos}px`);
    }

    // right edge of window
    else if ((horizPos + (this.tooltipWidth / 2)) >= rightEdge - edgePadding) {
      this.tooltip
        .style('left', `${rightEdge - (this.tooltipWidth)}px`)
        .style('top', `${vertPos}px`);
    }

    // default behavior in middle of window
    else {
      this.tooltip
        .style('left', `${horizPos - (this.tooltipWidth / 2)}px`)
        .style('top', `${vertPos}px`);
    }
  }

  drawText(): void {
    this.textValue
      .data(this.currentData)
      .classed('single-value-text', true)
      .transition()
      .tween('text', (d: IDonutChartDataPoint, i: number, n: any) => {
        const self = d3.select(n[i]);
        const start = parseInt(self.text());
        const end = d.displayValue ? d.displayValue : d.value;
        const interpolator = d3.interpolateNumber(start, end)
        const suffix = this.getSuffix();

        return (t: number) => self.text(Math.round(interpolator(t)) + suffix);
      })
      .duration(750);
  }

  getDonutText(d: IDonutChartDataPoint): string {
    const value = d.displayValue ? d.displayValue : d.value;
    const suffix = this.getSuffix();
    return value + suffix;
  }

  setMissingValuesToZero(previous: IDonutChartDataPoint[], current: IDonutChartDataPoint[]): IDonutChartDataPoint[] {
    const previousWithZeros = [ ...previous ];
    const items = previous.map((item: IDonutChartDataPoint) => item.name);

    current.forEach((d: IDonutChartDataPoint) => {
      if (!items.includes(d.name)) previousWithZeros.push({ ...d, value: 0 });
    });

    return previousWithZeros;
  }

  arcTween(d: PieArcDatum<IDonutChartDataPoint>, i: number, n: any) {
		const inter = d3.interpolate(n[i]._current, d);
    return (t: number) => this.pathArc(inter(t) as any);
	}

  getPercentage(value: number): number {
    const total = this.currentData
      .map((d: IDonutChartDataPoint) => d.value)
      .reduce((a: number, b: number) => a + b, 0);

    const percentage = (value / total) * 100;

    if (percentage < 100 && percentage > 99) return 99;
    if (percentage < 1 && percentage > 0) return 1;

    return Math.round(percentage);
  }

  getSuffix(): string {
    return this.displayPercentSymbol ? '%' : '';
  }

  createTooltip(): void {
    d3
      .select('body')
      .append('div')
      .attr('class', this.tooltipClass);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.windowWidth = window.innerWidth;
  }
}
