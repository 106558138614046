import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IUser } from '@app/moonbeamModels';

export interface IMenuItem {
  icon?: string;
  label?: string;
  eventType?: string;
  onClick?: (model: any, cardIndex?: number) => void;
  hidden?: (model?: any) => boolean;
  disable?: (model?: any, currentUser?: IUser) => boolean;
  disableText?: (model?: any, currentUser?: IUser) => string;
}

@Component({
  selector: 'op-menu',
  templateUrl: './op-menu.component.html',
  styleUrls: ['./op-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OpMenuComponent {
  @Input() items: IMenuItem[];
  @Input() model?: any;
  @Input() horizontal?: boolean = false;
  @Input() tooltipPosition?: String = 'below';
  @Input() cardIndex?: number;
  @Input() icon?: string;
  @Input() class?: string = '';
  @Input() currentUser?: IUser;

  onItemClick(event, item) {
    this.model ? item.onClick(this.model, this.cardIndex) : item.onClick(item);
  }
}
