import { Subject } from 'rxjs';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import {
  EBarChartDirection,
  EBarChartTextPosition
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.constants';
import { EChartColor, formatPaginator } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { getPercent } from '@app/components/utilities/number.utils';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  IPrivacyRequestCompliance,
  IPrivacyRequestComplianceItem,
  IPrivacyRequestComplianceRow,
  IPrivacyRequestsGlobalFilterApiBody,
  IPrivacyRequestsPagesApiBody,
  PrivacyRequestsComplianceExportMenuData,
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.models';
import { StringUtils } from '@app/components/utilities/StringUtils';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { AddConsentCategoriesModalComponent } from '@app/components/audit-reports/reports/privacy/add-consent-categories-modal/add-consent-categories-modal.component';
import { filter, take, takeUntil } from 'rxjs/operators';
import {
  EConsentCategoryType,
  EDomainType,
  IConsentCategories,
  IConsentCategoryGeos,
  IConsentCategorySnapshot
} from '@app/components/consent-categories/consent-categories.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { DecimalPipe, formatNumber } from '@angular/common';
import { AccountsService } from '@app/components/account/account.service';
import {
  EFreeTrialAdModalType
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import {
  FreeTrialAdModalComponent
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { IAuditEditorModalData } from '@app/components/audit/audit-editor/audit-editor.models';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'requests-compliance-table',
  templateUrl: './privacy-requests-table.component.html',
  styleUrls: ['./privacy-requests-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyRequestsTableComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() set items(data: IPrivacyRequestCompliance) {
    if (data) {
      this.dataSource.data = this.prepareItems(data.requests);
      this.setPagination(data.metadata);
    }
  }

  @Input() auditId: number;
  @Input() auditName: string;
  @Input() runId: number;
  @Input() state: EFilterSpinnerState;
  @Input() filteredPageCount: number;
  @Input() apiFilters: IAuditReportApiPostBody;
  @Input() auditConsentCategories: IConsentCategories[];
  @Input() runConsentCategories: IConsentCategorySnapshot[];
  @Input() exportReportConfig: PrivacyRequestsComplianceExportMenuData;

  @Output() onSort = new EventEmitter<Sort>();
  @Output() onPaginate = new EventEmitter<number>();
  @Output() localFilter = new EventEmitter<IPrivacyRequestsPagesApiBody | null>();
  @Output() globalFilter = new EventEmitter<IPrivacyRequestsGlobalFilterApiBody | null>();
  @Output() onGlobalFilterByCategoryName = new EventEmitter<number>();
  @Output() onManageConsentCategories = new EventEmitter<void>();

  readonly EConsentCategoryType = EConsentCategoryType;
  ccActionMenuOpen: boolean = false;
  statusActionMenuOpen: boolean = false;
  ccActionIndex = null;

  dataSource = new MatTableDataSource<IPrivacyRequestComplianceItem>();
  isFiltered = false;
  selectedRow: IPrivacyRequestComplianceItem;
  userIsReadOnly: boolean;
  geos: IConsentCategoryGeos;

  private isVisitorMode: boolean;
  private destroySubject = new Subject<void>();

  paginationState: { length: number, pageSize: number } = {
    length: 0,
    pageSize: 0
  };
  columns: string[] = [
    'domain',
    'geo',
    'request_count',
    'compliance_status',
    'consent_category_name',
    'page_count',
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('scrollTop', { read: ElementRef }) scrollTop: ElementRef;

  constructor(
    private filterBarService: AuditReportFilterBarService,
    private opModalService: OpModalService,
    private ccService: ConsentCategoriesService,
    private decimalPipe: DecimalPipe,
    private accountsService: AccountsService,
    private applicationChromeService: ApplicationChromeService,
  ) {
    this.userIsReadOnly = this.accountsService.userIsReadOnly();

    this.applicationChromeService.isVisitorMode$
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: isVisitorMode => this.isVisitorMode = isVisitorMode
      });

    this.ccService.geo$.pipe(
      take(1)
    ).subscribe(geos => {
      this.geos = geos;
    });
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe((sort: Sort) => this.onSort.emit(sort));
    this.paginator.page.subscribe(({pageIndex}) => this.onPaginate.emit(pageIndex));
    this.formatPaginator();
  }

  ngOnChanges() {
    if (this.apiFilters) {
      this.selectedRow = null;
      this.isFiltered = false;
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  prepareItems(data: IPrivacyRequestComplianceRow[]): IPrivacyRequestComplianceItem[] {
    return data?.map(item => {
      return {
        ...item,
        requestCount: formatNumber(item?.requestCount as number, 'en-US'),
        geo: item.requestGeo?.countryName,
        barChartSettings: {
          state: EFilterSpinnerState.None,
          displayPills: false,
          calcAsPercentage: true,
          displayPercentSymbol: false,
          textPosition: EBarChartTextPosition.Start
        },
        onPages: {
          chartData: [
            {
              name: item.requestDomain,
              colorClass: item.consentCategoryComplianceStatus ? item.consentCategoryComplianceStatus === EConsentCategoryType.APPROVED
                ? EChartColor.Green
                : EChartColor.Red
                : EChartColor.OPYellow,
              filtered: false,
              value: getPercent(+item.filteredPageCount, this.filteredPageCount),
              displayValue: item.filteredPageCount
            }
          ],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `tag-${ StringUtils.generateRandom(5) }-chart`,
        }
      };
    });
  }

  private scrollToTop() {
    this.scrollTop.nativeElement.scrollIntoView(true);
  }

  private setPagination(metadata) {
    if (!metadata) return;
    const { pagination } = metadata;
    this.paginationState.length = pagination.totalCount;
    this.paginationState.pageSize = pagination.pageSize;
  }

  isLineSelected(request: IPrivacyRequestComplianceItem) {
    return this.selectedRow === request;
  }

  filterByPage(item: IPrivacyRequestComplianceItem) {
    if (this.selectedRow === item) {
      this.localFilter.emit(null);
      this.selectedRow = null;
      this.isFiltered = false;

      return;
    }
    this.selectedRow = item;
    this.isFiltered = true;
    this.localFilter.emit({
      requestDomain: item.requestDomain,
      countryCode: item.requestGeo?.countryCode
    });
  }

  isStatusApproved({ consentCategoryComplianceStatus }): boolean {
    return consentCategoryComplianceStatus === EConsentCategoryType.APPROVED;
  }

  globalFilterByStatus({ consentCategoryComplianceStatus }) {
    this.filterBarService.addConsentCategoryStatusFilter(consentCategoryComplianceStatus);
  }

  globalFilterByCategoryName({ consentCategorySnapshotId }) {
    this.onGlobalFilterByCategoryName.emit(consentCategorySnapshotId);
  }

  globalFilterByCountry(item: IPrivacyRequestComplianceItem) {
    this.globalFilter.emit(item.requestGeo);
  }

  addToConsentCategoryClicked(item: IPrivacyRequestComplianceItem, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      this.addToConsentCategory(item);
    }
  }

  private addToConsentCategory(item: IPrivacyRequestComplianceItem) {
    // Format item before we pass into modal
    const countryName = item.requestGeo?.countryName;
    const locationId = Object
      .keys(this.geos.countriesById)
      .find(countryId => this.geos.countriesById[countryId].countryName === countryName);
    const formattedItem = {
      domain: item.requestDomain,
      domainType: EDomainType.EXACT,
      anyLocation: !locationId,
      locationIds: locationId ? [locationId] : []
    };

    this.opModalService
      .openModal(AddConsentCategoriesModalComponent, {
        data: {
          runId: this.runId,
          auditId: this.auditId,
          items: {
            cookies: [],
            tags: [],
            requestDomains: [formattedItem],
          }
        },
        autoFocus: false
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {});
  }

  private openFreeTrialAdModal() {
    const data = {
      type: EFreeTrialAdModalType.CONSENT_CATEGORY
    };

    this.opModalService.openModal(FreeTrialAdModalComponent, { data });
  }

  globalFilterByTagName({ tagAccount, tagId }) {
    this.filterBarService.addTagIdFilter(tagAccount, tagId);
  }

  globalFilterByDomain({ requestDomain }) {
    this.filterBarService.addReqDomainNameFilter(requestDomain);
  }

  getConsentCategoryFromAuditById(id: number) {
    return this.auditConsentCategories?.find(cc => cc.id === id);
  }

  getTooltip(row: IPrivacyRequestComplianceItem) {
    const consentCategoryId = this.getConsentCategoryFromRun(row)?.consentCategoryId;

    if (!consentCategoryId) {
      return 'This Consent Category has been deleted and cannot be edited.';
    } else if (!this.getConsentCategoryFromAuditById(consentCategoryId)) {
      return 'This Consent Category is no longer applied to future runs of this audit. Edit it from the Consent Category management screen.';
    }
  }

  private getConsentCategoryFromRun(row: IPrivacyRequestComplianceItem) {
    return this.runConsentCategories.find(cc => cc.consentCategorySnapshotId === row.consentCategorySnapshotId);
  }

  private formatPaginator(): void {
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }

  openAuditEditorToConsentCategoryStandard(event): void {
    event.stopPropagation();

    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      const data: IAuditEditorModalData = {
        auditId: this.auditId,
        runId: this.runId,
        step: 2,
        standardsTab: EStandardsTabs.ConsentCategories,
        disableNavigationButtons: true
      };

      this.opModalService.openFixedSizeModal(AuditEditorComponent, {disableClose: true, data}, 'op-audit-editor');
    }
  }
}
