import { ERuleMatchType } from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';
import { matchTypeToLabel } from '@app/components/reporting/rules/rules.utils';
import {
  IFailedRuleReport,
  IFailedRuleTagReport,
  INotAppliedRuleReport,
  IPassedRuleReport,
  IRuleReportItem,
  IRuleReportPageFilterResult,
  IRuleReportResult,
  IRuleReportSerializer,
  IRuleReportTagResult,
  IRuleReportVariableResult,
  RuleNotApplyingReason,
  RuleResultType,
} from '@app/components/reporting/rules/rule.models';

export class RuleReportSerializer implements IRuleReportSerializer {
  serializeNotAppliedRuleReports(
    results: Array<IRuleReportResult>
  ): Array<INotAppliedRuleReport> {
    let reports: Array<INotAppliedRuleReport> = [];
    if (!results) return reports;
    results.forEach(result => {
      if (result.resultType != RuleResultType.notApplied) return;
      let basedOnTagsItems = this.serializeNotAppliedRuleItemFromTagResults(
        result.tagResults
      );
      let basedOnPageFilterItems = this.serializeNotAppliedRuleItemFromPageFilters(
        result.pageFilterResults
      );
      reports.push({
        ruleId: result.ruleId,
        ruleName: result.name,
        items: basedOnTagsItems.concat(basedOnPageFilterItems)
      });
    });
    return reports;
  }

  serializePassedRuleReports(
    results: Array<IRuleReportResult>
  ): Array<IPassedRuleReport> {
    let reports: Array<IPassedRuleReport> = [];
    if (!results) return reports;
    results.forEach(result => {
      if (result.resultType != RuleResultType.passed) return;
      reports.push({
        ruleId: result.ruleId,
        ruleName: result.name
      });
    });
    return reports;
  }

  serializeFailedRuleReports(
    results: Array<IRuleReportResult>
  ): Array<IFailedRuleReport> {
    let reports: Array<IFailedRuleReport> = [];
    if (!results) return reports;
    results.forEach(result => {
      if (result.resultType != RuleResultType.failed) return;
      reports.push({
        ruleId: result.ruleId,
        ruleName: result.name,
        failedTags: this.serializeFailedRuleTags(result.tagResults),
        checkTimes: {
          actual: result.checkTimes.actual,
          expected: result.checkTimes.expected
        }
      });
    });
    return reports;
  }

  private serializeNotAppliedRuleItemFromTagResults(
    tagResults: Array<IRuleReportTagResult>
  ): Array<IRuleReportItem> {
    let items: Array<IRuleReportItem> = [];
    if (!tagResults) return items;
    tagResults.forEach(tag => {
      if (tag.resultType == RuleNotApplyingReason.missingTag) {
        items.push({
          name: tag.tag.name,
          expected: 'Present',
          actual: 'Not present'
        });
      }
      if (tag.resultType == RuleNotApplyingReason.incorrectVariables) {
        let variables = this.serializeRuleItemsFromVariable(tag.variables, tag.tag.id, tag.conditionId);
        items = items.concat(variables);
      }
    });
    return items;
  }

  private serializeNotAppliedRuleItemFromPageFilters(
    pageFilters: Array<IRuleReportPageFilterResult>
  ): Array<IRuleReportItem> {
    let items: Array<IRuleReportItem> = [];
    if (!pageFilters) return items;
    pageFilters.forEach(filter => {
      items.push({
        name: filter.filterType,
        expected: filter.expected,
        actual: filter.actual
      });
    });
    return items;
  }

  private serializeFailedRuleTags(
    tagResults: Array<IRuleReportTagResult>
  ): Array<IFailedRuleTagReport> {
    let reports: Array<IFailedRuleTagReport> = [];
    if (!tagResults) return reports;
    tagResults.forEach(tagResult => {
      reports.push({
        tagName: tagResult.tag.name,
        missed: tagResult.resultType == RuleNotApplyingReason.missingTag,
        items: this.serializeRuleItemsFromVariable(tagResult.variables, tagResult.tag.id, tagResult.conditionId)
      });
    });
    return reports;
  }

  private serializeRuleItemsFromVariable(
    variables: Array<IRuleReportVariableResult>,
    tagId: number,
    conditionId: number
  ): Array<IRuleReportItem> {
    let items: Array<IRuleReportItem> = [];
    if (!variables) return items;
    variables.forEach(variable => {
      items.push({
        name: variable.name,
        expected: this.serializeFailedRuleItemExpectedValues(
          variable.expectedValues,
          variable.matchType
        ),
        actual: this.serializeFailedRuleItemActualValues(
          variable.actualValue,
          variable.resultType
        ),
        conditionId: conditionId,
        snapshotId: variable.snapshotId,
        tagId: tagId
      });
    });
    return items;
  }

  private serializeFailedRuleItemActualValues(
    actualValue: string,
    resultType: string
  ): string {
    return (
      actualValue ||
      (resultType == 'MissingVariable' ? 'Missing variable' : actualValue)
    );
  }

  private serializeFailedRuleItemExpectedValues(
    expectedVariables: Array<String>,
    matchType: ERuleMatchType
  ): string {
    let varsWithMatchers = [];
    if (!expectedVariables) return '';
    if (!expectedVariables.length) {
      if (matchType == ERuleMatchType.Equals) {
        return 'Missing Variable';
      }
      return matchTypeToLabel(matchType);
    }
    expectedVariables.forEach(expectations => {
      varsWithMatchers.push(matchTypeToLabel(matchType) + expectations);
    });
    return this.serializeItemsWithComma(varsWithMatchers);
  }

  serializeItemsWithComma(items: Array<string>) {
    let result = '';
    for (let i = 0; i < items.length; i++) {
      result += items[i];
      if (items.length != 1 && i != items.length - 1) result += ', ';
    }
    return result;
  }
}
