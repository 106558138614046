export enum EIngestorState {
  CREATE,
  UPLOAD,
  FINISH,
  ERROR
}

export enum EIngestorValidationErrorCodes {
  DirectoriesNotSupported,
  InvalidFileType,
  FileSize
}
