import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailInboxesService } from '@app/components/email-inboxes/email-inboxes.service';
import { finalize } from 'rxjs/operators';
import { IEmailInbox } from '@app/components/email-inboxes/email-inboxes.models';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Account } from '@app/moonbeamModels';

@Component({
  selector: 'op-email-inbox-messages',
  templateUrl: './email-inbox-messages.component.html',
  styleUrls: ['./email-inbox-messages.component.scss']
})
export class EmailInboxMessagesComponent implements OnInit {
  id: number;
  inbox: IEmailInbox = null;
  loading: boolean = false;
  account: Account = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private emailInboxesService: EmailInboxesService,
    private authenticationService: AuthenticationService,
  ) {
    this.fillDataFromRouter();
  }

  private getAccount(): void {
    this.authenticationService.getAccount().subscribe(account => {
      this.account = account;
    });
  }

  private fillDataFromRouter(): void {
    this.id = this.route.snapshot.params.id;

    if (this.router.getCurrentNavigation()?.extras?.state?.inbox) {
      this.inbox = this.router.getCurrentNavigation().extras.state.inbox;
    } else {
      this.getEmailInboxData();
    }
  }

  private getEmailInboxData(): void {
    this.loading = true;
    this.emailInboxesService.getEmailInbox(this.id)
      .pipe(finalize(() => this.loading = false))
      .subscribe(inbox => {
        this.inbox = inbox;
      });
  }

  ngOnInit(): void {
    this.getAccount();
  }
}
