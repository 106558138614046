import { Component, Input } from '@angular/core';
import { TextareaPlaceholder } from './email-inbox-editor-step-1.constants';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EEmailInboxModalType } from '../email-inbox-editor.constants';

@Component({
  selector: 'op-email-inbox-editor-step-1',
  templateUrl: './email-inbox-editor-step-1.component.html',
  styleUrls: ['./email-inbox-editor-step-1.component.scss']
})
export class EmailInboxEditorStep1Component {

  @Input() formGroup: UntypedFormGroup;
  @Input() modalType: EEmailInboxModalType;
  @Input() userEmail: string;
  @Input() emailInboxAddress: string = '';

  EEmailInboxModalType = EEmailInboxModalType;
  textareaPlaceholder = TextareaPlaceholder;

  get notes(): UntypedFormControl {
    return this.formGroup.get('notes') as UntypedFormControl;
  }
}
