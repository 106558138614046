import { Component, Input } from '@angular/core';
import { IStatusBubble } from './status-bubble-timeline.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'status-bubble-timeline',
  templateUrl: './status-bubble-timeline.component.html',
  styleUrls: ['./status-bubble-timeline.component.scss']
})
export class StatusBubbleTimelineComponent {
  @Input() data: IStatusBubble[];
}
