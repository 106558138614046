<op-accordion>
  <mat-accordion displayMode="flat" multi="true" hideToggle="true">
    <mat-expansion-panel *ngFor="let tag of tags; trackBy: trackByFn" hideToggle="true" [attr.op-selector]="'match-tag-' + tag.id">
      <mat-expansion-panel-header op-selector="tag-header">
        <mat-panel-title>
          <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
          <img class="tag-icon" src="{{ tag.icon }}" alt="tag.name" />
          {{ tag.name }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="tag-content">
        <op-list-editor [items]="tag.variables" [options]="tagOptionsMap.get(tag.id)" (onChange)="updateVariables()"></op-list-editor>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</op-accordion>


<div *ngIf="!tags || !tags.length">
  No tags to select
</div>
