<div class="account-details">
  <form [formGroup]="accountDetailsForm" *ngIf="accountDetailsForm">
    <div class="cols global-account-info">
      <div class="field account-name">
        <mat-form-field appearance="outline">
          <mat-label>Account Name</mat-label>
          <input matInput placeholder="Account Name" formControlName="name">
          <mat-error *ngIf="name.errors?.required">This field is required</mat-error>
        </mat-form-field>
      </div>
      <div class="field type">
        <mat-form-field appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of types; let i = index" [value]="i">{{ ETypes[type] }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="field status">
        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let status of statuses" [value]="status.key">{{ status.value }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="cols">
      <div class="field notes">
        <mat-form-field appearance="outline">
          <mat-label>Notes</mat-label>
          <textarea matInput placeholder="Notes" formControlName="notes"></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
