<ng-container *ngIf="config">
  <span class="logic-info"
        [matTooltip]="getTooltip()"
        matTooltipShowDelay="750"
        matTooltipPosition="above">
    <span>{{ config.reportName }} - {{ config.metricName }} </span>
    <i class="sign">
      {{ config.operator?.sign }}
    </i>
    <i> {{ config.value }} {{ config.unit }} {{ config.postString }}</i>
    <ng-container *ngIf="config.filtersCount > 0">
      <span class="simplified-filters-info" *ngIf="!showFiltersList; else extendedFiltersInfo">
        ({{ config.filtersCount }} <mat-icon class="filter-icon" svgIcon="filter-alt-white"></mat-icon>)
      </span>
    </ng-container>
  </span>

  <ng-template #extendedFiltersInfo>
    <span class="extended-filters-info" (click)="$event.stopPropagation()">
      <mat-chip-listbox>
        <mat-chip-option class="small"
                         color="primary"
                         [matMenuTriggerFor]="alertFiltersMenu"
                         [class.selected]="matMenuTrigger?.menuOpen"
                         highlighted>
          {{ config.filtersCount }}
          <mat-icon class="filter-icon" svgIcon="filter-alt-white"></mat-icon>
          <mat-icon class="expand-more-icon">expand_more</mat-icon>
        </mat-chip-option>
      </mat-chip-listbox>
    </span>

    <!-- Hidden filters menu -->
    <mat-menu #alertFiltersMenu="matMenu"
              xPosition="after"
              class="alert-logic-details-menu"
              backdropClass="no-filter"
    >
      <mat-chip-listbox class="mat-chip-list-stacked">
        <mat-chip-option class="small"
                         *ngFor="let filter of filters"
                         color="primary"
                         highlighted>
          <span *ngIf="filter.display" [innerHTML]="filter.display"></span>&nbsp;
          <span [innerHTML]="getFilterLabel(filter)"></span>
          <span class="chip-post-display" [innerHTML]="filter.postDisplay || ''"></span>
        </mat-chip-option>
      </mat-chip-listbox>
    </mat-menu>
  </ng-template>
</ng-container>
