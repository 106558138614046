import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag, IUiTagCategory } from '@app/components/tag-database';

@Component({
  selector: 'op-tag-picker',
  templateUrl: './op-tag-picker.component.html',
  styleUrls: ['./op-tag-picker.component.scss'],
})
export class OpTagPickerComponent implements OnChanges {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;
  @Input() tags: IUiTag[] = [];
  @Input() clearOnSelect: boolean = false;
  @Input() placeholder: string = 'Tag Name';
  @Input() label: string = 'Tag Name';
  @Input() appearance?: string = 'underline';

  @Output() selected: EventEmitter<IUiTag> = new EventEmitter();

  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

  searchText: string;

  constructor(private uiTagService: UiTagService) {}

  onOpen = () => this.searchText = '';
  onClose = () => {
    this.searchText = null;
    this.ngSelect.searchInput.nativeElement.blur();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tags && changes.tags.currentValue) {
      this.tags.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  searchItem(searchTerm: string, item: IUiTag): boolean {
    this.searchText = searchTerm;
    const lowerSearch = searchTerm.toLowerCase();
    return item.name.toLowerCase().indexOf(lowerSearch) >= 0 ;
  }

  onChange(tag: IUiTag) {
    if (tag) this.selected.emit(tag);
    if (tag && this.clearOnSelect) this.ngSelect.clearModel();
    this.ngSelect.searchInput.nativeElement.blur();
  }

  getTagCategory(id: number): IUiTagCategory {
    return this.uiTagService.getTagCategory(id);
  }
}
