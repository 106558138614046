import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, AbstractControl } from '@angular/forms';
import { IMaskedInput } from '@app/components/actions/actions-preview/masked-input/masked-input.models';
import { EInputTypes } from '@app/components/actions/actions-preview/masked-input/masked-input.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MaskedInputComponent),
    multi: true
  }]
})
export class MaskedInputComponent implements ControlValueAccessor {

  @Input() formControl: AbstractControl;

  value: IMaskedInput;

  onChanged = () => {};
  onTouched = () => {};

  writeValue(val: IMaskedInput): void {
    this.value = this.initMaskedInput(val);
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private initMaskedInput(value: IMaskedInput): IMaskedInput {
    if (value.maskedValue && (value.maskedValue !== value.viewedValue)) {
      value.maskedInputType = EInputTypes.password;
      value.viewedValue = '**********';
    } else {
      value.maskedValue = '';
      value.maskedInputType = EInputTypes.text;
    }

    return value;
  }

  prepareToEdit(): void {
    this.value.maskedInputType = EInputTypes.text;
    if (!this.value.isChangedValue) {
      this.value.viewedValue = '';
    }
  }

  applyChanges(): void {
    if (!this.value.viewedValue && this.value.maskedValue && !this.value.isChangedValue) {
      this.value.maskedInputType = EInputTypes.password;
      this.value.viewedValue = '**********';
    } else {
      this.value.isChangedValue = true;
      this.value.maskedValue = this.value.viewedValue;
    }

    this.formControl.markAsTouched();
    this.formControl.updateValueAndValidity();
  }

}
