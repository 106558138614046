import * as Sentry from "@sentry/angular";
import { environment } from "./environments/environment";

const sentry = new Sentry.SentryErrorHandler();

export function globalExceptionHandler() {
  return function exceptionHandler(exception: Error) {
    if (environment.sentryConfig) {
      sentry.handleError(exception);
    } else {
      console.error('ANGULARJS GLOBAL EXCEPTION HANDLER', exception);
    }
  };
}
