import { Component, Input } from '@angular/core';
import { ISupportContact } from '@app/components/usage-v2/usage-v2.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-help-widget',
  templateUrl: './usage-help-widget.component.html',
  styleUrls: ['./usage-help-widget.component.scss']
})
export class UsageHelpWidgetComponent {
  @Input() contacts: ISupportContact[];
}
