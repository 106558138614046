import {
  AfterViewInit,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import * as d3 from 'd3';
import { Axis, AxisDomain, ScaleBand, ScaleLinear, Selection, Series, SeriesPoint } from 'd3';
import {
  IStackedBarChartBarInput,
  IStackedBarChartInput,
  IStackedBarChartSeries,
  IStackedBarChartSeriesPoint
} from './vertical-stacked-bar-chart.models';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IChartDataPoint } from '@app/components/audit-reports/audit-report/audit-report.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'vertical-stacked-bar-chart',
  templateUrl: './vertical-stacked-bar-chart.component.html',
  styleUrls: ['./vertical-stacked-bar-chart.component.scss']
})
export class VerticalStackedBarChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  // in order to have multiple instances on a page we
  // need a unique identifier to draw multiple SVGs
  @Input() uniqueIdentifier: string;
  @Input() inputData: IStackedBarChartInput;

  @Input() drawYAxis: boolean = true;
  @Input() drawYAxisLabel: boolean = false;
  @Input() drawXAxis: boolean = true;
  @Input() drawXAxisLabels: boolean = true;
  @Input() drawXAxisSubLabels: boolean = true;
  @Input() drawBarSegmentLabels: boolean = true;
  @Input() drawPills: boolean = false;

  @Input() marginTop: number;
  @Input() marginRight: number;
  @Input() marginBottom: number;
  @Input() marginLeft: number;

  private resize$ = new Subject();
  private destroy$ = new Subject();

  private margin: { top: number; right: number; bottom: number; left: number };
  private readonly colors = ['#f34145', '#1ca65a', '#ec7d0e'];
  private readonly BAR_MAX_WIDTH = 100; //px
  private readonly BAR_MIN_WIDTH = 100; //px
  private readonly BAR_MIN_HEIGHT = 2; //px

  private svgHeight: number;
  private svgWidth: number;
  private chartsHeight: number; // svgHeight - top and bottom margins
  private chartsWidth: number; // svgWidth - left and right margins
  private scaleRange: { xStart: number; xEnd: number; yStart: number; yEnd: number };
  private normalisedBandWidth: number;
  private xBandStartNormalised = xBandStartOriginal => {
    // xBandStartOriginal is this.chart.x(seriesPoint.data.label)
    // this.chart.x(label) returns X coordinate of the start of corresponding band, calculating X coord of it's center
    const xBandCenterOriginal = xBandStartOriginal + this.chart.x.bandwidth() / 2;
    return xBandCenterOriginal - this.normalisedBandWidth / 2;
  }

  // tooltip variables
  tooltip: Selection<any, any, any, any>;
  tooltipClass: string = 'horizontal-stacked-bar-chart-tooltip';
  tooltipWidth: number;
  tooltipHeight: number;
  windowWidth: number = window.innerWidth;

  private chart: {
    container: Selection<any, any, any, any>;

    stackedData: Array<IStackedBarChartSeries<IStackedBarChartBarInput, string>>;

    x: ScaleBand<any>;
    y: ScaleLinear<any, any, any>;

    rendered: {
      svg: Selection<any, any, any, any>;
      barsContainer: Selection<any, any, any, any>;
      barLayers: Selection<any, IStackedBarChartSeries<IStackedBarChartBarInput, string>, any, any>;
      barSegments: Selection<any, IStackedBarChartSeriesPoint<IStackedBarChartBarInput, string>, any, any>;
      barSegmentLabels: Selection<any, IStackedBarChartSeriesPoint<IStackedBarChartBarInput, string>, any, any>;

      xAxisContainer: Selection<any, any, any, any>;
      xAxisLabels: Selection<any, IStackedBarChartBarInput, any, any>;
      xAxisSubLabels: Selection<any, IStackedBarChartBarInput, any, any>;
      yAxisContainer: Selection<any, any, any, any>;
      yAxis: Axis<AxisDomain>;

      emptyBars: Selection<any, IStackedBarChartBarInput, any, any>;
      emptyBarLabels: Selection<any, IStackedBarChartBarInput, any, any>;
      emptyBarContainers: Selection<any, IStackedBarChartBarInput, any, any>;
    }
  } = {
    rendered: {}
  } as any;

  pills: IChartDataPoint[] = [];

  hasActiveFilter: boolean;

  private initHappened: boolean = false;

  ngOnInit(): void {
    this.setMargins();
    this.resize$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.initChart();
    });
    this.initHappened = true;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.initChart(), 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initHappened) {
      this.initChart();
      // const newInputData = changes.inputData.currentValue;
      // this.chart.stackedData = this.createStack(newInputData);
      // this.initScales(this.chart.stackedData);
      // this.chart.rendered.barLayers?.data(newInputData);
      // this.chart.rendered.barSegments?.data(newInputData);
      // this.chart.rendered.barSegmentLabels?.data(newInputData);
      // console.log('New Input Data', newInputData);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private initChart(): void {
    this.initContainer();
    this.generatePills();

    this.chart.stackedData = this.createStack(this.inputData);
    this.initScales(this.chart.stackedData);
    this.drawChart(this.chart.stackedData);
    this.drawAxes(this.inputData.yAxisLabel);
  }

  private generatePills() {
    this.pills = this.inputData.layerDefinitions.map(ld => ({
      name: ld.label,
      colorClass: ld.colorClass,
      filtered: false
    }));
  }

  private initContainer() {
    this.chart.container = d3.select(`.svg-container-${this.uniqueIdentifier}`).html('');
    this.svgHeight = parseInt(this.chart.container.style('height'));
    this.svgWidth = parseInt(this.chart.container.style('width'));

    this.chartsHeight = this.svgHeight - this.margin.top - this.margin.bottom;
    this.chartsWidth = this.svgWidth - this.margin.left - this.margin.right;

    this.scaleRange = {
      xStart: this.margin.left,
      xEnd: this.svgWidth - this.margin.right,
      yStart: this.svgHeight - this.margin.bottom,
      yEnd: this.margin.top
    };

    this.chart.rendered.svg = this.chart.container
      .append('svg')
      .classed('vertical-stacked-bar-chart-svg', true)
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    this.chart.rendered.barsContainer = this.chart.rendered.svg
      .append('g')
      .classed('layers', true);
  }

  private initScales(stackedData: Array<IStackedBarChartSeries<IStackedBarChartBarInput, string>>) {

    this.chart.x = d3.scaleBand()
      .rangeRound([this.scaleRange.xStart, this.scaleRange.xEnd])
      .paddingInner(0.2) // edit the inner padding value in [0,1]
      .paddingOuter(0.2) // edit the outer padding value in [0,1]
      .align(0.5); // edit the align: 0 is aligned left, 0.5 centered, 1 aligned right.

    this.chart.x.domain(this.inputData.bars.map(d => {
      return d.id;
    }));

    // by default scaleBand() would stretches bands to all range, there is no way to configure max width of the band
    // hence we have to adapt it ourselves considering the custom max band width
    this.normalisedBandWidth = Math.min(this.chart.x.bandwidth(), this.BAR_MAX_WIDTH);

    this.xBandStartNormalised = xBandStartOriginal => {
      // this.chart.x(label) returns X coordinate of the start of corresponding band, calculating X coord of it's center
      const xBandCenterOriginal = xBandStartOriginal + this.chart.x.bandwidth() / 2;
      return xBandCenterOriginal - this.normalisedBandWidth / 2;
    };

    this.chart.y = d3.scaleLinear()
      .range([this.scaleRange.yStart, this.scaleRange.yEnd]);

    this.chart.y.domain([0, +d3.max(stackedData, (d: Series<IStackedBarChartBarInput, string>) => {
      return d3.max(d, (d: SeriesPoint<IStackedBarChartBarInput>) => {
        return d[1]; //Corresponds to y1, the upper value (topline).
      });
    })]);
  }

  private drawAxes(yAxisLabel: string) {
    if (this.drawYAxis) {
      this.chart.rendered.yAxis = d3.axisLeft(this.chart.y)
        .tickSize(-this.chartsWidth)
        .tickPadding(20)
        .tickSizeOuter(0);

      this.chart.rendered.yAxisContainer = this.chart.rendered.svg.append('g')
        .attr('transform', `translate(${this.margin.left}, 0)`)
        .attr('class', 'y-axis-container')
        .call(this.chart.rendered.yAxis)
        .call(g => g.select('.domain').remove());

      if (this.drawYAxisLabel) {
        // Add label
        this.chart.rendered.svg
          .append('text')
          .call(g => g.select('.domain').remove())
          .attr('class', 'y-axis-label')
          .text(yAxisLabel);
      }

      // Set opacity on horizontal grid lines
      this.chart.rendered.yAxisContainer
        .selectAll('.tick line')
        .attr('opacity', 0.1);
      // Set color of tick marks
      this.chart.rendered.yAxisContainer
        .selectAll('text')
        .attr('class', 'y-axis-text');
    }

    if (this.drawXAxis) {
      this.chart.rendered.xAxisContainer = this.chart.rendered.svg.append('g')
        .classed('x-axis-container', true);

      if (this.drawXAxisLabels) {
        this.chart.rendered.xAxisLabels = this.chart.rendered.xAxisContainer
          .selectAll('.x-axis-label')
          .data(this.inputData.bars)
          .enter()
          .append('text')
          .attr('y', this.scaleRange.yStart + this.margin.bottom / 2)
          .attr('x', (d, i) => this.xBandStartNormalised(this.chart.x(d.id)))
          .attr('dx', this.normalisedBandWidth / 2)
          .classed('x-axis-label', true)
          .text(d => d.label);
      }

      if (this.drawXAxisSubLabels) {
        this.chart.rendered.xAxisSubLabels = this.chart.rendered.xAxisContainer
          .selectAll('.x-axis-sub-label')
          .data(this.inputData.bars)
          .enter()
          .append('text')
          .attr('y', this.scaleRange.yStart + this.margin.bottom / 2)
          .attr('x', (d, i) => this.xBandStartNormalised(this.chart.x(d.id)))
          .attr('dx', this.normalisedBandWidth / 2)
          .classed('x-axis-sub-label', true)
          .text(d => d.subLabel);
      }
    }
  }

  private createStack(data: IStackedBarChartInput): Array<IStackedBarChartSeries<IStackedBarChartBarInput, string>> {
    /***
    * Set min-height bar
    * */

    const maxValue = data.bars.reduce((max, bar) => {
      const value = bar.layerValues.failedRuleCount + bar.layerValues.passedRuleCount + bar.layerValues.notAppliedRuleCount;
      return max < value ? value : max;
    }, 0);

    const scaleHeight = this.scaleRange.yStart - this.scaleRange.yEnd;
    const pxPerPoint = scaleHeight / maxValue;
    const minValue = (this.drawBarSegmentLabels ? this.getTextHeight() : this.BAR_MIN_HEIGHT) / pxPerPoint;

    const stackGenerator = d3.stack<IStackedBarChartBarInput>()
      .keys(data.layerDefinitions.map(sd => sd.key))
      .value((obj, key) => {

        if (obj.layerValues[key] > 0) {
          if (minValue > obj.layerValues[key]) {
            return minValue;
          }
        }

        return obj.layerValues[key];
      });

    const stackedSeries: Array<Series<IStackedBarChartBarInput, string>> = stackGenerator(data.bars);
    const enrichedStackedSeries = stackedSeries as Array<IStackedBarChartSeries<IStackedBarChartBarInput, string>>;
    enrichedStackedSeries.forEach(series => {
      series.colorClass = data.layerDefinitions.find(ld => ld.key === series.key).colorClass;
      return series.forEach(seriesPoint => {
        seriesPoint.seriesKey = series.key;
      });
    });

    return enrichedStackedSeries;
  }

  private drawChart(stackedData: Array<IStackedBarChartSeries<IStackedBarChartBarInput, string>>) {
    this.chart.rendered.barLayers = this.chart.rendered.barsContainer
      .selectAll('.layer')
      .data(stackedData)
      .enter()
      .append('g')
      .attr('class', series => `layer ${series.colorClass}`);

    this.chart.rendered.barSegments = this.chart.rendered.barLayers
      .selectAll('rect')
      .data(d => d)
      .enter()
      .append('rect')
      .attr('y', d => this.chart.y(d[1]))
      .attr('x', d => this.xBandStartNormalised(this.chart.x(d.data.id)))
      .attr('width', this.normalisedBandWidth)
      .attr('height', d => {
        const hasRules = this.chart.y(d[0]) === 0 && this.chart.y(d[1]) === 0;
        if (!this.drawBarSegmentLabels || !hasRules) return this.chart.y(d[0]) - this.chart.y(d[1]);

        const textHeight = this.getTextHeight();
        const barHeight = this.chart.y(d[0]) - this.chart.y(d[1]);

        return barHeight > textHeight ? barHeight : textHeight + 2;
      });

    if (this.drawBarSegmentLabels) {
      this.chart.rendered.barSegmentLabels = this.chart.rendered.barLayers
        .selectAll('.layer')
        .data(d => d)
        .enter()
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'middle')
        .attr('y', d => {
          const textHeight = this.getTextHeight();
          const computedBarHeight = this.chart.y(d[0]) - this.chart.y(d[1])
          const barHeight = computedBarHeight > textHeight ? computedBarHeight : textHeight + 2;
          const offset = 25;

          return barHeight > (textHeight + (offset * 2))
            ? this.chart.y(d[1]) + offset
            : this.chart.y(d[1]) + (barHeight / 2);
        })
        .attr('x', d => this.xBandStartNormalised(this.chart.x(d.data.id)))
        .attr('dx', this.normalisedBandWidth / 2)
        .classed('layer-bar-label', true)
        .text(d => d.data.layerValues[d.seriesKey] || '');
    }

    // tooltip
    // only create if it's not already on the page
    if(!d3.select(`.${this.tooltipClass}`).node()) this.createTooltip();

    // get reference to the DOM element
    this.tooltip = d3.select(`.${this.tooltipClass}`);

    this.chart.rendered.barSegments.on('mouseover', (e: MouseEvent, d) => {
      setTimeout(() => this.getTooltipDimensions(), 0);
      this.showTooltip(d.data);
    });

    this.chart.rendered.barSegments.on('mousemove', (e: MouseEvent, d) => {
      this.handleTooltipPosition(e);
    });

    this.chart.rendered.barSegments.on('mouseout', (e: MouseEvent, d) => {
      this.hideTooltip();
    });

    this.drawEmptyBars();
  }

  private getTextHeight(): number {
    const text = this.chart.rendered.svg
      .append('text')
      .text('1');

    const height = text
      .node()
      .getBoundingClientRect()
      .height;

    text.remove();

    return height;
  }

  private drawEmptyBars() {
    const emptyBars = this.inputData.bars.filter(b => {
      const sumOfValues = Object.values(b.layerValues).reduce((acc, v) => acc + v, 0);
      return sumOfValues === 0;
    });
    this.chart.rendered.emptyBarContainers = this.chart.rendered.barsContainer.selectAll('.empty-bar-container')
      .data(emptyBars)
      .enter()
      .append('rect')
      .attr('y', this.scaleRange.yEnd)
      .attr('x', d => this.xBandStartNormalised(this.chart.x(d.id)))
      .attr('width', this.normalisedBandWidth)
      .attr('height', this.chartsHeight)
      .style('opacity', '0')
      .classed('empty-bar-container', true);
    this.chart.rendered.emptyBars = this.chart.rendered.barsContainer.selectAll('.empty-bar-rect')
      .data(emptyBars)
      .enter()
      .append('rect')
      .attr('y', this.scaleRange.yStart)
      .attr('x', d => this.xBandStartNormalised(this.chart.x(d.id)))
      .attr('width', this.normalisedBandWidth)
      .attr('height', '1px')
      .classed('empty-bar-rect', true);
    const emptyBarLabels = this.chart.rendered.barsContainer.selectAll('.empty-bar-text')
      .data(emptyBars)
      .enter()
      .append('text')
      .classed('empty-bar-text', true)
      .attr('y', this.scaleRange.yEnd + this.chartsHeight / 2)
      .attr('x', d => this.xBandStartNormalised(this.chart.x(d.id)))
      .attr('dx', this.normalisedBandWidth / 2)
      .text(d => this.inputData.emptyBarText || '');
    if (this.normalisedBandWidth > 55) {
      this.chart.rendered.emptyBarLabels = emptyBarLabels;
    }
  }

  private numberOrDefault(v: number, defaultV: number) {
    return typeof v === 'number' ? v : defaultV;
  }

  private setMargins() {
    this.margin = {
      top: this.numberOrDefault(this.marginTop, 15),
      right: this.numberOrDefault(this.marginRight, 10),
      bottom: this.numberOrDefault(this.marginBottom, 45),
      left: this.numberOrDefault(this.marginLeft, 40)
    };
  }

  private createTooltip(): void {
    d3
      .select('body')
      .append('div')
      .attr('class', this.tooltipClass);
  }

  getTooltipDimensions(): void {
    this.tooltipWidth = this.tooltip.node().getBoundingClientRect().width + 14;
    this.tooltipHeight = this.tooltip.node().getBoundingClientRect().height + 8;
  }

  showTooltip(d): void {
    this.tooltip.html(`${d.label} ${d.subLabel}`);

    this.tooltip
      .style('display', 'flex')
      .transition()
      .duration(150)
      .style('opacity', 1);
  }

  hideTooltip(): void {
    this.tooltip
      .style('opacity', 0)
      .style('display', 'none');
  }

  handleTooltipPosition(e: MouseEvent): void {
    const horizPos = e.clientX;
    const vertPos = e.clientY;
    const leftEdge = 0;
    const rightEdge = this.windowWidth;
    const edgePadding = 5;

    // left edge of window
    if ((horizPos - (this.tooltipWidth / 2)) <= leftEdge + edgePadding) {
      this.tooltip
        .style('left', `${(this.tooltipWidth / 2) + edgePadding}px`)
        .style('top', `${vertPos - 35}px`);
    }

    // right edge of window
    else if ((horizPos + (this.tooltipWidth / 2)) >= rightEdge - edgePadding) {
      this.tooltip
        .style('left', `${rightEdge - (this.tooltipWidth)}px`)
        .style('top', `${vertPos - 35}px`);
    }

    // default behavior in middle of window
    else {
      this.tooltip
        .style('left', `${horizPos - (this.tooltipWidth / 2)}px`)
        .style('top', `${vertPos - 35}px`);
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
  }
}
