import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpCodeHighlighterComponent } from './op-code-highlighter.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClipboardModule } from 'ngx-clipboard';
import { MatIconModule } from '@angular/material/icon';
import { OpModalModule } from '../shared/components/op-modal/op-modal.module';
import { OpCodeHighlighterFullScreenComponent } from './op-code-highlighter-full-screen/op-code-highlighter-full-screen.component';

@NgModule({
  declarations: [
    OpCodeHighlighterComponent,
    OpCodeHighlighterFullScreenComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    ClipboardModule,
    MatIconModule,
    OpModalModule
  ],
  exports: [
    OpCodeHighlighterComponent
  ],
  providers: [],
})
export class OpCodeHighlighterModule {}