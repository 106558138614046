import {
  IRule,
  IRuleV3,
  IRuleV3ActionRules,
  IRuleV3UpdateRequest,
  IRuleV3WebJourneyRules
} from '@app/components/rules/rules.models';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  WebJourneyV3RulesService
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3-rules.service';
import { RulesService } from '@app/components/rules/rules.service';
import { Injectable } from '@angular/core';
import {
  IRuleSnapshotId,
  ITagVariableSnapshotId
} from '../web-journey-report/web-journey-results/web-journey-results.models';
import { IRuleReportItem } from '../reporting/rules/rule.models';
import { EToastMessages } from './easy-rule-edit.constants';
import { HttpErrorResponse } from '@angular/common/http';
import { ActionSetLibraryService } from '../action-set-library/action-set-library.service';
import { DiscoveryAuditService } from '../domains/discoveryAudits/discoveryAuditService';
import { IAuditModel } from '@app/components/modals/modalData';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import {
  ERuleTagVariableSelectorType
} from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';

export type OnClose = (isChanged?: boolean) => void;

@Injectable({
  providedIn: 'root'
})
export class EasyRuleEditService {

  constructor(
    private rulesService: RulesService,
    private modalService: OpModalService,
    private snackbarService: SnackbarService,
    private webJourneyV3RulesService: WebJourneyV3RulesService,
    private actionSetService: ActionSetLibraryService,
    private auditService: DiscoveryAuditService
  ) {
  }

  editRule(snapshotRuleId: number, onClose?: OnClose) {
    return this.rulesService.getOriginalRuleId(snapshotRuleId).toPromise()
      .then(({originalRuleId}) => {
          this.openOriginRule(originalRuleId, onClose);
        },
        (error: HttpErrorResponse) => {
          this.snackbarService.openErrorSnackbar(EToastMessages.NoLongerExistsEdit);
        }
      );
  }

  private openOriginRule(ruleId: number, onClose?: OnClose) {
    this.openEditRuleModal(ruleId, onClose);
  }

  removeWebJourneyRule(journeyId: number, ruleId: number, actionId?: number, actionSetId?: number): void {
    this.rulesService.getOriginalRuleId(ruleId).subscribe((ruleSnapshot: IRuleSnapshotId) => {
        if (actionSetId) {
          this.removeActionSetActionRule(actionSetId, actionId, ruleSnapshot.originalRuleId);
        } else {
          actionId
            ? this.removeWebJourneyActionRule(journeyId, ruleSnapshot.originalRuleId, actionId)
            : this.removeWebJourneyGlobalRule(journeyId, ruleSnapshot.originalRuleId);
        }
      },
      _ => {
        this.snackbarService.openErrorSnackbar(EToastMessages.NoLongerExistsRemove);
      }
    );
  }

  private removeWebJourneyGlobalRule(journeyId: number, originalRuleId: number): void {
    this.webJourneyV3RulesService.getJourneyRules(journeyId).subscribe((journeyRules: IRuleV3WebJourneyRules) => {
      const matchingRule = journeyRules.webJourneyRules.find((rule: IRuleV3) => rule.id === originalRuleId);

      if (matchingRule) {
        const ruleIndex = journeyRules.webJourneyRules.indexOf(matchingRule);
        journeyRules.webJourneyRules.splice(ruleIndex, 1);
        const updatedRules = this.formatRulesForUpdate(journeyRules);
        this.webJourneyV3RulesService.updateJourneyRules(journeyId, updatedRules).subscribe(
          (rules: IRuleV3WebJourneyRules) => this.snackbarService.openSuccessSnackbar(EToastMessages.RemovedOnNextRun),
          (error: HttpErrorResponse) => this.snackbarService.openErrorSnackbar(EToastMessages.NotFoundInJourney)
        );
      } else {
        this.snackbarService.openErrorSnackbar(EToastMessages.NotFoundOnAction);
      }
    });
  }

  private removeWebJourneyActionRule(journeyId: number, originalRuleId: number, actionId: number): void {
    this.webJourneyV3RulesService
      .getJourneyActionRules(journeyId, actionId)
      .subscribe((currentActionRules: IRuleV3[]) => {
        const matchingRule = currentActionRules.find((rule: IRuleV3) => rule.id === originalRuleId);

        if (matchingRule) {
          const updatedRules = currentActionRules
            .filter((rule: IRuleV3) => rule.id !== originalRuleId)
            .map((rule: IRuleV3) => rule.id);

          this.webJourneyV3RulesService
            .updateJourneyActionRules(journeyId, actionId, updatedRules)
            .subscribe(
              (rule: IRuleV3[]) => this.snackbarService.openSuccessSnackbar(EToastMessages.RemovedOnNextRun),
              (error: HttpErrorResponse) => this.snackbarService.openErrorSnackbar(EToastMessages.GeneralError)
            );
        } else {
          this.snackbarService.openErrorSnackbar(EToastMessages.NotFoundOnAction);
        }
      });
  }

  private removeActionSetActionRule(actionSetId: number, actionId: number, ruleId: number) {
    this.actionSetService.getActionSetActionRules(actionSetId, actionId).subscribe((rules: IRuleV3[]) => {
      const updatedRules = rules.filter((rule: IRuleV3) => rule.id !== ruleId).map((rule: IRuleV3) => rule.id);
      this.actionSetService.updateActionSetActionRules(actionSetId, actionId, updatedRules).subscribe(
        (response: IRuleV3[]) => this.snackbarService.openSuccessSnackbar(EToastMessages.RemovedOnNextRun),
        (error: HttpErrorResponse) => this.snackbarService.openErrorSnackbar(EToastMessages.GeneralError)
      );
    });
  }

  private formatRulesForUpdate(rules: IRuleV3WebJourneyRules): IRuleV3UpdateRequest {
    const webJourneyRules = rules.webJourneyRules.map((rule: IRuleV3) => rule.id);
    const actionRules = rules.actionRules.map((rule: IRuleV3ActionRules) => {
      return {
        actionId: rule.actionId,
        rules: rule.rules.map((rule: IRuleV3) => rule.id)
      };
    });

    return {webJourneyRules, actionRules};
  }

  setAsExpected(ruleReportItem: IRuleReportItem): void {
    if (ruleReportItem.snapshotId === 0 && ruleReportItem.name === 'account') {
      this.rulesService.getOriginalRuleIdFromTagId(ruleReportItem.conditionId).subscribe(
        (response: ITagVariableSnapshotId) => {
          this.updateRule(response, ruleReportItem, true);
        },
        (error: HttpErrorResponse) => {
          this.snackbarService.openErrorSnackbar(EToastMessages.NoLongerExistsEdit);
        }
      );
    } else {
      this.rulesService.getOriginalRuleConditionId(ruleReportItem.snapshotId).subscribe(
        (response: ITagVariableSnapshotId) => {
          this.updateRule(response, ruleReportItem);
        },
        (error: HttpErrorResponse) => {
          this.snackbarService.openErrorSnackbar(EToastMessages.NoLongerExistsEdit);
        }
      );
    }
  }

  deleteVariable(ruleReportItem: IRuleReportItem): void {
    this.rulesService.getOriginalRuleConditionId(ruleReportItem.snapshotId).subscribe(
      (response: ITagVariableSnapshotId) => {
        this.deleteVariableFromRule(response, ruleReportItem);
      },
      (error: HttpErrorResponse) => {
        this.snackbarService.openErrorSnackbar(EToastMessages.NoLongerExistsEdit);
      }
    );
  }

  private openEditRuleModal(ruleId: number, onClose: OnClose = () => {
  }): void {
    this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        ruleId,
        mode: ERuleSetupMode.edit
      }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => onClose(!!rule));
  }

  private updateRule(originalIds: ITagVariableSnapshotId, item: IRuleReportItem, account?: boolean): void {
    this.rulesService.getRule(originalIds.originalRuleId).subscribe(
      (rule: IRule) => {
        if (rule.fromTemplate) {
          this.snackbarService.openErrorSnackbar(EToastMessages.PredefinedRule);
          return;
        }

        let foundCondition = false;

        for (let i = 0; i < rule.tags.length; i++) {
          let tag = rule.tags[i];

          if (account) {
            if (tag.id === originalIds.originalConditionId) {
              tag.account = item.actual;
              foundCondition = true;
              break;
            }
          } else if (tag.tagId === item.tagId && tag.variables) {
            for (let x = 0; x < tag.variables.length; x++) {
              let variable = tag.variables[x];
              // When using 'Set as expected' on a rule with a condition that has failed, set the new value
              // to always be a selector type string, clear out the valueTagId, and set the value to the actual
              if (variable.id === originalIds.originalConditionId) {
                variable.value = item.actual;
                variable.selectorType = ERuleTagVariableSelectorType.String;
                variable.valueTagId = null;
                foundCondition = true;
                break;
              }
            }
          }
        }

        if (foundCondition) {
          this.rulesService.updateRule(rule).subscribe(response => {
            this.snackbarService.openSuccessSnackbar(EToastMessages.UpdatedOnNextRun);
          });
        } else {
          // condition was deleted
          this.snackbarService.openErrorSnackbar(EToastMessages.RuleConditionMissing);
        }
      },
      (error) => {
        // catchall
        this.snackbarService.openErrorSnackbar(EToastMessages.GeneralError);
      }
    );
  }

  private deleteVariableFromRule(originalIds: ITagVariableSnapshotId, item: IRuleReportItem) {
    this.rulesService.getRule(originalIds.originalRuleId).subscribe(
      (rule: IRule) => {
        if (rule.fromTemplate) {
          this.snackbarService.openErrorSnackbar(EToastMessages.PredefinedRule);
          return;
        }

        let foundCondition = false;

        for (let i = 0; i < rule.tags.length; i++) {
          let tag = rule.tags[i];
          let updatedVariables = [];

          if (tag.tagId === item.tagId && tag.variables) {
            for (let x = 0; x < tag.variables.length; x++) {
              let variable = tag.variables[x];

              variable.id !== originalIds.originalConditionId
                ? updatedVariables.push(variable)
                : foundCondition = true;
            }

            tag.variables = updatedVariables;
          }
        }

        if (foundCondition) {
          this.rulesService.updateRule(rule).subscribe(response => {
            this.snackbarService.openSuccessSnackbar(EToastMessages.UpdatedOnNextRun);
          });
        } else {
          // condition was deleted
          this.snackbarService.openErrorSnackbar(EToastMessages.RuleConditionMissing);
        }
      },
      (error) => {
        // catchall
        this.snackbarService.openErrorSnackbar(EToastMessages.GeneralError);
      }
    );
  }

  removeAuditRule(ruleId: number, auditId: number) {
    this.rulesService.getOriginalRuleId(ruleId).subscribe(
      (ruleSnapshot: IRuleSnapshotId) => {
        this.auditService.getAudit(auditId).then((audit: IAuditModel) => {
          const ruleIds = audit.rules
            .filter((rule: IRule) => rule.id !== ruleSnapshot.originalRuleId)
            .map((rule: IRule) => rule.id);

          this.auditService.updateRulesForAudit(auditId, ruleIds).then(
            (rules: IRule[]) => this.snackbarService.openSuccessSnackbar(EToastMessages.RemovedOnNextRun),
            (error: HttpErrorResponse) => this.snackbarService.openErrorSnackbar(EToastMessages.GeneralError)
          );
        });
      },
      _ => {
        this.snackbarService.openErrorSnackbar(EToastMessages.NoLongerExistsRemove);
      }
    );
  }

  isRuleChanged(oldRule: IRule, newRule: IRule) {
    const oldRuleStr = JSON.stringify({
      name: oldRule.name,
      checkTimes: oldRule.checkTimes,
      pageFilters: {...oldRule.pageFilters},
      tags: {...oldRule.tags}
    });

    const newRuleStr = JSON.stringify({
      name: newRule.name,
      checkTimes: newRule.checkTimes,
      pageFilters: {...newRule.pageFilters},
      tags: {...newRule.tags}
    });

    return oldRuleStr !== newRuleStr;
  }
}
