import { Component, Input } from '@angular/core';
import { IButton } from '@app/models/commons';

@Component({
  selector: 'op-modal-footer',
  templateUrl: './op-modal-footer.component.html',
  styleUrls: ['./op-modal-footer.component.scss']
})
export class OpModalFooterComponent {

  @Input() leftFooterButtons?: IButton[];
  @Input() rightFooterButtons?: IButton[];

}
