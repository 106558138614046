import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  accountConditionConfig,
  conditionsTabConfig,
  generalTabConfig,
  IControlConfig,
  IFormControlConfigMap,
  mainConditionConfig,
  statusCodeConditionConfig,
  variableConditionConfig
} from './rule-setup-form-builder.constants';
import { ERuleFilterType, ERuleMatchType } from '../tabs/conditions-tab/rule-setup-conditions-tab.enums';
import { IRule, IRuleTag } from '@app/components/rules/rules.models';
import { RuleSetupFormUtilsService } from './rule-setup-form-utils.service';
import { ERuleConditionClause } from '../rule-setup.enums';
import {
  TagStatusCodeValues
} from '@app/components/rules/rule-setup/tabs/conditions-tab/shared/rule-tag-status-code-filter/rule-tag-status-code-filter.constants';
import {
  ITagStatusCodeOperator
} from '@app/components/rules/rule-setup/tabs/conditions-tab/shared/rule-tag-status-code-filter/rule-tag-status-code-filter.models';

@Injectable()
export class RuleSetupFormBuilderService {

  constructor(private formBuilder: UntypedFormBuilder, 
              private formUtilsService: RuleSetupFormUtilsService) { }

  private readonly defaultRule: Partial<IRule> = {
    name: '',
    isDefaultRule: false,
    recipients: [],
    checkTimes: 1,
    matchAllFilters: true,
    tags: [],
    labels: []
  };

  buildEmptyForm(): UntypedFormGroup {
    return this.buildFilledForm(this.defaultRule);
  }

  buildFilledForm(rule: Partial<IRule>, form?: UntypedFormGroup): UntypedFormGroup {
    const emptyForm = form ?? this.createEmptyForm();
    const filledForm = this.fillForm(emptyForm, rule);
    const filledFormWithValidation = this.updateValidation(filledForm);
    this.updateConditionsDisabling(filledFormWithValidation.controls.conditions as UntypedFormGroup);
    return filledFormWithValidation;
  }

  /* Build form */
  private createEmptyForm(): UntypedFormGroup {
    return this.formBuilder.group({
      general: this.formBuilder.group({
        name: this.formBuilder.control(''),
        isDefaultRule: this.formBuilder.control(false),
        labels: this.formBuilder.control([]),
        recipients: this.formBuilder.control([])
      }),
      conditions: this.formBuilder.group({
        checkTimes: this.formBuilder.control(''),
        matchAllFilters: this.formBuilder.control(''),
        filters: this.formBuilder.group({
          If: this.formBuilder.array([]),
          Then: this.formBuilder.array([
            this.createEmptyThenCondition()
           ])
        })
      })
    });
  }

  private createEmptyIfCondition(): UntypedFormGroup {
    return this.formBuilder.group({
      id: this.formBuilder.control(null),
      ruleId: this.formBuilder.control(null),
      type: this.formBuilder.control(''),
      matchType: this.formBuilder.control(''),
      value: this.formBuilder.control(''),
      tagId: this.formBuilder.control(''),
      account: this.getEmptyAccountCondition(),
      variables: this.formBuilder.array([]),
      statusCode: this.getEmptyStatusCodeCondition(),
    });
  }

  private createEmptyThenCondition(): UntypedFormGroup {
    return this.formBuilder.group({
      id: this.formBuilder.control(null),
      ruleId: this.formBuilder.control(null),
      type: this.formBuilder.control(ERuleFilterType.Tag),
      matchType: this.formBuilder.control(ERuleMatchType.Equals),
      value: this.formBuilder.control(''),
      tagId: this.formBuilder.control(''),
      account: this.getEmptyAccountCondition(),
      variables: this.formBuilder.array([]),
    });
  }

  private getEmptyAccountCondition(): UntypedFormGroup {
    const form = this.formBuilder.group({
      value: this.formBuilder.control(''),
      matchType: this.formBuilder.control(''),
      validationDescription: this.formBuilder.control('')
    });
    form.disable();
    return form;
  }

  private getEmptyStatusCodeCondition(): UntypedFormGroup {
    const form = this.formBuilder.group({
      value: this.formBuilder.control(''),
      matchType: this.formBuilder.control(''),
    });
    form.disable();
    return form;
  }

  private getEmptyVariableCondition(): UntypedFormGroup {
    return this.formBuilder.group({
      id: this.formBuilder.control(null),
      ruleTagId: this.formBuilder.control(null),
      variable: this.formBuilder.control(''),
      variableMatchAsRegex: this.formBuilder.control(''),
      matchType: this.formBuilder.control(''),
      selectorType: this.formBuilder.control(''),
      value: this.formBuilder.control(''),
      valueTagId: this.formBuilder.control(''),
      validationDescription: this.formBuilder.control(''),
    });
  }

  /* Fill form */
  private fillForm(form: UntypedFormGroup, rule: Partial<IRule>): UntypedFormGroup {
    const general = {
      name: rule.name,
      isDefaultRule: rule.isDefaultRule,
      labels: rule.labels ?? [],
      recipients: rule.recipients
    };
    // in old rules these props can be set to null
    const conditions = {
      checkTimes: rule.checkTimes ?? this.defaultRule.checkTimes,
      matchAllFilters: rule.matchAllFilters ?? this.defaultRule.matchAllFilters
    };
    form.patchValue(
      { general, conditions },
      { emitEvent: false }
    );

    this.fillIfConditions(form, rule);
    this.fillThenConditions(form, rule);

    return form;
  }

  private fillIfConditions(form: UntypedFormGroup, rule: Partial<IRule>) {
    const pageFiltersConditions = rule.pageFilters?.map(pageFilter => this.formBuilder.group({
      id: this.formBuilder.control(pageFilter.id),
      ruleId: this.formBuilder.control(pageFilter.ruleId),
      type: this.formBuilder.control(pageFilter.type),
      matchType: this.formBuilder.control(pageFilter.matchType),
      value: this.formBuilder.control(pageFilter.value),
      tagId: this.formBuilder.control(''),
      account: this.getEmptyAccountCondition(),
      variables: this.formBuilder.array([])
    }));

    const tagConditions = this.getTagConditions(rule.tags.filter(tag => tag.clause === ERuleConditionClause.If));

    const ifConditionsArray = form.get('conditions.filters.If') as UntypedFormArray;
    // Set conditions
    while (ifConditionsArray.length !== 0) {
      ifConditionsArray.removeAt(0, { emitEvent: false });
    }

    const ifConditions = pageFiltersConditions ? [...pageFiltersConditions, ...tagConditions] : tagConditions;
    ifConditions.forEach(condition => ifConditionsArray.push(condition, { emitEvent: false }));
  }

  private fillThenConditions(form: UntypedFormGroup, rule: Partial<IRule>) {
    const tagConditions = this.getTagConditions(rule.tags.filter(tag => tag.clause === ERuleConditionClause.Then));

    const thenConditionsArray = form.get('conditions.filters.Then') as UntypedFormArray;
    // Set conditions
    if (tagConditions.length > 0) { // should leave at least 1 condition in form array
      while (thenConditionsArray.length !== 0) {
        thenConditionsArray.removeAt(0, { emitEvent: false });
      }
  
      tagConditions.forEach(condition => thenConditionsArray.push(condition, { emitEvent: false }));
    }
  }

  private getTagConditions(tags: IRuleTag[]) {
    return tags.map(tag => {
      const account = this.formBuilder.group({
        value: this.formBuilder.control(tag.account),
        matchType: this.formBuilder.control(tag.matchType),
        validationDescription: this.formBuilder.control(tag.validationDescription)
      });

      const getStatusCodeValue = (tag: IRuleTag): ITagStatusCodeOperator => {
        const value: ITagStatusCodeOperator = {
          name: TagStatusCodeValues.find(v => v.valueType === tag.statusCode?.valueType)?.name,
          valueType: tag.statusCode?.valueType
        };

        if (tag.statusCode?.value !== null && tag.statusCode?.value >= 0) {
          value.value = tag.statusCode.value;
          value.name = String(tag.statusCode.value);
        }

        return value;
      };

      const statusCode = this.formBuilder.group({
        value: getStatusCodeValue(tag),
        matchType: this.formBuilder.control(tag.statusCode?.matchType),
      });

      if (!tag.account) account.disable();
      if (!tag.statusCode) statusCode.disable();

      const variables = (tag.variables || []).map(v => {
        // case-insensitive `regex:` at the beginning of the string, followed by any number of spaces
        const staticRegexMatcher = /^regex:\s*/i;
        // staticRegexMatcher + variable name part
        const variableRegexMatcher = /^regex:\s*(.*)$/i;
        const variableMatchAsRegex = variableRegexMatcher.test(v.variable);
        const variableName = variableMatchAsRegex ? v.variable.replace(staticRegexMatcher, '') : v.variable;
        return this.formBuilder.group({
          id: v.id,
          ruleTagId: v.ruleTagId,
          variable: this.formBuilder.control(variableName),
          variableMatchAsRegex: this.formBuilder.control(variableMatchAsRegex),
          matchType: this.formBuilder.control(v.matchType),
          selectorType: this.formBuilder.control(v.selectorType === 'Tag' ? v.valueTagId : v.selectorType),
          value: this.formBuilder.control(v.value),
          valueTagId: this.formBuilder.control(v.valueTagId),
          validationDescription: this.formBuilder.control(v.validationDescription)
        });
      });

      return this.formBuilder.group({
        id: this.formBuilder.control(tag.id),
        ruleId: this.formBuilder.control(tag.ruleId),
        type: this.formBuilder.control(ERuleFilterType.Tag),
        matchType: this.formBuilder.control(ERuleMatchType.Equals),
        value: this.formBuilder.control(''),
        tagId: this.formBuilder.control(tag.tagId),
        account,
        statusCode,
        variables: this.formBuilder.array(variables)
      });
    });
  }

  /* Form validation */
  private updateValidation(form: UntypedFormGroup): UntypedFormGroup {
    this.updateGeneralTabValidation(form.controls.general as UntypedFormGroup);
    this.updateConditionsTabValidation(form.controls.conditions as UntypedFormGroup);
    return form;
  }

  updateGeneralTabValidation(generalGroup: UntypedFormGroup): void {
    this.updateFormGroupValidation(generalGroup, generalTabConfig);
  }

  updateConditionsTabValidation(conditionsGroup: UntypedFormGroup): void {
    Object.keys(conditionsGroup.controls).forEach(conditionControlKey => {
      if (conditionControlKey === 'filters') {
        this.updateConditionsValidation(conditionsGroup.get('filters.If') as UntypedFormArray);
        this.updateConditionsValidation(conditionsGroup.get('filters.Then') as UntypedFormArray);
      } else {
        this.updateFormControlValidation(conditionsGroup, conditionControlKey, conditionsTabConfig);
      }
    });
  }

  private updateConditionsValidation(conditionsArray: UntypedFormArray): void {
    for (let conditionGroup of conditionsArray.controls) {
      const formGroup = conditionGroup as UntypedFormGroup;
      this.updateFormGroupValidation(formGroup, mainConditionConfig);
    }
  }

  private updateFormGroupValidation(formGroup: UntypedFormGroup, config: IFormControlConfigMap): UntypedFormGroup {
    for (let key in formGroup.controls) {
      const control = formGroup.controls[key];
      if (this.formUtilsService.isFormArray(control)) {
        this.updateFormArrayValidation(control, config[key] as IFormControlConfigMap);
      } else if (this.formUtilsService.isFormGroup(control)) {
        this.updateFormGroupValidation(control, config[key] as IFormControlConfigMap);
      } else {
        this.updateFormControlValidation(formGroup, key, config);
      }
    }
    return formGroup;
  }

  private updateFormArrayValidation(formArray: UntypedFormArray, config: IFormControlConfigMap) {
    for (let variableGroup of formArray.controls) {
      this.updateFormGroupValidation(variableGroup as UntypedFormGroup, config as IFormControlConfigMap);
    }
  }

  private updateFormControlValidation(formGroup: UntypedFormGroup, key: string, config: IFormControlConfigMap) {
    const formControl = formGroup.controls[key] as UntypedFormControl;
    const controlConfig = config[key] as IControlConfig;
    if (!controlConfig) return;
    const validators = controlConfig.validators(formGroup);
    formControl.setValidators(validators);
    formControl.updateValueAndValidity({ emitEvent: false });
  }

  /* Disable form controls */
  updateConditionsDisabling(conditionsGroup: UntypedFormGroup): void {
    this.updateConditionDisabling(conditionsGroup.get('filters.If') as UntypedFormArray);
    this.updateConditionDisabling(conditionsGroup.get('filters.Then') as UntypedFormArray);
  }

  private updateConditionDisabling(conditionsArray: UntypedFormArray): void {
    // Disable controls
    for (let conditionGroup of conditionsArray.controls) {
      const conditionGroupTyped = conditionGroup as UntypedFormGroup;
      const variablesArray = conditionGroupTyped.controls.variables as UntypedFormArray;

      if (!variablesArray) continue;

      for (let variableGroup of variablesArray.controls) {
        this.updateVariableControlsDisabling(variableGroup as UntypedFormGroup);
      }
    }
  }

  private updateVariableControlsDisabling(variableGroup: UntypedFormGroup): UntypedFormGroup {
    Object.keys(variableGroup.controls).forEach(variableKey => {
      const control = variableGroup.controls[variableKey];
      const controlConfig = variableConditionConfig[variableKey] as IControlConfig;
      if (controlConfig.disable?.(variableGroup)) {
        control.setValue(null, { emitEvent: false });
        control.disable({ emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
      }
    });
    return variableGroup;
  }

  /* Main Condition interaction */
  addIfCondition(conditionsArray: UntypedFormArray) {
    const conditionGroup = this.createEmptyIfCondition();
    const conditionGroupWithValidation = this.updateFormGroupValidation(conditionGroup, mainConditionConfig);
    conditionsArray.push(conditionGroupWithValidation);
  }

  addThenCondition(conditionsArray: UntypedFormArray) {
    const conditionGroup = this.createEmptyThenCondition();
    const conditionGroupWithValidation = this.updateFormGroupValidation(conditionGroup, mainConditionConfig);
    conditionsArray.push(conditionGroupWithValidation);
  }

  removeMainCondition(index: number, conditions: UntypedFormArray) {
    conditions.removeAt(index);
  }

  /* Account Condition interaction */
  addAccountCondition(accountForm: UntypedFormGroup) {
    for (let key in accountForm.controls) {
      const { validators } = accountConditionConfig[key] as IControlConfig;
      accountForm.get(key).setValidators(validators);
      accountForm.get(key).updateValueAndValidity({ emitEvent: false });
    }

    accountForm.enable();
  }

  removeAccountCondition(accountForm: UntypedFormGroup) {
    for (let key in accountForm.controls) {
      accountForm.get(key).clearValidators();
      accountForm.get(key).updateValueAndValidity({ emitEvent: false });
    }

    accountForm.disable();
  }

  removeStatusCodeCondition(statusCodeForm: UntypedFormGroup) {
    for (let key in statusCodeForm.controls) {
      statusCodeForm.get(key).clearValidators();
      statusCodeForm.get(key).updateValueAndValidity({ emitEvent: false });
    }

    statusCodeForm.disable();
  }

  /* Variable Condition interaction */
  addVariableCondition(variablesArray: UntypedFormArray) {
    const variableGroup = this.getEmptyVariableCondition();
    const variableGroupWithValidation = this.updateFormGroupValidation(variableGroup, variableConditionConfig);
    variablesArray.push(variableGroupWithValidation);
  }

  /* Status Code Condition interaction */
  addStatusCodeCondition(statusCodeForm: UntypedFormGroup) {
    for (let key in statusCodeForm.controls) {
      const { validators } = statusCodeConditionConfig[key] as IControlConfig;
      statusCodeForm.get(key).setValidators(validators);
      statusCodeForm.get(key).updateValueAndValidity({ emitEvent: false });
    }

    statusCodeForm.enable();
  }

  removeVariableCondition(index: number, variablesArray: UntypedFormArray) {
    variablesArray.removeAt(index);
  }
  /* End */

}
