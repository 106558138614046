import { LoginComponent } from '@app/components/login/login.component';
import { LoginPaths, LoginStateName } from './login.constants';
import { Route } from '@angular/router';

export const LoginRoutes: Route = {
  path: LoginPaths.base,
  component: LoginComponent,
  data: {
    stateName: LoginStateName,
    title: 'Login',  
    errorCode: ''
  }
};
