import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
import { AuditReportContainerStateNames, AuditReportContainerUrlBuilders } from '../audit-reports/audit-report-container.constants';

const RorReportBaseStateName = AuditReportContainerStateNames.base + '.ror_comparison';

export const RorReportStateNames = {
  base: RorReportBaseStateName,
  results: RorReportBaseStateName + '.results',
  tagPresence: RorReportBaseStateName + '.results.tag_presence',
  variablePresence: RorReportBaseStateName + '.results.variable_presence',
  valuePresence: RorReportBaseStateName + '.results.value_presence',
  missingPages: RorReportBaseStateName + '.results.missing_pages',
  tagPresencePages: RorReportBaseStateName + '.results.tag_presence.pages',
  variablePresencePages: RorReportBaseStateName + '.results.variable_presence.pages',
  valuePresencePages: RorReportBaseStateName + '.results.value_presence.pages',
};

export const RorReportPaths = {
  base: 'comparisons',
  results: 'results',
  tagPresence: 'tag-presence',
  variablePresence: 'variable-presence',
  valuePresence: 'value-presence',
  missingPages: 'missing-pages',
  tagPresencePages: ':tagId/:tagPresenceDiff/pages',
  variablePresencePages: ':tagId/:variableName/pages',
  valuePresencePages: ':tagId/:variableName/pages'
};

const baseUrl = (auditId: number, runId: number) => AuditReportContainerUrlBuilders.base(auditId, runId) + RouteBuilder.buildUrl([RorReportPaths.base]);
const baseResultsUrl = (auditId: number, runId: number) => baseUrl(auditId, runId) + RouteBuilder.buildUrl([RorReportPaths.results]);
export const RorReportUrlBuilders = {
  base: baseUrl,
  results: baseResultsUrl,
  tagPresence: (auditId: number, runId: number) => {
    return baseResultsUrl(auditId, runId) + RouteBuilder.buildUrl([RorReportPaths.tagPresence]);
  },
  variablePresence: (auditId: number, runId: number) => {
    return baseResultsUrl(auditId, runId) + RouteBuilder.buildUrl([RorReportPaths.variablePresence]);
  },
  valuePresence: (auditId: number, runId: number) => {
    return baseResultsUrl(auditId, runId) + RouteBuilder.buildUrl([RorReportPaths.valuePresence]);
  },
  missingPages: (auditId: number, runId: number) => {
    return baseResultsUrl(auditId, runId) + RouteBuilder.buildUrl([RorReportPaths.missingPages]);
  },
  tagPresencePages: (auditId: number, runId: number, tagId: number, tagPresenceDiff: number) => {
    return baseResultsUrl(auditId, runId) + RouteBuilder.buildUrl([RorReportPaths.tagPresence, RorReportPaths.tagPresencePages], String(tagId), String(tagPresenceDiff));
  },
  variablePresencePages: (auditId: number, runId: number, tagId: number, variableName: string) => {
    return baseResultsUrl(auditId, runId) + RouteBuilder.buildUrl([RorReportPaths.variablePresence, RorReportPaths.variablePresencePages], String(tagId), variableName);
  },
  valuePresencePages: (auditId: number, runId: number, tagId: number, variableName: string) => {
    return baseResultsUrl(auditId, runId) + RouteBuilder.buildUrl([RorReportPaths.variablePresence, RorReportPaths.valuePresencePages], String(tagId), variableName);
  }
};

export const enum EColor {
  Good = '#50bc77',
  Normal = '#ffb406',
  Bad = '#ee6b6e',
}

export const allComparisonsPlaceholder = {
  comparisonSnapshotId: -1,
  comparisonName: 'All Comparisons'
};
