import { Component, EventEmitter, forwardRef, Input, Output, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ConsentCategoryTypes } from '../../consent-categories.constants';
import {
  ICCEditParams,
  IConsentCategoryTag,
  IConsentCategoryTagTableRow,
  IConsentCategoryType,
  INewTagData,
} from '../../consent-categories.models';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { AddNewTagComponent } from './add-new-tag/add-new-tag.component';
import { ActivatedRoute } from '@angular/router';

import { ECCEditTabs, EditInstructions } from '../cc-edit.constants';
import { Subject } from 'rxjs';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { StorageService } from '@app/components/shared/services/storage.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ConsentCategoriesEditTagsComponent),
  multi: true
};

const CCEditTagsTablePageSizeStorageKey = 'cc-edit-tags-table-page-size';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-edit-tags',
  templateUrl: './cc-tags.component.html',
  styleUrls: ['./cc-tags.component.scss'],
  providers: [FORM_CONTROL_VALUE_ACCESSOR],
})
export class ConsentCategoriesEditTagsComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  readonly editTagsInstructions: string = EditInstructions;
  dataSource = new MatTableDataSource;
  displayedColumns = ['name', 'accounts', 'category', 'delete'];
  consentTypes: IConsentCategoryType[] = ConsentCategoryTypes;
  consentCategoryId: number;
  loading: boolean = false;
  filterValue: string;

  chipPlaceholderText: string = 'Enter account (or leave empty for any account)';

  tagsForm: UntypedFormGroup;
  pageSizeOptions = [10, 25, 50, 100, 500];
  minCookiesToShowPaginator = this.pageSizeOptions[0];

  onChange = (value: any) => {};
  onTouched = () => {};

  @Input() accountTags: IUiTag[] = [];
  @Output() tabDataCount: EventEmitter<{tabName: string, newTabCount: number}> = new EventEmitter();
  @Output() formTouched: EventEmitter<ECCEditTabs> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private modalService: OpModalService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private modalEscapeService: ModalEscapeService,
    private uiTagService: UiTagService,
    private storage: StorageService,
  ) {
    this.route.params.subscribe((params: ICCEditParams) => {
      this.consentCategoryId = params.id;
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.initTable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  initForm() {
    this.tagsForm = this.formBuilder.group({
      tags: []
    });
  }

  getTagData(id: number): IUiTag {
    return this.accountTags.find(tag => tag.id === id);
  }

  alphaSort(prop: string, arr: any[]): any[] {
    return arr.sort((a: any, b: any) => (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) ? 1 : -1);
  }

  addNewTag(): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openModal(AddNewTagComponent, {
      data: {
        tags: this.accountTags,
        types: this.consentTypes
      }
    })
      .afterClosed()
      .subscribe((tag: INewTagData) => {
        if (!tag) return;

        this.loading = true;

        const existingIndex = (this.dataSource.data as any).findIndex(fi => fi.tagId === tag.tag.id);

        if (existingIndex !== -1){

          const accounts = tag.accounts.filter(fi => !(this.dataSource.data[existingIndex] as any).accounts.includes(fi));

          (this.dataSource.data[existingIndex] as any).accounts = [...(this.dataSource.data[existingIndex] as any).accounts, ...accounts];
          this.dataSource.data = this.dataSource.data;

          const control = this.tags.value.findIndex(fi => fi.tagId === tag.tag.id);
          this.tags.value[control].accounts = (this.dataSource.data[existingIndex] as any).accounts;

          this.onChange(this.tags.value);
          this.formTouched.emit(ECCEditTabs.tags);
        } else {
          const newTableTag: IConsentCategoryTagTableRow = {
            id: tag.tag.id,
            icon: UiTagService.getTagIconUrl(tag.tag.id),
            name: tag.tag.name,
            accounts: tag.accounts,
            tagId: tag.tag.id,
            category: this.uiTagService.getTagCategory(tag.tag.tagCategoryId).category,
          };

          this.dataSource.data = [...this.dataSource.data, {...newTableTag, tagId: tag.tag.id }];
          this.dataSource.data = this.alphaSort('name', this.dataSource.data);

          // forces table to update
          this.dataSource.data = this.dataSource.data;

          this.tags.setValue(this.dataSource.data);

          this.onChange(this.tags.value);
          this.formTouched.emit(ECCEditTabs.tags);
        }

        this.loading = false;
        this.modalEscapeService.remove(index);
        this.updateTagsTabCount();
      });
  }

  deleteTag(tag: IConsentCategoryTagTableRow): void {
    // cache this in case of API failure
    const cachedTableTags = [ ...this.dataSource.data ];
    const index = this.dataSource.data.indexOf(tag);
    this.dataSource.data.splice(index, 1);
    // forces table to update
    this.dataSource.data = this.dataSource.data;

    this.tags.setValue(this.dataSource.data);
    this.onChange(this.tags.value);
    this.formTouched.emit(ECCEditTabs.tags);
    this.updateTagsTabCount();
  }

  openConfirmDeleteAllTags(): void {
    const rightFooterButtons = [
      {
        label: 'Cancel',
        action: () => {},
        primary: false
      },
      {
        label: 'Delete All Tags',
        action: () => {
          this.deleteAllTags();
        },
        primary: true
      }
    ];

    this.modalService
      .openConfirmModal({
        data: {
          title: 'Delete all tags?',
          messages: ['Are you sure you want to delete all tags from this consent category?'],
          rightFooterButtons
        }
      });
  }

  deleteAllTags(): void {
    this.dataSource.data = [];
    this.dataSource.data = this.dataSource.data;

    this.tags.setValue(this.dataSource.data);
    this.onChange(this.tags.value);
    this.formTouched.emit(ECCEditTabs.tags);
    this.updateTagsTabCount();
  }

  addAccount(event: MatChipInputEvent, tag: IConsentCategoryTagTableRow): void {
    if (event.value === '') return;

    if ((event.value).trim()) {
      if (!tag.accounts) tag.accounts = [];
      if (!tag.accounts.includes(event.value)) tag.accounts.push(event.value);
    }

    event.chipInput.clear();

    const control = this.tags.value.findIndex(fi => fi.tagId === tag.tagId);
    this.tags.value[control].accounts = tag.accounts;
    this.onChange(this.tags.value);
    this.formTouched.emit(ECCEditTabs.tags);
  }

  removeAccount(account: string, tag: IConsentCategoryTagTableRow): void {
    const accountIndex = tag.accounts.indexOf(account);
    tag.accounts.splice(accountIndex, 1);

    const control = this.tags.value.findIndex(fi => fi.tagId === tag.tagId);
    this.tags.value[control].accounts = tag.accounts;
    this.onChange(this.tags.value);
    this.formTouched.emit(ECCEditTabs.tags);
  }

  writeValue(tags: IConsentCategoryTag[], index?: number): void {
    this.tags.setValue([ ...tags ]);
    this.dataSource.data = this.tags.value.map((tag: IConsentCategoryTag) => {
      const tagData = this.getTagData(tag.tagId);
      return {
        tagId: tag.tagId,
        icon: UiTagService.getTagIconUrl(tagData?.id),
        name: tagData?.name,
        accounts: tag.accounts,
        category: this.uiTagService.getTagCategory(tagData?.tagCategoryId)?.category,
        index: index ? index : this.tags.value.length
      };
    });

    this.dataSource.data = this.alphaSort('name', this.dataSource.data);
    this.dataSource.sortingDataAccessor = (item, property) => {
      return typeof item[property] === 'string'
        ? item[property].toLocaleLowerCase()
        : item[property];
    };
  }

  initTable(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  applyFilter(event: KeyboardEvent): void {
    this.filterValue = (event.target as HTMLInputElement)?.value?.trim().toLowerCase() || '';
    this.dataSource.filter = this.filterValue;
  }

  savePageSizeToStorage(e: PageEvent): void {
    this.storage.setValue(CCEditTagsTablePageSizeStorageKey, e.pageSize);
  }

  get perPage(): number {
    return this.storage.getValue(CCEditTagsTablePageSizeStorageKey) || this.pageSizeOptions[2];
  }

  updateTagsTabCount() {
    this.tabDataCount.emit({tabName: ECCEditTabs.tags, newTabCount: this.tags.value.length});
  }

  get tags(): UntypedFormControl {
    return this.tagsForm.get('tags') as UntypedFormControl;
  }
}
