import { IComparisonSetupModel } from './one-time-comparison-setup/one-time-component-setup.models';
import { IComparisonCreate, IAccountTagSettings } from '../comparisons.models';
import { EComparisonType, EDatasourcesUseType, EDatasourceTagType } from '../comparisons.enums';
import { IDatasourceTag } from '@app/components/comparison-library/comparison-library.model';
import { datasourceAllTagsOnly, defaultDatasource } from '../shared/comparison-variable-selector/comparison-variable-selector.constants';
import { IComparisonVariableSelectorForm } from '../shared/comparison-variable-selector/comparison-variable-selector.models';
import { ITagWithVariables } from '@app/components/match-criteria-picker/match-criteria-picker.models';
import { EAssignmentType } from '@app/models/commons';

export interface IOneTimeComparisonDetails {
  type: EComparisonType.oneTime;
  datasource: IOneTimeComparisonDatasource;
  assignment: IItemRun;
  advancedOptions: IOneTimeComparisonAdvanvedOptions;
  datasourcesUseType: EDatasourcesUseType;
}

export interface IOneTimeComparisonDatasource {
  sourceRun: IItemRun;
  tags: IDatasourceTag[];
}

interface IOneTimeComparisonAdvanvedOptions {
  ignoreUrlDomain:	boolean;
  ignoreUrlQuery:	boolean;
}

interface IItemRun {
  itemType: EAssignmentType;
  itemId: number;
  runId: number;
}

export interface IOneTimeFormModel {
  setup: IComparisonSetupModel;
  matchTags: {
    tags: ITagWithVariables[];
  }
}

export namespace IOneTimeFormModel {

  export function toComparisonCreate({setup, matchTags}: IOneTimeFormModel, 
                                    tagIdToTagMap: Map<number, IAccountTagSettings>): IComparisonCreate {
    const sourceRun = {
      itemType: EAssignmentType.audit,
      itemId: setup.baselineAudit.id,
      runId: setup.baselineAudit.run
    };

    const datasourceTags = setup.tags
      .filter(tag => tag.tagId !== datasourceAllTagsOnly.key)
      .map(tag => IComparisonVariableSelectorForm.toDatasourceTag(tag, tagIdToTagMap));

    // API Workaround. defaultDatasource is needed for API in case of no more selected detasources except "All (Tags Presence Only)"
    const tags = datasourceTags.length > 0 ? datasourceTags : [defaultDatasource];

    const datasource = {sourceRun, tags};

    const assignment = {
      itemType: EAssignmentType.audit,
      itemId: setup.destinationAudit.id,
      runId: setup.destinationAudit.run
    };
    const advancedOptions = {
      ignoreUrlDomain: setup.ignoreUrlDomain,
      ignoreUrlQuery: setup.ignoreQueryParameters
    };

    const tagPresenceOnly = !!setup.tags.find(
      tag => tag.tagId === datasourceAllTagsOnly.key
    );
    const details = {
      datasource,
      assignment,
      advancedOptions,
      type: EComparisonType.oneTime,
      datasourcesUseType: tagPresenceOnly ? EDatasourcesUseType.allEnabledTagsPresence : EDatasourcesUseType.specifiedTags
    } as IOneTimeComparisonDetails;

    return {
      type: EComparisonType.oneTime,
      name: setup.name,
      alignments: matchTags.tags.map(ITagWithVariables.toTagVariableAlignment),
      labels: setup.labels || [],
      details
    };
  }

}

export interface IComparisonModalData {
  id?: number;
  auditId?: number;
}

export interface IRunTag {
  id: number;
  name: string;
  icon?: string;
  variables?: string[];
}

export interface IRunTagVariable {
  id: number;
  name: string;
}

export interface IExcludedVariables {
  tagId: number;
  variables: number[];
}
