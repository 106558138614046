<div class="form-container" [formGroup]="formGroup">
  <div class="form-input">
    <mat-label class="form-label">
      What data sources should this alert be applied to
    </mat-label>
  </div>

  <mat-checkbox
    color="primary"
    formControlName="isDefaultAlert"
    [matTooltip]="readOnlyLabel"
    matTooltipPosition="above"
    [matTooltipDisabled]="false"
    [disabled]="false">
    Add this as a default alert to all data sources that I create.
  </mat-checkbox>

  <op-selectable-table [allItems]="dataSourcesState"
                    [selectedItems]="itemsControl.value"
                    selectedItemText="Data Sources"
                    [columns]="columns"
                    [labels]="labels"
                    [pagination]="pagination"
                    [readOnlyLabel]="readOnlyLabel"
                    [isReadOnly]="false"
                    (selectionChanged)="selectionChanged($event)"
                    (sortChanged)="sortChanged($event)"
                    (paginationChanged)="paginationChanged($event)">
    <ng-template #filterBarTemplate>
      <alert-data-sources-filter-bar searchByTextPlaceholderSuffix="data source"
                                    [labels]="labels"
                                    (filtersChanged)="filtersChanged($event)">
      </alert-data-sources-filter-bar>
    </ng-template>
  </op-selectable-table>
</div>
