export const unselectFile = function() {
    return {
      require: 'ngModel',
      link:function(scope, el, attrs, ngModel) {
        scope.unselect = () => {
          el.val('');
        };
        scope.$watch(() => ngModel.$modelValue, (newValue, oldValue) => {
          if (!newValue) {
            scope.unselect();
          }
        });
      }
  };

}

