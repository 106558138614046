import { ENTER } from '@angular/cdk/keycodes';
import { COMMA } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'month-by-date-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule
  ],
  templateUrl: './month-by-date-selector.component.html',
  styleUrls: ['./month-by-date-selector.component.scss']
})
export class MonthByDateSelectorComponent implements OnInit {

  @Input() selectedDate: Date;
  @Output() selectedOptionChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('dayInput') dayInput: ElementRef<HTMLInputElement>;

  monthDays: string[] = [];
  filteredMonthDays$: Observable<string[]>;
  dayCtrl: FormControl = new FormControl('');
  selectedDays: string[] = [];
  warning: string;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() {
    this.filteredMonthDays$ = this.dayCtrl.valueChanges.pipe(
      startWith(null),
      map((day: string | null) => (day ? this._filter(day) : this.monthDays.slice()))
    );
  }

  ngOnInit(): void {
    this.generateMonthDays();
    this.setSelectedDay();
  }

  private generateMonthDays(): void {
    this.monthDays = Array.from({ length: 31 }, (_, i) => {
      const day = i + 1;
      return this.getDayLabel(day)
    });
  }

  private getDayLabel(day: number): string {
    const suffix = this.getOrdinalSuffix(day);
    return `${day}${suffix}`;
  }

  private getOrdinalSuffix(day: number): string {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:  return 'st';
      case 2:  return 'nd';
      case 3:  return 'rd';
      default: return 'th';
    }
  }

  private setSelectedDay(): void {
    const day = this.getDayLabel(this.selectedDate.getDate());
    this.selectedDays = [day];
    this.selectedOptionChange.emit(this.selectedDays);
    this.generateWarning();
  }

  private generateWarning(): void {
    const daysOver28 = this.selectedDays
      .map(day => parseInt(day))
      .filter(day => day > 28);

    if (daysOver28.length === 0) {
      this.warning = '';
      return;
    }

    const dayLabels = daysOver28.map(day => this.getDayLabel(day));
    const partial = dayLabels.length > 1
      ? `${dayLabels.slice(0, -1).join(', ')}, or ${dayLabels.at(-1)}`
      : dayLabels.join(', ');

    this.warning = `Months that don't have a ${partial} will be skipped.`;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // check if the value matches a day in the month
    const dayIndex = this.monthDays.indexOf(value);
    if (dayIndex === -1) {
      return;
    }

    // Add the selected day only if it's not already in the list
    if (value && this.selectedDays.indexOf(value) === -1) {
      this.selectedDays.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.dayCtrl.setValue(null);
    this.selectedOptionChange.emit(this.selectedDays);
    this.generateWarning();
  }

  remove(day: string): void {
    const index = this.selectedDays.indexOf(day);

    if (index >= 0) {
      this.selectedDays.splice(index, 1);
    }

    this.selectedOptionChange.emit(this.selectedDays);
    this.generateWarning();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedDays.indexOf(event.option.viewValue) === -1) {
      this.selectedDays.push(event.option.viewValue);
    }
    this.dayInput.nativeElement.value = '';
    this.dayCtrl.setValue(null);
    this.selectedOptionChange.emit(this.selectedDays);
    this.generateWarning();
  }

  private _filter(value: string): string[] {
    const filterValue = value.trim().toLowerCase();
    return this.monthDays.filter(day => day.toLowerCase().includes(filterValue));
  }
}
