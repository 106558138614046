<div>
  <ng-container *ngIf="this.title">
    <h2>{{this.title}}</h2>
  </ng-container>
  <div class="message-container">
    <p *ngIf="this.message">
      <span [innerHTML]="this.message"></span>
    </p>
    <ng-container *ngIf="this.message2">
      <p>
        <span [innerHTML]="this.message2"></span>
      </p>
    </ng-container>
  </div>
  <div class="buttons">
    <button id="bulk-action-confirm-left-btn" mat-flat-button (click)="actionSnackbar()" *ngIf="showSecondBtn">{{this.leftBtnLabel}}</button>
    <button id="bulk-action-confirm-left-btn" mat-falt-button (click)="closeSnackbar()">{{this.rightBtnLabel}}</button>
  </div>
</div>