import { ModalEvents, Names } from '@app/moonbeamConstants';
import { ButtonSet } from '@app/models/commons';
import { IModalTemplate, ModalTemplates } from './modalTemplates';
import { IShadeWindowDataService } from '../ui/shadeWindow/shadeWindowDataService';
import * as angular from 'angular';
import { StringUtils } from '@app/components/utilities/StringUtils';

// `ng-include` directive requires templates, that are loaded by the `ngtemplate-loader`
const modal = require('ngtemplate-loader!@app/components/modals/modal.html');
const fullScreenModal = require('ngtemplate-loader!@app/components/modals/fullScreenModal.html');
require('ngtemplate-loader?relativeTo=/web/&prefix=@app!@app/components/modals/modalFooter.html');

export interface IWizardModalController {
    currentStep: number;
    steps: Array<String>;
    modalButtons: Array<ButtonSet>;
    goToStep: (tabIndex: number) => void;
  }

  export interface IFullScreenModalController {
    subtitle: string;
    modalButtons?: Array<ButtonSet>;
  }

  export interface IModalScopeExtended<T> extends mgcrea.ngStrap.modal.IModalScope {
    $$childHead: T
  }

  export abstract class IModalService {
    abstract showModal<T>(template: IModalTemplate<T>, onHide: (response: T) => void);
    abstract showFullScreenModal<T>(template: IModalTemplate<T>, onHide: (response: T) => void): void;
    abstract showConfirmation<T, TResponse>(template: IModalTemplate<T>, onHide: (resposne: TResponse) => void): void;
    abstract showDelete<T, TResponse>(template: IModalTemplate<T>, onHide: (response: TResponse) => void): void;
    abstract addOnShowHandler(handler: (IModal) => void): string;
  }

  export class ModalService extends IModalService {

    static $inject = [
      '$rootScope',
      '$modal',
      Names.Services.shadeWindowData
    ];

    constructor(private $rootScope: angular.IScope,
      private $modal: mgcrea.ngStrap.modal.IModalService,
      private shadeWindowDataService: IShadeWindowDataService) {
      super();
    }

    private onShowHandlers: Map<string, (IModal) => void> = new Map();

    private createAndShowModal<T, TResponse>(template: IModalTemplate<T>, modalTemplate: string, onModalHide: (TResponse) => void, resolveToName?: string): void {
      const scope = this.createModalScope<T>(template.modalData);
      let modalResponse: TResponse;

      const modal = this.$modal({
        title: template.title,
        templateUrl: modalTemplate,
        controller: template.controller,
        controllerAs: 'ctrl',
        contentTemplate: template.templateUrl,
        animation: 'am-fade-and-scale',
        scope: scope,
        backdrop: true,
        keyboard: false,
        show: true,
        resolve: resolveToName ? {
          [resolveToName]: function () {
            return response => { modalResponse = response; };
          }
        } : null,
        onShow: (modal: mgcrea.ngStrap.modal.IModal) => {
          this.onShowHandlers.forEach(handler => {
            handler(modal);
          });
        }
      });

      const modalScope = (<any>modal).$scope as IModalScopeExtended<TResponse>;
      modalScope.$on(ModalEvents.modalHide, () => {
        if (this.shadeWindowDataService.isShadeWindowOpen()) {
          this.shadeWindowDataService.setShadeWindowIsClosed();
        }
        if (onModalHide) {
          if (!resolveToName) {
            modalResponse = modalScope.$$childHead;
          }
          onModalHide(modalResponse);
        }
        modalScope.$destroy();
      });
    }

    private createModalScope<T>(modalData: T): angular.IScope {
      const scope = this.$rootScope.$new();
      ModalTemplates.copyDataModel(modalData, scope);
      return scope;
    }

    showModal<T>(template: IModalTemplate<T>, onHide: (response: T) => void) {
      this.createAndShowModal(template, modal, onHide);
    }

    showFullScreenModal<T>(template: IModalTemplate<T>, onHide: (response: T) => void): void {
      this.createAndShowModal(template, fullScreenModal, onHide);
    }

    showConfirmation<T, TResponse>(template: IModalTemplate<T>, onHide: (resposne: TResponse) => void): void {
      this.createAndShowModal(template, modal, onHide, 'answer');
    }

    showDelete<T, TResponse>(template: IModalTemplate<T>, onHide: (response: TResponse) => void): void {
      this.createAndShowModal(template, modal, onHide, 'onDeleteHandler');
    }

    /**
     * Add onShow handler which will be called each time any modal is shown
     * @return handlerId use it to unsubscribe
     */
    addOnShowHandler(handler: (IModal) => void): string {
      const randomHandlerId = StringUtils.generateRandom(5);
      if (this.onShowHandlers.has(randomHandlerId)) {
        return this.addOnShowHandler(handler);
      } else {
        this.onShowHandlers.set(randomHandlerId, handler);
        return randomHandlerId;
      }
    }
  }
