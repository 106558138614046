<div class="error-message-container" [class]="data.snackbarClasses">
  <div><mat-icon>warning</mat-icon> </div>
  <div>
    <div *ngIf="data.message" [innerHTML]="data.message"></div>
    <div *ngIf="data.unsupportedSequence">
      <div>
        The regex characters of <strong>"{{data.unsupportedSequence}}"</strong> entered in for your search are not supported. <br>
        These regex characters are not supported:
        <span *ngFor="let character of data.allUnsupportedSequences; let i = index">
          <strong>{{character}}</strong> {{i === data.allUnsupportedSequences.length - 1 ? '.' : ','}}
        </span>
      </div>
    </div>
    <div *ngIf="data.includedNotApplied?.length">
      <div class="section-header">
        Include list regex filters that are not supported:
      </div>
      <ul>
        <li *ngFor="let filter of data.includedNotApplied">{{filter}}</li>
      </ul>
    </div>
    <div *ngIf="data.excludedNotApplied?.length">
      <div class="section-header">
        Exclude list regex filters that are not supported:
      </div>
      <ul>
        <li *ngFor="let filter of data.excludedNotApplied">{{filter}}</li>
      </ul>
    </div>
  </div>
  <div>
    <mat-icon class="close" (click)="close()">close</mat-icon>
  </div>
</div>
<div *ngIf="data.includedNotApplied?.length || data.excludedNotApplied?.length" class="controls">
  <p>Include/Exclude list updates will take place when the audit runs.</p>
  <div>
    <button mat-flat-button (click)="goToSettings()">
      EDIT REGEX FILTERS IN THIS AUDIT SETUP
    </button>
  </div>
</div>
