import { ComponentChanges } from './../../models/commons';
import { ICalendarEvent, ISelectedDayPayload } from './calendar/calendar.models';
import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { IRunPickerRun } from './run-picker-ng.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'run-picker-ng',
  templateUrl: './run-picker-ng.component.html',
  styleUrls: ['./run-picker-ng.component.scss']
})
export class RunPickerNgComponent implements OnInit, OnChanges {

  @Input() runs: IRunPickerRun[] = [];
  @Output() onClickRun: EventEmitter<IRunPickerRun> = new EventEmitter();

  allEvents: ICalendarEvent<IRunPickerRun>[];

  startMonth: Date;

  currentDate: Date;
  dateEvents: ICalendarEvent<IRunPickerRun>[];

  ngOnInit() {
    this.allEvents = this.makeCalendarEvents(this.runs);
  }

  ngOnChanges(changes: ComponentChanges<RunPickerNgComponent>) {
    if (changes.runs && changes.runs.previousValue !== changes.runs.currentValue) {
      this.allEvents = this.makeCalendarEvents(changes.runs.currentValue);
    }
  }

  handleClickDate(payload: ISelectedDayPayload): void {
    this.currentDate = new Date(payload.date);
    this.dateEvents = payload.events.sort((a, b) => a.date.getTime() - b.date.getTime()).reverse();

    if (payload.clickEvent && payload.events.length === 1) {
      this.handleClickEvent(payload.events[0]);
    }
  }

  handleClickEvent(calEvent: ICalendarEvent<IRunPickerRun>): void {
    if (this.onClickRun) {
      this.onClickRun.emit(calEvent.event);
    }
  }

  private makeCalendarEvents(runs: IRunPickerRun[]): ICalendarEvent<IRunPickerRun>[] {
    if (!runs) return [];
    return runs.map(run => {
      if (run.selected) {
        this.startMonth = new Date(run.date);
      }
      return {
        date: new Date(run.date),
        event: run,
        isSelected: run.selected,
        status: run.status
      };
    });
  }

}
