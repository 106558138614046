<mat-spinner *ngIf="showMainSpinner" [diameter]="75" [strokeWidth]="4"
             class="main-spinner centered"></mat-spinner>
<full-screen-status-banner *ngIf="showFullScreenStatusBanner && !showMainSpinner"
                           [runNowPending]="runNowPending"
                           (runNowClicked)="runNow()"
                           updatesType="WebJourney">
</full-screen-status-banner>

<div [hidden]="showFullScreenStatusBanner || showMainSpinner" class="basic-export-wrap">
  <table mat-table
         matSort
         class="basic-export-table styled-mat-table"
         [dataSource]="tableDataSource"
         multiTemplateDataRows
         matSortDirection="desc"
         matSortStart="desc">

    <ng-container [matColumnDef]="TableColumns.Name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        EXPORT NAME
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row[TableColumns.Name] }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumns.Description">
      <th mat-header-cell *matHeaderCellDef>
        DESCRIPTION
      </th>
      <td mat-cell
          *matCellDef="let row">
        {{ row[TableColumns.Description] }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumns.Preview">
      <th mat-header-cell *matHeaderCellDef>
        EXAMPLE
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="table-actions">
          <button class="table-action-icon preview-button"
                  [matTooltip]="'Preview'"
                  [matTooltipPosition]="'above'"
                  (click)="openImageInModal(row[TableColumns.Name], row.previewUrl)"
                  disableRipple
                  mat-button>
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumns.Export">
      <th mat-header-cell *matHeaderCellDef>
        EXPORT
      </th>
      <td mat-cell *matCellDef="let row">
        <div class="table-actions" *ngIf="row.status === EAuditExportStatuses.notExported">
          <button class="export-button"
                  [class.unhoverable]="row.noEndpoint"
                  [matTooltip]="row.noEndpoint ? 'Coming soon' : 'Export'"
                  [matTooltipPosition]="'above'"
                  disableRipple
                  mat-button
                  (click)="!row.noEndpoint && row.export()">
            <mat-icon>cloud_download_filled</mat-icon>
          </button>
        </div>
        <mat-spinner *ngIf="row.status === EAuditExportStatuses.generatingExport" class="spinner" [diameter]="17"
                     [strokeWidth]="2"></mat-spinner>
        <span class="request-sent-label" *ngIf="row.status === EAuditExportStatuses.emailSent">Request Sent</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"
        class="item-row">
    </tr>
  </table>
</div>
