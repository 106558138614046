import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IConsentCategoryCookie, IConsentCategoryRequestDomain,
  IConsentCategoryTag, IRequestDomain
} from '@app/components/consent-categories/consent-categories.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-create-selection-info',
  templateUrl: './cc-create-selection-info.component.html',
  styleUrls: ['./cc-create-selection-info.component.scss']
})
export class CCCreateSelectionInfoComponent {
  @Input() selectedItems: IConsentCategoryCookie[]|IConsentCategoryTag[]|IRequestDomain[]|string[] = [];
  @Input() selectedItemText: string;
  @Input() countOnly: boolean = false;
  @Input() selectedLimit: number = 1000;
  @Input() displaySelection: boolean = false;
  @Output() toggleSelectedTableVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}
}
