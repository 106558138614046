<header>
  <mat-icon class="success-icon">check_circle</mat-icon><h1>Success!</h1>
</header>
<button mat-flat-button class="close-button" (click)="closeSnackbar()">
  <mat-icon>close</mat-icon>
</button>
<p>
  You have successfully created a web journey (<a class="button-link" (click)="goToWebJourney()">{{journeyData.journey.name}}</a>).
  <span *ngIf="webJourneyStatus === EWebJourneyStatus.Paused">It is currently paused and must be manually run.</span>
  <span *ngIf="webJourneyStatus === EWebJourneyStatus.Running">It is running now and we will alert you when it is complete.</span>
  <span *ngIf="webJourneyStatus === EWebJourneyStatus.Scheduled">It is scheduled to run on {{getRunDate()}}.</span>
  <span *ngIf="webJourneyStatus === EWebJourneyStatus.Unscheduled">It is currently unscheduled and must be manually run.</span>
<div class="buttons">
  <button mat-flat-button class="bordered-button" (click)="createAnotherWebJourney()">
    <mat-icon>add_circle_outline</mat-icon>
    Create another web journey</button>
  <button mat-flat-button (click)="runWebJourneyNow()" *ngIf="showRunNowBtn">
    <mat-icon>loop</mat-icon>
    Run Now
  </button>
</div>
