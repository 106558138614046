<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="25"
              [strokeWidth]="4">
  </mat-spinner>
</div>

<div class="top-section" *ngIf="!loading">
  <share-report-btn *ngIf="reportType === 'audit'"
                    [auditId]="auditId"
                    [runId]="runId"
                    [pageId]="pageId"
                    [activeTab]="activeTab">
  </share-report-btn>
</div>
<rules-report *ngIf="!loading"
              [failed]="failedRuleReports"
              [notApplied]="notAppliedRuleReports"
              [passed]="passedRuleReports"
              [showEditButtons]="!userIsReadOnly"
              (editRule)="editRuleClicked($event)"
              (removeRule)="removeRuleClicked($event)"
              (setAsExpected)="setAsExpected($event)"
              (deleteVariable)="deleteVariable($event)">
</rules-report>

<button *ngIf="!loading && !userIsReadOnly"
        class="add-rule-button"
        mat-flat-button
        (click)="addRuleClicked()">
  <mat-icon>add</mat-icon> Add Rule
</button>
