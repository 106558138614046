import { Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  IAuditConsoleLogs,
  IAuditConsoleLogSummary
} from '@app/components/shared/components/audit-console-log-table/audit-console-log.models';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';

@Injectable()
export class AuditConsoleLogService extends AuditReportBaseService {

  /**
   * Caching below based on the following:
   * https://medium.com/@dilanthaprasanjith/caching-http-requests-with-rxjs-in-angular-9f7fa44387ce
   */

  constructor(
    private apiService: ApiService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);
  }

  getAuditPageConsoleLog(auditId: number, runId: number, pageId: string, params: {page: number, size: number}, sort: {sortBy: string, sortDesc: boolean}, includedLevels: string[] = [], search: string = ''): Observable<IAuditConsoleLogs> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/console-log?page=${params.page}&size=${params.size}&sortBy=${sort.sortBy}&sortDesc=${sort.sortDesc}`;

    return this.apiService
      .post<IAuditConsoleLogs>(requestUrl, {
        includedLevels,
        search
      });
  }

  getAuditPageConsoleLogSummarize(auditId: number, runId: number, pageId: string): Observable<IAuditConsoleLogSummary> {
    const requestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/pages/${pageId}/console-log-summary`;
    const cached = this.responseCache.get(requestUrl);

    if (cached) return of(cached);

    return this.apiService
      .post<IAuditConsoleLogSummary>(requestUrl, {})
      .pipe(tap(data => this.responseCache.set(requestUrl, data)));
  }
}
