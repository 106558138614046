import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-actions-menu',
  templateUrl: './cl-actions-menu.component.html',
  styleUrls: ['./cl-actions-menu.component.scss']
})
export class ComponentLibraryActionsMenuComponent {
}
