import { BulkAssignRulesModalComponent } from './buld-assign-rules-modal/bulk-assign-rules-modal.component';
import * as ngRedux from 'ng-redux';
import { Component, Inject, OnInit, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { userIsAdmin, userIsGuest } from '@app/authUtils';
import { AngularNames, Events, Features, Messages } from '@app/moonbeamConstants';

import { ILabel } from '@app/components/shared/services/label.service';
import { IApiError, IUser } from '@app/moonbeamModels';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { IFolder } from '@app/components/folder/foldersApiService';
import { StringUtils } from '@app/components/utilities/StringUtils';
import { IMultiSelectRequest } from '../shared/manage.models';
import { CardTypes } from './report-card-list/report-card-list.constants';
import { SplitterStatusDataService } from './report-card-list/services/splitter-status-data/splitter-status-data.service';
import {
  IAuditReportCard,
  IWebJourneyReportCard
} from './report-card-list/report-card-list.models';
import {
  ISort,
  ManageCommunicationService
} from '../shared/services/manage-communication/manage-communication.service';
import { ManageCardsDataService } from '../shared/services/manage-cards-data/manage-cards-data.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ICardViewParams, StorageService } from '@app/components/shared/services/storage.service';
import { AccountsService } from '@app/components/account/account.service';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';
import { ISortConfig } from './sort-and-group/sort-and-group.models';
import { IBulkAddLabels } from './bulk-action-bar/bulk-action-bar.models';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { OpDeleteItemWarningComponent } from '@app/components/shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { IAuditEditorCloseOptions } from '@app/components/audit/audit-editor/audit-editor.models';
import { WebJourneyEditorComponent } from '@app/components/web-journey/web-journey-editor/web-journey-editor.component';
import { CreateEditFolderComponent } from '@app/components/bulk-operations/manage-folders/create-edit-folder/create-edit-folder.component';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { EManageCardsViewMode } from "@app/components/manage/cards/manage-cards.constants";
import { CalendarViewService } from './calendar-view/calendar-view.service';

export class ReportCardViewGroupByTypes {
  static ungrouped: ReportCardViewGroupByType = 'ungrouped';
  static folders: ReportCardViewGroupByType = 'folders';
  static domains: ReportCardViewGroupByType = 'domains';
  static foldersAndDomains: ReportCardViewGroupByType = 'foldersAndDomains';
}

export class CardViewSortByTypes {
  static lastRunDate: CardViewSortByType = 'lastRunDate';
  static updatedAt: CardViewSortByType = 'updatedAt';
  static createdAt: CardViewSortByType = 'createdAt';
  static cardName: CardViewSortByType = 'name';
  static pageCount: CardViewSortByType = 'pageCount';
}

export class CardViewStartingGroup {
  type: 'folder' | 'domain';
  id: number;
}

export type ReportCardViewGroupByType = 'ungrouped' | 'folders' | 'domains' | 'foldersAndDomains';
export type CardViewSortByType = 'lastRunDate' | 'updatedAt' | 'createdAt' | 'name' | 'pageCount';

export enum ECardViewSortByType {
  LastRunDate = 'lastRunDate',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
  CardName = 'name',
  PageCount = 'pageCount'
}

export enum EReportCardViewGroupByType {
  Ungrouped = 'ungrouped',
  Folders = 'folders',
  Domains = 'domains',
  FoldersAndDomains = 'foldersAndDomains'
}

interface IGroupByOption {
  type: ReportCardViewGroupByType;
  label: string;
}

interface ISortByOption {
  type: CardViewSortByType,
  label: string
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-cards',
  templateUrl: './manage-cards.component.html',
  styleUrls: ['./manage-cards.component.scss'],
})
export class ManageCardsComponent implements OnInit, OnDestroy {

  Features = Features;

  cardListApi: any = {};
  allCardsExpanded: boolean;
  moveDomainId: number;
  isMovingItems: boolean = false;
  activeGroupByOption: ReportCardViewGroupByType;
  totalCardsCount: number = 0;
  filteredCardsCount: number = 0;
  isReadOnly: boolean;
  user: IUser;
  isAdmin: boolean;
  isWebJourneyAllowed = false;
  folderSearch: string = '';
  folderSearchDebounceSubscription: Subscription;
  groupByOptions: Array<IGroupByOption> = [];
  sortByOptions: Array<ISortByOption>;
  activeSortByOption: ECardViewSortByType;
  cardSort: ISort;
  sortDirection: ESortDirection = ESortDirection.desc;
  refreshContentEvent;
  cardSelectedEvent;
  cardDeselectedEvent;
  reportCardFiltersChangedEvent;
  selectedCardsCount: number = 0;
  selectedCards: any[] = [];
  scrollTo: CardViewStartingGroup;
  domains: IDomain[] = [];
  viewMode: EManageCardsViewMode;
  EManageCardsViewMode = EManageCardsViewMode;
  calendarViewEnabled: boolean = false;
  private destroy$: Subject<void> = new Subject();

  @ViewChild('createFolderInput') createFolderInput;
  @Output() runMethodOnServices = new EventEmitter<string>();

  // Sending a message to the BulkActionBarComponent to call (potentially) any method
  runMethodInManageCardsServices(message: string) {
    this.runMethodOnServices.emit(message);
  }

  constructor(
    @Inject(AngularNames.ngRedux) private ngRedux: ngRedux.INgRedux,
    public manageCardsDataService: ManageCardsDataService,
    private authenticationService: AuthenticationService,
    private manageCommunicationService: ManageCommunicationService,
    private accountsService: AccountsService,
    private eventManager: IEventManager,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private splitterStatusService: SplitterStatusDataService,
    public opModalService: OpModalService,
    private auditService: DiscoveryAuditService,
    private webJourneyService: IWebJourneyApiService,
    private domainsService: IDomainsService,
  ) {
  }

  ngOnInit() {
    // TODO: Remove once Calendar view goes live
    this.calendarViewEnabled = this.storageService.getValue<boolean>('calendarViewEnabled');
    //

    this.viewMode = this.manageCardsDataService.getCardViewMode();
    this.subscribeToEvents();
    this.setScrollToResource();
    this.setDefaultValuesForUI();

    this.updateDomains();
    this.handleUpdateDomainsOnNavigate();

    this.authenticationService.isFeatureAllowed(Features.webJourneys)
      .subscribe(isAllowed => this.isWebJourneyAllowed = isAllowed);

    this.accountsService.getUser().subscribe(user => {
      this.user = user;
      this.isReadOnly = userIsGuest(user);
      this.isAdmin = userIsAdmin(this.user);
    });
  }


  ngOnDestroy() {
    this.eventManager.unSubscribe(Messages.refreshContent, this.refreshContentEvent);
    this.eventManager.unSubscribe(Events.cardSelected, this.cardSelectedEvent);
    this.eventManager.unSubscribe(Events.cardDeselected, this.cardDeselectedEvent);
    this.eventManager.unSubscribe(Events.reportCardFiltersChanged, this.reportCardFiltersChangedEvent);
    this.destroy$.next();
  }

  handleUpdateDomainsOnNavigate(): void {
    this.route.queryParams.subscribe(params => {
      const { orderBy } = this.getCardViewParams();
      this.saveCardViewGroupBy(orderBy ?? ReportCardViewGroupByTypes.foldersAndDomains);

      if (params.itemType && (params.itemType === 'domain')) {
        if (params.isMovingItems) {
          this.isMovingItems = true;
          this.moveDomainId = params.itemId;
        }
      }
    });

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateDomains();
      }
    });
  }

  updateDomains() {
    this.domainsService.getDomains(true).then(domains => this.domains = domains);
  }

  public setViewMode(change: { value: EManageCardsViewMode }) {
    this.manageCardsDataService.setReportCardViewMode(change.value);
    this.eventManager.publish(Events.reportCardFiltersChanged);
  }

  setDefaultValuesForUI() {
    this.cardSort = { sortOn: 'lastRunDate', name: 'Date Run', reverse: false };
    let cardViewParams = this.getCardViewParams();

    if (!cardViewParams) {
      cardViewParams = { sortBy: ECardViewSortByType.LastRunDate };
    } else {
      if (!CardViewSortByTypes[cardViewParams.sortBy]) {
        cardViewParams.sortBy = ECardViewSortByType.LastRunDate;
      }
    }

    const defaultGroupByOption = ReportCardViewGroupByTypes.foldersAndDomains;
    this.activeGroupByOption = cardViewParams && cardViewParams.orderBy ? cardViewParams.orderBy : defaultGroupByOption;
    this.activeSortByOption = cardViewParams && cardViewParams.sortBy ? cardViewParams.sortBy : ECardViewSortByType.LastRunDate;
    this.sortDirection = cardViewParams && cardViewParams.sortDir ? cardViewParams.sortDir : ESortDirection.desc
    this.groupByOptions = this.initGroupByOptions();
    this.sortByOptions = this.initSortByOptions();

    this.setSort({ sortBy: this.activeSortByOption, sortDirection: this.sortDirection });

    this.allCardsExpanded = this.splitterStatusService.areAllSplittersExpanded();

    this.folderSearch = cardViewParams?.filterByName ? cardViewParams.filterByName : '';
  }

  subscribeToEvents() {
    this.refreshContentEvent = this.eventManager.subscribe(Messages.refreshContent, () => {
      this.cardListApi.reloadData();
    });

    this.cardSelectedEvent = this.eventManager.subscribe(Events.cardSelected, () => {
      this.selectedCardsCount = this.cardListApi?.getSelectedCount();
      this.selectedCards = this.cardListApi?.getSelectedCards();
    });

    this.cardDeselectedEvent = this.eventManager.subscribe(Events.cardDeselected, (item) => {
      this.cardListApi.deselectCard(item);
    });

    this.reportCardFiltersChangedEvent = this.eventManager.subscribe(Events.reportCardFiltersChanged, () => {
      this.viewMode = this.manageCardsDataService.getCardViewMode();
    });
  }

  setScrollToResource() {
    const snapshot = this.route.snapshot;
    const params = { ...snapshot.params, ...snapshot.queryParams };
    const { orderBy } = this.getCardViewParams() || { orderBy: ReportCardViewGroupByTypes.foldersAndDomains };

    if (params.itemType) {
      this.saveCardViewGroupBy(orderBy ?? ReportCardViewGroupByTypes.foldersAndDomains);

      this.scrollTo = {
        type: params.itemType,
        id: params.itemId
      };
    }
  }

  private saveCardViewGroupBy(orderBy: ReportCardViewGroupByType): void {
    let params: ICardViewParams = this.getCardViewParams() || {};
    params.orderBy = orderBy;
    this.setCardViewParams(params);
  }

  private saveCardSortBy(sortBy: ECardViewSortByType, sortDir: ESortDirection = ESortDirection.asc) {
    let params: ICardViewParams = this.getCardViewParams() || {};
    params.sortBy = sortBy;
    params.sortDir = sortDir;

    this.setCardViewParams(params);
  }

  private saveFilterByName(search: string) {
    let params: ICardViewParams = this.getCardViewParams() || {};
    params.filterByName = search;
    this.setCardViewParams(params);
  }

  setCardViewParams(params: ICardViewParams) {
    this.storageService.setReportCardViewParams(params);
  }

  getCardViewParams(): ICardViewParams {
    return this.storageService.getReportCardViewParams();
  }

  setSort(sortConfig: ISortConfig): void {
    this.activeSortByOption = sortConfig.sortBy;
    this.sortDirection = sortConfig.sortDirection;
    this.saveCardSortBy(this.activeSortByOption, this.sortDirection);
    this.cardSort = this.manageCommunicationService.setSort(this.activeSortByOption, this.sortDirection);
  }

  assignRules() {
    this.opModalService.openFixedSizeModal(BulkAssignRulesModalComponent, {
      data: {
        multiSelection: this.separateCardTypes(this.cardListApi.getSelectedCards())
      }
    }, 'bulk-assign-rules')
      .afterClosed()
      .subscribe(isAssigned => {
        if (isAssigned) {
          this.eventManager.publish(Events.deselectAllCards);
        }
      });
  }

  separateCardTypes(cards: Array<IAuditReportCard | IWebJourneyReportCard>): IMultiSelectRequest {
    return cards.reduce((accumulation, card) => {
      switch (card.type) {
        case CardTypes.audit: accumulation.audits.push(card); break;
        case CardTypes.webJourney: accumulation.webJourneys.push(card); break;
      }
      return accumulation;
    }, { audits: [], webJourneys: [] });
  }

  toggleAllCardsGroups(): void {
    if (this.isUngrouped()) return;
    this.allCardsExpanded = !this.allCardsExpanded;
  }

  private isUngrouped(): boolean {
    return this.activeGroupByOption === ReportCardViewGroupByTypes.ungrouped;
  }

  cardsChanged() {
    if (this.cardListApi && this.cardListApi.getFilteredCount) {
      setTimeout(() => {
        this.totalCardsCount = this.cardListApi.getCount();
        this.filteredCardsCount = this.cardListApi.getFilteredCount();
      });
    }
  }

  selectAll() {
    this.eventManager.publish(Events.selectAllCards);
  }

  selectNone() {
    this.eventManager.publish(Events.deselectVisibleCards);
  }

  generateSelectorByLabel(label: string): string {
    return StringUtils.replaceSpacesWith(label, '-').toLowerCase() + '-btn';
  }

  bulkDeleteConfirm(): void {
    this.opModalService
      .openModal(OpDeleteItemWarningComponent, {
        data: {
          name: 'something',
          itemType: 'Items',
          defaultMessage: false,
          additionalClasses: 'manage-cards_bulk-delete-modal',
          customMessage: this.getCustomBulkDeleteMessage()
        }
      })
      .afterClosed()
      .subscribe((canDelete: boolean) => {
        if (canDelete) {
          this.deleteSelectedItems(this.selectedCards);
          this.eventManager.publish(Events.deselectAllCards);
          this.snackBar.open(
            'Your data sources have been successfully deleted.',
            '',
            { duration: 5000, horizontalPosition: 'center', verticalPosition: 'top' }
          );
        }
      });
  }

  initGroupByOptions(): Array<IGroupByOption> {
    return [{
      type: ReportCardViewGroupByTypes.ungrouped,
      label: 'Ungrouped'
    }, {
      type: ReportCardViewGroupByTypes.folders,
      label: 'Folders'
    }, {
      type: ReportCardViewGroupByTypes.domains,
      label: 'Domains'
    }, {
      type: ReportCardViewGroupByTypes.foldersAndDomains,
      label: 'Folders and Domains'
    }];
  }

  initSortByOptions(): Array<ISortByOption> {
    return [{
      type: 'lastRunDate',
      label: 'Date Run'
    }, {
      type: 'updatedAt',
      label: 'Date Modified'
    }, {
      type: 'createdAt',
      label: 'Date Created'
    }, {
      type: 'name',
      label: 'A-Z'
    }];
  }

  updateGroup(value: ReportCardViewGroupByType): void {
    this.activeGroupByOption = value;
  }

  addBulkLabels(data: IBulkAddLabels): void {
    const { selectedCards, labels } = data;

    // add labels to cards
    selectedCards.forEach(card => {
      labels.forEach((labelToAdd: ILabel) => {
        if (card.labels.filter((label: ILabel) => label.id === labelToAdd.id).length === 0) {
          const newLabel = { id: labelToAdd.id, name: labelToAdd.name };
          const labels = [...new Set(card.labels.concat([newLabel]))] as any;

          // add to card
          card.labels.push(newLabel);

          // save to api
          switch (card.type) {
            case CardTypes.audit:
              this.addLabelToAuditCard(labels, card);
              break;

            case CardTypes.webJourney:
              this.addLabelToJourneyCard(labels, card);
              break;
          }
        }
      });
    });

    if (labels?.length) {
      this.eventManager.publish(Events.deselectAllCards);
      this.cardListApi.reloadData();
    }

    // triggers change detection so cards update
    window.dispatchEvent(new Event('resize'));
  }

  addLabelToAuditCard(labels: ILabel[], card: any): void {
    this.auditService.updateAuditLabels(card.id, labels)
      .then((updatedLabels: ILabel[]) => {
        card.labels = updatedLabels;
      })
      .catch((error: IApiError) => {
        this.showSnackbarError(error.message);
      });
  }

  addLabelToJourneyCard(labels: ILabel[], card: any): void {
    this.webJourneyService.updateWebJourneyLabels(card.id, labels)
      .then((updatedLabels: ILabel[]) => {
        card.labels = updatedLabels;
      })
      .catch(error => {
        this.showSnackbarError(error.message);
      });
  }

  showSnackbarError(errorMessage: string): void {
    this.snackBar.open(
      errorMessage,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom' }
    );
  }

  getCustomBulkDeleteMessage(): string {
    let message = '<div>Are you sure you want to permanently delete the following ';
    let numAudits = 0;
    let numWebJourneys = 0;
    let counts = [];
    let list = '';

    this.selectedCards.forEach((card: any) => {
      switch (card.type) {
        case CardTypes.audit:
          list += `<li>Audit: ${card.name}</li>`;
          numAudits++;
          break;

        case CardTypes.webJourney:
          list += `<li>Web Journey: ${card.name}</li>`;
          numWebJourneys++;
          break;
      }
    });

    if (numAudits) counts.push(`${numAudits} ${numAudits > 1 ? 'audits' : 'audit'}`);
    if (numWebJourneys) counts.push(`${numWebJourneys} ${numWebJourneys > 1 ? 'web journeys' : 'web journey'}`);

    message += `${counts.join(', ')}. Please note that this is irreversible.</div><br><ul>${list}</ul><br>`;

    return message;
  }

  deleteSelectedItems(selectedItems: any[]): void {
    let deleteRequests = [];
    let errorOccurred = false;

    selectedItems.forEach((card: any) => {
      switch (card.type) {
        case CardTypes.audit:
          deleteRequests.push(this.auditService.removeAudit(card.id));
          break;

        case CardTypes.webJourney:
          deleteRequests.push(this.webJourneyService.deleteJourney(card.id));
          break;
      }
    });

    forkJoin(deleteRequests)
      .pipe(catchError(() => {
        errorOccurred = true;
        return of();
      }))
      .subscribe(() => {
        if (errorOccurred) {
          this.snackBar.open(
            'There was an error attempting to delete some items.',
            '',
            { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom' }
          );
        }

        this.cardListApi.reloadData();
      });
  }

  createWebAudit() {
    this.opModalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data: '' }, 'op-audit-editor')
      .afterClosed()
      .subscribe((options?: IAuditEditorCloseOptions) => {
        if (options) this.router.navigateByUrl(this.router.url);
      });
  }

  createWebJourney() {
    this.opModalService.openFixedSizeModal(WebJourneyEditorComponent, { disableClose: true, data: '' }, 'op-webjourney-editor')
      .afterClosed()
      .subscribe(webJourney => {
        if (webJourney) this.router.navigateByUrl(this.router.url);
      });
  }

  openCreateNewFolderModal(): void {
    this.opModalService
      .openModal(CreateEditFolderComponent, {})
      .afterClosed()
      .subscribe((folder?: IFolder) => {
        this.updateDomains();
        this.eventManager.publish(Messages.refreshContent);

        if (folder) {
          this.snackBar.open(`${folder.name} folder has been successfully created`, '', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }
      });
  }
}
