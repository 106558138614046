import { Injectable } from '@angular/core';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import {
  IOpFilterBarV2Filter,
  IOpFilterBarV2MenuItem
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { OpFilterBarV2Service } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ETriggeredAlertsFilterBarMenuStrings,
  ETriggeredAlertsFilterTypes
} from './triggered-alerts-filter-bar.constants';
import { ITriggeredAlertsFilter } from './triggered-alerts-filter-bar.models';
import { subMonths } from 'date-fns';
import { DatePipe } from '@angular/common';
import { IFolder } from '@app/components/folder/foldersApiService';
import { AlertMetricType } from '@app/components/alert/alert-logic/alert-logic.enums';
import { MetricTypeFilterUtils } from '@app/components/alert/alert-logic/metric-type-filter/metric-type-filter.utils';
import {
  EOpFilterBarItemDatapickerRanges, IOpFilterBarItemDatepickerRangeState
} from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.models';
import { OpFilterBarItemDatepickerUtils } from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.utils';
import { DateRange } from '@angular/material/datepicker';

const FILTERS_STORAGE_KEY = 'triggered_alerts_filters';

@Injectable()
export class TriggeredAlertsFilterBarService extends OpFilterBarV2Service<ETriggeredAlertsFilterTypes> {
  constructor(
    storage: StorageService,
    cacheReset: CacheResetService,
    private datePipe: DatePipe
  ) {
    super(storage, FILTERS_STORAGE_KEY, cacheReset);
    this.updateSupportedFiltersList([
      ETriggeredAlertsFilterTypes.Name,
      ETriggeredAlertsFilterTypes.Label,
      ETriggeredAlertsFilterTypes.DataSourceName,
      ETriggeredAlertsFilterTypes.DataSourceLabel,
      ETriggeredAlertsFilterTypes.Subscribed,
      ETriggeredAlertsFilterTypes.Folder,
      ETriggeredAlertsFilterTypes.RunDateRange,
      ETriggeredAlertsFilterTypes.ReportMetric
    ]);
  }

  get filters$(): Observable<IOpFilterBarV2Filter<ETriggeredAlertsFilterTypes>[]> {
    return this.relevantFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters
          .filter((filter: ITriggeredAlertsFilter) => this.validTypes.includes(filter.type))
          .reduce((acc, curr) => {
            acc.push(curr);
            return acc;
          }, [])
      )
    );
  }

  protected removeInvalidFilters(filters: IOpFilterBarV2Filter<ETriggeredAlertsFilterTypes>[]): IOpFilterBarV2Filter<ETriggeredAlertsFilterTypes>[] {
    if (!filters) return [];

    return filters.filter(filter => {
        if (filter.type === ETriggeredAlertsFilterTypes.RunDateRange) {
          const value = filter.value[0];
          /**
           * Backward-compatibility check
           * Before, value format was: { from: Date, to: Date }
           * Now:                      { type: number, dates: { start: Date, end: Date } }
           */
          return Number.isInteger(value.type) && value.dates;
        }
        return true;
      })
  }

  protected createUniqueArrayOfFilters(filters: IOpFilterBarV2Filter<ETriggeredAlertsFilterTypes>[]): IOpFilterBarV2Filter<ETriggeredAlertsFilterTypes>[] {
    if (!filters) return [];

    // Unique filters
    const filterStringsToObjects = filters
      .reduce<{ [key: string]: IOpFilterBarV2Filter<ETriggeredAlertsFilterTypes> }>((acc, filter) => {
        if (filter.type === ETriggeredAlertsFilterTypes.RunDateRange) {
          /**
           * In localstorage, dates are stored as strings, so we need to convert them back to Date objects
           */
          const { type, dates } = filter.value[0];
          const updatedValue = {
            type,
            dates: new DateRange<Date>(
              new Date(dates.start),
              new Date(dates.end)
            )
          };

          /**
           * Also, dates can be outdated for the predefined ranges
           * For example, if we have a predefined range 'Last 30 days', that was selected yesteday,
           * 30 days will be from yesterday, not from today
           * So, we need to update the dates
           */
          filter.value = [OpFilterBarItemDatepickerUtils.prepareRangeState(updatedValue)];
        }

        acc[JSON.stringify(filter)] = filter;
        return acc;
      }, {});

    return Object.values(filterStringsToObjects);
  }

  handleFolderFilter(checked: boolean, item: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false, folders: IFolder[], domains: any): void {
    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);
    const folderIds = folders.map(folder => folder.id);

    // When using the 'only' button, clear out the existing filter values before adding the new selection
    if (replaceAll) {
      this.addFolderFilter(checked, menuItems, replaceAll, [], numChecked);
      replaceAll = false;
    }

    // If user clicked a Folder checkbox
    if (folderIds.includes(item.id)) {
      // If checking a folder, add all subfolder ids to values array if not already there
      if (checked) {
        domains[item.id]?.forEach(domain => {
          this.addFolderFilter(checked, menuItems, replaceAll, [domain.id], numChecked);
        });

        // If unchecking a folder, remove all subfolder ids from values array
      } else {
        // Deselect all domains in the deselected folder
        domains[item.id]?.forEach(domain => {
          this.addFolderFilter(checked, menuItems, replaceAll, [domain.id], numChecked);
        });
      }
    } else {
      // Add subfolder
      this.addFolderFilter(checked, menuItems, replaceAll, [item.id], numChecked);
    }

    item.checked = checked;
  }

  addFolderFilter(checked: boolean, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false, value: number[], numChecked: number): void {
    this.addFilter(
      {
        type: ETriggeredAlertsFilterTypes.Folder,
        display: `Folders (${numChecked})`,
        value,
        menuName: ETriggeredAlertsFilterBarMenuStrings.FolderAndSubFolder,
        icon: 'folder',
        menuItems,
        order: 6
      },
      replaceAll,
      numChecked,
      checked
    );
  }

  handleDataRangePickerFilter(contains: IOpFilterBarItemDatepickerRangeState,
                              menuItems: IOpFilterBarV2MenuItem[],
                              replaceAll: boolean = true) {
    let display = `Run Date Range:&nbsp;`;

    if (contains.type === EOpFilterBarItemDatapickerRanges.CustomDateRange) {
      const formatDate = (date: Date) => this.datePipe.transform(date, 'MMM d, yy');

      if (contains.dates.end) {
        if (formatDate(contains.dates.start) === formatDate(contains.dates.end)) {
          display += `<strong>${formatDate(contains.dates.start)}</strong>`;
        } else {
          display += `<strong>${formatDate(contains.dates.start)} - ${formatDate(contains.dates.end)}</strong>`;
        }
      } else {
        display += `<strong>${formatDate(subMonths(new Date(), 13))} - ${formatDate(new Date())}</strong>`;
      }
    } else {
      if (EOpFilterBarItemDatapickerRanges.Last30Days === contains.type) {
        display += `<strong>Last 30 Days</strong>`;
      }

      if (EOpFilterBarItemDatapickerRanges.Last60Days === contains.type) {
        display += `<strong>Last 60 Days</strong>`;
      }

      if (EOpFilterBarItemDatapickerRanges.Last90Days === contains.type) {
        display += `<strong>Last 90 Days</strong>`;
      }

      if (EOpFilterBarItemDatapickerRanges.AllAvailableDays === contains.type) {
        display += `<strong>All Available Days</strong>`;
      }
    }

    this.addFilter({
      type: ETriggeredAlertsFilterTypes.RunDateRange,
      display,
      unremovable: true,
      value: [contains],
      menuItems,
      order: 1
    }, replaceAll);
  }

  handleAlertNameContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: ETriggeredAlertsFilterTypes.Name,
      display: `Alert Name contains '${contains}'`,
      value: [contains],
      menuItems: [],
      icon: 'search',
      order: 2
    }, replaceAll);
  }

  handleDataSourceNameContainsFilter(contains: string, replaceAll: boolean = true) {
    if (contains.length === 0) return;

    this.addFilter({
      type: ETriggeredAlertsFilterTypes.DataSourceName,
      display: `Data Source Name contains '${contains}'`,
      value: [contains],
      menuItems: [],
      icon: 'search',
      order: 2
    }, replaceAll);
  }

  handleAlertsLabelsFilter(checked: boolean, label: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false): void {
    label.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    this.addFilter(
      {
        type: ETriggeredAlertsFilterTypes.Label,
        display: `Alerts Labels (${numChecked})`,
        value: [label.id],
        menuName: ETriggeredAlertsFilterBarMenuStrings.AlertsLabels,
        menuItems: menuItems,
        icon: 'label',
        order: 3
      },
      replaceAll,
      numChecked,
      checked
    );
  }

  handleDataSourcesLabelFilter(checked: boolean, label: IOpFilterBarV2MenuItem, menuItems: IOpFilterBarV2MenuItem[], replaceAll: boolean = false): void {
    label.checked = checked;

    // get count of checked items
    const numChecked = this.getNumChecked(menuItems);

    this.addFilter(
      {
        type: ETriggeredAlertsFilterTypes.DataSourceLabel,
        display: `Data Sources Labels (${numChecked})`,
        value: [label.id],
        menuName: ETriggeredAlertsFilterBarMenuStrings.DataSourceLabels,
        menuItems: menuItems,
        icon: 'label',
        order: 3
      },
      replaceAll,
      numChecked,
      checked
    );
  }

  handleSubscribedAlertsFilter(checked: boolean): void {
    if (!checked) {
      this.removeFilterByType(ETriggeredAlertsFilterTypes.Subscribed);
      return;
    }

    this.addFilter(
      {
        type: ETriggeredAlertsFilterTypes.Subscribed,
        display: `Subscribed Alerts Only`,
        value: checked ? [true] : [],
        menuName: ETriggeredAlertsFilterBarMenuStrings.OnlyShowMonitored,
        menuItems: [],
        icon: 'visibility',
        order: 4
      }
    );
  }

  handleReportMetricFilter(reportName: string,
                           metricName: string,
                           metricType: AlertMetricType,
                           menuItems: IOpFilterBarV2MenuItem[]): void {
    this.addFilter(
      {
        type: ETriggeredAlertsFilterTypes.ReportMetric,
        display: MetricTypeFilterUtils.getFilterDisplay(reportName, metricName),
        value: [metricType],
        menuName: ETriggeredAlertsFilterBarMenuStrings.ReportMetric,
        menuItems: menuItems,
        order: 5
      },
      true
    );
  }

  getNumChecked(menuItems: IOpFilterBarV2MenuItem[]): number {
    let count = 0;

    menuItems.forEach(item => {
      if (item.checked && !item.children) count++;
      if (item.children?.length) {
        item.children.forEach(child => {
          if (child.checked) count++;
        });
      }
    });

    return count;
  }

  onMenuClosed(): void {
    this.currentFilters.forEach(filter => {
      if (filter.value.length === 0) this.removeFilterByType(filter.type);
    });
  }

  getFilterValues(rawFilters) {
    let filters = {};
    rawFilters.forEach(filter => filters[filter.type] = filter);

    let filterByName = filters[ETriggeredAlertsFilterTypes.Name]?.value?.length > 0;
    let filterByLabel = filters[ETriggeredAlertsFilterTypes.Label]?.value?.length > 0;
    let filterByMonitored = filters[ETriggeredAlertsFilterTypes.Subscribed]?.value?.length > 0;

    return {
      filters,
      filterByName,
      filterByLabel,
      filterByMonitored
    };
  }

  filterByName(card, filters): boolean {
    return !!filters[ETriggeredAlertsFilterTypes.Name].value.find(value => {
      return card.name?.toLowerCase().includes(value.toLowerCase());
    });
  }

  filterByLabel(card, filters): boolean {
    const labelFilterValues = filters[ETriggeredAlertsFilterTypes.Label]?.value;
    let found = [];
    labelFilterValues.forEach(labelId => {
      let labelIndex = card.labels.findIndex(label => labelId === label.id);
      labelIndex > -1 ? found.push(true) : found.push(false);
    });
    return found.includes(false);
  }
}
