<div class="spinner-wrap" *ngIf="loading">
  <mat-spinner [diameter]="50" [strokeWidth]="6"></mat-spinner>
</div>
<audit-report-header-popover *ngIf="!loading && !hasSnapshotAlerts && !hasAssignedAlerts"
                             [auditId]="auditId"
                             [runId]="runId"
                             [user]="user"
                             [accountHasAlerts]="accountHasAlerts"
                             [popoverMode]="false">
</audit-report-header-popover>
<div *ngIf="!loading && hasSnapshotAlerts && (dataSource.data.length || currentFilters.length) && !hasTriggeredAlerts"
     class="no-triggered-alerts-banner">
  <div class="heading">Great News!</div>
  <div class="message" *ngIf="alertsCount <= 1">
    There was 1 alert applied to this audit that you were subscribed to, and that alert was not triggered in the latest run.
  </div>

  <div class="message" *ngIf="alertsCount > 1">
    Out of the {{ alertsCount }} alerts that you are subscribed to in this audit,
    {{ alertsCount === 2 ? 'neither' : 'none' }} of them were triggered in the latest run.
  </div>
</div>
<div class="table-wrap" [class.has-alerts]="!loading && (hasSnapshotAlerts || hasAssignedAlerts)" #alertSummaryTableScrollTo>
  <div class="section-title-wrap">
    <div class="title-spinner-wrap">
      <filter-spinner [state]="tableState"></filter-spinner>
      <p class="section-title">Alerts</p>
    </div>
    <op-audit-report-export-menu
      class="audit-report-export-menu"
      [auditId]="auditId"
      [runId]="runId"
      [tableCompletedState]="tableState"
      [transformSortingToSnakeCase]="false"
      [config]="exportAlertsConfig">
    </op-audit-report-export-menu>
  </div>
  <table mat-table
          matSort
          [dataSource]="dataSource"
          [class.updating]="tableState === EFilterSpinnerState.Loading"
          class="alert-summary-table"
          [matSortActive]="EAlertSummaryTableColumn.alertName"
          matSortDirection="asc"
          matSortStart='desc'>
    <ng-container [matColumnDef]="EAlertSummaryTableColumn.alertName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ALERT NAME</th>
      <td mat-cell *matCellDef="let alert"
          (click)="openAlertDesigner(alert, EAlertStep.Logic)"
          class="alert-name-col"
          [title]="alert.alertConfigExists ? (!alert.isCurrentlyAssigned ? NOT_ASSIGNED_MSG : '') : DELETED_MSG">
        <span [class.hover-state]="alert.alertConfigExists">
          {{ alert.name }}
          <mat-icon class="edit-icon">edit</mat-icon>
        </span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="EAlertSummaryTableColumn.alertRuleLogic">
      <th mat-header-cell *matHeaderCellDef>ALERT RULE LOGIC</th>
      <td mat-cell *matCellDef="let alert"
          (click)="openAlertDesigner(alert, EAlertStep.Logic)"
          [title]="alert.alertConfigExists ? (!alert.isCurrentlyAssigned ? NOT_ASSIGNED_MSG : '') : DELETED_MSG">
        <span [class.hover-state]="alert.alertConfigExists">
          <alert-logic-details [metricType]="alert?.metricType"
                               [operator]="alert?.operator"
                               [targetValue]="alert?.targetValue"
                               [showFiltersList]="true"
                               [showTooltip]="true"
                               [filters]="alert?.filters"
                               [clickable]="alert.alertConfigExists ? undefined : false">
          </alert-logic-details>
        </span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="EAlertSummaryTableColumn.statusValue">
      <th mat-header-cell *matHeaderCellDef class="center-text status-col">STATUS/VALUE</th>
      <td mat-cell *matCellDef="let alert"
          class="center-text status-col always-interactive-column"
          [ngClass]="alert.statusValueClass"
          (click)="openAuditReport(alert)"
      >
        <div class="status-wrap"
             [matTooltip]="getStatusTooltip(alert)"
             matTooltipShowDelay="750"
             matTooltipPosition="above"
        >
          <mat-icon class="triggered-icon status-icon">warning_amber</mat-icon>
          <mat-icon class="not-triggered-icon status-icon">check_circle</mat-icon>
          <ng-container *ngIf="alert.metricChangeType === 'VALUE_CHANGE' && (alert.operator.comparator === 'GREATER_OR_EQUAL' || alert.operator.comparator === 'LESS_OR_EQUAL') && alert.statusValue > 0">
            <mat-icon class="change-by-icon">arrow_upward</mat-icon>
          </ng-container>
          <ng-container *ngIf="alert.metricChangeType === 'VALUE_CHANGE' && (alert.operator.comparator === 'GREATER_OR_EQUAL' || alert.operator.comparator === 'LESS_OR_EQUAL') && alert.statusValue < 0">
            <mat-icon class="change-by-icon">arrow_downward</mat-icon>
          </ng-container>
          <span class="metric-value">{{ getMetricValue(alert) }}</span>
          <mat-icon class="redirect-arrow">arrow_forward</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="EAlertSummaryTableColumn.alertHistory">
      <th mat-header-cell *matHeaderCellDef class="center-text">ALERT HISTORY</th>
      <td mat-cell
          *matCellDef="let alert"
          class="center-text always-interactive-column"
          (click)="openFullscreenChart(alert)"
      >
        <mat-icon class="alert-history-icon">analytics</mat-icon>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="EAlertSummaryTableColumn.subscribed">
      <th mat-header-cell *matHeaderCellDef class="center-text">SUBSCRIBED</th>
      <td mat-cell
          *matCellDef="let alert"
          class="center-text not-assigned-interactive-column"
          (click)="openAlertDesigner(alert, EAlertStep.Notification)"
          [title]="alert.alertConfigExists ? '' : DELETED_MSG">
        <div class="subscribed-col-wrap">
          <!-- hard coding the count below so the icon will appear -->
          <alert-bell [class.neutral-color]="!alert.isUserSubscribed" [status]="alert.subscribedStatus" [showBellAlways]="true" class="bell-icon"></alert-bell>
          {{ alert.subscribedCount }}
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="EAlertSummaryTableColumn.edit">
      <th mat-header-cell *matHeaderCellDef class="center-text edit-col"></th>
      <td mat-cell *matCellDef="let alert"
          class="center-text not-assigned-interactive-column"
          [title]="alert.alertConfigExists ? (!alert.isCurrentlyAssigned ? NOT_ASSIGNED_MSG : '') : DELETED_MSG">
        <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!alert.alertConfigExists">
          <mat-icon class="three-dot-menu">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openAlertDesigner(alert, EAlertStep.Logic)">
            Edit Alert
          </button>
          <button *ngIf="alert.isCurrentlyAssigned" mat-menu-item (click)="handleAlertAssignmentState(alert)">
            Unassign from this audit
          </button>
          <button *ngIf="!alert.isCurrentlyAssigned" mat-menu-item (click)="handleAlertAssignmentState(alert)">
            Reassign to this audit
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let alert; columns: displayedColumns;"
        [class.not-assigned-alert-row]="!alert.isCurrentlyAssigned && alert.alertConfigExists"
        [class.deleted-alert-row]="!alert.alertConfigExists"
    ></tr>

  </table>
  <mat-paginator class="summary-paginator"
                  [length]="totalNumPages"
                  [pageSize]="pageSize"
                  [hidePageSize]="true">
  </mat-paginator>
</div>
<canvas #confettiCanvas class="confetti-canvas"></canvas>
