<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="{{ title }} File Substitution Config"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="rfm-editor">
      <form [formGroup]="rfmForm">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" autocomplete="off">
          <mat-error *ngIf="name.errors?.required">This field is required</mat-error>
        </mat-form-field>
        <rfm-picker [config]="rfmConfigModel"
                    [multi]="false"
                    [hideTitle]="true"
                    [submitted]="submitted"
                    (onConfigUpdate)="remoteFileMapConfigChanged($event)"
                    (onValidationUpdate)="remoteFileMapValidationChanged($event)">
        </rfm-picker>
      </form>
    </div>
  </ng-template>
</op-modal>