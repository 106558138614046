import { LiveConnectUrlBuilders } from './../live-connect.constants';
import { IHistoryFrame, RouteHistoryService } from '@app/components/shared/services/route-history.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as angular from 'angular';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import {
  IDeviceCategory,
  DeviceProfileService,
  EDeviceProfile,
  EMobileOs,
  ETvOs,
  EPcOs,
  IDeviceProfile,
  IDeviceProfileRequest
} from '@app/components/live-connect/device-profile.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { IFoldersApiService, IFolder, INewFolder } from '@app/components/folder/foldersApiService';
import { IOpModalBaseContent } from '@app/components/shared/components/op-modal';
import { IButton } from '@app/models/commons';
import { Observable, of, from } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';
import { OpSelectCreateComponent } from '@app/components/shared/components/op-select-create/op-select-create.component';
import { IUser } from '@app/moonbeamModels';
import { AngularNames } from '@app/moonbeamConstants';
import { IFolderActionsService } from '@app/store/actions/folderActions';
import { environment } from '@app/environments/environment';
import { IDeviceProfileStateParams } from './device-profile.models';
import { AccountsService } from '@app/components/account/account.service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { ILiveConnectRouterState } from '@app/components/live-connect/live-connect/live-connect.component';

export type ProfileCategories = { [deviceProfileType: string]: IDeviceCategory };

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'device-profile-creator',
  templateUrl: './device-profile-creator.component.html',
  styleUrls: ['./device-profile-creator.component.scss'],
})
export class DeviceProfileCreatorComponent implements OnInit, IOpModalBaseContent {
  title: string = '';
  rightFooterButtons?: IButton[] = [
    {
      label: 'Save & Close',
      action: () => this.saveAndClose()
    },
    {
      label: 'Save & Record a Journey',
      action: () => this.saveAndRecord(),
      primary: true
    }
  ];

  params: IDeviceProfileStateParams;

  categories: ProfileCategories;
  categoryImgs: { [deviceProfileType: string]: string };
  activeCategoryKey: EDeviceProfile;
  deviceProfileForm: UntypedFormGroup;
  labels: ILabel[];
  selectedLabels: ILabel[];
  folders: IFolder[];
  deviceOSList: Array<EMobileOs | ETvOs | EPcOs>;
  deviceProfileId: number;
  certLink: string = `${environment.apiProtocol}://${environment.apiHost}/v2/certs/ab436`;
  foldersPromise;
  submitted: boolean = false;
  user: IUser;
  qrCodeFilename: string;

  @ViewChild('osDevice') osDeviceSelector: OpSelectCreateComponent;

  constructor(
    @Inject(AngularNames.ngRedux) private ngRedux: ngRedux.INgRedux,
    private deviceProfileService: DeviceProfileService,
    private formBuilder: UntypedFormBuilder,
    private labelService: LabelService,
    private foldersServicer: IFoldersApiService,
    private router: Router,
    private route: ActivatedRoute,
    private routeHistoryService: RouteHistoryService,
    private accountsService: AccountsService,
    private folderActions: IFolderActionsService,
  ) { }

  ngOnInit() {
    this.params = {...this.route.snapshot.params, ...this.route.snapshot.queryParams} as IDeviceProfileStateParams;

    this.createForm();

    this.categories = this.deviceProfileService.getDeviceCategories();
    this.categoryImgs = this.initCategoryImgs(this.categories);
    this.getLabels();
    this.loadUser();
    this.foldersPromise = this.getFolders();
    let profileId = parseInt(this.params.dpId);
    let type = this.params.type;

    if (profileId) {
      this.deviceProfileId = profileId;
      this.initEdit(profileId);
    } else {
      this.activeCategoryKey = (this.categories[type] || this.categories['mobile']).deviceProfileType;
      this.setDeviceOSList(this.categories[this.activeCategoryKey]);
    }

    this.qrCodeFilename = environment.domain === 'observepoint.com'
      ? 'qr-code-prod.png'
      : 'qr-code-staging.png';
  }

  private createForm() {
    this.deviceProfileForm = this.formBuilder.group({
      deviceModel: this.formBuilder.control('', Validators.required),
      //OS version is not needed on the UI anymore, but is still required by the API, see https://observepoint.atlassian.net/browse/WORK-26317
      osVersion: this.formBuilder.control('stubVersion', Validators.required),
      folder: this.formBuilder.control('', Validators.required),
      deviceOs: this.formBuilder.control('', Validators.required),
      labels: this.formBuilder.control([])
    });
    this.selectedLabels = [];
  }

  private initEdit(profileId: number) {
    this.deviceProfileService.getADeviceProfile(profileId)
      .subscribe(deviceProfile =>  {
        this.loadSelectedLabels(deviceProfile.id);
        this.handleCategoryClick(this.categories[deviceProfile.journeyType]);
        this.foldersPromise.then(folders => {
          this.deviceProfileForm.get('folder').setValue(folders.find(f => f.id === deviceProfile.folderId));
        });
        this.deviceProfileForm.get('deviceModel').setValue(deviceProfile.name);
        this.deviceProfileForm.get('osVersion').setValue(deviceProfile.versionOfOs);
        const dpOsType = this.categories[deviceProfile.journeyType].osTypes.find(osType => osType == deviceProfile.osOfDevice);
        if (!dpOsType) {
          this.osDeviceSelector.switchMode();
        }
        this.deviceProfileForm.get('deviceOs').setValue(deviceProfile.osOfDevice);
      });
  }

  private loadSelectedLabels(deviceProfileId: number): void {
    this.deviceProfileService.getDeviceProfileLabels(deviceProfileId)
      .toPromise()
      .then((labels: Array<ILabel>) => {
        this.selectedLabels = labels;
      });
  }

  private getLabels(): void {
    this.labelService.getLabels().subscribe(labels => {
      this.labels = labels;
    });
  }

  private loadUser(): void {
    this.accountsService.getUser().subscribe((user: IUser) => {
      this.user = user;
    });
  }

  private getFolders(): angular.IPromise<IFolder[]> {
    return this.foldersServicer.getFolders().then(folders => {
      this.folders = folders;
      return folders;
    });
  }

  private setDeviceOSList(category: IDeviceCategory) {
    this.deviceOSList = category.osTypes;
  }

  private initCategoryImgs(categories: ProfileCategories): { [deviceProfileType: string]: string } {
    var categoryImgs: { [deviceProfileType: string]: string } = {};
    for (var deviceProfileType in categories) {
      categoryImgs[deviceProfileType] = categories[deviceProfileType].imgDefault;
    }
    return categoryImgs;
  }

  handleCategoryClick(category: IDeviceCategory) {
    if (this.activeCategoryKey != category.deviceProfileType) {
      this.osDeviceSelector.clear();
      this.activeCategoryKey = category.deviceProfileType;
    }

    this.setDeviceOSList(category);
  }

  close(dp: IDeviceProfile = {} as IDeviceProfile): void {
    const fallbackUrl = (dp.journeyType && dp.id) ?
      LiveConnectUrlBuilders.liveConnectSelected(dp.journeyType, dp.id) :
      LiveConnectUrlBuilders.base();
    const extras = (dp.journeyType && dp.id) ? {
      state: {
        deviceProfileType: dp.journeyType
      } as ILiveConnectRouterState
    } : {};
    this.router.navigateByUrl(fallbackUrl, extras);
  }

  private disableSaveButtons(): void {
    this.rightFooterButtons.forEach(b => b.disabled = true);
  }

  saveAndClose(): void {
    if (!this.validate()) return;
    this.disableSaveButtons();
    this.saveDeviceProfile()
      .subscribe(dp => this.close(dp));
  }

  saveAndRecord(): void {
    if (!this.validate()) return;
    this.disableSaveButtons();
    this.saveDeviceProfile()
      .subscribe((dp) => this.createManualJourney(dp));
  }

  private validate(): boolean {
    if (this.deviceProfileForm.invalid) {
      this.submitted = true;
      return false;
    }
    return true;
  }

  createManualJourney(dp: IDeviceProfile): void {
    this.router.navigateByUrl(LiveConnectUrlBuilders.manualJourneyCreate(dp.id));
  }

  private saveDeviceProfile(): Observable<IDeviceProfile> {
    return this.handleFolder().pipe(
      switchMap(() => {
        return this.updateOrCreateDeviceProfile(this.deviceProfileId)
        .pipe(tap(dp => {
          this.deviceProfileService.saveNewDeviceProfileId(dp.id);
        }));
      })
    );
  }

  private handleFolder(): Observable<any> {
    const folder = this.deviceProfileForm.get('folder').value;
    return (folder && !folder.id)
      ? this.createFolder(folder).pipe(map((f: IFolder) => {
        this.folders.push(f);
        this.deviceProfileForm.get('folder').setValue(f);
      }))
      : of({});
  }

  createFolder(folderName: string): Observable<IFolder> {
    var newFolder: INewFolder = {
      accountId: this.user.accountId,
      name: folderName
    };
    return from(this.ngRedux.dispatch(this.folderActions.createFolder(newFolder)) as Promise<IFolder>);
  }

  private updateOrCreateDeviceProfile(dpId?: number): Observable<IDeviceProfile> {
    if (dpId) {
      return this.deviceProfileService.updateDeviceProfile(dpId, this.buildDeviceProfileRequest())
        .pipe(tap(dp => {
          this.deviceProfileService.updateDeviceProfileLabels(dp.id, this.selectedLabels).subscribe();
        }));
    } else {
      return this.deviceProfileService.createDeviceProfile(this.buildDeviceProfileRequest())
        .pipe(tap(dp => {
          this.deviceProfileService.updateDeviceProfileLabels(dp.id, this.selectedLabels).subscribe();
        }));
    }
  }

  private buildDeviceProfileRequest(): IDeviceProfileRequest {
    const osOfDevice = this.deviceProfileForm.get('deviceOs').value;
    return {
      name: this.deviceProfileForm.get('deviceModel').value,
      journeyType: this.categories[this.activeCategoryKey].deviceProfileType,
      folderId: this.deviceProfileForm.get('folder').value.id,
      emails: [],
      osOfDevice,
      versionOfOs: this.deviceProfileForm.get('osVersion').value,
      useTransparentProxy: this.deviceProfileService.shouldUseTransparentProxy(osOfDevice)
    };
  }
}
