<div class="op-snackbar-container">
  <div class="message-wrap">
    <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    <div class="message" [innerHTML]="message"></div>
  </div>
  <div class="btn-wrap">
    <button *ngFor="let button of additionalButtons"
            (click)="button.action()"
            color="secondary">
      {{ button.label }}
    </button>
    <button (click)="close()">{{ closeBtnLabel }}</button>
  </div>
</div>