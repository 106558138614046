import { Component, Input } from '@angular/core';

@Component({
  selector: 'op-beta-label',
  template: `
  <div class="beta-label-stack">
    <div class="beta-label" *ngIf="showLabel">BETA</div>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
  `,
  styleUrls: ['./op-beta-label.component.scss']
})
export class OpBetaLabelComponent {
  private isLabelVisible: boolean = false;

  get showLabel(): boolean {
    return this.isLabelVisible;
  }
  @Input()
  set showLabel(value: boolean) {
    this.isLabelVisible = String(value) == 'true';
  }
}
