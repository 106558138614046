<op-accordion>
  <mat-accordion displayMode="flat">
    <mat-expansion-panel *ngFor="let report of reports" hideToggle="true"
      op-selector="not-applied-rule-expansion-panel">
      <mat-expansion-panel-header>
        <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
        <div class="rule-title">{{ report.ruleName }}</div>
        <div class='icons' *ngIf='showEditButtons'>
          <span class="icon edit-icon" (click)="handleEdit($event, report.ruleId)" op-selector="easy-rule-edit-button">
            <mat-icon class="edit">edit</mat-icon>
          </span>
          <span class="icon delete-icon" (click)="handleRemove($event, report.ruleId)"
            op-selector="easy-rule-remove-button">
            <mat-icon class="delete">delete</mat-icon>
          </span>
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <rule-results-table>
          <table mat-table [dataSource]="report.items">
            <!-- Filter Column -->
            <ng-container matColumnDef="filter">
              <th mat-header-cell *matHeaderCellDef>Filter</th>
              <td mat-cell *matCellDef="let element">{{element.name}}</td>
            </ng-container>

            <!-- Expected Column -->
            <ng-container matColumnDef="expected">
              <th mat-header-cell *matHeaderCellDef class="expected-value-header">Expected Value</th>
              <td mat-cell class="expected-value break-all" *matCellDef="let element">{{element.expected}}</td>
            </ng-container>

            <!-- Actual Column -->
            <ng-container matColumnDef="actual">
              <th mat-header-cell *matHeaderCellDef class="actual-value-header">Actual Value</th>
              <td mat-cell class="actual-value break-all" *matCellDef="let element">{{element.actual}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['filter', 'expected', 'actual']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['filter', 'expected', 'actual'];"></tr>
          </table>
        </rule-results-table>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</op-accordion>