import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EmailInboxesPaths } from '@app/components/email-inboxes/email-inboxes.constants';

@Component({
  selector: 'op-email-inbox-messages-header',
  templateUrl: './email-inbox-messages-header.component.html',
  styleUrls: ['./email-inbox-messages-header.component.scss']
})
export class EmailInboxMessagesHeaderComponent {
  constructor(private router: Router) {}

  @Input() title: string = '';
  @Input() loading: boolean = false;

  @HostListener('document:keydown.escape')
  closeInboxMessages(): void {
    this.router.navigate([EmailInboxesPaths.base]);
  }
}
