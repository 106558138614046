import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'share-report-success-snackbar',
  templateUrl: './share-report-snackbar.component.html',
  styleUrls: ['./share-report-snackbar.component.scss']
})
export class ShareReportSnackbarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data,
  ){}
}
