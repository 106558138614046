import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { pointer, select, Selection } from 'd3-selection';
import { hierarchy, tree } from 'd3-hierarchy';
import { tip as d3Tip } from '../../../../reporting/widgets/d3-tip';
import { zoom, zoomIdentity } from 'd3-zoom';
import { TagInitiatorsService } from '@app/components/shared/components/tag-initiators/tag-initiators.service';
import {
  ITagInitiatorNode,
  ITagInitiatorNodeExtended,
  ITagInitiatorTooltip
} from '@app/components/shared/components/tag-initiators/tag-initiators.models';
import * as d3 from 'd3';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

const NODE_SIZE = 30;
const VERTICAL_MARGIN = 10;
const HORIZONTAL_MARGIN = 200;
const HORIZONTAL_SPACER = 600;
const DURATION = 750;
const MAX_NODE_TITLE_LEN = 30;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-initiators-svg',
  templateUrl: './tag-initiators-svg.component.html',
  styleUrls: ['./tag-initiators-svg.component.scss']
})
export class TagInitiatorsSvgComponent implements OnInit, OnChanges, OnDestroy {

  nodeCount = 0;
  rootNode: ITagInitiatorNodeExtended;
  tooltipSelection: any;
  activeTooltip: any;
  containerElementWidth: number;
  containerElementHeight: number;
  fullscreen: boolean = false;
  svgContainerId: string;

  @Input() initiatorsData: ITagInitiatorNode;
  @Input() showFullScreenButton?: boolean = false;
  @Output() openInitiatorsFullScreen: EventEmitter<null> = new EventEmitter();

  constructor(private tagInitiatorsService: TagInitiatorsService) { }

  ngOnInit(): void {
    this.svgContainerId = this.generateUniqueSvgContainerId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.initiatorsData) {
      setTimeout(() => this.drawInitiatorTree(this.initiatorsData));
    }
  }

  ngOnDestroy(): void {
    // hide any active tooltips if the user
    // navigates away from initiators
    this.activeTooltip = null;
    this.tooltipSelection?.hide();
  }

  generateUniqueSvgContainerId(): string {
    return `svg-container-${Date.now()}`;
  }

  drawInitiatorTree(data: ITagInitiatorNode): void {
    if (data?.children === undefined) return;

    this.initiatorsData = data;
    const { svgGroup, newRootNode } = this.initializeTreeAndVisualization(this.initiatorsData);
    this.rootNode = newRootNode;
    this.renderNode(this.rootNode, svgGroup);
  }

  /**
   * Creates a string in a specific syntax, describing the start and endpoints of a diagonal line
   */
  buildDiagonal(source, destination) {
    return `M ${source.y} ${source.x}
            C ${(source.y + destination.y) / 2} ${source.x},
              ${(source.y + destination.y) / 2} ${destination.x},
              ${destination.y} ${destination.x}`;
  }

  /**
   * When the user clicks on a node in the tree, it should collapse (hide) its children, or
   * show them if it is currently collapsed
   */
  click(d: ITagInitiatorNodeExtended, svgGroup: Selection<any, any, any, any>) {
    if (d.children) {
      d._children = d.children;
      d.children = null;
    } else {
      d.children = d._children;
      d._children = null;
    }
    this.tooltipSelection.hide();
    this.renderNode(d, svgGroup);
  }

  /**
   * Creates a D3-"formatted" data hierarchy,
   * and an on screen <svg><g></g></svg> element in which to render it
   */
  initializeTreeAndVisualization(inputData: ITagInitiatorNode): { svgGroup: Selection<any, any, any, any>, newRootNode: ITagInitiatorNodeExtended } {
    const newRootNode: ITagInitiatorNodeExtended = hierarchy(inputData, d => d.children).sort((a, b) => a.data.name.localeCompare(b.data.name));
    newRootNode.x0 = 0;
    newRootNode.y0 = 0;

    this.tooltipSelection = (d3Tip() as any)
      .attr('class', 'd3-tip tree-chart-tip')
      .offset([-10, 0])
      .html((d: ITagInitiatorTooltip) => {
        let tooltip = '';

        if (d.hasOwnProperty('requestBlocked')) {
          tooltip = `<div>Request has been blocked as requested.</div>`;
        } else if (d.hasOwnProperty('substitutedSuccessfully')) {
          tooltip = d.substitutedSuccessfully ? `<div>File substitution successful</div>` : `<div>File substitution failed - file not found</div>`;
        } else {
          tooltip = `<div class="detail-item"><strong>URL:</strong> ${d.url} </div>`;
          if (d.size) tooltip += `<div class="detail-item"><strong>Request Size:</strong> ${d3.format(',')(d.size)} bytes<div>`;
          if (d.aggregatedLoadTime) tooltip += `<div class="detail-item"><strong>Load Time:</strong> ${Math.round(d.aggregatedLoadTime)}ms<div>`;
        }

        return tooltip;
      });

    const containerElement = select(`#${this.svgContainerId}`).html('');

    this.containerElementWidth = parseInt(containerElement.style('width'));
    this.containerElementHeight = parseInt(containerElement.style('height'));

    const svg = containerElement
      .append('svg')
      .attr('width', this.containerElementWidth)
      .attr('height', this.containerElementHeight)
      .attr('class', 'tree-chart')
      .call(this.tooltipSelection)
      .on('mousemove', (event: MouseEvent) => {
        if (this.activeTooltip) {
          const relativeMouseCoords = pointer(event, this.activeTooltip);
          if ((Math.abs(relativeMouseCoords[0])) > 240 || Math.abs(relativeMouseCoords[1]) > 20) {
            this.activeTooltip = null;
            this.tooltipSelection.hide();
          }
        }
      });

    const svgGroup = svg.append('g');
    const zoomListener = zoom().scaleExtent([.5, 3]).on('zoom', (event: any) => svgGroup.attr('transform', event.transform));

    svg
      .call(zoomListener)
      .call(zoomListener.transform, zoomIdentity.translate(HORIZONTAL_MARGIN, VERTICAL_MARGIN + (this.containerElementHeight / 2)));

    return { svgGroup, newRootNode };
  }

  /**
   * Renders the necessary graph nodes and edges to represent the input tree data
   * Can either render the whole tree (if root node is given as input),
   * or an arbitrary node and its descendants (when a node is clicked to collapse or expand)
   */
  renderNode(updateNode: ITagInitiatorNodeExtended, svgGroup: Selection<any, any, any, any>) {
    const treeLayout = tree<ITagInitiatorNode>()
      .size([this.containerElementHeight, this.containerElementWidth])
      .nodeSize([NODE_SIZE, NODE_SIZE]);

    const treeData = treeLayout(this.rootNode);
    const treeNodes = treeData.descendants();
    const graphEdges = treeData.descendants().slice(1);

    treeNodes.forEach(d => {
      d.y = d.depth * HORIZONTAL_SPACER;
    });

    const nodeSelection = svgGroup.selectAll('g.node').data<any>(treeNodes, d => d.id || (d.id = ++this.nodeCount));

    const nodesToCreate = nodeSelection.enter().append('g')
      .attr('class', d => d.data.tagId ? 'node is-tag' : 'node is-not-tag')
      .attr('transform', d => `translate(${updateNode.y0}, ${updateNode.x0})`)
      .on('click', (_: any, clickedNode: any) => this.click(clickedNode, svgGroup));

    nodesToCreate.append('circle')
      .attr('r', 1e-6)
      .classed('collapsed', d => d._children)
      .classed('tag', d => d.data.tagId);

    nodesToCreate.append('text')
      .attr('x', d => (d.children || d._children) || d.data.hasOwnProperty('substitution') || d.data.hasOwnProperty('requestBlocking') ? -14 : 20)
      .attr('dy', '5px')
      .attr('text-anchor', d => (d.children || d._children) || d.data.hasOwnProperty('substitution') || d.data.hasOwnProperty('requestBlocking') ? 'end' : 'start')
      .text((d: ITagInitiatorNodeExtended) => this.tagInitiatorsService.truncateMiddle(d.data.tagName || d.data.name, MAX_NODE_TITLE_LEN))
      .style('fill-opacity', 1e-6)
      .on('mouseover', (mouseEvent: any, mousedNode: any) => {
        let tooltipData: ITagInitiatorTooltip = {
          url: mousedNode.data.url
        }

        if (!mousedNode.data.hasOwnProperty('substitution') || !mousedNode.data.hasOwnProperty('requestBlocking')) {
          tooltipData.size = mousedNode.data.size;
          tooltipData.aggregatedLoadTime = mousedNode.data.aggregatedLoadTime;
        } else if (mousedNode.data.substitution.hasOwnProperty('failure')) {
          tooltipData.size = mousedNode.data.size;
          tooltipData.aggregatedLoadTime = mousedNode.data.aggregatedLoadTime;
        }

        this.activeTooltip = mouseEvent.target;
        this.tooltipSelection.show(tooltipData, this.activeTooltip);
      });

    // create file substitution circle (second circle)
    nodesToCreate.append('g')
      .attr('class', (data) => data.data.hasOwnProperty('substitution')
        ? data.data.substitution.hasOwnProperty('failure') ? 'substitution substitution-fail' : 'substitution substitution-success'
        : 'hidden')
      .attr('transform', 'translate(32, 0)')
      .append('circle')
      .attr('r', 12)
      .attr('class', 'substitution-circle');

    nodesToCreate.select('g.substitution')
      .append('svg:image')
      .attr('xlink:href', '/images/file_substitution.svg')
      .classed('file-substitution-icon', true)
      .on('mouseover', (mouseEvent: any, mousedNode: any) => {
        const tooltipData: ITagInitiatorTooltip = {
          substitutedSuccessfully: !mousedNode.data.substitution.hasOwnProperty('failure')
        }
        this.activeTooltip = mouseEvent.target;
        this.tooltipSelection.show(tooltipData, this.activeTooltip);
      });

    // create file substitution result (third circle)
    const substitutionResult = nodesToCreate.append('g')
      .attr('transform', 'translate(62, 0)')
      .attr('class', (data) => {
        let classes = [];
        if (data.data.hasOwnProperty('substitution')) {
          classes.push(data.data.substitution.hasOwnProperty('failure') ? 'hidden' : 'substitution-result');
          classes.push(data.data.substitution.tagId ? 'is-tag' : 'is-not-tag');
        } else {
          classes.push('hidden')
        }
        return classes.join(' ');
      });

    // draws circle (background) for JS/HTML/CSS icon (if not a tag, otherwise it's hidden)
    substitutionResult
      .append('circle')
      .attr('r', (d: ITagInitiatorNodeExtended) => d.data.substitution?.tagId ? 5.5 : 12)
      .attr('class', (d) => {
        let classes = [];

        d.data.hasOwnProperty('substitution')
          ? classes.push(d.data.substitution?.tagId ? 'hidden' : 'substitution-circle')
          : classes.push('hidden');

        return classes.join(' ');
      });

    // draws JS/HTML/CSS icon (if not a tag, otherwise it's hidden)
    substitutionResult
      .append('svg:image')
      .attr('xlink:href', (d: ITagInitiatorNodeExtended) => d.data.substitution?.tagId ? UiTagService.getTagIconUrl(d.data.substitution?.tagId) : this.tagInitiatorsService.getIconByFileExtension(d.data.substitution?.substituteUrl))
      .attr('class', (d: ITagInitiatorNodeExtended) => {
        let classes = [];

        d.data.hasOwnProperty('substitution')
          ? classes.push(d.data.substitution.tagId ? 'substitution-tag-icon' : 'substitution-circle-icon')
          : classes.push('hidden');

        return classes.join(' ');
      })
      .attr('width', 22)
      .attr('height', 22)
      .attr('x', d => d.data.children.length ? -8 : -11)
      .attr('y', -11);

    // adds text to third node
    substitutionResult
      .append('text')
      .attr('x', 17)
      .attr('dy', '5px')
      .attr('text-anchor', 'start')
      .text((d: ITagInitiatorNodeExtended) => {
        if (!d.data.hasOwnProperty('substitution')) return '';
        // empty string in case of substitution failure and neither property exists
        const name = d.data.substitution?.tagName || d.data.substitution?.substituteUrl || '';
        return this.tagInitiatorsService.truncateMiddle(name, MAX_NODE_TITLE_LEN);
      })
      .style('fill-opacity', 1)
      .on('mouseover', (mouseEvent: any, mousedNode: any) => {
        const tooltipData: ITagInitiatorTooltip = {
          url: mousedNode.data.substitution.substituteUrl,
          size: mousedNode.data.size,
          aggregatedLoadTime: mousedNode.data.aggregatedLoadTime
        }
        this.activeTooltip = mouseEvent.target;
        this.tooltipSelection.show(tooltipData, this.activeTooltip);
      });

    /**
     * Easy Block TMS Node - Start
     */
    // create request blocked circle (second circle)
    nodesToCreate.append('g')
      .attr('class', (data) => data.data.hasOwnProperty('requestBlocking')
        ? 'request-block'
        : 'hidden')
      .attr('transform', 'translate(32, 0)')
      .append('circle')
      .attr('r', 12)
      .attr('class', 'request-block-circle');

    nodesToCreate.select('g.request-block')
      .append('svg:image')
      .attr('xlink:href', '/images/request_blocked.svg')
      .classed('request-block-icon', true)
      .on('mouseover', (mouseEvent: any, mousedNode: any) => {
        const tooltipData: ITagInitiatorTooltip = {
          requestBlocked: true
        };
        this.activeTooltip = mouseEvent.target;
        this.tooltipSelection.show(tooltipData, this.activeTooltip);
      });

    // Tag Icon
    nodesToCreate.append('svg:image')
      .attr('xlink:href', (d: ITagInitiatorNodeExtended) => d.data.tagId ? UiTagService.getTagIconUrl(d.data.tagId) : this.tagInitiatorsService.getIconByFileExtension(d.data.url))
      .classed('tag-icon', true);

    const nodesToUpdate = nodeSelection.merge(nodesToCreate).transition()
      .duration(DURATION)
      .attr('transform', (d: ITagInitiatorNodeExtended) => `translate(${d.y}, ${d.x})`);

    nodesToUpdate.select('circle')
      .attr('r', (d: ITagInitiatorNodeExtended) => d.data.tagId ? 5.5 : 12)
      .attr('class', (d: ITagInitiatorNodeExtended) => d.children ? 'extended' : 'collapsed');

    nodesToUpdate.select('image.tag-icon')
      .attr('x', -10)
      .attr('y', -13)
      .attr('width', 26)
      .attr('height', 26);

    nodesToUpdate.select('.is-not-tag image.tag-icon')
      .attr('width', 22)
      .attr('height', 22)
      .attr('x', d => d.data.children.length ? -8 : -11)
      .attr('y', -11);

    nodesToUpdate.select('text')
      .style('fill-opacity', 1);

    nodesToUpdate.select('image.file-substitution-icon')
      .attr('width', 22)
      .attr('height', 22)
      .attr('x', -11)
      .attr('y', -11);

    nodesToUpdate.select('image.request-block-icon')
      .attr('width', 22)
      .attr('height', 22)
      .attr('x', -11)
      .attr('y', -11);

    nodesToUpdate.select('image.substitution-tag-icon')
      .attr('x', -16)
      .attr('y', -13)
      .attr('width', 26)
      .attr('height', 26);

    let nodesToHide = nodeSelection.exit().transition()
      .duration(DURATION)
      .attr('transform', () => `translate(${updateNode.y}, ${updateNode.x})`)
      .remove();

    nodesToHide.select('circle')
      .attr('r', 1e-5);

    nodesToHide.select('.substitution-circle')
      .attr('r', 1e-5);

    nodesToHide.select('.substitution-result .substitution-circle')
      .attr('r', 1e-5);

    nodesToHide.select('image.tag-icon')
      .attr('width', 1e-6)
      .attr('height', 1e-6);

    nodesToHide.select('.substitution-result image.substitution-tag-icon')
      .attr('width', 1e-6)
      .attr('height', 1e-6);

    nodesToHide.select('image.file-substitution-icon')
      .attr('width', 1e-6)
      .attr('height', 1e-6);

    nodesToHide.select('image.request-block-icon')
      .attr('width', 1e-6)
      .attr('height', 1e-6);

    nodesToHide.select('image.substitution-circle-icon')
      .attr('width', 1e-6)
      .attr('height', 1e-6);

    nodesToHide.select('text')
      .style('fill-opacity', 1e-6);

    nodesToHide.select('.substitution-result text')
      .style('fill-opacity', 1e-6);

    // Store the old positions for transition
    treeNodes.forEach(function (d: ITagInitiatorNodeExtended) {
      d.x0 = d.x;
      d.y0 = d.y;
    });

    /** BUILDING EDGES (lines between nodes) */
    let edgeSelection = svgGroup.selectAll('path.link')
      .data<any>(graphEdges, (d: ITagInitiatorNodeExtended) => d.id);

    let edgesToCreate = edgeSelection.enter().insert('path', 'g')
      .attr('class', 'link')
      .attr('d', () => {
        const o = { x: updateNode.x0, y: updateNode.y0 };
        return this.buildDiagonal(o, o);
      });

    let edgesToUpdate = edgeSelection.merge(edgesToCreate);

    // Transition back to the parent element position
    edgesToUpdate.transition()
      .duration(DURATION)
      .attr('d', (d: ITagInitiatorNodeExtended) => this.buildDiagonal(d, d.parent));

    // Remove any exiting links
    edgeSelection.exit()
      .transition().duration(DURATION)
      .attr('d', () => {
        const o = { x: updateNode.x, y: updateNode.y };
        return this.buildDiagonal(o, o);
      })
      .remove();
  }
}
