import { AfterViewInit, Component, HostListener, Input, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import { Selection } from 'd3';
import {
  attachSvgToContainer,
  determineChartSegments, drawLine, drawRectangle,
  getColorClass
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import { BaseType } from 'd3-selection';
import { trimDecimals } from '@app/components/utilities/number.utils';
import {
  IBoxPlotChartChartAxisItems,
  IBoxPlotChartConfig,
  IBoxPlotChartDashedSegmentColorClass,
  IBoxPlotChartLineSegmentColorClass,
  IBoxPlotChartSegment,
  IBoxPlotChartSegmentColorClass
} from '@app/components/shared/components/viz/box-plot-mini-chart/box-plot-mini-chart.models';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, take, takeUntil } from 'rxjs/operators';
import { SidebarService } from '@app/components/navigation/sidebar/sidebar.service';
import { Record } from 'immutable';

@Component({
  selector: 'op-box-plot-mini-chart',
  templateUrl: './box-plot-mini-chart.component.html',
  styleUrls: ['./box-plot-mini-chart.component.scss']
})
export class BoxPlotMiniChartComponent implements AfterViewInit, OnDestroy {
  readonly margin = { top: 5, right: 15, bottom: 18, left: 5 };

  // Main group of the svg where all the charts are drawn
  private svgG: Selection<SVGElement, any, any, any>;
  private container: Selection<BaseType, unknown, HTMLElement, any>;
  private height: number;
  private width: number;
  private svg: Selection<SVGElement, any, any, any>;
  private x: d3.ScaleLinear<number, number>;

  private resize$ = new Subject();
  private destroy$ = new Subject();
  private config$ = new ReplaySubject(1);

  @Input() whiskerHeight: number = 20;
  @Input() medianHeight: number = 30;
  @Input() mainRectangleHeight: number = 20;
  @Input() dataLoaded: boolean = false;

  private _config: IBoxPlotChartConfig | null = null;

  @Input() set config(config: IBoxPlotChartConfig | null) {
    if (!config || !config.boundaries.length || !config.svgBoxSelectorName) {
      return;
    }

    this.updateHighBoundary(config);

    this._config = config;

    this.config$.next(config);
  };

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
  }

  constructor(private sidebarService: SidebarService) {
  }

  ngAfterViewInit(): void {
    this.initCharts();
  }

  private chartRedrawSubscription(): Observable<any> {
    return merge(this.resize$, this.sidebarService.isClosed, this.config$)
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$),
      );
  }

  private initCharts(): void {
    // We need to wait until the sidebar has finished resizing to draw the chart with correct size
    this.config$
      .pipe(
        delay(300),
        switchMap(() => this.sidebarService.isClosed),
        take(1),
        switchMap(() => this.chartRedrawSubscription()),
      )
      .subscribe(() => this.createChart());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get config(): IBoxPlotChartConfig {
    return this._config;
  }

  get validConfig(): boolean {
    if (!this.config?.stats) {
      return false;
    }

    return Object.values(this.config?.stats ?? {}).every(el => el >= 0);
  }

  private createChart(): void {
    if (!this.validConfig) {
      return;
    }

    this.createSvgBox();
    this.createAxis();
    this.drawWhiskeredLine();
    this.drawDashedRectangles();
    this.drawMainRectangles();
    this.drawMedian();
  }

  private updateHighBoundary(config: IBoxPlotChartConfig): void {
    const max = Math.max(...Object.values(config.stats ?? {}));

    if (max > config.boundaries[config.boundaries.length - 1]) {
      config.boundaries.splice(
        config.boundaries.length - 1,
        1,
        max,
      );
    }
  }

  private drawMainRectangles(): void {
    determineChartSegments(
      this.config.stats.p25,
      this.config.stats.p75,
      this.config.boundaries
    )
      .forEach(segment => this.drawRectangle(
        this.x(segment.start),
        this.x(segment.end) - this.x(segment.start),
        this.getChartColorClass(this.x(segment.start))),
      );
  }

  private drawDashedRectangles(): void {
    this.drawRectangle(
      this.x(this.config.boundaries[0]),
      // -1 to avoid overlapping with the next rectangle (just a visual)
      this.x(this.config.boundaries[1]) - this.x(this.config.boundaries[0]) - 1,
      this.getChartDashedItemColorClass(this.x(this.config.boundaries[0])),
      this.height - this.margin.bottom - this.margin.top,
      this.margin.top,
    );
    this.drawRectangle(
      // +1 to avoid overlapping with the previous rectangle (just a visual)
      this.x(this.config.boundaries[1]) + 1,
      // -1 to avoid overlapping with the next rectangle (just a visual)
      this.x(this.config.boundaries[2]) - this.x(this.config.boundaries[1]) - 1,
      this.getChartDashedItemColorClass(this.x(this.config.boundaries[1])),
      this.height - this.margin.bottom - this.margin.top,
      this.margin.top,
    );
    this.drawRectangle(
      // +2 to avoid overlapping with the previous rectangle (just a visual)
      this.x(this.config.boundaries[2]) + 2,
      this.x(this.config.boundaries[3]) - this.x(this.config.boundaries[2]),
      this.getChartDashedItemColorClass(this.x(this.config.boundaries[2])),
      this.height - this.margin.bottom - this.margin.top,
      this.margin.top,
    );
  }

  get verticalCenter(): number {
    return (this.height + this.margin.top - this.margin.bottom) / 2;
  }

  private drawWhiskeredLine(): void {
    this.drawWhiskers();
    determineChartSegments(
      this.config.stats.min,
      this.config.stats.max,
      this.config.boundaries
    )
      .forEach(
        segment => drawLine(
          this.x(segment.start),
          this.x(segment.end),
          this.getChartLineItemColorClass(this.x(segment.start)),
          this.verticalCenter,
          this.verticalCenter,
          this.svgG
        )
      );
  }

  private drawWhiskers(): void {
    const whiskerHeight = this.whiskerHeight;

    const xMin = this.x(this.config.stats.min);
    const xMax = this.x(this.config.stats.max);

    const y1 = (this.verticalCenter - whiskerHeight / 2);
    const y2 = (this.verticalCenter + whiskerHeight / 2);

    // Left whisker
    drawLine(
        xMin,
        xMin,
        this.getChartLineItemColorClass(xMin),
        y1,
        y2,
        this.svgG
    );

    // Right whisker
    drawLine(
      xMax,
      xMax,
      this.getChartLineItemColorClass(xMax),
      y1,
      y2,
      this.svgG
    );
  }

  private drawMedian(): void {
    const medianHeight = this.medianHeight;

    const y1 = this.verticalCenter - medianHeight / 2;
    const y2 = this.verticalCenter + medianHeight / 2;

    const x = this.x(this.config.stats.median);

    drawLine(
        x,
        x,
        'median-line',
        y1,
        y2,
        this.svgG,
        undefined
    );
  }

  private drawRectangle(
    x: number,
    width: number,
    colorClass: IBoxPlotChartSegmentColorClass | IBoxPlotChartDashedSegmentColorClass = 'green',
    rectHeight: number = this.mainRectangleHeight,
    y: number = this.verticalCenter - rectHeight / 2,
  ): void {
    drawRectangle(x, width, colorClass, rectHeight, y, this.svgG);
  }

  private getChartColorClass(value: number): IBoxPlotChartSegmentColorClass {
    return getColorClass(
        value,
        [this.x(this.config.boundaries[1]), this.x(this.config.boundaries[2])],
        ['green', 'yellow', 'red']
    );
  }

  private getChartDashedItemColorClass(value: number): IBoxPlotChartDashedSegmentColorClass {
    return getColorClass(
        value,
        [this.x(this.config.boundaries[1]), this.x(this.config.boundaries[2])],
        ['green-dashed', 'yellow-dashed', 'red-dashed']
    );
  }

  private getChartLineItemColorClass(value: number): IBoxPlotChartLineSegmentColorClass {
    return getColorClass(
        value,
        [this.x(this.config.boundaries[1]), this.x(this.config.boundaries[2])],
        ['green-line', 'yellow-line', 'red-line']
    );
  }

  private createSvgBox(): void {
    // Clear old svg before redrawing if necessary
    if (this.svgG) {
      this.svgG.remove();
    }

    // Reset container contents for the redrawing case and then get the container again
    this.container = d3.select(`.${this.config.svgBoxSelectorName}`).html('');
    this.container = d3.select(`.${this.config.svgBoxSelectorName}`);

    // If selection is empty, any methods called on it will result in error
    if (this.container.empty()) {
      return;
    }

    this.height = parseInt(this.container.style('height'));
    this.width = parseInt(this.container.style('width'));

    this.svg = attachSvgToContainer(this.config.svgBoxSelectorName)
      .attr(
        'style',
        `width: ${this.width}px; height: ${this.height}px`,
      );

    this.svgG = this.svg.append('g')
      .attr(
        'transform',
        `translate(${this.margin.left},${this.margin.top})`,
      );
  }

  private createAxis(): void {
    this.addXAxis();
  }

  private addXAxis(): void {
    // If axisItems are not provided, use the stats values as axis items
    const axisItems = this.config.boundaries ? this.config.boundaries : Object.values(this.config.stats);
    const min = d3.min(axisItems);
    const max = d3.max(axisItems);

    this.x = d3.scalePow().exponent(0.5)
      .domain([min, max]) // Ensure domain starts from a positive value
      .range([this.margin.left, this.width - this.margin.left - this.margin.right]);

    this.svgG.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${this.height - this.margin.bottom})`)
      .call(
        d3.axisBottom(this.x)
          .tickValues(this.config.boundaries)
          .tickSize(0)
          .tickFormat((d) => trimDecimals((d as number)))
      )
      // region Removing the axis line
      .call(g => g.select('.domain').remove())
      // endregion Removing the axis line
      .selectAll('.tick text')
      .attr('class', 'x-axis-tick-text');
  }
}
