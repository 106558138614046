<ng-container *ngIf="config.totalRows || (config.filteredRows && appliedFilters) || rowsInView">
  <mat-icon [matTooltip]="'Export '+ config.tableName +' Section'"
            [matTooltipPosition]="'above'"
            [matMenuTriggerFor]="categoriesMenu">
    {{opened ? 'cloud_download' : 'cloud_download_filled' }}
  </mat-icon>
  <mat-menu class="audit-report-export-menu"
            #categoriesMenu="matMenu"
            backdropClass="no-filter">
    <div class="title">
      Export <strong>{{config.tableName}}</strong> section as emailed CSV
    </div>
    <ul>
      <li *ngIf="!hideExportFullReportItem"
          [class.loading]="fullReportLoading"
          (click)="handleExportFullReportClick()">
        <mat-spinner
          *ngIf="fullReportLoading"
          class="spinner"
          [diameter]="17"
          [strokeWidth]="2">
        </mat-spinner>
        <span *ngIf="isNumericTotalRows else staticTotalRows">All data ({{config.totalRows}} rows)</span>
        <ng-template #staticTotalRows>
          <span>All data (no filters applied)</span>
        </ng-template>
      </li>
      <li *ngIf="config.filteredRows && appliedFilters"
          [class.loading]="filteredReportLoading"
          (click)="handleExportFilteredReportClick()">
        <mat-spinner *ngIf="filteredReportLoading" class="spinner" [diameter]="17" [strokeWidth]="2"></mat-spinner>
        <span>All filtered data ({{config.filteredRows}} rows)</span>
      </li>
      <li *ngIf="rowsInView"
          [class.loading]="shownReportLoading"
          (click)="handleExportShownReportClick()">
        <mat-spinner *ngIf="shownReportLoading"  [class.loading]="shownReportLoading" class="spinner" [diameter]="17" [strokeWidth]="2"></mat-spinner>
        <span>Only current data in view (rows {{config.tableState.page * config.tableState.size + 1}}-{{ rowsInView }})</span>
      </li>
      <li *ngIf="config.dataToCopy?.data?.length" [class.loading]="shownReportLoading" (click)="copyToClipboard()">
        <mat-spinner *ngIf="shownReportLoading"  [class.loading]="shownReportLoading" class="spinner" [diameter]="17" [strokeWidth]="2"></mat-spinner>
        <span>Copy to Clipboard (rows {{config.tableState.page * config.tableState.size + 1}}-{{ rowsInView }})</span>
      </li>
    </ul>
  </mat-menu>
</ng-container>

