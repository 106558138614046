<ng-container [formGroup]="filterForm">
  <p class="headers">
    <span class="regex-header">Which sections of the website should be
      <span class="label-emphasis">&nbsp;{{title}}?</span>
      <ng-container *ngIf="showCollapseBtn">
        <span class="collapser" [class.collapsed]="isHidden" (click)="isHidden = !isHidden">{{ !isHidden ? 'Hide' : 'Show'}} {{filterControls.length}} ITEM(S)<mat-icon>expand_more</mat-icon></span>
        <mat-icon class="info-icon"
                  mdePopoverTriggerOn="hover"
                  [mdePopoverTriggerFor]="infoPopover"
                  mdePopoverOffsetX="-262"
        >
          info_outline
        </mat-icon>
      </ng-container>
      <mde-popover #infoPopover [mdePopoverOverlapTrigger]="false">
        <div class="info-popover">
          <p>All domains of <strong>Starting URLs</strong> are automatically added in this <strong>Included</strong> section as modified REGEX versions of the starting URL.</p>
          <p>The <strong>Include List</strong> can contain exact URLs, partial URLs, or regular expressions.</p>
          <p><a href="https://help.observepoint.com/en/articles/9099982-creating-audits" target="_blank">Learn More</a></p>
        </div>
      </mde-popover>
    </span>
    <span *ngIf="useScanLimit" class="limit-scan-header" [class.hide]="isHidden">Maximum pages in this section:</span>
    <span *ngIf="filterControls.controls.length > 1" class="remove-filter-header"></span>
  </p>
  <div class="filter-wrapper" formArrayName="filterControls">
    <cdk-virtual-scroll-viewport [itemSize]="70" class="virtual-scroll-viewport"
                                 [minBufferPx]="70 * 10"
                                 [maxBufferPx]="70 * 20" [style.height.px]="!isHidden ? 70 * filterControls.controls.length : 0">
      <div *cdkVirtualFor="let control of filters; let i = index; let last = last" class="row">
        <div class="filter-group"
             [formGroup]="getFilterControlsByIndex(i)">
          <div class="filter-fields" [class.include-filter-value]="useScanLimit">
            <mat-form-field floatLabel="always"
                            appearance="outline"
                            hideRequiredMarker
                            class="mat-input-container no-label">
              <mat-label></mat-label>
              <input matInput
                     [attr.idx]="i"
                     #htmlInputElement
                     (blur)="last && updateLastControlPointer()"
                     [placeholder]="placeholder"
                     formControlName="value">
              <mat-error *ngIf="getFilterControlsByIndex(i).controls.value.hasError('required')">Please enter this
                field
              </mat-error>
              <mat-error *ngIf="getFilterControlsByIndex(i).controls.value.hasError('validRegexes')">Please enter a
                valid regular expression
              </mat-error>
            </mat-form-field>
            <div class="include-filter-limit"
                 *ngIf="useScanLimit">
              <mat-form-field class="mat-input-container no-label"
                              floatLabel="always"
                              hideRequiredMarker
                              appearance="outline">
                <mat-label></mat-label>
                <input matInput
                       formControlName="includeLimit"
                       type="number"
                       min="0"
                       max="250000"
                       (keyup)="onIncludeLimitChange()">
                <mat-error *ngIf="getFilterControlsByIndex(i).controls.includeLimit.hasError('required')">Please enter a
                  number
                </mat-error>
                <mat-error *ngIf="getFilterControlsByIndex(i).controls.includeLimit.hasError('min')">The number is less
                  than expected
                </mat-error>
                <mat-error
                  *ngIf="getFilterControlsByIndex(i).controls.includeLimit.hasError('pattern') && !getFilterControlsByIndex(i).controls.includeLimit.hasError('min')">
                  Please enter a whole number
                </mat-error>
                <mat-hint *ngIf="last">0 = no limit set</mat-hint>
              </mat-form-field>
            </div>
            <div class="remove-filter-wrapper" *ngIf="filterControls.controls.length > 1">
              <mat-icon
                matTooltip="Remove from {{title}} list"
                matTooltipPosition="above"
                matTooltipShowDelay="250"
                (click)="removeFilter(i)"
                class="remove-filter"
                [class.push-down]>
                highlight_off
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
  <div class="btn-flex-wrp">
    <div class="button-wrapper" *ngIf="(!lastControl?.touched || lastControl?.status === 'VALID') && !isHidden" (click)="addFilter()">
      <button mat-raised-button
              class="btn-add-row"
              type="button"
      >
        <mat-icon>add</mat-icon>
      </button>
      <div class="button-description">Add another section to be {{title}}</div>
    </div>
    <div class="supports-regex">
      Supports REGEX - <a class="learn-more-link" href="{{LEARN_MORE.include}}" target="_blank">Learn more</a>
    </div>
  </div>
  <div *ngIf="useScanLimit && !filterLimitMatch" class="warning">
    {{ mismatchMessage }}
  </div>
</ng-container>
