import { ERuleSetupMode } from './../rule-setup/rule-setup.enums';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { Router } from '@angular/router';
import { IAddRuleLabel, IRemoveRuleLabel } from './rules-table/rules-table.models';
import { Component, OnInit } from '@angular/core';
import { userIsAdmin, userIsGuest } from '@app/authUtils';
import { RulesService } from '@app/components/rules/rules.service';
import { IUser } from '@app/moonbeamModels';
import { IMenuItem } from '@app/components/shared/components/op-menu/op-menu.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AccountsService } from '@app/components/account/account.service';
import { IOpFilterBarMenuItem } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import { RulesFilterBarService } from '@app/components/rules/rule-library/rules-filter-bar.service';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { IRuleFiltersV3, IRulePaginationV3, IRuleSortV3, IRulesUsageV3Response, IRuleV3Usage } from './rule-library.models';
import { ERulesV3FilterTypes, ESortColumnsV3 } from './rule-library.enums';
import { IRule } from '@app/components/rules/rules.models';
import { IRuleSetupModalData } from '../rule-setup/rule-setup.models';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-library',
  templateUrl: './rule-library.component.html',
  styleUrls: ['./rule-library.component.scss'],
})

export class RuleLibraryComponent implements OnInit {
  private destroy$ = new Subject<void>();
  private apiFilters: IRuleFiltersV3 = {};

  infoIsExpanded = false;
  isReadOnly = true;
  loadingRules = true;
  rules: IRuleV3Usage[] = [];
  user: IUser;
  users: IUser[];

  filterBarMenuItems: IOpFilterBarMenuItem[] = [
    {
      name: 'Rules Type',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'Custom',
          type: EFilterBarMenuTypes.Button,
          action: () => this.rulesFilterBarService.addRuleTypeFilter(false)
        },
        {
          name: 'Built-In',
          type: EFilterBarMenuTypes.Button,
          action: () => this.rulesFilterBarService.addRuleTypeFilter(true)
        }
      ]
    },
    {
      name: 'Labels',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'Label Search',
          type: EFilterBarMenuTypes.Search,
          searchPlaceholder: 'Search for label',
          action: (event: KeyboardEvent, el?: HTMLElement) => this.handleSearch(true, event, el),
          children: []
        }
      ]
    },
    {
      name: 'Created By',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'Creator Search',
          type: EFilterBarMenuTypes.Search,
          searchPlaceholder: 'Search for creator',
          action: (event: KeyboardEvent, el?: HTMLElement) => this.handleSearch(false, event, el),
          children: []
        }
      ]
    },
    {
      name: 'Notifications',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'On',
          type: EFilterBarMenuTypes.Button,
          action: () => this.rulesFilterBarService.addRuleNotificationStatusFilter(true)
        },
        {
          name: 'Off',
          type: EFilterBarMenuTypes.Button,
          action: () => this.rulesFilterBarService.addRuleNotificationStatusFilter(false)
        }
      ]
    },
    {
      name: 'Divider',
      type: EFilterBarMenuTypes.Divider
    },
    {
      name: 'clear all filters',
      type: EFilterBarMenuTypes.ClearBtn,
      action: () => this.rulesFilterBarService.clear()
    }
  ];

  labels$ = new BehaviorSubject<ILabel[]>(null);
  labelById: Map<number, ILabel>;

  menuItems: IMenuItem[] = [
    {
      icon: 'edit',
      label: 'Edit Rule',
      onClick: rule => this.editRule(rule)
    },
    {
      icon: 'content_copy',
      label: 'Copy Rule',
      onClick: rule => this.copyRule(rule)
    },
    {
      icon: 'delete',
      label: 'Delete Rule',
      onClick: rule => this.deleteRule(rule),
      hidden: rule => !this.canDelete(rule)
    },
  ];

  sortOptions: IRuleSortV3 = {
    sortBy: ESortColumnsV3.Name,
    sortDesc: false,
    sortDir: 'asc'
  };

  pagination: IRulePaginationV3 = {
    totalCount: 0,
    totalPageCount: 0,
    currentPageSize: 0,
    pageSize: 50,
    currentPageNumber: 0,
  };

  constructor(
    private router: Router,
    private accountsService: AccountsService,
    private labelsApiService: LabelService,
    private rulesService: RulesService,
    private opModalService: OpModalService,
    public rulesFilterBarService: RulesFilterBarService,
  ) {
  }

  ngOnInit() {
    this.rulesFilterBarService
      .updateSupportedFiltersList([
        ERulesV3FilterTypes.FromTemplate,
        ERulesV3FilterTypes.RuleName,
        ERulesV3FilterTypes.Label,
        ERulesV3FilterTypes.CreatedBy,
        ERulesV3FilterTypes.Notification
      ]);

    this.accountsService
      .getUsers()
      .subscribe(users => this.users = users);

    this.accountsService
      .getUser()
      .subscribe((user) => {
        this.user = user;
        this.isReadOnly = userIsGuest(this.user);
      });

    this.getLabels();

    this.rulesFilterBarService.filters$.subscribe((filters) => {
      this.apiFilters = filters.reduce((formattedFilters, filter) => {
        const filterType = filter.type;
        const filterValue = filter.value;

        if (filterType === ERulesV3FilterTypes.Label) {

          if (formattedFilters[filterType]) {
            formattedFilters[filterType] = [...formattedFilters[filterType], filterValue];
          } else {
            formattedFilters[filterType] = [filterValue];
          }

        } else {
          formattedFilters[filterType] = filterValue;
        }

        return formattedFilters;
      }, {});

      this.pagination.currentPageNumber = 0;

      this.loadRules();
    });
  }

  private loadRules() {
    forkJoin([
      this.getRules(),
      this.labels$.pipe(filter(v => !!v), first())
    ])
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe(([ruleUsage]) => this.prepareRules(ruleUsage));
  }

  createRule() {
    this.openRuleSetupModal({
      mode: ERuleSetupMode.create
    });
  }

  editRule(rule: IRule) {
    this.openRuleSetupModal({
      mode: ERuleSetupMode.edit,
      ruleId: rule.id,
    });
  }

  copyRule(rule: IRule) {
    this.openRuleSetupModal({
      mode: ERuleSetupMode.copy,
      ruleId: rule.id,
    });
  }

  private openRuleSetupModal(data: IRuleSetupModalData) {
    this.opModalService.openFixedSizeModal(RuleSetupModalComponent, { disableClose: true, data }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => {
        if (!rule) return;

        const ruleToEdit = this.rules.find(r => r.id === rule.id);

        if (rule && ruleToEdit) {
          ruleToEdit.name = rule.name;
          ruleToEdit.labels = rule.labels;
          ruleToEdit.updatedAt = rule.updatedAt;
          ruleToEdit.recipients = rule.recipients;
        }
      });
  }

  deleteRule(rule: IRule) {
    const data = {
      deleteButtonAction: () => {
        if (!rule) return;
        this.rulesService
          .deleteRule(rule.id)
          .subscribe(() => this.rules = this.rules.filter(r => r.id !== rule.id));
      },
      displayItem: {
        type: 'Rule',
        name: rule.name
      },
    };
    this.opModalService.openDeleteModal({data});
  }

  getLabels() {
    this.labels$.next(null);
    return this.labelsApiService.getLabels().subscribe(labels => {
      this.labelById = new Map;
      labels.forEach(label => this.labelById.set(label.id, label));
      this.labels$.next(labels);
    });
  }

  onChangeRule = () => {
    Promise.all([
      this.getLabels(),
      this.getRules().toPromise()
    ])
      .then(([labels, rules]) => this.prepareRules(rules));
  }

  createLabel({label, ruleId}: IAddRuleLabel): void {
    let rule = this.rules.find(r => r.id === ruleId);
    if (!rule) return;
    if (!rule.labels) rule.labels = [];

    const labels = [ ...rule.labels.filter(l => l.id), label];

    this.rulesService
      .updateRuleLabels(ruleId, labels)
      .toPromise()
      .then((labels: Array<ILabel>) => {
        rule.labels = labels;
      });
  }

  assignLabelToRule(ruleId: number) {
    let rule = this.rules.find(r => r.id === ruleId);
    this.rulesService.updateRuleLabels(ruleId, rule.labels).subscribe();
  }

  removeLabel({labelId, ruleId}: IRemoveRuleLabel) {
    this.rulesService
      .deleteRuleLabel(ruleId, labelId)
      .subscribe(_ => {
        let rule = this.rules.find(r => r.id === ruleId);
        if (!rule || !rule.labels || rule.labels.length <= 0) {
          return;
        }
        rule.labels = rule.labels.filter(l => l.id !== labelId);
      });
  }

  canDelete(rule: IRuleV3Usage) {
    return (userIsAdmin(this.user) || this.isOwner(rule));
  }

  handleSearch(isLabel: boolean, event: KeyboardEvent, el?: HTMLElement) {
    if (el) {
      el.focus();
      return;
    } else {
      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

      if (isLabel) {
        this.updateFilteredLabels(value);
      } else {
        this.updateFilteredCreators(value);
      }

      this.filterBarMenuItems = [...this.filterBarMenuItems];
    }
  }

  updateTableState(state: IRuleSortV3 & IRulePaginationV3) {
    this.pagination.currentPageNumber = state.currentPageNumber;
    this.sortOptions.sortBy = state.sortBy;
    this.sortOptions.sortDesc = state.sortDesc;

    this.getRules().subscribe(response => this.prepareRules(response));
  }

  private getRules(): Observable<IRulesUsageV3Response> {
    this.loadingRules = true;
    return this.rulesService.getRulesV3(this.sortOptions, this.pagination, this.apiFilters);
  }

  private isOwner(rule: IRuleV3Usage): boolean {
    return rule.ownedByUserId == this.user?.id;
  }

  private updateFilteredLabels(value: string) {

    this.labels$
      .pipe(
        filter(v => !!v),
        map(labels => labels
          .filter(label => label.name.toLowerCase().includes(value))
          .map(label => ({
            name: label.name,
            type: EFilterBarMenuTypes.Button,
            action: () => this.rulesFilterBarService.addRuleLabelIdFilter({name: label.name, id: label.id})
          }))
        )
      ).subscribe(labels => {
        this.filterBarMenuItems[1].children[0].children = value
          ? labels
          : [];
      });
  }

  private updateFilteredCreators(value: string) {
    let userSearchChildren = this.users
      .filter(user => (user.firstName + ' ' + user.lastName + user.email).includes(value))
      .map(user => {
        const fullName = user.firstName + ' ' + user.lastName;
        return {
          name: fullName,
          type: EFilterBarMenuTypes.Button,
          action: () => this.rulesFilterBarService.addRuleCreatorFilter({name: fullName, id: user.id})
        };
      });

    this.filterBarMenuItems[2].children[0].children = value
      ? userSearchChildren
      : [];
  }

  private prepareRules({rules, paginationMetadata}) {
    rules.forEach(rule => rule.labels = rule.labels.map(ruleLabel => this.labelById.get(ruleLabel.id)));
    this.rules = rules;
    this.pagination = paginationMetadata;
    this.loadingRules = false;
  }
}
