import {
    EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
    IFullscreenChartConfig
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { CommonReportsPagesFiltersConfig } from '@app/components/audit-reports/reports/general-reports.constants';

export enum BrowserConsoleLogsTrendName {
    Debug = 'debug_logs',
    Errors = 'error_logs',
    Warnings = 'warn_logs',
    Info = 'info_logs',
    Other = 'other_logs'
}

function defaultChartConfig(title: string): IFullscreenChartConfig {
    return {
        title: title,
        data: [],
        sideLabel: 'number',
        showXAxis: true,
        showYAxis: true,
        showSummaryLines: true,
        tooltip: (value: number) => `${value} log${value === 1 ? '' : 's'}`
    };
}

export const ERRORS_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Error Logs');
export const WARNINGS_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Warning Logs');
export const INFO_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Info Logs');
export const DEBUG_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Debug Logs');
export const OTHER_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Other Logs');

export const BROWSER_CONSOLE_LOGS = 'browser_logs_pages';

export const BrowserConsoleLogsRelevantFilters = [
    ...CommonReportsPagesFiltersConfig,
    EAuditReportFilterTypes.TagVendorId,
    EAuditReportFilterTypes.TagAnyRelated,
    EAuditReportFilterTypes.TagId,
    EAuditReportFilterTypes.TagCategory,
    EAuditReportFilterTypes.ConsoleLogMessage,
];
