import {AngularNames} from '@app/moonbeamConstants';
import * as angular from 'angular';

export class ServerErrorDirective implements ng.IDirective {
    require = 'ngModel';
    link = (scope, element, attributes, ngModel) => {
      scope.$watch(attributes.serverError, function(v) {
        ngModel.$setValidity('server-error-' + attributes.servererrortype, !v) ;
      });
      var self = this;
      ngModel.$parsers.push(function(ngModelValue: string) {
        var model = self.$parse(attributes.serverError);
        model.assign(scope, false);
        return ngModelValue;
      });    
    };

    static factory(): angular.IDirectiveFactory {
      const directive = ($parse: angular.IParseService) => {
        return new ServerErrorDirective($parse);
      };
      directive.$inject = [
        AngularNames.parse
      ];
      return directive;
    }
    constructor(private $parse: angular.IParseService) {};
}

