import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
import { ENotificationCenterColumns } from './notification-center.enums';
import { INotificationCenterSort } from './notification-center.models';

export const NotificationCenterNames = {
  base: 'notification_center'
};

export const NotificationCenterPaths = {
  base: 'notification-center'
};

export const NotificationCenterUrlBuilders = {
  base: () => RouteBuilder.buildUrl([NotificationCenterPaths.base])
};

export const NotificationCenterDefaultSortConfig: INotificationCenterSort = {
  sortBy: ENotificationCenterColumns.Email,
  sortDesc: false,
  sortDir: 'asc'
};

export const SlackHelpUrl = 'https://help.observepoint.com/article/440-slack-notification-integration';
export const JiraHelpUrl = 'https://help.observepoint.com/article/475-jira-integration-information';
export const TeamsHelpUrl = 'https://help.observepoint.com/article/476-microsoft-teams-integration-information';
export const NotificationCenterHelpUrl = 'https://help.observepoint.com/article/554-notification-center';

export const BULK_ACTION_MAX_PARALLEL_REQUESTS = 3;
