import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, switchMap, takeUntil } from 'rxjs/operators';
import { Selection } from 'd3';
import * as d3 from 'd3';
import {
  attachCandleChartToSvg, display, hide, scaleBandAxisGenerator,
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import { EDateFormats, formatDate } from '@app/components/date/date.service';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';

export interface IWebVitalsFullscreenChartData {
  value: string | number,
  sequence?: number,
  date?: Date | string,
  data?: {
    stats: Record<string, any>,
    distribution?: IWebVitalsFullscreenChartDataDistribution,
  },
}

export interface IWebVitalsFullscreenChartDataDistribution {
  poor: number;
  needsImprovement: number;
  good: number;
}

interface IWebVitalsFullscreenChartConfig {
  boundaries?: Record<string, number>,
  data?: IWebVitalsFullscreenChartData[],
  sideLabel?: string,
  tooltipValueLabel?: string
}

@Component({
  selector: 'op-web-vitals-box-plot-fullscreen-chart',
  templateUrl: './web-vitals-box-plot-fullscreen-chart.component.html',
  styleUrls: ['./web-vitals-box-plot-fullscreen-chart.component.scss']
})
export class WebVitalsBoxPlotFullscreenChartComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly svgBoxSelectorName = 'web-vitals-multi-chart';
  readonly barWidth = 84;
  readonly xAxisLowerPadding = 100;
  readonly chartTopMargin: number = 20;
  readonly WHISKER_WIDTH: number = 60;
  readonly BAR_WIDTH: number = 60;
  readonly MEDIAN_WIDTH = 72;
  readonly UNDERLAY_WIDTH: number = 76;
  readonly SCROLL_RIGHT_VALUE = 10000000;
  private readonly chartBodyContainer: string = 'web-vitals-chart-body-container';

  private config$ = new ReplaySubject(1);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  @Input() set config(config: IWebVitalsFullscreenChartConfig) {
    if (!config) {
      return;
    }

    this.processData(config);

    this.config$.next(this.chartConfig);
  }

  @Input() uniqueIdentifier?: string = '';

  private chartConfig: IWebVitalsFullscreenChartConfig = null;
  private margin: { top: number; right: number; bottom: number; left: number } = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 50
  };

  private x: d3.ScaleBand<string>;
  private y: d3.ScaleLinear<number, number>;
  private width: number;
  private height: number;
  private maxY: number;
  private container: Selection<any, any, any, any>;
  private svg: Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private innerSvg: Selection<SVGElement, any, any, any>;
  private datapointTooltipSelector: string = '#datapoint-tooltip';

  private resize$ = new Subject();
  private destroy$ = new Subject();

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
  }

  ngOnInit(): void {
    this.resize$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.createChart();
    });
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private chartRedrawSubscription(): Observable<any> {
    return merge(this.resize$, this.config$)
        .pipe(
            debounceTime(300),
            takeUntil(this.destroy$),
        );
  }

  private initCharts(): void {
    // We need to wait until the sidebar has finished resizing to draw the chart with correct size
    this.config$
        .pipe(
            delay(300),
            switchMap(() => this.chartRedrawSubscription()),
        )
        .subscribe(() => this.createChart());
  }

  ngAfterViewInit(): void {
    this.initCharts();
  }

  processData(config:IWebVitalsFullscreenChartConfig ) {
    let sequence = 0;
    this.chartConfig = {
      ...config,
      data: [...config.data.map(d => {
        const res = { sequence, value: d.value, data: { stats: d.data.stats, distribution: d.data.distribution }, date: d.date }
        sequence++;
        return res;
      })]
    };
  }

  private createChart(): void {
    this.createSvg();
    this.addXAxis();
    this.addYAxis();
    this.createThresholds();
    this.addCandles();
  }

  private createSvg(): void {
    if (this.svg){
      this.svg.remove();
    }

    if(this.innerSvg) {
      this.innerSvg.remove();
    }

    this.container = d3.select(`.${this.svgBoxSelectorName}`);
    // // If selection is empty, any methods called on it will result in error
    if (this.container?.empty()) {
      return;
    }

    this.container = d3.select(`.${this.svgBoxSelectorName}`).html('');
    this.container = d3.select(`.${this.svgBoxSelectorName}`);

    this.height = parseInt(this.container?.style('height'));
    this.width = parseInt(this.container?.style('width'));

    const width = this.getWidth();

    this.svg = this.container.append("svg")
        .attr('class', 'main-svg')
        .attr('style', `width: ${this.width}px; height: ${this.height}px`)
        .style("position", "absolute")
        .style("pointer-events", "none")
        .style("z-index", "3");

    // This div covers scrolling chart at the left of y-axis
    this.container.append('div')
        .attr('class', 'scroll-overflow-hide')
        .attr('style', `height: ${this.height}px; width: ${this.margin.left}px;`)

    const body = this.container.append('div')
        .attr('class', this.chartBodyContainer)
        .attr('style', `height: ${this.height}px; width: ${this.width}px; overflow-x: auto; -webkit-overflow-scrolling: touch;`)

    this.innerSvg = body.append("svg")
        .attr('class', 'inner-svg')
        .attr("width", width)
        .attr("height", this.height)
        .style("display", "block");

    this.scrollChartToTheRight();
  }

  private scrollChartToTheRight(): void {
    setTimeout(() => {
      this.document.querySelectorAll('.web-vitals-chart-body-container')[0].scrollLeft = this.SCROLL_RIGHT_VALUE;
    });
  }

  private getWidth(): number {
    const minWidth = this.barWidth * this.chartConfig.data.length - this.margin.left - this.margin.right;
    const maxWidth = this.width - this.margin.left - this.margin.right;
    return d3.max([minWidth, maxWidth]);
  }

  private addXAxis(): void {
    this.x = scaleBandAxisGenerator([this.margin.left, this.getWidth()], this.chartConfig.data.map((d: any) => d.sequence));

    this.innerSvg.append("g")
        .attr('class', 'x-axis')
        .attr('transform', `translate(0, ${this.height - this.margin.top - this.margin.bottom - this.xAxisLowerPadding})`)
        .call(
            d3.axisBottom(this.x)
                .ticks(this.chartConfig.data.length)
                .tickSize(0)
                .tickFormat((d, i) => this.formatXAxisTickText(d))
        )
        .selectAll('.tick text')
        .attr('class', 'x-axis-tick-text');

    // Apply multiline formatting for X axis
    this.container.selectAll(".tick text")
        .each(function(d, i) {
          const el = d3.select(this);
          const lines = el.text().split("\n");
          el.text('');
          lines.forEach(function(line, lineIndex) {
            el.append("tspan")
                .text(line)
                .attr("x", 0)
                // adjust for multi-line spacing
                .attr("dy", lineIndex === 0 ? 0 : "1.2em");
          });
        });
  }

  private addYAxis(): void {
    this.maxY = Math.max(...this.chartConfig.data.flatMap(d => Object.values(d.data.stats))) * 1.05;
    this.y = d3.scalePow().exponent(0.5)
        .domain([0, this.maxY])
        .range([this.height - this.margin.top - this.margin.bottom - this.xAxisLowerPadding, this.chartTopMargin]);

    this.svg.append('g')
        .attr('class', 'y-axis')
        .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
        .call(
            d3.axisRight(this.y)
                .ticks(5)
                .tickSize(this.width)
                .tickFormat(d => VizUtilsService.formatChartNumbers(d as number))
        )
        .selectAll('.tick text')
        .attr('class', 'y-axis-tick-text')
        // A bit of a magic number, but it's only to position the tick labels
        .attr('transform', `translate(-${this.width}, 0)`);
  }

  createThresholds(): void {
    if (this.chartConfig.boundaries) {
      const warnTooltip = `Values below ${this.chartConfig.boundaries.warnThreshold} ${this.chartConfig.sideLabel} are considered good as defined by Google`;
      const failTooltip = `Values at or above ${this.chartConfig.boundaries.failThreshold} ${this.chartConfig.sideLabel} are considered poor as defined by Google`;

      this.createThreshold(this.chartConfig.boundaries.warnThreshold, 'warn-threshold', warnTooltip);
      this.createThreshold(this.chartConfig.boundaries.failThreshold, 'fail-threshold', failTooltip);
    }
  }

  createThreshold(value: number, className: string, thresholdLabel: string): void {
    if (!this.y || !this.svg) {
      return;
    }

    const yPosition = this.y(value);

    // append the actual visible threshold line
    this.svg.select('g').append('line')
        .attr('x1', 0)
        .attr('y1', yPosition)
        .attr('x2', this.width - this.margin.right)
        .attr('y2', yPosition)
        .attr('stroke', 'black')
        .attr('stroke-width', 2)
        .attr('stroke-dasharray', '5,5')
        .attr('class', `threshold-line ${className}`)
        .style("pointer-events", "all");

    // append an invisible line for easier hover interaction
    this.svg.select('g').append('line')
        .attr('x1', 0)
        .attr('y1', yPosition)
        .attr('x2', this.width - this.margin.right)
        .attr('y2', yPosition)
        .attr('stroke', 'transparent')
        .attr('stroke-width', 10)
        .attr('class', 'threshold-line-wrapper')
        .style("pointer-events", "all")
        .attr('data-threshold-label', thresholdLabel);

    this.createThresholdTooltip();
  }

  createThresholdTooltip(): void {
    const tooltip = d3.select('#threshold-tooltip');

    this.svg.select('g').selectAll('line.threshold-line-wrapper')
        .on('mouseover', (event: MouseEvent) => {
          const line = d3.select(event.currentTarget as SVGLineElement);
          const thresholdLabel = line.attr('data-threshold-label');
          display(tooltip);
          tooltip.html(thresholdLabel);
        })
        .on('mousemove', (event: MouseEvent) => {
          const tooltipWidth = parseInt(tooltip.style('width'));
          const tooltipHeight = parseInt(tooltip.style('height'));

          let xPosition = event.pageX - tooltipWidth / 2;
          let yPosition = event.pageY - tooltipHeight * 2 - 15;

          tooltip
              .style('left', `${xPosition}px`)
              .style('top', `${yPosition}px`);
        })
        .on('mouseout', () => {
          hide(tooltip);
        });
  }

  private addCandles(): void {
    attachCandleChartToSvg({
        data: this.chartConfig.data,
        x: this.x,
        y: this.y,
        height: this.height - this.margin.top - this.margin.bottom - this.xAxisLowerPadding,
        containerClassName: this.svgBoxSelectorName + '-svg',
        barClassName: 'period-total-bar',
        boundaries: this.chartConfig.boundaries,
        whiskerWidth: this.WHISKER_WIDTH,
        barWidth: this.BAR_WIDTH,
        medianWidth: this.MEDIAN_WIDTH,
        underlayWidth: this.UNDERLAY_WIDTH,
        max: this.maxY,
        datapointTooltipSelector: this.datapointTooltipSelector,
        tooltipValueLabel: this.chartConfig.tooltipValueLabel ?? '',
        bodyContainerClassName: this.chartBodyContainer,
    }, this.innerSvg.append('g'));
  }

  private formatXAxisTickText(d: any): string {
    return formatDate(new Date(this.chartConfig.data[d].date), EDateFormats.dateThirtyTwo) + ',\n' + formatDate(new Date(this.chartConfig.data[d].date), EDateFormats.dateThirtyThree) + '\n' + formatDate(new Date(this.chartConfig.data[d].date), EDateFormats.timeOne);
  }
}
