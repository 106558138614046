<div class="journey-image-preview">
  <div class="top-section">
    <div class="back-close-bar">
      <button class="modal-back hover-highlight" (click)="onModalClose()" op-selector="journey-image-modal-back">
        <mat-icon>keyboard_backspace</mat-icon>
        Back
      </button>
      <button class="modal-close hover-highlight" (click)="onModalClose()" op-selector="journey-image-modal-close">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="step-number">
      {{ currentStep }} of {{ numActions }}
      <span *ngIf="label">:&nbsp;{{ label }}</span>
    </div>
  </div>
  <div class="image-arrows">
    <button class="hover-highlight" (click)="onLeftArrowClick()" op-selector="journey-image-modal-left-arrow">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <div class="image-wrap">

      <div class="image-container">
        <img class="image-load-check" src="{{currentAction.screenshot}}" (error)="setDefaultImage()">
      </div>
    </div>
    <button class="hover-highlight" (click)="onRightArrowClick()" op-selector="journey-image-modal-right-arrow">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>
</div>
