import { RouteDataService } from '@app/components/shared/services/route-data.service';
import { IComparisonReportStateParams } from './ror-comparison-report.models';
import { IComparisonRun } from './../comparison-library/comparison-library.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { allComparisonsPlaceholder, RorReportPaths } from './ror-comparison-report.contants';
import { RorComparisonReportService } from './ror-comparison-report.service';
import { RorComparisonReportScoresService } from './shared/ror-comparison-report-scores.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { BreadcrumbService } from '../shared/components/breadcrumb/breadcrumb.service';
import { BreadcrumbHistory } from '../shared/components/breadcrumb/breadcrumb.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-comparison-report',
  templateUrl: './ror-comparison-report.component.html',
  styleUrls: ['./ror-comparison-report.component.scss'],
  providers: [RorComparisonReportScoresService]
})
export class RorComparisonReportComponent implements OnInit, OnDestroy {

  selectComparisonEnabled: boolean;

  auditId: number;
  runId: number;
  comparisonSnapshotId: number;

  history: BreadcrumbHistory;
  runs: IComparisonRun[];

  private destroySubject = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private breadcrumbService: BreadcrumbService,
              private comparisonReportService: RorComparisonReportService,
              private comparisonReportScoresService: RorComparisonReportScoresService) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroySubject)
    ).subscribe(_ => {
      this.selectComparisonEnabled = this.isSelectComparisonEnabled();
    });

    combineLatest(
      [this.activatedRoute.params, this.activatedRoute.queryParams]
    ).pipe(
      map(([params, queryParams]) => ({...params, ...queryParams})),
      takeUntil(this.destroySubject)
    ).subscribe((stateParams: IComparisonReportStateParams) => {
      this.auditId = parseInt(stateParams.auditId);
      this.runId = parseInt(stateParams.runId);
      this.comparisonSnapshotId = stateParams.comparisonSnapshotId ? parseInt(stateParams.comparisonSnapshotId) : allComparisonsPlaceholder.comparisonSnapshotId;
      this.selectComparisonEnabled = this.isSelectComparisonEnabled();

      this.initBreadcrumbs();
      this.fetchData();
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  private isSelectComparisonEnabled(): boolean {
    const allowedRoutes = [
      RorReportPaths.results,
      RorReportPaths.tagPresence,
      RorReportPaths.valuePresence,
      RorReportPaths.variablePresence,
      RorReportPaths.missingPages
    ];
    const activePath = RouteDataService.getUrlSegments(this.router.parseUrl(this.router.url));
    return allowedRoutes.includes(activePath.pop());
  }

  private initBreadcrumbs(): void {
    this.breadcrumbService.historySubject
      .pipe(takeUntil(this.destroySubject))
      .subscribe(breadcrumbHistory => {
        this.history = breadcrumbHistory;
      });
    this.breadcrumbService.initBreadcrumbs(this.activatedRoute, this.getParentUrl());
  }

  private getParentUrl(): string {
    const resultsIndex = this.router.url.indexOf('/' + RorReportPaths.results);
    return this.router.url.substr(0, resultsIndex);
  }

  private fetchData(): void {
    this.comparisonReportScoresService.getResultScores(this.auditId).subscribe();

    this.comparisonReportService.getAssignedComparisonRuns(this.auditId, this.runId).subscribe(runs => {
      this.runs = [allComparisonsPlaceholder as any, ...runs];
      this.comparisonReportService.setComparisonsRunsCache(runs);
    });
  }

  goToComparisonResult(comparisonSnapshotId: number): void {
    comparisonSnapshotId = comparisonSnapshotId === allComparisonsPlaceholder.comparisonSnapshotId ? null : comparisonSnapshotId;
    this.router.navigate([], {
      queryParams: { comparisonSnapshotId },
      queryParamsHandling: 'merge'
    });
  }

}
