<div class="wv-chart-wrap">
  <div class="stats" *ngIf="false">
    <div class="stat">
      <div class="stat-label">Median</div>
      <div class="stat-value">{{ config?.stats?.median?.toLocaleString() }}</div>
    </div>
    <div class="stat">
      <div class="stat-label">Average</div>
      <div class="stat-value">{{ config?.stats?.average?.toLocaleString() }}</div>
    </div>
    <div class="stat">
      <div class="stat-label">Min</div>
      <div class="stat-value">{{ config?.stats?.min?.toLocaleString() }}</div>
    </div>
    <div class="stat">
      <div class="stat-label">Max</div>
      <div class="stat-value">{{ config?.stats?.max?.toLocaleString() }}</div>
    </div>
  </div>
  <div class="wv-chart-svg-container"></div>
</div>
<div id="threshold-tooltip" class="wv-tooltip"></div>
<div id="datapoint-tooltip" class="wv-datapoint-tooltip"></div>
