import { Injectable, OnDestroy } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { Observable, Subject } from 'rxjs';
import { EConsentCategoryComplianceStatus } from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IPrivacyRequestCompliance,
  IPrivacyRequestsLocations,
  IPrivacyRequestsPages,
  IPrivacyRequestsPagesApiBody,
  IPrivacyRequestsSpecificTrends,
  IPrivacyRequestsSummary,
  IPrivacyRequestsTableState,
  IPrivacyRequestsTrends
} from './privacy-requests.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { takeUntil } from 'rxjs/operators';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class PrivacyRequestsService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getPrivacyRequestsSummary(auditId: number, runId: number, body?: IAuditReportApiPostBody): Observable<IPrivacyRequestsSummary> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/request-privacy`, body);
  }

  getPrivacyRequestsTrends(auditId: number, runId: number, filters?: IAuditReportApiPostBody): Observable<IPrivacyRequestsTrends> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/runs/${runId}/reports/request-privacy/trends`, filters);
  }

  getPrivacyRequestsTrend(
    auditId: number,
    trendName: EConsentCategoryComplianceStatus,
    days: number
  ): Observable<IPrivacyRequestsSpecificTrends> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/reports/request-privacy/trends/${trendName}?days=${days}`);
  }

  getPrivacyRequestsCompliance(
    auditId: number,
    runId: number,
    pagination: IPrivacyRequestsTableState,
    body?: IAuditReportApiPostBody
  ): Observable<IPrivacyRequestCompliance> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/request-privacy/compliance`;
    const queryParams = `?page=${pagination.page}&size=${pagination.size}&sortBy=${pagination.sortBy}&sortDesc=${pagination.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }

  getPrivacyRequestsPages(
    auditId: number,
    runId: number,
    pagination: IPrivacyRequestsTableState,
    body?: IAuditReportApiPostBody
  ): Observable<IPrivacyRequestsPages> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/request-privacy/pages`;
    const queryParams = `?page=${pagination.page}&size=${pagination.size}&sortBy=${pagination.sortBy}&sortDesc=${pagination.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }

  getPrivacyRequestsRequestPages(
    auditId: number,
    runId: number,
    pagination: IPrivacyRequestsTableState,
    body?: IPrivacyRequestsPagesApiBody
  ): Observable<IPrivacyRequestsPages> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/request-privacy/request-pages`;
    const queryParams = `?page=${pagination.page}&size=${pagination.size}&sortBy=${pagination.sortBy}&sortDesc=${pagination.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getPrivacyRequestsLocations(
    auditId: number,
    runId: number,
    body?: IAuditReportApiPostBody
  ): Observable<IPrivacyRequestsLocations> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/request-privacy/locations`, body);
  }
}
