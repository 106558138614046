<div class="table-container">
  <h6>{{itemsCount}} item{{itemsCount > 1 ? 's' : ''}}</h6>

  <div class="table-details">
  
    <div class="table-header">
      <span class="tag-name-header">Tag Name</span>
      <span class="request-parity-header">Differences</span>
      <div class="pages-count-header">
        <span>Pages Count</span>
      </div>
    </div>
  
    <div class="table-content" *ngFor="let tag of tags">
      <span class="tag-name-cell">
        <img [src]="tagIdToTagMap.get(tag.tagId)?.iconUrl" class="tag-icon" [alt]="tagIdToTagMap.get(tag.tagId)?.name" />
        <span>{{ tagIdToTagMap.get(tag.tagId)?.name }}</span>
      </span>
      <div class="flex-col flex-stretch">
        <div class="count-cell" *ngFor="let diffObj of tag.presenceDiffs">
          <span *ngIf="tag.presenceDiffs.length === 1 && diffObj.diff === 0; else elseBlock">
            No tag presence changes were found. Choose the Variable Summary tab above for more detail about variable changes.
          </span >
          <ng-template #elseBlock>
            <span>{{ diffObj.diff | requestParityFormatter }}</span>
          </ng-template>
          <a class="diff-pages-count link" (click)="openPages(tag.tagId, diffObj.diff)">{{ diffObj.pagesCount }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
