import { EAuditReportFilterTypes } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { IAuditReportExportMenuData } from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import { EPageUrlFilterType } from '../../audit-report/audit-report.constants';
import { CommonReportsPagesFiltersConfig } from '@app/components/audit-reports/reports/general-reports.constants';

export interface ITagPrivacySummary {
  totalPageCount: number;
  filteredPageCount: number;
  totalUniqueTagCount: number;
  filteredUniqueTagCount: number;
  filteredApprovedTagCount: number;
  filteredUnapprovedTagCount: number;
  filteredPageWithUnapprovedTagCount: number;
  totalApprovedTagCount: number;
  totalUnapprovedTagCount: number;
}

export interface ITagPrivacyTrendsOverviews {
  runs: ITagPrivacyTrendOverview[];
}

export interface ITagPrivacyTrendOverview {
  runId: number;
  completedAt: string
  totalUniqueTagCount: number;
  totalApprovedTagCount: number;
  totalUnapprovedTagCount: number;
}

export interface ITagPrivacyTrends {
  runs: ITagPrivacyTrend[];
}

export interface ITagPrivacyTrend {
  runId: number;
  completedAt: string;
  trendValue: number;
}

export interface ITagPrivacySorting {
  page: number;
  size: number;
  sortBy: string;
  sortDesc: boolean;
}

export interface ITagPrivacyApiBody {
  pageUrl: {
    filterType: string,
    filterValue: string
  },
  primaryTagsOnly: boolean,
  tagId: number,
  tagCategoryId: number,
  tagAccount: string,
  consentCategorySnapshotId: number,
  consentCategoryComplianceStatus: string
}

export interface ITagPrivacyMetadata {
  pagination: {
    totalCount: number;
    totalPageCount: number;
    pageSize: number;
    currentPageSize: number;
    currentPageNumber: number;
  }
}

export interface ITagPrivacyComplianceTag {
  tagId: number;
  tagAccount: string;
  consentCategoryComplianceStatus: string;
  consentCategorySnapshotId: number;
  consentCategoryName: string;
  filteredPageCount: number;
}

export interface ITagPrivacyPagesTagApiBody {
  pageUrl?: {
    filterType: EPageUrlFilterType,
    filterValue: string
  },
  tagId: number,
  tagAccount: string,
}

export interface ITagPrivacyComplianceResponse {
  metadata: ITagPrivacyMetadata;
  tags: ITagPrivacyComplianceTag[]
}

export interface ITagPrivacyPage {
  pageId: string;
  pageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  finalPageStatusCode: number;
  tagRequestCount: number;
}

export interface ITagPrivacyPagesResponse {
  metadata: ITagPrivacyMetadata;
  pages: ITagPrivacyPage[]
}

export interface ITagPrivacyPageRow {
  pageId: string;
  pageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  tagRequestCount: number;
  pageLoadTimeClass: string;
  pageStatusCodeClass: string;
}

export const TagPrivacyRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,
  EAuditReportFilterTypes.TagVendorId,
  EAuditReportFilterTypes.TagPrimaryTagsOnly,
  EAuditReportFilterTypes.TagId,
  EAuditReportFilterTypes.TagCategory,
  EAuditReportFilterTypes.TagAccount,
  EAuditReportFilterTypes.ConsentCategoryComplianceStatus,
  EAuditReportFilterTypes.ConsentCategoryId,
];

export type TagsPrivacyTagComplianceExportMenuData = IAuditReportExportMenuData<{}>;
export type TagsPrivacyPagesExportMenuData = IAuditReportExportMenuData<Partial<ITagPrivacyPagesTagApiBody>>;
