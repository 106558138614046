import { Injectable } from '@angular/core';
import { Router, Route, UrlSegment } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { userIsOPAdmin } from '@app/authUtils';
import { HttpParams } from '@angular/common/http';
import { AccountsService } from '@app/components/account/account.service';
import { AuthenticationService } from '../core/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AdminPortalGuard  {

  constructor(private authenticationService: AuthenticationService,
    private accountsService: AccountsService) { }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.accountsService.getUser().pipe<boolean, boolean>(
      map(user => {
        if (userIsOPAdmin(user)) return true;
        else {
          this.authenticationService.goToLoginPage();
          return false;
        }
      }),
      catchError(_ => {
        this.authenticationService.goToLoginPage();
        return of(false);
      })
    );
  }

}
