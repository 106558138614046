import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MatChipGrid } from '@angular/material/chips';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { FloatLabelType } from '@angular/material/form-field';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RecipientsFormControlComponent),
  multi: true
};

const FORM_CONTROL_VALIDATION = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RecipientsFormControlComponent),
  multi: true
};

@Component({
  selector: 'op-recipients-form-control',
  templateUrl: './recipients-form-control.component.html',
  styleUrls: ['./recipients-form-control.component.scss'],
  providers: [FORM_CONTROL_VALUE_ACCESSOR, FORM_CONTROL_VALIDATION]
})
export class RecipientsFormControlComponent implements ControlValueAccessor, Validator {

  readonly separatorKeysCodes = [COMMA, ENTER, TAB];
  @Input() maxEmailsLength = 10;
  @Input() floatLabel?: FloatLabelType = 'auto';
  @Input() isReadOnly = false;
  @Input() hideHint = false;
  @Input() placeholder: string;
  @Input() label = 'ENTER EMAIL ADDRESSES';
  @Input() readOnlyLabel: string;
  @Input() shouldEmitOnWriteValue: boolean = false;
  @Output() onEmailsChanged = new EventEmitter<string[]>();
  @ViewChild(MatChipGrid) chipGrid: MatChipGrid;

  emailsInput = '';
  emails: string[] = [];
  redEmailsHint = '';

  onTouched: () => void;
  onChange: (model: string[]) => void = () => { };

  onKeyUp(ev: KeyboardEvent) {
    const inputValue = (ev.target as HTMLInputElement).value;
    if (this.separatorKeysCodes.includes(ev.keyCode)) {
      this.parseBuffer(inputValue);
    } else {
      const emails = inputValue.split(',');
      this.validateEmail(emails[0].trim());
    }
  }

  onBlur(ev: FocusEvent) {
    const inputValue = (ev.target as HTMLInputElement).value;
    this.parseBuffer(inputValue);
  }

  onPaste(ev: ClipboardEvent) {
    const inputValue = ev.clipboardData.getData('text');
    this.parseBuffer(inputValue);
  }

  remove(email: string) {
    const idx = this.emails.findIndex(e => e === email);
    if (idx !== -1) {
      this.emails.splice(idx, 1);
      this.validateEmail('');
      this.onChange(this.emails);
      this.onEmailsChanged.next(this.emails);
    }
  }

  removeAll() {
    if (this.isReadOnly) return;
    this.emails = [];
    this.onChange(this.emails);
    this.onEmailsChanged.next(this.emails);
  }

  private parseBuffer(inputValue: string) {
    /* 
      We should update Chip's input in the next digest loop. Otherwise, it isn't updated correctly.
      For examle, input isn't cleared when pasting emails.
    */
    setTimeout(() => {
      const emails = inputValue.split(',');

      for (let i = 0; i < emails.length; i++) {
        const email = emails[i].trim();
        if (this.validateEmail(email)) {
          if (email.length > 0) this.emails.push(email);
          this.emailsInput = '';
        } else {
          this.emailsInput = emails.splice(i).join(',');
          break;
        }
      }

      this.onChange(this.emails);
      this.onEmailsChanged.next(this.emails);
    });
  }

  private validateEmail(email: string): boolean {
    // Prepare error message, if any
    if (email) {
      if (!OPValidators.isInvalidEmail(email)) {
        const idx = this.emails.findIndex(e => e === email);

        if (idx === -1) {
          this.redEmailsHint = '';

          if (this.emails.length > this.maxEmailsLength) {
            this.redEmailsHint = 'Maximum number of emails is ' + this.maxEmailsLength;
          }

        } else {
          this.redEmailsHint = 'This email is already in use';
        }
      } else {
        this.redEmailsHint = 'Invalid email address';
      }
    } else {
      this.redEmailsHint = '';
      if (this.emails.length > this.maxEmailsLength) {
        this.redEmailsHint = 'Maximum number of emails is ' + this.maxEmailsLength;
      }
    }

    // Set error state for ChipList component
    if (this.chipGrid) this.chipGrid.errorState = !!this.redEmailsHint;

    return !this.redEmailsHint;
  }

  // CVA implementation
  writeValue(emails: string[]): void {
    if (!Array.isArray(emails)) return;
    this.emails = emails;
    if (this.shouldEmitOnWriteValue) {
      this.onEmailsChanged.next(this.emails);
    }
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Validator implementation
  validate(_: UntypedFormControl): ValidationErrors {
    return this.redEmailsHint
      ? { valid: false, message: this.redEmailsHint }
      : null;
  }

}
