<div class="login-page-wrap">
  <div class="form-wrap transparent-bg">
    <span class="icon-op-logo"></span>

    <!-- Login view -->
    <div class="open-login-form-wrap" [class.has-spinner]="showSpinner" *ngIf="loginView === ELoginView.LOGIN">
      <div class="login-error">
        <ng-container *ngIf="loginFailureMessage">
          {{ loginFailureMessage }}
        </ng-container>
      </div>
      <form validate-form
            *ngIf="!showSpinner"
            formName="loginForm"
            [formGroup]="loginForm"
            (submit)="login()"
            class="op-login-form">
        <input type="text" name="username" id="username" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
               placeholder="Username" formControlName="username" class="op-login-form-field">
        <input type="password" name="password" id="password" placeholder="Password" formControlName="password" class="op-login-form-field">
        <button mat-flat-button color="primary" type="submit" class="op-login-btn">Log In</button>
        <a id="forgot-password" class="forgot-password" (click)="openForgotPassword()">Forgot password or username?</a>
      </form>
      <mat-spinner *ngIf="showSpinner"
                    [diameter]="25"
                    [strokeWidth]="3"
                    class="spinner">
      </mat-spinner>
    </div>

    <!-- Signout view -->
    <div class="signout-section"
        *ngIf="loginView === ELoginView.SIGNOUT">
        <div class="data-paragraph">
          You have been successfully logged out.
        </div>
        <button mat-flat-button color="primary" (click)="switchToLogin()" class="op-login-btn">
          Sign Back In
        </button>
    </div>

    <!-- Reset password view -->
    <div class="reset-password-form"
         *ngIf="loginView === ELoginView.RESET">
      <div class="login-error">
        <ng-container *ngIf="resetError">
          {{ resetError }}
        </ng-container>
      </div>
      <div class="reset-request-section" *ngIf="resetView === EResetView.REQUEST" [class.has-spinner]="showSpinner">
        <form formName="resetForm" [formGroup]="resetForm" (submit)="resetPassword()" *ngIf="!showSpinner">
          <div class="data-paragraph">
            Create new password
          </div>
          <div class="field-wrap new-password-wrap">
            <input type="password" placeholder="New password" formControlName="newPassword" class="op-login-form-field">
            <div class="field-error-wrap">
              <mat-error *ngIf="newPassword.touched && newPassword?.errors?.required">
                This field is required
              </mat-error>
              <mat-error *ngIf="newPassword?.errors?.minlength?.requiredLength">
                Password length needs to be 8 or more characters
              </mat-error>
            </div>
          </div>
          <div class="field-wrap new-password-confirm-wrap">
            <input type="password" placeholder="Confirm new password" formControlName="newPasswordConfirm" class="op-login-form-field">
            <div class="field-error-wrap">
              <mat-error *ngIf="newPasswordConfirm.touched && newPasswordConfirm?.errors?.required">
                This field is required
              </mat-error>
              <mat-error *ngIf="resetForm.hasError('noMatch')">
                Passwords do not match
              </mat-error>
            </div>
        </div>
          <button mat-flat-button color="primary" type="submit" class="op-login-btn" [disabled]="resetForm.invalid">
            Update Password
          </button>
        </form>
        <mat-spinner *ngIf="showSpinner"
                     [diameter]="25"
                     [strokeWidth]="3"
                     class="spinner">
        </mat-spinner>
      </div>

      <div class="reset-confirm-section" *ngIf="resetView === EResetView.CONFIRM">
        <mat-icon class="reset-confirmation-icon color-success">done</mat-icon>
        <div class="data-paragraph center-text">
          Your password has been reset.
        </div>
        <button mat-flat-button color="primary" (click)="switchToLogin()" class="op-login-btn">
          Go To Login Screen
        </button>
      </div>

      <div class="reset-error-section" *ngIf="resetView === EResetView.ERROR">
        <button mat-flat-button color="primary" (click)="switchToLogin()" class="op-login-btn">
          Okay
        </button>
      </div>
    </div>

    <!-- Forgot Password view -->
    <div class="forgot-password-section" *ngIf="loginView === ELoginView.FORGOT">
      <div class="forgot-password-form-wrap" *ngIf="forgotView === EForgotView.REQUEST" [class.has-spinner]="showSpinner">
          <form validate-form
                (submit)="forgotPassword()"
                [formGroup]="forgotForm"
                *ngIf="!showSpinner">
            <div class="data-paragraph data-paragraph-forgot-password">
              Enter your email address or username, if it is associated with an account you'll receive a password
              reset link via email shortly. If you don't see it, please check your spam folder.
            </div>
            <input type="text" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
                   placeholder="Enter email address or username" formControlName="username" class="op-login-form-field">
            <button mat-flat-button color="primary" type="submit" class="op-login-btn" [disabled]="!forgotUsername.value">
              Send Email
            </button>
          </form>
          <mat-spinner *ngIf="showSpinner"
                       [diameter]="25"
                       [strokeWidth]="3"
                       class="spinner">
          </mat-spinner>
      </div>

      <div class="forgot-confirmation"  *ngIf="forgotView === EForgotView.CONFIRM">
        <mat-icon class="forgot-confirmation-icon color-success">done</mat-icon>
        <div class="data-paragraph">
          If a user exists for this username or email address you will receive an email shortly.
        </div>
        <button mat-flat-button color="primary" (click)="resetLogin()" class="op-login-btn">
          Okay
        </button>
      </div>

      <div class="forgot-confirmation"  *ngIf="forgotView === EForgotView.ERROR">
        <mat-icon class="forgot-confirmation-icon color-error">error</mat-icon>
        <div class="data-paragraph">
          An unknown error has occurred. Please try again later.
        </div>
        <button mat-flat-button color="primary" (click)="resetLogin()" class="op-login-btn">
          Okay
        </button>
      </div>
    </div>

    <div class="privacy">
      Any personal data you submit is processed in accordance with the terms of our
      <a (click)="openLink('https://www.observepoint.com/privacy-policy/')">privacy policy</a>.
    </div>
  </div>
  <div class="banner-wrap transparent-bg" [hidden]="!marketingImageLoaded">
    <div class="banner-wrap-inner">
      <a href="https://ops.observepoint.com/app-cta.php" target="_blank" rel="noopener noreferrer">
        <img class="login-banner" [src]="bannerUrl" (load)="marketingImageLoaded = true">
      </a>
    </div>
  </div>
</div>
