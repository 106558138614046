import { IFullscreenChartConfig } from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';

function defaultChartConfig(title: string): IFullscreenChartConfig {
  return {
    title: title,
    data: [],
    sideLabel: 'number',
    showXAxis: true,
    showYAxis: true,
    showSummaryLines: true,
    tooltip: (value: number) => `${value} cookie${value === 1 ? '' : 's'}`
  };
}

export const APPROVED_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Approved Cookies');
export const UNIQUE_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Unapproved Cookies');
export const UNAPPROVED_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Unique Cookies');
