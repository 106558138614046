import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  JiraHelpUrl,
  SlackHelpUrl,
  TeamsHelpUrl
} from '@app/components/notifications-center/notification-center.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'notification-channels-popover',
  templateUrl: './notification-channels-popover.component.html',
  styleUrls: ['./notification-channels-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationChannelsPopoverComponent {
  protected readonly SlackHelpUrl = SlackHelpUrl;
  protected readonly JiraHelpUrl = JiraHelpUrl;
  protected readonly TeamsHelpUrl = TeamsHelpUrl;
}
