export enum EJourneyStatusText {
  Success = 'Success',
  ActionFailure = 'Run Stopped - Action Failure',
  RuleFailure = 'Rule Failure'
}

export const AdvancedText = 'Advanced Journey - ';

export enum EAdvancedJourneyFeatures {
  FileSubstitution = 'File Substitution',
  GpcSignal = 'GPC Signal',
  Vpn = 'VPN',
  TechnologyBlocking = 'Technology Blocking',
  PrivacyConsentActions = 'User consent state (CMP) configured',
  BlockThirdPartyCookies = 'Block third-party cookies',
  CustomProxy = 'Custom Proxy',
}