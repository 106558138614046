export enum EExportCenterDataStatus {
  notInitialized = 'notInitialized',
  loading = 'loading', 
  loaded = 'loaded'
}

export enum EExportCenterTableColumn {
  DateExported = 'date_exported',
  AuditName = 'audit_name',
  RunDate = 'run_date',
  ExportName = 'export_name',
  RequestedBy = 'requested_by',
  Status = 'status'
}

export enum EExportCenterItemType {
  Audit = 'audit',
  WebJourney = 'web_journey',
  LiveConnect = 'live_connect',
  ConsentCategories = 'consent_categories',
  AppJourney = 'app_journey'
}

export enum ERunExportType {
  // Audits
  Audit_AuditSummaryDetectedTags = 'AUDIT_RUN_EXPORT-AUDIT_SUMMARY_DETECTED_TAGS',
  Audit_RuleSummaryPageResults = 'AUDIT_RUN_EXPORT-RULE_SUMMARY_PAGE_RESULTS',
  Audit_RuleSummaryPageRuleResults = 'AUDIT_RUN_EXPORT-RULE_SUMMARY_PAGE_RULE_RESULTS',
  Audit_PageSummaryPages = 'AUDIT_RUN_EXPORT-PAGE_SUMMARY_PAGES',
  Audit_PageSummaryBrokenPages = 'AUDIT_RUN_EXPORT-PAGE_SUMMARY_BROKEN_SCANNED_LINKS',
  Audit_TagInventoryPages = 'AUDIT_RUN_EXPORT-TAG_INVENTORY_PAGES',
  Audit_TagHealthPages = 'AUDIT_RUN_EXPORT-TAG_HEALTH_PAGES',
  Audit_TagDuplicatesAndMultiplesPages = 'AUDIT_RUN_EXPORT-TAG_DUPLICATES_AND_MULTIPLES_PAGES',
  Audit_VariableInventoryTagAccountPages = 'AUDIT_RUN_EXPORT-VARIABLE_INVENTORY_TAG_ACCOUNT_PAGES',
  Audit_VariableInventoryTagAccountVariablePages = 'AUDIT_RUN_EXPORT-VARIABLE_INVENTORY_TAG_ACCOUNT_VARIABLE_PAGES',
  Audit_VariableInventoryTagAccountVariableNotSetPages = 'AUDIT_RUN_EXPORT-VARIABLE_INVENTORY_TAG_ACCOUNT_VARIABLE_NOT_SET_PAGES',
  Audit_VariableInventoryTagAccountVariableValuePages = 'AUDIT_RUN_EXPORT-VARIABLE_INVENTORY_TAG_ACCOUNT_VARIABLE_VALUE_PAGES',
  Audit_CookieInventoryPages = 'AUDIT_RUN_EXPORT-COOKIE_INVENTORY_PAGES',
  Audit_CookieInventoryCookiePages = 'AUDIT_RUN_EXPORT-COOKIE_INVENTORY_COOKIE_PAGES',
  Audit_BrowserLogsPages = 'AUDIT_RUN_EXPORT-BROWSER_LOGS_PAGES',
  Audit_TagPrivacyCompliance = 'AUDIT_RUN_EXPORT-TAG_PRIVACY_COMPLIANCE',
  Audit_TagPrivacyPages = 'AUDIT_RUN_EXPORT-TAG_PRIVACY_PAGES',
  Audit_TagPrivacyTagPages = 'AUDIT_RUN_EXPORT-TAG_PRIVACY_TAG_PAGES',
  Audit_CookiePrivacyCompliance = 'AUDIT_RUN_EXPORT-COOKIE_PRIVACY_COMPLIANCE',
  Audit_CookiePrivacyPages = 'AUDIT_RUN_EXPORT-COOKIE_PRIVACY_PAGES',
  Audit_CookiePrivacyCookiePages = 'AUDIT_RUN_EXPORT-COOKIE_PRIVACY_COOKIE_PAGES',
  Audit_RequestPrivacyCompliance = 'AUDIT_RUN_EXPORT-REQUEST_PRIVACY_COMPLIANCE',
  Audit_RequestPrivacyPages = 'AUDIT_RUN_EXPORT-REQUEST_PRIVACY_PAGES',
  Audit_RequestPrivacyRequestPages = 'AUDIT_RUN_EXPORT-REQUEST_PRIVACY_REQUEST_PAGES',
  Audit_FileChangesPages = 'AUDIT_RUN_EXPORT-FILE_CHANGES_PAGES',
  Audit_FileChangesFilePages = 'AUDIT_RUN_EXPORT-FILE_CHANGES_FILE_PAGES',
  Audit_TagMetadata = 'AUDIT_RUN_EXPORT-TAG_METADATA',
  Audit_TagInventoryPageTags = 'AUDIT_RUN_EXPORT-TAG_INVENTORY_PAGE_TAGS',
  Audit_CookieInventoryCookies = 'AUDIT_RUN_EXPORT-COOKIE_INVENTORY_COOKIES',
  Audit_CookieInventoryCookiesAggregated = 'AUDIT_RUN_EXPORT-COOKIE_INVENTORY_COOKIES_AGGREGATED',
  Audit_BrowserLogsPageLogs = 'AUDIT_RUN_EXPORT-BROWSER_LOGS_PAGE_LOGS',
  Audit_NetworkRequestLogPageRequests = 'AUDIT_RUN_EXPORT-NETWORK_REQUEST_LOG_PAGE_REQUESTS',
  Audit_RuleSummaryRules = 'AUDIT_RUN_EXPORT-RULE_SUMMARY_RULES',
  Audit_VariableInventoryTagVariables = 'AUDIT_RUN_EXPORT-VARIABLE_INVENTORY_TAG_VARIABLES',

  // Web Journeys
  WebJourney_ActionTagVariables = 'WEB_JOURNEY_RUN_EXPORT-ACTION_TAG_VARIABLES',
  WebJourney_ActionNetworkRequests = 'WEB_JOURNEY_RUN_EXPORT-ACTION_NETWORK_REQUESTS',
  WebJourney_ActionCookies = 'WEB_JOURNEY_RUN_EXPORT-ACTION_COOKIES',
  WebJourney_ActionBrowserLogs = 'WEB_JOURNEY_RUN_EXPORT-ACTION_BROWSER_LOGS',
  WebJourney_ActionRuleSummary = 'WEB_JOURNEY_RUN_EXPORT-RULE_SUMMARY',
  WebJourney_ActionTags = 'WEB_JOURNEY_RUN_EXPORT-ACTION_TAGS',
  WebJourney_ActionSummary = 'WEB_JOURNEY_RUN_EXPORT-ACTION_SUMMARY',

  LiveConnect_Full = 'MANUAL_JOURNEY_RUN_EXPORT-FULL',

  ConsentCategories = 'CONSENT_CATEGORY_EXPORT-ACCOUNT_CONSENT_CATEGORY_CONFIGS_EXPORT',

  AccountUsageWebJourneyRuns = 'ACCOUNT_USAGE_EXPORT-WEB_JOURNEY_RUNS_EXPORT',
  AccountUsageAuditRuns = 'ACCOUNT_USAGE_EXPORT-AUDIT_RUNS_EXPORT'
}

export enum EExportStatus {
  queued = 'queued',
  inProgress = 'in_progress',
  completed = 'completed',
  failed = 'failed',
  noData = 'no_data'
}
