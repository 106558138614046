import { Component, Input, HostListener, OnInit, OnDestroy } from '@angular/core';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { combineLatest, Subject, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UpgradeUserBannerService } from './upgrade-user-banner.service';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'upgrade-user-banner',
  templateUrl: './upgrade-user-banner.component.html',
  styleUrls: ['./upgrade-user-banner.component.scss'],
  providers: [UpgradeUserBannerService]
})
export class UpgradeUserBannerComponent implements OnInit, OnDestroy {

  @Input() forceHidePartnerLogo?: boolean;
  @Input() forceHidePagesScanned?: boolean;
  @Input() textOverride?: string;

  accountType: EAccountType;
  showPartnerLogo: boolean;
  showPagesScanned: boolean;
  partnerId?: number;
  pagesScannedThisYear: number;
  maxPagesAllowedForScanning: number;
  isVisitorMode$: Observable<boolean>;

  readonly EAccountType = EAccountType;

  private destroySubject = new Subject<void>();

  constructor(private upgradeUserBannerService: UpgradeUserBannerService,
              private applicationChromeService: ApplicationChromeService
  ) { }

  ngOnInit() {
    combineLatest([
      this.upgradeUserBannerService.accountPreview$,
      this.upgradeUserBannerService.getUsageDataV3(),
    ])
      .pipe(takeUntil(this.destroySubject))
      .subscribe(([{ accountType, partner }, accountOverview]) => {
        this.accountType = accountType;
        this.showPartnerLogo = (typeof partner?.id === 'number' && accountType === EAccountType.SAMPLE) && !this.forceHidePartnerLogo;
        this.showPagesScanned = accountType === EAccountType.FREETRIAL && !this.forceHidePagesScanned;

        this.partnerId = partner?.id;
        this.pagesScannedThisYear = accountOverview.auditUsage.usageCount;
        this.maxPagesAllowedForScanning = accountOverview.auditUsage.usageLimit;
      });

    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  @HostListener('click') openUpgradeUserPage() {
    this.upgradeUserBannerService.openUpgradeUserPage();
  }

}
