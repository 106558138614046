import { CookieInventoryRelevantFilters } from './../reports/cookie-inventory/cookie-inventory.constants';
import { VariableInventoryRelevantFilters } from './../reports/variable-inventory/variable-inventory.constants';
import { RuleSummaryRelevantFilters } from './../reports/rule-summary/rule-summary.constants';
import { IPageStatusCodeFilter } from '@app/components/audit-reports/audit-report/audit-report.models';
import { AuditReportContainerStateNames } from '../audit-report-container.constants';
import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
import { DecimalPipe } from '@angular/common';
import { AuditSummaryRelevantFilters } from '../reports/audit-summary/audit-summary.models';
import { EAuditReportType } from './audit-report.enums';
import { PageSummaryRelevantFilters } from '../reports/page-summary/page-summary.constants';
import { TagInventoryRelevantFilters } from '../reports/tag-inventory/tag-inventory.constants';
import { TagHealthRelevantFilters } from '../reports/tag-health/tag-health.constants';
import { TagDuplicatesAndMultiplesRelevantFilters } from '../reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.constants';
import { BrowserConsoleLogsRelevantFilters } from '../reports/browser-console-logs/browser-console-logs.constants';
import { CookiesPrivacyRelevantFilters } from '../reports/privacy-cookies/privacy-cookies.models';
import { TagPrivacyRelevantFilters } from '../reports/privacy-tags/privacy-tags.models';
import { RequestsPrivacyRelevantFilters } from '../reports/privacy-requests/privacy-requests.models';
import { JsFileChangesRelevantFilters } from '../reports/js-files-changes/js-file-changes.constants';

const AuditReportBaseStateName = AuditReportContainerStateNames.base + '.audit_report';

export const AuditReportStateNames = {
  base: AuditReportBaseStateName,
  useCases: AuditReportBaseStateName + '.use-cases',
  useCaseOverview: AuditReportBaseStateName + '.use-case-overview',
  useCaseAnalytics: AuditReportBaseStateName + '.use-case-analytics',
  useCasePrivacy: AuditReportBaseStateName + '.use-case-privacy',
  useCaseLandingPage: AuditReportBaseStateName + '.use-case-landing-page',
  auditSummary: AuditReportBaseStateName + '.audit_summary',
  alertSummary: AuditReportBaseStateName + '.alert_summary',
  pageDetails: AuditReportBaseStateName + '.page_details',
  pageDetailsDeepLink: AuditReportBaseStateName + '.page_details_deep_link',
  pageSummary: AuditReportBaseStateName + '.page_summary',
  ruleSummary: AuditReportBaseStateName + '.rule_summary',
  tagInventory: AuditReportBaseStateName + '.tag_inventory',
  variableInventory: AuditReportBaseStateName + '.variable_inventory',
  tagHealth: AuditReportBaseStateName + '.tag_health',
  cookieInventory: AuditReportBaseStateName + '.cookie_inventory',
  browserConsoleLogs: AuditReportBaseStateName + '.browser_console_logs',
  duplicatesAndMultiples: AuditReportBaseStateName + '.duplicates_and_multiples',
  privacyCookies: AuditReportBaseStateName + '.privacy_cookies',
  privacyRequests: AuditReportBaseStateName + '.privacy_requests',
  privacyTags: AuditReportBaseStateName + '.privacy_tags',
  jsFileChanges: AuditReportBaseStateName + '.js_file_changes'
};

export const AuditReportPaths = {
  base: 'report',
  useCases: 'use-cases',
  useCaseOverview: 'overview',
  useCaseAnalytics: 'analytics',
  useCasePrivacy: 'privacy',
  useCaseLandingPage: 'landing-page',
  auditSummary: 'audit-summary',
  alertSummary: 'alert-summary',
  pageDetails: 'page-details',
  pageDetailsDeepLink: 'report/page-details/:pageId',
  pageSummary: 'page-summary',
  ruleSummary: 'rule-summary',
  tagInventory: 'tag-inventory',
  variableInventory: 'variable-inventory',
  tagHealth: 'tag-health',
  cookieInventory: 'cookie-inventory',
  browserConsoleLogs: 'console-logs',
  privacyCookies: 'privacy-cookies',
  privacyRequests: 'privacy-requests',
  duplicatesAndMultiples: 'duplicates-and-multiples',
  privacyTags: 'privacy-tags',
  privacyFileChanges: 'privacy-file-changes',
};

const baseUrl = (auditId: number, runId: number) => AuditReportContainerUrlBuilders.base(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.base]);

export const AuditReportUrlBuilders = {
  base: baseUrl,
  useCases: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.useCases]);
  },
  useCaseOverview: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.useCases, AuditReportPaths.useCaseOverview]);
  },
  useCaseAnalytics: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.useCases, AuditReportPaths.useCaseAnalytics]);
  },
  useCasePrivacy: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.useCases, AuditReportPaths.useCasePrivacy]);
  },
  useCaseLandingPage: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.useCases, AuditReportPaths.useCaseLandingPage]);
  },
  auditSummary: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.auditSummary]);
  },
  alertSummary: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.alertSummary]);
  },
  pageDetails: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.pageDetails]);
  },
  pageDetailsDeepLink: (auditId: number, runId: number, pageId: string) => {
    return AuditReportContainerUrlBuilders.base(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.pageDetailsDeepLink], pageId);
  },
  pageSummary: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.pageSummary]);
  },
  tagInventory: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.tagInventory]);
  },
  variableInventory: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.variableInventory]);
  },
  tagHealth: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.tagHealth]);
  },
  cookieInventory: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.cookieInventory]);
  },
  duplicatesAndMultiples: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.duplicatesAndMultiples]);
  },
  ruleSummary: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.ruleSummary]);
  },
  privacyTags: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.privacyTags]);
  },
  privacyCookies: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.privacyCookies]);
  },
  privacyRequests: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.privacyRequests]);
  },
  browserConsoleLogs: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.browserConsoleLogs]);
  },
  privacyFileChanges: (auditId: number, runId: number) => {
    return baseUrl(auditId, runId) + RouteBuilder.buildUrl([AuditReportPaths.privacyFileChanges]);
  },
};

export enum EPageUrlFilterType {
  Contains = 'contains',
  Regex = 'regex',
  Negate = 'negate'
}

export enum EPageStatusCodeFilterType {
  Specific = 'specific',
  Range = 'range'
}

export enum EPageTitleFilterType {
  Contains = 'contains',
  NotContains = 'not_contains'
}

export enum EConfiguredUrlsFilterType {
  Contains = 'contains',
  NotContains = 'not_contains'
}

export enum ECookieDomainFilterType {
  Contains = 'contains',
  NotContains = 'not_contains'
}

export enum EStatusCodeCategories {
  Successes = 'good',
  Specific = 'specific',
  Redirects = 'redirects',
  Broken = 'broken'
}

export enum ELogLevels {
  Error = 'error',
  Warning = 'warn',
  Info = 'info',
  Debug = 'debug',
  Other = 'other',
}

export enum EConsentCategoryComplianceStatus {
  Unique = 'unique',
  Approved = 'approved',
  Unapproved = 'unapproved',
}

export enum EChartColor {
  Red = 'chart-red',
  Orange = 'chart-orange',
  Yellow = 'chart-yellow',
  OPYellow = 'chart-op-yellow',
  Green = 'chart-green',
  Blue = 'chart-blue',
  Gray = 'chart-gray',
  Purple = 'chart-purple',
  TransparentRed = 'chart-red-transparent',
  TransparentYellow = 'chart-orange-transparent',
  TransparentGreen = 'chart-green-transparent',
}

export enum EChartTooltip {
  Filter = 'Click to filter',
  Clear = 'Click to remove filter'
}

export const PAGE_DETAILS_FULL_SCREEN_KEY = 'pageDetailsFullScreen';

export const TAGS_SEARCH_TEXT_KEY = 'tagsSearchText';

export const REQUEST_LOG_SEARCH_TEXT_KEY = 'requestLogSearchText';
export const REQUEST_LOG_SHOW_FILE_CHANGES_KEY = 'requestLogShowFileChanges';
export const REQUEST_LOG_SHOW_PRE_AUDIT_ACTION_REQUESTS_KEY = 'requestLogShowPreAuditActionRequests';
export const REQUEST_LOG_SORT_BY_KEY = 'requestLogSortBy';
export const REQUEST_LOG_SORT_DESC_KEY = 'requestLogSortDesc';
export const REQUEST_LOG_PAGE_INDEX_KEY = 'requestLogPageIndex';

export const COOKIES_SEARCH_TEXT_KEY = 'cookiesSearchText';
export const COOKIES_SHOW_PRE_AUDIT_ACTIONS = 'cookiesShowPreAuditActions';

export const PRIVACY_COOKIES_SEARCH_TEXT_KEY = 'privacyCookiesSearchText';

export const BROWSER_CONSOLE_LOGS_SEARCH_TEXT_KEY = 'browserConsoleLogsSearchText';

export const formatPaginator = (page: number, pageSize: number, length: number, decimalPipe: DecimalPipe) => {
  const start = length === 0
    ? length
    : decimalPipe.transform(page * pageSize + 1);

  const end = length < pageSize
    ? decimalPipe.transform(length)
    : ((page + 1) * pageSize) > length
      ? decimalPipe.transform(length)
      : (page + 1) * pageSize;

  return `${start} - ${end} of ${decimalPipe.transform(length)}`;
};

export const statusCodeCategoryToRangeMap = new Map<EStatusCodeCategories, IPageStatusCodeFilter>([
  [EStatusCodeCategories.Successes, { minInclusive: 200, maxExclusive: 300, negated: false, filterType: EPageStatusCodeFilterType.Range }],
  [EStatusCodeCategories.Redirects, { minInclusive: 300, maxExclusive: 400, negated: false, filterType: EPageStatusCodeFilterType.Range }],
  [EStatusCodeCategories.Broken, { minInclusive: 200, maxExclusive: 400, negated: true, filterType: EPageStatusCodeFilterType.Range }]
]);

// Report configs
export const reportTypeToFilterTypes = new Map([
  [EAuditReportType.AuditSummary, AuditSummaryRelevantFilters],
  [EAuditReportType.PageSummary, PageSummaryRelevantFilters],
  [EAuditReportType.RuleSummary, RuleSummaryRelevantFilters],
  [EAuditReportType.TagInventory, TagInventoryRelevantFilters],
  [EAuditReportType.VariableInventory, VariableInventoryRelevantFilters],
  [EAuditReportType.TagHealth, TagHealthRelevantFilters],
  [EAuditReportType.DuplicatesAndMultiples, TagDuplicatesAndMultiplesRelevantFilters],
  [EAuditReportType.CookieInventory, CookieInventoryRelevantFilters],
  [EAuditReportType.BrowserConsoleLogs, BrowserConsoleLogsRelevantFilters],
  [EAuditReportType.PrivacyCookies, CookiesPrivacyRelevantFilters],
  [EAuditReportType.PrivacyTags, TagPrivacyRelevantFilters],
  [EAuditReportType.PrivacyRequests, RequestsPrivacyRelevantFilters],
  [EAuditReportType.JsFileChanges, JsFileChangesRelevantFilters],
]);

export const PageLoadWidgetTooltip = 'The page load time average reports the Google Chrome “Load” metric which is visible when developer tools is opened in your browser. Configured audit actions or changes in proxy location can increase the average load time.';
export const PageLoadColumnTooltip = 'The page load time reports the Google Chrome “Load” metric which is visible when developer tools is opened in your browser. Configured audit actions or changes in proxy location can increase the load time.';
