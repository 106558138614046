import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms";

@Injectable()
export class RuleSetupFormUtilsService {

  markGroupedControlsTouched(groupedControls: UntypedFormGroup | UntypedFormArray) {
    Object.values(groupedControls.controls).forEach(control => {
      control.markAsTouched();

      if (this.isFormGroup(control) || this.isFormArray(control)) {
        this.markGroupedControlsTouched(control);
      }
    });
  }

  isFormGroup(abstractControl: AbstractControl): abstractControl is UntypedFormGroup {
    return (typeof (abstractControl as UntypedFormGroup).controls === 'object') && !this.isFormArray(abstractControl);
  }

  isFormArray(abstractControl: AbstractControl): abstractControl is UntypedFormArray {
    return Array.isArray((abstractControl as UntypedFormArray).controls);
  }

}