import { Pipe, PipeTransform } from '@angular/core';
import { STEP_TYPE_TO_LABEL_MAP } from '@app/components/audit/audit.constants';
import {
  INavActionDetails,
  IBaseActionDetails,
  IInputActionDetails, IWatchActionDetails
} from '@app/components/actions/action-details/action-details.models';
import { EActionTypeV3 } from '@app/components/web-journey/web-journey.models';

@Pipe({
  name: 'actionPreviewInfo'
})
export class ActionPreviewInfoPipe implements PipeTransform {

  transform(action: IBaseActionDetails): string {
    const actionLabel = STEP_TYPE_TO_LABEL_MAP.get(action.type);
    const value = this.getActionValue(action);
    return actionLabel + (value ? ` - ${value}` : '');
  }

  private getActionValue(action: IBaseActionDetails): string {
    switch (action.type) {
      case EActionTypeV3.NavTo:
        return (action as INavActionDetails).url;
      case EActionTypeV3.Input:
      case EActionTypeV3.Select:
        return (action as IInputActionDetails).value;
      case EActionTypeV3.Watch:
        return (action as IWatchActionDetails).seconds && (action as IWatchActionDetails).seconds.toString();
      default:
        return null;
    }
  }

}
