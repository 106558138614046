import {
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { PreviewItemDirective } from './preview-list.directive';

@Component({
  selector: 'op-preview-list',
  templateUrl: './preview-list.component.html',
  styleUrls: ['./preview-list.component.scss'],
})
export class PreviewListComponent implements OnInit {
  @Input() items: any[] = [];
  @Input() maxPreview: number = 5;

  // because rendering is so unpredictable passing this is required
  @Input() itemWidth: number;

  @ContentChild(PreviewItemDirective, { read: TemplateRef })
  previewItemTemplate;

  @ViewChild('list') list;
  @ViewChild('more', { static: true }) more;

  show: number = 0;
  notDisplayed: number = 0;
  expanded: boolean = false;
  delayedCalculationCount = 0;
  readonly itemPadding = 2;

  constructor(private elem: ElementRef) {}

  ngOnInit() {
    this.calculatePreview();
  }

  private calculatePreview() {
    this.show = this.calcItemsToShow();
    this.notDisplayed = this.items.length - this.show;
  }

  toggleExpandPreview() {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.show = this.items.length;
      this.notDisplayed = 0;
    } else {
      this.show = this.calcItemsToShow();
      this.notDisplayed = this.items.length - this.show;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.expanded) {
      this.calculatePreview();
    }
  }

  private calcItemsToShow() {
    const previewWidth =
      this.elem.nativeElement.clientWidth - this.more.nativeElement.clientWidth;

    if (previewWidth === 0 && this.delayedCalculationCount < 1) {
      /*
       * handles edge case where this component is initialized before its
       * parent has any width. Waiting one more turn of event loop to ensure
       * width really is 0.
      */
      this.delayedCalculationCount++;
      setTimeout(this.calculatePreview.bind(this));
      return;
    }

    const possibleToShow = Math.trunc(
      previewWidth / (this.itemWidth + this.itemPadding * 2)
    );
    const normalizedMaxPreview =
      this.items.length >= this.maxPreview
        ? this.maxPreview
        : this.items.length;

    if (possibleToShow <= normalizedMaxPreview) {
      return possibleToShow < 1 ? 1 : possibleToShow;
    } else {
      return possibleToShow - (possibleToShow - normalizedMaxPreview);
    }
  }
}
