import {
  ChartClassName,
  ChartType, EChartType,
  IChartsConfig
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import * as d3 from 'd3';
import { NumberValue } from 'd3';
import { attachRectToSvg, hide } from '@app/components/shared/components/viz/utils/d3-chart.utils';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import {
  createCircle,
  createTooltip,
  drawTooltip
} from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';
import { IUsageTrendsDTO } from '@app/components/usage-v2/usage-v2.models';
import { UsageChartComponent } from '@app/components/usage-v2/components/usage-chart/usage-chart.component';
import { DecimalPipe } from '@angular/common';

const actionTextVariantsA = {
  [EChartType.AUDIT]: 'Pages Scanned',
  [EChartType.WEB_JOURNEY]: 'Journey Runs',
};

const actionTextVariantsB = {
  [EChartType.AUDIT]: 'scan this many pages',
  [EChartType.WEB_JOURNEY]: 'run this many journeys',
};

const generateTooltipText = (value: number, actionTextA: string, actionTextB: string): string => {
  return '<div class="usage-chart-tooltip-body">' +
    '<h3 class="title">MONTHLY PACING</h3>' +
    '<div class="value">' +
    '<div class="value-data monthly-pacing big-font">' + new DecimalPipe('en-US').transform(value) + '</div>' +
    `<div class="value-label-thin">${actionTextA} per Month</div>` +
    '</div>' +
    '<div class="value">' +
    `<div class="value-label">To use your purchased volume, ${actionTextB} per month</div>` +
    '</div>' +
    '</div>';
};

export function addMonthlyPacingChartToConfig(trends: IUsageTrendsDTO, config: IChartsConfig, chartType: EChartType): void {
  if (!trends.monthlyPacing) {
    return;
  }
  config.charts[ChartType.monthlyPacing] = {
    data: trends.monthlyPacing,
    chartClassName: ChartClassName.monthlyPacing,
    toggleBtn: {
      className: UsageChartComponent.getChartToggleBtnClassName(ChartClassName.monthlyPacing),
      text: 'Monthly Pacing',
      tooltipText: generateTooltipText(trends.monthlyPacing, actionTextVariantsA[chartType], actionTextVariantsB[chartType]),
      chartClassName: ChartClassName.monthlyPacing,
      showAlways: true,
    },
  };
}

export function drawMonthlyPacingChart(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
  barChartPadding: number,
  overlayLineStroke: number,
  windowWidth: number,
  circleClass: string,
  circleRadius: number,
  tooltipClass: string,
  chartType: EChartType,
): void {
  const monthlyPacing = config.charts[ChartType.monthlyPacing];

  if (!monthlyPacing) {
    return;
  }

  const lineY = yAxis(monthlyPacing.data as NumberValue);

  if (!lineY && typeof lineY !== 'number') {
    return;
  }

  const lineWidth = xAxis.range()[1];
  const lineHeight = 2;

  attachRectToSvg({
    groupClassName: monthlyPacing.chartClassName,
    className: 'purple-line',
    lineX: barChartPadding,
    lineY,
    lineWidth,
    lineHeight,
  }, svg);

  attachRectToSvg({
    groupClassName: monthlyPacing.chartClassName,
    className: 'transparent-line',
    lineX: barChartPadding,
    lineY: lineY - overlayLineStroke / 2,
    lineWidth,
    lineHeight: overlayLineStroke,
  }, svg)
    .on('mousemove', (e) => {
      const circle = createCircle(svg, circleClass, circleRadius);
      const tooltip = createTooltip(tooltipClass);
      circle.attr('class', `${circleClass} purple-circle`);
      // Make tooltip width constant for edge cases
      tooltip.attr('class', `${tooltipClass} fixed-width-230`);

      drawTooltip(
        svg,
        generateTooltipText(monthlyPacing.data as number, actionTextVariantsA[chartType], actionTextVariantsB[chartType]),
        e.clientX,
        lineY,
        circle,
        tooltip,
        windowWidth,
        true,
      );
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
      // Reset tooltip class to default, because tooltip is a global reusable element
      d3.select(`.${tooltipClass}`).attr('class', tooltipClass);
    });
}
