import * as angular from 'angular';

import { WebsocketRouter } from './websocketRouter';

export interface IWebsocketService {
  subscribe(topic: string, identifier: any): angular.IPromise<Subscription>;
  unsubscribe(topic: string, identifier: any): IWebsocketService;
  send<T>(topic: string, data: T): angular.IPromise<any>;
  close(force: boolean): IWebsocketService;

  onOpen(callback: (event: Event) => void): IWebsocketService;
  onClose(callback: (event: CloseEvent) => void): IWebsocketService;
  onError(callback: (event: Event) => void): IWebsocketService;
}

class SocketMessage {
  uuid: string;
  topic: string;
  data: any;
  identifier: any;
  command: WebsocketCommand = WebsocketCommand.send;
  metadata: any;
  constructor(uuid: string, topic: string, data: any, identifier: any) {
    this.uuid = uuid;
    this.topic = topic;
    this.data = data;
    this.identifier = identifier;
  }
}

export interface IServerSocketMessage {
  uuid: string;
  topic: string;
  metadata: any;
  identifier: any;
  data: any;
}

export type WebsocketCommand = 'subscribe' | 'unsubscribe' | 'send';

export const WebsocketCommand = {
  subscribe: 'subscribe' as WebsocketCommand,
  unsubscribe: 'unsubscribe' as WebsocketCommand,
  send: 'send' as WebsocketCommand
};

export class Subscription {
  private id: number;

  private readonly topic: string;
  private readonly identifier: any;

  constructor(topic: string, identifier: any) {
    this.topic = topic;
    this.identifier = identifier;
  }

  /**
   * @return {number} identifier of registered callback
   */
  public on(callback: (update: any) => void): number {
    if (this.isCallbackRegistered()) {
      throw new Error(
        `Subscription(${this.topic}, ${
          this.identifier
        }) already has a registered callback #${this.id}`
      );
    }
    return (this.id = WebsocketRouter.register(
      this.topic,
      this.identifier,
      (data: { object?: any; obj?: any }) =>
        callback(!!data.object ? data.object : !!data.obj ? data.obj : data)
    ));
  }

  public close(ws: IWebsocketService): void {
    if (this.isCallbackRegistered()) {
      const countOfRemainingCallbacks = WebsocketRouter.unregister(
        this.topic,
        this.identifier,
        this.id
      );
      if (countOfRemainingCallbacks == 0) {
        ws.unsubscribe(this.topic, this.identifier);
      }
    }
  }

  public isCallbackRegistered(): boolean {
    return !!this.id;
  }
}

export class WebSocketService implements IWebsocketService {
  private conn: angular.websocket.IWebSocket;
  private heartbeat;

  private HEARTBEAT_TIMEOUT = 30000;

  constructor(websocketConn: ng.websocket.IWebSocket) {
    this.conn = websocketConn;
    this.handleMessages();
    this.startHeartbeat();
  }

  public subscribe(
    topic: string,
    identifier: any
  ): angular.IPromise<Subscription> {
    return this.send(topic, null, WebsocketCommand.subscribe, identifier).then(
      () => {
        return new Subscription(topic, identifier);
      }
    );
  }

  public unsubscribe(topic: string, identifier: any): IWebsocketService {
    this.send(topic, null, WebsocketCommand.unsubscribe, identifier);
    return this;
  }

  public send<T>(
    topic: string,
    data: T,
    command?: WebsocketCommand,
    identifier?: any
  ): angular.IPromise<any> {
    var msg: SocketMessage = new SocketMessage(
      this.generateUUID(),
      topic,
      data,
      identifier
    );
    msg.command = command ? command : WebsocketCommand.send;
    return this.conn.send(JSON.stringify(msg));
  }

  public close(force: boolean): IWebsocketService {
    this.conn.close();
    this.stopHeartbeat();
    return this;
  }

  public onOpen(callback: (event: Event) => void): IWebsocketService {
    this.conn.onOpen(callback);
    return this;
  }

  public onClose(callback: (event: CloseEvent) => void): IWebsocketService {
    this.conn.onClose(callback);
    return this;
  }

  public onError(callback: (event: CloseEvent) => void): IWebsocketService {
    this.conn.onError(callback);
    return this;
  }

  private handleMessages(): void {
    this.conn.onMessage(function(message: MessageEvent) {
      if (!message) return;
      var msg: IServerSocketMessage = JSON.parse(message.data);
      WebsocketRouter.handleMessages(msg.topic, msg.identifier, msg.data);
    });
  }

  private generateUUID(): string {
    return (
      this.s4() +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      this.s4() +
      this.s4()
    );
  }
  private s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  private startHeartbeat(): void {
    this.heartbeat = setInterval(() => {
      this.heartbeatConnection();
    }, this.HEARTBEAT_TIMEOUT);
  }

  private heartbeatConnection() {
    this.send('ping', null);
  }

  private stopHeartbeat(): void {
    clearInterval(this.heartbeat);
  }
}
