<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header
      title="Reprocess Comparisons"
      (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  
  <ng-template #bodyTemplate>
    <div class="body-container">
      <div *ngIf="!comparisons">
        <div class="title-message">
          Loading applied comparisons ...
        </div>
      </div>
      <div *ngIf="comparisons && !comparisons.length">
        <div class="title-message">
          There are currently no comparisons applied to your audit configuration.
        </div>
      </div>
      <div *ngIf="comparisons && comparisons.length">
        <div class="title-message">
          The following comparison(s) will be applied to the
          <span class="bold">currently viewed run</span>
          of this audit:
        </div>
        <div class="comparisons-wrp">
          <div class="comparison" *ngFor="let comparison of comparisons">
            {{ comparison.name }}
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</op-modal>
