import { IAccountTagSettings } from '@app/components/comparisons/comparisons.models';
import { IDatasourceTag } from '@app/components/comparison-library/comparison-library.model';
import { EDatasourceTagType } from '../../comparisons.enums';

export interface IComparisonVariableSelectorForm {
  tagId: number;
  type: EDatasourceTagType;
  selectedVariables?: string[];
}

export class IComparisonVariableSelectorForm {

  static toDatasourceTag(tag: IComparisonVariableSelectorForm, 
                         accountTagSettings: Map<number, IAccountTagSettings>): IDatasourceTag {
    const datasource: IDatasourceTag = {
      tagId: tag.tagId,
      type: tag.type
    };
    if (tag.type === EDatasourceTagType.SelectedVariables) datasource.selectedVariables = tag.selectedVariables;
    if (tag.type === EDatasourceTagType.AllVariablesExceptExcluded) {
      const exludedAccountVars = accountTagSettings.get(tag.tagId).excludedVariables;
      datasource.removedExclusions = exludedAccountVars.filter(v => !tag.selectedVariables.includes(v));
      datasource.addedExclusions = tag.selectedVariables.filter(v => !exludedAccountVars.includes(v));
    }
    return datasource;
  }

}
