export function getPercent(a: number, b: number): number {
  return Math.round(a / b * 100);
}

export function randomIntIncluding(min: number, max: number) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function bytesToKB(bytes: number, decimals = 1) {
  return Number((bytes / 1024).toFixed(decimals));
}

export function bytesToMB(bytes: number, decimals = 1) {
  return Number((bytes / 1024 / 1024).toFixed(decimals));
}

export function mbToBytes(bytes: string | number) {
  return Number((+bytes * 1024 * 1024).toFixed());
}

export function isNumber(str: string | number) {
  return /^\d+(:?.\d+)?$/.test(str.toString());
}
export function roundTo(value: number, places: number) {
  return +(Math.round(+(value + 'e+' + places) as number)  + 'e-' + places);
}

export function trimDecimals(num: number | undefined | null, maxFractionDigits = 3): string {
  // Check if num is undefined, null, or NaN
  if (num === undefined || num === null || isNaN(num)) {
    return '0';
  }

  // Convert the number to a string
  let str = Number(num).toFixed(maxFractionDigits);

  // Check if there's a decimal point
  if (str.includes('.')) {
    // Remove trailing zeros
    str = str.replace(/0+$/, '');

    // If there's a decimal point left at the end, remove it
    str = str.replace(/\.$/, '');
  }

  return str;
}

export function convertTimeWithPrecision(value: number, divider: number = 1, decimalsCount: number = 1): number {
  return +(value / divider).toFixed(decimalsCount);
}

export function getPercentageAofB(a: number, b: number, rounded = true): number {
  // Handling edge case not to divide by 0
  if (b === 0) {
    return 0;
  }

  // Fixing the floating point precision issue
  const percentage = parseFloat(((a / b) * 100).toFixed(2));

  return rounded ? Math.round(percentage) : percentage;
}

export function getPercentageOfTotal(a: number, b: number): { aOfTotal: number, bOfTotal: number } {
  // Getting the exact percentage without rounding
  // So that we can round those correctly as complementary percentages
  let aOfTotal = getPercentageAofB(a, a + b, false);
  let bOfTotal = getPercentageAofB(b, a + b, false);

  if ( aOfTotal > 0 && aOfTotal < 1) {
    return { aOfTotal: 1, bOfTotal: 99 };
  }

  if (aOfTotal > 99 && aOfTotal < 100) {
    return { aOfTotal: 99, bOfTotal: 1 };
  }

  [aOfTotal, bOfTotal] = fixComplementaryPercentages(aOfTotal, bOfTotal);

  return { aOfTotal, bOfTotal };
}

// Fixes percentages when the sum of the rounded values is greater than 100
export function fixComplementaryPercentages(a: number, b: number): [number, number] {
  const MAX_HANDLED_PERCENTAGE = 100;

  // If the sum exceeds MAX_HANDLED_PERCENTAGE just return the passed in values
  // Cause this fn does only handle complementary percentages which are a + b = 100
  if (a + b > MAX_HANDLED_PERCENTAGE) {
    return [a, b];
  }

  let roundedA = Math.round(a);
  let roundedB = Math.round(b);

  if (roundedA + roundedB > 100) {
    const diffA = roundedA - a;
    const diffB = roundedB - b;

    if (diffA === diffB && a === b) {
      // This case means that we received two same numbers
      roundedA--;
      roundedB--;
    } else if (diffA > diffB) {
      roundedA--; // Decrease A if it is closer to the next integer
    } else {
      roundedB--; // Otherwise decrease B
    }
  }
  return [roundedA, roundedB];
}
