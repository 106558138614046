import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { IFoldersApiService, IFolder } from '@app/components/folder/foldersApiService';
import { IUser } from '@app/moonbeamModels';
import { CreateEditDomainComponent } from './create-edit-domain/create-edit-domain.component';
import { EManageDomainsMode } from './create-edit-domain/create-edit-domain.constants';
import { ITableDomain } from './manage-domains.models';
import { catchError, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { ServerErrorCodes } from '@app/moonbeamConstants';
import { ModalType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import { TerminateActiveRunsModalService } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';
import { AccountsService } from '@app/components/account/account.service';
import { OpDeleteItemWarningComponent } from '@app/components/shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { BulkActionProgressComponent } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.component';
import { BulkActionProgressService } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.service';
import { ManageCardsService } from '@app/components/manage/cards/manage-cards.service';
import { BulkActionBarComponent } from '@app/components/manage/cards/bulk-action-bar/bulk-action-bar.component';
import { BulkOperationsDeleteMessaging } from '../bulk-operations.const';

/** DOMAINS ARE NOW CALLED SUBFOLDERS
 *   - we have not updated code references or component names to reflect this renaming
 *   - user facing strings should use the new nomenclature
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-domains',
  templateUrl: './manage-domains.component.html',
  styleUrls: ['./manage-domains.component.scss'],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions },
    ManageCardsService, BulkActionBarComponent,
  ]
})
export class ManageDomainsComponent implements OnInit {
  displayedColumns: string[] = ['select', 'name', 'folderName', 'dataLayer', 'createdBy', 'createdDate', 'id', 'editDelete'];
  dataSource = new MatTableDataSource;
  selection = new SelectionModel(true, []);
  folders: IFolder[];
  domains: IDomain[];
  users: IUser[];
  deleteButtonDisabled: boolean = true;
  editButtonDisabled: boolean = true;
  loading: boolean = true;
  user$: Observable<IUser>;
  duration: number = 5000;
  processCompleted = false;

  @ViewChild(MatSort) sort: MatSort;

  readonly maxParallel: number = 1;

  constructor(
    private domainService: IDomainsService,
    private folderService: IFoldersApiService,
    private accountsService: AccountsService,
    private modalService: OpModalService,
    private terminateActiveRunsModalService: TerminateActiveRunsModalService,
    private dateService: DateService,
    private snackBar: MatSnackBar,
    private bulkActionProgressService: BulkActionProgressService,
    private bulkActionBar: BulkActionBarComponent,
    private manageCardsService: ManageCardsService,
  ) { }

  ngOnInit(): void {
    forkJoin([
      this.domainService.getAllDomains(),
      this.folderService.getFolders(),
      this.accountsService.getUsers()
    ]).subscribe(([domains, folders, users]) => {
      this.users = users;
      this.folders = folders;
      this.domains = domains;
      this.initDataSource(domains);
    });

    this.user$ = this.accountsService.getUser().pipe(shareReplay());
    this.user$.subscribe();
  }

  initDataSource(domains: IDomain[]): void {
    this.dataSource.data = domains.map((domain: IDomain) => {
      let folder = this.getFolder(domain.folderId);

      return {
        name: domain.name,
        domain: domain.domain,
        createdBy: this.getUsername(domain.userId),
        createdDate: this.dateService.formatDate(new Date(domain.createdAt), EDateFormats.dateTwentyThree),
        folderName: folder.name,
        folder: folder,
        dataLayer: domain.dataLayer,
        id: domain.id
      };
    }).sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, attribute) => {
      if (typeof data[attribute] === 'number') {
        return data[attribute];
      }

      return data[attribute]?.toLowerCase();
    };
    this.loading = false;
  }

  getUsername(userId: number): string {
    let user = this.users.filter(user => user.id === userId);
    return user.length ? user[0].username : '';
  }

  getFolder(folderId: number): IFolder {
    let folder = this.folders.filter(folder => folder.id === folderId);
    return folder.length ? folder[0] : {} as IFolder;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isAllFilteredSelected(): boolean {
    return <boolean> this.dataSource.filteredData.reduce(
      (allSelected: boolean, row) => allSelected && this.selection.isSelected(row),
      true
    );
  }

  masterToggle(): void {
    if (this.dataSource.filter) {
      this.isAllFilteredSelected() ?
        this.dataSource.filteredData.forEach(row => this.selection.deselect(row)) :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    } else {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onCheckboxChecked(event: MouseEvent): void {
    event.stopPropagation();
    this.updateButtonStatus();
  }

  onCreateDomain(): void {
    this.modalService.openModal(CreateEditDomainComponent, {
      data: { domains: [], folders: this.folders, mode: EManageDomainsMode.Create }
    })
    .afterClosed()
    .subscribe((newDomain: ITableDomain) => {
      if (newDomain) {
        newDomain = {
          ...newDomain,
          createdBy: this.getUsername(newDomain.createdBy as number)
        };

        this.dataSource.data.push(newDomain);
        this.dataSource.data.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        // forces table to update
        this.dataSource.data = this.dataSource.data;
      }
    });
  }

  onEditDomain(event: MouseEvent, domain: ITableDomain): void {
    event.stopPropagation();
    this.modalService.openModal(CreateEditDomainComponent, {
      data: { domains: [domain], folders: this.folders, mode: EManageDomainsMode.Edit }
    })
    .afterClosed()
    .subscribe((updatedDomain: ITableDomain) => {
      if (updatedDomain) {
        domain.name = updatedDomain.name;
        domain.domain = updatedDomain.domain;
        domain.folderName = updatedDomain.folderName;
        domain.folder = updatedDomain.folder;
        domain.dataLayer = updatedDomain.dataLayer;
      }
    });
  }

  onEditMultiDomains(): void {
    this.modalService.openModal(CreateEditDomainComponent, {
      data: { domains: this.selection.selected, folders: this.folders, mode: EManageDomainsMode.Edit }
    })
    .afterClosed()
    .subscribe((updatedDomains: ITableDomain[]) => {
      if (updatedDomains) {
        if (!(updatedDomains instanceof Array)) updatedDomains = [updatedDomains];

        this.selection.selected.forEach((domain: ITableDomain, index: number) => {
          domain.name = updatedDomains[index].name;
          domain.domain = updatedDomains[index].domain;
          domain.folderName = updatedDomains[index].folderName;
          domain.folder = updatedDomains[index].folder;
          domain.dataLayer = updatedDomains[index].dataLayer;
        });
        this.selection.clear();
        this.updateButtonStatus();
        this.dataSource.data.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
        // forces table to update
        this.dataSource.data = this.dataSource.data;
      }
    });
  }

  onDeleteDomain(event: MouseEvent, domain: ITableDomain): void {
    event.stopPropagation();

    this.modalService.openModal(OpDeleteItemWarningComponent, {
      data: {
        name: domain.name,
        itemType: 'Sub-folder',
        customMessage: 'This is irreversible and any contents of the sub-folder (web audits and web journeys) will also be deleted.'
      }
    })
    .afterClosed()
    .subscribe((confirmDelete: boolean) => {
      if (confirmDelete) {
        this.deleteDomain(domain);
      }
    });
  }

  deleteDomain(domain: ITableDomain): void {
    // perform optimistic update
    const index = this.dataSource.data.indexOf(domain);
    this.dataSource.data.splice(index, 1);
    // forces table to update
    this.dataSource.data = this.dataSource.data;

    this.domainService.removeDomain(domain.id).catch(error => {
      if (error.errorCode === ServerErrorCodes.alreadyRunning) {
        this.terminateActiveRunsModalService.showTerminateActiveRunsModal(ModalType.Domain, error.items);
      }

      // put deleted row back if error
      this.dataSource.data.splice(index, 0);
      // forces table to update
      this.dataSource.data = this.dataSource.data;
    });
  }

  // Delete multiple sub-folders (domains)
  //  If user chooses to move items first (before delete), then nothing happens below after the dialog is closed
  onDeleteMultiSubfolders(): void {

    // Disable the delete button while loading dialogbox
    this.deleteButtonDisabled = true;
    setTimeout(() => { this.deleteButtonDisabled = false; }, 2500);

    const Subfolders = this.selection.selected;
    const numSubfolders = Subfolders.length;
    const message = `Are you sure you want to permanently delete the ${numSubfolders > 1 ? numSubfolders : ''} sub-folder${numSubfolders > 1 ? 's' : ''} as well as all items in the sub-folder${numSubfolders > 1 ? 's' : ''}? `;

    // get audit and journey counts for all selected folders to be deleted
    let auditCounts: number = 0;
    let journeyCounts: number = 0;
    this.manageCardsService.getAuditJourneyCountsBySubfolder(Subfolders, 'subfolders').then((subFolderInfo: number[]) => {
        auditCounts += subFolderInfo[0];
        journeyCounts += subFolderInfo[1];

      this.modalService.openModal(OpDeleteItemWarningComponent, {
        data: {
          itemType: 'Sub-folders',
          customMessage: message,
          defaultMessage: false,
          Folders: Subfolders,
          firstDomainID: subFolderInfo[2],
          haveCounts: true,
          FoldersCount: 0,
          SubfoldersCount: numSubfolders,
          AuditsCount: auditCounts,
          JourneysCount: journeyCounts,
          sourcePageTab: 'manage-domains',
        }
      })
      .afterClosed()
      .subscribe((confirmDelete: string) => {

        // Handle moving items
        if (confirmDelete === 'move') {

          ////////////////////////////////
          // Move items then delete them
          this.handleMoveAndDeleteResponse(Subfolders).then(confirmDelete => {

            if(BulkOperationsDeleteMessaging.Success !== confirmDelete) {
              let respMessage = "";
              if (BulkOperationsDeleteMessaging.Cancel === confirmDelete) {
                // Reload the first dialog box after move-cancel as if we hit the back-button.
                this.onDeleteMultiSubfolders();
                return;
              } else if (BulkOperationsDeleteMessaging.MoveFailure === confirmDelete) {
                respMessage = 'The move request did not succeed for some items';
              } else if (BulkOperationsDeleteMessaging.DeleteFailure === confirmDelete) {
                respMessage = 'The delete request did not succeed for some items';
              } else if(BulkOperationsDeleteMessaging.MoveToSameFolder === confirmDelete) {
                respMessage = 'The move request was cancelled because both source and destination subfolders were the same';
              } else {
                respMessage = 'Unknown response from processing. Please verify your request was completed.';
              }
              this.snackBar.open(respMessage, "", {
                duration: this.duration,
                horizontalPosition: "center",
                verticalPosition: "top",
              });
            } else {
              // On successful move, re-init the table - show new folders that may have been created during move
              this.ngOnInit();
              this.selection.clear();
            }
          });

        // Handle deleting without moving
        } else if (confirmDelete === 'delete') {

          //////////////////////////////////////////////
          // Show progress-bar and start deleting items
          this.showProgressDlg(Subfolders.length);
          this.deleteMultiSubfolders(Subfolders);

        } else {
          this.snackBar.open("Delete was cancelled. No items removed.", "", {
            duration: this.duration,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      });
    });
  }

  async handleMoveAndDeleteResponse(subfolders: any[]): Promise<number> {

    // Move items first - we must know the move was successful before deleting
    const res = await this.moveToSubfolderConfirmation(subfolders);
    if((res !== BulkOperationsDeleteMessaging.Success) && (res !== BulkOperationsDeleteMessaging.NoItemsToMove)) {
      return res;
    }

    // Ensure move is completed in the database (re-query the db to see if any items are still there)
    let filteredCards: any[];
    let loop: number = 1;

    do {
      await this.waitForMoveToComplete(800);
      filteredCards = await this.buildCards();
    }
    while((filteredCards.length > 0) && (loop++ < 3))

    // Let's abort the delete if items are still pending a move to new folder/subfolder
    if(filteredCards.length > 0) {
      return BulkOperationsDeleteMessaging.MoveFailure;
    }

    // Delete subfolders after moving items
    this.showProgressDlg(subfolders.length);
    await this.deleteMultiSubfolders(subfolders);

    return BulkOperationsDeleteMessaging.Success;
  }

  // Delete multiple sub-folders
  async deleteMultiSubfolders(subfolders: any[]): Promise<any> {

    let runningItems = [];
    const failures = [];
    let processedCount = 0;
    const result = await from(subfolders).pipe(
        mergeMap(subfolder => {
          return fromPromise(this.domainService.removeDomain(subfolder.id)
              .then(success => {
                let index = this.dataSource.data.indexOf(subfolder);
                this.dataSource.data.splice(index, 1);
              }))
              .pipe(
                mergeMap(result => of({ subfolder, success: true })),
                catchError(error => {
                  console.log(error);
                  if (error.errorCode === ServerErrorCodes.alreadyRunning) {
                    error.items.forEach(item => runningItems.push(item));
                  }
                  return of({ subfolder, success: false});
                })
              )
        }, this.maxParallel),
        tap(result => {
          // record failures for informing user
          if (!result.success) {
            failures.push(result.subfolder);
          }

          this.bulkActionProgressService.publishProgressbar(++processedCount);
        })

      ).toPromise().then(() => {
        // forces table to update
        this.dataSource.data = this.dataSource.data;
        this.selection.clear();
        this.updateButtonStatus();
        if (runningItems.length > 0) {
          this.terminateActiveRunsModalService.showTerminateActiveRunsModal(ModalType.Domains, runningItems);

          // Close the progress dialog if we are terminating runs
          this.snackBar.dismiss();
        }
      });
  }

  async moveToSubfolderConfirmation(subfolders: any[]): Promise<number> {

    // If there are no items to move then return and start deleting
    let filteredCards = await this.buildCards();
    if(filteredCards.length < 1) {
      return BulkOperationsDeleteMessaging.NoItemsToMove;
    }

    //Open the Move dialog box
    this.bulkActionBar.selectedCards = filteredCards;
    const excludeSubfolderIDs: number[] = subfolders.map(({ id }) => id);
    const res = await this.bulkActionBar.moveToFolderConfirmation(null, excludeSubfolderIDs);
    return res;
  }
  async buildCards(): Promise<any[]> {

    let cards = await this.manageCardsService.loadCardData();
    this.manageCardsService.selectedCards = cards;
    let filteredCards = await this.manageCardsService.filterCardsBySubfolder(this.selection.selected, 'subfolders');
    return filteredCards;
  }

  showProgressDlg(itemsCount: number, maxCountOverride?: number): boolean {

    const formattedMessage: string = "Selected subfolders are deleted.";
    const formattedMessage2: string = "Don't close this banner, or leave this page until finished or remaining data sources won't be completed.";
    const finalMessage: string = "All selected subfolders have been deleted.";
    this.processCompleted = false;

    const confirmConfig = {
      maxCount: (maxCountOverride) ? maxCountOverride : itemsCount,
      messages: [
        formattedMessage,
        formattedMessage2,
        finalMessage,
      ],
      showFinalMessage: false,
      showSecondBtn: false,
      showProgressBar: true,
      rightFooterButtons: [
        {
          label: "Yes, delete them",
          opSelector: "bulk-confirm-delete-yes",
          hidden: false,
          primary: false,
        },
        {
          label: "Cancel",
          opSelector: "bulk-confirm-delete-cancel",
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionProgressComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
    .subscribe((observer?: MatSnackBarDismiss) => {
      if(observer.dismissedByAction) {
        this.processCompleted = true;
        return false;
      }
    });

    return true;
  }

  updateButtonStatus(): void {
    setTimeout(() => {
      this.deleteButtonDisabled = !this.selection.hasValue();
      this.editButtonDisabled = !this.selection.hasValue();
    });
  }

  // Without this delay, the delete process deletes the last moved item before the API is done updating the db.
  async waitForMoveToComplete(milli: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, milli);
    });
  }
}
