import { EComparisonType } from '../comparisons/comparisons.enums';
import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const comparisonTypeToLabelMap = new Map<string, string>([
  [ EComparisonType.runOverRun, 'Run Over Run Comparison' ],
  [ EComparisonType.oneTime, 'One Time Comparison' ],
]);

export const ComparisonStateNames = {
  comparison: 'comparison',
  comparisonLibrary: 'comparison.library'
};

export const ComparisonLibraryPaths = {
  base: 'comparison',
  library: 'library'
};

export const ComparisonLibraryUrlBuilders = {
  base: () => RouteBuilder.buildUrl([ComparisonLibraryPaths.base]),
  library: () => RouteBuilder.buildUrl([ComparisonLibraryPaths.base, ComparisonLibraryPaths.library])
};
