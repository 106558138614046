import { Component, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { IDialogInfo, IAbortIt, ISelectedCard } from './bulk-action-progress.models';
import { BulkActionProgressService } from './bulk-action-progress.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject, merge } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bulk-action-progress',
  templateUrl: './bulk-action-progress.component.html',
  styleUrls: ['./bulk-action-progress.component.scss']
})
export class BulkActionProgressComponent implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  firstMessage: string;
  firstMessage_post: string;
  secondMessage: string;
  finalMessage: string = '-- Processing is completed --';
  showFinalMessage: boolean = true;
  itemCounter: number = 1;
  barStepCount: number = 1;
  maxCount: number = 100;
  leftBtnLabel: string;
  rightBtnLabel: string;
  dialog: IDialogInfo;
  abortIt: IAbortIt;
  showFirstBtn: boolean = true;
  showSecondBtn: boolean;
  showSecondMessage: boolean = false;
  showProgressBar: boolean = false;
  itsDone: boolean = false;
  failedItemCount: number = 0;
  failureMessage: string = '';
  serviceError: boolean = false;
  serviceErrorItemName: string = '';
  failedRuns: { audits: ISelectedCard[], journeys: ISelectedCard[] } = { audits: [], journeys: [] };
  retryCounter: number = 0;
  apiRateLimited$: Observable<boolean>;
  records: any[];

  constructor(
    private snackBarRef: MatSnackBarRef<BulkActionProgressComponent>,
    private bulkActionProgressService: BulkActionProgressService,
    private modalService: OpModalService,
    @Inject(MAT_SNACK_BAR_DATA) public msgConfig: IDialogInfo
    ) {
    this.dialog = {
      ...this.msgConfig,
    };

    this.bulkActionProgressService.reInit();
    this.apiRateLimited$ = this.bulkActionProgressService.getApiRateLimited();
    this.maxCount = (this.msgConfig.maxCount) ?? this.msgConfig.maxCount;
    this.bulkActionProgressService.setMaxCount(this.maxCount);
    this.retryCounter = 0;
    this.serviceErrorItemName = '';
    this.serviceError = false;
    this.failureMessage = '';
    this.leftBtnLabel = this.msgConfig.rightFooterButtons[0].label;
    this.rightBtnLabel = this.msgConfig.rightFooterButtons[1].label;
    if (this.dialog.messages) {
      this.firstMessage = this.dialog.messages[0];
      if (this.dialog.messages[1]) {
        this.secondMessage = this.dialog.messages[1];
        this.showSecondMessage = true;
      }

      // if we have want to display a different final message
      if (this.dialog.messages[2]) {
        this.finalMessage = this.dialog.messages[2];
        this.showSecondMessage = true;
      }

      // if we have want to display a different message when the process is done
      if (this.dialog.messages[3]) {
        this.firstMessage_post = this.dialog.messages[3];
      }

      // if we want to hide the final message
      this.showFinalMessage = (this.msgConfig.showFinalMessage !== undefined) ? this.msgConfig.showFinalMessage : true;
    }
    this.showSecondBtn =  (this.msgConfig.showSecondBtn) ? this.msgConfig.showSecondBtn : false;
    this.showProgressBar = (this.msgConfig.showProgressBar) ? this.msgConfig.showProgressBar : false;

    this.records = this.msgConfig.records;

    this.registerSubscriptions();
  }

  registerSubscriptions() {

    // Cancel progressbar if there is an error running bulk operations
    this.bulkActionProgressService.getCancel().subscribe({
      complete: () => {
        this.finishProcessing();
        return;
      }
    });

    // If the server fails then Retry - notify the customer to keep them apprised why the bulk operation is not progressing.
    this.registerRetrySubscription();

    // subscribe to bulk progress and update the progress bar
    this.bulkActionProgressService.subscribeProgressbar().pipe(
        takeUntil(merge(this.destroy$, this.bulkActionProgressService.getCancel()))
      ).subscribe(nextValue => {

        // Reset retryCount if the service call eventually succeeds
      if (!this.bulkActionProgressService.getIsRetrying() && this.retryCounter > 0) {
        this.resetRetry();
      }

      // If an error has not occurred during processing then keep working
      if(!this.bulkActionProgressService.getProcessCompleted()) {
        this.itemCounter = nextValue;
        this.barStepCount = ((nextValue / this.maxCount) * 100);

        // if we are done processing all items
        if (this.itemCounter === this.maxCount) {
          this.finishProcessing();
        }
      }
    });

    this.bulkActionProgressService.setSetupIsReady();
  }

  ngOnDestroy() {
    this.bulkActionProgressService.setProcessCompleted();
    this.destroy$.next();
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  // When we are retrying to connect to service (temporarily down) for a bulk operation
  registerRetrySubscription() {

    this.bulkActionProgressService.getRetry().pipe(
        takeUntil(this.destroy$ && this.bulkActionProgressService.getCancel())
      ).subscribe(nextValue => {

        if(!this.bulkActionProgressService.getProcessCompleted()) {

          // If this is the first time we are retrying then show the Intermitent progressbar and retry messages
          if(this.retryCounter === 0) {
            this.showProgressBar = false;
            this.showSecondMessage = false;
          }
          if(!nextValue) {
            this.resetRetry();
          } else {
            this.retryCounter = nextValue;
          }

        // Else if we have completed the bulk operations
        } else {
          this.resetRetry();
        }
    });
  }
  resetRetry() {
    this.bulkActionProgressService.resetRetry();
    this.retryCounter = 0;
    this.serviceError = false;
    this.showProgressBar = true;
    if (this.secondMessage) {
        this.showSecondMessage = true;
    }
    this.registerRetrySubscription();
  }

  finishProcessing() {

    // if there were failures, then show the failed runs to the user
    if(this.bulkActionProgressService.getFailures().length > 0) {

      // If this is a service error (e.g. http status error) then show the error message
      if (this.bulkActionProgressService.getFailures()[0]?.type === 'service') {
        this.serviceErrorItemName = this.bulkActionProgressService.getFailures()[0]?.card?.name;
        // NOTE: uncomment the following two lines once error messages are curated
        //this.failureMessage = this.bulkActionProgressService.getFailures()[0]?.error?.message?.trim() || '';
        //this.failureMessage = (this.failureMessage?.length > 120) ? this.failureMessage.slice(0, 120) + '...' : this.failureMessage;
        this.firstMessage = '';
        this.showSecondMessage = false;
        this.serviceError = true;

      } else {

        this.bulkActionProgressService.getFailures()?.forEach(failure => {
          if (failure?.card?.type === 'audit') {
            this.failedRuns.audits.push(failure.card);
          }
          if (failure?.card?.type === 'webJourney') {
            this.failedRuns.journeys.push(failure.card);
          }
        });

        // Tell the UI to show errors
        this.failedItemCount = this.bulkActionProgressService.getFailures().length;
     }
      
      this.bulkActionProgressService.resetFailures();
    }

    this.itsDone = true;
    this.retryCounter = 0;
    this.rightBtnLabel = 'Close';
    if(!this.serviceError) {
      this.firstMessage = this.firstMessage_post;
      this.secondMessage = this.finalMessage;
      this.showSecondMessage = this.showFinalMessage;
      if(this.records) {
        this.showSecondBtn =  true;
      }
    }

    // if the user was starting to abort but the process finished, then fix the dialog box
    if (this.abortIt) {
      this.abortIt.abortInProgress = false;
      this.firstMessage = this.abortIt.backupMessage;
      this.showSecondMessage = false;
      this.showSecondBtn =  false;
      this.showFirstBtn =  true;
    }
  }

  areYouSure() {
    if(this.itsDone) {
      this.closeSnackbar();
      return;
    }

    this.abortIt = {
      abortInProgress: true,
      backupMessage: this.firstMessage,
      backupMessage2: this.secondMessage,
    }
    this.showSecondMessage = false;
    this.showSecondBtn =  true;
    this.showFirstBtn =  false;
    this.firstMessage = `<p><b>Are You Sure You Want To Quit?</b></p>
      <p>If you select Yes, all previous items processed up to this point are completed.
      Remaining items are still selected and can be started again if desired.</p>`;
    this.secondMessage = "";
  }

  quitProcess(): void {
    this.itsDone = true;
    this.snackBarRef.dismissWithAction();
  }

  assignAudits() {
    this.itsDone = true;
    this.snackBarRef.dismissWithAction();
  }

  continueProcess(): void {
    //Double check if process is already completed -- user may have waited too long
    if(this.itsDone) {
      this.closeSnackbar();
      return;
    }
    this.abortIt.abortInProgress = false;
    this.firstMessage = this.abortIt.backupMessage;
    this.secondMessage = this.abortIt.backupMessage2;
    this.showSecondMessage = true;
    this.showSecondBtn =  false;
    this.showFirstBtn =  true;
  }

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }
}
