import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { UpgradeUserBannerComponent } from '@app/components/upgrade-user-banner/upgrade-user-banner.component';
import {
  EFreeTrialAdModalType,
  IFreeTrialAdModalPayload
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'free-trial-ad-modal',
  templateUrl: './free-trial-ad-modal.component.html',
  styleUrls: ['./free-trial-ad-modal.component.scss']
})
export class FreeTrialAdModalComponent {
  EFreeTrialAdModalType = EFreeTrialAdModalType;
  title: string;

  @ViewChild(UpgradeUserBannerComponent) upgradeUserBannerComponent: UpgradeUserBannerComponent;

  constructor(
    private dialogRef: MatDialogRef<FreeTrialAdModalComponent>,
    @Inject(MAT_DIALOG_DATA) public payload: IFreeTrialAdModalPayload,
    private authenticationService: AuthenticationService,
  ) {
  }

  goToFreeTrialAd() {
    this.upgradeUserBannerComponent.openUpgradeUserPage();
  }

  goToLoginPage() {
    this.authenticationService.goToLoginPage();
  }

  close() {
    this.dialogRef.close();
  }
}
