import { AccountSettingsUrlBuilders } from './../../../account-settings/account-settings.const';
import { IWJReportParams } from './../../web-journey-report.models';
import { ComponentChanges } from '@app/models/commons';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import {
  IAbsentTagSummary,
  IActionTagVariablesDiffCount,
  ISearchableAbsentTagSummary,
  ISearchableTagSummary,
  ISearchableTagVariableSummary,
  ITagVariablesDiffCount,
  ITagVariablesDiffDetails,
  ITagWithComparisons,
  IWJActionTagSummary,
  IWJComparisonSnapshotTag
} from './wj-results-tag-comparison.models';
import { WjResultsTagReportService } from './wj-results-tag-report.service';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { IActionSelectorAction } from '@app/components/action-selector/action-selector.models';
import { noActionTagsError, noMatchedActionError } from '../tag-comparison/wj-results-tag-comparison.constants';
import { IWJResultsVariable } from '../tag-report-table/wj-results-tag-report-table.models';
import { WjResultsTagReportTableService } from '../tag-report-table/wj-results-tag-report-table.service';
import { Events } from '@app/moonbeamConstants';
import { IPrimaryTagToggle } from '../web-journey-results.models';
import { forkJoin, from, of, ReplaySubject, Subject } from 'rxjs';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { IPrimaryTag, ManageTagsService } from '@app/components/account/manageTags/manageTagsService';
import { IJourneyVariable, IWebJourneyActionTag } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { ComparisonsService } from '@app/components/comparisons/comparisons.service';
import { IAccountTagSettings } from '@app/components/comparisons/comparisons.models';
import { ETagComparisonOutcome } from './wj-results-tag-comparison.enums';
import { tagComparisonOutcomeToLabelMap } from './wj-results-tag-comparison.constants';
import { ComparisonError } from '../web-journey-results.constants';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { AccountsService } from '@app/components/account/account.service';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';
import { WebJourneyReportService } from '../../web-journey-report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

export type ActionTagSummary = IWJActionTagSummary | IAbsentTagSummary;
type SearchableActionTagSummary = ISearchableTagSummary | ISearchableAbsentTagSummary;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'wj-results-tag-report',
  templateUrl: './wj-results-tag-report.component.html',
  styleUrls: ['./wj-results-tag-report.component.scss'],
  providers: [
    WjResultsTagReportTableService,
    WjResultsTagReportService
  ]
})
export class WjResultsTagReportComponent implements OnInit, OnChanges, OnDestroy {

  @Input() action: IActionSelectorAction;
  @Input() canAddRule: boolean;
  @Input() isReadOnly: boolean;
  @Input() primaryTagFilteringDisabled: boolean;
  @Input() primaryTagsToggleValue: boolean;
  @Output() onPrimaryTagsToggleValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onAddRule: EventEmitter<ActionTagSummary> = new EventEmitter();

  readonly noActionTagsErrorMessage = noActionTagsError;

  journeyId: number;
  runId: number;

  loading = true;

  showDiffsOnly = false;
  hasComparison: boolean;
  isActionMissing: boolean;
  showComparison: boolean;
  isFirstRun: boolean;
  noTagsForComparison: boolean;

  tagInstanceIdToDetailsMap: Map<string, ITagVariablesDiffDetails>;

  dataIsReadySubject = new ReplaySubject<void>();

  primaryTagToggleSubscription: number;
  tagFilteringToggleValue: boolean;
  primaryTags: IPrimaryTag[];
  accountTags: IUiTag[];
  maxComparisonTags: boolean;

  actionTagVarsDiffCount: IActionTagVariablesDiffCount[];
  comparisonTags: IAccountTagSettings[];
  missingActionsFromBaseline: number[] = [];
  comparisonSnapshotTagsMap: Map<number, IWJComparisonSnapshotTag>;

  comparisonError: ComparisonError;

  tags: ActionTagSummary[];

  filterBy = '';
  filteredTags: (ActionTagSummary & ITagWithComparisons)[] = [];
  searchableTags: SearchableActionTagSummary[];

  private destroy$ = new Subject();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private eventManager: IEventManager,
    private manageTagsService: ManageTagsService,
    private uiTagService: UiTagService,
    private accountsService: AccountsService,
    private resultsTagReportService: WjResultsTagReportService,
    private comparisonsService: ComparisonsService,
    private tagReportTableService: WjResultsTagReportTableService,
    private webJourneyReportService: WebJourneyReportService) { }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: IWJReportParams) => {
        this.journeyId = Number(params.journeyId);
        this.runId = Number(params.runId);

        this.fetchData();
      });

    this.initListener();
  }

  ngOnChanges(changes: ComponentChanges<WjResultsTagReportComponent>): void {
    const tagsChanged = changes.tags?.currentValue !== changes.tags?.previousValue;
    const actionChanged = changes.action?.currentValue !== changes.action?.previousValue;
    if (tagsChanged || actionChanged) this.prepareData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.eventManager.unSubscribe(Events.primaryTagWebJourneyToggleChanged, this.primaryTagToggleSubscription);
  }

  private initListener(): void {
    this.webJourneyReportService.tagFiltering$.pipe(takeUntil(this.destroy$)).subscribe((toggleValue: boolean) => {
      this.tagFilteringToggleValue = toggleValue;
      this.prepareData();
    });
  }

  private fetchData(): void {
    forkJoin([
      this.manageTagsService.getReportToggleValue(),
      this.manageTagsService.getPrimaryTags(),
      this.uiTagService.getTags(),
      this.resultsTagReportService.getActionTagVariablesDiffCount(this.journeyId, this.runId),
      this.comparisonsService.getAccountTags().pipe(catchError(_ => of(new ComparisonError()))),
      forkJoin([
        this.webJourneyReportService.getActionsMissingFromBaseline(this.journeyId, this.runId).pipe(catchError(_ => of(new ComparisonError()))),
        this.resultsTagReportService.getComparisonSnapshot(this.journeyId, this.runId),
        from(this.accountsService.getComparisonAccountSettings()).pipe(catchError(_ => of(new ComparisonError()))),
        this.resultsTagReportService.getTagVariablesDiffDetails(this.journeyId, this.runId, this.action.actionId),
        this.webJourneyReportService.getJourneyRuns(this.journeyId)
      ])
    ]).subscribe(([toggleValue, primaryTags, accountTags, actionTagVarsDiffCount, comparisonTags,
      [missingActions, comparisonSnapshot, accountSettings, diffDetails, runs]
    ]) => {
      this.loading = false;

      this.tagFilteringToggleValue = toggleValue;
      this.primaryTags = primaryTags;
      this.accountTags = accountTags;

      this.actionTagVarsDiffCount = (actionTagVarsDiffCount instanceof ComparisonError) ? null : actionTagVarsDiffCount;
      this.comparisonTags = (comparisonTags instanceof ComparisonError) ? null : comparisonTags;
      this.missingActionsFromBaseline = (missingActions instanceof ComparisonError) ? null : missingActions;

      if (comparisonSnapshot instanceof ComparisonError) {
        this.comparisonSnapshotTagsMap = null;
        this.hasComparison = false;
      } else {
        this.comparisonSnapshotTagsMap = ArrayUtils.toMap(comparisonSnapshot?.tags, 'tagId');
        this.hasComparison = comparisonSnapshot !== null;
        this.noTagsForComparison = !comparisonSnapshot?.tags?.length;
      }

      this.maxComparisonTags = (accountSettings instanceof ComparisonError) ?
        null :
        this.comparisonTags?.filter(t => t.comparisonEnabled).length >= accountSettings.maxComparisonTags;

      const sortedRuns = runs.sort((run1, run2) => new Date(run1.completedAt).getTime() - new Date(run2.completedAt).getTime());
      this.isFirstRun = Array.isArray(sortedRuns) && sortedRuns[0]?.id === this.runId;

      if (actionTagVarsDiffCount instanceof ComparisonError ||
        comparisonTags instanceof ComparisonError ||
        missingActions instanceof ComparisonError ||
        comparisonSnapshot instanceof ComparisonError ||
        accountSettings instanceof ComparisonError ||
        diffDetails instanceof ComparisonError) this.comparisonError = new ComparisonError();

      this.dataIsReadySubject.next();
    });
  }

  prepareData(): void {
    this.dataIsReadySubject.pipe(switchMap(() => this.checkComparison()))
      .subscribe(diffDetails => {
        if (diffDetails instanceof ComparisonError) {
          this.comparisonError = diffDetails;
        } else {
          this.tagInstanceIdToDetailsMap = ArrayUtils.toMap(diffDetails, 'tagInstanceId');
        }

        this.isActionMissing = this.missingActionsFromBaseline?.includes(this.action.actionId);
        this.showComparison = this.hasComparison && !this.isActionMissing;

        this.buildTagsList();
      });
  }

  buildTagsList(): void {
    const filteredTags = this.tagFilteringToggleValue && this.primaryTags.length ?
      this.manageTagsService.filterJourneyTagsByPrimaryTags(this.action.tags || [], this.primaryTags) as IWebJourneyActionTag[] :
      this.action.tags;

    this.tags = (!this.actionTagVarsDiffCount || this.actionTagVarsDiffCount.length === 0) ?
      this.buildTagsWithoutDiffs(filteredTags) :
      this.buildTagsWithDiffs(filteredTags);

    this.prepareVisibleTags(this.tags);
  }

  private buildTagsWithoutDiffs(filteredTags: IWebJourneyActionTag[]): IWJActionTagSummary[] {
    return filteredTags.map(t => ({
      ...t,
      excludedVars: this.comparisonTags?.find(ct => ct.tagId === t.tagId)?.excludedVariables,
      excludedVarsSnapshot: this.comparisonSnapshotTagsMap?.get(t.tagId)?.excludedVariables,
      duplicateScoring: this.isDuplicateScoring(t),
      outcome: ETagComparisonOutcome.match,
      visibleOutcome: this.getTagOutcome(),
      comparisonEnabled: this.isTagComparisonEnabled(t.tagId),
      matchCriteriaEnabled: this.isVarComparisonEnabled(t.tagId)
    }));
  }

  private buildTagsWithDiffs(filteredTags: IWebJourneyActionTag[]): ActionTagSummary[] {
    const actionDiffs = this.actionTagVarsDiffCount.find(summary => summary.actionId === this.action.actionId);

    const tags = filteredTags.map(t => {
      const diff = actionDiffs?.tagVariablesDifferences?.find(diff => diff.tagInstanceId === t.tagInstanceId);
      const varsDifferences = diff?.variablesDifferenceCount;
      return {
        ...t,
        varsDifferences,
        excludedVars: this.comparisonTags?.find(ct => ct.tagId === t.tagId)?.excludedVariables,
        excludedVarsSnapshot: this.comparisonSnapshotTagsMap?.get(t.tagId)?.excludedVariables,
        duplicateScoring: this.isDuplicateScoring(t),
        outcome: diff?.outcome || ETagComparisonOutcome.match,
        visibleOutcome: this.getTagOutcome(diff),
        comparisonEnabled: this.isTagComparisonEnabled(t.tagId),
        matchCriteriaEnabled: this.isVarComparisonEnabled(t.tagId)
      };
    });

    const absentTags: IAbsentTagSummary[] = actionDiffs?.tagVariablesDifferences
      .filter(diff => diff.outcome === ETagComparisonOutcome.tagAbsent && this.isVarComparisonEnabled(diff.tagId))
      .map(diff => {
        const accountTag = this.accountTags.find(aTag => aTag.id === diff.tagId);
        return {
          tagId: diff.tagId,
          tagName: accountTag?.name,
          tagIcon: accountTag?.iconUrl,
          account: diff.tagAccount,
          tagInstanceId: diff.tagInstanceId,
          excludedVars: this.comparisonTags?.find(ct => ct.tagId === diff.tagId)?.excludedVariables,
          excludedVarsSnapshot: this.comparisonSnapshotTagsMap?.get(diff.tagId)?.excludedVariables,
          outcome: ETagComparisonOutcome.tagAbsent,
          visibleOutcome: tagComparisonOutcomeToLabelMap.get(ETagComparisonOutcome.tagAbsent),
          comparisonEnabled: this.isTagComparisonEnabled(diff.tagId),
          matchCriteriaEnabled: this.isVarComparisonEnabled(diff.tagId)
        };
      });

    return absentTags ? [...tags, ...absentTags] : tags;
  }

  private isDuplicateScoring(tag: IWebJourneyActionTag): boolean {
    const accountTag = this.accountTags.find(accountTag => accountTag.id === tag.tagId);
    return accountTag ? accountTag.duplicateScoring : false;
  }

  private getTagOutcome(diff?: ITagVariablesDiffCount): string {
    if (!diff) return 'No Changes';
    switch (diff.outcome) {
      case ETagComparisonOutcome.tagAbsent:
      case ETagComparisonOutcome.tagAdded:
        return tagComparisonOutcomeToLabelMap.get(diff.outcome);
      case ETagComparisonOutcome.variableMismatch:
        return diff.variablesDifferenceCount + ' Change' + (diff.variablesDifferenceCount > 1 ? 's' : '');
      default:
        return 'No Changes';
    }
  }

  private isTagComparisonEnabled(tagId: number): boolean {
    return this.comparisonSnapshotTagsMap?.has(tagId);
  }

  private isVarComparisonEnabled(tagId: number): boolean {
    const snapshotTag = this.comparisonSnapshotTagsMap?.get(tagId);
    return snapshotTag?.matchCriteriaVariables?.length > 0;
  }

  showComparisonSetupBanned(): boolean {
    return this.noTagsForComparison && !this.isFirstRun;
  }

  showComparisonForTag(tag: ActionTagSummary): boolean {
    return this.showComparison && tag.matchCriteriaEnabled && !this.comparisonError;
  }

  private prepareVisibleTags(tags: ActionTagSummary[]): void {
    if (!Array.isArray(tags)) return;

    this.searchableTags = this.buildSearchableTags(this.tags);

    this.filterTags();
  }

  private checkComparison() {
    return this.resultsTagReportService.getTagVariablesDiffDetails(this.journeyId, this.runId, this.action.actionId);
  }

  filterTags(): void {
    if (!this.searchableTags) return;

    const hasChanges = (t: SearchableActionTagSummary): boolean => {
      return t.outcome !== ETagComparisonOutcome.match && t.comparisonEnabled && t.matchCriteriaEnabled;
    };
    const tagsWithChanges = this.showDiffsOnly ? this.searchableTags.filter(hasChanges) : this.searchableTags;

    const searchName = this.filterBy.toLowerCase();
    let filteredTags = tagsWithChanges.reduce((result, origTag) => {
      let comparisonVariables = this.getComparisonVariables(origTag);
      if (origTag.searchName.includes(searchName) || origTag.searchAccount.includes(searchName)) result.push({ ...origTag, comparisonVariables });
      else if (this.isActionTagSummary(origTag)) {
        const variables = origTag.variables.filter(v => this.variableHasSearchKey(v, searchName));

        if (variables.length) {
          result.push({ ...origTag, variables, comparisonVariables });
        }
      }
      return result;
    }, []).filter(tag => this.showDiffsOnly ? tag.comparisonVariables?.variablesDifferences?.length : true);

    this.filteredTags = ArrayUtils.sortBy(filteredTags, 'tagName', ESortDirection.asc);
  }

  private variableHasSearchKey(variable: ISearchableTagVariableSummary, searchKey: string) {
    return variable.searchDescription.includes(searchKey) ||
      variable.searchName.includes(searchKey) ||
      variable.searchValue.includes(searchKey);
  }

  // cache the `toLowerCase` name to prevent doing that on every search
  private buildSearchableTags(tags: ActionTagSummary[]): SearchableActionTagSummary[] {
    if (!tags) return;

    return this.tags.map(tag => {
      const variables = this.isActionTagSummary(tag) ?
        tag.variables.map(this.buildSearchableVariables) :
        [];
      return {
        ...tag,
        searchName: tag.tagName.toLowerCase(),
        searchAccount: tag.account ? tag.account.toLowerCase() : '',
        variables
      };
    });
  }

  private buildSearchableVariables(variable: IJourneyVariable): ISearchableTagVariableSummary {
    return {
      ...variable,
      searchName: variable.name.toLowerCase(),
      searchValue: variable.value.toLowerCase(),
      searchDescription: variable.description?.toLowerCase()
    };
  }

  private isActionTagSummary(tag: ActionTagSummary): tag is IWJActionTagSummary {
    return !!(tag as IWJActionTagSummary).variables;
  }

  addRule(event: MouseEvent, tag: IWJActionTagSummary): void {
    event.stopPropagation();

    this.onAddRule.emit(this.tags.find(tag2 => tag2.tagInstanceId === tag.tagInstanceId));
  }

  opened(tag: any) {
    tag.expanded = true;
  }

  closed(tag: any) {
    tag.expanded = false;
  }

  trackTagResult = (index: number, tag: IWJActionTagSummary) => {
    return tag.tagInstanceId;
  }

  onFilterChanging(text: string): void {
    this.filterBy = text.trim();
    this.filterTags();
  }

  onSortChange(propName: keyof IWJResultsVariable): void {
    this.tagReportTableService.sortBy(propName);
  }

  isNumber(val?: number): boolean {
    return typeof val === 'number';
  }

  isDefined(val?: boolean): boolean {
    return typeof val !== 'undefined';
  }

  goToAccountSettings(event?: MouseEvent): void {
    event?.stopPropagation();
    this.router.navigateByUrl(AccountSettingsUrlBuilders.manageTags());
  }

  getErrorMessage(): string {
    if (this.comparisonError) return this.comparisonError.message;

    const actionHasTags = this.action.tags?.length > 0;
    if (!actionHasTags) return noActionTagsError;

    if (this.isActionMissing) return noMatchedActionError;

    return '';
  }

  getComparisonVariables(tag) {
    if (this.tagInstanceIdToDetailsMap) {
      const searchName = this.filterBy.toLowerCase();

      let comparisonVariables: ITagVariablesDiffDetails = this.tagInstanceIdToDetailsMap?.get(tag.tagInstanceId);
      return {
        variablesDifferences: comparisonVariables?.variablesDifferences.filter(v => v.variableName.includes(searchName))
      };
    }
  }
}
