import { AccountSettingsUrlBuilders } from './../account-settings/account-settings.const';
import { defaultDatasource } from './../comparisons/shared/comparison-variable-selector/comparison-variable-selector.constants';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { IRunOverRunModalData } from '../comparisons/ror-comparison/ror-comparison.models';
import { comparisonTypeToLabelMap } from './comparison-library.constants';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ComparisonTypePickerComponent } from '@app/components/comparisons/comparison-type-picker/comparison-type-picker.component';
import { OneTimeComparisonComponent } from '@app/components/comparisons/one-time-comparison/one-time-comparison.component';
import { RorComparisonComponent } from '@app/components/comparisons/ror-comparison/ror-comparison.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { IComparisonLibrary, IComparisonLibraryFromAPI, IComparisonRun } from './comparison-library.model';
import { ComparisonLibraryService } from './comparison-library.service';
import { from, forkJoin, timer as timer$, Subject, Observable } from 'rxjs';
import { concatMap, take, takeUntil, switchMap, catchError, flatMap, map } from 'rxjs/operators';
import { IComparisonModalData } from '@app/components/comparisons/one-time-comparison/one-time-comparison.models';
import { EComparisonType } from '../comparisons/comparisons.enums';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { userIsAdmin } from '@app/authUtils';
import { IAdvancedConfigs } from '../shared/components/op-chip-selector/op-chip-selector.models';
import { IUser } from '@app/moonbeamModels';
import { Router } from '@angular/router';
import { AccountsService } from '@app/components/account/account.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'comparison-library',
  templateUrl: './comparison-library.component.html',
  styleUrls: ['./comparison-library.component.scss'],
  animations: [
    trigger('expand', [
      state('collapsed', style({ maxHeight: '0', minHeight: '0' })),
      state('loading', style({ maxHeight: '60px', minHeight: '60px' })),
      state('expanded', style({ maxHeight: '200px', minHeight: '{{height}}' }), { params: { height: '*' } }),
      transition('collapsed <=> expanded, loading <=> *', animate('200ms'))
    ])
  ]
})
export class ComparisonLibraryComponent implements OnInit, OnDestroy {

  readonly comparisonTypesMap = comparisonTypeToLabelMap;
  readonly comparisonType = EComparisonType;

  pollingTime = 10000; // 10 seconds
  destroy$ = new Subject<void>();

  // 'webjourneys' are commnted according to https://observepoint.atlassian.net/browse/WORK-14140
  // will be uncommented later
  displayedColumns = ['name', 'type', 'labels', 'tags', 'audits', /*'webjourneys',*/ 'options'];
  chipsConfig: IAdvancedConfigs = {
    collapsible: true,
    lines: 1,
    readOnly: false,
    resizable: false,
    availableWidth: 297
  };

  selectedComparison: IComparisonLibrary;
  comparisonRunMap: Map<number, IComparisonRun[]>;

  loading = false;
  loadingTable = true;

  filterBy = '';

  allLabels: ILabel[];

  origComparisons: IComparisonLibrary[] = [];

  filteredComparisons = new MatTableDataSource<IComparisonLibrary>();
  @ViewChild(MatSort) sort: MatSort;

  user: IUser;
  isAdmin = false;

  lastOpenedNestedTableHeight: number;
  lastOpenedNestedTable: HTMLDivElement;

  constructor(private snackBar: MatSnackBar,
              private router: Router,
              private comparisonLibraryService: ComparisonLibraryService,
              private modalService: OpModalService,
              private labelsService: LabelService,
              private accountsService: AccountsService,
              private cdr: ChangeDetectorRef) {
    this.comparisonRunMap = comparisonLibraryService.comparisonRunMap;
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  loadData() {
    this.loadingTable = true;
    forkJoin([
      this.accountsService.getUser(),
      this.fetchComparisons(),
    ]
    ).subscribe(([user, comparisons]) => {
      this.user = user;
      this.isAdmin = userIsAdmin(user);
      this.prepareComparisons(comparisons);
      this.checkingUpdates();
      this.loadingTable = false;
      this.filteredComparisons.sort = this.sort;
      this.filteredComparisons.sortingDataAccessor = (data, attribute) => {
        if (attribute === 'audits') {
          return data.assignments?.audits;
        } else if (typeof(data[attribute]) === 'string') {
          return data[attribute].toLowerCase();
        }

        return  data[attribute];
      };
    });
  }

  private prepareComparisons(comparisons: IComparisonLibrary[]) {
    this.origComparisons = comparisons.map(comparison => ({
      ...comparison,
      tags: comparison.tags.filter(t => t !== defaultDatasource.tagId),
      canDelete: userIsAdmin(this.user) || this.user.id === comparison.createdBy
    }));
    this.filteredComparisons.data = this.origComparisons;
  }

  checkingUpdates() {
    timer$(this.pollingTime, this.pollingTime).pipe(
      takeUntil(this.destroy$),
      switchMap(() => this.fetchComparisons())
    ).subscribe(comparisons => {
      let progressStatusChanged = false;
      comparisons.forEach(updatedComparison => {
        const cachedComparison = this.origComparisons.find(c => c.id === updatedComparison.id);
        if (cachedComparison && cachedComparison.isInProgress && !updatedComparison.isInProgress) {
          progressStatusChanged = true;
          this.showInfoMessage(`Comparison "${updatedComparison.name}" has been completed!`);
          this.comparisonLibraryService.resetComparisonRun(updatedComparison.id);
        }
      });
      if (progressStatusChanged) this.prepareComparisons(comparisons);
    });
  }

  onFilterChanging(text: string) {
    this.filterBy = text.trim();
    this.filterComparisons();
  }

  private filterComparisons() {
    const searchName = this.filterBy.toLowerCase();
    this.filteredComparisons.data = this.origComparisons.filter(tp => {
      const containsInName = tp.name.toLowerCase().indexOf(searchName) !== -1;
      const containsInLabels = !!tp.labels.find(label => label.name.toLowerCase().indexOf(searchName) !== -1);
      return containsInName || containsInLabels;
    });
  }

  openTypePickerModal() {
    this.modalService.openModal(ComparisonTypePickerComponent, {
      autoFocus: false
    })
      .afterClosed()
      .pipe(take(1))
      .subscribe(type => this.openComparisonByType(type));
  }

  openComparisonByType(type: EComparisonType, comparison?: IComparisonLibrary) {
    const data = comparison ? { id: comparison.id } : {};
    let dialogRef: MatDialogRef<any>;
    switch (type) {
      case EComparisonType.runOverRun:
        dialogRef = this.openRunOverRunComparison(data);
        break;
      case EComparisonType.oneTime:
        dialogRef = this.openOneTimeComparison(data);
        break;
    }

    dialogRef && dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      this.loadData();
      this.filterComparisons();
    });
  }

  openRunOverRunComparison(data: Partial<IRunOverRunModalData>): MatDialogRef<RorComparisonComponent> {
    return this.modalService.openModal(RorComparisonComponent, { data });
  }

  private openOneTimeComparison(data: Partial<IComparisonModalData>): MatDialogRef<OneTimeComparisonComponent> {
    return this.modalService.openModal(OneTimeComparisonComponent, { data });
  }

  selectComparison(comparison: IComparisonLibrary, forceOpen = false) {
    this.selectedComparison = (this.selectedComparison !== comparison || forceOpen) ? comparison : null;
    if (this.selectedComparison) {
      this.loading = true;
      this.comparisonLibraryService.getComparisonRuns(comparison.id).subscribe(() => {
        this.loading = false;
      });
    }
  }

  runComparison(comparison: IComparisonLibrary) {
    if (comparison.type !== this.comparisonType.oneTime) return;

    this.comparisonLibraryService.runComparison(comparison.id, comparison.name).pipe(
      catchError(err => {
        this.selectComparison(comparison, true);
        return err;
      })
    ).subscribe(() => {
      comparison.isInProgress = true;
    });
  }

  private showInfoMessage(message: string) {
    this.snackBar.open(
      message,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom' }
    );
  }

  deleteComparison(comparison: IComparisonLibrary) {
    if (!comparison) return;

    const data = {
      deleteButtonAction: () => {
        this.comparisonLibraryService.deleteComparison(comparison.id).subscribe(() => {
          if (this.selectedComparison && this.selectedComparison.id === comparison.id) this.selectedComparison = null;
          this.origComparisons = this.origComparisons.filter(c => c.id !== comparison.id);
          this.filteredComparisons.data = this.filteredComparisons.data.filter(c => c.id !== comparison.id);
        });
      },
      displayItem: {
        type: 'Comparison',
        name: comparison.name
      }
    };
    this.modalService.openDeleteModal({ data });
  }

  onCreateLabel(label: string, comparison: IComparisonLibrary) {
    this.labelsService.createLabel(label)
      .pipe(
        concatMap(newLabel => {
          return this.comparisonLibraryService.updateComparisonLabels(comparison.id, [
            ...comparison.labels.map(lab => lab.id),
            newLabel.id
          ]).pipe(map(() => {
            comparison.labels.push(newLabel);
          }));
        })
      )
      .subscribe();
  }

  onAddLabel(comparison: IComparisonLibrary) {
    const labelIds = comparison.labels.map(label => label.id);
    this.comparisonLibraryService.updateComparisonLabels(comparison.id, labelIds).subscribe();
  }

  onRemoveLabel(comparison: IComparisonLibrary) {
    this.comparisonLibraryService.updateComparisonLabels(comparison.id, comparison.labels.map(label => label.id)).subscribe();
  }

  openManageTags() {
    this.router.navigateByUrl(AccountSettingsUrlBuilders.manageTags());
  }

  animationDone($event: AnimationEvent, container: HTMLDivElement) {
    if (($event.fromState === 'loading' && $event.toState === 'expanded')
      || ($event.fromState === 'collapsed' && $event.toState === 'expanded')) {
      this.lastOpenedNestedTable = container;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = container.getBoundingClientRect().height;
        this.cdr.detectChanges();
      }, 300);
    }

    if ($event.fromState === 'expanded' && $event.toState === 'collapsed') {
      this.lastOpenedNestedTable = null;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = 0;
        this.cdr.detectChanges();
      }, 300);
    }
  }

  private fetchComparisons(): Observable<IComparisonLibrary[]> {
    return this.comparisonLibraryService.getComparisonLibrary().pipe(
      flatMap((comparisons: IComparisonLibraryFromAPI[]) => {
        const allLabelsObservable: Observable<ILabel[]> = this.allLabels
          ? from([this.allLabels])
          : this.labelsService.getLabels();
        return allLabelsObservable.pipe(
          map((labels: ILabel[]) => {
            if (!this.allLabels) {
              this.allLabels = labels;
            }
            return this.mapComparisonLabels(comparisons, labels);
          })
        );
      })
    );
  }

  private mapComparisonLabels(comparisons: IComparisonLibraryFromAPI[], labels: ILabel[]): IComparisonLibrary[] {
    const labelsMap = new Map<number, ILabel>(labels.map(l => [l.id, l]));
    return comparisons.map(c => {
      const updatedLabels: ILabel[] = c.labels.map(l => labelsMap.get(l)).filter(l => !!l);
      return {
        ...c,
        labels: updatedLabels
      } as IComparisonLibrary;
    });
  }
}
