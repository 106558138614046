<div class="vi-report-wrapper">
  <div class="widget-container">
    <split-card [state]="widgetState"
                [topLabel]="pageCountLabel"
                [metricType]="pageMetricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="filteredPageCount"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === pageMetricType"
                [disableHighlightFromClick]="true"
    >
      <div class="widget-page-count">
        <div class="page-counts">
          <span class="pages-filtered-count">{{filteredPageCount}}</span>
          <span *ngIf="widgetState === 'filtered'">&nbsp;of {{totalPageCount}}</span>
        </div>
      </div>
    </split-card>
    <split-card [state]="widgetState"
                [topLabel]="uniqueTagsLabel"
                [topChangeContent]="filteredUniqueTagsCount"
                [haveEnoughData]="uniqueTagsSparklineData?.length > 1"
                [bottomHandler]="uniqueTagsCountBottomHandler"
                [topTooltip]="'Total number unique tag types found in this audit/run.'"
                [metricType]="uniqueTagsMetricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="filteredUniqueTagsCount"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === uniqueTagsMetricType"
                [disableHighlightFromClick]="true"
    >
      <sparkline-chart-insight [meaningOfUp]="'none'"
                               [meaningOfNoChange]="'none'"
                               [label]="'since last run'"
                               [dataLoaded]="sparklineDataLoaded"
                               [dataPoints]="uniqueTagsSparklineData">
      </sparkline-chart-insight>
    </split-card>
    <split-card [state]="widgetState"
                [topLabel]="tagRequestsEvaluatedLabel"
                [topChangeContent]="filteredTagRequestsCountFormatted"
                [topChangeFilteredContent]="relevantFiltersApplied ? totalTagRequestsEvaluatedCount : null"
                [haveEnoughData]="tagRequestsEvaluatedSparklineData?.length > 1"
                [bottomHandler]="tagRequestsEvaluatedBottomHandler"
                [topTooltip]="'Total number of tag instances represented in the data below.'"
                [metricType]="tagRequestsEvaluatedMetricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="filteredTagRequestsCount"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === tagRequestsEvaluatedMetricType"
                [disableHighlightFromClick]="true"
    >
      <sparkline-chart-insight [meaningOfUp]="'none'"
                               [meaningOfNoChange]="'none'"
                               [label]="'since last run'"
                               [dataLoaded]="sparklineDataLoaded"
                               [dataPoints]="tagRequestsEvaluatedSparklineData">
      </sparkline-chart-insight>
    </split-card>
    <split-card [topLabel]="uniqueVariableNameLabel"
                [state]="widgetState"
                [topChangeContent]="filteredUniqueVariableNameCount"
                [topChangeFilteredContent]="relevantFiltersApplied ? totalUniqueVariableNameCount : null"
                [haveEnoughData]="uniqueVariableNameSparklineData?.length > 1"
                [bottomHandler]="uniqueVariableNameBottomHandler"
                [topTooltip]="'Count of unique variable names across all tags evaluated and selected by the filters.'"
                [metricType]="uniqueVariableMetricType"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="filteredUniqueVariableNameCount"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === uniqueVariableMetricType"
                [disableHighlightFromClick]="true"
    >
      <sparkline-chart-insight [meaningOfUp]="'none'"
                               [meaningOfNoChange]="'none'"
                               [label]="'since last run'"
                               [dataLoaded]="sparklineDataLoaded"
                               [dataPoints]="uniqueVariableNameSparklineData">
      </sparkline-chart-insight>
    </split-card>
    <split-card [topLabel]="uniqueVariableValueLabel"
                [state]="widgetState"
                [topChangeContent]="filteredUniqueVariableValueCountFormatted"
                [topChangeFilteredContent]="relevantFiltersApplied ? totalUniqueVariableValueCount : null"
                [haveEnoughData]="uniqueVariableValueSparklineData?.length > 1"
                [bottomHandler]="uniqueVariableValueBottomHandler"
                [topTooltip]="'Count of unique variable values across all tags evaluated and selected by the filters.'"
                [metricType]="uniqueVariableValueMetric"
                [auditId]="auditId"
                [runId]="runId"
                [currentValue]="filteredUniqueVariableValueCount"
                [filters]="currentFilters"
                [highlight]="highlightMetricType === uniqueVariableValueMetric"
                [disableHighlightFromClick]="true"
    >
      <sparkline-chart-insight [meaningOfUp]="'none'"
                               [meaningOfNoChange]="'none'"
                               label="'since last run'"
                               [dataLoaded]="sparklineDataLoaded"
                               [dataPoints]="uniqueVariableValueSparklineData">
      </sparkline-chart-insight>
    </split-card>
  </div>
  <div class="vi-table-section" [ngClass]="{'display-pages': selectedVariable.variableName !== null || displayPagesTable}">
    <div class="vi-table-section-header">
      <span>
        Tags & Variables
      </span>
      <filter-spinner [state]="tagTableState"></filter-spinner>
    </div>
    <div class="vi-tags-tables-container">
      <div class="vi-tags-and-accounts-table-border-wrapper">
        <div class="vi-tags-and-accounts-table"
             [ngClass]="{'scrolled': tagsAndAccountsTableScrolled}"
             (scroll)="tagsAndAccountsTableScroll($event)"
        >
          <table mat-table
                 #tagAndAccountsTableSort="matSort"
                 #tagAndAccountsTable
                 matSort
                 matSortStart="desc"
                 [dataSource]="tagsAndAccountsDataSource">
            <ng-container matColumnDef="tagAccount">
              <th mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  matTooltipShowDelay="500"
                  matTooltipPosition="above"
                  matTooltip="{{tagsAndAccountsDataSource.data?.length > 99 ? 'Showing the top 100 tag/account combinations. Use filters or export data to view more.' : ''}}"
                  class="vi-left-header">
                TAG & ACCOUNTS ({{tagsAndAccountsDataSource.data?.length > 99 ? '99+' : tagsAndAccountsDataSource.data?.length}})
              </th>
              <td mat-cell *matCellDef="let tag" (click)="selectTag(tag)">
                <div class="tag-account-container">
                  <div class="tag-icon-wrapper">
                    <img class="tag-icon" *ngIf="tag"
                         [src]="getTagIconUrl(tag.tagId)" alt="{{tag.tagName}}" />
                  </div>
                  <div class="tag-info">
                    <div class="tag-name"
                         [title]="tag.tagName">
                      {{ tag.tagName }}
                    </div>
                    <div class="tag-account"
                         [title]="tag.tagAccount">
                      {{tag.tagAccount}}
                    </div>
                    <div class="tag-counts">{{tag.totalVariableCount|number}} Variable{{tag.tagAccounts?.length !== 1 ? 's' : ''}}, {{tag.totalPageCount|number}} page{{tag.totalPageCount !== 1 ? 's' : ''}}</div>
                  </div>
                  <div class="hover-menu tag-and-accounts-menu-hover"
                       (click)="handleTagAndAccountsMenuClick($event)"
                       [matMenuTriggerData]="{tag: tag}"
                       [matMenuTriggerFor]="tagsAndAccountsMenu">
                    <mat-icon class="ellipse-icon">more_vert</mat-icon>
                  </div>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tagAndAccountsColumnsToDisplay; sticky: true;"></tr>
            <tr mat-row *matRowDef="let tag; columns: tagAndAccountsColumnsToDisplay;"
                class="tag-and-accounts-row"
                [ngClass]="{'selected': (selectedTag$ | async)?.tagName === tag.tagName && (selectedTag$ | async)?.tagAccount === tag.tagAccount}"
            >
            </tr>
          </table>
          <mat-menu
            #tagsAndAccountsMenu="matMenu"
            xPosition="before"
            yPosition="below"
            [overlapTrigger]="false"
            (closed)="menuClosed($event)"
            class="split-card-menu mat-menu-overlay"
          >
            <ng-template matMenuContent let-tag="tag">
              <ul>
                <ng-container *ngFor="let item of tagsAndAccountsMenuOptions">
                  <li mat-menu-item class="split-card-menu-option">
                    <button
                      class="mat-button"
                      [disabled]="item.disabled"
                      (click)="item.handler(tag)">
                      <mat-icon class="split-menu-icon">{{item.icon}}</mat-icon>{{item.label}}
                    </button>
                  </li>
                </ng-container>
              </ul>
            </ng-template>
          </mat-menu>
        </div>
      </div>
      <div class="vi-tag-account-variables-table"
           [ngClass]="{'scrolled': tagAccountVariablesTableScrolled}"
           (scroll)="tagAccountVariablesTableScroll($event)">
        <div class="vi-table-zero-state"
             *ngIf="(selectedTag$ | async)?.tagName === null">
          <div class="vi-zero-state-text">
            Select a tag to the left to see variable details
          </div>
        </div>
        <div class="loader" *ngIf="tagAccountVariablesLoading">
          <mat-spinner class="spinner" [diameter]="60" [strokeWidth]="3"></mat-spinner>
        </div>
        <table mat-table
               #tagAccountVariablesTableSort="matSort"
               matSort
               matSortStart="desc"
               [hidden]="(selectedTag$ | async)?.tagName === null"
               [dataSource]="tagAccountVariablesDataSource">
          <ng-container matColumnDef="friendlyName">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="tag-account-variable-header vi-left-header">
              VARIABLES ({{tagAccountVariablesDataSource.data?.length === 500
              ? 'ONLY SHOWING TOP 500'
              : tagAccountVariablesDataSource.data?.length}})
              <op-clearable-input (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" (onUserInput)="filteringVariables($event)" placeholderText="Filter by name"></op-clearable-input>
            </th>
            <td mat-cell *matCellDef="let variable;">
              <div class="tag-account-variable-name" *ngIf="variable.friendlyName">
                {{variable.friendlyName}} ({{variable.variableName}})
              </div>
              <div class="tag-account-variable-name" *ngIf="!variable.friendlyName">
                {{variable.variableName}}
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="uniqueValueCount">
            <th mat-header-cell
                class="tag-account-variable-header"
                *matHeaderCellDef mat-sort-header
            >
              # UNIQUE VALUES
            </th>
            <td mat-cell
                *matCellDef="let variable;"
                class="vi-clickable-cell"
                [ngClass]="{'tav-cell-selected': variable.selected === eVariableItemSelection.UNIQUE_VALUE_COUNT}"
                (click)="filterVariablePagesByUniqueCount(variable)"
            >
              <div class="tag-account-variable-unique-value-wrapper">
                <div class="tav-value">
                  {{variable.uniqueValueCount|number}}
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="variableSetPageCount">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="tag-account-variable-header"
            >
              <div class="tav-var-set-wrapper">
                PAGES WHERE VARIABLE IS SET
              </div>
            </th>
            <td mat-cell
                *matCellDef="let variable"
                class="vi-clickable-cell"
                [ngClass]="{'tav-cell-selected': variable.selected === eVariableItemSelection.VALUE_SET_COUNT}"
                (click)="filterVariablePagesByVariableSet(variable)"
            >
              <div class="tag-account-variable-variable-set-wrapper">
                <div class="tav-value">
                  {{variable.variableSetPageCount|number}}
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="variableNotSetPageCount">
            <th mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="tag-account-variable-header"
            >
              <div class="tav-var-not-set-wrapper">
                PAGES WHERE VARIABLE IS NOT SET
              </div>
            </th>
            <td mat-cell
                class="vi-clickable-cell"
                *matCellDef="let variable; let index;"
                [ngClass]="{'tav-cell-selected': variable.selected === eVariableItemSelection.VALUE_NOT_SET_COUNT}"
                (click)="filterVariablePagesByVariableNotSet(variable)"
            >
              <div class="tag-account-variable-variable-not-set-wrapper">
                <div class="tav-value">
                  {{variable.variableNotSetPageCount|number}}
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tagAccountVariablesColumnsToDisplay; sticky: true;"></tr>
          <tr mat-row
              (click)="handleTagAccountVariableRowClick(i)"
              [ngClass]="{'not-selected': selectedVariableRowIndex !== null && i !== selectedVariableRowIndex, 'selected': selectedVariableRowIndex !== null && i === selectedVariableRowIndex}"
              *matRowDef="let tag; columns: tagAccountVariablesColumnsToDisplay; index as i;"
              class="tag-account-variable-row"
          >
          </tr>
        </table>
        <div class="vi-table-zero-state" *ngIf="((selectedTag$ | async)?.tagName !== null) && tagAccountVariablesDataSource.data.length === 0">
            <div class="vi-zero-state-text" *ngIf="variableTableState !== EFilterSpinnerState.Loading">
              The selected tag account does not have any variables.
            </div>
        </div>
      </div>
    </div>
    <div class="vi-pages-table-container"
         *ngIf="displayPagesScannedSection"
         #pagesTableSectionScrollTo>
      <div class="vi-table-section-header vi-pages-scanned" >
        <filter-spinner [state]="pageTableState"></filter-spinner>
        <span>Pages Scanned</span>
        <op-audit-report-export-menu
          [auditId]="auditId"
          [runId]="runId"
          [tableCompletedState]="pageTableState"
          [hideExportFullReportItem]="true"
          [config]="exportReportConfig">
        </op-audit-report-export-menu>

        <op-switchable-column-menu [columnConfig]="PagesTableColumns"
                                   [storageKey]="CommonPagesConfigLocalStorageKey"
                                   [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
      </div>
      <!-- Tag Accounts, Set, Not Set Pages Table -->
      <div class="vi-pages-table" *ngIf="displayPagesTable">
        <table mat-table
               multiTemplateDataRows
               resizeableTable
               #pagesTableSort="matSort"
               matSort
               [dataSource]="pagesTableDataSource"
               [matSortActive]="TableColumn.PageUrl"
               matSortDirection="asc"
               matSortStart="desc"
        >
          <ng-container [matColumnDef]="TableColumn.PageUrl">
            <th mat-header-cell
                class="vi-sticky-header vi-left-header"
                *matHeaderCellDef resizeableColumn
                mat-sort-header>
              INITIAL PAGE URL
            </th>
            <td mat-cell
                *matCellDef="let page"
                class="no-left-padding"
            >
              <div class="url-cell-wrap">
                <div class="url-cell-text" opTableCellTooltipPrefix="View Page Details:" opTableCellTooltip="{{ page.pageUrl }}">
                  <div class="url-text">{{ page.pageUrl|hideProtocol }}</div>
                </div>
                <div class="open-drawer-icon-wrapper">
                  <mat-icon class="open-drawer-icon">ios_share</mat-icon>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
            <th mat-header-cell
                class="vi-sticky-header vi-left-header"
                *matHeaderCellDef resizeableColumn
                mat-sort-header>
              FINAL PAGE URL
            </th>
            <td mat-cell
                *matCellDef="let page"
                class="no-left-padding"
            >
              <div class="url-cell-wrap">
                <div class="url-cell-text" opTableCellTooltipPrefix="View Page Details:" opTableCellTooltip="{{ page.finalPageUrl }}">
                  <div class="url-text">{{ page.finalPageUrl|hideProtocol }}</div>
                </div>
                <div class="open-drawer-icon-wrapper">
                  <mat-icon class="open-drawer-icon">ios_share</mat-icon>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.PageLoadTime">
            <th mat-header-cell
                class="vi-pages-center-content vi-sticky-header center-text fixed-90"
                *matHeaderCellDef
                mat-sort-header
                [matTooltip]="PageLoadColumnTooltip"
                [matTooltipPosition]="'above'">
              PAGE LOAD TIME (sec)
            </th>
            <td mat-cell *matCellDef="let page" class="center-text">
              <div class="page-load-time-container {{getLoadTimeClass(page.pageLoadTime)}}">
                {{page.pageLoadTime|number}}
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
            <th mat-header-cell
                class="vi-pages-center-content vi-sticky-header center-text fixed-90"
                *matHeaderCellDef
                mat-sort-header>
              FINAL PAGE STATUS CODE
            </th>
            <td mat-cell *matCellDef="let page" class="center-text">
              <div class="page-status-code-container {{getStatusClass(page.finalPageStatusCode)}}"
                   [matTooltip]="PageStatusCodeTooltipMap[page.finalPageStatusCode] || null"
                   matTooltipPosition="above"
                   matTooltipShowDelay="250"
              >
                {{page.finalPageStatusCode}}
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.VariableCount">
            <th mat-header-cell
                class="vi-pages-center-content vi-sticky-header center-text fixed-90"
                *matHeaderCellDef
                mat-sort-header>
              # VARIABLES SET
            </th>
            <td mat-cell *matCellDef="let page" class="center-text">
              <div class="vi-pages-center-content">
                {{page.variableCount|number}}
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.RequestCount">
            <th mat-header-cell
                class="vi-pages-center-content vi-sticky-header center-text fixed-90"
                *matHeaderCellDef
                mat-sort-header>
              # OF REQUESTS
            </th>
            <td mat-cell *matCellDef="let page" class="center-text">
              <div class="vi-pages-center-content">
                {{page.requestCount|number}}
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.VariableValue">
            <th mat-header-cell
                class="vi-pages-center-content vi-sticky-header center-text"
                *matHeaderCellDef
                mat-sort-header>
              VALUE OF VARIABLE
            </th>
            <td mat-cell *matCellDef="let page" class="center-text">
              <div class="vi-pages-center-content">
                {{page.variableValue}}
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="pagesTableDisplayedColumns$ | async; sticky: true"></tr>
          <tr mat-row
              *matRowDef="let page; columns: pagesTableDisplayedColumns$ | async;"
              class="vi-pages-row"
              [ngClass]="{'vi-selected-page': pageIdOpenInPageDetails === page.pageId}"
              (click)="openPageDetails(page)"
          >
          </tr>
        </table>
      </div>
      <!-- Unique Values Table -->
      <div class="vi-unique-values-table" *ngIf="displayUniqueValuesTable">
        <table mat-table
               multiTemplateDataRows
               #uniqueValuesTableSort="matSort"
               resizeableTable
               [adjustColumnSizesOnInit]="false"
               [adjustColumnSizesOnChanges]="false"
               matSort
               [dataSource]="uniqueValuesPagesDataSource"
               [matSortActive]="TableColumn.PageUrl"
               matSortDirection="asc"
               matSortStart="desc">
          <ng-container matColumnDef="expand">
            <th mat-header-cell
                *matHeaderCellDef
                class="vi-sticky-header"></th>
            <td mat-cell *matCellDef="let value">
              <div class="icon-wrap">
                <mat-icon class="expand-arrow"
                          [class.expanded-row]="expandedValue === value"
                >
                  keyboard_arrow_right
                </mat-icon>
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.PageUrl">
            <th mat-header-cell
                *matHeaderCellDef
                class="vi-sticky-header vi-left-header"
                mat-sort-header>
              INITIAL PAGE URL
            </th>
            <td mat-cell *matCellDef="let value" [attr.colspan]="findItem((pagesTableDisplayedColumns$ | async), 'final_page_url') ? 2 : 1">
              <div class="unique-value-url-container">
                <span class="no-wrap">{{value.pageCount}} URL{{value.pageCount !== 1 ? 's' : ''}} with value of</span>
                <span class="vi-text-highlight"
                      matTooltip="{{value.variableValue}} (click to copy)"
                      matTooltipShowDelay="500"
                      matTooltipPosition="above"
                      ngxClipboard
                      [cbContent]="value.variableValue"
                      (click)="handleVariableValueClick($event)"
                >
                  {{value.variableValue === '' ? '&nbsp;' : value.variableValue}}
                </span>
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
            <th mat-header-cell
                class="vi-sticky-header vi-left-header"
                *matHeaderCellDef
                mat-sort-header>
              FINAL PAGE URL
            </th>
            <td mat-cell *matCellDef="let value" [hidden]="findItem((pagesTableDisplayedColumns$ | async), 'page_url')">
              <div class="unique-value-url-container">
                <span class="now-">{{value.pageCount}} URL{{value.pageCount !== 1 ? 's' : ''}} with value of</span>
                <span class="vi-text-highlight"
                      matTooltip="{{value.variableValue}} (click to copy)"
                      matTooltipShowDelay="500"
                      matTooltipPosition="above"
                      ngxClipboard
                      [cbContent]="value.variableValue"
                      (click)="handleVariableValueClick($event)"
                >
                  {{value.variableValue === '' ? '&nbsp;' : value.variableValue}}
                </span>
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.PageLoadTime">
            <th mat-header-cell
                class="vi-sticky-header"
                *matHeaderCellDef>
              PAGE LOAD TIME (sec)
            </th>
            <td mat-cell *matCellDef="let value">
              <div class="page-load-time-container">
                {{value.loadTime|number}}
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
            <th mat-header-cell
                class="vi-sticky-header"
                *matHeaderCellDef>
              FINAL PAGE STATUS CODE
            </th>
            <td mat-cell *matCellDef="let value">
              <div class="page-status-code-container vi-unique-value-center-text {{getStatusClass(value.finalPageStatusCode)}}">
                {{value.statusCode}}
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.RequestCount">
            <th mat-header-cell
                class="vi-sticky-header"
                *matHeaderCellDef>
              # OF REQUESTS
            </th>
            <td mat-cell *matCellDef="let value">
              <div class=" vi-unique-value-center-text">
                {{value.tagWithVariableValueCount}}
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="TableColumn.VariableValue">
            <th mat-header-cell
                class="vi-sticky-header"
                mat-sort-header
                *matHeaderCellDef>
              VALUE OF VARIABLE
            </th>
            <td mat-cell *matCellDef="let value">
              <div class="vi-variable-value"
                   matTooltip="{{value.variableValue}} (click to copy)"
                   matTooltipShowDelay="500"
                   matTooltipPosition="above"
                   ngxClipboard
                  [cbContent]="value.variableValue"
                  (click)="handleVariableValueClick($event)"
              >
                {{value.variableValue}}
              </div>
            </td>
          </ng-container>
          <!-- Unique Value expanded row -->
          <ng-container matColumnDef="expandedValue">
            <td mat-cell *matCellDef="let value" [attr.colspan]="(pagesTableDisplayedColumns$ | async).length">
              <div class="vi-unique-value-pages-content"
                   [@valueExpand]="value === expandedValue ? 'expanded' : 'collapsed'">
                <mat-spinner diameter="20" strokeWidth="3" *ngIf="pageTableState === EFilterSpinnerState.Loading">Loading</mat-spinner>

                <div class="vi-unique-value-expanded-row-content"
                     [ngClass]="{'vi-selected-unique-page': pageIdOpenInPageDetails === page.pageId}"
                     *ngFor="let page of expandedValuePages; let i = index;">
                  <div class="vi-unique-value-expand"></div>
                  <div *ngIf="findItem((pagesTableDisplayedColumns$ | async), CommonReportsPagesTableColumns.PageUrl)" class="vi-unique-value-page_url vi-unique-cell vi-clickable-cell"
                       (click)="openPageDetails(page)">
                    <div class="url-cell-wrap">
                      <div class="url-cell-text" opTableCellTooltipPrefix="View Page Details:" opTableCellTooltip="{{ page.pageUrl }}">{{ page.pageUrl|hideProtocol }}</div>
                      <div class="open-drawer-icon-wrapper">
                        <mat-icon class="open-drawer-icon">ios_share</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="findItem((pagesTableDisplayedColumns$ | async), CommonReportsPagesTableColumns.FinalPageUrl)" class="vi-unique-value-page_url vi-unique-cell vi-clickable-cell"
                       (click)="openPageDetails(page)">
                    <div class="url-cell-wrap">
                      <div class="url-cell-text" opTableCellTooltipPrefix="View Page Details:" opTableCellTooltip="{{ page.finalPageUrl }}">{{ page.finalPageUrl|hideProtocol }}</div>
                      <div class="open-drawer-icon-wrapper">
                        <mat-icon class="open-drawer-icon">ios_share</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="findItem((pagesTableDisplayedColumns$ | async), CommonReportsPagesTableColumns.PageLoadTime)" class="vi-unique-value-page_load_time vi-unique-cell vi-unique-strict-cell {{getLoadTimeClass(page.pageLoadTime)}}">{{page.pageLoadTime}}</div>
                  <div *ngIf="findItem((pagesTableDisplayedColumns$ | async), CommonReportsPagesTableColumns.FinalPageStatusCode)" class="vi-unique-value-final_page_status_code vi-unique-cell vi-unique-strict-cell {{getStatusClass(page.finalPageStatusCode)}}">{{page.finalPageStatusCode}}</div>
                  <div *ngIf="findItem((pagesTableDisplayedColumns$ | async), CommonReportsPagesTableColumns.RequestCount)" class="vi-unique-value-request_count vi-unique-cell vi-unique-strict-cell">{{page.tagWithVariableValueCount}}</div>
                  <div *ngIf="findItem((pagesTableDisplayedColumns$ | async), CommonReportsPagesTableColumns.VariableValue)" class="vi-unique-value-variable_value vi-unique-cell vi-unique-strict-cell"
                       (click)="copyToClipboard(value.variableValue)"
                       [matTooltip]="copyToClipboardTooltipText(value.variableValue)"
                       matTooltipClass="vi-multi-line-tooltip"
                       matTooltipPosition="above"
                       [matTooltipShowDelay]="1000"
                       (click)="handleVariableValueClick($event)"
                  >
                    <div class="vi-unique-value-value-wrapper">
                      {{value.variableValue}}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="pagesTableDisplayedColumns$ | async; sticky: true"></tr>
          <tr mat-row
              *matRowDef="let value; columns: pagesTableDisplayedColumns$ | async;"
              (click)="expandUniqueValueRow(value)"
              class="vi-unique-value-row"
              [class.expanded-row]="expandedValue === value"
          >
          </tr>
          <tr mat-row
              *matRowDef="let row; columns: uniqueValuesPagesExpandedColumnsToDisplay"
              class="vi-unique-value-pages-row"></tr>
        </table>
      </div>
      <mat-paginator class="vi-pages-table-paginator"
                     [hidden]="pageTableState === 'loading'"
                     [length]="pagesTablePagination.totalCount"
                     [pageSize]="pagesTablePagination.pageSize"
                     [hidePageSize]="true"
                     (page)='loadPage($event)'>
      </mat-paginator>
    </div>
  </div>
</div>
