import { Injectable, OnDestroy } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Observable, Subject } from 'rxjs';
import {
  IAuditReportJSFileChangesApiPostBody,
  IAuditReportJSFileChangesFilePagesApiPostBody,
  IJSFileChangesPagesResponse, IJSFileChangesPagesTableState,
  IJSFileChangesResponse,
  IJSFileChangesSummary
} from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { takeUntil } from 'rxjs/operators';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class JsFileChangesService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getSummary(auditId: number, runId: number, body?: IAuditReportJSFileChangesApiPostBody): Observable<IJSFileChangesSummary> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/file-changes`, body);
  }

  getFileChanges(auditId: number, runId: number, pagination: any, filters?: IAuditReportJSFileChangesApiPostBody): Observable<IJSFileChangesResponse> {
    const queryParams = `?page=${pagination.pagination.page}&size=${pagination.pagination.size}&sortBy=${pagination.sort.sortBy}&sortDesc=${pagination.sort.sortDesc}`;
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/file-changes/files${queryParams}`, filters);
  }

  getPages(auditId: number, runId: number, tableState: IJSFileChangesPagesTableState, apiFilters?: IAuditReportJSFileChangesApiPostBody): Observable<IJSFileChangesPagesResponse> {
    const queryParams = `?page=${tableState.pagination.page}&size=${tableState.pagination.size}&sortBy=${tableState.sort.sortBy}&sortDesc=${tableState.sort.sortDesc}`;
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/file-changes/pages${queryParams}`, apiFilters);
  }

  getSpecificPages(auditId: number, runId: number, tableState: IJSFileChangesPagesTableState, apiFilters?: IAuditReportJSFileChangesFilePagesApiPostBody): Observable<IJSFileChangesPagesResponse> {
    const queryParams = `?page=${tableState.pagination.page}&size=${tableState.pagination.size}&sortBy=${tableState.sort.sortBy}&sortDesc=${tableState.sort.sortDesc}`;
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/file-changes/file-pages${queryParams}`, apiFilters);
  }
}
