<div class="op-standards-selector-row">
  <div class="left-side">
    <button class="standard-name" (click)="editStandard.emit(standard)">
      <span class="standard-name-underline">{{ standard?.name }}</span>
      <mat-icon class="edit-icon">edit</mat-icon>
    </button>
  </div>
  <div class="right-side">
    <op-label-manager [compactView]="true"
                      [selectedLabels]="standard?.labels"
                      [chipCountOnly]="true"
                      [isReadOnly]="true">
    </op-label-manager>
    <button *ngIf="!isSelected && (availableConsentCatType === standard?.type || !availableConsentCatType)"
            (click)="addToList.emit(standard)"
            class="add-to-list-btn">
      assign
      <mat-icon class="add-to-list-arrow-icon">
        arrow_circle_up
      </mat-icon>
    </button>
    <div *ngIf="!isSelected && availableConsentCatType && availableConsentCatType !== standard?.type"
         class="conflicting-type">
      <mat-icon class="conflicting-type-icon">warning_amber</mat-icon>
      Conflicting Type
    </div>
    <button *ngIf="isSelected" (click)="removeFromList.emit(standard)" class="remove-from-list-btn">
      <mat-icon class="remove-icon">highlight_off</mat-icon>
    </button>
  </div>
</div>
