import { Component, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IButton } from '../../../../models/commons';
import { ITagInitiatorNode } from '@app/components/shared/components/tag-initiators/tag-initiators.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-initiators-full-screen',
  templateUrl: './tag-initiators-full-screen.component.html',
  styleUrls: ['./tag-initiators-full-screen.component.scss']
})
export class TagInitiatorsFullScreenComponent {

  rightFooterButtons: IButton[] = [{
    label: 'Close',
    action: () => {
      this.closeModal();
    },
    primary: true
  }];

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public payload: { initiatorsData: ITagInitiatorNode },
  ) { }

  closeModal(): void {
    this.dialogRef.close();
  }
}
