import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener, OnInit, OnChanges, AfterViewInit,
} from '@angular/core';
import * as d3 from 'd3';
import { ScaleTime, ScaleLinear, Selection } from 'd3';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  ISparklineChartConfig,
  ISparklineChartData,
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sparkline-chart',
  templateUrl: './sparkline-chart.component.html',
  styleUrls: ['./sparkline-chart.component.scss']
})
export class SparklineChartComponent implements OnInit, OnChanges, AfterViewInit {
  private isLoaded = false;

  svgWidth: number;
  svgHeight: number;
  container: Selection<any, any, any, any>;
  svg;  // main svg element that groups are all appended to
  line; // line graph of data points
  areaChart;
  margin;
  x: ScaleTime<any, any, never>;  // x-scale
  y: ScaleLinear<any, any, any>;  // y-scale
  g;
  chartConfig: ISparklineChartConfig = null;

  @Input() config: ISparklineChartConfig;
  @Input() uniqueIdentifier?: string = '';
  @Output() sectionClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  private resize$ = new Subject();
  private destroy$ = new Subject();

  ngOnInit(): void {
    this.resize$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.createChart();
    });
  }

  ngOnChanges(): void {
    if (this.isLoaded) {
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit() {
    this.processData();
    // In some instances the DOM doesn't appear to have loaded with the report elements
    // that we grab the widths from. Pushing it to the next cycle appears to solve the
    // issue, so wrapping it in a timeout as a fix.
    setTimeout(() => {
      this.createChart();
      this.isLoaded = true;
    });
  }

  processData() {
    if (this.chartConfig === null) this.chartConfig = { ...this.config };
    this.chartConfig.data = (this.config.data as ISparklineChartData[]).reduce((acc: ISparklineChartData[], item: ISparklineChartData) => {
      const sequence = item.sequence
      const value = item.value;

      const updatedItem: ISparklineChartData = { sequence, value };
      acc.push(updatedItem);

      return acc;
    }, []);
  }

  createChart(): void {
    this.createMargins();
    this.createSvg();
    this.createScales();
    
    let allValuesAreZero = (this.chartConfig.data as ISparklineChartData[]).every(p => p.value === 0) //checks to see if values are all zero
    
    
    let points = allValuesAreZero 
      ? this.chartConfig.data.map((p: ISparklineChartData) =>  ({...p, value: 0.1})) //in order to see the vertical lines we cant plot straight zeros so we return a new array of points with a value of one
      : this.chartConfig.data
    
    this.createAreaChart(allValuesAreZero);
    this.createLineChart(points);
    this.createAreaChartGradient();
    this.createSeperatorLines(points);
  }

  createMargins() {
    this.margin = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    };
  }

  createSvg() {
    const svgContainer = `.svg-container${this.uniqueIdentifier !== undefined ? '-' + this.uniqueIdentifier : ''}`;
    this.container = d3.select(svgContainer).html('');
    if (this.container.empty()) { // If selection is empty, any methods called on it will result in error
      return;
    }
    this.svgHeight = parseInt(this.container.style('height'));
    this.svgWidth = parseInt(this.container.style('width'));

    this.svg = this.container.append('svg')
      .attr('width', this.svgWidth)
      .attr('height', this.svgHeight);

    this.g = this.svg.append('g');
  }

  createScales() {
    const rangeXStart = 0;
    const rangeXEnd = this.svgWidth - this.margin.left - this.margin.right;
    const rangeYStart = this.svgHeight - this.margin.top - this.margin.bottom;
    const rangeYEnd = 0;

    this.x = d3.scaleTime()
      .rangeRound([rangeXStart, rangeXEnd]);  // range is width of graph

    this.y = d3.scaleLinear()
      .rangeRound([rangeYStart, rangeYEnd]);  // range is height of graph
  }

  createAreaChart(allValuesAreZero: boolean) {
    // Plot each point from data, where x is the order and area is between 0 and data point value
    this.areaChart = d3.area()
      .x((d: any) => this.x(d.sequence))
      .y0(this.y(0))
      .y1((d: any) => this.y(d.value));

    this.x.domain(d3.extent( this.chartConfig.data, (d: any) => d.sequence)); // add domain to time scale to display in sequence
    this.y.domain([0, allValuesAreZero ? 5 : d3.max(this.chartConfig.data, (d: any) => d.value * 1.05)]);  // add domain to value scale for min/max values

    // Attach gradient fill for area
    this.g.append('path')
      .attr('fill', 'url(#linear-gradient)')
      .attr('d', this.areaChart(this.chartConfig.data));
  }

  // Draw line at top of area chart
  createLineChart(points) {
    this.line = d3.line()
      .x((d: any) => this.x(d.sequence))
      .y((d: any) => this.y(d.value));

    this.g.append('path')
      .attr('class', 'sparkline-line-chart')
      .attr('d', this.line((points as any)));
  }

  createSeperatorLines(points) {

    for (let i = 1; i <= points.length - 2; i++) {

      const seperatorPointsArray = [{ x: i, y: points[i].value + .4 }, { x: i, y: 0 }]

      const seperatorLineOne = d3.line()
        .x((d: any) => this.x(d.x))
        .y((d: any) => this.y(d.y))

      this.g.append('path')
        .attr('class', 'seperatorLine')
        .attr('stroke-width', '2px')
        .attr('d', seperatorLineOne(seperatorPointsArray as any))

    }
  }

  createAreaChartGradient() {
    const gradient = this.svg
      .append('linearGradient')
      .attr('id', 'linear-gradient')
      .attr('gradientTransform', 'rotate(90)');

    gradient
      .append('stop')
      .attr('class', 'sparkline-chart-gradient-stop-one')
      .attr('offset', '0%');

    gradient
      .append('stop')
      .attr('class', 'sparkline-chart-gradient-stop-two')
      .attr('offset', '100%');
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resize$.next();
  }
}
