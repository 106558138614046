<mat-icon>check</mat-icon>
<!--Alert created with at least one subscriber-->
<p class="success-text" *ngIf="data.emails?.length > 0 && data.alertCreated"><span class="highlight-text" *ngIf="data.alertCreated">"{{data.name}}" alert has been created and you are now subscribed to it</span> - an alert email will be sent to <span class="highlight-text">{{data.emails}}</span> if an issue is found on future runs. Customize the alert if you want to change the threshold logic, add a custom alert message, {{accountLevelAlert ? 'or add notified users' : 'add notified users, or apply this alert to other audits'}}.{{data.noReprocessOption ? '' : ' Rerun this report if you want the alert reflected in the report.'}}</p>
<!--Alert created without any subscribers-->
<p class="success-text" *ngIf="!data.emails?.length && data.alertCreated"><span class="highlight-text" *ngIf="data.alertCreated">"{{data.name}}" alert has been created.</span> Add a subscriber to have an alert email sent if an issue is found on future runs. Customize the alert if you want to change the threshold logic, add a custom alert message, {{accountLevelAlert ? 'or add notified users' : 'add notified users, or apply this alert to other audits'}}.{{data.noReprocessOption ? '' : ' Rerun this report if you want the alert reflected in the report.'}}</p>
<!--Subscribe to existing alert-->
<p class="success-text" *ngIf="data.email && data.subscribed"><span class="highlight-text">You are now subscribed to the {{data.name}} alert(s)</span> - an alert email will be sent to <span class="highlight-text">{{data.email}}</span> if an issue is found on future runs. Customize the alert if you want to change the threshold logic, add a custom alert message, {{accountLevelAlert ? 'or add notified users' : 'add notified users, or apply this alert to other audits'}}.{{data.noReprocessOption ? '' : ' Rerun this report if you want the alert reflected in the report.'}}</p>
<button mat-flat-button
        class="highlight-text"
        (click)="editAlert()"
        [attr.op-selector]="opSelector.snackbarCustomizeLink"
>View or Customize Alert</button>
<mat-icon class="close-icon" (click)="close()">close</mat-icon>
