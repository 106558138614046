import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { Observable, Subject } from 'rxjs';
import { catchError, filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { AccountsService } from '@app/components/account/account.service';
import { StringUtils } from '@app/components/utilities/StringUtils';
import { ICommonTableState } from '@app/components/shared/components/export-report/export-reports.models';
import {
  AuditExportReportService
} from '@app/components/shared/components/audit-report-export/audit-export-report.service';
import {
  ExportReportErrorSnackbarComponent
} from '@app/components/shared/components/export-report/export-report-error-snackbar/export-report-error-snackbar.component';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  EFreeTrialAdModalType,
  IFreeTrialAdModalPayload
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import {
  FreeTrialAdModalComponent
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ExportReportModalComponent
} from '@app/components/shared/components/export-report/export-report-modal/export-report-modal.component';
import {
  ExportModal
} from '@app/components/shared/components/export-report/export-report-modal/export-report-modal.constants';

export interface ICopyConfigCell<T = any> {
  property?: string;
  tableColumnName?: string;
  title?: string;
  displayLike?: (v: T, additionalParams?: {[key: string]: any}) => string | number;
  additionalParams?: {[key: string]: any}
}

export interface IAuditReportExportMenuData<LocalFilters = {[key: string]: any}, PageRow = {[key: string]: any}> {
  tableName: string;
  exportType: string;
  totalRows: number;
  filteredRows: number;
  tableState: ICommonTableState;
  filters: IAuditReportApiPostBody & LocalFilters;
  specificExportTypes?: {
    all?: string;
    filtered?: string;
    view?: string;
  };
  additionalParams?: Object;
  dataToCopy?: {
    config: ICopyConfigCell<PageRow>[];
    data: { [key: string]: any }[];
    displayedColumns$?: Observable<string[]>;
  },
}

interface IExportResponse {
  exportId: number;
}

@Component({
  selector: 'op-audit-report-export-menu',
  templateUrl: './audit-report-export-menu.component.html',
  styleUrls: ['./audit-report-export-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AuditReportExportMenuComponent
  implements OnInit, OnDestroy {
  @HostBinding('class') @Input() tableCompletedState: EFilterSpinnerState;
  @Input() auditId: number;
  @Input() runId: number;
  @Input() config: IAuditReportExportMenuData;
  @Input() transformSortingToSnakeCase = true;
  @Input() hideExportFullReportItem = false;
  @Input() additionalParams: any;

  @Output() exportFullReport = new EventEmitter();
  @Output() exportFilteredReport = new EventEmitter();
  @Output() exportShownReport = new EventEmitter();

  protected destroy$ = new Subject;

  opened: boolean;
  fullReportLoading = false;
  filteredReportLoading = false;
  shownReportLoading = false;

  unsubscribe$ = new Subject();
  isVisitor: boolean;

  constructor(
    private snackbar: MatSnackBar,
    private auditExportReportService: AuditExportReportService,
    private accountsService: AccountsService,
    private applicationChromeService: ApplicationChromeService,
    private modalService: OpModalService,
  ) {}

  ngOnInit() {
    this.applicationChromeService.isVisitorMode$.subscribe(isVisitor => {
      this.isVisitor = isVisitor;
    });

    this.exportFullReport.pipe(
      filter(() => !this.fullReportLoading),
      switchMap(() => this.fullReport$),
      tap(exportResponse => {
        if (exportResponse) {
          this.showExportProgress(exportResponse.exportId);
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe({
      error: () => this.showErrorSnackbar()
    });

    this.exportFilteredReport.pipe(
      filter(() => !this.filteredReportLoading),
      switchMap(() => this.filteredReport$),
      tap(exportResponse => {
        if (exportResponse) {
          this.showExportProgress(exportResponse.exportId);
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      error: () => this.showErrorSnackbar()
    });

    this.exportShownReport.pipe(
      filter(() => !this.shownReportLoading),
      switchMap(() => this.shownReport$),
      tap(exportResponse => {
        if (exportResponse) {
          this.showExportProgress(exportResponse.exportId);
        }
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      error: () => this.showErrorSnackbar()
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected showExportProgress(exportId: number) {
    this.accountsService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        const message = user.email;

        this.modalService.openModal(ExportReportModalComponent, {
            width: ExportModal.width,
            height: ExportModal.height,
            disableClose: true,
            data: {
              message: message,
              reportItem: {},
              exportId: exportId,
            }
          }, 'exported-vendors-modal');
        }
      )
  }

  private showErrorSnackbar() {
    return this.snackbar
      .openFromComponent(
        ExportReportErrorSnackbarComponent, {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: 'email-report-error-snackbar',
          data: {}
        }
      );
  }

  // Display upgrade account modal if visitor
  handleExportFullReportClick(): void {
    if (this.isVisitor) {
      this.openUpgradeAccountModal();
    } else {
      this.exportFullReport.emit();
    }
  }

  handleExportFilteredReportClick(): void {
    if (this.isVisitor) {
      this.openUpgradeAccountModal();
    } else {
      this.exportFilteredReport.emit();
    }
  }

  handleExportShownReportClick(): void {
    if (this.isVisitor) {
      this.openUpgradeAccountModal();
    } else {
      this.exportShownReport.emit();
    }
  }

  openUpgradeAccountModal(): void {
    const data: IFreeTrialAdModalPayload = {
      type: EFreeTrialAdModalType.EXPORT,
    };

    this.modalService.openModal(FreeTrialAdModalComponent, { data });
  }

  get isNumericTotalRows(): boolean {
    /* Infinity is used when we don't know the exact amount of rows (but there's at least one)
     * For example, Privacy Tags :: Tag Compliance table */
    return this.config.totalRows > 0 && this.config.totalRows < Infinity;
  }

  get appliedFilters() {
    return Object.values(this.config.filters).length > 0;
  }

  get rowsInView() {
    return (this.config.filteredRows > (this.config.tableState.page + 1) * this.config.tableState.size)
      ? (this.config.tableState.page + 1) * this.config.tableState.size
      : this.config.filteredRows;
  }

  copyToClipboard() {
    if (this.config.dataToCopy.displayedColumns$) {
      this.config.dataToCopy.displayedColumns$
        .pipe(first())
        .subscribe(list => this.auditExportReportService.copyView(this.config.dataToCopy.data, this.config.dataToCopy.config, list));
    } else {
      this.auditExportReportService.copyView(this.config.dataToCopy.data, this.config.dataToCopy.config);
    }
  }

  private get fullReport$() {
    this.fullReportLoading = true;

    return this.auditExportReportService.send(
      this.auditId,
      this.runId,
      this.config.specificExportTypes?.all || this.config.exportType,
      this.preparedTableState,
      this.additionalParams
     ).pipe(
        tap((returnValue: IExportResponse) => {
        this.fullReportLoading = false;
        return returnValue;
      }),
      catchError(error => {
        console.error(error);
        this.fullReportLoading = false;
        throw error;
      })
    );
  }

  private get filteredReport$() {
    this.filteredReportLoading = true;

    return this.auditExportReportService.send(
      this.auditId,
      this.runId,
      this.config.specificExportTypes?.filtered || this.config.exportType,
      this.preparedTableState,
      {...this.config.filters, ...this.additionalParams}
    ).pipe(
      tap((returnValue: IExportResponse) => {
        this.filteredReportLoading = false;
        return returnValue;
      }),
      catchError(error => {
        console.error(error);
        this.filteredReportLoading = false;
        throw error;
      })
    );
  }

  private get shownReport$() {
    this.shownReportLoading = true;

    return this.auditExportReportService.send(
      this.auditId,
      this.runId,
      this.config.specificExportTypes?.view || this.config.exportType,
      this.preparedTableState,
      {...this.config.filters, ...this.additionalParams},
      false
    ).pipe(
      tap((returnValue: IExportResponse) => {
        this.shownReportLoading = false;
        return returnValue;
      }),
      catchError(error => {
        console.error(error);
        this.shownReportLoading = false;
        throw error;
      })
    );
  }

  private get preparedTableState(): ICommonTableState {
    if (this.transformSortingToSnakeCase && this.config.tableState.sortBy) {
      const tableState: ICommonTableState = JSON.parse(JSON.stringify(this.config.tableState));
      tableState.sortBy = StringUtils.camelCaseToSnakeCase(tableState.sortBy);
      return tableState;
    }

    return this.config.tableState;
  }
}
