<form [formGroup]="formData">
  <span>
    <mat-form-field class="styled-mat-input" appearance="outline">
      <mat-label>Variable</mat-label>
      <input matInput formControlName="variable">
      <mat-error *ngIf="variableControl.touched && variableControl.invalid">
        <mat-icon>warning_amber</mat-icon>
        Field can't be empty
      </mat-error>
    </mat-form-field>

    <div class="match-as-regex"
        [class.error-shown]="variableControl.touched && variableControl.invalid">
      <mat-checkbox color="primary" formControlName="variableMatchAsRegex">
        Match as RegEx
      </mat-checkbox>
    </div>
  </span>

  <mat-form-field class="styled-mat-input" appearance="outline">
    <mat-label>Operator</mat-label>
    <mat-select formControlName="matchType" (selectionChange)="operatorChanged()">
      <mat-option class="slim-mat-option" *ngFor="let operator of TagVariableOperators" [value]="operator.value">
        {{ operator.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="matchTypeControl.touched && matchTypeControl.invalid">
      <mat-icon>warning_amber</mat-icon>
      Field can't be empty
    </mat-error>
  </mat-form-field>

  <mat-form-field class="styled-mat-input selector-field" appearance="outline">
    <mat-label>Selector</mat-label>
    <input matInput formControlName="selectorType" [matAutocomplete]="auto"/>
    <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySelectorTypeFn" (opened)="panelOpened()">
      <cdk-virtual-scroll-viewport #virtualScrollSelect itemSize="48" style="height: 240px" minBufferPx="96" maxBufferPx="220">
        <ng-container *cdkVirtualFor="let selector of selectors$ | async; let i = index">
          <ng-container *ngIf="i < (staticSelectors$ | async).length">
            <mat-option class="slim-mat-option"
                        [innerHTML]="selector.name | highlightTextPipe: selectorSearchText"
                        [value]="selector.value">
            </mat-option>
          </ng-container>

          <ng-container *ngIf="(tagSelectors$ | async).length && i === (staticSelectors$ | async).length">
            <mat-option class="slim-mat-option no-events">Tags</mat-option>
          </ng-container>

          <ng-container *ngIf="(tagSelectors$ | async).length && (i > (staticSelectors$ | async).length || (staticSelectors$ | async).length === 0)">
            <mat-option class="slim-mat-option pl-32"
                        [value]="selector.id"
                        [innerHTML]="selector.name | highlightTextPipe: selectorSearchText">
            </mat-option>
          </ng-container>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>

    <mat-error *ngIf="selectorTypeControl.touched && selectorTypeControl.invalid">
      <mat-icon>warning_amber</mat-icon>
      Field can't be empty
    </mat-error>
  </mat-form-field>

  <mat-form-field class="styled-mat-input" appearance="outline">
    <mat-label>
      {{selectorTypeControl.value === 'String' ? 'Value' : 'Variable'}}
    </mat-label>
    <input matInput formControlName="value">
    <mat-error *ngIf="valueControl.touched && valueControl.invalid">
      <mat-icon>warning_amber</mat-icon>
      <span *ngIf="valueControl.hasError('number'); else requiredValueMessage">
        Should be a number
      </span>
      <ng-template #requiredValueMessage>
        <span>Field can't be empty</span>
      </ng-template>
    </mat-error>
  </mat-form-field>

  <mat-form-field class="styled-mat-input last-field" appearance="outline">
    <mat-label>Validation description</mat-label>
    <input matInput formControlName="validationDescription">
  </mat-form-field>

  <mat-icon class="remove-icon" (click)="onRemoveVariable.emit()">
    highlight_off
  </mat-icon>
</form>
