import {
  EChartDateRange,
  IFullscreenChartStatsItem
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';

export interface IDateRengeConfig {
  greaterThanOneMonth: boolean;
  greaterThanThreeMonths: boolean;
  greaterThanSixMonths: boolean;
  greaterThanNineMonths: boolean;
  greaterThanThirteenMonths: boolean;
}

const FalsyDateRengeConfig: IDateRengeConfig = {
  greaterThanOneMonth: false,
  greaterThanThreeMonths: false,
  greaterThanSixMonths: false,
  greaterThanNineMonths: false,
  greaterThanThirteenMonths: false
};

export function generateDateRangeConfig(dateRange: EChartDateRange): IDateRengeConfig {
  switch (dateRange) {
    case EChartDateRange.oneMonth:
      return FalsyDateRengeConfig;
    case EChartDateRange.threeMonth:
      return { ...FalsyDateRengeConfig, greaterThanOneMonth: true };
    case EChartDateRange.sixMonth:
      return { ...FalsyDateRengeConfig, greaterThanOneMonth: true, greaterThanThreeMonths: true };
    case EChartDateRange.nineMonth:
      return {
        ...FalsyDateRengeConfig,
        greaterThanOneMonth: true,
        greaterThanThreeMonths: true,
        greaterThanSixMonths: true
      };
    case EChartDateRange.thirteenMonth:
      return {
        ...FalsyDateRengeConfig,
        greaterThanOneMonth: true,
        greaterThanThreeMonths: true,
        greaterThanSixMonths: true,
        greaterThanNineMonths: true
      };
    default:
      FalsyDateRengeConfig;
  }
}

export function getDaysInDateRange(range: EChartDateRange): number {
  let days;

  if (range === EChartDateRange.oneMonth) {
    days = 30;
  } else if (range === EChartDateRange.threeMonth) {
    days = 90;
  } else if (range === EChartDateRange.sixMonth) {
    days = 180;
  } else if (range === EChartDateRange.nineMonth) {
    days = 270;
  } else if (range === EChartDateRange.thirteenMonth) {
    days = 390;
  }

  return days;
}

export function generateDateRangeFromDate(completedDate: string): EChartDateRange {
  const oneDay = 1000 * 60 * 60 * 24;
  const today = new Date();
  const runDate = new Date(completedDate);
  const msSinceRun = today.getTime() - runDate.getTime();
  const daysSinceRun = Math.round(msSinceRun / oneDay);

  if (daysSinceRun < 30) {
    return EChartDateRange.oneMonth;
  } else if (daysSinceRun < 90) {
    return EChartDateRange.threeMonth;
  } else if (daysSinceRun < 180) {
    return EChartDateRange.sixMonth;
  } else if (daysSinceRun < 270) {
    return EChartDateRange.nineMonth;
  } else if (daysSinceRun < 390) {
    return EChartDateRange.thirteenMonth;
  }

  return null;
}

export function statsToChartStatsItems({ min, max, average, median }): IFullscreenChartStatsItem[] {
  return [
    {
      title: 'Min',
      value: min,
    },
    {
      title: 'Max',
      value: max,
    },
    {
      title: 'Average',
      value: average,
    },
    {
      title: 'Median',
      value: median,
    },
  ];
}

