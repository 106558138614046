import { Route } from '@angular/router';
import { NoReadOnlyGuard } from '../core/permissions.guard';
import { ActionSetLibraryComponent } from './action-set-library.component';
import { ActionSetLibraryPaths, ActionSetStateNames } from './action-set-library.constants';

export const ActionSetLibraryRoutes: Route = {
  path: ActionSetLibraryPaths.base,
  canLoad: [ NoReadOnlyGuard ],
  component: ActionSetLibraryComponent,
  data: {
    stateName: ActionSetStateNames.base,
    title: 'Action Set Library',
    betaLabel: false,
  },
};
