import { Injectable } from '@angular/core';
import { CardTypes } from '@app/components/manage/cards/report-card-list/report-card-list.constants';
import { EDateFormats, formatDate, toUTC } from '@app/components/date/date.service';
import {
  IFrequencyWithNextRun,
  IReportCardScanStatus,
  IReportCardScanType,
  IScanModel,
} from '@app/components/manage/cards/manage-cards.constants';
import { AuditFrequencyFriendlyNames, EAuditFrequency } from '@app/components/audit/audit.constants';
import {
  EWebJourneyFrequency,
  WebJourneyFrequencyFriendlyNames
} from '@app/components/web-journey/web-journey.constants';
import { DataSourcesUtils, PAUSED_YEAR } from '@app/components/utilities/dataSourcesUtils';
import { StorageService } from '@app/components/shared/services/storage.service';

@Injectable()
export class ReportCardService {
  webJourneyFrequencies = WebJourneyFrequencyFriendlyNames;

  auditFrequencies = AuditFrequencyFriendlyNames;
  recurrenceEnabled: boolean = false;

  constructor(private storageService: StorageService) {
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
  }

  /**
   * Return the mat-icon name depending on scan type
   * @param model
   */
  getScanIcon(type: IReportCardScanType): string {
    switch (type) {
      case 'audit':
        return 'explore';
      case 'webJourney':
        return 'map';
    }
  }

  getScanType(type: IReportCardScanType): string {
    switch (type) {
      case 'audit':
        return 'Web Audit';
      case 'webJourney':
        return 'Web Journey';
    }
  }

  getScanTooltip(status: IReportCardScanStatus, type): string {
    if (type === 'audit') return '';

    if (type === 'webJourney') {
      switch (status) {
        case 'Ok':
          return 'It\'s all good';
        case 'ActionFailure':
          return 'Action failure';
        case 'RulesFailure':
          return 'Rule failure';
        default:
          return 'Waiting to run';
      }
    }
  }

  /**
   * Returns an object with the frequency and next run as readable strings
   * @param model - : IAuditReportCard | IWebJourneyReportCard | any
   */
   getFrequencyWithNextRun(model: IScanModel): IFrequencyWithNextRun {
    let frequency = { value: '', nextRun: '', frequencyString: '' };

    frequency.value = (model?.type === CardTypes.webJourney)
      ? this.webJourneyFrequencies[model?.options?.frequency]
      : model?.frequency?.charAt(0)?.toUpperCase() + model?.frequency?.slice(1);

    if (model?.type === CardTypes.audit) {
      frequency.value = this.auditFrequencies[model?.frequency];
    }

    // Next Run
    let nextRunDate = this.getNextRunDateFormatted(model);
    frequency.frequencyString = `${'FREQUENCY: <span class="frequency">' + frequency.value + '</span>' }`;

    //Frequency
    let nextRunYear: number = toUTC(new Date(nextRunDate)).getUTCFullYear();
    frequency.nextRun = `NEXT RUN: <span class="next-run">${(!this.isScheduled(model))
      ? 'Not Scheduled'
      : ((nextRunYear >= parseInt(PAUSED_YEAR)) ? 'Paused' : nextRunDate)}</span>`;

    return frequency;
  }

  /**
   * Returns an object with the frequency and next run as readable strings
   * @param model - : IAuditReportCard | IWebJourneyReportCard | any
   */
  getFrequencyStrings(model: IScanModel): IFrequencyWithNextRun {
    let frequency = { value: '', nextRun: '', frequencyString: '' };
    frequency.frequencyString = `${'FREQUENCY: <span class="frequency">' + model?.schedule?.description + '</span>' }`;

    const nextRunValue = model.type === CardTypes.audit ? model.nextRun : model.nextCheck;
    let nextRun: string = '';

    if (!nextRunValue || nextRunValue === null) {
      nextRun = 'Not scheduled';
    } else {
      const nextRunYear: number = toUTC(new Date(nextRunValue)).getUTCFullYear();
      nextRun = (nextRunYear >= parseInt(PAUSED_YEAR)) ? 'Not scheduled' : formatDate(nextRunValue, EDateFormats.dateTwelve);
    }

    frequency.nextRun = `${'NEXT RUN: <span class="next-run">' +  nextRun + '</span>' }`;
    return frequency;
  }

  /**
   * Takes the model and returns next run date as string if exists or 'Not scheduled'
   * @param model: IAuditReportCard | IWebJourneyReportCard | any
   */
  getNextRunDateFormatted(model: IScanModel): string {
    let nextRun = (model.type === CardTypes.audit) ? model.nextRun : model.nextCheck;

    return this.isPausedCard(model)
      ? 'Paused'
      : formatDate(new Date(nextRun), EDateFormats.dateTwelve);
  }

  isPausedCard(model: IScanModel): boolean {

    // Need to check date for the magical year 2038 denoted as the pause year
    let nextDate: string = ((model.type === CardTypes.audit) ? model?.nextRun : model?.nextCheck);
    if ((model.type === CardTypes.webJourney) && !(this.isScheduledJourney(model))) {
      return false;
    } else if ((model.type === CardTypes.audit) &&
      !(this.isScheduledAudit(model) &&
      (model.frequency.toLowerCase() !== EAuditFrequency.ONCE.toLowerCase()))) {
      return false;
    }

    // After checking if it is scheduled, check if it is paused
    return this.isPaused(nextDate);
  }

  isPaused(nextDate?: string | Date): boolean {
    return DataSourcesUtils.isPausedDate(nextDate);
  }

  canPause(model: IScanModel): boolean {
    if (this.recurrenceEnabled) {
      return !model?.schedule.isPaused;
    } else {
      // If item is NOT scheduled to run, it can't be paused
      if (model === undefined || model.type === undefined) return false;
      if (!this.isScheduled(model)) return false;
      return !this.isPausedCard(model);
    }
  }

  canResume(model: IScanModel): boolean {
    if (this.recurrenceEnabled) {
      return model?.schedule.isPaused;
    } else {
      // If item is NOT scheduled to run, it can't be resumed
      if (model === undefined || model.type === undefined) return false;
      if (!this.isScheduled(model)) return false;
      return this.isPausedCard(model);
    }
  }

  // Check to see if the audit or journey has a schedule (it does not take into account if it is paused)
  isScheduled(model: IScanModel): boolean {

    return model.type === CardTypes.webJourney
      ? (this.isScheduledJourney(model))
      : this.isScheduledAudit(model);
  }

  // Check to see if the audit has a schedule (it does not take into account if it is paused)
  isScheduledAudit(model: IScanModel): boolean {

    let frequency = (model?.frequency)
      ? model?.frequency
      : EAuditFrequency.ONCE;

    return frequency === EAuditFrequency.ONCE
      ? DataSourcesUtils.isOnceAndScheduledInFuture(model)
      : DataSourcesUtils.isAuditScheduled(frequency.toLowerCase());
  }

  // Check to see if the journey has a schedule (it does not take into account if it is paused)
  // Used to filter out Not-Scheduled journeys (we don't want to resume them)
  isScheduledJourney(model: IScanModel): boolean {

    let frequency = (model?.options?.frequency)
      ? model?.options?.frequency
      : (model?.frequency) ? model?.frequency : EWebJourneyFrequency.PAUSED; //NOTE: assuming "paused" (not scheduled) if everything comes back undefined.

    return frequency === EWebJourneyFrequency.ONCE
      ? DataSourcesUtils.isOnceAndScheduledInFuture(model)
      : DataSourcesUtils.isJourneyScheduled(frequency.toLowerCase());
  }

  isNotYetRun(model: IScanModel): boolean {
    let nextDate: Date = new Date((model.type === CardTypes.audit) ? model?.nextRun : model?.nextCheck);

    // If the last run is null and next run is in the past, then it has not been run yet.
    return (!(model.lastRun) && toUTC(nextDate) < toUTC(new Date()));
  }
}
