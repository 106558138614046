import { Subject } from 'rxjs';

export interface ICacheControls {
  (...args: string[]): any;
  cacheControls: CacheControls;
}
export class CacheControls {
  constructor(private map: Map<string, any>) {
  }

  removeAll() {
    this.map.forEach((value, key) => this.map.delete(key));
  }

  removeByArgs(...args) {
    const key = JSON.stringify(args);
    this.map.delete(key);
  }
}

export interface ICacheApiResponseOptions {
  liveTime?: number,
  resetCache?: Subject<any>
}
