import * as angular from 'angular';
import { Names } from '@app/moonbeamConstants';
import { Injectable } from '@angular/core';
import { CardType, CardTypes } from '@app/components/manage/cards/report-card-list/report-card-list.constants';
import { Router } from '@angular/router';
import { DataSourcesUrlBuilders, EManageCardsViewMode } from '@app/components/manage/cards/manage-cards.constants';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ERecurrencePresetNames } from '@app/components/shared/components/op-recurrence/op-recurrence.constants';

/**
 * Combines EWebJourneyFrequency from src/main/web/components/web-journey/web-journey.constants.ts
 * and EAuditFrequency  and src/main/web/components/audit/audit.constants.ts
 *
 * Duplicates ERunFrequency from src/main/web/components/usage/usage.models.ts
 */
export enum EReportCardsFrequency {
  NotScheduled = 'paused',
  Every15Minutes = '15 minutes',
  Hourly = '1 hour',
  Every6Hours = '6 hours',
  Every12Hours = '12 hours',
  Daily = 'daily',
  Weekly = 'weekly',
  Biweekly = 'biweekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  SemiAnnually = 'semiannually',
  Yearly = 'yearly',
  Once = 'once'
}

export interface IReportCardsCount {
  audit?: number;
  webJourney?: number;
  frequency?: {
    [key in EReportCardsFrequency]?: number;
  };
  presetType?: {
    [key in ERecurrencePresetNames]?: number;
  };
}

export interface IVisibleReportCards {
  audit?: boolean;
  webJourney?: boolean;
}

export interface ILabelsMap {
  [key: string]: Array<ILabel>;
}

export interface ILastVisibleCard {
  id: number;
  type: string;
  closestId: number;
  closestType: string;
}

@Injectable()
export class ManageCardsDataService {

  allLabels: Array<ILabel> = [];
  selectedLabelsMap: { [key: string]: Array<ILabel> } = {};

  visibleReportCards: IVisibleReportCards = {};
  reportCardsCount: IReportCardsCount = {
    frequency: {},
    presetType: {},
  };
  reportCardViewMode: EManageCardsViewMode;

  lastCard: any;
  multiSelectActive: boolean;

  private reportCardsCountSetSubject: Subject<null> = new Subject();
  public reportCardsCountSet$: Observable<null> = this.reportCardsCountSetSubject.asObservable();

  constructor(private router: Router,
              private storageService: StorageService,
              cacheResetService: CacheResetService,
              private labelsService: LabelService) {
    this.loadFromLocalStorage();
    cacheResetService.reset$.subscribe(() => this.cleanLocalStorage());
  }

  private saveToLocalStorage() {
    this.storageService.setValue(Names.GlobalStateKeys.visibleReportCards, this.visibleReportCards, StorageType.Local);
    this.storageService.setValue(Names.GlobalStateKeys.reportCardViewMode, this.reportCardViewMode, StorageType.Local, false);
  }

  private cleanLocalStorage() {
    this.storageService.removeValue(Names.GlobalStateKeys.visibleReportCards, StorageType.Local);
  }

  loadFromLocalStorage() {
    this.visibleReportCards = this.storageService.getValue<IVisibleReportCards>(Names.GlobalStateKeys.visibleReportCards, StorageType.Local);
    this.reportCardViewMode = this.storageService.getValue<EManageCardsViewMode>(Names.GlobalStateKeys.reportCardViewMode, StorageType.Local, false);

    if (this.hasObjectNullableProperty(this.visibleReportCards) || this.reportCardViewMode === null) {
      this.visibleReportCards = {
        audit: true,
        webJourney: true,
      };
      this.reportCardViewMode = (!this.reportCardViewMode) ? EManageCardsViewMode.Card : this.reportCardViewMode;
      this.saveToLocalStorage();
    }
  }

  getAllLabels(): ILabel[] {
    return this.allLabels;
  }

  loadLabels(): Observable<ILabel[]> {
    return this.labelsService.getLabels().pipe(
      tap((labels => {
        this.allLabels.splice(0, this.allLabels.length);
        this.allLabels.push.apply(this.allLabels, labels);
        return this.allLabels;
      })
    ));
  }

  getSelectedLabels(type: string): Array<ILabel> {
    if (typeof this.selectedLabelsMap[type] == 'undefined') {
      return [];
    }
    return this.selectedLabelsMap[type].filter(label => {
      return label.id;
    });
  }

  setSelectedLabels(type: string, newLabels: Array<ILabel>): void {
    this.selectedLabelsMap[type] = newLabels;
    this.saveToLocalStorage();
  }

  setVisibleReportCardsTypes(visibleReportCards: IVisibleReportCards) {
    this.visibleReportCards = angular.copy(visibleReportCards);
    this.saveToLocalStorage();
  }

  setReportCardViewMode(viewMode: EManageCardsViewMode) {
    this.reportCardViewMode = viewMode;
    this.saveToLocalStorage();
  }

  setReportCardsCount(reportCardsCount: IReportCardsCount) {
    this.reportCardsCount = reportCardsCount;
    this.reportCardsCountSetSubject.next();
  }

  isTypeVisible(type: CardType): boolean {
    switch (type) {
      case CardTypes.audit:
        return this.visibleReportCards.audit;
      case CardTypes.webJourney:
        return this.visibleReportCards.webJourney;
      default:
        return false;
    }
  }

  getCardViewMode(): EManageCardsViewMode {
    return this.reportCardViewMode;
  }

  private hasObjectNullableProperty(obj: IVisibleReportCards) {
    if (!obj) return true;

    for (let prop in obj) {
      if (obj.hasOwnProperty(prop) && obj[prop] == null) return true;
    }

    return false;
  }

  setLastCard(
    itemId: number,
    itemType: string,
    closestItemId,
    closestItemType
  ): void {
    this.lastCard = {
      id: itemId,
      type: itemType,
      closestId: closestItemId,
      closestType: closestItemType
    };
  }

  goToLastCard(deletedItemId?: number, deletedItemType?: string) {
    if (this.lastCard) {
      const isLastCardDeleted = deletedItemId && deletedItemType &&
                                deletedItemId === this.lastCard.id &&
                                deletedItemType === this.lastCard.type;

      const itemId = isLastCardDeleted ? this.lastCard.closestId : this.lastCard.id;
      const itemType = isLastCardDeleted ? this.lastCard.closestType : this.lastCard.type;

      const {extras, route} = DataSourcesUrlBuilders.selected(itemType, itemId);

      this.router.navigate([route], extras);
    } else {
      this.router.navigateByUrl(DataSourcesUrlBuilders.sources());
    }

  }

  getLastCard(): ILastVisibleCard {
    return this.lastCard;
  }
}
