<div class="user-folder-access">
  <div class="heading">{{ heading }}</div>
  <div class="search-wrap">
    <op-clearable-input placeholderText="Search by Name" (onUserInput)="handleFiltering($event)"></op-clearable-input>
  </div>
  <op-selectable-table [allItems]="filteredDataSourcesState"
                       [selectedItems]="selectedItems"
                       selectedItemText="Folders"
                       [columns]="columns"
                       [pagination]="pagination"
                       [disableTableSelection]="disableTableSelection"
                       (selectionChanged)="selectionChanged($event)"
                       (paginationChanged)="paginationChanged($event)"
  >
  </op-selectable-table>
</div>