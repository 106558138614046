import { Injectable } from '@angular/core';
import { IAuditModel } from '@app/components/modals/modalData';
import { ILabel } from '@app/components/shared/services/label.service';
import { IAuditFilter } from '@app/components/audit/audit.models';
import { ISortingService } from '@app/components/domains/discoveryAudits/reporting/services/sortingService/sortingService';
import { DateService } from '@app/components/date/date.service';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';
import { ECardViewSortByType } from '@app/components/manage/cards/manage-cards.component';

export interface ISort {
  sortOn: any;
  name?: string;
  reverse: boolean;
  compare?: (a: any, b: any, aFull?, bFull?) => number;
}

export class ManageButtonStyle {
  constructor(private value: string) {
  }

  toString() {
    return this.value;
  }

  static Button = new ManageButtonStyle('button');
  static IconButton = new ManageButtonStyle('icon-button');
  static MenuItem = new ManageButtonStyle('menu-item');
}

export interface IManageButton {
  icon: string;
  name: string;
  click: ($event) => void;
  style: ManageButtonStyle;
}

export interface IAuditBasicOptions {
  audit?: IAuditModel; // TODO: remove
  name: string;
  labels: Array<ILabel>;
  folder: IAuditBasicFolderOptions;
  frequency: string;
  domain: IAuditBasicDomainOptions;
  startingUrls: string[];
  includeList: Array<IAuditFilter>;
  hasRecipients: boolean;
  recipients?: string[];
  scanLimit?: number;
}

interface IAuditBasicItemOptions {
  createMode: boolean;
  id: number;
  name: string;
}

export interface IAuditBasicFolderOptions extends IAuditBasicItemOptions { }
export interface IAuditBasicDomainOptions extends IAuditBasicItemOptions {
  dataLayer: string;
}

@Injectable({
  providedIn: 'root'
})
export class ManageCommunicationService {

  constructor(private sortingService: ISortingService, private dateService: DateService) { }

  setSort(sortOn: string, sortDirection: ESortDirection): ISort {
    switch (sortOn) {
      case ECardViewSortByType.CardName:
        return {
          name: 'A-Z',
          sortOn: ECardViewSortByType.CardName,
          reverse: sortDirection !== ESortDirection.asc
        };

      case ECardViewSortByType.PageCount:
        return {
          name: 'Audit Size',
          sortOn: ECardViewSortByType.PageCount,
          reverse: sortDirection !== ESortDirection.asc,
          compare: (a, b, aFull, bFull) => {
            return this.sortingService.compareAuditSize(aFull, bFull, true);
          }
        };

      case ECardViewSortByType.CreatedAt:
        return {
          name: 'Date Created',
          sortOn: ECardViewSortByType.CreatedAt,
          reverse: sortDirection !== ESortDirection.desc,
          compare: (a, b) => {
            return this.sortingService.compareDates(this.dateService.dateFromString(a), this.dateService.dateFromString(b), true);
          }
        };

      case ECardViewSortByType.LastRunDate:
        return {
          name: 'Most Recently Run',
          sortOn: 'lastRunDate',
          reverse: sortDirection !== ESortDirection.asc,
          compare: this.compareCardByLastRun.bind(this)
        };

      case ECardViewSortByType.UpdatedAt:
        return {
          name: 'Date Modified',
          sortOn: ECardViewSortByType.UpdatedAt,
          reverse: sortDirection !== ESortDirection.desc,
          compare: (a, b) => {
            return this.sortingService.compareDates(this.dateService.dateFromString(a), this.dateService.dateFromString(b), true);
          }
        };
    }
  }

  private compareCardByLastRun(a: Date, b: Date): number {
    return this.sortingService.compareNullsLast(a, b, (a, b) => {
      return this.sortingService.compareDates(a, b, false);
    });
  }

  private compareReportCardByScanning(a: any, b: any): number {
    return this.sortingService.compareReportCardStatus(a, b, {
      'running': 1,
      'in_queue': 2,
      'scheduled': 3,
      'done': 4,
      'rule_failed': 5,
      'failed': 6,
      'app_failed': 7
    });
  }

}
