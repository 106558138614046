import { startWith, takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { RuleSetupFormBuilderService } from '@app/components/rules/rule-setup/services/rule-setup-form-builder.service';
import { ComponentChanges } from '@app/models/commons';
import { FilterTypes } from '../../rule-setup-conditions-tab.constants';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag, IUiTagCategory } from '@app/components/tag-database';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'then-condition',
  templateUrl: './then-condition.component.html',
  styleUrls: ['./then-condition.component.scss']
})
export class ThenConditionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() formData: UntypedFormGroup;
  @Input() groupName: UntypedFormGroup;
  @Input() allTags: IUiTag[];
  @Input() removable: boolean;

  @Output() onRemoveMainCondition = new EventEmitter<void>();

  FilterTypes = FilterTypes;

  private tagIdToTagMap = new Map<number, IUiTag>();

  updateTagIdSelectorSubject = new ReplaySubject<string>(1);
  filteredTags: IUiTag[];
  tagIdSearchText: string;

  private destroy$ = new Subject<void>();

  constructor(private formBuilderService: RuleSetupFormBuilderService, private uiTagService: UiTagService) {}

  ngOnInit() {
    const initialSearchText = this.getTagIdSearchText(this.tagIdControl.value);
    this.tagIdControl.valueChanges.pipe(
      startWith(initialSearchText),
      takeUntil(this.destroy$)
    ).subscribe((searchText: string | number) => {
      this.tagIdSearchText = this.getTagIdSearchText(searchText);
      this.updateTagIdSelectorSubject.next(this.tagIdSearchText);
    });

    this.updateTagIdSelectorSubject.pipe(
      takeUntil(this.destroy$),
    ).subscribe(searchText => {
      this.filteredTags = this.allTags.filter(tag => {
        return tag.name.toLowerCase().includes(searchText) ||
          this.getTagCategory(tag.tagCategoryId).category?.includes(searchText);
      });
    });
  }

  ngOnChanges(changes: ComponentChanges<ThenConditionComponent>) {
    if (changes.allTags?.previousValue !== changes.allTags?.currentValue) {
      this.tagIdToTagMap = ArrayUtils.toMap(changes.allTags.currentValue, 'id');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getTagCategory(id: number): IUiTagCategory {
    return this.uiTagService.getTagCategory(id);
  }

  tagSelected(tag: IUiTag) {
    this.tagIdControl.setValue(tag.id);
  }

  displayTagIdFn = (tagId: number) => this.tagIdToTagMap.get(tagId)?.name;

  addAccountCondition() {
    this.formBuilderService.addAccountCondition(this.accountGroup);
  }

  removeAccountCondition() {
    this.formBuilderService.removeAccountCondition(this.accountGroup);
  }

  addVariableCondition() {
    this.formBuilderService.addVariableCondition(this.variablesArray);
  }

  removeVariableCondition(index: number) {
    this.formBuilderService.removeVariableCondition(index, this.variablesArray);
  }

  private getTagIdSearchText(searchText?: string | number): string {
    if (!searchText) return ''; // tagId is disabled
    return typeof searchText === 'number' // selected tag, otherwise - searching text
      ? this.tagIdToTagMap.get(searchText)?.name.toLowerCase()
      : searchText.toLowerCase();
  }

  get tagIdControl(): UntypedFormControl {
    return this.formData?.get('tagId') as UntypedFormControl;
  }

  get accountGroup(): UntypedFormGroup {
    return this.formData?.get('account') as UntypedFormGroup;
  }

  get variablesArray(): UntypedFormArray {
    return this.formData?.get('variables') as UntypedFormArray;
  }

}
