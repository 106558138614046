import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { IOpFilterBarMenuItem } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import { OpFilterBarService } from '../op-filter-bar.service';
import { OpClearableInputComponent } from '../../op-clearable-input/op-clearable-input.component';

@Component({
  selector: 'op-filter-bar-menu-item',
  templateUrl: './op-filter-bar-menu-item.component.html',
  styleUrls: ['./op-filter-bar-menu-item.component.scss']
})
export class OpFilterBarMenuItemComponent {

  clearableInput: OpClearableInputComponent;
  currentMenuItem: IOpFilterBarMenuItem;

  @Input() menuItems: IOpFilterBarMenuItem[];
  @Input() service: OpFilterBarService<any>;
  @Input() matMenuClass: string | string[];

  @ViewChild('childMenu', { static: true }) public childMenu: MatMenu;

  EFilterBarMenuTypes = EFilterBarMenuTypes;

  handleUserInput(event, opClearableInput: OpClearableInputComponent, item: IOpFilterBarMenuItem): void {
    item.action(event);

    if (this.clearableInput === undefined || this.currentMenuItem === undefined) {
      this.clearableInput = opClearableInput;
      this.currentMenuItem = item;
    }
  }

  menuClosed(): void {
    if(this.clearableInput) {
      this.clearableInput.clearInput();
      this.currentMenuItem.children = [];
    }
  }
}
