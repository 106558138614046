import { RuleSetupModule } from '@app/components/rules/rule-setup/rule-setup.module';
import { MaterialModule } from '@app/components/material/material.module';
import { MatSelectSearchModule } from 'mat-select-search';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ActionsModule } from './../actions/actions.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/components/shared/shared.module';
import { CreatorNgModule } from '../creator/creatorModule';
import { WebJourneyEditorComponent } from '@app/components/web-journey/web-journey-editor/web-journey-editor.component';
import { WebJourneySetupFormComponent } from './web-journey-setup-form/web-journey-setup-form.component';
import { RuleSelectorModule } from '../account/rules/rule-selector/rule-selector.module';
import { WebJourneyService } from './web-journey.service';
import { OpModalModule } from '../shared/components/op-modal/op-modal.module';
import { OpFormModule } from '../shared/op-form.module';
import { OpSelectCreateModule } from '../shared/components/op-select-create/op-select-create.module';
import { WebJourneyV3Service } from '../domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import { WebJourneyV3NotificationsService } from '../domains/webJourneys/web-journey-v3-api/web-journey-v3-notifications.service';
import { TransformActionsService } from '../action-set-library/transform-actions.service';
import { WebJourneyV3RfmService } from '../domains/webJourneys/web-journey-v3-api/web-journey-v3-rfm.service';
import { WebJourneyEditorGuard } from './web-journey-editor/web-journey-editor-guard.service';
import { ActionSetLibraryModule } from '@app/components/action-set-library/action-set-library.module';
import { OpTabsModule } from '@app/components/shared/components/op-tabs/op-tabs.module';
import { StandardsTabModule } from '../shared/components/standards-tab/standards-tab.module';
import { ReportCardService } from '../manage/cards/report-card/report-card.service';
import {
  CreateFolderAndSubFolderFieldModule
} from '@app/components/folder-and-sub-folder-field/folder-and-sub-folder-field.module';
import {
  WebJourneySuccessSnackbarComponent
} from '@app/components/web-journey/web-journey-success-snackbar/web-journey-success-snackbar.component';
import { DataSourceEditorService } from '@app/components/data-source-editor/data-source-editor.service';
import { EasyBlockTagsModule } from '../audit/easy-block-tags/easy-block-tags.module';
import { OpLabelManagerModule } from '../shared/components/op-label-manager/op-label-manager.module';
import { OpRecurrenceComponent } from '../shared/components/op-recurrence/op-recurrence.component';

@NgModule({
  declarations: [
    WebJourneyEditorComponent,
    WebJourneySetupFormComponent,
    WebJourneySuccessSnackbarComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatSelectSearchModule,
    NgxMatSelectSearchModule,
    SharedModule,
    OpModalModule,
    OpFormModule,
    CreatorNgModule,
    ActionsModule,
    RuleSelectorModule,
    OpSelectCreateModule,
    ActionSetLibraryModule,
    RuleSetupModule,
    OpTabsModule,
    StandardsTabModule,
    CreateFolderAndSubFolderFieldModule,
    EasyBlockTagsModule,
    OpLabelManagerModule,
    OpRecurrenceComponent
  ],
  providers: [
    WebJourneyEditorGuard,
    WebJourneyService,
    WebJourneyV3Service,
    WebJourneyV3NotificationsService,
    WebJourneyV3RfmService,
    TransformActionsService,
    ReportCardService,
    DataSourceEditorService,
  ],
  exports: [
    WebJourneyEditorComponent
  ]
})
export class WebJourneyModule {}
