<div class="top-wrap">
  <filter-spinner [state]="state"></filter-spinner>
  <p class="title">Pages Scanned</p>
  <op-audit-report-export-menu
    [auditId]="auditId"
    [runId]="runId"
    [tableCompletedState]="state"
    [config]="exportReportConfig">
  </op-audit-report-export-menu>

  <op-switchable-column-menu [columnConfig]="PagesTableColumns"
                             [storageKey]="CommonPagesConfigLocalStorageKey"
                             [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
</div>

<div class="table-wrap">
  <div #scrollTop></div>
  <table
    mat-table
    matSort
    resizeableTable
    class="pages-table"
    [matSortActive]="TableColumn.GeoCount"
    matSortStart="desc"
    matSortDirection="desc"
    [dataSource]="dataSource"
    [class.updating]="state === 'loading'"
  >
    <ng-container [matColumnDef]="TableColumn.PageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>INITIAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ page.pageUrl }}">
            <div class="url-text">{{ page.pageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>FINAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ page.finalPageUrl }}">
            <div class="url-text">{{ page.finalPageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageLoadTime">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="center-text fixed-90"
          [matTooltip]="PageLoadColumnTooltip"
          [matTooltipPosition]="'above'"
      >PAGE LOAD TIME (sec)</th>
      <td mat-cell *matCellDef="let page" class="center-text {{page.pageLoadTimeClass}}">{{page.pageLoadTime}}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90">FINAL PAGE STATUS CODE</th>
      <td mat-cell *matCellDef="let page"
          class="center-text {{ page.pageStatusCodeClass }}"
          [matTooltip]="PageStatusCodeTooltipMap[page.finalPageStatusCode] || null"
          matTooltipPosition="above"
          matTooltipShowDelay="250"
      >{{ page.finalPageStatusCode }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.GeoCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90"># OF UNIQUE GEOS</th>
      <td mat-cell *matCellDef="let page" class="center-text">{{ page.geoCount }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.RequestCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text fixed-90"># OF NETWORK REQUESTS</th>
      <td mat-cell *matCellDef="let page" class="center-text">{{ page.requestCount }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row
        *matRowDef="let page; columns: displayedColumns$ | async;"
        [ngClass]="{ 'open-in-page-details': page.pageId === openedPageDetailsId }"
        (click)="openPageDetails(page)"></tr>
  </table>

  <mat-paginator
    class="pages-paginator"
    [length]="paginationState.length"
    [pageSize]="paginationState.pageSize"
    [hidePageSize]="true"
    (page)="onScrollTop()"
  ></mat-paginator>
</div>
