import {
  ChartClassName,
  ChartType, EChartType,
  IChartsConfig
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import {
  attachBarChartToSvg,
  hide,
  nearestDataPointCoordinates
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import { EDateFormats, formatDate, toUTC } from '@app/components/date/date.service';
import * as d3 from 'd3';
import {
  createTooltip,
  drawTooltip,
  getChartData,
  getPercentageOfTotal
} from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import { IUsageTrendsDTO } from '@app/components/usage-v2/usage-v2.models';
import {
  getProjectedUsageFilteredToggleBtn
} from '@app/components/usage-v2/components/usage-chart/datasets/future-periods/utils/toggle-buttons';

export function addFuturePeriodsPeriodProjectedTotalFilteredChartToConfig(trends: IUsageTrendsDTO, config: IChartsConfig, chartType: EChartType): void {
  if (!trends.futurePeriods?.length) {
    return;
  }
  const data = getChartData(trends.futurePeriods, 'periodProjectedTotal.scheduledUsageEstimate.filtered');
  if (!data.length) {
    return;
  }
  config.charts[ChartType.futurePeriodsPeriodProjectedTotalFiltered] = {
    data,
    chartClassName: ChartClassName.filteredProjectedUsage,
    toggleBtn: getProjectedUsageFilteredToggleBtn(chartType, false),
  };
}

export function drawFuturePeriodsPeriodProjectedTotalFilteredChart(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
  windowWidth: number,
  circleClass: string,
  tooltipClass: string,
  animationEnabled: boolean = true,
): void {
  const periodProjectedTotalFiltered = config.charts[ChartType.futurePeriodsPeriodProjectedTotalFiltered];
  const periodProjectedTotalData = config.charts[ChartType.futurePeriodsPeriodProjectedTotal]?.data;

  if (!periodProjectedTotalFiltered || !periodProjectedTotalData) {
    return;
  }

  attachBarChartToSvg({
      data: periodProjectedTotalFiltered.data,
      x: xAxis,
      y: yAxis,
      height: height - margin.top - margin.bottom,
      containerClassName: `period-projected-total-filtered ${periodProjectedTotalFiltered.chartClassName}`,
      barClassName: 'period-projected-total-filtered-bar',
      barLabelTextFormatterFn: VizUtilsService.formatChartNumbers,
      animationDuration: animationEnabled ? 750 : 0,
    },
    svg)
    .on('mouseenter', (e) => {
      const {
        x,
        y,
        dataPoint,
        dataPointIndex,
      } = nearestDataPointCoordinates(e, svg.node(), periodProjectedTotalFiltered.data, xAxis, yAxis);

      const tooltip = createTooltip(tooltipClass);

      const percentage = getPercentageOfTotal(dataPoint.value, periodProjectedTotalData[dataPointIndex].value);
      drawTooltip(
        svg,
        '<div class="usage-chart-tooltip-body">' +
        '<h3 class="title">SCHEDULED MONTHLY USAGE' + '<br>' +
        'IN ' +
        formatDate(toUTC(new Date(dataPoint.date)), EDateFormats.dateEight) +
        '</h3>' +
        '<div class="value">' +
        '<span class="value-data filtered">' + VizUtilsService.formatChartNumbers(dataPoint.value) + ' ' +
        '(<span class="material-icons icon">filter_alt</span>)' +
        '</span>' +
        '<span class="value-label"> of </span>' +
        '<span class="value-data estimated">' + VizUtilsService.formatChartNumbers(periodProjectedTotalData[dataPointIndex].value) + '</span>' +
        '</div>' +
        '<span class="value-label">' + percentage + '</span>' +
        '</div>',
        x,
        y,
        null,
        tooltip,
        windowWidth,
      );
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
    });
}
