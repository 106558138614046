import { Component } from '@angular/core';
import { EditDataLayerModalComponent } from '@app/components/shared/components/edit-data-layer-modal/edit-data-layer-modal.component';
import { OpModalService } from '@app/components/shared/components/op-modal';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-edit-data-layer-modal',
  templateUrl: './cl-edit-data-layer-modal.component.html',
  styleUrls: ['./cl-edit-data-layer-modal.component.scss']
})
export class ComponentLibraryEditDataLayerModalComponent {

  constructor(private modalService: OpModalService) {}

  openModal(): void {
    this.modalService.openModal(EditDataLayerModalComponent, {
      data: {
        domain: {
          dataLayer: 'myDataLayer'
        }
      }
    });
  }
}
