<op-modal (onClose)="close()"
          [leftFooterButtons]="leftFooterButtons"
          [rightFooterButtons]="rightFooterButtons">
  <ng-template #headerTemplate>
    <op-modal-header
      [title]="modalTitle"
      (onClose)="closeSilently();"></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="spinner" *ngIf="dataSourcesState.loading">
      <mat-spinner diameter="24"></mat-spinner>
    </div>
    <div class="content" [formGroup]="formGroup"
         [hidden]="dataSourcesState.loading">
      <op-selectable-table [allItems]="dataSourcesState"
                           [selectionMode]="selectionMode"
                           [selectedItems]="itemsControl.value"
                           [columns]="columns"
                           [labels]="labels"
                           [showSelectedLimit]="false"
                           [pagination]="pagination"
                           [selectionIndentifier]="'auditId'"
                           [readOnlyLabel]="readOnlyLabel"
                           [isReadOnly]="false"
                           (selectionChanged)="selectionChanged($event)"
                           (sortChanged)="sortChanged($event)"
                           (paginationChanged)="paginationChanged($event)">
        <ng-template #filterBarTemplate>
          <email-inbox-editor-data-sources-filter-bar
            searchByTextPlaceholderSuffix="data source"
            [labels]="labels"
            (filtersChanged)="filtersChanged($event)">
          </email-inbox-editor-data-sources-filter-bar>
        </ng-template>
      </op-selectable-table>
    </div>
  </ng-template>
</op-modal>
