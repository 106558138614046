<div class="top-section" [class.in-audit]="reportType === 'audit'" [ngClass]="{ shadowed: isScrolled }">
  <div class="search-toggle-wrap">
    <op-clearable-input [placeholderText]="'Filter Requests'"
                        [defaultValue]="search"
                        (onUserInput)="filterTable($event)"
                        class="clearable-input">
    </op-clearable-input>
    <mat-slide-toggle *ngIf="privacyFeatureEnabled && reportType === EReportType.AUDIT"
                      color="primary"
                      class="toggle-switch"
                      [checked]="showJSFileDifferences"
                      [disabled]="loading"
                      (change)="setFileChangesColumnsAndLoadData($event.checked)">
      Show JavaScript file differences
    </mat-slide-toggle>
    <mat-slide-toggle *ngIf="privacyFeatureEnabled && reportType === EReportType.AUDIT"
                      color="primary"
                      class="toggle-switch"
                      [checked]="showPreAuditActionRequests"
                      [disabled]="loading"
                      (change)="setPreAuditActionFlagAndLoadData($event.checked)">
      Show Pre-Audit Action Requests
    </mat-slide-toggle>
  </div>
  <share-report-btn *ngIf="reportType === 'audit'"
                    [auditId]="itemId"
                    [runId]="runId"
                    [pageId]="pageId"
                    [activeTab]="activeTab"
                    [state]="state">
  </share-report-btn>
</div>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="25"
               [strokeWidth]="4">
  </mat-spinner>
</div>

<div class="table-wrap" [class.hidden]="loading || !renderTable">

  <table
    mat-table
    matSort
    multiTemplateDataRows
    class="request-log-table"
    [dataSource]="dataSource"
    [class.updating]="tableLoading"
    [class.hidden]="!this.dataSource.data.length"
    [matSortActive]="sortColumn"
    [matSortDirection]="this.requestLogQueryParams.sortDesc ? 'desc' : 'asc'"
    matSortStart="asc"
  >
    <ng-container matColumnDef="{{TableColumn.EXPAND}}">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="td-expand-arrow" mat-cell *matCellDef="let request">
        <mat-icon class="expand-arrow">keyboard_arrow_right</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.URL}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [class.in-audit]="reportType === 'audit'">REQUEST<br>URL</th>
      <td mat-cell
          *matCellDef="let request"
          [matTooltip]="request.url"
          matTooltipPosition="above"
          class="request-icon-url">
        <div class="request-url-wrapper" [class.request-blocked]="request.blocked">
          <span class="request-blocked-icon-circle"
                [matTooltip]="'Request has been blocked as requested'"
                matTooltipPosition="above"
                matTooltipShowDelay="200"
                *ngIf="request.blocked">
            <mat-icon class="op-icon" svgIcon="request_blocked"></mat-icon>
          </span><span class="request-url">{{ request.url|hideProtocol }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.TAG}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text">TAG</th>
      <td class="td-tag center-text" mat-cell *matCellDef="let request">
        <span class="tag-icon-container">
          <img *ngIf="request.tagIcon"
               class="tag-icon"
               [src]="request.tagIcon"
               [matTooltip]="request.tooltip"
               matTooltipPosition="above">
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.COOKIE_INITIATORS}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text">COOKIE(S)<br>INITIATED</th>
      <td mat-cell *matCellDef="let request" class="center-text">
        {{ request.cookiesInitiated.length }}
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.ORIGIN}}">
      <th mat-header-cell *matHeaderCellDef class="center-text">ORIGIN</th>
      <td mat-cell [matTooltip]="request?.originTooltip" matTooltipPosition="above" *matCellDef="let request" class="center-text td-mime-type">
        {{ request.origin }}
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.DATE_DIFFERENCE}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text">LARGEST DATE DIFFERENCE</th>
      <td mat-cell *matCellDef="let request" class="center-text">
        {{ request.largestDateDifference }}
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.SIZE_DIFFERENCE}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text">LARGEST SIZE DIFFERENCE (Bytes)</th>
      <td mat-cell *matCellDef="let request" class="center-text">
        {{ request.largestSizeDifference }}
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.RESPONSE_SIZE}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text">RESPONSE<br>SIZE (bytes)</th>
      <td mat-cell *matCellDef="let request" class="center-text">
        {{ request.responseSize }}
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.LOAD_TIME}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text">Load<br>Time</th>
      <td mat-cell *matCellDef="let request" class="{{ request.loadTimeClass }} td-loadtime center-text">{{ request.loadTime }}</td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.STARTED_AT}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text">Started<br>At</th>
      <td mat-cell *matCellDef="let request" class="td-loadtime center-text">
        <ng-container *ngIf="request.origin === EPageDetailsRequestOriginLabels.PRE_AUDIT_ACTION">
          ---
        </ng-container>
        <ng-container *ngIf="request.origin === EPageDetailsRequestOriginLabels.ON_PAGE">
          {{ request.startedAt }} ms
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.REQUEST_METHOD}}">
      <th mat-header-cell *matHeaderCellDef>METHOD</th>
      <td class="td-method" mat-cell *matCellDef="let request">
        <span>{{ request.method }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.STATUS_CODE}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text">Status<br>Code</th>
      <td mat-cell
          *matCellDef="let request"
          [matTooltip]="request.statusCodeTooltip"
          matTooltipPosition="above"
          matTooltipShowDelay="300"
          class="{{ request.statusCodeClass }}
          center-text"
      >
        {{ request.statusCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.GEO_LOCATION}}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="center-text">GEO</th>
      <td mat-cell *matCellDef="let request" class="center-text">
        <ng-container *ngIf="request.geoLocation">
          <div [matTooltip]="request.geolocationExpandedValue"
               matTooltipPosition="above">
            {{ request.geoLocation }}
          </div>
        </ng-container>
        <ng-container *ngIf="!request.geoLocation">
          <mat-icon matTooltip="No location data found"
                    matTooltipPosition="above"
                    matTooltipShowDelay="250"
          >do_disturb</mat-icon>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="{{TableColumn.EXPAND_DETAIL}}">
      <td mat-cell *matCellDef="let request" [attr.colspan]="columnsToDisplay.length">
        <div class="request-detail" [@detailExpand]="request === expandedRequest ? 'expanded' : 'collapsed'">
          <div class="request-detail-wrap" [class.in-audit]="reportType === 'audit'">
            <p><strong>URL:</strong> {{ request.url }}</p>
            <p>
              <strong>STATUS CODE:</strong>
              <span class="{{ request.statusCodeClass }}"
                    [matTooltip]="request.statusCodeTooltip"
                    matTooltipPosition="above"
                    matTooltipShowDelay="300"
              >
                {{ request.statusCode }}
              </span>
            </p>
            <p *ngIf="reportType === 'webJourney'">
              <strong>METHOD:</strong>
              <span class="{{ request.method }}">
                {{ request.method }}
              </span>
            </p>
            <p>
              <strong>RESPONSE SIZE:</strong>
              <span class="content-size">
                {{ request.responseSize }} bytes
              </span>
            </p>
            <p *ngIf="request.loadTime">
              <strong>LOAD TIME:</strong>
              <span class="{{ request.loadTimeClass }}">
                {{ request.loadTime }}
              </span>
            </p>
            <p *ngIf="request.startedAt">
              <strong>STARTED AT:</strong>
              {{ request.startedAtFullDate }} ({{ request.startedAt }} ms)
            </p>
            <p><strong>GEO LOCATION:</strong> {{ request.geolocationExpandedValue || 'No location data found' }}</p>
            <p *ngIf="request.mimeType"><strong>TYPE:</strong> {{ request.mimeType }}</p>
            <p *ngIf="request.lastModifiedDate"><strong>LAST MODIFIED DATE:</strong> {{request.lastModifiedDate}} </p>
            <p *ngIf="request.filename"><strong>FILE NAME:</strong> {{request.filename}} </p>
            <p *ngIf="request.requestDomain"><strong>REQUEST DOMAIN:</strong> {{request.requestDomain}} </p>
            <p *ngIf="request.fileChangeType"><strong>FILE CHANGE TYPE:</strong> {{request.fileChangeType}} </p>

            <div class="header-title" *ngIf="reportType === 'webJourney'">
              <strong>{{request.requestHeaders?.length}} REQUEST HEADER{{request.headers?.length === 1 ? '' : 'S'}}:</strong>
              <div *ngFor="let header of request.requestHeaders" class="nested-header">
                <p>
                  <strong>NAME:</strong>
                  <span class="{{ header.name }}">
                    {{ header.name }}
                  </span>
                </p>
                <p>
                  <strong>VALUE:</strong>
                  <span class="{{ header.value }}">
                    {{ header.value }}
                  </span>
                </p>
              </div>
            </div>
            <div class="header-title" *ngIf="reportType === 'webJourney'">
              <strong>{{request.responseHeaders?.length}} RESPONSE HEADER{{request.responseHeaders?.length === 1 ? '' : 'S'}}:</strong>
              <div *ngFor="let header of request.responseHeaders" class="nested-header">
                <p>
                  <strong>NAME:</strong>
                  <span class="{{ header.name }}">
                {{ header.name }}
              </span>
                </p>
                <p>
                  <strong>VALUE:</strong>
                  <span class="{{ header.value }}">
                {{ header.value }}
              </span>
                </p>
              </div>
            </div>
            <ng-container *ngIf="reportType === 'audit'">
              <p *ngIf="request.tagName"><strong>TAG NAME:</strong> {{ request.tagName }}</p>
              <p *ngIf="request.tagCategory"><strong>TAG CATEGORY:</strong> {{ request.tagCategory }}</p>
              <div *ngIf="request.cookiesInitiated.length" class="cookies-initiated"><strong>COOKIE(S) INITIATED:</strong>
                <div *ngFor="let cookie of request.cookiesInitiated"
                     (click)="viewDetailsOnCookiesTab(cookie)">
                    <span class="tooltip-wrap"
                          matTooltip="View details on Cookies tab"
                          matTooltipPosition="above"
                    >
                      {{ cookie.display }}
                    </span>
                    <mat-icon>east</mat-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let request; columns: columnsToDisplay;"
        class="request-row"
        [class.expanded-row]="expandedRequest === request"
        (click)="expandedRequest = expandedRequest === request ? null : request">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

  </table>
  <mat-paginator
    *ngIf="reportType === 'audit'"
    [length]="totalNumPages"
    [pageSize]="requestLogQueryParams.size"
    [pageIndex]="requestLogQueryParams.page"
    [hidePageSize]="true"
  ></mat-paginator>
  <p class="no-requests-message" *ngIf="!this.dataSource.data.length">No requests found</p>
</div>
