import {IUserModel} from './components/modals/modalData';
import {IUser} from './moonbeamModels';
import { IUserBase } from '@app/moonbeamModels';


export const guest: string = 'guest';
export const standardUser: string = 'user';
export const admin: string = 'admin';
export const opAdmin: string = 'op-admin';
export const opSysAdmin: string = 'op-sys-admin';

export const GuestPermissions = 0;
export const StandardPermissions = 1;
export const AdminPermissions = 32;
export const OpAdminPermissions = 256;
export const OpSysAdminPermissions = 1024;

export enum EPermissionStringToIdMap {
  'guest' = GuestPermissions,
  'user' = StandardPermissions,
  'admin' = AdminPermissions,
  'op-admin' = OpAdminPermissions,
  'op-sys-admin' = OpSysAdminPermissions
}

export const AccountUserPermissions = [
  { key: 'Read Only', value: guest, number: GuestPermissions },
  { key: 'Standard', value: standardUser, number: StandardPermissions },
  { key: 'Account Admin', value: admin, number: AdminPermissions },
];

export const OpUserPermissions = [
  { key: 'OP Admin', value: opAdmin, number: OpAdminPermissions },
  { key: 'System Admin', value: opSysAdmin, number: OpSysAdminPermissions }
];

export const UserPermissions = AccountUserPermissions.concat(OpUserPermissions);

export function userIsGuest(user: IUserModel | IUser): boolean {
  return userIs(user, permissions => permissions === guest);
}

export function userIsStandard(user: IUserModel | IUser): boolean {
  return userIs(user, permissions => permissions === standardUser || userIsAdmin(user));
}

export function userIsAdmin(user: IUserModel | IUser): boolean {
  return userIs(user, permissions => permissions === admin || userIsOPAdmin(user));
}

export function userIsOPAdmin(user: IUserModel | IUser): boolean {
  return userIs(user, permissions => permissions === opAdmin || userIsOPSysAdmin(user));
}

export function userIsOPSysAdmin(user: IUserModel | IUser | IUserBase): boolean {
  if (!user) return false;
  return (typeof user.permissions === 'string')
    ? userIs(user, permissions => permissions === opSysAdmin)
    : userIs(user, permissions => permissions === OpSysAdminPermissions);
}

function userIs(user: IUserModel | IUser | IUserBase, check: Function): boolean {
  if (user) {
    return check(user.permissions);
  }
  return false;
}
