import { ComparisonError } from './../web-journey-results.constants';
import { tap, shareReplay, catchError } from 'rxjs/operators';
import { ApiService } from '@app/components/core/services/api.service';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { Observable, of } from 'rxjs';
import { IActionTagVariablesDiffCount, ITagVariablesDiffDetails, IWJComparisonSnapshot } from './wj-results-tag-comparison.models';

@Injectable()
export class WjResultsTagReportService {

  private readonly webJourneysUrl = environment.apiV3Url + 'comparisons/web-journeys';

  private getActionTagVariablesDiffCount$: Observable<IActionTagVariablesDiffCount[] | ComparisonError>;
  private comparisonSnapshot$: Observable<IWJComparisonSnapshot | ComparisonError>;

  private actionIdToDetailsMap = new Map<number, ITagVariablesDiffDetails[]>();

  constructor(private apiService: ApiService) {}

  /**
   * Get high-level tag variable comparison results for the given web-journey run. Only mismatches are returned
   * 200 - Tag variable comparison results
   * 204 - No comparison run for a web-journey run found OR no tags have comparison enabled in the run
   */
  getActionTagVariablesDiffCount(journeyId: number, runId: number): Observable<IActionTagVariablesDiffCount[] | ComparisonError> {
    if (!this.getActionTagVariablesDiffCount$) this.getActionTagVariablesDiffCount$ = this.fetchActionTagVariablesDiffCount(journeyId, runId);
    return this.getActionTagVariablesDiffCount$;
  }

  private fetchActionTagVariablesDiffCount(journeyId: number, runId: number): Observable<IActionTagVariablesDiffCount[] | ComparisonError> {
    return this.apiService.get<IActionTagVariablesDiffCount[] | ComparisonError>(`${this.webJourneysUrl}/${journeyId}/runs/${runId}/variables`).pipe(
      shareReplay(1),
      catchError(_ => of(new ComparisonError()))
    );
  }

  /**
   * Get detailed tag variable comparison results for the given web-journey run action
   * 200 - Detailed tag variable comparison results
   * 204 - No comparison run for a web-journey run found OR no tags have comparison enabled in the run
   */
  getTagVariablesDiffDetails(journeyId: number, runId: number, actionId: number): Observable<ITagVariablesDiffDetails[] | ComparisonError> {
    if (this.actionIdToDetailsMap.has(actionId)) return of(this.actionIdToDetailsMap.get(actionId));
    return this.apiService.get<ITagVariablesDiffDetails[]>(
      `${this.webJourneysUrl}/${journeyId}/runs/${runId}/actions/${actionId}/variables`
    ).pipe(
      tap(details => this.actionIdToDetailsMap.set(actionId, details)),
      catchError(_ => of(new ComparisonError()))
    );
  }

  /**
   * Get run-over-run comparison configuration for a specified web-journey run
   * 200 - Comparison snapshot details
   * 204 - No comparison run for a web-journey run found
   */
  getComparisonSnapshot(journeyId: number, runId: number): Observable<IWJComparisonSnapshot | ComparisonError> {
    if (!this.comparisonSnapshot$) this.comparisonSnapshot$ = this.fetchComparisonSnapshot(journeyId, runId);
    return this.comparisonSnapshot$;
  }

  private fetchComparisonSnapshot(journeyId: number, runId: number): Observable<IWJComparisonSnapshot | ComparisonError> {
    return this.apiService.get<IWJComparisonSnapshot | ComparisonError>(`${this.webJourneysUrl}/${journeyId}/runs/${runId}/run-over-run`).pipe(
      shareReplay(1),
      catchError(_ => of(new ComparisonError()))
    );
  }

}
