import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  IOpFilterBarV2MenuItemCustomComponent
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import {
  IOpFilterBarV2MenuCustomComponent
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-menu-custom-component-item-v2/abstract/op-filter-bar-menu-custom-component.interface';
import { OpFilterBarV2Service } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.service';

@Component({
  selector: 'op-filter-bar-menu-custom-component-item-v2',
  templateUrl: './op-filter-bar-menu-custom-component-item-v2.component.html'
})
export class OpFilterBarMenuItemCustomComponentV2Component
  implements AfterViewInit, OnDestroy {

  @Input() item: IOpFilterBarV2MenuItemCustomComponent;
  @Input() service: OpFilterBarV2Service<unknown>;
  @ViewChild('customComponentHost', { static: false, read: ViewContainerRef }) vcr!: ViewContainerRef;
  ref!: ComponentRef<any>;
  constructor(private cdr: ChangeDetectorRef) {
  }

  loadComponent(componentClassRef: string): void {
    if (this.vcr && componentClassRef) {
      const ref = this.service?.getCustomComponentByRef(componentClassRef);

      this.item.action = ref?.action;

      const component = this.vcr.createComponent<IOpFilterBarV2MenuCustomComponent>(ref.componentRef);
      component.instance.item = this.item;
      this.ref = component;
      this.cdr.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.loadComponent(this.item.customComponentRef);
  }

  ngOnDestroy(): void {
    this.ref?.destroy();
  }
}
