<ng-container *ngIf="value || renderWhenValueIs0">
  <div class="trend-value-container">
    <mat-icon>{{ value > 0 ? 'arrow_upward' : (value < 0 ? 'arrow_downward' : '')}}</mat-icon>
    <div class="trend-value"
         [class.zero-label]="!absValue && zeroLabel">
      {{ absValue !== 0 || !zeroLabel ? (absValue | number:valueFormat) : zeroLabel }}
    </div>
  </div>
  <span class="trend-label" *ngIf="label">{{label}}</span>
</ng-container>
