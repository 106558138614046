<div class="admin-clean-up">
  <form [formGroup]="cleanUpForm" class="admin-clean-up-form">
    <mat-form-field class="admin-select-type" floatLabel="always">
      <mat-label>Run Type</mat-label>
      <mat-select [formControl]="runType" (selectionChange)="clearMsgs()" placeholder="Select type">
        <mat-option *ngFor="let type of runTypes" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="hasError">{{errorMsg}}</mat-error>
      <mat-hint>Type of run to delete</mat-hint>
    </mat-form-field>
    <mat-form-field floatLabel="always" class="admin-input-ids">
      <mat-label>Run IDs</mat-label>
      <input matInput
             placeholder="Enter IDs"
             [formControl]="runIDs">
      <mat-error *ngIf="hasError">{{errorMsg}}&#125;</mat-error>
      <mat-hint >Enter run IDs separated by a comma</mat-hint>
    </mat-form-field>
    <button mat-flat-button color="primary" type="button" class="clean-up-button" [disabled]="runIDs.invalid || runType.invalid" (click)="deleteRuns()">Delete Runs</button>
  </form>
  <div *ngIf="hasSuccess" class="admin-delete-success">{{successMsg}}</div>
</div>
