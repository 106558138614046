import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpClearableInputComponent } from './op-clearable-input.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    MatButtonModule
  ],
  declarations: [
    OpClearableInputComponent
  ],
  exports: [
    OpClearableInputComponent
  ]
})
export class OpClearableInputModule {}
