import { Component, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'feedback-portal',
  templateUrl: './feedback-portal.component.html',
  styleUrls: ['./feedback-portal.component.scss']
})
export class FeedbackPortalComponent {
  collapsed: boolean = true;

  expand(){
    this.collapsed = false;
  }

  collapse() {
    this.collapsed = true;
  }
}
