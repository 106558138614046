import {IUtilitiesService} from './utilitiesService';
import * as angular from 'angular';


export class NullsLastFilter {
    static factory(utilitiesService: IUtilitiesService) {
      return (input: Array<any>, key: string) => {
        if (!angular.isArray(input)) {
          return;
        }

        let present = [];
        let empty = [];

        input.forEach(item => {
          if (utilitiesService.getNestedObjectByKey(item, key) !== null) {
            present.push(item);
          } else {
            empty.push(item);
          }
        });

        return present.concat(empty);
      };
    }
}
