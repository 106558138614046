import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UpgradeUserBannerComponent } from './upgrade-user-banner.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
  ],
  declarations: [
    UpgradeUserBannerComponent,
  ],
  exports: [
    UpgradeUserBannerComponent
  ]
})
export class UpgradeUserBannerModule { }
