import { Pipe, PipeTransform } from '@angular/core';
import { IVarComparisonAlignment } from './wj-results-tag-comparison.models';

@Pipe({
  name: 'matchCriteriaFormatter'
})
export class MatchCriteriaFormatterPipe implements PipeTransform {

  transform(vars?: IVarComparisonAlignment[]): string {
    if (!vars?.length) return '';
    return vars.reduce(
      (acc, variable) => acc.concat(`${variable.name} = ${variable.value} `),
      'Match Criteria: '
    );
  }

}
