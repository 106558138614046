import _isEqual from 'lodash-es/isEqual';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import {
  IHorizontalBarChartDataPoint
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.models';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { BehaviorSubject, forkJoin, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, mergeMap, startWith, takeUntil, tap } from 'rxjs/operators';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  IPageSummaryInsights,
  IPageSummaryInsightsByPage,
  IPageSummaryLoadTimeChart,
  IPageSummaryPage,
  IPageSummaryStatusCodeChart,
  IPageSummaryTableRow,
  IStatusCodeHorizontalBarChartDataPoint,
  IWebVitalsTrendData,
  IPageSummaryTrendsRun,
  IPageSummaryWebVitalsTrendsRun,
} from './page-summary.models';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
  IAuditReportApiPostBody,
  IPageStatusCodeFilter
} from '@app/components/audit-reports/audit-report/audit-report.models';
import { AuditReportBase, IFilterableAuditReport } from '../general-reports.models';
import { AuditReportLoadingService } from '../../audit-report-loading.service';
import {
  EStatusCodeCategories,
  formatPaginator,
  PageLoadColumnTooltip,
  PageLoadWidgetTooltip,
  statusCodeCategoryToRangeMap
} from '../../audit-report/audit-report.constants';
import {
  convertStats,
  determineChartFilterState,
  formatWidgetTime,
  formatWidgetTimeToMS,
  getPageLoadTimeChartData,
  getPageStatusCodeChartData,
  getWebVitalsColorClass,
  getWebVitalsDecimalsCount,
  getWebVitalsDivider,
  getWebVitalsStats
} from './page-summary.helpers';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import {
  AVG_LOAD_TIME_CHART_CONFIG,
  BROKEN_FINAL_PAGES_CHART_CONFIG,
  BROKEN_INITIAL_PAGES_CHART_CONFIG,
  EPageSummaryChartTypes,
  EPageSummaryTrendNames,
  finalPageStatusCodesChartTitle,
  FIRST_CONTENTFUL_PAINT_CHART_CONFIG,
  initialPageStatusCodesChartTitle,
  LARGEST_CONTENTFUL_PAINT_CHART_CONFIG,
  CUMULATIVE_LAYOUT_SHIFT_CHART_CONFIG,
  PAGE_SUMMARY_EXPORT_TYPE,
  pageLoadTimeChartTitle,
  pageLoadTimeFilterOptions,
  PAGES_WITH_BROKEN_LINKS_CHART_CONFIG,
  PagesTableSettingItems,
  PageSummaryRelevantFilters,
  TIME_TO_FIRST_BYTE_CHART_CONFIG,
  WIDGET_CUMULATIVE_LAYOUT_SHIFT_BOUNDARIES,
  WIDGET_FIRST_CONTENTFUL_PAINT_BOUNDARIES,
  WIDGET_LARGEST_CONTENTFUL_PAINT_BOUNDARIES,
  WIDGET_TIME_TO_FIRST_BYTE_BOUNDARIES,
} from '@app/components/audit-reports/reports/page-summary/page-summary.constants';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { PageSummaryReportService } from './page-summary.service';
import { DecimalPipe, formatNumber } from '@angular/common';
import { IPageListPage } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import {
  EChartType,
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalService
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.service';
import { sortBy } from 'lodash-es';
import { AuditReportScrollService } from '../../audit-report-scroll.service';
import {
  IAuditReportExportMenuData
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import { bytesToMB, convertTimeWithPrecision } from '@app/components/utilities/number.utils';
import { ICommonTableState } from '@app/components/shared/components/export-report/export-reports.models';
import { IAuditReportPageDetailsDrawerService } from '../../audit-report/audit-report-page-details-drawer.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import {
  AlertMetricType,
  EAlertPageSummaryMetric,
  EAlertPageSummaryWebVitalsMetric
} from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
    CommonPagesColumnConfigWarningMessage, CommonPagesConfigLocalStorageKey,
    CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import {
  ISparklineChartColorizedBoundaries
} from '@app/components/shared/components/viz/sparkline-chart-colorized/sparkline-chart-colorized.models';
import {
  EWebVitalsMetricType,
  WEB_VITALS_WIDGET_TOOLTIPS
} from '@app/components/shared/components/viz/web-vitals-chart/web-vitals-chart.constants';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  EPageInfoTrendNames,
  EPageInfoTrendNamesToDigitsInfo
} from '@app/components/audit-reports/page-information/page-information.constants';

interface IFiltersUpdate {
  filters: IAuditReportApiPostBody
}

@Component({
  selector: 'op-page-summary',
  templateUrl: './page-summary.component.html',
  styleUrls: ['./page-summary.component.scss'],
  providers: [ResizeableTableService]
})
export class PageSummaryComponent extends AuditReportBase implements IFilterableAuditReport, AfterViewInit, OnDestroy, OnInit {
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  readonly PagesTableSettingItems = PagesTableSettingItems;
  readonly EFilterSpinnerState = EFilterSpinnerState;
  readonly TableColumn = CommonReportsPagesTableColumns;
  readonly WEB_VITALS_WIDGET_TOOLTIPS = WEB_VITALS_WIDGET_TOOLTIPS;
  readonly EWebVitalsMetricType = EWebVitalsMetricType;

  // GENERAL
  auditId: number;
  runId: number;
  private isLoaded: boolean;
  private pageInsightsApiFilters: IAuditReportApiPostBody = {};
  private filtersUpdated$ = new Subject<IFiltersUpdate>();
  PageLoadWidgetTooltip = PageLoadWidgetTooltip;
  PageLoadColumnTooltip = PageLoadColumnTooltip;

  // webVitals tooltip
  webVitalsIconHovered: boolean = false;
  webVitalsTimeout: any = {};
  webVitalsTooltipPositionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: -1,
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: null,
    },
  ];

  // pages table
  displayedColumns$ = this.tableService.displayedColumns$;

  dataSource = new MatTableDataSource<IPageSummaryTableRow>();
  tableState: EFilterSpinnerState;
  private pagesTablePaginationState: ICommonTableState = {
    page: 0,
    size: 200,
    sortBy: CommonReportsPagesTableColumns.PageUrl,
    sortDesc: false
  };
  pageIdOpenInPageDetails: string;

  // pages table paginator
  totalNumPages: number | string;
  pageSize = 200;

  // charts
  pageLoadBarChartState: EFilterSpinnerState;
  pageLoadTimeChartData = getPageLoadTimeChartData();
  readonly pageLoadTimeChartTitle = pageLoadTimeChartTitle;

  initialPageStatusCodeBarChartState: EFilterSpinnerState;
  initialPageStatusCodesChartData = getPageStatusCodeChartData();
  readonly initialPageStatusCodesChartTitle = initialPageStatusCodesChartTitle;

  finalPageStatusCodeBarChartState: EFilterSpinnerState;
  finalPageStatusCodesChartData = getPageStatusCodeChartData();
  readonly finalPageStatusCodesChartTitle = finalPageStatusCodesChartTitle;

  // widgets
  widgetState: EFilterSpinnerState;
  sparklineDataLoaded = false;

  widgetPageScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    metricType: EAlertPageSummaryMetric.PagesScanned,
  };
  pagesFilteredCount: number = null;
  pagesTotalCount: number = null;

  widgetSparklineRunInfos: ISparklineRunInfo[] = [];

  widgetAvgPageLoadTime: ISplitCardChartData = {
    topLabel: 'Average Page Load Time',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openLoadTimeFullscreenChart.bind(this),
    metricType: EAlertPageSummaryMetric.AveragePageLoadTime,
  };
  widgetAvgPageLoadTimeSparklineData: ISparklineChartData[] = [];

  widgetBrokenInitialPages: ISplitCardChartData = {
    topLabel: 'Broken Initial Pages',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: () => {
      if (this.isFilteredByBrokenInitialPages) {
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.InitialPageStatusCode);
      } else {
        this.filterBarService.addPageStatusCodeFilter(EStatusCodeCategories.Broken);
      }
    },
    bottomHandler: this.openBrokenInitialPagesFullscreenChart.bind(this),
    includeFullscreenChart: true,
    metricType: EAlertPageSummaryMetric.BrokenInitialPages,
  };
  widgetBrokenInitialPagesSparklineData: ISparklineChartData[] = [];

  widgetBrokenFinalPages: ISplitCardChartData = {
    topLabel: 'Broken Final Pages',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: () => {
      if (this.isFilteredByBrokenFinalPages) {
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.FinalPageStatusCode);
      } else {
        this.filterBarService.addFinalPageStatusCodeFilter(EStatusCodeCategories.Broken);
      }

    },
    bottomHandler: this.openBrokenFinalPagesFullscreenChart.bind(this),
    includeFullscreenChart: true,
    metricType: EAlertPageSummaryMetric.BrokenFinalPages,
  };
  widgetBrokenFinalPagesSparklineData: ISparklineChartData[] = [];

  widgetPagesWithBrokenLinks: ISplitCardChartData = {
    topLabel: 'Pages with Broken Links',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: () => {
      if (this.isFilteredByPagesWithBrokenLinks) {
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.PagesWithBrokenLinks);
      } else {
        this.filterBarService.addPagesWithBrokenLinksFilter();
      }
    },
    bottomHandler: this.openPagesWithBrokenLinksFullscreenChart.bind(this),
    metricType: EAlertPageSummaryMetric.PagesWithBrokenLinks,
  };
  widgetPagesWithBrokenLinksSparklineData: ISparklineChartData[] = [];

  // Web Vitals widgets
  widgetLargestContentfulPaint: ISplitCardChartData = {
    topLabel: 'Largest Contentful Paint',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    uniqueId: 'page-summary-lcp-sparkline',
    topHandler: () => {},
    bottomHandler: this.openLargestContentfulPaintFullscreenChart.bind(this),
    metricType: EAlertPageSummaryWebVitalsMetric.LargestContentfulPaint,
    fullscreenChartHandler: this.openLargestContentfulPaintFullscreenChart.bind(this, EChartType.WebVitalsBoxPlot),
    digitsInfo: EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.LARGEST_CONTENTFUL_PAINT],
  };
  widgetLargestContentfulPaintSparklineData: ISparklineChartData[] = [];
  widgetLargestContentfulPaintBoundaries: ISparklineChartColorizedBoundaries = WIDGET_LARGEST_CONTENTFUL_PAINT_BOUNDARIES;

  widgetFirstContentfulPaint: ISplitCardChartData = {
    topLabel: 'First Contentful Paint',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    uniqueId: 'page-summary-fcp-sparkline',
    topHandler: () => {},
    bottomHandler: this.openFirstContentfulPaintFullscreenChart.bind(this),
    metricType: EAlertPageSummaryWebVitalsMetric.FirstContentfulPaint,
    fullscreenChartHandler: this.openFirstContentfulPaintFullscreenChart.bind(this, EChartType.WebVitalsBoxPlot),
    digitsInfo: EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.FIRST_CONTENTFUL_PAINT],
  };
  widgetFirstContentfulPaintSparklineData: ISparklineChartData[] = [];
  widgetFirstContentfulPaintBoundaries: ISparklineChartColorizedBoundaries = WIDGET_FIRST_CONTENTFUL_PAINT_BOUNDARIES;

  widgetTimeToFirstByte: ISplitCardChartData = {
    topLabel: 'Time To First Byte',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    uniqueId: 'page-summary-ttfb-sparkline',
    topHandler: () => {},
    bottomHandler: this.openTimeToFirstByteFullscreenChart.bind(this),
    metricType: EAlertPageSummaryWebVitalsMetric.TimeToFirstByte,
    fullscreenChartHandler: this.openTimeToFirstByteFullscreenChart.bind(this, EChartType.WebVitalsBoxPlot),
    digitsInfo: EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.TIME_TO_FIRST_BYTE],
  };
  widgetTimeToFirstByteSparklineData: ISparklineChartData[] = [];
  widgetTimeToFirstByteBoundaries = WIDGET_TIME_TO_FIRST_BYTE_BOUNDARIES;

  widgetCumulativeLayoutShift: ISplitCardChartData = {
    topLabel: 'Cumulative Layout Shift',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    uniqueId: 'page-summary-cls-sparkline',
    topHandler: () => {},
    bottomHandler: this.openCumulativeLayoutShiftFullscreenChart.bind(this),
    metricType: EAlertPageSummaryWebVitalsMetric.CumulativeLayoutShift,
    fullscreenChartHandler: this.openCumulativeLayoutShiftFullscreenChart.bind(this, EChartType.WebVitalsBoxPlot),
    digitsInfo: EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.CUMULATIVE_LAYOUT_SHIFT],
  };
  widgetCumulativeLayoutShiftSparklineData: ISparklineChartData[] = [];
  widgetCumulativeLayoutShiftBoundaries = WIDGET_CUMULATIVE_LAYOUT_SHIFT_BOUNDARIES;

  // exports
  exportPagesConfig: IAuditReportExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: PAGE_SUMMARY_EXPORT_TYPE,
    totalRows: this.pagesTotalCount,
    filteredRows: this.pagesFilteredCount,
    tableState: this.pagesTablePaginationState,
    filters: this.pageInsightsApiFilters,
    dataToCopy: {
      config: [
        {
          property: 'url',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl
        },
        {
          property: 'finalPageUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
        },
        {
          property: 'initialPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.PageStatusCode
        },
        {
          property: 'redirectCount',
          tableColumnName: CommonReportsPagesTableColumns.RedirectCount
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
        },
        {
          property: 'loadTime',
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime
        },
        {
          title: 'PAGE SIZE (mb)',
          tableColumnName: CommonReportsPagesTableColumns.Size,
          property: 'size',
        },
        {
          title: 'LARGEST CONTENTFUL PAINT (ms)',
          tableColumnName: CommonReportsPagesTableColumns.LargestContentfulPaint,
          property: 'largestContentfulPaint',
        },
        {
          title: 'FIRST CONTENTFUL PAINT (ms)',
          tableColumnName: CommonReportsPagesTableColumns.FirstContentfulPaint,
          property: 'firstContentfulPaint',
        },
        {
          title: 'TIME TO FIRST BYTE (ms)',
          tableColumnName: CommonReportsPagesTableColumns.TimeToFirstByte,
          property: 'timeToFirstByte',
        },
        {
          title: 'CUMULATIVE LAYOUT SHIFT',
          tableColumnName: CommonReportsPagesTableColumns.CumulativeLayoutShift,
          property: 'cumulativeLayoutShift',
        }
      ],
      data: null,
      displayedColumns$: this.tableService.displayedColumns$
    }
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('pageSummaryTableScrollTo', {read: ElementRef}) summaryTableScrollTo: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private auditReportService: AuditReportService,
    private pageSummaryService: PageSummaryReportService,
    private auditReportLoadingSvc: AuditReportLoadingService,
    private modalService: OpModalService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private scrollService: AuditReportScrollService,
    private decimalPipe: DecimalPipe,
    private alertReportingService: AlertReportingService,
    private cdr: ChangeDetectorRef,
    private tableService: ResizeableTableService,
  ) {
    super();

    this.route.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;
      const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();

      const alertId = queryParams?.alertId;
      const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
      this.preventHighlight = !highlight;
      // When loading report from an alert, first override filters with alert
      // filters before loading report data
      if (alertId) {
        this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
          this.filterBarService.overrideFilters(alert.config.filtersV0);
          this.highlightMetricType = highlight && alert.config.metricType;

          this.alertCheckComplete$.next(true);
        });
      } else {
        this.alertCheckComplete$.next(true);
      }

      if (this.isLoaded) {
        // only fire when calendar control updated
        this.handleSparklines();
        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          this.onFiltersChanged(this.pageInsightsApiFilters);
        });
      }
    });

    this.widgetState = EFilterSpinnerState.Loading;
    this.tableState = EFilterSpinnerState.Loading;
  }

  ngOnInit(): void {
    // setup for page details
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.PageInformation);

    // setup filters
    this.initFilters();
    this.handleSparklines();
    this.formatPaginator();
    this.isLoaded = true;

    this.pageDetailsDrawerService.pageDrawerClosed$.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handlePageDetailsClosed());
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.handleFilteringSortingPaging();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.auditReportLoadingSvc.forceOff();
    this.destroy();
    this.onDestroy$.next();

    this.pageDetailsDrawerService.closePageDetails();
  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(PageSummaryRelevantFilters);

    // ReplaySubject in filterBarService means this executes immediately
    // TODO: This may need to get triggered from ngOnInit instead of C'tor
    this.filterBarService.apiPostBody$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody) {
    // trigger table update
    this.pageInsightsApiFilters = apiPostBody;
    this.filtersUpdated$.next({ filters: apiPostBody });
    this.updateCurrentFilters();

    if (this.paginator) this.paginator.pageIndex = 0;
    this.resetQueryParams();
    this.handleCharts();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  resetQueryParams(): void {
    this.pagesTablePaginationState = {
      page: 0,
      size: 200,
      sortBy: CommonReportsPagesTableColumns.PageUrl,
      sortDesc: false
    };
  }

  initTable(auditPages: IPageSummaryPage[]): void {
    const noValuePlaceholder = '---';
    this.exportPagesConfig.dataToCopy.data = this.dataSource.data = auditPages.map(page => {
      const loadTime = Number.isInteger(page.pageLoadTime)
        ? parseFloat((page.pageLoadTime / 1000)?.toFixed(1))
        : null;
      const initialPageStatusCode = page.initialPageStatusCode ?? page.statusCode;
      const finalPageStatusCode = page.finalPageStatusCode ?? null;

      const pipe = new DecimalPipe('en-US');

      const largestContentfulPaintSeconds = page.largestContentfulPaint
        ? (page.largestContentfulPaint / 1000)
        : null;
      const firstContentfulPaintSeconds = page.firstContentfulPaint
        ? (page.firstContentfulPaint / 1000)
        : null;

      return {
        id: page.pageId,
        url: page.pageUrl,
        finalPageUrl: page.finalPageUrl,
        title: page.pageTitle,

        loadTime: loadTime ?? noValuePlaceholder,
        loadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(loadTime),

        initialPageStatusCode,
        initialPageStatusCodeClass: this.auditReportService.getStatusCodeClass(initialPageStatusCode),
        initialPageStatusCodeTooltip: PageStatusCodeTooltipMap[initialPageStatusCode] || null,

        finalPageStatusCode: finalPageStatusCode ?? noValuePlaceholder,
        finalPageStatusCodeClass: this.auditReportService.getStatusCodeClass(page.finalPageStatusCode),
        finalPageStatusCodeTooltip: PageStatusCodeTooltipMap[finalPageStatusCode] || null,

        redirectCount: page.redirectCount ?? noValuePlaceholder,
        redirectCountClass: page.redirectCount > 0 ? 'has-redirects' : '',

        largestContentfulPaint: largestContentfulPaintSeconds?.toFixed(3) ?? noValuePlaceholder,
        largestContentfulPaintClass: getWebVitalsColorClass(
          largestContentfulPaintSeconds,
          2.5,
          4,
        ),

        firstContentfulPaint: firstContentfulPaintSeconds?.toFixed(3) ?? noValuePlaceholder,
        firstContentfulPaintClass: getWebVitalsColorClass(
          firstContentfulPaintSeconds,
          1.8,
          3,
        ),

        cumulativeLayoutShift: pipe.transform(page.cumulativeLayoutShift) ?? noValuePlaceholder,
        cumulativeLayoutShiftPaintClass: getWebVitalsColorClass(
          page.cumulativeLayoutShift,
          .1,
          .25,
        ),

        timeToFirstByte: pipe.transform(page.timeToFirstByte) ?? noValuePlaceholder,
        timeToFirstByteClass: getWebVitalsColorClass(
          page.timeToFirstByte,
          800,
          1800,
        ),

        size: Number.isInteger(page.size) ? bytesToMB(page.size) : noValuePlaceholder,
      } as IPageSummaryTableRow;
    });
  }

  handleFilteringSortingPaging(): void {
    // update loading states for initial page load
    // because tap() doesn't fire then
    this.auditReportLoadingSvc.addLoadingToken();
    this.tableState = EFilterSpinnerState.Loading;

    this.alertCheckComplete$.subscribe(() => {
      merge(
        this.sort.sortChange,
        this.paginator.page,
        this.filtersUpdated$
      )
      .pipe(
        tap(() => {
          // update loading states each time filters are updated
          this.auditReportLoadingSvc.addLoadingToken();
          this.tableState = EFilterSpinnerState.Loading;
        }),
        startWith({}),
        mergeMap((mergedEvent: Sort & PageEvent & IFiltersUpdate ) => {
          if (mergedEvent !== undefined) {
            if (typeof mergedEvent.pageIndex === 'number') {
              this.resetPaginator(mergedEvent.pageIndex);
              this.scrollService.scrollByElement(this.summaryTableScrollTo.nativeElement);
            }
            if (mergedEvent.active) {
              this.pagesTablePaginationState.sortBy = mergedEvent.active;
              this.pagesTablePaginationState.sortDesc = mergedEvent.direction === 'desc';
              this.resetPaginator(0);
            }
            if (mergedEvent.filters) {
              this.resetPaginator(0);
            }
          }
          return this.pageSummaryService.getAuditPageSummaryInsightsByPage(this.auditId, this.runId, this.pagesTablePaginationState, this.pageInsightsApiFilters);
        }),
        catchError(() => {
          this.tableState = EFilterSpinnerState.None;
          return of({
            metadata: { pagination: { totalCount: 0 } },
            pages: []
          } as IPageSummaryInsightsByPage);
        }),
        tap(() => {
          this.auditReportLoadingSvc.removeLoadingToken();
        })
      )
      .subscribe(({ metadata, pages }) => {
        this.totalNumPages = metadata.pagination.totalCount;
        this.initTable(pages);
        this.updateExportButtonConfig();

        this.tableState = this.filterBarService.currentRelevantFilters.length
          ? EFilterSpinnerState.Filtered
          : EFilterSpinnerState.None;
      });
    });

  }

  openPageDetails(page: IPageListPage, tab?: EPageDetailsTabs) {
    this.pageIdOpenInPageDetails = page.id;
    this.pageDetailsDrawerService.openPageDetails(page, this.auditId, this.runId, tab);
  }

  get isFilteredByBrokenInitialPages() {
    return this.filterBarService.isFilteredByTypeAndValue(
      EAuditReportFilterTypes.InitialPageStatusCode,
      EStatusCodeCategories.Broken
    );
  }

  get isFilteredByBrokenFinalPages() {
    return this.filterBarService.isFilteredByTypeAndValue(
      EAuditReportFilterTypes.FinalPageStatusCode,
      statusCodeCategoryToRangeMap.get(EStatusCodeCategories.Broken)
    );
  }

  get isFilteredByPagesWithBrokenLinks() {
    return this.filterBarService.isFilteredByTypeAndValue(EAuditReportFilterTypes.PagesWithBrokenLinks, true);
  }

  handlePageDetailsClosed() {
    this.pageIdOpenInPageDetails = null;
  }

  handleCharts(): void {
    // update loading states
    this.auditReportLoadingSvc.addLoadingToken();
    this.pageLoadBarChartState = EFilterSpinnerState.Loading;
    this.initialPageStatusCodeBarChartState = EFilterSpinnerState.Loading;
    this.finalPageStatusCodeBarChartState = EFilterSpinnerState.Loading;
    this.widgetState = EFilterSpinnerState.Loading;

    this.pageSummaryService
      .getAuditPageSummaryInsights(this.auditId, this.runId, this.pageInsightsApiFilters)
      .pipe(catchError(() => of(undefined)))
      .subscribe((insights: IPageSummaryInsights | undefined) => {
        if (insights) {
          this.updateWidgets(insights);
          this.formatDataForLoadTimeChart(insights.pageCountByLoadTimes);
          this.formatDataForInitialPageStatusCodeChart(insights.pageCountsByInitialStatusCodes);
          this.formatDataForFinalPageStatusCodeChart(insights.pageCountsByFinalStatusCodes);
        } else {
          alert('Sorry! Some elements failed to update. Refresh your browser to try again.');
        }

        // update loading states
        this.auditReportLoadingSvc.removeLoadingToken();

        const { currentRelevantFilters } = this.filterBarService;
        this.pageLoadBarChartState = determineChartFilterState(EPageSummaryChartTypes.PageLoad, currentRelevantFilters);
        this.initialPageStatusCodeBarChartState = determineChartFilterState(EPageSummaryChartTypes.InitialPageStatusCode, currentRelevantFilters);
        this.finalPageStatusCodeBarChartState = determineChartFilterState(EPageSummaryChartTypes.FinalPageStatusCode, currentRelevantFilters);

        this.widgetState = this.filterBarService.currentRelevantFilters.length
          ? EFilterSpinnerState.Filtered
          : EFilterSpinnerState.None;
      });
  }

  updateWidgets(insights: IPageSummaryInsights): void {
    this.pagesFilteredCount = insights.pagesFiltered;

    if (this.pagesTotalCount === null) {
      this.exportPagesConfig.totalRows = insights.totalPages;
    }
    this.pagesTotalCount = insights.totalPages;

    this.widgetAvgPageLoadTime.topChangeContent = formatWidgetTime(insights.averagePageLoadTime);
    if (insights.averagePageLoadTime < 3000) {
      this.widgetAvgPageLoadTime.topChangeMeaning = ESplitCardChangeMeaning.POSITIVE;
    } else if (insights.averagePageLoadTime < 6000) {
      this.widgetAvgPageLoadTime.topChangeMeaning = ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else if (insights.averagePageLoadTime < 10000) {
      this.widgetAvgPageLoadTime.topChangeMeaning = ESplitCardChangeMeaning.SORT_OF_NEGATIVE;
    } else {
      this.widgetAvgPageLoadTime.topChangeMeaning = ESplitCardChangeMeaning.NEGATIVE;
    }

    this.widgetBrokenInitialPages.topChangeContent = formatNumber(insights.pagesWithBrokenInitialStatusCode || 0, 'en-us');
    this.widgetBrokenInitialPages.topChangeMeaning = insights.pagesWithBrokenInitialStatusCode > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;

    this.widgetBrokenFinalPages.topChangeContent = formatNumber(insights.pagesWithBrokenFinalStatusCode || 0, 'en-us');
    this.widgetBrokenFinalPages.topChangeMeaning = insights.pagesWithBrokenFinalStatusCode > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;

    this.widgetPagesWithBrokenLinks.topChangeContent = formatNumber(insights.pagesWithBrokenLinks || 0, 'en-us');
    this.widgetPagesWithBrokenLinks.topChangeMeaning = insights.pagesWithBrokenLinks > 0 ? ESplitCardChangeMeaning.NEGATIVE : ESplitCardChangeMeaning.POSITIVE;

    this.widgetLargestContentfulPaint.topChangeContent = formatWidgetTime(
      insights.webVitals.p75LargestContentfulPaint,
      EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.LARGEST_CONTENTFUL_PAINT],
    );
    this.widgetFirstContentfulPaint.topChangeContent = formatWidgetTime(
      insights.webVitals.p75FirstContentfulPaint,
      EPageInfoTrendNamesToDigitsInfo[EPageInfoTrendNames.FIRST_CONTENTFUL_PAINT],
    );
    this.widgetTimeToFirstByte.topChangeContent = formatWidgetTimeToMS(insights.webVitals.p75TimeToFirstByte);
    this.widgetCumulativeLayoutShift.topChangeContent = `${insights.webVitals.p75CumulativeLayoutShift}`;


    this.updateWebVitalsWidgets(insights.webVitals.p75LargestContentfulPaint / 1000, insights.webVitals.p75FirstContentfulPaint / 1000, insights.webVitals.p75TimeToFirstByte, insights.webVitals.p75CumulativeLayoutShift);
  }

  handleSparklines(): void {
    this.auditReportLoadingSvc.addLoadingToken();
    this.sparklineDataLoaded = false;

    forkJoin([
        this.pageSummaryService.getAuditPageSummaryTrends(this.auditId, this.runId).pipe(map(trends => sortBy(trends.runs, element => element.runId)), catchError(() => of(undefined))),
        this.pageSummaryService.getAuditSummaryWebVitalsTrends(this.auditId, this.runId).pipe(map(trends => sortBy(trends.runs, element => element.runId)), catchError(() => of(undefined)))
    ]).subscribe(([dataPointsTrends, dataPointsWebVitals]: [IPageSummaryTrendsRun[], IPageSummaryWebVitalsTrendsRun[]]) => {
        if (dataPointsTrends) {
          const pageLoadTimes: ISparklineChartData[] = [];
          const brokenInitialPages: ISparklineChartData[] = [];
          const brokenFinalPages: ISparklineChartData[] = [];
          const pagesWithBrokenLinks: ISparklineChartData[] = [];

          const runInfos: ISparklineRunInfo[] = [];

          dataPointsTrends.forEach((dataPoint, index) => {
            const avgLoadTimeSec = Math.round(dataPoint.averagePageLoadTime / 10) / 100;
            pageLoadTimes.push({ value: avgLoadTimeSec, sequence: index });
            brokenInitialPages.push({ value: dataPoint.pagesWithBrokenInitialStatusCode, sequence: index });
            brokenFinalPages.push({ value: dataPoint.pagesWithBrokenFinalStatusCode, sequence: index });
            pagesWithBrokenLinks.push({ value: dataPoint.pagesWithBrokenLinks, sequence: index });

            runInfos.push({ runId: dataPoint.runId, runCompletionDate: dataPoint.completedAt });
          });

          this.widgetAvgPageLoadTime.bottomHandler = pageLoadTimes.length < 2 ? null : this.widgetAvgPageLoadTime.bottomHandler;
          this.widgetBrokenInitialPages.bottomHandler = brokenInitialPages.length < 2 ? null : this.widgetBrokenInitialPages.bottomHandler;
          this.widgetBrokenFinalPages.bottomHandler = brokenFinalPages.length < 2 ? null : this.widgetBrokenFinalPages.bottomHandler;
          this.widgetPagesWithBrokenLinks.bottomHandler = pagesWithBrokenLinks.length < 2 ? null : this.widgetPagesWithBrokenLinks.bottomHandler;

          this.widgetAvgPageLoadTimeSparklineData = pageLoadTimes;
          this.widgetBrokenInitialPagesSparklineData = brokenInitialPages;
          this.widgetBrokenFinalPagesSparklineData = brokenFinalPages;
          this.widgetPagesWithBrokenLinksSparklineData = pagesWithBrokenLinks;

          this.widgetSparklineRunInfos = [...this.widgetSparklineRunInfos, ...runInfos];
        }

        if (dataPointsWebVitals) {
          if (!dataPointsWebVitals.every(dp => Object.entries(dp.firstContentfulPaintStats).length ||
            Object.entries(dp.largestContentfulPaintStats).length ||
            Object.entries(dp.timeToFirstByteStats).length ||
            Object.entries(dp.cumulativeLayoutShiftStats).length)) {
            return;
          }

          const cumulativeLayoutShiftStats: ISparklineChartData[] = [];
          const firstContentfulPaintStats: ISparklineChartData[] = [];
          const largestContentfulPaintStats: ISparklineChartData[] = [];
          const timeToFirstByteStats: ISparklineChartData[] = [];

          const runInfos: ISparklineRunInfo[] = [];

          dataPointsWebVitals.forEach((dataPoint, index) => {
            largestContentfulPaintStats.push({ value: dataPoint.largestContentfulPaintStats.p75, sequence: index });
            firstContentfulPaintStats.push({ value: dataPoint.firstContentfulPaintStats.p75, sequence: index });
            timeToFirstByteStats.push({ value: dataPoint.timeToFirstByteStats.p75, sequence: index });
            cumulativeLayoutShiftStats.push({ value: dataPoint.cumulativeLayoutShiftStats.p75, sequence: index });

            runInfos.push({ runId: dataPoint.runId, runCompletionDate: dataPoint.completedAt });
          });

          const MLCP = largestContentfulPaintStats[largestContentfulPaintStats.length - 1]?.value ?? 0;
          const MFCP = firstContentfulPaintStats[firstContentfulPaintStats.length - 1]?.value ?? 0;
          const MTTFB = timeToFirstByteStats[timeToFirstByteStats.length - 1]?.value ?? 0;
          const MCLS = cumulativeLayoutShiftStats[cumulativeLayoutShiftStats.length - 1]?.value ?? 0;

          if(!this.currentFilters.length) {
            this.widgetLargestContentfulPaint.topChangeContent = largestContentfulPaintStats.length ? formatWidgetTime(MLCP ?? 0) : '---';
            this.widgetFirstContentfulPaint.topChangeContent = firstContentfulPaintStats.length ? formatWidgetTime(MFCP) : '---';
            this.widgetTimeToFirstByte.topChangeContent = timeToFirstByteStats.length ? formatWidgetTimeToMS(MTTFB) : '---';
            this.widgetCumulativeLayoutShift.topChangeContent = cumulativeLayoutShiftStats.length ? `${MCLS}` : '---';
          }

          const webVitalsInSeconds = (item: IWebVitalsTrendData): IWebVitalsTrendData => ({
            average: item?.average / 1000,
            max: item?.max / 1000,
            median: item?.median / 1000,
            min: item?.min / 1000,
            p25: item?.p25 / 1000,
            p75: item?.p75 / 1000,
          });

          this.widgetLargestContentfulPaint.boxPlotDataConfig = {
            stats: webVitalsInSeconds(dataPointsWebVitals.find(i => i.runId === this.runId)?.largestContentfulPaintStats),
            boundaries: [0, 2.5, 4, 6],
            svgBoxSelectorName: 'largest-contentful-paint-box-plot',
          };

          this.widgetFirstContentfulPaint.boxPlotDataConfig = {
            stats: webVitalsInSeconds(dataPointsWebVitals.find(i => i.runId === this.runId)?.firstContentfulPaintStats),
            boundaries: [0, 1.8, 3, 6],
            svgBoxSelectorName: 'first-contentful-paint-box-plot',
          };

          this.widgetTimeToFirstByte.boxPlotDataConfig = {
            stats: webVitalsInSeconds(dataPointsWebVitals.find(i => i.runId === this.runId)?.timeToFirstByteStats),
            boundaries: [0, 0.8, 1.8, 4],
            svgBoxSelectorName: 'time-to-first-byte-box-plot',
          };

          this.widgetCumulativeLayoutShift.boxPlotDataConfig = {
            stats: dataPointsWebVitals.find(i => i.runId === this.runId)?.cumulativeLayoutShiftStats,
            boundaries: [0, .1, .25, .6],
            svgBoxSelectorName: 'cumulative-layout-shift-box-plot',
          };

          this.widgetLargestContentfulPaintSparklineData = largestContentfulPaintStats.map(el => ({ ...el, value: el.value / 1000 }));
          this.widgetFirstContentfulPaintSparklineData = firstContentfulPaintStats.map(el => ({ ...el, value: el.value / 1000 }));
          this.widgetTimeToFirstByteSparklineData = timeToFirstByteStats.map(el => ({ ...el, value: el.value }));
          this.widgetCumulativeLayoutShiftSparklineData = cumulativeLayoutShiftStats;

          this.widgetLargestContentfulPaint.bottomHandler = largestContentfulPaintStats.length < 2 ? null : this.widgetLargestContentfulPaint.bottomHandler;
          this.widgetFirstContentfulPaint.bottomHandler = firstContentfulPaintStats.length < 2 ? null : this.widgetFirstContentfulPaint.bottomHandler;
          this.widgetTimeToFirstByte.bottomHandler = largestContentfulPaintStats.length < 2 ? null : this.widgetTimeToFirstByte.bottomHandler;
          this.widgetCumulativeLayoutShift.bottomHandler = cumulativeLayoutShiftStats.length < 2 ? null : this.widgetCumulativeLayoutShift.bottomHandler;

          this.widgetSparklineRunInfos = [...this.widgetSparklineRunInfos, ...runInfos];

          if(!this.currentFilters.length) this.updateWebVitalsWidgets(MLCP / 1000, MFCP / 1000, MTTFB, MCLS);
        }

        this.sparklineDataLoaded = true;
        this.auditReportLoadingSvc.removeLoadingToken();
    });
  }

  private updateWebVitalsWidgets(largestContentfulPaint: number, firstContentfulPaint: number, timeToFirstByte: number, cumulativeLayoutShift: number): void {
    if (largestContentfulPaint < this.widgetLargestContentfulPaintBoundaries.warnThreshold) {
      this.widgetLargestContentfulPaint.topChangeMeaning = ESplitCardChangeMeaning.POSITIVE
    } else if (largestContentfulPaint >= this.widgetLargestContentfulPaintBoundaries.warnThreshold && largestContentfulPaint <= this.widgetLargestContentfulPaintBoundaries.failThreshold) {
      this.widgetLargestContentfulPaint.topChangeMeaning = ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else {
      this.widgetLargestContentfulPaint.topChangeMeaning = ESplitCardChangeMeaning.NEGATIVE;
    }

    if (firstContentfulPaint < this.widgetFirstContentfulPaintBoundaries.warnThreshold) {
      this.widgetFirstContentfulPaint.topChangeMeaning = ESplitCardChangeMeaning.POSITIVE
    } else if (firstContentfulPaint >= this.widgetFirstContentfulPaintBoundaries.warnThreshold && firstContentfulPaint <= this.widgetFirstContentfulPaintBoundaries.failThreshold) {
      this.widgetFirstContentfulPaint.topChangeMeaning = ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else {
      this.widgetFirstContentfulPaint.topChangeMeaning = ESplitCardChangeMeaning.NEGATIVE;
    }

    if (timeToFirstByte < this.widgetTimeToFirstByteBoundaries.warnThreshold) {
      this.widgetTimeToFirstByte.topChangeMeaning = ESplitCardChangeMeaning.POSITIVE
    } else if (timeToFirstByte >= this.widgetTimeToFirstByteBoundaries.warnThreshold && timeToFirstByte <= this.widgetTimeToFirstByteBoundaries.failThreshold) {
      this.widgetTimeToFirstByte.topChangeMeaning = ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else {
      this.widgetTimeToFirstByte.topChangeMeaning = ESplitCardChangeMeaning.NEGATIVE;
    }

    if (cumulativeLayoutShift < this.widgetCumulativeLayoutShiftBoundaries.warnThreshold) {
      this.widgetCumulativeLayoutShift.topChangeMeaning = ESplitCardChangeMeaning.POSITIVE
    } else if (cumulativeLayoutShift >= this.widgetCumulativeLayoutShiftBoundaries.warnThreshold && cumulativeLayoutShift <= this.widgetCumulativeLayoutShiftBoundaries.failThreshold) {
      this.widgetCumulativeLayoutShift.topChangeMeaning = ESplitCardChangeMeaning.SORT_OF_POSITIVE;
    } else {
      this.widgetCumulativeLayoutShift.topChangeMeaning = ESplitCardChangeMeaning.NEGATIVE;
    }
  }

  formatDataForLoadTimeChart(data: IPageSummaryLoadTimeChart): void {
    this.pageLoadTimeChartData = getPageLoadTimeChartData(data);

    if (this.pageInsightsApiFilters.hasOwnProperty(EAuditReportFilterTypes.PageLoadTime)) {
      const minLoadTimes = [0, 3000, 6000, 10000];
      const index = minLoadTimes.indexOf(this.pageInsightsApiFilters.pageLoadTime?.min);

      if (index && this.pageLoadTimeChartData[index]) {
        this.pageLoadTimeChartData[index].filtered = true;
        this.pageLoadTimeChartData = [ ...this.pageLoadTimeChartData ];
      }
    }
  }

  formatDataForInitialPageStatusCodeChart(data: IPageSummaryStatusCodeChart) {
    const { initialPageStatusCode } = this.pageInsightsApiFilters;
    const chartData = getPageStatusCodeChartData(data);
    this.initialPageStatusCodesChartData = this.formatDataForStatusCodeChart(chartData, initialPageStatusCode);
  }

  formatDataForFinalPageStatusCodeChart(data: IPageSummaryStatusCodeChart) {
    const { finalPageStatusCode } = this.pageInsightsApiFilters;
    const chartData = getPageStatusCodeChartData(data);
    this.finalPageStatusCodesChartData = this.formatDataForStatusCodeChart(chartData, finalPageStatusCode);
  }

  private formatDataForStatusCodeChart(chartData: IStatusCodeHorizontalBarChartDataPoint[], filter?: IPageStatusCodeFilter): IStatusCodeHorizontalBarChartDataPoint[] {
    if (!filter) return chartData;

    let updatedChartData = null;
    for (let [category, range] of statusCodeCategoryToRangeMap.entries()) {
      if (_isEqual(filter, range)) {
        updatedChartData = chartData.map(
          dataPoint => dataPoint.category === category ? { ...dataPoint, filtered: true } : dataPoint
        );
        break;
      }
    }

    return updatedChartData ?? chartData;
  }

  toggleLoadTimeFilter({ mouseEvent, item }: { mouseEvent: MouseEvent, item: IHorizontalBarChartDataPoint }): void {
    if (item.filtered) {
      const min = pageLoadTimeFilterOptions[item.name].min;
      const max = pageLoadTimeFilterOptions[item.name].max;

      this.filterBarService.addPageLoadTimeFilter(min, max);
    } else {
      this.filterBarService.removeFilterByType(EAuditReportFilterTypes.PageLoadTime);
    }
  }

  toggleInitialPageStatusCodeFilter({ item }: { item: IStatusCodeHorizontalBarChartDataPoint }) {
    if (item.filtered) {
      this.filterBarService.addPageStatusCodeFilter(EStatusCodeCategories[item.name]);
    } else {
      this.filterBarService.removeFilterByType(EAuditReportFilterTypes.InitialPageStatusCode);
    }
  }

  toggleFinalPageStatusCodeFilter({ item }: { item: IStatusCodeHorizontalBarChartDataPoint }) {
    if (item.filtered) {
      this.filterBarService.addFinalPageStatusCodeFilter(EStatusCodeCategories[item.name]);
    } else {
      this.filterBarService.removeFilterByType(EAuditReportFilterTypes.FinalPageStatusCode);
    }
  }

  get secondToLastCompletionDate(): string | undefined {
    return this.widgetSparklineRunInfos.length > 1 ? this.widgetSparklineRunInfos[this.widgetSparklineRunInfos.length - 2].runCompletionDate : undefined;
  }

  openLoadTimeFullscreenChart() {
    if (this.widgetAvgPageLoadTimeSparklineData.length > 1) {
      this.openFullscreenChart(
        EPageSummaryTrendNames.AVG_PAGE_LOAD_TIME,
        this.secondToLastCompletionDate,
        FullscreenChartModalService.getPageLoadTimeSummaryLines);
    }
  }

  openBrokenInitialPagesFullscreenChart() {
    if (this.widgetBrokenInitialPagesSparklineData.length > 1) {
      this.openFullscreenChart(
        EPageSummaryTrendNames.BROKEN_INITIAL_PAGES,
          this.secondToLastCompletionDate);
    }
  }

  openBrokenFinalPagesFullscreenChart() {
    if (this.widgetBrokenFinalPagesSparklineData.length > 1) {
      this.openFullscreenChart(
        EPageSummaryTrendNames.BROKEN_FINAL_PAGES,
          this.secondToLastCompletionDate);
    }
  }

  openPagesWithBrokenLinksFullscreenChart() {
    if (this.widgetPagesWithBrokenLinksSparklineData.length > 1) {
      this.openFullscreenChart(
        EPageSummaryTrendNames.PAGES_WITH_BROKEN_LINKS,
          this.secondToLastCompletionDate);
    }
  }

  openLargestContentfulPaintFullscreenChart(chartType: EChartType = EChartType.WebVitals): void {
    if (this.widgetLargestContentfulPaintSparklineData.length > 1) {
      this.openFullscreenWebVitalsChart(
          EPageSummaryTrendNames.LARGEST_CONTENTFUL_PAINT,
          this.secondToLastCompletionDate,
          chartType);
    }
  }

  openFirstContentfulPaintFullscreenChart(chartType: EChartType = EChartType.WebVitals): void {
    if (this.widgetFirstContentfulPaintSparklineData.length > 1) {
      this.openFullscreenWebVitalsChart(
          EPageSummaryTrendNames.FIRST_CONTENTFUL_PAINT,
          this.secondToLastCompletionDate,
          chartType);
    }
  }

  openTimeToFirstByteFullscreenChart(chartType: EChartType = EChartType.WebVitals): void {
    if (this.widgetTimeToFirstByteSparklineData.length > 1) {
      this.openFullscreenWebVitalsChart(
          EPageSummaryTrendNames.TIME_TO_FIRST_BYTE,
          this.secondToLastCompletionDate,
          chartType);
    }
  }

  openCumulativeLayoutShiftFullscreenChart(chartType: EChartType = EChartType.WebVitals): void {
    if (this.widgetCumulativeLayoutShiftSparklineData.length > 1) {
      this.openFullscreenWebVitalsChart(
          EPageSummaryTrendNames.CUMULATIVE_LAYOUT_SHIFT,
          this.secondToLastCompletionDate,
          chartType);
    }
  }

  openFullscreenChart(
      trendName: EPageSummaryTrendNames,
      secondToLastCompletionDate: string,
      getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName),
        metricType: trendName,
      }
    })
    .afterClosed()
    .subscribe(() => this.modalEscapeService.remove(index));
  }

  openFullscreenWebVitalsChart(
      trendName: EPageSummaryTrendNames,
      secondToLastCompletionDate: string,
      chartType: EChartType = EChartType.WebVitals,
      getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);
    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenWebVitalsChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName),
        chartType,
        tooltipStyle: 'web-vitals',
        metricType: trendName,
      }
    })
        .afterClosed()
        .subscribe(() => this.modalEscapeService.remove(index));
  }

  getFullscreenChartData(trendName: EPageSummaryTrendNames, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.pageSummaryService.getAuditPageSummaryInsightTrends(this.auditId, trendName, days)
      .pipe(
        map(({ runs }) => ({
            chartData: runs.map(dataPoint => {
              let val = dataPoint.trendValue;

              if (trendName === EPageSummaryTrendNames.AVG_PAGE_LOAD_TIME) {
                val = parseFloat((val / 1000)?.toFixed(1));
              }

              return {
                value: val,
                date: dataPoint.completedAt,
              };
            }),
          })
        ),
      );
  }

  getFullscreenWebVitalsChartData(
    trendName: EPageSummaryTrendNames,
    days: number,
    stat: keyof IWebVitalsTrendData = 'p75',
  ): Observable<IFullscreenChartDataWithStats> {
    const divider = getWebVitalsDivider(trendName);
    const decimalsCount = getWebVitalsDecimalsCount(trendName);

    return this.pageSummaryService.getAuditSummaryWebVitalsTrend(this.auditId, trendName, days)
      .pipe(
        map(({ runs }) => {
            const chartData = runs
              .map(run => ({
                  date: run.completedAt,
                  data: {
                    stats: convertStats(run.stats, divider, decimalsCount) as unknown as Record<string, number>,
                    distribution: run.distribution
                  },
                  value: convertTimeWithPrecision(run.stats[stat], divider, decimalsCount)
                })
              );

            const stats = getWebVitalsStats(chartData, trendName);

            return {
              chartData,
              stats,
            };
          }
        )
      );
  }

  getFullscreenChartConfig(trendName: EPageSummaryTrendNames) {
    switch (trendName) {
      case EPageSummaryTrendNames.AVG_PAGE_LOAD_TIME:
        return AVG_LOAD_TIME_CHART_CONFIG;
      case EPageSummaryTrendNames.BROKEN_INITIAL_PAGES:
        return BROKEN_INITIAL_PAGES_CHART_CONFIG;
      case EPageSummaryTrendNames.BROKEN_FINAL_PAGES:
        return BROKEN_FINAL_PAGES_CHART_CONFIG;
      case EPageSummaryTrendNames.PAGES_WITH_BROKEN_LINKS:
        return PAGES_WITH_BROKEN_LINKS_CHART_CONFIG;
      case EPageSummaryTrendNames.LARGEST_CONTENTFUL_PAINT:
        return LARGEST_CONTENTFUL_PAINT_CHART_CONFIG;
      case EPageSummaryTrendNames.FIRST_CONTENTFUL_PAINT:
        return FIRST_CONTENTFUL_PAINT_CHART_CONFIG;
      case EPageSummaryTrendNames.TIME_TO_FIRST_BYTE:
        return TIME_TO_FIRST_BYTE_CHART_CONFIG;
      case EPageSummaryTrendNames.CUMULATIVE_LAYOUT_SHIFT:
        return CUMULATIVE_LAYOUT_SHIFT_CHART_CONFIG;
      default:
        return null;
    }
  }

  getPageUrlTooltipPrefix(page: IPageSummaryTableRow): string {
    return page.title
      ? `View Page Details:\n ${page.title}`
      : 'View Page Details:';
  }

  webVitalsIconMouseEnter(): void {
    this.webVitalsIconHovered = true;
  }

  webVitalsIconMouseLeave(): void {
    this.webVitalsIconHovered = false;
  }

  webVitalsMouseHandle($event: MouseEvent): void {
    this.webVitalsTimeout = setTimeout(()=> {
      if (!['page-summary-web-vitals-75-percentile', 'cdk-overlay-pane'].some(el => ($event.relatedTarget as HTMLElement).className.includes(el))){
        this.webVitalsIconHovered = false;
      }
    },200);
  }

  webVitalsTooltipMouseEnter(): void {
    clearTimeout(this.webVitalsTimeout);
    this.webVitalsIconHovered = true;
  }

  private resetPaginator(pageNumber?: number): void {
    const p = pageNumber || 0;
    this.pagesTablePaginationState.page = p;
    this.paginator.pageIndex = p;
  }

  private formatPaginator(): void {
    if (!this.paginator) return;
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }

  private updateExportButtonConfig() {
    this.exportPagesConfig.tableState = {
      ...this.exportPagesConfig.tableState,
      page: this.paginator.pageIndex,
      sortBy: this.sort.active,
      sortDesc: this.sort.direction === 'desc'
    };

    this.exportPagesConfig.filters = this.pageInsightsApiFilters;
    this.exportPagesConfig.filteredRows = this.totalNumPages as number;
  }
}
