import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { ECookieOpChangeType } from '@app/components/audit-reports/page-details/page-details.enums';
import { ECookieSameSiteResponseTypes } from '@app/components/audit-reports/page-details/page-details.constants';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import {
  IAuditRequestBlockingResults,
  IJourneyRequestBlockingResults
} from '@app/components/audit-reports/page-details/page-details.models';

export interface IActionDetailsObject {
  itemId: number; // auditId or journeyId
  runId: number;
  actionIndex?: number; // specific to web journeys
  success?: boolean; // specific to web journeys
}

export interface IActionDetailsCookieResponse {
  create: IActionDetailsCookieObject[];
  insights: {
    noOfUniqueCookies?: number;
    noOfUniqueDomains?: number;
    noOf3rdPartyCookies?: number;
  }
}

export interface IActionDetailsCookieObject {
  name: string;
  value: string;
  domain: string;
  path: string;
  httpOnly: boolean;
  secure: boolean;
  samesite: ECookieSameSiteResponseTypes;
  expirationTimestamp: number;
  opChangeType?: ECookieOpChangeType;
  expandedInitiatorData?: boolean;
  initiators?: IActionDetailsCookieInitiator[];
  totalCookieActionCount?: number;
  totalCookieInitiatorCount?: number;
  partitionKey?: string;
}

export interface IActionDetailsCookieInitiator {
  initiatorType: string;
  tagId?: number;
}

export interface IActionDetailsRequestLogObject {
  time: number;
  request: {
    url: string;
    method: string;
    headers: { name: string, value: string };
    blockingResults?: IAuditRequestBlockingResults;
    requestBlocking?: IJourneyRequestBlockingResults;
  };
  response: {
    status: number;
    content: { size: number };
    headers: { name: string, value: string };
  };
  locationData: {
    country: string;
  };
}

export interface IActionDetailsConsoleLogObject {
  count: number;
  level: string;
  message: string;
  timestamp: number;
  source?: string;
  relatedTag?: {
    tagId: number;
    tagName: string;
    tagCategoryId: number;
    tagCategoryName: string;
  }
}

@Injectable()
export class WebJourneyActionDetailsService {

  updatedLogCountsSubject: Subject<boolean> = new Subject();
  updatedLogCounts$: Observable<boolean> = this.updatedLogCountsSubject.asObservable();

  requestLogCountSubject = new BehaviorSubject<string | number>('...');
  requestLogCount$ = this.requestLogCountSubject.asObservable();

  consoleLogCountSubject = new BehaviorSubject<string | number>('...');
  consoleLogCount$ = this.consoleLogCountSubject.asObservable();

  /**
   * Caching below based on the following:
   * https://medium.com/@dilanthaprasanjith/caching-http-requests-with-rxjs-in-angular-9f7fa44387ce
   */

  public responseCache = new Map();

  apiRoot: string = environment.apiV3Url + 'web-journeys';

  constructor(
    private apiService: ApiService,
    private cacheResetService: CacheResetService
  ) {
    this.cacheResetService.reset$.subscribe(_ => {
      this.responseCache.clear();
    });
  }

  @CacheApiResponse()
  getCookieData(journeyId: number, runId: number, actionSequence: number): Observable<IActionDetailsCookieResponse> {
    const requestUrl = `${this.apiRoot}/${journeyId}/runs/${runId}/actions/${actionSequence}/cookies`;

    return this.apiService.get<IActionDetailsCookieResponse>(requestUrl);
  }

  @CacheApiResponse()
  getCookieOriginData(
    journeyId: number,
    runId: number,
    name: string,
    domain: string,
    path: string
  ): Observable<IActionDetailsCookieResponse> {
    const requestUrl = `${this.apiRoot}/${journeyId}/runs/${runId}/cookies/origin`;
    const body = { name, domain, path };

    return this.apiService.post<IActionDetailsCookieResponse>(requestUrl, body);
  }

  @CacheApiResponse()
  getRequestLogs(journeyId: number, runId: number, actionSequence: number): Observable<IActionDetailsRequestLogObject[]> {
    const requestUrl = `${this.apiRoot}/${journeyId}/runs/${runId}/actions/${actionSequence}/requests`;
    return this.apiService.get<IActionDetailsRequestLogObject[]>(requestUrl);
  }

  @CacheApiResponse()
  getConsoleLogs(journeyId: number, runId: number, actionSequence: number): Observable<IActionDetailsConsoleLogObject[]> {
    const requestUrl = `${this.apiRoot}/${journeyId}/runs/${runId}/actions/${actionSequence}/logs`;
    return this.apiService.get<IActionDetailsConsoleLogObject[]>(requestUrl);
  }
}
