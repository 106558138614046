import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertChangeSuccessSnackbarService implements OnDestroy {

  private closeSnackbar: boolean;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.hideSnackbarOnRoute();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public closeSnackbarOnNextRouteChange(state: boolean): void {
    this.closeSnackbar = state;
  }

  private hideSnackbarOnRoute(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.closeSnackbar) {
        this.snackBar.dismiss();
        this.closeSnackbar = false;
      }
    });
  }
}