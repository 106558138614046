
export const auditExports = {
  actionSummary: {
    name: 'Action Summary',
    description: 'All sequenced actions and their action information.',
  },
  tagSummary: {
    name: 'Tag Summary',
    description: 'All actions and their Tag Inventory.',
  },
  tagAndVariableData: {
    name: 'Tag and Variable Data',
    description: 'All collected data for selected tags.',
  },
  rulesSummary: {
    name: 'Rule Summary',
    description: 'A summary of Journey and Action Rules.',
  },
  cookies: {
    name: 'Cookies',
    description: 'All cookies collected, broken down by action',
  },
  requestLog: {
    name: 'Request Log',
    description: 'All network requests collected, broken down by action.',
  },
  consoleLog: {
    name: 'Browser Console Logs',
    description: 'All console log messages  collected, broken down by action.',
  }
};
