import { Component, Input } from '@angular/core';
import { ESupportContactType, ISupportContact } from '@app/components/usage-v2/usage-v2.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-help-overlay',
  templateUrl: './usage-help-overlay.component.html',
  styleUrls: ['./usage-help-overlay.component.scss']
})
export class UsageHelpOverlayComponent {
  readonly ESupportContactType = ESupportContactType;
  readonly slackInviteLink = 'https://join.slack.com/t/observepointcommunity/shared_invite/zt-246rf4fj4-3ISj0TGqvjO_miLn6AFP5w';
  readonly slackBtnTooltip = 'Join our slack community to contact me with any questions.';
  readonly supportBtnSelector = 'usage-support-button';
  @Input() contact: ISupportContact;
}
