<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="'Confirm Change'"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="usage-overages-modal">
      <div class="question">
        <span class="question-icon">
          <mat-icon>question_mark</mat-icon>
        </span>
        <b>{{ USAGE_OVERAGES_QUESTION[type] }}</b>
      </div>
      <div class="explanation" [innerHTML]="USAGE_OVERAGES_EXPLANATIONS[type]"></div>
      <div class="confirm">
        Type out the word <span class="highlight">confirm</span> to proceed.
        <mat-form-field appearance="outline" class="confirm-form-field">
          <input
            matInput
            [(ngModel)]="confirmText"
            (keyup)="onConfirmTextChange()"
            placeholder="Type out the word confirm"
            class="confirm-input"
          >
        </mat-form-field>
      </div>
    </div>
  </ng-template>
</op-modal>