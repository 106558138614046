import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';

export const CommonPagesConfigLocalStorageKey = 'common-pages-config';
export const CommonPagesColumnConfigWarningMessage = 'At least one URL option must be selected';
export const CommonReportsPagesFiltersConfig = [
  EAuditReportFilterTypes.InitialPageUrlContains,
  EAuditReportFilterTypes.InitialPageUrlDoesNotContain,
  EAuditReportFilterTypes.FinalPageUrlContains,
  EAuditReportFilterTypes.FinalPageUrlDoesNotContain,
  EAuditReportFilterTypes.PageTitle,
  EAuditReportFilterTypes.PagesWithBrokenLinks,
  EAuditReportFilterTypes.CombinedPageStatusCode,
  EAuditReportFilterTypes.FinalPageStatusCode,
  EAuditReportFilterTypes.InitialPageStatusCode,
  EAuditReportFilterTypes.PageSize,
  EAuditReportFilterTypes.RedirectCount,
  EAuditReportFilterTypes.PageLoadTime,
  EAuditReportFilterTypes.ShowAuditConfigured
];

export enum CommonReportsPagesTableColumns {
  PageUrl = 'page_url',
  PageCount = 'page_count',
  FinalPageUrl = 'final_page_url',
  FinalPageStatusCode = 'final_page_status_code',
  LargestContentfulPaint = 'largest_contentful_paint',
  FirstContentfulPaint = 'first_contentful_paint',
  TimeToFirstByte = 'time_to_first_byte',
  CumulativeLayoutShift = 'cumulative_layout_shift',
  PageLoadTime = 'page_load_time',
  PageStatusCode = 'page_status_code',
  RuleFailureCount = 'rule_failures',
  ConditionResult = 'condition_result',
  CookieCount = 'cookie_count',
  TotalCookieSize = 'total_cookie_size',
  ErrorLogCount = 'error_log_count',
  WarningLogCount = 'warn_log_count',
  InfoLogCount = 'info_log_count',
  DebugLogCount = 'debug_log_count',
  OtherLogCount = 'other_log_count',
  VariableCount = 'variable_count',
  VariableValue = 'variable_value',
  RequestCount = 'request_count',
  RedirectCount = 'redirect_count',
  Size = 'size',
  TagInstanceCount = 'tag_instance_count',
  TagLoadTimeAverage = 'tag_load_time_average',
  TagDuplicates = 'tag_duplicates',
  TagMultiples = 'tag_multiples',
  TagRequestSizeAverage = 'tag_request_size_average',
  BrokenTagCount = 'broken_tag_count',
  UniqueTagCount = 'unique_tag_count',
  TagCount = 'tag_count',
  GeoCount = 'geo_count',
}

export const SnowflakeForbiddenSearchSymbolsRegEx = [
  '(?!', '(?:', '(?=', '(?<', '(?>'
];
