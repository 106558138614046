import { Subject } from 'rxjs';
import {
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AlertService } from '../../alert.service';
import {
  AlertReportsToAlertLibraryMetrics,
  AlertReportsToAllMetrics,
  AlertReportsToAuditMetrics,
  AlertReportsToUsageMetricsV2
} from '../alert-logic.constants';
import { AlertMetricType } from '../alert-logic.enums';
import { MatMenuTrigger } from '@angular/material/menu';
import { EAlertMenuContext } from '../../alert.enums';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  selector: 'op-report-metric-selector',
  templateUrl: './report-metric-selector.component.html',
  styleUrls: ['./report-metric-selector.component.scss'],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: ReportMetricSelectorComponent
  }],
})
export class ReportMetricSelectorComponent implements MatFormFieldControl<AlertMetricType>, ControlValueAccessor, OnChanges, OnDestroy, DoCheck {

  @Input()
  get value(): AlertMetricType {
    return this._value;
  }

  set value(type: AlertMetricType) {
    this._value = type;
    this.calcMetricPlaceholder();
    this.stateChanges.next();
  }

  private _value: AlertMetricType;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  protected _required: boolean;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;
  @Input() menuContext: EAlertMenuContext;

  @HostBinding() id = `report-metric-selector-${ReportMetricSelectorComponent.nextId++}`;
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  static nextId = 0;

  errorState: boolean;
  stateChanges = new Subject<void>();

  shouldLabelFloat = false;
  focused = false;
  touched = false;
  controlType = 'report-metric-selector-input';

  metricPlaceholder = '';

  alertReportsToMetrics;

  onChange: (value: AlertMetricType) => void;
  onTouched: () => void;

  constructor(@Optional() @Self() public ngControl: NgControl,
    private elementRef: ElementRef<HTMLElement>,
    private alertService: AlertService) {

    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import
      this.ngControl.valueAccessor = this;
    }
  }

  ngDoCheck() {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some error triggers that we can't subscribe to (e.g. marking as touched)
      this.updateErrorState();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stateChanges.next();

    if (changes.menuContext) {
      this.alertReportsToMetrics = this.getAlertReportsToMetrics();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  getAlertReportsToMetrics() {
    switch (this.menuContext) {
      case EAlertMenuContext.Audit:
        return AlertReportsToAuditMetrics;

      case EAlertMenuContext.Library:
        return AlertReportsToAlertLibraryMetrics;

      case EAlertMenuContext.Usage:
        return AlertReportsToUsageMetricsV2;

      case EAlertMenuContext.Triggered:
        return AlertReportsToAlertLibraryMetrics;

      default:
        return AlertReportsToAllMetrics;
    }
  }

  setMetric(metricType: AlertMetricType) {
    this.value = metricType;
    this.onChange(metricType);
  }

  private calcMetricPlaceholder(): string {
    if (!this.value) return null;

    const reportConfig = AlertUtils.getReportConfigByMetricType(this.value);
    const metric = AlertUtils.getMetricConfigByMetricType(this.value);

    this.metricPlaceholder = reportConfig?.name + ' - ' + metric?.name;
  }

  /*** Mat Form Field Control implementation ***/
  get empty() {
    return !this.value;
  }

  updateErrorState() {
    const newState = this.ngControl.errors !== null && this.ngControl.touched;
    if (this.errorState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    const target = event.relatedTarget as Element;
    if (!this.elementRef.nativeElement.contains(target) && !target?.classList.contains('mat-menu-trigger')) {
      this.clearFocus();
    }
  }

  private setFocus() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  clearFocus() {
    this.touched = true;
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) { }

  onContainerClick(_: MouseEvent) {
    this.menuTrigger.openMenu();
    this.setFocus();
  }

  /*** Control Value Accessor implementation ***/
  writeValue(metricType: AlertMetricType): void {
    this.value = metricType;
  }

  registerOnChange(fn: (value: AlertMetricType) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
