import { IPaginationMetaData } from '@app/components/consent-categories/consent-categories.models';
import { IUserBase } from '@app/moonbeamModels';
import { EAccountType } from "@app/components/core/services/authentication.enums";

// see https://stackoverflow.com/questions/39372804/how-can-i-loop-through-enum-values-for-display-in-radio-buttons
export const EAccountTypeIds: EAccountType[] = Object.keys(EAccountType)
  .filter(k => isNaN(Number(k)))
  .map(k => EAccountType[k]);

export const AccountTypeIdToDisplayValue: { [key: number]: string; } = {
  [EAccountType.ADOBE_AUDITOR]: 'Adobe Auditor',
  [EAccountType.SAMPLE]: 'Sample',
  [EAccountType.CONTRACT]: 'Contract',
  [EAccountType.PARTNER]: 'Partner',
  [EAccountType.FREETRIAL]: 'Free Trial',
  [EAccountType.INTERNAL]: 'Internal',
};

export interface IAccountsTableRow {
  id: number;
  company: string;
  accountType: string;
  status: string;
  name: string;
  username: string;
  email: string;
  canBeLoggedInAs: boolean;
  accountIsActive: boolean;
  account: IAdminAccount;
}

export enum EManageAccountStatusStringToNumber {
  'ACTIVE' = 2,
  'ACTIVE_BILLING_PROBLEM' = 3,
  'INACTIVE_BILLING_FAILED' = 4,
  'INACTIVE_USER_CANCELLED' = 5,
  'INACTIVE' = 6,
  'DEMO' = 7,
}

export interface IAdminAccountDTO {
  accounts: IAdminAccount[];
  metadata: { pagination: IPaginationMetaData }
}

export interface IAdminAccount {
  authSupport: boolean;
  company?: string;
  name?: string;
  strongPasswordSupport: boolean;
  maxUsers: number;
  concurrentAudits: number;
  auditPageLimit: number;
  concurrentJourneys: number;
  webJourneyRunLimit: number;
  deviceSupport: boolean;
  reportSupport: boolean;
  id: number;
  customProxySupport: boolean;
  status: string|number;
  maxMobileApps: number;
  notes: string;
  videoSupport: boolean;
  auditActionSupport: boolean;
  primaryUser: IUserBase;
  maxDomains: number;
  domains?: Array<string>;
  deleted: boolean;
  accountType: number;
  vpn: IAdminAccountVPN,
  features?: IAdminAccountFeature[],
  dataWareHouse?: boolean;
}

export interface IAdminAccountVPN {
  vpnCredentials?: string;
  vpnSupport: boolean;
}

export interface IAdminAccountFeature {
  name: string,
  enabled: boolean
}

export interface ICreateAccountRequest {
  account: IAdminAccount,
  templateData: ICreateTemplateData,
}

interface ICreateTemplateData {
  dataLayer: string
}

export interface IScriptServicesConfig {
  journeyFixes: number,
  maxMonitoredJourneys: number
}
