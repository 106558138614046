<div class="title" *ngIf="!hideTitle">
  Assign Rules (optional)
</div>
<div class="rule-selector">
  <div class="all-items">
    <div class="table-title">
      <span [attr.op-selector]="'all-rules-header-span'">
        All Rules ({{availableItemsDataSource?.filteredData?.length || '0'}})
      </span>
    </div>
    <div class="table-wrp">
      <div class="search-header">
        <span class="icon icon-search"></span>
        <mat-form-field appearance="outline" class="no-border no-hint-container rule-search-input">
          <input matInput
                 no-underline
                 placeholder="Search by rule or label name"
                 [(ngModel)]="searchText"
                 (input)="searchChanged()"
                 [attr.op-selector]="'input-search-rules'"/>
        </mat-form-field>
      </div>

      <div class="no-items-wrp">
        <span [attr.op-selector]="'no-items-to-display-text'" *ngIf="searchText !== '' && !availableItemsDataSource.filteredData?.length">
          There are no items to be displayed with these filters
        </span>
        <span [attr.op-selector]="'no-items-to-display-text'" *ngIf="searchText === '' && !rules?.length" class="zero-state">
          <p class="no-items-paragraph">
            Your account currently doesn't have any rules created. Add rules to this audit and they can be reused on any other audits and journeys.
          </p>
          <button class="new-item-btn" (click)="createNewRule()">
            <mat-icon>add_circle_outline</mat-icon>
            <span class="btn-text">Create New Rule</span>
            <mat-icon>expand_more</mat-icon>
          </button>
        </span>
      </div>

      <div class="alert-table-header" *ngIf="rules?.length">
        <div class="alert-column-header">RULE NAME</div>
        <div class="label-column-header">LABELS</div>
      </div>

      <div class="viewport">
        <ng-container *ngIf="availableItemsData.length > 0">
          <div *uiScroll="let item of availableItemsUIScrollDatasource">
            <rule-selector-row [rule]="item"
                               [labelsById]="labelsById"
                               [labels]="labels"
                               [isReadOnly]="true"
                               [includeLabels]="true"
                               [rulesById]="ruleLabelIdsMap"
                               [chipCountOnly]="true"
                               [attr.op-selector]="'rule-' + item.id"
                               (openRuleEditor)="openRuleEditor.emit(item)"
            >
              <ng-template #buttonTemplate>
                <div class="add-rule-btn" (click)="addRule(item)" [attr.op-selector]="'rule-' + item.id + '-add-to-list'">
                  add to list
                  <span class="icon icon-forward-empty"></span>
                </div>
              </ng-template>
            </rule-selector-row>
          </div>
        </ng-container>
      </div>

      <div class="table-footer" *ngIf="!isReadOnly">
        <div class="create-rule-btn" (click)="createNewRule()" [attr.op-selector]="'rule-selector-create-new-rule'">
          <div class="create-new-icon">
            <mat-icon>add_circle_outline</mat-icon>
          </div>
          <div class="create-new-text">
            create new rule
          </div>
        </div>
        <div class="add-all" (click)="addAll()" [attr.op-selector]="'add-all-rules-action'">
          Add all
          <span class="icon icon-forward-empty"></span>
        </div>
      </div>
    </div>
  </div>

  <div class="selected-items">
    <div class="table-title">
      <span [attr.op-selector]="'rule-selector-title'">Rules to be assigned ({{ rulesToBeAssigned }})</span>
    </div>
    <div class="table-wrp">
      <div *ngIf="!selectedItems?.length" class="right-side-zero-state">
        Add a rule to this audit by choosing them from the left.
      </div>
      <div class="viewport">
        <ng-container *ngIf="selectedItems?.length > 0">
          <div *uiScroll="let item of selectedItemsUIScrollDatasource">
            <rule-selector-row [rule]="item.rule"
                               [labelsById]="labelsById"
                               [labels]="labels"
                               [isReadOnly]="true"
                               [includeLabels]="true"
                               [labelRules]="item?.label ? labelRules[item?.label?.id] : null"
                               [rulesById]="ruleLabelIdsMap"
                               [chipCountOnly]="true"
                               [attr.op-selector]="'rule-' + item.rule.id"
                               (openRuleEditor)="openRuleEditor.emit(item.rule)"
            >
              <ng-template #buttonTemplate>
                <div class="add-rule-btn"
                     [attr.op-selector]="item ? 'assigned-item-rule-id-' + item.id + '-remove-icon' : 'label-info-' +  item?.label?.id + '-remove-btn'"
                     (click)="removeSelectedItem(item)">
                  <span class="icon icon-circle-cross"></span>
                </div>
              </ng-template>
            </rule-selector-row>
          </div>
        </ng-container>
      </div>

      <div class="table-footer">
        <div class="remove-all" (click)="removeAllSelectedItems()" [attr.op-selector]="'remove-all-rules-action'">
          <span [attr.op-selector]="'remove-all-rules-action-text'">Remove all</span>
          <span [attr.op-selector]="'remove-all-rules-btn'" class="icon icon-circle-cross"></span>
        </div>
      </div>
    </div>
  </div>
</div>
