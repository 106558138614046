import { EVarComparisonOutcome, ETagComparisonOutcome } from './wj-results-tag-comparison.enums';

export const varComparisonOutcomeToLabelMap = new Map([
  [EVarComparisonOutcome.match, 'Match'],
  [EVarComparisonOutcome.valueChanged, 'Value Changed'],
  [EVarComparisonOutcome.valueAbsent, 'Value Absent'],
  [EVarComparisonOutcome.valueAdded, 'Value Added'],
  [EVarComparisonOutcome.variableAbsent, 'Variable Absent'],
  [EVarComparisonOutcome.variableAdded, 'Variable Added'],
  [EVarComparisonOutcome.tagAbsent, 'Tag Absent'],
  [EVarComparisonOutcome.tagAdded, 'Tag Added']
]);

export const tagComparisonOutcomeToLabelMap = new Map([
  [ETagComparisonOutcome.match, 'Match'],
  [ETagComparisonOutcome.tagAbsent, 'Tag Absent'],
  [ETagComparisonOutcome.tagAdded, 'Tag Added'],
  [ETagComparisonOutcome.variableMismatch, 'Variable Mismatch']
])
