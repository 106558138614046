import { ComponentChanges } from '@app/models/commons';
import { Sort } from '@angular/material/sort';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  TemplateRef
} from '@angular/core';
import { ILabel } from '@app/components/shared/services/label.service';
import { ISimpleTableColumn, ISimpleTableItemsState } from './simple-table/simple-table.models';
import { ESelectableTableSelectionMode, IPagination, ISelectableTableSelectionChange } from './selectable-table.models';

@Component({
  selector: 'op-selectable-table',
  templateUrl: './selectable-table.component.html',
  styleUrls: ['./selectable-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectableTableComponent<D extends { id: number }> implements OnChanges {
  readonly ESimpleTableSelectionMode = ESelectableTableSelectionMode;

  @Input() allItems: ISimpleTableItemsState<D>;
  @Input() selectedItems: D[] = [];
  @Input() selectedItemText: string;
  @Input() selectedItemsLimit: number;
  @Input() show: number;
  @Input() selectionMode: ESelectableTableSelectionMode = ESelectableTableSelectionMode.Multiple;
  @Input() disableTableSelection: boolean = false;
  @Input() columns: ISimpleTableColumn<D>[] = [];
  @Input() labels: ILabel[];
  @Input() pagination: IPagination;
  @Input() isReadOnly = false;
  @Input() showSelectedLimit = true;
  @Input() readOnlyLabel: string;
  @Input() selectionIndentifier = 'id';

  @Output() selectionChanged = new EventEmitter<ISelectableTableSelectionChange<D>>();
  @Output() sortChanged = new EventEmitter<Sort>();
  @Output() paginationChanged = new EventEmitter<number>();

  @ContentChild('filterBarTemplate') filterBarTemplate: TemplateRef<any>;

  selectedItemsSort: Sort;
  selectedItemsSorted: D[];

  @Input() displaySelection = true;
  @Output() displaySelectionChange = new EventEmitter<boolean>();

  @HostBinding('class.many-selections')
  get manySelections() {
    return this.selectedItems.length > 2;
  }

  ngOnChanges(changes: ComponentChanges<SelectableTableComponent<D>>) {
    if (changes.selectedItems?.currentValue !== changes.selectedItems?.previousValue) {
      this.selectedItemsSorted = changes.selectedItems.currentValue;
    }

    if (changes.disableTableSelection?.currentValue !== undefined) {
      this.isReadOnly = changes.disableTableSelection.currentValue;
    }
  }

  sortSelectedItems(sort: Sort) {
    this.selectedItemsSort = sort;
    this.prepareSelectedItemsSorted();
  }

  private prepareSelectedItemsSorted() {
    const { active, direction } = this.selectedItemsSort;
    const { propName } = this.columns.find(column => column.sortKey === active);
    this.selectedItemsSorted = [...this.selectedItems].sort((a, b) => {
      const valueA = typeof a[propName] === 'string' ? (a[propName] as string).toLowerCase() : a[propName];
      const valueB = typeof b[propName] === 'string' ? (b[propName] as string).toLowerCase() : b[propName];
      if (valueA < valueB) return direction === 'asc' ? -1 : 1;
      if (valueA > valueB) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  }

}
