import { RouteDataService, IRouteDataBreadcrumb } from './../../services/route-data.service';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BreadcrumbHistory, IBreadcrumbItem } from './breadcrumb.models';
import { EHistoryType } from './breadcrumb.enums';

@Injectable()
export class BreadcrumbService {

  historySubject = new Subject<BreadcrumbHistory>();

  private parentUrl: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  /** 
   * Create breadcrumbs history. And recreate history on state transitions
   */
  initBreadcrumbs(activatedRoute: ActivatedRoute, parentUrl: string) {
    this.parentUrl = parentUrl;

    this.historySubject.next(this.createBreadcrumbs(activatedRoute));

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute), // get activated route
      map(route => { // get last activated route
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === PRIMARY_OUTLET), // only primary outlet
    ).subscribe(route => {
      this.historySubject.next(this.createBreadcrumbs(activatedRoute));
    });
  }

  private createBreadcrumbs(route: ActivatedRoute, url = '', breadcrumbs: IBreadcrumbItem[] = []): IBreadcrumbItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const breadcrumb = child.snapshot.data.breadcrumb as IRouteDataBreadcrumb;

      if (breadcrumb) {
        const crumb = this.hasMoreBreadrumbs(child) ?
          this.getBreadcrumbLink(breadcrumb.title, this.parentUrl + url) :
          this.getBreadcrumbDropdown(breadcrumb.title, this.parentUrl + url, child);
        breadcrumbs.push(crumb);
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  private hasMoreBreadrumbs(route: ActivatedRoute): boolean {
    if (!route.children.length) {
      return false
    }

    const child = route.children[0];

    if (!child.snapshot) {
      return false;
    }

    return child.snapshot.data.breadcrumb || this.hasMoreBreadrumbs(child);
  }

  private getBreadcrumbDropdown(text: string, url: string, activatedRoute: ActivatedRoute): IBreadcrumbItem {
    return {
      type: EHistoryType.Dropdown,
      selectedOption: text,
      options: this.fetchSiblings(activatedRoute, url)
    };
  }

  private getBreadcrumbLink(text: string, url: string): IBreadcrumbItem {
    return {
      type: EHistoryType.Link,
      text: text,
      url
    };
  }

  /** 
   * Get posible sibling states for dropdown breadcrumbs
   */
  private fetchSiblings(activatedRoute: ActivatedRoute, url: string): IBreadcrumbItem[] {
    const siblingsRoutes = activatedRoute.parent.routeConfig.children;
    return siblingsRoutes
      .filter(siblingRoute => siblingRoute.data?.breadcrumb)
      .map(siblingRoute => {
        const breadcrumb = siblingRoute.data.breadcrumb as IRouteDataBreadcrumb;
        const urlSegments = RouteDataService.getUrlSegments(this.router.parseUrl(url));
        urlSegments.splice(-1, 1, siblingRoute.path); // replaces the last url part
        return this.getBreadcrumbLink(breadcrumb.title, urlSegments.join('/'));
      });
  }

}
