import { UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { OPValidators } from "@app/components/shared/validators/op-validators";
import { ERuleFilterType, ERuleMatchType, ERuleTagVariableSelectorType } from "../tabs/conditions-tab/rule-setup-conditions-tab.enums";

export interface IFormControlConfigMap {
  [key: string]: IControlConfig | IFormControlConfigMap;
}

export interface IControlConfig {
  disable?: (formGroup?: UntypedFormGroup) => boolean;
  validators: (formGroup?: UntypedFormGroup) => ValidatorFn | ValidatorFn[] | null;
}

export const accountConditionConfig: IFormControlConfigMap = {
  value: {
    validators: () => Validators.required
  },
  matchType: {
    validators: () => Validators.required
  },
  validationDescription: {
    validators: () => null
  }
};

export const statusCodeConditionConfig: IFormControlConfigMap = {
  value: {
    validators: () => Validators.required
  },
  matchType: {
    validators: () => Validators.required
  },
};

export const variableConditionConfig: IFormControlConfigMap = {
  id: {
    validators: () => null
  },
  ruleTagId: {
    validators: () => null
  },
  variable: {
    validators: () => Validators.required
  },
  variableMatchAsRegex: {
    validators: () => null
  },
  matchType: {
    validators: () => Validators.required
  },
  selectorType: {
    validators: (formGroup: UntypedFormGroup) => {
      return [ERuleMatchType.IsSet, ERuleMatchType.IsNotSet]
        .includes(formGroup.get('matchType').value) ? null : Validators.required;
    },
    disable: (formGroup: UntypedFormGroup) => {
      return [ERuleMatchType.IsSet, ERuleMatchType.IsNotSet]
        .includes(formGroup.get('matchType').value);
    },
  },
  value: {
    validators: (formGroup: UntypedFormGroup) => {
      const selectorType = formGroup.get('selectorType').value;
      const matchType = formGroup.get('matchType').value;

      const isTagSelectorType = selectorType === ERuleTagVariableSelectorType.Tag;
      const isDisabled = [ERuleMatchType.IsSet, ERuleMatchType.IsNotSet].includes(matchType);
      if (isTagSelectorType || isDisabled) return null;

      const isStringSelectorType = selectorType === ERuleTagVariableSelectorType.String;
      const isNumericMatchType = [ERuleMatchType.LessThanOrEqualTo, ERuleMatchType.GreaterThanOrEqualTo].includes(matchType);

      if (isStringSelectorType && isNumericMatchType) return [Validators.required, OPValidators.number];

      return Validators.required;
    },
    disable: (formGroup: UntypedFormGroup) => {
      return [ERuleMatchType.IsSet, ERuleMatchType.IsNotSet].includes(formGroup.get('matchType').value);
    },
  },
  valueTagId: {
    validators: (formGroup: UntypedFormGroup) => {
      return formGroup.get('selectorType').value === ERuleTagVariableSelectorType.Tag
        ? Validators.required
        : null
      }
  },
  validationDescription: {
    validators: () => null
  }
};

export const mainConditionConfig: IFormControlConfigMap = {
  id: {
    validators: () => null
  },
  ruleId: {
    validators: () => null
  },
  type: {
    validators: () => Validators.required
  },
  matchType: {
    validators: () => Validators.required
  },
  value: {
    validators: (formGroup: UntypedFormGroup) => {
      return formGroup.get('type').value === ERuleFilterType.Tag ? null : Validators.required;
    }
  },
  tagId: {
    validators: (formGroup: UntypedFormGroup) => {
      return formGroup.get('type').value === ERuleFilterType.Tag ? Validators.required : null;
    }
  },
  account: accountConditionConfig,
  variables: variableConditionConfig,
  statusCode: statusCodeConditionConfig,
};

export const generalTabConfig: IFormControlConfigMap = {
  name: {
    validators: () => Validators.required
  },
  labels: {
    validators: () => null
  },
  recipients: {
    validators: () => null
  }
}

export const conditionsTabConfig: IFormControlConfigMap = {
  checkTimes: {
    validators: () => [Validators.required, Validators.min(1), Validators.max(99), OPValidators.integer]
  },
  matchAllFilters: {
    validators: () => Validators.required
  },
  filters: {
    If: mainConditionConfig,
    Then: mainConditionConfig
  }
}
