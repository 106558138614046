import { Injectable } from '@angular/core';
import {
  IEmailInbox,
  IEmailInboxConfigurationDTO,
  IEmailInboxDTO,
  IEmailInboxesQueryParams,
  IEmailInboxMessagesScreenshot,
  IEmailInboxPostBody,
  IEmailInboxMessages, IEmailInboxMessageAlerts, IEmailInboxMessageAlertsRequestBody, IEmailInboxMessageStatus
} from '@app/components/email-inboxes/email-inboxes.models';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import {
  IEmailInboxesFilter
} from '@app/components/email-inboxes/email-inboxes-filter-bar/email-inboxes-filter-bar.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';

@Injectable({
  providedIn: 'root'
}
)
export class EmailInboxesService {
  private apiEmailRoot = `${environment.apiV3Url}email-inboxes`;

  constructor(
    private apiService: ApiService,
  ) { }

  getEmailInboxes({ page, pageSize, sortBy, sortDesc }: IEmailInboxesQueryParams, apiFilters: IEmailInboxesFilter): Observable<IEmailInboxDTO> {
    let params = [];

    if (page !== undefined) params.push(`page=${page}`);
    if (pageSize) params.push(`pageSize=${pageSize}`);
    if (sortBy) params.push(`sortBy=${sortBy}`);
    if (sortDesc) params.push(`sortDesc=${sortDesc}`);

    const root = `${this.apiEmailRoot}/library`;
    const queryParams = params.length ? `?${params.join('&')}` : null;

    return this.apiService.post<IEmailInboxDTO>(`${root}${queryParams}`, apiFilters);
  }

  updateEmailInbox({ name, notes, labelIds, id, subscribers }: IEmailInboxPostBody): Observable<IEmailInbox> {
    const postBody = {
      name,
      notes,
      labelIds,
      subscribers,
    };

    return this.apiService.put<IEmailInbox>(`${this.apiEmailRoot}/${id}`, postBody);
  }

  updateEmailInboxLabels(inboxId: number, labelIds: number[]): Observable<any> {
    return this.apiService.put<number[]>(`${this.apiEmailRoot}/${inboxId}/labels`, labelIds);
  }

  updateEmailInboxNotes(inbox): Observable<string> {
    // explicitly set responseType to text to avoid parsing the response as JSON
    return this.apiService.put<string>(`${this.apiEmailRoot}/${inbox.id}/notes`, inbox.notes, { responseType: 'text' });
  }

  createInbox(payload): Observable<IEmailInbox> {
    return this.apiService.post(`${this.apiEmailRoot}`, payload);
  }

  deleteInbox(id: number) {
    return this.apiService.delete(`${this.apiEmailRoot}/${id}`);
  }

  getEmailInbox(id: number): Observable<IEmailInbox> {
    return this.apiService.get(`${this.apiEmailRoot}/${id}`);
  }

  getInboxById(inboxId: number): Observable<IEmailInbox> {
    return this.apiService.get(`${this.apiEmailRoot}/${inboxId}`);
  }

  getInboxAuditAssociations(inboxId: number): Observable<IEmailInboxConfigurationDTO[]> {
    return this.apiService.get(`${this.apiEmailRoot}/${inboxId}/audit-configurations`);
  }

  updateInboxWithAuditAssociations(inboxId: number, auditIds: number[]): Observable<void> {
    return this.apiService.put(`${this.apiEmailRoot}/${inboxId}/audit-configurations`, auditIds);
  }

  @CacheApiResponse({ liveTime: 10000 })
  getEmailInboxMessages(id: number, { page, pageSize, sortBy, sortDesc }: IEmailInboxesQueryParams = {}) {
    let params = [];

    if (page !== undefined) params.push(`page=${page}`);
    if (pageSize) params.push(`pageSize=${pageSize}`);
    if (sortBy) params.push(`sortBy=${sortBy}`);
    if (sortDesc) params.push(`sortDesc=${sortDesc}`);
    const queryParams = params.length ? `?${params.join('&')}` : '';

    return this.apiService.get<IEmailInboxMessages>(`${this.apiEmailRoot}/${id}/messages${queryParams}`);
  }

  getEmailInboxMessageAlerts(body: IEmailInboxMessageAlertsRequestBody): Observable<IEmailInboxMessageAlerts> {
    return this.apiService.post(`${environment.apiV3Url}email-inbox-message-alerts`, body);
  }

  getEmailInboxMessagesStatuses(emailInboxId: number, messageIds: number[]): Observable<IEmailInboxMessageStatus[]> {
    return this.apiService.post(`${environment.apiV3Url}email-inboxes/${emailInboxId}/messages/statuses`, messageIds);
  }

  getEmailMessageScreenshotUrl(inboxId, messageId): Observable<IEmailInboxMessagesScreenshot> {
    return this.apiService.get(`${environment.apiV3Url}lpv4e/inbox/${inboxId}/message/${messageId}/screenshot-url`);
  }
}
