import { RouteBuilder } from '../shared/services/route-builder.service';
const baseState = 'audit-report';

export const AuditReportContainerStateNames = {
  base: baseState,
  summary: baseState + '.summary',
  pagesWithTag: baseState + '.tagInventory',
  pagesWithoutTags: baseState + '.pagesWithoutTags',
  tagLoadTime: baseState + '.tagLoadTime',
  auditExports: baseState + '.auditExports'
};

export const AuditReportContainerPaths = {
  base: 'audit/:auditId/run/:runId',
  basicReport: ':reportName',
  auditExports: 'audit-exports'
};

const basePathBuilder = (auditId: number, runId: number) => {
  return RouteBuilder.buildUrl([AuditReportContainerPaths.base], String(auditId), String(runId));
};
export const AuditReportContainerUrlBuilders = {
  base: (auditId: number, runId: number) => basePathBuilder(auditId, runId),
  basicReport: (auditId: number, runId: number, reportName: string) => {
    return basePathBuilder(auditId, runId) + '/report/' + reportName;
  },
  auditExports: (auditId: number, runId: number) => {
    return basePathBuilder(auditId, runId) + '/' + AuditReportContainerPaths.auditExports;
  }
};

export const AuditReportContainerTitles = {
  base: 'Audit Report',
  auditExports: 'Audit Exports',
  comparisons: 'Tag & Variable Comparisons',
  pageSummary: 'Page Summary'
};

export const opSelectorMap = {
  'Use Cases': 'audit-use-cases',
  'Audit Summary': 'audit-audit-summary',
  'Alert Summary': 'alert-audit-summary',
  'Rule Summary': 'audit-rule-summary',
  'Tag Inventory': 'audit-tag-inventory',
  'Pages without Tags': 'audit-pages-without-tags',
  'Tag Versions': 'audit-tag-versions',
  'Tag Load Time': 'audit-tag-load-time',
  'Tag Load Over Time': 'audit-tag-load-over-time',
  'Tag Status Codes': 'audit-tag-status-codes',
  'Duplicates & Multiples': 'audit-duplicates-multiples',
  'Variable Inventory': 'audit-variable-inventory',
  'Page Inventory & Detail': 'audit-page-inventory-detail',
  'Cookie Inventory': 'audit-cookie-inventory',
  'Browser Console Logs': 'audit-console-logs',
  'Tags': 'audit-tags',
  'Cookies': 'audit-cookies',
  'Geolocations': 'audit-geolocations',
  'Request Domains': 'audit-request-domains',
  'Tag & Variable Comparisons': 'audit-comparisons',
  'Adobe Auditor': 'audit-adobe-auditor',
  'Audit Exports': 'audit-exports',
  'Page Summary': 'audit-page-summary',
  'Tag Health': 'audit-tag-health',
  'Console Logs': 'console-logs',
};

export const PageStatusCodeTooltipMap = {
  '0': 'ObservePoint requested the page but received no response from the web server.',

  // 1xx Informational Codes
  '100': 'Continue: The server acknowledges the client\'s request to continue with the request.',
  '101': 'Switching Protocols: The server is switching protocols according to the client\'s request.',
  '102': 'Processing: The server is still processing the request, and the client should continue to wait.',

  // 2xx Successful Codes
  '200': 'OK: The server successfully fulfilled the client\'s request, and the requested data is included in the response.',
  '201': 'Created: The server has successfully created a new resource as requested by the client.',
  '202': 'Accepted: The server has accepted the client\'s request, but the processing is not yet complete.',
  '203': 'Non-Authoritative Information: The server is returning non-authoritative information, usually from a cached copy.',
  '204': 'No Content: The server successfully processed the request, but there is no data to send back.',
  '205': 'Reset Content: The server requires the client to reset the document view, typically after a form submission.',
  '206': 'Partial Content: The server is delivering only a partial response, usually in response to a range request.',
  '207': 'Multi-Status: The server is returning multiple status responses for multiple independent operations.',
  '208': 'Already Reported: The members of a DAV binding have already been enumerated in a previous response.',
  '226': 'IM Used: The server has fulfilled a request for the resource, and the response is a representation of the result.',

  // 3xx Redirection Codes
  '300': 'Multiple Choices: The requested resource has multiple representations, and the server is providing a list of choices for the client to follow.',
  '301': 'Moved Permanently: The requested resource has been permanently moved to a new URL.',
  '302': 'Found: The requested resource has been temporarily moved to a new URL.',
  '303': 'See Other: The response to the request can be found under a different URI, and the client should follow the redirect.',
  '304': 'Not Modified: The client\'s cached copy of the resource is still valid, and the server does not need to send a new copy.',
  '305': 'Use Proxy: The requested resource must be accessed through the proxy given by the Location field.',
  '306': 'Switch Proxy: This status code is no longer used but was reserved in a previous version of the HTTP specification.',
  '307': 'Temporary Redirect: The requested resource has been temporarily moved to a different URI, and the client should follow the redirect.',
  '308': 'Permanent Redirect: The requested resource has been permanently moved to a different URI, and the client should follow the redirect.',

  // 4xx Client Error Codes
  '400': 'Bad Request: The server cannot understand the client\'s request due to malformed syntax or invalid parameters.',
  '401': 'Unauthorized: The client must authenticate itself to get the requested resource.',
  '402': 'Payment Required: This status code is reserved for future use.',
  '403': 'Forbidden: The client does not have permission to access the requested resource.',
  '404': 'Not Found: The requested resource could not be found on the server.',
  '405': 'Method Not Allowed: The method used in the request is not allowed for the requested resource.',
  '406': 'Not Acceptable: The server cannot produce a response matching the list of acceptable values defined in the request\'s headers.',
  '407': 'Proxy Authentication Required: The client must authenticate itself with the proxy server to get the requested resource.',
  '408': 'Request Timeout: The client did not produce a request within the time the server was willing to wait.',
  '409': 'Conflict: The client\'s request conflicts with the current state of the server.',
  '410': 'Gone: The requested resource is no longer available on the server, and no forwarding address is known.',
  '429': 'Too Many Requests: The user has sent too many requests in a given amount of time ("rate limiting").',

  // 5xx Client Error Codes
  '500': 'Internal Server Error: The server has encountered a situation it does not know how to handle.',
  '501': 'Not Implemented: The request method is not supported by the server and cannot be handled. The only methods that servers are required to support (and therefore that must not return this code) are GET and HEAD.',
  '502': 'Bad Gateway: This error response means that the server, while working as a gateway to get a response needed to handle the request, got an invalid response.',
  '503': 'Service Unavailable: The server is not ready to handle the request. Common causes are a server that is down for maintenance or that is overloaded. Note that together with this response, a user-friendly page explaining the problem should be sent. This response should be used for temporary conditions and the Retry-After HTTP header should, if possible, contain the estimated time before the recovery of the service. The webmaster must also take care about the caching-related headers that are sent along with this response, as these temporary condition responses should usually not be cached.',
  '504': 'Gateway Timeout: This error response is given when the server is acting as a gateway and cannot get a response in time.',
  '505': 'HTTP Version Not Supported: The HTTP version used in the request is not supported by the server.',
  '506': 'Variant Also Negotiates: The server has an internal configuration error: the chosen variant resource is configured to engage in transparent content negotiation itself, and is therefore not a proper end point in the negotiation process.',
  '507': 'Insufficient Storage (WebDAV): The method could not be performed on the resource because the server is unable to store the representation needed to successfully complete the request.',
  '508': 'Loop Detected (WebDAV): The server detected an infinite loop while processing the request.',
  '510': 'Not Extended: Further extensions to the request are required for the server to fulfill it.',
  '511': 'Network Authentication Required: Indicates that the client needs to authenticate to gain network access.',
};
