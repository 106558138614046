<mat-menu #childMenu="matMenu"
          class="filter-bar-menu"
          [class]="menuClass"
          (closed)="handleMenuClosed()"
          backdropClass="no-filter">
  <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <ng-container *ngFor="let item of menuItems">
      <ng-container *ngIf="item.displayWhen === undefined ? true : item.displayWhen">

        <!-- flyout menu item type -->
        <ng-container *ngIf="item.type === EFilterBarMenuTypes.Flyout && item.children && item.children.length">
          <button mat-menu-item #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu.childMenu"  [class.disabled]="item.disabled" [ngClass]="item.itemClasses" class="filter-bar-menu-item">
            <mat-icon *ngIf="item.icon" class="menu-item-icon">{{ item.icon }}</mat-icon> <span *ngIf="!item.menuItemNameTemplate">{{ item.name }}</span> <span *ngIf="item.menuItemNameTemplate" [innerHTML]="item.menuItemNameTemplate"></span>
          </button>
          <op-filter-bar-menu-item-v2 #menu [menuItems]="item.children" [service]="service" [menuClass]="menuClass"></op-filter-bar-menu-item-v2>
        </ng-container>

        <!-- button menu item type -->
        <ng-container *ngIf="item.type === EFilterBarMenuTypes.Button">
          <button mat-menu-item (click)="item.action(service)"  [class.disabled]="item.disabled" [ngClass]="item.itemClasses" class="filter-bar-menu-item">
            <mat-icon *ngIf="item.icon" class="menu-item-icon">{{ item.icon }}</mat-icon> <span *ngIf="!item.menuItemNameTemplate">{{ item.name }}</span> <span *ngIf="item.menuItemNameTemplate" [innerHTML]="item.menuItemNameTemplate"></span>
          </button>
        </ng-container>

        <!-- search menu item type -->
        <ng-container *ngIf="item.type === EFilterBarMenuTypes.Search">
          <div mat-menu-item [disableRipple]="true" [ngClass]="item.itemClasses" class="clearable-input-container">
            <op-clearable-input #opClearableInput
                                [class.disabled]="item.disabled"
                                [placeholderText]="item.searchPlaceholder"
                                [emitKeyboardEvent]="true"
                                (onUserInput)="handleUserInput($event, opClearableInput, item)"
                                [autofocus]="false"
                                (onBlur)="item.action($event, opClearableInput)">
            </op-clearable-input>
            <span class="additional-info">{{item.additionalInfo}}</span>
          </div>

          <!-- search results will have checkboxes (multiple selections allowed) -->
          <ng-container *ngIf="item.searchAllowsMultipleSelections">
            <div *ngFor="let searchResult of item.children" mat-menu-item  [class.disabled]="searchResult.disabled" [ngClass]="searchResult.itemClasses" class="filter-bar-menu-item">
              <mat-checkbox color="primary" class="filter-bar-menu-item" [value]="searchResult" (change)="handleCheckboxSearchResultChange($event.checked, searchResult)" [indeterminate]="searchResult.indeterminate">
                <div class="checkbox-content">
                  <mat-icon *ngIf="searchResult.icon" class="menu-item-icon">{{ searchResult.icon }}</mat-icon> <span *ngIf="!searchResult.menuItemNameTemplate">{{ searchResult.name }}</span> <span *ngIf="searchResult.menuItemNameTemplate" [innerHTML]="searchResult.menuItemNameTemplate"></span>
                </div>
              </mat-checkbox>
            </div>
          </ng-container>

          <!-- search results are buttons and only one selection is allowed -->
          <ng-container *ngIf="!item.searchAllowsMultipleSelections">
            <button *ngFor="let button of item.children" mat-menu-item  [class.disabled]="item.disabled" [ngClass]="item.itemClasses" (click)="button.action(service)" class="filter-bar-menu-item">
              <mat-icon *ngIf="button.icon" class="menu-item-icon">{{ button.icon }}</mat-icon> <span *ngIf="!button.menuItemNameTemplate">{{ button.name }}</span> <span *ngIf="button.menuItemNameTemplate" [innerHTML]="button.menuItemNameTemplate"></span>
            </button>
          </ng-container>
        </ng-container>

        <!-- checkbox menu item type -->
        <ng-container *ngIf="item.type === EFilterBarMenuTypes.Checkbox && (!clearableInput?.inputValue || !item.hideSelectedOnSearch)">
          <div mat-menu-item [ngClass]="item.itemClasses" class="filter-bar-menu-item" [class.disabled]="item.disabled">
            <mat-checkbox color="primary" [checked]="item.checked" [indeterminate]="item.indeterminate" [ngClass]="item.itemClasses" class="filter-bar-menu-item" [value]="item" (change)="item.action($event.checked, item, menuItems)">
              <div class="checkbox-content">
                <div class="checkbox-icon-name">
                  <ng-container *ngIf="item.icon && item.icon.indexOf('#svgCustomIcon')>-1;else svgCustomIcon">
                    <mat-icon class="menu-item-icon" svgIcon="{{ item.icon.substring(0, item.icon.indexOf('#svgCustomIcon')) }}"></mat-icon> <span *ngIf="!item.menuItemNameTemplate">{{ item.name }}</span> <span *ngIf="item.menuItemNameTemplate" [innerHTML]="item.menuItemNameTemplate"></span>
                  </ng-container>
                  <ng-template #svgCustomIcon>
                    <mat-icon *ngIf="item.icon" class="menu-item-icon">{{ item.icon }}</mat-icon> <span *ngIf="!item.menuItemNameTemplate">{{ item.name }}</span> <span *ngIf="item.menuItemNameTemplate" [innerHTML]="item.menuItemNameTemplate"></span>
                  </ng-template>
                </div>
                <button class="checkbox-only-btn" (click)="item.onlyButtonAction(item, menuItems)">Only</button>
              </div>
            </mat-checkbox>
          </div>
          <div class="checkbox-children" *ngIf="item?.children?.length">
            <ng-container *ngFor="let child of item.children">
              <div *ngIf="child.displayWhen === undefined ? true : child.displayWhen" mat-menu-item [ngClass]="child.itemClasses" class="filter-bar-menu-item">
                <mat-checkbox color="primary" [checked]="child.checked" [ngClass]="child.itemClasses" class="filter-bar-menu-item" [value]="child" (change)="child.action($event.checked, child, item, menuItems)">
                  <div class="checkbox-content">
                    <div class="checkbox-icon-name">
                      <mat-icon *ngIf="child.icon" class="menu-item-icon">{{ child.icon }}</mat-icon> <span *ngIf="!child.menuItemNameTemplate">{{ child.name }}</span> <span *ngIf="child.menuItemNameTemplate" [innerHTML]="child.menuItemNameTemplate"></span>
                    </div>
                    <button class="checkbox-only-btn" (click)="child.onlyButtonAction(child, menuItems, item)">Only</button>
                  </div>
                </mat-checkbox>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <!-- custom component menu item type -->
        <ng-container *ngIf="item.type === EFilterBarMenuTypes.CustomComponent">
          <div mat-menu-item (click)="$event.stopImmediatePropagation()" [ngClass]="item.itemClasses" class="filter-bar-menu-item custom-component-wrapper">
            <op-filter-bar-menu-custom-component-item-v2 [service]="service" [item]="item"></op-filter-bar-menu-custom-component-item-v2>
          </div>
        </ng-container>

        <!-- divider menu item type -->
        <ng-container *ngIf="item.type === EFilterBarMenuTypes.Divider" [ngClass]="item.itemClasses">
          <div class="filter-menu-divider-container">
            <mat-divider class="filter-menu-divider"></mat-divider>
            <span class="additional-info" *ngIf="item.additionalInfo">{{item.additionalInfo}}</span>
          </div>
        </ng-container>

        <!-- divider menu item type -->
        <ng-container *ngIf="item.type === EFilterBarMenuTypes.Note" [ngClass]="item.itemClasses">
          <div class="filter-menu-note-container">
            <div class="filter-menu-note">{{item.name}}</div>
          </div>
        </ng-container>


        <!-- clear button item type -->
        <ng-container *ngIf="item.type === EFilterBarMenuTypes.ClearBtn" [ngClass]="item.itemClasses">
          <button mat-menu-item (click)="item.action(service)" class="filter-bar-menu-item filter-menu-clear-btn">
            <mat-icon>close</mat-icon>
            <mat-icon *ngIf="item.icon" class="menu-item-icon">{{ item.icon }}</mat-icon> <span *ngIf="!item.menuItemNameTemplate">{{ item.name }}</span> <span *ngIf="item.menuItemNameTemplate" [innerHTML]="item.menuItemNameTemplate"></span>
          </button>
        </ng-container>

      </ng-container>
    </ng-container>
  </div>
</mat-menu>
