<div class="weekday-selector">
  <div class="days">
    <ng-container *ngFor="let weekday of weekdays">
      <label for="{{ weekday.value }}">
        <input class="checkbox" type="checkbox" id="{{ weekday.value}}" value="{{ weekday.value }}"
               [checked]="selectedDays.has(weekday.value)"
               (change)="onSelectionChanged(weekday.value, $event.target.checked)"
               [disabled]="selectedDays.size === 1 && selectedDays.has(weekday.value)" />
        <span class="checkmark">{{ weekday.label }}</span>
      </label>
    </ng-container>
  </div>
  <div class="info-message">
    <mat-icon class="info-icon material-icons-outlined">info</mat-icon>
    <span class="text">Runs will only occur on the days selected regardless of the start date specified.</span>
  </div>
</div>