<div class="input-container"
     (focusout)="onFocusOut($event)">
  <input matInput
         readonly
         [placeholder]="placeholder"
         [matMenuTriggerFor]="reportsMenu"
         #menuTrigger
         [value]="metricPlaceholder"
         (menuClosed)="clearFocus()"/>

  <mat-menu #reportsMenu="matMenu"
            class="slim-options"
            backdropClass="no-filter">
    <ng-container *ngFor="let reportConfig of alertReportsToMetrics">
      <ng-template #firstLevelMenu>
        <button mat-menu-item
                (click)="setMetric(reportConfig.value)">
          {{ reportConfig.name }}
        </button>
      </ng-template>

      <ng-container *ngIf="reportConfig.metrics; else firstLevelMenu">
        <button mat-menu-item
                [matMenuTriggerFor]="metricsMenu">
          {{ reportConfig.name }}
        </button>

        <mat-menu #metricsMenu="matMenu"
                  class="slim-options"
                  backdropClass="no-filter">
          <ng-container *ngFor="let metric of reportConfig.metrics">
            <ng-container *ngIf="metric.metrics; else subMenuLevel2">
              <button mat-menu-item
                      (click)="setMetric(metric.value)"
                      [matMenuTriggerFor]="metricsSubMenu">
                {{ metric.name }}

                <mat-menu #metricsSubMenu="matMenu"
                          class="slim-options">
                  <ng-container *ngFor="let subMetric of metric.metrics">
                    <ng-container *ngIf="subMetric.metrics; else subMenuLevel3">
                      <button mat-menu-item
                              (click)="setMetric(subMetric.value)"
                              [matMenuTriggerFor]="metricsSubSubMenu">
                        {{ subMetric.name }}

                        <mat-menu #metricsSubSubMenu="matMenu"
                                  class="slim-options">
                          <button mat-menu-item
                                  *ngFor="let subSubMetric of subMetric.metrics"
                                  (click)="setMetric(subSubMetric.value)">
                            {{ subSubMetric.name }}
                          </button>
                        </mat-menu>
                      </button>
                    </ng-container>

                    <ng-template #subMenuLevel3>
                      <button mat-menu-item
                              (click)="setMetric(subMetric.value)">
                        {{ subMetric.name }}
                      </button>
                    </ng-template>
                  </ng-container>
                </mat-menu>
              </button>
            </ng-container>

            <ng-template #subMenuLevel2>
              <button mat-menu-item
                      (click)="setMetric(metric.value)">
                {{ metric.name }}
              </button>
            </ng-template>
          </ng-container>
        </mat-menu>
      </ng-container>
    </ng-container>
  </mat-menu>
</div>
