import { IPageUrlFilter } from '@app/components/audit-reports/audit-report/audit-report.models';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';

export interface IVariableInventoryRequestDTO {
  pageUrl: IPageUrlFilter;
  primaryTagsOnly: boolean;
  tagId: number;
  tagCategoryId: number;
  tagAccount: string;
}

export interface IVariableInventorySummaryDTO {
  totalPageCount: number;
  filteredPageCount: number;
  totalUniqueTagCount: number;
  filteredUniqueTagCount: number;
  totalTagInstanceCount: number;
  filteredTagInstanceCount: number;
  totalUniqueVariableNameCount: number;
  filteredUniqueVariableNameCount: number;
  totalUniqueVariableValueCount: number;
  filteredUniqueVariableValueCount: number;
}

export interface IVariableInventoryTrendsDTO {
  runs: IVariableInventoryTrendsItem[];
}

export interface IVariableInventoryTrendsItem {
  runId: number;
  completedAt: string;
  totalUniqueTagCount: number;
  totalTagInstanceCount: number;
  totalUniqueVariableNameCount: number;
  totalUniqueVariableValueCount: number;
}

export interface IVariableInventorySpecificTrendValuesDTO {
  runs: IVariableInventorySpecificTrendValueItem[];
}

export interface IVariableInventorySpecificTrendValueItem {
  runId: number;
  completedAt: string;
  trendValue: number;
}

export interface IVariableInventoryTagsDTO {
  tags: IVariableInventoryTagItem[];
}

export interface IVariableInventoryTagItem {
  tagId: number;
  tagName: string;
  tagCategoryId: number;
  tagCategoryName: string;
  tagAccounts: IVariableInventoryTagAccountItem[];
}

export interface IVariableInventoryTagAccountItem {
  tagAccount: string;
  totalPageCount: number;
  totalVariableCount: number;
}

export interface IVariableInventoryTagAccount {
  tagId: number;
  tagName: string;
  tagCategoryId: number;
  tagCategoryName: string;
  tagAccount: string;
  totalPageCount: number;
  totalVariableCount: number;
}

export interface IVariableInventoryTagAccountVariablesDTO {
  variables: IVariableInventoryVariableItem[];
}

export interface IVariableInventoryVariableItem {
  variableName: string;
  friendlyName: string;
  uniqueValueCount: number;
  variableSetPageCount: number;
  variableNotSetPageCount: number;
  selected?: EVariableItemSelection;
}

export enum EVariableItemSelection {
  UNIQUE_VALUE_COUNT = 'uniqueValueCount',
  VALUE_SET_COUNT = 'valueSetCount',
  VALUE_NOT_SET_COUNT = 'valueNotsetCount',
  NOT_SELECTED = 'notSelected'
}

export interface IVariableInventoryPagesDTO {
  pages: IVariableInventoryPagesItem[];
}

export interface IVariableInventoryTagAccountsPagesDTO {
  metadata: IVariableInventoryPagesMetaData;
  pages: IVariableInventoryPagesItem[];
}

export interface IVariableInventoryPagesItem {
  pageId: string;
  pageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  finalPageStatusCode: number;
  variableCount: number;
}

export interface IVariableInventoryVariableNotSetPagesDTO {
  pages: IVariableInventoryVariableNotSetPagesItem[];
}

export interface IVariableInventoryVariableNotSetPagesItem {
  pageId: string;
  pageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  finalPageStatusCode: number;
}

export interface IVariableInventoryUniqueVariableValuesDTO {
  metadata: IVariableInventoryPagesMetaData;
  variableValues: IVariableInventoryUniqueVariableValuesItem[];
}

export interface IVariableInventoryPagesMetaData {
  pagination: IVariableInventoryPagination;
}

export interface IVariableInventoryPagination {
  currentPageNumber: number;
  currentPageSize: number;
  pageSize: number;
  totalCount: number;
  totalPageCount: number;
}

export interface IVariableInventoryUniqueVariableValuesItem {
  variableValue: string;
  pageCount: number;
  requestCount: number;
}

export interface IVariableInventoryVariableValuePagesDTO {
  pages: IVariableInventoryVariableValuePageItem[];
}

export interface IVariableInventoryVariableValuePageItem {
  pageId: string;
  pageUrl: string;
  finalPageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  finalPageStatusCode: number;
  tagWithVariableValueCount: number;
}

export interface IVariableInventoryQueryParams {
  currentPageNumber?: number;
  currentPageSize?: number;
  pageSize?: number;
  totalCount?: number;
  totalPageCount?: number;
  sortBy?: CommonReportsPagesTableColumns;
  sortDesc?: boolean;
}

export interface IVariableInventoryTagsAndAccountsMenuItem {
  label: string;
  icon: string;
  disabled?: boolean;
  handler: (tag) => void;
}

export interface IVariableInventoryReportFilter {
  tagId?: number;
  tagAccount?: string;
}

export enum EVariableInventoryPagesType {
  UNIQUE = 'unique',
  SET = 'set',
  NOT_SET = 'notSet',
  TAG_ACCOUNTS = 'tagAccounts',
}
