<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="title"
                     (onClose)="close()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="delete-item" [class]="additionalClasses">
      <div class="warning-icon-wrap">
          <mat-icon class="warning-icon">warning</mat-icon>
      </div>
      <div class="delete-content">
        <p class="stretch-container">
          <ng-container *ngIf="item.defaultMessage">Are you sure you want to delete the <b>{{ item.name }}</b> <span class="lowercase"> {{ item.itemType }}</span>? </ng-container>
          <ng-container *ngIf="item.customMessage">
            <span [innerHTML]="item.customMessage"></span>
            <span class="pre-warning">This is irreversible.</span>
          </ng-container>
        </p>
        <div id="wrapperCounts" [style.display]="makeDeleteCountsVisible ? 'block' : 'none'">
          <ul class="list-padding">
            <li *ngIf="makeFolderDeleteCountsVisible"><div class="delete-row-count">{{ item.FoldersCount }} Folder</div><div class="delete-row-count" *ngIf="item.FoldersCount !== 1">s</div></li>
            <li><div class="delete-row-count">{{ item.SubfoldersCount }} Subfolder</div><div class="delete-row-count" *ngIf="item.SubfoldersCount !== 1">s</div></li>
            <li><div class="delete-row-count">{{ item.AuditsCount }} Audit</div><div class="delete-row-count" *ngIf="item.AuditsCount !== 1">s</div></li>
            <li><div class="delete-row-count">{{ item.JourneysCount }} Journey</div><div class="delete-row-count" *ngIf="item.JourneysCount !== 1">s</div></li>
          </ul>
        </div>
      </div>

      <form [formGroup]="deleteForm" class="delete-form">
        <p class="type-out-word">Type out the word <span class="delete-hint">{{deleteHint}}</span> to proceed:</p>
        <mat-form-field class="confirm-delete" appearance="outline">
          <input matInput formControlName="confirmDelete" autocomplete="off">
          <mat-error *ngIf="confirmDelete.errors?.required">This field is required</mat-error>
          <mat-error *ngIf="confirmDelete.errors?.confirmDelete">You must type '{{deleteHint}}' in the input</mat-error>
        </mat-form-field>
        <mat-checkbox formControlName="understand" color="primary" class="understand">
          <ng-container *ngIf="item.customConsentMessage; else defaultConsentMessage">
            <span class="custom-consent">{{ item.customConsentMessage }}</span>
          </ng-container>
          <ng-template #defaultConsentMessage>
            I understand my <span class="lowercase">{{ item.itemType }}</span> will be permanently deleted
          </ng-template>
        </mat-checkbox>
      </form>
    </div>
  </ng-template>
</op-modal>
