import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IPrivacyRequestsLocation } from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fullscreen-map-modal',
  templateUrl: './privacy-requests-fullscreen-map-modal.component.html',
  styleUrls: ['./privacy-requests-fullscreen-map-modal.component.scss']
})
export class PrivacyRequestsFullscreenMapModalComponent {
  focusedCountries: IPrivacyRequestsLocation[];

  constructor(
    public dialogRef: MatDialogRef<PrivacyRequestsFullscreenMapModalComponent>,
    @Inject(MAT_DIALOG_DATA){ focusedCountries}: {focusedCountries: IPrivacyRequestsLocation[]}
  ) {
    this.focusedCountries = focusedCountries;
  }

  close() {
    this.dialogRef.close();
  }
}
