import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFoldersApiService, IFolder, INewFolder } from '@app/components/folder/foldersApiService';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { IUser } from '@app/moonbeamModels';
import { HttpErrorResponse } from '@angular/common/http';
import { AccountsService } from '@app/components/account/account.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'create-edit-folder',
  templateUrl: './create-edit-folder.component.html',
  styleUrls: ['./create-edit-folder.component.scss']
})
export class CreateEditFolderComponent implements OnInit {

  createMode: boolean;
  accountId: number;
  folderForm: UntypedFormGroup;
  folder: IFolder;
  editing = true;
  saving = false;
  error = false;

  rightFooterButtons = [
    {
      label: 'Save',
      action: () => {
        this.save();
      },
      primary: true
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<CreateEditFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private folderService: IFoldersApiService,
    private accountsService: AccountsService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.createMode = !(data && data.folder);
    this.folder = !this.createMode ? data.folder : null;
  }

  ngOnInit(): void {
    this.initForm();
    this.accountsService.getUser().subscribe((user: IUser) => {
      this.accountId = user.accountId;
    });
  }

  initForm(): void {
    this.folderForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    if(!this.createMode) {
      this.name.patchValue(this.folder.name);
    }
  }

  save() {
    this.folderForm.markAllAsTouched();
    if(this.folderForm.invalid || this.saving) return;

    this.editing = false;
    this.saving = true;

    this.createMode ? this.createFolder() : this.updateFolder();
  }

  createFolder(): void {
    const folderObj: INewFolder = {
      accountId: this.accountId,
      name: this.name.value
    }

    this.folderService.createFolder(folderObj)
      .then((folder: IFolder) => {
        this.closeModal(folder);
      })
      .catch((error: HttpErrorResponse) => {
        this.handleError(error);
      });
  }

  updateFolder(): void {
    const folderObj: IFolder = {
      ...this.folder,
      name: this.name.value
    };

    this.folderService.updateFolder(folderObj)
      .then((folder: IFolder) => {
        this.closeModal(folder);
      })
      .catch((error: HttpErrorResponse) => {
        this.handleError(error);
      });
  }

  handleError(error: HttpErrorResponse): void {
    console.error(error);
    this.saving = false;
    this.error = true;
    this.rightFooterButtons[0].label = 'OK';
    this.rightFooterButtons[0].action = () => {
      this.dialogRef.close();
    }
  }

  closeModal(folder?: IFolder) {
    this.dialogRef.close(folder);
  }

  get name(): AbstractControl {
    return this.folderForm.get('name');
  }
}
