import { take, takeUntil } from 'rxjs/operators';
import { IStatusBannerStateParams } from './status-banner.models';
import { ActivatedRoute } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges } from '@angular/core';
import { StatusBannerProcessor } from '@app/components/reporting/statusBanner/statusBannerProcessor';
import {
  IAuditStatus,
  IStatusBannerSettings,
  StatusBannerService,
  StatusUpdateType
} from '@app/components/reporting/statusBanner/statusBannerService';
import { IStopRunService } from '@app/components/stopRun/stopRunService';
import { Subscription } from '@app/components/websockets/websocketService';
import { ComponentChanges } from '@app/models/commons';
import { IStatusBarItem, StatusBarItemState } from '../status-progress-bar/status-progress-bar.models';
import { Subject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'status-banner',
  templateUrl: './status-banner.component.html',
  styleUrls: ['./status-banner.component.scss']
})
export class StatusBannerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() updatesType: StatusUpdateType;
  @Input() scheduledToScan: boolean;
  @Input() runCancelled: boolean = false;
  @Output() viewReport: EventEmitter<number> = new EventEmitter();
  @Output() runFinished: EventEmitter<void> = new EventEmitter();

  bannerVisibleFlag: boolean = false;
  activeRunId: number;
  resourceId: number;
  isDetailsShown: boolean = false;
  typeLabel: string;
  startedDate: string;

  progressBarItems: Array<IStatusBarItem>;

  stateParams: IStatusBannerStateParams;

  private newRunsSubscription: Subscription;
  private destroy$ = new Subject();

  constructor(
    private statusBannerService: StatusBannerService,
    private statusBannerProcessor: StatusBannerProcessor,
    private route: ActivatedRoute,
    private stopRunsService: IStopRunService
  ) { }

  ngOnInit() {
    this.initData();
    this.initialize();
  }

  ngOnDestroy() {
    this.destroy$.next();

    this.route.data
      .pipe(take(1))
      .subscribe(data => {
        this.statusBannerService.stopUpdatesOnExit(
          data.stateName,
          this.updatesType
        );
      });
  }

  initData() {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: IStatusBannerStateParams) => {
        this.stateParams = params;
        if (!this.scheduledToScan && this.stateParams.scheduledToScan) {
          this.scheduledToScan = true;
          this.showBanner();
        }
      });

    this.initProgressBarItems();
    this.typeLabel = this.statusBannerService.determineTypeLabel(this.updatesType);
  }

  ngOnChanges(changes: ComponentChanges<StatusBannerComponent>) {
    if (changes.runCancelled && changes.runCancelled.currentValue) {
      this.cancelActiveRun();
    }

    if (
      typeof changes.scheduledToScan !== 'undefined' &&
      changes.scheduledToScan.previousValue !== changes.scheduledToScan.currentValue
    ) {
      if (changes.scheduledToScan.currentValue) {
        this.runInitiating();
      }
    }
  }

  private runInitiating(): void {
    this.showBanner();
    this.initialize();
  }

  initProgressBarItems(): void {
    this.progressBarItems = this.statusBannerProcessor.generateProgressBarItems(this.updatesType);
  }

  private cancelActiveRun(): void {
    this.runFinished.emit();
    this.hideBanner();
  }

  private initialize(): void {
    switch (this.updatesType) {
      case StatusUpdateType.Audit:
        this.initAuditUpdates();
        break;
      case StatusUpdateType.WebJourney:
        this.initWebJourneyUpdates();
        break;
    }
  }

  public initAuditUpdates(): void {
    let auditId: number = parseInt(this.stateParams.auditId);
    this.resourceId = auditId;
    this.statusBannerService.getSettingsForAudit(auditId).then((settings: IStatusBannerSettings) => {
      if (this.isStatusBannerNotSupported(settings)) {
        return;
      }
      this.scheduledToScan = false;
      this.showBanner();

      // If there is a queued run, we display the progress banner in a queued state, but don't subscribe for updates since we don't have a run id yet.
      if (settings.queued) return;

      this.activeRunId = settings.lastRunId;
      this.subscribeOnRunsStoppedEvent();
      this.startedDate = settings.startedDate;

      this.statusBannerService.getAuditStatusProgress(auditId, this.activeRunId).then((update: IAuditStatus) => {
        this.progressBarItems = this.statusBannerProcessor.processAuditUpdate(update, this.progressBarItems);
      });
      const subscription = this.statusBannerService.subscribeForStatusUpdates(this.updatesType, auditId, this.activeRunId).subscribe((update: IAuditStatus) => {
        this.progressBarItems = this.statusBannerProcessor.processAuditUpdate(update, this.progressBarItems);
        if (this.isProcessDone()) {
          this.runFinished.emit();
        }
      });
      this.statusBannerService.addProgressSubscription(subscription);
    });
  }

  public initWebJourneyUpdates(): void {
    let journeyId: number = parseInt(this.stateParams.journeyId);
    this.resourceId = journeyId;
    this.statusBannerService.getSettingsForWebJourney(journeyId).then((settings: IStatusBannerSettings) => {
      if (this.isStatusBannerNotSupported(settings)) {
        return;
      }
      this.scheduledToScan = false;
      this.showBanner();
      this.activeRunId = settings.lastRunId;
      this.subscribeOnRunsStoppedEvent();
      this.startedDate = settings.startedDate;

      this.statusBannerService
        .getWebJourneyStatusProgress(journeyId, this.activeRunId)
        .subscribe(update => {
          this.progressBarItems = this.statusBannerProcessor.processWebJourneyUpdate(update as any, this.progressBarItems);
        });

      const subscription = this.statusBannerService.subscribeForWebJourneyUpdates(journeyId, this.activeRunId)
        .pipe(takeUntil(this.destroy$))
        .subscribe(update => {
          this.progressBarItems = this.statusBannerProcessor.processWebJourneyUpdate(update, this.progressBarItems);
          if (this.isProcessDone()) {
            this.runFinished.emit();
          }
        });
      this.statusBannerService.addProgressSubscription(subscription);
    });
  }

  isStatusBannerNotSupported(settings: IStatusBannerSettings): boolean {
    return (!settings.queued && !settings.notRunYet) && (settings.finished || settings.notRunYet || settings.firstRun || !settings.lastRunId);
  }

  private subscribeOnRunsStoppedEvent(): void {
    const runnableItem = this.stopRunsService.runnableItem(this.updatesType);
    this.stopRunsService.subscribeOnRunStoppedEvent(runnableItem, this.resourceId, this.activeRunId, () => {
      this.hideBanner();
    });
  }

  showBanner(): void {
    this.bannerVisibleFlag = true;
  }

  hideBanner(): void {
    this.bannerVisibleFlag = false;
  }

  isProcessInitiated(): boolean {
    return this.scheduledToScan && !this.isProcessInProgress() && !this.isProcessDone();
  }
  isProcessInProgress(): boolean {
    return this.progressBarItems[0].state != StatusBarItemState.notStarted && !this.isProcessDone();
  }

  isProcessDone(): boolean {
    return this.progressBarItems && this.progressBarItems[this.progressBarItems.length - 1].state == StatusBarItemState.done;
  }

  showReport(): void {
    this.initProgressBarItems();
    this.hideDetails();
    this.hideBanner();
    this.viewReport.emit(this.activeRunId);
  }

  showDetails(): void {
    this.isDetailsShown = true;
  }

  hideDetails(): void {
    this.isDetailsShown = false;
  }

  isAuditType(): boolean {
    return this.updatesType == StatusUpdateType.Audit;
  }
}
