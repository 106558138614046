import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatSnackBarRef } from '@angular/material/snack-bar';

export interface ISnackbarErrorData {
  message?: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'snackbar-error',
  templateUrl: './snackbar-error.component.html',
  styleUrls: ['./snackbar-error.component.scss']
})
export class SnackbarErrorComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarErrorData,
              public snackBarRef: MatSnackBarRef<any>) {}

  close() {
    this.snackBarRef.dismiss();
  }
}
