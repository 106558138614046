import { MaterialModule } from './../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RfmLibraryComponent } from './rfm-library.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RfmEditorComponent } from './rfm-editor/rfm-editor.component';
import { OpModalModule } from '../shared/components/op-modal/op-modal.module';
import { CreatorNgModule } from '../creator/creatorModule';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RfmLibraryRoutes } from './rfm-library.routes';
import { SharedModule } from '@app/components/shared/shared.module';
import { OpClearableInputModule } from '@app/components/shared/components/op-clearable-input/op-clearable-input.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    RfmLibraryComponent,
    RfmEditorComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MaterialModule,
    OpModalModule,
    CreatorNgModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    RouterModule.forChild([RfmLibraryRoutes]),
    SharedModule,
    OpClearableInputModule
  ],
})
export class RfmLibraryModule {}
