<ng-container *ngTemplateOutlet="filterBarTemplate"></ng-container>

<div class="scroll-container">
  <div *ngIf="selectionMode === ESimpleTableSelectionMode.Multiple"
       class="selected-items-table-wrapper">
    <mat-expansion-panel hideToggle
                         [disabled]="selectedItems?.length === 0"
                         [expanded]="displaySelection"
                         (opened)="displaySelection = true; displaySelectionChange.emit(true)"
                         (closed)="displaySelection = false; displaySelectionChange.emit(false)">
      <mat-expansion-panel-header>
        <selectable-table-selection-info [selectedItems]="selectedItems"
                                         [selectedLimit]="selectedItemsLimit || pagination?.totalCount"
                                         [selectedItemText]="selectedItemText"
                                         [showSelectedLimit]="showSelectedLimit"
                                         [displaySelection]="displaySelection">
        </selectable-table-selection-info>
      </mat-expansion-panel-header>

      <op-simple-table *ngIf="selectedItems.length > 0"
                       [allItems]="{ items: selectedItemsSorted, loading: false }"
                       [selectedItems]="selectedItems"
                       [isReadOnly]="isReadOnly"
                       [readOnlyLabel]="readOnlyLabel"
                       [selectionIndentifier]="selectionIndentifier"
                       [columns]="columns"
                       [labels]="labels"
                       [hideSelectAll]="true"
                       (selectionChanged)="selectionChanged.emit($event)"
                       (sortChanged)="sortSelectedItems($event)">
      </op-simple-table>
    </mat-expansion-panel>
  </div>

  <div class="all-items-container">
    <div class="all-items-table-wrapper">
      <op-simple-table [allItems]="allItems"
                       [selectionMode]="selectionMode"
                       [selectedItems]="selectedItems"
                       [isReadOnly]="isReadOnly"
                       [readOnlyLabel]="readOnlyLabel"
                       [selectionIndentifier]="selectionIndentifier"
                       [columns]="columns"
                       [labels]="labels"
                       [pagination]="pagination"
                       [stickyHeader]="true"
                       (selectionChanged)="selectionChanged.emit($event)"
                       (sortChanged)="sortChanged.emit($event)"
                       (paginationChanged)="paginationChanged.emit($event)">
      </op-simple-table>
    </div>
  </div>
</div>
