import * as angular from 'angular';
import { Observable, of } from 'rxjs';
import { AngularNames } from '@app/moonbeamConstants';

export class PromiseUtils {

  static $inject = [AngularNames.q];

  constructor(private $q: angular.IQService) {
  }

  convertObservableToAngJSPromise<T>(observable: Observable<T>): angular.IPromise<T> {
    let defer = this.$q.defer();
    observable.subscribe(
      val => defer.resolve(val),
      err => defer.reject(err)
    );
    return defer.promise as angular.IPromise<T>;
  }

}
