import {Names} from '@app/moonbeamConstants';
import * as angular from 'angular';


export interface IReprocessRulesScope extends angular.IScope {
    processing: boolean;
    runId: number;
  }

  export class ReprocessRulesBanner implements angular.IDirective {

    bindToController = {
      viewReport: '&',
      runId: '='
    };
    scope = {
      runId: '=runId'
    };
    controller = Names.Controllers.reprocessRulesBanner;
    controllerAs = 'reprocessCtrl';
    template = require('@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessRulesBanner.html');
    transclude = true;

    constructor() {
    }

    static Factory(): angular.IDirectiveFactory {
      const directive = () => {
        return new ReprocessRulesBanner();
      };
      return directive;
    }
}

