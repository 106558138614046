import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActionSetLibraryService } from './action-set-library.service';
import {
  EActionSetAssignmentType,
  IActionSet,
  IActionSetLibraryItem,
  IActionSetTableRow
} from './action-set-library.models';
import { OpModalService } from '../shared/components/op-modal';
import {
  OpDeleteItemWarningComponent
} from '../shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { ActionSetEditorComponent } from './action-set-editor/action-set-editor.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Features } from '@app/moonbeamConstants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-set-library',
  templateUrl: './action-set-library.component.html',
  styleUrls: ['./action-set-library.component.scss'],
  animations: [
    trigger('expand', [
      state('collapsed', style({ maxHeight: '0', minHeight: '0' })),
      state('loading', style({ maxHeight: '60px', minHeight: '60px' })),
      state('expanded', style({ maxHeight: '200px', minHeight: '{{height}}' }), { params: { height: '*' } }),
      transition('collapsed <=> expanded, loading <=> *', animate('200ms'))
    ])
  ]
})
export class ActionSetLibraryComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  EActionSetAssignmentType = EActionSetAssignmentType;

  loading = true;
  webJourneyAccessible = false;
  displayedColumns = ['name', 'webAuditCount', 'webJourneyCount', 'editDelete'];
  dataSource = new MatTableDataSource<IActionSetTableRow>();
  actionSetCount: number;

  selectedRow: IActionSetTableRow = null;
  selectedRowType: EActionSetAssignmentType = null;

  lastOpenedNestedTable: HTMLDivElement;
  lastOpenedNestedTableHeight: number;

  constructor(
    private actionSetService: ActionSetLibraryService,
    private modalService: OpModalService,
    private viewContainerRef: ViewContainerRef,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef
  ) {
    this.authenticationService.isFeatureAllowed(Features.webJourneys)
      .subscribe(isAllowed => this.webJourneyAccessible = isAllowed);
  }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.actionSetService.getActionSetLibrary().subscribe((actionSetLibrary: IActionSetLibraryItem[]) => {
      this.dataSource.data = actionSetLibrary
        .map(set => {
          return {
            ...set,
            webAuditCount: set.webAudits.length,
            webJourneyCount: set.webJourneys.length
          };
        })
        .sort((a: IActionSetTableRow, b: IActionSetTableRow) => a.name.localeCompare(b.name));

      this.dataSource.filterPredicate = (data, filter): boolean => {
        let nameCol = data['name'].trim().toLowerCase();
        let filterVal = filter.trim().toLowerCase();
        return nameCol.includes(filterVal);
      };

      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (data, attribute) => {
        if (typeof (data[attribute]) === 'string') {
          return data[attribute].toLowerCase();
        }
        return data[attribute];
      };

      this.updateActionSetCount();
      this.loading = false;
    });
  }

  filterActionSets(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.updateActionSetCount(true);
  }

  onAssignedClick(actionSet: IActionSetTableRow, type: EActionSetAssignmentType) {
    let prop = type === EActionSetAssignmentType.WebAudits ? 'webAuditCount' : 'webJourneyCount';
    if (!actionSet[prop] || !this.webJourneyAccessible) return;

    if (this.selectedRow === actionSet && this.selectedRowType === type) {
      return this.selectedRowType = this.selectedRow = null;
    }

    this.selectedRow = actionSet;
    this.selectedRowType = type;
  }

  animationDone($event: AnimationEvent, container: HTMLDivElement) {
    if (($event.fromState === 'loading' && $event.toState === 'expanded') || ($event.fromState === 'collapsed' && $event.toState === 'expanded')) {
      this.lastOpenedNestedTable = container;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = container.getBoundingClientRect().height;
        this.cdr.detectChanges();
      }, 300);
    }

    if ($event.fromState === 'expanded' && $event.toState === 'collapsed') {
      this.lastOpenedNestedTable = null;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = 0;
        this.cdr.detectChanges();
      }, 300);
    }
  }

  onCreate(): void {
    this.modalService.openFixedSizeModal(ActionSetEditorComponent, { disableClose: true, data: null })
    .afterClosed()
    .subscribe((actionSet: IActionSet) => {
      if (actionSet) {
        this.initData();
      }
    });
  }

  onEdit(actionSet: IActionSetTableRow): void {
    this.modalService.openFixedSizeModal(ActionSetEditorComponent, {
      disableClose: true,
      data: {
        actionSet: actionSet,
        copy: false
      }
    })
      .afterClosed()
      .subscribe((actionSet: IActionSet) => {
        if (actionSet) {
          this.initData();
        }
      });
  }

  onCopy(actionSet: IActionSetTableRow): void {
    this.modalService.openFixedSizeModal(ActionSetEditorComponent, {
      disableClose: true,
      data: {
        actionSet: actionSet,
        copy: true
      }
    })
      .afterClosed()
      .subscribe((actionSet: IActionSet) => {
        if (actionSet) {
          this.initData();
        }
      });
  }

  onDelete(actionSet: IActionSetTableRow) {
    let messages = [];

    if (actionSet.webAuditCount) {
      const plural = actionSet.webAuditCount > 1 ? 's' : '';
      messages.push(`<b>${actionSet.webAuditCount}</b> web audit${plural}`);
    }

    if (actionSet.webJourneyCount) {
      const plural = actionSet.webJourneyCount > 1 ? 's' : '';
      messages.push(`<b>${actionSet.webJourneyCount}</b> web journey${plural}`);
    }

    const joinedMessages = messages.join(' and ');
    const fullMessage = joinedMessages ? `and will affect ${joinedMessages}` : `but won't affect any web audits or web journeys`;

    this.modalService.openModal(OpDeleteItemWarningComponent, {
      data: {
        itemType: 'Action Set',
        name: actionSet.name,
        customMessage: `Doing so is irreversible ${fullMessage}.`
      }
    })
      .afterClosed()
      .subscribe((confirmDelete: boolean) => {
        if (confirmDelete) {
          this.actionSetService.deleteActionSet(actionSet.id).subscribe(() => {
            this.dataSource.data = this.dataSource.data.filter((item: IActionSetTableRow) => item.id !== actionSet.id);
          });
        }
      });
  }

  private updateActionSetCount(filtered?: boolean) {
    this.actionSetCount = filtered ?
      this.dataSource.filteredData.length || 0 :
      this.dataSource.data.length;
  }
}
