import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleRuleCreatorComponent } from './simple-rule-creator.component';
import { SharedModule } from '@app/components/shared/shared.module';
import { OpModalModule } from '@app/components/shared/components/op-modal/op-modal.module';
import { OpFormModule } from '@app/components/shared/op-form.module';
import { MaterialModule } from '@app/components/material/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule, SharedModule, OpFormModule, OpModalModule],
  declarations: [SimpleRuleCreatorComponent],
  exports: [SimpleRuleCreatorComponent]
})
export class SimpleRuleCreatorModule {}
