import { AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, first, map, takeUntil } from 'rxjs/operators';
import { EAuditReportFilterTypes, IConsentCategoryReportFilterData } from './audit-report-filter-bar.models';
import { AuditReportFilterBarService } from '../audit-report-filter-bar/audit-report-filter-bar.service';
import { EPageUrlFilterType, EStatusCodeCategories } from '../audit-report/audit-report.constants';
import { TagInventoryService } from '../reports/tag-inventory/tag-inventory.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ITagInventoryCategories, ITagInventoryTags } from '../reports/tag-inventory/tag-inventory.models';
import {
  IOpFilterBarInvertableFilter,
  IOpFilterBarMenuItem
} from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { ISearchByTextEmissionData } from '../../shared/components/op-filter-bar/op-filter-bar.models';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { IAuditGeoLocation } from '@app/components/consent-categories/consent-categories.models';
import { IAuditReportPageDetailsDrawerService } from '../audit-report/audit-report-page-details-drawer.models';
import { RuleSummaryReportService } from '@app/components/audit-reports/reports/rule-summary/rule-summary.service';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from '@angular/material/checkbox';
import { SnackbarErrorComponent } from '@app/components/shared/components/snackbars/snackbar-error/snackbar-error.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OpFilterBarComponent } from '@app/components/shared/components/op-filter-bar/op-filter-bar.component';
import {
  ISnackbarErrorData,
  SnackbarRegExpErrorComponent
} from '@app/components/audit-reports/audit-report-filter-bar/components/regexp-snackbar-error/regexp-snackbar-error.component';
import { ApiValidationsService } from '@app/services/api-validations/api-validations.service';
import { SnowflakeForbiddenSearchSymbolsRegEx } from '@app/components/audit-reports/reports/general-reports.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag, IUiTagCategory } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-report-filter-bar',
  templateUrl: './audit-report-filter-bar.component.html',
  styleUrls: ['./audit-report-filter-bar.component.scss'],
  providers: [{
    provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
    useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions
  }]
})
export class AuditReportFilterBarComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild(OpFilterBarComponent) filterBar: OpFilterBarComponent;

  validFilterTypes: EAuditReportFilterTypes[] = [];
  isScrolled: boolean = false;
  auditId: number;
  runId: number;
  menuConfig: IOpFilterBarMenuItem[] = [];
  tagCategoryDb: IUiTagCategory[] = [];
  pageUrlInputEnabled: boolean;

  //tag name search
  private tagDb: { id: number; name: string; searchName: string }[];

  //CC name search
  private ccsAssignedToCurrentRun: { categoryId: number; name: string; searchName: string }[];

  //Geolocation country name search
  private countries: IAuditGeoLocation[];

  readonly statusCodeCats = EStatusCodeCategories;

  private destroy$: Subject<void> = new Subject();
  searchInInitialURLs = true;
  searchInFinalURLs = false;

  snowflakeForbiddenSymbols = SnowflakeForbiddenSearchSymbolsRegEx;

  constructor(
    public filterBarSvc: AuditReportFilterBarService,
    private uiTagService: UiTagService,
    private changeDetector: ChangeDetectorRef,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private activatedRoute: ActivatedRoute,
    private tagInventoryService: TagInventoryService,
    private ccService: ConsentCategoriesService,
    private snackbar: MatSnackBar,
    private ruleSummaryService: RuleSummaryReportService,
    private apiValidationsService: ApiValidationsService,
    private router: Router,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.handleRunOrAuditIdUpdated(+params.auditId, +params.runId);
    });
  }

  ngOnInit(): void {
    this.fetchDataFromApi();

    this.filterBarSvc.scroll$.pipe(debounceTime(25), takeUntil(this.destroy$)).subscribe((offset: number) => {
      this.isScrolled = (offset !== 0);
      this.changeDetector.detectChanges();
    });

    // WORKAROUND: URL filters migration (can be removed with time)
    this.filterBarSvc.anyFiltersUpdates$
      .pipe(first())
      .subscribe(filters => {
        const filter = filters.find(filter => filter.display === 'URL ' || filter.display === 'URL regex');

        const value = filter?.value as {
          filterValue: string;
          negated: boolean;
          filterType: EPageUrlFilterType;
        };

        if (filter) {
          this.filterBarSvc.removeFilterByType(filter.type);
          if (!value.negated) {
            this.filterBarSvc.addUrlContainsFilter(value.filterValue, value.filterType === EPageUrlFilterType.Regex);
          } else {
            this.filterBarSvc.addUrlDoesNotContainFilter(value.filterValue, value.filterType === EPageUrlFilterType.Regex);
          }
        }

      });
    // END WORKAROUND: URL filters migration (can be removed with time)

    let lastMatchingUrlValue = null;
    this.filterBarSvc.anyFiltersUpdates$
      .subscribe(filters => {

        const matchingUrlsFilter = filters.find(filter => filter.type === EAuditReportFilterTypes.ShowAuditConfigured);

        if (matchingUrlsFilter) {
          if (lastMatchingUrlValue !== matchingUrlsFilter.value) {
            this.apiValidationsService
              .validateUrlMatchingFilter(this.auditId, this.runId)
              .subscribe(response => {

                if (response.includeFilters.notApplied.length || response.excludeFilters.notApplied.length) {
                  let includedNotApplied = [];
                  let excludedNotApplied = [];

                  response.includeFilters.notApplied.forEach(reason => {
                    includedNotApplied = [...includedNotApplied, ...reason.filters];
                  });

                  response.excludeFilters.notApplied.forEach(reason => {
                    excludedNotApplied = [...excludedNotApplied, ...reason.filters];
                  });

                  this.snackbar.openFromComponent<SnackbarRegExpErrorComponent, ISnackbarErrorData>(SnackbarRegExpErrorComponent, {
                    duration: 150000,
                    panelClass: 'no-max-width',
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    data: {
                      includedNotApplied,
                      excludedNotApplied,
                      domainId: null,
                      auditId: this.auditId,
                      runId: this.runId,
                      message: `"URLs matching/not matching Audit config" has not been applied because the following regular expressions were used in the Include/Exclude<br> sections of the audit setup and are not supported by this filter: <strong>"${this.snowflakeForbiddenSymbols.join('"</strong>, <strong>"')}"</strong> . `
                    }
                  });

                  this.filterBarSvc.removeFilterByType(EAuditReportFilterTypes.ShowAuditConfigured);
                  this.reloadPage(this.router.url);
                }
              });

            lastMatchingUrlValue = matchingUrlsFilter.value;
          }

        } else {
          lastMatchingUrlValue = null;
        }
      });
  }
  reloadPage(url: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
  ngAfterContentInit() {
    // AfterViewInit ensures that all sizes are accurate
    // setTimeout ensures we avoid the modified-after-checked Angular error
    this.filterBarSvc.relevantFilterUpdates$.pipe(takeUntil(this.destroy$)).subscribe(_ => {
      if (this.pageDetailsDrawerService.isDrawerOpen) {
        this.pageDetailsDrawerService.closePageDetails();
      }
    });
    this.filterBarSvc.validFilterTypesUpdated$.pipe(takeUntil(this.destroy$)).subscribe(validFilterTypes => {
      this.validFilterTypes = validFilterTypes;
      this.buildFilterMenu();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handlePageDetailsDrawer(): void {
    if (this.pageDetailsDrawerService.isDrawerOpen) {
      this.pageDetailsDrawerService.closePageDetails();
    }
  }

  handleSearchByUrl({value, regex}: ISearchByTextEmissionData): void {
    if (this.searchInInitialURLs) {
      this.filterBarSvc.addUrlContainsFilter(value, regex);
    }

    if (this.searchInFinalURLs) {
      this.filterBarSvc.addFinalUrlContainsFilter(value, regex);
    }
  }

  private handleRunOrAuditIdUpdated(newAuditId: number, newRunId: number): void {
    if (this.auditId === newAuditId && this.runId === newRunId) {
      return;
    }
    const currentAuditId = this.auditId;
    const currentRunId = this.runId;
    this.auditId = newAuditId;
    this.runId = newRunId;
    if (currentAuditId && currentRunId) { // Do not fetch data for initial load, it will happen in ngOnInit
      this.fetchDataFromApi();
    }
  }

  handleInvertableFilterToggled(filter: IOpFilterBarInvertableFilter<string>) {
    switch (filter.type) {
      case EAuditReportFilterTypes.InitialPageUrlContains:
        this.filterBarSvc.addUrlDoesNotContainFilter(filter.value['filterValue'], filter.value['filterType'] === EPageUrlFilterType.Regex);
        break;
      case EAuditReportFilterTypes.InitialPageUrlDoesNotContain:
        this.filterBarSvc.addUrlContainsFilter(filter.value['filterValue'], filter.value['filterType'] === EPageUrlFilterType.Regex);
        break;
      case EAuditReportFilterTypes.FinalPageUrlContains:
        this.filterBarSvc.addFinalUrlDoesNotContainFilter(filter.value['filterValue'], filter.value['filterType'] === EPageUrlFilterType.Regex);
        break;
      case EAuditReportFilterTypes.FinalPageUrlDoesNotContain:
        this.filterBarSvc.addFinalUrlContainsFilter(filter.value['filterValue'], filter.value['filterType'] === EPageUrlFilterType.Regex);
        break;
      case EAuditReportFilterTypes.PageTitle:
        this.filterBarSvc.addPageTitleFilter(filter.state, filter.value['filterValue']);
        break;
      case EAuditReportFilterTypes.ShowAuditConfigured:
        this.filterBarSvc.addShowConfiguredUrlsOnlyFilter(filter.state);
        break;
      case EAuditReportFilterTypes.ConsoleLogMessage:
        this.filterBarSvc.addConsoleLogMessageFilter(filter.state, filter.value['filterValue']);
        break;
      case EAuditReportFilterTypes.CookieDomain:
        this.filterBarSvc.addCookieDomainFilter(filter.state, filter.value['filterValue']);
        break;
      case EAuditReportFilterTypes.AlertStatus:
        this.filterBarSvc.addTriggeredAlertsFilter(filter.state);
        break;
      default:
        console.error(`Invertable filter toggled handler isn't implemented for the ${filter.type}`);
    }
  }

  private fetchDataFromApi() {
    forkJoin([
      this.uiTagService.getTags(),
      this.tagInventoryService.getTagInventoryTags(this.auditId, this.runId, {}),
      this.tagInventoryService.getTagInventoryCategories(this.auditId, this.runId, {})
    ])
      .subscribe(([accountTags, auditTags, auditCats]: [IUiTag[], ITagInventoryTags, ITagInventoryCategories]) => {
        const categoryMap = new Map<number, IUiTagCategory>();
        const tagIds = auditTags.tags.map(tag => tag.tagId);
        const categoryIds = auditCats.tagCategories.map(cat => cat.tagCategoryId);

        this.tagDb = accountTags
          .filter(tag => tagIds.includes(tag.id))
          .map(tag => {
            const category = this.uiTagService.getTagCategory(tag.tagCategoryId);
            categoryMap.set(category.id, category);
            return {id: tag.id, name: tag.name, searchName: tag.name.toLowerCase()};
          })
          .sort((a, b) => a.searchName < b.searchName ? -1 : 1);

        for (const cat of categoryMap.values()) {
          this.tagCategoryDb.push(cat);
        }

        this.tagCategoryDb = this.tagCategoryDb
          .filter(cat => categoryIds.includes(cat.id))
          .sort((c1, c2) => c1.category.toLowerCase() < c2.category.toLowerCase() ? -1 : 1);

        this.buildFilterMenu();
      });
    this.fetchConsentCategories();
    this.fetchGeolocations();
  }

  private fetchConsentCategories() {
    this.ccService.getConsentCategoriesAssignedToRun(this.auditId, this.runId).subscribe(ccsAssignedToRun => {
      this.ccsAssignedToCurrentRun = ccsAssignedToRun.map(cc => ({
        categoryId: cc.consentCategoryId,
        name: cc.name,
        searchName: cc.name.toLowerCase()
      }));
      // If there is already existing filter by CC name and current run doesn't have same CC assigned - filter should be removed
      const existingCCNameFilter = this.filterBarSvc.currentFilters.find(f => f.type === EAuditReportFilterTypes.ConsentCategoryId);

      if (existingCCNameFilter) {
        const existingFilteredCCcategoryId = (existingCCNameFilter.data as IConsentCategoryReportFilterData)?.categoryId;
        const sameCCInCurrentRun = this.ccsAssignedToCurrentRun.find(cc => cc.categoryId === existingFilteredCCcategoryId);

        if (!sameCCInCurrentRun) {
          this.filterBarSvc.removeFilterByType(EAuditReportFilterTypes.ConsentCategoryId);
        } else if (sameCCInCurrentRun.categoryId !== existingCCNameFilter.value) {
          // If the same CC is applied to the current run, but snapshotId is different - update the filter.
          this.filterBarSvc.addConsentCategoryNameFilter(sameCCInCurrentRun.categoryId, sameCCInCurrentRun.name);
        }
      }
    });
  }

  private fetchGeolocations() {
    this.ccService.getConsentCategoryCountries().subscribe(countries => this.countries = countries);
  }

  private buildFilterMenu() {
    // page url search input
    this.pageUrlInputEnabled = this.validFilterTypes.includes(EAuditReportFilterTypes.InitialPageUrlContains);

    setTimeout(() => {
      this.menuConfig = this.filterBarSvc.buildFilterMenu(
        this.validFilterTypes,
        this.tagDb,
        this.tagCategoryDb,
        this.countries,
        this.ccsAssignedToCurrentRun || [],
        ruleName => this.ruleSummaryService.getRuleResults(this.auditId, this.runId, {})
          .pipe(map(rules => rules.rules.map(rule => ({
            ruleName: rule.name,
            ruleId: rule.originalRuleId
          })).filter(rule => rule.ruleName.toLowerCase().includes(ruleName.toLowerCase()))))
      );
    });

    this.changeDetector.detectChanges();
  }

  onClickUrlOptions(event: MouseEvent, type: 'searchInInitialURLs' | 'searchInFinalURLs'): void {
    if (type === 'searchInInitialURLs') {

      if (this.searchInFinalURLs) {
        this.searchInInitialURLs = !this.searchInInitialURLs;
      } else {
        this.showUrlTypeError();
      }

    } else {
      if (this.searchInInitialURLs) {
        this.searchInFinalURLs = !this.searchInFinalURLs;
      } else {
        this.showUrlTypeError();
      }
    }
  }

  private showUrlTypeError() {
    this.snackbar.openFromComponent(SnackbarErrorComponent, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        message: 'You must select at least one URL option.'
      }
    });
  }
}
