import { Component, OnDestroy, OnInit } from '@angular/core';
import { IOpFilterBarMenuItem } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import { AccountsService } from '@app/components/account/account.service';
import { IUser } from '@app/moonbeamModels';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  IAddAlertLabel,
  IAlert,
  IAlertsFilters,
  IAlertsLibraryResponse,
  IChangeAlertLabel,
  ISort
} from '@app/components/alerts-library/alerts-library.models';
import { EAlertsLibraryColumns, EAlertsLibraryFilterTypes } from '@app/components/alerts-library/alerts-library.enums';
import { AlertsLibraryService } from '@app/components/alerts-library/alerts-library.service';
import { AlertsLibraryFilterBarService } from '@app/components/alerts-library/alerts-library-filter-bar.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { AlertService } from '@app/components/alert/alert.service';
import { AlertComponent } from '@app/components/alert/alert.component';
import { IAlertModalPayload } from '@app/components/alert/alert.models';
import { EAlertFilterType, EAlertMenuContext, EAlertModalType, EAlertStep } from '@app/components/alert/alert.enums';
import {
  OpDeleteItemWarningComponent
} from '@app/components/shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AlertChangeSuccessSnackbarComponent,
  EAlertSuccessType
} from '@app/components/alert/alert-change-success-snackbar/alert-change-success-snackbar.component';
import { Location } from '@angular/common';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { IEventManager } from '../eventManager/eventManager';
import { OPEN_ALERT_DESIGNER } from './alerts-library.constants';
import { MetricTypeFilterUtils } from '@app/components/alert/alert-logic/metric-type-filter/metric-type-filter.utils';
import { AlertMetricType } from '@app/components/alert/alert-logic/alert-logic.enums';
import {
  AlertChangeSuccessSnackbarService
} from '../alert/alert-change-success-snackbar/alert-change-success-snackbar.service';
import { IPagination } from '@app/components/shared/components/selectable-table/selectable-table.models';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alerts-library',
  templateUrl: './alerts-library.component.html',
  styleUrls: ['./alerts-library.component.scss']
})
export class AlertsLibraryComponent implements OnDestroy, OnInit {
  private apiFilters: IAlertsFilters;
  private destroy = new Subject<void>();

  readonly EAlertStep = EAlertStep;
  users: IUser[];
  alerts: IAlert[];
  loading = true;

  infoIsExpanded = false;

  filterBarMenuItems: IOpFilterBarMenuItem[] = [
    {
      name: 'Report Metric',
      type: EFilterBarMenuTypes.Flyout,
      children: []
    },
    {
      name: 'Labels',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'Label Search',
          type: EFilterBarMenuTypes.Search,
          searchPlaceholder: 'Search for label',
          action: (event: KeyboardEvent, el?: HTMLElement) => this.handleSearch(event, el, true),
          children: []
        }
      ]
    },
    {
      name: 'Subscribed Alerts Only',
      type: EFilterBarMenuTypes.Button,
      action: () => {
        if (this.isSubscribedOnly()) {
          this.alertsLibraryFilterBarService.removeSubscribedAlertsFilter();
        } else {
          this.alertsLibraryFilterBarService.addSubscribedAlertsFilter();
        }
      }
    },
    {
      name: 'Created By',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'Creator Search',
          type: EFilterBarMenuTypes.Search,
          searchPlaceholder: 'Search for creator',
          action: (event: KeyboardEvent, el?: HTMLElement) => this.handleSearch(event, el),
          children: []
        }
      ]
    },
    {
      name: 'Divider',
      type: EFilterBarMenuTypes.Divider
    },
    {
      name: 'clear all filters',
      type: EFilterBarMenuTypes.ClearBtn,
      action: () => this.alertsLibraryFilterBarService.clear()
    }
  ];

  pagination: IPagination = {
    totalCount: 0,
    totalPageCount: 0,
    currentPageSize: 0,
    pageSize: 50,
    currentPageNumber: 0,
  };

  sortOptions: ISort = {
    sortBy: EAlertsLibraryColumns.Name,
    sortDesc: false,
    sortDir: 'asc'
  };

  isReadOnly = false;

  subscriptionToken: number;

  constructor(
    private alertsLibraryService: AlertsLibraryService,
    private alertService: AlertService,
    private snackBar: MatSnackBar,
    public alertsLibraryFilterBarService: AlertsLibraryFilterBarService,
    private labelService: LabelService,
    private modalService: OpModalService,
    private accountsService: AccountsService,
    private alertReportingService: AlertReportingService,
    private location: Location,
    private eventManager: IEventManager,
    private alertChangeService: AlertChangeSuccessSnackbarService) {
    this.accountsService
      .getUsers()
      .subscribe(users => this.users = users);

    this.accountsService.getUser().subscribe(user => this.isReadOnly = user.permissions === 'guest');

    this.labelService.getLabels().subscribe(labels => {
      const metricClickedAction = (reportName: string, metricName: string, metricType: AlertMetricType) =>
        this.alertsLibraryFilterBarService.addReportMetricFilter(reportName, metricName, metricType);
      this.filterBarMenuItems[0].children =
        MetricTypeFilterUtils.buildReportMetricFilterMenuItemsV1(metricClickedAction);

      this.alertsLibraryFilterBarService.filters$
        .pipe(takeUntil(this.destroy))
        .subscribe((filters: IOpFilterBarV2Filter<string>[]) => {
          this.apiFilters = filters.reduce((filters, filter) => {
            if (filter.type !== EAlertsLibraryFilterTypes.Labels) {
              filters[filter.type] = filter.value;
            } else {
              if (filters[filter.type]) {
                filters[filter.type].push(filter.value);
              } else {
                filters[filter.type] = [filter.value];
              }
            }
            return filters;
          }, {});

          this.filterBarMenuItems[2].name = this.isSubscribedOnly() ? 'All alerts' : 'Subscribed Alerts Only';
          this.pagination.currentPageNumber = 0;
          this.updateTableState({...this.sortOptions, ...this.pagination});
        });

        const state = this.location.getState();
        if (state['openAlertDesigner']) {
          this.alertCreation();
        }
    });
  }

  ngOnInit(): void {
    this.subscriptionToken = this.eventManager.subscribe(OPEN_ALERT_DESIGNER, () => {
      this.alertCreation();
    });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
    this.eventManager.unSubscribe(OPEN_ALERT_DESIGNER, this.subscriptionToken);
  }

  private handleSearch(event: KeyboardEvent, el?: HTMLElement, isLabel = false) {
    if (el) {
      el.focus();
      return;
    } else {
      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

      if (isLabel) {
        this.handleLabelSearch(value);
      } else {
        this.handleCreatorSearch(value);
      }

      this.filterBarMenuItems = [...this.filterBarMenuItems];
    }
  }

  private handleCreatorSearch(value: string) {
    const userSearchChildren = this.users
      .filter(user => (user.firstName + ' ' + user.lastName + user.email).includes(value))
      .map(user => {
        const fullName = user.firstName + ' ' + user.lastName;
        return {
          name: fullName,
          type: EFilterBarMenuTypes.Button,
          action: () => this.alertsLibraryFilterBarService.addCreatedByFilter(fullName, user.id)
        };
      });

    this.filterBarMenuItems[3].children[0].children = value
      ? userSearchChildren
      : [];
  }

  handleLabelSearch(searchString: string) {
    let matchingLabels = [];

    for (let [key, label] of this.labelService.labelMap) {
      if (label.name?.toLowerCase().includes(searchString.toLowerCase())) {
        matchingLabels.push({
          name: label.name,
          type: EFilterBarMenuTypes.Button,
          action: () => this.alertsLibraryFilterBarService.addLabelIdFilter({name: label.name, id: label.id})
        });
      }
    }

    this.filterBarMenuItems[1].children[0].children = matchingLabels;
  }

  private isSubscribedOnly(): boolean {
    return !!this.apiFilters[EAlertsLibraryFilterTypes.IsSubscribedOnly];
  }

  alertEdit(alert: IAlert, currentStep?: EAlertStep) {
    const data: unknown = {
      type: this.isReadOnly ? EAlertModalType.ReadOnly : EAlertModalType.Edit,
      alertId: alert.id,
      currentStep,
      noReprocessOption: true,
      filterType: this.alertReportingService.isMetricSupportsFilters(alert.metricType)
        ? this.alertReportingService.isAccountLevelMetric(alert.metricType) ? EAlertFilterType.V2 : EAlertFilterType.V1
        : EAlertFilterType.None,
      menuContext: this.alertReportingService.isAccountLevelMetric(alert.metricType) ?
        EAlertMenuContext.Usage
        : EAlertMenuContext.Library
    } as IAlertModalPayload;

    this.modalService.openFixedSizeModal(AlertComponent, { disableClose: true, data, autoFocus: false }).afterClosed().subscribe(updated => {
      if (updated) {
        this.updateTableState({...this.sortOptions, ...this.pagination});
      }
    });
  }

  alertDuplication(alert: IAlert) {
    const data = {
      type: EAlertModalType.Duplicate,
      alertId: alert.id,
      noReprocessOption: true,
      filterType: this.alertReportingService.isAccountLevelMetric(alert.metricType)
        ? EAlertFilterType.V2 : EAlertFilterType.V1
    };

    this.modalService.openFixedSizeModal(AlertComponent, { disableClose: true, data, autoFocus: false }).afterClosed().subscribe(updated => {
      this.updateTableState({...this.sortOptions, ...this.pagination});
    });
  }

  alertDelete(alert: IAlert) {
    if (this.isReadOnly) return;

    this.modalService.openModal(OpDeleteItemWarningComponent, {
      data: {
        itemType: 'Alert',
        name: alert.name
      }
    })
      .afterClosed()
      .subscribe((confirmDelete: boolean) => {
        if (confirmDelete) {
          const snackbarData = {
            name: alert.name,
            type: EAlertSuccessType.Deleted,
            alert
          };

          this.alertService.deleteAlert(alert).subscribe(() => {
            this.snackBar.openFromComponent(AlertChangeSuccessSnackbarComponent, {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              data: snackbarData,
              panelClass: 'snackbar-wide-width'
            });
            this.alertChangeService.closeSnackbarOnNextRouteChange(true);
            this.updateTableState({...this.pagination, ...this.sortOptions});
          });
        }
      });
  }

  alertCreation() {
    if (this.isReadOnly) return;

    const data: IAlertModalPayload = {
      type: EAlertModalType.CreateFromLibrary,
      menuContext: EAlertMenuContext.Library,
      filterType: EAlertFilterType.V1,
      noReprocessOption: true,
    };
    this.modalService.openFixedSizeModal(AlertComponent, { disableClose: true, data, autoFocus: false }).afterClosed().subscribe(alert => {
      if (alert) {
        this.updateTableState({...this.sortOptions, ...this.pagination});
      }
    });
  }

  createLabel({label, alertId}: IAddAlertLabel): void {
    if (this.isReadOnly) return;

    const alert = this.alerts.find(r => r.id === alertId);
    if (!alert) return;
    if (!alert.labels) alert.labels = [];

    alert.labelsFormatted = alert.labelsFormatted.filter(l => l.id);

    this.alertsLibraryService
      .updateAlertLabels(alertId, [...alert.labelsFormatted, label])
      .subscribe(labels => {
        alert.labelsFormatted = [...alert.labelsFormatted, label];
      });
  }

  assignLabelToAlert({alertId, labelId}: IChangeAlertLabel) {
    if (this.isReadOnly) return;

    const alert = this.alerts.find(r => r.id === alertId);
    const labels = alert.labelsFormatted.map(label => label.id);

    if (!labels.includes(labelId)) {
      alert.labelsFormatted.push({ id: labelId } as ILabel);
    }
    this.alertsLibraryService.updateAlertLabels(alertId, alert.labelsFormatted).subscribe();
  }

  removeLabel({labelId, alertId}: IChangeAlertLabel) {
    if (this.isReadOnly) return;

    let alert = this.alerts.find(r => r.id === alertId);
    if (!alert || !alert.labels || alert.labels.length <= 0) {
      return;
    }

    this.alertsLibraryService
      .updateAlertLabels(alertId, alert.labelsFormatted.filter(label => label.id !== labelId))
      .subscribe(_ => {
        alert.labelsFormatted = alert.labelsFormatted.filter(label => label.id !== labelId);
      });
  }

  updateTableState(state: ISort & IPagination) {
    this.loading = true;
    this.pagination.currentPageNumber = state.currentPageNumber;
    this.sortOptions.sortBy = state.sortBy;
    this.sortOptions.sortDesc = state.sortDesc;

    this.getAlerts().pipe(finalize(() => this.loading = false)).subscribe(response => this.prepareAlerts(response));
  }

  getAlerts() {
    return this.alertsLibraryService.getAll(this.sortOptions, this.pagination, this.apiFilters);
  }

  async prepareAlerts(alertsResponse: IAlertsLibraryResponse): Promise<void> {
    this.pagination = alertsResponse.metadata.pagination;
    const alerts = alertsResponse.alerts as IAlert[];
    await this.processAlerts(alerts);

    this.alerts = alerts;
    this.loading = false;
  }

  private async processAlerts(alerts: IAlert[]): Promise<void> {
    for (const alert of alerts) {
      const reportConfig = AlertUtils.getReportConfigByMetricType(alert.metricType);
      const metricConfig = AlertUtils.getMetricConfigByMetricType(alert.metricType);

      alert.labelsFormatted = await this.labelService.getLabelsByIds(alert.labels);
      alert.reportTypeFormatted = reportConfig.name;
      alert.metricTypeFormatted = metricConfig.name;
      alert.targetItemCount = alert.reportTypeFormatted === 'Usage' ? '---' : alert.targetItemCount;
    }
  }
}
