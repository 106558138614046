export class Names {
  static config = 'config';
  static localStoragePrefix = 'op';
  static module = 'moonbeamModule';

  static Services = class {
    static api = 'apiService';
    static apiKeysService = 'apiKeysService';
    static auditActions = 'auditActionsService';
    static auditData = 'auditDataService';
    static auditRunSerializer = 'auditRunSerializer';
    static auditSelector = 'auditSelector';
    static auditSummaryStatsSerializer = 'auditSummaryStatsSerializer';
    static authenticationInterceptor = 'authenticationInterceptor';
    static calculateWordWidth = 'calculateWordWidthService';
    static date = 'date.service.ts';
    static discoveryAudit = 'discoveryAuditService';
    static domainActions = 'domainActionsService';
    static domainSelector = 'domainSelector';
    static domains = 'domainsService';
    static eventManager = 'eventManager';
    static exportToEmailModal = 'exportToEmailModalService';
    static folderActions = 'folderActionsService';
    static folderSelector = 'folderSelector';
    static foldersApi = 'foldersApiService';
    static httpCallOnce = 'httpCallOnceService';
    static intervalService = 'intervalService';
    static journeyResultSerializer = 'journeyResultSerializer';
    static labelSelector = 'labelSelector';
    static labelsApi = 'labelsApiService';
    static locations = 'locationsService';
    static manageCardsData = 'manageCardsDataService';
    static manageNewCards = 'manageNewCards';
    static manageTags = 'manageTagsService';
    static modal = 'modalService';
    static modalEscape = 'modalEscapeService';
    static modalHelper = 'modalHelperService';
    static multiSelectAPI = 'multiSelectAPIService';
    static naturalCompare = 'natualCompareService';
    static ngToast = 'ngToast';
    static numberFormat = 'numberFormatService';
    static opSpinner = 'opSpinnerService';
    static pageProfileSerializer = 'pageProfileSerializer';
    static promiseUtils = 'promiseUtils';
    static reportCards = 'reportCardsService';
    static reprocess = 'reprocessService';
    static rulesReportSerializer = 'rulesReportSerializerService';
    static runInfoSerializer = 'runInfoSerializer';
    static selectionSummarySerializer = 'selectionSummarySerializer';
    static shadeWindowData = 'shadeWindowDataService';
    static sorting = 'sortingService';
    static statusBanner = 'statusBannerService';
    static statusBannerProcessor = 'statusBannerProcessor';
    static stopRun = 'stopRunService';
    static tagPresenceSerializer = 'tagPresenceSerializer';
    static tags = 'tagsService';
    static timeSeriesChartFactory = 'timeSeriesChartFactory';
    static toastService = 'toastService';
    static userAgent = 'userAgentService';
    static userEvents = 'userEventsService';
    static utilities = 'utilitiesService';
    static webJourneyAPI = 'webJourneyAPIService';
    static webJourneyResultsSerializer = 'webJourneyResultsSerializer';
    static webJourneyRunsSerializer = 'webJourneyRunsSerializer';
    static webJourneyV3Service = 'webJourneyV3Service';
    static websocketConnection = 'websocketConnectionService';
    static websocketProvider = 'websocketProviderService';
    static widgetSize = 'widgetSizeService';
    static rorComparisonReport = 'rorComparisonReport';
    static storageService = 'storageService';

    static Analytics = class {
      static adobe = 'adobeAnalyticsService';
    };
  };
  static Filters = class {
    static highlightText = 'highlightTextFilter';
    static labelsFilter = 'labelsFilter';
    static navTitle = 'navTitleFilter';
    static nullsLast = 'nullsLast';
  };
  static Controllers = class {
    static confirmation = 'confirmationController';
    static deleteDomainController = 'deleteDomainController';
    static deleteFolderController = 'deleteFolderController';
    static deleteWebJourneyController = 'deleteWebJourneyController';
    static exportToEmailModal = 'exportToEmailModalController';
    static manageTags = 'manageTagsController';
    static manualJourneyRecorder = 'manualJourneyRecorderController';
    static reprocessRulesBanner = 'reprocessRulesBannerController';
    static reprocessRulesModalController = 'reprocessRulesModalController';
    static shadeWindowData = 'shadeWindowDataController';
    static stopRunModal = 'stopRunModalController';
    static rulesReport = 'rulesReportController';
  };

  static Directives = class {
    static fileDropper = 'fileDropper';
    static manualJourneyRecorder = 'manualJourneyRecorder';
    static opClipboard = 'opClipboard';
    static reprocessRulesBanner = 'reprocessRulesBanner';
    static tagContactsPopover = 'tagContactsPopover';
    static rulesReport = 'rulesReport';
  };

  static GlobalStateKeys = class {
    static announcement = 'announcement';
    static auditParams = 'auditParams';

    // Session Storage (creds are specific to a browser tab)
    static authImpersonate = 'authImpersonate';

    static isVisitorUser = 'isVisitorUser';

    // Local Storage (creds are good across browser tabs)
    static authorization = 'authorization';
    static authorizationId = 'authorizationId';
    static originalState = 'originalState';
    
    static disableConfetti = 'disableConfetti';
    static domainParams = 'domainParams';
    static fileUploadPostUploadData = 'fileUploadPostUploadData';
    static folderParams = 'folderParams';
    static primaryTagsMessage = 'primaryTagsMessage';
    static primaryTags = 'primaryTags';
    static reportCardViewMode = 'reportCardViewMode';
    static reportCardViewParams = 'reportCardViewParams';
    static visibleReportCards = 'visibleReportCards';
    static webParams = 'webParams';
    static manageCardsSplitterStatus = 'manageCardsSplitterStatus';
    static pageDetailsTagsView = 'pageDetailsTagsView';
  };

  static NgComponents = class {
    static opChipsNg = 'opChipsNg';
    static opMenuNg = 'opMenuNg';
    static opThumbnail = 'opThumbnailNg';
    static opModal = 'opModalNg';
    static ruleSetupModal = 'ruleSetupModal';
  };

  static NgServices = class {
    static actionSet = 'actionSetService';
    static activatedRoute = 'activatedRouteService';
    static adminService = 'adminService';
    static authentication = 'authenticationServiceNg';
    static authenticationStorage = 'authenticationStorageService';
    static cacheReset = 'cacheResetService';
    static opModalService = 'opModalService';
    static router = 'routerService';
    static routerHistory = 'routerHistoryService';
    static rules = 'rulesService';
    static switchAccount = 'switchAccountService';
    static toast = 'toastServiceNg';
    static snackbarService = 'snackbarServiceNg';
    static webJourneyV3Service = 'webJourneyV3Service';
    static terminateActiveRunsModalService = 'terminateActiveRunsModalService';
    static accountsService = 'accountsService';
    static labelService = 'labelService';
  };
}

export class AngularNames {
  static animate = '$animate';
  static compile = '$compile';
  static cookies = '$cookies';
  static document = '$document';
  static filter = '$filter';
  static http = '$http';
  static httpProvider = '$httpProvider';
  static interval = '$interval';
  static location = '$location';
  static locationProvider = '$locationProvider';
  static log = '$log';
  static logProvider = '$logProvider';
  static ngRedux = '$ngRedux';
  static parse = '$parse';
  static provide = '$provide';
  static q = '$q';
  static rootScope = '$rootScope';
  static sce = '$sce';
  static scope = '$scope';
  static spinnerService = 'usSpinnerService';
  static state = '$state';
  static stateParams = '$stateParams';
  static timeout = '$timeout';
  static window = '$window';
  static windowProvider = '$windowProvider';
  static websocket = '$websocket';
  static localStorageServiceProvider = 'localStorageServiceProvider';
}

export class Events {
  static cardSelected = 'cardSelected';
  static cardDeselected = 'cardDeselected';
  static deselectAllCards = 'deselectAllCards';
  static deselectVisibleCards = 'deselectVisibleCards';
  static destroy = '$destroy';
  static loadReportCardData = 'loadReportCardData';
  static loggedInAsAnother = 'loggedInAsAnother';
  static noDataFound = 'noDataFound';
  static primaryTagWebJourneyToggleChanged = 'primaryTagWebJourneyToggleChanged';
  static rdcUserPresenceConfirmation = 'rdcUserPresenceConfirmation';
  static reportCardFiltersChanged = 'reportCardFiltersChanged';
  static reprocessRulesBannerUpdated = 'reprocessRulesBannerUpdated';
  static reprocessConsentCategoryBannerUpdated = 'reprocessConsentCategoriesBannerUpdated';
  static resize = 'resize';
  static runsStopped = 'runsStopped';
  static selectAllCards = 'selectAllCards';
  static userAlertClearAll = 'userAlertClearAll';

  /**
   * Scroll event used for 'scroll-to' dirrective.
   * @see scrollToInvalidFormDirective
   */
  static scrollTo = 'scrollTo';
  static scrollToValidation = 'scrollToValidation';
}

export class Messages {
  static auditError = 'auditError';
  static auditForbidden = 'auditForbidden';
  static journeyInitiated = 'journeyInitiated';
  static refreshContent = 'refreshContent';
  static reprocessRules = 'reprocessRules';
}

export class ApiPrefix {
  static api = 'api';
  static users = 'users';
  static apiKey = 'api-key';
}

export class AuthenticationEvents {
  static credentialsExpired = 'auth-creeds-expired';
  static loginSuccess = 'auth-login-success';
  static logoutSuccess = 'auth-logout-success';
  static notAuthenticated = 'auth-not-authenticated';
  static notAuthorized = 'auth-not-authorized';
  static sessionTimeout = 'auth-session-timeout';
}

// eventually, all events from the AuthenticationEvents should be moved here
// AuthenticationEvents used for broadcasting events via $rootScope (deprecated solution),
// whereas EAuthenticationEvent used for broadcasting events via RxJS Observable (new solution)
export enum EAuthenticationEvent {
  goToLogin = 'auth-go-to-login',
}

export class ModalEvents {
  static modalHide = 'modal.hide';
}

export function startsWith(str: string, prefix: string): boolean {
  return str.lastIndexOf(prefix, 0) === 0;
}

export class ServerErrorCodes {
  static validationError = 10;
  static marformedRequest = 11;
  static credentialsExpired = 12;

  static accountConcurrencyReached = 32;
  static alreadyRunning = 33;
  static overageLimitExceeded = 34;
}

export class Features {
  static adobeAuditor = 'adobe-auditor';
  static apiKey = 'api-key';
  static realDeviceConnect = 'real-device-connect';
  static harFileIngestor = 'har-file-ingestor';
  static productLinePrivacy = 'productline-privacy';
  static webJourneys = 'web-journeys';

  // blacklisted: flags that are tied to functionality that we've deprecated and plan to rip out soon
  static blacklisted = [];

  static values = [
    {
      name: Features.apiKey,
      displayName: 'API Key'
    },
    {
      name: Features.realDeviceConnect,
      displayName: 'LiveConnect'
    },
    {
      name: Features.harFileIngestor,
      displayName: 'HAR File Ingestor'
    },
    {
      name: Features.productLinePrivacy,
      displayName: 'Product Line: Privacy'
    },
    {
      name: Features.webJourneys,
      displayName: 'Web Journey'
    },
  ];
}

export class HttpStatuses {
  static ok = 200;
  static noContent = 204;
  static multipleChoices = 300;
  static unauthorized = 401;
  static forbidden = 403;
  static authTimeout = 419;
  static loginTimeout = 419;
  static locked = 423;
  static internalServerError = 500;
}
