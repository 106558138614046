<!--CONSENT CATEGORIES LIST-->
<applied-consent-categories
  [runId]="runId"
  [auditId]="auditId"
  [categories]="ccsAssignedToRun"
  [categoriesType]="categoriesType"
  [auditConsentCategories]="auditConsentCategories"
  [isLoadedAuditConsentCategories]="isLoadedAuditConsentCategories"
  [isLoadedRunConsentCategories]="isLoadedRunConsentCategories"
  class="categories-wrap"
></applied-consent-categories>

<!-- WIDGETS -->
<div class="widgets">
  <div class="widget-container nowrap-min-1700 column-max-900">
    <div class="widget-container widget-group-3 nowrap-min-900">
      <split-card [topLabel]="widgetPagesScanned.topLabel"
                  [state]="widgetsState"
                  [topTooltip]="widgetPagesScanned.tooltip"
                  [metricType]="widgetPagesScanned.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="filteredPageCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPagesScanned.metricType"
                  [disableHighlightFromClick]="true"
      >
        <div class="widget-inside-wrap">
          <div class="counts">
            <span class="count">{{ filteredPageCount | number }}</span>
            <span *ngIf="widgetsState === 'filtered'">&nbsp;of {{ totalPageCount | number }}</span>
          </div>
          <filter-spinner [state]="widgetsState"></filter-spinner>
        </div>
      </split-card>
      <split-card [topLabel]="widgetPagesWithUnapproved.topLabel"
                  [state]="widgetsState"
                  [activated]="isFilteredByStatus(EConsentCategoryType.UNAPPROVED)"
                  [topHandler]="widgetPagesWithUnapproved.topHandler"
                  [topTooltip]="widgetPagesWithUnapproved.tooltip"
                  [haveEnoughData]="true"
                  [metricType]="widgetPagesWithUnapproved.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="unapprovedRequestCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPagesWithUnapproved.metricType"
                  [disableHighlightFromClick]="true"
      >
        <div class="widget-inside-wrap">
          <div class="counts">
            <span class="count red">{{ unapprovedRequestCount | number }}</span>
          </div>
          <filter-spinner [state]="widgetsState"></filter-spinner>
        </div>
      </split-card>
      <split-card [topLabel]="widgetNetworkRequestEvaluated.topLabel"
                  [topTooltip]="widgetNetworkRequestEvaluated.tooltip"
                  [state]="widgetsState"
                  [topChangeContent]="widgetNetworkRequestEvaluated.topChangeContent"
                  [bottomHandler]="widgetNetworkRequestEvaluated.bottomHandler"
                  [topChangeMeaning]="widgetNetworkRequestEvaluated.topChangeMeaning"
                  [haveEnoughData]="widgetNetworkRequestEvaluatedData?.length > 1"
                  [metricType]="widgetNetworkRequestEvaluated.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="widgetNetworkRequestEvaluated.rawValue"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetNetworkRequestEvaluated.metricType"
                  [disableHighlightFromClick]="true"
      >
        <sparkline-chart-insight meaningOfUp="none"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetNetworkRequestEvaluatedData">
        </sparkline-chart-insight>
      </split-card>
    </div>
    <div class="widget-container widget-group-4 nowrap-min-1120">
      <div class="widget-container nowrap-min-900">
        <split-card [topLabel]="widgetUniqueDomains.topLabel"
                    [topTooltip]="widgetUniqueDomains.tooltip"
                    [state]="widgetsState"
                    [topChangeContent]="widgetUniqueDomains.topChangeContent"
                    [bottomHandler]="widgetUniqueDomains.bottomHandler"
                    [topChangeMeaning]="widgetUniqueDomains.topChangeMeaning"
                    [haveEnoughData]="widgetUniqueDomainsData?.length > 1"
                    [metricType]="widgetUniqueDomains.metricType"
                    [auditId]="auditId"
                    [runId]="runId"
                    [currentValue]="widgetUniqueDomains.topChangeContent"
                    [filters]="currentFilters"
                    [highlight]="highlightMetricType === widgetUniqueDomains.metricType"
                    [disableHighlightFromClick]="true"
        >
          <sparkline-chart-insight meaningOfUp="none"
                                   meaningOfNoChange="none"
                                   label="since last run"
                                   [dataLoaded]="sparklineDataLoaded"
                                   [dataPoints]="widgetUniqueDomainsData">
          </sparkline-chart-insight>
        </split-card>
        <split-card [topLabel]="widgetUniqueGeolocations.topLabel"
                    [topTooltip]="widgetUniqueGeolocations.tooltip"
                    [state]="widgetsState"
                    [topChangeContent]="widgetUniqueGeolocations.topChangeContent"
                    [bottomHandler]="widgetUniqueGeolocations.bottomHandler"
                    [topChangeMeaning]="widgetUniqueGeolocations.topChangeMeaning"
                    [haveEnoughData]="widgetUniqueGeolocationsData?.length > 1"
                    [metricType]="widgetUniqueGeolocations.metricType"
                    [auditId]="auditId"
                    [runId]="runId"
                    [currentValue]="widgetUniqueGeolocations.topChangeContent"
                    [filters]="currentFilters"
                    [highlight]="highlightMetricType === widgetUniqueGeolocations.metricType"
                    [disableHighlightFromClick]="true"
        >
          <sparkline-chart-insight meaningOfUp="none"
                                   meaningOfNoChange="none"
                                   label="since last run"
                                   [dataLoaded]="sparklineDataLoaded"
                                   [dataPoints]="widgetUniqueGeolocationsData">
          </sparkline-chart-insight>
        </split-card>
      </div>
      <div class="widget-container nowrap-min-900">
        <split-card [topLabel]="widgetApproved.topLabel"
                    [topChangeContent]="widgetApproved.topChangeContent"
                    [topChangeMeaning]="widgetApproved.topChangeMeaning"
                    [topTooltip]="widgetApproved.tooltip"
                    [topHandler]="widgetApproved.topHandler"
                    [activated]="isFilteredByStatus(EConsentCategoryType.APPROVED)"
                    [bottomHandler]="widgetApproved.bottomHandler"
                    [state]="widgetsState"
                    [haveEnoughData]="widgetApprovedData?.length > 1"
                    [metricType]="widgetApproved.metricType"
                    [auditId]="auditId"
                    [runId]="runId"
                    [currentValue]="widgetApproved.topChangeContent"
                    [filters]="currentFilters"
                    [highlight]="highlightMetricType === widgetApproved.metricType"
                    [disableHighlightFromClick]="true"
        >
          <sparkline-chart-insight meaningOfUp="none"
                                   meaningOfNoChange="none"
                                   label="since last run"
                                   [dataLoaded]="sparklineDataLoaded"
                                   [dataPoints]="widgetApprovedData">
          </sparkline-chart-insight>
        </split-card>
        <split-card [topLabel]="widgetUnapproved.topLabel"
                    [topTooltip]="widgetUnapproved.tooltip"
                    [topHandler]="widgetUnapproved.topHandler"
                    [topChangeContent]="widgetUnapproved.topChangeContent"
                    [activated]="isFilteredByStatus(EConsentCategoryType.UNAPPROVED)"
                    [bottomHandler]="widgetUnapproved.bottomHandler"
                    [state]="widgetsState"
                    [topChangeMeaning]="widgetUnapproved.topChangeMeaning"
                    [haveEnoughData]="widgetUnapprovedData?.length > 1"
                    [metricType]="widgetUnapproved.metricType"
                    [auditId]="auditId"
                    [runId]="runId"
                    [filters]="currentFilters"
                    [highlight]="highlightMetricType === widgetUnapproved.metricType"
                    [disableHighlightFromClick]="true"
        >
          <sparkline-chart-insight meaningOfUp="negative"
                                   meaningOfNoChange="none"
                                   label="since last run"
                                   [dataLoaded]="sparklineDataLoaded"
                                   [dataPoints]="widgetUnapprovedData">
          </sparkline-chart-insight>
        </split-card>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="section-title-wrap">
    <div class="title-spinner-wrap">
      <h5 class="section-title">Geolocations of Approved & Unapproved Requests</h5>
      <filter-spinner [state]="complianceTableState"></filter-spinner>
    </div>
  </div>

  <privacy-request-map
    showFullscreen
    [focusedCountries]="focusedLocations$ | async"
    [selectedCountry]="selectedCountry"
    (selectCountry)="filterByCountry($event)"
    (fullscreen)="openFullscreenMap()">
  </privacy-request-map>
</div>

<!-- COMPLIANCE TABLE -->
<div class="compliance-wrap">
  <requests-compliance-table
    #requestsTable
    [items]="requests$ | async"
    [auditName]="audit?.name"
    [runId]="runId"
    [auditId]="auditId"
    [apiFilters]="apiFilters"
    [runConsentCategories]="ccsAssignedToRun"
    [auditConsentCategories]="auditConsentCategories"
    [exportReportConfig]="requestsComplianceExportConfig"
    [state]="complianceTableState"
    [filteredPageCount]="filteredPageCount"
    (onSort)="handleSortCompliance($event)"
    (onManageConsentCategories)="openConsentCategoryManager()"
    (localFilter)="handleLocalFilter($event)"
    (globalFilter)="filterByCountry($event)"
    (onPaginate)="handlePaginateCompliance($event)"
    (onGlobalFilterByCategoryName)="handleConsentCategoryNameFilterFromCompliance($event)"
  ></requests-compliance-table>
</div>

<!-- PAGES SCANNED -->
<privacy-requests-table
  #pagesTable
  [exportReportConfig]="pagesExportConfig"
  [state]="pagesTableState"
  [items]="pages$ | async"
  [requestDomainToAutofill]="requestComplianceVariables?.requestDomain"
  (onSort)="handleSortPages($event)"
  (onPaginate)="handlePaginationPages($event)"
></privacy-requests-table>
