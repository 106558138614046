import { RouteBuilder } from './../shared/services/route-builder.service';
const baseState = 'web-journey-report';

export const WebJourneyReportStateNames = {
  base: baseState,
  results: baseState + '.results',
  rules: baseState + '.rules',
  exports: baseState + '.exports',
};

export const WebJourneyReportPaths = {
  base: 'web-journey/:journeyId/run/:runId',
  results: 'results',
  rules: 'rules',
  exports: 'journey-exports'
};

const basePathBuilder = (journeyId: number, runId: number) => {
  return RouteBuilder.buildUrl([WebJourneyReportPaths.base], String(journeyId), String(runId));
};
export const WebJourneyReportUrlBuilders = {
  base: (journeyId: number, runId: number) => basePathBuilder(journeyId, runId),
  results: (journeyId: number, runId: number) => {
    return basePathBuilder(journeyId, runId) + '/' + WebJourneyReportPaths.results;
  },
  rules: (journeyId: number, runId: number) => {
    return basePathBuilder(journeyId, runId) + '/' + WebJourneyReportPaths.rules;
  },
  exports: (journeyId: number, runId: number) => {
    return basePathBuilder(journeyId, runId) + '/' + WebJourneyReportPaths.exports;
  },
};

export const WebJourneyTitles = {
  results: 'Journey Report',
  rules: 'Journey Rules',
  exports: 'Journey Exports'
};
