<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="data.title || 'Screenshot'"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="image-container-wrapper">
      <div class="image-container">
        <img [src]="data.screenshotUrl" onError="this.src='/images/no-img.png'" [alt]="data.title">
      </div>
    </div>
  </ng-template>
</op-modal>
