import { AngularNames, Names } from '@app/moonbeamConstants';
import { ButtonSet } from '@app/models/commons';
import { IStopRunModalData } from '../modals/modalData';
import { IStopRunService } from './stopRunService';

export class RunnableItem {
  readonly id: number;
  readonly name: string;

  private constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  private static items: { [id: number]: RunnableItem } = {};

  private static props(id: number, name: string): RunnableItem {
    return (RunnableItem.items[id] = new RunnableItem(id, name));
  }

  static readonly Audit = RunnableItem.props(0, 'Audit');
  static readonly WebJourney = RunnableItem.props(1, 'Web-Journey');
  static readonly LiveConnect = RunnableItem.props(3, 'Live-Connect');
}

export interface StopRunItem {
  itemType: RunnableItem;
  itemId: number;
  runId: number;
}

export interface StopRunItemsGroup {
  notificationTitle: string;
  items: Array<StopRunItem>;
}

interface StopRunProgress {
  started: boolean;
  item: StopRunItem;
}

interface IStopRunModalScope extends mgcrea.ngStrap.modal.IModalScope {
  confirmationTitle: string;
  itemsGroups: Array<StopRunItemsGroup>;
  isStopConfirmed: boolean;
}

export class StopRunModalController {
  modalButtons: Array<ButtonSet>;
  discardButtonText: string;

  private itemsGroups: Array<StopRunItemsGroup>;
  private confirmationTitle: string;

  static $inject = [
    AngularNames.q,
    AngularNames.scope,
    Names.Services.stopRun
  ];

  constructor(
    private $q: ng.IQService,
    private $scope: IStopRunModalScope,
    private stopRunService: IStopRunService,
  ) {
    this.itemsGroups = $scope.itemsGroups;
    this.confirmationTitle = $scope.confirmationTitle;
    this.initModalButtons();
  }

  multipleItems(): boolean {
    return (
      this.itemsGroups.reduce((acc, next) => acc + next.items.length, 0) > 1
    );
  }

  private initModalButtons(): void {
    this.modalButtons = [
      [
        {
          label: 'Yes, Stop',
          action: 'stopRunAndCloseModal',
          type: 'success',
          primary: true
        }
      ]
    ];
    this.discardButtonText = "Don't Stop";
  }

  stopRunAndCloseModal(): void {
    this.stopRuns().then(() => {
      this.close();
    });
  }

  private stopRuns(): ng.IPromise<StopRunProgress[][]> {
    const stoppedRunGroups = this.itemsGroups.map(group => {
      const runStops = group.items.map(item => {
        return this.stopRunService.stopRunInternal(item).then(isStarted => {
          return <StopRunProgress>{
            started: isStarted,
            item: item
          };
        });
      });

      return this.$q.all(runStops).then((stops: StopRunProgress[]) => {
        const runsForStopping = stops.filter(s => s.started).map(s => s.item);
        this.stopRunService.subscribeOnStopRunUpdates({
          notificationTitle: group.notificationTitle,
          items: runsForStopping
        });
        return stops;
      });
    });
    this.$scope.isStopConfirmed = true;
    return this.$q.all(stoppedRunGroups);
  }

  private close(): void {
    (<mgcrea.ngStrap.modal.IModalScope>this.$scope.$parent).$hide();
  }

  static modalData(
    confirmationTitle: string,
    itemsGroups: Array<StopRunItemsGroup>
  ): IStopRunModalData {
    return {
      confirmationTitle: confirmationTitle,
      itemsGroups: itemsGroups
    };
  }

  static stopRunsGroup(
    notificationTitle: string,
    items: Array<StopRunItem>
  ): StopRunItemsGroup {
    return {
      notificationTitle: notificationTitle,
      items: items
    };
  }

  static defaultModalData(
    itemType: RunnableItem,
    itemId: number,
    runId: number
  ): IStopRunModalData {
    return StopRunModalController.modalData(
      StopRunModalController.defaultConfirmation(itemType),
      [
        StopRunModalController.stopRunsGroup(
          StopRunModalController.defaultNotification(),
          [StopRunModalController.stopRunItem(itemType, itemId, runId)]
        )
      ]
    );
  }

  static defaultModalDataGroup(items: Array<StopRunItem>): IStopRunModalData {
    return StopRunModalController.modalData(
      StopRunModalController.defaultConfirmation(items[0].itemType, true),
      [
        StopRunModalController.stopRunsGroup(
          StopRunModalController.defaultNotification(true),
          items
        )
      ]
    );
  }

  static defaultConfirmation(
    itemType: RunnableItem,
    multiple: boolean = false
  ): string {
    return `Are you sure you want to stop ${multiple ? `these` : `this`} ${
      itemType.name
    }${multiple ? `s` : ``}?`;
  }

  static defaultNotification(multiple: boolean = false): string {
    return `The ${
      multiple ? `runs have` : `run has`
    } been successfully stopped and discarded`;
  }

  private static stopRunItem(
    itemType: RunnableItem,
    itemId: number,
    runId: number
  ): StopRunItem {
    return {
      itemType: itemType,
      itemId: itemId,
      runId: runId
    };
  }
}
