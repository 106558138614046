<op-modal (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="CONSTANTS.modalTitle"
                     (onClose)="close()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="add-data-section" *ngIf="dataLoaded">
      <div class="new-data-section">
        <div class="section-heading">{{CONSTANTS.data}}</div>
        <div class="">{{CONSTANTS.websiteAuditsAndJourneys}}</div>
        <div class="new-data-options">
          <new-data-option *ngFor="let option of webAuditsAndJourneyTypes"
                       [option]="option"
                       class="new-data-option">
            <p class="disabled-state" *ngIf="CONSTANTS.webJourney === option.type">
              <a class="hover-link" (click)="externalLinksService.openAdditionalProducts()">Learn More</a>
            </p>
          </new-data-option>
        </div>
      </div>
      <div class="new-data-section" *ngIf="externalDataSourceTypes.length > 0 && accountType !== EAccountType.FREETRIAL && accountType !== EAccountType.SAMPLE">
        <div>{{CONSTANTS.externalDataSources}}</div>
        <div class="new-data-options">
          <new-data-option *ngFor="let option of externalDataSourceTypes"
                       [option]="option"
                       class="new-data-option">
          </new-data-option>
        </div>
      </div>
      <div class="new-data-section">
        <div class="section-heading">{{CONSTANTS.standards}}</div>
        <div class="new-data-options">
          <new-data-option *ngFor="let option of standardTypes"
                       [option]="option"
                       class="new-data-option">
          </new-data-option>
        </div>
      </div>
      <div class="new-data-section">
        <div class="section-heading">{{CONSTANTS.configurations}}</div>
        <div *ngIf="CONSTANTS.addStructure">{{CONSTANTS.addStructure}}</div>
        <div class="new-data-options">
          <new-data-option *ngFor="let option of addStructureTypes"
                       [option]="option"
                       class="new-data-option">
          </new-data-option>
        </div>
      </div>
      <div class="new-data-section">
        <div *ngIf="CONSTANTS.organizeInformation">{{CONSTANTS.organizeInformation}}</div>
        <div class="new-data-options">
          <new-data-option *ngFor="let option of organizeInformationTypes"
                       [option]="option"
                       class="new-data-option">
          </new-data-option>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #footerTemplate></ng-template>
</op-modal>
