import { Component, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TagAccountOperators } from "./rule-tag-account-filter.constants";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-tag-account-filter',
  templateUrl: './rule-tag-account-filter.component.html',
  styleUrls: ['./rule-tag-account-filter.component.scss']
})
export class RuleTagAccountFilterComponent {

  @Input() formData: UntypedFormGroup;
  @Output() onRemoveAccount = new EventEmitter<void>();

  TagAccountOperators = TagAccountOperators;

  get matchTypeControl(): UntypedFormControl {
    return this.formData?.get('matchType') as UntypedFormControl;
  }

  get valueControl(): UntypedFormControl {
    return this.formData?.get('value') as UntypedFormControl;
  }

}
