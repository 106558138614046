import { MatSort, Sort } from '@angular/material/sort';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ComponentChanges } from '@app/models/commons';
import { ILabel } from '@app/components/shared/services/label.service';
import {
  ESelectableTableSelectionMode,
  IPagination,
  ISelectableTableSelectionChange
} from '../selectable-table.models';
import { ISimpleTableColumn, ISimpleTableItemsState } from './simple-table.models';
import { ESimpleTableColumnType } from './simple-table.enums';

@Component({
  selector: 'op-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleTableComponent<D extends { id: number }> implements AfterViewInit, OnChanges {
  readonly ESimpleTableSelectionMode = ESelectableTableSelectionMode;

  @Input() allItems: ISimpleTableItemsState<D>;
  @Input() selectedItems: D[];
  @Input() columns: ISimpleTableColumn<D>[];
  @Input() labels: ILabel[];
  @Input() pagination: IPagination;
  @Input() selectionMode: ESelectableTableSelectionMode = ESelectableTableSelectionMode.Multiple;

  @Input() isReadOnly: boolean;
  @Input() readOnlyLabel: string;
  @Input() hideSelectAll = false;
  @Input() stickyHeader = false;
  @Input() selectionIndentifier = 'id';

  @Output() selectionChanged = new EventEmitter<ISelectableTableSelectionChange<D>>();
  @Output() sortChanged = new EventEmitter<Sort>();
  @Output() paginationChanged = new EventEmitter<number>();

  @ViewChild(MatPaginator) matPaginator?: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[];
  dataSource = new MatTableDataSource();

  readonly ESimpleTableColumnType = ESimpleTableColumnType;

  ngAfterViewInit() {
    this.matPaginator?.page.subscribe(({ pageIndex }) => this.paginationChanged.emit(pageIndex));
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item: D, property: string) => {
      const value = item[property];
      return typeof value === 'string' ? value.toLowerCase() : value;
    };
  }

  ngOnChanges(changes: ComponentChanges<SimpleTableComponent<D>>) {
    const { columns, allItems } = changes;

    if (columns?.currentValue && columns.currentValue !== columns.previousValue) {
      const customColumns = columns.currentValue.map(column => column.propName) as string[];
      this.displayedColumns = ['select', ...customColumns];
    }

    if (allItems?.currentValue && allItems.currentValue !== allItems.previousValue) {
      this.dataSource.data = allItems.currentValue.items;
    }
  }

  toggleAllRows(checked: boolean) {
    const selectedIds = this.selectedItems.map(item => item[this.selectionIndentifier]);

    const { items } = this.allItems;
    if (checked) {
      const added = items.filter(item => !selectedIds.includes(item[this.selectionIndentifier]));
      this.selectionChanged.emit({ added });
    } else {
      const removed = items.filter(item => selectedIds.includes(item[this.selectionIndentifier]));
      this.selectionChanged.emit({ removed });
    }
  }

  toggleRow(row: D): void {
    if (this.selectionMode === ESelectableTableSelectionMode.Single) {
      this.toggleAllRows(false);
    }
    const changeResult = this.isSelected(row) ? { removed: [row] } : { added: [row] };
    this.selectionChanged.emit(changeResult);
  }

  areAllSelected(): boolean {
    return !this.allItems.items.find(item => !this.isSelected(item));
  }

  isSelected(row: D): boolean {
    return !!this.selectedItems.find(item => item[this.selectionIndentifier] === row[this.selectionIndentifier]);
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }
}
