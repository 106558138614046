export const enum AuditCreatorTitle {
  Create = 'Create Audit',
  Edit = 'Edit Audit',
}

export enum LearnMoreLinks {
  PreAuditActionsLearnMore = 'https://help.observepoint.com/en/articles/9101151-add-pre-audit-actions-to-an-audit',
  AuditActionsLearnMore = 'https://help.observepoint.com/en/articles/9099982-creating-audits',
  ObservepointCustomTagLearnMore = 'https://help.observepoint.com/en/articles/9106318-observepoint-custom-tag',
}

export const ExplanationText = 'Standards assigned here will be applied to the results of any pages and/or actions audited.'
export const LearnMoreLink = 'https://help.observepoint.com/article/365-standards-configurations-data-sources';

export const ErrorMessage = 'There is an issue with one or more of the fields above.';
