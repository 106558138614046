import { FeatureFlags } from './feature-flags.enum';
import { environment } from './environment';

export class FeatureFlagHelper {

  /**
   * Checks to see if a particular feature flag is enabled in the environment
   * @param flag The feature flag to test
   */
  static isEnabled(flag: FeatureFlags): boolean {
    return environment.features.includes(flag);
  }

  /**
   * Checks to see if a particular feature flag is enabled in the environment -OR- that the environment is not production
   * @param flag The feature flag to test
   */
  static isEnabledOrNonProd(flag: FeatureFlags) {
    return FeatureFlagHelper.isEnabled(flag) || !environment.production;
  }
}
