<div class="main-body">
  <mat-icon class="check-icon">check_circle</mat-icon>
  <div class="success-text">
    <strong>All Setup!</strong> Your Inbox inboxName with 2 associated Audits have been created. You will get a notification at <b>user email address</b> when all validation is complete.
    <br>
    <br>
    <b>Start sending your email messages to this Inbox address (copied to your clipboard):</b>
    <div class="email-wrapper">
      <div class="email-value">
        {{data.emailAddress}}
      </div>
      <mat-icon class="copy-icon" matTooltip="Copy email to your clipboard" matTooltipPosition="right" (click)="copyToClipboard(data.emailAddress)">content_copy</mat-icon>
    </div>
  </div>
  <mat-icon class="close-icon" (click)="close()">close</mat-icon>
</div>
<div class="footer">
  <button class="copy-and-review" mat-button (click)="copyToClipboard(data.emailAddress);close();">
    COPY ADDRESS TO CLIPBOARD & CLOSE
  </button>
</div>
