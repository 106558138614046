import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IJourneyReportInfoData } from '../web-journey-report-header.models';

export interface IJourneyReportInfoFailedRulesNotice {
  failedActionRulesCount: number;
  failedActionRules: IJourneyReportInfoFailedRule[];
  failedGlobalRulesCount: number;
  totalFailedRulesCount: number;
}

export interface IJourneyReportInfoFailedRule {
  actionIndex: number;
  actionName: string;
  failedCount: number;
}

export interface IJourneyReportInfoFailedActionNotice {
  actionIndex: number;
  failureReason: string;
  showFixJourneySpinner: boolean;
  fixRequested: boolean;
  outOfFixes: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'web-journey-report-info',
  templateUrl: './web-journey-report-info.component.html',
  styleUrls: ['./web-journey-report-info.component.scss']
})
export class WebJourneyReportInfoComponent {

  @Input() data: IJourneyReportInfoData;
  @Input() advancedJourneyFeatures: string[];
  @Input() failedRulesData: IJourneyReportInfoFailedRulesNotice;
  @Input() failedActionData: IJourneyReportInfoFailedActionNotice;

  @Output() jumpToAction: EventEmitter<number> = new EventEmitter<number>();
  @Output() jumpToGlobalRules: EventEmitter<null> = new EventEmitter<null>();
  @Output() requestJourneyFix: EventEmitter<null> = new EventEmitter<null>();

}
