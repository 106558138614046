import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
  IFullscreenChartConfig
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import {
  CommonReportsPagesFiltersConfig,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';

import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export enum CookieInventoryTrendName {
  UniqueCookies = 'unique_cookies',
  FirstParty = 'first_party',
  ThirdParty = 'third_party',
  NonSecure = 'non_secure_count',
  SameSiteEmpty = 'same_site_empty'
}

export const UNIQUE_COOKIES_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'Unique Cookies',
  data: [],
  sideLabel: 'number',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => {
    return `${value} cookie${value === 1 ? '' : 's'}`;
  }
};

export const FIRST_PARTY_CHART_CONFIG: IFullscreenChartConfig = {
  title: '1st Party',
  data: [],
  sideLabel: 'number',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => {
    return `${value} cookie${value === 1 ? '' : 's'}`;
  }
};

export const THIRD_PARTY_CHART_CONFIG: IFullscreenChartConfig = {
  title: '3rd Party',
  data: [],
  sideLabel: 'number',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => {
    return `${value} cookie${value === 1 ? '' : 's'}`;
  }
};

export const NON_SECURE_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'Non-Secure',
  data: [],
  sideLabel: 'number',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => {
    return `${value} cookie${value === 1 ? '' : 's'}`;
  }
};

export const SAME_SITE_EMPTY_CHART_CONFIG: IFullscreenChartConfig = {
  title: 'SameSite Empty',
  data: [],
  sideLabel: 'number',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => {
    return `${value} cookie${value === 1 ? '' : 's'}`;
  }
};

export const party = {
  'owned_3rd_party': '"owned" 3rd',
  '3rd_party': '3rd',
  '1st_party': '1st'
};

export enum ECookieInventoryFilterType {
  Name = 'name',
  Party = 'party',
  SameSite = 'sameSite',
  Secure = 'secure',
  Domain = 'domain',
  HttpOnly = 'httpOnly',
}

export enum ECookiePartyTypeConverter {
  '1st' = '1st_party',
  '3rd' = '3rd_party',
  '\"owned\" 3rd' = 'owned_3rd_party'
}

export enum ECookieInventoryExportType {
  Pages = 'cookie_inventory_pages',
  CookiePages = 'cookie_inventory_cookie_pages',
  Cookies = 'cookie_inventory_cookies_aggregated'
}

export const CookieInventoryRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,
  EAuditReportFilterTypes.CookieName,
  EAuditReportFilterTypes.CookieDomain,
  EAuditReportFilterTypes.CookieInitiatorDomain,
  EAuditReportFilterTypes.CookieInitiatorUrl,
  EAuditReportFilterTypes.CookiePartyType,
  EAuditReportFilterTypes.CookieSameSite,
  EAuditReportFilterTypes.CookieSecure,
  EAuditReportFilterTypes.CookieHttpOnly,
  EAuditReportFilterTypes.CookiePartitioned,
  EAuditReportFilterTypes.CookieAllThirdParty,
  EAuditReportFilterTypes.CookieExpirationType,
  EAuditReportFilterTypes.CookieSize,
  EAuditReportFilterTypes.CookieDuration,
  EAuditReportFilterTypes.CookieOriginInclusion,
  EAuditReportFilterTypes.TagVendorId,
  EAuditReportFilterTypes.TagAnyRelated,
  EAuditReportFilterTypes.TagCategory,
  EAuditReportFilterTypes.TagId
];

export const PagesTableSettingItems: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.CookieCount]: {
    title: '# Of Cookies',
    checked: true,
  },
  [CommonReportsPagesTableColumns.TotalCookieSize]: {
    title: 'Total Cookie Size',
    checked: true,
  },
};
