<div class="request-check">
  <mat-form-field class="request-check-url">
    <mat-label>Input Request URL</mat-label>
    <input matInput 
      [(ngModel)]="requestUrl" 
      placeholder="http://www.example.com" 
      (keyup)="subject.next(requestUrl)"
    >
    <mat-hint>Perform tag match logic on one URL(pretend the URL is one of the many network requests we sniff and see if it matches as a tag)</mat-hint>
  </mat-form-field>
  
  <div *ngIf="dataSource.data && !loading">
    <div *ngIf="dataSource.data.length">
      <h4 class="results-header">{{dataSource.data.length}} Match{{dataSource.data.length === 1 ? '' : 'es'}} Found:</h4>
      <table mat-table
             [dataSource]="dataSource"
             matSort
             multiTemplateDataRows>
        <ng-container matColumnDef="tagId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            ID
          </th>
          <td mat-cell *matCellDef="let row" valign="middle">
            {{row.tagId}}
          </td>
        </ng-container>
        <ng-container matColumnDef="tagName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Tag
          </th>
          <td mat-cell *matCellDef="let row" valign="middle">
            <img [src]="row.tagIcon" class="tag-icon" />&nbsp;<span>{{row.tagName}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="signature">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Signature
          </th>
          <td mat-cell *matCellDef="let row" valign="middle">
            {{row.signature}}
          </td>
        </ng-container>
        <ng-container matColumnDef="tagCategoryName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Tag Category (ID)
          </th>
          <td mat-cell *matCellDef="let row" valign="middle">
            {{row.tagCategoryName}} ({{row.tagCategoryId}})
          </td>
        </ng-container>
        <tr mat-header-row
            class="tag-database-library-row"
            *matHeaderRowDef="displayedColumns; sticky: true;">
        </tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
      <div *ngIf="loading" class="spinner-wrapper">
        <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
      </div>
    </div>
    <div *ngIf="!(dataSource.data.length > 0) && requestUrl !== undefined">
      <h4 class="results-header">No matches found</h4>
    </div>
  </div>
</div>
