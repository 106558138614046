
export interface IWebElementSelectorType {
  id: number;
  name: string;
}

export enum EWebElementSelectorType {
  Id = 1,
  Name = 2,
  Css = 3,
  XPath = 4,
  HtmlAttrs = 5
}

export enum EWebElementSelectorTypeV3 {
  Id = 'id',
  Name = 'name',
  Css = 'css',
  XPath = 'xpath',
  HtmlAttrs = 'htmlattributes'
}

export interface IAttribute{
  name: string;
  value: string;
}

export interface IParsedOuterHTML {
  tagName?: string;
  attributes?: IAttribute[];
  innerHTML?: string;
}

export interface IWebElementSelector {
  selectorType: EWebElementSelectorType;
  origValue?: string;
  value: string | IParsedOuterHTML;
}

