<!-- WIDGETS -->
<div class="tag-health-charts">
  <div class="widget-container">
    <div class="widget-container widget-nowrap">
      <split-card [topLabel]="widgetPageScanned.topLabel"
                  [topChangeMeaning]="widgetPageScanned.topChangeMeaning"
                  [state]="widgetState"
                  topTooltip="A count of pages scanned in this audit/run."
                  [metricType]="widgetPageScanned.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="pagesFilteredCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPageScanned.metricType"
                  [disableHighlightFromClick]="true"
                  class="pages-scanned-card">
        <div class="widget-pages-details">
          <div class="page-counts">
            <span class="pages-filtered-count">{{ pagesFilteredCount | number }}</span>
            <span *ngIf="widgetState === 'filtered'">&nbsp; of {{ pagesTotalCount | number }}</span>
          </div>
          <filter-spinner [state]="widgetState"></filter-spinner>
        </div>
      </split-card>
      <split-card [topLabel]="widgetAvgTagLoadTime.topLabel"
                  [topChangeContent]="widgetAvgTagLoadTime.topChangeContent"
                  [topChangeMeaning]="widgetAvgTagLoadTime.topChangeMeaning"
                  [bottomHandler]="widgetAvgTagLoadTime.bottomHandler"
                  [haveEnoughData]="widgetAvgTagLoadTimeSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="'Average load time in milliseconds of all tag requests selected by filters.' + (widgetState === 'filtered' ? ' (Filtered)' : '')"
                  [metricType]="widgetAvgTagLoadTime.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="widgetAvgTagLoadTime.currentValue"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetAvgTagLoadTime.metricType"
                  [disableHighlightFromClick]="true"
                  class="average-tag-load-time-card">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="positive"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetAvgTagLoadTimeSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetAvgTagRequestSize.topLabel"
                  [topChangeContent]="formatAvgTagRequestSizeTopValueNumber(widgetAvgTagRequestSize.topChangeContent)"
                  [topChangeMeaning]="widgetAvgTagRequestSize.topChangeMeaning"
                  [bottomHandler]="widgetAvgTagRequestSize.bottomHandler"
                  [haveEnoughData]="widgetAvgTagRequestSizeSparkLineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="'Average size per tag request in bytes.' + (widgetState === 'filtered' ? ' (Filtered)' : '')"
                  [metricType]="widgetAvgTagRequestSize.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="widgetAvgTagRequestSize.currentValue"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetAvgTagRequestSize.metricType"
                  [disableHighlightFromClick]="true"
                  class="average-tag-request-size-card">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="positive"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetAvgTagRequestSizeSparkLineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
    <div class="widget-container widget-nowrap">
      <split-card [topLabel]="widgetTagSlowLoading.topLabel"
                  [topChangeContent]="widgetTagSlowLoading.topChangeContent"
                  [topChangeMeaning]="widgetTagSlowLoading.topChangeMeaning"
                  [bottomHandler]="widgetTagSlowLoading.bottomHandler"
                  [state]="widgetState"
                  [haveEnoughData]="widgetTagSlowLoadingSparklineData.length > 1"
                  [topTooltip]="'Percent of tag requests which loaded slower than 2,000 ms.' + (widgetState === 'filtered' ? ' (Filtered)' : '')"
                  [metricType]="widgetTagSlowLoading.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="widgetTagSlowLoading.currentValue"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetTagSlowLoading.metricType"
                  [disableHighlightFromClick]="true"
                  class="tag-slow-loading-percentage-card">
        <sparkline-chart-insight meaningOf="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetTagSlowLoadingSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetBrokenTag.topLabel"
                  [topChangeContent]="widgetBrokenTag.topChangeContent"
                  [topChangeMeaning]="widgetBrokenTag.topChangeMeaning"
                  [bottomHandler]="widgetBrokenTag.bottomHandler"
                  [haveEnoughData]="widgetBrokenTagSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="'Percent of tag requests which have a 0, 400+, or 500+ HTTP status code.' + (widgetState === 'filtered' ? ' (Filtered)' : '')"
                  [metricType]="widgetBrokenTag.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="widgetBrokenTag.currentValue"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetBrokenTag.metricType"
                  [disableHighlightFromClick]="true"
                  class="broken-tag-requests-percentage-card">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetBrokenTagSparklineData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetTotalTagRequest.topLabel"
                  [topChangeContent]="widgetTotalTagRequest.topChangeContent"
                  [topChangeMeaning]="widgetTotalTagRequest.topChangeMeaning"
                  [bottomHandler]="widgetTotalTagRequest.bottomHandler"
                  [haveEnoughData]="widgetTotalTagRequestSparklineData.length > 1"
                  [state]="widgetState"
                  [topTooltip]="'Count of all tag requests selected by filters.' + (widgetState === 'filtered' ? ' (Filtered)' : '')"
                  [metricType]="widgetTotalTagRequest.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="widgetTotalTagRequest.currentValue"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetTotalTagRequest.metricType"
                  [disableHighlightFromClick]="true"
                  class="total-tag-requests-card">
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetTotalTagRequestSparklineData">
        </sparkline-chart-insight>
      </split-card>
    </div>
  </div>
</div>

<!-- BAR CHARTS -->
<div class="tag-health-charts">
  <div class="chart-container">
    <horizontal-bar-chart *ngIf="tagLoadTimeChartData"
                          [title]="tagLoadTimeChartTitle"
                          [data]="tagLoadTimeChartData"
                          [state]="tagLoadTimeChartState"
                          (filterSelected)="toggleLoadTimeFilter($event)"
                          uniqueIdentifier="chart-1"
                          class="tag-load-times-chart">
    </horizontal-bar-chart>
  </div>
  <div class="chart-container">
    <horizontal-bar-chart *ngIf="tagStatusCodeChartData"
                          [title]="tagStatusCodeChartTitle"
                          [data]="tagStatusCodeChartData"
                          [state]="tagStatusCodeChartState"
                          (filterSelected)="toggleStatusCodeFilter($event)"
                          uniqueIdentifier="chart-2"
                          class="tag-status-codes-chart">
    </horizontal-bar-chart>
  </div>
</div>

<!-- TAGS TABLE -->
<div class="tags-list">
  <tags-list
    [auditId]="auditId"
    [runId]="runId"
    [items]="tagTableData$ | async"
    [tableState]="tagTableState"
    [apiFilters]="apiFilters"
    (tagTableFilterCreated)="addTagTableFilter($event)"
    (loadTimeReportFilterCreated)="addLoadTimeReportFilter($event)"
  ></tags-list>
</div>

<!-- PAGES TABLE -->
<div class="table-wrap" #scrollToTop>
  <div class="section-title-wrap">
    <div class="title-spinner-wrap">
      <filter-spinner [state]="pagesTableState"></filter-spinner>
      <p class="section-title">Pages Scanned</p>
      <op-audit-report-export-menu
        [auditId]="auditId"
        [runId]="runId"
        [tableCompletedState]="pagesTableState"
        [config]="exportReportConfig">
      </op-audit-report-export-menu>
    </div>

    <op-switchable-column-menu [columnConfig]="PagesTableColumns"
                               [storageKey]="CommonPagesConfigLocalStorageKey"
                               [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
  </div>

  <table mat-table
         resizeableTable
         matSort
         [dataSource]="dataSource"
         [class.updating]="pagesTableState === stateOptions.Loading"
         class="tag-health-pages-table"
         [matSortActive]="TableColumn.TagInstanceCount"
         matSortDirection="desc"
         matSortStart="desc"
  >
    <ng-container [matColumnDef]="TableColumn.PageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>INITIAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ page.url }}">
            <div class="url-text">{{ page.url|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
      <th mat-header-cell *matHeaderCellDef resizeableColumn mat-sort-header>FINAL PAGE URL</th>
      <td mat-cell
          *matCellDef="let page"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text"
               opTableCellTooltipPrefix="View Page Details:"
               opTableCellTooltip="{{ page.finalUrl }}">
            <div class="url-text">{{ page.finalUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageLoadTime">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header
          class="center-text fixed-90"
          [matTooltip]="PageLoadColumnTooltip"
          [matTooltipPosition]="'above'"
      >PAGE LOAD TIME<br>(sec)</th>
      <td mat-cell *matCellDef="let page" class="center-text {{ page.loadTimeClass }}">{{ page.loadTime | number }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header
          class="center-text fixed-90">FINAL PAGE<br>STATUS CODE</th>
      <td mat-cell *matCellDef="let page"
          class="center-text {{ page.statusCodeClass }}"
          [matTooltip]="PageStatusCodeTooltipMap[page.statusCode] || null"
          matTooltipPosition="above"
          matTooltipShowDelay="250"
      >{{ page.finalPageStatusCode }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.TagLoadTimeAverage">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header
          class="center-text fixed-110">AVG. TAG LOAD<br>TIME (ms)</th>
      <td mat-cell
          *matCellDef="let page"
          class="center-text {{ page.averageTagLoadTimeClass }}">{{ page.averageTagLoadTime | number }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.TagRequestSizeAverage">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header
          class="center-text fixed-110">AVG. TAG<br>REQUEST SIZE<br>(bytes)</th>
      <td mat-cell *matCellDef="let page" class="center-text">{{ page.averageTagRequestSize | number }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.TagInstanceCount">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header
          class="center-text fixed-90"># OF TAG<br>REQUESTS</th>
      <td mat-cell *matCellDef="let page" class="center-text">{{ page.numberOfTagRequests | number }}</td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.BrokenTagCount">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header
          class="center-text fixed-90">BROKEN<br>TAGS</th>
      <td mat-cell
          *matCellDef="let page"
          class="center-text {{ page.brokenTagsClass }}">{{ page.brokenTags | number }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row
        *matRowDef="let page; columns: displayedColumns$ | async;"
        [ngClass]="{ 'open-in-page-details': page.id === pageIdOpenInPageDetails }"
        (click)="openPageDetails(page)"></tr>
  </table>
  <mat-paginator
    class="pages-paginator"
    [length]="paginationState.length"
    [pageSize]="paginationState.pageSize"
    [hidePageSize]="true"
    (page)="onScrollTop()"
  ></mat-paginator>
</div>
