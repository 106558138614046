import { Injectable } from '@angular/core';
import { ApiService, IApiErrorResponse } from '../core/services/api.service';
import { from, Observable, of } from 'rxjs';
import { environment } from '@app/environments/environment';
import {
  ENotificationCenterTargetItemType,
  INotificationCenterEmailsResponse,
  INotificationCenterSearchTargetItemsRequest,
  INotificationCenterSearchTargetItemsResponse,
  ITargetItemsSort
} from '@app/components/notifications-center/notification-center.models';
import {
  ICheckDuplicatesValidator,
  ICheckDuplicatesValueCombinationsValidator
} from '@app/components/shared/validators/op-validators';
import { HttpParams } from '@angular/common/http';
import { AlertService } from '@app/components/alert/alert.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RulesService } from '@app/components/rules/rules.service';
import { IPagination } from '@app/components/shared/components/selectable-table/selectable-table.models';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import {
  WebJourneyV3NotificationsService
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3-notifications.service';
import { IAlertRequestItem } from '@app/components/alert/alert.models';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import {
  RuleSetupDataSerializationService
} from '@app/components/rules/rule-setup/services/rule-setup-data-serialization.service';
import { BULK_ACTION_MAX_PARALLEL_REQUESTS } from '@app/components/notifications-center/notification-center.constants';
import { IAuditModel } from '@app/components/modals/modalData';
import { EmailInboxesService } from '@app/components/email-inboxes/email-inboxes.service';

export enum EUpdateOperationType {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  REPLACE = 'REPLACE'
}

export enum EUpdateOperationResultType {
  SUCCESS = 'SUCCESS',
  FAILED_TO_UPDATE = 'FAILED_TO_UPDATE',
  FAILED_TO_LOAD = 'FAILED_TO_LOAD',
  NOTHING_TO_UPDATE = 'NOTHING_TO_UPDATE'
}

export interface IUpdateOperationResult {
  itemId: number;
  itemType: ENotificationCenterTargetItemType;
  operation: EmailsUpdateOperation;
  updatedEmails: string[];
  resultType: EUpdateOperationResultType;
  errorStatusCode?: number; // only for failures
  errorMessage?: string; // only for failures
}

export interface IEmailsUpdateOperationData {
  emailsSubmittedForUpdate: string[];
  emailsToReplaceWith?: string[]; //only for EUpdateOperationType.REPLACE
}

export class EmailsUpdateOperation {
  private constructor(public operationType: EUpdateOperationType, public data: IEmailsUpdateOperationData) {
    this.operationType = operationType;
    this.data = data;
  }

  public static ADD(emails: string[]) {
    return new EmailsUpdateOperation(EUpdateOperationType.ADD, { emailsSubmittedForUpdate: [...emails] });
  }

  public static REMOVE(emails: string[]) {
    return new EmailsUpdateOperation(EUpdateOperationType.REMOVE, { emailsSubmittedForUpdate: [...emails] });
  }

  public static REPLACE(emailsToBeReplaced: string[], emailsToReplaceWith: string[]) {
    return new EmailsUpdateOperation(EUpdateOperationType.REPLACE, {
      emailsSubmittedForUpdate: [...emailsToBeReplaced],
      emailsToReplaceWith: [...emailsToReplaceWith]
    });
  }
}

const ITEM_TYPE = ENotificationCenterTargetItemType;
const RESULT_TYPE = EUpdateOperationResultType;

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService implements ICheckDuplicatesValidator, ICheckDuplicatesValueCombinationsValidator {

  notificationCenterApiRoot = `${environment.apiV3Url}notification-center`;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private rulesService: RulesService,
    private auditsService: DiscoveryAuditService,
    private webJourneysService: WebJourneyV3NotificationsService,
    private emailInboxesService: EmailInboxesService,
  ) {
  }

  getEmails(): Observable<INotificationCenterEmailsResponse> {
    return this.apiService.get(`${this.notificationCenterApiRoot}/emails`);
  }

  getEmailsSubscribedTo(
    itemType: ENotificationCenterTargetItemType = ENotificationCenterTargetItemType.AUDIT,
    itemIds: number[],
  ): Observable<INotificationCenterEmailsResponse> {
    return this.apiService.post(
      `${this.notificationCenterApiRoot}/emails`,
      { itemType, itemIds }
    );
  }

  searchTargetItems(
    itemType: ENotificationCenterTargetItemType,
    sorting: ITargetItemsSort,
    pagination: IPagination,
    filters?: INotificationCenterSearchTargetItemsRequest
  ): Observable<INotificationCenterSearchTargetItemsResponse> {
    const params = new HttpParams()
      .set('page', pagination.currentPageNumber)
      .set('size', pagination.currentPageSize)
      .set('sortBy', sorting.sortBy)
      .set('sortDesc', sorting.sortDesc);
    return this.apiService.post(
      `${this.notificationCenterApiRoot}/target-items/${itemType}/search`,
      filters || {},
      { params }
    );
  }

  updateEmailsInAlerts(alertIds: number[], operation: EmailsUpdateOperation): Observable<IUpdateOperationResult> {
    const createLoadAlertObservable = (alertId: number) => this.alertService.getAlertById(alertId).pipe(
      catchError((error: IApiErrorResponse) => {
        return this.handleUpdate(alertId, ITEM_TYPE.ALERT, operation, RESULT_TYPE.FAILED_TO_LOAD, undefined, error);
      })
    );
    return this.updateEmailsInItems<IAlertRequestItem>(
      ENotificationCenterTargetItemType.ALERT,
      operation,
      of(...alertIds).pipe(mergeMap(createLoadAlertObservable, BULK_ACTION_MAX_PARALLEL_REQUESTS)),
      alert => alert.id,
      alert => alert.emails,
      alert => this.alertService.updateAlert(alert)
    );
  }

  updateEmailsInRules(ruleIds: number[], operation: EmailsUpdateOperation): Observable<IUpdateOperationResult> {
    const createLoadRuleObservable = (ruleId: number) => this.rulesService.getRule(ruleId).pipe(
      map(RuleSetupDataSerializationService.normaliseTagVariablesBeforeSaving),
      catchError((error: IApiErrorResponse) => {
        return this.handleUpdate(ruleId, ITEM_TYPE.RULE, operation, RESULT_TYPE.FAILED_TO_LOAD, undefined, error);
      })
    );
    return this.updateEmailsInItems(
      ENotificationCenterTargetItemType.RULE,
      operation,
      of(...ruleIds).pipe(mergeMap(createLoadRuleObservable, BULK_ACTION_MAX_PARALLEL_REQUESTS)),
      rule => rule.id,
      rule => rule.recipients,
      rule => this.rulesService.updateRule(rule)
    );
  }

  updateEmailsInAudits(auditIds: number[], operation: EmailsUpdateOperation, itemsSource: Observable<IAuditModel[]>): Observable<IUpdateOperationResult> {
    /**
     * This method serves multiple purposes:
     * 1. It filters out items that are not in auditIds (we download all accessible items within account from the server)
     * 2. It maintains amount of objects emitted from returned Observable identical to the size of auditIds
     * 2.1. For items which are in auditIds but not in itemsSource, it returns a failure result
     * 2.2. In case of error in itemsSource, it returns a failure result for each item in auditIds
     */
    const createLoadAuditObservable = (auditId: number) => {
      return itemsSource.pipe(
        mergeMap((items) => {
          const itemByItemId = ArrayUtils.toMap(items, 'id') as Map<number, IAuditModel>;
          if (itemByItemId.has(auditId)) {
            return of(itemByItemId.get(auditId));
          } else {
            return this.handleUpdate(auditId, ITEM_TYPE.AUDIT, operation, RESULT_TYPE.FAILED_TO_LOAD);
          }
        }),
        catchError((error: IApiErrorResponse) => {
          return this.handleUpdate(auditId, ITEM_TYPE.AUDIT, operation, RESULT_TYPE.FAILED_TO_LOAD, undefined, error);
        })
      );
    };
    return this.updateEmailsInItems<IAuditModel>(
      ENotificationCenterTargetItemType.AUDIT,
      operation,
      of(...auditIds).pipe(mergeMap(createLoadAuditObservable, BULK_ACTION_MAX_PARALLEL_REQUESTS)),
      audit => audit.id,
      audit => audit.recipients,
      audit => from(this.auditsService.updateAudit(audit))
    );
  }

  /* Return all audits */
  getAllAudits(): Observable<IAuditModel[]> {
    return from(this.auditsService.getAudits());
  }

  updateEmailsInInboxEmailsReceived(inboxIds: number[], operation: EmailsUpdateOperation): Observable<IUpdateOperationResult> {
    const createLoadInboxEmailsReceivedObservable = (inboxId: number) => this.emailInboxesService.getEmailInbox(inboxId)
      .pipe(
        catchError((error: IApiErrorResponse) => this.handleUpdate(inboxId, ITEM_TYPE.EMAIL_INBOX_MESSAGE_RECEIVED, operation, RESULT_TYPE.FAILED_TO_LOAD, undefined, error))
      );
    return this.updateEmailsInItems(
      ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED,
      operation,
      of(...inboxIds).pipe(mergeMap(createLoadInboxEmailsReceivedObservable, BULK_ACTION_MAX_PARALLEL_REQUESTS)),
      inbox => inbox.id,
      inbox => inbox.subscribers.onReceived,
      inbox => this.emailInboxesService.updateEmailInbox(inbox)
    );
  }

  updateEmailsInInboxEmailsProcessed(inboxIds: number[], operation: EmailsUpdateOperation): Observable<IUpdateOperationResult> {
    const createLoadInboxEmailsProcessedObservable = (inboxId: number) => this.emailInboxesService.getEmailInbox(inboxId)
      .pipe(
        catchError((error: IApiErrorResponse) => this.handleUpdate(inboxId, ITEM_TYPE.EMAIL_INBOX_MESSAGE_PROCESSED, operation, RESULT_TYPE.FAILED_TO_LOAD, undefined, error))
      );
    return this.updateEmailsInItems(
      ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED,
      operation,
      of(...inboxIds).pipe(mergeMap(createLoadInboxEmailsProcessedObservable, BULK_ACTION_MAX_PARALLEL_REQUESTS)),
      inbox => inbox.id,
      inbox => inbox.subscribers.onProcessed,
      inbox => this.emailInboxesService.updateEmailInbox(inbox)
    );
  }

  updateEmailsInWebJourneys(journeyIds: number[], operation: EmailsUpdateOperation): Observable<IUpdateOperationResult> {
    const createLoadJourneyObservable = (journeyId: number) => this.webJourneysService.getNotificationsConfig(journeyId).pipe(
      map(notificationConfig => ({ id: journeyId, notificationConfig })),
      catchError((error: IApiErrorResponse) => {
        return this.handleUpdate(journeyId, ITEM_TYPE.ALERT, operation, RESULT_TYPE.FAILED_TO_LOAD, undefined, error);
      })
    );
    return this.updateEmailsInItems(
      ENotificationCenterTargetItemType.AUDIT,
      operation,
      of(...journeyIds).pipe(mergeMap(createLoadJourneyObservable, BULK_ACTION_MAX_PARALLEL_REQUESTS)),
      journey => journey.id,
      journey => journey.notificationConfig.emails,
      journey => this.webJourneysService.updateNotificationsConfig(journey.id, journey.notificationConfig)
    );
  }

  private updateEmailsInItems<T>(
    itemType: ENotificationCenterTargetItemType,
    operation: EmailsUpdateOperation,
    itemsSource: Observable<T | IUpdateOperationResult>,
    itemIdAccessor: (item: T) => number,
    itemEmailsAccessor: (item: T) => string[],
    itemUpdater: (item: T) => Observable<any>
  ): Observable<IUpdateOperationResult> {
    const RESULT_TYPE = EUpdateOperationResultType;
    return itemsSource.pipe(
      mergeMap((itemObject) => {
        // itemsSource might fail to load a certain item, in which case we want to return a failure result for that item
        const presumablyFailureResult = itemObject as IUpdateOperationResult;
        if (presumablyFailureResult.resultType && presumablyFailureResult.resultType === RESULT_TYPE.FAILED_TO_LOAD) {
          return of(presumablyFailureResult);
        } else {
          return this.updateEmailsInSingleItem(itemType, operation, itemObject, itemIdAccessor, itemEmailsAccessor, itemUpdater);
        }
      }, BULK_ACTION_MAX_PARALLEL_REQUESTS),
      catchError((error: IApiErrorResponse) => this.handleUpdate(undefined, itemType, operation, RESULT_TYPE.FAILED_TO_LOAD, undefined, error))
    );
  }

  private updateEmailsInSingleItem<T>(
    itemType: ENotificationCenterTargetItemType,
    operation: EmailsUpdateOperation,
    item: T,
    itemIdAccessor: (item: T) => number,
    itemEmailsAccessor: (item: T) => string[],
    itemUpdater: (item: T) => Observable<any>
  ): Observable<IUpdateOperationResult> {
    const RESULT_TYPE = EUpdateOperationResultType;
    const itemId = itemIdAccessor(item);
    const itemEmails = itemEmailsAccessor(item);
    let updatedEmails;
    switch (operation.operationType) {
      case EUpdateOperationType.ADD:
        updatedEmails = this.addEmailsIfNotExist(itemEmails, operation.data.emailsSubmittedForUpdate);
        break;
      case EUpdateOperationType.REMOVE:
        updatedEmails = this.removeEmailsIfExist(itemEmails, operation.data.emailsSubmittedForUpdate);
        break;
      case EUpdateOperationType.REPLACE:
        updatedEmails = this.addEmailsIfNotExist(itemEmails, operation.data.emailsToReplaceWith);
        updatedEmails = updatedEmails
          ? this.removeEmailsIfExist(updatedEmails, operation.data.emailsSubmittedForUpdate)
          : undefined;
        break;
      default:
        updatedEmails = undefined;
    }
    if (!updatedEmails) {
      return this.handleUpdate(itemId, itemType, operation, RESULT_TYPE.NOTHING_TO_UPDATE, updatedEmails);
    } else {
      itemEmails.length = 0;
      itemEmails.push(...updatedEmails);
      return itemUpdater(item).pipe(
        mergeMap(_ => this.handleUpdate(itemId, itemType, operation, RESULT_TYPE.SUCCESS, updatedEmails)),
        catchError((error: IApiErrorResponse) => this.handleUpdate(itemId, itemType, operation, RESULT_TYPE.FAILED_TO_UPDATE, updatedEmails, error))
      );
    }
  }

  private handleUpdate(
    itemId: number,
    itemType: ENotificationCenterTargetItemType,
    operation: EmailsUpdateOperation,
    resultType: EUpdateOperationResultType,
    updatedEmails?: string[],
    error?: IApiErrorResponse
  ): Observable<IUpdateOperationResult> {
    const result: IUpdateOperationResult = {
      itemId: itemId,
      itemType: itemType,
      operation,
      updatedEmails: updatedEmails,
      resultType: resultType,
      errorStatusCode: error ? error.code : undefined,
      errorMessage: error ? error.message : undefined
    };
    return of(result);
  }

  private addEmailsIfNotExist(originalEmails: string[], emailsToAdd: string[]): string[] | undefined {
    if (!originalEmails || !emailsToAdd || emailsToAdd.length === 0) {
      return undefined;
    }
    const existingEmailsSet = new Set(originalEmails);
    const newEmailsToAdd = emailsToAdd.filter((email) => !existingEmailsSet.has(email));
    return newEmailsToAdd.length > 0
      ? [...originalEmails, ...newEmailsToAdd]
      : undefined;
  }

  private removeEmailsIfExist(originalEmails: string[], emailsToRemove: string[]): string[] | undefined {
    if (!originalEmails || originalEmails.length === 0 || !emailsToRemove || emailsToRemove.length === 0) {
      return undefined;
    }
    const emailsToRemoveSet = new Set(emailsToRemove);
    const updatedEmails = originalEmails.filter((email) => !emailsToRemoveSet.has(email));
    return originalEmails.length !== updatedEmails.length
      ? updatedEmails
      : undefined;
  }

  checkDuplicates(str: string): Promise<boolean> {
    return Promise.resolve(null);
  }

  checkCombination(obj: any): Promise<boolean> {
    return Promise.resolve(null);
  }
}
