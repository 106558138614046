<form class="inputs" [formGroup]="listEditorForm">
  <mat-form-field>
    <mat-label>{{placeholder}}</mat-label>
    <input matInput [matAutocomplete]="auto" type="text" (keydown.enter)="add()" 
            formControlName="input"
            op-selector="new-item-input" />
    <mat-error *ngIf="listEditorForm.controls.input.hasError('duplicate')">value already exists</mat-error>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button mat-flat-button (click)="add()" [disabled]="listEditorForm.invalid || !input"
          op-selector="add-item-btn">
    ADD
  </button>
</form>
<div #list class="list">
  <div class="list-item" *ngFor="let item of items" [attr.op-selector]="'item-' + item">
    <div class="remove" (click)="remove(item)">x</div>
    <span>{{item}}</span>
  </div>
</div>
