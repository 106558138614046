<svg viewBox="0 0 100 100" style="overflow: visible">
  <defs>
    <filter id="shadow" >
      <feDropShadow dx="-1" dy="1" stdDeviation="2" flood-opacity="0.5"/>
    </filter>
  </defs>
  <g transform="translate(50, 50)" overflow="visible">
    <circle cx="0" cy="0" [attr.r]="45 - strokeRadiusPercentage/4" [attr.fill]="bgArcColor"></circle>
    <path  overflow="visible" [attr.d]="progressArc" [attr.fill]="fgArcColor" style="filter:url(#shadow)"></path>
    <text *ngIf="showValue"
          class="value"
          [attr.x]="showPercentageSign ? 3 : 0"
          [attr.y]="message ? 0 : 3"
          [attr.fill]="valueColor || DEFAULT_TEXT_COLOR"
          text-anchor="middle"
          alignment-baseline="middle"
    >
      {{value}}
      <tspan class="percentage-sign"
             *ngIf="showPercentageSign && value >= 0"
             dx="-6"
             dy="-2"
      >
        %
      </tspan>
    </text>
  </g>
  <g *ngIf="message" transform="translate(50, 70)">
    <text class="message"
          [attr.fill]="messageColor || DEFAULT_TEXT_COLOR"
          text-anchor="middle"
          alignment-baseline="middle"
    >
      {{message}}
    </text>
  </g>
</svg>
