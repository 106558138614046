import { ILabel } from '@app/components/shared/services/label.service';
import { toJSOrGiven } from './selectorHelpers';
import * as Immutable from 'immutable';

export interface ILabelSelector {
  selectLabels(state): Array<ILabel>;
}

export class LabelSelector implements ILabelSelector {
  private getLabels(state): Immutable.List<Immutable.Map<string, any>> {
    return state.labels.get('all');
  }

  selectLabels(state): Array<ILabel> {
    return toJSOrGiven<Array<ILabel>>(this.getLabels(state), undefined);
  }

}

