<div class="ss-wrap">
  <div class="opadmin-banner">
    You have {{journeyFixes}} Journey Requests available this month.
  </div>
  <ng-container *ngIf="!submissionSuccess && !unauthorized">
    <h1>Journey Support Request Form</h1>
    <p>
      Use this form to submit a request to the Journey Support team to create a Journey for you in your account.
      You will be notified by email when it is complete. <a href='https://help.observepoint.com/en/articles/9102002-journey-support#h_9db7bfd67d' target="_blank" class="learn-more-link">How to use this form.</a>
    </p>
    <div class="form-wrap mat-elevation-z4" *ngIf="scriptServicesForm">
      <form [formGroup]="scriptServicesForm" (ngSubmit)="submitForm()">

        <div class="support-heading">
          <a href='https://docs.google.com/spreadsheets/d/1VDtXXUGh2V6XWRPVIlWo6ufgLK-xat6T/export' target="_blank" class="learn-more-link">
            Download the Excel file <mat-icon class="file-icons">download</mat-icon></a>and
          enter each individual action you want to take place in the Journey.
          Describe each action in detail so that the Journey Support team can
          correctly implement the Journey.
        </div>

        <!-- Number of Journeys to create -->
        <mat-form-field appearance="outline" floatLabel="always" class="number-journeys">
          <mat-label class="form-label">Journeys To Create*</mat-label>
          <mat-select formControlName="numberJourneys" [multiple]="false" placeholder="Select number of Journeys to create">
            <mat-option *ngFor="let count of journeyFixOptions" [value]="count">
              {{ count }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="scriptServicesForm.get('numberJourneys').errors?.required && scriptServicesForm.get('numberJourneys').touched">This field is required</mat-error>
        </mat-form-field>

        <!-- Special Instructions -->
        <mat-form-field appearance="outline" floatLabel="always" class="special-instructions">
          <mat-label class="form-label">Special instructions</mat-label>
          <textarea formControlName="custom" placeholder="Enter special instructions such as location, schedule, etc." matInput></textarea>
        </mat-form-field>

        <!-- File Drop Boxes -->
        <div class="file-drop-boxes">

          <!-- Excel File Drop Box -->
          <div class="upload-template-container excel-file-drop-box">
            <div class="file-drop-heading">
              Please download <a href='https://docs.google.com/spreadsheets/d/1VDtXXUGh2V6XWRPVIlWo6ufgLK-xat6T/export'
                target="_blank" class="learn-more-link">this excel file</a>, complete it then upload the modified .xlsx file (10MB limit)
            </div>
            <div *ngIf="!excelFileDropped" class="drop-zone">
              <op-file-drop #fileDrop acceptedFileTypes="xlsx" [multiple]="false" (fileDropped)="onFileDropExcelsheet($event)">
                <div class="file-drop-content">
                  <button mat-flat-button class="op-upload-button upload-button-margin" (click)="fileDrop.openFileSelector()" type="button">
                      <mat-icon class="file-icons">cloud_upload</mat-icon> Upload Completed Template</button>
                      <div class="top-margin">or</div>
                      <div class="top-margin">drag & drop</div>
                </div>
              </op-file-drop>
              <mat-spinner *ngIf="processingUpload_Excel" [diameter]="40" [strokeWidth]="5" class="upload-spinner"></mat-spinner>
            </div>
            <div *ngIf="excelFileDropped" class="file-is-dropped-boxes">
              <mat-chip>
                <span class="ellipsis">{{ excelFile?.name }}</span>
                <button matChipRemove
                  [attr.aria-label]="'remove file + {{ excelFile?.name }}'"
                  (click)="resetExcelFileDropbox()">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </div>
          </div>

          <!-- Any File Drop Box -->
          <div class="upload-template-container any-file-drop-box">
            <div class="file-drop-heading">Upload a file or video to help our team understand your needs (150MB limit)</div>
            <div *ngIf="!anyFileDropped" class="drop-zone">
              <op-file-drop #fileDrop [multiple]="false" (fileDropped)="onFileDropAnyFile($event)">
                <div class="file-drop-content">
                  <button mat-flat-button class="op-upload-button upload-button-margin" (click)="fileDrop.openFileSelector()" type="button">
                      <mat-icon class="file-icons">cloud_upload</mat-icon> Upload video or file</button>
                      <div class="top-margin">or</div>
                      <div class="top-margin">drag & drop</div>
                </div>
              </op-file-drop>
              <mat-spinner *ngIf="processingUpload_Any" [diameter]="40" [strokeWidth]="5" class="upload-spinner"></mat-spinner>
            </div>
            <div *ngIf="anyFileDropped" class="file-is-dropped-boxes">
              <mat-chip>
                <div class="ellipsis">{{ anyFile?.name }}</div>
                <button matChipRemove
                  [attr.aria-label]="'remove file + {{ anyFile?.name }}'"
                  (click)="resetAnyFileDropbox()">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </div>
          </div>
        </div>

        <div>
        </div>

        <p class="questons-regarding-form">For any questions regarding the status of a request, please contact us at <a class="learn-more-link" href="mailto:journey.support@observepoint.com">journey.support&#64;observepoint.com</a></p>

        <div class="button-wrap">
          <small *ngIf="showSpinner">(This may take up to 30 seconds)</small>
          <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="showSpinner"></mat-spinner>
          <p class="submission-error" *ngIf="submissionError">Submission error. Please try again.</p>
          <button mat-flat-button color="primary" type="submit" [attr.op-selector]="'script-services-submit'" [disabled]="showSpinner">Submit</button>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-container *ngIf="submissionSuccess">
    <h1>Success</h1>
    <p>Your submission has been received. The Journey Support team will reach out to you by email when your journey is ready.</p>
    <button class="submit-another"
            mat-flat-button
            color="primary"
            [attr.op-selector]="'script-services-submit-another'"
            (click)="resetForm()">
      Submit Another Request
    </button>
  </ng-container>
  <ng-container *ngIf="unauthorized">
    <h1>Journey Support</h1>
    <p>
      Journey Support from ObservePoint is designed to augment your company’s ability to manage user journeys
      by creating and maintaining them for you! All you have to do is submit a request for any journeys, rules, audits,
      or ObservePoint custom tags and our Journey Support team will take it from there.
    </p>
    <p>Please contact your ObservePoint representative for more information.</p>
  </ng-container>
</div>
