import { EStatusCodeCategories } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { IPageUrlFilter } from '@app/components/audit-reports/audit-report/audit-report.models';
import {
  IAuditReportExportMenuData
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import {
  EJSFileChangeType
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';

export interface IJSFileChangesSort<SortColumn> {
  sortBy: SortColumn;
  sortDesc: boolean;
}

export interface IJSFileChangesPagination {
  page: number;
  size: number;
}

export interface IJSFileChangesTableState {
  sort: IJSFileChangesSort<IJSFileChangesSortColumn>;
  pagination: IJSFileChangesPagination;
  pagesTotal: number;
}

export type IJSFileChangesSortColumn =
  'largest_size_diff'
  | 'page_count'
  | 'tag'
  | 'filename'
  | 'change_type'
  | 'domain'
  | 'party_type'
  | 'largest_date_diff';

interface IMetadata {
  pagination: {
    totalCount: number,
    totalPageCount: number,
    pageSize: number,
    currentPageSize: number,
    currentPageNumber: number
  };
}

export interface IJSFileChangesSummary {
  totalPageCount: number;
  filteredPageCount: number;
  totalChangedFileCount: number;
  filteredChangedFileCount: number;
  totalNewFileCount: number;
  filteredNewFileCount: number;
  totalFileDateDifferenceCount: number;
  filteredFileDateDifferenceCount: number;
  totalFileSizeDifferenceCount: number;
  filteredFileSizeDifferenceCount: number;
}

export interface IJSFileChangesItem {
  filename: string;
  requestDomain: string;
  partyType: PartyType;
  fileChangeType: ChangeType;
  tag: {
    tagId: number;
    tagName: string;
    tagCategoryId: number;
    tagCategoryName: string;
  };
  largestDateDifference?: number;
  largestSizeDifference?: number;
  pageCount: number;
}

export enum ChangeType {
  new_file= 'New File',
  changed_file = 'Changed',
  unknown = 'Unknown',
  not_changed = 'No Change',
  deleted_file = 'File Removed'
}

export enum PartyType {
  '3rd_party' = '3rd Party',
  '1st_party' = '1st Party',
}

export type IChangeType = 'new_file' | 'changed_file' | 'not_changed' | 'deleted_file';

export interface IJSFileChangesResponse {
  files: IJSFileChangesItem[];
  metadata: IMetadata;
}

export interface IPagesTableState {
  sort: IJSFileChangesSort<IPagesSortColumn>;
  pagination: IJSFileChangesPagination;
  pagesTotal: number;
}

export type IPagesSortColumn = 'page_url'
  | 'page_load_time'
  | 'page_status_code';

export interface IJSFileChangesPagesResponse {
  pages: IJSFileChangesPagesTableRow[];
  metadata: IMetadata;
}

export interface IJSFileChangesPagesTableRow {
  pageId: string,
  pageUrl: string,
  pageLoadTime: number,
  pageStatusCode: number,
  finalPageStatusCode: number,
}

export interface IJSFileChangesPagesTableState {
  sort: IJSFileChangesSort<CommonReportsPagesTableColumns>;
  pagination: IJSFileChangesPagination;
  pagesTotal: number;
}

export interface IAuditReportJSFileChangesApiPostBody {
  pageLoadTime?: {
    min: number;
    max: number;
  };
  pageUrl?: IPageUrlFilter;
  pageStatusCode?: EStatusCodeCategories;
  primaryTagsOnly?: boolean;
  tagId?: number;
  tagCategoryId?: number;
  dateDifference?: {
    min: number;
    max: number;
  };
  sizeDifference?: {
    min: number;
    max: number;
  };
  filename?: string;
  changeType?: EJSFileChangeType;
}

export interface IAuditReportJSFileChangesFilePagesApiPostBody {
  pageLoadTime?: {
    min: number;
    max: number;
  };
  pageUrl?: IPageUrlFilter;
  pageStatusCode?: EStatusCodeCategories;
  file: {
    filename: string;
    requestDomain: string;
    fileChangeType: string;
    partyType: PartyType;
    tagId: number;
  }
}

export type JsFileChangesExportMenuData = IAuditReportExportMenuData<IAuditReportJSFileChangesFilePagesApiPostBody | {}>;
