export class NavTitleFilter {
    static factory() {
      return (original: String, active: boolean): String => {0;

        if (!original) {
          return;
        }

        let items = original.split('|');

        if (items.length == 1 || items.length > 2) {
          return items[0];
        }

        return active ? items[1] : items[0];

      };
    }
}
