<mat-chip-listbox *ngIf="closed || advancedConfigs.readOnly" class="op-chip-selector">
  <mat-basic-chip
    [ngClass]="{'small-chip': advancedConfigs.collapsible}"
    *ngFor="let chip of selectedChips | slice: 0 : chipsInPreview;"
    (click)="switchView()">
    {{bindChipLabel ? chip[bindChipLabel] : chip}}
  </mat-basic-chip>
</mat-chip-listbox>

<div *ngIf="!closed && !advancedConfigs.readOnly"
  class="op-chip-selector op-chips-editable-wrp flex-stretch"
  [ngClass]="{'hasNoHint': !hint, 'hasNoLabel': !label}"
>
  <mat-form-field
    class="chips"
    [ngClass]="additionalFormFieldClasses"
    [appearance]="appearance"
    [floatLabel]="floatLabel">
    <mat-label>{{label}}</mat-label>
    <mat-chip-grid #chipList>
      <mat-basic-chip
        [ngClass]="{'removable': removable, 'small-chip': advancedConfigs.collapsible}"
        *ngFor="let chip of selectedChips"
        [removable]="removable"
        [color]="chip?.color"
        (removed)="remove(chip)">
        {{bindChipLabel ? chip[bindChipLabel] : chip}}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-basic-chip>

      <input matInput #chipInput
        name="query"
        class="chip-input"
        [ngClass]="{'small-input': advancedConfigs.collapsible}"
        [(ngModel)]="searchText"
        (input)="onSearchChange($event)"
        [placeholder]="getPlaceholder()"
        [formControl]="chipCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="onInputTokenEnd($event)"
        [attr.maxlength]="maxlength">

    </mat-chip-grid>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let chip of filteredChips" [value]="chip">
        {{chip[bindChipLabel]}}
      </mat-option>
      <mat-option
        *ngIf="searchText && onCreateChip.observers.length !== 0 && !exactMatch"
        class="no-matches"
        [value]="searchText"
      >
        <div class="search-text">{{ searchText }}</div>
        <div class="new-chip-label">{{ createNewText }}</div>
      </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="hint">{{hint}}</mat-hint>
  </mat-form-field>
</div>

<button *ngIf="selectedChips.length === 0 && closed && !advancedConfigs.readOnly"
  class="btn btn-link add-first-chip-btn"
  (click)="switchView()">
  <span>{{ getPlaceholder() }}</span>
</button>

<button *ngIf="advancedConfigs.collapsible && (selectedChips.length > 0 || !closed)"
  class="btn btn-link control"
  [ngClass]="{'opened': !closed}"
  (click)="$event.stopPropagation(); switchView()">{{
    closed
    ? ( (selectedChips.length - chipsInPreview) === 0 ? '' : ('+ ' + (selectedChips.length - chipsInPreview))    )
    : 'close'}}
    <i class="icon-mode-edit" *ngIf="closed && !advancedConfigs.readOnly"></i>
</button>
