<div class="tag-initiators-wrap">
  <button *ngIf="showFullScreenButton"
          mat-icon-button
          class="open-full-screen-btn"
          (click)="openInitiatorsFullScreen.emit()"
          matTooltip="View full screen"
          matTooltipPosition="above">
    <mat-icon>open_in_full</mat-icon>
  </button>
  <div class="svg-container" id="{{ svgContainerId }}"></div>
</div>