import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuditReportBase, IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { ActivatedRoute } from '@angular/router';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { BehaviorSubject, EMPTY, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { catchError, debounceTime, finalize, map, mergeMap, switchMap, takeUntil, tap, } from 'rxjs/operators';
import { TagHealthService } from '@app/components/audit-reports/reports/tag-health/tag-health.service';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  TagHealthPage,
  TagHealthPages,
  TagHealthPagesPagination,
  TagHealthPagesTablePagination,
  TagHealthPagesTableRow,
  TagHealthSummary,
  TagHealthTags,
  TagHealthTrend,
  TagHealthTrends,
  TagHealthTrendsByName,
  TagLoadTimeDistribution,
  TagStatusCodeDistribution
} from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { DecimalPipe, formatNumber } from '@angular/common';
import {
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import {
  AVG_LOAD_TIME_CHART_CONFIG,
  AVG_TAG_REQUEST_SIZE_CHART_CONFIG,
  ETagLoadTimePills,
  PagesTableColumns,
  TAG_SLOW_LOADING_CHART_CONFIG,
  TAG_TOTAL_BROKEN_CHART_CONFIG,
  TAG_TOTAL_REQUEST_CHART_CONFIG,
  TagHealthChartType,
  TagHealthRelevantFilters,
  TagHealthTrendNames,
  tagLoadTimeChartTitle,
  tagLoadTimeFilterOptions,
  tagStatusCodeChartTitle
} from './tag-health.constants';
import {
  IHorizontalBarChartDataPoint
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.models';
import {
  determineChartFilterState,
  getTagLoadTimeChartData,
  getTagStatusCodeChartData
} from '@app/components/audit-reports/reports/tag-health/tag-health.helpers';
import {
  EStatusCodeCategories,
  formatPaginator,
  PageLoadColumnTooltip,
  TAGS_SEARCH_TEXT_KEY
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { IPageListPage } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { TagTableFilterType } from '@app/components/audit-reports/reports/tag-health/tags-list/tag-list.constants';
import {
  LoadTimeReportFilter,
  TagAccountFilter,
  TagFilter,
  TagTableFilter
} from '@app/components/audit-reports/reports/tag-health/tags-list/tags-list.models';
import { cloneDeep, sortBy } from 'lodash-es';
import {
  FullscreenChartModalService
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.service';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import {
  IAuditReportExportMenuData
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import _isEmpty from 'lodash-es/isEmpty';
import { AlertMetricType, EAlertTagHealthMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { PageStatusCodeTooltipMap } from '../../audit-report-container.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  CommonPagesColumnConfigWarningMessage,
  CommonPagesConfigLocalStorageKey,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-health',
  templateUrl: './tag-health.component.html',
  styleUrls: ['./tag-health.component.scss'],
  providers: [ResizeableTableService]
})
export class TagHealthComponent extends AuditReportBase implements IFilterableAuditReport, OnDestroy, AfterViewInit, OnInit {
  protected readonly PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;
  readonly stateOptions = EFilterSpinnerState;
  readonly PagesTableColumns = PagesTableColumns;
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  // GENERAL
  auditId: number;
  runId: number;
  apiFilters: IAuditReportApiPostBody = {};
  totalTagRequests: number;
  PageLoadColumnTooltip = PageLoadColumnTooltip;

  pagesFilteredCount = 0;
  pagesTotalCount = 0;

  private filtersUpdated$ = new Subject<void>();
  private loadTimesUpdated$ = new Subject<void>();

  // widgets
  sparklineDataLoaded: boolean = false;
  widgetState: EFilterSpinnerState = EFilterSpinnerState.None;
  widgetSparklineRunInfos: ISparklineRunInfo[] = [];
  widgetTagSlowLoadingSparklineData: ISparklineChartData[] = [];
  widgetAvgTagLoadTimeSparklineData: ISparklineChartData[] = [];
  widgetBrokenTagSparklineData: ISparklineChartData[] = [];
  widgetTotalTagRequestSparklineData: ISparklineChartData[] = [];
  widgetAvgTagRequestSizeSparkLineData: ISparklineChartData[] = [];

  widgetPageScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    metricType: EAlertTagHealthMetric.PagesScanned,
  };

  widgetAvgTagLoadTime: ISplitCardChartData = {
    topLabel: 'Avg. Tag Load Time',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openAverageLoadTimeFullscreenChart.bind(this),
    metricType: EAlertTagHealthMetric.AvgTagLoadTime,
  };

  widgetAvgTagRequestSize: ISplitCardChartData = {
    topLabel: 'Avg. Tag Request Size',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openAverageTagRequestSizeFullscreenChart.bind(this),
    metricType: EAlertTagHealthMetric.AvgTagRequestSize,
  };

  widgetTagSlowLoading: ISplitCardChartData = {
    topLabel: '% of Slow Loading Tags',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openSlowLoadingTagsFullscreenChart.bind(this),
    metricType: EAlertTagHealthMetric.PercentageOfSlowLoadingTags,
  };

  widgetBrokenTag: ISplitCardChartData = {
    topLabel: '% of Broken Tag Requests',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openBrokenTagsFullscreenChart.bind(this),
    metricType: EAlertTagHealthMetric.PercentageOfOfBrokenTags,
  };

  widgetTotalTagRequest: ISplitCardChartData = {
    topLabel: 'Total Tag Requests',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openTotalTagRequestsFullscreenChart.bind(this),
    metricType: EAlertTagHealthMetric.TotalTagRequests,
  };

  // charts
  tagLoadTimeChartState: EFilterSpinnerState;
  tagLoadTimeChartData: IHorizontalBarChartDataPoint[];
  readonly tagLoadTimeChartTitle = tagLoadTimeChartTitle;
  tagStatusCodeChartState: EFilterSpinnerState;
  tagStatusCodeChartData: IHorizontalBarChartDataPoint[];
  readonly tagStatusCodeChartTitle = tagStatusCodeChartTitle;

  // pages table
  displayedColumns$ = this.tableService.displayedColumns$;
  dataSource: MatTableDataSource<TagHealthPagesTableRow> = new MatTableDataSource();
  pagesTableState: EFilterSpinnerState;

  pagesTablePagination: TagHealthPagesTablePagination = {
    page: 0, // API paginates starting with 0
    size: 200,
    sortBy: CommonReportsPagesTableColumns.TagInstanceCount,
    sortDesc: true
  };

  paginationState: { length: number, pageSize: number } = {
    length: 0,
    pageSize: 0
  };

  pageIdOpenInPageDetails: string;
  pagesTableFilter: LoadTimeReportFilter;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false, read: ElementRef }) table: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('scrollToTop', { static: false }) scrollToTop: ElementRef;

  // tags table
  tagTableData$: Observable<TagHealthTags>;
  tagTableState = EFilterSpinnerState.Loading;

  identity = (x: any) => x;

  exportReportConfig: IAuditReportExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: 'tag_health_pages',
    totalRows: this.pagesTotalCount,
    filteredRows: this.pagesFilteredCount,
    tableState: this.pagesTablePagination,
    filters: this.apiFilters,
    dataToCopy: {
      config: [
        {
          property: 'url',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl
        },
        {
          property: 'finalUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
        },
        {
          property: 'loadTime',
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
        },
        {
          title: 'AVG. TAG LOAD TIME (ms)',
          property: 'averageTagLoadTime',
          tableColumnName: CommonReportsPagesTableColumns.TagLoadTimeAverage
        },
        {
          title: 'AVG. TAG REQUEST SIZE (bytes)',
          property: 'averageTagRequestSize',
          tableColumnName: CommonReportsPagesTableColumns.TagRequestSizeAverage
        },
        {
          title: '# OF TAG REQUESTS',
          property: 'numberOfTagRequests',
          tableColumnName: CommonReportsPagesTableColumns.TagInstanceCount
        },
        {
          title: 'BROKEN TAGS',
          property: 'brokenTags',
          tableColumnName: CommonReportsPagesTableColumns.BrokenTagCount
        }
      ],
      data: null,
      displayedColumns$: this.tableService.displayedColumns$
    }
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;
  tags: { [id: string]: string };

  readonly TableColumn = CommonReportsPagesTableColumns;

  constructor(
    private route: ActivatedRoute,
    private auditReportService: AuditReportService,
    private tagHealthService: TagHealthService,
    private auditReportLoadingService: AuditReportLoadingService,
    private modalService: OpModalService,
    private filterBarService: AuditReportFilterBarService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    public modalEscapeService: ModalEscapeService,
    private scrollService: AuditReportScrollService,
    private decimalPipe: DecimalPipe,
    private alertReportingService: AlertReportingService,
    private uiTagService: UiTagService,
    private tableService: ResizeableTableService,
  ) {
    super();

    this.route.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;

      const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
      const alertId = queryParams?.alertId;
      const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
      this.preventHighlight = !highlight;
      // When loading report from an alert, first override filters with alert
      // filters before loading report data
      if (alertId) {
        this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
          this.filterBarService.overrideFilters(alert.config.filtersV0);
          this.highlightMetricType = highlight && alert.config.metricType;

          this.alertCheckComplete$.next(true);
        });
      } else {
        this.alertCheckComplete$.next(true);
      }

      this.alertCheckComplete$.pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(() => {
        this.onFiltersChanged(this.apiFilters);
      });
    });

    this.widgetState = EFilterSpinnerState.Loading;
    this.pagesTableState = EFilterSpinnerState.Loading;
    this.tagTableState = EFilterSpinnerState.Loading;
  }

  ngOnInit(): void {
    this.onFiltersChanged(this.apiFilters);

    this.initFilters();
    this.handleTagsList();
    this.handleCharts();
    this.handleSparkLines();
    this.createTagsMap();

    this.filterBarService.apiPostBody$
      .pipe(
        debounceTime(1000),
        takeUntil(this.onDestroy$)
      ).subscribe(this.onFiltersChanged.bind(this));

    // setup for page details
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.Tags);
    this.pageDetailsDrawerService.pageDrawerClosed$.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handlePageDetailsClosed());
  }

  ngOnDestroy(): void {
    this.auditReportLoadingService.forceOff();
    this.destroy();
    this.onDestroy$.next();

    this.pageDetailsDrawerService.closePageDetails();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    // reset back to the first page if the user changes the sort order
    this.sort.sortChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.paginator.firstPage();
        this.pagesTablePagination.page = 0;
      });

    this.handlePagesTable();
    this.formatPaginator();
  }

  initFilters(): void {
    this.filterBarService.updateSupportedFiltersList(TagHealthRelevantFilters);
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody): void {
    this.exportReportConfig.filters = this.apiFilters = apiPostBody;
    this.exportReportConfig.tableState.page = this.pagesTablePagination.page = 0;
    this.pagesTableFilter = undefined;
    this.paginator?.firstPage();
    this.filtersUpdated$.next();
    this.updateCurrentFilters();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  private handleCharts(): void {
    this.filtersUpdated$.pipe(
      tap(() => {
        this.auditReportLoadingService.addLoadingToken();
        this.tagLoadTimeChartState = EFilterSpinnerState.Loading;
        this.tagStatusCodeChartState = EFilterSpinnerState.Loading;
        this.widgetState = EFilterSpinnerState.Loading;
      }),
      switchMap(() =>
        this.tagHealthService.getTagHealthSummary(this.auditId, this.runId, this.apiFilters)
      ),
      catchError(() => EMPTY),
    ).subscribe((summary: TagHealthSummary | undefined) => {
      if (summary) {
        this.updateWidgets(summary);
        this.updateCharts(summary);
      } else {
        alert('Sorry! Some elements failed to update. Refresh your browser to try again.');
      }
      this.widgetState = this.filterBarService.currentRelevantFilters.length > 0
        ? EFilterSpinnerState.Filtered
        : EFilterSpinnerState.None;
      this.tagLoadTimeChartState = determineChartFilterState(
        TagHealthChartType.LoadTime,
        this.filterBarService.currentRelevantFilters
      );
      this.tagStatusCodeChartState = determineChartFilterState(
        TagHealthChartType.StatusCode,
        this.filterBarService.currentRelevantFilters
      );
      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  formatAvgTagRequestSizeTopValueNumber(targetValue: string): string {
    return `${ targetValue } bytes`;
  }

  private updateWidgets(tagHealth: TagHealthSummary): void {
    this.exportReportConfig.filteredRows = this.pagesFilteredCount = tagHealth.filteredPageCount;
    this.exportReportConfig.totalRows = this.pagesTotalCount = tagHealth.totalPageCount;
    this.totalTagRequests = tagHealth.filteredTagInstanceCount;

    this.widgetAvgTagRequestSize.topChangeContent = formatNumber( tagHealth.filteredTagRequestSizeAverage || 0, 'en-us');
    this.widgetAvgTagRequestSize.currentValue = tagHealth.filteredTagRequestSizeAverage || 0;

    this.widgetAvgTagLoadTime.topChangeContent = `${tagHealth.filteredTagLoadTimeAverage} ms`;
    this.widgetAvgTagLoadTime.currentValue = tagHealth.filteredTagLoadTimeAverage || 0;

    this.widgetTagSlowLoading.topChangeContent = `${tagHealth.filteredSlowTagPercentage}%`;
    this.widgetTagSlowLoading.currentValue = tagHealth.filteredSlowTagPercentage || 0;

    this.widgetBrokenTag.topChangeContent = `${tagHealth.filteredBrokenTagPercentage}%`;
    this.widgetBrokenTag.currentValue = tagHealth.filteredBrokenTagPercentage || 0;

    this.widgetTotalTagRequest.topChangeContent = formatNumber(tagHealth.filteredTagInstanceCount || 0, 'en-us');
    this.widgetTotalTagRequest.currentValue = tagHealth.filteredTagInstanceCount || 0;
  }

  private updateCharts(summary: TagHealthSummary): void {
    this.formatDataForLoadTimesChart(summary.tagLoadTimeDistribution);
    this.formatDataForStatusCodesChart(summary.tagStatusCodeDistribution);
  }

  private handleSparkLines(): void {
    this.filtersUpdated$.pipe(
      tap(() => {
        this.auditReportLoadingService.addLoadingToken();
        this.sparklineDataLoaded = false;
      }),
      mergeMap(() => this.tagHealthService.getTagHealthTrends(this.auditId, this.runId)),
      catchError(() => EMPTY),
      map((data: TagHealthTrends) => sortBy(data.runs, element => element.runId)),
    ).subscribe((resp: TagHealthTrend[]) => {
      const tagLoadTimes: ISparklineChartData[] = [];
      const tagSlowLoading: ISparklineChartData[] = [];
      const tagBroken: ISparklineChartData[] = [];
      const tagTotalRequest: ISparklineChartData[] = [];
      const runInfos: ISparklineRunInfo[] = [];
      const tagRequestSize: ISparklineChartData[] = [];

      resp.forEach((data, index: number) => {
        tagLoadTimes.push({ value: data.totalTagLoadTimeAverage, sequence: index });
        tagSlowLoading.push({ value: data.slowTagPercentage, sequence: index });
        tagBroken.push({ value: data.brokenTagPercentage, sequence: index });
        tagTotalRequest.push({ value: data.totalTagInstanceCount, sequence: index });
        runInfos.push({ runId: data.runId, runCompletionDate: data.completedAt });
        tagRequestSize.push({ value: data.totalTagRequestSizeAverage, sequence: index });
      });

      this.widgetAvgTagLoadTimeSparklineData = tagLoadTimes;
      this.widgetTagSlowLoadingSparklineData = tagSlowLoading;
      this.widgetBrokenTagSparklineData = tagBroken;
      this.widgetTotalTagRequestSparklineData = tagTotalRequest;
      this.widgetSparklineRunInfos = runInfos;
      this.widgetAvgTagRequestSizeSparkLineData = tagRequestSize;
      this.sparklineDataLoaded = true;

      this.auditReportLoadingService.removeLoadingToken();
    });
  }

  openAverageLoadTimeFullscreenChart(): void {
    if (this.widgetAvgTagLoadTimeSparklineData.length > 1) {
      this.openFullscreenChart(
        TagHealthTrendNames.AVG_TAG_LOAD_TIME,
        this.widgetSparklineRunInfos[this.widgetSparklineRunInfos.length - 2].runCompletionDate,
        this.identity,
        FullscreenChartModalService.getTagLoadTimeSummaryLines
      );
    }
  }

  openAverageTagRequestSizeFullscreenChart(): void {
    if (this.widgetAvgTagRequestSizeSparkLineData.length > 1) {
      this.openFullscreenChart(
        TagHealthTrendNames.AVG_TAG_REQUEST_SIZE,
        this.widgetSparklineRunInfos[this.widgetSparklineRunInfos.length - 2].runCompletionDate,
        this.identity
      );
    }
  }

  openSlowLoadingTagsFullscreenChart(): void {
    if (this.widgetTagSlowLoadingSparklineData.length > 1) {
      this.openFullscreenChart(
        TagHealthTrendNames.SLOW_TAG_PERCENTAGE,
        this.widgetSparklineRunInfos[this.widgetSparklineRunInfos.length - 2].runCompletionDate,
        this.identity
      );
    }
  }

  openBrokenTagsFullscreenChart(): void {
    if (this.widgetTagSlowLoadingSparklineData.length > 1) {
      this.openFullscreenChart(
        TagHealthTrendNames.BROKEN_TAG_PERCENTAGE,
        this.widgetSparklineRunInfos[this.widgetSparklineRunInfos.length - 2].runCompletionDate,
        this.identity
      );
    }
  }

  openTotalTagRequestsFullscreenChart(): void {
    if (this.widgetTagSlowLoadingSparklineData.length > 1) {
      this.openFullscreenChart(
        TagHealthTrendNames.TAG_TOTAL_REQUEST,
        this.widgetSparklineRunInfos[this.widgetSparklineRunInfos.length - 2].runCompletionDate,
        this.identity
      );
    }
  }

  openFullscreenChart(
    trendName: TagHealthTrendNames,
    secondToLastCompletionDate: string,
    trendValueConverter: (x: number) => number,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[],
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days, trendValueConverter),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName)
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  private getFullscreenChartConfig(trendName: TagHealthTrendNames) {
    switch (trendName) {
      case TagHealthTrendNames.AVG_TAG_LOAD_TIME:
        return AVG_LOAD_TIME_CHART_CONFIG;
      case TagHealthTrendNames.AVG_TAG_REQUEST_SIZE:
        return AVG_TAG_REQUEST_SIZE_CHART_CONFIG;
      case TagHealthTrendNames.SLOW_TAG_PERCENTAGE:
        return TAG_SLOW_LOADING_CHART_CONFIG;
      case TagHealthTrendNames.BROKEN_TAG_PERCENTAGE:
        return TAG_TOTAL_BROKEN_CHART_CONFIG;
      case TagHealthTrendNames.TAG_TOTAL_REQUEST:
        return TAG_TOTAL_REQUEST_CHART_CONFIG;
      default:
        return null;
    }
  }

  getFullscreenChartData(trendName: string, days: number, converter: (x: number) => number): Observable<IFullscreenChartDataWithStats> {
    return this.tagHealthService
      .getTagHealthTrendsByName(this.auditId, trendName, days)
      .pipe(
        map((data: TagHealthTrendsByName) => {
          return {
            chartData: data.runs.map(item => {
              return {
                value: converter(item.trendValue),
                date: item.completedAt
              };
            })
          };
        })
      );
  }

  formatDataForLoadTimesChart(data: TagLoadTimeDistribution): void {
    this.tagLoadTimeChartData = getTagLoadTimeChartData(data);

    if (this.apiFilters.hasOwnProperty(EAuditReportFilterTypes.TagLoadTime)) {
      const minLoadTimes = [ 0, 500, 1000, 2000 ];
      const index = minLoadTimes.indexOf(this.apiFilters.tagLoadTime?.min);
      this.tagLoadTimeChartData[index].filtered = true;
      this.tagLoadTimeChartData = [ ...this.tagLoadTimeChartData ];
    }
  }

  formatDataForStatusCodesChart(data: TagStatusCodeDistribution): void {
    this.tagStatusCodeChartData = getTagStatusCodeChartData(data);

    if (this.apiFilters.hasOwnProperty(EAuditReportFilterTypes.TagStatusCode)) {
      const statusCodeOptions = [
        EStatusCodeCategories.Successes,
        EStatusCodeCategories.Redirects,
        EStatusCodeCategories.Broken
      ];

      const index = statusCodeOptions.indexOf(this.apiFilters.tagStatusCode);
      this.tagStatusCodeChartData[index].filtered = true;
      this.tagStatusCodeChartData = [ ...this.tagStatusCodeChartData ];
    }
  }

  toggleLoadTimeFilter({ item }: { item: IHorizontalBarChartDataPoint }): void {
    if (item.filtered) {
      // remove status code filter if present
      this.tagStatusCodeChartData.forEach(dataPoint => dataPoint.filtered = false);

      const min = tagLoadTimeFilterOptions[item.name].min;
      const max = tagLoadTimeFilterOptions[item.name].max;

      this.filterBarService.addTagLoadTimeFilter(min, max);
    } else {
      this.removeFilter(EAuditReportFilterTypes.TagLoadTime);
    }
  }

  toggleStatusCodeFilter({ item }: { item: IHorizontalBarChartDataPoint }): void {
    if (item.filtered) {
      // remove load time filter if present
      this.tagLoadTimeChartData.forEach(dataPoint => dataPoint.filtered = false);

      switch (EStatusCodeCategories[item.name]) {
        case EStatusCodeCategories.Successes:
          this.filterBarService.addTagStatusCodeFilter(EStatusCodeCategories.Successes);
          break;
        case EStatusCodeCategories.Redirects:
          this.filterBarService.addTagStatusCodeFilter(EStatusCodeCategories.Redirects);
          break;
        case EStatusCodeCategories.Broken:
          this.filterBarService.addTagStatusCodeFilter(EStatusCodeCategories.Broken);
          break;
      }
    } else {
      this.removeFilter(EAuditReportFilterTypes.TagStatusCode);
    }
  }

  private removeFilter(type: EAuditReportFilterTypes): void {
    const filter = this.filterBarService.currentRelevantFilters.find(
      filter => filter.type === type
    );

    if (filter) {
      this.filterBarService.removeFilter(filter);
    }
  }

  private handleTagsList() {
    this.tagTableData$ = this.filtersUpdated$.pipe(
      tap(() => {
        this.tagTableState = EFilterSpinnerState.Loading;
        this.auditReportLoadingService.addLoadingToken();
      }),
      mergeMap(() =>
        this.tagHealthService
          .getTagHealthTags(this.auditId, this.runId, this.apiFilters)
          .pipe(
            finalize(() => {
              this.tagTableState = this.filterBarService.currentRelevantFilters.length ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
              this.auditReportLoadingService.removeLoadingToken();
            }),
          )
      )
    );
  }

  handlePagesTable(): void {
    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.filtersUpdated$,
      this.loadTimesUpdated$
    ).pipe(
      debounceTime(300),
      tap(() => {
        // update loading states each time filters are updated
        this.auditReportLoadingService.addLoadingToken();
        this.pagesTableState = EFilterSpinnerState.Loading;
      }),
      switchMap((filter: any) => {
        if (filter !== undefined) {
          if (typeof filter.pageIndex === 'number') {
            this.pagesTablePagination.page = filter.pageIndex;
          }
          if (filter.active) {
            this.exportReportConfig.tableState.sortBy = this.pagesTablePagination.sortBy = filter.active;
            this.exportReportConfig.tableState.sortDesc = this.pagesTablePagination.sortDesc = filter.direction === 'desc';
          }
        }

        return this.tagHealthService.getTagHealthPages(this.auditId, this.runId, this.pagesTablePagination, this.mergeFilters());
      }),
      catchError(() => {
        this.pagesTableState = EFilterSpinnerState.None;
        return of({ pages: [] });
      }),
      tap(() => {
        this.auditReportLoadingService.removeLoadingToken();
      })
    )
      .subscribe(({ pages, metadata: { pagination } }: TagHealthPages) => {
        this.initTable(pages);
        this.setPaginationData(pagination);

        if (!!this.pagesTableFilter) {
          this.onScrollTop();
        }

        this.exportReportConfig.filters = this.mergeFilters();

        if (_isEmpty(this.exportReportConfig.filters)) {
          this.exportReportConfig.totalRows = pagination.totalCount;
          this.exportReportConfig.filteredRows = Infinity;
        } else {
          this.exportReportConfig.filteredRows = pagination.totalCount;
        }

        this.pagesTableState = this.filterBarService.currentRelevantFilters.length || this.pagesTableFilter
          ? EFilterSpinnerState.Filtered
          : EFilterSpinnerState.None;
      });
  }

  initTable(auditPages: TagHealthPage[]): void {
    this.exportReportConfig.dataToCopy.data = this.dataSource.data = auditPages.map((page: TagHealthPage) => {
      const loadTime = parseFloat((page.pageLoadTime / 1000).toFixed(1));

      return {
        id: page.pageId,
        url: page.pageUrl,
        finalUrl: page.finalPageUrl,
        loadTime: loadTime,
        loadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(loadTime),
        statusCode: page.pageStatusCode,
        finalPageStatusCode: page.finalPageStatusCode,
        statusCodeClass: this.auditReportService.getStatusCodeClass(page.finalPageStatusCode),
        averageTagLoadTime: page.filteredTagLoadTimeAverage,
        averageTagLoadTimeClass: this.auditReportService.getLoadTimeClassForMs(page.filteredTagLoadTimeAverage),
        averageTagRequestSize: page.filteredTagRequestSizeAverage,
        numberOfTagRequests: page.filteredTagInstanceCount,
        brokenTags: page.filteredBrokenTagCount,
        brokenTagsClass: page.filteredBrokenTagCount > 0 ? 'has-broken-tags' : 'no-broken-tags'
      } as TagHealthPagesTableRow;
    });
  }

  openPageDetails(page: IPageListPage) {
    this.pageIdOpenInPageDetails = page.id;

    const state = {
      [TAGS_SEARCH_TEXT_KEY]: this.tags[this.apiFilters?.tagId] || ''
    };

    this.pageDetailsDrawerService.openPageDetails(page, this.auditId, this.runId, EPageDetailsTabs.Tags, null, state);
  }

  handlePageDetailsClosed() {
    this.pageIdOpenInPageDetails = null;
  }

  addTagTableFilter(filter: TagTableFilter): void {
    switch (filter.type) {
      case TagTableFilterType.Tag:
        const { tagName, tagId } = filter.value as TagFilter;
        this.filterBarService.addTagIdFilter(tagName, tagId);
        break;
      case TagTableFilterType.TagAccount:
        const { accountName } = filter.value as TagAccountFilter;
        this.filterBarService.addTagAccountFilter(accountName);
        break;
      case TagTableFilterType.TagStatus:
        this.filterBarService.addTagStatusCodeFilter(filter.value as EStatusCodeCategories);
        break;
    }
  }

  addLoadTimeReportFilter(filter: LoadTimeReportFilter): void {
    this.pagesTableFilter = filter;
    this.exportReportConfig.tableState.page = this.pagesTablePagination.page = 0;
    this.paginator.firstPage();
    this.loadTimesUpdated$.next();
  }

  private mergeFilters(): IAuditReportApiPostBody {
    if (!this.pagesTableFilter) {
      return this.apiFilters;
    }

    const mergedFilters = cloneDeep(this.apiFilters);
    switch (this.pagesTableFilter.type) {
      case TagTableFilterType.Tag:
        mergedFilters.tagId = (this.pagesTableFilter.value as TagFilter).tagId;
        mergedFilters.tagAccount = undefined;
        break;
      case TagTableFilterType.TagAccount:
        const filter = this.pagesTableFilter.value as TagAccountFilter;
        mergedFilters.tagId = filter.tagId;
        mergedFilters.tagAccount = filter.accountName;
        break;
    }
    mergedFilters.tagLoadTime = {
      min: tagLoadTimeFilterOptions[ETagLoadTimePills[this.pagesTableFilter.bucket]].min,
      max: tagLoadTimeFilterOptions[ETagLoadTimePills[this.pagesTableFilter.bucket]].max
    };

    return mergedFilters;
  }

  private setPaginationData(pagination: TagHealthPagesPagination) {
    this.paginationState.length = pagination.totalCount;
    this.exportReportConfig.tableState.size = this.paginationState.pageSize = pagination.pageSize;
  }

  onScrollTop() {
    this.scrollService.scrollByElement(this.scrollToTop.nativeElement);
  }

  private formatPaginator(): void {
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }

  private createTagsMap(): void {
    this.uiTagService.getTags().subscribe((tags: IUiTag[]) => {
      this.tags = tags.reduce((acc, tag) => {
        acc[tag.id] = tag.name;
        return acc;
      }, {});
    });
  }
}
