import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable } from 'rxjs';
import { IWebJourneyRunInfo, IWebJourneyRunProgress, IWebJourneyRunResponse, IWebJourneyV3, IWebJourneyV3Status } from './web-journey-v3.models';
import { ServerErrorCodes } from '@app/moonbeamConstants';
import {
  SnackbarErrorComponent
} from '@app/components/shared/components/snackbars/snackbar-error/snackbar-error.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class WebJourneyV3Service {

  apiRoot: string = `${environment.apiV3Url}web-journeys`;

  constructor(
    private apiService: ApiService,
    private snackbarService: MatSnackBar,
  ) {}

  getJourneys(): Observable<IWebJourneyV3[]> {
    return this.apiService.get(this.apiRoot);
  }

  getJourney(journeyId: number): Observable<IWebJourneyV3> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}`);
  }

  getJourneyRunInfo(journeyId: number, runId: number): Observable<IWebJourneyRunInfo> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}/runs/${runId}/info`);
  }

  createJourney(journey: IWebJourneyV3): Observable<IWebJourneyV3> {
    return this.apiService.post(this.apiRoot, journey);
  }

  updateJourney(journey: IWebJourneyV3): Observable<IWebJourneyV3> {
    return this.apiService.put(`${this.apiRoot}/${journey.id}`, journey);
  }

  getJourneyStatus(journeyId: number): Observable<IWebJourneyV3Status> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}/status`);
  }

  getJourneyRunStatus(journeyId: number, runId: number): Observable<IWebJourneyRunProgress> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}/runs/${runId}/status`);
  }

  runWebJourneyNow(journeyId: number): Observable<IWebJourneyRunResponse> {
    return this.apiService.post(`${this.apiRoot}/${journeyId}/runs`, null);
  }

  handleWebJourneyRunError(error, journeyName?: string): void {
    if (error?.code === 403 && error?.errorCode?.errorCode === ServerErrorCodes.overageLimitExceeded) {
      this.showErrorSnackbar(`The account run limit has been reached. ${journeyName ? 'Web journey ' + journeyName + ' cannot be run.' : 'Web journey cannot be run.'}`);
    } else if (error?.statusCode === 409) { // journey is already running
      this.showErrorSnackbar('Web journey is already running');
    } else if (error?.statusCode === 503) { // journey run request timed out
      this.showErrorSnackbar('The request to run web journey timed out. Please try again.');
    } else if (error?.statusCode === 400) { // journey has no actions
      this.showErrorSnackbar('Web journey has no actions and cannot be run');
    } else {
      this.showErrorSnackbar('An unexpected error occurred');
    }
  }

  private showErrorSnackbar(message: string) {
    this.snackbarService.openFromComponent(SnackbarErrorComponent, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {message}
    });
  }
}
