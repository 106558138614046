import { Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { environment } from '@app/environments/environment';
import {
  IAbstractExportReportService
} from '@app/components/shared/components/export-report/export-reports.abstract.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class WebJourneyExportService implements IAbstractExportReportService {
  private apiRoot = `${environment.apiV3Url}web-journeys`;

  constructor(protected apiService: ApiService) {
  }

  send(auditId: number, runId: number, type: string, tableState: any, body: any = {}, allData = true) {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/exports/${type}`, body);
  }
}
