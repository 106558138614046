import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { CardSplitterType, ManageCardsSplitterStatusStoragePrefix } from '../../report-card-list.constants';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { Names } from '@app/moonbeamConstants';

export type SplitterStatusMap = { [id: string]: boolean };

@Injectable()
export class SplitterStatusDataService {

  private readonly storagePrefix = ManageCardsSplitterStatusStoragePrefix;
  private currentStorageType: StorageType = StorageType.Local;

  private _splittersStatusMap: BehaviorSubject<SplitterStatusMap> = new BehaviorSubject({});
  readonly splittersStatusMap: Observable<SplitterStatusMap> = this._splittersStatusMap.asObservable();

  constructor(private storageService: StorageService) {
    this.setStorageType();
    this._splittersStatusMap.next(this.storageService.getValue(Names.GlobalStateKeys.manageCardsSplitterStatus, this.getStorageType()) || {});
  }

  private buildSplitterStatusKey(id: number, splitterType: CardSplitterType): string{
    return `${this.storagePrefix}_${splitterType}_${id}`;
  }

  private areAllSplittersHasStatus(status: boolean): boolean {
    let map = this._splittersStatusMap.getValue();
    for (var splitterKey in map) {
      if (splitterKey.startsWith(this.storagePrefix) && map[splitterKey] != status) return false;
    }
    return true;
  }

  setSplitterStatus(id: number, type: CardSplitterType, status: boolean): void {
    let map = this._splittersStatusMap.getValue();
    map[this.buildSplitterStatusKey(id, type)] = status;
    this.storageService.setValue<SplitterStatusMap>(Names.GlobalStateKeys.manageCardsSplitterStatus, map, this.getStorageType());
    this._splittersStatusMap.next({...map});
  }
  setCardStatusMap(cardSplitterStatus) {
    this.storageService.setValue(Names.GlobalStateKeys.manageCardsSplitterStatus, cardSplitterStatus, this.getStorageType());
  }
  setAllSplitterStatus(splitterMap: SplitterStatusMap) {
    this._splittersStatusMap.next(splitterMap);
  }

  getSplitterStatus(id: number, type: CardSplitterType): boolean {
    let map = this._splittersStatusMap.getValue();
    return map[ this.buildSplitterStatusKey(id, type) ];
  }
  getCardStatusMap(): any {
    return this.storageService.getValue(Names.GlobalStateKeys.manageCardsSplitterStatus, this.getStorageType());
  }

  areAllSplittersExpanded(): boolean {
    return this.areAllSplittersHasStatus(true);
  }

  areAllSplittersCollapsed(): boolean {
    return this.areAllSplittersHasStatus(false);
  }

  impersonateUser() {

    this.setStorageType();

    // Start impersonating
    this.setCardStatusMap({});
    this.setAllSplitterStatus({});
  }
  stopImpersonating() {
    this.setStorageType();

    const curSplitterStatusMap = this.storageService.getValue<SplitterStatusMap>(Names.GlobalStateKeys.manageCardsSplitterStatus) || {};
    this.setAllSplitterStatus(curSplitterStatusMap);

    this.clear();
  }

  clear() {
    this.storageService.removeValue(Names.GlobalStateKeys.manageCardsSplitterStatus, StorageType.Session);
  }

  getStorageType(): StorageType {
    return this.currentStorageType;
  }
  private setStorageType(): void {
    this.currentStorageType = (this.storageService.isLoggedInAsAnother()) ? StorageType.Session : StorageType.Local;
  }
}
