<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="closeModal()" op-selector="domain-setup-container">
  <ng-template #headerTemplate>
    <op-modal-header title="{{ mode === manageDomainsMode.Create ? 'Create' : 'Edit' }} Sub-folder{{ multi ? 's' : '' }}"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="edit-domain-name">
      <ng-container *ngIf="editing">
        <div class="multi-message" *ngIf="multi">
          <div class="warning-message">
            <p>
              <strong>WARNING:</strong> You are editing multiple sub-folders at once.
              Any changes made here will be applied to all sub-folders that were selected.
            </p>
          </div>
          <p><em>Fields left blank will not be updated.</em></p>
        </div>
        <form [formGroup]="manageDomainsForm">
          <mat-form-field class="styled-mat-input" appearance="outline" floatLabel="always">
            <mat-label>Folder</mat-label>
            <mat-select formControlName="folder"
                        placeholder="Select folder name"
                        op-selector="domain-setup-folder-selector">
              <mat-option *ngFor="let folder of folders" [value]="folder" [attr.op-selector]="'domain-setup-folder-item-' + folder.id">
                {{ folder.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="folder.errors?.required">This field is required</mat-error>
          </mat-form-field>
          <mat-form-field class="styled-mat-input" appearance="outline">
            <input matInput placeholder="Name" formControlName="name" op-selector="domain-setup-domain-name">
            <mat-error *ngIf="name.errors?.required">This field is required</mat-error>
          </mat-form-field>
          <mat-form-field class="styled-mat-input" appearance="outline">
            <input matInput placeholder="Data Layer" formControlName="dataLayer" op-selector="domain-setup-domain-datalayer">
            <mat-hint>Enter a data layer object, for example: digitalData, dataLayer[1], etc. This field accepts a comma separated list of JavaScript objects or functions to be identified as data layers.</mat-hint>
          </mat-form-field>
        </form>
      </ng-container>
      <ng-container *ngIf="saving">
        <div class="center-elements">
          <mat-spinner [diameter]="25" [strokeWidth]="4"></mat-spinner>
          <span>Saving...</span>
        </div>
      </ng-container>
      <ng-container *ngIf="error">
        <div class="center-elements">
          <p>There was a problem updating the sub-folder{{ multi ? 's' : '' }}.<br>Please refresh the page and try again.</p>
        </div>
      </ng-container>
    </div>
  </ng-template>
</op-modal>
