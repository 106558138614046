import { Injectable, OnDestroy } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Observable, of, Subject } from 'rxjs';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import {
  IAuditScoreDatum,
  IAuditSummaryHeaderData,
  IAuditSummaryPrimaryTags, IAuditSummaryScoresTrendsDatum,
  IAuditSummaryTags,
  IAuditSummaryTrendsDatum
} from './audit-summary.models';
import { takeUntil } from 'rxjs/operators';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class AuditSummaryService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getPrimaryTags(auditId: number, runId: number, body: IAuditReportApiPostBody): Observable<IAuditSummaryPrimaryTags> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/audit-summary/primary-tags`;
    return this.apiService.post(`${baseRequestUrl}`, body);
  }

  getHeaderData(auditId: number, runId: number, body: IAuditReportApiPostBody): Observable<IAuditSummaryHeaderData> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/audit-summary`;
    return this.apiService.post(`${baseRequestUrl}`, body);
  }

  getSummaryTrendsData(auditId: number, runId: number): Observable<IAuditSummaryTrendsDatum> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/audit-summary/trends`;
    return this.apiService.get(baseRequestUrl);
  }

  getAuditScoreData(auditId: number, runId: number): Observable<IAuditScoreDatum> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/audit-summary/scores`;
    return this.apiService.get(baseRequestUrl);
  }

  getAuditScoreTrendData(auditId: number, runId: number): Observable<IAuditSummaryScoresTrendsDatum> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/audit-summary/scores/trends`;
    return this.apiService.get(baseRequestUrl);
  }

  getTrend(auditId: number, trendName: string, days: number = 1) {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/reports/audit-summary/trends/${trendName}?days=${days}`;
    return this.apiService.get(baseRequestUrl);
  }

  getScoreTrend(auditId: number, trendName: string, days: number = 1) {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/reports/audit-summary/scores/trends/${trendName}?days=${days}`;
    return this.apiService.get(baseRequestUrl);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getTags(auditId: number, runId: number, body: IAuditReportApiPostBody): Observable<IAuditSummaryTags> {
    const baseRequestUrl = `${this.apiRoot}/${auditId}/runs/${runId}/reports/audit-summary/tags`;
    return this.apiService.post(`${baseRequestUrl}`, body);
  }
}
