import { AngularNames, Names } from '@app/moonbeamConstants';
import { IFormattedRun } from '../../reporting/summaryReports/auditSummary/auditSummaryData';
import { ISerializer } from '../../../../api/apiService';
import { IRunsDisplayLimiter } from '@app/moonbeamModels';
import { DateService, IDateService } from '../../../../date/date.service';
import * as angular from 'angular';
import { Observable } from 'rxjs';
import { ERunPickerStatus } from '@app/components/run-picker-ng/run-picker-ng.enums';
import { IRunPickerRun } from '@app/components/run-picker-ng/run-picker-ng.models';

export interface IAuditRunSummary {
    webAuditId: number;
    score: number;
    totalUrl: number;
    id: number;
    silentMode: boolean;
    completed: string;
    taggedUrl: number;
    userId: number;
    started: string;
    limit: number;
    needReprocess?: boolean;
    adobeAuditor: boolean;
    supportedFeatures: Array<string>;
    isRuleFailurePresent?: boolean;
  }

  export interface IRunInfoSerializer {
    serialize(jsonArray: Array<IAuditRunSummary>): Array<IFormattedRun>;
    serializeForRunPicker(jsonArray: Array<IAuditRunSummary>): angular.IPromise<Array<IRunPickerRun>>;
  }

  export class RunInfoSerializer implements ISerializer<Array<IFormattedRun>> {

    runsLimiter: angular.IPromise<IRunsDisplayLimiter>;

    static $inject = [
      AngularNames.q,
      Names.Services.date
    ];

    constructor(
      private $q: angular.IQService,
      private dateService: DateService
    ) {}

    serialize(jsonArray: Array<IAuditRunSummary>): Array<IFormattedRun> {
      return jsonArray
        .filter((json: IAuditRunSummary) => json.completed != null)
        .map((json: IAuditRunSummary) => {
          return {
            id: json.id,
            date: this.dateService.dateFromString(json.completed),
            className: '',
            name: '',
            possibleScore: 100,
            score: json.score,
            value: json.score / 100
          };
        });
    }

    serializeForRunPicker(jsonArray: Array<IAuditRunSummary>): IRunPickerRun[] {
      return jsonArray.map(json => {
        return {
          id: json.id,
          date: this.dateService.dateFromString(json.completed),
          selected: false,
          status: this.getRunPickerRunStatus(json),
          completed: !!json.completed,
        };
      });
    }

    private getRunPickerRunStatus(run: IAuditRunSummary): ERunPickerStatus {
      return run.isRuleFailurePresent ? ERunPickerStatus.RuleFailure
        : run.completed ? ERunPickerStatus.Success
          : ERunPickerStatus.ActionFailed;
    }

    private convertObservableToAngJSPromise<T>(observable: Observable<T>): angular.IPromise<T> {
      let defer = this.$q.defer();
      observable.subscribe(
        val => defer.resolve(val),
        err => defer.reject(err)
      );
      return defer.promise as angular.IPromise<T>;
    }
}
