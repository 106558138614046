import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IRunPickerRun } from '../run-picker-ng.models';
import { statusToTooltipMessageMap } from './calendar-events.constants';
import { ERunPickerStatus } from '../run-picker-ng.enums';
import { ICalendarEvent } from '../calendar/calendar.models';
import { formatDefs } from '@app/components/date/date.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'calendar-events',
  templateUrl: './calendar-events.component.html',
  styleUrls: ['./calendar-events.component.scss']
})
export class CalendarEventsComponent implements OnInit {
  hourMinuteFormat: string = formatDefs.timeOne;
  monthDayFormat: string = formatDefs.dateFive;

  @Input() currentDate: Date;
  @Input() events: ICalendarEvent<IRunPickerRun>[];
  @Output() onClickEvent: EventEmitter<ICalendarEvent<IRunPickerRun>> = new EventEmitter();

  readonly runPickerStatus = ERunPickerStatus;
  readonly statusToMessageMap = statusToTooltipMessageMap;

  selectedEvent: ICalendarEvent<IRunPickerRun>;

  ngOnInit() {
    if (!this.events) return;
    const selectedEvent = this.events.find(event => event.isSelected);
    if (selectedEvent) this.selectedEvent = selectedEvent;
  }

  handleClickEvent(event: ICalendarEvent<IRunPickerRun>): void {
    if (this.selectedEvent) {
      this.selectedEvent.isSelected = false;
    }

    this.selectedEvent = event;
    this.selectedEvent.isSelected = true;

    if (this.onClickEvent) {
      this.onClickEvent.emit(event);
    }
  }

}
