export enum EAlertBellStatus {
  NoConfiguredAlerts, // plus (create) bell
  NoSubscribedAlerts, // empty bell
  CreateAlert, // plus (create) bell
  ConfiguredAndSubscribedAlerts, // checked bell
  ConfiguredAndTriggeredAlerts, // empty exclamation ringing bell
  TriggeredAlerts, // exclamation ringing bell
}

export enum EAlertBellType {
  Icon,
  Menu
}
