<div opFileDrop class="drop-zone" (dropped)="handleFileDrop($event)">
  <input #fileInput
         type="file"
         class="visually-hidden"
         [accept]="acceptedFileTypes"
         [multiple]="multiple"
         (change)="addFiles(fileInput.files!)"
  />
  <ng-content></ng-content>
</div>
