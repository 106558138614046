import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OpFileDropDirective } from './op-file-drop.directive';

@Component({
  selector: 'op-file-drop',
  standalone: true,
  imports: [OpFileDropDirective],
  templateUrl: './op-file-drop.component.html',
  styleUrls: ['./op-file-drop.component.scss']
})
export class OpFileDropComponent {

  @Input() multiple = true;
  @Input() acceptedFileTypes: string = ''; // comma separated list of file types
  @Output() fileDropped = new EventEmitter<FileList>();
  @ViewChild('fileInput') private inputRef!: ElementRef<HTMLInputElement>;


  addFiles(files: FileList): void {
    if (files.length) {
      this.fileDropped.emit(files);
    }
  }

  handleFileDrop(event: DragEvent): void {
    const droppedFiles = event?.dataTransfer?.files;
    if (droppedFiles?.length) {
      this.inputRef.nativeElement.files = droppedFiles;
      this.addFiles(droppedFiles);
    }
  }

  openFileSelector(): void {
    this.inputRef.nativeElement.click();
  }
}
