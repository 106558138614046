import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface ISnackbarInfoData {
  message?: string;
  messages?: string[];
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'snackbar-info',
  templateUrl: './snackbar-info.component.html',
  styleUrls: ['./snackbar-info.component.scss']
})
export class SnackbarInfoComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarInfoData,
              public snackBarRef: MatSnackBarRef<SnackbarInfoComponent>) {}

  close() {
    this.snackBarRef.dismiss();
  }
}
