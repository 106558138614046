import { Component, Input, ViewEncapsulation } from '@angular/core';

export const labeledMenuComponentNameNg = 'labeledMenuNg';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'labeled-menu',
  templateUrl: './labeled-menu.component.html',
  styleUrls: ['./labeled-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LabeledMenuComponent {
  @Input() icon?: string;
  @Input() buttonLabel?: string;
  @Input() menuLabel: string;
  @Input() menuItems: ILabeledMenuItem[];
}

export interface ILabeledMenuItem {
  label: string;
  disabled: boolean;
  onClick: () => void;
}
