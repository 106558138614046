<div class="action-item-container" [ngClass]="{
          'status-passed': statusIcon === 'check_circle',
          'status-action-failure': statusIcon === 'cancel',
          'status-rule-failure': statusIcon === 'error',
          'selected': currentStep === index + 1
        }" (click)="onStepClick(index + 1)" op-selector="action-selector-item">
  <div class="number-status">
    <span class="step-status">
      <mat-icon>{{ statusIcon }}</mat-icon>
    </span>
    <span class="step-number">{{ index + 1}}.</span>
    <span class="step-type">
      {{ defaultActions[action?.action?.action] }}
    </span>
  </div>
  <div class="action-item-image" [ngClass]="{'vertical-action': appActions}">
    <img src="{{ appActions ? action.screenshot : action.thumbnail }}" onError="this.src='/images/no-img-b.png'">
    <mat-icon class="action-item-expand" *ngIf="currentStep === index + 1" (click)="onClickToEnlarge()">open_in_full</mat-icon>
  </div>
</div>
