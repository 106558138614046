import { Route } from '@angular/router';
import { ScriptServicesComponent } from './script-services.component';
import { ScriptServicesPaths, ScriptServicesStateNames } from './script-services.constants';

export const ScriptServicesFutureState: Route = {
  path: ScriptServicesPaths.base,
  loadChildren: () => import('./script-services.module').then(mod => mod.ScriptServicesModule),
};

export const ScriptServicesRoutes: Route = {
  path: '',
  component: ScriptServicesComponent,
  data: {
    title: 'Journey Support',
    stateName: ScriptServicesStateNames.base
  }
};
