import {AngularNames} from '@app/moonbeamConstants';
import * as angular from 'angular';

export interface IHttpCallOnceService {
    get<T>(url: string, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T>;
    delete<T>(url: string, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T>;
    post<T>(url: string, data: any, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T>;
    put<T>(url: string, data: any, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T>;
  }

  export class HttpCallOnceService implements IHttpCallOnceService {
    private promiseCache = new Map<string, angular.IDeferred<any>>();

    static $inject = [
      AngularNames.http
    ];

    constructor(private $http: angular.IHttpService) {
    }

    get<T>(url: string, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T> {
      if (this.promiseCache[url]) return this.promiseCache[url];

      var promise = (this.promiseCache[url] = this.$http.get<T>(url, config));

      promise.then(() => {
        delete this.promiseCache[url];
      }, () => {
        delete this.promiseCache[url];
      });

      return promise;
    }

    delete<T>(url: string, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T> {
      return this.$http.delete<T>(url, config);
    }

    post<T>(url: string, data: any, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T> {
      return this.$http.post<T>(url, data, config);
    }

    put<T>(url: string, data: any, config?: angular.IRequestShortcutConfig): angular.IHttpPromise<T> {
      return this.$http.put<T>(url, data, config);
    }

  }


