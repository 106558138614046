import { IPageListPage } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { BehaviorSubject, Subject } from 'rxjs';
import { EPageDetailsTabs } from '../page-details/page-details.constants';

export abstract class IAuditReportPageDetailsDrawerService {

  protected openPageChanged$ = new BehaviorSubject(null);
  protected pageClosed$ = new Subject();
  protected drawerOpen: boolean;

  get pageDrawerOpenedOrUpdated$() { return this.openPageChanged$.asObservable(); }
  get pageDrawerClosed$() { return this.pageClosed$.asObservable(); }
  get isDrawerOpen() { return this.drawerOpen; }

  // helper method for setting the default tab on the page details report
  // Typically this is called once on report init
  abstract setDefaultPageDetailsTab(defaultTab: EPageDetailsTabs);

  // helper method to open page details flyout
  abstract openPageDetails(page: IPageListPage, auditId: number, runId: number, tab?: EPageDetailsTabs, fullScreen?: boolean, state?: any, updateActiveTab?: boolean);

  // helper method to close page details flyout
  abstract closePageDetails();

}
