import { ISerializer } from '../../../../../api/apiService';
import { IAuditSummaryStats } from './auditSummaryData';

export interface IAuditSummaryStatsJson {
  averageLoadTime: number;
  consoleLogs: number;
  failedBusinessRules: number;
  okStatus: number;
  pagesTagged: number;
  failedVendorRules: number;
  pagesAudited: number;
  runId: number;
  duplicateTags: number;
}

export interface IAuditSummariesJson {
  summaries: Array<IAuditSummaryStatsJson>;
}

export interface IAuditSummaryStatsSerializer extends ISerializer<Array<IAuditSummaryStats>> {
}

export class AuditSummaryStatsSerializer implements IAuditSummaryStatsSerializer {
  serialize(json: IAuditSummariesJson): Array<IAuditSummaryStats> {
    if (json.summaries && json.summaries.length > 0) {
      return json.summaries.map((x: IAuditSummaryStatsJson) => this.transformSummary(x));
    } else {
      return [<IAuditSummaryStats>{
        runId: null,
        averageLoadTime: null,
        consoleLogs: null,
        failedBusinessRules: null,
        okStatusPercent: null,
        pagesTaggedPercent: null,
        failedVendorRules: null,
        pagesAudited: null,
        duplicateTags: null
      }];
    }
  }

  private transformSummary(json: IAuditSummaryStatsJson): IAuditSummaryStats {
    return <IAuditSummaryStats>{
      runId: json.runId,
      averageLoadTime: json.averageLoadTime / 1000,
      consoleLogs: json.consoleLogs,
      failedBusinessRules: json.failedBusinessRules,
      okStatusPercent: json.okStatus * 100,
      pagesTaggedPercent: json.pagesTagged * 100,
      failedVendorRules: json.failedVendorRules,
      pagesAudited: json.pagesAudited,
      duplicateTags: json.duplicateTags
    };
  }
}
