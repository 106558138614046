import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TZDate } from '@date-fns/tz';
import { tzOffset } from '@date-fns/tz/tzOffset';
import { UtilitiesService } from '@app/components/utilities/utilitiesService';
import { timeZoneAbbreviations } from '@app/components/date/date.constants';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AccountsService } from '@app/components/account/account.service';
import { IUser } from '@app/moonbeamModels';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { ITimeZone, ITimeZoneGroup } from '@app/components/shared/components/op-recurrence/op-recurrence.models';
import { toZonedTime, format } from 'date-fns-tz';

@Component({
  selector: 'op-run-time',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTooltipModule,
  ],
  providers: [
    UtilitiesService,
    DateService,
  ],
  templateUrl: './op-run-time.component.html',
  styleUrls: ['./op-run-time.component.scss']
})
export class OpRunTimeComponent implements OnInit {
  destroy$ = new Subject<void>();
  groupedTimeZones: ITimeZoneGroup[] = [];
  filteredTimeZones = [];
  timeZoneFilterControl: FormControl<string> = new FormControl('');
  selectedTimeZone: ITimeZone;
  originalTimeZone: ITimeZone;
  userTimeZone: string;
  selectedTimeZoneTooltip: string;
  supportedTimeZones: string[];

  @Input() runTimeForm: FormGroup;
  @Input() runDateForm: FormGroup;
  @ViewChild('timeZoneFilter', { read: MatInput }) timeZoneFilter: MatInput;

  constructor(
    private utilitiesService: UtilitiesService,
    private accountsService: AccountsService,
    private dateService: DateService,
  ) {}

  ngOnInit(): void {
    this.groupedTimeZones = this.buildTimeZoneGroups();
    this.filteredTimeZones = this.sortGroups(this.groupedTimeZones);
    this.initListeners();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initListeners(): void {
    this.runTimeForm.get('time').valueChanges.subscribe(() => {
      this.selectedTimeZoneTooltip = this.getSelectedTimeZoneTooltip();
    });

    this.runDateForm.get('date').valueChanges.subscribe(() => {
      // Rebuild the time zone menu with the new date to update time zone offset's for any daylight savings changes
      this.groupedTimeZones = this.buildTimeZoneGroups();
      this.filteredTimeZones = this.sortGroups(this.groupedTimeZones);
      this.selectedTimeZone = this.getMatchingTimeZone(this.timeZone.value);
      this.selectedTimeZoneTooltip = this.getSelectedTimeZoneTooltip();
    });

    this.timeZoneFilterControl.valueChanges
      .pipe(
        debounceTime(250),
        takeUntil(this.destroy$)
      )
      .subscribe((tzFilter: string) => {
        this.filterTimeZones(tzFilter);
        // When rebuilding the menu on filter, scroll position will get messed up. Focusing the filter input will reset the scroll position to the top again.
        this.focusTimeZoneFilter();
      });
  }

  loadData(): void {
    this.accountsService.getUser().subscribe((user: IUser) => {
      this.userTimeZone = user.timezone;
    });

    this.originalTimeZone = this.selectedTimeZone = this.getMatchingTimeZone(this.timeZone.value);
    this.selectedTimeZoneTooltip = this.getSelectedTimeZoneTooltip();
  }

  focusTimeZoneFilter(): void {
    this.timeZoneFilter.focus();
  }

  getMatchingTimeZone(tzName: string): ITimeZone | undefined {
    for (const key in this.groupedTimeZones) {
      if (this.groupedTimeZones.hasOwnProperty(key)) {
        const group = this.groupedTimeZones[key];
        const matchingZone = group.zonesInOffset.find(tz => tz.name === tzName);
        if (matchingZone) {
          return matchingZone;
        }
      }
    }

    return undefined;
  }

  // Create our time zone menu from the full Intl supported time zone list
  // (400+ including very obscure) using the OP time zone list and include the
  // current user's time zone if it is something outside that list.
  buildTimeZoneGroups() {
    const date = this.dateService.convertToZonedTime(this.runDate.value, this.timeZone.value);
    const opTz = this.utilitiesService.getTimezones();
    this.supportedTimeZones = (Intl as any).supportedValuesOf('timeZone').filter(tz => opTz.includes(tz) || tz === this.timeZone.value);
     return (this.buildMenuWithDateFnsJs(this.supportedTimeZones, date) as any);
  }

  // Filter, regroup, and resort based on search string
  filterTimeZones(filter: string): void {
    const filteredGroupedTimeZones = {};

    for (const zoneOffsetMin in this.groupedTimeZones) {
      if (this.groupedTimeZones.hasOwnProperty(zoneOffsetMin)) {
        const group = this.groupedTimeZones[zoneOffsetMin];
        const filteredZones = group.zonesInOffset.filter(timezone =>
          timezone.name.toLowerCase().includes(filter.toLowerCase()) ||
          timezone.longName.toLowerCase().includes(filter.toLowerCase())
        );

        if (filteredZones.length > 0) {
          filteredGroupedTimeZones[zoneOffsetMin] = {
            offset: group.offset,
            zonesInOffset: filteredZones,
          };
        }
      }
    }

    this.filteredTimeZones = this.sortGroups(filteredGroupedTimeZones);
  }

  dateToMs(date: number|Date) {
    if (typeof date === 'number') {
      return date;
    } else if (date instanceof Date) {
      return date.getTime();
    }
  }

  sortGroups(groupTzs) {
    const sortedTzs = [];
    const matchingGroup = [];

    Object.keys(groupTzs).sort((a, b) => {
      return +b - +a;
    }).forEach(offsetMin => {
      groupTzs[offsetMin].zonesInOffset.sort((a, b) => a.name.localeCompare(b.name));
      if (groupTzs[offsetMin].zonesInOffset.some(tz => tz.name === this.timeZone.value)) {
        matchingGroup.push(groupTzs[offsetMin]);
      } else {
        sortedTzs.push(groupTzs[offsetMin]);
      }
    });

    return [...matchingGroup, ...sortedTzs];
  }

  buildMenuWithDateFnsJs(tzs: string[], date: Date) {
    const dfnsDate = new TZDate(this.dateToMs(date));
    const groupTzs = {};

    tzs.forEach(tz => {
      const zoneSpecificFnsDate = dfnsDate.withTimeZone(tz);
      const zoneOffsetMin = tzOffset(tz, zoneSpecificFnsDate);
      const zoneOffset =
        (zoneOffsetMin < 0 ? '-' : '+') +
        Math.trunc(Math.abs(zoneOffsetMin / 60)).toString().padStart(2, '0') + ':' +
        Math.abs(zoneOffsetMin % 60).toString().padStart(2, '0');
      const tzFull = zoneSpecificFnsDate.toLocaleDateString('en-US', { timeZoneName: 'long' }).split(', ')[1];
      const tzAbbr = timeZoneAbbreviations[tzFull] || zoneOffset;
      const zoneInfo = { name: tz, abbr: tzAbbr, longName: tzFull };

      if (groupTzs[zoneOffsetMin]) {
        groupTzs[zoneOffsetMin].zonesInOffset.push(zoneInfo);
      } else {
        groupTzs[zoneOffsetMin] = {
          offset: zoneOffset,
          zonesInOffset: [zoneInfo]
        };
      }
    });

    return groupTzs;
  }

  setTimeZone(timeZone: ITimeZone): void {
    this.selectedTimeZone = timeZone;
    this.timeZone.setValue(timeZone.name);
    this.selectedTimeZoneTooltip = this.getSelectedTimeZoneTooltip();
    this.filteredTimeZones = this.sortGroups(this.groupedTimeZones);
  }

  getSelectedTimeZoneTooltip(): string {
    const [hours, minutes] = this.time.value.split(':');
    const period = hours >= 12 ? 'pm' : 'am';
    const formattedHour = hours % 12 || 12; // Convert to 12-hour format
    let tooltip = `${formattedHour}:${minutes} ${period} in ${this.selectedTimeZone.name} (${this.selectedTimeZone.abbr})`;

    // When the time zone has been changed from the original,
    // get the second tooltip line -- the selected time
    // if converted to the time zone of the current user.
    if (this.originalTimeZone.name !== this.selectedTimeZone.name) {
      const timeDiffString = this.calculateTimeInUserTimeZone(hours, minutes);
      tooltip += `\n${timeDiffString}`;
    }

    return tooltip;
  }

  calculateTimeInUserTimeZone(hour: number, minute: number): string | null {
    if (!this.selectedTimeZone || !this.originalTimeZone || this.selectedTimeZone.name === this.originalTimeZone.name) {
      return null;
    }

    const userTime = this.dateService.convertTimeZone(this.runDate.value, this.selectedTimeZone.name, this.userTimeZone, EDateFormats.timeEight);

    return `${userTime} in your ${this.userTimeZone} time zone`;
  }

  get time(): AbstractControl {
    return this.runTimeForm.get('time');
  }

  get timeZone(): AbstractControl {
    return this.runTimeForm.get('timeZone');
  }

  get runDate(): AbstractControl {
    return this.runDateForm.get('date');
  }
}
