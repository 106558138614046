import { environment } from "@app/environments/environment";

export const ShareLinksStateNames = {
  base: 'share_links',
};

export const ShareLinksPaths = {
  base: 'share',
  sharedLinks: 'shared-links',
  visitorLogin: 'visitor-login',
};

const apiRoot = `${environment.authApiProtocol}://${environment.authApiHost}`;
export const ShareLinksApiUrl = {
  sharedLinkUrl: apiRoot + '/' + ShareLinksPaths.base,
  sendSharedLinkUrl: apiRoot + '/' + ShareLinksPaths.sharedLinks,
  deleteSharedLinkUrl: apiRoot + '/' + ShareLinksPaths.sharedLinks,
  visitorLogin: apiRoot + '/' + ShareLinksPaths.visitorLogin,
}