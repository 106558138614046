<component-template componentName="Filter Spinner">
  <component-library-section sectionName="Overview" selector="<filter-spinner>">
    <p>This is the filter spinner component. It is primarily used in the widgets on audit reports.</p>

    <div class="filter-spinner-example">
      <p>Example of a filtered state:</p>
      <filter-spinner [state]="EFilterSpinnerState.Filtered"></filter-spinner>
    </div>

    <div class="filter-spinner-example">
      <p>Example of a loading state:</p>
      <filter-spinner [state]="EFilterSpinnerState.Loading"></filter-spinner>
    </div>
  </component-library-section>
</component-template>