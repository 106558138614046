<div class="rule-results-container">
  <table mat-table
         matSort
         #ruleResultsTable
         [dataSource]="tableDataSource"
         multiTemplateDataRows
         class="rule-results-table"
         [matSortActive]="TABLE_COLUMN.FailedCount"
         matSortDirection="desc"
         matSortStart="desc"
  >

    <ng-container [matColumnDef]="TABLE_COLUMN.Expanded">
      <th mat-header-cell *matHeaderCellDef class="expanded-th" [ngClass]="{ 'expanded': allExpanded }">
      </th>
      <td mat-cell *matCellDef="let row" class="expanded-td">
        <mat-icon class="expand-arrow" [ngClass]="{'dimmed' : (ruleSummaryTrendSelected === 'failed' && row.failedRulePageCount === 0)
        || (ruleSummaryTrendSelected === 'passed' && row.passedRulePageCount === 0)
        || (ruleSummaryTrendSelected === 'notApplied' && row.notAppliedRulePageCount === 0) }">keyboard_arrow_right</mat-icon>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMN.RuleName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="rule-name-th">
        <span>RULE NAME</span>
      </th>
      <td mat-cell *matCellDef="let row" class="rule-name-td" >
        <div class="rule-name-tags-wrp">
          <div class="rule-name"
          [ngClass]="{'dimmed' : (ruleSummaryTrendSelected === 'failed' && row.failedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'passed' && row.passedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'notApplied' && row.notAppliedRulePageCount === 0) }">
            {{ row.ruleName }}</div>
          <div class="rule-tag-icon"
               *ngFor="let tag of row.tags">
            <img
              [matTooltip]="tag.name"
              [src]="tag.iconUrl"
              matTooltipShowDelay="500"
              matTooltipPosition="above"
              class="rule-name-icon"
              [ngClass]="{'dimmed' : (ruleSummaryTrendSelected === 'failed' && row.failedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'passed' && row.passedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'notApplied' && row.notAppliedRulePageCount === 0) }"
            />
          </div>
          <button mat-icon-button
                  class="rule-edit-button"
                  (click)="editRule(row); $event.stopPropagation()"
                  [class.disabled]="!row.ruleConfigExists || loadingRuleEdit"
                  matTooltipPosition="above"
                  matTooltip="This rule has been deleted."
                  [matTooltipDisabled]="row.ruleConfigExists"
                  *ngIf="!userIsReadOnly">
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMN.HasEmailNotification">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="email-notifications-th">
        <span>EMAIL NOTIFICATIONS</span>
      </th>
      <td mat-cell *matCellDef="let row" class="email-notifications-td">
        <div class="email-notifications-icon-wrp"
             [ngClass]="{'dimmed' : (ruleSummaryTrendSelected === 'failed' && row.failedRulePageCount === 0)
                        || (ruleSummaryTrendSelected === 'passed' && row.passedRulePageCount === 0)
                        || (ruleSummaryTrendSelected === 'notApplied' && row.notAppliedRulePageCount === 0) }">
          <div mdePopoverTriggerOn="hover"
               [mdePopoverTriggerFor]="emailRecipientsPopover"
               (click)="$event.stopPropagation()"
               class="email-notifications-icon-btn"
          >
            <mat-icon *ngIf="!row.hasEmailNotification; else emailNotificationsElseBlock"
                      class="email-notifications-warn">warning</mat-icon>
            <ng-template #emailNotificationsElseBlock>
              <mat-icon class="email-notifications-ok">check_circle</mat-icon>
            </ng-template>
          </div>
          <mde-popover #emailRecipientsPopover="mdePopover"
                       class="rule-summary-rule-email-recipients-popover"
                       [mdePopoverOverlapTrigger]="false"
                       [mdePopoverCloseOnClick]="false">
            <div class="rule-summary-rule-email-recipients-popover-content" (click)="row.ruleConfigExists && onEditEmail.emit(row.identifier.ruleSnapshotId)">
              <ng-container *ngIf="!row.ruleConfigExists else ruleExists">
                <span *ngIf="!row.hasEmailNotification">This rule has been deleted.</span>
              </ng-container>
              <ng-template #ruleExists>
                <span *ngFor="let email of row.emailRecipients">{{ email }} (edit)</span>
                <span *ngIf="!row.hasEmailNotification">No email notifications configured (Add an email)</span>
              </ng-template>
            </div>
          </mde-popover>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMN.FailedCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="failed-count-th">
        <span>PAGES WITH FAILED RULES</span>
      </th>
      <td mat-cell *matCellDef="let row" class="failed-count-td">
        <div class="failed-count-wrp">
          <div class="count-metric failed-count-metric" (click)="ruleMetricClicked(row, RULE_RESULT_TYPE.Failed, $event)">
            <div class="count-metric-container"
            [ngClass]="{'dimmed' : (ruleSummaryTrendSelected === 'failed' && row.failedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'passed' && row.passedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'notApplied' && row.notAppliedRulePageCount === 0) }">
                <span
                  class="{{ row.failedRulePageCount > 0 ? 'negative' : 'neutral' }}"
                  [class.filtered]="row.clickedRuleResultType === RULE_RESULT_TYPE.Failed"
                  [class.non-empty]="row.failedRulePageCount !== null && row.failedRulePageCount !== undefined"

                >
                  {{ row.failedRulePageCount === null ? '---' : (row.failedRulePageCount | number ) }}
                </span>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMN.PassedCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="passed-count-th">
        <span>PAGES WITH PASSED RULES</span>
      </th>
      <td mat-cell *matCellDef="let row" class="passed-count-td">
        <div class="passed-count-wrp">
          <div class="count-metric passed-count-metric" (click)="ruleMetricClicked(row, RULE_RESULT_TYPE.Passed, $event)">
            <div class="count-metric-container"
            [ngClass]="{'dimmed' : (ruleSummaryTrendSelected === 'failed' && row.failedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'passed' && row.passedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'notApplied' && row.notAppliedRulePageCount === 0) }">
                <span
                  class="{{ row.passedRulePageCount > 0 ? 'positive' : 'neutral' }}"
                  [class.filtered]="row.clickedRuleResultType === RULE_RESULT_TYPE.Passed"
                  [class.non-empty]="row.passedRulePageCount !== null && row.passedRulePageCount !== undefined"
                >
                  {{ row.passedRulePageCount === null ? '---' : (row.passedRulePageCount | number ) }}
                </span>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TABLE_COLUMN.NotAppliedCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="notapplied-count-th last-column-count-th">
        <span>PAGES WITH RULES NOT APPLIED</span>
      </th>
      <td mat-cell *matCellDef="let row" class="notapplied-count-td">
        <div class="count-metric notapplied-count-metric" (click)="ruleMetricClicked(row, RULE_RESULT_TYPE.NotApplied, $event)">
          <div class="count-metric-container"
          [ngClass]="{'dimmed' : (ruleSummaryTrendSelected === 'failed' && row.failedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'passed' && row.passedRulePageCount === 0)
          || (ruleSummaryTrendSelected === 'notApplied' && row.notAppliedRulePageCount === 0) }">
                <span
                  class="neutral"
                  [class.filtered]="row.clickedRuleResultType === RULE_RESULT_TYPE.NotApplied"
                  [class.non-empty]="row.notAppliedRulePageCount !== null && row.notAppliedRulePageCount !== undefined"
                >
                  {{ row.notAppliedRulePageCount === null ? '---' : (row.notAppliedRulePageCount | number ) }}
                </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="tableDisplayedColumns.length" class="expanded-detail-td">
        <div class="expanded-detail-wrap" [class.reprocessing-message-wrap]="row.reprocessing">
          <div class="expanded-detail-spinner-wrap" *ngIf="row.expanded && row.loadingRuleDetails">
            <mat-spinner [diameter]="25" [strokeWidth]="3"></mat-spinner>
          </div>
          <div *ngIf="row.expanded && !row.loadingRuleDetails && row.reprocessing" class="expanded-detail-data-wrap">
            <div class="reprocessing-message">
              Apologies, the information is currently unavailable during the reprocessing phase. Kindly retry once the reprocessing is finished.
            </div>
          </div>
          <div class="expanded-detail-data-wrap" *ngIf="row.expanded && !row.loadingRuleDetails && row.ruleDetails && !row.reprocessing">
            <div class="rule-detail-row"
                 *ngFor="let rd of row.ruleDetailRows"
                 [class.data-row]="!rd.isHelper && !rd.isSection"
                 [class.helper-row]="rd.isHelper"
                 [class.section]="rd.isSection"
                 [class.filtered]="rd.clickedRuleResultType"
            >
              <div class="expanded-detail-rule-structure-wrap">
                <div class="expanded-detail-rule-structure" [style.margin-left]="(rd.level * 20) + 'px'">
                  <mat-icon *ngIf="rd.withArrow">
                    subdirectory_arrow_right
                  </mat-icon>
                  <span *ngFor="let segment of rd.valueSegments" class="value-segment" [class]="segment.cssClass">{{ segment.value }}</span>
                </div>
              </div>
              <div class="count-metric failed-count-metric"
                   (click)="ruleMetricClicked(rd, RULE_RESULT_TYPE.Failed, $event)">
                <div class="count-metric-container">
                  <span
                    class="{{ rd.failedCount > 0 ? 'negative' : 'neutral' }}"
                    [class.filtered]="rd.clickedRuleResultType === RULE_RESULT_TYPE.Failed"
                    [class.non-empty]="!rd.isHelper && rd.failedCount !== null && rd.failedCount !== undefined"
                  >
                  {{ rd.failedCount === null ? '---' : (rd.failedCount | number ) }}
                </span>
                </div>
              </div>
              <div class="count-metric passed-count-metric"
                   (click)="ruleMetricClicked(rd, RULE_RESULT_TYPE.Passed, $event)">
                <div class="count-metric-container">
                  <span
                    class="{{ rd.passedCount > 0 ? 'positive' : 'neutral' }}"
                    [class.filtered]="rd.clickedRuleResultType === RULE_RESULT_TYPE.Passed"
                    [class.non-empty]="!rd.isHelper && rd.passedCount !== null && rd.passedCount !== undefined"
                  >
                  {{ rd.passedCount === null || rd.passedCount === 0 ? '---' : (rd.passedCount | number ) }}
                </span>
                </div>
              </div>
              <div class="count-metric last-count-metric notapplied-count-metric"
                   (click)="rd.notAppliedCount !== null && ruleMetricClicked(rd, RULE_RESULT_TYPE.NotApplied, $event)">
                <div class="count-metric-container">
                  <span
                    [class.filtered]="rd.clickedRuleResultType === RULE_RESULT_TYPE.NotApplied"
                    [class.non-empty]="!rd.isHelper && rd.notAppliedCount !== null && rd.notAppliedCount !== undefined"
                  >
                  {{ rd.notAppliedCount === null ? '---' : (rd.notAppliedCount | number ) }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"
        class="item-row"
        [class.expanded]="row.expanded"
        [class.filtered]="row.clickedRuleResultType"
        (click)="rowClicked(row, $event)">
    </tr>
    <tr mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        [@detailExpand]="row.expanded ? 'expanded' : 'collapsed'"
        class="expanded-detail-row"
    ></tr>
    <tr *matNoDataRow>
      <td class="no-rules-message-td" [colSpan]="tableDisplayedColumns.length">
        <div class="no-rules-message-container">
          <mat-spinner [diameter]="25" [strokeWidth]="3" *ngIf="spinnerState === 'loading'; else noRulesFoundText"></mat-spinner>
          <ng-template #noRulesFoundText>
            <span class="no-rules-applied">no rules applied this run</span>
          </ng-template>
        </div>
      </td>
    </tr>

  </table>
</div>
