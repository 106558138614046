import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EasyBlockTagsComponent } from './easy-block-tags.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { OpClearableInputModule } from '@app/components/shared/components/op-clearable-input/op-clearable-input.module';
import { MatMenuModule } from '@angular/material/menu';
import { OpenMenuOnHoverModule } from '@app/components/shared/components/open-menu-on-hover/open-menu-on-hover.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MdePopoverModule } from '@app/components/popover';

@NgModule({
  declarations: [
    EasyBlockTagsComponent
  ],
    imports: [
        CommonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MdePopoverModule,
        MatCardModule,
        OpClearableInputModule,
        MatMenuModule,
        OpenMenuOnHoverModule,
        MatCheckboxModule,
        ReactiveFormsModule
    ],
  exports: [
    EasyBlockTagsComponent
  ],
  providers: [],
})
export class EasyBlockTagsModule {}
