<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="modalTitle"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="move-to-domain-modal" [class.create-mode]="this.selectCreateDomain.mode === InputMode.Create">
      <div class="instructions">
        Select the folder/sub-folder you want to move the selected items to.
      </div>
      <form [formGroup]="moveDomainForm">
        <div class="select-create-wrapper">
          <op-select-create #selectCreateFolder
                            [label]="selectCreateFolder.mode === InputMode.Select ? 'Select folder' : 'New folder name'"
                            formControlName="folder"
                            formControlPath="moveDomainForm.folder"
                            prefix=""
                            createText="create a new folder"
                            appendTo=".select-folder"
                            cancelText="select from a list"
                            appearance="outline"
                            [data]="folders"
                            (onSwitchMode)="onSwitchFolderMode($event)"
                            (onSelect)="onFolderChange($event)">
          </op-select-create>
          <div class="select-folder dropdown-sticker"></div>
        </div>

        <div class="select-create-wrapper" [class.disabled]="domain.disabled">
          <op-select-create #selectCreateDomain
                            [label]="selectCreateDomain.mode === InputMode.Select ? 'Select sub-folder' : 'New sub-folder name'"
                            formControlName="domain"
                            formControlPath="moveDomainForm.domain"
                            prefix=""
                            appearance="outline"
                            appendTo=".select-sub-folder"
                            createText="create a new sub-folder"
                            cancelText="select from a list"
                            [data]="availableDomains"
                            [hideSwitch]="isCreateMode(selectCreateFolder.mode) || (folder.valid && !this.availableDomains.length)">
          </op-select-create>
          <div class="select-sub-folder dropdown-sticker"></div>
        </div>

        <div class="datalayer-field-wrapper" *ngIf="this.selectCreateDomain.mode === InputMode.Create">
          <mat-form-field id="dataLayerFormField" appearance="outline">
            <input matInput formControlName="dataLayer" />
            <mat-label>Data Layer</mat-label>
            <mat-hint>Enter a data layer object, for example: digitalData, dataLayer[1], etc. This field accepts a comma separated list of JavaScript objects or functions to be identified as data layers.</mat-hint>
          </mat-form-field>
        </div>
      </form>
    </div>
  </ng-template>
</op-modal>
