import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IRFMConfig,
  IRFMConfigV3CreationRequest,
  IRFMUpdatedControl,
  IRFMUpdatedValidation,
  MatchType
} from '@app/components/creator/shared/remoteFileMapping/remote-file-mapping.component';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IRFMConfigV3 } from '../../creator/shared/remoteFileMapping/remote-file-mapping.component';
import { DateService, EDateFormats } from '@app/components/date/date.service';

interface IRfmEditorPayload {
  rfm: IRFMConfigV3;
  copy: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rfm-editor',
  templateUrl: './rfm-editor.component.html',
  styleUrls: ['./rfm-editor.component.scss']
})
export class RfmEditorComponent implements OnInit {

  title: string;
  rfmObj: IRFMConfigV3;
  rfmForm: UntypedFormGroup;
  createMode: boolean;
  copyMode: boolean;
  submitted: boolean = false;

  rfmConfigModel: IRFMConfig = {
    fileId: null,
    fileUrl: '',
    matchType: MatchType.Equals,
    matchValue: ''
  };

  rightFooterButtons = [
    {
      label: 'Save',
      action: () => {
        this.saveRfmConfig();
      },
      primary: true,
      disabled: true,
      opSelector: 'rfm-create-save',
    }
  ];
  
  constructor(
    public dialogRef: MatDialogRef<RfmEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRfmEditorPayload,
    private fb: UntypedFormBuilder,
    private dateService: DateService,
  ) {
    this.createMode = !this.data;
    this.copyMode = this.data && this.data.copy;
    this.title = this.createMode ? 'Create' : 'Edit';
    if (!this.createMode) this.rfmObj = this.data.rfm;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.rfmForm = this.fb.group({
      name: ['', Validators.required],
      rfmConfig: ['', Validators.required]
    });

    if (this.copyMode) {
      this.rfmObj.name = this.rfmObj.name + ' copied ' + this.dateService.formatDate(new Date, EDateFormats.dateTwo);
      delete this.rfmObj.id;
    }

    if (!this.createMode) {
      this.name.patchValue(this.rfmObj.name);
      
      this.rfmConfigModel = {
        fileId: this.rfmObj.fileId,
        fileUrl: this.rfmObj.fileUrl,
        matchType: this.rfmObj.matchType,
        matchValue: this.rfmObj.matchValue
      };
      
      setTimeout(() => {
        // triggers validation so form becomes valid
        this.submitted = true;
      });
    }
  }

  remoteFileMapConfigChanged($event: IRFMUpdatedControl): void {
    this.rfmConfig.setValue($event.config);
  }

  remoteFileMapValidationChanged($event: IRFMUpdatedValidation): void {
    $event.isValid
      ? this.rfmConfig.setErrors(null)
      : this.rfmConfig.setErrors({ invalid: true });
    
    setTimeout(() => { this.rfmForm.updateValueAndValidity(); });
    this.rightFooterButtons[0].disabled = this.rfmForm.invalid;
  }

  saveRfmConfig(): void {
    this.submitted = true;
    if (this.rfmForm.invalid) return;

    let rfmConfigObj: IRFMConfigV3CreationRequest = {
      ...this.rfmConfig.value,
      name: this.name.value
    };

    if (rfmConfigObj.fileId === null) delete rfmConfigObj.fileId;
    if (rfmConfigObj.fileUrl === null) delete rfmConfigObj.fileUrl;
    
    this.closeModal(rfmConfigObj);
  }
  
  closeModal(config?: IRFMConfigV3CreationRequest): void {
    this.dialogRef.close(config);
  }

  get name(): AbstractControl {
    return this.rfmForm.get('name');
  }

  get rfmConfig(): AbstractControl {
    return this.rfmForm.get('rfmConfig');
  }
}
