import { DEFAULT_SELECTOR } from './active-selector-picker.constants';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ActionDetailsFormBuilder } from '@app/components/actions/action-details/action-details-form-builder.service';
import { ISelectorChange } from '../multiple-selectors/multiple-selectors.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'active-selector-picker',
  templateUrl: './active-selector-picker.component.html',
  styleUrls: ['./active-selector-picker.component.scss']
})
export class ActiveSelectorPickerComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() formArray: UntypedFormArray;

  @Input() activeSelectorIndex: number = 0;
  @Input() submitted: boolean;
  @Input() toValidate: boolean;

  constructor(private actionDetailsFormBuilder: ActionDetailsFormBuilder) {}

  ngOnInit() {
    if (!this.formArray.length) this.addSelector();
  }

  removeSelector(index: number): void {
    if (!this.formArray || this.formArray.length < 2 || index < 0 || index >= this.formArray.length) return;
    this.formArray.removeAt(index);
    
    if (this.activeSelectorIndex >= this.formArray.length) {
      this.activeSelectorIndex = this.formArray.length - 1;
    }
  }

  updateSelector({index}: ISelectorChange): void {
    const currentSelector = this.formArray.at(this.activeSelectorIndex);
    if (currentSelector.invalid) {
      currentSelector.get('value').markAsTouched();
      return;
    }
    this.activeSelectorIndex = index;
  }

  addSelector(): void {
    const currentSelector = this.formArray.at(this.activeSelectorIndex);
    if (currentSelector && currentSelector.invalid) {
      currentSelector.get('value').markAsTouched();
      return;
    }

    this.formArray.push(this.actionDetailsFormBuilder.buildSelectorGroup(DEFAULT_SELECTOR));
    this.activeSelectorIndex = this.formArray.length - 1;
  }

  get selectors(): UntypedFormArray {
    const selectorsArray =  this.parentForm.get('selectors') as UntypedFormArray;
    return selectorsArray || new UntypedFormArray([]);
  }
}
