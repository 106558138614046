<div class="step-1" [formGroup]="formGroup">
  <section>
    <div class="heading uppercase">Email Inbox</div>

    <p>Automatically validate all links (landing pages) found in email messages by sending them to ObservePoint.</p>

    <div class="list">
      <p><b>1. Get Inbox Address</b> - Once this Inbox is created, and you have <b>assigned an Audit(s)</b> your Inbox Address will be created so you can copy it.</p>
      <br>
      <p><b>2. Send your Email Messages</b> - Add this custom email address to your test send group(s) or forward any message to the inbox address. Any message sent to this inbox will automatically run any associated Audits (configured on the next step).</p>
      <br>
      <p><b>3. Review Results</b> - After completion, all notifications will be sent to all users assigned to the associated Audits, Tag & Variable Rules, or Alerts.</p>
    </div>
  </section>

  <section>
    <div class="email-inbox-address">
      <div class="uppercase">Email Inbox Address</div>
      <op-copy-box
        [copyValue]="emailInboxAddress"
        [showInfo]="modalType === EEmailInboxModalType.AdvancedCreate || modalType === EEmailInboxModalType.Duplicate"
        [copyBtnDisabled]="modalType === EEmailInboxModalType.AdvancedCreate || !emailInboxAddress"
        infoText="The custom email address for this inbox will be provided once this new inbox is saved."
      ></op-copy-box>
    </div>
  </section>

  <section>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>Notes About This Inbox</mat-label>
      <textarea matInput [placeholder]="textareaPlaceholder" formControlName="notes" class="notes-box"></textarea>
    </mat-form-field>
  </section>
</div>
