import {
  Component,
  TemplateRef,
  ContentChild,
  Input,
  Output,
  EventEmitter,
  Optional,
  HostListener, OnInit,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { IButton } from '@app/models/commons';
import { ECurtainModalClass } from '../';

/**
 * This component is used for displaying modals with dynamic content.
 *
 * Basically, modal consists of the following parts:
 *
 * 1. HEADER. Can be used:
 *  - default header by sending @Input title and @Output onClose
 *  - custom header by sending custom header template with #headerTemplate reference
 *
 * 2. BODY. Should be sent body template with #bodyTemplate reference
 *
 * 3. FOOTER. Can be used:
 * - default footer by sending  @Input leftFooterButtons, @Input rightFooterButtons and @Output onClose
 * - custom footer by sending custom footer template with #footerTemplate reference
 *
 */
@Component({
  selector: 'op-modal',
  templateUrl: './op-modal.component.html',
  styleUrls: ['./op-modal.component.scss'],
})
export class OpModalComponent implements OnInit {
  private readonly defaultLeftButtons: IButton[] = [{
    label: 'CANCEL',
    action: () => this.closeModal(),
    transparent: true,
  }];

  @ContentChild('headerTemplate') headerTemplate?: TemplateRef<any>;
  @ContentChild('bodyTemplate') bodyTemplate: TemplateRef<any>;
  @ContentChild('footerTemplate') footerTemplate?: TemplateRef<any>;

  @Input() title?: string;
  @Input() ignoreEscape = false;
  // To avoid closing underlying modal when pressing 'esc'
  @Input() preventSimultaneousClose = false;
  @Output() onClose?: EventEmitter<any> = new EventEmitter();

  @Input() leftFooterButtons?: IButton[] = this.defaultLeftButtons;
  @Input() rightFooterButtons?: IButton[];

  curtainModalClass: ECurtainModalClass = ECurtainModalClass.Default;

  constructor(@Optional() private dialogRef: MatDialogRef<OpModalComponent>, private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.dialogRef)
      this.dialogRef
        .backdropClick()
        .pipe(take(1))
        .subscribe(() => this.dialogRef.disableClose ? null : this.dialogRef.close());
  }

  @HostListener('document:keydown.escape')
  onEscClick(): void {
    if (this.ignoreEscape) return;
    if (this.preventSimultaneousClose && this.dialog.openDialogs.length > 1) return;
    this.closeModal();
  }

  closeModal(): void {
    if (this.dialogRef) this.dialogRef.close();
    this.onClose.emit();
  }

}
