<div class="view-tag flex-col flex-stretch">
  <div class="go-back-arrow" (click)="goToTagDatabase()">
    <mat-icon>chevron_left</mat-icon>
    BACK TO LIBRARY
  </div>
  <div class="flex-col tag-info">
    <div class="form-item flex-row flex-spread-apart flex-center-items">
      <span>Tag</span>
      <div class="flex-row flex-center-items">
        <img class="tag-icon" [src]="tag?.icon"/>
        <span class="name-spacing">{{tag?.name}}</span>
        <span>(id: {{tag?.id}})</span>
      </div>
    </div>
    <div class="form-item flex-row flex-spread-apart flex-center-items">
      <span>Category</span>
      <div class="flex-row flex-center-items">
        <span class="name-spacing">{{tag?.category?.name}}</span>
        <span>(id: {{tag?.category?.id}})</span>
      </div>
    </div>
<!--  TODO: Add Vendor.. not sure where to get it from  -->
<!--    <div class="form-item flex-row flex-spread-apart flex-center-items">-->
<!--      <span>Vendor</span>-->
<!--      <div class="flex-row flex-center-items">-->
<!--        <span></span>-->
<!--        <span>(id: )</span>-->
<!--      </div>-->
<!--    </div>-->
  </div>
  <div class="flex-col flex-stretch table-wrappers">
    <mat-accordion class="example-headers-align"
                   [multi]="true"
    >
      <mat-expansion-panel [expanded]="signaturesExpanded" (click)="signaturesExpanded = !signaturesExpanded" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Signatures ({{tagSignaturesDataSource?.data?.length}})
          </mat-panel-title>

        </mat-expansion-panel-header>
        <tag-signatures-table [signatures]="tagSignaturesDataSource"></tag-signatures-table>
      </mat-expansion-panel>

      <mat-expansion-panel [expanded]="variablesExpanded" (click)="variablesExpanded = !variablesExpanded" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="flex-row expansion-section-header">
              <div class="section-title">
                Variables ({{tagVariablesDataSource?.data?.length}})
              </div>
              <div class="search-bar" [ngClass]="{'hide-component': !variablesExpanded}" (click)="clickSearch($event)">
                <op-clearable-input placeholderText="Search variables..."
                                    [emitKeyboardEvent]="true"
                                    class="table-filter-input"
                                    (onUserInput)="debounceFilterData($event)">
                </op-clearable-input>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <tag-variables-table [variables]="tagVariablesDataSource"></tag-variables-table>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
