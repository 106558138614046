import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output } from '@angular/core';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { EColorHighlightMeaning } from '@app/components/shared/components/viz/color-highlight/color-highlight.directive';
import { AlertMetricType } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { IUser } from '@app/moonbeamModels';
import { EAlertMenuContext } from '@app/components/alert/alert.enums';
import { IOpSummaryCardIconConfig } from './op-summary-card.models';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'op-summary-card',
  templateUrl: './op-summary-card.component.html',
  styleUrls: ['./op-summary-card.component.scss']
})
export class OPSummaryCardComponent implements OnChanges {

  widgetHover: boolean = false;
  tooltipTextFormatted: string = null;
  isVisitorMode$: Observable<boolean>;

  @Input() headerText: string;
  @Input() menuContext: EAlertMenuContext = EAlertMenuContext.Audit;
  @Input() filteredValue: number | string;
  @Input() noDataLabel: string;
  @Input() totalValue: number | string;
  @Input() showTotalValueWhenFiltered = false;
  @Input() valueColorHighlight: EColorHighlightMeaning = EColorHighlightMeaning.NONE;
  @Input() trendColorHighlight: EColorHighlightMeaning = EColorHighlightMeaning.NONE;
  @Input() widgetState: EFilterSpinnerState;
  @Input() tooltipText?: string;
  @Input() leftSideIcon?: string;
  @Input() rightSideIcon?: IOpSummaryCardIconConfig;
  @Input() showBothNumbersAlways?: boolean;
  @Input() disableHighlightFromClick?: boolean = false;
  @Input() metricType?: AlertMetricType;
  @Input() auditId?: number;
  @Input() runId?: number;
  @Input() currentValue?;
  @Input() filters?: IAuditReportApiPostBody;
  @Input() user?: IUser;
  // adds red border to the card
  @Input() highlight?: boolean;
  // changes background color of the card
  @Input() activated?: boolean;

  @HostListener('mouseenter')
  onWidgetHover() {
    this.widgetHover = true;
  }
  @HostListener('mouseleave')
  onWidgetBlur() {
    this.widgetHover = false;
  }

  @Output() cardClicked = new EventEmitter<void>();

  constructor(private decimalPipe: DecimalPipe,
    private applicationChromeService: ApplicationChromeService) {
    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
  }

  ngOnChanges(): void {
    this.totalValue = typeof this.totalValue === 'number'
      ? this.decimalPipe.transform(this.totalValue)
      : this.totalValue;

    this.filteredValue = typeof this.filteredValue === 'number'
      ? this.decimalPipe.transform(this.filteredValue)
      : this.filteredValue;

    this.generateTooltipText();
  }

  private generateTooltipText() {
    if (!this.tooltipText) {
      this.tooltipTextFormatted = null;
      return;
    }

    const ending = (this.tooltipText && this.cardClicked.observers.length > 0)
      ? (this.activated ? ' Click to clear the filter.' : ' Click to toggle filter.')
      : '';

    this.tooltipTextFormatted = this.tooltipText + ending;
  }
}
