import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/components/material/material.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { OpModalModule } from '../shared/components/op-modal/op-modal.module';
import {
  FolderAndSubFolderModalComponent
} from '@app/components/folder-and-sub-folder-modal/folder-and-sub-folder-modal.component';
import { OpSelectCreateModule } from '@app/components/shared/components/op-select-create/op-select-create.module';
import { OpFormModule } from '@app/components/shared/op-form.module';

@NgModule({
  declarations: [
    FolderAndSubFolderModalComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    OpModalModule,
    OpSelectCreateModule,
    OpFormModule,
  ],
  exports: [
    FolderAndSubFolderModalComponent,
  ]
})
export class FolderAndSubFolderModalModule {}
