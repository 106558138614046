import { UseCaseUtils } from '@app/components/audit-reports/use-cases/use-case.utils';

export const PagesSection = {
  sectionIcon: 'web',
  sectionTitle: 'PAGES',
};

export const CookiesSection = {
  sectionIcon: 'cookie',
  sectionTitle: 'COOKIES',
};

export const TagsSection = {
  sectionIcon: 'local_offer',
  sectionTitle: 'TAGS',
};

export const OverviewPagesScannedWidget = {
  topText: 'Pages Scanned',
  topTooltip: 'Count of all unique pages scanned in this audit run. Click to view additional details.',
  bodyContent: {
    navToText: 'View Pages report to get more detail',
    bodyText: {
      paid: 'To govern at scale we recommend auditing the most important pages on your website at a regular cadence even as frequently as daily. A full scan of your entire index should be performed at least quarterly.',
      guest: 'Typically a small sample of pages provides a good high level indication of what’s happening on your website, it’s just a starting point. To govern at scale we recommend auditing the most important pages on your website at a regular cadence even as frequently as daily. A full scan of your entire index should be performed at least quarterly.',
      trial: 'To govern at scale we recommend auditing the most important pages on your website at a regular cadence even as frequently as daily. A full scan of your entire index should be performed at least quarterly.',
    }
  }
};

export const OverviewBrokenPagesWidget = {
  topText: 'Broken Pages',
  topIconName: 'error',
  topTooltip: 'Count of all pages that had an HTTP status code of 400+ or 500+. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'View all broken pages found in this audit',
    bodyText: {
      paid: 'Broken pages create poor user experiences and lower onsite conversions rates. Sites with broken links can also be penalized by paid media providers and in organic search results.',
      guest: 'Broken pages create poor user experiences and lower onsite conversions rates. Sites with broken links can also be penalized by paid media providers and in organic search results.',
      trial: 'Broken pages create poor user experiences and lower onsite conversions rates. Sites with broken links can also be penalized by paid media providers and in organic search results.',
    },
  }
};

export const OverviewAveragePageLoadTimeWidget = {
  topText: 'Average Page Load Time',
  topIconName: 'check_circle',
  topTooltip: 'Average load time in seconds of all pages scanned. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: '< 3.0 sec',
  bodyContent: {
    navToText: 'View all slow loading pages',
    bodyText: {
      paid: 'For optimal user experience as well as paid and organic search performance, it’s recommended that all pages load in three seconds or less.',
      guest: 'For optimal user experience as well as paid and organic search performance, it’s recommended that all pages load in three seconds or less.',
      trial: 'For optimal user experience as well as paid and organic search performance, it’s recommended that all pages load in three seconds or less.',
    },
  },
};

export const OverviewLCPWidget = {
  topText: '75TH Percentile Largest Contentful Paint',
  topIconName: 'check_circle',
  topTooltip: '',
  bottomText: 'BEST PRACTICE',
  bestPracticeValue: 2.5,
  get bottomValue() {
    return `< ${this.bestPracticeValue} sec`
  },
  bodyContent: {
    navToText: 'View all supported web vitals',
    bodyText: {
      paid: `Largest Contentful Paint (LCP) is a stable Core Web Vital metric for measuring perceived load speed. It marks the point in the page load timeline when the page's main content has likely loaded. A fast LCP helps reassure the user that the page is useful. View all supported web vitals`,
      guest: `Largest Contentful Paint (LCP) is a stable Core Web Vital metric for measuring perceived load speed. It marks the point in the page load timeline when the page's main content has likely loaded. A fast LCP helps reassure the user that the page is useful. View all supported web vitals`,
      trial: `Largest Contentful Paint (LCP) is a stable Core Web Vital metric for measuring perceived load speed. It marks the point in the page load timeline when the page's main content has likely loaded. A fast LCP helps reassure the user that the page is useful. View all supported web vitals`,
    },
  },
};

export const OverviewUniqueTagsStats = {
  min: '1',
  max: '613',
  median: '34',
  average: '50',
  ninetyFifthPercentile: '143',
  ninetyNinthPercentile: '293',
};

export const OverviewUniqueTagsWidget = {
  topText: 'Unique Tags',
  topTooltip: 'Total number of unique tags (identified technologies) found in this audit run. Click to view additional details.',
  statsData: {
    headerText: '<div class="header-text-top">UNIQUE TAGS</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...OverviewUniqueTagsStats,
  },
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    OverviewUniqueTagsStats.median,
    OverviewUniqueTagsStats.average,
    OverviewUniqueTagsStats.min,
    OverviewUniqueTagsStats.max,
  ),
  bodyContent: {
    navToText: 'View the complete list of all tag technologies on your site',
    bodyText: {
      paid: 'Most organizations have dozens of marketing technologies deployed on their website. How many did you have? You’ll want to verify that each technology is properly documented, especially 3rd party tags. Below is a summary of all tag technology categories found in this sample audit of your site.',
      guest: 'Most organizations have dozens of marketing technologies deployed on their website. How many did you have? You’ll want to verify that each technology is properly documented, especially 3rd party tags. Below is a summary of all tag technology categories found in this sample audit of your site.',
      trial: 'Most organizations have dozens of marketing technologies deployed on their website. How many did you have? You’ll want to verify that each technology is properly documented, especially 3rd party tags. Below is a summary of all tag technology categories found in this sample audit of your site.'
    },
  },
};

export const OverviewBrokenTagRequestsWidget = {
  topText: 'Broken Tag Requests',
  topIconName: 'error',
  topTooltip: 'Count tag requests that had and HTTP status code of  400+ or 500+. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'View more specifics on your broken tags',
    bodyText: {
      paid: 'Broken tags typically mean your critical technologies or analytics tags aren’t loading correctly this results in data loss. You’ll want to get these fixed right away.',
      guest: 'Broken tags typically mean your critical technologies or analytics tags aren’t loading correctly this results in data loss. You’ll want to get these fixed right away.',
      trial: 'Broken tags typically mean your critical technologies or analytics tags aren’t loading correctly this results in data loss. You’ll want to get these fixed right away.',
    },
  }
};

export const OverviewAverageTagLoadTimeWidget = {
  topText: 'Average Tag Load Time',
  topIconName: 'error',
  topTooltip: 'Average load time in milliseconds of all identified tag requests. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: '< 500 ms',
  bodyContent: {
    navToText: 'View load times for all tags',
    bodyText: {
      paid: 'Slow loading tags can negatively impact page load time, and thus user experience. Slow tags may also contribute to data loss and inaccurate reporting. Third party tags are often the worst offenders.',
      guest: 'Slow loading tags can negatively impact page load time, and thus user experience. Slow tags may also contribute to data loss and inaccurate reporting. Third party tags are often the worst offenders.',
      trial: 'Slow loading tags can negatively impact page load time, and thus user experience. Slow tags may also contribute to data loss and inaccurate reporting. Third party tags are often the worst offenders.',
    },
  },
};

export const OverviewTagInitiatorWidget = {
  topText: 'Tag Initiators on<br><b>{{pageTitle}}</b>',
  bodyContent: {
    bodyText: {
      paid: 'Here is how tags loaded on <b>{{pageTitle}}</b>. To view tag initiators for other pages click a URL from any report to open a detailed view. <div class="open-initiators-btn">View Tag Initiators Full Screen</div>',
      guest: 'Here is how tags loaded on <b>{{pageTitle}}</b>. To view tag initiators for other pages click a URL from any report to open a detailed view. <div class="open-initiators-btn">View Tag Initiators Full Screen</div>',
      trial: 'Here is how tags loaded on <b>{{pageTitle}}</b>. To view tag initiators for other pages click a URL from any report to open a detailed view. <div class="open-initiators-btn">View Tag Initiators Full Screen</div>',
    }
  }
};

export const OverviewUniqueCookiesStats = {
  min: '0',
  max: '3,178,469',
  median: '66',
  average: '3,173',
  ninetyFifthPercentile: '1,707',
  ninetyNinthPercentile: '45,972',
};

export const OverviewUniqueCookiesWidget = {
  topText: 'Unique Cookies',
  topTooltip: 'Count of cookies discovered during this audit run. Uniqueness is determined by all cookie parameters except path and value. Click to view additional details.',
  statsData: {
    headerText: '<div class="header-text-top">UNIQUE COOKIES</div><div class="header-text-bottom">Across All ObservePoint Customers</div>',
    ...OverviewUniqueCookiesStats
  },
  bottomText: 'MEDIAN<br>Across All ObservePoint Customers',
  bottomValue: 66,
  bottomLeftCustomContent: UseCaseUtils.buildCustomBottomStatsContent(
    OverviewUniqueCookiesStats.median.toLocaleString(),
    OverviewUniqueCookiesStats.average,
    OverviewUniqueCookiesStats.min.toLocaleString(),
    OverviewUniqueCookiesStats.max,
  ),
  bodyContent: {
    navToText: 'View all 1st and 3rd party cookies',
    bodyText: {
      paid: 'Cookies are vital to your customers\' experience on your website. Most companies will want to keep a precise list of all cookies found on their web properties. We can help with that.',
      guest: 'Cookies are vital to your customers\' experience on your website. Most companies will want to keep a precise list of all cookies found on their web properties. We can help with that.',
      trial: 'Cookies are vital to your customers\' experience on your website. Most companies will want to keep a precise list of all cookies found on their web properties. We can help with that.'
    },
  },
};

export const OverviewNonSecureCookiesWidget = {
  topText: 'Non-Secure Cookies',
  topTooltip: 'Count of unique cookies where the secure attribute is false or empty. Uniqueness is determined by all cookie parameters except path and value. Click to view additional details.',
  bottomText: 'BEST PRACTICE',
  bottomValue: 0,
  bodyContent: {
    navToText: 'View all Non-Secure cookies',
    bodyText: {
      paid: 'Cookies that are non-secure can be transferred via an un-encrypted connection. A man-in-the-middle attack (MiTM) can be used to get the contents of these cookies, hijack a session and steal authentication details or sensitive data. You’ll want to minimize all non-secure cookies on your web sites.',
      guest: 'Cookies that are non-secure can be transferred via an un-encrypted connection. A man-in-the-middle attack (MiTM) can be used to get the contents of these cookies, hijack a session and steal authentication details or sensitive data. You’ll want to minimize all non-secure cookies on your web sites.',
      trial: 'Cookies that are non-secure can be transferred via an un-encrypted connection. A man-in-the-middle attack (MiTM) can be used to get the contents of these cookies, hijack a session and steal authentication details or sensitive data. You’ll want to minimize all non-secure cookies on your web sites.'
    },
  },
};
