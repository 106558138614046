import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RRule } from 'rrule';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'weekday-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule
  ],
  templateUrl: './weekday-selector.component.html',
  styleUrls: ['./weekday-selector.component.scss']
})
export class WeekdaySelectorComponent implements OnChanges {

  @Input() daysOfWeek: number[] = [];
  @Input() startDate: string | Date = new Date();
  @Output() selectionChanged: EventEmitter<number[]> = new EventEmitter<number[]>();

  selectedDays: Set<number> = new Set();

  weekdays = [
    { label: 'S', value: RRule.SU.weekday },
    { label: 'M', value: RRule.MO.weekday },
    { label: 'T', value: RRule.TU.weekday },
    { label: 'W', value: RRule.WE.weekday },
    { label: 'T', value: RRule.TH.weekday },
    { label: 'F', value: RRule.FR.weekday },
    { label: 'S', value: RRule.SA.weekday },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.daysOfWeek?.currentValue) {
      this.selectedDays = new Set(changes.daysOfWeek.currentValue);
    }

    if (changes.startDate?.currentValue) {
      const startDateObj = new Date(this.startDate);
      const startDayOfWeek = startDateObj.getDay();
      if (this.selectedDays.size === 0) {
        this.selectedDays.add(this.weekdays[startDayOfWeek].value);
        this.selectionChanged.emit(Array.from(this.selectedDays));
      }
    }
  }

  onSelectionChanged(weekday: number, checked: boolean): void {
    if (!checked && this.selectedDays.size === 1 && this.selectedDays.has(weekday)) {
      // Prevent deselection if it's the only selected day
      return;
    }

    checked ? this.selectedDays.add(weekday) : this.selectedDays.delete(weekday);
    this.selectionChanged.emit(Array.from(this.selectedDays).sort());
  }
}
