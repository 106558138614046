import { Route } from '@angular/router';
import { AccountSettingsComponent } from './account-settings.component';
import { AccountSettingsPaths, AccountSettingsStateNames } from './account-settings.const';
import { ManageTagsComponent } from './manage-tags/manage-tags.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { EventLogComponent } from './event-log/event-log.component';
import { AcctAdminOnlyGuard } from '../core/permissions.guard';

export const AccountSettingsFutureState: Route = {
  path: AccountSettingsPaths.base,
  canLoad: [ AcctAdminOnlyGuard ],
  loadChildren: () => import('./account-settings.module').then(mod => mod.AccountSettingsModule),
};

export const ManageTagsState: Route = {
  path: AccountSettingsPaths.manageTags,
  component: ManageTagsComponent,
  data: {
    title: 'Manage Tags',
    stateName: AccountSettingsStateNames.manageTags,
  }
};

const manageUsersState: Route = {
  path: AccountSettingsPaths.manageUsers,
  component: ManageUsersComponent,
  data: {
    title: 'Account Users',
    stateName: AccountSettingsStateNames.manageUsers,
  }
};

const userEventsState: Route = {
  path: AccountSettingsPaths.userEvents,
  component: EventLogComponent,
  data: {
    title: 'User Events',
    stateName: AccountSettingsStateNames.userEvents,
  }
};

export const AccountSettingsRoutes: Route = {
  path: '',
  component: AccountSettingsComponent,
  data: {
    stateName: AccountSettingsStateNames.base,
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: manageUsersState.path },
    manageUsersState,
    userEventsState,
  ]
};
