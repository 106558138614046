import { AngularNames, Names } from '@app/moonbeamConstants';

import { TagsService } from '../../tags/tagsService';
import { IPrimaryTag, ITagCostPeriod, ManageTagsService, TagCostPeriods } from './manageTagsService';
import * as angular from 'angular';
import { IUser } from '../../../moonbeamModels';
import {
  AdminPermissions,
  GuestPermissions,
  StandardPermissions,
  userIsAdmin,
  userIsGuest,
  userIsStandard
} from '../../../authUtils';
import { ISort } from '@app/components/manage/shared/services/manage-communication/manage-communication.service';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AccountsService } from '@app/components/account/account.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

type TagContactTabType = 'owners' | 'technicalSpecialists' | 'vendorContacts';
  interface ITagContactTab {
    name: string;
    type: TagContactTabType;
    itemName: string;
  }

export class ManageTagsController {

    userPermission: number;
    permissions: {[key: string]: number} = {
      'GuestPermissions': GuestPermissions,
      'StandardPermissions': StandardPermissions,
      'AdminPermissions': AdminPermissions
    };

    tableSort: ISort;
    tagsSearch: string;

    accountTags: Array<IUiTag>;
    filteredAccountTags: Array<IUiTag>;

    primaryTags: Array<IPrimaryTag>;
    sortedPrimaryTags: Array<IPrimaryTag>;
    editablePrimaryTags: {[primaryTagId: number]: IPrimaryTag} = {};

    contactTabs: Array<ITagContactTab> = [
      {
        name: 'Tag Owners',
        type: 'owners',
        itemName: 'Owner'
      },
      {
        name: 'Technical Specialists',
        type: 'technicalSpecialists',
        itemName: 'Specialist'
      },
      {
        name: 'Vendor Contacts',
        type: 'vendorContacts',
        itemName: 'Contact'
      }
    ];
    activeContactTabs: {[primaryTagId: number]: ITagContactTab } = {};

    tagCostPeriods: Array<ITagCostPeriod> = [
      { key: TagCostPeriods.month, label: 'Month'},
      { key: TagCostPeriods.quarter, label: 'Quarter'},
      { key: TagCostPeriods.year, label: 'Year'}
    ];

    static $inject = [
      AngularNames.filter,
      Names.Services.tags,
      Names.NgServices.authentication,
      Names.NgServices.accountsService,
      Names.Services.manageTags
    ];

    constructor(private $filter: angular.IFilterService,
                private tagsService: TagsService,
                private authenticationService: AuthenticationService,
                private accountsService: AccountsService,
                private uiTagService: UiTagService,
                private manageTagsService: ManageTagsService) {

      this.accountsService.getUser().subscribe((user: IUser) => {
        this.userPermission = this.determineUserPermission(user);
      });
      this.loadPrimaryTags().then( (primaryTags: Array<IPrimaryTag>) => {
        this.primaryTags = primaryTags;
        this.setSort('name');
      });
      this.loadAccountTags();
    }

    private determineUserPermission(user: IUser): number {
      if (userIsAdmin(user)) return AdminPermissions;
      if (userIsStandard(user)) return StandardPermissions;
      if (userIsGuest(user)) return GuestPermissions;
    }

    loadPrimaryTags(): angular.IPromise<Array<IPrimaryTag>> {
      return this.manageTagsService.getPrimaryTags();
    }

    loadAccountTags(): void {
      this.uiTagService.getAllTagsData()
        .subscribe(([accountTags]) => this.accountTags = accountTags);
    }

    addPrimaryTag(tag: IUiTag): void{
      this.manageTagsService.addPrimaryTag({
        tagId: tag.id,
        name: tag.name,
        icon: tag.iconUrl,
        categoryName: this.uiTagService.getTagCategory(tag.tagCategoryId).category,
        enabled: true
      }).then( (primaryTag: IPrimaryTag) => {
        this.manageTagsService.getPrimaryTags().then( (tags: Array<IPrimaryTag>) => {
          this.tagsSearch = '';
          this.primaryTags = tags;
          this.togglePrimaryTagSettings(primaryTag, true);
          this.sortedPrimaryTags = this.sortTags(this.primaryTags);
        });
      });
    }

    removePrimaryTag(primaryTagId: number, index: number): void {
      this.manageTagsService.deletePrimaryTag(primaryTagId).then( () => {
        this.manageTagsService.getPrimaryTags().then( (tags: Array<IPrimaryTag>) => {
          this.primaryTags = tags;
          delete this.editablePrimaryTags[primaryTagId];
          this.sortedPrimaryTags = this.sortTags(this.primaryTags);
        });
      });
    }

    togglePrimaryTagFilter(primaryTag: IPrimaryTag, index: number): void {
      this.manageTagsService.updatePrimaryTagFilter(primaryTag.id, primaryTag.enabled).then( (updatedPrimaryTag: IPrimaryTag) => {
        this.manageTagsService.getPrimaryTags().then( (tags: Array<IPrimaryTag>) => {
          this.resetStoredReportTogglesIfNeeded(primaryTag, tags);
          this.primaryTags = tags;
          this.sortedPrimaryTags = this.sortTags(this.primaryTags);
        });
      });
    }

    private resetStoredReportTogglesIfNeeded(changedPrimaryTag: IPrimaryTag, newPrimaryTags: Array<IPrimaryTag>): void {
      var newAmountOfEnabledTags = newPrimaryTags.filter( pt => pt.enabled).length;
      var oldAmountOfEnabledTags = newAmountOfEnabledTags + (changedPrimaryTag.enabled ? -1 : 1);
      if ( (oldAmountOfEnabledTags == 1 && newAmountOfEnabledTags == 0)
        || (oldAmountOfEnabledTags == 0 && newAmountOfEnabledTags == 1) )
        this.manageTagsService.resetStoredReportToggleValues();
    }

    togglePrimaryTagSettings(tag: IPrimaryTag, value?: boolean): void {
      if (typeof value != 'undefined' ? value : !(typeof this.editablePrimaryTags[tag.id] !== 'undefined')) {
        this.editablePrimaryTags[tag.id] = angular.copy(tag);
        this.openContactTab(tag.id, this.contactTabs[0]);
      } else {
        delete this.editablePrimaryTags[tag.id];
      }
    }

    cancelPrimaryTag(tag: IPrimaryTag): void {
      this.togglePrimaryTagSettings(tag, false);
    }
    savePrimaryTag(primaryTag: IPrimaryTag, index: number): void {
      primaryTag.owners = this.editablePrimaryTags[primaryTag.id].owners;
      primaryTag.technicalSpecialists = this.editablePrimaryTags[primaryTag.id].technicalSpecialists;
      primaryTag.vendorContacts = this.editablePrimaryTags[primaryTag.id].vendorContacts;
      primaryTag.cost = this.editablePrimaryTags[primaryTag.id].cost;
      primaryTag.notes = this.editablePrimaryTags[primaryTag.id].notes;
      this.manageTagsService.updatePrimaryTag(primaryTag).then( (updatedPrimaryTag: IPrimaryTag) => {
        this.manageTagsService.getPrimaryTags().then( (tags: Array<IPrimaryTag>) => {
          this.primaryTags = tags;
          this.togglePrimaryTagSettings(updatedPrimaryTag, false);
          this.sortedPrimaryTags = this.sortTags(this.primaryTags);
        });
      });
    }

    openContactTab(primaryTagId: number, tab: ITagContactTab): void {
      this.activeContactTabs[primaryTagId] = tab;
    }

    addEmptyContact(tag: IPrimaryTag, contactKey: TagContactTabType): void {
      if (!tag[contactKey]) tag[contactKey] = [];
      tag[contactKey].push({name: '', email: '', phone: ''});
    }

    removeContact(tag: IPrimaryTag, contactKey: TagContactTabType, contactIndex: number): void {
      if (!tag[contactKey]) return;
      tag[contactKey].splice(contactIndex, 1);
    }

    sortTags(primaryTags: Array<IPrimaryTag>): Array<IPrimaryTag> {
      return this.$filter('orderBy')(primaryTags, this.tableSort.sortOn, this.tableSort.reverse);
    }

    tagSearchFilterChanged(tagsSearch: string): void {
      var selectedAccountTags: Array<number> = this.primaryTags.map( (tag: IPrimaryTag) => tag.tagId);
      var filtered = this.$filter('filter')(this.accountTags, (tag, index, tags) => {
        return selectedAccountTags.indexOf(tag.id) == -1 &&
          ( (tag.name && tag.name.toLowerCase().indexOf(tagsSearch.toLowerCase()) != -1) ||
            (this.uiTagService.getTagCategory(tag.tagCategoryId).category?.toLowerCase().indexOf(tagsSearch.toLowerCase()) != -1) );
      });
      this.filteredAccountTags = this.$filter('orderBy')(filtered, 'name');
    }

    setSort(column: string): void {
      var reverse = (this.tableSort && this.tableSort.name == column) ? !this.tableSort.reverse : false;
      switch (column) {
        case 'name':
          this.tableSort = { sortOn: 'name', name: 'name', reverse: reverse };
          break;
        case 'enabled':
          this.tableSort = { sortOn: 'enabled', name: 'enabled', reverse: reverse };
          break;
        case 'cost.cost':
          this.tableSort = { sortOn: (pt: IPrimaryTag) => new Number(pt.cost ? pt.cost.cost : 0), name: 'cost.cost', reverse: reverse };
          break;
      }
      this.sortedPrimaryTags = this.sortTags(this.primaryTags);
    }

    hasNumbersOnly(value: any): boolean {
      return !value || /^\d+$/.test(value);
    }

    isPrimaryTagValid(editableTag: IPrimaryTag): boolean {
      return editableTag && (!editableTag.cost || this.hasNumbersOnly(editableTag.cost.cost));
    }
  }
