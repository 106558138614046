import { IAuditActionsService } from '@app/store/actions/auditActions';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { Injectable, Inject } from '@angular/core';
import { Observable, of, from, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularNames } from '@app/moonbeamConstants';
import { IAuditModel } from '../modals/modalData';
import { ILabel } from '@app/components/shared/services/label.service';
import { IEditAudit, INewAudit } from '@app/components/audit/audit.models';
import { IAuditFilter } from '@app/components/audit/audit.models';

@Injectable()
export class AuditService {

  // default to true so if the user doesn't edit the starting urls we still report that filters were generated
  includeFiltersGeneratedSubject = new BehaviorSubject<boolean>(true);
  includeFiltersGenerated$ = this.includeFiltersGeneratedSubject.asObservable();

  constructor(@Inject(AngularNames.ngRedux) private ngRedux: ngRedux.INgRedux,
              private discoveryAuditService: DiscoveryAuditService,
              private auditActionsService: IAuditActionsService) {}

  autoGenerateIncludeList(startingUrls: string, includeFilters?: IAuditFilter[]): Observable<IAuditFilter[]> {
    if (!startingUrls) return of([]);
    return from(this.discoveryAuditService.getDefaultFilters(startingUrls.split('\n')))
      .pipe(map(filters => {
        // add current includeLimit values to default so these numbers aren't overwritten
        const generatedFilters = filters.include.map(generatedFilter => {
          if(includeFilters) {
            includeFilters.map(includeFilter => {
              if(generatedFilter.value === includeFilter.value) {
                generatedFilter.includeLimit = includeFilter.includeLimit;
              }
            });
          }

          return generatedFilter;
        });

        const generatedFiltersValues = generatedFilters.map(filter => filter.value);

        // On Nov 9, 2023, we launched a new default inculde filter that appends the suffix regex ([^.]|$)
        // https://observepoint.atlassian.net/browse/WORK-26440
        // Replace any existing default include filters with this new filter if present.
        const NEW_DEFAULT_INCLUDE_FILTER_SUFFIX = '([^.]|$)';
        const modifiedIncludeFilters = includeFilters?.map(includeFilter => {
          const alternativeFilterValue = includeFilter.value + NEW_DEFAULT_INCLUDE_FILTER_SUFFIX
          if (generatedFiltersValues.includes(alternativeFilterValue)) {
            return {
              ...includeFilter,
              value: alternativeFilterValue,
            }
          } else {
            return includeFilter;
          }
        })

        // add any existing include filters to list of newly generated filters if they aren't present
        modifiedIncludeFilters?.forEach(includeFilter => {
          if (!generatedFiltersValues.includes(includeFilter.value)) {
            generatedFilters.push(includeFilter);
          }
        })

        return generatedFilters;
      }));
  }

  /**
   * Is used for removing blank lines and leading / trailing white spaces from the starting urls
   */
  removeBlankLines(startingUrls: string): string {
    return startingUrls.split('\n').map(url => url.trim()).filter(url => url !== '').join('\n');
  }

  createAudit(audit: INewAudit): angular.IPromise<IAuditModel> {
    return this.ngRedux.dispatch(this.auditActionsService.createAudit(audit));
  }

  updateAudit(audit: IEditAudit): angular.IPromise<IAuditModel> {
    return this.ngRedux.dispatch(this.auditActionsService.updateAudit(audit));
  }

  updateAuditLabels(auditId: number, labels: ILabel[]): angular.IPromise<ILabel[]> {
    return this.discoveryAuditService.updateAuditLabels(auditId, labels);
  }

  recipientsStringToArray(recipients: string): string[] {
    return recipients && recipients.length
      ? recipients.split('\n').map(r => r.trim()).filter(r => !!r)
      : [];
  }

  setIncludeFiltersGenerated(value: boolean) {
    this.includeFiltersGeneratedSubject.next(value);
  }
}
