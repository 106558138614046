import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { takeUntil } from 'rxjs/operators';
import { AuditReportBase } from '@app/components/audit-reports/reports/general-reports.models';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  IJSFileChangesPagesTableRow,
  IJSFileChangesPagesTableState
} from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.models';
import { IJSFileChangesTableRow } from '../js-file-changes-table/js-file-changes-table.models';
import {
  formatPaginator,
  PageLoadColumnTooltip,
  REQUEST_LOG_SEARCH_TEXT_KEY,
  REQUEST_LOG_SHOW_FILE_CHANGES_KEY
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import { DecimalPipe } from '@angular/common';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  TableColumn
} from '@app/components/audit-reports/reports/js-files-changes/components/js-file-changes-pages-table/js-file-changes-pages-table.constants';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'js-file-changes-pages-table',
  templateUrl: './js-file-changes-pages-table.component.html',
  styleUrls: ['./js-file-changes-pages-table.component.scss'],
})
export class JsFileChangesPagesTableComponent extends AuditReportBase implements OnInit, OnChanges {
  @Input() auditId: number;
  @Input() runId: number;
  @Input() inputData: IJSFileChangesPagesTableRow[];
  @Input() spinnerState: EFilterSpinnerState;
  @Input() tableState: IJSFileChangesPagesTableState;
  @Input() selectedFile: IJSFileChangesTableRow;

  @Output() updateTableState = new EventEmitter<IJSFileChangesPagesTableState>();

  @ViewChild(MatSort, {static: true}) tableSort: MatSort;
  @ViewChild(MatPaginator, {static: true}) tablePaginator: MatPaginator;

  PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;
  readonly TableColumn = CommonReportsPagesTableColumns;
  PageLoadColumnTooltip = PageLoadColumnTooltip;

  tableDataSource = new MatTableDataSource<IJSFileChangesPagesTableRow>();

  displayedColumns$ = this.tableService.displayedColumns$;
  openedPageId: string = null;
  readonly EFilterSpinnerState = EFilterSpinnerState;

  constructor(
    private auditReportService: AuditReportService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private decimalPipe: DecimalPipe,
    private tableService: ResizeableTableService,
    ) {
    super();
  }

  ngOnInit() {
    this.handleInputData(this.inputData);
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.RequestLog);
    this.formatPaginator();

    this.pageDetailsDrawerService
      .pageDrawerClosed$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.openedPageId = null);

    this.tableSort.sortChange.subscribe((sort: Sort) => {
      this.tableState.sort.sortBy = sort.active as CommonReportsPagesTableColumns;
      this.tableState.sort.sortDesc = sort.direction === 'desc';
      this.tableState.pagination.page = 0;
      this.updateTableState.emit(this.tableState);
    });

    this.tablePaginator.page.subscribe((pagination: PageEvent) => {
      this.tableState.pagination.page = pagination.pageIndex;
      this.updateTableState.emit(this.tableState);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputData']) {
      this.handleInputData(changes['inputData'].currentValue);
    }
  }

  openPageDetails(page: IJSFileChangesPagesTableRow) {
    this.openedPageId = page.pageId;
    this.pageDetailsDrawerService
      .openPageDetails(
        { id: page.pageId, url: page[TableColumn.PageUrl] },
        this.auditId,
        this.runId,
        EPageDetailsTabs.RequestLog,
        false, // we actually don't care - openPageDetails() will check user pref if false
        {
          [REQUEST_LOG_SEARCH_TEXT_KEY]: this.selectedFile?.filename,
          [REQUEST_LOG_SHOW_FILE_CHANGES_KEY]: true
        }
      );
  }

  private handleInputData(inputData: IJSFileChangesPagesTableRow[]) {
    if (inputData && Array.isArray(inputData)) {
      this.tableDataSource.data = inputData.map(i => {
        const loadTime = parseFloat((i.pageLoadTime / 1000).toFixed(1));
        return {
          ...i,
          pageLoadTime: loadTime,
          loadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(loadTime),
          finalPageStatusCode: i.finalPageStatusCode,
          statusCodeClass: this.auditReportService.getStatusCodeClass(i.finalPageStatusCode)
        };
      });
    }
  }

  private formatPaginator(): void {
    this.tablePaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }
}
