import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-standards-selector',
  templateUrl: './cl-standards-selector.component.html',
  styleUrls: ['./cl-standards-selector.component.scss']
})
export class ComponentLibraryStandardsSelectorComponent {
}
