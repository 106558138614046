export const runDateFormat = 'MMM DD, YYYY | hh:mm A';

export enum EStartingUrlFailureType {
  NoLinksOneAndOut = 'no_links_scanned_one_and_out',
  AllFailedOneAndOut = 'all_starting_urls_failed_one_and_out'
}

export enum EAuditRunStatus {
  Basic,    // normal info icon (no background)
  Advanced, // shuffle icon (yellow background)
  Issues,   // warning icon (orange background)
  Stopped  // warning icon (red background)
}

export enum EAuditStatusPillText {
  PreAuditActionFailures = 'Pre-audit actions failed',
  PagesCantBeReached = 'Pages can\'t be reached',
  OnPageActionFailures = 'On-page action failure(s)',
}