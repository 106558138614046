<op-modal [leftFooterButtons]="[]"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="{{fullscreenChartConfig?.title}}"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="date-range-filter">
      <op-date-range-buttons
        [loading]="loading"
        [dateRange]="dateRange"
        [greaterThanOneMonthDisabled]="greaterThanOneMonthDisabled"
        [greaterThanThreeMonthsDisabled]="greaterThanThreeMonthsDisabled"
        [greaterThanSixMonthsDisabled]="greaterThanSixMonthsDisabled"
        [greaterThanNineMonthsDisabled]="greaterThanNineMonthsDisabled"
        [greaterThanThirteenMonthsDisabled]="greaterThanThirteenMonthsDisabled"
        (dateRangeChange)="getDataForDateRange($event)">
      </op-date-range-buttons>
    </div>
    <div class="spinner-container" *ngIf="loading">
      <mat-spinner [diameter]="50" [strokeWidth]="5"></mat-spinner>
    </div>
    <div *ngIf="dateRange === null && !loading"></div>
    <div class="chart-container" *ngIf="dateRange !== null && !loading">
      <vertical-stacked-bar-chart [inputData]="fullscreenChartConfig.data"
                                  [marginLeft]="0"
                                  [drawXAxis]="false"
                                  [drawYAxis]="false"
                                  [drawBarSegmentLabels]="true"
                                  [drawPills]="true"
                                  uniqueIdentifier="chart-fullscreen-1">
      </vertical-stacked-bar-chart>
    </div>
  </ng-template>
</op-modal>
