<div class="top">
  <h2 class="title">
    Tag & Variable Rules
    <span class="icon-wrapper" [class.highlight]="infoIsExpanded" (click)="infoIsExpanded = !infoIsExpanded">
      <mat-icon class="title-icon">help_outline</mat-icon>
      <mat-icon class="expansion-icon">expand_more</mat-icon>
    </span>
  </h2>
  <op-button-2021 labelText="Create new Rule"
                  matIcon="control_point"
                  (buttonAction)="createRule()"
                  buttonType="filled">
  </op-button-2021>
</div>

<op-info-panel [expanded]="infoIsExpanded" class="info-panel">
  <p>
    Use rules to validate that tag and variable data is being collected as expected. Rules can be as simple as checking for the presence of a specified tag or complex using regular expressions to ensure critical data is structured correctly. <a href="https://help.observepoint.com/category/284-rules" target="_blank">Learn more</a>.
  </p>
</op-info-panel>

<op-filter-bar [menuItems]="filterBarMenuItems"
               [service]="rulesFilterBarService"
               [isSearchByTextEnabled]="true"
               [validFilterTypes]="rulesFilterBarService.validFilterTypesUpdated$ | async"
               [hideSearchByRegexOption]="true"
               (searchByTextIsEntered)="rulesFilterBarService.addNameContainsFilter($event.value)"
               searchByTextPlaceholderSuffix="Name">
</op-filter-bar>

<rules-table [loadingRules]="loadingRules"
             [isReadOnly]="isReadOnly"
             [rules]="rules"
             [sortOptions]="sortOptions"
             [pagination]="pagination"
             [menuItems]="menuItems"
             [allLabels]="labels$ | async"
             [users]="users"
             (editRule)="editRule($event)"
             (updateTableState)="updateTableState($event)"
             (onCreateLabel)="createLabel($event)"
             (onAddLabel)="assignLabelToRule($event)"
             (onRemoveLabel)="removeLabel($event)">
</rules-table>
