<span [class.has-alerts]="count > 0"
      [class.as-menu-button]="type === EAlertBellType.Menu"
      [class.show-bell-always]="showBellAlways"
      matTooltipPosition="below"
      [matTooltip]="tooltipPlaceholder ? tooltipPlaceholder : null"
      (mouseenter)="hovered = true" (mouseleave)="hovered = false" (click)="onClick.emit()"
      class="icon-container">
  <!-- empty bell -->
  <mat-icon [class.display-icon]="status === EAlertBellStatus.NoSubscribedAlerts">
    notifications_none
  </mat-icon>

  <!-- plus (create) bell -->
  <mat-icon [class.display-icon]="status === EAlertBellStatus.CreateAlert"
            matTooltip="Create new Alert">
    add_alert
  </mat-icon>

  <!-- checked bell -->
  <mat-icon [class.display-icon]="status === EAlertBellStatus.ConfiguredAndSubscribedAlerts"
            svgIcon="bell-check"
            class="custom-icon">
  </mat-icon>

  <!-- exclamation ringing bell -->
  <mat-icon [class.display-icon]="status === EAlertBellStatus.TriggeredAlerts"
            svgIcon="bell-filled-triggered"
            class="custom-icon"
            [class.triggered-red]="colorRedOnTriggered">
  </mat-icon>

  <!-- empty exclamation ringing bell -->
  <mat-icon [class.display-icon]="status === EAlertBellStatus.ConfiguredAndTriggeredAlerts"
            svgIcon="bell-empty-triggered"
            class="custom-icon"
            [class.triggered-red]="colorRedOnTriggered">
  </mat-icon>
  <ng-container *ngIf="type === EAlertBellType.Menu">
    <span class="button-name">{{menuName}}</span>
    <mat-icon class="expand-icon display-icon">expand_more</mat-icon>
  </ng-container>
</span>

<span class="count" *ngIf="showCount && count">
 <span *ngIf="count < 10; else asterisk" class="value">{{ count }}</span>
 <ng-template #asterisk>
   <span class="asterisk">*</span>
 </ng-template>
</span>
