import { IWJReportParams } from './../../web-journey-report.models';
import { ActivatedRoute } from '@angular/router';
import { EVarComparisonOutcome } from './../tag-report/wj-results-tag-comparison.enums';
import { userIsAdmin } from '@app/authUtils';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IJourneyResultsVariable } from '@app/components/tag-report/tag-report-table/tag-report-table.models';
import { IWJResultsVariable, WJResultsVariable } from './wj-results-tag-report-table.models';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RorComparisonReportService } from '@app/components/ror-comparison-report/ror-comparison-report.service';
import { ETagReportTableColumns } from './wj-results-tag-report-table.enums';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { varComparisonOutcomeToLabelMap } from '../tag-report/wj-results-tag-comparison.constants';
import { dateRunFormat, timeRunFormat } from '../web-journey-results.constants';
import { WjResultsTagReportTableService } from './wj-results-tag-report-table.service';
import { Subject } from 'rxjs';
import { ITagVariablesDiffDetails, IVarComparisonDetailsDiff } from '../tag-report/wj-results-tag-comparison.models';
import { IWebJourneyRun } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { ComponentChanges } from '@app/models/commons';
import { takeUntil } from 'rxjs/operators';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';
import { WebJourneyReportService } from '../../web-journey-report.service';
import { AccountsService } from '@app/components/account/account.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'wj-results-tag-report-table',
  templateUrl: './wj-results-tag-report-table.component.html',
  styleUrls: ['./wj-results-tag-report-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WjResultsTagReportTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() tagVariables: IJourneyResultsVariable[];
  @Input() comparisonVariables: ITagVariablesDiffDetails;
  @Input() excludedVars?: string[];
  @Input() excludedVarsSnapshot?: string[];
  @Input() showDiffsOnly: boolean;
  @Input() showComparison: boolean;
  @Input() tagId: number;
  @Input() actionId: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  readonly outcomeToLabelMap = varComparisonOutcomeToLabelMap;
  readonly comparisonOutcomes = EVarComparisonOutcome;
  readonly dateFormat = dateRunFormat;
  readonly timeFormat = timeRunFormat;
  readonly basicColumns = [ETagReportTableColumns.variables, ETagReportTableColumns.currentValue];

  displayedColumns = this.basicColumns;
  dataSource = new MatTableDataSource();

  journeyId: number;
  runId: number;

  runs: IWebJourneyRun[];
  previousRun: IWebJourneyRun;
  currentRun: IWebJourneyRun;

  isCurrentUserAdmin = false;

  variableNameToVariable = new Map<string, IJourneyResultsVariable>();

  private destroy$ = new Subject();

  constructor(private snackBar: MatSnackBar,
              private route: ActivatedRoute,
              private accountsService: AccountsService,
              private comparisonReportService: RorComparisonReportService,
              private webJourneyReportService: WebJourneyReportService,
              private tagReportTableService: WjResultsTagReportTableService) { }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: IWJReportParams) => {
        this.journeyId = +params.journeyId;
        this.runId = +params.runId;

        if (!this.runs) this.init();
        else this.setCurrentAndPreviousRuns();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnChanges(changes: ComponentChanges<WjResultsTagReportTableComponent>) {
    if (changes.tagVariables?.currentValue !== changes.tagVariables?.previousValue) {
      this.variableNameToVariable = ArrayUtils.toMap(changes.tagVariables.currentValue, 'name');
    }
    this.buildDisplayedColumns();
    this.prepareTableData();
  }

  private init() {
    this.webJourneyReportService.getJourneyRuns(this.journeyId).subscribe(runs => {
      this.runs = runs.sort(
        (run1, run2) => new Date(run1.completedAt).getTime() - new Date(run2.completedAt).getTime()
      );
      if (runs.length > 1) {
        this.setCurrentAndPreviousRuns();
      }
    });

    this.accountsService.getUser().subscribe(user => {
      this.isCurrentUserAdmin = userIsAdmin(user);
      this.buildDisplayedColumns();
      this.prepareTableData();
    });

    this.tagReportTableService.sortByProp$
      .pipe(takeUntil(this.destroy$))
      .subscribe(({propName, direction}) => {
        this.onMatSortChange(propName, direction);
      });
  }

  private setCurrentAndPreviousRuns() {
    const runIndex = this.runs.findIndex(r => r.id === this.runId);
    this.currentRun = this.runs[runIndex];
    this.previousRun = this.runs[runIndex - 1];
  }

  onMatSortChange(propName: keyof IWJResultsVariable, sortDirection: ESortDirection): void {
    const vars = this.dataSource.data as IWJResultsVariable[];

    if (propName === 'name') {
      this.dataSource.data = ArrayUtils.collatorCompare(vars, a => a['name'], sortDirection);
      return;
    }

    this.dataSource.data = ArrayUtils.sortBy(vars, propName, sortDirection);
  }

  excludeVariable(variable: IJourneyResultsVariable): void {
    this.comparisonReportService.excludeVariable(this.tagId, variable.name).subscribe(() => {
      // TODO: extend IJourneyResultsVariable or create new model
      (variable as any).excluded = true;
      this.showVarExclusionMessage(`Variable has been excluded in account level comparison settings`);
    }, () => {
      this.showVarExclusionMessage(`Failed to exclude variable`);
    });
  }

  private prepareTableData(): void {
    this.dataSource.data = this.showDiffsOnly ? this.getOnlyDiffs() : this.mergeAllVariables();
    this.dataSource.sort = this.sort;
    this.onMatSortChange('name', ESortDirection.asc);
  }

  private buildDisplayedColumns(): void {
    if (!this.showComparison) {
      this.displayedColumns = this.basicColumns;
      return;
    }

    this.displayedColumns = this.isCurrentUserAdmin ?
      Object.keys(ETagReportTableColumns) as ETagReportTableColumns[] :
      Object.keys(ETagReportTableColumns).filter(col => col !== ETagReportTableColumns.excludeVariable) as ETagReportTableColumns[];
  }

  private showVarExclusionMessage(message: string): void {
    this.snackBar.open(
      message,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom', panelClass: 'excluded-var-snackbar' }
    );
  }

  private getOnlyDiffs(): IWJResultsVariable[] {
    const varsDiffs = this.comparisonVariables?.variablesDifferences || [];
    return varsDiffs.map(diff => {
      const isExcludedVarSnapshot = this.excludedVarsSnapshot?.includes(diff.variableName) || false;
      return new WJResultsVariable(
        diff.variableName,
        this.getPreviousValue(diff),
        this.getActualValue(diff),
        this.isVarExcluded(diff.variableName),
        this.getVarOutcome(diff.outcome, isExcludedVarSnapshot),
        this.variableNameToVariable.get(diff.variableName)?.description
      )}
    );
  }

  private mergeAllVariables(): IWJResultsVariable[] {
    const varsWithDiff = this.getOnlyDiffs();
    const noDiffPredicate = this.showComparison ?
      tVar => !varsWithDiff.find(diff => diff.name === tVar.name) :
      _ => true;
    const varsWithoutDiff = this.tagVariables
      .filter(noDiffPredicate)
      .map(tVar => {
        const isExcludedVarSnapshot = this.excludedVarsSnapshot?.includes(tVar.name) || false;
        return new WJResultsVariable(
          tVar.name,
          (this.showComparison && !isExcludedVarSnapshot) ? tVar.value : '—',
          tVar.value,
          this.isVarExcluded(tVar.name),
          this.showComparison ? this.getVarOutcome(EVarComparisonOutcome.match, isExcludedVarSnapshot) : '—',
          tVar.description);
        });
    return this.showComparison ? varsWithDiff.concat(varsWithoutDiff) : varsWithoutDiff;
  }

  private getPreviousValue(diff: IVarComparisonDetailsDiff): string {
    const isAdded = [EVarComparisonOutcome.variableAdded, EVarComparisonOutcome.tagAdded].includes(diff.outcome);
    return isAdded || !this.showComparison ?
      '—' :
      diff.expectedValue;
  }

  private getActualValue(diff: IVarComparisonDetailsDiff): string {
    const isAbsent = [EVarComparisonOutcome.variableAbsent, EVarComparisonOutcome.tagAbsent].includes(diff.outcome);
    return isAbsent || !this.showComparison ?
    '—' :
    diff.actualValue;
  }

  private isVarExcluded(variableName: string): boolean {
    return this.excludedVars?.includes(variableName) || false;
  }

  private getVarOutcome(outcome: EVarComparisonOutcome, isExcludedVarSnapshot = false): string {
    return isExcludedVarSnapshot ? 'Not Compared' : this.outcomeToLabelMap.get(outcome);
  }
}
