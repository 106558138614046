<div class="admin-tags">
  <mat-button-toggle-group #group="matButtonToggleGroup" [hideSingleSelectionIndicator]="true" [value]="views.Customer">
    <mat-button-toggle [value]="views.Customer">Customer Tags</mat-button-toggle>
    <mat-button-toggle [value]="views.Regular">Regular Tags</mat-button-toggle>
  </mat-button-toggle-group>
  <div class="view-container">
    <div class="view" *ngIf="group.value === views.Customer">
      <admin-tags-grid [tags]="allCustomerTags" [enabledTags]="enabledTags" (updated)="saveTags.emit($event)"></admin-tags-grid>
    </div>
    <div class="view" *ngIf="group.value === views.Regular">
      <admin-tags-grid [tags]="allRegularTags" [enabledTags]="enabledTags" (updated)="saveTags.emit($event)"></admin-tags-grid>
    </div>
  </div>
</div>