import { Component, OnInit, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lock-overlay',
  templateUrl: './lock-overlay.component.html',
  styleUrls: ['./lock-overlay.component.scss']
})
export class LockOverlayComponent {

  @Input() isLocked: boolean;
  @Input() headerText: string;
  @Input() headerSubtext: string;

}
