import {
  CookieInventoryCookieRow,
  CookieTagInitiatorRow,
  EOriginType,
} from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.models';

export function getCookieOriginData(origins: EOriginType[]): CookieInventoryCookieRow['origin'] {
  if (!origins || origins.length === 0) {
    return {
      text: '---',
      tooltip: '',
    };
  }

  if (origins.length === 1) {
    return {
      text: EOriginType[origins[0]],
      tooltip: `Origin: ${EOriginType[origins[0]]}`,
    };
  }

  return {
    text: `${origins.length}`,
    tooltip: `${origins.length} unique origins: ${origins.map(o => EOriginType[o]).join(', ')}`,
    icon: 'info_outline',
  };
}

export function getTagInitiatorOriginData(origins: EOriginType[]): CookieTagInitiatorRow['origin'] {
  if (!origins || origins.length === 0) {
    return {
      text: '---',
    };
  }

  if (origins.length === 1) {
    return {
      text: EOriginType[origins[0]],
    };
  }

  return {
    text: origins.map(o => EOriginType[o]).join(', '),
  };
}
