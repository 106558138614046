<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" [(ngModel)]="filterByUrl" placeholder="Search Pages">
</mat-form-field>

<div class="title">Missing {{variableName}}</div>

<h6>{{dataSource?.filteredData?.length}} item{{dataSource?.filteredData?.length > 1 ? 's' : ''}}</h6>

<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="url">
      <th mat-header-cell *matHeaderCellDef> URL </th>
      <td mat-cell *matCellDef="let variable"
          matTooltipPosition="above"
          matTooltipClass="details"
          [matTooltip]="'If: ' + variable.url + '&#13;' + variable.details.alignmentVariables">
        {{variable.url}} 
      </td>
    </ng-container>

    <ng-container matColumnDef="expectedValue">
      <th mat-header-cell *matHeaderCellDef> PREVIOUS VALUE </th>
      <td mat-cell *matCellDef="let variable"> {{variable.details.expectedValue}} </td>
    </ng-container>

    <ng-container matColumnDef="actualValue">
      <th mat-header-cell *matHeaderCellDef> CURRENT VALUE </th>
      <td mat-cell *matCellDef="let variable"> {{variable.details.actualValue}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>

  </table>
</div>
