import {Names} from '@app/moonbeamConstants';
import * as angular from 'angular';
import {DeleteDomainController} from './domain/deleteDomainController';
import {DeleteFolderController} from './folder/deleteFolderController';
import {DeleteWebJourneyController} from './webjourney/deleteWebJourneyController';


export default angular.module('deleteModule', [])
  .controller(Names.Controllers.deleteDomainController, DeleteDomainController)
  .controller(Names.Controllers.deleteFolderController, DeleteFolderController)
  .controller(Names.Controllers.deleteWebJourneyController, DeleteWebJourneyController)
  .name
