<mat-spinner *ngIf="showMainSpinner" [diameter]="75" [strokeWidth]="4" class="main-spinner centered"></mat-spinner>
<full-screen-status-banner *ngIf="showFullScreenStatusBanner"
                           [runNowPending]="runNowPending"
                           (runNowClicked)="runNow()"
                           updatesType="WebJourney">
</full-screen-status-banner>
<div class="loading-error" *ngIf="!loadingJourneyState && showLoadingErrorMessage && !showFullScreenStatusBanner">There was an error loading the data. Please refresh the page to try again.</div>
<div *ngIf="results" class="flex-col web-journey-results-wrap">
  <div class="journey-status-wrap">
    <p class="step-name">
        Action {{ currentStep }} of {{ actions.length }}
        <span *ngIf="currentAction?.action">:
          {{ currentAction?.action?.label || defaultActions[currentAction?.action?.action] }}
        </span>
    </p>
  </div>
  <div class="flex-row columns">
    <action-selector [actions]="actions"
                     [defaultActions]="defaultActions"
                     [currentStep]="currentStep"
                     (stepSelected)="onStepSelected($event)"
                     (clickToEnlarge)="onClickToEnlarge()">
    </action-selector>
    <div class="flex-col web-journey-results-tabs">
      <mat-tab-group [animationDuration]="0" [selectedIndex]="currentTab" (selectedIndexChange)="onTabSelected($event)">
        <mat-tab label="Action Details"
                 labelClass="tab-label-one"
                 op-selector="details-tab">
          <ng-template mat-tab-label>
            <mat-icon *ngIf="!currentAction?.success" class="action-failure">cancel</mat-icon>
            Action Details
          </ng-template>
          <ng-template matTabContent>
            <action-failure-message
              *ngIf="currentAction?.failureMessage"
              [currentAction]="currentAction"
              [journeyId]="journeyId"
              [runId]="runId"
            ></action-failure-message>
            <div class="actions mat-elevation-z2">
              <div class="action-heading">
                <span>Action</span>
                <mat-icon *ngIf="!isReadOnly" (click)="editAction()">edit</mat-icon>
              </div>
              <div class="action-body action-body-details">
                <table class="action-table">
                  <tr *ngIf="currentAction?.action?.action">
                    <td class="action-body-item-name">Action Type:</td>
                    <td>{{ defaultActions[currentAction?.action?.action] }}</td>
                  </tr>
                  <tr *ngIf="currentAction?.action?.url">
                    <td class="action-body-item-name">URL:</td>
                    <td class="navigate-to-url-row">
                      <span class="navigate-to-url"
                          [matTooltip]="currentAction?.action?.url"
                          matTooltipPosition="above"
                          matTooltipClass="page-redirect-url-tooltip">
                        {{ currentAction?.action?.url }}
                      </span>

                      <mat-icon (click)="goToUrl(currentAction?.action?.url)" matTooltip="View webpage in new window">open_in_new</mat-icon>

                      <mat-icon matTooltip="Copy to clipboard"
                                matTooltipPosition="above"
                                ngxClipboard
                                [cbContent]="currentAction?.action?.url">
                        content_copy
                      </mat-icon>
                    </td>
                  </tr>
                  <tr *ngIf="currentAction?.action?.selectors && currentAction?.action.selectors?.length">
                    <td class="action-body-item-name">Selector Value:</td>
                    <td>{{ currentAction?.action?.selectors[0].value }}</td>
                  </tr>
                  <tr *ngIf="currentAction?.action?.value">
                    <td class="action-body-item-name">Value:</td>
                    <td>{{ currentAction?.action?.value }}</td>
                  </tr>
                  <tr *ngIf="currentAction?.action?.js">
                    <td class="action-body-item-name" valign="top">JavaScript:</td>
                    <td class="javascript">
                      <op-code-highlighter
                        label="JavaScript"
                        [value]="currentAction?.action?.js"
                        [readonly]="true"
                        [fullScreenModalTitle]="getActionLabel(currentAction?.action)"
                      ></op-code-highlighter>
                    </td>
                  </tr>
                  <tr *ngIf="currentAction?.action?.seconds">
                    <td class="action-body-item-name">Watch Time:</td>
                    <td>{{ currentAction?.action?.seconds }} seconds</td>
                  </tr>
                  <tr *ngIf="currentAction?.action?.preventNavigation">
                    <td class="action-body-item-name">Prevent Navigation:</td>
                    <td>{{ currentAction?.action?.preventNavigation }}</td>
                  </tr>
                  <tr *ngIf="currentAction?.action?.label">
                    <td class="action-body-item-name">Label:</td>
                    <td>{{ currentAction?.action?.label }}</td>
                  </tr>
                  <tr *ngIf="currentAction?.timestamp">
                    <td class="action-body-item-name">Time Stamp:</td>
                    <td>{{ beautifyTimestamp(currentAction?.timestamp) }}</td>
                  </tr>
                  <tr *ngIf="currentAction?.action?.waitDuration">
                    <td class="action-body-item-name">Additional Wait Time:</td>
                    <td>{{ currentAction?.action?.waitDuration }} seconds</td>
                  </tr>
                  <tr *ngIf="currentAction?.startPageUrl && currentStep !== 1">
                    <td class="action-body-item-name">Action Start URL:</td>
                    <td class="navigate-to-url-row">
                      <span class="navigate-to-url"
                          [matTooltip]="currentAction?.startPageUrl"
                          matTooltipPosition="above"
                          matTooltipClass="page-redirect-url-tooltip">
                        {{ currentAction?.startPageUrl }}
                      </span>

                      <mat-icon (click)="goToUrl(currentAction?.startPageUrl)" matTooltip="View webpage in new window">open_in_new</mat-icon>

                      <mat-icon matTooltip="Copy to clipboard"
                                matTooltipPosition="above"
                                ngxClipboard
                                [cbContent]="currentAction?.startPageUrl">
                        content_copy
                      </mat-icon>
                    </td>
                  </tr>
                  <tr *ngIf="currentAction?.finishPageUrl">
                    <td [ngClass]="{ 'no-padding': isUrlSame() }" class="action-body-item-name">Action Finish URL:</td>
                    <td [ngClass]="{ 'no-padding': isUrlSame() }" class="navigate-to-url-row">
                      <span class="navigate-to-url"
                          [matTooltip]="currentAction?.finishPageUrl"
                          matTooltipPosition="above"
                          matTooltipClass="page-redirect-url-tooltip">
                        {{ currentAction?.finishPageUrl }}
                      </span>

                      <mat-icon (click)="goToUrl(currentAction?.finishPageUrl)" matTooltip="View webpage in new window">open_in_new</mat-icon>

                      <mat-icon matTooltip="Copy to clipboard"
                                matTooltipPosition="above"
                                ngxClipboard
                                [cbContent]="currentAction?.finishPageUrl">
                        content_copy
                      </mat-icon>
                    </td>
                  </tr>
                  <tr *ngIf="isUrlSame() && currentStep !== 1">
                    <td class="small-notation">&nbsp;</td>
                    <td class="small-notation">
                      <span>
                        (start and finish urls are the same)
                      </span>
                    </td>
                  </tr>
                  <tr *ngIf="currentAction?.action?.failureMessage">
                    <td class="action-body-item-name">Failure Message:</td>
                    <td class="action-failure-message">{{ currentAction?.action?.failureMessage }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div *ngIf="currentAction?.generatedActions && currentAction?.generatedActions?.length"
              class="generated-actions actions mat-elevation-z2">
              <div class="action-heading">Generated Actions</div>
              <div class="action-body" *ngFor="let action of currentAction?.generatedActions">
                <table>
                  <tr *ngIf="action.action.action">
                    <td>Action Type:</td>
                    <td>{{ action.action.action }}</td>
                  </tr>
                  <tr *ngIf="action.action.url">
                    <td>URL:</td>
                    <td>{{ action.action.url }}</td>
                  </tr>
                  <tr *ngIf="action.timestamp">
                    <td>Time Stamp:</td>
                    <td>{{ beautifyTimestamp(currentAction?.timestamp) }}</td>
                  </tr>
                  <tr *ngIf="currentAction?.startPageUrl">
                    <td class="action-body-item-name">Action Start URL:</td>
                    <td class="navigate-to-url-row">
                      <span class="navigate-to-url"
                          [matTooltip]="currentAction?.startPageUrl"
                          matTooltipPosition="above"
                          matTooltipClass="page-redirect-url-tooltip">
                        {{ currentAction?.startPageUrl }}
                      </span>

                      <mat-icon (click)="goToUrl(currentAction?.startPageUrl)" matTooltip="View webpage in new window">open_in_new</mat-icon>

                      <mat-icon matTooltip="Copy to clipboard"
                                matTooltipPosition="above"
                                ngxClipboard
                                [cbContent]="currentAction?.startPageUrl">
                        content_copy
                      </mat-icon>
                    </td>
                  </tr>
                  <tr *ngIf="currentAction?.finishPageUrl">
                    <td [ngClass]="{ 'no-padding': isUrlSame() }" class="action-body-item-name">Action Finish URL:</td>
                    <td [ngClass]="{ 'no-padding': isUrlSame() }" class="navigate-to-url-row">
                      <span class="navigate-to-url"
                          [matTooltip]="currentAction?.finishPageUrl"
                          matTooltipPosition="above"
                          matTooltipClass="page-redirect-url-tooltip">
                        {{ currentAction?.finishPageUrl }}
                      </span>

                      <mat-icon (click)="goToUrl(currentAction?.finishPageUrl)" matTooltip="View webpage in new window">open_in_new</mat-icon>

                      <mat-icon matTooltip="Copy to clipboard"
                                matTooltipPosition="above"
                                ngxClipboard
                                [cbContent]="currentAction?.finishPageUrl">
                        content_copy
                      </mat-icon>
                    </td>
                  </tr>
                  <tr *ngIf="isUrlSame()">
                    <td class="small-notation">&nbsp;</td>
                    <td class="small-notation">
                      <span>
                        (start and finish urls are the same)
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="action-details">
              <div class="expander-container" *ngIf="currentAction?.fileMappingResults?.length">
                <op-expander title="File Substitution">
                  <div class="remote-file-mapping actions">
                    <div class="mapping mapping-{{ i + 1 }}" *ngFor="let action of currentAction?.fileMappingResults; let i = index">
                      <table>
                        <tr *ngIf="currentAction?.fileMappingResults?.length > 1">
                          <td>Mapping #{{ i + 1 }}</td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td>Match Found:</td>
                          <td *ngIf="action.mappedRequests.length" class="flex-align">
                            Yes
                            <mat-icon class="match-found">check_circle</mat-icon>
                          </td>
                          <td *ngIf="!action.mappedRequests.length" class="flex-align">
                            No
                            <mat-icon class="no-match">cancel</mat-icon>
                          </td>
                        </tr>
                        <tr>
                          <td>Match Type:</td>
                          <td>{{ action.config.matchType }}</td>
                        </tr>
                        <tr>
                          <td>Match Value:</td>
                          <td>{{ action.config.matchValue }}</td>
                        </tr>
                        <tr>
                          <td>Replacement File Name:</td>
                          <td>{{ action.config.fileUrl }}</td>
                        </tr>
                        <tr *ngIf="action.mappedRequests.length">
                          <td valign="top">Matched Request{{ action.mappedRequests.length > 1 ? 's' : '' }}:</td>
                          <td>
                            <p *ngFor="let request of action.mappedRequests">
                              {{ request }}
                            </p>
                          </td>
                        </tr>
                      </table>
                      <mat-divider></mat-divider>
                  </div>
                  </div>
                </op-expander>
              </div>
            </div>
            <div class="action-details">
              <div class="expander-container">
                <op-expander [title]="requestExpanderTitle" [isExpanded]="isRequestLogExpanded" (opened)="handleRequestLogOpened()">
                  <request-log-table [itemId]="journeyId"
                                     [runId]="runId"
                                     [actionIndex]="currentStep - 1"
                                     [success]="currentAction?.success"
                                     [reportType]="'webJourney'"
                                     [state]="state"
                                     [loading]="loadingRequestLogs"
                                     [renderTable]="isRequestLogExpanded">
                  </request-log-table>
                </op-expander>
              </div>
              <div class="expander-container">
                <op-expander [title]="consoleLogExpanderTitle">
                  <console-log-table [itemId]="journeyId"
                                     [runId]="runId"
                                     [actionIndex]="currentStep - 1"
                                     [success]="currentAction?.success"
                                     [reportType]="'webJourney'"
                                     [loading]="loadingConsoleLogs">
                  </console-log-table>
                </op-expander>
              </div>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="Tag Presence ({{ tagCount$ | async }})"
                 labelClass="tab-label-one"
                 op-selector="tag-comparison-tab">
          <wj-results-tag-comparison [action]="currentAction"
                                     [primaryTagFilteringDisabled]="primaryTagFilteringDisabled"
                                     [primaryTagsToggleValue]="primaryTagsToggleValue"
                                     (onPrimaryTagsToggleValueChange)="handlePrimaryTagsToggleValueChange($event)"
          ></wj-results-tag-comparison>
        </mat-tab>
        <mat-tab label="Variable Summary"
                 labelClass="tab-label-one"
                 op-selector="tags-tab">
          <ng-template matTabContent>
            <wj-results-tag-report *ngIf="currentAction"
                                   [action]="currentAction"
                                   [canAddRule]="canAddRule"
                                   [isReadOnly]="isReadOnly"
                                   [primaryTagFilteringDisabled]="primaryTagFilteringDisabled"
                                   [primaryTagsToggleValue]="primaryTagsToggleValue"
                                   (onPrimaryTagsToggleValueChange)="handlePrimaryTagsToggleValueChange($event)"
                                   (onAddRule)="onAddRule($event)">
            </wj-results-tag-report>
          </ng-template>
        </mat-tab>
        <mat-tab [label]="cookieTabTitle"
                 labelClass="tab-label-one"
                 op-selector="cookies-tab">
          <cookies-table *ngIf="currentTab === EWJResultsTab.Cookies"
                         [itemId]="journeyId"
                         [runId]="runId"
                         [actionIndex]="currentStep - 1"
                         [success]="currentAction?.success"
                         [reportType]="EReportType.WEB_JOURNEY"
                         [stepLoaded$]="stepLoaded$"
                         (navToTab)="handleNavToTab($event)"
                         (navToAction)="handleNavToAction($event)"
                         (cookieCount)="updateCookieCount($event)">
          </cookies-table>
        </mat-tab>
        <mat-tab op-selector="rules-tab"
                 labelClass="tab-label-one"
        >
          <ng-template mat-tab-label>
            <mat-icon *ngIf="currentAction?.failedRuleReports?.length" class="rule-failure">error</mat-icon>
            Rules ({{ totalRuleCount }})
          </ng-template>
          <ng-template matTabContent>
            <div class="rules-container">
              <rules-report [failed]="currentAction?.failedRuleReports"
                            [notApplied]="currentAction?.notAppliedRuleReports"
                            [passed]="currentAction?.passedRuleReports"
                            [showEditButtons]="canEditRules && !isReadOnly"
                            (removeRule)="onRemoveRule($event)"
                            (editRule)="onEditRule($event)"
                            (setAsExpected)="onSetAsExpected($event)"
                            (deleteVariable)="onDeleteVariable($event)">
              </rules-report>
              <div class="add-rules-to-action-btn" *ngIf="!isReadOnly">
                <button op-selector="add-rules-to-action-btn" mat-flat-button color="primary" (click)="assignRulesToAction()">
                  <mat-icon>add</mat-icon>add rules
                </button>
                <mat-spinner *ngIf="showRulesSpinner" [diameter]="25" [strokeWidth]="4" class="fix-spinner"></mat-spinner>
              </div>
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
