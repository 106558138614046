<form class="flex-form journey-setup-form" [formGroup]="webJourneySetupForm" formName="webJourneySetupForm" [submitted]="submitted" validate-form>
  <div class="journey-setup-center-text">{{ JourneyCenterText }} <a class="learn-more-link" [href]="LEARN_MORE.centerText" target="_blank">Learn more.</a></div>

  <div class="two-controls">
    <div class="form-input name-wrp"
         #nameInput
         (mouseleave)="hideHint(nameInput)"
         (mouseenter)="displayHint(nameInput)">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label class="form-label">Journey Name</mat-label>
        <input matInput placeholder="Enter a name for this journey" formControlName="name" [attr.op-selector]="OP_SELECTORS.name">
        <mat-hint *ngIf="!name.invalid" class="field-hint">Name what this journey will be validating</mat-hint>
      </mat-form-field>
      <op-error-message formControlPath="webJourneySetupForm.name"></op-error-message>
    </div>

    <div class="form-input no-bottom-padding">
      <op-folder-and-sub-folder-field formControlName="folderData"
                                   [itemType]="EDataSourceType.WebJourney"
                                   [submitted]="submitted"
      >
      </op-folder-and-sub-folder-field>
    </div>
  </div>

  <div *ngIf="recurrenceEnabled" class="recurrence-component-wrap">
    <op-recurrence
      [itemType]="ERecurrenceItemType.Journey"
      [parentFormField]="recurrence"
      [recurrence]="recurrenceSchedule"
    ></op-recurrence>
  </div>

  <div class="two-controls" *ngIf="!recurrenceEnabled">
    <div #frequencySelect
         class="form-input"
         (mouseleave)="hideHint(frequencySelect)"
         (mouseenter)="displayHint(frequencySelect)"
    >
      <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.frequency">
        <mat-label>How often should the journey run?</mat-label>
        <mat-select formControlName="frequency">
          <mat-option *ngFor="let frequency of frequencies" [value]="frequency.key">{{ frequency.label }}</mat-option>
        </mat-select>
      </mat-form-field>
      <span class="field-hint">If set to run more than once, actions on pages with new code might need to be updated before any subsequent runs to avoid failures.</span>
    </div>
    <div class="form-input"
         #startingTimeInput
         (mouseleave)="hideHint(startingTimeInput)"
         (mouseenter)="displayHint(startingTimeInput)">
      <div class="two-controls">
        <div class="form-input nested-form-input">
          <mat-form-field appearance="outline" floatLabel="always" class="run-date no-hint-container" [attr.op-selector]="OP_SELECTORS.nextRunDate">
            <mat-label>{{ isEditMode ? FIELD_LABELS.NEXT_DATE : FIELD_LABELS.INITIAL_DATE}}</mat-label>
            <input matInput [min]="minStartDate" [matDatepicker]="picker" formControlName="startingDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <op-error-message formControlPath="webJourneySetupForm.startingDate"></op-error-message>
        </div>
        <div class="form-input nested-form-input date-controls">
          <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.nextRunTime">
            <mat-label>{{isEditMode ? FIELD_LABELS.NEXT_TIME : FIELD_LABELS.INITIAL_TIME}}</mat-label>
            <input matInput type="time" formControlName="startingTime" (focus)="onTimeFocus('startingTime')">
          </mat-form-field>
          <op-error-message formControlPath="webJourneySetupForm.startingTime"></op-error-message>
        </div>
      </div>
      <mat-hint *ngIf="!startingDate.invalid && !startingTime.invalid" class="field-hint">Specify when the journey should start.</mat-hint>
    </div>
  </div>

  <div class="additional-setup-divider">
    <button mat-button type="button" (click)="toggleAdditionalSetupView()">
      Additional Setup Options <mat-icon class="down-arrow" iconPositionEnd [class.flip-vertical]="showAdditionalSetupOptions">expand_more</mat-icon>
    </button>
  </div>

  <div class="additional-setup-options-container" [class.not-visible]="!showAdditionalSetupOptions">
    <div class="two-controls" *ngIf="locations.length > 0">
      <div class="form-input labels-control"
           [attr.op-selector]="OP_SELECTORS.labels"
      >
        <div class="label-manager-wrap">
          <div class="faux-form-label">Labels</div>
          <op-label-manager [selectedLabels]="selectedLabels"
                            (onLabelCreated)="handleLabelCreated($event)"
                            (onLabelSelected)="handleLabelSelected($event)"
                            (onLabelRemoved)="handleLabelRemoved($event)"
          >
          </op-label-manager>
          <div class="field-hint">Add labels to quickly categorize and identify your journey. <a class="learn-more-link" [href]="LEARN_MORE.labels" target="_blank">Learn more.</a></div>
        </div>
      </div>

      <div class="form-input"
           #proxySupportSelect
           (mouseleave)="hideHint(proxySupportSelect)"
           (mouseenter)="displayHint(proxySupportSelect)"
           [matTooltip]="vpn.value ? 'VPN can only be enabled on Journeys running from USA, Oregon' : null"
           matTooltipPosition="above"
      >
        <mat-form-field floatLabel="always"
                        appearance="outline"
                        class="no-hint-container"
                        [attr.op-selector]="OP_SELECTORS.customVpn"
                        *ngIf="customProxySupport && customProxy.value"
        >
          <mat-label>{{FIELD_LABELS.CUSTOM_PROXY}}</mat-label>
          <input matInput formControlName="location" placeholder="ie, http://90.90.90.90:8080">
        </mat-form-field>

        <!-- LOCATION FIELD (proxy) -->
        <mat-form-field appearance="outline"
                        class="no-hint-container"
                        [attr.op-selector]="OP_SELECTORS.vpn"
                        *ngIf="!customProxySupport || (customProxySupport && !customProxy.value)"
                        floatLabel="always"
        >
          <mat-label>{{FIELD_LABELS.RUN_FROM}}</mat-label>
          <mat-select [formControl]="location" #locationSelect panelClass="location-drop-down-panel">
            <mat-select-trigger class="location-input">
              <div class="flag-icon flag-icon-{{selectedLocation?.countryCode}}"></div>
              <div class="location-label">{{selectedLocation?.label}}</div>
            </mat-select-trigger>
            <div>
              <mat-option class="location-search-field">
                <ngx-mat-select-search [formControl]="locationFilterCtrl" placeholderLabel="Search"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let location of filteredLocations | async" [value]="location.name" class="proxy-autocomplete">
                <div>
                  <div class="flag-icon flag-icon-{{location.countryCode}}"></div>
                  <div class="location-label">{{ location.label }}</div>
                </div>
              </mat-option>
            </div>
          </mat-select>
        </mat-form-field>
        <span *ngIf="!location.invalid" class="field-hint" [class.feature-disabled]="vpn.value">Changing this is useful if there are geo-based content or restrictions on the site.<button class="learn-more-link" type="button" (click)="onLocationChange(true)" *ngIf="customProxySupport && !this.customProxy.value">&nbsp;A custom proxy can also be used.</button><button type="button" class="learn-more-link" (click)="onLocationChange(false)" *ngIf="customProxySupport && this.customProxy.value">&nbsp;Select an existing proxy.</button>&nbsp;&nbsp;<a class="learn-more-link" [href]="LEARN_MORE.proxy" target="_blank">Learn more.</a></span>
        <op-error-message [relativePosition]="true" formControlPath="webJourneySetupForm.location"></op-error-message>
      </div>
    </div>

    <div class="two-controls">
      <div class="form-input"
           #userAgent
           (mouseleave)="hideHint(userAgent)"
           (mouseenter)="displayHint(userAgent)">
        <mat-form-field appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.userAgent">
          <mat-label>{{FIELD_LABELS.USER_AGENT}}</mat-label>
          <mat-select formControlName="userAgent"
                      [panelClass]="'user-agent-popover'"
          >
            <mat-option *ngFor="let agent of userAgents" [value]="agent.name">{{ agent.label }}</mat-option>
          </mat-select>
          <mat-hint class="field-hint">Specify a user agent. <a class="learn-more-link" [href]="LEARN_MORE.userAgent" target="_blank">Learn More</a>.</mat-hint>
        </mat-form-field>
      </div>
      <div class="form-input"
           #browserDimensionsInput
           (mouseleave)="hideHint(browserDimensionsInput)"
           (mouseenter)="displayHint(browserDimensionsInput)">
        <div class='two-controls size-inputs'>
          <div class="form-input nested-form-input override-input">
            <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.browserWidth">
              <mat-label>{{FIELD_LABELS.WIDTH}}</mat-label>
              <input matInput type="number" formControlName="browserWidth" min="0" max="3840"/>
            </mat-form-field>
            <op-error-message formControlPath="webJourneySetupForm.browserWidth"></op-error-message>
          </div>

          <div class="form-input nested-form-input override-input">
            <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.browserHeight">
              <mat-label>{{FIELD_LABELS.HEIGHT}}</mat-label>
              <input matInput type="number" formControlName="browserHeight" min="300" max="4000">
            </mat-form-field>
            <op-error-message formControlPath="webJourneySetupForm.browserHeight"></op-error-message>
          </div>
        </div>
        <mat-hint *ngIf="!browserWidth.invalid && !browserHeight.invalid" class="field-hint">Specify custom width and height of the browser window. This is typically used in conjunction with a mobile browser user agent.</mat-hint>
      </div>
    </div>

    <div class="two-controls">
      <div class="form-input flex-col rfm-container"
           #rfm
           (mouseleave)="hideHint(rfm)"
           (mouseenter)="displayHint(rfm)">
        <mat-form-field appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.fileSubstitution">
          <mat-label>File Substitutions (Remote File Mapping)</mat-label>
          <mat-select formControlName="rfmConfig" multiple>
            <mat-option *ngFor="let mapping of remoteFileMappings" [value]="mapping.id">{{ mapping.name }}</mat-option>
          </mat-select>
          <mat-hint class="field-hint">Substitute one or more files on pages visited. <button class="subtext-link" type="button" (click)="createNewFileSubstition()">Create New File Substitution</button>.  <a class="learn-more-link" [href]="LEARN_MORE.rfm" target="_blank">Learn More</a>.</mat-hint>
        </mat-form-field>
      </div>

      <div class="form-input monitor-journey" [attr.op-selector]="OP_SELECTORS.monitorJourney">
        <mat-slide-toggle formControlName="monitorJourney"
                          (change)="monitorJourneyChanged($event)"
                          color="primary"
                          matTooltip="No more monitored journeys available. Please contact support for more info."
                          [matTooltipDisabled]="!monitorJourney.disabled"
                          matTooltipPosition="above">
        </mat-slide-toggle>
        <div class="monitor-journey-text" [class.dimmed]="!monitoredJourneysLeft">
          <mat-icon>visibility</mat-icon>
          Monitor journey
          <div *ngIf="monitoredJourneysLeft" class="small-text">&nbsp;({{ monitoredJourneysLeft > -1 ? monitoredJourneysLeft + ' left' : 'No limit' }})</div>
          <div class="monitor-learn-more"><a [href]="LEARN_MORE.monitored" target="_blank">&nbsp;Learn more.</a></div>
        </div>
      </div>
    </div>

    <easy-block-tags formControlName="easyBlockTags"></easy-block-tags>

    <div class="two-controls blackout-section-wrapper"
           [matTooltip]="frequency.value === EWebJourneyFrequency.ONCE ? 'Blackout window requires a frequency other than once. Change frequency to enable blackout window.' : ''"
           matTooltipShowDelay="400"
           matTooltipPosition="above"
      >
        <div class="form-input option-toggles no-top-margin blackout-toggle" [attr.op-selector]="OP_SELECTORS.blackoutToggle">
          <mat-slide-toggle class="feature-toggle" formControlName="blackoutEnabled" labelPosition="after" color="primary"><strong class="toggle-label">Add Blackout Window</strong> (Journey will not <em>start</em> during this daily window.) <a class="learn-more-link" href="{{LEARN_MORE.blackout}}" target="_blank">Learn more.</a></mat-slide-toggle>
        </div>
        <div class="form-input no-top-margin blackout-toggle-grouping"
             #blackoutTimeInput
             (mouseleave)="hideHint(blackoutTimeInput)"
             (mouseenter)="displayHint(blackoutTimeInput)">
          <div class="two-controls">
            <div class="form-input nested-form-input">
              <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.blackoutStart">
                <mat-label>{{FIELD_LABELS.START_TIME}}</mat-label>
                <input matInput type="time" formControlName="blackoutStart" (focus)="onTimeFocus('blackoutStart')">
              </mat-form-field>
              <op-error-message formControlPath="webJourneySetupForm.blackoutStart"></op-error-message>
            </div>
            <div class="form-input nested-form-input">
              <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.blackoutEnd">
                <mat-label>{{FIELD_LABELS.END_TIME}}</mat-label>
                <input matInput type="time" formControlName="blackoutEnd" (focus)="onTimeFocus('blackoutEnd')">
              </mat-form-field>
              <op-error-message formControlPath="webJourneySetupForm.blackoutEnd"></op-error-message>
            </div>
          </div>
          <mat-hint class="field-hint" [class.hide-section]="blackoutEnabled.disabled || (blackoutStart.touched && blackoutStart.invalid) || (blackoutEnd.touched && blackoutEnd.invalid)">Specify a blackout window when this journey should not start.</mat-hint>
        </div>
      </div>

    <div class="two-controls more-bottom-padding">
      <div class="form-input option-toggles no-top-margin"
           [class.field-disabled]="vpn.disabled"
           [attr.op-selector]="OP_SELECTORS.vpnToggle"
           [matTooltip]="!vpnSupport ? 'This feature requires VPN support to be enabled' : location.value !== 'mountain' && !vpn.value ? 'VPN can only be enabled on Journeys running from USA, Oregon' : null"
           matTooltipPosition="above">
        <mat-slide-toggle formControlName="vpn"
                          (change)="vpnChange($event)"
                          color="primary"
        >
        </mat-slide-toggle>
        <div class="toggle-text" [class.dimmed]="!vpnSupport">
          <strong class="toggle-label">VPN Mode</strong><span>&nbsp;(Allows outside access to secure content on your website).</span>
          <a class="learn-more-link" [href]="LEARN_MORE.vpn" target="_blank">&nbsp;Learn more.</a>
        </div>
      </div>
    </div>

    <!--  Privacy Section-->
    <div class="form-input">
      <mat-label class="form-label">Privacy Settings</mat-label>
    </div>
    <div class="two-controls privacy-section">
      <div class="form-input"
           [attr.op-selector]="OP_SELECTORS.gpcToggle"
           matTooltip="This feature requires 'Product Line: Privacy' to be enabled"
           [matTooltipDisabled]="privacyFeatureSupport"
           matTooltipPosition="above">
        <div class="privacy-toggle-wrapper">
          <mat-slide-toggle formControlName="gpc"
                            color="primary"
          >
          </mat-slide-toggle>
          <div class="toggle-text" [class.dimmed]="!privacyFeatureSupport">
            <strong class="toggle-label">Send GPC Signal</strong><span>&nbsp;(On all pages visited, the ObservePoint browser will send GPC signal of true).</span>
            <a class="learn-more-link" [href]="LEARN_MORE.gpc" target="_blank">&nbsp;Learn more.</a>
          </div>
        </div>
      </div>
      <div class="form-input"
           [attr.op-selector]="OP_SELECTORS.gpcToggle">
        <div>
          <strong class="toggle-label">CMP Accept All / Reject All</strong> - Consent management "Accept All" or "Reject All" is configured on the Actions tab.
        </div>
        <a class="learn-more-link" (click)="onAddCmpAction.emit()" target="_blank">Add the action now<mat-icon>arrow_forward</mat-icon></a>
      </div>
    </div>
    <div class="two-controls privacy-section">
      <div class="form-input"
           [attr.op-selector]="OP_SELECTORS.blockThirdPartyCookies">
        <div class="privacy-toggle-wrapper">
          <mat-slide-toggle formControlName="blockThirdPartyCookies"
                            color="primary"
          >
          </mat-slide-toggle>
          <div class="toggle-text">
            <strong class="toggle-label">Block 3rd Party Cookies</strong><span> (Enable this setting to block all 3rd party cookies.)</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-input flex-col">
    <mat-label class="form-label">Journey Notifications</mat-label>
    <div class="form-text"><em>Who should be notified if this journey has any action failures?</em> (For back-to-back journey runs any notifications on subsequent failures will not be sent. If there are tag & variable rule failures a separate notice will be sent.)</div>
    <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.notifications">
      <mat-label>Input email addresses (one per line)</mat-label>
      <textarea class="vertical-resize"
                matInput
                rows="2"
                formControlName="recipients"
                [readonly]="hasRecipients.value ? null : 'readonly'">
      </textarea>
    </mat-form-field>
    <op-error-message formControlPath="webJourneySetupForm.recipients" relativePosition="true"></op-error-message>
  </div>

  <div class="form-input webhook-container"
       #webHookInput
       (mouseleave)="hideHint(webHookInput)"
       (mouseenter)="displayHint(webHookInput)">
    <mat-form-field appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.webhook">
      <mat-label>Complete Webhook URL</mat-label>
      <input matInput
             type="text"
             formControlName="webhook">
      <mat-hint *ngIf="!webhook.invalid" class="field-hint">Add a webhook URL.&nbsp;<a class="learn-more-link" [href]="LEARN_MORE.webhook" target="_blank">Learn more.</a></mat-hint>
    </mat-form-field>
    <op-error-message formControlPath="webJourneySetupForm.webhook"></op-error-message>
  </div>

</form>
