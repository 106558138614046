import {
  ISupportContact,
  IUsageContractTerm,
  IFilterableInteger,
  IItemSpecificSummaryBaseUsage,
  IFullSummaryWebJourneySupportUsage,
  IMonetaryAmount,
  ESupportContactType,
  IUsersUsage,
  IUsageTrendsDTO,
  IUsageTrendsPastPeriod,
  IUsageTrendsFuturePeriod,
  IUsageFilterableProjectedUsage,
  IAuditUsage, IWebJourneyUsage, IAccountUsageV2SummaryResponseDTO, IDailyUsageV2
} from '@app/components/usage-v2/usage-v2.models';

export const MockContractTerm: IUsageContractTerm = {
  sequence: 3,
  isCurrent: true,
  startDateInclusive: '2021-03-05',
  endDateInclusive: '2021-03-06'
};

export const MockContractTermPast1: IUsageContractTerm = {
  sequence: 2,
  isCurrent: false,
  startDateInclusive: '2021-03-05',
  endDateInclusive: '2021-09-09',
};

export const MockContractTermPast: IUsageContractTerm = {
  sequence: 1,
  isCurrent: false,
  startDateInclusive: '2020-01-01',
  endDateInclusive: '2021-01-01',
};

export const MockContact: ISupportContact = {
  name: 'John Doe',
  email: 'dylan.sellers@example.com',
  type: ESupportContactType.AE,
};

export const MockContact1: ISupportContact = {
  name: 'Megan Beck',
  email: 'dylan.sellers@example.com',
  type: ESupportContactType.CSM,
};

export const MockUsersUsage: IUsersUsage = {
  totalUsers: 100,
  usersLoggedInLast30Days: [10593, 10524, 440, 95, 88],
};

export const MockFullSummaryWebJourneySupportUsage: IFullSummaryWebJourneySupportUsage = {
  currentlyMonitoredWebJourneys: { total: 50, filtered: 45 },
  maxMonitoredWebJourneys: 100,
  remainingWebJourneySupportRequests: 30,
};

export const MockFilterableInteger: IFilterableInteger = {
  total: 200,
  filtered: 180,
};

export const generateMockMonetaryAmount = (): IMonetaryAmount => ({
  amount: 5000,
  currency: 'USD',
});

export const MockFullSummaryBaseUsageTrait: IItemSpecificSummaryBaseUsage = {
  cumulativeTotal: MockFilterableInteger,
  termLimit: 12,
  cumulativePacing: 80,
  overLimitPrice: generateMockMonetaryAmount(),
  monthlyUsage: [
    {
      month: 'March',
      year: 2023,
      runs: 91,
      pagesScanned: 3775
    },
    {
      month: 'April',
      year: 2023,
      runs: 87,
      pagesScanned: 3531
    },
    {
      month: 'May',
      year: 2023,
      runs: 128,
      pagesScanned: 14825
    },
    {
      month: 'June',
      year: 2023,
      runs: 85,
      pagesScanned: 3604
    },
    {
      month: 'July',
      year: 2023,
      runs: 129,
      pagesScanned: 19729
    },
    {
      month: 'August',
      year: 2023,
      runs: 124,
      pagesScanned: 8787
    },
    {
      month: 'September',
      year: 2023,
      runs: 189,
      pagesScanned: 20139
    },
    {
      month: 'October',
      year: 2023,
      runs: 261,
      pagesScanned: 11402
    },
    {
      month: 'November',
      year: 2023,
      runs: 206,
      pagesScanned: 13321
    },
    {
      month: 'December',
      year: 2023,
      runs: 164,
      pagesScanned: 39764
    },
    {
      month: 'January',
      year: 2024,
      runs: 201,
      pagesScanned: 11864
    },
    {
      month: 'February',
      year: 2024,
      runs: 10,
      pagesScanned: 335
    }
  ]
};

export const MockAuditUsage: IAuditUsage = {
  currentContractTerm: MockContractTerm,
  contractTerms: [MockContractTermPast, MockContractTermPast1, MockContractTerm],
  usage: MockFullSummaryBaseUsageTrait,
  concurrentAudits: 30
};
export const MockWebJourneyUsage: IWebJourneyUsage = {
  currentContractTerm: MockContractTerm,
  contractTerms: [MockContractTermPast, MockContractTermPast1, MockContractTerm],
  usage: MockFullSummaryBaseUsageTrait,
  concurrentWebJourneys: 2232,
  supportUsage: MockFullSummaryWebJourneySupportUsage,
};

export const auditsInCurrentRollingWeek: IDailyUsageV2[] = [
  {
    day: '2024-01-30',
    noOfCompletedRuns:5,
    noOfScheduledRuns: 0,
    completedItems: [
      {
        lastRunId: 97487,
        lastRunCompletedAt : '2024-01-30T12:14:30Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        lastRunId : 97488,
        lastRunCompletedAt : '2024-01-30T12:04:07Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      },
      {
        lastRunId : 97482,
        lastRunCompletedAt : '2024-01-30T10:28:20Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        lastRunId : 97483,
        lastRunCompletedAt : '2024-01-30T10:26:22Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      }
    ],
    scheduledItems : [],
    noOfPagesScanned : 204,
    noOfPagesScheduledToScan : 0
  },
  {
    day: ' 2024-01-31',
    noOfCompletedRuns:7,
    noOfScheduledRuns: 0,
    completedItems: [
      {
        lastRunId : 97707,
        lastRunCompletedAt : '2024-01-31T17:01:33Z',
        noOfRunsCompleted : 1,
        frequency : 'once',
        itemName : 'Ovsiichuk test',
        itemId : 338092
      },
      {
        lastRunId : 97693,
        lastRunCompletedAt : '2024-01-31T15:19:18Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        lastRunId : 97688,
        lastRunCompletedAt : '2024-01-31T12:13:28Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        lastRunId : 97689,
        lastRunCompletedAt : '2024-01-31T12:03:20Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      },
      {
        lastRunId : 97676,
        lastRunCompletedAt : '2024-01-31T10:50:50Z',
        noOfRunsCompleted : 1,
        frequency : 'weekly',
        itemName : 'Customers Home Pages 1000',
        itemId : 299272
      },
      {
        lastRunId : 97680,
        lastRunCompletedAt : '2024-01-31T10:29:39Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        lastRunId : 97679,
        lastRunCompletedAt : '2024-01-31T10:26:40Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      }
    ],
    scheduledItems : [],
    noOfPagesScanned : 1205,
    noOfPagesScheduledToScan : 0
  },
  {
    day: ' 2024-02-01',
    noOfCompletedRuns:8,
    noOfScheduledRuns: 0,
    completedItems: [
      {
        lastRunId : 97837,
        lastRunCompletedAt : '2024-02-01T17:00:17Z',
        noOfRunsCompleted : 1,
        frequency : 'once',
        itemName : 'Ovsiichuk test',
        itemId : 338092
      },
      {
        lastRunId : 97820,
        lastRunCompletedAt : '2024-02-01T15:20:35Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        lastRunId : 97785,
        lastRunCompletedAt : '2024-02-01T12:13:09Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        lastRunId : 97786,
        lastRunCompletedAt : '2024-02-01T12:03:07Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      },
      {
        lastRunId : 97780,
        lastRunCompletedAt : '2024-02-01T10:34:14Z',
        noOfRunsCompleted : 1,
        frequency : 'weekly',
        itemName : 'User Session Failure with CNN Page 30',
        itemId : 301342
      },
      {
        lastRunId : 97779,
        lastRunCompletedAt : '2024-02-01T10:33:59Z',
        noOfRunsCompleted : 1,
        frequency : 'weekly',
        itemName : 'User Session with CNN Page',
        itemId : 274115
      },
      {
        lastRunId : 97778,
        lastRunCompletedAt : '2024-02-01T10:29:41Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        lastRunId : 97777,
        lastRunCompletedAt : '2024-02-01T10:26:59Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      }
    ],
    scheduledItems : [],
    noOfPagesScanned : 237,
    noOfPagesScheduledToScan : 0
  },
  {
    day: ' 2024-02-02',
    noOfCompletedRuns:2,
    noOfScheduledRuns: 3,
    completedItems: [
      {
        lastRunId : 97911,
        lastRunCompletedAt : '2024-02-02T10:28:01Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        lastRunId : 97912,
        lastRunCompletedAt : '2024-02-02T10:27:43Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      }
    ],
    scheduledItems : [
      {
        nextScheduledRunDateTime: '2024-02-02T15:13:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 5,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        nextScheduledRunDateTime: '2024-02-02T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 100,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        nextScheduledRunDateTime: '2024-02-02T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      }
    ],
    noOfPagesScanned : 98,
    noOfPagesScheduledToScan : 106
  },
  {
    day: ' 2024-02-03',
    noOfCompletedRuns:0,
    noOfScheduledRuns: 5,
    completedItems: [],
    scheduledItems : [
      {
        nextScheduledRunDateTime: '2024-02-03T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        nextScheduledRunDateTime: '2024-02-03T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        nextScheduledRunDateTime: '2024-02-03T15:13:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 5,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        nextScheduledRunDateTime: '2024-02-03T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 100,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        nextScheduledRunDateTime: '2024-02-03T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      }
    ],
    noOfPagesScanned : 0,
    noOfPagesScheduledToScan : 204
  },
  {
    day: ' 2024-02-04',
    noOfCompletedRuns:0,
    noOfScheduledRuns: 7,
    completedItems: [],
    scheduledItems : [
      {
        nextScheduledRunDateTime: '2024-02-04T13:57:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 10,
        frequency : 'weekly',
        itemName : 'audit with rules Chrome',
        itemId : 152789
      },
      {
        nextScheduledRunDateTime: '2024-02-04T13:57:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 10,
        frequency : 'weekly',
        itemName : 'Audit with On Page Action Failure',
        itemId : 301343
      },
      {
        nextScheduledRunDateTime: '2024-02-04T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        nextScheduledRunDateTime: '2024-02-04T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        nextScheduledRunDateTime: '2024-02-04T15:13:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 5,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        nextScheduledRunDateTime: '2024-02-04T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 100,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        nextScheduledRunDateTime: '2024-02-04T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      }
    ],
    noOfPagesScanned : 0,
    noOfPagesScheduledToScan : 224
  },
  {
    day: ' 2024-02-05',
    noOfCompletedRuns:0,
    noOfScheduledRuns: 6,
    completedItems: [],
    scheduledItems : [
      {
        nextScheduledRunDateTime: '2024-02-05T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 10,
        frequency : 'weekly',
        itemName : 'Audit to test Rule endpoints caching',
        itemId : 224307
      },
      {
        nextScheduledRunDateTime: '2024-02-05T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        nextScheduledRunDateTime: '2024-02-05T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        nextScheduledRunDateTime: '2024-02-05T15:13:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 5,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        nextScheduledRunDateTime: '2024-02-05T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 100,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        nextScheduledRunDateTime: '2024-02-05T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      }
    ],
    noOfPagesScanned : 0,
    noOfPagesScheduledToScan : 214
  }
];

export const webJourneysInCurrentRollingWeek = [
  {
    day: '2024-01-30',
    noOfCompletedRuns:5,
    noOfScheduledRuns: 0,
    completedItems: [
      {
        lastRunId: 97487,
        lastRunCompletedAt : '2024-01-30T12:14:30Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Smoke testing WebJourneys',
        itemId : 198184
      },
      {
        lastRunId : 97488,
        lastRunCompletedAt : '2024-01-30T12:04:07Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      },
      {
        lastRunId : 97482,
        lastRunCompletedAt : '2024-01-30T10:28:20Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        lastRunId : 97483,
        lastRunCompletedAt : '2024-01-30T10:26:22Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      }
    ],
    scheduledItems : [],
    noOfPagesScanned : 204,
    noOfPagesScheduledToScan : 0
  },
  {
    day: ' 2024-01-31',
    noOfCompletedRuns:7,
    noOfScheduledRuns: 0,
    completedItems: [
      {
        lastRunId : 97707,
        lastRunCompletedAt : '2024-01-31T17:01:33Z',
        noOfRunsCompleted : 1,
        frequency : 'once',
        itemName : 'Ovsiichuk test',
        itemId : 338092
      },
      {
        lastRunId : 97693,
        lastRunCompletedAt : '2024-01-31T15:19:18Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        lastRunId : 97688,
        lastRunCompletedAt : '2024-01-31T12:13:28Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        lastRunId : 97689,
        lastRunCompletedAt : '2024-01-31T12:03:20Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      },
      {
        lastRunId : 97676,
        lastRunCompletedAt : '2024-01-31T10:50:50Z',
        noOfRunsCompleted : 1,
        frequency : 'weekly',
        itemName : 'Customers Home Pages 1000',
        itemId : 299272
      },
      {
        lastRunId : 97680,
        lastRunCompletedAt : '2024-01-31T10:29:39Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        lastRunId : 97679,
        lastRunCompletedAt : '2024-01-31T10:26:40Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      }
    ],
    scheduledItems : [],
    noOfPagesScanned : 1205,
    noOfPagesScheduledToScan : 0
  },
  {
    day: ' 2024-02-01',
    noOfCompletedRuns:8,
    noOfScheduledRuns: 0,
    completedItems: [
      {
        lastRunId : 97837,
        lastRunCompletedAt : '2024-02-01T17:00:17Z',
        noOfRunsCompleted : 1,
        frequency : 'once',
        itemName : 'Ovsiichuk test',
        itemId : 338092
      },
      {
        lastRunId : 97820,
        lastRunCompletedAt : '2024-02-01T15:20:35Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        lastRunId : 97785,
        lastRunCompletedAt : '2024-02-01T12:13:09Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        lastRunId : 97786,
        lastRunCompletedAt : '2024-02-01T12:03:07Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      },
      {
        lastRunId : 97780,
        lastRunCompletedAt : '2024-02-01T10:34:14Z',
        noOfRunsCompleted : 1,
        frequency : 'weekly',
        itemName : 'User Session Failure with CNN Page 30',
        itemId : 301342
      },
      {
        lastRunId : 97779,
        lastRunCompletedAt : '2024-02-01T10:33:59Z',
        noOfRunsCompleted : 1,
        frequency : 'weekly',
        itemName : 'User Session with CNN Page',
        itemId : 274115
      },
      {
        lastRunId : 97778,
        lastRunCompletedAt : '2024-02-01T10:29:41Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        lastRunId : 97777,
        lastRunCompletedAt : '2024-02-01T10:26:59Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      }
    ],
    scheduledItems : [],
    noOfPagesScanned : 237,
    noOfPagesScheduledToScan : 0
  },
  {
    day: ' 2024-02-02',
    noOfCompletedRuns:2,
    noOfScheduledRuns: 3,
    completedItems: [
      {
        lastRunId : 97911,
        lastRunCompletedAt : '2024-02-02T10:28:01Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        lastRunId : 97912,
        lastRunCompletedAt : '2024-02-02T10:27:43Z',
        noOfRunsCompleted : 1,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      }
    ],
    scheduledItems : [
      {
        nextScheduledRunDateTime: '2024-02-02T15:13:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 5,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        nextScheduledRunDateTime: '2024-02-02T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 100,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        nextScheduledRunDateTime: '2024-02-02T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      }
    ],
    noOfPagesScanned : 98,
    noOfPagesScheduledToScan : 106
  },
  {
    day: ' 2024-02-03',
    noOfCompletedRuns:0,
    noOfScheduledRuns: 5,
    completedItems: [],
    scheduledItems : [
      {
        nextScheduledRunDateTime: '2024-02-03T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        nextScheduledRunDateTime: '2024-02-03T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        nextScheduledRunDateTime: '2024-02-03T15:13:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 5,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        nextScheduledRunDateTime: '2024-02-03T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 100,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        nextScheduledRunDateTime: '2024-02-03T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      }
    ],
    noOfPagesScanned : 0,
    noOfPagesScheduledToScan : 204
  },
  {
    day: ' 2024-02-04',
    noOfCompletedRuns:0,
    noOfScheduledRuns: 7,
    completedItems: [],
    scheduledItems : [
      {
        nextScheduledRunDateTime: '2024-02-04T13:57:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 10,
        frequency : 'weekly',
        itemName : 'audit with rules Chrome',
        itemId : 152789
      },
      {
        nextScheduledRunDateTime: '2024-02-04T13:57:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 10,
        frequency : 'weekly',
        itemName : 'Audit with On Page Action Failure',
        itemId : 301343
      },
      {
        nextScheduledRunDateTime: '2024-02-04T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        nextScheduledRunDateTime: '2024-02-04T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        nextScheduledRunDateTime: '2024-02-04T15:13:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 5,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        nextScheduledRunDateTime: '2024-02-04T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 100,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        nextScheduledRunDateTime: '2024-02-04T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      }
    ],
    noOfPagesScanned : 0,
    noOfPagesScheduledToScan : 224
  },
  {
    day: ' 2024-02-05',
    noOfCompletedRuns:0,
    noOfScheduledRuns: 6,
    completedItems: [],
    scheduledItems : [
      {
        nextScheduledRunDateTime: '2024-02-05T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 10,
        frequency : 'weekly',
        itemName : 'Audit to test Rule endpoints caching',
        itemId : 224307
      },
      {
        nextScheduledRunDateTime: '2024-02-05T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49',
        itemId : 277528
      },
      {
        nextScheduledRunDateTime: '2024-02-05T10:11:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 49,
        frequency : 'daily',
        itemName : 'Clients starting URLs 49 copied 04 October 2023 | 1:08pm',
        itemId : 308923
      },
      {
        nextScheduledRunDateTime: '2024-02-05T15:13:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 5,
        frequency : 'daily',
        itemName : 'Zuko',
        itemId : 9109
      },
      {
        nextScheduledRunDateTime: '2024-02-05T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 100,
        frequency : 'daily',
        itemName : 'Smoke testing Audit',
        itemId : 198184
      },
      {
        nextScheduledRunDateTime: '2024-02-05T11:56:00Z',
        noOfRunsScheduled: 1,
        noOfPagesScheduledToScan : 1,
        frequency : 'daily',
        itemName : 'ABC',
        itemId : 306237
      }
    ],
    noOfPagesScanned : 0,
    noOfPagesScheduledToScan : 214
  }
];

export const MockUsageSummaryDTO: IAccountUsageV2SummaryResponseDTO = {
  contacts: [MockContact, MockContact1],
  usersUsage: MockUsersUsage,
  allowOverages: true,
};

export const MockUsageSpecificSummaries = {
  auditUsage: MockAuditUsage,
  webJourneyUsage: MockWebJourneyUsage,
  isWebJourneyFeatureEnabled: true
};

export const MockUsageFullSummaryDTO = {
  contacts: [MockContact, MockContact1],
  usersUsage: MockUsersUsage,
  auditUsage: MockAuditUsage,
  webJourneyUsage: MockWebJourneyUsage,
  allowOverages: true,
  isWebJourneyFeatureEnabled: true
};

export const MockUsageTrendsDTO: IUsageTrendsDTO = {
  pastPeriods: [
    {
      startDateInclusive: '2022-01-15',
      endDateExclusive: '2022-02-01',
      periodTotal: { total: 300, filtered: 200 },
      cumulativeTotal: { total: 500, filtered: 0 },
    },
    {
      startDateInclusive: '2022-02-01',
      endDateExclusive: '2022-03-01',
      periodTotal: { total: 400, filtered: 350 },
      cumulativeTotal: { total: 1000, filtered: 0 },
    },
    {
      startDateInclusive: '2022-03-01',
      endDateExclusive: '2022-04-01',
      periodTotal: { total: 350, filtered: 450 },
      cumulativeTotal: { total: 1150, filtered: 500 },
    },
    {
      startDateInclusive: '2022-04-01',
      endDateExclusive: '2022-05-01',
      periodTotal: { total: 360, filtered: 460 },
      cumulativeTotal: { total: 1300, filtered: 600 },
    },
    {
      startDateInclusive: '2022-05-01',
      endDateExclusive: '2022-06-01',
      periodTotal: { total: 380, filtered: 480 },
      cumulativeTotal: { total: 1320, filtered: 620 },
    },
  ],
  currentPeriod: {
    startDateInclusive: '2022-06-01',
    endDateExclusive: '2022-07-01',
    periodTotal: { total: 400, filtered: 300 },
    cumulativeTotal: { total: 1320, filtered: 620 },
    periodProjectedTotal: {
      scheduledUsageEstimate: { total: 434, filtered: 323 },
      historicalUsageEstimate: { total: 95, filtered: 77 },
    },
    cumulativeProjectedTotal: {
      scheduledUsageEstimate: { total: 1330, filtered: 640 },
      historicalUsageEstimate: { total: 1350, filtered: 220 },
    },
  },
  futurePeriods: [
    // {
    //   startDateInclusive: '2022-06-01',
    //   endDateExclusive: '2022-07-01',
    //   periodProjectedTotal: {
    //     scheduledUsageEstimate: { total: 400, filtered: 300 },
    //     historicalUsageEstimate: { total: 90, filtered: 70 },
    //   },
    //   cumulativeProjectedTotal: {
    //     scheduledUsageEstimate: { total: 1320, filtered: 620 },
    //     historicalUsageEstimate: { total: 1320, filtered: 200 },
    //   },
    // },
    {
      startDateInclusive: '2022-07-01',
      endDateExclusive: '2022-08-01',
      periodProjectedTotal: {
        scheduledUsageEstimate: { total: 412, filtered: 300 },
        historicalUsageEstimate: { total: 90, filtered: 70 },
      },
      cumulativeProjectedTotal: {
        scheduledUsageEstimate: { total: 1350, filtered: 650 },
        historicalUsageEstimate: { total: 1500, filtered: 200 },
      },
    },
    {
      startDateInclusive: '2022-08-01',
      endDateExclusive: '2022-09-01',
      periodProjectedTotal: {
        scheduledUsageEstimate: { total: 400, filtered: 300 },
        historicalUsageEstimate: { total: 90, filtered: 70 },
      },
      cumulativeProjectedTotal: {
        scheduledUsageEstimate: { total: 1350, filtered: 700 },
        historicalUsageEstimate: { total: 1500, filtered: 200 },
      },
    },
    {
      startDateInclusive: '2022-09-01',
      endDateExclusive: '2022-10-01',
      periodProjectedTotal: {
        scheduledUsageEstimate: { total: 400, filtered: 300 },
        historicalUsageEstimate: { total: 90, filtered: 70 },
      },
      cumulativeProjectedTotal: {
        scheduledUsageEstimate: { total: 1350, filtered: 800 },
        historicalUsageEstimate: { total: 1500, filtered: 200 },
      },
    },
    {
      startDateInclusive: '2022-10-01',
      endDateExclusive: '2022-11-01',
      periodProjectedTotal: {
        scheduledUsageEstimate: { total: 400, filtered: 300 },
        historicalUsageEstimate: { total: 90, filtered: 70 },
      },
      cumulativeProjectedTotal: {
        scheduledUsageEstimate: { total: 1350, filtered: 900 },
        historicalUsageEstimate: { total: 1550, filtered: 200 },
      },
    },
    {
      startDateInclusive: '2022-11-01',
      endDateExclusive: '2022-12-01',
      periodProjectedTotal: {
        scheduledUsageEstimate: { total: 401, filtered: 300 },
        historicalUsageEstimate: { total: 90, filtered: 70 },
      },
      cumulativeProjectedTotal: {
        scheduledUsageEstimate: { total: 1450, filtered: 1000 },
        historicalUsageEstimate: { total: 1600, filtered: 200 },
      },
    },
    {
      startDateInclusive: '2022-12-01',
      endDateExclusive: '2022-12-15',
      periodProjectedTotal: {
        scheduledUsageEstimate: { total: 410, filtered: 300 },
        historicalUsageEstimate: { total: 90, filtered: 70 },
      },
      cumulativeProjectedTotal: {
        scheduledUsageEstimate: { total: 1350, filtered: 1100 },
        historicalUsageEstimate: { total: 1800, filtered: 200 },
      },
    },
  ],
  termLimit: 1100,
  monthlyPacing: 300,
};
