import { InjectionToken } from "@angular/core";

export const CC_ASSIGN_STRINGS = {
  modalTitle: 'ASSIGN CONSENT CATEGORIES TO DATA SOURCE',
  nextBtn: 'NEXT, SELECT AUDITS',
  nextBtnOpSelector: 'cc-assign-next-btn',
  prevBtn: 'PREV',
  prevBtnOpSelector: 'cc-assign-prev-btn',
  doneBtn: 'DONE, ASSIGN CATEGORIES TO DATA SOURCES',
  doneBtnOpSelector: 'cc-assign-done-btn',
  selectInstructions: 'Select the Consent Categories that you want to apply to specific audits',
  searchInputPlaceholder1: 'Search by consent category or label',
  searchInputPlaceholder2: 'Search by audit or label name',
};

export const CC_ASSIGN_CC_FILTERS_STORAGE_KEY = 'ccAssign_CC_filters';
export const CC_ASSIGN_DC_FILTERS_STORAGE_KEY = 'ccAssign_DC_filters';
export const STORAGE_KEY = new InjectionToken<string>('STORAGE_KEY');

export enum ECCAssignView {
  SELECT_CC,
  SELECT_REPORT
}
