import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable, of } from 'rxjs';
import {
  IPageUrlMatchingAuditFilterConfigurationValidation
} from '@app/services/api-validations/api-validation.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';

@Injectable({
  providedIn: 'root'
})
export class ApiValidationsService {
  private apiRoot = `${environment.apiV3Url}`;

  constructor(private apiService: ApiService) {
  }

  @CacheApiResponse() validateUrlMatchingFilter(auditId: number, runId: number): Observable<IPageUrlMatchingAuditFilterConfigurationValidation> {
    return this.apiService.get(this.apiRoot + `web-audits/${auditId}/runs/${runId}/filter-validation/urls-matching-audit-configuration`);
  }

  @CacheApiResponse() validateUrlsSearchFilter(regex) {
    return this.apiService.post<{
      allUnsupportedSequences: string[],
      unsupportedSequence: string;
      isValid: boolean,
    }>(this.apiRoot + `reports/filter-validations/regexes`, { regex });
  }

}
