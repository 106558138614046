import { IWebElementSelector } from '@app/components/shared/components/multi-selectors/multi-selectors.models';
import { EActionType } from '@app/components/web-journey/web-journey.models';
import { IActionSetSelector } from '@app/components/action-set-library/action-set-library.models';
import { EStartingUrlFailureType } from '@app/components/audit-reports/audit-report-header/audit-report-header.constants';

export interface IAudit {
  id?: number;
  name?: string;
  reports?: IAuditReport[];
  webAuditRunning?: boolean;
  queued?: boolean;
}

export interface IAuditReport {
  id: number;
}

export interface IPagesRequestBody {
  identifiers: Array<string>;
}

export class DomainAuditReport implements IAuditReport {
  id: number;
  score: number;
  started: Date;
  completed: Date;
}

export interface IDomainAuditAction {
  type: EActionType;
  preventNavigation: boolean;
  sequence: number;
  value?: string;
  maskedValue?: string;
  identifier?: string;
  selectors?: Array<IWebElementSelector>;
  filter?: string;
  action?: EActionType;
  rules?: Array<number>;
  url?: string;
  js?: string;
  contextLevel?: number;
  seconds?: number;
}

export class DomainAuditActionArray {
  constructor(public actions: IDomainAuditAction[]) { }
}

export interface IDomainAuditUserSession {
  url: string;
  actions?: IDomainAuditAction[];
}

export interface IAccountSummaries {
  auditRunId: number;
  noncompliant: number;
  tag: IAccountSummariesTag,
  duplicates: number;
  tagged: number;
  variables: number;
  multiples: number;
  missing: number;
  account: string;
}

export interface IAccountSummariesTag {
  name: string;
  icon: string;
  id: number;
  category: IAccountSummariesTagCategory;
  duplicateScoring: boolean;
}

export interface IAccountSummariesTagCategory {
  id: number;
  name: string;
}

export interface ISelectedAccount {
  vendor: number;
  account: string;
}

export interface IV3AuditAction {
  id: number;
  sequence: number;
  rules: number[];
  action: EActionType;
  selectors: IActionSetSelector[];
  value: string;
  maskedValue: string;
  description: string;
  preventNav: boolean;
  waitDuration: number;
  actionSetId: number;
}

export interface IV3AuditWithLatestRun {
  id: number;
  name: string;
  latestRun: { id: number; completedAt: string; pageCount: number; };
  domainInfo: { id: number; name: string; };
  labels: Array<{ id: number; name: string; }>;
}

export interface IAuditRunErrors {
  preAuditActionResults?: IAuditRunErrorsPreAuditActionResults;
  onPagesActionResults?: IAuditRunErrorsOnPagesActionResults;
  startingUrlResults?: IAuditRunErrorsStartingUrlResults;
}

export interface IAuditRunErrorsPreAuditActionResults {
  zeroAndOut: boolean;
  preAuditActionFailures: IAuditRunErrorsPreAuditActionFailures[];
}

export interface IAuditRunErrorsPreAuditActionFailures {
  auditActionSnapshotId: number;
  failureMessage: string;
}

export interface IAuditRunErrorsOnPagesActionResults {
  totalConfiguredCount: number;
  totalFailedCount: number;
  resultsSample: IAuditRunErrorsOnPagesActionResultsSample[];
}

export interface IAuditRunErrorsOnPagesActionResultsSample {
  pageUrl: string;
  results: IAuditRunErrorsOnPagesActionResultsSampleResult[];
}

export interface IAuditRunErrorsOnPagesActionResultsSampleResult {
  auditActionSnapshotId: number;
  failureMessage: string;
}

export interface IAuditRunErrorsStartingUrlResults {
  failureType: EStartingUrlFailureType;
  linksExcludedSample: string[];
  startingUrlFailures: IAuditRunErrorsStartingUrlFailure[];
}

export interface IAuditRunErrorsStartingUrlFailure {
  statusCode: number;
  pageCount: number;
  browserError: string;
}

export interface IAuditRunSnapshot {
  preAuditActionSnapshots: IAuditRunActionSnapshot[];
  onPageActionSnapshots: IAuditRunActionSnapshot[];
}

export interface IAuditRunActionSnapshot {
  actionSnapshotId: number;
  sequenceId: number;
  actionType: number;
  description?: string;
}

export interface IEasyBlockTags {
  tagCategoryIds: number[];
  tagIds: number[];
}
