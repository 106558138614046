import { ENotificationCenterTargetItemType } from '@app/components/notifications-center/notification-center.models';

export enum ENotificationCenterColumns {
  Email = 'email',
  AuditCount = 'auditCount',
  WebJourneyCount = 'webJourneyCount',
  RuleCount = 'ruleCount',
  AlertCount = 'alertCount',
  UsageAlertCount = 'usageAlertCount',
  Edit = 'edit',
  InboxReceivedCount = 'inboxReceivedCount',
  InboxCompletionCount = 'inboxCompletionCount'
}

export enum ENotificationCenterTargetItemField {
  AUDIT = 'auditCount',
  ALERT = 'alertCount',
  USAGE_ALERT = 'usageAlertCount',
  RULE = 'ruleCount',
  WEB_JOURNEY = 'webJourneyCount',
}
