import { ERuleMatchType } from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';

export function matchTypeToLabel(matchType: ERuleMatchType): string {
  switch (matchType) {
    case ERuleMatchType.Equals:
    case ERuleMatchType.Equal:
      return '';
    case ERuleMatchType.DoesNotEqual:
      return 'Does not equal ';
    case ERuleMatchType.NotEqual:
      return 'Not equal ';
    case ERuleMatchType.Contains:
      return 'Contains ';
    case ERuleMatchType.DoesNotContain:
      return 'Does not contain ';
    case ERuleMatchType.IsSet:
      return 'Is set ';
    case ERuleMatchType.IsNotSet:
      return 'Is not set ';
    case ERuleMatchType.NotSet:
      return 'Not set ';
    case ERuleMatchType.Regex:
      return 'Regex ';
    case ERuleMatchType.GreaterThanOrEqualTo:
    case ERuleMatchType.GreaterThanOrEquals:
      return '>= ';
    case ERuleMatchType.LessThanOrEqualTo:
    case ERuleMatchType.LessThanOrEquals:
      return '<= ';
    case ERuleMatchType.EqualIgnoreCase:
      return '(ignore case) ';
    case ERuleMatchType.NotEqualIgnoreCase:
      return 'Does not equal (ignore case) ';
    case ERuleMatchType.ContainsIgnoreCase:
      return 'Contains (ignore case) ';
    case ERuleMatchType.DoesNotContainIgnoreCase:
      return 'Does not contain (ignore case) ';
    default:
      return matchType;
  }
}
