<span class="control-container" [formGroup]="control.parent">
  <span
    class="view-mode"
    [class.read-only]="modalType === EEmailInboxModalType.ReadOnly"
    *ngIf="mode === EEmailInboxNameControlMode.View; else editName"
    (click)="switchToEditMode()"
  >
    <span>{{control.value}}</span>
    <mat-icon *ngIf="modalType !== EEmailInboxModalType.ReadOnly">edit</mat-icon>
  </span>
  <ng-template #editName>
    <mat-form-field appearance="outline" class="styled-mat-input no-hint-container">
      <input matInput formControlName="name"
             (keydown.enter)="switchToViewMode($event.target.value)"
             (blur)="switchToViewMode($event.target.value)"
             placeholder="Create Inbox Name"
             autocomplete="new-password">
      <mat-error *ngIf="control.invalid">
        Email inbox name is required
      </mat-error>
    </mat-form-field>
  </ng-template>
</span>
