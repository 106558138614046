import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';

export enum EAlertSuccessType {
  Created,
  Edited,
  Unassigned,
  Deleted
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-change-success-snackbar',
  templateUrl: './alert-change-success-snackbar.component.html',
  styleUrls: ['./alert-change-success-snackbar.component.scss']
})
export class AlertChangeSuccessSnackbarComponent {
  readonly EAlertSuccessType = EAlertSuccessType;
  accountLevelAlert: boolean = false;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data,
    private snackBarRef: MatSnackBarRef<AlertChangeSuccessSnackbarComponent>,
    private alertReportingService: AlertReportingService,
  ){
    this.accountLevelAlert = this.alertReportingService.isAccountLevelMetric(data.alert.metricType);
  }

  close() {
    this.snackBarRef.dismiss();
  }
}
