export interface IWidgetSizeService {
    calculateElementWidth(element: Element): number;
    calculateElementHeight(element: Element): number;
    calculateElementTop(element: Element): number;
    emptyElementTop(element: Element): number;
  }

  export class WidgetSizeService implements IWidgetSizeService {
    calculateElementWidth(element: HTMLElement): number {
      if (!element.offsetWidth) {
        return 0;
      }
      var style = window.getComputedStyle(element);
      var width = element.offsetWidth;
      var margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      var padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
      var border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
      return width - margin - padding - border;
    }

    calculateElementHeight(element: HTMLElement): number {
      if (!element.offsetHeight) {
        return 0;
      }
      var style = window.getComputedStyle(element);
      var height = element.offsetHeight;
      var margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom);
      var padding = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
      var border = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);

      return height - margin - padding - border;
    }

    calculateElementTop(element: HTMLElement): number {
      if (!element.scrollTop) {
        return 0;
      }
      var style = window.getComputedStyle(element);
      var height = element.scrollTop;
      var margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom);
      var padding = parseFloat(style.paddingTop) + parseFloat(style.paddingBottom);
      var border = parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);

      return height - margin - padding - border;
    }
    emptyElementTop(element: HTMLElement): number {
      if (!element.scrollTop) {
        return 0;
      }
      element.scrollTop = 0;
      return this.calculateElementTop(element);
    }
  
}

