import { UsageChartComponent } from '@app/components/usage-v2/components/usage-chart/usage-chart.component';
import {
  ChartClassName,
  EChartType,
  IChartToggleBtn
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import { DecimalPipe } from '@angular/common';
import { IUsageTrendsDTO } from '@app/components/usage-v2/usage-v2.models';
import { getPercentageOfTotal } from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';

const actionTextVariants = {
  [EChartType.AUDIT]: 'page scans',
  [EChartType.WEB_JOURNEY]: 'journey runs',
};

const generateTooltipText = (value: string, actionText: string): string => {
  return '<div class="usage-chart-tooltip-body">' +
    '<h3 class="title">ACTUAL USAGE</h3>' +
    '<div class="value">' +
    '<div class="value-data actual big-font">' + value + '</div>' +
    `<div class="value-label">Total ${actionText} since the start of your subscription.</div>` +
    '</div>' +
    '</div>';
};

const generateFilteredTooltipText = (value: string, ofValue: string, percentage: string, actionText: string): string => {
  return '<div class="usage-chart-tooltip-body">' +
    '<h3 class="title">ACTUAL USAGE</h3>' +
    '<div class="value">' +
    '<span class="value-data filtered">' + value + ' ' +
    '(<span class="material-icons icon">filter_alt</span>)' +
    '</span>' +
    '<span class="value-label"> of </span>' +
    '<span class="value-data actual">' + ofValue + '</span>' +
    '</div>' +
    '<div class="value">' +
    '<span class="value-label">' + percentage + '</span>' +
    `<div class="value-label">Total ${actionText} since the start of your subscription.</div>` +
    '</div>';
};

export function getActualUsageToggleBtn(trends: IUsageTrendsDTO, chartType: EChartType): IChartToggleBtn {
  return {
    className: UsageChartComponent.getChartToggleBtnClassName(ChartClassName.pastPeriods),
    text: 'Actual Usage',
    tooltipText: generateTooltipText(
      new DecimalPipe('en-US').transform(trends?.currentPeriod?.cumulativeTotal?.total),
      actionTextVariants[chartType],
    ),
    chartClassName: ChartClassName.pastPeriods,
  };
}

export function getActualUsageFilteredToggleBtn(trends: IUsageTrendsDTO, chartType: EChartType): IChartToggleBtn {
  const percentage = getPercentageOfTotal(
    trends?.currentPeriod?.cumulativeTotal?.filtered,
    trends?.currentPeriod?.cumulativeTotal?.total,
  );
  return {
    className: UsageChartComponent.getChartToggleBtnClassName(ChartClassName.filteredUsage),
    text: 'Actual Usage',
    tooltipText: generateFilteredTooltipText(
      new DecimalPipe('en-US').transform(trends?.currentPeriod?.cumulativeTotal?.filtered),
      new DecimalPipe('en-US').transform(trends?.currentPeriod?.cumulativeTotal?.total),
      percentage,
      actionTextVariants[chartType],
    ),
    chartClassName: ChartClassName.filteredUsage,
    showWithFiltered: true,
  };
}
