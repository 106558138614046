import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  OnDestroy,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  IOpFilterBarV2InvertableFilter,
  IOpFilterBarV2MenuItem,
  IOpFilterBarV2PrintViewItem,
  ISearchByTextEmissionDataV2
} from './op-filter-bar-v2.models';
import { CalculateWordWidth } from '@app/components/domains/discoveryAudits/reporting/services/calculateWordWidthService/calculateWordWidthService';
import { OpFilterBarV2Service } from './op-filter-bar-v2.service';
import { ActivatedRoute } from '@angular/router';
import { IOpFilterBarV2Filter } from './op-filter-bar-v2.models';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { EFilterBarV2MenuTypes, FilterRemovedEvent } from './op-filter-bar-v2.constants';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { calculateHiddenFilterChips } from '@app/components/utilities/filter-bar-utils';

@Component({
  selector: 'op-filter-bar-v2',
  templateUrl: './op-filter-bar-v2.component.html',
  styleUrls: ['./op-filter-bar-v2.component.scss']
})
export class OpFilterBarV2Component implements AfterViewInit, OnDestroy, OnChanges {

  @Input() menuItems: IOpFilterBarV2MenuItem[];
  @Input() service: OpFilterBarV2Service<any>;
  @Input() isSearchByTextEnabled: boolean;
  @Input() searchByTextHidden: boolean;
  @Input() alwaysHighlightedFiltersButton: boolean;
  @Input() searchByTextPlaceholderSuffix: string;
  @Input() validFilterTypes: any[];
  @Input() hideSearchByRegexOption: boolean = false;
  @Input() keepWide: boolean = false; // Set true to always display input in wide mode
  @Input() enableTypeahead: boolean = false;
  @Input() menuClass: string = '';
  @Output() searchByTextIsFocused: EventEmitter<void> = new EventEmitter();
  @Output() searchByTextIsEntered: EventEmitter<ISearchByTextEmissionDataV2> = new EventEmitter();
  @Output() invertableFilterToggled: EventEmitter<IOpFilterBarV2InvertableFilter<string>> = new EventEmitter();
  @Output() onMenuClosed: EventEmitter<void> = new EventEmitter();

  // filters
  @ViewChild('filterChipBag') filterChipBag: ElementRef;
  // search parent
  @ViewChild('parentDivSearch') parentDivSearch: ElementRef;

  visibleFilters: IOpFilterBarV2Filter<string>[] = [];
  hiddenFilters: IOpFilterBarV2Filter<string>[] = [];
  hiddenContainsValidTypes: boolean = false;
  auditId: number;
  runId: number;
  modalServiceIndex: number;
  printViewFilters: IOpFilterBarV2PrintViewItem[];

  // search by url
  showTextSearchByRegexOpt: boolean = false;
  treatSearchByTextAsRegex: boolean = false;
  setToHideRegexOpt: NodeJS.Timeout;
  ignoreSearchByUrlBlur: boolean = false;
  searchByTextInputValue: string = '';

  private filters: IOpFilterBarV2Filter<string>[] = [];
  private destroy: Subject<void> = new Subject();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateHiddenFilterChips();
  }

  constructor(
    private self: ElementRef,
    private calculateWordWidthService: CalculateWordWidth,
    private activatedRoute: ActivatedRoute,
    private modalEscapeService: ModalEscapeService,
    private eventManager: IEventManager,
    private authenticationService: AuthenticationService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;
    });
  }

  ngAfterViewInit(): void {
    // AfterViewInit ensures that all sizes are accurate
    // setTimeout ensures we avoid the modified-after-checked Angular error
    this.service.anyFiltersUpdates$.pipe(takeUntil(this.destroy)).subscribe(filters => {
      this.filters = [ ...new Set(filters) ];
      setTimeout(this.calculateHiddenFilterChips.bind(this));
    });

    this.service.validFilterTypesUpdated$.pipe(takeUntil(this.destroy)).subscribe(validFilterTypes => {
      setTimeout(this.calculateHiddenFilterChips.bind(this));
    });

    // ensure the filter chips are measured correctly and display properly
    setTimeout(this.calculateHiddenFilterChips.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['menuItems']?.currentValue) {
      this.authenticationService.getFeaturesWithCache().subscribe(allowedFeatures => {
        OpFilterBarV2Component.checkFeatureAllowed(allowedFeatures, this.menuItems);
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  // filters
  calculateHiddenFilterChips(wide: boolean = false): void {
    const {
      visibleFilters,
      printViewFilters,
      hiddenFilters,
      hiddenContainsValidTypes
    } = calculateHiddenFilterChips(wide, this.self, this.filterChipBag, this.filters, this.validFilterTypes);

    this.visibleFilters = visibleFilters;
    this.printViewFilters = printViewFilters;
    this.hiddenFilters = hiddenFilters;
    this.hiddenContainsValidTypes = hiddenContainsValidTypes;
  }

  removeFilter(filter: IOpFilterBarV2Filter<string>): void {
    this.eventManager.publish(FilterRemovedEvent);
    this.service.removeFilter(filter);
    this.calculateHiddenFilterChips();
  }

  // URL Filtering
  searchByTextMouseDown(): void {
    this.ignoreSearchByUrlBlur = true;
  }

  searchByTextFocused(): void {
    this.searchByTextIsFocused.emit();

    this.parentDivSearch.nativeElement.classList.add('wide');
    this.calculateHiddenFilterChips(true);
    if (this.setToHideRegexOpt) {
      clearTimeout(this.setToHideRegexOpt);
      this.setToHideRegexOpt = null;
    }
    this.showTextSearchByRegexOpt = true;
  }

  focusout(event: KeyboardEvent): void {
    const searchByTextInputValue = (event.target as HTMLInputElement).value;
    if (searchByTextInputValue == '') {
      if (!this.keepWide) {
        this.parentDivSearch.nativeElement.classList.remove('wide');
      }
      this.calculateHiddenFilterChips(false);
    }
  }

  searchByTextBlurred(): void {
    if (!this.ignoreSearchByUrlBlur) {
      this.setToHideRegexOpt = setTimeout(() => this.showTextSearchByRegexOpt = false, 0);
    }
    this.ignoreSearchByUrlBlur = false;
  }

  searchByTextKeyUp(e: KeyboardEvent): void {
    const searchByTextInputValue = (e.target as HTMLInputElement).value;
    const hasSearchFilter = !!this.filters.filter(filter => filter.type === EFilterBarV2MenuTypes.Search).length;

    if (!hasSearchFilter && this.enableTypeahead && !(e.key === 'Enter')) {
      this.handleTypeahead(searchByTextInputValue);
      return;
    }

    switch (e.key) {
      case 'Enter':
        const searchByTextData = {
          value: (e.target as HTMLInputElement).value,
          regex: this.treatSearchByTextAsRegex
        };

        this.searchByTextIsEntered.emit(searchByTextData);
        this.searchByTextInputValue = '';
        this.showTextSearchByRegexOpt = false;
        this.treatSearchByTextAsRegex = false;
        break;
      case 'Escape':
        if (!this.showTextSearchByRegexOpt) {
          if (searchByTextInputValue) {
            // regex opt box hidden and value present in text box... clear it.
            this.searchByTextInputValue = '';
            e.stopPropagation();
          }
        } else {
          // hide the regex opt box
          this.showTextSearchByRegexOpt = false;
          e.stopPropagation();
        }
        break;
      default:
        // re-show the regex opt box
        this.showTextSearchByRegexOpt = true;
        break;
    }
  }

  handleTypeahead(searchValue: string): void {
    const searchByTextData = {
      value: searchValue,
      regex: this.treatSearchByTextAsRegex,
      isTypeahead: true
    };

    this.searchByTextIsEntered.emit(searchByTextData);
    this.showTextSearchByRegexOpt = false;
    this.treatSearchByTextAsRegex = false;
  }

  toggleInvertableFilter(filter: IOpFilterBarV2InvertableFilter<string>): void {
    filter.state = !filter.state;
    this.invertableFilterToggled.emit(filter);
  }

  clearFilters(): void {
    this.eventManager.publish(FilterRemovedEvent);
    this.service.clear();
  }

  menuOpened(): void {
    this.modalServiceIndex = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(this.modalServiceIndex);
  }

  menuClosed(): void {
    setTimeout(() => {
      this.modalEscapeService.remove(this.modalServiceIndex);
      this.modalServiceIndex = undefined;
      this.onMenuClosed.emit();
    });
  }

  clearSearchByUrl(): void {
    this.searchByTextInputValue = '';

    if ((!this.visibleFilters.length && !this.hiddenFilters.length) && this.enableTypeahead) {
      this.handleTypeahead('');
    }
  }

  private static checkFeatureAllowed(allowedFeatures: string[], menu: IOpFilterBarV2MenuItem[]) {
    menu.forEach(menuItem => {
      if (menuItem.requiredFeature && menuItem.displayWhen === undefined) {
        menuItem.displayWhen = allowedFeatures.includes(menuItem.requiredFeature);
      }

      if (menuItem.children) {
        OpFilterBarV2Component.checkFeatureAllowed(allowedFeatures, menuItem.children);
      }
    });
  }
}
