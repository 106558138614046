<ng-container *ngIf="!loading; else spinner">
  <p *ngIf="!accessibilityChecksSummary; else content" class="empty-state">
    Accessibility checks are only available on newer runs.
    <br>
    Please re-run the audit now or schedule this Audit to take advantage of new
    information.
  </p>
</ng-container>

<ng-template #content>
  <page-details-accessibility-filter-bar
    [itemId]="itemId"
    [runId]="runId"
    [pageId]="pageId"
    [reportType]="reportType"
    [activeTab]="activeTab"
    (onUpdateFilter)="updateFilter($event)"
    (onExportAllIssues)="exportAllIssues()"
  ></page-details-accessibility-filter-bar>
  <page-details-accessibility-widget-bar
    [accessibilityChecksSummary]="accessibilityChecksSummary"
    [filteredItems]="accessibilityChecksSummary | filterRuleResults : (filters$ | async)"
  ></page-details-accessibility-widget-bar>
</ng-template>

<ng-template #spinner>
  <div class="spinner-overlay">
    <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
  </div>
</ng-template>

<div class="groups-container" *ngIf="!!accessibilityChecksSummary" >
  <div class="groups-container-title">
    <div class="title">
      ISSUE TYPES ({{ (accessibilityChecksSummary
      | filterRuleResults : (filters$ | async)
      | filterRuleImpact : (filters$ | async))?.length }})
    </div>
    <div class="right">
      <div class="number">
        #
      </div>
      <div class="level">
        VER + LEVEL
      </div>
    </div>
  </div>
  <div class="groups-list">
    <op-page-details-group *ngFor="let group of accessibilityChecksSummary
      | filterRuleResults : (filters$ | async)
      | filterRuleImpact : (filters$ | async)"
     [group]="group"></op-page-details-group>
  </div>
</div>
