import { IWJTagPresenceDiff } from '../tag-comparison/wj-results-tag-comparison.models';

export interface IWJTagToAllDiffs {
  tagId: number;
  tagName: string;
  tagIcon: string;
  diffs: IWJTagPresenceDiff[];
}

export class WJTagToAllDiffs implements IWJTagToAllDiffs {
  constructor(public tagId: number = null, 
              public tagName: string = '', 
              public tagIcon: string = '', 
              public diffs: IWJTagPresenceDiff[] = []) {}
}
