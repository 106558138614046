<section class="heading-block">
  <h2 class="heading">No email messages have been received in this inbox yet.</h2>
  <p class="heading-info">
    We will automatically validate landing pages found in email messages when following these steps:
  </p>
</section>

<ol class="steps">
  <li class="step">
    <span class="step-highlight">Get Inbox Address</span>
    - Copy the custom Inbox address below. After completion, a summary email with the results
    will be sent to all users assigned to the associated Audits or Alerts.
  </li>
  <li class="step">
    <span class="step-highlight">Send your Email Messages.</span>
    Add this custom address to your test send group(s) or forward any message. Any message sent to this inbox will
    automatically run any associated Audits (configured on the next step).
  </li>
  <li class="step">
    <span class="step-highlight">Review Results.</span>
    After completion, a summary email with the results will be sent to all users assigned to the associated Audits or
    Alerts.
  </li>
</ol>

<section class="copy-block">
  <h3 class="copy-block-heading">EMAIL INBOX ADDRESS</h3>
  <op-copy-box [copyValue]="inbox?.emailAddress"></op-copy-box>
</section>
