<i
  class="op-menu-icon"
  [ngClass]="{ 'icon-more': horizontal && !icon, 'icon-more-vert': !horizontal && !icon, 'icon-gear': !horizontal && icon }"
  mat-button
  [matMenuTriggerFor]="menu"
  op-selector="open-menu-options-btn"
>
</i>
<mat-menu
  #menu="matMenu"
  xPosition="before"
  yPosition="below"
  [overlapTrigger]="false"
  class="mat-menu-overlay"
  backdropClass="no-filter"
  [class]="class"
>
  <ul>
    <ng-container *ngFor="let item of items">
      <li
        *ngIf="!item.hidden || !item.hidden(model)"
        mat-menu-item
        matTooltip="{{item.disableText && item.disableText(model, currentUser) ? item.disableText(model, currentUser) : ''}}"
        [matTooltipPosition]="tooltipPosition"
        [matTooltipClass]="{ 'op-menu-item-tooltip': true }"
      >
        <button
          class="mat-button"
          (click)="onItemClick($event, item)"
          [disabled]="item.disable ? item.disable(model, currentUser): false"
        >
          <i class={{item.icon}}></i>{{item.label}}
        </button>
      </li>
    </ng-container>
  </ul>
</mat-menu>
