import { ICookieOrigin } from '@app/components/audit-reports/page-details/page-details.models';

export interface ICookieOriginStory {
  uniqueInitiators: number;
  actionCount: number;
  actions: ICookieOrigin[];
}

export interface ICookiePropertyChange {
  name: string;
  oldValue: any;
  newValue: any;
}

export enum ECookieExpirationProperty {
  CookieTTL = 'cookieTTL',
  TTLInMinutes = 'ttlInMinutes',
  Expires = 'expires',
  Duration = 'duration',
}
