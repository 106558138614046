import { Component, Input } from '@angular/core';
import { IAdminAccount } from '@app/components/admin-portal/manage-accounts/manage-accounts.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'account-users',
  templateUrl: './account-users.component.html',
  styleUrls: ['./account-users.component.scss']
})
export class AccountUsersComponent {
  adminUsers: boolean = true;
  users: IAdminAccount;

  @Input() accountV2: IAdminAccount;

  constructor() { }
}
