import { ComparisonLibraryService } from './../../comparison-library/comparison-library.service';
import { OneTimeComparisonSetupComponent } from './one-time-comparison-setup/one-time-comparison-setup.component';
import { IComparisonTag, IAccountTagSettings } from './../comparisons.models';
import { IComparisonModalData, IOneTimeFormModel } from './one-time-comparison.models';
import { stepIdToLabelMap } from './one-time-comparison.constants';
import { EOneTimeComparisonStep } from './one-time-comparison.enums';
import { Component, Inject, Optional, ChangeDetectorRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { IOpModalWithTabsContent } from '@app/components/shared/components/op-modal';
import { IButton } from '@app/models/commons';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComparisonsService } from '../comparisons.service';
import { IComparison } from '../comparisons.models';
import { finalize, catchError } from 'rxjs/operators';
import { forkJoin, ReplaySubject, of, Subject, throwError } from 'rxjs';
import { ComparisonVariableSelectorService } from '../shared/comparison-variable-selector/comparison-variable-selector.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'one-time-comparison',
  templateUrl: './one-time-comparison.component.html',
  styleUrls: ['./one-time-comparison.component.scss']
})
export class OneTimeComparisonComponent implements IOpModalWithTabsContent, OnInit, OnDestroy {
  
  readonly comparisonSteps = EOneTimeComparisonStep;

  @ViewChild(OneTimeComparisonSetupComponent) setupFormComp: OneTimeComparisonSetupComponent;

  comparisonForm: UntypedFormGroup;
  submitted: boolean = false;
  showSpinner = true;

  comparison: IComparison;

  accountTagsSubject = new ReplaySubject<IAccountTagSettings[]>();
  accountTagSettingsMap = new Map<number, IAccountTagSettings>();

  comparisonTags: IComparisonTag[] = [];

  matchTagsOptions: Map<number, string[]> = new Map();

  destroy$ = new Subject<void>();

  title = 'One Time Comparison';
  currentStep = this.comparisonSteps.SETUP;
  steps: string[] = Array.from(stepIdToLabelMap.values());
  rightFooterButtons: IButton[] = [{
    label: 'Back',
    action: this.goToStep.bind(this, this.comparisonSteps.SETUP),
    icon: 'icon-back-empty',
    hidden: true,
    primary: false,
  }, {
    label: 'Continue',
    icon: 'icon-forward-empty',
    action: this.goToStep.bind(this, this.comparisonSteps.MATCH_TAGS),
    primary: false,
  }, {
    label: 'Save',
    action: () => this.save(),
    primary: true,
  }, {
    label: 'Save & Run',
    action: () => this.save(true),
    primary: true,
  }];
  
  constructor(@Inject(MAT_DIALOG_DATA) @Optional() public data: IComparisonModalData,
              private formBuilder: UntypedFormBuilder,
              private dialogRef: MatDialogRef<OneTimeComparisonComponent>,
              private changeDetection: ChangeDetectorRef,
              private comparisonsService: ComparisonsService,
              private comparisonLibraryService: ComparisonLibraryService,
              private comparisonVariableSelectorService: ComparisonVariableSelectorService) {
    
    this.initForm();

  }

  ngOnInit(): void {
    forkJoin(
      this.comparisonsService.getAccountTags(),
      this.data.id ? this.comparisonsService.get(this.data.id) : of(null)
    ).pipe(
      finalize(() => {
        this.showSpinner = false;
      })
    ).subscribe(([accountTags, comparison]) => {
      if (comparison) this.comparison = comparison;
        this.accountTagsSubject.next(accountTags);

        this.accountTagSettingsMap = accountTags.reduce(
          (map, tag) => map.set(tag.tagId, tag),
          new Map()
        );

      if (comparison) this.fillSetupTab(comparison);
    });
  }

  ngOnDestroy(): void {
    this.accountTagsSubject.unsubscribe();
    this.destroy$.next();
  }

  private initForm(): void {
    this.comparisonForm = this.formBuilder.group({
      setup: this.formBuilder.group({
        name: this.formBuilder.control('', Validators.required),
        labels: this.formBuilder.control([]),
        destinationAudit: this.formBuilder.group({
          id: this.formBuilder.control('', Validators.required),
          run: this.formBuilder.control('', Validators.required)
        }),
        baselineAudit: this.formBuilder.group({
          id: this.formBuilder.control('', Validators.required),
          run: this.formBuilder.control('', Validators.required)
        }), 
        tags: this.formBuilder.array([
          this.comparisonVariableSelectorService.createTagGroup(this.destroy$)
        ]),
        ignoreUrlDomain: this.formBuilder.control(false),
        ignoreQueryParameters: this.formBuilder.control(false)
      }),
      matchTags: this.formBuilder.group({
        tags: this.formBuilder.control([]),
      })
    });
  }

  private fillSetupTab(comparison: IComparison): void {
    const {tags, ...restForm} = IComparison.toOneTimeComparisonSetupModel(comparison, this.accountTagSettingsMap);

    this.setupForm.patchValue(restForm);

    const tagsFormArray = this.setupForm.get('tags') as UntypedFormArray;
    tagsFormArray.clear();
    tags.forEach(t => tagsFormArray.push(this.comparisonVariableSelectorService.createTagGroup(this.destroy$, t)));
  }

  goToStep(stepId: EOneTimeComparisonStep): void {
    this.submitted = false;
    if (this.comparisonForm.invalid) {
      this.submitted = true;
      this.changeDetection.detectChanges();
      return;
    }

    this.currentStep = stepId;
    this.updateButtons();
    
    if (this.currentStep === EOneTimeComparisonStep.MATCH_TAGS) 
      this.updateMatchTags();
  }

  private updateButtons(): void {
    this.rightFooterButtons[0].hidden = this.currentStep === EOneTimeComparisonStep.SETUP;
    this.rightFooterButtons[1].hidden = this.currentStep === EOneTimeComparisonStep.MATCH_TAGS;
  }

  private updateMatchTags(): void {
    this.matchTagsForm.get('tags').setValue(this.setupFormComp.getTagsWithVariables());
  }

  save(shouldRun: boolean = false): void {
    this.submitted = true;
    this.changeDetection.detectChanges();

    if (this.comparisonForm.invalid) return;
    if (this.currentStep === EOneTimeComparisonStep.SETUP) this.updateMatchTags();

    const comparisonCreate = IOneTimeFormModel.toComparisonCreate(
      this.comparisonForm.value,
      this.accountTagSettingsMap
    );
    if (this.data && this.data.id) {
      this.comparisonsService.update(comparisonCreate, this.data.id).subscribe(() => {
        this.closeModal();
        if (shouldRun) this.runComparison(this.data.id, comparisonCreate.name);
      });
    } else {
      this.comparisonsService.create(comparisonCreate).subscribe(comparisonId => {
        this.closeModal();
        if (shouldRun) this.runComparison(comparisonId, comparisonCreate.name);
      });
    }
  }

  private runComparison(comparisonId: number, comparisonName: string): void {
    this.comparisonLibraryService.runComparison(comparisonId, comparisonName)
      .pipe(catchError(err => err))
      .subscribe();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  get setupForm(): UntypedFormGroup {
    return this.comparisonForm.get('setup') as UntypedFormGroup;
  }

  get matchTagsForm(): UntypedFormGroup {
    return this.comparisonForm.get('matchTags') as UntypedFormGroup;
  }

}
