import { EAlertDataSourcesFilterTypes } from './alert-data-sources-filter-bar/alert-data-sources-filter-bar.enums';
import { DefaultAlertAssignmentsPagination } from './../alert.constants';
import { format } from 'date-fns';
import { EventEmitter, OnChanges, Output } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ComponentChanges } from '@app/models/commons';
import { AlertService } from '@app/components/alert/alert.service';
import { ESimpleTableColumnType } from '@app/components/shared/components/selectable-table/simple-table/simple-table.enums';
import { ISimpleTableColumn, ISimpleTableItemsState } from '@app/components/shared/components/selectable-table/simple-table/simple-table.models';
import { ISelectableTableSelectionChange } from '@app/components/shared/components/selectable-table/selectable-table.models';
import { ILabel } from '@app/components/shared/services/label.service';
import { DefaultAlertAssignmentsSorting } from '../alert.constants';
import { ISearchAlertAssignmentsRequest } from '../alert.models';
import { IAlertDataSourcesTableItem } from './alert-data-sources.models';
import { EAlertDataSourcesTableSortColumn } from './alert-data-sources.enums';
import { EAlertModalType } from '@app/components/alert/alert.enums';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-data-sources',
  templateUrl: './alert-data-sources.component.html',
  styleUrls: ['./alert-data-sources.component.scss']
})
export class  AlertDataSourcesComponent implements OnInit, OnChanges {

  @Input() formGroup: UntypedFormGroup;
  @Input() modalType: EAlertModalType;
  @Input() readOnlyLabel: string;
  @Input() labelsMap: Map<number, ILabel>;
  @Output() filtersUpdated = new EventEmitter<IOpFilterBarV2Filter<EAlertDataSourcesFilterTypes>[]>();

  dataSourcesState: ISimpleTableItemsState<IAlertDataSourcesTableItem>;
  labels: ILabel[] = [];

  assignments: ISearchAlertAssignmentsRequest = {};
  sorting = { ...DefaultAlertAssignmentsSorting };
  pagination = { ...DefaultAlertAssignmentsPagination };

  readonly EAlertModalType = EAlertModalType;
  readonly columns: ISimpleTableColumn<IAlertDataSourcesTableItem>[] = [{
    propName: 'name',
    sortKey: EAlertDataSourcesTableSortColumn.ItemName,
    title: 'Data Source Name',
    type: ESimpleTableColumnType.Text
  }, {
    propName: 'lastRunAt',
    sortKey: EAlertDataSourcesTableSortColumn.RunDate,
    title: 'Run Date',
    type: ESimpleTableColumnType.Text
  }, {
    propName: 'labels',
    title: 'Labels',
    type: ESimpleTableColumnType.Chips
  }];

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.fetchDataSources();
  }

  ngOnChanges(changes: ComponentChanges<AlertDataSourcesComponent>) {
    if (changes.labelsMap?.currentValue && changes.labelsMap?.currentValue !== changes.labelsMap?.previousValue) {
      this.labels = [...this.labelsMap.values()];
    }
  }

  private fetchDataSources() {
    this.dataSourcesState = { items: [], loading: true };
    this.alertService.searchDataSources(this.assignments, this.sorting, this.pagination)
      .subscribe(({ items, metadata: { pagination } }) => {
        this.pagination = pagination;

        const mappedItems = items.map(({ itemId, itemName, itemLastRunAt, itemLabels }) => ({
          id: itemId,
          name: itemName,
          labels: itemLabels.map(id => this.labelsMap.get(id)),
          lastRunAt: itemLastRunAt ? format(new Date(itemLastRunAt), 'yyyy/MM/dd, hh:mm aaa') : null,
        }));
        this.dataSourcesState = { items: mappedItems, loading: false };
      });
  }

  selectionChanged({ added, removed }: ISelectableTableSelectionChange<IAlertDataSourcesTableItem>) {
    let selectedItems = this.itemsControl.value as IAlertDataSourcesTableItem[];
    if (removed) {
      selectedItems = selectedItems.filter(
        selectedItem => !removed.find(r => r.id === selectedItem.id)
      )
    }

    if (added) {
      selectedItems = [ ...selectedItems, ...added ];
    }

    this.itemsControl.setValue([...new Set(selectedItems)]);
  }

  sortChanged(sort: Sort) {
    this.sorting = {
      sortBy: sort.active as EAlertDataSourcesTableSortColumn,
      sortDesc: sort.direction === 'desc',
    };
    this.pagination.currentPageNumber = 0;
    this.fetchDataSources();
  }

  paginationChanged(pageIndex: number) {
    this.pagination.currentPageNumber = pageIndex;
    this.fetchDataSources();
  }

  filtersChanged(filters: IOpFilterBarV2Filter<EAlertDataSourcesFilterTypes>[]) {
    this.assignments = filters.reduce((acc: ISearchAlertAssignmentsRequest, filter) => {
      switch (filter.type) {
        case EAlertDataSourcesFilterTypes.Name:
          const itemName = (filter.value as string[])[0];
          return { ...acc, itemName };
        case EAlertDataSourcesFilterTypes.Label:
          const labelIds = filter.value as number[];
          const itemLabels = acc.itemLabels ? [ ...acc.itemLabels, ...labelIds ] : labelIds;
          return { ...acc, itemLabels };
        case EAlertDataSourcesFilterTypes.Folder:
          const checkedItems = filter.menuItems
            .map(folder => {
              const checkedDomains = folder.children
                .filter(({ checked }) => checked)
                .map(({ id }) => id);

              if (!folder.checked && checkedDomains.length === 0) return null;

              const allDomainsChecked = checkedDomains.length === folder.children.length;
              return {
                folderId: folder.id,
                domains: allDomainsChecked ? null : checkedDomains
              };
            })
            .filter(folder => !!folder);
          return { ...acc, folders: checkedItems };
        default:
          return acc;
      }
    }, {});
    this.pagination.currentPageNumber = 0;
    this.fetchDataSources();
  }

  get itemsControl(): UntypedFormControl {
    return this.formGroup.get('items') as UntypedFormControl;
  }

}
