<div *ngIf="bannerText"
     class="banner"
     [class.clickable]="bannerClickAction"
     (click)="bannerClickAction()"
     [class.banner-yellow]="bannerColor === EUseCaseBannerColor.Yellow"
     [class.banner-gray]="bannerColor === EUseCaseBannerColor.Gray"
>
  {{ bannerText }}
  <mat-icon>keyboard_double_arrow_right</mat-icon>
</div>
<div class="use-cases-widget"
     [attr.op-selector]="opSelector"
     [class.widget-hovered]="widgetHover">
  <div class="left">
    <div class="card"
         [ngClass]="{
            'split-card': splitCard,
            'full-card': !splitCard
          }"
    >
      <div class="top"
           [class.clickable]="topClickAction"
           [matTooltip]="!richTooltip ? topTooltip : ''"
           matTooltipPosition="above"
           matTooltipTouchGestures="off"
           cdkOverlayOrigin
           (mouseenter)="labelHover()"
           (mouseout)="labelBlur()"
           #trigger="cdkOverlayOrigin"
           (click)="topClickAction ? topClickAction() : null"
      >
        <ng-container *ngIf="topLeftCustomContent">
          <ng-content select="[topLeft]"></ng-content>
        </ng-container>
        <ng-container *ngIf="!topLeftCustomContent">
          <mat-icon *ngIf="topIconName"
                    class="top-text-icon"
                    [ngClass]="{
                      'color-graph-green' : topIconMeaning === 'positive',
                      'color-graph-yellow': topIconMeaning === 'sortofpositive',
                      'color-graph-orange': topIconMeaning === 'sortofnegative',
                      'color-graph-red'   : topIconMeaning === 'negative',
                      'material-icons-outlined': topIconOutline
                    }"
          >
            {{ topIconName }}
          </mat-icon>
          <img *ngIf="topIconPath" [src]="topIconPath" alt="icon" class="top-tag-icon"/>
          <div class="top-text-label" [innerHTML]="topText"></div>
          <div class="top-text-value"
               [innerHTML]="topValue"
               [ngClass]="{
                  'color-graph-green' : topValueMeaning === 'positive',
                  'color-graph-yellow': topValueMeaning === 'sortofpositive',
                  'color-graph-orange': topValueMeaning === 'sortofnegative',
                  'color-graph-red'   : topValueMeaning === 'negative'
              }"
          ></div>
        </ng-container>
      </div>
      <div class="bottom"
           *ngIf="!hideBottom"
           [matTooltip]="bottomTooltip"
           [matTooltipShowDelay]="tooltipDelay"
           matTooltipPosition="above"
           matTooltipTouchGestures="off"
      >
        <stats-popup-panel *ngIf="statsData" [data]="statsData"
        ></stats-popup-panel>
        <ng-container *ngIf="bottomLeftCustomContent">
          <ng-content select="[bottomLeft]"></ng-content>
        </ng-container>
        <ng-container *ngIf="!bottomLeftCustomContent">
          <div class="bottom-text-label" [innerHTML]="bottomText"></div>
          <div class="bottom-text-value">{{ bottomValue }}</div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="right">
    <ng-content *ngIf="!loading" select="[right]"></ng-content>
    <div class="loading" *ngIf="loading">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </div>
  <ng-template
    *ngIf="richTooltip"
    cdkConnectedOverlay
    cdkConnectedOverlayPanelClass="no-pointer-events"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="widgetLabelHover"
    cdkConnectedOverlayPush
    [cdkConnectedOverlayPositions]="positionPairs"
    [cdkConnectedOverlayWidth]="360"
  >
    <ng-content select="[overlayTooltip]"></ng-content>
  </ng-template>
</div>
