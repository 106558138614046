import { IFullscreenChartConfig } from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';

export enum PrivacyRequestsTrendName {
  Evaluated = 'request_count',
  Unique = 'unique_domains',
  Geolocations = 'unique_geos',
  Approved = 'unique_approved_domains',
  Unapproved = 'unique_unapproved_domains'
}

function defaultChartConfig(title: string): IFullscreenChartConfig {
  return {
    title: title,
    data: [],
    sideLabel: 'number',
    showXAxis: true,
    showYAxis: true,
    showSummaryLines: true,
    tooltip: (value: number) => `${value} request${value === 1 ? '' : 's'}`
  };
}

export const EVALUATED_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Network Requests Evaluated');
export const UNIQUE_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Unique Domains');
export const GEOLOCATIONS_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Unique Geolocations');
export const APPROVED_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Approved');
export const UNAPPROVED_CHART_CONFIG: IFullscreenChartConfig = defaultChartConfig('Unapproved');

export enum EPrivacyRequestsExportType {
  geolocationAndRequestDomainCompliance= 'request_privacy_compliance',
  pages = 'request_privacy_pages',
  requestPages = 'request_privacy_request_pages'
}
