export enum ENotificationCenterModalFilterTypes {
  Name = 'itemName',
  Label = 'itemLabels'
}

export enum ENotificationCenterSetupMode {
  Edit,
  Create
}

export enum ENotificationCenterTabs {
  Emails,
  Items
}
