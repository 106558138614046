<op-modal [leftFooterButtons]="leftFooterButtons"
          [rightFooterButtons]="rightFooterButtons"
          class="cc-import-modal-wrapper"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="title" [titleIcon]="titleIcon" (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="import-modal-body">

      <div>Enter a URL + Location below and we will detect your OneTrust consent categories found there. 
        To import or update consent categories for multiple domains and/or locations, simply rerun this process 
        for each unique combination.</div>

      <div class="form-container" [formGroup]="formImportCookies">
        <div class="onetrust-options-group">
          <mat-form-field appearance="outline" floatLabel="always" class="styled-mat-input onetrust-control onetrust-control-left"
                          matTooltip="Enter a URL (e.g. www.observepoint.com)"
                          matTooltipPosition="above">
            <mat-label class="field-title">Where can the consent banner be found?*</mat-label>
            <input matInput type="text" (keydown.enter)="onEnterKeyPressed($event)" placeholder="Enter a URL (e.g. www.observepoint.com)" formControlName="ctlUrl" [disabled]="!groupsReady"/>
            <mat-error *ngIf="false">
              This field is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" floatLabel="always" class="styled-mat-input onetrust-control onetrust-control-right"
                    matTooltipPosition="above">
            <mat-label class="field-title">From Which Location?*</mat-label>
            <mat-select formControlName="ctlLocation" placeholder="Choose a location" [disabled]="!groupsReady">
              <mat-select-trigger class="location-input">
                <div class="flag-icon flag-icon-{{selectedLocation?.localeCode.slice(0, 2)}}"></div>
                <div class="location-label">{{selectedLocation?.localeName}}</div>
              </mat-select-trigger>
              <mat-option class="location-search-field">
                <ngx-mat-select-search [formControl]="ctlLocationFilter" placeholderLabel="Search"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let countryCode of filteredLocations | async" [value]="countryCode['localeName']" class="loc-autocomplete">
                <div class="flag-icon flag-icon-{{ countryCode['localeCode'].slice(0, 2) }}"></div>
                <div class="location-label">{{ countryCode['localeName'] }}</div>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="false">
              This field is required
            </mat-error>
          </mat-form-field>
        </div>

        <button mat-flat-button class="op-upload-button" (click)="detectNewCCsToImport()" [disabled]="!groupsReady || initializingImport">Detect Your Consent Categories</button> 
        <div class="detect-consent-categories-text">It will take 10 - 30 seconds to gather the consent categories.</div>
        <div *ngIf="this.detectDomain && !detectingCategories" class="options-selected-container">
            Detected with {{this.detectDomain}}, {{this.cacheGeo.localeName}}
        </div>
        <div class="spinner-container" *ngIf="!groupsReady">
          <mat-spinner [diameter]="50" [strokeWidth]="10"></mat-spinner> <div class="import-consent-categories">{{importingRecords ? 'importing' : 'detecting'}} OneTrust consent categories</div>
        </div>
        <div class="spinner-container" *ngIf="initializingImport">
          <mat-spinner [diameter]="50" [strokeWidth]="10"></mat-spinner> <div class="import-consent-categories">preparing for import</div>
        </div>
        <div class="error-message-container" *ngIf="showErrorMessage">
          <div class="error-message-title">
            <mat-icon class="error-message-icon">info_outline</mat-icon>
            <div class="error-message-pad">{{errorMessageTitle}}</div>
          </div>
          <div class="error-message-body">{{errorMessageBody}}</div>
        </div>

        <div *ngIf="showResults" class="onetrust-options">
          <mat-label class="field-title">Found OneTrust Categories</mat-label>
          <div class="onetrust-groups">
            <div formArrayName="ctlGroups" *ngFor="let group of groups; let i = index">
              <div class="onetrust-group" [formGroupName]="i">
                <div class="onetrust-group-item">
                  <div class="onetrust-group-item-check">
                    <mat-icon 
                          class="expand-contract-icon"
                          [ngClass]="{'rotate-caret': !group.expanded}"
                          [attr.op-selector]="'expand-splitter-content-btn'"
                          (click)="toggleGroupExpanded(group)">keyboard_arrow_down</mat-icon>
                    <mat-checkbox color="primary" [checked]="group.selected" (change)="groupSelectedChanged(group)" formControlName="selected">
                      <span class="checkbox-label">{{group?.groupName?.length > MAX_GROUP_NAME ? (group?.groupName | slice:0:MAX_GROUP_NAME) + '...' : group?.groupName}} ({{group.cookies.length}} approved cookies) &nbsp;-</span>
                      <ng-container [ngSwitch]="group.updateType">
                        <span *ngSwitchCase="IConsentGroupType.Insert" class="checkbox-label-new">{{TypeMessaging.Inserted}}</span>
                        <span *ngSwitchCase="IConsentGroupType.Update" class="checkbox-label-update">{{TypeMessaging.Updated}}</span>
                        <span *ngSwitchCase="IConsentGroupType.Delete" class="checkbox-label-delete">{{TypeMessaging.Deleted}}</span>
                        <span *ngSwitchCase="IConsentGroupType.NoChangeCookies" class="checkbox-label-update">{{getNoChangeCookieMessage(group)}}</span>
                        <span *ngSwitchCase="IConsentGroupType.NoAction_ExistingNotUpdated" class="checkbox-label-nochange">{{TypeMessaging.NoAction_ExistingNotUpdated}}</span>
                        <span *ngSwitchCase="IConsentGroupType.NoAction_NewlyDetectedNotAdded" class="checkbox-label-nochange">{{TypeMessaging.NoAction_NewlyDetectedNotAdded}}</span>
                        <span *ngSwitchCase="IConsentGroupType.NoAction_NotFoundNotDeleted" class="checkbox-label-nochange">{{TypeMessaging.NoAction_NotFoundNotDeleted}}</span>
                        <span *ngSwitchDefault class="checkbox-label-nochange">{{TypeMessaging.NotChanged}}</span>
                      </ng-container>
                    </mat-checkbox>
                  </div>
                </div>
                <mat-accordion>
                  <mat-expansion-panel [expanded]="group.expanded" (opened)="triggerChangeDetection()" class="cookie-group-panel">
                    <div formArrayName="ctlCookies" class="cookie-group-column">
                      <div *ngFor="let cookie of group?.cookies; let j = index" class="cookie-group-column-item">
                        <div [formGroupName]="j" class="cookie-item">
                          <div class="checkbox-label-container">
                            <span class="checkbox-label">{{getCookieName(cookie)}}</span>
                            <span class="checkbox-label-domain"> (domain: {{getCookieDomain(cookie)}})</span>
                            <mat-icon *ngIf="cookie.description" class="cookie-info-icon" matTooltip="{{cookie.description}}">info_outline</mat-icon>
                            <ng-container [ngSwitch]="cookie.updateType">
                              <span *ngSwitchCase="IConsentGroupType.Insert" class="checkbox-label-new">{{TypeMessaging.Inserted}}</span>
                              <span *ngSwitchCase="IConsentGroupType.Update" class="checkbox-label-update">{{TypeMessaging.Updated}}</span>
                              <span *ngSwitchCase="IConsentGroupType.Delete" class="checkbox-label-delete">{{TypeMessaging.Deleted}}</span>
                              <span *ngSwitchCase="IConsentGroupType.NoAction_ExistingNotUpdated" class="checkbox-label-nochange">{{TypeMessaging.NoAction_ExistingNotUpdated}}</span>
                              <span *ngSwitchCase="IConsentGroupType.NoAction_NewlyDetectedNotAdded" class="checkbox-label-nochange">{{TypeMessaging.NoAction_NewlyDetectedNotAdded}}</span>
                              <span *ngSwitchCase="IConsentGroupType.NoAction_NotFoundNotDeleted" class="checkbox-label-nochange">{{TypeMessaging.NoAction_NotFoundNotDeleted}}</span>
                              <span *ngSwitchDefault class="checkbox-label-nochange">{{TypeMessaging.NotChanged}}</span>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>

          <!-- Previously imported OneTrust categories not found -->
          <div *ngIf="showResultsNF" class="spacing-groups">
            <mat-label class="field-title">Previously Imported OneTrust Categories Not Found</mat-label>
            <div class="onetrust-groups">
              <div formArrayName="ctlGroupsNF">
                <div *ngFor="let groupNF of groupsNF; let i = index">
                  <div [formGroupName]="i">
                    <div class="onetrust-group-item">

                      <!-- Groups Not Found (to be deleted)  -->
                      <div class="onetrust-group-item-check">
                        <mat-icon 
                              class="expand-contract-icon"
                              [ngClass]="{'rotate-caret': !groupNF.expanded}"
                              [attr.op-selector]="'expand-splitter-content-btn'"
                              (click)="toggleGroupExpanded(groupNF)">keyboard_arrow_down</mat-icon>
                        <mat-checkbox color="primary" [checked]="groupNF.selected" (change)="groupSelectedChangedNF(groupNF)" formControlName="selectedNF">
                          <span class="checkbox-label">{{groupNF?.groupName?.length > MAX_GROUP_NAME ? (groupNF?.groupName | slice:0:MAX_GROUP_NAME) + '...' : groupNF?.groupName}} ({{groupNF.cookies.length}} approved cookies) &nbsp;-</span>
                          <ng-container [ngSwitch]="groupNF.updateType">
                            <span *ngSwitchCase="IConsentGroupType.Insert" class="checkbox-label-new">{{TypeMessaging.Inserted}}</span>
                            <span *ngSwitchCase="IConsentGroupType.Update" class="checkbox-label-update">{{TypeMessaging.Updated}}</span>
                            <span *ngSwitchCase="IConsentGroupType.Delete" class="checkbox-label-delete">{{TypeMessaging.Deleted}}</span>
                            <span *ngSwitchCase="IConsentGroupType.NoAction_ExistingNotUpdated" class="checkbox-label-nochange">{{TypeMessaging.NoAction_ExistingNotUpdated}}</span>
                            <span *ngSwitchCase="IConsentGroupType.NoAction_NewlyDetectedNotAdded" class="checkbox-label-nochange">{{TypeMessaging.NoAction_NewlyDetectedNotAdded}}</span>
                            <span *ngSwitchCase="IConsentGroupType.NoAction_NotFoundNotDeleted" class="checkbox-label-nochange">{{TypeMessaging.NoAction_NotFoundNotDeleted}}</span>
                            <span *ngSwitchDefault class="checkbox-label-nochange">{{TypeMessaging.NotChanged}}</span>
                          </ng-container>
                        </mat-checkbox>
                      </div>
                    </div>
                    <mat-accordion>
                      <mat-expansion-panel [expanded]="groupNF.expanded" (opened)="triggerChangeDetection()" class="cookie-group-panel">
                        <div formArrayName="ctlCookiesNF" class="cookie-group-column">
                          <div *ngFor="let cookie of groupNF?.cookies; let j = index" class="cookie-group-column-item">
                            <div [formGroupName]="j" class="cookie-item">
                              <div class="checkbox-label-container">
                                <span class="checkbox-label">{{getCookieName(cookie)}}</span>
                                <span class="checkbox-label-domain"> (domain: {{getCookieDomain(cookie)}})</span>
                                <mat-icon *ngIf="cookie.description" class="cookie-info-icon" matTooltip="{{cookie.description}}">info_outline</mat-icon>
                                <ng-container [ngSwitch]="cookie.updateType">
                                  <span *ngSwitchCase="IConsentGroupType.Insert" class="checkbox-label-new">{{TypeMessaging.Inserted}}</span>
                                  <span *ngSwitchCase="IConsentGroupType.Update" class="checkbox-label-update">{{TypeMessaging.Updated}}</span>
                                  <span *ngSwitchCase="IConsentGroupType.Delete" class="checkbox-label-delete">{{TypeMessaging.Deleted}}</span>
                                  <span *ngSwitchCase="IConsentGroupType.NoAction_ExistingNotUpdated" class="checkbox-label-nochange">{{TypeMessaging.NoAction_ExistingNotUpdated}}</span>
                                  <span *ngSwitchCase="IConsentGroupType.NoAction_NewlyDetectedNotAdded" class="checkbox-label-nochange">{{TypeMessaging.NoAction_NewlyDetectedNotAdded}}</span>
                                  <span *ngSwitchCase="IConsentGroupType.NoAction_NotFoundNotDeleted" class="checkbox-label-nochange">{{TypeMessaging.NoAction_NotFoundNotDeleted}}</span>
                                  <span *ngSwitchDefault class="checkbox-label-nochange">{{TypeMessaging.NotChanged}}</span>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ng-template>
</op-modal>

