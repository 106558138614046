<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="data.title"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="modal-body">
      <div class="sizes-container">
        <div class="input-container">
          <label>{{data.minLabel || 'MINIMUM SIZE (BYTES)'}}</label>
          <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always">
            <mat-label>{{data.minPlaceholder || 'MIN #'}}</mat-label>
            <input matInput (keyup)="onInputChanged()" [(ngModel)]="min" placeholder="0 (default)"/>
            <button *ngIf="min" matSuffix mat-icon-button aria-label="Clear" (click)="min=''; onInputChanged()">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <mat-icon class="from-to-icon">open_in_full</mat-icon>
        <div class="input-container">
          <label>{{data.maxLabel || 'MAXIMUM SIZE (BYTES)'}}</label>
          <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always">
            <mat-label>{{data.maxPlaceholder || 'MAX #'}}</mat-label>
            <input matInput (keyup)="onInputChanged()" [(ngModel)]="max" placeholder="∞ (default)"/>
            <button *ngIf="max" matSuffix mat-icon-button aria-label="Clear" (click)="max=''; onInputChanged()">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <p class="validation-description" *ngIf="this.validationMessage"><mat-icon>warning</mat-icon> {{this.validationMessage}}</p>
      <p class="hint-description" *ngIf="!this.validationMessage">{{this.hintMessage}}</p>
    </div>
    <ng-template #loadingTpl>
      <mat-spinner class="spinner" [diameter]="30" [strokeWidth]="4"></mat-spinner>
    </ng-template>
  </ng-template>
</op-modal>
