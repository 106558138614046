import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ComponentChanges } from '@app/models/commons';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import {
  EAlertDataSourcesFilterTypes
} from './email-inbox-editor-data-sources-filter-bar/email-inbox-editor-data-sources-filter-bar.enums';
import { ISearchAlertAssignmentsRequest } from '@app/components/alert/alert.models';
import {
  DefaultAlertAssignmentsPagination,
  DefaultAlertAssignmentsSorting
} from '@app/components/alert/alert.constants';
import { EmailInboxEditorDataSourcesTableColumns, } from './email-inbox-editor-data-sources.enums';
import { EEmailInboxModalType } from '../email-inbox-editor.constants';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { MatTableDataSource } from '@angular/material/table';
import { IEmailInboxConfigurationDTO } from '@app/components/email-inboxes/email-inboxes.models';
import { EAlertModalType } from '@app/components/alert/alert.enums';
import { IAuditModel } from '@app/components/modals/modalData';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { IAuditEditorCloseOptions } from '@app/components/audit/audit-editor/audit-editor.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  EmailInboxAuditSelectorComponent
} from '@app/components/email-inboxes/email-inbox-editor/email-inbox-editor-data-sources/email-inbox-audit-selector/email-inbox-audit-selector.component';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { Observable } from 'rxjs';
import {
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  ESelectableTableSelectionMode
} from '@app/components/shared/components/selectable-table/selectable-table.models';
import { filter } from 'rxjs/operators';
import { EAuditTabUrlSources } from '@app/components/audit/audit.constants';

@Component({
  selector: 'op-email-inbox-editor-data-sources',
  templateUrl: './email-inbox-editor-data-sources.component.html',
  styleUrls: ['./email-inbox-editor-data-sources.component.scss'],
})
export class EmailInboxEditorDataSourcesComponent implements OnChanges {
  protected readonly TableColumn = EmailInboxEditorDataSourcesTableColumns;
  protected readonly EFilterSpinnerState = EFilterSpinnerState;
  protected readonly EAlertModalType = EAlertModalType;
  private _associatedAuditItems: IEmailInboxConfigurationDTO[];

  @Input() modalType: EEmailInboxModalType;
  @Input() readOnlyLabel: string;
  @Input() labelsMap: Map<number, ILabel>;
  @Output() selectedAuditsChange = new EventEmitter<IEmailInboxConfigurationDTO[]>();
  @Output() auditUpdated = new EventEmitter<IAuditModel>();
  @Output() auditsDisassociated = new EventEmitter<IEmailInboxConfigurationDTO[]>();
  @Output() auditCreated = new EventEmitter<IAuditModel>();

  disassociatedAudits: IEmailInboxConfigurationDTO[] = [];

  @Input() set associatedAuditItems(audits: IEmailInboxConfigurationDTO[]) {
    this._associatedAuditItems = audits;
    this.selectedAuditsChange.emit(audits);

    if (audits.length) {
      // Create an async function to handle labels loading logic
      const loadLabels = async () => {
        const auditItems = await Promise.all(audits.map(async a => ({
          ...a,
          labels: await this.labelService.getLabelsByIds(a.auditLabels)
        })));
        this.dataSource = new MatTableDataSource(auditItems);
      };

      loadLabels();
    } else {
      this.dataSource = null;
    }
  }

  get associatedAuditItems(): IEmailInboxConfigurationDTO[] {
    return this._associatedAuditItems;
  }

  @Output() filtersUpdated = new EventEmitter<IOpFilterBarV2Filter<EAlertDataSourcesFilterTypes>[]>();

  labels: ILabel[] = [];

  assignments: ISearchAlertAssignmentsRequest = {};
  sorting = { ...DefaultAlertAssignmentsSorting };
  pagination = { ...DefaultAlertAssignmentsPagination };

  readonly EEmailInboxModalType = EEmailInboxModalType;

  readonly columns = [
    EmailInboxEditorDataSourcesTableColumns.Name,
    EmailInboxEditorDataSourcesTableColumns.Labels,
    EmailInboxEditorDataSourcesTableColumns.Remove,
  ];

  dataSource: MatTableDataSource<IEmailInboxConfigurationDTO>;

  constructor(
    private auditDataService: IAuditDataService,
    private modalService: OpModalService,
    private labelService: LabelService,
  ) {
  }

  ngOnChanges(changes: ComponentChanges<EmailInboxEditorDataSourcesComponent>) {
    if (changes.labelsMap?.currentValue && changes.labelsMap?.currentValue !== changes.labelsMap?.previousValue) {
      this.labels = [...this.labelsMap.values()];
    }
  }

  disassociateAudit(audit: IEmailInboxConfigurationDTO): void {
    this.disassociatedAudits.push(audit);
    this.auditsDisassociated.emit(this.disassociatedAudits);
  }

  revertDisassociation(audit: IEmailInboxConfigurationDTO): void {
    this.disassociatedAudits = this.disassociatedAudits.filter(a => a.auditId !== audit.auditId);
    this.auditsDisassociated.emit(this.disassociatedAudits);
  }

  auditToBeDisassociated(audit: IEmailInboxConfigurationDTO): boolean {
    return !!this.disassociatedAudits.find(a => a.auditId === audit.auditId);
  }

  editAudit(audit: IEmailInboxConfigurationDTO, copy?: boolean): void {
    const data = {
      auditId: audit.auditId,
    };
    this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor')
      .afterClosed()
      .subscribe((options?: IAuditEditorCloseOptions) => {
        options.audit && this.auditUpdated.emit(options.audit);
      });
  }

  private openAuditSelector<T>(
    selectionMode: ESelectableTableSelectionMode = ESelectableTableSelectionMode.Multiple,
    associatedAudits: IEmailInboxConfigurationDTO[] = [],
  ): Observable<T> {
    return this.modalService.openFixedSizeModal(EmailInboxAuditSelectorComponent, {
      width: '780px',
      data: {
        labels: this.labelsMap,
        associatedAudits,
        selectionMode,
      }
    }).afterClosed();
  }

  useExistingAudits(): void {
    const selectedAuditsForModal = this.associatedAuditItems.filter(a => !this.auditToBeDisassociated(a));

    this.openAuditSelector<IEmailInboxConfigurationDTO[]>(
      ESelectableTableSelectionMode.Multiple,
      selectedAuditsForModal,
  )
      .subscribe(async (result: IEmailInboxConfigurationDTO[]) => {
        if (!result?.length) {
          return;
        }

        const mappedAudits = await Promise.all(result.map(async a => ({
          ...a,
          labels: await this.labelService.getLabelsByIds(a.auditLabels),
        })));

        const arrayDifference = ArrayUtils.findDifferences(selectedAuditsForModal, mappedAudits, 'auditId');

        if (arrayDifference.onlyInArr1.length) {
          arrayDifference.onlyInArr1.forEach(a => {
            this.disassociateAudit(a);
          });
        }
        if (arrayDifference.onlyInArr2.length) {
          arrayDifference.onlyInArr2.forEach(a => {
            this.revertDisassociation(a);
          });
        }

        this.associatedAuditItems = ArrayUtils.getUniqueObjects([...this.associatedAuditItems, ...result ?? []], 'auditId');
      });
  }

  createAudit(data: MatDialogConfig['data'] = {}): void {
    this.modalService.openFixedSizeModal(AuditEditorComponent, {
      data: {
        ...data,
        urlSourcesTab: EAuditTabUrlSources.emailInboxes,
        showCloseBtn: true,
        showCreateBtn: false,
        showRunNowBtn: false,
        disableLink: true,
        text: 'It is associated with this Inbox and will auto-run when email messages are sent to the inbox.',
      },
    })
      .afterClosed()
      .subscribe((options?: IAuditEditorCloseOptions) => {
        if (options?.audit) {
          this.auditCreated.emit(options.audit);
        }
      });
  }

  copyAudit(): void {
    this.openAuditSelector<IEmailInboxConfigurationDTO[]>(ESelectableTableSelectionMode.Single)
      .pipe(filter(results => !!results))
      .subscribe((results: IEmailInboxConfigurationDTO[]) =>
        this.createAudit({
          auditId: results[0].auditId,
          copy: true,
        })
      );
  }
}
