import { Component, HostBinding, HostListener, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { EFilterSpinnerState } from '../filter-spinner/filter-spinner.constants';
import { ESplitCardChangeMeaning } from './split-card.models';
import { AlertMetricType } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { EAlertMenuContext } from '@app/components/alert/alert.enums';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { Observable } from 'rxjs';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'split-card',
  templateUrl: './split-card.component.html',
  styleUrls: ['./split-card.component.scss'],
})
export class SplitCardComponent {

  widgetHover: boolean = false;
  widgetLabelHover: boolean = false;
  isVisitorMode$: Observable<boolean>;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  @Input() topLabel?: string;
  @Input() topTooltip: string = null;
  @Input() menuContext: EAlertMenuContext = EAlertMenuContext.Audit;
  @Input() icon?: string;
  @Input() iconColor?: string;
  @HostBinding('class.activated') @Input() activated?: boolean;
  @Input() topChangeContent?: string;
  @Input() topChangeFilteredContent?: string;
  @Input() topChangeMeaning?: ESplitCardChangeMeaning = ESplitCardChangeMeaning.NONE;
  @Input() state: EFilterSpinnerState = EFilterSpinnerState.None;
  @Input() topHandler?: () => void = null; // Pass in a function to have fired from top section
  @Input() bottomHandler?: () => void = null; // Pass in a function to have fired from bottom section
  @Input() haveEnoughData = false;
  @Input() metricType?: AlertMetricType;
  @Input() auditId?: number;
  @Input() runId?: number;
  @Input() currentValue?;
  @Input() alertBellValue?;
  @Input() filters?: IAuditReportApiPostBody;
  @Input() highlight?: boolean;
  @Input() disableHighlightFromClick?: boolean = false;
  @Input() richTooltip: boolean = false;
  @Input() cardFooterVisible: boolean = false;
  @HostListener('mouseenter')
  onWidgetHover() {
    this.widgetHover = true;
  }
  @HostListener('mouseleave')
  onWidgetBlur() {
    this.widgetHover = false;
    this.widgetLabelHover = false;
  }

  constructor(private applicationChromeService: ApplicationChromeService) {
    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
  }

  handleTopClick() {
    if (this.topHandler) this.topHandler();
  }

  onBottomClick() {
    if (this.bottomHandler && this.haveEnoughData) this.bottomHandler();
  }

  positionPairs: ConnectionPositionPair[] = [
    {
      offsetX: 0,
      offsetY: 6,
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      panelClass: null,
    },
  ];

  labelHover(): void {
    this.widgetLabelHover = true;
  }

  labelBlur(): void {
    this.widgetLabelHover = false;
  }
}
