<use-case-report-section>
  <use-case-widget [topText]="pagesScannedWidget.topText"
                   [opSelector]="pagesScannedWidget.opSelector"
                   [topValue]="pagesScannedWidget.topValue"
                   [topTooltip]="pagesScannedWidget.topTooltip"
                   [topClickAction]="pagesScannedWidget.topClickAction"
                   [splitCard]="false">
    <use-case-widget-body right [widget]="pagesScannedWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="uniqueCookiesWidget.topText"
                   [opSelector]="uniqueCookiesWidget.opSelector"
                   [topValue]="uniqueCookiesWidget.topValue"
                   [topValueMeaning]="uniqueCookiesWidget.topValueMeaning"
                   [topIconName]="uniqueCookiesWidget.topIconName"
                   [topIconMeaning]="uniqueCookiesWidget.topIconMeaning"
                   [topTooltip]="uniqueCookiesWidget.topTooltip"
                   [topClickAction]="uniqueCookiesWidget.topClickAction"
                   [bottomLeftCustomContent]="true"
                   [statsData]="uniqueCookiesWidget.statsData"
  >
    <use-case-widget-body right [widget]="uniqueCookiesWidget">
      <div class="donut-chart-wrapper">
        <donut-chart-with-key [data]="uniqueCookiesWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'privacy-unique-cookies-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="uniqueCookiesWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topText]="thirdPartyCookiesWidget.topText"
                   [opSelector]="thirdPartyCookiesWidget.opSelector"
                   [topValue]="thirdPartyCookiesWidget.topValue"
                   [topValueMeaning]="thirdPartyCookiesWidget.topValueMeaning"
                   [topIconName]="thirdPartyCookiesWidget.topIconName"
                   [topIconMeaning]="thirdPartyCookiesWidget.topIconMeaning"
                   [topTooltip]="thirdPartyCookiesWidget.topTooltip"
                   [topClickAction]="thirdPartyCookiesWidget.topClickAction"
                   [bottomLeftCustomContent]="true"
                   [statsData]="thirdPartyCookiesWidget.statsData">
    <use-case-widget-body right [widget]="thirdPartyCookiesWidget">
      <div class="pills-wrapper">
        <div class="pill" *ngFor="let pill of thirdPartyCookiesWidget.chartData">
          {{ pill.domain }} <span class="value">({{pill.percent}}% of pages)</span>
        </div>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="thirdPartyCookiesWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topText]="uniqueTagsWidget.topText"
                   [opSelector]="uniqueTagsWidget.opSelector"
                   [topValue]="uniqueTagsWidget.topValue"
                   [topValueMeaning]="uniqueTagsWidget.topValueMeaning"
                   [topIconName]="uniqueTagsWidget.topIconName"
                   [topIconMeaning]="uniqueTagsWidget.topIconMeaning"
                   [topTooltip]="uniqueTagsWidget.topTooltip"
                   [topClickAction]="uniqueTagsWidget.topClickAction"
                   [bottomLeftCustomContent]="true"
                   [statsData]="uniqueTagsWidget.statsData">
    <use-case-widget-body right [widget]="uniqueTagsWidget">
      <div class="horizontal-stacked-bar-chart-wrapper">
        <horizontal-stacked-bar-chart [data]="uniqueTagsWidget.chartData"
                                      [roundedCorners]="true"
                                      [hideTooltip]="true"
                                      [uniqueIdentifier]="'unique-analytics-chart'"
                                      [chartHeight]="20"
        ></horizontal-stacked-bar-chart>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="uniqueTagsWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topText]="uniqueGEOWidget.topText"
                   [opSelector]="uniqueGEOWidget.opSelector"
                   [topValue]="uniqueGEOWidget.topValue"
                   [topValueMeaning]="uniqueGEOWidget.topValueMeaning"
                   [topIconName]="uniqueGEOWidget.topIconName"
                   [topIconMeaning]="uniqueGEOWidget.topIconMeaning"
                   [topTooltip]="uniqueGEOWidget.topTooltip"
                   [topClickAction]="uniqueGEOWidget.topClickAction"
                   [bottomLeftCustomContent]="true"
                   [statsData]="uniqueGEOWidget.statsData"
                   [loading]="uniqueGEOWidget.loading">
    <use-case-widget-body class="unique-geolocations-widget" right [widget]="uniqueGEOWidget">
      <div class="requests-map-chart-wrapper">
        <privacy-request-map
          heightSVG="212px"
          [showInitialAnimation]="true"
          [showZoomButtons]="false"
          [focusedCountries]="uniqueGEOWidget.chartData">
        </privacy-request-map>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="uniqueGEOWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topText]="pagesMissingConsentManagerWidget.topText"
                   [opSelector]="pagesMissingConsentManagerWidget.opSelector"
                   [topIconName]="pagesMissingConsentManagerWidget.topIconName"
                   [topIconMeaning]="pagesMissingConsentManagerWidget.topIconMeaning"
                   [topValue]="pagesMissingConsentManagerWidget.topValue"
                   [topValueMeaning]="pagesMissingConsentManagerWidget.topValueMeaning"
                   [topTooltip]="pagesMissingConsentManagerWidget.topTooltip"
                   [topClickAction]="pagesMissingConsentManagerWidget.topClickAction"
                   [splitCard]="true"
                   [bottomText]="pagesMissingConsentManagerWidget.bottomText"
                   [bottomValue]="pagesMissingConsentManagerWidget.bottomValue"
                   [loading]="pagesMissingConsentManagerWidget.loading"
  >
    <use-case-widget-body right [widget]="pagesMissingConsentManagerWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="pagesMissingTagManagerWidget.topText"
                   [opSelector]="pagesMissingTagManagerWidget.opSelector"
                   [topIconName]="pagesMissingTagManagerWidget.topIconName"
                   [topIconMeaning]="pagesMissingTagManagerWidget.topIconMeaning"
                   [topValue]="pagesMissingTagManagerWidget.topValue"
                   [topValueMeaning]="pagesMissingTagManagerWidget.topValueMeaning"
                   [topTooltip]="pagesMissingTagManagerWidget.topTooltip"
                   [topClickAction]="pagesMissingTagManagerWidget.topClickAction"
                   [splitCard]="true"
                   [bottomText]="pagesMissingTagManagerWidget.bottomText"
                   [bottomValue]="pagesMissingTagManagerWidget.bottomValue"
                   [loading]="pagesMissingTagManagerWidget.loading"
  >
    <use-case-widget-body right [widget]="pagesMissingTagManagerWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="pagesMissingPrivacyPolicyLinksWidget.topText"
                   [opSelector]="pagesMissingPrivacyPolicyLinksWidget.opSelector"
                   [topIconName]="pagesMissingPrivacyPolicyLinksWidget.topIconName"
                   [topIconMeaning]="pagesMissingPrivacyPolicyLinksWidget.topIconMeaning"
                   [topValue]="pagesMissingPrivacyPolicyLinksWidget.topValue"
                   [topValueMeaning]="pagesMissingPrivacyPolicyLinksWidget.topValueMeaning"
                   [topTooltip]="pagesMissingPrivacyPolicyLinksWidget.topTooltip"
                   [topClickAction]="pagesMissingPrivacyPolicyLinksWidget.topClickAction"
                   [splitCard]="true"
                   [bottomText]="pagesMissingPrivacyPolicyLinksWidget.bottomText"
                   [bottomValue]="pagesMissingPrivacyPolicyLinksWidget.bottomValue"
                   [loading]="pagesMissingPrivacyPolicyLinksWidget.loading"
  >
    <use-case-widget-body right [widget]="pagesMissingPrivacyPolicyLinksWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="pagesMissingShareSellLinksWidget.topText"
                   [opSelector]="pagesMissingShareSellLinksWidget.opSelector"
                   [topIconName]="pagesMissingShareSellLinksWidget.topIconName"
                   [topIconMeaning]="pagesMissingShareSellLinksWidget.topIconMeaning"
                   [topValue]="pagesMissingShareSellLinksWidget.topValue"
                   [topValueMeaning]="pagesMissingShareSellLinksWidget.topValueMeaning"
                   [topTooltip]="pagesMissingShareSellLinksWidget.topTooltip"
                   [topClickAction]="pagesMissingShareSellLinksWidget.topClickAction"
                   [splitCard]="true"
                   [bottomText]="pagesMissingShareSellLinksWidget.bottomText"
                   [bottomValue]="pagesMissingShareSellLinksWidget.bottomValue"
                   [loading]="pagesMissingShareSellLinksWidget.loading"
  >
    <use-case-widget-body right [widget]="pagesMissingShareSellLinksWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="newJavascriptFilesWidget.topText"
                   [opSelector]="newJavascriptFilesWidget.opSelector"
                   [topIconName]="newJavascriptFilesWidget.topIconName"
                   [topIconMeaning]="newJavascriptFilesWidget.topIconMeaning"
                   [topValue]="newJavascriptFilesWidget.topValue"
                   [topValueMeaning]="newJavascriptFilesWidget.topValueMeaning"
                   [topTooltip]="newJavascriptFilesWidget.topTooltip"
                   [topClickAction]="newJavascriptFilesWidget.topClickAction"
                   [splitCard]="true"
                   [bottomText]="newJavascriptFilesWidget.bottomText"
                   [bottomValue]="newJavascriptFilesWidget.bottomValue"
                   [loading]="newJavascriptFilesWidget.loading"
  >
    <use-case-widget-body right [widget]="newJavascriptFilesWidget"
    ></use-case-widget-body>
  </use-case-widget>
</use-case-report-section>
<use-case-report-section [sectionIcon]="unapprovedItemsSection.sectionIcon"
                         [customIcon]="unapprovedItemsSection.customIcon"
                         [sectionTitle]="unapprovedItemsSection.sectionTitle"
                         [bannerColor]="unapprovedItemsSection.bannerColor"
                         [bannerText]="unapprovedItemsSection.bannerText"
                         [bannerClickAction]="unapprovedItemsSection.bannerClickAction"
>
  <use-case-widget [topText]="unapprovedCookiesWidget.topText"
                   [opSelector]="unapprovedCookiesWidget.opSelector"
                   [topIconName]="unapprovedCookiesWidget.topIconName"
                   [topIconMeaning]="unapprovedCookiesWidget.topIconMeaning"
                   [topValue]="unapprovedCookiesWidget.topValue"
                   [topValueMeaning]="unapprovedCookiesWidget.topValueMeaning"
                   [topTooltip]="unapprovedCookiesWidget.topTooltip"
                   [topClickAction]="unapprovedCookiesWidget.topClickAction"
                   [splitCard]="true"
                   [bottomText]="unapprovedCookiesWidget.bottomText"
                   [bottomValue]="unapprovedCookiesWidget.bottomValue"
                   [loading]="unapprovedCookiesWidget.loading"
  >
    <use-case-widget-body right [widget]="unapprovedCookiesWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="unapprovedTagsWidget.topText"
                   [opSelector]="unapprovedTagsWidget.opSelector"
                   [topIconName]="unapprovedTagsWidget.topIconName"
                   [topIconMeaning]="unapprovedTagsWidget.topIconMeaning"
                   [topValue]="unapprovedTagsWidget.topValue"
                   [topValueMeaning]="unapprovedTagsWidget.topValueMeaning"
                   [topTooltip]="unapprovedTagsWidget.topTooltip"
                   [topClickAction]="unapprovedTagsWidget.topClickAction"
                   [splitCard]="true"
                   [bottomText]="unapprovedTagsWidget.bottomText"
                   [bottomValue]="unapprovedTagsWidget.bottomValue"
                   [loading]="unapprovedTagsWidget.loading"
  >
    <use-case-widget-body right [widget]="unapprovedTagsWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="unapprovedGeoLocationsWidget.topText"
                   [opSelector]="unapprovedGeoLocationsWidget.opSelector"
                   [topIconName]="unapprovedGeoLocationsWidget.topIconName"
                   [topIconMeaning]="unapprovedGeoLocationsWidget.topIconMeaning"
                   [topValue]="unapprovedGeoLocationsWidget.topValue"
                   [topValueMeaning]="unapprovedGeoLocationsWidget.topValueMeaning"
                   [topTooltip]="unapprovedGeoLocationsWidget.topTooltip"
                   [topClickAction]="unapprovedGeoLocationsWidget.topClickAction"
                   [splitCard]="true"
                   [bottomText]="unapprovedGeoLocationsWidget.bottomText"
                   [bottomValue]="unapprovedGeoLocationsWidget.bottomValue"
                   [loading]="unapprovedGeoLocationsWidget.loading"
  >
    <use-case-widget-body right [widget]="unapprovedGeoLocationsWidget"
    ></use-case-widget-body>
  </use-case-widget>
</use-case-report-section>
