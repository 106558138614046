import {
  AlertMetricTypeToReportAndMetricConfigs,
  AlertReportsToAllMetrics
} from '@app/components/alert/alert-logic/alert-logic.constants';
import {
  IAlertReportMetricsConfig,
  IAlertReportToMetricsConfig
} from '@app/components/alert/alert-logic/alert-logic.models';
import {
  AlertMetricType,
  EAlertMetricChangeType,
} from '@app/components/alert/alert-logic/alert-logic.enums';

export class AlertUtils {

  static getReportConfigByMetricType(metricType: AlertMetricType): IAlertReportToMetricsConfig | undefined {
    const configs = AlertMetricTypeToReportAndMetricConfigs.get(metricType);
    if (!configs) {
      console.error(`Report config for metric: ${metricType} wasn't found!`);
    }
    return configs?.reportConfig;
  }

  static getMetricConfigByMetricType(metricType: AlertMetricType): IAlertReportMetricsConfig | undefined {
    const configs = AlertMetricTypeToReportAndMetricConfigs.get(metricType);
    if (!configs) {
      console.error(`Metric config for metric: ${metricType} wasn't found!`);
    }
    return configs?.metricConfig;
  }

  static isRelativeChange(metricChangeType: EAlertMetricChangeType): boolean {
    return [
      EAlertMetricChangeType.RelativeValueChange,
      EAlertMetricChangeType.RelativeValueChangeAbs
    ].includes(metricChangeType);
  }

  // Used to convert an alert value to a different UI format from the API value
  static convertToUIValue(metricType: AlertMetricType, metricChangeType: EAlertMetricChangeType, value: number): number {
    const isRelativeChange = AlertUtils.isRelativeChange(metricChangeType);
    const metricConfig = AlertUtils.getMetricConfigByMetricType(metricType);
    return AlertUtils.toUIValueInternal(isRelativeChange, metricConfig, value);
  }

  private static toUIValueInternal(isRelativeChange: boolean, metricConfig: IAlertReportMetricsConfig, value: number): number {
    const correctedValue = isNaN(Number(value)) ? 0 : Number(value);
    if (!isRelativeChange && metricConfig.valueConverters) {
      return metricConfig.valueConverters.apiToUi(correctedValue);
    }
    return correctedValue;
  }

  static renderUiValueWithUnits(
    metricType: AlertMetricType,
    metricChangeType: EAlertMetricChangeType,
    metricValue: number
  ): string {
    const isRelativeChange = AlertUtils.isRelativeChange(metricChangeType);
    const metricConfig = AlertUtils.getMetricConfigByMetricType(metricType);
    const uiValue = AlertUtils.toUIValueInternal(isRelativeChange, metricConfig, metricValue);
    const unit = isRelativeChange
      ? '%'
      : metricConfig?.unit || '';
    return unit
      ? `${uiValue} ${unit}`
      :  `${uiValue}`;
  }

  // Used to convert an alert value to a different API format from the UI value
  static convertToApiValue(metricType: AlertMetricType, metricChangeType: EAlertMetricChangeType, value: any): number {
    const isRelativeChange = AlertUtils.isRelativeChange(metricChangeType);
    const metricConfig = AlertUtils.getMetricConfigByMetricType(metricType);
    if (!isRelativeChange && metricConfig.valueConverters) {
      return metricConfig.valueConverters.uiToApi(value);
    }
    return value;
  }

  static millisecondsToSeconds(milliseconds: number): number {
    if (milliseconds === null || typeof milliseconds !== 'number') {
      return 0;
    }
    // Convert from ms to seconds
    const seconds = milliseconds / 1000;
    // check if it's a whole number or not
    return seconds % 1 === 0
      ? seconds
      : seconds.toFixed(1).slice(-1) === '0'
        ? Math.round(seconds)
        : parseFloat(seconds.toFixed(1));
  }

  static secondsToMilliseconds(seconds: number): number {
    if (seconds === null || typeof seconds !== 'number') {
      return 0;
    }
    return seconds * 1000;
  }

}
