import { IUsageTrendsDTO } from '@app/components/usage-v2/usage-v2.models';
import {
  ChartClassName,
  ChartType,
  EChartType,
  IChartsConfig
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import { createTooltip, drawTooltip, getChartData, getPercentageOfTotal } from '../../usage-chart.utils';
import {
  attachBarChartToSvg,
  hide,
  nearestDataPointCoordinates
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import { EDateFormats, formatDate, toUTC } from '@app/components/date/date.service';
import * as d3 from 'd3';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import {
  getActualUsageFilteredToggleBtn
} from '@app/components/usage-v2/components/usage-chart/datasets/past-periods/utils/toggle-buttons';
import {
  getProjectedUsageFilteredToggleBtn
} from '@app/components/usage-v2/components/usage-chart/datasets/future-periods/utils/toggle-buttons';

export function addCurrentPeriodFilteredChartToConfig(trends: IUsageTrendsDTO, config: IChartsConfig, chartType: EChartType, displayedCharts): void {
  if (!trends.currentPeriod) {
    return;
  }
  const currentPeriodPeriodTotalFilteredData = getChartData([trends.currentPeriod], 'periodTotal.filtered');
  const currentPeriodPeriodProjectedTotalFilteredData = getChartData([trends.currentPeriod], 'periodProjectedTotal.scheduledUsageEstimate.filtered');

  if (displayedCharts.blueBars && currentPeriodPeriodTotalFilteredData.length) {
    config.charts[ChartType.currentPeriodPeriodTotalFiltered] = {
      data: currentPeriodPeriodTotalFilteredData,
      chartClassName: ChartClassName.filteredUsage,
      toggleBtn: getActualUsageFilteredToggleBtn(trends, chartType),
    };
  }

  if (displayedCharts.greyBars && currentPeriodPeriodProjectedTotalFilteredData.length) {
    config.charts[ChartType.currentPeriodPeriodProjectedTotalFiltered] = {
      data: currentPeriodPeriodProjectedTotalFilteredData,
      chartClassName: ChartClassName.filteredProjectedUsage,
      toggleBtn: getProjectedUsageFilteredToggleBtn(chartType, false),
    };
  }
}

export function drawCurrentPeriodPeriodProjectedTotalFilteredChart(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
  windowWidth: number,
  circleClass: string,
  tooltipClass: string,
  animationEnabled: boolean = true,
): void {
  const currentPeriodTotalFiltered = config.charts[ChartType.currentPeriodPeriodTotalFiltered];
  const currentPeriodTotal = config.charts[ChartType.currentPeriodPeriodTotal];
  const currentPeriodPeriodProjectedTotalFiltered = config.charts[ChartType.currentPeriodPeriodProjectedTotalFiltered];
  const currentPeriodPeriodProjectedTotal = config.charts[ChartType.currentPeriodPeriodProjectedTotal];

  if (!currentPeriodTotalFiltered) {
    return;
  }

  attachBarChartToSvg({
      data: currentPeriodTotalFiltered.data,
      x: xAxis,
      y: yAxis,
      height: height - margin.top - margin.bottom,
      containerClassName: `current-period period-total-filtered ${currentPeriodTotalFiltered.chartClassName}`,
      barClassName: 'period-total-filtered-bar',
      barLabelTextFormatterFn: VizUtilsService.formatChartNumbers,
      animationDuration: animationEnabled ? 750 : 0,
    },
    svg)
    .on('mouseenter', (e) => {
      const {
        x,
        y,
        dataPoint,
        dataPointIndex,
      } = nearestDataPointCoordinates(e, svg.node(), currentPeriodTotalFiltered.data, xAxis, yAxis);

      const tooltip = createTooltip(tooltipClass);

      const percentage = getPercentageOfTotal(dataPoint.value, currentPeriodTotal.data[dataPointIndex].value);
      drawTooltip(
        svg,
        '<div class="usage-chart-tooltip-body">' +
        '<h3 class="title">ACTUAL USAGE' + '<br>' +
        'IN ' +
        formatDate(toUTC(new Date(dataPoint.date)), EDateFormats.dateEight) +
        '</h3>' +
        '<div class="value">' +
        '<span class="value-data filtered">' + VizUtilsService.formatChartNumbers(dataPoint.value) + ' ' +
        '(<span class="material-icons icon">filter_alt</span>)' +
        '</span>' +
        '<span class="value-label"> of </span>' +
        '<span class="value-data actual">' + VizUtilsService.formatChartNumbers(currentPeriodTotal.data[dataPointIndex].value) + '</span>' +
        '</div>' +
        '<span class="value-label">' + percentage + '</span>' +
        '</div>',
        x,
        y,
        null,
        tooltip,
        windowWidth,
      );
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
    });

  const stackedOnData = currentPeriodTotalFiltered.data;
  const cleanHeight = height - margin.top - margin.bottom;

  attachBarChartToSvg({
      data: currentPeriodPeriodProjectedTotalFiltered.data,
      stackedOnData,
      x: xAxis,
      y: yAxis,
      height: cleanHeight,
      containerClassName: `period-projected-total-filtered ${currentPeriodPeriodProjectedTotalFiltered.chartClassName}`,
      barClassName: 'period-projected-total-filtered-bar',
      barLabelTextFormatterFn: VizUtilsService.formatChartNumbers,
      animationDuration: animationEnabled ? 750 : 0,
    },
    svg)
    .on('mouseenter', (e) => {
      const {
        x,
        y,
        dataPoint,
        dataPointIndex,
      } = nearestDataPointCoordinates(e, svg.node(), currentPeriodPeriodProjectedTotalFiltered.data, xAxis, yAxis);

      const stackedDataPoint = nearestDataPointCoordinates(e, svg.node(), stackedOnData, xAxis, yAxis).dataPoint;
      const stackedTooltipY = y - (cleanHeight - yAxis(stackedDataPoint.value));
      const tooltip = createTooltip(tooltipClass);

      const percentage = getPercentageOfTotal(dataPoint.value, currentPeriodPeriodProjectedTotal.data[dataPointIndex].value);
      drawTooltip(
        svg,
        '<div class="usage-chart-tooltip-body">' +
        '<h3 class="title">SCHEDULED MONTHLY USAGE' + '<br>' +
        'IN ' +
        formatDate(toUTC(new Date(dataPoint.date)), EDateFormats.dateEight) +
        '</h3>' +
        '<div class="value">' +
        '<span class="value-data filtered">' + VizUtilsService.formatChartNumbers(dataPoint.value) + ' ' +
        '(<span class="material-icons icon">filter_alt</span>)' +
        '</span>' +
        '<span class="value-label"> of </span>' +
        '<span class="value-data estimated">' + VizUtilsService.formatChartNumbers(currentPeriodPeriodProjectedTotal.data[dataPointIndex].value) + '</span>' +
        '</div>' +
        '<span class="value-label">' + percentage + '</span>' +
        '</div>',
        x,
        stackedTooltipY,
        null,
        tooltip,
        windowWidth,
      );
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
    });
}
