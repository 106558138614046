import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatOptionModule } from '@angular/material/core';
import { RuleSetupGeneralTabComponent } from './tabs/general-tab/rule-setup-general-tab.component';
import { RuleSetupConditionsTabComponent } from './tabs/conditions-tab/rule-setup-conditions-tab.component';
import { RuleSetupFormBuilderService } from './services/rule-setup-form-builder.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IfConditionsComponent } from './tabs/conditions-tab/if-conditions/if-conditions.component';
import { IfConditionComponent } from './tabs/conditions-tab/if-conditions/if-condition/if-condition.component';
import { RuleTagVariableFilterComponent } from './tabs/conditions-tab/shared/rule-tag-variable-filter/rule-tag-variable-filter.component';
import { RuleTagAccountFilterComponent } from './tabs/conditions-tab/shared/rule-tag-account-filter/rule-tag-account-filter.component';
import { ThenConditionsComponent } from './tabs/conditions-tab/then-conditions/then-conditions.component';
import { ThenConditionComponent } from './tabs/conditions-tab/then-conditions/then-condition/then-condition.component';
import { RuleSetupDataSerializationService } from './services/rule-setup-data-serialization.service';
import { RuleSetupFormUtilsService } from './services/rule-setup-form-utils.service';
import { SharedModule } from '@app/components/shared/shared.module';
import { OpModalModule } from '@app/components/shared/components/op-modal/op-modal.module';
import { OpTabsModule } from '@app/components/shared/components/op-tabs/op-tabs.module';
import { OpTagPickerModule } from '@app/components/account-settings/op-tag-picker/op-tag-picker.module';
import { RecipientsFormControlModule } from '@app/components/shared/components/recipients-form-control/recipients-form-control.module';
import { RuleSetupComponent } from './rule-setup.component';
import { RuleSetupModalComponent } from './modal/rule-setup-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  RuleTagStatusCodeFilterComponent
} from '@app/components/rules/rule-setup/tabs/conditions-tab/shared/rule-tag-status-code-filter/rule-tag-status-code-filter.component';
import { OpClearableInputModule } from '@app/components/shared/components/op-clearable-input/op-clearable-input.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatIconModule,
    MatOptionModule,
    MatSelectModule,
    MatToolbarModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    SharedModule,
    OpModalModule,
    OpTabsModule,
    OpTagPickerModule,
    RecipientsFormControlModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    OpClearableInputModule,
  ],
  declarations: [
    RuleSetupModalComponent,
    RuleSetupComponent,
    RuleSetupGeneralTabComponent,
    RuleSetupConditionsTabComponent,
    IfConditionsComponent,
    IfConditionComponent,
    ThenConditionsComponent,
    ThenConditionComponent,
    RuleTagVariableFilterComponent,
    RuleTagAccountFilterComponent,
    RuleTagStatusCodeFilterComponent,
  ],
  exports: [
    RuleSetupModalComponent,
  ],
  providers: [
    RuleSetupFormUtilsService,
    RuleSetupFormBuilderService,
    RuleSetupDataSerializationService,
  ]
})
export class RuleSetupModule {}
