import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDomainsService } from '@app/components/domains/domainsService';
import { IFolder } from '@app/components/folder/foldersApiService';
import { Subject } from 'rxjs';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IUpdateDomainRequest } from '@app/components//domains/domainsService';
import { HttpErrorResponse } from '@angular/common/http';
import { IButton } from '@app/models/commons';
import { takeUntil } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'edit-data-layer-modal',
  templateUrl: './edit-data-layer-modal.component.html',
  styleUrls: ['./edit-data-layer-modal.component.scss']
})
export class EditDataLayerModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  dataLayerForm: UntypedFormGroup;
  folders: IFolder[];
  saving = false;
  error = false;
  editing: boolean = true;

  rightFooterButtons: IButton[] = [
    {
      label: 'Save',
      action: () => {
        this.save();
      },
      disabled: true,
      primary: true,
      opSelector: 'edit-data-layer-save-btn',
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<EditDataLayerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private domainService: IDomainsService,
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initFields();
    this.initListeners();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): void {
    this.dataLayerForm = this.formBuilder.group({
      dataLayer: [''],
    });
  }

  private initFields(): void {
    const { domain: { dataLayer } } = this.data;
    this.dataLayerForm.patchValue({
      dataLayer
    });
  }

  private initListeners(): void {
    this.dataLayer.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((newValue) => {
      if (newValue !== this.data.domain.dataLayer) {
        this.rightFooterButtons[0]['disabled'] = false;
      } else {
        this.rightFooterButtons[0]['disabled'] = true;
      }
    });
  }

  save(): void {
    this.dataLayerForm.markAllAsTouched();
    if (this.dataLayerForm.invalid) return;

    this.updateDataLayer();

    this.editing = false;
    this.saving = true;
    this.rightFooterButtons[0]['disabled'] = true;
  }

  private updateDataLayer(): void {
    const { domain } = this.data;

    const updateRequest: IUpdateDomainRequest = {
      id: domain.id,
      name: domain.name,
      domain: domain.domain,
      dataLayer: this.dataLayer.value,  // Only value changeable from this modal
      folderId: domain.folderId,
    };

    this.domainService.updateDomain(updateRequest)
      .then(() => {
        console.log('CLOSE MODAL');
        this.closeModal(true);
      })
      .catch((error: HttpErrorResponse) => this.handleError(error));
  }

  private handleError(error: HttpErrorResponse): void {
    console.error(error);
    this.saving = false;
    this.error = true;
    this.rightFooterButtons[0].label = 'OK';
    this.rightFooterButtons[0]['disabled'] = false;
    this.rightFooterButtons[0].action = () => {
      this.closeModal();
    };
  }

  closeModal(updated?): void {
    this.dialogRef.close(updated);
  }

  get dataLayer(): AbstractControl {
    return this.dataLayerForm.get('dataLayer');
  }
}
