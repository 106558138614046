import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OpTableCellTooltipService, IOpTableCellTooltipData } from './op-table-cell-tooltip.service';

@Component({
  selector: 'op-table-cell-tooltip',
  templateUrl: './op-table-cell-tooltip.component.html',
  styleUrls: ['./op-table-cell-tooltip.component.scss']
})
export class OpTableCellTooltipComponent implements OnInit, OnDestroy {

  tooltipVisible: boolean;
  tooltipPrefix: string;
  tooltipText: string;
  tooltipLeft: number;
  tooltipBottom: number;
  tooltipMaxWidth: number;

  private onDestroy$: Subject<void> = new Subject();

  constructor(private tooltipUpdateService: OpTableCellTooltipService) {}

  ngOnInit() {
    this.tooltipUpdateService.tooltipStateChanges$.pipe(takeUntil(this.onDestroy$))
      .subscribe((update: IOpTableCellTooltipData | null) => {
        if (!update) {
          this.tooltipVisible = false;
        } else {
          this.tooltipText = update.text;
          this.tooltipPrefix = update.prefix ?? null;
          this.tooltipLeft = update.left;
          this.tooltipBottom = update.bottom;
          this.tooltipMaxWidth = update.maxWidth;
          this.tooltipVisible = true;
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
