import { Names } from '@app/moonbeamConstants';

export type ShadeModalItemTypes = 'folder' | 'domain' | 'rule';

export const ShadeModalItemTypes = {
  folder: 'folder' as ShadeModalItemTypes,
  domain: 'domain' as ShadeModalItemTypes,
  rule: 'rule' as ShadeModalItemTypes
};

interface IShadeModalConfig {
  shadeModalController: string;
  templateUrl: string;
  onCreateCallback?: Function;
}

export interface IShadeWindowDataService {
  showShadeModal: boolean;

  isShadeWindowOpen(): boolean;
  setShadeWindowIsOpened(): void;
  setShadeWindowIsClosed(): void;
}

export class ShadeWindowDataService implements IShadeWindowDataService {
  showShadeModal: boolean = false;

  isShadeWindowOpen(): boolean {
    return this.showShadeModal;
  }

  setShadeWindowIsOpened(): void {
    this.showShadeModal = true;
  }

  setShadeWindowIsClosed(): void {
    this.showShadeModal = false;
  }
}
