import { Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { environment } from '@app/environments/environment';
import { Observable } from 'rxjs';
import { IOpRecurrenceResponse, IRecurrencePreset } from './op-recurrence.models';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { ERecurrencePresetNames } from './op-recurrence.constants';
import { AccountsService } from '@app/components/account/account.service';
import { map } from 'rxjs/operators';
import { CacheApiResponse } from "@app/components/core/decorators/cache-api-response.decorator";

@Injectable({
  providedIn: 'root'
})
export class RecurrenceService {

  apiRoot: string = environment.apiV3Url;

  constructor(
    private apiService: ApiService,
    private dateService: DateService,
    private accountsService: AccountsService
  ) { }

  @CacheApiResponse()
  getSchedulePresets(): Observable<IRecurrencePreset[]> {
    return this.apiService.get(`${this.apiRoot}schedules/presets`);
  }

  getRecurrenceRuleDescription(rule: string): Observable<{ description: string }> {
    const payload = {
      recurrenceRule: rule
    };

    return this.apiService.post(`${this.apiRoot}schedules/rule-description`, payload);
  }

  getDefaultSchedule(): Observable<IOpRecurrenceResponse> {
    const date = this.dateService.formatDate(new Date(), EDateFormats.dateTwentyOne);
    const time = this.dateService.formatDate(new Date(), EDateFormats.timeFour);

    return this.accountsService.getUser().pipe(
      map(user => ({
        schedule: {
          tzId: user.timezone,
          isPaused: false,
          dtStart: `${date}T${time}.000`,
          presetType: ERecurrencePresetNames.Weekly,
          recurrenceRule: 'WKST=SU;FREQ=WEEKLY;INTERVAL=1',
          description: null,
        }
      } as IOpRecurrenceResponse))
    );
  }

  combineDateAndTime(date: Date, time: string): string {
    /**
     * Manually formatting the date in this way (rather than using Date.toISOString()) is a
     * conscious decision because we do not want to format the date string to UTC time. The
     * timezone is explicitly set in the tzId property.
     */
    return `${this.dateService.formatDate(date, EDateFormats.dateTwentyOne)}T${time}:00.000`;
  }
}
