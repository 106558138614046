import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertComponent } from '@app/components/alert/alert.component';
import { EAlertFilterType, EAlertMenuContext, EAlertModalType } from '@app/components/alert/alert.enums';
import { IAlertEditModalPayload, IAlertQuickCreatePayload } from '@app/components/alert/alert.models';
import { ConsentCategoryCreateComponent } from '@app/components/consent-categories/cc-create/cc-create.component';
import { ConsentCategoriesEditComponent } from '@app/components/consent-categories/cc-edit/cc-edit.component';
import { ECCEditTabs } from '@app/components/consent-categories/cc-edit/cc-edit.constants';
import { EConsentCategoryType, IConsentCategory } from '@app/components/consent-categories/consent-categories.models';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { IRule } from '@app/components/rules/rules.models';
import { IUser } from '@app/moonbeamModels';
import { OpModalService } from '../op-modal';
import {
  EProductType,
  EStandardsSelectorType,
  productStrings,
  standardsSelectorStrings
} from './op-standards-selector.constants';
import {
  IProductTypeStrings,
  IStandardsSelectorItem,
  IStandardsSelectorLabel,
  IStandardsSelectorStrings
} from './op-standards-selector.models';
import { OpStandardsSelectorService } from './op-standards-selector.service';
import {
  ImportCategorizedCookiesModalComponent
} from '@app/components/consent-categories/import-categorized-cookies-modal/import-categorized-cookies-modal.component';
import {
  AssignDatasetsModal,
  ConsentCategoriesUIConstants
} from '@app/components/consent-categories/consent-categories.constants';
import { hasOwnProperty } from 'fast-json-patch/module/helpers';
import {
  ImportCategorizedCookiesSnackbarComponent
} from '@app/components/consent-categories/import-categorized-cookies-snackbar/import-categorized-cookies-snackbar.component';
import {
  ImportCategorizedCookiesWithDataSourcesSnackbarComponent
} from '@app/components/consent-categories/import-categorized-cookies-with-datasources-snackbar/import-categorized-cookies-with-datasources-snackbar.component';
import { CCAssignComponent } from '@app/components/consent-categories/cc-assign/cc-assign.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Datasource, IDatasource } from 'ngx-ui-scroll';

interface IData {
  name: string;
}

@Component({
  selector: 'op-standards-selector',
  templateUrl: './op-standards-selector.component.html',
  styleUrls: ['./op-standards-selector.component.scss']
})
export class OpStandardsSelectorComponent implements OnInit {

  @Input() standardsType: EStandardsSelectorType = EStandardsSelectorType.RULES;
  @Input() productType: EProductType = EProductType.AUDIT;
  @Input() createDataSource: boolean = false;
  @Input() selectedStandardsIds: number[] = []; // array of IDs
  @Input() auditId: number;
  @Input() runId: number;
  @Input() hasLoaded: boolean;
  @Input() user: IUser;
  @Output() selectedStandardsUpdated: EventEmitter<IStandardsSelectorItem[]> = new EventEmitter();
  @Output() handleInitialLoad: EventEmitter<void> = new EventEmitter();

  loading: boolean = true;
  searchValue: string = '';
  standards: IStandardsSelectorItem[] = [];
  selectedStandards: IStandardsSelectorItem[] = [];
  filteredStandards: IStandardsSelectorItem[] = [];
  availableStandards: IStandardsSelectorItem[] = [];
  standardsWithCreateMenu: EStandardsSelectorType[] = [EStandardsSelectorType.CONSENT_CATEGORIES];
  templateStrings: IStandardsSelectorStrings = standardsSelectorStrings[this.standardsType];
  productContextStrings: IProductTypeStrings = productStrings[this.productType];
  sortAsc: boolean = true;
  availableConsentCatType: EConsentCategoryType;
  hasConflictingConsentCatTypes: boolean = false;

  availableStandardsDatasource: IDatasource;
  selectedStandardsDatasource: IDatasource;

  createStandardMenus = {
    [EStandardsSelectorType.CONSENT_CATEGORIES]: [
      {
        action: () => this.openConsentCatCreation(),
        label: ConsentCategoriesUIConstants.createNew,
      },
      {
        action: () => this.openConsentCategoryImportModal(),
        label: ConsentCategoriesUIConstants.importCategorizedCookies,
      }
    ]
  };

  constructor(
    private standardsSelectorService: OpStandardsSelectorService,
    private modalService: OpModalService,
    private snackbar: MatSnackBar,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.setTemplateStringValues();
    this.standardsSelectorService.getData(this.standardsType).then((standards: IStandardsSelectorItem[]) => {
      this.standards = standards;
      this.getSelectedStandards();
      this.filterStandards();

      //If creating a new DS then add standards that are marked as default for each new journey/audit (alerts are handled differently)
      if (this.createDataSource && this.standardsType !== EStandardsSelectorType.ALERTS) {
        let filteredStandards = this.standards.filter(std => std.isDefaultForNewDataSource);

        //Ensure we don't have duplicates (this can happen after creating a new consent category when creating a new audit)
        filteredStandards.filter(filteredStandard => {
          return this.selectedStandards?.find(selectedStandard => selectedStandard.id === filteredStandard.id)
            ? null
            : this.addToListOnly(filteredStandard);
        });
      }
      this.AddToListPlus();
      
      this.setAvailableStandardsVS();
      this.setSelectedStandardsVS();

      if (this.hasLoaded) {
        this.loading = false;
      } else {
        this.handleInitialLoad.emit();
        setTimeout(async () => {
          const settings = {
            startIndex: Number(0),
            bufferSize: Number(30)
          };
          await this.availableStandardsDatasource.adapter.reset({ settings });
          await this.selectedStandardsDatasource.adapter.reset({ settings });
          this.loading = false;
        }, 1500);
      }
    });
  }

  setTemplateStringValues(): void {
    this.templateStrings = standardsSelectorStrings[this.standardsType];
    this.productContextStrings = productStrings[this.productType];
  }

  // Filter all standards, setting the availableStandards to equal all standards after removing
  // selected ones, sorting alphabetically, and setting the available consent category type
  filterStandards(): void {
    this.selectedStandardsIds = this.selectedStandards?.map((standard: IStandardsSelectorItem) => standard?.id);
    this.filteredStandards = this.standards.filter((standard: IStandardsSelectorItem) => !this.selectedStandardsIds.includes(standard.id));
    this.availableStandards = [...this.filteredStandards].sort(
      (a: IStandardsSelectorItem, b: IStandardsSelectorItem) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
    );
    if (this.standardsType === EStandardsSelectorType.CONSENT_CATEGORIES) {
      this.availableConsentCatType = this.selectedStandards[0]?.type || null;
      this.hasConflictingConsentCatTypes = [...new Set(this.standards?.map((standard: IStandardsSelectorItem) => standard.type))].length > 1;
    }
  }

  setAvailableStandardsVS() {
    this.availableStandardsDatasource = new Datasource<IData>({
      get: (index, count, success) => {
        const start = Math.max(index, 0);
        const end = index + count - 1;
        success(start <= end
          ? this.availableStandards.slice(start, end + 1)
          : []
        );
      },
      settings: {
        bufferSize: 30,
        startIndex: 0,
        minIndex: 0,
      },
    });
  }

  async setSelectedStandardsVS() {
    this.selectedStandardsDatasource = new Datasource<IData>({
      get: (index, count, success) => {
        const start = Math.max(index, 0);
        const end = index + count - 1;
        success(start <= end
          ? this.selectedStandards.slice(start, end + 1)
          : []
        );
      },
      settings: {
        startIndex: 0,
        minIndex: 0,
        bufferSize: 30,
      },
    });
  }

  getSelectedStandards(): void {
    this.selectedStandards = this.standardsSelectorService.getSelectedStandards(this.standardsType, this.selectedStandardsIds);
  }

  async insertAvailableStandard(standard) {
    await this.availableStandardsDatasource.adapter.relax();

    const items = [standard];

    // Insert into selected data
    this.availableStandards.push(standard);
    this.availableStandards.sort(
      (a: IStandardsSelectorItem, b: IStandardsSelectorItem) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
    );
    const newAvailableIndex = this.availableStandards.findIndex(availableStandard => availableStandard.id === standard.id);
    const afterIndex = newAvailableIndex > 0 ? newAvailableIndex - 1 : 0;

    // Insert into selected virtual scroller
    await this.availableStandardsDatasource.adapter.insert({
      afterIndex,
      items,
    });
  }

  async removeAvailableStandard(standard) {
    await this.availableStandardsDatasource.adapter.relax();
    const indexInAvailableList = this.availableStandards.findIndex(s => s.id === standard.id);
    // Remove from data
    this.availableStandards.splice(indexInAvailableList, 1);
    // Remove from virtual scroller
    await this.availableStandardsDatasource.adapter.remove({
      predicate: ({ data }) => {
        return (data as IStandardsSelectorItem).id === standard.id;
      },
    });
  }

  async insertSelectedStandard(standard) {
    if(!this.selectedStandardsDatasource) {
      await this.setSelectedStandardsVS();
    }
    await this.selectedStandardsDatasource.adapter.relax();

    // Insert into selected data
    this.selectedStandards.push(standard);
    await this.selectedStandardsDatasource.adapter.append(standard);
  }

  async removeSelectedStandard(standard) {
    await this.selectedStandardsDatasource.adapter.relax();
    const indexInSelectedList = this.selectedStandards.findIndex(s => s.id === standard.id);
    // Remove from data
    this.selectedStandards.splice(indexInSelectedList, 1);
    // Remove from virtual scroller
    await this.selectedStandardsDatasource.adapter.remove({
      predicate: ({ data }) => {
        return (data as IStandardsSelectorItem).id === standard.id;
      },
    });
  }

  searchStandards(): void {
    this.filteredStandards = this.standards.filter((standard: IStandardsSelectorItem) => !this.selectedStandardsIds.includes(standard.id));
    this.availableStandards = [];
    this.cdr.detectChanges();

    this.availableStandards = [...this.filteredStandards.filter((standard: IStandardsSelectorItem) => {
      const nameMatches = standard.name.toLowerCase().includes(this.searchValue.trim().toLowerCase());
      const labelMatches = !!standard.labels.filter((label: IStandardsSelectorLabel) => {
        return label.name?.toLowerCase().includes(this.searchValue.trim().toLowerCase());
      }).length;

      return nameMatches || labelMatches;
    })];

    this.setAvailableStandardsVS();
  }

  toggleSort(): void {
    this.sortAsc = !this.sortAsc;
    const sortValLeft = this.sortAsc ? 1 : -1;
    const sortValRight = this.sortAsc ? -1 : 1;
    this.availableStandards.sort(
      (a: IStandardsSelectorItem, b: IStandardsSelectorItem) => (a.name.toLowerCase() > b.name.toLowerCase()) ? sortValLeft : sortValRight
    );
  }

  async addToList(standard: IStandardsSelectorItem) {
    await this.addToListOnly(standard);
    this.AddToListPlus();
  }

  private async addToListOnly(standard: IStandardsSelectorItem) {
    // Add to selected
    await this.insertSelectedStandard(standard);
    await this.removeAvailableStandard(standard);
  }

  private AddToListPlus(): void {

    this.selectedStandards?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    this.selectedStandardsUpdated.emit(this.selectedStandards);
  }

  async removeFromList(standard: IStandardsSelectorItem) {
    await this.removeSelectedStandard(standard);

    // Make sure it matches any current search criteria before adding when we remove a alue from the selected list
    const nameMatches = standard.name.toLowerCase().includes(this.searchValue.trim().toLowerCase());
    const labelMatches = !!standard.labels.filter((label: IStandardsSelectorLabel) => {
      return label.name?.toLowerCase().includes(this.searchValue.trim().toLowerCase());
    }).length;

    if (nameMatches || labelMatches) await this.insertAvailableStandard(standard);

    this.selectedStandardsUpdated.emit(this.selectedStandards);
  }

  async addAll() {
    const allSelected = this.selectedStandards?.concat(this.availableStandards).sort(
      (a: IStandardsSelectorItem, b: IStandardsSelectorItem) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
    );
    this.selectedStandards = [];
    this.availableStandards = [];
    this.cdr.detectChanges();
    this.selectedStandards = allSelected;
    this.setSelectedStandardsVS();
    this.setAvailableStandardsVS();
    await this.selectedStandardsDatasource.adapter.reload();
    await this.availableStandardsDatasource.adapter.reload();
    this.selectedStandardsUpdated.emit(this.selectedStandards);
  }

  async removeAll() {
    this.selectedStandards = [];
    this.availableStandards = [];
    this.cdr.detectChanges();
    this.filterStandards();
    this.setSelectedStandardsVS();
    this.setAvailableStandardsVS();

    await this.selectedStandardsDatasource.adapter.reload();
    await this.availableStandardsDatasource.adapter.reload();
    this.selectedStandardsUpdated.emit(this.selectedStandards);
  }

  createStandard(): void {
    switch (this.standardsType) {
      case EStandardsSelectorType.RULES:
        this.openRuleCreation();
        break;

      case EStandardsSelectorType.ALERTS:
        this.openAlertDesigner();
        break;

      case EStandardsSelectorType.CONSENT_CATEGORIES:
        this.openConsentCatCreation();
        break;

      default:
        this.openRuleCreation();
        break;
    }
  }

  editStandard(standard: IStandardsSelectorItem): void {
    switch (this.standardsType) {
      case EStandardsSelectorType.RULES:
        this.openRuleEditor(standard);
        break;

      case EStandardsSelectorType.CONSENT_CATEGORIES:
        this.openConsentCatEditor(standard);
        break;

      case EStandardsSelectorType.ALERTS:
        this.openAlertDesigner(standard);
        break;

      default:
        this.openRuleEditor(standard);
        break;
    }
  }

  openRuleCreation(): void {
    this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: { mode: ERuleSetupMode.create }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => this.onCreateRule(rule));
  }

  openRuleEditor(standard: IStandardsSelectorItem): void {
    const mode = ERuleSetupMode.edit;
    this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode,
        ruleId: standard.id
      }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => this.onUpdateRule(rule));
  }

  private async onCreateRule(createdRule?: IRule) {
    if (!createdRule) return;

    const newStandard = this.standardsSelectorService.convertRuleToStandard(createdRule);
    await this.updateWithCreatedStandard(newStandard);
  }

  private async onUpdateRule(updatedRule: IRule) {
    if (!updatedRule) return;

    const index = this.standards.map(standard => standard.id).indexOf(updatedRule.id);
    const updatedStandard = this.standardsSelectorService.convertRuleToStandard(updatedRule);
    this.standards[index] = updatedStandard;
    await this.updateStandard(updatedStandard);
  }

  async updateStandard(updatedStandard) {
    await this.availableStandardsDatasource.adapter.relax();
    await this.selectedStandardsDatasource.adapter.relax();

    const availableIndex = this.availableStandards.findIndex(standard => updatedStandard.id === standard.id);
    if (availableIndex > -1) {
      await this.availableStandardsDatasource.adapter.replace({
        predicate: ({ data }) => {
          return (data as IStandardsSelectorItem).id === updatedStandard.id;
        },
        items: [updatedStandard],
      });
    }

    const selectedIndex = this.selectedStandards.findIndex(standard => updatedStandard.id === standard.id);
    if (selectedIndex > -1) {
      await this.selectedStandardsDatasource.adapter.replace({
        predicate: ({ data }) => {
          return (data as IStandardsSelectorItem).id === updatedStandard.id;
        },
        items: [updatedStandard],
      });
    }
  }

  async updateWithCreatedStandard(newStandard) {
    await this.availableStandardsDatasource.adapter.relax();
    await this.selectedStandardsDatasource.adapter.relax();

    this.standards.push(newStandard);
    await this.insertSelectedStandard(newStandard);
    this.selectedStandardsUpdated.emit(this.selectedStandards);
  }

  openConsentCatCreation(): void {
    this.modalService.openFixedSizeModal(ConsentCategoryCreateComponent, {
      data: {
        allLabels: this.standardsSelectorService.labels,
        cookies: [],
        tags: [],
        type: 'approved',
        editing: false,
        requestDomains: [],
        auditId: this.auditId,
      }
    })
      .afterClosed()
      .subscribe(async (data) => {
        if (data.cc?.id) {
          const newCC = this.standardsSelectorService.convertConsentCatToStandard(data.cc);
          await this.updateWithCreatedStandard(newCC);
        }
      });
  }

  openConsentCategoryImportModal(modalConfig: any = { data: {} }): void {
    if (this.auditId) modalConfig.data.auditId = this.auditId;

    this.modalService.openModal(ImportCategorizedCookiesModalComponent, modalConfig)
      .afterClosed()
      .subscribe(data => {
        if (data && data.hasOwnProperty('importedCCList')) {
          const updatedSelectedStandards = [...this.selectedStandards, ...data.importedCCList];
          this.selectedStandardsUpdated.emit(updatedSelectedStandards);
        }

        this.ngOnInit();

        if (data && data === AssignDatasetsModal) {
          this.openAssignModal();
        } else if (data && hasOwnProperty(data, 'topXNew')) {
          this.snackbar.openFromComponent(ImportCategorizedCookiesSnackbarComponent, {
            data: { data },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 7000,
          });
        } else if (data && hasOwnProperty(data, 'importedSnackbarData')) {
          //snackbar for imported cookies
          this.snackbar.openFromComponent(ImportCategorizedCookiesWithDataSourcesSnackbarComponent, {
            data: { data: data.importedSnackbarData },
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 7000,
          });
        }
      });
  }

  private openAssignModal(): void {
    this.modalService
      .openModal(
        CCAssignComponent,
        {
          data: {
            labels: this.standardsSelectorService.labels
          },
          autoFocus: false
        }
      )
      .afterClosed()
      .subscribe((counts: { ccCount: number, itemCount: number }) => {
        if (counts) {
          this.showAssignModalConfirm(counts);
          this.ngOnInit();
        }
      });
  }

  showAssignModalConfirm(counts: { ccCount: number, itemCount: number }): void {
    const message = `${counts.ccCount} Consent Categor${counts.ccCount > 1 ? 'ies have' : 'y has'} now been assigned to ${counts.itemCount} data source${counts.itemCount > 1 ? 's' : ''}.`;
    this.snackbar.open(message, '', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'top' });
  }

  openConsentCatEditor(standard: IStandardsSelectorItem): void {
    this.modalService.openFixedSizeModal(ConsentCategoriesEditComponent, {
      disableClose: true,
      data: {
        consentCategoryId: standard.id,
        tab: ECCEditTabs.cookies,
      }
    })
      .afterClosed()
      .subscribe(async (updatedConsentCatArr: IConsentCategory[]) => {
        if (!updatedConsentCatArr) return;
        const updatedConsentCat = updatedConsentCatArr[0];
        const index = this.standards.findIndex(standard => standard.id === updatedConsentCat.id);
        const updatedStandard = this.standardsSelectorService.convertConsentCatToStandard(updatedConsentCat);
        this.standards[index] = updatedStandard;
        await this.updateStandard(updatedStandard);
      });
  }

  openAlertDesigner(alert?: IStandardsSelectorItem): void {
    let data = {};

    if (alert) {
      data = {
        disableClose: true,
        data: {
          type: EAlertModalType.Edit,
          alertId: alert.id,
          menuContext: EAlertMenuContext.Audit,
          filterType: EAlertFilterType.V1
        } as IAlertEditModalPayload,
        auditFocus: false
      };
    } else {
      data = {
        disableClose: true,
        data: {
          auditId: this.auditId,
          runId: this.runId,
          metricType: null,
          currentValue: null,
          filters: null,
          filterType: EAlertFilterType.V1,
          menuContext: EAlertMenuContext.Audit,
          type: EAlertModalType.CreateForAudit,
          emails: [this.user.email]
        } as IAlertQuickCreatePayload,
        auditFocus: true
      };
    }

    this.modalService.openFixedSizeModal(AlertComponent, data)
      .afterClosed()
      .subscribe(async (alert) => {
        if (alert) {
          const index = this.standards.findIndex(standard => standard.id === alert.id);
          const updatedStandard = this.standardsSelectorService.convertAlertToStandard(alert);

          // updating existing alert
          if (index > -1) {
            this.standards[index] = updatedStandard;
            await this.updateStandard(updatedStandard);
            this.selectedStandardsUpdated.emit(this.selectedStandards);
          } else {
            // created a new alert, so add to the selected standards
            await this.updateWithCreatedStandard(updatedStandard);
          }
        }
      });
  }
}
