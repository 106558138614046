<div class="banner-container" *ngIf="bannerVisibleFlag">
  <div class="initiatedWrp titleWrp" *ngIf="isProcessInitiated()">
    <div class="initiatedBody">
      <span class="msgWrp">
        <span class="msg">Next run scheduled to scan...</span>
      </span>
    </div>
  </div>
  <div class="inProgressWrp" *ngIf="isProcessInProgress()">
    <div class="inProgressBody">
      <button class="showDetails titleWrp" (click)="showDetails()">
        <span class="iconWrp">
          <mat-icon>autorenew</mat-icon>
        </span>
        <span class="msgWrp">
          <span class="msg">
            Next run in progress...
            <mat-icon>expand_more</mat-icon>
          </span>
        </span>
      </button>
    </div>
  </div>
  <div class="doneWrp titleWrp" *ngIf="isProcessDone()">
    <div class="doneBody">
      <span class="iconWrp">
        <i class="icon icon-check-empty"></i>
      </span>
      <span class="msgWrp">
        <span class="msg">New {{typeLabel}} run complete.</span>
      </span>
      <button class="viewReport" (click)="showReport()">
        View
      </button>
    </div>
  </div>
  <div class="modalBannerWrp" *ngIf="isDetailsShown" [class.lg]="isAuditType()">
    <div class="headerWrp">
      <div class="titleWrp">
        <span class="iconWrp">
          <i class="icon icon-run"></i>
        </span>
        <span class="msgWrp">
          <span class="msg">Next {{typeLabel}} run currently in progress</span>
        </span>
      </div>
      <div class="subTitleWrp">
        <span class="subTitle">
          Started {{startedDate}}
        </span>
      </div>
      <i class="icon-close-bare close-btn" (click)="hideDetails()"></i>
    </div>
    <status-progress-bar [items]="progressBarItems"></status-progress-bar>
  </div>
</div>
