import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild, OnDestroy
} from '@angular/core';
import { EFieldLabels } from '../audit-setup-form/audit-setup-form.constants';
import { ECIAppearance } from '@app/components/shared/components/op-clearable-input/op-clearable-input.constants';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  CalculateWordWidth
} from '@app/components/domains/discoveryAudits/reporting/services/calculateWordWidthService/calculateWordWidthService';
import { EASY_BLOCK_TAG_LIMIT, EEasyBlockStrings } from './easy-block-tags.constants';
import { IEasyBlockTags } from '@app/components/domains/discoveryAudits/discoveryAuditModels';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

const EASY_BLOCK_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EasyBlockTagsComponent),
  multi: true
};

const EASY_BLOCK_CONTROL_VALIDATION = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EasyBlockTagsComponent),
  multi: true
};

enum EEasyBlockTagType {
  CATEGORY,
  TAG
}

interface ITagCategoryMap {
  [key: string]: {
    id: number,
    tags: IEasyBlockTag[],
    easyBlockType?: EEasyBlockTagType;
  }
}

interface ICategorizedTag {
  name: string;
  id: number;
  tags: IUiTag[];
  checked?: boolean;
  easyBlockType?: EEasyBlockTagType;
}

interface IEasyBlockTag extends IUiTag {
  checked?: boolean;
  tags?: IUiTag[];
  easyBlockType?: EEasyBlockTagType;
}

const CUSTOM_TAG_CATEGORY_ID = 7;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'easy-block-tags',
  templateUrl: './easy-block-tags.component.html',
  styleUrls: ['./easy-block-tags.component.scss'],
  providers: [
    EASY_BLOCK_CONTROL_VALUE_ACCESSOR,
    EASY_BLOCK_CONTROL_VALIDATION,
  ]
})
export class EasyBlockTagsComponent implements OnInit, ControlValueAccessor, OnDestroy {

  selectedTags: IEasyBlockTag[] = [];
  visibleTags: IEasyBlockTag[] = [];
  hiddenTags: IEasyBlockTag[] = [];
  dataFromParent: IEasyBlockTags;
  value: FormControl<IEasyBlockTags> = new FormControl({
    tagCategoryIds: [],
    tagIds: []
  }, [this.easyBlockValidator.bind(this)]);
  EFieldLabels = EFieldLabels;
  ECIAppearance = ECIAppearance;
  EEasyBlockStrings = EEasyBlockStrings;
  EEasyBlockTagType = EEasyBlockTagType;
  tags: IEasyBlockTag[] = [];
  tagCategoryMap: ITagCategoryMap = {};
  checkedStateMap: any = {};
  categorizedTags: ICategorizedTag[] = []; // source of truth
  filteredTags: ICategorizedTag[] = []; // used to build menu
  tagsAreLoaded: boolean = false;
  searchString: string = '';

  private destroy$: Subject<void> = new Subject<void>();

  onChange: any = () => {};
  onTouch: any = () => {};

  @Input() numRows: number = 2;

  @ViewChild('chipGrid', { read: ElementRef }) chipGrid: ElementRef;

  constructor(
    private uiTagService: UiTagService,
    private calculateWordWidthService: CalculateWordWidth,
  ) {}

  ngOnInit(): void {
    this.uiTagService.getAllTagsData().subscribe(([tags, vendors, categories]) => {
      this.tags = tags;
      this.tagsAreLoaded = true;
      this.createTagCategoryMap();
      this.createCategorizedArray();
      this.sortCategorizedTags();
      this.addSelectAllOption();
      this.createCheckedStateMap();
      this.filteredTags = this.categorizedTags;
      this.loadSelectedTags();
      setTimeout(() => this.calculateVisibleTags());
      this.value.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.calculateVisibleTags();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createTagCategoryMap(): void {
    this.tags.forEach((tag: IEasyBlockTag) => {
      const category = this.uiTagService.getTagCategory(tag.tagCategoryId);
      if (!this.tagCategoryMap[category.category]) {
        this.tagCategoryMap[category.category] = {
          id: category.id,
          tags: []
        };
      }

      this.tagCategoryMap[category.category].tags.push({ ...tag, easyBlockType: EEasyBlockTagType.TAG, checked: false });
    });
  }

  private createCategorizedArray(): void {
    Object.keys(this.tagCategoryMap).forEach((key: string) => {
      this.categorizedTags.push({
        name: key,
        id: this.tagCategoryMap[key].id,
        tags: this.tagCategoryMap[key].tags,
        easyBlockType: EEasyBlockTagType.CATEGORY
      });
    });
  }

  private sortCategorizedTags(): void {
    this.categorizedTags.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    this.categorizedTags.forEach((category: ICategorizedTag) => {
      category.tags.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    });
  }

  private addSelectAllOption(): void {
    this.categorizedTags.forEach((category: ICategorizedTag) => {
      category.tags.unshift({
        id: category.id,
        name: `All ${category.name}${category.id === CUSTOM_TAG_CATEGORY_ID ? 's' : ' Tags'}`,
        checked: false,
        easyBlockType: EEasyBlockTagType.CATEGORY
      } as IEasyBlockTag);
    });
  }

  private createCheckedStateMap(): void {
    this.categorizedTags.forEach((category: ICategorizedTag) => {
      const tagsMap = {};
      category.tags.forEach((tag: IEasyBlockTag) => {
        tagsMap[tag.id] = tag;
      });

      this.checkedStateMap[category.id] = {
        ...category,
        tags: tagsMap
      };
    });
  }

  private allTagsOfCategoryAreSelected(catId: number): boolean {
    return this.categorizedTags.find((category: ICategorizedTag) => category.id === catId).tags
      .filter((tag: IEasyBlockTag) => tag.easyBlockType !== EEasyBlockTagType.CATEGORY)
      .every((tag: IEasyBlockTag) => tag.checked);
  }

  private setSelectAllCheckboxState(catId: number, state: boolean): void {
    this.checkedStateMap[catId].tags[catId].checked = state;
  }

  handleCheckboxChange(tag: IEasyBlockTag, category: ICategorizedTag, checked: boolean, event): void {
    event.stopPropagation();
    checked ? this.deselectTag(tag, category) : this.selectTag(tag, category);
  }

  searchTags(value: string): void {
    this.searchString = value.trim().toLowerCase();
    const tagsToSearch = _.cloneDeep(this.categorizedTags);

    if (!this.searchString) {
      this.filteredTags = this.categorizedTags;
      return;
    }

    this.filteredTags = tagsToSearch.filter((category: ICategorizedTag) => {
      if (category.name.toLowerCase().includes(this.searchString)) return category;

      category.tags = [...category.tags].filter((tag: IEasyBlockTag) => {
        return tag.name.toLowerCase().includes(this.searchString) && tag.easyBlockType !== EEasyBlockTagType.CATEGORY;
      });

      return category.tags.length ? category : false;
    });
  }

  private updateForm(): void {
    const tagCategoryIds: number[] = this.selectedTags
      .filter(chip => chip.easyBlockType === EEasyBlockTagType.CATEGORY)
      .map(category => category.id);

    const tagIds: number[] = this.selectedTags
      .filter(chip => chip.easyBlockType === EEasyBlockTagType.TAG)
      .map(tag => tag.id);

    this.value.patchValue({ tagCategoryIds, tagIds });
  }

  private calculateVisibleTags(): void {
    this.visibleTags = [];
    this.hiddenTags = [];

    const reservedSpaceForMoreTag = 110;
    const spaceForTags = this.chipGrid?.nativeElement?.offsetWidth || 100;
    const chipExtraWidth = 47;

    // NOTE: config should match font style properties for chips
    const config = {
      font: 'Open Sans',
      fontSize: '13px'
    };

    let currentWidth = 0;
    let row = 1;

    this.selectedTags.forEach((tag: IEasyBlockTag) => {
      const chipWidth = this.calculateWordWidthService.calculate(tag.name, config).width + chipExtraWidth;

      currentWidth += chipWidth;

      if (row < this.numRows) {
        if (currentWidth <= spaceForTags) {
          this.visibleTags.push(tag);
        } else {
          this.visibleTags.push(tag);
          currentWidth = chipWidth;
          row++;
        }
      } else if (row === this.numRows) {
        if (currentWidth <= spaceForTags - reservedSpaceForMoreTag) {
          this.visibleTags.push(tag);
        } else {
          this.hiddenTags.push(tag);
          row++;
        }
      } else { // no more rows available
        this.hiddenTags.push(tag);
      }
    });
  }

  private loadSelectedTags(): void {
    if (!this.dataFromParent) return;

    const tagCategories = this.dataFromParent.tagCategoryIds.map((tagCategoryId: number) => {
      return this.categorizedTags.find((tagCategory: ICategorizedTag) => {
        return tagCategory.id === tagCategoryId;
      });
    });

    const tags = this.dataFromParent.tagIds.map((tagId: number) => {
      for (let i = 0; i < this.categorizedTags.length; i++) {
        for (let j = 0; j < this.categorizedTags[i].tags.length; j++) {
          if ((this.categorizedTags[i].tags[j] as IEasyBlockTag).easyBlockType === EEasyBlockTagType.CATEGORY) continue;
          if (this.categorizedTags[i].tags[j].id === tagId) {
            return this.categorizedTags[i].tags[j];
          }
        }
      }
    });

    tagCategories.forEach((tagCategory: ICategorizedTag) => {
      this.handleSelectAllTagsOfCategory(tagCategory);
    });

    tags.forEach((tag: IEasyBlockTag) => {
      const category = this.categorizedTags.find((category: ICategorizedTag) => category.id === tag.tagCategoryId);
      this.handleSelectSingleTag(tag, category);
    });
  }

  /**
   * Adding Tags Section
   */

  selectTag(tag: IEasyBlockTag, category: ICategorizedTag): void {
    tag.checked = true;
    tag.easyBlockType === EEasyBlockTagType.CATEGORY ? this.handleSelectAllTagsOfCategory(category) : this.handleSelectSingleTag(tag, category);
  }

  handleSelectSingleTag(tag: IEasyBlockTag, category: ICategorizedTag): void {
    this.checkedStateMap[category.id].tags[tag.id].checked = true;
    this.addTagToSelectedTags(tag);

    if (this.allTagsOfCategoryAreSelected(category.id)) {
      this.setSelectAllCheckboxState(category.id, true);
      this.handleSelectAllTagsOfCategory(category);
    }
  }

  handleSelectAllTagsOfCategory(category: ICategorizedTag): void {
    // update categorizedTags with current checked state
    this.setSelectAllCheckboxState(category.id, true);

    // remove any tags of that category from this.selectedTags
    this.selectedTags = this.selectedTags.filter((selectedTag: IEasyBlockTag) => {
      if (selectedTag.easyBlockType === EEasyBlockTagType.CATEGORY) return true;
      return selectedTag?.tagCategoryId !== category.id;
    });

    // update tags in menu to show checked state
    category.tags
      .filter((tag: IEasyBlockTag) => tag.easyBlockType !== EEasyBlockTagType.CATEGORY)
      .forEach((tag: IEasyBlockTag) => {
        this.checkedStateMap[tag.tagCategoryId].tags[tag.id].checked = true;
        tag.checked = true;
      });

    const categoryTag = {
      id: category.id,
      name: category.name,
      easyBlockType: EEasyBlockTagType.CATEGORY
    } as IEasyBlockTag;

    this.addTagToSelectedTags(categoryTag);
  }

  addTagToSelectedTags(tag: IEasyBlockTag): void {
    this.selectedTags.push(tag);
    this.updateForm();
  }

  /**
   * Deselecting Tags Section
   */

  deselectTag(tag: IEasyBlockTag, category: ICategorizedTag): void {
    tag.checked = false;
    tag.easyBlockType === EEasyBlockTagType.CATEGORY ? this.handleDeselectAllTagsOfCategory(category) : this.handleDeselectSingleTag(tag);
  }

  handleDeselectSingleTag(tag: IEasyBlockTag): void {
    this.checkedStateMap[tag.tagCategoryId].tags[tag.id].checked = false;

    const entireCategoryIsSelected = this.checkedStateMap[tag.tagCategoryId].tags[tag.tagCategoryId].checked;

    if (entireCategoryIsSelected) {
      // unchecked the select all checkbox
      this.checkedStateMap[tag.tagCategoryId].tags[tag.tagCategoryId].checked = false;

      // remove category chip
      this.selectedTags = this.selectedTags.filter((selectedTag: IEasyBlockTag) => {
        return selectedTag.id !== tag.tagCategoryId;
      });

      this.categorizedTags.find((category: ICategorizedTag) => category.id === tag.tagCategoryId).tags
        .filter((t: IEasyBlockTag) => t.easyBlockType !== EEasyBlockTagType.CATEGORY)
        .filter((t: IEasyBlockTag) => t.id !== tag.id) // skip the one that was deselected
        .forEach((t: IEasyBlockTag) => {
          this.addTagToSelectedTags(t);
        });
    } else {
      this.selectedTags = this.selectedTags.filter((selectedTag: IEasyBlockTag) => {
        return selectedTag.id !== tag.id;
      });
    }

    this.updateForm();
  }

  handleDeselectAllTagsOfCategory(category: ICategorizedTag): void {
    if (!category.tags) {
      category = this.categorizedTags.find((c: ICategorizedTag) => c.id === category.id);
    }

    category.tags.forEach((tag: IEasyBlockTag) => {
      this.checkedStateMap[category.id].tags[tag.id].checked = false;
      tag.checked = false;
      this.removeTagFromSelectedTags(tag);
    });
  }

  removeTagFromSelectedTags(tag: IEasyBlockTag|ICategorizedTag): void {
    this.selectedTags = this.selectedTags.filter((selectedTag: IEasyBlockTag) => {
      // return if the IDs match AND they're the same type because
      // some tag IDs match the category IDs
      return !(selectedTag.id === tag.id && selectedTag.easyBlockType === tag.easyBlockType);
      });

    this.updateForm();
  }

  handleChipRemoved(tag: IEasyBlockTag|ICategorizedTag): void {
    if (tag.easyBlockType === EEasyBlockTagType.TAG) {
      this.handleDeselectSingleTag(tag as IEasyBlockTag);
    } else {
      this.handleDeselectAllTagsOfCategory(tag as ICategorizedTag);
    }
  }

  easyBlockValidator(control: AbstractControl): ValidationErrors | null {
    let totalTagsSelected = 0;
    const easyBlockInvalid = {
      easyBlockForm: {
        value: totalTagsSelected,
        message: `Please select ${EASY_BLOCK_TAG_LIMIT} or less tagging technologies to block`
      }
    };

    totalTagsSelected = this.countSelectedTags(control.value);

    return totalTagsSelected > EASY_BLOCK_TAG_LIMIT ? easyBlockInvalid : null;
  }

  /**
   * Return the total number of tags selected in the menu, including the 'select all' option for any tag category that
   * may be selected.
   */
  countSelectedTags(tags: IEasyBlockTags): number {
    let selectedTagsSet: Set<number> = new Set();

    // Add tags from all selected tag categories
    tags.tagCategoryIds?.forEach((categoryId: number) => {
      const category = this.categorizedTags.find((c: ICategorizedTag) => c.id === categoryId);

      category?.tags?.forEach((tag: IEasyBlockTag) => {
        // First item in the tag list is the 'select all' option with the category id, so ignore it in the counts
        if (tag.id !== categoryId) selectedTagsSet.add(tag.id);
      });
    });

    // Add individual tags
    tags.tagIds?.forEach((tagId: number) => {
      selectedTagsSet.add(tagId);
    });

    return selectedTagsSet.size;
  }

  /***** */

  writeValue(tags: IEasyBlockTags): void {
    this.dataFromParent = tags;

    if (this.tagsAreLoaded) {
      this.loadSelectedTags();
    }
  }

  registerOnChange(fn: any): void {
    this.value.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  validate(c: AbstractControl): ValidationErrors {
    return this.value.valid ? null : {
      easyBlockForm: {
        valid: false, message: `Please select ${EASY_BLOCK_TAG_LIMIT} or less tagging technologies to block`
      }
    };
  }
}
