import { AuditReportHeaderComponent } from './audit-report-header/audit-report-header.component';
import { AuditReportContainerComponent } from './audit-report-container.component';
import { AuditReportContainerPaths, AuditReportContainerStateNames, AuditReportContainerTitles } from './audit-report-container.constants';
import { Route } from '@angular/router';
import { comparisonReportRoutes } from '../ror-comparison-report/ror-comparison-report.routes';
import { auditReportRoutes, pageDetailsDeepLink } from './audit-report/audit-report.routes';
import { AuditReportPaths } from './audit-report/audit-report.constants';
import { AuditExportComponent } from '@app/components/audit-reports/audit-exports/audit-export.component';

export const AuditReportContainerFutureState: Route = {
  path: AuditReportContainerPaths.base,
  loadChildren: () => import('./audit-report-container.module').then(mod => mod.AuditReportContainerModule)
};

const auditExports: Route = {
  path: AuditReportContainerPaths.auditExports,
  pathMatch: 'full',
  component: AuditExportComponent,
  data: {
    stateName: AuditReportContainerStateNames.auditExports,
    title: AuditReportContainerTitles.auditExports + ' - ' + AuditReportContainerTitles.base
  }
};

export const AuditReportContainerRoutes: Route = {
  path: '',
  component: AuditReportContainerComponent,
  data: {
    stateName: AuditReportContainerStateNames.base,
    fullScreen: true,
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: `${AuditReportPaths.base}/${AuditReportPaths.useCases}` },
    comparisonReportRoutes,
    auditExports,
    pageDetailsDeepLink,
    auditReportRoutes,
  ]
};

export const AuditReportTopBarRoutes: Route = {
  path: '',
  component: AuditReportHeaderComponent,
  outlet: 'navTopBar',
  data: {
    stateName: AuditReportContainerStateNames.base,
  },
};
