import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import {
  AuditReportUrlBuilders,
  EConsentCategoryComplianceStatus
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map } from 'rxjs/operators';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { sortBy } from 'lodash-es';
import {
  PrivacyRequestsService
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.service';
import {
  IPrivacyRequestsSummary,
  IPrivacyRequestsTrend,
  IPrivacyRequestsTrends,
  RequestsPrivacyRelevantFilters
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.models';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import {
  PrivacyRequestsTrendName,
  UNAPPROVED_CHART_CONFIG
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.constants';
import {
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import { formatNumber } from '@angular/common';
import { AbbreviateNumberPipe } from '@app/pipes/abbreviate-number.pipe';
import { Router } from '@angular/router';
import { AuditSummaryRelevantFilters } from '@app/components/audit-reports/reports/audit-summary/audit-summary.models';
import {
  PrivacyWidgetsLoadingStateHandler
} from '@app/components/audit-reports/reports/audit-summary/privacy-section-in-audit-summary/loading-state-handler';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';

const RelevantFiltersOverlap = AuditSummaryRelevantFilters.filter(ft => RequestsPrivacyRelevantFilters.includes(ft));

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'widgets-requests',
  templateUrl: './widgets-requests.component.html',
  styleUrls: ['./widgets-requests.component.scss'],
  providers: [AbbreviateNumberPipe]
})
export class WidgetsRequestsComponent extends PrivacyWidgetsLoadingStateHandler implements OnInit, OnChanges, OnDestroy {

  @Input() auditId: number;
  @Input() runId: number;
  @Input() apiFilters: IAuditReportApiPostBody;

  EConsentCategoryComplianceStatus = EConsentCategoryComplianceStatus;

  sparklineDataLoaded: boolean = false;
  widgetSparklineRunsInfo: ISparklineRunInfo[];

  requestSummary: IPrivacyRequestsSummary;
  requestTrends: IPrivacyRequestsTrend[];

  //PAGES WITH UNAPPROVED ITEMS
  widgetPagesWithUnapproved: ISplitCardChartData = {
    topLabel: 'Pages with Unapproved Request Domains & Geos',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.explore(),
    tooltip: 'Pages with a request domain or geolocation marked as unapproved in one or more consent category.',
  };
  pagesWithUnapprovedRequestCount: number;

  //Unapproved domains and geos
  widgetUnapproved: ISplitCardChartData = {
    topLabel: 'Unapproved Request Domains & Geos',
    tooltip: 'Count of request domains and geolocations that were marked as unapproved in an applied consent category.',
    topHandler: this.explore(),
    bottomHandler: this.openUnapprovedChart.bind(this)
  };
  widgetUnapprovedData: ISparklineChartData[];

  constructor(
    private router: Router,
    private privacyRequestsService: PrivacyRequestsService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private modalService: OpModalService,
    private auditReportLoadingService: AuditReportLoadingService,
    private abbreviateNumberPipe: AbbreviateNumberPipe,
  ) {
    super(auditReportLoadingService, filterBarService);
  }

  ngOnInit(): void {
    let previousRelevantFilters = [...this.filterBarService.currentFilters.filter(f => RelevantFiltersOverlap.includes(f.type))];
    this.filterBarService.anyFiltersUpdates$.pipe(
      debounceTime(300),
      filter(newFilters => {
        const newRelevantFilters = newFilters.filter(f => RelevantFiltersOverlap.includes(f.type));
        const diff = ArrayUtils.diff(previousRelevantFilters, newRelevantFilters, (f1, f2) => f1.type === f2.type);
        previousRelevantFilters = [...newRelevantFilters];
        if (diff.added.length > 0 || diff.removed.length > 0) {
          return true;
        } else {
          const anyChanges = JSON.stringify(diff.same.array1) !== JSON.stringify(diff.same.array2);
          return anyChanges;
        }
      })
    ).subscribe(newFilters => {
      this.apiFilters = this.filterBarService.generateApiPostBody(newFilters.filter(f => RelevantFiltersOverlap.includes(f.type)));
      this.loadRequests();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let newAuditId = changes?.auditId?.currentValue;
    let newRunId = changes?.runId?.currentValue;
    if (newAuditId || newRunId) {
      this.auditId = newAuditId || this.auditId;
      this.runId = newRunId || this.runId;
      this.loadRequests();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private loadRequests() {
    this.startLoading();
    this.sparklineDataLoaded = false;
    forkJoin([
      this.privacyRequestsService.getPrivacyRequestsSummary(this.auditId, this.runId, this.apiFilters)
        .pipe(catchError((err) => {
          console.error(`Failed to load locations results for map for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);
          return EMPTY;
        })),
      this.privacyRequestsService.getPrivacyRequestsTrends(this.auditId, this.runId)
        .pipe(
          map((data: IPrivacyRequestsTrends) => sortBy(data.runs, el => el.runId) as IPrivacyRequestsTrend[]),
          catchError((err) => {
            console.error(`Failed to load privacy trends for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);
            return EMPTY;
          })
        )
    ]).pipe(finalize(() => {
      this.stopLoading();
      this.sparklineDataLoaded = true;
    }))
      .subscribe(([requestSummary, requestTrends]) => {
        this.requestSummary = requestSummary;
        this.requestTrends = requestTrends;
        this.updateWidgets(requestSummary);
        this.updateSparkLines(requestTrends);
      });
  }

  private updateWidgets(data: IPrivacyRequestsSummary) {
    this.pagesWithUnapprovedRequestCount = data.filteredPageWithUnapprovedRequestCount;

    this.widgetUnapproved.topChangeContent = this.formatWidgetContent(data.filteredUniqueUnapprovedRequestDomainCount, data.totalUniqueUnapprovedRequestDomainCount);

    this.widgetUnapproved.topChangeMeaning = data.filteredUniqueUnapprovedRequestDomainCount === 0
      ? ESplitCardChangeMeaning.NEUTRAL
      : ESplitCardChangeMeaning.NEGATIVE;
  }

  private updateSparkLines(runs: IPrivacyRequestsTrend[]) {
    const widgetUnapprovedData: ISparklineChartData[] = [];
    const runsInfo: ISparklineRunInfo[] = [];

    runs.forEach((val, index) => {
      widgetUnapprovedData.push({value: val.totalUniqueUnapprovedRequestDomainCount, sequence: index});
      runsInfo.push({runId: val.runId, runCompletionDate: val.completedAt});
    });

    this.widgetSparklineRunsInfo = runsInfo;
    this.widgetUnapprovedData = widgetUnapprovedData;
  }

  public explore() {
    return () => {
      this.filterBarService.addConsentCategoryStatusFilter(EConsentCategoryComplianceStatus.Unapproved);
      this.router.navigateByUrl(AuditReportUrlBuilders.privacyRequests(this.auditId, this.runId));
    };
  }

  private openUnapprovedChart() {
    this.openFullscreenChart(
      PrivacyRequestsTrendName.Unapproved,
      this.widgetSparklineRunsInfo.length > 1
        ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate
        : undefined
    );
  }

  private openFullscreenChart(
    trendName: PrivacyRequestsTrendName,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: {...UNAPPROVED_CHART_CONFIG, title: 'Unapproved Request Domains & Geos'}
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  private getFullscreenChartData(trendName: any, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.privacyRequestsService.getPrivacyRequestsTrend(this.auditId, trendName, days)
      .pipe(
        map(({ runs }) => ({
            chartData: runs.map(({ trendValue, completedAt }) => ({ value: trendValue, date: completedAt }))
          })
        )
      );
  }

  private formatWidgetContent(filteredCount: number, totalCount?: number, abbreviate = false): string {
    const format = (x: number) => formatNumber(x, 'en-US');
    if (!this.isFiltered() || (this.isFiltered() && !totalCount)) {
      return abbreviate ? this.abbreviateNumberPipe.transform(filteredCount) : format(filteredCount);
    } else {
      return abbreviate ?
        `${this.abbreviateNumberPipe.transform(filteredCount)} of ${this.abbreviateNumberPipe.transform(totalCount)}` :
        `${format(filteredCount)} of ${format(totalCount)}`;
    }
  }

  isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.filter(f => RelevantFiltersOverlap.includes(f.type)).length > 0;
  }

}
