import { ChangeType } from '@app/components/audit-reports/reports/js-files-changes/js-file-changes.models';
import { minutesToTime } from '@app/components/date/date.service';

// This logic is used in PageDetails which is used in both AuditReport module and WebJourneyReport module
// so it was decided to share it as a util
export class JsFileChangesReportUtils {
  static formatDateDifference(largestDateDifference: number): string {
    if (typeof largestDateDifference !== 'number') {
      return '';
    }
    return largestDateDifference < 0
      ? '-' + minutesToTime(-1 * largestDateDifference, true)
      : minutesToTime(largestDateDifference, true);
  }

  static formatSizeDifference(largestSizeDifference: number, format: (value: number) => string) {
    return typeof largestSizeDifference !== 'number' ? '---' : format(largestSizeDifference);
  }

  static formatDiffValueAccordingToChangeType(changeTypeValue: string, originalDiffValue: number, formattedDiffValue: string) {
    switch (changeTypeValue) {
      case ChangeType.new_file:
      case ChangeType.deleted_file:
        return '---';
      case ChangeType.changed_file:
      case ChangeType.not_changed:
      case ChangeType.unknown:
        return originalDiffValue === undefined ? 'Unknown' : formattedDiffValue;
      default: return 'Unknown';
    }
  }
}
