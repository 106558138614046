export class ModalEscapeService {

    modalsStackModel = [];

    add(index: number): void {
      this.modalsStackModel.push(index);
    }

    clear(): void {
      this.modalsStackModel = [];
    }

    remove(index: number): void {
      let i = this.modalsStackModel.indexOf(index);
      if (i >= 0) {
        this.modalsStackModel.splice(i, 1);
      }
    }

    getLast(): number {
      return this.modalsStackModel.length > 0 ? this.modalsStackModel[this.modalsStackModel.length - 1] : 0;
    }
}
