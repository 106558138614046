import { datasourceAllTagsOnly } from './comparison-variable-selector.constants';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IComparisonTag } from '../../comparisons.models';
import { tagWarningMessages } from '../../comparisons.constants';
import { MAT_SELECT_CONFIG, MatSelectChange } from '@angular/material/select';
import { EDatasourceTagType } from '../../comparisons.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'comparison-variable-selector',
  templateUrl: './comparison-variable-selector.component.html',
  styleUrls: ['./comparison-variable-selector.component.scss']
})
export class ComparisonVariableSelectorComponent implements OnInit {

  @Input() formGroup: UntypedFormGroup;
  @Input() allTags: IComparisonTag[] = [];
  @Input() tagToAllVariablesMap: Map<number, string[]>;
  @Input() tagToExcludedVariablesMap: Map<number, string[]>;
  @Input() isTagPresenceOnly: boolean;

  @Output() onDeleteTag: EventEmitter<void> = new EventEmitter();
  @Output() onTagPresenceOnlyChange = new EventEmitter<boolean>();

  readonly datasourceTagType = EDatasourceTagType;
  readonly datasourceTagTypes = [{
    key: EDatasourceTagType.AllVariables,
    name: 'All Variables'
  }, {
    key: EDatasourceTagType.AllVariablesExceptExcluded,
    name: 'All Except Excluded'
  }, {
    key: EDatasourceTagType.SelectedVariables,
    name: 'Selected Variables'
  }];

  cachedTagId: number;
  readonly datasourceAllTags = datasourceAllTagsOnly;

  ngOnInit() {
    this.cachedTagId = this.tagIdControl.value;
  }

  getTagWarning(): string {
    if (this.isTagDisabledForComparison()) return tagWarningMessages.noLongerAvailable;
    else if (!this.allTags || !this.allTags.find(t => t.comparisonEnabled)) return tagWarningMessages.noAccountTags;
    // else if (this.runWithoutEnabledTags) return tagWarningMessages.runWithoutEnabled;
    return '';
  }

  isTagDisabledForComparison(): boolean {
    const tag = this.allTags && this.allTags.find(t => t.id === this.tagIdControl.value);
    return tag && !tag.comparisonEnabled; 
  }

  isVariableExcludedForAccount(variable: string): boolean {
    if (!this.allTags) {
      return false;
    }
    const tag = this.allTags.find(t => t.id === this.tagIdControl.value);
    return tag.accountExcludedVariables.includes(variable);
  }

  addCustomVar(variable: string): void {
    if (this.typeControl.value === EDatasourceTagType.SelectedVariables) {
      const allVars = this.tagToAllVariablesMap.get(this.tagIdControl.value);
      this.tagToAllVariablesMap.set(this.tagIdControl.value, [...allVars, variable]);
    } else if (this.typeControl.value === EDatasourceTagType.AllVariablesExceptExcluded) {
      const allVars = this.tagToExcludedVariablesMap.get(this.tagIdControl.value);
      this.tagToExcludedVariablesMap.set(this.tagIdControl.value, [...allVars, variable]);
    }

    this.variablesControl.setValue([...this.variablesControl.value, variable]);
  }

  getVariablesList(): string[] {
    return this.typeControl.value === EDatasourceTagType.SelectedVariables 
      ? this.tagToAllVariablesMap.get(this.tagIdControl.value) 
      : this.tagToExcludedVariablesMap.get(this.tagIdControl.value);
  }

  onChangeType(newType: EDatasourceTagType): void {
    const vars = (newType === EDatasourceTagType.AllVariablesExceptExcluded) ? 
      this.tagToExcludedVariablesMap.get(this.tagIdControl.value) : [];
    this.variablesControl.setValue(vars);
  }

  onTagChange(change: MatSelectChange): void {
    if (this.cachedTagId === this.datasourceAllTags.key || change.value === this.datasourceAllTags.key) {
      this.onTagPresenceOnlyChange.emit(change.value === this.datasourceAllTags.key);
    }
    this.cachedTagId = change.value;
  }

  get tagIdControl(): UntypedFormControl {
    return this.formGroup?.get('tagId') as UntypedFormControl;
  }

  get typeControl(): UntypedFormControl {
    return this.formGroup?.get('type') as UntypedFormControl;
  }

  get variablesControl(): UntypedFormControl {
    return this.formGroup?.get('selectedVariables') as UntypedFormControl;
  }
}
