import { CacheReturn } from '@app/components/core/decorators/cache-return.decorator';

/*
To calculate word width:

const size = calculate('Hello world!', {
   font: 'Arial',
   fontSize: '12px'
})

### Options
* `font: string`
* `fontSize: string`
* `fontWeight: string`
* `width: string` (Constraint width by a fixed value to calc height)

console.log(size.width) // 140
console.log(size.height) // 20 
*/

export interface IOptionalOptions {
    font?: string
    fontSize?: string
    fontWeight?: string
    width?: string
  }

  interface ICalculateWordWidthOptions {
    font: string
    fontSize: string
    fontWeight: string
    width: string
  }

  export interface IWordDimensions {
    width: number
    height: number
  }

  export class CalculateWordWidth {

    @CacheReturn() calculate(text: string, options: IOptionalOptions): IWordDimensions {
      options.font = options.font || 'Times';
      options.fontSize = options.fontSize || '16px';
      options.fontWeight = options.fontWeight || 'normal';
      options.width = options.width || 'auto';

      const element = this.createDummyElement(text, options as ICalculateWordWidthOptions);

      const size = {
        width: element.offsetWidth,
        height: element.offsetHeight,
      };

      this.destroyElement(element);

      return size;
    }

    private createDummyElement(text: string, options: ICalculateWordWidthOptions): HTMLElement {
      const element = document.createElement('div');
      const textNode = document.createTextNode(text);

      element.appendChild(textNode);

      element.style.fontFamily = options.font;
      element.style.fontSize = options.fontSize;
      element.style.fontWeight = options.fontWeight;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.left = '-999px';
      element.style.top = '-999px';
      element.style.width = options.width;
      element.style.height = 'auto';

      document.body.appendChild(element);

      return element;
    }

    private destroyElement(element: HTMLElement): void {
      element.parentNode.removeChild(element);
    }

}

