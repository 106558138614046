<div class="instruction">{{ ccAssignStrings.selectInstructions }}</div>
<cc-data-sources-filter-bar></cc-data-sources-filter-bar>
<div *ngIf="loading" class="spinner-wrap">
  <mat-spinner class="loading-spinner" [diameter]="75" [strokeWidth]="5"></mat-spinner>
</div>
<div [hidden]="loading" class="select-cc-table-wrap">
  <table #selectCcTable mat-table [dataSource]="dataSource" matSort class="select-cc-table">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CONSENT CATEGORY</th>
      <td mat-cell *matCellDef="let cc; let i = index;">
        <div class="dimming-wrap" [class.dimmed]="selectionType && cc.type !== selectionType">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="handleCheckboxClick(i)"
                        [checked]="selection.isSelected(cc)"
                        [disabled]="selectionType && cc.type !== selectionType"
                        color="primary"
                        [matTooltip]="cc?.name?.length > 25 ? cc.name : null"
                        matTooltipShowDelay="250"
                        matTooltipPosition="above"
                        class="cc-row-checkbox">
            {{ cc.name }}
          </mat-checkbox>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>TYPE</th>
      <td mat-cell *matCellDef="let cc">
        <div class="dimming-wrap" [class.dimmed]="selectionType && cc.type !== selectionType">
          {{ cc.type | titlecase }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="labels">
      <th mat-header-cell *matHeaderCellDef>LABEL</th>
      <td mat-cell *matCellDef="let cc">
        <div class="dimming-wrap line-clamp"
             [class.dimmed]="selectionType && cc.type !== selectionType"
             [matTooltip]="getLabelNames(cc.labelIds)"
             matTooltipShowDelay="900"
             matTooltipPosition="above"
        >
          {{ getLabelNames(cc.labelIds) }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="conflict">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let cc" class="conflicting-type-cell">
        <div class="conflicting-type-message"
              [class.conflicting-visible]="selectionType && cc.type !== selectionType"
              matTooltip="Consent Categories of differing types cannot be applied to the same data sets."
              matTooltipPosition="above">
          <mat-icon class="conflicting-icon">info_outline</mat-icon>
          Conflict
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [length]="paginationState.length" [pageSize]="paginationState.pageSize" [hidePageSize]="true"></mat-paginator>
</div>
