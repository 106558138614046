import { Component, Input, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { IOpFilterBarV2MenuItem } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EFilterBarV2MenuTypes, MenuClosedEvent } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.constants';
import { OpFilterBarV2Service } from '../op-filter-bar-v2.service';
import { OpClearableInputComponent } from '../../op-clearable-input/op-clearable-input.component';
import { IEventManager } from '@app/components/eventManager/eventManager';

@Component({
  selector: 'op-filter-bar-menu-item-v2',
  templateUrl: './op-filter-bar-menu-item-v2.component.html',
  styleUrls: ['./op-filter-bar-menu-item-v2.component.scss']
})
export class OpFilterBarMenuItemV2Component {

  clearableInput: OpClearableInputComponent;
  currentMenuItem: IOpFilterBarV2MenuItem;

  @Input() menuItems: IOpFilterBarV2MenuItem[];
  @Input() service: OpFilterBarV2Service<any>;
  @Input() matMenuTrigger: MatMenuTrigger;
  @Input() menuClass: string = '';
  @ViewChild('childMenu', { static: true }) public childMenu: MatMenu;

  EFilterBarMenuTypes = EFilterBarV2MenuTypes;

  constructor(private eventManager: IEventManager) {}

  handleUserInput(event: KeyboardEvent, opClearableInput: OpClearableInputComponent, item: IOpFilterBarV2MenuItem): void {
    item.action(event);

    if (this.clearableInput === undefined || this.currentMenuItem === undefined) {
      this.clearableInput = opClearableInput;
      this.currentMenuItem = item;
    }
  }

  handleMenuClosed(): void {
    this.eventManager.publish(MenuClosedEvent);

    if (this.clearableInput) {
      this.clearableInput.clearInput();
      this.currentMenuItem.children = [];
    }
  }

  handleCheckboxSearchResultChange(checked: boolean, item: IOpFilterBarV2MenuItem): void {
    item.action(checked);
  }

  closeMenu() {
    this.matMenuTrigger?.closeMenu();
  }
}
