<div class="input-container" [class.read-only]="isReadOnly">
  <mat-form-field class="styled-mat-input chips"
                  appearance="outline"
                  [floatLabel]="floatLabel">
    <mat-label>{{label}}</mat-label>
    <mat-chip-grid #chipList [disabled]="isReadOnly">
      <mat-chip-row *ngFor="let email of emails"
                    [matTooltip]="readOnlyLabel"
                    matTooltipPosition="above"
                    [matTooltipDisabled]="!isReadOnly"
                    (removed)="remove(email)">
        {{email}}
        <button matChipRemove>
          <mat-icon>close</mat-icon>
        </button>
      </mat-chip-row>
      <input [matChipInputFor]="chipList"
             autocomplete="false"
             [matTooltip]="readOnlyLabel"
             matTooltipPosition="above"
             [placeholder]="placeholder"
             [matTooltipDisabled]="!isReadOnly"
             [(ngModel)]="emailsInput"
             [disabled]="isReadOnly"
             (keyup)="onKeyUp($event)"
             (blur)="onBlur($event)"
             (paste)="onPaste($event)">
    </mat-chip-grid>
    <mat-icon *ngIf="emails.length && (!isReadOnly || isReadOnly && readOnlyLabel)"
              class="remove-all-control"
              matSuffix
              [matTooltip]="readOnlyLabel"
              matTooltipPosition="above"
              [matTooltipDisabled]="!isReadOnly"
              (click)="removeAll()">close
    </mat-icon>

    <mat-error *ngIf="chipList.errorState">
      <mat-icon>warning_amber</mat-icon>
      {{redEmailsHint}}
    </mat-error>
    <!--  With ng-container renders hint in wrong place  -->
    <mat-hint *ngIf="!hideHint && !chipList.errorState">Enter up to 10 email addresses, separate each with a comma.
    </mat-hint>
    <mat-hint *ngIf="!hideHint" class="counter" align="end"
              [class.red]="emails.length > maxEmailsLength">{{emails.length}}/{{maxEmailsLength}}</mat-hint>
  </mat-form-field>
</div>
