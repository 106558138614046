import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
export const LoginStateName = 'login';

export const LoginPaths = {
  base: 'login'
};

export const LoginUrlBuilders = {
  base: () => RouteBuilder.buildUrl([LoginPaths.base])
};
