import { IHar, ICookie } from '@app/models/dataCollection/har';
import * as Immutable from 'immutable';
import { IReduxAction } from './reducers';
import { ActionTypes } from '../constants/actionTypes';
import { IBrowserLog, IAuditPageDataCollection } from 'models/dataCollection/auditPageDataCollection';
import { IPageProfile } from 'models/tagModels/pageDetails';

function setHar(auditPageDataState, uuid: string, har: IHar) {
  return auditPageDataState.setIn(['dataCollection', uuid, 'har'], Immutable.fromJS(har));
}

function setCookies(auditPageDataState, uuid: string, cookies: Array<ICookie>) {
  return auditPageDataState.setIn(['dataCollection', uuid, 'cookies'], Immutable.fromJS(cookies));
}

function setLogs(auditPageDataState, uuid: string, logs: Array<IBrowserLog>) {
  return auditPageDataState.setIn(['dataCollection', uuid, 'logs'], Immutable.fromJS(logs));
}

function setAuditPageData(auditPageDataState, uuid: string, auditPageDataCollection: IAuditPageDataCollection) {
  return setLogs(
    setCookies(
        setHar(auditPageDataState, uuid, auditPageDataCollection.har),
        uuid,
        auditPageDataCollection.cookies
    ),
    uuid,
    auditPageDataCollection.logs
  );
}

function setAuditPageInformation(auditPageDataState, runId: number, pageId: string, pageInformation: IPageProfile) {
  return auditPageDataState.setIn(['runs', runId.toString(), 'pages', pageId], Immutable.fromJS(pageInformation));
}

export function auditPageDataReducer(auditPageDataState = Immutable.Map(), action: IReduxAction<any>) {
  switch (action.type) {
    case ActionTypes.SET_AUDIT_PAGE_DATA_COLLECTION:
      return setAuditPageData(auditPageDataState, action.payload.uuid, action.payload.auditPageDataCollection);
    case ActionTypes.SET_AUDIT_PAGE_INFORMATION:
      return setAuditPageInformation(auditPageDataState, action.payload.runId, action.payload.pageId, action.payload.pageInformation);
  }

  return auditPageDataState;
}