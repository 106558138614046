<op-modal (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="payload?.type" (onClose)="close()"></op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="body">
      <p class="info-text"
         *ngIf="[EFreeTrialAdModalType.ALERT, EFreeTrialAdModalType.RULE, EFreeTrialAdModalType.CONSENT_CATEGORY].includes(payload.type)">
        Get notified when ObservePoint finds broken pages,<br>
        missing tags, unauthorized cookies, and thousands of<br>
        other use case specific issues.
      </p>
      <p class="info-text" *ngIf="payload.type === EFreeTrialAdModalType.EXPORT">
        Export and share specific data from this report or see a<br>
        history of what has been exported.
      </p>
      <p class="unlock-text">
        Unlock this feature by starting a <a (click)="goToFreeTrialAd()">free trial</a>, or <a (click)="goToLoginPage()">login</a>.
      </p>
    </div>
  </ng-template>

  <ng-template #footerTemplate>
    <upgrade-user-banner [forceHidePartnerLogo]="true" [forceHidePagesScanned]="true"></upgrade-user-banner>
  </ng-template>
</op-modal>
