import { Route } from '@angular/router';
import { BulkOperationsComponent } from './bulk-operations.component';
import { BulkOperationsPaths, BulkOperationsStateNames } from './bulk-operations.const';
import { ManageFoldersComponent } from './manage-folders/manage-folders.component';
import { ManageDomainsComponent } from './manage-domains/manage-domains.component';
import { ManageLabelsComponent } from './manage-labels/manage-labels.component';

export const BulkOperationsFutureState: Route = {
  path: BulkOperationsPaths.base,
  loadChildren: () => import('./bulk-operations.module').then(mod => mod.BulkOperationsModule),
};

const manageFoldersState: Route = {
  path: BulkOperationsPaths.manageFolders,
  component: ManageFoldersComponent,
  data: {
    title: 'Manage Folders',
    stateName: BulkOperationsStateNames.manageFolders,
  }
};

const manageDomainsState: Route = {
  path: BulkOperationsPaths.manageSubfolers,
  component: ManageDomainsComponent,
  data: {
    title: 'Manage Subfolders',
    stateName: BulkOperationsStateNames.manageSubfolders,
  }
};

const manageLabelsState: Route = {
  path: BulkOperationsPaths.manageLabels,
  component: ManageLabelsComponent,
  data: {
    title: 'Manage Labels',
    stateName: BulkOperationsStateNames.manageLabels,
  }
};

export const BulkOperationsRoutes: Route = {
  path: '',
  component: BulkOperationsComponent,
  data: {
    stateName: BulkOperationsStateNames.base,
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: manageFoldersState.path },
    manageFoldersState,
    manageDomainsState,
    manageLabelsState
  ]
};
