<div class="banner-container"
    [class.show-partner-logo]="showPartnerLogo"
    [class.show-pages-scanned]="showPagesScanned">
  <div class="partner-content" *ngIf="showPartnerLogo">
    <span class="title-text">Brought to you by:</span>
    <img [src]="'https://s3.amazonaws.com/img.observepoint.com/partner_logos/' + partnerId + '.png'" height="24">
    <span class="plus-sign">+</span>
    <mat-icon class="op-icon" svgIcon="op-black-logo"></mat-icon>
  </div>

  <div class="banner-content">

    <!-- Show content based on Account Type and visitor mode -->
    <ng-container *ngIf="accountType === EAccountType.SAMPLE && (isVisitorMode$ | async); then upgradeToFreeTrial; else upgradeToFullAccount">
    </ng-container>

    <!-- Upgrade to Free Trial template -->
    <ng-template #upgradeToFreeTrial>
      <span class="banner-link">
        {{ textOverride ?? 'START FREE TRIAL NOW' }}
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </span>
    </ng-template>

    <!-- Upgrade to Full Account template -->
    <ng-template #upgradeToFullAccount>
      <span class="banner-link">
        <div class="freetrial-container">
          <div class="side" *ngIf="showPagesScanned">
            <mat-icon *ngIf="pagesScannedThisYear >= maxPagesAllowedForScanning"
                      class="info-icon">
                      info
            </mat-icon>
            {{ pagesScannedThisYear | number }} of {{ maxPagesAllowedForScanning }} {{ pagesScannedThisYear >= maxPagesAllowedForScanning ? 'PAGE LIMIT HAS BEEN REACHED' : 'PAGE LIMIT HAS BEEN USED' }}
          </div>
          <div class="side">
            {{ textOverride ?? 'START FULL OBSERVEPOINT ACCOUNT' }}
            <mat-icon>keyboard_double_arrow_right</mat-icon>
          </div>
        </div>
      </span>
    </ng-template>

  </div>
</div>
