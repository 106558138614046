import { Injectable } from '@angular/core';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { StorageService } from '@app/components/shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { DateService } from '@app/components/date/date.service';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import {
  EAuditReportFilterTypes,
  IConsentCategoryReportFilterData
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { RulesService } from '@app/components/rules/rules.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiValidationsService } from '@app/services/api-validations/api-validations.service';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';

@Injectable()
export class AlertLogicFilterBarService extends AuditReportFilterBarService {
  constructor(
    storage: StorageService,
    cacheReset: CacheResetService,
    protected dateService: DateService,
    protected modalService: OpModalService,
    protected uiTagService: UiTagService,
    protected snackbar: MatSnackBar,
    protected ccService: ConsentCategoriesService,
    protected rulesService: RulesService,
    protected apiValidationsService: ApiValidationsService
  ) {
    super(storage, cacheReset, dateService, modalService, uiTagService, ccService, snackbar, rulesService, apiValidationsService);
  }

  // Overriding methods in base class. Filter bar service doesn't need to deal with
  // read/write to local storage.
  protected initializeFiltersFromLocalStorage() {
  }

  updateSubscribers(): void {
    const mergedFilters = this.createUniqueArrayOfFilters([...this.filters, ...this.anyFiltersUpdates$.value]);

    this.anyFiltersUpdates$.next(mergedFilters);
  }

  get currentRelevantFilters() {
    return this.createUniqueArrayOfFilters([...this.filters, ...this.anyFiltersUpdates$.value]).filter((filter: IOpFilterBarFilter<EAuditReportFilterTypes>) => this.validTypes?.includes(filter.type));
  }

  removeFilterByType(filterType: EAuditReportFilterTypes) {
    this.filters = this.anyFiltersUpdates$.value;
    const toRemove = this.anyFiltersUpdates$.value.filter(f => f.type === filterType);

    toRemove.forEach(filter => {
      const remove = this.anyFiltersUpdates$.value.findIndex(f => f === filter);
      if (remove >= 0) {
        this.anyFiltersUpdates$.value.splice(remove, 1);
        this.updateSubscribers();
      }
    });
  }

  removeFilter(filter: IOpFilterBarFilter<EAuditReportFilterTypes>) {
    this.filters = this.anyFiltersUpdates$.value;
    const remove = this.anyFiltersUpdates$.value.findIndex(f => JSON.stringify(f) === JSON.stringify(filter));
    if (remove >= 0) {
      this.anyFiltersUpdates$.value.splice(remove, 1);
      this.updateSubscribers();
    }
  }

  addConsentCategoryNameFilter(categoryId: number, categoryName: string) {
    this.addFilter({
      type: EAuditReportFilterTypes.ConsentCategoryId,
      display: `Consent Category Name is '${categoryName}'`,
      value: categoryId,
      data: {
        categoryId: categoryId,
        name: categoryName
      } as IConsentCategoryReportFilterData
    });
  }

  replaceFilter(replaceType: EAuditReportFilterTypes, withFilter: IOpFilterBarFilter<EAuditReportFilterTypes>) {
    this.filters = this.anyFiltersUpdates$.value;

    const toReplace = this.anyFiltersUpdates$.value.filter(f => f.type === replaceType);
    let insertIndex = this.filters.length;
    toReplace.forEach((filter, index) => {
      const replaceIndex = this.filters.findIndex(f => f === filter);
      if (replaceIndex >= 0 && index === 0) {
        insertIndex = replaceIndex; // insert new filter at the same index as the first filter to be replaced
      }
      this.anyFiltersUpdates$.value.splice(replaceIndex, 1);
    });
    this.anyFiltersUpdates$.value.splice(insertIndex, 0, withFilter);

    this.updateSubscribers();
  }
}
