import { Component } from '@angular/core';
import { EChartColor } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { EBarChartDirection, EBarChartTextPosition } from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.constants';
import { IHorizontalBarChartDataPoint, IHorizontalBarChartTitle } from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-horizontal-bar-chart',
  templateUrl: './cl-horizontal-bar-chart.component.html',
  styleUrls: ['./cl-horizontal-bar-chart.component.scss']
})
export class ComponentLibraryHorizontalBarChartComponent {

  readonly EFilterSpinnerState = EFilterSpinnerState;
  readonly EBarChartTextPosition = EBarChartTextPosition;
  readonly EBarChartDirection = EBarChartDirection;

  example1Data: IHorizontalBarChartDataPoint[] = [
    { name: 'A', value: 10, filtered: false, colorClass: EChartColor.Red },
    { name: 'B', value: 20, filtered: false, colorClass: EChartColor.Orange },
    { name: 'C', value: 40, filtered: false, colorClass: EChartColor.Yellow },
    { name: 'D', value: 30, filtered: false, colorClass: EChartColor.Green }
  ];

  example2Data: IHorizontalBarChartDataPoint[] = [
    { name: 'A', value: 75, filtered: false, colorClass: EChartColor.Green }
  ];

  example2Title: IHorizontalBarChartTitle = {
    title: 'Example With A Title & Tooltip',
    tooltip: 'Example tooltip'
  };

}
