<div class="content">
  <div class="body" [class.with-filters]="isAppliedFilters">
    <div class="tab-body">
      <p class="description">
        Select items that you want to assign this user's email address to:
      </p>
      <op-filter-bar [menuItems]="filterBarMenuItems"
                     [service]="filterBarService"
                     [hideSearchByRegexOption]="true"
                     [keepWide]="true"
                     [isSearchByTextEnabled]="true"
                     [validFilterTypes]="filterBarService.validFilterTypesUpdated$ | async"
                     (searchByTextIsEntered)="filterBarService.addNameContainsFilter($event.value)"
                     searchByTextPlaceholderSuffix="Item Name"
                     [collapsablePills]="false">
      </op-filter-bar>
      <div class="tabs">
        <div class="tab-title" [class.active]="itemType === ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED"
             (click)="changeItemType(ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED)">
          <mat-icon>mail</mat-icon>
          <div class="title">
            Email Inbox Received <br>
            ({{currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED].selectedItems.length}})
          </div>
        </div>
        <div class="tab-title" [class.active]="itemType === ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED"
             (click)="changeItemType(ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED)">
          <mat-icon>mail</mat-icon>
          <div class="title">
            Email Inbox Processed <br>
            ({{currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED].selectedItems.length}})
          </div>
        </div>
        <div class="tab-title" [class.active]="itemType === ENotificationCenterTargetItemType.ALERT" (click)="changeItemType(ENotificationCenterTargetItemType.ALERT)">
          <mat-icon>notifications_none</mat-icon>
          <div class="title">
            Alerts <br>({{currentState.items[ENotificationCenterTargetItemType.ALERT].selectedItems.length}})
          </div>
        </div>
        <div class="tab-title" [class.active]="itemType === ENotificationCenterTargetItemType.USAGE_ALERT" (click)="changeItemType(ENotificationCenterTargetItemType.USAGE_ALERT)">
          <mat-icon>multiline_chart</mat-icon>
          <div class="title">
            Usage Alerts <br>({{currentState.items[ENotificationCenterTargetItemType.USAGE_ALERT].selectedItems.length}})
          </div>
        </div>
        <div class="tab-title" [class.active]="itemType === ENotificationCenterTargetItemType.RULE" (click)="changeItemType(ENotificationCenterTargetItemType.RULE)">
          <mat-icon>straighten</mat-icon>
          <div class="title">
            Tag & Variable Rules <br>({{currentState.items[ENotificationCenterTargetItemType.RULE].selectedItems.length}})
          </div>
        </div>
        <div class="tab-title" [class.active]="itemType === ENotificationCenterTargetItemType.AUDIT" (click)="changeItemType(ENotificationCenterTargetItemType.AUDIT)">
          <mat-icon>explore</mat-icon>
          <div class="title">
            Audit Summary <br>({{currentState.items[ENotificationCenterTargetItemType.AUDIT].selectedItems.length}})
          </div>
        </div>
        <div class="tab-title" [class.active]="itemType === ENotificationCenterTargetItemType.WEB_JOURNEY" (click)="changeItemType(ENotificationCenterTargetItemType.WEB_JOURNEY)">
          <mat-icon>map</mat-icon>
          <div class="title">
            Web Journey Failures <br>({{currentState.items[ENotificationCenterTargetItemType.WEB_JOURNEY].selectedItems.length}})
          </div>
        </div>
      </div>
      <div class="spinner-container" *ngIf="!itemsToSelect || itemsToSelect.loading">
        <mat-spinner [diameter]="25"
                     [strokeWidth]="4">
        </mat-spinner>
      </div>
      <op-selectable-table [class.hidden]="!itemsToSelect || itemsToSelect.loading"
                           [allItems]="itemsToSelect"
                           [selectedItems]="currentState.items[itemType].selectedItems"
                           class="selectable-table"
                           selectedItemText="Items"
                           selectionIndentifier="itemId"
                           [showSelectedLimit]="false"
                           [selectedItemsLimit]="SELECT_ITEMS_LIMIT"
                           [columns]="columns[itemType]"
                           [(displaySelection)]="displaySelectedTable[itemType]"
                           [labels]="labels$ | async"
                           [pagination]="currentState.items[itemType].pagination"
                           (selectionChanged)="onSelectionChanged($event)"
                           (paginationChanged)="changePagination($event)"
                           (sortChanged)="changeSorting($event)">
      </op-selectable-table>
    </div>
  </div>
</div>
