<div class="main-body">
  <mat-icon class="check">check_circle</mat-icon>
  <div class="main-body-right">
    <ng-container *ngIf="data.type === EEmailInboxSuccessType.Created
             || data.type === EEmailInboxSuccessType.Duplicated">
      <h3 class="text mb15 bold heading">All Setup - You're ready to start testing your emails!</h3>
      <p class="text">
        Inbox <span class="text bold">{{ data.name }}</span>
        with {{ data.audits?.length }} associated
        Audit{{ data.audits?.length > 1 ? 's' : '' }} has been created.

        <ng-container *ngIf="data.subscribers?.onProcessed?.length; else noSubscribers">
          You will get a single notification when all validation is complete. to the following destinations:
          <ul class="text">
            <li *ngFor="let email of data.subscribers.onProcessed">{{ email }}</li>
          </ul>
        </ng-container>
      </p>

      <ng-template #noSubscribers>
        No notifications are assigned to this email inbox. We recommend configuring alerts with recipients so it’s easy
        to tell if this message is ready to send.
      </ng-template>

      <p class="text bold">Start sending your email messages to this Inbox address (copied to your clipboard):</p>

      <op-copy-box [copyValue]="data.emailAddress"
                   [color]="ECopyBoxColor.Yellow"
                   [compactCopyBtn]="true"
                   [copyBtnIcon]="'copy_all'"
                   class="copy-box"></op-copy-box>

      <button mat-flat-button
              class="close-button"
              [cbContent]="data.emailAddress"
              ngxClipboard
              (click)="close()">
        Copy Address to Clipboard & Close
      </button>
    </ng-container>

    <ng-container *ngIf="data.type === EEmailInboxSuccessType.Edited">
      <div class="text-wrapper">
        <p class="text">
          Inbox <span class="text bold">{{ data.name }}</span> has been updated successfully.
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="data.type === EEmailInboxSuccessType.Deleted">
      <div class="text-wrapper">
        <p class="text">
          Inbox at <span class="text bold">{{ data.emailAddress }}</span> has been deleted successfully.
        </p>
      </div>
    </ng-container>
  </div>

  <mat-icon class="close-icon" (click)="close()">close</mat-icon>
</div>
