import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AccountsService } from '@app/components/account/account.service';
import { IUser } from '@app/moonbeamModels';
import { MatTableDataSource } from '@angular/material/table';
import {
  ENotificationCenterTargetItemType,
  ETargetItemsSortColumns,
  IBulkActionItem,
  IBulkActionOperation,
  INotificationCenterSort,
  INotificationCenterTargetItem,
  INotificationProfile
} from '@app/components/notifications-center/notification-center.models';
import {
  EmailsUpdateOperation,
  EUpdateOperationType,
  IUpdateOperationResult,
  NotificationCenterService
} from '@app/components/notifications-center/notification-center.service';
import { MatSort } from '@angular/material/sort';
import { ENotificationCenterColumns, ENotificationCenterTargetItemField } from './notification-center.enums';
import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  BULK_ACTION_MAX_PARALLEL_REQUESTS,
  NotificationCenterDefaultSortConfig,
  NotificationCenterHelpUrl
} from './notification-center.constants';
import { filter, finalize, mergeMap, pluck, switchMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { NotificationCenterModalComponent } from '@app/components/notifications-center/notification-center-modal/notification-center-modal.component';
import {
  INotificationProfileSetupModalData,
  INotificationProfileSetupModalResultItem
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.models';
import { OpDeleteItemWarningComponent } from '@app/components/shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { ChangeEmailComponent } from '@app/components/notifications-center/change-email/change-email.component';
import { BulkActionProgressComponent } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.component';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { BulkActionProgressService } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.service';

@Component({
  selector: 'op-notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss'],
  animations: [
    trigger('expand', [
      state('collapsed', style({ maxHeight: '0', minHeight: '0' })),
      state('loading', style({ maxHeight: '60px', minHeight: '60px' })),
      state('expanded', style({ maxHeight: '200px', minHeight: '{{height}}' }), { params: { height: '*' } }),
      transition('collapsed <=> expanded, loading <=> *', animate('200ms'))
    ])
  ],
})
export class NotificationCenterComponent implements OnInit, AfterViewInit, OnDestroy {
  protected readonly NotificationCenterHelpUrl = NotificationCenterHelpUrl;
  protected readonly ENotificationCenterTargetItemType = ENotificationCenterTargetItemType;

  bulkActionPending: boolean = false;
  @ViewChild(MatSort) matSort: MatSort;
  @Input() sortOptions: INotificationCenterSort = NotificationCenterDefaultSortConfig;
  @Output() updateTableState = new EventEmitter<INotificationCenterSort>();

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.bulkActionPending) {
      $event.preventDefault();
      $event.returnValue = true;
    }
  }

  readonly displayedColumns = [
    ENotificationCenterColumns.Email,
    ENotificationCenterColumns.InboxReceivedCount,
    ENotificationCenterColumns.InboxCompletionCount,
    ENotificationCenterColumns.AlertCount,
    ENotificationCenterColumns.UsageAlertCount,
    ENotificationCenterColumns.RuleCount,
    ENotificationCenterColumns.AuditCount,
    ENotificationCenterColumns.WebJourneyCount,
    ENotificationCenterColumns.Edit,
  ];

  readonly targetItemTypes: ENotificationCenterTargetItemType[] = [
    ENotificationCenterTargetItemType.ALERT,
    ENotificationCenterTargetItemType.INBOX,
    ENotificationCenterTargetItemType.USAGE_ALERT,
    ENotificationCenterTargetItemType.AUDIT,
    ENotificationCenterTargetItemType.RULE,
    ENotificationCenterTargetItemType.WEB_JOURNEY,
  ];

  private notificationCenterSortSubscription: Subscription;
  users: IUser[];
  loading = false;

  selectedRow: INotificationProfile;
  dataSource = new MatTableDataSource<INotificationProfile>();

  topHeaderColumns = [
    'empty',
    'inbox',
    'alerts',
    'tagAndVariables',
    'auditRun',
    'webJourneyRun',
    'empty2',
  ];

  lastOpenedNestedTable: HTMLDivElement;
  lastOpenedNestedTableHeight: number;
  infoIsExpanded: boolean;
  stopBulkAction: Subject<void> = new Subject();

  constructor(
    private notificationCenterService: NotificationCenterService,
    private accountsService: AccountsService,
    private modalService: OpModalService,
    private snackBar: MatSnackBar,
    private bulkActionService: BulkActionProgressService,
  ) {
  }

  private getUsers(): void {
    this.accountsService
      .getUsers()
      .subscribe(users => this.users = users);
  }

  private getEmails(): void {
    this.loading = true;
    this.notificationCenterService.getEmails()
      .pipe(finalize(() => this.loading = false))
      .subscribe(profiles => this.dataSource.data = profiles.emails);
  }

  private initSort(): void {
    this.dataSource.sort = this.matSort;

    this.dataSource.sortingDataAccessor = (data, attribute) => {
      switch (attribute) {
        case ENotificationCenterColumns.AuditCount:
          return +data.usage.auditCount;
        case ENotificationCenterColumns.AlertCount:
          return +data.usage.alertCount;
        case ENotificationCenterColumns.UsageAlertCount:
          return +data.usage.usageAlertCount;
        case ENotificationCenterColumns.RuleCount:
          return +data.usage.ruleCount;
        case ENotificationCenterColumns.WebJourneyCount:
          return +data.usage.webJourneyCount;
        default:
          return data[attribute].toLowerCase();
      }
    };

    this.notificationCenterSortSubscription = this.matSort?.sortChange.subscribe(sort => {
      this.sortOptions.sortBy = sort.active as ENotificationCenterColumns;
      this.sortOptions.sortDesc = sort.direction === 'desc';
      this.updateTableState.emit(this.sortOptions);
    });
  }

  private getItemsToCount(profile: INotificationProfile) {
    const usage = profile.usage;
    return [
      {
        label: 'Alert',
        value: usage.alertCount,
      },
      {
        label: 'Usage Alert',
        value: usage.usageAlertCount,
      },
      {
        label: 'Rule',
        value: usage.ruleCount,
      },
      {
        label: 'Audit',
        value: usage.auditCount,
      },
      {
        label: 'Web Journey',
        value: usage.webJourneyCount,
      }
    ];
  }

  filterRows(input): void {
    const filter = input?.target?.value ? input?.target?.value : '';
    this.dataSource.filter = filter.trim().toLowerCase();
  }

  openReplaceEmailModal(profile: INotificationProfile): void {
    const totalItemCount = this.getItemsToCount(profile).reduce((acc, { value }) => acc + value, 0);

    this.modalService.openModal(ChangeEmailComponent, {
      data: {
        title: 'Reassign to different email address',
        helpText: `Specify an email address to replace all assignments of <b>${profile.email}</b>`,
        profile,
      }
    }).afterClosed()
      .pipe(
        filter(v => !!v),
        switchMap(({ emails, profile }) => this.handleReplaceAssignments(emails, profile, totalItemCount))
      )
      .subscribe();
  }

  openCloneAssignmentsModal(profile: INotificationProfile): void {
    const totalItemCount = this.getItemsToCount(profile).reduce((acc, { value }) => acc + value, 0);

    this.modalService.openModal(ChangeEmailComponent, {
      data: {
        title: 'Add additional email address',
        helpText: `Specify an email address to assign everywhere where <b>${profile.email}</b> is already assigned`,
        profile,
      }
    }).afterClosed()
      .pipe(
        filter(v => !!v),
        switchMap(({ emails, profile }) => this.handleCloneAssignments(emails, profile, totalItemCount))
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.getUsers();
    this.getEmails();
  }

  ngAfterViewInit(): void {
    this.initSort();
  }

  ngOnDestroy(): void {
    this.notificationCenterSortSubscription?.unsubscribe();
  }

  openCreateAssignmentsModal(): void {
    this.modalService.openModal(
      NotificationCenterModalComponent,
      { data: {} },
      'op-modal-extended-height',
    )
      .afterClosed()
      .pipe(
        filter(v => !!v),
        switchMap(({
          emails,
          items
        }) => this.handleCreateAssignments(emails, items, items.reduce((acc, i) => acc + i.value.length, 0)))
      )
      .subscribe();
  }

  openEditAssignmentsModal(profile: INotificationProfile, initialTab?: ENotificationCenterTargetItemType): void {
    this.modalService.openModal<NotificationCenterModalComponent, INotificationProfileSetupModalData>(
      NotificationCenterModalComponent,
      {
        data: {
          profile: JSON.parse(JSON.stringify(profile)),
          showSideNav: false,
          title: `UPDATE ITEMS ASSIGNED TO THIS EMAIL ADDRESS <${profile.email}>`,
          ...(initialTab ? { initialTab } : {}),
        },
      },
      'op-modal-extended-height',
    ).afterClosed()
      .pipe(
        filter(v => !!v),
        switchMap(({
          emails,
          items
        }) => {
          return this.handleEditAssignments(emails, items, items.reduce((acc, i) => acc + i.value.length, 0))
        })
      )
      .subscribe();
  }

  openRemoveAssignmentsModal(profile: INotificationProfile): void {
    const itemsToCount = this.getItemsToCount(profile);
    const totalItemCount = itemsToCount.reduce((acc, { value }) => acc + value, 0);
    const totalItemsMessage = `${totalItemCount} item${totalItemCount > 1 ? 's' : ''}`;
    const constructMessage = (label: string, counter?: number): string | null => counter
      ? `<li>${counter} ${label}${counter > 1 ? 's' : ''}</li>`
      : null;

    const messages = itemsToCount.map(({ label, value }) =>
      constructMessage(label, value)).filter(message => !!message).join('');

    const itemList = `<ul class="item-list">${messages}</ul>`;
    const highlightedEmail = `<p class="highlighted">${profile.email}</p>`;
    const fullMessage = `<p>Are your sure you want to permanently remove the
    ${highlightedEmail} from the following ${totalItemsMessage}?
    This can’t be undone but can be re-added. ${itemList}</p>`;

    this.modalService.openModal(OpDeleteItemWarningComponent, {
      data: {
        itemType: ``,
        name: profile.email,
        defaultMessage: false,
        customTitle: `Remove email from assigned items`,
        customMessage: fullMessage,
        customConsentMessage: `I understand the email ${profile.email} will be removed`,
        customConfirmBtnText: 'Remove email',
        customDeleteHint: 'remove',
        showWarning: false,
      }
    })
      .afterClosed()
      .pipe(
        filter(v => !!v),
        switchMap(() => this.handleRemoveAssignments(profile, totalItemCount))
      )
      .subscribe();
  }

  private handleBulkAction(
    operations: IBulkActionOperation[],
    totalItemCount: number,
    message: string,
  ): Observable<IUpdateOperationResult[]> {
    
    this.bulkActionPending = true;
    const itemsSource = this.notificationCenterService.getAllAudits();

    // Counter for bulk action modal has to be scoped like this because we are handling several different actions,
    // and they must reuse the value to show correct progress in modal
    let counter = 0;

    const handleSingleOperation = (
      operation: EmailsUpdateOperation,
      item: IBulkActionItem,
    ): Observable<IUpdateOperationResult> => {
      let stream: Observable<IUpdateOperationResult>;
      switch (operation.operationType) {
        case EUpdateOperationType.ADD:
        case EUpdateOperationType.REMOVE:
        case EUpdateOperationType.REPLACE:
          stream = item.value
            .pipe(
              mergeMap((targetItems) => {
                const args: [number[], EmailsUpdateOperation] = [
                  targetItems.map(i => i.itemId),
                  operation,
                ];

                switch (item.itemType) {
                  case ENotificationCenterTargetItemType.ALERT:
                  case ENotificationCenterTargetItemType.USAGE_ALERT:
                    return this.notificationCenterService.updateEmailsInAlerts(...args);

                  case ENotificationCenterTargetItemType.AUDIT:
                    return this.notificationCenterService.updateEmailsInAudits(...args, itemsSource);

                  case ENotificationCenterTargetItemType.RULE:
                    return this.notificationCenterService.updateEmailsInRules(...args);

                  case ENotificationCenterTargetItemType.WEB_JOURNEY:
                    return this.notificationCenterService.updateEmailsInWebJourneys(...args);

                  case ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED:
                    return this.notificationCenterService.updateEmailsInInboxEmailsReceived(...args);

                  case ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED:
                    return this.notificationCenterService.updateEmailsInInboxEmailsProcessed(...args);
                }
              }),
              tap(() => this.bulkActionService.publishProgressbar(++counter)),
            );
      }

      return stream;
    };

    this.showProgressDialog(message, totalItemCount);

    return of(...operations).pipe(
      mergeMap(({ operation, items }) =>
        of(...items).pipe(
          mergeMap(item => handleSingleOperation(operation, item), BULK_ACTION_MAX_PARALLEL_REQUESTS),
          toArray()
        )
      ),
      // Stop api requests when dismiss button is clicked
      takeUntil(this.stopBulkAction),
      finalize(() => {
        // Disable prevent accidental close handler
        this.bulkActionPending = false;
        // Reload the table to display the updates
        this.getEmails();
      }),
    );
  }

  private handleRemoveAssignments(
    profile: INotificationProfile,
    totalItemCount: number,
  ): Observable<IUpdateOperationResult[]> {
    const items = this.mapTargetItemTypes(profile);

    return this.handleBulkAction(
      [{
        operation: EmailsUpdateOperation.REMOVE([profile.email]),
        items,
      }],
      totalItemCount,
      `Removing ${profile.email} from ${totalItemCount} item${totalItemCount > 1 ? 's' : ''}`,
    );
  }

  private handleCreateAssignments(
    emails: string[],
    items: INotificationProfileSetupModalResultItem[],
    totalItemCount: number,
  ): Observable<IUpdateOperationResult[]> {
    return this.handleBulkAction(
      [{
        operation: EmailsUpdateOperation.ADD(emails),
        items: items.map(i => ({ ...i, value: of(i.value) })),
      }],
      totalItemCount,
      `Adding ${emails.join(', ')} to ${totalItemCount} item${totalItemCount > 1 ? 's' : ''}`
    );
  }

  private handleEditAssignments(
    emails: string[],
    items: INotificationProfileSetupModalResultItem[],
    totalItemCount: number,
  ): Observable<IUpdateOperationResult[]> {

    const groupedByOperationType = items.reduce((result, item) => {
      item.value.forEach(innerItem => {
        const { operationType } = innerItem;

        if (!result.hasOwnProperty(operationType)) {
          result[operationType] = [];
        }

        result[operationType].push({ ...item, value: of([innerItem]) });
      });

      return result;
    }, {});

    const operations = [
      ...(groupedByOperationType[EUpdateOperationType.ADD]?.length
          ? [{
            operation: EmailsUpdateOperation.ADD(emails),
            items: groupedByOperationType[EUpdateOperationType.ADD],
          }]
          : []
      ),
      ...(groupedByOperationType[EUpdateOperationType.REMOVE]?.length
          ? [{
            operation: EmailsUpdateOperation.REMOVE(emails),
            items: groupedByOperationType[EUpdateOperationType.REMOVE],
          }]
          : []
      ),
    ];

    return this.handleBulkAction(
      operations,
      totalItemCount,
      `Editing ${emails.join(', ')} with ${totalItemCount} item${totalItemCount > 1 ? 's' : ''}`
    );
  }

  private handleCloneAssignments(
    emails: string[],
    profile: INotificationProfile,
    totalItemCount: number,
  ): Observable<IUpdateOperationResult[]> {
    const items = this.mapTargetItemTypes(profile);

    return this.handleBulkAction(
      [{
        operation: EmailsUpdateOperation.ADD(emails),
        items,
      }],
      totalItemCount,
      `Adding ${emails.join(', ')} to ${totalItemCount} item${totalItemCount > 1 ? 's' : ''}`,
    );
  }

  private handleReplaceAssignments(
    emails: string[],
    profile: INotificationProfile,
    totalItemCount: number,
  ): Observable<IUpdateOperationResult[]> {
    const items = this.mapTargetItemTypes(profile);

    return this.handleBulkAction(
      [{
        operation: EmailsUpdateOperation.REPLACE([profile.email], emails),
        items,
      }],
      totalItemCount,
      `Reassigning ${emails.join(', ')} to ${totalItemCount} item${totalItemCount > 1 ? 's' : ''}`,
    );
  }

  private showProgressDialog(
    message: string,
    count: number,
    showFinalMessage: boolean = true,
    additionalMessage: string = `Don't close this banner, or leave this page until 
    finished or remaining operations won't be complete`,
    finalMessage?: string,
  ): void {
    const confirmConfig = {
      maxCount: count,
      messages: [
        message,
        additionalMessage,
        finalMessage,
      ],
      showFinalMessage,
      showSecondBtn: false,
      showProgressBar: true,
      rightFooterButtons: [
        {
          label: 'Yes, pause them',
          opSelector: 'bulk-confirm-pause-yes',
          hidden: false,
          primary: false,
        },
        {
          label: 'Cancel',
          opSelector: 'bulk-confirm-pause-cancel',
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionProgressComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    })
      .afterDismissed()
      .subscribe((observer?: MatSnackBarDismiss) => {
        if (observer.dismissedByAction) {
          this.stopBulkAction.next();
        }
      });
  }

  private getItems(itemType: ENotificationCenterTargetItemType, profile: INotificationProfile): Observable<Array<INotificationCenterTargetItem>> {
    // API error
    // searchTargetItemsForEmails.size: must be greater than or equal to 50
    const pageSize = 2 * profile.usage[ENotificationCenterTargetItemField[itemType]] > 50
      ? 2 * profile.usage[ENotificationCenterTargetItemField[itemType]]
      : 50;
    return this.notificationCenterService.searchTargetItems(
      itemType,
      {
        sortBy: ETargetItemsSortColumns.isAssigned,
        sortDesc: false
      },
      {
        currentPageNumber: 0,
        currentPageSize: pageSize,
        pageSize,
        totalCount: this.dataSource.data.length,
        totalPageCount: 1,
      },
      {
        email: profile.email,
        isAssigned: true
      }
    )
      .pipe(pluck(('items')));
  }

  private mapTargetItemTypes(profile: INotificationProfile): IBulkActionItem[] {
    return this.targetItemTypes
      // Don't perform actions if there are no items to be cloned
      .filter(item => profile.usage[ENotificationCenterTargetItemField[item]] > 0)
      .map(i => ({ value: this.getItems(i, profile), itemType: i }));
  }
}
