<mat-spinner *ngIf="showMainSpinner" [diameter]="75" [strokeWidth]="4" class="main-spinner centered"></mat-spinner>
<full-screen-status-banner *ngIf="showFullScreenStatusBanner"
                           [runNowPending]="runNowPending"
                           (runNowClicked)="runNow()"
                           updatesType="WebJourney"></full-screen-status-banner>
<ng-container *ngIf="journeyRules && journeyResults">
  <div class="no-rules" *ngIf="!journeyRules.webJourneyRules && !journeyResults.globalRuleResults.length">
    No journey rules have been assigned to this journey
    <div *ngIf="!isReadOnly">
      <button mat-flat-button color="primary" class="yellow-button" (click)="assignRules()">
        Assign Rules To This Journey
      </button>
      <span>(Rules assigned to this journey will only affect future runs)</span>
    </div>
  </div>

  <div class="rule-results" *ngIf="journeyResults.globalRuleResults.length; else noData">
    <h2>Journey Rules</h2>
    <rules-report [showEditButtons]="canEditRules && !isReadOnly"
                  [failed]="failedRules"
                  [notApplied]="notAppliedRules"
                  [passed]="passedRules"
                  (editRule)="onEditRule($event)"
                  (removeRule)="onRemoveRule($event)"
                  (setAsExpected)="onSetAsExpected($event)">
    </rules-report>
    <div *ngIf="!isReadOnly">
      <button mat-flat-button color="primary" class="yellow-button" (click)="assignRules()">
        <mat-icon>add</mat-icon>
        Add Rules
      </button>
    </div>
  </div>

</ng-container>

<ng-template #noData>
  <div class="no-data">
    No Data To Report
    <button mat-flat-button color="primary" class="yellow-button" (click)="assignRules()">
      <mat-icon>add_circle</mat-icon>
      Add Rules
    </button>
  </div>
</ng-template>
