import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ERuleResultType,
  IAuditRunSpecificRulePageRuleResultsItem,
  IRuleSummaryPagesTableState
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.models';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { IRuleSummaryPagination } from '@app/components/audit-reports/reports/rule-summary/rule-summary.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import {
  IAuditRunPageRuleResultsRow
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-pages/rule-summary-pages.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { mkConditionResultSegments } from './rule-summary-pages-specific.utils';
import { ActivatedRoute } from '@angular/router';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';
import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export interface IAuditRunSpecificRulePageRuleResultsRow extends IAuditRunSpecificRulePageRuleResultsItem {
  statusCodeClass: string;
  loadTimeClass: string;
  conditionResultSegments: IConditionResultSegment[];
}

export interface IConditionResultSegment {
  value: string;
  cssClass: 'no-highlight' | 'highlight-1' | 'highlight-2'
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-summary-pages-specific',
  templateUrl: './rule-summary-pages-specific.component.html',
  styleUrls: ['./rule-summary-pages-specific.component.scss']
})
export class RuleSummaryPagesSpecificComponent implements OnInit, AfterViewInit, OnChanges {

  auditId: number;
  runId: number;

  private onDestroy$: Subject<void> = new Subject();
  readonly TableColumn = CommonReportsPagesTableColumns;

  @Input() inputData: IAuditRunSpecificRulePageRuleResultsItem[];
  @Input() tableState: IRuleSummaryPagesTableState;
  @Input() spinnerState: EFilterSpinnerState;
  @Input() pagesTableSettings: ISwitchableMenuItems;
  @Input() ruleResultType: ERuleResultType;
  @Output() tableStateChanged = new EventEmitter<IRuleSummaryPagesTableState>();
  @Output() pageChanged = new EventEmitter<IRuleSummaryPagination>();

  tableDataSource = new MatTableDataSource<IAuditRunSpecificRulePageRuleResultsRow>();
  displayedColumns$ = this.tableService.displayedColumns$;

  @ViewChild(MatSort, {static: true}) tableSort: MatSort;
  @ViewChild(MatPaginator, {static: true}) tablePaginator: MatPaginator;
  @ViewChild('tableScrollTo', {read: ElementRef}) tableScrollTo: ElementRef;

  pageIdOpenInPageDetails: string;
  constructor(
    private auditReportService: AuditReportService,
    private scrollService: AuditReportScrollService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private activatedRoute: ActivatedRoute,
    private tableService: ResizeableTableService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;
    });
  }

  ngOnInit(): void {
    this.handleInputData(this.inputData);
    this.pageDetailsDrawerService.pageDrawerClosed$.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handlePageDetailsClosed());
  }

  ngAfterViewInit(): void {
    this.tableDataSource.sort = this.tableSort;
    this.tableSort.sortChange.subscribe((sort: Sort) => {
      // reset the paginator after sorting
      this.tableState.pagination.page = 0;
      this.tablePaginator.firstPage();

      this.tableState.sort = sort.direction
        ? {sortBy: sort.active as CommonReportsPagesTableColumns, sortDesc: sort.direction === 'desc'}
        : undefined;

      this.tableStateChanged.emit(this.tableState);
    });

    this.tablePaginator.page.subscribe((page: PageEvent) => {
      // we only want to trigger tableStateChanged if page has been changed within this component
      // (i.e. user clicked next or prev. page), so data could be reloaded in rule-summary.component.ts
      const pageChangedManully = this.tableState.pagination.page !== page.pageIndex;
      // sync table state pagination with table paginator
      this.tableState.pagination = {
        size: page.pageSize,
        page: page.pageIndex
      };
      if (pageChangedManully) {
        this.tableStateChanged.emit(this.tableState);
      }
      // scrolls to the first row when the paginator fires
      this.scrollService.scrollByElement(this.tableScrollTo.nativeElement);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputData']) {
      this.handleInputData(changes['inputData'].currentValue);
    }
  }

  private handleInputData(inputData: IAuditRunSpecificRulePageRuleResultsItem[]) {
    if (inputData && Array.isArray(inputData)) {
      // sync table paginator with table state pagination
      // this allows to control pagination from outside
      // for example when rule-summary.component.ts needs to reset pagination to 1st page
      // after reloading data with sorting or filtering applied)
      this.tablePaginator.pageIndex = this.tableState.pagination.page;
      this.tableDataSource.data = inputData.map(i => ({
        ...i,
        pageLoadTime: i.pageLoadTime / 1000,
        loadTimeClass: this.auditReportService.getLoadTimeClassForMs(i.pageLoadTime),
        finalPageStatusCode: i.finalPageStatusCode,
        statusCodeClass: this.auditReportService.getStatusCodeClass(i.finalPageStatusCode),
        conditionResultSegments: mkConditionResultSegments(i.conditionResult, this.ruleResultType)
      }));
    }
  }

  openPageDetails(row: IAuditRunPageRuleResultsRow) {
    this.pageIdOpenInPageDetails = row.pageId;
    this.pageDetailsDrawerService.openPageDetails({
      id: row.pageId,
      url: row.pageUrl
    }, this.auditId, this.runId);
  }

  handlePageDetailsClosed() {
    this.pageIdOpenInPageDetails = null;
  }

  destroy() {
    this.onDestroy$.next();
  }
}
