<op-modal (onClose)="close()" (keydown)="handleKeydown($event)">
  <ng-template #headerTemplate>
    <op-modal-header title=""
                     (onClose)="close()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>

    <div class="export-body">

      <div *ngIf="isExporting">
        <div class="header-text">Generating your export</div>
        <div class="sub-header-text">
          When the export completes, we will send an email to {{message}}. 
          The email will come from support&#64;observepoint.com with a download link.
        </div>
      </div>

      <div class="export-container" *ngIf="isExporting">
        <div class="spinner-wrapper">
          <mat-progress-spinner mode="indeterminate" [diameter]="50" [strokeWidth]="10"></mat-progress-spinner>
        </div>
        <div class="text-wrapper">
          <div class="info-wrapper">
            <div class="fixed-width">Time Elapsed:</div>
            <div class="update-text">{{timeElapsed}}</div>
          </div>
          <div class="info-wrapper">
            <div class="fixed-width">Export Status:</div>
            <div class="update-text">{{exportStatus}}</div>
          </div>
        </div>
      </div>

      <div class="completed-wrapper">
        <div class="completed-text" *ngIf="!isExporting">{{message}}</div>
      </div>
      <div class="success-container" *ngIf="!isExporting && exportDownloadLink">
        If your export did not download, you can <a href="{{exportDownloadLink}}" target="_blank">re-download the export</a>.
      </div>

      <div class="export-options-text">
        View all exports in the&nbsp;
        <a [routerLink]="['../export-center']" (click)="closeDialogOnLeftClick($event)">Export Center</a>.
        <div class="note-text" *ngIf="isExporting">You can dismiss this dialog, and your export will continue in the background.</div>
      </div>

    </div>
  </ng-template>
  <ng-template #footerTemplate></ng-template>
</op-modal>
