import _isEqual from 'lodash-es/isEqual';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, HostBinding, ViewChild } from '@angular/core';
import { ComponentChanges } from '@app/models/commons';
import { IChipConfig } from '@app/components/shared/components/op-chip-manager/op-chip-manager.models';
import {
  IAuditReportFilter, IAuditReportInvertableFilter
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { AlertMetricType, EAlertMetricChangeType } from '../alert-logic/alert-logic.enums';
import { IAlertOperator } from '../alert-logic/alert-logic.models';
import { AlertService } from '../alert.service';
import { AlertOperatorGroups } from '../alert-logic/alert-logic.constants';
import { IAlertLogicDetailsConfig } from './alert-logic-details.models';
import { MatMenuTrigger } from '@angular/material/menu';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-logic-details',
  templateUrl: './alert-logic-details.component.html',
  styleUrls: ['./alert-logic-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertLogicDetailsComponent implements OnChanges {

  @Input() metricType?: AlertMetricType;
  @Input() operator?: IAlertOperator;
  @Input() targetValue?: number;
  @Input() showTooltip: boolean = false;
  @Input() filters?: (IAuditReportFilter | IAuditReportInvertableFilter | IOpFilterBarV2Filter<any>)[];
  @Input() showFiltersList = false;

  @HostBinding('class.clickable') @Input() clickable: boolean;
  @Output() clicked = new EventEmitter<void>();

  // exists if showFiltersList is true
  @ViewChild(MatMenuTrigger) matMenuTrigger?: MatMenuTrigger;

  config: IAlertLogicDetailsConfig;
  chipListConfigs: IChipConfig = { chipDisplayProperty: 'display', maxWidth: 200 };

  constructor(private alertService: AlertService) { }

  ngOnChanges({ metricType, operator, targetValue, filters }: ComponentChanges<AlertLogicDetailsComponent>) {
    if (metricType?.currentValue !== metricType?.previousValue ||
        operator?.currentValue !== operator?.previousValue ||
        targetValue?.currentValue !== targetValue?.previousValue ||
        !_isEqual(filters?.currentValue, filters?.previousValue)) {

      this.config = this.getConfig();

    }
  }

  // Builds filter info placeholder interactively (based on the filled by user data)
  private getConfig(): IAlertLogicDetailsConfig {
    if (!this.metricType) return null;

    const config = this.getEmptyConfig();

    // if filters are set - add them to the placeholder
    if (this.filters) {
      config.filtersCount = this.filters.length;
    }

    // if metric type is set - add it to the placeholder
    const reportConfig = AlertUtils.getReportConfigByMetricType(this.metricType);
    const metricConfig = AlertUtils.getMetricConfigByMetricType(this.metricType);

    config.reportName = reportConfig.name;
    config.metricName = metricConfig.name;
    config.postString = metricConfig.postString || '';

    if (!this.operator) return config;

    // if operator is set - add it to the placeholder
    const operator = AlertOperatorGroups
      .flatMap(group => group.operators)
      .find(operator => operator === this.operator);
    config.operator = operator;

    if (!(typeof this.targetValue === 'number')) return config;

    // if targetValue is set - add it to the placeholder
    config.value = this.targetValue;

    if (!metricConfig.unit) return config;

    // if unit is set - add it to the placeholder
    const isRelativeChange = AlertUtils.isRelativeChange(operator?.changeType);
    config.unit = isRelativeChange ? '' : metricConfig.unit;

    // return final placeholder, that includes all filtering details
    return config;
  }

  private getEmptyConfig(): IAlertLogicDetailsConfig {
    return {
      reportName: '',
      metricName: '',
      operator: null,
      value: null,
      unit: '',
      filtersCount: null,
      postString: '',
    };
  }

  getTooltip(): string|null {
    const { reportName, metricName, operator, value, unit } = this.config;
    return this.showTooltip ? `${reportName} - ${metricName} is ${operator?.title?.toLowerCase()} ${value} ${unit}` : null;
  }

  getFilterLabel(filter: IAuditReportFilter | IAuditReportInvertableFilter): string {
    if (this.isInvertable(filter)) {
      return filter.state ? filter.toggleOption1.display : filter.toggleOption2.display;
    }
    return '';
  }

  private isInvertable(test: any): test is IAuditReportInvertableFilter {
    return test.state !== undefined;
  }

}
