<component-template componentName="Accordion">
  <component-library-section sectionName="Overview" selector="<op-accordion>">
    <p>This is a wrapper for <code>mat-expansion-panel</code> that just adds some custom styles.</p>

    <div class="accordion-example">
      <op-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              This is op-accordion
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="accordion-content">
            <p>This is some content for the accordion</p>
          </div>
        </mat-expansion-panel>
      </op-accordion>
    </div>

    <div class="accordion-example">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            This is a plain mat-expansion-panel
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="accordion-content">
          <p>This is some content for the accordion</p>
        </div>
      </mat-expansion-panel>
    </div>

    <p><b>Note:</b> This is a (poor) wrapper around mat-expansion panel. Rather than including <code>mat-expansion-panel</code> in the wrapper, the implementation requires the consumer to wrap <code>mat-expansion-panel</code> with <code>op-accordion</code> to apply some styles.</p>
  </component-library-section>
</component-template>