<ng-container *ngIf="dataSources.length">
  <alert-preview-data-source-selector [dataSources]="dataSources"
    [selectedDataSource]="selectedDataSource"
    (dataSourceSelected)="dataSourceSelected($event)">
  </alert-preview-data-source-selector>

  <div class="title">TRIGGERED ALERT HISTORY</div>

  <alert-preview-notification [dataSource]="selectedDataSource"
  [bars]="bars"
  [lastFailedRun]="lastFailedRun">
  </alert-preview-notification>

  <alert-logic-details [metricType]="triggerFormData?.metricType"
                      [operator]="triggerFormData?.operator"
                      [targetValue]="triggerFormData?.targetValue"
                      [showFiltersList]="true"
                      [filters]="filters">
  </alert-logic-details>

  <alert-preview-chart [class.dimmed]="loading"
  [data]="bars"
  [uniqueIdentifier]="uniqueIdentifier"
  [showYAxis]="false">
  </alert-preview-chart>

  <mat-spinner [diameter]="75" [strokeWidth]="8" *ngIf="loading"></mat-spinner>
</ng-container>
<ng-container *ngIf="!dataSources.length">
  <div class="no-data-sources">
    <div class="title">No data sources have been selected.</div>
    <div class="message">
      Please select at least one data source (step 3) to preview the alert history.
    </div>
  </div>
</ng-container>
