<ul class="grid">
  <li class="grid-row grid-header-row">
    <div class="left">USERS LOGGED IN LAST 30 DAYS</div>
    <div class="right">USER AUDIT LIMIT</div>
  </li>
  <li class="grid-row" *ngFor="let user of loggedIn30DaysUsers">
    <div class="left">
      <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
      <span class="email">({{ user.email }})</span>
      <mat-icon class="actions-icon"
                [matMenuTriggerFor]="firstThreeDots"
                [matMenuTriggerData]="{user}">more_vert
      </mat-icon>
    </div>
    <div class="right">
      {{ user.maxPagesPerAudit ? (user.maxPagesPerAudit | number) : 'unlimited' }}
      <mat-icon class="actions-icon"
                [matMenuTriggerFor]="secondThreeDots"
                [matMenuTriggerData]="{user}">more_vert
      </mat-icon>
    </div>
  </li>
</ul>

<ul *ngIf="allOtherUsers?.length" class="grid">
  <li class="grid-row grid-header-row">
    <div class="left">ALL OTHER USERS</div>
    <div class="right">USER AUDIT LIMIT</div>
  </li>
  <li class="grid-row" *ngFor="let user of allOtherUsers">
    <div class="left">
      <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
      <span class="email">({{ user.email }})</span>
      <mat-icon class="actions-icon"
                [matMenuTriggerFor]="firstThreeDots"
                [matMenuTriggerData]="{user}">more_vert
      </mat-icon>
    </div>
    <div class="right">
      {{ user.maxPagesPerAudit ? (user.maxPagesPerAudit | number) : 'unlimited' }}
      <mat-icon class="actions-icon"
                [matMenuTriggerFor]="secondThreeDots"
                [matMenuTriggerData]="{user}">more_vert
      </mat-icon>
    </div>
  </li>
</ul>

<footer class="footer">
  <a class="manage-link"
     [routerLink]="manageUsersUrl">Manage Users
    <mat-icon>arrow_forward</mat-icon>
  </a>
</footer>
<mat-menu #firstThreeDots="matMenu"
          backdropClass="no-filter">
  <ng-template
    matMenuContent let-user="user">
    <button mat-menu-item
            (click)="applyFilter(user)">
      Filter Report by This User
    </button>
  </ng-template>
</mat-menu>

<mat-menu #secondThreeDots="matMenu"
          backdropClass="no-filter">
  <ng-template
    matMenuContent let-user="user">
    <button mat-menu-item
            (click)="editAuditLimit(user)">
      Edit Users Audit Limit
    </button>
  </ng-template>
</mat-menu>

<div class="print-view">
  <ul class="users-list">
    <li class="user-item" *ngFor="let user of loggedIn30DaysUsers">
      <div class="name">{{ user.firstName }} {{ user.lastName }}</div>
      <div class="email">({{ user.email }})</div>
    </li>
  </ul>
</div>
