import { RouteBuilder } from '../shared/services/route-builder.service';

export const BulkOperationsStateNames = {
  base: 'bulk_operations',
  manageFolders: 'bulk_operations.manage-folders',
  manageSubfolders: 'bulk_operations.manage-subfolders',
  manageLabels: 'bulk_operations.manage-labels'
};

export const BulkOperationsPaths = {
  base: 'bulk-operations',
  manageFolders: 'manage-folders',
  manageSubfolers: 'manage-subfolders',
  manageLabels: 'manage-labels',
};

export const BulkOperationsDeleteMessaging = {
  'Success': 0,
  'Cancel': 1,
  'MoveFailure': 2,
  'DeleteFailure': 3,
  'NoItemsToMove': 4,
  'MoveToSameFolder': 5,
  'Unknown': 6,
};

export const BulkOperationsUrlBuilders = {
  base: () => RouteBuilder.buildUrl([BulkOperationsPaths.base]),
  manageFolders: () => RouteBuilder.buildUrl([BulkOperationsPaths.base, BulkOperationsPaths.manageFolders]),
  manageSubfolders: () => RouteBuilder.buildUrl([BulkOperationsPaths.base, BulkOperationsPaths.manageSubfolers]),
  manageLabels: () => RouteBuilder.buildUrl([BulkOperationsPaths.base, BulkOperationsPaths.manageLabels])
};

export const MAX_DELETE_AMT = 5;
