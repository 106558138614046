<div class="component-container">
  <div class="title">
    <span class="title-text">Privacy Validation</span>
    <filter-spinner [state]="loadingState"></filter-spinner>
  </div>

  <applied-consent-categories
    [runId]="runId"
    [auditId]="auditId"
    [categories]="ccsAssignedToRun"
    [categoriesType]="categoriesType"
    [auditConsentCategories]="auditConsentCategories"
    [isLoadedAuditConsentCategories]="isLoadedAuditConsentCategories"
    [isLoadedRunConsentCategories]="isLoadedRunConsentCategories"
    class="categories-wrap"
  ></applied-consent-categories>

  <div *ngIf="ccsAssignedToRun.length" class="widgets">
    <widgets-cookies [auditId]="auditId" [runId]="runId" [apiFilters]="apiFilters"></widgets-cookies>
    <widgets-tags [auditId]="auditId" [runId]="runId" [apiFilters]="apiFilters"></widgets-tags>
    <widgets-requests [auditId]="auditId" [runId]="runId" [apiFilters]="apiFilters"></widgets-requests>
  </div>
</div>
