<div class="bulk-action-bar" [class.multi-select-mode]="mCardSvc.isAnythingSelected(selectedCards)">
  <div class="left">
    <button class="collapse-expand-all-btn" (click)="toggleCardGroups.emit()" *ngIf="groupBy !== 'ungrouped'"
      matTooltip="{{ allCardsExpanded ? 'Collapse' : 'Expand' }} all folders & sub-folders" matTooltipPosition="above"
      op-selector="collapse-expand-all-btn">
      <div class="btn-border">
        <mat-icon class="expand-icon" [class.rotated]="!allCardsExpanded">expand_more</mat-icon>
      </div>
    </button>
    <mat-checkbox #selectAllCheckbox class="select-all"
      [checked]="mCardSvc.selectAllState"
      [indeterminate]="mCardSvc.selectAllIndeterminateState"
      (click)="toggleSelectAllItems()">
    </mat-checkbox>
    <button class="selected-cards-pop-up-btn" *ngIf="selectedCards.length" mdePopoverTriggerOn="click"
    [mdePopoverTriggerFor]="selectedCardsPopover">
      {{ selectedCards.length }} Selected <mat-icon class="num-selected-icon">expand_more</mat-icon>
      <div class="scan-count">
        <ng-container *ngIf="filteredCards !== totalCards">{{ filteredCards }} of</ng-container>
        {{ totalCards }} Total Data Sources
      </div>
    </button>
    <mde-popover #selectedCardsPopover="mdePopover"
               [mdePopoverOverlapTrigger]="false"
               [mdePopoverCloseOnClick]="false"
               class="mat-elevation-z5">
      <div class="selected-cards-popover">
        <div *ngFor="let item of selectedCards" class="selected-card">
          <mat-checkbox color="primary" checked="true" (change)="deselectItem(item)"></mat-checkbox>
          <div class="item-info">
            <div class="name">{{ item.name }}</div>
            <div class="folder-domain">
              {{ subFolderToFolderMap[item.domainId ? item.domainId : item.appId]?.folderName }} /
              {{ subFolderToFolderMap[item.domainId ? item.domainId : item.appId]?.subFolderName }}
            </div>
          </div>
        </div>
      </div>
    </mde-popover>
    <div class="scan-count" *ngIf="!selectedCards.length">
      <span *ngIf="filteredCards === totalCards">
        {{ filteredCards }} Data Sources
      </span>
      <span *ngIf="filteredCards !== totalCards">
        {{ filteredCards }} of {{ totalCards }} Total Data Sources
      </span>
    </div>
    <button class="select-create-menu-btn" [matMenuTriggerFor]="menu" *ngIf="!selectedCards.length">
      <mat-icon class="menu-btn">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="bulk-action-menu">
      <div class="menu-info-msg">
        <mat-icon class="info-icon">info_outline</mat-icon>
        Select data sources for bulk actions
      </div>
      <button mat-menu-item [disabled]="!filteredCards || !totalCards" (click)="selectAllCards.emit()" [attr.op-selector]="'manage-cards-select-all'" >Select All Data Sources</button>
      <ng-container *ngIf="!isReadOnly">
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="newFolder()"[attr.op-selector]="'manage-cards-create-new-folder'" >Create New Folder</button>
        <button mat-menu-item (click)="newDomain()"[attr.op-selector]="'manage-cards-create-new-domain'" >Create New Sub-folder</button>
      </ng-container>
    </mat-menu>
  </div>
  <div class="middle" *ngIf="mCardSvc.isAnythingSelected(selectedCards)">
    
    <!--Scheduling Menu-->
    <div id="op-scheduling">
      <button [matMenuTriggerFor]="schedulingMenuMat" class="bulk-action-btn">
        <mat-icon class="bulk-action-icon run-now-icon">sync</mat-icon>
          <span class="btn-text">Scheduling</span>
        <mat-icon class="num-selected-icon" iconPositionEnd>expand_more</mat-icon>
      </button>
      <mat-menu #schedulingMenuMat="matMenu" class="bulk-action-run-menu">
        <button mat-menu-item 
          [disabled]="isReadOnly ? true : null" 
          (click)="runNowConfirmation()" [attr.op-selector]="'manage-cards-bulk-run-now'">
          <mat-icon class="bulk-action-icon run-audit-icon">sync</mat-icon>
          <span class="btn-text">Run Now</span>
        </button>
        <button mat-menu-item 
          [disabled]="isReadOnly ? true : null" 
          (click)="pauseSelectedRunsConfirmation()" [attr.op-selector]="'manage-cards-bulk-pause'">
          <mat-icon class="bulk-action-icon pause-audit-icon" svgIcon="pause_icon"></mat-icon>
          <span class="btn-text">Pause Scheduled Runs</span>
        </button>
        <button mat-menu-item 
          [disabled]="isReadOnly ? true : null" 
          (click)="resumeSelectedRunsConfirmation()" [attr.op-selector]="'manage-cards-bulk-resume'">
          <mat-icon class="bulk-action-icon resume-audit-icon">event_repeat</mat-icon>
          <span class="btn-text">Resume Scheduled Runs</span>
        </button>
        <button mat-menu-item
          [disabled]="isReadOnly"
          [class.disabled]="isReadOnly"
          class="bulk-action-btn add-label"
          (click)="changeRunFrequencyConfirmation()"
          matTooltip="Change the run frequencies"
          matTooltipPosition="above"
          [matTooltipDisabled]="!showBtnTooltips"
          [attr.op-selector]="'manage-cards-bulk-add-data-layers'">
          <mat-icon class="bulk-action-icon pause-audit-icon change-frequency-svg" svgIcon="change-freq"></mat-icon>
          <span class="btn-text">Change Run Frequency</span>
        </button>
        <button mat-menu-item 
          [disabled]="isReadOnly ? true : null" 
          (click)="discardSelectedRunsConfirmation()"
          [attr.op-selector]="'manage-cards-bulk-discard'">
          <mat-icon class="bulk-action-icon">stop_circle</mat-icon>
          <span class="btn-text">Stop & Discard Runs</span>
        </button>
      </mat-menu>
    </div>

    <!--Setup Menu-->
    <div id="op-setup">
      <button [matMenuTriggerFor]="setupMenuMat" class="bulk-action-btn">
        <mat-icon class="bulk-action-icon run-now-icon">tune</mat-icon>
          <span class="btn-text">Setup</span>
        <mat-icon class="num-selected-icon" iconPositionEnd>expand_more</mat-icon>
      </button>
      <mat-menu #setupMenuMat="matMenu" class="bulk-action-run-menu">
        <button mat-menu-item
          [disabled]="isReadOnly" 
          [class.disabled]="isReadOnly" 
          class="bulk-action-btn add-label" 
          (click)="setAuditScanLimit()" 
          matTooltip="Set Audit Scan Limit" 
          matTooltipPosition="above" 
          [matTooltipDisabled]="!showBtnTooltips"
          [attr.op-selector]="'manage-cards-bulk-audit-scan-limit'">
          <mat-icon class="bulk-action-icon pause-audit-icon" svgIcon="scan_limit"></mat-icon>
          <span class="btn-text">Page Limit</span>
        </button>
        <button mat-menu-item
          [disabled]="isReadOnly" 
          [class.disabled]="isReadOnly"
          class="bulk-action-btn add-label"
          (click)="addDataLayerConfirmation()" 
          matTooltip="Add Data Layers" 
          matTooltipPosition="above" 
          [matTooltipDisabled]="!showBtnTooltips"
          [attr.op-selector]="'manage-cards-bulk-add-data-layers'">
          <mat-icon class="bulk-action-icon pause-audit-icon" svgIcon="datalayer_icon">
          </mat-icon>
          <span class="btn-text">Add Data Layers</span>
        </button>
        <button mat-menu-item
          [disabled]="isReadOnly" 
          [class.disabled]="isReadOnly"
          class="bulk-action-btn add-label"
          (click)="addFileSubstitutionsConfirmation()" 
          matTooltip="Add File Substitutions" 
          matTooltipPosition="above" 
          [matTooltipDisabled]="!showBtnTooltips"
          [attr.op-selector]="'manage-cards-bulk-add-data-layers'">
          <mat-icon class="bulk-action-icon">alt_route</mat-icon>
          <span class="btn-text">Add File Substitutions</span>
        </button>
        <button mat-menu-item 
          [matMenuTriggerFor]="journeySpecific" 
          class="bulk-action-btn add-label"
          matTooltip="Web Journey Specific Bulk Tasks" 
          matTooltipPosition="above" 
          [matTooltipDisabled]="!showBtnTooltips"
          [attr.op-selector]="'manage-cards-bulk-web-journey-specific'">
          <mat-icon class="bulk-action-icon">map</mat-icon>
          <span class="btn-text">Journey Support</span>
        </button>
        <mat-menu #journeySpecific="matMenu" class="bulk-action-run-menu bulk-action-journey-specific-menu">
          <button mat-menu-item 
            class="bulk-action-btn add-label"
            (click)="requestJourneyFixConfirmation()" 
            matTooltip="Request journey fix" 
            matTooltipPosition="above" 
            [matTooltipDisabled]="!showBtnTooltips"
            [attr.op-selector]="'manage-cards-bulk-request-journey-fix'">
            <mat-icon class="bulk-action-icon">flag</mat-icon>
            <span class="btn-text">Request Journey Fix ({{remainingFixesCount === UNLIMITED ? UNLIMITED_TEXT : remainingFixesCount + ' remaining'}})</span>
          </button>
          <button mat-menu-item 
            class="bulk-action-btn add-label"
            (click)="monitorSelectedRunsConfirmation()" 
            matTooltip="Start monitoring one or more journeys" 
            matTooltipPosition="above" 
            [matTooltipDisabled]="!showBtnTooltips"
            [attr.op-selector]="'manage-cards-bulk-start-monitoring-journey'">
            <mat-icon class="bulk-action-icon bulk-action-visibility">visibility</mat-icon>
            <span class="btn-text">Start Monitoring Journey(s)</span>
          </button>
          <button mat-menu-item 
            class="bulk-action-btn add-label"
            (click)="unmonitorSelectedRunsConfirmation()" 
            matTooltip="Stop monitoring journey" 
            matTooltipPosition="above" 
            [matTooltipDisabled]="!showBtnTooltips"
            [attr.op-selector]="'manage-cards-bulk-stop-monitoring-journey'">
            <mat-icon class="bulk-action-icon bulk-action-visibility">visibility_off</mat-icon>
            <span class="btn-text">Stop Monitoring Journey(s)</span>
          </button>
        </mat-menu>
      </mat-menu>
    </div>

    <!--Standards Menu-->
    <div id="op-standards">
      <button [matMenuTriggerFor]="standardsMenuMat" class="bulk-action-btn">
        <mat-icon class="bulk-action-icon">architecture</mat-icon>
          <span class="btn-text">Standards</span>
        <mat-icon class="num-selected-icon" iconPositionEnd>expand_more</mat-icon>
      </button>
      <mat-menu #standardsMenuMat="matMenu" class="bulk-action-run-menu">
        <button mat-menu-item
          [disabled]="isReadOnly" 
          [class.disabled]="isReadOnly" 
          (click)="assignRulesConfirmation()" 
          matTooltip="Assign Rule" 
          matTooltipPosition="above" 
          [matTooltipDisabled]="!showBtnTooltips">
          <mat-icon class="bulk-action-icon">note_alt</mat-icon>
          <span class="btn-text">Assign Rule</span>
        </button>
        <button mat-menu-item
          [disabled]="isReadOnly" 
          [class.disabled]="isReadOnly" 
          (click)="reprocessConsentCategoriesConfirmation()" 
          matTooltip="Reprocess Consent Categories & Alerts" 
          matTooltipPosition="above" 
          [matTooltipDisabled]="!showBtnTooltips">
          <mat-icon class="bulk-action-icon">refresh</mat-icon>
          <span class="btn-text">Reprocess Consent Categories</span>
        </button>
        <button mat-menu-item
          [disabled]="isReadOnly" 
          [class.disabled]="isReadOnly" 
          (click)="reprocessRulesConfirmation()" 
          matTooltip="Reprocess Tags, Variable Rules & Alerts" 
          matTooltipPosition="above" 
          [matTooltipDisabled]="!showBtnTooltips">
          <mat-icon class="bulk-action-icon">refresh</mat-icon>
          <span class="btn-text">Reprocess Rules</span>
        </button>
      </mat-menu>
    </div>
  
    <!--Organization Menu-->
    <div id="op-organization">
      <button [matMenuTriggerFor]="organizationMenuMat" class="bulk-action-btn">
        <mat-icon class="bulk-action-icon">rule_folder</mat-icon>
          <span class="btn-text">Organization</span>
        <mat-icon class="num-selected-icon" iconPositionEnd>expand_more</mat-icon>
      </button>
      <mat-menu #organizationMenuMat="matMenu" class="bulk-action-run-menu">
        <button mat-menu-item
          [disabled]="isReadOnly" 
          [class.disabled]="isReadOnly" 
          (click)="addLabel()" 
          matTooltip="Add Label" 
          matTooltipPosition="above" 
          [matTooltipDisabled]="!showBtnTooltips">
          <mat-icon class="bulk-action-icon add-label-icon">new_label</mat-icon>
          <span class="btn-text">Add Label</span>
        </button>
        <button mat-menu-item
            [class.disabled]="isReadOnly ? true : null"
            [disabled]="isReadOnly"
            (click)="removeLabelConfirmation()"
            matTooltip="Disassociate labels with selected items"
            [matTooltipDisabled]="!showBtnTooltips"
            matTooltipPosition="above">
          <mat-icon class="bulk-action-icon">label_outline</mat-icon>
          <span class="btn-text">Remove Associated Labels</span>
        </button>
        <button mat-menu-item
            [class.disabled]="isReadOnly ? true : null"
            [disabled]="isReadOnly"
            (click)="moveToFolderConfirmation()"
            matTooltip="Move items to a Folder/Sub-folder"
            [matTooltipDisabled]="!showBtnTooltips"
            matTooltipPosition="above">
          <mat-icon class="bulk-action-icon move-to-domain-icon">compare_arrows</mat-icon>
          <span class="btn-text">Move to Folder/Sub-folder</span>
        </button>
        <button mat-menu-item 
          [matMenuTriggerFor]="deleteSpecific" 
          class="bulk-action-btn add-label"
          matTooltip="Delete Specific Items In Bulk" 
          matTooltipPosition="above" 
          [matTooltipDisabled]="!showBtnTooltips"
          [attr.op-selector]="'manage-cards-bulk-web-delete-specific'">
          <mat-icon class="bulk-action-icon delete-icon">delete_outline</mat-icon>
          <span class="btn-text">Delete Options</span>
        </button>
        <mat-menu #deleteSpecific="matMenu" class="bulk-action-run-menu">
          <button mat-menu-item
            [disabled]="isReadOnly" 
            [class.disabled]="isReadOnly" 
            (click)="deleteCardsConfirmation()" 
            matTooltip="Delete journeys and audits" matTooltipPosition="above" 
            [matTooltipDisabled]="!showBtnTooltips">
            <mat-icon class="bulk-action-icon delete-icon">delete_outline</mat-icon>
            <span class="btn-text">Delete Audits/Journeys</span>
          </button>
          <button mat-menu-item
            [class.disabled]="!mCardSvc.areAnyFoldersSelected() ? true : null"
            [disabled]="!mCardSvc.areAnyFoldersSelected()"
            (click)="deleteFoldersConfirmation()"
            matTooltip="Delete selected folders, subfolders with selected items"
            [matTooltipDisabled]="!showBtnTooltips"
            matTooltipPosition="above">
            <mat-icon class="bulk-action-icon delete-icon">delete_outline</mat-icon>
            <span class="btn-text">Delete Folders</span>
          </button>
          <button mat-menu-item
            [disabled]="!mCardSvc.areAnySubfoldersSelected()" 
            [class.disabled]="mCardSvc.areAnySubfoldersSelected()" 
            (click)="deleteSubfoldersConfirmation()"
            matTooltip="Delete subfolders with selected items" 
            matTooltipPosition="above" 
            [matTooltipDisabled]="!showBtnTooltips">
            <mat-icon class="bulk-action-icon delete-icon">delete_outline</mat-icon>
            <span class="btn-text">Delete Sub-folders</span>
          </button>
          <button mat-menu-item
            [disabled]="isReadOnly" 
            [class.disabled]="isReadOnly" 
            routerLink="/bulk-operations/manage-labels"
            matTooltip="Delete labels" 
            matTooltipPosition="above" 
            [matTooltipDisabled]="!showBtnTooltips">
            <mat-icon class="bulk-action-icon delete-icon">delete_outline</mat-icon>
            <span class="btn-text">Delete Labels</span>
          </button>
        </mat-menu>
      </mat-menu>
    </div>
  </div>
  <div class="right" *ngIf="mCardSvc.isAnythingSelected(selectedCards)">
    <button class="close-multi-select-btn" (click)="closeBulkActionBar()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
</div>
