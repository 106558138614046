<component-template componentName="Beta Label">
  <component-library-section sectionName="Overview" selector="<op-beta-label>">
    <p>This is the beta label component. It is a simple component that displays a label with a beta tag above the element that it is wrapping.</p>

    <div class="beta-label-example">
      <op-beta-label [showLabel]="true">
        The above is a beta label
      </op-beta-label>
    </div>
  </component-library-section>
</component-template>