<div class="page-redirect-container">
  <span class="left-side">
    <ng-content></ng-content>
  </span>
  <span class="title" [style.minWidth.px]="pageDetails.titleWidth">
    {{ pageDetails.title }}
  </span>
  <span class="status-code"
        [ngClass]="getStatusCodeClass()"
        matTooltipPosition="above"
        [matTooltip]="getStatusCodeTooltipText()">
      {{ pageDetails.statusCode }}
  </span>

  <span class="navigate-to-url"
        [matTooltip]="pageDetails.url"
        matTooltipPosition="above"
        matTooltipClass="page-redirect-url-tooltip"
  >
      {{ pageDetails.url }}
    </span>

  <mat-icon (click)="goToUrl()" matTooltip="View webpage in new window">open_in_new</mat-icon>

  <mat-icon *ngIf="!isAllowedCopyAll"
            matTooltip="Copy to clipboard"
            matTooltipPosition="above"
            ngxClipboard
            [cbContent]="pageDetails.url">
    content_copy
  </mat-icon>

  <actions-menu *ngIf="isAllowedCopyAll"
                matTooltip="Copy URL"
                [triggerMenuIcon]="'content_copy'"
                [showTriggerMenuIndicator]="true"
                [class.opened]="copyMenuOpened"
                (menuTriggered)="copyMenuOpened = $event"
  >
    <button mat-menu-item
            ngxClipboard
            [cbContent]="pageDetails.url"
    >
      Copy this URL
    </button>
    <button mat-menu-item (click)="copyAllRedirects.emit()">
      Copy All URLs in redirect chain
    </button>
  </actions-menu>
</div>
