import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { IButton } from '@app/models/commons';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EIngestorState, EIngestorValidationErrorCodes } from './har-file-ingestor.enums';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { StringUtils } from '@app/components/utilities/StringUtils';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  IHarJourneyRunCreateRequest,
  IS3PostUploadData,
  ISelectedFile,
  ISelectedFileWithError
} from '@app/components/live-connect/har-file-ingestor/har-file-ingestor.models';
import { HarFileIngestorService } from '@app/components/live-connect/har-file-ingestor/har-file-ingestor.service';
import { S3ErrorResponseParser } from '@app/components/utilities/s3ErrorResponseParser';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { AccountsService } from '@app/components/account/account.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'har-file-ingestor',
  templateUrl: './har-file-ingestor.component.html',
  styleUrls: ['./har-file-ingestor.component.scss']
})
export class HarFileIngestorComponent implements OnInit {

  private bytesInMB = 1048576;

  private allowedFileExtensions = ['.har', '.zip', '.gz', '.tar.gz'];

  title = 'Upload HAR files';
  rightFooterButtons: IButton[] = [{
    label: 'Upload & Save',
    action: this.upload.bind(this),
    primary: true,
    opSelector: 'upload-har',
  }, {
    label: 'Done',
    action: this.closeModal.bind(this),
    primary: true,
    hidden: true
  }];

  formGroup: UntypedFormGroup;

  hoveringDropzone: boolean = false;

  s3PostUploadData: IS3PostUploadData;
  selectedFiles: ISelectedFile[] = [];
  selectedFilesWithErrors: ISelectedFileWithError[] = [];
  areFilesSelected: boolean = false;
  noFilesSelectedValidationError: boolean = false;
  maxFilesSelected: boolean = false;

  state: EIngestorState = EIngestorState.CREATE;
  readonly ingestorState = EIngestorState;

  user: any;

  constructor(@Inject(MAT_DIALOG_DATA) private data: { deviceId: number },
              private dialogRef: MatDialogRef<any>,
              private accountsService: AccountsService,
              private authenticationService: AuthenticationService,
              private harIngestorService: HarFileIngestorService) {

    this.formGroup = new UntypedFormGroup({
      journeyRunName: new UntypedFormControl(''),
      webhookUrl: new UntypedFormControl('', [OPValidators.url, Validators.maxLength(2500)])
    });

  }

  ngOnInit(): void {
    this.harIngestorService.getPostUploadData(this.data.deviceId).subscribe(data => {
      this.s3PostUploadData = data;
    });
    this.accountsService.getUser().subscribe(user => {
      this.user = user;
    });
  }

  onFilesDropped(fileList: FileList): void {
    if (this.maxFilesSelected) {
      return;
    }

    const maxFiles = this.s3PostUploadData ? this.s3PostUploadData.maxFiles : 15;
    const maxFileSizeBytes = this.s3PostUploadData ? this.s3PostUploadData.maxSizeFor1FileBytes : 100 * this.bytesInMB;

    this.hoveringDropzone = false;
    this.noFilesSelectedValidationError = false;
    this.areFilesSelected = false;
    this.selectedFilesWithErrors = [];

    // Convert FileList to array
    const files = Array.from(fileList);
    const totalAmountOfFilesToBeSelected = this.selectedFiles.length + files.length;
    const filesToProcess = totalAmountOfFilesToBeSelected > maxFiles
      ? files.slice(0, maxFiles - this.selectedFiles.length)
      : files;

    filesToProcess.forEach(file => {
      // Directory check
      if (file.size === 0 && file.type === "") {
        this.selectedFilesWithErrors.push({
          fileName: file.name,
          errorCode: EIngestorValidationErrorCodes.DirectoriesNotSupported,
          errorMessage: `Directories are not supported.`
        });
        return;
      }

      if (!this.isValidFileType(file)) {
        this.selectedFilesWithErrors.push({
          fileName: file.name,
          errorCode: EIngestorValidationErrorCodes.InvalidFileType,
          errorMessage: `File type is not supported. Please select files with types ${this.allowedFileExtensions.join(', ')}`
        });
        return;
      }

      if (file.size > maxFileSizeBytes) {
        this.selectedFilesWithErrors.push({
          fileName: file.name,
          errorCode: EIngestorValidationErrorCodes.FileSize,
          errorMessage: `File size ${file.size} exceeds the limit of ${maxFileSizeBytes} bytes`
        });
      } else {
        this.selectedFiles.push({
          file: file,
          fileName: file.name,
          uploadProgress: 0,
          uploadDone: false,
          state: EIngestorState.CREATE,
          uploadErrorMessage: undefined,
          s3Key: undefined
        });
      }
    });

    this.areFilesSelected = this.selectedFiles.length > 0;
    this.maxFilesSelected = this.selectedFiles.length >= maxFiles;
  }

  onDeleteFile(selectedFileIndex: number): void {
    this.selectedFiles.splice(selectedFileIndex, 1);
    if (this.selectedFiles.length < 1) {
      this.areFilesSelected = false;
    }
    this.maxFilesSelected = false;
  }

  retryUpload(sf: ISelectedFile) {
    sf.uploadErrorMessage = undefined;
    sf.uploadDone = false;
    sf.uploadProgress = 0;
    this.createUploadToS3Observable(sf).subscribe();
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getFileLimitationsString() {
    if (this.s3PostUploadData) {
      const maxFileSize = this.s3PostUploadData.maxSizeFor1FileBytes / this.bytesInMB;
      const maxTotalSize = this.s3PostUploadData.maxSizeForAllFilesBytes / this.bytesInMB;
      return `(max file size ${maxFileSize} MB, max total size ${maxTotalSize} MB, max files ${this.s3PostUploadData.maxFiles})`;
    } else {
      return '';
    }
  }

  upload(): void {
    if (!this.selectedFiles || this.selectedFiles.length < 1) {
      this.noFilesSelectedValidationError = true;
      return;
    }
    if (this.formGroup.invalid) {
      return;
    }

    this.harIngestorService.getPostUploadData(this.data.deviceId).subscribe(data => {
      this.s3PostUploadData = data;
      this.uploadFiles();
    });
  }

  private isValidFileType(file: File): boolean {
    const fileName = file.name;
    const regexString = `(\\${this.allowedFileExtensions.join('|\\')})$`;
    const regEx = new RegExp(regexString, 'i');
    return regEx.exec(fileName) !== null;
  }

  private uploadFiles(): void {
    const uploadObservables = this.selectedFiles.map(sf => {
      return this.createUploadToS3Observable(sf);
    });

    this.state = EIngestorState.UPLOAD;
    this.rightFooterButtons[0].disabled = true;

    forkJoin(...uploadObservables).subscribe(event => {
      //no need to handle every single event
    }, error => {
      //error are handled in above
    }, () => {
      this.saveJourneyRun();
    });
  }

  private saveJourneyRun() {
    const requestBody: IHarJourneyRunCreateRequest = {
      journeyRunName: this.formGroup.get('journeyRunName').value || undefined,
      webhookUrl: this.formGroup.get('webhookUrl').value || undefined,
      harFiles: this.selectedFiles.map(sf => ({
        s3Key: sf.s3Key,
        name: sf.fileName
      }))
    };
    this.harIngestorService.saveJourneyRun(this.data.deviceId, requestBody).subscribe(resp => {
      this.state = EIngestorState.FINISH;
      this.rightFooterButtons[0].hidden = true;
      this.rightFooterButtons[1].hidden = false;
    }, error => {
      console.error(error);
      this.state = EIngestorState.ERROR;
      this.rightFooterButtons[0].hidden = true;
      this.rightFooterButtons[1].hidden = false;
    });
  }

  private createUploadToS3Observable(sf: ISelectedFile) {
    const s3Key = `${this.s3PostUploadData.objectKeyPrefix}${this.generateRandomFileId()}`;
    sf.s3Key = s3Key;
    const formDataEntries = {
      ...this.s3PostUploadData.formData,
      'key': s3Key,
      'file': sf.file
    };
    return this.harIngestorService.uploadFileToS3(
      formDataEntries,
      this.s3PostUploadData.uploadUrl
    ).pipe(
      tap(event => {
        this.handleEventMessage(event, sf);
      }, error => {
        console.error(error);
        sf.uploadErrorMessage = `Failed to upload. ${S3ErrorResponseParser.parseToString(error.error)}`;
      })
    );
  }

  generateRandomFileId(): string {
    return StringUtils.generateRandom(15);
  }

  private handleEventMessage(event: HttpEvent<any>, selectedFile: ISelectedFile): void {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        selectedFile.uploadProgress = Math.floor((event.loaded / event.total) * 100);
        break;
      case HttpEventType.Response:
        selectedFile.uploadDone = true;
        selectedFile.state = EIngestorState.FINISH;
        break;
    }
  }

}
