import { Component } from '@angular/core';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { SampleModalComponent } from './sample-modal/sample-modal.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-modal',
  templateUrl: './cl-modal.component.html',
  styleUrls: ['./cl-modal.component.scss']
})
export class ComponentLibraryModalComponent {

  constructor(private readonly modalService: OpModalService) {}

  openModal(): void {
    this.modalService.openModal(SampleModalComponent, {
      autoFocus: false
    });
  }
}
