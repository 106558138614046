import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardsTabComponent } from './standards-tab.component';
import { OpStandardsSelectorModule } from '../op-standards-selector/op-standards-selector.module';
import { OpTabsModule } from '../op-tabs/op-tabs.module';

@NgModule({
  declarations: [
    StandardsTabComponent
  ],
  imports: [
    CommonModule,
    OpStandardsSelectorModule,
    OpTabsModule
  ],
  exports: [
    StandardsTabComponent
  ],
  providers: [],
})
export class StandardsTabModule {}