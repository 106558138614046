/* eslint-disable @angular-eslint/no-input-rename */
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ElementRef, HostListener, ComponentRef, OnDestroy } from '@angular/core';
import { Directive, Input, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OpTooltipWithCustomTemplateComponent } from './op-tooltip-with-custom-template.component';

@Directive({
  selector: '[opTooltipWithCustomTemplate]'
})
export class OpTooltipWithCustomTemplateDirective implements OnDestroy {

  @Input('opTooltipTemplate') template: TemplateRef<ElementRef>;
  @Input('opTooltipPosition') position: Partial<ConnectedPosition> = {};

  private overlayRef: OverlayRef;
  private tooltipRef: ComponentRef<OpTooltipWithCustomTemplateComponent>;

  private destroySubject = new Subject<void>();

  private readonly defaultPosition: ConnectedPosition = {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top'
  };

  constructor(private elementRef: ElementRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder) { }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.tooltipRef = null;
    }

    this.destroySubject.next();
    this.destroySubject.complete();
  }

  @HostListener('mouseenter')
  show() {
    this.createOverlay();
    this.detach();
    this.attach();
  }

  @HostListener('mouseleave')
  hide() {
    this.tooltipRef.instance.close();
  }

  private createOverlay() {
    if (this.overlayRef) return this.overlayRef;

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{ ...this.defaultPosition, ...this.position }]);
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });
  }

  private attach() {
    this.tooltipRef = this.overlayRef.attach(new ComponentPortal(OpTooltipWithCustomTemplateComponent));
    this.tooltipRef.instance.contentTemplate = this.template;
    this.tooltipRef.instance.closed$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => this.detach());
  }

  private detach() {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }
    this.tooltipRef = null;
  }

}
