export enum EStandardsSelectorType {
  RULES,
  CONSENT_CATEGORIES,
  ALERTS
}

export enum EProductType {
  AUDIT = 'AUDIT',
  JOURNEY = 'JOURNEY'
}

export const standardsSelectorStrings = {
  [EStandardsSelectorType.RULES]: {
    singular: 'rule',
    plural: 'rules',
    createBtn: 'tag & variable rule'
  },
  [EStandardsSelectorType.CONSENT_CATEGORIES]: {
    singular: 'consent category',
    plural: 'consent categories',
    createBtn: 'consent category'
  },
  [EStandardsSelectorType.ALERTS]: {
    singular: 'alert',
    plural: 'alerts',
    createBtn: 'alert'
  }
}

export const productStrings = {
  [EProductType.AUDIT]: {
    singular: 'audit',
    plural: 'audits'
  },
  [EProductType.JOURNEY]: {
    singular: 'journey',
    plural: 'journeys'
  }
}