import { RuleSetupModule } from '@app/components/rules/rule-setup/rule-setup.module';
import { MaterialModule } from '@app/components/material/material.module';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/components/shared/shared.module';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { FilterListComponent } from '@app/components/audit/audit-editor/filter-list/filter-list.component';
import { CreatorNgModule } from '../creator/creatorModule';
import { AuditService } from './audit.service';
import { ActionsModule } from '../actions/actions.module';
import { OpModalModule } from '../shared/components/op-modal/op-modal.module';
import { OpFormModule } from '../shared/op-form.module';
import { OpSelectCreateModule } from '../shared/components/op-select-create/op-select-create.module';
import { LockOverlayComponent } from './lock-overlay/lock-overlay.component';
import { CommonModule } from '@angular/common';
import { TransformActionsService } from '../action-set-library/transform-actions.service';
import { EasyRuleEditService } from '@app/components/shared-services/easy-rule-edit-service';
import { PageDetailsReportService } from '../audit-reports/page-details/page-details.service';
import { WebJourneyActionDetailsService } from '../domains/webJourneys/webJourneyAPI/web-journey-action-details.service';
import { AuditSuccessSnackbarComponent } from './audit-success-snackbar/audit-success-snackbar.component';
import { AuditConsoleLogService } from '@app/components/shared/components/audit-console-log-table/audit-console-log.service';
import { OpTabsModule } from '@app/components/shared/components/op-tabs/op-tabs.module';
import { StandardsTabModule } from '../shared/components/standards-tab/standards-tab.module';
import { AuditSetupFormComponent } from '@app/components/audit/audit-setup-form/audit-setup-form.component';
import { RfmLibraryModule } from '@app/components/rfm-library/rfm-library.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  CreateFolderAndSubFolderFieldModule
} from '@app/components/folder-and-sub-folder-field/folder-and-sub-folder-field.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DataSourceEditorService } from '../data-source-editor/data-source-editor.service';
import {
  StandardsChangedSnackbarComponent
} from '@app/components/audit/standards-changed-snackbar/standards-changed-snackbar.component';
import { EasyBlockTagsModule } from './easy-block-tags/easy-block-tags.module';
import { OpLabelManagerModule } from '../shared/components/op-label-manager/op-label-manager.module';
import { MatSelectSearchModule } from 'mat-select-search';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MdePopoverModule } from '../popover';
import { UrlSourcesComponent } from './url-sources/url-sources/url-sources.component';
import { ClipboardModule as ngxClipboard }   from 'ngx-clipboard';
import { OpRecurrenceComponent } from '../shared/components/op-recurrence/op-recurrence.component';

@NgModule({
  declarations: [
    AuditEditorComponent,
    AuditSetupFormComponent,
    FilterListComponent,
    LockOverlayComponent,
    AuditSuccessSnackbarComponent,
    StandardsChangedSnackbarComponent,
    UrlSourcesComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatSelectModule,
    MatSelectSearchModule,
    NgxMatSelectSearchModule,
    SharedModule,
    OpModalModule,
    OpFormModule,
    OpSelectCreateModule,
    CreatorNgModule,
    ActionsModule,
    RuleSetupModule,
    OpTabsModule,
    StandardsTabModule,
    RfmLibraryModule,
    ScrollingModule,
    MdePopoverModule,
    CreateFolderAndSubFolderFieldModule,
    ClipboardModule,
    MatSnackBarModule,
    EasyBlockTagsModule,
    OpLabelManagerModule,
    ngxClipboard,
    OpRecurrenceComponent
  ],
  exports: [
    AuditSetupFormComponent,
    FilterListComponent,
    AuditSuccessSnackbarComponent
  ],
  providers: [
    AuditService,
    TransformActionsService,
    EasyRuleEditService,
    PageDetailsReportService,
    AuditConsoleLogService,
    WebJourneyActionDetailsService,
    DataSourceEditorService
  ]
})
export class AuditNgModule {}
