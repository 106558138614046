import { NgModule } from '@angular/core';
import { FullScreenStatusBannerComponent } from './full-screen-status-banner/full-screen-status-banner.component';
import { StatusProgressBarComponent } from './status-progress-bar/status-progress-bar.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { StatusBannerComponent } from './status-banner/status-banner.component';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  declarations: [
    FullScreenStatusBannerComponent,
    StatusProgressBarComponent,
    StatusBannerComponent
  ],
  exports: [
    FullScreenStatusBannerComponent,
    StatusProgressBarComponent,
    StatusBannerComponent
  ]
})
export class StatusBannerModule {}
