export enum EAuditReportType {
  AuditSummary,
  PageSummary,
  RuleSummary,
  TagInventory,
  VariableInventory,
  TagHealth,
  DuplicatesAndMultiples,
  CookieInventory,
  BrowserConsoleLogs,
  PrivacyCookies,
  PrivacyTags,
  PrivacyRequests,
  JsFileChanges,
  Usage,
  WebVitals
}
