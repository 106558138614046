import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import _escape from 'lodash-es/escape';
import {
  IAccessibilityRuleResult, IAccessibilityRuleTableResult
} from '@app/components/audit-reports/page-details/page-details.models';

@Component({
  selector: 'op-page-details-group',
  templateUrl: './page-details-group.component.html',
  styleUrls: ['./page-details-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageDetailsGroupComponent implements OnInit {
  @Input() group: IAccessibilityRuleTableResult;

  description: string = '';

  ngOnInit(): void {
    this.makeDescriptions();
  }

  private makeDescriptions(): void {
    this.description = _escape(this.group?.description) + '. View relevant document at ' +
        this.group?.successCriteria
            .map(c => `<a class="success-criteria" target="_blank" href="${c.w3Link}">${c.wcagSuccessCriteria}</a>`)
            .join(', ') + '.';
  }
}
