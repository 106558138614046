import { ILabel } from '@app/components/shared/services/label.service';
import { IRulePreview } from '@app/components/rules/rules.models';

export interface ISelectedItem {
  rule?: IRulePreview
  label?: ILabel
}

 export interface IRuleSelection {
  selectedRuleIds: Array<number>;
  selectedItems: Array<ISelectedItem>;
}

 export class IRuleSelection {

  static createDefault(): IRuleSelection {
    return { selectedRuleIds: [], selectedItems: [] };
  }

}
