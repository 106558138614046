import { possibleActions } from '@app/components/web-journey/web-journey.constants';
import { Injectable } from '@angular/core';
import { EActionTypeV3, ISelectOption } from '@app/components/web-journey/web-journey.models';
import { EActionCreatorMode } from '@app/components/actions/actions-creator/actions-creator.enum';
import { IBaseActionDetails } from '@app/components/actions/action-details/action-details.models';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable } from 'rxjs';
import { environment } from '@app/environments/environment';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';

export interface ICmpProvider {
  id: number;
  name: string;
  description: string;
}

@Injectable()
export class ActionDetailsService {

  root: string = environment.apiV3Url;

  constructor(private apiService: ApiService) { }

  @CacheApiResponse()
  getSupportedCmpList(): Observable<ICmpProvider[]> {
    return this.apiService.get(`${this.root}cmp-providers`);
  }

  calcPossibleActionTypes(mode: EActionCreatorMode, index: number, actions: IBaseActionDetails[]): ISelectOption[] {
    return possibleActions.filter(action => {
      if (index === 0 && (mode === EActionCreatorMode.AuditUserSession || mode === EActionCreatorMode.WebJourney)) return action.key === EActionTypeV3.NavTo || action.key === EActionTypeV3.ActionSet || action.key === EActionTypeV3.CmpOptInOptOut;
      if (action.key === EActionTypeV3.LeaveIFrame && !this.checkLeaveIFrameAllowed(index, actions)) return false;
      if (mode === EActionCreatorMode.ActionSet && action.key === EActionTypeV3.ActionSet) return false;
      if (mode === EActionCreatorMode.AuditActions && action.key === EActionTypeV3.CmpOptInOptOut) return false;
      return true;
    });
  }

  checkLeaveIFrameAllowed(index: number, actions: IBaseActionDetails[]): boolean {
    return actions.slice(0, index).reduce((allowed: boolean, action: IBaseActionDetails) => {
      if (action.type === EActionTypeV3.SwitchToIFrame) return true;
      if (action.type === EActionTypeV3.LeaveIFrame) return false;
      return allowed;
    }, false);
  }
}
