<div class="manage-accounts">
  <div class="manage-accounts-header">
    <div class="accounts-count header-left">
      {{ pagination.totalCount }} Accounts
    </div>
    <div class="search-create header-right">
      <div class="filter-bar-wrapper">
        <manage-accounts-filter-bar (typeahead)="handleTypeahead($event)"></manage-accounts-filter-bar>
      </div>
      <button class="create-new-account-btn"
              mat-flat-button color="primary"
              (click)="openCreateNewAccountModal()">
        Create New Account
      </button>
    </div>
  </div>
  <div class="manage-accounts-table-wrapper">
    <mat-spinner *ngIf="loading"
                 class="accounts-spinner"
                 [diameter]="75"
                 [strokeWidth]="8"></mat-spinner>

    <table mat-table
           [dataSource]="accounts"
           [class.loading]="loading"
           matSort
           matSortActive="id"
           matSortDirection="asc"
           class="accounts-table">

      <!-- id column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let account">{{ account.id }}</td>
      </ng-container>

      <!-- company column -->
      <ng-container matColumnDef="company">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>COMPANY</th>
        <td #companyCell
            mat-cell
            *matCellDef="let account"
            opTableCellTooltip="{{ account.company }}"
            (click)="editAccount(account)">
          {{ account.company }}
        </td>
      </ng-container>

      <!-- type column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            title="Sorting is according to an internal order, it is NOT alphabetical">TYPE</th>
        <td mat-cell *matCellDef="let account">{{ account.accountType }}</td>
      </ng-container>

      <!-- status column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
        <td mat-cell *matCellDef="let account">{{ account.status }}</td>
      </ng-container>

      <!-- name column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>NAME</th>
        <td mat-cell *matCellDef="let account" opTableCellTooltip="{{ account.name }}">
          {{ account.name }}
        </td>
      </ng-container>

      <!-- username column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>USERNAME</th>
        <td mat-cell *matCellDef="let account" opTableCellTooltip="{{ account.username }}">
          {{ account.username }}
        </td>
      </ng-container>

      <!-- email column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>EMAIL</th>
        <td mat-cell *matCellDef="let account" opTableCellTooltip="{{ account.email }}">
          {{ account.email }}
        </td>
      </ng-container>

      <!-- Login column -->
      <ng-container matColumnDef="login">
        <th mat-header-cell *matHeaderCellDef>LOGIN</th>
        <td mat-cell *matCellDef="let account">
          <button *ngIf="account.accountIsActive && account.canBeLoggedInAs"
                  class="login-button"
                  (click)="loginAsAccount(account.account)">
            Login
          </button>
          <div class="cannot-login" *ngIf="account.accountIsActive && !account.canBeLoggedInAs">Login</div>
          <div class="inactive-account" *ngIf="!account.accountIsActive">Inactive</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="pagination.totalCount" [pageSize]="pagination.pageSize"></mat-paginator>
    <op-table-cell-tooltip></op-table-cell-tooltip>
  </div>
</div>
