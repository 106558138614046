import { Component, ViewChild, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, AbstractControl, Validators } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { IUser, ITokenInformation } from '@app/moonbeamModels';
import { AngularNames, AuthenticationEvents } from '@app/moonbeamConstants';
import { IModalService } from '../modals/modalService';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Title } from '@angular/platform-browser';
import { OpModalService } from '../shared/components/op-modal';
import { WindowRef } from '../core/services/window.service';
import { environment } from '@app/environments/environment';
import { Router } from '@angular/router';
import { DataSourcesUrlBuilders } from '../manage/cards/manage-cards.constants';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { tap } from 'rxjs/operators';

const DEFAULT_BANNER: string = 'https://s3.amazonaws.com/img.observepoint.com/app-cta/marketing-banner.jpg';
const DEFAULT_REDIRECT: string = DataSourcesUrlBuilders.sources();

enum ELoginView {
  LOGIN,
  SIGNOUT,
  FORGOT,
  RESET
}

enum EForgotView {
  REQUEST,
  CONFIRM,
  ERROR
}

enum EResetView {
  REQUEST,
  CONFIRM,
  ERROR
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  ELoginView = ELoginView;
  EForgotView = EForgotView;
  EResetView = EResetView;
  loginView: ELoginView = ELoginView.LOGIN;
  forgotView: EForgotView = EForgotView.REQUEST;
  resetView: EResetView = EResetView.REQUEST;
  showSpinner: boolean = false;
  loginForm: UntypedFormGroup;
  forgotForm: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  resetError: string;
  loginDisabled: boolean = false;
  marketingImageLoaded: boolean = false;
  loginFailureMessage: string;
  showResetModal: boolean = false;
  isSignout: boolean = false;
  jwt: string;
  destroySuccessEventListener: () => void;
  destroyExpiredEventListener: () => void;

  @Input() bannerUrl: string = DEFAULT_BANNER;

  @ViewChild('username') usernameInput: MatFormField;
  @ViewChild('password') passwordInput: MatFormField;

  constructor(
    @Inject(AngularNames.rootScope) private $rootScope: angular.IRootScopeService,
    @Inject(AngularNames.location) private $location: angular.ILocationService,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private modalService: IModalService,
    private titleService: Title,
    private router: Router,
    private opModalService: OpModalService,
    private windowRef: WindowRef,
  ) {
    this.loginForm = this.formBuilder.group({
      username: this.formBuilder.control(''),
      password: this.formBuilder.control('')
    });

    this.forgotForm = this.formBuilder.group({
      username: this.formBuilder.control('')
    });

    this.resetForm = this.formBuilder.group({
      newPassword: ['', [Validators.minLength(8), Validators.required]],
      newPasswordConfirm: ['', Validators.required]
    });

    this.resetForm.setValidators(OPValidators.passwordsMatch);

    this.subscribeOnEvents();
  }

  ngOnInit() {
    this.handleQueryParams();
    this.titleService.setTitle('Login - Observepoint');
  }

  ngOnDestroy() {
    if (this.destroySuccessEventListener) {
      this.destroySuccessEventListener();
    }
  }

  private subscribeOnEvents() {
    this.destroySuccessEventListener = this.$rootScope.$on(
      AuthenticationEvents.loginSuccess,
      (event: angular.IAngularEvent, user: IUser) => this.navigateAfterLogin(user)
    );
  }

  private handleQueryParams() {
    const queryParams = this.getQueryParameters();
    const isSignout = queryParams.get('signout');
    const isReset = queryParams.get('resetpassword');
    this.jwt = queryParams.get('jwt');

    if (isSignout && isSignout === 'true') {
      this.loginView = ELoginView.SIGNOUT;
    }

    if (isReset && isReset === 'true') {
      this.loginView = ELoginView.RESET;
    }
  }

  openLink(link: string): void {
    window.open(link, 'helpWindow', 'width=1050,height=800');
  }

  login() {
    this.loginDisabled = true;
    this.showSpinner = true;

    var credentials = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password
    };

    this.authenticationService.login(credentials).subscribe((tokenInfo: ITokenInformation) => {
      delete this.loginFailureMessage;
      this.loginDisabled = false;
      if (tokenInfo.subDomain) {
        // oh look! They have a subdomain but they logged in to OP without using it
        const host = this.windowRef.nativeWindow.location.hostname;
        const protocol = this.windowRef.nativeWindow.location.protocol + '//';
        const newLocation = (host === 'localhost'
          ? 'localhost'
          : tokenInfo.subDomain + '.app.' + environment.domain);
        this.windowRef.nativeWindow.location.href = protocol + newLocation;
      }
    }, (error: any) => {
      this.showSpinner = false;
      this.loginFailureMessage = this.getFailureMessage(error);
      this.loginDisabled = false;
      console.error(error);
    });
  }

  switchToLogin() {
    // get rid of the signout and resetpassword parameters
    // this can only happen after JS thread goes idle...
    const state = this.$location.search();
    delete state.signout;
    delete state.resetpassword;
    delete state.jwt;
    this.$location.search(state);
    // ... so reload the state (reruns the resolver) with setTimeout
    // to force it to occur after URL query params cleared

    setTimeout(() => this.windowRef.nativeWindow.location.href = this.$location.absUrl());
  }

  openForgotPassword() {
    this.loginView = ELoginView.FORGOT;
  }

  forgotPassword(): void {
    this.showSpinner = true;

    this.authenticationService
      .forgotPassword(this.forgotUsername.value)
      .pipe(tap(() => this.showSpinner = false))
      .subscribe(
        res => this.forgotView = EForgotView.CONFIRM,
        error => this.forgotView = EForgotView.ERROR
      );
  }

  resetPassword(): void {
    this.resetForm.markAllAsTouched();
    if (this.resetForm.invalid) return;

    this.showSpinner = true;

    this.authenticationService
      .resetPassword(this.newPassword.value, this.jwt)
      .pipe(tap(() => this.showSpinner = false))
      .subscribe(
        res => {
          this.resetError = '';
          this.resetView = EResetView.CONFIRM;
        },
        error => {
          if (error.message.match(/^Password reset .* expired$/)) {
            this.resetError = 'The reset token has expired. Please submit a new forgot password request.';
            this.resetView = EResetView.ERROR;
          } else {
            this.showSpinner = false;
            this.resetError = error.message;
          }
        }
      );
  }

  resetLogin() {
    this.loginView = ELoginView.LOGIN;
    this.forgotView = EForgotView.REQUEST;
    this.loginUsername.setValue('');
    this.loginPassword.setValue('');
    this.forgotUsername.patchValue('');
  }

  private navigateAfterLogin(user: IUser) {
    const queryParams = this.getQueryParameters();
    const previousState = queryParams.get('redirect') || DEFAULT_REDIRECT;
    const previousUserId = this.authenticationService.getPreviousUserId();

    const redirectToPreviousState = previousUserId === -1 || user.id === previousUserId;
    const navigatePath = redirectToPreviousState ? previousState : DEFAULT_REDIRECT;

    this.showSpinner = false;

    if (this.isExternalURL(navigatePath)) this.windowRef.nativeWindow.location.href = navigatePath;
    else this.router.navigateByUrl(decodeURIComponent(navigatePath));
  }

  getFailureMessage(error: any): string {
    if (error === null) return 'An unknown error has occurred';
    if (error && error.message && error.message.status === 500) return 'An unknown error has occurred';
    return (error && error.message && error.message?.message) ? error.message.message : 'Username or password is incorrect';
  }

  private isExternalURL(navigatePath: string) {
    return (
      navigatePath.indexOf('http://') > -1 ||
      navigatePath.indexOf('https://') > -1 ||
      navigatePath.indexOf('help.observepoint') > -1
    );
  }

  private getQueryParameters(): URLSearchParams {
    // I think the more correct way to get query params is
    // to inject ActivatedRoute into this component. But we
    // cannot do that while on Hybrid routing.
    return new URLSearchParams(window.location.search);
  }

  get loginUsername(): AbstractControl {
    return this.loginForm.get('username');
  }

  get loginPassword(): AbstractControl {
    return this.loginForm.get('password');
  }

  get forgotUsername(): AbstractControl {
    return this.forgotForm.get('username');
  }

  get newPassword(): AbstractControl {
    return this.resetForm.get('newPassword');
  }

  get newPasswordConfirm(): AbstractControl {
    return this.resetForm.get('newPasswordConfirm');
  }
}
