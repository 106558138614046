import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const FeedbackPortalStateNames = {
  base: 'feedback_portal'
};

export const FeedbackPortalPaths = {
  base: 'feedback-portal'
};

export const FeedbackPortalUrlBuilders = {
  base: () => RouteBuilder.buildUrl([FeedbackPortalPaths.base])
};
