import { AfterViewInit, Component, forwardRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  EReportType,
  IAuditRunCookie,
  IConsentCategoryCookie,
  IPaginationMetaData,
  IRunInfo,
} from '@app/components/consent-categories/consent-categories.models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { SelectionModel } from '@angular/cdk/collections';
import {
  EConsentCategoryCreateStep,
  EConsentCategorySourceSortBy
} from '@app/components/consent-categories/cc-create/cc-create.enums';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { ConsentCategoriesUIConstants } from '@app/components/consent-categories/consent-categories.constants';
import { IDataSource } from '@app/components/shared/services/data-sources/data-sources.models';
import { MatPaginator } from '@angular/material/paginator';

const REPORT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ConsentCategoryCookiesComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-create-cookies',
  templateUrl: './cc-create-cookies.component.html',
  styleUrls: ['./cc-create-cookies.component.scss'],
  providers: [
    REPORT_CONTROL_VALUE_ACCESSOR
  ],
})

export class ConsentCategoryCookiesComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  CONSTANTS: any = { ...ConsentCategoriesUIConstants };
  destroy$: Subject<void> = new Subject();
  onChange: (value) => void;
  selection = new SelectionModel(true, []);
  searchText: string = '';
  selectAll: boolean = false;
  dataSource: MatTableDataSource<any> = new MatTableDataSource;
  selectedDataSource: MatTableDataSource<any> = new MatTableDataSource;
  displayedColumns: string[] = ['select', 'cookieName', 'cookieDomain'];
  pagination: IPaginationMetaData = {
    totalCount: 0,
    totalPageCount: 0,
    pageSize: 1500,
    currentPageSize: 0,
    currentPageNumber: -1
  };
  cookiesHash = {};
  cookies: IAuditRunCookie[] = [];
  loading: boolean = true;
  pagesLoadedCount: number = 0;
  queryParams = {
    page: 0,
    size: 1500,
    sortBy: EConsentCategorySourceSortBy.CookieName,
    sortDesc: false,
    search: '',
  };
  cookiesFilter: string = '';
  selectedLimit: number = 1500;
  displaySelection: boolean = false;
  selectedCookies: string[] = [];
  allCookiesSelected: boolean;
  editableExistingCookies: IConsentCategoryCookie[] = [];
  filterChanged$ = new Subject<string>();

  unselectedCookiesInResponse: number = 0;

  shiftKeyPressed: boolean = false;
  lastSelectedIndex: number = null;

  @Input() formControl: UntypedFormControl;
  @Input() noSelectionDisplay: boolean = false;
  @Input() currentStep: EConsentCategoryCreateStep;
  @Input() reports: IDataSource[] = []; // Selected audits to populate tabs data
  @Input() continueToSource$: Observable<boolean>;  // Triggered by outer component when needing to fetch initial data
  @Input() existingCookies: IConsentCategoryCookie[] = [];
  @ViewChild('resultsTableSort', { static: true }) resultsTableSort: MatSort;
  @ViewChild('selectedTableSort', { static: true }) selectedTableSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @HostListener('window:keydown.shift')
  onKeyDown() {
    this.shiftKeyPressed = true;
  }

  @HostListener('window:keyup.shift')
  onKeyUp() {
    this.shiftKeyPressed = false;
  }

  constructor(private ccService: ConsentCategoriesService) {}

  ngOnInit() {
    this.resultsTableSort.sortChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe((sort: Sort) => {
      this.queryParams.sortBy = sort.active === 'cookieName' ? EConsentCategorySourceSortBy.CookieName : EConsentCategorySourceSortBy.CookieDomain;
      this.queryParams.sortDesc = sort.direction === 'asc' ? false : true;
      this.resetPagination();
      this.loadCookies();
    });
    this.dataSource.sort = this.resultsTableSort;

    this.selectedDataSource.sort = this.selectedTableSort;
    this.selectedDataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'cookieName':
          return item['name'].toLowerCase();
        case 'cookieDomain':
          return item['domain'].toLowerCase();
      }
    };

    this.selectedDataSource.filterPredicate = (cookie: any, filter: string) => {
      const filterLower = filter.toLowerCase();

      // Filter on all tag name and account names
      const matchString = `${cookie['name']}~~${cookie['domain']}`.toLowerCase();

      return matchString.includes(filterLower);
    };

    this.addExistingCookiesToHashAndSelectedCookies();

    this.filterChanged$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe((filter: string) => {
      this.cookiesFilter = filter;
      this.queryParams.search = encodeURI(filter);
      this.resetPagination();
      this.loadCookies();
    });
  }

  ngAfterViewInit() {
    // Fetch initial cookies load
    this.continueToSource$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((selectedRunsChanged) => {
      if (selectedRunsChanged) {
        this.cookiesHash = {};
        this.dataSource.data = [];
        this.selectedDataSource.data = [];
        this.selectedCookies = [];
        this.addExistingCookiesToHashAndSelectedCookies();
        this.pagesLoadedCount = 0;
        this.resetPagination();

        if (this.reports.length === 0) {
          this.dataSource.data = [];
          this.loading = false;
        } else {
          this.loadCookies();
        }
      }
    });

    this.paginator.page.subscribe(({ pageIndex }) => {
      this.pagination.currentPageNumber = pageIndex;
      this.loadCookies();
    });
  }

  resetPagination(): void {
    this.pagination = {
      totalCount: 0,
      totalPageCount: 0,
      pageSize: 1500,
      currentPageSize: 0,
      currentPageNumber: 0
    };
  }

  /**
   * Component will receive an array of existing values on init if there are any. These
   * array items are the initial values in requestDomainsHash and selectedItems.
   */
  addExistingCookiesToHashAndSelectedCookies(): void {
    if (!this.existingCookies?.length) return;
    this.editableExistingCookies = [...this.existingCookies];
    this.addToCookiesHash(this.editableExistingCookies);
    this.editableExistingCookies.forEach(cookie => this.addToSelectedItems(this.getCookieId(cookie)));

    this.updateSelectedCookiesTable();

    // Update parent formControl with new selections
    this.setSelectedCookiesInForm(this.selectedCookies);
  }

  setSelectedCookiesInForm(selectedCookiesUIDs: string[]): void {
    let selectedCookiesObjects = selectedCookiesUIDs.map(cookieUID => this.cookiesHash[cookieUID]);
    this.updateSelectedCookiesTable();
    this.onChange && this.onChange(selectedCookiesObjects);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  loadCookies(): void {
    this.loading = true;

    // Create post body using selected reports that we are retrieving tab data for
    const runInfo: IRunInfo[] = this.reports.map(report => ({
      itemType: EReportType.AUDIT,
      itemId: report.itemId,
      runId: report.lastCompletedRun.runId
    }));

    // Fetch cookies for selected reports
    this.ccService.getRunCookies(runInfo, this.pagination, this.queryParams).pipe(
      take(1)
    ).subscribe(cookiesDTO => {
      this.addToCookiesHash(cookiesDTO.cookies);

      this.dataSource.data = cookiesDTO.cookies;
      this.pagination = cookiesDTO.metadata.pagination;
      this.paginator.pageIndex = this.pagination.currentPageNumber;

      this.unselectedCookiesInResponse = 0;
      this.allCookiesSelected = this.areAllSelected();
      this.filterSelectedCookies(this.cookiesFilter);

      this.loading = false;
    }, (err) => {
      this.loading = false;
      console.log('Error retrieving requested tag data');
    });
  }

  areAllSelected(): boolean {
    if (this.dataSource.data.length === 0) {
      return false;
    }

    let allSelected = true;

    this.dataSource.data.forEach(cookie => {
      const cookieId = this.getCookieId(cookie);
      if (!this.cookiesHash[cookieId] || (!this.cookiesHash[cookieId]?.existing && !this.cookiesHash[cookieId]?.selected)) {
        allSelected = false;
        this.unselectedCookiesInResponse++;
      }
    });

    return allSelected;
  }

  filterSelectedCookies(filterValue: string): void {
    this.selectedDataSource.filter = filterValue;
  }

  getCookieId(cookie: IConsentCategoryCookie): string {
    return cookie.name + cookie.domain;
  }

  openedSelection() {
    this.displaySelection = true;
  }

  closedSelection() {
    this.displaySelection = false;
  }

  /**
   * Iterate over the current list of results and either add/remove all non-existing items from the selection
   * @param checked: boolean
   */
  toggleSelectAll(checked) {
    this.allCookiesSelected = checked;
    // add all request domains in the list that aren't currently selected
    this.dataSource.data.forEach((cookie) => {
      // if not already selected, toggle select property and push to selected array
      const existing = this.cookiesHash[this.getCookieId(cookie)].existing;
      const selected = this.cookiesHash[this.getCookieId(cookie)].selected;

      if (!existing) {
        // Select all checked
        if (this.allCookiesSelected) {
          // Select any current values that are deselected
          if (!selected) {
            this.cookiesHash[this.getCookieId(cookie)].selected = true;
            this.addToSelectedItems(this.getCookieId(cookie));
          }
          // Deselect all current values
        } else {
          if (selected) {
            this.cookiesHash[this.getCookieId(cookie)].selected = false;
            this.removeFromSelectedItems(this.getCookieId(cookie));
          }
        }
      }
    });

    this.updateSelectedCookiesTable();

    // Update parent formControl with new selections
    this.setSelectedCookiesInForm(this.selectedCookies);
  }

  /**
   * Manually toggles the selected property of a request domain row in requestDomainsHash
   */
  toggleSelectOne(checked, row, updateExisting = false) {
    // If item is an existing item, we don't want to allow selection changes here
    if (this.cookiesHash[row?.name + row?.domain]?.existing) return;

    if (checked) {
      this.cookiesHash[this.getCookieId(row)].selected = true;
      this.addToSelectedItems(this.getCookieId(row));
    } else {
      this.cookiesHash[this.getCookieId(row)].selected = false;

      if (updateExisting && this.cookiesHash[this.getCookieId(row)].existing) {
        this.cookiesHash[this.getCookieId(row)].existing = false;
      }

      this.removeFromSelectedItems(this.getCookieId(row));
      this.allCookiesSelected = false;
    }

    this.updateSelectedCookiesTable();

    // Update parent formControl with new selections
    this.setSelectedCookiesInForm(this.selectedCookies);
  }

  debounceFilterData(searchValue): void {
    const filter = searchValue?.target?.value ? searchValue?.target?.value : '';

    this.filterChanged$.next(filter);
  }

  removeFromSelectedItems(cookieId: string): void {
    const index = this.selectedCookies.findIndex(selectedCookieId => cookieId === selectedCookieId);
    this.selectedCookies.splice(index, 1);
  }

  addToSelectedItems(cookieUID: string): void {
    this.selectedCookies.push(cookieUID);
  }

  updateSelectedCookiesTable(): void {
    this.selectedDataSource.data = this.selectedCookies.map(cookieUID => this.cookiesHash[cookieUID]);
  }

  page(event) {
    this.pagination.currentPageNumber = event.pageIndex;

    this.loadCookies();
  }

  /**
   * A cookies hash map is created to manage and display state
   * (i.e. existing, selected) for the overall list of cookies. Any time
   * new data is retrieved we add values to this hash if they weren't already loaded. Once
   * added they maintain their state for subsequent loads and are not added again.
   */
  addToCookiesHash(cookies) {
    cookies.forEach(cookie => {
      if (!this.cookiesHash[this.getCookieId(cookie)]) {
        this.cookiesHash[this.getCookieId(cookie)] = cookie;
      }
    });
  }

  writeValue(value: IConsentCategoryCookie[]) {
    const hashFromValue = {};
    value.forEach(cookie => {
      hashFromValue[this.getCookieId(cookie)] = cookie;
    });

    this.cookiesHash = hashFromValue;
  }

  registerOnChange(fn: (value: IConsentCategoryCookie[]) => void) { this.onChange = fn; }

  registerOnTouched(fn) {}

  handleCheckboxClick(checked: boolean, index: number): void {
    if (this.shiftKeyPressed) {
      if (this.lastSelectedIndex < index) {
        for (let i = this.lastSelectedIndex + 1; i <= index; i++) {
          this.toggleSelectOne(checked, this.dataSource.data[i]);
        }
      } else {
        for (let i = this.lastSelectedIndex - 1; i >= index; i--) {
          this.toggleSelectOne(checked, this.dataSource.data[i]);
        }
      }
    } else {
      this.toggleSelectOne(checked, this.dataSource.data[index]);
    }

    this.lastSelectedIndex = index;
  }
}
