import { ErrorHandler, Injectable } from '@angular/core';

/**
 * Global error handler for local executions of the application.
 * Error tracking solution (e.g. Sentry.io) should be used in staging and production environments 
 * See usage in app.module.ts
 */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  constructor() { }

  handleError(error: Error) {
    console.error('GLOBAL EXCEPTION HANDLER', error);
  }
}
