import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { SafeUrlPipe } from '@app/components/shared/pipes/safe-url/safe-url.pipe';
import { AutofocusDirective } from '@app/components/shared/directives/autofocus/autofocus.directive';
import { ClickStopPropagationDirective } from '@app/components/shared/directives/click-stop-propagation/click-stop-propagation.directive';
import { HighlightTextPipe } from '@app/components/shared/pipes/highlight-text/highlight-text.pipe';
import { OpMenuComponent } from '@app/components/shared/components/op-menu/op-menu.component';
import { OpSelectComponent } from '@app/components/shared/components/op-select/op-select.component';
import { OpSelectWithIconsComponent } from '@app/components/shared/components/op-select-with-icons/op-select-with-icons.component';
import { OpChipSelectorComponent } from '@app/components/shared/components/op-chip-selector/op-chip-selector.component';
import { OverflowClassDirective } from '@app/components/shared/directives/overflow-class/overflow-class.directive';
import { OpButtonComponent } from './components/op-button/op-button.component';
import { OpBetaLabelComponent } from './components/op-beta-label/op-beta-label.component';
import { ThumbnailComponent } from './components/op-thumbnail/thumbnail.component';
import { OpSortIndicatorComponent } from './components/op-sort-indicator/sort-indicator.component';
import { TagImgComponent } from './components/op-tag-img/tag-img.component';
import { OpSpinnerDirective } from './directives/op-spinner/spinner.component';
import { MaterialModule } from '@app/components/material/material.module';
import { FileUploadService } from './services/file-upload.service';
import { OpSuccessToastComponent } from './components/toasts/op-success-toast/op-success-toast.component';
import { OpSplashPageBasicComponent } from './components/op-splash-page-basic/op-splash-page-basic.component';
import { OpLabelEditorModule } from './components/op-label-editor/op-label-editor.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverflowFormatterDirective } from './directives/overflow-class/overflow-formatter.directive';
import { OpTooltipComponent } from './components/op-tooltip/op-tooltip.component';
import { ColorHighlightDirective } from './components/viz/color-highlight/color-highlight.directive';
import { TrendIndicatorComponent } from './components/viz/trend-indicator/trend-indicator.component';
import { OpErrorToastComponent } from '@app/components/shared/components/toasts/op-error-toast/op-error-toast.component';
import { OpButton2021Component } from './components/op-button-2021/op-button-2021.component';
import { AdminService } from '@app/components/admin-portal/system-status/admin.service';
import { ManageUsersComponent } from '@app/components/account-settings/manage-users/manage-users.component';
import { AdminAccountsService } from '@app/components/admin-portal/admin-accounts.service';
import { ExternalLinksService } from '@app/components/shared/services/external-links.service';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  ResizeableColumnDirective
} from '@app/components/shared/directives/resizeable-table-column/resizeable-column.directive';
import { ResizeableTableDirective } from '@app/components/shared/directives/resizeable-table/resizeable-table.directive';
import { VerticalResizeDirective } from '@app/components/shared/directives/vertical-resize/vertical-resize.directive';
import { MdePopoverModule } from '../popover';
import {
  DomainsOnlyPipe,
  HideProtocolAndDomainsPipe,
  HideProtocolPipe
} from '@app/components/shared/pipes/url-formatting/url-formatting.pipe';

/**
 * This module contains code that will be used across all app.
 * Should be included into the specific Feature Modules as needed.
 *
 * The following items should be declared inside this module:
 * 1. Common templates components
 * 2. Commonly used pipes (ie filters) and directives
 * 3. Commonly used Angular modules (eg CommonModule from @angular/common for the *ngIf structure directive),
 * so they can be easily used across your app, without importing them in every Feature Module.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MaterialModule,
    MdePopoverModule,
    MatSnackBarModule
  ],
  declarations: [
    AutofocusDirective,
    ClickStopPropagationDirective,
    HighlightTextPipe,
    OpButtonComponent,
    OpBetaLabelComponent,
    OpChipSelectorComponent,
    OpMenuComponent,
    OpSelectComponent,
    OpSelectWithIconsComponent,
    OverflowClassDirective,
    OverflowFormatterDirective,
    OpSpinnerDirective,
    SafeUrlPipe,
    ThumbnailComponent,
    OpSortIndicatorComponent,
    TagImgComponent,
    OpSuccessToastComponent,
    OpErrorToastComponent,
    OpSplashPageBasicComponent,
    OpTooltipComponent,
    ColorHighlightDirective,
    TrendIndicatorComponent,
    OpButton2021Component,
    ManageUsersComponent,
    ResizeableColumnDirective,
    ResizeableTableDirective,
    VerticalResizeDirective,
    HideProtocolAndDomainsPipe,
    DomainsOnlyPipe,
    HideProtocolPipe,
  ],
  exports: [
    AutofocusDirective,
    ClickStopPropagationDirective,
    HighlightTextPipe,
    OpButtonComponent,
    OpBetaLabelComponent,
    OpChipSelectorComponent,
    OpMenuComponent,
    OpSelectComponent,
    OpSelectWithIconsComponent,
    OverflowClassDirective,
    OverflowFormatterDirective,
    OpSpinnerDirective,
    SafeUrlPipe,
    ThumbnailComponent,
    OpSortIndicatorComponent,
    TagImgComponent,
    OpSplashPageBasicComponent,
    OpLabelEditorModule,
    OpTooltipComponent,
    ColorHighlightDirective,
    TrendIndicatorComponent,
    OpButton2021Component,
    ManageUsersComponent,
    ResizeableColumnDirective,
    ResizeableTableDirective,
    VerticalResizeDirective,
    HideProtocolAndDomainsPipe,
    DomainsOnlyPipe,
    HideProtocolPipe,
  ],
  providers: [
    DatePipe,
    FileUploadService,
    AdminAccountsService,
    AdminService,
    ExternalLinksService,
    SnackbarService
  ]
})
export class SharedModule { }
