import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable } from 'rxjs';
import { IActionSetAction } from '../../../action-set-library/action-set-library.models';

@Injectable({
  providedIn: 'root'
})
export class WebJourneyV3ActionsService {

  apiRoot: string = `${environment.apiV3Url}web-journeys`;

  constructor(private apiService: ApiService) {}

  getJourneyActions(journeyId: number): Observable<IActionSetAction[]> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}/actions`);
  }

  getJourneyAction(journeyId: number, actionId: number): Observable<IActionSetAction> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}/actions/${actionId}`);
  }

  updateJourneyActions(journeyId: number, actions: IActionSetAction[]): Observable<IActionSetAction[]> {
    return this.apiService.put(`${this.apiRoot}/${journeyId}/actions`, actions);
  }

  updateJourneyAction(journeyId: number, actionId: number, action: IActionSetAction): Observable<IActionSetAction> {
    return this.apiService.put(`${this.apiRoot}/${journeyId}/actions/${actionId}`, action);
  }
}
