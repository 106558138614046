<ng-container *ngFor="let node of initiatorsData">
  <div (click)="toggleCollapseState($event, node)"
       class="node"
       [class.node-collapsed]="node.collapsed"
       [class.node-no-children]="!node?.children?.length">
    <div class="node-container">
      <div class="tag-row">
        <mat-icon *ngIf="node?.children?.length"
                  class="node-expand-arrow"
                  [class.node-expand-arrow-collapsed]="node.collapsed">
          expand_more
        </mat-icon>
        <div class="tag-info">
          <div class="tag-info-main">
            <span class="tag-icon-wrap" [class.circled]="!node.tagId">
              <img class="tag-icon" src="{{ node.tagId ? getTagIcon(node.tagId) : getExtensionIcon(node) || '' }}" alt="{{ node.name }}">
            </span>
            <span class="tag-icon-wrap circled request-blocked-icon"
                  *ngIf="node.requestBlocking"
                  [matTooltip]="'Request has been blocked as requested'"
                  matTooltipPosition="above"
                  matTooltipShowDelay="200"
            >
              <mat-icon class="op-icon" svgIcon="request_blocked"></mat-icon>
            </span>
            <span class="tag-name">{{ node.tagName || node.name }}</span>
          </div>
          <span *ngIf="node.tagId" class="tag-request-url">{{ node.url }}</span>
        </div>
      </div>
      <div *ngIf="node.substitution !== undefined" class="substitution-wrap">
        <div class="substitution-status">
          <span class="file-substitution-icon-circle" [ngClass]="{
            'substitution-success': !node.substitution.hasOwnProperty('failure'),
            'substitution-failure': node.substitution.hasOwnProperty('failure')
          }">
            <img class="substitution-icon" src="/images/file_substitution.svg" alt="File Substitution">
          </span>
          <div *ngIf="!node.substitution.hasOwnProperty('failure')" class="file-substitution-message">
            File above was successfully substituted with:
          </div>
          <div *ngIf="node.substitution.hasOwnProperty('failure')" class="file-substitution-message">
            File substitution above failed
          </div>
        </div>
        <div *ngIf="!node.substitution.hasOwnProperty('failure')" class="substitution-result">
          <div class="tag-info-main">
            <span class="tag-icon-wrap" [class.circled]="!node.substitution.tagId">
              <img class="tag-icon" src="{{ node.substitution.tagId ? getTagIcon(node.substitution.tagId) : getExtensionIcon(node) || '' }}" alt="{{ node.substitution.tagName }}">
            </span>
            <span class="tag-name">{{ node.substitution.tagName || node.substitution.substituteUrl }}</span>
          </div>
          <span *ngIf="node.substitution.tagId" class="tag-request-url">{{ node.substitution.substituteUrl }}</span>
        </div>
      </div>
    </div>
    <tag-initiators-list [initiatorsData]="node.children"></tag-initiators-list>
  </div>
</ng-container>
