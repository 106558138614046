import { AfterViewInit, Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuditReportFilterBarService } from '../audit-report-filter-bar/audit-report-filter-bar.service';
import { AuditReportScrollService } from '../audit-report-scroll.service';
import { AuditReportPaths } from './audit-report.constants';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { Events } from '@app/moonbeamConstants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements AfterViewInit, OnInit {
  @ViewChild('reportWrapper', { read: ElementRef }) reportWrapper: ElementRef;
  showFilterBar: boolean = true;

  constructor(
    public auditReportFilterBarService: AuditReportFilterBarService,
    private scrollService: AuditReportScrollService,
    private router: Router,
    private eventManager: IEventManager
  ) {
    // initial page load
    this.handleFilterBarVisibility();

    // re-run logic on route change
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleFilterBarVisibility();
      }
    });
  }

  ngOnInit(): void {
    // hate this but it allows the page to reload so the data loads properly
    // after impersonating a user -- this only affects OP admins.
    this.eventManager.subscribe(Events.loggedInAsAnother, () => location.reload());
  }

  handleFilterBarVisibility(): void {
    const paths = this.router.url.split('/');
    const currentReportPath = paths[paths.length - 2];
    this.showFilterBar = currentReportPath !== AuditReportPaths.useCases;
  }

  ngAfterViewInit(): void {
    this.scrollService.register(this.reportWrapper);
  }

  onScroll(event: Event): void {
    this.auditReportFilterBarService.scroll$.next((event.target as HTMLElement).scrollTop);
  }

  reportActivated(e) {
    // fires anytime a report is activated. E.g. a user clicks on a report name in left-nav
    // does not fire when navigating within a report (i.e. use cases)
    this.scrollService.scrollToTop();
  }
}
