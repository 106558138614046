import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IBaseActionDetails } from '../../actions/action-details/action-details.models';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActionSetLibraryService } from '../action-set-library.service';
import {
  IActionSet,
  ICreateActionSetObject,
  IActionSetCreationRequest
} from '@app/components/action-set-library/action-set-library.models';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TransformActionsService } from '../transform-actions.service';
import { IRule, IRuleV3 } from '@app/components/rules/rules.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'create-action-set',
  templateUrl: './create-action-set.component.html',
  styleUrls: ['./create-action-set.component.scss']
})
export class CreateActionSetComponent implements OnInit {

  selectedActions: IBaseActionDetails[];
  actionSetForm: UntypedFormGroup;
  submitted: boolean = false;

  rightFooterButtons = [
    {
      label: 'Create Action Set',
      action: () => {
        this.saveActionSet();
      },
      primary: true,
      opSelector: 'action-set-migrate',
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<CreateActionSetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IBaseActionDetails[],
    private fb: UntypedFormBuilder,
    private actionSetService: ActionSetLibraryService,
    private transformActionsService: TransformActionsService
  ) {
    this.selectedActions = data;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.actionSetForm = this.fb.group({
      name: ['', Validators.required],
      addSimilar: [false]
    });
  }

  saveActionSet(): void {
    this.submitted = true;
    this.actionSetForm.markAllAsTouched();
    if (this.actionSetForm.invalid) return;

    const actionSet: IActionSetCreationRequest = {
      name: this.name.value,
      actions: this.transformActionsService.convertUiActionsToApi(this.selectedActions)
    };

    this.actionSetService.createActionSet(actionSet).subscribe((actionSet: IActionSet) => {
      let ruleObservables$ = [];

      this.selectedActions.forEach((action: IBaseActionDetails, index: number) => {
        if (!action.rules.length) return;

        const actionSetId = actionSet.id;
        const actionId = actionSet.actions[index].id;
        const rules = action.rules.map((rule: IRule) => rule.id);

        ruleObservables$.push(
          this.actionSetService
            .updateActionSetActionRules(actionSetId, actionId, rules)
            .pipe(catchError(error => {
              console.log(error);
              return of({});
            }))
        );
      });

      if (ruleObservables$.length) {
        forkJoin(ruleObservables$).subscribe((rules: IRuleV3[]) => {
          this.closeModal({actionSet, addSimilar: this.addSimilar.value});
        });
      } else {
        this.closeModal({actionSet, addSimilar: this.addSimilar.value});
      }
    });
  }

  closeModal(data?: ICreateActionSetObject): void {
    this.dialogRef.close(data);
  }

  get name(): AbstractControl {
    return this.actionSetForm.get('name');
  }

  get addSimilar(): AbstractControl {
    return this.actionSetForm.get('addSimilar');
  }
}
