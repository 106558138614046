<op-modal (onClose)="close()" [rightFooterButtons]="rightFooterButtons">
  <ng-template #headerTemplate>
    <op-modal-header
        title="Add Rules to Action"
        (onClose)="close()"
      ></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <rule-selector
      [rules]="rules"
      [labels]="labels"
      [selectedItemsAndRules]="selectedRules"
      (openRuleCreation)="openRuleCreation()"
      (openRuleEditor)="openRuleEditor($event)">
    </rule-selector>
  </ng-template>
</op-modal>
