<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Migrate Actions"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="migrate-actions">
      <div class="migrate-spinner" *ngIf="!showTable && !showNoItems && !showError">
        <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
      </div>
      <div class="migrate-content" [class.hidden]="!showTable">
        <p class="migrate-note">
          Below are the audits and web journeys that will be modified if you proceed.
          Please deselect any that you do not wish to update.
        </p>
        <div class="item-counts">
          <span class="item-count">
            <span class="item-count-number">{{ numAuditActions }}</span> Audit Action(s)
          </span>
          <span class="item-count">
            <span class="item-count-number">{{ numAuditUserSessions }}</span> Audit User Session(s)
          </span>
          <span class="item-count">
            <span class="item-count-number">{{ numJourneys }}</span> Web Journey(s)
          </span>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 migrate-table" matSort>

          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="onMasterToggleChange($event)"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            color="primary">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="onCheckboxChange($event, row)"
                            [checked]="selection.isSelected(row)"
                            color="primary">
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let item">{{ item.name }}</td>
          </ng-container>

          <ng-container matColumnDef="ownerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Owner</th>
            <td mat-cell *matCellDef="let item">{{ item.ownerName }}</td>
          </ng-container>

          <ng-container matColumnDef="itemType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let item">{{ item.itemType }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
        <div class="acknowledge">
          <mat-checkbox [(ngModel)]="confirmDelete" color="primary" (change)="updateSaveButtonStatus()">I understand that the above items will be updated to reference the action set rather than individual actions. This change cannot be undone.</mat-checkbox>
        </div>
      </div>
      <div class="no-items-to-migrate" *ngIf="showNoItems">
        No matching actions were found in your account.<br>Click "OK" to return to the previous screen.
      </div>
      <div class="no-items-to-migrate" *ngIf="showError">
        An error occurred while looking for matching actions.<br>Click "OK" to return to the previous screen.
      </div>
    </div>
  </ng-template>
</op-modal>