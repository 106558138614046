import { ILabel } from '@app/components/shared/services/label.service';
import { HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '../core/services/api.service';
import { IRule, IRulePreview, ITagVariableConditionMetadata, ITagVariableSnapshotId } from './rules.models';
import { RulesSerializer } from './rules.serializer';
import { IRuleFiltersV3, IRuleJourneyUsage, IRulePaginationV3, IRuleSortV3, IRulesUsageV3Response } from './rule-library/rule-library.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class RulesService {

  private readonly root = environment.apiUrl;
  private readonly rootV3 = environment.apiV3Url;
  private readonly ruleEndpointUpload = environment.apiUploadAppUrl + 'rules';
  private readonly ruleEndpoint = environment.apiUrl + 'rules';

  constructor(private apiService: ApiService,
    private reprocessService: IReprocessService,
    private rulesSerializer: RulesSerializer) {

    this.reprocessService.reprocessComplete$.subscribe(() => {
      clearCacheOnReprocess.next();
    });
  }

  getRules(): Observable<IRule[]> {
    return this.apiService.get(this.ruleEndpointUpload, {}, this.rulesSerializer);
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess, liveTime: 5000 })
  getRulesCached(): Observable<IRule[]> {
    return this.apiService.get(this.ruleEndpointUpload, {}, this.rulesSerializer);
  }

  getRulesV3(sorting: IRuleSortV3, pagination: IRulePaginationV3, filters: IRuleFiltersV3): Observable<IRulesUsageV3Response> {
    const params = new HttpParams()
      .set('page', pagination.currentPageNumber)
      .set('size', pagination.pageSize)
      .set('sortBy', sorting.sortBy)
      .set('sortDesc', sorting.sortDesc);

    return this.apiService.post(`${this.rootV3}rule-library`, filters, { params });
  }

  getRulePreviews(): Observable<IRulePreview[]> {
    return this.apiService.get(`${this.ruleEndpoint}/previews`, {});
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess, liveTime: 5000 })
  getRulePreviewsCached(): Observable<IRulePreview[]> {
    return this.apiService.get(`${this.ruleEndpoint}/previews`, {});
  }

  getRule(ruleId: number): Observable<IRule> {
    return this.apiService.get(`${this.ruleEndpoint}/${ruleId}`);
  }

  getAutomatedJourneysForRule(ruleId: number): Observable<IRuleJourneyUsage[]> {
    const params = new HttpParams().set('withRuleUsages', true);
    return this.apiService.get(`${this.ruleEndpoint}/${ruleId}/web-audits`, { params });
  }

  getGuidedJourneysForRule(ruleId: number): Observable<IRuleJourneyUsage[]> {
    const params = new HttpParams().set('withRuleUsages', true);
    return this.apiService.get(`${this.ruleEndpoint}/${ruleId}/web-journeys`, { params });
  }

  getRulesForWebJourneyAction(journeyId: number, actionId: number): Observable<number[]> {
    return this.apiService.get(`${this.root}web-journeys/${journeyId}/actions/${actionId}/rules`);
  }

  updateRule(rule: IRule): Observable<IRule> {
    return this.apiService.put(`${this.ruleEndpoint}/${rule.id}`, rule);
  }

  deleteRule(ruleId: number): Observable<{}> {
    return this.apiService.delete(`${this.ruleEndpoint}/${ruleId}`);
  }

  createRule(rule: IRule): Observable<IRule> {
    return this.apiService.post(this.ruleEndpoint, rule);
  }

  bindRuleToWebJourneyAction(journeyId: number, ruleIds: number[], actionId: number): Observable<number[]> {
    return this.apiService.put(`${this.root}web-journeys/${journeyId}/actions/${actionId}/rules`, ruleIds);
  }

  updateRuleLabels(ruleId: number, labels: Array<ILabel>): Observable<ILabel[]> {
    return this.apiService.put(`${this.ruleEndpoint}/${ruleId}/labels`, labels);
  }

  deleteRuleLabel(ruleId: number, labelId: number): Observable<any> {
    return this.apiService.delete(`${this.ruleEndpoint}/${ruleId}/labels/${labelId}`);
  }

  searchForRuleByNameAndLabel(rules: IRule[], query = ''): IRule[] {
    if (!rules) return [];
    const searchQuery = query.toLowerCase();
    return rules.filter(rule => {
      const nameMatched = rule?.name.toLowerCase().includes(searchQuery);
      const labelsMatched = !!rule?.labels.find(label => {
        return label.name.toLowerCase().includes(searchQuery);
      });
      return nameMatched || labelsMatched;
    });
  }

  getOriginalRuleId(snapshotId: number): Observable<{ originalRuleId: number }> {
    return this.apiService.get(`${this.root}rules/snapshots/${snapshotId}/original`);
  }

  getOriginalRuleIdFromTagId(snapshotId: number): Observable<ITagVariableSnapshotId> {
    return this.apiService.get(`${this.root}rules/snapshots/tags/${snapshotId}/original`);
  }

  // Get original rule tag variable id by given rule tag variable snapshot id
  getOriginalRuleConditionId(snapshotId: number): Observable<ITagVariableSnapshotId> {
    return this.apiService.get(`${this.root}rules/snapshots/tags/variables/${snapshotId}/original`);
  }

  getOriginalRulesByConditionIds(snapshotIds: number[]): Observable<ITagVariableConditionMetadata[]> {
    return this.apiService.post(`${this.root}rules/snapshots/tags/variables/original-description`, snapshotIds);
  }

}
