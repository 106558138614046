import { Component, ElementRef, Input, ViewChild, OnInit, OnChanges, AfterViewInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { CodeJar } from 'codejar';
import { withLineNumbers } from 'codejar-linenumbers';
import * as Prism from 'prismjs';
import { OpModalService } from '../shared/components/op-modal';
import { ModalEscapeService } from '../ui/modalEscape/modalEscapeService';
import { OpCodeHighlighterFullScreenComponent } from './op-code-highlighter-full-screen/op-code-highlighter-full-screen.component';

@Component({
  selector: 'op-code-highlighter',
  templateUrl: './op-code-highlighter.component.html',
  styleUrls: ['./op-code-highlighter.component.scss']
})
export class OpCodeHighlighterComponent implements OnInit, OnChanges, AfterViewInit {

  formControl: AbstractControl;
  code: string;
  highlightedCode: string;
  jar: any;

  // required
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input() showFullScreenButton: boolean = true;
  @Input() editorMinHeight: number = 150; // pixels
  @Input() scrollAreaMaxHeight: number = 150; // pixels
  @Input() fullScreenModalTitle: string = '';
  @Input() formGroup: FormGroup = new FormGroup({ js: new FormControl('') });
  @Input() controlName: string = 'js';
  @Input() value: string = ''; // used for readonly mode
  @Input() showLineNumbers: boolean = true;
  @Input() showActionButtons: boolean = true;
  @Input() withInnerPadding: boolean = false;

  @ViewChild('editor') editor: ElementRef<HTMLElement>;
  @ViewChild('matFormField', { read: ElementRef }) matFormField: ElementRef<HTMLElement>;

  constructor(
    private modalService: OpModalService,
    private modalEscapeService: ModalEscapeService
  ) {}

  ngOnInit(): void {
    this.setVariables();
  }

  ngOnChanges(): void {
    this.setVariables();
    this.jar?.updateCode(this.code);
  }

  ngAfterViewInit(): void {
    this.initCodeEditor();
  }

  setVariables(): void {
    this.formControl = this.formGroup?.get(this.controlName) || this.formGroup?.get('js');
    this.code = (this.value ? this.value : this.formControl.value) ?? '';
    this.highlightedCode = Prism.highlight(this.code, Prism.languages['js'], 'javascript');
  }

  initCodeEditor(): void {
    this.jar = CodeJar(this.editor.nativeElement as HTMLElement, this.showLineNumbers ? withLineNumbers(this.highlightMethod) : this.highlightMethod);
    this.jar.updateCode(this.code);
    this.jar.onUpdate((code: string) => {
      this.code = code;
      this.formControl.setValue(code);
    });

    if (this.readonly) this.editor.nativeElement.setAttribute('contenteditable', 'none')
  }

  highlightMethod(editor): void {
    if (editor.textContent !== null && editor.textContent !== undefined) {
      editor.innerHTML = Prism.highlight(editor.textContent, Prism.languages['js'], 'javascript');
    }
  }

  openFullScreen(): void {
    // to work on web journey results page we need to add an index to modalEscapeService
    const index = this.modalEscapeService.getLast() + 1;

    const dialogRef = this.modalService.openFullscreenModal(OpCodeHighlighterFullScreenComponent, {
      data: {
        title: this.fullScreenModalTitle,
        label: this.label,
        formGroup: this.formGroup,
        controlName: this.controlName,
        readonly: this.readonly,
        value: this.value
      }
    });

    this.modalEscapeService.add(index);

    dialogRef.afterClosed().subscribe((js: string) => {
      this.modalEscapeService.remove(index);

      if (js || js === '') {
        // only update the code if the modal was closed with 'OK' button
        this.jar.updateCode(js);
      } else {
        // if the modal was closed with the 'X' button, update the control with the existing value
        this.jar.updateCode(this.code);
      }
    });
  }
}
