import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RorComparisonReportService } from '../ror-comparison-report.service';
import { IVarPresencePagesStateParams, IMissingTagVariable, IMissingTagVarDatasource } from './ror-comparison-variable-presence-pages.models';
import { MatTableDataSource } from '@angular/material/table';
import { allComparisonsPlaceholder } from '../ror-comparison-report.contants';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-comparison-variable-presence-pages',
  templateUrl: './ror-comparison-variable-presence-pages.component.html',
  styleUrls: ['./ror-comparison-variable-presence-pages.component.scss']
})
export class RorComparisonVariablePresencePagesComponent implements OnInit, OnDestroy {

  auditId: number;
  runId: number;
  tagId: number;
  variableName: string;
  comparisonSnapshotId: number;

  variables: IMissingTagVariable[];

  displayedColumns: string[] = ['url', 'expectedValue', 'actualValue'];
  dataSource: MatTableDataSource<IMissingTagVarDatasource>;

  filterByUrl: string = '';

  private destroySubject = new Subject<void>();

  constructor(private activatedRoute: ActivatedRoute,
              private comparisonReportService: RorComparisonReportService) { }

  ngOnInit() {
    combineLatest(
      [this.activatedRoute.params, this.activatedRoute.queryParams]
    ).pipe(
      map(([params, queryParams]) => ({...params, ...queryParams})),
      takeUntil(this.destroySubject)
    ).subscribe((stateParams: IVarPresencePagesStateParams) => {
      this.auditId = parseInt(stateParams.auditId);
      this.runId = parseInt(stateParams.runId);
      this.comparisonSnapshotId = stateParams.comparisonSnapshotId ? parseInt(stateParams.comparisonSnapshotId) : allComparisonsPlaceholder.comparisonSnapshotId;
      this.tagId = parseInt(stateParams.tagId);
      this.variableName = stateParams.variableName;

      this.fetchData();
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

  private fetchData() {
    this.comparisonReportService.getVariablePresencePages(
      this.auditId,
      this.runId,
      this.tagId,
      this.variableName,
      this.comparisonSnapshotId
    ).subscribe(pages => {
      this.variables = pages;
      this.dataSource = new MatTableDataSource(
        pages.map(page => ({
          url: page.page.pageUrl,
          details: {
            expectedValue: page.expectedValue,
            actualValue: `Missing ${this.variableName}`,
            alignmentVariables: page.alignmentVariables.reduce(
              (acc, variable) => acc.concat(`${variable.name} = ${variable.value} `),
              ''
            )
          }
        }))
      );
    });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
