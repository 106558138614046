<section class="table-section">
  <h3 class="section-title">APPLY AUDIT TESTING SCENARIOS</h3>

  <div class="explanation-text">
    <p class="explanation-text-paragraph">
      Select one or more Audits. Every link in all messages received at this
      inbox will automatically be checked in the Audits specified below.
    </p>

    <p class="explanation-text-paragraph">
      Evaluate all pages with numerous possible testing scenarios like: GPC on,
      GPC off, Accept all, Reject all, or different locations like London
      or California.
    </p>

    <p class="explanation-text-paragraph">
      At least one Audit needs to be applied for a message to be validated.
    </p>
  </div>
  <table *ngIf="dataSource"
         mat-table
         matSort
         [dataSource]="dataSource"
         class="email-inbox-sources-table styled-mat-table"
         [matSortActive]="TableColumn.Name"
         matSortDirection="asc"
         matSortStart='desc'>
    <ng-container [matColumnDef]="TableColumn.Name">
      <th mat-header-cell
          *matHeaderCellDef
          class="center-text">
        AUDIT TESTING SCENARIOS*
      </th>
      <td mat-cell
          *matCellDef="let item"
          class="center-text"
          [class.faded]="auditToBeDisassociated(item)">
        <button (click)="editAudit(item)"
                opTableCellTooltip="{{ item.auditName }}"
                class="audit-name-block">
          <div class="audit-name-block-text">
            {{ item.auditName }}
            <mat-icon class="edit-icon">edit</mat-icon>
          </div>
        </button>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.Labels">
      <th mat-header-cell
          *matHeaderCellDef
          class="center-text"
      >Labels
      </th>
      <td mat-cell
          *matCellDef="let item"
          class="center-text"
          [class.faded]="auditToBeDisassociated(item)">
        <op-chip-manager
          [compactView]="true"
          [allChips]="item.labels"
          [selectedChips]="item.labels"
          [numberOfRows]="1"
          [chipType]="'Label'"
          [isReadOnly]="true"
          [class.read-only]="true">
        </op-chip-manager>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.Remove">
      <th mat-header-cell
          *matHeaderCellDef
          class="center-text">
      </th>
      <td mat-cell
          *matCellDef="let item"
          class="center-text">
        <div class="cell-wrapper">
          <button *ngIf="!auditToBeDisassociated(item); else disassociatedLabel"
                  (click)="disassociateAudit(item)"
                  class="disassociate-source-btn">
            <mat-icon class="material-icons-outlined">cancel</mat-icon>
          </button>
        </div>

        <ng-template #disassociatedLabel>
          <button class="disassociate-source-btn-revert"
                  (click)="revertDisassociation(item)">
            Undo
          </button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let page; columns: columns"></tr>
  </table>
</section>

<section class="select-audits-section">
  <button
    mat-flat-button
    color="primary"
    class="uppercase select-audits-btn"
    [matMenuTriggerFor]="sourcesMenu"
  >
    <div class="select-audits-btn-content">
      Select Audit(s)
      <mat-icon class="chevron">keyboard_arrow_down</mat-icon>
    </div>
  </button>
  <mat-menu #sourcesMenu="matMenu"
            class="slim-options"
            backdropClass="no-filter">
    <button mat-menu-item
            (click)="createAudit()">
      Create New Audit
    </button>
    <button mat-menu-item
            (click)="useExistingAudits()">
      Select Existing Audit
    </button>
    <button mat-menu-item
            (click)="copyAudit()">
      Copy Existing Audit
    </button>
  </mat-menu>
  <p class="select-audits-info">
    <mat-icon class="info-icon">info_outline</mat-icon>
    Selecting existing Audits can potentially affect trended data and applied standards.
  </p>
</section>

<section>
  <h3 class="section-title">EXCLUDE EMAIL MESSAGE LINKS FROM BEING SCANNED</h3>
  <div class="exclude-section">
    <div class="exclude-left">
      <div class="exclude-links-box boxed-bg">
        op="no"
        <button
          mat-flat-button
          color="primary"
          class="copy-btn uppercase"
          cbContent='op="no"'
          matTooltip="Copied to clipboard"
          matTooltipPosition="above"
          ngxClipboard
        >
          <mat-icon>content_copy</mat-icon>
          Copy
        </button>
      </div>
    </div>
    <div class="exclude-right">
      Add this HTML attribute to any link that should be excluded from being scanned.<br>
      Example: &lt;a href="https://www.example.com/spring-sale" <span class="highlight">op="no"</span>&gt;Spring Sale&lt;/a&gt;
    </div>
  </div>
</section>
<op-table-cell-tooltip></op-table-cell-tooltip>
