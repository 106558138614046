<div class="top-section">
  <div class="explanation-text">
    {{ explanationText }}
    <a [href]="learnMoreLink" target="_blank" class="learn-more-link">Learn more</a>
  </div>

  <op-tabs *ngIf="productType === EProductType.AUDIT" [tabs]="tabs" [activeTab]="activeTab" [isSubMenu]="true" (tabClicked)="handleTabClicked($event)"></op-tabs>
</div>

<!-- alerts -->
<op-standards-selector *ngIf="productType === EProductType.AUDIT && activeTab === EStandardsTabs.Alerts"
                       [standardsType]="EStandardsSelectorType.ALERTS"
                       [selectedStandardsIds]="selectedAlerts"
                       [productType]="productType"
                       [auditId]="auditId"
                       [runId]="runId"
                       [user]="user"
                       [hasLoaded]="hasLoaded"
                       (handleInitialLoad)="handleHasLoaded()"
                       (selectedStandardsUpdated)="alertsUpdated.emit($event)">
</op-standards-selector>

<!-- consent categories -->
<op-standards-selector *ngIf="privacyEnabled && activeTab === EStandardsTabs.ConsentCategories"
                       [auditId]="auditId"
                       [standardsType]="EStandardsSelectorType.CONSENT_CATEGORIES"
                       [selectedStandardsIds]="selectedConsentCats"
                       [productType]="productType"
                       [hasLoaded]="hasLoaded"
                       [createDataSource]="createDataSource"
                       (handleInitialLoad)="handleHasLoaded()"
                       (selectedStandardsUpdated)="consentCatsUpdated.emit($event)">
</op-standards-selector>

<!-- rules -->
<op-standards-selector *ngIf="activeTab === EStandardsTabs.Rules"
                       [standardsType]="EStandardsSelectorType.RULES"
                       [selectedStandardsIds]="selectedRules"
                       [productType]="productType"
                       [hasLoaded]="hasLoaded"
                       [createDataSource]="createDataSource"
                       (handleInitialLoad)="handleHasLoaded()"
                       (selectedStandardsUpdated)="rulesUpdated.emit($event)">
</op-standards-selector>
