import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ITagSignature } from '@app/components/tags/tagsService';
import { MatSort } from '@angular/material/sort';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-signatures-table',
  templateUrl: './tag-signatures-table.component.html',
  styleUrls: ['./tag-signatures-table.component.scss'],
})

export class TagSignaturesTableComponent implements AfterViewInit {
  displayedColumns: string[] = ['signature', 'matches', 'accountDelimiter', 'variableDelimiter', 'batchDelimiter'];

  dataSource = new MatTableDataSource();

  @Input() signatures: MatTableDataSource<ITagSignature>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor() {
  }

  ngAfterViewInit(){
    this.signatures.sort = this.sort;
  }
}
