<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Add New Tag" (onClose)="closeModal()"></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="add-primary-tags">
      <div class="tag-picker-wrap" *ngIf="payload.userIsAdmin">
        <op-tag-picker [clearOnSelect]="false"
                       [tags]="payload.accountTags"
                       (selected)="handleTagSelected($event)"
                       appearance="outline">
        </op-tag-picker>
      </div>
    </div>
  </ng-template>
</op-modal>