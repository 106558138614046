import { PrivacyPolicyComponent } from '@app/components/footerNg/privacy-policy.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    PrivacyPolicyComponent
  ],
  exports: [
    PrivacyPolicyComponent
  ]
})
export class FooterNgModule {}