<ror-parity-chart
  boxTitle="Tag Level"
  boxSubtitle="Percentage of Request Parity"
  [percentage]="tagLevelScore / 100"
  (onClickHandler)="openMissingTags()"
></ror-parity-chart>

<ror-parity-chart
  boxTitle="Variable Level"
  boxSubtitle="Percentage of Unchanged Variables"
  [percentage]="variableLevelScore / 100"
  (onClickHandler)="openMissingVariables()"
></ror-parity-chart>

<ror-parity-chart
  boxTitle="Value Level"
  boxSubtitle="Percentage of Unchanged Values"
  [percentage]="valueLevelScore / 100"
  (onClickHandler)="openIncorrectValues()"
></ror-parity-chart>

<ror-parity-chart
  boxTitle="Pages Not Compared"
  boxSubtitle="Count of pages missing from baseline"
  [rawValue]="pagesMissing"
  (onClickHandler)="openMissingPages()"
></ror-parity-chart>
