import { EActionTypeV3 } from '@app/components/web-journey/web-journey.models';
import { IAuditOptions } from '@app/components/audit/audit.models';

export const DEFAULT_AUDIT_LOCATION = {
  id: 1,
  name: 'mountain',
  label: 'USA, Oregon',
  countryCode: 'us'
};

export const DEFAULT_AUDIT_OPTIONS: IAuditOptions = {
  location: 'mountain',
  customProxy: null,
  userAgent: 'Chrome - Linux',
  requestRate: 10,
  fireTags: false,
  clearCookies: false,
  stripQueryString: false,
  loadFlash: true,
  browserWidth: 1366,
  browserHeight: 1200,
  vpnEnabled: false,
  gpcEnabled: false,
  adobeAuditor: false,
  blackoutPeriod: null,
  remoteFileMapConfig: [],
  webHookUrl: null,
  blockThirdPartyCookies: false,
};

export const DEFAULT_PAGE_LIMIT = 100;
export const MAX_PAGE_SCAN_LIMIT = 250000;
export const MAX_FREE_TRIAL_PAGE_SCAN_LIMIT = 300;

export enum EAuditFrequency {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMI_ANNUALLY = 'semiannually',
  YEARLY = 'yearly',
  ONCE = 'once'
}

export const AuditFrequencyFriendlyNames = {
  [EAuditFrequency.DAILY]: 'Daily',
  [EAuditFrequency.WEEKLY]: 'Weekly',
  [EAuditFrequency.BIWEEKLY]: 'Biweekly',
  [EAuditFrequency.MONTHLY]: 'Monthly',
  [EAuditFrequency.QUARTERLY]: 'Quarterly',
  [EAuditFrequency.SEMI_ANNUALLY]: 'Semiannually',
  [EAuditFrequency.YEARLY]: 'Yearly',
  [EAuditFrequency.ONCE]: 'Once'
};

export const DEFAULT_FREQUENCY = EAuditFrequency.ONCE;

export const DEFAULT_CUSTOM_PROXY = 'customProxy';

export enum EAuditTab {
  testScenario,
  urlSources ,
  standards,
  userSession,
  actions
}

export enum EAuditTabUrlSources {
  scheduledScan,
  emailInboxes
}

export interface IAuditTab {
  name: string;
  path: EAuditTab;
}

export const TAB_ID_TO_LABEL_MAP = new Map<number, string>([
  [ EAuditTab.testScenario, 'Test Scenario' ],
  [ EAuditTab.urlSources, 'URL Sources' ],
  [ EAuditTab.standards, 'Standards' ],
  [ EAuditTab.userSession, 'Pre-audit Actions' ],
  [ EAuditTab.actions, 'On-Page Actions' ]
]);

export const TAB_URL_SOURCES_TO_LABEL_MAP = new Map<number, string>([
    [EAuditTabUrlSources.scheduledScan, 'Scheduled scan'],
    [EAuditTabUrlSources.emailInboxes, 'Email inboxes'],
]);

export const STEP_TYPE_TO_LABEL_MAP = new Map<string, string>([
  [ EActionTypeV3.NavTo, 'Navigate To' ],
  [ EActionTypeV3.Click, 'Click' ],
  [ EActionTypeV3.Input, 'Input' ],
  [ EActionTypeV3.MaskedInput, 'Masked Input' ],
  [ EActionTypeV3.Select, 'Select' ],
  [ EActionTypeV3.Check, 'Check' ],
  [ EActionTypeV3.Uncheck, 'Uncheck' ],
  [ EActionTypeV3.Execute, 'Execute JavaScript' ],
  [ EActionTypeV3.Watch, 'Watch' ],
  [ EActionTypeV3.SwitchToIFrame, 'Switch to IFrame' ],
  [ EActionTypeV3.LeaveIFrame, 'Leave IFrame' ],
  [ EActionTypeV3.ActionSet, 'Action Set' ],
  [ EActionTypeV3.ClearCookies, 'Clear All Cookies' ],
  [ EActionTypeV3.CmpOptInOptOut, 'CMP' ],
  [ EActionTypeV3.CmpOptIn, 'CMP' ],
  [ EActionTypeV3.CmpOptOut, 'CMP' ]
]);

export const confettiColors: string[] = [
  '#F2CD14', // $Yellow_1
  '#D5A900', // $Yellow_2
  '#FAEB9D', // $Yellow_3,
  '#B0B0B0', // $Gray_5
];

export const RUN_AUDIT_NOW = 'runAuditNow';
export const CHECK_FOR_ALERT_CHANGES = 'checkForAlertChanges';
