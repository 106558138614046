import { Component, Input } from '@angular/core';
import { EWebVitalsMetricType } from '@app/components/shared/components/viz/web-vitals-chart/web-vitals-chart.constants';

@Component({
  selector: 'op-web-vitals-widget-tooltip',
  templateUrl: './web-vitals-widget-tooltip.component.html',
  styleUrls: ['./web-vitals-widget-tooltip.component.scss']
})
export class WebVitalsWidgetTooltipComponent {
  protected readonly EWebVitalsMetricType = EWebVitalsMetricType;
  @Input() tooltip: EWebVitalsMetricType = EWebVitalsMetricType.LargestContentfulPaint;
}
