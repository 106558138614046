<div class="selector-list">
  <ng-container *ngIf="hasMultipleSelectors()">
    <div
      *ngFor="let sel of selectors; index as i"
      class="selector"
      [class.active]="activeSelectorIndex === i"
      (click)="select(sel, i)"
      [matTooltip]="getTooltip(sel)"
      matTooltipShowDelay="500"
    >
      <span class="position">{{i + 1}}</span>
      <span class="bar"></span>
    </div>
  </ng-container>
  <button
    mat-icon-button
    disableRipple="true"
    *ngIf="selectors.length < maxSelectors"
    class="add-selector"
    (click)="add()">
    <mat-icon>add_box</mat-icon>
  </button>
  <span class="add-text" *ngIf="!hasMultipleSelectors()">Add Alternate Selectors</span>
</div>
