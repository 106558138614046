<div class="table-wrap">
  <div [hidden]="loading || (!loading && !alerts?.length)">
    <table class="styled-mat-table" mat-table matSort multiTemplateDataRows [dataSource]="dataSource">

      <!-- Name Column -->
      <ng-container [matColumnDef]="EAlertsLibraryColumns.Name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Alert Name</th>
        <td mat-cell *matCellDef="let alert">
          <span class="hover-link" (click)="editAlert.emit(alert)">
            {{ alert.name }}
          </span>
        </td>
      </ng-container>

      <!-- Report Metric Column -->
      <ng-container [matColumnDef]="EAlertsLibraryColumns.MetricType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Report Metric</th>
        <td mat-cell *matCellDef="let alert">
          <span>{{ alert.reportTypeFormatted }} -</span>&nbsp;
          <span> {{ alert.metricTypeFormatted }}</span>
        </td>
      </ng-container>

      <!-- Labels Column -->
      <ng-container [matColumnDef]="displayedColumns[2]">
        <th mat-header-cell *matHeaderCellDef>Labels</th>
        <td mat-cell *matCellDef="let alert" (click)="$event.stopPropagation()">
          <op-label-manager [selectedLabels]="alert.labelsFormatted"
                            (onLabelCreated)="createLabel(alert, $event)"
                            (onLabelSelected)="addLabel(alert, $event)"
                            (onLabelRemoved)="removeLabel(alert, $event)"
          ></op-label-manager>
        </td>
      </ng-container>

      <!-- Created column -->
      <ng-container [matColumnDef]="EAlertsLibraryColumns.UpdatedAt">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Created/Edited</th>
        <td mat-cell *matCellDef="let alert">
          <div class="date-format">
            <span>{{alert.updatedAt | date: dateFormat}} </span>&nbsp;
            <span>{{alert.updatedAt | date: timeFormat}}</span>
          </div>
        </td>
      </ng-container>

      <!-- Datasources Column -->
      <ng-container [matColumnDef]="EAlertsLibraryColumns.TargetItemCount">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Data Sources</th>
        <td mat-cell
            *matCellDef="let alert"
            (click)="alert.targetItemCount && openDataSourcesAlert.emit(alert)"
            [class.disabled]="!alert.targetItemCount || alert.targetItemCount === '---'"
            class="usage center-text">
          <div class="flex-row">
            <mat-icon>explore</mat-icon>
            <span>{{ alert.targetItemCount }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Suscribers Column -->
      <ng-container [matColumnDef]="EAlertsLibraryColumns.SubscribedCount">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Subscribers</th>
        <td mat-cell
            *matCellDef="let alert"
            (click)="alert.subscribedCount && openSubscribersAlert.emit(alert)"
            [class.disabled]="!alert.subscribedCount"
            class="usage center-text">
          <div class="flex-row">
            <mat-icon *ngIf="!alert.subscribedCount">notifications_none</mat-icon>
            <mat-icon *ngIf="alert.subscribedCount" svgIcon="bell-check"></mat-icon>

            <span>{{ alert.subscribedCount }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Edit Column -->
      <ng-container [matColumnDef]="displayedColumns[6]">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell
            *matCellDef="let alert"
            class="center-text">
          <actions-menu [class.disabled]="true"
                        matTooltip="You don't have access to this profile"
                        matTooltipPosition="above"
                        [matTooltipDisabled]="true">
            <button mat-menu-item
                    [disabled]="isReadOnly"
                    (click)="editAlert.emit(alert)"
            >
            <mat-icon class="alerts-table-menu-icon">edit</mat-icon>
              Edit Alert
            </button>
            <button mat-menu-item
                    [disabled]="isReadOnly"
                    (click)="duplicateAlert.emit(alert)"
            >
              <mat-icon class="alerts-table-menu-icon">content_copy</mat-icon>
              Duplicate Alert
            </button>
            <button mat-menu-item
                    [disabled]="true"
                    (click)="viewChangeLog.emit(alert)"
            >
              <mat-icon class="alerts-table-menu-icon">restore</mat-icon>
              View Change Log
            </button>
            <button mat-menu-item
                    (click)="deleteAlert.emit(alert)"
            >
              <mat-icon class="alerts-table-menu-icon">delete</mat-icon>
              Delete Alert
            </button>
          </actions-menu>
        </td>
      </ng-container>


      <tr *matNoDataRow>
        <td colSpan="7">
          <div *ngIf="!loading" class="no-data">
            <span>No notification profiles found.</span>
          </div>
        </td>
      </tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="expander hoverable"></tr>

    </table>

    <div>
      <mat-paginator class="styled-mat-paginator"
                     [class.loading]="loading"
                     [length]="pagination?.totalCount"
                     [pageSize]="pagination?.pageSize"
                     [pageIndex]="pagination?.currentPageNumber"

                     [hidePageSize]="true">
      </mat-paginator>
    </div>
  </div>
  <ng-container *ngIf="!loading && alerts && !alerts.length">
    <div class="zero-state">
      <h2>No alerts have been configured in your account.</h2>

      <p>In any audit report just find a data insight you want an alert on and click
        the bell icon to create the alert, or click a custom alert below and assign
        it to specific data sources.</p>
      <div class="img">
        <img src="images/alerts/zero-state.png">
      </div>
      <div>
        <op-button-2021 labelText="Create Alert"
                        matIcon="control_point"
                        (buttonAction)="createAlert.emit()"
                        buttonType="filled">
        </op-button-2021>
      </div>
    </div>
  </ng-container>
</div>
