<op-accordion>
  <mat-accordion displayMode="flat">
    <mat-expansion-panel *ngFor="let report of reports" hideToggle="true" op-selector="failed-rule-expansion-panel">
      <mat-expansion-panel-header>
        <mat-icon class="arrow">keyboard_arrow_right</mat-icon>
        <mat-icon class="info-icon">info</mat-icon>
        <div class="rule-title">{{ report.ruleName }}</div>
        <div class="icons" *ngIf="showEditButtons">
          <span class="icon edit-icon" (click)="handleEdit($event, report.ruleId)" op-selector="easy-rule-edit-button">
            <mat-icon class="edit">edit</mat-icon>
          </span>
          <span class="icon delete-icon" (click)="handleRemove($event, report.ruleId)"
            op-selector="easy-rule-remove-button">
            <mat-icon class="delete">delete</mat-icon>
          </span>
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <rule-results-table class="failed-tags-wrp" *ngFor="let failedTag of report.failedTags | tagMissed:false">
          <table mat-table [dataSource]="failedTag.items">
            <!-- Tag Name Header -->
            <ng-container matColumnDef="tag-name-header">
              <th colspan="4" mat-header-cell *matHeaderCellDef class="pre-header">
                <span>{{failedTag.tagName}}</span>
              </th>
            </ng-container>

            <!-- Variable Column -->
            <ng-container matColumnDef="variable">
              <th mat-header-cell *matHeaderCellDef class="variable-header">
                Variable
              </th>
              <td mat-cell *matCellDef="let element">
                <div>{{element.name}}</div>
                <div class="validation-description">{{element.validationDescription}}</div>
              </td>
            </ng-container>

            <!-- Expected Value Column -->
            <ng-container matColumnDef="expected">
              <th mat-header-cell *matHeaderCellDef class="expected-value-header">Expected Value</th>
              <td mat-cell class="expected-value break-all" *matCellDef="let element">{{element.expected}}</td>
            </ng-container>

            <!-- Actual Value Column -->
            <ng-container matColumnDef="actual">
              <th mat-header-cell *matHeaderCellDef class="actual-value-header">Actual Value</th>
              <td mat-cell class="break-all actual-value" *matCellDef="let element">
                <div class="actual-value-wrap">
                  <mat-icon>info</mat-icon>{{element.actual}}
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="set-as-expected">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element" class="set-as-expected">
                <div class="set-as-expected-wrap">
                  <button *ngIf="showEditButtons && showSetAsExpected(element)" mat-icon-button
                    aria-label="Set as expected value" matTooltip="Set as expected value" [matTooltipShowDelay]="500"
                    [disableRipple]="false" (click)="handleSetAsExpected(element)" op-selector="set-as-expected-button">
                    <mat-icon>input</mat-icon>
                  </button>
                  <button *ngIf="showEditButtons" mat-icon-button
                    aria-label="Delete variable from rule" matTooltip="Delete variable from rule" [matTooltipShowDelay]="500"
                    [disableRipple]="false" (click)="handleDeleteVariable(element)" op-selector="delete-variable-from-rule-button">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['tag-name-header']"></tr>
            <tr mat-header-row *matHeaderRowDef="['variable', 'expected', 'actual', 'set-as-expected']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['variable', 'expected', 'actual', 'set-as-expected'];"></tr>
          </table>
        </rule-results-table>
        <rule-results-table *ngIf="report.checkTimes && report.checkTimes.actual > 0">
          <table mat-table [dataSource]="[report.checkTimes]">
            <ng-container matColumnDef="check-times-header">
              <th colspan="2" mat-header-cell *matHeaderCellDef class="pre-header">
                <span>Number of times validated</span>
              </th>
            </ng-container>

            <ng-container matColumnDef="expected-validations">
              <th mat-header-cell *matHeaderCellDef>Expected Validations</th>
              <td mat-cell *matCellDef="let element">{{ element.expected }}</td>
            </ng-container>

            <ng-container matColumnDef="actual-validations">
              <th mat-header-cell *matHeaderCellDef>Actual Validations</th>
              <td mat-cell *matCellDef="let element" class="actual-value">{{ element.actual }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['check-times-header']"></tr>
            <tr mat-header-row *matHeaderRowDef="['expected-validations', 'actual-validations']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expected-validations', 'actual-validations']"></tr>
          </table>
        </rule-results-table>
        <ng-container *ngIf="hasMissingTags(report.failedTags)">
          <rule-results-table>
            <table mat-table [dataSource]="report.failedTags | tagMissed:true">
              <ng-container matColumnDef="condition-failure">
                <th mat-header-cell *matHeaderCellDef>Missing tags</th>
                <td mat-cell *matCellDef="let element">{{element.tagName}}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="['condition-failure']"></tr>
              <tr mat-row *matRowDef="let row; columns: ['condition-failure'];"></tr>
            </table>
          </rule-results-table>
        </ng-container>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</op-accordion>
