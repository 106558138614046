import { IFolder } from '../../components/folder/foldersApiService';
import { checkForKey } from '../selectors/selectorHelpers';
import { IReduxAction } from './reducers';
import { ActionTypes } from '../constants/actionTypes';
import * as Immutable from 'immutable';

function getFolderIndex(foldersState, folderId) {
  return foldersState.get('all').findIndex(folderMap => {
    return folderMap.get('id') == folderId;
  });
}

function setFolders(foldersState, folders: Array<IFolder>) {
  return foldersState.set('all', Immutable.fromJS(folders));
}

function updateFolderField(foldersState, folderId: number, field: string, updateFn) {
  return checkForKey(foldersState, 'all', () => {
    return foldersState.updateIn(['all', getFolderIndex(foldersState, folderId), field], updateFn);
  });
}

function addFolder(foldersState, folder) {
  return checkForKey(foldersState, 'all', () => {
    return foldersState.update('all', allFolders => allFolders.push(Immutable.fromJS(folder)));
  });
}

function updateFolder(foldersState, folder) {
  return checkForKey(foldersState, 'all', () => {
    return foldersState.updateIn(['all', getFolderIndex(foldersState, folder.id)], folderMap => folderMap.merge(folder));
  });
}

function deleteFolder(foldersState, folderId) {
  return checkForKey(foldersState, 'all', () => {
    return foldersState.update('all', allFolders => allFolders.filter(folderMap => folderMap.get('id') != folderId));
  });
}

export function folderReducer(state = Immutable.Map(), action: IReduxAction<any>) {
  switch (action.type) {
    case ActionTypes.SET_FOLDERS:
      return setFolders(state, action.payload);
    case ActionTypes.ADD_DOMAIN:
      return updateFolderField(state, action.payload.folderId, 'domainsCount', count => count + 1);
    case ActionTypes.DEL_DOMAIN:
      return updateFolderField(state, action.payload.folderId, 'domainsCount', count => count - 1);
    case ActionTypes.ADD_FOLDER:
      return addFolder(state, action.payload);
    case ActionTypes.UPDATE_FOLDER:
      return updateFolder(state, action.payload);
    case ActionTypes.DEL_FOLDER:
      return deleteFolder(state, action.payload);
  }

  return state;
}
