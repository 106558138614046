import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  ViewChild,
  OnInit,
  HostListener,
  HostBinding
} from '@angular/core';
import { IDailyUsageV2 } from '@app/components/usage-v2/usage-v2.models';
import {
  UsageRollingDateTableComponent
} from '@app/components/usage-v2/components/usage-chart-with-table/usage-rolling-date-table/usage-rolling-date-table.component';
import {
  UsageV2BarChartComponent
} from '@app/components/usage-v2/components/usage-chart-with-table/usage-bar-chart/usage-bar-chart.component';
import {
  EUsageTabTitleChartTypes
} from '@app/components/usage-v2/components/usage-tab-title-chart/usage-tab-title.enums';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import {
  IUsageBarsChartBar
} from '@app/components/usage-v2/components/usage-chart-with-table/usage-chart-with-table.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-chart-with-table',
  templateUrl: './usage-chart-with-table.component.html',
  styleUrls: ['./usage-chart-with-table.component.scss']
})
export class UsageChartWithTableComponent implements OnInit {
  @HostListener('window:beforeprint', ['$event']) beforePrint(): void {
    this._printing = true;
  }

  @HostListener('window:afterprint', ['$event']) afterPrint(): void {
    this._printing = false;
  }

  @HostBinding('class.printing') _printing = false;

  @ViewChild(UsageRollingDateTableComponent) scrollable: UsageRollingDateTableComponent;
  @ViewChild(UsageV2BarChartComponent) chart: UsageV2BarChartComponent;

  @Input() dailyUsageData: IDailyUsageV2[];
  @Input() amount: number;
  @Input() openTabType: EUsageTabTitleChartTypes;

  EUsageTabTitleChartTypes = EUsageTabTitleChartTypes;

  completedOrScheduledItems: IUsageBarsChartBar[];

  constructor(private dateService: DateService) {
  }

  ngOnInit(): void {
    this.completedOrScheduledItems = this.calculateCompletedOrScheduledScans(this.dailyUsageData);
  }

  scrollUsageTable(data: IUsageBarsChartBar): void {
    this.scrollable.scrollToDay(data.id);
  }

  updateChart() {
    this.chart.drawChart();
  }

  private calculateCompletedOrScheduledScans(data: IDailyUsageV2[]): IUsageBarsChartBar[] {
    const today = this.dateService.toUTC(new Date()).getDate();
    return data.map((d: IDailyUsageV2) => {
      const formattedDay = this.dateService.formatDate(this.dateService.toUTC(new Date(d.day)), EDateFormats.dateThree);
      d['id'] = 'id' + (1 | Math.random() * 1e16);
      return {
        id: d['id'],
        label: today == this.dateService.parseDate(d.day, EDateFormats.dateTwentyOne).getDate() ? 'Today' : formattedDay,
        completed: d.hasOwnProperty('noOfPagesScanned')
          ? d.noOfPagesScanned
          : d.completedItems?.length || 0,
        scheduled: d.hasOwnProperty('noOfPagesScheduledToScan')
          ? d.noOfPagesScheduledToScan
          : d.scheduledItems?.length || 0,
      };
    });
  }
}
