import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';
import { enUS } from 'date-fns/locale';
import { MatIconModule } from '@angular/material/icon';

export const MY_FORMATS = {
  parse: {
    dateInput: 'PP',
  },
  display: {
    dateInput: 'EEE, MMM d, yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'PP',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@Component({
  selector: 'op-run-date',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: DateFnsAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: enUS }
  ],
  templateUrl: './op-run-date.component.html',
  styleUrls: ['./op-run-date.component.scss']
})
export class OpRunDateComponent {

  @Input() runDateForm: FormGroup;
  @Input() showLabel?: boolean = true;
  @Input() placeholder?: string = '';
  @Input() controlName: string = 'date';

  get date(): AbstractControl {
    return this.runDateForm.get(this.controlName);
  }
}
