import { Component, Input, TemplateRef, ContentChild, ElementRef, Output, EventEmitter } from '@angular/core';

/**
 * This component is used as a splash page skeleton.
 * It receives different parts of splash page (header text, image src, and body template) and
 * injects them in the appropriate parts of the page.
 */
@Component({
  selector: 'op-splash-page-basic',
  templateUrl: './op-splash-page-basic.component.html',
  styleUrls: ['./op-splash-page-basic.component.scss']
})
export class OpSplashPageBasicComponent {

  /**
   * Header text, will be displayed at the top of a splash page
   */
  @Input() header: string;

  /**
   * Image src, will displayed at the right side of a splash page
   */
  @Input() imageSrc: string;

  /**
   * Event, will be emitted on footer button click
   */
  @Output() onClick = new EventEmitter<void>();

  /**
   * Body template, will be shown on the left side of a splash page
   */
  @ContentChild('bodyTemplate') bodyTemplate: TemplateRef<ElementRef>;

}
