<div class="status-button {{buttonColor}}-status"
     [class.enabled-info-items]="advanced"
>
  <span class="status-icon">
    <mat-icon class="leading-icon-{{leadingIconColor}}">{{ leadingIcon }}</mat-icon>
    {{ buttonText }}
  </span>
  <mat-icon [class.basic-status]="!advanced">{{ trailingIcon }}</mat-icon>
</div>
<ng-content></ng-content>
