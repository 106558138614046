import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-section-divider',
  templateUrl: './cl-section-divider.component.html',
  styleUrls: ['./cl-section-divider.component.scss']
})
export class ComponentLibrarySectionDividerComponent {
}
