<page-details-accessibility-widget class="plain">
  <ng-container title>
    Total Page Issues
  </ng-container>
  <div value
       class="value">
    {{ filteredPageIssuesNumber }}
    <span class="value-of">of</span>
    {{ totalPageIssues }}
  </div>
</page-details-accessibility-widget>

<page-details-accessibility-widget *ngFor="let widget of widgets"
                                   (valueChanged)="updateSeverity($event)"
                                   [widget]="widget">
  <ng-container title>{{ widget.label }}</ng-container>
  <div value
       class="value"
       [ngClass]="widget.className">{{ groupedIssues[widget.name]?.length || 0 }}
  </div>
</page-details-accessibility-widget>
