import { IEnvironment, sentryAllowUrls, updateApiUrlsForSso } from './environment.helpers';
import { FeatureFlags } from './feature-flags.enum';
import * as Sentry from "@sentry/angular";
import * as SentryTypes from "@sentry/types";

export const environment: IEnvironment = {
  production: true,
  domain: 'observepointstaging.com',
  apiProtocol: 'https',
  gatewayHost: '/api',
  apiHost: 'app.observepointstaging.com/api',
  apiUrl: 'https://app.observepointstaging.com/api/v2/',
  apiUploadAppUrl: 'https://app.observepointstaging.com/old/v2/',
  apiV3Url: 'https://app.observepointstaging.com/api/v3/',
  apiScriptServices: 'https://script.google.com/macros/s/AKfycby76O1P9yuQNxndjN7siP6MU9bfGKOHqNDHlQrtRTi0Dvk5Vb22BCjsDa1Fp3CziDbqpA/exec',
  authApiProtocol: 'https',
  authApiHost: 'app.observepointstaging.com/auth',
  features: [
    FeatureFlags.SQSJourneyEngines
  ],
  sentryConfig: {
    dsn: 'https://f1209dc2cf1723551f1890137e2a11ff@o4505801143681024.ingest.sentry.io/4505801217540096',
    environment: 'staging',
    release: 'moonbeam_staging_chore_sentry-unknown-fix-attempt',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      // https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      // new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://app.observepointstaging.com/api'],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,

    allowUrls: sentryAllowUrls,

    // https://docs.sentry.io/platforms/node/configuration/filtering/
    beforeSend(event: SentryTypes.ErrorEvent, hint: SentryTypes.EventHint) {
      // we can decide if we want to send the event to Sentry
      // by returning the event or we can throw it away by
      // returning null
      console.log('Sentry hint/event data: ', hint, event);

      const { message, statusCode } = hint?.originalException as any ?? {};
      if (message && message.match(/Cannot\sread\sproperties\sof\sundefined/i)
      ) {
        event.fingerprint = ["dereferencing-undefined-error"];
      }
      return event;
    }
  },
  sentryErrorConfig: {
    logErrors: true,
    showDialog: false
  },
  webSocketUrl: 'wss://upload.observepointstaging.com:443/v2/wse',
  originalRuleIdMappingDate: new Date('2019-10-18').getTime(),
  tagIconUrl: 'https://app.observepoint.com/icons/',
};

updateApiUrlsForSso(environment);
