import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requestParityFormatter'
})
export class RequestParityFormatterPipe implements PipeTransform {
  transform(value: number): string {
    const sign = value > 0 ? '+' : value < 0 ? '-' : ''
    const isPlural = Math.abs(value) > 1;
    return `${sign}${Math.abs(value)} REQUEST${isPlural ? 'S' : ''}`;
  }
}
