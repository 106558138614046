<div class="op-select-create">
  <op-select #opSelect
      *ngIf="mode === inputModes.Select"
      [placeholder]="label"
      [disabled]="isDisabled"
      [data]="data"
      [bindValue]="bindValue || null"
      [bindLabel]="bindLabel || 'name'"
      [appearance]="appearance"
      [(ngModel)]="modelValue"
      [clearable]="false"
      [appendTo]="appendTo"
      (onSelectionChanged)="onSelectChange($event)"
      (focus)="onFocus()">
  </op-select>
  <mat-form-field *ngIf="mode === inputModes.Create" [appearance]="appearance">
    <mat-label>{{ createLabel || label }}</mat-label>
    <input matInput [(ngModel)]="modelValue" (input)="onChange($event.target.value)" (focus)="onFocus()" [placeholder]="createPlaceholder || ''" />
  </mat-form-field>
  <op-error-message [formControlPath]="formControlPath" relativePosition="true"></op-error-message>
  <div class="mode-switch" *ngIf="!hideSwitch">
    Select an existing
    <span *ngIf="formControlPath === 'moveDomainForm.folder'">{{ itemName || 'folder' }}</span>
    <span *ngIf="formControlPath !== 'moveDomainForm.folder'">{{ subItemName || 'sub-folder' }}</span>
    or <span>{{ prefix }}</span>
    <a class="switch-btn" (click)="switchMode()">
      {{ mode === inputModes.Select ? createText : cancelText }}
    </a>
  </div>
</div>
