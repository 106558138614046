import { Component } from '@angular/core';
import { IOpTab } from '../../../shared/components/op-tabs/op-tabs.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-tabs',
  templateUrl: './cl-tabs.component.html',
  styleUrls: ['./cl-tabs.component.scss']
})
export class ComponentLibraryTabsComponent {

  sampleTabs: IOpTab[] = [
    { name: 'Tab 1', path: 'tab1' },
    { name: 'Tab 2', path: 'tab2' },
    { name: 'Tab 3', path: 'tab3' }
  ];

  activeTab: string = 'tab1';

  onTabClicked(path: string): void {
    this.activeTab = path;
  }
}
