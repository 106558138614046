import { Component } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@app/components/core/services/api.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OPValidators } from '@app/components/shared/validators/op-validators';

interface IItemFinderRouteParams {
  url?: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'verify-access',
  templateUrl: './verify-access.component.html',
  styleUrls: ['./verify-access.component.scss'],
})
export class VerifyAccessComponent {
  Object = Object;
  responses: Array<any> = [];
  error;
  blockingForm: UntypedFormGroup;
  submitted: boolean = false;
  root: string = environment.apiUrl;
  loading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private apiService: ApiService
  ) {
    this.activatedRoute.queryParams.subscribe((queryParams: IItemFinderRouteParams) => {
      this.checkParams(queryParams);
    });

    this.blockingForm = this.fb.group({
      url: ['', [Validators.required, OPValidators.url]]
    });
  }

  private verifyScheme(url: string): string {
    return url.indexOf('http') === -1 ? `http://${url}` : url;
  }

  checkParams(queryParams: IItemFinderRouteParams): void {
    if (queryParams.url) {
      this.url.setValue(this.verifyScheme(queryParams.url));
      this.go();
    }
  }

  go() {
    const url = this.verifyScheme(this.url.value);

    this.error = null;
    this.responses = [];

    this.loading = true;

    this.apiService.get(`${environment.apiUploadAppUrl}admin/services/verify-access?url=${url}`)
      .toPromise()
      .then(response => {
        this.loading = false;
        this.parseResponse(response);
        this.submitted = true;
      }, error => {
        this.loading = false;
        this.parseError(error);
      }).catch(error => {
        this.loading = false;
        this.parseError(error);
    });
  }

  private getStatusLink(status) {
    return status.split(' ')[0];
  }

  parseResponse(response) {
    setTimeout(() => {
      this.responses = response.responses;
      this.responses.forEach(r => r.statusCode = this.getStatusLink(r.status));
    });
  }

  parseError(error) {
    this.error = {
      message: `${this.url} returned with status ${error.status} (${error.statusText})`
    };
  }

  get url(): AbstractControl {
    return this.blockingForm.get('url');
  }
}
