<ng-container *ngIf="checkStatus()">
  <full-screen-status-banner *ngIf="showFullScreenStatusBanner"
                             [runNowPending]="runNowPending"
                             (runNowClicked)="runNow()"
                             updatesType="Audit">
  </full-screen-status-banner>
  <div class="report-view" *ngIf="!showFullScreenStatusBanner && !runNowPending">
    <div class="loading-indicator">
      <mat-progress-bar mode="indeterminate" color="primary" [style.opacity]="+(isLoading$ | async)"></mat-progress-bar>
    </div>
    <div class="alerts-changed-banner" *ngIf="showAlertsChangedBanner">
      The alerts associated with this audit have changed which might not match the data in this run.
      <ng-container *ngIf="selectedRunIsMostRecent && !userIsReadOnly">
        <button (click)="runNow()" class="run-audit-btn">Run the audit now</button>.
      </ng-container>
    </div>
    <div class="mobile-report-menu">
      <button [mdePopoverTriggerFor]="mobileMenuPopover"
              mdePopoverTriggerOn="click"
              class="mobile-menu-btn"
              [class.outlined]="outlined"
              (opened)="outlined = true"
              (closed)="outlined = false"
              #mobileMenuBtn
      >
        <div class="report-name">{{ currentReportName }}</div>
        <mat-icon>expand_more</mat-icon>
      </button>
      <mde-popover #mobileMenuPopover
                   [mdePopoverOverlapTrigger]="false"
                   mdePopoverOffsetY="-8"
      >
        <div class="mobile-menu-wrp" [style.width.px]="mobileMenuWidth">
          <modal-sidebar [navSections]="navSections"></modal-sidebar>
        </div>
      </mde-popover>
    </div>
    <div class="report-view-columns" [class.banner-appended]="showAlertsChangedBanner || !selectedRunIsMostRecent">
      <op-drawer>
        <modal-sidebar [navSections]="navSections"></modal-sidebar>
        <audit-notes [auditId]="auditId"></audit-notes>
      </op-drawer>
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
