import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IChartPillDataPoint } from './filter-pill.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'filter-pill',
  templateUrl: './filter-pill.component.html',
  styleUrls: ['./filter-pill.component.scss']
})
export class FilterPillComponent {
  @Input() data: IChartPillDataPoint;
  @Output() onClick: EventEmitter<{mouseEvent: MouseEvent, item: IChartPillDataPoint}> = new EventEmitter();
  @Output() onStartHover: EventEmitter<IChartPillDataPoint> = new EventEmitter();
  @Output() onEndHover: EventEmitter<IChartPillDataPoint> = new EventEmitter();
}
