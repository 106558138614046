import {
  AlertReportsToAuditMetrics, AlertReportsToUsageMetricsV2
} from '@app/components/alert/alert-logic/alert-logic.constants';
import { EFilterBarV2MenuTypes } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.constants';
import { IOpFilterBarV2MenuItem } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { AlertMetricType } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarMenuItem } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import { IAlertReportMetricsConfig, IAlertReportToMetricsConfig } from '@app/components/alert/alert-logic/alert-logic.models';
import { AlertUtils } from '@app/components/alert/alert.utils';

export class MetricTypeFilterUtils {

  static getFilterDisplay(reportName: string, metricName: string) {
    if (reportName === metricName) {
      return `Report metric is '${reportName}'`;
    }

    return `Report metric is '${reportName} - ${metricName}'`;
  }

  static buildReportMetricFilterMenuItemsV1(
    metricClickedAction: (reportName: string, metricName: string, metricType: AlertMetricType) => void
  ): IOpFilterBarMenuItem[] {
    const auditMenuItems = MetricTypeFilterUtils.buildMenuItemsV1(AlertReportsToAuditMetrics, metricClickedAction);
    const usageV2MenuItems = MetricTypeFilterUtils.buildMenuItemsV1(AlertReportsToUsageMetricsV2, metricClickedAction);
    return [
      {
        name: 'Audits',
        type: EFilterBarMenuTypes.Flyout,
        children: auditMenuItems
      },
      ...usageV2MenuItems
    ];
  }

  private static buildMenuItemsV1(
    reportToMetricsConfigs: IAlertReportToMetricsConfig[] | IAlertReportMetricsConfig[],
    metricClickedAction: (reportName: string, metricName: string, metricType: AlertMetricType) => void
  ): IOpFilterBarMenuItem[] {
    const buildMenuItems = (configs: (IAlertReportToMetricsConfig | IAlertReportMetricsConfig)[]): IOpFilterBarMenuItem[] => {
      return configs.map(reportConfig => {
        if ('metrics' in reportConfig && Array.isArray(reportConfig.metrics)) {
          const metricConfig = reportConfig as IAlertReportToMetricsConfig;
          return {
            name: metricConfig.name,
            type: EFilterBarMenuTypes.Flyout,
            children: buildMenuItems(metricConfig.metrics)
          };
        } else {
          const metricConfig = reportConfig as IAlertReportMetricsConfig;
          const reportConfigName = AlertUtils.getReportConfigByMetricType(metricConfig.value)?.name || metricConfig.name;
          return {
            name: metricConfig.name,
            type: EFilterBarMenuTypes.Button,
            action: () => metricClickedAction(reportConfigName, metricConfig.name, metricConfig.value)
          };
        }
      });
    }

    return buildMenuItems(reportToMetricsConfigs);
  }

  static buildReportMetricFilterMenuItemsV2(
    metricClickedAction: (reportName: string, metricName: string, metricType: AlertMetricType) => void
  ): IOpFilterBarV2MenuItem[] {
    const buildMenuItems = (reportConfigs: (IAlertReportToMetricsConfig | IAlertReportMetricsConfig)[]): IOpFilterBarV2MenuItem[] => {
      return reportConfigs.map(reportConfig => {
        if ('metrics' in reportConfig && Array.isArray(reportConfig.metrics)) {
          return {
            name: reportConfig.name,
            type: EFilterBarV2MenuTypes.Flyout,
            children: buildMenuItems(reportConfig.metrics)
          };
        } else {
          const metricConfig = reportConfig as IAlertReportMetricsConfig;
          return {
            name: metricConfig.name,
            type: EFilterBarV2MenuTypes.Button,
            action: () => metricClickedAction(reportConfig.name, metricConfig.name, metricConfig.value)
          };
        }
      });
    }

    return buildMenuItems(AlertReportsToAuditMetrics);
  }
}
