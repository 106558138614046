import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IActionSelectorAction } from '../action-selector.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-selector-item',
  templateUrl: './action-selector-item.component.html',
  styleUrls: ['./action-selector-item.component.scss'],
})
export class ActionSelectorItemComponent implements OnInit {

  @Input() action: IActionSelectorAction;
  @Input() defaultActions: Object;
  @Input() index: number;
  @Input() currentStep: number;
  @Input() appActions?: boolean = false;

  @Output() stepSelected: EventEmitter<number> = new EventEmitter();
  @Output() clickToEnlarge: EventEmitter<number> = new EventEmitter();

  ruleFailures: boolean = false;
  statusIcon: string;

  ngOnInit() {
    this.setStatus();
  }

  onStepClick(stepNumber: number) {
    this.stepSelected.emit(stepNumber);
  }

  private setStatus() {
    let failure = this.appActions ? this.action.failureMessage : !this.action.success;

    if (failure) {
      this.statusIcon = 'cancel';
    } else if (this.action.failedRuleReports && this.action.failedRuleReports.length) {
      this.statusIcon = 'error';
    } else {
      this.statusIcon = 'check_circle';
    }
  }

  onClickToEnlarge() {
    this.clickToEnlarge.emit(this.index);
  }
}
