import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-tooltip',
  templateUrl: './cl-tooltip.component.html',
  styleUrls: ['./cl-tooltip.component.scss']
})
export class ComponentLibraryTooltipComponent {
}
