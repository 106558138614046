import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class AuditReportLoadingService {

  private loadingTokens = 0;
  private loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get isLoadingAsync() { return this.loading$.asObservable(); }
  get isLoading() { return this.loadingTokens > 0; }

  forceOff() {
    this.loadingTokens = 0;
    this.notify();
  }

  addLoadingToken(): void {
    this.loadingTokens++;
    this.notify();
  }

  removeLoadingToken(): void {
    if (this.loadingTokens > 0) {
      this.loadingTokens--;
      this.notify();
    }
  }

  private notify() {
    this.loading$.next(this.loadingTokens > 0);
  }
}
