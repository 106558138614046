import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { AuditReportFilterBarService } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { Subject } from 'rxjs';

export abstract class PrivacyWidgetsLoadingStateHandler {

  loadingState: EFilterSpinnerState = EFilterSpinnerState.None;

  private loadingCounter: number = 0;
  private _loadingService: AuditReportLoadingService;
  private _filterBarService: AuditReportFilterBarService;

  protected destroy$: Subject<void> = new Subject();

  constructor(
    loadingService: AuditReportLoadingService,
    filterBarService: AuditReportFilterBarService
  ) {
    this._filterBarService = filterBarService;
    this._loadingService = loadingService;
  }

  startLoading() {
    this._loadingService.addLoadingToken();
    this.loadingCounter++;
    this.resetLoadingState();
  }

  stopLoading() {
    this._loadingService.removeLoadingToken();
    this.loadingCounter--;
    this.resetLoadingState();
  }

  abstract isFiltered(): boolean;

  private resetLoadingState() {
    if (this.loadingCounter > 0) {
      this.loadingState = EFilterSpinnerState.Loading;
    } else {
      this.loadingState = this.isFiltered()
        ? EFilterSpinnerState.Filtered
        : EFilterSpinnerState.None;
    }
  }
}
