<div class="sbt-wrap">
  <div class="bubbles" [class.less-then-six-items]="data.length < 6" *ngIf="data.length > 1">
    <div *ngFor="let bubble of data" class="bubble">
      <div [matTooltip]="bubble.tooltip" matTooltipPosition="above" class="bubble-background bubble-icon {{ bubble.color }}">
        {{ bubble.statusCode }}
      </div>
      <div class="bubble-date">
        {{ bubble.runDate | date: 'MMM dd'}}
      </div>
    </div>
  </div>
  <div *ngIf="data.length === 1" class="one-item">trendline requires more data</div>
</div>
