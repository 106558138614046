import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { EUserStep } from './create-edit-user-modal.constants';
import { AccountsService } from '../account/account.service';
import { IUser } from '@app/moonbeamModels';
import {
  EPermissionStringToIdMap,
  opSysAdmin,
  OpSysAdminPermissions,
  userIsAdmin,
  userIsOPAdmin,
  userIsOPSysAdmin,
  UserPermissions
} from '@app/authUtils';
import { AuthenticationService } from '../core/services/authentication.service';
import { IUserFolder } from '../folder/foldersApiService';
import {
  ICreateEditUserModalData,
  IProfileStepInfo,
  IUserProfileForm
} from '@app/components/create-edit-user-modal/create-edit-user-modal.models';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import {
  NotificationCenterModalFilterBarService
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal-filter-bar.service';
import { mergeMap, toArray } from 'rxjs/operators';
import {
  EmailsUpdateOperation,
  EUpdateOperationType,
  IUpdateOperationResult,
  NotificationCenterService
} from '@app/components/notifications-center/notification-center.service';
import {
  ENotificationCenterTargetItemType,
  IBulkActionItem,
  INotificationCenterTargetItem,
  INotificationProfile
} from '@app/components/notifications-center/notification-center.models';
import {
  INotificationCenterCurrentState,
  INotificationCenterCurrentStateItems,
  INotificationProfileSetupModalResultDiffItem,
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.models';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { BULK_ACTION_MAX_PARALLEL_REQUESTS } from '@app/components/notifications-center/notification-center.constants';
import { startsWith } from '@app/moonbeamConstants';
import { AdminPortalUrlBuilders } from '@app/components/admin-portal/admin-portal.constants';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import {
  UserNotificationsComponent
} from '@app/components/create-edit-user-modal/user-notifications/user-notifications.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

const emptyProfile = {
  email: '',
  usage: {
    auditCount: 0,
    webJourneyCount: 0,
    ruleCount: 0,
    alertCount: 0,
    usageAlertCount: 0,
    emailInboxMessageReceivedCount: 0,
    emailInboxMessageProcessedCount: 0
  }
};

@Component({
  selector: 'op-create-edit-user-modal',
  templateUrl: './create-edit-user-modal.component.html',
  styleUrls: ['./create-edit-user-modal.component.scss']
})
export class CreateEditUserModalComponent {
  private backButton: IButton = {
    label: 'Back',
    icon: 'icon-back-empty',
    action: () => this.previousStep(),
    hidden: true
  };

  private nextButton: IButton = {
    label: 'Next',
    icon: 'icon-forward-empty',
    action: () => this.nextStep(),
    hidden: false
  };
  private saveButton: IButton = {
    label: 'Save',
    action: () => this.save(),
    primary: true,
    hidden: false
  };

  profileStepInfo: IProfileStepInfo = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    timeZone: '',
  }

  profileStepSubtitle: string = '';

  readonly footerButtons: IButton[] = [this.backButton, this.nextButton, this.saveButton];
  readonly EUserStep = EUserStep;

  currentStep = EUserStep.Profile;
  userForm: UntypedFormGroup;
  editMode: boolean = false;
  modalTitle: string = 'User Profile';
  currentUser: IUser;
  isAdmin = false;
  isOpAdmin = false;
  isOpSysAdmin = false;
  userBeingEdited: IUser;
  userBeingEditedIsSelf = false;
  accountType: number;
  userFolders: IUserFolder[] = [];

  selectedPermissionLevel: number;
  selectedFoldersCount: number;

  stepOneValid: boolean;
  stepTwoValid: boolean;
  stepThreeValid: boolean;
  useExistingSelection: boolean = false;

  notificationProfile: INotificationProfile;
  notificationsCurrentState: any;
  selectedNotificationsCount: any;
  saving: boolean;
  isInAdminPortal: boolean;

  @ViewChild(UserNotificationsComponent) userNotifications: UserNotificationsComponent;

  private emailChanges$ = new Subject<string>();

  constructor(
    private dialogRef: MatDialogRef<CreateEditUserModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public payload: ICreateEditUserModalData,
    private accountsService: AccountsService,
    private authService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private filterBarService: NotificationCenterModalFilterBarService,
    private notificationCenterService: NotificationCenterService,
    private router: Router,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.isInAdminPortal = this.checkIfAdminPortal();
    this.filterBarService.clear();
    this.editMode = this.payload.editMode;
    this.modalTitle = `${this.editMode ? 'Edit ' : 'Create '} User Profile`
    this.initForm();
    this.getData();
    this.initListeners();
    this.initEmailChangeListener();
  }

  ngOnDestroy(): void {
    this.filterBarService.clear();
  }

  initListeners() {
    this.profileFormGroup.valueChanges.subscribe(() => {
      this.validateProfileStep();
      this.cdr.detectChanges();
    });

    this.profileFormGroup.statusChanges.subscribe(status => {
      if (status === 'PENDING') return;
      this.validateProfileStep();
    });

    this.permissionsFormGroup.valueChanges.subscribe(() => {
      this.validatePermissionsStep();
    });
  }

  private initEmailChangeListener(): void {
    this.emailChanges$
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(email => {
        if (email && !this.editMode) {
          this.fetchNotificationProfile(email);
        }
      });
  }

  private fetchNotificationProfile(email: string): void {
    this.notificationCenterService.getEmails().subscribe(notificationProfiles => {
      const profile = notificationProfiles.emails.find(profile => profile.email === email);
      if (profile) {
        this.notificationProfile = profile;
      } else {
        this.notificationProfile = {
          ...emptyProfile,
          email: email,
        };
      }
      this.updateNotificationsTab();
    });
  }

  private updateNotificationsTab(): void {
    if (this.userNotifications) {
      this.userNotifications.notificationProfile = this.notificationProfile;
      this.userNotifications.loadAssignedItems();
    }
  }

  getNotificationProfile() {
    if (this.editMode) {
      this.notificationCenterService.getEmails().subscribe(notificationProfiles => {
          this.notificationProfile = notificationProfiles.emails.find(profile => profile.email === this.userBeingEdited.email);

          if (!this.notificationProfile) {
            this.notificationProfile = {
              ...emptyProfile,
              email: this.userBeingEdited.email,
            }
          }
        });
    } else {
      this.notificationProfile = emptyProfile;
    }
  }

  private initForm() {
    this.userForm = this.formBuilder.group({
      profile: this.formBuilder.group<IUserProfileForm>({
        firstName: new FormControl<string | null>(null, { validators: [Validators.required] }),
        lastName: new FormControl<string | null>(null, { validators: [Validators.required] }),
        username: new FormControl<string | null>(null, {
          validators: [
            Validators.required,
          ],
        }),
        email: new FormControl<string | null>(null, { validators: [Validators.required, OPValidators.emails] }),
        password: new FormControl<string | null>(null, { validators: [] }),
        currentPassword: new FormControl<string | null>(null, { validators: [] }),
        newPassword: new FormControl<string | null>(null, { validators: [] }),
        newPasswordConfirm: new FormControl<string | null>(null, { validators: [] }),
        timeZone: new FormControl<string | null>(null, { validators: [Validators.required] })
      }),
      permissions: this.formBuilder.group({
        permissionLevel: this.formBuilder.control(null, [Validators.required]),
        auditPageLimit: this.formBuilder.control(null, [Validators.required, Validators.min(0), Validators.max(250000)]),
        usageDashboardAccess: this.formBuilder.control(null),
        accountStatus: this.formBuilder.control(null)
      }),
      folderAccess: this.formBuilder.group({
        items: this.formBuilder.control([])
      }),
      notifications: this.formBuilder.group({
        items: []
      })
    });

    this.password.disable();
  }

  notificationsSelectionChanged(currentState: INotificationCenterCurrentState): void {
    this.notificationsCurrentState = currentState;
    let diff = this.getTotalSelectedNotifications(currentState.items);
    this.useExistingSelection = true;
    this.selectedNotificationsCount = this.getTotalSelectedItems(currentState.items);

    this.notificationsFormGroup.patchValue({
      items: diff
    });
  }

  getTotalSelectedItems(items: { [key: string]: INotificationCenterCurrentStateItems }): number {
    let totalSelectedItems = 0;

    for (const key in items) {
      if (items.hasOwnProperty(key)) {
        totalSelectedItems += items[key].selectedItems.length;
      }
    }

    return totalSelectedItems;
  }

  getTotalSelectedNotifications(data: any) {
    let diff = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        diff[key] = this.calculateDiff(data[key]);
      }
    }

    return diff;
  }

  private calculateDiff(item): INotificationProfileSetupModalResultDiffItem[] {
    const diff = ArrayUtils.diff(
      item.initialSelectedItemIds,
      item.selectedItems,
      (a: number, b: INotificationCenterTargetItem) => a === b.itemId);
    return [
      ...diff.added.map((i: INotificationCenterTargetItem) => ({itemId: i.itemId, operationType: EUpdateOperationType.ADD})),
      ...diff.removed.map(i => ({itemId: i, operationType: EUpdateOperationType.REMOVE}))
    ];
  }

  transformNotificationItemsStructure(input: { items: { [key: string]: { itemId: number, operationType: string }[] } }): { itemType: string, value: { itemId: number, operationType: string }[] }[] {
    const result: { itemType: string, value: { itemId: number, operationType: string }[] }[] = [];

    for (const itemType in input.items) {
      if (input.items.hasOwnProperty(itemType) && input.items[itemType].length > 0) {
        result.push({
          itemType,
          value: input.items[itemType]
        });
      }
    }

    return result;
  }

  private handleNotificationAssignments() {
    const email = [this.email.value];
    const items = this.transformNotificationItemsStructure(this.notificationsFormGroup.value) || [];

    const groupedByOperationType = items.reduce((result, item) => {
      item.value.forEach(innerItem => {
        const { operationType } = innerItem;

        if (!result.hasOwnProperty(operationType)) {
          result[operationType] = [];
        }

        result[operationType].push({ ...item, value: of([innerItem]) });
      });

      return result;
    }, {});

    const operations = [
      ...(groupedByOperationType[EUpdateOperationType.ADD]?.length
          ? [{
            operation: EmailsUpdateOperation.ADD(email),
            items: groupedByOperationType[EUpdateOperationType.ADD],
          }]
          : []
      ),
      ...(groupedByOperationType[EUpdateOperationType.REMOVE]?.length
          ? [{
            operation: EmailsUpdateOperation.REMOVE(email),
            items: groupedByOperationType[EUpdateOperationType.REMOVE],
          }]
          : []
      ),
    ];

    return operations;
  }

  // Transform the items saved in the formgroup into API requests for each of the
  // various notification types.
  private saveAllNotificationChanges(): Observable<IUpdateOperationResult[]> {
    let operations = this.handleNotificationAssignments();
    const itemsSource = this.notificationCenterService.getAllAudits();

    const handleSingleOperation = (
      operation: EmailsUpdateOperation,
      item: IBulkActionItem,
    ): Observable<IUpdateOperationResult> => {
      let stream: Observable<IUpdateOperationResult>;
      switch (operation.operationType) {
        case EUpdateOperationType.ADD:
        case EUpdateOperationType.REMOVE:
        case EUpdateOperationType.REPLACE:
          stream = item.value
            .pipe(
              mergeMap((targetItems) => {
                const args: [number[], EmailsUpdateOperation] = [
                  targetItems.map(i => i.itemId),
                  operation,
                ];

                switch (item.itemType) {
                  case ENotificationCenterTargetItemType.ALERT:
                  case ENotificationCenterTargetItemType.USAGE_ALERT:
                    return this.notificationCenterService.updateEmailsInAlerts(...args);

                  case ENotificationCenterTargetItemType.AUDIT:
                    return this.notificationCenterService.updateEmailsInAudits(...args, itemsSource);

                  case ENotificationCenterTargetItemType.RULE:
                    return this.notificationCenterService.updateEmailsInRules(...args);

                  case ENotificationCenterTargetItemType.WEB_JOURNEY:
                    return this.notificationCenterService.updateEmailsInWebJourneys(...args);

                  case ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED:
                    return this.notificationCenterService.updateEmailsInInboxEmailsReceived(...args);

                  case ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED:
                    return this.notificationCenterService.updateEmailsInInboxEmailsProcessed(...args);
                }
              })
            );
      }

      return stream;
    };

    return of(...operations).pipe(
      mergeMap(({ operation, items }) =>
        of(...items).pipe(
          mergeMap(item => handleSingleOperation(operation, item), BULK_ACTION_MAX_PARALLEL_REQUESTS),
          toArray()
        )
      )
    );
  }

  getData(): void {
    this.accountsService.getUser().subscribe(user => {
      this.currentUser = user;
      this.isAdmin = userIsAdmin(this.currentUser);
      this.isOpAdmin = userIsOPAdmin(this.currentUser);
      this.isOpSysAdmin = userIsOPSysAdmin(this.currentUser);

      if (this.payload.accountType) {
        this.accountType = this.payload.accountType;
      } else {
        this.authService.getAccountPreview().subscribe(account => {
          this.accountType = account.accountType;
        });
      }

      if (this.editMode) {
        if (this.payload.userId !== this.currentUser.id) {
          this.userBeingEditedIsSelf = false;
          this.accountsService.getUserById(this.payload.userId).then(user => {
            this.userBeingEdited = user;
            this.cdr.detectChanges();
            this.patchUserProfileFormGroup();
            this.patchUserPermissionsFormGroup();
            this.getDataForFolderAccess();
            this.getNotificationProfile();
            this.username.setAsyncValidators([
              OPValidators.checkDuplicatesAsync(this.accountsService, [this.userBeingEdited?.username])
            ]);
            this.username.updateValueAndValidity();
            this.validateProfileStep();
          });
        } else {
          this.userBeingEditedIsSelf = true;
          this.userBeingEdited = this.currentUser;
          this.username.setAsyncValidators([
            OPValidators.checkDuplicatesAsync(this.accountsService, [this.currentUser?.username])
          ]);
          this.username.updateValueAndValidity();
          this.cdr.detectChanges();
          this.patchUserProfileFormGroup();
          this.patchUserPermissionsFormGroup();
          this.getDataForFolderAccess();
          this.getNotificationProfile();
        }
      } else {
        this.username.setAsyncValidators([
          OPValidators.checkDuplicatesAsync(this.accountsService, [])
        ]);
        this.username.updateValueAndValidity();
        this.validateProfileStep();
        this.timeZone.setValue(this.currentUser.timezone);
      }
    });
  }

  getDataForFolderAccess(): void {
    // For Op Sys Admin editing another Op Sys Admin, skip folder retrieval
    if (this.isOpSysAdmin && this.userBeingEdited?.permissions === opSysAdmin) return;

    this.accountsService.getUserFolders(this.userBeingEdited.id).then((folders: IUserFolder[]) => {
      this.userFolders = folders;

      this.folderAccessFormGroup.patchValue({
        items: this.userFolders.map(folder => folder.id)
      });
    });
  }

  private patchUserProfileFormGroup(): void {
    this.profileFormGroup.patchValue({
      firstName: this.userBeingEdited?.firstName,
      lastName: this.userBeingEdited?.lastName,
      email: this.userBeingEdited?.email,
      username: this.userBeingEdited?.username,
      timeZone: this.userBeingEdited?.timezone,
    });

    this.updateProfileSubtext();
    this.validateProfileStep();
  }

  private validateProfileStep(): void {
    if (this.profileFormGroup.status === 'PENDING') return;
    if (this.profileFormGroup.status === 'VALID') {
      this.stepOneValid = true;
    } else {
      this.stepOneValid = false;
    }
    this.cdr.detectChanges();
  }

  private validatePermissionsStep() {
    if (this.permissionsFormGroup.valid) {
      this.stepTwoValid = true;
    } else {
      this.stepTwoValid = false;
    }
    this.cdr.detectChanges();
  }

  private patchUserPermissionsFormGroup(): void {
    this.permissionsFormGroup.patchValue({
      permissionLevel: EPermissionStringToIdMap[this.userBeingEdited?.permissions],
      auditPageLimit: this.userBeingEdited?.maxPagesPerAudit,
      usageDashboardAccess: this.userBeingEdited?.fullUsageDashboardAccess,
      accountStatus: this.payload.accountLocked
    });

    this.selectedPermissionLevel = EPermissionStringToIdMap[this.userBeingEdited?.permissions];

    this.validatePermissionsStep();
  }

  nextStep() {
    this.goToStep(this.currentStep + 1);
  }

  previousStep() {
    this.goToStep(this.currentStep - 1);
  }

  goToStep(stepId: EUserStep): void {
    if (this.isInAdminPortal && stepId === EUserStep.Notifications) {
      return;
    }

    switch(this.currentStep) {
      case EUserStep.Profile:
        // If current tab invalid, display validation and stay here
        if (this.profileFormGroup.invalid) {
          this.profileFormGroup.markAllAsTouched();
          this.profileFormGroup.updateValueAndValidity();
          this.stepOneValid = false;
          return;
        }

        // If clicking on the third tab, validate the second tab first and force user to update that before moving on
        if (stepId === EUserStep.FolderAccess && this.permissionsFormGroup.invalid) {
          this.permissionsFormGroup.markAllAsTouched();
          this.permissionsFormGroup.updateValueAndValidity();
          this.stepTwoValid = false;
          return;
        }

        // If clicking on the fourth tab, validate the second and third tabs first and force user to update them before moving on
        if (stepId === EUserStep.Notifications && (this.permissionsFormGroup.invalid || this.folderAccessFormGroup.invalid)) {
          this.permissionsFormGroup.markAllAsTouched();
          this.permissionsFormGroup.updateValueAndValidity();
          this.folderAccessFormGroup.markAllAsTouched();
          this.folderAccessFormGroup.updateValueAndValidity();
          this.stepTwoValid = false;
          return;
        }

        break;
      case EUserStep.Permissions:
        if (this.permissionsFormGroup.invalid && stepId > this.currentStep) {
          this.permissionsFormGroup.markAllAsTouched();
          this.permissionsFormGroup.updateValueAndValidity();
          this.stepTwoValid = false;
          return;
        }

        // If clicking on the fourth tab, validate the second and third tabs first and force user to update them before moving on
        if (stepId === EUserStep.Notifications && this.folderAccessFormGroup.invalid) {
          this.folderAccessFormGroup.markAllAsTouched();
          this.folderAccessFormGroup.updateValueAndValidity();
          this.stepTwoValid = false;
          return;
        }

        break;
      case EUserStep.FolderAccess:
        if (this.selectedFoldersCount === 0 && stepId > this.currentStep) {
          this.folderAccessFormGroup.markAllAsTouched();
          this.folderAccessFormGroup.updateValueAndValidity();
          this.stepThreeValid = false;
          return;
        }
        break;
    }

    if (stepId === EUserStep.Notifications) {
      if (this.userNotifications) {
        this.userNotifications.updateCurrentTable();
      }
    }

    this.currentStep = stepId;
    this.updateButtons();

    if (stepId > EUserStep.Profile && !this.editMode) {
      const email = this.email.value;
      this.emailChanges$.next(email);
    }
  }

  private updateButtons(): void {
    this.backButton.hidden = this.currentStep === EUserStep.Profile;
    this.nextButton.hidden = this.currentStep === EUserStep.Notifications || (this.isInAdminPortal && (this.currentStep === EUserStep.FolderAccess || this.currentStep === EUserStep.Permissions));
  }

  handleProfileChanges(): void {
    if (this.profileFormGroup.valid) {
      this.stepOneValid = true;
      this.cdr.detectChanges();
    }

    this.updateProfileSubtext();
  }

  private updateProfileSubtext(): void {
    this.profileStepInfo.firstName = this.firstName.value;
    this.profileStepInfo.lastName = this.lastName.value;
    this.profileStepInfo.email = this.email.value;
    this.profileStepInfo.username = this.username.value;
    this.profileStepInfo.timeZone = this.timeZone.value;
    this.profileStepSubtitle = `
      ${this.profileStepInfo.firstName ? this.profileStepInfo.firstName : ''} ${this.profileStepInfo.lastName ? this.profileStepInfo.lastName + '<br>' : ''}
      ${this.profileStepInfo.email ? this.profileStepInfo.email + '<br>' : ''}
      ${this.profileStepInfo.username ? this.profileStepInfo.username + '<br>' : ''}
      ${this.profileStepInfo.timeZone ? this.profileStepInfo.timeZone : ''}
      `
  }

  updateSidebarWithPermissionLevel(level: number): void {
    this.selectedPermissionLevel = level;
  }

  updateStepTwoValidState(valid: boolean): void {
    this.stepTwoValid = valid;
  }

  updateSidebarWithSelectedFoldersCount(count: number): void {
    this.selectedFoldersCount = count;
    this.cdr.detectChanges();
  }

  updateStepThreeValidState(valid: boolean): void {
    this.stepThreeValid = valid;
  }

  save() {
    this.userForm.markAllAsTouched();
    if (this.userForm.invalid || (this.isInAdminPortal && (this.profileFormGroup.invalid || this.permissionsFormGroup.invalid))) return;

    this.saving = true;

    // Notifications require a bearer token of the edited user, so disabling in admin portal
    // as this doesn't work with current endpoints unless we are first impersonating.
    if (!this.isInAdminPortal) {
      this.saveAllNotificationChanges().subscribe(() => {});
    }

    if (this.userBeingEditedIsSelf) {
      this.updateSelf();
    } else if (this.userBeingEdited) {
      this.updateUser();
    } else {
      this.createNewUser();
    }
  }

  private updateUser() {
    let updatedUser: IUser = {
      ...this.userBeingEdited,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      username: this.username.value,
      email: this.email.value.trim(),
      timezone: this.timeZone.value,
      maxPagesPerAudit: this.auditPageLimit.value,
      fullUsageDashboardAccess: this.usageDashboardAccess.value
    };

    if (this.isInAdminPortal) {
      // logic:
      // if form value is locked
      // check if we have a value from the api and use it
      // otherwise set the date for 24 hours in the future
      // otherwise send a null value
      updatedUser.blockedUntil = this.accountStatus.value
        ? this.userBeingEdited.blockedUntil
          ? this.userBeingEdited.blockedUntil
          : new Date(new Date().getTime() + (24 * 60 * 60 * 1000))
        : null;
    }

    if (this.permissionsFormGroup.value) {
      if (this.payload.adminAccount || this.isOpSysAdmin) {
        // Admin permissions endpoint requires permission to be a number, while other endpoint requires a string.
        // Converting back to a number for admin calls.
        updatedUser.permissions = this.getPermissionNumber(this.permissionLevel.value);
      } else {
        updatedUser.permissions = this.getPermissionString(this.permissionLevel.value);
      }
    }

    let folders = this.selectedFolders.value;
    let requests = [];

    if (this.isAdmin || this.isOpSysAdmin) {
      requests = [
        this.payload.adminAccount
          ? this.accountsService.updateUserWithAccount(this.payload.adminAccount.id, updatedUser)
          : this.isOpSysAdmin ? this.accountsService.updateUserWithAccount(this.currentUser.accountId, updatedUser)
            : this.accountsService.updateUser(updatedUser),
      ];

      // If not updating from admin, update folders, as well but not if the user is an OP Sys Admin
      if (!this.isInAdminPortal && updatedUser.permissions !== OpSysAdminPermissions) {
        this.accountsService.updateUserFolders(this.userBeingEdited.id, folders);
      }
    }

    if (this.newPassword.value) {
      if (this.payload.adminAccount) {
        requests.push(this.accountsService.changePasswordByAdminWithAccount(this.payload.adminAccount.id, this.userBeingEdited.id, this.newPassword.value));
      } else {
        requests.push(this.accountsService.changePasswordByAdmin(this.userBeingEdited.id, this.newPassword.value));
      }
    }

    if (requests.length) {
      forkJoin(requests).subscribe(
        success => {
          this.accountsService.resetUserCache();
          this.close(success);
        },
        error => {
          // Handle error message when a user is attempting to use a recent password and
          // has strong password enabled
          const message = error.errorCode === 10 ? error.message : undefined;
          this.handleErrorWhileSaving(error, error.errorCode === 10 ? false : undefined, message);
          this.setUnableToUseSamePasswordValidationFlag();

          return of(null);
        }
      );
    } else {
      this.close();
    }
  }

  private setUnableToUseSamePasswordValidationFlag(): void {
    this.newPassword.setErrors({ unableToUseSamePassword: true });
    this.newPasswordConfirm.setErrors({ unableToUseSamePassword: true });
  }

  private updateSelf() {
    let updatedUser = {
      ...this.currentUser,
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      username: this.username.value,
      email: this.email.value.trim(),
      maxPagesPerAudit: this.auditPageLimit.value,
      timezone: this.timeZone.value,
      fullUsageDashboardAccess: this.usageDashboardAccess.value
    };

    let folders = this.selectedFolders.value;
    let requests = [];

    if (this.isAdmin) {
      if (this.payload.adminAccount) {
        requests = [
          this.accountsService.updateUserWithAccount(this.payload.adminAccount.id, updatedUser)
        ];
      } else {
        requests = [
          this.accountsService.updateUser(updatedUser),
          this.accountsService.updateUserFolders(this.currentUser.id, folders)
        ];
      }
    }

    if (this.newPassword.value) {
      if (this.payload.adminAccount) {
        requests.push(this.accountsService.changePasswordWithAccount(this.payload.adminAccount.id, this.currentUser.id, this.newPassword.value));
      } else {
        requests.push(this.accountsService.changePassword(this.currentPassword.value, this.newPassword.value));
      }
    }

    if (requests.length) {
      forkJoin(requests).subscribe(
        success => {
          this.accountsService.resetUserCache();
          this.close(success);
        },
        error => {
          // Handle error message when a user is attempting to use a recent password and
          // has strong password enabled
          const message = error.errorCode === 10 ? error.message : undefined;
          this.handleErrorWhileSaving(error, error.errorCode === 10 ? false : undefined, message);

          return of(null);
        }
      );
    } else {
      this.close();
    }
  }

  private createNewUser() {
    let user = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      username: this.username.value,
      email: this.email.value.trim(),
      maxPagesPerAudit: this.auditPageLimit.value,
      permissions: this.permissionLevel.value,
      timezone: this.timeZone.value,
      fullUsageDashboardAccess: this.usageDashboardAccess.value
    } as IUser;

    if (this.payload.adminAccount || this.isOpSysAdmin) {
      // Admin permissions endpoint requires permission to be a number, while accounts endpoint requires a string.
      // Converting back to a number for admin calls.
      user.permissions = this.getPermissionNumber(user.permissions);
    } else {
      user.permissions = this.getPermissionString(user.permissions);
    }

    let folders = this.selectedFolders.value;

    let createPromise = this.payload.adminAccount ?
      this.accountsService.createUserWithAccount(this.payload.adminAccount.id, user)
      : this.isOpSysAdmin ? this.accountsService.createUserWithAccount(this.currentUser.accountId, user)
        : this.accountsService.createUser(user);

    createPromise.then(
      user => {
        // If not updating from admin, update folders, as well but not if the user is an OP Sys Admin
        if (!this.isInAdminPortal && user.permissions !== OpSysAdminPermissions) {
          this.accountsService.updateUserFolders(user.id, folders).then(
            success => this.close(user),
            error => this.handleErrorWhileSaving(error)
          );
        } else {
          this.close(user);
        }
      },
      error => {
        this.handleErrorWhileSaving(error, false, 'An unknown error occurred and the user could not be saved');
      }
    );
  }

  private showSnackbar(message: string) {
    this.snackbarService.openErrorSnackbar(message);
  }

  private handleErrorWhileSaving(error, close?: boolean, customMessage?: string) {
    console.error(error);
    if (close) this.close();
    this.saving = false;
    this.showSnackbar(customMessage || 'An error occurred while saving');
  }

  getPermissionNumber(userPermission: string|number): number {
    return typeof userPermission === 'number'
      ? userPermission
      : UserPermissions.find(permission => permission.value === userPermission).number;
  }

  getPermissionString(userPermission: string|number): string {
    return typeof userPermission === 'string'
      ? userPermission
      : UserPermissions.find(permission => permission.number === userPermission).value;
  }

  checkIfAdminPortal(): boolean {
    const route = this.router.url;
    return startsWith(route, AdminPortalUrlBuilders.base());
  }

  close(data?: any): void {
    this.saving = false;
    this.dialogRef.close(data);
  }

  get profileFormGroup(): FormGroup<IUserProfileForm> {
    return this.userForm.get('profile') as FormGroup<IUserProfileForm>;
  }

  get permissionsFormGroup(): UntypedFormGroup {
    return this.userForm.get('permissions') as UntypedFormGroup;
  }

  get folderAccessFormGroup(): UntypedFormGroup {
    return this.userForm.get('folderAccess') as UntypedFormGroup;
  }

  get notificationsFormGroup(): UntypedFormGroup {
    return this.userForm.get('notifications') as UntypedFormGroup;
  }

  get firstName(): FormControl<string> {
    return (this.profileFormGroup.get('firstName') as FormControl<string | null>);
  }

  get lastName(): FormControl<string> {
    return (this.profileFormGroup.get('lastName') as FormControl<string | null>);
  }

  get email(): FormControl<string> {
    return (this.profileFormGroup.get('email') as FormControl<string | null>);
  }

  get username(): FormControl<string> {
    return (this.profileFormGroup.get('username') as FormControl<string | null>);
  }

  get password(): FormControl<string> {
    return (this.profileFormGroup.get('password') as FormControl<string | null>);
  }

  get currentPassword(): FormControl<string> {
    return (this.profileFormGroup.get('currentPassword') as FormControl<string | null>);
  }

  get newPassword(): FormControl<string> {
    return (this.profileFormGroup.get('newPassword') as FormControl<string | null>);
  }

  get newPasswordConfirm(): FormControl<string> {
    return (this.profileFormGroup.get('newPasswordConfirm') as FormControl<string | null>);
  }

  get timeZone(): FormControl<string> {
    return (this.profileFormGroup.get('timeZone') as FormControl<string | null>);
  }

  get permissionLevel(): FormControl<string> {
    return (this.permissionsFormGroup.get('permissionLevel') as FormControl<string | null>);
  }

  get auditPageLimit(): FormControl<number> {
    return (this.permissionsFormGroup.get('auditPageLimit') as FormControl<number | null>);
  }

  get usageDashboardAccess(): FormControl<boolean> {
    return (this.permissionsFormGroup.get('usageDashboardAccess') as FormControl<boolean | null>);
  }

  get accountStatus(): FormControl<boolean> {
    return (this.permissionsFormGroup.get('accountStatus') as FormControl<boolean | null>);
  }

  get selectedFolders(): FormControl<any[]> {
    return (this.folderAccessFormGroup.get('items') as FormControl<any[] | null>);
  }
}