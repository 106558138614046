import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

/**
 * NumberFormatPipe
 *
 * This pipe provides flexible number formatting with two options:
 * 1. Truncation: Removes decimal places without rounding
 * 2. Decimal formatting: Uses Angular's DecimalPipe for precise control over decimal places
 *
 * Usage in template:
 * {{ value | numberFormat: digitsInfo : truncate }}
 *
 * @param value: The number to format
 * @param digitsInfo: A string that defines the number of decimal places (e.g., '1.0-2' for 1 to 2 decimal places)
 * @param truncate: A boolean flag. If true, decimals are removed without rounding
 *
 * Priority:
 * 1. If the value is null or undefined, an empty string is returned
 * 2. If truncate is true, decimals are removed regardless of digitsInfo
 * 3. If truncate is false or not provided, digitsInfo is used for formatting
 * 4. If neither truncate nor digitsInfo are provided, default formatting of 3 decimal places is used
 */
@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | null | undefined, digitsInfo?: string, truncate?: boolean): string {
    if (value == null) {
      return '';
    }

    if (truncate) {
      return Math.trunc(value).toString();
    }

    return this.decimalPipe.transform(value, digitsInfo || '1.0-3') || '';
  }
}