import { ActionSetLibraryModule } from '@app/components/action-set-library/action-set-library.module';
import { MainComponent } from './components/main/main.component';
import { CookieService } from 'ngx-cookie-service';
import { NgModule, ErrorHandler, APP_INITIALIZER, DoBootstrap } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';
import { CreatorNgModule } from '@app/components/creator/creatorModule';
import { NavigationNgModule } from '@app/components/navigation/navigationModule';
import { DomainsNgModule } from '@app/components/domains/domainsModule';
import { CoreModule } from '@app/components/core/core.module';
import appWrapper from './moonbeam';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './components/shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { AuthRequestInterceptor } from '@app/components/core/interceptors/auth-request.interceptor';
import { GlobalErrorHandler } from './components/core/services/global-error-handler.service';
import * as Sentry from '@sentry/angular';
import { LoginModule } from './components/login/login.module';
import { defaultModalConfigsProvider } from '@app/components/shared/components/op-modal';
import { AuditNgModule } from '@app/components/audit/audit.module';
import { AuditsPickerModule } from './components/audits-picker/audits-picker.module';
import { DragulaModule } from 'ng2-dragula';
import { SimpleRuleCreatorModule } from './components/rules/simple-rule-creator/simple-rule-creator.module';
import { StatusBannerModule } from './components/status-banner/status-banner.module';
import { AppInitService } from './components/core/services/app-init.service';
import { ReprocessComparisonsModalModule } from '@app/components/reprocess-comparisons-modal/reprocess-comparisons-modal.module';
import { OpDeleteItemWarningModule } from './components/shared/components/op-delete-item-warning/op-delete-item-warning.module';
import { TopBarModule } from './components/navigation/top-bar/top-bar.module';
import { AppComponent } from './components/app/app.component';
import { SidebarModule } from './components/navigation/sidebar/sidebar.module';
import { ModalSidebarModule } from './components/navigation/modal-sidebar/modal-sidebar.module';
import { AuthResponseInterceptor } from './components/core/interceptors/auth-response.interceptor';
import { TerminateActiveRunsModalModule } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.module';
import { ReprocessConfirmationSnackbarModule } from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.module';
import { InvalidUrlsSnackbarModule } from '@app/components/invalid-urls-snackbar/invalid-urls-snackbar.module';
import { UpgradeUserBannerModule } from '@app/components/upgrade-user-banner/upgrade-user-banner.module';
import { AuditReportFilterBarService } from './components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from './environments/environment';
import { Router } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';

import 'ag-grid-enterprise';



export function initApp(appInitService: AppInitService) {
  return () => appInitService.onAppInit();
}

function setUpGlobalExceptionHandler() {
  return environment.sentryErrorConfig
    ? Sentry.createErrorHandler(environment.sentryErrorConfig)
    : new GlobalErrorHandler();
}

/**
 * Main App Module.
 */
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    UpgradeModule,
    AppRoutingModule,
    SharedModule,
    DragulaModule.forRoot(),
    DomainsNgModule,
    CreatorNgModule,
    NavigationNgModule,
    LoginModule,
    AuditNgModule,
    AuditsPickerModule,
    SimpleRuleCreatorModule,
    ReprocessComparisonsModalModule,
    ReprocessConfirmationSnackbarModule,
    InvalidUrlsSnackbarModule,
    ModalSidebarModule,
    StatusBannerModule,
    OpDeleteItemWarningModule,
    TopBarModule,
    SidebarModule,
    TerminateActiveRunsModalModule,
    ActionSetLibraryModule,
    UpgradeUserBannerModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    AgGridModule,
    HttpClientJsonpModule,
  ],
  providers: [
    Title,
    CookieService,
    AppInitService,
    AuditReportFilterBarService,
    // default Angular Material modal's config
    defaultModalConfigsProvider,
    // Http request / response interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthResponseInterceptor, multi: true },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: ErrorHandler, useFactory: setUpGlobalExceptionHandler },
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [AppInitService, Sentry.TraceService], multi: true }
  ],
  declarations: [
    MainComponent,
    AppComponent
  ]
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule,
  ) {
  }

  ngDoBootstrap(app) {
    try {
      Sentry.addBreadcrumb({
        category: 'load',
        message: 'AngularJs bootstrap',
        level: 'info'
      });
    } catch (e) { }
    this.upgrade.bootstrap(document.body, [appWrapper.name]);
    app.bootstrap(MainComponent);
  }
}
