import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ENameType, ICCEditParams, IConsentCategoryCookie, INewCookie } from '../../consent-categories.models';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { Subject } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { AddNewCookieComponent } from './add-new-cookie/add-new-cookie.component';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { ECCEditTabs } from '../cc-edit.constants';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from '@app/components/shared/services/storage.service';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ConsentCategoriesEditCookiesComponent),
  multi: true
};

const CCEditCookiesTablePageSizeStorageKey = 'cc-edit-cookies-table-page-size';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-edit-cookies',
  templateUrl: './cc-cookies.component.html',
  styleUrls: ['./cc-cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FORM_CONTROL_VALUE_ACCESSOR]
})
export class ConsentCategoriesEditCookiesComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  filterChanged$ = new Subject<string>();
  ccId: number;
  dataSource = new MatTableDataSource<IConsentCategoryCookie>();
  displayedColumns = ['name', 'nameCheckboxes', 'domain', 'domainRegEx',  'delete'];
  consentCategoryId: number;
  loading: boolean = true;
  cookieForm: UntypedFormGroup;
  filterValue: string;
  pageSizeOptions = [10, 25, 50, 100, 500];
  minCookiesToShowPaginator = this.pageSizeOptions[0];

  private destroy$ = new Subject();

  onChange = (value: any) => {};
  onTouched = () => {};

  @Output() tabDataCount: EventEmitter<{tabName: string, newTabCount: number}> = new EventEmitter();
  @Output() formTouched: EventEmitter<ECCEditTabs> = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private modalService: OpModalService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private modalEscapeService: ModalEscapeService,
    private dateService: DateService,
    private cd: ChangeDetectorRef,
    private storage: StorageService,
  ) {
    this.route.params.subscribe((params: ICCEditParams) => {
      this.consentCategoryId = params.id;
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngAfterViewInit(): void {
    this.initTable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  initForm(): void {
    this.cookieForm = this.formBuilder.group({
      cookies: this.formBuilder.array([])
    });

    this.cookieForm
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (this.cookieForm.pristine === false) {
          this.formTouched.emit(ECCEditTabs.cookies);
        }
        this.onChange(this.cookies.value);
      });
  }

  getActualIndex(cookie: IConsentCategoryCookie): number {
    return this.dataSource.data.indexOf(cookie);
  }

  initTable(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      return typeof item[property] === 'string'
        ? item[property]?.toLocaleLowerCase()
        : item[property];
    };
    this.dataSource.data = this.alphaSort('name', [ ...this.cookies.value ]);

    this.cd.detectChanges();
    this.matchFormArrayWithTable();

    this.loading = false;
  }

  updateDataSource(alpha?: boolean): void {
    setTimeout(() => {
      const formArrayValueCopy = [ ...this.cookies.getRawValue()];
      this.dataSource.data = alpha
        ? this.alphaSort('name', formArrayValueCopy)
        : formArrayValueCopy;

      this.matchFormArrayWithTable();
      this.updateCookiesTabCount();
      this.formTouched.emit(ECCEditTabs.cookies);
    }, 200);
  }

  matchFormArrayWithTable(): void {
    // update the form array so the table will properly sort
    this.cookies.setValue(this.dataSource.data);
    this.dataSource.data.forEach((cookie, index) => {
      const group = this.cookies.at(index);
      if (cookie.nameMatchAny) {
        this.handleNameMatchAnyChecked(group.get('nameRegex'), group.get('nameMatchAny'), group.get('name'));
      } else if (cookie.nameRegex) {
        this.handleNameRegexChecked(group.get('nameMatchAny'), group.get('nameRegex'));
      }
    });
  }

  alphaSort(prop: string, arr: any[]): any[] {
    return arr.sort((a: any, b: any) => (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) ? 1 : -1);
  }

  applyFilter(event: KeyboardEvent): void {
    this.filterValue = (event.target as HTMLInputElement)?.value?.trim().toLowerCase() || '';
    this.dataSource.filter = this.filterValue;
  }

  savePageSizeToStorage(e: PageEvent): void {
    this.storage.setValue(CCEditCookiesTablePageSizeStorageKey, e.pageSize);
  }

  get perPage(): number {
    return this.storage.getValue(CCEditCookiesTablePageSizeStorageKey) || this.pageSizeOptions[2];
  }

  openAddNewCookieModal(): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openModal(AddNewCookieComponent, {})
    .afterClosed()
    .subscribe((cookie: INewCookie) => {
      if (cookie) {
        this.createNewCookie(cookie);
        this.updateDataSource(true);
      }

      this.modalEscapeService.remove(index);
    });
  }

  copyCookie(cookie: IConsentCategoryCookie): void {
    const newCookie = {
      name: (cookie.name || '') + ' copied ' + this.dateService.formatDate(new Date(), EDateFormats.dateTwo),
      nameRegex: cookie.nameRegex,
      nameMatchAny: cookie.nameMatchAny,
      domain: cookie.domain || '',
      domainRegex: cookie.domainRegex
    };
    this.createNewCookie(newCookie);
    this.updateDataSource(true);
  }

  deleteCookie(cookie: IConsentCategoryCookie): void {
    const indexToRemove = this.cookies.value.findIndex((val: IConsentCategoryCookie) => val.index === cookie.index);
    this.cookies.removeAt(indexToRemove);
    this.updateDataSource();
  }

  openConfirmDeleteAllCookies(): void {
    const rightFooterButtons = [
      {
        label: 'Cancel',
        action: () => {},
        primary: false
      },
      {
        label: 'Delete All Cookies',
        action: () => {
          this.deleteAllCookies();
        },
        primary: true
      }
    ];

    this.modalService
      .openConfirmModal({
        data: {
          title: 'Delete all cookies?',
          messages: ['Are you sure you want to delete all cookies from this consent category?'],
          rightFooterButtons
        }
      });
  }

  deleteAllCookies(): void {
    this.cookies.clear();
    this.updateDataSource();
  }

  writeValue(value: IConsentCategoryCookie[] | UntypedFormControl): void {
    this.cookies.clear();

    const cookies = Array.isArray(value)
      ? value
      : value.value.cookies;

    cookies.forEach((cookie: IConsentCategoryCookie, index: number) => {
      this.createNewCookie(cookie, index);
    });
  }

  createNewCookie(cookie: IConsentCategoryCookie | INewCookie, index?: number): void {
    this.cookies.push(this.formBuilder.group({
      name: cookie.name || '',
      nameRegex: cookie.nameRegex,
      nameMatchAny: cookie.nameMatchAny,
      domain: cookie.domain || '',
      domainRegex: cookie.domainRegex,
      index: index ? index : this.cookies.length
    }));
  }

  updateCookiesTabCount() {
    this.tabDataCount.emit({tabName: ECCEditTabs.cookies, newTabCount: this.cookies.value.length});
  }

  onNameMatchAnyChanged(checked: boolean, cookie: IConsentCategoryCookie, cookieIndex: number): void {
    cookie.nameMatchAny = checked;
    const cookieFormGroup = this.cookies.at(cookieIndex);
    const nameMatchAnyFormControl = cookieFormGroup.get('nameMatchAny');
    const nameRegexFormControl = cookieFormGroup.get('nameRegex');
    const nameFormControl = cookieFormGroup.get('name');
    if (checked) {
      cookie.nameType = ENameType.ANY;
      this.handleNameMatchAnyChecked(nameRegexFormControl, nameMatchAnyFormControl, nameFormControl);
    } else {
      cookie.nameType = ENameType.EXACT;
      nameRegexFormControl.enable();
      nameFormControl.patchValue('');
      nameFormControl.enable();
    }
  }

  private handleNameMatchAnyChecked(nameRegexFormControl: AbstractControl<boolean>,
                                    nameMatchAnyFormControl: AbstractControl<boolean>,
                                    nameFormControl: AbstractControl<string>) {
    nameMatchAnyFormControl.patchValue(true);
    nameMatchAnyFormControl.enable();
    nameRegexFormControl.disable();
    nameRegexFormControl.patchValue(false);
    nameFormControl.disable();
    nameFormControl.patchValue('');
  }

  onNameRegexChanged(checked: boolean, cookie: IConsentCategoryCookie, cookieIndex: number): void {
    cookie.nameRegex = checked;
    let cookieFormGroup;

    if (this.filterValue?.length > 0) {
      cookieFormGroup = this.cookies.controls.find(control => {
        return cookie.name === control.get('name').value && cookie.domain === control.get('domain').value;
      });
    } else {
      cookieFormGroup = this.cookies.at(cookieIndex);
    }

    const nameMatchAnyFormControl = cookieFormGroup?.get('nameMatchAny');
    const nameRegexFormControl = cookieFormGroup?.get('nameRegex');

    // ensure we have the form controls so we don't throw an error
    if (nameMatchAnyFormControl || nameRegexFormControl) {
      if (checked) {
        cookie.nameType = ENameType.REGEX;
        this.handleNameRegexChecked(nameMatchAnyFormControl, nameRegexFormControl);
      } else {
        cookie.nameType = ENameType.EXACT;
        nameMatchAnyFormControl.enable();
      }
    }
  }

  private handleNameRegexChecked(nameMatchAnyFormControl: AbstractControl<boolean>,
                                 nameRegexFormControl: AbstractControl<boolean>) {
    nameRegexFormControl.patchValue(true);
    nameRegexFormControl.enable();
    nameMatchAnyFormControl.patchValue(false);
    nameMatchAnyFormControl.disable();
  }

  get cookies(): UntypedFormArray {
    return this.cookieForm.get('cookies') as UntypedFormArray;
  }
}
