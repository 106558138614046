import { EMPTY, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { StringUtils } from '@app/components/utilities/StringUtils';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import {
  CookiesPrivacyCookieComplianceExportMenuData,
  ICookiePrivacyComplianceItem,
  ICookiePrivacySummary,
  ICookiesPrivacyComplianceTableState,
} from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.models';
import {
  EBarChartDirection,
  EBarChartTextPosition,
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.constants';
import {
  AuditReportUrlBuilders,
  EChartColor,
  EConsentCategoryComplianceStatus,
  formatPaginator,
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  ICookiePrivacyComplianceRow,
} from '@app/components/audit-reports/reports/privacy-cookies/components/privacy-cookies-compliance-table/privacy-cookies-compliance-table.models';
import { getPercent } from '@app/components/utilities/number.utils';
import {
  AuditReportFilterBarService,
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import {
  EConsentCategoryType,
  IConsentCategory,
  IConsentCategorySnapshot,
} from '@app/components/consent-categories/consent-categories.models';
import { DecimalPipe } from '@angular/common';
import { AccountsService } from '@app/components/account/account.service';
import {
  EFreeTrialAdModalType,
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import {
  FreeTrialAdModalComponent,
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { Router } from '@angular/router';
import { IAuditEditorModalData } from '@app/components/audit/audit-editor/audit-editor.models';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { CookiesListTableColumns } from './privacy-cookies-compliance-table.enums';
import { CookiesTableColumns, PrivacyCookiesConfigLocalStorageKey } from './privacy-cookies-compliance-table.constants';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { EPrivacyCookiesExportType } from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.enums';
import { PrivacyCookiesService } from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.service';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { getCookieOriginData } from '@app/components/audit-reports/reports/general-reports.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'privacy-cookies-compliance-table',
  templateUrl: './privacy-cookies-compliance-table.component.html',
  styleUrls: ['./privacy-cookies-compliance-table.component.scss'],
  providers: [ResizeableTableService],
})
export class PrivacyCookiesComplianceTableComponent
  implements OnInit, OnDestroy {
  readonly EConsentCategoryType = EConsentCategoryType;
  readonly CookiesListTableColumns = CookiesListTableColumns;

  readonly PrivacyCookiesConfigLocalStorageKey = PrivacyCookiesConfigLocalStorageKey;
  readonly CookiesTableColumns = CookiesTableColumns;
  ccActionMenuOpen: boolean = false;
  statusActionMenuOpen: boolean = false;
  ccActionIndex = null;

  @Input() auditId: number;
  @Input() runId: number;
  @Input() spinnerState: EFilterSpinnerState;
  @Input() auditConsentCategories: IConsentCategory[];
  @Input() runConsentCategories: IConsentCategorySnapshot[];
  @Input() getTagNameByTagId: (tagId: number) => string;
  @Input() filteredPageCount: number;
  @Input() filteredWidget: EConsentCategoryComplianceStatus;

  @Output() onCookieClicked = new EventEmitter<ICookiePrivacyComplianceItem>();
  @Output() onAddToConsentCategoryClicked = new EventEmitter<ICookiePrivacyComplianceRow>();
  @Output() onEditToConsentCategoryClicked = new EventEmitter<IConsentCategorySnapshot>();
  @Output() onSetConsentCategoryNameGlobalFilterClicked = new EventEmitter<ICookiePrivacyComplianceRow>();
  @Output() onManageConsentCategories = new EventEmitter<void>();

  @ViewChild(MatSort, { static: true }) tableSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) tablePaginator: MatPaginator;

  readonly EFilterSpinnerState = EFilterSpinnerState;
  private isVisitorMode: boolean;
  private destroySubject = new Subject<void>();

  private apiFilters: IAuditReportApiPostBody = {};

  tableDataSource = new MatTableDataSource<ICookiePrivacyComplianceRow>();
  displayedColumns$ = this.tableService.displayedColumns$;
  selectedCookie: ICookiePrivacyComplianceItem;

  selectedRow: ICookiePrivacyComplianceItem;
  isFiltered = false;
  userIsReadOnly: boolean;

  tableState: ICookiesPrivacyComplianceTableState = {
    sort: { sortBy: 'compliance_status', sortDesc: true },
    pagination: { size: 100, page: 0 },
    pagesTotal: 0,
  };

  exportConfig: CookiesPrivacyCookieComplianceExportMenuData = {
    tableName: 'Cookie Compliance',
    exportType: EPrivacyCookiesExportType.cookieCompliance,
    totalRows: 0,
    filteredRows: 0,
    tableState: {
      ...this.tableState.pagination,
      ...this.tableState.sort,
    },
    filters: this.apiFilters,
  };

  constructor(
    private filterBarService: AuditReportFilterBarService,
    private decimalPipe: DecimalPipe,
    private accountsService: AccountsService,
    private modalService: OpModalService,
    private applicationChromeService: ApplicationChromeService,
    private router: Router,
    private tableService: ResizeableTableService,
    private cookiePrivacyService: PrivacyCookiesService,
    private snackbarService: SnackbarService,
    private auditReportLoadingService: AuditReportLoadingService,
  ) {
    this.userIsReadOnly = this.accountsService.userIsReadOnly();

    this.applicationChromeService.isVisitorMode$
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: isVisitorMode => this.isVisitorMode = isVisitorMode,
      });
  }

  ngOnInit(): void {
    this.formatPaginator();
    this.initFilters();

    this.tablePaginator.page.subscribe((pagination: PageEvent) => {
      this.tableState.pagination.page = pagination.pageIndex;
      this.loadComplianceTableData();
    });

    this.tableSort
      .sortChange
      .subscribe((sort: Sort) => {
        if (sort.active === 'filteredPageCount') {
          sort.active = 'pageCount';
        }

        if (sort.active === 'consentCategoryComplianceStatus') {
          sort.active = 'complianceStatus';
        }

        this.tableState.sort.sortBy = StringUtils.camelCaseToSnakeCase(sort.active) as any;
        this.tableState.sort.sortDesc = sort.direction === 'desc';
        this.tableState.pagination.page = 0;
        this.loadComplianceTableData();
      });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private onFiltersChanged(apiPostBody: IAuditReportApiPostBody): void {
    this.apiFilters = apiPostBody;
    this.tableState.pagination.page = 0;
    this.selectedCookie = null;

    this.loadWidgets();
    this.loadComplianceTableData();
  }

  private loadWidgets() {
    this.cookiePrivacyService
      .getCookiesPrivacySummary(this.auditId, this.runId, this.totalFilters())
      .pipe(catchError(() => EMPTY))
      .subscribe((data: ICookiePrivacySummary | undefined) => {
        if (data) {
          this.updateExportConfig(data);
        } else {
          console.error('Sorry! Some elements failed to update. Refresh your browser to try again.');
        }
      });
  }

  private updateExportConfig(summary: ICookiePrivacySummary): void {
    this.exportConfig = {
      ...this.exportConfig,
      totalRows: summary.totalUniqueCookieCount > 0 ? Infinity : 0,
    };
  }

  private initFilters(): void {
    this.filterBarService
      .apiPostBody$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  loadComplianceTableData(): void {
    this.spinnerState = EFilterSpinnerState.Loading;
    this.cookiePrivacyService
      .getCookiePrivacyCompliance(this.auditId, this.runId, this.tableState, this.totalFilters())
      .subscribe(
        ({ cookies, metadata }) => {
          this.handleCookies(cookies);
          this.tableState.pagesTotal = metadata.pagination.totalCount;
          this.spinnerState = this.calcSpinnerState();

          this.exportConfig = {
            ...this.exportConfig,
            filters: this.apiFilters,
            filteredRows: metadata.pagination.totalCount,
            tableState: {
              ...this.tableState.pagination,
              ...this.tableState.sort,
            },
          };
        },
        this.handleApiError('Failed to load privacy cookies.'),
        this.handleApiComplete(() => {
            this.spinnerState = this.calcSpinnerState();
          },
        ),
      );
  }

  private totalFilters() {
    return {
      ...this.apiFilters,
    };
  }

  private calcSpinnerState(): EFilterSpinnerState {
    return this.filterBarService.currentFilters.length || Object.keys(this.apiFilters).length || this.filteredWidget || this.selectedCookie
           ? EFilterSpinnerState.Filtered
           : EFilterSpinnerState.None;
  }

  private errorToast(message: string) {
    this.snackbarService.openErrorSnackbar(message, {
      duration: 10000,
    });
  }

  private handleApiError = (message: string, handler?: (error: any) => void) => {
    return error => {
      handler && handler(error);
      console.error(error);
      this.errorToast(message);
    };
  };

  private handleApiComplete = (handler?: () => void) => {
    return () => {
      handler && handler();
      this.auditReportLoadingService.removeLoadingToken();
    };
  };

  private handleCookies(inputData: ICookiePrivacyComplianceItem[]) {
    if (inputData && Array.isArray(inputData)) {
      this.tableDataSource.data = inputData.map(item => ({
        ...item,
        origin: getCookieOriginData(item.cookieChangedDuring),
        barChartSettings: {
          state: EFilterSpinnerState.None,
          displayPills: false,
          calcAsPercentage: true,
          displayPercentSymbol: false,
          textPosition: EBarChartTextPosition.Start,
        },
        onPages: {
          chartData: [
            {
              name: `${item.cookieName}`,
              colorClass: item.consentCategoryComplianceStatus ? item.consentCategoryComplianceStatus === 'approved' ? EChartColor.Green : EChartColor.Red : EChartColor.OPYellow,
              filtered: false,
              value: getPercent(item.filteredPageCount, this.filteredPageCount),
              displayValue: item.filteredPageCount,
            },
          ],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `cookie-${1 | Math.random() * 16e6}-chart`,
        },
      }));
    }
  }

  selectRow(selectedCookie: ICookiePrivacyComplianceItem): void {
    if (this.selectedRow === selectedCookie) {
      this.selectedRow = null;
      this.isFiltered = false;
    } else {
      this.selectedRow = selectedCookie;
      this.isFiltered = true;
    }

    this.onCookieClicked.emit(selectedCookie);
    this.selectedCookie = (this.selectedCookie === selectedCookie) ? null : selectedCookie;
  }

  setConsentCategoryNameGlobalFilter(row: ICookiePrivacyComplianceRow) {
    this.onSetConsentCategoryNameGlobalFilterClicked.emit(row);
  }

  setConsentCategoryTypeGlobalFilter(name: string) {
    this.filterBarService.addConsentCategoryStatusFilter(name);
  }

  setCookieNameGlobalFilter(name: string) {
    this.filterBarService.addCookieNameFilter(name);
  }

  addToConsentCategory(row: ICookiePrivacyComplianceRow, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    this.onAddToConsentCategoryClicked.emit(row);
  }

  private openFreeTrialAdModal() {
    const data = {
      type: EFreeTrialAdModalType.CONSENT_CATEGORY,
    };

    this.modalService.openModal(FreeTrialAdModalComponent, { data });
  }

  getConsentCategoryFromAuditById(id: number) {
    return this.auditConsentCategories?.find(cc => cc.id === id);
  }

  getTooltip(row: ICookiePrivacyComplianceRow) {
    const consentCategoryId = this.getConsentCategoryFromRun(row)?.consentCategoryId;

    if (!consentCategoryId) {
      return 'This Consent Category has been deleted and cannot be edited.';
    } else if (!this.getConsentCategoryFromAuditById(consentCategoryId)) {
      return 'This Consent Category is no longer applied to future runs of this audit. Edit it from the Consent Category management screen.';
    }
  }

  private getConsentCategoryFromRun(row: ICookiePrivacyComplianceRow) {
    return this.runConsentCategories.find(cc => cc.consentCategorySnapshotId === row.consentCategorySnapshotId);
  }

  isLineSelected(row: ICookiePrivacyComplianceRow) {
    return this.selectedRow === row;
  }

  private formatPaginator(): void {
    this.tablePaginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }

  handleSetFilterAndNavigate(name: string): void {
    this.setCookieNameGlobalFilter(name);
    this.router.navigateByUrl(AuditReportUrlBuilders.cookieInventory(this.auditId, this.runId));
  }

  openAuditEditorToConsentCategoryStandard(event): void {
    event.stopPropagation();

    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      const data: IAuditEditorModalData = {
        auditId: this.auditId,
        runId: this.runId,
        step: 2,
        standardsTab: EStandardsTabs.ConsentCategories,
        disableNavigationButtons: true,
      };

      this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor');
    }
  }
}
