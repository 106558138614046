import { Component, EventEmitter, Input, OnDestroy, Output, SimpleChanges, OnInit } from '@angular/core';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'op-label-manager',
  templateUrl: './op-label-manager.component.html',
  styleUrls: ['./op-label-manager.component.scss']
})
export class OpLabelManagerComponent implements OnDestroy, OnInit {

  /**
   * **************************
   * README
   * **************************
   *
   * this component will:
   *  - make API calls to create new labels
   *  - make API calls to edit existing labels
   *
   * consumer needs to:
   *  - provide selected labels for item (card, consent category, rule, etc.)
   *  - add newly created labels to item
   *  - replace labels on item
   *  - remove labels from item
   */

  allLabels: ILabel[];

  @Input() compactView: boolean = false;
  @Input() chipCountOnly: boolean = false;
  @Input() selectedLabels: ILabel[] = [];
  @Input() numberOfRows: number = 1; // number of rows available to display chips
  @Input() isReadOnly: boolean = false;

  @Output() onLabelCreated: EventEmitter<ILabel> = new EventEmitter();
  @Output() onLabelSelected: EventEmitter<ILabel> = new EventEmitter();
  @Output() onLabelRemoved: EventEmitter<ILabel> = new EventEmitter();
  @Output() addingLabel: EventEmitter<boolean> = new EventEmitter();

  destroy$ = new Subject();
  constructor(
    private labelService: LabelService
  ) {}

  ngOnInit(): void {
    this.labelService.allLabels$.pipe(takeUntil(this.destroy$)).subscribe((labels: ILabel[]) => {
      this.allLabels = labels;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createNewLabel(name: string): void {
    const labelName = name.trim();

    // if blank then update selectedLabels to only
    // include existing labels (they have an ID)
    if (labelName === '') {
      this.selectedLabels = this.selectedLabels.filter((label: ILabel) => label.id);
      return;
    }

    let existingLabel;
    for (let [key, value] of this.labelService.labelMap) {
      if (value.name === name) {
        existingLabel = value;
        break;
      }
    }

    // if label already exists then just optimistically add and emit
    if (existingLabel) {
      this.handleLabelSelected(existingLabel);
      return;
    }

    this.optimisticallyAddLabel(labelName);

    // create new label then emit to the consumer
    this.labelService.createLabel(labelName).subscribe((label: ILabel) => {
      this.updateSelectedLabelsAfterOptimisticAdd(label);
      this.onLabelCreated.emit(label);
    });
  }

  handleLabelSelected(label: ILabel): void {
    this.optimisticallyAddLabel(label);
    this.onLabelSelected.emit(label);
  }

  removeLabel(removedLabel: ILabel): void {
    this.optimisticallyRemoveLabel(removedLabel);
    this.onLabelRemoved.emit(removedLabel);
  }

  private optimisticallyAddLabel(label: string | ILabel): void {
    const newLabel = typeof label === 'string'
      ? { id: null, name: label }
      : label;

    // we add the new label immediately to avoid latency from the API –
    // the consumer can update the selected labels if this isn't wanted
    this.selectedLabels.push(newLabel);
    this.selectedLabels = [ ...this.selectedLabels ];
  }

  private optimisticallyRemoveLabel(removedLabel: ILabel): void {
    this.selectedLabels = this.selectedLabels.filter((label: ILabel) => label?.id !== removedLabel?.id);
  }

  private updateSelectedLabelsAfterOptimisticAdd(label: ILabel): void {
    this.selectedLabels = this.selectedLabels.filter((label: ILabel) => label.id);
    this.selectedLabels.push(label);
    this.selectedLabels = [ ...this.selectedLabels ];
  }
}
