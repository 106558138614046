import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPassedRuleReport } from '@app/components/reporting/rules/rule.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'passed-rules-report',
  templateUrl: './passed-rules-report.component.html',
  styleUrls: ['./passed-rules-report.component.scss']
})
export class PassedRulesReportComponent {

  @Input() reports: Array<IPassedRuleReport>;

  @Input() showEditButtons: boolean = true;
  
  @Output() removeRule: EventEmitter<number> = new EventEmitter();
  @Output() editRule: EventEmitter<number> = new EventEmitter();


  handleEdit(event: MouseEvent, ruleId: number) {
    event.stopPropagation();
    this.editRule.emit(ruleId)
  }

  handleRemove(event: MouseEvent, ruleId: number) {
    event.stopPropagation();
    this.removeRule.emit(ruleId)
  }
}
