<mat-spinner *ngIf="showSpinner" [diameter]="100" [strokeWidth]="4" class="centered"></mat-spinner>

<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header  [title]="title"
                      [steps]="steps"
                      [currentStep]="currentStep"
                      (goToStep)="goToStep($event)"
                      (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <form class="flex-col flex-stretch" [formGroup]="comparisonForm">
      <one-time-comparison-setup [class.hidden]="currentStep !== comparisonSteps.SETUP"
                                  [accountTagsSubject]="accountTagsSubject"
                                  [comparison]="comparison"
                                  [formGroup]="comparisonForm.get('setup')"
                                  [submitted]="submitted">
      </one-time-comparison-setup>

      <one-time-comparison-match-tags [class.hidden]="currentStep !== comparisonSteps.MATCH_TAGS"
                                      [formGroup]="comparisonForm.get('matchTags')">
      </one-time-comparison-match-tags>
    </form>
  </ng-template>

</op-modal>
  
