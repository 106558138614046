import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';

import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-primary-tag-modal',
  templateUrl: './add-primary-tag-modal.component.html',
  styleUrls: ['./add-primary-tag-modal.component.scss']
})
export class AddPrimaryTagModalComponent {

  selectedTag: IUiTag;

  rightFooterButtons: IButton[] = [{
    label: 'Add Tag',
    action: () => this.saveTag(),
    primary: true,
    disabled: true,
    opSelector: 'add-primary-tag-button',
  }];

  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public payload: { accountTags: IUiTag[], userIsAdmin: boolean }
  ) {}

  handleTagSelected(tag: IUiTag): void {
    this.selectedTag = tag;
    this.rightFooterButtons[0].disabled = false;
  }

  saveTag(): void {
    this.closeModal(this.selectedTag);
  }

  closeModal(tag?: IUiTag): void {
    this.dialogRef.close(tag);
  }
}
