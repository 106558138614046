import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { PrivacyTagsService } from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.service';
import { PrivacyCookiesService } from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.service';
import { PrivacyRequestsService } from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { EConsentCategoryComplianceStatus } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { AuditReportFilterBarService } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { finalize } from 'rxjs/operators';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import {
  IConsentCategories,
  IConsentCategorySnapshot
} from '@app/components/consent-categories/consent-categories.models';
import { ECCEditTabs } from '@app/components/consent-categories/cc-edit/cc-edit.constants';
import { PrivacyWidgetsLoadingStateHandler } from '@app/components/audit-reports/reports/audit-summary/privacy-section-in-audit-summary/loading-state-handler';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'privacy-section-in-audit-summary',
  templateUrl: './privacy-section-in-audit-summary.component.html',
  styleUrls: ['./privacy-section-in-audit-summary.component.scss']
})
export class PrivacySectionInAuditSummaryComponent extends PrivacyWidgetsLoadingStateHandler implements OnChanges, OnDestroy {

  @Input() auditId: number;
  @Input() runId: number;
  @Input() apiFilters: IAuditReportApiPostBody;

  EConsentCategoryComplianceStatus = EConsentCategoryComplianceStatus;

  //------ Consent Categories
  isLoadedAuditConsentCategories = false;
  isLoadedRunConsentCategories = false;
  auditConsentCategories: IConsentCategories[] = [];
  ccsAssignedToRun: IConsentCategorySnapshot[] = [];
  categoriesType: ECCEditTabs = ECCEditTabs.cookies;

  constructor(
    private ccService: ConsentCategoriesService,
    private tagPrivacyService: PrivacyTagsService,
    private cookiePrivacyService: PrivacyCookiesService,
    private privacyRequestsService: PrivacyRequestsService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private modalService: OpModalService,
    private auditReportLoadingService: AuditReportLoadingService,
  ) {
    super(auditReportLoadingService, filterBarService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let newAuditId = changes?.auditId?.currentValue;
    let newRunId = changes?.runId?.currentValue;
    if (newAuditId || newRunId) {
      this.auditId = newAuditId || this.auditId;
      this.runId = newRunId || this.runId;
      this.loadCCs();
    }
  }

  private loadCCs() {
    this.startLoading();
    this.ccService
      .getConsentCategoriesAssignedToRun(this.auditId, this.runId)
      .subscribe(ccs => {
        this.ccsAssignedToRun = ccs;
        this.isLoadedRunConsentCategories = true;
      });
    this.ccService
      .getConsentCategoriesAssignedToAudit(this.auditId)
      .pipe(finalize(() => {
        this.stopLoading();
      }))
      .subscribe(ccs => {
        this.auditConsentCategories = ccs;
        this.isLoadedAuditConsentCategories = true;
      });
  }

  isFiltered(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
