  <div class="table-header sticky-header">
    <span class="tag-name-header">Tag Name</span>
    <span class="tag-details-header">
      <span class="flex-stretch">Account</span>
      <span class="flex-col flex-stretch">
        <ng-container *ngIf="previousRun">
          <div class="run-number">Previous Run:</div>
          <div class="run-date">
            <span overflowFormatter>{{ previousRun?.completedAt | date : dateTimeFormat }}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="!previousRun">
          <div class="run-number"></div>
        </ng-container>
      </span>
      <span class="flex-col flex-stretch">
        <div class="run-number">Current Run:</div>
        <div class=run-date>
          <span overflowFormatter>{{ currentRun?.completedAt | date : dateTimeFormat}}</span>
        </div>
      </span>
    </span>
  </div>
<div class="flex-col">
  <div class="table-content" *ngFor="let group of filteredDiffs">
    <span class="tag-name-cell">
      <img [src]="group.tagIcon" class="tag-icon" />
      <span overflowFormatter>{{ group.tagName }}</span>
    </span>
    <div class="tag-details">
      <div class="details-cells" *ngFor="let diff of group.diffs">
        <span class="tag-account">
          <span overflowFormatter>{{ diff.tagAccount }}</span>
        </span>
        <span class="count" *ngIf="showPreviousRun">
          <span>{{isNumber(diff.expectedCount) ? diff.expectedCount : 'Not Run Yet' }}</span>
          <status-code-indicators *ngIf="diff.expectedCount && previousActionTagStatuses[actionId]" [payload]="previousActionTagStatuses[actionId][group.tagId + diff.tagAccount]"></status-code-indicators>
        </span>
        <span class="count" *ngIf="!showPreviousRun">
        </span>
        <span class="count">
          <span>{{ diff.actualCount }}</span>
          <status-code-indicators *ngIf="diff.actualCount && currentActionTagStatuses[actionId]" [payload]="currentActionTagStatuses[actionId][group.tagId + diff.tagAccount]"></status-code-indicators>
        </span>
      </div>
    </div>
  </div>

  <span class="no-tag-differences" *ngIf="!loading && filteredDiffs?.length === 0">
    No tag presence differences were found
  </span >
</div>
