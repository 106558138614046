import { ComponentChanges } from '@app/models/commons';
import { RuleDetailsTypes } from '@app/components/account/rules/rules.enums';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  AfterViewInit, ChangeDetectorRef, OnInit
} from '@angular/core';
import { ILabel } from '@app/components/shared/services/label.service';
import { IAdvancedConfigs } from '@app/components/shared/components/op-chip-selector/op-chip-selector.models';
import { RulesService } from '@app/components/rules/rules.service';
import { IRemoveRuleLabel, IAddRuleLabel } from './rules-table.models';
import { IMenuItem } from '@app/components/shared/components/op-menu/op-menu.component';
import { IUser } from '@app/moonbeamModels';
import { IRuleDetailsCache } from '../rule-details/rule-details.models';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { MatPaginator } from '@angular/material/paginator';
import { StringUtils } from '@app/components/utilities/StringUtils';
import { ESortColumnsV3 } from '../rule-library.enums';
import { IRuleJourneyUsage, IRulePaginationV3, IRuleSortV3, IRuleV3Usage } from '../rule-library.models';
import { RuleDetailsType } from '@app/components/account/rules/rules.constants';
import { animate, state, style, transition, trigger, AnimationEvent } from '@angular/animations';
import { IRule } from '@app/components/rules/rules.models';
import { Features } from '@app/moonbeamConstants';
import { AuthenticationService } from '@app/components/core/services/authentication.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rules-table',
  templateUrl: './rules-table.component.html',
  styleUrls: ['./rules-table.component.scss'],
  animations: [
    trigger('expand', [
      state('collapsed', style({ maxHeight: '0', minHeight: '0' })),
      state('loading', style({ maxHeight: '60px', minHeight: '60px' })),
      state('expanded', style({ maxHeight: '200px', minHeight: '{{height}}' }), { params: { height: '*' } }),
      transition('collapsed <=> expanded, loading <=> *', animate('200ms'))
    ])
  ]
})

export class RulesTableComponent implements OnChanges, AfterViewInit, OnInit {
  readonly RuleDetailsTypes = RuleDetailsTypes;
  readonly ESortColumnsV3 = ESortColumnsV3;

  @Input() isReadOnly: boolean;
  @Input() rules: IRuleV3Usage[];
  @Input() menuItems: IMenuItem[];
  @Input() allLabels: ILabel[] = [];
  @Input() users: IUser[];
  @Input() loadingRules = true;

  @Input() pagination: IRulePaginationV3;
  @Input() sortOptions: IRuleSortV3;

  @Output() onCreateLabel = new EventEmitter<IAddRuleLabel>();
  @Output() onAddLabel = new EventEmitter<number>();
  @Output() onRemoveLabel = new EventEmitter<IRemoveRuleLabel>();
  @Output() editRule = new EventEmitter<IRule>();

  @Output() updateTableState = new EventEmitter<IRuleSortV3 & IRulePaginationV3>();

  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild(MatPaginator) matPaginator: MatPaginator;

  displayedColumns = [
    ESortColumnsV3.Name,
    ESortColumnsV3.Type,
    'labels',
    ESortColumnsV3.Audits,
    ESortColumnsV3.WebJourneys,
    ESortColumnsV3.Owner,
    ESortColumnsV3.ModifiedAt,
    'notification',
    'options'
  ];

  readonly dateFormat = 'MMM d, yyyy';
  readonly timeFormat = 'hh:mm a';

  userIdToUserMap: Map<number, IUser>;
  dataSource = new MatTableDataSource<IRuleV3Usage>();

  isWebJourneyAccessible = false;

  chipsConfig: IAdvancedConfigs;
  loading = false;

  selectedRule: IRuleV3Usage;
  selectedItemType: RuleDetailsType;

  ruleToRuleDetailsMap = new Map<number, IRuleDetailsCache>();
  visibleRuleDetails: IRuleDetailsCache;

  lastOpenedNestedTableHeight: number;
  lastOpenedNestedTable: HTMLDivElement;

  constructor(
    private rulesService: RulesService,
    public authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.chipsConfig = {
      collapsible: true,
      lines: 1,
      readOnly: this.isReadOnly,
      resizable: false,
      availableWidth: 200
    };

    this.authenticationService.isFeatureAllowed(Features.webJourneys)
      .subscribe(isAccessible => this.isWebJourneyAccessible = isAccessible);
  }

  ngOnChanges(changes: ComponentChanges<RulesTableComponent>) {
    if (changes.rules?.currentValue !== changes.rules?.previousValue) {
      this.dataSource.data = changes.rules.currentValue;
    }

    if (changes.users?.currentValue !== changes.users?.previousValue) {
      this.userIdToUserMap = ArrayUtils.toMap(changes.users?.currentValue, 'id');
    }

    if (changes.isReadOnly?.currentValue !== changes.isReadOnly?.previousValue) {
      this.chipsConfig = {
        ...this.chipsConfig,
        readOnly: changes.isReadOnly.currentValue
      };
    }
  }

  ngAfterViewInit() {
    this.matSort?.sortChange.subscribe(sort => {
      this.sortOptions.sortBy = StringUtils.camelCaseToSnakeCase(sort.active) as ESortColumnsV3;
      this.sortOptions.sortDesc = sort.direction === 'desc';
      this.pagination.currentPageNumber = 0;
      this.updateTableState.emit({...this.sortOptions, ...this.pagination});
    });

    this.matPaginator?.page.subscribe(pagination => {
      this.pagination.currentPageNumber = pagination.pageIndex;
      this.updateTableState.emit({...this.sortOptions, ...this.pagination});
    });
  }

  createLabel(rule: IRuleV3Usage, label: ILabel) {
    this.onCreateLabel.emit({
      ruleId: rule.id,
      label: label
    });
  }

  addLabel(rule: IRuleV3Usage) {
    this.onAddLabel.emit(rule.id);
  }

  removeLabel(rule: IRuleV3Usage, label: ILabel) {
    this.onRemoveLabel.emit({
      ruleId: rule.id,
      labelId: label.id
    });
  }

  deselectRule() {
    this.selectedRule = this.selectedItemType = null;
    // timeout is needed for animation rendering
    setTimeout(() => this.visibleRuleDetails = null, 225);
  }

  animationDone($event: AnimationEvent, container: HTMLDivElement) {
    if (($event.fromState === 'loading' && $event.toState === 'expanded')
      || ($event.fromState === 'collapsed' && $event.toState === 'expanded')) {
      this.lastOpenedNestedTable = container;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = container.getBoundingClientRect().height;
        this.cdr.detectChanges();
      }, 300);
    }

    if ($event.fromState === 'expanded' && $event.toState === 'collapsed') {
      this.lastOpenedNestedTable = null;
      setTimeout(() => {
        this.lastOpenedNestedTableHeight = 0;
        this.cdr.detectChanges();
      }, 300);
    }
  }

  async selectRule(rule: IRuleV3Usage, itemType: RuleDetailsType): Promise<void> {
    const sameRule = this.selectedRule === rule;
    const sameItemType = this.selectedItemType === itemType;

    if (!sameRule || (sameRule && !sameItemType)) {
      this.loading = true;
      this.visibleRuleDetails = await this.getRuleDetails(rule, itemType);
      this.loading = false;

      if (!sameItemType) {
        this.lastOpenedNestedTableHeight = 0;

        if (this.lastOpenedNestedTable) {
          setTimeout(() => {
            this.lastOpenedNestedTableHeight = this.lastOpenedNestedTable
              ? this.lastOpenedNestedTable.getBoundingClientRect().height
              : 0;
            this.cdr.detectChanges();
          }, 300);
        }
      }
    } else {
      this.deselectRule();
    }
  }

  private async showAudits(rule: (IRuleV3Usage | any)): Promise<IRuleDetailsCache> {
    if (rule.usage?.auditCount <= 0 || rule.auditsCount <= 0) return Promise.resolve(null);

    const ruleDetails = this.ruleToRuleDetailsMap?.get(rule.id);
    const cachedAudits = ruleDetails?.[RuleDetailsTypes.audit];
    if (cachedAudits) return Promise.resolve(ruleDetails);

    const audits = await this.rulesService.getAutomatedJourneysForRule(rule.id).toPromise();
    return this.cacheRuleDetails(rule, RuleDetailsTypes.audit, audits);
  }

  private async showWebJourneys(rule: (IRuleV3Usage | any)): Promise<IRuleDetailsCache> {
    if (rule.usage.webJourneyCount <= 0) return Promise.resolve(null);

    const ruleDetails = this.ruleToRuleDetailsMap?.get(rule.id);
    const cachedWebJourney = ruleDetails?.[RuleDetailsTypes.webJourney];
    if (cachedWebJourney) return Promise.resolve(ruleDetails);

    const webJourneys = await this.rulesService.getGuidedJourneysForRule(rule.id).toPromise();
    return this.cacheRuleDetails(rule, RuleDetailsTypes.webJourney, webJourneys);
  }

  private cacheRuleDetails(rule: IRuleV3Usage, type: RuleDetailsType, items: IRuleJourneyUsage[]): IRuleDetailsCache {
    let ruleDetails = this.ruleToRuleDetailsMap.get(rule.id) || {rule, type};
    ruleDetails[type] = items as any;
    this.ruleToRuleDetailsMap.set(rule.id, ruleDetails);
    return ruleDetails;
  }

  private getRuleDetails(rule: IRuleV3Usage, itemType: RuleDetailsType): angular.IPromise<IRuleDetailsCache> {
    this.selectedRule = rule;
    this.selectedItemType = itemType;

    switch (itemType) {
      case RuleDetailsTypes.audit:
        return this.showAudits(rule);
      case RuleDetailsTypes.webJourney:
        return this.showWebJourneys(rule);
    }
  }
}
