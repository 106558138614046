import { Subject } from 'rxjs';
import { takeUntil, skip } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AlertPreviewService } from '../alert-preview/alert-preview.service';
import { AlertMetricType } from './alert-logic.enums';
import { EAlertFilterType, EAlertMenuContext, EAlertModalType } from '@app/components/alert/alert.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-logic',
  templateUrl: './alert-logic.component.html',
  styleUrls: ['./alert-logic.component.scss']
})
export class AlertLogicComponent implements OnInit, OnDestroy {
  EAlertFilterType = EAlertFilterType;

  @Input() formGroup: UntypedFormGroup;
  @Input() modalType: EAlertModalType;
  @Input() readOnlyLabel: string;
  @Input() filterType: EAlertFilterType;
  @Input() menuContext: EAlertMenuContext;

  private destroySubject = new Subject<void>();

  constructor(private previewService: AlertPreviewService) {}

  ngOnInit() {
    this.metricTypeFormControl.valueChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe((metricType: AlertMetricType) => {
        metricType
          ? this.filtersFormControl.enable({ emitEvent: false })
          : this.filtersFormControl.disable({ emitEvent: false });
      });

    this.formGroup.valueChanges
      .pipe(
        skip(1), // skip form initialization
        takeUntil(this.destroySubject)
      )
      .subscribe(r => {
        this.previewService.reset();
      });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  get triggerFormGroup(): UntypedFormGroup {
    return this.formGroup.get('trigger') as UntypedFormGroup;
  }

  get metricTypeFormControl(): UntypedFormControl {
    return this.triggerFormGroup.get('metricType') as UntypedFormControl;
  }

  get filtersFormControl(): UntypedFormControl {
    return this.formGroup.get('filters') as UntypedFormControl;
  }
}
