<div class="quick-create-subscribe">
  <div class="help-text">{{ alertCount }} similar alerts already exist in your account for this data. To subscribe with your email address ({{ user.email }}) select one or more of the existing alerts below.</div>
  <div class="quick-create-table-wrp">
    <table class="quick-create-table styled-mat-table" mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="select-col">
          <mat-checkbox color="primary"
                        (change)="$event ? toggleAllRows() : null"
                        [attr.op-selector]="opSelectors.subscribeSelectAll"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="select-col">
          <mat-checkbox color="primary"
                        (click)="$event.stopPropagation()"
                        (change)="toggleSelection($event, row)"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>ALERT NAME</th>
        <td mat-cell *matCellDef="let alert">{{ alert.name }}</td>
      </ng-container>

      <ng-container matColumnDef="logic">
        <th mat-header-cell *matHeaderCellDef>ALERT RULE LOGIC</th>
        <td mat-cell *matCellDef="let alert">
          <div class="default-alert-container" (click)="$event.stopPropagation()">
            <alert-logic-details [metricType]="alert?.metricType"
                                 [operator]="alert?.logicComparator"
                                 [targetValue]="alert?.targetValueComparator?.targetValue"
                                 [showFiltersList]="true"
                                 [filters]="alert?.filtersV0">
            </alert-logic-details>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef class="center-text">EMAIL ADDRESSES</th>
        <td mat-cell *matCellDef="let alert" class="email-col" (click)="$event.stopPropagation()">
          <div class="center">
            <button class="email-popover-btn"
                    mdePopoverTriggerOn="click"
                    [disabled]="alert.emails.length === 0"
                    [mdePopoverTriggerFor]="emailPopover">
              {{ alert.emails?.length }}
              <mat-icon class="btn-icon">expand_more</mat-icon>
            </button>
          </div>
          <mde-popover #emailPopover="mdePopover"
                      [mdePopoverOverlapTrigger]="false"
                      [mdePopoverCloseOnClick]="false"
                      mdePopoverPositionX="before">
            <div class="email-popover">
              <div *ngFor="let email of alert.emails" class="email-pill">
                {{ email }}
              </div>
            </div>
          </mde-popover>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          (click)="selection.toggle(row)">
      </tr>
    </table>
    <div *ngIf="loading" class="spinner-wrapper">
      <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>
</div>
