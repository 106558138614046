import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AccountsService } from '@app/components/account/account.service';
import { IUser } from '@app/moonbeamModels';
import { AlertService } from '../../../alert/alert.service';
import { IAlertPartialModalPayload } from '@app/components/alert/alert.models';
import { QuickCreateOpSelectors } from '@app/components/alert/alert-quick-create/alert-quick-create.constants';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { EAlertFilterType } from '@app/components/alert/alert.enums';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';

@Component({
  selector: 'op-alert-quick-create-subscribe',
  templateUrl: './alert-quick-create-subscribe.component.html',
  styleUrls: ['./alert-quick-create-subscribe.component.scss'],
})
export class AlertQuickCreateSubscribeComponent implements OnInit {
  user: IUser;
  alertCount: number = 0;
  displayedColumns: string[] = ['select', 'name', 'logic', 'email'];
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  invalid: boolean = true;
  showValidation: boolean = false;
  opSelectors = QuickCreateOpSelectors;
  loading: boolean = true;

  @Input() payload: IAlertPartialModalPayload;
  @Output() updateAlertCount = new EventEmitter<number>();

  constructor(
    private accountsService: AccountsService,
    private alertsService: AlertService,
    private alertReportingService: AlertReportingService,
    private auditFilterBarService: AuditReportFilterBarService,
  ) {}

  ngOnInit(): void {
    this.accountsService.getUser().subscribe((user: IUser) => {
      this.user = user;
    });

    this.alertReportingService.getAlertsByMetric(this.payload.metricType).subscribe(({alerts}) => {
      this.dataSource.data = alerts
        .filter(alert => !alert.emails.includes(this.user.email))
        .map(alert => {
          return {
            ...alert,
            logicComparator: this.alertsService.getOperatorByComparator(alert.targetValueComparator.operator),
            // Convert filters format from api post body to audit filter
            filtersV0: this.payload.filterType === EAlertFilterType.V1 ? this.auditFilterBarService.getAuditFiltersFromApiPostBodyFilters(alert.filtersV0 as IAuditReportApiPostBody) : {}
          };
        });

      this.alertCount = this.dataSource.data.length;
      this.updateAlertCount.emit(this.alertCount);
      this.loading = false;
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  toggleSelection($event, row): void {
    $event ? this.selection.toggle(row) : null;
    this.invalid = this.selection.selected.length > 0;
  }

  toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }
}
