import { Names } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { ReprocessRulesModalController } from './statusBanner/reprocessRulesBanner/modal/reprocessRulesModalController';
import { ReprocessRulesBanner } from './statusBanner/reprocessRulesBanner/reprocessRulesBanner';
import { ReprocessRulesBannerController } from './statusBanner/reprocessRulesBanner/reprocessRulesBannerController';
import { ReprocessService } from './statusBanner/reprocessRulesBanner/reprocessService';
import { StatusBannerProcessor } from './statusBanner/statusBannerProcessor';
import { StatusBannerService } from './statusBanner/statusBannerService';
import { WidgetSizeService } from './widgets/widgetSizeService';
import { downgradeComponent } from '@angular/upgrade/static';
import { StatusBannerComponent } from '../status-banner/status-banner/status-banner.component';
import { FullScreenStatusBannerComponent } from '../status-banner/full-screen-status-banner/full-screen-status-banner.component';
import { timeSeries, TimeSeries } from './widgets/timeSeries/timeSeries';
import { TimeSeriesDirective } from './widgets/timeSeries/timeSeriesDirective';
import { RuleReportSerializer } from '@app/components/reporting/rules/ruleReportSerializer';
import { RulesReport } from './rules/rulesReportDirective';
import { RulesReportController } from "@app/components/reporting/rules/rulesReportController";

export default angular.module('reportingModule', [])
  .service(Names.Services.rulesReportSerializer, RuleReportSerializer)
  .controller(Names.Controllers.rulesReport, RulesReportController)
  .directive(Names.Directives.rulesReport, RulesReport.Factory())
  .directive('statusBannerNg', downgradeComponent({component: StatusBannerComponent}))
  .directive('fullScreenStatusBannerNg', downgradeComponent({component: FullScreenStatusBannerComponent}))
  .controller(Names.Controllers.reprocessRulesModalController, ReprocessRulesModalController)
  .directive(Names.Directives.reprocessRulesBanner, ReprocessRulesBanner.Factory())
  .controller(Names.Controllers.reprocessRulesBanner, ReprocessRulesBannerController)
  .service('timeSeriesChart', TimeSeries)
  .directive('timeSeries', TimeSeriesDirective.Factory())
  .service(Names.Services.timeSeriesChartFactory, timeSeries)
  .service(Names.Services.reprocess, ReprocessService)
  .service(Names.Services.statusBannerProcessor, StatusBannerProcessor)
  .service(Names.Services.statusBanner, StatusBannerService)
  .service(Names.Services.widgetSize, WidgetSizeService)
  .name;
