import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { IUser } from '@app/moonbeamModels';
import { ScriptServicesService, IScriptServicesRequest, IPreSignedUrlResponse, IScriptServicesResponse } from './script-services.service';
import { IWebJourneyApiService } from '../domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { IWebJourneyServices } from '../web-journey/web-journey.models';
import { Account } from '../../moonbeamModels';
import { StorageService } from '@app/components/shared/services/storage.service';
import { AccountsService } from '@app/components/account/account.service';
import { AdminAccountsService, IScriptServicesConfig } from '@app/components/admin-portal/admin-accounts.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'script-services',
  templateUrl: './script-services.component.html',
  styleUrls: ['./script-services.component.scss']
})
export class ScriptServicesComponent implements OnInit {

  scriptServicesForm: UntypedFormGroup;
  user: IUser;
  account: Account;

  excelAttachmentUrls: IPreSignedUrlResponse;
  excelFile: File;
  anyAttachmentUrls: IPreSignedUrlResponse;
  anyFile: File;
  company: string;
  email: string;

  allowedExtType_Excel = 'xlsx';
  allowedExtType_Any = '*';

  isLoggedInAsAnother: boolean = false;
  showSpinner: boolean = false;
  submissionError: boolean = false;
  submissionSuccess: boolean = false;
  unauthorized: boolean = false;

  journeyFixes: string = '0';
  journeyFixesCount: number = 0;
  MAX_JOURNEY_FIXES: string = 'unlimited';
  hasUnlimitedFixes: boolean = false;
  maxMonitoredJourneys: number = 0;

  hoveringOverDropzone: boolean = false;
  excelFileDropped: boolean = false;
  anyFileDropped: boolean = false;
  processingUpload_Excel: boolean = false;
  processingUpload_Any: boolean = false;
  errorMsg: string;
  importErrors: string[] = []

  importSuccessMessage = 'Your cookies have successfully imported into the specified consent categories!';
  title = 'Upload Journey Request';
  dropZoneLabel = ''
  templateDownloadLink = `https://observepoint-moonbeam-prod-aws.s3.us-west-2.amazonaws.com/uploaded_files/consent-categories/templates/ObservePoint-Cookie-Consent-Category-Template.csv`

  showInstructions: boolean = false;
  showUpload: boolean = true;

  ImportUploadOverTenMB = 'This file exceeded the 10mb limit. Please select a smaller .xlsx file and try again.';
  ImportUploadOver150MB = 'This file exceeded the 150mb limit. Please select a smaller file and try again.';
  ImportUploadEmpty = 'Empty files are not allowed. Please select a different file and try again.';
  ImportUploadNotExcel = "Only Excel files allowed. Please upload an .xlsx file and try again.";
  ImportUploadExcludedFileType = 'This file type is not allowed.';

  private excelFileMB = 10048576; // 10MB
  private anyFileMB = 150048576; // 150MB
  journeyFixOptions = Array.from({length: 99}, (_, i) => i + 1);
  private readonly EXCLUDED_FILE_TYPES = [
    'exe', 'bat', 'cmd', 'sh', 'php', 'py', 'rb', 'pl', 'cgi', 'app', 'jar', 'swf', 'sql', 'ps1', 'apk', 'dmg', 'iso', 'dll', 'scr', 'hta', 'pif', 'vbs', 'wsf', 'msi', 'msp', 'com', 'gadget', 'ocx', 'jar', 'class', 'war', 'jsp', 'jspx', 'asp', 'aspx', 'scf', 'lnk', 'inf', 'reg', 'docm', 'xlsm', 'pptm', 'mht', 'svg', 'xht', 'xhtml', 'xml', 'xsl', 'xslt', 'dtd', 'xlam', 'xla', 'ade', 'adp', 'crt', 'ins', 'mdb', 'mde', 'nsh', 'scr', 'sct', 'shb', 'sys', 'vb', 'vbe', 'vxd', 'wsc', 'wsf', 'wsh'
  ];

  msgDuration: number = 5000;

  constructor(
    private fb: UntypedFormBuilder,
    private accountsService: AccountsService,
    private adminAccountsService: AdminAccountsService,
    private scriptServicesService: ScriptServicesService,
    private webJourneyService: IWebJourneyApiService,
    private storageService: StorageService,
    private snackBar: MatSnackBar
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.isLoggedInAsAnother = this.storageService.isLoggedInAsAnother();
    this.getJourneyFixes();
  }

  initForm(): void {
    this.scriptServicesForm = this.fb.group({
      numberJourneys: ['', Validators.required],
      custom: [''],
    });
  }

  initData(): void {
    forkJoin([
      this.accountsService.getUser(),
      this.accountsService.getAccount(),
    ]).subscribe(([user, account]) => {
      this.user = user;
      this.account = account;
      this.email = this.user.email;
      this.company = this.account.name;
    });
  }

  private getJourneyFixes(): void {
    this.webJourneyService.getScriptServicesCounts().then((response: IWebJourneyServices) => {
      if (response.journeyFixes === 0) {
        this.unauthorized = true;
      } else {
        this.initData();
      }
      this.maxMonitoredJourneys = response.maxMonitoredJourneys;
      this.hasUnlimitedFixes = response.journeyFixes === -1;
      this.journeyFixes = (response.journeyFixes === -1) ? this.MAX_JOURNEY_FIXES : response.journeyFixes.toLocaleString();
      this.journeyFixesCount = response.journeyFixes;
    });
  }

  async submitForm(): Promise<void> {

    this.submissionError = false;
    this.scriptServicesForm.markAllAsTouched();

    if(!this.hasUnlimitedFixes && this.journeyFixesCount < this.numberJourneys.value) {
      this.showSnack(`You only have ${this.journeyFixes} remaining. Please reduce the number of requests.`);
      return;
    }

    if (this.scriptServicesForm.invalid) return;
    if (
      (!this.excelAttachmentUrls?.putPresignedUrl) &&
      (!this.anyAttachmentUrls?.putPresignedUrl && !this.anyFileDropped)) {
      this.showSnack('Please attach either the recommended template or another supporting video or file.');
      return;
    }

    this.showSpinner = true;

    // Upload the excel file to S3
    if (this.excelAttachmentUrls?.putPresignedUrl) {
      const uploadSuccess = await this.scriptServicesService.uploadFileToS3(this.excelAttachmentUrls.putPresignedUrl, this.excelFile);
      if (!uploadSuccess) {
        this.showSpinner = false;
        this.submissionError = true;
        this.showSnack('The file could not be uploaded at this time. Please try again later.');
        return;
      }
    }

    // If user is also uploading a video/any file
    if (this.anyAttachmentUrls?.putPresignedUrl && this.anyFileDropped) {
      const uploadSuccess = await this.scriptServicesService.uploadFileToS3(this.anyAttachmentUrls.putPresignedUrl, this.anyFile);
      if (!uploadSuccess) {
        this.showSpinner = false;
        this.submissionError = true;
        this.showSnack('The second file could not be uploaded at this time. Please try again later.');
        return;
      }
    }

    this.updateJourneyFixes();
    this.formatDataForGoogleSheets();
  }

  formatDataForGoogleSheets(): void {
    let newRequest: IScriptServicesRequest = {
      excelAttachmentUrl: this.excelAttachmentUrls?.getPresignedUrl,
      anyAttachmentUrl: this.anyAttachmentUrls?.getPresignedUrl,
      company: this.company,
      email: this.email,
      numberJourneys: this.numberJourneys.value,
      journey: {
        custom: this.custom.value, // aka: Special instructions
        domain: '',
        folder: 'See uploaded Excel file',
      },
    };

    this.submitToGoogleSheets(newRequest);
  }

  submitToGoogleSheets(request: IScriptServicesRequest): void {
    this.scriptServicesService.submitScriptServicesRequest(request).subscribe(
      (response: IScriptServicesResponse) => {
        this.showSpinner = false;
        this.submissionSuccess = true;
        this.getJourneyFixes();
      },
      error => {
        console.log(error);
        this.showSpinner = false;
        this.submissionError = true;
      }
    );
  }

  updateJourneyFixes(): void {
    // If they have unlimited fixes then don't update
    if (this.hasUnlimitedFixes) {
      return;
    }

    const config: IScriptServicesConfig = {
      journeyFixes: this.journeyFixesCount - this.numberJourneys.value,
      maxMonitoredJourneys: this.maxMonitoredJourneys
    };

    this.adminAccountsService.updateScriptServicesConfig(this.account.id, config).subscribe(
      () => {
        console.log('Successfully updated # of fixes to: ', this.journeyFixesCount - +this.numberJourneys.value);
      },
      error => {
        console.log('Error updating fixes: ', error.message);
      }
    );
  }

  onFileDropExcelsheet(files: FileList) {
    this.handleFileDrop(files, this.allowedExtType_Excel);
  }

  onFileDropAnyFile(files: FileList) {
    this.handleFileDrop(files, this.allowedExtType_Any);
  }

  async handleFileDrop(files: FileList, dropboxType: string) {
    // Start the spinner for upload status
    if (dropboxType === this.allowedExtType_Excel) this.processingUpload_Excel = true; else this.processingUpload_Any = true;

    this.errorMsg = null;
    this.hoveringOverDropzone = false;

    if (!files || files.length === 0) {
      this.resetFileDropbox(dropboxType);
      return;
    }

    const file = files[0];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const isExcelFile: boolean = fileExtension === this.allowedExtType_Excel;

    // If the actual file matches the drop-box's allowed type OR the drop-box type is 'any'
    if (fileExtension === dropboxType || dropboxType === this.allowedExtType_Any) {
      if (this.EXCLUDED_FILE_TYPES.includes(fileExtension)) {
        this.showSnack(this.ImportUploadExcludedFileType);
        this.resetFileDropbox(dropboxType);
        return;
      }

      if (file.size <= 44) { // 44b is headers only
        this.showSnack(this.ImportUploadEmpty);
        this.resetFileDropbox(dropboxType);
      } else if (isExcelFile && (dropboxType === this.allowedExtType_Excel) && file.size <= this.excelFileMB) {
        this.excelFile = file;
        this.excelFileDropped = true;
        this.processingUpload_Excel = false;
        this.excelAttachmentUrls = await this.scriptServicesService.getPresignedURLs();
      } else if ((dropboxType === this.allowedExtType_Any) && file.size <= this.anyFileMB) {
        this.anyFile = file;
        this.anyFileDropped = true;
        this.processingUpload_Any = false;
        this.anyAttachmentUrls = await this.scriptServicesService.getPresignedURLs();
      } else {
        if (dropboxType === this.allowedExtType_Excel) {
          this.resetExcelFileDropbox();
          this.showSnack(this.ImportUploadOverTenMB);
        } else {
          this.resetAnyFileDropbox();
          this.showSnack(this.ImportUploadOver150MB);
        }
      }
    } else {
      // If not an Excel file && the dropbox type === allowedExtType_Excel
      this.showSnack(this.ImportUploadNotExcel);
      this.resetExcelFileDropbox();
    }
  }

  resetForm(): void {
    this.showSpinner = false;
    this.processingUpload_Excel = false;
    this.processingUpload_Any = false;
    this.submissionSuccess = false;
    this.submissionError = false;
    this.scriptServicesForm.reset();
    this.company = this.account.name;
    this.email = this.user.email;
    this.resetExcelFileDropbox();
    this.resetAnyFileDropbox();
    this.scriptServicesForm.get('numberJourneys').setValue('');
    this.ngOnInit();
  }
  resetFileDropbox(dropboxType: string) {
    if (dropboxType === this.allowedExtType_Excel) {
      this.resetExcelFileDropbox();
    }
    else {
      this.resetAnyFileDropbox();
    }
  }
  resetExcelFileDropbox() {
    this.excelFileDropped = false;
    this.excelAttachmentUrls = null;
    this.excelFile = null;
    this.processingUpload_Excel = false;
  }
  resetAnyFileDropbox() {
    this.anyFileDropped = false;
    this.anyAttachmentUrls = null;
    this.anyFile = null;
    this.processingUpload_Any = false;
  }

  showSnack(message: string) {
    this.snackBar.open(message, '', {
      duration: this.msgDuration,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  closeModal(): void {
  }

  get numberJourneys(): AbstractControl {
    return this.scriptServicesForm.get('numberJourneys');
  }

  get custom(): AbstractControl {
    return this.scriptServicesForm.get('custom');
  }
}
