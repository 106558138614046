<op-modal (onClose)="closeModal()" class="account-search-wrapper">
  <ng-template #headerTemplate>
    <op-modal-header [title]="''"
                     class="account-search-header"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="search-click-backdrop" (click)="closeModal()"></div>
    <div class="search-input">
      <mat-icon class="search-icon">search</mat-icon>
      <input matInput
              placeholder="Search folders, audits or journeys"
              autofocus
              autocomplete="off"
              [(ngModel)]="query"
              (ngModelChange)="searchAccount()"
              class="account-search">
      <mat-icon class="clear-icon" *ngIf="query !== ''" (click)="clearSearch()">clear</mat-icon>
    </div>
    <div class="search-results-backdrop" *ngIf="query !== '' && results" (click)="clearSearch()">
      <mat-card class="search-results mat-elevation-z6" [ngStyle]="{ 'max-height': maxSearchHeight + 'px' }">
        <mat-list *ngIf="results">

          <ng-container *ngIf="results.folders?.length">
            <div mat-subheader>Folders</div>
            <mat-list-item class="search-result" *ngFor="let folder of results.folders" (click)="goToFolder(folder)">
              <div mat-line>{{ folder.name }}</div>
            </mat-list-item>
          </ng-container>

          <ng-container *ngIf="results.domains?.length">
            <div mat-subheader>Sub-Folders</div>
            <mat-list-item class="search-result" *ngFor="let domain of results.domains" (click)="goToDomain(domain)">
              <div mat-line>{{ domain.name }}</div>
            </mat-list-item>
          </ng-container>

          <ng-container *ngIf="results.audits?.length">
            <div mat-subheader>Web Audits</div>
            <mat-list-item class="search-result" *ngFor="let audit of results.audits" (click)="goToAudit(audit)">
              <div mat-line>{{ audit.name }}</div>
            </mat-list-item>
          </ng-container>

          <ng-container *ngIf="results.webJourneys?.length">
            <div mat-subheader>Web Journeys</div>
            <mat-list-item class="search-result" *ngFor="let webJourney of results.webJourneys" (click)="goToWebJourney(webJourney)">
              <div mat-line>{{ webJourney.name }}</div>
            </mat-list-item>
          </ng-container>

          <ng-container *ngIf="!results.folders?.length && !results.domains?.length && !results.audits?.length && !results.webJourneys?.length">
            <div mat-subheader>No Results</div>
          </ng-container>

        </mat-list>
      </mat-card>
    </div>
  </ng-template>
  <ng-template #footerTemplate></ng-template>
</op-modal>
