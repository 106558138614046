import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-select',
  templateUrl: './cl-select.component.html',
  styleUrls: ['./cl-select.component.scss']
})
export class ComponentLibrarySelectComponent {

  data: any[] = [
    { id: 1, name: 'Item 1' },
    { id: 2, name: 'Item 2' },
    { id: 3, name: 'Item 3' },
  ];

}
