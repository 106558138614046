<div class="page-wrap">
  <div class="card">
    <span class="icon-op-logo"></span>

    <div class="card-body">
      <mat-spinner *ngIf="loading" [diameter]="25" [strokeWidth]="3"></mat-spinner>

      <div *ngIf="!loading && errorMessage" class="error-state">
        <div class="message">{{ errorMessage }}</div>
        <button mat-flat-button color="primary" (click)="switchToLogin()">Go To Sign In</button>
      </div>
    </div>

    <div class="privacy">
      Any personal data you submit is processed in accordance with the terms of our
      <a (click)="openLink('https://www.observepoint.com/privacy-policy/')">privacy policy</a>.
    </div>
  </div>
</div>
