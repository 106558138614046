import { Component, Input } from '@angular/core';
import { IFailedRuns } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'failed-runs-message',
  templateUrl: './failed-runs-message.component.html',
  styleUrls: ['./failed-runs-message.component.scss']
})
export class FailedRunsMessageComponent {

  @Input() failedRuns: IFailedRuns = { audits: [], journeys: [] };
}
