import { Component, Input } from '@angular/core';
import { ITagInitiatorNodeViz } from '../tag-initiators/tag-initiators.models';
import { TagInitiatorsService } from '../tag-initiators/tag-initiators.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-initiators-list',
  templateUrl: './tag-initiators-list.component.html',
  styleUrls: ['./tag-initiators-list.component.scss']
})
export class TagInitiatorsListComponent {

  constructor(private tagInitiatorsService: TagInitiatorsService) {}

  @Input() initiatorsData: ITagInitiatorNodeViz[];

  getTagIcon(tagId: number): string {
    return UiTagService.getTagIconUrl(tagId);
  }

  getExtensionIcon(node: ITagInitiatorNodeViz): string {
    return this.tagInitiatorsService.getIconByFileExtension(node.url);
  }

  toggleCollapseState(event: MouseEvent, node: ITagInitiatorNodeViz): void {
    event.stopPropagation();
    if (!node.children) return;
    node.collapsed = !node.collapsed;
  }
}
