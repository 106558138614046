import { Component, Input, SimpleChanges, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { IEventManager } from '../eventManager/eventManager';
import {
  IReprocessBannerUpdate,
  ReprocessBannerStatus,
  IReprocessService
} from '../reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { Events } from '@app/moonbeamConstants';
import { IAuditStatus } from '@app/components/reporting/statusBanner/statusBannerService';
import { Router } from '@angular/router';
import { ReprocessBannerType } from '@app/components/reprocess-banner/reprocess-banner.constants';
import { IAuditDataService } from '../domains/discoveryAudits/reporting/services/auditDataService/auditDataService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'reprocess-banner',
  templateUrl: './reprocess-banner.component.html',
  styleUrls: ['./reprocess-banner.component.scss']
})
export class ReprocessBannerComponent implements OnInit, OnChanges, OnDestroy {

  statusUpdatesSubscriptionID: number;
  reprocessStatus: string;

  @Input() auditId: number;
  @Input() runId: number;
  @Input() auditName: string;
  @Input() set bannerType(type: ReprocessBannerType) {
    switch (type) {
      case ReprocessBannerType.Rules:
        this.eventName = Events.reprocessRulesBannerUpdated;
        break;
      case ReprocessBannerType.ConsentCategories:
        this.eventName = Events.reprocessConsentCategoryBannerUpdated;
        break;
    }
    this.type = type;
  }

  type: ReprocessBannerType;
  private eventName: string;

  constructor(
    private auditDataService: IAuditDataService,
    private reprocessService: IReprocessService,
    private eventManager: IEventManager,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscribeOnAuditStatusUpdates();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.runId) this.processRunIdChanging();
  }

  ngOnDestroy(): void {
    this.eventManager.unSubscribe(this.eventName, this.statusUpdatesSubscriptionID);
  }

  private subscribeOnAuditStatusUpdates(): void {
    const statusUpdated = (update: IReprocessBannerUpdate) => {
      if (update.auditId != this.auditId || update.runId != this.runId || update.type != this.type) return;
      this.processStatusUpdates(update.status);
    };

    this.processStatusUpdates(ReprocessBannerStatus.hidden);
    this.statusUpdatesSubscriptionID = this.eventManager.subscribe(this.eventName, statusUpdated);
    this.checkAuditStatusProgress(true);
  }

  private checkAuditStatusProgress(isInitialCheck: boolean = false): void {
    this.auditDataService.getAuditStatusProgress(this.auditId, this.runId)
    .then( update => update.object as IAuditStatus)
    .then((update: IAuditStatus) => {
      const rulesReprocessingInProgress = this.type === ReprocessBannerType.Rules
        && !update.finished && !update.rulesVerified;
      const consentCategoriesReprocessingInProgress = this.type === ReprocessBannerType.ConsentCategories
        && update.consentCategoriesVerificationStarted && !update.consentCategoriesVerified;
      if (rulesReprocessingInProgress || consentCategoriesReprocessingInProgress) {
        this.processStatusUpdates(ReprocessBannerStatus.inProgress);
        if (isInitialCheck) {
          switch (this.type) {
            case ReprocessBannerType.Rules:
              this.reprocessService.subscribeOnAuditReprocessingRulesUpdates(this.auditId, this.runId, this.auditName);
              break;
            case ReprocessBannerType.ConsentCategories:
              this.reprocessService.subscribeOnAuditReprocessingConsentCategoriesUpdates(this.auditId, this.runId, this.auditName);
              break;
          }
        }
      } else {
        this.processStatusUpdates(ReprocessBannerStatus.hidden);
      }
    });
  }

  private processRunIdChanging(): void {
    this.processStatusUpdates(ReprocessBannerStatus.hidden);
    this.checkAuditStatusProgress();
  }

  private processStatusUpdates(status: ReprocessBannerStatus): void {
    switch (status) {
      case ReprocessBannerStatus.inProgress:
        this.reprocessStatus = ReprocessBannerStatus.inProgress;
        break;

      case ReprocessBannerStatus.done:
        this.reprocessStatus = ReprocessBannerStatus.done;
        break;

      case ReprocessBannerStatus.hidden:
        this.reprocessStatus = ReprocessBannerStatus.hidden;
        break;
    }
  }


  /**
   * Since the Angular router doesn't have a `reload()` option
   * this is a hack to reload the audit report component so
   * that we get the newly reprocessed rules data.
   *
   * This works by navigating to a different URL (but preventing
   * a visual change from happening) then re-routing back to
   * the URL the user was on when they clicked the 'View' button
   * in the reprocess rules banner component.
   */

  reload(): void {
    const currentURL = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(
      () => this.router.navigateByUrl(currentURL)
    );
  }
}
