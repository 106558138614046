import { Component, Input, OnInit, Output, ViewChild, EventEmitter, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { CC_ASSIGN_STRINGS, STORAGE_KEY, CC_ASSIGN_CC_FILTERS_STORAGE_KEY } from '../cc-assign.constants';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { EConsentCategoryType, ICCLibraryQueryParams, IConsentCategory } from '@app/components/consent-categories/consent-categories.models';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ConsentCategoriesService } from '../../consent-categories.service';
import { EConsentCategoriesFilterTypes } from '../../consent-categories-filter-bar/consent-categories-filter-bar.constants';
import { CCDataSourcesFilterBarService } from '../../cc-data-sources-filter-bar/cc-data-sources-filter-bar.service';
import { takeUntil, startWith, switchMap, tap, debounceTime } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { IConsentCategoryLibraryDTO } from '../../consent-categories.models';
import { ECCSortBy } from '../../consent-categories.constants';
import { MatCheckboxChange } from '@angular/material/checkbox';

interface IConsentCategoryCmp extends IConsentCategory {
    domain: string;
    geo: string;
  }

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-assign-select-cc',
  templateUrl: './cc-assign-select-cc.component.html',
  styleUrls: ['./cc-assign-select-cc.component.scss'],
  providers: [
    { provide: STORAGE_KEY, useValue: CC_ASSIGN_CC_FILTERS_STORAGE_KEY },
    CCDataSourcesFilterBarService
  ]
})
export class CCAssignSelectCcComponent implements OnInit, AfterViewInit, OnDestroy {

  ccAssignStrings = CC_ASSIGN_STRINGS;
  displayedColumns: string[] = ['name', 'type', 'labels', 'conflict'];
  dataSource = new MatTableDataSource<IConsentCategory>([]);
  selection = new SelectionModel<IConsentCategory>(true, []);
  selectionType: EConsentCategoryType;
  loading: boolean = true;

  queryParams: ICCLibraryQueryParams = {
    page: 0,
    pageSize: 100,
    sortBy: ECCSortBy.Name,
    sortDesc: false
  };

  paginationState: { length: number, pageSize: number } = {
    length: 0,
    pageSize: 0
  };

  shiftKeyPressed: boolean = false;
  lastSelectedIndex: number = null;

  private destroy$ = new Subject();
  private filtersUpdated$ = new Subject();

  @Input() labels: ILabel[];
  @Input() selectedConsentCategories: IConsentCategoryCmp[];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() selectionChanged: EventEmitter<IConsentCategory[]> = new EventEmitter();

  @HostListener('window:keydown.shift')
  onKeyDown() {
    this.shiftKeyPressed = true;
  }

  @HostListener('window:keyup.shift')
  onKeyUp() {
    this.shiftKeyPressed = false;
  }

  constructor(
    private ccService: ConsentCategoriesService,
    private labelService: LabelService,
    private ccDataSourcesFbSvc: CCDataSourcesFilterBarService
  ) {}

  ngOnInit(): void {
    this.initFilters();
    this.handleAvailableSelection();
  }

  ngAfterViewInit(): void {
    // reset back to the first page if the user changes the sort order
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    setTimeout(() => this.handleTable(), 0);
    setTimeout(() => this.createFilters(), 50);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initFilters(): void {

    this.ccDataSourcesFbSvc
    this.ccDataSourcesFbSvc.updateSupportedFiltersList([
      EConsentCategoriesFilterTypes.Name,
      EConsentCategoriesFilterTypes.Type,
      EConsentCategoriesFilterTypes.Label
    ]);

    this.ccDataSourcesFbSvc
      .currentFilters$
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  createFilters(): void {

    // Check if we are coming from the OneTrust import page
    const geo = this.selectedConsentCategories[0]?.geo;
    const domain = this.selectedConsentCategories[0]?.domain;
    if(geo && domain) {
      this.setImportLabels(geo, domain);
      return;
    }

    // Recreate the filters from storage
    const curFilters = this.ccDataSourcesFbSvc.currentFilters;

    const createLabelFilter = (filter: IOpFilterBarFilter<EConsentCategoriesFilterTypes>): IOpFilterBarFilter<EConsentCategoriesFilterTypes> => ({
      type: EConsentCategoriesFilterTypes.Label,
      display: filter.display,
      value: filter.value
    });

    this.ccDataSourcesFbSvc.clear();

    const newFilters = curFilters.map(createLabelFilter);
    newFilters.forEach(filter => this.ccDataSourcesFbSvc.addFilter(filter));
  }
  async setImportLabels(geo: string, domain: string): Promise<void> {

    this.ccDataSourcesFbSvc.clear();

    // Create the new labels
    const geoId = this.labels
      .filter((label: ILabel) => label.name === geo)
      .map((label: ILabel) => label.id);

    const labelGeo: IOpFilterBarFilter<EConsentCategoriesFilterTypes> = {
      type: EConsentCategoriesFilterTypes.Label,
      display: geo,
      value: geoId
    };

    const domainId = this.labels
      .filter((label: ILabel) => label.name === domain)
      .map((label: ILabel) => label.id);

    const labelDomain: IOpFilterBarFilter<EConsentCategoriesFilterTypes> = {
      type: EConsentCategoriesFilterTypes.Label,
      display: domain,
      value: domainId
    };

    // Set new labels and filters
    this.ccDataSourcesFbSvc.addFilter(labelGeo);
    this.ccDataSourcesFbSvc.addFilter(labelDomain);
  }

  onFiltersChanged(apiPostBody): void {
    if (this.paginator) this.paginator.pageIndex = 0;
    this.filtersUpdated$.next({ filters: apiPostBody });
    this.resetQueryParams();
  }

  handleTable(): void {
    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.filtersUpdated$
    ).pipe(
      tap(() => {
        this.loading = true;
        this.resetQueryParams();
      }),
      startWith([]),
      debounceTime(150), // Wait for 150ms of inactivity before proceeding with merge (so that getDataSources is only called once)
      switchMap((mergedEvent: Sort & PageEvent & IOpFilterBarFilter<EConsentCategoriesFilterTypes>[]) => {
        let labelIds = [];
        if (mergedEvent) {
          // sorting
          if (mergedEvent.hasOwnProperty('direction')) {
            this.paginator.pageIndex = 0;
            this.queryParams.sortBy = mergedEvent.active;
            this.queryParams.sortDesc = mergedEvent.direction === 'desc';
          }

          // pagination
          if (mergedEvent.hasOwnProperty('pageIndex')) {
            this.queryParams.page = mergedEvent.pageIndex;
          }

          // filter bar
          if (mergedEvent.hasOwnProperty('filters')) {
            const filters = mergedEvent['filters'];

            filters.forEach((filter: IOpFilterBarFilter<EConsentCategoriesFilterTypes>) => {
              // name filter
              if (filter.type === EConsentCategoriesFilterTypes.Name) {
                this.queryParams.name = filter.value['filterValue'];
              }
              // type filter
              if (filter.type === EConsentCategoriesFilterTypes.Type) {
                this.queryParams.type = filter.value as EConsentCategoryType;
              }
              // label filters
              if (filter.type === EConsentCategoriesFilterTypes.Label) {
                if (Array.isArray(filter?.value) && typeof filter?.value[0] === 'number') {
                  labelIds.push(filter.value[0]);
                }
              }
            });

          }
        }

        this.queryParams.labels = labelIds.length ? labelIds : null;
        return this.ccService.getConsentCategoriesLibrary(this.queryParams);
      })
    )
    .subscribe((data: IConsentCategoryLibraryDTO) => {
      // update loading state
      this.loading = false;

      // update pagination
      const pagination = data.metadata.pagination;
      this.paginationState.length = pagination.totalCount;
      this.paginationState.pageSize = pagination.pageSize;

      // update data source
      this.dataSource.data = [ ...data.consentCategories ];
      this.setSelectedState();
    });
  }

  getLabelNames(ids: number[]): string {
    return ids.map((id: number) => this.labels.find((label: ILabel) => label.id === id)?.name).join(', ');
  }

  resetQueryParams(): void {
    this.queryParams = {
      page: 0,
      pageSize: 100,
      sortBy: ECCSortBy.Name,
      sortDesc: false
    };
  }

  onCheckboxChange(event: MatCheckboxChange, cc: IConsentCategory): void {
    event ? this.selection.toggle(cc) : null;
    this.selectionChanged.emit(this.selection.selected);
  }

  private async getLabels(): Promise<void> {
    this.labels = await this.labelService.getLabels().toPromise();
  }

  setSelectedState(): void {
    if (!this.selectedConsentCategories.length) return;
    
    this.selectedConsentCategories.forEach(cc => {
      const found = this.dataSource.data.find(item => item.id === cc.id);
      if (found) {
        this.selection.select(found);
        this.selectionChanged.emit(this.selection.selected);
      }
    });
  }

  handleAvailableSelection(): void {
    this.selection.changed.pipe(takeUntil(this.destroy$)).subscribe(selection => {
      if (!this.selection.hasValue()) return this.selectionType = null;

      this.selectionType = selection.added.length
          ? selection.added[0].type
          : selection.removed[0].type;
    });
  }

  handleCheckboxClick(index: number): void {
    const i = this.lastSelectedIndex ?? index;
    const typeToSelect = this.dataSource.data[i].type;

    if (this.shiftKeyPressed) {
      if (this.lastSelectedIndex < index) {
        for (let i = this.lastSelectedIndex + 1; i <= index; i++) {
          if (this.dataSource.data[i].type === typeToSelect) {
            this.selection.toggle(this.dataSource.data[i]);
          }
        }
      } else {
        for (let i = this.lastSelectedIndex - 1; i >= index; i--) {
          if (this.dataSource.data[i].type === typeToSelect) {
            this.selection.toggle(this.dataSource.data[i]);
          }
        }
      }
    } else {
      this.selection.toggle(this.dataSource.data[index]);
    }

    this.lastSelectedIndex = index;
    this.selectionChanged.emit(this.selection.selected);
  }
}
