<page-redirect [pageDetails]="initialPage" [isAllowedCopyAll]="!!initialPage && !!finalPage" (copyAllRedirects)="copyAllRedirects()">

  <span *ngIf="pageInfo.redirects?.length > 1; else notClickableCount"
        class="redirects-count active"
        [ngClass]="{
          expanded: isExpanded,
          medium: pageInfo.redirects.length > 1 && pageInfo.redirects.length < 9,
          wide: pageInfo.redirects.length >= 9
        }"
        [matTooltip]="getToggleRedirectsTooltipText()"
        matTooltipPosition="above"
        (click)="isExpanded = !isExpanded">
    {{ pageInfo.redirects.length + 1 }}
    <mat-icon class="toggle-redirects-icon">expand_more</mat-icon>
  </span>

  <ng-template #notClickableCount>
    <span class="redirects-count"
          [matTooltip]="pageInfo.redirects?.length === 1 ? '1 redirect' : 'No redirects'"
          matTooltipPosition="above">
      {{ pageInfo.redirects ? pageInfo.redirects.length + 1 : 1 }}
    </span>
  </ng-template>

</page-redirect>

<div class="redirects" [class.collapsed]="isExpanded">
  <div *ngFor="let redirect of redirects">
    <page-redirect [pageDetails]="redirect" (copyAllRedirects)="copyAllRedirects()" [isAllowedCopyAll]="!!initialPage && !!finalPage">
      <mat-icon class="arrow-right-icon"
                [ngClass]="{
                  medium: pageInfo.redirects.length > 1 && pageInfo.redirects.length < 9,
                  wide: pageInfo.redirects.length >= 9
                }">
        subdirectory_arrow_right
      </mat-icon>
    </page-redirect>
  </div>
</div>

<page-redirect [pageDetails]="finalPage" *ngIf="finalPage" [isFinal]="true" (copyAllRedirects)="copyAllRedirects()" [isAllowedCopyAll]="!!initialPage && !!finalPage">
  <mat-icon class="arrow-right-icon"
            [ngClass]="{
              medium: pageInfo.redirects.length > 1 && pageInfo.redirects.length < 9,
              wide: pageInfo.redirects.length >= 9
            }">
    subdirectory_arrow_right
  </mat-icon>
</page-redirect>
