export const AuditInfoListItems = [
  'gpcEnabled',
  'vpnEnabled',
  'customProxy',
  'locationId',
  'loginActions',
  'onPageActions',
  'privacyConsentActions',
  'technologyBlockingEnabled',
  'fileSubstitutionConfigured',
  'blockThirdPartyCookies',
];

export enum EAuditInfoItemNames {
  gpcEnabled = 'GPC Signal enabled',
  vpnEnabled = 'VPN enabled',
  loginActions = 'Pre-audit actions configured',
  onPageActions = 'On-page actions (including OP custom Tag) configured',
  privacyConsentActions = 'User consent state (CMP) configured',
  technologyBlockingEnabled = 'Technology Blocking enabled',
  fileSubstitutionConfigured = 'File Substitution configured',
  blockThirdPartyCookies = 'Block third-party cookies',
}
