import { Injectable } from '@angular/core';
import { ApiService } from '../core/services/api.service';
import { environment } from '@app/environments/environment';
import { Observable, of } from 'rxjs';
import {
  IPaginationMetaData, ISharedLink,
  ISharedLinks,
  ISharedLinksFilters,
  ISort
} from '@app/components/shared-links/shared-links-library.models';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { ApplicationChromeService } from '../core/services/application-chrome.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SharedLinksLibraryService {

  apiRoot = `${environment.authApiProtocol}://${environment.authApiHost}`;

  constructor(private apiService: ApiService,
    private authenticationStorage: AuthenticationStorageService,
    private applicationChromeService: ApplicationChromeService) {}

  getAll(sorting: ISort, pagination: IPaginationMetaData, filters: ISharedLinksFilters): Observable<ISharedLinks> {
    const params = new HttpParams()
      .set('page', pagination.currentPageNumber)
      .set('size', pagination.pageSize)
      .set('sortBy', sorting.sortBy)
      .set('sortDesc', sorting.sortDesc);
    return this.apiService.post(`${this.apiRoot}/shared-links`, filters, { params });
  }

  setSharedLinksFlag(isSharingEnabled: boolean) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authenticationStorage.get().token}`
    });

    return this.apiService.put<{ isSharingEnabled: boolean }>(`${this.apiRoot}/account/sharing-settings`, {
      isSharingEnabled
    }, {headers}).pipe(
      tap(() => this.applicationChromeService.updateAccountPreview())
    );
  }

  getSharedLinksFlag() {
    return this.apiService.get<{ isSharingEnabled: boolean }>(`${this.apiRoot}/account/sharing-settings`, this.authHeaders);
  }

  updateSharedLinksStatus(sharedLinkIds: ISharedLink[], isShared: boolean) {
    return this.apiService.patch(`${this.apiRoot}/shared-links/status`, {
      sharedLinkIds: sharedLinkIds.map(link => link.id),
      operationType: isShared ? 'enable' : 'disable'
    }, this.authHeaders);
  }

  private get authHeaders(): {headers: HttpHeaders} {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.authenticationStorage.get().token}`
      })
    };
  }
}
