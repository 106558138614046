<div class="recurrence-component">
  <ng-container *ngIf="recurrence?.schedule">
    <div class="left-side" #leftSide (mouseenter)="showHint(leftSide)" (mouseleave)="hideHint(leftSide)">
      <div class="run-date-time-wrp">
        <op-run-date class="run-date" [runDateForm]="runDateFormGroup"></op-run-date>
        <op-run-time class="run-time"
                    [runTimeForm]="runTimeFormGroup"
                    [runDateForm]="runDateFormGroup"
        >

        </op-run-time>
      </div>
      <mat-hint *ngIf="runTimeFormGroup.valid" class="run-date-time-hint">{{ runDateTimeHint }}</mat-hint>
    </div>
    <op-run-frequency class="frequency"
                      [frequencyForm]="frequencyFormGroup"
                      [fieldLabel]="fieldLabel"
                      [selectedDate]="runDateFormGroup.value.date"
                      [selectedTime]="runTimeFormGroup.value.time"
                      [itemType]="itemType"
    >
    </op-run-frequency>
  </ng-container>
</div>
