import { Injectable } from '@angular/core';
import { StorageService } from '@app/components/shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { Observable } from 'rxjs';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { map } from 'rxjs/operators';
import {
  ENotificationCenterModalFilterTypes
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.enums';
import {
  NOTIFICATION_CENTER_MODAL_FILTERS_STORAGE_KEY
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterModalFilterBarService extends OpFilterBarService<ENotificationCenterModalFilterTypes> {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService
  ) {
    super(storage, NOTIFICATION_CENTER_MODAL_FILTERS_STORAGE_KEY, cacheReset, new Map([[ENotificationCenterModalFilterTypes.Label, true]]));
    this.updateSupportedFiltersList([
      ENotificationCenterModalFilterTypes.Label,
      ENotificationCenterModalFilterTypes.Name
    ]);
  }

  get filters$(): Observable<IOpFilterBarFilter<ENotificationCenterModalFilterTypes>[]> {
    return this.anyFiltersUpdates$
      .pipe(
        map(newFilters =>
          newFilters
            .filter(filter => this.validTypes.includes(filter.type))
            .reduce((acc, curr) => {
              acc.push(curr);
              return acc;
            }, [])
        )
      );
  }

  addNameContainsFilter(contains: string) {
    this.addFilter({
      type: ENotificationCenterModalFilterTypes.Name,
      display: `Item Name contains '${contains}'`,
      value: contains,
      order: 1
    });
  }

  addLabelIdFilter(label: { name: string, id: number }) {
    this.addFilter({
      type: ENotificationCenterModalFilterTypes.Label,
      display: `Label: ${label.name}`,
      value: label.id,
      order: 3
    });
  }
}

