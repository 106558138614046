import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import {
  AuditReportUrlBuilders,
  EConsentCategoryComplianceStatus
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, debounceTime, filter, finalize, map } from 'rxjs/operators';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { sortBy } from 'lodash-es';
import {
  ITagPrivacySummary,
  ITagPrivacyTrendOverview,
  ITagPrivacyTrendsOverviews,
  TagPrivacyRelevantFilters
} from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.models';
import { PrivacyTagsService } from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.service';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import {
  TagPrivacyTrendName,
  UNAPPROVED_TAGS_CHART_CONFIG
} from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.constants';
import { formatNumber } from '@angular/common';
import { Router } from '@angular/router';
import { AuditSummaryRelevantFilters } from '@app/components/audit-reports/reports/audit-summary/audit-summary.models';
import {
  PrivacyWidgetsLoadingStateHandler
} from '@app/components/audit-reports/reports/audit-summary/privacy-section-in-audit-summary/loading-state-handler';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';

const RelevantFiltersOverlap = AuditSummaryRelevantFilters.filter(ft => TagPrivacyRelevantFilters.includes(ft));

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'widgets-tags',
  templateUrl: './widgets-tags.component.html',
  styleUrls: ['./widgets-tags.component.scss']
})
export class WidgetsTagsComponent extends PrivacyWidgetsLoadingStateHandler implements OnInit, OnChanges, OnDestroy {

  @Input() auditId: number;
  @Input() runId: number;
  @Input() apiFilters: IAuditReportApiPostBody;

  EConsentCategoryComplianceStatus = EConsentCategoryComplianceStatus;

  sparklineDataLoaded: boolean = false;
  widgetSparklineRunsInfo: ISparklineRunInfo[];

  //------- Tags
  tagSummary: ITagPrivacySummary;
  tagTrends: ITagPrivacyTrendOverview[];

  //2 - PAGES UNAPPROVED TAGS
  widgetPagesUnapprovedTags: ISplitCardChartData = {
    topLabel: 'Pages with Unapproved Tags',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.explore(),
    tooltip: 'Pages with a tag marked as unapproved in one or more consent categories.',
  };
  pagesWithUnapprovedTagsCount: number;

  //5 UnapprovedTags
  widgetUnapprovedTags: ISplitCardChartData = {
    topLabel: 'Unapproved Tags',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: this.explore(),
    tooltip: 'Tags unapproved in one or more consent categories.',
    bottomHandler: this.openTagsUnapprovedChart.bind(this)
  };
  widgetUnapprovedTagsData: ISparklineChartData[];

  constructor(
    private router: Router,
    private tagPrivacyService: PrivacyTagsService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private modalService: OpModalService,
    private auditReportLoadingService: AuditReportLoadingService,
  ) {
    super(auditReportLoadingService, filterBarService);
  }

  ngOnInit(): void {
    let previousRelevantFilters = [...this.filterBarService.currentFilters.filter(f => RelevantFiltersOverlap.includes(f.type))];
    this.filterBarService.anyFiltersUpdates$.pipe(
      debounceTime(300),
      filter(newFilters => {
        const newRelevantFilters = newFilters.filter(f => RelevantFiltersOverlap.includes(f.type));
        const diff = ArrayUtils.diff(previousRelevantFilters, newRelevantFilters, (f1, f2) => f1.type === f2.type);
        previousRelevantFilters = [...newRelevantFilters];
        if (diff.added.length > 0 || diff.removed.length > 0) {
          return true;
        } else {
          const anyChanges = JSON.stringify(diff.same.array1) !== JSON.stringify(diff.same.array2);
          return anyChanges;
        }
      })
    ).subscribe(newFilters => {
      this.apiFilters = this.filterBarService.generateApiPostBody(newFilters.filter(f => RelevantFiltersOverlap.includes(f.type)));
      this.loadTags();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let newAuditId = changes?.auditId?.currentValue;
    let newRunId = changes?.runId?.currentValue;
    if (newAuditId || newRunId) {
      this.auditId = newAuditId || this.auditId;
      this.runId = newRunId || this.runId;
      this.loadTags();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private loadTags() {
    this.startLoading();
    this.sparklineDataLoaded = false;
    forkJoin([
      this.tagPrivacyService.getTagPrivacySummary(this.auditId, this.runId, this.apiFilters)
        .pipe(catchError((err) => {
          console.error(`Failed to load tag privacy summary for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);
          return EMPTY;
        })),
      this.tagPrivacyService.getTagPrivacyTrends(this.auditId, this.runId)
        .pipe(
          map((data: ITagPrivacyTrendsOverviews) => sortBy(data.runs, el => el.runId) as ITagPrivacyTrendOverview[]),
          catchError((err) => {
            console.error(`Failed to load tag privacy trends for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);
            return EMPTY;
          })
        )
    ]).pipe(finalize(() => {
      this.stopLoading();
      this.sparklineDataLoaded = true;
    }))
      .subscribe(([tagSummary, tagTrends]) => {
        this.tagSummary = tagSummary;
        this.updateWidgets(tagSummary);
        this.tagTrends = tagTrends;
        this.updateSparkLines(tagTrends);
      });
  }

  private updateWidgets(data: ITagPrivacySummary) {
    this.pagesWithUnapprovedTagsCount = data.filteredPageWithUnapprovedTagCount;

    this.widgetUnapprovedTags.topChangeContent = this.formatWidgetContent(data.filteredUnapprovedTagCount, data.totalUnapprovedTagCount);
    this.widgetUnapprovedTags.topChangeMeaning = data.filteredUnapprovedTagCount === 0
      ? ESplitCardChangeMeaning.NEUTRAL
      : ESplitCardChangeMeaning.NEGATIVE;
  }

  private updateSparkLines(data: ITagPrivacyTrendOverview[]) {
    const unapprovedTags: ISparklineChartData[] = [];
    const runsInfo: ISparklineRunInfo[] = [];

    data.forEach((val, index) => {
      unapprovedTags.push({value: val.totalUnapprovedTagCount, sequence: index});
      runsInfo.push({runId: val.runId, runCompletionDate: val.completedAt});
    });

    this.widgetSparklineRunsInfo = runsInfo;
    this.widgetUnapprovedTagsData = unapprovedTags;
  }

  public explore() {
    return () => {
      this.filterBarService.addConsentCategoryStatusFilter(EConsentCategoryComplianceStatus.Unapproved);
      this.router.navigateByUrl(AuditReportUrlBuilders.privacyTags(this.auditId, this.runId));
    };
  }

  private openTagsUnapprovedChart() {
    this.openFullscreenChart(
      TagPrivacyTrendName.UnApprovedTags,
      this.widgetSparklineRunsInfo.length > 1
        ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate
        : undefined
    );
  }

  private openFullscreenChart(
    trendName: TagPrivacyTrendName,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: UNAPPROVED_TAGS_CHART_CONFIG
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  private getFullscreenChartData(trendName: any, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.tagPrivacyService.getTagPrivacyTrend(this.auditId, trendName, days)
      .pipe(
        map(({ runs }) => ({
            chartData: runs.map(({ trendValue, completedAt }) => ({ value: trendValue, date: completedAt }))
          })
        )
      );
  }

  private formatWidgetContent(filteredCount: number, totalCount?: number): string {
    const format = (x: number) => formatNumber(x, 'en-US');
    if (!this.isFiltered() || (this.isFiltered() && !totalCount)) {
      return format(filteredCount);
    } else {
      return `${format(filteredCount)} of ${format(totalCount)}`;
    }
  }

  isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.filter(f => RelevantFiltersOverlap.includes(f.type)).length > 0;
  }

}
