export class StringUtils {

  static replaceNewLinesWith(toModify: string, replaceWith: string): string {
    return toModify.replace(/\n/g, replaceWith);
  }

  static replaceSpacesWith(toModify: string, replaceWith: string): string {
    return toModify.replace(/\s/g, replaceWith);
  }

  static camelCaseToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  static generateRandom(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

}
