import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { EAlertBellStatus } from '@app/components/alert/alert-bell/alert-bell.enums';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { AlertComponent } from '@app/components/alert/alert.component';
import { EAlertFilterType, EAlertMenuContext, EAlertModalType, EAlertResultStatus } from '@app/components/alert/alert.enums';
import { IAlertCreateForAuditModalPayload, IAlertModalPayload, IAlertResultsItem } from '@app/components/alert/alert.models';
import { IAuditSummaryAlert } from '@app/components/audit/audit.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { IUser } from '@app/moonbeamModels';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuditReportFilterBarService } from '../../audit-report-filter-bar/audit-report-filter-bar.service';
import { AuditReportUrlBuilders } from '../../audit-report/audit-report.constants';
import { EAuditPopoverStates } from './audit-report-header-popover.constants';
import { IBellIconStateData } from './audit-report-header-popover.models';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { AuditReportChangesService, IAlertChangeState } from '../../audit-report-changes.service';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { Observable } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-report-header-popover',
  templateUrl: './audit-report-header-popover.component.html',
  styleUrls: ['./audit-report-header-popover.component.scss']
})
export class AuditReportHeaderPopoverComponent implements OnInit, OnDestroy {

  @Input() auditId: number;
  @Input() runId: number;
  @Input() popoverOpen: boolean;
  @Input() popoverMode: boolean = true;
  @Input() user: IUser;
  @Input() accountHasAlerts: boolean = false;

  @Output() onCloseEmitter: EventEmitter<any> = new EventEmitter();
  @Output() headerIconData: EventEmitter<IBellIconStateData> = new EventEmitter();

  auditHasAlerts: boolean = false;
  userIsSubscribed: boolean = false;
  hasTriggeredAlerts: boolean = false;
  alertsCount: number = 0;
  triggeredAndSubscribedAlertsCount: number = 0;
  EAuditPopoverStates = EAuditPopoverStates;
  popoverView: EAuditPopoverStates = EAuditPopoverStates.NoTriggeredAlerts;
  dataToEmit: IBellIconStateData = {
    status: EAlertBellStatus.CreateAlert,
    alertsCount: 0
  };
  hideNoAlertsNotAssignedMessage: boolean = false;

  isVisitorMode$: Observable<boolean>;
  private destroy$ = new Subject();

  constructor(
    private modalService: OpModalService,
    private alertReportingService: AlertReportingService,
    private router: Router,
    private filterBarService: AuditReportFilterBarService,
    private auditReportChangesService: AuditReportChangesService,
    private applicationChromeService: ApplicationChromeService
  ) {
    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
  }

  ngOnInit(): void {
    this.alertReportingService.alertPopoverData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((alerts: IAuditSummaryAlert[]) => {
        this.handleSummaryData(alerts);
      });

    this.auditReportChangesService.onAlertsChanged$.pipe(takeUntil(this.destroy$)).subscribe((alertChangeState: IAlertChangeState) => {
      this.checkForNewlyAssignedAlerts(alertChangeState)
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkForNewlyAssignedAlerts(alertChangeState: IAlertChangeState): void {
    this.hideNoAlertsNotAssignedMessage = (this.alertsCount === 0) && alertChangeState.hasAssignedAlerts;
  }

  handleSummaryData(alerts: IAuditSummaryAlert[]): void {
    this.auditHasAlerts = !!alerts.length;
    this.userIsSubscribed = !!alerts.filter(alert => alert.isSubscribed).length;
    this.alertsCount = alerts.filter(alert => alert.isSubscribed).length;
    this.hasTriggeredAlerts = !!alerts.filter(alert => alert.status === EAlertResultStatus.Triggered).length;
    this.triggeredAndSubscribedAlertsCount = alerts
      .filter(alert => alert.status === EAlertResultStatus.Triggered)
      .filter(alert => alert.isSubscribed).length;

    this.dataToEmit.alertsCount = this.triggeredAndSubscribedAlertsCount;

    if (this.accountHasAlerts) {
      if (this.auditHasAlerts) {
        if (this.userIsSubscribed) {
          if (this.hasTriggeredAlerts) {
            // subscribed and triggered
            this.popoverView = EAuditPopoverStates.HasTriggeredAlerts;
            this.dataToEmit.status = EAlertBellStatus.TriggeredAlerts;
          } else {
            // subscribed but not triggered (confetti)
            this.popoverView = EAuditPopoverStates.NoTriggeredAlerts;
            this.dataToEmit.status = EAlertBellStatus.ConfiguredAndSubscribedAlerts
          }
        } else {
          this.popoverView = EAuditPopoverStates.NotSubscribed;
          this.dataToEmit.status = EAlertBellStatus.NoSubscribedAlerts;
        }
      } else {
        this.popoverView = EAuditPopoverStates.NoneConfigured;
        this.dataToEmit.status = EAlertBellStatus.CreateAlert;
      }
    } else {
      this.popoverView = EAuditPopoverStates.NoAlertsInAccount;
      this.dataToEmit.status = EAlertBellStatus.CreateAlert;
    }

    this.headerIconData.emit(this.dataToEmit);
  }

  assignAlert(): void {
    const data = {
      auditId: this.auditId,
      runId: this.runId,
      step: 1,
    };
    this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor')
      .afterClosed()
      .subscribe(() => {
        this.auditReportChangesService.checkForAlertChanges(this.auditId, this.runId);
      });
  }

  addAlert(): void {
    const data: IAlertModalPayload = {
      auditId: this.auditId,
      runId: this.runId,
      menuContext: EAlertMenuContext.Audit,
      filterType: EAlertFilterType.V1,
      type: EAlertModalType.CreateForAudit,
      emails: (this.user ? [this.user.email] : []),
    } as IAlertCreateForAuditModalPayload;

    this.modalService.openFixedSizeModal(AlertComponent, { disableClose: true, data, autoFocus: false })
      .afterClosed()
      .subscribe(() => {
        this.auditReportChangesService.checkForAlertChanges(this.auditId, this.runId);
      });;
  }

  getTriggeredAlertsCount(alerts: IAlertResultsItem[]): number {
    let triggeredAlertsCount = 0;
    alerts.forEach((alert: IAlertResultsItem) => {
      if (alert.result.status === EAlertResultStatus.Triggered) triggeredAlertsCount++;
    });

    return triggeredAlertsCount;
  }

  routeToAlertsSummary(setFilters: boolean = false): void {
    if (setFilters) {
      this.filterBarService.addSubscribedAlertsFilter();
      this.filterBarService.addTriggeredAlertsFilter(true);
    }

    this.router.navigateByUrl(AuditReportUrlBuilders.alertSummary(this.auditId, this.runId));
  }
}
