import { RRule } from 'rrule';

export const ORDINAL_TO_NUMBER = {
  First: 1,
  Second: 2,
  Third: 3,
  Fourth: 4,
  Last: -1,
};

export const DAY_OF_WEEK_TO_RRULE_DAY = {
  Sunday: RRule.SU,
  Monday: RRule.MO,
  Tuesday: RRule.TU,
  Wednesday: RRule.WE,
  Thursday: RRule.TH,
  Friday: RRule.FR,
  Saturday: RRule.SA,
};

