<div class="filter-bar-wrap" [class.collapsable-pills]="collapsablePills">
  <div class="filter-bar-controls">
    <div>
      <div #parentDivSearch class="search-by-url-wrap"
           [class.disabled]="!isSearchByTextEnabled || isReadOnly"
           [class.wide]="keepWide"
           [matTooltip]="isReadOnly ? readOnlyLabel : 'No ' + searchByTextPlaceholderSuffix + 's to search for on the page' "
           matTooltipPosition="above"
           [matTooltipDisabled]="isSearchByTextEnabled  && !isReadOnly">
        <mat-icon class="search-by-url-icon">search</mat-icon>
        <input type="text"
               name="textSearch"
               autocomplete="off"
               #searchByTextInput
               placeholder="Search By {{ searchByTextPlaceholderSuffix }}"
               [(ngModel)]="searchByTextInputValue"
               [attr.disabled]="!isSearchByTextEnabled ? 'true' : null"
               (keyup)="searchByTextKeyUp($event)"
               (focus)="searchByTextFocused()"
               (blur)="searchByTextBlurred()"
               (focusout)="focusout($event)"
               tabindex="1"
               class="search-by-url-input"
        />
        <button *ngIf="searchByTextInputValue !== ''"
                class="clear-search-by-url-btn"
                (click)="clearSearchByUrl()"
                type="button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="search-by-url-options-layout">
        <div *ngIf="showTextSearchByRegexOpt && !hideSearchByRegexOption">
          <ng-content></ng-content>
          <mat-checkbox [checked]="searchByTextAsRegex"
                        color="primary"
                        (focusin)="searchByTextFocused()"
                        (focusout)="searchByTextBlurred()"
                        (click)="changeSearchByTextAsRegex()"
                        (mousedown)="searchByTextMouseDown()"
                        tabindex="2"
          >Treat as regular expression</mat-checkbox>
        </div>
      </div>
    </div>
    <button mat-button class="add-filter-button"
      *ngIf="!isReadOnly"
      [matMenuTriggerFor]="menu.childMenu"
      tabindex="3"
      type="button"
      (menuOpened)="onMenuOpened()"
      (menuClosed)="onMenuClosed()">
      <mat-icon class="filter-icon">tune</mat-icon>
      Filters
      <mat-icon class="expand-icon" iconPositionEnd>expand_more</mat-icon>
    </button>
  </div>
  <div #filterChipBag class="chip-bag">
    <mat-chip-listbox aria-label="Currently applied filters">
      <ng-container
        *ngFor="let vizFilter of visibleFilters">

        <!-- invertable chip template -->
        <mat-chip-option *ngIf="vizFilter.toggleOption1"
                  [removable]="true"
                  (removed)="removeFilter(vizFilter)"
                  [color]="validFilterTypes.includes(vizFilter.type) ? 'primary' : 'none'"
                  [matTooltip]="validFilterTypes.includes(vizFilter.type) ? 'Filter is active on this report' : 'Filter is inactive on this report'"
                  matTooltipPosition="above"
                  highlighted>
          <span class="chip-label-html" [innerHTML]="vizFilter.display"></span>
          <div class="chip-toggle" (click)="toggleInvertableFilter(vizFilter)">
            <ng-container *ngIf="vizFilter.state">
              <mat-icon class="chip-toggle-icon">toggle_on</mat-icon>
              <span class="chip-toggle-text">{{ vizFilter.toggleOption1.display }}</span>
            </ng-container>
            <ng-container *ngIf="!vizFilter.state">
              <mat-icon class="chip-toggle-icon">toggle_off</mat-icon>
              <span class="chip-toggle-text">{{ vizFilter.toggleOption2.display }}</span>
            </ng-container>
          </div>
          <span class="chip-label-html" [innerHTML]="vizFilter.postDisplay || ''"></span>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>

        <!-- all other chips template -->
        <mat-chip-option *ngIf="!vizFilter.toggleOption1"
                  [removable]="true"
                  (removed)="removeFilter(vizFilter)"
                  [color]="validFilterTypes.includes(vizFilter.type) ? 'primary' : 'none'"
                  [matTooltip]="validFilterTypes.includes(vizFilter.type) ? 'Filter is active on this report' : 'Filter is inactive on this report'"
                  matTooltipPosition="above"
                  [matTooltipDisabled]="vizFilter.type === EAuditReportFilterTypes.DeletedItem"
                  highlighted
        >
          <mat-icon class="warning-icon" *ngIf="vizFilter.type === EAuditReportFilterTypes.DeletedItem">warning</mat-icon>
          <span class="chip-label-html" [innerHTML]="vizFilter.display"></span>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-option>

      </ng-container>
      <mat-chip-option *ngIf="hiddenFilters.length"
        [removable]="false"
        highlighted
        [matMenuTriggerFor]="hiddenFiltersMenu"
        [color]="hiddenContainsValidTypes ? 'primary' : 'none'">
        More ({{ hiddenFilters.length }})
        <mat-icon>expand_more</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
</div>
<!-- Hidden filters menu -->
<mat-menu  #hiddenFiltersMenu="matMenu" xPosition="before" class="hidden-filter-menu">
  <div class="filter-chip hidden-filter-menu-item" mat-menu-item *ngFor="let hidFilter of hiddenFilters">
    <!-- invertable chip template -->
    <mat-chip-option *ngIf="hidFilter.toggleOption1"
              class="op-std-chip"
              [removable]="true"
              (removed)="removeFilter(hidFilter)"
              [color]="validFilterTypes.includes(hidFilter.type) ? 'primary' : 'none'"
              [matTooltip]="validFilterTypes.includes(hidFilter.type) ? 'Filter is active on this report' : 'Filter is inactive on this report'"
              matTooltipPosition="above"
              highlighted>
      <span class="chip-label-html" [innerHTML]="hidFilter.display"></span>
      <div class="chip-toggle" (click)="toggleInvertableFilter(hidFilter)">
        <ng-container *ngIf="hidFilter.state">
          <mat-icon class="chip-toggle-icon">toggle_off</mat-icon>
          <span class="chip-toggle-text">{{ hidFilter.toggleOption1.display }}</span>
        </ng-container>
        <ng-container *ngIf="!hidFilter.state">
          <mat-icon class="chip-toggle-icon">toggle_on</mat-icon>
          <span class="chip-toggle-text">{{ hidFilter.toggleOption2.display }}</span>
        </ng-container>
      </div>
      <span class="chip-label-html" [innerHTML]="hidFilter.postDisplay || ''"></span>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-option>

    <!-- all other chips template -->
    <mat-chip-option *ngIf="!hidFilter.toggleOption1"
              class="op-std-chip"
              [removable]="true"
              (removed)="removeFilter(hidFilter)"
              [color]="validFilterTypes.includes(hidFilter.type) ? 'primary' : 'none'"
              highlighted
    >
      <span class="chip-label-html" [innerHTML]="hidFilter.display"></span>
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-option>
  </div>
  <button class="filter-bar-menu-item" mat-menu-item (click)="clearFilters()" type="button">Clear All</button>
</mat-menu>
<!-- Base filter menu -->
<op-filter-bar-menu-item [matMenuClass]="matMenuClass" #menu [menuItems]="menuItems"></op-filter-bar-menu-item>

