import { Component } from '@angular/core';
import { AccountsService } from '@app/components/account/account.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'live-connect-splash-page',
  templateUrl: './live-connect-splash-page.component.html'
})

export class LiveConnectSplashPageComponent {
  userId: number;

  constructor(private accountsService: AccountsService) {
    this.accountsService.getUser().subscribe(user => {
      this.userId = user.id;
    });
  }

  goToMarketingSite() {
    window.open(`https://www.observepoint.com/liveconnect?userId=${this.userId}`);
  }
}
