<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="title" (onClose)="close()"></op-modal-header>
  </ng-template>
  
  <ng-template #bodyTemplate>
    <div class="assign-rules-container">
      <rule-selector [rules]="rules"
                     [labels]="labels"
                     [selectedItemsAndRules]="ruleSelection"
                     [hideTitle]="true"
                     (openRuleCreation)="openRuleCreation()"
                     (openRuleEditor)="openRuleEditor($event)"
                     (selectionChanged)="selectionChanged($event)">
      </rule-selector>
    </div>
  </ng-template>
</op-modal>
