import * as angular from 'angular';

export const inputMaterial = function() {
    return {
      require: 'ngModel',
      link: function(scope, element, attrs, ngModel) {
        element.addClass('input-material');
        var group = angular.element(element.parent()[0]);

        //adding hidden select
        if (attrs.bsSelect !== undefined) {
          group.append('<select style="display:none" ng-model=' + attrs.ngModel + ' name="' + attrs.name + '"></select>');
          if (!angular.isDefined(attrs.placeholder)) {
            attrs.$set('placeholder', '');
          }
        }

        //adding label
        var label = attrs.label;
        if (label) {
          group.append('<label>' + label + '</label>');
        }
        //adding floating bar when focus
        group.append('<span class="bar"></span>');

        var setHasValue = function(hasValue) {
          element.toggleClass('has-value', !!hasValue);
        };

        function update(modelValue: any) {
          var tmp = ngModel.$isEmpty(modelValue);
          setHasValue(!tmp);
          return modelValue;
        };

        ngModel.$formatters.push(update.bind(this));
        ngModel.$parsers.push(update.bind(this));

        element.on('input', function() {
          setHasValue(element.val().length > 0 || (element[0].validity || {}).badInput);
        });
      }
    }
  }

