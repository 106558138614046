import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const RfmLibraryStateNames = {
  base: 'rfm_library'
};

export const RfmLibraryPaths = {
  base: 'file-substitutions'
};

export const RfmLibraryUrlBuilders = {
  base: () => RouteBuilder.buildUrl([RfmLibraryPaths.base])
};
