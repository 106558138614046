import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { DAY_OF_WEEK_TO_RRULE_DAY, ORDINAL_TO_NUMBER } from './month-by-day-selector.constants';
import { Weekday } from 'rrule';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ENTER } from '@angular/cdk/keycodes';
import { COMMA } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'month-by-day-selector',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule
  ],
  templateUrl: './month-by-day-selector.component.html',
  styleUrls: ['./month-by-day-selector.component.scss']
})
export class MonthByDaySelectorComponent implements OnInit {

  @Input() selectedDate: Date;
  @Output() selectedOptionChange: EventEmitter<{n: number, weekday: Weekday}[]> = new EventEmitter();
  @ViewChild('dayInput') dayInput: ElementRef<HTMLInputElement>;

  dayOptions: string[] = [];
  filteredDayOptions$: Observable<string[]>;
  selectedDays: string[] = [];
  dayCtrl: FormControl = new FormControl('');

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  private daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  private ordinals = ['First', 'Second', 'Third', 'Fourth', 'Last'];

  constructor() {
    this.filteredDayOptions$ = this.dayCtrl.valueChanges.pipe(
      startWith(null),
      map((day: string | null) => (day ? this._filter(day) : this.dayOptions.slice()))
    );
  }

  ngOnInit() {
    this.generateDayOptions();
    this.loadOption();
  }

  private generateDayOptions() {
    this.ordinals.forEach(ordinal => {
      this.daysOfWeek.forEach(day => {
        this.dayOptions.push(`${ordinal} ${day}`);
      });
    });
  }

  private loadOption() {
    if (this.selectedDate) {
      const dayOfWeek = this.selectedDate.getDay();
      const dayOfMonth = this.selectedDate.getDate();
      const weekNumber = Math.floor((dayOfMonth - 1) / 7);
      const lastDayOfMonth = new Date(this.selectedDate.getFullYear(), this.selectedDate.getMonth() + 1, 0).getDate();

      let ordinal: string;
      if (dayOfMonth > lastDayOfMonth - 7) {
        ordinal = this.ordinals[4];
      } else {
        ordinal = this.ordinals[weekNumber];
      }

      const dayName = this.daysOfWeek[dayOfWeek];
      this.selectedDays.push(`${ordinal} ${dayName}`);
    }

    this.convertAndEmit(this.selectedDays);
  }

  convertAndEmit(selectedDays: string[]) {
    const values = selectedDays.map(option => {
      const [ordinal, dayName] = option.split(' ');
      return {
        n: ORDINAL_TO_NUMBER[ordinal],
        weekday: DAY_OF_WEEK_TO_RRULE_DAY[dayName],
      };
    });

    this.selectedOptionChange.emit(values);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // check if the value matches a day in the month
    const dayIndex = this.dayOptions.indexOf(value);
    if (dayIndex === -1) {
      return;
    }

    // Add the selected day only if it's not already in the list
    if (value && this.selectedDays.indexOf(value) === -1) {
      this.selectedDays.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.dayCtrl.setValue(null);
    this.convertAndEmit(this.selectedDays);
  }

  remove(day: string): void {
    const index = this.selectedDays.indexOf(day);

    if (index >= 0) {
      this.selectedDays.splice(index, 1);
    }

    this.convertAndEmit(this.selectedDays);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // Add the selected day only if it's not already in the list
    if (this.selectedDays.indexOf(event.option.viewValue) === -1) {
      this.selectedDays.push(event.option.viewValue);
    }

    this.dayInput.nativeElement.value = '';
    this.dayCtrl.setValue(null);
    this.convertAndEmit(this.selectedDays);
  }

  // TODO: TYPE THIS PROPERLY
  private _filter(value: string): any[] {
    const filterValue = value.trim().toLowerCase();
    return this.dayOptions.filter(day => day.toLowerCase().includes(filterValue));
  }
}
