import { Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { environment } from '@app/environments/environment';
import { Observable } from 'rxjs';
import { EDataSourcesSortBy, EDataSourceType } from './data-sources.constants';
import { IDataSource, IDataSourceQueryParams, IDataSources } from './data-sources.models';

@Injectable({
  providedIn: 'root'
})
export class DataSourcesService {

  apiRoot: string = `${environment.apiV3Url}data-sources`;

  constructor(private apiService: ApiService) {}

  getDataSources({ page, pageSize, sortBy, sortDesc, itemName, itemType, labelIds, withRuns }: IDataSourceQueryParams): Observable<IDataSources> {
    let queryParams = `?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}`;

    if (itemName) queryParams += `&itemName=${itemName}`;
    if (itemType) queryParams += `&itemType=${itemType}`;
    if (labelIds) queryParams += `&labelIds=${labelIds.join(',')}`;
    if (withRuns) queryParams += `&withRuns=true`;

    return this.apiService.get(`${this.apiRoot}${queryParams}`);
  }

  getAllDataSources({ sortBy, sortDesc, itemType, withRuns }): Promise<IDataSource[]> {
    return new Promise(async (resolve, reject) => {
      const pageSize = 1000;
      let currentPage = 0;
      let lastPage = 1;
      let dataSources: IDataSource[] = [];

      while (currentPage < lastPage) {
        const batchOfDataSources: IDataSources = await this.getDataSources({
          sortBy,
          sortDesc,
          itemType,
          withRuns,
          page: currentPage,
          pageSize: pageSize
        }).toPromise();

        if (batchOfDataSources.metadata.pagination.totalPageCount) {
          lastPage = batchOfDataSources.metadata.pagination.totalPageCount;
        } else {
          lastPage += batchOfDataSources.metadata.pagination.currentPageSize < pageSize ? 0 : 1;
        }

        dataSources = dataSources.concat(batchOfDataSources.dataSources);
        currentPage++;
      }

      resolve(dataSources);
    });
  }
}
