import { EPageSummaryTrendNames } from '@app/components/audit-reports/reports/page-summary/page-summary.constants';
import { AlertMetricType } from '@app/components/alert/alert-logic/alert-logic.enums';
import {
  IBoxPlotChartConfig
} from '@app/components/shared/components/viz/box-plot-mini-chart/box-plot-mini-chart.models';

export interface ISplitCardMenuItem {
  label: string;
  icon?: string;
  trendName?: EPageSummaryTrendNames;
  isActivated?: boolean;
  activatedLabel?: string;
  handler: () => void;
  disabled?: boolean;
}

export interface ISplitCardChartData {
  topLabel?: string;
  icon?: string;
  iconColor?: string;
  tooltip?: string;
  topChangeContent?: string;
  topChangeMeaning?: ESplitCardChangeMeaning;
  menuOptions?: ISplitCardMenuItem[];
  trendName?: EPageSummaryTrendNames;
  includeFullscreenChart?: boolean;
  topHandler?: () => void;
  bottomHandler?: () => void;
  fullscreenChartHandler?: () => void;
  [k: string]: any
  metricType?: AlertMetricType;
  currentValue?: number;
  rawValue?: number;
  digitsInfo?: string;
  boxPlotDataConfig?: IBoxPlotChartConfig;
  uniqueId?: string;
}

export enum ESplitCardChangeMeaning {
  NONE = 'none',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  SORT_OF_POSITIVE = 'sortofpositive',
  SORT_OF_NEGATIVE = 'sortofnegative'
}
