import { EAuditReportType } from '@app/components/audit-reports/audit-report/audit-report.enums';
import {
  AlertMetricType,
  EAlertAuditSummaryMetric as EAuditSummaryMetric,
  EAlertBrowserConsoleLogsMetric as EBrowserConsoleLogsMetric,
  EAlertCookieInventoryMetric as ECookieInventoryMetric,
  EAlertDuplicatesAndMultiplesMetric as EDuplicatesAndMultiplesMetric,
  EAlertJavaScriptFileChangesMetric as EJavaScriptFileChangesMetric,
  EAlertMetricChangeType as EMetricChangeType,
  EAlertMetricValueComparator as EMetricValueComparator,
  EAlertPageSummaryMetric as EPageSummaryMetric,
  EAlertPageSummaryWebVitalsMetric,
  EAlertPrivacyCookiesMetric as EPrivacyCookiesMetric,
  EAlertPrivacyTagsMetric as EPrivacyTagsMetric,
  EAlertRequestsDomainsAndGeosMetric as ERequestsDomainsAndGeosMetric,
  EAlertTagAndVariableRulesMetric as ETagAndVariableRulesMetric,
  EAlertTagHealthMetric as ETagHealthMetric,
  EAlertTagInventoryMetric as ETagInventoryMetric,
  EAlertUsageMetricV2,
  EAlertVariableInventoryMetric as EVariableInventoryMetric,
} from './alert-logic.enums';
import { IAlertOperator, IAlertReportMetricsConfig, IAlertReportToMetricsConfig } from './alert-logic.models';
import { AuditReportPaths } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { UsageV2Paths } from '@app/components/usage-v2/usage-v2.constants';
import { AlertUtils } from '@app/components/alert/alert.utils';

export const enum AlertThresholdByIndex {
  GreaterOrEqual = 0,
  Greater = 1,
  LessOrEqual = 2,
  Less = 3,
  Equal = 4,
  NotEqual = 5,
}

/**
 * NOTE: THE ORDER OF THESE AlertMetricThresholdOperators MATTERS - They are being assigned as default operators to
 * metric configs based on index. If changing the order, be sure to update the `defaultValue` operator contained inside
 * the AlertReportsToMetrics members, too.
 *
 * GreaterOrEqual = 0
 * Greater = 1
 * LessOrEqual = 2
 * Less = 3
 * Equal = 4
 * NotEqual = 5
 */
export const AlertMetricThresholdOperators: IAlertOperator[] = [
  {
    comparator: EMetricValueComparator.GreaterOrEqual,
    title: 'Greater than or equal to',
    sign: '≥'
  },
  {
    comparator: EMetricValueComparator.Greater,
    title: 'Greater than',
    sign: '>'
  },
  {
    comparator: EMetricValueComparator.LessOrEqual,
    title: 'Less than or equal to',
    sign: '≤'
  },
  {
    comparator: EMetricValueComparator.Less,
    title: 'Less than',
    sign: '<'
  },
  {
    comparator: EMetricValueComparator.Equal,
    title: 'Equal to',
    sign: '='
  },
  {
    comparator: EMetricValueComparator.NotEqual,
    title: 'Not equal to',
    sign: '≠'
  },
];

const ChangesByOperator: IAlertOperator = {
  comparator: EMetricValueComparator.GreaterOrEqual,
  changeType: EMetricChangeType.ValueChangeAbs,
  title: 'Changes by',
  sign: '∆'
};
const IncreasesByOperator: IAlertOperator = {
  comparator: EMetricValueComparator.GreaterOrEqual,
  changeType: EMetricChangeType.ValueChange,
  title: 'Increases by',
  sign: '+'
};
const DecreasesByOperator: IAlertOperator = {
  comparator: EMetricValueComparator.LessOrEqual,
  changeType: EMetricChangeType.ValueChange,
  title: 'Decreases by',
  sign: '-'
};
export const AnyChangeOperator: IAlertOperator = {
  comparator: EMetricValueComparator.NotEqual,
  changeType: EMetricChangeType.ValueChangeAbs,
  title: 'Any change',
  sign: '±'
};
const ChangesByAbsOperator: IAlertOperator = {
  comparator: EMetricValueComparator.GreaterOrEqual,
  changeType: EMetricChangeType.RelativeValueChangeAbs,
  title: 'Changes by',
  sign: '∆ %'
};
const IncreasesByAbsOperator: IAlertOperator = {
  comparator: EMetricValueComparator.GreaterOrEqual,
  changeType: EMetricChangeType.RelativeValueChange,
  title: 'Increases by',
  sign: '+%'
};
const DecreasesByAbsOperator: IAlertOperator = {
  comparator: EMetricValueComparator.LessOrEqual,
  changeType: EMetricChangeType.RelativeValueChange,
  title: 'Decreases by',
  sign: '-%'
};

export const AlertMetricChangeOperators = [
  ChangesByOperator,
  IncreasesByOperator,
  DecreasesByOperator,
  AnyChangeOperator,
  ChangesByAbsOperator,
  IncreasesByAbsOperator,
  DecreasesByAbsOperator,
];

export const AlertOperatorGroups = [
  { name: 'Threshold', operators: AlertMetricThresholdOperators },
  { name: 'Change (Run Over Run)', operators: AlertMetricChangeOperators }
];

const CommonMetricValueConverters = {
  MillisecondsSeconds: {
    uiToApi: AlertUtils.secondsToMilliseconds,
    apiToUi: AlertUtils.millisecondsToSeconds
  }
}

const AlertAuditSummaryConfig: IAlertReportToMetricsConfig = {
  name: 'Summary',
  type: EAuditReportType.AuditSummary,
  link: `${AuditReportPaths.base}/${AuditReportPaths.auditSummary}`,
  metrics: [
    {
      name: 'Audit Score',
      value: EAuditSummaryMetric.AuditScore,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less]
      },
    },
    {
      name: 'Tag Presence Score',
      value: EAuditSummaryMetric.TagPresenceScore,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less],
      },
    },
    {
      name: 'Tag Performance Score',
      value: EAuditSummaryMetric.TagPerformanceScore,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less],
      },
    },
    {
      name: 'Rule Score',
      value: EAuditSummaryMetric.RuleScore,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less],
      },
    },
    {
      name: 'Page Performance Score',
      value: EAuditSummaryMetric.PagePerformanceScore,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less],
      },
    },
    {
      name: 'Cookie Score',
      value: EAuditSummaryMetric.CookieScore,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less],
      },
    },
  ]
};

const AlertPageSummaryConfig: IAlertReportToMetricsConfig = {
  name: 'Pages',
  type: EAuditReportType.PageSummary,
  link: `${AuditReportPaths.base}/${AuditReportPaths.pageSummary}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: EPageSummaryMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Average Page Load Time',
      value: EPageSummaryMetric.AveragePageLoadTime,
      unit: 'sec',
      valueConverters: CommonMetricValueConverters.MillisecondsSeconds,
      defaultValue: {
        value: 3,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Broken Initial Pages',
      value: EPageSummaryMetric.BrokenInitialPages,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Broken Final Pages',
      value: EPageSummaryMetric.BrokenFinalPages,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Pages with Broken Links',
      value: EPageSummaryMetric.PagesWithBrokenLinks,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Web Vitals',
      metrics: [
        {
          name: 'Largest Contentful Paint',
          value: EAlertPageSummaryWebVitalsMetric.LargestContentfulPaint,
          unit: 'sec',
          valueConverters: CommonMetricValueConverters.MillisecondsSeconds,
          defaultValue: {
            value: 2.5,
            useCurrent: false,
            operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
          },
        },
        {
          name: 'First Contentful Paint',
          value: EAlertPageSummaryWebVitalsMetric.FirstContentfulPaint,
          unit: 'sec',
          valueConverters: CommonMetricValueConverters.MillisecondsSeconds,
          defaultValue: {
            value: 1.8,
            useCurrent: false,
            operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
          },
        },
        {
          name: 'Time To First Byte',
          value: EAlertPageSummaryWebVitalsMetric.TimeToFirstByte,
          unit: 'ms',
          defaultValue: {
            value: 800,
            useCurrent: false,
            operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
          },
        },
        {
          name: 'Cumulative Layout Shift',
          value: EAlertPageSummaryWebVitalsMetric.CumulativeLayoutShift,
          unit: '',
          defaultValue: {
            value: 0.1,
            useCurrent: false,
            operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
          },
        },
      ],
      value: null,
      defaultValue: null,
      isChild: true,
    },
  ]
};

const AlertTagInventoryConfig: IAlertReportToMetricsConfig = {
  name: 'Tag Inventory',
  type: EAuditReportType.TagInventory,
  link: `${AuditReportPaths.base}/${AuditReportPaths.tagInventory}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: ETagInventoryMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unique Tags',
      value: ETagInventoryMetric.UniqueTags,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Broken Tags',
      value: ETagInventoryMetric.BrokenTags,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Broken Pages',
      value: ETagInventoryMetric.BrokenPages,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertTagHealthConfig: IAlertReportToMetricsConfig = {
  name: 'Tag Health',
  type: EAuditReportType.TagHealth,
  link: `${AuditReportPaths.base}/${AuditReportPaths.tagHealth}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: ETagHealthMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Avg Tag Load Time', value: ETagHealthMetric.AvgTagLoadTime,
      unit: 'ms',
      defaultValue: {
        value: 500,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Avg Tag Request Size', value: ETagHealthMetric.AvgTagRequestSize,
      unit: 'bytes',
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: '% Slow Loading Tags',
      unit: '%',
      value: ETagHealthMetric.PercentageOfSlowLoadingTags,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: '% of Broken Tags',
      unit: '%',
      value: ETagHealthMetric.PercentageOfOfBrokenTags,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Total Tag Requests',
      value: ETagHealthMetric.TotalTagRequests,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertVariableInventoryConfig: IAlertReportToMetricsConfig = {
  name: 'Variable Inventory',
  type: EAuditReportType.VariableInventory,
  link: `${AuditReportPaths.base}/${AuditReportPaths.variableInventory}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: EVariableInventoryMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unique Tags',
      value: EVariableInventoryMetric.UniqueTags,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Tag Requests Evaluated',
      value: EVariableInventoryMetric.TagRequestsEvaluated,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unique Variables',
      value: EVariableInventoryMetric.UniqueVariables,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unique Values',
      value: EVariableInventoryMetric.UniqueValues,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertTagAndVariableRulesConfig: IAlertReportToMetricsConfig = {
  name: 'Tag & Variable Rules',
  type: EAuditReportType.RuleSummary,
  link: `${AuditReportPaths.base}/${AuditReportPaths.ruleSummary}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: ETagAndVariableRulesMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Rules Assigned',
      value: ETagAndVariableRulesMetric.RulesAssigned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less],
      },
    },
    {
      name: 'Rule Failures',
      value: ETagAndVariableRulesMetric.RuleFailures,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Failed Instances',
      value: ETagAndVariableRulesMetric.FailedInstances,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Passed Instances',
      value: ETagAndVariableRulesMetric.PassedInstances,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less],
      },
    },
    {
      name: 'Not Applied Instances',
      value: ETagAndVariableRulesMetric.NotAppliedInstances,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Failed Pages',
      value: ETagAndVariableRulesMetric.FailedPages,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Passed Pages',
      value: ETagAndVariableRulesMetric.PassedPages,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less],
      },
    },
    {
      name: 'Not Applied Pages',
      value: ETagAndVariableRulesMetric.NotAppliedPages,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertDuplicatesAndMultiplesConfig: IAlertReportToMetricsConfig = {
  name: 'Duplicates & Multiples',
  type: EAuditReportType.DuplicatesAndMultiples,
  link: `${AuditReportPaths.base}/${AuditReportPaths.duplicatesAndMultiples}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: EDuplicatesAndMultiplesMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unique Tags',
      value: EDuplicatesAndMultiplesMetric.UniqueTags,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Total Tag Requests',
      value: EDuplicatesAndMultiplesMetric.TotalTagRequests,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Total Duplicates',
      value: EDuplicatesAndMultiplesMetric.TotalDuplicates,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Total Multiples',
      value: EDuplicatesAndMultiplesMetric.TotalMultiples,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertCookieInventoryConfig: IAlertReportToMetricsConfig = {
  name: 'Cookie Inventory',
  type: EAuditReportType.CookieInventory,
  link: `${AuditReportPaths.base}/${AuditReportPaths.cookieInventory}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: ECookieInventoryMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unique Cookies',
      value: ECookieInventoryMetric.UniqueCookies,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: '1st Party',
      value: ECookieInventoryMetric.FirstParty,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: '3rd Party',
      value: ECookieInventoryMetric.ThirdParty,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Non-Secure',
      value: ECookieInventoryMetric.NonSecure,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'SameSite Empty',
      value: ECookieInventoryMetric.SameSiteEmpty,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertBrowserConsoleLogsConfig: IAlertReportToMetricsConfig = {
  name: 'Browser Console Logs',
  type: EAuditReportType.BrowserConsoleLogs,
  link: `${AuditReportPaths.base}/${AuditReportPaths.browserConsoleLogs}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: EBrowserConsoleLogsMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Errors',
      value: EBrowserConsoleLogsMetric.Errors,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Warnings',
      value: EBrowserConsoleLogsMetric.Warnings,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Info',
      value: EBrowserConsoleLogsMetric.Info,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Debug',
      value: EBrowserConsoleLogsMetric.Debug,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Other',
      value: EBrowserConsoleLogsMetric.Other,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertPrivacyCookiesConfig: IAlertReportToMetricsConfig = {
  name: 'Privacy Cookies',
  type: EAuditReportType.PrivacyCookies,
  link: `${AuditReportPaths.base}/${AuditReportPaths.privacyCookies}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: EPrivacyCookiesMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Pages with Unapproved Cookies',
      value: EPrivacyCookiesMetric.PagesWithUnapprovedCookies,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unique Cookies',
      value: EPrivacyCookiesMetric.UniqueCookies,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Approved Cookies',
      value: EPrivacyCookiesMetric.ApprovedCookies,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unapproved Cookies',
      value: EPrivacyCookiesMetric.UnapprovedCookies,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertPrivacyTagsConfig: IAlertReportToMetricsConfig = {
  name: 'Privacy Tags',
  type: EAuditReportType.PrivacyTags,
  link: `${AuditReportPaths.base}/${AuditReportPaths.privacyTags}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: EPrivacyTagsMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Pages with Unapproved Tags',
      value: EPrivacyTagsMetric.PagesWithUnapprovedTags,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Tags Evaluated',
      value: EPrivacyTagsMetric.UniqueTags,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Approved Tags',
      value: EPrivacyTagsMetric.ApprovedTags,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unapproved Tags',
      value: EPrivacyTagsMetric.UnapprovedTags,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertRequestsDomainsAndGeosConfig: IAlertReportToMetricsConfig = {
  name: 'Requests Domains & Geos',
  type: EAuditReportType.PrivacyRequests,
  link: `${AuditReportPaths.base}/${AuditReportPaths.privacyRequests}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: ERequestsDomainsAndGeosMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Pages with Unapproved Items',
      value: ERequestsDomainsAndGeosMetric.PagesWithUnapprovedItems,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Network Requests Evaluated',
      value: ERequestsDomainsAndGeosMetric.NetworkRequestsEvaluated,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unique Domains',
      value: ERequestsDomainsAndGeosMetric.UniqueDomains,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unique Geolocations',
      value: ERequestsDomainsAndGeosMetric.UniqueGeolocations,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Approved Domains',
      value: ERequestsDomainsAndGeosMetric.ApprovedDomains,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Unapproved Domains',
      value: ERequestsDomainsAndGeosMetric.UnapprovedDomains,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

const AlertJavaScriptFileChangesConfig: IAlertReportToMetricsConfig = {
  name: 'JavaScript File Changes',
  type: EAuditReportType.JsFileChanges,
  link: `${AuditReportPaths.base}/${AuditReportPaths.privacyFileChanges}`,
  metrics: [
    {
      name: 'Pages Scanned',
      value: EJavaScriptFileChangesMetric.PagesScanned,
      defaultValue: {
        value: 0,
        useCurrent: true,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Changed Files',
      value: EJavaScriptFileChangesMetric.ChangedFiles,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'New Files',
      value: EJavaScriptFileChangesMetric.NewFiles,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'File Date Changes',
      value: EJavaScriptFileChangesMetric.FileDateChanges,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'File Size Changes',
      value: EJavaScriptFileChangesMetric.FileSizeChanges,
      defaultValue: {
        value: 0,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
  ]
};

/* Original Usage alerts are DEPRECATED
 * The new Usage alerts are in the next section
 */
const AlertUsageConfigV2: IAlertReportToMetricsConfig = {
  name: 'Usage',
  type: EAuditReportType.Usage,
  link: `${UsageV2Paths.base}`,
  metrics: [
    {
      name: 'Pages Scanned in Current Term',
      value: EAlertUsageMetricV2.AccountUsageCurTermAuditPageScannedCount,
      unit: '%',
      postString: 'of Purchased Volume',
      defaultValue: {
        value: 70,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Pages Scanned % of Monthly Pacing',
      value: EAlertUsageMetricV2.AccountUsageMonthPaceAuditPageScannedCount,
      unit: '%',
      postString: 'of monthly pacing',
      defaultValue: {
        value: 90,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Journey Runs in Current Term',
      value: EAlertUsageMetricV2.AccountUsageCurTermWebJourneyRunsCount,
      unit: '%',
      postString: 'of Purchased Volume',
      defaultValue: {
        value: 70,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Journey Runs % of Monthly Pacing',
      value: EAlertUsageMetricV2.AccountUsageMonthPaceWebJourneyRunsCount,
      unit: '%',
      postString: 'of monthly pacing',
      defaultValue: {
        value: 90,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Greater],
      },
    },
    {
      name: 'Users Logged In Last 30 Days',
      value: EAlertUsageMetricV2.UsersLoggedInLast30Days,
      defaultValue: {
        value: 1,
        useCurrent: false,
        operator: AlertMetricThresholdOperators[AlertThresholdByIndex.Less],
      },
    },
  ]
};

export const AlertReportsToAuditMetrics = [
  AlertAuditSummaryConfig,
  AlertPageSummaryConfig,
  AlertTagAndVariableRulesConfig,
  AlertTagInventoryConfig,
  AlertTagHealthConfig,
  AlertVariableInventoryConfig,
  AlertDuplicatesAndMultiplesConfig,
  AlertCookieInventoryConfig,
  AlertBrowserConsoleLogsConfig,
  AlertPrivacyCookiesConfig,
  AlertPrivacyTagsConfig,
  AlertRequestsDomainsAndGeosConfig,
  AlertJavaScriptFileChangesConfig
];

const AlertAuditSectionConfig = {
  name: 'Audits',
  metrics: [...AlertReportsToAuditMetrics]
};

export const AlertReportsToAllMetrics = [
  ...AlertReportsToAuditMetrics,
  AlertUsageConfigV2
];

// Cached map of AlertMetricType to IAlertReportToMetricsConfig and IAlertReportMetricsConfig
// It significantly speeds up the process of finding the report and metric config for a given metric type
export const AlertMetricTypeToReportAndMetricConfigs: Map<AlertMetricType, {
  reportConfig: IAlertReportToMetricsConfig,
  metricConfig: IAlertReportMetricsConfig
}> = AlertReportsToAllMetrics.reduce((resultMap, reportConfig) => {
  // Get the child metrics (implemented for Web Vitals which are part of Page Summary, but visually separated)
  const metricsFlattened = reportConfig.metrics.flatMap(metric => metric.metrics || metric);
  metricsFlattened.forEach(metricConfig => {
    resultMap.set(metricConfig.value, {
      reportConfig,
      metricConfig
    });
  })
  return resultMap;
}, new Map());

// this needs to be updated to be grouped in the future
export const AlertReportsToAlertLibraryMetrics = [
  AlertAuditSectionConfig,
  AlertUsageConfigV2,
];

export const AlertReportsToUsageMetricsV2 = AlertUsageConfigV2.metrics;

export const UsagePostScriptsByMetricV2 = {
  [EAlertUsageMetricV2.AccountUsageCurTermAuditPageScannedCount]: 'of Purchased Volume',
  [EAlertUsageMetricV2.AccountUsageMonthPaceAuditPageScannedCount]: 'of monthly pacing',
  [EAlertUsageMetricV2.AccountUsageCurTermWebJourneyRunsCount]: 'of Purchased Volume',
  [EAlertUsageMetricV2.AccountUsageMonthPaceWebJourneyRunsCount]: 'of monthly pacing'
};
