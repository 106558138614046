export const enum WebJourneyCreatorTitle {
  Create = 'Create Web Journey',
  Edit = 'Edit Web Journey',
}

export enum EWebJourneyTab {
  setup,
  actions,
  standards,
}

export const TAB_ID_TO_LABEL_MAP = new Map<EWebJourneyTab, string>([
  [ EWebJourneyTab.setup, 'Journey Setup' ],
  [ EWebJourneyTab.actions, 'Actions' ],
  [ EWebJourneyTab.standards, 'Standards' ],
]);

export const ExplanationText = `Standards assigned here will be applied to the results
of all pages visited and actions executed. To apply specific standards to individual
actions configure those on the actions tab.`

export const LearnMoreLink = 'https://help.observepoint.com/en/articles/9089312-data-sources-standards-and-configurations';
export const ActionsLearnMoreLink = 'https://help.observepoint.com/en/articles/9101987-creating-journeys';

export const ErrorMessage = 'There is an issue with one or more of the fields above.';
