<div class="spinner-wrap" *ngIf="loading">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>
<div class="title">Event Log</div>
<div class="event-filter-options">
  <div class="event-filter-options-wrap">
    <div class="filter-option">
      <mat-form-field>
        <mat-label>Item Type</mat-label>
        <mat-select [(ngModel)]="itemType">
          <mat-option *ngFor="let item of itemTypes" [value]="item.key">
            {{ item.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="filter-option">
      <mat-form-field>
        <mat-label>User</mat-label>
        <mat-select [(ngModel)]="user">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let user of users" [value]="user.id">
            {{ user.firstName }} {{ user.lastName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="filter-option">
      <mat-form-field>
        <mat-label>Event Type</mat-label>
        <mat-select [(ngModel)]="eventType">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let event of eventTypes" [value]="event.key">
            {{ event.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="filter-option">
      <mat-form-field>
        <mat-label>Item ID</mat-label>
        <input matInput [(ngModel)]="itemId" autocomplete="off">
      </mat-form-field>
    </div>
    <div class="filter-option">
      <mat-form-field>
        <mat-label>From</mat-label>
        <input matInput [matDatepicker]="fromPicker" [(ngModel)]="from" autocomplete="off" disabled>
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker disabled="false"></mat-datepicker>
      </mat-form-field>
      <small class="clear-date-field" (click)="clearDateField('from')" *ngIf="from">clear field</small>
    </div>
    <div class="filter-option">
      <mat-form-field>
        <mat-label>To</mat-label>
        <input matInput [matDatepicker]="toPicker" [(ngModel)]="to" autocomplete="off" disabled>
        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker disabled="false"></mat-datepicker>
      </mat-form-field>
      <small class="clear-date-field" (click)="clearDateField('to')" *ngIf="to">clear field</small>
    </div>
    <div class="filter-option filter-option-button">
      <button mat-flat-button
              color="primary"
              class="filter-events-button"
              (click)="getFilteredEvents()">
        Submit
      </button>
    </div>
  </div>
</div>
<div class="table-container">
  <div class="events-table-wrap"
       [class.hidden]="loading"
       #scrollTopUserEvents>
    <table mat-table [dataSource]="dataSource" matSort class="events-table styled-mat-table" matSortActive="timestamp" matSortDirection="desc">
      <!-- User ID Column -->
      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
        <td mat-cell *matCellDef="let event">{{ event.userId }}</td>
      </ng-container>

      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Username</th>
        <td mat-cell *matCellDef="let event">{{ event.userName }}</td>
      </ng-container>

      <!-- Event Type Column -->
      <ng-container matColumnDef="eventType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Event Type</th>
        <td mat-cell *matCellDef="let event">{{ event.eventType }}</td>
      </ng-container>

      <!-- Item Type Column -->
      <ng-container matColumnDef="itemType">
        <th mat-header-cell *matHeaderCellDef>Item Type</th>
        <td mat-cell *matCellDef="let event">{{ event.itemType }}</td>
      </ng-container>

      <!-- Item ID Column -->
      <ng-container matColumnDef="itemId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item ID</th>
        <td mat-cell *matCellDef="let event">{{ event.itemId }}</td>
      </ng-container>

      <!-- Item Name Column -->
      <ng-container matColumnDef="itemName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Item Name</th>
        <td mat-cell *matCellDef="let event" class="multiline">{{ event.itemName }}</td>
      </ng-container>

      <!-- Timestamp Column -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
        <td mat-cell *matCellDef="let event">{{ event.timestamp }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="page-controls">
    <mat-icon class="control"
              [class.disabled]="pageIndex === 0"
              (click)="loadPreviousPage()">
      chevron_left
    </mat-icon>
    <mat-icon class="control"
              [class.disabled]="!nextPage"
              (click)="loadNextPage()">
      chevron_right
    </mat-icon>
  </div>
</div>
