export enum ERulesV3FilterTypes {
  RuleName = 'ruleName',
  FromTemplate = 'fromTemplate',
  Label = 'labels',
  CreatedBy = 'createdByUserId',
  Notification = 'notificationEnabled'
}

export enum ESortColumnsV3 {
  Name = 'name',
  Type = 'type',
  Audits = 'audits',
  WebJourneys = 'web_journeys',
  Owner = 'owner',
  ModifiedAt = 'modified_at',
}

export enum ERuleUsageType {
  action, global, actionAndGlobal
}