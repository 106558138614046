import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import {
  IStackedBarChartDataPoint, IStackedLimitBarChartFormattedDataPoint
} from '@app/components/shared/components/viz/horizontal-stacked-limit-bar-chart/horizontal-stacked-limit-bar-chart.models';
import { EChartColor } from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  HorizontalStackedLimitBarChartComponent
} from '@app/components/shared/components/viz/horizontal-stacked-limit-bar-chart/horizontal-stacked-limit-bar-chart.component';

enum EWebJourneySummaryTypes {
  Monitored,
  Total
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-webjourney-summary',
  templateUrl: './usage-webjourney-summary-chart.component.html',
  styleUrls: ['./usage-webjourney-summary-chart.component.scss']
})
export class UsageWebJourneySummaryChartComponent implements OnChanges {
  @ViewChild(HorizontalStackedLimitBarChartComponent) horizontalStackedLimitBarChartComponent: HorizontalStackedLimitBarChartComponent;
  @Input() monitored: number;
  @Input() total: number;
  @Input() requests: number;

  data: [IStackedBarChartDataPoint, IStackedBarChartDataPoint];
  currentMonth = (new Date).toLocaleString('default', { month: 'long' });
  additionalTooltipClass = 'wj-summary-chart-tooltip';
  ngOnChanges() {
    this.data = [
      {
        id: EWebJourneySummaryTypes.Total,
        value: this.total,
        filtered: false,
        name: '',
        colorClass: EChartColor.Gray
      },
      {
        id: EWebJourneySummaryTypes.Monitored,
        value: this.monitored,
        filtered: false,
        name: '',
        colorClass: EChartColor.Blue,
      }
    ];

    setTimeout(() => this.horizontalStackedLimitBarChartComponent.redrawCharts(), 2000);
  }

  updateChart() {
    this.horizontalStackedLimitBarChartComponent.redrawCharts();
  }

  getTooltipFn = (data: IStackedLimitBarChartFormattedDataPoint): string => {
    if (data.id === EWebJourneySummaryTypes.Monitored) {
      return this.getTooltipTemplate('Journeys currently being monitored by the ObservePoint support team.');
    } else {
      return this.getTooltipTemplate('The number of journey fixes by our support team remaining in the current month.');
    }
  }

  getTooltipTemplate(message: string) {
    return `<div class="message">${message}</div>`;
  }
}
