import {
  EComplianceItemIdType,
  ERuleResultType,
  IAuditRunRuleConditionResult,
  IRuleConditionExpectedValue
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.models';
import { IConditionResultSegment } from '@app/components/audit-reports/reports/rule-summary/rule-summary-pages-specific/rule-summary-pages-specific.component';
import { ERuleMatchType } from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';

export function mkConditionResultSegments(conditionResult: IAuditRunRuleConditionResult, ruleResultType: ERuleResultType): IConditionResultSegment[] {
  switch (conditionResult.idType) {
    case EComplianceItemIdType.Filter: return makeFilterSegments(conditionResult, ruleResultType);
    case EComplianceItemIdType.Tag: return makeTagSegments(conditionResult, ruleResultType);
    case EComplianceItemIdType.Variable: return makeVariableSegments(conditionResult, ruleResultType);
    default:
      console.error('Unhandled condition result idType', conditionResult);
      return [_nh(`Unhandled condition result idType ${conditionResult.idType}`)];
  }
}

function makeFilterSegments(conditionResult: IAuditRunRuleConditionResult, ruleResultType: ERuleResultType): IConditionResultSegment[] {
  return [
    _nh('Filter'),
    _h1(conditionResult.name),
    ruleResultType === ERuleResultType.NotApplied ? _nh(oppositeMatcher(conditionResult.matcher)) : _nh(conditionResult.matcher),
    ...makeExpectedSegments(conditionResult.expected)
  ];
}

function makeTagSegments(conditionResult: IAuditRunRuleConditionResult, ruleResultType: ERuleResultType): IConditionResultSegment[] {
  let segments: IConditionResultSegment[] = [];
  if (ruleResultType === ERuleResultType.NotApplied) {
    segments = [...makeFilterSegments(conditionResult, ruleResultType)];
  } else {
    let segments = [
      _nh('Tag'),
      _h1(conditionResult.name),
    ]

    if (conditionResult['account'] && conditionResult['accountMatchType']) {
      segments.push(_nh(accountMatcher(conditionResult['accountMatchType'])));
      segments.push(_h1(conditionResult['account']));
    }

    segments.push(ruleResultType === ERuleResultType.Failed ? _nh(oppositeMatcher(conditionResult.matcher)) : _nh(conditionResult.matcher));
    segments.push(...makeExpectedSegments(conditionResult.expected));
    return segments;
  }
  return segments;
}

function makeVariableSegments(conditionResult: IAuditRunRuleConditionResult, ruleResultType: ERuleResultType): IConditionResultSegment[] {
  let segments: IConditionResultSegment[] = [];
  if (ruleResultType === ERuleResultType.NotApplied) {
    segments = [...makeFilterSegments(conditionResult, ruleResultType)];
  } else if (ruleResultType === ERuleResultType.Failed) {
    segments = [
      _nh('Variable'),
      _h1(conditionResult.name)
    ];
    if (typeof conditionResult.actual !== 'undefined' && conditionResult.actual !== null) {
      segments.push(_nh(oppositeMatcher(conditionResult.matcher)));
      segments.push(...makeExpectedSegments(conditionResult.expected));
      segments.push({
        value: 'actual value',
        cssClass: 'no-highlight'
      }, {
        value: conditionResult.actual === '' ? 'is empty' : conditionResult.actual,
        cssClass: 'highlight-1'
      });
    } else {
      segments.push(_nh('is missing'));
    }
  } else {
    segments = [
      _nh('Variable'),
      _h1(conditionResult.name),
      _nh(conditionResult.matcher)
    ];
    if (typeof conditionResult.actual !== 'undefined' && conditionResult.actual !== null) {
      segments.push(...makeExpectedSegments(conditionResult.expected));
      segments.push({
        value: 'actual value',
        cssClass: 'no-highlight'
      }, {
        value: conditionResult.actual === '' ? 'is empty' : conditionResult.actual,
        cssClass: 'highlight-1'
      });
    }
  }
  return segments;
}

function makeExpectedSegments(expected: IRuleConditionExpectedValue): IConditionResultSegment[] {
  const segments: IConditionResultSegment[] = [];
  if (expected.selectorType) {
    segments.push({
      value: expected.selectorType,
      cssClass: 'no-highlight'
    });
    if (expected.name) {
      segments.push({
        value: expected.name,
        cssClass: 'highlight-2'
      });
    }
  }
  if (expected.expected && expected.expected.length > 0) {
    segments.push({
      value: expected.expected?.join(', ') || '',
      cssClass: 'highlight-1'
    });
  }
  return segments;
}

function oppositeMatcher(matcher: string): string {
  switch (matcher) {
    case 'unknown': return '?';
    case 'is present': return 'not found';
    case '=': return 'has different value than';
    case '!=': return 'has different value than';
    case 'contains': return 'does not contain';
    case 'does not contain': return 'contains';
    case 'matches regex': return 'does not match regex';
    case 'is set': return 'missing';
    case 'is not set': return 'found';
    case '>=': return 'is not >= than';
    case '<=': return 'is not <= than';
    case '= (ignore case)': return 'has different value than';
    case '!= (ignore case)': return 'has different value than';
    case 'contains (ignore case)': return 'does not contain (ignore case)';
    case 'does not contain (ignore case)': return 'contains (ignore case)';
  }
}

function accountMatcher(matcher: string): string {
  switch (matcher) {
    case ERuleMatchType.Equals: return 'with account';
    case ERuleMatchType.NotEqual: return 'with account not equal to';
    case ERuleMatchType.Contains: return 'with account containing';
    case ERuleMatchType.DoesNotContain: return 'with account not containing';
    case ERuleMatchType.Regex: return 'with account matching regex';
  }

}

function _nh(value: string): IConditionResultSegment {
  return {
    value: value,
    cssClass: 'no-highlight'
  };
}

function _h1(value: string): IConditionResultSegment {
  return {
    value: value,
    cssClass: 'highlight-1'
  };
}

// copy to clipboard
export function mkConditionResultSegmentsToCopy(conditionResult: IAuditRunRuleConditionResult, ruleResultType: ERuleResultType): string {
  switch (conditionResult.idType) {
    case EComplianceItemIdType.Filter: return makeFilterSegmentsToCopy(conditionResult, ruleResultType);
    case EComplianceItemIdType.Tag: return makeTagSegmentsToCopy(conditionResult, ruleResultType);
    case EComplianceItemIdType.Variable: return makeVariableSegmentsToCopy(conditionResult, ruleResultType);
    default:
      console.error('Unhandled condition result idType', conditionResult);
      return `Unhandled condition result idType ${conditionResult.idType}`;
  }
}

function makeFilterSegmentsToCopy(conditionResult: IAuditRunRuleConditionResult, ruleResultType: ERuleResultType): string {
  return `Filter "${conditionResult.name}" ` + (ruleResultType === ERuleResultType.NotApplied ? oppositeMatcher(conditionResult.matcher) : conditionResult.matcher) + makeExpectedSegmentsToCopy(conditionResult.expected);
}

function makeTagSegmentsToCopy(cr: IAuditRunRuleConditionResult, ruleResultType: ERuleResultType): string {
  if (ruleResultType === ERuleResultType.NotApplied) {
    return makeFilterSegmentsToCopy(cr, ruleResultType);
  } else {
    return `Tag "${cr.name}" ` + (ruleResultType === ERuleResultType.Failed ? oppositeMatcher(cr.matcher) : cr.matcher) + makeExpectedSegmentsToCopy(cr.expected);
  }
}

function makeVariableSegmentsToCopy(conditionResult: IAuditRunRuleConditionResult, ruleResultType: ERuleResultType): string {
  let segments: string[] = [];
  if (ruleResultType === ERuleResultType.NotApplied) {
    segments.push(makeFilterSegmentsToCopy(conditionResult, ruleResultType));
  } else if (ruleResultType === ERuleResultType.Failed) {
    segments = [
      'Variable',
      `"${conditionResult.name}"`
    ];

    if (typeof conditionResult.actual !== 'undefined' && conditionResult.actual !== null) {
      segments.push(oppositeMatcher(conditionResult.matcher));
      segments.push(makeExpectedSegmentsToCopy(conditionResult.expected));

      segments.push('actual value', conditionResult.actual === '' ? 'is empty' : `"${conditionResult.actual}"`);
    } else {
      segments.push('is missing');
    }
  } else {
    segments = [
      'Variable',
      `"${conditionResult.name}"`,
      conditionResult.matcher
    ];

    if (typeof conditionResult.actual !== 'undefined' && conditionResult.actual !== null) {
      segments.push(makeExpectedSegmentsToCopy(conditionResult.expected));
      segments.push('actual value', conditionResult.actual === '' ? 'is empty' : `"${conditionResult.actual}"`);
    }
  }

  return segments.join(' ');
}

function makeExpectedSegmentsToCopy(expected: IRuleConditionExpectedValue): string {
  const segments: string[] = [];

  if (expected.selectorType) {
    segments.push(expected.selectorType);

    if (expected.name) {
      segments.push(`"${expected.name}"`);
    }
  }

  if (expected.expected && expected.expected.length > 0) {
    segments.push(expected.expected?.map(v => `"${v}"`).join(', ') || '');
  }

  return segments.join(' ');
}
