import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EBrowserRedirectType } from '../../page-details/page-details.enums';
import { IPageRedirectPage } from '../page-redirects.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-redirect',
  templateUrl: './page-redirect.component.html',
  styleUrls: ['./page-redirect.component.scss']
})
export class PageRedirectComponent {

  @Input() pageDetails: IPageRedirectPage;
  @Input() isFinal = false;
  @Input() isAllowedCopyAll = false;

  @Output() copyAllRedirects = new EventEmitter();

  copyMenuOpened = false;

  getStatusCodeTooltipText(): string {
    const { statusCode, redirectType } = this.pageDetails;
    if (statusCode === 0) return 'No response from URL';
    if (statusCode === 200 && redirectType === EBrowserRedirectType.clientSide) return 'Client Side Redirect: A redirect that is executed by the browser instead of the web server. Typically using an HTML meta tag or JavaScript.';
    if (statusCode >= 200 && statusCode < 300) return 'Success';
    if (statusCode >= 300 && statusCode < 400) return 'Server Side Redirect: A redirect that is executed by the web server hosting this page.';
    return 'Broken';
  }

  goToUrl() {
    window.open(this.pageDetails.url, '_blank');
  }

  getStatusCodeClass() {
    if (this.pageDetails.statusCode >= 300 && this.pageDetails.statusCode < 400 && this.isFinal) {
      return 'status-fail';
    }

    if (this.pageDetails.redirectType === EBrowserRedirectType.clientSide && this.pageDetails.statusCode === 200) {
      return 'status-warning-client';
    }

    if (this.pageDetails.statusCode >= 200 && this.pageDetails.statusCode < 300) {
      return 'status-pass';
    }

    if (this.pageDetails.statusCode >= 300 && this.pageDetails.statusCode < 400) {
      return 'status-warning';
    }

    return 'status-fail';
  }
}
