import { Injectable } from '@angular/core';
import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addYears,
  compareAsc,
  Duration,
  endOfWeek,
  format,
  formatDistance,
  formatDistanceToNow,
  getDay,
  getDaysInMonth,
  Interval,
  intervalToDuration,
  isBefore,
  isMatch,
  isSameDay,
  isValid,
  isWithinInterval,
  Locale,
  parse,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subHours,
  subMinutes,
  subMonths
} from 'date-fns';
import { format as tzFormat } from 'date-fns-tz';
import { IDurationOptions } from '@app/components/date/date.models';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

export interface IFormatDistanceToNowOptions {
  includeSeconds?: boolean
  addSuffix?: boolean
  locale?: Locale
}

// Angular date pipe uses these formats, which has some differences from date-fns, so need to maintain both
export const formatDefs = {
  timeOne: 'h:mma', // '10:52am'
  timeTwo: 'HH:mm',  // '10:52'
  timeThree: 'hh:mm A',  // '10:52 AM'
  timeFour: 'HH:mm:ss', // '10:51:25
  timeFive: 'HH:mm:ssZ', // '10:51:25-07:00'
  timeSix: 'hh:mm:ss a', // '10:52:18 am'
  timeSeven: 'hh:mm:ss a Z', // '10:52:18 am -07:00'
  dateOne: 'ddd MMM DD, YYYY: h:mm a', // 'Fri Feb 11, 2022: 10:52 am'
  dateTwo: 'DD MMMM YYYY | h:mma',  // '11 February 2022 | 10:52am'
  dateThree: 'M/D',  // '2/11'
  dateFour: 'MMM d, y', // 'Feb 2, 2022'
  dateFive: 'MMMM d', // February 2
  dateSix: 'MMM DD, YY', // 'Feb 14, 22'
  dateSeven: 'MMM DD, YYYY', // 'Feb 14, 2022'
  dateEight: 'MMMM yyyy',  // February 2022
  dateNine: 'LL h:mm a', // 'February 11, 2022 10:52 am'
  dateTen: 'MM/DD/YYYY', // '02/14/2022 | 07:05 AM'
  dateEleven: 'MM/DD/YYYY | hh:mm A', // '02/14/2022 | 07:05 AM'
  dateTwelve: 'MMM d, y h:mm a',  // 'Feb 2, 2022 12:21 pm'
  dateThirteen: 'MMM d, YYYY hh:mm a', // 'Feb 11, 2022 10:52 AM'
  dateFourteen: 'MMM dd, yyyy | hh:mm a', // 'Feb 02, 2022 | 01:44 pm'
  dateFifteen: 'MMM DD, YYYY hh:mma', // 'Feb 11, 2022 10:52am'
  dateSixteen: 'MMM dd, yyyy hh:mm a', // 'Feb Tu 2022 12:08 pm'
  dateSeventeen: 'MMM D, YYYY | h:mm A', // Feb 11, 2022 | 10:52 AM'
  dateEighteen: 'MMMM Do YYYY, h:mm:ss a', // 'February 11th 2022, 10:52:18 am'
  dateNineteen: 'MMMM DD, YYYY [at] h:mm a', // 'February 11th 2022 at 10:52 am'
  dateTwenty: 'YYYY-DD-MM', // '2022-11-02'
  dateTwentyOne: 'YYYY-MM-DD',  // '2022-02-11'
  dateTwentyTwo: 'YYYY/MM/DD, hh:mma', // '2022/02/11, 10:52am'
  dateTwentyThree: 'yyyy-MM-dd hh:mm aaa',  // '2022-02-11 10:52 am'
};

// date-fns formats
// https://date-fns.org/v2.29.3/docs/format
export enum EDateFormats {
  timeOne = 'h:mmaaa',
  timeTwo = 'HH:mm',
  timeThree = 'hh:mm aa',
  timeFour = 'HH:mm:ss',
  timeFive = 'HH:mm:ssxx',
  timeSix = 'hh:mm:ss aaa',
  timeSeven = 'hh:mm:ss aaa xxx',
  timeEight = 'hh:mm aaaa',
  dateOne = 'eee MMM dd, yyyy: h:mm aaa',
  dateTwo = 'dd MMMM yyyy | h:mmaaa',
  dateThree = 'M/d',
  dateFour = 'MMM d, y',
  dateFive = 'MMMM d',
  dateSix = 'MMM dd, yy',
  dateSeven = 'MMM dd, yyyy',
  dateSeven2 = 'MMM d, yyyy',
  dateEight = 'MMMM yyyy',
  dateNine = 'MMMM dd, yyyy h:mm aaa',
  dateTen = 'MM/dd/yyyy',
  dateEleven = 'MM/dd/yyyy | hh:mm aa',
  dateTwelve = 'MMM d, y h:mm aaa',
  dateThirteen = 'LLL d, yyyy hh:mm aa',
  dateFourteen = 'MMM dd, yyyy | hh:mm aaa',
  dateFifteen = 'MMM dd, yyyy hh:mmaaa',
  dateSixteen = 'MMM dd, yyyy hh:mm aaa',
  dateSeventeen = 'MMM d, yyyy | h:mm aa',
  dateEighteen = 'MMMM do yyyy, h:mm:ss aaa',
  dateNineteen = "MMMM do, yyyy 'at' h:mm aaa",
  dateTwenty = 'yyyy-dd-MM',
  dateTwentyOne = 'yyyy-MM-dd',
  dateTwentyTwo = 'yyyy/MM/dd, hh:mmaaa',
  dateTwentyThree = 'yyyy-MM-dd hh:mm aaa',
  dateTwentyFour = 'Mddyyyy',
  dateTwentyFive = 'Md',
  dateTwentySix = 'd',
  dateTwentySeven = 'MMM',
  dateTwentyEight = 'MMM d',
  dateTwentyNine = 'MMM d, yyyy | h:mm a',
  dateThirty = 'eee MMM dd, yyyy | h:mm aaa',
  dateThirtyOne = 'MMM d, yyyy | h:mm:ss.SSS a', // 'Jun 5, 2024 | 2:56:33.936 AM'
  dateThirtyTwo = 'MMM dd',
  dateThirtyThree = 'yy',
  dateThirtyFour = 'MMMM d, yyyy',
  dateThirtySix = 'yyyy-MM-dd\'T\'HH:mm:ss.SSS',
}

export const now = (): number => {
  return Date.now();
};

export const formatDate = (date: Date, dateFormat: EDateFormats): string => {
  if (!dateFormat) throw new Error(`Date format is required.`);
  return format(date, dateFormat);
};

/**
 * Convert given date object to UTC date object
 * @link https://github.com/date-fns/date-fns/issues/556#issuecomment-391048347
 * @param date | plain JS date object
 * @return {Date | *}
 */
export const toUTC = (date: Date): Date => {
  const offset = date.getTimezoneOffset();

  return Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset));
};

/**
 * Format the provided date to UTC and return a string in the provided format
 * @param date
 * @param dateFormat
 */
export const formatToUTC = (date: Date, dateFormat: EDateFormats): string => {
  return format(toUTC(date), dateFormat);
};

/**
 * Compare the two dates and return 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
 * @param dateOne
 * @param dateTwo
 */
export function compareDatesAsc(dateOne: Date, dateTwo: Date) {
  return compareAsc(dateOne, dateTwo);
}

/**
 * Perform a timezone adjustment to a given time string (HH:mm), returning the updated time in the same format
 */
export function timeZoneAdjustmentToTimeString(dateTime: string, ianaTimezone: string, format: boolean): string {
  let date = parseDate(dateTime, EDateFormats.timeTwo);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let offset = getTimeZoneOffset(ianaTimezone);
  let offsetSign = offset.substring(0, 1);
  let offsetHours = offset.substring(1, 3);
  let offsetMinutes = offset.substring(4, 6);

  let newDate = new Date();
  let newStartHours = 0;
  let newStartMinutes = 0;

  if (offsetSign === '+') {
    newStartHours = hours;
    newStartMinutes = minutes + +offsetMinutes;

    newDate.setHours(newStartHours);
    newDate.setMinutes(newStartMinutes);

    newDate = addHoursToDate(newDate, +offsetHours);
    newDate = addMinutesToDate(newDate, +offsetMinutes);
  } else {
    newStartHours = hours;
    newStartMinutes = minutes;

    newDate.setHours(newStartHours);
    newDate.setMinutes(newStartMinutes);

    newDate = subtractHoursFromDate(newDate, +offsetHours);
    newDate = subtractMinutesFromDate(newDate, +offsetMinutes);
  }

  let startHoursString: string;
  let startMinutesString: string;

  if (format) {
    startHoursString = String(newDate.getHours()).padStart(2, '0');
    startMinutesString = String(newDate.getMinutes()).padStart(2, '0');
  } else {
    const updatedHours = newDate.getHours();
    const updatedMinutes = newDate.getMinutes();

    startHoursString = updatedHours > 11 ?
      String(updatedHours - 12).padStart(2, '0')
      : String(updatedHours).padStart(2, '0');

    startMinutesString = String(updatedMinutes).padStart(2, '0') + (updatedHours > 11 ? ' PM' : ' AM');
  }

  return `${startHoursString}:${startMinutesString}`;
}

/**
 * Return a new date after adding the provided number of minutes
 * @param date
 * @param numberOfMinutes
 */
export function addMinutesToDate(date: Date, numberOfMinutes: number): Date {
  return addMinutes(date, numberOfMinutes);
}

/**
 * Return a new date after subtracting the provided number of minutes
 * @param date
 * @param numberOfMinutes
 */
export function subtractMinutesFromDate(date: Date, numberOfMinutes: number): Date {
  return subMinutes(date, numberOfMinutes);
}

/**
 * Return a new date after adding the provided number of hours
 * @param date
 * @param numberOfHours
 */
export function addHoursToDate(date: Date, numberOfHours: number): Date {
  return addHours(date, numberOfHours);
}

/**
 * Return a new date after subtracting the provided number of hours
 * @param date
 * @param numberOfHours
 */
export function subtractHoursFromDate(date: Date, numberOfHours: number): Date {
  return subHours(date, numberOfHours);
}

/**
 * Return a new date after adding the provided number of days
 * @param date
 * @param numberOfDays
 */
export function addDaysToDate(date: Date, numberOfDays: number): Date {
  return addDays(date, numberOfDays);
}

/**
 * Return a new date after subtracting the provided number of days
 * @param date
 * @param amount
 */
export function subtractDaysFromDate(date: Date, amount: number): Date {
  return subDays(date, amount);
}

/**
 * Return a new date after adding the provided number of months
 * @param date
 * @param numToAdd
 */
export function addMonthsToDate(date: Date, numToAdd: number): Date {
  return addMonths(date, numToAdd);
}

/**
 * Return a new date after subtracting the provided number of months
 * @param date
 * @param numToSubtract
 */
export function subtractMonthsFromDate(date: Date, numToSubtract: number): Date {
  return subMonths(date, numToSubtract);
}

/**
 * Return a new date after adding the provided number of years
 * @param date
 * @param numberOfYears
 */
export function addYearsToDate(date: Date, numberOfYears: number): Date {
  return addYears(date, numberOfYears);
}

/**
 * Adapted from https://stackoverflow.com/a/69014377/2763234
 *
 * @param ianaTimeZone: IANA timezone string like 'America/Chicago', 'Africa/Cairo', etc
 */
export function getTimeZoneOffset(ianaTimeZone: string): string {
  const now = new Date();
  now.setSeconds(0, 0);

  // Format current time in `ianaTimeZone` as `M/DD/YYYY, HH:MM:SS`:
  const tzDateString = now.toLocaleString('en-US', {
    timeZone: ianaTimeZone,
    hour12: false,
  });

  // Parse formatted date string:
  const match = /(\d+)\/(\d+)\/(\d+), (\d+):(\d+)/.exec(tzDateString);
  const [_, month, day, year, hour, min] = match.map(Number);

  // Change date string's time zone to UTC and get timestamp:
  const tzTime = Date.UTC(year, month - 1, day, hour, min);

  // Return the offset between UTC and target time zone:
  const offsetInMinutes = Math.floor((tzTime - now.getTime()) / (1000 * 60));
  let hours = Math.trunc(offsetInMinutes / 60 % 24); // pad with 1 zero
  const minutes = offsetInMinutes % 60; // pad with 1 zero
  const hoursSign = hours > 0 ? '+' : '-';

  return `${hoursSign}${('0' + Math.abs(hours)).slice(-2)}:${('0' + minutes).slice(-2)}`;
}

/**
 * Create a new date object using the given an invalid date string and the matching format to give interpretation
 * on how to format the date, i.e. new Date('10:30') is invalid, but can create a new date as long as you provide the
 * applicable format, like parseDate('10:30', 'MM:ss').
 * @param dateString
 * @param format
 */
export const parseDate = (dateString: string, format: EDateFormats): Date => {
  const parsedDate = parse(dateString, format, new Date());
  return parsedDate;
};

/**
 * Return the number of days in a month
 * @param date: Date
 * @return number of days in the given month
 */
export function getNumberOfDaysInMonth(date: Date): number {
  return getDaysInMonth(date);
}

/**
 * Return 0 indexed number representing the day of week for the first day of the month
 * @param date: Date
 * @return number
 */
export function getDayOfWeekForFirstDayOfMonth(date: Date): number {
  return getDay(getStartOfMonth(date));
}

/**
 * Return new Date set to the first day of month
 * @param date: Date
 * @return Date
 */
export function getStartOfMonth(date: Date): Date {
  return startOfMonth(date);
}

/**
 * Return a new date object set to midnight on the date passed in
 * @param date: Date
 * @return Date
 */
export function getStartOfDay(date: Date): Date {
  return startOfDay(date);
}

export function isDateValid(date: Date): boolean {
  return isValid(date);
}

export function isDateSameDay(dateOne: Date, dateTwo: Date): boolean {
  return isSameDay(dateOne, dateTwo);
}

export function isDateBefore(dateOne: Date, dateTwo: Date): boolean {
  return isBefore(dateOne, dateTwo);
}

export function isDateTodayOrAfter(dateOne: Date, dateTwo: Date): boolean {
  return dateTwo.getTime() >= dateOne.getTime() ? true : isSameDay(dateOne, dateTwo) ? true : false;
 }

/**
 * Return the distance between the given date and now in words.
 * View formatting at: https://date-fns.org/v2.28.0/docs/formatDistanceToNow
 * @param date
 * @param options
 */
export const distanceToNow = (date: Date, options?: IFormatDistanceToNowOptions): string => {
  return formatDistanceToNow(date, options);
};

/**
 * Return the distance between two dates in words.
 * View formatting at: https://date-fns.org/v2.29.3/docs/formatDistance
 * @param date1
 * @param date2
 */

export const distanceBetween = (date1: Date, date2: Date): string => {
  return formatDistance(date1, date2);
};

/**
 * Return the duration as an object: { years: 39, months: 2, days: 20, hours: 7, minutes: 5, seconds: 0 }
 * View formatting at: https://date-fns.org/v2.16.1/docs/intervalToDuration
 * @param date1
 * @param date2
 */

export const runDuration = (date1: Date, date2: Date): Duration => {
  return intervalToDuration({
    start: date1,
    end: date2
  });
};

/**
 * Return whether or not the provided dateString matches a given date format
 * @param dateString
 * @param format
 */
export const doesDateStringMatchFormat = (dateString: string, format: string): boolean => {
  return isMatch(dateString, format);
};

export const dateFromString = (date: Date | string): Date => {
  return new Date(date);
};

/**
 * Returns the Iana timezone, i.e. 'America/Denver', for the current browser
 */
export const getBrowserTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Parses a time value from the api to return the value needed to fill a form input type="time"
 * Safari treats the time input different, so pass in format24Hour = false for safari
 * @param time: Date
 * @param format24Hour: boolean - Should be false to format in HH:mm aa (primarily for Safari time inputs)
 * @return string in format HH:mm or HH:mm aa
 */
export function timeStringToInputValue(time: Date, format24Hour: boolean = true): string {
  if (!time) return '';

  return formatDate(time, format24Hour ? EDateFormats.timeTwo : EDateFormats.timeThree);
}

/**
 * Set the time of the provided date to match the provided time argument
 * @param time
 * @param date
 * @param format24Hour
 */
export const changeTimeInDate = (time: string, date: Date): Date => {
  const timeDate = parseDate(time, EDateFormats.timeTwo);

  date.setHours(timeDate.getHours());
  date.setMinutes(timeDate.getMinutes());
  date.setSeconds(timeDate.getSeconds());

  return date;
};

/**
 Takes a total number of minutes and returns a string representing those minutes split
 into day, hours, and minutes. Including parts with 0 values is on by default, but optional.
 @param totalMinutes: Total number of minutes to be parsed
 @param noZeros (optional): true if you want to exclude parts that have a value of zero

 i.e.
 totalMinutes = 2953, noZeros = false, formatted = '2d 1h 13m'
 totalMinutes = 2893, noZeros = true, formatted = '2d 13m'
 */
export const minutesToTime = (totalMinutes: number, noZeros?: boolean): string => {
  const minutes = totalMinutes % 60;
  let remainingMinutes = totalMinutes - minutes;
  const hours = (remainingMinutes / 60) % 24;
  const days  = Math.floor(remainingMinutes / 60 / 24);

  let formatted = noZeros ?
    `${days > 0 ? days + 'd' : ''}${hours > 0 ? ' ' + hours + 'h' : ''}${minutes > 0 ? ' ' + minutes + 'm' : ''}`
    : `${days}d ${hours}h ${minutes}m`;

  formatted = noZeros ? formatted.length > 0 ? formatted.trim() : '0' : formatted;

  return formatted;
};

export const isoWithoutTimezone = (date: Date): string => {
  return dateWithoutTimezone(date).toISOString();
};

/**
 * Convert the provided date (ignoring timezone of the system date) from the provided fromTimeZone to the provided toTimeZone (ignoring timezone of the system date). Example usage:

  // Convert given date/time from EST to PST
  convertTimeZone(new Date(), 'America/New_York', 'America/Los_Angeles')

  This will convert the current date to the equivalent date as if
  it was in EST to a date in PST time, regardless of the timezone
  of the system date. It will still treat the Date as if it was
  using the system time, but the month, day, year, and times will
  be using the provided timezones, so you can just ignore and
  format as wanted.
 * @param date: Date
 * @param fromTimeZone: IANA timezone string
 * @param toTimeZone: IANA timezone string
 */
export const convertTimeZone = (date: Date, fromTimeZone: string, toTimeZone: string, format: string): string => {
  // Parse the time in the source time zone
  const utcDate = fromZonedTime(date, fromTimeZone);

  // Convert the UTC time to the target time zone
  const zonedDate = toZonedTime(utcDate, toTimeZone);

  // Format the date in the target time zone
  return tzFormat(zonedDate, format, { timeZone: toTimeZone });
}

export const convertToZonedTime = (date: Date, timeZone: string): Date => {
  return toZonedTime(date, timeZone);
}

export const dateWithoutTimezone = (date: Date): Date => {
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
};

// date: date
// baseDate: date we are comparing against
export const getDuration = (date: Date, baseDate: Date, options?: IDurationOptions): string => {
  return formatDistance(date, baseDate, options);
};

// Given a number of milliseconds, return a string representing the duration
// in individual units: MS, SECONDS, MINUTES, HOURS, DAYS, or MONTHS. Months
// are rounded to one decimal place.
export const getReadableDuration = (timeDiffInMS: number): string => {
  const absTimeDiffInMS = Math.abs(timeDiffInMS);
  const second = 1000;
  const minute = 1000 * 60;
  const hour = 1000 * 60 * 60;
  const day = hour * 24;
  const month = day * 30;

  if (absTimeDiffInMS < second) {
    return `${absTimeDiffInMS} MS`;
  } else if (absTimeDiffInMS < minute) {
    // Return number of seconds
    // get seconds from number of milliseconds
    const seconds = Math.floor(absTimeDiffInMS / second);
    return `${seconds} SECOND${seconds > 1 ? 'S' : ''}`;
  } else if (absTimeDiffInMS < hour) {
    // Return number of minutes
    // get minutes from number of milliseconds
    const minutes = Math.floor(absTimeDiffInMS / minute);
    return `${minutes} MINUTE${minutes > 1 ? 'S' : ''}`;
  } else if (absTimeDiffInMS < day) {
    // Return number of hours
    const hours = Math.floor(absTimeDiffInMS / hour);
    return `${hours} HOUR${hours > 1 ? 'S' : ''}`;
  } else if (absTimeDiffInMS < month) {
    // Return number of days
    const days = Math.floor(absTimeDiffInMS / day);
    return `${days} DAY${days > 1 ? 'S' : ''}`;
  } else {
    // Return number of months rounded to 1 decimal place
    const months = absTimeDiffInMS / month;
    return `${months.toFixed(1)} MONTH${months > 1 ? 'S' : ''}`;
  }
};

/**
 * Returns whether the provided date is within the provided interval
 * @param date
 * @param interval
 */
export const isDateWithinInterval = (date: Date, interval: Interval): boolean => {
  return isWithinInterval(date, interval);
};

/**
 * Returns a string representing the week range that the given date belongs to
 * in the format "MMMM d - d, yyyy" (June 8 - 14, 2024) or (June 25 - July 1, 2024) if the week spans two months
 * @param date
 */
export const getWeekRange = (date: Date): string => {
  // Calculate the first and last day of the week
  const firstDay = startOfWeek(date, { weekStartsOn: 0 }); // Sunday
  const lastDay = endOfWeek(date, { weekStartsOn: 0 }); // Saturday

  // Check if the first and last days are in different months
  const firstDayMonth = firstDay.getMonth();
  const lastDayMonth = lastDay.getMonth();

  // Format the dates
  const firstDayStr = format(firstDay, 'MMMM d');
  const lastDayStr = format(lastDay, firstDayMonth === lastDayMonth ? 'd, yyyy' : 'MMMM d, yyyy');

  return `${firstDayStr} - ${lastDayStr}`;
}

/**
 * Set the time of the provided date to match the provided time argument
 * @param date
 * @param time HH:mm
 */
export const updateTime = (date: Date, time: string): Date => {
  const [hours, minutes] = time.split(':').map(Number);
  date.setHours(hours, minutes);
  return date;
}

@Injectable({
  providedIn: 'root'
})
export class DateService implements IDateService {
  now = now;
  formatDate = formatDate;
  dateFromString = dateFromString;
  distanceToNow = distanceToNow;
  distanceBetween = distanceBetween;
  runDuration = runDuration;
  formatToUTC = formatToUTC;
  getNumberOfDaysInMonth = getNumberOfDaysInMonth;
  getDayOfWeekForFirstDayOfMonth = getDayOfWeekForFirstDayOfMonth;
  addMonthsToDate = addMonthsToDate;
  subtractMonthsFromDate = subtractMonthsFromDate;
  getStartOfMonth = getStartOfMonth;
  isDateValid = isDateValid;
  isDateSameDay = isDateSameDay;
  isDateBefore = isDateBefore;
  addYearsToDate = addYearsToDate;
  getStartOfDay = getStartOfDay;
  doesDateStringMatchFormat = doesDateStringMatchFormat;
  getBrowserTimezone = getBrowserTimezone;
  parseDate = parseDate;
  getTimeZoneOffset = getTimeZoneOffset;
  subtractDaysFromDate = subtractDaysFromDate;
  isDateTodayOrAfter = isDateTodayOrAfter;
  addDaysToDate = addDaysToDate;
  toUTC = toUTC;
  EDateFormats = EDateFormats;
  addMinutesToDate = addMinutesToDate;
  subtractMinutesFromDate = subtractMinutesFromDate;
  addHoursToDate = addHoursToDate;
  subtractHoursFromDate = subtractHoursFromDate;
  timeZoneAdjustmentToTimeString = timeZoneAdjustmentToTimeString;
  changeTimeInDate = changeTimeInDate;
  minutesToTime = minutesToTime;
  isoWithoutTimezone = isoWithoutTimezone;
  dateWithoutTimezone = dateWithoutTimezone;
  getDuration = getDuration;
  getReadableDuration = getReadableDuration;
  isWithinInterval = isDateWithinInterval;
  convertTimeZone = convertTimeZone;
  convertToZonedTime = convertToZonedTime;
  getWeekRange = getWeekRange;
}

export interface IDateService {
  now(): number;
  formatDate(date: any, format: string): string;
  dateFromString(date: any, format?: string): Date;
  distanceToNow(date: Date, options?: IFormatDistanceToNowOptions): string;
  distanceBetween(date1: Date, date2: Date): string;
  runDuration(date1: Date, date2: Date): Duration;
  formatToUTC(date: Date, format: EDateFormats): string;
  getNumberOfDaysInMonth(date: Date): number;
  getDayOfWeekForFirstDayOfMonth(date: Date): number;
  addMinutesToDate(date: Date, numberOfMinutes: number): Date;
  subtractMinutesFromDate(date: Date, numberOfMinutes: number): Date;
  addHoursToDate(date: Date, numberOfHours: number): Date;
  subtractHoursFromDate(date: Date, numberOfHours: number): Date;
  addDaysToDate(date: Date, numberOfDays: number): Date;
  subtractDaysFromDate(date: Date, amount: number): Date;
  addMonthsToDate(date: Date, numToAdd: number): Date;
  subtractMonthsFromDate(date: Date, numToSubtract: number): Date;
  addYearsToDate(date: Date, numberOfYears: number): Date;
  getStartOfMonth(date: Date): Date;
  getStartOfDay(date: Date): Date;
  isDateValid(date: Date): boolean;
  isDateSameDay(dateOne: Date, dateTwo: Date): boolean;
  isDateBefore(dateOne: Date, dateTwo: Date): boolean;
  doesDateStringMatchFormat(dateString: string, format: string): boolean;
  getBrowserTimezone(): string;
  parseDate(dateString: string, format: EDateFormats): Date;
  getTimeZoneOffset(ianaTimeZone: string): string;
  isDateTodayOrAfter(dateOne: Date, dateTwo: Date): boolean;
  toUTC(date: Date): Date;
  EDateFormats: any;
  changeTimeInDate(time: string, date: Date, format24Hour?: boolean): Date;
  timeZoneAdjustmentToTimeString(dateTime: string, ianaTimezone: string, format: boolean): string;
  minutesToTime(totalMinutes: number, noZeros?: boolean): string;
  isoWithoutTimezone(date: Date): string;
  dateWithoutTimezone(date: Date): Date;
  getDuration(startDate: Date, endDate: Date): string;
  getReadableDuration(timeDiffInMS: number): string;
  isWithinInterval(date: Date, interval: Interval): boolean;
  convertTimeZone(date: Date, fromTimeZone: string, toTimeZone: string, format: string): string;
  convertToZonedTime(date: Date, timeZone: string): Date;
  getWeekRange(date: Date): string;
}
