import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import {
  EAuditConsoleLogCounts,
  EAuditExportType,
  IAuditConsoleLog,
  IAuditConsoleLogs,
  IAuditConsoleLogSummary
} from '@app/components/shared/components/audit-console-log-table/audit-console-log.models';
import {
  AuditConsoleLogService
} from '@app/components/shared/components/audit-console-log-table/audit-console-log.service';
import { debounceTime, distinctUntilChanged, skip, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { formatDefs } from '@app/components/date/date.service';
import {
  BROWSER_CONSOLE_LOGS_SEARCH_TEXT_KEY
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-console-log-table',
  templateUrl: './audit-console-log-table.component.html',
  styleUrls: ['./audit-console-log-table.component.scss']
})
export class AuditConsoleLogTableComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;

  EReportType = EReportType;
  AuditConsoleLogCounts = EAuditConsoleLogCounts;
  EAuditExportType = EAuditExportType;

  loading = true;
  searchingStr$ = new BehaviorSubject('');
  destroy$ = new Subject();
  logTypeCounts: IAuditConsoleLogSummary = null;

  logTypeVisibility = {
    [EAuditConsoleLogCounts.errorLogCount]: true,
    [EAuditConsoleLogCounts.warnLogCount]: true,
    [EAuditConsoleLogCounts.debugLogCount]: true,
    [EAuditConsoleLogCounts.infoLogCount]: true,
    [EAuditConsoleLogCounts.otherLogCount]: true
  };

  search: string;

  @Input() itemId: number;
  @Input() runId: number;
  @Input() state?: any;
  @Input() reportType: EReportType;
  @Input() pageId?: string; // specific to audits
  @Input() activeTab?: EPageDetailsTabs; // specific to audits
  @Input() actionIndex?: number; // specific to web journeys
  @Input() success?: boolean; // specific to web journeys
  @Input() isScrolled = false;
  @Output() consoleLogCount = new EventEmitter<number>(); // specific to web journeys

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  readonly dateFormat = formatDefs.dateFour;
  readonly timeFormat = formatDefs.timeSix;

  dataSource = new MatTableDataSource<IAuditConsoleLog>();
  displayedColumns = ['count', 'related_tag', 'level', 'message', 'filters'];

  tableState = {
    sort: {sortBy: 'level', sortDesc: true},
    pagination: {size: 50, page: 0},
    pagesTotal: 0
  };

  constructor(
    private auditConsoleLogService: AuditConsoleLogService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.initData();
  }

  ngAfterViewInit() {
    this.sort
      .sortChange
      .pipe(
        takeUntil(this.destroy$),
        switchMap(sort => {
          this.tableState.pagination.page = 0;
          this.tableState.sort.sortBy = sort.active;
          this.tableState.sort.sortDesc = sort.direction === 'desc';
          return this.loadAuditLogs();
        })
      )
      .subscribe();

    this.searchingStr$
      .pipe(
        takeUntil(this.destroy$),
        skip(1),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(() => this.loadAuditLogs())
      )
      .subscribe();

    this.externalToInternalState();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private externalToInternalState(): void {
    this.state = this.state || {};

    if (this.state.hasOwnProperty(BROWSER_CONSOLE_LOGS_SEARCH_TEXT_KEY)) {
      this.search = this.state[BROWSER_CONSOLE_LOGS_SEARCH_TEXT_KEY];
      this.changeDetectorRef.detectChanges();
      this.searchingStr$.next(this.state[BROWSER_CONSOLE_LOGS_SEARCH_TEXT_KEY] || '');
    }
  }

  formatConsoleLogData(data: IAuditConsoleLog[]): void {
    const formattedData = [];

    data.forEach(log => formattedData
      .push({
        ...log,
        level: AuditConsoleLogTableComponent.replaceLogLevel(log.level) as EAuditExportType
      }));

    this.dataSource.data = formattedData;
  }

  handleJourneyActionFailureState(): void {
    this.consoleLogCount.emit(0);
  }

  toggleView(logType: EAuditConsoleLogCounts): void {
    this.logTypeVisibility[logType] = !this.logTypeVisibility[logType];
    this.loadAuditLogs().subscribe();
  }

  private initData() {
    forkJoin([
      this.auditConsoleLogService.getAuditPageConsoleLogSummarize(this.itemId, this.runId, this.pageId),
      this.loadAuditLogs()
    ])
      .subscribe(([summary]) => this.logTypeCounts = summary);
  }

  private loadAuditLogs(): Observable<IAuditConsoleLogs> {
    this.loading = true;
    const selectedTypes = Object.entries(this.logTypeVisibility)
      .filter(([type, visibility]) => visibility)
      .map(([type]) => {
        return type.split(/[A-Z]/)[0];
      });

    return this.auditConsoleLogService
      .getAuditPageConsoleLog(this.itemId, this.runId, this.pageId, this.tableState.pagination, this.tableState.sort, selectedTypes, this.searchingStr$.getValue())
      .pipe(tap(consoleLogs => {
        this.formatConsoleLogData(consoleLogs.consoleLogs);
        this.tableState.pagesTotal = consoleLogs.metadata?.pagination.totalCount;
        this.tableState.pagination.size = consoleLogs.metadata?.pagination.pageSize;
        this.tableState.pagination.page = consoleLogs.metadata?.pagination.currentPageNumber;
        this.loading = false;
      }));
  }

  private static replaceLogLevel(level): string {
    switch (level) {
      case 'SEVERE':
        return 'error';
      case 'WARNING':
        return 'warning';
      case 'FINE':
        return 'debug';
      case 'INFO':
        return 'info';
      case 'verbose':
        return 'other';
      default:
        return level;
    }
  }

  onChangePage(ev) {
    this.tableState.pagination.page = ev.pageIndex;
    this.loadAuditLogs().subscribe();
  }
}
