import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsService } from '../account/account.service';
import { userIsAdmin, userIsStandard } from '@app/authUtils';
import { forkJoin } from 'rxjs';
import { IUsageSummaryDTO } from '../usage-v2/usage.models';
import { UsageV2ApiService } from '@app/components/usage-v2/usage-v2-api.service';
import { EOverageMessageVersion, UsageUrlBuilders } from '@app/components/usage-v2/usage-v2.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'overage-banner',
  templateUrl: './overage-banner.component.html',
  styleUrls: ['./overage-banner.component.scss']
})
export class OverageBannerComponent implements OnInit {

  userIsAdmin = false;
  showOverageBanner = false;
  message = '';
  currentRoutePath = '';

  constructor(
    private router: Router,
    private accountsService: AccountsService,
    private usageService: UsageV2ApiService,
  ) {}

  ngOnInit(): void {
    this.getData();
    this.determineCurrentlyActiveRoute();
  }

  getData(): void {
    forkJoin([
      this.accountsService.getUser(),
      this.usageService.getUsageOverview(),
    ]).subscribe(([user, usageSummary]) => {
      this.userIsAdmin = userIsAdmin(user);
      const userIsStdOrAdmin = this.userIsAdmin || userIsStandard(user);

      if (userIsStdOrAdmin) {
        this.message = this.getOverageMessage(usageSummary);

        this.showOverageBanner = usageSummary.auditUsage.usagePercentage >= 90 || usageSummary.webJourneyUsage.usagePercentage >= 90;
      }
    });
  }

  getOverageMessageVersion(auditPercent: number, journeyPercent: number): EOverageMessageVersion {
    if (auditPercent >= 100 && journeyPercent >= 100) {
      return EOverageMessageVersion.BothOver;
    } else if (auditPercent >= 100 && journeyPercent >= 90) {
      return EOverageMessageVersion.AuditsOverJourneysWarning;
    } else if (auditPercent >= 90 && journeyPercent >= 100) {
      return EOverageMessageVersion.JourneysOverAuditsWarning;
    } else if (auditPercent >= 100) {
      return EOverageMessageVersion.OnlyAuditsOver;
    } else if (journeyPercent >= 100) {
      return EOverageMessageVersion.OnlyJourneysOver;
    } else if (auditPercent > 90 && journeyPercent > 90) {
      return EOverageMessageVersion.BothWarning;
    } else if (auditPercent > 90) {
      return EOverageMessageVersion.OnlyAuditsWarning;
    } else if (journeyPercent > 90) {
      return EOverageMessageVersion.OnlyJourneysWarning;
    }
  }

  getOverageMessage(usageSummary: IUsageSummaryDTO): string {
    const { allowOverages, auditUsage, webJourneyUsage } = usageSummary;
    const { usagePercentage: auditPercent, overLimitPrice: auditOverLimitPrice } = auditUsage;
    const { usagePercentage: journeyPercent, overLimitPrice: journeyOverLimitPrice } = webJourneyUsage;

    let bannerMessage;
    let messageVersion = this.getOverageMessageVersion(auditPercent, journeyPercent);

    const bothTypes = [EOverageMessageVersion.BothOver, EOverageMessageVersion.BothWarning, EOverageMessageVersion.JourneysOverAuditsWarning, EOverageMessageVersion.AuditsOverJourneysWarning];
    const onlyAudits = [EOverageMessageVersion.OnlyAuditsOver, EOverageMessageVersion.OnlyAuditsWarning];
    const onlyJourneys = [EOverageMessageVersion.OnlyJourneysOver, EOverageMessageVersion.OnlyJourneysWarning];
    const bothTypesAffected = bothTypes.includes(messageVersion);
    const onlyAuditsAffected = onlyAudits.includes(messageVersion);
    const onlyJourneysAffected = onlyJourneys.includes(messageVersion);

    let costOfOverage = '';

    if (this.userIsAdmin) {
      const showForAudit = auditOverLimitPrice?.amount && auditOverLimitPrice?.currency;
      const showForJourney = journeyOverLimitPrice?.amount && journeyOverLimitPrice?.currency;

      const auditCostTemplate = `${auditOverLimitPrice?.amount} (${auditOverLimitPrice?.currency}) for each page`;
      const journeyCostTemplate = `${journeyOverLimitPrice?.amount} (${journeyOverLimitPrice?.currency}) for each run`;

      if (bothTypesAffected && showForAudit && showForJourney) {
        costOfOverage = `The extra cost is ${auditCostTemplate} and ${journeyCostTemplate} over the limit. `;
      } else if (onlyAuditsAffected && showForAudit) {
        costOfOverage = `The extra cost is ${auditCostTemplate} over the limit. `;
      } else if (onlyJourneysAffected && showForJourney) {
        costOfOverage = `The extra cost is ${journeyCostTemplate} over the limit. `;
      }
    }

    let overageSettingStatement = allowOverages ? '<span class="bold-text">allow</span>' : '<span class="bold-text"></span>not allow</span>';
    const showPreviousOverageSetting = (bothTypesAffected || onlyAuditsAffected || onlyJourneysAffected) && !allowOverages;
    const startOverageMsg = `${allowOverages ? '<span class="bold-text">will</span> start according to their normal schedules.' : '<span class="bold-text">will not</span> start.'}`;
    const startWarningMsg = `${allowOverages ? '<span class="bold-text">will</span> start according to their normal schedules.' : '<span class="bold-text">will not</span> start if they cause overages.'}`;

    const msgIntro = `Your account is ${(showPreviousOverageSetting ? 'currently ' : '')} set to ${overageSettingStatement} overages${(allowOverages ? '' : ', but was previously set to allow overages')}.`;

    const bothTypesIntro = 'Pages scanned and Journey runs';
    const journeyTypeIntro = 'The number of Journey runs';
    const auditTypeIntro = 'The number of pages scanned';
    const overIntro = 'is at or over your Purchased Volume.';
    const warningIntro = 'exceeds 90% of your Purchased Volume.';

    switch (messageVersion) {
      case 'bothOver':
        bannerMessage = `${bothTypesIntro} ${overIntro} ${msgIntro} Audits and Journeys ${startOverageMsg}`;
        break;
      case 'onlyAuditsOver':
        bannerMessage = `${auditTypeIntro} ${overIntro} ${msgIntro} Audits ${startOverageMsg}`;
        break;
      case 'onlyJourneysOver':
        bannerMessage = `${journeyTypeIntro} ${overIntro} ${msgIntro} Journeys ${startWarningMsg}`;
        break;
      case 'onlyAuditsWarning':
        bannerMessage = `${auditTypeIntro} ${warningIntro} ${msgIntro} Audits ${startWarningMsg}`;
        break;
      case 'onlyJourneysWarning':
        bannerMessage = `${journeyTypeIntro} ${warningIntro} ${msgIntro} Journeys ${startWarningMsg}`;
        break;
      case 'bothWarning':
        bannerMessage = `${bothTypesIntro} ${warningIntro} ${msgIntro} Audits and Journeys ${startWarningMsg}`;
        break;
      case 'auditsOverJourneysWarning':
        if (allowOverages) {
          bannerMessage = `${auditTypeIntro} ${overIntro} ${journeyTypeIntro} ${warningIntro} ${msgIntro} Audits and Journeys <span class="bold-text">will</span> start according to their normal schedules.`;
        } else {
          bannerMessage = `${auditTypeIntro} ${overIntro} Audits <span class="bold-text">will not</span> start. ${journeyTypeIntro} ${warningIntro} Journeys <span class="bold-text">will not</span> start if they cause overages. ${msgIntro}`;
        }
        break;
      case 'journeysOverAuditsWarning':
        bannerMessage = `${journeyTypeIntro} ${overIntro} ${auditTypeIntro} ${warningIntro} ${msgIntro} ${allowOverages ? 'Audits and Journeys <span class="bold-text">will</span> start according to their normal schedules.' : 'Journeys <span class="bold-text">will not</span> start. Audits <span class="bold-text">will not</span> start if they cause overages.'}`;
        if (allowOverages) {
          bannerMessage = `${journeyTypeIntro} ${overIntro} ${auditTypeIntro} ${warningIntro} ${msgIntro} Audits and Journeys <span class="bold-text">will</span> start according to their normal schedules.`;
        } else {
          bannerMessage = `${journeyTypeIntro} ${overIntro} Journeys <span class="bold-text">will not</span> start. ${auditTypeIntro} ${warningIntro} Audits <span class="bold-text">will not</span> start if they cause overages. ${msgIntro}`;
        }
        break;
    }

    // final message
    return `${bannerMessage} ${costOfOverage}`;
  }

  determineCurrentlyActiveRoute(): void {
    this.currentRoutePath = this.router.url.split('/').slice(-1)[0];
  }

  navToUsageReport(): void {
    this.router.navigateByUrl(UsageUrlBuilders.base());
  }
}
