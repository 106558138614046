import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IConsentCategoryType, INewTagModalData } from '@app/components/consent-categories/consent-categories.models';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { INewTagData } from '../../../consent-categories.models';

import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-new-tag',
  templateUrl: './add-new-tag.component.html',
  styleUrls: ['./add-new-tag.component.scss']
})
export class AddNewTagComponent implements OnInit {

  tags: IUiTag[];
  consentTypes: IConsentCategoryType[];
  newTagForm: UntypedFormGroup;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  rightFooterButtons = [
    {
      label: 'Done',
      action: () => {
        this.save();
      },
      primary: true
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<AddNewTagComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INewTagModalData,
    private formBuilder: UntypedFormBuilder
  ) {
    this.tags = this.data.tags;
    this.consentTypes = this.data.types;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.newTagForm = this.formBuilder.group({
      tag: ['', Validators.required],
      accounts: this.formBuilder.array([]),
    });
  }

  addAccount(event: MatChipInputEvent): void {
    if ((event.value || '').trim()) {
      this.accounts.push(this.formBuilder.control(event.value));
    }

    event.chipInput.clear();
  }

  removeAccount(index: number): void {
    this.accounts.removeAt(index);
  }

  onSelectTag(tag: IUiTag) {
    tag ? this.tag.setValue(tag) : this.tag.setValue(null);
  }

  save(): void {
    this.newTagForm.markAllAsTouched();
    if (this.newTagForm.invalid) return;

    this.closeModal(this.newTagForm.value);
  }

  closeModal(tag?: INewTagData): void {
    this.dialogRef.close(tag);
  }

  get tag(): AbstractControl {
    return this.newTagForm.get('tag');
  }

  get accounts() {
    return this.newTagForm.get('accounts') as UntypedFormArray;
  }
}
