import { OpModalService } from '@app/components/shared/components/op-modal';
import { RouteHistoryService } from '@app/components/shared/services/route-history.service';
import * as angular from 'angular';

import {
  IFailedRuleReport,
  INotAppliedRuleReport,
  IPassedRuleReport,
  RuleResultType
} from '@app/components/reporting/rules/rule.models';
import { ButtonSet, IButton } from '@app/models/commons';
import {
  name as MJS_NAME,
  IInitializedManualJourney,
  IManualJourney,
  IManualJourneyRequestLog,
  IManualJourneyRulesReprocessingResponse,
  IManualJourneyStep,
  IManualJourneyTag,
  ManualJourneyService,
  RDCManualJourneyStatuses
} from '@app/components/live-connect/manual-journey/manualJourneyService';
import { IWebsocketService } from '@app/components/websockets/websocketService';
import {
  name as DPS_NAME,
  DeviceProfileService,
} from '../../../live-connect/device-profile.service';
import {
  IManualJourneyFile,
  IManualJourneyFileMetadata,
  name as LCAFS_NAME,
  LiveConnectAttachedFilesService
} from '@app/components/live-connect/manual-journey/liveConnectAttachedFilesService';
import { RulesService } from '@app/components/rules/rules.service';
import { AngularNames, Events, Names } from '@app/moonbeamConstants';
import {
  IPrimaryTag,
  ManageTagsService
} from '@app/components/account/manageTags/manageTagsService';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import {
  name as MJSS_NAME,
  ManualJourneySocketsService,
} from '@app/components/live-connect/manual-journey/manualJourneyWsService';
import { IOPSpinnerService } from '@app/components/ui/spinner/spinnerService';
import { IModalService } from '@app/components/modals/modalService';
import { IUser } from '@app/moonbeamModels';
import { ModalTemplates } from '@app/components/modals/modalTemplates';
import {
  IConfirmationModalData,
  IManualJourneyExportModalData,
  IRdcUserPresenceModalData,
  IReprocessRulesModalData,
  IRuleModalData
} from '@app/components/modals/modalData';
import { IJourneyVariable } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { ISort } from '@app/components/manage/shared/services/manage-communication/manage-communication.service';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Observable } from 'rxjs';
import { IDeviceProfile } from '../../../live-connect/device-profile.service';
import { ToastService } from '@app/components/utilities/toastService';
import { AccountsService } from '@app/components/account/account.service';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { IRule, IRulePreview } from '@app/components/rules/rules.models';
import { IApiErrorResponse } from '@app/components/core/services/api.service';
import { Router } from '@angular/router';
import { LiveConnectUrlBuilders } from '@app/components/live-connect/live-connect.constants';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { IWebJourneyActionRules } from '@app/components/web-journey/web-journey-editor/web-journey-editor.models';
import {
  CurtainedRuleSelectorComponent,
  ICurtainedRuleSelectorComponentData
} from '@app/components/account/rules/curtained-rule-selector/curtained-rule-selector.component';
import { tap } from 'rxjs/operators';

export type RDCControlTabType = 'steps' | 'attachments';
export type RDCRulesViewType = 'assigned';
export type RDCRequestDetailsTabType = 'request' | 'response';
export type RDCJourneyControlRequest =
  | 'start'
  | /*'next-step' | */ 'abort'
  | 'stop'
  | 'restart'
  | 'resume'
  | 'finalize';

export interface IManualJourneyRule {
  id: number;
  name: string;
  resultType: RuleResultType;
  report: IFailedRuleReport | INotAppliedRuleReport | IPassedRuleReport;
}

interface IButtonState {
  blocked: boolean;
}

interface IRuleCreatorTag {
  tagId: number;
  tagIcon: string;
  tagName: string;
  account: string;
  variables: Array<IJourneyVariable>;
}

const NOT_STARTED_JOURNEY_HEADER_URL: string = require('ngtemplate-loader!@app/components/creator/live-connect/manual-journey/headers/notStartedManualJourneyRecorderHeader.html');
const IN_PROGRESS_JOURNEY_HEADER_URL: string = require('ngtemplate-loader!@app/components/creator/live-connect/manual-journey/headers/inProgressManualJourneyRecorderHeader.html');
const FINISHED_JOURNEY_HEADER_URL: string = require('ngtemplate-loader!@app/components/creator/live-connect/manual-journey/headers/finishedManualJourneyRecorderHeader.html');
const STOPPED_JOURNEY_HEADER_URL: string = require('ngtemplate-loader!@app/components/creator/live-connect/manual-journey/headers/stoppedManualJourneyRecorderHeader.html');
const CONTENT_HEADER_URL: string = require('ngtemplate-loader!@app/components/creator/live-connect/manual-journey/manualJourneyRecorder.html');

export class ManualJourneyRecorderController {
  fullScreenCreatorId = 'manualJourneyFullScreenRecorder';

  buttonStates: { [key: string]: IButtonState } = {
    start: { blocked: false },
    stop: { blocked: false }
  };

  FILTER_DELIMITER = '\n';
  contentTemplateUrl: string = CONTENT_HEADER_URL;
  hiddenFooter: boolean;
  closeIconText: string;
  contentHeaderUrl: string;
  title: string;
  saveButton: IButton = {
    label: 'SAVE & FINISH',
    action: 'saveAndFinish',
    primary: true
  };
  modalButtons: Array<ButtonSet> = [];
  isSpinnerVisible = false;
  spinnerKey: string = 'journey-recorder-spinner';
  spinnerText1: string = '';
  spinnerText2: string = 'Please, wait...';

  tagsWindowCollapsed: boolean;
  rulesWindowCollapsed: boolean;
  mjStatuses: { [key: string]: RDCManualJourneyStatuses } = {
    NOT_STARTED: RDCManualJourneyStatuses.initialized,
    IN_PROGRESS: RDCManualJourneyStatuses.started,
    STOPPED: RDCManualJourneyStatuses.stopped,
    FINISHED: RDCManualJourneyStatuses.finished,
    ABORTED: RDCManualJourneyStatuses.aborted,
    FAILED: RDCManualJourneyStatuses.failed,
    PROCESSED: RDCManualJourneyStatuses.processed,
    RULES_PROCESSING: RDCManualJourneyStatuses.rulesProcessing,
    RULES_REPROCESSING: RDCManualJourneyStatuses.rulesReprocessing,
    ENFORCED: RDCManualJourneyStatuses.enforced
  };

  wsConn: angular.IPromise<IWebsocketService>;
  user: IUser;

  activeDeviceProfile: IDeviceProfile;

  initializedManualJourney: IInitializedManualJourney;
  initialisingInProgress: boolean = false;
  activeManualJourney: IManualJourney;
  deviceProfileInfoEditMode: boolean;
  journeyNameEditMode: boolean;

  activeStep: IManualJourneyStep;
  activeTag: IManualJourneyTag;
  activeRuleCreatorTag: IRuleCreatorTag;

  activeControlTab: RDCControlTabType;
  activeStepNoteId: number;
  stepNotesEditMode: boolean;
  tempStepNotes: string;

  activeStepNameId: number;
  stepNameEditMode: boolean;
  tempStepName: string;

  manualJourneySteps: Array<IManualJourneyStep>;
  attachedFiles: Array<IManualJourneyFile>;

  manualJourneyTags: Array<IManualJourneyTag>;
  filteredManualJourneyTags: Array<IManualJourneyTag>;
  tagVarsTableSort: ISort;

  activeRulesViewType: RDCRulesViewType;

  allRules: Array<IRulePreview>;
  selectedRulesIds: Array<number>;
  selectedRules: Array<IRulePreview>;
  processedRules: Array<IManualJourneyRule>;
  allLabels: Array<ILabel>;

  filterRequestLogsByRegex: boolean;

  requestLogFiltersValid: boolean;
  requestLogFilters: string;

  requestLogSequencesMap: Map<string, number>;
  requestLogs: Array<IManualJourneyRequestLog>;
  filteredRequestLogs: Array<IManualJourneyRequestLog>;
  activeRequestLog: IManualJourneyRequestLog;
  activeRequestDetailsTab: RDCRequestDetailsTabType;
  logsFilter: string;

  primaryTagFilter: boolean;
  primaryTagFilterDisabled: boolean;

  newFileMetadata: IManualJourneyFileMetadata;
  newFile: File;
  fileUploadingInProgress: boolean;
  fileRemovalInProgress: boolean;

  activeFileNameId: number;
  fileNameEditMode: boolean;
  tempFileName: string;

  waitingForRulesProcessingToComplete: boolean = false;

  ruleSelectorComponentInstance: CurtainedRuleSelectorComponent;

  static $inject = [
    AngularNames.q,
    AngularNames.timeout,
    AngularNames.scope,
    DPS_NAME,
    Names.NgServices.rules,
    Names.NgServices.labelService,
    MJS_NAME,
    Names.Services.manageTags,
    Names.NgServices.authentication,
    Names.NgServices.accountsService,
    Names.Services.modal,
    Names.NgServices.opModalService,
    Names.Services.eventManager,
    LCAFS_NAME,
    Names.Services.reprocess,
    MJSS_NAME,
    Names.Services.opSpinner,
    Names.NgServices.routerHistory,
    Names.Services.ngToast,
    Names.Services.toastService,
    Names.NgServices.router,
    AngularNames.location,
    AngularNames.window
  ];

  constructor(
    private $q: angular.IQService,
    private $timeout: angular.ITimeoutService,
    private $scope,
    private deviceProfileService: DeviceProfileService,
    private rulesService: RulesService,
    private labelsService: LabelService,
    private manualJourneyService: ManualJourneyService,
    private manageTagsService: ManageTagsService,
    private authenticationService: AuthenticationService,
    private accountsService: AccountsService,
    private modalService: IModalService,
    private modalServiceNg: OpModalService,
    private eventManager: IEventManager,
    private rdcAttachedFilesService: LiveConnectAttachedFilesService,
    private reprocessService: IReprocessService,
    private manualJourneySocketsService: ManualJourneySocketsService,
    private spinnerService: IOPSpinnerService,
    private routeHistoryService: RouteHistoryService,
    private ngToast: any,
    private toastService: ToastService, // TODO: Switch this to SnackBar once component converted
    private router: Router,
    private $location: angular.ILocationService,
    private $window: angular.IWindowService
  ) {
    this.tagVarsTableSort = { sortOn: 'order', name: '', reverse: false };
    this.activateControlTab('steps');
    this.activateRulesView('assigned');
    this.activateRequestDetailsTab('request');
    this.activeStepNoteId = -1;

    $window.addEventListener('beforeunload', (e) => {
      if (this.isJourneyInProgress()) {
        e.preventDefault();
        e.returnValue = true; //Gecko + IE
        return '\o/'; //Webkit, Safari, Chrome
      }
    });

    if (this.$scope.mjId) {
      this.setReportControlButtons();
      this.showSpinner('Loading journey data...');
    } else {
      this.showSpinner(
        'Spinning up a new proxy and configuring your journey.',
        'It might take up to 30 seconds. Please, wait...'
      );
    }

    this.initData(this.$scope.dpId, this.$scope.mjId)
      .catch( (error: IApiErrorResponse) => {
        const errorCode = error?.errorCode ? ` Error code: ${error.errorCode}` : '';
        alert('Failed to load LiveConnect journey. Please contact support. Error code: ' + errorCode);
        this.backToLiveConnectHomePage();
      }).finally(() => { this.hideSpinner(); });

    this.loadLabels().subscribe((labels: Array<ILabel>) => {
      this.allLabels = labels;
    });
  }

  private backToLiveConnectHomePage() {
    this.router.navigateByUrl(LiveConnectUrlBuilders.base());
    this.pureClose();
  }

  private showSpinner(text1?: string, text2?: string) {
    this.isSpinnerVisible = true;
    this.spinnerText1 = text1 || '';
    this.spinnerText2 = text2 || '';
    const state = this.spinnerService.getSpinnerState(this.spinnerKey);
    this.spinnerService.spin(state);
  }

  private hideSpinner() {
    this.isSpinnerVisible = false;
    this.spinnerText1 = '';
    this.spinnerText2 = '';
    const state = this.spinnerService.getSpinnerState(this.spinnerKey);
    this.spinnerService.stop(state);
  }

  private getDeviceProfileRules(dpId: number): angular.IPromise<number[]> {
    return this.$q((resolve, reject) => {
      this.deviceProfileService.getDeviceProfileRules(dpId)
        .subscribe(resolve);
    });
  }

  private initData(dpId: number, mjId?: number): angular.IPromise<void> {
    this.hiddenFooter = true;
    return this.$q.all([
        this.loadADeviceProfile(dpId),
        this.loadRules(),
        this.manageTagsService.getPrimaryTags(),
        this.manageTagsService.getReportToggleValue(),
        this.convertObservableToAngJSPromise((this.accountsService.getUser())),
        this.getDeviceProfileRules(dpId),
      ])
      .then(
        ([dp, rules, prTags, tagFilterValue, user, selectedRulesIds]) => {
          this.activeDeviceProfile = dp;
          this.allRules = rules;
          this.primaryTagFilterDisabled = !this.manageTagsService.hasEnabledPrimaryTags(
            prTags
          );
          this.primaryTagFilter = this.primaryTagFilterDisabled
            ? false
            : tagFilterValue;
          this.user = user;
          this.selectedRulesIds = selectedRulesIds;
          this.selectedRules = this.getSelectedRules(rules, selectedRulesIds);
          if (mjId) {
            return this.loadAManualJourney(dp.id, mjId).then(
              (mj: IManualJourney) => {
                this.activeManualJourney = mj;
                this.modalButtons = this.initModalButtons(
                  this.activeManualJourney.status
                );
                this.setFooterState();
                this.title = mj.name;
                if (mj.status >= RDCManualJourneyStatuses.enforced)
                  this.contentHeaderUrl = FINISHED_JOURNEY_HEADER_URL;
                if (mj.status >= this.mjStatuses.ENFORCED) {
                  this.manualJourneyService
                    .getManualJourneyProcessedRules(
                      this.activeDeviceProfile.id,
                      this.activeManualJourney.id
                    )
                    .then((processedRules: Array<IManualJourneyRule>) => {
                      this.processedRules = processedRules;
                    });
                }

                this.manualJourneyService
                  .getManualJourneySteps(dp.id, mj.id)
                  .then((steps: Array<IManualJourneyStep>) => {
                    this.manualJourneySteps = steps;
                    if (this.manualJourneySteps.length)
                      this.activateStep(this.manualJourneySteps[0]);
                  });
                this.rdcAttachedFilesService
                  .getManualJourneyAttachedFiles(dp.id, mj.id)
                  .then((files: Array<IManualJourneyFile>) => {
                    this.attachedFiles = files;
                  });

                return this.$q
                  .all([
                    this.manualJourneyService.getManualJourneyRequestFilters(
                      dp.id,
                      mj.id
                    ),
                    this.manualJourneyService.getManualJourneyRequestLogs(
                      dp.id,
                      mj.id
                    )
                  ])
                  .then(([filters, logs]) => {
                    this.requestLogFilters = this.buildRequestFiltersString(
                      filters
                    );
                    this.requestLogFiltersValid = this.areRequestLogFiltersValid();
                    this.requestLogs = this.serializeRequestLogs(logs);
                    this.requestLogs = this.processRequestLogsSSLFlags(
                      this.requestLogs,
                      this.getRequestLogFiltersList(this.requestLogFilters)
                    );
                    this.filteredRequestLogs = this.requestLogs;
                    return this.manualJourneyService
                      .getManualJourneyTags(dp.id, mj.id)
                      .then((tags: Array<IManualJourneyTag>) => {
                        this.manualJourneyTags = this.sortTagsByRequestSequence(
                          tags
                        );
                        return this.filterManualJourneyTags(
                          this.primaryTagFilter,
                          this.manualJourneyTags
                        ).then((fts: Array<IManualJourneyTag>) => {
                          this.filteredManualJourneyTags = fts;
                          if (this.filteredManualJourneyTags.length) {
                            this.activateFirstSelectableTag(
                              this.filteredManualJourneyTags
                            );
                          } else if (this.filteredRequestLogs.length) {
                            this.activateRequestLog(
                              this.filteredRequestLogs[0]
                            );
                          }
                        });
                      });
                  });
              }
            );
          } else {
            this.contentHeaderUrl = NOT_STARTED_JOURNEY_HEADER_URL;
            this.initialisingInProgress = true;
            return this.manualJourneyService
              .initializeAManualJourney(dpId)
              .then((initializedMj: IInitializedManualJourney) => {
                this.initialisingInProgress = false;
                this.initializedManualJourney = initializedMj;
                this.activeManualJourney = this.generateNewManualJourney(
                  dp,
                  initializedMj
                );
                this.requestLogFilters = '';
                this.requestLogFiltersValid = true;
                this.requestLogs = [];
                this.filteredRequestLogs = this.requestLogs;
                this.manualJourneySteps = [];
                this.attachedFiles = [];
                this.manualJourneyTags = [];
                this.filterManualJourneyTags(
                  this.primaryTagFilter,
                  this.manualJourneyTags
                ).then((fts: Array<IManualJourneyTag>) => {
                  this.filteredManualJourneyTags = fts;
                  this.activateFirstSelectableTag(
                    this.filteredManualJourneyTags
                  );
                });
                this.title = this.activeManualJourney.name;
                this.subscribeOnLivePresenseCheck(this.activeDeviceProfile.id, this.activeManualJourney.id);
                this.$scope.$on('$destroy', () => {
                  this.unsubscribeFromLivePresenceCheck();
                });
              })
              .catch((err: IApiErrorResponse) => {
                this.initialisingInProgress = false;
                if (err?.code === 504) {
                  alert('LiveConnect is unavailable or at capacity. Please try again later.');
                }
                if (err.message === 'All pre-defined ports are busy') {
                  alert('All LiveConnect ports are currently in use. Please try again later.');
                }
                this.backToLiveConnectHomePage();
              });
          }
        }
      );
  }

  private showErrorToast(message: string) {
    this.ngToast.create(this.toastService.generateSingleMessageWithWrapperToastConfig(
      message,
      'manual-recorder-toast',
      'manual-recorder-toast-wrapper',
    ));
  }

  private convertObservableToAngJSPromise<T>(observable: Observable<T>): angular.IPromise<T> {
    let defer = this.$q.defer();
    observable.subscribe(
      val => defer.resolve(val),
      err => defer.reject(err)
    );
    return defer.promise as angular.IPromise<T>;
  }

  initModalButtons(status: RDCManualJourneyStatuses): Array<ButtonSet> {
    return status == this.mjStatuses.STOPPED ? [[this.saveButton]] : [];
  }

  private setFooterState() {
    this.hiddenFooter =
      this.modalButtons.length === 0 || this.modalButtons[0].length === 0;
  }

  private setReportControlButtons() {
    this.closeIconText = 'close';
    this.hiddenFooter = true;
  }

  private buildRequestFiltersString(filtersList: Array<string>): string {
    return filtersList.join(this.FILTER_DELIMITER);
  }
  private getRequestLogFiltersList(filtersString: string): Array<string> {
    return filtersString.split(this.FILTER_DELIMITER).filter(f => f !== '');
  }
  private sortTagsByRequestSequence(
    tags: Array<IManualJourneyTag>
  ): Array<IManualJourneyTag> {
    return tags.sort((t1, t2) => {
      const t1rId = t1.journeyRequestIds && t1.journeyRequestIds[0];
      const t2rId = t2.journeyRequestIds && t2.journeyRequestIds[0];
      const t1Seq = this.requestLogSequencesMap.get(t1rId);
      const t2Seq = this.requestLogSequencesMap.get(t2rId);
      return t1Seq > t2Seq ? 1 : t1Seq < t2Seq ? -1 : 0;
    });
  }

  getTagsAmount(tags: Array<IManualJourneyTag>, calcStubs: boolean): number {
    if (!tags) return 0;
    return calcStubs ? tags.length : tags.filter(t => !t.stub).length;
  }

  onChangeManageSSL(flag: boolean): void {
    this.manualJourneyService
      .updateAManualJourneyManageSSL(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        flag
      )
      .then((mj: IManualJourney) => {
        this.activeManualJourney = mj;
      });
  }
  onChangeRequestLogSSL(log: IManualJourneyRequestLog): void {
    var logFilter: string = `^${this.getHostname(log.requestLog)}$`;
    var filtersList = this.getRequestLogFiltersList(this.requestLogFilters);
    var filterIndex = filtersList.indexOf(logFilter);
    if (filterIndex == -1) {
      filtersList.push(logFilter);
    } else {
      filtersList.splice(filterIndex, 1);
    }
    this.updateManualJourneyRequestFilters(
      this.activeDeviceProfile.id,
      this.activeManualJourney.id,
      filtersList
    );
  }

  onChangeManualRequestLogSSL(): void {
    this.requestLogFiltersValid = this.areRequestLogFiltersValid();
  }

  private areRequestLogFiltersValid(): boolean {
    return this.validateRequestLogFilters(
      this.getRequestLogFiltersList(this.requestLogFilters)
    );
  }

  onBlurManualRequestLogSSL(requestLogFilters: string): void {
    if (!this.requestLogFiltersValid) return;
    this.updateManualJourneyRequestFilters(
      this.activeDeviceProfile.id,
      this.activeManualJourney.id,
      this.getRequestLogFiltersList(this.requestLogFilters)
    );
  }

  updateManualJourneyRequestFilters(
    activeDeviceProfileId: number,
    activeManualJourneyId: number,
    filtersList: Array<string>
  ): void {
    this.manualJourneyService
      .updateManualJourneyRequestFilters(
        activeDeviceProfileId,
        activeManualJourneyId,
        filtersList
      )
      .then((newFilters: Array<string>) => {
        this.requestLogFilters = this.buildRequestFiltersString(newFilters);
        this.requestLogs = this.processRequestLogsSSLFlags(
          this.requestLogs,
          this.getRequestLogFiltersList(this.requestLogFilters)
        );
      });
  }

  validateRequestLogFilters(filters: Array<string>): boolean {
    return !filters.find(f => !this.validateRegex(f));
  }
  validateRegex(line: string): boolean {
    try {
      new RegExp(line);
    } catch (e) {
      return false;
    }
    return true;
  }

  processRequestLogsSSLFlags(
    logs: Array<IManualJourneyRequestLog>,
    filters: Array<string>
  ): Array<IManualJourneyRequestLog> {
    logs.forEach(l => (l.enabledSsl = this.getRequestLogSSLFlag(l, filters)));
    return logs;
  }
  getRequestLogSSLFlag(
    log: IManualJourneyRequestLog,
    filters: Array<string>
  ): boolean {
    return !filters.find(f =>
      new RegExp(f).test(this.getHostname(log.requestLog))
    );
  }

  escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  getHostname(url: string): string {
    if (!new RegExp('^http(s?)://', 'i').test(url)) url = `http://${url}`;
    return new URL(url).host;
  }

  public export(): void {
    const template = ModalTemplates.initTemplate<IManualJourneyExportModalData>(
      ModalTemplates.ManualJourneyExport
    );
    template.modalData = {
      deviceProfileId: this.activeDeviceProfile.id,
      manualJourneyId: this.activeManualJourney.id
    };
    this.modalService.showModal<IManualJourneyExportModalData>(
      template,
      () => {}
    );
  }

  private subscribeOnLivePresenseCheck(deviceProfileId: number, manualJourneyId: number) {
    this.manualJourneySocketsService.subscribeOnLivePresenseCheck(
      deviceProfileId,
      manualJourneyId,
      (update: any) => this.handlePresenceCheckUpdate(update)
    );
  }

  private unsubscribeFromLivePresenceCheck() {
    if (this.activeDeviceProfile && this.activeManualJourney) {
      this.manualJourneySocketsService.unsubscribeFromLivePresenceCheck(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id
      );
    }
  }

  private subscribeOnLiveDataUpdates(
    accountId: number,
    deviceProfileId: number,
    manualJourneyId: number
  ): void {
    this.manualJourneySocketsService.subscribeOnManualJourneyStateUpdate(
      accountId,
      (update: any) => {
        if (
          !this.activeManualJourney ||
          update.runId != this.activeManualJourney.id
        )
          return;
        this.handleManualJourneyStateUpdate(update);
      }
    );
    this.manualJourneySocketsService.subscribeOnLiveTagUpdates(
      deviceProfileId,
      manualJourneyId,
      (tags: Array<IManualJourneyTag>) => this.handleLiveTagUpdate(tags)
    );
    this.manualJourneySocketsService.subscribeOnLiveRequestUpdates(
      deviceProfileId,
      manualJourneyId,
      (logs: Array<IManualJourneyRequestLog>) =>
        this.handleLiveRequestUpdate(logs)
    );
  }
  private unsubscribeFromLiveDataUpdates(): void {
    if (this.user) {
      this.manualJourneySocketsService.unsubscribeFromManualJourneyStateUpdate(this.user.accountId);
    }
    if (this.activeDeviceProfile && this.activeManualJourney) {
      this.manualJourneySocketsService.unsubscribeFromLiveTagUpdates(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
      );
      this.manualJourneySocketsService.unsubscribeFromLiveRequestUpdates(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id
      );
    }
  }

  handleManualJourneyStateUpdate(update): void {
    this.activeManualJourney.status = update.state;
    this.modalButtons = this.initModalButtons(this.activeManualJourney.status);
    this.setFooterState();
    switch (update.state) {
      case this.mjStatuses.IN_PROGRESS:
      case this.mjStatuses.ABORTED:
        break;
      case this.mjStatuses.FAILED:
        this.updateRouteToJourneyRunReport();
        this.contentHeaderUrl = FINISHED_JOURNEY_HEADER_URL;
        this.hideSpinner();
        this.showErrorToast('Journey run failed. Please try again later.');
        break;
      case this.mjStatuses.STOPPED:
      case this.mjStatuses.FINISHED:
        break;
      case this.mjStatuses.PROCESSED:
      case this.mjStatuses.RULES_PROCESSING: //ws not send this yet
      case this.mjStatuses.RULES_REPROCESSING: //ws not send this yet
        this.hideSpinner();
        break;
      case this.mjStatuses.ENFORCED:
        this.updateRouteToJourneyRunReport();
        this.manualJourneyService
          .getManualJourneyProcessedRules(
            this.activeDeviceProfile.id,
            this.activeManualJourney.id
          )
          .then((processedRules: Array<IManualJourneyRule>) => {
            this.processedRules = processedRules;
          }).finally(() => { this.waitingForRulesProcessingToComplete = false; });
        break;
    }
  }

  private handleLiveTagUpdate(tags: Array<IManualJourneyTag>): void {
    if (!tags.length) return;
    const lastStep = this.manualJourneySteps[
      this.manualJourneySteps.length - 1
    ];
    tags.forEach(t => {
      t.stepId !== (lastStep && lastStep.id) ? (t.stepId = lastStep.id) : null;
    });
    this.manualJourneyTags = this.manualJourneyTags.concat(tags);
    this.filterManualJourneyTags(
      this.primaryTagFilter,
      this.manualJourneyTags
    ).then((fts: Array<IManualJourneyTag>) => {
      let priorTagsArrayLength = this.filteredManualJourneyTags.length;

      this.filteredManualJourneyTags = fts;
      // Only set focus on first selectable tag when loading tags for the first time
      if (priorTagsArrayLength === 0) {
        this.activateFirstSelectableTag(this.filteredManualJourneyTags);
      }
    });
    this.eventManager.publish(Events.rdcUserPresenceConfirmation);
  }
  private handleLiveRequestUpdate(logs: Array<IManualJourneyRequestLog>): void {
    if (!logs.length) return;
    if (!this.requestLogs.length && logs.length)
      this.activateRequestLog(logs[0]);
    logs = this.serializeRequestLogs(logs);
    logs = this.processRequestLogsSSLFlags(
      logs,
      this.getRequestLogFiltersList(this.requestLogFilters)
    );
    const lastStep = this.manualJourneySteps[
      this.manualJourneySteps.length - 1
    ];
    logs.forEach(l => {
      l.stepId !== (lastStep && lastStep.id) ? (l.stepId = lastStep.id) : null;
    });
    this.requestLogs = this.requestLogs.concat(logs);
    this.filteredRequestLogs = this.filterRequestLogs(
      this.logsFilter,
      this.requestLogs,
      this.filterRequestLogsByRegex
    );
    this.eventManager.publish(Events.rdcUserPresenceConfirmation);
  }

  private serializeRequestLogs(
    logs: Array<IManualJourneyRequestLog>
  ): Array<IManualJourneyRequestLog> {
    if (!this.requestLogSequencesMap) {
      this.requestLogSequencesMap = new Map();
    }
    return logs.map((l, index) => {
      this.requestLogSequencesMap.set(l.id, index);
      l.requestInfo = this.manualJourneyService.serializeRequestLogProps(
        l.requestInfo
      );
      l.responseInfo = this.manualJourneyService.serializeRequestLogProps(
        l.responseInfo
      );
      return l;
    });
  }

  private handlePresenceCheckUpdate(update): void {
    this.showUserPresenceConfirmation(update.timeoutMilliseconds / 1000);
  }

  private showUserPresenceConfirmation(countDown: number): void {
    const template = ModalTemplates.initTemplate<IRdcUserPresenceModalData>(
      ModalTemplates.RDCUserPresence
    );
    template.modalData = { countDown: countDown };
    this.modalService.showConfirmation<IRdcUserPresenceModalData, boolean>(
      template,
      (abortRecording: boolean = true) => {
        if (abortRecording) {
          this.close();
          return;
        }
        this.manualJourneyService.confirmUserPresence(
          this.activeDeviceProfile.id,
          this.activeManualJourney.id
        );
      }
    );
  }

  onChangePrimaryToggle(value: boolean): void {
    this.primaryTagFilter = value;
    this.manageTagsService.setActiveReportToggle(value);
    this.filterManualJourneyTags(value, this.manualJourneyTags).then(
      (fts: Array<IManualJourneyTag>) => (this.filteredManualJourneyTags = fts)
    );
  }

  filterManualJourneyTags(
    filter: boolean,
    manualJourneyTags: Array<IManualJourneyTag>
  ): angular.IPromise<Array<IManualJourneyTag>> {
    return this.manageTagsService
      .getPrimaryTags()
      .then((primaryTags: Array<IPrimaryTag>) => {
        return filter
          ? this.manageTagsService.filterManualJourneyTagsByPrimaryTags(
              manualJourneyTags,
              primaryTags
            )
          : this.manualJourneyTags;
      });
  }

  activateFirstSelectableTag(
    tags: Array<IManualJourneyTag>
  ): IManualJourneyTag {
    var firstSelectableTag: IManualJourneyTag = tags.find(t => !t.stub);
    if (firstSelectableTag) this.activateTag(firstSelectableTag);
    return firstSelectableTag;
  }

  private getSelectedRules(
    rules: Array<IRulePreview>,
    selectedRuleIds: Array<number>
  ): Array<IRulePreview> {
    return rules.filter(r => selectedRuleIds.indexOf(r.id) != -1);
  }

  startRecording(): void {
    this.showSpinner('Enabling request capturing...');
    this.buttonStates.start.blocked = true;
    this.setFooterState();
    this.manualJourneyService
      .controlAManualJourney(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        'start'
      )
      .then((firstStep: IManualJourneyStep) => {
        this.buttonStates.start.blocked = false;
        this.activeManualJourney.status = this.mjStatuses.IN_PROGRESS;
        this.manualJourneySteps.push(firstStep);
        if (this.manualJourneySteps.length)
          this.activateStep(this.manualJourneySteps[0]);
        this.contentHeaderUrl = IN_PROGRESS_JOURNEY_HEADER_URL;
        this.subscribeOnLiveDataUpdates(
          this.user.accountId,
          this.activeDeviceProfile.id,
          this.activeManualJourney.id
        );
        this.$scope.$on('$destroy', () => {
          this.unsubscribeFromLiveDataUpdates();
        });
      }, error => {
        this.showErrorToast('Failed to start recording. Looks like journey is in a broken state. Please, start a new journey.');
      }).finally(() => { this.hideSpinner(); });
  }
  stopRecording(): void {
    this.showSpinner('Disabling request capturing...');
    this.buttonStates.stop.blocked = true;
    this.manualJourneyService
      .controlAManualJourney(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        'stop'
      )
      .then(() => {
        this.buttonStates.stop.blocked = false;
        this.activeManualJourney.status = this.mjStatuses.STOPPED;
        this.modalButtons = this.initModalButtons(
          this.activeManualJourney.status
        );
        this.setFooterState();
        this.contentHeaderUrl = STOPPED_JOURNEY_HEADER_URL;
        this.activateRulesView('assigned');
      }, (error: IApiErrorResponse) => {
        this.buttonStates.stop.blocked = false;
        console.error(`Failed to stop recording liveConnectJournyId=${this.activeManualJourney.id}`, error);
        this.showErrorToast('Failed to stop recording. Try again later or start a new journey.');
      }).finally(() => { this.hideSpinner(); });
  }

  restartRecording(): void {
    this.showConfirmationModal([
      `Are you sure you want to Start Over?`,
      'All Recorded Data will be lost.'
    ], () => {
      this.showSpinner('Restarting your journey...', 'This might take a while. Please wait...');
      this.manualJourneyService
        .controlAManualJourney(
          this.activeDeviceProfile.id,
          this.activeManualJourney.id,
          'restart'
        )
        .then(() => {
          this.contentHeaderUrl = IN_PROGRESS_JOURNEY_HEADER_URL;
          this.modalButtons = this.initModalButtons(
            this.activeManualJourney.status
          );
          this.setFooterState();
          this.processedRules = [];
          this.requestLogFilters = '';
          this.requestLogs = [];
          this.filteredRequestLogs = this.requestLogs;
          this.attachedFiles = [];
          this.manualJourneyTags = [];
          this.activeRequestLog = undefined;
          return Promise.all([
            this.filterManualJourneyTags(
              this.primaryTagFilter,
              this.manualJourneyTags
            ),
            this.manualJourneyService
              .getManualJourneySteps(
                this.activeDeviceProfile.id,
                this.activeManualJourney.id
              )
          ]).then(([fts, steps]) => {
            this.filteredManualJourneyTags = fts;
            this.manualJourneySteps = steps;
            if (this.manualJourneySteps.length) {
              this.activateStep(this.manualJourneySteps[0]);
            }
          });
        }).then(undefined, (error: IApiErrorResponse) => {
        console.error(`Failed to restart journey liveConnectJourneyId=${this.activeManualJourney.id}`, error);
        this.showErrorToast('Failed to restart recording. Try again later or start a new journey.');
      }).finally(() => { this.hideSpinner(); });
    });
  }
  resumeRecording(): void {
    this.showSpinner('Enabling request capturing...');
    this.manualJourneyService
      .controlAManualJourney(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        'resume'
      )
      .then(() => {
        this.contentHeaderUrl = IN_PROGRESS_JOURNEY_HEADER_URL;
        this.modalButtons = this.initModalButtons(
          this.activeManualJourney.status
        );
        this.setFooterState();
      }, (error: IApiErrorResponse) => {
        console.error(`Failed to resume recording liveConnectJourneyId=${this.activeManualJourney.id}`, error);
        this.showErrorToast('Failed to resume recording. Try again later or start a new journey.');
      }).finally(() => { this.hideSpinner(); });
  }

  saveAndFinish(): void {
    this.showSpinner('Finalising your journey, parsing tags and calculating summaries.', 'This might take a while. Please wait...');
    this.manualJourneyService.controlAManualJourney(
      this.activeDeviceProfile.id,
      this.activeManualJourney.id,
      'finalize'
    ).then(() => {
      this.setReportControlButtons();
      this.contentHeaderUrl = FINISHED_JOURNEY_HEADER_URL;
      this.waitingForRulesProcessingToComplete = true;
      this.updateRouteToJourneyRunReport();
      if (this.activeManualJourney.status < this.mjStatuses.ENFORCED) {
        this.showSpinner('Processing data...', 'This might take a while. Please wait...');
      } else {
        this.hideSpinner();
      }
    }, (error: IApiErrorResponse) => {
      console.error(`Failed to save and finish liveConnectJourneyId=${this.activeManualJourney.id}`, error);
      if (error.code === 504 && this.activeManualJourney.status < this.mjStatuses.ENFORCED) {
        this.showSpinner('Still finalizing...', 'It is taking a bit longer to shutdown the infrastructure. Please wait...');
      } else {
        this.showErrorToast('Failed to finalize recording. Try again later or start a new journey.');
        this.hideSpinner();
      }
    });
  }

  private updateRouteToJourneyRunReport() {
    this.$location.url(LiveConnectUrlBuilders.manualJourneyEdit(this.activeDeviceProfile.id, this.activeManualJourney.id));
  }

  generateNewManualJourney(
    dp: IDeviceProfile,
    initedMj: IInitializedManualJourney
  ): IManualJourney {
    return {
      id: initedMj.runId,
      name: initedMj.name,
      versionOfOs: dp.versionOfOs,
      osOfDevice: dp.osOfDevice,
      labels: [],
      journeyId: dp.id,
      proxyInfo: `${initedMj.proxyInfo.interface}:${initedMj.proxyInfo.port}`,
      certificate: initedMj.certificate,
      status: this.mjStatuses.NOT_STARTED,
      requestedAt: new Date(),
      startedAt: new Date(),
      completedAt: new Date(),
      manageSSL: true
    };
  }

  toggleTagsWindow(): void {
    this.tagsWindowCollapsed = !this.tagsWindowCollapsed;
  }
  toggleRulesWindow(): void {
    this.rulesWindowCollapsed = !this.rulesWindowCollapsed;
  }

  setDeviceProfileInfoEditMode(flag: boolean): void {
    this.deviceProfileInfoEditMode = flag;
  }
  setJourneyNameEditMode(flag: boolean): void {
    this.journeyNameEditMode = flag;
  }
  updateAManualJourneyName(name: string): void {
    this.setJourneyNameEditMode(false);
    this.manualJourneyService
      .updateAManualJourneyName(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        name
      )
      .then((mj: IManualJourney) => {
        this.activeManualJourney.name = mj.name;
      });
  }

  activateRequestLog(requestLog: IManualJourneyRequestLog): void {
    this.activeRequestLog = requestLog;
  }

  activeStepByRequestLog(requestLog: IManualJourneyRequestLog): void {
    const step = this.manualJourneySteps.find(s => s.id === requestLog.stepId);
    if (step) this.activateStep(step);
  }

  activateTag(tag: IManualJourneyTag): void {
    this.activeTag = tag;
    this.activeRuleCreatorTag = this.buildRuleCreatorTag(tag);
    if (this.filteredRequestLogs) {
      this.activeRequestLog = this.filteredRequestLogs.find(
        l => l.journeyTagIds.indexOf(tag.id) !== -1
      );
    }
  }

  openRuleSelector(): void {
    const selectedRules = {
      selectedItems: this.selectedRules.map(rule => { return { rule }; }),
      selectedRuleIds: this.selectedRulesIds
    };
    const data: ICurtainedRuleSelectorComponentData = {
      rules: this.allRules,
      labels: this.allLabels,
      selectedRules: selectedRules as any,
      openRuleCreation: this.openRuleCreation.bind(this),
      openRuleEditor: this.openRuleEditor.bind(this),
    } as any;

      const modal = this.modalServiceNg.openFixedSizeModal(CurtainedRuleSelectorComponent, {data});
      this.ruleSelectorComponentInstance = modal.componentInstance;
      modal.afterClosed().subscribe(rules => this.closeRuleSelector(rules));
  }

  closeRuleSelector(selectedRuleIds: number[]): void {
    if (selectedRuleIds) {
      this.rulePickerApply(selectedRuleIds);
    }
  }

  openRuleCreation(): void {
    this.modalServiceNg.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.create
      }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => this.closeRuleCreation(rule));
  }

  openRuleEditor(rule: IRule) {
    this.modalServiceNg.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.edit,
        ruleId: rule.id
      }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => this.onEditRule(rule));
  }

  async onEditRule(rule: IRule) {
    if (rule) {
      const updatedRule = this.allRules.find(r => r.id === rule.id);

      // update all labels in case new ones were added
      if (rule.labels) {
        this.labelsService.getLabels().subscribe(labels => {
          this.allLabels = labels;
        });
      }

      // update the labels in case they have changed after being edited
      updatedRule.labels = rule.labels;

      // update the name in case it has changed after being edited
      updatedRule.name = rule.name;
    }
  }

  closeRuleCreation(newRule?: IRule): void {
    if (newRule) {
      this.ruleSelectorComponentInstance.addRule(newRule);
    }
  }
  activateRulesView(viewType: RDCRulesViewType): void {
    this.activeRulesViewType = viewType;
  }
  activateControlTab(tabType: RDCControlTabType): void {
    this.activeControlTab = tabType;
  }
  activateRequestDetailsTab(tabType: RDCRequestDetailsTabType): void {
    this.activeRequestDetailsTab = tabType;
  }

  activateStep(step: IManualJourneyStep): void {
    this.activeStep = step;
  }
  activeStepByTag(tag: IManualJourneyTag): void {
    this.activateTag(tag);
    let step = this.manualJourneySteps.find(s => s.id == tag.stepId);
    if (step) this.activateStep(step);
  }
  addStep(): void {
    this.showSpinner('Adding a new action.', 'Please wait...');
    this.manualJourneyService
      .addManualJourneyStep(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id
      )
      .then((nextStep: IManualJourneyStep) => {
        this.manualJourneySteps.push(nextStep);
        this.activateStep(nextStep);
      }, (error: IApiErrorResponse) => {
        console.error(`Failed to create new action liveConnectJourneyId=${this.activeManualJourney.id}`, error);
        this.showErrorToast('Failed to add a new action. Try again later or start a new journey.');
      }).finally(() => { this.hideSpinner(); });
  }

  removeStep(step: IManualJourneyStep) {
    if (this.activeManualJourney.status !== this.mjStatuses.IN_PROGRESS) return;

    this.showSpinner('Removing the action.', 'Please wait...');
    this.manualJourneyService
      .deleteManualJourneyStep(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        step.id
      )
      .then(() => {
        let stepIndex = this.manualJourneySteps.indexOf(step);
        this.manualJourneySteps.splice(stepIndex, 1);
        let previousStep = this.manualJourneySteps[stepIndex - 1];
        if (this.activeStep.id == step.id) {
          this.activateStep(previousStep);
        }

        this.requestLogs.forEach((r: IManualJourneyRequestLog) => {
          if (r.stepId == step.id) {
            r.stepId = previousStep.id;
          }
        });
        this.filteredRequestLogs = this.filterRequestLogs(
          this.logsFilter,
          this.requestLogs,
          this.filterRequestLogsByRegex
        );

        this.manualJourneyTags.forEach((t: IManualJourneyTag) => {
          if (t.stepId == step.id) {
            t.stepId = previousStep.id;
          }
        });
        return this.filterManualJourneyTags(
          this.primaryTagFilter,
          this.manualJourneyTags
        ).then(
          (fts: Array<IManualJourneyTag>) =>
            (this.filteredManualJourneyTags = fts)
        );
      }, (error: IApiErrorResponse) => {
        console.error(`Failed to remove action liveConnectJourneyId=${this.activeManualJourney.id}`, error);
        this.showErrorToast('Failed to remove action. Try again later or start a new journey.');
      }).finally(() => { this.hideSpinner(); });
  }

  requestLogsFilterChanged(query: string): void {
    this.filteredRequestLogs = this.filterRequestLogs(
      query,
      this.requestLogs,
      this.filterRequestLogsByRegex
    );
  }
  filterRequestLogs(
    query: string,
    requestLogs: Array<IManualJourneyRequestLog>,
    filterRequestLogsByRegex: boolean
  ): Array<IManualJourneyRequestLog> {
    if (!query) return requestLogs;
    return requestLogs.filter(log => {
      if (filterRequestLogsByRegex)
        return new RegExp(query).test(log.requestLog);
      return log.requestLog.toLowerCase().indexOf(query.toLowerCase()) != -1;
    });
  }

  toggleStepNote(step: IManualJourneyStep): void {
    this.activeStepNoteId = this.activeStepNoteId == step.id ? -1 : step.id;
    this.setStepNotesEditMode(false);
    this.tempStepNotes = step.note;
  }
  setStepNotesEditMode(flag: boolean): void {
    this.stepNotesEditMode = flag;
  }
  cancelStepNotes(step: IManualJourneyStep): void {
    this.setStepNotesEditMode(false);
    this.tempStepNotes = step.note || '';
  }
  saveStepNotes(step: IManualJourneyStep): void {
    this.manualJourneyService
      .updateManualJourneyStep(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        step.id,
        { name: step.name, notes: this.tempStepNotes }
      )
      .then((newStep: IManualJourneyStep) => {
        this.setStepNotesEditMode(false);
        step.note = newStep.note;
      });
  }

  setStepNameEditMode(step: IManualJourneyStep, flag: boolean): void {
    this.activeStepNameId = this.activeStepNameId == step.id ? -1 : step.id;
    this.tempStepName = step.name || '';
    this.stepNameEditMode = flag;
  }
  cancelStepName(step: IManualJourneyStep): void {
    this.setStepNameEditMode(step, false);
  }
  saveStepName(step: IManualJourneyStep): void {
    this.manualJourneyService
      .updateManualJourneyStep(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        step.id,
        { name: this.tempStepName, notes: step.note }
      )
      .then((newStep: IManualJourneyStep) => {
        this.setStepNameEditMode(newStep, false);
        step.name = newStep.name;
      });
  }

  setFileNameEditMode(file: IManualJourneyFile, flag: boolean): void {
    this.activeFileNameId = this.activeFileNameId == file.id ? -1 : file.id;
    this.tempFileName = file.name || '';
    this.fileNameEditMode = flag;
  }
  cancelFileNameEdit(file: IManualJourneyFile): void {
    this.setFileNameEditMode(file, false);
  }
  saveFileName(file: IManualJourneyFile): void {
    let fileUpdate = {
      name: this.tempFileName
    };
    this.rdcAttachedFilesService
      .updateManualJourneyFile(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        file.id,
        fileUpdate
      )
      .then((newFile: IManualJourneyFile) => {
        this.setFileNameEditMode(file, false);
        file.name = newFile.name;
      });
  }

  //F1
  fileSelected(fileList: FileList): void {
    this.$timeout(() => {
      if (fileList.length == 0 || fileList.length > 1) return;
      this.newFile = fileList[0];
      this.newFileMetadata = {
        name: fileList[0].name
      };
    });
  }

  dropFile(fileList: FileList): void {
    this.fileSelected(fileList);
  }

  unselectFile(): void {
    delete this.newFile;
    delete this.newFileMetadata;
  }

  saveNewFile(): void {
    this.fileUploadingInProgress = true;

    this.rdcAttachedFilesService
      .uploadManualJourneyFile(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        this.newFile,
        this.newFileMetadata,
        progressEvent => {
          if (progressEvent.lengthComputable) {
            console.log((progressEvent.loaded / progressEvent.total) * 100);
          }
        }
      )
      .then((uploadedFile: IManualJourneyFile) => {
        this.attachedFiles.push(uploadedFile);
        this.unselectFile();
      })
      .finally(() => {
        this.fileUploadingInProgress = false;
      });
  }

  removeFile(file: IManualJourneyFile): void {
    this.fileRemovalInProgress = true;

    this.rdcAttachedFilesService
      .removeManualJourneyFile(
        this.activeDeviceProfile.id,
        this.activeManualJourney.id,
        file.id
      )
      .then(() => {
        this.attachedFiles.splice(this.attachedFiles.indexOf(file), 1);
      })
      .finally(() => {
        this.fileRemovalInProgress = false;
      });
  }

  //R1
  //On finish reprocessing - display tooltip
  reprocessRules(): void {
    const template = ModalTemplates.initTemplate<IReprocessRulesModalData>(
      ModalTemplates.ReprocessRules
    );
    template.modalData = {
      rules: this.selectedRules,
      reprocessRules: (): void => {
        //subscribe on socket updates
        this.reprocessService
          .subscribeOnManualJourneyReprocessingRulesUpdates(
            this.user.accountId,
            this.activeDeviceProfile.id,
            this.activeManualJourney.id,
            this.activeManualJourney.name,
            update => this.handleManualJourneyStateUpdate(update)
          )
          .then((ws: IWebsocketService) => {
            // If connection between UI and API is slow and journey has low amount of tags and rules,
            // reprocessing happens really quickly and UI never receives "Reprocessing finished" update.
            // (possibly because websocket subscription/connection is not yet established correctly)
            // setTimeout below completely solved the problem for me.
            // However we still might have same issues on other environments, in other conditions.
            setTimeout(() => {
              this.manualJourneyService
                .reprocessManualJourneyRules(
                  this.activeDeviceProfile.id,
                  this.activeManualJourney.id
                )
                .then((response: IManualJourneyRulesReprocessingResponse) => {
                  if (!response.startedReprocessing) return;
                  this.reprocessService.displayManualJourneyRulesReprocessingStartedToast(
                    this.activeManualJourney.name
                  );
                });
            }, 1000);
          });
      }
    };
    this.modalService.showConfirmation<IReprocessRulesModalData, boolean>(
      template,
      () => {}
    );
  }

  removeSelectedRule(rule: IManualJourneyRule): void {
    var rulesIdsCopy = angular.copy(this.selectedRulesIds);
    var index = rulesIdsCopy.indexOf(rule.id);
    rulesIdsCopy.splice(index, 1);
    this.deviceProfileService
      .updateDeviceProfileRules(this.activeDeviceProfile.id, rulesIdsCopy)
      .toPromise()
      .then((rulesIds: Array<number>) => {
        this.selectedRulesIds = rulesIdsCopy;
        this.selectedRules = this.getSelectedRules(
          this.allRules,
          this.selectedRulesIds
        );
      });
  }

  rulePickerApply(selectedRules) {
    this.showSpinner('Assigning rules', 'Please wait...');
    this.selectedRulesIds = selectedRules;
    this.deviceProfileService
      .updateDeviceProfileRules(
        this.activeDeviceProfile.id,
        this.selectedRulesIds
      )
      .toPromise()
      .then((rulesIds: Array<number>) => {
        this.selectedRulesIds = rulesIds;
        this.selectedRules = this.getSelectedRules(
          this.allRules,
          this.selectedRulesIds
        );
        this.activateRulesView('assigned');
      }, (error: IApiErrorResponse) => {
        console.error(`Failed to assign rules liveConnectJourneyId=${this.activeManualJourney.id}`, error);
        this.showErrorToast('Failed to assign rules. Try again later.');
      }).finally(() => this.hideSpinner());
  }

  rulePickerCancel() {
    this.activateRulesView('assigned');
  }

  ruleCreatorComponentApply(rule: IRule): void {
    this.allRules.push(rule);
    var rulesIdsCopy = angular.copy(this.selectedRulesIds);
    rulesIdsCopy.push(rule.id);
    this.deviceProfileService
      .updateDeviceProfileRules(this.activeDeviceProfile.id, rulesIdsCopy)
      .toPromise()
      .then((rulesIds: Array<number>) => {
        this.selectedRulesIds = rulesIds;
        this.selectedRules = this.getSelectedRules(
          this.allRules,
          this.selectedRulesIds
        );
        this.activateRulesView('assigned');
      });
  }
  ruleCreatorComponentCancel(): void {
    this.activateRulesView('assigned');
  }

  loadRules(): angular.IPromise<Array<IRulePreview>> {
    return this.rulesService.getRulePreviews().toPromise();
  }
  loadLabels(): Observable<ILabel[]> {
    return this.labelsService.getLabels();
  }

  setSort(column: string): void {
    var reverse =
      this.tagVarsTableSort.sortOn == column
        ? !this.tagVarsTableSort.reverse
        : false;
    switch (column) {
      case 'order':
        this.tagVarsTableSort = { sortOn: 'order', name: '', reverse: reverse };
        break;
      case 'value':
        this.tagVarsTableSort = { sortOn: 'value', name: '', reverse: reverse };
    }
  }

  displayActualVariableName(variable: IJourneyVariable): String {
    return this.shouldDisplayActualName(variable)
      ? '(' + variable.name + ')'
      : '';
  }
  private shouldDisplayActualName(variable: IJourneyVariable): Boolean {
    return variable.description !== variable.name;
  }

  isObjectEmpty(object): boolean {
    return Object.keys(object).length === 0;
  }

  private buildRuleCreatorTag(tag: IManualJourneyTag): IRuleCreatorTag {
    return {
      tagId: tag.tagId,
      tagIcon: tag.icon,
      tagName: tag.name,
      account: tag.account,
      variables: tag.variables
    };
  }

  private loadAManualJourney(
    dpId: number,
    mjId: number
  ): angular.IPromise<IManualJourney> {
    return this.manualJourneyService.getAManualJourney(dpId, mjId);
  }

  private loadADeviceProfile(dpId: number): angular.IPromise<IDeviceProfile> {
    return this.$q((resolve, reject) => {
      this.deviceProfileService.getADeviceProfile(dpId)
        .subscribe(resolve);
    });
  }

  private pureClose(): void {
    this.$scope.$hide();
    // this.routeHistoryService.goToLastFromHistory();
    this.router.navigateByUrl(LiveConnectUrlBuilders.base());
  }

  private isJourneyInProgress(): boolean {
    return this.initialisingInProgress
      || this.activeManualJourney && (this.activeManualJourney.status < this.mjStatuses.FAILED);
  }

  private isJourneyBeingProcessed(): boolean {
    return this.activeManualJourney
      && this.activeManualJourney.status > this.mjStatuses.FAILED
      && this.activeManualJourney.status < this.mjStatuses.ENFORCED;
  }

  private close(): void {
    if (this.isJourneyInProgress()) {
      this.showConfirmationModal([
        `Are you sure you want to close?`,
        'Journey will be removed and all Recorded Data will be lost.'
      ], () => {
        this.showSpinner('Removing journey.', 'Please wait...');
        this.manualJourneyService.removeManualJourney(this.activeDeviceProfile.id, this.activeManualJourney.id)
          .finally(() => {
            this.hideSpinner();
            this.pureClose();
          });
      });
    } else {
      this.pureClose();
    }
  }

  addRule() {
    this.modalServiceNg.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.create,
        hidePageMatching: true
      }
    }, 'rule-setup-modal')
    .afterClosed()
    .subscribe(rule => this.onAddRule(rule));
  }

  private onAddRule(rule?: IRule) {
    if (!rule) return;
    
    this.allRules.push(rule);
    let rulesIdsCopy = angular.copy(this.selectedRulesIds);
    rulesIdsCopy.push(rule.id);
    this.deviceProfileService
      .updateDeviceProfileRules(this.activeDeviceProfile.id, rulesIdsCopy)
      .toPromise()
      .then((rulesIds: Array<number>) => {
        this.selectedRulesIds = rulesIds;
        this.selectedRules = this.getSelectedRules(
          this.allRules,
          this.selectedRulesIds
        );
      })
      .then(() => {
        this.openRuleSelector();
      })
  }

  private showConfirmationModal(messages: string[], onConfirmed: () => void) {
    const template = ModalTemplates.initTemplate<IConfirmationModalData>(
      ModalTemplates.Confirmation
    );
    template.modalData = {
      messages: messages,
      hideDiscardButton: true
    };
    this.modalService.showConfirmation<IConfirmationModalData, boolean>(
      template,
      isConfirmed => {
        if (isConfirmed) {
          onConfirmed();
        }
      }
    );
  }
}
