import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AccountsService } from '@app/components/account/account.service';
import { ModalTemplates } from '@app/components/modals/modalTemplates';
import { userIsOPAdmin } from '@app/authUtils';
import { AccountSettingsUrlBuilders } from '@app/components/account-settings/account-settings.const';
import { IConfirmationModalData } from '@app/components/modals/modalData';
import { IModalService } from '@app/components/modals/modalService';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminService } from '@app/components/admin-portal/system-status/admin.service';
import { Observable } from 'rxjs';

type RunTypes = 'audits' | 'web-journeys' ;

const RunTypes = {
  Audits: 'audits' as RunTypes,
  WebJourneys: 'web-journeys' as RunTypes,
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'clean-up',
  templateUrl: './clean-up.component.html',
  styleUrls: ['./clean-up.component.scss']
})
export class CleanUpComponent {
  runTypes: Array<RunTypes> = [
    RunTypes.Audits,
    RunTypes.WebJourneys,
  ];
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMsg: string;
  successMsg: string;
  cleanUpForm: UntypedFormGroup;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private accountsService: AccountsService,
              private adminService: AdminService,
              private fb: UntypedFormBuilder,
              private modalService: IModalService) {
    this.accountsService.getUser().subscribe(user => {
      if (!userIsOPAdmin(user)) {
        this.router.navigateByUrl(AccountSettingsUrlBuilders.user());
      }
    });

    this.cleanUpForm = this.fb.group({
      runType: ['', Validators.required],
      runIDs: ['', Validators.required],
    });
  }

  deleteRuns(): void {
    let runType = this.runType.value;
    let ids = this.runIDs.value;

    this.clearMsgs();

    if (!runType || !ids) {
      this.showError('Please fill all inputs');
      return;
    }

    let arr = ids.split(',').map(item => Number(item));

    for (let i of arr) {
      if (!(<any>Number).isInteger(i) || i <= 0 ) {
        this.showError('Ids input has invalid symbols');
        return;
      }
    }

    if (arr.length > 0) {
      // TODO: Swap out for success modal
      const template = ModalTemplates.initTemplate<IConfirmationModalData>(ModalTemplates.Confirmation);
      template.modalData = {
        messages: ['Are you sure you want to delete these run(s)?', arr.join(', '), 'This cannot be undone.']
      };
      this.modalService.showConfirmation<IConfirmationModalData, boolean>(template, shouldDelete => {
        if (shouldDelete) {
          this.doCall(arr);
        }
      });
    }
  }

  private doCall(arr: Array<number>): void {
    let servicesToMention;
    let deleteObservable: Observable<any>;
    switch (this.runType.value) {
      case RunTypes.Audits:
        servicesToMention = 'Starter, Manager, Processor, Enforcer, Indexer, Summariser';
        deleteObservable = this.adminService.removeAuditRuns(arr);
        break;
      case RunTypes.WebJourneys:
        servicesToMention = 'Starter, Manager, Processor, Enforcer, Indexer, Summariser';
        deleteObservable = this.adminService.removeWebJourneyRuns(arr);
        break;
    }

    deleteObservable.subscribe(
      success => this.showSuccess(`Deletion process started. See progress in logs for ${servicesToMention} services.`),
      error => this.showError('Cannot start delete process. See error log'));
  }

  private showSuccess(msg): void {
    this.hasSuccess = true;
    this.successMsg = msg;
  }

  private showError(msg): void {
    this.hasError = true;
    this.errorMsg = msg;
  }

  clearMsgs(): void {
    this.hasError = false;
    this.errorMsg = '';
    this.hasSuccess = false;
    this.successMsg = '';
  }

  get runType(): AbstractControl {
    return this.cleanUpForm.get('runType');
  }

  get runIDs(): AbstractControl {
    return this.cleanUpForm.get('runIDs');
  }
}
