import { EPageFilterType, ERuleFilterType, ERuleMatchType } from "./rule-setup-conditions-tab.enums";
import { IFilterTypeConfig } from "./rule-setup-conditions-tab.models";

export const ConditionsMatchers = [
  { name: 'All', value: true },
  { name: 'Any', value: false }
];

export const FilterTypes: IFilterTypeConfig[] = [
  {
    name: 'Initial URL',
    value: EPageFilterType.URL,
    matchTypes: [
      { name: 'contains', value: ERuleMatchType.Contains },
      { name: 'does not contain', value: ERuleMatchType.DoesNotContain },
      { name: 'regex', value: ERuleMatchType.Regex }
    ]
  },
  {
    name: 'Initial Page Status Code',
    value: EPageFilterType.StatusCode,
    matchTypes: [
      { name: 'equals', value: ERuleMatchType.Equals },
      { name: 'does not equal', value: ERuleMatchType.DoesNotEqual },
      { name: 'regex', value: ERuleMatchType.Regex }
    ]
  },
  {
    name: 'Final URL',
    value: EPageFilterType.FinalUrl,
    matchTypes: [
      { name: 'contains', value: ERuleMatchType.Contains },
      { name: 'does not contain', value: ERuleMatchType.DoesNotContain },
      { name: 'regex', value: ERuleMatchType.Regex }
    ]
  },
  {
    name: 'Final Page Status Code',
    value: EPageFilterType.FinalStatusCode,
    matchTypes: [
      { name: 'equals', value: ERuleMatchType.Equals },
      { name: 'does not equal', value: ERuleMatchType.DoesNotEqual },
      { name: 'regex', value: ERuleMatchType.Regex }
    ]
  },
  {
  name: 'Tag',
  value: ERuleFilterType.Tag,
  matchTypes: [
      { name: 'equals', value: ERuleMatchType.Equals }
    ]
  }
];
