<div class="ror-first-item-creator" *ngIf="loaded; else loader">
  <!-- No comparisons assigned, so no summary data -->
  <div *ngIf="!comparisonAssigned && !hasComparisonRun; else noComparisonRun" class="flex-col flex-center-items">
    <p>No comparisons have been assigned to this audit.</p>
    <button mat-stroked-button class="zero-state-button" (click)="goToComparisonLibrary()">
      ASSIGN COMPARISONS
    </button>
    <h6>(Comparisons assigned to this audit will only affect future runs)</h6>
  </div>

  <!-- Comparison assigned, but audit not run since assignment, so no summary data -->
  <ng-template #noComparisonRun>
    <div class="flex-col flex-center-items">
      <p>The associated comparison information was added after this run already completed.</p>
      <p>Please rerun the audit and generate new results.</p>
      <button mat-stroked-button class="zero-state-button" (click)="runAudit()">RUN AUDIT NOW</button>
    </div>
  </ng-template>
</div>

<ng-template #loader>
  <mat-spinner class="centered" [diameter]="75" [strokeWidth]="8"></mat-spinner>
</ng-template>
