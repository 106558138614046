import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'one-time-comparison-match-tags',
  templateUrl: './one-time-comparison-match-tags.component.html',
  styleUrls: ['./one-time-comparison-match-tags.component.scss']
})
export class OneTimeComparisonMatchTagsComponent {

  @Input() formGroup: UntypedFormGroup;

}
