import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuditReportBase, IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { BehaviorSubject, EMPTY, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { catchError, debounceTime, finalize, map, skip, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  TagDuplicatesAndMultiplesService
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.service';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  TagDuplicatesAndMultiplesExportMenuData,
  TagDuplicatesAndMultiplesMergedFilters,
  TagDuplicatesAndMultiplesPage,
  TagDuplicatesAndMultiplesPages,
  TagDuplicatesAndMultiplesPagesPagination,
  TagDuplicatesAndMultiplesPagesTablePagination,
  TagDuplicatesAndMultiplesPagesTableRow,
  TagDuplicatesAndMultiplesSummary,
  TagDuplicatesAndMultiplesTags,
  TagDuplicatesAndMultiplesTrendRunOverview,
  TagDuplicatesAndMultiplesTrendRunOverviews,
  TagDuplicatesAndMultiplesTrends
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.models';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import { cloneDeep, sortBy } from 'lodash-es';
import {
  IFullscreenChartConfig,
  IFullscreenChartData,
  IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import {
  FullscreenChartModalComponent
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import {
  PagesTableSettingItems,
  TagDuplicatesAndMultiplesExportType,
  TagDuplicatesAndMultiplesRelevantFilters,
  TagDuplicatesAndMultiplesTrendName,
  TOTAL_DUPLICATES_CHART_CONFIG,
  TOTAL_MULTIPLES_CHART_CONFIG,
  TOTAL_TAG_REQUESTS_CHART_CONFIG,
  UNIQUE_TAGS_CHART_CONFIG
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.constants';
import { DecimalPipe, formatNumber } from '@angular/common';
import {
  TagsTableFilter,
  TagsTableReportFilter
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples-tags-table/tag-duplicates-and-multiples-tags-table.models';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import {
  TagDuplicatesAndMultiplesFilterType
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples-tags-table/tag-duplicates-and-multiples-tags-table.constants';
import {
  formatPaginator,
  PageLoadColumnTooltip,
  TAGS_SEARCH_TEXT_KEY
} from '../../audit-report/audit-report.constants';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import {
  AlertMetricType,
  EAlertDuplicatesAndMultiplesMetric
} from '@app/components/alert/alert-logic/alert-logic.enums';
import { PageStatusCodeTooltipMap } from '@app/components/audit-reports/audit-report-container.constants';
import { StorageService } from '@app/components/shared/services/storage.service';
import { TagsService } from '@app/components/tags/tagsService';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  CommonPagesColumnConfigWarningMessage,
  CommonPagesConfigLocalStorageKey,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-duplicates-and-multiples',
  templateUrl: './tag-duplicates-and-multiples.component.html',
  styleUrls: ['./tag-duplicates-and-multiples.component.scss'],
  providers: [ResizeableTableService]
})
export class TagDuplicatesAndMultiplesComponent extends AuditReportBase implements IFilterableAuditReport, OnInit, OnDestroy, AfterViewInit {
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  readonly PagesTableSettingItems = PagesTableSettingItems;
  readonly stateOptions = EFilterSpinnerState;

  // GENERAL
  auditId: number;
  runId: number;
  apiFilters: IAuditReportApiPostBody = {};
  private filtersUpdated$ = new Subject<void>();
  totalPageCount = 0;
  filteredPageCount = 0;
  PageLoadColumnTooltip = PageLoadColumnTooltip;
  protected readonly PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;

  // WIDGETS
  widgetsState = EFilterSpinnerState.Loading;
  sparklineDataLoaded: boolean = false;

  widgetSparklineRunsInfo: ISparklineRunInfo[] = [];

  widgetPagesScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    metricType: EAlertDuplicatesAndMultiplesMetric.PagesScanned,
  };

  widgetUniqueTags: ISplitCardChartData = {
    topLabel: 'Unique Tags',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openUniqueTagsFullscreenChart.bind(this),
    metricType: EAlertDuplicatesAndMultiplesMetric.UniqueTags,
  };
  widgetUniqueTagsSparklineData: ISparklineChartData[] = [];

  widgetTotalTagRequests: ISplitCardChartData = {
    topLabel: 'Total Tags Requests',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openTotalTagRequestsFullscreenChart.bind(this),
    metricType: EAlertDuplicatesAndMultiplesMetric.TotalTagRequests,
  };
  widgetTotalTagRequestsSparklineData: ISparklineChartData[] = [];

  widgetTotalDuplicates: ISplitCardChartData = {
    topLabel: 'Total Duplicates',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openTotalDuplicatesChart.bind(this),
    metricType: EAlertDuplicatesAndMultiplesMetric.TotalDuplicates,
  };
  widgetTotalDuplicatesSparklineData: ISparklineChartData[] = [];

  widgetTotalMultiples: ISplitCardChartData = {
    topLabel: 'Total Multiples',
    topChangeContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openTotalMultiplesChart.bind(this),
    metricType: EAlertDuplicatesAndMultiplesMetric.TotalMultiples,
  };
  widgetTotalMultiplesSparklineData: ISparklineChartData[] = [];

  // TAGS TABLE
  tagsTableState = EFilterSpinnerState.Loading;
  tagsTableData$: Observable<TagDuplicatesAndMultiplesTags>;

  // PAGES TABLE
  displayedColumns$ = this.tableService.displayedColumns$;

  dataSource = new MatTableDataSource<TagDuplicatesAndMultiplesPagesTableRow>();
  pagesTableState = EFilterSpinnerState.Loading;
  pagesTablePagination: TagDuplicatesAndMultiplesPagesTablePagination = {
    page: 0, // API paginates starting with 0
    size: 200,
    sortBy: CommonReportsPagesTableColumns.TagDuplicates,
    sortDesc: true
  };
  paginationState: { length: number, pageSize: number } = {
    length: 0,
    pageSize: 0
  };
  pageIdOpenInPageDetails: string;
  pagesTableFilter: TagsTableReportFilter;
  private pagesTableFilterUpdated$: Subject<void> = new Subject();

  tagsMap: { [key: string]: string } = {};

  exportReportConfig: TagDuplicatesAndMultiplesExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: TagDuplicatesAndMultiplesExportType,
    totalRows: this.totalPageCount,
    filteredRows: this.filteredPageCount,
    tableState: this.pagesTablePagination,
    filters: this.apiFilters,
    dataToCopy: {
      config: [
        {
          property: 'pageUrl',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl
        },
        {
          property: 'finalPageUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
        },
        {
          property: 'pageLoadTime',
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
        },
        {
          title: 'DUPLICATES',
          property: 'duplicates',
          tableColumnName: CommonReportsPagesTableColumns.TagDuplicates
        },
        {
          title: 'MULTIPLES',
          property: 'multiples',
          tableColumnName: CommonReportsPagesTableColumns.TagMultiples
        }
      ],
      data: null,
      displayedColumns$: this.displayedColumns$
    }
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('scrollToTop', { static: false }) scrollToTop: ElementRef;

  readonly TableColumn = CommonReportsPagesTableColumns;

  constructor(
    private route: ActivatedRoute,
    private auditReportService: AuditReportService,
    private tagDuplicatesAndMultiplesService: TagDuplicatesAndMultiplesService,
    private auditReportLoadingService: AuditReportLoadingService,
    private modalService: OpModalService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private scrollService: AuditReportScrollService,
    private decimalPipe: DecimalPipe,
    private alertReportingService: AlertReportingService,
    private storageService: StorageService,
    private cdr: ChangeDetectorRef,
    private tagsService: TagsService,
    private uiTagService: UiTagService,
    private tableService: ResizeableTableService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params.pipe(
      tap(({ auditId, runId }) => {
        this.auditId = +auditId;
        this.runId = +runId;

        const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
        const alertId = queryParams?.alertId;
        const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
        this.preventHighlight = !highlight;
        // When loading report from an alert, first override filters with alert
        // filters before loading report data
        if (alertId) {
          this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
            this.filterBarService.overrideFilters(alert.config.filtersV0);
            this.highlightMetricType = highlight && alert.config.metricType;

            this.alertCheckComplete$.next(true);
          });
        } else {
          this.alertCheckComplete$.next(true);
        }
      }),
      skip(1), // only fire when calendar control updated
      tap(() => {
        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          this.onFiltersChanged(this.apiFilters);
        });
      })
    ).subscribe();

    // setup for page details
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.Tags);

    this.initFilters();
    this.createTagsMap();

    this.pageDetailsDrawerService.pageDrawerClosed$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handlePageDetailsClosed());
  }

  ngOnDestroy(): void {
    this.auditReportLoadingService.forceOff();
    this.destroy();
    this.onDestroy$.next();

    this.pageDetailsDrawerService.closePageDetails();
  }

  ngAfterViewInit(): void {
    this.sort.sortChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.paginator.firstPage();
        this.pagesTablePagination.page = 0;
      });

    this.dataSource.sort = this.sort;
    this.handlePagesTable();
    this.formatPaginator();
    this.cdr.detectChanges();
  }

  initFilters(): void {
    this.filterBarService.updateSupportedFiltersList(TagDuplicatesAndMultiplesRelevantFilters);

    // ReplaySubject in filterBarService means this executes immediatel
    this.filterBarService.apiPostBody$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody): void {
    if (this.paginator) this.paginator.firstPage();
    this.pagesTablePagination.page = 0;

    this.apiFilters = apiPostBody;
    this.filtersUpdated$.next();
    this.updateCurrentFilters();

    this.handleWidgets();
    this.handleSparklines();
    this.handleTagsTable();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  private formatWidgetContent(filteredCount: number, totalCount?: number): string {
    const format = (x: number) => formatNumber(x, 'en-US');
    if (!this.isFiltered() || (this.isFiltered() && !totalCount)) {
      return format(filteredCount);
    } else {
      return `${format(filteredCount)} of ${format(totalCount)}`;
    }
  }

  private isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.length > 0;
  }

  handleWidgets(): void {
    this.auditReportLoadingService.addLoadingToken();
    this.widgetsState = EFilterSpinnerState.Loading;

    this.tagDuplicatesAndMultiplesService.getTagDuplicatesAndMultiplesSummary(this.auditId, this.runId, this.apiFilters)
      .pipe(
        catchError(() => EMPTY),
        tap(() => {
          this.auditReportLoadingService.removeLoadingToken();
        })
      )
      .subscribe((data: TagDuplicatesAndMultiplesSummary | undefined) => {
        if (data) {
          this.updateWidgets(data);
        } else {
          alert('Sorry! Some elements failed to update. Refresh your browser to try again.');
        }

        this.widgetsState = this.isFiltered() ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
      });
  }

  private updateWidgets(summary: TagDuplicatesAndMultiplesSummary): void {
    this.exportReportConfig.totalRows = this.totalPageCount = summary.totalPageCount;
    this.exportReportConfig.filteredRows = this.filteredPageCount = summary.filteredPageCount;
    this.widgetUniqueTags.topChangeContent = this.formatWidgetContent(summary.filteredUniqueTagCount);
    this.widgetUniqueTags.currentValue = summary.filteredUniqueTagCount || 0;
    this.widgetTotalTagRequests.topChangeContent = this.formatWidgetContent(summary.filteredTagInstanceCount);
    this.widgetTotalTagRequests.currentValue = summary.filteredTagInstanceCount || 0;
    this.widgetTotalDuplicates.topChangeContent = this.formatWidgetContent(summary.filteredTagDuplicateCount);
    this.widgetTotalDuplicates.currentValue = summary.filteredTagDuplicateCount || 0;
    this.widgetTotalMultiples.topChangeContent = this.formatWidgetContent(summary.filteredTagMultipleCount);
    this.widgetTotalMultiples.currentValue = summary.filteredTagMultipleCount || 0;
  }

  handleSparklines(): void {
    this.sparklineDataLoaded = false;
    this.auditReportLoadingService.addLoadingToken();

    this.tagDuplicatesAndMultiplesService.getTagDuplicatesAndMultiplesTrends(this.auditId, this.runId)
      .pipe(
        map((data: TagDuplicatesAndMultiplesTrendRunOverviews) => sortBy(data.runs, element => element.runId)),
        catchError(() => EMPTY),
        tap(() => {
          this.auditReportLoadingService.removeLoadingToken();
        })
      )
      .subscribe((data: TagDuplicatesAndMultiplesTrendRunOverview[] | undefined) => {
        if (data) {
          this.updateSparklines(data);
        }
        this.sparklineDataLoaded = true;
      });
  }

  private updateSparklines(dataPoints: TagDuplicatesAndMultiplesTrendRunOverview[]): void {
    const uniqueTags: ISparklineChartData[] = [];
    const totalTagRequests: ISparklineChartData[] = [];
    const totalDuplicates: ISparklineChartData[] = [];
    const totalMultiples: ISparklineChartData[] = [];

    const runsInfo: ISparklineRunInfo[] = [];

    dataPoints.forEach((dataPoint, index: number) => {
      uniqueTags.push({ value: dataPoint.totalUniqueTagCount, sequence: index });
      totalTagRequests.push({ value: dataPoint.totalTagInstanceCount, sequence: index });
      totalDuplicates.push({ value: dataPoint.totalTagDuplicateCount, sequence: index });
      totalMultiples.push({ value: dataPoint.totalTagMultipleCount, sequence: index });

      runsInfo.push({ runId: dataPoint.runId, runCompletionDate: dataPoint.completedAt });
    });

    this.widgetUniqueTags.bottomHandler = uniqueTags.length < 2 ? null : this.widgetUniqueTags.bottomHandler;
    this.widgetTotalTagRequests.bottomHandler = totalTagRequests.length < 2 ? null : this.widgetTotalTagRequests.bottomHandler;
    this.widgetTotalDuplicates.bottomHandler = totalDuplicates.length < 2 ? null : this.widgetTotalDuplicates.bottomHandler;
    this.widgetTotalMultiples.bottomHandler = totalMultiples.length < 2 ? null : this.widgetTotalMultiples.bottomHandler;

    this.widgetUniqueTagsSparklineData = uniqueTags;
    this.widgetTotalTagRequestsSparklineData = totalTagRequests;
    this.widgetTotalDuplicatesSparklineData = totalDuplicates;
    this.widgetTotalMultiplesSparklineData = totalMultiples;

    this.widgetSparklineRunsInfo = runsInfo;
  }

  openFullscreenChart(
    trendName: TagDuplicatesAndMultiplesTrendName,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName)
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  getFullscreenChartData(trendName: TagDuplicatesAndMultiplesTrendName, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.tagDuplicatesAndMultiplesService
      .getTagDuplicatesAndMultiplesTrend(this.auditId, trendName, days)
      .pipe(
        map((data: TagDuplicatesAndMultiplesTrends) => {
          return {
            chartData: data.runs.map(item => {
              return {
                value: item.trendValue,
                date: item.completedAt,
              };
            })
          };
        })
      );
  }

  getFullscreenChartConfig(trendName: TagDuplicatesAndMultiplesTrendName): IFullscreenChartConfig {
    switch (trendName) {
      case TagDuplicatesAndMultiplesTrendName.UniqueTags:
        return UNIQUE_TAGS_CHART_CONFIG;
      case TagDuplicatesAndMultiplesTrendName.TagRequests:
        return TOTAL_TAG_REQUESTS_CHART_CONFIG;
      case TagDuplicatesAndMultiplesTrendName.TagDuplicates:
        return TOTAL_DUPLICATES_CHART_CONFIG;
      case TagDuplicatesAndMultiplesTrendName.TagMultiples:
        return TOTAL_MULTIPLES_CHART_CONFIG;
      default:
        return null;
    }
  }

  openUniqueTagsFullscreenChart(): void {
    if (this.widgetUniqueTagsSparklineData.length > 1) {
      this.openFullscreenChart(
        TagDuplicatesAndMultiplesTrendName.UniqueTags,
        this.widgetSparklineRunsInfo.length > 1 ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate : undefined
      );
    }
  }

  openTotalTagRequestsFullscreenChart(): void {
    if (this.widgetTotalTagRequestsSparklineData.length > 1) {
      this.openFullscreenChart(
        TagDuplicatesAndMultiplesTrendName.TagRequests,
        this.widgetSparklineRunsInfo.length > 1 ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate : undefined
      );
    }
  }

  openTotalDuplicatesChart(): void {
    if (this.widgetTotalDuplicatesSparklineData.length > 1) {
      this.openFullscreenChart(
        TagDuplicatesAndMultiplesTrendName.TagDuplicates,
        this.widgetSparklineRunsInfo.length > 1 ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate : undefined
      );
    }
  }

  openTotalMultiplesChart(): void {
    if (this.widgetTotalMultiplesSparklineData.length > 1) {
      this.openFullscreenChart(
        TagDuplicatesAndMultiplesTrendName.TagMultiples,
        this.widgetSparklineRunsInfo.length > 1 ? this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate : undefined
      );
    }
  }

  handleTagsTable(): void {
    this.auditReportLoadingService.addLoadingToken();
    this.tagsTableState = EFilterSpinnerState.Loading;
    this.tagsTableData$ = this.tagDuplicatesAndMultiplesService
      .getTagDuplicatesAndMultiplesTags(this.auditId, this.runId, this.apiFilters)
      .pipe(
        tap(() => this.tagsTableState = this.filterBarService.currentRelevantFilters.length ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None),
        finalize(() => this.auditReportLoadingService.removeLoadingToken())
      );
  }

  addGlobalFilter(filter: TagsTableFilter): void {
    switch (filter.type) {
      case 'tag':
        this.filterBarService.addTagIdFilter(filter.tagName, filter.tagId);
        break;
      case 'tag-account':
        this.filterBarService.addTagIdFilter(filter.tagName, filter.tagId);
        this.filterBarService.addTagAccountFilter(filter.tagAccount);
        break;
    }
  }

  addReportFilter(filter: TagsTableReportFilter): void {
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.pagesTablePagination.page = 0;
    this.pagesTableFilter = filter;
    this.pagesTableFilterUpdated$.next();
  }

  openPageDetails(page: TagDuplicatesAndMultiplesPagesTableRow) {
    const state = {
      [TAGS_SEARCH_TEXT_KEY]: this.pagesTableFilter?.value?.tagName || this.tagsMap[this.apiFilters.tagId] || ''
    };

    this.pageIdOpenInPageDetails = page.pageId;
    this.pageDetailsDrawerService.openPageDetails({ id: page.pageId, url: page.pageUrl }, this.auditId, this.runId, EPageDetailsTabs.Tags, null, state);
  }

  handlePageDetailsClosed() {
    this.pageIdOpenInPageDetails = null;
  }

  handlePagesTable(): void {
    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.filtersUpdated$,
      this.pagesTableFilterUpdated$
    ).pipe(
      // we have to manually trigger it for the 1st time because at the moment of subscription all observables have been emitted values
      startWith({}),
      debounceTime(300),
      tap(() => {
        // update loading states each time filters are updated
        this.auditReportLoadingService.addLoadingToken();
        this.pagesTableState = EFilterSpinnerState.Loading;
      }),
      switchMap((filter: any) => {
        if (filter !== undefined) {
          if (typeof filter.pageIndex === 'number') {
            this.pagesTablePagination.page = filter.pageIndex;
          }
          if (filter.active) {
            this.pagesTablePagination.sortBy = filter.active;
            this.pagesTablePagination.sortDesc = filter.direction === 'desc';
          }
        }
        this.exportReportConfig.filters = this.mergeFilters();
        return this.tagDuplicatesAndMultiplesService.getTagDuplicatesAndMultiplesPages(this.auditId, this.runId, this.pagesTablePagination, this.exportReportConfig.filters);
      }),
      catchError(() => {
        this.pagesTableState = EFilterSpinnerState.None;
        return of({ pages: [], metadata: {} });
      }),
      tap(() => {
        this.auditReportLoadingService.removeLoadingToken();
      })
    )
      .subscribe(({pages, metadata: {pagination}}: TagDuplicatesAndMultiplesPages) => {
        this.exportReportConfig.filteredRows = pagination.totalCount;
        this.initTable(pages);
        this.setPaginationData(pagination);

        if (!!this.pagesTableFilter) {
          this.onScrollTop();
        }

        this.pagesTableState = this.filterBarService.currentRelevantFilters.length || this.pagesTableFilter
          ? EFilterSpinnerState.Filtered
          : EFilterSpinnerState.None;
      });
  }

  initTable(auditPages: TagDuplicatesAndMultiplesPage[]): void {
    this.exportReportConfig.dataToCopy.data = this.dataSource.data = auditPages.map((page: TagDuplicatesAndMultiplesPage) => {
      const loadTime = parseFloat((page.pageLoadTime / 1000).toFixed(1));

      return {
        pageId: page.pageId,
        pageUrl: page.pageUrl,
        finalPageUrl: page.finalPageUrl,
        pageLoadTime: loadTime,
        pageLoadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(loadTime),
        pageStatusCode: page.pageStatusCode,
        finalPageStatusCode: page.finalPageStatusCode,
        pageStatusCodeClass: this.auditReportService.getStatusCodeClass(page.finalPageStatusCode),
        duplicates: page.filteredTagDuplicateCount,
        multiples: page.filteredTagMultipleCount,
      } as TagDuplicatesAndMultiplesPagesTableRow;
    });
  }

  private setPaginationData(pagination: TagDuplicatesAndMultiplesPagesPagination) {
    this.paginationState.length = pagination.totalCount;
    this.paginationState.pageSize = pagination.pageSize;
  }

  onScrollTop() {
    this.scrollService.scrollByElement(this.scrollToTop.nativeElement);
  }

  private mergeFilters(): TagDuplicatesAndMultiplesMergedFilters {
    if (!this.pagesTableFilter) {
      return this.apiFilters;
    }

    const reportFilter = this.pagesTableFilter.value;
    const mergedFilters = cloneDeep(this.apiFilters) as TagDuplicatesAndMultiplesMergedFilters;

    switch (reportFilter.type) {
      case TagDuplicatesAndMultiplesFilterType.Tag:
        mergedFilters.tagId = reportFilter.tagId;
        mergedFilters.tagAccount = undefined;
        break;
      case TagDuplicatesAndMultiplesFilterType.TagAccount:
        mergedFilters.tagId = reportFilter.tagId;
        mergedFilters.tagAccount = reportFilter.tagAccount;
        break;
    }

    switch (this.pagesTableFilter.column) {
      case 'duplicates':
        mergedFilters.type = 'tag_duplicates';
        break;
      case 'multiples':
        mergedFilters.type = 'tag_multiples';
        break;
    }

    return mergedFilters;
  }

  private formatPaginator(): void {
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }

  private createTagsMap(): void {
    this.uiTagService.getTags().subscribe((tags: IUiTag[]) => {
      this.tagsMap = tags.reduce((acc, tag) => {
        acc[tag.id] = tag.name;
        return acc;
      }, {});
    });
  }
}
