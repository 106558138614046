import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { CONFIRM_INPUT_TEXT, EUsageOveragesModalType, USAGE_OVERAGES_EXPLANATIONS, USAGE_OVERAGES_QUESTION } from './usage-overages-modal.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-overages-modal',
  templateUrl: './usage-overages-modal.component.html',
  styleUrls: ['./usage-overages-modal.component.scss']
})
export class UsageOveragesModalComponent {

  type: EUsageOveragesModalType;
  confirmText: string = '';

  USAGE_OVERAGES_EXPLANATIONS = USAGE_OVERAGES_EXPLANATIONS;
  USAGE_OVERAGES_QUESTION = USAGE_OVERAGES_QUESTION;

  constructor (
    private dialogRef: MatDialogRef<UsageOveragesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { modalType: EUsageOveragesModalType }
  ) {
    this.type = data.modalType;
  }

  rightFooterButtons: IButton[] = [{
    label: 'Confirm',
    action: () => this.closeModal(true),
    primary: true,
    disabled: true
  }];

  onConfirmTextChange(): void {
    this.rightFooterButtons[0].disabled = this.confirmText.toLowerCase() !== CONFIRM_INPUT_TEXT;
  }

  closeModal(confirmed?: boolean): void {
    this.dialogRef.close(confirmed);
  }
}
