<div class="chart-container" (click)="openReport($event)">
  <div class="chart-header">
    <span>{{boxTitle}}</span>
    <h6>{{boxSubtitle}}</h6>
  </div>
  <div class="chart-content">
    <div class="chart">
      <op-circle-progress 
        [percentage]="percentage"
        [rawValue]="rawValue"
        [strokeRadiusPercentage]="25"
        [showPercentageSign]="rawValue === undefined"
        [bgArcColor]="chartOptions?.bgArcColor"
        [fgArcColor]="chartOptions?.fgArcColor"
        [valueColor]="chartOptions?.valueColor"
      ></op-circle-progress>
    </div>
    <span class="report-link">View details</span>
  </div>
</div>
