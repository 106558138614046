import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

export enum EColorHighlightMeaning {
  NONE = 'none',
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  SORTOFPOSITIVE = 'sortofpositive',
  SORTOFNEGATIVE = 'sortofnegative'
}

@Directive({
  selector: '[colorHighlight]'
})
export class ColorHighlightDirective {
  
  private readonly ColorHighlightClassPrefix: string = 'color-highlight-';
  
  @Input() set colorHighlight(meaning: EColorHighlightMeaning) {
    if (!meaning) {
      meaning = EColorHighlightMeaning.NONE;
    }
    //remove previously added classes first to avoid duplication and color conflicts
    const appliedColorHiglightClasses = this.elementRef.nativeElement.className
      .split(' ')
      .filter(c => c.startsWith(this.ColorHighlightClassPrefix));
    appliedColorHiglightClasses.forEach(c => this.renderer.removeClass(this.elementRef.nativeElement, c));
    //add calculated class
    const classToAdd = this.calculateClassForMeaning(meaning);
    this.renderer.addClass(
      this.elementRef.nativeElement,
      classToAdd
    );
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  /**
   * Classes are defined at {@link src/main/web/styles/global/style.scss}
   */
  private calculateClassForMeaning(meaning: EColorHighlightMeaning): string {
    switch (meaning) {
      case EColorHighlightMeaning.NEGATIVE: return 'color-highlight-negative';
      case EColorHighlightMeaning.SORTOFNEGATIVE: return 'color-highlight-sortofnegative';
      case EColorHighlightMeaning.NEUTRAL: return 'color-highlight-neutral';
      case EColorHighlightMeaning.SORTOFPOSITIVE: return 'color-highlight-sortofpositive';
      case EColorHighlightMeaning.POSITIVE: return 'color-highlight-positive';
      default: return 'color-highlight-none';
    }
  }

}
