<svg [ngStyle]="{width: widthSVG, height: heightSVG}" [class.filtered]="selectedCountry" #map></svg>
<div #tooltip class="tooltip"></div>
<div class="controls">
  <button class="fullscreen" *ngIf="showFullscreen" (click)="fullScreen()">
    <mat-icon>open_in_full</mat-icon>
  </button>
</div>

<div class="controls right" *ngIf="showZoomButtons">
  <button class="zoom-in" (click)="zoomIn()">
    <mat-icon>add</mat-icon>
  </button>
  <button class="zoom-out" (click)="zoomOut()">
    <mat-icon>remove</mat-icon>
  </button>
</div>
