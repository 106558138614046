export enum EFilterBarV2MenuTypes {
  Flyout = 'flyout',
  Button = 'button',
  Search = 'search',
  Checkbox = 'checkbox',
  CustomComponent = 'customComponent',
  Divider = 'divider',
  Note = 'note',
  ClearBtn = 'clearBtn'
}

export enum ETextInputCheckboxFilterType {
  Contains = 'contains',
  Regex = 'regex'
}

export const MenuClosedEvent = 'menuClosedEvent';
export const FilterRemovedEvent = 'filterRemovedEvent';
export const CardCountUpdatedEvent = 'cardCountUpdateEvent';
