import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'notification-channels',
  templateUrl: './notification-channels.component.html',
  styleUrls: ['./notification-channels.component.scss'],
})

export class NotificationChannelsComponent {
  @Output() createProfile = new EventEmitter<void>();
}
