<use-case-report-section>
  <use-case-widget [topText]="pagesScannedWidget.topText"
                   [opSelector]="pagesScannedWidget.opSelector"
                   [topValue]="pagesScannedWidget.topValue"
                   [topTooltip]="pagesScannedWidget.topTooltip"
                   [topClickAction]="pagesScannedWidget.topClickAction"
                   [splitCard]="false">
    <use-case-widget-body right [widget]="pagesScannedWidget"
    ></use-case-widget-body>
  </use-case-widget>
</use-case-report-section>

<use-case-report-section [sectionIcon]="pagesSection.sectionIcon"
                         [sectionTitle]="pagesSection.sectionTitle">
  <use-case-widget [topText]="brokenPagesWidget.topText"
                   [opSelector]="brokenPagesWidget.opSelector"
                   [topValue]="brokenPagesWidget.topValue"
                   [topValueMeaning]="brokenPagesWidget.topValueMeaning"
                   [topIconName]="brokenPagesWidget.topIconName"
                   [topIconMeaning]="brokenPagesWidget.topIconMeaning"
                   [topTooltip]="brokenPagesWidget.topTooltip"
                   [topClickAction]="brokenPagesWidget.topClickAction"
                   [bottomText]="brokenPagesWidget.bottomText"
                   [bottomValue]="brokenPagesWidget.bottomValue">
    <use-case-widget-body right [widget]="brokenPagesWidget">
      <div class="donut-chart-wrapper">
        <donut-chart-with-key [data]="brokenPagesWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'overview-broken-pages-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
  </use-case-widget>

  <use-case-widget [topText]="averagePageLoadTimeWidget.topText"
                   [opSelector]="averagePageLoadTimeWidget.opSelector"
                   [topValue]="averagePageLoadTimeWidget.topValue"
                   [topValueMeaning]="averagePageLoadTimeWidget.topValueMeaning"
                   [topIconName]="averagePageLoadTimeWidget.topIconName"
                   [topIconMeaning]="averagePageLoadTimeWidget.topIconMeaning"
                   [topTooltip]="averagePageLoadTimeWidget.topTooltip"
                   [topClickAction]="averagePageLoadTimeWidget.topClickAction"
                   [bottomText]="averagePageLoadTimeWidget.bottomText"
                   [bottomValue]="averagePageLoadTimeWidget.bottomValue">
    <use-case-widget-body right [widget]="averagePageLoadTimeWidget">
      <div class="horizontal-bar-chart-rounded-wrapper" *ngIf="averagePageLoadTimeWidget.chartData">
        <horizontal-bar-chart-rounded [data]="averagePageLoadTimeWidget.chartData"
                                      [hideTooltip]="true"
                                      [uniqueIdentifier]="'overview-average-page-load-time-chart'"
        ></horizontal-bar-chart-rounded>
      </div>
    </use-case-widget-body>
  </use-case-widget>

  <use-case-widget [topText]="overviewLCPWidget.topText"
                   [opSelector]="overviewLCPWidget.opSelector"
                   [topValue]="overviewLCPWidget.topValue"
                   [topValueMeaning]="overviewLCPWidget.topValueMeaning"
                   [topIconName]="overviewLCPWidget.topIconName"
                   [topIconMeaning]="overviewLCPWidget.topIconMeaning"
                   [topTooltip]="overviewLCPWidget.topTooltip"
                   [topClickAction]="overviewLCPWidget.topClickAction"
                   [bottomText]="overviewLCPWidget.bottomText"
                   [bottomValue]="overviewLCPWidget.bottomValue"
                   [richTooltip]="true">
    <ng-container overlayTooltip>
      <op-web-vitals-widget-tooltip
        [tooltip]="EWebVitalsMetricType.LargestContentfulPaint">
      </op-web-vitals-widget-tooltip>
    </ng-container>
    <use-case-widget-body right [widget]="overviewLCPWidget">
      <div class="web-vitals-chart-wrapper" *ngIf="overviewLCPWidget.chartData">
        <op-web-vitals-chart
          [data]="overviewLCPWidget.chartData"
          [metricType]="overviewLCPWidget.metricType"
          [webVitalsAvailable]="true"
          [loading]="overviewLCPWidget.loading"
          [showTitle]="false"
          [displayValueFractionDigits]="3"
          [displayValueUnits]="''"
          [showLeftThresholdValue]="false"
          [showRightThresholdValue]="false"
          [displayValueFollowsCircle]="true"
          [biggerCircle]="true"
        ></op-web-vitals-chart>
      </div>
    </use-case-widget-body>
  </use-case-widget>
</use-case-report-section>

<use-case-report-section [sectionIcon]="tagsSection.sectionIcon"
                         [sectionTitle]="tagsSection.sectionTitle">
  <use-case-widget [topText]="uniqueTagsWidget.topText"
                   [opSelector]="uniqueTagsWidget.opSelector"
                   [topValue]="uniqueTagsWidget.topValue"
                   [topValueMeaning]="uniqueTagsWidget.topValueMeaning"
                   [topIconName]="uniqueTagsWidget.topIconName"
                   [topIconMeaning]="uniqueTagsWidget.topIconMeaning"
                   [topTooltip]="uniqueTagsWidget.topTooltip"
                   [topClickAction]="uniqueTagsWidget.topClickAction"
                   [statsData]="uniqueTagsWidget.statsData"
                   [bottomLeftCustomContent]="true"
  >
    <use-case-widget-body right [widget]="uniqueTagsWidget">
      <div class="horizontal-stacked-bar-chart-wrapper">
        <horizontal-stacked-bar-chart [data]="uniqueTagsWidget.chartData"
                                      [roundedCorners]="true"
                                      [hideTooltip]="true"
                                      [uniqueIdentifier]="'unique-tags-chart'"
                                      [chartHeight]="20"
        ></horizontal-stacked-bar-chart>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="uniqueTagsWidget.bottomLeftCustomContent">\
    </div>
  </use-case-widget>

  <use-case-widget [topText]="brokenTagRequestsWidget.topText"
                   [opSelector]="brokenTagRequestsWidget.opSelector"
                   [topValue]="brokenTagRequestsWidget.topValue"
                   [topValueMeaning]="brokenTagRequestsWidget.topValueMeaning"
                   [topIconName]="brokenTagRequestsWidget.topIconName"
                   [topIconMeaning]="brokenTagRequestsWidget.topIconMeaning"
                   [topTooltip]="brokenTagRequestsWidget.topTooltip"
                   [topClickAction]="brokenTagRequestsWidget.topClickAction"
                   [bottomText]="brokenTagRequestsWidget.bottomText"
                   [bottomValue]="brokenTagRequestsWidget.bottomValue">
    <use-case-widget-body right [widget]="brokenTagRequestsWidget">
      <div class="horizontal-bar-chart-rounded-wrapper broken-tag-requests" *ngIf="brokenTagRequestsWidget.chartData?.length">
        <horizontal-bar-chart-rounded [data]="brokenTagRequestsWidget.chartData"
                                      [hideTooltip]="true"
                                      [uniqueIdentifier]="'overview-broken-tag-chart'"
        ></horizontal-bar-chart-rounded>
      </div>
    </use-case-widget-body>
  </use-case-widget>

  <use-case-widget [topText]="averageTagLoadTimeWidget.topText"
                   [opSelector]="averageTagLoadTimeWidget.opSelector"
                   [topValue]="averageTagLoadTimeWidget.topValue"
                   [topValueMeaning]="averageTagLoadTimeWidget.topValueMeaning"
                   [topIconName]="averageTagLoadTimeWidget.topIconName"
                   [topIconMeaning]="averageTagLoadTimeWidget.topIconMeaning"
                   [topTooltip]="averageTagLoadTimeWidget.topTooltip"
                   [topClickAction]="averageTagLoadTimeWidget.topClickAction"
                   [bottomText]="averageTagLoadTimeWidget.bottomText"
                   [bottomValue]="averageTagLoadTimeWidget.bottomValue">
    <use-case-widget-body right [widget]="averageTagLoadTimeWidget">
      <div class="horizontal-bar-chart-rounded-wrapper" *ngIf="averageTagLoadTimeWidget.chartData?.length">
        <horizontal-bar-chart-rounded [data]="averageTagLoadTimeWidget.chartData"
                                      [hideTooltip]="true"
                                      [uniqueIdentifier]="'overview-average-tag-load-time-chart'"
        ></horizontal-bar-chart-rounded>
      </div>
    </use-case-widget-body>
  </use-case-widget>

  <use-case-widget [topText]="tagInitiatorWidget.topText"
                   [opSelector]="tagInitiatorWidget.opSelector"
                   [splitCard]="false"
  >
    <div right class="initiators-widget-right-side">
      <div *ngIf="tagInitiatorsData" class="initiators-chart-container">
        <tag-initiators-svg [initiatorsData]="tagInitiatorsData"
                            [showFullScreenButton]="false"
        >
        </tag-initiators-svg>
      </div>
      <div class="gradient-overlay">
        <use-case-widget-body (click)="openInitiatorsFullScreen($event)" [widget]="tagInitiatorWidget"></use-case-widget-body>
      </div>
    </div>
  </use-case-widget>
</use-case-report-section>

<use-case-report-section [sectionIcon]="cookiesSection.sectionIcon"
                         [sectionTitle]="cookiesSection.sectionTitle">
  <use-case-widget [topText]="uniqueCookiesWidget.topText"
                   [opSelector]="uniqueCookiesWidget.opSelector"
                   [topValue]="uniqueCookiesWidget.topValue"
                   [topValueMeaning]="uniqueCookiesWidget.topValueMeaning"
                   [topIconName]="uniqueCookiesWidget.topIconName"
                   [topIconMeaning]="uniqueCookiesWidget.topIconMeaning"
                   [topClickAction]="uniqueCookiesWidget.topClickAction"
                   [statsData]="uniqueCookiesWidget.statsData"
                   [topTooltip]="uniqueCookiesWidget.topTooltip"
                   [bottomLeftCustomContent]="true"
  >
    <use-case-widget-body right [widget]="uniqueCookiesWidget">
      <div class="donut-chart-wrapper">
        <donut-chart-with-key [data]="uniqueCookiesWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'overview-unique-cookies-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="uniqueCookiesWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topText]="nonSecureCookiesWidget.topText"
                   [opSelector]="nonSecureCookiesWidget.opSelector"
                   [topValue]="nonSecureCookiesWidget.topValue"
                   [topValueMeaning]="nonSecureCookiesWidget.topValueMeaning"
                   [topIconName]="nonSecureCookiesWidget.topIconName"
                   [topIconMeaning]="nonSecureCookiesWidget.topIconMeaning"
                   [topTooltip]="nonSecureCookiesWidget.topTooltip"
                    [topClickAction]="nonSecureCookiesWidget.topClickAction"
                   [bottomText]="nonSecureCookiesWidget.bottomText"
                   [bottomValue]="nonSecureCookiesWidget.bottomValue">
    <use-case-widget-body right [widget]="nonSecureCookiesWidget">
      <div class="donut-chart-wrapper">
        <donut-chart-with-key [data]="nonSecureCookiesWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'overview-non-secure-cookies-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
  </use-case-widget>
</use-case-report-section>
