import { Component, EventEmitter, forwardRef, Input, Output, Renderer2, ViewChild, OnInit, OnDestroy, OnChanges } from '@angular/core';
import {
  AbstractControl, FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { WindowRef } from '@app/components/core/services/window.service';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { IGeoLocation, LocationsService } from '@app/components/creator/webJourney/locationsService';
import { IUserAgent, IUserAgentService } from '@app/components/domains/userAgentService.models';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AccountsService } from '@app/components/account/account.service';
import { AuditService } from '@app/components/audit/audit.service';
import { RemoteFileMapService } from '@app/components/creator/services/remote-file-map.service';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import {
  ManageCardsDataService
} from '@app/components/manage/shared/services/manage-cards-data/manage-cards-data.service';
import {
  DEFAULT_AUDIT_LOCATION,
  DEFAULT_AUDIT_OPTIONS,
  DEFAULT_FREQUENCY,
  EAuditFrequency,
  EAuditTab, MAX_FREE_TRIAL_PAGE_SCAN_LIMIT,
  MAX_PAGE_SCAN_LIMIT
} from '@app/components/audit/audit.constants';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { debounceTime, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { IAuditModel } from '@app/components/modals/modalData';
import {
  IAuditFrequency,
  IAuditSetupForm,
  IAuditSetupFormOptionData,
} from '@app/components/audit/audit-setup-form/audit-setup-form.models';
import { isSafari } from '@app/components/utilities/browser.utils';
import * as dateUtils from '@app/components/date/date.service';
import { EDateFormats } from '@app/components/date/date.service';
import * as blackoutPeriodUtils from '@app/components/utilities/blackoutPeriodUtils';
import { IUser } from '@app/moonbeamModels';
import { Features } from '@app/moonbeamConstants';
import {
  IRFMConfigV3,
  IRFMConfigV3CreationRequest
} from '@app/components/creator/shared/remoteFileMapping/remote-file-mapping.component';
import { MatSelectChange } from '@angular/material/select';
import { ComponentChanges } from '@app/models/commons';
import { IAuditFilter } from '@app/components/audit/audit.models';
import {
  CreateEditFolderComponent
} from '@app/components/bulk-operations/manage-folders/create-edit-folder/create-edit-folder.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  EAuditSetupOpSelectors,
  EFieldLabels,
  ELearnMoreOptionsLinks,
  REQUEST_RATES
} from '@app/components/audit/audit-setup-form/audit-setup-form.constants';
import { RfmEditorComponent } from '@app/components/rfm-library/rfm-editor/rfm-editor.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ITableDomain } from '@app/components/bulk-operations/manage-domains/manage-domains.models';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { EDataSourceType } from '@app/components/shared/services/data-sources/data-sources.constants';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { IEasyBlockTags } from '@app/components/domains/discoveryAudits/discoveryAuditModels';
import { IPromise } from 'angular';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DEFAULT_LOCATION } from '@app/components/web-journey/web-journey-setup-form/web-journey-setup-form.constants';
import {
  EFolderMode,
  ESubFolderMode
} from '@app/components/folder-and-sub-folder-modal/folder-and-sub-folder-modal.models';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AuditSetupFormComponent),
  multi: true
};

const FORM_CONTROL_VALIDATION = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => AuditSetupFormComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-setup-form',
  templateUrl: './audit-setup-form.component.html',
  styleUrls: ['./audit-setup-form.component.scss'],
  providers: [FORM_CONTROL_VALUE_ACCESSOR, FORM_CONTROL_VALIDATION]
})
export class AuditSetupFormComponent implements OnInit, OnDestroy, OnChanges {
  EDataSourceType = EDataSourceType;
  EAccountType = EAccountType;
  FIELD_LABELS = EFieldLabels;
  LEARN_MORE = ELearnMoreOptionsLinks;
  REQUEST_RATES = REQUEST_RATES;
  OP_SELECTORS = EAuditSetupOpSelectors;
  EAuditFrequency = EAuditFrequency;
  showAdditionalSetupOptions: boolean = false;
  auditSetupForm: UntypedFormGroup;
  minStartDate: Date;
  auditNameIsLong = false;
  user: IUser;
  tagsToBlock: IEasyBlockTags;
  selectedLabels: ILabel[] = [];
  selectedLocation: IGeoLocation;

  folders: Array<IFolder> = [];
  domains: Array<IDomain> = [];
  folderId: number;
  domainId: number;
  frequencies: IAuditFrequency[] = [];

  locations: IGeoLocation[] = [];
  locationObj: IGeoLocation;
  cachedLocationObj: IGeoLocation;
  filteredLocations: ReplaySubject<IGeoLocation[]> = new ReplaySubject<IGeoLocation[]>(1);

  userAgents: IUserAgent[] = [];
  hasNotVisitedAdvancedTab: boolean = true;
  initialScanLimitReceived: boolean = false;
  startingUrlsCount: number = 0;

  customProxySupport: boolean = true;
  vpnSupport: boolean = false;
  privacyFeatureEnabled: boolean = false;
  auditorSupport: boolean = false;

  remoteFileMappings: IRFMConfigV3[];
  accountType: EAccountType;

  onTouched: () => void;
  onChanged: (model: IAuditSetupForm) => void;

  private destroy$ = new Subject<void>();
  private dataPromise: Promise<IAuditSetupFormOptionData>;
  private fetchedDefaultIncludeUrlDomains: Set<string> = new Set<string>();

  @Input() scanLimit: number;
  @Input() submitted: boolean = false;
  @Input() auditHasRuns: boolean = false;
  @Input() currentTab: EAuditTab;
  @Input() auditId: number;
  @Input() areUrlsLocked: boolean;
  @Input() labels: ILabel[] = [];
  @Input() sameUrlRunId: number = null;

  @Output() onUpdateScanLimit = new EventEmitter<string>();
  @Output() onStartingUrlsChange = new EventEmitter<string>();
  @Output() onFrequencyChanged = new EventEmitter<IAuditFrequency>();
  @Output() onLocationChanged = new EventEmitter<string>();
  @Output() onVPNChanged = new EventEmitter<Boolean>();
  @Output() onRunDateChanged = new EventEmitter<void>();
  @Output() onRunTimeChanged = new EventEmitter<void>();
  @Output() onAddCmpAction = new EventEmitter<void>();
  @Output() filtersAreGenerating = new EventEmitter<boolean>();
  @Output() onShowInvalidUrls = new EventEmitter<void>();

  @ViewChild('locationSelect') locationSelect: MatSelect;

  constructor(private window: WindowRef,
              private formBuilder: UntypedFormBuilder,
              private discoveryAuditService: DiscoveryAuditService,
              private locationsService: LocationsService,
              private userAgentService: IUserAgentService,
              private authenticationService: AuthenticationService,
              private accountsService: AccountsService,
              private auditService: AuditService,
              private rfmService: RemoteFileMapService,
              private foldersService: IFoldersApiService,
              private domainsService: IDomainsService,
              private labelsService: LabelService,
              private modalService: OpModalService,
              private manageCardsData: ManageCardsDataService,
              private applicationChromeService: ApplicationChromeService,
              private renderer: Renderer2,
              private snackbar: MatSnackBar,
  ) {
    this.minStartDate = new Date();

    this.applicationChromeService.accountPreview$
      .pipe(filter(accountPreview => !!accountPreview), takeUntil(this.destroy$))
      .subscribe(({ accountType }) => {
        this.accountType = accountType;

        this.initForm();
        this.initListeners();
        this.initValidation();
      });
  }

  ngOnInit() {
    this.loadData();
  }

  displayHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.addClass(hint, 'show-hint');
  }

  hideHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.removeClass(hint, 'show-hint');
  }

  ngOnChanges(changes: ComponentChanges<AuditSetupFormComponent>): void {
    if (changes.submitted && changes.submitted.currentValue !== changes.submitted.previousValue) {
      this.auditSetupForm.updateValueAndValidity();
    }

    if (changes.currentTab && changes.currentTab.currentValue !== changes.currentTab.previousValue && changes.currentTab.currentValue === EAuditTab.testScenario) {
      this.hasNotVisitedAdvancedTab = false;
    }

    if (!this.auditId && this.hasNotVisitedAdvancedTab && changes.scanLimit && changes.scanLimit.currentValue !== changes.scanLimit.previousValue) {
      this.onUpdateScanLimit.emit(changes.scanLimit.currentValue >= 10000 ? 'monthly' : 'weekly');
      this.initialScanLimitReceived = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private initForm(): void {
    this.locationObj = this.selectedLocation = this.cachedLocationObj = DEFAULT_AUDIT_LOCATION;
    this.auditSetupForm = this.formBuilder.group({
      name: this.formBuilder.control('', [Validators.required, Validators.maxLength(340)]),
      labels: this.formBuilder.control([]),
      recipients: this.formBuilder.control('', [OPValidators.emails]),
      location: this.formBuilder.control(DEFAULT_AUDIT_OPTIONS.location, Validators.required),
      locationFilterCtrl: this.formBuilder.control(''),
      customProxy: false,
      userAgent: this.formBuilder.control(DEFAULT_AUDIT_OPTIONS.userAgent),
      browserWidth: this.formBuilder.control(
        DEFAULT_AUDIT_OPTIONS.browserWidth,
        [Validators.min(250), Validators.max(3840), OPValidators.integer]
      ),
      browserHeight: this.formBuilder.control(
        DEFAULT_AUDIT_OPTIONS.browserHeight,
        [Validators.min(250), Validators.max(4000), OPValidators.integer]
      ),
      rfmConfig: this.formBuilder.control([]),
      easyBlockTags: this.formBuilder.control({tagCategoryIds: [], tagIds: []}),
      clearCookies: this.formBuilder.control(DEFAULT_AUDIT_OPTIONS.clearCookies),
      requestRate: this.formBuilder.control(DEFAULT_AUDIT_OPTIONS.requestRate),
      vpn: this.formBuilder.control(DEFAULT_AUDIT_OPTIONS.vpnEnabled),
      gpc: this.formBuilder.control(DEFAULT_AUDIT_OPTIONS.gpcEnabled),
      blockThirdPartyCookies: this.formBuilder.control(DEFAULT_AUDIT_OPTIONS.blockThirdPartyCookies),
      adobeAuditor: this.formBuilder.control(DEFAULT_AUDIT_OPTIONS.adobeAuditor),
      webHookUrl: this.formBuilder.control('', [OPValidators.url]),
      folderData: this.formBuilder.control({
        folder: null,
        subFolder: null,
        dataLayer: '',
        folderMode: EFolderMode.Select,
        subFolderMode: ESubFolderMode.Select,
      })
    });
  }

  private initListeners(): void {
    this.auditSetupForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.emitChanges());

    this.name.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(250)
      )
      .subscribe((newValue: string) => {
        this.auditNameIsLong = newValue && newValue.length >= 40;
      });

    this.location.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(loc => {
        if (!this.customProxy.value && loc) {
          // Set the selected location, so we know what to display in the input
          // for map/label
          this.selectedLocation = this.locations.find(location => location.name === loc);
          this.setCachedLocation(loc);
        }

        loc === 'mountain' ? this.vpn.enable() : this.vpn.disable();
      });

    // listen for search field value changes
    this.locationFilterCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterLocations();
      });
  }

  protected filterLocations() {
    if (!this.locations) {
      return;
    }
    // get the search keyword
    let search = this.locationFilterCtrl.value;
    if (!search) {
      this.filteredLocations.next(this.locations.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredLocations.next(
      this.sortLocations(this.locations.filter(loc => {
        const match = loc.label.toLowerCase().indexOf(search) > -1;
        const currentlySelected = loc.name.toLowerCase() === this.location.value.toLowerCase();
        return match || currentlySelected;
      }))
    );
  }

  private initValidation() {
    this.auditSetupForm?.controls?.folderData?.setValidators(
      [
        Validators.required
    ]);
  }

  // Filter and sort the locations with US at the top of list
  sortLocations(locations: IGeoLocation[]): IGeoLocation[] {
    return locations.sort((a, b) => {
      // Sort 'us' countryCode first
      if (a.countryCode === 'us' && b.countryCode !== 'us') {
        return -1;
      } else if (a.countryCode !== 'us' && b.countryCode === 'us') {
        return 1;
      } else {
        // Sort alphabetically by label
        return a.label.localeCompare(b.label);
      }
    });
  }

  private loadData(): void {
    this.loadLabels();
    this.loadFrequencies();

    this.dataPromise = this.getFormOptionData().toPromise();
    this.dataPromise.then((data: IAuditSetupFormOptionData) => {
      this.user = data.user;
      this.locations = this.sortLocations(data.locations);
      this.userAgents = data.userAgents;
      this.auditorSupport = data.features.includes(Features.adobeAuditor);
      this.customProxySupport = data.account.customProxySupport;
      this.vpnSupport = data.account.vpnSupport;
      this.vpnSupport ? this.vpn.enable() : this.vpn.disable();
      this.privacyFeatureEnabled = data.features.includes(Features.productLinePrivacy);
      this.privacyFeatureEnabled ? this.gpc.enable() : this.gpc.disable();

      this.folders = data.folders.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
      this.domains = data.domains.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
      this.tagsToBlock = data.easyBlockTags;

      // If creating new audit, set default values
      if (!this.auditId) {
        this.setDefaultCreateAuditValues();
        this.assignLocations();
      }
    }, err => {
      console.error(err);
      alert('There was an error obtaining the data for this page. Please reload and try again');
    });
    this.loadRfms();
  }

  private async assignLocations(): Promise<void> {
    // Set the location country codes to lowercase
    this.locations = this.locations?.map(location => ({
      ...location,
      countryCode: location.countryCode.toLowerCase()
    }));

    this.setLocation();

    // Sort locations alphabetically with US at the top
    this.locations = this.sortLocations(this.locations);
    this.filteredLocations.next(this.locations.slice());
  }

  private setCachedLocation(locationName: string): void {
    if (this.locations?.length > 0) {
      this.cachedLocationObj = this.locationObj = this.locations.find(location => location.name === locationName);
    } else {
      this.cachedLocationObj = this.locationObj;
    }
  }

  private setLocation(): void {
    if (!this.location) {
      this.location.setValue(DEFAULT_AUDIT_OPTIONS.location);
    }
    const curLocationName = this.location.value;

    this.locationObj = this.locations.find(location => location.name === curLocationName);
    this.cachedLocationObj = this.locationObj;
  }

  setDefaultCreateAuditValues() {
    const use24Hour = !isSafari(this.window);
    const nextRunTime = dateUtils.formatDate(this.minStartDate, use24Hour ? EDateFormats.timeTwo : EDateFormats.timeThree);

    let audit = {
      startingDate: this.minStartDate,
      startingTime: nextRunTime,
      recipients: this.user.email,
    };

    this.auditSetupForm.patchValue(audit);
  }

  markAsTouched() {
    Object.values(this.auditSetupForm.controls).forEach(control => {
      control.markAsTouched();
    });
    this.onTouched();
  }

  createNewFileSubstition(): void {
    this.modalService.openModal(RfmEditorComponent, { data: null })
      .afterClosed()
      .subscribe((rfmConfig?: IRFMConfigV3CreationRequest) => {
        if (rfmConfig) {
          this.rfmService.createRfmConfig(rfmConfig).subscribe(
            (config: IRFMConfigV3) => {
              // add newly created file substitution to selection
              this.rfmConfig.setValue([
                ...this.rfmConfig.value,
                config
              ]);

              this.loadRfms();
            },
            error => {
              console.log(error);
              this.showSnackbarError('There was a problem saving the remote file mapping configuration.');
            }
          );
        }
      });
  }

  private showSnackbarError(errorMessage: string): void {
    this.snackbar.open(
      errorMessage,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom' }
    );
  }

  createNewFolder(): void {
    this.modalService
      .openModal(CreateEditFolderComponent, {})
      .afterClosed()
      .subscribe(() => {
        this.loadFoldersAndSubfolders();
      });
  }

  private fillEditForm(audit: IAuditModel): void {

    let location;
    if (this.customProxySupport && audit.options.customProxy) {
      location = audit.options.customProxy;
      this.customProxy.setValue(true);
    } else {
      location = audit.options.location;
    }

    this.assignLocations();

    const blackout = blackoutPeriodUtils.blackoutToUIModelWithOffset(
      {
        start: audit.options.blackoutPeriod?.start,
        end: audit.options.blackoutPeriod?.end
      },
      this.user.timezone,
      !isSafari(this.window),
      true);

    const blackoutControls = blackout ?
      {
        blackoutEnabled: !!blackout.start,
        blackoutStart: blackout.start,
        blackoutEnd: blackout.end
      }
      : {};

    const nextRunTime = dateUtils.formatDate(audit.nextRun, !isSafari(this.window) ? EDateFormats.timeTwo : EDateFormats.timeThree);

    this.folderId = audit.folderId;
    this.domainId = audit.domainId;

    const folder = this.folders.find(f => f.id === audit.folderId);
    const domain = this.domains.find(d => d.id === audit.domainId);

    this.selectedLabels = audit.labels;

    const updatedValue = {
      folderData: {
        folder,
        subFolder: domain,
        dataLayer: domain.dataLayer,
        folderMode: EFolderMode.Select,
        subFolderMode: ESubFolderMode.Select,
      },
      frequency: audit.frequency || DEFAULT_FREQUENCY,
      name: audit.name,
      labels: this.selectedLabels,
      startingUrls: audit.startingUrls ? audit.startingUrls?.join('\n') : '',
      scanStartingUrlsOnly: audit.startingUrls.length === audit.limit,
      limit: audit.limit,
      recipients: audit.recipients.length > 0 ? audit.recipients.join('\n') : '',
      adobeAuditor: audit.options.adobeAuditor,
      browserWidth: audit.options.browserWidth,
      browserHeight: audit.options.browserHeight,
      clearCookies: audit.options.clearCookies,
      includeFilters: audit.filters && audit.filters.include,
      excludeFilters: audit.filters && audit.filters.exclude.length > 0 ? audit.filters.exclude : [''],
      location,
      customProxy: this.customProxy.value,
      requestRate: audit.options.requestRate,
      rfmConfig: audit.options.remoteFileMapConfig,
      consentCategorySelection: audit.options.consentCategories,
      startingDate: audit.nextRun,
      startingTime: nextRunTime,
      templateMode: audit.options.stripQueryString,
      userAgent: audit.options.userAgent,
      webHookUrl: audit.options.webHookUrl,
      vpn: audit.options.vpnEnabled,
      gpc: audit.options.gpcEnabled,
      blockThirdPartyCookies: audit.options.blockThirdPartyCookies,
      ...blackoutControls,
      scanPreviousRunUrlsOnly: audit.options.sameUrlRunId && Number.isInteger(audit.options.sameUrlRunId),
      easyBlockTags: this.tagsToBlock,
    } as IAuditSetupForm;

    this.auditSetupForm.patchValue(updatedValue);
    this.auditSetupForm.updateValueAndValidity();

    this.checkLocationAndVPNSettings(audit.options.location, audit.options.vpnEnabled);
  }

  private emitChanges(): void {
    this.onChanged && this.onChanged(this.auditSetupForm.getRawValue());
  }

  getRfmTooltip(): string {
    return this.rfmConfig?.value?.reduce((acc, rfm, index) => {
      return `${acc}${rfm?.name}${index < this.rfmConfig?.value?.length - 1 ? ', ' : ''}`;
    }, '');
  }

  compareObjectsWithIds(a: any, b: any): boolean {
    if (!a || !b) return;
    return a.id === b.id;
  }

  toggleAdditionalSetupView(): void {
    this.showAdditionalSetupOptions = !this.showAdditionalSetupOptions;
  }

  getStartingUrlDomains(startingUrls: string[] = []): Set<string> {
    const domains = new Set<string>();

    startingUrls.forEach(url => {
      const urlHostname = this.getHostname(url);
      domains.add(urlHostname);
    });

    return domains;
  }

  getHostname(url: string): string {
    try {
      return new URL(url).hostname;
    } catch (e) {
      return url;
    }
  }

  generateIncludeList(startingUrls: string, includeFilters: IAuditFilter[]): void {
    this.filtersAreGenerating.emit(true);
    const startingUrlsToFetch = startingUrls.split('\n').filter((url: string) => {
      const hostName = this.getHostname(url);
      const alreadyFetched = this.fetchedDefaultIncludeUrlDomains.has(hostName);
      this.fetchedDefaultIncludeUrlDomains.add(hostName);
      return !alreadyFetched;
    }).join('\n');

    // Only generate new include filters if we have new domains added
    if (startingUrlsToFetch.length > 0) {
      this.auditService.autoGenerateIncludeList(startingUrlsToFetch, includeFilters).subscribe(filters => {

        // only filter out empty filters if we have at least 1 starting url.
        // this prevents removing the initial include filter if startingUrls is blurred.
        let filteredFilters = filters;

        if (!filteredFilters.length) {
          filteredFilters.push({
            value: '',
            includeLimit: 0
          });
        }

        this.filtersAreGenerating.emit(false);
      });
    } else {
      this.filtersAreGenerating.emit(false);
    }
  }

  onLocationChange(customProxy: boolean): void {
    this.customProxy.setValue(customProxy);

    if (customProxy) {
      this.location.setValue('');
      this.location.setValidators([Validators.required, OPValidators.url]);
    } else {
      if (this.cachedLocationObj) {
        this.location.setValue(this.cachedLocationObj.name);
      } else {
        this.setCachedLocation(DEFAULT_LOCATION);
        this.location.setValue(DEFAULT_LOCATION);
      }

      this.location.setValidators(Validators.required);
    }

    this.location.updateValueAndValidity();
  }

  showInvalidUrls(): void {
    this.onShowInvalidUrls.emit();
  }

  onStartingUrlsBlur() {
    let startingUrls = this.auditSetupForm.get('startingUrls').value;

    // remove blank lines
    startingUrls = this.auditService.removeBlankLines(startingUrls);

    // split into an array
    startingUrls = startingUrls.split('\n');

    // add https: if missing
    startingUrls = startingUrls.map((url: string) => {
      if (!url) return;

      if (!url.match(/:\/\//)) {
        return `https://${url}`;
      }

      return url;
    });

    // dedupe starting urls
    startingUrls = [ ...new Set(startingUrls) ];

    // join back together
    startingUrls = startingUrls.join('\n');

    // send to bottom of stack so it calculates the correct startingUrlsCount after dedupe
    setTimeout(() => {
      this.startingUrlsCount = startingUrls.split('\n').filter((url: string) => url !== '').length;
      if (this.scanStartingUrlsOnly.value) {
        this.limit.setValue(this.startingUrlsCount);
      }

      if (this.startingUrlsCount < 1) {
        this.scanStartingUrlsOnly.disable();
        this.scanStartingUrlsOnly.setValue(false);
      } else {
        this.scanStartingUrlsOnly.enable();
      }

      if (this.scanStartingUrlsOnly.value) {
        this.limit.setValue(this.startingUrlsCount);
      }

      this.auditSetupForm.patchValue({ startingUrls });

      this.onChanged && this.onChanged(this.auditSetupForm.getRawValue());

      if (!this.startingUrls.invalid) {
        this.onStartingUrlsChange.emit(startingUrls);
      }
    });
  }

  frequencyChange(frequency: MatSelectChange) {
    this.onFrequencyChanged.emit(frequency.value as IAuditFrequency);
  }

  locationChange(location: MatSelectChange) {
    this.onLocationChanged.emit(location.value as string);
  }

  vpnChange(vpn: MatSlideToggleChange) {
    this.onVPNChanged.emit(vpn.checked);
  }

  // Updating Location/VPN to be mutually exclusive. However, some legacy data
  // sources may have both a non-Oregon location and VPN enabled. In cases like
  // this, disable the location field and leave the VPN enabled. Otherwise, if
  // the location is Oregon, disable the VPN field.
  private checkLocationAndVPNSettings(location: string, vpnEnabled: boolean): void {
    if (vpnEnabled) {
      this.location.disable();
    } else if (location !== 'mountain') {
      this.vpn.disable();
    }
  }

  onTimeFocus(control: string) {
    const c = this.auditSetupForm.get(control);
    const timeValue = c.value ? dateUtils.formatDate(dateUtils.parseDate(c.value, EDateFormats.timeTwo), EDateFormats.timeTwo) : dateUtils.timeStringToInputValue(new Date(), !isSafari(this.window));

    c.setValue(timeValue);
  }

  private getFormOptionData(): Observable<IAuditSetupFormOptionData> {
    const promises = [];
    const locationPromise = this.locationsService.getAllLocations();
    const userAgentPromise = this.userAgentService.getUserAgents();

    promises.push(locationPromise, userAgentPromise);

    if (this.auditId) {
      promises.push(this.loadEasyBlockTags());
    }

    const allPromise = from(Promise.all(promises));

    return this.authenticationService.getFeaturesWithCache()
      .pipe(
        mergeMap(features =>
          forkJoin(
            allPromise,
            this.authenticationService.getAccountWithCache(),
            this.loadFolders(),
            this.loadDomains(),
            this.loadUser(),
            ([locations, userAgents, easyBlockTags], account, folders, domains, user) => ({
              account, locations, easyBlockTags, features, userAgents, folders, domains, user
              })
            )
        )
      );
  }

  loadRfms(): void {
    this.rfmService.getRfmConfigs().subscribe((configs: IRFMConfigV3[]) => {
      this.remoteFileMappings = configs
        .filter((rfmConfig: IRFMConfigV3) => !!rfmConfig.name)
        .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    });
  }

  private loadFoldersAndSubfolders(subfolder?: ITableDomain) {
    forkJoin([
      this.loadFolders(),
      this.loadDomains()
    ]).subscribe(([folders, domains]) => {
      this.folders = folders;
      this.domains = domains;
    });
  }

  private loadFolders(): Observable<IFolder[]> {
    return this.foldersService.getFoldersObservable(true);
  }

  private loadDomains(): Observable<IDomain[]> {
    return this.domainsService.getDomainsObservable(true);
  }

  private loadFrequencies() {
    this.discoveryAuditService.getAuditFrequencies().then(frequencies => {
      this.frequencies = frequencies.map(f => ({
        name: f.toLowerCase(),
        label: f
      }));
    });
  }

  private loadLabels(): void {
    this.labelsService.getLabels().subscribe(labels => {
      this.labels = labels;
    });
  }

  private loadUser(): Observable<IUser> {
    return this.accountsService.getUser().pipe(
      tap((user: IUser) => this.user = user)
    );
  }

  private loadEasyBlockTags(): IPromise<IEasyBlockTags> {
    return this.discoveryAuditService.getEasyBlockTagIds(this.auditId);
  }

  get frequency(): AbstractControl {
    return this.auditSetupForm.get('frequency');
  }

  private get name(): AbstractControl {
    return this.auditSetupForm.get('name');
  }

  private get recipients(): AbstractControl {
    return this.auditSetupForm.get('recipients');
  }

  get startingDate(): AbstractControl {
    return this.auditSetupForm.get('startingDate');
  }

  get startingTime(): AbstractControl {
    return this.auditSetupForm.get('startingTime');
  }

  get blackoutEnabled(): AbstractControl {
    return this.auditSetupForm.get('blackoutEnabled');
  }

  get blackoutStart(): AbstractControl {
    return this.auditSetupForm.get('blackoutStart');
  }

  get blackoutEnd(): AbstractControl {
    return this.auditSetupForm.get('blackoutEnd');
  }

  get rfmConfig(): UntypedFormArray {
    return this.auditSetupForm.get('rfmConfig') as UntypedFormArray;
  }

  get adobeAuditor(): AbstractControl {
    return this.auditSetupForm.get('adobeAuditor');
  }

  get includeFilters(): AbstractControl {
    return this.auditSetupForm.get('includeFilters');
  }

  get excludeFilters(): AbstractControl {
    return this.auditSetupForm.get('excludeFilters');
  }

  get customProxy(): AbstractControl {
    return this.auditSetupForm.get('customProxy');
  }

  get location(): AbstractControl {
    return this.auditSetupForm.get('location');
  }

  get locationFilterCtrl(): AbstractControl {
    return this.auditSetupForm.get('locationFilterCtrl');
  }

  get vpn(): AbstractControl {
    return this.auditSetupForm.get('vpn');
  }

  get gpc(): UntypedFormControl {
    return this.auditSetupForm.get('gpc') as UntypedFormControl;
  }

  get blockThirdPartyCookies(): UntypedFormControl {
    return this.auditSetupForm.get('blockThirdPartyCookies') as UntypedFormControl;
  }

  get scanPreviousRunUrlsOnly(): AbstractControl {
    return this.auditSetupForm.get('scanPreviousRunUrlsOnly');
  }

  get startingUrls(): AbstractControl {
    return this.auditSetupForm.get('startingUrls');
  }

  get scanStartingUrlsOnly(): AbstractControl {
    return this.auditSetupForm.get('scanStartingUrlsOnly');
  }

  get limit(): AbstractControl {
    return this.auditSetupForm.get('limit');
  }

  get folderData(): AbstractControl {
    return this.auditSetupForm.get('folderData');
  }

  get easyBlockTags(): AbstractControl {
    return this.auditSetupForm.get('easyBlockTags');
  }

  get browserWidth(): AbstractControl {
    return this.auditSetupForm.get('browserWidth');
  }

  get browserHeight(): AbstractControl {
    return this.auditSetupForm.get('browserHeight');
  }

  // CVA implementation
  writeValue(audit: any | IAuditModel | IAuditSetupForm): void {
    // writeValue only passes in an audit when editing or cloning an existing audit
    if (Object.keys(audit).length === 0) return;

    this.dataPromise.then(() => {
      this.fillEditForm(audit);
    });
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Validator implementation
  validate(c: AbstractControl): ValidationErrors {
    return this.auditSetupForm.valid ? null : {auditSetupForm: {valid: false, message: 'Invalid audit setup form'}};
  }

  handleLabelCreated(label: ILabel): void {
    this.selectedLabels = this.selectedLabels.filter((label: ILabel) => !!label.id);
    this.selectedLabels.push(label);
    this.auditSetupForm.controls.labels.patchValue([...this.selectedLabels]);
    this.selectedLabels = [ ...this.selectedLabels ];
  }

  handleLabelSelected(label: ILabel): void {
    this.auditSetupForm.controls.labels.patchValue([...this.selectedLabels]);
    this.selectedLabels = [...this.selectedLabels];
  }

  handleLabelRemoved(label: ILabel): void {
    this.selectedLabels = this.selectedLabels.filter((l: ILabel) => l.id !== label.id);
    this.selectedLabels = [...this.selectedLabels];
    this.auditSetupForm.controls.labels.patchValue([...this.selectedLabels]);
  }

  private userAuditScanLimit(limit: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const scanLimit = limit === 0 ?
        this.accountType === EAccountType.FREETRIAL ?
          MAX_FREE_TRIAL_PAGE_SCAN_LIMIT
          : MAX_PAGE_SCAN_LIMIT
        : this.user.maxPagesPerAudit;

      if (control.value > scanLimit) {
        return { userScanLimitSurpassed: true };
      }

      return null;
    };
  }
}
