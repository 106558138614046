<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="title" (onClose)="closeModal()"></op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <div class="body-container">
      <form class="flex-form" [formGroup]="formGroup" validate-form formName="formGroup" *ngIf="state === ingestorState.CREATE">
          <div class="flex-col form-input">
            <mat-form-field class="flex-stretch">
              <mat-label>Journey Run Name</mat-label>
              <input matInput formControlName="journeyRunName"/>
            </mat-form-field>
            <op-error-message formControlPath="formGroup.journeyRunName"></op-error-message>
          </div>
          <div class="flex-col form-input">
            <mat-form-field class="flex-stretch">
              <mat-label>Complete Webhook URL</mat-label>
              <input matInput formControlName="webhookUrl"/>
            </mat-form-field>
            <op-error-message formControlPath="formGroup.webhookUrl"></op-error-message>
          </div>
          <div class="form-input file-upload">

            <div>
              <p class="validation-error-text" *ngIf="noFilesSelectedValidationError">No files selected for upload</p>
              <div class="unsupported-file-error validation-error-text" *ngFor="let fileWithError of selectedFilesWithErrors">
                <div class="unsupported-file-error-filename-container">
                  <mat-icon>warning</mat-icon><span class="hide-long-text" [title]="fileWithError.fileName">{{ fileWithError.fileName }}</span>
                </div>
                <span>{{ fileWithError.errorMessage }}</span>
              </div>
            </div>
            <op-file-drop #fileDrop acceptedFileTypes="har,zip,gz" (fileDropped)="onFilesDropped($event)">
              <div class="file-drop-content">
                <div *ngFor="let selectedFile of selectedFiles; index as index;" class="dropped-file-info-container">
                  <div class="dropped-file-info">
                    <span class="hide-long-text" [title]="selectedFile.fileName">{{ selectedFile.fileName }}</span> <mat-icon (click)="onDeleteFile(index)">delete_forever</mat-icon>
                  </div>
                </div>
                <div *ngIf="!maxFilesSelected" class="file-upload-options-container">
                  <mat-icon class="file-upload-icon">cloud_upload</mat-icon>
                  <div class="file-upload-options">
                    <button class="select-files-btn" (click)="fileDrop.openFileSelector()">Select files</button> or drag them here
                  </div>
                </div>
              </div>
            </op-file-drop>
            <small>
              Upload .har, .zip, .gz files {{ getFileLimitationsString() }}
            </small>
          </div>
        </form>

        <div class="upload-progress" *ngIf="state === ingestorState.UPLOAD">
          <p>Uploading...</p>
          <br>
          <div *ngFor="let selectedFile of selectedFiles" class="upload-progress-container">
            <span class="hide-long-text" [title]="selectedFile.fileName">{{ selectedFile.fileName }}</span>
            <mat-progress-bar
              mode="determinate"
              [value]="selectedFile.uploadProgress"
              *ngIf="!selectedFile.uploadErrorMessage"></mat-progress-bar>
            <div class="validation-error-text" *ngIf="selectedFile.uploadErrorMessage">
              <mat-icon>warning</mat-icon> {{ selectedFile.uploadErrorMessage }}
              <button (click)="retryUpload(selectedFile)">Retry</button>
            </div>
            <br>
          </div>
        </div>

        <div class="upload-progress" *ngIf="state !== ingestorState.CREATE">
          <div class="upload-finished" *ngIf="state === ingestorState.FINISH">
            <mat-icon>check_circle</mat-icon>
            <div class="message main">All files have been successfully uploaded.</div>
            <div class="message">You will receive an email (to <b>{{user.email}}</b>) and a new journey will appear on the list as soon as we are done processing it.</div>
          </div>

          <div class="upload-error" *ngIf="state === ingestorState.ERROR">
            <mat-icon>error</mat-icon>
            There was a problem processing your files. Please contact support.
          </div>
        </div>
    </div>
  </ng-template>

</op-modal>
