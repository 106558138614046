<ng-select *ngIf="initialized"
           [placeholder]="placeholder"
           [appendTo]="appendTo"
           [appearance]="appearance"
           [items]="data"
           [bindLabel]="bindLabel"
           [bindValue]="bindValue"
           [virtualScroll]="true"
           [appearance]="appearance"
           [(ngModel)]="selectedItem"
           (ngModelChange)="onChange($event)"
           [disabled]="disabled"
           [searchable]="searchable"
           (blur)="onTouched()"
           (change)="onSelectionChanged.next($event)"
           class="{{popoverClass}}"
           [clearable]="clearable">
  <mat-icon matSuffix>domain</mat-icon>
  <ng-template let-item="item">
    {{item.name}}
  </ng-template>
</ng-select>
