import { Route } from '@angular/router';
import { FeedbackPortalComponent } from './feedback-portal.component';
import { FeedbackPortalPaths, FeedbackPortalStateNames } from './feedback-portal.constants';

export const FeedbackPortalFutureState: Route = {
  path: FeedbackPortalPaths.base,
  loadChildren: () => import('./feedback-portal.module').then(mod => mod.FeedbackPortalModule),
};

export const FeedbackPortalRoutes: Route = {
  path: '',
  component: FeedbackPortalComponent,
  data: {
    stateName: FeedbackPortalStateNames.base
  }
};
