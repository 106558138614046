<div #scroller class="rolling-data-table-wrapper" *ngIf="tableData">
  <div *ngFor="let day of formattedData"
       class="day-block"
       [id]="day.id">
    <div class="rolling-date-table-row rolling-date-table-header-row" [class.day-selected]="day.day === selectedDay">
      <div class="left-column">{{day.day}}</div>
      <ng-container *ngIf="day?.completedItems?.length || day?.scheduledItems?.length">
        <div class="center-column">RUNS</div>
        <div class="center-column">SCHEDULE</div>
        <div class="right-column">{{ day.lastColumnHeader }}</div>
      </ng-container>
    </div>

    <div *ngIf="day?.completedItems?.length === 0 && day?.scheduledItems?.length === 0" class="rolling-date-table-row rolling-date-table-no-data-row">
      No {{ openTabType | lowercase }}s on this day
    </div>

    <div class="rolling-date-table-row rolling-date-table-data-row rolling-date-table-completed-item"
         *ngFor="let completedReport of day?.completedItems" (click)="navToRun(completedReport.itemId, completedReport.lastRunId)">
      <div class="left-column">
        {{completedReport.itemName}}
      </div>
      <div class="center-column">{{completedReport.noOfRunsCompleted}}</div>
      <div class="center-column" matTooltip="{{completedReport.description}}" matTooltipPosition="above">
        {{completedReport.frequency}}
      </div>
      <div class="right-column usage-link"
           matTooltip="View {{openTabType}} run"
           matTooltipPosition="above"
           matTooltipShowDelay="500"
      >{{completedReport.lastRunCompletedAt | date: 'MMM d, y | h:mm a'}}</div>
    </div>

    <div class="rolling-date-table-row rolling-date-table-data-row"
         *ngFor="let scheduledReport of day?.scheduledItems">
      <div class="left-column">{{scheduledReport.itemName}}</div>
      <div class="center-column">{{scheduledReport.noOfRunsScheduled}}</div>
      <div class="center-column" matTooltip="{{scheduledReport.description}}" matTooltipPosition="above">
        {{scheduledReport.frequency}}
      </div>
      <div class="right-column scheduled-link">
        {{scheduledReport.nextScheduledRunDateTime | date: 'MMM d, y | h:mm a'}}
      </div>
    </div>
  </div>
</div>
