<div class="selection-count-wrapper" [class.selection-warning]="showSelectedLimit && selectedItems.length > selectedLimit">
  <div class="selection-count" [class.selection-open]="displaySelection">
    <span class="selected-count">{{selectedItems.length | number}}</span>
    <span *ngIf="showSelectedLimit && (selectedItems.length > selectedLimit)">/{{ selectedLimit }} {{selectedItemText | titlecase }}</span>
    &nbsp;Selected
    <mat-icon>expand_more</mat-icon>
  </div>
  <div class="selection-count-warning" *ngIf="showSelectedLimit && selectedItems.length > selectedLimit">
    <mat-icon>warning_amber</mat-icon>
    {{selectedItemText}} limit exceeded
  </div>
</div>
