/* eslint-disable @angular-eslint/no-input-rename */

import { Component, EventEmitter, Output, Input, ChangeDetectionStrategy } from '@angular/core';
import { IFailedRuleReport, INotAppliedRuleReport, IPassedRuleReport, IRuleReportItem } from '../reporting/rules/rule.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rules-report',
  templateUrl: './rules-report.component.html',
  styleUrls: ['./rules-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RulesReportComponent {

  @Output() removeRule: EventEmitter<IRuleReportItem> = new EventEmitter();
  @Output() editRule: EventEmitter<IRuleReportItem> = new EventEmitter();
  @Output() setAsExpected: EventEmitter<IRuleReportItem> = new EventEmitter();
  @Output() deleteVariable: EventEmitter<IRuleReportItem> = new EventEmitter();

  @Input() showEditButtons: boolean = false;
  @Input('failed') failedRuleReports: Array<IFailedRuleReport> = [];
  @Input('notApplied') notAppliedRuleReports: Array<INotAppliedRuleReport> = [];
  @Input('passed') passedRuleReports: Array<IPassedRuleReport> = [];

}
