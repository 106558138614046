export interface IUsageContractTerm {
  sequence: number;
  isCurrent: boolean;
  startDateInclusive: string;
  endDateInclusive: string;
}
export interface IUsageContractTermDTO {
  startDateInclusive: string; //Date in ISO 8601 format, in UTC timezone
  endDateInclusive: string; //Date in ISO 8601 format, in UTC timezone
}

export enum ESupportContactType {
  // - Account Executive
  AE = 'AE',
  // - Customer Success Manager
  CSM = 'CSM',
}

export interface ISupportContact {
  name: string;
  email: string;
  type: ESupportContactType;
  // Calculated properties below
  imageUrl?: string;
  title?: string;
  description?: string;
}

export interface IUsersUsage {
  totalUsers: number;
  usersLoggedInLast30Days: number[];
}

export interface IFullSummaryWebJourneySupportUsage {
  currentlyMonitoredWebJourneys: IFilterableInteger;
  maxMonitoredWebJourneys: number;
  remainingWebJourneySupportRequests: number;
}

export interface IAuditUsage {
  currentContractTerm: IUsageContractTerm;
  contractTerms: IUsageContractTerm[];
  usage: IItemSpecificSummaryBaseUsage;
  concurrentAudits: number;
}

export interface IWebJourneyUsage {
  currentContractTerm: IUsageContractTerm;
  contractTerms: IUsageContractTerm[];
  usage: IItemSpecificSummaryBaseUsage;
  concurrentWebJourneys: number;
  supportUsage: IFullSummaryWebJourneySupportUsage;
}

export interface IFilterableInteger {
  total: number;
  filtered?: number;
}

export interface IMonetaryAmount {
  amount: number;
  // pattern: "^[A-Z]{3}$"
  currency: string;
}

export interface IAccountUsageV2SummaryResponseDTO {
  usersUsage: IUsersUsage;
  contacts: ISupportContact[];
  allowOverages: boolean;
}

export interface IAccountUsageV2ItemSpecificSummaryResponseDTO {
  currentContractTerm: IUsageContractTermDTO;
  contractTerms: IUsageContractTermDTO[];
  usage: IItemSpecificSummaryBaseUsage;
}

export interface IItemSpecificSummaryBaseUsage {
  cumulativeTotal: IFilterableInteger;
  termLimit: number;
  cumulativePacing: number;
  overLimitPrice: IMonetaryAmount;
  monthlyUsage: IMonthlyUsage[];
  //only for journeys
  supportUsage?: {
    currentlyMonitoredWebJourneys: {
      total: number;
      filtered?: number;
    },
    maxMonitoredWebJourneys: number;
    remainingWebJourneySupportRequests: number;
  }
}

export interface IUsageFilterableProjectedUsage {
  scheduledUsageEstimate: IFilterableInteger;
  historicalUsageEstimate: IFilterableInteger;
}

export interface IUsageTrendsBasePeriodTrait {
  startDateInclusive: string;
  endDateExclusive: string;
}

export interface IUsageTrendsPastPeriod extends IUsageTrendsBasePeriodTrait {
  periodTotal: IFilterableInteger;
  cumulativeTotal: IFilterableInteger;
}

export interface IUsageTrendsFuturePeriod extends IUsageTrendsBasePeriodTrait {
  // `periodProjectedTotal` and `cumulativeProjectedTotal` can represent
  // either pages scanned (for audits) or runs completed (for web journeys)
  periodProjectedTotal: IUsageFilterableProjectedUsage;
  cumulativeProjectedTotal: IUsageFilterableProjectedUsage;
}

export type IUsageTrendsCurrentPeriod = IUsageTrendsFuturePeriod & IUsageTrendsPastPeriod;

// Past periods
//     obj.pastPeriods[i].periodTotal; // blueBar
//     obj.pastPeriods[i].cumulativeTotal; // blueArea
// Current Period :: past
//     obj.currentPeriod.periodTotal; // blueBar
//     obj.currentPeriod.cumulativeTotal; // blueArea
// Current Period :: Projection
//     obj.currentPeriod.periodProjectedTotal.scheduledUsageEstimate; // greyBar
//     obj.currentPeriod.cumulativeProjectedTotal.historicalUsageEstimate; // greyArea1
//     obj.currentPeriod.cumulativeProjectedTotal.scheduledUsageEstimate; // greyArea2
// Future periods
//     futurePeriods[i].periodProjectedTotal.scheduledUsageEstimate; // greyBar
//     futurePeriods[i].cumulativeProjectedTotal.historicalUsageEstimate; // greyArea1
//     futurePeriods[i].cumulativeProjectedTotal.scheduledUsageEstimate; // greyArea2
export interface IUsageTrendsDTO {
  // Represents all past months, the last item in this array is current month
  pastPeriods: IUsageTrendsPastPeriod[];
  // Represents future months, with projected values
  futurePeriods: IUsageTrendsFuturePeriod[];
  currentPeriod?: IUsageTrendsCurrentPeriod;
  // Limit of pages scanned (for audits) or runs completed (for web journeys) for the contract term
  termLimit?: number;
  // Shows how many pages should be scanned per month to hit the limit by the end of the contract term
  monthlyPacing?: number;
}

export enum EUsageItemType {
  AUDIT = 'audit',
  WEB_JOURNEY = 'web_journey',
}

export enum EUsageExportType {
  AuditRuns = 'AUDIT_RUNS_EXPORT',
  WebJourneyRuns = 'WEB_JOURNEY_RUNS_EXPORT'
}

export interface IUsageRequestDTO {
  dataSources?: number[]; //array of data source ids
  dataSourceCreators?: number[]; //array of data source creator ids
  dataSourceFolders?: IUsageFolderFilterDTO[];
  dataSourceLabels?: number[];
  runFrequencies?: EUsageFrequency[];
  contractTerm?: IUsageContractTermDTO;
}
export interface IUsageFolderFilterDTO {
  folderId: number;
  domains: number[]
}

export enum EUsageFrequency {
  PAUSED = 'paused',
  ONCE = 'once',
  EVERY_15_MINUTES = '15 minutes',
  HOURLY = '1 hour',
  EVERY_6_HOURS = '6 hours',
  EVERY_12_HOURS = '12 hours',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  SEMIANNUALLY = 'semiannually',
  YEARLY = 'yearly'
}

export interface IMonthlyUsage {
  month: string;
  year: number; // 4-digit year
  pagesScanned?: number;
  actionsExecuted?: number;
  runs: number;
}

export interface IDailyUsageV2 {
  day: string; // 'yyyy-mm-dd'
  completedItems?: IScheduledRunWithItemInfo[];
  scheduledItems?: IRunWithItemInfo[];
  noOfCompletedRuns?: number;
  noOfScheduledRuns?: number;
  noOfPagesScanned?: number;
  noOfPagesScheduledToScan?: number;
}

export interface IRunWithItemInfo {
  frequency: string;
  itemId: number;
  itemName: string;
  lastRunCompletedAt?: string;
  nextScheduledRunDateTime?: string;
  lastRunId?: number;
  noOfRunsCompleted?: number;
  noOfRunsScheduled?: number;
  noOfPagesScheduledToScan: number;
}

export interface IScheduledRunWithItemInfo {
  lastRunId: number;
  lastRunCompletedAt: string;
  noOfRunsCompleted: number;
  frequency: string;
  itemName: string;
  itemId: number;
}

export interface IExportResponse {
  exportId: number;
}
