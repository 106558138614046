<ng-container *ngIf="!showFlyout">
  <!--  If sidebar is not collapsed, only section subitems have a flyout menu  -->
  <ng-container *ngIf="sectionLink && (sectionLink?.subitems?.length || 0) > 0 && !sectionLink.hidden && !isMobile">
    <div class="sidebar-link-item menu-item-has-subitems"
        [ngClass]="customLinkClass"
        [class.selected]="hasActiveChild(sectionLink.subitems)"
        (click)="sidebarLinkEnter(sidebarLinkTrigger)"
        [attr.op-selector]="sectionLink?.opSelector">
      <span class="icon-wrapper" [ngClass]="{ 'closed': sidebarIsClosed, 'open': !sidebarIsClosed }">
        <mat-icon svgIcon="{{getCustomIcon(sectionLink.customIcon, isActive(sectionLink.routePath))}}"
                  [class]="sectionLink?.customIcon.includes('strala_logo') ? 'strala-logo' : ''"
                  *ngIf="sectionLink.customIcon"
                  [matTooltip]="sectionLink.displayText"
                  matTooltipClass="sidebar-item-tooltip"
                  matTooltipPosition="right"
                  [matTooltipDisabled]="!sidebarIsClosed">
        </mat-icon>
        <mat-icon *ngIf="!sectionLink.customIcon"
                  [matTooltip]="sectionLink.displayText"
                  matTooltipPosition="right"
                  matTooltipClass="sidebar-item-tooltip"
                  [matTooltipDisabled]="!sidebarIsClosed">{{ sectionLink.icon }}</mat-icon>
        <span *ngIf="sectionLink.notified" class="notified-menu-item"></span>
        <span class="hidden-mat-menu-trigger"
              #sidebarLinkTrigger="matMenuTrigger"
              [matMenuTriggerFor]="sidebarLinkMenu">
        </span>
      </span>
      <span class="text-wrapper" [ngClass]="{ 'fade-out': sidebarIsClosed, 'fade-in': !sidebarIsClosed }">
        <span class="truncate">{{ sectionLink.displayText }}</span>
        <span *ngIf="sectionLink.label" class="labeled-menu-item" [ngClass]="sectionLink.labelClass">
          <span>{{sectionLink.label}}</span>
        </span>
        <mat-icon *ngIf="sectionLink.subitems">keyboard_arrow_right</mat-icon>
      </span>
      <span *ngIf="sectionLink.label && sidebarIsClosed" class="labeled-menu-item-dot" [ngClass]="sectionLink.labelClass"></span>
    </div>
    <mat-menu #sidebarLinkMenu="matMenu"
              [class]="menuPositioningClass"
              [backdropClass]="sidebarIsClosed ? 'sidebar-menu-backdrop_collapsed' : 'sidebar-menu-backdrop'"
              [hasBackdrop]="false">
      <span (mouseenter)="menuEnter()" (mouseleave)="menuLeave(sidebarLinkTrigger)">
        <ng-container *ngFor="let subMenuItem of sectionLink.subitems; let index = index">
          <mat-divider *ngIf="index > 0"></mat-divider>
          <ng-container *ngFor="let link of subMenuItem | enabledFeatures : accountFeatures">
            <ng-container *ngIf="!link.hidden && hasRequiredPermission(link)">
              <ng-container *ngIf="link.clickAction">
                <div mat-menu-item
                    (click)="menuItemClick(link)"
                    [class.router-link-active]="isActive(link.routePath)"
                    [class.is-section-header]="link.isSectionHeader"
                    [attr.op-selector]="link?.opSelector">
                  <span>{{ link.displayText }}</span>
                  <span class="labeled-menu-item" *ngIf="link.label">
                    <span>{{link.label}}</span>
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="!link.clickAction">
                <a mat-menu-item
                  [routerLink]="link.routePath"
                  href=""
                  [attr.op-selector]="link?.opSelector"
                  [class.router-link-active]="isActive(link.routePath)">
                  <span>{{ link.displayText }}</span>
                  <span class="labeled-menu-item" *ngIf="link.label">
                    <span>{{link.label}}</span>
                  </span>
                </a>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </span>
    </mat-menu>
  </ng-container>

  <!--  If sidebar is not collapsed and no subitems, section items do not have a flyout menu  -->
  <ng-container *ngIf="sectionLink && (sectionLink?.subitems?.length || 0) === 0 && !sectionLink?.hidden && !isMobile">
    <div class="sidebar-link-item"
        [ngClass]="customLinkClass"
        [class.selected]="isActive(sectionLink.routePath)"
        [attr.op-selector]="sectionLink.opSelector">
      <span class="icon-wrapper open">
        <mat-icon svgIcon="{{getCustomIcon(sectionLink.customIcon, isActive(sectionLink.routePath))}}"
                  [class]="sectionLink?.customIcon.includes('strala_logo') ? 'strala-logo' : ''"
                  *ngIf="sectionLink.customIcon"
                  [matTooltip]="sectionLink.displayText"
                  matTooltipPosition="right"
                  matTooltipClass="sidebar-item-tooltip"
                  [matTooltipDisabled]="!sidebarIsClosed">
        </mat-icon>
        <mat-icon *ngIf="!sectionLink.customIcon"
                  [matTooltip]="sectionLink.displayText"
                  matTooltipPosition="right"
                  matTooltipClass="sidebar-item-tooltip"
                  [matTooltipDisabled]="!sidebarIsClosed">{{ sectionLink.icon }}</mat-icon>
        <span *ngIf="sectionLink.notified" class="notified-menu-item"></span>
      </span>
      <span class="text-wrapper fade-in" *ngIf="!sidebarIsClosed">
        <span class="truncate">{{ sectionLink.displayText }}</span>
        <span *ngIf="sectionLink.label" class="labeled-menu-item" [ngClass]="sectionLink.labelClass">
          <span>{{sectionLink.label}}</span>
        </span>
      </span>
    </div>
  </ng-container>



  <!-- ---------------------------------------------- -->
  <!-- ---------------MOBILE MENU-------------------- -->
  <!-- ---------------------------------------------- -->

  <!-- has sub menu -->
  <ng-container *ngIf="sectionLink && (sectionLink?.subitems?.length || 0) > 0 && !sectionLink.hidden && isMobile">
    <div class="sidebar-link-item menu-item-has-subitems"
         [ngClass]="customLinkClass"
         [class.selected]="hasActiveChild(sectionLink.subitems)"
         [attr.op-selector]="sectionLink?.opSelector">
      <span class="icon-wrapper" [ngClass]="{ 'closed': sidebarIsClosed, 'open': !sidebarIsClosed }">
        <mat-icon svgIcon="{{getCustomIcon(sectionLink.customIcon, isActive(sectionLink.routePath))}}"
                  [class]="sectionLink?.customIcon.includes('strala_logo') ? 'strala-logo' : ''"
                  *ngIf="sectionLink.customIcon"
                  [matTooltip]="sectionLink.displayText"
                  matTooltipClass="sidebar-item-tooltip"
                  matTooltipPosition="right"
                  [matTooltipDisabled]="!sidebarIsClosed">
        </mat-icon>
        <mat-icon *ngIf="!sectionLink.customIcon"
                  [matTooltip]="sectionLink.displayText"
                  matTooltipPosition="right"
                  matTooltipClass="sidebar-item-tooltip"
                  [matTooltipDisabled]="!sidebarIsClosed">{{ sectionLink.icon }}</mat-icon>
        <span *ngIf="sectionLink.notified" class="notified-menu-item"></span>
      </span>
      <span class="text-wrapper" [ngClass]="{ 'fade-out': sidebarIsClosed, 'fade-in': !sidebarIsClosed }">
        <span class="truncate">{{ sectionLink.displayText }}</span>
        <span *ngIf="sectionLink.label" class="labeled-menu-item" [ngClass]="sectionLink.labelClass">
          <span>{{sectionLink.label}}</span>
        </span>
        <mat-icon *ngIf="sectionLink.subitems">keyboard_arrow_right</mat-icon>
      </span>
      <span *ngIf="sectionLink.label && sidebarIsClosed" class="labeled-menu-item-dot" [ngClass]="sectionLink.labelClass"></span>
    </div>
  </ng-container>

  <!-- doesn't have sub menu -->
  <ng-container *ngIf="sectionLink && (sectionLink?.subitems?.length || 0) === 0 && !sectionLink?.hidden && isMobile">
    <div class="sidebar-link-item"
        [ngClass]="customLinkClass"
        [class.selected]="isActive(sectionLink.routePath)"
        [attr.op-selector]="sectionLink.opSelector"
        (click)="menuItemClick()">
      <span class="icon-wrapper open">
        <mat-icon svgIcon="{{getCustomIcon(sectionLink.customIcon, isActive(sectionLink.routePath))}}"
                  [class]="sectionLink?.customIcon.includes('strala_logo') ? 'strala-logo' : ''"
                  *ngIf="sectionLink.customIcon"
                  [matTooltip]="sectionLink.displayText"
                  matTooltipPosition="right"
                  matTooltipClass="sidebar-item-tooltip"
                  [matTooltipDisabled]="!sidebarIsClosed">
        </mat-icon>
        <mat-icon *ngIf="!sectionLink.customIcon"
                  [matTooltip]="sectionLink.displayText"
                  matTooltipPosition="right"
                  matTooltipClass="sidebar-item-tooltip"
                  [matTooltipDisabled]="!sidebarIsClosed">{{ sectionLink.icon }}</mat-icon>
        <span *ngIf="sectionLink.notified" class="notified-menu-item"></span>
      </span>
      <span class="text-wrapper fade-in" *ngIf="!sidebarIsClosed">
        <span class="truncate">{{ sectionLink.displayText }}</span>
        <span *ngIf="sectionLink.label" class="labeled-menu-item" [ngClass]="sectionLink.labelClass">
          <span>{{sectionLink.label}}</span>
        </span>
      </span>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="showFlyout">
  <div class="mobile-sub-menu">
    <ng-container *ngFor="let subMenuItem of sectionLink.subitems; let index = index">
      <mat-divider *ngIf="index > 0"></mat-divider>
      <ng-container *ngFor="let link of subMenuItem | enabledFeatures : accountFeatures">
        <ng-container *ngIf="!link.hidden && hasRequiredPermission(link)">
          <ng-container *ngIf="link.clickAction">
            <div mat-menu-item
                (click)="menuItemClick(link)"
                [class.router-link-active]="isActive(link.routePath)"
                [class.is-section-header]="link.isSectionHeader"
                [attr.op-selector]="link?.opSelector">
              {{ link.displayText }}
            </div>
          </ng-container>
          <ng-container *ngIf="!link.clickAction">
            <a mat-menu-item
              (click)="menuItemClick()"
              [routerLink]="link.routePath"
              href=""
              [attr.op-selector]="link?.opSelector"
              [class.router-link-active]="isActive(link.routePath)">
              <span>{{ link.displayText }}</span>
              <span class="labeled-menu-item" *ngIf="link.label">
                <span>{{link.label}}</span>
              </span>
            </a>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
