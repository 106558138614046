import { Injectable, OnDestroy } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { Observable, Subject } from 'rxjs';
import {
  ICookiePrivacyComplianceResponse,
  ICookiePrivacyPagesResponse,
  ICookiePrivacyPagesTableState,
  ICookiePrivacySpecificFilters,
  ICookiePrivacySummary,
  ICookiePrivacyTrends,
  ICookiesPrivacyComplianceTableState,
  ICookiesPrivacyRunOverviews,
} from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.models';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { takeUntil } from 'rxjs/operators';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class PrivacyCookiesService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getCookiesPrivacySummary(auditId: number, runId: number, body?: IAuditReportApiPostBody): Observable<ICookiePrivacySummary> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-privacy`, body);
  }

  getCookiePrivacyTrends(auditId: number, runId: number, filters?: IAuditReportApiPostBody): Observable<ICookiesPrivacyRunOverviews> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-privacy/trends`, filters);
  }

  getCookiePrivacyTrend(auditId: number, trendName: string, days: number): Observable<ICookiePrivacyTrends> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/reports/cookie-privacy/trends/${trendName}?days=${days}`);
  }

  getCookiePrivacyCompliance(
    auditId: number,
    runId: number,
    pagination: ICookiesPrivacyComplianceTableState,
    filters?: IAuditReportApiPostBody
  ): Observable<ICookiePrivacyComplianceResponse> {
    const queryParams = `?page=${pagination.pagination.page}&size=${pagination.pagination.size}&sortBy=${pagination.sort.sortBy}&sortDesc=${pagination.sort.sortDesc}`;
    return this.apiService.post(
      `${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-privacy/compliance${queryParams}`,
      filters
    );
  }

  getCookiePrivacyPages(
    auditId: number,
    runId: number,
    pagination: ICookiePrivacyPagesTableState,
    apiFilters?: IAuditReportApiPostBody
  ): Observable<ICookiePrivacyPagesResponse> {
    const queryParams = `?page=${pagination.pagination.page}&size=${pagination.pagination.size}&sortBy=${pagination.sort.sortBy}&sortDesc=${pagination.sort.sortDesc}`;
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-privacy/pages${queryParams}`, apiFilters);
  }

  getCookiePrivacySpecificPages(
    auditId: number,
    runId: number,
    pagination: ICookiePrivacyPagesTableState,
    apiFilters: ICookiePrivacySpecificFilters
  ): Observable<ICookiePrivacyPagesResponse> {
    const queryParams = `?page=${pagination.pagination.page}&size=${pagination.pagination.size}&sortBy=${pagination.sort.sortBy}&sortDesc=${pagination.sort.sortDesc}`;
    return this.apiService.post(
      `${this.apiRoot}/${auditId}/runs/${runId}/reports/cookie-privacy/cookie-pages${queryParams}`,
      apiFilters
    );
  }

}
