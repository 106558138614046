import { Route } from '@angular/router';
import { OpAdminOnlyGuard } from '../core/permissions.guard';
import { AgGridSampleComponent } from '@app/components/ag-grid-sample/ag-grid-sample.component';

export const AgGridSampleState: Route = {
  path: 'ag-grid',
  canLoad: [ OpAdminOnlyGuard ],
  loadChildren: () => import('./ag-grid-sample.module').then(mod => mod.AgGridSampleModule),
};

export const AgGridSampleRoutes: Route = {
  path: '',
  component: AgGridSampleComponent,
  data: {
    stateName: 'ag_grid_sample',
    title: 'AG Grid Sample',
    betaLabel: false,
  },
};
