import { Component, Input, Output, EventEmitter, forwardRef, ViewChild, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MatFormFieldAppearance } from '@angular/material/form-field';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => OpSelectComponent),
  multi: true
};

/**
 * This component is used for selecting item from items array.
 *
 * @param {Array} data. Items array, that will be displayed.
 * @param {string} bindValue. Object property to use for selected model. By default binds to whole object.
 * @param {string} bindLabel. Object property to use for label. Default name.
 * @param {string} placeholder. Placeholder text.
 * @param {boolean} disabled. Disable flag.
 * @param {string} searchable. Can we filter displayed items by search text. By default true.
*/

@Component({
  selector: 'op-select',
  templateUrl: './op-select.component.html',
  styleUrls: ['./op-select.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class OpSelectComponent implements ControlValueAccessor, OnInit {

  @Input() data: any[] = [];
  @Input() bindValue?: string;
  @Input() bindLabel: string = 'name';
  @Input() placeholder: string = 'Select item';
  @Input() disabled: boolean;
  @Input() searchable: boolean = true;
  @Input() clearable: boolean = true;
  @Input() appendTo?: string;
  @Input() popoverClass?: string;
  @Input() appearance: MatFormFieldAppearance = 'fill';

  @Output() onSelectionChanged: EventEmitter<any> = new EventEmitter();

  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

  selectedItem: any;
  initialized: boolean = false;

  ngOnInit() {
    /*
     *  This is here to ensure that ng-select waits until the "appendTo"
     *  input has been initialized before rendering so that it can
     *  receive the "appendTo" value
     *
    */

    this.initialized = true;
  }

  /**
   * Callback function that should be called when the control's value changes in the UI.
   * Initialized in registerOnChange().
   */
  onChange = (newValue: any) => {
  }

  /**
   * Callback function that should be called when the control was touched.
   * Initialized in registerOnTouched().
   */
  onTouched = () => {
  }

  clear() {
    this.selectedItem = null;
  }

  /**
   * This is called by the forms API on initialization.
   * It receives function that should be called when the control's value changes in the UI.
   */
  registerOnChange(fn: (newValue: any) => void): void {
    this.onChange = fn;
  }

  /**
   * This is called by the forms API on initialization.
   * It receives function that should be called when the control should be considered blurred or "touched".
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * This method will be called by the forms API to write to the view when programmatic (model -> view) changes are requested.
   */
  writeValue(val: any): void {
    if (val || val === false) this.selectedItem = val;
    if (val === '') this.clear();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
