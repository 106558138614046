import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  IPagesDetailsAccessibilityFilter,
  IPagesDetailsAccessibilityFilterBarFilters,
  IPagesDetailsAccessibilitySeverityFilter
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.models';
import { StorageService } from '@app/components/shared/services/storage.service';
import {
  PageDetailsAccessibilityDefaultFilters,
  PageDetailsAccessibilityFiltersStorageKey
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.constants';

@Injectable()
export class PageDetailsAccessibilityService {
  filters: BehaviorSubject<IPagesDetailsAccessibilityFilterBarFilters> = new BehaviorSubject<IPagesDetailsAccessibilityFilterBarFilters>(this.filtersFromStorage || PageDetailsAccessibilityDefaultFilters);

  updateFilters(filter: IPagesDetailsAccessibilityFilter): void {
    this.filters.next({
      ...this.filters.value,
      topFilters: {
        ...this.filters.value?.topFilters,
        [filter.name]: filter,
      },
    });
    this.saveToStorage();
  }

  updateSeverity(severity: IPagesDetailsAccessibilitySeverityFilter): void {
    this.filters.next({
      ...this.filters.value,
      severity: {
        ...this.filters.value?.severity,
        [severity.name]: severity,
      },
    });
    this.saveToStorage();
  }

  saveToStorage(): void {
    this.storage.setValue(PageDetailsAccessibilityFiltersStorageKey, this.filters.value);
  }

  get filtersFromStorage(): IPagesDetailsAccessibilityFilterBarFilters | null {
    return this.storage.getValue(PageDetailsAccessibilityFiltersStorageKey);
  }

  constructor(private storage: StorageService) {
    this.saveToStorage();
  }
}
