<span class="control-container" [formGroup]="control.parent">
  <span class="view-mode"
        [class.read-only]="modalType === EAlertModalType.ReadOnly"
        *ngIf="mode === EAlertNameControlMode.View; else editName"
        (click)="switchToEditMode()">
    <span>{{control.value}}</span>
    <mat-icon *ngIf="modalType !== EAlertModalType.ReadOnly">edit</mat-icon>
  </span>
  <ng-template #editName>
    <mat-form-field appearance="outline" class="styled-mat-input no-hint-container" >
      <input matInput formControlName="name"
             (keydown.enter)="switchToViewMode($event.target.value)"
             (blur)="switchToViewMode($event.target.value)"
             placeholder="Create Alert Name">
      <mat-error *ngIf="control.invalid">
        Alert name is required
      </mat-error>
    </mat-form-field>
  </ng-template>
</span>
