<mat-radio-group (change)="onPreparedDateRangeSelect($event)" [value]="item.value?.type">
  <mat-radio-button color="primary" [value]="EOpFilterBarItemDatepickerRanges.Last30Days">Last 30 Days</mat-radio-button>
  <mat-radio-button color="primary" [value]="EOpFilterBarItemDatepickerRanges.Last60Days">Last 60 Days</mat-radio-button>
  <mat-radio-button color="primary" [value]="EOpFilterBarItemDatepickerRanges.Last90Days">Last 90 Days</mat-radio-button>
  <mat-radio-button color="primary" [value]="EOpFilterBarItemDatepickerRanges.AllAvailableDays">All Available Days</mat-radio-button>
  <mat-divider></mat-divider>
  <mat-radio-button color="primary" [value]="EOpFilterBarItemDatepickerRanges.CustomDateRange">
    <p class="custom-range-label">CUSTOM DATE RANGE</p>
    <p class="custom-range-value">
      <ng-container *ngIf="item.value?.dates?.start && item.value?.dates?.end else allTimeLabel">
        <ng-container *ngIf="(item.value.dates.start | date: dateFormat) === (item.value.dates.end | date: dateFormat) else fullDate">
          {{item.value.dates.start | date: dateFormat}}
        </ng-container>
        <ng-template #fullDate>
          {{item.value.dates.start | date: dateFormat}} - {{item.value.dates.end | date: dateFormat}}
        </ng-template>
      </ng-container>
    </p>
    <ng-template #allTimeLabel>{{defaultDateRange.start | date: dateFormat}} - {{defaultDateRange.end | date: dateFormat}}</ng-template>
  </mat-radio-button>
</mat-radio-group>


<mat-card>
  <mat-calendar (selectedChange)="onSelectedRangeChange($event)"
                [maxDate]="today"
                [selected]="item.value?.dates">
  </mat-calendar>
</mat-card>
