import { UpgradeComponent } from '@angular/upgrade/static';
import { Injector, ElementRef, Directive, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { ITimeSeriesPlot } from './time-series.models';
import { scopeProvider } from '@app/ajs-upgraded.providers';
import { DateService } from '@app/components/date/date.service';

@Directive({
  selector: 'time-series-ng',
  providers: [scopeProvider, DateService]
})
export class TimeSeriesDirective extends UpgradeComponent implements OnInit, OnDestroy {

  @Input() data !: Array<ITimeSeriesPlot>;
  @Input() tipDisabled !: boolean;
  @Input() seriesTitle !: string;
  @Input() focusLine !: number;
  @Input() currentId !: number;
  @Input() user !: any;

  @Output() dataIdSelected !: EventEmitter<{ id: number }>;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('timeSeries', elementRef, injector);
  }
}
