export enum WebJourneyRunStatus {
  Queued = 0,
  Successful = 1,
  Error = 2,
  PageError = 3,
  ActionError = 4,
  RuleError = 5,
  ExecutingActions = 6,
  ProcessingRules = 7
}

export const webJourneyIsFinished = (status: WebJourneyRunStatus): boolean => status > 0 && status < 6;
export const webJourneyVerifyingRules = (status: WebJourneyRunStatus): boolean => status === 7;
export const webJourneyHasStarted = (status: WebJourneyRunStatus): boolean => status > 0;
