import { Injectable } from '@angular/core';
import { Router, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { userIsAdmin, userIsStandard } from '@app/authUtils';
import { DataSourcesUrlBuilders } from '../manage/cards/manage-cards.constants';
import { AccountsService } from '@app/components/account/account.service';


@Injectable({
  providedIn: 'root'
})
export class UsageReportGuard  {

  constructor(
    private router: Router,
    private accountsService: AccountsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.notReadOnlyOrElseGotoMainDash();
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.notReadOnlyOrElseGotoMainDash();
  }

  private notReadOnlyOrElseGotoMainDash(): Observable<boolean> {
    return this.accountsService.getUser()
      .pipe(
        map(user => {
          if (userIsAdmin(user)) return true;
          else if (userIsStandard(user) && user?.fullUsageDashboardAccess === true) return true;
          else {
            this.router.navigateByUrl(DataSourcesUrlBuilders.sources());
            return false;
          }
        })
      );
  }
}
