import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { MatTableDataSource } from '@angular/material/table';
import {
  TagDuplicatesAndMultiplesTagAccount,
  TagDuplicatesAndMultiplesTags
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.models';
import {
  TagDuplicatesAndMultiplesService
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import {
  TagsTableFilter,
  TagsTableReportFilter,
  TagsTableTagAccountRow,
  TagsTableTagRow
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples-tags-table/tag-duplicates-and-multiples-tags-table.models';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  EBarChartDirection,
  EBarChartTextPosition
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.constants';
import {
  TagDuplicatesAndMultiplesFilterType
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples-tags-table/tag-duplicates-and-multiples-tags-table.constants';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import { MatSort } from '@angular/material/sort';
import { ComponentChanges } from '@app/models/commons';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-duplicates-and-multiples-tags-table',
  templateUrl: './tag-duplicates-and-multiples-tags-table.component.html',
  styleUrls: [ './tag-duplicates-and-multiples-tags-table.component.scss' ],
  providers: [ResizeableTableService],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagDuplicatesAndMultiplesTagsTableComponent implements AfterViewInit, OnChanges {

  @Input() set items(data: TagDuplicatesAndMultiplesTags) {
    if (data) {
      this.dataSource.data = this.prepareTags(data);
    }
  }
  @Input() auditId: number;
  @Input() runId: number;
  @Input() tableState: EFilterSpinnerState;
  @Input() apiFilters: IAuditReportApiPostBody;

  @Output() globalFilterCreated = new EventEmitter<TagsTableFilter>();
  @Output() reportFilterCreated = new EventEmitter<TagsTableReportFilter>();

  dataSource = new MatTableDataSource<TagsTableTagRow>();
  selectedTag: TagsTableTagRow;
  selectedTagAccount: TagsTableTagAccountRow;
  selectedColumn: string;
  isFiltered = false;

  columns = [
    'expand',
    'name',
    'numberOfRequests',
    'duplicateCount',
    'tagDuplicatePageCount',
    'empty',
    'multipleCount',
    'tagMultiplePageCount'
  ];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private tagDuplicatesAndMultiplesService: TagDuplicatesAndMultiplesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: ComponentChanges<TagDuplicatesAndMultiplesTagsTableComponent>) {
    if (changes.apiFilters) {
      this.clearReportLevelFilter();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return item.name.toLowerCase();
        case 'duplicateCount':
          return item.tagDuplicateCount;
        case 'multipleCount':
          return item.tagMultipleCount;
        default:
          return item[property];
      }
    };
  }

  expand(tag: TagsTableTagRow): void {
    const accountOfThisTagSelected = tag.expanded && this.selectedTag && this.selectedTag.id === tag.id && this.selectedTagAccount;

    const tagExpandedFirstTime = !tag.expanded && !tag.tagAccounts;
    if (tagExpandedFirstTime) {
      this.getTagAccounts(tag);
    }

    tag.expanded = !tag.expanded;

    if (accountOfThisTagSelected) {
      this.clearReportLevelFilter();
    }
  }

  prepareTags(data: TagDuplicatesAndMultiplesTags): TagsTableTagRow[] {
    return data.tags.map(tag => {
      return {
        id: tag.tagId,
        name: tag.tagName,
        tagIcon: UiTagService.getTagIconUrl(tag.tagId),
        numberOfRequests: tag.filteredTagInstanceCount,
        tagDuplicateCount: tag.filteredTagDuplicateCount,
        tagDuplicateBarChartPayload: {
          chartData: [{
            name: `${tag.tagId}-duplicates`,
            colorClass: `chart-red`,
            filtered: false,
            value: this.getPercentage(tag.filteredTagDuplicateCount, tag.filteredTagInstanceCount),
            displayValue: tag.filteredTagDuplicateCount
          }],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `tag-${tag.tagId}-duplicates-chart`,
        },
        tagDuplicateBarChartHovered: false,
        tagDuplicatePageCount: tag.filteredTagDuplicatePageCount,
        tagMultipleCount: tag.filteredTagMultipleCount,
        tagMultipleBarChartPayload: {
          chartData: [{
            name: `${tag.tagId}-multiples`,
            colorClass: `chart-blue`,
            filtered: false,
            value: this.getPercentage(tag.filteredTagMultipleCount, tag.filteredTagInstanceCount),
            displayValue: tag.filteredTagMultipleCount
          }],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `tag-${tag.tagId}-multiple-chart`,
        },
        tagMultipleBarChartHovered: false,
        tagMultiplePageCount: tag.filteredTagMultiplePageCount,
        barChartConfig: {
          state: EFilterSpinnerState.None,
          displayPills: false,
          calcAsPercentage: true,
          displayPercentSymbol: false,
          textPosition: EBarChartTextPosition.Start
        },
        expanded: false
      };
    });
  }

  prepareAccounts(tagId: number, accounts: TagDuplicatesAndMultiplesTagAccount[]): TagsTableTagAccountRow[] {
    return accounts.map(account => {
      return {
        name: account.tagAccount,
        numberOfRequests: account.filteredTagInstanceCount,
        tagDuplicateCount: account.filteredTagDuplicateCount,
        tagDuplicateBarChartPayload: {
          chartData: [{
            name: `${tagId}-${account.tagAccount}-duplicates`,
            colorClass: `chart-red`,
            filtered: false,
            value: this.getPercentage(account.filteredTagDuplicateCount, account.filteredTagInstanceCount),
            displayValue: account.filteredTagDuplicateCount
          }],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `account-${tagId}-${account.tagAccount}-duplicates-chart`,
        },
        tagDuplicateBarChartHovered: false,
        tagDuplicatePageCount: account.filteredTagDuplicatePageCount,
        tagMultipleCount: account.filteredTagMultipleCount,
        tagMultipleBarChartPayload: {
          chartData: [{
            name: `${tagId}-multiples`,
            colorClass: `chart-blue`,
            filtered: false,
            value: this.getPercentage(account.filteredTagMultipleCount, account.filteredTagInstanceCount),
            displayValue: account.filteredTagMultipleCount
          }],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `account-${tagId}-${account.tagAccount}-multiples-chart`,
        },
        tagMultipleBarChartHovered: false,
        tagMultiplePageCount: account.filteredTagMultiplePageCount,
        barChartConfig: {
          state: EFilterSpinnerState.None,
          displayPills: false,
          calcAsPercentage: true,
          displayPercentSymbol: false,
          textPosition: EBarChartTextPosition.Start
        },
      };
    });
  }

  private getTagAccounts(tag: TagsTableTagRow): void {
    this.tagDuplicatesAndMultiplesService
      .getTagDuplicatesAndMultiplesTagAccounts(this.auditId, this.runId, tag.id, this.apiFilters)
      .subscribe(({ tagAccounts }) => {
        // cut 20 items by design if they are exist. if they are more that 20 we show info wrap
        tag.tagAccounts = this.prepareAccounts(tag.id, tagAccounts.slice(0, 20));
        this.changeDetectorRef.markForCheck();
      });
  }

  trackFn(index, item): string {
    return item.name;
  }

  getPercentage(a: number, b: number): number {
    // ensures fractional percentages don't round to
    // 0% or 100% and instead display as 1% and 99%
    const percentage = (a / b) * 100;

    if (percentage < 100 && percentage > 99) return 99;
    if (percentage < 1 && percentage > 0) return 1;

    return Math.round(percentage);
  }

  filterByTag(tag: TagsTableTagRow): void {
    const filter = {
      type: TagDuplicatesAndMultiplesFilterType.Tag,
      tagId: tag.id,
      tagName: tag.name
    } as TagsTableFilter;
    this.globalFilterCreated.emit(filter);
  }

  filterByTagAndAccount(tag: TagsTableTagRow, account: TagsTableTagAccountRow): void {
    this.filterByTag(tag);
    const filter = {
      type: TagDuplicatesAndMultiplesFilterType.TagAccount,
      tagId: tag.id,
      tagName: tag.name,
      tagAccount: account.name
    } as TagsTableFilter;
    this.globalFilterCreated.emit(filter);
  }

  addReportLevelFilter(
    column: string,
    tag: TagsTableTagRow,
    account?: TagsTableTagAccountRow
  ): void {
    const sameTag = this.selectedTag?.id === tag.id;
    const absentOrSameAccount = (!this.selectedTagAccount && !account) ||
      (this.selectedTagAccount && account && this.selectedTagAccount.name === account.name);
    const sameColumn = this.selectedColumn === column;

    if (sameTag && absentOrSameAccount && sameColumn) {
      this.clearReportLevelFilter();
    } else {
      this.isFiltered = true;

      this.selectedTag = tag;
      this.selectedTagAccount = account;
      this.selectedColumn = column;

      if (account) {
        this.reportFilterCreated.emit({
          column: column,
          value: {
            type: TagDuplicatesAndMultiplesFilterType.TagAccount,
            tagId: tag.id,
            tagName: tag.name,
            tagAccount: account.name
          }
        });
      } else {
        this.reportFilterCreated.emit({
          column: column,
          value: {
            type: TagDuplicatesAndMultiplesFilterType.Tag,
            tagId: tag.id,
            tagName: tag.name
          }
        });
      }
    }
  }

  isTagRowSelected(tag: TagsTableTagRow): boolean {
    return this.selectedTag && this.selectedTag.id === tag.id && !this.selectedTagAccount;
  }

  isTagAccountRowSelected(tag: TagsTableTagRow, account: TagsTableTagAccountRow): boolean {
    return this.selectedTag && this.selectedTagAccount && this.selectedTag.id === tag.id && this.selectedTagAccount.name === account.name;
  }

  isTagColumnSelected(tag: TagsTableTagRow, column: string): boolean {
    return this.isTagRowSelected(tag) && this.selectedColumn === column;
  }

  isTagAccountColumnSelected(tag: TagsTableTagRow, account: TagsTableTagAccountRow, column: string): boolean {
    return this.isTagAccountRowSelected(tag, account) && this.selectedColumn === column;
  }

  mouseEnteredBarChart(column: string, row: TagsTableTagRow | TagsTableTagAccountRow): void {
    switch (column) {
      case 'duplicates':
        row.tagDuplicateBarChartHovered = true;
        break;
      case 'multiples':
        row.tagMultipleBarChartHovered = true;
        break;
    }
  }

  mouseLeftBarChart(column: string, row: TagsTableTagRow | TagsTableTagAccountRow): void {
    switch (column) {
      case 'duplicates':
        row.tagDuplicateBarChartHovered = false;
        break;
      case 'multiples':
        row.tagMultipleBarChartHovered = false;
        break;
    }
  }

  private clearReportLevelFilter(): void {
    this.isFiltered = false;

    this.selectedTag = undefined;
    this.selectedTagAccount = undefined;
    this.selectedColumn = undefined;

    this.reportFilterCreated.emit(undefined);
  }

}
