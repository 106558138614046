import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  EChartDateRange,
  EChartType,
  IFullscreenChartConfig,
  IFullscreenChartDataWithStats,
  IFullscreenChartModalConfig,
  IFullscreenChartStatsItem,
} from './fullscreen-chart-modal.constants';
import {
  generateDateRangeConfig,
  generateDateRangeFromDate,
  getDaysInDateRange
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.utils';
import {
  EPageInfoTrendNamesToDigitsInfo
} from '@app/components/audit-reports/page-information/page-information.constants';

@Component({
  selector: 'op-fullscreen-chart-modal',
  templateUrl: './fullscreen-chart-modal.component.html',
  styleUrls: ['./fullscreen-chart-modal.component.scss']
})
export class FullscreenChartModalComponent {
  areaChartUID: string = 'area-fullscreen';
  rightFooterButtons = [];
  fullscreenChartConfig: IFullscreenChartConfig;
  dateRange: EChartDateRange = null;
  EChartDateRange = EChartDateRange;
  loading: boolean = true;
  chartType: EChartType = EChartType.Area;
  stats: IFullscreenChartStatsItem[] = [];

  EChartType = EChartType;

  greaterThanOneMonthDisabled: boolean = false;
  greaterThanThreeMonthsDisabled: boolean = false;
  greaterThanSixMonthsDisabled: boolean = false;
  greaterThanNineMonthsDisabled: boolean = false;
  greaterThanThirteenMonthsDisabled: boolean = false;

  get digitsInfo(): string {
    return this.fullscreenChartConfig?.digitsInfo ?? '1.0-3';
  }

  get truncate(): boolean {
    return this.fullscreenChartConfig?.truncate ?? false;
  }

  constructor(
    public dialogRef: MatDialogRef<FullscreenChartModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFullscreenChartModalConfig,
  ) {
    const dateRange = generateDateRangeFromDate(this.data.timeframeOriginRunCompletion);
    this.dateRange = dateRange; // set togglebutton on template
    this.updateButtonRangeDisabledStates(dateRange);
    this.getDataForDateRange(dateRange);
    if (data.chartType) {
      this.chartType = data.chartType;
    }
  }

  @HostListener('document:keydown.escape')
  closeModal(): void {
    this.dialogRef.close();
  }

  updateButtonRangeDisabledStates(dateRange: EChartDateRange): void {
    const drc = generateDateRangeConfig(dateRange);
    this.greaterThanOneMonthDisabled = drc?.greaterThanOneMonth;
    this.greaterThanThreeMonthsDisabled = drc?.greaterThanThreeMonths;
    this.greaterThanSixMonthsDisabled = drc?.greaterThanSixMonths;
    this.greaterThanNineMonthsDisabled = drc?.greaterThanNineMonths;
    this.greaterThanThirteenMonthsDisabled = drc?.greaterThanThirteenMonths;
  }

  getDataForDateRange(dateRange: EChartDateRange): void {
    this.loading = true;

    this.data.getData(getDaysInDateRange(dateRange))
      .subscribe((dataForRange: IFullscreenChartDataWithStats) => {
        this.stats = dataForRange.stats;

        // Calculate summary line for each trend type
        const summaryLines = this.data.getSummaryLines
          ? this.data.getSummaryLines(dataForRange.chartData)
          : [];

        this.fullscreenChartConfig = {
          ...this.data.chartConfig,
          data: dataForRange.chartData,
          summaryLines,
          metricType: this.data.metricType,
          digitsInfo: EPageInfoTrendNamesToDigitsInfo[this.data.metricType],
        };

        this.loading = false;
      });
  }
}
