<op-modal [leftFooterButtons]="[]">
  <ng-template #headerTemplate>
    <op-modal-header title="Map" (onClose)="close()"></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div>
      <privacy-request-map
        [focusedCountries]="focusedCountries"
        heightSVG="100vh"
      ></privacy-request-map>
    </div>
  </ng-template>
</op-modal>
