import { Inject, Injectable, Optional } from '@angular/core';
import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { StorageService } from '../../shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { EConsentCategoriesFilterTypes } from '../consent-categories-filter-bar/consent-categories-filter-bar.constants';
import { ETextInputCheckboxFilterType } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import { CCType } from '../consent-categories.constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOpFilterBarFilter } from '../../shared/components/op-filter-bar/op-filter-bar.models';
import { STORAGE_KEY } from '../cc-assign/cc-assign.constants';

@Injectable({
  providedIn: 'root'
})
export class CCDataSourcesFilterBarService extends OpFilterBarService<EConsentCategoriesFilterTypes> {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService,
    @Optional() @Inject(STORAGE_KEY) storageKey: string = null,
  ) {
    super(storage, storageKey, cacheReset, new Map([ [EConsentCategoriesFilterTypes.Label, true] ]));
  }

  get currentFilters$(): Observable<IOpFilterBarFilter<EConsentCategoriesFilterTypes>[]> {
    return this.anyFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters.filter((filter: any) => this.validTypes.includes(filter.type))
      )
    );
  }

  addNameContainsFilter(contains: string, isRegex = false) {
    this.addFilter({
      type: EConsentCategoriesFilterTypes.Name,
      display: `Name ${isRegex ? 'regex' : 'contains'} '${contains}'`,
      value: {
        filterType: isRegex ? ETextInputCheckboxFilterType.Regex : ETextInputCheckboxFilterType.Contains,
        filterValue: contains
      }
    });
  }

  addTypeFilter(type: CCType): void {
    this.addFilter({
      type: EConsentCategoriesFilterTypes.Type,
      display: `Type is ${type}`,
      value: type
    });
  }

  addLabelIdFilter(label: { name: string, id: number }): void {
    this.addFilter({
      type: EConsentCategoriesFilterTypes.Label,
      display: `Label: ${label.name}`,
      value: label.id
    });
  }
}
