<component-template componentName="Horizontal Bar Chart">
  <component-library-section sectionName="Basic Example" selector="<horizontal-bar-chart>">
    <p>This is the horizontal bar chart component.</p>
    <div class="example-container">
      <horizontal-bar-chart
        [data]="example1Data"
        [state]="EFilterSpinnerState.None"
        [uniqueIdentifier]="'cl-horizontal-bar-chart-1'"
      ></horizontal-bar-chart>
    </div>
  </component-library-section>

  <component-library-section sectionName="With Title & Tooltip" selector='<horizontal-bar-chart [title]="exampleTitleObject">'>
    <div class="example-container">
      <horizontal-bar-chart
        [data]="example1Data"
        [state]="EFilterSpinnerState.None"
        [title]="example2Title"
        [uniqueIdentifier]="'cl-horizontal-bar-chart-2'"
      ></horizontal-bar-chart>
    </div>
  </component-library-section>

  <component-library-section sectionName="Without Title or Pills" selector='<horizontal-bar-chart [displayPills]="false">'>
    <div class="example-container">
      <horizontal-bar-chart
        [displayPills]="false"
        [data]="example1Data"
        [state]="EFilterSpinnerState.None"
        [uniqueIdentifier]="'cl-horizontal-bar-chart-3'"
      ></horizontal-bar-chart>
    </div>
  </component-library-section>

  <component-library-section sectionName="Percentage Display" selector='<horizontal-bar-chart [calcAsPercentage]="true" [displayPercentSymbol]="true" [displayActualValue]="false">'>
    <div class="example-container">
      <horizontal-bar-chart
        [calcAsPercentage]="true"
        [displayPercentSymbol]="true"
        [displayActualValue]="false"
        [displayPills]="false"
        [data]="example1Data"
        [state]="EFilterSpinnerState.None"
        [uniqueIdentifier]="'cl-horizontal-bar-chart-4'"
      ></horizontal-bar-chart>
    </div>
  </component-library-section>

  <component-library-section sectionName="Text Position at Start" selector='<horizontal-bar-chart [textPosition]="EBarChartTextPosition.Start">'>
    <div class="example-container">
      <horizontal-bar-chart
        [textPosition]="EBarChartTextPosition.Start"
        [data]="example1Data"
        [state]="EFilterSpinnerState.None"
        [uniqueIdentifier]="'cl-horizontal-bar-chart-5'"
      ></horizontal-bar-chart>
    </div>
  </component-library-section>

  <component-library-section sectionName="Right-to-Left Direction" selector='<horizontal-bar-chart [barDirection]="EBarChartDirection.RTL">'>
    <div class="example-container">
      <horizontal-bar-chart
        [barDirection]="EBarChartDirection.RTL"
        [data]="example1Data"
        [state]="EFilterSpinnerState.None"
        [uniqueIdentifier]="'cl-horizontal-bar-chart-6'"
      ></horizontal-bar-chart>
    </div>
  </component-library-section>

  <component-library-section sectionName="Single Data Point with Large Font" selector='<horizontal-bar-chart [fontSize]="25" [calcAsPercentage]="true">'>
    <div class="example-container example-7">
      <horizontal-bar-chart
        [fontSize]="25"
        [displayPills]="false"
        [calcAsPercentage]="true"
        [data]="example2Data"
        [state]="EFilterSpinnerState.None"
        [uniqueIdentifier]="'cl-horizontal-bar-chart-7'"
      ></horizontal-bar-chart>
    </div>
  </component-library-section>
</component-template>