import { RouteBuilder } from '@app/components/shared/services/route-builder.service';

export const ScriptServicesStateNames = {
  base: 'script_services'
};

export const ScriptServicesPaths = {
  base: 'web-journey-support'
};

export const ScriptServicesUrlBuilders = {
  base: () => RouteBuilder.buildUrl([ScriptServicesPaths.base])
};
