import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * This is a very basic wrapper around the material expansion panel
 * that is used primary to style it appropriately. The full API
 * of the expansion panel is not available but this wrapper
 * component could be extended to support the full API at a later
 * date if necessary.
 */

@Component({
  selector: 'op-expander',
  templateUrl: './op-expander.component.html',
  styleUrls: ['./op-expander.component.scss']
})
export class OpExpanderComponent {

  @Input() isExpanded?: boolean = false;  // Default to collapsed
  @Input() title: string;
  @Output() opened:EventEmitter<void> = new EventEmitter<void>();

  handleOpened() {
    this.isExpanded = true;
    this.opened.emit();
  }
}
