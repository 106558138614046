import { Component, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { INewCookie } from '@app/components/consent-categories/consent-categories.models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-new-cookie',
  templateUrl: './add-new-cookie.component.html',
  styleUrls: ['./add-new-cookie.component.scss']
})
export class AddNewCookieComponent implements OnInit, OnDestroy {

  newCookieForm: UntypedFormGroup;
  rightFooterButtons = [
    {
      label: 'Done',
      action: () => {
        this.save();
      },
      primary: true,
      disabled: false
    }
  ];

  private destroy$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<AddNewCookieComponent>,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  initForm(): void {
    this.newCookieForm = this.formBuilder.group({
      name: [''],
      nameRegex: [false],
      nameMatchAny: [false],
      domain: ['', OPValidators.urlWithOptionalProtocolAndOptionalLeadingPeriod],
      domainRegex: [false]
    });

    this.newCookieForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.rightFooterButtons[0].disabled = !this.newCookieForm.valid;
    });
  }

  onNameMatchRegexChanged(checked: boolean) {
    if (checked) {
      this.name.setValidators(OPValidators.regexSnowflake);
      this.nameMatchAny.patchValue(false);
      this.nameMatchAny.disable();
    } else {
      this.name.clearValidators();
      this.nameMatchAny.enable();
    }

    this.name.updateValueAndValidity();
  }

  onNameMatchAnyChanged(checked: boolean) {
    if (checked) {
      this.name.clearValidators();
      this.name.patchValue('');
      this.name.disable();
      this.nameRegex.patchValue(false);
      this.nameRegex.disable();
    } else {
      this.name.enable();
      this.nameRegex.enable();
    }
    this.name.updateValueAndValidity();
  }

  onDomainMatchRegexChanged(checked: boolean) {
    checked
      ? this.domain.setValidators([OPValidators.regexSnowflake])
      : this.domain.setValidators([OPValidators.urlWithOptionalProtocolAndOptionalLeadingPeriod]);

    this.domain.updateValueAndValidity();
  }

  save(): void {
    this.newCookieForm.markAllAsTouched();
    if (this.newCookieForm.invalid) return;

    const domain = this.domain.value.indexOf('http') > -1
      ? this.domain.value.split('/')[2]
      : this.domain.value.split('/')[0];

    this.closeModal({
      name: this.name.value,
      nameRegex: this.nameRegex.value,
      nameMatchAny: this.nameMatchAny.value,
      domain: domain,
      domainRegex: this.domainRegex.value
    });
  }

  closeModal(cookie?: INewCookie): void {
    this.dialogRef.close(cookie);
  }

  get name(): AbstractControl {
    return this.newCookieForm.get('name');
  }

  get nameRegex(): AbstractControl {
    return this.newCookieForm.get('nameRegex');
  }

  get nameMatchAny(): AbstractControl {
    return this.newCookieForm.get('nameMatchAny');
  }

  get domain(): AbstractControl {
    return this.newCookieForm.get('domain');
  }

  get domainRegex(): AbstractControl {
    return this.newCookieForm.get('domainRegex');
  }
}
