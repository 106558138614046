import {
  IResizeableColumnProps
} from '@app/components/shared/directives/resizeable-table-column/resizeable-columns.model';
import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export interface IAuditReportParams {
  auditId: string;
  runId: string;
  reportName?: string;
}

export interface IAuditReportBaseFilter {
  RunID: string;
  AuditID: string;
}

export interface IAuditReportBase {
  dashboardId: string;
  filter: IAuditReportBaseFilter;
}

// ---- EXPORT MODELS

export interface IAuditTagReportBase {
  id: number;
  name: string;
  tagCategory: {
    id: number;
    category: string;
  };
}

export interface IAuditAccountTag extends IAuditTagReportBase {
  accounts: IAuditAccountTagAccount[];
}

export interface IAuditVersionTag extends IAuditTagReportBase {
  versions: IAuditVersionTagVersion[];
}

export interface IAuditStatusCodeTag extends IAuditTagReportBase {
  statuses: IAuditStatusCodeTagStatus[];
}

export interface IAuditAccountTagAccount {
  name: string;
}

export interface IAuditVersionTagVersion {
  name: string;
}

export interface IAuditStatusCodeTagStatus {
  code: number;
  pagesTotal: number;
}

export interface IAuditConsoleLogsLevel {
  level: string;
  pageCount: number;
}

export interface IAuditStatusCode {
  code: string;
  pageCount: number;
}

export interface IAuditPageLoadTime {
  interval: {
    start: number;
    startInclusive: boolean;
    end: number;
    endInclusive: boolean;
  }
}

export interface IAuditExportPayload {
  identifiers: string[];
}

export interface IAuditTagLoadTimeExportPayload {
  intervals: IAuditTagLoadTimeExportPayloadInterval[];
  tagIds: number[];
}

export interface IAuditTagLoadTimeExportPayloadInterval {
  start: number;
  startInclusive: boolean;
  endInclusive: boolean;
  unit: string;
}

export interface IAuditPageStatusCodesPayload {
  identifiers: string[];
}

export interface IAuditAccountTagReport {
  producedSelection: any;
  request: any;
  runTags: IAccountTagReportTag[];
}

export interface IAccountTagReportTag {
  runId: number;
  tags: IAuditAccountTag[];
}

export interface IAuditVersionTagReport {
  producedSelection: any;
  request: any;
  runTags: IVersionTagReportTag[];
}

export interface IVersionTagReportTag {
  runId: number;
  tags: IAuditVersionTag[];
}

export interface IAuditTagLoadTimeReport {
  id: number;
  averageLoadtime: number;
  intervals: {
    amountOfPages: number;
    interval: IAuditTagLoadTimeReportInterval[];
  }
}

export interface IAuditTagLoadTimeReportInterval {
  start: number;
  startInclusive: boolean;
  end: number;
  endInclusive: boolean;
  unit: string;
}

export interface IAuditTagStatusCodesReport {
  producedSelection: any;
  request: any;
  runTags: IAuditTagStatusCodesReportItem[];
  totalPages: number;
}

export interface IAuditTagStatusCodesReportItem {
  runId: number;
  tags: IAuditTagStatusCodesReportTag[];
}

export interface IAuditTagStatusCodesReportTag {
  id: number;
  name: string;
  runId: number;
  statuses: IAuditStatusCodeTagStatus[];
}

export interface IPagesTableLocalStorageConfig {
  items: ISwitchableMenuItems,
  sizes: IResizeableColumnProps[],
}

export enum EOrientation {
  horizontal,
  vertical,
}
