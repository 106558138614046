import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  DEFAULT_AUDIT_OPTIONS,
  DEFAULT_FREQUENCY,
  DEFAULT_PAGE_LIMIT,
  EAuditFrequency,
  EAuditTabUrlSources,
  MAX_FREE_TRIAL_PAGE_SCAN_LIMIT,
  MAX_PAGE_SCAN_LIMIT,
  TAB_URL_SOURCES_TO_LABEL_MAP
} from '@app/components/audit/audit.constants';
import { ILabel } from '@app/components/shared/services/label.service';
import {
  IAuditFrequency,
  IAuditSetupForm,
  IAuditSetupFormOptionData
} from '@app/components/audit/audit-setup-form/audit-setup-form.models';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { IAuditModel } from '@app/components/modals/modalData';
import { forkJoin, from, Observable, ReplaySubject, Subject } from 'rxjs';
import * as dateUtils from '@app/components/date/date.service';
import { EDateFormats } from '@app/components/date/date.service';
import { isSafari } from '@app/components/utilities/browser.utils';
import * as blackoutPeriodUtils from '@app/components/utilities/blackoutPeriodUtils';
import { WindowRef } from '@app/components/core/services/window.service';
import {
  EAuditSetupOpSelectors,
  EFieldLabels,
  ELearnMoreOptionsLinks
} from '@app/components/audit/audit-setup-form/audit-setup-form.constants';
import { IUser } from '@app/moonbeamModels';
import { IEasyBlockTags } from '@app/components/domains/discoveryAudits/discoveryAuditModels';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { IGeoLocation, LocationsService } from '@app/components/creator/webJourney/locationsService';
import { IUserAgent, IUserAgentService } from '@app/components/domains/userAgentService.models';
import { IRFMConfigV3 } from '@app/components/creator/shared/remoteFileMapping/remote-file-mapping.component';
import { FilterListComponent } from '@app/components/audit/audit-editor/filter-list/filter-list.component';
import { MatSelectChange } from '@angular/material/select';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AccountsService } from '@app/components/account/account.service';
import { AuditService } from '@app/components/audit/audit.service';
import { RemoteFileMapService } from '@app/components/creator/services/remote-file-map.service';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, finalize, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentChanges } from '@app/models/commons';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { IAuditFilter } from '@app/components/audit/audit.models';
import { ITableDomain } from '@app/components/bulk-operations/manage-domains/manage-domains.models';
import { IPromise } from 'angular';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { EDataSourceType } from '@app/components/shared/services/data-sources/data-sources.constants';
import { EmailInboxesService } from '@app/components/email-inboxes/email-inboxes.service';
import {
  EEmailInboxesSortColumns,
  IEmailInboxesQueryParams,
  IEmailInboxesSort,
  IEmailInboxTableData
} from '@app/components/email-inboxes/email-inboxes.models';
import { IPaginationMetaData } from '@app/components/consent-categories/consent-categories.models';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import {
  IEmailInboxesFilter
} from '@app/components/email-inboxes/email-inboxes-filter-bar/email-inboxes-filter-bar.models';
import { StorageService } from "@app/components/shared/services/storage.service";
import { IOpRecurrenceResponse, IOpRecurrenceScheduleResponse } from '@app/components/shared/components/op-recurrence/op-recurrence.models';
import { RecurrenceService } from '@app/components/shared/components/op-recurrence/op-recurrence.service';
import { ERecurrenceItemType } from '@app/components/shared/components/op-recurrence/op-recurrence.constants';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UrlSourcesComponent),
  multi: true
};

const FORM_CONTROL_VALIDATION = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => UrlSourcesComponent),
  multi: true
};

@Component({
  selector: 'op-url-sources',
  templateUrl: './url-sources.component.html',
  styleUrls: ['./url-sources.component.scss'],
  providers: [FORM_CONTROL_VALUE_ACCESSOR, FORM_CONTROL_VALIDATION]
})
export class UrlSourcesComponent implements OnInit, OnDestroy, OnChanges {
  readonly tabsLib = EAuditTabUrlSources;

  tabs = [];

  EDataSourceType = EDataSourceType;
  EAccountType = EAccountType;
  FIELD_LABELS = EFieldLabels;
  LEARN_MORE = ELearnMoreOptionsLinks;
  OP_SELECTORS = EAuditSetupOpSelectors;
  EAuditFrequency = EAuditFrequency;
  showAdditionalSetupOptions: boolean = true;
  urlSourcesForm: UntypedFormGroup;

  minStartDate: Date;
  user: IUser = null;
  tagsToBlock: IEasyBlockTags;
  selectedLabels: ILabel[] = [];

  folders: Array<IFolder> = [];
  domains: Array<IDomain> = [];
  frequencies: IAuditFrequency[] = [];

  locations: IGeoLocation[] = [];
  locationObj: IGeoLocation;
  cachedLocationObj: IGeoLocation;
  filteredLocations: ReplaySubject<IGeoLocation[]> = new ReplaySubject<IGeoLocation[]>(1);

  userAgents: IUserAgent[] = [];
  userLimit: number = 0;
  hasNotVisitedAdvancedTab: boolean = true;
  initialScanLimitReceived: boolean = false;
  startingUrlsCount: number = 0;

  customProxySupport: boolean = true;
  vpnSupport: boolean = false;
  privacyFeatureEnabled: boolean = false;
  auditorSupport: boolean = false;

  remoteFileMappings: IRFMConfigV3[];
  accountType: EAccountType;

  isEditMode: boolean = false;

  recurrenceEnabled: boolean = false;
  recurrenceSchedule: IOpRecurrenceResponse;

  readonly ERecurrenceItemType = ERecurrenceItemType;

  onTouched: () => void;
  onChanged: (model: any) => void;

  private destroy$ = new Subject<void>();
  private dataPromise: Promise<IAuditSetupFormOptionData>;
  private fetchedDefaultIncludeUrlDomains: Set<string> = new Set<string>();
  private cachedScanStartingUrlsOnlyValue: boolean;

  @Input() loading: boolean = false;
  @Input() lockUrlsPrevRunDate: string;
  @Input() runDateFormat: string = '';
  @Input() currentTab: EAuditTabUrlSources = this.tabsLib.scheduledScan;
  @Input() scanLimit: number;
  @Input() submitted: boolean = false;
  @Input() auditHasRuns: boolean = false;
  @Input() auditId: number;
  @Input() areUrlsLocked: boolean;
  @Input() sameUrlRunId: number = null;

  @Output() onUpdateScanLimit = new EventEmitter<string>();
  @Output() onStartingUrlsChange = new EventEmitter<string>();
  @Output() onFrequencyChanged = new EventEmitter<IAuditFrequency>();
  @Output() onLocationChanged = new EventEmitter<string>();
  @Output() onVPNChanged = new EventEmitter<Boolean>();
  @Output() onRunDateChanged = new EventEmitter<void>();
  @Output() onRunTimeChanged = new EventEmitter<void>();
  @Output() filtersAreGenerating = new EventEmitter<boolean>();
  @Output() onShowInvalidUrls = new EventEmitter<void>();

  @ViewChild('includeFilters') includeFiltersComponent: FilterListComponent;
  @ViewChild('excludeFilters') excludeFiltersComponent: FilterListComponent;

  constructor(private window: WindowRef,
    private formBuilder: UntypedFormBuilder,
    private discoveryAuditService: DiscoveryAuditService,
    private locationsService: LocationsService,
    private userAgentService: IUserAgentService,
    private authenticationService: AuthenticationService,
    private accountsService: AccountsService,
    private auditService: AuditService,
    private rfmService: RemoteFileMapService,
    private foldersService: IFoldersApiService,
    private domainsService: IDomainsService,
    private applicationChromeService: ApplicationChromeService,
    private renderer: Renderer2,
    private snackbar: MatSnackBar,
    private emailInboxesService: EmailInboxesService,
    private snackbarService: SnackbarService,
    private storageService: StorageService,
    private dateService: dateUtils.DateService,
    private recurrenceService: RecurrenceService
  ) {
    // TODO: Remove once recurrence component goes live
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
    this.minStartDate = new Date();
    this.generateTabs();
  }

  ngOnInit(): void {
    this.applicationChromeService.accountPreview$
      .pipe(filter(accountPreview => !!accountPreview), takeUntil(this.destroy$))
      .subscribe(({ accountType }) => {
        this.accountType = accountType;

        this.initForm();
        this.initListeners();
      });

    this.loadData();
    if (this.auditId) {
      this.loadEmailInboxes();
    }
  }

  displayHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.addClass(hint, 'show-hint');
  }

  hideHint(el: HTMLElement, customSelector: string = '.field-hint') {
    let hint = el.querySelector(customSelector);
    if (hint) this.renderer.removeClass(hint, 'show-hint');
  }

  ngOnChanges(changes: ComponentChanges<UrlSourcesComponent>): void {
    this.isEditMode = !!this.auditId;

    if (changes.submitted && changes.submitted.currentValue !== changes.submitted.previousValue) {
      this.urlSourcesForm?.updateValueAndValidity();
    }

    if (changes.auditHasRuns && changes.auditHasRuns.currentValue) {
      this.scanPreviousRunUrlsOnly.enable();
    }

    if (changes.currentTab && changes.currentTab.currentValue !== changes.currentTab.previousValue) {
      this.hasNotVisitedAdvancedTab = false;
    }

    if (!this.auditId && this.hasNotVisitedAdvancedTab && changes.scanLimit && changes.scanLimit.currentValue !== changes.scanLimit.previousValue) {
      this.onUpdateScanLimit.emit(changes.scanLimit.currentValue >= 10000 ? 'monthly' : 'weekly');
      this.initialScanLimitReceived = true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  get urlSourcesValue(): IAuditSetupForm {
    return this.urlSourcesForm.value;
  }

  private initForm(): void {
    this.urlSourcesForm = this.formBuilder.group({
      frequency: this.formBuilder.control(DEFAULT_FREQUENCY),
      startingUrls: new UntypedFormControl('', [OPValidators.startingUrls]),
      scanStartingUrlsOnly: this.formBuilder.control(false),
      limit: this.formBuilder.control(
        DEFAULT_PAGE_LIMIT,
        [Validators.required, Validators.min(1), OPValidators.integer]
      ),
      startingDate: this.formBuilder.control('', [OPValidators.validDate]),
      startingTime: this.formBuilder.control('', [OPValidators.validTime]),
      includeFilters: this.formBuilder.control(['']),
      excludeFilters: this.formBuilder.control(['']),
      blackoutEnabled: this.formBuilder.control(false),
      blackoutStart: new UntypedFormControl({ value: '', disabled: true }, [OPValidators.validTime]),
      blackoutEnd: new UntypedFormControl({ value: '', disabled: true }, [OPValidators.validTime]),
      templateMode: this.formBuilder.control(DEFAULT_AUDIT_OPTIONS.stripQueryString),
      scanPreviousRunUrlsOnly: this.formBuilder.control({ value: false, disabled: !this.auditHasRuns }),
      recurrence: this.formBuilder.control({})
    });

    if (!this.isEditMode) {
      // only disable this if we're creating a new audit
      this.scanStartingUrlsOnly.disable();
    }
  }

  private initListeners(): void {
    this.urlSourcesForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.emitChanges());

    this.blackoutEnabled.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(blackoutEnabled => {
        if (blackoutEnabled) {
          this.blackoutStart.enable();
          this.blackoutEnd.enable();

          const timeValueStart = this.blackoutStart.value
            ? dateUtils.formatDate(dateUtils.parseDate(this.blackoutStart.value, EDateFormats.timeTwo), EDateFormats.timeTwo)
            : dateUtils.timeStringToInputValue(new Date(), !isSafari(this.window));
          this.blackoutStart.setValue(timeValueStart);

          const timeValueEnd = this.blackoutEnd.value
            ? dateUtils.formatDate(dateUtils.parseDate(this.blackoutEnd.value, EDateFormats.timeTwo), EDateFormats.timeTwo)
            : dateUtils.timeStringToInputValue(new Date(), !isSafari(this.window));
          this.blackoutEnd.setValue(timeValueEnd);

        } else {
          this.blackoutStart.disable();
          this.blackoutEnd.disable();
        }
      });

    this.startingDate.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.startingTime.markAsTouched();
        this.startingTime.updateValueAndValidity();
      });

    this.scanStartingUrlsOnly.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((checked: boolean) => {
        this.startingUrlsCount = this.startingUrls.value.split('\n').filter((url: string) => url !== '').length;

        if (checked) {
          this.limit.setValue(this.startingUrlsCount);
          this.limit.disable();
        } else {
          this.limit.enable();
        }
      });

    this.startingDate.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onRunDateChanged.emit();
      });

    this.startingTime.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onRunTimeChanged.emit();
      });

    this.scanPreviousRunUrlsOnly.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          this.cachedScanStartingUrlsOnlyValue = this.scanStartingUrlsOnly.value;
          this.scanStartingUrlsOnly.setValue(false);
          this.scanStartingUrlsOnly.disable();
        } else {
          this.scanStartingUrlsOnly.enable();
          if (this.cachedScanStartingUrlsOnlyValue !== undefined) {
            this.scanStartingUrlsOnly.setValue(this.cachedScanStartingUrlsOnlyValue);
          }
        }
      });
  }

  sortOptions: IEmailInboxesSort = {
    sortBy: EEmailInboxesSortColumns.NAME,
    sortDesc: false,
    sortDir: 'asc'
  };

  pagination: IPaginationMetaData = {
    pageSize: 100,
    currentPageNumber: 0,
  };

  inboxes: any[] = [];
  allLabels: ILabel[] = [];

  getEmailInboxesOptions(): IEmailInboxesQueryParams {
    const { sortBy, sortDesc } = this.sortOptions;
    const page = this.pagination.currentPageNumber;
    const { pageSize } = this.pagination;

    return { page, pageSize, sortBy, sortDesc };
  }

  private getUserLimit(): void {
    if (!this.user) {
      return;
    }

    if (this.user.maxPagesPerAudit !== 0) {
      this.userLimit = this.user?.maxPagesPerAudit;
      return;
    }

    if (this.accountType === EAccountType.FREETRIAL) {
      this.userLimit = MAX_FREE_TRIAL_PAGE_SCAN_LIMIT;
      return;
    }

    this.userLimit = MAX_PAGE_SCAN_LIMIT;
  }

  private loadEmailInboxes(): void {
    this.loading = true;

    const apiFilters: IEmailInboxesFilter = {
      auditIds: [this.auditId]
    };

    this.emailInboxesService.getEmailInboxes(this.getEmailInboxesOptions(), apiFilters).pipe(
      takeUntil(this.destroy$),
      finalize(() => this.loading = false)
    ).subscribe(
      ({ emailInboxes, metadata: { pagination } }) => {
        this.inboxes = (emailInboxes as unknown as any[]).map(inbox => {
          let inboxData: IEmailInboxTableData = { ...inbox, labels: [] };
          inboxData.labels = inbox.labelIds.map(labelId => {
            const foundLabel = this.allLabels.find(label => label.id === labelId);
            return foundLabel ? { id: foundLabel.id, name: foundLabel.name } : undefined;
          });

          return inboxData;
        });

        const emailTab = this.tabs.find(t => t.name === TAB_URL_SOURCES_TO_LABEL_MAP.get(EAuditTabUrlSources.emailInboxes));
        emailTab.name = emailTab.name + ` (${this.inboxes.length})`;
      }, error => {
        console.log(error);
        this.snackbarService.openErrorSnackbar('There was a problem loading Email Inboxes.');
      }
    );
  }

  // Filter and sort the locations with US at the top of list
  sortLocations(locations: IGeoLocation[]): IGeoLocation[] {
    return locations.sort((a, b) => {
      // Sort 'us' countryCode first
      if (a.countryCode === 'us' && b.countryCode !== 'us') {
        return -1;
      } else if (a.countryCode !== 'us' && b.countryCode === 'us') {
        return 1;
      } else {
        // Sort alphabetically by label
        return a.label.localeCompare(b.label);
      }
    });
  }

  private loadData(): void {
    this.loadFrequencies();

    this.dataPromise = this.getFormOptionData().toPromise();
    this.dataPromise.then((data: IAuditSetupFormOptionData) => {
      this.user = data.user;
      this.getUserLimit();

      this.updateScanLimitBasedOnUserLimit();

      // If creating new audit, set default values
      if (!this.auditId) {
        this.setDefaultCreateAuditValues();
      }
    }, err => {
      console.error(err);
      alert('There was an error obtaining the data for this page. Please reload and try again');
    });
  }

  updateScanLimitBasedOnUserLimit(): void {
    this.urlSourcesForm?.controls?.limit.addValidators(this.userAuditScanLimit(this.user.maxPagesPerAudit));
  }

  setDefaultCreateAuditValues() {
    const use24Hour = !isSafari(this.window);
    const nextRunTime = dateUtils.formatDate(this.minStartDate, use24Hour ? EDateFormats.timeTwo : EDateFormats.timeThree);

    let audit = {
      startingDate: this.minStartDate,
      startingTime: nextRunTime,
      recipients: this.user.email,
    };

    if (this.recurrenceEnabled && !this.isEditMode) {
      this.recurrenceService.getDefaultSchedule().subscribe(schedule => {
        this.recurrenceSchedule = schedule;
      });
    }

    this.urlSourcesForm.patchValue(audit);
  }

  markAsTouched() {
    Object.values(this.urlSourcesForm.controls).forEach(control => {
      control.markAsTouched();
    });

    this.onTouched();
  }

  private showSnackbarError(errorMessage: string): void {
    this.snackbar.open(
      errorMessage,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom' }
    );
  }

  private fillEditForm(audit: IAuditModel): void {
    if (!Object.keys(audit).length) return;

    const blackout = blackoutPeriodUtils.blackoutToUIModelWithOffset(
      {
        start: audit.options?.blackoutPeriod?.start,
        end: audit.options?.blackoutPeriod?.end
      },
      this.user.timezone,
      !isSafari(this.window),
      true);

    const blackoutControls = blackout ?
      {
        blackoutEnabled: !!blackout.start,
        blackoutStart: blackout.start,
        blackoutEnd: blackout.end
      }
      : {};

    if (audit.frequency === EAuditFrequency.ONCE.toLowerCase()) this.blackoutEnabled.disable();

    const nextRunTime = dateUtils.formatDate(audit.nextRun, !isSafari(this.window) ? EDateFormats.timeTwo : EDateFormats.timeThree);

    this.selectedLabels = audit.labels;

    this.recurrenceSchedule = { schedule: audit.schedule as IOpRecurrenceScheduleResponse };

    const updatedValue = {
      frequency: audit.frequency || DEFAULT_FREQUENCY,
      startingUrls: audit.startingUrls ? audit.startingUrls?.join('\n') : '',
      scanStartingUrlsOnly: audit.startingUrls.length === audit.limit,
      limit: audit.limit,
      startingDate: audit.nextRun,
      startingTime: nextRunTime,
      includeFilters: audit.filters && audit.filters.include.length > 0 ? audit.filters.include : [''],
      excludeFilters: audit.filters && audit.filters.exclude.length > 0 ? audit.filters.exclude : [''],
      templateMode: audit.options?.stripQueryString,
      ...blackoutControls,
      scanPreviousRunUrlsOnly: audit.options.sameUrlRunId && Number.isInteger(audit.options.sameUrlRunId),
    };

    this.urlSourcesForm.patchValue(updatedValue);
    this.urlSourcesForm.updateValueAndValidity();

    const startingUrls = this.startingUrls?.value?.split('\n').filter((url: string) => url !== '');
    this.fetchedDefaultIncludeUrlDomains = this.getStartingUrlDomains(startingUrls);
    this.startingUrlsCount = startingUrls.length;
    if (this.startingUrlsCount < 1) {
      this.scanStartingUrlsOnly.setValue(false);
      this.scanStartingUrlsOnly.disable();
    }
  }

  private emitChanges(): void {
    this.onChanged && this.onChanged(this.urlSourcesForm.getRawValue());
  }

  toggleAdditionalSetupView(): void {
    this.showAdditionalSetupOptions = !this.showAdditionalSetupOptions;
  }

  getStartingUrlDomains(startingUrls: string[] = []): Set<string> {
    const domains = new Set<string>();

    startingUrls.forEach(url => {
      const urlHostname = this.getHostname(url);
      domains.add(urlHostname);
    });

    return domains;
  }

  getHostname(url: string): string {
    try {
      return new URL(url).hostname;
    } catch (e) {
      return url;
    }
  }

  generateIncludeList(startingUrls: string, includeFilters: IAuditFilter[]): void {
    const startingUrlsToFetch = startingUrls.split('\n').filter((url: string) => {
      const hostName = this.getHostname(url);
      const alreadyFetched = this.fetchedDefaultIncludeUrlDomains.has(hostName);
      this.fetchedDefaultIncludeUrlDomains.add(hostName);
      return !alreadyFetched;
    }).join('\n');

    // Only generate new include filters if we have new domains added
    if (startingUrlsToFetch.length > 0) {
      this.auditService.autoGenerateIncludeList(startingUrlsToFetch, includeFilters).subscribe(filters => {
        // only filter out empty filters if we have at least 1 starting url.
        // this prevents removing the initial include filter if startingUrls is blurred.
        let filteredFilters = filters;

        if (!filteredFilters.length) {
          filteredFilters.push({
            value: '',
            includeLimit: 0
          });
        }

        if (this.startingUrls.value !== '') {
          filteredFilters = filters.filter(filter => filter.value);
        }

        this.includeFilters.setValue(filteredFilters);
        this.includeFilters.updateValueAndValidity({ onlySelf: true });
        this.auditService.setIncludeFiltersGenerated(true);
      });
    } else {
      this.auditService.setIncludeFiltersGenerated(true);
    }
  }

  showInvalidUrls(): void {
    this.onShowInvalidUrls.emit();
  }

  onStartingUrlsBlur(): void {
    // update this to false so the user can't save yet
    this.auditService.setIncludeFiltersGenerated(false);

    let startingUrls = this.urlSourcesForm.get('startingUrls').value || '';

    // remove blank lines
    startingUrls = this.auditService.removeBlankLines(startingUrls);

    // split into an array
    startingUrls = startingUrls.split('\n');

    // add https: if missing
    startingUrls = startingUrls.map((url: string) => {
      if (!url) return;

      if (!url.match(/:\/\//)) {
        return `https://${url}`;
      }

      return url;
    });

    // dedupe starting urls
    startingUrls = [...new Set(startingUrls)];

    // join back together
    startingUrls = startingUrls.join('\n');

    // send to bottom of stack so it calculates the correct startingUrlsCount after dedupe
    setTimeout(() => {
      this.startingUrlsCount = startingUrls.split('\n').filter((url: string) => url !== '').length;

      if (this.startingUrlsCount < 1) {
        this.scanStartingUrlsOnly.disable();
        this.scanStartingUrlsOnly.setValue(false);
      } else {
        this.scanStartingUrlsOnly.enable();
      }

      // update the limit every time the field is blurred
      if (this.scanStartingUrlsOnly.value === true) {
        this.limit.setValue(this.startingUrlsCount);
      }

      this.urlSourcesForm.patchValue({ startingUrls });

      this.onChanged && this.onChanged(this.urlSourcesForm.getRawValue());

      this.generateIncludeList(this.startingUrls.getRawValue(), this.includeFilters.getRawValue());
    });
  }

  frequencyChange(frequency: MatSelectChange) {
    this.onFrequencyChanged.emit(frequency.value as IAuditFrequency);
  }

  onTimeFocus(control: string) {
    const c = this.urlSourcesForm.get(control);
    const timeValue = c.value ? dateUtils.formatDate(dateUtils.parseDate(c.value, EDateFormats.timeTwo), EDateFormats.timeTwo) : dateUtils.timeStringToInputValue(new Date(), !isSafari(this.window));

    c.setValue(timeValue);
  }

  private getFormOptionData(): Observable<IAuditSetupFormOptionData> {
    const promises = [];
    const locationPromise = this.locationsService.getAllLocations();
    const userAgentPromise = this.userAgentService.getUserAgents();

    promises.push(locationPromise, userAgentPromise);

    if (this.auditId) {
      promises.push(this.loadEasyBlockTags());
    }

    const allPromise = from(Promise.all(promises));

    return this.authenticationService.getFeaturesWithCache()
      .pipe(
        mergeMap(features =>
          forkJoin(
            allPromise,
            this.authenticationService.getAccountWithCache(),
            this.loadFolders(),
            this.loadDomains(),
            this.loadUser(),
            ([locations, userAgents, easyBlockTags], account, folders, domains, user) => ({
              account, locations, easyBlockTags, features, userAgents, folders, domains, user
            })
          )
        )
      );
  }

  loadRfms(): void {
    this.rfmService.getRfmConfigs().subscribe((configs: IRFMConfigV3[]) => {
      this.remoteFileMappings = configs
        .filter((rfmConfig: IRFMConfigV3) => !!rfmConfig.name)
        .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    });
  }

  private loadFoldersAndSubfolders(subfolder?: ITableDomain) {
    forkJoin([
      this.loadFolders(),
      this.loadDomains()
    ]).subscribe(([folders, domains]) => {
      this.folders = folders;
      this.domains = domains;
    });
  }

  private loadFolders(): Observable<IFolder[]> {
    return this.foldersService.getFoldersObservable(true);
  }

  private loadDomains(): Observable<IDomain[]> {
    return this.domainsService.getDomainsObservable(true);
  }

  private loadFrequencies() {
    this.discoveryAuditService.getAuditFrequencies().then(frequencies => {
      this.frequencies = frequencies.map(f => ({
        name: f.toLowerCase(),
        label: f
      }));
    });
  }

  private loadUser(): Observable<IUser> {
    return this.accountsService.getUser().pipe(
      tap((user: IUser) => this.user = user)
    );
  }

  private loadEasyBlockTags(): IPromise<IEasyBlockTags> {
    return this.discoveryAuditService.getEasyBlockTagIds(this.auditId);
  }

  get frequency(): AbstractControl {
    return this.urlSourcesForm.get('frequency');
  }

  get startingDate(): AbstractControl {
    return this.urlSourcesForm.get('startingDate');
  }

  get startingTime(): AbstractControl {
    return this.urlSourcesForm.get('startingTime');
  }

  get blackoutEnabled(): AbstractControl {
    return this.urlSourcesForm.get('blackoutEnabled');
  }

  get blackoutStart(): AbstractControl {
    return this.urlSourcesForm.get('blackoutStart');
  }

  get blackoutEnd(): AbstractControl {
    return this.urlSourcesForm.get('blackoutEnd');
  }

  get includeFilters(): AbstractControl {
    return this.urlSourcesForm.get('includeFilters');
  }

  get excludeFilters(): AbstractControl {
    return this.urlSourcesForm.get('excludeFilters');
  }

  get scanPreviousRunUrlsOnly(): AbstractControl {
    return this.urlSourcesForm.get('scanPreviousRunUrlsOnly');
  }

  get startingUrls(): AbstractControl {
    return this.urlSourcesForm.get('startingUrls');
  }

  get scanStartingUrlsOnly(): AbstractControl {
    return this.urlSourcesForm?.get('scanStartingUrlsOnly');
  }

  get limit(): AbstractControl {
    return this.urlSourcesForm.get('limit');
  }

  get recurrence(): AbstractControl {
    return this.urlSourcesForm.get('recurrence');
  }

  // CVA implementation
  writeValue(audit: any | IAuditModel | IAuditSetupForm): void {
    // writeValue only passes in an audit when editing or cloning an existing audit
    if (Object.keys(audit).length === 0) return;

    this.fillEditForm(audit);

    this.dataPromise.then(() => {
      this.fillEditForm(audit);
    });
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Validator implementation
  validate(c: AbstractControl): ValidationErrors {
    return this.urlSourcesForm.valid ? null : { urlSourcesForm: { valid: false, message: 'Invalid urlSources form' } };
  }

  private userAuditScanLimit(limit: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const scanLimit = limit === 0 ?
        this.accountType === EAccountType.FREETRIAL ?
          MAX_FREE_TRIAL_PAGE_SCAN_LIMIT
          : MAX_PAGE_SCAN_LIMIT
        : this.user.maxPagesPerAudit;

      if (control.value > scanLimit) {
        return { userScanLimitSurpassed: true };
      }

      return null;
    };
  }

  generateTabs(): void {
    this.tabs = [
      {
        name: TAB_URL_SOURCES_TO_LABEL_MAP.get(EAuditTabUrlSources.scheduledScan),
        path: EAuditTabUrlSources.scheduledScan
      },
      {
        name: TAB_URL_SOURCES_TO_LABEL_MAP.get(EAuditTabUrlSources.emailInboxes),
        path: EAuditTabUrlSources.emailInboxes
      }
    ];
  }

  goToTab(tabId: EAuditTabUrlSources): void {
    this.currentTab = tabId;
  }
}
