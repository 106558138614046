<form [formGroup]="urlSourcesForm">
  <p class="notice">
    Audit test scenarios can run through scheduled scans or automatically when an email message with URLs arrives.
    This audit supports both URL sources. When triggered by email, only the URLs in the email are scanned,
    without affecting existing filters and schedules. All other audit settings are applied to the URLs found in the email.
    <a class="learn-more-link"  href="{{LEARN_MORE.audit}}" target="_blank">Learn more.</a>
  </p>
  <div class="tabs">
    <op-tabs [tabs]="tabs"
             [activeTab]="currentTab"
             [useRouting]="false"
             (tabClicked)="goToTab($event)"
    ></op-tabs>
  </div>
  <div class="lock-urls" [class.hidden]="loading || !urlSourcesValue?.scanPreviousRunUrlsOnly">
    <div class="centerer">
        <span class="locked" *ngIf="sameUrlRunId">
          <mat-icon class="info-icon">info_outline</mat-icon>
          Next run will only audit pages scanned on&nbsp;
          <span *ngIf="lockUrlsPrevRunDate" class="locked-date">{{ lockUrlsPrevRunDate | date: runDateFormat }}</span>
          <span *ngIf="!lockUrlsPrevRunDate">current run</span>
        </span>
    </div>
  </div>

  <div class="audit-scheduled-scan" [class.hidden]="currentTab !== tabsLib.scheduledScan"
       [class.top-banner-displayed]="!(loading || !urlSourcesValue?.scanPreviousRunUrlsOnly)">
      <lock-overlay [isLocked]="areUrlsLocked"
                    headerText="&quot;Audit same URLs&quot; is enabled"
                    headerSubtext="(toggle the setting off to manage these fields)">
        <div class="form-input starting-urls">
          <p class="starting-urls-label"><strong>WHICH</strong> URLS SHOULD BE USED TO START THE SCAN?</p>
          <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.startingUrls">
            <mat-label>STARTING URLS</mat-label>
            <textarea
              class="vertical-resize italic-placeholder"
              matInput rows="3"
              placeholder="Example: https://www.observepoint.com"
              formControlName="startingUrls"
              (focus)="startingUrls.markAsTouched()"
              (blur)="onStartingUrlsBlur()">
        </textarea>
          </mat-form-field>
          <mat-error *ngIf="startingUrls.errors && startingUrls.touched">
            Please enter a valid URL.
            <button *ngIf="startingUrls.value !== ''" (click)="showInvalidUrls()" class="show-invalid-urls-btn">View invalid URL(s)</button>
          </mat-error>
          <div class="starting-urls-bottom" [attr.op-selector]="OP_SELECTORS.startingUrlsToggle">
            <mat-hint *ngIf="!urlSourcesForm.controls.startingUrls.invalid" class="field-hint">If protocol is missing, it will be automatically added as https://. Duplicate URLs will be removed.</mat-hint>
            <div class="starting-urls-count">{{ startingUrlsCount }} URL(S) ADDED</div>
          </div>
        </div>
        <div class="two-controls">
          <mat-slide-toggle class="starting-urls-only" color="primary" formControlName="scanStartingUrlsOnly">Audit starting URLs only</mat-slide-toggle>
          <div class="form-input"
               #pageCountInput
               (mouseleave)="hideHint(pageCountInput)"
               (mouseenter)="displayHint(pageCountInput)">
            <mat-form-field appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.pageLimit">
              <mat-label>HOW MANY PAGES SHOULD BE AUDITED?</mat-label>
              <input matInput type="number" step="1" formControlName="limit">
              <mat-hint *ngIf="!urlSourcesForm.controls.limit.invalid"
                        class="field-hint">
                User limit is {{ userLimit | number }} pages{{ user?.maxPagesPerAudit === 0 ? '.' : '. See admin for limit increase.' }}</mat-hint>
            </mat-form-field>
            <mat-error class="user-limit-error"
                       *ngIf="urlSourcesForm.controls.limit?.errors?.userScanLimitSurpassed">
              User limit is {{ userLimit | number }} pages{{ user?.maxPagesPerAudit === 0 ? '.' : '. See admin for limit increase.' }}
            </mat-error>
            <mat-error class="user-limit-error" *ngIf="urlSourcesForm.controls.limit?.errors?.required">Please enter this field</mat-error>
            <mat-error class="user-limit-error" *ngIf="urlSourcesForm.controls.limit?.errors?.min">Value cannot be less than 1</mat-error>
          </div>
        </div>
      </lock-overlay>

      <div class="two-controls">
        <div class="form-input option-toggles" [attr.op-selector]="OP_SELECTORS.dedupeUrlToggle">
          <mat-slide-toggle formControlName="templateMode" color="primary"><span class="toggle-label">De-duplicate URLs</span> (Only audit one instance of each page path if query strings are used.) <a class="learn-more-link" href="{{LEARN_MORE.dedup}}" target="_blank">Learn more.</a></mat-slide-toggle>
        </div>
        <div class="form-input option-toggles" [attr.op-selector]="OP_SELECTORS.auditSameUrlsToggle">
          <mat-slide-toggle formControlName="scanPreviousRunUrlsOnly"
                            matTooltip="Audit must have at least one prior run"
                            color="primary">
            <span class="toggle-label">Audit Same URLs</span> (Only audit URLs found in the most recently completed run prior to being enabled.)
          </mat-slide-toggle>
        </div>
      </div>

      <div class="additional-setup-divider">
        <button mat-button type="button" (click)="toggleAdditionalSetupView()">
          ADVANCED URL CONSTRAINTS<mat-icon iconPositionEnd class="down-arrow" [class.flip-vertical]="!showAdditionalSetupOptions">expand_more</mat-icon>
        </button>
      </div>

      <lock-overlay class="lock-filters" [class.hidden]="showAdditionalSetupOptions"
                    [isLocked]="scanPreviousRunUrlsOnly.value && auditHasRuns"
                    headerText="&quot;Audit same URLs&quot; is enabled"
                    headerSubtext="(toggle the setting off to manage these fields)">
        <div class="form-input" [attr.op-selector]="OP_SELECTORS.includeList">
          <filter-list #includeFilters
                       formControlName="includeFilters"
                       title="{{ FIELD_LABELS.INCLUDE }}"
                       [maxScanLimit]="scanLimit"
                       [showCollapseBtn]="false"
                       class="control-padding">
          </filter-list>
        </div>
        <div class="form-input" [attr.op-selector]="OP_SELECTORS.excludeList">
          <filter-list #excludeFilters
                       formControlName="excludeFilters"
                       title="{{ FIELD_LABELS.EXCLUDE }}"
                       placeholder="e.g. blog, careers"
                       [useScanLimit]="false"
                       [showCollapseBtn]="false"
                       class="control-padding">
          </filter-list>
        </div>
      </lock-overlay>

     <div class="form-divider"></div>

      <p class="frequency-label"><strong>WHEN & HOW OFTEN</strong> SHOULD THE AUDIT RUN?</p>

    <div *ngIf="recurrenceEnabled" class="recurrence-component-wrap">
      <op-recurrence
      [itemType]="ERecurrenceItemType.Audit"
      [parentFormField]="recurrence"
      [recurrence]="recurrenceSchedule"
    ></op-recurrence>
    </div>

      <div class="two-controls" *ngIf="!recurrenceEnabled">
        <div class="form-input"
             *ngIf="frequency"
             #frequencySelect
             (mouseleave)="hideHint(frequencySelect)"
             (mouseenter)="displayHint(frequencySelect)"
        >
          <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.frequency">
            <mat-label>HOW OFTEN SHOULD THE AUDIT RUN?</mat-label>
            <mat-select formControlName="frequency" (selectionChange)="frequencyChange($event)">
              <mat-option *ngFor="let frequency of frequencies" [value]="frequency.name">{{ frequency.label }}</mat-option>
            </mat-select>
          </mat-form-field>
          <span class="field-hint">Larger audits that run too frequently won't start until the previous run is completed. <a class="learn-more-link" href="{{LEARN_MORE.frequency}}" target="_blank">Learn more.</a></span>
        </div>
        <div class="form-input"
             #startingTimeInput
             (mouseleave)="hideHint(startingTimeInput)"
             (mouseenter)="displayHint(startingTimeInput)">
          <div class="two-controls date-controls">
            <div class="form-input nested-form-input">
              <mat-form-field appearance="outline" floatLabel="always" class="run-date no-hint-container" [attr.op-selector]="OP_SELECTORS.nextRunDate">
                <mat-label> {{ auditHasRuns ? FIELD_LABELS.NEXT_DATE : FIELD_LABELS.INITIAL_DATE }} </mat-label>
                <input matInput [min]="minStartDate" [matDatepicker]="picker" formControlName="startingDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <op-error-message formControlPath="urlSourcesForm.startingDate"></op-error-message>
            </div>
            <div class="form-input nested-form-input">
              <mat-form-field appearance="outline" floatLabel="always" class="no-hint-container" [attr.op-selector]="OP_SELECTORS.nextRunTime">
                <mat-label> {{ auditHasRuns ? 'Next run time' : 'Initial run time' }} </mat-label>
                <input matInput type="time" formControlName="startingTime" (focus)="onTimeFocus('startingTime')">
              </mat-form-field>
              <op-error-message formControlPath="urlSourcesForm.startingTime"></op-error-message>
            </div>
          </div>
          <mat-hint *ngIf="!urlSourcesForm.controls.startingDate.invalid && !urlSourcesForm.controls.startingTime.invalid" class="field-hint">Specify when the audit should start.</mat-hint>
        </div>
      </div>

      <div class="two-controls blackout-section-wrapper">
        <div class="form-input option-toggles no-top-padding"
             [attr.op-selector]="OP_SELECTORS.blackoutToggle"
             [matTooltip]="frequency.value === EAuditFrequency.ONCE ? 'Blackout window requires a frequency other than once. Change frequency to enable blackout window.' : ''"
             matTooltipShowDelay="400"
             matTooltipPosition="above"
        >
          <mat-slide-toggle class="feature-toggle" formControlName="blackoutEnabled" labelPosition="after" color="primary"><span class="toggle-label">Add Blackout Window</span> (Audit will not <em>start</em> during this daily window.) <a class="learn-more-link" href="{{LEARN_MORE.blackout}}" target="_blank">Learn more.</a></mat-slide-toggle>
        </div>
        <div class="form-input blackout-toggle-grouping"
             #blackoutTimeInput
             (mouseleave)="hideHint(blackoutTimeInput)"
             (mouseenter)="displayHint(blackoutTimeInput)">
          <div class="two-controls">
            <div class="form-input nested-form-input">
              <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.blackoutStart">
                <mat-label>{{FIELD_LABELS.START_TIME}}</mat-label>
                <input matInput type="time" formControlName="blackoutStart" (focus)="onTimeFocus('blackoutStart')">
              </mat-form-field>
              <op-error-message *ngIf="blackoutStart.errors && blackoutStart.touched" formControlPath="urlSourcesForm.blackoutStart"></op-error-message>
            </div>
            <div class="form-input nested-form-input">
              <mat-form-field class="no-hint-container" appearance="outline" floatLabel="always" [attr.op-selector]="OP_SELECTORS.blackoutEnd">
                <mat-label>{{FIELD_LABELS.END_TIME}}</mat-label>
                <input matInput type="time" formControlName="blackoutEnd" (focus)="onTimeFocus('blackoutEnd')">
              </mat-form-field>
              <op-error-message *ngIf="blackoutEnd.errors && blackoutEnd.touched" formControlPath="urlSourcesForm.blackoutEnd"></op-error-message>
            </div>
          </div>
          <mat-hint class="field-hint" [class.hide-section]="urlSourcesForm.controls.blackoutEnabled.disabled
        || (urlSourcesForm.controls.blackoutStart.touched && urlSourcesForm.controls.blackoutStart.invalid)
        || (urlSourcesForm.controls.blackoutEnd.touched && urlSourcesForm.controls.blackoutEnd.invalid)">Specify a blackout window when this audit should not start.</mat-hint>
        </div>
      </div>
  </div>

  <div class="audit-email-inboxes" [class.hidden]="currentTab !== tabsLib.emailInboxes">
    <p class="notice">
      <strong>Associated Inboxes</strong> that will use this audit configuration WHEN MESSAGES are RECEIVED
    </p>

    <p class="inbox-notice">Only URLs from within email messages received by the inbox are scanned in a new audit run. Existing audit filters and settings for scheduled audit runs are not affected. All audit settings, besides filters and schedules, will still be applied to the URLs found in the email.</p>
    <p class="inbox-notice">Associate Email Inboxes with this audit from the Email Inbox Library.</p>
    <div class="inbox-list">
      <div *ngFor="let inbox of inboxes">
        <div class="inbox-wrapper">
          <div class="name-wrapper">
            <div class="name-label">ASSOCIATED EMAIL INBOX</div>
            <div class="name-value">{{ inbox.name || '' }}</div>
          </div>
          <div class="email-wrapper">
            <div class="email-label">
              INBOX ADDRESS
            </div>
            <div class="email-value">
              <div class="email-value-container">
                <div class="email-value-text">
                  {{ inbox.emailAddress || '' }}
                </div>
              </div>
              <mat-icon
                class="copy-button"
                matTooltip="Copy to clipboard"
                matTooltipPosition="above"
                ngxClipboard
                [cbContent]="inbox.emailAddress">
                copy_all
              </mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
