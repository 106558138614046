<div class="comparison-header flex-col">
  <op-beta-label showLabel="true">
    <breadcrumb [history]="history"></breadcrumb>
  </op-beta-label>

  <op-select *ngIf="selectComparisonEnabled && runs && runs.length > 1"
            [(ngModel)]="comparisonSnapshotId"
            bindValue="comparisonSnapshotId"
            bindLabel="comparisonName"
            [data]="runs"
            placeholder="Comparison Name"
            [clearable]="false"
            appendTo="body"
            (onSelectionChanged)="goToComparisonResult($event.comparisonSnapshotId)">
  </op-select>
</div>

<router-outlet></router-outlet>
