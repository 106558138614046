import {AngularNames} from '@app/moonbeamConstants';
/**
 * @ngdoc directive
 * @name moonbeam.directive:ShadeWindowDirective
 * @restrict E
 * @scope
 *
 * @param {String} template - template url, that will be inserting inside shade modal window
 * @param {String} childCtrl - controller, related to template
 * @param {Function} onCreateCallback - callback, that will be executing after closing shade modal
 *
 * @description
 * This directive is used for inserting evaluated value (childCtrl) from scope into another directive ShadeWindowDataDirective.
 *
 */
import {IShadeWindowScope} from './shadeWindowDataController';
import * as angular from 'angular';


export class ShadeWindowDirective implements angular.IDirective {
    restrict = 'E';
    scope = {
      template: '=',
      childCtrl: '=',
      onCreateCallback: '&'
    };

    link = (scope: IShadeWindowScope, element: JQuery) => {
      if (!element.attr('shade-window-data')) {
        var name = scope.childCtrl;
        if (name) {
          element = element.removeAttr('child-ctrl');
          element.attr('shade-window-data', name);
          this.$compile(element)(scope);
        }
      }
    }

    static factory(): angular.IDirectiveFactory {
      const directive = ($compile: angular.ICompileService) => {
        return new ShadeWindowDirective($compile);
      };
      directive.$inject = [AngularNames.compile];
      return directive;
    };

    constructor(private $compile: angular.ICompileService) { };
  }

