<div class="flex-row flex-center-items comparison-variable-selector-container" [formGroup]="formGroup">

  <div class="form-input tag-input">
    <mat-form-field op-selector="tag"
                    [class.disabled]="isTagPresenceOnly && tagIdControl.value !== datasourceAllTags.key"
                    [matTooltip]="tagIdControl.value === datasourceAllTags.key ? 'Tags configured in this comparison are ignored. Tags enabled in account settings will be used instead.' : ''"
                    matTooltipClass="tags-presence-only-tooltip">
      <mat-label>Tag</mat-label>
      <mat-select formControlName="tagId"
                  (selectionChange)="onTagChange($event)"
                  panelClass="tag-list-popover"
      >
        <mat-option [value]="datasourceAllTags.key" op-selector="dataSource-all-tags">
          {{datasourceAllTags.name}}
        </mat-option>

        <mat-option *ngFor="let tag of allTags"
                    [attr.op-selector]="'dataSource-tag-' + tag.id"
                    [value]="tag.id"
                    [disabled]="!tag.comparisonEnabled"
                    [matTooltip]="!tag.comparisonEnabled ? tag.name + ' is no longer enabled for comparisons' : ''">
          {{ tag.name }}
        </mat-option>
      </mat-select>
      <mat-error>Please enter this field</mat-error>
      <mat-hint class="warn">
        {{getTagWarning()}}
      </mat-hint>
    </mat-form-field>
  </div>

  <div class="form-input variable-filter-input">
    <mat-form-field op-selector="variable-filter" [class.disabled]="!tagIdControl.value || isTagPresenceOnly">
      <mat-label>Variable Filter</mat-label>
      <mat-select formControlName="type"
                  (selectionChange)="onChangeType($event.value)"
                  panelClass="variable-list-popover"
      >
        <ng-container *ngFor="let type of datasourceTagTypes" >
          <mat-option [value]="type.key" [attr.op-selector]="'dataSource-variable-filter-' + type.key">
            {{ type.name }}
          </mat-option>
        </ng-container>
      </mat-select>
      <mat-error>Please enter this field</mat-error>
    </mat-form-field>
  </div>
  
  <div class="form-input variables">
    <mat-form-field op-selector="variables"
                    [class.disabled]="typeControl.value === datasourceTagType.AllVariables || isTagPresenceOnly">
      <mat-label>Variables</mat-label>
      <mat-select multiple
                  formControlName="selectedVariables"
                  (openedChange)="customOption.init()"
                  panelClass="variables-list comparison-popover"
      >

        <!-- Is needed for displaying Add Custom option w/o other mat-option (no variables) -->
        <mat-option style="display: none;"></mat-option>

        <op-select-custom-option #customOption 
                                 [list]="getVariablesList()"
                                 (onAdd)="addCustomVar($event)"
        ></op-select-custom-option>
        <select-check-all *ngIf="getVariablesList()?.length > 1"
                          op-selector="dataSource-all-variables"
                          [model]="variablesControl" 
                          [values]="getVariablesList()" 
                          text="All in the list">
        </select-check-all>
        <mat-option *ngFor="let variable of getVariablesList()" [value]="variable" 
                    [attr.op-selector]="'dataSource-variable-' + variable">
          {{ variable }}
          <span class="excluded-var-text" 
                *ngIf="typeControl.value === datasourceTagType.SelectedVariables && tagToExcludedVariablesMap.get(tagIdControl.value).includes(variable)"
                matTooltip="Variable is Excluded">
            (excl.)
          </span>
          <span class="excluded-var-text" 
                *ngIf="typeControl.value === datasourceTagType.AllVariablesExceptExcluded && isVariableExcludedForAccount(variable)"
                matTooltip="Variable Excluded for Account">
            (A)
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-icon class="delete-icon" (click)="onDeleteTag.emit()" op-selector="delete-action">
    clear
  </mat-icon>
</div>
