import {
  IRuleDetailRow,
  IRuleDetailRowIdentifier,
  IRuleDetailRowValueSegment
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-rules-table/rule-summary-rules-table.models';
import {
  IAuditRunSpecificRuleResultsWithMatchAll,
  IRuleTagSnapshot,
  IRuleTagVariableSnapshot
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.models';
import { ERuleConditionClause } from '@app/components/rules/rule-setup/rule-setup.enums';
import { ERuleMatchType } from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';
import { ESelectorMatchType } from '@app/models/commons';
import { matchTypeToLabel } from '@app/components/reporting/rules/rules.utils';
import { ERuleTagStatusCodeValueType, IRuleTagStatusCode } from '@app/components/rules/rules.models';
import {
  TagStatusCodeValues
} from '@app/components/rules/rule-setup/tabs/conditions-tab/shared/rule-tag-status-code-filter/rule-tag-status-code-filter.constants';

function lower(str: string): string {
  return (str + '').toLowerCase();
}

function _v(value: string): IRuleDetailRowValueSegment {
  return {
    value: value,
    cssClass: 'no-highlight'
  };
}

function _h1(value: string): IRuleDetailRowValueSegment {
  return {
    value: value,
    cssClass: 'highlight-1'
  };
}

function _h2(value: string): IRuleDetailRowValueSegment {
  return {
    value: value,
    cssClass: 'highlight-2'
  };
}

function getPfTypeString(type: number): string {
  switch (type){
    case 1:
      return 'Initial URL';
    case 2:
      return 'Initial Status Code';
    case 3:
      return 'Final URL';
    case 4:
      return 'Final URL Status Code';
    default:
      return 'Undefined Page Filter Type';
  }
}

export class RuleDetailUtils {
  static ruleDetailToRuleDetailRows(
    ruleDetail: IAuditRunSpecificRuleResultsWithMatchAll,
    getTagNameByTagId: (tagId: number) => string,
    matchAllFilters?: boolean,
  ): IRuleDetailRow[] {
    const ifConditions: IRuleDetailRow[] = [];
    const addHelperRow = () => {
      if (ifConditions.length === 0) {
        ifConditions.push(this.mkRuleDetailRowSection([_v('IF CONDITIONS')]));
      } else {
        const conditionalType = matchAllFilters ? 'AND' : 'OR';
        ifConditions.push(this.mkRuleDetailRow([_v(conditionalType)], true, 1));
      }
    };
    ruleDetail.pageFilters.forEach(pf => {
      addHelperRow();
      const pfTypeString = getPfTypeString(pf.type);
      const pfAsString = [_h2(pfTypeString), _v(pf.matchType), _h2(pf.value)];
      const row = this.mkRuleDetailRow(pfAsString, false, 1);
      this.fillData(row, pf, ERuleConditionClause.If, {
        ruleSnapshotId: ruleDetail.ruleSnapshotId,
        ruleFilter: {
          filterType: 'page_filter',
          pageFilterSnapshotId: pf.pageFilterSnapshotId
        },
        ruleResultType: undefined
      });
      ifConditions.push(row);
    });

    ruleDetail.tags.filter(t => t.clause === ERuleConditionClause.If).forEach(t => {
      addHelperRow();
      ifConditions.push(
        this.mkRuleDetailRowFromTag(
          ruleDetail.ruleSnapshotId,
          t,
          [_h2('TAG'), _v('equals')],
          false,
          ERuleConditionClause.If
        )
      );

      if (t.statusCode) {
        ifConditions.push(
          this.mkRuleDetailTagStatusCodeRow(t.statusCode)
        );
      }

      t.variables.forEach(v => {
        ifConditions.push(this.mkRuleDetailRowFromVariable(ruleDetail.ruleSnapshotId, v, getTagNameByTagId, false, ERuleConditionClause.If));
      });
    });

    const expectedConditions: IRuleDetailRow[] = [];
    ruleDetail.tags.filter(t => t.clause === ERuleConditionClause.Then).forEach(t => {
      if (expectedConditions.length === 0) {
        expectedConditions.push(this.mkRuleDetailRowSection([_v('EXPECTED CONDITIONS')]));
      }
      expectedConditions.push(this.mkRuleDetailRowFromTag(ruleDetail.ruleSnapshotId, t, [], true, ERuleConditionClause.Then));
      t.variables.forEach(v => {
        expectedConditions.push(this.mkRuleDetailRowFromVariable(ruleDetail.ruleSnapshotId, v, getTagNameByTagId, true, ERuleConditionClause.Then));
      });
    });

    // TODO: Else clause is outdated, remove it
    const elseConditions: IRuleDetailRow[] = [];
    /*ruleDetail.tags.filter(t => t.clause === ERuleConditionClause.Else).forEach(t => {
      if (elseConditions.length === 0) {
        elseConditions.push(this.mkRuleDetailRowSection([_v('ELSE CONDITIONS')]));
      }
      elseConditions.push(this.mkRuleDetailRowFromTag(ruleDetail.ruleSnapshotId, t, [], true, ERuleConditionClause.Else));
      t.variables.forEach(v => {
        elseConditions.push(this.mkRuleDetailRowFromVariable(ruleDetail.ruleSnapshotId, v, getTagNameByTagId, true, ERuleConditionClause.Else));
      });
    });*/
    return [
      ...ifConditions,
      ...expectedConditions,
      ...elseConditions
    ];
  }

  private static mkRuleDetailRowFromTag(
    ruleSnapshotId: number,
    t: IRuleTagSnapshot,
    tagPrefix: IRuleDetailRowValueSegment[] = [],
    withArrow: boolean = true,
    conditionType: ERuleConditionClause
  ): IRuleDetailRow {
    const accountSegments = t.account ? [_h2('account'), _v(lower(matchTypeToLabel(t.matchType))), _h2(t.account)] : [];
    const tagSegments = [...tagPrefix, _h1(t.tagName)];
    const row = this.mkRuleDetailRow([...tagSegments, ...accountSegments], false, 1, withArrow);
    this.fillData(row, t, conditionType, {
      ruleSnapshotId: ruleSnapshotId,
      ruleFilter: {
        filterType: 'tag_filter',
        tagSnapshotId: t.ruleTagSnapshotId
      },
      ruleResultType: undefined
    });
    return row;
  }

  private static mkRuleDetailTagStatusCodeRow(
    statusCode: IRuleTagStatusCode,
  ): IRuleDetailRow {
    if (!statusCode) {
      return null;
    }
    const value = statusCode.valueType === ERuleTagStatusCodeValueType.Specific
      ? String(statusCode.value)
      : TagStatusCodeValues.find(v => v.valueType === statusCode.valueType)?.name ?? statusCode.valueType;
    const tagSegments = [
      _h2('TAG STATUS CODE'),
      _v(lower(matchTypeToLabel(statusCode.matchType))),
      _h1(value),
    ];
    return this.mkRuleDetailRow([...tagSegments], false, 1);
  }

  private static mkRuleDetailRowFromVariable(
    ruleSnapshotId: number,
    v: IRuleTagVariableSnapshot,
    getTagNameByTagId: (tagId: number) => string,
    withArrow: boolean = true,
    conditionType: ERuleConditionClause
  ): IRuleDetailRow {
    const TagSelectorSegments = v.selectorType === ESelectorMatchType.tagVariable
      ? [_h1(getTagNameByTagId(v.valueTagId)), _v('variable')]
      : [];
    const selectorSegments = v.selectorType ? [_v(v.selectorType), ...TagSelectorSegments, _h2(v.value)] : [];
    const matchTypeSegments = this.mkMatchTypeSegments(v);
    const variableSegments = [_v('Variable'), _h2(v.variable), ...matchTypeSegments, ...selectorSegments];
    const row = this.mkRuleDetailRow(variableSegments, false, 2, withArrow);
    this.fillData(row, v, conditionType, {
      ruleSnapshotId: ruleSnapshotId,
      ruleFilter: {
        filterType: 'tag_variable_filter',
        tagSnapshotId: v.ruleTagSnapshotId,
        tagVariableSnapshotId: v.ruleTagVariableSnapshotId
      },
      ruleResultType: undefined
    });
    return row;
  }

  private static mkMatchTypeSegments(v: IRuleTagVariableSnapshot): IRuleDetailRowValueSegment[] {
    return [ERuleMatchType.IsSet, ERuleMatchType.IsNotSet].includes(v.matchType)
      ? [_v('has condition'), _h2(matchTypeToLabel(v.matchType))]
      : [_v(lower(matchTypeToLabel(v.matchType)))];
  }

  private static fillData(
    row: IRuleDetailRow,
    data: { failedRulePageCount: number; passedRulePageCount: number; notAppliedRulePageCount: number },
    conditionType: ERuleConditionClause,
    identifier?: IRuleDetailRowIdentifier
  ): void {
    row.failedCount = conditionType === ERuleConditionClause.If ? undefined : data.failedRulePageCount;
    row.passedCount = conditionType === ERuleConditionClause.If ? undefined : data.passedRulePageCount;
    row.notAppliedCount = conditionType === ERuleConditionClause.If ? data.notAppliedRulePageCount : null;
    row.identifier = identifier;
  }

  private static mkRuleDetailRow(
    value: IRuleDetailRowValueSegment[],
    isHelper: boolean = true,
    level: number = 0,
    withArrow: boolean = false
  ): IRuleDetailRow {
    return {
      valueSegments: value,
      isHelper: isHelper,
      level: level,
      withArrow: withArrow
    };
  }

  private static mkRuleDetailRowSection(value: IRuleDetailRowValueSegment[]): IRuleDetailRow {
    return {
      valueSegments: value,
      isHelper: true,
      level: 0,
      withArrow: false,
      isSection: true
    };
  }
}
