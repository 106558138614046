import { Route } from '@angular/router';
import { MyProfileComponent } from './my-profile.component';
import { MyProfilePaths, MyProfileStateNames } from './my-profile.constants';

export const MyProfileFutureState: Route = {
  path: MyProfilePaths.base,
  loadChildren: () => import('./my-profile.module').then(mod => mod.MyProfileModule),
};

export const MyProfileRoutes: Route = {
  path: '',
  component: MyProfileComponent,
  data: {
    title: 'My Profile',
    stateName: MyProfileStateNames.base
  }
};
