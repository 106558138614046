<div class="report-header" (keydown.esc)="runPickerVisible = false">
  <div class="left">
    <button *ngIf="(isVisitorMode$ | async) === false" (click)="closeReport(true)" class="back-btn">
      <mat-icon (click)="closeReport(true)"
                matTooltip="Close Audit"
                class="back-icon">navigate_before</mat-icon>
    </button>
    <div class="title-date-wrp">
      <div class="audit-name-wrp">
        <h1 class="report-name audit-name" title="{{ auditName }}">{{ auditName }}</h1>
      </div>
      <div class="run-picker-wrp"
           matTooltip="Run Date"
           *ngIf="!noAudit"
      >
        <mat-icon *ngIf="showPauseIconFlag" class="report-header-icon-paused-state" svgIcon="pause_icon"></mat-icon>
        <button *ngIf="this.runId"
                class="btn btn-link"
                mdePopoverTriggerOn="click"
                [mdePopoverTriggerFor]="appPopover"
                (opened)="runPickerVisible = true"
                (closed)="runPickerVisible = false">
          <span>{{ currentRunDate }}</span>
          <mat-icon>expand_more</mat-icon>
        </button>

        <mde-popover #appPopover="mdePopover"
                    [mdePopoverOverlapTrigger]="false"
                    [mdePopoverCloseOnClick]="false">
          <run-picker-ng  *ngIf="runPickerVisible"
                          [runs]="auditRuns"
                          (onClickRun)="onClickRun($event)">
          </run-picker-ng>
        </mde-popover>
      </div>
    </div>
    <status-banner
      *ngIf="!auditHasNotRunYet"
      [updatesType]="statusBannerUpdateType.Audit"
      [scheduledToScan]="scheduledToScan"
      [runCancelled]="runCancelled"
      (viewReport)="updateRun($event)"
      (runFinished)="runFinished()">
    </status-banner>
    <reprocess-banner [auditId]="auditId"
                      [runId]="runId"
                      [auditName]="auditName"
                      bannerType="rules">
    </reprocess-banner>
    <reprocess-banner [auditId]="auditId"
                      [runId]="runId"
                      [auditName]="auditName"
                      bannerType="consent-categories">
    </reprocess-banner>
  </div>
  <div class="right">
    <div class="desktop-menu">
      <div class="report-info menu-icon-wrp menu-icon" op-selector="help-doc-link"  id="help-doc-link">
        <mat-icon matTooltip="Help Documentation"
                  matTooltipPosition="below"
        >help_outline</mat-icon>
      </div>

      <div *ngIf="(isVisitorMode$ | async) === false; else visitorModeShareButton"
           class="report-info menu-icon-wrp menu-icon"
           op-selector="alert-link"
           matTooltip="Alerts">
        <alert-bell mdePopoverTriggerOn="click"
                    [mdePopoverTriggerFor]="bellIconPopover"
                    [status]="bellStatus"
                    [count]="alertsCount"
                    [showBellAlways]="true"
                    [showCount]="true"
                    class="bell-icon"
                    (opened)="alertPopoverOpen = true"
                    (closed)="alertPopoverOpen = false">
        </alert-bell>
        <mde-popover #bellIconPopover="mdePopover"
                  [mdePopoverOverlapTrigger]="false"
                  [mdePopoverCloseOnClick]="true">
          <audit-report-header-popover [auditId]="auditId"
                                      [runId]="runId"
                                      [user]="user"
                                      [popoverOpen]="alertPopoverOpen"
                                      [accountHasAlerts]="accountHasAlerts"
                                      (headerIconData)="setBellIconStates($event)">
          </audit-report-header-popover>
        </mde-popover>
      </div>
      <ng-template #visitorModeShareButton>
        <div class="report-info menu-icon-wrp menu-icon"
             op-selector="alert-link"
             matTooltip="Alerts">
          <alert-bell [status]="bellStatus"
                      [count]="alertsCount"
                      [showBellAlways]="true"
                      [showCount]="true"
                      class="bell-icon"
                      (click)="openFreeTrialAd()">
          </alert-bell>
        </div>
      </ng-template>

      <div class="report-info menu-icon-wrp menu-icon">
        <status-popover-button [advanced]="enabledInfoItems?.length > 0 || auditRunStatus.status !== EAuditRunStatus.Basic"
                               [leadingIcon]="auditRunStatus.icon"
                               [buttonText]="auditRunStatus.pillText"
                               [trailingIcon]="'info_outline'"
                               [buttonColor]="auditRunStatus.status === EAuditRunStatus.Stopped ? EStatusButtonColor.Red : auditRunStatus.status === EAuditRunStatus.Issues ? EStatusButtonColor.Orange : enabledInfoItems?.length > 0 ? EStatusButtonColor.Yellow : enabledInfoItems?.length > 0 || auditRunStatus.status !== EAuditRunStatus.Basic ? EStatusButtonColor.None : EStatusButtonColor.Green"
                               #auditInfoTrigger
                               mdePopoverTriggerOn="click"
                               matTooltip="Audit Run Information"
                               matTooltipPosition="below"
                               [mdePopoverTriggerFor]="infoPopover"
        >
          <mde-popover #infoPopover="mdePopover"
                       class="audit-info-popover"
                       [mdePopoverOverlapTrigger]="false"
                       [mdePopoverArrowColor]="'rgba(0,0,0,0)'"
                       [mdePopoverEnterDelay]="400"
                       [mdePopoverCloseOnClick]="false">
            <audit-report-info [audit]="audit"
                               [runId]="runId"
                               [folderName]="folderName"
                               [domainName]="domainName"
                               [auditRunStatus]="auditRunStatus"
                               [auditHasNotRunYet]="auditHasNotRunYet"
                               (disableWarning)="disableWarningIconHighlight()"
                               (editAudit)="handleEditAuditConfiguration()"
                               (viewPagesWithFailedActions)="handleViewPagesWithFailedActions()">
            </audit-report-info>
          </mde-popover>
        </status-popover-button>
      </div>
      <op-menu class="flex-row action-menu menu-icon-wrp menu-icon"
               matTooltip="Audit Settings"
               matTooltipPosition="below"
               *ngIf="!noAudit && !isReadOnly && (isVisitorMode$ | async) === false && user"
               [items]="menuOptions"
               [model]="audit"
               [currentUser]="user"
               icon="icon-gear">
      </op-menu>
      <span class="report-info menu-icon share-link" op-selector="share-link"
            *ngIf="sharingEnabled">
        <button mat-raised-button color="primary"
                matTooltip="Share Audit Report"
                matTooltipPosition="below"
                (click)="shareReport()">
          <mat-icon>ios_share</mat-icon>
          Share
        </button>
      </span>
    </div>
    <div class="mobile-menu">
      <button [matMenuTriggerFor]="menu" class="three-dot-menu-btn" op-selector="report-mobile-menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button *ngIf="(isVisitorMode$ | async) === false" mat-menu-item op-selector="alert-link-mobile" (click)="routeToAlertSummary(true)">
          <mat-icon>notifications</mat-icon>
           Alerts
        </button>

        <button mat-menu-item op-selector="help-doc-link" id="help-doc-link-mobile">
          <mat-icon>help_outline</mat-icon>
          Help Documentation
        </button>
        <button mat-menu-item
                mdePopoverTriggerOn="click"
                [mdePopoverTriggerFor]="mobileInfoPopover"
                op-selector="info-panel-link-mobile"
        >
          <mat-icon>info_outline</mat-icon>
          Audit Run Information
        </button>
        <mde-popover #mobileInfoPopover="mdePopover"
                     class="audit-info-popover"
                     mdePopoverPositionY="above"
                     [mdePopoverArrowColor]="'rgba(0,0,0,0)'"
                     [mdePopoverEnterDelay]="400"
                     [mdePopoverCloseOnClick]="false">
          <audit-report-info [audit]="audit"
                             [runId]="runId"
                             [folderName]="folderName"
                             [domainName]="domainName"
                             (disableWarning)="disableWarningIconHighlight()">
          </audit-report-info>
        </mde-popover>
      </mat-menu>
      <button class="share-link-btn" op-selector="share-link-mobile" (click)="shareReport()"
              *ngIf="sharingEnabled">
        <mat-icon>ios_share</mat-icon>
      </button>
    </div>
  </div>
</div>
