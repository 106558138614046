import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { AuditReportUrlBuilders } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { WebJourneyReportUrlBuilders } from '@app/components/web-journey-report/web-journey-report.constants';
import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { RorReportUrlBuilders } from '@app/components/ror-comparison-report/ror-comparison-report.contants';

// dummy IDs for building route strings
const auditId = 0;
const journeyId = 0;
const runId = 0;

export enum EKeyCodes {
  Command = 'meta',
  QuestionMark = '?',
  Shift = 'shift',
  Control = 'control',
  Escape = 'escape',
  ArrowDown = 'arrowdown',
  ArrowUp = 'arrowup',
  ArrowRight = 'arrowright',
  ArrowLeft = 'arrowleft',
  BracketLeft = '[',
  KeyC = 'c',
  KeyF = 'f',
  KeyH = 'h',
  KeyE = 'e',
  KeyN = 'n',
  KeyS = 's',
  KeyI = 'i',
  KeyT = 't'
}

export const IgnoreKeyboardShortcuts = [
  'input',
  'textarea',
  'span'
];

export enum EReportTypes {
  Audit = 'audit',
  WebJourney = 'web-journey'
}

export const AuditPathRegex = /\/audit\/\d{1,8}\/run\/\d{1,8}\//;

const AuditReportRoutes = [
  AuditReportUrlBuilders.base(auditId, runId),
  AuditReportContainerUrlBuilders.auditExports(auditId, runId),
  RorReportUrlBuilders.results(auditId, runId)
];

export const KeyRoutesInclusive = {
  [EKeyCodes.KeyC]: [
    DataSourcesUrlBuilders.base(),
    DataSourcesUrlBuilders.sources()
  ],
  [EKeyCodes.KeyE]: [
    ...AuditReportRoutes,
    WebJourneyReportUrlBuilders.results(journeyId, runId),
  ],
  [EKeyCodes.KeyI]: AuditReportRoutes,
  [EKeyCodes.KeyN]: [
    ...AuditReportRoutes,
    WebJourneyReportUrlBuilders.results(journeyId, runId)
  ],
  [EKeyCodes.ArrowDown]: AuditReportRoutes,
  [EKeyCodes.ArrowRight]: AuditReportRoutes,
  [EKeyCodes.ArrowUp]: AuditReportRoutes,
  [EKeyCodes.ArrowLeft]: AuditReportRoutes,
};

export const KeyRoutesExclusive = {
  [EKeyCodes.KeyT]: [],
  [EKeyCodes.BracketLeft]: [],
};

export const KeysIgnoredByModal = [
  EKeyCodes.KeyT
];

export const KeyboardShortcutsPrefStorageKey: string = 'keyboardShortcuts';
