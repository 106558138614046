import { Injectable } from '@angular/core';
import { AccountsService } from '@app/components/account/account.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { environment } from '@app/environments/environment';
import { statusCodeThresholdClass } from '../../shared/components/request-log-table/request-log.constants';

/**
 * Each report gets their own service and inherits from this base service
 * which automatically handles cache clearing during reset events.
 */
export class AuditReportBaseService {
  protected responseCache = new Map();
  protected apiRoot = `${environment.apiV3Url}web-audits`;
  protected apiV2Root = `${environment.apiUrl}web-audits`;

  constructor(private cacheResetService: CacheResetService) {
    this.cacheResetService.reset$.subscribe(_ => {
      this.responseCache.clear();
    });
  }
}

@Injectable()
export class AuditReportService extends AuditReportBaseService {

  userIsReadOnly: boolean;

  /**
   * Caching below based on the following:
   * https://medium.com/@dilanthaprasanjith/caching-http-requests-with-rxjs-in-angular-9f7fa44387ce
   */

  constructor(
    cacheResetService: CacheResetService,
    private accountsService: AccountsService
  ) {
    super(cacheResetService);
    this.userIsReadOnly = this.accountsService.userIsReadOnly();
  }

  getStatusCodeClass(code: number): string {
    if (!Number.isInteger(code)) return '';

    const firstDigit = +code.toString().substr(0, 1);
    return statusCodeThresholdClass[firstDigit];
  }

  getLoadTimeClassForSeconds(
    time: number,
    greenThreshold = 3,
    yellowThreshold = 6,
    orangeThreshold = 12,
  ): string {
    return Number(time) === time // Number.isInteger returns false for floating point value
      ? this.getLoadTimeClass(time, greenThreshold, yellowThreshold, orangeThreshold)
      : '';
  }

  getLoadTimeClassForMs(time: number): string {
    return this.getLoadTimeClass(time, 500, 1000, 2000);
  }

  private getLoadTimeClass(
    time: number,
    greenThreshold: number,
    yellowThreshold: number,
    orangeThreshold: number
  ): string {
    if (time < greenThreshold) {
      return 'load-time-green';
    } else if (time < yellowThreshold) {
      return 'load-time-yellow';
    } else if (time < orangeThreshold) {
      return 'load-time-orange';
    } else {
      return 'load-time-red';
    }
  }
}
