import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';
import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export const PagesTableColumns: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.GeoCount]: {
    title: '# of Unique Geos',
    checked: true,
  },
  [CommonReportsPagesTableColumns.RequestCount]: {
    title: '# of Network Requests',
    checked: true,
  },
};
