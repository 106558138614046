export const statusCodeThresholdClass = {
  0: 'status-code-red', // http 0 status code
  1: 'status-code-orange', // http 100 level status codes
  2: 'status-code-green', // http 200 level status codes
  3: 'status-code-yellow', // http 300 level status codes
  4: 'status-code-red', // http 400 level status codes
  5: 'status-code-red', // http 500 level status codes
  6: 'status-code-red', // http 600 level status codes
  7: 'status-code-red', // http 700 level status codes
  8: 'status-code-red', // http 800 level status codes
  9: 'status-code-red', // http 900 level status codes
};
