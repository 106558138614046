import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'select-check-all',
  templateUrl: './select-check-all.component.html',
  styleUrls: ['./select-check-all.component.scss']
})
export class SelectCheckAllComponent {

  @Input() model: UntypedFormControl;
  @Input() values: string[] = [];
  @Input() text: string = 'Select All'; 

  isChecked(): boolean {
    return this.model.value && this.values && this.values.length
      && this.model.value.length === this.values.length;
  }

  isIndeterminate(): boolean {
    return this.model.value && this.values && this.values.length && this.model.value.length
      && this.model.value.length < this.values.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.model.setValue(this.values);
    } else {
      this.model.setValue([]);
    }
  }
}
