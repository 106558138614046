import { Router, NavigationEnd, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { Subject } from 'rxjs';

export interface IHistoryFrame {
  url: string;
  stateName?: string;

  // We pass additional params to the history which we would use by navigating back.
  extras?: NavigationExtras
}

/**
 * Stores navigation history and provides helpful functions (go to last from history etc)
 */
@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService implements OnDestroy {

  private history: IHistoryFrame[] = [];
  private destroySubject = new Subject();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private cacheResetService: CacheResetService) {

    this.cacheResetService.reset$.subscribe(_ => this.clear());
    
  }

  init() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd), takeUntil(this.destroySubject))
      .subscribe((newState: NavigationEnd) => {

        /**
         * It handles circular route issue. For example:
         * 1. Navigate to My Cards (history -> [/manage])
         * 2. Navigate to Web Journey Report ([/manage, /report])
         * 3. Navigate to Edit Web Journey ([/manage, /report, /details])
         * 4. Click close button on Edit Web Journey ([/manage, /report, /details, /report])
         * 5. Click close button on Web Journey Report ([/manage, /report, /details, /report, /details])
         * 
         * The problem appears on step #4. Each time close button is clicked, the [latest - 1] history frame is taken.
         * And again, this listener is called for the new redirection -> inserted new frame to the history.
         * But we simply go back, so, we should remove the last history frame and don't insert the new one.
         */
        const goToPreviousState = this.getPreviousState()?.url === newState.url;
        if (goToPreviousState) {
          this.history.pop();
          return;
        }

        /**
         * It handles the same route navigation. For example:
         * 1. Navigate to My Cards (history -> [/manage])
         * 2. Navigate to Web Journey Report ([/manage, /report])
         * 3. Select another run from run picker ([/manage, /report, /report])
         * 4. Click close button on Web Journey Report ([/manage, /report])
         * 
         * The problem appears on step #3.
         * When user clicks close button, it redirects to the previous report instead of My Cards view.
         * So, we should simply replace previous report's history frame with the new one in case of the same state redirection.
         */

        const newStateName = this.getRouteData(this.route).stateName;
        const currentStateName = this.getCurrentState()?.stateName;
        const goToSameState = newStateName !== undefined && newStateName === currentStateName;
        if (goToSameState) {
          this.updateCurrentState(newState.url, newStateName);
          return;
        }

        const data = this.getRouteData(this.route);
        this.history.push({
          url: newState.urlAfterRedirects,
          stateName: data?.stateName
        });

      });
  }

  updateCurrentState(url: string, stateName: string, extras?: NavigationExtras): void {
    this.history.pop();
    this.history.push({
      url,
      stateName,
      extras
    });
  }

  /**
   * Navigates back to the previous url
   * Navigates to the previous url if there is previous url and it isn't excluded (`shouldIgnore` predicate function)
   * Otherwise, navigates to the fallback url (`fallbackUrl` property)
   */
  goToLastFromHistory(fallbackUrl = DataSourcesUrlBuilders.sources(),
                      shouldIgnore?: (previousState: IHistoryFrame) => boolean,
                      extras?: NavigationExtras) {
    const previousState = this.getPreviousState();
    if (!previousState) this.router.navigate([fallbackUrl], extras);
    else {
      const isIgnored = shouldIgnore?.(previousState);
      this.router.navigate([isIgnored ? fallbackUrl : previousState.url], isIgnored ? extras : previousState.extras);
    }
  }

  /**
   * Clears url history. Is needed when switching between users. For example:
   * 1. Admin navigates to Admin Portal
   * 2. Admin opens someone's report, hence impersonate another user
   * 3. Close button is clicked
   * 4. By default - admin will be redirected to the last route from history - Admin Portal
   * 5. Desired behavior - close button should navigate to My Cards page
   */
  clear(): void {
    this.history = [];
  }

  getPreviousUrl(): string {
    return this.getPreviousState()?.url;
  }

  getCurrentState(): IHistoryFrame {
    return this.history[this.history.length - 1];
  }
  
  getPreviousState(): IHistoryFrame {
    return this.history[this.history.length - 2];
  }

  private getRouteData(route: ActivatedRoute): any {
    let r = this.route;
    while (r.firstChild) r = r.firstChild;
    return r.snapshot.data;
  }

  ngOnDestroy() {
    this.destroySubject.next();
  }

}
