import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { IAccountTriggeredAlertsDTO, ITriggeredAlertsSearchBody, ITriggeredAlertsSearchParams, ITriggeredAlertDataSourcesDTO, ITriggeredAlertDataSourcesSearchBody, ITriggeredAlertDataSourcesSearchParams, IAccountTriggeredAlertsSummaryDTO, ITriggeredAlertsExportResponse } from './triggered-alerts.models';
import { CacheResetService, ECacheResetEvent } from '@app/components/core/services/cache-reset.service';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';

const resetCacheSubject = new Subject<ECacheResetEvent>();

@Injectable({
  providedIn: 'root'
})
export class TriggeredAlertsService implements OnDestroy {

  private apiRoot = `${environment.apiV3Url}account-triggered-alerts`;
  private destroySubject = new Subject<void>();

  constructor(private apiService: ApiService,
    private cacheResetService: CacheResetService) {

    this.cacheResetService.reset$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(resetEvent => resetCacheSubject.next(resetEvent));

  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  getAlerts({ size, page, sortBy, sortDesc }: ITriggeredAlertsSearchParams,
    body: ITriggeredAlertsSearchBody = {}): Observable<IAccountTriggeredAlertsDTO> {
    const requestUrl = `${this.apiRoot}/alerts`;
    const params = new HttpParams({
      fromObject: { size, page, sortDesc, sortBy }
    });
    return this.apiService.post(requestUrl, body, { params });
  }

  @CacheApiResponse({
    liveTime: 10000,
    resetCache: resetCacheSubject
  })
  getAlertsSummary(body: ITriggeredAlertsSearchBody = {}): Observable<IAccountTriggeredAlertsSummaryDTO> {
    const requestUrl = `${this.apiRoot}/summary`;
    return this.apiService.post(requestUrl, body);
  }

  getAlertDataSources(alertId: number,
    { sortBy, sortDesc }: ITriggeredAlertDataSourcesSearchParams,
    body: ITriggeredAlertDataSourcesSearchBody = {}): Observable<ITriggeredAlertDataSourcesDTO> {
    const requestUrl = `${this.apiRoot}/alerts/${alertId}`;
    const params = new HttpParams({
      fromObject: { sortDesc, sortBy }
    });
    return this.apiService.post(requestUrl, body, { params });
  }

  exportTriggeredAlerts({ size, page, sortBy, sortDesc }: ITriggeredAlertsSearchParams,
    body: ITriggeredAlertsSearchBody = {}): Observable<ITriggeredAlertsExportResponse> {
    const requestUrl = `${environment.apiV3Url}alerts/exports/account_level_triggered_alerts_export`;
    const params = new HttpParams({
      fromObject: { size, page, sortDesc, sortBy }
    });
    return this.apiService.post(requestUrl, body, { params });
  }
}
