<div class="sparkline-chart">
  <div class="left" *ngIf="dataPoints && dataPoints?.length > 1">
    <op-sparkline-chart-colorized
      [config]="chartConfig"
      [boundaries]="boundaries"
      [uniqueId]="uniqueId">
    </op-sparkline-chart-colorized>
  </div>

  <div class="right" *ngIf="dataPoints && dataPoints?.length > 1">
    <div class="change-indicator"
         [ngClass]="{
       'green-icon': changeMeaning === 'positive',
       'red-icon': changeMeaning === 'negative',
       'orange-icon': changeMeaning === 'neutral'
       }"
    >
      <mat-icon *ngIf="changeDirection > 0">arrow_upward</mat-icon>
      <mat-icon *ngIf="changeDirection < 0">arrow_downward</mat-icon>

      <ng-template #noChange>
        <div class="change-value no-change">No Change</div>
      </ng-template>

      <div *ngIf="changeValue; else noChange"
           class="change-value">{{ changeValue | number: digitsInfo }}
      </div>
    </div>

    <div class="change-label" *ngIf="dataPoints && dataPoints?.length > 1">{{ label }}</div>
  </div>

  <div class="no-trend-line" *ngIf="dataLoaded && dataPoints && dataPoints?.length <= 1">
    trend lines will appear as new runs are performed
  </div>
</div>
