import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef, OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  ICCEditParams,
  IConsentCategoryGeos,
  IConsentCategoryRequestDomain,
  IContinentMap,
  INewRequestDomain
} from '@app/components/consent-categories/consent-categories.models';
import { ConsentCategoriesService } from '../../consent-categories.service';
import { ActivatedRoute } from '@angular/router';
import { ECCEditTabs, EditInstructions } from '../cc-edit.constants';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AddNewRequestDomainComponent } from './add-new-request-domain/add-new-request-domain.component';
import { ICCRequestDomainTableLocation, ICCRequestDomainTableRow } from '../cc-edit.models';
import { EGeoAutoCompleteType } from '../../cc-geo-selector/cc-geo-selector.constants';
import { ICCGeoSelectorItem } from '../../cc-geo-selector/cc-geo-selector.models';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { StorageService } from '@app/components/shared/services/storage.service';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ConsentCategoriesEditRequestDomainsComponent),
  multi: true
};

const FORM_CONTROL_VALIDATION = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ConsentCategoriesEditRequestDomainsComponent),
  multi: true
};

const CCEditRequestDomainsTablePageSizeStorageKey = 'cc-edit-request-domains-table-page-size';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-edit-request-domains',
  templateUrl: './cc-requests-and-geos.component.html',
  styleUrls: ['./cc-requests-and-geos.component.scss'],
  providers: [FORM_CONTROL_VALUE_ACCESSOR, FORM_CONTROL_VALIDATION],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentCategoriesEditRequestDomainsComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {

  readonly editRequestDomainsInstructions: string = EditInstructions;
  consentCategoryId: number;
  dataSource = new MatTableDataSource;
  displayedColumns = ['domain', 'geolocation', 'delete'];
  loading: boolean = false;
  requestsAndGeosForm: UntypedFormGroup;
  geoData: IConsentCategoryGeos;
  filterValue: string;
  autoCompleteList: ICCGeoSelectorItem[] = [];
  pageSizeOptions = [10, 25, 50, 100, 500];
  minCookiesToShowPaginator = this.pageSizeOptions[0];

  private destroy$ = new Subject();

  onChange = (value: any) => {};
  onTouched = () => {};

  @Output() tabDataCount: EventEmitter<{tabName: string, newTabCount: number}> = new EventEmitter();
  @Output() formTouched: EventEmitter<ECCEditTabs> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private ccService: ConsentCategoriesService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private modalService: OpModalService,
    private modalEscapeService: ModalEscapeService,
    private dateService: DateService,
    private cd: ChangeDetectorRef,
    private storage: StorageService,
  ) {
    this.route.params.subscribe((params: ICCEditParams) => {
      this.consentCategoryId = params.id;
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.getGeoData();
  }

  ngAfterViewInit(): void {
    this.initTable();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  initForm(): void {
    this.requestsAndGeosForm = this.formBuilder.group({
      requestDomains: this.formBuilder.array([])
    });

    this.requestsAndGeosForm
      .valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        if (this.requestsAndGeosForm.pristine === false) {
          this.formTouched.emit(ECCEditTabs.requestDomains);
        }
        this.onChange(this.requestDomains.value);
      });
  }

  initTable(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      return typeof item[property] === 'string'
        ? item[property]?.toLocaleLowerCase()
        : item[property];
    };

    this.dataSource.data = this.alphaSort('domain', [ ...this.requestDomains.value ]);
    this.cd.detectChanges();
    this.matchFormControlSortOrderWithTable();

    this.loading = false;
  }

  getActualIndex(cookie: IConsentCategoryGeos): number {
    return this.dataSource.data.indexOf(cookie);
  }

  updateDataSource(alpha?: boolean): void {
    setTimeout(() => {
      this.dataSource.data = alpha
        ? this.alphaSort('domain', [ ...this.requestDomains.value ])
        : [ ...this.requestDomains.value ];

      this.matchFormControlSortOrderWithTable();
      this.updateRequestDomainsTabCount();
      this.formTouched.emit(ECCEditTabs.requestDomains);
    }, 200);
  }

  matchFormControlSortOrderWithTable(): void {
    // update the form array so the table will properly sort
    this.requestDomains.controls.forEach((requestDomainControl, i) => {
      // Patch Form controls
      requestDomainControl.patchValue(this.dataSource.data[i]);

      // Update nested FormArrays in the FormGroup
      let locationIds = this.dataSource.data[i]['locations'].map(location => location.id);
      let locationsForm = requestDomainControl.get('locations') as UntypedFormArray;
      locationsForm.clear();

      this.createLocationControls(locationIds).forEach(control => {
        locationsForm.push(control);
      });
    });
  }

  getGeoData(): void {
    this.ccService.geo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((geoData: IConsentCategoryGeos) => {
        this.geoData = geoData;
        this.createContinentObj(this.geoData.continentsById);
      });
  }

  alphaSort(prop: string, arr: any[]): any[] {
    return arr.sort((a: any, b: any) => (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) ? 1 : -1);
  }

  applyFilter(event: KeyboardEvent): void {
    this.filterValue = (event.target as HTMLInputElement)?.value?.trim().toLowerCase() || '';
    this.dataSource.filter = this.filterValue;
  }

  savePageSizeToStorage(e: PageEvent): void {
    this.storage.setValue(CCEditRequestDomainsTablePageSizeStorageKey, e.pageSize);
  }

  get perPage(): number {
    return this.storage.getValue(CCEditRequestDomainsTablePageSizeStorageKey) || this.pageSizeOptions[2];
  }

  openAddRequestAndGeoModal(): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService
      .openModal(AddNewRequestDomainComponent, { data: this.autoCompleteList })
      .afterClosed()
      .subscribe((domain: INewRequestDomain) => {
        if (domain) {
          this.createNewRequestDomain(domain);
          this.updateDataSource(true);
        }

        this.modalEscapeService.remove(index);
      });
  }

  copyRequestDomain(domain: ICCRequestDomainTableRow): void {
    const newRequestDomain = {
      domain: (domain.domain || '') + ' copied ' + this.dateService.formatDate(new Date(), EDateFormats.dateTwo),
      regex: domain.regex,
      locationIds: domain.locations.map(location => location.id),
      anyLocation: domain.anyLocation
    };
    this.createNewRequestDomain(newRequestDomain);
    this.updateDataSource(true);
  }

  deleteRequestDomain(domain: INewRequestDomain): void {
    const indexToRemove = this.requestDomains.value.findIndex((val: ICCRequestDomainTableRow) => val.index === domain.index);
    this.requestDomains.removeAt(indexToRemove);
    this.updateDataSource();
  }

  openComfirmDeleteAllRequestDomains(): void {
    const rightFooterButtons = [
      {
        label: 'Cancel',
        action: () => {},
        primary: false
      },
      {
        label: 'Delete All Request Domains & Geolocations',
        action: () => {
          this.deleteAllRequestDomains();
        },
        primary: true
      }
    ];

    this.modalService
      .openConfirmModal({
        data: {
          title: 'Delete all request domains & geolocations?',
          messages: ['Are you sure you want to delete all request domains & geolocations from this consent category?'],
          rightFooterButtons
        }
      });
  }

  deleteAllRequestDomains(): void {
    this.requestDomains.clear();
    this.updateDataSource();
  }

  writeValue(value: IConsentCategoryRequestDomain[] | UntypedFormControl): void {
    this.requestDomains.clear();

    const domains = Array.isArray(value)
      ? value
      : value.value.requestDomains;

    domains.forEach((requestDomain: IConsentCategoryRequestDomain, index: number) => {
      this.createNewRequestDomain(requestDomain, index);
    });
  }

  createNewRequestDomain(requestDomain: IConsentCategoryRequestDomain | INewRequestDomain, index?: number): void {
    this.requestDomains.push(this.formBuilder.group({
      domain: [requestDomain.domain || ''],
      regex: [requestDomain.regex],
      locations: this.formBuilder.array(this.createLocationControls(requestDomain.locationIds)),
      anyLocation: [requestDomain.anyLocation],
      index: index ? index : this.requestDomains?.length
    }, {
      validators: [this.requestDomainValidator.bind(this)],
      updateOn: 'change'
    }));
  }

  requestDomainValidator(formGroup: UntypedFormGroup) {
    // Have to set the validator at the formGroup level for cross-control validation between the various controls
    const domainControl = formGroup.get('domain');
    const regexControl = formGroup.get('regex');
    const locationsControl = formGroup.get('locations');
    const domainOrLocationRequiredError = { domainOrLocationRequired: {}};

    const domainErrors = locationsControl?.value?.length === 0 && domainControl?.value?.length === 0 ? domainOrLocationRequiredError : regexControl?.value ? OPValidators.regex(domainControl) : OPValidators.urlWithOptionalProtocolAndOptionalLeadingPeriod(domainControl);

    domainControl.setErrors(domainErrors);
    domainControl.markAsTouched();

    return domainErrors;
  }

  createLocationControls(locationIds: number[] = []): UntypedFormControl[] {
    return locationIds.map((id: number) => {
      return this.formBuilder.control({
        id: id,
        name: this.geoData.countriesById[id].countryName,
        type: EGeoAutoCompleteType.COUNTRY
      });
    });
  }

  updateLocations(locations: ICCGeoSelectorItem[], requestDomain: IConsentCategoryRequestDomain): void {
    // get the array control for this row
    const formControl = this.requestDomains.controls.find(control => control?.value?.index == requestDomain?.index);
    const locationsArray = formControl.get('locations') as UntypedFormArray;

    // clear the old locations
    locationsArray.clear();

    // add the current locations to the control
    locations.forEach((location: ICCGeoSelectorItem) => {
      locationsArray.push(this.formBuilder.control(location));
    });

    this.onChange(this.requestDomains.value);
    this.formTouched.emit(ECCEditTabs.requestDomains);
  }

  createContinentObj(continents: IContinentMap): void {
    // ensure we have the data (not an empty object)
    if (!continents.hasOwnProperty(1)) return;

    // continents
    const continent1 = [{ // Africa
      id: continents[1][0].continentId,
      name: `${continents[1][0].continentName} (${continents[1].length} countries included)`,
      type: EGeoAutoCompleteType.CONTINENT
    }];

    const continent2 = [{ // North America
      id: continents[2][0].continentId,
      name: `${continents[2][0].continentName} (${continents[2].length} countries included)`,
      type: EGeoAutoCompleteType.CONTINENT
    }];

    const continent3 = [{ // Oceania
      id: continents[3][0].continentId,
      name: `${continents[3][0].continentName} (${continents[3].length} countries included)`,
      type: EGeoAutoCompleteType.CONTINENT
    }];

    const continent4 = [{ // Antarctica
      id: continents[4][0].continentId,
      name: `${continents[4][0].continentName} (${continents[4].length} countries included)`,
      type: EGeoAutoCompleteType.CONTINENT
    }];

    const continent5 = [{ // Asia
      id: continents[5][0].continentId,
      name: `${continents[5][0].continentName} (${continents[5].length} countries included)`,
      type: EGeoAutoCompleteType.CONTINENT
    }];

    const continent6 = [{ // Europe
      id: continents[6][0].continentId,
      name: `${continents[6][0].continentName} (${continents[6].length} countries included)`,
      type: EGeoAutoCompleteType.CONTINENT
    }];

    const continent7 = [{ // South America
      id: continents[7][0].continentId,
      name: `${continents[7][0].continentName} (${continents[7].length} countries included)`,
      type: EGeoAutoCompleteType.CONTINENT
    }];

    const allContinents = continent1
      .concat(continent2, continent3, continent4, continent5, continent6, continent7)
      .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    // countries

    let allCountries = [];

    for (const continent in continents) {
      const temp = continents[continent].map(country => {
        return {
          id: country.countryId,
          name: country.countryName,
          type: EGeoAutoCompleteType.COUNTRY
        };
      });

      allCountries = allCountries.concat(temp);
    }

    allCountries.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    this.autoCompleteList = allContinents.concat(allCountries);
  }

  updateRequestDomainsTabCount() {
    this.tabDataCount.emit({tabName: ECCEditTabs.requestDomains, newTabCount: this.requestDomains?.value.length});
  }

  validate(c: AbstractControl): ValidationErrors {
    return this.requestsAndGeosForm.valid ? null : {requestsAndGeosForm: {valid: false, message: 'Invalid request and geos form'}};
  }

  get requestDomains(): UntypedFormArray {
    return this.requestsAndGeosForm.get('requestDomains') as UntypedFormArray;
  }
}
