import { DecimalPipe, formatNumber, Location } from '@angular/common';
import {
  IAuditReportPageDetailsDrawerService,
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuditReportFilterBarService } from '../../audit-report-filter-bar/audit-report-filter-bar.service';
import { AuditReportBase, IFilterableAuditReport } from '../general-reports.models';
import {
  EAuditReportFilterTypes,
  IAuditReportFilter,
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { auditTime, catchError, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { IAuditReportApiPostBody } from '../../audit-report/audit-report.models';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import {
  IStackedBarChartDataPoint,
  ITagInventoryCategories,
  ITagInventoryPage,
  ITagInventoryPages,
  ITagInventoryPagesPagination,
  ITagInventoryPagesTableRow,
  ITagInventoryQueryParams,
  ITagInventoryRouteState,
  ITagInventorySummary,
  ITagInventoryTableRow,
  ITagInventoryTableTagAccount,
  ITagInventoryTag,
  ITagInventoryTagAccount,
  ITagInventoryTagAccounts,
  ITagInventoryTags,
  ITagInventoryTrend,
  ITagInventoryTrendByName,
  ITagInventoryTrends,
} from './tag-inventory.models';
import {
  AverageBrokenPagesChartConfig,
  AverageBrokenTagsChartConfig,
  AverageUniqueTagsChartConfig,
  ETagInventoryReportFilterType,
  ETagInventoryTrendNames,
  PagesTableColumns,
  TAG_INVENTORY_EXPORT_TYPE,
  TagInventoryRelevantFilters,
} from './tag-inventory.constants';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData,
} from '@app/components/shared/components/split-card/split-card.models';
import { TagInventoryService } from './tag-inventory.service';
import { ISparklineChartData } from '../../../shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { AuditReportLoadingService } from '../../audit-report-loading.service';
import { BehaviorSubject, forkJoin, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  AuditReportUrlBuilders,
  EStatusCodeCategories,
  formatPaginator,
  PageLoadColumnTooltip,
  TAGS_SEARCH_TEXT_KEY,
} from '../../audit-report/audit-report.constants';
import { ISparklineRunInfo } from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import {
  IFullscreenChartData,
  IFullscreenChartDataWithStats,
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  FullscreenChartModalComponent,
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import {
  EBarChartDirection,
  EBarChartTextPosition,
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.constants';
import { AuditReportService } from '../../audit-report/audit-report.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ITagInventoryCategory } from '@app/components/audit-reports/reports/tag-inventory/tag-inventory.models';
import {
  IStackedBarChartFormattedDataPoint,
} from '../../../shared/components/viz/horizontal-stacked-bar-chart/horizontal-stacked-bar-chart.models';
import { IPromise } from 'angular';
import {
  IHorizontalBarChartDataPoint,
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.models';
import { sortBy } from 'lodash-es';
import { AuditReportScrollService } from '../../audit-report-scroll.service';
import { ValueDisplayAs } from '../../percent-numeric-toggle/percent-numeric-toggle.component';
import {
  IAccountFilter,
  ITagPresenceFilter,
} from '../audit-summary/primary-tag-health-card/primary-tag-health-card.models';
import {
  IAuditReportExportMenuData,
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import { AlertMetricType, EAlertTagInventoryMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import { PageStatusCodeTooltipMap } from '../../audit-report-container.constants';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import {
  CommonPagesColumnConfigWarningMessage,
  CommonPagesConfigLocalStorageKey,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTagCategory } from '@app/components/tag-database';
import { getPercentageAofB, getPercentageOfTotal } from '@app/components/utilities/number.utils';

interface IFiltersUpdate {
  filters: IAuditReportApiPostBody
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-inventory',
  templateUrl: './tag-inventory.component.html',
  styleUrls: ['./tag-inventory.component.scss'],
  providers: [ResizeableTableService],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed, void => expanded', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class TagInventoryComponent extends AuditReportBase implements IFilterableAuditReport, OnInit, AfterViewInit, OnDestroy {
  protected readonly PageStatusCodeTooltipMap = PageStatusCodeTooltipMap;
  readonly CommonPagesColumnConfigWarningMessage = CommonPagesColumnConfigWarningMessage;
  readonly CommonPagesConfigLocalStorageKey = CommonPagesConfigLocalStorageKey;
  readonly PagesTableColumns = PagesTableColumns;
  auditId: number;
  runId: number;
  reportFilters: IAuditReportApiPostBody = {};
  initialInit: boolean = true;
  viewIsLoaded: boolean = false;
  sparklineDataLoaded: boolean = false;
  PageLoadColumnTooltip = PageLoadColumnTooltip;

  // widget variables
  widgetState: EFilterSpinnerState;
  widgetSparklineRunInfo: ISparklineRunInfo[] = [];

  // category stacked bar chart variables
  catChartData: IStackedBarChartDataPoint[] = [];
  categoryMap: { [id: number]: string } = {};
  categoryChartState: EFilterSpinnerState;

  // tags table variables
  tagsTableDataSource = new MatTableDataSource<ITagInventoryTableRow>();
  tagsTableDisplayedColumns = ['tagName', 'numAccounts', 'missingTags', 'tagPresence'];
  tagsTableState: EFilterSpinnerState = EFilterSpinnerState.Loading;
  displayAsPercentage: boolean = true;
  tags: ITagInventoryTags;
  expandedTag: ITagInventoryTableRow;
  tagAccounts: ITagInventoryTagAccount[];
  chartWidthLeft: number;
  chartWidthRight: number;
  isFiltered: boolean;
  previouslyFilteredItem: IHorizontalBarChartDataPoint;
  tagFilterTypes = ETagInventoryReportFilterType;
  onTheFlyTagPresenceFilter: ITagPresenceFilter;
  onTheFlyAccountFilter: IAccountFilter;

  // pages table variables
  pagesTableDataSource = new MatTableDataSource<ITagInventoryPagesTableRow>();
  pagesTableDisplayedColumns$ = this.tableService.displayedColumns$;
  pagesTableState: EFilterSpinnerState = EFilterSpinnerState.Loading;
  pagesScannedQueryParams: ITagInventoryQueryParams = {
    // defaults
    page: 0, // API paginates starting with 0
    size: 200,
    sortBy: CommonReportsPagesTableColumns.PageUrl,
    sortDesc: false
  };
  pageIdOpenInPageDetails: string;
  paginationState: { length: number, pageSize: number } = {
    length: 0,
    pageSize: 0
  };

  tagsMap: { [id: string]: string };

  @ViewChild('tagsTable', { read: MatSort, static: true }) tagsSort: MatSort;
  @ViewChild('pagesTable', { read: MatSort, static: true }) pagesSort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('chartWrapperLeft') chartWrapperLeft: ElementRef;
  @ViewChild('chartWrapperRight') chartWrapperRight: ElementRef;
  @ViewChild('tagsTableScrollTo', { read: ElementRef }) tagsTableScrollTo: ElementRef;
  @ViewChild('pagesTableScrollTo', { read: ElementRef }) pagesTableScrollTo: ElementRef;

  readonly stateOptions = EFilterSpinnerState;

  apiFilters: IAuditReportApiPostBody = {};
  private isLoaded: boolean;
  private filtersUpdated$: Subject<IFiltersUpdate> = new Subject();

  // pages scanned widget
  widgetPageScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeContent: null,
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    menuOptions: null,
    metricType: EAlertTagInventoryMetric.PagesScanned,
  };
  pagesFilteredCount: number = null;
  pagesTotalCount: number = null;

  // unique tags widget
  widgetUniqueTags: ISplitCardChartData = {
    topLabel: 'Unique Tags',
    topChangeContent: null,
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    bottomHandler: this.openUniqueTagsFullscreenChart.bind(this),
    includeFullscreenChart: true,
    metricType: EAlertTagInventoryMetric.UniqueTags,
  };
  widgetUniqueTagsSparklineData: ISparklineChartData[] = [];

  // broken tags widget
  widgetBrokenTags: ISplitCardChartData = {
    topLabel: 'Broken Tag Requests',
    topChangeContent: '',
    topChangeFilteredContent: '',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Count of broken tags found in this audit which have a 0, 400+, or 500+ HTTP status code.',
    topHandler: () => {
      this.filterBarService.addTagStatusCodeFilter(EStatusCodeCategories.Broken);
      this.router.navigateByUrl(AuditReportUrlBuilders.tagHealth(this.auditId, this.runId));
    },
    bottomHandler: this.openBrokenTagsFullscreenChart.bind(this),
    includeFullscreenChart: true,
    metricType: EAlertTagInventoryMetric.BrokenTags,
  };
  widgetBrokenTagsSparklineData: ISparklineChartData[] = [];

  // broken pages widget
  widgetBrokenPages: ISplitCardChartData = {
    topLabel: 'Broken Pages',
    topChangeContent: null,
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    topHandler: () => {
      if (this.filterByBrokenPages) {
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.InitialPageStatusCode);
      } else {
        this.filterBarService.addPageStatusCodeFilter(EStatusCodeCategories.Broken);
      }
    },
    bottomHandler: this.openBrokenPagesFullscreenChart.bind(this),
    includeFullscreenChart: true,
    metricType: EAlertTagInventoryMetric.BrokenPages,
  };
  widgetBrokenPagesSparklineData: ISparklineChartData[] = [];

  exportReportConfig: IAuditReportExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: TAG_INVENTORY_EXPORT_TYPE,
    totalRows: this.pagesTotalCount,
    filteredRows: this.pagesFilteredCount,
    tableState: this.pagesScannedQueryParams,
    filters: this.apiFilters,
    dataToCopy: {
      config: [
        {
          property: 'url',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl
        },
        {
          property: 'finalUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
        },
        {
          property: 'loadTime',
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime
        },
        {
          property: 'statusCode',
          tableColumnName: CommonReportsPagesTableColumns.PageStatusCode
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
        },
        {
          title: '# OF TAG REQUESTS',
          property: 'tagInstances',
          tableColumnName: CommonReportsPagesTableColumns.TagInstanceCount
        },
        {
          title: '#|% OF UNIQUE TAGS',
          displayLike: (row) => `${row.uniqueTags.count} | ${row.uniqueTags.percentage}%`,
          tableColumnName: CommonReportsPagesTableColumns.UniqueTagCount
        },
        {
          title: 'BROKEN TAGS',
          property: 'brokenTags',
          tableColumnName: CommonReportsPagesTableColumns.BrokenTagCount
        }
      ],
      displayedColumns$: this.tableService.displayedColumns$,
      data: null
    }
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  readonly TableColumn = CommonReportsPagesTableColumns;

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private filterBarService: AuditReportFilterBarService,
    private tagInventoryService: TagInventoryService,
    private auditReportLoadingService: AuditReportLoadingService,
    private modalService: OpModalService,
    private auditReportService: AuditReportService,
    private uiTagService: UiTagService,
    private modalEscapeService: ModalEscapeService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private scrollService: AuditReportScrollService,
    private decimalPipe: DecimalPipe,
    private changeDetectorRef: ChangeDetectorRef,
    private alertReportingService: AlertReportingService,
    private router: Router,
    private tableService: ResizeableTableService,
  ) {
    super();

    this.activatedRoute.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;

      const alertId = (this.activatedRoute?.queryParams as BehaviorSubject<any>)?.getValue()?.alertId;
      // When loading report from an alert, first override filters with alert
      // filters before loading report data
      if (alertId) {
        this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
          this.filterBarService.overrideFilters(alert.config.filtersV0);
          this.highlightMetricType = alert.config.metricType;

          this.alertCheckComplete$.next(true);
        });
      } else {
        this.alertCheckComplete$.next(true);
      }

      if (this.isLoaded) {
        // only fire when calendar control updated
        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          this.onFiltersChanged(this.apiFilters);
        });
      }
    });

    const state = this.location.getState() as ITagInventoryRouteState;
    if (state.tagPresenceFilter) {
      this.onTheFlyTagPresenceFilter = state.tagPresenceFilter;
    }

    if (state.accountFilter) {
      this.onTheFlyAccountFilter = state.accountFilter;
    }
  }

  get filterByBrokenPages(): boolean {
    return this.filterBarService.isFilteredByTypeAndValue(EAuditReportFilterTypes.InitialPageStatusCode, EStatusCodeCategories.Broken);
  }

  ngOnInit(): void {
    // setup for page details
    this.pageDetailsDrawerService.setDefaultPageDetailsTab(EPageDetailsTabs.Tags);
    this.pageDetailsDrawerService.pageDrawerClosed$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.handlePageDetailsClosed());

    // setup filters
    this.initFilters();
    this.createTagsMap();
    this.isLoaded = true;
  }

  ngAfterViewInit(): void {
    this.viewIsLoaded = true;

    this.tagsTableDataSource.sortingDataAccessor = (data, attribute) => {
      return attribute === this.tagsTableDisplayedColumns[0]
        ? data[attribute].toLowerCase()
        : parseInt(data[attribute]);
    };

    this.tagsTableDataSource.sort = this.tagsSort;
    this.pagesTableDataSource.sort = this.pagesSort;

    this.setTagBarsFilterState();
    this.handlePagesTable();
    this.handleOpenAccountOnTheFly();

    // format paginator
    this.formatPaginator();
    // suppresses change detection error
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.destroy();
    this.onDestroy$.next();
    this.pageDetailsDrawerService.closePageDetails();
  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(TagInventoryRelevantFilters);

    // BehaviourSubject in filterBarService means this executes immediately
    this.filterBarService.apiPostBody$
      .pipe(takeUntil(this.onDestroy$), auditTime(500))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody) {
    if (this.paginator) this.paginator.pageIndex = 0;
    this.resetQueryParams();
    this.clearReportFilters();
    this.apiFilters = apiPostBody;
    this.reportFilters = {};
    this.filtersUpdated$.next({ filters: apiPostBody });
    this.updateCurrentFilters();
    this.handleTagCategoryChart();
    this.handleWidgetsAndCharts();
    this.handleTagsTable();
    this.initialInit = false;
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  getTagCategories(): IPromise<IUiTagCategory[]> {
    return this.uiTagService.getAllTagsData().pipe(map(([tag, vendor, cat]) => cat)).toPromise();
  }

  private createTagsMap(): void {
    this.uiTagService.getAllTagsData().subscribe(([tags]) => {
      this.tagsMap = tags.reduce((acc, tag) => {
        acc[tag.id] = tag.name;
        return acc;
      }, {});
    });
  }

  resetQueryParams(): void {
    this.pagesScannedQueryParams = {
      page: 0,
      size: 200,
      sortBy: CommonReportsPagesTableColumns.PageUrl,
      sortDesc: false
    };
  }

  async handleTagCategoryChart(): Promise<void> {
    // set loading state of chart
    this.auditReportLoadingService.addLoadingToken();
    this.categoryChartState = EFilterSpinnerState.Loading;

    // only run this the first time this method fires
    if (!Object.keys(this.categoryMap).length) {
      const categories = await this.getTagCategories();

      categories.forEach((cat: IUiTagCategory) => {
        this.categoryMap[cat.id] = cat.category;
      });
    }

    const filter = this.filterBarService.currentRelevantFilters
      .find((filter: IAuditReportFilter) => filter.type === EAuditReportFilterTypes.TagCategory);

    this.tagInventoryService.getTagInventoryCategories(this.auditId, this.runId, this.apiFilters)
    .pipe(
      catchError((err: any) => of({...err, isErrorState: true })),
      tap((o) => {
        // set loading state of chart
        this.auditReportLoadingService.removeLoadingToken();
        if (o.isErrorState === true) {
          throw o;
        }
      })
    )
    .subscribe((cats: ITagInventoryCategories) => {
      this.catChartData = cats.tagCategories
        .filter((cat: ITagInventoryCategory) => cat.tagFilterStatus)
        .map((cat: ITagInventoryCategory) => {
          return {
            id: cat.tagCategoryId,
            value: cat.totalTagInstanceCount,
            name: this.categoryMap[cat.tagCategoryId],
            colorClass: `tag-cat-${cat.tagCategoryId}`,
            filtered: filter ? filter.value === cat.tagCategoryId : false
          };
        }
      );

      // set loading state of chart
      this.categoryChartState = this.determineCatChartFilterState(!!filter, this.filterBarService.currentRelevantFilters);
    });
  }

  determineCatChartFilterState(hasCatFilter: boolean, currentFilters: IAuditReportFilter[]) {
    if (hasCatFilter && currentFilters.length === 1) {
      return EFilterSpinnerState.None;
    } else if (hasCatFilter && currentFilters.length > 1) {
      return EFilterSpinnerState.Filtered;
    } else if (!hasCatFilter && currentFilters.length > 0) {
      return EFilterSpinnerState.Filtered;
    } else {
      return EFilterSpinnerState.None;
    }
  }

  toggleCategoryFilter({ mouseEvent, item }: { mouseEvent: MouseEvent, item: IStackedBarChartFormattedDataPoint }): void {
    const filter = this.filterBarService.currentRelevantFilters
      .find((filter: IAuditReportFilter) => filter.type === EAuditReportFilterTypes.TagCategory);

    item.filtered
      ? this.filterBarService.addTagCategoryFilter(item.tooltipDisplayName, item.id)
      : this.filterBarService.removeFilter(filter);
  }

  handleWidgetsAndCharts(): void {
    this.auditReportLoadingService.addLoadingToken();
    this.widgetState = EFilterSpinnerState.Loading;
    this.sparklineDataLoaded = false;

    forkJoin([
      this.tagInventoryService
        .getTagInventorySummary(this.auditId, this.runId, this.apiFilters)
        .pipe(catchError(() => of(undefined))),
      this.tagInventoryService
        .getTagInventoryTrends(this.auditId, this.runId)
        .pipe(
          map((data: ITagInventoryTrends) => sortBy(data.runs, (run: ITagInventoryTrend) => run.runId)),
          catchError(() => of(undefined))
        )
    ])
    .pipe(
      catchError((err: any) => of({...err, isErrorState: true })),
      tap((o) => {
        // set loading state of chart
        this.auditReportLoadingService.removeLoadingToken();
        this.widgetState = EFilterSpinnerState.None;
        if (o.isErrorState === true) {
          throw o;
        }
      })
    )
    .subscribe(([summary, trends]: [ITagInventorySummary, ITagInventoryTrend[]]) => {
        // scanned pages totals
        this.exportReportConfig.totalRows = this.pagesTotalCount = summary.totalPageCount;
        this.exportReportConfig.filteredRows = this.pagesFilteredCount = summary.filteredPageCount;

        const uniqueTags = [];
        const brokenTags = [];
        const brokenPages = [];
        const runInfo: ISparklineRunInfo[] = [];

        this.widgetUniqueTags.topChangeContent = formatNumber(summary.filteredUniqueTagCount || 0, 'en-us');
        this.widgetBrokenTags.topChangeContent = formatNumber(summary.filteredBrokenTagCount || 0, 'en-us');
        this.widgetBrokenTags.topChangeFilteredContent = formatNumber(summary.totalTagInstanceCount, 'en-us');
        this.widgetBrokenPages.topChangeContent = formatNumber(summary.filteredBrokenPageCount || 0, 'en-us');

        // update broken pages meaning
        summary.filteredBrokenPageCount > 0
          ? this.widgetBrokenPages.topChangeMeaning = ESplitCardChangeMeaning.NEGATIVE
          : this.widgetBrokenPages.topChangeMeaning = ESplitCardChangeMeaning.NEUTRAL;

        trends.forEach((run, index: number) => {
          uniqueTags.push({ value: run.totalUniqueTagCount, sequence: index });
          brokenTags.push({ value: run.totalBrokenTagCount, sequence: index });
          brokenPages.push({ value: run.totalBrokenPageCount, sequence: index });
          runInfo.push({ runId: run.runId, runCompletionDate: run.completedAt });
        });

        this.widgetUniqueTagsSparklineData = uniqueTags;
        this.widgetBrokenTagsSparklineData = brokenTags;
        this.widgetBrokenPagesSparklineData = brokenPages;
        this.widgetSparklineRunInfo = runInfo;

        // update loading states
        this.sparklineDataLoaded = true;
        this.widgetState = this.filterBarService.currentRelevantFilters.length
          ? EFilterSpinnerState.Filtered
          : EFilterSpinnerState.None;
      });
  }

  openUniqueTagsFullscreenChart() {
    if (this.widgetBrokenPagesSparklineData.length > 1) {
      this.openFullscreenChart(
        ETagInventoryTrendNames.UniqueTags,
        this.widgetSparklineRunInfo.length > 1 ? this.widgetSparklineRunInfo[this.widgetSparklineRunInfo.length - 2].runCompletionDate : undefined);
    }
  }

  openBrokenTagsFullscreenChart() {
    if (this.widgetBrokenPagesSparklineData.length > 1) {
      this.openFullscreenChart(
        ETagInventoryTrendNames.BrokenTags,
        this.widgetSparklineRunInfo.length > 1 ? this.widgetSparklineRunInfo[this.widgetSparklineRunInfo.length - 2].runCompletionDate : undefined);
    }
  }

  openBrokenPagesFullscreenChart() {
    if (this.widgetBrokenPagesSparklineData.length > 1) {
      this.openFullscreenChart(
        ETagInventoryTrendNames.BrokenPages,
        this.widgetSparklineRunInfo.length > 1 ? this.widgetSparklineRunInfo[this.widgetSparklineRunInfo.length - 2].runCompletionDate : undefined);
    }
  }

  openFullscreenChart(
    trendName: ETagInventoryTrendNames,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName)
      }
    })
    .afterClosed()
    .subscribe(() => this.modalEscapeService.remove(index));
  }

  getFullscreenChartData(trendName: ETagInventoryTrendNames, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.tagInventoryService
      .getTagInventoryTrendByName(this.auditId, trendName, days)
      .pipe(
        map((trendData: ITagInventoryTrendByName) => {
          return {
            chartData: trendData.runs.map((dataPoint: ITagInventoryTrend) => {
              return {
                value: +dataPoint.trendValue,
                date: dataPoint.completedAt
              };
            })
          };
        }),
      );
  }

  getFullscreenChartConfig(trendName: ETagInventoryTrendNames) {
    switch (trendName) {
      case ETagInventoryTrendNames.UniqueTags:
        return AverageUniqueTagsChartConfig;
      case ETagInventoryTrendNames.BrokenTags:
        return AverageBrokenTagsChartConfig;
      case ETagInventoryTrendNames.BrokenPages:
        return AverageBrokenPagesChartConfig;
      default:
        return null;
    }
  }

  handleTagsTable(): void {
    // update loading states
    this.auditReportLoadingService.addLoadingToken();
    this.tagsTableState = EFilterSpinnerState.Loading;

    this.tagInventoryService
      .getTagInventoryTags(this.auditId, this.runId, this.apiFilters)
      .pipe(
        catchError((err: any) => of({...err, isErrorState: true })),
        tap((o) => {
          // set loading state of chart
          this.auditReportLoadingService.removeLoadingToken();
          if (o.isErrorState === true) {
            throw o;
          }
        })
      )
      .subscribe((tags: ITagInventoryTags) => {
        this.tags = tags;
        this.tags.tags.sort((a: any, b: any) => (a.tagPresentPageCount > b.tagPresentPageCount) ? -1 : 1);
        this.formatDataForTable();
        this.setTagBarsFilterState();

        // update loading states
        this.tagsTableState = this.filterBarService.currentRelevantFilters.length
          ? EFilterSpinnerState.Filtered
          : EFilterSpinnerState.None;
      });
  }

  formatDataForTable(): void {
    this.tagsTableDataSource.data = this.tags.tags.map((tag: ITagInventoryTag): ITagInventoryTableRow => {
      let pagesMissingTagFiltered: boolean = false;
      let pagesWithTagFiltered: boolean = false;

      if (this.onTheFlyTagPresenceFilter?.tagId === tag.tagId) {
        pagesMissingTagFiltered = this.onTheFlyTagPresenceFilter.missingTags;
        pagesWithTagFiltered = !this.onTheFlyTagPresenceFilter.missingTags;
      }

      const { aOfTotal, bOfTotal } = getPercentageOfTotal(tag.tagAbsentPageCount, tag.tagPresentPageCount);

      return {
        tagName: tag.tagName,
        tagIcon: UiTagService.getTagIconUrl(tag.tagId),
        tagId: tag.tagId,
        tagCategoryId: tag.tagCategoryId,
        numAccounts: tag.filteredTagAccountCount,
        missingTags: this.displayAsPercentage
          ? aOfTotal
          : tag.tagAbsentPageCount,
        tagPresence: this.displayAsPercentage
          ? bOfTotal
          : tag.tagPresentPageCount,
        expanded: false,
        barChartSettings: {
          state: EFilterSpinnerState.None,
          displayPills: false,
          calcAsPercentage: true,
          displayPercentSymbol: this.displayAsPercentage,
          textPosition: EBarChartTextPosition.Start
        },
        pagesMissingTag: {
          chartData: [{
            name: `${tag.tagName}`,
            colorClass: `tag-cat-${tag.tagCategoryId}`,
            filtered: pagesMissingTagFiltered,
            value: aOfTotal,
            displayValue: this.displayAsPercentage ? null : tag.tagAbsentPageCount
          }],
          barDirection: EBarChartDirection.RTL,
          uniqueIdentifier: `tag-id-${tag.tagId}-chart-missing`,
        },
        pagesWithTag: {
          chartData: [{
            name: `${tag.tagName}`,
            colorClass: `tag-cat-${tag.tagCategoryId}`,
            filtered: pagesWithTagFiltered,
            value: bOfTotal,
            displayValue: this.displayAsPercentage ? null : tag.tagPresentPageCount
          }],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `tag-id-${tag.tagId}-chart-present`,
        }
      };
    });

    this.handleTagPresenceOnTheFly();
    this.handleOpenAccountOnTheFly();
  }

  percentNumericToggleChanged(displayAs: ValueDisplayAs) {
    if (displayAs === ValueDisplayAs.Percentage) {
      this.displayBarsAsPercentage();
    } else if (displayAs === ValueDisplayAs.Count) {
      this.displayBarsAsCount();
    }
  }

  displayBarsAsPercentage(): void {
    this.displayAsPercentage = true;

    this.tagsTableDataSource.data = this.tagsTableDataSource.data.map(row => {
      const tag = this.tags.tags.find(tag => tag.tagId === row.tagId);

      const { aOfTotal, bOfTotal } = getPercentageOfTotal(tag.tagAbsentPageCount, tag.tagPresentPageCount);

      row.missingTags = aOfTotal;
      row.tagPresence = bOfTotal;
      row.barChartSettings.displayPercentSymbol = true;
      row.pagesMissingTag.chartData[0].displayValue = null;
      row.pagesWithTag.chartData[0].displayValue = null;

      row.tagAccounts?.map((account: ITagInventoryTableTagAccount) => {
        account.barChartSettings.displayPercentSymbol = true;
        account.pagesMissingAccount.chartData[0].displayValue = null;
        account.pagesWithAccount.chartData[0].displayValue = null;
      });

      return row;
    });
  }

  displayBarsAsCount(): void {
    this.displayAsPercentage = false;

    this.tagsTableDataSource.data = this.tagsTableDataSource.data.map(row => {
      const tag = this.tags.tags.find(tag => tag.tagId === row.tagId);

      row.missingTags = tag.tagAbsentPageCount;
      row.tagPresence = tag.tagPresentPageCount;
      row.barChartSettings.displayPercentSymbol = false;
      row.pagesMissingTag.chartData[0].displayValue = tag.tagAbsentPageCount;
      row.pagesWithTag.chartData[0].displayValue = tag.tagPresentPageCount;

      row.tagAccounts?.map((account: ITagInventoryTableTagAccount) => {
        account.barChartSettings.displayPercentSymbol = false;
        account.pagesMissingAccount.chartData[0].displayValue = account.numPagesMissingAccount;
        account.pagesWithAccount.chartData[0].displayValue = account.numPagesWithAccount;
      });

      return row;
    });
  }

  expandTagRow(row: ITagInventoryTableRow): void {
    row.expanded = !row.expanded;
    if (this.chartWrapperLeft?.nativeElement) {
      this.chartWidthLeft = this.chartWrapperLeft.nativeElement.offsetWidth - 6;
    }
    if (this.chartWrapperRight?.nativeElement) {
      this.chartWidthRight = this.chartWrapperRight.nativeElement.offsetWidth - 6;
    }

    // if row is being expanded and we need to go get the tag accounts
    if (!row.tagAccounts?.length) {
      this.tagInventoryService
        .getTagInventoryTagAccounts(this.auditId, this.runId, row.tagId, this.apiFilters)
        .subscribe((accounts: ITagInventoryTagAccounts) => {
          row.tagAccounts = this.formatChartDataForTagAccounts(row, accounts);
        }
      );
    } else if (!row.expanded) {
      // if the row being closed has a bar chart filtered
      if (this.rowIsFiltered(row)) {
        this.previouslyFilteredItem.filtered = false;
        this.toggleReportFilter(row);
      }
    }
  }

  rowIsFiltered(row: ITagInventoryTableRow): boolean {
    const tagIsFiltered = row.pagesMissingTag.chartData[0].filtered || row.pagesWithTag.chartData[0].filtered;
    const accountMissingIsFiltered = row.tagAccounts?.find(accountRow => accountRow.pagesMissingAccount.chartData[0].filtered);
    const accountPresentIsFiltered = row.tagAccounts?.find(accountRow => accountRow.pagesWithAccount.chartData[0].filtered);

    return !!tagIsFiltered || !!accountMissingIsFiltered || !!accountPresentIsFiltered;
  }

  formatChartDataForTagAccounts(tag: ITagInventoryTableRow, accounts: ITagInventoryTagAccounts): ITagInventoryTableTagAccount[] {
    return accounts.tagAccounts.map((account: ITagInventoryTagAccount): ITagInventoryTableTagAccount => {
      const accountName = account.tagAccount.replace(/[\W_]+/g, '').toLowerCase();
      const { aOfTotal, bOfTotal } = getPercentageOfTotal(account.tagAbsentPageCount, account.tagPresentPageCount);

      return {
        name: account.tagAccount,
        tagId: tag.tagId,
        tagName: tag.tagName,
        numPagesMissingAccount: account.tagAbsentPageCount,
        numPagesWithAccount: account.tagPresentPageCount,
        barChartSettings: {
          state: EFilterSpinnerState.None,
          displayPills: false,
          calcAsPercentage: true,
          displayPercentSymbol: this.displayAsPercentage,
          textPosition: EBarChartTextPosition.Start
        },
        pagesMissingAccount: {
          chartData: [{
            name: `${tag.tagId}-${account.tagAccount}`,
            colorClass: `tag-cat-${tag.tagCategoryId}`,
            filtered: false,
            value: aOfTotal,
            displayValue: this.displayAsPercentage ? null : account.tagAbsentPageCount
          }],
          barDirection: EBarChartDirection.RTL,
          uniqueIdentifier: `account-${tag.tagId}-${accountName}-chart-missing`,
        },
        pagesWithAccount: {
          chartData: [{
            name: `${tag.tagId}-${account.tagAccount}`,
            colorClass: `tag-cat-${tag.tagCategoryId}`,
            filtered: false,
            value: bOfTotal,
            displayValue: this.displayAsPercentage ? null : account.tagPresentPageCount
          }],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `account-${tag.tagId}-${accountName}-chart-present`,
        }
      };
    });
  }

  addGlobalTagFilter(tag: ITagInventoryTableRow): void {
    this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);
  }

  addGlobalAccountFilter(account: ITagInventoryTableTagAccount): void {
    this.filterBarService.addTagIdFilter(account.tagName, account.tagId);
    this.filterBarService.addTagAccountFilter(account.name);
  }

  toggleReportFilter(
    row: ITagInventoryTableRow | ITagInventoryTableTagAccount,
    filterType?: ETagInventoryReportFilterType,
    event?: { mouseEvent: MouseEvent, item: IHorizontalBarChartDataPoint }
  ): void {
    if (event) event.mouseEvent.stopPropagation();

    // update filtered/dimmed states for bars
    this.handleBarFilterState(event?.item || this.previouslyFilteredItem);
    this.setTagBarsFilterState();

    // scroll page so tags table is at the top of the viewport (if filtered)
    if (this.isFiltered) this.scrollToTagsTable();

    // create report filters
    this.createReportFilters(row, filterType);

    this.resetPaginator(0);

    // make api request for pages table
    this.getPagesTableData();
    this.scrollToTopOfPagesTable();
  }

  private cleanupFilters(filters: IAuditReportApiPostBody): IAuditReportApiPostBody {
    const filtersCopy = { ...filters };
    if (filtersCopy.pagesWithoutTag) {
      // ETagInventoryReportFilterType.PagesWithoutTag filter is not compatible with
      // EAuditReportFilterTypes.[TagId, TagCategoryId, TagVendorId] filters simultaneously
      // so we need to remove the incompatible filters from the filter bar
      if (filtersCopy.tagId) {
        delete filtersCopy.tagId;
      }
      if (filtersCopy.tagCategoryId) {
        delete filtersCopy.tagCategoryId;
      }
      if (filters.tagVendorId) {
        delete filtersCopy.tagVendorId;
      }
    }
    return filtersCopy;
  }

  createReportFilters(
    row: ITagInventoryTableRow | ITagInventoryTableTagAccount,
    filterType?: ETagInventoryReportFilterType
  ): void {
    // clear report filter object
    this.reportFilters = { ...this.apiFilters };

    // update report filter object if table is filtered
    if (this.isFiltered) {
      if (filterType === ETagInventoryReportFilterType.PagesWithoutTag) {
        this.reportFilters.pagesWithoutTag = { tagId: row.tagId };
      } else if (filterType === ETagInventoryReportFilterType.TagPresent) {
        this.reportFilters.tagId = row.tagId;
      }

      if (filterType === ETagInventoryReportFilterType.AccountMissing) {
        this.reportFilters.pagesWithoutTag = {
          tagId: row.tagId,
          tagAccount: (row as ITagInventoryTableTagAccount).name
        };
      }

      if (filterType === ETagInventoryReportFilterType.AccountPresent) {
        this.reportFilters.tagId = row.tagId;
        this.reportFilters.tagAccount = (row as ITagInventoryTableTagAccount).name;
      }

      this.reportFilters = this.cleanupFilters(this.reportFilters);
    }
  }

  getPagesTableData(): void {
    // update loading states
    this.auditReportLoadingService.addLoadingToken();
    this.pagesTableState = EFilterSpinnerState.Loading;

    this.tagInventoryService.getTagInventoryPages(
      this.auditId,
      this.runId,
      this.pagesScannedQueryParams,
      this.reportFilters
    )
    .pipe(
      catchError((err: any) => of({...err, isErrorState: true })),
      tap((o) => {
        // set loading state of chart
        this.auditReportLoadingService.removeLoadingToken();
        this.pagesTableState = EFilterSpinnerState.None;
        if (o.isErrorState === true) {
          throw o;
        }
      })
    )
    .subscribe((response: ITagInventoryPages) => {
      this.initPagesTable(response.pages);
      this.setPaginationData(response.metadata.pagination);

      // update loading states
      this.pagesTableState = this.filterBarService.currentRelevantFilters.length || Object.keys(this.reportFilters).length
        ? EFilterSpinnerState.Filtered
        : EFilterSpinnerState.None;

      this.updateExportButtonConfig();
    });
  }

  handleBarFilterState(item: IHorizontalBarChartDataPoint): void {
    if (this.previouslyFilteredItem) {
      const prevItem = `${this.previouslyFilteredItem.name}${this.previouslyFilteredItem.value}`;
      const curItem = `${item.name}${item.value}`;

      if (prevItem !== curItem) this.previouslyFilteredItem.filtered = false;
    }

    this.previouslyFilteredItem = item;
  }

  setTagBarsFilterState(): void {
    // on the fly filter
    const onTheFlyFilter = !!this.onTheFlyTagPresenceFilter;

    // tags
    const tagMissingIsFiltered = !!this.tagsTableDataSource.data.find(row => row.pagesMissingTag.chartData[0].filtered);
    const tagPresentIsFiltered = !!this.tagsTableDataSource.data.find(row => row.pagesWithTag.chartData[0].filtered);

    // accounts
    const accountMissingIsFiltered = !!this.tagsTableDataSource.data.find(
      row => row.tagAccounts?.find(
        accountRow => accountRow.pagesMissingAccount.chartData[0].filtered
      )
    );
    const accountPresentIsFiltered = !!this.tagsTableDataSource.data.find(
      row => row.tagAccounts?.find(
        accountRow => accountRow.pagesWithAccount.chartData[0].filtered
      )
    );

    this.isFiltered = onTheFlyFilter || tagMissingIsFiltered || tagPresentIsFiltered || accountMissingIsFiltered || accountPresentIsFiltered;
  }

  handlePagesTable(): void {
    merge(
      this.pagesSort.sortChange,
      this.paginator.page,
      this.filtersUpdated$
    )
    .pipe(
      auditTime(500),
      tap(() => {
        // update loading states each time filters are updated
        this.auditReportLoadingService.addLoadingToken();
        this.pagesTableState = EFilterSpinnerState.Loading;
      }),
      mergeMap((mergedEvent: Sort & PageEvent & IFiltersUpdate) => {
        if (mergedEvent !== undefined) {
          if (typeof mergedEvent.pageIndex === 'number') {
            this.resetPaginator(mergedEvent.pageIndex);
            this.scrollToTopOfPagesTable();
          }
          if (mergedEvent.active) {
            this.pagesScannedQueryParams.sortBy = mergedEvent.active as CommonReportsPagesTableColumns;
            if (mergedEvent.direction) this.pagesScannedQueryParams.sortDesc = mergedEvent.direction === 'desc';
            this.resetPaginator(0);
          }
          if (mergedEvent.filters) {
            this.resetPaginator(0);
          }
        }

        if (this.onTheFlyTagPresenceFilter) {
          const filterType = this.onTheFlyTagPresenceFilter?.missingTags
            ? ETagInventoryReportFilterType.PagesWithoutTag
            : ETagInventoryReportFilterType.TagPresent;

          this.createReportFilters({ tagId: this.onTheFlyTagPresenceFilter.tagId } as ITagInventoryTableRow, filterType);
        }

        const filters = Object.keys(this.reportFilters).length
          ? this.reportFilters
          : this.apiFilters;

        return this.tagInventoryService.getTagInventoryPages(this.auditId, this.runId, this.pagesScannedQueryParams, filters);
      }),
      catchError(() => {
        this.pagesTableState = EFilterSpinnerState.None;
        return of({
          content: [],
          totalElements: 0
        });
      })
    )
    .pipe(
      catchError((err: any) => of({...err, isErrorState: true })),
      tap((o) => {
        // set loading state of chart
        this.auditReportLoadingService.removeLoadingToken();
        this.pagesTableState = EFilterSpinnerState.None;
        if (o.isErrorState === true) {
          throw o;
        }
      })
    )
    .subscribe((response: ITagInventoryPages) => {
      this.initPagesTable(response.pages);
      this.setPaginationData(response.metadata.pagination);

      // update loading states
      this.pagesTableState = this.filterBarService.currentRelevantFilters.length
        ? EFilterSpinnerState.Filtered
        : EFilterSpinnerState.None;

      this.updateExportButtonConfig();

      // Those streams at the top aren't being wrapped in the change detection.
      // This manual change detection is updating config of export menu to
      this.changeDetectorRef.detectChanges();
    });
  }

  initPagesTable(pages: ITagInventoryPage[]) {
    this.exportReportConfig.dataToCopy.data = this.pagesTableDataSource.data = pages.map((page: ITagInventoryPage): ITagInventoryPagesTableRow => {
      const loadTime: number = parseFloat((page.pageLoadTime / 1000).toFixed(1));

      return {
        id: page.pageId,
        url: page.pageUrl,
        finalUrl: page.finalPageUrl,
        loadTime: loadTime,
        loadTimeClass: this.auditReportService.getLoadTimeClassForSeconds(loadTime),
        statusCode: page.pageStatusCode,
        finalPageStatusCode: page.finalPageStatusCode,
        statusCodeClass: this.auditReportService.getStatusCodeClass(page.finalPageStatusCode),
        tagInstances: page.tagInstanceCount,
        uniqueTags: {
          count: page.uniqueTagCount,
          percentage: !page.uniqueTagCount ? 0 : getPercentageAofB(page.uniqueTagCount, page.tagInstanceCount)
        },
        brokenTags: page.brokenTagCount,
        brokenTagsClass: page.brokenTagCount > 0 ? 'has-broken-tags' : 'no-broken-tags'
      };
    });
  }

  setPaginationData(pagination: ITagInventoryPagesPagination) {
    this.paginationState.length = pagination.totalCount;
    this.paginationState.pageSize = pagination.pageSize;
  }

  scrollToTagsTable(): void {
    // scrolls to the top of the tags table when user filters by tag or account
    this.scrollService.scrollByElement(this.tagsTableScrollTo.nativeElement);
  }

  scrollToTopOfPagesTable(): void {
    // scrolls to the first row when the paginator fires
    this.scrollService.scrollByElement(this.pagesTableScrollTo.nativeElement);
  }

  openPageDetails(page: ITagInventoryPagesTableRow): void {
    this.pageIdOpenInPageDetails = page.id;

    const state = {
      [TAGS_SEARCH_TEXT_KEY]: this.tagsMap[this.reportFilters?.tagId] || this.tagsMap[this.apiFilters?.tagId] || ''
    };

    this.pageDetailsDrawerService.openPageDetails({ id: page.id, url: page.url }, this.auditId, this.runId, EPageDetailsTabs.Tags, null, state);
  }

  handlePageDetailsClosed(): void {
    this.pageIdOpenInPageDetails = null;
  }

  clearReportFilters(): void {
    if (this.initialInit) return;

    if (this.previouslyFilteredItem) this.previouslyFilteredItem.filtered = false;
    if (this.onTheFlyTagPresenceFilter) this.onTheFlyTagPresenceFilter = undefined;
    this.setTagBarsFilterState();
  }

  get reportFiltersLength() {
    return Object.values(this.reportFilters).length;
  }
  // 'on the fly' means a report level filter (not global)
  // is being applied when this report is initialized
  handleTagPresenceOnTheFly(): void {
    if (this.onTheFlyTagPresenceFilter) {
      const tableRow = this.tagsTableDataSource.data
        .find((row: ITagInventoryTableRow) => row.tagId === this.onTheFlyTagPresenceFilter.tagId);

      if (tableRow !== undefined) {
        const item = this.onTheFlyTagPresenceFilter.missingTags
          ? tableRow.pagesMissingTag.chartData[0]
          : tableRow.pagesWithTag.chartData[0];

        this.handleBarFilterState(item);
      }

      // clear filter so subsequent interactions work normally
      this.onTheFlyTagPresenceFilter = undefined;
    }
  }

  // 'on the fly' means a report level filter (not global)
  // is being applied when this report is initialized
  handleOpenAccountOnTheFly(): void {
    if (this.onTheFlyAccountFilter && this.viewIsLoaded && this.tagsTableDataSource.data.length) {
      const row = this.tagsTableDataSource.data.find(row => row.tagId === this.onTheFlyAccountFilter.tagId);
      setTimeout(() => this.expandTagRow(row));

      // clear filter so subsequent interactions work normally
      this.onTheFlyAccountFilter = undefined;
    }
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    if (this.chartWrapperLeft?.nativeElement) {
      this.chartWidthLeft = this.chartWrapperLeft.nativeElement.offsetWidth - 6;
    }
    if (this.chartWrapperRight?.nativeElement) {
      this.chartWidthRight = this.chartWrapperRight.nativeElement.offsetWidth - 6;
    }
  }

  private resetPaginator(pageNumber?: number): void {
    const p = pageNumber || 0;
    this.pagesScannedQueryParams.page = p;
    this.paginator.pageIndex = p;
  }

  private formatPaginator(): void {
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }

  private updateExportButtonConfig() {
    this.exportReportConfig.tableState = {
      ...this.exportReportConfig.tableState,
      page: this.paginator.pageIndex,
      sortBy: this.pagesSort.active,
      sortDesc: this.pagesSort.direction === 'desc'
    };

    // If there is selected row from tag-categories
    if (this.reportFiltersLength) {
      this.exportReportConfig.filters = this.reportFilters;
      this.exportReportConfig.filteredRows = this.paginationState.length;
    } else {
      this.exportReportConfig.filters = this.apiFilters;
      this.exportReportConfig.filteredRows = this.pagesFilteredCount;
    }
  }
}
