import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import {
  ENotificationCenterTargetItemType,
  ETargetItemsSortColumns,
  INotificationCenterSearchTargetItemsResponse,
  INotificationCenterTargetItem,
  NotificationCenterTargetItemTypes,
} from '@app/components/notifications-center/notification-center.models';
import {
  NotificationCenterModalFilterBarService
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal-filter-bar.service';
import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
  ISimpleTableColumn
} from '@app/components/shared/components/selectable-table/simple-table/simple-table.models';
import {
  ESimpleTableColumnType
} from '@app/components/shared/components/selectable-table/simple-table/simple-table.enums';
import {
  EUpdateOperationType,
  NotificationCenterService
} from '@app/components/notifications-center/notification-center.service';
import { RulesService } from '@app/components/rules/rules.service';
import {
  DefaultNotificationCenterAssignmentsPagination,
  DefaultNotificationCenterAssignmentsSorting
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.constants';
import {
  INotificationCenterCurrentState,
  INotificationCenterCurrentStateItems,
  INotificationProfileSetupModalData,
  INotificationProfileSetupModalResult,
  INotificationProfileSetupModalResultDiffItem
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.models';
import {
  ISelectableTableSelectionChange
} from '@app/components/shared/components/selectable-table/selectable-table.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
  ENotificationCenterModalFilterTypes,
  ENotificationCenterSetupMode,
  ENotificationCenterTabs
} from '@app/components/notifications-center/notification-center-modal/notification-center-modal.enums';
import { ESortDirection } from '@app/components/utilities/arrayUtils.enums';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

const ITEM_TYPE = ENotificationCenterTargetItemType;
const SELECT_ITEMS_LIMIT = 1000;

@Component({
  selector: 'op-notification-center-modal',
  templateUrl: './notification-center-modal.component.html',
  styleUrls: ['./notification-center-modal.component.scss']
})
export class NotificationCenterModalComponent implements OnInit, OnDestroy {
  SELECT_ITEMS_LIMIT = SELECT_ITEMS_LIMIT;
  ENotificationCenterTabs = ENotificationCenterTabs;
  ENotificationCenterTargetItemType = ENotificationCenterTargetItemType;

  destroy = new Subject<void>();

  hidePageMatching: boolean;
  modalTitle: string;

  rightFooterButtons: IButton[] = [
    {
      label: 'Back',
      action: () => this.prev(),
      disabled: false,
      hidden: true,
      primary: false
    },
    {
      label: 'Next',
      action: () => this.next(),
      disabled: true,
      hidden: false,
      primary: true
    }
  ];

  currentState: INotificationCenterCurrentState;
  showSideNav: boolean = true;
  title: string = 'ASSIGN EMAIL ADDRESSES TO ITEMS';

  filterBarMenuItems = [
    {
      name: 'Labels',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'Label Search',
          type: EFilterBarMenuTypes.Search,
          searchPlaceholder: 'Search for label',
          action: (event: KeyboardEvent, el?: HTMLElement) => this.handleSearch(true, event, el),
          children: []
        }
      ]
    },
  ];
  validFilterTypes: EAuditReportFilterTypes[] = [];
  itemsToSelect = {
    items: [],
    loading: true
  };
  labels: ILabel[] = [];

  readonly columns: {
    [key in ENotificationCenterTargetItemType]: ISimpleTableColumn<any>[]
  } = {
    [ENotificationCenterTargetItemType.INBOX]:[{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Alert Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Alert Name',
      type: ESimpleTableColumnType.Text
    }],
    [ENotificationCenterTargetItemType.ALERT]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Alert Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.AUDIT]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Audit Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.WEB_JOURNEY]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Web Journey Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLastRunAt',
      sortKey: ETargetItemsSortColumns.runDate,
      title: 'Run Date',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.USAGE_ALERT]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Usage Alert Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLastRunAt',
      sortKey: ETargetItemsSortColumns.runDate,
      title: 'Run Date',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.RULE]: [{
      propName: 'itemName',
      sortKey: ETargetItemsSortColumns.itemName,
      title: 'Rule Name',
      type: ESimpleTableColumnType.Text
    }, {
      propName: 'itemLabels',
      title: 'Labels',
      type: ESimpleTableColumnType.Chips
    }],
    [ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED]: [
      {
        propName: 'itemName',
        sortKey: ETargetItemsSortColumns.itemName,
        title: 'Inbox name',
        type: ESimpleTableColumnType.Text
      },
      {
        propName: 'itemLabels',
        title: 'Labels',
        type: ESimpleTableColumnType.Chips
      }
    ],
    [ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED]: [
      {
        propName: 'itemName',
        sortKey: ETargetItemsSortColumns.itemName,
        title: 'Inbox name',
        type: ESimpleTableColumnType.Text
      },
      {
        propName: 'itemLabels',
        title: 'Labels',
        type: ESimpleTableColumnType.Chips
      }
    ],
  };

  tab = ENotificationCenterTabs.Emails;
  itemType = ENotificationCenterTargetItemType.ALERT;
  displaySelectedTable: {[itemType in ENotificationCenterTargetItemType]?: boolean} = {};
  emails: string[];

  labels$ = new BehaviorSubject<ILabel[]>(null);
  labelById: Map<number, ILabel>;
  isAppliedFilters: boolean;

  selectionChangesMade: boolean = false;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.totalItems || this.emails.length) {
      $event.preventDefault();
      $event.returnValue = true;
    }
  }

  constructor(public dialogRef: MatDialogRef<NotificationCenterModalComponent>,
              public filterBarService: NotificationCenterModalFilterBarService,
              public rulesService: RulesService,
              private labelService: LabelService,
              private notificationCenterService: NotificationCenterService,
              @Inject(MAT_DIALOG_DATA) public modalData: INotificationProfileSetupModalData) {
    this.currentState = {
      filters: {
        // when email is not specified, to workaround API validation
        // sending a space sign to the API to get all the target items
        email: ' '
      },
      items: NotificationCenterTargetItemTypes.reduce((acc, t) => {
        acc[t] = this.initDefaultState();
        return acc;
      }, {})
    };

    // visually switching tab in constructor to avoid UI blinking, because of async ngOnInit
    if (modalData.profile) {
      this.emails = [this.modalData.profile.email];
      this.switchToTab(ENotificationCenterTabs.Items);

      this.currentState.items[ITEM_TYPE.ALERT].initialSelectedCount = this.modalData.profile.usage?.alertCount;
      this.currentState.items[ITEM_TYPE.USAGE_ALERT].initialSelectedCount = this.modalData.profile.usage?.usageAlertCount;
      this.currentState.items[ITEM_TYPE.RULE].initialSelectedCount = this.modalData.profile.usage?.ruleCount;
      this.currentState.items[ITEM_TYPE.AUDIT].initialSelectedCount = this.modalData.profile.usage?.auditCount;
      this.currentState.items[ITEM_TYPE.WEB_JOURNEY].initialSelectedCount = this.modalData.profile.usage?.webJourneyCount;
      this.currentState.items[ITEM_TYPE.EMAIL_INBOX_MESSAGE_PROCESSED].initialSelectedCount = this.modalData.profile.usage?.emailInboxMessageProcessedCount;
      this.currentState.items[ITEM_TYPE.EMAIL_INBOX_MESSAGE_RECEIVED].initialSelectedCount = this.modalData.profile.usage?.emailInboxMessageReceivedCount;
    }

    if (modalData.initialTab) {
      this.itemType = modalData.initialTab;
    }

    if (modalData.showSideNav !== undefined) {
      this.showSideNav = modalData.showSideNav;
    }

    if (modalData.title) {
      this.title = modalData.title;
    }
  }

  async ngOnInit() {
    await this.getLabels();
    this.changeItemType(this.itemType);

    this.filterBarService
      .updateSupportedFiltersList([
        ENotificationCenterModalFilterTypes.Name,
        ENotificationCenterModalFilterTypes.Label
      ]);

    if (this.modalData.profile) {
      this.loadAssignedItems();
    }

    this.filterBarService
      .filters$
      .pipe(takeUntil(this.destroy))
      .subscribe((filters) => {
        this.currentState.filters = filters.reduce((formattedFilters, filter) => {
          const filterType = filter.type;
          const filterValue = filter.value;

          if (filterType === ENotificationCenterModalFilterTypes.Label) {

            if (formattedFilters[filterType]) {
              formattedFilters[filterType] = [...formattedFilters[filterType], filterValue];
            } else {
              formattedFilters[filterType] = [filterValue];
            }

          } else {
            formattedFilters[filterType] = filterValue;
          }

          return formattedFilters;
        }, {
          email: this.currentState.filters.email
        });

        this.isAppliedFilters = Object.keys(this.currentState.filters).length > 1;

        this.currentState.items[this.itemType].pagination.currentPageNumber = 0;

        this.updateCurrentTable();
      });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  close(profile?: Partial<INotificationProfileSetupModalResult>) {
    this.dialogRef.close(profile);
  }

  changeItemType(type: ENotificationCenterTargetItemType) {
    this.itemType = type;

    this.updateCurrentTable();
  }

  changePagination(currentPage: number) {
    this.currentState.items[this.itemType].pagination.currentPageNumber = currentPage;

    this.updateCurrentTable();
  }

  changeSorting(sorting: {active: ETargetItemsSortColumns, direction: ESortDirection}) {
    this.currentState.items[this.itemType].sorting = {
      sortBy: sorting.active,
      sortDesc: sorting.direction === ESortDirection.desc
    };

    this.updateCurrentTable();
  }

  updateCurrentTable() {
    this.itemsToSelect.loading = true;

    this.notificationCenterService.searchTargetItems(
      this.itemType,
      this.currentState.items[this.itemType].sorting,
      this.currentState.items[this.itemType].pagination,
      this.currentState.filters
    )
      .subscribe(response => {
        this.itemsToSelect = {
          loading: false,
          items: this.attachLabelsToItems(response)
        };
        this.currentState.items[this.itemType].pagination = response.metadata.pagination;
        this.currentState.items[this.itemType].pagination.currentPageSize = 100;
      });
  }

  private loadAssignedItems() {
    const pagination = {
      totalCount: 0,
      totalPageCount: 0,
      pageSize: 1000,
      currentPageSize: 1000,
      currentPageNumber: 0
    };

    const filters = {
      email: this.modalData.profile.email,
      isAssigned: true
    };

    const emptyList = {
      items: []
    };

    forkJoin([
      this.modalData.profile.usage.alertCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.ALERT,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.modalData.profile.usage.auditCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.AUDIT,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.modalData.profile.usage.usageAlertCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.USAGE_ALERT,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.modalData.profile.usage.ruleCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.RULE,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.modalData.profile.usage.webJourneyCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.WEB_JOURNEY,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.modalData.profile.usage.emailInboxMessageReceivedCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
      this.modalData.profile.usage.emailInboxMessageProcessedCount ? this.notificationCenterService.searchTargetItems(
        ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED,
        DefaultNotificationCenterAssignmentsSorting,
        pagination,
        filters
      ) : of(emptyList),
    ]).subscribe(([alerts, audits, usageAlerts, rules, webJourneys, emailInboxReceived, emailInboxProcessed]) => {
      this.currentState.items[ENotificationCenterTargetItemType.ALERT].selectedItems = this.attachLabelsToItems(alerts);
      this.currentState.items[ENotificationCenterTargetItemType.ALERT].initialSelectedItemIds = alerts.items.map(item => item.itemId);
      this.currentState.items[ENotificationCenterTargetItemType.WEB_JOURNEY].selectedItems = this.attachLabelsToItems(webJourneys);
      this.currentState.items[ENotificationCenterTargetItemType.WEB_JOURNEY].initialSelectedItemIds = webJourneys.items.map(item => item.itemId);
      this.currentState.items[ENotificationCenterTargetItemType.RULE].selectedItems = this.attachLabelsToItems(rules);
      this.currentState.items[ENotificationCenterTargetItemType.RULE].initialSelectedItemIds = rules.items.map(item => item.itemId);
      this.currentState.items[ENotificationCenterTargetItemType.AUDIT].selectedItems = this.attachLabelsToItems(audits);
      this.currentState.items[ENotificationCenterTargetItemType.AUDIT].initialSelectedItemIds = audits.items.map(item => item.itemId);
      this.currentState.items[ENotificationCenterTargetItemType.USAGE_ALERT].selectedItems = this.attachLabelsToItems(usageAlerts);
      this.currentState.items[ENotificationCenterTargetItemType.USAGE_ALERT].initialSelectedItemIds = usageAlerts.items.map(item => item.itemId);

      this.currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED].selectedItems = this.attachLabelsToItems(emailInboxReceived);
      this.currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED].initialSelectedItemIds = emailInboxReceived.items.map(item => item.itemId);

      this.currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED].selectedItems = this.attachLabelsToItems(emailInboxProcessed);
      this.currentState.items[ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED].initialSelectedItemIds = emailInboxProcessed.items.map(item => item.itemId);

      this.itemsToSelect = {
        ...this.itemsToSelect,
        loading: false
      };
    });
  }

  onSelectionChanged($event: ISelectableTableSelectionChange<any>) {
    if ($event.added) {
      this.currentState.items[this.itemType].selectedItems = [
        ...this.currentState.items[this.itemType].selectedItems,
        ...$event.added
      ];
    }

    if ($event.removed) {
      this.currentState.items[this.itemType].selectedItems =
        this.currentState.items[this.itemType].selectedItems
          .filter(item => !$event.removed.find(removedItem => removedItem.itemId === item.itemId));
    }

    // Check that initially selected items haven't changed (lengths are the same and itemIds coincide)
    const selectedItems = this.currentState.items[this.itemType].selectedItems;
    const initialSelectedItems = this.currentState.items[this.itemType].initialSelectedItemIds;
    this.selectionChangesMade = !((selectedItems.length === initialSelectedItems.length)
      && initialSelectedItems.every(i => selectedItems.map(el => el.itemId).includes(i)));

    this.rightFooterButtons[1].disabled = !this.totalItems || !!this.isLimitsExceeded;
  }

  get isLimitsExceeded() {
    return Object.keys(this.currentState.items).find(itemType => {
      const item: INotificationCenterCurrentStateItems = this.currentState.items[itemType];
      return item.selectedItems.length > this.SELECT_ITEMS_LIMIT;
    });
  }

  switchToTab(tab: ENotificationCenterTabs) {
    if (tab === ENotificationCenterTabs.Items && this.areEmailsValid()) {
      this.tab = ENotificationCenterTabs.Items;
      this.rightFooterButtons[0].hidden = false;
      this.rightFooterButtons[1].label = 'Save';
      this.rightFooterButtons[1].disabled = !this.totalItems;
    } else {
      this.tab = ENotificationCenterTabs.Emails;
      this.rightFooterButtons[0].hidden = true;
      this.rightFooterButtons[1].label = 'Next';
      this.updateNextButtonState();
    }
  }
  next() {
    if (this.tab === ENotificationCenterTabs.Emails) {
      this.switchToTab(ENotificationCenterTabs.Items);
    } else {
      this.save();
    }
  }

  prev() {
    this.switchToTab(ENotificationCenterTabs.Emails);
  }

  private save() {
    this.close({
      emails: this.emails,
      items: [
        {
          itemType: ENotificationCenterTargetItemType.ALERT,
          value: this.calculateDiff(ENotificationCenterTargetItemType.ALERT),
        },
        {
          itemType: ENotificationCenterTargetItemType.AUDIT,
          value: this.calculateDiff(ENotificationCenterTargetItemType.AUDIT),
        },
        {
          itemType: ENotificationCenterTargetItemType.USAGE_ALERT,
          value: this.calculateDiff(ENotificationCenterTargetItemType.USAGE_ALERT),
        },
        {
          itemType: ENotificationCenterTargetItemType.RULE,
          value: this.calculateDiff(ENotificationCenterTargetItemType.RULE),
        },
        {
          itemType: ENotificationCenterTargetItemType.WEB_JOURNEY,
          value: this.calculateDiff(ENotificationCenterTargetItemType.WEB_JOURNEY),
        },
        {
          itemType: ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED,
          value: this.calculateDiff(ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED),
        },
        {
          itemType: ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED,
          value: this.calculateDiff(ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED),
        },
      ].filter(i => i.value.length > 0)
    });
  }


  private handleSearch(isLabel: boolean, event: KeyboardEvent, el?: HTMLElement) {
    if (el) {
      el.focus();
      return;
    } else {
      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

      this.updateFilteredLabels(value);

      this.filterBarMenuItems = [...this.filterBarMenuItems];
    }
  }

  private calculateDiff(itemType: ENotificationCenterTargetItemType): INotificationProfileSetupModalResultDiffItem[] {
    const diff = ArrayUtils.diff(
      this.currentState.items[itemType].initialSelectedItemIds,
      this.currentState.items[itemType].selectedItems,
      (a: number, b: INotificationCenterTargetItem) => a === b.itemId);
    return [
      ...diff.added.map((i: INotificationCenterTargetItem) => ({itemId: i.itemId, operationType: EUpdateOperationType.ADD})),
      ...diff.removed.map(i => ({itemId: i, operationType: EUpdateOperationType.REMOVE}))
    ];
  }

  getLabels() {
    this.labels$.next(null);
    return this.labelService.getLabels().subscribe(labels => {
      this.labelById = new Map;
      labels.forEach(label => this.labelById.set(label.id, label));
      this.labels$.next(labels);
    });
  }

  private updateFilteredLabels(value: string) {

    this.labels$
      .pipe(
        filter(v => !!v),
        map(labels => labels
          .filter(label => label.name.toLowerCase().includes(value))
          .map(label => ({
            name: label.name,
            type: EFilterBarMenuTypes.Button,
            action: () => this.filterBarService.addLabelIdFilter({name: label.name, id: label.id})
          }))
        )
      ).subscribe(labels => {
      this.filterBarMenuItems[0].children[0].children = value
        ? labels
        : [];
    });
  }

  get totalItems() {
    return Object.keys(this.currentState.items).reduce((totalCount, itemType) => {
      const item: INotificationCenterCurrentStateItems = this.currentState.items[itemType];
      const itemCount = this.selectionChangesMade
        ? item.selectedItems.length
        : 0;
      return totalCount + itemCount;
    }, 0);
  }

  areEmailsValid() {
    return this.emails && this.emails.length > 0 && this.emails.length <= 10;
  }

  updateNextButtonState() {
    this.rightFooterButtons[1].disabled = !this.areEmailsValid();
  }

  makeAssignedItemsTabDescriptionText(): string {
    const assignedItemsCount = Object.keys(this.currentState.items).reduce((totalCount, itemType) => {
      const item: INotificationCenterCurrentStateItems = this.currentState.items[itemType];
      const itemCount = this.selectionChangesMade
        ? item.selectedItems.length
        : item.initialSelectedCount;
      return totalCount + itemCount;
    }, 0);

    const addressPlural = this.emails?.length > 1 ? 'es' : '';

    return assignedItemsCount > 0
      ? `Email address${addressPlural} ${this.modalData.profile ? 'is' : 'will be'} assigned to ${assignedItemsCount}
       item${assignedItemsCount > 1 ? 's' : ''}.`
      : `Select items that you want to assign email address${addressPlural} to`;
  }

  private attachLabelsToItems(response: INotificationCenterSearchTargetItemsResponse): INotificationCenterTargetItem[] {
    return response.items.map(item => {
      return {
        ...item,
        itemLabels: this.labels$.value?.filter(label => (item.itemLabels as number[]).includes(label.id))
      };
    });
  }

  private initDefaultState(): INotificationCenterCurrentStateItems {
    return {
      pagination: {...DefaultNotificationCenterAssignmentsPagination},
      sorting: {...DefaultNotificationCenterAssignmentsSorting},
      selectedItems: [],
      initialSelectedItemIds: [],
      initialSelectedCount: 0
    };
  }
}
