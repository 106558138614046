import { IWebJourneyCreationRequest, IWebJourney, IWebJourneyUpdateRequest } from '@app/components/web-journey/web-journey.models';
import { Injectable } from '@angular/core';
import { IWebJourneyApiService } from '../domains/webJourneys/webJourneyAPI/webJourneyAPIService';

@Injectable()
export class WebJourneyService {

  constructor( private webJourneyApiService: IWebJourneyApiService) {}

  recipientsStringToArray(recipients: string): string[] {
    return recipients && recipients.length
      ? recipients.split('\n').map(r => r.trim()).filter(r => !!r)
      : [];
  }

  createWebJourney(webJourney: IWebJourneyCreationRequest): angular.IPromise<IWebJourney> {
    return this.webJourneyApiService.createJourney(webJourney);
  }

  updateWebJourney(webJourney: IWebJourneyUpdateRequest): angular.IPromise<IWebJourney> {
    return this.webJourneyApiService.updateJourney(webJourney);
  }

}
