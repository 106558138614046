import { RouteBuilder } from '../shared/services/route-builder.service';

export const SetCookiesPaths = {
  base: 'set-cookies'
};

export const SetCookiesStateNames = {
  setCookies: 'set_cookies'
};

export const SetCookiesUrlBuilder = {
  base: () => RouteBuilder.buildUrl([SetCookiesPaths.base])
};