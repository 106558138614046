import {
  EPageUrlFilterType,
  EStatusCodeCategories
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import {
  SummaryCardFilterType
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-trends/rule-summary-trends.component';
import { IAPIResponseMetadata } from '@app/components/audit-reports/audit-report/audit-report.models';
import {
  IRuleSummaryPagination,
  IRuleSummarySort
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.service';
import { ESelectorMatchType } from '@app/models/commons';
import {
  EPageFilterType,
  ERuleMatchType
} from '@app/components/rules/rule-setup/tabs/conditions-tab/rule-setup-conditions-tab.enums';
import { ERuleConditionClause } from '@app/components/rules/rule-setup/rule-setup.enums';
import { EAlertTagAndVariableRulesMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { ERuleStatus } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';
import { IRuleTagStatusCode } from '@app/components/rules/rules.models';

export interface IRuleSummaryQueryParams {
  placeholder: number;
}

export interface IAuditRunRuleSummaryRequestDTO {
  pageUrl?: {
    filterType: EPageUrlFilterType;
    filterValue: string;
  };
  finalPageUrl?: {
    filterType: EPageUrlFilterType;
    filterValue: string;
  };
  pageStatusCode?: EStatusCodeCategories;
  primaryTagsOnly?: boolean;
  tagId?: number;
  tagCategoryId?: number;
  tagAccount?: string;
  ruleStatus?: ERuleStatus; // only for rule results
  ruleId?: number;
  name?: string;
}

export enum ERuleResultType {
  Failed = 'failed',
  Passed = 'passed',
  NotApplied = 'not_applied'
}

export interface IAuditRunPagesRuleResultsRuleFilterDTO {
  ruleFilter?: IAuditRunPageRuleResultsRuleFilter;
  ruleResultType?: ERuleResultType
}

export interface IAuditRunPagesRuleResultsRequestDTO extends IAuditRunPagesRuleResultsRuleFilterDTO {
  pageUrl?: {
    filterType: EPageUrlFilterType;
    filterValue: string;
  };
  pageStatusCode?: EStatusCodeCategories;
  primaryTagsOnly?: Boolean;
  tagId?: number;
  tagCategoryId?: number;
  tagAccount?: string;
}
export type IAuditRunPageRuleResultsRuleFilter = IAuditRunPageRuleResultsRulePageFilter
  | IAuditRunPageRuleResultsRuleTagFilter
  | IAuditRunPageRuleResultsRuleTagVariableFilter;

export interface IAuditRunPageRuleResultsRulePageFilter {
  filterType: 'page_filter';
  pageFilterSnapshotId: number;
}
export interface IAuditRunPageRuleResultsRuleTagFilter {
  filterType: 'tag_filter';
  tagSnapshotId: number;
}
export interface IAuditRunPageRuleResultsRuleTagVariableFilter {
  filterType: 'tag_variable_filter';
  tagSnapshotId: number;
  tagVariableSnapshotId: number;
}

export interface IAuditRunRuleResultsSummaryDTO {
  failedRulePageCount: number;
  passedRulePageCount: number;
  notAppliedRulePageCount: number;
  failedRuleCount: number;
  passedRuleCount: number;
  notAppliedRuleCount: number;
  totalPageCount: number;
  filteredPageCount: number;
  totalRuleCount: number;
  filteredRuleCount: number;
  totalRuleFailureCount: number;
  filteredRuleFailureCount: number;
}

// -------- Rule Summary Trends ----------- //

// from API
export interface IAuditRunRuleResultsAllTrendsDTO {
  runs: IAuditRunRuleResultsAllTrendsItem[];
}

export interface IAuditRunRuleResultsAllTrendsItem {
  runId: number;
  completedAt: string;
  failedRulePageCount: number;
  passedRulePageCount: number;
  notAppliedRulePageCount: number;
  failedRuleCount: number;
  passedRuleCount: number;
  notAppliedRuleCount: number;
}

//custom
export interface IAuditRunRuleSummaryTrendNoDiff {
  failedRulesCount: number;
  passedRulesCount: number;
  notAppliedRulesCount: number;
}

export interface IAuditRunRuleSummaryTrend extends IAuditRunRuleSummaryTrendNoDiff {
  failedRulesCountDiffWithPrev: number;
  passedRulesCountDiffWithPrev: number;
  notAppliedRulesCountDiffWithPrev: number;
}

export interface IAuditRunRuleSummaryTrendFilters {
  cards: SummaryCardFilterType;
}

// -------- Rule Summary Trends ----------- //

// -------- Rule Results Trends ----------- //

export interface IAuditRunRuleResultsTrendDTO {
  runs: IAuditRunRuleResultsTrendItem[]
}

export interface IAuditRunRuleResultsTrendItem {
  runId: number
  completedAt: string
  failedRuleCount: number
  passedRuleCount: number
  notAppliedRuleCount: number
}

export interface IAlertAuditRunTagAndVariableRulesTrendMetrics {
  failedRuleMetricType: EAlertTagAndVariableRulesMetric
  passedRuleMetricType: EAlertTagAndVariableRulesMetric
  notAppliedRuleMetricType: EAlertTagAndVariableRulesMetric
}

// -------- Rule Results Trends ----------- //

// -------- Page Count Trends ----------- //

export interface IAuditRunRuleResultsPageCountTrendDTO {
  runs: IAuditRunRuleResultsPageCountTrendItem[]
}

export interface IAuditRunRuleResultsPageCountTrendItem {
  runId: number
  completedAt: string
  failedRulePageCount: number
  passedRulePageCount: number
  notAppliedRulePageCount: number
}

// -------- Page Count Trends ----------- //

// -------- Rule results ----------- //

export interface IPageUrlFilter {
  filterType: EPageUrlFilterType;
  filterValue: string;
}

export interface IAuditRunRuleResultsSummaryRequestDTO {
  pageUrl?: IPageUrlFilter,
  pageStatusCode?: EStatusCodeCategories,
  primaryTagsOnly?: boolean;
  tagId?: number;
  tagCategoryId?: number;
  tagAccount?: string;
  ruleName?: string;
  ruleFailuresOnly?: boolean;
}

export interface IRuleSummaryTag {
  tagId: number;
  tagName: string;
  tagCategoryId: number;
  tagCategoryName: string;
}

export interface IAuditRunAllRuleResultsItem {
  failedRulePageCount: number;
  passedRulePageCount: number;
  notAppliedRulePageCount: number;
  ruleSnapshotId: number;
  name: string;
  originalRuleId: number;
  ruleConfigExists: boolean;
  tags: IRuleSummaryTag[];
  hasEmailNotification: boolean;
  emailRecipients: string[];
  matchAllFilters: boolean;
}

export interface IAuditRunAllRuleResultsOverviewDTO {
  rules: IAuditRunAllRuleResultsItem[]
}

// specific rule result

export interface IAuditRunSpecificRuleResultsDTO {
  failedRulePageCount: number;
  passedRulePageCount: number;
  notAppliedRulePageCount: number;
  ruleSnapshotId: number;
  name: string;
  tags: IRuleTagSnapshot[];
  hasEmailNotification: boolean;
  emailRecipients: string[]
  originalRuleId: number;
  pageFilters: IRulePageFilterSnapshot[]
}

export interface IAuditRunSpecificRuleResultsWithMatchAll extends IAuditRunSpecificRuleResultsDTO {
  matchAllFilters: boolean;
}

export interface IRuleTagVariableSnapshot {
  failedRulePageCount: number;
  passedRulePageCount: number;
  notAppliedRulePageCount: number;
  ruleTagVariableSnapshotId: number;
  ruleTagSnapshotId: number;
  variable?: string;
  value?: string;
  validationDescription?: string;
  matchType: ERuleMatchType,
  valueTagId?: number;
  selectorType?: ESelectorMatchType
}

export interface IRuleTagSnapshot {
  tagId: number;
  tagName?: string;
  tagCategoryId: number;
  tagCategoryName?: string;
  failedRulePageCount: number;
  passedRulePageCount: number;
  notAppliedRulePageCount: number;
  ruleTagSnapshotId: number;
  ruleSnapshotId: number;
  matchType?: ERuleMatchType;
  account?: string;
  validationDescription?: string;
  clause: ERuleConditionClause;
  variables: IRuleTagVariableSnapshot[];
  statusCode?: IRuleTagStatusCode;
}

export interface IRulePageFilterSnapshot {
  failedRulePageCount: number;
  passedRulePageCount: number;
  notAppliedRulePageCount: number;
  pageFilterSnapshotId: number;
  ruleSnapshotId: number;
  value: string;
  type: EPageFilterType;
  matchType?: ERuleMatchType;
}

// -------- Rule results ----------- //

// -------- Page Rule Results ----------- //

export interface IAuditRunPageRuleResultsDTO {
  pages: IAuditRunPageRuleResultsItem[]
  metadata: IAPIResponseMetadata
}

export interface IAuditRunPageRuleResultsItem {
  pageId: string;
  pageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  finalPageStatusCode: number;
  ruleFailureCount: number;
}

//specific rule
export interface IAuditRunSpecificRulePageRuleResultsDTO {
  pages: IAuditRunSpecificRulePageRuleResultsItem[];
  metadata: IAPIResponseMetadata
}

export interface IAuditRunSpecificRulePageRuleResultsItem {
  pageId: string;
  pageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  finalPageStatusCode: number;
  conditionResult: IAuditRunRuleConditionResult;
}

export interface IRuleConditionExpectedValue {
  selectorType?: ESelectorMatchType;
  name?: string;
  expected: string[];
}

export enum EComplianceItemIdType {
  Filter = 'Filter',
  Tag = 'Tag',
  Variable = 'Variable'
}

export interface IAuditRunRuleConditionResult {
  id: number;
  idType: EComplianceItemIdType,
  parentId?: number;
  parentType: string;
  name: string;
  matcher: string;
  expected: IRuleConditionExpectedValue;
  actual?: string;
}

// -------- Page Rule Results ----------- //

export interface IRuleSummaryPagesTableState {
  sort?: IRuleSummarySort<CommonReportsPagesTableColumns>
  pagination: IRuleSummaryPagination
  pagesTotal: number
}
