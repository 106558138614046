import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { EHistoryType } from './breadcrumb.enums';
import { BreadcrumbHistory, IBreadcrumbItem } from './breadcrumb.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {

  @Input() history: BreadcrumbHistory;
  @Output() onStateSelect = new EventEmitter<BreadcrumbHistory>();

  readonly historyType = EHistoryType;

  constructor(private router: Router) {}

  selectStateOption({ value }: MatSelectChange) {
    const [dropdown] = this.history.slice(-1);
    const link = this.findLink(value, dropdown.options);
    this.selectStateLink(link);
  }

  selectStateLink(link: IBreadcrumbItem) {
    this.router.navigateByUrl(link.url);
  }

  private findLink(value: string, links: IBreadcrumbItem[]): IBreadcrumbItem {
    return links.find(link => link.text === value);
  }
}
