import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { ICustomFrequencyModalData } from './custom-frequency-modal.models';
import { OpModalModule } from '../../../op-modal/op-modal.module';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { OpRunDateComponent } from '../../op-run-date/op-run-date.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RRule, Options, Frequency, Weekday } from 'rrule';
import { WeekdaySelectorComponent } from './weekday-selector/weekday-selector.component';
import { EFrequencyType, EFrequencyTypeToRRuleMap, RRuleToEFrequencyTypeMap } from './custom-frequency-modal.constants';
import { MonthByDaySelectorComponent } from './month-by-day-selector/month-by-day-selector.component';
import { MonthByDateSelectorComponent } from './month-by-date-selector/month-by-date-selector.component';
import { RecurrenceService } from '../../op-recurrence.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'custom-frequency-modal',
  standalone: true,
  imports: [
    CommonModule,
    OpModalModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatSelectModule,
    OpRunDateComponent,
    WeekdaySelectorComponent,
    MonthByDaySelectorComponent,
    MonthByDateSelectorComponent
  ],
  templateUrl: './custom-frequency-modal.component.html',
  styleUrls: ['./custom-frequency-modal.component.scss']
})
export class CustomFrequencyModalComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject();

  startDate: Date | string;
  startTime: string;
  recurrenceRule: string;
  customFrequencyForm: FormGroup;
  frequencyType: EFrequencyType = EFrequencyType.DAY;
  EFrequencyType = EFrequencyType;
  rightFooterButtons: IButton[] = [{
    label: 'Done',
    action: () => this.save(),
    primary: true
  }];

  supportedFrequencies: { label: string; value: EFrequencyType }[] = [];

  constructor(
    private dialogRef: MatDialogRef<CustomFrequencyModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ICustomFrequencyModalData,
    private cdr: ChangeDetectorRef,
    private recurrenceService: RecurrenceService
  ) {
    this.startDate = this.data.startDate;
    this.startTime = this.data.startTime;
    this.recurrenceRule = this.data.recurrenceRule;
  }

  ngOnInit(): void {
    this.initForm();
    this.patchForm();
    this.initListeners();
    this.updateSupportedFrequencies();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): void {
    this.customFrequencyForm = new FormGroup({
      count: new FormControl<number>(1, [Validators.required, Validators.min(1)]),
      frequency: new FormControl<EFrequencyType>(EFrequencyType.DAY),
      daysOfWeek: new FormControl<number[]>([]),
      endRuns: new FormControl<boolean>(false),
      endRunsDate: new FormControl<Date | null>({ value: null, disabled: true }),
      monthByDayN: new FormControl<number[] | null>(null),
      monthByDayWeekday: new FormControl<Weekday[] | null>(null),
      monthByDate: new FormControl<number[] | null>(null)
      });
  }

  private patchForm(): void {
    if (!this.recurrenceRule) return;

    // convert recurrence rule string to rrule object
    const rrule = RRule.fromString(this.recurrenceRule);

    /**
     * Determine the frequency type based on the RRule options.
     * This is necessary because the monthly frequency can be either by day or by date,
     * which isn't directly represented in the RRule frequency enum.
     */
    let frequency: EFrequencyType = RRuleToEFrequencyTypeMap[rrule.options.freq];
    this.frequencyType = frequency;
    if (rrule.options.freq === Frequency.MONTHLY) {
      this.frequencyType = frequency = rrule.options.bynweekday ? EFrequencyType.MONTH_BY_DAY : EFrequencyType.MONTH_BY_DATE;
    }

    // patch form with rrule options
    this.customFrequencyForm.patchValue({
      count: rrule.options.interval,
      frequency: frequency,
      daysOfWeek: rrule.options.byweekday,
      endRuns: !!rrule.options.until,
      endRunsDate: rrule.options.until ? new Date(rrule.options.until) : null,
      // additional fields are only used to store data after the custom frequency modal is closed
    });

    // enable date input if endRuns is true
    if (rrule.options.until) {
      this.endRunsDate.enable();
    }
  }

  private initListeners(): void {
    this.count.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.updateSupportedFrequencies();
    });

    this.frequency.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.frequencyType = this.frequency.value;

      if (this.frequencyType !== EFrequencyType.WEEK) {
        // clear out selected days if user chooses a frequency that isn't weekly
        this.customFrequencyForm.patchValue({ daysOfWeek: [] });
      }
    });

    this.endRuns.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: boolean) => {
      value ? this.endRunsDate.enable() : this.endRunsDate.disable();
    });
  }

  private updateSupportedFrequencies(): void {
    const isPlural = this.count.value !== 1;

    this.supportedFrequencies = [{
      label: `Minute${isPlural ? 's' : ''}`,
      value: EFrequencyType.MINUTE
    }, {
      label: `Hour${isPlural ? 's' : ''}`,
      value: EFrequencyType.HOUR
    }, {
      label: `Day${isPlural ? 's' : ''}`,
      value: EFrequencyType.DAY
    }, {
      label: `Week${isPlural ? 's' : ''}`,
      value: EFrequencyType.WEEK
    }, {
      label: `Month${isPlural ? 's' : ''} - by Day`,
      value: EFrequencyType.MONTH_BY_DAY
    }, {
      label: `Month${isPlural ? 's' : ''} - by Date`,
      value: EFrequencyType.MONTH_BY_DATE
    }];
  }

  private getRecurrenceRule(): string {
    const { count, frequency, daysOfWeek, endRunsDate, monthByDayN, monthByDayWeekday, monthByDate } = this.customFrequencyForm.value;

    const options: Partial<Options> = {
      freq: EFrequencyTypeToRRuleMap[frequency],
      interval: count,
    };

    if (daysOfWeek?.length) {
      options.byweekday = daysOfWeek.sort();
    }

    if (this.endRuns.value) {
      options.until = new Date(endRunsDate).toISOString() as unknown as Date;
    }

    if (this.frequencyType === EFrequencyType.MONTH_BY_DATE && monthByDate !== null) {
      options.bymonthday = monthByDate.map(day => parseInt(day));
    }

    if (this.frequencyType === EFrequencyType.MONTH_BY_DAY && monthByDayN !== null && monthByDayWeekday !== null) {
      options.byweekday = monthByDayWeekday.map((day, index) =>
        day.nth(monthByDayN[index])
      );
    }

    return new RRule(options).toString();
  }

  weekdaySelectionChanged(daysOfWeek: number[]): void {
    this.customFrequencyForm.patchValue({ daysOfWeek });
    this.cdr.detectChanges();
  }

  monthByDaySelectionChanged(selectedOption: { n: number, weekday: number }[]): void {
    this.customFrequencyForm.patchValue({
      monthByDayN: selectedOption.map(option => option.n),
      monthByDayWeekday: selectedOption.map(option => option.weekday)
    });
  }

  monthByDateSelectionChanged(dates: number[]): void {
    this.customFrequencyForm.patchValue({ monthByDate: dates });
  }

  async save(): Promise<void> {
    /**
     * We have to format this properly for the API. First, we remove the
     * RRULE: prefix and replace it with the week start day, then we remove
     * the Z from the end of the date string when UNTIL= is present.
     */
    let rule = this.getRecurrenceRule();
    rule = rule.replace('RRULE:', 'WKST=SU;');
    if (rule.includes('UNTIL=')) {
      rule = rule.replace(/UNTIL=(\d{8}T\d{6})Z/, 'UNTIL=$1');
    }

    try {
      const { description } = await this.recurrenceService.getRecurrenceRuleDescription(rule).toPromise();
      this.dialogRef.close({ rule, description });
    } catch (error) {
      const description = RRule.fromString(rule).toText();
      this.dialogRef.close({ rule, description });
    }
  }

  close(value?: string): void {
    this.dialogRef.close(value);
  }

  get count(): FormControl {
    return this.customFrequencyForm.get('count') as FormControl;
  }

  get frequency(): FormControl {
    return this.customFrequencyForm.get('frequency') as FormControl;
  }

  get daysOfWeek(): FormControl {
    return this.customFrequencyForm.get('daysOfWeek') as FormControl;
  }

  get endRuns(): FormControl {
    return this.customFrequencyForm.get('endRuns') as FormControl;
  }

  get endRunsDate(): FormControl {
    return this.customFrequencyForm.get('endRunsDate') as FormControl;
  }

  get monthByDayN(): FormControl {
    return this.customFrequencyForm.get('monthByDayN') as FormControl;
  }

  get monthByDayWeekday(): FormControl {
    return this.customFrequencyForm.get('monthByDayWeekday') as FormControl;
  }

  get monthByDate(): FormControl {
    return this.customFrequencyForm.get('monthByDate') as FormControl;
  }
}
