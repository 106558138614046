import { Component, Input, OnInit } from '@angular/core';
import { EColorHighlightMeaning } from '@app/components/shared/components/viz/color-highlight/color-highlight.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'trend-indicator',
  templateUrl: './trend-indicator.component.html',
  styleUrls: ['./trend-indicator.component.scss']
})
export class TrendIndicatorComponent {

  /**
   * arrow direction
   * value < 0 - downward
   * value > 0 - upward
   * else - arrow is not rendered
   */
  @Input() set value(v: number) {
    this._value = v;
    this.absValue = Math.abs(v);
  }
  get value(): number {
    return this._value;
  }

  @Input() renderWhenValueIs0: boolean = false;

  @Input() valueFormat: string = '1.0-2';

  @Input() label: string;
  @Input() zeroLabel: string;

  absValue: number;
  private _value: number;

  constructor() { }

}
