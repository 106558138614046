import { EWebElementSelectorType } from '@app/components/shared/components/multi-selectors/multi-selectors.models';
import {
  Component,
  forwardRef,
  Input,
  ViewChild,
  OnDestroy,
  HostListener,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
  ChangeDetectorRef, AfterContentInit
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormArray,
  Validator,
  AbstractControl,
  ValidationErrors,
  NG_VALIDATORS
} from '@angular/forms';
import { ActionsPreviewComponent } from '@app/components/actions/actions-preview/actions-preview.component';
import { EActionCreatorMode } from './actions-creator.enum';
import { defaultAuditAction, defaultWebJourneyAction } from './actions-creator.constants';
import { Subject, Subscription } from 'rxjs';
import { IDragModel } from '@app/components/actions/actions-preview/actions-preview.models';
import {
  IBaseActionDetails,
  IActionDetailsRaw,
  IActionDetails,
  IMaskedInputActionDetailsRaw,
  IMaskedInputActionDetails
} from '@app/components/actions/action-details/action-details.models';
import { ActionDetailsComponent } from '@app/components/actions/action-details/action-details.component';
import { ActionDetailsFormBuilder } from '../action-details/action-details-form-builder.service';
import { EActionType, EActionTypeV3 } from '@app/components/web-journey/web-journey.models';
import { IWebJourneyActionRules } from '@app/components/web-journey/web-journey-editor/web-journey-editor.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { DeleteActionModalComponent } from '../delete-action-modal/delete-action-modal.component';
import { IDeleteActionModalPayload, IDeleteActionModalResponse } from '../delete-action-modal/delete-action-modal.models';
import { MultiSelectorsService } from '@app/components/shared/components/multi-selectors/multi-selectors.service';
import { IActionSet } from '../../action-set-library/action-set-library.models';
import { ActionSetLibraryService } from '@app/components/action-set-library/action-set-library.service';
import { DataSourceEditorService } from '@app/components/data-source-editor/data-source-editor.service';
import { takeUntil } from 'rxjs/operators';
import { Datasource, IDatasource } from 'ngx-ui-scroll';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ActionsCreatorComponent),
  multi: true
};
const FORM_CONTROL_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ActionsCreatorComponent),
  multi: true
};
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'actions-creator',
  templateUrl: './actions-creator.component.html',
  styleUrls: ['./actions-creator.component.scss'],
  providers: [FORM_CONTROL_VALUE_ACCESSOR, FORM_CONTROL_VALIDATOR]
})
export class ActionsCreatorComponent implements OnDestroy, ControlValueAccessor, Validator, AfterContentInit {
  private destroy$: Subject<void> = new Subject();

  initialLoadComplete: boolean;
  activeActionIndex: number = 0;
  allActionSets: IActionSet[];
  navToActionSets: IActionSet[];
  onChange: Function;
  actionsCreatorForm: UntypedFormGroup;
  valueChangedSubscription: Subscription;
  formIsValid: boolean;
  actionsDatasource: IDatasource;

  @Input() submitted: boolean = false;
  @Input() mode: EActionCreatorMode;
  @Input() leftColHeading: string = 'Action List';
  @Input() startingUrl?: string; // is used for Web Journey actions
  @Output() openRuleSelector: EventEmitter<IWebJourneyActionRules> = new EventEmitter();
  @Output() onActionSetCreated: EventEmitter<IActionSet> = new EventEmitter();
  @ViewChild(ActionsPreviewComponent) actionsList: ActionsPreviewComponent;
  @ViewChildren(ActionDetailsComponent) actionDetails: QueryList<ActionDetailsComponent>;

  constructor(private formBuilder: UntypedFormBuilder,
              public opModalService: OpModalService,
              private actionDetailsFormBuilder: ActionDetailsFormBuilder,
              private multiSelectorsService: MultiSelectorsService,
              private actionSetService: ActionSetLibraryService,
              private dataSourceEditorService: DataSourceEditorService,
              private cdr: ChangeDetectorRef,
  ) {
    this.actionsCreatorForm = formBuilder.group({
      actions: formBuilder.array([])
    });
  }

  ngAfterContentInit() {
    this.valueChangedSubscription = this.actionsCreatorForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const processedActions = this.actions.controls.map(this.processForm.bind(this));
      this.onChange(processedActions);
      this.formIsValid = this.actionsCreatorForm.valid;
    });

    this.initActionSets();
    this.handleScrollToCmpAction();
  }

  ngOnDestroy(): void {
    this.valueChangedSubscription && this.valueChangedSubscription.unsubscribe();
    this.destroy$.next();
  }

  setActionsVS() {
    // This is the virtual scroll datasource which is required by the scroll library
    // It is used to manage the virtual scroll items
    // You can replace the *uiScroll="let action of actionsDatasource" in the HTML
    // with *ngFor="let action of actions.controls"
    // And that will work, but the virtual scroll is more efficient for large lists

    // Make sure you update the datasource with *actionsDatasource.adapter.reload()* after updating the actions array
    // In case with drag'n'drop WITHOUT this action you'll end with the wrong indexes in the virtual scroll
    this.actionsDatasource = new Datasource({
      get: (index, count, success) => {
        const start = Math.max(index, 0);
        const end = index + count - 1;
        success(start <= end
          ? this.actions.controls.slice(start, end + 1)
          : []
        );
      },
      settings: {
        bufferSize: 15,
        startIndex: 0,
        minIndex: 0,
      },
    });
  }

  initActionSets(): void {
    if (this.mode !== EActionCreatorMode.ActionSet) { // don't get action sets if creating one
      this.actionSetService.getAllActionSets().subscribe((actionSets: IActionSet[]) => {
        actionSets.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

        this.allActionSets = actionSets;
        this.navToActionSets = actionSets.filter(actionSet => {
          const navToAction = actionSet.actions.find(a => a.sequence === 0).actionType === EActionTypeV3.NavTo;

          // the new CMP action needs to also be allowed as a first step in an action set
          const firstAction = actionSet.actions.find(a => a.sequence === 0).actionType;
          const cmpAction = firstAction === EActionTypeV3.CmpOptIn || firstAction === EActionTypeV3.CmpOptOut;

          return  navToAction || cmpAction;
        });
      });
    }
  }

  async addToActionSets(data: any) {
    this.allActionSets.splice(0, 0, data.actionSet);
    this.allActionSets = [...this.allActionSets];
    this.navToActionSets.splice(0, 0, data.actionSet);
    const newActionIndex = this.actions?.value?.findIndex(action => {
      return action.actionId === data.selectedActions[0]?.actionId;
    });

    this.onActionSetCreated.emit(data);
    await this.onSelect(newActionIndex, true);
 }

  // process action details object before emiting value outside
  private processForm(form: UntypedFormGroup): IActionDetailsRaw {
    let formValue = <IActionDetails>form.value;
    formValue.filter = form.getRawValue().filter; // need to get filter from raw value in case it is disabled
    if (formValue.selectors) {
      formValue.selectors = formValue.selectors.map(selector => {
        if (selector.selectorType === EWebElementSelectorType.HtmlAttrs) {
          selector.value = this.multiSelectorsService.parseAttributes(selector.value as string);
        }
        return selector;
      });
    }
    return formValue.type === EActionType.MaskedInput ? <IMaskedInputActionDetailsRaw>{
      ...formValue,
      ...(<IMaskedInputActionDetails>formValue).maskedValue
    } : <IActionDetailsRaw>formValue;
  }

  private createNewAction(): IBaseActionDetails {
    switch (this.mode) {
      case EActionCreatorMode.AuditActions:
      case EActionCreatorMode.AuditUserSession:
      case EActionCreatorMode.ActionSet:
        return defaultAuditAction();
      case EActionCreatorMode.WebJourney:
        return defaultWebJourneyAction(this.startingUrl);
    }
  }

  async writeValue(actions: IBaseActionDetails[]) {
    if (actions?.length) {
      const actionsControls = actions.map(action => this.actionDetailsFormBuilder.buildForm(action, this.mode));
      this.actionsCreatorForm.setControl('actions', this.formBuilder.array(actionsControls));
      if (!this.actionsDatasource) {
        await this.initializeVSDatasource();
      }
    }
  }

  async initializeVSDatasource() {
    this.setActionsVS();
    this.initialLoadComplete = true;
    this.formIsValid = this.actionsCreatorForm.valid;
    this.cdr.detectChanges();
  }

  registerOnChange(fn: (value: IBaseActionDetails[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {}

  async onSelect(index: number, scrollToAction: boolean = false) {
    this.activeActionIndex = index;
    if (scrollToAction) {
      await this.scrollToAction(this.activeActionIndex);
    }
  }

  onTypeChange(index: number): void {
    this.actionDetails.forEach(comp => comp.calcPossibleActions(index));
  }

  async onDrag(dragModel: IDragModel) {
    await this.writeValue(dragModel.targetModel);
    this.actionsList.selectAction(this.actions.value[dragModel.targetIndex], dragModel.targetIndex);
    // We need to reload the datasource to update the virtual scroll items' indexes
    await this.actionsDatasource.adapter.reload();
  }

  onDelete(index: number): void {
    if (index === 0 && this.mode === EActionCreatorMode.WebJourney) return;
    this.opModalService.openModal(DeleteActionModalComponent, {
      data: {
        optionalDeleteFollowing: true,
        deleteFollowingSteps: false
      } as IDeleteActionModalPayload
    })
      .afterClosed()
      .subscribe(async (response: IDeleteActionModalResponse) => {
        if (response && response.confirmDelete) {
          if (!response.deleteFollowingSteps) {
            await this.removeOneAction(index);
          } else {
            await this.removeActionAndAllFollowing(index);
          }
        }
      });
  }

  async addAction(index: number) {
    this.submitted = true;
    this.cdr.detectChanges();
    if (this.actionsCreatorForm.invalid) {
      await this.handleValidationError();
      return;
    }

    const newAction = this.createNewAction();
    await this.addActionToDataAndScroll(newAction, index);
    this.actionsList.selectAction(newAction, index);
    this.submitted = false;
    this.actions.controls[index].touched = false;
    this.actions.controls[index].controls.url.touched = false;
    this.cdr.detectChanges();
  }

  async addActionToDataAndScroll(action, index) {
    await this.actionsDatasource?.adapter?.relax();

    // Insert new action in the form array
    this.actions.insert(index, this.actionDetailsFormBuilder.buildForm(action, this.mode));

    if (!this.actionsDatasource && this.actions.length === 1) {
      await this.initializeVSDatasource();
    // Insert into any index after the first in the virtual scroller
    } else if (index > 0) {
      await this.actionsDatasource?.adapter?.insert({
        afterIndex: index - 1,
        items: [action]
      });
      // Inserting into the first item in the virtual scroller
    } else {
      // First action in the list, so just append it
      await this.actionsDatasource?.adapter?.prepend({
        items: [action],
        bof: true
      });
    }
  }

  async removeOneAction(index) {
    await this.actionsDatasource?.adapter?.relax();
    // Remove one
    this.actions.removeAt(index);
    await this.actionsDatasource?.adapter?.remove({ indexes: [index] });
  }

  async removeActionAndAllFollowing(index) {
    let tempIndex = index;
    await this.actionsDatasource?.adapter?.relax();
    let removedIndexCount = 0;
    let indexesToRemove = [];

    for (let i = tempIndex; i < this.actions.length; i++) {
      indexesToRemove.push(tempIndex);
      tempIndex++;
    }

    while (this.actions.length > index) {
      this.actions.removeAt(index);
      removedIndexCount++;
    }

    // Remove from virtual scroll datasource
    await this.actionsDatasource?.adapter?.remove({ indexes: indexesToRemove });
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.actionsCreatorForm.valid ? null : {actions: {valid: false, message: 'Invalid actions'}};
  }

  async handleValidationError() {
    const invalidActionIndex = this.actions.controls.findIndex(action => action.invalid);
    await this.scrollToAction(invalidActionIndex);
  }

  async scrollToAction(index) {
    const viewport: HTMLElement = document.querySelector('.viewport'); // use native ref instead
    let found: HTMLElement | null = null;

    if (this.actionsDatasource) {
      await this.actionsDatasource?.adapter?.fix({
        updater: ({ $index, element, data }) =>
          !found && $index === index ? found = element : null
      });

      if (found && viewport) {
        await this.actionsDatasource?.adapter?.fix({
          scrollPosition: found.offsetTop - viewport.offsetTop
        });
      } else {
        await this.actionsDatasource?.adapter?.reload(index);
      }
    }
  }

  handleScrollToCmpAction() {
    this.dataSourceEditorService.scrollToCmpAction$.pipe(takeUntil(this.destroy$)).subscribe((scrollToIndex: number) => {
      setTimeout(async() => {
        await this.onSelect(scrollToIndex, true);
      });
    });
  }

  get actions(): any {
    return this.actionsCreatorForm.get('actions') as UntypedFormArray;
  }
}
