<ul #list>
  <li class="item" *ngFor="let item of items | slice:0:show">
    <ng-container
      *ngTemplateOutlet="previewItemTemplate; context: {$implicit: item}">
    </ng-container>
  </li>
  <li
    class="more"
    #more
    [ngClass]="{'hidden': expanded || notDisplayed === 0}"
    (click)="toggleExpandPreview()">
    <div class="count">
      <span>+{{notDisplayed}}</span>
    </div>
    <div class="icon">
      <mat-icon>more_horiz</mat-icon>
    </div>
  </li>
</ul>
<div class="close" [ngClass]="{'hidden': !expanded}">
  <button mat-button (click)="toggleExpandPreview()">close</button>
</div>
