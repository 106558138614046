import { Component } from '@angular/core';
import { MenuItems } from '@app/components/shared/components/op-button-2021/op-button-2021.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-button',
  templateUrl: './cl-button.component.html',
  styleUrls: ['./cl-button.component.scss']
})
export class ComponentLibraryButtonComponent {
  menuItems: MenuItems[] = [
    { label: 'Item 1', action: () => console.log('Item 1 clicked') },
    { label: 'Item 2', action: () => console.log('Item 2 clicked') },
    { label: 'Item 3', action: () => console.log('Item 3 clicked') }
  ];
}
