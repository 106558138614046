import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RecurrenceService } from '../op-recurrence.service';
import { IRecurrencePreset } from '../op-recurrence.models';
import { CUSTOM_FREQUENCY_PRESET, EFrequencyTooltip, ERecurrenceItemType, ERecurrencePresetNames, ERecurrenceScheduleState } from '../op-recurrence.constants';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { format, parse } from 'date-fns';
import { RRule } from 'rrule';
import { MatDividerModule } from '@angular/material/divider';
import { OpModalService } from '../../op-modal';
import { CustomFrequencyModalComponent } from './custom-frequency-modal/custom-frequency-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICustomFrequencyModalData } from './custom-frequency-modal/custom-frequency-modal.models';
import { MatTooltipModule } from '@angular/material/tooltip';
import { isAfter } from 'date-fns';

interface IFrequencyHint {
  text: string;
  showIcon: boolean;
  showLink: boolean;
}

interface IScheduleOption {
  label: string;
  value: string;
  isDivider: boolean;
}

@Component({
  selector: 'op-run-frequency',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatTooltipModule,
  ],
  templateUrl: './op-run-frequency.component.html',
  styleUrls: ['./op-run-frequency.component.scss']
})
export class OpRunFrequencyComponent implements OnInit, OnChanges, OnDestroy {

  @Input() fieldLabel: string = 'Frequency';
  @Input() frequencyForm: FormGroup;
  @Input() selectedDate: string;
  @Input() selectedTime: string;
  @Input() itemType: ERecurrenceItemType;

  readonly ERecurrencePresetNames = ERecurrencePresetNames;
  readonly ERecurrenceScheduleState = ERecurrenceScheduleState;

  private destroy$: Subject<void> = new Subject();

  schedulePresets: IRecurrencePreset[] = [];
  scheduleState: ERecurrenceScheduleState;
  scheduleStateIcon: string = '';
  scheduleStateTooltip: string = '';
  scheduleOptions: IScheduleOption[] = [];
  selectedOptionLabel: string = '';
  previousPresetType: string;
  recurrenceRuleMap: Record<string, string> = {};
  frequencyHint: IFrequencyHint = {
    text: '',
    showIcon: false,
    showLink: false
  };

  constructor(
    private renderer: Renderer2,
    private recurrenceService: RecurrenceService,
    private modalService: OpModalService
  ) {}

  ngOnInit(): void {
    this.initListeners();
    this.loadSchedulePresets();
    this.setScheduleBtnState();
    this.setScheduleBtnIcon();
    this.setFrequencyHint();

    this.previousPresetType = this.presetType.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // we need to update the schedule options any time the selected date or time changes
    if (changes.selectedDate?.currentValue || changes.selectedTime?.currentValue) {
      this.createScheduleOptions();
      this.setScheduleBtnState();
      this.setScheduleBtnIcon();
      this.setFrequencyHint();
      this.setRuleValue();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initListeners(): void {
    this.presetType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string) => {
      this.setScheduleBtnState();
      this.setScheduleBtnIcon();
      this.setFrequencyHint();
      this.setRuleValue();
      this.getSelectedOptionLabel();

      if (value === CUSTOM_FREQUENCY_PRESET) {
        this.openCustomFrequencyDialog();
      } else {
        this.previousPresetType = value;
      }
    });
  }

  private loadSchedulePresets(): void {
    this.recurrenceService.getSchedulePresets().subscribe((presets: IRecurrencePreset[]) => {
      this.schedulePresets = presets;
      this.createScheduleOptions();
      this.createRecurrenceRuleMap();
      this.getSelectedOptionLabel();
      this.setRuleValue();
    });
  }

  private getSelectedOptionLabel(): void {
    this.selectedOptionLabel = this.scheduleOptions.find(option => option.value === this.presetType.value)?.label;
  }

  private setScheduleBtnState(): void {
    if (this.isUnscheduled()) {
      this.setStateAndTooltip(ERecurrenceScheduleState.NotScheduled, EFrequencyTooltip.IsNotScheduled);
      return;
    }

    if (this.isRunOnce()) {
      this.handleRunOnceState();
      return;
    }

    if (this.isPaused.value) {
      this.setStateAndTooltip(ERecurrenceScheduleState.Paused, EFrequencyTooltip.IsPaused);
      return;
    }

    if (this.itemWillRunInFuture()) {
      this.setStateAndTooltip(ERecurrenceScheduleState.Scheduled, EFrequencyTooltip.IsScheduled);
    } else {
      this.setStateAndTooltip(ERecurrenceScheduleState.NotScheduled, EFrequencyTooltip.IsNotScheduled);
    }
  }

  private isUnscheduled(): boolean {
    return this.presetType.value === ERecurrencePresetNames.Unscheduled;
  }

  private isRunOnce(): boolean {
    return this.presetType.value === ERecurrencePresetNames.RunOnce;
  }

  private handleRunOnceState(): void {
    if (this.dateIsInThePast()) {
      this.setStateAndTooltip(ERecurrenceScheduleState.NotScheduled, EFrequencyTooltip.IsNotScheduled);
    } else {
      this.setStateAndTooltip(ERecurrenceScheduleState.Scheduled, EFrequencyTooltip.IsScheduled);
    }
  }

  private setStateAndTooltip(state: ERecurrenceScheduleState, tooltipSuffix: string): void {
    this.scheduleState = state;
    this.scheduleStateTooltip = `${this.itemType} ${tooltipSuffix}`;
  }

  private setRuleValue(): void {
    // custom frequency is handled after the modal closes.
    const customPresets = [ERecurrencePresetNames.Custom, CUSTOM_FREQUENCY_PRESET];
    if (customPresets.includes(this.presetType.value)) return;

    this.recurrenceRule.setValue(this.recurrenceRuleMap[this.presetType.value]);
  }

  private createRecurrenceRuleMap(): void {
    // use reduce to map over the schedule presets and create a map of the rule values
    this.recurrenceRuleMap = this.schedulePresets.reduce((ruleMap: Record<string, string>, preset: IRecurrencePreset) => {
      ruleMap[preset.freqId] = preset.rule;
      return ruleMap;
    }, {});
  }

  private setScheduleBtnIcon(): void {
    switch (this.scheduleState) {
      case ERecurrenceScheduleState.Scheduled:
        this.scheduleStateIcon = 'event_repeat';
        break;
      case ERecurrenceScheduleState.NotScheduled:
        this.scheduleStateIcon = 'event_busy';
        break;
      case ERecurrenceScheduleState.Paused:
        this.scheduleStateIcon = 'pause_card';
        break;
    }
  }

  private setFrequencyHint(): void {
    const itemType = this.itemType === ERecurrenceItemType.Generic ? 'item' : this.itemType;
    if (this.presetType.value === ERecurrencePresetNames.RunOnce) {
      if (this.dateIsInThePast()) {
        this.frequencyHint = {
          text: `This ${itemType} should have already run. Update the date or time to run it again.`,
        showIcon: false,
          showLink: false
        };
      } else {
        this.frequencyHint = {
          text: `This ${itemType} is scheduled to run in the future.`,
          showIcon: false,
          showLink: false
        };
      }
    } else if (this.presetType.value === ERecurrencePresetNames.Unscheduled) {
      this.frequencyHint = {
        text: 'Not scheduled (Select an option).',
        showIcon: false,
        showLink: false
      };
    } else if (this.presetType.value === ERecurrencePresetNames.Custom) {
      if (this.itemWillRunInFuture()) { // there are still runs scheduled
        this.frequencyHint = {
          text: 'The custom schedule will run on or after the selected start date and time.',
          showIcon: true,
          showLink: false
        };
      } else { // no more runs are scheduled
        this.frequencyHint = {
          text: 'This schedule has completed. Update it with a later completion date to run again.',
          showIcon: false,
          showLink: false
        };
      }
    } else {
      this.frequencyHint = {
        text: 'If a run overlaps into next scheduled run, it will begin after the previous run finishes.',
        showIcon: false,
        showLink: true
      };
    }
  }

  private itemWillRunInFuture(): boolean {
    /**
     * If the recurrence rule is not set, we need to assume the item will run in the future
     * because the user may not have set the recurrence rule yet
     */
    if (!this.recurrenceRule.value) return true;

    const rule = RRule.fromString(this.recurrenceRule.value);
    return !!rule.after(new Date());
  }

  private dateIsInThePast(): boolean {
    const currentDate = new Date(this.selectedDate);
    const selectedDateTime = new Date(`${currentDate.toISOString().split('T')[0]}T${this.selectedTime}`);
    const currentDateTime = new Date();
    return !isAfter(selectedDateTime, currentDateTime);
  }

  private createScheduleOptions(): void {
    if (!this.schedulePresets.length) return;

    // Group schedulePresets by groupId
    const groupedPresets = this.schedulePresets.reduce((acc, preset) => {
      if (!acc[preset.groupId]) {
        acc[preset.groupId] = [];
      }
      acc[preset.groupId].push(preset);
      return acc;
    }, {} as { [key: string]: IRecurrencePreset[] });

    // Create schedule options from grouped presets
    this.scheduleOptions = Object.entries(groupedPresets).flatMap(([groupId, presets]) => {
      const options = presets.map(preset => ({
        label: this.getFriendlyName(preset),
        value: preset.freqId,
        isDivider: false
      }));

      // Add a divider after each group except the last one
      if (groupId !== Object.keys(groupedPresets).pop()) {
        options.push({ label: '', value: null, isDivider: true });
      }

      return options;
    });

    // insert a group before the last divider for the custom option
    const customGroup = this.getCustomGroup();
    const lastDividerIndex = this.scheduleOptions.findIndex(option => option.isDivider);
    this.scheduleOptions.splice(lastDividerIndex, 0, ...customGroup);
  }

  private getCustomGroup(): IScheduleOption[] {
    const options: IScheduleOption[] = [];

    const customSectionDivider: IScheduleOption = {
      label: '',
      value: null,
      isDivider: true
    }

    const customSectionCreatorOption: IScheduleOption = {
    label: 'Custom Schedule',
      value: CUSTOM_FREQUENCY_PRESET,
      isDivider: false
    }

    options.push(customSectionDivider);

    if (this.presetType.value === ERecurrencePresetNames.Custom) {
      const customFrequencyOption: IScheduleOption = {
        label: this.description.value.charAt(0).toUpperCase() + this.description.value.slice(1),
        value: ERecurrencePresetNames.Custom,
        isDivider: false
      }
      options.push(customFrequencyOption);

      customSectionCreatorOption.label = 'Change custom schedule'
    }

    options.push(customSectionCreatorOption);

    return options;
  }

  private getFriendlyName(preset: IRecurrencePreset): string {
    switch (preset.freqId) {
      case ERecurrencePresetNames.FifteenMinutes:
        return 'Every 15 minutes';
      case ERecurrencePresetNames.Hourly:
        return 'Every hour';
      case ERecurrencePresetNames.SixHours:
        return 'Every 6 hours';
      case ERecurrencePresetNames.Daily:
        const parsedTime = parse(this.selectedTime, 'HH:mm', new Date());
        return `Every day at ${format(parsedTime, 'p')}`;
      case ERecurrencePresetNames.Weekly:
        return `Every week on ${format(new Date(this.selectedDate), 'EEEE')}`;
      case ERecurrencePresetNames.Monthly:
        return `Every month on the ${format(new Date(this.selectedDate), 'do')}`;
      case ERecurrencePresetNames.Quarterly:
        return 'Every quarter';
      default:
        return preset.friendlyName;
    }
  }

  private openCustomFrequencyDialog(): void {
    const data: ICustomFrequencyModalData = {
      startDate: this.selectedDate,
      startTime: this.selectedTime,
      recurrenceRule: this.recurrenceRule.value,
    }

    this.modalService
      .openModal(CustomFrequencyModalComponent, {
        data,
        disableClose: true,
        autoFocus: false
      })
      .afterClosed()
      .subscribe((value) => {
        if (value) {
          const { rule, description } = value;

          this.recurrenceRule.patchValue(rule);

          // update the description
          this.description.patchValue(description);

          // update the selected frequency to the new frequency
          this.presetType.patchValue(ERecurrencePresetNames.Custom);

          // recreate schedule options
          this.createScheduleOptions();

          // set the previous preset type to the current value
          this.previousPresetType = this.presetType.value;

          // get the selected option label
          this.getSelectedOptionLabel();
        } else {
          // reset the preset type to the previous value
          this.presetType.patchValue(this.previousPresetType);
        }
      });
  }

  showHint(frequency: HTMLElement):void {
    this.renderer.addClass(frequency, 'show-hint');
  }

  hideHint(frequency: HTMLElement):void {
    setTimeout(() => {
      this.renderer.removeClass(frequency, 'show-hint');
    }, 200);
  }

  pauseSchedule(): void {
    this.isPaused.setValue(true);
    this.setScheduleBtnState();
    this.setScheduleBtnIcon();
  }

  resumeSchedule(): void {
    this.isPaused.setValue(false);
    this.setScheduleBtnState();
    this.setScheduleBtnIcon();
  }

  get recurrenceRule(): AbstractControl {
    return this.frequencyForm.get('recurrenceRule');
  }

  get isPaused(): AbstractControl {
    return this.frequencyForm.get('isPaused');
  }

  get description(): AbstractControl {
    return this.frequencyForm.get('description');
  }

  get presetType(): AbstractControl {
    return this.frequencyForm.get('presetType');
  }
}
