import { AngularNames, Names, ServerErrorCodes } from '@app/moonbeamConstants';
import { DeleteControllerBase, IDeleteScope, IDisplayItem } from '../deleteControllerBase';
import { IWebJourney } from '@app/components/web-journey/web-journey.models';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { TerminateActiveRunsModalService } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';
import { ModalType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';


export interface IDeleteWebJourneyScope extends IDeleteScope<IWebJourney> {}

  export class DeleteWebJourneyController extends DeleteControllerBase<IWebJourney> {

    static $inject = [
      AngularNames.scope,
      DeleteWebJourneyController.Dependencies.deleteHandler,
      Names.Services.webJourneyAPI,
      Names.NgServices.terminateActiveRunsModalService
    ];

    constructor(protected $scope: IDeleteWebJourneyScope,
                protected onDeleteHandler: Function,
                private webJourneyApiService: IWebJourneyApiService,
                private terminateActiveRunsModalService: TerminateActiveRunsModalService) {
        super($scope, onDeleteHandler);
    }

    protected createDisplayItem(item: IWebJourney): IDisplayItem {
      return {
        name: item.name,
        createdAt: item.createdAt,
        type: 'Web Journey'
      };
    }

    protected delete(item: IWebJourney): angular.IPromise<boolean> {
      return this.webJourneyApiService.deleteJourney(item.id);
    }

    protected handleError(error: any): void {
      if (error.errorCode === ServerErrorCodes.alreadyRunning) {
        this.close();
        this.terminateActiveRunsModalService.showTerminateActiveRunModal(ModalType.WebJourney);
      }
    }
}
