import { Component } from '@angular/core';
import { IMenuItem } from '@app/components/shared/components/op-menu/op-menu.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-menu',
  templateUrl: './cl-menu.component.html',
  styleUrls: ['./cl-menu.component.scss']
})
export class ComponentLibraryMenuComponent {

  menuItems: IMenuItem[] = [
    {
      icon: 'icon-edit',
      label: 'Edit',
      onClick: () => {},
    },
    {
      icon: 'icon-delete',
      label: 'Delete',
      onClick: () => {},
    },
  ];

}
