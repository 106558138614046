import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {
  ITagPrivacyComplianceResponse,
  ITagPrivacyComplianceTag,
  ITagPrivacyPagesTagApiBody
} from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { MatTableDataSource } from '@angular/material/table';
import { ITagComplianceRow } from './tag-compliance-table.models';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import {
  EBarChartDirection,
  EBarChartTextPosition
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.constants';
import {
  AuditReportUrlBuilders,
  EChartColor,
  formatPaginator
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import { getPercent } from '@app/components/utilities/number.utils';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import {
  AddConsentCategoriesModalComponent,
} from '@app/components/audit-reports/reports/privacy/add-consent-categories-modal/add-consent-categories-modal.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  ReprocessConfirmationSnackbarService
} from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { StringUtils } from '@app/components/utilities/StringUtils';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { filter, takeUntil } from 'rxjs/operators';
import {
  EConsentCategoryType,
  IConsentCategories,
  IConsentCategorySnapshot,
  IConsentCategoryTag
} from '@app/components/consent-categories/consent-categories.models';
import { DecimalPipe } from '@angular/common';
import { AccountsService } from '@app/components/account/account.service';
import { TagsPrivacyTagComplianceExportMenuData } from './../../privacy-tags.models';
import { Subject } from 'rxjs';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import {
  EFreeTrialAdModalType
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import {
  FreeTrialAdModalComponent
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import { Router } from '@angular/router';
import { IAuditEditorModalData } from '@app/components/audit/audit-editor/audit-editor.models';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-compliance-table',
  templateUrl: './tag-compliance-table.component.html',
  styleUrls: [ './tag-compliance-table.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagComplianceTableComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() set items(data: ITagPrivacyComplianceResponse) {
    if (data) {
      this.dataSource.data = this.prepareItems(data.tags);
      this.setPagination(data.metadata);
      this.scrollToTop();
    }
  }

  @Input() auditId: number;
  @Input() auditName: string;
  @Input() runId: number;
  @Input() state: EFilterSpinnerState;
  @Input() filteredPageCount: number;
  @Input() apiFilters: IAuditReportApiPostBody;
  @Input() auditConsentCategories: IConsentCategories[];
  @Input() runConsentCategories: IConsentCategorySnapshot[];
  @Input() exportReportConfig: TagsPrivacyTagComplianceExportMenuData;

  @Output() onSort = new EventEmitter<Sort>();
  @Output() onPaginate = new EventEmitter<number>();
  @Output() localFilter = new EventEmitter<ITagPrivacyPagesTagApiBody | null>();
  @Output() onGlobalFilterByCategoryName = new EventEmitter<number>();
  @Output() onManageConsentCategories = new EventEmitter<void>();

  readonly EConsentCategoryType = EConsentCategoryType;
  ccActionMenuOpen: boolean = false;
  statusActionMenuOpen: boolean = false;
  ccActionIndex = null;

  dataSource = new MatTableDataSource<ITagComplianceRow>();
  isFiltered = false;
  selectedRow: ITagComplianceRow;
  userIsReadOnly: boolean;

  private isVisitorMode: boolean;
  private destroySubject = new Subject<void>();

  paginationState: { total: number, size: number } = {
    total: 0,
    size: 0
  };
  columns: string[] = [
    'tag_name',
    'compliance_status',
    'consent_category_name',
    'page_count',
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('scrollTop', { read: ElementRef }) scrollTop: ElementRef;

  constructor(
    private filterBarService: AuditReportFilterBarService,
    private opModalService: OpModalService,
    private ccService: ConsentCategoriesService,
    private reprocessConfirmationSnackbarService: ReprocessConfirmationSnackbarService,
    private decimalPipe: DecimalPipe,
    private accountsService: AccountsService,
    private applicationChromeService: ApplicationChromeService,
    private router: Router
  ) {
    this.userIsReadOnly = this.accountsService.userIsReadOnly();

    this.applicationChromeService.isVisitorMode$
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: isVisitorMode => this.isVisitorMode = isVisitorMode
      });
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe((sort: Sort) => this.onSort.emit(sort));
    this.paginator.page.subscribe(({pageIndex}) => this.onPaginate.emit(pageIndex));
    this.formatPaginator();
  }

  ngOnChanges() {
    if (this.apiFilters) {
      this.selectedRow = null;
      this.isFiltered = false;
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  prepareItems(data: ITagPrivacyComplianceTag[]): ITagComplianceRow[] {
    return data.map(item => {
      return {
        ...item,
        tagIcon: UiTagService.getTagIconUrl(item.tagId),
        barChartSettings: {
          state: EFilterSpinnerState.None,
          displayPills: false,
          calcAsPercentage: true,
          displayPercentSymbol: false,
          textPosition: EBarChartTextPosition.Start
        },
        onPages: {
          chartData: [
            {
              name: item.tagAccount,
              colorClass: item.consentCategoryComplianceStatus ? item.consentCategoryComplianceStatus === EConsentCategoryType.APPROVED
                ? EChartColor.Green
                : EChartColor.Red
                : EChartColor.OPYellow,
              filtered: false,
              value: getPercent(item.filteredPageCount, this.filteredPageCount),
              displayValue: item.filteredPageCount
            }
          ],
          barDirection: EBarChartDirection.LTR,
          uniqueIdentifier: `tag-${ StringUtils.generateRandom(5) }-chart`,
        }
      };
    });
  }

  private scrollToTop() {
    this.scrollTop.nativeElement.scrollIntoView(false);
  }

  private setPagination({ pagination }) {
    this.paginationState.total = pagination.totalCount;
    this.paginationState.size = pagination.pageSize;
  }

  isLineSelected(item: ITagComplianceRow) {
    return this.selectedRow === item;
  }

  filterByPage(item: ITagComplianceRow) {
    if (this.selectedRow === item) {
      this.localFilter.emit(null);
      this.selectedRow = null;
      this.isFiltered = false;

      return;
    }
    this.selectedRow = item;
    this.isFiltered = true;
    this.localFilter.emit({ tagId: item.tagId, tagAccount: item.tagAccount });
  }

  isStatusApproved({ consentCategoryComplianceStatus }): boolean {
    return consentCategoryComplianceStatus === EConsentCategoryType.APPROVED;
  }

  globalFilterByStatus({ consentCategoryComplianceStatus }) {
    this.filterBarService.addConsentCategoryStatusFilter(consentCategoryComplianceStatus);
  }

  globalFilterByCategoryName({ consentCategorySnapshotId }): void {
    this.onGlobalFilterByCategoryName.emit(consentCategorySnapshotId);
  }

  addToConsentCategoryClicked(item: ITagComplianceRow, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      const ccTagItem: IConsentCategoryTag = {
        tagId: item.tagId,
        accounts: item.tagAccount ? [item.tagAccount] : [],
        anyAccount: !item.tagAccount,
      };

      this.addToConsentCategory(ccTagItem);
    }
  }

  private addToConsentCategory(item: IConsentCategoryTag, event?: MouseEvent) {
    this.opModalService
      .openModal(AddConsentCategoriesModalComponent, {
        data: {
          runId: this.runId,
          auditId: this.auditId,
          items: {
            cookies: [],
            tags: [item],
            requestDomains: [],
          }
        },
        autoFocus: false
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {});
  }

  private openFreeTrialAdModal() {
    const data = {
      type: EFreeTrialAdModalType.CONSENT_CATEGORY
    };

    this.opModalService.openModal(FreeTrialAdModalComponent, { data });
  }

  globalFilterByTagName({ tagName, tagId }) {
    this.filterBarService.addTagIdFilter(tagName, tagId);
  }

  getConsentCategoryFromAuditById(id: number) {
    return this.auditConsentCategories?.find(cc => cc.id === id);
  }

  getTooltip(row: ITagComplianceRow) {
    const consentCategoryId = this.getConsentCategoryFromRun(row)?.consentCategoryId;

    if (!consentCategoryId) {
      return 'This Consent Category has been deleted and cannot be edited.';
    } else if (!this.getConsentCategoryFromAuditById(consentCategoryId)) {
      return 'This Consent Category is no longer applied to future runs of this audit. Edit it from the Consent Category management screen.';
    }
  }

  private getConsentCategoryFromRun(row: ITagComplianceRow) {
    return this.runConsentCategories.find(cc => cc.consentCategorySnapshotId === row.consentCategorySnapshotId);
  }

  private formatPaginator(): void {
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) =>
      formatPaginator(page, pageSize, length, this.decimalPipe);
  }

  handleSetFilterAndNavigate(tag): void {
    this.globalFilterByTagName(tag);
    this.router.navigateByUrl(AuditReportUrlBuilders.tagInventory(this.auditId, this.runId));
  }

  openAuditEditorToConsentCategoryStandard(event): void {
    event.stopPropagation();

    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else {
      const data: IAuditEditorModalData = {
        auditId: this.auditId,
        runId: this.runId,
        step: 2,
        standardsTab: EStandardsTabs.ConsentCategories,
        disableNavigationButtons: true
      };

      this.opModalService.openFixedSizeModal(AuditEditorComponent, {disableClose: true, data}, 'op-audit-editor');
    }
  }
}
