import { Names } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { RouterModule } from '@angular/router';
import { FullScreenView } from './fullScreenView/fullScreenCreatorDirective';
import { ManageNewCardsService } from './manage/manageNewCardsService';
import { NgModule } from '@angular/core';
import { downgradeInjectable, downgradeComponent } from '@angular/upgrade/static';
import { FooterNgModule } from '@app/components/footerNg/footer.module';
import { SharedModule } from '@app/components/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FullScreenViewComponent } from './fullScreenViewNg/full-screen-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectApiService } from '../manage/shared/services/multi-select-api/multi-select-api.service';
import { ManageCardsDataService } from '../manage/shared/services/manage-cards-data/manage-cards-data.service';
import { ReportCardsApiService } from '../manage/shared/services/report-cards-api/report-cards-api.service';
import { TopBarComponent } from './top-bar/top-bar.component';

export default angular.module('navigationModule', [])
  .directive('fullScreenView', FullScreenView.Factory())
  .service(Names.Services.manageNewCards, ManageNewCardsService)
  .service(Names.Services.multiSelectAPI, downgradeInjectable(MultiSelectApiService))
  .service(Names.Services.reportCards, downgradeInjectable(ReportCardsApiService))
  .service(Names.Services.manageCardsData, downgradeInjectable(ManageCardsDataService))
  .directive('topBar', downgradeComponent({ component: TopBarComponent }))
  .name;

@NgModule({
  imports: [
    CommonModule,
    FooterNgModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    FullScreenViewComponent
  ],
  providers: [
    ManageCardsDataService
  ],
  exports: [
    FullScreenViewComponent,
  ]
})
export class NavigationNgModule { }
