import { Component, EventEmitter, Input, Output, SimpleChanges, AfterViewInit, OnChanges } from '@angular/core';
import { confettiColors } from '@app/components/audit/audit.constants';
import { StorageService } from '@app/components/shared/services/storage.service';
import { Names } from '@app/moonbeamConstants';
import JSConfetti from 'js-confetti'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'no-triggered-alerts',
  templateUrl: './no-triggered-alerts.component.html',
  styleUrls: ['./no-triggered-alerts.component.scss']
})
export class NoTriggeredAlertsComponent implements AfterViewInit, OnChanges {

  @Input() canvas: HTMLCanvasElement;
  @Input() popoverOpen: boolean;
  @Input() alertsCount: number;

  @Output() routeToAlertsSummary: EventEmitter<void> = new EventEmitter();

  confetti: JSConfetti;

  constructor(private storageService: StorageService) {}

  ngAfterViewInit(): void {
    this.confetti = new JSConfetti({ canvas: this.canvas });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('popoverOpen')) {
      if (!this.confetti) return;

      // fire confetti once on initial open
      if (this.popoverOpen) {
        if (this.storageService.getValue(Names.GlobalStateKeys.disableConfetti)) return;
        setTimeout(() => this.launchConfetti(), 750);
      }
    }
  }

  launchConfetti(): void {
    this.confetti.addConfetti({
      confettiColors,
      confettiRadius: 4,
      confettiNumber: 500
    });
  }
}
