import { Injectable } from '@angular/core';
import { ApiService } from '../core/services/api.service';
import { Observable, of } from 'rxjs';
import { environment } from '@app/environments/environment';
import {
  IAlertsFilters,
  IAlertsLibraryResponse, ISort
} from '@app/components/alerts-library/alerts-library.models';
import { HttpParams } from '@angular/common/http';
import { ILabel } from '@app/components/shared/services/label.service';
import { IPagination } from '@app/components/shared/components/selectable-table/selectable-table.models';

@Injectable({
  providedIn: 'root'
})
export class AlertsLibraryService {

  apiRoot = `${environment.apiV3Url}alerts/`;

  constructor(private apiService: ApiService) {}

  getAll(sorting: ISort, pagination: IPagination, filters: IAlertsFilters): Observable<IAlertsLibraryResponse> {
    const params = new HttpParams()
      .set('page', pagination.currentPageNumber)
      .set('size', pagination.pageSize)
      .set('sortBy', sorting.sortBy)
      .set('sortDesc', sorting.sortDesc);

    return this.apiService.post(this.apiRoot + 'library', filters || {}, { params });
  }

  updateAlertLabels(alertId: number, labels: ILabel[]): Observable<void> {
    return this.apiService.put(this.apiRoot + `${alertId}/labels`, {
      labels: labels.map(label => label.id)
    });
  }
}
