<component-template componentName="Actions Menu">
  <component-library-section sectionName="Overview" selector="<actions-menu>">
    <p>This creates a button that opens a menu when clicked. The button uses the vertical 3 dots icon to represent the menu trigger. The consumer of this component only needs to pass in menu items and the outer menu will be generated by the component. This is typically used in a table as the last column.</p>
    <p>It also has an optional trigger menu indicator that is a small arrow that shows the menu is open or closed.</p>

    <div class="actions-menu-example">
      <div><b>Example 1</b></div>
      <br>
      <actions-menu>
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
        <button mat-menu-item>Item 3</button>
      </actions-menu>
    </div>

    <div class="actions-menu-example">
      <div><b>Example 2</b></div>
      <br>
      <actions-menu [showTriggerMenuIndicator]="true">
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
        <button mat-menu-item>Item 3</button>
      </actions-menu>
    </div>
  </component-library-section>
</component-template>