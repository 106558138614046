<form [formGroup]="formData">
  <div class="title-container">
    <span class="main-text">IF</span>
    <span class="matcher-container">
      <mat-form-field appearance="outline" floatLabel="always" class="match-all-filters-control">
        <mat-select formControlName="matchAllFilters">
          <mat-option class="slim-mat-option" *ngFor="let matcher of ConditionsMatchers" [value]="matcher.value">
            {{matcher.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </span>
    <span class="main-text">of the following items are true:</span>
  </div>
  
  <form [formGroup]="filtersGroup">
    <div *ngFor="let filterGroup of filtersArray?.controls; let i = index; let last = last"
         formArrayName="If"
         class="filters" [class.last]="last">
      <if-condition [formData]="filterGroup"
                    [allTags]="allTags"
                    [hidePageMatching]="hidePageMatching"
                    (onRemoveMainCondition)="removeMainCondition(i)">
      </if-condition>
      <div class="match-marker" *ngIf="!last">
        {{matchAllFiltersControl?.value ? 'AND' : 'OR'}}
      </div>
    </div>
  
    <op-button-2021 labelText="Add another precondition"
                    matIcon="control_point"
                    buttonType="filled"
                    [disabled]="filtersArray?.invalid"
                    (buttonAction)="addMainCondition()">
    </op-button-2021>
  </form>
</form>