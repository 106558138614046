import { EMPTY, Observable, throwError as observableThrowError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { catchError, filter, map } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { AngularNames, AuthenticationEvents, HttpStatuses, ServerErrorCodes } from '@app/moonbeamConstants';
import { IApiErrorResponse } from '../services/api.service';

@Injectable()
export class AuthResponseInterceptor implements HttpInterceptor {

  constructor(
    @Inject(AngularNames.rootScope) private rootScope: any,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // filter out non-HttpResponse events. Non-200-level status codes will already be in the error stream and will skip ahead to the catchError()
      filter(event => event instanceof HttpResponse),
      // map the HttpResponse to another HttpResponse object. This is where we can perform additional validation on success api responses
      map(event => {
        const response = event as HttpResponse<any>;
        return this.handleResponseSuccess(response);
      }),
      // catchError() is called for non-200-level status codes and for any thrown exceptions in the map() function
      catchError(response => {
        if (response instanceof HttpErrorResponse) return this.handleResponseError(response);
        else return observableThrowError(response);
      })
    );
  }

  private handleResponseSuccess(response: HttpResponse<any>): HttpResponse<any> {
    if (!this.isSuccess(response)) {
      throw {
        errorCode: 500,
        statusCode: response.status,
        message: 'This should never happen: Unsuccessful API call ended up in the success stream.'
      } as IApiErrorResponse;
    }

    const body = response.body;
    if (this.allowsBody(response) && !body) {
      // TBD - does arch committee care about APIs returning 200s with no body?

      // throw {
      //   errorCode: 500,
      //   statusCode: response.status,
      //   message: 'Body is missing in the response.'
      // } as IApiErrorResponse;
    }

    return response;
  }

  private isSuccess(response: any): boolean {
    return HttpStatuses.ok <= response.status && response.status < HttpStatuses.multipleChoices;
  }

  private allowsBody(response: any): boolean {
    return response.status !== HttpStatuses.noContent;
  }

  private handleResponseError(response: HttpErrorResponse): Observable<never> {
    switch (response.status) {
      case HttpStatuses.unauthorized:
        this.rootScope.$broadcast(AuthenticationEvents.notAuthenticated, response);
        // // Suppress the propagation of 401 errors to the error stream.
        // // Trust that the broadcast will escort the user to the login screen.
        // // For now, return empty so the Observable cleans up but
        // // never executes any handlers.
        // return EMPTY;
        break;
      case HttpStatuses.forbidden:
        if (response.error && response.error.error && response.error.error.errorCode == ServerErrorCodes.credentialsExpired) {
          this.rootScope.$broadcast(AuthenticationEvents.credentialsExpired);
        }
        break;
    }

    // special handling for 50x errors
    if (response.status >= 500) {
      /**
       * TODO: Uncomment after monitoring Sentry for a bit to see how often this is happening
       */
      // show user a snackbar to let them know something might be broken on the page
      // const message = 'An error has occurred. You may need to refresh or retry your operation. If the issue persists, please reach out to customer support.';
      // this.snackbar.open(message, 'OK', { duration: 5000, horizontalPosition: 'center', verticalPosition: 'top' });

      // send error to sentry
      Sentry.captureMessage(`500-level error: ${response.message}`, {
        level: 'error' as Sentry.SeverityLevel,
        extra: {
          statusCode: response.status,
          statusText: response.name,
          errorCode: response.error,
          message: response.message,
          url: response.url
        }
      });
    }

    /**
     * API v3 errors are shaped differently than
     * our v2 errors so this messages the v3 errors
     * to fit with our current handling of errors
     */
    if (response.url.indexOf('/v3/') > -1) {
      return observableThrowError({
        code: response.status,
        statusCode: response.status,
        errorCode: response.error,
        message: response.message,
        status: response.name, // not part of IApiErrorResponse but kept for back compatibility
        url: response.url
      } as IApiErrorResponse);
    } else {

      // apiService.ts and api.service.ts both expect error to be an object
      // or will just display a message with 'Unknown Error'. Handle cases where have error
      // information from some v2 endpoints to display a more meaningful error message
      const error = {
        data: { ...response.error },
        ...response.error,
        originalError: response.error,
        statusCode: response.status, // v2 can have access to status code too
      }

      // If there isn't already an error property, set it to the original error
      if (!error.error) {
        error.error = error.originalError;
      }

      delete error.originalError;

      return observableThrowError(error);
    }
  }
}
