export class EmailListValidator {

    // Also used in api
    static emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

    static validateOne = function(email: string) {
      if (!email) {
        return true;
      }
      if (!EmailListValidator.emailRegex.test(email)) {
        return false;
      }
      return true;
    };

    static validate = function(emails: string) {
      if (!emails) {
        return true;
      }

      let allEmails = emails.split('\n').map(email => email.trim()).filter(email => email != '');

      for (var email of allEmails) {
        if (!EmailListValidator.emailRegex.test(email)) {
          return false;
        }
      }
      return true;
    };
  }

  export class UrlListValidator {

    // regex adapted from here https://gist.github.com/dperini/729294
    // api uses the same regex
    static urlRegex = /^(?:(?:(?:https?):)\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[\/?#]\S*)?$/i;

    static urlWithOptionalProtocolRegex = /^(?:(?:(?:https?):)\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[\/?#]\S*)?$/i;

    static regexCharactersRegex = /[{}^\\|\[\]]/i;

    static validateOne = function (url: string) {
      if (!url) {
        return true;
      }
      if (!UrlListValidator.urlRegex.test(url) || UrlListValidator.regexCharactersRegex.test(url)) {
        return false;
      }
      return true;
    };

    static validateWithOptionalProtocolOne = function (url: string) {
      if (!url) {
        return true;
      }
      if (!UrlListValidator.urlWithOptionalProtocolRegex.test(url) || UrlListValidator.regexCharactersRegex.test(url)) {
        return false;
      }
      return true;
    };

    static validate = function (urls: string) {
      if (!urls) {
        return true;
      }
      for (var url of urls.split('\n')) {
        if (!UrlListValidator.urlRegex.test(url) || UrlListValidator.regexCharactersRegex.test(url)) {
          return false;
        }
      }
      return true;
    };
  }
  export class DeepLinkValidator {
    static validDeepLink = /^[A-z]+:\/\/(?:\S*[\/?#]?)?$/i;

    static validate = function(deepLink) {
      return DeepLinkValidator.validDeepLink.test(deepLink) || EmailListValidator.emailRegex.test(deepLink);
    };
  }
