<div class="cc-edit-cookies">
  <div class="search-add-wrap">
    <op-clearable-input placeholderText="Search by cookie name or domain"
                        [emitKeyboardEvent]="true"
                        appearance="OUTLINED"
                        class="table-filter-input"
                        (onUserInput)="applyFilter($event)">
    </op-clearable-input>
    <div class="global-add-delete">
      <button type="button"
              class="add-new-cookie add-new-cookie-yellow"
              (click)="openAddNewCookieModal()">
        <mat-icon>add_circle_outline</mat-icon>
        <span class="add-text">Add cookie</span>
      </button>
      <mat-divider [vertical]="true" *ngIf="dataSource.data.length > 0"></mat-divider>
      <button class="add-new-cookie"
              type="button"
              *ngIf="dataSource.data.length > 0"
              (click)="openConfirmDeleteAllCookies()"
              matTooltip="Delete All Cookies"
              matTooltipPosition="above">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <form [formGroup]="cookieForm">
    <div class="table-wrap" formArrayName="cookies">
      <table mat-table [dataSource]="dataSource"
             class="cc-edit-cookies-table"
             matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>COOKIE NAME</th>
          <td mat-cell *matCellDef="let cookie; let i = index;" [formGroupName]="getActualIndex(cookie)">
            <div class="cell-wrap">
              <mat-form-field appearance="outline" class="in-table">
                <input matInput
                       formControlName="name"
                       [placeholder]="cookie.nameMatchAny ? '.*' : 'Enter cookie name'"
                       (blur)="updateDataSource()">
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="nameCheckboxes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let cookie; let i = index;" [formGroupName]="getActualIndex(cookie)">
            <div class="checkbox-wrap">
              <mat-checkbox color="primary"
                            formControlName="nameMatchAny"
                            (change)="onNameMatchAnyChanged($event.checked, cookie, i)">Any Cookie Name
              </mat-checkbox>
              <mat-checkbox color="primary"
                            formControlName="nameRegex"
                            (change)="onNameRegexChanged($event.checked, cookie, i)">Match RegEx
              </mat-checkbox>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="domain">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>COOKIE DOMAIN</th>
          <td mat-cell *matCellDef="let cookie; let i = index;" [formGroupName]="getActualIndex(cookie)">
            <div class="cell-wrap">
              <mat-form-field appearance="outline" class="in-table">
                <input matInput placeholder="Enter domain (or leave empty for any domain)" formControlName="domain"
                       (blur)="updateDataSource()">
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="domainRegEx">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let cookie; let i = index;" [formGroupName]="getActualIndex(cookie)">
            <div class="checkbox-wrap">
              <mat-checkbox color="primary" formControlName="domainRegex">Match RegEx</mat-checkbox>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef class="last-col"></th>
          <td mat-cell *matCellDef="let cookie" align="right" class="last-col">
            <div class="icon-wrap">
              <mat-icon class="last-col-icon copy-icon"
                        matTooltip="Copy cookie"
                        matTooltipPosition="above"
                        (click)="copyCookie(cookie)">
                content_copy
              </mat-icon>
              <mat-icon class="last-col-icon delete-icon"
                        matTooltip="Delete cookie"
                        matTooltipPosition="above"
                        (click)="deleteCookie(cookie)">
                delete
              </mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div [hidden]="cookies.value.length <= minCookiesToShowPaginator">
        <mat-paginator [pageSize]="perPage"
                       [pageSizeOptions]="pageSizeOptions"
                       [showFirstLastButtons]="true"
                       (page)="savePageSizeToStorage($event)"
                       aria-label="Select cookies table page size">
        </mat-paginator>
      </div>

      <div class="spinner-container" *ngIf="loading">
        <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
      </div>
    </div>
  </form>
</div>
