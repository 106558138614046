export interface IWJResultsVariable {
  name: string;
  previousValue: string;
  currentValue: string;
  excluded: boolean;
  outcome: string;
  description?: string;
}

export class WJResultsVariable implements IWJResultsVariable{
  constructor(public name: string,
              public previousValue: string,
              public currentValue: string,
              public excluded: boolean,
              public outcome: string,
              public description?: string) {}
}
