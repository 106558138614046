import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { IAuditModel } from '@app/components/modals/modalData';
import {
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { Router } from '@angular/router';
import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { EAuditStatus } from '@app/components/audit/audit-success-snackbar/audit-success-snackbar.constants';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { IAuditEditorModalData } from '@app/components/audit/audit-editor/audit-editor.models';
import { StorageService } from '@app/components/shared/services/storage.service';

@Component({
  selector: 'op-audit-success-snackbar',
  templateUrl: './audit-success-snackbar.component.html',
  styleUrls: ['./audit-success-snackbar.component.scss']
})
export class AuditSuccessSnackbarComponent implements OnInit {
  EAuditStatus = EAuditStatus;
  message: string;
  showRunNowBtn: boolean;
  showCloseBtn: boolean = false;
  showCreateBtn: boolean = true;
  auditStatus: EAuditStatus;

  recurrenceEnabled: boolean = false;

  constructor(
    private router: Router,
    private snackBarRef: MatSnackBarRef<AuditSuccessSnackbarComponent>,
    private auditDataService: IAuditDataService,
    @Inject(MAT_SNACK_BAR_DATA) public data: { audit: IAuditModel, extras: IAuditEditorModalData },
    private dateService: DateService,
    private modalService: OpModalService,
    private storageService: StorageService
  ) {
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
  }

  ngOnInit(): void {
    this.setData();
  }

  runAuditNow(): void {
    this.auditDataService.runNow(this.data.audit);
    this.snackBarRef.dismiss();
    this.router.navigateByUrl(this.router.url);
  }

  closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }

  setData(): void {
    if (this.recurrenceEnabled) {
      if (this.data.audit.nextRun === null) {
        if (this.data.audit?.schedule?.isPaused) {
          this.auditStatus = EAuditStatus.Paused;
        } else {
          this.auditStatus = EAuditStatus.Unscheduled;
        }
        this.showRunNowBtn = false;
      } else {
        const now = new Date().getTime();
        const runDateMs = this.data.audit.nextRun.getTime();

        if (this.data.audit?.schedule?.isPaused) { // audit is paused
          this.showRunNowBtn = true;
          this.auditStatus = EAuditStatus.Paused;
        } else if (now > runDateMs) { // audit is scheduled to run now
          this.showRunNowBtn = false;
          this.auditStatus = EAuditStatus.Running;
        } else { // audit is scheduled to run in the near future
          this.showRunNowBtn = true;
          this.auditStatus = EAuditStatus.Scheduled;
        }

        // Override showRunNowBtn if it was passed in the data
        this.showRunNowBtn = this.data.extras.showRunNowBtn ?? this.showRunNowBtn;
        this.showCreateBtn = this.data.extras.showCreateBtn ?? this.showCreateBtn;
        this.showCloseBtn = this.data.extras.showCloseBtn;
      }
    } else {
      const now = new Date().getTime();
      const runDateMs = this.data.audit.nextRun.getTime();
      const runDateYear = this.data.audit.nextRun.getFullYear();

      if (runDateYear >= 2037) { // audit is paused
        this.showRunNowBtn = true;
        this.auditStatus = EAuditStatus.Paused;
      } else if (now > runDateMs) { // audit is scheduled to run now
        this.showRunNowBtn = false;
        this.auditStatus = EAuditStatus.Running;
      } else { // audit is scheduled to run in the near future
        this.showRunNowBtn = true;
        this.auditStatus = EAuditStatus.Scheduled;
      }

      // Override showRunNowBtn if it was passed in the data
      this.showRunNowBtn = this.data.extras.showRunNowBtn ?? this.showRunNowBtn;
      this.showCreateBtn = this.data.extras.showCreateBtn ?? this.showCreateBtn;
      this.showCloseBtn = this.data.extras.showCloseBtn;
    }
  }

  getRunDate(): string {
    return this.dateService.formatDate(new Date(this.data.audit.nextRun), EDateFormats.dateNineteen);
  }

  goToAudit() {
    this.router.navigateByUrl(AuditReportContainerUrlBuilders.base(this.data.audit.id, 0));
  }

  createAnotherAudit(): void {
    this.snackBarRef.dismiss();

    this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data: {} })
      .afterClosed()
      .subscribe((audit?: IAuditModel) => {
        if (audit) this.router.navigateByUrl(this.router.url);
      });
  }
}
