import { Component, ElementRef, forwardRef, Input, OnChanges, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { IAuditFilter } from '@app/components/audit/audit.models';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validators,
  Validator,
  NG_VALIDATORS
} from '@angular/forms';
import { Subject } from 'rxjs';

import { IFilterFormControls } from '@app/components/audit/audit-editor/filter-list/filter-list.models';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  ELearnMoreOptionsLinks
} from '@app/components/audit/audit-setup-form/audit-setup-form.constants';

const FILTER_LIST_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FilterListComponent),
  multi: true
};

const FILTER_LIST_CONTROL_VALIDATION = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => FilterListComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
  providers: [
    FILTER_LIST_CONTROL_VALUE_ACCESSOR,
    FILTER_LIST_CONTROL_VALIDATION
  ],
})
export class FilterListComponent
  implements ControlValueAccessor, Validator, OnChanges, OnDestroy {
  @Input() title: string = 'Include';
  @Input() maxScanLimit?: number;
  @Input() useScanLimit?: boolean = true;
  @Input() placeholder: string = '';
  @Input() showCollapseBtn: boolean = true;

  private destroy$ = new Subject();
  filterForm: UntypedFormGroup;
  LEARN_MORE = ELearnMoreOptionsLinks;

  filterLimitMatch: boolean = true;
  includeLimitTotal: number = 0;
  mismatchMessage: string;
  lastControl: AbstractControl;

  filters: (IAuditFilter | string)[];
  isHidden = false;

  onTouched: () => void;
  onChange: (filters: IAuditFilter[] | string[]) => void;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.createForm();
    this.createFormFieldListeners();
  }

  ngOnChanges() {
    this.validateIncludeLimit();
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  createForm(): void {
    this.filterForm = this.formBuilder.group({
        filterControls: this.formBuilder.array([
          this.createFilterFormGroup({value: '', includeLimit: 0} as IAuditFilter)
        ])
      }
    );

    this.updateLastControlPointer();
  }

  createFilterFormGroup(filter: IAuditFilter | string): UntypedFormGroup {
    let filterGroup = this.formBuilder.group({});

    if (this.useScanLimit) {
      filterGroup.addControl('value', new UntypedFormControl((filter as IAuditFilter).value, [OPValidators.regex]));
      filterGroup.addControl('includeLimit', new UntypedFormControl(
          (filter as IAuditFilter).includeLimit || 0,
          [Validators.required, Validators.min(0), Validators.pattern('\\d*')]
        ));
    } else {
      filterGroup.addControl('value', new UntypedFormControl(filter, OPValidators.regex));
    }

    return filterGroup;
  }

  createFormFieldListeners(): void {
    this.filterForm.valueChanges
      .pipe(
        debounceTime(250),
        takeUntil(this.destroy$)
      )
      .subscribe(model => {
        this.emitChanges(model);
        model.filterControls.forEach((v, i) => this.useScanLimit ? this.filters[i] = v : this.filters[i] = v.value);
      });
  }

  validate(c: AbstractControl): ValidationErrors {
    return this.filterForm.valid ? null : {filterForm: {valid: false, message: 'Invalid filter form'}};
  }

  getFilterControlsByIndex(idx: number): UntypedFormGroup {
    return (this.filterForm.get('filterControls') as UntypedFormArray).at(idx) as UntypedFormGroup;
  }

  get filterControls(): UntypedFormArray {
    return this.filterForm.get('filterControls') as UntypedFormArray;
  }

  set filterControls(array: UntypedFormArray) {
    this.filterForm.setControl('filterControls', array);
  }

  emitChanges(changes: IFilterFormControls): void {
    this.onChange && this.onChange(
      this.useScanLimit ? changes.filterControls : changes.filterControls.map(filter => filter.value)
    );
  }

  /**
   * This is called by the forms API on initialization.
   * It receives function that should be called when the control's value changes in the UI.
   */
  registerOnChange(fn: (newValue: any) => void): void {
    this.onChange = fn;
  }

  /**
   * This is called by the forms API on initialization.
   * It receives function that should be called when the control should be considered blurred or "touched".
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * This method will be called by the forms API to write to the view when programmatic (model -> view) changes are requested.
   */
  writeValue(filters: IAuditFilter[]): void {
    this.filters = filters;
    this.updateForm(filters);
    this.validateIncludeLimit();
  }

  updateForm(filters: (IAuditFilter | string)[]): void {
    this.filterControls = this.formBuilder.array([]);
    const newFilterControlArray = filters?.map(filter => this.createFilterFormGroup(filter));
    this.filterControls = this.formBuilder.array(newFilterControlArray ?? []);
  }

  private makeFilter(): IAuditFilter | string {
    return this.useScanLimit ? { value: '', includeLimit: 0 } : '';
  }

  addFilter(): void {
    this.filters.push(this.makeFilter());
    this.filters = [...this.filters];
    this.updateForm(this.filters);
    this.updateLastControlPointer();
  }

  removeFilter(i: number): void {
    this.filters.splice(i, 1);
    this.filters = [...this.filters];
    this.updateForm(this.filters);
    this.updateLastControlPointer();
  }

  updateLastControlPointer() {
    let lastIndex = this.filterControls.controls.length - 1;
    this.lastControl = this.filterControls.controls[lastIndex];
  }

  markAsTouched(): void {
    this.filterControls.controls.forEach((formGroup: UntypedFormGroup) => {
      Object.values(formGroup.controls).forEach(control => {
        control.markAsTouched();
        control.updateValueAndValidity();
      });
    });
    this.onTouched();
  }

  onIncludeLimitChange() {
    this.validateIncludeLimit();
  }

  private validateIncludeLimit() {
    let listLimits = this.filterForm.get('filterControls').value.map(item => {
      return item.includeLimit;
    });

    if (!listLimits.length) return;

    this.includeLimitTotal = listLimits.reduce((a: number, b: number) => a + b);
    
    // if the scan limit is greater than the include limit total...
    if (this.maxScanLimit > this.includeLimitTotal) {
      // ...and there is no 0 in any of the limits
      if (listLimits.indexOf(0) === -1) {
        this.filterLimitMatch = false;
        this.mismatchMessage = 'Include filter limits do not add up to scan limit of ' + this.maxScanLimit + '. We recommend adjusting include filter limits or adding an include filter with a 0 limit.';
      } else {
        this.filterLimitMatch = true;
      }
    } else if (this.maxScanLimit < this.includeLimitTotal) {
      // if the scan limit is less than the include limit total
      this.filterLimitMatch = false;
      this.mismatchMessage = 'Include filter limits add up to more than the scan limit of ' + this.maxScanLimit + '. We recommend adjusting include filter limits or increasing the scan limit of the audit.';
    } else {
      this.filterLimitMatch = true;
    }
  }
}
