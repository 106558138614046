import { ILabel } from '@app/components/shared/services/label.service';
import { IPaginationMetaData } from '@app/components/consent-categories/consent-categories.models';
import { SortDirection } from '@angular/material/sort';
import {
  EEmailInboxModalType,
  EEmailInboxStep
} from '@app/components/email-inboxes/email-inbox-editor/email-inbox-editor.constants';
import {
  EEmailInboxAlertStatus,
  EEmailInboxMessageStatus
} from '@app/components/email-inboxes/email-inboxes.constants';

export interface IEmailInboxDTO {
  emailInboxes: IEmailInbox[],
  metadata: { pagination: IPaginationMetaData }
}

export interface IEmailInbox {
  id: number;
  labelIds: number[];
  name: string;
  emailAddress: string;
  notes: string;
  messagesReceived: number;
  lastMessageReceived?: string;
  subscribers: {
    onReceived: string[];
    onProcessed: string[];
  };
}

export interface IEmailInboxTableData extends IEmailInbox {
  labels: ILabel[];
  lastMessageReceivedText: string;
}

export interface IEmailInboxConfigurationDTO {
  auditId: number;
  auditName: string;
  auditLastRunAt: string;
  auditLabels: number[];
}

export interface IEmailInboxesSort {
  sortBy: EEmailInboxesSortColumns;
  sortDesc: boolean;
  sortDir: SortDirection;
}

export enum EEmailInboxesSortColumns {
  'NAME' = 'name',
}

export interface IEmailMessagesSort {
  sortBy: EEmailMessagesSortColumns;
  sortDesc: boolean;
  sortDir: SortDirection;
}

export enum EEmailMessagesSortColumns {
  'RECEIVED_AT' = 'receivedAt',
}

export interface IEmailInboxesQueryParams {
  page?: Number;
  pageSize?: number;
  name?: string;
  labels?: number[];
  sortBy?: string;
  sortDesc?: boolean;
}

export interface IEmailInboxEditorModalPayload {
  name: string;
  notes: string;
  labels: ILabel[];
  labelIds: number[];
  type: EEmailInboxModalType;
  emailAddress?: string;
  id?: number;
  edit: Function;
  step?: EEmailInboxStep;
}

export interface IEmailInboxPostBody {
  name: string;
  notes: string;
  labelIds: number[];
  id?: number;
  subscribers?: {
    onReceived: string[];
    onProcessed: string[];
  };
}

export interface ISort {
  sortBy: EEmailInboxesSortColumns;
  sortDesc: boolean;
  sortDir?: SortDirection;
}

export interface IEmailInboxMessage {
  id: number;
  receivedAt: string;
  runs: IEmailInboxMessageRun[];
  screenshotPath: string;
  screenshotThumbnailPath: string;
  senderEmailAddress: string;
  status: string;
  subject: string;
  uniqueLinks: number;
}

export interface IEmailInboxMessageRun {
  auditName: string;
  auditId: number;
  runId: number;
  isCompleted: boolean;
}

export interface IEmailInboxMessageNormalized extends IEmailInboxMessage {
  receivedAtText: string;
  receivedAtTooltipText: string;
  audits?: IEmailInboxMessageAlertAuditItem[],
  readyToSend?: {
    assignedAlertCount: number;
    triggeredAlertCount: number;
    status: EEmailInboxAlertStatus;
    label: string;
    iconName: string;
  };
}

export interface IEmailInboxMessages {
  messages: IEmailInboxMessage[];
  metadata: {
    pagination: IPaginationMetaData;
  };
}

export interface IEmailInboxMessagesScreenshot {
  url: string;
  message: number;
  inbox: number;
}

export interface IEmailInboxMessageAlertsRequestBody {
  emailInboxId: number;
  messageIds: number[];
}

export interface IEmailInboxMessageAlertAuditItem {
  auditId: number;
  auditName: string;
  isCompleted: boolean;
  runId: number;
  assignedAlertCount: number;
  triggeredAlertCount?: number;
}

export interface IEmailInboxMessageAlertMessageItem {
  messageId: number;
  audits: IEmailInboxMessageAlertAuditItem[];
}

export interface IEmailInboxMessageAlerts {
  messages: IEmailInboxMessageAlertMessageItem[];
}

export interface IEmailInboxMessageStatus {
  id: number;
  status: EEmailInboxMessageStatus;
}
