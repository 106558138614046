import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IDateDifferenceModalConfigData,
  IDateDifferenceModalClosedData
} from './date-difference-modal.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'date-difference-modal',
  templateUrl: './date-difference-modal.component.html',
  styleUrls: ['./date-difference-modal.component.scss']
})
export class DateDifferenceModalComponent {
  private min = undefined;
  private max = undefined;
  validationMessage = '';
  from = {
    minutes: '',
    hours: '',
    days: '',
    seconds: 0
  };

  to = {
    minutes: '',
    hours: '',
    days: '',
    seconds: 0
  };

  rightFooterButtons = [
    {
      label: 'Done',
      action: () => this.submitFilter(),
      primary: true,
      disabled: true
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<DateDifferenceModalComponent, IDateDifferenceModalClosedData>,
    @Inject(MAT_DIALOG_DATA) public data: IDateDifferenceModalConfigData,
  ) {
  }

  submitFilter() {
    this.closeModal({
      min: this.min,
      max: this.max
    });
  }

  closeModal(data?: IDateDifferenceModalClosedData) {
    this.dialogRef.close(data);
  }

  isValid() {
    this.rightFooterButtons[0].disabled = true;
    this.validationMessage = '';

    this.min = undefined;
    this.max = undefined;

    let from = 0;
    let to = 0;

    const isMinTyped = this.from.days || this.from.hours || this.from.minutes;
    const isMaxTyped = this.to.days || this.to.hours || this.to.minutes;

    if (this.from.days) {
      if (!Number.isInteger(+this.from.days)) {
        this.validationMessage = 'Min days is not a number.';
        return;
      }
      if (+this.from.days < 0) {
        this.validationMessage = 'Min days should be positive.';
        return;
      }

      from += +this.from.days * 24 * 60;
    }

    if (this.from.hours) {
      if (!Number.isInteger(+this.from.hours)) {
        this.validationMessage = 'Min hours is not a number.';
        return;
      }
      if (+this.from.hours < 0) {
        this.validationMessage = 'Min hours should be positive.';
        return;
      }

      from += +this.from.hours * 60;
    }

    if (this.from.minutes) {
      if (!Number.isInteger(+this.from.minutes)) {
        this.validationMessage = 'Min minutes is not a number.';
        return;
      }
      if (+this.from.minutes < 0) {
        this.validationMessage = 'Min minutes should be positive.';
        return;
      }

      from += +this.from.minutes;
    }

    if (this.to.days) {
      if (!Number.isInteger(+this.to.days)) {
        this.validationMessage = 'Max days is not a number.';
        return;
      }
      if (+this.to.days < 0) {
        this.validationMessage = 'Max days should be positive.';
        return;
      }

      to += +this.to.days * 24 * 60;
    }

    if (this.to.hours) {
      if (!Number.isInteger(+this.to.hours)) {
        this.validationMessage = 'Max hours is not a number.';
        return;
      }
      if (+this.to.hours < 0) {
        this.validationMessage = 'Max hours should be positive.';
        return;
      }

      to += +this.to.hours * 60;
    }

    if (this.to.minutes) {
      if (!Number.isInteger(+this.to.minutes)) {
        this.validationMessage = 'Max minutes is not number.';
        return;
      }
      if (+this.to.minutes < 0) {
        this.validationMessage = 'Max minutes should be positive.';
        return;
      }

      to += +this.to.minutes;
    }

    if (isMinTyped && isMaxTyped && from === to) {
      this.validationMessage = `Max and Min ${this.data.itemName ?? 'date'}s cannot be equal.`;
      return;
    }

    if (isMinTyped && !from) {
      this.validationMessage = 'Min cannot be zero.';
      return;
    }

    if (isMaxTyped && !to) {
      this.validationMessage = 'Max cannot be zero.';
      return;
    }

    if (from && to && from > to) {
      this.validationMessage = `Max ${this.data.itemName ?? 'date'} should be bigger than Min ${this.data.itemName ?? 'date'}.`;
      return;
    }

    if (!from && !to) {
      return;
    }

    this.min = from || undefined;
    this.max = to || undefined;
    this.rightFooterButtons[0].disabled = false;
    return true;
  }
}
