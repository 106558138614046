<div class="page-rule-results-container" #tableScrollTo>
  <table mat-table
         matSort
         resizeableTable
         #pagesTable
         [dataSource]="tableDataSource"
         multiTemplateDataRows
         class="page-rule-results-table"
         [matSortActive]="TableColumn.PageUrl"
         matSortDirection="asc"
         matSortStart="desc"
  >

    <ng-container [matColumnDef]="TableColumn.PageUrl">
      <th mat-header-cell mat-sort-header *matHeaderCellDef resizeableColumn class="pageurl-th">
        <span>INITIAL PAGE URL</span>
      </th>
      <td mat-cell
          *matCellDef="let row"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text" opTableCellTooltipPrefix="View Page Details:" opTableCellTooltip="{{ row.pageUrl }}">
            <div class="url-text">{{ row.pageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageUrl">
      <th mat-header-cell mat-sort-header *matHeaderCellDef resizeableColumn class="pageurl-th">
        <span>FINAL PAGE URL</span>
      </th>
      <td mat-cell
          *matCellDef="let row"
          class="no-left-padding"
      >
        <div class="url-cell-wrap">
          <div class="url-cell-text" opTableCellTooltipPrefix="View Page Details:" opTableCellTooltip="{{ row.finalPageUrl }}">
            <div class="url-text">{{ row.finalPageUrl|hideProtocol }}</div>
          </div>
        </div>
        <div class="open-drawer-icon-wrapper">
          <mat-icon class="open-drawer-icon">ios_share</mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageLoadTime">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="loadtime-th center-text fixed-90"
          [matTooltip]="PageLoadColumnTooltip"
          [matTooltipPosition]="'above'">
        <span>PAGE LOAD TIME (sec)</span>
      </th>
      <td mat-cell *matCellDef="let row" class="loadtime-td center-text {{ row.loadTimeClass }}">
        <span>{{ row.pageLoadTime | number: '1.0-1' }}</span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.FinalPageStatusCode">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="statuscode-th center-text fixed-90">
        <span>FINAL PAGE STATUS CODE</span>
      </th>
      <td mat-cell *matCellDef="let row" class="statuscode-td center-text {{ row.statusCodeClass }}">
        <span [matTooltip]="PageStatusCodeTooltipMap[row.finalPageStatusCode] || null"
              matTooltipPosition="above"
              matTooltipShowDelay="250"
        >{{ row.finalPageStatusCode }}</span>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.RuleFailureCount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="failures-th center-text fixed-90">
        <span>RULE FAILURES</span>
      </th>
      <td mat-cell *matCellDef="let row" class="failures-td center-text {{ row.ruleFailureCount > 0 ? 'negative' : 'neutral' }}">
        <span>{{ row.ruleFailureCount }}</span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns$ | async;"
        class="item-row"
        [ngClass]="{ 'open-in-page-details': row.pageId === pageIdOpenInPageDetails }"
        (click)="openPageDetails(row)"
    ></tr>
    <tr *matNoDataRow>
      <td class="no-pages-message-td" [colSpan]="(displayedColumns$ | async).length">
        <div class="no-pages-message-container">
          <mat-spinner [diameter]="25" [strokeWidth]="3" *ngIf="spinnerState === 'loading'; else noPagesFoundText"></mat-spinner>
          <ng-template #noPagesFoundText><span>No pages found</span></ng-template>
        </div>
      </td>
    </tr>

  </table>
  <mat-paginator class="pages-paginator"
                 [length]="pagesFilteredCount"
                 [pageSize]="tableState.pagination.size"
                 [hidePageSize]="true">
  </mat-paginator>

</div>
