<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="close()">
  <ng-template #headerTemplate>
    <op-modal-header
      [title]="title"
      (onClose)="close()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="modal-body">
      <div *ngIf="items.length > 0">
        Following items are still running. Please stop & discard their active runs first.
        <div class="items-container">
          <div class="items-list">
            <table class="table table-striped">
              <thead class="thead-color">
              <tr>
                <th>Data Source Type</th>
                <th>Name</th>
                <th class="stop-run">Stop Run</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of items">
                <td>{{ itemTypeToString(item.itemType) }}</td>
                <td>{{ item.itemName }}</td>
                <td class="stop-run">
                  <button class="mat-button" (click)="terminateRun(item)">
                    <span class=icon-stop></span>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div *ngIf="items.length === 0">
        This {{ type }} doesn't contain any active runs at the moment, you can safely delete it now.
      </div>
    </div>
  </ng-template>
</op-modal>
