import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBaseActionDetails } from '@app/components/actions/action-details/action-details.models';
import { EActionCreatorMode } from '@app/components/actions/actions-creator/actions-creator.enum';
import { EActionTypeV3 } from '@app/components/web-journey/web-journey.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-preview',
  templateUrl: './action-preview.component.html',
  styleUrls: ['./action-preview.component.scss']
})
export class ActionPreviewComponent {
  actionTypesWithTooltip: any = [EActionTypeV3.NavTo, EActionTypeV3.Input, EActionTypeV3.Select];

  @Input() actions: IBaseActionDetails[];
  @Input() action: IBaseActionDetails;
  @Input() mode: EActionCreatorMode;
  @Input() index: number;
  @Input() draggedMode: boolean;
  @Input() actionSetCreationMode: boolean;

  @Output() onSelect = new EventEmitter<IBaseActionDetails>();
  @Output() onDelete = new EventEmitter<IBaseActionDetails>();
  @Output() onInsert = new EventEmitter<IBaseActionDetails>();
  @Output() onActionSelected = new EventEmitter<IBaseActionDetails>();

  canDelete: boolean = false;

  readonly actionCreatorMode = EActionCreatorMode;
  readonly actionType = EActionTypeV3;

  deleteAction(event, action: IBaseActionDetails) {
    event.stopPropagation();
    this.onDelete.emit(action);
  }

  insertAction(event, action: IBaseActionDetails) {
    this.onInsert.emit(action);
  }

  onHover() {
    switch (this.mode) {
      case EActionCreatorMode.AuditActions:
        this.canDelete = true;
        break;
      case EActionCreatorMode.AuditUserSession:
        this.canDelete = (this.index > 1 || this.actions.length <= 1 || this.actions[1].type === EActionTypeV3.NavTo);
        break;
      case EActionCreatorMode.WebJourney:
        this.canDelete = this.index > 1;
        break;
      case EActionCreatorMode.ActionSet:
        this.canDelete = true;
        break;
    }
  }

}
