<!--Underlay to make the box .5 transparent, but keep btn and text strong-->
<div class="underlay"></div>
<div class="copy-box-content">
  <div class="left">
    <ng-container *ngIf="showInfo; else value">
      <mat-icon class="material-icons-outlined">{{ infoIcon }}</mat-icon>
      {{ infoText }}
    </ng-container>
    <ng-template #value>{{ copyValue }}</ng-template>
  </div>

  <div class="right">
    <button *ngIf="!compactCopyBtn; else copyIcon"
            mat-flat-button
            color="primary"
            class="copy-btn uppercase"
            [disabled]="copyBtnDisabled"
            [cbContent]="copyValue"
            ngxClipboard
            [matTooltip]="copyTooltipText"
            matTooltipPosition="above"
            #tooltip
            (click)="copyBtnClick()"
            (mouseleave)="resetTooltip()"
    >
      <mat-icon>{{ copyBtnIcon }}</mat-icon>
      {{ copyBtnText }}
    </button>

    <ng-template #copyIcon>
      <button mat-icon-button
              color="primary"
              class="copy-btn compact"
              [disabled]="copyBtnDisabled"
              [cbContent]="copyValue"
              ngxClipboard
              [matTooltip]="copyTooltipText"
              matTooltipPosition="above"
              #tooltip
              (click)="copyBtnClick()"
              (mouseleave)="resetTooltip()"
      >
        <mat-icon>{{ copyBtnIcon }}</mat-icon>
      </button>
    </ng-template>
  </div>
</div>
