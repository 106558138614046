import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'op-sort-indicator',
  template: `
    <button type="button" [ngClass]="{'ascending': checkDirection('asc'), 'descending': checkDirection('desc')}"></button>
  `,
  styleUrls: ['./sort-indicator.component.scss'],
})
export class OpSortIndicatorComponent {
  @Input() sortDirection?: string = null;

  checkDirection(direction: 'asc' | 'desc' | '') {
    return this.sortDirection === direction;
  }
}
