import { OnInit, OnDestroy } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IAdminTagObj, IAdminTagsEmitter } from '../admin-tags.models';
import { IAdminTag } from '../../../../admin-accounts.service';
import { Subject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'admin-tags-grid',
  templateUrl: './admin-tags-grid.component.html',
  styleUrls: ['./admin-tags-grid.component.scss']
})
export class AdminTagsGridComponent implements OnInit, OnDestroy {

  @Input() tags: IAdminTagObj[];
  @Input() enabledTags: IAdminTag[];

  @Output() updated = new EventEmitter<IAdminTagsEmitter>();
  private updatedDebouncer = new Subject<void>();

  deleted: number[] = [];
  selection: number[] = [];

  tabSelection: number;
  totalTags: number;
  cachedTags: IAdminTagObj[];

  private destroy = new Subject<void>();

  ngOnInit(): void {
    this.cachedTags = [ ...this.tags ];
    this.getSelection();
    this.getTotalCount();
    this.getEnabledCount();

    this.updatedDebouncer
      .pipe(
        debounceTime(1000),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.updated.emit({ selection: this.selection, deleted: this.deleted })
      });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  adminTagClicked(tag: IAdminTagObj): void {
    if (tag.enabled) {
      this.selection.push(tag.id);
      this.deleted = this.deleted.filter(id => id !== tag.id);
    } else {
      this.selection = this.selection.filter(id => id !== tag.id);
      this.deleted.push(tag.id);
    }

    this.updatedDebouncer.next();
    this.getEnabledCount();
  }

  selectAll(): void {
    // set enabled to true
    this.tags = this.tags.map(tag => {
      tag.enabled = true;
      return tag;
    });

    // concat selection with all tags on tab and make unique array of IDs with Set
    this.selection = [ ...new Set(this.selection.concat(this.tags.map(tag => tag.id)))];
    this.deleted = [];

    this.updatedDebouncer.next();
    this.getEnabledCount();
  }

  selectNone(): void {
    // get array of all tag ids that are being disabled
    this.deleted = this.tags.filter(tag => tag.enabled).map(tag => tag.id);

    // set enabled to false
    this.tags = this.tags.map((tag: IAdminTagObj) => {
      tag.enabled = false;
      return tag;
    });

    // update selection to not include tags to disabled
    this.tags.forEach(tag => {
      const index = this.selection.indexOf(tag.id);
      this.selection.splice(index, 1);
    });

    this.updatedDebouncer.next();
    this.getEnabledCount();
  }

  filterTags(value: string): void {
    this.tags = value
      ? this.tags.filter(tag => tag.name.toLowerCase().includes(value.toLowerCase()))
      : this.cachedTags;
  }

  private getSelection(): void {
    this.selection = this.enabledTags.map(tag => tag.id);
  }

  private getTotalCount(): void {
    this.totalTags = this.tags.length;
  }

  private getEnabledCount(): void {
    this.tabSelection = this.tags.filter(tag => tag.enabled).length;
  }
}
