<mat-chip-listbox>
  <mat-chip-option color="primary" highlighted [matMenuTriggerFor]="dataSourcesMenu">
    <mat-icon class="tune-icon">tune</mat-icon>
    <span class="divider"></span>
    <span class="name">DATA SOURCE: {{ selectedDataSource?.name }}</span>
    <mat-icon>expand_more</mat-icon>
  </mat-chip-option>
</mat-chip-listbox>

<mat-menu #dataSourcesMenu="matMenu" class="alert-preview-data-source-menu" xPosition="before">
  <button *ngFor="let dataSource of dataSources"
          mat-menu-item
          (click)="dataSourceSelected.emit(dataSource)">
    {{ dataSource.name }}
  </button>
</mat-menu>
