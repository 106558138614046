import { SnackbarErrorComponent } from '../../shared/components/snackbars/snackbar-error/snackbar-error.component';
import { DataSourcesUrlBuilders, PAUSED_DATE } from './../../manage/cards/manage-cards.constants';
import { Router } from '@angular/router';
import { forkJoin, from, Observable, of, throwError as observableThrowError } from 'rxjs';
import { FolderService } from './../../folder/folder.service';
import {
  ActionsLearnMoreLink,
  ErrorMessage,
  EWebJourneyTab,
  ExplanationText,
  LearnMoreLink,
  TAB_ID_TO_LABEL_MAP,
  WebJourneyCreatorTitle
} from './web-journey-editor.constants';
import { ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IWebJourneySetupForm } from '../web-journey-setup-form/web-journey-setup-form.models';
import { IUser } from '@app/moonbeamModels';
import { EActionTypeV3, IWebJourneyOptions } from '@app/components/web-journey/web-journey.models';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { OpModalComponent, OpModalService, OpSuccessModalComponent } from '@app/components/shared/components/op-modal';
import { IWebJourney } from '../web-journey.models';
import { changeTimeInDate, DateService, EDateFormats, formatDate } from '@app/components/date/date.service';
import { DEFAULT_CUSTOM_PROXY, DEFAULT_LOCATION } from '../web-journey-setup-form/web-journey-setup-form.constants';
import { defaultWebJourneyAction } from '@app/components/actions/actions-creator/actions-creator.constants';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { RuleSelectorComponent } from '@app/components/account/rules/rule-selector/rule-selector.component';
import { IRuleSelection, ISelectedItem } from '@app/components/account/rules/rule-selector/rule-selector.models';
import { RulesService } from '@app/components/rules/rules.service';
import { IWebJourneyActionRules, IWebJourneyEditorModalData, IWebJourneyTab, } from './web-journey-editor.models';
import { WebJourneyService } from '../web-journey.service';
import { EActionCreatorMode } from '@app/components/actions/actions-creator/actions-creator.enum';
import { IDomain } from '@app/components/domains/domainsService';
import { ActionsCreatorComponent } from '@app/components/actions/actions-creator/actions-creator.component';
import { DomainService } from '@app/components/domain/domain.service';
import {
  IWebJourneyV3,
  IWebJourneyV3NotificationConfig
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import { IActionSetAction } from '@app/components/action-set-library/action-set-library.models';
import {
  WebJourneyV3ActionsService
} from '../../domains/webJourneys/web-journey-v3-api/web-journey-v3-actions.service';
import {
  WebJourneyV3RulesService
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3-rules.service';
import {
  WebJourneyV3NotificationsService
} from '../../domains/webJourneys/web-journey-v3-api/web-journey-v3-notifications.service';
import { TransformActionsService } from '@app/components/action-set-library/transform-actions.service';
import {
  WebJourneyV3RfmService
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3-rfm.service';
import { IRFMConfigV3 } from '../../creator/shared/remoteFileMapping/remote-file-mapping.component';
import { AccountsService } from '@app/components/account/account.service';
import { blackoutToApiModel } from '@app/components/utilities/blackoutPeriodUtils';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import {
  IRule,
  IRulePreview,
  IRuleV3,
  IRuleV3ActionRules,
  IRuleV3UpdateRequest,
  IRuleV3WebJourneyRules
} from '@app/components/rules/rules.models';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  SnackbarSuccessComponent
} from '@app/components/shared/components/snackbars/snackbar-success/snackbar-success.component';
import { ModalWithHotkeySupport } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.models';
import { EKeyCodes } from '@app/components/shared/services/keyboard-shortcuts/keyboard-shortcuts.constants';
import { userIsGuest } from '@app/authUtils';
import { EProductType } from '@app/components/shared/components/op-standards-selector/op-standards-selector.constants';
import {
  IStandardsSelectorItem
} from '@app/components/shared/components/op-standards-selector/op-standards-selector.models';
import {
  WebJourneySuccessSnackbarComponent
} from '@app/components/web-journey/web-journey-success-snackbar/web-journey-success-snackbar.component';
import { DataSourceEditorService } from '@app/components/data-source-editor/data-source-editor.service';
import { ECmpOption } from '@app/components/actions/action-details/action-details.constants';
import {
  WebJourneySetupFormComponent
} from '@app/components/web-journey/web-journey-setup-form/web-journey-setup-form.component';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import {
  CurtainedRuleSelectorComponent,
  ICurtainedRuleSelectorComponentData
} from '@app/components/account/rules/curtained-rule-selector/curtained-rule-selector.component';
import { EWebJourneyFrequency } from '@app/components/web-journey/web-journey.constants';
import { StorageService } from '@app/components/shared/services/storage.service';
import { IOpRecurrenceScheduleRequest } from '@app/components/shared/components/op-recurrence/op-recurrence.models';
import { RecurrenceService } from '@app/components/shared/components/op-recurrence/op-recurrence.service';

@Component({
  selector: 'op-web-journey-editor',
  templateUrl: './web-journey-editor.component.html',
  styleUrls: ['./web-journey-editor.component.scss'],
})
export class WebJourneyEditorComponent implements OnInit, ModalWithHotkeySupport {

  readonly actionsModes = EActionCreatorMode;
  readonly webJourneyTabs = EWebJourneyTab;

  webJourneyForm: UntypedFormGroup;
  submitted: boolean = false;
  EProductType = EProductType;

  title: string;
  journeyId: number;
  currentTab: number = EWebJourneyTab.setup;
  tabs: IWebJourneyTab[] = this.generateTabs();
  rightFooterButtons: IButton[];
  actionsTabVisited: boolean = false;

  user: IUser;
  isReadOnly: boolean;
  isRunning: boolean;

  rules: IRulePreview[] = [];
  selectedRuleIDs: number[] = [];
  labels: ILabel[] = [];
  assigningRule: boolean = false;
  selectedRuleItems: Array<ISelectedItem>;

  ruleSelectorTitle: string;
  actionRules: IWebJourneyActionRules;
  selectedActionRules: IRuleSelection;
  newActionRule: IRulePreview;

  errorMessage = ErrorMessage;

  recurrenceEnabled: boolean = false;

  explanationText = ExplanationText;
  learnMoreLink = LearnMoreLink;
  actionsLearnMoreLink = ActionsLearnMoreLink;

  backButton = {
    label: 'Back',
    icon: 'icon-back-empty',
    action: this.goBackTab.bind(this),
    opSelector: 'web-journey-back',
  };

  continueButton = {
    label: 'Continue',
    icon: 'icon-forward-empty',
    action: this.goForwardTab.bind(this),
    opSelector: 'web-journey-continue',
  };

  saveButton: IButton = {
    label: 'Save Changes',
    action: this.save.bind(this),
    primary: true,
    opSelector: 'web-journey-create-save',
  };

  saveAndRunButton: IButton = {
    label: 'Save Changes & Run Now',
    action: this.save.bind(this, true),
    primary: true,
    opSelector: 'web-journey-create-save-run',
  };

  basicRightFooterButtons = {
    [EWebJourneyTab.setup]: [this.continueButton],
    [EWebJourneyTab.actions]: [this.backButton, this.continueButton],
    [EWebJourneyTab.standards]: [this.backButton],
  };

  selectedDomain: string;
  successModalRef: MatDialogRef<OpSuccessModalComponent>;
  successSnackBarRef: MatSnackBarRef<WebJourneySuccessSnackbarComponent>;

  scrollToActionIndex?: number;
  createdDefaultAction: boolean = false;
  ruleSelectorComponentInstance: CurtainedRuleSelectorComponent;

  // Ensure data is loaded before standards-tab is loaded
  isLoaded: boolean = false;

  @ViewChild(WebJourneySetupFormComponent) webJourneySetupForm: WebJourneySetupFormComponent;
  @ViewChild(OpModalComponent) opModal: OpModalComponent;
  @ViewChild(RuleSelectorComponent) ruleSelectorComponent: RuleSelectorComponent;
  @ViewChild(ActionsCreatorComponent) actionsCreatorComponent: ActionsCreatorComponent;

  constructor(private dialogRef: MatDialogRef<WebJourneyEditorComponent>,
    @Inject(MAT_DIALOG_DATA) @Optional() public modalData: IWebJourneyEditorModalData,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private accountsService: AccountsService,
    private webJourneyService: WebJourneyService,
    private webJourneyAPIService: IWebJourneyApiService,
    private modalServiceNg: OpModalService,
    private folderService: FolderService,
    private domainService: DomainService,
    private rulesService: RulesService,
    private labelsService: LabelService,
    private transformActionsService: TransformActionsService,
    private webJourneyV3Service: WebJourneyV3Service,
    private webJourneyV3ActionsService: WebJourneyV3ActionsService,
    private webJourneyV3RulesService: WebJourneyV3RulesService,
    private webJourneyV3NotificationsService: WebJourneyV3NotificationsService,
    private webJourneyV3RfmService: WebJourneyV3RfmService,
    private snackBar: MatSnackBar,
    private dataSourceEditorService: DataSourceEditorService,
    private changeDetectorRef: ChangeDetectorRef,
    private dateService: DateService,
    private storageService: StorageService,
    private recurrenceService: RecurrenceService
  ) {
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');

    if (typeof this.modalData.step !== 'undefined') this.currentTab = this.modalData.step;
    this.journeyId = this.modalData.journeyId ? this.modalData.journeyId : null;

    this.title = this.getTitle();
    this.rightFooterButtons = this.getRightFooterButtons(this.currentTab);

    this.createEmptyForm();

    this.journeyId ? this.initEditWebJourney() : this.initCreateWebJourney();
  }

  ngOnInit() {
    this.accountsService.getUser().subscribe(user => {
      this.user = user;
      this.isReadOnly = userIsGuest(user);
    });
  }

  private generateTabs(): IWebJourneyTab[] {
    return [
      {
        name: TAB_ID_TO_LABEL_MAP.get(EWebJourneyTab.setup),
        path: EWebJourneyTab.setup
      },
      {
        name: TAB_ID_TO_LABEL_MAP.get(EWebJourneyTab.actions),
        path: EWebJourneyTab.actions
      },
      {
        name: TAB_ID_TO_LABEL_MAP.get(EWebJourneyTab.standards),
        path: EWebJourneyTab.standards
      },
    ];
  }

  private getTitle(webJourneyName?: string): string {
    return this.journeyId && !this.modalData.copy ? `${WebJourneyCreatorTitle.Edit} - ${webJourneyName}` : WebJourneyCreatorTitle.Create;
  }

  private getRightFooterButtons(tabId: EWebJourneyTab): IButton[] {
    const rightFooterButtons = this.basicRightFooterButtons[tabId];
    return (this.journeyId || this.actionsTabVisited) ?
      [...rightFooterButtons, this.saveButton, this.saveAndRunButton] : rightFooterButtons;
  }

  private createEmptyForm(): void {
    this.webJourneyForm = this.formBuilder.group({
      setup: this.formBuilder.control({}),
      actions: this.formBuilder.control([])
    });
  }

  private initCreateWebJourney(): void {
    this.createdDefaultAction = false;

    forkJoin([
      from(this.labelsService.getLabels()),
      this.rulesService.getRulePreviews(),
    ]).subscribe(([labels, rules]) => {
      this.labels = labels;
      this.updateRules(rules, []);
      this.isLoaded = true;
    });
  }

  private initEditWebJourney(): void {
    const $labels = from(this.labelsService.getLabels());
    const $journeyLabels = from(this.webJourneyAPIService.getWebJourneyLabels(this.journeyId));
    const $rules = this.rulesService.getRulePreviewsCached();

    const $webJourney: Observable<IWebJourneyV3> = this.webJourneyV3Service.getJourney(this.journeyId).pipe(
      catchError(error => {
        console.error(error.message);
        this.router.navigateByUrl(DataSourcesUrlBuilders.sources());
        return observableThrowError(error);
      })
    );

    const $actions: Observable<IActionSetAction[]> = this.webJourneyV3ActionsService.getJourneyActions(this.journeyId).pipe(
      catchError(error => {
        console.error(error.message);
        this.showErrorSnackbar('Error: Could not load journey actions');
        return observableThrowError(error);
      })
    );

    const $journeyRules: Observable<IRuleV3WebJourneyRules> = this.webJourneyV3RulesService.getJourneyRules(this.journeyId).pipe(
      catchError(error => {
        console.error(error.message);
        this.showErrorSnackbar('Error: Could not load journey rules');
        return observableThrowError(error);
      })
    );

    const $notifications: Observable<IWebJourneyV3NotificationConfig> =
      this.webJourneyV3NotificationsService.getNotificationsConfig(this.journeyId).pipe(
        catchError(error => {
          console.error(error.message);
          this.showErrorSnackbar('Error: Could not load journey notification settings');
          return observableThrowError(error);
        })
      );

    const $rfmConfigs: Observable<IRFMConfigV3[]> = this.webJourneyV3RfmService.getRfmConfig(this.journeyId).pipe(
      catchError(error => {
        console.error(error.message);
        this.showErrorSnackbar('Error: Could not load remote file mappings');
        return observableThrowError(error);
      })
    );

    let observables: Observable<any>[] = [
      $labels,
      $journeyLabels,
      $rules,
      $webJourney,
      $actions,
      $journeyRules,
      $notifications,
      $rfmConfigs
    ];

    forkJoin(observables).subscribe((
      [
        labels,
        journeyLabels,
        rules,
        journey,
        actions,
        journeyRules,
        notifications,
        rfmConfigs
      ]
    ) => {
      let actionRules: IRuleV3ActionRules[] = journeyRules.actionRules ? journeyRules.actionRules : [];
      let globalRules: IRuleV3[] = journeyRules.webJourneyRules ? journeyRules.webJourneyRules : [];
      let webJourney = this.createJourneyUiObj(journey, notifications, rfmConfigs);

      if (this.modalData.copy) {
        webJourney.name = webJourney.name + ' copied ' + formatDate(new Date(), EDateFormats.dateTwo);
        webJourney.options.monitoredByScriptServices = false;
      }

      webJourney.labels = journeyLabels;

      this.title = this.getTitle(webJourney.name);
      this.fillForm(webJourney);

      const convertedActions = this.transformActionsService
        .convertApiActionsToUi(actions, actionRules)
        .sort((a: any, b: any) => (a.sequence > b.sequence) ? 1 : -1);

      this.webJourneyForm.patchValue({
        actions: convertedActions
      });

      this.labels = labels;
      this.updateRules(rules, globalRules);

      this.isLoaded = true;

      if (this.modalData.action) {
        this.actionsCreatorComponent.onSelect(this.modalData.action, true);
      }
    });
  }

  private updateRules(rules: Array<IRulePreview>, journeyRules: Array<IRuleV3> = []): void {
    this.rules = rules;
    this.selectedRuleIDs = journeyRules.map(rule => rule.id);
  }

  onSelectDomain(domain: IDomain | string): void {
    if (!domain) return;
    this.selectedDomain = typeof domain === 'object' ? domain.domain : domain;
    if (!this.journeyId && this.actions.value.length === 1) {
      // When creating a new journey, the "domain" will be a string. It will be the name of the sub-folder
      // and we will populate the journey using the default domain.
      const useDefault = typeof domain === 'string';
      const navToUrl = this.domainService.prepareDomainUrl(this.selectedDomain, useDefault);
      this.actions.setValue([defaultWebJourneyAction(navToUrl)]);
    }
  }

  private fillForm(webJourney: IWebJourney): void {
    this.webJourneyForm.patchValue({
      setup: webJourney
    });
  }

  goToTab(tabId: EWebJourneyTab): void {
    this.submitted = false;
    // We need to create our default action on tab change or save instead of
    // init now that it is possible to also create a CMP action as the
    // initial action.
    if (!this.journeyId && this.createdDefaultAction === false && tabId === EWebJourneyTab.actions) {
      if (this.currentTab === EWebJourneyTab.setup && this.setup.invalid) {
        this.submitted = true;
        return;
      }

      this.setDefaultActionToNavTo();
      this.createdDefaultAction = true;

      this.changeDetectorRef.detectChanges();

      this.actionsTabVisited = true;
      this.currentTab = tabId;
      this.rightFooterButtons = this.getRightFooterButtons(tabId);
      return;
    }

    if (this.webJourneyForm.invalid) {
      this.submitted = true;
      if (this.currentTab === EWebJourneyTab.actions) this.actionsCreatorComponent.handleValidationError();
      return;
    }

    if (tabId === EWebJourneyTab.actions) this.actionsTabVisited = true;

    this.currentTab = tabId;
    this.rightFooterButtons = this.getRightFooterButtons(tabId);
  }

  setDefaultActionToNavTo(): void {
    this.actions.setValue([defaultWebJourneyAction(this.selectedDomain)]);
  }

  private goBackTab(): void {
    this.goToTab(this.currentTab - 1);
  }

  private goForwardTab(): void {
    this.goToTab(this.currentTab + 1);
  }

  openRuleSelector(actionRules: IWebJourneyActionRules): void {
    this.assigningRule = true;
    this.actionRules = actionRules;
    this.selectedActionRules = {
      selectedItems: this.actionRules.rules.value.map((rule) => ({ rule })),
      selectedRuleIds: this.actionRules.rules.value.map(rule => rule.id)
    };
    const data: ICurtainedRuleSelectorComponentData = {
      rules: this.rules,
      labels: this.labels,
      title: 'Add rules to Action ' + actionRules.index,
      newActionRule: this.newActionRule,
      selectedRules: this.selectedActionRules,
      openRuleCreation: this.openRuleCreation.bind(this),
      openRuleEditor: this.openRuleEditor.bind(this)
    };

    const modal = this.modalServiceNg.openFixedSizeModal(CurtainedRuleSelectorComponent, { data });
    this.ruleSelectorComponentInstance = modal.componentInstance;
    modal.afterClosed().subscribe(rules => this.closeRuleSelector(rules));
  }

  closeRuleSelector(value: number[]): void {
    this.assigningRule = false;
    if (value) this.actionRules.rules.patchValue(value.map(id => this.rules.find(r => r.id === id)));
    this.newActionRule = null;
  }

  openRuleCreation(): void {
    this.modalServiceNg.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.create
      }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => this.onCreateRule(rule));
  }

  openRuleEditor(rule: IRule): void {
    this.modalServiceNg.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.edit,
        ruleId: rule.id
      }
    }, 'rule-setup-modal')
      .afterClosed()
      .subscribe(rule => this.onUpdateRule(rule));
  }

  onCreateRule(newRule?: IRule): void {
    if (newRule && !this.assigningRule) {
      // update labels for grouping if newRule has a label on it in case a new label was created
      if (newRule.labels.length > 0) {
        this.refreshLabels().subscribe(() => {
          this.updateSelectedRuleAfterCreation(newRule);
        });
      } else {
        this.updateSelectedRuleAfterCreation(newRule);
      }
    }

    if (newRule && this.assigningRule) {
      this.newActionRule = newRule;
      if (newRule.labels.length > 0) {
        this.refreshLabels().subscribe(() => {
          this.rules = [...this.rules, newRule];
        });
      } else {
        this.rules = [...this.rules, newRule];
      }
    }

    if (newRule) {
      this.ruleSelectorComponentInstance.addRule(newRule);
    }
  }

  private async onUpdateRule(updatedRule?: IRule): Promise<void> {
    if (!updatedRule) return;
    this.refreshLabels().subscribe(labels => {
      this.updateSelectedRuleAfterUpdation(updatedRule);
    });
  }

  private updateSelectedRuleAfterUpdation(updatedRule: IRule): void {
    this.rules = this.rules.map(rule => rule.id === updatedRule.id ? updatedRule : rule);
    const selectedRuleIds = this.ruleSelectorComponent.selectedItemsAndRules.selectedRuleIds ?? [];
    this.selectedRuleIDs = selectedRuleIds;
  }

  private refreshLabels() {
    return this.labelsService.getLabels().pipe(
      tap((labels) => this.labels = labels)
    );
  }

  updateSelectedRuleAfterCreation(rule: IRulePreview): void {
    this.rules.push(rule);
    const newSelectedRuleIds = [...this.ruleSelectorComponent.selectedItemsAndRules.selectedRuleIds, rule.id];

    this.selectedRuleIDs = newSelectedRuleIds;
  }

  save(runAfterSave: boolean = false): void {
    this.submitted = true;
    if (this.webJourneyForm.invalid) {
      return;
    }

    this.saveButton.disabled = true;
    this.saveAndRunButton.disabled = true;
    this.folderService.handleFolder(this.user, this.setupValue.folderData.folder)
      .pipe(
        switchMap(folder =>
          this.domainService.handleDomain(folder.id, this.setupValue.folderData.subFolder, this.setupValue.folderData.dataLayer, true)
            .pipe(
              tap(domain => this.saveJourney(folder.id, domain.id, runAfterSave)),
            )
        ),
      )
      .subscribe(
        () => {},
        () => this.handleError(),
      );
  }

  private handleError() {
    const message = 'An unknown error occurred. Please refresh the page and try again.';
    this.showErrorSnackbar(message);
    this.saveButton.disabled = false;
    this.saveAndRunButton.disabled = false;
  }

  private saveJourney(folderId: number, domainId: number, runAfterSave: boolean = false): void {
    const isCreate = !this.modalData.journeyId || !!this.modalData.copy;
    const options = this.getOptions();
    const journey: IWebJourneyV3 = this.createJourneyApiObj(this.journeyId, folderId, domainId);
    const actions = this.transformActionsService.convertUiActionsToApi(this.actions.value, null, this.modalData.copy);
    const webJourneyRules = this.selectedRuleIDs;

    const notifications: IWebJourneyV3NotificationConfig = {
      emails: this.getEmails(),
      alerts: !!options.alerts,
      webHookUrl: options.webHookUrl
    };

    const $journeyObservable = isCreate
      ? this.webJourneyV3Service.createJourney(journey)
      : this.webJourneyV3Service.updateJourney(journey);

    $journeyObservable.subscribe(
      (journey: IWebJourneyV3) => {
        this.webJourneyV3ActionsService.updateJourneyActions(journey.id, actions).subscribe(
          (actions: IActionSetAction[]) => {

            const actionRules = this.actions.value.length ? this.actions.value.map((action, index: number) => {
              return {
                actionId: actions[index].id,
                rules: action.rules.length ? action.rules.map(rule => rule.id) : []
              };
            }) : [];

            const rules: IRuleV3UpdateRequest = {
              webJourneyRules: webJourneyRules,
              actionRules: actionRules
            };

            const $updateRules = this.webJourneyV3RulesService.updateJourneyRules(journey.id, rules).pipe(
              catchError(error => {
                this.showErrorSnackbar('Error: Could not update journey rules');
                return of([]);
              })
            );

            const $updateNotificationsConfig = this.webJourneyV3NotificationsService
              .updateNotificationsConfig(journey.id, notifications).pipe(
                catchError(error => {
                  this.showErrorSnackbar('Error: Could not update email notification settings');
                  return of({});
                })
              );

            const $updateJourneyLabels = from(
              this.webJourneyAPIService.updateWebJourneyLabels(journey.id, this.setupValue.labels)
                .catch(() => {
                  this.showErrorSnackbar('Error: Could not update journey labels');
                  return Promise.resolve({});
                })
            );

            const $updateRfmConfigs = this.webJourneyV3RfmService
              .updateRfmConfig(journey.id, options.rfmConfig).pipe(
                catchError(error => {
                  this.showErrorSnackbar('Error: Could not update remote file mapping settings');
                  return of([]);
                })
              );

            const $updateBlockingConfig = from(
              this.webJourneyAPIService.updateEasyBlockTagIds(journey.id, this.setup.value.easyBlockTags)
                .catch((error) => {
                  this.showErrorSnackbar('Error: Could not update blocking config');
                  return of({});
                })
            );

            const observables: Observable<any>[] = [
              $updateRules,
              $updateNotificationsConfig,
              $updateJourneyLabels,
              $updateRfmConfigs,
              $updateBlockingConfig
            ];

            forkJoin(observables).subscribe(() => {
              this.close(journey);
              if (runAfterSave) {
                this.runNow(journey);
                this.isRunning = true;
              }
              if (isCreate) {
                this.showCreateSuccessSnackbar(journey, this.isRunning);
              }
            });
          },
          error => {
            this.handleError();
          }
        );
      },
      error => {
        if (error?.errorCode?.validationReport?.messages.length > 0) {
          //display each message in the messages array
          error?.errorCode?.validationReport?.messages.forEach(m => {
            this.showErrorSnackbar(m.message);
          });
        } else {
          this.handleError();
        }
      });
  }

  private getEmails(): string[] {
    return this.webJourneyService.recipientsStringToArray(this.setupValue.recipients);
  }

  private getOptions(): IWebJourneyOptions {
    const blackoutPeriodModel = blackoutToApiModel({
      start: this.setupValue.blackoutStart + ':00',
      end: this.setupValue.blackoutEnd + ':00'
    }, this.user.timezone);

    const blackoutPeriod = this.setupValue.blackoutEnabled ? blackoutPeriodModel : null;

    const startingDate = this.setupValue.frequency === EWebJourneyFrequency.PAUSED
      ? new Date(PAUSED_DATE)
      : this.setupValue.startingDate;

    const nextRun = this.setupValue.startingTime && startingDate
      ? this.dateService.changeTimeInDate(this.setupValue.startingTime, new Date(startingDate))
      : null;

    const options: IWebJourneyOptions = {
      location: this.setupValue.vpn ? DEFAULT_LOCATION : this.setupValue.customProxy ? DEFAULT_CUSTOM_PROXY : this.setupValue.location,
      customProxy: this.setupValue.customProxy ? this.setupValue.location : null,
      frequency: this.setupValue.frequency,
      nextRun: nextRun,
      userAgent: this.setupValue.userAgent,
      userAgentDescription: this.setupValue.userAgent,
      browserWidth: this.setupValue.browserWidth,
      browserHeight: this.setupValue.browserHeight,
      alerts: this.setupValue.hasRecipients,
      vpnEnabled: this.setupValue.vpn && !this.setupValue.flashLiveVideo,
      gpcEnabled: this.setupValue.gpc,
      blockThirdPartyCookies: this.setupValue.blockThirdPartyCookies,
      flashLiveVideoEnabled: this.setupValue.flashLiveVideo && !this.setupValue.vpn,
      blackoutPeriod,
      rfmConfig: this.setupValue.rfmConfig,
      webHookUrl: this.setupValue.webhook || null,
      monitoredByScriptServices: this.setupValue.monitorJourney,
      notes: this.setupValue.notes || ''
    };

    if (this.recurrenceEnabled) {
      const combinedDateTime = this.recurrenceService.combineDateAndTime(
        this.setupValue.recurrence.runDate.date,
        this.setupValue.recurrence.runTime.time
      );
      const schedule: IOpRecurrenceScheduleRequest = {
        dtStart: combinedDateTime,
        tzId: this.setupValue.recurrence.runTime.timeZone,
        recurrenceRule: this.setupValue.recurrence.frequency.recurrenceRule,
        isPaused: this.setupValue.recurrence.frequency.isPaused
      }

      options.schedule = schedule;
    }

    return options;
  }

  close(webJourney?: IWebJourneyV3): void {
    this.dialogRef.close(webJourney);
  }

  private createAnotherUserJourney(): void {
    if (this.successSnackBarRef) {
      this.successSnackBarRef.dismiss();
      this.successSnackBarRef = null;
    }

    this.modalServiceNg.openFixedSizeModal(WebJourneyEditorComponent, {
      disableClose: true,
      data: {}
    }, 'op-webjourney-editor')
      .afterClosed()
      .subscribe((webJourney?: IWebJourneyV3) => {
        if (webJourney) this.router.navigateByUrl(this.router.url);
      });
  }

  private runNow(webJourney: IWebJourneyV3): void {
    this.webJourneyV3Service.runWebJourneyNow(webJourney.id).subscribe(_ => {
      if (webJourney.frequency === EWebJourneyFrequency.ONCE) return;  // If frequency is Run Once then let the normal dialog show
      if (this.successSnackBarRef) {
        this.successSnackBarRef.dismiss();
        this.successSnackBarRef = null;
      }

      this.showSuccessSnackbar(`${webJourney.name} has been started`);
    }, (error) => this.webJourneyV3Service.handleWebJourneyRunError(error, webJourney.name));
  }

  private showCreateSuccessSnackbar(journey: IWebJourneyV3, isRunning: boolean) {
    const rightFooterButtons = [{
      label: 'Create another web journey',
      action: () => this.createAnotherUserJourney(),
      type: 'success',
      icon: 'icon-add-empty'
    }, {
      label: 'Run Now',
      action: () => this.runNow(journey),
      primary: true,
      icon: 'icon-run'
    }];

    let journeyData = {
      journey,
      isRunning,
      rightFooterButtons,
    };

    this.successSnackBarRef = this.snackBar.openFromComponent(WebJourneySuccessSnackbarComponent, {
      data: journeyData,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      duration: 999000,
    });
  }

  private showSuccessSnackbar(message: string) {
    this.snackBar.openFromComponent(SnackbarSuccessComponent, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: { message }
    });
  }

  private showErrorSnackbar(message: string) {
    this.snackBar.openFromComponent(SnackbarErrorComponent, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: { message }
    });
  }

  updateActionsForm(data: any): void {
    let indexes = data.selectedActions.map((action: any) => action.index - 1);
    let updatedActions = this.webJourneyForm.value.actions.filter((action: any, index: number) => !indexes.includes(index));
    let newActionSetAction = {
      type: EActionTypeV3.ActionSet,
      label: undefined,
      actionSet: data.actionSet,
      matchAllPages: false,
      filter: ''
    };

    updatedActions.splice(indexes[0], 0, newActionSetAction);
    this.actions.patchValue(updatedActions);
  }

  private createJourneyApiObj(journeyId: number, folderId: number, domainId: number): IWebJourneyV3 {
    const options = this.getOptions();

    let journeyObj = {
      folderId: folderId,
      domainId: domainId,
      name: this.setupValue.name,
      frequency: options.frequency,
      location: options.location,
      userAgent: options.userAgent,
      userAgentDescription: options.userAgentDescription,
      browserWidth: options.browserWidth,
      browserHeight: options.browserHeight,
      vpnEnabled: options.vpnEnabled,
      gpcEnabled: options.gpcEnabled,
      blockThirdPartyCookies: options.blockThirdPartyCookies,
      monitoredByScriptServices: options.monitoredByScriptServices,
      nextCheck: options.nextRun
    } as IWebJourneyV3;

    if (this.recurrenceEnabled) journeyObj['schedule'] = options.schedule;
    if (journeyId) journeyObj['id'] = journeyId;
    if (options.blackoutPeriod) journeyObj['blackoutPeriod'] = options.blackoutPeriod;
    if (options.customProxy) journeyObj['customProxy'] = options.customProxy;
    if (options.notes) journeyObj['notes'] = options.notes;

    return journeyObj;
  }

  private createJourneyUiObj(
    journey: IWebJourneyV3,
    notifications: IWebJourneyV3NotificationConfig,
    rfmConfigs: IRFMConfigV3[]
  ): IWebJourney {
    const obj: IWebJourney = {
      id: journey.id,
      name: journey.name,
      folderId: journey.folderId,
      domainId: journey.domainId,
      options: {
        location: journey.location,
        nextRun: journey.nextCheck as Date,
        browserWidth: journey.browserWidth,
        browserHeight: journey.browserHeight,
        userAgent: journey.userAgent,
        userAgentDescription: journey.userAgentDescription,
        customProxy: journey.customProxy,
        monitoredByScriptServices: journey.monitoredByScriptServices,
        blackoutPeriod: journey.blackoutPeriod,
        frequency: journey.frequency,
        alerts: notifications.alerts,
        vpnEnabled: journey.vpnEnabled,
        gpcEnabled: journey.gpcEnabled,
        blockThirdPartyCookies: journey.blockThirdPartyCookies,
        webHookUrl: notifications.webHookUrl,
        rfmConfig: rfmConfigs.map((config: IRFMConfigV3) => config.id),
        flashLiveVideoEnabled: undefined,
      },
      createdAt: journey.createdAt,
      nextCheck: journey.nextCheck as string,
      userId: journey.userId,
      emails: notifications.emails,
      notes: journey.notes || '',
      domain: undefined,
      status: undefined,
    };

    if (this.recurrenceEnabled) {
      obj.options.schedule = journey.schedule as IOpRecurrenceScheduleRequest;
    }

    return obj;
  }

  getHotkeyHandler(key: EKeyCodes) {
    return key === EKeyCodes.KeyS
      ? () => this.save()
      : null;
  }

  updateTagAndVariableRules(selectedRules: IStandardsSelectorItem[]): void {
    this.selectedRuleIDs = selectedRules.map(rule => rule.id);
  }

  onLocationChanged(location: string) {
    if (location === 'mountain') {
      this.webJourneySetupForm.vpn.enable();
    } else {
      this.webJourneySetupForm.vpn.disable();
    }
  }

  onVPNChanged(vpnEnabled: boolean) {
    if (vpnEnabled && this.webJourneySetupForm.vpnSupport) {
      this.webJourneySetupForm.location.disable();
    } else {
      this.webJourneySetupForm.location.enable();
    }
  }

  handleSaveBtnText(freq: string, runNow: boolean): void {

    setTimeout(() => {
      if (!runNow && (!this.setupValue.startingDate || !this.setupValue.startingTime)) {
        return;
      }

      const startingDate = !runNow
        ? freq === EWebJourneyFrequency.PAUSED
          ? new Date(PAUSED_DATE)
          : this.setupValue.startingDate
        : new Date();

      const startingTime = startingDate.getHours().toString() + ':' + startingDate.getMinutes().toString();
      const isEditMode = !!this.journeyId;
      const nextRunUtc = startingDate && startingTime
        ? this.dateService.changeTimeInDate(startingTime, new Date(startingDate))
        : null;
      const nextRun = nextRunUtc ? new Date(nextRunUtc).getTime() : null;
      const now = new Date().getTime();
      this.isRunning = now > nextRun;

      if (isEditMode) {
        this.saveButton.label = 'Save Changes';
        this.saveAndRunButton.label = 'Save Changes & Run Now';
      } else {
        this.saveButton.label = 'Save Journey';
        this.saveAndRunButton.label = 'Save Journey & Run Now';
      }
      // don't like setting this but it's the only way to get the button to update
    }, 300);
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsTouched();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  handleAddCmpAction(): void {
    this.webJourneySetupForm.markAsTouched();

    if (this.webJourneyForm.invalid) {
      this.submitted = true;
      return;
    }

    const cmpActionIndex = this.getCmpActionIndex();
    if (cmpActionIndex >= 0) {
      this.scrollToActionIndex = cmpActionIndex;
      this.dataSourceEditorService.scrollToCmpAction.next(this.scrollToActionIndex);
    } else {
      this.createdDefaultAction = true;
      let actions = this.actions.value;
      actions.unshift({
        cmpType: ECmpOption.OPT_OUT,
        cmpUrl: '',
        isRequired: true,
        label: 'Set Consent Manager State (CMP)',
        type: EActionTypeV3.CmpOptInOptOut
      });

      this.actions.patchValue(actions);
    }

    this.currentTab = EWebJourneyTab.actions;
  }

  getCmpActionIndex(): number {
    return this.actions.value.findIndex(action => action.type === EActionTypeV3.CmpOptInOptOut);
  }

  private get setup(): AbstractControl {
    return this.webJourneyForm.get('setup');
  }

  private get setupValue(): Partial<IWebJourneySetupForm> {
    return this.setup.value;
  }

  private get actions(): AbstractControl {
    return this.webJourneyForm.get('actions');
  }
}
