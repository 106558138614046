<applied-consent-categories
  [runId]="runId"
  [auditId]="auditId"
  [categories]="ccsAssignedToRun"
  [categoriesType]="categoriesType"
  [auditConsentCategories]="auditConsentCategories"
  [isLoadedAuditConsentCategories]="isLoadedAuditConsentCategories"
  [isLoadedRunConsentCategories]="isLoadedRunConsentCategories"
  class="categories-wrap"
></applied-consent-categories>
<ng-container>
  <!-- WIDGETS -->
  <div class="charts">
    <div class="widget-container">
      <div class="widget-container">
        <split-card [topLabel]="widgetPagesScanned.topLabel"
                    [topChangeMeaning]="widgetPagesScanned.topChangeMeaning"
                    [state]="widgetsState"
                    [metricType]="widgetPagesScanned.metricType"
                    [auditId]="auditId"
                    [runId]="runId"
                    [currentValue]="filteredPageCount"
                    [filters]="currentFilters"
                    [highlight]="highlightMetricType === widgetPagesScanned.metricType"
                    [disableHighlightFromClick]="true"
                    [topTooltip]="'A count of pages scanned in this audit/run.'">
          <div class="widget-pages-details">
            <div class="page-counts">
              <span class="pages-filtered-count">{{ filteredPageCount | number }}</span>
              <span *ngIf="widgetsState === 'filtered'">&nbsp;of {{ totalPageCount | number }}</span>
            </div>
            <filter-spinner [state]="widgetsState"></filter-spinner>
          </div>
        </split-card>
        <split-card [topLabel]="widgetWithUnapprovedCookiesPages.topLabel"
                    [topChangeMeaning]="widgetWithUnapprovedCookiesPages.topChangeMeaning"
                    [topHandler]="widgetWithUnapprovedCookiesPages.topHandler"
                    [haveEnoughData]="true"
                    [activated]="filteredWidget === EConsentCategoryComplianceStatus.Unapproved"
                    [state]="widgetsState"
                    [metricType]="widgetWithUnapprovedCookiesPages.metricType"
                    [auditId]="auditId"
                    [runId]="runId"
                    [filters]="currentFilters"
                    [highlight]="highlightMetricType === widgetWithUnapprovedCookiesPages.metricType"
                    [disableHighlightFromClick]="true"
                    [topTooltip]="'Pages with a cookie marked as unapproved in one or more consent categories.'">
          <div class="widget-pages-details">
            <div class="page-counts">
              <span class="pages-filtered-count"
                    [class.color-graph-red]="unapprovedCookiesCount">
                {{ unapprovedCookiesCount | number }}
              </span>
            </div>
            <filter-spinner [state]="widgetsState"></filter-spinner>
          </div>
        </split-card>
        <split-card [topLabel]="widgetUniqueCookies.topLabel"
                    [topChangeContent]="widgetUniqueCookies.topChangeContent"
                    [topChangeMeaning]="widgetUniqueCookies.topChangeMeaning"
                    [bottomHandler]="widgetUniqueCookies.bottomHandler"
                    [haveEnoughData]="widgetUniqueCookiesSparklineData.length > 1"
                    [state]="widgetsState"
                    [metricType]="widgetUniqueCookies.metricType"
                    [auditId]="auditId"
                    [runId]="runId"
                    [activated]="filteredWidget === EConsentCategoryComplianceStatus.Unique"
                    [currentValue]="widgetUniqueCookies.rawValue"
                    [filters]="currentFilters"
                    [highlight]="highlightMetricType === widgetUniqueCookies.metricType"
                    [disableHighlightFromClick]="true"
                    [topTooltip]="'Count of unique cookies discovered during this audit/run. Unique cookies are defined by cookie name and cookie domain combination. Leading dot in domain is disregarded. '">
          <sparkline-chart-insight [meaningOfUp]="'none'"
                                  [meaningOfNoChange]="'none'"
                                  [label]="'since last run'"
                                  [dataLoaded]="sparklineDataLoaded"
                                  [dataPoints]="widgetUniqueCookiesSparklineData">
          </sparkline-chart-insight>
        </split-card>
        <split-card [topLabel]="widgetApprovedCookies.topLabel"
                    [topChangeContent]="widgetApprovedCookies.topChangeContent"
                    [topChangeMeaning]="widgetApprovedCookies.topChangeMeaning"
                    [bottomHandler]="widgetApprovedCookies.bottomHandler"
                    [topHandler]="widgetApprovedCookies.topHandler"
                    [haveEnoughData]="widgetApprovedCookiesSparklineData.length > 1"
                    [state]="widgetsState"
                    [metricType]="widgetApprovedCookies.metricType"
                    [auditId]="auditId"
                    [runId]="runId"
                    [activated]="filteredWidget === EConsentCategoryComplianceStatus.Approved"
                    [currentValue]="widgetApprovedCookies.rawValue"
                    [filters]="currentFilters"
                    [highlight]="highlightMetricType === widgetApprovedCookies.metricType"
                    [disableHighlightFromClick]="true"
                    [topTooltip]="'Cookies discovered during this audit/run and approved in one or more consent categories. ' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
          <sparkline-chart-insight [meaningOfUp]="'none'"
                                  [meaningOfNoChange]="'none'"
                                  [label]="'since last run'"
                                  [dataLoaded]="sparklineDataLoaded"
                                  [dataPoints]="widgetApprovedCookiesSparklineData">
          </sparkline-chart-insight>
        </split-card>
        <split-card [topLabel]="widgetUnapprovedCookies.topLabel"
                    [topChangeContent]="widgetUnapprovedCookies.topChangeContent"
                    [topChangeMeaning]="widgetUnapprovedCookies.topChangeMeaning"
                    [bottomHandler]="widgetUnapprovedCookies.bottomHandler"
                    [topHandler]="widgetUnapprovedCookies.topHandler"
                    [state]="widgetsState"
                    [metricType]="widgetUnapprovedCookies.metricType"
                    [auditId]="auditId"
                    [runId]="runId"
                    [activated]="filteredWidget === EConsentCategoryComplianceStatus.Unapproved"
                    [haveEnoughData]="widgetUnapprovedCookiesSparklineData.length > 1"
                    [filters]="currentFilters"
                    [highlight]="highlightMetricType === widgetUnapprovedCookies.metricType"
                    [disableHighlightFromClick]="true"
                    [topTooltip]="'Cookies discovered during this audit/run and unapproved in one or more consent categories. ' + (widgetsState === 'filtered' ? ' (Filtered)' : '')">
          <sparkline-chart-insight [meaningOfUp]="'negative'"
                                  [meaningOfNoChange]="'none'"
                                  [label]="'since last run'"
                                  [dataLoaded]="sparklineDataLoaded"
                                  [dataPoints]="widgetUnapprovedCookiesSparklineData">
          </sparkline-chart-insight>
        </split-card>
      </div>
    </div>
  </div>

  <div #cookieComplianceSection>
    <privacy-cookies-compliance-table
      [auditId]="auditId"
      [runId]="runId"
      [auditConsentCategories]="auditConsentCategories"
      [runConsentCategories]="ccsAssignedToRun"
      [filteredPageCount]="filteredPageCount"
      [filteredWidget]="filteredWidget"
      (onCookieClicked)="handleSelectedCookieClicked($event)"
      (onAddToConsentCategoryClicked)="addToConsentCategoryClicked($event)"
      (onManageConsentCategories)="openConsentCategoryManager()"
      (onSetConsentCategoryNameGlobalFilterClicked)="handleSetConsentCategoryNameFilterFromCompliance($event)"
      (onEditToConsentCategoryClicked)="editConsentCategories($event)"
    ></privacy-cookies-compliance-table>
  </div>

  <div #pagesSection>
    <div class="section-title-wrap pages">
      <div class="title-spinner-wrap">
        <filter-spinner class="left-side" [state]="cookiePrivacyPagesSpinnerState"></filter-spinner>
        <p class="section-title">Pages Scanned</p>
        <op-audit-report-export-menu
          [auditId]="auditId"
          [runId]="runId"
          [tableCompletedState]="cookiePrivacyPagesSpinnerState"
          [config]="pagesExportConfig">
        </op-audit-report-export-menu>
      </div>

      <op-switchable-column-menu [columnConfig]="PagesTableColumns"
                                 [storageKey]="CommonPagesConfigLocalStorageKey"
                                 [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
    </div>

    <privacy-cookies-pages-logs-table
      [inputData]="cookiePrivacyPages"
      [spinnerState]="cookiePrivacyPagesSpinnerState"
      [tableState]="cookiePrivacyPagesTableState"
      [selectedCookie]="selectedCookie"
      (updateTableState)="updatePagesState()"
    ></privacy-cookies-pages-logs-table>
  </div>
</ng-container>
