<div class="no-alerts-not-subscribed">
  <!-- NOT visitor mode -->
  <ng-container *ngIf="(isVisitorMode$ | async) === false">
    <!-- no alerts exist in account view -->
    <ng-container *ngIf="viewToShow === EAuditPopoverStates.NoAlertsInAccount">
      <div class="heading">No alerts have been created in your account.</div>
      <div class="body">
        Find the bell icon (<mat-icon class="bell-icon">add_alert</mat-icon>) on any data insight you want to be alerted on, create a custom alert below, or pick from existing alerts in audit config.
      </div>
      <img class="bell-icon-image mat-elevation-z5" src="/images/bell-icon-in-report.png" alt="bell icon" />
    </ng-container>

    <!-- no alerts configured for audit view -->
    <ng-container *ngIf="viewToShow === EAuditPopoverStates.NoneConfigured">
      <div class="heading">No alerts have been configured for this audit yet.</div>
      <div class="body">
        Find the bell icon (<mat-icon class="bell-icon">add_alert</mat-icon>) on any data insight you want to be alerted on, create a custom alert below, or pick from existing alerts in audit config.
      </div>
      <img class="bell-icon-image mat-elevation-z5" src="/images/bell-icon-in-report.png" alt="bell icon" />
    </ng-container>

    <!-- user not subscribed to configured alerts view -->
    <ng-container *ngIf="viewToShow === EAuditPopoverStates.NotSubscribed">
      <div class="heading">You are not subscribed to any<br>alerts in this audit.</div>
      <div class="body">
        <button (click)="routeToAlertsSummary.emit()" class="route-btn">View & subscribe to alerts created by others</button> or find the bell icon (<mat-icon class="bell-icon">add_alert</mat-icon>) on any data insight you want to be alerted on or create a custom alert below.
      </div>
      <img class="bell-icon-image mat-elevation-z5" src="/images/bell-icon-in-report.png" alt="bell icon" />
    </ng-container>
  </ng-container>
  <!-- visitor mode -->
  <ng-container *ngIf="(isVisitorMode$ | async)">
    <div class="heading">No alerts have been been configured for this audit yet.</div>
    <div class="body">
      After starting your free trial or purchasing a full account, log in and find the bell icon (<mat-icon class="bell-icon">add_alert</mat-icon>) on any data insight you want to be alerted on or create a custom alert.
    </div>
    <img class="bell-icon-image mat-elevation-z5" src="/images/bell-icon-in-report.png" alt="bell icon" />
  </ng-container>
</div>