import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { AuditReportUrlBuilders } from '../../../audit-reports/audit-report/audit-report.constants';
import { ActivatedRoute } from '@angular/router';

const tooltipMessage = {
  default: 'Copy link to this page report',
  copied: 'Report link has been copied!'
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'share-report-btn',
  templateUrl: './share-report-btn.component.html',
  styleUrls: ['./share-report-btn.component.scss']
})
export class ShareReportBtnComponent implements OnChanges {

  pageLink: string;
  tooltip: string = tooltipMessage.default;

  @Input() auditId: number;
  @Input() runId: number;
  @Input() pageId: string;
  @Input() activeTab: string;
  @Input() state?: { [x: string]: string };

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if((changes.pageId && changes.activeTab) || changes.state) {
      this.pageLink = this.getReportLink();
    }
  }

  getReportLink(): string {
    const origin = window.location.origin;
    const path = AuditReportUrlBuilders.pageDetailsDeepLink(this.auditId, this.runId, this.pageId);
    const stateParams = this.getStateParams();
    return `${origin}${path}?tab=${this.activeTab}${stateParams}`;
  }

  getStateParams(): string {
    if (!this.state) return '';

    let params = '';

    for (let prop in this.state) {
      if (this.state.hasOwnProperty(prop) && typeof this.state[prop] !== 'undefined') {
        params += `&${prop}=${this.state[prop]}`;
      }
    }

    return params;
  }

  showCopyConfirmation(event: MouseEvent): void {
    // prevents tooltip from hiding on click
    event.stopPropagation();

    this.tooltip = tooltipMessage.copied;
    setTimeout(() => {
      this.tooltip = tooltipMessage.default;
    }, 2000);
  }
}
