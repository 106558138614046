import { Component } from '@angular/core';
import { EAlertAuditSummaryMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { EColorHighlightMeaning } from '@app/components/shared/components/viz/color-highlight/color-highlight.directive';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-report-widgets',
  templateUrl: './cl-report-widgets.component.html',
  styleUrls: ['./cl-report-widgets.component.scss']
})
export class ComponentLibraryReportWidgetsComponent {

  readonly EColorHighlightMeaning = EColorHighlightMeaning;
  readonly EFilterSpinnerState = EFilterSpinnerState;
  readonly EAlertAuditSummaryMetric = EAlertAuditSummaryMetric;

  widget1Value = Number('1234').toLocaleString();
  widget2Value = Number('2309').toLocaleString();
  widget3Value = Number('647').toLocaleString();
  widget3SparklineData = [
    {
      value: 21,
      sequence: 1
    },
    {
      value: 87,
      sequence: 2
    },
    {
      value: 65,
      sequence: 3
    },
    {
      value: 79,
      sequence: 4
    },
    {
      value: 44,
      sequence: 5
    }
  ];
}
