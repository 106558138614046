<form [formGroup]="formData">
  <span class="account-text">Account</span>

  <mat-form-field class="styled-mat-input" appearance="outline">
    <mat-label>Operator</mat-label>
    <mat-select formControlName="matchType">
      <mat-option class="slim-mat-option" *ngFor="let operator of TagAccountOperators" [value]="operator.value">
        {{ operator.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="matchTypeControl.touched && matchTypeControl.invalid">
      <mat-icon>warning_amber</mat-icon>
      Field can't be empty
    </mat-error>
  </mat-form-field>

  <mat-form-field class="styled-mat-input" appearance="outline">
    <mat-label>Value</mat-label>
    <input matInput formControlName="value">
    <mat-error *ngIf="valueControl.touched && valueControl.invalid">
      <mat-icon>warning_amber</mat-icon>
      Field can't be empty
    </mat-error>
  </mat-form-field>

  <mat-form-field class="styled-mat-input last-field" appearance="outline">
    <mat-label>Validation description</mat-label>
    <input matInput formControlName="validationDescription">
  </mat-form-field>

  <mat-icon class="remove-icon" (click)="onRemoveAccount.emit()">
    highlight_off
  </mat-icon>
</form>
