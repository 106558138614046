<div class="heading">
  No alerts have been configured yet.
</div>
<div class="message">
  In an audit find the bell icon (<mat-icon class="bell-icon">add_alert</mat-icon>) on any data insight you want to be alerted on,
  create a new alert below, or pick from existing alerts in audit library.
</div>
<img class="bell-icon-image mat-elevation-z5" src="/images/bell-icon-in-report.png" alt="bell icon" />
<button  mat-raised-button color="primary" (click)="addAlert()">
  <mat-icon>add_circle_outline</mat-icon>
  Create New Alert
</button>