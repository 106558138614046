<op-modal (onClose)="close()" [rightFooterButtons]="footerButtons">
  <ng-template #headerTemplate>
    <op-modal-header [title]="modalTitle" (onClose)="close()"></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="form-container" [formGroup]="userForm">
      <op-user-steps [currentStep]="currentStep"
                     [profileStepSubtitle]="profileStepSubtitle"
                     [selectedPermissionLevel]="selectedPermissionLevel"
                     [selectedFoldersCount]="selectedFoldersCount"
                     [stepOneValid]="stepOneValid"
                     [stepTwoValid]="stepTwoValid"
                     [stepThreeValid]="stepThreeValid"
                     [selectedNotificationsCount]="selectedNotificationsCount"
                     [editMode]="editMode"
                     (stepSelected)="goToStep($event)"
      >
      </op-user-steps>
      <div class="content">
        <op-user-profile *ngIf="currentStep === EUserStep.Profile"
                         [currentStep]="currentStep"
                         [editMode]="editMode"
                         [currentUser]="currentUser"
                         [userBeingEdited]="userBeingEdited"
                         [formGroup]="profileFormGroup"
                         (profileUpdated)="handleProfileChanges()"
        >
        </op-user-profile>
        <op-user-permissions *ngIf="currentStep === EUserStep.Permissions"
                             [currentStep]="currentStep"
                             [formGroup]="permissionsFormGroup"
                             [editMode]="editMode"
                             [currentUser]="currentUser"
                             [userBeingEdited]="userBeingEdited"
                             [accountType]="accountType"
                             (selectedPermissionLevel)="updateSidebarWithPermissionLevel($event)"
                             (stepTwoValid)="updateStepTwoValidState($event)"
        >
        </op-user-permissions>
        <op-user-folder-access [currentStep]="currentStep"
                               [class.hidden]="currentStep !== EUserStep.FolderAccess"
                               [formGroup]="folderAccessFormGroup"
                               [editMode]="editMode"
                               [currentUser]="currentUser"
                               [userBeingEdited]="userBeingEdited"
                               [permissionLevel]="permissionLevel.value"
                               (selectedFoldersCount)="updateSidebarWithSelectedFoldersCount($event)"
                               (stepThreeValid)="updateStepThreeValidState($event)"
        >
        </op-user-folder-access>
        <op-user-notifications #userNotificationsComponent
                               *ngIf="!isInAdminPortal"
                               [class.hidden]="currentStep !== EUserStep.Notifications"
                               [currentStep]="currentStep"
                               [currentUser]="currentUser"
                               [userBeingEdited]="userBeingEdited"
                               [notificationProfile]="notificationProfile"
                               [formGroup]="notificationsFormGroup"
                               (selectionChanged)="notificationsSelectionChanged($event)"
        >
        </op-user-notifications>
      </div>
    </div>
  </ng-template>
</op-modal>

