<div class="split-card-wrapper"
     [class.highlight-widget]="highlight"
     [class.widget-hovered]="widgetHover">
  <div class="split-top"
       (click)="handleTopClick()"
       [matTooltip]="!richTooltip ? (topTooltip + ((topTooltip && topHandler) ? (activated ? ' Click to clear the filter.' : ' Click to toggle filter.') : '')) : ''"
       matTooltipShowDelay="1200"
       matTooltipPosition="above"
       (mouseenter)="labelHover()"
       (mouseout)="labelBlur()"
       cdkOverlayOrigin
       #trigger="cdkOverlayOrigin"
       [ngClass]="{
        'top-hover': topHandler,
        'color-graph-green': topChangeMeaning === 'positive',
        'color-graph-yellow': topChangeMeaning === 'sortofpositive',
        'color-graph-orange': topChangeMeaning === 'sortofnegative',
        'color-graph-red': topChangeMeaning === 'negative'
        }">
    <ng-template
      *ngIf="richTooltip"
      cdkConnectedOverlay
      cdkConnectedOverlayPanelClass="no-pointer-events"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="widgetLabelHover"
      cdkConnectedOverlayPush
      [cdkConnectedOverlayPositions]="positionPairs"
      [cdkConnectedOverlayWidth]="360"
    >
      <ng-content select="[overlayTooltip]"></ng-content>
    </ng-template>
    <div class="split-top-label" [class.has-change-content]="topChangeContent">
      <div class="label-wrapper">
        <mat-icon *ngIf="icon" [ngClass]="iconColor">{{icon}}</mat-icon>
        {{topLabel}}
      </div>
      <op-widget-bell *ngIf="metricType"
                      [auditId]="auditId"
                      [runId]="runId"
                      [widgetName]="metricType"
                      [widgetIsHovered]="widgetHover"
                      [currentValue]="alertBellValue ?? currentValue"
                      [menuContext]="menuContext"
                      [disableHighlightFromClick]="disableHighlightFromClick"
                      [filters]="filters"
      ></op-widget-bell>
    </div>
    <div class="split-top-content">
      <span class="split-top-content-value">
        {{topChangeContent}}
      </span>
      <span class="split-top-content-filtered-value"
            *ngIf="topChangeFilteredContent">of {{ topChangeFilteredContent }}</span>
    </div>
    <filter-spinner [state]="state"></filter-spinner>
  </div>
    <div class="split-bottom"
       (click)="onBottomClick()"
       [ngClass]="{'bottom-hover': !!bottomHandler && haveEnoughData}">
      <ng-content></ng-content>
    </div>
    <div class="split-bottom"
         *ngIf="cardFooterVisible"
         [ngClass]="{'bottom-hover': !!bottomHandler && haveEnoughData}">
      <ng-content select="[cardFooter]"></ng-content>
    </div>
</div>
