import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/components/material/material.module';
import { NgModule } from '@angular/core';
import { ReprocessComparisonsModalComponent } from './reprocess-comparisons-modal.component';
import { SharedModule } from '../shared/shared.module';
import { OpModalModule } from '../shared/components/op-modal/op-modal.module';

@NgModule({
  declarations: [
    ReprocessComparisonsModalComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    SharedModule,
    OpModalModule
  ]
})
export class ReprocessComparisonsModalModule {
}
