import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
import {
  IAuditReportCard,
  IWebJourneyReportCard
} from '@app/components/manage/cards/report-card-list/report-card-list.models';
import { NavigationExtras } from '@angular/router';
import { PAUSED_YEAR } from '@app/components/utilities/dataSourcesUtils';

export const ManageCardsStateNames = {
  cardsParent: 'manage',
  manageSources: 'manage.sources'
};

export const ManageCardsPaths = {
  base: '',
  sources: 'data-sources'
};

export const DataSourcesUrlBuilders = {
  base: () => RouteBuilder.buildUrl([ManageCardsPaths.base]),
  sources: () => RouteBuilder.buildUrl([ManageCardsPaths.base, ManageCardsPaths.sources]),
  selected: (itemType: string, itemId: number, isMovingItems?: boolean): {
    route: string,
    extras: NavigationExtras
  } => {
    return {
      route: RouteBuilder.buildUrl([ManageCardsPaths.base, ManageCardsPaths.sources]),
      extras: {
        queryParams: { itemType, itemId, isMovingItems },
        queryParamsHandling: 'merge'
      }
    };
  }
};

export const PAUSED_DATE = PAUSED_YEAR + '-01-02T00:00:00.000Z';

export interface IFrequencyWithNextRun {
  value: string,
  frequencyString: string,
  nextRun: string
}

export interface IStatusWithTooltip {
  value: string,
  icon: string,
  status: string
}

export enum EManageCardsViewMode {
  Card = 'card',
  List = 'list',
  Calendar = 'calendar'
}

export type IReportCardScanStatus = 'Ok'|'ActionFailure'|'RulesFailure'|0|1|2|null;
export type IReportCardScanType = 'audit'|'webJourney';
export type IScanModel = IAuditReportCard | IWebJourneyReportCard | any;
export const multiSelectEvent = 'multiSelectItemsRun';
