export enum EToastMessages {
  NoLongerExistsEdit = 'The rule you are attempting to edit no longer exists.',
  NoLongerExistsRemove = 'The rule you are attempting to remove no longer exists.',
  RemovedOnNextRun = 'Your rule was successfully removed and the changes will be reflected after the next run.',
  UpdatedOnNextRun = 'Your rule was successfully updated and the changes will be reflected after the next run.',
  NotFoundOnAction = 'This rule could not be found on this action.',
  NotFoundInJourney = 'This rule could not be found in this journey.',
  PredefinedRule = 'This is a pre-defined rule and cannot be edited.',
  RuleConditionMissing = 'The rule condition you are attempting to modify no longer exists.',
  GeneralError = 'There was an error processing your request.'
}