import { Component, Input } from '@angular/core';

@Component({
  selector: 'op-section-divider',
  templateUrl: './op-section-divider.component.html',
  styleUrls: ['./op-section-divider.component.scss']
})
export class OpSectionDividerComponent {

  @Input() text: string;

}
