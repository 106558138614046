<div class="top-wrap">
  <filter-spinner [state]="state"></filter-spinner>
  <p class="title">Tag Compliance</p>
  <op-audit-report-export-menu
    [auditId]="auditId"
    [runId]="runId"
    [tableCompletedState]="state"
    [config]="exportReportConfig">
  </op-audit-report-export-menu>
</div>

<div class="table-wrap">
  <div #scrollTop></div>
  <table
    mat-table
    matSort
    class="tags-list"
    matSortStart="desc"
    matSortActive="compliance_status"
    matSortDirection="desc"
    [class.updating]="state === 'loading'"
    [dataSource]="dataSource"
    [class.is-filtered]="isFiltered"
  >
    <ng-container matColumnDef="tag_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>TAG NAME</th>
      <td mat-cell *matCellDef="let tag">
        <div class="tag-name-wrap">
          <div class="icon"><img [src]="tag.tagIcon"></div>
          <div class="text">
            <p class="title">{{tag.tagName}}</p>
            <span class="sub-title">{{tag.tagAccount}}</span>
          </div>
          <actions-menu>
            <button mat-menu-item (click)="globalFilterByTagName(tag)">
              <mat-icon>tune</mat-icon>
              Filter report by this item
            </button>
            <button mat-menu-item (click)="handleSetFilterAndNavigate(tag)">
              <mat-icon>tune</mat-icon>
              View additional details on Tag Inventory
            </button>
          </actions-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="compliance_status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>STATUS</th>
      <td mat-cell *matCellDef="let tag; let index;">
        <div class="status"
             [class.approved]="tag.consentCategoryComplianceStatus && isStatusApproved(tag)"
             [class.unapproved]="tag.consentCategoryComplianceStatus && !isStatusApproved(tag)"
        >
          <div class="status-with-cc-wrapper"
               *ngIf="tag.consentCategoryComplianceStatus; else uncategorized">
            <mat-icon>{{isStatusApproved(tag) ? 'check_circle' : 'warning'}}</mat-icon>
            {{tag.consentCategoryComplianceStatus}}
            <button mat-icon-button
                    class="trigger-button"
                    [matMenuTriggerFor]="statusActionMenu"
                    (click)="statusActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
            >
              <mat-icon class="trigger-icon">more_vert</mat-icon>
            </button>
            <mat-menu #statusActionMenu="matMenu"
                      class="cc-action-menu"
                      (closed)="statusActionMenuOpen = false; ccActionIndex = null"
            >
              <button mat-menu-item
                      [disabled]="userIsReadOnly"
                      (click)="addToConsentCategoryClicked(tag, $event)"
              >
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} & Add this to a Consent Category
              </button>
              <button mat-menu-item
                      [disabled]="userIsReadOnly"
                      (click)="onManageConsentCategories.emit()"
              >
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} multiple items & Assign to existing or new Consent Category
              </button>
              <button mat-menu-item
                      (click)="globalFilterByStatus(tag)">
                <mat-icon>tune</mat-icon>
                Filter report by all "{{ isStatusApproved(tag) ? 'Approved' : 'Unapproved' }}" items
              </button>
            </mat-menu>
          </div>
          <ng-template #uncategorized>
            <i>Uncategorized</i>
            <button mat-icon-button
                    class="trigger-button"
                    [matMenuTriggerFor]="statusActionMenu"
                    (click)="statusActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
            >
              <mat-icon class="trigger-icon">more_vert</mat-icon>
            </button>
            <mat-menu #statusActionMenu="matMenu"
                      class="cc-action-menu"
                      (closed)="statusActionMenuOpen = false; ccActionIndex = null"
            >
              <button mat-menu-item
                      [disabled]="userIsReadOnly"
                      (click)="addToConsentCategoryClicked(tag, $event)"
              >
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                Add this item to a Consent Category
              </button>
              <button mat-menu-item
                      [disabled]="userIsReadOnly"
                      (click)="onManageConsentCategories.emit()"
              >
                <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
                Add multiple items to a new or existing Consent Category
              </button>
            </mat-menu>
          </ng-template>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="consent_category_name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>CONSENT CATEGORY</th>
      <td mat-cell *matCellDef="let tag; let index;">
        <div class="category-name" *ngIf="tag.consentCategoryName; else addCategory">
          {{tag.consentCategoryName}}

          <button mat-icon-button
                  class="trigger-button"
                  [matMenuTriggerFor]="ccActionMenu"
                  (click)="ccActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
          >
            <mat-icon class="trigger-icon">more_vert</mat-icon>
          </button>
          <mat-menu #ccActionMenu="matMenu"
                    class="cc-action-menu"
                    (closed)="ccActionMenuOpen = false; ccActionIndex = null"
          >
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    (click)="addToConsentCategoryClicked(tag, $event)"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} & Add this to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ !runConsentCategories?.length ? 'Assign ' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}} multiple items & Assign to existing or new Consent Category
            </button>
            <button mat-menu-item
                    (click)="globalFilterByCategoryName(tag)">
              <mat-icon>tune</mat-icon>
              Filter report by this Consent Category
            </button>
          </mat-menu>
        </div>

        <ng-template #addCategory>
          <div class="cc-action-icon"
               *ngIf="!userIsReadOnly && !runConsentCategories?.length"
               (click)="openAuditEditorToConsentCategoryStandard($event)"
          >
            <mat-icon class="add-category">add_circle_outline</mat-icon>
            <span class="cc-action-text">Assign</span>
          </div>
          <div class="cc-action-icon"
               [class.cc-action-menu-open]="ccActionMenuOpen && ccActionIndex === index"
               *ngIf="!userIsReadOnly && runConsentCategories?.length"
               (click)="addToConsentCategoryClicked(tag, $event)"
          >
            <mat-icon class="add-category">add_circle_outline</mat-icon>
            <span class="cc-action-text">
              {{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve & Add' : 'Unapprove & Add'}}
            </span>
          </div>
          <button mat-icon-button
                  class="trigger-button"
                  [matMenuTriggerFor]="ccActionMenu"
                  (click)="ccActionMenuOpen = true; ccActionIndex = index; $event.stopPropagation();"
          >
            <mat-icon class="trigger-icon">more_vert</mat-icon>
          </button>
          <mat-menu #ccActionMenu="matMenu"
                    class="cc-action-menu"
                    (closed)="ccActionMenuOpen = false; ccActionIndex = null"
          >
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="!runConsentCategories?.length"
                    (click)="addToConsentCategoryClicked(tag, $event)"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              Add this item to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="!runConsentCategories?.length"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ !runConsentCategories?.length ? 'thumbs_up_down' : runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              Add multiple items to a new or existing Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="runConsentCategories?.length"
                    (click)="addToConsentCategoryClicked(tag, $event)"
            >
              <mat-icon>{{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve & ' : 'Unapprove & '}}Add this to a Consent Category
            </button>
            <button mat-menu-item
                    [disabled]="userIsReadOnly"
                    *ngIf="runConsentCategories?.length"
                    (click)="onManageConsentCategories.emit()"
            >
              <mat-icon>{{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'thumb_up' : 'thumb_down'}}</mat-icon>
              {{ runConsentCategories[0]?.type === EConsentCategoryType.APPROVED ? 'Approve ' : 'Unapprove '}}multiple items & Assign to existing or a new Consent Category
            </button>
          </mat-menu>
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="page_count">
      <th mat-header-cell mat-sort-header *matHeaderCellDef># OF PAGES</th>
      <td mat-cell *matCellDef="let tag">
        <div class="chart-wrapper" [class.selected]="isLineSelected(tag)">
          <horizontal-bar-chart [data]="tag.onPages.chartData"
                                [barDirection]="tag.onPages.barDirection"
                                [uniqueIdentifier]="tag.onPages.uniqueIdentifier"
                                [state]="tag.barChartSettings.state"
                                [displayPills]="tag.barChartSettings.displayPills"
                                [calcAsPercentage]="tag.barChartSettings.calcAsPercentage"
                                [displayPercentSymbol]="tag.barChartSettings.displayPercentSymbol"
                                [textPosition]="tag.barChartSettings.textPosition"
          ></horizontal-bar-chart>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr
      mat-row
      *matRowDef="let tag; columns: columns;"
      (click)="filterByPage(tag)"
      [class.row-selected]="isLineSelected(tag)"
    ></tr>
  </table>
</div>

<mat-paginator
  class="paginator"
  hidePageSize="true"
  [length]="paginationState.total"
  [pageSize]="paginationState.size"
></mat-paginator>
