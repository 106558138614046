<div class="events-list">
  <div class="events-list-head">
    <span>{{ currentDate | date: monthDayFormat}}</span>
    <hr class="header-break" />
  </div>
  <div class="events-list-body">
    <div class="events-list-item flex-row"
         *ngFor="let event of events"
         [class.selected]="event.isSelected"
         (click)="handleClickEvent(event)">

      <span [ngSwitch]="event.status"
            class="status-icon"
            [matTooltip]="statusToMessageMap.get(event.status)"
            matTooltipPosition="above">

        <mat-icon *ngSwitchCase="runPickerStatus.Success" class="events-icon-success">check_circle</mat-icon>
        <mat-icon *ngSwitchCase="runPickerStatus.ActionFailed" class="events-icon-action-failure">cancel</mat-icon>
        <mat-icon *ngSwitchCase="runPickerStatus.RuleFailure" class="events-icon-rule-failure">error</mat-icon>

      </span>
      <span>{{ event.date | date: hourMinuteFormat }}</span>
    </div>
  </div>
</div>
