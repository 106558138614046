import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { userIsAdmin, userIsGuest } from '@app/authUtils';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { EDateFormats, formatDate } from '@app/components/date/date.service';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { IAuditCardModel, IAuditModel } from '@app/components/modals/modalData';
import { StatusBannerService, StatusUpdateType } from '@app/components/reporting/statusBanner/statusBannerService';
import { IRunPickerRun } from '@app/components/run-picker-ng/run-picker-ng.models';
import { IMenuItem } from '@app/components/shared/components/op-menu/op-menu.component';
import { RunnableItem } from '@app/components/stopRun/stopRunModalController';
import { IStopRunService } from '@app/components/stopRun/stopRunService';
import { IAuditReportParams } from '../audit-report-container.models';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import {
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { IUser } from '@app/moonbeamModels';
import { OpModalService } from '@app/components/shared/components/op-modal';
import {
  IReprocessService,
  ReprocessBannerStatus
} from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import {
  IReprocessComparisonsModalData
} from '@app/components/reprocess-comparisons-modal/reprocess-comparisons-modal.models';
import {
  ReprocessComparisonsModalComponent
} from '@app/components/reprocess-comparisons-modal/reprocess-comparisons-modal.component';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { IHistoryFrame, RouteHistoryService } from '@app/components/shared/services/route-history.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import { RouteDataService } from '@app/components/shared/services/route-data.service';
import { AuditReportContainerStateNames, AuditReportContainerUrlBuilders } from '../audit-report-container.constants';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { EDataSelectorType, SwitchAccountService } from '@app/components/account/admin/switch-account.service';
import {
  ReprocessConfirmationSnackbarService
} from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.service';
import { Features, ServerErrorCodes } from '@app/moonbeamConstants';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { IConsentCategories, } from '@app/components/consent-categories/consent-categories.models';
import {
  IReprocessConfirmationItem
} from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.component';
import { AccountsService } from '@app/components/account/account.service';
import { KeyboardShortcutsService } from '../../shared/services/keyboard-shortcuts/keyboard-shortcuts.service';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { IAuditEditorCloseOptions } from '@app/components/audit/audit-editor/audit-editor.models';
import { AlertComponent } from '@app/components/alert/alert.component';
import {
  TerminateActiveRunsModalService
} from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';
import { ModalType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import { EAlertSearchSortBy } from '@app/components/alert/alert.constants';
import { IAlertQuickCreatePayload, IAlertsSearchAssignmentBody } from '@app/components/alert/alert.models';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { EAlertFilterType, EAlertModalType } from '@app/components/alert/alert.enums';
import { EProductType } from '@app/components/shared/components/op-standards-selector/op-standards-selector.constants';
import { EAlertBellStatus } from '@app/components/alert/alert-bell/alert-bell.enums';
import { IBellIconStateData } from './audit-report-header-popover/audit-report-header-popover.models';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { RUN_AUDIT_NOW } from '@app/components/audit/audit.constants';
import { AuditReportChangesService } from '../audit-report-changes.service';
import { ReportCardService } from '@app/components/manage/cards/report-card/report-card.service';
import { ManageCardsService } from '@app/components/manage/cards/manage-cards.service';
import { AuditReportPaths, AuditReportUrlBuilders } from '../audit-report/audit-report.constants';
import { AuditReportFilterBarService } from '../audit-report-filter-bar/audit-report-filter-bar.service';
import { ShareReportModalComponent } from '../share-report-modal/share-report-modal.component';
import { IShareReportModalPayload } from '../share-report-modal/share-report-modal.models';
import { FreeTrialAdModalComponent } from './free-trial-ad-modal/free-trial-ad-modal.component';
import { UseCaseService } from '@app/components/audit-reports/use-cases/use-case.service';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import {
  ESharedItemDetailsItemType,
  ESharedItemDetailsSharedItemType
} from '@app/components/share-links/share-links.enums';
import { ShareLinksPaths } from '@app/components/share-links/share-links.constants';
import { ISharingRequest } from '@app/components/share-links/share-links.models';
import { ShareLinksService } from '@app/components/share-links/share-links.service';
import {
  EFreeTrialAdModalType
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import { CardTypes } from '@app/components/manage/cards/report-card-list/report-card-list.constants';
import { LocationsService } from '@app/components/shared/services/locations.service';
import { EAuditRunStatus, EAuditStatusPillText, EStartingUrlFailureType } from './audit-report-header.constants';
import { IAuditRunStatus } from './audit-report-header.models';
import { EPageErrorType } from '../audit-report-filter-bar/audit-report-filter-bar.models';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import {
  ELeadingIconColor,
  EStatusButtonColor
} from '@app/components/shared/components/status-popover-button/status-popover-button.constants';
import { AuditReportLoadingService } from '../audit-report-loading.service';
import { MdePopoverTrigger } from '@app/components/popover';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-report-header',
  templateUrl: './audit-report-header.component.html',
  styleUrls: ['./audit-report-header.component.scss']
})
export class AuditReportHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly EStatusButtonColor = EStatusButtonColor;
  readonly ELeadingIconColor = ELeadingIconColor;
  readonly statusBannerUpdateType = StatusUpdateType;

  @ViewChild(MdePopoverTrigger) popover: MdePopoverTrigger;
  @ViewChild('auditInfoTrigger', { read: ElementRef }) auditInfoTrigger: ElementRef;

  stateParams: IAuditReportParams;
  auditId: number;
  runId: number;
  nextRun: Date;
  audit: IAuditCardModel|IAuditModel;
  showPauseIconFlag: boolean = false;

  runPickerVisible: boolean;
  auditRuns: IRunPickerRun[];

  currentRunDate: string = 'Select Run';
  auditName: string = 'Select...';

  folderName: string;
  domainName: string;
  domainId: number;

  user: IUser;
  isReadOnly: boolean = true;
  comparisonsEnabled: boolean = false;
  opSelectorString: string = '';

  noAudit: boolean;
  loaded: boolean;
  auditRunning: boolean;
  scheduledToScan: boolean;

  enabledFeatures: string[];
  disableWarningHighlight: boolean = false;

  runCancelled: boolean = false;

  alertPopoverOpen: boolean = false;

  params = {
    size: 1000,
    page: 0,
    sortBy: EAlertSearchSortBy.ALERT_NAME,
    sortDesc: false
  };

  requestBody: IAlertsSearchAssignmentBody = {
    targetItem: {
      itemType: EProductType.AUDIT,
      itemId: null,
      isAssigned: true
    }
  };

  bellStatus: EAlertBellStatus = EAlertBellStatus.CreateAlert;
  alertsCount: number = 0;
  accountHasAlerts: boolean = false;

  subscriptionToken: number;

  auditHasNotRunYet: boolean = false;

  readonly menuOptions: IMenuItem[] = [{
    icon: 'icon-edit',
    label: 'Edit',
    onClick: () => this.editAudit(),
  }, {
    icon: 'icon-run',
    label: 'Run Now',
    onClick: () => this.runNow(),
    hidden:  () => this.inProgress(),
    disable: (model: any, currentUser: IUser) => this.runNowDisabled(model, currentUser),
    disableText: (model: any, currentUser: IUser) => this.userPageLimitInsufficient(model, currentUser),
  }, {
    icon: 'icon-stop',
    label: 'Stop & Discard Run',
    onClick: () => this.stopRun(),
    hidden:  () => !this.inProgress()
  }, {
    icon: 'icon-pause',
    label: 'Pause Scheduled Runs',
    onClick: () => this.pauseAudit(),
    hidden:  () => !this.canPause(),
  }, {
    icon: 'icon-refresh',
    label: 'Resume Scheduled Runs',
    onClick: () => this.resumeAudit(),
    hidden:  () => !this.canResume(),
  }, {
    icon: 'icon-refresh',
    label: 'Reprocess Rules',
    onClick: () => this.reprocessRules(),
  }, {
    icon: 'icon-refresh',
    label: 'Reprocess Comparisons',
    onClick: () => this.reprocessComparisons()
  }, {
    icon: 'icon-refresh',
    label: 'Reprocess Consent Categories',
    onClick: () => this.reprocessConsentCategories(),
    hidden:  () => !(this.enabledFeatures && this.enabledFeatures.includes(Features.productLinePrivacy))
  }, {
    icon: 'icon-delete',
    label: 'Delete',
    onClick: () => this.deleteAudit(),
    hidden:  () => !this.canDelete()
  }];

  isVisitorMode$: Observable<boolean>;
  accountType: EAccountType;
  sharingEnabled: boolean;

  disableReportClose: boolean = false;
  private destroy$ = new Subject();
  enabledInfoItems: string[];

  auditRunStatus: IAuditRunStatus = {
    errors: null,
    snapshot: null,
    status: EAuditRunStatus.Basic,
    pillText: '',
    icon: '',
    issueDescription: '',
    reason: '',
    solution: '',
    btnText: '',
    filters: null,
    userIsReadOnly: false
  };

  EAuditRunStatus = EAuditRunStatus;

  readonly EAccountType = EAccountType;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private routeHistoryService: RouteHistoryService,
              private storageService: StorageService,
              private authService: AuthenticationService,
              private accountsService: AccountsService,
              private opModalService: OpModalService,
              private statusBannerService: StatusBannerService,
              private foldersService: IFoldersApiService,
              private domainsService: IDomainsService,
              private discoveryAuditService: DiscoveryAuditService,
              private auditDataService: IAuditDataService,
              private stopRunsService: IStopRunService,
              private reprocessService: IReprocessService,
              private reprocessConfirmationSnackbarService: ReprocessConfirmationSnackbarService,
              private modalEscapeService: ModalEscapeService,
              private switchAccountService: SwitchAccountService,
              private consentCategoriesService: ConsentCategoriesService,
              private keyboardShortcutsService: KeyboardShortcutsService,
              private alertReportingService: AlertReportingService,
              private terminateActiveRunsModalService: TerminateActiveRunsModalService,
              private eventManager: IEventManager,
              private auditReportChangesService: AuditReportChangesService,
              private rCardSvc: ReportCardService,
              private mCardSvc: ManageCardsService,
              private filterBarService: AuditReportFilterBarService,
              private applicationChromeService: ApplicationChromeService,
              private shareLinksService: ShareLinksService,
              private useCaseService: UseCaseService,
              private locationsService: LocationsService,
              private snackbar: SnackbarService,
              private auditReportLoadingService: AuditReportLoadingService,
              private cdr: ChangeDetectorRef,
    ) {
      this.opSelectorString = `audit-report-header-${this.router.url.split('/').pop()}`;
    }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((params: IAuditReportParams) => {
        this.setDefaultValues();
        this.setIds(params);
        this.storageService.setParamsForAudits(params);
        this.initListeners();
        this.determineLoadingPath();
      });
  }

  private determineLoadingPath(): void {
    this.auditDataService.getLastRun(this.auditId)
      .then((run) => {
        // if the audit doesn't have a run id (hasn't run yet) then
        // we'll go down this path to load the data for the header
        if (run === undefined || run.id === 0) {
          this.loadAccountDetails();
          this.loadDataForHeader();
          this.auditHasNotRunYet = true;
          this.loadAuditDetails();
          return;
        } else {
          this.auditHasNotRunYet = false;
          const firstLoad = !this.user;
          firstLoad ? this.loadData() : this.loadAuditDetails();

          this.alertReportingService.getAlertsForRun(this.auditId, this.runId, true);
          this.locationsService.locationsMapById$.pipe(takeUntil(this.destroy$)).subscribe(locationsById => {
            forkJoin([
              this.discoveryAuditService.getAuditRunInfo(this.auditId, this.runId),
              this.discoveryAuditService.getAuditRunFailures(this.auditId, this.runId),
              this.discoveryAuditService.getAuditRunActionSnapshots(this.auditId, this.runId)
            ]).subscribe(([runInfo, auditRunErrors, auditRunSnapshot]) => {
              this.enabledInfoItems = this.discoveryAuditService.getEnabledInfoItems(runInfo, locationsById);
              this.auditRunStatus.errors = auditRunErrors;
              this.auditRunStatus.snapshot = auditRunSnapshot;
              this.auditRunStatus = { ...this.auditRunStatus };
              this.auditRunStatus.userIsReadOnly = this.isReadOnly;
              this.setRunStatus();
            });
          });
        }
      });
  }

  private setIds(params: IAuditReportParams): void {
    this.auditId = +params.auditId;
    this.runId = +params.runId;
    this.requestBody.targetItem.itemId = this.auditId;
  }

  private initListeners(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.opSelectorString = `audit-report-header-${event.url.split('/').pop()}`;
        this.runCancelled = false;
      }
    });

    this.alertReportingService.accountHasAlerts$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((hasAlerts: boolean) => {
        this.accountHasAlerts = hasAlerts;
      });

    this.subscriptionToken = this.eventManager.subscribe(RUN_AUDIT_NOW, () => {
      this.runNow();
    });

    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
    this.isVisitorMode$.pipe(takeUntil(this.destroy$)).subscribe((isVisitorMode: boolean) => {
      this.disableReportClose = isVisitorMode;
    });

    this.applicationChromeService.accountPreview$
      .pipe(
        filter(accountPreview => !!accountPreview),
        takeUntil(this.destroy$)
      )
      .subscribe(({ sharingEnabled, accountType }) => {
        this.accountType = accountType;
        this.sharingEnabled = sharingEnabled;
      });
  }

  ngAfterViewInit(): void {
    this.keyboardShortcutsService.registerAuditInfoIcon(this.auditInfoTrigger.nativeElement);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.eventManager.unSubscribe(RUN_AUDIT_NOW, this.subscriptionToken);
  }

  private runNowDisabled(audit, currentUser): boolean   {
    return currentUser.maxPagesPerAudit > 0 && audit?.limit > currentUser?.maxPagesPerAudit;
  }

  private userPageLimitInsufficient(audit, currentUser): string {
    return currentUser.maxPagesPerAudit > 0 && audit?.limit > currentUser?.maxPagesPerAudit ? 'This audit is configured to scan more pages than you have permission to initiate.' : '';
  }

  private setRunStatus(): void {
    // stopped audit (warning icon / red background)
    if (
      this.auditRunStatus.errors.startingUrlResults?.failureType === EStartingUrlFailureType.AllFailedOneAndOut ||
      this.auditRunStatus.errors.preAuditActionResults.zeroAndOut
    ) {
      this.configureForStoppedAudit();
    } else if ( // issues audit (warning icon / orange background)
      this.auditRunStatus.errors.startingUrlResults?.failureType === EStartingUrlFailureType.NoLinksOneAndOut ||
      this.auditRunStatus.errors.hasOwnProperty('onPagesActionResults')
    ) {
      this.configureForIssuesAudit();
    } else if ( // advanced audit (shuffle icon / yellow background)
      !this.auditRunStatus.errors.preAuditActionResults.zeroAndOut &&
      !this.auditRunStatus.errors.hasOwnProperty('onPagesActionResults') &&
      !this.auditRunStatus.errors.hasOwnProperty('startingUrlResults') &&
      this.enabledInfoItems.length
    ) {
      this.configureForAdvancedAudit();
    } else if (!this.enabledInfoItems.length) { // basic audit (info icon / no background color)
      this.configureForBasicAudit();
    }
  }

  private configureForStoppedAudit(): void {
    this.auditRunStatus.status = EAuditRunStatus.Stopped;
    this.auditRunStatus.pillText = 'Run Stopped - ';
    this.auditRunStatus.icon = 'warning';
    this.auditRunStatus.btnText = 'View Failure Details';

    if (this.auditRunStatus.errors.preAuditActionResults?.zeroAndOut === true) {
      this.auditRunStatus.pillText += EAuditStatusPillText.PreAuditActionFailures;
      this.auditRunStatus.issueDescription = 'Audit was stopped because pre-audit actions failed.';
      this.auditRunStatus.reason = 'Pre-Audit actions failed to execute on all starting URLs.';
      this.auditRunStatus.solution = 'Review each failure and adjust audit settings as needed.';
    } else {
      this.auditRunStatus.pillText += EAuditStatusPillText.PagesCantBeReached;
      this.auditRunStatus.issueDescription = `The audit failed to scan the configured ${this.audit?.limit} page${this.audit?.limit > 1 ? 's' : ''}.`;
      this.auditRunStatus.reason = 'All starting URLs failed to load correctly.';
      this.auditRunStatus.solution = 'Ensure each starting URL loads correctly.';
    }
  }

  private configureForIssuesAudit(): void {
    this.auditRunStatus.status = EAuditRunStatus.Issues;
    this.auditRunStatus.pillText = 'Run Issues - ';
    this.auditRunStatus.icon = 'warning';

    if (this.auditRunStatus.errors.startingUrlResults?.failureType === EStartingUrlFailureType.NoLinksOneAndOut) {
      this.auditRunStatus.pillText += EAuditStatusPillText.PagesCantBeReached;
      this.auditRunStatus.issueDescription = `The audit failed to scan the configured ${this.audit?.limit} page${this.audit?.limit > 1 ? 's' : ''}.`;
      this.auditRunStatus.reason = 'All links found on the starting URLs have been excluded.';
      this.auditRunStatus.solution = 'Update starting URLs or modify the setup filters to match appropriate URLs.';
      this.auditRunStatus.btnText = 'View Exclusions';
    } else {
      this.auditRunStatus.pillText += EAuditStatusPillText.OnPageActionFailures;
      this.auditRunStatus.issueDescription = `On-page actions failed on ${this.auditRunStatus.errors.onPagesActionResults.totalFailedCount}
      of ${this.auditRunStatus.errors.onPagesActionResults.totalConfiguredCount} configured page${this.audit?.limit > 1 ? 's' : ''}.`;
      this.auditRunStatus.reason = 'Unable to execute one or more on-page actions.';
      this.auditRunStatus.solution = 'Review each failure on the page details and adjust audit settings as needed.';
      this.auditRunStatus.btnText = 'View all pages with failed actions';
    }
  }

  private configureForAdvancedAudit(): void {
    this.auditRunStatus.status = EAuditRunStatus.Advanced;
    this.auditRunStatus.pillText = 'Advanced Audit';
    this.auditRunStatus.icon = 'shuffle';
    this.auditRunStatus.issueDescription = 'All pages scanned in this audit run used settings that may affect collected data in tags, cookies, and other technologies. Report data reflects these changes.';
  }

  private configureForBasicAudit(): void {
    this.auditRunStatus.status = EAuditRunStatus.Basic;
  }

  private setDefaultValues() {
    this.runPickerVisible = this.auditRunning = this.scheduledToScan = this.loaded = this.noAudit = this.runCancelled = false;
  }

  private loadDataForHeader(): void {
    forkJoin([
      from(this.discoveryAuditService.getAudit(this.auditId)),
      from(this.foldersService.getFolders(false)),
      from(this.domainsService.getDomains()),
    ])
    .subscribe(([audit, folders, domains]: [IAuditModel, IFolder[], IDomain[]]) => {
      this.audit = audit;
      this.auditName = this.audit.name;
      this.auditRunning = audit.queued || audit.webAuditRunning;
      this.setFolderAndDomainData(folders, domains);
    });
  }

  runFinished(): void {
    this.auditRunning = false;
    this.runCancelled = false;
    this.cdr.detectChanges();
  }

  /** Menu Options **/
  runNow(): void {
    const doRunNow = () => {
      this.auditDataService.runNow(this.audit).then((response: any) => {
        if (
          response
          && response.errorCode !== ServerErrorCodes.overageLimitExceeded
          && response.code !== 400
        ) {
          this.scheduledToScan = true;
          this.audit.queued = true;
          this.auditRunning = true;
          this.statusBannerService.updateStatusBanner();
        }
      });
    };

    const confirm = (messages: string[]) => {
      this.opModalService.openConfirmModal({
        data: {
          messages,
          rightFooterButtons: [{
            label: 'Ok',
            action: doRunNow
          }, {
            label: 'Cancel',
            action: () => {}
          }]
        } as any
      });
    };

    this.auditDataService.getAuditRunsNonCached(this.audit?.id)
    .then((runs) => {
      const lastRun = runs && runs[0];
      if (lastRun && !lastRun.completed) {
        confirm(['This audit is already running.', 'Would you like to schedule another run?']);
      } else if (this.audit.queued) {
        confirm(['This audit is already queued.', 'Would you like to requeue it?']);
      } else {
        doRunNow();
      }
    })
    .catch(() => {
      // Couldn't get audit runs for some reason. Just try to run now anyway
      doRunNow();
    });
  }

  editAudit(): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    const data = {
      domainId: this.domainId,
      auditId: this.auditId,
      runId: this.runId
    };
    this.opModalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor')
      .afterClosed()
      .subscribe((options?: IAuditEditorCloseOptions) => {
        this.modalEscapeService.remove(index);
        if (options) {
          this.loadAuditDetails();
          this.router.navigateByUrl(this.router.url);
        } else {
          this.auditReportChangesService.checkForAlertChanges(this.auditId, this.runId);
        }
      });
  }

  deleteAudit(): void {
    const data = {
      deleteButtonAction: () => this.deleteAuditAccepted(),
      displayItem: {
        type: 'Audit',
        name: this.audit.name
      }
    };
    this.opModalService.openDeleteModal({ data });
  }

  private deleteAuditAccepted() {
    this.discoveryAuditService.removeAudit(this.audit.id).then(
      isDeleted => {
        if (!isDeleted) return;
        this.router.navigateByUrl(DataSourcesUrlBuilders.sources());
      },
      error => {
        if (error.errorCode === ServerErrorCodes.alreadyRunning) {
          this.terminateActiveRunsModalService.showTerminateActiveRunModal(ModalType.Audit);
        }
      }
    );
  }

  reprocessRules() {
    this.reprocessConfirmationSnackbarService.showReprocessRules(this.audit.rules, () => {
      this.auditDataService
        .reprocessRules(this.auditId, this.runId)
        .then(() => {
          this.reprocessService.reprocessComplete();
          this.reprocessService.subscribeOnAuditReprocessingRulesUpdates(this.auditId, this.runId, this.audit.name);
          this.reprocessService.updateAuditReprocessRulesBannerStatus(this.auditId, this.runId, ReprocessBannerStatus.inProgress);
        });
    });
  }

  reprocessConsentCategories(): void {
    this.consentCategoriesService.getConsentCategoriesAssignedToAudit(this.auditId).subscribe((response: IConsentCategories[]) => {
      const consentCategoryNames = response.map(cc => { return { name: cc.name } as IReprocessConfirmationItem; });
      const consentCategoryIds = response.map(cc => cc.id);
      this.reprocessConfirmationSnackbarService.showReprocessConsentCategories(consentCategoryNames, () => {
        this.consentCategoriesService
          .reprocessConsentCategories(this.auditId, this.runId, consentCategoryIds)
          .subscribe(
            () => {
              this.reprocessService.reprocessComplete();
              this.reprocessService.subscribeOnAuditReprocessingConsentCategoriesUpdates(this.auditId, this.runId, this.audit.name);
              this.reprocessService.updateAuditReprocessConsentCategoriesBannerStatus(this.auditId, this.runId, ReprocessBannerStatus.inProgress);
             },
            error => {
              if (error.code === 423) this.reprocessService.displayAuditConsentCategoriesReprocessInProgressToast();
            }
          );
      });
    });
  }

  reprocessComparisons(): void {
    const runId = this.runId;
    const auditId = this.audit.id;
    const data: IReprocessComparisonsModalData = { auditId, runId };
    this.opModalService.openModal(ReprocessComparisonsModalComponent, { data });
  }

  stopRun(): void {
    this.auditDataService.getAuditRunsNonCached(this.auditId).then(runs => {
      const runId = runs.length > 0 && !runs[0].completed ? runs[0].id : undefined;
      this.stopRunsService.stopRun(RunnableItem.Audit, this.auditId, runId, () => {
        this.scheduledToScan = false;
        this.audit.queued = false;
        this.auditRunning = false;
        this.runCancelled = true;
        this.statusBannerService.updateStatusBanner();
        this.statusBannerService.runStoppedSubject.next();
      });
    });
  }
  /** End Menu Options */

  inProgress(): boolean {
    return this.auditRunning;
  }

  canPause(): boolean {
    return this.rCardSvc.canPause(this.audit);
  }

  canResume(): boolean {
    return this.rCardSvc.canResume(this.audit);
  }

  //Used for icon display -- different logic than canPause
  showPauseIcon(): boolean {
    return this.rCardSvc.isPausedCard(this.audit);
  }

  pauseAudit(): void {
    this.mCardSvc.pauseResumeAudit(this.audit, this.mCardSvc.pauseDate);
    this.audit.nextRun = this.mCardSvc.pauseDate;
    this.nextRun = new Date(this.mCardSvc.pauseDate);
    this.showPauseIconFlag = true;
    this.statusBannerService.publishPauseStatus(true);
  }

  resumeAudit(): void {
    this.mCardSvc.pauseResumeAudit(this.audit, this.mCardSvc.resumeDate);
    this.audit.nextRun = this.mCardSvc.resumeDate;
    this.nextRun = new Date(this.mCardSvc.resumeDate);
    this.showPauseIconFlag = false;
    this.statusBannerService.publishPauseStatus(false);
  }

  isComparisonsEnabled(): boolean {
    return this.comparisonsEnabled;
  }

  canDelete(): boolean {
    return (this.user && this.audit && (userIsAdmin(this.user) || this.user.id === this.audit.ownerId));
  }

  canStopRun(): boolean {
    return !this.auditRunning;
  }

  /** Init logic **/
  private loadData(): void {
    this.loadAccountDetails();
    this.loadAuditDetails();
  }

  private loadAccountDetails(): void {
    forkJoin([
      this.accountsService.getUser(),
      this.authService.getFeaturesWithCache()
    ]).subscribe(([ user, features]) => {
      this.user = user;
      this.isReadOnly = userIsGuest(user);
      this.enabledFeatures = features;
      this.comparisonsEnabled = features.includes('comparisons');
    });
  }

  private loadAuditDetails(): void {
    forkJoin([
      this.foldersService.getFolders(false),
      this.domainsService.getDomains(),
      from(this.discoveryAuditService.getAudit(this.auditId) as Promise<IAuditCardModel>).pipe<IAuditCardModel>(catchError((e) => {
        // If Audit was not found - init the flow of switching to /data-sources
        if (e.code === 404) {
          return of(null);
        }

        // Handle error where *attemptSwitchAccounts* is called
        if (e.code === 403) {
          return throwError(e);
        }

        return e.code === 404 ? of(null) : throwError(e);
      })),
      from(this.auditDataService.getAuditRunsForRunPicker(this.auditId)).pipe<IRunPickerRun[]>(catchError(() => of(null))),
    ]).subscribe(([folders, domains, audit, runs]) => {
      if (!audit) {
        this.discoveryAuditService.openAuditDoesNotExistSnackbar();
        this.router.navigateByUrl('/data-sources');
        return;
      }

      const createdOutsideDataWarehouseWindow = !this.statusBannerService.createdInLast13Months(new Date(audit?.created));
      const curRun = runs?.find(r => r.id === this.runId);

      if (curRun) {
        this.currentRunDate = formatDate(curRun.date, EDateFormats.dateTwentyNine);
        curRun.selected = true;
      } else {
        // Run ID from route is 0, but since there are prior runs available, we should
        // redirect to the most recent run
        if (runs?.length > 0 && this.runId === 0 && createdOutsideDataWarehouseWindow) {
          this.discoveryAuditService.openRunDoesNotExistSnackbar();
        // Data retention issue - last run is not available
        } else if (runs?.length === 0 && audit.lastRun && createdOutsideDataWarehouseWindow) {
          this.discoveryAuditService.openRunDoesNotExistSnackbar();
        }

        const firstRunInProgress = runs.length <= 1 && !runs[0]?.completed;
        if (!firstRunInProgress) {
          this.auditDataService.getLastRun(this.auditId).then(mostRecentRun => {
            // store the current report name before we navigate away
            const reportName = this.router.routerState.snapshot.url.match(/report\/(.*)/)[1];

            // reset loading service to 0 since some are stuck
            this.auditReportLoadingService.forceOff();

            // navigate somewhere else and then back to the report to force a reload
            // method taken from: https://stackoverflow.com/a/49509706
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              const url = AuditReportContainerUrlBuilders.basicReport(this.auditId, mostRecentRun.id, reportName);
              this.router.navigateByUrl(url);
            });
          });
        }

        return;
      }

      this.audit = audit;
      this.auditRuns = runs;
      this.auditRunStatus.filters = audit.filters;
      this.auditName = this.audit.name;
      (this.audit as IAuditCardModel).type = CardTypes.audit;
      this.nextRun = this.audit.nextRun;
      this.showPauseIconFlag = this.showPauseIcon();

      // If loading a queued audit that has a previous run, we still load the previous run, but need to update the auditRunning
      // property to reflect the fact that the audit is queued and should display as 'Stop and Discard' instead of 'Run Now'
      this.auditRunning = this.scheduledToScan = audit.webAuditRunning !== null ?
        audit.webAuditRunning || audit.queued
        : runs.length > 0 && !runs[0]?.completed;

      this.setFolderAndDomainData(folders, domains);

      this.keyboardShortcutsService.registerEditMethod(() => this.editAudit());
      this.auditReportChangesService.checkForAlertChanges(this.auditId, this.runId);
      this.useCaseService.auditHeaderDoneLoading();
    }, (error) => {
        if (error.code === 403) {
          this.switchAccountService.attemptSwitchAccounts(this.auditId, EDataSelectorType.audits).subscribe(() => {
            this.useCaseService.auditHeaderDoneLoading();
          });
        }

        throw error;
    });
  }

  private setFolderAndDomainData(folders: IFolder[], domains: IDomain[]): void {
    if (this.audit) {
      this.folderName = folders.find(f => f.id === this.audit.folderId).name;
    }
    const domain = domains.find(d => d.id === this.audit.domainId);
    this.domainName = domain.name;
    this.domainId = domain.id;
  }

  onClickRun(run: IRunPickerRun): void {
    this.popover.closePopover();
    this.updateRun(run.id);
  }

  updateRun(id: number): void {
    const urlEnding = this.getReportUrlEnding();
    this.router.navigateByUrl(AuditReportContainerUrlBuilders.base(this.auditId, id) + '/' + urlEnding);
  }

  private getReportUrlEnding(): string {
    const urlTree = this.router.parseUrl(this.router.url);
    const segments = RouteDataService.getUrlSegments(urlTree);
    if (segments.includes('use-cases')) return 'report/' + segments.slice(-2).join('/');
    else if (segments.includes('page-details')) return 'report/page-details/' + segments.pop();
    else if (segments.includes('report')) return 'report/' + segments.pop();
    else if (segments.includes('comparisons')) return 'comparisons/'
      + segments.slice(segments.indexOf('comparisons') + 1).join('/');
    else return segments.pop();
  }

  disableWarningIconHighlight() {
    this.disableWarningHighlight = true;
  }

  @HostListener('document:keydown.escape')
  closeReport(closeButtonClicked: boolean = false): void {
    if (this.disableReportClose) {
      // closing the report is disabled
      return;
    }

    if (closeButtonClicked) {
      this.modalEscapeService.clear();
    } else if (this.modalEscapeService.getLast()) return;
    const {extras, route} = DataSourcesUrlBuilders.selected('audit', this.auditId);
    const shouldIgnore = (previousState: IHistoryFrame) => {
      const wasManageCards = previousState?.url.startsWith(DataSourcesUrlBuilders.sources());
      const wasReportPage = previousState?.stateName?.includes(AuditReportContainerStateNames.base);
      return wasManageCards || wasReportPage;
    };
    this.routeHistoryService.goToLastFromHistory(route, shouldIgnore, extras);
  }

  addAlert() {
    const data: IAlertQuickCreatePayload = {
      auditId: this.auditId,
      runId: this.runId,
      metricType: null,
      currentValue: null,
      filters: null,
      filterType: EAlertFilterType.V1
    };

    this.opModalService.openFixedSizeModal(AlertComponent, {
      disableClose: true,
      data: {
        type: EAlertModalType.CreateForAudit,
        ...data,
      }, autoFocus: false
    });
  }

  setBellIconStates(data: IBellIconStateData): void {
    this.bellStatus = data.status;
    this.alertsCount = data.alertsCount;
  }

  routeToAlertSummary(setFilters: boolean = false): void {
    if (setFilters) {
      this.filterBarService.addSubscribedAlertsFilter();
      this.filterBarService.addTriggeredAlertsFilter(true);
    }

    this.router.navigateByUrl(AuditReportUrlBuilders.alertSummary(this.auditId, this.runId));
  }

  shareReport() {
    const data: IShareReportModalPayload = {
      auditId: this.auditId,
      runId: this.runId,
      reportName: this.getReportUrlEnding(),
      accountType: this.accountType,
      auditName: this.auditName,
      runDate: this.currentRunDate,
      folderName: this.folderName,
      filters: this.filterBarService.currentFilters,
      link: null
    };

    this.getLink().subscribe(link => {
      data.link = link;
      const modal = this.opModalService.openModal(ShareReportModalComponent, { data });
      modal.afterClosed().subscribe(saved => {
        if (!saved) {
          this.shareLinksService.deleteSharedLink(link.id).subscribe();
        }
      });
    });
  }

  openFreeTrialAd() {
    const data = {
      type: EFreeTrialAdModalType.ALERT,
    };

    this.opModalService.openModal(FreeTrialAdModalComponent, { data });
  }

  private getLink() {
    const sharedItemDetails = {
      sharedItemType: ESharedItemDetailsSharedItemType.FolderItemRunReport,
      itemId: this.auditId,
      itemType: ESharedItemDetailsItemType.Audit,
      runId: this.runId,
      reportName: this.getReportUrlEnding(),
      filters: this.filterBarService.currentFilters
    };
    const reportUrlPathPart = ShareLinksPaths.base;
    const shareRequestParams: ISharingRequest = {
      sharedItemDetails,
      reportUrlPathPart
    };

    return this.shareLinksService.getSharedLink(shareRequestParams);
  }

  private closeInfoPopover(): void {
    this.auditInfoTrigger.nativeElement.click();
  }

  handleEditAuditConfiguration(): void {
    this.closeInfoPopover();
    this.editAudit();
  }

  handleViewPagesWithFailedActions(): void {
    this.closeInfoPopover();
    this.filterBarService.addPageErrorsFilter(EPageErrorType.OnPageActionError);
    if (!this.router.url.includes(AuditReportPaths.pageSummary)) {
      this.router.navigateByUrl(AuditReportUrlBuilders.pageSummary(this.auditId, this.runId));
    }
  }
}
