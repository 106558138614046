<div class="month-by-day-selector">
  <mat-form-field appearance="outline" class="no-overrides">
    <mat-label>DAYS OF THE MONTH</mat-label>
    <mat-chip-grid #chipGrid>
      @for (day of selectedDays; track day) {
        <mat-chip-row class="chip-color-primary" (removed)="remove(day)">
          {{ day }}
          <button matChipRemove [attr.aria-label]="'remove ' + day">
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
    <input
      name="currentDay"
      #dayInput
      [formControl]="dayCtrl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      @for (day of filteredDayOptions$ | async; track day) {
        <mat-option [value]="day">{{ day }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</div>