import JSConfetti from 'js-confetti';
import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { confettiColors } from '@app/components/audit/audit.constants';
import { StorageService } from '@app/components/shared/services/storage.service';
import { Names } from '@app/moonbeamConstants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'no-alerts-triggered',
  templateUrl: './no-alerts-triggered.component.html',
  styleUrls: ['./no-alerts-triggered.component.scss']
})
export class NoAlertsTriggeredComponent implements AfterViewInit, OnDestroy {

  @Input() canvas: HTMLCanvasElement;
  @Input() alertsCount: number;
  @Input() filterCount: number;

  confetti: JSConfetti;

  constructor(private storageService: StorageService) {}

  ngAfterViewInit(): void {
    if (this.storageService.getValue(Names.GlobalStateKeys.disableConfetti)) return;
    this.confetti = new JSConfetti({ canvas: this.canvas });
    setTimeout(() => this.launchConfetti(), 750);
  }

  ngOnDestroy(): void {
    this.confetti.clearCanvas();
  }

  private launchConfetti(): void {
    this.confetti.addConfetti({
      confettiColors,
      confettiRadius: 4,
      confettiNumber: 500
    });
  }

}
