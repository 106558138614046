import { FeedbackPortalUrlBuilders } from './../../feedback-portal/feedback-portal.constants';
import { ScriptServicesUrlBuilders } from './../../script-services/script-services.constants';
import { TagDatabaseUrlBuilders } from './../../tag-database/tag-database.constants';
import { BulkOperationsUrlBuilders } from './../../bulk-operations/bulk-operations.const';
import { AccountSettingsUrlBuilders } from './../../account-settings/account-settings.const';
import { AdminPortalUrlBuilders } from './../../admin-portal/admin-portal.constants';
import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { IUser } from '@app/moonbeamModels';
import { userIsAdmin, userIsOPAdmin, userIsOPSysAdmin } from '@app/authUtils';
import { startsWith } from '@app/moonbeamConstants';
import { Router, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { AccountsService } from '@app/components/account/account.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  user: IUser;
  userGreeting: string;
  userIsAdmin: boolean;
  userIsOpAdmin: boolean;
  isInAdminPortal: boolean;

  @Input() loggedInAsAnother: boolean;
  @Output() sidebarToggle: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private authenticationService: AuthenticationService,
    private accountsService: AccountsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.determineCurrentState();

    this.accountsService.getUser().subscribe((user: IUser) => {
      this.user = user;
      this.userIsAdmin = userIsAdmin(this.user);
      this.userIsOpAdmin = userIsOPAdmin(this.user) || userIsOPSysAdmin(this.user);
      this.setUserGreeting();
    });


    /**
     * this is necessary to ensure that the search component and 
     * some of the icons in the top bar component are shown and
     * hidden as users go between the admin portal and the app
     */

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.determineCurrentState();
      });
  }

  determineCurrentState(): void {
    const route = this.router.url;
    this.isInAdminPortal = startsWith(route, AdminPortalUrlBuilders.base());
  }

  setUserGreeting(): void {
    this.userGreeting = this.user.firstName
    ? this.user.firstName
    : this.user.username || 'My Profile';
  }

  goToManageSection(): void {
    this.router.navigateByUrl(DataSourcesUrlBuilders.sources());
  }

  goToAdminPortal(): void {
    this.router.navigateByUrl(AdminPortalUrlBuilders.base());
  }

  
  // menu 1 (question mark icon)
  
  openHelpDocumentation(): void {
    window.open('https://observepoint.helpscoutdocs.com/');
  }

  openAcademyTraining(): void {
    window.open('https://academy.observepoint.com/');
  }

  goToFeedbackPortal(): void {
    this.router.navigateByUrl(FeedbackPortalUrlBuilders.base());
  }


  // menu 2 (rubiks cube icon)

  goToAccountUsers(): void {
    this.router.navigateByUrl(AccountSettingsUrlBuilders.manageUsers());
  }

  goToTagSetup(): void {
    this.router.navigateByUrl(AccountSettingsUrlBuilders.manageTags());
  }

  goToBulkOperations(): void {
    this.router.navigateByUrl(BulkOperationsUrlBuilders.manageFolders());
  }

  goToEventLog(): void {
    this.router.navigateByUrl(AccountSettingsUrlBuilders.userEvents());
  }

  goToTagDatabase(): void {
    this.router.navigateByUrl(TagDatabaseUrlBuilders.base());
  }

  goToScriptServices(): void {
    this.router.navigateByUrl(ScriptServicesUrlBuilders.base());
  }


  // menu 3 (user icon)

  goToUserProfile(): void {
    this.router.navigateByUrl(AccountSettingsUrlBuilders.user());
  }

  logout(): void {
    this.authenticationService.logout();
  }
}
