<split-card [topLabel]="widgetPagesUnapprovedTags.topLabel"
            [topHandler]="widgetPagesUnapprovedTags.topHandler"
            [state]="loadingState"
            [topTooltip]="widgetPagesUnapprovedTags.tooltip">
  <div class="widget-inside-wrap">
    <div class="counts">
              <span class="count"
                    [class.red]="pagesWithUnapprovedTagsCount"
              >{{ pagesWithUnapprovedTagsCount | number }}</span>
    </div>
    <filter-spinner [state]="loadingState"></filter-spinner>
  </div>
</split-card>
<split-card [topLabel]="widgetUnapprovedTags.topLabel"
            [topTooltip]="widgetUnapprovedTags.tooltip"
            [topHandler]="widgetUnapprovedTags.topHandler"
            [state]="loadingState"
            [topChangeContent]="widgetUnapprovedTags.topChangeContent"
            [bottomHandler]="widgetUnapprovedTags.bottomHandler"
            [topChangeMeaning]="widgetUnapprovedTags.topChangeMeaning"
            [haveEnoughData]="widgetUnapprovedTagsData?.length > 1"
>
  <sparkline-chart-insight meaningOfUp="negative"
                           meaningOfNoChange="none"
                           label="since last run"
                           [dataLoaded]="sparklineDataLoaded"
                           [dataPoints]="widgetUnapprovedTagsData">
  </sparkline-chart-insight>
</split-card>
