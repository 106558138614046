<div class="page-details-tags-wrapper">
  <!--Search / Chip / Header buttons-->
  <div class="spinner-container" *ngIf="loading">
    <mat-spinner [diameter]="25" [strokeWidth]="4"></mat-spinner>
  </div>

  <div class="page-detail-tag-filters" [class.hidden]="loading" [ngClass]="{ shadowed: isScrolled }">
    <op-clearable-input [placeholderText]="searchPlaceholder"
                        [defaultValue]="this.filterString"
                        (onUserInput)="debounceFilterData($event)"
                        class="clearable-input">
    </op-clearable-input>

    <div class="page-detail-tag-button-wrapper">
      <share-report-btn *ngIf="reportType === 'audit'"
                        [auditId]="itemId"
                        [runId]="runId"
                        [pageId]="pageId"
                        [activeTab]="activeTab">
      </share-report-btn>
      <mat-button-toggle-group
        class="tag-view-toggle"
        [hideSingleSelectionIndicator]="true"
        (change)="viewSelected($event.value)"
      >
        <mat-button-toggle [value]="ETagView.tagView"
                           [checked]="currentView === ETagView.tagView"
                           matTooltip="Group Tag Requests"
                           matTooltipPosition="above">
          <mat-icon>view_week</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle [value]="ETagView.requestView"
                           [checked]="currentView === ETagView.requestView"
                           matTooltip="Separate Tag Requests"
                           matTooltipPosition="above">
          <mat-icon class="rotated-menu">view_week</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="page-detail-summary-wrapper" *ngIf="!loading">
    <div class="page-detail-summary-box">
      <div class="detail-summary-label">Total Tag Requests</div>
      <div class="detail-summary-value">{{tagInsights?.noOfTagRequests || '---'}}</div>
    </div>
    <div class="page-detail-summary-box">
      <div class="detail-summary-label"># of Unique Tags</div>
      <div class="detail-summary-value">{{tagInsights?.noOfUniqueTags || '---'}}</div>
    </div>
    <div class="page-detail-summary-box">
      <div class="detail-summary-label"># of Broken Tags</div>
      <div class="detail-summary-value" [ngClass]="{'value-warning': tagInsights?.noOfBrokenTags > 0}">{{tagInsights?.noOfBrokenTags || '---'}}</div>
    </div>
  </div>

  <div class="page-details-tags-table-container" *ngIf="!loading">
    <div class="page-details-tags-header-names">
      <div class="page-details-tag-name page-details-tags-heading"
           [ngClass]="{'tags-sorting-by-name': sortingBy === ETagColumnNames.tagName}"
           (click)="sortTagData(ETagColumnNames.tagName)">
        <div class="page-details-tag-name-text">
          Tag Name
        </div>
        <div class="page-details-tag-name-sort">
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'ascending'"
                    [ngClass]="{'tags-sort-asc': sortDirection === 'ascending'}">north</mat-icon>
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'descending'"
                    [ngClass]="{'tags-sort-desc': sortDirection === 'descending'}">south</mat-icon>
        </div>
      </div>
      <div class="page-details-tag-related-cookies page-details-tags-heading"
           [ngClass]="{'tags-sorting-by-related-cookies': sortingBy === ETagColumnNames.relatedCookies}"
           (click)="sortTagData(ETagColumnNames.relatedCookies)">
        <div class="page-details-tag-name-text">
          Related Cookie(s)
        </div>
        <div class="page-details-tag-name-sort">
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'ascending'"
                    [ngClass]="{'tags-sort-asc': sortDirection === 'ascending'}">north</mat-icon>
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'descending'"
                    [ngClass]="{'tags-sort-desc': sortDirection === 'descending'}">south</mat-icon>
        </div>
      </div>
      <div class="page-details-tag-category page-details-tags-heading"
           [ngClass]="{'tags-sorting-by-category': sortingBy === ETagColumnNames.tagCategory}"
           (click)="sortTagData(ETagColumnNames.tagCategory)">
        <div class="page-details-tag-name-text">
          Category
        </div>
        <div class="page-details-tag-name-sort">
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'ascending'"
                    [ngClass]="{'tags-sort-asc': sortDirection === 'ascending'}">north</mat-icon>
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'descending'"
                    [ngClass]="{'tags-sort-desc': sortDirection === 'descending'}">south</mat-icon>
        </div>
      </div>
      <div class="page-details-tag-status-codes page-details-tags-heading"
           [ngClass]="{'tags-sorting-by-status-code': sortingBy === ETagColumnNames.tagStatusCode}"
           (click)="sortTagData(ETagColumnNames.tagStatusCode)">
        <div class="page-details-tag-name-text">
          Tag Status Code{{currentView === ETagView.tagView ? 's' : ''}}
        </div>
        <div class="page-details-tag-name-sort">
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'ascending'"
                    [ngClass]="{'tags-sort-asc': sortDirection === 'ascending'}">north</mat-icon>
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'descending'"
                    [ngClass]="{'tags-sort-desc': sortDirection === 'descending'}">south</mat-icon>
        </div>
      </div>
      <div class="page-details-tag-requests-count page-details-tags-heading"
           *ngIf="currentView === ETagView.tagView"
           [ngClass]="{'tags-sorting-by-requests': sortingBy === ETagColumnNames.tagRequests}"
           (click)="sortTagData(ETagColumnNames.tagRequests)">
        <div class="page-details-tag-name-text">
          Requests
        </div>
        <div class="page-details-tag-name-sort">
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'ascending'"
                    [ngClass]="{'tags-sort-asc': sortDirection === 'ascending'}">north</mat-icon>
          <mat-icon class="tag-sort-header-arrow"
                    *ngIf="sortDirection === 'descending'"
                    [ngClass]="{'tags-sort-desc': sortDirection === 'descending'}">south</mat-icon>
        </div>
      </div>
    </div>

  <!-- Request View start-->
    <div *ngIf="currentView === ETagView.requestView">
        <mat-expansion-panel #panel *ngFor="let instance of filteredInstanceData" [hideToggle]="true">
          <mat-expansion-panel-header>
            <div class="page-details-tags-header-container">
              <div class="page-details-tag-name">
                <div class="arrow">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
                <img [src]="getTagIconUrl(instance?.tagId)" class="tag" alt="tag"/>
                <div class="page-details-tag-name-wrapper">
                  <div class="page-details-tag-name-wrapper-stack">
                    <div class="page-details-tag-name-data">
                      <span>{{ instance.tagName }}</span>
                    </div>
                    <div class="page-details-tag-account-data">Account ID: {{ instance.account }}</div>
                  </div>
                  <div class="page-details-duplicate-tag-count">
                    <div *ngIf="instance.originalDuplicates > 0"
                         class="duplicate-highlight-round"
                         matTooltip="{{ instance.originalDuplicates }} duplicate requests"
                         matTooltipPosition="above">
                      <div class="duplicate-highlight-round-number">{{ instance.originalDuplicates }}</div>
                    </div>
                  </div>
                  <span class="create-rule-button" *ngIf="!userIsReadOnly">
                    <mat-icon class="rule-button"
                              (click)="createRule($event, instance)"
                              matTooltip="Create Rule"
                              matTooltipPosition="above">
                      add_circle_outline
                    </mat-icon>
                  </span>
                </div>
              </div>

              <div class="page-details-tag-related-cookies">
                <div class="related-cookies-value-wrapper"
                     *ngIf="instance.relatedCookies?.length"
                     mdePopoverTriggerOn="click"
                     [mdePopoverTriggerFor]="relatedCookiesPopover"
                     (click)="$event.stopPropagation()">
                  {{ instance.relatedCookies?.length || '---' }} <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
                <div class="related-cookies-value-wrapper"
                     *ngIf="!instance.relatedCookies?.length">
                  ---
                </div>
                <mde-popover #relatedCookiesPopover="mdePopover"
                             class="page-details-tags-popover"
                             [mdePopoverOverlapTrigger]="false"
                             [mdePopoverCloseOnClick]="false">
                  <div class="related-cookies-popover-wrapper">
                    <div *ngFor="let cookie of instance.relatedCookies" class="related-cookie" (click)="navToCookiesTab(cookie)">
                      <span matTooltip="View details on Cookies tab" matTooltipPosition="above">
                        {{ cookie.name }} ({{ cookie.domain }})
                      </span>
                      <mat-icon class="arrow-right">east</mat-icon>
                    </div>
                  </div>
                </mde-popover>
              </div>
              <div class="page-details-tag-category">
                {{ instance.category }}
              </div>
              <div class="page-details-tag-status-codes">
                <div class="page-details-tag-status-wrapper"
                     *ngIf="instance.statusCode < 200 || instance.statusCode > 600">
                  ---
                </div>
                <div class="page-details-tag-status-wrapper"
                     [matTooltip]="instance.statusCodeTooltip"
                     matTooltipPosition="above"
                     matTooltipShowDelay="300"
                     *ngIf="instance.statusCode >= 200 && instance.statusCode <= 599"
                     [ngClass]="{'status-pass': instance.statusCode >= 200 && instance.statusCode < 300, 'status-warning': instance.statusCode >= 300 && instance.statusCode < 400, 'status-fail': instance.statusCode >= 400}">{{ instance.statusCode }}</div>
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="request-view-expansion-body" *ngIf="panel.expanded">
            <page-details-tags-expansion-content [view]="currentView"
                                                 [data]="instance"
                                                 [originTag]="getOriginTagByInstanceId(instance.tagInstanceId)"
                                                 [filterString]="filterString"
                                                 class="tag-view">
            </page-details-tags-expansion-content>
          </div>
        </mat-expansion-panel>
    </div>
    <!-- Request View end -->
    <!-- Tag View start -->
    <div *ngIf="currentView === ETagView.tagView">
      <div *ngFor="let tag of filteredTagsData">
        <mat-expansion-panel #tagViewExpansionPanel [hideToggle]="true">
          <mat-expansion-panel-header>
            <div class="page-details-tags-header-container">
              <div class="page-details-tag-name">
                <div class="arrow">
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
                <img [src]="getTagIconUrl(tag?.tagId)" class="tag" alt="tag"/>
                <div class="page-details-tag-name-wrapper">
                  <div class="page-details-tag-name-wrapper-stack">
                    <div class="page-details-tag-name-data">{{ tag?.name }}</div>
                    <div class="page-details-tag-account-data">Accounts: {{ tag?.accountList?.length > 0 ? tag?.accountList?.length : 'No account' }}</div>
                  </div>
                </div>
              </div>
              <div class="page-details-tag-related-cookies">
                <div class="related-cookies-value-wrapper"
                     *ngIf="tag.relatedCookies?.length"
                     mdePopoverTriggerOn="click"
                     [mdePopoverTriggerFor]="relatedCookiesPopover"
                     (click)="$event.stopPropagation()">
                  {{ tag.relatedCookies?.length || '---' }} <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
                <div class="related-cookies-value-wrapper"
                     *ngIf="!tag.relatedCookies?.length">
                  ---
                </div>
                <mde-popover #relatedCookiesPopover="mdePopover"
                             class="page-details-tags-popover"
                             [mdePopoverOverlapTrigger]="false"
                             [mdePopoverCloseOnClick]="false">
                  <div class="related-cookies-popover-wrapper">
                    <div *ngFor="let cookie of tag.relatedCookies" class="related-cookie" (click)="navToCookiesTab(cookie)">
                      <span matTooltip="View details on Cookies tab" matTooltipPosition="above">
                        {{ cookie.name }} ({{ cookie.domain }})
                      </span>
                      <mat-icon class="arrow-right">east</mat-icon>
                    </div>
                  </div>
                </mde-popover>
              </div>
              <div class="page-details-tag-category">
                {{ tag.category }}
              </div>
              <div class="page-details-tag-status-codes">
                <div class="page-details-tag-status-wrapper">
                  <status-code-indicators [payload]="makeStatusCodeIndicatorPayload(tag)" (click)="$event.stopPropagation()"></status-code-indicators>
                </div>
              </div>
              <div class="page-details-tag-requests-count"
                   *ngIf="currentView === ETagView.tagView">
                <div class="page-details-tag-requests-count"
                     [class.duplicate-highlight]="tag.tagDuplicateRequestCount > 0"
                     [class.multiple-highlight]="tag.tagDuplicateRequestCount === 0 && tag.tagMultipleRequestCount > 0"
                     (click)="$event.stopPropagation();"
                     mdePopoverTriggerOn="click"
                     [mdePopoverTriggerFor]="requestTypePopover">
                  {{ tag.tagRequestCount }}
                  <div class="page-details-tags-popover-arrow">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </div>
                </div>
                <mde-popover #requestTypePopover="mdePopover"
                             class="page-details-tags-popover"
                             [mdePopoverOverlapTrigger]="false"
                             [mdePopoverCloseOnClick]="true">
                  <div class="tags-summary-popover-wrapper" (click)="tagViewExpansionPanel.expanded = true">
                    <div class="request-type-duplicate tags-count">
                      {{ tag.tagDuplicateRequestCount }} Duplicate{{ tag.tagDuplicateRequestCount === 1 ? '':'s' }}
                    </div>
                    <div class="request-type-multiple tags-count">
                      {{ tag.tagMultipleRequestCount }} Multiple{{ tag.tagMultipleRequestCount === 1 ? '':'s' }}
                    </div>
                    <div class="request-type-unique tags-count">
                      {{ tag.tagUniqueRequestCount }} Unique{{ tag.tagUniqueRequestCount === 1 ? '':'s' }}
                    </div>
                  </div>
                </mde-popover>
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="tag-view-expansion-body" *ngIf="tagViewExpansionPanel.expanded">
            <div class="tag-expansion-sticky-column">
              <div class="page-details-tag-expansion-cell cell-border-top page-details-tag-expansion-header page-details-tags-sticky-cell">
                <div class="tag-cell-value">Observepoint Data</div>
              </div>
              <div class="page-details-tag-expansion-cell" *ngIf="tag?.requestViewFilteredOPHeaders?.showAccount">
                <div class="tag-cell-value">{{ETagsExpansionOPData.ACCOUNT}}</div>
              </div>
              <div class="page-details-tag-expansion-cell" *ngIf="tag?.requestViewFilteredOPHeaders?.showStatusCode">
                <div class="tag-cell-value">{{ETagsExpansionOPData.STATUS_CODE}}</div>
              </div>
              <div class="page-details-tag-expansion-cell" *ngIf="tag?.requestViewFilteredOPHeaders?.showTagSize">
                <div class="tag-cell-value">{{ETagsExpansionOPData.TAG_SIZE}}</div>
              </div>
              <div class="page-details-tag-expansion-cell" *ngIf="tag?.requestViewFilteredOPHeaders?.showLoadTime">
                <div class="tag-cell-value">{{ETagsExpansionOPData.LOAD_TIME}}</div>
              </div>
              <div class="page-details-tag-expansion-cell" *ngIf="tag?.requestViewFilteredOPHeaders?.showDuplicateRequest">
                <div class="tag-cell-value">{{ETagsExpansionOPData.DUPLICATES}}</div>
              </div>
              <div class="page-details-tag-expansion-cell" *ngIf="tag?.requestViewFilteredOPHeaders?.showMultipleRequest">
                <div class="tag-cell-value">{{ETagsExpansionOPData.MULTIPLES}}</div>
              </div>
              <div class="page-details-tag-expansion-cell page-details-tag-expansion-header"><div class="tag-cell-value">Variable Data</div></div>
              <!--  Variable Data section  -->
              <div class="page-details-tag-expansion-cell cell-border-top" *ngFor="let variable of tag.variableList">
                <div class="tag-cell-value"
                     [matTooltip]="variable"
                     matTooltipPosition="above"
                     matTooltipClass="tooltip-wide"
                >{{variable}}</div>
              </div>
            </div>
            <page-details-tags-expansion-content *ngFor="let instance of tag.pageTagInstances"
                                                 [variableList]="tag.variableList"
                                                 [view]="ETagView.tagView"
                                                 [filterString]="filterString"
                                                 [opDataRowsForTag]="tag.requestViewFilteredOPHeaders"
                                                 [data]="instance"
                                                 [originTag]="getOriginTagByInstanceId(instance.tagInstanceId)"
                                                 [itemId]="itemId">
            </page-details-tags-expansion-content>

          </div>
        </mat-expansion-panel>
      </div>
    </div>
    <!-- Tag View end -->
  </div>
</div>


