import { datasourceAllTagsOnly } from './../../shared/comparison-variable-selector/comparison-variable-selector.constants';
import { IComparisonVariableSelectorForm } from './../../shared/comparison-variable-selector/comparison-variable-selector.models';
import { ComponentChanges } from './../../../../models/commons';
import { IRorSetupForm } from './ror-setup-form.models';
import { Component, OnInit, forwardRef, Input, OnDestroy, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormBuilder, Validators, UntypedFormGroup, ControlValueAccessor, AbstractControl, ValidationErrors, Validator, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { IComparisonTag } from '../../comparisons.models';
import { ComparisonVariableSelectorService } from '../../shared/comparison-variable-selector/comparison-variable-selector.service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

const FORM_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RorSetupFormComponent),
  multi: true
};

const FORM_CONTROL_VALIDATION = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RorSetupFormComponent),
  multi: true
};

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ror-setup-form',
  templateUrl: './ror-setup-form.component.html',
  styleUrls: ['./ror-setup-form.component.scss'],
  providers: [
    FORM_CONTROL_VALUE_ACCESSOR,
    FORM_CONTROL_VALIDATION
  ]
})
export class RorSetupFormComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator, OnChanges {

  @Input() submitted: boolean;
  @Input() allTags: IComparisonTag[]; // possible tags with vars 

  onChange: (changes: IComparisonVariableSelectorForm) => void;

  setupForm: UntypedFormGroup;
  destroy$ = new Subject<void>();
  tagData: any[];
  allLabels: ILabel[];

  tagToAllVariablesMap: Map<number, string[]> = new Map();
  tagToExcludedVariablesMap: Map<number, string[]> = new Map();

  isTagPresenceOnly = false;

  constructor(private fb: UntypedFormBuilder,
              private comparisonVariableSelectorService: ComparisonVariableSelectorService,
              private labelService: LabelService) {
  }

  ngOnInit() {
    this.initForm();
    this.loadData();
    this.onAllTagsChange();
  }

  ngOnChanges(changes: ComponentChanges<RorSetupFormComponent>) {
    if (changes.allTags && changes.allTags.currentValue !== changes.allTags.previousValue) {
      this.onAllTagsChange();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private initForm(): void {
    this.setupForm = this.fb.group({
      name: ['', Validators.required],
      labels: [],
      tags: this.fb.array([])
    });

    this.addItem();

    this.setupForm.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(100)
    ).subscribe(changes => { 
      this.onChange && this.onChange(changes);
    });
  }

  private loadData(): void {
    this.labelService.getLabels().subscribe((labels: ILabel[]) => {
      this.allLabels = labels;
    });
  }

  private onAllTagsChange(): void {
    this.allTags.forEach(t => {
      const allVars = [...t.variables].sort();
      this.tagToAllVariablesMap.set(t.id, Array.from(new Set(allVars)));
      this.tagToExcludedVariablesMap.set(t.id, [...t.excludedVariables]);
    });

    this.updateDragList();
  }

  writeValue(changes: IRorSetupForm): void {
    this.setupForm.patchValue({
      name: changes.name,
      labels: changes.labels
    });

    if (changes.tags) {
      this.tags.clear();

      changes.tags.forEach(tag => {
        this.tags.push(
          this.comparisonVariableSelectorService.createTagGroup(this.destroy$, tag)
        );
      });

      this.isTagPresenceOnly = !!changes.tags.find(tag => tag.tagId === datasourceAllTagsOnly.key);

      this.updateDragList();
    }
  }

  addItem(source?: IComparisonVariableSelectorForm, index?: number): void {
    const tagGroup = this.comparisonVariableSelectorService.createTagGroup(this.destroy$, source);
    if (index) this.tags.insert(index, tagGroup);
    else this.tags.push(tagGroup);

    this.updateDragList();
  }

  private updateDragList() {
    if (!this.setupForm) return;

    this.tagData = this.setupForm.value.tags.map((data) => {
      const tag = this.allTags.find(t => t.id === data.tagId);
      return {
        ...data,
        comparisonEnabled: tag ? tag.comparisonEnabled : undefined
      };
    });
  }

  addTag(index: number) {
    this.addItem(null, index);
  }

  deleteTag(index: number): void {
    const tag = this.tags.at(index).value as IComparisonVariableSelectorForm;
    if (tag.tagId === datasourceAllTagsOnly.key) this.isTagPresenceOnly = false;

    this.tags.removeAt(index);
    this.updateDragList();
  }

  createLabel(labelName: string) {
    this.labelService.createLabel(labelName).subscribe(label => {
      this.allLabels.push(label);
      
      const oldLabelsList = this.labels.value;
      this.labels.patchValue([...oldLabelsList, label]);
    });
  }

  get name(): UntypedFormControl {
    return this.setupForm.get('name') as UntypedFormControl;
  }

  get labels(): UntypedFormControl {
    return this.setupForm.get('labels') as UntypedFormControl;
  }

  get tags(): UntypedFormArray {
    return this.setupForm.get('tags') as UntypedFormArray;
  }

  getTagId(index: number): number {
    const tagGroup = this.tags.at(index) as UntypedFormGroup;
    return tagGroup.get('tagId').value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {}

  validate(c: AbstractControl): ValidationErrors {
    return this.setupForm.valid ? 
      null : 
      { setupForm: { valid: false, message: 'Invalid run over run comparison setup form' } };
  }

}
