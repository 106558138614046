import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EAlertBellStatus } from '@app/components/alert/alert-bell/alert-bell.enums';
import {
  AlertChangeSuccessSnackbarComponent,
  EAlertSuccessType
} from '@app/components/alert/alert-change-success-snackbar/alert-change-success-snackbar.component';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { AlertComponent } from '@app/components/alert/alert.component';
import { EAlertAssignmentOperation, EAlertSearchSortBy } from '@app/components/alert/alert.constants';
import {
  EAlertFilterType,
  EAlertMenuContext,
  EAlertModalType,
  EAlertResultStatus,
  EAlertStep
} from '@app/components/alert/alert.enums';
import {
  IAlertEditModalPayload,
  IAlertReadOnlyModalPayload,
  IAlertsResponseItemConfig,
  IAlertsSearchAssignmentBody,
  IAlertsSearchAssignmentsResponse,
  IAlertSummaryAlert,
  IAlertSummaryAlertHistory,
  IAlertSummaryRequestBody,
  IAlertSummaryResponse,
  ISpecificAlertSummaryDTO
} from '@app/components/alert/alert.models';
import { AlertService } from '@app/components/alert/alert.service';
import {
  IAuditReportExportMenuData
} from '@app/components/shared/components/audit-report-export/audit-report-export-menu/audit-report-export-menu.component';
import { ICommonTableState } from '@app/components/shared/components/export-report/export-reports.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { IUser } from '@app/moonbeamModels';
import { BehaviorSubject, forkJoin, merge, of, ReplaySubject, Subject } from 'rxjs';
import { catchError, finalize, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EAuditReportFilterTypes } from '../../audit-report-filter-bar/audit-report-filter-bar.models';
import { AuditReportFilterBarService } from '../../audit-report-filter-bar/audit-report-filter-bar.service';
import { AuditReportLoadingService } from '../../audit-report-loading.service';
import { AuditReportScrollService } from '../../audit-report-scroll.service';
import { AuditReportBase } from '../general-reports.models';
import { ALERT_SUMMARY_EXPORT_TYPE, EAlertSummaryTableColumn } from './alert-summary.constants';
import { IAlertSummaryRouteState, IAlertSummaryTableRow } from './alert-summary.models';
import {
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { EProductType } from '@app/components/shared/components/op-standards-selector/op-standards-selector.constants';
import { AccountsService } from '@app/components/account/account.service';
import { IRunPickerRun } from '@app/components/run-picker-ng/run-picker-ng.models';
import JSConfetti from 'js-confetti';
import { confettiColors } from '@app/components/audit/audit.constants';
import { IAuditSummaryAlert } from '@app/components/audit/audit.models';
import {
  EAlertMetricChangeType,
  EAlertMetricValueComparator,
  EAlertPageSummaryMetric
} from '@app/components/alert/alert-logic/alert-logic.enums';
import {
  SummaryHistoryPreviewModalComponent
} from './summary-history-preview-modal/summary-history-preview-modal.component';
import { Location } from '@angular/common';
import {
  AlertMetricChangeOperators,
  AlertMetricThresholdOperators
} from '@app/components/alert/alert-logic/alert-logic.constants';
import { ISummaryHistoryPreviewModalPayload } from './summary-history-preview-modal/summary-history-preview-modal.models';
import { AuditReportChangesService, IAlertChangeState } from '../../audit-report-changes.service';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import {
  FreeTrialAdModalComponent
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import {
  EFreeTrialAdModalType
} from '@app/components/audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import { StorageService } from '@app/components/shared/services/storage.service';
import { Names } from '@app/moonbeamConstants';
import { AlertUtils } from '@app/components/alert/alert.utils';

interface IFiltersUpdate {
  filters: IAlertSummaryRequestBody
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-summary',
  templateUrl: './alert-summary.component.html',
  styleUrls: ['./alert-summary.component.scss']
})
export class AlertSummaryComponent extends AuditReportBase implements AfterViewInit, OnDestroy, OnInit {
  EAlertStep = EAlertStep;
  auditId: number;
  runId: number;
  private filtersUpdated$ = new Subject<IFiltersUpdate>();
  private alertSummaryApiFilters: IAlertSummaryRequestBody = {};
  displayedColumns: EAlertSummaryTableColumn[] = [
    EAlertSummaryTableColumn.alertName,
    EAlertSummaryTableColumn.alertRuleLogic,
    EAlertSummaryTableColumn.statusValue,
    EAlertSummaryTableColumn.alertHistory,
    EAlertSummaryTableColumn.subscribed,
    EAlertSummaryTableColumn.edit
  ];
  dataSource = new MatTableDataSource<IAlertSummaryTableRow>();
  tableState: EFilterSpinnerState;
  pagesFilteredCount: number = null;
  private alertsTablePaginationState: ICommonTableState = {
    page: 0,
    size: 200,
    sortBy: EAlertSummaryTableColumn.alertName,
    sortDesc: false
  };
  exportAlertsConfig: IAuditReportExportMenuData = {
    tableName: 'Alert Summary',
    exportType: ALERT_SUMMARY_EXPORT_TYPE,
    totalRows: 0,
    filteredRows: Infinity,
    tableState: this.alertsTablePaginationState,
    filters: this.alertSummaryApiFilters,
    dataToCopy: {
      config: [
        {
          property: 'name',
          title: 'ALERT NAME'
        },
        {
          property: 'targetValueComparator',
          title: 'ALERT RULE LOGIC',
          displayLike: (alert) => this.getAlertLogicExport(alert)
        },
        {
          property: 'filtersV0',
          title: 'APPLIED FILTERS',
          displayLike: (alert) => this.getFilterExport(alert)
        },
        {
          property: 'actualValue',
          title: 'ACTUAL VALUE',
          displayLike: (alert) => this.getActualValueExport(alert)
        },
        {
          property: 'status',
          title: 'STATE',
          displayLike: (alert) => this.getStatusExport(alert)
        },
        {
          property: '',
          title: 'RUN DATE',
          displayLike: (alert) => this.getRunDateExport(alert)
        },
      ],
      data: null
    }
  };
  EFilterSpinnerState = EFilterSpinnerState;
  EAlertSummaryTableColumn = EAlertSummaryTableColumn;
  totalNumPages: number | string;
  pageSize = 200;
  widgetState: EFilterSpinnerState;
  bars = [];

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  onDestroy$ = new Subject<void>();

  loading = true;
  viewInitialized = false;
  currentRun: IRunPickerRun;
  firedConfetti: boolean = false;
  isVisitorMode: boolean;

  isReadOnly = false;
  alertSupportedFilters = [
    EAuditReportFilterTypes.AlertName,
    EAuditReportFilterTypes.AlertReportMetric,
    EAuditReportFilterTypes.AlertStatus,
    EAuditReportFilterTypes.AlertSubscribed,
  ];
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];

  assignedAlertsParams = {
    size: 1000,
    page: 0,
    sortBy: EAlertSearchSortBy.ALERT_NAME,
    sortDesc: false
  };

  assignedAlertsRequestBody: IAlertsSearchAssignmentBody = {
    targetItem: {
      itemType: EProductType.AUDIT,
      itemId: null,
      isAssigned: true
    }
  };

  users: IUser[];
  user: IUser;

  alertDataLoaded: boolean = false;
  hasSnapshotAlerts: boolean = false;
  hasAssignedAlerts: boolean = false;
  auditHasAlerts: boolean = false;
  hasTriggeredAlerts: boolean = false;
  alertsCount: number = 0;

  confetti: JSConfetti;

  accountHasAlerts: boolean = false;

  DELETED_MSG = 'Alert configuration no longer exists because it has been deleted';
  NOT_ASSIGNED_MSG = 'This alert is no longer assigned to this audit';

  private destroy$ = new Subject();

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('alertSummaryTableScrollTo', {read: ElementRef}) summaryTableScrollTo: ElementRef;
  @ViewChild('confettiCanvas', {read: ElementRef}) canvas: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private auditReportLoadingSvc: AuditReportLoadingService,
    private scrollService: AuditReportScrollService,
    private filterBarService: AuditReportFilterBarService,
    private alertService: AlertService,
    private alertReportingService: AlertReportingService,
    private auditFilterBarService: AuditReportFilterBarService,
    private modalService: OpModalService,
    private snackbar: MatSnackBar,
    private auditDataService: IAuditDataService,
    private dateService: DateService,
    private accountsService: AccountsService,
    private location: Location,
    private router: Router,
    private auditReportChangesService: AuditReportChangesService,
    private applicationChromeService: ApplicationChromeService,
    private storageService: StorageService
  ) {
    super();

    const state = this.location.getState() as IAlertSummaryRouteState;
    if (state.setFilters) {
      this.filterBarService.addSubscribedAlertsFilter();
      this.filterBarService.addTriggeredAlertsFilter(true);
    }

    this.alertReportingService.accountHasAlerts$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((hasAlerts: boolean) => {
      this.accountHasAlerts = hasAlerts;
    });

    this.applicationChromeService.isVisitorMode$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(isVisitorMode => {
      this.isVisitorMode = isVisitorMode;
    });

    this.accountsService.getUsers().subscribe(users => this.users = users);
    this.accountsService.getUser().subscribe(user => this.user = user);

    this.accountsService.getUser().subscribe(user => this.isReadOnly = user.permissions === 'guest');
    this.route.params.subscribe(params => {
      this.auditId = +params.auditId;
      this.runId = +params.runId;

      this.auditDataService.getAuditRunsForRunPicker(this.auditId).then((runs: IRunPickerRun[]) => {
        this.currentRun = runs.find(run => this.runId === run.id);
      });

      const alertId = (this.route?.queryParams as BehaviorSubject<any>)?.getValue()?.alertId;
      // When loading report from an alert, first override filters with alert
      // filters before loading report data
      if (alertId) {
        this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
          this.filterBarService.overrideFilters(alert.config.filtersV0);
          this.alertCheckComplete$.next(true);
        });
      } else {
        this.alertCheckComplete$.next(true);
      }

      this.alertReportingService.alertPopoverData$
        .pipe(takeUntil(this.destroy$))
        .subscribe((alerts: IAuditSummaryAlert[]) => {
          this.auditHasAlerts = !!alerts.length;
          this.hasTriggeredAlerts = !!alerts.filter(alert => alert.status === EAlertResultStatus.Triggered).length;
          this.alertsCount = alerts.filter(alert => alert.isSubscribed).length;
          this.alertDataLoaded = true;
          this.initConfetti();
        });

      this.assignedAlertsRequestBody.targetItem.itemId = this.auditId;
      this.isReadOnly = this.accountsService.userIsReadOnly();
    });

    this.widgetState = EFilterSpinnerState.Loading;
    this.tableState = EFilterSpinnerState.Loading;
  }

  ngOnInit() {
    this.initFilters();
    this.handleTableColumns();
    this.auditReportChangesService.onAlertsChanged$.pipe(takeUntil(this.destroy$)).subscribe((alertChangeState: IAlertChangeState) => {
      this.hasSnapshotAlerts = alertChangeState.hasSnapshotAlerts;
      this.hasAssignedAlerts = alertChangeState.hasAssignedAlerts;
      if (this.viewInitialized) {
        this.handleFilteringSortingPaging();
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    this.viewInitialized = true;
    this.handleFilteringSortingPaging();

    if (this.alertDataLoaded) {
      this.initConfetti();
    }
  }

  ngOnDestroy() {
    this.auditReportLoadingSvc.forceOff();
    this.destroy();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initConfetti(): void {
    // don't run if we haven't yet found the canvas element
    if (!this.canvas?.nativeElement) return;

    // don't run if there are no alerts OR triggered alerts
    if (!this.hasSnapshotAlerts || this.hasTriggeredAlerts) return;

    // don't run if table hasn't finish loading
    if (this.loading) return;

    // don't run if already fired
    if (this.firedConfetti) return;

    this.confetti = new JSConfetti({ canvas: this.canvas.nativeElement });
    // fire confetti once on initial report load if not disabled
    if (this.storageService.getValue(Names.GlobalStateKeys.disableConfetti)) return;
    setTimeout(() => this.launchConfetti(), 500);
    this.firedConfetti = true;
  }

  launchConfetti(): void {
    this.confetti.addConfetti({
      confettiColors,
      confettiNumber: 700
    });
  }

  handleTableColumns(): void {
    if (this.isReadOnly) {
      this.displayedColumns.pop();
    }
  }

  initFilters() {
    this.filterBarService.updateSupportedFiltersList(this.alertSupportedFilters);

    this.filterBarService.apiPostBody$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAlertSummaryRequestBody) {
    this.updateCurrentFilters();
    this.alertSummaryApiFilters = apiPostBody;
    this.filtersUpdated$.next({filters: apiPostBody});
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  handleFilteringSortingPaging(): void {
    this.loading = true;
    this.auditReportLoadingSvc.addLoadingToken();
    this.tableState = EFilterSpinnerState.Loading;

    this.alertCheckComplete$.subscribe(() => {
      merge(
        this.sort.sortChange,
        this.paginator.page,
        this.filtersUpdated$
      )
      .pipe(
        tap(() => {
          // update loading states each time filters are updated
          this.auditReportLoadingSvc.addLoadingToken();
          this.tableState = EFilterSpinnerState.Loading;
        }),
        startWith({}),
        switchMap((mergedEvent: Sort & PageEvent & IFiltersUpdate ) => {
          if (mergedEvent !== undefined) {
            if (typeof mergedEvent.pageIndex === 'number') {
              this.resetPaginator(mergedEvent.pageIndex);
              this.scrollService.scrollByElement(this.summaryTableScrollTo.nativeElement);
            }
            if (mergedEvent.active) {
              this.alertsTablePaginationState.sortBy = mergedEvent.active;
              this.alertsTablePaginationState.sortDesc = mergedEvent.direction === 'desc';
              this.resetPaginator(0);
            }

            // Break cache when filters change to force a reload of data
            if (mergedEvent.filters) {
              this.resetPaginator(0);
            }
          }

          return forkJoin([
            this.alertReportingService.getAlertSummary(this.auditId, this.runId, this.alertsTablePaginationState, this.alertSummaryApiFilters),
            this.alertService.getAlertsForAssignment(this.assignedAlertsParams, this.assignedAlertsRequestBody)
          ]).pipe(
            catchError(error => {
              console.log(error);
              this.tableState = EFilterSpinnerState.None;
              return of([
                {
                  metadata: { pagination: { totalCount: 0 } },
                  alerts: []
                } as IAlertSummaryResponse,
                {
                  metadata: { pagination: { totalCount: 0 } },
                  alerts: []
                } as IAlertsSearchAssignmentsResponse
              ]);
            }),
            finalize(() => this.finalizeRequest())
          );
        }),
        catchError(error => {
          console.log(error);
          this.tableState = EFilterSpinnerState.None;
          return of([
            {
              metadata: { pagination: { totalCount: 0 } },
              alerts: []
            } as IAlertSummaryResponse,
            {
              metadata: { pagination: { totalCount: 0 } },
              alerts: []
            } as IAlertsSearchAssignmentsResponse
          ]);
        }),
        finalize(() => this.finalizeRequest())
      )
      .subscribe(([snapshot, assigned]: [snapshot: IAlertSummaryResponse, assigned: IAlertsSearchAssignmentsResponse]) => {
        const assignedAlerts = assigned.alerts.map(alert => alert.id);
        this.totalNumPages = (snapshot.metadata as any).pagination.totalCount;
        this.initTable(snapshot.alerts, assignedAlerts);
        this.updateExportButtonConfig();

        // update loading states
        this.finalizeRequest();
        this.initConfetti();
      });
    });
  }

  initTable(alerts: IAlertSummaryAlert[], assignedAlerts: number[]): void {
    this.exportAlertsConfig.dataToCopy.data = this.dataSource.data = alerts.map((alert: IAlertSummaryAlert) => {
      let alertSummary: IAlertSummaryTableRow = {
        id: alert.id,
        name: alert.config.name,
        metricType: alert.config.metricType,
        operator: this.getOperator(alert.config),
        targetValue: AlertUtils.convertToUIValue(alert.config.metricType, alert.config.metricChangeType, alert.config.targetValueComparator.targetValue),
        filters: this.auditFilterBarService.getAuditFiltersFromApiPostBodyFilters(alert.config.filtersV0),
        statusValue: alert.result.actualValue,
        isUserSubscribed: alert.config.isUserSubscribed,
        statusValueClass: this.getStatusValueColumnClass(alert),
        subscribedStatus: this.getSubscribedStatus(alert),
        subscribedCount: alert.config.subscriberCount,
        isCurrentlyAssigned: assignedAlerts.includes(alert.id),
        history: alert.history || [],
        alertConfigExists: alert.alertConfigExists,
      };

      if (alert.config?.metricChangeType) {
        alertSummary.metricChangeType = alert.config?.metricChangeType;
      }

      return alertSummary;
    });
  }

  private getOperator(alertConfig: IAlertsResponseItemConfig) {
    let operator = AlertMetricChangeOperators.find(
      operator => operator.comparator === alertConfig.targetValueComparator.operator
      && operator.changeType === alertConfig.metricChangeType
    );

    if (!operator) {
      operator = AlertMetricThresholdOperators.find(
        operator => operator.comparator === alertConfig.targetValueComparator.operator
      );
    }

    return operator;
  }

  private updateExportButtonConfig() {
    this.exportAlertsConfig.tableState = {
      ...this.exportAlertsConfig.tableState,
      page: this.paginator.pageIndex,
      sortBy: this.sort.active,
      sortDesc: this.sort.direction === 'desc'
    };

    this.exportAlertsConfig.filters = this.alertSummaryApiFilters;

    if (this.filterBarService.currentRelevantFilters.length) {
      this.exportAlertsConfig.filteredRows = this.totalNumPages as number;
    } else {
      this.exportAlertsConfig.totalRows = this.totalNumPages as number;
      this.exportAlertsConfig.filteredRows = Infinity;
    }
  }

  private resetPaginator(pageNumber?: number): void {
    const p = pageNumber || 0;
    this.alertsTablePaginationState.page = p;
    this.paginator.pageIndex = p;
  }

  private getStatusValueColumnClass(alert: IAlertSummaryAlert): string {
    switch (alert.result.status) {
      case EAlertResultStatus.Triggered:
        return 'triggered';

      case EAlertResultStatus.NotTriggered:
        return 'not-triggered';

      default:
        return 'not-computed';
    }
  }

  private getSubscribedStatus(alert: IAlertSummaryAlert): EAlertBellStatus {
    const userIsSubscribed = alert.config.isUserSubscribed;
    const alertIsTriggered = alert.result.status === EAlertResultStatus.Triggered;

    if (alertIsTriggered) {
      if (userIsSubscribed) {
        // user is subscribed and alert is triggered
        // icon: checked ringing bell
        return EAlertBellStatus.TriggeredAlerts;
      } else {
        // user is not subscribed and alert is triggered
        // icon: empty ringing bell
        return EAlertBellStatus.ConfiguredAndTriggeredAlerts;
      }
    } else {
      if (userIsSubscribed) {
        // user is subscribed and alert is not triggered
        // icon: checked bell
        return EAlertBellStatus.ConfiguredAndSubscribedAlerts;
      } else {
        // user is not subscribed and alert is not triggered
        // icon: empty bell
        return EAlertBellStatus.NoSubscribedAlerts;
      }
    }
  }

  // Returns a string representing the alert logic
  private getAlertLogicExport(alert): string {
    const metricConfig = AlertUtils.getMetricConfigByMetricType(alert.metricType);
    return `${metricConfig.name} ${alert.operator.sign} ${alert.targetValue}`;
  }

  // Returns a string representing the alert filters
  private getFilterExport(alert): string {
    return alert.filters?.map(filter => filter.display)?.join(', ');
  }

  // Returns a string representing the alert value
  private getActualValueExport(alert): string {
    return alert.statusValue;
  }

  // Returns a string representing the alert status
  private getStatusExport(alert): string {
    return alert.statusValueClass;
  }

  // Returns a string representing the alert run date
  private getRunDateExport(alert): string {
    return this.currentRun ? `${this.dateService.formatDate(this.currentRun?.date, EDateFormats.dateSeventeen)}` : '';
  }

  handleAlertAssignmentState(alert: IAlertSummaryTableRow): void {
    const updates = [{
      alertId: alert.id,
      operation: alert.isCurrentlyAssigned ? EAlertAssignmentOperation.UNASSIGN : EAlertAssignmentOperation.ASSIGN
    }];

    this.alertService.patchAssignedAlerts('AUDIT', this.auditId, updates).subscribe(() => {
      this.snackbar.openFromComponent(AlertChangeSuccessSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: {
          name: alert.name,
          type: EAlertSuccessType.Unassigned,
          alert: alert,
        },
        panelClass: 'snackbar-wide-width'
      });

      this.handleFilteringSortingPaging();
    });
  }

  openFullscreenChart(alert: IAlertSummaryTableRow): void {
    const alertObj = {
      id: alert.id,
      name: alert.name,
      metricType: alert.metricType,
      metricChangeType:alert.metricChangeType,
    }

    const data: ISummaryHistoryPreviewModalPayload = {
      alert: alertObj,
      barsConfig: { itemId: this.auditId, runId: this.runId },
    };

    this.modalService.openFixedSizeModal(SummaryHistoryPreviewModalComponent, { data });
  }

  openFreeTrialAdModal(type: EFreeTrialAdModalType) {
    const data = {
      type: EFreeTrialAdModalType.ALERT
    };

    this.modalService.openModal(FreeTrialAdModalComponent, { data });
  }

  openAlertDesigner(alertRow: IAlertSummaryTableRow, step: EAlertStep): void {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal(EFreeTrialAdModalType.EXPORT);
      return;
    }

    if (!alertRow.alertConfigExists) {
      return;
    }
    const data: IAlertEditModalPayload|IAlertReadOnlyModalPayload = {
      type: this.isReadOnly ? EAlertModalType.ReadOnly : EAlertModalType.Edit,
      auditId: this.auditId,
      alertId: alertRow.id,
      currentStep: step,
      filterType: EAlertFilterType.V1,
      menuContext: EAlertMenuContext.Audit
    };

    this.modalService.openFixedSizeModal(AlertComponent, {
      disableClose: true,
      data: ({
        ...data,
      } as IAlertEditModalPayload), autoFocus: false }).afterClosed().subscribe(updated => {
      if (updated) {
        // although data is snapshot we still want to update it
        // if user edits again so edited values are present
        this.handleFilteringSortingPaging();
      }
    });
  }

  openAuditReport(alertRow: IAlertSummaryTableRow): void {
    const alertReportConfig = AlertUtils.getReportConfigByMetricType(alertRow.metricType);

    this.router.navigateByUrl(`audit/${this.auditId}/run/${this.runId}/${alertReportConfig.link}?alertId=${alertRow.id}`);
  }

  private finalizeRequest() {
    this.loading = false;
    this.auditReportLoadingSvc.removeLoadingToken();
    this.tableState = this.filterBarService.currentRelevantFilters.length
      ? EFilterSpinnerState.Filtered
      : EFilterSpinnerState.None;
  }

  getMetricValue(alert: IAlertSummaryTableRow) {
    return AlertUtils.renderUiValueWithUnits(alert.metricType, alert.metricChangeType, alert.statusValue);
  }

  getStatusTooltip(alert): string {
    if (alert.metricChangeType === EAlertMetricChangeType.ValueChange) {
      if (
        alert.operator.comparator === EAlertMetricValueComparator.GreaterOrEqual
        || alert.operator.comparator === EAlertMetricValueComparator.LessOrEqual
      ) {
        const metricValueWithUnits = this.getMetricValue(alert);

        return `${alert.statusValue >= 0 ? 'Increased' : 'Decreased'} by ${metricValueWithUnits} since last run - View Report`;
      }
    }

    return `View Report`;
  }
}
