import { Component } from '@angular/core';
import { EChartColor } from '@app/components/audit-reports/audit-report/audit-report.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-filter-pill',
  templateUrl: './cl-filter-pill.component.html',
  styleUrls: ['./cl-filter-pill.component.scss']
})
export class ComponentLibraryFilterPillComponent {

  filterPills = [
    {
      name: '0 - 3 Seconds',
      colorClass: EChartColor.Blue,
      filtered: false
    },
    {
      name: '3 - 6 Seconds',
      colorClass: EChartColor.Green,
      filtered: false
    },
    {
      name: '6 - 9 Seconds',
      colorClass: EChartColor.Orange,
      filtered: false
    },
    {
      name: '9 - 12 Seconds',
      colorClass: EChartColor.Red,
      filtered: false
    },
    {
      name: '>12 Seconds',
      colorClass: EChartColor.Purple,
      filtered: false
    }
  ];

  onPillClick(event: MouseEvent, pill: any) {
    pill.filtered = !pill.filtered;

    // If the clicked pill is now filtered, unfilter all other pills
    if (pill.filtered) {
      this.filterPills.forEach(p => {
        if (p !== pill) {
          p.filtered = false;
        }
      });
    }
  }
}
