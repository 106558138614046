<!--CONSENT CATEGORIES LIST-->
<applied-consent-categories
  [runId]="runId"
  [auditId]="auditId"
  [categories]="ccsAssignedToRun"
  [categoriesType]="categoriesType"
  [auditConsentCategories]="auditConsentCategories"
  [isLoadedAuditConsentCategories]="isLoadedAuditConsentCategories"
  [isLoadedRunConsentCategories]="isLoadedRunConsentCategories"
  class="categories-wrap"
></applied-consent-categories>

<!--WIDGETS-->
<div class="widgets">
  <div class="widget-container">
    <div class="widget-container">
      <split-card [topLabel]="widgetPagesScanned.topLabel"
                  [state]="widgetsState"
                  [topTooltip]="widgetPagesScanned.tooltip"
                  [metricType]="widgetPagesScanned.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="filteredPageCount"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPagesScanned.metricType"
                  [disableHighlightFromClick]="true"
      >
        <div class="widget-inside-wrap">
          <div class="counts">
            <span class="count">{{ filteredPageCount | number }}</span>
            <span *ngIf="widgetsState === 'filtered'">&nbsp;of {{ totalPageCount | number }}</span>
          </div>
          <filter-spinner [state]="widgetsState"></filter-spinner>
        </div>
      </split-card>
      <split-card [topLabel]="widgetPagesUnapprovedTags.topLabel"
                  [topHandler]="widgetPagesUnapprovedTags.topHandler"
                  [activated]="isFilteredByStatus(EConsentCategoryType.UNAPPROVED)"
                  [haveEnoughData]="true"
                  [state]="widgetsState"
                  [topTooltip]="widgetPagesUnapprovedTags.tooltip"
                  [metricType]="widgetPagesUnapprovedTags.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetPagesUnapprovedTags.metricType"
                  [disableHighlightFromClick]="true"
      >
        <div class="widget-inside-wrap">
          <div class="counts">
              <span class="count"
                    [class.red]="unapprovedTagCount"
              >{{ unapprovedTagCount | number }}</span>
          </div>
          <filter-spinner [state]="widgetsState"></filter-spinner>
        </div>
      </split-card>
      <split-card [topLabel]="widgetTagsEvaluated.topLabel"
                  [topTooltip]="widgetTagsEvaluated.tooltip"
                  [state]="widgetsState"
                  [topChangeContent]="widgetTagsEvaluated.topChangeContent"
                  [bottomHandler]="widgetTagsEvaluated.bottomHandler"
                  [haveEnoughData]="widgetTagsEvaluatedData?.length > 1"
                  [metricType]="widgetTagsEvaluated.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="widgetTagsEvaluated.topChangeContent"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetTagsEvaluated.metricType"
                  [disableHighlightFromClick]="true"
      >
        <sparkline-chart-insight meaningOfUp="none"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetTagsEvaluatedData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetTagsApproved.topLabel"
                  [topTooltip]="widgetTagsApproved.tooltip"
                  [topHandler]="widgetTagsApproved.topHandler"
                  [activated]="isFilteredByStatus(EConsentCategoryType.APPROVED)"
                  [state]="widgetsState"
                  [topChangeContent]="widgetTagsApproved.topChangeContent"
                  [bottomHandler]="widgetTagsApproved.bottomHandler"
                  [topChangeMeaning]="widgetTagsApproved.topChangeMeaning"
                  [haveEnoughData]="widgetTagsApprovedData?.length > 1"
                  [metricType]="widgetTagsApproved.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [currentValue]="widgetTagsApproved.topChangeContent"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetTagsApproved.metricType"
                  [disableHighlightFromClick]="true"
      >
        <sparkline-chart-insight meaningOfUp="none"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetTagsApprovedData">
        </sparkline-chart-insight>
      </split-card>
      <split-card [topLabel]="widgetUnapprovedTags.topLabel"
                  [topTooltip]="widgetUnapprovedTags.tooltip"
                  [topHandler]="widgetUnapprovedTags.topHandler"
                  [activated]="isFilteredByStatus(EConsentCategoryType.UNAPPROVED)"
                  [topChangeContent]="widgetUnapprovedTags.topChangeContent"
                  [state]="widgetsState"
                  [bottomHandler]="widgetUnapprovedTags.bottomHandler"
                  [topChangeMeaning]="widgetUnapprovedTags.topChangeMeaning"
                  [haveEnoughData]="widgetUnapprovedTagsData?.length > 1"
                  [metricType]="widgetUnapprovedTags.metricType"
                  [auditId]="auditId"
                  [runId]="runId"
                  [filters]="currentFilters"
                  [highlight]="highlightMetricType === widgetUnapprovedTags.metricType"
                  [disableHighlightFromClick]="true"
      >
        <sparkline-chart-insight meaningOfUp="negative"
                                 meaningOfNoChange="none"
                                 label="since last run"
                                 [dataLoaded]="sparklineDataLoaded"
                                 [dataPoints]="widgetUnapprovedTagsData">
        </sparkline-chart-insight>
      </split-card>
    </div>
  </div>
</div>

<!--TAG COMPLIANCE TABLE -->
<div class="compliance-wrap">
  <tag-compliance-table
    #tagCompliance
    [items]="tags$ | async"
    [auditConsentCategories]="auditConsentCategories"
    [runConsentCategories]="ccsAssignedToRun"
    [runId]="runId"
    [auditId]="auditId"
    [auditName]="audit?.name"
    [apiFilters]="apiFilters"
    [state]="tagComplianceTableState"
    [filteredPageCount]="filteredPageCount"
    [exportReportConfig]="tagComplianceExportConfig"
    (onSort)="handleSortTagCompliance($event)"
    (onManageConsentCategories)="openConsentCategoryManager()"
    (onPaginate)="handlePaginationTagCompliance($event)"
    (localFilter)="handleLocalFilter($event)"
    (onGlobalFilterByCategoryName)="handleConsentCategoryNameFilterFromCompliance($event)"
  ></tag-compliance-table>
</div>

<!-- PAGES SCANNED -->
<privacy-tags-pages-table
  #pagesTable
  [state]="pagesTableState"
  [items]="pages$ | async"
  [exportReportConfig]="pagesExportConfig"
  [tagNameToAutofill]="tagNameToAutofill"
  (onSort)="handleSortPages($event)"
  (onPaginate)="handlePaginationPages($event)"
></privacy-tags-pages-table>
