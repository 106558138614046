import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IUseCaseWidget } from '@app/components/audit-reports/use-cases/use-cases.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { UseCaseService } from '@app/components/audit-reports/use-cases/use-case.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'use-case-widget-body',
  templateUrl: './use-case-widget-body.component.html',
  styleUrls: ['./use-case-widget-body.component.scss']
})
export class UseCaseWidgetBodyComponent {

  @Input() widget: IUseCaseWidget;

  constructor(
    private useCaseService: UseCaseService,
  ) { }

  navigate(): void {
    // check if the window size is less than 500px
    const isSmallScreen = window.innerWidth < 500;

    if (isSmallScreen && !this.widget.skipNavigationConfirm) {
      this.useCaseService.confirmReportNavigation(this.widget.navTo);
    } else {
      this.widget?.navTo();
    }
  }
}
