<form [formGroup]="formData">
  <!-- Main Filter -->
  <div class="main-filter">
    <mat-form-field class="styled-mat-input" appearance="outline">
      <mat-label>Filter By</mat-label>
      <mat-select formControlName="type">
        <mat-option class="slim-mat-option" *ngFor="let filterType of filterTypes" [value]="filterType.value">
          {{ filterType.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="filterTypeControl.touched && filterTypeControl.invalid">
        <mat-icon>warning_amber</mat-icon>
        Field can't be empty
      </mat-error>
    </mat-form-field>

    <mat-form-field class="styled-mat-input" appearance="outline">
      <mat-label>Operator</mat-label>
      <mat-select formControlName="matchType">
        <mat-option class="slim-mat-option"
                    *ngFor="let matchType of filterTypeToMatchTypesMap.get(filterTypeControl.value)"
                    [value]="matchType.value">
          {{ matchType.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="matchTypeControl.touched && matchTypeControl.invalid">
        <mat-icon>warning_amber</mat-icon>
        Field can't be empty
      </mat-error>
    </mat-form-field>

    <mat-form-field class="styled-mat-input last-field" appearance="outline"
                    *ngIf="filterTypeControl.value !== RuleFilterType.Tag; else tagSelector">
      <mat-label>Value</mat-label>
      <input matInput formControlName="value">
      <mat-error *ngIf="valueControl.touched && valueControl.invalid">
        <mat-icon>warning_amber</mat-icon>
        Field can't be empty
      </mat-error>
    </mat-form-field>

    <ng-template #tagSelector>
      <mat-form-field class="styled-mat-input last-field" appearance="outline">
        <mat-label>Value</mat-label>
        <input matInput formControlName="tagId" [matAutocomplete]="auto" />
        <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTagIdFn">
          <mat-option class="slim-mat-option tag-id-option"
                      *ngFor="let tag of filteredTags"
                      [value]="tag.id">
            <div class="tag-option">
              <span class="tag-icon-container">
                <img [src]="tag.iconUrl" class="tag-icon" loading="lazy" />
              </span>
              <div class="tag-info">
                <div class="name" [innerHTML]="tag.name | highlightTextPipe: tagIdSearchText"></div>
                <div class="category" [innerHTML]="getTagCategory(tag.tagCategoryId).category | highlightTextPipe: tagIdSearchText"></div>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="tagIdControl.touched && tagIdControl.invalid">
          <mat-icon>warning_amber</mat-icon>
          Field can't be empty
        </mat-error>
      </mat-form-field>
    </ng-template>

    <mat-icon class="remove-icon" (click)="onRemoveMainCondition.emit()">
      highlight_off
    </mat-icon>
  </div>

  <!-- Tag Account Filter -->
  <div *ngIf="filterTypeControl.value === RuleFilterType.Tag && accountGroup.enabled"
       class="tag-account-wrp">
      <rule-tag-account-filter [formData]="accountGroup"
                               (onRemoveAccount)="removeAccountCondition()">
      </rule-tag-account-filter>
  </div>

  <!-- Tag Status Code Filter -->
  <div *ngIf="filterTypeControl.value === RuleFilterType.Tag && statusCodeGroup?.enabled"
       class="tag-status-code-wrp">
    <op-rule-tag-status-code-filter [formData]="statusCodeGroup"
                                 (onRemoveStatusCode)="removeStatusCodeCondition()">
    </op-rule-tag-status-code-filter>
  </div>

  <!-- Tag Variable Filters -->
  <div *ngIf="filterTypeControl.value === RuleFilterType.Tag"
        formArrayName="variables">
    <div *ngFor="let variableGroup of variablesArray.controls; let i = index"
         class="tag-variable-wrp">
      <rule-tag-variable-filter [formData]="variableGroup"
                                [allTags]="allTags"
                                (onRemoveVariable)="removeVariableCondition(i)">

      </rule-tag-variable-filter>
    </div>
  </div>

  <!-- Add Tag Conditions buttons -->
  <div *ngIf="filterTypeControl.value === RuleFilterType.Tag" class="add-tag-conditions-buttons">
    <a class="add-account-btn"
       (click)="addAccountCondition()"
       [class.disabled]="formData.invalid"
       *ngIf="accountGroup.disabled">
      <mat-icon>add_circle_outline</mat-icon>
      <span>Add Account</span>
    </a>

    <span class="or-text" *ngIf="accountGroup.disabled">OR</span>

    <a class="add-variable-btn"
       (click)="addVariableCondition()"
       [class.disabled]="formData.invalid">
      <mat-icon>add_circle_outline</mat-icon>
      <span>Add Variable</span>
    </a>

    <span class="or-text" *ngIf="statusCodeGroup.disabled">OR</span>

    <a class="add-variable-btn"
       (click)="addStatusCodeCondition()"
       [class.disabled]="formData.invalid"
       *ngIf="statusCodeGroup.disabled">
      <mat-icon>add_circle_outline</mat-icon>
      <span>Add Status Code</span>
    </a>
  </div>
</form>
