<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>
<div class="sort-delete" [class.hidden]="loading">
  <div class="filter-container">
    <div class="filter-input">
      <op-clearable-input placeholderText="Search Folders"
                          class="table-filter-input"
                          (onUserInput)="applyFilter($event)">
      </op-clearable-input>

    </div>
  </div>
  <div class="button-container">
    <button mat-flat-button
            color="primary"
            (click)="onCreateFolder()"
            class="create-new-button"
    >
      <mat-icon>add_circle_outline</mat-icon>
      Create New Folder
    </button>
    <button mat-flat-button
            [disabled]="deleteButtonDisabled"
            matTooltip="Delete Selected Folders"
            matTooltipShowDelay="350"
            (click)="onDeleteMultiFolders()"
            class="icon-button delete-button"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
<div class="content-wrap" [class.hidden]="loading">
  <table mat-table class="styled-mat-table" matSort [dataSource]="dataSource">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (click)="masterToggle(); onCheckboxChecked($event)"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      color="primary">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" valign="middle">
        <mat-checkbox (click)="selection.toggle(row); onCheckboxChecked($event)"
                      [checked]="selection.isSelected(row)"
                      color="primary">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Folder Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Folder Name</th>
      <td mat-cell *matCellDef="let folder">{{ folder.name }}</td>
    </ng-container>

    <!-- Sub-Folders Count Column -->
    <ng-container matColumnDef="domains">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">Sub-folders</th>
      <td mat-cell *matCellDef="let folder" class="center-text">{{ folder.domains }}</td>
    </ng-container>

    <!-- Created By Column -->
    <ng-container matColumnDef="createdBy">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">Created By</th>
      <td mat-cell *matCellDef="let folder" class="center-text">{{ folder.createdBy }}</td>
    </ng-container>

    <!-- Created At Column -->
    <ng-container matColumnDef="createdDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">Created Date</th>
      <td mat-cell *matCellDef="let folder" class="center-text">{{ folder.createdDate }}</td>
    </ng-container>

    <!-- ID Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef class="center-text">ID</th>
      <td mat-cell *matCellDef="let folder" class="center-text">{{ folder.id }}</td>
    </ng-container>

    <!-- Edit/Delete Column -->
    <ng-container matColumnDef="editDelete">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let folder" align="right">
        <div class="btn-wrap">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="more-menu-btn">more_vert</mat-icon>
          </button>
        </div>
        <mat-menu #menu="matMenu" class="manage-folders-menu">
          <button mat-menu-item class="table-action-icon" (click)="onEditFolder($event, folder)">
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button mat-menu-item class="table-action-icon" (click)="onDeleteFolder($event, folder)">
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
