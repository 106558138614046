import { RouteReloadService } from './../../shared/services/route-reload.service';
import { IWebJourneyEditorModalData } from './../../web-journey/web-journey-editor/web-journey-editor.models';
import { WebJourneyReportUrlBuilders } from './../web-journey-report.constants';
import { AccountSettingsUrlBuilders } from './../../account-settings/account-settings.const';
import { RouteDataService } from '@app/components/shared/services/route-data.service';
import { catchError, startWith, takeUntil } from 'rxjs/operators';
import { WebJourneyReportService } from './../web-journey-report.service';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { userIsAdmin, userIsGuest } from '@app/authUtils';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { EDateFormats, formatDate, runDuration } from '@app/components/date/date.service';
import { IDomain, IDomainsService } from '@app/components/domains/domainsService';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { IWebJourneyResults, IWebJourneyRun } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { IDeleteItemModalData } from '@app/components/modals/modalData';
import { IModalService } from '@app/components/modals/modalService';
import { ModalTemplates } from '@app/components/modals/modalTemplates';
import { StatusBannerService, StatusUpdateType } from '@app/components/reporting/statusBanner/statusBannerService';
import { IRunPickerRun } from '@app/components/run-picker-ng/run-picker-ng.models';
import { IMenuItem } from '@app/components/shared/components/op-menu/op-menu.component';
import { RunnableItem } from '@app/components/stopRun/stopRunModalController';
import { IStopRunService } from '@app/components/stopRun/stopRunService';
import { IUser } from '@app/moonbeamModels';
import { IWJReportParams } from './../web-journey-report.models';
import { combineLatest, forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import {
  IWebJourneyRunInfo,
  IWebJourneyV3,
  IWebJourneyV3Card
} from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { IWebJourneyRunsSeriazlier } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyRunsSerializer';
import { IFolder, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DataSourcesUrlBuilders } from '@app/components/manage/cards/manage-cards.constants';
import { StorageService } from '@app/components/shared/services/storage.service';
import { ITimeSeriesRuns } from '@app/components/reporting/widgets/timeSeries/timeSeries';
import { FeatureFlagHelper } from '@app/environments/feature-flag-helper';
import { FeatureFlags } from '@app/environments/feature-flags.enum';
import { AccountsService } from '@app/components/account/account.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { WebJourneyEditorComponent } from '@app/components/web-journey/web-journey-editor/web-journey-editor.component';
import { ReportCardService } from '@app/components/manage/cards/report-card/report-card.service';
import { ManageCardsService } from '@app/components/manage/cards/manage-cards.service';
import { CardTypes } from '@app/components/manage/cards/report-card-list/report-card-list.constants';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { EDataSelectorType, SwitchAccountService } from '@app/components/account/admin/switch-account.service';
import { EJourneyFailureType } from '../web-journey-results/web-journey-results.enums';
import { AdvancedText, EAdvancedJourneyFeatures, EJourneyStatusText } from './web-journey-report-header.constants';
import { IJourneyHeaderStatus, IJourneyReportInfoData } from './web-journey-report-header.models';
import { LocationsService } from '@app/components/shared/services/locations.service';
import { IJourneyStatus } from '../web-journey-results/web-journey-results.models';
import { IGeoLocation } from '@app/components/creator/webJourney/locationsService';
import { ELeadingIconColor, EStatusButtonColor } from '@app/components/shared/components/status-popover-button/status-popover-button.constants';
import { ILabel } from '@app/components/shared/services/label.service';
import { IJourneyReportInfoFailedActionNotice, IJourneyReportInfoFailedRulesNotice } from './web-journey-report-info/web-journey-report-info.component';
import { IWebJourneyServices } from '@app/components/web-journey/web-journey.models';
import { MdePopoverTrigger } from '@app/components/popover';
import { Duration } from 'date-fns';

const defaultRunInfoPanelObj = {
  executedActions: '...',
  location: {
    label: '...',
    countryCode: '...',
  } as IGeoLocation,
  runDuration: {
    start: '...',
    end: '...',
    duration: '...'
  },
  browserVersion: '...',
  dataLayer: '...',
  vpn: '...',
  runId: '...',
  userAgent: '...',
  viewport: '...',
  folderDomain: '...',
  runFrequency: '...',
  actionsConfigured: '...',
  journeyId: '...',
  creator: '...',
  labels: '...',
  monitoredJourney: '...',
  runInfoExists: null,
  customProxy: null
}

@Component({
  selector: 'op-web-journey-report-header',
  templateUrl: './web-journey-report-header.component.html',
  styleUrls: ['./web-journey-report-header.component.scss'],
})
export class WebJourneyReportHeaderComponent implements OnInit, OnDestroy {

  @ViewChild(MdePopoverTrigger) popover: MdePopoverTrigger;
  @ViewChild('journeyInfoTrigger', { read: ElementRef }) journeyInfoTrigger: ElementRef;

  readonly statusBannerUpdateType = StatusUpdateType;
  runCancelled: boolean;
  journeyId: number;
  runId: number;
  nextCheck: Date;
  showPauseIconFlag: boolean = false;
  folder: IFolder;
  domain: IDomain;

  runPickerVisible: boolean;

  date: string = 'Loading';
  journeyName: string = 'Select...';

  folderName: string;
  domainName: string;

  primaryTagFilter: boolean;
  primaryTagFilterDisabled: boolean;

  users: IUser[];
  user: IUser;
  isReadOnly: boolean = true;
  comparisonsEnabled: boolean;

  isTimeSeriesVisible: boolean;

  noJourney: boolean;
  loaded: boolean;

  journeyRunning: boolean;

  scheduledToScan: boolean;

  folders: IFolder[];
  domains: IDomain[];
  labels: ILabel[];

  journeys: IWebJourneyV3Card[];
  journey: IWebJourneyV3Card;

  runs: IWebJourneyRun[];
  run: IWebJourneyRun;
  timeSeriesRuns: ITimeSeriesRuns[];
  runPickerRuns: IRunPickerRun[];
  remainingJourneyFixes = 0;

  journeyStatus: IJourneyHeaderStatus = {
    icon: null,
    text: null,
    color: null,
    leadingIconColor: null
  };
  journeyIsAdvanced: boolean = false;
  journeyHasNotRunYet: boolean = false;
  EJourneyFailureType = EJourneyFailureType;
  runInfo: IWebJourneyRunInfo;
  loadingRunInfo: boolean = true;
  status: IJourneyStatus;
  results: IWebJourneyResults;
  locations: IGeoLocation[];

  runInfoPanelData: IJourneyReportInfoData = defaultRunInfoPanelObj;

  recurrenceEnabled: boolean = false;

  advancedJourneyFeatures: string[] = [];
  failedRulesData: IJourneyReportInfoFailedRulesNotice;
  failedActionData: IJourneyReportInfoFailedActionNotice;

  readonly menuOptions: IMenuItem[] = [{
    icon: 'icon-edit',
    label: 'Edit',
    onClick: () => this.editJourney(),
  }, {
    icon: 'icon-run',
    label: 'Run Now',
    onClick: () => this.runNow(),
    hidden: () => this.inProgress()
  }, {
    icon: 'icon-stop',
    label: 'Stop & Discard Run',
    onClick: () => this.stopRun(),
    hidden: () => !this.inProgress()
  }, {
    icon: 'icon-pause',
    label: 'Pause Scheduled Runs',
    onClick: () => this.pauseJourney(),
    hidden: () => !this.rCardSvc.canPause(this.journey),
  }, {
    icon: 'icon-refresh',
    label: 'Resume Scheduled Runs',
    onClick: () => this.resumeJourney(),
    hidden: () => !this.rCardSvc.canResume(this.journey),
  }, {
    icon: 'icon-settings',
    label: 'Comparison Configuration',
    onClick: () => this.goToComparisonConfiguration(),
    hidden: () => !this.isComparisonsEnabled()
  }, {
    icon: 'icon-delete',
    label: 'Delete',
    onClick: () => this.deleteJourney(),
    hidden: () => !this.canDelete()
  }];

  private destroy$ = new Subject();

  constructor(private route: ActivatedRoute,
              private router: Router,
              private routeReload: RouteReloadService,
              private storageService: StorageService,
              private authService: AuthenticationService,
              private accountsService: AccountsService,
              private webJourneyAPIService: IWebJourneyApiService,
              private webJourneyReportService: WebJourneyReportService,
              private stopRunService: IStopRunService,
              private modalService: IModalService,
              private modalServiceNg: OpModalService,
              private modalEscapeService: ModalEscapeService,
              private statusBannerService: StatusBannerService,
              private snackbarService: SnackbarService,
              private foldersService: IFoldersApiService,
              private domainsService: IDomainsService,
              private changeDetection: ChangeDetectorRef,
              private webJourneyV3Service: WebJourneyV3Service,
              private webJourneyRunsSerializer: IWebJourneyRunsSeriazlier,
              private rCardSvc: ReportCardService,
              private mCardSvc: ManageCardsService,
              private switchAccountService: SwitchAccountService,
              private locationsService: LocationsService,
              private reportCardService: ReportCardService
  ) {
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
  }

  ngOnInit(): void {
    combineLatest([
      this.route.params,
      this.routeReload.reloadRouteEvents$.pipe(startWith({})),
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([params, _]: [IWJReportParams, NavigationEnd]) => {
        this.setDefaultValues();

        this.journeyId = +params.journeyId;
        this.runId = +params.runId;

        this.storageService.setParamsForWebJourneys(params);

        // if the journey doesn't have a run id (hasn't run yet) then
        // we'll go down this path to load the data for the header
        if (!this.runId) {
          this.loadAccountDetails();
          this.loadDataForHeader();
          this.loadData();
          this.journeyHasNotRunYet = true;
          return;
        }

        const dataIsLoaded = this.folders && this.domains && this.journeys && this.runs?.length > 0;
        if (dataIsLoaded) this.loadJourneyDetails();
        else this.loadData();
      });

    this.webJourneyReportService.webJourneyConfiguredActions$.pipe(takeUntil(this.destroy$)).subscribe(count => {
      this.runInfoPanelData.actionsConfigured = count;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private setDefaultValues() {
    this.runPickerVisible = this.isTimeSeriesVisible = this.journeyRunning = this.scheduledToScan = this.loaded = this.noJourney = false;
    this.failedRulesData = this.failedActionData = null;
    this.advancedJourneyFeatures = [];
    this.runInfoPanelData = defaultRunInfoPanelObj;
  }

  /** Menu options **/
  runNow(): void {
    this.runCancelled = false;

    if (FeatureFlagHelper.isEnabled(FeatureFlags.SQSJourneyEngines)) {
      this.webJourneyV3Service.runWebJourneyNow(this.journeyId).subscribe(res => {
        this.scheduledToScan = true;
        this.journeyRunning = true;
        if (!this.runId) {
          this.statusBannerService.updateStatusBanner();
        }
      }, (error) => this.webJourneyV3Service.handleWebJourneyRunError(error, this.journey.name));
    } else {
      this.webJourneyAPIService.runWebJourneyNow(this.journey).then(res => {
        if (res && res.code && res.code === 403) {
          this.snackbarService.openErrorSnackbar(res.message);
        } else {
          this.scheduledToScan = true;
          this.journeyRunning = true;
          if (!this.runId) {
            this.statusBannerService.updateStatusBanner();
          }
        }
      });
    }
  }

  editJourney(): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    const data: IWebJourneyEditorModalData = {
      journeyId: this.journeyId,
    };
    this.modalServiceNg.openFixedSizeModal(WebJourneyEditorComponent, {disableClose: true, data}, 'op-webjourney-editor')
      .afterClosed()
      .subscribe((webJourney?: IWebJourneyV3) => {
        this.modalEscapeService.remove(index);
        if (webJourney) this.router.navigateByUrl(this.router.url);
      });
  }

  goToComparisonConfiguration(): void {
    this.router.navigateByUrl(AccountSettingsUrlBuilders.manageTags());
  }

  deleteJourney(): void {
    const template = ModalTemplates.initTemplate<IDeleteItemModalData<IWebJourneyV3>>(ModalTemplates.DeleteWebJourney);
    template.modalData = {
      item: this.journey
    };
    this.modalService.showDelete<IDeleteItemModalData<IWebJourneyV3>, boolean>(
      template,
      isDeleted => {
        if (isDeleted) this.router.navigateByUrl(DataSourcesUrlBuilders.sources());
      }
    );
  }

  stopRun(): void {
    const journeyId: number = this.journeyId;

    // clear cache so we can retrieve the latest run
    this.webJourneyReportService.getJourneyRuns(journeyId).subscribe(runs => {
      const runId = runs.length > 0 && !runs[0].completedAt ? runs[0].id : undefined;
      const onStopCallback = () => {
        this.journeyRunning = false;
        this.scheduledToScan = false;
        this.runCancelled = true;
        this.statusBannerService.updateStatusBanner();
        this.statusBannerService.runStoppedSubject.next();
      };
      this.stopRunService.stopRun(RunnableItem.WebJourney, journeyId, runId, onStopCallback);
    });
  }

  inProgress(): boolean {
    return this.journeyRunning;
  }

  // Used for icon display -- different logic than canPause
  showPauseIcon(): boolean {
    if (this.recurrenceEnabled) {
      return this.journey.schedule.isPaused;
    } else {
      return this.rCardSvc.isPausedCard(this.journey);
    }
  }

  pauseJourney(): void {
    this.changeDetection.detectChanges();
    this.mCardSvc.pauseResumeJourney(this.journey, this.mCardSvc.pauseDate);
    this.journey.nextCheck = this.mCardSvc.pauseDate;
    this.nextCheck = new Date(this.mCardSvc.pauseDate);
    this.showPauseIconFlag = true;
    this.statusBannerService.publishPauseStatus(true);
  }

  resumeJourney(): void {
    this.changeDetection.detectChanges();
    this.mCardSvc.pauseResumeJourney(this.journey, this.mCardSvc.resumeDate);
    this.journey.nextCheck = this.mCardSvc.resumeDate;
    this.nextCheck = new Date(this.mCardSvc.resumeDate);
    this.showPauseIconFlag = false;
    this.statusBannerService.publishPauseStatus(false);
  }

  isComparisonsEnabled(): boolean {
    return this.comparisonsEnabled;
  }

  canDelete(): boolean {
    return (this.user && this.journey && (userIsAdmin(this.user) || this.user.id === this.journey?.userId));
  }

  canStopRun(): boolean {
    return !this.isReadOnly;
  }

  /** Init logic **/
  private loadData(): void {
    this.loadAccountDetails();
    this.loadJourneyDetails();
  }

  private loadAccountDetails() {
    forkJoin([
      this.accountsService.getUser(),
      this.authService.getFeaturesWithCache()
    ]).subscribe(([user, features]) => {
      this.user = user;
      this.isReadOnly = userIsGuest(user);
      this.comparisonsEnabled = features.includes('comparisons');
    });
  }

  private loadJourneyDetails() {
    forkJoin([
      from(this.foldersService.getFolders(false)),
      from(this.domainsService.getDomains()),
      this.webJourneyV3Service.getJourneys() as Observable<IWebJourneyV3Card[]>,
      this.webJourneyReportService.getJourneyRuns(this.journeyId)
        .pipe(catchError((e) => {
          return throwError(e);
        })),
      this.runId === 0 ? of(undefined) : this.webJourneyV3Service.getJourneyRunInfo(this.journeyId, this.runId)
      .pipe(catchError((e) => {
        return of(undefined);
      })),
      this.locationsService.getAllLocations(),
      this.runId === 0 ? of(undefined) : this.webJourneyReportService.getJourneyResult(this.journeyId, this.runId)
        .pipe(catchError((e) => {
          return of(undefined);
        })),
      this.accountsService.getUsers(),
      this.webJourneyAPIService.getWebJourneyLabels(this.journeyId),
      this.webJourneyAPIService.getScriptServicesCounts()
    ])
      .subscribe(
        data => this.onJourneyDetailsDataReady(data as any),
        error => this.handleNoJourney(error),
      );
  }

  private loadDataForHeader(): void {
    forkJoin([
      this.webJourneyV3Service.getJourney(this.journeyId),
      from(this.foldersService.getFolders(false)),
      from(this.domainsService.getDomains()),
      this.webJourneyV3Service.getJourneyStatus(this.journeyId)
    ]).subscribe(([journey, folders, domains, status]) => {
      this.journeyName = journey.name;
      this.journeyRunning = status.webJourneyRunning;
      this.folders = folders;
      this.domains = domains;
      this.folder = this.folders.find(f => f.id === journey.folderId);
      this.domain = this.domains.find(f => f.id === journey.domainId);
      if (this.folder) this.folderName = this.folder.name;
      if (this.domain) this.domainName = this.getDomainName(this.domain);
    });
  }

  private handleNoJourney(e?): void {
    const noJourney = (!!this.journeys && !this.journey) || e?.code === 404;

    // Handle error where *attemptSwitchAccounts* is called
    if (e?.code === 403) {
      this.switchAccountService
        .attemptSwitchAccounts(this.journeyId, EDataSelectorType.web)
        .subscribe();
    }

    // If Journey was not found - init the flow of switching to /data-sources
    if (noJourney) {
      this.noJourney = true;
      this.loaded = true;
      this.snackbarService.openInfoSnackbar('The Web Journey you requested does not exist or is not accessible', {
        duration: 150000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.router.navigateByUrl('/data-sources');
      return;
    }
  }

  private handleNoRun(): void {
    const firstRunInProgress = this.runId === 0 && !this.runs[0]?.completedAt;
    if (!firstRunInProgress) {
      this.snackbarService.openInfoSnackbar('The Web Journey run you requested does not exist or is outside of your data retention window. We\'ve redirected you to the most recent run.', {
        duration: 5000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });

      this.updateRun(this.runPickerRuns[0].id);
    }

    return;
  }

  private onJourneyDetailsDataReady(
    [
      folders,
      domains,
      journeys,
      runs,
      runInfo,
      locations,
      results,
      users,
      labels,
      scriptServicesCounts
    ]:
    [
      IFolder[],
      IDomain[],
      IWebJourneyV3Card[],
      IWebJourneyRun[],
      IWebJourneyRunInfo,
      IGeoLocation[],
      IWebJourneyResults,
      IUser[],
      ILabel[],
      IWebJourneyServices
    ]
    ): void {
    this.folders = folders;
    this.domains = domains;

    this.journeys = journeys;
    this.journey = this.journeys.find(journey => journey.id === this.journeyId);

    this.webJourneyReportService.webJourneySubject.next(this.journey);

    this.runInfo = runInfo;
    this.locations = locations;
    this.results = results;
    this.users = users;
    this.labels = labels;
    this.remainingJourneyFixes = scriptServicesCounts.journeyFixes;

    this.handleNoJourney();

    this.runs = runs;
    this.timeSeriesRuns = this.webJourneyRunsSerializer.serialize(runs);
    this.runPickerRuns = this.webJourneyRunsSerializer.serializeForRunPicker(runs);

    if (this.runPickerRuns && this.runPickerRuns.length) {
      if (this.runPickerRuns.findIndex(r => r.id === this.runId) !== -1) {
        this.run = this.runs.find(r => r.id === this.runId);
        this.selectCalendarRun(this.runPickerRuns);
      } else {
        this.handleNoRun();
        return;
      }
    }

    if (this.journey) {
      this.journeyName = this.journey.name;
      this.folder = this.folders.find(f => f.id === this.journey.folderId);
      this.domain = this.domains.find(f => f.id === this.journey.domainId);

      if (this.folder) this.folderName = this.folder.name;
      if (this.domain) this.domainName = this.getDomainName(this.domain);
    }

    if (!this.loaded && this.journey && this.timeSeriesRuns && this.run) {
      this.handleDataLoaded(this.journey, this.run, this.timeSeriesRuns);
    }

    if (this.journey) {
      this.webJourneyV3Service.getJourneyStatus(this.journey.id).subscribe(status => {
        this.journeyRunning = status.webJourneyRunning;
      });

      this.journey.type = CardTypes.webJourney;
      this.nextCheck = new Date(this.journey.nextCheck);
      this.showPauseIconFlag = this.showPauseIcon();
    }

    // Only update header info if the journey has run
    if (this.runId > 0) {
      this.status = this.webJourneyReportService.getJourneyStatus(this.results);
      this.setJourneyAdvancedStatus();
      this.configureStatusBtn();
      this.createRunInfoDataConfigObject();
    }
  }

  private configureStatusBtn(): void {
    if (this.status.failed) {
      switch (this.status.failureType) {
        case EJourneyFailureType.Action:
          this.journeyStatus.icon = 'cancel';
          this.journeyStatus.text = this.journeyIsAdvanced
            ? `${AdvancedText}${EJourneyStatusText.ActionFailure}`
            : EJourneyStatusText.ActionFailure;
          this.journeyStatus.color = EStatusButtonColor.Orange;
          this.journeyStatus.leadingIconColor = ELeadingIconColor.Orange;
          this.createFailedActionDataObject();
          break;

        case EJourneyFailureType.Rule:
        case EJourneyFailureType.Global:
          this.journeyStatus.icon = 'warning';
          this.journeyStatus.text = this.journeyIsAdvanced
            ? `${AdvancedText}${EJourneyStatusText.RuleFailure}`
            : EJourneyStatusText.RuleFailure;
          this.journeyStatus.color = EStatusButtonColor.Red;
          this.journeyStatus.leadingIconColor = ELeadingIconColor.Red;
          this.createFailedRulesDataObject();
          break;
      }
    } else {
      this.journeyStatus.icon = 'check_circle';
      this.journeyStatus.text = this.journeyIsAdvanced
        ? `${AdvancedText}${EJourneyStatusText.Success}`
        : EJourneyStatusText.Success;
      this.journeyStatus.color = this.journeyIsAdvanced
        ? EStatusButtonColor.Yellow
        : EStatusButtonColor.Green;
      this.journeyStatus.leadingIconColor = ELeadingIconColor.Green;
    }
  }

  private createRunInfoDataConfigObject(): void {
    const totalActionsCount = this.results.actions.length;
    const executedActionsCount = this.results.actions.filter(action => action.success).length;
    const location = this.locations.find(location => location.id === this.runInfo?.locationId);

    this.runInfoPanelData.executedActions = `${executedActionsCount} of ${totalActionsCount}`;
    this.runInfoPanelData.location = location;
    this.runInfoPanelData.runDuration.start = formatDate(new Date(this.run.startedAt), EDateFormats.dateSeventeen);
    this.runInfoPanelData.runDuration.end = formatDate(new Date(this.run.completedAt), EDateFormats.dateSeventeen);
    this.runInfoPanelData.runDuration.duration = this.formatRunDuration(runDuration(new Date(this.run.startedAt), new Date(this.run.completedAt)));
    this.runInfoPanelData.browserVersion = `${this.runInfo?.browserName}, Version ${this.runInfo?.browserVersion}`;
    this.runInfoPanelData.dataLayer = this.domain.dataLayer || '--';
    this.runInfoPanelData.vpn = this.runInfo?.vpnEnabled ? 'Enabled' : 'Not Configured';
    this.runInfoPanelData.runId = this.run.id;
    this.runInfoPanelData.userAgent = this.runInfo?.userAgent;
    this.runInfoPanelData.viewport = `${this.runInfo?.width} x ${this.runInfo?.height}`;
    this.runInfoPanelData.folderDomain = `${this.folderName} / ${this.domainName}`;
    this.runInfoPanelData.runFrequency = this.reportCardService.webJourneyFrequencies[this.journey?.frequency];
    this.runInfoPanelData.journeyId = this.journeyId;
    this.runInfoPanelData.creator = this.getUserDisplayName(this.journey?.userId);
    this.runInfoPanelData.labels = this.labels.map(label => label.name).join(', ') || '--';
    this.runInfoPanelData.monitoredJourney = this.journey?.monitoredByScriptServices;
    this.runInfoPanelData.runInfoExists = !!this.runInfo;
    this.runInfoPanelData.customProxy = this.runInfo?.customProxy;

    this.loadingRunInfo = false;
  }

  private formatRunDuration(d: Duration): string {
    let duration = '';

    if (d.years) duration += `${d.years} year${d.years > 1 ? 's' : ''} `;
    if (d.months) duration += `${d.months} month${d.months > 1 ? 's' : ''} `;
    if (d.days) duration += `${d.days} day${d.days > 1 ? 's' : ''} `;
    if (d.hours) duration += `${d.hours} hour${d.hours > 1 ? 's' : ''} `;
    if (d.minutes) duration += `${d.minutes} min. `;
    if (d.seconds) duration += `${d.seconds} sec.`;

    return duration;
  }

  private getUserDisplayName(id: number): string {
    const user = this.users.find(user => user.id === id);
     return user?.firstName && user?.lastName
      ? `${user?.firstName} ${user?.lastName}`
      : user?.username;
  }

  private setJourneyAdvancedStatus(): void {
    // reset so we don't get duplicates between runs
    this.advancedJourneyFeatures = [];

    if (this.runInfo?.fileSubstitutionConfigured) this.advancedJourneyFeatures.push(EAdvancedJourneyFeatures.FileSubstitution);
    if (this.runInfo?.gpcEnabled) this.advancedJourneyFeatures.push(EAdvancedJourneyFeatures.GpcSignal);
    if (this.runInfo?.vpnEnabled) this.advancedJourneyFeatures.push(EAdvancedJourneyFeatures.Vpn);
    if (this.runInfo?.technologyBlockingEnabled) this.advancedJourneyFeatures.push(EAdvancedJourneyFeatures.TechnologyBlocking);
    if (this.runInfo?.privacyConsentActions) this.advancedJourneyFeatures.push(EAdvancedJourneyFeatures.PrivacyConsentActions);
    if (this.runInfo?.blockThirdPartyCookies) this.advancedJourneyFeatures.push(EAdvancedJourneyFeatures.BlockThirdPartyCookies);
    if (this.runInfo?.customProxy) this.advancedJourneyFeatures.push(EAdvancedJourneyFeatures.CustomProxy);

    this.journeyIsAdvanced = !!this.advancedJourneyFeatures.length;
  }

  private createFailedRulesDataObject(): void {
    let failedActionRulesCount = 0;
    let failedGlobalRulesCount = this.results.failedRuleReports.length;
    const failedRules = [];

    this.results.actions.forEach((action, index) => {
      if (action.failedRuleReports.length) {
        failedRules.push({
          actionName: action.action.label,
          actionIndex: index + 1,
          failedCount: action.failedRuleReports.length
        });

        failedActionRulesCount += action.failedRuleReports.length;
      }
    });

    this.failedRulesData = {
      failedActionRulesCount,
      failedActionRules: failedRules,
      failedGlobalRulesCount,
      totalFailedRulesCount: failedActionRulesCount + failedGlobalRulesCount
    };
  }

  private createFailedActionDataObject(): void {
    const firstFailingAction = this.results.actions.find(action => !action.success);
    this.failedActionData = {
      actionIndex: this.results.actions.length ? this.results.actions.indexOf(firstFailingAction) + 1 : 1,
      failureReason: firstFailingAction?.failureMessage,
      showFixJourneySpinner: false,
      fixRequested: false,
      outOfFixes: this.isFixJourneyDisabled()
    }
  }

  private getDomainName(domain: IDomain): string {
    return domain.name || domain.domain.replace(/(^\w+:|^)\/\//, '').split('/')[0];
  }

  private handleDataLoaded(journey: IWebJourneyV3Card, run: IWebJourneyRun, runs: ITimeSeriesRuns[]): void {
    this.loaded = true;
    this.journeyName = journey.name;

    this.date = (runs[0]?.values?.length > 0 && run.completedAt) ?
      formatDate(new Date(run.completedAt), EDateFormats.dateFifteen) :
      'Not yet run';

    if (runs && typeof run == 'undefined') {
      this.date = 'Select...';
    }
  }

  private selectCalendarRun(runs: IRunPickerRun[]): void {
    runs.forEach(run => {
      run.selected = this.runId == run.id;
    });
  }

  onClickRun(run: IRunPickerRun): void {
    this.popover.closePopover();
    this.updateRun(run.id);
  }

  updateRun(id: number): void {
    const urlTree = this.router.parseUrl(this.router.url);
    const segments = RouteDataService.getUrlSegments(urlTree);
    this.router.navigateByUrl(WebJourneyReportUrlBuilders.base(this.journeyId, id) + '/' + segments.pop());
  }

  closeReport(): void {
    this.webJourneyReportService.closeReport(this.journeyId);
  }

  handleJumpToAction(actionIndex: number): void {
    this.journeyInfoTrigger.nativeElement.click();
    this.webJourneyReportService.webJourneyJumpToActionSubject.next(actionIndex);
  }

  handleJumpToGlobalRules(): void {
    this.journeyInfoTrigger.nativeElement.click();
    this.router.navigateByUrl(WebJourneyReportUrlBuilders.rules(this.journeyId, this.runId));
  }

  handleRequestJourneyFix(): void {
    this.requestJourneyFix();
  }

  requestJourneyFix(): void {
    if (!this.remainingJourneyFixes) return;

    let modalRef = this.modalServiceNg.openConfirmModal({
      data: {
        title: 'Fix Journey',
        messages: [
          `You have ${
            this.remainingJourneyFixes > 0 ? this.remainingJourneyFixes : 'unlimited'
          } journey fix${this.remainingJourneyFixes === 1 ? '' : 'es'} left. This action will use one.`,
          `Do you want to continue?`
        ],
        rightFooterButtons: [
          {
            label: 'No', action: () => {
              modalRef.close();
            },
            primary: false,
            opSelector: 'no-fix-journey'
          },
          {
            label: 'Yes', action: () => {
              this.sendFixJourneyRequest();
            },
            primary: true,
            opSelector: 'fix-journey'
          }
        ]
      }
    });
  }

  isFixJourneyDisabled(): boolean {
    return this.remainingJourneyFixes === 0;
  }

  sendFixJourneyRequest(): void {
    this.failedActionData.showFixJourneySpinner = true;
    this.webJourneyAPIService.fixJourney(this.journeyId, this.runId)
      .then(
        success => {
          this.failedActionData.showFixJourneySpinner = false;
          this.failedActionData.fixRequested = true;
          this.snackbarService.openSuccessSnackbar('Your request to fix this journey has been received.');
        },
        error => {
          this.failedActionData.showFixJourneySpinner = false;
          this.failedActionData.fixRequested = false;
          this.snackbarService.openErrorSnackbar('An unknown error occurred while requesting this fix. Please try again.');
        }
      );
  }
}
