import { Subject } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IButton } from '@app/models/commons';
import { IRuleSetupTab } from './rule-setup.models';
import { IRule } from '@app/components/rules/rules.models';
import { RuleSetupOpSelectors } from './rule-setup.constants';
import { ERuleSetupMode, ERuleSetupStep } from './rule-setup.enums';
import { RuleSetupFormBuilderService } from './services/rule-setup-form-builder.service';
import { RuleSetupDataSerializationService } from './services/rule-setup-data-serialization.service';
import { RuleSetupFormUtilsService } from './services/rule-setup-form-utils.service';
import { RulesService } from '../rules.service';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-setup',
  templateUrl: './rule-setup.component.html',
  styleUrls: ['./rule-setup.component.scss']
})
export class RuleSetupComponent implements OnInit {

  @Input() mode: ERuleSetupMode;
  @Input() hidePageMatching: boolean;
  @Input() ruleId?: number;
  @Input() rule?: Partial<IRule>;

  @Output() onClosed = new EventEmitter<IRule>();

  ERuleSetupStep = ERuleSetupStep;
  readonly tabs: IRuleSetupTab[] = [
    { name: 'General', path: ERuleSetupStep.General },
    { name: 'Conditions', path: ERuleSetupStep.Conditions }
  ];

  rightFooterButtons: IButton[] = [
    {
      label: 'General',
      icon: 'icon-back-empty',
      action: () => this.goToStep(ERuleSetupStep.General),
      disabled: false,
      hidden: true,
      opSelector: RuleSetupOpSelectors.backBtn,
    },
    {
      label: 'Continue',
      icon: 'icon-forward-empty',
      action: () => this.goToStep(ERuleSetupStep.Conditions),
      disabled: false,
      hidden: false,
      opSelector: RuleSetupOpSelectors.continueBtn,
    },
    {
      label: 'Save Rule',
      action: () => this.save(),
      disabled: false,
      hidden: true,
      opSelector: RuleSetupOpSelectors.saveBtn,
      primary: true
    },
  ];

  currentStep = ERuleSetupStep.General;
  conditionsLoaded = false;
  modalTitle = '';

  ruleSetupForm: UntypedFormGroup;

  originalRule: IRule;
  allLabels: ILabel[];
  allTags: IUiTag[];

  destroy$ = new Subject<void>();

  constructor(private formUtilsService: RuleSetupFormUtilsService,
    private formBuilderService: RuleSetupFormBuilderService,
    private dataSerializationService: RuleSetupDataSerializationService,
    private rulesService: RulesService,
    private labelService: LabelService,
    private uiTagService: UiTagService,
    private dateService: DateService,
  ) { }

  ngOnInit(): void {
    this.ruleSetupForm = this.formBuilderService.buildEmptyForm();
    this.fetchData();
  }

  private async fetchData() {
    this.labelService.getLabels().subscribe(labels => {
      this.allLabels = labels;
    });

    this.uiTagService.getAllTagsData().subscribe(([tags]) => {
      this.allTags = ArrayUtils.sortBy(tags, 'name');
    });

    if (this.ruleId) {
      this.originalRule = await this.rulesService.getRule(this.ruleId).toPromise();
      const rule = this.mode === ERuleSetupMode.copy
        ? {
          ...this.originalRule,
          name: `${this.originalRule.name} copied ${this.dateService.formatDate(new Date(), EDateFormats.dateTwo)}`
        }
        : this.originalRule;
      this.formBuilderService.buildFilledForm(rule, this.ruleSetupForm);
    } else if (this.rule) {
      this.formBuilderService.buildFilledForm(this.rule, this.ruleSetupForm);
    }

    this.modalTitle = this.getModalTitle();
  }

  private getModalTitle(): string {
    switch (this.mode) {
      case ERuleSetupMode.edit: return `Edit Rule - ${this.originalRule.name}`;
      case ERuleSetupMode.copy: return `Copy Rule - ${this.originalRule.name}`;
      default: return 'Create Rule';
    }
  }

  goToStep(stepId: ERuleSetupStep): void {
    const activeFormGroup = this.currentStep === ERuleSetupStep.General ? this.generalGroup : this.conditionsGroup;
    if (activeFormGroup.invalid) {
      this.formUtilsService.markGroupedControlsTouched(activeFormGroup);
      return;
    }

    if (stepId === ERuleSetupStep.Conditions) {
      this.conditionsLoaded = true;
    }

    this.currentStep = stepId;
    this.updateFooterButtonVisibility(stepId);
  }

  private updateFooterButtonVisibility(stepId: ERuleSetupStep): void {
    const [backBtn, continueBtn, saveBtn] = this.rightFooterButtons;
    if (stepId === ERuleSetupStep.General) {
      backBtn.hidden = saveBtn.hidden = true;
      continueBtn.hidden = false;
    } else {
      backBtn.hidden = saveBtn.hidden = false;
      continueBtn.hidden = true;
    }
  }

  createLabel(labelName: string) {
    this.labelService.createLabel(labelName).subscribe(label => {
      const oldLabelsList = this.labelsControl.value;
      this.labelsControl.setValue([...oldLabelsList, label]);
    });
  }

  get generalGroup(): UntypedFormGroup {
    return this.ruleSetupForm.get('general') as UntypedFormGroup;
  }

  get conditionsGroup(): UntypedFormGroup {
    return this.ruleSetupForm.get('conditions') as UntypedFormGroup;
  }

  private get labelsControl(): UntypedFormControl {
    return this.generalGroup.get('labels') as UntypedFormControl;
  }

  /* Save */
  private save() {
    this.formUtilsService.markGroupedControlsTouched(this.ruleSetupForm);
    if (this.ruleSetupForm.invalid) return;

    const isEdit = this.mode === ERuleSetupMode.edit;
    const ruleRequest = this.dataSerializationService.formDataToSaveRequest(this.ruleSetupForm.value, isEdit ? this.ruleId : null);
    const saveRule$ = isEdit
      ? this.rulesService.updateRule(ruleRequest)
      : this.rulesService.createRule(ruleRequest);

    saveRule$.subscribe(savedRule => {
      this.onClosed.emit(savedRule as IRule);
    });
  }

}
