import { Names } from '@app/moonbeamConstants';

export const manualJourneyRecorderDirective = function () {
    return {
      restrict: 'E',
      controller: Names.Controllers.manualJourneyRecorder,
      controllerAs: 'ctrl',
      scope: {},
      bindToController: true,
      template: '<full-screen-view></full-screen-view>'
    };
  };
