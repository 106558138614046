import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IFailedResponse } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.models';

@Injectable()
export class BulkActionProgressService {

  private barStepCount: number = 0;
  private maxCount: number = 100;
  private itsDone: boolean = false;
  private readonly MAX_RETRY_ATTEMPTS: number = 18000; // 18,000 retries / 5-10 (jitter) second delay === 8-24 hours of continuous retries (allow service to recover for big batch jobs)
  private isRetrying: boolean = false;
  private failures: IFailedResponse[] = [];
  private setupIsReady: boolean = false;

  // These subjects must be initialized for unit tests to pass.
  private barProgress$: Subject<number> = new Subject();
  private cancel$: Subject<number> = new Subject();
  private retry$: Subject<number> = new Subject(); // When retrying to connect to backend service that is down. Keep the user apprised.
  private apiRateLimited$ = new BehaviorSubject<boolean>(false);

  // The reInit() function is used by the progressbar to handle cancelling and retrying to connect to backend service that is down. Keep the user apprised.
  reInit() {
    this.resetProgress();
    this.resetCancel()
    this.resetRetry();
    this.itsDone = false;
    this.resetFailures();
  }
  setSetupIsReady() {
    this.setupIsReady = true;
  }
  getSetupIsReady(): boolean {
    return this.setupIsReady;
  }

  publishProgressbar(num: number) {
    this.barStepCount = num;
    if(this.barProgress$) this.barProgress$.next(num);
  }
  subscribeProgressbar(): Observable<number> {
    return this.barProgress$.asObservable();
  }
  getProgressbarCount(): number {
    return this.barStepCount;
  }
  resetProgress() {
    this.barProgress$ = new Subject();
  }
  setMaxCount(num: number) {
    this.maxCount = num;
  }
  getMaxCount(): number {
    return this.maxCount;
  }

  // Progress status functions
  setProcessCompleted() {
    this.itsDone = true;
    if(this.barProgress$) {
      this.barProgress$.next();
      this.barProgress$.complete();
    }
    this.cancelIt();
    this.stopRetrying();
    this.resetFailures();
    this.resetApiRateLimited();
  }
  getProcessCompleted(): boolean {
    return this.itsDone;
  }

  // Retry functions
  publishRetry(num: number) {
    if (this.retry$ && !this.retry$.closed && !this.retry$.isStopped) {
      this.retry$.next(num);
      this.isRetrying = true;
    } else {
      this.resetRetry();
    }
  }
  getRetry(): Observable<number> {
    return this.retry$.asObservable();
  }
  getIsRetrying(): boolean {
    return this.isRetrying;
  }
  stopRetrying() {
    if (this.retry$ && !this.retry$.closed && !this.retry$.isStopped) {
      this.retry$.next(0);
      this.retry$.complete();
    }
    this.isRetrying = false;
  }
  getMaxRetryAttempts(): number {
    return this.MAX_RETRY_ATTEMPTS;
  }
  resetRetry() {
    this.isRetrying = false;
    this.retry$ = new Subject();
    this.apiRateLimited$ = new BehaviorSubject<boolean>(false);
  }

  setApiRateLimited(value: boolean) {
    if (this.apiRateLimited$ && !this.apiRateLimited$.closed && !this.apiRateLimited$.isStopped) {
      this.apiRateLimited$.next(value);
      this.isRetrying = value;
    } else {
      this.resetApiRateLimited();
    }
  }
  getApiRateLimited(): Observable<boolean> {
    if(this.apiRateLimited$) {
      return this.apiRateLimited$.asObservable();
    }
  }
  resetApiRateLimited(): void {
    if (this.apiRateLimited$ && !this.apiRateLimited$.closed && !this.apiRateLimited$.isStopped) {
      this.apiRateLimited$.next(false);
      this.isRetrying = false;
    }
    this.apiRateLimited$ = new BehaviorSubject<boolean>(false);
  }

  // Track failures during bulk actions
  getFailures(): IFailedResponse[] {
    return this.failures;
  }
  setFailure(failure: IFailedResponse) {
    this.failures?.push(failure);
  }
  resetFailures() {
    this.failures = [];
  }

  getCancel(): Observable<number> {
    return this.cancel$.asObservable();
  }
  resetCancel(): void {
    this.cancel$ = new Subject();
  }
  cancelIt() {
    if (this.cancel$ && !this.cancel$.closed && !this.cancel$.isStopped) {
      this.cancel$.next(0);
      this.cancel$.complete();
    }
  }
}
