import { SharedModule } from '@app/components/shared/shared.module';
import { NgModule } from '@angular/core';
import { OpTagPickerComponent } from './op-tag-picker.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    FormsModule,
    NgSelectModule,
    SharedModule,
  ],
  declarations: [
    OpTagPickerComponent
  ],
  exports: [
    OpTagPickerComponent
  ],
})
export class OpTagPickerModule { }
