import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { ConditionsMatchers } from '../rule-setup-conditions-tab.constants';
import { RuleSetupFormBuilderService } from '@app/components/rules/rule-setup/services/rule-setup-form-builder.service';
import { IUiTag } from '@app/components/tag-database';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'then-conditions',
  templateUrl: './then-conditions.component.html',
  styleUrls: ['./then-conditions.component.scss']
})
export class ThenConditionsComponent {

  @Input() formData: UntypedFormGroup;
  @Input() allTags: IUiTag[];

  ConditionsMatchers = ConditionsMatchers;

  constructor(private formBuilderService: RuleSetupFormBuilderService) {}

  addMainCondition() {
    this.formBuilderService.addThenCondition(this.filtersArray);
  }

  removeMainCondition(index: number) {
    this.formBuilderService.removeMainCondition(index, this.filtersArray);
  }

  get matchAllFiltersControl(): UntypedFormControl {
    return this.formData?.get('matchAllFilters') as UntypedFormControl;
  }

  get filtersGroup(): UntypedFormGroup {
    return this.formData?.get('filters') as UntypedFormGroup;
  }

  get filtersArray(): UntypedFormArray {
    return this.filtersGroup?.get('Then') as UntypedFormArray;
  }

}
