import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'send-report-success-snackbar',
  templateUrl: './send-report-success-snackbar.component.html',
  styleUrls: ['./send-report-success-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendReportSuccessSnackbarComponent {

  emails: string[];

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { emails: string[] }) {
    this.emails = data.emails;
  }

}