import { AngularNames } from '@app/moonbeamConstants';
import { IWebsocketService, WebSocketService } from './websocketService';
import * as angular from 'angular';

export interface IWebsocketProvider {
  connection(url: string, protocols: string[]): IWebsocketService;
}

export class WebsocketProviderService implements IWebsocketProvider {

  private pool = new Map<string, IWebsocketService>();

  static $inject = [
    AngularNames.websocket
  ];

  constructor(private $websocket: angular.websocket.IWebSocketProvider) {
  }

  public connection(url: string, protocols: string[] = []): IWebsocketService {
    if (this.pool[url]) return this.pool[url];
    var conn: IWebsocketService = new WebSocketService(this.$websocket(url, protocols));
    conn.onClose((event) => {
      delete this.pool[url];
    });
    return (this.pool[url] = conn);
  }
}

