import { Component, Optional, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { IRuleSelection } from '../rule-selector/rule-selector.models';
import { IRuleSelectorModalPayload } from './rule-selector-modal.models';
import { OpModalComponent } from '@app/components/shared/components/op-modal';
import { OpModalService } from '@app/components/shared/components/op-modal/shared/op-modal.service';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { IRule, IRulePreview } from '@app/components/rules/rules.models';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-selector-modal',
  templateUrl: './rule-selector-modal.component.html',
  styleUrls: ['./rule-selector-modal.component.scss']
})
export class RuleSelectorModalComponent {

  rules: IRulePreview[] = [];
  labels: ILabel[] = [];
  selectedRules: IRuleSelection;
  rightFooterButtons: IButton[] = [{
    label: 'Assign',
    action: this.assignRules.bind(this),
  }];

  @ViewChild(OpModalComponent) opModal: OpModalComponent;

  constructor(
    @Optional() private dialogRef: MatDialogRef<RuleSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public payload: IRuleSelectorModalPayload,
    private modalService: OpModalService,
    private labelService: LabelService
  ) {
    this.rules = payload.rules;
    this.labels = payload.labels;
    this.selectedRules = {
      selectedRuleIds: payload.selectedRules,
      selectedItems: []
    };
  }

  assignRules() {
    this.close(this.selectedRules);
  }

  close(selection?: IRuleSelection): void {
    this.dialogRef && this.dialogRef.close(selection);
  }

  openRuleCreation() {
    this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.create
      }
    }, 'rule-setup-modal')
    .afterClosed()
    .subscribe(rule => this.onCreateRule(rule));
  }

  async onCreateRule(rule: IRule) {
    if (rule) {
      if (rule.labels) {
        this.labelService.getLabels().subscribe(labels => {
          this.labels = labels;
        });
      }

      this.rules = [...this.rules, rule];
      this.selectedRules = {
        selectedRuleIds: [...this.selectedRules.selectedRuleIds, rule.id],
        selectedItems: [...this.selectedRules.selectedItems, { rule: rule }]
      };
    }
  }

  openRuleEditor(rule: IRulePreview) {
    this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.edit,
        ruleId: rule.id
      }
    }, 'rule-setup-modal')
    .afterClosed()
    .subscribe(rule => this.onEditRule(rule));
  }

  async onEditRule(rule: IRule) {
    if (rule) {
      const updatedRule = this.rules.find(r => r.id === rule.id);

      // update all labels in case new ones were added
      if (rule.labels) {
        this.labelService.getLabels().subscribe(labels => {
          this.labels = labels;
        });
      }

      // update the labels in case they have changed after being edited
      updatedRule.labels = rule.labels;

      // update the name in case it has changed after being edited
      updatedRule.name = rule.name;
    }
  }
}
