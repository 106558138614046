import { Component, OnInit } from '@angular/core';
import { ApplicationChromeService } from '../core/services/application-chrome.service';
import { AuthenticationStorageService } from '../core/services/authentication-storage.service';
import { AuthenticationService } from '../core/services/authentication.service';
import { StorageService } from '../shared/services/storage.service';
import { ThemeService } from '@app/services/theme-service/theme.service';
import { takeUntil } from 'rxjs/operators';
import { createCustomTheme } from '@mobiscroll/angular';

// MAIN IS *THE* ENTRY POINT FOR THE APP (see AppModule.ngDoBootstrap)
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  darkTheme: boolean;

  constructor(private authenticationService: AuthenticationService,
              private authenticationStorageService: AuthenticationStorageService,
              private storageService: StorageService,
              private themeService: ThemeService,
              private applicationChromeService: ApplicationChromeService) { }

  ngOnInit() {
    this.loadSessionDataFromCookies();
    this.applicationChromeService.init();
    // createCustomTheme('op-theme', 'material');

    this.themeService.isDarkTheme.subscribe(dark => {
      this.darkTheme = dark;
      // createCustomTheme('op-theme-dark', 'material');
    });

    // When printing, set theme to light if currently in dark.
    window.onbeforeprint = () => {
      if (this.darkTheme) {
        document.body.classList.remove('dark-theme');
      }
    };

    // Return to dark when done printing if currently in dark.
    window.onafterprint = () => {
      if (this.darkTheme) {
        document.body.classList.add('dark-theme');
      }
    };
  }

  // load existing session data from cookies (so user doesn't have to login on 2nd tab)
  private loadSessionDataFromCookies() {
    // check for cookie - if it exists, set it using storage service
    // cookies should be present when redirecting back from a 3rd party IDP
    // This code is required for SSO login to work properly
    const cookieInfo = this.authenticationService.readAuthInformationFromCookies();
    const isLoggedInAsAnother = this.storageService.isLoggedInAsAnother();
    const isVisitorUser = this.storageService.isVisitorUser();

    if (cookieInfo && !isLoggedInAsAnother && !isVisitorUser) {
      this.authenticationStorageService.set({
        token: cookieInfo.accessKey,
        id: cookieInfo.userId,
        expiresAt: cookieInfo.expiration,
        credentialsExpired: false,
        tokenExpired: false
      });
    }
  }
}
