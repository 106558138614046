import {
  ChartClassName,
  ChartType,
  EChartType,
  IChartsConfig
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import {
  attachBarChartToSvg,
  hide,
  nearestDataPointCoordinates
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import { EDateFormats, formatDate, toUTC } from '@app/components/date/date.service';
import * as d3 from 'd3';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import {
  createTooltip,
  drawTooltip,
  getChartData
} from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';
import { IUsageTrendsDTO } from '@app/components/usage-v2/usage-v2.models';
import {
  getActualUsageToggleBtn,
} from '@app/components/usage-v2/components/usage-chart/datasets/past-periods/utils/toggle-buttons';
import {
  getProjectedUsageToggleBtn
} from '@app/components/usage-v2/components/usage-chart/datasets/future-periods/utils/toggle-buttons';

export function addCurrentPeriodChartToConfig(trends: IUsageTrendsDTO, config: IChartsConfig, chartType: EChartType, displayedCharts): void {
  if (!trends.currentPeriod) {
    return;
  }
  if (displayedCharts.blueBars) {
    config.charts[ChartType.currentPeriodPeriodTotal] = {
      data: getChartData([trends.currentPeriod], 'periodTotal.total'),
      chartClassName: ChartClassName.pastPeriods,
      toggleBtn: getActualUsageToggleBtn(trends, chartType),
    };
  }
  if (displayedCharts.greyBars) {
    config.charts[ChartType.currentPeriodPeriodProjectedTotal] = {
      data: getChartData([trends.currentPeriod], 'periodProjectedTotal.scheduledUsageEstimate.total'),
      chartClassName: ChartClassName.futurePeriods,
      toggleBtn: getProjectedUsageToggleBtn(chartType, false),
    };
  }
}

export function drawCurrentPeriodPeriodProjectedTotalChart(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
  windowWidth: number,
  circleClass: string,
  tooltipClass: string,
  animationEnabled: boolean = true,
): void {
  const currentPeriodTotal = config.charts[ChartType.currentPeriodPeriodTotal];
  const currentPeriodPeriodProjectedTotal = config.charts[ChartType.currentPeriodPeriodProjectedTotal];

  if (!currentPeriodTotal) {
    return;
  }

  attachBarChartToSvg({
      data: currentPeriodTotal.data,
      x: xAxis,
      y: yAxis,
      height: height - margin.top - margin.bottom,
      containerClassName: `current-period period-total ${currentPeriodTotal.chartClassName}`,
      barClassName: 'period-total-bar',
      barLabelTextFormatterFn: VizUtilsService.formatChartNumbers,
      animationDuration: animationEnabled ? 750 : 0,
    },
    svg)
    .on('mouseenter', (e) => {
      const {
        x,
        y,
        dataPoint
      } = nearestDataPointCoordinates(e, svg.node(), currentPeriodTotal.data, xAxis, yAxis);

      const tooltip = createTooltip(tooltipClass);

      drawTooltip(
        svg,
        '<div class="usage-chart-tooltip-body">' +
        '<h3 class="title">ACTUAL USAGE' + '<br>' +
        'IN ' +
        formatDate(toUTC(new Date(dataPoint.date)), EDateFormats.dateEight) +
        '</h3>' +
        '<div class="value">' +
        '<span class="value-data">' + VizUtilsService.formatChartNumbers(dataPoint.value) + '</span>' +
        '</div>' +
        '</div>',
        x,
        y,
        null,
        tooltip,
        windowWidth,
      );
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
    });

  const stackedOnData = currentPeriodTotal.data;
  const cleanHeight = height - margin.top - margin.bottom;

  attachBarChartToSvg({
      data: currentPeriodPeriodProjectedTotal.data,
      stackedOnData,
      x: xAxis,
      y: yAxis,
      height: cleanHeight,
      containerClassName: `period-projected-total ${currentPeriodPeriodProjectedTotal.chartClassName}`,
      barClassName: 'period-projected-total-bar',
      barLabelClassName: `bar-label`,
      barLabelTextFormatterFn: VizUtilsService.formatChartNumbers,
      animationDuration: animationEnabled ? 750 : 0,
    },
    svg)
    .on('mouseenter', (e) => {
      const {
        x,
        y,
        dataPoint,
      } = nearestDataPointCoordinates(e, svg.node(), currentPeriodPeriodProjectedTotal.data, xAxis, yAxis);

      const stackedDataPoint = nearestDataPointCoordinates(e, svg.node(), stackedOnData, xAxis, yAxis).dataPoint;
      const stackedTooltipY = y - (cleanHeight - yAxis(stackedDataPoint.value));

      const tooltip = createTooltip(tooltipClass);

      drawTooltip(
        svg,
        '<div class="usage-chart-tooltip-body">' +
        '<h3 class="title">SCHEDULED MONTHLY USAGE' + '<br>' +
        'IN ' +
        formatDate(toUTC(new Date(dataPoint.date)), EDateFormats.dateEight) +
        '</h3>' +
        '<div class="value">' +
        '<span class="value-data">' + VizUtilsService.formatChartNumbers(dataPoint.value) + '</span>' +
        '</div>' +
        '</div>',
        x,
        stackedTooltipY,
        null,
        tooltip,
        windowWidth,
      );
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
    });
}
