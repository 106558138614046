import { Injectable } from '@angular/core';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { Subscription } from 'rxjs';
import { IModalService } from '@app/components/modals/modalService';
import { Router } from '@angular/router';
import {
  InvalidUrlsSnackbarComponent,
} from '@app/components/invalid-urls-snackbar/invalid-urls-snackbar.component';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable()
export class InvalidUrlsSnackbarService {

  private timeoutMs: number = 60000 * 60;

  private activeToast: MatSnackBarRef<any>;
  private afterAnyModalOpenedSubscription: Subscription;

  constructor(
    private snackbar: MatSnackBar,
    private opModalService: OpModalService,
    private modalService: IModalService,
    private router: Router
  ) {
    this.router.events.subscribe(e => this.closeIfPresent());
    this.modalService.addOnShowHandler(() => this.closeIfPresent());
    this.afterAnyModalOpenedSubscription = this.opModalService
      .afterModalOpened()
      .subscribe(() => this.closeIfPresent());
  }

  showInvalidUrls(urls: string[]): MatSnackBarRef<any> {
    this.closeIfPresent();
    const activeToast = this.snackbar.openFromComponent(InvalidUrlsSnackbarComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: urls
    });
    this.activeToast = activeToast;
    return activeToast;
  }

  private closeIfPresent() {
    if (this.activeToast) {
      this.activeToast.dismiss();
      this.activeToast = undefined;
    }
  }
}
