<form [formGroup]="folderAndSubfolderForm"
      formName="folderAndSubfolderForm"
      class="folder-and-subfolder-form"
      validate-form
>
  <div
     #selectFolder
     *ngIf="!loading"
     (mouseleave)="hideHint(selectFolder)"
     (mouseenter)="displayHint(selectFolder)">
    <mat-form-field appearance="outline"
                    class="no-hint-container"
                    floatLabel="always"
                    [attr.op-selector]="'create-folder-and-subfolder-field'"
    >
      <mat-label>{{this.folderSelectPlaceholder}}</mat-label>
      <input type="text"
             placeholder="{{folders.length > 0 ? 'Select or create new folder/sub-folder' : 'Create folder'}}"
             aria-label="Select or create new folder/sub-folder"
             matInput
             [formControl]="subFolderSearch"
             [matAutocomplete]="auto">
      <mat-icon class="arrow-icon">arrow_drop_down</mat-icon>
      <mat-autocomplete #auto="matAutocomplete"
                        (opened)="onSubfolderSelectOpened()"
                        (closed)="onSubfolderSelectClosed()"
                        (optionSelected)="onSubfolderChange($event)"
                        [displayWith]="displayFn.bind(this)"
      >
        <!-- Display option when creating a new folder & creating a new sub-folder -->
        <mat-optgroup *ngIf="folderMode === EFolderMode.Create"
                      [label]="tempCreatedFolderName + ' (new)'"
        >
          <mat-option [value]="tempCreatedSubFolderName">
            <ng-container *ngIf="!hideFolderName">{{tempCreatedFolderName}} / </ng-container> {{tempCreatedSubFolderName}} (new)</mat-option>
        </mat-optgroup>
        <!-- Display options for all other existing folders filtered by autocomplete -->
        <mat-optgroup *ngFor="let filteredFolder of filteredFolders|async" [label]="filteredFolder.name">
          <!-- Option for empty folder -->
          <mat-option class="no-subfolder-option" disabled *ngIf="subFoldersByFolderId[filteredFolder.id]?.length === 0">No subfolders</mat-option>
          <!-- Options for existing sub-folders in the filteredFolder -->
          <mat-option *ngIf="folderMode === EFolderMode.Select && subFolderMode === ESubFolderMode.Create && folderMap[filteredFolder.id].name === folder.value?.name" [value]="subFolder.value"><ng-container *ngIf="!hideFolderName">{{folderMap[filteredFolder.id].name}} / </ng-container>{{subFolder.value}} (new)</mat-option>
          <!-- When NOT filtering, use this mapping for ALL subfolders -->
          <ng-container *ngIf="filterValue.length === 0">
            <mat-option *ngFor="let subfolder of subFoldersByFolderId[filteredFolder.id]" [value]="subfolder">
              <ng-container *ngIf="!hideFolderName">{{folderMap[subfolder.folderId].name}} / </ng-container>{{subfolder?.name}}
            </mat-option>
          </ng-container>
          <!-- When filtering, use this mapping for FILTERED subfolders -->
          <ng-container *ngIf="filterValue.length > 0 && filteredSubFoldersByFolderId[filteredFolder.id]?.length > 0">
            <mat-option *ngFor="let subfolder of filteredSubFoldersByFolderId[filteredFolder.id]" [value]="subfolder">
              <ng-container *ngIf="!hideFolderName">{{folderMap[subfolder.folderId].name}} / </ng-container>{{subfolder?.name}}
            </mat-option>
          </ng-container>
        </mat-optgroup>
      </mat-autocomplete>
    </mat-form-field>
    <op-error-message formControlPath="folderAndSubfolderForm.folder"></op-error-message>
    <div [class.extra-hint-margin]="folderAndSubfolderForm.controls.subFolder.touched && folderAndSubfolderForm.controls.subFolder.invalid"
         class="nested-group-subtext field-hint"
         *ngIf="(!folderAndSubfolderForm.controls.subFolder.invalid && !folderAndSubfolderForm.controls.folder.invalid) || subfolders.length === 0"
    >
      Select existing folder and sub-folder that this {{itemType === EDataSourceType.Audit ? 'audit' : 'web-journey'}} should be placed in, or&nbsp;
      <span>
        <button class="subtext-link" type="button" (click)="createFolderOrSubfolder($event)">create a new folder/sub-folder</button>
      </span>. <a class="learn-more-link" href="https://help.observepoint.com/en/articles/9095470-create-and-manage-a-folder" target="_blank">Learn more.</a>
    </div>
  </div>
</form>
