<op-filter-bar [menuItems]="filterBarMenuItems"
               [service]="filterBarService"
               [isSearchByTextEnabled]="true"
               [validFilterTypes]="validFilterTypes"
               [hideSearchByRegexOption]="true"
               [keepWide]="true"
               [enableTypeahead]="true"
               searchByTextPlaceholderSuffix=""
               (searchByTextIsEntered)="handleSearchBy($event)">
</op-filter-bar>
<div class="filter-bar-search-hint">Search by username, first & last name, company, email, and account ID</div>
