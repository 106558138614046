import { EAuditReportFilterTypes, EJSFileChangeType } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { ChangeType } from './js-file-changes.models';
import { CommonReportsPagesFiltersConfig } from '@app/components/audit-reports/reports/general-reports.constants';

export const JsFileChangesRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,
  EAuditReportFilterTypes.TagVendorId,
  EAuditReportFilterTypes.TagPrimaryTagsOnly,
  EAuditReportFilterTypes.TagId,
  EAuditReportFilterTypes.TagCategory,
  EAuditReportFilterTypes.TagAnyRelated,
  EAuditReportFilterTypes.JSFilesFilename,
  EAuditReportFilterTypes.JSFilesSizeDifference,
  EAuditReportFilterTypes.JSFilesDateDifference,
  EAuditReportFilterTypes.JSFilesChangeType,
];

export const JsFileChangesTypeOptions = new Map([
  [ EJSFileChangeType.newFile, ChangeType.new_file ],
  [ EJSFileChangeType.notChanged, ChangeType.not_changed ],
  [ EJSFileChangeType.changedFile, ChangeType.changed_file ],
  [ EJSFileChangeType.deletedFile, ChangeType.deleted_file ],
  [ EJSFileChangeType.unknown, ChangeType.unknown ]
]);
