import { Component, EventEmitter, Input, Output } from '@angular/core';
import { INotAppliedRuleReport } from '@app/components/reporting/rules/rule.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'not-applied-rules-report',
  templateUrl: './not-applied-rules-report.component.html',
  styleUrls: ['./not-applied-rules-report.component.scss']
})
export class NotAppliedRulesReportComponent {

  @Input() reports: Array<INotAppliedRuleReport>;
  @Input() showEditButtons: boolean = true;
  
  @Output() removeRule: EventEmitter<number> = new EventEmitter();
  @Output() editRule: EventEmitter<number> = new EventEmitter();


  handleEdit(event: MouseEvent, ruleId: number) {
    event.stopPropagation();
    this.editRule.emit(ruleId)
  }

  handleRemove(event: MouseEvent, ruleId: number) {
    event.stopPropagation();
    this.removeRule.emit(ruleId)
  }
}
