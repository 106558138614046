import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpLabelManagerComponent } from './op-label-manager.component';
import { OpChipManagerModule } from '../op-chip-manager/op-chip-manager.module';

@NgModule({
  declarations: [
    OpLabelManagerComponent
  ],
  imports: [
    CommonModule,
    OpChipManagerModule
  ],
  exports: [
    OpLabelManagerComponent
  ],
  providers: [],
})
export class OpLabelManagerModule {}