import { AngularNames } from '@app/moonbeamConstants';

export abstract class IOPSpinnerService {
  abstract getSpinnerState(key: string): ISpinnerState;
  abstract spin(state: ISpinnerState): void;
  abstract stop(state: ISpinnerState): void;
}

export interface ISpinnerState {
  key: string;
  isActive: boolean;
  delayInMilliseconds: number;
}

/**
 * wrapper for the SpinnerService that will allow for a delay before the timer starts.
 */
export class OPSpinnerService extends IOPSpinnerService {
  defaultDelay: number = 750;
  static $inject = [
    AngularNames.spinnerService
  ];

  constructor(private spinnerService: ISpinnerService) {
    super();
  }

  getSpinnerState(key: string): ISpinnerState {
    if (key) {
      return {
        key: key,
        isActive: true,
        delayInMilliseconds: this.defaultDelay
      };
    }
    return null;
  }

  spin(state: ISpinnerState): void {
    if (state) {
      window.setTimeout(() => {
        if (state.isActive) {
          this.spinnerService.spin(state.key);
        }
      }, state.delayInMilliseconds);
    }
  }

  stop(state: ISpinnerState): void {
    if (state && state.isActive) {
      state.isActive = false;
      this.spinnerService.stop(state.key);
    }
  }
}
