import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { StorageService } from '@app/components/shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { ILabel } from '@app/components/shared/services/label.service';
import { ESelectableTableFilterTypes } from './selectable-table-filter-bar.models';

@Injectable()
export class SelectableTableFilterBarService extends OpFilterBarService<any> {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService,
  ) {
    super(storage, null, cacheReset, new Map([[ESelectableTableFilterTypes.Labels, true]]));
  }

  get currentFilters$(): Observable<IOpFilterBarFilter<ESelectableTableFilterTypes>[]> {
    return this.anyFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters.filter(filter => this.validTypes.includes(filter.type))
      )
    );
  }

  addNameContainsFilter(contains: string) {
    this.addFilter({
      type: ESelectableTableFilterTypes.Name,
      display: `Name contains '${contains}'`,
      value: contains,
      order: 1
    });
  }

  addLabelIdFilter({name, id}: ILabel) {
    this.addFilter({
      type: ESelectableTableFilterTypes.Labels,
      display: `Label: ${name}`,
      value: id,
      order: 1
    });
  }
}
