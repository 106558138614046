<div class="nav-arrow-buttons">
  <button class="nav-arrow-btn left mat-elevation-z6" [disabled]="!leftBtn.path" [routerLink]="leftBtn.path">
    <mat-icon>arrow_back</mat-icon>
    <span class="desktop-view-label">{{ leftBtn.deskTopViewLabel }}</span>
    <span class="mobile-view-label">{{ leftBtn.mobileViewLabel }}</span>
  </button>
  <button class="nav-arrow-btn right mat-elevation-z6" [disabled]="!rightBtn.path" [routerLink]="rightBtn.path">
    <span class="desktop-view-label">{{ rightBtn.deskTopViewLabel }}</span>
    <span class="mobile-view-label">{{ rightBtn.mobileViewLabel }}</span>
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>