import { Component } from '@angular/core';
import { scopeProvider } from '@app/ajs-upgraded.providers';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-database-container',
  template: '<router-outlet class="flex-col flex-stretch"></router-outlet>'
})
export class TagDatabaseContainerComponent {
  constructor() {}
}
