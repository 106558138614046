import { NumberValue } from 'd3';
import {
  IUsageTrendsCurrentPeriod,
  IUsageTrendsFuturePeriod,
  IUsageTrendsPastPeriod
} from '@app/components/usage-v2/usage-v2.models';

export interface IChartData {
  date: string;
  dateString: string;
  value: number;
  data?: Record<string, number>
}

export interface IChartToggleBtn {
  className: string;
  chartClassName: ChartClassName;
  text: string;
  tooltipText: string;
  tooltipClass?: string;
  showAlways?: boolean;
  showWithFiltered?: boolean;
}

export interface IChartConfig {
  data: IChartData[] | NumberValue;
  chartClassName: string;
  highlighted?: boolean;
  hidden?: boolean;
  toggleBtn?: IChartToggleBtn;
}

export interface IDisplayedCharts {
  blueLine?: boolean;
  blueBars?: boolean;
  greyLines?: boolean;
  greyBars?: boolean;
  monthlyPacing?: boolean;
  termLimit?: boolean;
}

export const DefaultDisplayedCharts: IDisplayedCharts = {
  blueLine: true,
  blueBars: true,
  greyLines: true,
  greyBars: true,
  monthlyPacing: true,
  termLimit: true,
};

export enum ChartType {
  pastPeriodsCumulativeTotal = 'pastPeriodsCumulativeTotal',
  pastPeriodsCumulativeTotalFiltered = 'pastPeriodsCumulativeTotalFiltered',
  pastPeriodsPeriodTotal = 'pastPeriodsPeriodTotal',
  pastPeriodsPeriodTotalFiltered = 'pastPeriodsPeriodTotalFiltered',
  futurePeriodsPeriodProjectedTotal = 'futurePeriodsPeriodProjectedTotal',
  futurePeriodsPeriodProjectedTotalFiltered = 'futurePeriodsPeriodProjectedTotalFiltered',
  futurePeriodsCumulativeProjectedTotalScheduledUsageEstimate = 'futurePeriodsCumulativeProjectedTotalScheduledUsageEstimate',
  futurePeriodsCumulativeProjectedTotalScheduledUsageEstimateFiltered = 'futurePeriodsCumulativeProjectedTotalScheduledUsageEstimateFiltered',
  futurePeriodsCumulativeProjectedTotalHistoricalUsageEstimate = 'futurePeriodsCumulativeProjectedTotalHistoricalUsageEstimate',
  monthlyPacing = 'monthlyPacing',
  termLimit = 'termLimit',
  currentPeriodPeriodTotal = 'currentPeriodPeriodTotal',
  currentPeriodPeriodProjectedTotal = 'currentPeriodPeriodProjectedTotal',
  currentPeriodPeriodTotalFiltered = 'currentPeriodPeriodTotalFiltered',
  currentPeriodPeriodProjectedTotalFiltered = 'currentPeriodPeriodProjectedTotalFiltered',
}

export enum ChartClassName {
  pastPeriods = 'past-periods',
  futurePeriods = 'future-periods',
  monthlyPacing = 'monthly-pacing',
  termLimit = 'term-limit',
  filteredUsage = 'filtered-usage',
  filteredProjectedUsage = 'filtered-projected-usage',
  historicalFuture = 'cumulative-projected-total-historical-usage-estimate',
  scheduledFuture = 'cumulative-projected-total-scheduled-usage-estimate',
}

export interface IChartsConfig {
  charts: Partial<Record<ChartType, IChartConfig>>,
  timeline: string[];
  valueLine: number[];
  periods: Array<IUsageTrendsPastPeriod | IUsageTrendsCurrentPeriod | IUsageTrendsFuturePeriod>;
}

export enum EChartType {
  AUDIT = 'audit',
  WEB_JOURNEY = 'web-journey',
}
