import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { STORAGE_KEY, CC_ASSIGN_DC_FILTERS_STORAGE_KEY } from '../cc-assign.constants';
import { MatTableDataSource } from '@angular/material/table';
import { EConsentCategoryType, IConsentCategory } from '@app/components/consent-categories/consent-categories.models';
import { SelectionModel } from '@angular/cdk/collections';
import { IDataSource, IDataSourceLabel, IDataSourceQueryParams } from '../../../shared/services/data-sources/data-sources.models';
import { EDataSourcesSortBy, EDataSourceType } from '@app/components/shared/services/data-sources/data-sources.constants';
import { Subject, merge } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CCDataSourcesFilterBarService } from '../../cc-data-sources-filter-bar/cc-data-sources-filter-bar.service';
import { EConsentCategoriesFilterTypes } from '../../consent-categories-filter-bar/consent-categories-filter-bar.constants';
import { takeUntil, tap, startWith, switchMap, debounceTime } from 'rxjs/operators';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { DataSourcesService } from '@app/components/shared/services/data-sources/data-sources.service';
import { IDataSources } from '@app/components/shared/services/data-sources/data-sources.models';
import { DateService, EDateFormats } from '@app/components/date/date.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-assign-select-data-source',
  templateUrl: './cc-assign-select-data-source.component.html',
  styleUrls: ['./cc-assign-select-data-source.component.scss'],
  providers: [
    { provide: STORAGE_KEY, useValue: CC_ASSIGN_DC_FILTERS_STORAGE_KEY },
    CCDataSourcesFilterBarService
  ]
})
export class CCAssignSelectDataSourceComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = ['itemName', 'lastRun', 'labels', 'conflict'];
  dataSource = new MatTableDataSource<IDataSource>([]);
  selection = new SelectionModel<IDataSource>(true, []);
  loading: boolean = true;

  queryParams: IDataSourceQueryParams = {
    page: 0,
    pageSize: 100,
    sortBy: EDataSourcesSortBy.ItemName,
    sortDesc: false,
    itemType: EDataSourceType.Audit
  };

  paginationState: { length: number, pageSize: number } = {
    length: 0,
    pageSize: 0
  };

  shiftKeyPressed: boolean = false;
  lastSelectedIndex: number = null;

  initialLoad: boolean = true;

  private destroy$ = new Subject();
  private filtersUpdated$ = new Subject();

  @Input() selectedConsentCategories: IConsentCategory[];
  @Input() selectedConsentCategoryNames: string;
  @Input() selectedType: EConsentCategoryType;
  @Input() selectedDataSources: IDataSource[];
  @Input() headerText?;
  @Input() auditId?: number;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() selectionChanged: EventEmitter<IDataSource[]> = new EventEmitter();

  @HostListener('window:keydown.shift')
  onKeyDown() {
    this.shiftKeyPressed = true;
  }

  @HostListener('window:keyup.shift')
  onKeyUp() {
    this.shiftKeyPressed = false;
  }

  constructor(
    private dataSourceService: DataSourcesService,
    private ccDataSourcesFbSvc: CCDataSourcesFilterBarService,
    private dateService: DateService,
  ) {}

  ngOnInit(): void {
    this.initFilters();
  }

  ngAfterViewInit(): void {
    // reset back to the first page if the user changes the sort order
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    setTimeout(() => this.handleTable(), 0);
    setTimeout(() => this.createFilters(), 50);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initFilters(): void {

    this.ccDataSourcesFbSvc.updateSupportedFiltersList([
      EConsentCategoriesFilterTypes.Name,
      EConsentCategoriesFilterTypes.Label
    ]);

    this.ccDataSourcesFbSvc
      .currentFilters$
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.onFiltersChanged.bind(this));
  }

  createFilters(): void {

    // Recreate the filters from storage
    const curFilters = this.ccDataSourcesFbSvc.currentFilters;

    const createLabelFilter = (filter: IOpFilterBarFilter<EConsentCategoriesFilterTypes>): IOpFilterBarFilter<EConsentCategoriesFilterTypes> => ({
      type: EConsentCategoriesFilterTypes.Label,
      display: filter.display,
      value: filter.value
    });

    this.ccDataSourcesFbSvc.clear();

    const newFilters = curFilters.map(createLabelFilter);
    newFilters.forEach(filter => this.ccDataSourcesFbSvc.addFilter(filter));
  }

  onFiltersChanged(apiPostBody): void {
    if (this.paginator) this.paginator.pageIndex = 0;
    this.filtersUpdated$.next({ filters: apiPostBody });
  }

  handleTable(): void {
    merge(
      this.sort.sortChange,
      this.paginator.page,
      this.filtersUpdated$
    )
    .pipe(
      tap(() => {
        this.loading = true;
      }),
      startWith([]),
      debounceTime(150), // Wait for 150ms of inactivity before proceeding with merge (so that getDataSources is only called once)
      switchMap((mergedEvent: Sort & PageEvent & IOpFilterBarFilter<EConsentCategoriesFilterTypes>[]) => {
        if (mergedEvent) {
          // sorting
          if (mergedEvent.hasOwnProperty('direction')) {
            this.queryParams.page = 0;
            this.queryParams.sortBy = mergedEvent.active as EDataSourcesSortBy;
            this.queryParams.sortDesc = mergedEvent.direction === 'desc';
          }

          // pagination
          if (mergedEvent.hasOwnProperty('pageIndex')) {
            this.queryParams.page = mergedEvent.pageIndex;
          }

          // filter bar
          if (mergedEvent.hasOwnProperty('filters')) {
            const filters = mergedEvent['filters'];
            this.queryParams.page = 0;
            let labelIds = [];
            this.queryParams.itemName =  this.queryParams.labelIds = undefined;
            filters.forEach((filter: IOpFilterBarFilter<EConsentCategoriesFilterTypes>) => {
              // name filter
              if (filter.type === EConsentCategoriesFilterTypes.Name) {
                this.queryParams.itemName = filter.value['filterValue'];
              }

              // label filters
              if (filter.type === EConsentCategoriesFilterTypes.Label) {
                if (typeof filter?.value === 'number') labelIds.push(filter.value);
              }
            });

            this.queryParams.labelIds = labelIds.length ? labelIds : null;
          }
        }
console.log("DS ASSIGN::get library");
        return this.dataSourceService.getDataSources(this.queryParams);
      })
    )
    .subscribe((data: IDataSources) => {
      // update loading state
      this.loading = false;

      // filter out data
      const filteredData = data.dataSources;

      // update pagination
      const pagination =  data.metadata.pagination;
      this.paginationState.length = pagination.totalCount;
      this.paginationState.pageSize = pagination.pageSize;

      this.dataSource.data = [...filteredData];

      this.setSelectedState();
console.log("DS ASSIGN::get library SUB");
      if (this.initialLoad && this.auditId) {
        this.initialLoad = false;
        this.selectInitiatingAudit();
      }
    });
  }

  // If creating a CC from an audit, select the audit immediately as a datasource to load data from
  selectInitiatingAudit(): void {
    const auditIndex = this.dataSource.data.findIndex((item: any) => item?.itemId === this.auditId);
    if (auditIndex > -1) this.handleCheckboxClick(auditIndex);
  }

  getLabelNames(labels: IDataSourceLabel[]): string {
    return labels.map(label => label.name).join(', ');
  }

  formatDate(date: Date): string {
    return this.dateService.formatDate(new Date(date), EDateFormats.dateTwentyTwo);
  }

  resetQueryParams(): void {
    this.queryParams = {
      page: 0,
      pageSize: 100,
      sortBy: EDataSourcesSortBy.ItemName,
      sortDesc: false,
      itemType: EDataSourceType.Audit
    };
  }

  setSelectedState(): void {
    if (!this.selectedDataSources?.length) return;

    this.selectedDataSources?.forEach(source => {
      const found = this.dataSource.data.find(item => item.itemId === source.itemId);
      if (found) this.selection.select(found);
    });
  }

  handleCheckboxClick(index: number): void {
    if (this.shiftKeyPressed) {
      if (this.lastSelectedIndex < index) {
        for (let i = this.lastSelectedIndex + 1; i <= index; i++) {
          const item = this.dataSource.data[i];
          const hasOwnProperty = item.hasOwnProperty('consentCategoryType');
          if (!hasOwnProperty || (hasOwnProperty && this.selectedType === item.consentCategoryType)) {
            this.selection.toggle(this.dataSource.data[i]);
          }
        }
      } else {
        for (let i = this.lastSelectedIndex - 1; i >= index; i--) {
          const item = this.dataSource.data[i];
          const hasOwnProperty = item.hasOwnProperty('consentCategoryType');
          if (!hasOwnProperty || (hasOwnProperty && this.selectedType === item.consentCategoryType)) {
            this.selection.toggle(this.dataSource.data[i]);
          }
        }
      }
    } else {
      this.selection.toggle(this.dataSource.data[index]);
    }

    this.lastSelectedIndex = index;
    this.selectionChanged.emit(this.selection.selected);
  }
}
