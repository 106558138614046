import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import {
  IConsentCategories,
  IConsentCategorySnapshot
} from '@app/components/consent-categories/consent-categories.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { ReprocessConfirmationSnackbarService } from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.service';
import { ConsentCategoriesUrlBuilders } from '@app/components/consent-categories/consent-categories.constants';
import { ConsentCategoriesEditComponent } from '@app/components/consent-categories/cc-edit/cc-edit.component';
import { ECCEditTabs } from '@app/components/consent-categories/cc-edit/cc-edit.constants';
import {
  IReprocessService,
  ReprocessBannerStatus
} from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { AccountsService } from '../account/account.service';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EFreeTrialAdModalType } from '../audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.models';
import { FreeTrialAdModalComponent } from '../audit-reports/audit-report-header/free-trial-ad-modal/free-trial-ad-modal.component';
import {
  IAuditEditorCloseOptions,
  IAuditEditorModalData
} from '@app/components/audit/audit-editor/audit-editor.models';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'applied-consent-categories',
  templateUrl: './applied-consent-categories.component.html',
  styleUrls: ['./applied-consent-categories.component.scss']
})
export class AppliedConsentCategoriesComponent implements OnChanges, OnInit, OnDestroy {

  @Input() isLoadedAuditConsentCategories: boolean;
  @Input() isLoadedRunConsentCategories: boolean;
  @Input() runId: number;
  @Input() auditId: number;
  @Input() categoriesType: ECCEditTabs = ECCEditTabs.cookies;
  @Input() auditConsentCategories: IConsentCategories[];

  @Input() set categories(data: IConsentCategorySnapshot[]) {
    if (data) {
      this.categoriesList = data;
    }
  }

  categoriesList: IConsentCategorySnapshot[];
  consentCategoriesListUrl = ConsentCategoriesUrlBuilders.base();
  isCategories: boolean;
  userIsReadOnly: boolean;
  showRunsDifferBanner: boolean;

  private checkedForDiffsBetweenAuditAndRunCcs: boolean;
  private auditName: string = '';
  private isVisitorMode: boolean;
  private destroySubject = new Subject<void>();

  constructor(
    private ccService: ConsentCategoriesService,
    private reprocessConfirmationSnackbarService: ReprocessConfirmationSnackbarService,
    private modalService: OpModalService,
    private reprocessService: IReprocessService,
    private auditService: DiscoveryAuditService,
    private accountsService: AccountsService,
    private applicationChromeService: ApplicationChromeService,
    private opModalService: OpModalService,
    private modalEscapeService: ModalEscapeService,
  ) {
    this.userIsReadOnly = this.accountsService.userIsReadOnly();

    this.applicationChromeService.isVisitorMode$
      .pipe(takeUntil(this.destroySubject))
      .subscribe({
        next: isVisitorMode => this.isVisitorMode = isVisitorMode
      });
  }

  ngOnInit() {
    this.auditService.getAudit(this.auditId).then(audit => {
      this.auditName = audit.name;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.checkedForDiffsBetweenAuditAndRunCcs && changes.hasOwnProperty('categories')) {
      // categories changed since detecting differences - this indicates user selected a new run
      this.checkedForDiffsBetweenAuditAndRunCcs = false;
    }
    if (!this.checkedForDiffsBetweenAuditAndRunCcs && this.isLoaded() && this.categoriesList?.length) {
      // determine if consent category assignments have changed for this audit since this snapshot
      const auditCcIds = this.auditConsentCategories.map(cc => cc.id).sort();
      const runSsCcIds = this.categoriesList.map(ss => ss.consentCategoryId).sort();
      const runsDiffer = JSON.stringify(auditCcIds) !== JSON.stringify(runSsCcIds);

      this.showRunsDifferBanner = runsDiffer && auditCcIds.length !== 0;
      this.checkedForDiffsBetweenAuditAndRunCcs = true;
    }
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  getAssignedCCTooltip(category: IConsentCategorySnapshot): string {
    return !category.consentCategoryConfigExists ? 'This consent category has been deleted and cannot be edited.' : (category.name.length + category.type.length) > 30 ? category.name + ' (' + category.type + ')' : null;
  }

  selectCategory($event: MouseEvent, category: IConsentCategorySnapshot) {
    if (!category.consentCategoryConfigExists) {
      return;
    }

    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else if (this.userIsReadOnly) {
      return;
    } else if (category.consentCategoryId) {
      this.editConsentCategory(category.consentCategoryId);
    }
  }

  openAuditStandards() {
    if (this.isVisitorMode) {
      this.openFreeTrialAdModal();
    } else if (this.userIsReadOnly) {
      return;
    } else {
      const index = this.modalEscapeService.getLast() + 1;
      this.modalEscapeService.add(index);

      const data: IAuditEditorModalData = {
        auditId: this.auditId,
        runId: this.runId,
        step: 1,
        standardsTab: EStandardsTabs.ConsentCategories,
      };

      this.opModalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor')
        .afterClosed()
        .subscribe((options?: IAuditEditorCloseOptions) => {
          this.modalEscapeService.remove(index);
        });
    }
  }

  private openFreeTrialAdModal() {
    const data = {
      type: EFreeTrialAdModalType.CONSENT_CATEGORY
    };

    this.modalService.openModal(FreeTrialAdModalComponent, { data });
  }

  private editConsentCategory(consentCategoryId: number) {
    this.modalService.openFixedSizeModal(ConsentCategoriesEditComponent, {
      disableClose: true,
      data: {
        tab: this.categoriesType,
        consentCategoryId,
      }
    })
    .afterClosed()
    .subscribe(isUpdated => {
      if (isUpdated) {
        this.showReprocessModal();
      }
    });
  }

  showReprocessModal() {
    this.reprocessConfirmationSnackbarService
      .showReprocessConsentCategories(this.auditConsentCategories, () => {
        const ids = this.auditConsentCategories.map(i => i.id);
        this.ccService.reprocessConsentCategories(this.auditId, this.runId, ids)
          .subscribe(
          () => {
            this.reprocessService.reprocessComplete();
            this.reprocessService.subscribeOnAuditReprocessingConsentCategoriesUpdates(this.auditId, this.runId, this.auditName);
            this.reprocessService.updateAuditReprocessConsentCategoriesBannerStatus(this.auditId, this.runId, ReprocessBannerStatus.inProgress);
          },
          error => {
            if (error.code === 423) this.reprocessService.displayAuditConsentCategoriesReprocessInProgressToast();
          }
        );
      });
  }

  openConsentCategoryManager(): void {
    const data: IAuditEditorModalData = {
      auditId: this.auditId,
      runId: this.runId,
      step: 2,
      standardsTab: EStandardsTabs.ConsentCategories,
      disableNavigationButtons: true
    };

    this.modalService.openFixedSizeModal(AuditEditorComponent, { disableClose: true, data }, 'op-audit-editor');
  }

  isLoaded() {
    return this.isLoadedRunConsentCategories && this.isLoadedAuditConsentCategories;
  }
}
