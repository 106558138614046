import { IWebJourneyRunsSeriazlier, WebJourneyRunsSerializer } from './components/domains/webJourneys/webJourneyAPI/webJourneyRunsSerializer';
import { IFolderSelector, FolderSelector } from './store/selectors/folderSelectorService';
import { IModalService, ModalService } from '@app/components/modals/modalService';
import { AngularNames, Names } from '@app/moonbeamConstants';
import { EventManager, IEventManager } from '@app/components/eventManager/eventManager';
import { IManageNewCardsService, ManageNewCardsService } from '@app/components/navigation/manage/manageNewCardsService';
import { FoldersApiService, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { IDomainsService } from '@app/components/domains/domainsService';
import {
  AuditDataService,
  IAuditDataService
} from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import {
  IReprocessService,
  ReprocessService
} from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import {
  IWebJourneyApiService,
  WebJourneyAPIService
} from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import {
  ISortingService,
  SortingService
} from '@app/components/domains/discoveryAudits/reporting/services/sortingService/sortingService';
import { IStopRunService, StopRunService } from '@app/components/stopRun/stopRunService';
import { IApiService, ApiService } from '@app/components/api/apiService';
import { IOPSpinnerService, OPSpinnerService } from '@app/components/ui/spinner/spinnerService';
import { TagsService } from '@app/components/tags/tagsService';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { CalculateWordWidth } from '@app/components/domains/discoveryAudits/reporting/services/calculateWordWidthService/calculateWordWidthService';
import { IFolderActionsService } from '@app/store/actions/folderActions';
import {
  name as MJS_NAME,
  ManualJourneyService
} from './components/live-connect/manual-journey/manualJourneyService';
import { IAuditActionsService } from '@app/store/actions/auditActions';
import { IDomainActionsService } from '@app/store/actions/domainActions';
import { LocationsService } from './components/creator/webJourney/locationsService';
import { IUserAgentService } from './components/domains/userAgentService.models';
import { NumberFormatter } from './components/domains/discoveryAudits/reporting/services/numberFormatting/numberFormatService';
import { ToastService } from './components/utilities/toastService';
import { ManageTagsService } from './components/account/manageTags/manageTagsService';
import { DomainService } from './components/domain/domain.service';
import { StatusBannerService } from './components/reporting/statusBanner/statusBannerService';
import { StatusBannerProcessor } from './components/reporting/statusBanner/statusBannerProcessor';
import { ModalEscapeService } from './components/ui/modalEscape/modalEscapeService';
import { IDomainSelector, DomainSelector } from './store/selectors/domainSelectorService';
import { IUtilitiesService, UtilitiesService } from './components/utilities/utilitiesService';
import { ApiKeyService } from './components/account/keys/apiKeysService';
import { UserEventsService } from './components/userEvents/userEventsService';

/**
 * This file is used for declaring providers of AngularJS services.
 * This providers are used for upgrading AngularJS services (thus AngularJS services can be used inside Angular logic).
 * Each provider is an object, that consists of the following properties:
 *  @property provide. An abstract class, that's implemented by 'use' property.
 *            Should be an abstract class because after compilation javascript does NOT have interfaces.
 *  @property use. Implementation of the abstract class.
 *  @property useFactory. Factory function, that should return service.
 *  @property deps. Array of dependencies. Basically it consists of ONLY injector.
 */
export const scopeProvider = {
  provide: AngularNames.scope,
  useFactory: i => i.get(AngularNames.rootScope).$new(),
  deps: ['$injector']
};

export const modalServiceProvider = {
  provide: IModalService,
  use: ModalService,
  useFactory: i => i.get(Names.Services.modal),
  deps: ['$injector']
};

export const ngReduxProvider = {
  provide: AngularNames.ngRedux,
  useFactory: i => i.get(AngularNames.ngRedux),
  deps: ['$injector']
};

export const folderActionsServiceProvider = {
  provide: IFolderActionsService,
  useFactory: i => i.get(Names.Services.folderActions),
  deps: ['$injector']
};

export const manualJourneyServiceProvider = {
  provide: ManualJourneyService,
  useFactory: i => i.get(MJS_NAME),
  deps: ['$injector']
};

export const eventManagerServiceProvider = {
  provide: IEventManager,
  use: EventManager,
  useFactory: i => i.get(Names.Services.eventManager),
  deps: ['$injector']
};

export const manageCardsServiceProvider = {
  provide: IManageNewCardsService,
  use: ManageNewCardsService,
  useFactory: i => i.get(Names.Services.manageNewCards),
  deps: ['$injector']
};

export const foldersServiceProvider = {
  provide: IFoldersApiService,
  use: FoldersApiService,
  useFactory: i => i.get(Names.Services.foldersApi),
  deps: ['$injector']
};

export const domainsServiceProvider = {
  provide: IDomainsService,
  use: DomainService,
  useFactory: i => i.get(Names.Services.domains),
  deps: ['$injector']
};

export const auditDataServiceProvider = {
  provide: IAuditDataService,
  use: AuditDataService,
  useFactory: i => i.get(Names.Services.auditData),
  deps: ['$injector']
};

export const reprocessRulesServiceProvider = {
  provide: IReprocessService,
  use: ReprocessService,
  useFactory: i => i.get(Names.Services.reprocess),
  deps: ['$injector']
};

export const webJourneyAPIServiceProvider = {
  provide: IWebJourneyApiService,
  use: WebJourneyAPIService,
  useFactory: i => i.get(Names.Services.webJourneyAPI),
  deps: ['$injector']
};

export const apiServiceProvider = {
  provide: IApiService,
  use: ApiService,
  useFactory: i => i.get(Names.Services.api),
  deps: ['$injector']
};

export const apiKeyServiceProvider = {
  provide: ApiKeyService,
  useFactory: i => i.get(Names.Services.apiKeysService),
  deps: ['$injector']
};

export const userEventServiceProvider = {
  provide: UserEventsService,
  useFactory: i => i.get(Names.Services.userEvents),
  deps: ['$injector']
};

export const sortingServiceProvider = {
  provide: ISortingService,
  use: SortingService,
  useFactory: i => i.get(Names.Services.sorting),
  deps: ['$injector']
};

export const stopRunProvider = {
  provide: IStopRunService,
  use: StopRunService,
  useFactory: i => i.get(Names.Services.stopRun),
  deps: ['$injector']
};

export const spinnerServiceProvider = {
  provide: IOPSpinnerService,
  use: OPSpinnerService,
  useFactory: i => i.get(Names.Services.opSpinner),
  deps: ['$injector']
};

export const tagsServiceProvider = {
  provide: TagsService,
  useFactory: i => i.get(Names.Services.tags),
  deps: ['$injector']
};

export const discoveryAuditServiceProvider = {
  provide: DiscoveryAuditService,
  useFactory: i => i.get(Names.Services.discoveryAudit),
  deps: ['$injector']
};

export const calculateWordWidthServiceProvider = {
  provide: CalculateWordWidth,
  useFactory: i => i.get(Names.Services.calculateWordWidth),
  deps: ['$injector']
};

export const rootScopeProvider = {
  provide: AngularNames.rootScope,
  useFactory: i => i.get(AngularNames.rootScope),
  deps: ['$injector']
};

export const locationProvider = {
  provide: AngularNames.location,
  useFactory: i => i.get(AngularNames.location),
  deps: ['$injector']
};

export const qProvider = {
  provide: AngularNames.q,
  useFactory: i => i.get(AngularNames.q),
  deps: ['$injector']
};

export const locationServiceProvider = {
  provide: LocationsService,
  useFactory: i => i.get(Names.Services.locations),
  deps: ['$injector']
};

export const auditActionsServiceProvider = {
  provide: IAuditActionsService,
  useFactory: i => i.get(Names.Services.auditActions),
  deps: ['$injector']
};

export const domainActionsServiceProvider = {
  provide: IDomainActionsService,
  useFactory: i => i.get(Names.Services.domainActions),
  deps: ['$injector']
};

export const userAgentServiceProvider = {
  provide: IUserAgentService,
  useFactory: i => i.get(Names.Services.userAgent),
  deps: ['$injector']
};

export const numberFormatterProvider = {
  provide: NumberFormatter,
  useFactory: i => i.get(Names.Services.numberFormat),
  deps: ['$injector']
};

export const ngToastProvider = {
  provide: Names.Services.ngToast,
  useFactory: i => i.get(Names.Services.ngToast),
  deps: ['$injector']
};

export const toastServiceProvider = {
  provide: ToastService,
  useFactory: i => i.get(Names.Services.toastService),
  deps: ['$injector']
};

export const manageTagsServiceProvider = {
  provide: ManageTagsService,
  useFactory: i => i.get(Names.Services.manageTags),
  deps: ['$injector']
};

export const statusBannerServiceProvider = {
  provide: StatusBannerService,
  useFactory: i => i.get(Names.Services.statusBanner),
  deps: ['$injector']
};

export const statusBannerProcessorProvider = {
  provide: StatusBannerProcessor,
  useFactory: i => i.get(Names.Services.statusBannerProcessor),
  deps: ['$injector']
};

export const modalEscapeServiceProvider = {
  provide: ModalEscapeService,
  useFactory: i => i.get(Names.Services.modalEscape),
  deps: ['$injector']
};

export const folderSelectorServiceProvider = {
  provide: IFolderSelector,
  use: FolderSelector,
  useFactory: i => i.get(Names.Services.folderSelector),
  deps: ['$injector']
};

export const domainSelectorServiceProvider = {
  provide: IDomainSelector,
  use: DomainSelector,
  useFactory: i => i.get(Names.Services.domainSelector),
  deps: ['$injector']
};

export const utilitiesServiceProvider = {
  provide: IUtilitiesService,
  use: UtilitiesService,
  useFactory: i => i.get(Names.Services.utilities),
  deps: ['$injector']
};

export const webJourneyRunsSeriazlierProvider = {
  provide: IWebJourneyRunsSeriazlier,
  use: WebJourneyRunsSerializer,
  useFactory: i => i.get(Names.Services.webJourneyRunsSerializer),
  deps: ['$injector']
};
