export const SIDEBAR_IS_CLOSED = 'op.sidebarIsClosed';

export enum ETooltipOptions {
  Expand = 'Expand nav bar',
  Collapse = 'Collapse nav bar'
}

export const opSelectors = {
  'expandNav': 'sidebar-expand-nav',
  'collapseNav': 'sidebar-collapse-nav',
};

export const opLinkSelectorMap = {
  'dashboard': 'sidebar-dashboard',
  'standards': 'sidebar-standards',
  'configurations': 'sidebar-configurations',
  'support-top-level': 'sidebar-support-top-level',
  'support': 'sidebar-support',
  'tag inventory': 'sidebar-technology-tag-inventory',
  'variable inventory': 'sidebar-technology-variable-inventory',
  'tag load time': 'sidebar-technology-tag-load-time',
  'tag versions': 'sidebar-technology-tag-versions',
  'duplicates & multiples': 'sidebar-technology-duplicates-multiples',
  'tag status codes': 'sidebar-technology-tag-status-codes',
  'cookie inventory': 'sidebar-technology-cookie-inventory',
  'page inventory': 'sidebar-technology-page-inventory',
  'tags': 'sidebar-privacy-tags',
  'cookies': 'sidebar-privacy-cookies',
  'geolocations': 'sidebar-privacy-geolocations',
  'request domains': 'sidebar-privacy-request-domains',
  'page inventory-privacy': 'sidebar-privacy-page-inventory',
  'experience': 'sidebar-experience',
  'touchpoints': 'sidebar-touchpoints',
  'attribution': 'sidebar-attribution',
  'rules': 'sidebar-standards-rules',
  'consent-preferences': 'sidebar-standards-consent-preferences',
  'action sets': 'sidebar-configurations-action-sets',
  'remote file mapping': 'sidebar-configurations-remote-file-mapping',
  'comparisons': 'sidebar-configurations-comparisons',
  'tag setup': 'sidebar-configurations-tag-setup',
  'bulk actions': 'sidebar-configurations-bulk-actions',
  'data sources': 'sidebar-data-sources',
  'audits & journeys': 'sidebar-data-sources-audits-journeys',
  'app center': 'sidebar-data-sources-app-center',
  'liveconnect': 'sidebar-data-sources-liveconnect',
  'har upload': 'sidebar-data-sources-har-upload',
  'export center': 'sidebar-data-sources-export-center',
  'create new': 'sidebar-create-new',
  'theme toggle': 'sidebar-light-dark-mode-toggle',
  'search': 'sidebar-search',
  'chat/email': 'sidebar-support-chat-email',
  'help documentation': 'sidebar-support-help-documentation',
  'academy training': 'sidebar-support-academy-training',
  'tag database': 'sidebar-support-tag-database',
  'feedback portal': 'sidebar-support-feedback-portal',
  'api documentation': 'sidebar-support-api-documentation',
  'script services': 'sidebar-support-script-services',
  'settings': 'sidebar-settings',
  'users': 'sidebar-settings-users',
  'event-log': 'sidebar-settings-event-log',
  'usage-heading': 'sidebar-settings-usage-heading',
  'usage': 'sidebar-settings-usage',
  'event log': 'sidebar-settings-event-log',
  'profile-admin-top-level': 'sidebar-profile-admin-top-level',
  'profile-top-level': 'sidebar-profile-top-level',
  'profile': 'sidebar-profile-profile',
  'admin portal': 'sidebar-profile-admin-portal',
  'sign out': 'sidebar-profile-sign-out',
  'privacy': 'sidebar-privacy',
  'technology': 'sidebar-technology',
  'manage tags': 'sidebar-manage-tags',
  'bulk operations': 'sidebar-bulk-operations',
  'other products': 'sidebar-other-products',
  'keyboard shortcuts': 'sidebar-keyboard-shortcuts',
  'announcement': 'sidebar-announcement',
  'checklist': 'sidebar-checklist',
  'email inboxes': 'sidebar-email-inboxes',
};

export const AccountSearchModalConfig = {
  disableClose: false,
  height: '100%',
  width: '100%',
  backdropClass: 'account-search-backdrop',
  panelClass: 'account-search-panel'
};
