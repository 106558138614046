import { AngularNames } from '@app/moonbeamConstants';
import { ButtonSet } from '@app/models/commons';
import { IRule } from '@app/components/rules/rules.models';

interface IReprocessRulesModalScope extends mgcrea.ngStrap.modal.IModalScope{
    rules: IRule[];
    reprocessRules: () => void;
  }

  export class ReprocessRulesModalController {
    modalButtons: Array<ButtonSet> = [];
    hideDiscardButton: boolean = false;
    discardButtonText: string;

    rules: IRule[];

    static $inject = [
      AngularNames.scope
    ];

    constructor(
      private $scope: IReprocessRulesModalScope
    ) {
      this.rules = this.$scope.rules;
      this.initModalButtons();
    }

    initModalButtons(): void{
      if (this.rules && this.rules.length ){
        this.discardButtonText = 'Close';
        this.modalButtons = [
          [{
            label: 'Reprocess Rules',
            action: 'reprocessRules',
            type: 'success',
            primary: true,
          }]
        ];
      } else {
        this.modalButtons = [[{
          label: 'OK',
          action: 'close',
          primary: true
        }]];
        this.hideDiscardButton = true;
      }
    }

    reprocessRules(): void{
      this.$scope.reprocessRules();
      this.close();
    }

    private close(): void {
      (<mgcrea.ngStrap.modal.IModalScope>this.$scope.$parent).$hide();
    }
}
