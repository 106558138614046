import { Names } from '@app/moonbeamConstants';
import {
  IGeneratedAction,
  IJourneyVariable,
  IWebJourneyActionTag,
  IWebJourneyAPIAction,
  IWebJourneyCategory,
  IWebJourneyPageInfo,
  IWebJourneyResults,
  IWebJourneyRuleFailure
} from '../webJourneyDefinitions';
import { IRuleReportResult, IRuleReportSerializer } from '../../../reporting/rules/rule.models';
import { ITag } from '../../../../models/tagModels/tag';
import { ISerializer } from '../../../api/apiService';
import { INatualCompareService } from '../../../utilities/naturalCompareService';
import { IRFMInfo } from '@app/models/tagModels/pageDetails';

export interface IJourneyResultSerializer {
  serialize(json: IJourneyResultDataJson): IWebJourneyResults
}

interface IJourneyActionJson {
  url: string;
  action: string;
  label: string;
}

interface ICategoryJson {
  id: string;
  name: string;
}

interface ITagJson {
  id: number;
  name: string;
  icon: string;
  category: ICategoryJson;
}

export interface IJourneyVariableJson {
  name: string;
  value: string;
  description: string;
}

interface IActionJson {
  action: string;
  url: string;
}

interface IGeneratedActionJson {
  timestamp: number;
  action: IActionJson
}

export interface IJourneyResultTagJson {
  statusCode: number;
  tag: ITagJson;
  stoppedAt: number;
  duplicates: number;
  responseAt: number;
  variables: Array<IJourneyVariableJson>;
  multiples: number;
  invalidVariables: Array<any>;
  version: string;
  valid: boolean;
  startedAt: number;
  position: number;
  account: string;
  tagInstanceId?: string;
}

interface IJourneyResultActionJson {
  success: boolean;
  simplifiedRuleSetupAllowed: boolean;
  timestamp: number;
  actionId: number;
  actionSetId?: number;
  tags: Array<IJourneyResultTagJson>
  pageInfo: any;
  failureMessage: any;
  thumbnail: string;
  generatedActions: Array<IGeneratedActionJson>;
  failures: Array<any>
  screenshot: string;
  action: IJourneyActionJson;
  ruleResults?: Array<IRuleReportResult>;
  fileMappingResults: IRFMInfo[];
  startPageUrl: string;
  finishPageUrl: string;
}

interface IJourneyResultRuleFailuresJson {
  name: string;
  tag: ITag;
  account: any;
  variable?: string;
  failureType?: any;
  result?: IMatchResultJson
}

interface IMatchResultJson {
  expected: IRuleExpectationJson,
  found: any
}

interface IRuleExpectationJson {
  expected: string,
  matchType: string
}

interface IJourneyResultDataJson {
  actions: Array<IJourneyResultActionJson>;
  globalRuleResults: Array<IRuleReportResult>;
}

export class JourneyResultSerializer implements ISerializer<IWebJourneyResults> {

  static $inject = [
    Names.Services.naturalCompare,
    Names.Services.rulesReportSerializer
  ];

  constructor(private naturalCompare: INatualCompareService,
    private ruleReportSerializer: IRuleReportSerializer) { }

  serialize(json: IJourneyResultDataJson): IWebJourneyResults {
    if (json && Array.isArray(json.actions)) {
      return <IWebJourneyResults>{
        actions: this.getActions(json.actions),
        globalRuleResults: json.globalRuleResults,
        failedRuleReports: this.ruleReportSerializer.serializeFailedRuleReports(json.globalRuleResults),
        notAppliedRuleReports: this.ruleReportSerializer.serializeNotAppliedRuleReports(json.globalRuleResults),
        passedRuleReports: this.ruleReportSerializer.serializePassedRuleReports(json.globalRuleResults),
      };
    }
  }

  private getActions(actions: Array<IJourneyResultActionJson>): Array<IWebJourneyAPIAction> {
    var webJourneyActions: Array<IWebJourneyAPIAction> = [];
    actions.forEach((action: IJourneyResultActionJson, index) => {
      webJourneyActions.push(<IWebJourneyAPIAction>{
        success: action.success,
        simplifiedRuleSetupAllowed: action.success,
        timestamp: action.timestamp,
        actionId: action.actionId,
        actionSetId: action.actionSetId,
        tags: this.getActionTags(action.tags),
        pageInfo: <IWebJourneyPageInfo>action.pageInfo,
        failureMessage: action.failureMessage,
        generatedActions: this.getGuaranteedActions(action.generatedActions),
        failures: this.getRuleFailures(action.failures),
        action: <any>action.action,
        sequence: index,
        thumbnail: action.thumbnail || null,
        screenshot: action.screenshot || null,
        failedRuleReports: this.ruleReportSerializer.serializeFailedRuleReports(action.ruleResults),
        notAppliedRuleReports: this.ruleReportSerializer.serializeNotAppliedRuleReports(action.ruleResults),
        passedRuleReports: this.ruleReportSerializer.serializePassedRuleReports(action.ruleResults),
        fileMappingResults: action.fileMappingResults,
        startPageUrl: action.startPageUrl,
        finishPageUrl: action.finishPageUrl,
      });
    });
    return webJourneyActions;
  }

  private getRuleFailures(failures: Array<IJourneyResultRuleFailuresJson>): Array<IWebJourneyRuleFailure> {
    var ruleFailures: Array<IWebJourneyRuleFailure> = [];
    if (failures) {
      failures.forEach((failure: IJourneyResultRuleFailuresJson) => {
        ruleFailures.push(<IWebJourneyRuleFailure>{
          name: failure.name,
          tag: failure.tag,
          account: failure.account,
          variable: failure.variable || null,
          failureType: failure.failureType || null,
          result: failure.result || null
        });
      });
      return ruleFailures;
    }
  }

  private getActionTags(tags: Array<IJourneyResultTagJson>): Array<IWebJourneyActionTag> {
    var actionTags: Array<IWebJourneyActionTag> = [];
    if (tags && tags.length > 0) {
      tags.forEach((tag: IJourneyResultTagJson) => {
        actionTags.push(<IWebJourneyActionTag>{
          statusCode: tag.statusCode,
          tagId: tag.tag.id,
          tagName: tag.tag.name,
          tagIcon: tag.tag.icon,
          tagCategory: <IWebJourneyCategory>{
            id: tag.tag.category.id,
            name: tag.tag.category.name
          },
          stoppedAt: tag.stoppedAt,
          duplicates: tag.duplicates,
          responseAt: tag.responseAt,
          variables: this.getActionTagVariables(tag.variables),
          multiples: tag.multiples,
          invalidVariables: tag.invalidVariables,
          version: tag.version,
          valid: tag.valid,
          startedAt: tag.startedAt,
          position: tag.position,
          account: tag.account,
          tagInstanceId: tag.tagInstanceId
        });
      });
    }
    return actionTags;
  }

  private getGuaranteedActions(genActions: Array<IGeneratedActionJson>): Array<IGeneratedAction> {
    var generatedActions: Array<IGeneratedAction> = [];
    if (genActions) {
      genActions.forEach((genAction: IGeneratedActionJson) => {
        generatedActions.push(<IGeneratedAction>{
          timestamp: genAction.timestamp,
          action: genAction.action
        });
      });
      return generatedActions;
    }
  }

  private getActionTagVariables(variables: Array<IJourneyVariableJson>): Array<IJourneyVariable> {
    var journeyVariables: Array<IJourneyVariable> = [];
    if (variables) {
      variables.sort((var1, var2) => this.naturalCompare.compare(var1.name, var2.name)).map((key, i) => {
        journeyVariables.push(<IJourneyVariable>{
          order: i,
          name: key.name,
          value: key.value,
          description: key.description
        });
      });
    }
    return journeyVariables;
  }
}
