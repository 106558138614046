import { OnChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ComponentChanges } from '@app/models/commons';
import { ILabel } from '@app/components/shared/services/label.service';
import { EAlertNameControlMode } from './alert-name-control/alert-name-control.enums';
import { EAlertModalType } from '@app/components/alert/alert.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'alert-header',
  templateUrl: './alert-header.component.html',
  styleUrls: ['./alert-header.component.scss']
})
export class AlertHeaderComponent implements OnChanges {

  @Input() formGroup: UntypedFormGroup;
  @Input() labels: ILabel[];
  @Input() modalType: EAlertModalType;
  @Input() readOnly: boolean = false;
  @Output() onCreateLabel = new EventEmitter<ILabel>();

  allLabels: ILabel[] = [];

  readonly EAlertNameControlMode = EAlertNameControlMode;
  readonly EAlertModalType = EAlertModalType;

  constructor(private formBuilder: UntypedFormBuilder) { }

  ngOnChanges(changes: ComponentChanges<AlertHeaderComponent>) {
    if (changes.labels) {
      const { previousValue, currentValue } = changes.labels;
      if (previousValue !== currentValue) this.allLabels = currentValue;
    }
  }

  createLabel(label: ILabel) {
    this.onCreateLabel.emit(label);
    this.addLabel(label);
  }

  addLabel(label: ILabel): void {
    this.labelsArray.push(this.formBuilder.control(label));
  }

  removeLabel(label: ILabel): void {
    const index = (this.labelsArray.value as ILabel[]).findIndex(l => l.id === label.id);
    this.labelsArray.removeAt(index);
  }

  // when name is changed, we should recalculate labels chips by triggering change detection
  triggerLabelsRerendering() {
    this.allLabels = [...this.allLabels];
  }

  get nameControl(): UntypedFormControl {
    return this.formGroup.get('name') as UntypedFormControl;
  }

  get labelsArray(): UntypedFormArray {
    return this.formGroup.get('labels') as UntypedFormArray;
  }

}
