import { Injectable } from '@angular/core';
import { StorageService } from '@app/components/shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { UsageFilterBarService } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.service';
import { IFoldersApiService } from '@app/components/folder/foldersApiService';
import { IDomainsService } from '@app/components/domains/domainsService';
import { LabelService } from '@app/components/shared/services/label.service';
import { AccountsService } from '@app/components/account/account.service';
import {
  ReportCardsApiService
} from '@app/components/manage/shared/services/report-cards-api/report-cards-api.service';
import { AuthenticationService } from '@app/components/core/services/authentication.service';

@Injectable()
export class UsageAlertFilterBarService extends UsageFilterBarService {

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService,
    foldersService: IFoldersApiService,
    domainsService: IDomainsService,
    labelsService: LabelService,
    accountsService: AccountsService,
    reportCardsService: ReportCardsApiService,
    authenticationService: AuthenticationService
  ) {
    super(storage, cacheReset, foldersService, domainsService, labelsService, accountsService, reportCardsService, authenticationService);
  }

  // Overriding methods in base class. Filter bar service doesn't need to deal with
  // read/write to local storage.
  protected initializeFiltersFromLocalStorage() {}
  updateStorage(): void {
    const uniqueFilters = this.createUniqueArrayOfFilters(this.filters);

    this.anyFiltersUpdates$.next(uniqueFilters);
  }
}
