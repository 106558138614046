import { OnChanges, Directive, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ValidateMessages } from '../../../form/validate-messages.service';

@Directive({
  selector: '[validate-form]'
})
export class ValidateFormDirective implements OnChanges, OnInit {

  @Input() submitted: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() formName: string;

  constructor(private validateMessages: ValidateMessages) {}
  
  ngOnInit() {
    this.validateMessages.registerForm(this.formName, this.formGroup);
  }

  ngOnChanges() {
    if (this.submitted) {
      this.markFormGroupTouched(this.formGroup);
    }
  }

  /**
   * Marks all controls in a form group as touched
   */
  markFormGroupTouched(formGroup: UntypedFormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if ((<UntypedFormGroup>control).controls) {
        this.markFormGroupTouched(<UntypedFormGroup>control);
      }
    });
  }
}
