import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RecipientsFormControlComponent } from './recipients-form-control.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
  ],
  declarations: [
    RecipientsFormControlComponent
  ],
  exports: [
    RecipientsFormControlComponent
  ]
})
export class RecipientsFormControlModule {
}
