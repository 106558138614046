import { Injectable } from '@angular/core';
import conditionalValidator from '@app/components/shared/validators/conditional-validator';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { delay, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { datasourceAllTagsOnly } from './comparison-variable-selector.constants';
import { IComparisonVariableSelectorForm } from './comparison-variable-selector.models';

@Injectable({
  providedIn: 'root'
})
export class ComparisonVariableSelectorService {

  constructor(private formBuilder: UntypedFormBuilder) { }

  createTagGroup(destroy$: Subject<void>, source?: IComparisonVariableSelectorForm): UntypedFormGroup {
    const typeValidator = conditionalValidator(
      control => control.parent.value.tagId !== datasourceAllTagsOnly.key,
      Validators.required
    );
  
    const tagGroup = this.formBuilder.group({
      tagId: this.formBuilder.control(source ? source.tagId : '', Validators.required),
      type: this.formBuilder.control(source ? source.type : '', typeValidator),
      selectedVariables: this.formBuilder.control(source && source.selectedVariables ? source.selectedVariables : [])
    });
    tagGroup.get('tagId').valueChanges.pipe(
      delay(100),
      takeUntil(destroy$)
    ).subscribe(() => {
      tagGroup.get('type').updateValueAndValidity();
      tagGroup.get('selectedVariables').setValue([]);
    });
    return tagGroup;
  }

}
