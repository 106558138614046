<span class="header-container" [formGroup]="formGroup">
  <mat-icon>inbox</mat-icon>

  <op-email-inbox-name-control
    [control]="nameControl"
    [initialMode]="modalType !== EEmailInboxModalType.ReadOnly ? EEmailInboxNameControlMode.Edit : EEmailInboxNameControlMode.View"
    [modalType]="modalType"
    (modeChanged)="triggerLabelsRerendering()"
  >
  </op-email-inbox-name-control>

  <op-label-manager
    [isReadOnly]="modalType === EEmailInboxModalType.ReadOnly"
    [selectedLabels]="labelsArray.value"
    (onLabelCreated)="createLabel($event)"
    (onLabelSelected)="addLabel($event)"
    (onLabelRemoved)="removeLabel($event)"
  >
  </op-label-manager>
</span>
