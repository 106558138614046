<op-modal [leftFooterButtons]="[]"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Add New Cookie"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="add-new-cookie">
      <form [formGroup]="newCookieForm" class="new-cookie-form">
        <div class="cookie-name cookie-input">
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Cookie Name</mat-label>
            <input placeholder="Enter Cookie Name" matInput formControlName="name" autocomplete="off">
            <mat-error *ngIf="name.errors?.validRegexes"
                       class="validation-error"
                       matTooltip="You must enter a valid regular expression">
              You must enter a valid regular expression
            </mat-error>
            <mat-error *ngIf="name.errors?.validRegexesSnowflake"
                       class="validation-error"
                       matTooltip="{{name.errors?.validRegexesSnowflake?.errorMessage}}">
              {{ name.errors?.validRegexesSnowflake?.errorMessage }}
            </mat-error>
          </mat-form-field>
          <mat-checkbox color="primary"
                        formControlName="nameMatchAny"
                        (change)="onNameMatchAnyChanged($event.checked)">Any Cookie Name</mat-checkbox>
          <mat-checkbox color="primary"
                        formControlName="nameRegex"
                        (change)="onNameMatchRegexChanged($event.checked)">Match RegEx</mat-checkbox>
        </div>

        <div class="cookie-domain cookie-input">
          <mat-form-field floatLabel="always" appearance="outline">
            <mat-label>Cookie Domain</mat-label>
            <input matInput placeholder="Enter Domain" formControlName="domain" autocomplete="off">
            <mat-error *ngIf="domain.errors?.urlWithOptionalProtocol">You must enter a valid domain</mat-error>
            <mat-error *ngIf="domain.errors?.validRegexes"
                       class="validation-error"
                       matTooltip="You must enter a valid regular expression">
              You must enter a valid regular expression
            </mat-error>
            <mat-error *ngIf="domain.errors?.validRegexesSnowflake"
                       class="validation-error"
                       matTooltip="{{domain.errors?.validRegexesSnowflake?.errorMessage}}">
              {{ domain.errors?.validRegexesSnowflake?.errorMessage }}
            </mat-error>
          </mat-form-field>
          <mat-checkbox color="primary" (change)="onDomainMatchRegexChanged($event.checked)" formControlName="domainRegex">Match RegEx</mat-checkbox>
        </div>
      </form>
    </div>
  </ng-template>
</op-modal>
