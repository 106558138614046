import { Route } from '@angular/router';
import {
  ManageAccountsRouteName,
  AdminPortalRouteName,
  ItemFinderRouteName,
  TagDatabaseRouteName,
  TagDatabaseLibraryRouteName,
  TagDatabaseRequestCheckRouteName,
  TagDatabaseViewTagRouteName,
  VerifyAccessRouteName,
  CleanUpRouteName,
  SystemStatusRouteName,
  SystemStatusAuditsRouteName,
  SystemStatusWebJourneysRouteName,
  AdminPortalPaths, AdminPortalTitles,
  ComponentLibraryRouteName,
} from './admin-portal.constants';
import { TagDatabaseRequestCheckComponent } from './tag-database/request-check/request-check.component';
import { AdminPortalComponent } from './admin-portal.component';
import { AdminPortalGuard } from './admin-portal.guard';
import { SystemStatusComponent } from './system-status/system-status.component';
import { ItemFinderComponent } from '@app/components/admin-portal/item-finder/item-finder.component';
import { VerifyAccessComponent } from '@app/components/admin-portal/verify-access/verify-access.component';
import { TagDatabaseLibraryComponent } from '@app/components/admin-portal/tag-database/library/tag-database-library.component';
import { ViewTagComponent } from '@app/components/admin-portal/tag-database/view-tag/view-tag.component';
import { TagDatabaseContainerComponent } from '@app/components/admin-portal/tag-database/tag-database-container.component';
import { CleanUpComponent } from '@app/components/admin-portal/clean-up/clean-up.component';
import { SystemStatusAuditsComponent } from '@app/components/admin-portal/system-status/audits/system-status-audits.component';
import { SystemStatusWebJourneysComponent } from '@app/components/admin-portal/system-status/web-journeys/system-status-web-journeys.component';
import { ManageAccountsComponent } from './manage-accounts/manage-accounts.component';
import { componentLibrary} from './component-library/component-library.routes';

export const AdminPortalFutureState: Route = {
  path: AdminPortalPaths.base,
  canLoad: [AdminPortalGuard],
  loadChildren: () => import('./admin-portal.module').then(mod => mod.AdminPortalModule),
};

const manageAccounts: Route = {
  path: AdminPortalPaths.accounts,
  component: ManageAccountsComponent,
  data: {
    stateName: ManageAccountsRouteName,
    title: AdminPortalTitles.manageAccounts,
  }
};

// query params: search
const itemFinder: Route = {
  path: AdminPortalPaths.itemFinder,
  component: ItemFinderComponent,
  data: {
    stateName: ItemFinderRouteName,
    title: AdminPortalTitles.itemFinder,
  }
};

const tagDatabaseLibrary: Route = {
  path: AdminPortalPaths.tagDatabaseLibrary,
  component: TagDatabaseLibraryComponent,
  data: {
    stateName: TagDatabaseLibraryRouteName,
    title: AdminPortalTitles.tagDatabaseLibrary,
  }
};

const tagDatabaseRequestCheck: Route = {
  path: AdminPortalPaths.tagDatabaseRequestCheck,
  component: TagDatabaseRequestCheckComponent,
  data: {
    stateName: TagDatabaseRequestCheckRouteName,
    title: AdminPortalTitles.tagDatabaseRequestCheck,
  }
};

const tagDatabaseViewTag: Route = {
  path: AdminPortalPaths.tagDatabaseViewTag,
  component: ViewTagComponent,
  data: {
    stateName: TagDatabaseViewTagRouteName,
    title: AdminPortalTitles.tagDatabaseViewTag,
  }
};

const tagDatabase: Route = {
  path: AdminPortalPaths.tagDatabase,
  component: TagDatabaseContainerComponent,
  data: {
    stateName: TagDatabaseRouteName,
    title: AdminPortalTitles.tagDatabase,
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: tagDatabaseLibrary.path },
    tagDatabaseLibrary,
    tagDatabaseRequestCheck,
    tagDatabaseViewTag
  ]
};

// query params: url
const verifyAccess: Route = {
  path: AdminPortalPaths.verifyAccess,
  component: VerifyAccessComponent,
  data: {
    stateName: VerifyAccessRouteName,
    title: AdminPortalTitles.verifyAccess,
  }
};

const cleanUp: Route = {
  path: AdminPortalPaths.cleanUp,
  component: CleanUpComponent,
  data: {
    stateName: CleanUpRouteName,
    title: AdminPortalTitles.cleanUp,
  }
};

const systemStatusAudits: Route = {
  path: AdminPortalPaths.systemStatusAudits,
  component: SystemStatusAuditsComponent,
  data: {
    stateName: SystemStatusAuditsRouteName,
    title: AdminPortalTitles.systemStatusAudits,
  }
};

const systemStatusWebJourneys: Route = {
  path: AdminPortalPaths.systemStatusWebJourneys,
  component: SystemStatusWebJourneysComponent,
  data: {
    stateName: SystemStatusWebJourneysRouteName,
    title: AdminPortalTitles.systemStatusWebJourneys,
  }
};

const systemStatus: Route = {
  path: AdminPortalPaths.systemStatus,
  component: SystemStatusComponent,
  data: {
    stateName: SystemStatusRouteName,
    title: AdminPortalTitles.systemStatus,
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: systemStatusAudits.path },
    systemStatusAudits,
    systemStatusWebJourneys,
  ]
};


export const AdminPortalRoutes: Route = {
  path: '',
  component: AdminPortalComponent,
  data: {
    stateName: AdminPortalRouteName,
    title: AdminPortalTitles.base,
    fullScreen: true,
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: manageAccounts.path },
    manageAccounts,
    itemFinder,
    tagDatabase,
    verifyAccess,
    cleanUp,
    systemStatus,
    componentLibrary,
  ],
};
