export enum EConsentCategoryCreateStep {
  SETUP,
  COOKIES,
  TAGS,
  REQUEST_DOMAINS
}

export enum EConsentCategorySourceChipType {
  ACCOUNT,
  GEO_LOCATION
}

export enum EConsentCategorySourceSortBy {
  TagName = 'tag_name',
  CookieName = 'cookie_name',
  CookieDomain = 'cookie_domain',
  RequestDomain = 'request_domain'
}
