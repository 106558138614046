import { RouteBuilder } from '@app/components/shared/services/route-builder.service';
import { AdminPortalPaths, AdminPortalTitles, ComponentLibraryRouteName } from '../admin-portal.constants';

export const ComponentLibraryButtonRouteName = `${ComponentLibraryRouteName}.button`;
export const ComponentLibraryTabsRouteName = `${ComponentLibraryRouteName}.tabs`;
export const ComponentLibraryFileUploadRouteName = `${ComponentLibraryRouteName}.file_upload`;
export const ComponentLibraryReportWidgetsRouteName = `${ComponentLibraryRouteName}.report_widgets`;
export const ComponentLibraryAccordionRouteName = `${ComponentLibraryRouteName}.accordion`;
export const ComponentLibraryBetaLabelRouteName = `${ComponentLibraryRouteName}.beta_label`;
export const ComponentLibraryActionsMenuRouteName = `${ComponentLibraryRouteName}.actions_menu`;
export const ComponentLibraryBreadcrumbRouteName = `${ComponentLibraryRouteName}.breadcrumb`;
export const ComponentLibraryEditDataLayerModalRouteName = `${ComponentLibraryRouteName}.edit_data_layer_modal`;
export const ComponentLibraryFilterPillRouteName = `${ComponentLibraryRouteName}.filter_pill`;
export const ComponentLibraryFilterSpinnerRouteName = `${ComponentLibraryRouteName}.filter_spinner`;
export const ComponentLibraryChipManagerRouteName = `${ComponentLibraryRouteName}.chip_manager`;
export const ComponentLibraryLabelManagerRouteName = `${ComponentLibraryRouteName}.label_manager`;
export const ComponentLibraryMenuRouteName = `${ComponentLibraryRouteName}.menu`;
export const ComponentLibraryModalRouteName = `${ComponentLibraryRouteName}.modal`;
export const ComponentLibraryProgressBarRouteName = `${ComponentLibraryRouteName}.progress_bar`;
export const ComponentLibrarySectionDividerRouteName = `${ComponentLibraryRouteName}.section_divider`;
export const ComponentLibrarySelectRouteName = `${ComponentLibraryRouteName}.select`;
export const ComponentLibrarySelectCreateRouteName = `${ComponentLibraryRouteName}.select_create`;
export const ComponentLibrarySelectWithIconsRouteName = `${ComponentLibraryRouteName}.select_with_icons`;
export const ComponentLibrarySnackbarRouteName = `${ComponentLibraryRouteName}.snackbar`;
export const ComponentLibrarySortIndicatorRouteName = `${ComponentLibraryRouteName}.sort_indicator`;
export const ComponentLibrarySplashPageBasicRouteName = `${ComponentLibraryRouteName}.splash_page_basic`;
export const ComponentLibraryStandardsSelectorRouteName = `${ComponentLibraryRouteName}.standards_selector`;
export const ComponentLibraryTooltipRouteName = `${ComponentLibraryRouteName}.tooltip`;
export const ComponentLibraryOpenMenuOnHoverRouteName = `${ComponentLibraryRouteName}.open_menu_on_hover`;
export const ComponentLibraryHorizontalBarChartRouteName = `${ComponentLibraryRouteName}.horizontal_bar_chart`;
export const ComponentLibraryTagImageRouteName = `${ComponentLibraryRouteName}.tag_image`;

export const ComponentLibraryPaths = {
  button: 'button',
  tabs: 'tabs',
  fileUpload: 'file-upload',
  reportWidgets: 'report-widgets',
  accordion: 'accordion',
  betaLabel: 'beta-label',
  actionsMenu: 'actions-menu',
  breadcrumb: 'breadcrumb',
  editDataLayerModal: 'edit-data-layer-modal',
  filterPill: 'filter-pill',
  filterSpinner: 'filter-spinner',
  chipManager: 'chip-manager',
  labelManager: 'label-manager',
  menu: 'menu',
  modal: 'modal',
  progressBar: 'progress-bar',
  sectionDivider: 'section-divider',
  select: 'select',
  selectCreate: 'select-create',
  selectWithIcons: 'select-with-icons',
  snackbar: 'snackbar',
  sortIndicator: 'sort-indicator',
  splashPageBasic: 'splash-page-basic',
  standardsSelector: 'standards-selector',
  tooltip: 'tooltip',
  openMenuOnHover: 'open-menu-on-hover',
  horizontalBarChart: 'horizontal-bar-chart',
  tagImage: 'tag-image'
}

export const ComponentLibraryUrlBuilders = {
  componentLibraryButton: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.button]),
  componentLibraryFileUpload: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.fileUpload]),
  componentLibraryTabs: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.tabs]),
  componentLibraryReportWidgets: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.reportWidgets]),
  componentLibraryAccordion: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.accordion]),
  componentLibraryBetaLabel: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.betaLabel]),
  componentLibraryActionsMenu: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.actionsMenu]),
  componentLibraryBreadcrumb: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.breadcrumb]),
  componentLibraryEditDataLayerModal: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.editDataLayerModal]),
  componentLibraryFilterPill: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.filterPill]),
  componentLibraryFilterSpinner: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.filterSpinner]),
  componentLibraryChipManager: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.chipManager]),
  componentLibraryLabelManager: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.labelManager]),
  componentLibraryMenu: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.menu]),
  componentLibraryModal: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.modal]),
  componentLibraryProgressBar: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.progressBar]),
  componentLibrarySectionDivider: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.sectionDivider]),
  componentLibrarySelect: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.select]),
  componentLibrarySelectCreate: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.selectCreate]),
  componentLibrarySelectWithIcons: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.selectWithIcons]),
  componentLibrarySnackbar: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.snackbar]),
  componentLibrarySortIndicator: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.sortIndicator]),
  componentLibrarySplashPageBasic: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.splashPageBasic]),
  componentLibraryStandardsSelector: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.standardsSelector]),
  componentLibraryTooltip: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.tooltip]),
  componentLibraryOpenMenuOnHover: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.openMenuOnHover]),
  componentLibraryHorizontalBarChart: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.horizontalBarChart]),
  componentLibraryTagImage: () => RouteBuilder.buildUrl([AdminPortalPaths.base, AdminPortalPaths.componentLibrary, ComponentLibraryPaths.tagImage]),
}

export const ComponentLibraryTitles = {
  button: 'Button',
  tabs: 'Tabs',
  fileUpload: 'File Upload',
  reportWidgets: 'Report Widgets',
  accordion: 'Accordion',
  betaLabel: 'Beta Label',
  actionsMenu: 'Actions Menu',
  breadcrumb: 'Breadcrumb',
  editDataLayerModal: 'Edit Data Layer Modal',
  filterPill: 'Filter Pill',
  filterSpinner: 'Filter Spinner',
  chipManager: 'Chip Manager',
  labelManager: 'Label Manager',
  menu: 'Menu',
  modal: 'Modal',
  progressBar: 'Progress Bar',
  sectionDivider: 'Section Divider',
  select: 'Select',
  selectCreate: 'Select Create',
  selectWithIcons: 'Select w/ Icons',
  snackbar: 'Snackbar',
  sortIndicator: 'Sort Indicator',
  splashPageBasic: 'Splash Page Basic',
  standardsSelector: 'Standards Selector',
  tooltip: 'Tooltip',
  openMenuOnHover: 'Open Menu On Hover',
  horizontalBarChart: 'Horizontal Bar Chart',
  tagImage: 'Tag Image'
}

export const componentLibrarySection = {
  sectionName: AdminPortalTitles.componentLibrary,
  navs: [
    {
      title: ComponentLibraryTitles.accordion,
      link: ComponentLibraryUrlBuilders.componentLibraryAccordion(),
    },
    {
      title: ComponentLibraryTitles.actionsMenu,
      link: ComponentLibraryUrlBuilders.componentLibraryActionsMenu(),
    },
    {
      title: ComponentLibraryTitles.betaLabel,
      link: ComponentLibraryUrlBuilders.componentLibraryBetaLabel(),
    },
    {
      title: ComponentLibraryTitles.breadcrumb,
      link: ComponentLibraryUrlBuilders.componentLibraryBreadcrumb(),
    },
    {
      title: ComponentLibraryTitles.button,
      link: ComponentLibraryUrlBuilders.componentLibraryButton(),
    },
    {
      title: ComponentLibraryTitles.fileUpload,
      link: ComponentLibraryUrlBuilders.componentLibraryFileUpload(),
    },
    {
      title: ComponentLibraryTitles.reportWidgets,
      link: ComponentLibraryUrlBuilders.componentLibraryReportWidgets(),
    },
    {
      title: ComponentLibraryTitles.tabs,
      link: ComponentLibraryUrlBuilders.componentLibraryTabs(),
    },
    {
      title: ComponentLibraryTitles.editDataLayerModal,
      link: ComponentLibraryUrlBuilders.componentLibraryEditDataLayerModal(),
    },
    {
      title: ComponentLibraryTitles.filterPill,
      link: ComponentLibraryUrlBuilders.componentLibraryFilterPill(),
    },
    {
      title: ComponentLibraryTitles.filterSpinner,
      link: ComponentLibraryUrlBuilders.componentLibraryFilterSpinner(),
    },
    {
      title: ComponentLibraryTitles.chipManager,
      link: ComponentLibraryUrlBuilders.componentLibraryChipManager(),
    },
    {
      title: ComponentLibraryTitles.labelManager,
      link: ComponentLibraryUrlBuilders.componentLibraryLabelManager(),
    },
    {
      title: ComponentLibraryTitles.menu,
      link: ComponentLibraryUrlBuilders.componentLibraryMenu(),
    },
    {
      title: ComponentLibraryTitles.modal,
      link: ComponentLibraryUrlBuilders.componentLibraryModal(),
    },
    {
      title: ComponentLibraryTitles.progressBar,
      link: ComponentLibraryUrlBuilders.componentLibraryProgressBar(),
    },
    {
      title: ComponentLibraryTitles.select,
      link: ComponentLibraryUrlBuilders.componentLibrarySelect(),
    },
    {
      title: ComponentLibraryTitles.sectionDivider,
      link: ComponentLibraryUrlBuilders.componentLibrarySectionDivider(),
    },
    {
      title: ComponentLibraryTitles.selectCreate,
      link: ComponentLibraryUrlBuilders.componentLibrarySelectCreate(),
    },
    {
      title: ComponentLibraryTitles.selectWithIcons,
      link: ComponentLibraryUrlBuilders.componentLibrarySelectWithIcons(),
    },
    {
      title: ComponentLibraryTitles.snackbar,
      link: ComponentLibraryUrlBuilders.componentLibrarySnackbar(),
    },
    {
      title: ComponentLibraryTitles.sortIndicator,
      link: ComponentLibraryUrlBuilders.componentLibrarySortIndicator(),
    },
    {
      title: ComponentLibraryTitles.splashPageBasic,
      link: ComponentLibraryUrlBuilders.componentLibrarySplashPageBasic(),
    },
    {
      title: ComponentLibraryTitles.standardsSelector,
      link: ComponentLibraryUrlBuilders.componentLibraryStandardsSelector(),
    },
    {
      title: ComponentLibraryTitles.tooltip,
      link: ComponentLibraryUrlBuilders.componentLibraryTooltip(),
    },
    {
      title: ComponentLibraryTitles.openMenuOnHover,
      link: ComponentLibraryUrlBuilders.componentLibraryOpenMenuOnHover(),
    },
    {
      title: ComponentLibraryTitles.horizontalBarChart,
      link: ComponentLibraryUrlBuilders.componentLibraryHorizontalBarChart(),
    },
    {
      title: ComponentLibraryTitles.tagImage,
      link: ComponentLibraryUrlBuilders.componentLibraryTagImage(),
    }
  ]
};