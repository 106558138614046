import { OpModalService } from '@app/components/shared/components/op-modal/shared/op-modal.service';
import { AngularNames, Names } from '@app/moonbeamConstants';
import { RulesService } from '../../rules/rules.service';
import { ToastService } from '@app/components/utilities/toastService';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { userIsGuest } from '@app/authUtils';
import { AccountsService } from '@app/components/account/account.service';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { IRule, ITagVariableSnapshotId } from '@app/components/rules/rules.models';
import {
  IFailedRuleReport, IFailedRuleTagReport,
  INotAppliedRuleReport,
  IPassedRuleReport, IRuleReportItem
} from "@app/components/reporting/rules/rule.models";

type RuleReportType = 'single' | 'multiple';

export class RulesReportController {

  failedRuleReports: Array<IFailedRuleReport>;
  notAppliedRuleReports: Array<INotAppliedRuleReport>;
  passedRuleReports: Array<IPassedRuleReport>;
  enableRuleEditing?: boolean;

  isReadOnly: boolean = true;

  static $inject = [
    Names.NgServices.opModalService,
    Names.NgServices.rules,
    Names.Services.ngToast,
    Names.Services.toastService,
    Names.Services.eventManager,
    Names.NgServices.accountsService,
    AngularNames.scope,
  ];

  constructor(private modalService: OpModalService,
              private rulesService: RulesService,
              private ngToast: any,
              private toastService: ToastService,
              private eventManager: IEventManager,
              private accountsService: AccountsService,
              private $scope
  ) {
    this.accountsService.getUser().subscribe(user => {
      this.isReadOnly = userIsGuest(user);
    });

    if (Array.isArray(this.failedRuleReports)) {
      this.failedRuleReports.forEach(report => {
        report['checkTimeTableItems'] = [report.checkTimes];
      });
    }
  }

  copyToClipboard(value: string) {
    const tempInput = document.createElement('input');
    tempInput.style.position = 'absolute';
    tempInput.style.left = '-9999px';
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }

  editRuleById(ruleId: number, $event): void {
    $event.stopPropagation();

    this.rulesService.getOriginalRuleId(ruleId).subscribe(response => {
      ruleId = response['originalRuleId'];

      this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
        disableClose: true,
        data: {
          ruleId,
          mode: ERuleSetupMode.edit
        }
      }, 'rule-setup-modal').afterClosed()
        .subscribe()
    });
  }

  renderMissingTags(tagResults: Array<IFailedRuleTagReport>): string {
    var result = '';
    var filteredTagResult: Array<IFailedRuleTagReport> = [];
    for (let i = 0; i < tagResults.length; i++) {
      if (tagResults[i].missed) filteredTagResult.push(tagResults[i]);
    }
    for (let i = 0; i < filteredTagResult.length; i++) {
      result += filteredTagResult[i].tagName;
      if (filteredTagResult.length != 1 && i != filteredTagResult.length - 1) result += ', ';
    }
    return result;
  }

  hasMissingTags(tagResults: Array<IFailedRuleTagReport>): boolean {
    for (let i = 0; i < tagResults.length; i++) {
      if (tagResults[i].missed) return true;
    }
    return false;
  }

  getOriginalRuleId(item: IRuleReportItem) {
    if(item.snapshotId === 0 && item.name === 'account') {
      this.rulesService.getOriginalRuleIdFromTagId(item.conditionId).subscribe(
        (response: ITagVariableSnapshotId) => {
          this.updateRule(response, item, true);
        },
        error => {
          const failureMessage = 'The rule you are attempting to edit has been deleted.';
          this.ngToast.create(this.toastService.generateMessagesToastConfig(failureMessage));
        }
      );
    } else {
      this.rulesService.getOriginalRuleConditionId(item.snapshotId).subscribe(
        (response: ITagVariableSnapshotId) => {
          this.updateRule(response, item);
        },
        error => {
          const failureMessage = 'The rule you are attempting to edit has been deleted.';
          this.ngToast.create(this.toastService.generateMessagesToastConfig(failureMessage));
        }
      );
    }
  }

  updateRule(originalIds: ITagVariableSnapshotId, item: IRuleReportItem, account?: boolean) {
    this.rulesService.getRule(originalIds.originalRuleId).subscribe(
      (rule: IRule) => {
        if(rule.fromTemplate) {
          const message = `This is a pre-defined rule and cannot be edited.`;
          this.generateToastMessage(message);
          return;
        }

        let foundCondition = false;

        for(let i = 0; i < rule.tags.length; i++) {
          let tag = rule.tags[i];

          if(account) {
            if(tag.id === originalIds.originalConditionId) {
              tag.account = item.actual;
              foundCondition = true;
              break;
            }
          } else {
            if(tag.tagId === item.tagId) {
              for(let x = 0; x < tag.variables.length; x++) {
                let variable = tag.variables[x];

                if(variable.id === originalIds.originalConditionId) {
                  variable.value = item.actual;
                  foundCondition = true;
                  break;
                }
              }
              break;
            }
          }
        }

        if(foundCondition) {
          this.rulesService.updateRule(rule).subscribe(response => {
            const message = `Your rule was successfully updated! Please be aware that the<br>changes will not be reflected until the journey has been run again.`;
            this.generateToastMessage(message);
          });
        } else {
          // condition was deleted
          const message = 'The rule condition you are attempting to modify no longer exists.';
          this.generateToastMessage(message);
        }
      },
      (error) => {
        // catch all
        const message = 'There was an error processing your request.';
        this.generateToastMessage(message);
      }
    );
  }

  private generateToastMessage(message: string) {
    this.ngToast.create(this.toastService.generateMessagesToastConfig(message));
  }

  removeRule(ruleId: number, event) {
    event.stopPropagation();

    this.rulesService.getOriginalRuleId(ruleId).subscribe(
      response => {
        this.eventManager.publish<any>('rulesReportController', response['originalRuleId']);
      },
      error => {
        const message = 'The rule you are attempting to remove no longer exists.';
        this.generateToastMessage(message);
      }
    );
  }
}
