import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { ValidateMessages } from '@app/components/form/validate-messages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface IErrorMessage {
  type: string;
  message: string;
}

/**
 * @param {string} formControlPath. Path to FormControl. In format [formName].[formControlName]. Can be nested
 *
 * @description
 * This component is used to display error messages for invalid fields in form
 * It renders styled error message.
 *
 * Please, note, this component can be used JUST with validate-form directive.
 * This directive saves FormGroup in the validateMessages service and thus we can get error messages.
 */
@Component({
  selector: 'op-error-message',
  templateUrl: './op-error-message.component.html',
  styleUrls: ['./op-error-message.component.scss']
})
export class OpErrorMessageComponent implements OnInit, OnDestroy {

  @Input() formControlPath: string;
  @Input() relativePosition: boolean = false;

  private destroy$ = new Subject();

  formControl: UntypedFormControl;
  controlMessages: any;

  formName: string;
  controlPath: string[];

  errorMessage: IErrorMessage;

  constructor(private validate: ValidateMessages) {
  }

  ngOnInit(): void {
    if (!this.formControlPath) return;

    let paths = this.formControlPath.split('.');
    this.formName = paths.shift();
    this.controlPath = paths;

    const parentForm = this.validate.getFormByName(this.formName);
    if (parentForm) {
      this.formControl = this.getFormControl(parentForm, paths);
      this.controlMessages = this.getControlMessages(this.formName, this.controlPath);
      this.subscribeOnChanges();
      this.checkOnErrors();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private getFormControl(parentForm: UntypedFormGroup, paths: string[]): UntypedFormControl {
    let control: AbstractControl = parentForm;
    for (let i = 0; i < paths.length; i++) {
      control = (<UntypedFormGroup>control).controls[paths[i]];
      if (!(<UntypedFormGroup>control).controls) {
        return <UntypedFormControl>control;
      }
    }
  }

  private subscribeOnChanges(): void {
    this.formControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(newValue => {
      this.checkOnErrors();
    });
    this.formControl.statusChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(status => {
      this.checkOnErrors();
    });
  }

  private getControlMessages(formName: string, paths: Array<string>): Object {
    const msgs = ValidateMessages.ngMessages[formName];
    return (typeof msgs === 'undefined') ? {} : this.getNestedProperty(paths, msgs);
  }

  private getNestedProperty(pathArr: string[], nestedObj: any): any {
    return pathArr.reduce(
      (acc: any, key: string) => (acc && acc[key] !== 'undefined') ? acc[key] : {},
      nestedObj
    );
  }

  private checkOnErrors(): void {
    const validators = this.formControl && this.formControl.errors;
    if (!validators) {
      this.errorMessage = null;
    }
    for (const key in validators) {
      if (this.formControl.hasError(key)) {
        const message = (this.controlMessages && this.controlMessages[key]) ?
          this.controlMessages[key] : ValidateMessages.defaultMessages[key];
        this.errorMessage = {type: key, message};
      }
    }
  }

}
