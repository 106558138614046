<div id="systemStatus" class="flex-col flex-stretch">
  <div class="action-button-group refresh">
    <button mat-flat-button color="primary" class="action-button" (click)="refresh()">
      Refresh
      <mat-icon iconPositionEnd>autorenew</mat-icon>
      {{timeUntilRefresh()}}
    </button>
    <button mat-flat-button color="primary" class="action-button" *ngIf="!isPaused()" (click)="pause()">
      Pause
      <mat-icon iconPositionEnd>pause</mat-icon>
    </button>
    <button mat-flat-button color="primary" class="action-button" *ngIf="isPaused()" (click)="resume()">
      Resume
      <mat-icon iconPositionEnd>play</mat-icon>
    </button>
  </div>

  <router-outlet></router-outlet>
</div>
