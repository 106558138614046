import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IPrimaryTag, ManageTagsService } from '../../account/manageTags/manageTagsService';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { MatExpansionPanel } from '@angular/material/expansion';
import { userIsAdmin } from '@app/authUtils';
import { IPrimaryTagSettings } from './manage-tags.models';
import { defaultTagSettings } from './manage-tags.constants';
import { AccountsService } from '@app/components/account/account.service';
import { AddPrimaryTagModalComponent } from './add-primary-tag-modal/add-primary-tag-modal.component';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { forkJoin, from } from 'rxjs';
import { IUiTag } from '@app/components/tag-database/tag-database.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss']
})
export class ManageTagsComponent implements OnInit {

  @ViewChildren(MatExpansionPanel) tagPanels: QueryList<MatExpansionPanel>;

  accountTags: IUiTag[];
  accountTagsCache: IUiTag[];
  primaryTags: IPrimaryTag[];
  numTags: number;
  validationTagLimit: number;
  validationTagCount: number = 0;
  disableComparisonToggle: boolean = false;
  userIsAdmin: boolean = false;
  infoIsExpanded: boolean = false;

  constructor(private manageTagsService: ManageTagsService,
              private modalService: OpModalService,
              private ref: ChangeDetectorRef,
              private uiTagService: UiTagService,
              private accountsService: AccountsService) {}

  ngOnInit() {
    this.accountsService.getUser().subscribe(user => {
      this.userIsAdmin = userIsAdmin(user);
    });

    const primaryTagsPromise = this.manageTagsService.getPrimaryTags();
    const comparisonSettingsPromise = this.accountsService.getComparisonAccountSettings();
    const comparisonTagsPromise = this.accountsService.getComparisonAccountTags();

    forkJoin([
      from(primaryTagsPromise),
      this.uiTagService.getAllTagsData(),
      from(comparisonSettingsPromise),
      from(comparisonTagsPromise),
    ])
      .subscribe(([primaryTags, [accountTags], productLimits, comparisonTags]) => {
        this.primaryTags = primaryTags.map(t => ({
          ...t,
          icon: UiTagService.getTagIconUrl(t.tagId),
          comparisonsSettings: {
            ...defaultTagSettings,
            tagId: t.tagId
          }
        }));

        comparisonTags.forEach(ct => {
          if (ct.comparisonEnabled) this.validationTagCount++;
          const tag = this.primaryTags.find(t => t.tagId === ct.tagId);
          if (tag) tag.comparisonsSettings = ct;
        });

        this.accountTagsCache = accountTags;
        this.accountTags = this.filterAccountTags();
        this.validationTagLimit = productLimits.maxComparisonTags;
        this.updateNumTags();
        this.sortTags();
        this.checkValidationLimit();
        this.infoIsExpanded = !this.primaryTags.length;
      });
  }

  addPrimaryTag(tag: IUiTag): void {
    const primaryTag: IPrimaryTag = {
      tagId: tag.id,
      name: tag.name,
      icon: UiTagService.getTagIconUrl(tag.id),
      categoryName: this.uiTagService.getTagCategory(tag.tagCategoryId).category,
      enabled: true,
    };

    this.manageTagsService.addPrimaryTag(primaryTag).then(response => {
      response.comparisonsSettings = {
        ...defaultTagSettings,
        tagId: primaryTag.tagId
      };
      this.primaryTags.push(response);
      this.accountTags = this.filterAccountTags();
      this.updateNumTags();
      this.sortTags();

      this.ref.detectChanges();
      const newTagIndex = this.primaryTags.findIndex((primaryTag) => primaryTag === response);
      const newTagPanel = this.tagPanels.toArray()[newTagIndex];
      newTagPanel?.open();
    });
  }

  tagFilteringChanged(tag: IPrimaryTag): void {
    this.manageTagsService.updatePrimaryTagFilter(tag.id, tag.enabled).then(updatedTag => {
      tag = updatedTag;
    });
  }

  preventExpanderToggle(event: MouseEvent) {
    event.stopPropagation();
  }

  updateComparisonState(tag: IPrimaryTag, isEnabled: boolean, clearVars?: boolean): void {
    if (tag.comparisonsSettings.comparisonEnabled !== isEnabled) {
      this.validationTagCount += isEnabled ? 1 : -1;
    }
    tag.comparisonsSettings.comparisonEnabled = isEnabled;
    this.checkValidationLimit();
    const { tagId, ...payload } = tag.comparisonsSettings;
    this.accountsService.updateComparisonAccountTag(tag.tagId, clearVars ? defaultTagSettings : payload);
  }

  checkValidationLimit() {
    this.disableComparisonToggle = this.validationTagCount >= this.validationTagLimit;
  }

  confirmDelete(event: MouseEvent, tag: IPrimaryTag): void {
    event.stopPropagation();
    let modalRef = this.modalService.openConfirmModal({
      data: {
        title: 'Confirm Delete',
        messages: [
          'You are about to delete ' + tag.name + ' from your primary tags.',
          'Are you sure you want to do this?'
        ],
        rightFooterButtons: [
          {
            label: 'Cancel', action: () => {
              modalRef.close();
            },
            primary: false
          },
          {
            label: 'Delete', action: () => {
              // this ensures that if a primary tag is deleted while being
              // enabled for comparisons that we disable it for comparisons
              // so awkward UI bugs don't appear
              if (tag.comparisonsSettings.comparisonEnabled) this.updateComparisonState(tag, false);
              this.deletePrimaryTag(tag);
              const deletedIndex = this.primaryTags.findIndex(primaryTag => primaryTag.tagId === tag.tagId);
              this.tagPanels.toArray()[deletedIndex].close();

              this.primaryTags = this.primaryTags.filter(primaryTag => primaryTag.tagId !== tag.tagId);
              this.accountTags = this.filterAccountTags();
              modalRef.close();
            }, primary: true
          }
        ]
      }
    });
  }

  deletePrimaryTag(tag: IPrimaryTag): void {
    this.manageTagsService.deletePrimaryTag(tag.id).then(() => {
      this.accountTags = this.filterAccountTags();
      this.updateNumTags();
      this.updateComparisonState(tag, false, true);
    });
  }

  onUpdateTagSettings(tagSettings: IPrimaryTagSettings) {
    const { tagId, ...payload } = tagSettings;
    this.accountsService.updateComparisonAccountTag(tagId, payload);
  }

  onTagInfoSave(data) {
    const formData = data.formData;
    const tagData = data.tagData;
    const primaryTag: IPrimaryTag = {
      id: tagData.id,
      tagId: tagData.tagId,
      name: tagData.name,
      icon: tagData.icon,
      categoryName: tagData.categoryName,
      enabled: tagData.enabled,
      owners: formData.tagOwners ? formData.tagOwners : [],
      technicalSpecialists: formData.techSpecialists ? formData.techSpecialists : [],
      vendorContacts: formData.vendorContacts ? formData.vendorContacts : [],
      cost: {
        cost: formData.cost.cost ? formData.cost.cost : '',
        period: formData.cost.period ? formData.cost.period : ''
      },
      notes: formData.notes ? formData.notes : ''
    };

    this.manageTagsService.updatePrimaryTag(primaryTag);
    const updatedIndex = this.primaryTags.findIndex(tag => tagData.tagId === tag.tagId);
    this.tagPanels.toArray()[updatedIndex].close();
  }

  openTagPickerModal() {
    this.modalService
      .openModal(AddPrimaryTagModalComponent, { data: {
        accountTags: this.accountTags,
        userIsAdmin: this.userIsAdmin
      }})
      .afterClosed()
      .subscribe((tag: IUiTag) => {
        if (tag) {
          this.addPrimaryTag(tag);
        }
      });
  }

  private filterAccountTags() {
    let primaryTagIds = this.primaryTags.map(tag => tag.tagId);

    return this.accountTagsCache.filter((accountTag: IUiTag) => {
      return primaryTagIds.indexOf(accountTag.id) == -1;
    });
  }

  private updateNumTags(): void {
    this.numTags = this.primaryTags.length;
  }

  private sortTags() {
    this.primaryTags.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
  }

}
