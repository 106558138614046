import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { IModalService } from '@app/components/modals/modalService';
import { ModalTemplates } from '@app/components/modals/modalTemplates';

/**
 * This is a temporary component, that's used for deep linking:
 * - separate url is visited
 * - full screen modal with manual journey recorder is opened
 * It should be removed after Manual Journey Recorder migration
 */

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manual-journey-recorder-route-container',
  template: ''
})
export class ManualJourneyRecorderRouteContainerComponent {

  constructor(private route: ActivatedRoute,
              private modal: IModalService) {

    const template = ModalTemplates.initTemplate(ModalTemplates.ManualJourney);
    template.modalData = route.snapshot.params;
    this.modal.showFullScreenModal(template, _ => {});

  }

}
