<div id="progressBarWrp" [ngClass]="barSize">
  <div class="progressBarItem" [ngClass]="getItemState(item)" *ngFor="let item of items; let first = first; let last = last; let i = index">
    <div class="separate-line-wrp" *ngIf="!first">
      <div class="line">
        <div class="line-body"></div>
      </div>
    </div>
    <div class="circleWrp ">
      <div class="circleBlock" [ngClass]="{'first': first, 'last': last}">
        <div class="line leftLine" *ngIf="!first">
          <div class="line-body"></div>
        </div>
        <div class="circle">
          <span class="marker number">{{i + 1}}</span>
          <i class="marker tick icon-check-bare"></i>
        </div>
        <div class="line rightLine" *ngIf="!last">
          <div class="line-body"></div>
        </div>
      </div>
      <div class="bottomLable">{{provideLabel(item)}}</div>
    </div>
  </div>
</div>