import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IFailedRuleReport, IFailedRuleTagReport, IRuleReportItem } from '@app/components/reporting/rules/rule.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'failed-rules-report',
  templateUrl: './failed-rules-report.component.html',
  styleUrls: ['./failed-rules-report.component.scss'],
})
export class FailedRulesReportComponent {
  
  @Input() showEditButtons: boolean = true;
  @Input() reports: Array<IFailedRuleReport>;
  @Output() removeRule: EventEmitter<number> = new EventEmitter();
  @Output() editRule: EventEmitter<number> = new EventEmitter();
  @Output() setAsExpected: EventEmitter<IRuleReportItem> = new EventEmitter();
  @Output() deleteVariable: EventEmitter<IRuleReportItem> = new EventEmitter();

  handleEdit($event: MouseEvent, ruleId: number) {
    $event.stopPropagation();
    this.editRule.emit(ruleId);
  }
  
  handleRemove($event: MouseEvent, ruleId: number) {
    $event.stopPropagation();
    this.removeRule.emit(ruleId);
  }

  handleSetAsExpected(ruleReportItem: IRuleReportItem) {
    this.setAsExpected.emit(ruleReportItem);
  }

  handleDeleteVariable(ruleReportItem: IRuleReportItem) {
    this.deleteVariable.emit(ruleReportItem);
  }

  hasMissingTags(reports: IFailedRuleTagReport[]): boolean {
    return reports && reports.reduce((hasMissingTags, report) => hasMissingTags || report.missed, false);
  }

  showSetAsExpected(reportItem: IRuleReportItem): boolean {
    return reportItem.actual && reportItem.expected && reportItem.actual.toLowerCase() !== 'missing variable';
  }

}
