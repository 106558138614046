export interface IFreeTrialAdModalPayload {
  type: EFreeTrialAdModalType;
}

export enum EFreeTrialAdModalType {
  ALERT = 'Alert',
  EXPORT = 'Export',
  RULE = 'Rule',
  CONSENT_CATEGORY = 'Consent Category',
}
