<div class="page-container">
  <div class="title">
    <h3>Requested Exports</h3>
    <op-clearable-input
      placeholderText="Search exports"
      [defaultValue]="initialSearchText"
      (onUserInput)="handleSearch($event)">
    </op-clearable-input>
  </div>
  <h5 class="subtitle">Exports are available for 100 days from the requested date or until the run is no longer available in the product.</h5>
  <export-center-table
    [exportsDTO]="exportsDTO"
    [userIdToUserMap]="userIdToUserMap"
    [dataStatus]="dataStatus"
    (onSort)="handleSort($event)"
    (onPagination)="handlePagination($event)"
    (onCopyLink)="copyLink($event)"
    (onDownloadExport)="downloadExport($event)"
    (onSendReport)="sendReport($event)">
  </export-center-table>
</div>
