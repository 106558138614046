import { ILabel } from '@app/components/shared/services/label.service';
import { SortDirection } from '@angular/material/sort';
import { EConsentCategoryCreateStep } from '@app/components/consent-categories/cc-create/cc-create.enums';
import { IUiTag } from '@app/components/tag-database/tag-database.model';
import { IConsentGroupType } from './sync-onetrust-categorized-cookies-modal/sync-onetrust-categorized-cookies-modal.models';

export interface IConsentCategoryBase {
  name: string;
  notes: string;
  type: EConsentCategoryType;
  id?: number;
  isDefaultCC: boolean;
  cmpData?: ICmpData;
  updatedAt?: string;
}

export interface IConsentCategoryBaseDTO {
  metadata: IPaginationMetaData;
  consentCategories: IConsentCategoryBase[];
}

export interface IConsentCategory {
  name: string;
  notes: string;
  type: EConsentCategoryType;
  id: number;
  itemCounts: IConsentCategoryItemCounts;
  auditCount: number;
  labelIds: number[];
  labels?: ILabel[];
  isDefaultCC: boolean;
  cmpData?: ICmpData;
  cmpId?: string;
  updatedAt?: string;
  cookies?: IConsentCategoryCookie[];
}

export interface ICmpData {
  cmpVendor: string;
  oneTrustCookieGroupDomain: string;
  oneTrustCookieGroupGeo: string;
  oneTrustCookieGroupId: string;
}

export interface IConsentCategoryTableRow {
  id: number;
  name: string;
  auditCount: number;
  type: EConsentCategoryType;
  labels: ILabel[];
  updatedAt?: string;
  itemCounts: IConsentCategoryItemCounts;
}

export interface IConsentCategoryLibraryDTO {
  metadata: { pagination: IPaginationMetaData };
  consentCategories: IConsentCategory[];
}

export interface IConsentCategorySource extends IConsentCategory {
  labelNames: IConsentCategoryLabel[];
}

export interface IConsentCategoryItemCounts {
  tags: number;
  cookies: number;
  requestDomainsAndGeos: number;
}

export interface IConsentCategoryModalData {
  id?: number;
  allLabels?: ILabel[];
  editing: boolean;
  name?: string;
  type?: EConsentCategoryType;
  cookies?: IConsentCategoryCookie[];
  tags?: IConsentCategoryTag[];
  requestDomains?: IConsentCategoryRequestDomain[];
  updatedAt?: string;
  auditId?: number;
  skipCreate?: boolean;
  step?: EConsentCategoryCreateStep;
  useSelectedDataOnlyCreate?: boolean;
  noSelectionDisplay?: boolean;
  isDefaultCC: boolean;
  availableConsentCatType?: string;
}

export interface IConsentCategoryTag {
  tagId: number;
  accounts: string[];
  anyAccount: boolean;
  selected?: boolean;
  existing?: boolean;
  tagName?: string;
}

export interface IConsentCategoryTagDTO {
  tags: IConsentCategoryTag[];
}

export interface IConsentCategoryTagTableRow {
  id: number;
  icon: string;
  name: string;
  accounts: string[];
  tagId: number;
  category: string;
}

export interface INewTagModalData {
  tags: IUiTag[];
  types: IConsentCategoryType[];
}

export interface INewTagData {
  tag: IUiTag;
  accounts: string[];
  type: IConsentCategoryType;
}

export interface IConsentCategoryGeos {
  countriesById: ICountryMap;
  continentsById: IContinentMap;
}

export interface IContinentMap {
  [key: number]: IAuditGeoLocation[];
}

export interface ICountryMap {
  [key: number]: IAuditGeoLocation;
}

export interface IConsentCategoryType {
  name: string;
  value: 'approved' | 'unapproved' | 'required';
}

export interface IConsentCategoryRequestDomain {
  domain: string;
  regex?: boolean;
  locations?: any[];
  locationIds: number[];
  anyLocation: boolean;
  index?: number;
  domainType?: EDomainType;
}

export interface INewRequestDomain {
  domain: string;
  regex: boolean;
  locationIds: number[];
  anyLocation: boolean;
  index?: number;
}

export interface IConsentCategoryRequestDomainDTO {
  requestDomains: IRequestDomain[];
}

export interface IRequestDomainsHash {
  [key: string]: IRequestDomain;
}

export interface IRequestDomain {
  domain: string;
  domainType?: EDomainType;
  regex?: boolean;
  anyLocation: boolean;
  locationIds: number[];
  locations?: any[];
  selected?: boolean;
  existing?: boolean;
  countries?: boolean;
}

export interface ICmpCookieData extends IConsentCategoryCookie {
  type: IConsentGroupType;
}
export interface IConsentCategoryCookieDTO {
  cookies: IConsentCategoryCookie[];
}

export interface IConsentCategoryCookie {
  name: string;
  nameRegex?: boolean;
  nameMatchAny?: boolean;
  description?: string;
  domain: string;
  domainRegex?: boolean;
  expirationDate: string;
  selected?: boolean;
  existing?: boolean;
  sequence?: number;
  index?: number;
  nameType?: ENameType;
  domainType?: EDomainType;
}

export enum ENameType {
  EXACT = 'name_exact_match',
  REGEX = 'name_regex',
  ANY = 'name_any'
}

export enum EDomainType {
  EXACT = 'domain_exact_match',
  REGEX = 'domain_regex',
  ANY = 'domain_any'
}

export interface INewCookie {
  name: string;
  nameRegex: boolean;
  nameMatchAny: boolean;
  domain: string;
  domainRegex: boolean;
  index?: number;
}

export enum EConsentCategoryType {
  ASSIGN = 'assign',
  APPROVED = 'approved',
  UNAPPROVED = 'unapproved'
}

export enum EReportType {
  AUDIT = 'audit',
  WEB_JOURNEY = 'webJourney',
}

export enum EConsentCategoriesType {
  approved = 'Approved',
  unapproved = 'UnApproved'
}

export interface ICCEditParams {
  id: number;
}

export interface IConsentCategoryLabelDTO {
  labels: IConsentCategoryLabel[];
}

export interface IConsentCategoryLabel {
  name: string;
  id: number;
}

export interface IRunInfo {
  itemType: EReportType;
  itemId: number;
  runId: number;
}

export interface IPaginationMetaData {
  totalCount?: number;
  totalPageCount?: number;
  pageSize?: number;
  currentPageSize?: number;
  currentPageNumber?: number;
  cmpVendor?: string;
}

export interface IRunTagsDTO {
  metadata: { pagination: IPaginationMetaData };
  tags: IAuditRunTag[];
}

export interface IRunCookiesDTO {
  metadata: { pagination: IPaginationMetaData };
  cookies: IAuditRunCookie[];
}

export interface IRunRequestDomainsDTO {
  metadata: { pagination: IPaginationMetaData };
  requestDomains: IAuditRunRequestDomain[];
}

export interface IAuditRunTag {
  accounts: string[];
  tagId: number;
  tagName: string;
  selected?: boolean;
}

export interface IAuditRunCookie {
  domain: string;
  name: string;
  selected?: boolean;
}

export interface IAuditRunRequestDomain {
  domain: string;
  countries: IAuditGeoLocation2[];
  selected?: boolean;
}

export interface IAuditGeoLocation {
  countryCode: string;
  countryName: string;
  countryId: number;
  continentId: number;
  continentName: string;
}

export interface IAuditGeoLocation2 {
  countryCode: string;
  countryName: string;
  countryCodeId: number;
  continentId: number;
  continentName: string;
}

export interface ICategorizedCookiesImportPreview {
  fileId: string;
  results: ICategorizedCookies[];
}

export interface ICategorizedCookies {
  consentCategoryName: string;
  noOfNewCookies: number;
  consentCategoryId?: number;
  labels: { id: number, name: string }[]
}

export interface ISnackBarDataObject {
  numAdditional: number;
  topXNew: string[];
  totalNewCookies: number;
  totalExistingCookies: number;
  totalExistingCategories: number;
  totalNewCategories: number;
}

export interface ISnackbarWithDataSourcesDataObject extends ISnackBarDataObject {
  ccCount: number,
  itemCount: number
}

export interface IConsentCategorySummaryDTO {
  metadata: {
    pagination: {
      totalCount: number;
      totalPageCount: number;
      pageSize: number;
      currentPageSize: number;
      currentPageNumber: number;
    }
  },
  consentCategories: IConsentCategoriesLine[]
}

export interface IConsentCategoriesLine {
  name: string;
  notes: string;
  type: EConsentCategoriesType;
  id: number;
}

export interface IConsentCategoriesDTO {
  consentCategories: IConsentCategories[]
}

export interface IConsentCategories {
  name: string;
  notes: string;
  type: EConsentCategoriesType;
  id: number;
  isNoteShown?: boolean;
}

export interface IConsentCategorySnapshot {
  consentCategoryId: number;
  consentCategorySnapshotId: number;
  consentCategoryConfigExists: boolean;
  name: string;
  notes: string;
  type: EConsentCategoryType;
  isNoteShown?: boolean;
}

export interface IConsentCategoryAssignedLabelsDTO {
  labels: IConsentCategoryAssignedLabels[]
}

export interface IConsentCategoryAssignedLabels {
  id: number;
  name: string;
}

export interface IConsentCategoryLibraryPagination {
  page: number;
  pageSize: number;
}

export interface ICCSort {
  sortBy: ECCSortColumns;
  sortDesc: boolean;
  sortDir: SortDirection;
}

export enum ECCSortColumns {
  'NAME' = 'name',
  'TYPE' = 'type',
  'UPDATED_AT' = 'updated_at',
}

export interface ICCLibraryQueryParams {
  page?: Number;
  pageSize?: number;
  name?: string;
  labels?: number[];
  type?: EConsentCategoryType;
  sortBy?: string;
  sortDesc?: boolean;
  cmpVendor?: string;
}
