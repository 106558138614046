import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { ITagInitiatorNode } from './tag-initiators.models';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import { TagInitiatorsFullScreenComponent } from '../tag-initiators-full-screen/tag-initiators-full-screen.component';
import { OpModalService } from '../op-modal';
import { TagInitiatorsService } from './tag-initiators.service';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { IApiErrorResponse } from '@app/components/core/services/api.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tag-initiators',
  templateUrl: './tag-initiators.component.html',
  styleUrls: ['./tag-initiators.component.scss']
})
export class TagInitiatorsComponent implements OnChanges {

  loading: boolean = true;
  failedToLoadMessage: string;
  initiatorsData: ITagInitiatorNode;

  @Input() showFullScreenButton?: boolean = false;
  @Input() url: string;
  @Input() auditId: number;
  @Input() runId: number;
  @Input() pageId?: string; // specific to audits
  @Input() reportType?: EReportType; // specific to audits
  @Input() activeTab?: EPageDetailsTabs; // specific to audits
  @Output() initiatorsFullScreenModalClosed?: EventEmitter<void> = new EventEmitter();

  constructor(private tagInitiatorsService: TagInitiatorsService,
              private modalEscapeService: ModalEscapeService,
              private opModalService: OpModalService) {}

  ngOnChanges() {
    this.loading = true;
    this.failedToLoadMessage = undefined;
    this.initiatorsData = undefined;
    this.tagInitiatorsService.getTagInitiators(this.url, this.reportType, this.runId).subscribe((initiators) => {
        this.initiatorsData = this.tagInitiatorsService.prepareTagInitiators(initiators);
        this.loading = false;
      }, (error: IApiErrorResponse) => {
        this.loading = false;
        this.failedToLoadMessage = error.code && error.code === 404
          ? 'No Tag Initiators data found'
          : 'Failed to load Tag Initiators data';
      });
  }

  openInitiatorsFullScreen() {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.opModalService.openFullscreenModal(TagInitiatorsFullScreenComponent, {
      data: {
        initiatorsData: this.initiatorsData
      }
    })
    .afterClosed()
    .subscribe(() => {
      this.modalEscapeService.remove(index);
      if (this.initiatorsFullScreenModalClosed) {
        this.initiatorsFullScreenModalClosed.emit();
      }
    });
  }

}
