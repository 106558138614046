export enum EPageInfoLinkDownloadDirection {
  ToThisPage = 'to_this_page',
  FromThisPage = 'from_this_page',
}

export enum EPageInfoLinkDownloadOption {
  ToInternalPages = 'to_internal_pages',
  ToExternalPages = 'to_external_pages',
  ToUrlsNotAudited = 'to_urls_not_audited',
}

export enum EPageInfoLinkDownloadLinkType {
  Initial = 'initial',
  Final = 'final',
}

export enum EPageInfoWebVitalsItems {
  LargestContentfulPaint = 'largestContentfulPaint',
  CumulativeLayoutShift = 'cumulativeLayoutShift',
  FirstContentfulPaint = 'firstContentfulPaint',
  TimeToFirstByte = 'timeToFirstByte',
}
