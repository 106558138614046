export class ActionTypes {
  static ADD_AUDIT = 'ADD_AUDIT';
  static ADD_DOMAIN = 'ADD_DOMAIN';
  static ADD_FOLDER = 'ADD_FOLDER';
  static DEL_AUDIT = 'DEL_AUDIT';
  static DEL_AUDIT_LABEL = 'DEL_AUDIT_LABEL';
  static DEL_DOMAIN = 'DEL_DOMAIN';
  static DEL_FOLDER = 'DEL_FOLDER';
  static SET_AUDITS = 'SET_AUDITS';
  static SET_AUDIT_LABELS = 'SET_AUDIT_LABELS';
  static SET_AUDIT_PAGE_DATA_COLLECTION = 'SET_AUDIT_PAGE_DATA';
  static SET_AUDIT_PAGE_INFORMATION = 'SET_AUDIT_PAGE_INFORMATION';
  static SET_DOMAINS = 'SET_DOMAINS';
  static SET_FOLDERS = 'SET_FOLDERS';
  static SET_LABELS = 'SET_LABELS';
  static SET_SELECTED_AUDIT = 'SET_SELECTED_AUDIT';
  static UPDATE_AUDIT = 'UPDATE_AUDIT';
  static UPDATE_DOMAIN = 'UPDATE_DOMAIN';
  static UPDATE_FOLDER = 'UPDATE_FOLDER';
  static USER_LOGOUT = 'USER_LOGOUT';
}
