import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'actions-menu',
  templateUrl: './actions-menu.component.html',
  styleUrls: ['./actions-menu.component.scss']
})
export class ActionsMenuComponent {
  @Input() triggerMenuIcon = 'more_vert';
  @Input() showTriggerMenuIndicator = false;
  @Input() disabled = false;

  @Output() menuTriggered = new EventEmitter();

  private _menuOpened = false;
  set menuOpened(v: boolean) {
    this._menuOpened = v;
    this.menuTriggered.emit(v);
  }

  get menuOpened() {
    return this._menuOpened;
  }
}
