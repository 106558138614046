import { OpModalService } from '@app/components/shared/components/op-modal';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import {
  EAuditReportFilterTypes,
  ECookieExpirationTypeTypes,
  ECookieOriginInclusionTypes,
  ECookiePartyType,
  ECookieSameSiteTypes,
  EJSFileChangeType,
  EPageErrorFilterType,
  EPageErrorType,
  ERuleStatus,
  GetRulesFn,
  IAuditReportFilter,
  IConsentCategoryReportFilterData
} from './audit-report-filter-bar.models';
import { IAuditReportApiPostBody } from '../audit-report/audit-report.models';
import {
  EConfiguredUrlsFilterType,
  ECookieDomainFilterType,
  EPageTitleFilterType,
  EPageUrlFilterType,
  EStatusCodeCategories,
  statusCodeCategoryToRangeMap
} from '../audit-report/audit-report.constants';
import { StorageService } from '@app/components/shared/services/storage.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { DateService } from '@app/components/date/date.service';
import { IAuditGeoLocation, IConsentCategoryGeos } from '@app/components/consent-categories/consent-categories.models';
import { IOpFilterBarMenuItem } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import { EFilterBarMenuNames } from './audit-report-filter-bar.enums';
import { DateDifferenceModalComponent } from '@app/components/audit-reports/audit-report-filter-bar/components/date-difference/date-difference-modal.component';
import { IDateDifferenceModalClosedData } from '@app/components/audit-reports/audit-report-filter-bar/components/date-difference/date-difference-modal.models';
import {
  IRageFilterModalConfigData,
  IRangeFilterClosedData
} from './components/range-filter-modal/range-filter-modal.models';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { RulesService } from '@app/components/rules/rules.service';
import {
  IAlertReportMetricsConfig,
  IAlertReportToMetricsConfig
} from '@app/components/alert/alert-logic/alert-logic.models';
import { AlertReportsToAuditMetrics } from '@app/components/alert/alert-logic/alert-logic.constants';
import { catchError, distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { JsFileChangesTypeOptions } from '../reports/js-files-changes/js-file-changes.constants';
import { bytesToMB, isNumber, mbToBytes, roundTo } from '@app/components/utilities/number.utils';
import { IRulePreview } from '@app/components/rules/rules.models';
import { RangeFilterModalComponent } from '@app/components/audit-reports/audit-report-filter-bar/components/range-filter-modal/range-filter-modal.component';
import { EAlertSummaryToggleFilterType } from '../reports/alert-summary/alert-summary.enums';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarErrorComponent } from '@app/components/shared/components/snackbars/snackbar-error/snackbar-error.component';
import {
  ISnackbarErrorData,
  SnackbarRegExpErrorComponent
} from '@app/components/audit-reports/audit-report-filter-bar/components/regexp-snackbar-error/regexp-snackbar-error.component';
import { JsFileChangesReportUtils } from '@app/components/utilities/js-file-changes-report.utils';
import { ApiValidationsService } from '@app/services/api-validations/api-validations.service';
import { IUiTag, IUiTagCategory, IUiTagVendor } from '@app/components/tag-database';

export const AUDIT_REPORT_FILTERS_STORAGE_KEY = 'reports_filters_audit';

@Injectable()
export class AuditReportFilterBarService extends OpFilterBarService<EAuditReportFilterTypes> implements OnDestroy {
  tags: IUiTag[] = [];
  tagCats: IUiTagCategory[] = [];
  tagVendors: IUiTagVendor[] = [];
  geos: IConsentCategoryGeos;
  rules: IRulePreview[];

  private destroy$: Subject<void> = new Subject();

  private loadedSubject = new BehaviorSubject(false);
  loaded$ = this.loadedSubject.asObservable();

  constructor(
    storage: StorageService,
    cacheReset: CacheResetService,
    protected dateService: DateService,
    protected modalService: OpModalService,
    protected uiTagService: UiTagService,
    protected ccService: ConsentCategoriesService,
    private snackBar: MatSnackBar,
    protected rulesService: RulesService,
    protected apiValidationsService: ApiValidationsService
  ) {
    super(storage, AUDIT_REPORT_FILTERS_STORAGE_KEY, cacheReset, new Map([[EAuditReportFilterTypes.AlertReportMetric, true]]));

    combineLatest([
      this.uiTagService.getAllTagsData(),
      this.ccService.geo$,
      this.rulesService.getRulePreviewsCached()
    ]).subscribe(([[tags, tagVendors, tagCats], geos, rules]) => {
      this.tags = tags;
      this.tagCats = tagCats;
      this.tagVendors = tagVendors;
      this.geos = geos;
      this.rules = rules;

      this.loadedSubject.next(true);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  get apiPostBody$(): Observable<IAuditReportApiPostBody> {
    return this.anyFiltersUpdates$.pipe(
      map(newFilters => this.generateApiPostBody(newFilters))
    );
  }

  overrideFilters(filters: IAuditReportApiPostBody): void {
    this.loadedSubject.pipe(filter(v => !!v), first()).subscribe(() => {
      this.clear();

      for (const key in filters) {
        this.addFilterByApiPostBody((key as EAuditReportFilterTypes), filters[key]);
      }
    });
  }

  /**
   * Get and Add filter methods
   * Get version that gives the IAuditFilter data - if you only want to filter data (no add)
   * Add version that adds the filter to the filter bar - can be called directly
   */
  getUrlContainsFilter(contains: string, isRegex: boolean = false, negate: boolean = true, isFinalUrl = false) {
    return {
      type: negate
        ? isFinalUrl ? EAuditReportFilterTypes.FinalPageUrlDoesNotContain : EAuditReportFilterTypes.InitialPageUrlDoesNotContain
        : isFinalUrl ? EAuditReportFilterTypes.FinalPageUrlContains : EAuditReportFilterTypes.InitialPageUrlContains,
      display: `${isFinalUrl ? `Final` : `Initial`} URL ${isRegex ? 'regex' : ''}`,
      value: {
        filterType: isRegex ? EPageUrlFilterType.Regex : EPageUrlFilterType.Contains,
        filterValue: contains,
        negated: negate
      },
      state: !negate,
      toggleOption1: {
        display: 'contains',
        value: false
      },
      toggleOption2: {
        display: 'does not contain',
        value: true
      },
      postDisplay: `'${contains}'`
    };
  }

  addUrlContainsFilter(contains: string, isRegex = false) {
    const existingType = this.filters.find(f => f.type === EAuditReportFilterTypes.InitialPageUrlContains || f.type === EAuditReportFilterTypes.InitialPageUrlDoesNotContain)?.type;
    const replaceType = existingType ? existingType : EAuditReportFilterTypes.InitialPageUrlContains;

    if (existingType) {
      this.replaceFilter(replaceType, this.getUrlContainsFilter(contains, isRegex, false));
    } else {
      this.addFilter(this.getUrlContainsFilter(contains, isRegex, false));
    }

  }

  addUrlDoesNotContainFilter(contains: string, isRegex = false) {
    const existingType = this.filters.find(f => f.type === EAuditReportFilterTypes.InitialPageUrlContains || f.type === EAuditReportFilterTypes.InitialPageUrlDoesNotContain)?.type;
    const replaceType = existingType ? existingType : EAuditReportFilterTypes.InitialPageUrlDoesNotContain;

    if (existingType) {
      this.replaceFilter(replaceType, this.getUrlContainsFilter(contains, isRegex, true));
    } else {
      this.addFilter(this.getUrlContainsFilter(contains, isRegex, true));
    }
  }

  addFinalUrlContainsFilter(contains: string, isRegex = false) {
    const existingType = this.filters.find(f => f.type === EAuditReportFilterTypes.FinalPageUrlContains || f.type === EAuditReportFilterTypes.FinalPageUrlDoesNotContain)?.type;
    const replaceType = existingType ? existingType : EAuditReportFilterTypes.FinalPageUrlContains;

    if (existingType) {
      this.replaceFilter(replaceType, this.getUrlContainsFilter(contains, isRegex, false, true));
    } else {
      this.addFilter(this.getUrlContainsFilter(contains, isRegex, false, true));
    }
  }

  addFinalUrlDoesNotContainFilter(contains: string, isRegex = false) {
    const existingType = this.filters.find(f => f.type === EAuditReportFilterTypes.FinalPageUrlContains || f.type === EAuditReportFilterTypes.FinalPageUrlDoesNotContain)?.type;
    const replaceType = existingType ? existingType : EAuditReportFilterTypes.FinalPageUrlDoesNotContain;

    if (existingType) {
      this.replaceFilter(replaceType, this.getUrlContainsFilter(contains, isRegex, true, true));
    } else {
      this.addFilter(this.getUrlContainsFilter(contains, isRegex, true, true));
    }
  }

  addPageLoadTimeFilter(min: number, max?: number): void {
    this.addFilter(this.getPageLoadTimeFilter([min ? min.toString() : null, max ? max.toString() : null]));
  }

  addCookieDurationFilter(minutes: [number, number]): void {
    this.addFilter(this.getCookieDurationFilter(minutes));
  }

  getPageStatusCodeFilter(category: EStatusCodeCategories, value?: string) {
    const labelOptions = {
      [EStatusCodeCategories.Specific]: value,
      [EStatusCodeCategories.Successes]: 'Successes',
      [EStatusCodeCategories.Redirects]: 'Redirects',
      [EStatusCodeCategories.Broken]: 'Broken',
    };

    return {
      type: EAuditReportFilterTypes.InitialPageStatusCode,
      display: `Initial Page Status Codes: '${labelOptions[category]}'`,
      value: value ? {
        filterType: 'specific',
        filterValue: +value,
        negated: false
      } : statusCodeCategoryToRangeMap.get(category)
    };
  }

  addPageStatusCodeFilter(category: EStatusCodeCategories, value?: string): void {
    this.addFilter(this.getPageStatusCodeFilter(category, value));
  }

  addCombinedPageStatusCodeFilter(category: EStatusCodeCategories): void {
    this.addFilter(this.getCombinedPageStatusCodeFilter(category));
  }

  private getCombinedPageStatusCodeFilter(category: EStatusCodeCategories) {
    const status = category === EStatusCodeCategories.Successes ? 'Successful' : 'Broken';
    return {
      type: EAuditReportFilterTypes.CombinedPageStatusCode,
      display: `${status} Pages Only`,
      value: category
    };
  }

  addPageErrorsFilter(errorType: EPageErrorType): void {
    this.addFilter(this.getPageErrorsFilter(errorType));
  }

  getPageErrorsFilter(errorType: EPageErrorType) {
    return {
      type: EAuditReportFilterTypes.Errors,
      display: 'On-Page Action Failure',
      value: {
        filterType: EPageErrorFilterType.All,
        errorTypes: [errorType],
      }
    };
  }

  getFinalPageStatusCodeFilter(category: EStatusCodeCategories, value?: string) {
    const labelOptions = {
      [EStatusCodeCategories.Specific]: value,
      [EStatusCodeCategories.Successes]: 'Successes',
      [EStatusCodeCategories.Redirects]: 'Redirects',
      [EStatusCodeCategories.Broken]: 'Broken',
    };

    return {
      type: EAuditReportFilterTypes.FinalPageStatusCode,
      display: `Final Page Status Codes: '${labelOptions[category]}'`,
      value: value ? {
        filterType: 'specific',
        filterValue: +value,
        negated: false
      } : statusCodeCategoryToRangeMap.get(category)
    };
  }

  addFinalPageStatusCodeFilter(category: EStatusCodeCategories, value?: string): void {
    this.addFilter(this.getFinalPageStatusCodeFilter(category, value));
  }

  getPageTitleFilter(contains: boolean, value: string) {
    return {
      type: EAuditReportFilterTypes.PageTitle,
      display: 'Page Title',
      value: {
        filterType: contains ? EPageTitleFilterType.Contains : EPageTitleFilterType.NotContains,
        filterValue: value
      },
      state: contains,
      toggleOption1: {
        display: 'contains',
        value: EPageTitleFilterType.Contains
      },
      toggleOption2: {
        display: 'does not contain',
        value: EPageTitleFilterType.NotContains
      },
      postDisplay: value
    };
  }

  addPageTitleFilter(contains: boolean, value: string) {
    this.addFilter(this.getPageTitleFilter(contains, value));
  }

  getPagesWithBrokenLinksFilter() {
    return {
      type: EAuditReportFilterTypes.PagesWithBrokenLinks,
      display: `Pages with Broken Links`,
      value: true
    };
  }

  getLargestContentfulPaintFilter(times: [string, string]) {
    return this.getTimeRangeFilter('Largest Contentful Paint', EAuditReportFilterTypes.LargestContentfulPaint, times);
  }

  getFirstContentfulPaintFilter(times: [string, string]) {
    return this.getTimeRangeFilter('First Contentful Paint', EAuditReportFilterTypes.FirstContentfulPaint, times);
  }

  getTimeToFirstByteFilter(times: [string, string]) {
    return this.getTimeRangeFilterInMS('Time To First Byte', EAuditReportFilterTypes.TimeToFirstByte, times);
  }

  getCumulativeLayoutShiftFilter(values: [string, string]) {
    return this.getRangeFilter('Cumulative Layout Shift', EAuditReportFilterTypes.CumulativeLayoutShift, values);
  }

  geConfiguredUrlsOnlyFilter(value: boolean) {
    return {
      type: EAuditReportFilterTypes.ShowAuditConfigured,
      display: '',
      value,
      state: value,
      toggleOption1: {
        display: 'Matching',
        value: EConfiguredUrlsFilterType.Contains
      },
      toggleOption2: {
        display: 'Not Matching',
        value: EConfiguredUrlsFilterType.NotContains
      },
      postDisplay: 'URLs from Audit Setup'
    };
  }

  addPagesWithBrokenLinksFilter() {
    this.addFilter(this.getPagesWithBrokenLinksFilter());
  }

  addLargestContentfulPaintFilter(min: number, max?: number): void {
    this.addFilter(this.getLargestContentfulPaintFilter([min ? min.toString() : null, max ? max.toString() : null]));
  }

  addFirstContentfulPaintFilter(min: number, max?: number): void {
    this.addFilter(this.getFirstContentfulPaintFilter([min ? min.toString() : null, max ? max.toString() : null]));
  }

  addTimeToFirstByteFilter(min: number, max?: number): void {
    this.addFilter(this.getTimeToFirstByteFilter([min ? min.toString() : null, max ? max.toString() : null]));
  }

  addCumulativeLayoutShiftFilter(min: number, max?: number): void {
    this.addFilter(this.getCumulativeLayoutShiftFilter([min ? min.toString() : null, max ? max.toString() : null]));
  }

  addShowConfiguredUrlsOnlyFilter(value: boolean) {
    const existingType = this.filters.find(f => f.type === EAuditReportFilterTypes.ShowAuditConfigured)?.type;

    if (existingType) {
      this.replaceFilter(existingType, this.geConfiguredUrlsOnlyFilter(value));
    } else {
      this.addFilter(this.geConfiguredUrlsOnlyFilter(value));
    }
  }

  getPrimaryTagsFilter() {
    return {
      type: EAuditReportFilterTypes.TagPrimaryTagsOnly,
      display: 'Primary Tags Only',
      value: true
    };
  }

  getPagesWithoutTagIdFilter(label: string, id: number) {
    return {
      type: EAuditReportFilterTypes.PagesWithoutTagId,
      display: `Pages without Tag: '${label}'`,
      value: id
    };
  }

  getPagesWithoutTagCategoryIdFilter(label: string, id: number) {
    return {
      type: EAuditReportFilterTypes.PagesWithoutTagCategoryId,
      display: `Pages without Tag Category: '${label}'`,
      value: id
    };
  }

  getPagesWithoutTagVendorIdFilter(label: string, id: number) {
    return {
      type: EAuditReportFilterTypes.PagesWithoutTagVendorId,
      display: `Pages without Tag Vendor: '${label}'`,
      value: id
    };
  }

  getTagVendorFilter(label: string, id: number) {
    return {
      type: EAuditReportFilterTypes.TagVendorId,
      display: `Tag Vendor: '${label}'`,
      value: { tagVendorId: id, label },
    };
  }

  addPrimaryTagsFilter() {
    this.addFilter(this.getPrimaryTagsFilter());
  }

  private getAnyRelatedTagsFilter() {
    return {
      type: EAuditReportFilterTypes.TagAnyRelated,
      display: 'Any Related Tag',
      value: true
    };
  }

  addAnyRelatedTagsFilter() {
    this.addFilter(this.getAnyRelatedTagsFilter());
  }

  getTagIdFilter(label: string, id: number) {
    return {
      type: EAuditReportFilterTypes.TagId,
      display: `Tag: '${label}'`,
      value: { tagId: id, label },
    };
  }

  addTagIdFilter(label: string, id: number) {
    this.addFilter(this.getTagIdFilter(label, id));
  }

  getTagCategoryFilter(label: string, id: number) {
    return {
      type: EAuditReportFilterTypes.TagCategory,
      display: `Tag Category: '${label}'`,
      value: { tagCategoryId: id, label },
    };
  }

  addTagCategoryFilter(label: string, id: number) {
    this.addFilter(this.getTagCategoryFilter(label, id));
  }

  addTagVendorFilter(label: string, id: number) {
    this.addFilter(this.getTagVendorFilter(label, id));
  }

  addPagesWithoutTagIdFilter(label: string, id: number) {
    this.addFilter(this.getPagesWithoutTagIdFilter(label, id));
  }

  addPagesWithoutTagCategoryIdFilter(label: string, id: number) {
    this.addFilter(this.getPagesWithoutTagCategoryIdFilter(label, id));
  }

  addPagesWithoutTagVendorIdFilter(label: string, id: number) {
    this.addFilter(this.getPagesWithoutTagVendorIdFilter(label, id));
  }

  getTagAccountFilter(label: string) {
    return {
      type: EAuditReportFilterTypes.TagAccount,
      display: `Tag Account is '${label}'`,
      value: label
    };
  }

  addTagAccountFilter(label: string) {
    this.addFilter(this.getTagAccountFilter(label));
  }

  getTagLoadTimeFilter(min: number, max: number = null) {
    const loadTimeDisplay = this.getLoadTimeFilterDisplayValueInMs(min, max);

    return {
      type: EAuditReportFilterTypes.TagLoadTime,
      display: `Tag Load ${loadTimeDisplay}`,
      value: { min, max }
    };
  }

  addTagLoadTimeFilter(min: number, max: number = null) {
    this.addFilter(this.getTagLoadTimeFilter(min, max));
  }

  getTagStatusCodeFilter(statusCodeRanges: EStatusCodeCategories) {
    const labelOptions = {
      [EStatusCodeCategories.Successes]: 'Successes',
      [EStatusCodeCategories.Redirects]: 'Redirects',
      [EStatusCodeCategories.Broken]: 'Broken',
    };

    return {
      type: EAuditReportFilterTypes.TagStatusCode,
      display: `Tag Status Codes: '${labelOptions[statusCodeRanges]}'`,
      value: statusCodeRanges
    };
  }

  addTagStatusCodeFilter(statusCodeRanges: EStatusCodeCategories) {
    this.addFilter(this.getTagStatusCodeFilter(statusCodeRanges));
  }

  private handleEnterConsoleLogMessage(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addConsoleLogMessageFilter(true, val);
      target.value = '';
    }
  }

  addConsoleLogMessageFilter(contains: boolean, value: string) {
    this.addFilter(this.getConsoleLogMessageFilter(contains, value));
  }

  private getConsoleLogMessageFilter(contains: boolean, value: string) {
    return {
      type: EAuditReportFilterTypes.ConsoleLogMessage,
      display: 'Message text',
      value: {
        filterType: contains ? EPageTitleFilterType.Contains : EPageTitleFilterType.NotContains,
        filterValue: value
      },
      state: contains,
      toggleOption1: {
        display: 'contains',
        value: EPageTitleFilterType.Contains
      },
      toggleOption2: {
        display: 'does not contain',
        value: EPageTitleFilterType.NotContains
      },
      postDisplay: `'${value}'`
    };
  }

  getCookieNameFilter(name: string) {
    return {
      type: EAuditReportFilterTypes.CookieName,
      display: `Cookie Name contains '${name}'`,
      value: {
        filterType: 'contains',
        filterValue: name
      }
    };
  }

  addCookieNameFilter(name: string) {
    this.addFilter(this.getCookieNameFilter(name));
  }

  getCookieDomainFilter(contains: boolean, value: string) {
    return {
      type: EAuditReportFilterTypes.CookieDomain,
      display: 'Cookie Domain',
      value: {
        filterType: contains ? 'contains' : 'not_contains',
        filterValue: value
      },
      state: contains,
      toggleOption1: {
        display: 'contains',
        value: 'contains'
      },
      toggleOption2: {
        display: 'does not contain',
        value: 'not_contains'
      },
      postDisplay: `'${value}'`
    };
  }

  addCookieDomainFilter(contains: boolean, value: string) {
    this.addFilter(this.getCookieDomainFilter(contains, value));
  }

  getCookieInitiatorFilter(initiator: string) {
    return {
      type: EAuditReportFilterTypes.CookieInitiator,
      display: `Cookie Initiator contains '${initiator}'`,
      value: {
        filterType: 'contains',
        filterValue: initiator
      }
    };
  }

  getCookieInitiatorDomainFilter(domain: string) {
    return {
      type: EAuditReportFilterTypes.CookieInitiatorDomain,
      display: `Cookie Initiator Domain: '${domain}'`,
      value: {
        filterType: 'contains',
        filterValue: domain
      }
    };
  }

  getCookieInitiatorUrlFilter(url: string) {
    return {
      type: EAuditReportFilterTypes.CookieInitiatorUrl,
      display: `Cookie Initiator URL contains '${url}'`,
      value: {
        filterType: 'contains',
        filterValue: url
      }
    };
  }

  addCookieInitiatorFilter(initiator: string) {
    this.addFilter(this.getCookieInitiatorFilter(initiator));
  }

  addCookieInitiatorDomainFilter(domain: string) {
    this.addFilter(this.getCookieInitiatorDomainFilter(domain));
  }

  addCookieInitiatorUrlFilter(domain: string) {
    this.addFilter(this.getCookieInitiatorUrlFilter(domain));
  }

  getCookiePartyFilter(party: string) {
    const partyOptions = {
      [ECookiePartyType.first]: '1st Party',
      [ECookiePartyType.third]: '3rd Party',
    };

    return {
      type: EAuditReportFilterTypes.CookiePartyType,
      display: `${partyOptions[party]} Cookies`,
      value: party
    };
  }

  addCookiePartyFilter(party: string) {
    this.addFilter(this.getCookiePartyFilter(party));
  }

  getCookieSameSiteFilter(sameSiteType: ECookieSameSiteTypes) {
    const sameSiteOptions = {
      [ECookieSameSiteTypes.strict]: 'SameSite: Strict',
      [ECookieSameSiteTypes.empty]: 'SameSite: Empty or not present',
      [ECookieSameSiteTypes.none]: 'SameSite: none',
      [ECookieSameSiteTypes.lax]: 'SameSite: Lax',
    };

    return {
      type: EAuditReportFilterTypes.CookieSameSite,
      display: `${sameSiteOptions[sameSiteType]}`,
      value: sameSiteType
    };
  }

  addCookieSameSiteFilter(sameSiteType: ECookieSameSiteTypes) {
    this.addFilter(this.getCookieSameSiteFilter(sameSiteType));
  }

  getCookieSecureFilter(secure: boolean) {
    return {
      type: EAuditReportFilterTypes.CookieSecure,
      display: `Secure Cookies: ${secure}`,
      value: secure
    };
  }

  addCookieSecureFilter(secure: boolean) {
    this.addFilter(this.getCookieSecureFilter(secure));
  }

  addCookieHttpOnlyFilter(httpOnly: boolean) {
    this.addFilter(this.getCookieHttpOnlyFilter(httpOnly));
  }

  addCookiePartitionedFilter(partitioned: boolean) {
    this.addFilter(this.getCookiePartitionedFilter(partitioned));
  }

  addCookieAllThirdPartyFilter(allThirdParty: boolean) {
    this.addFilter(this.getCookieAllThirdPartyFilter(allThirdParty));
  }

  private getCookieHttpOnlyFilter(httpOnly: boolean) {
    return {
      type: EAuditReportFilterTypes.CookieHttpOnly,
      display: `HttpOnly Cookies: ${httpOnly}`,
      value: httpOnly
    };
  }

  private getCookiePartitionedFilter(partitioned: boolean) {
    return {
      type: EAuditReportFilterTypes.CookiePartitioned,
      display: `Partitioned Cookies: ${partitioned}`,
      value: partitioned
    };
  }

  private getCookieAllThirdPartyFilter(allThirdParty: boolean) {
    return {
      type: EAuditReportFilterTypes.CookieAllThirdParty,
      display: `All 3rd Party Cookies: ${allThirdParty}`,
      value: allThirdParty
    };
  }

  getConsentCategoryNameFilter(categoryId: number, categoryName: string) {
    return {
      type: EAuditReportFilterTypes.ConsentCategoryId,
      display: `Consent Category Name is '${categoryName}'`,
      value: categoryId,
      data: {
        categoryId: categoryId,
        name: categoryName
      } as IConsentCategoryReportFilterData
    };
  }

  addConsentCategoryNameFilter(categoryId: number, categoryName: string) {
    this.addFilter(this.getConsentCategoryNameFilter(categoryId, categoryName));
  }

  addConsentCategoryDeletedFilter() {
    this.addFilter(this.getConsentCategoryDeletedFilter());
  }

  getConsentCategoryDeletedFilter() {
    return {
      type: EAuditReportFilterTypes.DeletedItem,
      display: 'Consent Category has been deleted',
      value: ''
    };
  }

  getConsentCategoryStatusFilter(name: string) {
    return {
      type: EAuditReportFilterTypes.ConsentCategoryComplianceStatus,
      display: `Consent Category Status is '${name}'`,
      value: name
    };
  }

  addConsentCategoryStatusFilter(name: string) {
    this.addFilter(this.getConsentCategoryStatusFilter(name));
  }

  getGeolocationFilter(countryName: string, countryCode: string) {
    return {
      type: EAuditReportFilterTypes.Geolocation,
      display: `Geolocation is '${countryName}'`,
      value: countryCode,
    };
  }

  getRuleNameFilter(ruleName: string, ruleId: number) {
    return {
      type: EAuditReportFilterTypes.RuleId,
      display: `Rule is '${ruleName}'`,
      value: ruleId
    };
  }

  addGeolocationFilter(countryName: string, countryCode: string) {
    this.addFilter(this.getGeolocationFilter(countryName, countryCode));
  }

  addRuleNameFilter(ruleId: number, ruleName: string) {
    this.addFilter(this.getRuleNameFilter(ruleName, ruleId));
  }

  getReqDomainNameFilter(name: string) {
    return {
      type: EAuditReportFilterTypes.RequestDomain,
      display: `Request Domain contains '${name}'`,
      value: name
    };
  }

  addReqDomainNameFilter(name: string) {
    this.addFilter(this.getReqDomainNameFilter(name));
  }

  getRuleFailureFilter(status: ERuleStatus) {
    return {
      type: EAuditReportFilterTypes.RuleStatus,
      display: `Rule Status ${status === ERuleStatus.NotApplied ? 'Not Applied' : typeof status === 'string' ? status[0].toUpperCase() + status.substring(1) : ''}`,
      value: status
    };
  }

  addRuleFailureFilter(status: ERuleStatus) {
    this.addFilter(this.getRuleFailureFilter(status));
  }

  getJSFilenameFilter(name: string) {
    return {
      type: EAuditReportFilterTypes.JSFilesFilename,
      display: `Javascript filename contains '${name}'`,
      value: name
    };
  }

  addJSFilenameFilter(name: string) {
    this.addFilter(this.getJSFilenameFilter(name));
  }

  private getJSFileChangeTypeFilter(changeType: EJSFileChangeType) {
    return {
      type: EAuditReportFilterTypes.JSFilesChangeType,
      display: `Javascript File Change Type: ${JsFileChangesTypeOptions.get(changeType)}`,
      value: changeType
    };
  }

  addJSFileChangeTypeFilter(changeType: EJSFileChangeType) {
    this.addFilter(this.getJSFileChangeTypeFilter(changeType));
  }

  getJSFileSizeDifferenceFilter(sizes: [string, string]) {
    return this.getBytesRangeFilter('File Size Difference', EAuditReportFilterTypes.JSFilesSizeDifference, sizes);
  }

  getPageSizeRangeFilter(label: string, type: EAuditReportFilterTypes, [min, max]: [string, string]) {
    let display: string;

    if (!min && max) {
      display = `${label}: <strong>&lt;${max}</strong> MB`;
    }

    if (min && !max) {
      display = `${label}: <strong>&gt;=${min}</strong> MB`;
    }

    if (min && max) {
      display = `${label}: <strong>&gt;=${min}</strong> and <strong>&lt;${max} MB</strong>`;
    }

    return {
      type: type,
      display,
      value: {
        min: min ? mbToBytes(min) : null,
        max: max ? mbToBytes(max) : null
      }
    };
  }

  getRequestSizeFilter(sizes: [string, string]) {
    return this.getBytesRangeFilter('Request Size', EAuditReportFilterTypes.RequestSize, sizes);
  }

  getCookieDurationFilter(days: [number, number]) {
    return this.getCookieDurationRangeFilter(days);
  }

  getPageLoadTimeFilter(times: [string, string]) {
    return this.getTimeRangeFilter('Page Load Time', EAuditReportFilterTypes.PageLoadTime, times);
  }

  getCookieDurationRangeFilter([min, max]: [number, number]) {
    let display: string;

    if (!min && max) {
      display = `Cookie Duration: <strong>&lt;${JsFileChangesReportUtils.formatDateDifference(max)}</strong>`;
    }

    if (min && !max) {
      display = `Cookie Duration: <strong>&gt;=${JsFileChangesReportUtils.formatDateDifference(min)}</strong>`;
    }

    if (min && max) {
      display = `Cookie Duration: <strong>&gt;=${JsFileChangesReportUtils.formatDateDifference(min)}</strong> and <strong>&lt;${JsFileChangesReportUtils.formatDateDifference(max)}</strong>`;
    }

    return {
      type: EAuditReportFilterTypes.CookieDuration,
      display,
      value: {
        min: min ?? null,
        max: max ?? null
      }
    };
  }

  getBytesRangeFilter(label: string, type: EAuditReportFilterTypes, [min, max]: [string, string]) {
    let display: string;

    if (!min && max) {
      display = `${label}: <strong>&lt;${max}</strong> bytes`;
    }

    if (min && !max) {
      display = `${label}: <strong>&gt;=${min}</strong> bytes`;
    }

    if (min && max) {
      display = `${label}: <strong>&gt;=${min}</strong> and <strong>&lt;${max} bytes</strong>`;
    }

    return {
      type: type,
      display,
      value: {
        min: +min || null,
        max: +max || null
      }
    };
  }

  getRangeFilter(label: string, type: EAuditReportFilterTypes, [min, max]: [string, string]) {
    let display: string;

    if (!min && max) {
      display = `${label}: <strong>&lt;${max}</strong>`;
    }

    if (min && !max) {
      display = `${label}: <strong>&gt;=${min}</strong>`;
    }

    if (min && max) {
      display = `${label}: <strong>&gt;=${min}</strong> and <strong>&lt;${max}</strong>`;
    }

    return {
      type: type,
      display,
      value: {
        min: +min || null,
        max: +max || null
      }
    };
  }

  getRedirectsRangeFilter(label: string, type: EAuditReportFilterTypes, [min, max]: [string, string]) {
    let display: string;

    if (!min && max) {
      display = `${label}: <strong>&lt;${max}</strong> redirects`;
    }

    if (min && !max) {
      display = `${label}: <strong>&gt;=${min}</strong> redirects`;
    }

    if (min && max) {
      display = `${label}: <strong>&gt;=${min}</strong> and <strong>&lt;${max} redirects</strong>`;
    }

    return {
      type: type,
      display,
      value: {
        min: +min || null,
        max: +max || null
      }
    };
  }

  getTimeRangeFilter(label: string, type: EAuditReportFilterTypes, [min, max]: [string, string]) {
    let display: string;

    if (!min && max) {
      display = `${label}: <strong>&lt;${roundTo(+max / 1000, 2)}</strong> seconds`;
    }

    if (min && !max) {
      display = `${label}: <strong>&gt;=${roundTo(+min / 1000, 2)}</strong> seconds`;
    }

    if (min && max) {
      display = `${label}: <strong>&gt;=${roundTo(+min / 1000, 2)}</strong> and <strong>&lt;${roundTo(+max / 1000, 2)} seconds</strong>`;
    }

    return {
      type: type,
      display,
      value: {
        min: +min || null,
        max: +max || null
      }
    };
  }

  getTimeRangeFilterInMS(label: string, type: EAuditReportFilterTypes, [min, max]: [string, string]) {
    let display: string;

    if (!min && max) {
      display = `${label}: <strong>&lt;${roundTo(+max, 2)}</strong> ms`;
    }

    if (min && !max) {
      display = `${label}: <strong>&gt;=${roundTo(+min, 2)}</strong> ms`;
    }

    if (min && max) {
      display = `${label}: <strong>&gt;=${roundTo(+min, 2)}</strong> and <strong>&lt;${roundTo(+max, 2)} ms</strong>`;
    }

    return {
      type: type,
      display,
      value: {
        min: +min || null,
        max: +max || null
      }
    };
  }

  getPageSizeFilter(sizes: [string, string]) {
    return this.getPageSizeRangeFilter('Page Size', EAuditReportFilterTypes.PageSize, sizes);
  }

  getRedirectsNumberFilter(redirects: [string, string]) {
    return this.getRedirectsRangeFilter('Number of Redirects', EAuditReportFilterTypes.RedirectCount, redirects);
  }

  addJSFileSizeDifferenceFilter(sizes: [string, string]) {
    this.addFilter(this.getJSFileSizeDifferenceFilter(sizes));
  }

  getCookieSizeFilter(sizes: [string, string]) {
    return this.getBytesRangeFilter('Cookie Size', EAuditReportFilterTypes.CookieSize, sizes);
  }

  addPageSizeFilter(sizes) {
    this.addFilter(this.getPageSizeFilter(sizes));
  }

  addRedirectsCountFilter(sizes: [string, string]) {
    this.addFilter(this.getRedirectsNumberFilter(sizes));
  }

  addRequestSizeFilter(sizes: [string, string]) {
    this.addFilter(this.getRequestSizeFilter(sizes));
  }

  addCookieSizeFilter(sizes: [string, string]) {
    this.addFilter(this.getCookieSizeFilter(sizes));
  }

  getJSFileDateDifferenceFilter(dates: [number, number]) {
    const from = this.dateService.minutesToTime(dates[0]);
    const to = this.dateService.minutesToTime(dates[1]);

    let display: string;
    if (dates[0] === undefined && dates[1] !== undefined) {
      display = `File Date Difference: <strong>&lt;${to}</strong>`;
    }

    if (dates[0] !== undefined && dates[1] === undefined) {
      display = `File Date Difference: <strong>&gt;=${from}</strong>`;
    }

    if (dates[0] !== undefined && dates[1] !== undefined) {
      display = `File Date Difference: <strong>&gt;=${from}</strong> and <strong>&lt;${to}</strong>`;
    }

    return {
      type: EAuditReportFilterTypes.JSFilesDateDifference,
      display,
      value: {
        min: dates[0],
        max: dates[1]
      }
    };
  }

  addJSFileDateDifferenceFilter(dates: [number, number]) {
    this.addFilter(this.getJSFileDateDifferenceFilter(dates));
  }

  getAuditFiltersFromApiPostBodyFilters(filters: IAuditReportApiPostBody): IAuditReportFilter[] {
    let auditFilters = [];

    for (const key in filters) {
      auditFilters.push(this.getAuditFilterFromApiPostBodyFilter((key as EAuditReportFilterTypes), filters[key]));
    }

    return auditFilters.filter(filter => filter);
  }

  // Gets a single IAuditReportFilter - useful when needing to convert from type
  // IApiPostBody -> IAuditReportFilter without wanting to also add to a filter bar
  getAuditFilterFromApiPostBodyFilter(type, value): IAuditReportFilter {
    if (!value) return null;

    let minInclusive, maxExclusive, category, tag, tagVendor;

    switch (type) {
      case EAuditReportFilterTypes.InitialPageUrlContains:
        return this.getUrlContainsFilter(value.filterValue, value.filterType === 'regex', false);
      case EAuditReportFilterTypes.InitialPageUrlDoesNotContain:
        return this.getUrlContainsFilter(value.filterValue, value.filterType === 'regex');
      case EAuditReportFilterTypes.FinalPageUrlContains:
        return this.getUrlContainsFilter(value.filterValue, value.filterType === 'regex', false, true);
      case EAuditReportFilterTypes.FinalPageUrlDoesNotContain:
        return this.getUrlContainsFilter(value.filterValue, value.filterType === 'regex', true, true);
      case EAuditReportFilterTypes.PageLoadTime:
        return this.getPageLoadTimeFilter([value.min, value.max]);
      case EAuditReportFilterTypes.RedirectCount:
        return this.getRedirectsNumberFilter([value.min, value.max]);
      case EAuditReportFilterTypes.InitialPageStatusCode:
        minInclusive = value.minInclusive;
        maxExclusive = value.maxExclusive;

        category = this.getStatusCodeCategoryFromRange({ minInclusive, maxExclusive });
        return this.getPageStatusCodeFilter(category, value.filterValue);
      case EAuditReportFilterTypes.FinalPageStatusCode:
        minInclusive = value.minInclusive;
        maxExclusive = value.maxExclusive;

        category = this.getStatusCodeCategoryFromRange({ minInclusive, maxExclusive });
        return this.getFinalPageStatusCodeFilter(category, value.filterValue);
      case EAuditReportFilterTypes.CombinedPageStatusCode:
        return this.getCombinedPageStatusCodeFilter(value);
      case EAuditReportFilterTypes.PageTitle:
        return this.getPageTitleFilter(value.filterType === 'contains', value.filterValue);
      case EAuditReportFilterTypes.PagesWithBrokenLinks:
        return value ? this.getPagesWithBrokenLinksFilter() : null;
      case EAuditReportFilterTypes.TagId:
        tag = this.tags?.find(tag => tag.id === value);
        return tag ? this.getTagIdFilter(tag.name, tag.id) : null;
      case EAuditReportFilterTypes.TagAccount:
        return this.getTagAccountFilter(value);
      case EAuditReportFilterTypes.TagCategory:
        const tagCat = this.tagCats?.find(t => t.id === value);
        return tagCat.id ? this.getTagCategoryFilter(tagCat.category, tagCat.id) : null;
      case EAuditReportFilterTypes.TagVendorId:
        tagVendor = this.tagVendors?.find(t => t.id === value);
        return tagVendor ? this.getTagVendorFilter(tagVendor.name, tagVendor.id) : null;
      case EAuditReportFilterTypes.TagPrimaryTagsOnly:
        return this.getPrimaryTagsFilter();
      case EAuditReportFilterTypes.TagAnyRelated:
        return this.getAnyRelatedTagsFilter();
      case EAuditReportFilterTypes.TagLoadTime:
        return this.getTagLoadTimeFilter(value.min, value.max);
      case EAuditReportFilterTypes.TagStatusCode:
        return this.getTagStatusCodeFilter(value);
      case EAuditReportFilterTypes.RuleId:
        const rule = this.rules?.find(rule => rule.id === value);
        return rule ? this.getRuleNameFilter(rule.name, rule.id) : null;
      case EAuditReportFilterTypes.RuleStatus:
        return this.getRuleFailureFilter(value);
      case EAuditReportFilterTypes.ConsoleLogMessage:
        return this.getConsoleLogMessageFilter(value.filterType === EPageTitleFilterType.Contains, value.filterValue);
      case EAuditReportFilterTypes.PageSize:
        return this.getPageSizeFilter([mbToBytes(value.min).toString(), mbToBytes(value.max).toString()]);
      case EAuditReportFilterTypes.CookieName:
        return this.getCookieNameFilter(value.filterValue);
      case EAuditReportFilterTypes.CookieDomain:
        return this.getCookieDomainFilter(value.filterType === EPageTitleFilterType.Contains, value.filterValue);
      case EAuditReportFilterTypes.CookieInitiator:
        return this.getCookieInitiatorFilter(value.filterValue);
      case EAuditReportFilterTypes.CookieInitiatorDomain:
        return this.getCookieInitiatorDomainFilter(value.filterValue);
      case EAuditReportFilterTypes.CookieInitiatorUrl:
        return this.getCookieInitiatorUrlFilter(value.filterValue);
      case EAuditReportFilterTypes.CookiePartyType:
        return this.getCookiePartyFilter(value);
      case EAuditReportFilterTypes.CookieSameSite:
        return this.getCookieSameSiteFilter(value);
      case EAuditReportFilterTypes.CookieSecure:
        return this.getCookieSecureFilter(value);
      case EAuditReportFilterTypes.CookieHttpOnly:
        return this.getCookieHttpOnlyFilter(value);
      case EAuditReportFilterTypes.CookiePartitioned:
        return this.getCookiePartitionedFilter(value);
      case EAuditReportFilterTypes.CookieAllThirdParty:
        return this.getCookieAllThirdPartyFilter(value);
      case EAuditReportFilterTypes.CookieExpirationType:
        return this.getCookieExpirationTypeFilter(value);
      case EAuditReportFilterTypes.CookieSize:
        return this.getCookieSizeFilter([value.min, value.max]);
      case EAuditReportFilterTypes.CookieDuration:
        return this.getCookieDurationFilter([value.min, value.max]);
      case EAuditReportFilterTypes.CookieOriginInclusion:
        return this.getCookieOriginInclusion();
      case EAuditReportFilterTypes.ConsentCategoryId:
        // Filter doesn't return the name, only the snapshot ID. Must handle
        // individually to retrieve snapshot information from the id so we
        // have the display name to show on the filter chip.
        // See alert-filters.component.ts writeValue method for example
        let ccIdFilter = this.getConsentCategoryNameFilter(value, '');

        this.ccService.getConsentCategoryById(value).toPromise().then(cc => {
          const ccFilter = this.getConsentCategoryNameFilter(value, cc.name);
          ccIdFilter.display = ccFilter.display;
          ccIdFilter.data.name = ccFilter.data.name;
        });

        return ccIdFilter;
      case EAuditReportFilterTypes.ConsentCategoryComplianceStatus:
        return this.getConsentCategoryStatusFilter(value);
      case EAuditReportFilterTypes.Geolocation:
        const country = Object.values(this.geos?.countriesById ?? {}).find(c => c.countryCode === value);
        return country ? this.getGeolocationFilter(country.countryName, country.countryCode) : null;
      case EAuditReportFilterTypes.RequestDomain:
        return this.getReqDomainNameFilter(value);
      case EAuditReportFilterTypes.RequestSize:
        return this.getRequestSizeFilter([value.min, value.max]);
      case EAuditReportFilterTypes.JSFilesSizeDifference:
        return this.getJSFileSizeDifferenceFilter([value.min, value.max]);
      case EAuditReportFilterTypes.JSFilesDateDifference:
        return this.getJSFileDateDifferenceFilter([value.min, value.max]);
      case EAuditReportFilterTypes.JSFilesFilename:
        return this.getJSFilenameFilter(value);
      case EAuditReportFilterTypes.JSFilesChangeType:
        return this.getJSFileChangeTypeFilter(value);
      case EAuditReportFilterTypes.ShowAuditConfigured:
        return this.geConfiguredUrlsOnlyFilter(value);
      case EAuditReportFilterTypes.PagesWithoutTagId:
        tag = this.tags?.find(tag => tag.id === value);
        return tag ? this.getPagesWithoutTagIdFilter(tag.name, tag.id) : null;
      case EAuditReportFilterTypes.PagesWithoutTagCategoryId:
        tag = this.tags?.find(t => t.id === value);
        return tag?.tagCategoryId ? this.getPagesWithoutTagCategoryIdFilter(tag.name, tag.tagCategoryId) : null;
      case EAuditReportFilterTypes.PagesWithoutTagVendorId:
        tagVendor = this.tagVendors?.find(t => t.id === value);
        return tagVendor ? this.getPagesWithoutTagVendorIdFilter(tagVendor.name, tagVendor.id) : null;

      case EAuditReportFilterTypes.Errors:
        return this.getPageErrorsFilter(value);
      case EAuditReportFilterTypes.DeletedItem:
        return this.getConsentCategoryDeletedFilter();

      // WebVitals section
      case EAuditReportFilterTypes.LargestContentfulPaint:
        return this.getLargestContentfulPaintFilter([value.min, value.max]);
      case EAuditReportFilterTypes.FirstContentfulPaint:
        return this.getFirstContentfulPaintFilter([value.min, value.max]);
      case EAuditReportFilterTypes.TimeToFirstByte:
        return this.getTimeToFirstByteFilter([value.min, value.max]);
      case EAuditReportFilterTypes.CumulativeLayoutShift:
        return this.getCumulativeLayoutShiftFilter([value.min, value.max]);

      // Filters which is not being used in reports: (Should be refactored and removed from EAuditReportFilterTypes)
      case EAuditReportFilterTypes.AlertName:
      case EAuditReportFilterTypes.AlertStatus:
      case EAuditReportFilterTypes.AlertSubscribed:
      case EAuditReportFilterTypes.AlertReportMetric:

      // Filters which were removed with time.
      case 'display':
      case 'requestSize':
      case 'initiator':
      case 'pageStatusCode':
        return null;
      default:
        throw new Error('This filter was not handled: ' + type) as any;
    }
  }

  // Given an apiPostBody, this method generates the matching filters and
  // populates the filter bar. Filters are typically built and added from
  // component -> service -> api response. This method allows us to generate
  // filters in the other direction,  api response -> service -> component.
  // Included sample API response value/type for each filter type as comment.
  addFilterByApiPostBody(type: EAuditReportFilterTypes, value: any): void {
    let minInclusive, maxExclusive, category, tag, tagCat, tagVendor;

    switch (type) {
      // Pages
      case EAuditReportFilterTypes.InitialPageUrlContains:
        // value = {
        //   'filterType': 'regex',
        //   'filterValue': 'example.com',
        //   'negated': boolean
        // }

        if (value.negated) {
          this.addUrlDoesNotContainFilter(value.filterValue, value.filterType === 'regex');
        } else {
          this.addUrlContainsFilter(value.filterValue, value.filterType === 'regex');
        }
        break;
      case EAuditReportFilterTypes.InitialPageUrlDoesNotContain:
        // value = {
        //   'filterType': 'regex',
        //   'filterValue': 'example.com',
        //   'negated': boolean
        // }

        this.addUrlDoesNotContainFilter(value.filterValue, value.filterType === 'regex');
        break;
      case EAuditReportFilterTypes.FinalPageUrlContains:
        // value = {
        //   'filterType': 'regex',
        //   'filterValue': 'example.com'
        //   'negated': boolean
        // }
        if (value.negated) {
          this.addFinalUrlDoesNotContainFilter(value.filterValue, value.filterType === 'regex');
        } else {
          this.addFinalUrlContainsFilter(value.filterValue, value.filterType === 'regex');
        }
        break;
      case EAuditReportFilterTypes.FinalPageUrlDoesNotContain:
        // value = {
        //   'filterType': 'regex',
        //   'filterValue': 'example.com',
        //   'negated': boolean
        // }

        this.addFinalUrlDoesNotContainFilter(value.filterValue, value.filterType === 'regex');
        break;
      case EAuditReportFilterTypes.PageLoadTime:
        // value = {
        //   'min': 0,
        //   'max': 2999
        // }
        this.addPageLoadTimeFilter(value.min, value.max);
        break;
      case EAuditReportFilterTypes.PageSize:
        // value = { min: number, max: number }
        value ? this.addPageSizeFilter([bytesToMB(value.min), bytesToMB(value.max)]) : null;
        break;
      case EAuditReportFilterTypes.ShowAuditConfigured:
        // value = boolean
        this.addShowConfiguredUrlsOnlyFilter(value);
        break;
      case EAuditReportFilterTypes.InitialPageStatusCode:
        // value = {
        //   'minInclusive'?: 200,
        //   'maxExclusive'?: 300,
        //   'negated': false,
        //   'filterType': 'range' | 'specific'
        //   'filterValue'?: string
        // }
        minInclusive = value.minInclusive;
        maxExclusive = value.maxExclusive;

        category = this.getStatusCodeCategoryFromRange({ minInclusive, maxExclusive });
        this.addPageStatusCodeFilter(category, value.filterValue);
        break;
      case EAuditReportFilterTypes.FinalPageStatusCode:
        // value = {
        //   'minInclusive'?: 200,
        //   'maxExclusive'?: 300,
        //   'negated': false,
        //   'filterType': 'range' | 'specific'
        //   'filterValue'?: string
        // }
        minInclusive = value.minInclusive;
        maxExclusive = value.maxExclusive;

        category = this.getStatusCodeCategoryFromRange({ minInclusive, maxExclusive });
        this.addFinalPageStatusCodeFilter(category, value.filterValue);
        break;
      case EAuditReportFilterTypes.CombinedPageStatusCode:
        this.addCombinedPageStatusCodeFilter(value);
        break;
      case EAuditReportFilterTypes.PageTitle:
        // value = {
        //   'filterType': 'contains',
        //   'filterValue': 'home'
        // }
        this.addPageTitleFilter(value.filterType === 'contains', value.filterValue);
        break;
      case EAuditReportFilterTypes.PagesWithBrokenLinks:
        // value = boolean
        value ? this.addPagesWithBrokenLinksFilter() : null;
        break;
      case EAuditReportFilterTypes.RedirectCount:
        // value = { min: number, max: number }
        value ? this.addRedirectsCountFilter([value.min, value.max]) : null;
        break;

      // Tags
      case EAuditReportFilterTypes.TagId:
        // value = number (tagId)
        tag = this.tags.find(tag => tag.id === value);
        tag ? this.addTagIdFilter(tag.name, tag.id) : null;
        break;
      case EAuditReportFilterTypes.PagesWithoutTagId:
        // value = number (tagId)
        tag = this.tags.find(tag => tag.id === value);
        tag ? this.addPagesWithoutTagIdFilter(tag.name, tag.id) : null;
        break;
      case EAuditReportFilterTypes.PagesWithoutTagCategoryId:
        // value = number (tagCat)
        tagCat = this.tagCats.find(tag => tag.id === value);
        tagCat ? this.addPagesWithoutTagCategoryIdFilter(tagCat.category, tagCat.id) : null;
        break;
      case EAuditReportFilterTypes.PagesWithoutTagVendorId:
        // value = number (vendorId)
        tagVendor = this.tagVendors.find(tagVendor => tagVendor.id === value);
        tagVendor ? this.addPagesWithoutTagVendorIdFilter(tagVendor.name, tagVendor.id) : null;
        break;
      case EAuditReportFilterTypes.TagAccount:
        // value = string
        this.addTagAccountFilter(value);
        break;
      case EAuditReportFilterTypes.TagCategory:
        // value = number (categoryId)
        tagCat = this.tagCats.find(cat => cat.id === value);
        tagCat ? this.addTagCategoryFilter(tagCat.category, tagCat.id) : null;
        break;
      case EAuditReportFilterTypes.TagVendorId:
        // value = number (vendorId)
        tagVendor = this.tagVendors.find(tagVendor => tagVendor.id === value);
        tagVendor ? this.addTagVendorFilter(tagVendor.name, tagVendor.id) : null;
        break;
      case EAuditReportFilterTypes.TagPrimaryTagsOnly:
        // value = boolean
        value ? this.addPrimaryTagsFilter() : null;
        break;
      case EAuditReportFilterTypes.TagAnyRelated:
        // value = boolean
        value ? this.addAnyRelatedTagsFilter() : null;
        break;
      case EAuditReportFilterTypes.TagLoadTime:
        value ? this.addTagLoadTimeFilter(value.min, value.max) : null;
        break;
      case EAuditReportFilterTypes.TagStatusCode:
        value ? this.addTagStatusCodeFilter(value) : null;
        break;
      case EAuditReportFilterTypes.RuleId:
        const rule = this.rules.find(rule => rule.id === value);
        rule ? this.addRuleNameFilter(rule.id, rule.name) : null;
        break;
      case EAuditReportFilterTypes.ConsoleLogMessage:
        value ? this.addConsoleLogMessageFilter(value.filterType === ECookieDomainFilterType.Contains, value.filterValue) : null;
        break;

      // Cookies
      case EAuditReportFilterTypes.CookieName:
        // value = {
        //   'filterType': 'contains',
        //   'filterValue': 'name'
        // }
        value ? this.addCookieNameFilter(value.filterValue) : null;
        break;
      case EAuditReportFilterTypes.CookieDomain:
        value ? this.addCookieDomainFilter(value.filterType === ECookieDomainFilterType.Contains, value.filterValue) : null;
        break;
      case EAuditReportFilterTypes.CookieInitiator:
        value ? this.addCookieInitiatorFilter(value) : null;
        break;
      case EAuditReportFilterTypes.CookieInitiatorDomain:
        value ? this.addCookieInitiatorDomainFilter(value.filterValue) : null;
        break;
      case EAuditReportFilterTypes.CookieInitiatorUrl:
        value ? this.addCookieInitiatorUrlFilter(value.filterValue) : null;
        break;
      case EAuditReportFilterTypes.CookieDuration:
        // value = {
        //   'min': 0,
        //   'max': 2999
        // }
        this.addCookieDurationFilter([
          (value.min ?? null),
          (value.max ?? null)
        ]);
        break;
      case EAuditReportFilterTypes.CookieOriginInclusion:
        value ? this.addCookieOriginInclusionFilter() : null;
        break;
      case EAuditReportFilterTypes.CookiePartyType:
        value ? this.addCookiePartyFilter(value) : null;
        break;
      case EAuditReportFilterTypes.CookieSameSite:
        value ? this.addCookieSameSiteFilter(value) : null;
        break;
      case EAuditReportFilterTypes.CookieSecure:
        this.addCookieSecureFilter(value);
        break;
      case EAuditReportFilterTypes.CookieSize:
        // value = { min: number, max: number }
        value ? this.addCookieSizeFilter([value.min, value.max]) : null;
        break;
      case EAuditReportFilterTypes.CookieHttpOnly:
        this.addCookieHttpOnlyFilter(value);
        break;
      case EAuditReportFilterTypes.CookiePartitioned:
        this.addCookiePartitionedFilter(value);
        break;
      case EAuditReportFilterTypes.CookieAllThirdParty:
        this.addCookieAllThirdPartyFilter(value);
        break;
      case EAuditReportFilterTypes.CookieExpirationType:
        this.addCookieExpirationTypeFilter(value);
        break;

      // Consent Categories
      case EAuditReportFilterTypes.ConsentCategoryId:
        // Filter doesn't return the name, only the snapshot ID. Must handle
        // individually to retrieve snapshot information from the id so we
        // have the display name to show on the filter chip.
        // See alert-filters.component.ts writeValue method for example
        this.ccService.getConsentCategoryById(value)
          .pipe(catchError(() => of(undefined)))
          .toPromise()
          .then(cc => {
            cc === undefined
              ? this.addConsentCategoryDeletedFilter()
              : this.addConsentCategoryNameFilter(value, cc.name);
          });
        break;
      case EAuditReportFilterTypes.ConsentCategoryComplianceStatus:
        value ? this.addConsentCategoryStatusFilter(value) : null;
        break;

      // Geolocation
      case EAuditReportFilterTypes.Geolocation:
        // value = string (country code)
        const country = Object.values(this.geos.countriesById).find(c => c.countryCode === value);
        country ? this.addGeolocationFilter(country.countryName, country.countryCode) : null;
        break;

      // Requests
      case EAuditReportFilterTypes.RequestDomain:
        value ? this.addReqDomainNameFilter(value) : null;
        break;

      case EAuditReportFilterTypes.RequestSize:
        // value = { min: number, max: number }
        value ? this.addRequestSizeFilter([value.min, value.max]) : null;
        break;

      // JS Files
      case EAuditReportFilterTypes.JSFilesSizeDifference:
        // value = { min: number, max: number }
        value ? this.addJSFileSizeDifferenceFilter([value.min, value.max]) : null;
        break;

      case EAuditReportFilterTypes.JSFilesDateDifference:
        // value = { min: number, max: number }
        value ? this.addJSFileDateDifferenceFilter([value.min, value.max]) : null;
        break;
      case EAuditReportFilterTypes.JSFilesFilename:
        // value = string
        value ? this.addJSFilenameFilter(value) : null;
        break;
      case EAuditReportFilterTypes.JSFilesChangeType:
        // value = string
        value ? this.addJSFileChangeTypeFilter(value) : null;
        break;

      // Rules
      case EAuditReportFilterTypes.RuleStatus:
        // value = boolean
        value ? this.addRuleFailureFilter(value) : null;
        break;

      // page errors
      case EAuditReportFilterTypes.Errors:
        // value = {
        //   filterType: EPageErrorFilterType.All,
        //   errorTypes: [errorType],
        // }
        value ? this.addPageErrorsFilter(EPageErrorType.OnPageActionError) : null;
        break;

      // delete items
      case EAuditReportFilterTypes.DeletedItem:
        // value = ''
        value ? this.addConsentCategoryDeletedFilter() : null;
        break;

      case EAuditReportFilterTypes.LargestContentfulPaint:
        value ? this.addLargestContentfulPaintFilter(value.min, value.max) : null;
        break;

      case EAuditReportFilterTypes.FirstContentfulPaint:
        value ? this.addFirstContentfulPaintFilter(value.min, value.max) : null;
        break;

      case EAuditReportFilterTypes.TimeToFirstByte:
        value ? this.addTimeToFirstByteFilter(value.min, value.max) : null;
        break;

      case EAuditReportFilterTypes.CumulativeLayoutShift:
        value ? this.addCumulativeLayoutShiftFilter(value.min, value.max) : null;
        break;

      // Not appliable filters to alert designer
      // DON'T USE THESE FIELDS TO POSTPONE IMPLEMENTATION!

      case EAuditReportFilterTypes.AlertName:
        break;
      case EAuditReportFilterTypes.AlertReportMetric:
        break;
      case EAuditReportFilterTypes.AlertStatus:
        break;
      case EAuditReportFilterTypes.AlertSubscribed:
        break;
      default:
        throw new Error('This filter is not supported by alert designer.' + 'Type: "' + type + '". Value: ' + JSON.stringify(value));
    }
  }

  getStatusCodeCategoryFromRange(range): EStatusCodeCategories {
    if (range.minInclusive === 200 && range.maxExclusive === 300) {
      return EStatusCodeCategories.Successes;
    } else if (range.minInclusive === 300 && range.maxExclusive === 400) {
      return EStatusCodeCategories.Redirects;
    } else if (range.minInclusive === 200 && range.maxExclusive === 400) {
      return EStatusCodeCategories.Broken;
    } else {
      return EStatusCodeCategories.Specific;
    }
  }

  generateApiPostBody(newFilters: IAuditReportFilter[] = []): IAuditReportApiPostBody {
    const apiPostBody = newFilters
      .filter(filter => this.validTypes?.includes(filter?.type))
      .reduce((acc, curr) => {
        if (curr.type !== EAuditReportFilterTypes.AlertReportMetric) {
          if (curr.type === EAuditReportFilterTypes.TagId) {
            acc['tagId'] = curr.value['tagId'];
          } else if (curr.type === EAuditReportFilterTypes.TagVendorId) {
            acc['tagVendorId'] = curr.value['tagVendorId'];
          } else if (curr.type === EAuditReportFilterTypes.TagCategory) {
            acc['tagCategoryId'] = curr.value['tagCategoryId'];
          } else if (curr.type === EAuditReportFilterTypes.InitialPageUrlContains || curr.type === EAuditReportFilterTypes.InitialPageUrlDoesNotContain) {
            acc[EAuditReportFilterTypes.InitialPageUrlContains] = curr.value as any;
          } else if (curr.type === EAuditReportFilterTypes.FinalPageUrlContains || curr.type === EAuditReportFilterTypes.FinalPageUrlDoesNotContain) {
            acc[EAuditReportFilterTypes.FinalPageUrlContains] = curr.value as any;
          } else {
            acc[curr.type] = curr.value;
          }
        } else {
          if (acc[curr.type]) {
            acc[curr.type].push(curr.value);
          } else {
            acc[curr.type] = [curr.value];
          }
        }

        return acc;
      }, {} as IAuditReportApiPostBody);

    return apiPostBody;
  }

  private getLoadTimeFilterDisplayValueInMs(min: number, max: number): string {
    return this.getLoadTimeFilterDisplayValue(min, max, 'ms', x => x);
  }

  private getLoadTimeFilterDisplayValue(
    min: number,
    max: number,
    timeUnit: string,
    converter: (value) => number
  ): string {
    const minValue: number = converter(min);
    const maxValue = max ? converter(max) : null;

    return max
      ? `${minValue}-${maxValue} ${timeUnit}`
      : `>${minValue} ${timeUnit}`;
  }

  buildFilterMenu(validFilterTypes: EAuditReportFilterTypes[],
    tagDb: { id: number; name: string; searchName: string }[],
    tagCategoryDb: IUiTagCategory[],
    countries: IAuditGeoLocation[],
    ccsAssignedToCurrentRun: { categoryId: number; name: string; searchName: string }[],
    getRules: GetRulesFn): IOpFilterBarMenuItem[] {

    // don't continue unless we have the valid filter types and the tag categories
    if (!validFilterTypes.length && !tagCategoryDb.length) return;

    // page filters
    const pageLoadTime = validFilterTypes.includes(EAuditReportFilterTypes.PageLoadTime);
    const initialPageStatusCodes = validFilterTypes.includes(EAuditReportFilterTypes.InitialPageStatusCode);
    const finalPageStatusCodes = validFilterTypes.includes(EAuditReportFilterTypes.FinalPageStatusCode);
    const combinedPageStatusCode = validFilterTypes.includes(EAuditReportFilterTypes.CombinedPageStatusCode);
    const pageTitle = validFilterTypes.includes(EAuditReportFilterTypes.PageTitle);
    const pagesTopLevel = pageLoadTime || initialPageStatusCodes || finalPageStatusCodes || pageTitle;
    const pagesWithBrokenLinks = validFilterTypes.includes(EAuditReportFilterTypes.PagesWithBrokenLinks);
    const pageSize = validFilterTypes.includes(EAuditReportFilterTypes.PageSize);
    const pagesWithoutTagId = validFilterTypes.includes(EAuditReportFilterTypes.PagesWithoutTagId);
    const pagesWithoutTagCategoryId = validFilterTypes.includes(EAuditReportFilterTypes.PagesWithoutTagCategoryId);
    const pagesWithoutTagVendorId = validFilterTypes.includes(EAuditReportFilterTypes.PagesWithoutTagVendorId);
    const redirectsCount = validFilterTypes.includes(EAuditReportFilterTypes.RedirectCount);
    const showConfiguredUrls = validFilterTypes.includes(EAuditReportFilterTypes.ShowAuditConfigured);
    const pageErrors = validFilterTypes.includes(EAuditReportFilterTypes.Errors);
    const largestContentfulPaint = validFilterTypes.includes(EAuditReportFilterTypes.LargestContentfulPaint);
    const firstContentfulPaint = validFilterTypes.includes(EAuditReportFilterTypes.FirstContentfulPaint);
    const timeToFirstByte = validFilterTypes.includes(EAuditReportFilterTypes.TimeToFirstByte);
    const cumulativeLayoutShift = validFilterTypes.includes(EAuditReportFilterTypes.CumulativeLayoutShift);
    const webVitalsTopLevel = largestContentfulPaint || firstContentfulPaint || timeToFirstByte || cumulativeLayoutShift;

    // Console logs
    const consoleLogMessage = validFilterTypes.includes(EAuditReportFilterTypes.ConsoleLogMessage);

    // JS files
    const jsFilesSizeDifference = validFilterTypes.includes(EAuditReportFilterTypes.JSFilesSizeDifference);
    const jsFilesDateDifference = validFilterTypes.includes(EAuditReportFilterTypes.JSFilesDateDifference);
    const jsFilesFilename = validFilterTypes.includes(EAuditReportFilterTypes.JSFilesFilename);
    const jsFilesChangeType = validFilterTypes.includes(EAuditReportFilterTypes.JSFilesChangeType);
    const jsFilesTopLevel = jsFilesSizeDifference || jsFilesDateDifference || jsFilesFilename || jsFilesChangeType;

    // other filters
    const cookieName = validFilterTypes.includes(EAuditReportFilterTypes.CookieName);
    const cookieDomain = validFilterTypes.includes(EAuditReportFilterTypes.CookieDomain);
    const cookieInitiator = validFilterTypes.includes(EAuditReportFilterTypes.CookieInitiator);
    const cookieInitiatorDomain = validFilterTypes.includes(EAuditReportFilterTypes.CookieInitiatorDomain);
    const cookieInitiatorUrl = validFilterTypes.includes(EAuditReportFilterTypes.CookieInitiatorUrl);
    const cookiePartyType = validFilterTypes.includes(EAuditReportFilterTypes.CookiePartyType);
    const cookieSameSite = validFilterTypes.includes(EAuditReportFilterTypes.CookieSameSite);
    const cookieExpirationType = validFilterTypes.includes(EAuditReportFilterTypes.CookieExpirationType);
    const cookieSecure = validFilterTypes.includes(EAuditReportFilterTypes.CookieSecure);
    const cookieHttpOnly = validFilterTypes.includes(EAuditReportFilterTypes.CookieHttpOnly);
    const cookieAllThirdParty = validFilterTypes.includes(EAuditReportFilterTypes.CookieAllThirdParty);
    const cookieSize = validFilterTypes.includes(EAuditReportFilterTypes.CookieSize);
    const cookieDuration = validFilterTypes.includes(EAuditReportFilterTypes.CookieDuration);
    const cookieOriginInclusion = validFilterTypes.includes(EAuditReportFilterTypes.CookieOriginInclusion);
    const cookiePartitioned = validFilterTypes.includes(EAuditReportFilterTypes.CookieDuration);

    // alert filters
    const alertName = validFilterTypes.includes(EAuditReportFilterTypes.AlertName);
    const alertReportMetric = validFilterTypes.includes(EAuditReportFilterTypes.AlertReportMetric);
    const alertStatus = validFilterTypes.includes(EAuditReportFilterTypes.AlertStatus);
    const alertSubscribed = validFilterTypes.includes(EAuditReportFilterTypes.AlertSubscribed);

    // Requests
    const requestDomains = validFilterTypes.includes(EAuditReportFilterTypes.RequestDomain);
    const handleTagNameSearch = (searchConfig: IOpFilterBarMenuItem,
      tagDb: { id: number; name: string; searchName: string }[],
      event: KeyboardEvent,
      el?: HTMLElement) => {
      const tagSearchTextUpdated = Date.now();

      // stolen! https://github.com/angular/components/issues/7973
      // Material issue occasionally tries to steal the focus away from embedded textboxes to give to menu items
      if (el && Date.now() < tagSearchTextUpdated + 200) {
        el.focus();
        return;
      } else { // handle normal search event
        const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

        const tagSearchChildren = tagDb
          .filter(tag => {
            const name = tag.searchName;
            return name.includes(value);
          })
          .map(tag => {
            return {
              name: tag.name,
              type: EFilterBarMenuTypes.Button,
              action: () => this.addPagesWithoutTagIdFilter(tag.name, tag.id) // this menu option is shared by tagName
            };
          });

        searchConfig.children = value ? tagSearchChildren : [];
      }
    };

    const searchTagConfig = {
      name: 'Tag Search',
      type: EFilterBarMenuTypes.Search,
      searchPlaceholder: 'Tag Name',
      action: (event: KeyboardEvent, el?: HTMLElement) => handleTagNameSearch(searchTagConfig, tagDb, event, el),
      children: []
    };

    const tagCategoryButtons: IOpFilterBarMenuItem[] = tagCategoryDb.map(cat => {
      return {
        name: cat.category,
        type: EFilterBarMenuTypes.Button,
        action: () => this.addPagesWithoutTagCategoryIdFilter(cat.category, cat.id)
      };
    });

    const handleTagVendorSearch = (searchConfig: IOpFilterBarMenuItem,
      event: KeyboardEvent,
      el?: HTMLElement) => {
      const tagSearchTextUpdated = Date.now();

      // stolen! https://github.com/angular/components/issues/7973
      // Material issue occasionally tries to steal the focus away from embedded textboxes to give to menu items
      if (el && Date.now() < tagSearchTextUpdated + 200) {
        el.focus();
        return;
      } else { // handle normal search event
        const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

        const tagSearchChildren = this.tagVendors
          .filter(tag => tag.name.toLowerCase().includes(value))
          .map(tagVendor => {
            return {
              name: tagVendor.name,
              type: EFilterBarMenuTypes.Button,
              action: () => this.addPagesWithoutTagVendorIdFilter(tagVendor.name, tagVendor.id) // this menu option is shared by tagName
            };
          });

        searchConfig.children = value ? tagSearchChildren : [];
      }
    };

    const searchTagVendorConfig = {
      name: 'Tag Vendor Search',
      type: EFilterBarMenuTypes.Search,
      searchPlaceholder: 'Tag Vendor Name',
      action: (event: KeyboardEvent, el?: HTMLElement) => handleTagVendorSearch(searchTagVendorConfig, event, el),
      children: []
    };

    const getPageStatusCodeChildren = action => ([
      {
        name: 'Status Code Search',
        type: EFilterBarMenuTypes.Search,
        searchPlaceholder: 'Search by status code',
        action: (event: KeyboardEvent) => {
          if (event.key === 'Enter') {
            const target = event.target as HTMLInputElement;
            const val = target.value.toString().trim();

            if (val && Number.isInteger(+val)) {
              action(EStatusCodeCategories.Specific, val);
              target.value = '';
            } else {
              this.snackBar.openFromComponent(SnackbarErrorComponent, {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'top',
                data: {
                  message: 'To apply filter you should enter valid status code.'
                }
              });
            }

          }
        }
      },
      {
        name: 'Successes (200s)',
        type: EFilterBarMenuTypes.Button,
        action: () => action(EStatusCodeCategories.Successes)
      },
      {
        name: 'Redirects (300s)',
        type: EFilterBarMenuTypes.Button,
        action: () => action(EStatusCodeCategories.Redirects)
      },
      {
        name: 'Broken (0, 400s, 500s)',
        type: EFilterBarMenuTypes.Button,
        action: () => action(EStatusCodeCategories.Broken)
      }
    ]);

    return [
      {
        name: 'Pages',
        type: EFilterBarMenuTypes.Flyout,
        displayWhen: pagesTopLevel,
        children: [
          {
            name: 'Load Time',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: pageLoadTime,
            children: [
              {
                name: '0-3 sec',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addPageLoadTimeFilter(0, 3000),
              },
              {
                name: '3-6 sec',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addPageLoadTimeFilter(3000, 6000),
              },
              {
                name: '6-10 sec',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addPageLoadTimeFilter(6000, 10000),
              },
              {
                name: '>10 sec',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addPageLoadTimeFilter(10000)
              },
              {
                name: 'Custom Range',
                type: EFilterBarMenuTypes.Button,
                action: () => this.handleEnterPageLoadTime()
              }
            ]
          },
          {
            name: 'Web Vitals',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: webVitalsTopLevel,
            children: [
              {
                name: 'Largest Contentful Paint',
                type: EFilterBarMenuTypes.Flyout,
                displayWhen: largestContentfulPaint,
                children: [
                  {
                    name: '0-2.5 sec',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addLargestContentfulPaintFilter(0, 2500),
                  },
                  {
                    name: '2.5-4 sec',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addLargestContentfulPaintFilter(2500, 4000),
                  },
                  {
                    name: '> 4 sec',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addLargestContentfulPaintFilter(4000),
                  },
                  {
                    name: 'Custom Range',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.handleEnterLargestContentfulPaint()
                  }
                ]
              },
              {
                name: 'First Contentful Paint',
                type: EFilterBarMenuTypes.Flyout,
                displayWhen: firstContentfulPaint,
                children: [
                  {
                    name: '0-1.8 sec',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addFirstContentfulPaintFilter(0, 1800),
                  },
                  {
                    name: '1.8-3 sec',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addFirstContentfulPaintFilter(1800, 3000),
                  },
                  {
                    name: '> 3 sec',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addFirstContentfulPaintFilter(3000),
                  },
                  {
                    name: 'Custom Range',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.handleEnterFirstContentfulPaint()
                  }
                ]
              },
              {
                name: 'Time To First Byte',
                type: EFilterBarMenuTypes.Flyout,
                displayWhen: timeToFirstByte,
                children: [
                  {
                    name: '0-800 ms',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addTimeToFirstByteFilter(0, 800),
                  },
                  {
                    name: '800-1800 ms',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addTimeToFirstByteFilter(800, 1800),
                  },
                  {
                    name: '> 1800 ms',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addTimeToFirstByteFilter(1800),
                  },
                  {
                    name: 'Custom Range',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.handleEnterTimeToFirstByte()
                  }
                ]
              },
              {
                name: 'Cumulative Layout Shift',
                type: EFilterBarMenuTypes.Flyout,
                displayWhen: cumulativeLayoutShift,
                children: [
                  {
                    name: '0-0.1',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addCumulativeLayoutShiftFilter(0, 0.1),
                  },
                  {
                    name: '0.1-0.25',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addCumulativeLayoutShiftFilter(0.1, 0.25),
                  },
                  {
                    name: '> 0.25',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addCumulativeLayoutShiftFilter(0.25),
                  },
                  {
                    name: 'Custom Range',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.handleEnterCumulativeLayoutShift()
                  }
                ]
              },
            ]
          },
          {
            name: 'Initial Status Codes',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: !initialPageStatusCodes,
            children: getPageStatusCodeChildren(this.addPageStatusCodeFilter.bind(this))
          },
          {
            name: 'Status Codes',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: initialPageStatusCodes || finalPageStatusCodes || combinedPageStatusCode,
            children: [
              {
                name: 'Initial Status Codes',
                type: EFilterBarMenuTypes.Flyout,
                children: getPageStatusCodeChildren(this.addPageStatusCodeFilter.bind(this))
              },
              {
                name: 'Final Status Codes',
                type: EFilterBarMenuTypes.Flyout,
                children: getPageStatusCodeChildren(this.addFinalPageStatusCodeFilter.bind(this))
              },
              {
                name: 'Broken/Successful Pages',
                type: EFilterBarMenuTypes.Flyout,
                children: [
                  {
                    name: 'Successful Pages',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addCombinedPageStatusCodeFilter(EStatusCodeCategories.Successes)
                  },
                  {
                    name: 'Broken Pages',
                    type: EFilterBarMenuTypes.Button,
                    action: () => this.addCombinedPageStatusCodeFilter(EStatusCodeCategories.Broken)
                  },
                ]
              },
            ]
          },
          {
            name: 'Page Title',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: pageTitle,
            children: [
              {
                name: 'Page Title Search',
                type: EFilterBarMenuTypes.Search,
                searchPlaceholder: 'Page Title',
                action: (event: KeyboardEvent) => this.handleEnterPageTitle(event)
              }
            ]
          },
          {
            name: 'Page Errors',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: pageErrors,
            children: [
              {
                name: 'On-Page Action Failure',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addPageErrorsFilter(EPageErrorType.OnPageActionError),
              },
            ],
          },
          {
            name: 'Page Size',
            type: EFilterBarMenuTypes.Button,
            displayWhen: pageSize,
            action: () => this.handleEnterPageSize()
          },
          {
            name: 'Pages with Broken Links',
            type: EFilterBarMenuTypes.Button,
            displayWhen: pagesWithBrokenLinks,
            action: () => this.addPagesWithBrokenLinksFilter()
          },
          {
            name: 'Number of Redirects',
            type: EFilterBarMenuTypes.Button,
            displayWhen: redirectsCount,
            action: () => this.handleEnterRedirectsAmount()
          },
          {
            name: 'Match URLs from Audit Setup',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: showConfiguredUrls,
            children: [
              {
                name: 'Show Matching Only',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addShowConfiguredUrlsOnlyFilter(true)
              },
              {
                name: 'Show Not Matching Only',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addShowConfiguredUrlsOnlyFilter(false)
              }
            ]
          },
          {
            name: 'Pages Without Tag',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: pagesWithoutTagId,
            itemClasses: ['mat-menu-primary-tags-item'],
            children: [
              searchTagConfig
            ]
          },
          {
            name: 'Pages Without Tag Category',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: pagesWithoutTagCategoryId,
            itemClasses: ['mat-menu-primary-tags-item'],
            children: tagCategoryButtons
          },
          {
            name: 'Pages Without Tag Vendor',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: pagesWithoutTagVendorId,
            itemClasses: ['mat-menu-primary-tags-item'],
            children: [
              searchTagVendorConfig
            ]
          },
        ]
      },
      this.getTagMenuItem(validFilterTypes, tagDb, tagCategoryDb),
      {
        name: 'Console Messages',
        type: EFilterBarMenuTypes.Flyout,
        displayWhen: consoleLogMessage,
        children: [
          {
            name: 'Console Messages',
            type: EFilterBarMenuTypes.Search,
            searchPlaceholder: 'Message Text',
            action: (event: KeyboardEvent) => this.handleEnterConsoleLogMessage(event)
          }
        ]
      },
      {
        name: 'Cookies',
        type: EFilterBarMenuTypes.Flyout,
        displayWhen: cookieName || cookieDomain || cookieInitiator || cookiePartyType || cookieSameSite || cookieSecure,
        children: [
          {
            name: 'Cookie Name',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookieName,
            children: [
              {
                name: 'Cookie Name Search',
                type: EFilterBarMenuTypes.Search,
                searchPlaceholder: 'Cookie Name',
                action: (event: KeyboardEvent) => this.handleEnterCookieName(event)
              }
            ]
          },
          {
            name: 'Cookie Domain',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookieDomain,
            children: [
              {
                name: 'Cookie Domain Search',
                type: EFilterBarMenuTypes.Search,
                searchPlaceholder: 'Cookie Domain',
                action: (event: KeyboardEvent) => this.handleEnterCookieDomain(event)
              }
            ]
          },
          {
            name: 'Cookie Initiator',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookieInitiator,
            children: [
              {
                name: 'Cookie Initiator',
                type: EFilterBarMenuTypes.Search,
                searchPlaceholder: 'Search by Cookie Initiator',
                action: (event: KeyboardEvent) => this.handleEnterCookieInitiator(event)
              }
            ]
          },
          {
            name: '1st & 3rd Party',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookiePartyType,
            children: [
              {
                name: '1st Party',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookiePartyFilter(ECookiePartyType.first)
              },
              {
                name: '3rd Party',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookiePartyFilter(ECookiePartyType.third)
              },
            ]
          },
          {
            name: 'Expiration Type',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookieExpirationType,
            children: [
              {
                name: 'Session',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieExpirationTypeFilter(ECookieExpirationTypeTypes.session)
              },
              {
                name: 'Timestamp',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieExpirationTypeFilter(ECookieExpirationTypeTypes.timestamp)
              },
            ]
          },
          {
            name: 'SameSite',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookieSameSite,
            children: [
              {
                name: 'Lax',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieSameSiteFilter(ECookieSameSiteTypes.lax)
              },
              {
                name: 'None',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieSameSiteFilter(ECookieSameSiteTypes.none)
              },
              {
                name: 'Strict',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieSameSiteFilter(ECookieSameSiteTypes.strict)
              },
              {
                name: 'Empty',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieSameSiteFilter(ECookieSameSiteTypes.empty)
              },
            ]
          },
          {
            name: 'Secure',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookieSecure,
            children: [
              {
                name: 'True',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieSecureFilter(true)
              },
              {
                name: 'False',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieSecureFilter(false)
              },
            ]
          },
          {
            name: 'HttpOnly',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookieHttpOnly,
            children: [
              {
                name: 'True',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieHttpOnlyFilter(true)
              },
              {
                name: 'False',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookieHttpOnlyFilter(false)
              },
            ],
          },
          {
            name: 'Size',
            type: EFilterBarMenuTypes.Button,
            displayWhen: cookieSize,
            action: () => this.handleEnterCookieSize()
          },
          {
            name: 'Duration',
            type: EFilterBarMenuTypes.Button,
            displayWhen: cookieDuration,
            action: () => this.handleCookieDurationTime()
          },
          {
            name: 'Hide Pre-Audit Action Cookies',
            type: EFilterBarMenuTypes.Button,
            displayWhen: cookieOriginInclusion,
            action: () => this.handleCookieOriginInclusion()
          },
          {
            name: 'Partitioned',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookiePartitioned,
            children: [
              {
                name: 'True',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookiePartitionedFilter(true)
              },
              {
                name: 'False',
                type: EFilterBarMenuTypes.Button,
                action: () => this.addCookiePartitionedFilter(false)
              },
            ],
          },
          {
            name: 'Cookie Initiator Domain',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookieInitiatorDomain,
            children: [
              {
                name: 'Cookie Initiator Domain',
                type: EFilterBarMenuTypes.Search,
                searchPlaceholder: 'Cookie Initiator Domain',
                action: (event: KeyboardEvent) => this.handleEnterCookieInitiatorDomain(event)
              }
            ]
          },
          {
            name: 'Cookie Initiator URL',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: cookieInitiatorUrl,
            children: [
              {
                name: 'Cookie Initiator URL',
                type: EFilterBarMenuTypes.Search,
                searchPlaceholder: 'Cookie Initiator URL',
                action: (event: KeyboardEvent) => this.handleEnterCookieInitiatorUrl(event)
              }
            ]
          },
        ],
      },
      this.getCCMenuItem(validFilterTypes, ccsAssignedToCurrentRun),
      {
        name: 'Request Domains',
        type: EFilterBarMenuTypes.Flyout,
        displayWhen: requestDomains,
        children: [
          {
            name: 'Request Domain Search',
            type: EFilterBarMenuTypes.Search,
            searchPlaceholder: 'Request Domain',
            action: (event: KeyboardEvent) => this.handleEnterDomainName(event)
          }
        ]
      },
      this.getGeolocationsMenuItem(validFilterTypes, countries),
      {
        name: 'Javascript File',
        type: EFilterBarMenuTypes.Flyout,
        displayWhen: jsFilesTopLevel,
        children: [
          {
            name: 'File Name',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: jsFilesFilename,
            children: [
              {
                name: 'File Name',
                type: EFilterBarMenuTypes.Search,
                itemClasses: ['w-235'],
                searchPlaceholder: 'Search javascript file by name',
                action: (event: KeyboardEvent) => this.handleEnterJSFilename(event)
              }
            ]
          },
          {
            name: 'File Size Difference',
            type: EFilterBarMenuTypes.Button,
            displayWhen: jsFilesSizeDifference,
            action: () => this.handleEnterJSFileSizeDifference()
          },
          {
            name: 'File Date Difference',
            type: EFilterBarMenuTypes.Button,
            displayWhen: jsFilesDateDifference,
            action: () => this.handleEnterJSFileDateDifference()
          },
          {
            name: 'File Change Type',
            type: EFilterBarMenuTypes.Flyout,
            displayWhen: jsFilesChangeType,
            children: [...JsFileChangesTypeOptions.entries()].map(([type, name]) => ({
              name,
              type: EFilterBarMenuTypes.Button,
              action: () => this.addJSFileChangeTypeFilter(type)
            }))
          },
        ]
      },
      this.getRulesMenuItem(validFilterTypes, getRules),
      {
        name: 'Alert Name',
        type: EFilterBarMenuTypes.Flyout,
        displayWhen: alertName,
        children: [
          {
            name: 'Alert Name',
            type: EFilterBarMenuTypes.Search,
            itemClasses: ['w-235'],
            searchPlaceholder: 'Search by alert name',
            action: (event: KeyboardEvent) => this.handleEnterAlertName(event)
          }
        ]
      },
      {
        name: 'Report Metric',
        type: EFilterBarMenuTypes.Flyout,
        displayWhen: alertReportMetric,
        children: this.prepareReportMetricToFilter(AlertReportsToAuditMetrics),
      },
      {
        name: 'Status',
        type: EFilterBarMenuTypes.Flyout,
        displayWhen: alertStatus,
        children: [
          {
            name: 'Triggered Alerts',
            type: EFilterBarMenuTypes.Button,
            displayWhen: alertStatus,
            action: () => this.addTriggeredAlertsFilter(true)
          },
          {
            name: 'Non-triggered Alerts',
            type: EFilterBarMenuTypes.Button,
            displayWhen: alertStatus,
            action: () => this.addTriggeredAlertsFilter(false)
          },
        ]
      },
      {
        name: 'Subscribed Alerts Only',
        type: EFilterBarMenuTypes.Button,
        displayWhen: alertSubscribed,
        action: () => {
          if (this.isSubscribedOnly()) {
            this.removeSubscribedAlertsFilter();
          } else {
            this.addSubscribedAlertsFilter();
          }
        }
      },
      {
        name: 'Divider',
        type: EFilterBarMenuTypes.Divider
      },
      {
        name: 'clear all filters',
        type: EFilterBarMenuTypes.ClearBtn,
        action: () => this.clear()
      },
    ];
  }

  private getTagMenuItem(validFilterTypes: EAuditReportFilterTypes[],
    tagDb: { id: number; name: string; searchName: string }[],
    tagCategoryDb: IUiTagCategory[]) {

    const primaryTagsOnly = validFilterTypes.includes(EAuditReportFilterTypes.TagPrimaryTagsOnly);
    const tagVendor = validFilterTypes.includes(EAuditReportFilterTypes.TagVendorId);
    const tagName = validFilterTypes.includes(EAuditReportFilterTypes.TagId);
    const tagAccount = validFilterTypes.includes(EAuditReportFilterTypes.TagAccount);
    const tagCategory = validFilterTypes.includes(EAuditReportFilterTypes.TagCategory);
    const tagLoadTime = validFilterTypes.includes(EAuditReportFilterTypes.TagLoadTime);
    const tagStatusCode = validFilterTypes.includes(EAuditReportFilterTypes.TagStatusCode);
    const tagAnyRelated = validFilterTypes.includes(EAuditReportFilterTypes.TagAnyRelated);
    const tagsTopLevel = tagVendor || primaryTagsOnly || tagName || tagAccount || tagCategory || tagLoadTime || tagStatusCode || tagAnyRelated;
    const requestSize = validFilterTypes.includes(EAuditReportFilterTypes.RequestSize);

    const tagCategoryButtons: IOpFilterBarMenuItem[] = tagCategoryDb.map(cat => {
      return {
        name: cat.category,
        type: EFilterBarMenuTypes.Button,
        action: () => this.addTagCategoryFilter(cat.category, cat.id)
      };
    });

    const searchConfig = {
      name: EFilterBarMenuNames.TagSearchName,
      type: EFilterBarMenuTypes.Search,
      searchPlaceholder: 'Tag Search',
      action: (event: KeyboardEvent, el?: HTMLElement) => handleTagNameSearch(searchConfig, tagDb, event, el),
      children: []
    };

    const searchTagVendorConfig = {
      name: 'Tag Vendor Search',
      type: EFilterBarMenuTypes.Search,
      searchPlaceholder: 'Tag Vendor Name',
      action: (event: KeyboardEvent, el?: HTMLElement) => handleTagVendorSearch(searchTagVendorConfig, event, el),
      children: []
    };

    const handleTagNameSearch = (searchConfig: IOpFilterBarMenuItem,
      tagDb: { id: number; name: string; searchName: string }[],
      event: KeyboardEvent,
      el?: HTMLElement) => {
      const tagSearchTextUpdated = Date.now();

      // stolen! https://github.com/angular/components/issues/7973
      // Material issue occasionally tries to steal the focus away from embedded textboxes to give to menu items
      if (el && Date.now() < tagSearchTextUpdated + 200) {
        el.focus();
        return;
      } else { // handle normal search event
        const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

        const tagSearchChildren = tagDb
          .filter(tag => {
            const name = tag.searchName;
            return name.includes(value);
          })
          .map(tag => {
            return {
              name: tag.name,
              type: EFilterBarMenuTypes.Button,
              action: () => this.addTagIdFilter(tag.name, tag.id) // this menu option is shared by tagName
            };
          });

        searchConfig.children = value ? tagSearchChildren : [];
      }
    };

    const handleTagVendorSearch = (searchConfig: IOpFilterBarMenuItem,
      event: KeyboardEvent,
      el?: HTMLElement) => {
      const tagSearchTextUpdated = Date.now();

      // stolen! https://github.com/angular/components/issues/7973
      // Material issue occasionally tries to steal the focus away from embedded textboxes to give to menu items
      if (el && Date.now() < tagSearchTextUpdated + 200) {
        el.focus();
        return;
      } else { // handle normal search event
        const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';

        const tagSearchChildren = this.tagVendors
          .filter(tag => tag.name.toLowerCase().includes(value))
          .map(tagVendor => {
            return {
              name: tagVendor.name,
              type: EFilterBarMenuTypes.Button,
              action: () => this.addTagVendorFilter(tagVendor.name, tagVendor.id) // this menu option is shared by tagName
            };
          });

        searchConfig.children = value ? tagSearchChildren : [];
      }
    };

    return {
      name: EFilterBarMenuNames.Tag,
      type: EFilterBarMenuTypes.Flyout,
      displayWhen: tagsTopLevel,
      itemClasses: ['mat-menu-tag-item'],
      children: [
        {
          name: 'Primary Tags',
          type: EFilterBarMenuTypes.Button,
          action: () => this.addPrimaryTagsFilter(),
          displayWhen: primaryTagsOnly,
          itemClasses: ['mat-menu-primary-tags-item'],
        },
        {
          name: 'Any Related Tag',
          type: EFilterBarMenuTypes.Button,
          action: () => this.addAnyRelatedTagsFilter(),
          displayWhen: tagAnyRelated,
        },
        {
          name: EFilterBarMenuNames.TagName,
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: tagName,
          children: [searchConfig]
        },
        {
          name: 'Account',
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: tagAccount,
          children: [
            {
              name: 'Account Search',
              type: EFilterBarMenuTypes.Search,
              searchPlaceholder: 'Tag Account Name',
              action: (event: KeyboardEvent) => this.handleEnterTagAccount(event)
            }
          ]
        },
        {
          name: 'Vendor',
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: tagVendor,
          itemClasses: ['mat-menu-primary-tags-item'],
          children: [
            searchTagVendorConfig
          ]
        },
        {
          name: 'Category',
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: tagCategory,
          children: tagCategoryButtons
        },
        {
          name: 'Load Time',
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: tagLoadTime,
          children: [
            {
              name: 'Below 500 ms',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addTagLoadTimeFilter(0, 499)
            },
            {
              name: '500-999 ms',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addTagLoadTimeFilter(500, 999)
            },
            {
              name: '1000-1999 ms',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addTagLoadTimeFilter(1000, 1999)
            },
            {
              name: 'Above 2000 ms',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addTagLoadTimeFilter(2000)
            }
          ]
        },
        {
          name: 'Status Code',
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: tagStatusCode,
          children: [
            {
              name: 'Successes (200s)',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addTagStatusCodeFilter(EStatusCodeCategories.Successes)
            },
            {
              name: 'Redirects (300s)',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addTagStatusCodeFilter(EStatusCodeCategories.Redirects)
            },
            {
              name: 'Broken (0, 400s, 500s)',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addTagStatusCodeFilter(EStatusCodeCategories.Broken)
            }
          ]
        },
        {
          name: 'Request Size',
          type: EFilterBarMenuTypes.Button,
          displayWhen: requestSize,
          action: () => this.handleEnterRequestSize()
        },
      ]
    };
  }

  private getCCMenuItem(validFilterTypes: EAuditReportFilterTypes[],
    ccsAssignedToCurrentRun: { categoryId: number; name: string; searchName: string }[]): IOpFilterBarMenuItem {

    const consentCategorySnapshotId = validFilterTypes.includes(EAuditReportFilterTypes.ConsentCategoryId);
    const consentCategoryComplianceStatus = validFilterTypes.includes(EAuditReportFilterTypes.ConsentCategoryComplianceStatus);
    const consentCategoryTopLevel = consentCategorySnapshotId || consentCategoryComplianceStatus;

    const searchConfig = {
      name: EFilterBarMenuNames.ConsentCategoryNameSearch,
      type: EFilterBarMenuTypes.Search,
      searchPlaceholder: 'Consent Category Name',
      action: (event: KeyboardEvent, el?: HTMLElement) => handleCCNameSearch(searchConfig, ccsAssignedToCurrentRun, event, el),
      children: []
    };

    const handleCCNameSearch = (searchConfig: IOpFilterBarMenuItem,
      ccsAssignedToCurrentRun: { categoryId: number; name: string; searchName: string }[],
      event: KeyboardEvent,
      el?: HTMLElement) => {
      const ccNameSearchTextUpdated = Date.now();
      // stolen! https://github.com/angular/components/issues/7973
      // Material issue occasionally tries to steal the focus away from embedded textboxes to give to menu items
      if (el && Date.now() < ccNameSearchTextUpdated + 200) {
        el.focus();
        return;
      }

      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
      if (!value) return;

      const ccNameSearchChildren = ccsAssignedToCurrentRun
        .filter(cc => cc.searchName.includes(value))
        .map(cc => ({
          name: cc.name,
          type: EFilterBarMenuTypes.Button,
          action: () => this.addConsentCategoryNameFilter(cc.categoryId, cc.name)
        })
        );

      searchConfig.children = value ? ccNameSearchChildren : [];
    };

    return {
      name: EFilterBarMenuNames.ConsentCategory,
      type: EFilterBarMenuTypes.Flyout,
      displayWhen: consentCategoryTopLevel,
      children: [
        {
          name: EFilterBarMenuNames.ConsentCategoryName,
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: consentCategorySnapshotId,
          children: [searchConfig]
        },
        {
          name: 'Status',
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: consentCategoryComplianceStatus,
          children: [
            {
              name: 'Unapproved',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addConsentCategoryStatusFilter('unapproved')
            },
            {
              name: 'Approved',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addConsentCategoryStatusFilter('approved')
            }
          ]
        }
      ]
    };
  }

  private getGeolocationsMenuItem(validFilterTypes: EAuditReportFilterTypes[],
    countries: IAuditGeoLocation[]): IOpFilterBarMenuItem {
    const displayWhen = validFilterTypes.includes(EAuditReportFilterTypes.Geolocation);

    const searchConfig = {
      name: EFilterBarMenuNames.GeolocationsCountryNameSearch,
      type: EFilterBarMenuTypes.Search,
      searchPlaceholder: 'Country Name',
      action: (event: KeyboardEvent, el?: HTMLElement) => handleCountryNameSearch(searchConfig, countries, event, el),
      children: []
    };

    const handleCountryNameSearch = (searchConfig: IOpFilterBarMenuItem,
      countries: IAuditGeoLocation[],
      event: KeyboardEvent,
      el?: HTMLElement) => {
      const countryNameSearchTextUpdated = Date.now();
      // stolen! https://github.com/angular/components/issues/7973
      // Material issue occasionally tries to steal the focus away from embedded textboxes to give to menu items
      if (el && Date.now() < countryNameSearchTextUpdated + 200) {
        el.focus();
        return;
      }

      const value = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
      if (!value) return;

      const countryNameSearchChildren = countries
        .filter(country => country.countryName.toLowerCase().includes(value))
        .map(country => ({
          name: country.countryName,
          type: EFilterBarMenuTypes.Button,
          action: () => this.addGeolocationFilter(country.countryName, country.countryCode)
        })
        );

      searchConfig.children = value ? countryNameSearchChildren : [];
    };

    return {
      name: EFilterBarMenuNames.Geolocations,
      type: EFilterBarMenuTypes.Flyout,
      displayWhen,
      children: [searchConfig]
    };
  }

  private getRulesMenuItem(validFilterTypes: EAuditReportFilterTypes[], getRules: GetRulesFn): IOpFilterBarMenuItem {
    const ruleStatus = validFilterTypes.includes(EAuditReportFilterTypes.RuleStatus);
    const ruleId = validFilterTypes.includes(EAuditReportFilterTypes.RuleId);

    const searchFilter = {
      name: 'Rule Name',
      type: EFilterBarMenuTypes.Search,
      itemClasses: ['w-235'],
      searchPlaceholder: 'Search rule by name',
      action: (event: KeyboardEvent, el) => getRulesList(searchFilter, event, el),
      children: []
    };

    const search$ = new Subject<string>();

    search$
      .pipe(
        distinctUntilChanged(),
        switchMap(ruleName => getRules(ruleName))
      )
      .subscribe(response => {
        searchFilter.children = response.filter((r, idx) => idx < 10)
          .map(rule => ({
            name: rule.ruleName,
            type: EFilterBarMenuTypes.Button,
            action: () => this.addRuleNameFilter(rule.ruleId, rule.ruleName)
          }));
      });

    const getRulesList = (searchConfig: IOpFilterBarMenuItem,
      event: KeyboardEvent,
      el?: HTMLElement) => {
      const countryNameSearchTextUpdated = Date.now();
      // stolen! https://github.com/angular/components/issues/7973
      // Material issue occasionally tries to steal the focus away from embedded textboxes to give to menu items
      if (el && Date.now() < countryNameSearchTextUpdated + 200) {
        el.focus();
        return;
      }

      const ruleName = (event.target as HTMLInputElement)?.value.trim().toLowerCase() || '';
      if (!ruleName) return;

      search$.next(ruleName);
    };

    return {
      name: 'Rule',
      type: EFilterBarMenuTypes.Flyout,
      displayWhen: ruleStatus || ruleId,
      children: [
        {
          name: 'Status',
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: ruleStatus,
          children: [
            {
              name: 'Not Applied',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addRuleFailureFilter(ERuleStatus.NotApplied)
            },
            {
              name: 'Passed',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addRuleFailureFilter(ERuleStatus.Passed)
            },
            {
              name: 'Failed',
              type: EFilterBarMenuTypes.Button,
              action: () => this.addRuleFailureFilter(ERuleStatus.Failed)
            }
          ]
        },
        {
          name: 'Rule Name',
          type: EFilterBarMenuTypes.Flyout,
          displayWhen: ruleId,
          children: [searchFilter]
        }
      ]
    };
  }

  private handleEnterPageTitle(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addPageTitleFilter(true, val);
      target.value = '';
    }
  }

  private handleEnterTagAccount(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addTagAccountFilter(val);
      target.value = '';
    }
  }

  private handleEnterCookieName(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addCookieNameFilter(val);
      target.value = '';
    }
  }

  private handleEnterCookieDomain(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addCookieDomainFilter(true, val);
      target.value = '';
    }
  }

  private handleEnterCookieInitiator(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addCookieInitiatorFilter(val);
      target.value = '';
    }
  }

  private handleEnterCookieInitiatorDomain(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addCookieInitiatorDomainFilter(val);
      target.value = '';
    }
  }

  private handleEnterCookieInitiatorUrl(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addCookieInitiatorUrlFilter(val);
      target.value = '';
    }
  }

  private handleEnterDomainName(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addReqDomainNameFilter(val);
      target.value = '';
    }
  }

  private handleEnterJSFilename(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addJSFilenameFilter(val);
      target.value = '';
    }
  }

  private handleEnterAlertName(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const target = event.target as HTMLInputElement;
      const val = target.value;
      this.addAlertNameFilter(val);
      target.value = '';
    }
  }

  private static integersRangeValidation(min: number, max: number, units = 'size'): string {
    if (!min && !max) {
      return;
    }

    if (min && !Number.isInteger(+min)) {
      return `Min ${units} is not an integer.`;
    }

    if (min && Number.isInteger(+min) && +min < 0) {
      return `Min ${units} cannot be negative.`;
    }

    if (max && !Number.isInteger(+max)) {
      return `Max ${units} is not an integer.`;
    }

    if (max && Number.isInteger(+max) && +max < 0) {
      return `Max ${units} cannot be negative.`;
    }

    if (min && Number.isInteger(+min) && +min === 0) {
      return `Min ${units} cannot be zero.`;
    }

    if (min && max && +min > +max) {
      return `Max number can’t be less than the min number.`;
    }

    if (min && max && +min === +max) {
      return `Max and Min ${units}s cannot be the same.`;
    }

    if (max && Number.isInteger(+max) && +max === 0) {
      return `Max ${units} cannot be zero.`;
    }
  }

  private static requestSizeRangeValidation(min: number, max: number): string {
    if (!min && !max) {
      return;
    }

    if (min && !Number.isInteger(+min)) {
      return 'Min request size is not an integer.';
    }

    if (min && Number.isInteger(+min) && +min < 0) {
      return 'Min request size cannot be negative.';
    }

    if (max && !Number.isInteger(+max)) {
      return 'Max request size is not an integer.';
    }

    if (max && Number.isInteger(+max) && +max < 0) {
      return 'Max request size cannot be negative.';
    }

    if (min && max && +min > +max) {
      return 'Max request size can’t be less than the min number.';
    }

    if (min && max && +min === +max) {
      return 'Max and Min request size cannot be the same.';
    }

    if (max && Number.isInteger(+max) && +max === 0) {
      return 'Max request size cannot be zero.';
    }
  }

  private static filterRangeHints(min: string | number, max: string | number): string {
    if (min && !max) {
      return 'All values >= to ' + min;
    }

    if (max && !min) {
      return 'All values < ' + max;
    }

    if (min && max) {
      return `All values >= ${min} and < ${max}`;
    }
  }

  handleEnterJSFileSizeDifference() {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'File Size Difference',
        validation: AuditReportFilterBarService.integersRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
      .afterClosed()
      .subscribe((data?: IRangeFilterClosedData) => {
        if (data) {
          this.addJSFileSizeDifferenceFilter([data.min, data.max]);
        }
      });
  }

  private static numberRangeValidation(min: string, max: string): string {

    if (!min && !max) {
      return;
    }

    if (min && !isNumber(min)) {
      return 'Min size is not an number.';
    }

    if (min && isNumber(min) && +min < 0) {
      return 'Min size cannot be negative.';
    }

    if (max && !isNumber(max)) {
      return 'Max size is not an number.';
    }

    if (max && isNumber(max) && +max < 0) {
      return 'Max size cannot be negative.';
    }

    if (min && isNumber(min) && +min === 0) {
      return 'Min size cannot be zero.';
    }

    if (min && max && +min > +max) {
      return 'Max number can’t be less than the min number.';
    }

    if (min && max && +min === +max) {
      return 'Max and Min sizes cannot be the same.';
    }

    if (max && isNumber(max) && +max === 0) {
      return 'Max size cannot be zero.';
    }
  }

  private handleEnterRequestSize(): void {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'Request Size',
        validation: AuditReportFilterBarService.requestSizeRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
      .afterClosed()
      .subscribe((data?: IRangeFilterClosedData) => {
        if (data) {
          this.addRequestSizeFilter([data.min, data.max]);
        }
      });
  }

  private handleEnterPageSize(): void {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'Page Size',
        minLabel: 'MINIMUM SIZE (MB)',
        maxLabel: 'MAXIMUM SIZE (MB)',
        validation: AuditReportFilterBarService.numberRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
      .afterClosed()
      .subscribe((data?: IRangeFilterClosedData) => {
        if (data) {
          this.addPageSizeFilter([data.min, data.max]);
        }
      });
  }

  private handleEnterRedirectsAmount(): void  {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'NUMBER OF REDIRECTS',
        minLabel: 'MIN REDIRECTS',
        maxLabel: 'MAX REDIRECTS',
        validation: AuditReportFilterBarService.integersRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
      .afterClosed()
      .subscribe((data?: IRangeFilterClosedData) => {
        if (data) {
          this.addRedirectsCountFilter([data.min, data.max]);
        }
      });
  }

  private handleEnterPageLoadTime(): void {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'PAGE LOAD TIME',
        minLabel: 'MIN SECONDS',
        maxLabel: 'MAX SECONDS',
        validation: AuditReportFilterBarService.numberRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
      .afterClosed()
      .subscribe((data?: IRangeFilterClosedData) => {
        if (data) {
          this.addPageLoadTimeFilter(+data.min * 1000, +data.max * 1000);
        }
      });
  }

  private handleEnterLargestContentfulPaint(): void {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'LARGEST CONTENTFUL PAINT',
        minLabel: 'MIN SECONDS',
        maxLabel: 'MAX SECONDS',
        validation: AuditReportFilterBarService.numberRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
        .afterClosed()
        .subscribe((data?: IRangeFilterClosedData) => {
          if (data) {
            this.addLargestContentfulPaintFilter(+data.min * 1000, +data.max * 1000);
          }
        });
  }

  private handleEnterFirstContentfulPaint(): void {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'FIRST CONTENTFUL PAINT',
        minLabel: 'MIN SECONDS',
        maxLabel: 'MAX SECONDS',
        validation: AuditReportFilterBarService.numberRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
        .afterClosed()
        .subscribe((data?: IRangeFilterClosedData) => {
          if (data) {
            this.addFirstContentfulPaintFilter(+data.min * 1000, +data.max * 1000);
          }
        });
  }

  private handleEnterTimeToFirstByte(): void {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'TIME TO FIRST BYTE',
        minLabel: 'MIN MS',
        maxLabel: 'MAX MS',
        validation: AuditReportFilterBarService.numberRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
        .afterClosed()
        .subscribe((data?: IRangeFilterClosedData) => {
          if (data) {
            this.addTimeToFirstByteFilter(+data.min, +data.max);
          }
        });
  }

  private handleEnterCumulativeLayoutShift(): void {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'CUMULATIVE LAYOUT SHIFT',
        minLabel: 'MIN',
        maxLabel: 'MAX',
        validation: AuditReportFilterBarService.numberRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
        .afterClosed()
        .subscribe((data?: IRangeFilterClosedData) => {
          if (data) {
            this.addCumulativeLayoutShiftFilter(+data.min, +data.max);
          }
        });
  }

  private handleCookieDurationTime() {
    this.modalService.openModal(DateDifferenceModalComponent, {
      autoFocus: false,
      data: {
        title: 'COOKIE DURATION',
        minLabel: 'MIN DURATION',
        maxLabel: 'MAX DURATION',
        itemName: 'duration',
      }
    })
      .afterClosed()
      .subscribe((data?: IDateDifferenceModalClosedData) => {
        if (data) {
          this.addCookieDurationFilter([data.min, data.max]);
        }
      });
  }

  private handleCookieOriginInclusion(): void {
    this.addCookieOriginInclusionFilter();
  }

  private addCookieOriginInclusionFilter(): void {
    this.addFilter(this.getCookieOriginInclusion());
  }

  private getCookieOriginInclusion(): { type: EAuditReportFilterTypes, display: string, value: string } {
    return {
      type: EAuditReportFilterTypes.CookieOriginInclusion,
      display: `Hide Pre-Audit Action Cookies`,
      value: ECookieOriginInclusionTypes.exclude
    };
  }

  private handleEnterCookieSize() {
    this.modalService.openModal(RangeFilterModalComponent, {
      autoFocus: false,
      data: {
        title: 'Cookie Size',
        validation: AuditReportFilterBarService.integersRangeValidation,
        hintMessage: AuditReportFilterBarService.filterRangeHints
      } as IRageFilterModalConfigData
    })
      .afterClosed()
      .subscribe((data?: IRangeFilterClosedData) => {
        if (data) {
          this.addCookieSizeFilter([data.min, data.max]);
        }
      });
  }

  handleEnterJSFileDateDifference() {
    this.modalService.openModal(DateDifferenceModalComponent, {
      autoFocus: false,
      data: {},
    })
      .afterClosed()
      .subscribe((data?: IDateDifferenceModalClosedData) => {
        if (data) {
          this.addJSFileDateDifferenceFilter([data.min, data.max]);
        }
      });
  }

  private prepareReportMetricToFilter(list: Array<IAlertReportToMetricsConfig | IAlertReportMetricsConfig>, reportMetric?: IAlertReportToMetricsConfig): IOpFilterBarMenuItem[] {
    return list.map(item => {
      const menuItem: IOpFilterBarMenuItem = {
        name: item.name,
        type: EFilterBarMenuTypes.Flyout,
      };

      if (this.isMetricConfig(item)) {
        menuItem.type = EFilterBarMenuTypes.Button;
        menuItem.action = () => this.addReportMetricFilter(reportMetric, item); // guards
      } else {
        menuItem.children = this.prepareReportMetricToFilter(item.metrics, item);
      }
      return menuItem;
    });
  }

  addReportMetricFilter(reportConfig: IAlertReportToMetricsConfig, metricConfig: IAlertReportMetricsConfig) {
    this.addFilter(this.getReportMetricFilter(reportConfig, metricConfig));
  }

  getReportMetricFilter(reportConfig: IAlertReportToMetricsConfig, metricConfig: IAlertReportMetricsConfig) {
    return {
      type: EAuditReportFilterTypes.AlertReportMetric,
      display: `Report metric is '${reportConfig.name} - ${metricConfig.name}'`,
      value: metricConfig.value,
      order: 1
    };
  }

  isMetricConfig(config: IAlertReportToMetricsConfig | IAlertReportMetricsConfig): config is IAlertReportMetricsConfig {
    if (!(config as IAlertReportToMetricsConfig).metrics) {
      return true;
    }
  }

  private isSubscribedOnly(): boolean {
    return !!this.filters.find(filter => filter.type === EAuditReportFilterTypes.AlertSubscribed);
  }

  addSubscribedAlertsFilter() {
    this.addFilter(this.getSubscribedAlertsFilter());
  }

  getSubscribedAlertsFilter() {
    return {
      type: EAuditReportFilterTypes.AlertSubscribed,
      display: `Only subscribed alerts shown`,
      value: true,
      order: 3
    };
  }

  removeSubscribedAlertsFilter() {
    this.removeFilterByType(EAuditReportFilterTypes.AlertSubscribed);
  }

  addTriggeredAlertsFilter(triggeredOnly: boolean) {
    this.removeFilterByType(EAuditReportFilterTypes.AlertStatus);
    this.addFilter(this.getTriggeredAlertsFilter(triggeredOnly));
  }

  getTriggeredAlertsFilter(triggeredOnly: boolean) {
    return {
      type: EAuditReportFilterTypes.AlertStatus,
      display: 'Alert',
      value: triggeredOnly ? 'TRIGGERED' : 'NOT_TRIGGERED',
      state: triggeredOnly,
      toggleOption1: {
        display: EAlertSummaryToggleFilterType.With,
        value: EAlertSummaryToggleFilterType.With
      },
      toggleOption2: {
        display: EAlertSummaryToggleFilterType.Without,
        value: EAlertSummaryToggleFilterType.Without
      },
      postDisplay: 'Triggered'
    };
  }

  getAlertNameFilter(name: string) {
    return {
      type: EAuditReportFilterTypes.AlertName,
      display: `Alert name contains '${name}'`,
      value: name
    };
  }

  getCookieExpirationTypeFilter(type: string) {
    return {
      type: EAuditReportFilterTypes.CookieExpirationType,
      display: `Expiration Type: ${type}`,
      value: type
    };
  }

  addCookieExpirationTypeFilter(type: string) {
    this.addFilter(this.getCookieExpirationTypeFilter(type));
  }

  addAlertNameFilter(name: string) {
    this.addFilter(this.getAlertNameFilter(name));
  }

  public async searchableValueValidator(value: string, searchByTextAsRegex: boolean): Promise<boolean> {
    if (searchByTextAsRegex) {
      const result = await this.apiValidationsService.validateUrlsSearchFilter(value).toPromise();

      if (!result.isValid) {
        if (result.unsupportedSequence) {
          this.snackBar.openFromComponent<SnackbarRegExpErrorComponent, ISnackbarErrorData>(SnackbarRegExpErrorComponent, {
            duration: 150000,
            panelClass: 'no-max-width',
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              allUnsupportedSequences: result.allUnsupportedSequences,
              unsupportedSequence: result.unsupportedSequence
            }
          });
        } else {
          this.snackBar.openFromComponent<SnackbarRegExpErrorComponent, ISnackbarErrorData>(SnackbarRegExpErrorComponent, {
            duration: 15000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
              snackbarClasses: ['centered'],
              message: 'The Regular Expression is not valid'
            }
          });
        }
      }

      return result.isValid;
    }
    return Promise.resolve(true);
  }
}
