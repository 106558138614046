<div class="rule-summary-container">
  <div class="no-rules-banner" *ngIf="rulesTotalCount === 0">
    <span>No rules have been applied to this run. <a (click)="assignRules()">Assign rules to this audit.</a> (any newly assigned rules will take affect on the next run or you can choose to process them now).</span>
  </div>
  <div class="summary-section">
    <op-summary-card
      headerText="Pages Scanned"
      [filteredValue]="pagesFilteredCount"
      [totalValue]="pagesTotalCount"
      [showTotalValueWhenFiltered]="true"
      [widgetState]="widgetState"
      [metricType]="pagesScannedMetricType"
      [auditId]="auditId"
      [runId]="runId"
      [currentValue]="pagesTotalCount"
      [filters]="currentFilters"
      [highlight]="highlightMetricType === pagesScannedMetricType"
      [disableHighlightFromClick]="true"
      tooltipText="Count of pages scanned in this audit/run"
      class="total-pages-card"
    ></op-summary-card>
    <op-summary-card
      headerText="Rules Assigned"
      [filteredValue]="rulesFilteredCount"
      [totalValue]="rulesTotalCount"
      [valueColorHighlight]="totalAssignedStatus"
      [showTotalValueWhenFiltered]="true"
      [widgetState]="widgetState"
      [metricType]="rulesAssignedMetricType"
      [auditId]="auditId"
      [runId]="runId"
      [currentValue]="rulesTotalCount"
      [filters]="currentFilters"
      [highlight]="highlightMetricType === rulesAssignedMetricType"
      [disableHighlightFromClick]="true"
      tooltipText="Number of rules applied during the most recent run"
      class="assigned-rules-card"
    ></op-summary-card>
    <op-summary-card
      headerText="Total Rule Failures"
      [filteredValue]="totalRuleFailureFilteredCount"
      [totalValue]="totalRuleFailureTotalCount"
      [valueColorHighlight]="totalFailureStatus"
      [showTotalValueWhenFiltered]="true"
      [widgetState]="widgetState"
      [metricType]="totalRuleFailureMetricType"
      [auditId]="auditId"
      [runId]="runId"
      [filters]="currentFilters"
      [highlight]="highlightMetricType === totalRuleFailureMetricType"
      [disableHighlightFromClick]="true"
      tooltipText="Count of all rules failure instances across all filtered pages"
      class="failed-rules-card"
    ></op-summary-card>
  </div>
  <div class="trends-section">
    <rule-summary-trends
      headerText="Rule Instances by Status"
      [totalRules]="rulesTotalCount"
      [trendData]="ruleInstancesTrend"
      [chartData]="ruleInstancesTrendChartData"
      [widgetState]="widgetState"
      [filters]="trendFilters"
      [filterBarFilters]="currentFilters"
      [auditId]="auditId"
      [runId]="runId"
      [alertMetricTypes]="ruleInstancesAlertTrendMetricTypes"
      [highlightMetricType]="highlightMetricType"
      tooltipText="Total number of rules by status and run date"
      (summaryCardClicked)="filterByClickedSummaryCard($event)"
      (historyClicked)="openRuleResultsFullScreenChart()"
      chartId="rule-summary-rule-instances-trend-chart"
      class="rule-summary-rule-instances-trend">
    </rule-summary-trends>
    <rule-summary-trends
      headerText="Pages by Rule Status"
      [totalRules]="rulesTotalCount"
      [trendData]="rulePagesTrend"
      [chartData]="rulePagesTrendChartData"
      [widgetState]="widgetState"
      [filters]="trendFilters"
      [filterBarFilters]="currentFilters"
      [auditId]="auditId"
      [runId]="runId"
      [alertMetricTypes]="rulePagesAlertTrendMetricTypes"
      [highlightMetricType]="highlightMetricType"
      tooltipText="Total number of pages by rule status and run date"
      (summaryCardClicked)="filterByClickedSummaryCard($event)"
      (historyClicked)="openRuleResultsPageCountFullScreenChart()"
      chartId="rule-summary-rule-pages-trend-chart"
      class="rule-summary-rule-pages-trend">
    </rule-summary-trends>
  </div>
  <div class="rule-results-section" [class.updating]="ruleResultsSpinnerState === spinnerStates.Loading">
    <div class="section-title-wrap" #ruleDetailsByPage>
      <div class="title-spinner-wrap">
        <p class="section-title">Rule Details By Page</p>
        <filter-spinner [state]="ruleResultsSpinnerState"></filter-spinner>
      </div>
    </div>
    <rule-summary-rules-table
      [apiFilters]="apiFilters"
      [inputData]="ruleResults"
      [spinnerState]="ruleResultsSpinnerState"
      [loadSpecificRuleResult]="loadSpecificRuleResults"
      [getTagNameByTagId]="getTagNameByTagId"
      [ruleSummaryTrendSelected]="trendFilters.cards"
      [userIsReadOnly]="userIsReadOnly"
      [rulesById]="rulesById"
      (onRuleConditionMetricClicked)="handleRuleConditionMetricClicked($event)"
      (onRuleEditClicked)="editRuleClicked($event)"
      (onEditEmail)="editRuleEmailClicked($event)"
    ></rule-summary-rules-table>
  </div>
  <div class="pages-section" #pagesSection [class.updating]="pageRuleResultsSpinnerState === spinnerStates.Loading">
    <div class="section-title-wrap sticky">
      <div class="title-spinner-wrap">
        <filter-spinner class="left-side" [state]="pageRuleResultsSpinnerState"></filter-spinner>
        <p class="section-title">Pages Scanned</p>
        <op-audit-report-export-menu
          [auditId]="auditId"
          [runId]="runId"
          [tableCompletedState]="pageRuleResultsSpinnerState"
          [config]="exportReportConfig">
        </op-audit-report-export-menu>
      </div>

      <op-switchable-column-menu [columnConfig]="PagesTableColumns"
                                 [storageKey]="CommonPagesConfigLocalStorageKey"
                                 [warningMessage]="CommonPagesColumnConfigWarningMessage"></op-switchable-column-menu>
    </div>
    <rule-summary-pages-specific
      *ngIf="selectedRuleConditionIdentifier else pageRuleResultsTable"
      [inputData]="pageRuleResultsSpecific"
      [tableState]="pageRuleResultsSpecificTableState"
      [spinnerState]="pageRuleResultsSpinnerState"
      [ruleResultType]="selectedRuleConditionIdentifier?.ruleResultType"
      (tableStateChanged)="pagesSpecificTableConfigChanged($event)"
    ></rule-summary-pages-specific>
    <ng-template #pageRuleResultsTable>
      <rule-summary-pages
        [inputData]="pageRuleResults"
        [pagesFilteredCount]="pagesFilteredCount"
        [tableState]="pageRuleResultsTableState"
        [spinnerState]="pageRuleResultsSpinnerState"
        (tableStateChanged)="pagesTableConfigChanged($event)"
      ></rule-summary-pages>
    </ng-template>
  </div>
</div>
