import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IStackedBarChartInput } from '@app/components/shared/components/viz/vertical-stacked-bar-chart/vertical-stacked-bar-chart.models';
import {
  IAlertAuditRunTagAndVariableRulesTrendMetrics,
  IAuditRunRuleSummaryTrend,
  IAuditRunRuleSummaryTrendFilters
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { EColorHighlightMeaning } from '@app/components/shared/components/viz/color-highlight/color-highlight.directive';
import { Router } from '@angular/router';
import { AlertMetricType, EAlertTagAndVariableRulesMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EAuditReportFilterTypes } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';

export type SummaryCardFilterType = 'failed' | 'passed' | 'notApplied';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-summary-trends',
  templateUrl: './rule-summary-trends.component.html',
  styleUrls: ['./rule-summary-trends.component.scss']
})
export class RuleSummaryTrendsComponent {

  @Input() headerText: string;
  @Input() chartId: string;
  @Input() runId: number;
  @Input() auditId?: number;
  @Input() filterBarFilters?: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  @Input() alertMetricTypes?: IAlertAuditRunTagAndVariableRulesTrendMetrics;

  @Input() chartData: IStackedBarChartInput;
  @Input() totalRules: number;
  @Input() trendData: IAuditRunRuleSummaryTrend;
  @Input() filters: IAuditRunRuleSummaryTrendFilters;
  @Input() widgetState: EFilterSpinnerState;
  @Input() tooltipText?: string;
  @Input() highlightMetricType: AlertMetricType;
  @Input() highlightMetricSection: 'none'|'pages'|'instances';

  @Output() summaryCardClicked = new EventEmitter<SummaryCardFilterType>();
  @Output() historyClicked = new EventEmitter<void>();

  constructor(private router: Router) {}

  colorEnum = EColorHighlightMeaning;

  get isEnoughData() {
    return !(this.chartData?.bars.length === 1 && +this.chartData?.bars[0].id === this.runId);
  }

  get failedRulesStatus() {
    if (this.trendData.failedRulesCountDiffWithPrev === 0) {
      return this.colorEnum.NEUTRAL;
    } else if (this.trendData.failedRulesCountDiffWithPrev > 0) {
      return this.colorEnum.NEGATIVE;
    }

    return this.colorEnum.POSITIVE;
  }

  get passedRulesStatus() {
    if (this.trendData.passedRulesCountDiffWithPrev === 0) {
      return this.colorEnum.NEUTRAL;
    } else if (this.trendData.passedRulesCountDiffWithPrev > 0) {
      return this.colorEnum.POSITIVE;
    }

    return this.colorEnum.NEGATIVE;
  }

  handleHistoryClicked() {
    if (this.totalRules > 0) {
      this.historyClicked.emit();
    }
  }
}
