<div class="step-container" [class.create-user]="!editMode">
  <div *ngFor="let step of steps"
       class="step"
       [class.active]="currentStep === step.id"
       (click)="stepSelected.emit(step.id)">
    <ng-container *ngIf="!isInAdminPortal || (step.id !== EUserStep.Notifications && step.id !== EUserStep.FolderAccess)">
      <div class="top">
        <span class="step-id">{{step.id + 1}}. </span>
        <span class="step-title">{{step.title}}</span>
        <mat-icon *ngIf="step.valid" class="step-check material-icons-outlined">check_circle</mat-icon>
        <mat-icon *ngIf="step.id !== 3 && !step.valid" class="step-check required-icon">emergency</mat-icon>
      </div>
      <div class="bottom" [innerHTML]="step.subtitle">
      </div>
    </ng-container>
  </div>
</div>

