import { AbstractControl, ValidationErrors } from '@angular/forms';

type validatorType = (control: AbstractControl) => ValidationErrors | null;

/**
 * Applies validator to form control based on condition (predicate)
 */
export default function conditionalValidator(predicate: (control: AbstractControl) => boolean, 
                                             validator: validatorType): validatorType {
  return (control => {
    if (!control.parent) return null;
    if (predicate(control)) return validator(control);
    return null;
  });
}
