<op-modal (onClose)="closeModal()"
          [rightFooterButtons]="rightFooterButtons"
          [leftFooterButtons]="[]">
  <ng-template #headerTemplate>
    <op-modal-header (onClose)="closeModal()"
                     [title]="data?.title"
    ></op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="code-highlighter-wrap">
      <op-code-highlighter
        #highlighter
        [label]="label"
        [formGroup]="formGroup"
        [controlName]="controlName"
        [readonly]="readonly"
        [value]="value"
        [showFullScreenButton]="false"
        [scrollAreaMaxHeight]="scrollAreaMaxHeight"
      ></op-code-highlighter>
    </div>
  </ng-template>
</op-modal>
