import { Component, Input } from '@angular/core';
import { EChartChangeTypes } from '../page-information/page-information.constants';
import {
  ISparklineChartColorizedBoundaries,
  ISparklineChartColorizedConfig,
  ISparklineChartColorizedData
} from '@app/components/shared/components/viz/sparkline-chart-colorized/sparkline-chart-colorized.models';

@Component({
  selector: 'op-sparkline-chart-insight-colorized',
  templateUrl: './sparkline-chart-insight-colorized.component.html',
  styleUrls: ['./sparkline-chart-insight-colorized.component.scss']
})
export class SparklineChartInsightColorizedComponent {
  readonly runsToDisplay = 5;
  chartConfig: ISparklineChartColorizedConfig;
  changeMeaning: EChartChangeTypes;
  changeValue: number;
  changeDirection: number = 0;

  @Input() uniqueId: string;

  private _dataPoints: ISparklineChartColorizedData[];

  get dataPoints(): ISparklineChartColorizedData[] {
    return this._dataPoints;
  }

  @Input() set dataPoints(data: ISparklineChartColorizedData[]) {
    if (data) {
      if (data.length > this.runsToDisplay) {
        data = data.slice(data.length - this.runsToDisplay, data.length);
      }

      this.chartConfig = { data };

      const lastDataPt = data[data.length - 1];

      if (data.length > 1) {
        const secondToLastDataPt = data[data.length - 2];

        this.getChangeDirection(lastDataPt, secondToLastDataPt);
        this.getChangeValue(this.changeDirection);
        this.getChangeMeaning();
      } else if (data.length === 1) {
        this.setNoChanges(lastDataPt);
      }
    }
    this._dataPoints = data;
  };

  @Input() boundaries: ISparklineChartColorizedBoundaries;
  @Input() dataLoaded?: boolean = true;
  @Input() meaningOfUp: EChartChangeTypes = EChartChangeTypes.neutral;
  @Input() meaningOfNoChange: EChartChangeTypes = EChartChangeTypes.none;
  @Input() label: string;
  @Input() digitsInfo: string = '1.0-3';

  constructor() {
    this.chartConfig = {
      data: [],
    };
  }

  private getChangeMeaning(): void {
    if (this.changeDirection === 0) {
      this.changeMeaning = this.meaningOfNoChange;
    } else if (this.meaningOfUp === EChartChangeTypes.positive) {
      this.changeMeaning = this.changeDirection > 0 ? EChartChangeTypes.positive : EChartChangeTypes.negative;
    } else if (this.meaningOfUp === EChartChangeTypes.negative) {
      this.changeMeaning = this.changeDirection > 0 ? EChartChangeTypes.negative : EChartChangeTypes.positive;
    } else {
      this.changeMeaning = this.meaningOfUp;
    }
  }

  private getChangeDirection(lastDataPt: ISparklineChartColorizedData, secondToLastDataPt: ISparklineChartColorizedData): void {
    this.changeDirection = lastDataPt.value - secondToLastDataPt.value;
  }

  private getChangeValue(changeDirection: number): void {
    this.changeValue = Math.abs(changeDirection);
  }

  private setNoChanges(lastDataPt: ISparklineChartColorizedData,): void {
    this.changeMeaning = EChartChangeTypes.none;
    this.changeValue = lastDataPt.value;
    this.changeDirection = 0;
  }
}
