import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UsageV2ApiService } from '@app/components/usage-v2/usage-v2-api.service';
import {
  EUsageExportType,
  EUsageItemType,
  IUsageContractTerm,
  IUsageRequestDTO,
  IUsageTrendsCurrentPeriod,
  IUsageTrendsDTO,
  IUsageTrendsFuturePeriod,
  IUsageTrendsPastPeriod
} from '@app/components/usage-v2/usage-v2.models';
import { combineLatest, Observable, Subject } from 'rxjs';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EUsageFilterTypes } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.enums';
import { takeUntil } from 'rxjs/operators';
import { IDisplayedCharts } from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import { UsageFilterBarService } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.service';
import { UsageV2Service } from '@app/components/usage-v2/usage-v2.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-audit-chart-content',
  templateUrl: './usage-audit-chart-content.component.html',
  styleUrls: ['./usage-audit-chart-content.component.scss']
})
export class UsageAuditChartContentComponent implements OnInit, OnDestroy {
  readonly topChartDisplayedCharts: IDisplayedCharts = {
    blueBars: true,
    greyBars: true,
    monthlyPacing: true,
  };
  readonly bottomChartDisplayedCharts: IDisplayedCharts = {
    blueLine: true,
    greyLines: true,
    termLimit: true,
  };
  @Input() $filtersChanged: Observable<IOpFilterBarV2Filter<EUsageFilterTypes>[]>;
  @Input() $termChanged: Observable<IUsageContractTerm>;

  trends: IUsageTrendsDTO;

  private destroySubject = new Subject<void>();
  currentFilters: IUsageRequestDTO;
  currentTerm: IUsageContractTerm;

  constructor(
    private usageApiService: UsageV2ApiService,
    private usageFilterBarService: UsageFilterBarService,
    private usageV2Service: UsageV2Service,
  ) {
  }

  get isFiltered(): boolean {
    return Object.values(this.currentFilters || {}).length > 0;
  }

  ngOnInit(): void {
    // events from the 2 observables below can fire at a random time.
    // we need to make sure that when at least one of them fires we have the latest values from both observables
    combineLatest([
      this.$filtersChanged,
      this.$termChanged
    ])
      .pipe(takeUntil(this.destroySubject))
      .subscribe(([filters, contractTerm]) => {
        this.currentFilters = this.usageFilterBarService.generateApiPostBody(filters);
        this.currentTerm = contractTerm;
        this.getUsageTrends();
      });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  getUsageTrends(): void {
    this.trends = undefined;
    this.usageApiService.getUsageTrends(EUsageItemType.AUDIT, this.currentFilters, this.currentTerm)
      .subscribe((trends) => {
        this.trends = trends;
      });
  }

  exportPeriod(period: IUsageTrendsPastPeriod | IUsageTrendsCurrentPeriod | IUsageTrendsFuturePeriod) {
    this.usageApiService.sendPeriodReportToEmail(EUsageExportType.AuditRuns, period as any)
      .subscribe(
        () => this.usageV2Service.showExportSuccessSnackbar(),
        () => this.usageV2Service.showExportErrorSnackbar()
      );
  }
}
