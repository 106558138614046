import { MultiSelectApiService } from '@app/components/manage/shared/services/multi-select-api/multi-select-api.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IButton } from '../../../../models/commons';
import { IBulkAssignRulesModalData } from './bulk-assign-rules-modal.models';
import { IRule, IRulePreview } from '@app/components/rules/rules.models';
import { RulesService } from '@app/components/rules/rules.service';
import { forkJoin } from 'rxjs';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { RuleSetupModalComponent } from '@app/components/rules/rule-setup/modal/rule-setup-modal.component';
import { ERuleSetupMode } from '@app/components/rules/rule-setup/rule-setup.enums';
import { IRuleSelection } from '@app/components/account/rules/rule-selector/rule-selector.models';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { tap } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bulk-assign-rules-modal',
  templateUrl: './bulk-assign-rules-modal.component.html',
  styleUrls: ['./bulk-assign-rules-modal.component.scss']
})
export class BulkAssignRulesModalComponent implements OnInit {

  title: string = 'Assign Rule(s)';
  rightFooterButtons: IButton[] = [
    {
      label: 'Assign',
      action: () => this.assignRules(),
      primary: true
    }
  ];

  rules: IRulePreview[] = [];
  labels: ILabel[] = [];

  ruleSelection: IRuleSelection = {
    selectedRuleIds: [],
    selectedItems: []
  };
  
  constructor(
    private dialogRef: MatDialogRef<BulkAssignRulesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public payload: IBulkAssignRulesModalData,
    private modalService: OpModalService,
    private rulesService: RulesService,
    private labelsApiService: LabelService,
    private multiSelectAPI: MultiSelectApiService,
  ) { }

  ngOnInit() {
    forkJoin([
      this.rulesService.getRulePreviews(),
      this.labelsApiService.getLabels(),
    ]).subscribe(([rules, labels]) => {
      this.rules = rules;
      this.labels = labels;
    });
  }

  openRuleCreation() {
    this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.create,
      }
    }, 'rule-setup-modal')
    .afterClosed()
    .subscribe((createdRule?: IRule) => this.onCreateRule(createdRule));
  }
  
  openRuleEditor(rule: IRule) {
    this.modalService.openFixedSizeModal(RuleSetupModalComponent, {
      disableClose: true,
      data: {
        mode: ERuleSetupMode.edit,
        ruleId: rule.id
      }
    }, 'rule-setup-modal')
    .afterClosed()
    .subscribe((updatedRule?: IRule) => this.onUpdateRule(updatedRule));
  }

  selectionChanged(ruleSelection: IRuleSelection) {
    this.ruleSelection = ruleSelection;
  }

  assignRules() {
    this.multiSelectAPI.assignRules(this.payload.multiSelection, this.ruleSelection.selectedRuleIds).subscribe();
    this.close(true);
  }

  close(isOperationConfirmed = false): void {
    this.dialogRef.close(isOperationConfirmed);
  }

  private async onCreateRule(createdRule?: IRule) {
    if (!createdRule) return;

    if (createdRule.labels.length > 0) this.refreshLabels().subscribe();
    this.rules.push(createdRule);
    this.updateSelectedRuleAfterCreation(createdRule);
  }

  private updateSelectedRuleAfterCreation(newRule: IRule) {
    const selectedRuleIds = [...this.ruleSelection.selectedRuleIds, newRule.id];
    const selectedItems = [...this.ruleSelection.selectedItems, { rule: newRule }];
    this.selectionChanged({ selectedRuleIds, selectedItems });
  }

  private async onUpdateRule(updatedRule?: IRule) {
    if (!updatedRule) return;

    this.refreshLabels().subscribe(() => {
      this.rules = this.rules.map(rule => rule.id === updatedRule.id ? updatedRule : rule);
      this.updateSelectedRuleAfterUpdation(updatedRule);
    });
  }

  private updateSelectedRuleAfterUpdation(updatedRule: IRule) {
    const selectedItems = this.ruleSelection.selectedItems.map(selectedItem => {
      if (selectedItem.rule.id !== updatedRule.id) return selectedItem;
      const rule: IRulePreview = {
        id: updatedRule.id,
        name: updatedRule.name,
        isDefaultRule: updatedRule.isDefaultRule,
        labels: updatedRule.labels
      };
      return { label: selectedItem.label, rule };
    });
    const selectedRuleIds = this.ruleSelection.selectedRuleIds ?? [];
    this.selectionChanged({ selectedRuleIds, selectedItems });
  }

  private refreshLabels() {
    return this.labelsApiService.getLabels().pipe(
      tap(labels => this.labels = labels)
    );
  }

  private ruleHasPageFilters(rule: IRule) {
    return rule.pageFilters && rule.pageFilters.length > 0;
  }
}
