import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { IAuditSummaryPrimaryTag } from '../audit-summary.models';
import { IPrimaryTagEmittedData, IPrimaryTagHealthCard } from './primary-tag-health-card.models';
import { IDonutChartDataPoint } from '../../../../shared/components/viz/donut-chart/donut-chart.models';
import { EChartColor, EStatusCodeCategories } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { AuditReportService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { EPrimaryTagHealthCardMenuItem } from '../audit-summary.constants';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { EStatusCodeLevels } from './primary-tag-health-card.constants';
import { shortFallName } from '@app/components/shared/components/viz/donut-chart/donut-chart.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'primary-tag-health-card',
  templateUrl: './primary-tag-health-card.component.html',
  styleUrls: ['./primary-tag-health-card.component.scss']
})
export class PrimaryTagHealthCardComponent implements OnChanges {

  tag: IPrimaryTagHealthCard;
  pagesWithTagsChartData: IDonutChartDataPoint[];
  statusCodesChartData: IDonutChartDataPoint[];
  menuOptions = EPrimaryTagHealthCardMenuItem;

  @Input() data: IAuditSummaryPrimaryTag;
  @Input() pagesScanned: number;

  @Output() menuItemClicked: EventEmitter<IPrimaryTagEmittedData> = new EventEmitter();
  @Output() accountCountClicked: EventEmitter<IPrimaryTagHealthCard> = new EventEmitter();

  constructor(
    private auditReportService: AuditReportService,
    private filterBarService: AuditReportFilterBarService
  ) { }

  ngOnChanges(): void {
    if(!this.data || !this.pagesScanned) return;

    this.pagesWithTagsChartData = this.formatDataForPagesWithTagsChart();
    this.statusCodesChartData = this.formatDataForStatusCodesChart();
    this.tag = {
      ...this.data,
      tagIconUrl: UiTagService.getTagIconUrl(this.data.tagId),
      tagLoadTimeClass: this.auditReportService.getLoadTimeClassForMs(this.data.tagLoadTimeAverage),
      menuOpen: false
    };
  }

  formatDataForPagesWithTagsChart(): IDonutChartDataPoint[] {
    return [{
      name: 'Explore pages with tag',
      colorClass: EChartColor.Blue,
      value: this.data.pageCount,
      singleValueMissingSectionTooltip: 'Explore pages without tag',
      totalForPercentage: this.pagesScanned
    }];
  }

  formatDataForStatusCodesChart(): IDonutChartDataPoint[] {
    return [
      {
        name: 'Explore broken status codes for this tag',
        colorClass: EChartColor.Red,
        value: this.data.statusCodeDistribution.broken
      },
      {
        name: 'Explore redirect status codes for this tag',
        colorClass: EChartColor.Yellow,
        value: this.data.statusCodeDistribution.redirect
      },
      {
        name: 'Explore success status codes for this tag',
        colorClass: EChartColor.Green,
        value: this.data.statusCodeDistribution.good
      }
    ];
  }

  addGlobalTagFilter(tag: IPrimaryTagHealthCard): void {
    this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);
  }

  navToTagInventory(
    { mouseEvent, item }: { mouseEvent: MouseEvent, item: IDonutChartDataPoint },
    tag: IPrimaryTagHealthCard
  ): void {
    this.filterBarService.addTagIdFilter(tag.tagName, tag.tagId);

    const filterData = {
      tagId: tag.tagId,
      missingTags: item.name === shortFallName
    };

    this.menuItemClicked.emit({ tag, event: EPrimaryTagHealthCardMenuItem.TagInventory, tagPresenceFilter: filterData });
  }

  navToTagHealth(tag: IPrimaryTagHealthCard): void {
    this.menuItemClicked.emit({ tag, event: EPrimaryTagHealthCardMenuItem.TagHealth });
  }

  navToTagHealthWithStatusCode(
    { mouseEvent, item }: { mouseEvent: MouseEvent, item: IDonutChartDataPoint },
    tag: IPrimaryTagHealthCard): void {
    this.filterBarService.addTagStatusCodeFilter(this.getStatusCodeFilterArgument(item));
    this.menuItemClicked.emit({ tag, event: EPrimaryTagHealthCardMenuItem.TagHealth });
  }

  private getStatusCodeLevelFromChart(item: IDonutChartDataPoint): string {
    switch (item.colorClass) {
      case EChartColor.Green:
        return EStatusCodeLevels.Success;
      case EChartColor.Yellow:
        return EStatusCodeLevels.Redirect;
      case EChartColor.Red:
        return EStatusCodeLevels.Broken;
    }
  }

  private getStatusCodeFilterArgument(item: IDonutChartDataPoint) {
    const statusCodeLevels = {
      success: EStatusCodeCategories.Successes,
      redirect: EStatusCodeCategories.Redirects,
      broken: EStatusCodeCategories.Broken
    };

    return statusCodeLevels[this.getStatusCodeLevelFromChart(item)];
  }
}
