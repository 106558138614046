import { Component, Input } from '@angular/core';
import { IStatusBarItem } from './status-progress-bar.models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'status-progress-bar',
  templateUrl: './status-progress-bar.component.html',
  styleUrls: ['./status-progress-bar.component.scss']
})
export class StatusProgressBarComponent {

  @Input() items: IStatusBarItem[];
  @Input() barSize: string;

  provideLabel(item: IStatusBarItem): string {
    return item.label;
  }

  getItemState(item: IStatusBarItem): string {
    return item.state;
  }

}