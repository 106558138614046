<div class="cc-edit-tags">
  <div class="cc-edit-top-section">
    <div class="search-add-wrap">
      <op-clearable-input placeholderText="Search by tag name or tag account"
                          [emitKeyboardEvent]="true"
                          appearance="OUTLINED"
                          class="table-filter-input"
                          (onUserInput)="applyFilter($event)">
      </op-clearable-input>
      <div class="global-add-delete">
        <button class="add-new-tag add-new-tag-yellow" (click)="addNewTag()">
          <mat-icon>add_circle_outline</mat-icon>
          <span class="add-text">Add tag</span>
        </button>
        <mat-divider [vertical]="true" style="height: 15px; margin: 0 5px;" *ngIf="tags.value?.length > 0"></mat-divider>
        <button class="add-new-tag"
                *ngIf="tags.value?.length > 0"
                (click)="openConfirmDeleteAllTags()"
                matTooltip="Delete All Tags"
                matTooltipPosition="above">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

  </div>
  <div class="table-wrap">
    <table mat-table [dataSource]="dataSource" class="cc-edit-tags-table" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag Name</th>
        <td mat-cell *matCellDef="let tag">
          <img class="tag-icon" [src]="tag.icon" />
          {{ tag.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="accounts">
        <th mat-header-cell *matHeaderCellDef>Tag Accounts</th>
        <td mat-cell *matCellDef="let tag">
          <mat-form-field class="tag-account-list" floatLabel="never" appearance="outline">
            <mat-chip-grid #accountList>
              <mat-chip-row *ngFor="let account of tag.accounts"
                            selectable="false"
                            [matTooltip]="account?.length > 25 ? account : null"
                            matTooltipPosition="above"
                            matTooltipShowDelay="300"
                            class="account-chip"
                            removable="true"
                            (removed)="removeAccount(account, tag)" >
                {{ account }}
                <mat-icon matChipRemove class="mat-chip-remove">cancel</mat-icon>
              </mat-chip-row>
              <input [matChipInputFor]="accountList"
                     [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                     matChipInputAddOnBlur="true"
                     (matChipInputTokenEnd)="addAccount($event, tag)"
                     [placeholder]="chipPlaceholderText">
            </mat-chip-grid>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag Category</th>
        <td mat-cell *matCellDef="let tag" >
          {{ tag.category }}
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let tag" align="right">
          <mat-icon matTooltip="Delete"
                    matTooltipPosition="above"
                    (click)="deleteTag(tag)">
            delete
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div [hidden]="tags.value.length <= minCookiesToShowPaginator">
      <mat-paginator [pageSize]="perPage"
                     [pageSizeOptions]="pageSizeOptions"
                     [showFirstLastButtons]="true"
                     (page)="savePageSizeToStorage($event)"
                     aria-label="Select cookies table page size">
      </mat-paginator>
    </div>
    <div class="spinner-container" *ngIf="loading">
      <mat-spinner [diameter]="50" [strokeWidth]="4"></mat-spinner>
    </div>
  </div>
</div>
