import { select, Selection, BaseType } from 'd3-selection';
import { scaleLinear, scaleTime, ScaleLinear, ScaleTime } from 'd3-scale';
import { format } from 'd3-format';
import { timeSecond, timeMinute, timeHour, timeDay, timeWeek, timeMonth, timeYear } from 'd3-time';
import { timeFormat } from 'd3-time-format';
import { transition } from 'd3-transition';
import { min, max } from 'd3-array';
import { line, Line } from 'd3-shape';
import { zoom, ZoomBehavior } from 'd3-zoom';
import { axisBottom, axisLeft, Axis, AxisDomain } from 'd3-axis';
import { formatDistanceToNow, isAfter, isBefore } from 'date-fns';

import { IFormattedRun } from '../../../domains/discoveryAudits/reporting/summaryReports/auditSummary/auditSummaryData';
import { IUser } from '../../../../moonbeamModels';
import { EDateFormats, formatDate } from '@app/components/date/date.service';

export interface ITimeSeriesRuns {
  name: string;
  values: Array<IFormattedRun>;
  running?: boolean;
}

interface IMargin {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface ITimeSeries {
  isInitialized(): boolean;
  margin: IMargin;
  seriesTitle: string;
  updateTitle(title: string): void;
  tipDisabled: boolean;
  width: number;
  height: number;
  setUser(user: IUser): void;
  setData(data: Array<ITimeSeriesRuns>): void;
  setSize: (width: number, height: number) => void;
  create(parent: Element, clipLocation: string, uniqueIndex: number): void;
  tooltipFunction: (d: any) => void;
  drawGraph: () => void;
  focusLine: (num: number) => void;
  unfocusLine: (num: number) => void;
  onDataPointSelected: (id: number) => void;
  selectedId: number;
  setSelectedId(id: number): void;
  closeTooltip(): void;
}

export class TimeSeries implements ITimeSeries {
  margin: IMargin = { top: 20, right: 25, bottom: 50, left: 50 };
  DEFAULT_RUNS_LIMIT: number = 3;
  width: number;
  height: number;
  private innerWidth: number;
  private innerHeight: number;

  seriesTitle: string = 'Time Series Chart';
  percentFormat: (value: number) => string;
  timeFormat: (value: Date) => string;
  scaleExtentRange: [number, number];

  onDataPointSelected: (id: number) => void;
  selectedId: number;

  tipDisabled: boolean;
  color: any = undefined;
  svg: Selection<any, any, any, any>;
  outerSvg: Selection<any, any, any, any>;
  lineBuilder: Selection<any, any, any, any>;
  clipRect;
  tip;
  clip;

  private radius: number = 4;
  private selectedRadius: number = 7;
  private parent: Element;
  private data: Array<any>;
  private user: IUser;
  private clipLocation: string;
  private clipIndex: number;

  private readonly tooltipWidth = 160;
  private readonly tooltipHeight = 70;
  private readonly tooltipArrow = 12;

  private xScale: ScaleTime<number, number>;
  private yScale: ScaleLinear<number, number>;

  constructor() {
    this.percentFormat = format('.0%');

    const formatMillisecond = timeFormat('.%L'),
          formatSecond = timeFormat(':%S'),
          formatMinute = timeFormat('%I:%M'),
          formatHour = timeFormat('%I %p'),
          formatDay = timeFormat('%a %d'),
          formatWeek = timeFormat('%b %d'),
          formatMonth = timeFormat('%B'),
          formatYear = timeFormat('%Y');

    this.timeFormat = function multiFormat(date) {
      return (timeSecond(date) < date ? formatMillisecond
          : timeMinute(date) < date ? formatSecond
          : timeHour(date) < date ? formatMinute
          : timeDay(date) < date ? formatHour
          : timeMonth(date) < date ? (timeWeek(date) < date ? formatDay : formatWeek)
          : timeYear(date) < date ? formatMonth
          : formatYear)(date);
    };
  }

  create(parent: Element, clipLocation: string, uniqueIndex: number): void {
    this.parent = parent;
    this.clipLocation = clipLocation;
    this.clipIndex = uniqueIndex;
  }

  isInitialized(): boolean {
    return (
      this.width > 0 && this.height > 0 && this.data && this.data.length > 0
    );
  }

  setUser(user: IUser): void {
    this.user = user;
  }

  setData(data: Array<ITimeSeriesRuns>) {
    this.data = data;
  }

  setSelectedId(id: number): void {
    this.selectedId = id;
    this.updateGraphPoints();
  }

  setSize(width: number, height: number): void {
    this.width = width;
    this.height = height;
    this.updateSvgWidth();
  }

  private updateSvgWidth(): void {
    this.measure();
    if (this.svg) {
      this.updateGraph();

      this.setXScale(this.data);
      this.setYScale();
      var line = this.createLine();
      var xAxis = this.createXAxis();
      var yAxis = this.createYAxis();
      var zoom = this.createZoom(this.createZoomCallback(xAxis, yAxis, line) as any);

      this.clip.attr('width', this.innerWidth).attr('height', this.innerHeight);

      this.svg.call(zoom);

      this.svg.append('svg:g').attr('class', 'x axis');

      this.updateLines(line);
      this.updateGraphPoints();

      transition()
        .select('.y.axis')
        .call(yAxis);

      transition()
        .select('.x.axis')
        .attr('transform', 'translate(0,' + this.innerHeight + ')')
        .call(xAxis);
    }
  }

  measure(): void {
    this.innerWidth = this.width - this.margin.left - this.margin.right;
    this.innerHeight = this.height - this.margin.top - this.margin.bottom;
  }

  private updateGraph() {
    this.svg.selectAll('.timeSeriesSvg').attr('width', this.innerWidth);

    this.svg.selectAll('.plot').attr('width', this.innerWidth);

    this.svg.selectAll('.timeSeriesTitle').attr('x', this.innerWidth / 2);

    this.svg
      .selectAll('.timeSeriesInstructions')
      .attr('x', this.innerWidth - this.margin.right - this.margin.left);

    this.svg.selectAll('.clipRect').attr('width', this.innerWidth);
  }

  constructGraph() {
    this.outerSvg = select(this.parent)
      .append('svg')
      .attr('class', 'timeSeriesSvg')
      .attr('height', this.innerHeight + this.margin.top + this.margin.bottom);

    this.clip = this.outerSvg
      .append('defs')
      .append('svg:clipPath')
      .attr('id', 'clip-' + this.clipIndex)
      .append('svg:rect')
      .attr('width', this.innerWidth)
      .attr('height', this.innerHeight);

    this.svg = this.outerSvg
      .append('g')
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );

    this.svg
      .append('svg:rect')
      .attr('width', this.innerWidth)
      .attr('height', this.innerHeight)
      .attr('class', 'plot');

    this.tip = this.createTooltip();

    this.svg
      .append('text')
      .attr('class', 'timeSeriesTitle')
      .attr('x', this.innerWidth / 2)
      .attr('y', 5 - this.margin.top / 2)
      .attr('text-anchor', 'middle')
      .text(this.seriesTitle);

    this.svg
      .append('text')
      .attr('class', 'timeSeriesInstructions')
      .attr('x', this.innerWidth - this.margin.right - this.margin.left)
      .attr('y', 5 - this.margin.top / 2)
      .attr('text-anchor', 'middle')
      .text('Scroll on Chart to Zoom');
  }

  updateTitle(title: string): void {
    this.seriesTitle = title;

    if (this.svg) {
      this.svg.select('.timeSeriesTitle').text(this.seriesTitle);
    }
  }

  tooltipFunction = function(d: any) {
    var tooltipDate = formatDate(new Date(d.date), EDateFormats.dateOne);

    return (
      "<span style='color: #e8ca14'>" +
      (d.name != null ? d.name : tooltipDate) +
      '</span>' +
      (d.name != null ? '<br/>' + tooltipDate : '') +
      '<br/>' +
      formatDistanceToNow(new Date(d.date)) +
      '<br/>Score: ' +
      d.score +
      ' / ' +
      d.possibleScore +
      ' (' +
      (d.value * 100).toFixed(0) +
      '%)'
    );
  };

  private createTooltip(): Selection<Element, unknown, any, any> {
    return select('body').append('div')
             .attr('class', 'd3-tooltip')
             .style('opacity', 0);
  }

  closeTooltip(): void {
    select('div.d3-tooltip').remove();
  }

  private setXScale(lineData: Array<ITimeSeriesRuns>): void {
    var xMaxDate = new Date(
      max(lineData, c => {
        return max(c.values, v => {
          return v.date;
        });
      })
    );

    var xMinDate = new Date(
      min(lineData, c => {
        return min(c.values, v => {
          return v.date;
        });
      })
    );

    var extentSpacing = this.determineXScale(xMaxDate, xMinDate);
    var xMax = new Date(xMaxDate.setDate(xMaxDate.getDate() + extentSpacing));
    var xMin = new Date(xMinDate.setDate(xMinDate.getDate() - extentSpacing));

    this.xScale = scaleTime()
      .domain([xMin.getTime(), xMax.getTime()])
      .range([0, this.innerWidth]);
  }

  private determineXScale(xMaxDate: any, xMinDate: any): number {
    var difference =
      Math.ceil(
        Math.abs(new Date(xMaxDate).getTime() - new Date(xMinDate).getTime())
      ) /
      (1000 * 3600);
    if (difference < 10) {
      this.scaleExtentRange = [1, 10];
      return 1 / 24;
    } else if (difference >= 10 && difference < 24) {
      this.scaleExtentRange = [1, 100];
      return 2 / 24;
    } else if (difference >= 24 && difference < 144) {
      this.scaleExtentRange = [1, 100];
      return 6 / 24;
    } else if (difference >= 144 && difference < 2400) {
      this.scaleExtentRange = [1, 1000];
      return 84 / 24;
    } else if (difference >= 2400) {
      this.scaleExtentRange = [1, 10000];
      return 720 / 24;
    }
  }

  private setYScale(): void {
    this.yScale = scaleLinear()
      .domain([0, 1])
      .range([this.innerHeight - 10, 10]);
  }

  private createLine(): Line<[number, number]> {
    return line()
      .x((d: any) => this.xScale(d.date.getTime()))
      .y((d: any) => this.yScale(d.value));
  }

  private createZoom(zoomed: () => any): ZoomBehavior<Element, number> {
    return zoom<Element, number>()
      .scaleExtent(this.scaleExtentRange)
      .on('zoom', zoomed);
  }

  private createXAxis(): any {
    return axisBottom(this.xScale)
      .tickPadding(8)
      .tickFormat(this.timeFormat);
  }

  private createYAxis(): any {
    return axisLeft(this.yScale)
      .tickSize(0 - this.innerWidth)
      .tickPadding(8)
      .tickFormat(this.percentFormat);
  }

  private createZoomCallback(xAxis: Axis<AxisDomain>,
                             yAxis: Axis<AxisDomain>,
                             line: Line<[number, number]>) {
    return (event) => {
      const newXScale = event.transform.rescaleX(this.xScale);
      this.svg.select('.x.axis').call(xAxis.scale(newXScale));

      this.svg.selectAll<Element, any>('.point, .tipcircle')
        .attr('cx', d => newXScale(d.date.getTime()));
      this.svg.selectAll<Element, any>('.line')
        .attr('d', d => line
            .x((d: any) => newXScale(d.date.getTime()))
            (d.values)
        );
    };
  }

  drawGraph() {
    if (this.isInitialized()) {
      this.measure();
      if (!this.svg) {
        this.constructGraph();
      }
      this.render();
    }
  }

  private render() {
    this.setXScale(this.data);
    this.setYScale();
    var line = this.createLine();
    var xAxis = this.createXAxis();
    var yAxis = this.createYAxis();
    var zoom = this.createZoom(this.createZoomCallback(xAxis, yAxis, line) as any);

    this.svg.call(zoom);

    this.svg.append('svg:g').attr('class', 'x axis');
    this.svg.append('svg:g').attr('class', 'y axis');

    var graph = this.svg.selectAll('.graph').data(this.data);

    this.updateLine(graph, line);
    const enterLine = this.enterLine(graph, line);
    graph = graph.merge(enterLine);

    var point = graph.selectAll('circle.point').data(d => d.values);
    this.updatePoints(point);
    this.enterPoints(point);
    this.exitPoints(point);

    var tooltipPoints = graph.selectAll('circle.tooltip').data(d => d.values);
    this.updatePoints(tooltipPoints);
    this.enterTooltipPoints(tooltipPoints);
    this.exitPoints(tooltipPoints);

    select('.y.axis')
      .transition()
      .call(yAxis);

    transition()
      .select('.x.axis')
      .attr('transform', 'translate(0,' + this.innerHeight + ')')
      .call(xAxis);
  }

  private updateGraphPoints(): void {
    var graph = this.svg.selectAll('.graph');

    var pointSelection = graph.selectAll('circle.point');
    this.updatePoints(pointSelection);

    var tooltipSelection = graph.selectAll('circle.tooltip');
    this.updatePoints(tooltipSelection);
  }

  private updateLines(line: Line<[number, number]>) {
    this.svg.selectAll<BaseType, any>('.line').attr('d', d => {
      return line(d.values);
    });
    this.svg
      .selectAll<BaseType, any>('.tipcircle')
      .attr('cx', d => {
        return this.xScale(d.date.getTime());
      })
      .attr('cy', d => {
        return this.yScale(d.value);
      });
  }

  private updateLine(graph: Selection<BaseType, any, any, any>,
                     line: Line<[number, number]>): void {
    graph
      .selectAll('path')
      .data(d => {
        return [
          {
            values: d.values
          }
        ];
      })
      .transition()
      .duration(250)
      .attr('d', d => {
        return line(<any>d.values);
      });
  }

  private enterLine(
    graph: Selection<any, any, any, any>,
    line: Line<[number, number]>
  ): Selection<BaseType, any, any, any> {
    this.lineBuilder = graph
      .enter()
      .append('g')
      .attr(
        'clip-path',
        'url(' + this.clipLocation + '#clip-' + this.clipIndex + ')'
      )
      .attr('class', 'graph')
      .style('stroke-width', 1.5);

    this.lineBuilder
      .append('path')
      .attr('class', d => 'line ' + d.name)
      .attr('d', d => line(d.values));

    return this.lineBuilder;
  }

  private updatePoints(pointSelection: Selection<any, any, any, any>) {
    pointSelection
      .transition()
      .duration(250)
      .attr('r', (d: IFormattedRun) => {
        return d.id == this.selectedId ? this.selectedRadius : this.radius;
      })
      .attr('cx', d => {
        return this.xScale(d.date.getTime());
      })
      .attr('cy', d => {
        return this.yScale(d.value);
      });
  }

  private enterPoints(points: Selection<any, any, any, any>): any {
    return points
      .enter()
      .append('circle')
      .merge(points)
      .attr('class', d => {
        return 'point point-' + d.className;
      })
      .attr('cx', d => {
        return this.xScale(d.date.getTime());
      })
      .attr('cy', d => {
        return this.yScale(d.value);
      })
      .attr('r', (d: IFormattedRun) => {
        return d.id == this.selectedId ? this.selectedRadius : this.radius;
      })
      .style('stroke', 'white')
      .style('stroke-width', '2');
  }

  private exitPoints(pointSelection: Selection<any, any, any, any>) {
    pointSelection.exit().remove();
  }

  private enterTooltipPoints(tooltipPoints: Selection<any, any, any, any>): void {
    let tooltipPointsFilled = tooltipPoints
      .enter()
      .merge(tooltipPoints)
      .append('circle')
      .attr(
        'clip-path',
        'url(' + this.clipLocation + '#clip-' + this.clipIndex + ')'
      )
      .attr('class', 'tipcircle')
      .attr('cx', d => {
        return this.xScale(d.date.getTime());
      })
      .attr('cy', d => {
        return this.yScale(d.value);
      })
      .attr('r', 4)
      .style('opacity', 1e-6);

    tooltipPointsFilled
      .on('mouseover', (event, d) => {
        if (!this.tipDisabled) {
          this.tip.transition()
                  .duration(200)
                  .style('opacity', .75);
          this.tip.html(this.tooltipFunction(d))
                  .style('left', (event.pageX - this.tooltipWidth / 2) + 'px')
                  .style('top', (event.pageY - this.tooltipHeight - this.tooltipArrow) + 'px');
        }
      })
      .on('mouseout', () => {
        this.tip.transition()
                .duration(200)
                .style('opacity', 0);
        this.tip.html('');
      });

    if (this.onDataPointSelected) {
      var availablePoints = tooltipPointsFilled
        .sort((a, b) => {
          return isBefore(a.date, b.date) ? -1 : isAfter(a.date, b.date) ? 1 : 0;
        });
      availablePoints.style('cursor', 'pointer');
      availablePoints.on('click', (d: IFormattedRun) => {
        this.selectedId = d.id;
        this.updateGraphPoints();
        this.onDataPointSelected(d.id);
      });
    }
  }

  private getLines(): BaseType[] {
    return this.lineBuilder?.nodes?.() || [];
  }

  private getLine(i: number): BaseType {
    return this.getLines()[i];
  }

  focusLine = (num: number) => {
    select(this.getLine(num))
      .transition()
      .duration(200)
      .style('stroke-width', 3.5);
    this.getLines().map((d, i) => {
      if (i !== num) {
        select(this.getLine(i))
          .transition('unfocus')
          .duration(200)
          .style('opacity', 0.4);
      }
    });
  }

  unfocusLine = (num: number) => {
    select(this.getLine(num))
      .transition('focus')
      .duration(200)
      .style('stroke-width', 1.5);
    this.getLines().map((d, i) => {
      if (i !== num) {
        select(this.getLine(i))
          .transition('unfocus')
          .duration(200)
          .style('opacity', 1);
      }
    });
  }
}

export const timeSeries = [
  function() {
    return function () {
      return new TimeSeries();
    };
  }
];
