import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import {
  IOpFilterBarMenuItem,
  ISearchByTextEmissionData
} from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  EManageAccountsFilterTypes, EManageAccountStatus
} from '@app/components/admin-portal/manage-accounts/manage-accounts-filter-bar/manage-accounts-filter-bar.constants';
import { ManageAccountsFilterBarService } from '@app/components/admin-portal/manage-accounts/manage-accounts-filter-bar/manage-accounts-filter-bar.service';
import {
  AccountTypeIdToDisplayValue, EAccountTypeIds
} from '@app/components/admin-portal/manage-accounts/manage-accounts.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-accounts-filter-bar',
  templateUrl: './manage-accounts-filter-bar.component.html',
  styleUrls: ['./manage-accounts-filter-bar.component.scss']
})
export class ManageAccountsFilterBarComponent implements OnInit, OnDestroy {

  filterBarMenuItems: IOpFilterBarMenuItem[] = [];
  validFilterTypes: EManageAccountsFilterTypes[];
  private destroy$: Subject<void> = new Subject();

  @Output() typeahead: EventEmitter<string> = new EventEmitter();

  constructor(
    public filterBarService: ManageAccountsFilterBarService,
  ) {}

  ngOnInit(): void {
    this.getValidFilterTypes();
    this.buildMenu();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getValidFilterTypes(): void {
    this.filterBarService.validFilterTypesUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe((validFilterTypes: EManageAccountsFilterTypes[]) => {
        this.validFilterTypes = validFilterTypes;
      });
  }

  buildMenu(): void {
    this.filterBarMenuItems = [
      {
        name: 'Account Type',
        type: EFilterBarMenuTypes.Flyout,
        children: EAccountTypeIds.map(accountTypeId => ({
          name: AccountTypeIdToDisplayValue[accountTypeId],
          type: EFilterBarMenuTypes.Button,
          action: () => this.filterBarService.addTypeFilter(accountTypeId)
        }))
      },
      {
        name: 'Account Status',
        type: EFilterBarMenuTypes.Flyout,
        children: [
          {
            name: 'Active',
            type: EFilterBarMenuTypes.Button,
            action: () => this.filterBarService.addStatusFilter(EManageAccountStatus.Active)
          },
          {
            name: 'Active Billing Problem',
            type: EFilterBarMenuTypes.Button,
            action: () => this.filterBarService.addStatusFilter(EManageAccountStatus.ActiveBillingProblem)
          },
          {
            name: 'Inactive',
            type: EFilterBarMenuTypes.Button,
            action: () => this.filterBarService.addStatusFilter(EManageAccountStatus.Inactive)
          },
          {
            name: 'Inactive Billing Failed',
            type: EFilterBarMenuTypes.Button,
            action: () => this.filterBarService.addStatusFilter(EManageAccountStatus.InactiveBillingFailed)
          },
          {
            name: 'Inactive User Cancelled',
            type: EFilterBarMenuTypes.Button,
            action: () => this.filterBarService.addStatusFilter(EManageAccountStatus.InactiveUserCancelled)
          },
          {
            name: 'Demo',
            type: EFilterBarMenuTypes.Button,
            action: () => this.filterBarService.addStatusFilter(EManageAccountStatus.Demo)
          },
        ]
      },
      {
        name: 'Divider',
        type: EFilterBarMenuTypes.Divider
      },
      {
        name: 'clear all filters',
        type: EFilterBarMenuTypes.ClearBtn,
        action: () => this.filterBarService.clear()
      }
    ];
  }

  handleSearchBy({ value, isTypeahead }: ISearchByTextEmissionData): void {
    if (isTypeahead) {
      this.typeahead.emit(value);
      return;
    }

    this.filterBarService.addSearchContainsFilter(value);
  }
}
