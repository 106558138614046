import {
  ChartClassName,
  ChartType,
  EChartType,
  IChartsConfig
} from '@app/components/usage-v2/components/usage-chart/usage-chart.models';
import {
  attachBarChartToSvg,
  hide,
  nearestDataPointCoordinates
} from '@app/components/shared/components/viz/utils/d3-chart.utils';
import * as d3 from 'd3';
import { EDateFormats, formatDate, toUTC } from '@app/components/date/date.service';
import {
  createTooltip,
  drawTooltip,
  getChartData
} from '@app/components/usage-v2/components/usage-chart/usage-chart.utils';
import { VizUtilsService } from '@app/components/shared/components/viz/utils/viz-utils.service';
import { IUsageTrendsDTO } from '@app/components/usage-v2/usage-v2.models';
import {
  getActualUsageToggleBtn
} from '@app/components/usage-v2/components/usage-chart/datasets/past-periods/utils/toggle-buttons';

export function addPastPeriodsPeriodTotalChartToConfig(trends: IUsageTrendsDTO, config: IChartsConfig, chartType: EChartType): void {
  if (!trends.pastPeriods) {
    return;
  }
  config.charts[ChartType.pastPeriodsPeriodTotal] = {
    data: getChartData(trends.pastPeriods, 'periodTotal.total'),
    chartClassName: ChartClassName.pastPeriods,
    toggleBtn: getActualUsageToggleBtn(trends, chartType),
  };
}

export function drawPastPeriodsPeriodTotalChart(
  config: IChartsConfig,
  svg: d3.Selection<SVGElement, any, any, any>,
  xAxis: d3.ScaleBand<string>,
  yAxis: d3.ScaleLinear<number, number>,
  height: number,
  margin: { top: number; right: number; bottom: number; left: number },
  barChartPadding: number,
  windowWidth: number,
  circleClass: string,
  tooltipClass: string,
  animationEnabled: boolean = true,
): void {
  const periodTotal = config.charts[ChartType.pastPeriodsPeriodTotal];

  if (!periodTotal) {
    return;
  }

  attachBarChartToSvg({
      data: periodTotal.data,
      x: xAxis,
      y: yAxis,
      gap: barChartPadding * 2,
      height: height - margin.top - margin.bottom,
      containerClassName: `period-total ${periodTotal.chartClassName}`,
      barClassName: 'period-total-bar',
      barLabelClassName: `bar-label`,
      barLabelTextFormatterFn: VizUtilsService.formatChartNumbers,
      animationDuration: animationEnabled ? 750 : 0,
    },
    svg)
    .on('mouseenter', (e) => {
      const { x, y, dataPoint } = nearestDataPointCoordinates(e, svg.node(), periodTotal.data, xAxis, yAxis);

      const tooltip = createTooltip(tooltipClass);

      drawTooltip(
        svg,
        '<div class="usage-chart-tooltip-body">' +
        '<h3 class="title">ACTUAL USAGE' + '<br>' +
        'IN ' +
        formatDate(toUTC(new Date(dataPoint.date)), EDateFormats.dateEight) +
        '</h3>' +
        '<div class="value">' +
        '<span class="value-data">' + VizUtilsService.formatChartNumbers(dataPoint.value) + '</span>' +
        '</div>' +
        '</div>',
        x,
        y,
        null,
        tooltip,
        windowWidth,
      );
    })
    .on('mouseleave', () => {
      hide(svg.select(`.${circleClass}`));
      hide(d3.select(`.${tooltipClass}`));
    });
}
