import { FeatureFlags } from './feature-flags.enum';
import * as SentryTypes from "@sentry/types";
import * as SentryAngularTypes from "@sentry/angular";

export interface IEnvironment {
  production: boolean;
  domain: string;
  apiProtocol: string;
  apiUrl: string;
  apiUploadAppUrl: string;
  apiV3Url: string;
  apiHost: string;
  apiScriptServices: string;
  gatewayHost: string;
  authApiProtocol: string;
  authApiHost: string;
  features: Array<FeatureFlags>;
  // Sentry init options -> https://github.com/getsentry/sentry-javascript/blob/ca7fc38ea8d72b12fe990371d3bca0c6ce741b33/packages/browser/src/client.ts#L25C38-L25C61
  sentryConfig?: SentryTypes.ClientOptions | SentryTypes.BrowserClientReplayOptions;
  // Sentry error options -> https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts#L13
  sentryErrorConfig?: SentryAngularTypes.ErrorHandlerOptions;
  webSocketUrl: string;
  originalRuleIdMappingDate: number;
  tagIconUrl: string;
}

export function updateApiUrlsForSso(env: IEnvironment) {
  const ssoHostnameMatch = /([\w-_]+)\.app\.observepoint(staging)?\.com/.exec(window.location.hostname);
  if (ssoHostnameMatch) {
    const ssoId = ssoHostnameMatch[1];

    env.apiHost = `${ssoId}.${env.apiHost}`;
    env.authApiHost = `${ssoId}.${env.authApiHost}`;

    const apiUrl = new URL(env.apiUrl);
    env.apiUrl = `${apiUrl.protocol}//${ssoId}.${apiUrl.host}${apiUrl.pathname}`;

    const apiV3Url = new URL(env.apiV3Url);
    env.apiV3Url = `${apiV3Url.protocol}//${ssoId}.${apiV3Url.host}${apiV3Url.pathname}`;
  }
}

// 9-May-2024: These are currently unused because we do not have any instances of these errors in our environments;
//             Leaving them here for future reference.
// This list was taken from https://gist.github.com/impressiver/5092952
// which was linked to from https://docs.sentry.io/platforms/node/configuration/filtering/
export const sentryIgnoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  'Can\'t find variable: ZiteReader',
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.'
];

export const sentryAllowUrls = [
  /app\.observepoint\.com/i,
  /app\.observepointstaging\.com/i,
]
