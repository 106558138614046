import { IProductLimits } from '@app/components/account/account.models';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { IAdminAccount } from '@app/components/admin-portal/manage-accounts/manage-accounts.constants';
import { OPValidators } from '@app/components/shared/validators/op-validators';
import { IKeyValue } from '@app/moonbeamModels';
import {
  ERetentionPolicyName,
  ERetentionPolicyNameToDisplayValue,
  ERetentionPolicyNameToIndex,
  IScriptServicesConfig,
  IV3AdminAccount
} from '../../../admin-accounts.service';
import { merge, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { OpConfirmModalComponent, OpModalService } from '@app/components/shared/components/op-modal';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'product-limits',
  templateUrl: './product-limits.component.html',
  styleUrls: ['./product-limits.component.scss']
})
export class ProductLimitsComponent implements OnInit, OnDestroy {
  protected readonly dataRetentionPolicies = Object.keys(ERetentionPolicyName);
  protected readonly ERetentionPolicyNameToDisplayValue = ERetentionPolicyNameToDisplayValue;

  MAX_DOMAINS: number = 100;

  productLimitsForm: UntypedFormGroup;

  private destroy$: Subject<void> = new Subject();

  @Input() productLimits: IProductLimits;
  @Input() account: IAdminAccount;
  @Input() accountV3: IV3AdminAccount;
  @Input() webJourneyFrequencies: IKeyValue<number, string>[];
  @Input() scriptServicesConfig: IScriptServicesConfig;

  @Output() updated: EventEmitter<any[]> = new EventEmitter();
  @Output() retentionPolicyUpdated: EventEmitter<ERetentionPolicyName> = new EventEmitter();
  retentionPolicy$ = new Subject<ERetentionPolicyName>();

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: OpModalService,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initForm(): void {
    this.productLimitsForm = this.fb.group({
      maxUsers: [this.account.maxUsers, [Validators.required, Validators.min(1)]],
      concurrentAudits: [this.account.concurrentAudits, [Validators.required, Validators.min(1)]],
      concurrentJourneys: [this.account.concurrentJourneys, [Validators.required, Validators.min(1)]],
      maxMonitoredJourneys: [this.scriptServicesConfig.maxMonitoredJourneys, [Validators.required, Validators.min(-1)]],
      journeyFixes: [this.scriptServicesConfig.journeyFixes, [Validators.required, Validators.min(-1)]],
      maxComparisonTags: [this.productLimits.maxComparisonTags, [Validators.required, Validators.min(0)]],
      maxDomains: [this.account.maxDomains, [Validators.required, Validators.min(1), Validators.max(this.MAX_DOMAINS)]],
      domains: this.fb.array(this.getDomainControls(this.account.domains)),
      ...(this.accountV3.retentionPolicy ? {
        retentionPolicy: [this.accountV3.retentionPolicy?.name, [Validators.required]],
      } : []),
    });

    this.validateValuesFromApi();
    this.handleValueChanges();
  }

  private handleValueChanges(): void {
    this.handleFormValueChange();
    this.handleRetentionPolicyUpdated();
  }

  private validateValuesFromApi(): void {
    this.productLimitsForm.markAllAsTouched();
    this.productLimitsForm.updateValueAndValidity();
  }

  // Set to fire on blur because this needs to validate the full value, not on each keystroke. If there are
  // 2 domains with values and a max domain beginning with '1' is attempted, i.e. '15', it's not possible to
  // type a value beginning with 1, it will automatically change the initial number to the number of domains
  // with values (2 in this example).
  handleMaxDomains(inputEvent): void {
    const value = inputEvent.target.value;

    if (!value) return;

    // Limit domains to 100
    const newMaxDomainsValue = parseInt(value) > this.MAX_DOMAINS ? this.MAX_DOMAINS : parseInt(value);
    const numDomains = this.domains.value.length;

    // new value is less than the current number of domains
    if (newMaxDomainsValue < numDomains) {
      // get domains with values
      const domains = this.domains.value.filter((domain: string) => domain);

      // clear FormArray
      this.domains.clear();

      // add back domain values
      for (let i = 0; i < domains.length; i++) {
        this.domains.push(this.createNewFormControl(domains[i], [OPValidators.url]));
      }

      // add extra fields if needed
      if (newMaxDomainsValue > this.domains.value.length) {
        const diff = newMaxDomainsValue - this.domains.value.length;
        for (let i = 0; i < diff; i++) {
          this.domains.push(this.createNewFormControl('', [OPValidators.url]));
        }
      }

      // update max domains value to be current actual number
      this.maxDomains.setValue(this.domains.value.length);

      // new value is greater than the current number of domains
    } else if (newMaxDomainsValue > numDomains) {
      // find out how many to add
      const diff = newMaxDomainsValue - numDomains;

      // add extra blank domains
      for (let i = 0; i < diff; i++) {
        this.domains.push(this.createNewFormControl('', [OPValidators.url]));
      }
    }
  }

  private handleFormValueChange(): void {
    merge(
      this.maxUsers.valueChanges,
      this.concurrentAudits.valueChanges,
      this.concurrentJourneys.valueChanges,
      this.maxMonitoredJourneys.valueChanges,
      this.journeyFixes.valueChanges,
      this.maxComparisonTags.valueChanges,
      this.maxDomains.valueChanges,
      this.domains.valueChanges,
    )
      .pipe(
        debounceTime(250),
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        if (this.productLimitsForm.valid) {
          this.updated.emit(this.productLimitsForm.value);
        }
      });

  }

  private getDomainControls(domains: string[]): UntypedFormControl[] {
    let numDomains = this.account.maxDomains;
    let domainControls = domains.map((domain: string) => {
      return this.createNewFormControl(domain, [OPValidators.url]);
    });

    for (let i = 0; i < numDomains - domains.length; i++) {
      domainControls.push(this.createNewFormControl('', [OPValidators.url]));
    }

    return domainControls;
  }

  private createNewFormControl(value: any, validators: any[]): UntypedFormControl {
    return new UntypedFormControl(value, validators);
  }

  // Display standard snackbar if retention policy is changing to a lower value.
  private shouldUpdateRetentionPolicy(prev: ERetentionPolicyName, curr: ERetentionPolicyName): boolean {
    const selectedPolicyIndex = ERetentionPolicyNameToIndex[prev];
    const newPolicyIndex = ERetentionPolicyNameToIndex[curr];
    if (newPolicyIndex < selectedPolicyIndex) {
      return true;
    }
  }

  handleRetentionPolicyUpdated(): void {
    this.retentionPolicy$.pipe(
      startWith(this.retentionPolicy?.value),
      distinctUntilChanged(),
      pairwise(),
      takeUntil(this.destroy$),
      filter(([prev, curr]) => !!prev && (prev !== curr)),
    )
      .subscribe(([prev, curr]) => this.retentionPolicyUpdated.next(curr));

    this.retentionPolicy?.valueChanges
      .pipe(
        startWith(this.retentionPolicy?.value),
        distinctUntilChanged(),
        pairwise(),
        takeUntil(this.destroy$),
        switchMap(([prev, curr]) => {
          return this.shouldUpdateRetentionPolicy(prev, curr)
            ? this.modalService.openConfirmModal({
              data: {
                title: 'Continue?',
                messages: [
                  'Changing retention policy to a shorter duration takes immediate effect.',
                  'Data deletion may start at any time and IT IS NOT RECOVERABLE, not even by Curt.',
                  'Are you sure you want to decrease data retention duration?',
                ],
                leftFooterButtons: [
                  {
                    label: 'No',
                    primary: false,
                    action: () => {
                      this.retentionPolicy.setValue(prev);
                    },
                  }
                ],
                rightFooterButtons: [
                  {
                    label: 'Yes',
                    primary: true,
                    action: () => {
                      this.retentionPolicy$.next(curr);
                    }
                  }
                ],
              }
            }).afterClosed()
              .pipe(tap(v => v === false && this.retentionPolicy.setValue(prev)))
            : of('')
              .pipe(tap(() => this.retentionPolicy$.next(curr)));
        }),
      )
      .subscribe();
  }

  get maxUsers(): AbstractControl {
    return this.productLimitsForm.get('maxUsers');
  }

  get concurrentAudits(): AbstractControl {
    return this.productLimitsForm.get('concurrentAudits');
  }

  get auditPageLimit(): AbstractControl {
    return this.productLimitsForm.get('auditPageLimit');
  }

  get concurrentJourneys(): AbstractControl {
    return this.productLimitsForm.get('concurrentJourneys');
  }

  get webJourneyFrequency(): AbstractControl {
    return this.productLimitsForm.get('webJourneyFrequency');
  }

  get webJourneyMinutes(): AbstractControl {
    return this.productLimitsForm.get('webJourneyMinutes');
  }

  get maxMonitoredJourneys(): AbstractControl {
    return this.productLimitsForm.get('maxMonitoredJourneys');
  }

  get journeyFixes(): AbstractControl {
    return this.productLimitsForm.get('journeyFixes');
  }

  get maxComparisonTags(): AbstractControl {
    return this.productLimitsForm.get('maxComparisonTags');
  }

  get vpnSupport(): AbstractControl {
    return this.productLimitsForm.get('vpnSupport');
  }

  get vpnCredentials(): AbstractControl {
    return this.productLimitsForm.get('vpnCredentials');
  }

  get maxDomains(): AbstractControl {
    return this.productLimitsForm.get('maxDomains');
  }

  get domains(): UntypedFormArray {
    return this.productLimitsForm.get('domains') as UntypedFormArray;
  }

  get retentionPolicy(): AbstractControl {
    return this.productLimitsForm?.get('retentionPolicy');
  }
}
