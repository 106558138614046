<op-modal [title]="title"
          [leftFooterButtons]="leftFooterButtons"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="close()">

  <ng-template #bodyTemplate>
    <div class="delete-modal">
      <div class="flex-col">
        <div class="delete-icon-container flex-row flex-center-content">
          <i class="icon-delete" *ngIf="allAuthorized"></i>
          <i class="icon-warning2" *ngIf="!allAuthorized"></i>
        </div>
        <div class="delete-message-container flex-col flex-center-items">
          <h5 *ngIf="allAuthorized">The {{displayItem.type | lowercase}} <strong>{{displayItem.name}}</strong>
            <span *ngIf="displayItem.createdAt">
              created {{displayItem.createdAt | date: createdDateFormat}},<br>
            </span> is about to be deleted.
          </h5>
          <div *ngIf="!allAuthorized">
            <h2>Cannot be Deleted</h2>
            <p class="delete-unable-message">
              This {{displayItem.type | lowercase}} cannot be deleted because it contains items that were created by other
              users, indicated in red. To override this action and delete this {{displayItem.type | lowercase}}, please
              see your account administrator.
            </p>
          </div>
        </div>
      </div>

      <div class="delete-content-container">
        <div>
          <div class="delete-content" *ngIf="content.length">
            <h6 *ngIf="allAuthorized" class="flex-row flex-center-content">{{deleteContentRequestText}}</h6>
            <div class="delete-content-list">
              <table class="table">
                <thead class="thead-color">
                  <tr>
                    <th>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Created</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of content" [ngClass]="{'unauthorized-row': !item.authorized}">
                    <td class="unauthorized-col"><span class="icon-warning2" *ngIf="!item.authorized"></span></td>
                    <td>{{item.type}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.createdAt| date: createdDateFormat}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="delete-no-content" *ngIf="!content.length">
            <h6>{{deleteNoContentText}}</h6>
          </div>
        </div>
      </div>
      <div *ngIf="allAuthorized" class="delete-request-container flex-col flex-center-items">
        <mat-spinner *ngIf="loadingContent" [diameter]="25" [strokeWidth]="4"></mat-spinner>
        <span *ngIf="loadingContent">Loading related items...</span>
        <span *ngIf="!loadingContent">Are you sure you want to proceed?</span>
      </div>
    </div>
    
  </ng-template>

</op-modal>
