import { IDonutChartDataPoint } from './donut-chart.models';

export const backgroundChartData: IDonutChartDataPoint[] = [
  {
    name: 'none',
    colorClass: 'chart-gray',
    value: 100
  }
];

export const totalMissingMessage = 'Please include the `totalForPercentage` property in your data object or provide more than one data point.'
export const totalMustBeLargerMessage = '`totalForPercentage` must be larger than `value`.';

export const shortFallName = 'shortfall';