import { Pipe, PipeTransform } from '@angular/core';
import { IAccessibilityRuleResult } from '@app/components/audit-reports/page-details/page-details.models';
import {
  IPagesDetailsAccessibilityFilterBarFilters
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.models';

@Pipe({
  name: 'filterRuleImpact',
})
export class FilterRuleImpactPipe implements PipeTransform {

  transform(
    value: IAccessibilityRuleResult[],
    filters: IPagesDetailsAccessibilityFilterBarFilters,
  ): IAccessibilityRuleResult[] {
    let resultingItems: IAccessibilityRuleResult[] = value;

    if (!filters || !value) {
      return resultingItems;
    }

    const severitiesFilters = filters.severity;

    if (severitiesFilters) {
      const allDisabled = Object.values(severitiesFilters).every((option) => !option.checked);

      if (allDisabled) {
        return resultingItems;
      }

      const enabledItems = Object.values(severitiesFilters)
        .filter((option) => option.checked)
        .map((option) => option.name);

      resultingItems = resultingItems.filter((item) =>
        enabledItems.some((value) => item.impact === value));
    }

    return resultingItems;
  }

}
