import * as angular from 'angular';

import { Names } from '@app/moonbeamConstants';
import { IModalInfo } from '../../moonbeamModels';
import { name as DDPC_NAME } from '../live-connect/device-profile/deleteDeviceProfileController';
import { name as DMJC_NAME } from '../live-connect/manual-journey/deleteManualJourneyController';
import { name as MJEMC_Name } from '../creator/live-connect/manual-journey/modals/export/manualJourneyExportModalController';
import { name as UPMC_NAME } from '../creator/live-connect/manual-journey/modals/userPresence/userPresenceModalController';

export interface IModalTemplate<T> extends IModalInfo {
    modalData?: T
  }
  class ModalTemplate<T> implements IModalTemplate<T> {
    modalData: T;
    title: string;
    controller: string;
    templateUrl: string;
  }
  export class ModalTemplates {
    static initTemplate<T>(template: IModalInfo): IModalTemplate<T> {
      const copy = new ModalTemplate<T>();
      ModalTemplates.copyDataModel(template, copy);
      return copy;
    }

    static copyDataModel(from: any, to: any): void {
      angular.forEach(from, function (value, key) {
        to[key] = value;
      });
    }

    // Used in ManualJourneyRecorderController in liveConnectCreateModule
    static ReprocessRules: IModalInfo = {
      title: 'Reprocess Rules',
      controller: Names.Controllers.reprocessRulesModalController,
      templateUrl: require('ngtemplate-loader!@app/components/reporting/statusBanner/reprocessRulesBanner/modal/reprocessRulesModal.html')
    };

    // Used in StopRunService in app-summary-header.component.ts
    static StopRun: IModalInfo = {
      title: 'Stop Run',
      controller: Names.Controllers.stopRunModal,
      templateUrl: require('ngtemplate-loader!@app/components/stopRun/stopRunModal.html')
    };

    // Used in ManualJourneyRecorderController and clean-up.component.ts and report-card-list.component.ts
    static Confirmation: IModalInfo = {
      title: '',
      controller: Names.Controllers.confirmation,
      templateUrl: require('ngtemplate-loader!@app/components/modals/confirmModal.html')
    };

    // Used in report-card-list.component.ts and web-journey-report-header.component.ts
    static DeleteWebJourney: IModalInfo = {
      title: 'Confirm Delete',
      controller: Names.Controllers.deleteWebJourneyController,
      templateUrl: require('ngtemplate-loader!@app/components/modals/deleteModal.html')
    };

    // Used in report-card-list.component.ts
    static DeleteDomain: IModalInfo = {
      title: 'Confirm Delete',
      controller: Names.Controllers.deleteDomainController,
      templateUrl: require('ngtemplate-loader!@app/components/modals/deleteModal.html')
    };

    // Used in report-card-list.component.ts
    static DeleteFolder: IModalInfo = {
      title: 'Confirm Delete',
      controller: Names.Controllers.deleteFolderController,
      templateUrl: require('ngtemplate-loader!@app/components/modals/deleteModal.html')
    };

    // Used in live-connect.component.ts
    static DeleteManualJourney: IModalInfo = {
      title: 'Confirm Delete',
      controller: DMJC_NAME, // Names.Controllers.deleteManualJourney,
      templateUrl: require('ngtemplate-loader!@app/components/modals/deleteModal.html')
    };

    // Used in live-connect.component.ts
    static DeleteDeviceProfile: IModalInfo = {
      title: 'Confirm Delete',
      controller: DDPC_NAME, // Names.Controllers.deleteDeviceProfile,
      templateUrl: require('ngtemplate-loader!@app/components/modals/deleteModal.html')
    };

    // Used in ExportToEmailModalService.showExportToEmailAlertModal() in ManualJourneyExportModalController
    static ExportToEmailAlert: IModalInfo = {
      title: '',
      controller: Names.Controllers.exportToEmailModal,
      templateUrl: require('ngtemplate-loader!@app/components/modals/confirmModal.html')
    };

    // Used in ManualJourneyRecorderController
    static RDCUserPresence: IModalInfo = {
      title: '',
      controller: UPMC_NAME,
      templateUrl: require('ngtemplate-loader!@app/components/modals/confirmModal.html')
    };

    // Used in ManualJourneyRecorderController
    static ManualJourneyExport: IModalInfo = {
      title: 'Export Options',
      controller: MJEMC_Name,
      templateUrl: require('ngtemplate-loader!@app/components/creator/live-connect/manual-journey/modals/export/manualJourneyExportModal.html')
    };

    // Used in manual-journey-recorder-route-container.component.ts
    static ManualJourney: IModalInfo = {
      title: 'Manual Journey',
      controller: Names.Controllers.manualJourneyRecorder,
      templateUrl: require('ngtemplate-loader!@app/components/creator/live-connect/manual-journey/manualJourneyRecorder.html')
    };
  }
