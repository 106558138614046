import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { placeholderText, showNotesKey } from './audit-notes.constants';
import { AuditNotesService } from './audit-notes.service';
import { IAuditNotes } from './audit-notes.models';
import { KeyboardShortcutsService } from '../../shared/services/keyboard-shortcuts/keyboard-shortcuts.service';
import { AccountsService } from '@app/components/account/account.service';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'audit-notes',
  templateUrl: './audit-notes.component.html',
  styleUrls: ['./audit-notes.component.scss']
})
export class AuditNotesComponent implements OnInit, OnDestroy, AfterViewInit {

  isKBShortcutRegistered = false;
  showNotes = true;
  notes: string = '';
  saveNotes$ = new Subject<string>();
  edited = false;
  saved = true;
  saveError = false;
  hasEnteredNotes = false;
  focused = false;
  userIsReadOnly: boolean;
  isVisitorMode$: Observable<boolean>;

  @Input() auditId: number;

  @ViewChild('notesBox') notesBox: ElementRef;

  constructor(
    private auditNotesService: AuditNotesService,
    private storageService: StorageService,
    private snackbarService: SnackbarService,
    private keyboardShortcutsService: KeyboardShortcutsService,
    private accountsService: AccountsService,
    private applicationChromeService: ApplicationChromeService,
  ) {
    const localStorageShowNotes: boolean = this.storageService.getValue(showNotesKey, StorageType.Local);

    localStorageShowNotes === null
      ? this.setShowNotesInLocalStorage(this.showNotes)
      : this.showNotes = localStorageShowNotes;

    this.userIsReadOnly = this.accountsService.userIsReadOnly();
    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
  }

  ngOnInit(): void {
    this.handleAuditNotes();
    this.saveNotes$.pipe(debounceTime(750)).subscribe(() => {
      this.saveAuditNotes();
    });
  }

  ngAfterViewInit(): void {
    this.registerKBShortcut();
  }

  ngOnDestroy(): void {
    this.auditNotesService.clean();
  }

  handleAuditNotes(): void {
    this.auditNotesService.getNotes(this.auditId).subscribe((res: IAuditNotes) => {
      if (res.notes === null || res.notes === '') {
        this.hasEnteredNotes = false;
        this.notes = placeholderText;
      } else {
        this.hasEnteredNotes = true;
        this.notes = res.notes;
      }
    });
  }

  saveAuditNotes(): void {
    this.auditNotesService.saveNotes(this.auditId, this.notes).subscribe(
      (res: IAuditNotes) => {
        this.saved = true;
      },
      error => {
        this.saveError = true;

        this.snackbarService.openErrorSnackbar('Error: Could not save notes. Please refresh the page and try again.');
      }
    );
  }

  onNotesUpdated(): void {
    this.saved = false;
    this.edited = true;
    this.hasEnteredNotes = !!this.notes;
    this.saveNotes$.next();
  }

  toggleNotes() {
    this.showNotes = !this.showNotes;
    this.setShowNotesInLocalStorage(this.showNotes);
    this.registerKBShortcut();
  }

  setShowNotesInLocalStorage(value: boolean): void {
    this.storageService.setValue(showNotesKey, value, StorageType.Local);
  }

  registerKBShortcut(): void {
    if (this.isKBShortcutRegistered || !this.showNotes) return;
    this.isKBShortcutRegistered = true;
    setTimeout(() => this.keyboardShortcutsService.registerNotesBox(this.notesBox.nativeElement), 0);
  }

  onFocus(): void {
    this.focused = true;
    if (!this.hasEnteredNotes) {
      this.notes = '';
    }
    this.auditNotesService.setArrowState(true);
  }

  onBlur(): void {
    this.focused = false;
    if (this.notes === null || this.notes === '') {
      this.notes = placeholderText;
    }
    this.auditNotesService.setArrowState(false);
  }
}
