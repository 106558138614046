import { Component, Input, OnChanges } from '@angular/core';

export const fileMappingResultComponentNameNg = 'fileMappingResultsNg'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'file-mapping-result',
  templateUrl: './file-mapping-result.component.html',
  styleUrls: ['./file-mapping-result.component.scss']
})
export class FileMappingResultComponent implements OnChanges {

  @Input() fileName: string;
  @Input() remoteFile: string;
  @Input() matchValue: string;
  @Input() requests: string[];
  @Input() index: number;

  statusIcon: string;
  statusText: string;
  statusClass: string;

  ngOnChanges() {
    this.init();
  }

  private init() {
    this.setStatus();
  }

  private setStatus() {
    if (!this.remoteFile) {
      this.statusIcon = 'insert_drive_file';
      this.statusText = 'No file selected';
      this.statusClass = 'no-config';
    } else if (!this.requests || this.requests.length < 1) {
      this.statusIcon = 'not_interested';
      this.statusText = 'No network request matched for:';
      this.statusClass = 'none-found';
    } else {
      this.statusIcon = 'check';
      this.statusText = 'Network request matched for:';
      this.statusClass = 'request-found';
    }
  }

  goToLink(remoteFile: string) {
    window.open(remoteFile, '_blank');
  }
}