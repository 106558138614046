import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isDateValid } from '../../date/date.service';
import { DeepLinkValidator } from '@app/components/form/validateUtils/listValidators';
import Timeout = NodeJS.Timeout;
import { from, Observable } from 'rxjs';

export interface ICheckDuplicatesValidator {
  checkDuplicates(str: string): Promise<boolean>;
}

export interface ICheckDuplicatesValueCombinationsValidator {
  checkCombination(obj: any): Promise<boolean>;
}

export class OPValidators {

  // the following email regex allows for bad emails such as a@b
  // private static emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

  // this email regex comes from http://emailregex.com/ and is the same one used in the API
  // a case insensitive flag was added to this regex
  private static emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

  /**
   * `urlRegex` is to be kept up to date with the BE which is using the same regex string which can be found here:
   * https://github.com/observepoint/api/blob/master/api/src/main/scala/observepoint/api/common/helpers/Validators.scala
   *
   * this ensures that customers don't run into an issue of on side rejecting a valid
   * url while the other side accepts it.
   */
  private static urlRegex = /^https?:\/\/[^\s?#$&\[\]{}\\|'\"<>,=+`~*%][^/?#]*(:\d+)?[/?#]?.*/i;
  private static urlRegexNoProtocol =  /^[^\s?#$&\[\]{}\\|'\"<>,=+`~*%][^\/?#]*(:\d+)?[\/?#]?.*/i;
  private static protocolRegex = /^(http|https)$/i;

  private static urlWithOptionalProtocolRegex = /^(?:(?:(?:https?):)\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[\/?#]\S*)?$/i;
  private static urlWithOptionalProtocolAndOptionalLeadingPeriodRegex = /^(?:(?:(?:https?):)\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[.a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[\/?#]\S*)?$/i;
  private static regexCharactersRegex = /[{}^\\|\[\]]/i;
  private static timeRegex = /^\d{1,2}:\d{2}\s*([A|P]M)?\s*([+|-]\d{2}:?\d{2})?\s*$/i; // Ex. 11:20 PM +0700

  static url(control: AbstractControl): ValidationErrors | null {
    return (control.value && OPValidators.isInvalidUrl(control.value)) ?
      OPValidators.generateInvalidUrlError(control.value) : null;
  }

  static startingUrls(control: AbstractControl): ValidationErrors | null {
    const urls: any[] = control.value.split('\n').map(url => url.trim()).filter(url => url !== '');
    let isValid: boolean = null;

    // Using a for loop so we can break out of it early if we find an invalid url. This is
    // more efficient than using `.forEach()` which would continue to iterate over the rest
    // of the urls.
    for (let i = 0; i < urls.length; i++) {
      let [protocol, remainder] = urls[i].split(/:\/\/(.*)/s, 2);

      isValid = protocol && remainder
        // protocol exists
        ? !OPValidators.isInvalidProtocol(protocol) && !OPValidators.isInvalidStartingUrl(remainder)
        // no protocol so remainder is undefined -- use protocol variable as remainder value
        : !OPValidators.isInvalidStartingUrl(protocol);

      if (!isValid) {
        return OPValidators.generateInvalidUrlWithOptionalProtocolError(urls[i]);
      }
    }

    return null;
  }

  static urlWithOptionalProtocol(control: AbstractControl, valueKey?: string): ValidationErrors | null {
    const valueToTest = valueKey ? control?.value ? control.value[valueKey] : '' : control.value;
    return (valueToTest && OPValidators.isInvalidUrlWithOptionalProtocol(valueToTest)) ?
      OPValidators.generateInvalidUrlWithOptionalProtocolError(valueToTest) : null;
  }

  static urlWithOptionalProtocolAndOptionalLeadingPeriod(control: AbstractControl): ValidationErrors | null {
    return (control.value && OPValidators.isInvalidUrlWithOptionalProtocolAndOptionalLeadingPeriod(control.value)) ?
      OPValidators.generateInvalidUrlWithOptionalProtocolError(control.value) : null;
  }

  static urls(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const urls = control.value.split('\n').map(url => url.trim()).filter(url => url !== '');
    const invalidUrl = urls.find(OPValidators.isInvalidUrl);
    return invalidUrl ? OPValidators.generateInvalidUrlsError(invalidUrl) : null;
  }

  static integer(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    return Number.isInteger(+control.value) ?
      null :
      { numberInteger: { value: control.value } };
  }

  static number(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    return Number.isNaN(Number(control.value)) ?
      { number: { value: control.value } } :
      null;
  }

  static emails(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    const emails = control.value.split('\n').map(email => email.trim()).filter(email => email !== '');
    const invalidEmail = emails.find(OPValidators.isInvalidEmail);
    return invalidEmail ? OPValidators.generateInvalidEmailError(invalidEmail) : null;
  }

  static regex(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    try {
      new RegExp(control.value);
    } catch (e) {
      return {validRegexes: {value: control.value}};
    }
    return null;
  }

  static regexSnowflake(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;

    try {
      new RegExp(control.value);
      const regValue: string = control.value;
      // Check for Regex features not supported in Snowflake
      // see https://community.snowflake.com/s/question/0D50Z00007ENLKsSAP/expanded-support-for-regular-expressions-regex
      if (
        regValue.includes('?:') || //non-capturing groups: (?: … )
        regValue.includes('?=') || regValue.includes('?!') || //lookahead (positive and negative): (?= … ), (?! … )
        regValue.includes('?<=') || regValue.includes('?<!') //lookbehind (positive and negative): (?<= … ), (?<! … )
      ) {
        return {
          validRegexesSnowflake: {
            value: control.value,
            errorMessage: 'Non-capturing groups (?:), lookahead (?=), (?!), and lookbehind (?<=), (?<!) features are not supported by regular expressions in Consent Preferences'
          }
        };
      }
    } catch (e) {
      return {validRegexes: {value: control.value}};
    }
    return null;
  }

  static regexes(control: AbstractControl): ValidationErrors | null {
    const lines = control.value.split('\n');
    for (let i = 0; i < lines.length; i++) {
      try {
        new RegExp(lines[i]);
      } catch (e) {
        return {validRegexes: {value: lines[i]}};
      }
    }
    return null;
  }

  static passwordsMatch(control: AbstractControl): ValidationErrors | null {
    if (!control.get('newPassword').value || !control.get('newPasswordConfirm').value) return null;

    let password = control.get('newPassword').value;
    let confirmPassword = control.get('newPasswordConfirm').value;

    return password === confirmPassword ? null : { noMatch: true };
  }

  static confirmDelete(keyword: string = 'delete'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;
      let text = control.value.trim().toLowerCase();
      return text === keyword ?
        null :
        { confirmDelete: { value: `input must contain ${keyword}`} };
    };
  }

  static isInvalidUrl(url: string): boolean {
    return !OPValidators.urlRegex.test(url);
  }

  static isInvalidProtocol(protocol: string): boolean {
    return !OPValidators.protocolRegex.test(protocol);
  }

  static isInvalidStartingUrl(url: string): boolean {
    return !OPValidators.urlRegexNoProtocol.test(url);
  }

  private static isInvalidUrlWithOptionalProtocol(url: string): boolean {
    return !OPValidators.urlWithOptionalProtocolRegex.test(url) || OPValidators.regexCharactersRegex.test(url);
  }

  private static isInvalidUrlWithOptionalProtocolAndOptionalLeadingPeriod(url: string): boolean {
    return !OPValidators.urlWithOptionalProtocolAndOptionalLeadingPeriodRegex.test(url) || OPValidators.regexCharactersRegex.test(url);
  }

  public static isInvalidEmail(email: string): boolean {
    return !OPValidators.emailRegex.test(email);
  }

  private static generateInvalidUrlError(invalidUrl: string): ValidationErrors {
    return {
      url: {
        value: invalidUrl
      }
    };
  }

  private static generateInvalidUrlWithOptionalProtocolError(invalidUrl: string): ValidationErrors {
    return {
      urlWithOptionalProtocol: {
        value: invalidUrl
      }
    };
  }

  private static generateInvalidUrlWithOptionalProtocolAndOptionalLeadingPeriodError(invalidUrl: string): ValidationErrors {
    return {
      urlWithOptionalProtocolAndOptionalLeadingPeriod: {
        value: invalidUrl
      }
    };
  }

  private static generateInvalidUrlsError(invalidUrl: string): ValidationErrors {
    return {
      urlsListValidator: {
        value: invalidUrl
      }
    };
  }

  private static generateInvalidEmailError(invalidEmail: string): ValidationErrors {
    return {
      emailsListValidator: {
        value: invalidEmail
      }
    };
  }

  static validDate(control: AbstractControl): ValidationErrors | null {
    const result = new Date(control.value);
    return isDateValid(result) ? null :{
      validDate: {
        value: control.value,
        message: 'Couldn\'t parse the date'
      }
    };
  }

  // Expects a time value in the form: '13:10'. If not in this form, it will return an unable to parse error.
  static validTime(control: AbstractControl): ValidationErrors | null {
    let date = new Date();
    let parsedTime = control.value.split(':');

    if (parsedTime.length === 2) {
      date.setHours(parsedTime[0]);
      date.setMinutes(parsedTime[1]);
    }

    if (OPValidators.timeRegex.test(control.value) && isDateValid(date)) { return null; }

    return {
      validTime: {
        value: control.value,
        message: 'Couldn\'t parse the time'
      }
    };
  }

  static validDeepLink(control: AbstractControl): ValidationErrors | null {
    if (!DeepLinkValidator.validate(control.value)) {
      return {
        deepLink: {
          value: control.value,
          message: 'Invalid deep link value'
        }
      };
    }

    return null;
  }

  static maskedInput(control: AbstractControl): ValidationErrors | null {
    if (!control.value.maskedValue) {
      return {
        maskedValue: {
          value: control.value.maskedValue,
          message: 'This field is required'
        }
      };
    }

    return null;
  }

  static checkDuplicatesAsync(service: ICheckDuplicatesValidator, exceptions: string[] = [], debounceTime = 300): AsyncValidatorFn {
    let timeout: number = null;
    return (control: AbstractControl): Observable<{duplicate: boolean} | null> => {
      return from(new Promise<{duplicate: boolean} | null>(res => {
        if (exceptions.includes(control.value) || (control.parent && control.parent.untouched)) {
          return res(null);
        } else {
          clearTimeout(timeout);

          timeout = window.setTimeout(() => {
            service.checkDuplicates(control.value)
              .then(_ => res(null))
              .catch(_ => res({duplicate: true}));
          }, debounceTime);
        }
      }));
    };
  }

  static checkDuplicatesValueCombinationsAsync(service: ICheckDuplicatesValueCombinationsValidator, fields: string[], debounceTime = 300): AsyncValidatorFn {
    let timeout: number = null;

    return (formGroup: FormGroup) => {
      return new Promise<{duplicatedCombination: boolean} | null>(res => {

        clearTimeout(timeout);
        const combination = {};
        fields.forEach(field => combination[field] = formGroup.get(field));

        timeout = window.setTimeout(() => {
          service.checkCombination(combination)
            .then(_ => res(null))
            .catch(_ => res({duplicatedCombination: true}));
        }, debounceTime);

      });
    };
  }

  static isUrl(control: AbstractControl): ValidationErrors | null {
    if (!control.value) return null;
    if (OPValidators.isInvalidUrl(control.value)) return {validRegexes: {value: control.value}};
    return null;
  }

  static timeZoneValidator(validTimezones: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && !validTimezones.includes(value)) {
        return { invalidTimeZone: true };
      }
      return null;
    };
  }

  static strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d+\-.,!@#$%^&*();\\/|<>"']).{8,}$/;

  static strongPassword(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null; // Don't validate empty values to allow required validator to handle it
      }

      return OPValidators.strongPasswordRegex.test(value) ? null : { strongPassword: true };
    };
  }
}
