import { Component, Input } from '@angular/core';
import { IButton } from '@app/models/commons';

@Component({
  selector: 'op-modal-footer-buttons',
  templateUrl: './op-modal-footer-buttons.component.html',
  styleUrls: ['./op-modal-footer-buttons.component.scss']
})
export class OpModalFooterButtonsComponent {

  @Input() buttons?: IButton[];

}
