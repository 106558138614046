<div class="drag-list-container" [dragula]="dragulaName" [(dragulaModel)]="data">
  <div class="drag-list-row" *ngFor="let item of data; let i = index">
    <div class="drag-list-index">
      <mat-icon *ngIf="draggable" class="handle" (mousedown)="select(i)">drag_indicator</mat-icon>
      <span class="number">{{i + 1}}.</span>
    </div>
    <ng-container *ngTemplateOutlet="rowTemplate; context: {item: item, index: i}"></ng-container>
    <div class="drag-list-row-border" *ngIf="i === selectedIndex"></div>
  </div>
</div>
