import { FormControl } from '@angular/forms';
import { IDomain } from '@app/components/domains/domainsService';
import { IFolder } from '@app/components/folder/foldersApiService';
import { EDataSourceType } from '@app/components/shared/services/data-sources/data-sources.constants';

export interface IFolderAndSubFolderForm {
  folder: FormControl<string>;
  subFolder: FormControl<string>;
  dataLayer: FormControl<string>;
}

export interface ICreateFolderAndSubFolderModalData {
  folders: IFolder[];
  subFolders: IDomain[];
  subFoldersByFolderId: { [key: number]: IDomain[] };
  currentFolderId: number;
  currentSubFolderId: number;
  useDefaultDomain: boolean;
  mode: EFolderMode;
  itemType: EDataSourceType
}

export interface ISubFoldersByFolderId {
  [key: number]: IDomain[]
}

export enum EFolderMode {
  Create,
  Select,
}

export enum ESubFolderMode {
  Create,
  Select,
}
