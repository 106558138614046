import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { MenuItems } from '@app/components/shared/components/op-button-2021/op-button-2021.component';
import { MatTableDataSource } from '@angular/material/table';
import { SharedLinksLibraryService } from '@app/components/shared-links/shared-links-library.service';
import { debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  SharedLinksLibraryFilterBarService
} from '@app/components/shared-links/shared-links-library-filter-bar.service';
import { Subject } from 'rxjs';
import {
  IPaginationMetaData,
  ISharedLink,
  ISharedLinksFilters,
  ISort
} from '@app/components/shared-links/shared-links-library.models';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  ESharedLibraryColumns,
  ESharedLinksFilterTypes
} from '@app/components/shared-links/shared-links-library.constants';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { IOpFilterBarMenuItem } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import { EFilterBarMenuTypes } from '@app/components/shared/components/op-filter-bar/op-filter-bar.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'shared-links-library',
  templateUrl: './shared-links-library.component.html',
  styleUrls: ['./shared-links-library.component.scss']
})
export class SharedLinksLibraryComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild(MatPaginator) matPaginator: MatPaginator;

  private destroySubject = new Subject<void>();

  private apiFilters: ISharedLinksFilters = {};
  private showNonExpiredOnly: boolean;
  private showDisabledOnly: boolean;

  infoIsExpanded: boolean;
  isFiltersApplied = false;

  actionsMenu: MenuItems[] = [
    {
      label: 'Disable Links',
      action: () => {
        this.loading = true;
        this.sharedLinksLibraryService.updateSharedLinksStatus(this.selected, false).subscribe(() => {
          this.updateTableState();
        });
      }
    },
    {
      label: 'Enable Links',
      action: () => {
        this.loading = true;
        this.sharedLinksLibraryService.updateSharedLinksStatus(this.selected, true).subscribe(() => {
          this.updateTableState();
        });
      }
    }
  ];

  dataSource = new MatTableDataSource<ISharedLink>();
  loading = true;
  isSharingEnabled: boolean;
  selected: ISharedLink[] = [];
  displayedColumns: string[] = [
    'select',
    ESharedLibraryColumns.DatasourceName,
    ESharedLibraryColumns.ReportName,
    ESharedLibraryColumns.FolderName,
    ESharedLibraryColumns.CreatedBy,
    ESharedLibraryColumns.SharedTo,
    ESharedLibraryColumns.Views,
    ESharedLibraryColumns.Expiration,
    ESharedLibraryColumns.Status,
    ESharedLibraryColumns.ShareLink
  ];

  pagination: IPaginationMetaData = {
    pageSize: 100,
    currentPageNumber: 0,
  };
  sorting: ISort = {
    sortBy: ESharedLibraryColumns.Expiration,
    sortDesc: true,
    sortDir: 'asc'
  };

  statusFilterItem = {
    name: 'Show enabled links only',
    type: EFilterBarMenuTypes.Button,
    action: () => this.filterBarService.handleDisabledOnlyFilter(this.showDisabledOnly = !this.showDisabledOnly),
  };

  nonExpiredOnlyFilterItem = {
    name: 'Show non-expired links only',
    type: EFilterBarMenuTypes.Button,
    action: () => this.filterBarService.handleNonExpiredOnlyFilter(this.showNonExpiredOnly = !this.showNonExpiredOnly),
  };

  filterBarMenuItems: IOpFilterBarMenuItem[] = [
    {
      name: 'Report',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'Search by Name',
          type: EFilterBarMenuTypes.Search,
          searchPlaceholder: 'Search by report name',
          action: (event: KeyboardEvent) => {
            if (event.code === 'Enter') {
              this.filterBarService.handleReportNameContainsFilter((event.target as HTMLInputElement)?.value.trim().toLowerCase() || '');
            }
          },
        },
      ]
    },
    {
      name: 'Folder',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        {
          name: 'Search by Name',
          type: EFilterBarMenuTypes.Search,
          searchPlaceholder: 'Search by folder name',
          action: (event: KeyboardEvent) => {
            if (event.code === 'Enter') {
              this.filterBarService.handleFolderNameContainsFilter((event.target as HTMLInputElement)?.value.trim().toLowerCase() || '');
            }
          },
        },
      ]
    },
    {
      name: 'Status',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        this.statusFilterItem
      ]
    },
    {
      name: 'Non-Expired Only',
      type: EFilterBarMenuTypes.Flyout,
      children: [
        this.nonExpiredOnlyFilterItem
      ]
    },
    {
      name: 'Divider',
      type: EFilterBarMenuTypes.Divider
    },
    {
      name: 'clear all filters',
      type: EFilterBarMenuTypes.ClearBtn,
      action: () => this.filterBarService.clear()
    }
  ];

  constructor(private sharedLinksLibraryService: SharedLinksLibraryService,
              public filterBarService: SharedLinksLibraryFilterBarService) {
  }

  select($event: MatCheckboxChange, row) {
    if ($event.checked) {
      this.selected.push(row);
    } else {
      this.selected = this.selected.filter((item) => item.id !== row.id);
    }
  }

  ngOnInit() {

    this.sharedLinksLibraryService.getSharedLinksFlag().pipe(
      tap(data => this.isSharingEnabled = data.isSharingEnabled),
      switchMap((data) => this.filterBarService.filters$),
      debounceTime(100),
      takeUntil(this.destroySubject),
    ).subscribe(filters => {
      this.pagination.currentPageNumber = 0;
      this.isFiltersApplied = false;
      this.showNonExpiredOnly = false;
      this.showDisabledOnly = false;
      this.apiFilters = filters.reduce((filters, filter) => {

        if (filter.type === ESharedLinksFilterTypes.Disabled) {
          this.showDisabledOnly = !filter.value;
        }

        if (filter.type === ESharedLinksFilterTypes.NonExpiredOnly) {
          this.showNonExpiredOnly = !filter.value;
        }

        filters[filter.type] = filter.value;
        this.isFiltersApplied = true;
        return filters;
      }, {});

      if (!this.showDisabledOnly) {
        this.statusFilterItem.name = 'Show enabled links only';
      } else {
        this.statusFilterItem.name = 'Show disabled links only';
      }

      if (this.showNonExpiredOnly) {
        this.nonExpiredOnlyFilterItem.name = 'Show expired links only';
      } else {
        this.nonExpiredOnlyFilterItem.name = 'Show non-expired links only';
      }
      this.updateTableState();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.matSort;

    this.matSort?.sortChange.subscribe(sort => {
      this.sorting.sortBy = sort.active as ESharedLibraryColumns;
      this.sorting.sortDesc = sort.direction === 'desc';
      this.sorting.sortDir = sort.direction;
      this.pagination.currentPageNumber = 0;
      this.updateTableState();
    });

    this.matPaginator?.page.subscribe(pagination => {
      this.pagination.currentPageNumber = pagination.pageIndex;
      this.updateTableState();
    });
  }

  selectAll($event: MatCheckboxChange) {
    if ($event.checked) {
      this.selected = this.dataSource.data.filter((item) => !item.expired);
    } else {
      this.selected = [];
    }
  }

  updateTableState() {
    this.loading = true;
    this.sharedLinksLibraryService.getAll(this.sorting, this.pagination, this.apiFilters).subscribe((data) => {
      this.dataSource = new MatTableDataSource(data.sharedLinks);
      this.dataSource.sort = this.matSort;
      this.dataSource.sort.active = this.sorting.sortBy;
      this.pagination = data.metadata.pagination;
      this.selected = [];
      this.loading = false;
    });
  }

  updateFeatureFlag() {
    this.sharedLinksLibraryService.setSharedLinksFlag(!this.isSharingEnabled)
      .subscribe((data) => {
        this.isSharingEnabled = data.isSharingEnabled;
      });
  }
}
