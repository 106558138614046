<use-case-report-section>
  <use-case-widget [topText]="pagesScannedWidget.topText"
                   [opSelector]="pagesScannedWidget.opSelector"
                   [topValue]="pagesScannedWidget.topValue"
                   [topTooltip]="pagesScannedWidget.topTooltip"
                   [topClickAction]="pagesScannedWidget.topClickAction"
                   [splitCard]="false"
                   [loading]="pagesScannedWidget.loading"
  >
    <use-case-widget-body right [widget]="pagesScannedWidget"
    ></use-case-widget-body>
  </use-case-widget>
</use-case-report-section>

<use-case-report-section [sectionIcon]="analyticsTagsSection.sectionIcon"
                         [sectionTitle]="analyticsTagsSection.sectionTitle"
>
  <use-case-widget [topText]="uniqueAnalyticsTagsWidget.topText"
                   [opSelector]="uniqueAnalyticsTagsWidget.opSelector"
                   [topValue]="uniqueAnalyticsTagsWidget.topValue"
                   [topTooltip]="uniqueAnalyticsTagsWidget.topTooltip"
                   [topClickAction]="uniqueAnalyticsTagsWidget.topClickAction"
                   [bottomLeftCustomContent]="true"
                   [statsData]="uniqueAnalyticsTagsWidget.statsData"
                   [loading]="uniqueAnalyticsTagsWidget.loading"
  >
    <use-case-widget-body right [widget]="uniqueAnalyticsTagsWidget"
    >
      <div class="horizontal-stacked-bar-chart-wrapper">
        <horizontal-stacked-bar-chart [data]="uniqueAnalyticsTagsWidget.chartData"
                                      [roundedCorners]="true"
                                      [hideTooltip]="true"
                                      [uniqueIdentifier]="'unique-analytics-chart'"
                                      [chartHeight]="20"
        ></horizontal-stacked-bar-chart>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="uniqueAnalyticsTagsWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topText]="topAnalyticsWidget.topText"
                   [opSelector]="topAnalyticsWidget.opSelector"
                   [topTooltip]="topAnalyticsWidget.topTooltip"
                   [topIconName]="topAnalyticsWidget.topIconName"
                   [topIconMeaning]="topAnalyticsWidget.topIconMeaning"
                   [topIconPath]="topAnalyticsWidget.topIconPath"
                   [topValue]="topAnalyticsWidget.topValue"
                   [topValueMeaning]="topAnalyticsWidget.topValueMeaning"
                   [topClickAction]="topAnalyticsWidget.topClickAction"
                   [bottomText]="topAnalyticsWidget.bottomText"
                   [bottomValue]="topAnalyticsWidget.bottomValue"
                   [loading]="topAnalyticsWidget.loading"
  >
    <use-case-widget-body right [widget]="topAnalyticsWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="topTagManagerWidget.topText"
                   [opSelector]="topTagManagerWidget.opSelector"
                   [topTooltip]="topTagManagerWidget.topTooltip"
                   [topIconName]="topTagManagerWidget.topIconName"
                   [topIconMeaning]="topTagManagerWidget.topIconMeaning"
                   [topIconPath]="topTagManagerWidget.topIconPath"
                   [topValue]="topTagManagerWidget.topValue"
                   [topValueMeaning]="topTagManagerWidget.topValueMeaning"
                   [topClickAction]="topTagManagerWidget.topClickAction"
                   [bottomText]="topTagManagerWidget.bottomText"
                   [bottomValue]="topTagManagerWidget.bottomValue"
                   [loading]="topTagManagerWidget.loading"
  >
    <use-case-widget-body right [widget]="topTagManagerWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="tagRequestsWidget.topText"
                   [opSelector]="tagRequestsWidget.opSelector"
                   [topTooltip]="tagRequestsWidget.topTooltip"
                   [topValue]="tagRequestsWidget.topValue"
                   [topClickAction]="tagRequestsWidget.topClickAction"
                   [statsData]="tagRequestsWidget.statsData"
                   [bottomLeftCustomContent]="true"
                   [loading]="tagRequestsWidget.loading"
  >
    <use-case-widget-body right [widget]="tagRequestsWidget"
    >
      <div class="donut-chart-wrapper">
        <donut-chart-with-key [data]="tagRequestsWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'tag-requests-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="tagRequestsWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topText]="brokenTagRequestsWidget.topText"
                   [opSelector]="brokenTagRequestsWidget.opSelector"
                   [topTooltip]="brokenTagRequestsWidget.topTooltip"
                   [topValue]="brokenTagRequestsWidget.topValue"
                   [topValueMeaning]="brokenTagRequestsWidget.topValueMeaning"
                   [topIconName]="brokenTagRequestsWidget.topIconName"
                   [topIconMeaning]="brokenTagRequestsWidget.topIconMeaning"
                   [topClickAction]="brokenTagRequestsWidget.topClickAction"
                   [bottomText]="brokenTagRequestsWidget.bottomText"
                   [bottomValue]="brokenTagRequestsWidget.bottomValue"
                   [loading]="brokenTagRequestsWidget.loading"
  >
    <use-case-widget-body right [widget]="brokenTagRequestsWidget"
    >
      <div class="horizontal-bar-chart-rounded-wrapper" *ngIf="brokenTagRequestsWidget.chartData.length > 0">
        <horizontal-bar-chart-rounded [data]="brokenTagRequestsWidget.chartData"
                                      [hideTooltip]="true"
                                      [uniqueIdentifier]="'broken-tag-chart'"
        ></horizontal-bar-chart-rounded>
      </div>
    </use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="duplicateTagRequestsWidget.topText"
                   [opSelector]="duplicateTagRequestsWidget.opSelector"
                   [topTooltip]="duplicateTagRequestsWidget.topTooltip"
                   [topValue]="duplicateTagRequestsWidget.topValue"
                   [statsData]="duplicateTagRequestsWidget.statsData"
                   [topValueMeaning]="duplicateTagRequestsWidget.topValueMeaning"
                   [topIconName]="duplicateTagRequestsWidget.topIconName"
                   [topIconMeaning]="duplicateTagRequestsWidget.topIconMeaning"
                   [topClickAction]="duplicateTagRequestsWidget.topClickAction"
                   [bottomLeftCustomContent]="true"
                   [loading]="duplicateTagRequestsWidget.loading"
  >
    <use-case-widget-body right [widget]="duplicateTagRequestsWidget"
    >
      <div class="donut-chart-wrapper">
        <donut-chart-with-key [data]="duplicateTagRequestsWidget.chartData"
                              [displayPills]="true"
                              [donutThickness]="21"
                              [uniqueIdentifier]="'duplicate-requests-chart'"
        ></donut-chart-with-key>
      </div>
    </use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="duplicateTagRequestsWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topText]="tagAndVariableRuleFailuresWidget.topText"
                   [opSelector]="tagAndVariableRuleFailuresWidget.opSelector"
                   [topTooltip]="tagAndVariableRuleFailuresWidget.topTooltip"
                   [topIconName]="tagAndVariableRuleFailuresWidget.topIconName"
                   [topIconMeaning]="tagAndVariableRuleFailuresWidget.topIconMeaning"
                   [topValue]="tagAndVariableRuleFailuresWidget.topValue"
                   [topValueMeaning]="tagAndVariableRuleFailuresWidget.topValueMeaning"
                   [topClickAction]="tagAndVariableRuleFailuresWidget.topClickAction"
                   [bottomText]="tagAndVariableRuleFailuresWidget.bottomText"
                   [bottomValue]="tagAndVariableRuleFailuresWidget.bottomValue"
                   [loading]="tagAndVariableRuleFailuresWidget.loading"
  >
    <use-case-widget-body right [widget]="tagAndVariableRuleFailuresWidget"
    ></use-case-widget-body>
  </use-case-widget>
  <use-case-widget [topText]="uniqueTagVariablesWidget.topText"
                   [opSelector]="uniqueTagVariablesWidget.opSelector"
                   [topTooltip]="uniqueTagVariablesWidget.topTooltip"
                   [topValue]="uniqueTagVariablesWidget.topValue"
                   [topClickAction]="uniqueTagVariablesWidget.topClickAction"
                   [statsData]="uniqueTagVariablesWidget.statsData"
                   [bottomLeftCustomContent]="true"
                   [loading]="uniqueTagVariablesWidget.loading"
  >
    <use-case-widget-body right [widget]="uniqueTagVariablesWidget"
    ></use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="uniqueTagVariablesWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topText]="uniqueTagVariableValuesWidget.topText"
                   [opSelector]="uniqueTagVariableValuesWidget.opSelector"
                   [topTooltip]="uniqueTagVariableValuesWidget.topTooltip"
                   [topValue]="uniqueTagVariableValuesWidget.topValue"
                   [topClickAction]="uniqueTagVariableValuesWidget.topClickAction"
                   [statsData]="uniqueTagVariableValuesWidget.statsData"
                   [bottomLeftCustomContent]="true"
                   [loading]="uniqueTagVariableValuesWidget.loading"
  >
    <use-case-widget-body right [widget]="uniqueTagVariableValuesWidget"
    ></use-case-widget-body>
    <div bottomLeft class="widget-body-custom-bottom-left"
         [innerHTML]="uniqueTagVariableValuesWidget.bottomLeftCustomContent">
    </div>
  </use-case-widget>
  <use-case-widget [topLeftCustomContent]="true"
                   [opSelector]="dataLayerCoverageWidget.opSelector"
                   [topTooltip]="dataLayerCoverageWidget.topTooltip"
                   [bottomText]="dataLayerCoverageWidget.bottomText"
                   [bottomValue]="dataLayerCoverageWidget.bottomValue"
                   [topClickAction]="dataLayerCoverageWidget.topClickAction"
                   [loading]="dataLayerCoverageWidget.loading"
                   [bannerText]="dataLayerCoverageWidget.bannerText"
                   [bannerColor]="dataLayerCoverageWidget.bannerColor"
                   [bannerClickAction]="dataLayerCoverageWidget.bannerClickAction"
  >
    <div topLeft class="additional-insights-top">
      <h2 class="top-heading">{{dataLayerCoverageWidget.topText}}</h2>
      <div class="top-custom-text-value" [innerHTML]="dataLayerCoverageWidget.topValue"></div>
    </div>
    <use-case-widget-body right [widget]="dataLayerCoverageWidget"
    ></use-case-widget-body>
  </use-case-widget>
</use-case-report-section>
