<op-modal [leftFooterButtons]="[]"
          [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Add New Request Domain & Geolocation"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="add-new-request-geo">
      <form [formGroup]="newRequestDomainForm" class="new-request-geo-form">
        <div class="new-request-geo-form-wrap">
          <mat-form-field floatLabel="always" appearance="outline" class="domain-input request-geo-input">
            <mat-label>Request Domain</mat-label>
            <input matInput formControlName="domain" autocomplete="off" placeholder="Enter domain (or leave empty for any domain)">
            <mat-error *ngIf="domain.errors?.urlWithOptionalProtocol">You must enter a valid domain</mat-error>
            <mat-error *ngIf="domain.errors?.validRegexes"
                        class="validation-error"
                        matTooltip="You must enter a valid regular expression">
              You must enter a valid regular expression
            </mat-error>
            <mat-error *ngIf="domain.errors?.validRegexesSnowflake"
                        class="validation-error"
                        matTooltip="{{domain.errors?.validRegexesSnowflake?.errorMessage}}">
              {{ domain.errors?.validRegexesSnowflake?.errorMessage }}
            </mat-error>
          </mat-form-field>
          <cc-geo-selector class="request-geo-input"
                           [autoCompleteList]="autoCompleteList"
                           [selectedCountries]="[]"
                           (selectionChanged)="updateLocationFormControl($event)">
          </cc-geo-selector>
        </div>
        <mat-checkbox (change)="onChangeMatchRegexCheckbox($event)" formControlName="regex" color="primary">Match RegEx</mat-checkbox>
      </form>
    </div>
  </ng-template>
</op-modal>
