import { Component, OnInit, ViewChild } from '@angular/core';
import { OpModalService } from '../shared/components/op-modal';
import { RfmEditorComponent } from './rfm-editor/rfm-editor.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { RemoteFileMapService } from '../creator/services/remote-file-map.service';
import {
  IRFMConfigV3,
  IRFMConfigV3CreationRequest
} from '../creator/shared/remoteFileMapping/remote-file-mapping.component';
import { forkJoin, of } from 'rxjs';
import { IUser } from '@app/moonbeamModels';
import {
  OpDeleteItemWarningComponent
} from '../shared/components/op-delete-item-warning/op-delete-item-warning.component';
import { userIsAdmin } from '@app/authUtils';
import { AccountsService } from '@app/components/account/account.service';
import { formatDefs } from '@app/components/date/date.service';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';

export interface IRFMConfigV3TableData extends IRFMConfigV3 {
  matchTypeFormatted: string;
  fileLocation: string;
  createdByName: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rfm-library',
  templateUrl: './rfm-library.component.html',
  styleUrls: ['./rfm-library.component.scss']
})
export class RfmLibraryComponent implements OnInit {
  loading: boolean = true;
  displayedColumns: string[] = ['name', 'matchTypeFormatted', 'fileLocation', 'createdByName', 'createdAt', 'updatedAt', 'editDelete'];
  dataSource = new MatTableDataSource;
  rfmConfigCount: number = 0;
  currentUser: IUser;
  users: IUser[];
  userIsAdmin: boolean;
  headerDateFormat: string = formatDefs.dateTwentyThree;

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private rfmService: RemoteFileMapService,
    private opModalService: OpModalService,
    private accountsService: AccountsService,
    private snackbarService: SnackbarService
  ) {
  }

  ngOnInit() {
    this.initData();
  }

  initData() {
    forkJoin([
      this.rfmService.getRfmConfigs(),
      this.accountsService.getUser(),
      this.accountsService.getUsers()
    ]).subscribe(([rmfConfigs, currentUser, users]) => {
      this.currentUser = currentUser;
      this.userIsAdmin = userIsAdmin(this.currentUser);
      this.users = users;
      this.dataSource.data = rmfConfigs.map((config: IRFMConfigV3) => {
        return {
          ...config,
          name: config.name ? config.name : 'Unknown',
          matchTypeFormatted: config.matchType === 'equals' ? 'URL' : 'RegEx',
          fileLocation: config.fileId ? 'Uploaded File' : 'URL',
          createdByName: this.getUserName(config.createdBy)
        } as IRFMConfigV3TableData;
      });

      this.dataSource.data.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

      this.dataSource.filterPredicate = (data, filter) => {
        let nameCol = data['name'].trim().toLowerCase();
        let filterVal = filter.trim().toLowerCase();
        return nameCol.includes(filterVal);
      };

      this.dataSource.sort = this.sort;
      this.updateRfmConfigCount();
      this.loading = false;
    });
  }

  filterRfmConfigs(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.updateRfmConfigCount(true);
  }

  getUserName(id: number): string {
    let user = this.users.find(user => user.id === id);

    if (user && user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}` || user.username || 'Unknown';
    }

    return 'Migrated';
  }

  createNewConfig() {
    this.opModalService.openModal(RfmEditorComponent, {data: null})
      .afterClosed()
      .subscribe((configRequest: IRFMConfigV3CreationRequest) => {
        if (configRequest) {
          this.rfmService.createRfmConfig(configRequest).subscribe(
            (config: IRFMConfigV3) => {

              const newRow = {
                ...config,
                createdByName: this.getUserName(config.createdBy)
              } as IRFMConfigV3TableData;

              this.dataSource.data.push(newRow);
              this.dataSource.data.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
              // forces table to update
              this.dataSource.data = this.dataSource.data;
            },
            error => {
              console.log(error);
              this.showErrorToast('There was a problem saving the remote file mapping configuration.');
            }
          );
        }
      });
  }

  editConfig(config: IRFMConfigV3TableData) {
    this.opModalService.openModal(RfmEditorComponent, {data: {rfm: config}})
      .afterClosed()
      .subscribe(
        (configRequest: IRFMConfigV3CreationRequest) => {
          if (configRequest) {
            configRequest.id = config.id;
            this.rfmService.updateRfmConfig(configRequest).subscribe((updatedConfig: IRFMConfigV3) => {
              config.name = updatedConfig.name;
              config.matchTypeFormatted = updatedConfig.matchType === 'equals' ? 'URL' : 'RegEx';
              config.fileLocation = updatedConfig.fileId ? 'Uploaded File' : 'Remote File';
              config.createdAt = updatedConfig.createdAt;
              config.updatedAt = updatedConfig.updatedAt;

              this.dataSource.data.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
              // forces table to update
              this.dataSource.data = this.dataSource.data;
            });
          }
        },
        error => {
          console.log(error);
          this.showErrorToast('There was a problem updating the remote file mapping configuration.');
        }
      );
  }

  copyConfig(config: IRFMConfigV3) {
    this.opModalService.openModal(RfmEditorComponent, {
      data: {
        rfm: {...config},
        copy: true
      }
    }).afterClosed().subscribe(
      (configRequest: IRFMConfigV3CreationRequest) => {
        if (configRequest) {
          this.rfmService.createRfmConfig(configRequest).subscribe((config: IRFMConfigV3) => {

            const newRow = {
              ...config,
              matchTypeFormatted: config.matchType === 'equals' ? 'URL' : 'RegEx',
              fileLocation: config.fileId ? 'Uploaded File' : 'Remote File',
              createdByName: this.getUserName(config.createdBy)
            } as IRFMConfigV3TableData;

            this.dataSource.data.push(newRow);
            this.dataSource.data.sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
            // forces table to update
            this.dataSource.data = this.dataSource.data;
          });
        }
      },
      error => {
        console.log(error);
        this.showErrorToast('There was a problem copying the remote file mapping configuration.');
      }
    );
  }

  deleteConfig(config: IRFMConfigV3) {
    this.opModalService.openModal(OpDeleteItemWarningComponent, {
      data: {
        itemType: 'Remote File Mapping',
        name: config.name,
        customMessage: 'Doing so is irreversible and will affect any audits or journeys to which this remote file mapping is assigned.'
      }
    })
      .afterClosed()
      .subscribe((confirmDelete: boolean) => {
        if (confirmDelete) {
          this.rfmService.deleteRfmConfig(config.id).subscribe(
            () => {
              this.dataSource.data = this.dataSource.data.filter((item: IRFMConfigV3) => item.id !== config.id);
            },
            error => {
              console.log(error);
              this.showErrorToast('There was a problem deleting the remote file mapping config.');
            }
          );
        }
      });
  }

  private updateRfmConfigCount(filtered?: boolean): void {
    this.rfmConfigCount = filtered ?
      this.dataSource.filteredData.length || 0 :
      this.dataSource.data.length;
  }

  private showErrorToast(message: string): void {
    this.snackbarService.openErrorSnackbar(message);
  }
}
