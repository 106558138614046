<op-modal
  [title]="title"
  [rightFooterButtons]="rightFooterButtons"
  (onClose)="close(undefined)">
  <ng-template #bodyTemplate>
    <div class="delete-action-content">
      <h2>Following actions may be affected.</h2>
      <img src="/images/delete-action.png"/>
      <p>Deleting an action which has other actions following may result in actions breaking.</p>
      <div>
        <mat-checkbox [ngModel]="true" [disabled]="true" color="primary">
          <b>Delete the action</b>
        </mat-checkbox>
      </div>
      <div>
        <mat-checkbox [(ngModel)]="deleteFollowingSteps" [disabled]="!optionalDeleteFollowing" color="primary">
          <b>Delete all following actions</b>
          <em *ngIf="deleteFollowingRecommended">Recommended</em>
        </mat-checkbox>
      </div>
    </div>
  </ng-template>
</op-modal>
