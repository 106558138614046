import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { IUser, IUserBase } from 'moonbeamModels';
import { AccountsService } from '@app/components/account/account.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ShareFoldersComponent } from '../share-folders/share-folders.component';
import { NewUserConfirmComponent } from './new-user-confirm/new-user-confirm.component';
import {
  opSysAdmin,
  opAdmin,
  admin,
  standardUser,
  guest,
  UserPermissions,
  OpSysAdminPermissions,
  OpAdminPermissions,
  AdminPermissions,
  StandardPermissions,
  GuestPermissions
} from '@app/authUtils';
import {
  AdminAccountsService,
} from '@app/components/admin-portal/admin-accounts.service';
import { IUserModel } from '@app/components/modals/modalData';
import { DateService } from '@app/components/date/date.service';
import { ICacheControls } from '@app/components/core/decorators/global-decorators.models';
import { Subject } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { IAdminAccount } from '@app/components/admin-portal/manage-accounts/manage-accounts.constants';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';
import { CreateEditUserModalComponent } from '@app/components/create-edit-user-modal/create-edit-user-modal.component';
import { ICreateEditUserModalData } from '@app/components/create-edit-user-modal/create-edit-user-modal.models';
import { MatPaginator } from '@angular/material/paginator';

export interface IUserListItem {
  isMe: boolean;
  isEditable: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit, OnDestroy, AfterViewInit {
  private createNewUser$ = new Subject<void>();
  updateTable: Subscription;

  users: IUser[] | IUserModel[];
  loading = true;
  currentUser: IUser;
  numUsers: number;
  displayedColumns: string[] = ['select', 'name', 'username', 'email', 'pageLimit', 'role', 'activity', 'created', 'editDelete'];
  dataSource = new MatTableDataSource;
  selection = new SelectionModel(true, []);
  primaryUserId: number;
  userRoles = {
    [opSysAdmin]: 'OP System Admin',
    [opAdmin]: 'OP Admin',
    [admin]: 'Admin',
    [standardUser]: 'Standard',
    [guest]: 'Read-only'
  };
  userRolesAsNumber = {
    [GuestPermissions]: this.userRoles[guest],
    [StandardPermissions]: this.userRoles[standardUser],
    [AdminPermissions]: this.userRoles[admin],
    [OpAdminPermissions]: this.userRoles[opAdmin],
    [OpSysAdminPermissions]: this.userRoles[opSysAdmin],
  };

  @Input() adminPortalUsers = false;
  @Input() account?: IAdminAccount;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) matPaginator: MatPaginator;

  constructor(
    private accountsService: AccountsService,
    private modalService: OpModalService,
    private snackbarService: SnackbarService,
    private adminAccountsService: AdminAccountsService,
    private dateService: DateService,
  ) {
  }

  ngOnInit() {
    this.getUsers();

    this.updateTable = this.createNewUser$
      .asObservable()
      .pipe(
        switchMap(_ => {
          let data: ICreateEditUserModalData = {
            editMode: false,
            accountType: this.account?.accountType ?? null,
          };

          if (this.adminPortalUsers) data.adminAccount = this.account;
          return this.modalService.openFixedSizeModal(CreateEditUserModalComponent, {
            disableClose: true,
            data
          }).afterClosed()
        }),
        filter(userInfo => !!userInfo),
        switchMap(userInfo => {
          return this.modalService.openModal(NewUserConfirmComponent, {
            maxHeight: 'calc(100vh - 100px)',
            height: 'auto',
            data: userInfo.firstName
          })
            .afterClosed();
        }),
        tap(shouldCreateAnotherUser => {
          if (shouldCreateAnotherUser) {
            this.createNewUser$.next();
          } else {
            this.getUsers();
          }
        })
      ).subscribe();
  }

  ngAfterViewInit() {
    this.primaryUserId = this.account?.primaryUser?.id;
  }

  getUsers() {
    let getUsersObservable: Observable<IUserModel[] | IUser[]>;

    if (this.adminPortalUsers) {
      getUsersObservable = this.adminAccountsService.getUsersForAccount(this.account.id);
    } else {
      (this.accountsService.getUsers as ICacheControls).cacheControls?.removeAll();
      getUsersObservable = this.accountsService.getUsers();
    }

    forkJoin([
      this.accountsService.getUser(),
      getUsersObservable
    ]).subscribe(([user, users]) => {
      this.users = users;
      this.currentUser = user;
      this.initTable();
    });
  }

  initTable() {
    if (this.adminPortalUsers && !this.displayedColumns.includes('timezone')) {
      this.displayedColumns.splice(6, 0, 'timezone', 'isPrimary');
    }

    this.dataSource.data = (this.users as IUser[]).map((user: IUser) => {
      const currentUserPermissionsNum = this.getPermissionsNumber(this.currentUser.permissions);
      const userPermissionsNum = this.adminPortalUsers ? user.permissions as number : this.getPermissionsNumber(user.permissions);
      const tooMighty = currentUserPermissionsNum < userPermissionsNum ||
                        (currentUserPermissionsNum === userPermissionsNum && currentUserPermissionsNum === OpAdminPermissions);

      if (this.adminPortalUsers) user.permissions = this.getPermissionsValue(+user.permissions);

      return {
        id: user.id,
        name: user.firstName + ' ' + user.lastName,
        username: user.username + (user.id === this.currentUser.id ? ' (You)' : ''),
        email: user.email,
        pageLimit: user.maxPagesPerAudit,
        role: this.userRoles[user.permissions],
        activity: user.lastLogin,
        created: user.created,
        timezone: user.timezone,
        isEditable: !tooMighty,
        isMe: user.id === this.currentUser.id
      };
    }).sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, attribute) => {
      return typeof data[attribute] === 'string'
        ? data[attribute]?.toLowerCase()
        : data[attribute];
    }
    this.dataSource.paginator = this.matPaginator;
    this.numUsers = this.dataSource.data.length;
    this.loading = false;
  }

  ngOnDestroy() {
    this.updateTable.unsubscribe();
  }

  formatRecentActivity(date: Date): string {
    return date === null ? 'Never logged in' : this.dateService.distanceToNow(new Date(date));
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return !this.adminPortalUsers ? numSelected === numRows : numSelected === (numRows - 1); // Primary user is unselectable in admin
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach((row: (IUserBase | IUserModel) & IUserListItem) => {
        if (row.id !== this.primaryUserId && !row.isMe && row.isEditable) this.selection.select(row);
      });
  }

  onNewUser() {
    this.createNewUser$.next();
  }

  onEditUser(user) {
    // @ts-ignore
    let fullUser = this.users.filter(item => item.id === user.id)[0];

    let data: ICreateEditUserModalData = {
      editMode: true,
      hideFolderTab: this.adminPortalUsers,
      userId: user.id,
      accountType: this.account?.accountType ?? null,
      accountLocked: !!fullUser['blockedUntil'] ?? null
    };

    if (this.adminPortalUsers) data.adminAccount = this.account;
    this.modalService.openFixedSizeModal(CreateEditUserModalComponent, {
      disableClose: true,
      data
    })
      .afterClosed()
      .subscribe((user) => {
        if (user) {
          this.getUsers();
        }
      });
  }

  onDeleteUser(user: IUser) {
    let deletePromise = Promise.resolve();
    const data = {
      deleteButtonAction: () => {
        deletePromise = this.accountsService.deleteUser(user.id).then(
          success => {},
          error => {
            error.message.includes('doesn\'t have access to')
              ? this.showToast('You do not have permission to delete this user.')
              : this.showToast('There was a problem deleting this user. Please refresh the page and try again.');
          }
        );
      },
      displayItem: {
        type: 'User',
        name: user['name']
      },
    };

    this.modalService.openDeleteModal({data}).afterClosed().subscribe(toDelete => {
      if (toDelete) {
        deletePromise.then(() => {
          // forces table to update
          this.dataSource.data = this.dataSource.data.filter(usr => usr !== user);
          this.getUsers();
        });
      }
    });
  }

  onShareFolders() {
    this.modalService
      .openModal(ShareFoldersComponent, {
        maxHeight: 'calc(100vh - 100px)',
        height: 'auto',
        data: this.selection.selected
      })
      .afterClosed()
      .subscribe(response => {
        if (response) this.selection.clear();
      });
  }

  onDeleteUsers() {
    let deleteCount = this.selection.selected.length;
    let plural = deleteCount > 1 ? 's' : '';

    let modalRef = this.modalService.openConfirmModal({
      data: {
        title: 'Confirm Delete',
        messages: [
          'You have selected ' + deleteCount + ' user' + plural + ' to be deleted.',
          'Are you sure you want to proceed?'
        ],
        rightFooterButtons: [
          {
            label: 'Cancel', action: () => {
              modalRef.close();
            },
            primary: false
          },
          {
            label: 'Delete ' + deleteCount + ' User' + plural, action: () => {
              let toastMessage = deleteCount > 1 ? 'these users' : 'this user';
              let deletePromises = this.selection.selected.map(user => {
                return this.accountsService.deleteUser(user.id);
              });

              forkJoin(deletePromises).subscribe(
                success => {},
                error => {
                  this.showToast('There was a problem deleting ' + toastMessage + '. Please refresh the page and try again.');
                }
              );

              this.selection.selected.forEach(user => {
                let index = this.dataSource.data.indexOf(user);
                this.dataSource.data.splice(index, 1);
              });

              // forces table to update
              this.dataSource.data = this.dataSource.data;

              this.selection.clear();
              modalRef.close();
            }, primary: true
          }
        ]
      }
    });
  }

  private showToast(message: string) {
    this.snackbarService.openErrorSnackbar(message);
  }

  confirmChangePrimaryUser(clickedUser, event) {
    if (clickedUser.id !== this.primaryUserId && clickedUser.role !== this.userRoles[guest] && clickedUser.role !== this.userRoles[standardUser]) {
      event.preventDefault();
      // @ts-ignore
      const foundUser = this.users.find(user => user.id === clickedUser.id);

      this.modalService.openConfirmModal({
        data: {
          title: 'Change primary user?',
          messages: [
            `Are you sure you want to make ${clickedUser.username} your new primary user?`
          ],
          rightFooterButtons: [
            {
              label: 'Yes, change primary user',
              action: () => {
                this.primaryUserId = clickedUser.id;
                const updatedPrimaryUser = {
                  ...foundUser,
                  permissions: this.getPermissionsNumber(foundUser.permissions)
                };
                // Get the original account and make the selected user the new primary user
                let updatedAccount = {...this.account, primaryUser: updatedPrimaryUser};
                // Make API call to update the account
                this.adminAccountsService.updateAccount((updatedAccount as IAdminAccount)).subscribe((account) => {
                });
              },
              primary: true
            }
          ]
        }
      });
    }
  }

  getPermissionsValue(value: number): string {
    const permission = UserPermissions.filter(p => p.number === value);
    return permission[0]['value'];
  }

  getPermissionsNumber(value: string|number): number {
    const permission = UserPermissions.filter(p => p.value === value);
    return permission[0]['number'];
  }
}
