import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IPagesDetailsAccessibilityFilter
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.models';
import {
  PageDetailsAccessibilityService
} from '@app/components/audit-reports/page-details/components/page-details-accessibility/page-details-accessibility.service';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { EPageDetailsTabs } from '@app/components/audit-reports/page-details/page-details.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'page-details-accessibility-filter-bar',
  templateUrl: './page-details-accessibility-filter-bar.component.html',
  styleUrls: ['./page-details-accessibility-filter-bar.component.scss']
})
export class PageDetailsAccessibilityFilterBarComponent {
  readonly exportAllIssuesTooltip = 'Export all issues in Excel';
  @Input() itemId: number;
  @Input() runId: number;
  @Input() pageId: string;
  @Input() reportType: EReportType;
  @Input() activeTab?: EPageDetailsTabs; // specific to audits
  @Output() onExportAllIssues: EventEmitter<void> = new EventEmitter<void>();
  @Output() onUpdateFilter: EventEmitter<IPagesDetailsAccessibilityFilter> = new EventEmitter<IPagesDetailsAccessibilityFilter>();
  filters: IPagesDetailsAccessibilityFilter[] = Object.values(this.accessibilityService.filters.value.topFilters);

  constructor(private accessibilityService: PageDetailsAccessibilityService) {
  }

  updateFilter(filter: IPagesDetailsAccessibilityFilter): void {
    this.onUpdateFilter.emit(filter);
  }

  exportAllIssues(): void {
    this.onExportAllIssues.emit();
  }
}
