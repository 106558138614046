<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Create New Account"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="create-new-account">
      <mat-spinner *ngIf="saving" [diameter]="75" [strokeWidth]="5"></mat-spinner>
      <form [formGroup]="newAccountForm" class="new-account-form" [class.saving]="saving">
        <div class="cols">
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Account Name</mat-label>
              <input title="Account Name" matInput placeholder="Account Name" formControlName="name">
              <mat-error *ngIf="name.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Username (primary user)</mat-label>
              <input title="Username (primary user)" matInput placeholder="Username" formControlName="username">
              <mat-error *ngIf="username.errors?.required">This field is required</mat-error>
              <mat-error *ngIf="username.errors?.duplicate">This username is already taken</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="cols">
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input title="First Name" matInput placeholder="First name" formControlName="firstName">
              <mat-error *ngIf="domain.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input title="Last Name" matInput placeholder="Last name" formControlName="lastName">
              <mat-error *ngIf="domain.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Email Address (primary user)</mat-label>
              <input title="Email Address (primary user)" matInput placeholder="Email Address" formControlName="email">
              <mat-error *ngIf="email.errors?.required">This field is required</mat-error>
              <mat-error *ngIf="email.errors?.emailsListValidator">You must enter a valid email address</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="cols">
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Domain</mat-label>
              <input title="Domain" matInput placeholder="Domain" formControlName="domain">
              <mat-error *ngIf="domain.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Data Layer (optional)</mat-label>
              <input title="Data Layer (optional)" matInput placeholder="Data Layer" formControlName="dataLayer">
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option *ngFor="let status of statuses" [value]="status.key">{{ status.value }}</mat-option>
              </mat-select>
              <mat-error *ngIf="status.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="cols six-fields">
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Max Domains</mat-label>
              <input title="Max Domains" matInput placeholder="Max Domains" formControlName="maxDomains">
              <mat-error *ngIf="maxDomains.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Max Monitored Journeys</mat-label>
              <input title="Max Monitored Journeys" matInput placeholder="Max Monitored Journeys" formControlName="maxMonitoredJourneys">
              <mat-error *ngIf="maxMonitoredJourneys.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Web Journey Support Fixes</mat-label>
              <input title="Web Journey Support Fixes" matInput placeholder="Web Journey Support Fixes" formControlName="ssJourneyFixes">
              <mat-error *ngIf="ssJourneyFixes.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Monthly Pages Limit</mat-label>
              <input title="Monthly Pages Limit" matInput placeholder="Monthly Pages Limit" formControlName="auditPageLimit">
              <mat-error *ngIf="auditPageLimit.errors?.min">This field must be > 0</mat-error>
              <mat-error *ngIf="auditPageLimit.errors?.max">This field must be < 2147483648</mat-error>
              <mat-error *ngIf="auditPageLimit.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Monthly Runs Limit</mat-label>
              <input title="Monthly Runs Limit" matInput placeholder="Monthly Runs Limit" formControlName="webJourneyRunLimit">
              <mat-error *ngIf="webJourneyRunLimit.errors?.min">This field must be > 0</mat-error>
              <mat-error *ngIf="webJourneyRunLimit.errors?.max">This field must be < 2147483648</mat-error>
              <mat-error *ngIf="webJourneyRunLimit.errors?.required">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="cols">
          <div class="field">
            <mat-checkbox color="primary" formControlName="customProxy">Custom Proxy</mat-checkbox>
          </div>
          <div class="field">
            <mat-checkbox color="primary" formControlName="strongPassword">Strong Password</mat-checkbox>
          </div>
          <div class="field">
            <mat-checkbox color="primary" formControlName="templateData">Template Data</mat-checkbox>
          </div>
          <div class="field">
            <mat-checkbox color="primary" formControlName="dataWarehousing">Enhanced Data Retention</mat-checkbox>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</op-modal>
