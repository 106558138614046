import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild, OnDestroy
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {
  IActionDetailsCookieResponse,
  WebJourneyActionDetailsService
} from '@app/components/domains/webJourneys/webJourneyAPI/web-journey-action-details.service';
import {
  IActionDetailsCookieObject
} from '../../../domains/webJourneys/webJourneyAPI/web-journey-action-details.service';
import { DatePipe } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import {
  ECookieOrigin,
  IAuditCookie,
  IAuditCookieOrigin,
  IAuditCookies,
  IAuditCookiesParams,
  IWebJourneyResponseCookieOriginDTO,
} from '@app/components/audit-reports/page-details/page-details.models';
import {
  AuditCookieOriginMap,
  CookieChangeActionStringMap,
  ECookieSameSiteDisplayTypes,
  ECookieSameSiteResponseTypes,
  EPageDetailsTabs,
  party
} from '@app/components/audit-reports/page-details/page-details.constants';
import { PageDetailsReportService } from '@app/components/audit-reports/page-details/page-details.service';
import { ECookieOpChangeType } from '@app/components/audit-reports/page-details/page-details.enums';
import {
  AuditReportUrlBuilders,
  COOKIES_SEARCH_TEXT_KEY, COOKIES_SHOW_PRE_AUDIT_ACTIONS,
  PRIVACY_COOKIES_SEARCH_TEXT_KEY,
} from '@app/components/audit-reports/audit-report/audit-report.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ICookieTableRow } from './cookies-table.models';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IUiTag } from '@app/components/tag-database/tag-database.model';
import { JsFileChangesReportUtils } from '@app/components/utilities/js-file-changes-report.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cookies-table',
  templateUrl: './cookies-table.component.html',
  styleUrls: ['./cookies-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ]
})
export class CookiesTableComponent implements OnChanges, AfterViewInit, OnInit, OnDestroy {
  protected readonly EReportType = EReportType;
  private destroy$ = new Subject();
  originInfoTooltip: string = 'Most recent action affecting the state of the cookie. To view additional details expand an individual cookie row.';
  loading: boolean = true;
  columnsToDisplay: string[];
  dataSource = new MatTableDataSource<ICookieTableRow>();
  expandedCookie: IActionDetailsCookieObject;
  actionTypesToShowMessageFor = [ECookieOrigin.PreAuditAction, ECookieOrigin.OnPageAction];
  tags: IUiTag[] = [];

  insightUniqueCookies: string;
  insightUniqueCookieDomains: string;
  insightThirdPartyCookies: string;

  showPreAuditActionRequests: boolean = false;
  cookiesTableRequestParams: IAuditCookiesParams = {};

  search: string;

  @Input() itemId: number;
  @Input() runId: number;
  @Input() reportType: EReportType;
  @Input() pageId?: string; // specific to audits
  @Input() activeTab?: EPageDetailsTabs; // specific to audits
  @Input() actionIndex?: number; // specific to web journeys
  @Input() success?: boolean; // specific to web journeys
  @Input() isScrolled: boolean = false;
  @Input() state?: any;
  @Input() stepLoaded$?: Observable<string>;
  @Output() cookieCount: EventEmitter<number> = new EventEmitter(); // specific to web journeys
  @Output() navToAction: EventEmitter<{ actionIndex: number, cookie: ICookieTableRow }> = new EventEmitter();
  @Output() navToTab: EventEmitter<{ tab: EPageDetailsTabs, searchValue: string, setActiveTab?: boolean }> = new EventEmitter();
  @Output() setCookieNameFilter: EventEmitter<string> = new EventEmitter();
  @Output() stateUpdated: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  get isAudit(): boolean {
    return this.reportType === EReportType.AUDIT;
  }

  constructor(
    private actionDetailsService: WebJourneyActionDetailsService,
    private datePipe: DatePipe,
    private pageDetailsService: PageDetailsReportService,
    private router: Router,
    private uiTagService: UiTagService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.externalToInternalState();
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.action) {
        this.actionIndex = +queryParams.action;
      }
    });
  }

  ngOnInit(): void {
    this.uiTagService.getTags().subscribe((tags: IUiTag[]) => {
      this.tags = tags;
      this.setFilterPredicate();
      this.initColumns();
      this.externalToInternalState();
    },
    () => {
      this.tags = [];
      this.setFilterPredicate();
      this.initColumns();
      this.externalToInternalState();
    });

    if (this.search) {
      this.filterTable(this.search);
    }
  }

  setPreAuditActionFlagAndLoadData(newValue: boolean): void {
    this.showPreAuditActionRequests = newValue;
    this.cookiesTableRequestParams.showPreAuditActionCookies = newValue;
    this.internalToExternalState();
    this.getCookieData();
  }

  initColumns() {
    this.columnsToDisplay = ['expand', 'name', 'domain', 'partyType', 'partitionKey', 'initiators', 'origin'].filter(Boolean);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.externalToInternalState();

    if (this.reportType === EReportType.WEB_JOURNEY) {
      this.getCookieData();
    }

    // `changes.pageId` prevents the `isScrolled` input from causing
    // the table data to be refreshed which collapses any open rows
    // and only gets data if the pageId changes
    if (this.reportType === EReportType.AUDIT && changes.pageId) {
      this.loading = true;
      this.getCookieData();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    if (this.stepLoaded$) {
      this.stepLoaded$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((stepEventItem: any) => {
          const foundCookie = this.dataSource.data.find(cookie => cookie.name === stepEventItem?.name);
          this.toggleCookieDetailsView(foundCookie);
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate = (data: ICookieTableRow, filter: string) => {
      const searchTerms = filter.toLowerCase().split(/\s+/);
      return searchTerms.every(term => {
        const foundInAtLeastOneProp = ['name', 'value', 'domain', 'samesite'].some(prop =>
          data[prop]?.toLowerCase().includes(term)
        );
        return foundInAtLeastOneProp;
      });
    };
  }

  getCookieData(): void {
    this.loading = true;
    if (this.reportType === EReportType.AUDIT) {
      this.pageDetailsService
        .getAuditPageCookies(this.itemId, this.runId, this.pageId, this.cookiesTableRequestParams)
        .pipe(finalize(() => this.loading = false))
        .subscribe((response: IAuditCookies) => {
          this.insightUniqueCookies = response.insights?.noOfUniqueCookies?.toLocaleString() ?? '?';
          this.insightUniqueCookieDomains = response.insights?.noOfUniqueDomains?.toLocaleString() ?? '?';
          this.insightThirdPartyCookies = response.insights?.noOf3rdPartyCookies?.toLocaleString() ?? '?';
          this.formatCookieData(response.cookies);
        });
    } else {
      this.actionDetailsService
        .getCookieData(this.itemId, this.runId, this.actionIndex)
        .pipe(finalize(() => this.loading = false))
        .subscribe((response: IActionDetailsCookieResponse) => {
          if (!response || !response.create) return this.handleJourneyActionFailureState();

          this.insightUniqueCookies = response.insights.noOfUniqueCookies?.toLocaleString() ?? '?';
          this.insightUniqueCookieDomains = response.insights.noOfUniqueDomains?.toLocaleString() ?? '?';
          this.insightThirdPartyCookies = response.insights.noOf3rdPartyCookies?.toLocaleString() ?? '?';
          this.formatCookieData(response.create);
        });
    }
  }

  formatCookieData(data: (IActionDetailsCookieObject | IAuditCookie)[]): void {
    this.dataSource.data = data
      .map((cookie: (IActionDetailsCookieObject | IAuditCookie)) => {
        const row: ICookieTableRow = {
          name: cookie.name ?? '',
          value: cookie.value ?? '',
          domain: cookie.domain ?? '',
          path: cookie.path ?? '',
          origin: this.reportType === EReportType.WEB_JOURNEY ? this.formatWebJourneyCookieChangeType((cookie as IActionDetailsCookieObject).opChangeType) : this.formatAuditCookieOrigin((cookie as IAuditCookie).origin),
          partyType: ('partyType' in cookie && party[cookie.partyType]) ? party[cookie.partyType] : '',
          duration:
            this.formatCookieDuration(cookie) || '',
          expires:
            this.formatCookieExpiry(
              ('expires' in cookie && cookie.expires)
              || ('expirationTimestamp' in cookie && cookie.expirationTimestamp))
              || '',
          httpOnly: cookie.httpOnly,
          sameSite: this.formatSameSite(cookie.samesite),
          secure: cookie.secure,
          loading: false,
          expandedInitiatorData: false,
          initiators: this.getTotalInitiatorCountForCookie(cookie),
          changes: (cookie as IAuditCookie).changes || [],
          partitionKey: cookie.partitionKey ?? '---',
        };

        return row;
      })
      .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => String(data[sortHeaderId]).toLowerCase();

    this.loading = false;
  }

  getTotalInitiatorCountForCookie(cookie): number | string {
    let count = 0;

    if (this.isAudit) {
      count = cookie.changes.reduce((acc, change) => {
        return count += (change.initiators?.length || 0);
      }, count);
    } else {
      count = cookie.initiators?.length;
    }

    return count > 0 ? count : '---';
  }

  formatSameSite(sameSite: ECookieSameSiteResponseTypes): ECookieSameSiteDisplayTypes|'---' {
    return ECookieSameSiteDisplayTypes[sameSite] || '---';
  }

  formatWebJourneyCookieChangeType(changeType: ECookieOpChangeType): string {
    return changeType ? CookieChangeActionStringMap[changeType] : 'Available on newer runs';
  }

  formatAuditCookieOrigin(origin: IAuditCookieOrigin): string {
    if (origin) {
      return (origin.hasOwnProperty('action') && origin.hasOwnProperty('originType'))
        ? `${CookieChangeActionStringMap[origin.action]} ${AuditCookieOriginMap[origin.originType]}`
        : 'Could not Determine';
    } else {
      return 'Available on newer runs';
    }
  }

  getWebJourneyCookieOrigin(cookie, forceOpen?: boolean) {
    const requestBody = {
      name: cookie.name,
      domain: cookie.domain,
      path: cookie.path,
    };

    // Update for new origin endpoint
    return this.pageDetailsService.getWebJourneyCookieOrigin(this.itemId, this.runId, this.actionIndex, requestBody).subscribe((webJourneyOriginDTO: IWebJourneyResponseCookieOriginDTO) => {
      cookie.changes = webJourneyOriginDTO?.actions?.map(change => {
        return { ...change, cookieOriginType: ECookieOrigin.OnPageAction };
      }).sort((a, b) => a.action?.sequence - b.action?.sequence);
      cookie.totalCookieActionCount = webJourneyOriginDTO.totalCookieActionCount;
      cookie.totalCookieInitiatorCount = webJourneyOriginDTO.totalCookieInitiatorCount;
      cookie.loading = false;
    });
  }

  // Returns the cookie duration in a human-readable format. Subtracts the time to live value
  // (returned in minutes) from the expiration date to get the creation date, then
  // formats the difference.
  formatCookieDuration(cookie): string {
    const ttl = cookie.ttlInMinutes || cookie.cookieTTL;
    if (typeof ttl === 'number') {
      if (cookie.expires === 'Session') return 'Session';

      return JsFileChangesReportUtils.formatDateDifference(ttl);
    }

    return 'Session';
  }

  formatCookieExpiry(timestamp: number | string): string {
    if (!timestamp) return 'Session';

    let now = new Date();
    if (now.getTime() > (timestamp as number)) return 'Session';

    return this.datePipe.transform(new Date(timestamp), 'yyyy-MM-dd h:mm:ss aaa');
  }

  toggleCookieDetailsView(cookie): void {
    this.expandedCookie = this.expandedCookie === cookie ? null : cookie;

    if (!this.isAudit) {
      cookie.loading = true;
      this.getWebJourneyCookieOrigin(cookie);
    }
  }

  navToCookieInventoryByName(name: string): void {
    if (this.isAudit) {
      this.setCookieNameFilter.emit(name);
      this.router.navigateByUrl(AuditReportUrlBuilders.cookieInventory(this.itemId, this.runId));
    }
  }

  handleJourneyActionFailureState(): void {
    this.dataSource.data = [];

    if (this.reportType === EReportType.WEB_JOURNEY) {
      this.loading = false;
      this.cookieCount.emit(0);
    }
  }

  filterTable(value: string): void {
    this.search = value?.trim().toLowerCase();
    this.dataSource.filter = this.search;
    this.internalToExternalState();
  }

  private externalToInternalState(): void {
    this.state = this.state || {};

    if (this.state.hasOwnProperty(COOKIES_SEARCH_TEXT_KEY) || this.state.hasOwnProperty(PRIVACY_COOKIES_SEARCH_TEXT_KEY)) {
      this.search = this.state[COOKIES_SEARCH_TEXT_KEY] || this.state[PRIVACY_COOKIES_SEARCH_TEXT_KEY] || '';
    }

    if (this.state.hasOwnProperty(COOKIES_SHOW_PRE_AUDIT_ACTIONS)) {
      this.showPreAuditActionRequests = this.state[COOKIES_SHOW_PRE_AUDIT_ACTIONS] ?? false;
      this.cookiesTableRequestParams.showPreAuditActionCookies = this.showPreAuditActionRequests;
    }
  }

  private internalToExternalState(): void {
    const newState = {};

    newState[COOKIES_SEARCH_TEXT_KEY] = this.search || '';
    newState[COOKIES_SHOW_PRE_AUDIT_ACTIONS] = this.showPreAuditActionRequests;

    this.state = { ...this.state, ...newState };
    this.stateUpdated.emit(newState);
  }
}
