import { Injectable } from '@angular/core';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { IRuleV3Usage } from '@app/components/rules/rule-library/rule-library.models';
import { IRule, IRulePreview, IRuleV3Label } from '@app/components/rules/rules.models';
import { RulesService } from '@app/components/rules/rules.service';
import { EStandardsSelectorType } from './op-standards-selector.constants';
import { IStandardsSelectorItem } from './op-standards-selector.models';
import { IConsentCategory } from '@app/components/consent-categories/consent-categories.models';
import { IAlertAssignment, usageMetricTypes } from '@app/components/alert/alert.models';
import { AlertService } from '@app/components/alert/alert.service';
import { EAlertSearchSortBy } from '@app/components/alert/alert.constants';
import { EAlertUsageMetricV2 } from '@app/components/alert/alert-logic/alert-logic.enums';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Injectable()
export class OpStandardsSelectorService {

  private consentCatStandards: IStandardsSelectorItem[] = [];
  private consentCatDefaultType: string;
  private invalidated_CC_Cache: boolean = true;
  ruleStandards: IStandardsSelectorItem[] = [];
  alertStandards: IStandardsSelectorItem[] = [];
  labels: ILabel[] = [];

  constructor(
    private labelsService: LabelService,
    private rulesService: RulesService,
    private ccService: ConsentCategoriesService,
    private alertService: AlertService
  ) {}

  async getData(standardsType: EStandardsSelectorType, audits: boolean = true): Promise<IStandardsSelectorItem[]> {
    await this.getLabels();

    switch (standardsType) {
      case EStandardsSelectorType.RULES:
        return this.getAllRules();

      case EStandardsSelectorType.CONSENT_CATEGORIES:
        return this.getAllConsentCategories(audits);

      case EStandardsSelectorType.ALERTS:
        return this.getAllAlerts();

      default:
        return this.getAllRules();
    }
  }

  private getLabels(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.labelsService.getLabels().subscribe((labels: ILabel[]) => {
        this.labels = labels;
        resolve();
      });
    });
  }

  private async getAllRules(): Promise<IStandardsSelectorItem[]> {
    let rules: IRulePreview[] = await this.rulesService.getRulePreviewsCached().toPromise();
    let allRules: IStandardsSelectorItem[] = rules.map((rule: IRulePreview) => {
      rule.labels?.sort((a, b) => a.id - b.id);
      return {
        id: rule.id,
        name: rule.name,
        labels: rule.labels || [],
        isDefaultForNewDataSource: rule.isDefaultRule || false,
      };
    });

    allRules.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
	  this.ruleStandards = allRules;

    return allRules;
  }

  convertRuleToStandard(rule: IRuleV3Usage | IRule): IStandardsSelectorItem {
    return {
      id: rule.id,
      name: rule.name,
      labels: rule.labels.map((ruleLabel: IRuleV3Label) => ({
        id: ruleLabel.id,
        name: this.labels.find(label => label.id === ruleLabel.id)?.name
      }))
    };
  }

  private async getAllConsentCategories(audits: boolean): Promise<IStandardsSelectorItem[]> {
    let allConsentCats: IStandardsSelectorItem[] = [];
    const pageSize = 1000;
    let currentPage = 0;
    let lastPage = 1;

    while (currentPage < lastPage) {
      const batchOfConsentCats = await this.ccService.getConsentCategoriesLibrary({
        page: currentPage,
        pageSize: pageSize
      }).toPromise();

      if (batchOfConsentCats.metadata.pagination.totalPageCount) {
        lastPage = batchOfConsentCats.metadata.pagination.totalPageCount;
      } else {
        lastPage += batchOfConsentCats.metadata.pagination.currentPageSize < pageSize ? 0 : 1;
      }

      // Sort by name
      batchOfConsentCats.consentCategories.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

      allConsentCats = allConsentCats.concat(batchOfConsentCats.consentCategories.map((consentCat: IConsentCategory) =>
        this.convertConsentCatToStandard(consentCat)
      ));

      currentPage++;
    }

    this.consentCatStandards = allConsentCats;
    if(audits) this.setConsentCatDefaultType();
    return allConsentCats;
  }
  convertConsentCatToStandard(consentCat: IConsentCategory): IStandardsSelectorItem {
    return {
      id: consentCat.id,
      name: `${consentCat.name} (${consentCat.type})`,
      type: consentCat.type,
      labels: consentCat.labelIds?.map((id: number) => ({
        id: id,
        name: this.labels.find(label => label.id === id).name
      })) || [],
      isDefaultForNewDataSource: consentCat.isDefaultCC || false,
    };
  }

  private async setConsentCatDefaultType() {
    await this.getData(EStandardsSelectorType.CONSENT_CATEGORIES, false);
    this.consentCatDefaultType = this.consentCatStandards.find((consentCat: IStandardsSelectorItem) => consentCat.isDefaultForNewDataSource)?.type ?? null;
    this.invalidated_CC_Cache = false;
  }
  async getConsentCatDefaultType(): Promise<string> {
    if (this.invalidated_CC_Cache) {
      await this.setConsentCatDefaultType();
    }
    return this.consentCatDefaultType?.toLocaleLowerCase() || null;
  }
  invalidateConsentCatCache(): void {
    this.invalidated_CC_Cache = true;
  }
  async noDefaultCCs(): Promise<boolean> {
    if (this.invalidated_CC_Cache) {
      await this.setConsentCatDefaultType();
    }
    return this.consentCatStandards.filter((consentCat: IStandardsSelectorItem) => consentCat.isDefaultForNewDataSource).length === 0;
  }

  getSelectedStandards(type: EStandardsSelectorType, ids: number[]): IStandardsSelectorItem[] {
    let selected = [];
    let standards = [];

    switch (type) {
      case EStandardsSelectorType.ALERTS:
        standards = this.alertStandards;
        break;

      case EStandardsSelectorType.CONSENT_CATEGORIES:
        standards = this.consentCatStandards;
        break;

      case EStandardsSelectorType.RULES:
        standards = this.ruleStandards;
        break;
    }

    ids.forEach((id: number) => {
      selected.push(standards.find((standard: IStandardsSelectorItem) => standard.id === id));
    });

    selected.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    return selected;
  }

  private async getAllAlerts(): Promise<IStandardsSelectorItem[]> {
    let allAlerts: IStandardsSelectorItem[] = [];
    let currentPage = 0;
    let lastPage = 1;

    // sending empty request body because we want ALL alerts not just unsubscribed
    const requestBody = {};
    const params = {
      size: 1000,
      page: 0,
      sortBy: EAlertSearchSortBy.ALERT_NAME,
      sortDesc: false
    };

    while (currentPage < lastPage) {
      let batchOfAlerts = await this.alertService.getAlertsForAssignment(params, requestBody).toPromise();
      // Remove usage alerts from result for standards tab since they can't be assigned to an audit
      batchOfAlerts.alerts = batchOfAlerts.alerts?.filter((alert: IAlertAssignment) => !usageMetricTypes.includes(alert.metricType as EAlertUsageMetricV2));

      if (batchOfAlerts.metadata.pagination.totalPageCount) {
        lastPage = batchOfAlerts.metadata.pagination.totalPageCount;
      } else {
        lastPage += batchOfAlerts.metadata.pagination.currentPageSize < params.size ? 0 : 1;
      }

      allAlerts = allAlerts.concat(batchOfAlerts.alerts.map((alert: IAlertAssignment) => this.convertAlertToStandard(alert)));

      currentPage++;
    }

    this.alertStandards = allAlerts; //.filter((alert: IStandardsSelectorItem) => usageMetricTypes.includes(alert.metricType as EAlertUsageMetric));
    return allAlerts;
  }

  convertAlertToStandard(alert: IAlertAssignment): IStandardsSelectorItem {
    return {
      id: alert.id,
      name: `${alert.name}`,
      labels: alert.labels?.map((id: number) => ({
        id: id,
        name: this.labels.find(label => label.id === id)?.name
      })) || []
    };
  }
}
