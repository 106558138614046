<div class="stats-popup menu-icon-wrp menu-icon">
  <div mdePopoverTriggerOn="hover"
       [mdePopoverTriggerFor]="statsPopupPanelTrigger"
  >
    <mat-icon>info_outline</mat-icon>
  </div>
  <mde-popover #statsPopupPanelTrigger="mdePopover"
               class="audit-info-popover"
               [mdePopoverOverlapTrigger]="false"
               [mdePopoverArrowColor]="'rgba(0,0,0,0)'"
               [mdePopoverEnterDelay]="400"
               [mdePopoverCloseOnClick]="false">
    <div class="stats-popup-container">
      <div class="stats-popup-section-header" [innerHTML]="data.headerText">
      </div>
      <div class="stats-popup-section-columns">
        <div class="stats-popup-section-left">
          <div class="info-item">
            <div class="info-item-label">Median</div>
            <div class="info-item-text">{{ data.median }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">95th Percentile</div>
            <div class="info-item-text">{{ data.ninetyFifthPercentile }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">Min</div>
            <div class="info-item-text">{{ data.min }}</div>
          </div>
        </div>
        <div class="stats-popup-section-right">
          <div class="info-item">
            <div class="info-item-label">Average</div>
            <div class="info-item-text">{{ data.average }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">99th Percentile</div>
            <div class="info-item-text">{{ data.ninetyNinthPercentile }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">Max</div>
            <div class="info-item-text">{{ data.max }}</div>
          </div>
        </div>
      </div>
    </div>
  </mde-popover>
</div>
