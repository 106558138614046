import { ComponentLibraryAccordionRouteName, ComponentLibraryActionsMenuRouteName, ComponentLibraryBetaLabelRouteName, ComponentLibraryBreadcrumbRouteName, ComponentLibraryButtonRouteName, ComponentLibraryChipManagerRouteName, ComponentLibraryEditDataLayerModalRouteName, ComponentLibraryFileUploadRouteName, ComponentLibraryFilterPillRouteName, ComponentLibraryFilterSpinnerRouteName, ComponentLibraryHorizontalBarChartRouteName, ComponentLibraryLabelManagerRouteName, ComponentLibraryMenuRouteName, ComponentLibraryModalRouteName, ComponentLibraryOpenMenuOnHoverRouteName, ComponentLibraryProgressBarRouteName, ComponentLibraryReportWidgetsRouteName, ComponentLibrarySectionDividerRouteName, ComponentLibrarySelectCreateRouteName, ComponentLibrarySelectRouteName, ComponentLibrarySelectWithIconsRouteName, ComponentLibrarySnackbarRouteName, ComponentLibrarySortIndicatorRouteName, ComponentLibrarySplashPageBasicRouteName, ComponentLibraryStandardsSelectorRouteName, ComponentLibraryTabsRouteName, ComponentLibraryTagImageRouteName, ComponentLibraryTitles, ComponentLibraryTooltipRouteName } from './component-library.constants';

import { Route } from '@angular/router';
import { ComponentLibraryPaths } from './component-library.constants';
import { ComponentLibraryButtonComponent } from './button/cl-button.component';
import { ComponentLibraryFileUploadComponent } from './file-upload/cl-file-upload.component';
import { ComponentLibraryReportWidgetsComponent } from './report-widgets/cl-report-widgets.component';
import { ComponentLibraryTabsComponent } from './tabs/cl-tabs.component';
import { ComponentLibraryAccordionComponent } from './accordion/cl-accordion.component';
import { ComponentLibraryBetaLabelComponent } from './beta-label/cl-beta-label.component';
import { ComponentLibraryActionsMenuComponent } from './actions-menu/cl-actions-menu.component';
import { ComponentLibraryBreadcrumbComponent } from './breadcrumb/cl-breadcrumb.component';
import { ComponentLibraryEditDataLayerModalComponent } from './edit-data-layer-modal/cl-edit-data-layer-modal.component';
import { ComponentLibraryFilterPillComponent } from './filter-pill/cl-filter-pill.component';
import { ComponentLibraryFilterSpinnerComponent } from './filter-spinner/cl-filter-spinner.component';
import { ComponentLibraryChipManagerComponent } from './chip-manager/cl-chip-manager.component';
import { ComponentLibraryLabelManagerComponent } from './label-manager/cl-label-manager.component';
import { ComponentLibraryMenuComponent } from './menu/cl-menu.component';
import { ComponentLibraryModalComponent } from './modal/cl-modal.component';
import { ComponentLibraryProgressBarComponent } from './progress-bar/cl-progress-bar.component';
import { ComponentLibrarySectionDividerComponent } from './section-divider/cl-section-divider.component';
import { ComponentLibrarySelectComponent } from './select/cl-select.component';
import { ComponentLibrarySelectCreateComponent } from './select-create/cl-select-create.component';
import { ComponentLibrarySelectWithIconsComponent } from './select-with-icons/cl-select-with-icons.component';
import { ComponentLibrarySortIndicatorComponent } from './sort-indicator/cl-sort-indicator.component';
import { ComponentLibrarySnackbarComponent } from './snackbar/cl-snackbar.component';
import { ComponentLibraryStandardsSelectorComponent } from './standards-selector/cl-standards-selector.component';
import { ComponentLibrarySplashPageBasicComponent } from './splash-page-basic/cl-splash-page-basic.component';
import { ComponentLibraryTooltipComponent } from './tooltip/cl-tooltip.component';
import { ComponentLibraryOpenMenuOnHoverComponent } from './open-menu-on-hover/cl-open-menu-on-hover.component';
import { ComponentLibraryHorizontalBarChartComponent } from './horizontal-bar-chart/cl-horizontal-bar-chart.component';
import { AdminPortalPaths, AdminPortalTitles, ComponentLibraryRouteName } from '../admin-portal.constants';
import { ComponentLibraryComponent } from './component-library.component';
import { ComponentLibraryTagImageComponent } from './tag-image/cl-tag-image.component';

export const componentLibraryButton: Route = {
  path: ComponentLibraryPaths.button,
  component: ComponentLibraryButtonComponent,
  data: {
    stateName: ComponentLibraryButtonRouteName,
    title: ComponentLibraryTitles.button,
  }
};

export const componentLibraryFileUpload: Route = {
  path: ComponentLibraryPaths.fileUpload,
  component: ComponentLibraryFileUploadComponent,
  data: {
    stateName: ComponentLibraryFileUploadRouteName,
    title: ComponentLibraryTitles.fileUpload,
  }
};

export const componentLibraryReportWidgets: Route = {
  path: ComponentLibraryPaths.reportWidgets,
  component: ComponentLibraryReportWidgetsComponent,
  data: {
    stateName: ComponentLibraryReportWidgetsRouteName,
    title: ComponentLibraryTitles.reportWidgets,
  }
};

export const componentLibraryTabs: Route = {
  path: ComponentLibraryPaths.tabs,
  component: ComponentLibraryTabsComponent,
  data: {
    stateName: ComponentLibraryTabsRouteName,
    title: ComponentLibraryTitles.tabs,
  }
};

export const componentLibraryAccordion: Route = {
  path: ComponentLibraryPaths.accordion,
  component: ComponentLibraryAccordionComponent,
  data: {
    stateName: ComponentLibraryAccordionRouteName,
    title: ComponentLibraryTitles.accordion,
  }
};

export const componentLibraryBetaLabel: Route = {
  path: ComponentLibraryPaths.betaLabel,
  component: ComponentLibraryBetaLabelComponent,
  data: {
    stateName: ComponentLibraryBetaLabelRouteName,
    title: ComponentLibraryTitles.betaLabel,
  }
};

export const componentLibraryActionsMenu: Route = {
  path: ComponentLibraryPaths.actionsMenu,
  component: ComponentLibraryActionsMenuComponent,
  data: {
    stateName: ComponentLibraryActionsMenuRouteName,
    title: ComponentLibraryTitles.actionsMenu,
  }
};

export const componentLibraryBreadcrumb: Route = {
  path: ComponentLibraryPaths.breadcrumb,
  component: ComponentLibraryBreadcrumbComponent,
  data: {
    stateName: ComponentLibraryBreadcrumbRouteName,
    title: ComponentLibraryTitles.breadcrumb,
  }
};

export const componentLibraryEditDataLayerModal: Route = {
  path: ComponentLibraryPaths.editDataLayerModal,
  component: ComponentLibraryEditDataLayerModalComponent,
  data: {
    stateName: ComponentLibraryEditDataLayerModalRouteName,
    title: ComponentLibraryTitles.editDataLayerModal,
  }
};

export const componentLibraryFilterPill: Route = {
  path: ComponentLibraryPaths.filterPill,
  component: ComponentLibraryFilterPillComponent,
  data: {
    stateName: ComponentLibraryFilterPillRouteName,
    title: ComponentLibraryTitles.filterPill,
  }
};

export const componentLibraryFilterSpinner: Route = {
  path: ComponentLibraryPaths.filterSpinner,
  component: ComponentLibraryFilterSpinnerComponent,
  data: {
    stateName: ComponentLibraryFilterSpinnerRouteName,
    title: ComponentLibraryTitles.filterSpinner,
  }
};

export const componentLibraryChipManager: Route = {
  path: ComponentLibraryPaths.chipManager,
  component: ComponentLibraryChipManagerComponent,
  data: {
    stateName: ComponentLibraryChipManagerRouteName,
    title: ComponentLibraryTitles.chipManager,
  }
};

export const componentLibraryLabelManager: Route = {
  path: ComponentLibraryPaths.labelManager,
  component: ComponentLibraryLabelManagerComponent,
  data: {
    stateName: ComponentLibraryLabelManagerRouteName,
    title: ComponentLibraryTitles.labelManager,
  }
};

export const componentLibraryMenu: Route = {
  path: ComponentLibraryPaths.menu,
  component: ComponentLibraryMenuComponent,
  data: {
    stateName: ComponentLibraryMenuRouteName,
    title: ComponentLibraryTitles.menu,
  }
};

export const componentLibraryModal: Route = {
  path: ComponentLibraryPaths.modal,
  component: ComponentLibraryModalComponent,
  data: {
    stateName: ComponentLibraryModalRouteName,
    title: ComponentLibraryTitles.modal,
  }
};

export const componentLibraryProgressBar: Route = {
  path: ComponentLibraryPaths.progressBar,
  component: ComponentLibraryProgressBarComponent,
  data: {
    stateName: ComponentLibraryProgressBarRouteName,
    title: ComponentLibraryTitles.progressBar,
  }
};

export const componentLibrarySectionDivider: Route = {
  path: ComponentLibraryPaths.sectionDivider,
  component: ComponentLibrarySectionDividerComponent,
  data: {
    stateName: ComponentLibrarySectionDividerRouteName,
    title: ComponentLibraryTitles.sectionDivider,
  }
};

export const componentLibrarySelect: Route = {
  path: ComponentLibraryPaths.select,
  component: ComponentLibrarySelectComponent,
  data: {
    stateName: ComponentLibrarySelectRouteName,
    title: ComponentLibraryTitles.select,
  }
};

export const componentLibrarySelectCreate: Route = {
  path: ComponentLibraryPaths.selectCreate,
  component: ComponentLibrarySelectCreateComponent,
  data: {
    stateName: ComponentLibrarySelectCreateRouteName,
    title: ComponentLibraryTitles.selectCreate,
  }
};

export const componentLibrarySelectWithIcons: Route = {
  path: ComponentLibraryPaths.selectWithIcons,
  component: ComponentLibrarySelectWithIconsComponent,
  data: {
    stateName: ComponentLibrarySelectWithIconsRouteName,
    title: ComponentLibraryTitles.selectWithIcons,
  }
};

export const componentLibrarySnackbar: Route = {
  path: ComponentLibraryPaths.snackbar,
  component: ComponentLibrarySnackbarComponent,
  data: {
    stateName: ComponentLibrarySnackbarRouteName,
    title: ComponentLibraryTitles.snackbar,
  }
};

export const componentLibrarySortIndicator: Route = {
  path: ComponentLibraryPaths.sortIndicator,
  component: ComponentLibrarySortIndicatorComponent,
  data: {
    stateName: ComponentLibrarySortIndicatorRouteName,
    title: ComponentLibraryTitles.sortIndicator,
  }
};

export const componentLibrarySplashPageBasic: Route = {
  path: ComponentLibraryPaths.splashPageBasic,
  component: ComponentLibrarySplashPageBasicComponent,
  data: {
    stateName: ComponentLibrarySplashPageBasicRouteName,
    title: ComponentLibraryTitles.splashPageBasic,
  }
};

export const componentLibraryStandardsSelector: Route = {
  path: ComponentLibraryPaths.standardsSelector,
  component: ComponentLibraryStandardsSelectorComponent,
  data: {
    stateName: ComponentLibraryStandardsSelectorRouteName,
    title: ComponentLibraryTitles.standardsSelector,
  }
};

export const componentLibraryTooltip: Route = {
  path: ComponentLibraryPaths.tooltip,
  component: ComponentLibraryTooltipComponent,
  data: {
    stateName: ComponentLibraryTooltipRouteName,
    title: ComponentLibraryTitles.tooltip,
  }
};

export const componentLibraryOpenMenuOnHover: Route = {
  path: ComponentLibraryPaths.openMenuOnHover,
  component: ComponentLibraryOpenMenuOnHoverComponent,
  data: {
    stateName: ComponentLibraryOpenMenuOnHoverRouteName,
    title: ComponentLibraryTitles.openMenuOnHover,
  }
};

export const componentLibraryHorizontalBarChart: Route = {
  path: ComponentLibraryPaths.horizontalBarChart,
  component: ComponentLibraryHorizontalBarChartComponent,
  data: {
    stateName: ComponentLibraryHorizontalBarChartRouteName,
    title: ComponentLibraryTitles.horizontalBarChart,
  }
};

export const componentLibraryTagImage: Route = {
  path: ComponentLibraryPaths.tagImage,
  component: ComponentLibraryTagImageComponent,
  data: {
    stateName: ComponentLibraryTagImageRouteName,
    title: ComponentLibraryTitles.tagImage,
  }
};

export const componentLibrary: Route = {
  path: AdminPortalPaths.componentLibrary,
  component: ComponentLibraryComponent,
  data: {
    stateName: ComponentLibraryRouteName,
    title: AdminPortalTitles.componentLibrary,
  },
  children: [
    { path: '', pathMatch: 'full', redirectTo: componentLibraryButton.path },
    componentLibraryButton,
    componentLibraryFileUpload,
    componentLibraryReportWidgets,
    componentLibraryTabs,
    componentLibraryAccordion,
    componentLibraryBetaLabel,
    componentLibraryActionsMenu,
    componentLibraryBreadcrumb,
    componentLibraryEditDataLayerModal,
    componentLibraryFilterPill,
    componentLibraryFilterSpinner,
    componentLibraryChipManager,
    componentLibraryLabelManager,
    componentLibraryMenu,
    componentLibraryModal,
    componentLibraryProgressBar,
    componentLibrarySectionDivider,
    componentLibrarySelect,
    componentLibrarySelectCreate,
    componentLibrarySelectWithIcons,
    componentLibrarySnackbar,
    componentLibrarySortIndicator,
    componentLibrarySplashPageBasic,
    componentLibraryStandardsSelector,
    componentLibraryTooltip,
    componentLibraryOpenMenuOnHover,
    componentLibraryHorizontalBarChart,
    componentLibraryTagImage
  ]
};