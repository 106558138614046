import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAdminTagObj } from '../admin-tags.models';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'admin-tag',
  templateUrl: './admin-tag.component.html',
  styleUrls: ['./admin-tag.component.scss']
})
export class AdminTagComponent  {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;

  @Input() tag: IAdminTagObj;
  @Output() clicked: EventEmitter<IAdminTagObj> = new EventEmitter;

  click(tag: IAdminTagObj): void {
    tag.enabled = !tag.enabled;
    this.clicked.emit(tag);
  }
}
