<op-modal [rightFooterButtons]="rightFooterButtons" (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header
      [title]="title"
      [steps]="steps"
      [currentStep]="currentStep"
      (onClose)="closeModal()"
      (goToStep)="goToStep($event)">
    </op-modal-header>
  </ng-template>

  <ng-template #bodyTemplate>
    <mat-spinner *ngIf="showSpinner" [diameter]="100" [strokeWidth]="4" class="centered"></mat-spinner>

    <form [formGroup]="rorComparisonForm" class="flex-col">
      <ror-setup-form formControlName="setup" [allTags]="comparisonTags" [class.hidden]="currentStep !== 0" [submitted]="submitted"></ror-setup-form>

      <div class="apply flex-col" [class.hidden]="currentStep !== 1">
        <div class="content-header">Add One Or More Audits to This Plan</div>
        <audits-picker class="flex-col" formControlName="apply" [audits]="availableAudits"></audits-picker>
      </div>

      <div class="match-tags"  [class.hidden]="currentStep !== 2" >
        <div class="content-header">Specify Variables Used to Match Comparable Tags</div>
        <match-criteria-picker formControlName="matchTags" [tagOptionsMap]=matchTagsOptions></match-criteria-picker>
      </div>
    </form>
  </ng-template>
</op-modal>
