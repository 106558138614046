import { AngularNames, Names } from '@app/moonbeamConstants';
import { IOPSpinnerService } from '../ui/spinner/spinnerService';
import * as angular from 'angular';

export interface ISerializer<T> {
    serialize(json: any, origin?: string, url?: string): T;
  }

  export abstract class IApiService {
    abstract handleResponse<T>(promise: angular.IHttpPromise<any>, serializer?: ISerializer<T>, spinnerKey?: string): angular.IPromise<T>;
  }

  export interface IHALResponse {
    _embedded?: any;
  }

  export class ApiService extends IApiService {
    static $inject = [
      AngularNames.http,
      AngularNames.q,
      AngularNames.log,
      Names.Services.opSpinner
    ];

    constructor(private $http: angular.IHttpService,
                private $q: angular.IQService,
                private $log: angular.ILogService,
                private spinnerService?: IOPSpinnerService) {
      super();
    }

    private isSuccess(response: angular.IHttpPromiseCallbackArg<any>): boolean {
      return 200 <= response.status && response.status < 300;
    }

    private allowsBody(response: angular.IHttpPromiseCallbackArg<any>): boolean {
      return response.status != 204;
    }

    public handleResponse<T>(promise: angular.IHttpPromise<T>, serializer?: ISerializer<T>, spinnerKey?: string): angular.IPromise<T> {
      var spinnerState = this.spinnerService.getSpinnerState(spinnerKey);
      this.spinnerService.spin(spinnerState);

      return promise.then((x: angular.IHttpPromiseCallbackArg<T>) => {
        this.spinnerService.stop(spinnerState);

        if (this.isSuccess(x)) {
          if (this.allowsBody(x)) {
            const body = x.data;
            if (serializer) {
              return serializer.serialize(body);
            } else {
              return body;
            }
          } else {
            return undefined;
          }
        } else {
          return this.$q.reject({
            code: x.status,
            message: x.statusText
          });
        }

      }, (error) => {
        this.spinnerService.stop(spinnerState);
        return this.$q.reject({
          code: error.status,
          errorCode: error.data && error.data.errorCode,
          message: error.data && (error.data.message || error.data) || 'Unknown error',
          items: error.data && error.data.items
        });
      });
    }
  }
