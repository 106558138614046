<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header [title]="data.title || 'DATE DIFFERENCE BETWEEN MIN TO MAX'"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="modal-body">
      <div class="sizes-container">
        <div class="input-container">
          <label>{{data.minLabel || 'MINIMUM DATE'}}</label>
          <mat-form-field appearance="outline" class="no-hint-container">
            <mat-label>days</mat-label>
            <input matInput (keyup)="isValid()" [(ngModel)]="from.days"/>
            <button *ngIf="from.days" matSuffix mat-icon-button aria-label="Clear" (click)="from.days=''">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-container">
            <mat-label>hours</mat-label>
            <input matInput (keyup)="isValid()" [(ngModel)]="from.hours"/>
            <button *ngIf="from.hours" matSuffix mat-icon-button aria-label="Clear" (click)="from.hours=''">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-container">
            <mat-label>minutes</mat-label>
            <input matInput (keyup)="isValid()" [(ngModel)]="from.minutes"/>
            <button *ngIf="from.minutes" matSuffix mat-icon-button aria-label="Clear" (click)="from.minutes=''">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <mat-icon class="from-to-icon">open_in_full</mat-icon>
        <div class="input-container">
          <label>{{data.maxLabel || 'MAXIMUM DATE'}}</label>
          <mat-form-field appearance="outline" class="no-hint-container">
            <mat-label>days</mat-label>
            <input matInput (keyup)="isValid()" [(ngModel)]="to.days"/>
            <button *ngIf="to.days" matSuffix mat-icon-button aria-label="Clear" (click)="to.days=''">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-container">
            <mat-label>hours</mat-label>
            <input matInput (keyup)="isValid()" [(ngModel)]="to.hours"/>
            <button *ngIf="to.hours" matSuffix mat-icon-button aria-label="Clear" (click)="to.hours=''">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="outline" class="no-hint-container">
            <mat-label>minutes</mat-label>
            <input matInput (keyup)="isValid()" [(ngModel)]="to.minutes"/>
            <button *ngIf="to.minutes" matSuffix mat-icon-button aria-label="Clear" (click)="to.minutes=''">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <p class="validation-description"><mat-icon *ngIf="this.validationMessage">warning</mat-icon> {{this.validationMessage}}</p>
    </div>
    <ng-template #loadingTpl>
      <mat-spinner class="spinner" [diameter]="30" [strokeWidth]="4"></mat-spinner>
    </ng-template>
  </ng-template>
</op-modal>
