<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="Change Page Audit Limit for {{selectedCards.length}} Audits"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="edit-scan-limit">
      <form [formGroup]="scanLimitForm">
        <div class="form-input">
          <mat-label>Larger audits that run too frequently won't start until the previous run is completed.</mat-label>
          <mat-form-field appearance="outline" floatLabel="always" id="op_bulk_scan_limit" tabindex="1">
            <mat-label class="label-title">How Many Pages Should Be Scanned In Each Audit?</mat-label>
            <input matInput placeholder="Enter number of pages" id="scanLimit" type="number" min="1" step="1" formControlName="scanLimit">
            <mat-hint *ngIf="!scanLimitForm.invalid" class="field-hint">Maximum of {{maxScanLimit}} per audit</mat-hint>
            <mat-error *ngIf="scanLimitForm.errors?.required">This field is required</mat-error>
            <mat-error *ngIf="scanLimitForm.controls['scanLimit'].errors">Please enter a number between 1 and {{maxScanLimit}}</mat-error>
          </mat-form-field>
        </div>
      </form>
      <ng-container *ngIf="processCompleted">
        <div class="center-elements">
          <mat-spinner [diameter]="25" [strokeWidth]="4"></mat-spinner>
          <span>Setting limits...</span>
        </div>
      </ng-container>
      <ng-container *ngIf="error">
        <div class="center-elements">
          <p>There was a problem updating the scan limit.<br>Please refresh the page and try again.</p>
        </div>
      </ng-container>
    </div>
  </ng-template>
</op-modal>
