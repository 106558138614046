export enum EAlertModalType {
  /**
   * CreateFromLibrary is a mode for creating without any specified data at start.
   * No audit, no filters
   * */
  CreateFromLibrary,

  /**
   * CreateForAudit is a mode for creating alert for specific audit.
   * That audit will be preselected in the alert
   * */
  CreateForAudit,

  /*
  * CreatePrefilled is a mode for creating alert with some specified data
  * AuditId, Filters, Notification settings
  * It is used in QuickCreate modal
  * */
  CreatePrefilled,

  /**
   * ReadOnly is mode for preview the alert for read-only users
   * */
  ReadOnly,

  /**
   * Edit for editing from Alert Library
   * */
  Edit,

  /**
   * Duplicate for duplicating from Alert Library
   * */
  Duplicate,

  /**
   * Create with filters only
   * */
  CreateWithFilters
}

export enum EAlertStep {
  Logic,
  Notification,
  DataSources,
  Preview
}

export enum EAlertFilterType {
  None,
  V1,
  V2
}

// All statuses except TRIGGERED and NOT_TRIGGERED mean that alert/metric could not be computed
export enum EAlertResultStatus {
  Triggered = 'TRIGGERED',
  NotTriggered = 'NOT_TRIGGERED',
  UnknownError = 'UNKNOWN_ERROR',
  RunNotFound = 'RUN_NOT_FOUND',
  NoValue = 'NO_VALUE',
  PreviousRunNotFound = 'PREVIOUS_RUN_NOT_FOUND',
  PreviousRunNoValue = 'PREVIOUS_RUN_NO_VALUE',
  RelativeChangePreviousRunZeroValue = 'RELATIVE_CHANGE_PREVIOUS_RUN_ZERO_VALUE'
}

export enum EAlertNotificationPolicy {
  WhenNewlyTriggered = 'WHEN_NEWLY_TRIGGERED',
  AlwaysWhenTriggered = 'ALWAYS_WHEN_TRIGGERED'
}

export enum EQuickCreateMode {
  Create,
  Subscribe
}

export enum EQuickCreateTitle {
  Create = 'Create alert',
  Subscribe = 'Subscribe to existing alerts'
}

export enum EReportTypes {
  AuditSummary = 'Audit Summary',
  TagAndVariableRules = 'Tag & Variable Rules',
  PageSummary = 'Page Summary',
  TagInventory = 'Tag Inventory',
  TagHealth = 'Tag Health',
  VariableInventory = 'Variable Inventory',
  DuplicatesAndMultiples = 'Duplicates & Multiples',
  CookieInventory = 'Cookie Inventory',
  BrowserConsoleLogs = 'Browser Console Logs',
  PrivacyCookies = 'Privacy Cookies',
  PrivacyTags = 'Privacy Tags',
  RequestsDomainsAndGeos = 'Requests Domains & Geos',
  JavascriptFileChanges = 'Javascript File Changes',
  UsageReport = 'Usage Report',
}

export enum EAlertMenuContext {
  Audit,
  Journey,
  Library,
  Triggered,
  Usage
}
