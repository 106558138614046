import { Injectable, NgZone } from '@angular/core';
import {
  IReprocessConfirmationData,
  IReprocessConfirmationItem,
  ReprocessConfirmationSnackbarComponent
} from '@app/components/reprocess-confirmation-snackbar/reprocess-confirmation-snackbar.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { Subscription } from 'rxjs';
import { IModalService } from '@app/components/modals/modalService';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable()
export class ReprocessConfirmationSnackbarService {

  private timeoutMs: number = 60000;

  private activeSnackbar: MatSnackBarRef<any>;
  private afterAnyModalOpenedSubscription: Subscription;

  constructor(
    private snackbar: MatSnackBar,
    private opModalService: OpModalService,
    private modalService: IModalService,
    private router: Router,
    private zone: NgZone
  ) {
    this.router.events.subscribe(e => {
      this.closeIfPresent();
    });
    this.modalService.addOnShowHandler(() => {
      this.closeIfPresent();
    });
    this.afterAnyModalOpenedSubscription = this.opModalService.afterModalOpened().subscribe(() => {
      this.closeIfPresent();
    });
  }

  showReprocessRules(rules: IReprocessConfirmationItem[], onReprocessRules?: () => void): MatSnackBarRef<any> {
    this.closeIfPresent();
    let activeSnackbar: MatSnackBarRef<any>;
    // running in the zone ensures snackbar is positioned correctly when it appears
    // Source: https://stackoverflow.com/a/53502137
    this.zone.run(() => {
      activeSnackbar = this.snackbar.openFromComponent(ReprocessConfirmationSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: this.timeoutMs,
        panelClass: 'reprocess-confirmation-snackbar',
        data: {
          itemType: 'Rules may',
          items: rules,
          onReprocess: onReprocessRules
        } as IReprocessConfirmationData
      });
    });
    this.activeSnackbar = activeSnackbar;
    return activeSnackbar;
  }

  showReprocessConsentCategories(ccs: IReprocessConfirmationItem[], onReprocess?: () => void): MatSnackBarRef<any> {
    this.closeIfPresent();
    let activeSnackbar: MatSnackBarRef<any>;
    // running in the zone ensures snackbar is positioned correctly when it appears
    // Source: https://stackoverflow.com/a/53502137
    this.zone.run(() => {
      activeSnackbar = this.snackbar.openFromComponent(ReprocessConfirmationSnackbarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: this.timeoutMs,
        panelClass: 'reprocess-confirmation-snackbar',
        data: {
          itemType: 'Consent Categories',
          items: ccs,
          onReprocess: onReprocess
        } as IReprocessConfirmationData
      });
    });
    this.activeSnackbar = activeSnackbar;
    return activeSnackbar;
  }

  private closeIfPresent() {
    if (this.activeSnackbar) {
      this.activeSnackbar.dismiss();
      this.activeSnackbar = undefined;
    }
  }
}
