<header>
  <mat-icon class="success-icon">check_circle</mat-icon>

  <h1>Success!</h1>

  <button mat-flat-button
          class="close-button"
          (click)="closeSnackbar()">
    <mat-icon>close</mat-icon>
  </button>
</header>

<p>
  You have successfully created an audit
  <ng-container *ngIf="data.extras.disableLink; else link">
    <span class="button-link bold">({{ data.audit.name }}).</span>
  </ng-container>
  <ng-template #link>
    <a class="button-link" (click)="goToAudit()">({{ data.audit.name }}).</a>
  </ng-template>
  <span *ngIf="data.extras.text; else defaultText">{{ data.extras.text }}</span>

  <ng-template #defaultText>
    <span *ngIf="auditStatus === EAuditStatus.Paused">It is currently paused and must be manually run.</span>
    <span *ngIf="auditStatus === EAuditStatus.Running">It is running now and we will alert you when it is complete.</span>
    <span *ngIf="auditStatus === EAuditStatus.Scheduled">It is scheduled to run on {{ getRunDate() }}.</span>
    <span *ngIf="auditStatus === EAuditStatus.Unscheduled">It is currently unscheduled and must be manually run.</span>
  </ng-template>
</p>

<div class="buttons">
  <button mat-flat-button
          class="bordered-button"
          (click)="createAnotherAudit()"
          *ngIf="showCreateBtn">
    <mat-icon>add_circle_outline</mat-icon>
    Create another audit
  </button>

  <button mat-flat-button
          class="bordered-button"
          (click)="runAuditNow()"
          *ngIf="showRunNowBtn">
    <mat-icon>loop</mat-icon>
    Run Now
  </button>

  <button mat-flat-button
          class="bordered-button"
          (click)="closeSnackbar()"
          *ngIf="showCloseBtn">
    Close
  </button>
</div>
