import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-file-upload',
  templateUrl: './cl-file-upload.component.html',
  styleUrls: ['./cl-file-upload.component.scss']
})
export class ComponentLibraryFileUploadComponent {

}
