import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '../../core/services/api.service';
import { ReplaySubject, Observable } from 'rxjs';
import { IItemScore, IItemScoreResponse } from '.././ror-comparison-report.models';
import { shareReplay, map, tap, catchError } from 'rxjs/operators';
import { compareDatesAsc } from '@app/components/date/date.service';

@Injectable()
export class RorComparisonReportScoresService {

  private readonly basePath = environment.apiV3Url + 'comparisons';

  constructor(private apiService: ApiService) { }

  private _auditScoresSubject = new ReplaySubject<IItemScore[]>();
  auditScores$ = this._auditScoresSubject.asObservable().pipe(shareReplay(1));

  getResultScores(auditId: number): Observable<IItemScore[]> {
    return this.apiService.get(`${this.basePath}/audits/${auditId}/scores`).pipe(
      map((response: IItemScoreResponse[]) => response.map(IItemScoreResponse.toItemScore)),
      tap(scores => {
        const sortedScores = scores.sort((s1, s2) => compareDatesAsc(new Date(s1.finishedAt), new Date(s2.finishedAt)));
        this._auditScoresSubject.next(sortedScores);
      }),
      catchError(_ => {
        this._auditScoresSubject.next([]);
        return [];
      })
    );
  }

  calcAllTags(scores: IItemScore[]): IItemScore {
    const defaultScore = {
      runId: 0,
      comparisonId: 0,
      comparisonSnapshotId: 0,
      finishedAt: new Date(),
      averageScore: 0,
      tagParityScore: 0,
      variableParityScore: 0,
      variableValueParityScore: 0,
      evaluatedPages: 0,
      notComparedPages: 0
    };
    const allScores = scores.reduce((acc, score) => {
      acc.averageScore += score.averageScore;
      acc.tagParityScore += score.tagParityScore;
      acc.variableParityScore += score.variableParityScore;
      acc.variableValueParityScore += score.variableValueParityScore;
      acc.evaluatedPages += score.evaluatedPages;
      acc.notComparedPages += score.notComparedPages;
      return acc;
    }, defaultScore);

    allScores.averageScore = allScores.averageScore / scores.length;
    allScores.tagParityScore = allScores.tagParityScore / scores.length;
    allScores.variableParityScore = allScores.variableParityScore / scores.length;
    allScores.variableValueParityScore = allScores.variableValueParityScore / scores.length;

    return allScores;
  }

}
