import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IKeyValue } from '@app/moonbeamModels';
import { IOpTab } from '@app/components/shared/components/op-tabs/op-tabs.models';
import { editAccountTabs } from './edit-account.constants';
import { forkJoin, from } from 'rxjs';
import {
  AdminAccountsService,
  ERetentionPolicyName,
  IAdminTag,
  IProductLimits,
  ISsoConfig,
  IV3AdminAccount
} from '../../admin-accounts.service';
import { IAccountDetailsFormValue } from './account-details/account-details.models';
import { IAccountTag, TagsService } from '@app/components/tags/tagsService';
import { IAdminTagsEmitter } from './admin-tags/admin-tags.models';
import { IFeaturesTableRow } from './admin-features/admin-features.component';
import { Features } from '@app/moonbeamConstants';
import { AccountsService } from '@app/components/account/account.service';
import {
  IAdminAccount,
  IAdminAccountFeature,
  IScriptServicesConfig
} from '@app/components/admin-portal/manage-accounts/manage-accounts.constants';
import { switchMap } from 'rxjs/operators';

export interface IEditAccountData {
  account: IAdminAccount;
  statuses: IKeyValue<number, string>[];
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit {

  loading: boolean = true;
  tabs: IOpTab[] = editAccountTabs;
  activeTab: string = this.tabs[0].path;
  accountId: number;
  accountV2: IAdminAccount;
  accountV3: IV3AdminAccount;
  accountName: string;
  statuses: IKeyValue<number, string>[] = [];
  tags: IAccountTag[];
  enabledTags: IAdminTag[];
  features: IAdminAccountFeature[];
  extraFeatures: IFeaturesTableRow[];
  productLimits: IProductLimits;
  webJourneyFrequencies: IKeyValue<number, string>[];
  scriptServicesConfig: IScriptServicesConfig;
  sso: ISsoConfig;
  ssoCreateMode = true;

  constructor(
    private dialogRef: MatDialogRef<EditAccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEditAccountData,
    private adminAccountsService: AdminAccountsService,
    private tagsService: TagsService,
    private accountsService: AccountsService,
  ) {
    this.accountV2 = data.account;
    this.accountId = this.accountV2.id;
    this.accountName = this.accountV2.company;
    this.statuses = data.statuses;
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    // Left this out of the forkJoin because the error is state used to determine if we're creating a new authentication
    // config or have an existing one to update.
    this.adminAccountsService.getAuthConfig(this.accountId).subscribe(sso => {
      this.sso = sso;
      this.ssoCreateMode = true;
    }, err => {
      this.sso = {
        authenticationType: 'Password',
        createNewUser: false
      };

      this.ssoCreateMode = true;
    });

    this.accountsService.getComparisonAccountSettings(this.accountId).then(
      limits => {
        this.productLimits = limits as IProductLimits;
      },
        err => {
        console.log('Unable to retrieve limits.');
        this.productLimits = {maxComparisonTags: -1};
      }
    );

    forkJoin([
      this.adminAccountsService.getAccountById(this.accountId),
      this.adminAccountsService.getTagsForAccount(this.accountId, false, null),
      from(this.tagsService.getAllTags()),
      this.adminAccountsService.getFeaturesForAccount(this.accountId),
      this.adminAccountsService.getFrequencies(),
      this.adminAccountsService.getScriptServicesConfig(this.accountId),
    ])
    .subscribe(([account, enabledTags, tags, features, frequencies, config]) => {
      // ~* weird issue *~
      // we have to cast these because apparently the typings for forkJoin define a
      // maximum of 6 parameters and we start getting type errors without casting
      //
      // more info here: https://tinyurl.com/373kwubu
      this.accountV3 = account as IV3AdminAccount;
      this.enabledTags = enabledTags as IAdminTag[];
      this.tags = tags as IAccountTag[];
      this.features = this.filterFeaturesList(features as IAdminAccountFeature[]);
      this.webJourneyFrequencies = frequencies as IKeyValue<number, string>[];
      this.scriptServicesConfig = config as IScriptServicesConfig;
      this.generateExtraFeatures();

      // tell loading spinner to go away
      this.loading = false;
    });
  }

  private generateExtraFeatures(): void {
    const showDataWareHouse = !this.accountV3.retentionPolicy;

    this.extraFeatures = [
      {
        name: 'customProxySupport',
        displayName: 'Custom Proxy',
        enabled: this.accountV2.customProxySupport
      },
      {
        name: 'strongPasswordSupport',
        displayName: 'Strong Password',
        enabled: this.accountV2.strongPasswordSupport
      },
      ...(showDataWareHouse ? [{
        name: 'dataWareHouse',
        displayName: 'Enhanced Data Retention',
        enabled: this.accountV2.dataWareHouse
      }] : []),
      {
        name: 'vpn',
        displayName: 'Virtual Private Network (VPN)',
        enabled: this.accountV2.vpn.vpnSupport
      }
    ];
  }

  tabClicked(path: string): void {
    this.activeTab = path;
  }

  accountTabUpdated(value: IAccountDetailsFormValue): void {
    this.accountV2 = {
      ...this.accountV2,
      name: value.name,
      status: value.status,
      accountType: value.type,
      notes: value.notes
    };

    this.updateAccountV2(this.accountV2);
  }

  saveTags(update: IAdminTagsEmitter): void {
    if (update.deleted.length) {
      this.adminAccountsService.deleteAssignedTags(this.accountId, update.deleted).subscribe(() => {
        if (update.selection.length) {
          this.adminAccountsService.saveAssignedTags(this.accountId, update.selection).subscribe(() => {
            this.reloadEnabledTags();
          });
        }
      });
    } else {
      this.adminAccountsService.saveAssignedTags(this.accountId, update.selection).subscribe(() => {
        this.reloadEnabledTags();
      });
    }
  }

  private reloadEnabledTags() {
    this.adminAccountsService.getTagsForAccount(this.accountId, false, null).subscribe(enabledTags => {
      this.enabledTags = enabledTags;
    });
  }

  private filterFeaturesList(features: IAdminAccountFeature[]): IAdminAccountFeature[] {
    const usableFeatures = Features.values
      .filter(feature => !Features.blacklisted.includes(feature.name)) // features frontend knows about
      .map(feature => feature.name);

    // filter API response to just what FE knows about
    return features.filter(feature => usableFeatures.indexOf(feature.name) > -1);
  }

  featuresUpdated(selection: IFeaturesTableRow[]): void {
    const { features, extraFeatures } = this.sortFeatures(selection);
    this.handleFeaturesUpdated(features);
    this.handleExtraFeaturesUpdated(extraFeatures);
  }

  private sortFeatures(selection: IFeaturesTableRow[]): { features: IFeaturesTableRow[], extraFeatures: IFeaturesTableRow[] } {
    const extraFeaturesNames = this.extraFeatures.map(feature => feature.name);
    const features = [];
    const extraFeatures = [];

    selection.forEach((item: IFeaturesTableRow) => {
      extraFeaturesNames.includes(item.name)
        ? extraFeatures.push(item)
        : features.push(item);
    });

    return { features, extraFeatures };
  }

  private handleFeaturesUpdated(features: IFeaturesTableRow[]): void {
    const updatedFeatures = this.featuresObjToArr(features);

    // get added features
    const featuresAdded = this.features
    .filter((feature: IAdminAccountFeature) => updatedFeatures[feature.name])
    .map((feature: IAdminAccountFeature) => ({ name: feature.name }));

    // get removed features
    const featuresRemoved = this.features
      .filter((feature: IAdminAccountFeature) => !updatedFeatures[feature.name])
      .map((feature: IAdminAccountFeature) => ({ name: feature.name }));

    // update our main features obj
    this.features = this.features.map((feature: IAdminAccountFeature) => {
      feature.enabled = updatedFeatures[feature.name];
      return feature;
    });

    this.adminAccountsService
      .enableFeaturesForAccount(this.accountId, featuresAdded)
      .subscribe(() => {});

    if (featuresRemoved.length) {
      this.adminAccountsService
        .disableFeaturesForAccount(this.accountId, featuresRemoved)
        .subscribe(() => {});
    }
  }

  private handleExtraFeaturesUpdated(extraFeatures: IFeaturesTableRow[]): void {
    const updatedFeatures = this.featuresObjToArr(extraFeatures);
    this.accountV2 = { ...this.accountV2, ...updatedFeatures };
    this.updateAccountV2(this.accountV2);
  }

  private featuresObjToArr(features: IFeaturesTableRow[]) {
    const featureObj = {};

    features.forEach((feature: IFeaturesTableRow) => {
      if (feature.name !== 'vpn') {
        featureObj[feature.name] = feature.enabled;
      } else {
        featureObj[feature.name] = {
          vpnCredentials: '',
          vpnSupport: !!feature.enabled
        };
      }

    });

    return featureObj;
  }

  handleRetentionPolicyUpdated(value: ERetentionPolicyName): void {
    this.adminAccountsService.updateAccountRetentionPolicy(this.accountId, value)
      .pipe(switchMap(() => this.adminAccountsService.getAccountById(this.accountId)))
      .subscribe(account => this.accountV3 = account as IV3AdminAccount);
  }

  productLimitsUpdated(value: any): void {
    // update account object
    this.accountV2 = {
      ...this.accountV2,
      concurrentAudits: +value.concurrentAudits,
      concurrentJourneys: +value.concurrentJourneys,
      domains: value.domains,
      maxDomains: +value.maxDomains,
      maxMobileApps: 0,
      maxUsers: +value.maxUsers,
      vpn: this.accountV2.vpn
    };

    // update max tags for comparisons field
    this.accountsService.updateComparisonAccountSettings(value.maxComparisonTags, this.accountId)
      .then(() => this.productLimits.maxComparisonTags = value.maxComparisonTags);

    // update account
    this.updateAccountV2(this.accountV2);

    // update script services config
    if (value.journeyFixes !== '-' && value.maxMonitoredJourneys !== '-') {
      this.adminAccountsService.updateScriptServicesConfig(this.accountId, {
        journeyFixes: +value.journeyFixes,
        maxMonitoredJourneys: +value.maxMonitoredJourneys
      }).subscribe(() => {
        this.scriptServicesConfig.journeyFixes = +value.journeyFixes;
        this.scriptServicesConfig.maxMonitoredJourneys = +value.maxMonitoredJourneys;
      });
    }
  }

  private updateAccountV2(accountV2: IAdminAccount): void {
    if (accountV2.hasOwnProperty('company')) delete accountV2.company;

    this.adminAccountsService
      .updateAccount(accountV2)
      .subscribe((account: IAdminAccount) => {
        this.accountV2 = account;
      });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
