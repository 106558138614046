import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { EUserStep } from '@app/components/create-edit-user-modal/create-edit-user-modal.constants';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IUser } from '@app/moonbeamModels';
import { ISimpleTableColumn, ISimpleTableItemsState } from '@app/components/shared/components/selectable-table/simple-table/simple-table.models';
import { IUserFolderAccessTableItem } from './user-folder-access.models';
import { ReportCardsApiService } from '@app/components/manage/shared/services/report-cards-api/report-cards-api.service';
import { forkJoin } from 'rxjs';
import { IFoldersApiService } from '@app/components/folder/foldersApiService';
import { IDomainsService } from '@app/components/domains/domainsService';
import { ESimpleTableColumnType } from '@app/components/shared/components/selectable-table/simple-table/simple-table.enums';
import { DefaultUserFolderAccessAssignmentsPagination } from './user-folder-access.constants';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { ISelectableTableSelectionChange } from '@app/components/shared/components/selectable-table/selectable-table.models';
import { Sort } from '@angular/material/sort';
import { AdminPermissions } from '@app/authUtils';

@Component({
  selector: 'op-user-folder-access',
  templateUrl: './user-folder-access.component.html',
  styleUrls: ['./user-folder-access.component.scss']
})
export class UserFolderAccessComponent {

  dataSourcesState: ISimpleTableItemsState<IUserFolderAccessTableItem>;
  filteredDataSourcesState: ISimpleTableItemsState<IUserFolderAccessTableItem>;
  selectedItems: IUserFolderAccessTableItem[] = [];

  @Input() currentStep: EUserStep;
  @Input() formGroup: UntypedFormGroup;
  @Input() editMode: boolean;
  @Input() currentUser: IUser;
  @Input() userBeingEdited: IUser;
  @Input() permissionLevel: number;

  @Output() selectedFoldersCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() stepThreeValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly AdminPermissions = AdminPermissions;

  heading: string = '';
  editingSelf: boolean = false;
  pagination = { ...DefaultUserFolderAccessAssignmentsPagination };
  sorting = { sortBy: 'name', sortDesc: false };
  disableTableSelection: boolean = false;

  readonly columns: ISimpleTableColumn<IUserFolderAccessTableItem>[] = [{
    propName: 'name',
    sortKey: 'name',
    title: 'Folder Name',
    type: ESimpleTableColumnType.Text
  }, {
    propName: 'auditCount',
    popoverPropName: 'audits',
    title: 'Audits',
    type: ESimpleTableColumnType.Popover,
    icon: 'explore'
  }, {
    propName: 'webJourneyCount',
    popoverPropName: 'webJourneys',
    title: 'Web Journeys',
    type: ESimpleTableColumnType.Popover,
    icon: 'map'
  }, {
    propName: 'created',
    sortKey: 'created',
    title: 'Created',
    type: ESimpleTableColumnType.Text
  }, {
    propName: 'createdBy',
    sortKey: 'createdBy',
    title: 'Created By',
    type: ESimpleTableColumnType.Text
  }];

  constructor(
    private folderService: IFoldersApiService,
    private domainsService: IDomainsService,
    private reportCardsApiService: ReportCardsApiService,
    private dateService: DateService,
  ) {}

  ngOnInit(): void {
    this.setHeading();
    this.fetchDataSources();
    this.formGroup.valueChanges.subscribe(() => {
      this.initializeSelectedItems();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentUser?.currentValue && changes.userBeingEdited?.currentValue) {
      this.editingSelf = this.currentUser.id === this.userBeingEdited.id;
    }

    if (changes.permissionLevel?.currentValue !== undefined) {
      this.setHeading();
      this.initializeSelectedItems();
      this.disableTableSelection = this.userIsAdminOrAbove();
    }
  }

  setHeading(): void {
    if (this.userIsAdminOrAbove()) {
      this.heading = this.editingSelf
        ? 'As an admin you have rights to all folders.'
        : 'Admins automatically have rights to all folders.';
    } else {
      this.heading = this.editingSelf
        ? 'Which folders (Audits and Web Journeys) should you have access to?'
        : 'Which folders (Audits and Web Journeys) should this user have access to?';
    }
  }

  handleFiltering(searchTerm: string): void {
    if (!searchTerm) {
      // If the search term is empty, reset to original data
      this.filteredDataSourcesState = { ...this.dataSourcesState };
    } else {
      // Filter the items based on the search term
      const filteredItems = this.dataSourcesState.items.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      this.filteredDataSourcesState = { ...this.dataSourcesState, items: filteredItems };
    }

    // Update pagination after filtering
    this.updatePagination();
  }

  private fetchDataSources() {
    this.dataSourcesState = { items: [], loading: true };

    forkJoin([
      this.folderService.getFoldersObservable(true, true),
      this.domainsService.getDomainsObservable(),
      this.reportCardsApiService.getAudits(),
      this.reportCardsApiService.getWebJourneys()
    ]).subscribe(([folders, domains, audits, webJourneys]) => {
      const folderMap = new Map<number, IUserFolderAccessTableItem>();

      // Step 1: Initialize folderMap with folders
      folders.forEach(folder => {
        folderMap.set(folder.id, {
          id: folder.id,
          name: folder.name,
          auditCount: 0,
          audits: [],
          webJourneyCount: 0,
          webJourneys: [],
          created: this.dateService.formatDate(new Date(folder.createdAt), EDateFormats.dateTwentyOne),
          createdBy: folder.createdByUserName
        });
      });

      // Step 2: Group domains under their respective folders
      const domainMap = new Map<number, number>(); // Maps domainId to folderId
      domains.forEach(domain => {
        domainMap.set(domain.id, domain.folderId);
      });

      // Step 3: Group audits and web journeys under their respective folders
      audits.forEach(audit => {
        const folderId = domainMap.get(audit.domainId);
        if (folderId && folderMap.has(folderId)) {
          const folderItem = folderMap.get(folderId)!;
          folderItem.audits.push(audit.name);
          folderItem.auditCount++;
        }
      });

      webJourneys.forEach(journey => {
        const folderId = domainMap.get(journey.domainId);
        if (folderId && folderMap.has(folderId)) {
          const folderItem = folderMap.get(folderId)!;
          folderItem.webJourneys.push(journey.name);
          folderItem.webJourneyCount++;
        }
      });

      // Step 4: Convert the folderMap to an array and sort alphabetically by name
      const sortedItems = Array.from(folderMap.values()).sort((a, b) =>
        (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1
      );

      // Step 5: Set the organized and sorted data into dataSourcesState
      this.dataSourcesState.items = sortedItems;
      this.dataSourcesState.loading = false;
      this.dataSourcesState = { ...this.dataSourcesState };

      // Step 6: Initialize selected items if in edit mode
      if (this.editMode) {
        this.initializeSelectedItems();
      }

      // Step 7: Update the filtered data source initially to display all sorted items
      this.filteredDataSourcesState = { ...this.dataSourcesState, items: sortedItems };

      // Step 8: Update pagination
      this.updatePagination();
    });
  }

  private initializeSelectedItems() {
    if (!this.dataSourcesState) return;

    // Check if the user being edited is an admin
    // If admin, select all folders
    if (this.userIsAdminOrAbove()) {
      this.selectedItems = this.dataSourcesState.items;
      this.selectedFoldersCount.emit(this.selectedItems.length);
      this.stepThreeValid.emit(this.selectedItems.length > 0);
      return;
    }

    const selectedIds = this.itemsControl.value as number[];
    this.selectedItems = this.dataSourcesState?.items.filter(item => selectedIds.includes(item.id));

    // Emit the count of selected folders
    this.selectedFoldersCount.emit(this.selectedItems.length);
    this.stepThreeValid.emit(this.selectedItems.length > 0);
  }

  selectionChanged({ added, removed }: ISelectableTableSelectionChange<IUserFolderAccessTableItem>) {
    // Update selectedItems array
    if (removed && removed.length > 0) {
      this.selectedItems = this.selectedItems.filter(item => !removed.find(r => r.id === item.id));
    }

    if (added && added.length > 0) {
      this.selectedItems = [...this.selectedItems, ...added];
    }

    // Update itemsControl with only the IDs of the selected items
    const selectedIds = this.selectedItems.map(item => item.id);
    this.itemsControl.setValue(selectedIds);

    // Emit the count of selected folders
    this.selectedFoldersCount.emit(this.selectedItems.length);
  }

  paginationChanged(pageIndex: number) {
    this.pagination.currentPageNumber = pageIndex;
  }

  private updatePagination() {
    const totalCount = this.filteredDataSourcesState.items.length;
    const totalPageCount = Math.ceil(totalCount / this.pagination.pageSize);
    const currentPageSize = Math.min(this.pagination.pageSize, totalCount - (this.pagination.currentPageNumber * this.pagination.pageSize));

    this.pagination = {
      ...DefaultUserFolderAccessAssignmentsPagination,
      totalCount,
      totalPageCount,
      currentPageSize,
      currentPageNumber: this.pagination.currentPageNumber
    };
  }

  userIsAdminOrAbove(): boolean {
    return this.permissionLevel >= this.AdminPermissions;
  }

  get itemsControl(): UntypedFormControl {
    return this.formGroup.get('items') as UntypedFormControl;
  }
}
