export interface INatualCompareService {
    compare(a: string, b: string): number;
  }

  export class NaturalCompareService implements INatualCompareService {
    compare(a: string, b: string): number {
      const evaluate = /(_+)|([0-9]+)|([^0-9_]+)/g;
      var ax = [], bx = [];
      a.replace(evaluate, function (_, $1, $2, $3): any {
        ax.push([$1 || '0', $2 || Infinity, $3 || '']);
      });
      b.replace(evaluate, function (_, $1, $2, $3): any {
        bx.push([$1 || '0', $2 || Infinity, $3 || '']);
      });

      while (ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = an[0].localeCompare(bn[0]) ||
          (an[1] - bn[1]) ||
          an[2].localeCompare(bn[2]);
        if (nn) return nn;
      }

      return ax.length - bx.length;
    }

}

