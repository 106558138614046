import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAdvancedConfigs } from '@app/components/shared/components/op-chip-selector/op-chip-selector.models';
import { IDataSource } from '@app/components/shared/services/data-sources/data-sources.models';
import { IButton } from '@app/models/commons';
import { hasOwnProperty } from 'fast-json-patch/module/helpers';
import { forkJoin, of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { catchError } from 'rxjs/operators';
import { ImportUploadEmpty, ImportUploadNotCSV, ImportUploadOverOneMB } from '../consent-categories.constants';
import { EConsentCategoryType, IConsentCategories, IConsentCategory } from '../consent-categories.models';
import { ConsentCategoriesService } from '../consent-categories.service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import {
  ICreateFolderAndSubFolderModalData
} from '@app/components/folder-and-sub-folder-modal/folder-and-sub-folder-modal.models';

type TableName = 'newCookies' | 'existingCookies'

interface IUploadedCSVResults {
  fileId: string;
  results: IResults[]
}

interface IResults {
  consentCategoryId?: number;
  consentCategoryName: string;
  noOfNewCookies: number;
  labels: IResultsLabels[]
}

interface IResultsLabels {
  id: number;
  name: string
}

enum ImportModalStep {
  IMPORT = 'import',
  ASSIGN = 'assign'
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'import-categorized-cookies-modal',
  templateUrl: './import-categorized-cookies-modal.component.html',
  styleUrls: ['./import-categorized-cookies-modal.component.scss']
})
export class ImportCategorizedCookiesModalComponent implements OnInit {
  importSuccessMessage = 'Your cookies have successfully imported into the specified consent categories!';
  title = 'Import Categorized Cookies';
  dropZoneLabel = 'Drag and drop a CSV file containing your CMP export data here.'
  templateDownloadLink = `https://observepoint-moonbeam-prod-aws.s3.us-west-2.amazonaws.com/uploaded_files/consent-categories/templates/ObservePoint-Cookie-Consent-Category-Template.csv`

  showInstructions: boolean = false;
  showUpload: boolean = true;

  instructions: string[] = [
    'If either the cookie name or cookie domain values for any row are empty we will import and allow any value in that field',
    'If both cookie name and domain are empty the row will be ignored',
    'If category is empty that row will be ignored',
    'Only CSV files are accepted, column headers need to match exactly what\'s in the downloaded template - otherwise the entire \n file will fail to process',
    'Any additional columns will be ignored',
    'Maximum file size of 1.0 mb'
  ]

  steps: string[] = [ImportModalStep.IMPORT, ImportModalStep.ASSIGN]
  currentStep: ImportModalStep = ImportModalStep.IMPORT

  hoveringOverDropzone: boolean = false;
  fileDropped: boolean = false;
  uploadedFile: File;
  errorMsg: string;
  importErrors: string[] = []

  leftFooterButtons: IButton[] = [{
    label: 'CANCEL',
    action: () => this.closeModal(),
    transparent: true,
  }];

  rightFooterButtons: IButton[] = [
    {
      label: 'SAVE & CLOSE',
      action: this.saveAndClose.bind(this),
      disabled: true,
      primary: true,
      hidden: false
    },
    {
      label: 'SAVE & ASSIGN TO DATA SETS',
      action: this.saveAndAssignDataSets.bind(this),
      disabled: true,
      primary: true,
      hidden: false
    },
    {
      label: 'DONE & ASSIGN',
      action: this.assignToDataSources.bind(this),
      disabled: true,
      primary: true,
      hidden: true
    }
  ];

  displayedColumns: string[] = ['consentCategoryName', 'noOfNewCookies', 'labels']

  fileParseSuccess: boolean = false;
  newCookies: any[] = []
  existingCookies: any[] = []

  allLabels: ILabel[] = []
  chipsConfig: IAdvancedConfigs = {
    collapsible: true,
    lines: 1,
    readOnly: false
  }
  topXNewCookies: number = 10
  uploadedCSVResults: IUploadedCSVResults
  uploadedFileID: string

  /** for cc-assign-select-data-source inputs */
  importedCCList: IConsentCategory[] = [];
  importedCCNames: string;
  importedCCType: EConsentCategoryType = EConsentCategoryType.APPROVED
  selectedDataSources: IDataSource[] = []

  auditId: number;

  constructor(
    public dialogRef: MatDialogRef<ImportCategorizedCookiesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private ccService: ConsentCategoriesService,
    private labelService: LabelService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.getLabels();
    if (this.data?.auditId) this.auditId = this.data?.auditId;
  }

  getLabels() {
    this.labelService.getLabels()
      .subscribe(labels => this.allLabels = labels)
  }

  toggleInstructions() {
    this.showInstructions = !this.showInstructions;
  }

  onFileDrop(files: FileList) {
    this.errorMsg = null;
    this.hoveringOverDropzone = false;

    if (files.length === 0) {
      return;
    }

    const file = files[0];
    this.uploadedFile = file;
    this.newCookies = [];
    this.existingCookies = [];

    const fileExtension = file.name.split('.').pop();

    if (fileExtension === 'csv') {
      if (file.size <= 44) { // 44b is headers only
        this.importErrorHandler(ImportUploadEmpty);
        this.uploadedFile = null;
        this.fileDropped = false;
      }
      else if (file.size <= 1000000) { // 1mb
        this.fileDropped = true;
        this.parseCSVData(file);
      } else {
        this.importErrorHandler(ImportUploadOverOneMB);
        this.uploadedFile = null;
        this.fileDropped = false;
      }
    } else {
      this.importErrorHandler(ImportUploadNotCSV);
      this.fileDropped = false;
      this.uploadedFile = null;
    }
  }

  parseCSVData(file: File) {

    this.ccService.uploadCSV(file).subscribe((data: IUploadedCSVResults) => {

      this.importErrors = []
      this.uploadedCSVResults = data
      this.uploadedFileID = data.fileId


      data.results.forEach((r => {
        if (hasOwnProperty(r, 'consentCategoryId') && r.consentCategoryId !== null) {
          this.existingCookies = [...this.existingCookies, r]
        } else {
          this.newCookies = [...this.newCookies, r]
        }
      }))
      this.fileParseSuccess = true;
      this.rightFooterButtons.forEach(b => { b.disabled = false });
      // manually triggered change detection solves https://observepoint.atlassian.net/browse/WORK-19784
      this.ref.detectChanges();
    },
      error => {
        this.importErrorHandler(error)
      })

  }

  onRemoveChip(removedLabel: { id: number, name: string }, cc, table: TableName) {
    const newLabelIds = cc.labels.map((label: { id: number, name: string }) => label.id);
    const oldLabelIds = [...newLabelIds, removedLabel.id];

  }

  onSelectChip(newLabel: { id: number, name: string }, cc, table: TableName) {
    const oldLabelIds = cc.labels.map((label: { id: number, name: string }) => label.id);
    const newLabelIds = [...oldLabelIds, newLabel.id];
    cc.labels.push(newLabel);
  }

  createLabel(labelName: string, cc, tableName: TableName) {
    this.labelService.createLabel(labelName).subscribe((label: ILabel) => {
      this.allLabels = [...this.allLabels, label]
      this.onSelectChip(label, cc, tableName)
    })
  }

  saveAndClose() {
    this.saveImportedData().subscribe(result => {
      const snackbarDataset = this.makeImportTotalSnackbarDataset();

      if (result) {
        this.dialogRef.close(snackbarDataset);
      }
    },
      error => this.importErrorHandler(error));
  }

  closeModal() {
    this.dialogRef.close();
  }

  saveAndAssignDataSets() {
    this.saveImportedData().subscribe((result: IConsentCategory[]) => {
      if (result) {
        this.currentStep = ImportModalStep.ASSIGN;
        this.leftFooterButtons[0].label = `SKIP FOR NOW, DON'T ASSIGN`;  // update cancel button text
        this.rightFooterButtons[0].hidden = true; // save & close button
        this.rightFooterButtons[1].hidden = true; // save & assign to dataSets button
        this.rightFooterButtons[2].hidden = false; // unhide DONE button
        this.rightFooterButtons[2].disabled = true; // make sure DONE button is still disabled

        this.importedCCList = result;
        this.importedCCNames = result.map(r => r.name).join(', ');

        const snackbarDataset = { importedCCList: this.importedCCList };
        this.dialogRef.close(snackbarDataset);
      }
    },
      error => this.importErrorHandler(error));
  }

  saveImportedData() {
    const consentCategories = [...this.existingCookies, ...this.newCookies];
    this.importedCCList = consentCategories.map(cc => {
      cc.id = cc.consentCategoryId;
      return cc;
    });

    const data = {
      fileId: this.uploadedFileID,
      consentCategories,
      importedCCList: this.importedCCList,
    };

    data.consentCategories.forEach((cc: any) => {
      cc.labels = cc.labels.map(label => label.id);
      if (hasOwnProperty(cc, 'consentCategoryId')) {
        delete cc.consentCategoryName;
      }
    });

    return this.ccService.saveImportedCategorizedCookies(data);
  }

  importErrorHandler(error) {
    this.importErrors = []

    if (typeof error === 'string') {
      this.importErrors.push(error)
    }
    else if (error?.errorCode?.message.split(';').length <= 1) {
      this.importErrors.push(error?.errorCode.message)
    }
    else {
      let errorMsgArray = error?.errorCode.message.split(';')
      this.importErrors = errorMsgArray.slice(1, errorMsgArray.length)
      this.uploadedFile = null;
      this.fileDropped = false;
    }
  }

  assignToDataSources() {

    const assignedConsentCategories = this.selectedDataSources.map((dataSource: IDataSource) => {
      return this.ccService
        .getConsentCategoriesAssignedToAudit(dataSource.itemId)
        .pipe(catchError(() => of({})));
    });

    forkJoin(assignedConsentCategories).subscribe((res: IConsentCategories[][]) => {
      const patchRequests = this.selectedDataSources.map((dataSource: IDataSource, index: number) => {
        const oldConsentCategoryIDs = res[index].map(cc => cc.id);
        const newConsentCategoryIDs = this.importedCCList.map(cc => cc.id);

        return this.ccService
          .patchWebAuditCcs(dataSource.itemId, oldConsentCategoryIDs, newConsentCategoryIDs)
          .pipe(catchError(() => of({})));
      });

      forkJoin(patchRequests).subscribe(res => {
        const importedSnackbarData = {
          ...this.makeImportTotalSnackbarDataset(),
          ccCount: this.importedCCList.length,
          itemCount: this.selectedDataSources.length
        };

        this.dialogRef.close({ importedSnackbarData });
      });
    });
  }

  ccSelectionChanged(event) {
    this.rightFooterButtons[2].disabled = event.length > 0 ? false : true
    this.selectedDataSources = event
  }

  makeImportTotalSnackbarDataset() {
    const totalNewCategories = this.newCookies.length
    const totalExistingCategories = this.existingCookies.length
    const topXNew = this.newCookies
      .sort((a, b) => b.noOfNewCookies - a.noOfNewCookies)
      .slice(0, this.topXNewCookies)
      .map(cc => cc.consentCategoryName)
    const numAdditional = this.newCookies.length > this.topXNewCookies ? this.newCookies.length - 10 : 0
    const totalNewCookies = this.newCookies
      .reduce((acc, cur) => {
        return acc += cur.noOfNewCookies
      }, 0)
    const totalExistingCookies = this.existingCookies
      .reduce((acc, cur) => {
        return acc += cur.noOfNewCookies
      }, 0)

    return {
      topXNew,
      numAdditional,
      totalNewCookies,
      totalExistingCookies,
      totalNewCategories,
      totalExistingCategories,
      importedCCList: this.importedCCList
    };
  }
}
