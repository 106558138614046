import {
  EAuditReportFilterTypes
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import {
  IHorizontalBarChartTitle
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.models';
import {
  CommonReportsPagesFiltersConfig,
  CommonReportsPagesTableColumns
} from '@app/components/audit-reports/reports/general-reports.constants';
import {
  ISwitchableMenuItems
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

export enum TagHealthTrendNames {
  AVG_TAG_LOAD_TIME = 'average_tag_load_time',
  AVG_TAG_REQUEST_SIZE = 'average_tag_request_size',
  TAG_TOTAL_REQUEST = 'tag_requests',
  SLOW_TAG_PERCENTAGE = 'slow_tag_percentage',
  BROKEN_TAG_PERCENTAGE = 'broken_tag_percentage',
}

export const AVG_LOAD_TIME_CHART_CONFIG = {
  title: 'Avg. Tag Load Time',
  data: [],
  sideLabel: 'ms',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => {
    return `${value} ms`;
  },
};

export const AVG_TAG_REQUEST_SIZE_CHART_CONFIG = {
  title: 'Avg. Tag Request Size',
  data: [],
  sideLabel: 'bytes',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: false,
  tooltip: (value: number) => {
    return `${value} bytes`;
  },
};

export const TAG_SLOW_LOADING_CHART_CONFIG = {
  title: '% of Slow Loading Tags',
  data: [],
  sideLabel: '%',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => {
    return `${value}%`;
  },
};

export const TAG_TOTAL_BROKEN_CHART_CONFIG = {
  title: '% of Broken Tag Requests',
  data: [],
  sideLabel: '%',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => {
    return `${value}%`;
  },
};

export const TAG_TOTAL_REQUEST_CHART_CONFIG = {
  title: 'Total Tag Requests',
  data: [],
  sideLabel: 'requests',
  showXAxis: true,
  showYAxis: true,
  showSummaryLines: true,
  tooltip: (value: number) => {
    return `${value} requests`;
  },
};

export const tagLoadTimeChartTitle: IHorizontalBarChartTitle = {
  title: 'Tag Load Times (ms)',
  tooltip: 'Total number of tag requests within each segment.'
};

export const tagStatusCodeChartTitle: IHorizontalBarChartTitle = {
  title: 'Tag Status Codes',
  tooltip: 'Grouping of all tag requests having Good (200 series), Redirect (300 series) or Broken (0, 400 or 500 series) HTTP status codes.'
};

export enum ETagLoadTimePills {
  'Below500' = 'Below 500',
  '500to1000' = '500 to 999',
  '1000to2000' = '1000 to 1999',
  '2000andAbove' = '2000 and above'
}

export const tagLoadTimeFilterOptions = {
  [ETagLoadTimePills['Below500']]: {
    min: 0,
    max: 499
  },
  [ETagLoadTimePills['500to1000']]: {
    min: 500,
    max: 999
  },
  [ETagLoadTimePills['1000to2000']]: {
    min: 1000,
    max: 1999
  },
  [ETagLoadTimePills['2000andAbove']]: {
    min: 2000,
    max: null
  }
};

export enum TagHealthChartType {
  LoadTime,
  StatusCode
}

export const TagHealthRelevantFilters = [
  ...CommonReportsPagesFiltersConfig,

  EAuditReportFilterTypes.TagVendorId,
  EAuditReportFilterTypes.TagPrimaryTagsOnly,
  EAuditReportFilterTypes.TagLoadTime,
  EAuditReportFilterTypes.TagStatusCode,
  EAuditReportFilterTypes.TagId,
  EAuditReportFilterTypes.TagCategory,
  EAuditReportFilterTypes.TagAccount,
  EAuditReportFilterTypes.RequestSize
];

export const PagesTableColumns: ISwitchableMenuItems = {
  [CommonReportsPagesTableColumns.PageUrl]: {
    title: 'Initial URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.FinalPageUrl]: {
    title: 'Final URL',
    checked: true,
    pinned: true,
  },
  [CommonReportsPagesTableColumns.PageLoadTime]: {
    title: 'Page Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.FinalPageStatusCode]: {
    title: 'Final Page Status Code',
    checked: true,
  },
  [CommonReportsPagesTableColumns.TagLoadTimeAverage]: {
    title: 'Avg. Tag Load Time',
    checked: true,
  },
  [CommonReportsPagesTableColumns.TagRequestSizeAverage]: {
    title: 'Avg. Tag Request Size',
    checked: true,
  },
  [CommonReportsPagesTableColumns.TagInstanceCount]: {
    title: '# of Tag Requests',
    checked: true,
  },
  [CommonReportsPagesTableColumns.BrokenTagCount]: {
    title: 'Broken Tags',
    checked: true,
  },
};
