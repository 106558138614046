import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';

export interface OpCodeHighlighterFullScreenData {
  title: string;
  label: string;
  formGroup?: FormGroup;
  controlName?: string;
  readonly?: boolean;
  value?: string;
}

@Component({
  selector: 'op-code-highlighter-full-screen',
  templateUrl: './op-code-highlighter-full-screen.component.html',
  styleUrls: ['./op-code-highlighter-full-screen.component.scss']
})
export class OpCodeHighlighterFullScreenComponent implements AfterViewInit {

  title: string = '';
  label: string = '';
  value: string = '';
  formGroup: FormGroup;
  controlName: string;
  readonly: boolean = false;
  scrollAreaMaxHeight: number;

  private saveButton: IButton = {
    label: 'OK',
    primary: true,
    action: () => this.closeModal(this.formGroup.get(this.controlName)?.value),
  };

  readonly rightFooterButtons = [this.saveButton];

  // this is what needs to be removed when calculating the scroll area height
  readonly highlighterPadding = 20;

  @ViewChild('highlighter', { read: ElementRef }) highlighter: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<OpCodeHighlighterFullScreenComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OpCodeHighlighterFullScreenData,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.title = data.title;
    this.label = data.label;
    this.formGroup = data.formGroup;
    this.controlName = data.controlName;
    this.readonly = data.readonly;
    this.value = data.value;
  }

  ngAfterViewInit(): void {
    this.determineScrollAreaHeight();
  }

  @HostListener('window:resize', ['$event'])
  determineScrollAreaHeight(): void {
    this.scrollAreaMaxHeight = this.highlighter.nativeElement.offsetHeight - this.highlighterPadding;
    this.changeDetectorRef.detectChanges();
  }

  closeModal(js?: string): void {
    this.dialogRef.close(js);
  }
}
