import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable } from 'rxjs';
import { IRuleV3, IRuleV3UpdateRequest, IRuleV3WebJourneyRules } from '@app/components/rules/rules.models';

@Injectable({
  providedIn: 'root'
})
export class WebJourneyV3RulesService {

  apiRoot = `${environment.apiV3Url}web-journeys`;

  constructor(private apiService: ApiService) {}

  getJourneyRules(journeyId: number): Observable<IRuleV3WebJourneyRules> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}/rules`);
  }

  getJourneyActionRules(journeyId: number, actionId: number): Observable<IRuleV3[]> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}/actions/${actionId}/rules`);
  }

  updateJourneyRules(journeyId: number, rules: IRuleV3UpdateRequest): Observable<IRuleV3WebJourneyRules> {
    return this.apiService.put(`${this.apiRoot}/${journeyId}/rules`, rules);
  }

  updateJourneyActionRules(journeyId: number, actionId: number, rules: number[]): Observable<IRuleV3[]> {
    return this.apiService.put(`${this.apiRoot}/${journeyId}/actions/${actionId}/rules`, rules);
  }
}
