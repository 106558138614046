<div class="title-container">
  <h1 class="title">
    Notification Center
    <span class="icon-wrapper"
          [class.highlight]="infoIsExpanded"
          (click)="infoIsExpanded = !infoIsExpanded">
      <mat-icon class="title-icon">help_outline</mat-icon>
    </span>
  </h1>

  <div class="controls">
    <op-button-2021 labelText="Assign Email Address To Items"
                    matIcon="control_point"
                    buttonType="filled"
                    (buttonAction)="openCreateAssignmentsModal()"
    >
    </op-button-2021>
  </div>
</div>

<op-info-panel [expanded]="infoIsExpanded" class="info-panel">
  <p>
    The notification center streamlines the process of managing, changing, removing email addresses assotiated with
    Alerts, Audits, Journeys, and Tag & Variable Rules.
    <a [href]="NotificationCenterHelpUrl" target="_blank">Learn more</a>
  </p>
</op-info-panel>

<div class="search-input">
  <op-clearable-input placeholderText="Search email address"
                      [emitKeyboardEvent]="true"
                      (onUserInput)="filterRows($event)"
                      class="table-filter-input">
  </op-clearable-input>
</div>

<div class="notification-center-table-wrap">
  <table class="styled-mat-table"
         mat-table
         matSort
         multiTemplateDataRows
         [dataSource]="dataSource">

    <!-- Destination Column -->
    <ng-container [matColumnDef]="displayedColumns[0]">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef>
        Email Address
      </th>
      <td mat-cell *matCellDef="let profile">
        {{ profile.email }}
      </td>
    </ng-container>

    <!-- Email inboxes Received Column -->
    <ng-container [matColumnDef]="displayedColumns[1]">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="center-text">
        RECEIVED
      </th>
      <td mat-cell
          *matCellDef="let profile"
          (click)="openEditAssignmentsModal(profile, ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_RECEIVED)"
          class="usage center-text highlightable">
        <div class="flex-row">
          <span>{{ profile.usage.emailInboxMessageReceivedCount }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Email Inboxed Completion Column -->
    <ng-container [matColumnDef]="displayedColumns[2]">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="center-text">
        COMPLETION
      </th>
      <td mat-cell
          *matCellDef="let profile"
          (click)="openEditAssignmentsModal(profile, ENotificationCenterTargetItemType.EMAIL_INBOX_MESSAGE_PROCESSED)"
          class="usage center-text highlightable">
        <div class="flex-row">
          <span>{{ profile.usage.emailInboxMessageProcessedCount }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Audit alerts column -->
    <ng-container [matColumnDef]="displayedColumns[3]">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="center-text">
        Audits
      </th>
      <td mat-cell
          *matCellDef="let profile"
          (click)="openEditAssignmentsModal(profile, ENotificationCenterTargetItemType.ALERT)"
          class="usage center-text highlightable">
        <div class="flex-row">
          <span>{{ profile.usage.alertCount }}</span>
        </div>
      </td>
    </ng-container>

    <!-- WebJourney Alerts Column -->
    <ng-container [matColumnDef]="displayedColumns[4]">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="center-text">
        Usage
      </th>
      <td mat-cell
          *matCellDef="let profile"
          (click)="openEditAssignmentsModal(profile, ENotificationCenterTargetItemType.USAGE_ALERT)"
          class="usage center-text highlightable">
        <div class="flex-row">
          <span>{{ profile.usage.usageAlertCount }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Audit Rules Column -->
    <ng-container [matColumnDef]="displayedColumns[5]">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="center-text">
        Rules
      </th>
      <td mat-cell
          *matCellDef="let profile"
          (click)="openEditAssignmentsModal(profile, ENotificationCenterTargetItemType.RULE)"
          class="usage center-text highlightable">
        <div class="flex-row">
          <span>{{ profile.usage.ruleCount }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Audit Runs Column -->
    <ng-container [matColumnDef]="displayedColumns[6]">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="center-text">
        Summary
      </th>
      <td mat-cell
          *matCellDef="let profile"
          (click)="openEditAssignmentsModal(profile, ENotificationCenterTargetItemType.AUDIT)"
          class="usage center-text highlightable">
        <div class="flex-row">
          <span>{{ profile.usage.auditCount }}</span>
        </div>
      </td>
    </ng-container>

    <!-- WebJourney Runs Column -->
    <ng-container [matColumnDef]="displayedColumns[7]">
      <th mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="center-text">
        Updates
      </th>
      <td mat-cell
          *matCellDef="let profile"
          (click)="openEditAssignmentsModal(profile, ENotificationCenterTargetItemType.WEB_JOURNEY)"
          class="usage center-text highlightable">
        <div class="flex-row">
          <span>{{ profile.usage.webJourneyCount }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Audit Runs Column -->
    <ng-container [matColumnDef]="displayedColumns[8]">
      <th mat-header-cell
          *matHeaderCellDef
          class="center-text"></th>
      <td mat-cell
          *matCellDef="let profile"
          class="center-text">
        <actions-menu>
          <button mat-menu-item
                  (click)="openCloneAssignmentsModal(profile)">
            Add additional email address
          </button>
          <button mat-menu-item
                  (click)="openReplaceEmailModal(profile)">
            Reassign to different email address
          </button>
          <button mat-menu-item
                  (click)="openEditAssignmentsModal(profile)">
            Change Assigned Items
          </button>
          <button mat-menu-item
                  (click)="openRemoveAssignmentsModal(profile)">
            Remove Email Address
          </button>
        </actions-menu>
      </td>
    </ng-container>

    <!-- Empty Column -->
    <ng-container [matColumnDef]="topHeaderColumns[0]">
      <th mat-header-cell colSpan="1" *matHeaderCellDef class="center-text"></th>
    </ng-container>

    <!-- Inboxes Column -->
    <ng-container [matColumnDef]="topHeaderColumns[1]">
      <th mat-header-cell colSpan="2" *matHeaderCellDef class="center-text">
        <mat-icon>mail_outline</mat-icon>
        <p>INBOX FOR EMAIL</p>
      </th>
    </ng-container>

    <!-- Alerts Column -->
    <ng-container [matColumnDef]="topHeaderColumns[2]">
      <th mat-header-cell colSpan="2" *matHeaderCellDef class="center-text">
        <mat-icon>notifications_none</mat-icon>
        <p>Alerts</p>
      </th>
    </ng-container>

    <!-- Tag and Variables Column -->
    <ng-container [matColumnDef]="topHeaderColumns[3]">
      <th mat-header-cell colSpan="1" *matHeaderCellDef class="center-text">
        <mat-icon>gavel</mat-icon>
        <p>Tag & Variable</p>
      </th>
    </ng-container>

    <!-- Audit Column -->
    <ng-container [matColumnDef]="topHeaderColumns[4]">
      <th mat-header-cell *matHeaderCellDef class="center-text">
        <mat-icon>explore</mat-icon>
        <p>Audit Run</p>
      </th>
    </ng-container>

    <!-- WebJourney Column -->
    <ng-container [matColumnDef]="topHeaderColumns[5]">
      <th mat-header-cell *matHeaderCellDef class="center-text">
        <mat-icon>map</mat-icon>
        <p>Web Journey</p></th>
    </ng-container>

    <!-- Empty Column -->
    <ng-container colSpan="2" [matColumnDef]="topHeaderColumns[6]">
      <th mat-header-cell *matHeaderCellDef class="center-text"></th>
    </ng-container>


    <tr *matNoDataRow>
      <td colSpan="7">
        <div *ngIf="!loading" class="no-data">
          <span>No notification profiles found.</span>
        </div>
      </td>
    </tr>

    <tr mat-header-row class="top-header" *matHeaderRowDef="topHeaderColumns; sticky: true"></tr>
    <tr mat-header-row class="second-header" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="expander hoverable"></tr>
  </table>

  <div class="spinner-container" *ngIf="loading">
    <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
  </div>

  <div class="notification-channels">
    <notification-channels (createProfile)="openCreateAssignmentsModal()"></notification-channels>
  </div>
</div>

