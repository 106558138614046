import * as angular from 'angular';

import { AngularNames, Names, ServerErrorCodes } from '@app/moonbeamConstants';
import {
  DeleteControllerBase,
  IDeleteScope,
  IDisplayItem
} from '@app/components/delete/deleteControllerBase';
import { IDeviceProfile } from '../../live-connect/device-profile.service';
import { TerminateActiveRunsModalService } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';
import { ModalType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import { IApiService } from '@app/components/api/apiService';
import { environment } from '@app/environments/environment';
import { EDateFormats, IDateService } from '@app/components/date/date.service';

export const name = 'DeleteDeviceProfileController';

export class DeleteDeviceProfileController extends DeleteControllerBase<
  IDeviceProfile
> {
  static $inject = [
    AngularNames.scope,
    AngularNames.q,
    AngularNames.http,
    DeleteDeviceProfileController.Dependencies.deleteHandler,
    Names.NgServices.terminateActiveRunsModalService,
    Names.Services.api,
    Names.Services.date,
  ];

  constructor(
    protected $scope: IDeleteScope<IDeviceProfile>,
    protected $q: angular.IQService,
    protected $http: angular.IHttpService,
    protected onDeleteHandler: Function,
    private terminateActiveRunsModalService: TerminateActiveRunsModalService,
    private apiService: IApiService,
    protected dateService: IDateService,
  ) {
    super($scope, onDeleteHandler, dateService);
  }

  protected createDisplayItem(item: IDeviceProfile): IDisplayItem {
    return {
      name: item.name,
      createdAt: this.dateService.formatDate(new Date(item.createdAt), EDateFormats.dateTwo),
      type: 'Device Profile'
    };
  }

  protected delete(item: IDeviceProfile): angular.IPromise<boolean> {
    return this.apiService.handleResponse(
      this.$http.delete(`${environment.apiUrl}manual-journeys/${item.id}`)
    ).then(() => true);
  }

  protected handleError(error: any): void {
    if (error.errorCode === ServerErrorCodes.alreadyRunning) {
      this.close();
      this.terminateActiveRunsModalService.showTerminateActiveRunModal(ModalType.DeviceProfile);
    }
  }
}
