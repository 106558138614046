import { IWebElementSelector, EWebElementSelectorType, IWebElementSelectorType } from '../multi-selectors.models';

export const DEFAULT_SELECTOR: IWebElementSelector = {
  selectorType: EWebElementSelectorType.Id,
  origValue: '',
  value: ''
};

export const selectorTypes: IWebElementSelectorType[] = [
  { id: EWebElementSelectorType.Id, name: 'ID' },
  { id: EWebElementSelectorType.Name, name: 'Name' },
  { id: EWebElementSelectorType.Css, name: 'CSS Selector' },
  { id: EWebElementSelectorType.XPath, name: 'XPath' },
  { id: EWebElementSelectorType.HtmlAttrs, name: 'HTML Attributes' }
];
