import { ActionTypes } from '../constants/actionTypes';


export class AuthenticationActions {
  static userLogout = payload => {
    return {
      type: ActionTypes.USER_LOGOUT,
      payload
    };
  }
}
