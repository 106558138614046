<div class="messages-container" [class.loading]="spinnerState === EFilterSpinnerState.Loading">
  <div #browserMessagesTableScrollTop></div>
  <table mat-table
         matSort
         [dataSource]="tableDataSource"
         multiTemplateDataRows
         [matSortActive]="TableColumn.Type"
         matSortDirection="desc"
         matSortStart="desc">

    <ng-container [matColumnDef]="TableColumn.Expanded">
      <th mat-header-cell *matHeaderCellDef class="expanded-th">
      </th>
      <td mat-cell *matCellDef="let row" class="expanded-td">
        <mat-icon class="expand-arrow">keyboard_arrow_right</mat-icon>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.Message">
      <th mat-header-cell *matHeaderCellDef
          mat-sort-header>
        CONSOLE MESSAGE
      </th>
      <td mat-cell
          *matCellDef="let row"
          class="console-message"
          [ngClass]="levelToStatus(row)">
        <div class="preview">
          {{ row[TableColumn.Message] }}
        </div>
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.Type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        TYPE
      </th>
      <td mat-cell
          *matCellDef="let row"
          [ngClass]="levelToStatus(row)">
        {{ levelToStatus(row) }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.RelatedTag">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span matTooltip="If this message relates to a tag it will be displayed below." matTooltipPosition="above">
          RELATED TAG
        </span>
      </th>
      <td mat-cell *matCellDef="let row">
        <img
          class="tag"
          *ngIf="row[TableColumn.RelatedTag].src"
          [matTooltip]="row[TableColumn.RelatedTag].tagName"
          [src]="row[TableColumn.RelatedTag].src"
          matTooltipShowDelay="500"
          matTooltipPosition="above"
        />
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.LogCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span matTooltip="Total number of occurrences for this message." matTooltipPosition="above"># OF INSTANCES</span>
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row[TableColumn.LogCount] === null ? '---' : (row[TableColumn.LogCount] | number ) }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="TableColumn.PageCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          matTooltip="Pages affected by this message." matTooltipPosition="above">
        # OF PAGES
      </th>
      <td mat-cell *matCellDef="let row">
        <span [class.not-empty]="row[TableColumn.LogCount]" [class.selected]="row === selectedMetricRow" (click)="selectRow($event, row)">
          {{ row[TableColumn.PageCount] === null ? '---' : (row[TableColumn.PageCount] | number ) }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let row" [attr.colspan]="tableDisplayedColumns.length">
        <div class="details">
          <h6>{{levelToStatus(row)}} MESSAGE</h6>
          <pre [ngClass]="levelToStatus(row)">{{ row[TableColumn.Message] }}</pre>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: tableDisplayedColumns;"
        [class.expanded]="row.expanded"
        (click)="rowClicked(row)">
    </tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" [class.expanded]="row.expanded" class="console-message-details"></tr>

    <tr *matNoDataRow>
      <td [colSpan]="tableDisplayedColumns.length">
        <div class="no-pages-message-container">
          <span *ngIf="spinnerState !== 'loading'">no console logs here</span>
        </div>
      </td>
    </tr>
  </table>
</div>
<mat-paginator
  class="messages-paginator"
  [length]="tableState?.pagesTotal"
  [pageSize]="tableState?.pagination.size"
  [pageIndex]="tableState?.pagination.page"
  [hidePageSize]="true"
></mat-paginator>
