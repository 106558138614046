import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Events, Features, Messages, ServerErrorCodes } from '@app/moonbeamConstants';
import { PAUSED_DATE } from './manage-cards.constants';
import { CardTypes } from './report-card-list/report-card-list.constants';
import { IEventManager } from '@app/components/eventManager/eventManager';
import { from, of, throwError, defer, EMPTY, forkJoin } from 'rxjs';
import { dateFromString, toUTC } from '@app/components/date/date.service';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { ReportCardsApiService } from '@app/components/manage/shared/services/report-cards-api/report-cards-api.service';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { RemoteFileMapService } from '@app/components/creator/services/remote-file-map.service';
import { WebJourneyV3RfmService } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3-rfm.service';
import { IRFMConfigV3 } from '@app/components/creator/shared/remoteFileMapping/remote-file-mapping.component';
import { IAudit } from '@app/components/audits-picker/audits-picker.models';
import { catchError, concatMap, delay, map, mergeMap, retryWhen, switchAll, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { IStopRunService } from '@app/components/stopRun/stopRunService';
import { IWebJourneyRun } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { IWebJourneyV3 } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.models';
import { ReportCardService } from './report-card/report-card.service';
import { IAuditReportCard, IWebJourneyReportCard } from './report-card-list/report-card-list.models';
import { EConsentCategoryType, EDomainType, ENameType, ICmpData, IConsentCategories, IConsentCategory, IConsentCategoryBase, IConsentCategoryCookie, IRequestDomain } from '@app/components/consent-categories/consent-categories.models';
import { IReprocessService, ReprocessBannerStatus } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { IAuditDataService } from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { BulkActionProgressService } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.service';
import { IDomainSelected, IDomainsService } from '@app/components/domains/domainsService';
import { IUpdateDomainRequest } from '@app/components/domains/domainsService';
import { FormControl } from '@angular/forms';
import { IAuditModel } from '@app/components/modals/modalData';
import { IMultiSelectRequest } from '../shared/manage.models';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { IAuditFrequency } from '@app/components/audit/audit-setup-form/audit-setup-form.models';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';
import { IFolderSelected, IFoldersApiService } from '@app/components/folder/foldersApiService';
import { ModalType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import { TerminateActiveRunsModalService } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.service';
import { IFailedResponse } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.models';
import { IPromise } from 'rx';
import { IConsentGroup, ICountryCodes, ICmpDetectResponse, ICmpDetectProcessing, ICmpDetectProcessingDetails, ICmpCookie, IConsentGroupType } from '@app/components/consent-categories/sync-onetrust-categorized-cookies-modal/sync-onetrust-categorized-cookies-modal.models';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { IOpRecurrenceScheduleRequest } from '@app/components/shared/components/op-recurrence/op-recurrence.models';
import { StorageService } from '@app/components/shared/services/storage.service';

@Injectable()
export class ManageCardsService {

  selectedCards: any[] = [];
  auditIndex: number = 0;
  journeyIndex: number = 1;
  sourceNames: string[];
  inProgress = false;
  cachedRFMs: IRFMConfigV3[];
  monitoredJourneysCountRetreived: boolean = false;
  monitoredJourneysLeft: number = 0;
  usedMonitoredJourneys: number = 0;
  remainingJourneyFixes = 0;
  isWebJourneyAllowed: boolean = false;
  selectAllState: boolean = false;
  selectAllIndeterminateState: boolean = false;

  cards: (IAuditReportCard | IWebJourneyReportCard)[];

  // All filtered cards.
  filteredCards: (IAuditReportCard | IWebJourneyReportCard)[];
  filteredFolders: IFolderSelected[] = [];
  filteredSubfolders: IDomainSelected[] = [];

  readonly pauseDate: Date = toUTC(dateFromString(PAUSED_DATE));
  readonly resumeDate: Date = toUTC(new Date());
  readonly PARALLEL_PROCESSES: number = 3;
  readonly duration: number = 6000;

  // On Failure, wait x seconds then retry again (for getMaxRetry() number of times)
  readonly DELAY_BEFORE_RETRY: number = 5000;
  private retryAttempts: number = 0;
  private retryItems: any[] = [];

  runningItems = [];
  alreadyRunningIsOK = false;

  recurrenceEnabled: boolean = false;

  private errorGroup: IConsentGroup[] = [{
    groupId: '3',
    cmpId: '',
    status: 'Error',
    updateType: IConsentGroupType.NoChange,
    selected: false,
    expanded: false,
    geo: '',
    domain: '',
    cookies: [],
    groupName: '',
    vendor: 'Error',
  }];

  constructor(
    private eventManager: IEventManager,
    private webJourneyServiceV3: WebJourneyV3Service,
    private webJourneyServiceV2: IWebJourneyApiService,
    private auditService: DiscoveryAuditService,
    private reportCardsAPIService: ReportCardsApiService,
    private stopRunService: IStopRunService,
    private consentCategoriesService: ConsentCategoriesService,
    private reprocessService: IReprocessService,
    private auditDataService: IAuditDataService,
    private snackBar: MatSnackBar,
    private rCardSvc: ReportCardService,
    private domainsService: IDomainsService,
    private bulkActionProgressService: BulkActionProgressService,
    private rfmService: RemoteFileMapService,
    private webJourneyV3RfmService: WebJourneyV3RfmService,
    private authenticationService: AuthenticationService,
    private folderService: IFoldersApiService,
    private webJourneyAPIService: IWebJourneyApiService,
    private terminateActiveRunsModalService: TerminateActiveRunsModalService,
    private labelService: LabelService,
    private cacheResetService: CacheResetService,
    private storageService: StorageService
  ) {
    this.recurrenceEnabled = this.storageService.getValue('recurrenceEnabled');
    // Ensure we have rights to work with web journeys
    this.authenticationService.isFeatureAllowed(Features.webJourneys)
      .subscribe(isAllowed => this.isWebJourneyAllowed = isAllowed);

    this.cacheResetService.reset$.subscribe(_ => {
      this.filteredCards = [];
      this.filteredFolders = [];
      this.filteredSubfolders = [];
    });
  }

  ///////////////////////////////////////////
  // Main method for all bulk operations
  ///////////////////////////////////////////
  async runBulkOperation(
    selectedCards: any[],
    auditFunction: (card: any, optionalParams?: any[]) => IPromise<any>,
    journeyFunction: (card: any, optionalParams?: any[]) => IPromise<any>,
    optionalVals?: any[]): Promise<boolean> {

    try {
      let processedCount = 0;
      this.retryAttempts = 0;
      this.runningItems = [];
      this.selectedCards = selectedCards;

      await this.waitForProgresBarSetup();

      return await new Promise(async (resolve, reject) => {

        // Loop all selected cards
        from(this.selectedCards).pipe(
          mergeMap(card => {
            const bulkFunction = card.type === CardTypes.audit ? auditFunction : journeyFunction;

            // Using defer so that retries actully call the function again
            return defer(() => bulkFunction(card, optionalVals)).pipe(

              // Notify the progress bar that one item has been processed
              tap(() => {
                this.handleRetryLogic(card);
                this.bulkActionProgressService.publishProgressbar(++processedCount);
              }),

              // Handle any service errors - process approved http errors stop processing on all others.
              retryWhen(errors => errors.pipe(

                // Notify the Progressbar that we are retrying
                tap(() => {

                  this.bulkActionProgressService.publishRetry(++this.retryAttempts);

                  // Add the card to the retryItems array if it's not already there
                  const cardId = card.id || card.cmpId;
                  if (!this.retryItems?.includes(cardId)) {
                    this.retryItems?.push(cardId);
                  }
                }),

                // Check the error code and decide whether to retry or not
                concatMap((error) => {
                  if (error.code === 429 || (error.code >= 500 && error.code <= 599)) {
                    // If the API is limiting throughput then tell the progress bar (and user) a specific message
                    if (error.code === 429) {
                      this.bulkActionProgressService.setApiRateLimited(true);
                    }
                    return of(error);
                  } else {
                    return throwError(error);
                  }
                }),

                // Delay the retry for x number of milliseconds plus a jitter so that each parallel process is not simultaneously retrying
                delay(Math.round(Math.random() * this.DELAY_BEFORE_RETRY) + this.DELAY_BEFORE_RETRY),

                // Retry for up to 8 hours
                takeWhile(() => this.retryAttempts < this.bulkActionProgressService.getMaxRetryAttempts() && this.bulkActionProgressService.getIsRetrying())
              )),

              catchError(error => {

                // If bulk-running data-sources and this card is already running then treat it as a successful operation and move to the next card.
                if (this.isAlreadyRunningOK(card, error)) {
                  this.handleRetryLogic(card);
                  this.bulkActionProgressService.publishProgressbar(++processedCount);
                  return EMPTY;
                }

                // Report to the user the error we have encountered then quit
                const result = { card, success: false, error, type: 'service' } as IFailedResponse;
                this.bulkActionProgressService.setFailure(result);

                // When deleting items like subfolders (and thus its contents),
                //    we track if any items are running then abort accordingly
                //    (and prompt the user to cancel running items if needed)
                if (error?.code === 423 && error?.errorCode === ServerErrorCodes.alreadyRunning) {
                  error?.items?.forEach(item => this.runningItems?.push(item));
                }

                this.bulkActionProgressService.setProcessCompleted();

                return throwError(error); // Throw an error to stop the Observable stream
              })
            );
          },

          // Number of parallel processes to run at once
          this.PARALLEL_PROCESSES),

          // Continue until the process completes, errors out or is cancelled by the user
          takeUntil(this.bulkActionProgressService.getCancel())

        ).subscribe({

          error: err => {
            console.error('* runBulkOperation handler:', err);
            reject(err); // Reject the promise with the error
          },

          // After all work is completed then refresh the page
          complete: () => {

            this.refreshContent();

            // If there are any running items then prompt the user to terminate them
            if (this.runningItems.length > 0) {
              this.terminateActiveRunsModalService.showTerminateActiveRunsModal(ModalType.Folders, this.runningItems);

              // Close the progress dialog if we are terminating runs
              this.snackBar.dismiss();
            }

            // If errors then return false else true
            if (this.bulkActionProgressService.getFailures().length > 0) {
              return resolve(false);
            } else {
              return resolve(true);
            }
          }
        });
      });
    } catch (error) {
      return false;
    }
  }
  // If one or more cards have had failures but we are now succeeding notify the customer.
  private handleRetryLogic(card: any): void {

    // If this card was previously in the retry list then remove it.
    const cardId = card.id || card.cmpId;
    if (this.retryItems.includes(cardId)) {

      const index = this.retryItems.indexOf(cardId);
      if (index !== -1) {
        this.retryItems.splice(index, 1);
      }
    }

    // If previous retry attempts have been resolved then turn off retry/rate-limited mode.
    if (this.retryItems.length === 0 && this.bulkActionProgressService.getIsRetrying()) {
      this.retryAttempts = 0;
      this.retryItems = [];
      this.bulkActionProgressService.stopRetrying();
    }
  }
  // If attempting to bulk run and an item is already running then ignore the error and continue on.
  isAlreadyRunningOK(card: any, error: any): boolean {
    if (this.alreadyRunningIsOK) {
      if (card.type === CardTypes.audit && error.code === 423) {
        if (error.errorCode === ServerErrorCodes.alreadyRunning) {
          return true;
        }
      } else if (card.type === CardTypes.webJourney && error.code === 409) {
        if (error.errorCode?.errorCode === ServerErrorCodes.alreadyRunning) {
          return true;
        }
      }
    }
    return false;
  }
  async waitForProgresBarSetup(): Promise<void> {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (this.bulkActionProgressService.getSetupIsReady()) {
          clearInterval(interval);
          clearTimeout(timeout);
          resolve();
        }
      }, 25);

      const timeout = setTimeout(() => {
        clearInterval(interval);
        resolve();
      }, 1000);
    });
  }

  //#region Scheduling Menu

  // Bulk Run All selected audits and journeys now
  async runAllNow(selectedCards: any[]) {
    this.alreadyRunningIsOK = true;

    await this.runBulkOperation(
      selectedCards,
      (card) => this.auditService.runAudit(card.id),
      (card) => this.webJourneyServiceV3.runWebJourneyNow(card.id).toPromise());

    this.alreadyRunningIsOK = false;
  }

  // Bulk Pause Audits and Journeys
  //   Set the next run date to THE "pause" date for all selected audits/journeys.
  //   If user confirms, cancel the run if running
  pauseSelectedRuns(selectedCards: any[]) {
    let optionalParams: any[] = [this.pauseDate];

    this.runBulkOperation(
      selectedCards,
      (card, optionalParams) => this.pauseOrResumeAudit(card, optionalParams),
      (card, optionalParams) => this.pauseOrResumeJourney(card, optionalParams),
      optionalParams);
  }
  resumeSelectedRuns(selectedCards: any[]) {
    let optionalParams: any[] = [this.resumeDate];

    this.runBulkOperation(
      selectedCards,
      (cardId, optionalParam) => this.pauseOrResumeAudit(cardId, optionalParams),
      (cardId, optionalParam) => this.pauseOrResumeJourney(cardId, optionalParams),
      optionalParams);
  }
  async pauseOrResumeAudit(card: any, optionalParams: any[]): Promise<IAuditModel> {
    if (this.recurrenceEnabled) {
      const audit = await this.auditService.getAudit(card.id);
      const scheduled = { ...audit.schedule, isPaused: !audit.schedule.isPaused };
      const updatedAudit = { ...audit, schedule: scheduled };
      return this.auditService.updateAudit(updatedAudit);
    } else {
      let newDate: Date = optionalParams[0];

    // Get the audit object for updating
    return await this.auditService.getAudit(card.id).then((audit: IAudit) => {
      this.removeSchedule(audit);

      //Change the audit's next run date/time
      const updatedAudit = { ...audit, nextRun: this.preserveCardTime(newDate, card.nextRun) };

      return this.auditService.updateAudit(updatedAudit);
      });
    }
  }


  async pauseOrResumeJourney(card: any, optionalParams: any[]): Promise<IWebJourneyV3> {
    if (this.recurrenceEnabled) {
      const journey = await this.webJourneyServiceV3.getJourney(card.id).toPromise();
      const scheduled = { ...journey.schedule, isPaused: !journey.schedule.isPaused };
      const updatedJourney = { ...journey, schedule: scheduled };
      return this.webJourneyServiceV3.updateJourney(updatedJourney).toPromise();
    } else {
      let newDate: Date = optionalParams[0];

      // Get the journey object for updating
      return await this.webJourneyServiceV3.getJourney(card.id).pipe(map((journey: IWebJourneyV3) => {
        this.removeSchedule(journey);

        //Change the jounrey's next run(check) date/time
        const updatedJourney = {
          ...journey,
          nextCheck: this.preserveCardTime(newDate, card.nextCheck),
          location: (journey.customProxy) ? 'customProxy' : journey.location
        };

        return this.webJourneyServiceV3.updateJourney(updatedJourney).toPromise();
      })).toPromise();
    }
  }

  // Bulk Update Run Frequency for selected runs
  async updateRunFrequencies(selectedCards: any[], auditFrequency: any, journeyFrequency: any, newDate: Date): Promise<void> {
    let auditOptionalParams: any[] = [auditFrequency, newDate];
    let journeyOptionalParams: any[] = [journeyFrequency, newDate];
    let optionalValues: any[] = [auditOptionalParams, journeyOptionalParams];

    this.runBulkOperation(
      selectedCards,
      (card, optionalParams) => this.updateAuditRunFrequency(card, auditOptionalParams),
      (card, optionalParams) => this.updateJourneyRunFrequency(card, journeyOptionalParams),
      optionalValues);
  }
  async updateAuditRunFrequency(card: any, auditOptionalParams: any[]): Promise<IAuditModel> {

    let auditFrequency: any = auditOptionalParams[0];
    let newDate: Date = auditOptionalParams[1];

    return await this.auditService.getAudit(card.id).then((audit: IAudit) => {
      this.removeSchedule(audit);

      const updatedAudit = this.getAuditFrequencyUpdateConfig(audit, card, auditFrequency, newDate);
      if (updatedAudit) {
        return this.auditService.updateAudit(updatedAudit);
      }
    });
  }
  async updateJourneyRunFrequency(card: any, journeyOptionalParams: any[]): Promise<IWebJourneyV3> {

    let journeyFrequency: any = journeyOptionalParams[0];
    let newDate: Date = journeyOptionalParams[1];

    return await this.webJourneyServiceV3.getJourney(card.id).pipe(map((journey: IWebJourneyV3) => {
      this.removeSchedule(journey);

      const updatedJourney = this.getJourneyFrequencyUpdateConfig(journey, card, journeyFrequency, newDate);
      if (updatedJourney) {
        return this.webJourneyServiceV3.updateJourney(updatedJourney).toPromise();
      }
    })).toPromise();
  }

  // Bulk Update Run Schedule for selected items (cards)
  async updateRunSchedules(selectedCards: any[], schedule: IOpRecurrenceScheduleRequest): Promise<void> {
   this.runBulkOperation(
      selectedCards,
      (card) => this.updateAuditRunSchedule(card, schedule),
      (card) => this.updateJourneyRunSchedule(card, schedule)
    );
  }

  async updateAuditRunSchedule(card: any, schedule: IOpRecurrenceScheduleRequest): Promise<IAuditModel> {
    const audit = await this.auditService.getAudit(card.id);
    const updatedAudit = { ...audit, schedule };
    return this.auditService.updateAudit(updatedAudit);
  }

  async updateJourneyRunSchedule(card: any, schedule: IOpRecurrenceScheduleRequest): Promise<IWebJourneyV3> {
    const journey = await this.webJourneyServiceV3.getJourney(card.id).toPromise();
    const updatedJourney = { ...journey, schedule };
    return this.webJourneyServiceV3.updateJourney(updatedJourney).toPromise();
  }

  private removeSchedule(item: { schedule?: unknown }): void {
    if (this.recurrenceEnabled) return;

    // Temporarily remove the schedule object until we support schedules (instead of
    // frequency and the nextCheck 2038 pause hack) in the UI.
    delete item.schedule;
  }

  getAuditFrequencyUpdateConfig(audit: IAudit, card: any, newFrequency: any, newDate: Date): IAudit {
    let updatedAudit;

    // Do nothing if nothing has changed
    if (card.frequency === newFrequency.name && !newDate) {
      updatedAudit = null;
    } else {
      updatedAudit = {
        ...audit, frequency: newFrequency.name,
        nextRun: newDate
      };
    }

    return updatedAudit;
  }
  getJourneyFrequencyUpdateConfig(journey: IWebJourneyV3, card: any, newFrequency: string, newDate: Date): IWebJourneyV3 {
    let updatedJourney;

    // Do nothing if nothing has changed
    if (card.frequency === newFrequency && !newDate) {
      updatedJourney = null;
    }
    // Change the frequency and next run date
    else {
      updatedJourney = {
        ...journey,
        frequency: newFrequency,
        location: (journey.customProxy) ? 'customProxy' : journey.location,
        nextCheck: newDate
      };
    }

    return updatedJourney;
  }

  // Bulk Stop/Discard Selected Runs (which are running)
  async discardSelectedRuns(selectedCards: any[]): Promise<void> {
    this.runBulkOperation(
      selectedCards,
      (card) => this.auditService.stopActiveAuditRun(card.id),
      (card) => this.discardSelectedJourney(card));
  }
  async discardSelectedJourney(card: any): Promise<boolean> {

    // Get the journey runs then the specific run for stopping
    return await this.webJourneyServiceV2.getWebJourneyRuns(card.id).then((runs: IWebJourneyRun[]) => {

      // Find the run for stopping
      let runId = (runs.length > 0 && !runs[0].completedAt) ? runs[0].id : 0;

      return this.stopRunService.stopWebJourneyRun(card.id, runId);
    });
  }

  //#endregion - End of Scheduling Menu functions

  //#region Setup Menu

  // Bulk Update selected audits with new scan limit
  async setAuditScanLimits(selectedCards: any[], scanLimit: number): Promise<void> {
    let optionalParams: any[] = [scanLimit];

    this.runBulkOperation(
      selectedCards,
      (card) => this.setOneAuditScanLimit(card, optionalParams),
      () => Promise.resolve(),
      optionalParams);
  }
  async setOneAuditScanLimit(card: any, optionalParams: any[]): Promise<IAuditModel> {
    let scanLimit: number = optionalParams[0];

    // Get the audit object for updating
    return await this.auditService.getAudit(card.id).then((audit: IAudit) => {

      //Change the audit's page-scan limit
      const updatedAudit = { ...audit, limit: scanLimit };

      return this.auditService.updateAudit(updatedAudit);
    });
  }

  // Data Layers
  async updateDomainDataLayer(domains: any[], dataLayers: string): Promise<void> {
    let optionalParams: any[] = [dataLayers];

    this.runBulkOperation(
      domains,
      () => Promise.resolve(),
      (domain) => this.updateDataLayer(domain, optionalParams),
      optionalParams);
  }
  private async updateDataLayer(domain: any, optionalParams: any[]): Promise<any> {
    const id = domain.id;
    const dLayer: string = optionalParams[0];

    return await this.domainsService.getDomain(id).then((domainObj) => {
      if (domainObj) {
        const name = domainObj.name;
        const domain = domainObj.domain;
        const preDataLayers = domainObj.dataLayer;
        const folderId = domainObj.folderId;
        const dataLayer = preDataLayers ? preDataLayers + ', ' + dLayer : dLayer;

        // create a temporary object to send to the
        // api with the updated values
        const subfolderUpdateObj: IUpdateDomainRequest = {
          id,         // domain id (subfolder id)
          domain,     // url
          dataLayer,  // domain data layer
          folderId,   // folder id
          name        // descriptive domain name
        };

        return this.domainsService.updateDomain(subfolderUpdateObj);
      }
    });
  }

  // File Substitutions
  async updateFileSubstitutions(selectedCards: any[], UIfileSubs: FormControl): Promise<void> {

    let fsArray: IRFMConfigV3[] = UIfileSubs.value;
    let UIfileSubIDs: number[] = fsArray.map(fs => fs.id);

    // Load File Substitutions then filter the list to only those selected
    let filteredRFM: IRFMConfigV3[] = [];
    this.loadFileSubstitutions().then((configs: IRFMConfigV3[]) => {

      // Filter to only those selected for addition
      filteredRFM = this.cachedRFMs
        .filter((rfmConfig: IRFMConfigV3) => UIfileSubIDs.includes(rfmConfig.id))
        .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

      let optionalParams: any[] = [filteredRFM];

      this.runBulkOperation(
        selectedCards,
        (card, optionalParams) => this.updateAuditRFM(card, optionalParams),
        (card, optionalParams) => this.updateJourneyRFM(card, optionalParams),
        optionalParams);
    });
  }
  private async updateAuditRFM(card: IAuditModel, optionalParams: any[]): Promise<IAuditModel> {
    let filteredRFM: IRFMConfigV3[] = optionalParams[0];

    // update the audit with the new file substitutions
    return await this.auditService.getAudit(card.id).then((audit: IAuditModel) => {
      let auditRFM: IRFMConfigV3[] = audit.options.remoteFileMapConfig;
      if (!auditRFM) auditRFM = [];

      // dedupe the list of file substitutions then merge both lists
      let ids = new Set(auditRFM.map(d => d.id));
      let mergedRFM = [...auditRFM, ...filteredRFM.filter(d => !ids.has(d.id))];

      // update the audit with the new file substitutions
      audit.options.remoteFileMapConfig = mergedRFM;
      return this.auditService.updateAudit(audit);
    });
  }
  private async updateJourneyRFM(card: IWebJourneyV3, optionalParams: any[]): Promise<IRFMConfigV3[]> {
    let filteredRFM: IRFMConfigV3[] = optionalParams[0];

    // update the audit with the new file substitutions
    let filteredRfmIDs: number[] = filteredRFM.map(fs => fs.id);
    return await this.webJourneyV3RfmService.getRfmConfig(card.id).pipe(
      map(journeyRFM => {
        if (!journeyRFM) journeyRFM = [];

        // dedupe the list of file substitutions then merge both lists
        let journeyRfmIDs: number[] = journeyRFM.map(fs => fs.id);
        let mergedRFM = [...journeyRfmIDs, ...filteredRfmIDs];

        // update the journey with the new file substitutions
        return this.webJourneyV3RfmService.updateRfmConfig(card.id, mergedRFM);
      }),
      // Flatten all obervables into one then convert to a promise
      switchAll()
    ).toPromise();
  }
  private loadFileSubstitutions(): Promise<IRFMConfigV3[] | void> {

    //if we have a cached list of File Substitutions (rfms), return it
    if (this.cachedRFMs) {
      return Promise.resolve(this.cachedRFMs);
    }

    return this.rfmService.getRfmConfigs().toPromise().then((configs: IRFMConfigV3[]) => {
      this.cachedRFMs = configs
        .filter((rfmConfig: IRFMConfigV3) => !!rfmConfig.name)
        .sort((a: any, b: any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
    });
  }

  //--> SubMenu: Journey Support - Script Services

  // Request Journey Fix
  async sendFixJourneyRequest(selectedCards: any[]): Promise<number> {

    await this.runBulkOperation(
      selectedCards,
      () => Promise.resolve(),
      (card) => this.webJourneyAPIService.fixJourney(card.id, card.lastRun?.id));

    return await this.getJourneyFixCounts();
  }
  async getJourneyFixCounts(): Promise<number> {
    return this.webJourneyAPIService.getScriptServicesCounts().then(counts => {
      this.remainingJourneyFixes = counts.journeyFixes;
      return this.remainingJourneyFixes;
    });
  }
  isFixJourneyDisabled(): boolean {
    return this.remainingJourneyFixes === 0;
  }

  // Bulk Monitor/Unmonitor Journeys (monitorServices is a toggle)
  async monitorJourneys(selectedCards: any[], monitorServices: boolean): Promise<void> {

    const canProceed: boolean = await this.loadMonitoredJourneysCounts(monitorServices);
    if (canProceed === false) return;
    let monitorServicesParam: any[] = [monitorServices];

    this.runBulkOperation(
      selectedCards,
      () => Promise.resolve(),
      (card) => this.monitorOneJourney(card, monitorServicesParam)
    );
  }
  async monitorOneJourney(card: any, optionalParams): Promise<IWebJourneyV3> {
    let monitorServices: boolean = optionalParams[0];

    return await this.webJourneyServiceV2.getScriptServicesCounts().then(counts => {
      if (!monitorServices || counts.maxMonitoredJourneys === -1 || counts.maxMonitoredJourneys > counts.usedMonitoredJourneys) {
        return this.updateMonitoredJourneySettings(card, monitorServices);
      }
    });
  }
  private async updateMonitoredJourneySettings(card: IWebJourneyV3, monitorServices: boolean): Promise<IWebJourneyV3> {

    // If trying to monitor (as opposed to unmonitor) then check if we have any left
    if (monitorServices && this.monitoredJourneysLeft < 1) {
      return;
    }

    // Update the journey to be monitored by Script Services
    return await this.webJourneyServiceV3.getJourney(card.id).toPromise().then((journey: IWebJourneyV3) => {
      const updatedJourney = { ...journey, monitoredByScriptServices: monitorServices };
      return this.webJourneyServiceV3.updateJourney(updatedJourney).toPromise();
    });
  }
  private async loadMonitoredJourneysCounts(monitorServices: boolean): Promise<boolean> {

    await this.getMonitoredJourneysCounts();

    // If trying to monitor (as opposed to unmonitor) then check if we have any left
    if (monitorServices && this.monitoredJourneysLeft < 1) {
      this.snackBar.open("No Journeys were monitored. You have reached the maximum number of monitored journeys.", "", {
        duration: this.duration,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
      return false;
    } else {
      return true;
    }
  }
  async getMonitoredJourneysCounts(): Promise<number> {

    // Monitoring Logic:
    // maxMonitoredJourneys:      -1 indicates unlimited journeys
    // remainingWebJourneyFixes:  -1 indicates unlimited journeys

    return this.webJourneyServiceV2.getScriptServicesCounts().then(counts => {

      this.usedMonitoredJourneys = counts.usedMonitoredJourneys;
      this.monitoredJourneysLeft = (counts.maxMonitoredJourneys === -1)
        ? Number.MAX_SAFE_INTEGER
        : Math.max(counts.maxMonitoredJourneys - counts.usedMonitoredJourneys, 0);

      // Called internally and by BulkActionBarComponent
      return this.monitoredJourneysLeft;
    });
  }

  //#endregion - Setup Menu

  //#region - Standards Menu

  // Bulk reprocess consent categories
  async reprocessConsentCategories(selectedCards: any[]): Promise<void> {

    this.runBulkOperation(
      selectedCards,
      (card) => this.reprocessCCs(card),
      () => Promise.resolve());
  }
  async reprocessCCs(card: any): Promise<void> {

    return await this.auditService.getAudit(card.id).then((audit: IAudit) => {
      return this.auditService.getAuditRuns(audit.id).then((runs) => {
        const runId = runs.length > 0 ? runs[0].id : 0;
        return this.reprocessOneCC(audit as IAuditReportCard, runId)
      })
    });
  }
  async reprocessOneCC(audit: IAuditReportCard, runId: number): Promise<void> {
    const auditName = audit.name;
    const auditId = audit.id;

    try {
      const response: IConsentCategories[] = await this.consentCategoriesService.getConsentCategoriesAssignedToAudit(auditId).toPromise();
      const consentCategoryIds = response.map(cc => cc.id);

      await this.consentCategoriesService.reprocessConsentCategories(auditId, runId, consentCategoryIds).toPromise();

      await this.reprocessService.reprocessComplete();
      await this.reprocessService.subscribeOnAuditReprocessingConsentCategoriesUpdates(auditId, runId, auditName);
      await this.reprocessService.updateAuditReprocessConsentCategoriesBannerStatus(auditId, runId, ReprocessBannerStatus.inProgress);
    } catch (error) {
      if (error.code === 423) this.reprocessService.displayAuditConsentCategoriesReprocessInProgressToast();
    }
  }

  async importAllCCs(groups: IConsentGroup[], urls: string[], geos: ICountryCodes[], allConsentCategories: IConsentCategory[]): Promise<void> {
    await this.runBulkOperation(
      groups,
      () => Promise.resolve(),
      (group) => this.importAllCcGroups(group, urls, geos, allConsentCategories));
  }
  async importAllCcGroups(group: IConsentGroup, urls: string[], geos: ICountryCodes[], allConsentCategories: IConsentCategory[]): Promise<void>{
    if( group.updateType === IConsentGroupType.Insert) {
      return await this.importOneCmpGroup_Insert(group, urls, geos);
    } else if( group.updateType === IConsentGroupType.Update) {
      return await this.importOneCmpGroup_Update(group, urls, geos, allConsentCategories);
    } else if( group.updateType === IConsentGroupType.NoChange || group.updateType === IConsentGroupType.NoChangeCookies) {
      return await this.importOneCmpGroup_NoChange(group, urls, geos, allConsentCategories);
    } else if( group.updateType === IConsentGroupType.Delete) {
      const ccId: number = allConsentCategories.find(cc => cc.cmpId === group.cmpId)?.id;
      return await this.importOneCmpGroup_Delete(ccId);
    }
  }
  async importOneCmpGroup_Insert(group: IConsentGroup, urls: string[], geos: ICountryCodes[]): Promise<void> {

    const payload = {
      name: await this.getCmpGroupName(group.groupName, urls, geos),
      cmpData: {
        cmpVendor: group.vendor,
        oneTrustCookieGroupDomain: urls[0]?.trim(),
        oneTrustCookieGroupGeo: geos[0].localeCode?.toLocaleLowerCase()?.trim(),
        oneTrustCookieGroupId: group.groupId,
      } as ICmpData,
      notes: '',
      type: EConsentCategoryType.APPROVED,
      isDefaultCC: false,
    } as IConsentCategoryBase;

    try {
      const isUnique = await this.consentCategoriesService.isConsentCategoryNameUnique(payload.name).toPromise();
      if (!isUnique) {
        console.log("A Consent Category with the same name already exists: " + payload.name);
        return Promise.reject(new Error(`A Consent Category with the same name already exists: ${payload.name}`));
      }

      const cc = await this.consentCategoriesService.createConsentCategory(payload).toPromise();
      group.id = cc.id;
      const patchObservables = [];
      const cookies: IConsentCategoryCookie[] = this.convertCookies(group.cookies);

      let newLabels: ILabel[] = [];
      newLabels.push(await this.createLabel("OneTrust Imported"));
      newLabels.push(await this.createLabel(urls[0]));
      newLabels.push(await this.createLabel(geos[0].localeCode));
      const newLabelIds = newLabels.map(label => label.id);

      patchObservables.push(
        this.consentCategoriesService.patchConsentCategoryLabels(cc.id, [], newLabelIds).toPromise().catch(err => {
          console.error("Error patching labels:", err);
          return null;
        })
      );
      patchObservables.push(
        this.consentCategoriesService.patchConsentCategoryCookies(cc.id, this.consentCategoriesService.compareConsentCategories([], cookies)).toPromise().catch(err => {
          console.error("Error patching cookies:", err);
          return null;
        })
      );

      await Promise.all(patchObservables);
    } catch (err) {
      console.error("Consent Category not added from OneTrust. Error message (1): '" + payload.name + "', " + err.message);
      return Promise.reject("Consent Category not added from OneTrust. Error message (1): '" + payload.name + "', " + err.message);
    }
  }
  async importOneCmpGroup_Update(group: IConsentGroup, urls: string[], geos: ICountryCodes[], cc: IConsentCategory[]): Promise<void> {

    const currentCookies = cc.find(c => c.cmpId === group.cmpId)?.cookies;
    const payload = {
      id: cc.find(c => c.cmpId === group.cmpId)?.id,
      name: await this.getCmpGroupName(group.groupName, urls, geos),
      cmpData: {
        cmpVendor: group.vendor,
        oneTrustCookieGroupDomain: urls[0]?.trim() || '',
        oneTrustCookieGroupGeo: geos[0]?.localeCode?.toLocaleLowerCase()?.trim() || '',
        oneTrustCookieGroupId: group.groupId,
      } as ICmpData,
      notes: '',
      type: EConsentCategoryType.APPROVED,
      isDefaultCC: false,
    } as IConsentCategoryBase;

    try {
      await this.consentCategoriesService.updateConsentCategory(payload.id, payload).toPromise();

      const patchObservables = [];
      const cookies: IConsentCategoryCookie[] = this.convertCookies(this.removeDeleteCookies(group.cookies));

      patchObservables.push(this.consentCategoriesService.patchConsentCategoryCookies(
        payload.id,
        this.consentCategoriesService.compareConsentCategories(currentCookies, cookies)
      ));

      await forkJoin(patchObservables).toPromise();
    }
    catch(err) {
      console.error("Consent Category not updated from OneTrust. Error message: '" + payload.name + "', " + err.message);
      return Promise.reject("Consent Category not updated from OneTrust. Error message: '" + payload.name + "', " + err.message);
    }
  }
  async importOneCmpGroup_Delete(ccId: number): Promise<void> {
    return await this.consentCategoriesService.deleteConsentCategory(ccId)
      .pipe(map(() => void 0))
      .toPromise();
  }
  async importOneCmpGroup_NoChange(group: IConsentGroup, urls: string[], geos: ICountryCodes[], cc: IConsentCategory[]): Promise<void> {

    // This call will also update any cookies that need deleting or inserting
    return await this.importOneCmpGroup_Update(group, urls, geos, cc);
  }

  private convertCookies(importCookies: ICmpCookie[]): IConsentCategoryCookie[] {
    return importCookies.map(cookie => {
      const nameIsRegex = cookie?.nameIsRegex ?? false;
      return {
        name: cookie.name,
        nameRegex: nameIsRegex,
        nameMatchAny: false,
        domain: cookie.domain,
        domainRegex: true,
        expirationDate: null,
        existing: false,
        sequence: 1,
        index: 1,
        nameType: (nameIsRegex) ? ENameType.REGEX : ENameType.EXACT, // always exact match when importing from existing cookies
        domainType: EDomainType.REGEX,
      } as IConsentCategoryCookie;
    });
  }
  private getSelectedRequestDomains(urls: string[], geos: ICountryCodes[]): IRequestDomain[] {
    return urls.map(url => {
      return {
        domain: url,
        domainType: EDomainType.EXACT,
        regex: false,
        locationIds: [geos[0].id],
        locations: [geos[0].localeCode?.toLocaleLowerCase()?.trim()],
        anyLocation: false
      } as IRequestDomain;
    });
  }
  private removeDeleteCookies(cookies: ICmpCookie[]): ICmpCookie[] {
    return cookies.filter(cookie => cookie.updateType !== IConsentGroupType.Delete);
  }
  getCmpGroupName(groupName: string, urls: string[], geos: ICountryCodes[]): string {
    return groupName + ' | ' + urls[0] + ' | ' + geos[0].localeName;
  }
  async createLabel(labelName: string): Promise<ILabel> {
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    try {
      // Make this call every time to ensure we have the latest labels (we have a mass import going and several processes tyring to add the same labels)
      await this.labelService.getLabels().toPromise();

      const label = this.labelService.allLabels.find(l => l.name.trim().toLocaleLowerCase() === labelName.trim().toLocaleLowerCase());
      if (label) {
        return label;
      } else {
        try {
          return await this.labelService.createLabel(labelName).toPromise();
        } catch (error) {
          // Because we are creating multiple CCs at once and each CC is creating the same label at the same time, let's check once more before giving up.
          await delay(150);
          await this.labelService.getLabels().toPromise();
          const findLabel = this.labelService.allLabels.find(l => l.name.trim().toLocaleLowerCase() === labelName.trim().toLocaleLowerCase());
          if( findLabel ) {
            return findLabel;
          }
          console.log("Error creating label: " + labelName + ", " + error.message);
          return null;
        }
      }
    }
    catch (error) {
      console.log("Error finding label: " + labelName + ", " + error.message);
    }
  }

  async detectCategories(url: string, geos: string): Promise<IConsentGroup[]> {
    // 1. POST to https://api.observepointstaging.com/v3/cmp-providers/imports/cookies
    const postData = {
      "cmpProvider": "ONE_TRUST",
      "sourceUrl": url,
      "locales": [geos]
    }

    let fetchResponse;

    try {
      fetchResponse = await this.consentCategoriesService.detectCategories(postData).toPromise();
    } catch (error) {
      this.errorGroup[0].groupId = '0';
      this.errorGroup[0].groupName = (error?.message?.includes('429')) ? 'Too many requests. Please try again later.' : error?.message;
      return this.errorGroup;
    }
    const requestId = fetchResponse.results[0].requestId;

    // 2. check status and loop if PROCESSING, exit if error, or move to step 3 if COMPLETED (or "results" json response?)
    let rsp: ICmpDetectProcessing;
    let rspDetails: ICmpDetectProcessingDetails;
    let attempts = 0;
    const ATTEMPT_INTERVAL = 4000; // retry after 4 seconds
    const MAX_ATTEMPTS = 15; // Retry up to 30 seconds before giving up
    const cmpRequest: ICmpDetectResponse = { importRequestIds: [requestId] };

    do {
      // Don't delay on the first attempt
      if (attempts > 0) {
        await new Promise(resolve => setTimeout(resolve, ATTEMPT_INTERVAL)); // Wait for x seconds before retrying
      }

      // Check if status is still PROCESSING and retry if needed
      try {
        rsp = await this.consentCategoriesService.getCmpConsentCategories(cmpRequest).toPromise();
        rspDetails = await this.getFirstDetectResponse(requestId, rsp);
      } catch (error) {
        this.errorGroup[0].groupId = '1';
        this.errorGroup[0].groupName = (error?.message?.includes('429')) ? 'Too many requests. Please try again later.' : error?.message;
        return this.errorGroup;
      }
    } while (rspDetails.status === "PROCESSING" && ++attempts < MAX_ATTEMPTS);

    // 3. Grab groups response and return (or handle errors)
    if (rspDetails.status === "COMPLETE") {
      rspDetails.groups.map(group => group.domain = rspDetails.domain);
      return rspDetails.groups as IConsentGroup[];
    } else if (rspDetails.status === "FAILURE") {
      this.errorGroup[0].groupId = '2';
      this.errorGroup[0].groupName = '';
      return this.errorGroup;
    } else {
      this.errorGroup[0].groupId = '3';
      this.errorGroup[0].groupName = '';
      return this.errorGroup;
    }
  }
  getFirstDetectResponse(requestId: string, rsp: ICmpDetectProcessing): ICmpDetectProcessingDetails {
    if (rsp.importResults.hasOwnProperty(requestId)) {
      return rsp.importResults[requestId];
    } else {
      console.log('No import results found');
      return null;
    }
  }

  // Bulk reprocess rules
  async reprocessRules(selectedCards: any[]): Promise<void> {

    this.runBulkOperation(
      selectedCards,
      (card) => this.reprocessRulesCore(card),
      () => Promise.resolve());
  }
  async reprocessRulesCore(card: any): Promise<void> {

    return await this.auditService.getAudit(card.id).then((audit: IAudit) => {
      this.auditService.getAuditRuns(audit.id).then((runs) => {
        const runId = runs.length > 0 ? runs[0].id : 0;
        return this.reprocessOneAuditRules(audit as IAuditReportCard, runId)
      })
    });
  }
  reprocessOneAuditRules(audit: IAuditReportCard, runId: number): void {
    let auditId: number = audit.id;
    fromPromise(this.auditDataService
      .reprocessRules(auditId, runId)
      .then(() => {
        this.reprocessService.reprocessComplete();
        this.reprocessService.subscribeOnAuditReprocessingRulesUpdates(auditId, runId, audit.name);
        this.reprocessService.updateAuditReprocessRulesBannerStatus(auditId, runId, ReprocessBannerStatus.inProgress);
      }));
  }

  //#endregion - Standards Menu

  //#region - Organization Menu

  async addLabels(selectedCards: any[], newLabels: ILabel[]): Promise<void> {
    let optionalParams: any[] = [newLabels];

    this.runBulkOperation(
      selectedCards,
      (card, optionalParams) => this.addAuditLabel(card, optionalParams),
      (card, optionalParams) => this.addJourneyLabel(card, optionalParams),
      optionalParams);
  }
  async addAuditLabel(card: any, optionalParams: any[]): Promise<ILabel[]> {
    let newLabels: ILabel[] = optionalParams[0];

    return await this.auditService.getAudit(card.id).then((audit: IAudit) => {

      //Update the audit's labels
      let mergedLabels = [...audit.labels, ...newLabels];
      return this.auditService.updateAuditLabels(card.id, mergedLabels).then((updatedLabels: ILabel[]) => {
        card.labels = updatedLabels;
        return updatedLabels;
      })
    });
  }
  async addJourneyLabel(card: any, optionalParams: any[]): Promise<ILabel[]> {
    let newLabels: ILabel[] = optionalParams[0];

    return await this.webJourneyServiceV2.getWebJourneyLabels(card.id).then((labels: ILabel[]) => {

      //Update the journey's labels
      let mergedLabels = [...labels, ...newLabels];
      return this.webJourneyServiceV2.updateWebJourneyLabels(card.id, mergedLabels).then((updatedLabels: ILabel[]) => {
        card.labels = updatedLabels;
        return updatedLabels
      })
    });
  }

  removeLabels(selectedCards: any[]): void {
    this.runBulkOperation(
      selectedCards,
      (card) => this.auditService.updateAuditLabels(card.id, []),
      (card) => this.webJourneyServiceV2.updateWebJourneyLabels(card.id, []));
  }

  async moveToFolders(selectedCards: any[], values: any): Promise<boolean> {
    let optionalParams: any[] = [values];

    return await this.runBulkOperation(
      selectedCards,
      (card, optionalParams) => this.moveAuditsToFolders(card, optionalParams),
      (card, optionalParams) => this.moveJourneysToFolders(card, optionalParams),
      optionalParams);
  }
  async moveAuditsToFolders(card: any, optionalParams: any[]): Promise<IAuditModel> {
    let { folder, domain } = optionalParams[0];

    return await this.auditService.getAudit(card.id).then((audit: IAudit) => {

      //Change the audit's folder/domain
      const updatedAudit = { ...audit, domainId: domain.id };
      return this.auditService.updateAudit(updatedAudit);
    });
  }
  async moveJourneysToFolders(card: any, optionalParams: any[]): Promise<IWebJourneyV3> {
    let { folder, domain } = optionalParams[0];

    return await this.webJourneyServiceV3.getJourney(card.id)
      .pipe(map((journey: IWebJourneyV3) => {

        const updatedJourney = {
          ...journey, folderId: folder.id, domainId: domain.id,
          location: (journey.customProxy) ? 'customProxy' : journey.location
        };
        return this.webJourneyServiceV3.updateJourney(updatedJourney).toPromise();
      })).toPromise();
  }

  // => Delete Sub-menu section

  // Delete selected audits or journeys
  deleteSelectedAuditsJourneys(selectedCards: any[]): void {

    this.runBulkOperation(
      selectedCards,
      (card) => this.auditService.removeAudit(card.id),
      (card) => this.webJourneyServiceV2.deleteJourney(card.id));
  }
  // Delete multiple folders, subfolders and contents
  async deleteMultiFolders(folders: any[]): Promise<any> {

    return this.runBulkOperation(
      folders,
      () => Promise.resolve(),
      (folder) => this.folderService.removeFolder(folder.id));
  }
  async deleteMultiSubfolders(subfolders: any[]): Promise<any> {

    return await this.runBulkOperation(
      subfolders,
      () => Promise.resolve(),
      (sub) => this.domainsService.removeDomain(sub.id));
  }
  async buildCardsByFolders(folders: any[]): Promise<any[]> {

    let cards = await this.loadCardData();

    this.selectedCards = cards;
    let filteredCards = await this.filterCardsBySubfolder(folders, 'folders');

    return filteredCards;
  }
  async buildCardsBySubfolders(subfolders: any[]): Promise<any[]> {

    let cards = await this.loadCardData();
    this.selectedCards = cards;
    let filteredCards = await this.filterCardsBySubfolder(subfolders, 'subfolders');
    return filteredCards;
  }

  //#endregion - Organization Menu

  ////////////////////////////////////////////////////
  //#region - Non-Bulk Functions

  // Pause or Resume a single audit
  async pauseResumeAudit(card: any, newDate: Date) {
    if (this.recurrenceEnabled) {
      const audit = await this.auditService.getAudit(card.id);
      const scheduled = { ...audit.schedule, isPaused: !audit.schedule.isPaused };
      const updatedAudit = { ...audit, schedule: scheduled };

      fromPromise(this.auditService.updateAudit(updatedAudit)).subscribe({
        complete: () => {
          this.refreshContent();
        }
      });
    } else {
      fromPromise(this.auditService.getAudit(card.id).then((audit: IAudit) => {
        // Change the audit's next run date/time
        const updatedAudit = { ...audit, nextRun: this.preserveCardTime(newDate, card.nextRun) };
        fromPromise(this.auditService.updateAudit(updatedAudit));
      })).subscribe({
        complete: () => {
          this.refreshContent();
        },
      });
    }
  }

  // Pause or Resume a single journey
  async pauseResumeJourney(card: IWebJourneyV3, newDate: Date) {
    if (this.recurrenceEnabled) {
      const journey = await this.webJourneyServiceV3.getJourney(card.id).toPromise();
      const scheduled = { ...journey.schedule, isPaused: !journey.schedule.isPaused };
      const updatedJourney = { ...journey, schedule: scheduled };
      this.webJourneyServiceV3.updateJourney(updatedJourney).subscribe({
        complete: () => {
          this.refreshContent();
        }
      });
    } else {
      //Don't pause/resume a Not-Scheduled (paused) journey
      if (!this.rCardSvc.isScheduledJourney(card)) {
        this.snackBar.open("Journeys which are not scheduled cannot be resumed.", "", {
          duration: this.duration,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        return;
      }

      this.webJourneyServiceV3.getJourney(card.id).pipe(map((journey: IWebJourneyV3) => {
        const updatedJourney = {
          ...journey,
          location: (journey.customProxy) ? 'customProxy' : journey.location,
          nextCheck: this.preserveCardTime(newDate, journey.nextCheck.toString())
        };
        this.webJourneyServiceV3.updateJourney(updatedJourney).subscribe();
      })).subscribe({
        complete: () => {
          this.refreshContent();
        },
      });
    }
  }

  // Run a single audit or journey now
  runOneNow(cardType, cardId: number) {
    switch (cardType) {
      case CardTypes.audit:
        return this.auditService.runAudit(cardId)
      case CardTypes.webJourney:
        return this.webJourneyServiceV3.runWebJourneyNow(cardId)
    }
  }

  //#endregion - Non-Bulk Functions

  ////////////////////////////////////////////////////
  //#region - Utility Functions

  async getAuditJourneyCountsBySubfolder(folders: any[], folderLevel: string): Promise<Array<number>> {

    let reportCardsCount: Array<number> = [];
    let auditsFiltered: IAuditReportCard[];
    let journeysFiltered: IWebJourneyReportCard[];

    let auditsPromise = (await this.reportCardsAPIService.getAudits().toPromise());
    let webJourneysPromise = (this.isWebJourneyAllowed) ? this.reportCardsAPIService.getWebJourneys().toPromise() : Promise.resolve([]);
    let subFolderIDsPromise = (folderLevel === 'folders') ? this.getSubfolderByFolders(folders) : this.getSubfolderIDs(folders);

    return Promise.all([auditsPromise, webJourneysPromise, subFolderIDsPromise]).then(
      ([audits, webJourneys, subFolderIDs]) => {

        auditsFiltered = audits.filter(audit => subFolderIDs.includes(audit.domainId));
        journeysFiltered = webJourneys.filter(journey => subFolderIDs.includes(journey.domainId));

        // find the first domain containing an audit or journey from the auditsFiltered or journeysFiltered arrays
        let firstDomainId: number;
        if (auditsFiltered.length > 0) {
          firstDomainId = auditsFiltered[0].domainId;
        } else if (journeysFiltered.length > 0) {
          firstDomainId = journeysFiltered[0].domainId;
        }

        reportCardsCount = [auditsFiltered.length, journeysFiltered.length, firstDomainId];
        return Promise.resolve(reportCardsCount);
      })
      .catch(error => {
        console.log("Error: getAuditJourneyCountsBySubfolder", error);
        reportCardsCount = [0, 0, 0];
        return Promise.resolve(reportCardsCount);
      });
  }
  private async getSubfolderByFolders(folders: any[]): Promise<Array<number>> {

    let subFolderIDs: Array<number> = [];

    await Promise.all(folders.map(async (folder) => {
      try {
        const domains = await this.domainsService.getDomains(true, folder.id);
        domains.map(domain => {
          subFolderIDs.push(domain.id);
        });
      } catch (error) {
        console.log("Error: getAuditJourneyCountsBySubfolder", error);
      }
    }));

    return subFolderIDs;
  }
  private async getSubfolderIDs(subfolders: any[]): Promise<Array<number>> {

    let subFolderIDs: Array<number> = [];
    await subfolders.map(domain => {
      subFolderIDs.push(domain.id);
    });

    return subFolderIDs;
  }
  async filterCardsBySubfolder(folders: any[], folderLevel: string): Promise<Array<any>> {

    const subFolderIDs = await (folderLevel === 'folders')
      ? await this.getSubfolderByFolders(folders)
      : await this.getSubfolderIDs(folders);

    let filtered = await this.selectedCards.filter(card => {
      return subFolderIDs.includes(card.domainId);
    });

    return filtered;
  }
  refreshContent() {
    setTimeout(() => {
      this.eventManager.publish(Messages.refreshContent);
    }, 400);
  }

  async waitForAsync(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  loadCardData(): Promise<Array<any>> {

    let auditsPromise = this.reportCardsAPIService.getAudits().toPromise();
    let webJourneysPromise = this.authenticationService.isFeatureAllowed(Features.webJourneys).toPromise().then(res => {
      if (res) {
        return this.reportCardsAPIService.getWebJourneys().toPromise();
      } else {
        return Promise.resolve([]);
      }
    });

    return Promise.all([auditsPromise, webJourneysPromise])
      .then(([audits, webJourneys]) => {
        return (audits as any).concat(webJourneys as any);
      });
  }

  haveAuditsSelected(selCards: any[]): boolean {
    return selCards.some(card => {
      return card.type === CardTypes.audit
    });
  }
  haveJourneysSelected(selCards: any[]): boolean {
    return selCards.some(card => {
      return card.type === CardTypes.webJourney
    });
  }
  auditsSelectedCount(selCards: any[]): number {
    return selCards.filter(card => (card.type === CardTypes.audit)).length;
  }
  journeysSelectedCount(selCards: any[]): number {
    return selCards.filter(card => (card.type === CardTypes.webJourney)).length;
  }

  getMultiSelectItemCount(items: IMultiSelectRequest): number {
    const audits = items.audits.length;
    const webJourneys = items.webJourneys.length;

    return audits + webJourneys;
  }
  preserveCardTime(newDate: Date, oldDate: string): Date {
    //Preserve the Time so that daily schedules will not change when pausing and resuming
    let prevDate: Date = dateFromString(oldDate);
    newDate.setHours(prevDate.getHours(), prevDate.getMinutes(), prevDate.getSeconds(), prevDate.getMilliseconds());
    return newDate;
  }

  async loadAuditFrequencies(): Promise<IAuditFrequency[]> {

    let auditFrequencies = (await this.auditService.getAuditFrequencies()).map(f => ({
      name: f.toLowerCase(),
      label: f
    })) as IAuditFrequency[];

    return auditFrequencies;
  }

  async deselectItem(item: any) {
    this.eventManager.publish(Events.cardDeselected, item);
  }

  async deselectAllItems() {
    this.eventManager.publish(Events.deselectAllCards);
  }

  showSnackbarError(errorMessage: string, duration?: number): void {
    const durationNumber = (duration) ? duration : 8000;
    this.snackBar.open(
      errorMessage,
      '',
      { duration: durationNumber, horizontalPosition: 'center', verticalPosition: 'top' }
    );
  }

  // Without this delay, the delete process deletes the last moved item before the API is done updating the db.
  async waitForMoveToComplete(milli: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, milli);
    });
  }

  // Is any data source or folder or subfolder selected
  isAnythingSelected(selCards: any[]): boolean {

    if (selCards.length > 0
      || this.filteredFolders.some(folder => (folder.selected && !folder.indeterminate))
      || this.filteredSubfolders.some(sub => (sub?.selected && !sub.indeterminate))
    ) {
      return true;
    } else {
      return false;
    }
  }

  areAnyFoldersSelected(): boolean {

    if (this.filteredFolders.some(folder => (folder.selected && !folder.indeterminate))) {
      return true;
    } else {
      return false;
    }
  }
  areAnySubfoldersSelected(): boolean {

    if (this.filteredSubfolders.some(sub => (sub?.selected && !sub.indeterminate))) {
      return true;
    } else {
      return false;
    }
  }
  areOnlySubfoldersSelected(): boolean {

    if (!this.filteredFolders.some(folder => (folder.selected && !folder.indeterminate))
      && this.filteredSubfolders.some(sub => (sub?.selected && !sub.indeterminate))
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Function to test retry logic in bulk operations (when services fail, retry)
  //  NOTE: replace the runAudit call above with this one: (card) => this.runAuditRetry(card.id),
  /*async runAuditRetry(id: number): Promise<IAuditModel> {
    const retryFailureRange: number[] = [3, 29];
    const retryLoop: number = this.bulkActionProgressService.getProgressbarCount() + this.retryAttempts;

    if( retryLoop > retryFailureRange[0] && retryLoop < retryFailureRange[1]) {
      console.log('  ++RunAudit - RETRY:', retryLoop, this.retryAttempts, retryFailureRange[0], retryFailureRange[1], `${DiscoveryAuditService.path}/${id}/runs`);

      try {
        //const errorResponse = { code: 500, errorCode: 0, items: undefined, message: `Audit ${id}: Internal server error` };
        //const errorResponse = { code: 429, errorCode: 0, items: undefined, message: `Audit ${id}: Retry` };
        //const errorResponse = { code: 423, errorCode: 33, items: undefined, message: `Audit ${id}: is already running. Please wait for it to complete` };
        const errorResponse = { code: 300, errorCode: 0, items: undefined, message: `Audit ${id}: 300 error` };
        throw errorResponse;

      } catch (error) {
        return Promise.reject(error);
      }

    } else {
      console.log("  ++RunAudit - SUCCESS: ", id);
      const simulatedAuditModel: IAuditModel = {
        id: 1,
        name: 'name',
        folderId: 1,
        domainId: 1,
      };
      await this.waitForAsync(600);
      return Promise.resolve(simulatedAuditModel);
    }
  }*/

  //#endregion - Utility Functions
}
