<div class="admin-features">
  <table mat-table [dataSource]="dataSource" class="admin-features-table" *ngIf="dataSource.data.length">

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      color="primary">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" class="selection-checkbox">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="checkboxToggled($event, row)"
                      [checked]="selection.isSelected(row)"
                      color="primary">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="feature">
      <th mat-header-cell *matHeaderCellDef>FEATURE</th>
      <td mat-cell *matCellDef="let feature" class="name">{{ feature.displayName }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="checkboxToggled($event, row)">
    </tr>
  </table>
</div>
