import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  EChartDateRange,
  IFullscreenVerticalStackedBarChartChartConfig,
  IFullscreenVerticalStackedBarChartModalConfig,
} from './fullscreen-vertical-stacked-bar-chart-modal.constants';
import {
  IStackedBarChartBarInput,
  IStackedBarChartInput
} from '@app/components/shared/components/viz/vertical-stacked-bar-chart/vertical-stacked-bar-chart.models';
import {
  generateDateRangeConfig,
  generateDateRangeFromDate, getDaysInDateRange
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fullscreen-vertical-stacked-bar-chart-modal',
  templateUrl: './fullscreen-vertical-stacked-bar-chart-modal.component.html',
  styleUrls: ['./fullscreen-vertical-stacked-bar-chart-modal.component.scss']
})
export class FullscreenVerticalStackedBarChartModalComponent {
  areaChartUID: string = 'area-fullscreen';
  rightFooterButtons = [];
  fullscreenChartConfig: IFullscreenVerticalStackedBarChartChartConfig;
  dateRange: EChartDateRange = null;
  EChartDateRange = EChartDateRange;
  loading: boolean = true;

  greaterThanOneMonthDisabled: boolean = false;
  greaterThanThreeMonthsDisabled: boolean = false;
  greaterThanSixMonthsDisabled: boolean = false;
  greaterThanNineMonthsDisabled: boolean = false;
  greaterThanThirteenMonthsDisabled: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FullscreenVerticalStackedBarChartModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFullscreenVerticalStackedBarChartModalConfig
  ) {
    const dateRange = generateDateRangeFromDate(this.data.currentRunCompletedAt);
    this.dateRange = dateRange; // set togglebutton on template
    this.updateButtonRangeDisabledStates(dateRange);
    this.getDataForDateRange(dateRange);
  }

  @HostListener('document:keydown.escape')
  closeModal(): void {
    this.dialogRef.close();
  }

  updateButtonRangeDisabledStates(dateRange: EChartDateRange): void {
    const drc = generateDateRangeConfig(dateRange);
    this.greaterThanOneMonthDisabled = drc.greaterThanOneMonth;
    this.greaterThanThreeMonthsDisabled = drc.greaterThanThreeMonths;
    this.greaterThanSixMonthsDisabled = drc.greaterThanSixMonths;
    this.greaterThanNineMonthsDisabled = drc.greaterThanNineMonths;
    this.greaterThanThirteenMonthsDisabled = drc.greaterThanThirteenMonths;
  }

  getDataForDateRange(dateRange: EChartDateRange): void {
    this.loading = true;
    this.data.getData(getDaysInDateRange(dateRange))
      .subscribe((dataForRange: IStackedBarChartInput) => {
        this.fullscreenChartConfig = {
          ...this.data.chartConfig,
          data: { ...this.data.chartConfig.data, bars: dataForRange.bars }
        };
        this.loading = false;
      });
  }
}
