import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EEmailInboxModalType, EEmailInboxStep } from '../email-inbox-editor.constants';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'op-email-inbox-editor-steps',
  templateUrl: './email-inbox-editor-steps.component.html',
  styleUrls: ['./email-inbox-editor-steps.component.scss']
})
export class EmailInboxEditorStepsComponent implements OnInit {
  private readonly SuccessTooltipText: string = 'This step has been completed.';
  @Input() formGroup: UntypedFormGroup;
  @Input() currentStep: EEmailInboxStep;
  @Input() configuredAudits: number = 0;
  @Input() modalType: EEmailInboxModalType;
  @Input() noFilterSupport: boolean = false;
  @Input() notificationEmailsCount: number = 0;
  @Input() displayAuditsCount: boolean = false;
  @Input() emailInboxAddress: string = '';
  @Output() stepSelected = new EventEmitter<EEmailInboxStep>();

  filterInfoPlaceholder: string;
  isAlertTriggeredInLastRun = false;
  customNotificationMessage: boolean = false;
  private visitedSteps: EEmailInboxStep[] = [];
  private destroy$ = new Subject();

  readonly EEmailInboxStep = EEmailInboxStep;

  ngOnInit(): void {
    this.visitedSteps.push(this.currentStep);
  }

  onSelectStep1(): void {
    this.stepSelected.emit(EEmailInboxStep.Inbox);
    this.visitedSteps.push(EEmailInboxStep.Inbox);
  }

  onSelectStep2(): void {
    if (this.step1Incomplete) {
      return;
    }

    this.stepSelected.emit(EEmailInboxStep.Testing);
    this.visitedSteps.push(EEmailInboxStep.Testing);
  }

  onSelectStep3(): void {
    if (this.step1Incomplete) {
      return;
    }

    this.stepSelected.emit(EEmailInboxStep.Notifications);
    this.visitedSteps.push(EEmailInboxStep.Notifications);
  }

  isStepVisited(stepName: EEmailInboxStep): boolean {
    return this.visitedSteps.includes(stepName);
  }

  get step1Incomplete(): boolean {
    return !this.formGroup.get('name').value;
  }

  get step2Incomplete(): boolean {
    return this.configuredAudits === 0;
  }

  get step3Incomplete(): boolean {
    return this.notificationEmailsCount === 0;
  }

  get step1Tooltip(): string | null {
    if (!this.isStepVisited(EEmailInboxStep.Inbox)) {
      return 'An inbox name needs to be added to this inbox.';
    }

    if (this.step1Incomplete) {
      return 'Add an inbox name, and labels.';
    }

    return this.SuccessTooltipText;
  }

  get step2Tooltip(): string | null {
    if (!this.isStepVisited(EEmailInboxStep.Testing)) {
      return 'No audits have been assigned to this inbox yet, add them so any received email messages can be processed.';
    }

    if (this.step2Incomplete) {
      return 'Assign audits to the inbox.';
    }

    return this.SuccessTooltipText;
  }

  get step3Tooltip(): string | null {
    if (!this.isStepVisited(EEmailInboxStep.Notifications)) {
      return 'Specify who should be notified when email messages have been processed.';
    }

    if (this.step3Incomplete) {
      return 'Specify who should get notifications for this inbox.';
    }

    return this.SuccessTooltipText;
  }
}
