import { Injectable, OnDestroy } from '@angular/core';
import { AuditReportBaseService } from '@app/components/audit-reports/audit-report/audit-report.service';
import { ApiService } from '@app/components/core/services/api.service';
import { CacheResetService } from '@app/components/core/services/cache-reset.service';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { Observable, Subject } from 'rxjs';
import {
  TagDuplicatesAndMultiplesMergedFilters,
  TagDuplicatesAndMultiplesPages,
  TagDuplicatesAndMultiplesPagesTablePagination,
  TagDuplicatesAndMultiplesSummary,
  TagDuplicatesAndMultiplesTagAccounts,
  TagDuplicatesAndMultiplesTags,
  TagDuplicatesAndMultiplesTrendRunOverviews,
  TagDuplicatesAndMultiplesTrends
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.models';
import { TagDuplicatesAndMultiplesTrendName } from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.constants';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { takeUntil } from 'rxjs/operators';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable()
export class TagDuplicatesAndMultiplesService extends AuditReportBaseService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  constructor(
    private apiService: ApiService,
    private reprocessService: IReprocessService,
    cacheResetService: CacheResetService
  ) {
    super(cacheResetService);

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess })
  getTagDuplicatesAndMultiplesSummary(auditId: number, runId: number, body?: IAuditReportApiPostBody): Observable<TagDuplicatesAndMultiplesSummary> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-duplicates-and-multiples`, body);
  }

  getTagDuplicatesAndMultiplesTrends(auditId: number, runId: number): Observable<TagDuplicatesAndMultiplesTrendRunOverviews> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-duplicates-and-multiples/trends`);
  }

  getTagDuplicatesAndMultiplesTrend(auditId: number, trendName: TagDuplicatesAndMultiplesTrendName, days: number): Observable<TagDuplicatesAndMultiplesTrends> {
    return this.apiService.get(`${this.apiRoot}/${auditId}/reports/tag-duplicates-and-multiples/trends/${trendName}?days=${days}`);
  }

  getTagDuplicatesAndMultiplesTags(auditId: number, runId: number, body?: IAuditReportApiPostBody): Observable<TagDuplicatesAndMultiplesTags> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-duplicates-and-multiples/tags`, body);
  }

  getTagDuplicatesAndMultiplesTagAccounts(auditId: number, runId: number, tagId: number, body?: IAuditReportApiPostBody): Observable<TagDuplicatesAndMultiplesTagAccounts> {
    return this.apiService.post(`${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-duplicates-and-multiples/tags/${tagId}/accounts`, body);
  }

  getTagDuplicatesAndMultiplesPages(auditId: number, runId: number, pagination: TagDuplicatesAndMultiplesPagesTablePagination, body?: TagDuplicatesAndMultiplesMergedFilters): Observable<TagDuplicatesAndMultiplesPages> {
    const url = `${this.apiRoot}/${auditId}/runs/${runId}/reports/tag-duplicates-and-multiples/pages`;
    const queryParams = `?page=${pagination.page}&size=${pagination.size}&sortBy=${pagination.sortBy}&sortDesc=${pagination.sortDesc}`;
    return this.apiService.post(`${url}${queryParams}`, body);
  }

}
