export class Interval {
    static maxNanoseconds: number = 9223372036854775807;
    static maxMicroseconds: number = 9223372036854775;
    static maxMilliseconds: number = 9223372036854;
    static maxSeconds: number = 9223372036;
    static maxMinutes: number = 153722867;
    static maxHours: number = 2562047;
    static maxDays: number = 106751;
    static max: number = 9223372036854775807;
    static min: number = -9223372036854775808;
  }
