import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { ApiService } from '@app/components/core/services/api.service';
import { Observable } from 'rxjs';
import { IRFMConfigV3 } from '@app/components/creator/shared/remoteFileMapping/remote-file-mapping.component';

@Injectable()
export class WebJourneyV3RfmService {

  apiRoot: string = `${environment.apiV3Url}web-journeys`;

  constructor(private apiService: ApiService) {}

  getRfmConfig(journeyId: number): Observable<IRFMConfigV3[]> {
    return this.apiService.get(`${this.apiRoot}/${journeyId}/remotefilemappings`);
  }

  updateRfmConfig(journeyId: number, rfmConfigIds: number[]): Observable<IRFMConfigV3[]> {
    return this.apiService.put(`${this.apiRoot}/${journeyId}/remotefilemappings`, rfmConfigIds);
  }
}