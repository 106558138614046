import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'export-report-success-snackbar',
  templateUrl: './export-report-success-snackbar.component.html',
  styleUrls: ['./export-report-success-snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportReportSuccessSnackbarComponent {
  email: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: { email: string }
  ) {
    this.email = data.email;
  }
}
