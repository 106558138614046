import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { selectorTypes } from '../active-selector-picker/active-selector-picker.constants';
import { Subscription } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'active-selector-details',
  templateUrl: './active-selector-details.component.html',
  styleUrls: ['./active-selector-details.component.scss']
})
export class ActiveSelectorDetailsComponent implements OnInit, OnDestroy {

  @Input() formGroup: UntypedFormGroup;
  @Input() selectorsCount: number;
  @Input() submitted: boolean;

  @Output() removeSelector = new EventEmitter<void>();

  readonly elemSelectorTypes = selectorTypes;

  selectorTypeSubscription: Subscription;

  ngOnInit() {
    this.selectorTypeSubscription = this.selectorType.valueChanges.subscribe(type => {
      this.value.updateValueAndValidity();
    });
  }

  ngOnDestroy() {
    this.selectorTypeSubscription && this.selectorTypeSubscription.unsubscribe();
  }

  get selectorType(): AbstractControl {
    return this.formGroup.get('selectorType');
  }

  get value(): AbstractControl {
    return this.formGroup.get('value');
  }

}
 