<div>
  <div class="bulk-message-title">{{this.itemCounter - this.failedItemCount}} of {{this.maxCount}}</div>
  <div class="bulk-first-message" *ngIf="this.firstMessage" [innerHTML]="this.firstMessage"></div>
  <div class="bulk-progress-container" *ngIf="showProgressBar">
      <mat-progress-bar id="bulkProgressBar" mode="determinate" [value]="barStepCount"></mat-progress-bar>
  </div>
  <ng-container *ngIf="this.showSecondMessage">
    <div class="bulk-second-message" [innerHTML]="this.secondMessage"></div>
  </ng-container>
  <ng-container *ngIf="this.retryCounter > 0">
    <mat-progress-bar id="bulkRetryBar" mode="buffer"></mat-progress-bar>
    <div class="bulk-retry-message">{{ (apiRateLimited$ | async) 
      ? 'Pausing briefly to comply with API rate limits. Resuming momentarily.' 
      : 'Reconnecting to the server. Stand by...' + retryCounter }}
    </div>
  </ng-container>
  <failed-runs-message *ngIf="this.failedItemCount > 0"
                       [failedRuns]="failedRuns"
  ></failed-runs-message>
  <div *ngIf="this.serviceError">
    <div class="bulk-service-error">
      <div class="bulk-service-error-title"><mat-icon>error</mat-icon>An error occurred. 
        {{(this.itemCounter > 2) ? 'The first ' + (this.itemCounter) + ' items were completed successfully, however, the ' : (this.itemCounter === 2) ? 'One item was completed successfully, however, the ' : ''}} 
        {{(this.itemCounter === 1) ? 'The ' : ''}}
        item below (and any following items) did not complete. Please try again in a few minutes.
      </div>
      <div class="bulk-service-error-message">{{(this.failureMessage?.length > 0) ? ' Error message: "' + this.failureMessage + '"' : ''}}</div>
    </div>
    <div class="bulk-service-error-item">{{serviceErrorItemName}}</div>
  </div>
  <div *ngIf="!(records && itsDone)" class="buttons-container">
    <button id="bulk-action-progress-yes-btn" mat-flat-button (click)="quitProcess()" *ngIf="showSecondBtn">Yes, Quit</button>
    <button id="bulk-action-progress-no-btn" mat-flat-button (click)="continueProcess()" *ngIf="showSecondBtn">No, Continue</button>
    <button id="bulk-action-progress-quit-btn" mat-flat-button (click)="areYouSure()" *ngIf="showFirstBtn">{{this.rightBtnLabel}}</button>
  </div>
  <div *ngIf="records && itsDone" [ngClass]="{'single-button': !showSecondBtn}" class="buttons-container-records">
    <button id="bulk-action-progress-yes-btn" mat-flat-button (click)="closeSnackbar()" *ngIf="showFirstBtn">{{this.rightBtnLabel}}</button>
    <button id="bulk-action-progress-assign-audit-btn" mat-flat-button (click)="assignAudits()" *ngIf="showSecondBtn">Assign to Audits</button>
  </div>
</div>
