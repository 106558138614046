<div class="cc-header">
  <div class="title">
    <span class="title-text">{{ CONSTANTS.pageTitle }}</span>
    <div class="cc-header-icon-wrapper" [class.highlight]="infoIsExpanded" (click)="infoIsExpanded = !infoIsExpanded">
      <mat-icon class="title-icon">help_outline</mat-icon>
      <mat-icon class="title-expansion-icon">expand_more</mat-icon>
    </div>
  </div>
  <div class="button-wrap" *ngIf="!loading && consentCategories.length > 0">
    <op-button-2021 [labelText]="CONSTANTS.createConsentCategories"
                    matIcon="control_point"
                    buttonType="filled"
                    [menuItems]="createCCMenu">
    </op-button-2021>
    <mat-icon class="download-button" (click)="exportConsentCategories()">cloud_download</mat-icon>
  </div>
</div>

<op-info-panel [expanded]="infoIsExpanded" class="info-panel">
  <p>
    Create individual user consent preferences for each of the different categories that you have across your domains
    and apply them to your different audits.
  </p>
  <p>
    You can have Tags, Cookies, Geolocations & Request Domains either be approved or unapproved. <a
    href="https://help.observepoint.com/article/395-consent-categories" target="_blank">Learn more</a>
  </p>
</op-info-panel>
<div class="flex-col filter-bar-host">
  <consent-categories-filter-bar
    *ngIf="initialCCValueReceived && !(consentCategories.length === 0 && filters.length === 0 && !loading) && (consentCategories.length > 0 || filtersCount > 0)"></consent-categories-filter-bar>
  <!-- * initialCCValueReceived ensures visibility once the data loads the first time
       * the middle condition (in parens) ensures visibility in all cases where we don't absolutely return to zero state
       * the final condition (in parens) ensures visibility when we either have results that can be filtered or when filters have been or were applied - we must stay visible until we know we should hide
  -->
</div>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner [diameter]="75" [strokeWidth]="8"></mat-spinner>
</div>
<div class="flex-col flex-stretch cc-form"
     [class.hidden]="loading || (initialCCValueReceived && consentCategories.length === 0 && filters.length === 0)">
  <div class="cc-table-container">

    <table mat-table
           matSort
           [matSortActive]="sortOptions.sortBy"
           [matSortDirection]="sortOptions.sortDir"
           [matSortStart]="sortOptions.sortDir"
           class="styled-mat-table"
           [dataSource]="dataSource"
           (matSortChange)="handleSort(sort)"
           multiTemplateDataRows>

      <!-- Category Name column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>{{CONSTANTS.ccTable.nameHeader}}
        </th>
        <td mat-cell *matCellDef="let cc">
          <span class="hover-link truncate-text"
                (click)="onEditCC($event, cc)"
                matTooltip="{{cc.name}}"
                matTooltipPosition="above"
                [matTooltipDisabled]="cc.name.length < 21"
          >{{ cc.name }} <span *ngIf="cc.isDefaultCC">(default)</span></span>
        </td>
      </ng-container>

      <!-- Category Type column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell
            mat-sort-header
            *matHeaderCellDef>{{CONSTANTS.ccTable.typeHeader}}</th>
        <td mat-cell
            *matCellDef="let cc"
            (click)="onEditCC($event, cc)"
            class="hover-link">{{ cc.type | titlecase }}</td>
      </ng-container>

      <!-- Notes column -->
      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef class="center-text">{{CONSTANTS.ccTable.notesHeader}}</th>
        <td mat-cell
            (click)="clickNote($event, cc)"
            *matCellDef="let cc"
            [class.notes-expanded]="expandedCC === cc && clicked === 'notes'"
            [class.empty]="!cc.notes"
            class="center-text">
          <mat-icon class="cc-note"
                    matTooltip="Edit note"
                    matTooltipPosition="above"
                    matTooltipShowDelay="500">description
          </mat-icon>
          <mat-icon class="caret">expand_more</mat-icon>
        </td>
      </ng-container>

      <!-- Labels column -->
      <ng-container matColumnDef="labels">
        <th mat-header-cell *matHeaderCellDef>{{CONSTANTS.ccTable.labelsHeader}}</th>
        <td mat-cell *matCellDef="let cc" (click)="$event.stopPropagation()">
          <op-label-manager [selectedLabels]="cc.labels"
                            (onLabelCreated)="selectChip($event, cc)"
                            (onLabelSelected)="selectChip($event, cc)"
                            (onLabelRemoved)="removeChip($event, cc)"
          ></op-label-manager>
        </td>
      </ng-container>

      <!-- Cookies column -->
      <ng-container matColumnDef="cookies">
        <th mat-header-cell
            *matHeaderCellDef
            class="center-text">{{CONSTANTS.ccTable.cookiesHeader}}</th>
        <td mat-cell
            *matCellDef="let cc"
            (click)="clickCookiesCount($event, cc)"
            [class.hoverable-text]="cc.itemCounts.cookies > 0"
            [class.disabled]="cc.itemCounts.cookies <= 0"
            class="center-text">{{cc.itemCounts.cookies}}</td>
      </ng-container>

      <!-- Tags column -->
      <ng-container matColumnDef="tags">
        <th mat-header-cell
            *matHeaderCellDef
            class="center-text space-header">{{CONSTANTS.ccTable.tagsHeader}}</th>
        <td mat-cell
            *matCellDef="let cc"
            (click)="clickTagsCount($event, cc)"
            [class.hoverable-text]="cc.itemCounts.tags > 0"
            [class.disabled]="cc.itemCounts.tags <= 0"
            class="center-text">{{cc.itemCounts.tags}}</td>
      </ng-container>

      <!-- Request Domains column -->
      <ng-container matColumnDef="requestDomains">
        <th mat-header-cell
            *matHeaderCellDef
            class="center-text">{{CONSTANTS.ccTable.domainsHeader}}</th>
        <td mat-cell
            *matCellDef="let cc"
            (click)="clickRequestDomainsCount($event, cc)"
            [class.hoverable-text]="cc.itemCounts.requestDomainsAndGeos > 0"
            [class.disabled]="cc.itemCounts.requestDomainsAndGeos <= 0"
            class="center-text">{{cc.itemCounts.requestDomainsAndGeos}}</td>
      </ng-container>

      <!-- Audits column -->
      <ng-container matColumnDef="audits">
        <th mat-header-cell
            *matHeaderCellDef
            class="center-text">{{CONSTANTS.ccTable.auditsHeader}}</th>

        <td mat-cell
            class="usage"
            [class.disabled]="!item.auditCount"
            [class.opened]="expandedCC === item && clicked === 'audits'"
            *matCellDef="let item"
            (click)="clickAuditCount($event, item)">
          <div class="flex-row">
            <mat-icon>explore</mat-icon>
            <span>{{ item.auditCount }}</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </td>
      </ng-container>

      <!-- Last Updated column -->
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>{{CONSTANTS.ccTable.lastUpdatedHeader}}
        </th>
        <td mat-cell *matCellDef="let cc">
          <span class="updated-at">{{ cc.updatedAt }}</span>
        </td>
      </ng-container>

      <!-- Edit options column -->
      <ng-container matColumnDef="editOptions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let cc" align="right">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="more-menu-btn">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="cc-menu">
            <button mat-menu-item
                    class="table-action-icon"
                    (click)="onEditCC($event, cc)"
                    matTooltip="{{CONSTANTS.ccTable.editCC}}"
                    matTooltipPosition="left"
                    matTooltipShowDelay="250">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
            <button mat-menu-item
                    class="table-action-icon"
                    (click)="onCopyCC($event, cc)"
                    matTooltip="{{CONSTANTS.ccTable.copyCC}}"
                    matTooltipPosition="left"
                    matTooltipShowDelay="250">
              <mat-icon>content_copy</mat-icon>
              Copy
            </button>
            <button mat-menu-item
                    class="table-action-icon"
                    (click)="onDeleteCC($event, cc)"
                    matTooltip="{{CONSTANTS.ccTable.deleteCC}}"
                    matTooltipPosition="left"
                    matTooltipShowDelay="250">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
            <button mat-menu-item
                    class="table-action-icon"
                    *ngIf="isAdmin"
                    (click)="onViewChangeLog($event, cc)"
                    matTooltip="{{CONSTANTS.ccTable.changeLogCC}}"
                    matTooltipPosition="left"
                    matTooltipShowDelay="250">
              <mat-icon>restore</mat-icon>
              View event log
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <!-- Expanded Content Column -->
      <ng-container matColumnDef="expanded-detail">
        <td mat-cell *matCellDef="let cc" [attr.colspan]="displayedColumns.length">
          <div class="expanded-row-detail"
               #details
               [@expand]="{
                 value: cc === expandedCC ? (loading ? 'loading' : 'expanded') : 'collapsed',
                 params: {
                  height: this.lastOpenedNestedTableHeight ? this.lastOpenedNestedTableHeight + 'px' : '*'
                 }
               }"
               (@expand.done)="animationDone($event, details)"
          >
            <textarea (keyup)="debouncedUpdateNote($event, cc)"
                      placeholder="Notes could include:
- a description of what user state this consent category is validating
- changes to settings
- a list of to do items"
                      *ngIf="clicked === 'notes' && cc === expandedCC">{{cc.notes}}</textarea>
            <div class="notes-saved-indicator" *ngIf="noteSaved">
              <mat-icon>check</mat-icon>
              Saved
            </div>
            <cc-report-row *ngIf="clicked === 'audits' && cc === expandedCC"
                           [reportType]="reportType.AUDIT"
                           [users]="users"
                           (loadConsentCategories)="loadConsentCategories(cc.id)"
                           ccId="{{cc.id}}">
            </cc-report-row>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr mat-row class="hoverable"
          *matRowDef="let row; columns: displayedColumns"
          [class.expanded]="expandedCC === row"
          [ngClass]="{
            'notes-active': clicked === 'notes' && expandedCC === row,
            'audits-active': clicked === 'audits' && expandedCC === row
          }"
          (click)="setExpandedCC(row)"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: ['expanded-detail']" [class.expanded]="expandedCC === row"
          class="expandable"></tr>

      <tr *matNoDataRow>
        <td [colSpan]="displayedColumns.length">
          <div *ngIf="!loading" class="no-data">
            <span>No consent categories matched applied filters.</span>
          </div>
        </td>
      </tr>

    </table>
    <mat-paginator class="styled-mat-paginator"
                   [class.hide-paginator]="loading || pagination.totalCount < 101"
                   [length]="pagination?.totalCount"
                   [pageSize]="pagination?.pageSize"
                   [hidePageSize]="true">
    </mat-paginator>
  </div>
</div>
<cc-zero-state [loading]="loading"
               [showZeroState]="!loading && consentCategories.length === 0 && filters.length === 0"
               (openConsentCategoryModal)="openConsentCategoryModal({data: {'allLabels': allLabels, editing: false, type: 'approved'}})"
               (openConsentCategorySyncOneTrust)="openConsentCategorySyncOneTrust({})"
               (openConsentCategoryImportModal)="openConsentCategoryImportModal({})">
</cc-zero-state>
