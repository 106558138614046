import { Component } from '@angular/core';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cl-filter-spinner',
  templateUrl: './cl-filter-spinner.component.html',
  styleUrls: ['./cl-filter-spinner.component.scss']
})
export class ComponentLibraryFilterSpinnerComponent {
  readonly EFilterSpinnerState = EFilterSpinnerState;
}
