import { EAssignmentType } from '@app/models/commons';
import { EComparisonType, EDatasourceTagType } from '../comparisons/comparisons.enums';
import { EComparisonRunStatusType } from './comparison-library.enums';
import { ILabel } from '@app/components/shared/services/label.service';
import { IOneTimeComparisonDetails } from '../comparisons/one-time-comparison/one-time-comparison.models';
import { IRorComparisonDetails } from '../comparisons/ror-comparison/ror-comparison.models';

export interface IComparisonLibrary {
  id: number;
  type: EComparisonType;
  name: string;
  labels: ILabel[];
  tags?: number[];
  assignments?: IComparisonAssignments;
  createdBy: number;
  canDelete: boolean;
  isInProgress: boolean;
}

export interface IComparisonLibraryFromAPI extends Omit<Omit<IComparisonLibrary, 'labels'>, 'canDelete'> {
  labels: number[]
}

export interface IComparisonAssignments {
  audits: number;
  webJourneys: number;
}

export interface IComparisonPreview {
  id: number;
  name: string;
  type: EComparisonType;
}

export interface IComparisonSummary {
  id: number;
  type: EComparisonType;
  name: string;
  alignments: ITagVariableAlignment[];
  labels: number[];
  details: IRorComparisonDetails | IOneTimeComparisonDetails;
  createdAt: number;
  createdBy: number;
  updatedAt?: number;
  updatedBy?: number;
}

export interface ITagVariableAlignment {
  tagId: number;
  variables: string[];
}

export interface IDatasourceTag {
  tagId: number;
  type: EDatasourceTagType;
  selectedVariables?: string[];
  removedExclusions?: string[];
  addedExclusions?: string[];
}

export interface IComparisonRunResponse {
  comparisonId: number;
  comparisonSnapshotId: number;
  comparisonType: EComparisonType;
  comparisonName: string;
  itemType:	EAssignmentType
  itemId: number;
  runId: number;
  status: EComparisonRunStatusType;
  createdAt: number;
  updatedAt?: number;
}

export interface IComparisonRun extends IComparisonRunResponse {
  itemName: string;
}

export class IComparisonRunResponse {

  static toComparisonRun(response: IComparisonRunResponse, items: IComparisonItemPreview[]): IComparisonRun {
    const item = items.find(i => i.id === response.itemId);
    return {
      ...response,
      itemName: item ? item.name : ''
    };
  }

}

export interface IComparisonItemPreview {
  id: number;
  name: string;
}
