import { Injectable } from '@angular/core';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { Item, ModalType } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.models';
import { TerminateActiveRunsModalComponent } from '@app/components/terminate-active-runs-modal/terminate-active-runs-modal.component';

@Injectable()
export class TerminateActiveRunsModalService {

  constructor(private opModalService: OpModalService) { }

  showTerminateActiveRunModal(type: ModalType): void {
    this.opModalService.openConfirmModal({
      data: {
        title: 'Terminate active run',
        messages: [
          `This ${type} has an active run. Please stop & discard active run first.`
        ],
        rightFooterButtons: [{
          label: 'Ok',
          action: () => {},
          primary: true
        }]
      }
    });
  }

  showTerminateActiveRunsModal(type: ModalType, items: Item[]): void {
    this.opModalService.openModal(
      TerminateActiveRunsModalComponent,
      {
        data: {
          type: type,
          items: items
        }
      }
    );
  }
}
