<div class="snackbar-container">
  <p>{{ display.totalNewCategories | number }} new consent categories have been added
    including {{ display.totalNewCookies | number }} new cookies.
  </p>
  <p>
    {{ display.totalExistingCategories | number }} existing categories with {{ display.totalExistingCookies | number }} new
    cookies have been updated.
  </p>
  <p>
    {{ display.ccCount | number }} Consent Categor{{ display.ccCount > 1 ? "ies have" : "y has"}} now been assigned to
    {{ display.itemCount | number }} data souce{{ display.itemCount > 1 ? "s" : ""}}.
  </p>

  <ul>
    <li *ngFor="let row of display.topXNew">
      {{ row }}
    </li>
    <li *ngIf="display.numAdditional > 0">+ {{ display.numAdditional | number }} more</li>
  </ul>
</div>