<div class="table-wrap">
  <ng-container *ngIf="dataSource?.data?.length > 0">
    <table class="styled-mat-table"
          [dataSource]="dataSource"
           mat-table
           matSort
           [matSortActive]="sortOptions.sortBy"
           [matSortDirection]="sortOptions.sortDir"
           multiTemplateDataRows>
      <ng-container [matColumnDef]="ETriggeredAlertsTableColumns.RecentlyTriggeredDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Recently Triggered</th>
        <td mat-cell *matCellDef="let alert"
            [title]="!alert.alertConfigExists ? ALERT_DELETED_MESSAGE : ''">
          <span (click)="editAlert({ alert })"
                [class]="alert.alertConfigExists ? 'hover-link editable-alert-info' : 'deleted-alert-info'">
            <span>{{ alert.recentTriggeredDate | date: 'MM/dd/yyyy' }}</span>
            <mat-icon class="absolute-positioning">edit</mat-icon>
          </span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="ETriggeredAlertsTableColumns.AlertName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Alert Name</th>
        <td mat-cell *matCellDef="let alert"
            [title]="!alert.alertConfigExists ? ALERT_DELETED_MESSAGE : ''">
          <span (click)="editAlert({ alert })"
                [class]="alert.alertConfigExists ? 'hover-link editable-alert-info' : 'deleted-alert-info'">
            <span>{{ alert.name }}</span>
            <mat-icon>edit</mat-icon>
          </span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="ETriggeredAlertsTableColumns.ReportMetric">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Alert Rule Logic In Most Recent Run</th>
        <td mat-cell *matCellDef="let alert"
            [title]="!alert.alertConfigExists ? ALERT_DELETED_MESSAGE : ''">
          <span (click)="editAlert({ alert })"
                [class]="alert.alertConfigExists ? 'hover-link editable-alert-info' : 'deleted-alert-info'">
            <alert-logic-details [metricType]="alert.trigger.metricType"
                                 [operator]="alert.trigger.operator"
                                 [targetValue]="alert.trigger.targetValue"
                                 [filters]="alert.filters"
                                 [showFiltersList]="true"
                                 [clickable]="alert.alertConfigExists ? undefined : false">
            </alert-logic-details>
          </span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="ETriggeredAlertsTableColumns.Subscribers">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Subscribers</th>
        <td mat-cell *matCellDef="let alert"
            [title]="!alert.alertConfigExists ? ALERT_DELETED_MESSAGE : ''">
          <span (click)="editAlert({alert, step: EAlertStep.Notification})">
            <span class="subscribers-wrapper"
                  *ngIf="alert.subscriberCount > 0; else noSubscribers"
                  [class]="alert.alertConfigExists ? 'hover-link editable-alert-info' : 'deleted-alert-info'">
              <alert-bell [status]="alert.isUserSubscribed ? EAlertBellStatus.ConfiguredAndSubscribedAlerts : EAlertBellStatus.NoSubscribedAlerts"
                          [showBellAlways]="true">
              </alert-bell>
              <span>{{ alert.subscriberCount }}</span>
            </span>

            <ng-template #noSubscribers>
              <mat-icon *ngIf="alert.subscriberCount === 0"
                        matTooltip="This alert is not sending out notifications - add an email address for who should be notified."
                        matTooltipPosition="above"
                        class="no-subscribers-icon"
              >
                warning_amber
              </mat-icon>
            </ng-template>
          </span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="ETriggeredAlertsTableColumns.DataSources">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Data Source</th>
        <td mat-cell
            class="always-interactive-column"
            *matCellDef="let alert"
            (click)="selectAlert(alert)">
          <span class="data-sources-count" [class.opened]="selectedAlert === alert">
            {{ alert.dataSourceCount }}
            <mat-icon>expand_more</mat-icon>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandableRow">
        <td mat-cell *matCellDef="let alert" [attr.colspan]="displayedColumns.length">
          <div #container
               [@expand]="{
                 value: alert === selectedAlert ? (loading ? 'loading' : 'expanded') : 'collapsed',
                 params: {
                  height: this.lastOpenedNestedTableHeight ? this.lastOpenedNestedTableHeight + 'px' : '*'
                 }
               }"
               (@expand.done)="animationDone($event, container)">
            <div class="details" *ngIf="alert === selectedAlert">
              <triggered-alert-data-sources-table [data]="visibleAlertDataSources"
                                                  [alert]="selectedAlert"
                                                  [loading]="loading"
                                                  (updateTableState)="updateDataSourcesTableState($event)">
              </triggered-alert-data-sources-table>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="hoverable"
          *matRowDef="let row; columns: displayedColumns"
          [class.expanded]="selectedAlert === row"
          [class.deleted-alert-row]="row.alertConfigExists === false"
      ></tr>
      <tr mat-row class="expandable" *matRowDef="let row; columns: ['expandableRow']" [class.expanded]="selectedAlert === row"></tr>
    </table>

    <mat-paginator #paginator class="styled-mat-paginator" [hidePageSize]="true"></mat-paginator>
  </ng-container>
</div>
