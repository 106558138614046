import { Component } from '@angular/core';

@Component({
  selector: 'op-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent {

  constructor() { }

}
