import { Injectable } from '@angular/core';
import { IRule } from '@app/components/rules/rules.models';
import { ISerializer } from '../api/apiService';

@Injectable({
  providedIn: 'root'
})
export class RulesSerializer implements ISerializer<IRule[]> {

  serialize(json: IRule[]): IRule[] {
    json.forEach(rule => {
      if (rule.pageFilters) {
        const pageFilters = rule.pageFilters.filter(pageFilter => pageFilter.id);
        rule.pageFilters = (pageFilters.length > 0) ? pageFilters : null;
      }
    });
    return json;
  }

}
