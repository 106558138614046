import { IOpTab } from '@app/components/shared/components/op-tabs/op-tabs.models';

export const editAccountTabs: IOpTab[] = [
  {
    name: 'Account Details',
    path: 'accountDetails'
  },
  {
    name: 'Users',
    path: 'users'
  },
  {
    name: 'Tags',
    path: 'tags'
  },
  {
    name: 'Features',
    path: 'features'
  },
  {
    name: 'Product Limits',
    path: 'productLimits'
  },
  {
    name: 'SSO',
    path: 'sso'
  }
];