<div class="sso-settings">
  <div class="sso-toggle">
    <mat-radio-group [(ngModel)]="authenticationType">
      <mat-radio-button  color="primary" value="SAML">SSO Enabled</mat-radio-button>
      <mat-radio-button  color="primary" value="Password">SSO Disabled</mat-radio-button>
    </mat-radio-group>
  </div>
  <form [formGroup]="ssoForm">
    <div class="saml-config-section" [class.hidden]="authenticationType === 'Password'">
      <div class="sub-domain-field">
        <mat-form-field appearance="outline">
          <input matInput
                 formControlName="subDomainIdentifier"
                 class="sub-domain-input"
                 type="text"
                 placeholder="sub-domain">
          <mat-error *ngIf="subDomainIdentifier?.errors?.required">This field is required</mat-error>
          <mat-error *ngIf="subDomainIsDuplicate">Subdomain is already in use!</mat-error>
        </mat-form-field>
        <span class="sso-domain-text">.app.observepoint.com</span>
        <button mat-flat-button [class.hidden]="authenticationType === 'Password' || ssoForm.hasError('required')" (click)="onDownloadMetadataClick()">Download SP Metadata Config</button>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Certificate</mat-label>
        <input matInput formControlName="certificate" type="text">
        <mat-error *ngIf="certificate?.errors?.required">This field is required</mat-error>
        <mat-hint>Paste body of certificate provided by IDP</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>IDP Login URL</mat-label>
        <input matInput formControlName="idpLoginURL" type="text">
        <mat-error *ngIf="idpLoginURL?.errors?.required">This field is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>IDP Logout URL</mat-label>
        <input matInput formControlName="idpLogoutURL" type="text">
        <mat-error *ngIf="idpLogoutURL?.errors?.required">This field is required</mat-error>
      </mat-form-field>
      <mat-radio-group formControlName="createNewUser">
        <mat-label class="sso-radio-label">Create new user on login</mat-label>
        <mat-radio-button color="primary" [value]="true">Yes</mat-radio-button>
        <mat-radio-button color="primary" [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="sso-save" [class.sso-pull-right]="authenticationType === 'SAML'">
      <button mat-flat-button
              color="primary"
              (click)="saveSSO()"
              [disabled]="(authenticationType === 'SAML' && ((ssoForm.invalid && ssoForm.touched) || (sso.authenticationType === 'SAML' && ssoForm.pristine))) || (authenticationType === 'Password' && sso.authenticationType === 'Password')">
        Save Changes
      </button>
    </div>
  </form>
</div>
