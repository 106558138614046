import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {
  ICustomComponentRef,
  IOpFilterBarV2Filter,
  IOpFilterBarV2FilterValue,
  IOpFilterBarV2InvertableFilter,
  IOpFilterBarV2MenuItem
} from './op-filter-bar-v2.models';
import { StorageService, StorageType } from '@app/components/shared/services/storage.service';
import { CacheResetService, ECacheResetEvent } from '@app/components/core/services/cache-reset.service';
import { ComponentType } from '@angular/cdk/portal';
import { EManageCardsFilterBarMenuStrings, EManageCardsFilterTypes } from '@app/components/manage/cards/manage-cards-filter-bar/manage-cards-filter-bar.constants';

export class OpFilterBarV2Service<EFilterType> {

  protected filters: IOpFilterBarV2Filter<EFilterType>[] = [];
  protected validTypes: EFilterType[] = [];
  private storeFiltersInStorage = true;
  private customComponentsRefs: {[componentRef: string]: ICustomComponentRef} = {};

  relevantFiltersUpdates$ = new BehaviorSubject<IOpFilterBarV2Filter<EFilterType>[]>([]);
  anyFiltersUpdates$ = new BehaviorSubject<IOpFilterBarV2Filter<EFilterType>[]>([]);
  validFilterTypesUpdated$ = new BehaviorSubject<EFilterType[]>([]);
  filterChipRemoved$ = new BehaviorSubject<EFilterType>(null);

  constructor(
    private storage: StorageService,
    private readonly storageKey: string,
    private cacheReset: CacheResetService
  ) {
    this.cacheReset.reset$.subscribe(this.reset.bind(this));
    this.reset();
    this.addFiltersFromLocalStorage();
  }

  private reset(event: ECacheResetEvent = ECacheResetEvent.login) {
    if (event === ECacheResetEvent.login || event === ECacheResetEvent.loginAsOriginal) {
      if (this.storageKey) {
        const savedFilters = this.storage.getValue<IOpFilterBarV2Filter<EFilterType>[]>(this.storageKey, StorageType.Local);
        this.handleFrequencyNameChange(savedFilters);
        const onlyValidFilters = this.removeInvalidFilters(savedFilters);
        const uniqueFilters = this.createUniqueArrayOfFilters(onlyValidFilters);

        this.filters = uniqueFilters || [];
      } else {
        this.filters = [];
      }

      this.storeFiltersInStorage = true;
      this.updateSubscribers();
    } else {
      this.filters = [];
      this.storeFiltersInStorage = false;
      this.updateSubscribers();
    }
  }

  get currentFilters() {
    return this.filters;
  }

  get currentRelevantFilters() {
    return this.filters.filter((filter: IOpFilterBarV2Filter<EFilterType>) => this.validTypes.includes(filter.type));
  }

  get relevantFilterUpdates$(): Observable<IOpFilterBarV2Filter<EFilterType>[]> {
    return this.relevantFiltersUpdates$.pipe(
      map(newFilters =>
        newFilters.filter((filter: IOpFilterBarV2Filter<EFilterType>) => this.validTypes.includes(filter.type))
      )
    );
  }

  isFilteredByTypeAndValue(type: EFilterType, value: IOpFilterBarV2FilterValue) {
    return !!this.filters.find(filter => filter.type === type && value === filter.value);
  }

  addFiltersFromLocalStorage(): void {
    this.filters.forEach((filter: IOpFilterBarV2Filter<EFilterType>) => {
      this.addFilter(filter);
    });
  }

  addCustomComponentRef(componentRef: string, applyFilterAction: (value) => void, component: ComponentType<any>) {
    this.customComponentsRefs[componentRef] = {
      componentRef: component,
      action: applyFilterAction
    };
  }

  getCustomComponentByRef(componentRef: string): ICustomComponentRef {
    const ref = this.customComponentsRefs[componentRef];

    if (!ref) {
      throw new Error('You are trying to initialize component by reference but you probably did not add this component to this service');
    }
    return this.customComponentsRefs[componentRef];
  }

  addFilter(filter: IOpFilterBarV2Filter<EFilterType> | IOpFilterBarV2InvertableFilter<EFilterType>, replaceAll: boolean = false, numChecked?: number, checked?: boolean) {
    const existingFilterTypes = this.filters.map(existingFilter => existingFilter.type);
    const index = existingFilterTypes.indexOf(filter.type);

    if (!replaceAll) {
      if (checked || checked === undefined) {
        filter.value = [...new Set(filter.value.concat(this.filters[index]?.value || []))];
      } else {
        const itemsToRemove = this.filters[index]?.value.filter(item => item === filter.value[0]);
        if (!itemsToRemove) {
          return;
        }
        itemsToRemove.forEach(item => this.filters[index]?.value.splice(this.filters[index]?.value.indexOf(item), 1));
        filter.value = this.filters[index]?.value;
      }
    }

    if (index > -1) {
      this.filters[index].value = filter.value;
      this.filters[index].display = `${filter.menuName === filter.display ? filter.menuName : filter.display}`;
      this.filters[index].menuItems = filter.menuItems;
      if (numChecked && !this.filters[index].display.includes('(')) this.filters[index].display += ` (${numChecked})`;
    } else {
      this.filters.push(filter);
    }

    this.updateSubscribers();
  }

  removeFilter(filter: IOpFilterBarV2Filter<EFilterType>) {
    const remove = this.filters.findIndex(f => JSON.stringify(f) === JSON.stringify(filter));
    if (remove >= 0) {
      this.filters.splice(remove, 1);

      if (this.isValidFilter(filter)) {
        this.filterChipRemoved$.next(filter.type);
        this.updateSubscribers();
      } else {
        this.updateStorage();
      }
    }
  }

  removeFilterByType(filterType: EFilterType) {
    const toRemove = this.currentFilters.filter(f => f.type === filterType);

    toRemove.forEach(filter => {
      const remove = this.filters.findIndex(f => f === filter);
      if (remove >= 0) {
        this.filters.splice(remove, 1);

        if (this.isValidFilter(filter)) {
          this.updateSubscribers();
        } else {
          this.updateStorage();
        }
      }
    });
  }

  clear() {
    if (this.areThereActivatedValidFilters()) {
      this.filters = [];
      this.updateSubscribers();
      //
    } else {
      this.filters = [];
      this.updateStorage();
    }
  }

  updateSupportedFiltersList(list: EFilterType[]): void {
    this.validTypes = list;
    this.validFilterTypesUpdated$.next(list);
  }

  rebuildChipMenusOnPageLoad(menuItems: IOpFilterBarV2MenuItem[]): void {
    const menuNames = menuItems.map((item: IOpFilterBarV2MenuItem) => item.name);
    this.filters.forEach((filter: IOpFilterBarV2Filter<EFilterType>) => {
      let index = menuNames.indexOf(filter.menuName);
      filter.menuItems = menuItems[index]?.children || filter.menuItems;
    });
  }

  protected updateSubscribers(): void {
    const uniqueFilters = this.createUniqueArrayOfFilters(this.filters);
    this.relevantFiltersUpdates$.next(uniqueFilters);

    this.updateStorage();
  }

  protected updateStorage() {
    const uniqueFilters = this.createUniqueArrayOfFilters(this.filters);

    if (this.storeFiltersInStorage && this.storageKey) {
      this.storage.setValue(this.storageKey, uniqueFilters, StorageType.Local);
    }

    this.anyFiltersUpdates$.next(uniqueFilters);
  }

  protected removeInvalidFilters(filters: IOpFilterBarV2Filter<EFilterType>[]): IOpFilterBarV2Filter<EFilterType>[] {
    return filters;
  }

  protected createUniqueArrayOfFilters(filters: IOpFilterBarV2Filter<EFilterType>[]): IOpFilterBarV2Filter<EFilterType>[] {
    if (!filters) return [];

    // Unique filters
    const filterStringsToObjects = filters.reduce<{ [key: string]: IOpFilterBarV2Filter<EFilterType> }>((acc, filter) => {
      acc[JSON.stringify(filter)] = filter;
      return acc;
    }, {});

    return Object.values(filterStringsToObjects);
  }

  private isValidFilter(filter: IOpFilterBarV2Filter<EFilterType>) {
    return this.validTypes.includes(filter.type);
  }

  private areThereActivatedValidFilters() {
    return this.validTypes.some(validFilter => !!this.filters.find(filter => filter.type === validFilter));
  }

  private handleFrequencyNameChange(filters: IOpFilterBarV2Filter<EFilterType>[]): IOpFilterBarV2Filter<EFilterType>[] {
    const frequencyFilter = filters?.find(filter => filter.type === EManageCardsFilterTypes.Frequency);
    if (!frequencyFilter || frequencyFilter.display.includes(EManageCardsFilterBarMenuStrings.DataSourceFrequency)) return filters;

    const filterCount = frequencyFilter.display.split('(')[1];
    frequencyFilter.display = `${EManageCardsFilterBarMenuStrings.DataSourceFrequency} (${filterCount}`;
    return filters;
  }
}
