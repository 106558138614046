import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'abbreviateNumber'
})
export class AbbreviateNumberPipe implements PipeTransform {

  transform(value: number): string {
    let newValue: string | number = value;

    // Resolving Sentry event 093bb077f99e40cda26f0f8ed633ebba where
    // newValue can be undefined. Unable to reproduce the case where this happens,
    // but this will at least prevent the app from crashing and being broken
    // on the page where this occurs. In cases where this happens, we do not
    // have a way to display a number, so returning a generic empty string.
    if (newValue === undefined) return '---';

    const suffixes = ["", "K", "M", "B","T"];
    let suffixNum = 0;
    while (newValue >= 1000) {
      newValue /= 1000;
      suffixNum++;
    }

    newValue = newValue.toPrecision(3);
    newValue += suffixes[suffixNum];
    return newValue;
  }

}
