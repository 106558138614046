import { Component, Input } from '@angular/core';
import { IAccountUsageV2SummaryResponseDTO } from '@app/components/usage-v2/usage-v2.models';
import { EAlertUsageMetricV2 } from '@app/components/alert/alert-logic/alert-logic.enums';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { Observable } from 'rxjs';
import { IUser } from '@app/moonbeamModels';
import { EAlertMenuContext } from '@app/components/alert/alert.enums';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-top-widgets',
  templateUrl: './usage-top-widgets.component.html',
  styleUrls: ['./usage-top-widgets.component.scss']
})
export class UsageTopWidgetsComponent {
  EAlertMenuContext = EAlertMenuContext;

  @Input() summary: IAccountUsageV2SummaryResponseDTO;
  @Input() user: IUser;

  isVisitorMode$: Observable<boolean>;
  usageAlertMetric = EAlertUsageMetricV2.UsersLoggedInLast30Days;

  constructor(private applicationChromeService: ApplicationChromeService) {
    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
  }

}
