import { IFolder } from '../../folder/foldersApiService';
import { IDomain } from '../../domains/domainsService';
import { IAuditModel } from '../../modals/modalData';

export abstract class IManageNewCardsService {
  abstract getIds(items: Array<{id: number}>): Array<number>;

  abstract setNewItems(items: Array<IFolder | IDomain | IAuditModel>, currentIds: Array<number>): void;
}

export class ManageNewCardsService extends IManageNewCardsService {

  getIds(items: Array<IFolder | IDomain | IAuditModel>): Array<number> {
    if (items && items.length > 0) {
      return items.map(x => x.id);
    }
    return [];
  }

  setNewItems(items: Array<IFolder | IDomain | IAuditModel>, currentIds: Array<number>): void {
    const updatedIds = this.getIds(items);
    const newIds = updatedIds.filter(updatedId => !currentIds.find(currentId => updatedId == currentId));

    if (items) {
      items.filter(x => newIds.includes(x.id)).forEach(x => x.isNew = true);
    }
  }

}
