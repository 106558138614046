import { Names } from '@app/moonbeamConstants';
import * as angular from 'angular';
import { ManageTagsService } from './manageTags/manageTagsService';
import { ApiKeyService } from './keys/apiKeysService';
import { ManageTagsController } from './manageTags/manageTagsController';
import { TagContactsDirective } from './manageTags/tagContactsPopover/tagContactsPopover';

export default angular.module('accountModule', [])
  .service(Names.Services.apiKeysService, ApiKeyService)
  .controller(Names.Controllers.manageTags, ManageTagsController)
  .service(Names.Services.manageTags, ManageTagsService)
  .directive(Names.Directives.tagContactsPopover, TagContactsDirective.factory())
  .name;
