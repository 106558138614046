import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { MatRadioChange } from '@angular/material/radio';
import {
  IOpFilterBarV2MenuItemCustomComponent
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import {
  IOpFilterBarV2MenuCustomComponent
} from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-menu-custom-component-item-v2/abstract/op-filter-bar-menu-custom-component.interface';
import {
  EOpFilterBarItemDatapickerRanges,
  IOpFilterBarItemDatepickerRangeState
} from './op-filter-bar-item-datepicker.models';
import { Subject } from 'rxjs';
import {
  OpFilterBarItemDatepickerService
} from '@app/components/shared/components/date-range-filter/op-filter-bar-item-datepicker.service';
import { filter, takeUntil } from 'rxjs/operators';
import { OpFilterBarItemDatepickerUtils } from './op-filter-bar-item-datepicker.utils';

/*
*
* It returns:
* case AllAvailableDays: DateRange(null, null)
* case Last30Days: DateRange(-30d, now)
* case Last60Days: DateRange(-60d, now)
* case Last90Days: DateRange(-90d, now)
*
* */

@Component({
  selector: 'op-filter-bar-item-datepicker',
  styleUrls: ['./op-filter-bar-item-datepicker.component.scss'],
  templateUrl: './op-filter-bar-item-datepicker.component.html'
})
export class OpFilterBarItemDatepickerComponent implements IOpFilterBarV2MenuCustomComponent, OnInit, OnDestroy {
  EOpFilterBarItemDatepickerRanges = EOpFilterBarItemDatapickerRanges;
  private destroySubject = new Subject<void>();
  readonly dateFormat = 'MMM d, yyyy';

  @Input() item: IOpFilterBarV2MenuItemCustomComponent<IOpFilterBarItemDatepickerRangeState>;

  selectedOption = EOpFilterBarItemDatapickerRanges.CustomDateRange;
  today = new Date();
  lastSelectedDate = null;
  readonly defaultDateRange = OpFilterBarItemDatepickerUtils.getDefaultDateRange();

  constructor(private cdr: ChangeDetectorRef, private opFilterBarItemDatepickerService: OpFilterBarItemDatepickerService) { }

  onSelectedRangeChange(date: Date): void {
    let dates: DateRange<Date>;
    if (this.lastSelectedDate) {
      if (this.lastSelectedDate > date) {
        dates = new DateRange(
          date,
          this.lastSelectedDate
        );
      } else {
        dates = new DateRange(
          this.lastSelectedDate,
          date
        );
      }

      const rangeState = {
        type: EOpFilterBarItemDatapickerRanges.CustomDateRange,
        dates
      }
      this.item.value = OpFilterBarItemDatepickerUtils.prepareRangeState(rangeState);

      this.item.action(this.item.value);
      this.lastSelectedDate = null;
      this.selectedOption = EOpFilterBarItemDatapickerRanges.CustomDateRange;

    } else {
      dates = new DateRange(
        date,
        null
      );
      this.item.value = {
        type: EOpFilterBarItemDatapickerRanges.CustomDateRange,
        dates
      };

      this.lastSelectedDate = date;
    }

    this.opFilterBarItemDatepickerService.sync({
      component: this,
      item: this.item
    });
  }

  preparedRangeSelected(value: EOpFilterBarItemDatapickerRanges, syncAfterChange = true) {
    const range = {
      type: value,
      dates: this.item.value.dates
    };
    this.item.value = OpFilterBarItemDatepickerUtils.prepareRangeState(range);

    this.selectedOption = value;
    this.item.action(this.item.value);

    if (syncAfterChange) {
      this.opFilterBarItemDatepickerService.sync({
        component: this,
        item: this.item
      });
    }

    this.cdr.detectChanges();
  }

  onPreparedDateRangeSelect(dateRange: MatRadioChange) {
    this.preparedRangeSelected(dateRange.value);
  }

  ngOnInit() {
    this.opFilterBarItemDatepickerService
      .subscribeToUpdates()
      .pipe(
        filter(value => value.component !== this),
        filter(value => !!value.item),
        filter(value => value.item.customComponentRef === this.item.customComponentRef),
        takeUntil(this.destroySubject)
      )
      .subscribe(value => {
        this.item.value = value.item.value;
        this.cdr.detectChanges();
      });

    this.opFilterBarItemDatepickerService.getUpdatePredefinedDates()
      .subscribe(value => this.preparedRangeSelected(this.selectedOption, false));
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
