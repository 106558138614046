import { ITagVariableAlignment } from '@app/components/comparison-library/comparison-library.model';

export namespace ITagWithVariables {
  export function toTagVariableAlignment(tag: ITagWithVariables): ITagVariableAlignment {
    return {
      tagId: tag.id,
      variables: tag.variables
    };
  }
}

export interface ITag {
  id: number;
  name: string;
  icon: string;
}

export interface ITagWithVariables extends ITag{
  variables: string[];
}
