<table mat-table [dataSource]="dataSource" 
                  *ngIf="dataSource.data.length"
                  [ngClass]="{
                  'show-comparison': showComparison,
                  'is-admin': isCurrentUserAdmin 
                  }">

  <ng-container matColumnDef="variables">
    <td mat-cell *matCellDef="let variable" class="data-cell name-cell">
      <ng-container *ngIf="variable.name !== variable.description">
        {{variable.description}} ({{variable.name}})
      </ng-container>
      <ng-container *ngIf="variable.name === variable.description">
        {{variable.name}}
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="previousValue">
    <td mat-cell *matCellDef="let variable" class="data-cell">
      {{ variable.previousValue }}
    </td>
  </ng-container>

  <ng-container matColumnDef="currentValue">
    <td mat-cell *matCellDef="let variable" class="data-cell">
      {{ variable.currentValue }}
    </td>
  </ng-container>

  <ng-container matColumnDef="outcome">
    <td mat-cell *matCellDef="let variable" class="data-cell">
      <div [class.has-change]="variable.outcome !== outcomeToLabelMap.get(comparisonOutcomes.match)">
        {{ variable.outcome }}
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="excludeVariable">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let variable" class="exclude-var-cell">
      <mat-icon class="exclude-btn"
                *ngIf="isCurrentUserAdmin"
                [matTooltip]="variable.excluded ? 
                              'Variable is excluded in account settings' : 
                              'Exclude Variable in account settings'" 
                matTooltipPosition="above" 
                matTooltipShowDelay="250"
                [ngClass]="{'excluded': variable.excluded}" 
                (click)="!variable.excluded && excludeVariable(variable)">
        visibility_off
      </mat-icon>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div *ngIf="!dataSource.data.length" class="no-variables">
  <p>
    <i *ngIf="tagVariables?.length > 0; else noVariables">No changes to variables</i>
    <ng-template #noVariables><i>No variables</i></ng-template>
  </p>
</div>
