export enum EVarComparisonOutcome {
  match = 'MATCH',
  valueChanged = 'VALUE_CHANGED',
  valueAbsent = 'VALUE_ABSENT',
  valueAdded = 'VALUE_ADDED',
  variableAbsent = 'VARIABLE_ABSENT',
  variableAdded = 'VARIABLE_ADDED',
  tagAbsent = 'TAG_ABSENT',
  tagAdded = 'TAG_ADDED'  
}

export enum ETagComparisonOutcome {
  match = 'MATCH',
  tagAbsent = 'TAG_ABSENT',
  tagAdded = 'TAG_ADDED',
  variableMismatch = 'VARIABLE_MISMATCH'
}
