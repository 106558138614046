import { CacheResetService } from './../core/services/cache-reset.service';
import { EAssignmentType } from '@app/models/commons';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { environment } from '@app/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import {
  IComparisonSummary,
  IComparisonRun,
  IComparisonRunResponse,
  IComparisonItemPreview,
  IComparisonLibraryFromAPI
} from './comparison-library.model';
import { tap, concatMap, map, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ComparisonLibraryService {
  
  comparisonsApiUrl = environment.apiV3Url + 'comparisons';
  auditsApiUrl = environment.apiUrl + 'web-audits';

  /**
   * map with cached comparison runs
   */
  comparisonRunMap = new Map<number, IComparisonRun[]>();

  constructor(private snackBar: MatSnackBar,
              private apiService: ApiService,
              private cacheResetService: CacheResetService) {

    this.cacheResetService.reset$.subscribe(_ => {
      this.comparisonRunMap.clear();
    });

  }
  
  getComparisonSummary(): Observable<IComparisonSummary[]> {
    return this.apiService.get<IComparisonSummary[]>(this.comparisonsApiUrl);
  }
  
  getComparisonLibrary(): Observable<IComparisonLibraryFromAPI[]> {
    return this.apiService.get(`${this.comparisonsApiUrl}/library`);
  }

  deleteComparison(comparisonId: number): Observable<void> {
    return this.apiService.delete<void>(`${this.comparisonsApiUrl}/${comparisonId}`).pipe(
      tap(() => {
        this.comparisonRunMap.delete(comparisonId);
      })
    );
  }

  runComparison(comparisonId: number, comparisonName: string): Observable<void> {
    return this.apiService.post<void>(`${this.comparisonsApiUrl}/${comparisonId}/start`).pipe(
      tap(() => this.showInfoMessage(`Comparison "${comparisonName}" has been started!`)),
      catchError(err => {
        this.showInfoMessage(`Comparison "${comparisonName}" is already in progress!`);
        return throwError(err);
      })
    );
  }

  private showInfoMessage(message: string): void {
    this.snackBar.open(
      message,
      '',
      { duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom' }
    );
  }

  /**
   * Tightly coupled with Audits (API contract for Web Journeys isn't known yet)
   */
  getComparisonRuns(comparisonId: number): Observable<IComparisonRun[]> {
    const cachedRuns = this.comparisonRunMap.get(comparisonId);
    if (cachedRuns) return of(cachedRuns);

    return this.apiService.get(`${this.comparisonsApiUrl}/${comparisonId}/runs`).pipe(
      concatMap((runs: IComparisonRunResponse[]) => {
        const auditIds = this.getItemIds(EAssignmentType.audit, runs);
        return this.fetchItemPreviews(EAssignmentType.audit, auditIds).pipe(
          map((items: IComparisonItemPreview[]) => runs.map(run => IComparisonRunResponse.toComparisonRun(run, items))),
          tap((runs: IComparisonRun[]) => this.comparisonRunMap.set(comparisonId, runs))
        );
      })
    );
  }

  resetComparisonRun(comparisonId: number): void {
    this.comparisonRunMap.delete(comparisonId);
  }

  updateComparisonLabels(comparisonId: number, labelIds: number[] = []): Observable<void> {
    return this.apiService.put(`${this.comparisonsApiUrl}/${comparisonId}/labels`, labelIds);
  }

  private getItemIds(itemType: EAssignmentType, runs: IComparisonRunResponse[]): number[] {
    const itemRuns = runs.filter(r => r.itemType === itemType)
                         .map(r => r.itemId);
    return Array.from(new Set(itemRuns));
  }

  private fetchItemPreviews(itemType: EAssignmentType, itemsIds: number[]): Observable<IComparisonItemPreview[]> {
    switch (itemType) {
      case EAssignmentType.audit:
        return this.apiService.post(`${this.auditsApiUrl}/previews`, itemsIds);
      case EAssignmentType.webJourney:
        console.log(`Comparisons for Web journey aren't implemented yet.`);
        return of([]);
    }
  }

}
