<section class="section">
  <h3 class="section-title">COMPLETION & ALERT NOTIFICATION</h3>

  <div class="explanation-text">
    <p class="explanation-text-paragraph">
      People subscribed to the assigned Alert and Audit Completion notification
      email will receive one combined message containing any triggered alerts and
      audit summary metrics, this will be sent to the users specified below -
      links inside the email will be publicly accessible for users without ObservePoint accounts.
      <a href="https://app.observepoint.com/account/shared-links"
         target="_blank"
         class="highlight">Public links</a>
      can be enabled or disabled by an account administrator.
    </p>
  </div>

  <op-recipients-form-control [formControl]="onProcessedSubscribers"
                              [hideHint]="true"
                              [label]="'COMPLETION SUMMARY NOTIFICATION (ENTER EMAIL ADDRESSES)'"
                              [shouldEmitOnWriteValue]="true"
                              (onEmailsChanged)="updateProcessedSubscribers($event)"
                              [maxEmailsLength]="1000"
                              floatLabel="always"
  ></op-recipients-form-control>
</section>

<section class="section">
  <h3 class="section-title">EMAIL MESSAGE RECEIVED NOTIFICATION</h3>

  <section *ngIf="confirmationCheckbox"
           class="confirmation-checkbox-section">
    <mat-checkbox color="primary"
                  [formControl]="confirmationCheckbox"
                  (change)="updateShouldSaveReceivedSubscribers()"
                  class="confirmation-checkbox">
      <div class="confirmation-checkbox-label">
        Send email confirmation when any email messages are received by this inbox.
        <mat-icon class="info-icon"
                  [matTooltip]="
                  'You will receive a confirmation email each time a new message arrives in this inbox. ' +
                  'The confirmation email includes a screenshot of the received message and the number of links it contains. ' +
                  'Even if you do not select this option, your emails will still be validated.'
        ">
          info_outline
        </mat-icon>
      </div>
    </mat-checkbox>
  </section>

  <op-recipients-form-control [formControl]="onReceivedSubscribers"
                              [hideHint]="true"
                              [label]="'EMAIL ADDRESS'"
                              [isReadOnly]="!confirmationCheckboxValue"
                              [shouldEmitOnWriteValue]="true"
                              (onEmailsChanged)="updateReceivedSubscribers($event)"
                              [maxEmailsLength]="1000"
                              floatLabel="always"
  ></op-recipients-form-control>
</section>
