import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { Observable } from 'rxjs';
import { EAuditPopoverStates } from '../audit-report-header-popover.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'no-alerts-not-subscribed',
  templateUrl: './no-alerts-not-subscribed.component.html',
  styleUrls: ['./no-alerts-not-subscribed.component.scss']
})
export class NoAlertsNotSubscribedComponent {

  EAuditPopoverStates = EAuditPopoverStates;
  isVisitorMode$: Observable<boolean>;

  @Input() viewToShow: EAuditPopoverStates = EAuditPopoverStates.NoAlertsInAccount;
  @Output() routeToAlertsSummary: EventEmitter<void> = new EventEmitter();

  constructor(private applicationChromeService: ApplicationChromeService) {
    this.isVisitorMode$ = this.applicationChromeService.isVisitorMode$;
  }
}
