import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IChangeEmailDialogData,
  IChangeEmailPayload
} from '@app/components/notifications-center/notification-center.models';
import { IButton } from '@app/models/commons';
import { FormControl, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent implements OnInit, OnDestroy {
  rightFooterButtons: IButton[] = [{
    label: 'Save',
    action: () => this.save(),
    primary: true,
    disabled: true,
  }];

  emails: FormControl;
  formValiditySubscription: Subscription;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.emails.value.length) {
      $event.preventDefault();
      $event.returnValue = true;
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IChangeEmailDialogData,
    private dialogRef: MatDialogRef<ChangeEmailComponent>,
  ) {
  }

  private initForm(): void {
    this.emails = new FormControl<string[]>(
      [],
      [Validators.required]
    );
    this.formValiditySubscription = this.emails.statusChanges
      .pipe(startWith(this.emails.invalid))
      .subscribe(() => this.rightFooterButtons[0].disabled = this.emails.invalid);
  }

  close(payload?: IChangeEmailPayload): void {
    this.dialogRef.close(payload);
  }

  save(): void {
    this.close({
      emails: this.emails.value,
      profile: this.data.profile,
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.formValiditySubscription?.unsubscribe();
  }
}
