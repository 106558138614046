<ng-container *ngIf="contact">
  <div class="top-row">
    <img [src]="contact.imageUrl"
         [alt]="contact.name"
         class="image"/>
    <div class="text">
      <p class="title">{{ contact.title }}</p>
      <p class="name">{{ contact.name }}</p>
      <p class="email">{{ contact.email }}</p>
    </div>
  </div>

  <div class="mid-row">
    <a [href]="slackInviteLink"
       target="_blank"
       class="btn"
       matTooltipPosition="above"
       [matTooltip]="slackBtnTooltip">
      <img src="images/slack_icon.svg"
           alt="Slack"
           class="slack-icon">message me</a>

    <a [href]="'mailto:' + contact.email"
       target="_blank"
       class="btn">
      <mat-icon class="material-symbols-outlined">mail</mat-icon>
      email
    </a>

    <button *ngIf="contact.type === ESupportContactType.CSM"
            class="btn"
            [attr.op-selector]="supportBtnSelector">
      <mat-icon class="material-symbols-outlined">support</mat-icon>
      support
    </button>
  </div>

  <p class="description">{{ contact.description }}</p>
</ng-container>
