import { IWebJourney } from '@app/components/web-journey/web-journey.models';
import { Names } from '@app/moonbeamConstants';
import { IAmount } from '../../models/utils/amount';
import { IAuditModel } from '../modals/modalData';
import { IApiService } from '../api/apiService';
import * as angular from 'angular';
import { environment } from '@app/environments/environment';
import { from, Observable } from 'rxjs';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';

export interface ICreateDomainRequest {
  name: string;
  domain: string;
  folderId: number;
  dataLayer?: string;
}

export interface IUpdateDomainRequest {
  id: number;
  name: string;
  domain: string;
  dataLayer?: string;
  folderId: number;
}

export interface IDomain {
  id: number;
  folderId: number;
  name?: string;
  domain?: string;
  screenshot?: string;
  dataLayer?: string;
  totals?: IDomainTotals;
  createdAt?: any;
  userId?: number;
  isNew?: boolean;
  automatedJourneys?: number;
  guidedJourneys?: number;
  folderName?: string;
}

interface IDomainTotals {
  audits: number;
  simulations: number;
  pages: number;
  errors: number;
  averageScore: number;
}

export interface IDomainSelected extends IDomain {
  selected: boolean;
  indeterminate: boolean;
}

export abstract class IDomainsService {
  abstract getDomain(id: number, withTotals?: boolean): angular.IPromise<IDomain>;
  abstract getDomains(withTotals?: boolean, folderId?: number): angular.IPromise<IDomain[]>;
  abstract getDomainsObservable(withTotals?: boolean, folderId?: number): Observable<IDomain[]>;
  abstract getAllDomains(): angular.IPromise<IDomain[]>;
  abstract getDomainsCount(): angular.IPromise<IAmount>;
  abstract createDomain(domain: ICreateDomainRequest): angular.IPromise<IDomain>;
  abstract updateDomain(domain: IUpdateDomainRequest): angular.IPromise<IDomain>;
  abstract removeDomain(id: number): angular.IPromise<boolean>;
  abstract structureAuditsDates(auditsPromise: any): angular.IPromise<IAuditModel[]>;
  abstract getAuditsByDomain(id: number): angular.IPromise<IAuditModel[]>;
  abstract getSimulationsByDomain(id: number): angular.IPromise<any[]>;
}

const root = environment.apiUrl;

export class DomainsService extends IDomainsService {

  static $inject = [
    '$http',
    '$q',
    Names.Services.api
  ];

  constructor(
    private $http: angular.IHttpService,
    private $q: angular.IQService,
    private apiService: IApiService
  ) {
    super();
  }

  getDomain(id: number, withTotals: boolean = false): angular.IPromise<IDomain> {
    var url = `${root}domains/${id}?withTotals=${withTotals}`;
    return this.apiService.handleResponse<IDomain>(this.$http.get(url));
  }

  getDomains(withTotals: boolean = false, folderId: number = 0) {
    var url = root + 'domains?withTotals=' + withTotals;
    if (folderId !== 0) {
      url += `&folderId=${folderId}`;
    }
    return this.apiService.handleResponse<IDomain[]>(this.$http.get(url), null, 'domains-spinner');
  }

  @CacheApiResponse({ liveTime: 5000 })
  getDomainsObservable(withTotals: boolean = false, folderId: number = 0): Observable<IDomain[]> {
    let url = root + 'domains?withTotals=' + withTotals;
    if (folderId !== 0) {
      url += `&folderId=${folderId}`;
    }
    return from(this.apiService.handleResponse<IDomain[]>(this.$http.get(url), null, 'domains-spinner'));
  }

  getAllDomains(): angular.IPromise<IDomain[]> {
    var url = root + 'domains';
    return this.apiService.handleResponse<IDomain[]>(this.$http.get(url));
  }

  getDomainsCount() {
    var url = root + 'domains/count';
    return this.apiService.handleResponse<IAmount>(this.$http.get(url));
  }

  createDomain(domain: ICreateDomainRequest) {
    return this.apiService.handleResponse<IDomain>(this.$http.post(root + 'domains', domain)).then(newDomain => {
      newDomain.automatedJourneys = 0,
        newDomain.guidedJourneys = 0;
      return newDomain;
    });
  }

  updateDomain(domain: IUpdateDomainRequest) {
    return this.apiService.handleResponse<IDomain>(this.$http.put(root + 'domains/' + domain.id, domain));
  }

  removeDomain(id: number): angular.IPromise<boolean> {
    return this.apiService.handleResponse(this.$http.delete(`${root}domains/${id}`)).then(() => {
      return true;
    });
  }

  structureAuditsDates(auditsPromise): angular.IPromise<Array<IAuditModel>> {
    var defer = this.$q.defer<IAuditModel[]>();
    auditsPromise.then((audits: any) => {
      for (var i = 0; i < audits.length; i++) {
        audits[i].created = (audits[i].created) ? new Date(audits[i].created) : null;
        audits[i].lastRun = (audits[i].lastRun) ? new Date(audits[i].lastRun) : null;
        audits[i].lastUpdated = (audits[i].lastUpdated) ? new Date(audits[i].lastUpdated) : null;
        audits[i].nextRun = (audits[i].nextRun) ? new Date(audits[i].nextRun) : null;
      }
      defer.resolve(audits);
    }, (response) => {
      defer.reject(response);
    });
    return defer.promise;
  }

  getAuditsByDomain(id: number) {
    return this.structureAuditsDates(this.apiService.handleResponse(this.$http.get(`${root}domains/${id}/web-audits`)));
  }

  getSimulationsByDomain(id: number): angular.IPromise<Array<IWebJourney>> {
    return this.apiService.handleResponse(this.$http.get(`${root}domains/${id}/web-journeys`));
  }
}
