import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EChartChangeTypes } from '../page-information/page-information.constants';
import {
  ISparklineChartConfig,
  ISparklineChartData
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sparkline-chart-insight',
  templateUrl: './sparkline-chart-insight.component.html',
  styleUrls: ['./sparkline-chart-insight.component.scss']
})
export class SparklineChartInsightComponent implements OnChanges {

  chartConfig: ISparklineChartConfig;
  uniqueId: number;
  changeMeaning: EChartChangeTypes;
  changeValue: number;
  changeDirection: number = 0;

  @Input() dataPoints: ISparklineChartData[];
  @Input() dataLoaded?: boolean = true;
  @Input() meaningOfUp: EChartChangeTypes = EChartChangeTypes.neutral;
  @Input() meaningOfNoChange: EChartChangeTypes = EChartChangeTypes.none;
  @Input() label: string;

  constructor() {
    this.chartConfig = {
      data: [],
    };
    this.uniqueId = Math.floor(Math.random() * 1000);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataPoints?.currentValue) {
      let data = changes.dataPoints.currentValue;
      if (data.length > 5) {
        data = data.slice(data.length - 5, data.length);
      }
      this.chartConfig = {
        data
      };
      const lastDataPt = data[data.length - 1];
      if (data.length > 1) {
        const compDataPt = data[data.length - 2];
        this.changeDirection = Math.round(lastDataPt.value * 1000 - compDataPt.value * 1000) / 1000;
        this.changeValue = Math.abs(this.changeDirection);
        if (this.changeDirection === 0) {
          this.changeMeaning = this.meaningOfNoChange;
        } else if (this.meaningOfUp === EChartChangeTypes.positive) {
          this.changeMeaning = this.changeDirection > 0 ? EChartChangeTypes.positive : EChartChangeTypes.negative;
        } else if (this.meaningOfUp === EChartChangeTypes.negative) {
          this.changeMeaning = this.changeDirection > 0 ? EChartChangeTypes.negative : EChartChangeTypes.positive;
        } else {
          this.changeMeaning = this.meaningOfUp;
        }
      } else if (data.length === 1) {
        this.changeMeaning = EChartChangeTypes.none;
        this.changeValue = lastDataPt.value;
        this.changeDirection = 0;
      }
    }
  }
}
