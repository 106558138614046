import { IFolder } from '../../components/folder/foldersApiService';
import { firstOrGiven, givenOrList, toJSOrGiven } from './selectorHelpers';

export abstract class IFolderSelector {
  abstract selectAllFolders(state): Array<IFolder>;
  abstract selectFolderById(state, folderId: number): IFolder;
}

export class FolderSelector extends IFolderSelector {
  private getFolders(state) {
    return givenOrList(state.folders.get('all'));
  }

  selectAllFolders(state): Array<IFolder> {
    return toJSOrGiven<Array<IFolder>>(state.folders.get('all'), undefined);
  };

  selectFolderById(state, folderId: number): IFolder {
    return firstOrGiven(this.getFolders(state).filter(folderMap => folderMap.get('id') == folderId).toJS(), undefined);
  }

}

