import { Component, Input, OnDestroy } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import {
  EAlertFilterType,
  EAlertMenuContext,
  EAlertModalType,
  EAlertResultStatus
} from '@app/components/alert/alert.enums';
import { EAlertBellStatus, EAlertBellType } from '@app/components/alert/alert-bell/alert-bell.enums';
import { IAuditSummaryAlert } from '@app/components/audit/audit.models';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { AlertService } from '@app/components/alert/alert.service';
import {
  IAlertEditModalPayload,
  IAlertModalPayload,
  IAlertRequestItem,
  IAlertSummaryAlert
} from '@app/components/alert/alert.models';
import { AlertComponent } from '@app/components/alert/alert.component';
import { AccountsService } from '@app/components/account/account.service';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { IOpFilterBarV2Filter } from '@app/components/shared/components/op-filter-bar-v2/op-filter-bar-v2.models';
import { EUsageFilterTypes } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.enums';
import { UsageFilterBarService } from '@app/components/usage-v2/components/usage-filter-bar/usage-filter-bar.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'usage-header-alerts',
  templateUrl: './usage-header-alerts.component.html',
  styleUrls: ['./usage-header-alerts.component.scss']
})
export class UsageHeaderAlertsComponent implements OnDestroy {
  @Input() filters: IOpFilterBarV2Filter<EUsageFilterTypes>[];

  EAlertBellType = EAlertBellType;

  private destroy$ = new Subject();

  isReadOnly: boolean = false;
  status: EAlertBellStatus;
  alertCount: number;
  tooltip: string;

  alerts: IAlertSummaryAlert[] = [];

  constructor(
    private modalService: OpModalService,
    private alertService: AlertService,
    private accountsService: AccountsService,
    private usageFilterBarService: UsageFilterBarService,
    private alertReportingService: AlertReportingService
  ) {

    forkJoin([
      this.accountsService.getUser(),
      this.alertReportingService.getUsageAlertSummary()
    ])
      .subscribe(([user, usageAlertsSummary]) => {
        this.isReadOnly = user.permissions === 'guest';
        this.alerts = usageAlertsSummary.alerts;

        this.alertCount = this.alerts.filter((alert) => alert.result.status === EAlertResultStatus.Triggered).length;
        this.setBellStatuses();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  setBellStatuses(): void {
    const userIsSubscribed = this.alerts.map((alert) => {
      return alert.config?.isUserSubscribed;
    }).includes(true);

    // no bell/plus bell - no alerts
    if (this.alerts.length === 0) {
      this.status = EAlertBellStatus.CreateAlert;
      this.tooltip = `Create a Usage alert`;
      return;
    }

    // outlined bell - alerts exist but user isn't subscribed
    if (this.alerts.length > 0 && !userIsSubscribed) {
      const status = this.alerts.findIndex(alert => alert.result?.status === EAlertResultStatus.Triggered);
      this.status = status > 0
        ? EAlertBellStatus.ConfiguredAndTriggeredAlerts
        : EAlertBellStatus.NoSubscribedAlerts;
      this.tooltip = `Modify/Subscribe to ${this.alerts.length > 1 ? 'an' : 'this'} alert`;
      return;
    }

    // ringing bell - one or more (count of alerts doesn't matter here)
    if (this.alerts.length > 0 && userIsSubscribed) {
      // because we're getting alerts by metric each array will only contain
      // one object so it's safe to hardcode index 0 here
      const status = this.alerts.findIndex(alert => alert.result?.status === EAlertResultStatus.Triggered);
      this.status = status >= 0
        ? EAlertBellStatus.TriggeredAlerts
        : EAlertBellStatus.ConfiguredAndSubscribedAlerts;
      this.tooltip = `Modify ${this.alerts.length > 1 ? 'an' : 'this'} alert`;
      return;
    }
  }

  openAlertEditor(alert: IAuditSummaryAlert | IAlertRequestItem): void {
    let data: IAlertEditModalPayload = {
      type: EAlertModalType.Edit,
      alertId: alert.id,
      filterType: EAlertFilterType.V2,
      menuContext: EAlertMenuContext.Usage,
    };

    this.modalService.openFixedSizeModal(AlertComponent, { disableClose: true, data, autoFocus: false });
  }

  openCreateModal(): void {

    if (this.isReadOnly) return;

    const data: IAlertModalPayload = {
      type: EAlertModalType.CreateWithFilters,
      menuContext: EAlertMenuContext.Usage,
      filterType: EAlertFilterType.V2,
      filters: this.usageFilterBarService.generateApiPostBody(this.filters)
    };

    this.modalService.openFixedSizeModal(AlertComponent, {
      disableClose: true,
      data,
      autoFocus: false
    }).afterClosed().subscribe(alert => {
    });
  }

  // Either reload the route to the alert and apply filters or edit the selected alert.
  handleNameClick(alert): void {
    let alertForClick = { id: alert.id, metricType: alert.config?.metricType };
    this.alertService.navigateToAlert(alertForClick as IAuditSummaryAlert);
  }
}
