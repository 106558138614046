<mat-expansion-panel class="page-issue-details" hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="panel-title">
        <div class="expand-arrow">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
        <div class="counter">
            <span class="current">
              <ng-content select="[current]"></ng-content>
            </span>
          <ng-content select="[of-total]"></ng-content>
        </div>
        -
        <div class="title copy-wrapper">
          {{ selectors && selectors[0] || 'unknown' }}
          <button class="copy-button" mat-icon-button
                  (click)="$event.stopPropagation();copy(selectors)">
            <mat-icon class="copy-icon">content_copy</mat-icon>
          </button>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="panel-content">
    <p *ngIf="selectors?.length > 1">Element selectors ({{ selectors?.length }})</p>
    <div class="selectors">
      <div class="selectors-css" [class.has-display-all]="selectors?.length > 1" >
        <div *ngFor="let selector of selectors; let i = index;" class="copy-wrapper">
          <ng-container *ngIf="i === 0">
            <span class="selectors-label">
              <ng-container *ngIf="selectors.length > 1; else singleSelector">
                {{ i + 1 }} of {{ selectors.length }}:
              </ng-container>
            </span>
              <span class="selectors-code">
              {{ selector }}
            </span>
          </ng-container>
          <ng-container *ngIf="displayAll && i > 0">
            <span class="selectors-label">
            <ng-container *ngIf="selectors.length > 1; else singleSelector">
              {{ i + 1 }} of {{ selectors.length }}:
            </ng-container>
          </span>
            <span class="selectors-code">
            {{ selector }}
          </span>
          </ng-container>
          <mat-icon
            class="copy-button"
            matTooltip="Copy to clipboard"
            matTooltipPosition="above"
            ngxClipboard
            [cbContent]="selector">
            content_copy
          </mat-icon>
        </div>
        <button class="display-all-btn"
                *ngIf="selectors?.length > 1"
                [class.expanded]="displayAll"
                (click)="toggleDisplayAll()">
          <span class="display-all-btn-content">
          - {{ displayAll ? 'Hide All' : 'See All' }} {{ selectors.length }} Selectors
            <mat-icon class="display-all-btn-arrow">keyboard_arrow_down</mat-icon>
          </span>
        </button>
      </div>
      <div class="selectors-html">
        <div class="copy-wrapper">
          {{ issue?.html }}
          <mat-icon
            class="copy-button"
            matTooltip="Copy to clipboard"
            matTooltipPosition="above"
            ngxClipboard
            [cbContent]="issue?.html">
            content_copy
          </mat-icon>
        </div>
      </div>
    </div>


    <div class="solution" *ngIf="issue?.fixOneOf?.length || issue?.fixAnyOf?.length || issue?.fixAllOf?.length || issue?.doNoneOf?.length">
      <p class="solution-title">SOLUTION</p>
      <ng-container *ngIf="issue?.fixOneOf?.length">
        <p class="solution-list-label">Fix <span class="solution-list-label-bold">ONE</span> of following:</p>
        <ul class="solution-list">
          <li class="solution-list-item" *ngFor="let solution of issue?.fixOneOf">{{ solution }}</li>
        </ul>
      </ng-container>
      <ng-container *ngIf="issue?.fixAnyOf?.length">
        <p class="solution-list-label"><span *ngIf="issue?.fixOneOf?.length" class="solution-list-label-bold">AND</span>
          {{ issue?.fixOneOf?.length ? 'fix' : 'Fix' }} <span class="solution-list-label-bold">ANY</span> of following:</p>
        <ul class="solution-list">
          <li class="solution-list-item" *ngFor="let solution of issue?.fixAnyOf">{{ solution }}</li>
        </ul>
      </ng-container>
      <ng-container *ngIf="issue?.fixAllOf?.length">
        <p class="solution-list-label"><span *ngIf="issue?.fixAnyOf?.length || issue?.fixOneOf?.length" class="solution-list-label-bold">AND</span>
          {{ (issue?.fixAnyOf?.length || issue?.fixOneOf?.length) ? 'fix' : 'Fix' }} <span class="solution-list-label-bold">ALL</span> of following:</p>
        <ul class="solution-list">
          <li class="solution-list-item" *ngFor="let solution of issue?.fixAllOf">{{ solution }}</li>
        </ul>
      </ng-container>
      <ng-container *ngIf="issue?.doNoneOf?.length">
        <p class="solution-list-label">
          <span *ngIf="issue?.fixAnyOf?.length || issue?.fixAllOf?.length || issue?.fixOneOf?.length" class="solution-list-label-bold">AND</span>
          {{ issue?.fixAnyOf?.length || issue?.fixAllOf?.length || issue?.fixOneOf?.length ? 'do' : 'Do'}} <span class="solution-list-label-bold">ALL</span> of following:
        </p>
        <ul class="solution-list">
          <li class="solution-list-item" *ngFor="let solution of issue?.doNoneOf">{{ solution }}</li>
        </ul>
      </ng-container>
    </div>
  </div>
</mat-expansion-panel>

<ng-template #singleSelector>
  Element Selector:
</ng-template>

