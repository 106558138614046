import { WebJourneyModule } from './../../web-journey/web-journey.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { SidebarLinkComponent } from './sidebar-link/sidebar-link.component';
import { MatIconModule } from '@angular/material/icon';
import { NewDataModalComponent } from '@app/components/new-data-modal/new-data-modal.component';
import { OpModalModule } from '@app/components/shared/components/op-modal/op-modal.module';
import { NewDataOptionComponent } from '@app/components/new-data-modal/new-data-option/new-data-option.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@app/components/shared/shared.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { EnabledFeaturesPipe } from './sidebar-link/enabled-features.pipe';
import { KeyboardShortcutsModalComponent } from '@app/components/keyboard-shortcuts-modal/keyboard-shortcuts-modal.component';
import { OpSectionDividerModule } from '@app/components/shared/components/op-section-divider/op-section-divider.module';
import { ReportCardService } from '@app/components/manage/cards/report-card/report-card.service';
import { UtilitiesService } from '@app/components/utilities/utilitiesService';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    OpModalModule,
    MatButtonModule,
    MatTooltipModule,
    MatDividerModule,
    MatMenuModule,
    SharedModule,
    RouterModule,
    MatSlideToggleModule,
    OpSectionDividerModule,
    WebJourneyModule,
  ],
  declarations: [
    SidebarComponent,
    SidebarLinkComponent,
    EnabledFeaturesPipe,
    NewDataModalComponent,
    NewDataOptionComponent,
    KeyboardShortcutsModalComponent
  ],
  exports: [
    SidebarComponent,
    NewDataModalComponent,
    SidebarComponent,
    KeyboardShortcutsModalComponent
  ],
  providers: [
    ReportCardService,
    UtilitiesService
  ]
})
export class SidebarModule {}
