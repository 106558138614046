import { Component, ElementRef, HostListener, Input, ViewChild, AfterViewInit } from '@angular/core';
import { IAuditSummaryPrimaryTag } from '../audit-summary.models';
import { EMissingTagsCardStates } from './missing-tags-card.constants';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'missing-tags-card',
  templateUrl: './missing-tags-card.component.html',
  styleUrls: ['./missing-tags-card.component.scss']
})
export class MissingTagsCardComponent implements AfterViewInit {
  readonly getTagIconUrl = UiTagService.getTagIconUrl;

  cardState = EMissingTagsCardStates;
  displayTags: IAuditSummaryPrimaryTag[];
  iconContainerWidth: number;
  cardPadding: number = 50;
  gridItemWidth: number = 42;

  @Input() tags: IAuditSummaryPrimaryTag[];
  @Input() state: EMissingTagsCardStates;

  @ViewChild('iconContainer', { static: false }) iconContainer: ElementRef;

  ngAfterViewInit(): void {
    setTimeout(() => this.calcNumTagsToShow(), 0);
  }

  calcNumTagsToShow(): void {
    this.iconContainerWidth = this.iconContainer.nativeElement.offsetWidth;
    const rowLength = Math.floor((this.iconContainerWidth - this.cardPadding) / this.gridItemWidth);
    const totalNumIcons = rowLength * 2;
    this.displayTags = this.tags.slice(0, totalNumIcons);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.calcNumTagsToShow();
  }

}
