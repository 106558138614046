import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from '@app/components/core/services/api.service';
import { BehaviorSubject, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import {
  IConsentCategory,
  IConsentCategoryAssignedLabelsDTO,
  IConsentCategoryBase,
  IConsentCategoryCookieDTO,
  IAuditGeoLocation,
  IConsentCategoryGeos,
  IConsentCategoryLabel,
  IConsentCategoryLabelDTO,
  IConsentCategoryRequestDomain,
  IConsentCategoryRequestDomainDTO,
  IConsentCategorySnapshot,
  IConsentCategorySummaryDTO,
  IConsentCategoryTagDTO,
  IRunCookiesDTO,
  IRunInfo,
  IRunRequestDomainsDTO,
  IRunTagsDTO,
  IConsentCategories,
  ICCLibraryQueryParams,
  IConsentCategoryLibraryDTO,
  IAuditRunRequestDomain,
} from './consent-categories.models';
import { environment } from '@app/environments/environment';
import * as jsonpatch from 'fast-json-patch';
import { Router } from '@angular/router';
import { CacheResetService } from './../core/services/cache-reset.service';
import { catchError, map, pluck, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ICCAssignWebAudits } from './cc-assign/cc-assign.models';
import { ArrayUtils } from '@app/components/utilities/arrayUtils';
import { OpModalService } from '../shared/components/op-modal';
import { CacheApiResponse } from '@app/components/core/decorators/cache-api-response.decorator';
import { IReprocessService } from '@app/components/reporting/statusBanner/reprocessRulesBanner/reprocessService';
import { ICmpDetect, ICmpDetectProcessing, ICmpDetectResponse } from './sync-onetrust-categorized-cookies-modal/sync-onetrust-categorized-cookies-modal.models';

const clearCacheOnReprocess: Subject<any> = new Subject();

@Injectable({
  providedIn: 'root'
})
export class ConsentCategoriesService implements OnDestroy {
  private destroy$: Subject<void> = new Subject();

  apiRoot: string = `${environment.apiV3Url}consent-preferences`;
  newApiRoot: string = `${environment.apiV3Url}consent-categories`;
  patchOptions = {
    headers: {
      'Content-Type': 'application/json-patch+json'
    }
  };

  private consentCategoriesSubject = new BehaviorSubject<IConsentCategory[]>([]);
  private geoSubject: BehaviorSubject<IConsentCategoryGeos> = new BehaviorSubject<IConsentCategoryGeos>({
    countriesById: {},
    continentsById: {}
  });
  consentCategories$ = this.consentCategoriesSubject.asObservable();
  geo$: Observable<IConsentCategoryGeos> = this.geoSubject.asObservable();
  selectedCCId: number;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private modalService: OpModalService,
    private cacheResetService: CacheResetService,
    private reprocessService: IReprocessService,
  ) {
    this.cacheResetService.reset$.subscribe(_ => {
      this.consentCategoriesSubject.next([]);
    });

    this.reprocessService.reprocessComplete$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        clearCacheOnReprocess.next();
      });

    this.getConsentCategoryCountries().pipe(
      take(1)
    ).subscribe(countries => {
      const geos = countries.reduce((acc, country) => {
        // Build countriesById map
        acc.countriesById[country.countryId] = country;

        // Build continentsById map
        if (acc.continentsById[country.continentId] === undefined) {
          acc.continentsById[country.continentId] = [];
        }

        acc.continentsById[country.continentId].push(country);

        return acc;
      }, { countriesById: {}, continentsById: {} });

      this.geoSubject.next(geos);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createConsentCategory(consentCategory: IConsentCategoryBase): Observable<IConsentCategoryBase> {
    return this.apiService.post(this.newApiRoot, consentCategory);
  }

  setSelectedCCId(id: number): void {
    this.selectedCCId = id;
  }

  copyConsentCategory(consentCategory: IConsentCategoryBase): Observable<IConsentCategoryBase> {
    const createPayload = {
      name: `${consentCategory.name} (copy)`,
      notes: consentCategory.notes,
      type: consentCategory.type,
      isDefaultCC: consentCategory.isDefaultCC,
    };

    return this.createConsentCategory(createPayload).pipe(
      switchMap(cc => {
        const getObservables = [];
        getObservables.push(this.getConsentCategoryLabels(consentCategory.id));
        getObservables.push(this.getConsentCategoryTags(consentCategory.id));
        getObservables.push(this.getConsentCategoryCookies(consentCategory.id));
        getObservables.push(this.getConsentCategoryRequestDomains(consentCategory.id));

        return forkJoin(getObservables).pipe(
          switchMap(([labels, tags, cookies, requestDomains]) => {
            const setObservables = [];
            setObservables.push(this.patchConsentCategoryLabels(cc.id, [], (labels as any)?.labels?.map(label => label.id)));
            setObservables.push(this.patchConsentCategoryTags(cc.id, this.compareConsentCategories([], (tags as IConsentCategoryTagDTO).tags)));
            setObservables.push(this.patchConsentCategoryCookies(cc.id, this.compareConsentCategories([], (cookies as IConsentCategoryCookieDTO).cookies)));
            setObservables.push(this.patchConsentCategoryRequestDomains(cc.id, this.compareConsentCategories([], (requestDomains as IConsentCategoryRequestDomainDTO).requestDomains)));
            return forkJoin(setObservables);
          }),
          switchMap(() => {
            const copiedCC = { name: createPayload.name, notes: createPayload.notes, type: createPayload.type, isDefaultCC: createPayload.isDefaultCC };
            return this.updateConsentCategory(cc.id, copiedCC);
          })
        )
      }),
      catchError(err => {
        if (err.errorCode?.message?.includes('A Consent Category with the same name')) {
          return this.copyConsentCategory({ ...createPayload, id: consentCategory.id });
        }
      })
    );
  }

  deleteConsentCategory(ccId: number) {
    return this.apiService.delete(`${this.newApiRoot}/${ccId}`);
  }

  getConsentCategoriesLibrary({ page, pageSize, name, labels, type, sortBy, sortDesc, cmpVendor }: ICCLibraryQueryParams): Observable<IConsentCategoryLibraryDTO> {
    let params = [];

    if (page !== undefined) params.push(`page=${page}`);
    if (pageSize) params.push(`pageSize=${pageSize}`);
    if (name) params.push(`name=${name}`);
    if (labels) params.push(`labels=${labels}`);
    if (type) params.push(`type=${type.toLowerCase()}`);
    if (sortBy) params.push(`sortBy=${sortBy}`);
    if (sortDesc) params.push(`sortDesc=${sortDesc}`);
    if (cmpVendor) params.push(`cmpVendor=${cmpVendor}`);

    const root = `${this.newApiRoot}/library`;
    const queryParams = params.length ? `?${params.join('&')}` : null;

    return this.apiService.get(`${root}${queryParams}`);
  }

  isConsentCategoryNameUnique(name: string): Observable<boolean> {
    const nameEncoded = encodeURIComponent(name);
    return this.apiService.get(`${this.newApiRoot}/library?name=${nameEncoded}`).pipe(map((res: IConsentCategoryLibraryDTO) => {
      // if nothing comes back we know it's unique
      if (!res.consentCategories.length) return true;

      // check  if any of the names that come back match
      const names = res.consentCategories.map(cc => cc.name.toLowerCase());
      return !names.includes(name.toLowerCase());
    }));
  }

  getConsentCategoryLabels(ccId): Observable<IConsentCategoryLabel[]> {
    return this.apiService.get(`${this.newApiRoot}/${ccId}/labels`);
  }

  addConsentCategoryLabels(ccId, newLabels = []) {
    const body = newLabels.map((label) => ({
      'op': 'add',
      'path': '/-',
      'value': label.id
    }));
    return this.apiService.patch(`${this.newApiRoot}/${ccId}/labels`, body, this.patchOptions);
  }

  getConsentCategoryTags(ccId: number): Observable<IConsentCategoryTagDTO> {
    return this.apiService.get(`${this.newApiRoot}/${ccId}/tags`);
  }

  patchConsentCategoryTags(ccId: number, value: any): Observable<IConsentCategoryTagDTO> {
    return this.apiService.patch(`${this.newApiRoot}/${ccId}/tags`, value, this.patchOptions);
  }

  getConsentCategoryCookies(ccId: number): Observable<IConsentCategoryCookieDTO> {
    return this.apiService.get(`${this.newApiRoot}/${ccId}/cookies`);
  }

  patchConsentCategoryCookies(ccId: number, value: any): Observable<IConsentCategoryCookieDTO> {
    return this.apiService.patch(`${this.newApiRoot}/${ccId}/cookies`, value, this.patchOptions);
  }

  @CacheApiResponse()
  getConsentCategoryCountries(): Observable<IAuditGeoLocation[]> {
    return this.apiService.get(`${environment.apiV3Url}geo-locations/countries`);
  }

  getConsentCategoryRequestDomains(ccId: number): Observable<IConsentCategoryRequestDomainDTO> {
    return this.apiService.get(`${this.newApiRoot}/${ccId}/request-domains`);
  }

  patchConsentCategoryRequestDomains(ccId: number, value: any): Observable<IConsentCategoryRequestDomain[]> {
    return this.apiService.patch(`${this.newApiRoot}/${ccId}/request-domains`, value, this.patchOptions);
  }

  getConsentCategoryById(ccId: number): Observable<IConsentCategory> {
    return this.apiService.get(`${this.newApiRoot}/${ccId}`);
  }

  updateConsentCategory(id: number, cc: IConsentCategoryBase): Observable<IConsentCategoryBase> {
    return this.apiService.put(`${this.newApiRoot}/${id}`, cc);
  }

  compareConsentCategories(original, current) {
    return jsonpatch.compare(original, current);
  }

  exportConsentCategories(): Observable<any> {
    return this.apiService.post(`${environment.apiV3Url}consent-categories/export`, {});
  }

  getAuditConsentCategories(auditId: number): Observable<{ consentCategories: IConsentCategoryBase[] }> {
    return this.apiService.get(`${environment.apiV3Url}web-audits/${auditId}/consent-categories`);
  }

  patchAuditConsentCategories(auditId, oldConsentCategoryIDs = [], newConsentCategoryIDs = []) {
    let remainingOldConsentCategoryIDs = [...oldConsentCategoryIDs];
    const body = [];
    newConsentCategoryIDs.forEach(id => {
      const foundIndex = remainingOldConsentCategoryIDs.indexOf(id);

      if (foundIndex === -1) {
        // Add cc to web audit
        body.push({
          'op': 'add',
          'path': '/-',
          'value': id,
        });
      } else {
        // CC was and remains assigned to audit; no changed needed.
        remainingOldConsentCategoryIDs.splice(foundIndex, 1);
      }
    });

    // Remove any remaining old CCs
    remainingOldConsentCategoryIDs.forEach(removedId => {
      body.push({
        'op': 'remove',
        'path': '/',
        'value': removedId,
      });
    });

    if (body.length === 0) return of([]);

    return this.apiService.patch(`${environment.apiV3Url}web-audits/${auditId}/consent-categories`, body, this.patchOptions);
  }

  patchConsentCategoryLabels(ccId: number, oldIds: number[], newIds: number[]): Observable<IConsentCategoryLabelDTO> {
    const body = this.getPatchBody(oldIds, newIds);

    if (body.length === 0) return of({ labels: [] });

    return this.apiService.patch(`${this.newApiRoot}/${ccId}/labels`, body, this.patchOptions);
  }

  getConsentCategoryWebAudits(ccId: number) {
    return this.apiService.get(`${this.newApiRoot}/${ccId}/web-audits`);
  }

  patchConsentCategoryWebAudits(ccId: number, oldIds: number[], newIds: number[]) {
    const body = this.getPatchBody(oldIds, newIds);

    if (body.length === 0) return of([]);

    return this.apiService.patch(`${this.newApiRoot}/${ccId}/web-audits`, body, this.patchOptions);
  }

  getPatchBody(oldIds, newIds) {
    let remainingOldCCIDs = [...oldIds];
    const body = [];
    newIds.forEach(id => {
      const foundIndex = remainingOldCCIDs.indexOf(id);

      if (foundIndex === -1) {
        // Add cc to web-journey
        body.push({
          'op': 'add',
          'path': '/-',
          'value': id,
        });
      } else {
        // CC was and remains assigned to journey; no changed needed.
        remainingOldCCIDs.splice(foundIndex, 1);
      }
    });

    // Remove any remaining old CCs
    remainingOldCCIDs.forEach(removedId => {
      body.push({
        'op': 'remove',
        'path': '/',
        'value': removedId,
      });
    });

    return body;
  }

  getRunTags(runInfo: IRunInfo[], pagination, options?): Observable<IRunTagsDTO> {
    const requestUrl = `${environment.apiV3Url}runs/tags`;
    const queryParams = `?page=${pagination.currentPageNumber}&size=${pagination.pageSize}${options.search.length > 0 ? '&search=' + options.search : ''}&sortBy=${options.sortBy}&sortDesc=${options.sortDesc}`;

    return this.apiService.post(`${requestUrl}${queryParams}`, runInfo);
  }

  getRunCookies(runInfo: IRunInfo[], pagination, options?): Observable<IRunCookiesDTO> {
    const requestUrl = `${environment.apiV3Url}runs/cookies`;
    const queryParams = `?page=${pagination.currentPageNumber}&size=${pagination.pageSize}${options.search.length > 0 ? '&search=' + options.search : ''}&sortBy=${options.sortBy}&sortDesc=${options.sortDesc}`;

    return this.apiService.post(`${requestUrl}${queryParams}`, runInfo);
  }

  getRunRequestDomains(runInfo: IRunInfo[], pagination, options?): Observable<IRunRequestDomainsDTO> {
    const requestUrl = `${environment.apiV3Url}runs/request-domains`;
    const queryParams = `?page=${pagination.currentPageNumber}&sortBy=${options.sortBy}&sortDesc=${options.sortDesc}&size=${pagination.pageSize}${options.search.length > 0 ? '&search=' + options.search : ''}`;

    return this.apiService.post(`${requestUrl}${queryParams}`, runInfo);
  }

  dedupeCollection(collection: Array<any>, dedupeProp: string = 'name') {
    return collection.filter((value, index, array) => {
      return array.findIndex(item => (item[dedupeProp] === value[dedupeProp])) === index;
    });
  }

  uploadCSV(file: File) {
    const requestUrl = `${environment.apiV3Url}consent-categories/upload/preview`;

    const formData: FormData = new FormData();
    formData.append('upload', file, file.name);

    return this.apiService.post(requestUrl, formData);
  }

  saveImportedCategorizedCookies(data) {
    const requestUrl = `${environment.apiV3Url}consent-categories/upload`;

    return this.apiService.post(requestUrl, data);
  }

  detectCategories(data): Observable<ICmpDetect> {
    const requestUrl = `${environment.apiV3Url}cmp-providers/imports/cookies`;
    return this.apiService.post(requestUrl, data);
  }
  getCmpConsentCategories(data: ICmpDetectResponse): Observable<ICmpDetectProcessing> {
    const requestUrl = `${environment.apiV3Url}cmp-providers/imports/cookies/results`;
    return this.apiService.post(requestUrl, data);
  }

  // Remove the protocol from url
  formatRunRequestDomainsResponseForUI(requestDomains: IAuditRunRequestDomain[]) {
    return requestDomains.map(requestDomain => {
      const splitDomain = requestDomain.domain.split('//');

      return {
        ...requestDomain,
        domain: splitDomain.length > 1 ? splitDomain[1] : requestDomain.domain,
        locationIds: requestDomain.countries.map(country => country.countryCodeId)
      };
    });
  }

  getCCWebAudits(consentCatId: number): Observable<ICCAssignWebAudits> {
    return this.apiService.get(`${this.newApiRoot}/${consentCatId}/web-audits`);
  }

  patchCCWebAudits(consentCatId: number, body: number[]): Observable<ICCAssignWebAudits> {
    const path = `${this.newApiRoot}/${consentCatId}/web-audits`;
    const patch = body.map((id: number) => ({ op: 'add', path: '/-', value: id }));
    return this.apiService.patch(path, patch, this.patchOptions);
  }

  patchWebAuditCcs(auditId: number, oldConsentCategoryIDs: number[], newConsentCategoryIDs: number[]): Observable<IConsentCategories> {
    const path = `${environment.apiV3Url}web-audits/${auditId}/consent-categories`;
    const uniqueNewConsentCategories = newConsentCategoryIDs.filter(newId => !oldConsentCategoryIDs.includes(newId));
    const patchBody = uniqueNewConsentCategories.map((id: number) => ({ op: 'add', path: '/-', value: id }));
    return this.apiService.patch(path, patchBody, this.patchOptions);
  }

  getConsentCategorySummary(pagination?, options?): Observable<IConsentCategorySummaryDTO> {
    const requestUrl = `${environment.apiV3Url}consent-categories`;
    const queryParams = `?pageSize=${500}`;

    return this.apiService.get(`${requestUrl}${queryParams}`);
  }

  getConsentCategoriesAssignedToAudit(auditId: number): Observable<IConsentCategories[]> {
    return this.apiService.get(`${environment.apiV3Url}web-audits/${auditId}/consent-categories`)
      .pipe(pluck('consentCategories'));
  }

  @CacheApiResponse({ resetCache: clearCacheOnReprocess, liveTime: 1000 })
  getConsentCategoriesAssignedToRun(auditId: number, runId: number): Observable<IConsentCategorySnapshot[]> {
    return this.apiService.get(`${environment.apiV3Url}web-audits/${auditId}/runs/${runId}/consent-categories`)
      .pipe(
        map((response: { consentCategorySnapshots?: IConsentCategorySnapshot[]; consentCategories?: IConsentCategorySnapshot[]; }) => response.consentCategorySnapshots || response.consentCategories || []),
        map(ccs => ArrayUtils.sortBy(ccs, cc => cc.name)),
        catchError(err => {
          console.error(`Failed to load consent categories for auditId=${auditId}, runId=${runId}, reason: ${err.message}`);
          return throwError(err);
        })
      );
  }

  getConsentCategoryAssignedLabels(ccId: number): Observable<IConsentCategoryAssignedLabelsDTO> {
    return this.apiService.get(`${this.newApiRoot}/${ccId}/labels`);
  }

  reprocessConsentCategories(auditId: number, runId: number, ccIds: number[]): Observable<any> {
    return this.apiService.post(`${environment.apiV3Url}web-audits/${auditId}/runs/${runId}/consent-categories/reprocess`, ccIds);
  }
}
