<div class="btn-bar-wrapper">
  <share-report-btn [auditId]="itemId"
                    [runId]="runId"
                    [pageId]="pageId"
                    [activeTab]="activeTab">
  </share-report-btn>
</div>
<div class="widget-container">
  <split-card [topLabel]="'Final Page Status Code'"
              [topChangeContent]="pageStatusCodeValue"
              [topChangeMeaning]="pageStatusCodeMeaning"
              [state]="widgetState"
              [topTooltip]="pageStatusCodeTooltip">
    <status-bubble-timeline [data]="statusBubbles"></status-bubble-timeline>
  </split-card>
  <split-card [topLabel]="'Page Load Time'"
              [topChangeContent]="pageLoadTimeValue"
              [topChangeMeaning]="pageLoadTimeMeaning"
              [haveEnoughData]="widgetLoadTimeSparklineData.length > 1"
              [bottomHandler]="widgetPageLoadTimeBottomHandler"
              [state]="widgetState"
              [topTooltip]="PageLoadWidgetTooltip">
    <sparkline-chart-insight [meaningOfUp]="'negative'"
                             [meaningOfNoChange]="'positive'"
                             [label]="'since last run'"
                             [dataLoaded]="sparklineDataLoaded"
                             [dataPoints]="widgetLoadTimeSparklineData">
    </sparkline-chart-insight>
  </split-card>
  <split-card [topLabel]="'Page Size'"
              [topChangeContent]="pageSizeValue"
              [topChangeMeaning]="'none'"
              [haveEnoughData]="widgetPageSizeSparklineData.length > 1"
              [bottomHandler]="widgetPageSizeBottomHandler"
              [state]="widgetState"
              [topTooltip]="'Showing size for this page over the last few audit runs it has appeared in.'">
    <sparkline-chart-insight [meaningOfUp]="'none'"
                             [meaningOfNoChange]="'none'"
                             [label]="'since last run'"
                             [dataLoaded]="sparklineDataLoaded"
                             [dataPoints]="widgetPageSizeSparklineData">
    </sparkline-chart-insight>
  </split-card>
</div>
<!--Browser error banner-->
<div class="middle-info-section"
     *ngIf="!(pageInfo?.onPageActionResults?.length > 0) && !(pageErrors?.preAuditActionResults?.zeroAndOut) && pageInfo?.browserError && (pageInfo?.finalStatusCode === 0 || pageInfo?.finalStatusCode >= 400)">
  <div class="browser-errors">
    <h3>BROWSER RESPONDED</h3>
    <p class="message">
      <mat-icon>announcement</mat-icon>
      {{ pageInfo?.browserError }}
    </p>
    <p class="link">
      <a target="_blank" href="https://help.observepoint.com/article/418-browser-error-messages">
        Learn more about this browser message</a>
    </p>
  </div>
</div>

<div class="middle-info-section"
     *ngIf="!(pageInfo?.onPageActionResults?.length > 0) && pageErrors?.preAuditActionResults?.zeroAndOut">
  <div class="browser-errors">
    <div class="header-bar">
      <mat-icon>warning</mat-icon>&nbsp;
      <h3>Pre-Audit Action Failure</h3>
    </div>
    <p class="message">
      This page was not actually scanned due to a pre-audit action failure. Information displayed for this page only
      reflects what took place prior to the audit beginning.
    </p>
    <p class="message" *ngIf="pageInfo?.browserError">
      {{ pageInfo.browserError }}
    </p>
  </div>
</div>
<div class="middle-info-section" *ngIf="pageInfo?.onPageActionResults?.length > 0">
  <div class="browser-errors on-page-action-failures">
    <div class="header-bar">
      <mat-icon>warning</mat-icon>&nbsp;
      <h3>On Page Action Failure(s)</h3>
    </div>
    <div class="message">
      <ul>
        <li *ngFor="let failure of pageInfo?.onPageActionResults;">{{ failure?.failureMessage }}</li>
      </ul>
    </div>
  </div>
</div>

<div class="web-vitals">
  <div class="web-vitals-heading">
    <div class="subsection-heading">Web Vitals</div>
    <a class="icon-wrapper"
       href="https://help.observepoint.com/en/articles/9100627-page-details-report#h_b7d2fd726b"
       target="_blank">
      <mat-icon class="help-icon">help_outline</mat-icon>
    </a>
  </div>

  <div #webVitalsWrapper
       class="wv-widget-wrapper">
    <op-widget-splitter *ngFor="let widget of webVitalsWidgets"
                        class="wv-widget">
      <op-web-vitals-chart
        [data]="widget.data"
        [metricType]="widget.metricType"
        [webVitalsAvailable]="widget.webVitalsAvailable"
        [loading]="widget.loading"
      ></op-web-vitals-chart>

      <op-sparkline-chart-insight-colorized
        class="wv-bottom-chart"
        [class.pointer]="widget.webVitalsTrends?.length > 1"
        [dataPoints]="widget.webVitalsTrends"
        [boundaries]="widget.boundaries"
        [meaningOfUp]="EChartChangeTypes.negative"
        [label]="'since last run'"
        [digitsInfo]="widget.digitsInfo"
        [uniqueId]="widget.uniqueId"
        (click)="widget.webVitalsTrends?.length > 1 ? widget.clickHandler() : null"
      >
      </op-sparkline-chart-insight-colorized>
    </op-widget-splitter>
  </div>
</div>

<div class="bottom-info-section">
  <div class="first-section-bottom">
    <ng-container *ngIf="pageInfo?.links">
      <div class="subsection-heading">LINKS TO THIS URL ({{ sumLinksToThisPage() }}):
        <mat-icon [class.disabled]="!sumLinksToThisPage()"
                  class="download-icon"
                  (click)="downloadReport(EPageInfoLinkDownloadDirection.ToThisPage)"
                  matTooltipPosition="above"
                  matTooltip="Export all pages linking to this URL.">
          cloud_download_filled
        </mat-icon>
      </div>

      <div class="page-errors-counts">
        <ng-container *ngIf="sumLinksToThisPage()">
          <div class="value-container">
            <span class="property">INITIAL URL:</span>
            <span class="value">{{ pageInfo.links.linkToInitialUrlCount || 0 }}</span>
            <mat-icon [class.disabled]="!sumLinksToInitialUrl()"
                      class="download-icon"
                      (click)="downloadReport(EPageInfoLinkDownloadDirection.ToThisPage, null, EPageInfoLinkDownloadLinkType.Initial)"
                      matTooltipPosition="above"
                      matTooltip="Export all pages linking to initial URL">
              cloud_download_filled
            </mat-icon>
          </div>
          <div class="value-container">
            <span class="property">FINAL URL:</span>
            <span class="value">{{ pageInfo.links.linkToFinalUrlCount || 0 }}</span>
            <mat-icon [class.disabled]="!sumLinksToFinalUrl()"
                      class="download-icon"
                      (click)="downloadReport(EPageInfoLinkDownloadDirection.ToThisPage, null, EPageInfoLinkDownloadLinkType.Final)"
                      matTooltipPosition="above"
                      matTooltip="Export all pages linking to final URL">
              cloud_download_filled
            </mat-icon>
          </div>
        </ng-container>

        <ng-container *ngIf="!pageInfo.redirects?.length">
          <ng-container *ngIf="sumLinksToThisPage()">
            <div *ngIf="!pageInfo.links.linkToInitialUrlCount && pageInfo.links.linkToFinalUrlCount"
                 class="value-container">
              <span class="property">FINAL URL:</span>
              <span class="value">{{ pageInfo.links.linkToFinalUrlCount || 0 }}</span>
            </div>
          </ng-container>
        </ng-container>

        <div *ngIf="!sumLinksToThisPage()" class="subsection-info-pair">
          <div class="subsection-info-pair-label extended">No links to this URL or it was used as a starting URL.</div>
        </div>

      </div>
    </ng-container>

    <div class="subsection-heading top-info-heading" *ngIf="!pageErrors?.preAuditActionResults?.zeroAndOut">
      SCREENSHOT
    </div>

    <div class="subsection-heading top-info-heading" *ngIf="pageErrors?.preAuditActionResults?.zeroAndOut">
      Screenshot during Pre-Audit Actions
    </div>

    <div class="screenshot-wrapper">
      <click-to-enlarge-screenshot *ngIf="pageInfo?.screenshot"
                                   [screenshotUrl]="pageInfo?.screenshot"
                                   (afterCloseCallback)="screenshotClosed.emit()"></click-to-enlarge-screenshot>
      <div *ngIf="!pageInfo?.screenshot">No screenshot available</div>
    </div>
  </div>

  <div class="rfm-section" *ngIf="pageInfo">
    <ng-container *ngIf="pageInfo.links">
      <div class="subsection-heading top-info-heading link-from-this-page-section">
        LINKS FROM THIS PAGE ({{ sumLinksFromPage() }}):
        <mat-icon [class.disabled]="!sumLinksFromPage()"
                  matTooltip="Export all URLs linked from this page."
                  matTooltipPosition="above"
                  mdePopoverTriggerOn="click"
                  [mdePopoverTriggerFor]="exportLinksPopover"
                  (opened)="exportLinksPopoverVisible = true"
                  (closed)="exportLinksPopoverVisible = false">
          cloud_download_filled
        </mat-icon>

        <mde-popover #exportLinksPopover
                     mdePopoverPositionX="before"
                     mdePopoverOffsetX="105"
                     [mdePopoverOverlapTrigger]="false"
                     [mdePopoverCloseOnClick]="false">
          <page-information-export-links *ngIf="exportLinksPopoverVisible"
                                         [links]="pageInfo.links.linksFromThisPageV2"
                                         (export)="exportLinks($event)">
          </page-information-export-links>
        </mde-popover>
      </div>

      <ng-container *ngIf="sumLinksFromPage()">
        <div class="subsection-info-pair">
          <div class="subsection-info-pair-label"
               matTooltip="Based on the final URL, an internal page is defined as any page that has the same base domain as the page that is currently being viewed.">
            TO AUDITED INTERNAL PAGES:
          </div>
        </div>
        <ul class="page-statistic-summary">
          <li class="success" [class.no-links]="!pageInfo.links.linksFromThisPageV2.scannedInternalSuccessfulPageCount">
            <mat-icon>check_circle</mat-icon>
            {{ pageInfo.links.linksFromThisPageV2.scannedInternalSuccessfulPageCount }} successes
          </li>
          <li class="broken" [class.no-links]="!pageInfo.links.linksFromThisPageV2.scannedInternalBrokenPageCount">
            <mat-icon>cancel</mat-icon>
            {{ pageInfo.links.linksFromThisPageV2.scannedInternalBrokenPageCount }} broken
          </li>
        </ul>

        <div class="subsection-info-pair">
          <div class="subsection-info-pair-label"
               matTooltip="Based on the final URL, an external page is defined as any page that has a different base domain than the page that is currently being viewed.">
            TO AUDITED EXTERNAL PAGES:
          </div>
        </div>
        <ul class="page-statistic-summary">
          <li class="success" [class.no-links]="!pageInfo.links.linksFromThisPageV2.scannedExternalSuccessfulPageCount">
            <mat-icon>check_circle</mat-icon>
            {{ pageInfo.links.linksFromThisPageV2.scannedExternalSuccessfulPageCount }} successes
          </li>
          <li class="broken" [class.no-links]="!pageInfo.links.linksFromThisPageV2.scannedExternalBrokenPageCount">
            <mat-icon>cancel</mat-icon>
            {{ pageInfo.links.linksFromThisPageV2.scannedExternalBrokenPageCount }} broken
          </li>
        </ul>

        <div class="subsection-info-pair">
          <div class="subsection-info-pair-label"
               opTooltipWithCustomTemplate [opTooltipTemplate]="notAuditedUrlsTooltip">
            TO URLS NOT AUDITED:
          </div>
        </div>
        <ul class="page-statistic-summary">
          <li class="unscanned" [class.no-links]="!pageInfo.links.linksFromThisPageV2.unscannedInternaLinkCount">
            <mat-icon>block</mat-icon>
            {{ pageInfo.links.linksFromThisPageV2.unscannedInternaLinkCount }} internal
            <mat-icon class="info-tooltip"
                      matTooltip="Pages stopped being visited when the maximum number of pages (as set in the audit settings) have been scanned. Modify the Scan Limit in audit settings to increase or decrease the pages scanned.">
              info_outline
            </mat-icon>
          </li>
          <li class="unscanned" [class.no-links]="!pageInfo.links.linksFromThisPageV2.unscannedExternaLinkCount">
            <mat-icon>block</mat-icon>
            {{ pageInfo.links.linksFromThisPageV2.unscannedExternaLinkCount }} external
            <mat-icon class="info-tooltip"
                      matTooltip="Pages stopped being visited when the maximum number of pages (as set in the audit settings) have been scanned. Modify the Scan Limit in audit settings to increase or decrease the pages scanned.">
              info_outline
            </mat-icon>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="!sumLinksFromPage()">
        <div class="subsection-info-pair">
          <div class="subsection-info-pair-label extended">
            There are no links to report because the page did not load.
          </div>
        </div>
      </ng-container>
    </ng-container>
    <!--
      https://observepoint.atlassian.net/browse/WORK-26555
      hiding this for now as it's broken until BE changes are made

      <ng-container *ngIf="pageInfo?.blockedRequestSummary?.blockedRequests">
      <div class="subsection-heading top-info-heading">
        <mat-icon svgIcon="request_blocked" class="custom-icon"></mat-icon>
        BLOCKED TECHNOLOGIES
      </div>
      <div class="subsection-info-single">
        <div class="subsection-info-single-value">
          <mat-icon class="checked-icon">check</mat-icon>
          Item(s) were blocked
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!pageInfo?.blockedRequestSummary?.blockedRequests">
      <div class="subsection-heading top-info-heading">BLOCKED TECHNOLOGIES</div>
      <div class="subsection-info-pair">
        <div class="subsection-info-pair-label">Not configured/found</div>
      </div>
    </ng-container> -->
    <ng-container *ngIf="!rfms.length">
      <div class="subsection-heading top-info-heading">FILE SUBSTITUTIONS</div>
      <div class="subsection-info-pair">
        <div class="subsection-info-pair-label">Not configured/found</div>
      </div>
    </ng-container>

    <ng-container *ngIf="rfms.length">
      <div class="rfm-info-group" *ngFor="let rfm of rfms; let index = index;">
        <div class="subsection-heading" [ngClass]="{'top-info-heading': index === 0}">FILE SUBSTITUTIONS</div>
        <div class="subsection-info-pair">
          <div class="subsection-info-pair-label">MATCH FOUND:</div>
          <div class="subsection-info-pair-value">{{ rfm.match ? 'Yes' : 'No' }}</div>
        </div>
        <div class="subsection-info-pair">
          <div class="subsection-info-pair-label">MATCH TYPE:</div>
          <div class="subsection-info-pair-value">{{ rfm.matchType }}</div>
        </div>
        <div class="subsection-info-pair">
          <div class="subsection-info-pair-label">MATCH VALUE:</div>
          <div class="subsection-info-pair-value">{{ rfm.match }}</div>
        </div>
        <div class="subsection-info-pair">
          <div class="subsection-info-pair-label">REPLACEMENT FILE NAME:</div>
          <div class="subsection-info-pair-value">{{ rfm.fileUrl }}</div>
        </div>
        <div class="subsection-info-pair">
          <div class="subsection-info-pair-label">MATCHED REQUEST:</div>
          <div class="subsection-info-pair-value">{{ rfm.mappedRequests.join(', ') }}</div>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- </div> -->
</div>

<ng-template #notAuditedUrlsTooltip>
  <div>URLs can be not audited due to:</div>
  <ul [style.paddingLeft]="'20px'" [style.marginBottom]="'0px'">
    <li>Include/Exclude filters set in audit configuration</li>
    <li>The page limit set in audit configuration</li>
    <li>The link is to a non-page (ex: 'mailto:' or an empty link destination)</li>
  </ul>
</ng-template>
