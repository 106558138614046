import { MaterialModule } from './../material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionSetLibraryComponent } from './action-set-library.component';
import {
  ActionSetAssignmentTableComponent
} from '@app/components/action-set-library/action-set-assignment-table/action-set-assignment-table.component';
import { OpModalModule } from '../shared/components/op-modal/op-modal.module';
import { ActionSetEditorComponent } from './action-set-editor/action-set-editor.component';
import { ActionsModule } from '../actions/actions.module';
import { CreateActionSetComponent } from './create-action-set/create-action-set.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RuleSelectorModule } from '../account/rules/rule-selector/rule-selector.module';
import { MigrateActionsComponent } from './migrate-actions/migrate-actions.component';
import { SharedModule } from '@app/components/shared/shared.module';
import { OpClearableInputModule } from '@app/components/shared/components/op-clearable-input/op-clearable-input.module';


@NgModule({
  declarations: [
    ActionSetLibraryComponent,
    ActionSetAssignmentTableComponent,
    ActionSetEditorComponent,
    CreateActionSetComponent,
    MigrateActionsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    OpModalModule,
    ActionsModule,
    ReactiveFormsModule,
    RuleSelectorModule,
    FormsModule,
    SharedModule,
    OpClearableInputModule
  ]
})
export class ActionSetLibraryModule {}
