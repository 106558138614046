import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsentCategoriesUIConstants } from '@app/components/consent-categories/consent-categories.constants';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { EReportType } from '@app/components/consent-categories/consent-categories.models';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { AuditReportContainerUrlBuilders } from '@app/components/audit-reports/audit-report-container.constants';
import { WebJourneyReportUrlBuilders } from '@app/components/web-journey-report/web-journey-report.constants';
import { IAuditDataService } from '@app/components/domains/discoveryAudits/reporting/services/auditDataService/auditDataService';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { IAuditRunSummary } from '@app/components/domains/discoveryAudits/discoveryAuditsDashboard/discoveryAuditsNavTopBar/runInfoSerializer';
import { IWebJourneyRun } from '@app/components/domains/webJourneys/webJourneyDefinitions';
import { IUser } from '@app/moonbeamModels';
import { DateService, EDateFormats } from '@app/components/date/date.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'cc-report-row',
  templateUrl: './cc-report-row.component.html',
  styleUrls: ['./cc-report-row.component.scss'],
})
export class ConsentCategoryReportRowComponent implements OnInit {
  CONSTANTS = { ...ConsentCategoriesUIConstants };
  loading: boolean = true;
  dataSource = new MatTableDataSource;
  displayedColumns: string[] = ['auditName', 'domain', 'createdBy', 'lastRun', 'options'];

  @Input() ccId: number;
  @Input() reportType: EReportType;
  @Input() users: IUser[];
  @Output() loadConsentCategories: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private router: Router,
    private modalService: OpModalService,
    private auditDataService: IAuditDataService,
    private webJourneyApiService: IWebJourneyApiService,
    private ccService: ConsentCategoriesService,
    private dateService: DateService,
  ) {}

  ngOnInit(): void {
    this.initListeners();
    this.initData();
  }

  initData(): void {
    this.loadCCReports();
  }

  initListeners(): void {
    this.ccService.consentCategories$.subscribe(consentCategories => {
      this.loading = true;
      this.loadCCReports();
    });
  }

  loadCCReports() {
    this.getCCAudits(this.ccId)
      .subscribe(({webAudits}) => {
        const tempAudits = webAudits.map(audit => {
          const tempAudit = {...audit};
          tempAudit.lastRun = tempAudit.lastRun ?
            this.dateService.formatDate(new Date(tempAudit.lastRun), EDateFormats.dateFifteen)
            : 'Audit not run';
          return tempAudit;
        });

        this.dataSource.data = tempAudits;
        this.loading = false;
      });
  }

  getCCAudits(ccId): Observable<any> {
    return this.ccService.getConsentCategoryWebAudits(ccId);
  }

  unassignReport(event, report) {
    const reportIdField = 'auditId';
    const oldReportIds = this.dataSource.data.map(attachedReport => attachedReport[reportIdField]);
    const removedIndex = oldReportIds.indexOf(report[reportIdField]);
    const newReportIds = [...oldReportIds];
    newReportIds.splice(removedIndex, 1);

    this.ccService.patchConsentCategoryWebAudits(this.ccId, oldReportIds, newReportIds).subscribe(() => {
      this.loadConsentCategories.emit();
    });
  }

  gotoReport(event, report) {
    const reportId = this.reportType === EReportType.AUDIT ? report.auditId : report.webJourneyId;
    const runIdPromise: angular.IPromise<IAuditRunSummary|IWebJourneyRun> = this.reportType === EReportType.AUDIT ?
      this.auditDataService.getLastRun(reportId)
      : this.webJourneyApiService.getJourneyLastRun(reportId);

    runIdPromise.then((mostRecentRun) => {
      this.reportType === EReportType.AUDIT ?
        this.router.navigateByUrl(AuditReportContainerUrlBuilders.base(reportId, mostRecentRun.id))
        : this.router.navigateByUrl(WebJourneyReportUrlBuilders.base(reportId, mostRecentRun.id));
    });
  }

}
