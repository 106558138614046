import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EFilterSpinnerState } from './filter-spinner.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'filter-spinner',
  templateUrl: './filter-spinner.component.html',
  styleUrls: ['./filter-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterSpinnerComponent {
  readonly stateOptions = EFilterSpinnerState;
  @Input() state: EFilterSpinnerState;
}
