import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ERuleResultType,
  IAuditRunAllRuleResultsItem,
  IAuditRunRuleSummaryRequestDTO,
  IAuditRunSpecificRuleResultsDTO,
  IAuditRunSpecificRuleResultsWithMatchAll
} from '@app/components/audit-reports/reports/rule-summary/rule-summary.models';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import {
  IReprocessingRuleSummaryRulesTableTableRow,
  IRuleDetailRow,
  IRuleDetailRowIdentifier,
  IRuleSummaryRulesTableTableRow
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-rules-table/rule-summary-rules-table.models';
import {
  RuleDetailUtils
} from '@app/components/audit-reports/reports/rule-summary/rule-summary-rules-table/rule-summary-rules-table.utils';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { SummaryCardFilterType } from '../rule-summary-trends/rule-summary-trends.component';
import { UiTagService } from '@app/components/tag-database/tag-database.service';
import { IRule } from '@app/components/rules/rules.models';

enum TableColumn {
  Expanded = 'expanded',
  RuleName = 'ruleName',
  HasEmailNotification = 'hasEmailNotification',
  FailedCount = 'failedRulePageCount',
  PassedCount = 'passedRulePageCount',
  NotAppliedCount = 'notAppliedRulePageCount'
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'rule-summary-rules-table',
  templateUrl: './rule-summary-rules-table.component.html',
  styleUrls: ['./rule-summary-rules-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class RuleSummaryRulesTableComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() inputData: IAuditRunAllRuleResultsItem[];
  @Input() spinnerState: EFilterSpinnerState;
  @Input() loadingRuleEdit: boolean = false;
  @Input() loadSpecificRuleResult: (ruleSnapshotId: number) => Observable<IAuditRunSpecificRuleResultsDTO | undefined>;
  @Input() getTagNameByTagId: (tagId: number) => string;
  @Input() ruleSummaryTrendSelected: SummaryCardFilterType = undefined;
  @Input() apiFilters: IAuditRunRuleSummaryRequestDTO;
  @Input() userIsReadOnly: boolean;
  @Input() rulesById: Map<number, IRule>;
  @Output() onRuleConditionMetricClicked = new EventEmitter<IRuleDetailRowIdentifier>();
  @Output() onRuleEditClicked = new EventEmitter<number>();
  @Output() onEditEmail = new EventEmitter<number>();

  @ViewChild('ruleResultsTable', { read: MatSort, static: true }) tableSort: MatSort;

  TABLE_COLUMN = TableColumn;
  RULE_RESULT_TYPE = ERuleResultType;

  tableDataSource = new MatTableDataSource<IRuleSummaryRulesTableTableRow>();
  tableDisplayedColumns = this.getAllTableColumns();
  allExpanded: boolean = false;

  ngOnInit(): void {
    this.handleInputData(this.inputData);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.sortingDataAccessor = (data, attribute) => {
      return attribute === TableColumn.HasEmailNotification ? data.hasEmailNotification : data[attribute];
    };

    this.tableDataSource.sort = this.tableSort;

    this.tableDataSource.sortData = (data: IRuleSummaryRulesTableTableRow[], sort: MatSort) => {
      return data.sort((a, b) => {
        if (sort.direction === 'asc') {
          if (typeof a[sort.active] === 'string') {
            return a[sort.active].toLowerCase().localeCompare(b[sort.active].toLowerCase());
          }

          return a[sort.active] - b[sort.active];
        } else {
          if (typeof a[sort.active] === 'string') {
            return b[sort.active].toLowerCase().localeCompare(a[sort.active].toLowerCase());
          }
          return b[sort.active] - a[sort.active];
        }
      });
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputData']) {
      this.handleInputData(changes['inputData'].currentValue);
    }
    if (this.apiFilters) {
      this.tableDataSource.data.forEach(r => {
        r.clickedRuleResultType = undefined;
        r.ruleDetailRows && r.ruleDetailRows.forEach(rd => {
          rd.clickedRuleResultType = undefined;
        });
      });
    }
  }

  expandAllClicked($event: MouseEvent): void {
    const totalRows = this.tableDataSource.data.length;
    const expandedCount = this.tableDataSource.data.filter(r => r.expanded).length;
    this.allExpanded = expandedCount === 0
      ? true
      : expandedCount === totalRows
        ? false
        : expandedCount >= (totalRows / 2);
    this.tableDataSource.data.forEach(row => {
      if (row.expanded != this.allExpanded) {
        this.rowClicked(row, $event);
      }
    });
  }

  rowClicked(row: IRuleSummaryRulesTableTableRow, $event: MouseEvent): void {
    row.expanded = !row.expanded;

    if (row.expanded && !row.ruleDetails && !row.loadingRuleDetails) {
      row.loadingRuleDetails = true;
      this.loadSpecificRuleResult(row.rule.ruleSnapshotId).subscribe(data => {
        (row as IReprocessingRuleSummaryRulesTableTableRow).reprocessing = false;
        row.ruleDetails = data;
        const matchAllFilters = this.rulesById.get(data?.originalRuleId)?.matchAllFilters || false;
        row.ruleDetailRows = RuleDetailUtils.ruleDetailToRuleDetailRows((data as IAuditRunSpecificRuleResultsWithMatchAll), this.getTagNameByTagId, matchAllFilters);
      }, error => {
        // API throws a 423 if the rule is currently being reprocessed. Special handling
        // to display a message to the user in this instance.
        if (error.code === 423) {
          row.loadingRuleDetails = false;
          (row as IReprocessingRuleSummaryRulesTableTableRow).reprocessing = true;
        }
        console.error(error);
      }, () => {
        row.loadingRuleDetails = false;
      });
    }

    if (!row.expanded) {
      if ((row as IReprocessingRuleSummaryRulesTableTableRow).reprocessing) {
        (row as IReprocessingRuleSummaryRulesTableTableRow).reprocessing = false;
      } else {
        row.ruleDetailRows?.forEach(rd => {
          if (rd.clickedRuleResultType) {
            this.ruleMetricClicked(rd, rd.clickedRuleResultType, $event);
          }
        });
      }
    }
  }

  isExpanded(row: IRuleSummaryRulesTableTableRow): boolean {
    return row.expanded;
  }

  editRule(row: IRuleSummaryRulesTableTableRow): void {
    if (row.ruleConfigExists) {
      this.onRuleEditClicked.emit(row.rule.ruleSnapshotId);
    }
  }

  ruleMetricClicked(
    row: {
      ruleDetailRows?: IRuleDetailRow[];
      clickedRuleResultType?: ERuleResultType,
      identifier?: IRuleDetailRowIdentifier
    },
    ruleResultType: ERuleResultType,
    $event: MouseEvent
  ): void {
    $event.stopPropagation();
    if (!row.identifier) {
      return;
    }
    if (row.clickedRuleResultType === ruleResultType) {
      row.clickedRuleResultType = undefined;
    } else {
      this.tableDataSource.data.forEach(r => {
        r.clickedRuleResultType = undefined;
        r.ruleDetailRows && r.ruleDetailRows.forEach(rd => {
          rd.clickedRuleResultType = undefined;
        });
      });
      row.clickedRuleResultType = ruleResultType;
    }
    this.onRuleConditionMetricClicked.emit({
      ...row.identifier,
      ruleResultType: ruleResultType
    });
  }

  private getAllTableColumns(): string[] {
    return Object.values(this.TABLE_COLUMN);
  }

  private dataItemToTableRow(i: IAuditRunAllRuleResultsItem): IRuleSummaryRulesTableTableRow {
    return {
      rule: { ...i },

      ruleName: i.name,
      ruleConfigExists: i.ruleConfigExists,
      expanded: false,
      tags: i.tags.map(t => ({ iconUrl: UiTagService.getTagIconUrl(t.tagId), name: t.tagName })),
      hasEmailNotification: i.hasEmailNotification,
      emailRecipients: i.emailRecipients,
      failedRulePageCount: i.failedRulePageCount,
      passedRulePageCount: i.passedRulePageCount,
      notAppliedRulePageCount: i.notAppliedRulePageCount,
      matchAllFilters: i.matchAllFilters,

      menuOpen: false,
      loadingRuleDetails: false,

      identifier: {
        ruleSnapshotId: i.ruleSnapshotId,
        ruleResultType: ERuleResultType.Failed
      }
    };
  }

  private handleInputData(inputData: IAuditRunAllRuleResultsItem[]) {
    if (inputData && Array.isArray(inputData)) {
      this.tableDataSource.data = inputData.map(i => this.dataItemToTableRow(i));
    }
  }
}
