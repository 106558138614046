import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

export const defaultModalConfigsProvider = {
  provide: MAT_DIALOG_DEFAULT_OPTIONS,
  useValue: {
    disableClose: false,
    closeOnNavigation: true,
    autoFocus: true,
    hasBackdrop: true
  }
};
