import { HttpParameterCodec } from "@angular/common/http";
import { IApiErrorResponse } from "./api.service";
import * as Sentry from '@sentry/angular';

/**
 * Class containing factory methods for creating error handlers for API requests.
 * Callers of services that ultimately make an API request should use these factories
 * to be explicit about the expectation that no errors should occur.
 */
export class ApiErrorHandlerFactory {
  /**
   * This method is used by other methods and returns a Sentry-enabled error handler.
   * This probably should not be called directly unless you're getting a reference to wrap it with your own handler.
   * @param reqMethod The calling method that initiates the callstack that makes the API request.
   * @param url [Optional] the URL of the API request.
   * @returns An instrumented "safety net" error handler that will report to Sentry if an unexpected error occurs in a way that lets us easily identify where the problem occurred.
   */
  static getSafetyNet(reqMethod: string, url: string) {
    return (error: IApiErrorResponse) => {
      console.error('Api SafetyNet caught an error', error, { reqMethod, url });
      Sentry.captureMessage(`Unhandled non-200-response in ${reqMethod} request, ${url}`, {
        level: 'error' as Sentry.SeverityLevel,
        extra: {
          errorDetails: error,
          context: { reqMethod, url }
        }
      });
    }
  }

  /**
   * This method is used when there are no expected error status codes.
   * @param reqMethod The calling method that initiates the callstack that makes the API request.
   * @returns An instrumented "safety net" error handler that will report to Sentry if an unexpected error occurs in a way that lets us easily identify where the problem occurred.
   */
  static noExpectedErrors(reqMethod: string = null) {
    if (!reqMethod) {
      try {
        const e = new Error();
        reqMethod = e.stack.split('\n')[2].replace(/^\s+at\s+/, '');
      } catch { }
    }
    return ApiErrorHandlerFactory.getSafetyNet(reqMethod, '<unknown url>');
  }

  /**
   * This method is used when there are expected error status codes, but they are not user impacting.
   * @param codes A list of http status codes that can be safely suppressed (safely == no user impact)
   * @param reqMethod The calling method that initiates the callstack that makes the API request.
   * @returns An instrumented "safety net" error handler that will report to Sentry if an unexpected error occurs in a way that lets us easily identify where the problem occurred.
   */
  static supressStatusCodes(codes: number[], reqMethod: string = null) {
    if (!reqMethod) {
      try {
        const e = new Error();
        reqMethod = e.stack.split('\n')[2].replace(/^\s+at\s+/, '');
      } catch { }
    }
    const safetyNet = ApiErrorHandlerFactory.getSafetyNet(reqMethod, '<unknown url>');
    return (error: IApiErrorResponse) => {
      if (error && codes.includes(error.statusCode)) {
        return;
      }
      safetyNet(error);
    }
  }
}


export class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
