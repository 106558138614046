import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { Subscription, forkJoin, from, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { CreateEditLabelComponent } from './create-edit-label/create-edit-label.component';
import { BulkActionProgressComponent } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.component';
import { BulkActionProgressService } from '@app/components/shared/components/bulk-action-progress/bulk-action-progress.service';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'manage-labels',
  templateUrl: './manage-labels.component.html',
  styleUrls: ['./manage-labels.component.scss'],
  providers: [
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions },
  ]
})
export class ManageLabelsComponent implements OnInit {
  displayedColumns: string[] = ['select', 'name', 'audits', 'webJourneys', 'rules', 'id', 'delete'];
  dataSource = new MatTableDataSource;
  selection = new SelectionModel(true, []);
  deleteButtonDisabled: boolean = true;
  loading: boolean = true;
  duration: number = 5000;
  processCompleted = false;
  sub: Subscription;

  @ViewChild(MatSort) sort: MatSort;

  readonly maxParallel: number = 1;

  constructor(
    private labelService: LabelService,
    private opModalService: OpModalService,
    private snackBar: MatSnackBar,
    private bulkActionProgressService: BulkActionProgressService,
) { }

  async ngOnInit() {
    this.labelService.getLabels()
      .subscribe((labels: ILabel[]) => {
        this.loading = false;

        this.dataSource.data = labels.map((label: ILabel) => {
          return {
            id: label.id,
            name: label.name,
            audits: label.audits.length,
            webJourneys: label.webJourney.length,
            rules: label.rules.length
          };
        }).sort((a: any, b: any) => (a.name?.toLowerCase() > b.name?.toLowerCase()) ? 1 : -1);

        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = (data: any, filter: string) => {
          const filterLower = filter?.toLowerCase();

          // Filter on all attributes except id
          const matchString = Object.keys(data)
            .filter(key => key !== 'id')
            .reduce((value, key) => `${value}${data[key]}`, '')
            .toLowerCase();

          return matchString.includes(filterLower);
        };

        this.dataSource.sortingDataAccessor = (data, attribute) => {
          if (typeof data[attribute] === 'number') {
            return data[attribute];
          }

          return data[attribute]?.toLowerCase();
        };
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isAllFilteredSelected(): boolean {
    return <boolean> this.dataSource.filteredData.reduce(
      (allSelected: boolean, row) => allSelected && this.selection.isSelected(row),
      true
    );
  }

  masterToggle(): void {
    if (this.dataSource.filter) {
      this.isAllFilteredSelected() ?
        this.dataSource.filteredData.forEach(row => this.selection.deselect(row)) :
        this.dataSource.filteredData.forEach(row => this.selection.select(row));
    } else {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onCheckboxChecked(event: MouseEvent): void {
    event.stopPropagation();
    this.updateButtonStatus();
  }

  onCreateLabel(): void {
    this.opModalService
      .openModal(CreateEditLabelComponent, { })
      .afterClosed()
      .subscribe((label: ILabel) => {
        if (label) {
          this.dataSource.data.push({
            id: label.id,
            name: label.name,
            audits: label.audits?.length,
            webJourneys: label.webJourney?.length,
            rules: label.rules?.length
          });
          this.dataSource.data.sort((a: any, b: any) => (a.name?.toLowerCase() > b.name?.toLowerCase()) ? 1 : -1);
          // forces table to update
          this.dataSource.data = this.dataSource.data;
        }
      });
  }

  onEditLabel(event: MouseEvent, label: ILabel): void {
    this.opModalService
      .openModal(CreateEditLabelComponent, { data: { label: label } })
      .afterClosed()
      .subscribe((updatedLabel: ILabel) => {
        if (updatedLabel) {
          label.name = updatedLabel.name;
          this.dataSource.data.sort((a: any, b: any) => (a.name?.toLowerCase() > b.name?.toLowerCase()) ? 1 : -1);
          // forces table to update
          this.dataSource.data = this.dataSource.data;
        }
      });
  }

  onDeleteLabel(event: MouseEvent, label: ILabel): void {
    event.stopPropagation();
    const data = {
      displayItem: {
        type: 'Label',
        name: label.name,
      },
      deleteButtonAction: () => {
        this.labelService.deleteLabel(label.id).subscribe(() => {
          let index = this.dataSource.data.indexOf(label);
          this.dataSource.data.splice(index, 1);
          // forces table to update
          this.dataSource.data = this.dataSource.data;
        });
      }
    };

    this.opModalService.openDeleteModal({data});
  }

  onDeleteMultiLabels(): void {

    const labelCount: number = this.selection.selected.length;
    const rightFooterButtons = [
      {
        label: 'Cancel',
        action: () => {},
        primary: false
      },
      {
        label: 'Yes, Delete ' + labelCount + ' Labels',
        action: () => {
          this.showProgressDlg(labelCount);
          this.deleteMultiLabels();
        },
        primary: true
      }
    ];

    this.opModalService.openConfirmModal({
      data: {
        title: 'Delete Labels',
        messages: ['You have selected ' + this.selection.selected.length + ' labels to delete. Are you sure you want to proceed?'],
        rightFooterButtons
      }
    });
  }

  // Delete multiple lables
  deleteMultiLabels(): void {

    let processedCount = 0;

    const theObserv = from(this.selection.selected);
      this.sub = theObserv.pipe(
        mergeMap(label => {
          return fromPromise( this.labelService.deleteLabel(label.id).toPromise()
              .then(success => {
                let index = this.dataSource.data.indexOf(label);
                this.dataSource.data.splice(index, 1);
              }))
              .pipe(
                mergeMap(result => of({ label, success: true })),
                catchError(error => {
                  console.log(error);
                  return of({ label, success: false});
                })
              )
        }, this.maxParallel),
        tap(result => {
          this.bulkActionProgressService.publishProgressbar(++processedCount);
        })

      ).subscribe(successes => {
        // forces table to update
        this.dataSource.data = this.dataSource.data;
        this.selection.clear();
        this.updateButtonStatus();
      });
  }

  showProgressDlg(itemsCount: number, maxCountOverride?: number): boolean {

    const formattedMessage: string = "Selected labels are deleted.";
    const formattedMessage2: string = "Don't close this banner, or leave this page until finished or remaining data sources won't be completed.";
    const finalMessage: string = "All selected labels have been deleted.";
    this.processCompleted = false;

    const confirmConfig = {
      maxCount: (maxCountOverride) ? maxCountOverride : itemsCount,
      messages: [
        formattedMessage,
        formattedMessage2,
        finalMessage,
      ],
      showFinalMessage: false,
      showSecondBtn: false,
      showProgressBar: true,
      rightFooterButtons: [
        {
          label: "Yes, delete them",
          opSelector: "bulk-confirm-delete-yes",
          hidden: false,
          primary: false,
        },
        {
          label: "Cancel",
          opSelector: "bulk-confirm-delete-cancel",
          hidden: false,
          primary: false,
        },
      ],
    };

    this.snackBar.openFromComponent(BulkActionProgressComponent, {
      data: confirmConfig,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    }).afterDismissed()
    .subscribe((observer?: MatSnackBarDismiss) => {
      if(observer.dismissedByAction) {
        this.processCompleted = true;
        this.sub.unsubscribe();
        return false;
      }
    });

    return true;
  }

  updateButtonStatus(): void {
    setTimeout(() => {
      this.deleteButtonDisabled = !this.selection.hasValue();
    });
  }
}
