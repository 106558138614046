import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { EUserStep } from '@app/components/create-edit-user-modal/create-edit-user-modal.constants';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AdminPermissions, GuestPermissions, OpAdminPermissions, OpSysAdminPermissions, StandardPermissions, userIsOPAdmin, userIsOPSysAdmin } from '@app/authUtils';
import { IUser } from '@app/moonbeamModels';
import { ApiKeyService, IApiKey, IApiKeyResponse } from '@app/components/account/keys/apiKeysService';
import { EAccountType } from '@app/components/core/services/authentication.enums';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { Features } from '@app/moonbeamConstants';

@Component({
  selector: 'op-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent {

  inAdminPortal: boolean = false;
  accessSectionHeading: string = '';
  selectedAccessLevel: number = null;
  apiKey: IApiKey;
  apiKeyInfoLoaded: boolean = false;
  editingSelf: boolean = false;

  accessLevels = [
    {
      label: 'Admin',
      value: AdminPermissions,
      description: 'This user may create, view, and edit anything in the account'
    },
    {
      label: 'Standard',
      value: StandardPermissions,
      description: 'This user may create, view, and edit anything within shared folder(s)'
    },
    {
      label: 'Read Only',
      value: GuestPermissions,
      description: 'This user may only view items within shared folder(s)'
    }
  ];

  opAdminAccessLevel = {
    label: 'OP Admin',
    value: OpAdminPermissions,
    description: 'This user has access to the admin portal (ObservePoint employees only)'
  };

  opSysAdminAccessLevel = {
    label: 'OP Sys Admin',
    value: OpSysAdminPermissions,
    description: 'This user has full system access (ObservePoint\'s mighty and strong⚡️)'
  };

  hasApiKeyFeature: boolean = false;

  private readonly ADMIN_PORTAL_PATH = '/admin/';
  readonly StandardPermissions = StandardPermissions;
  readonly EAccountType = EAccountType

  @Input() currentStep: EUserStep;
  @Input() formGroup: UntypedFormGroup;
  @Input() editMode: boolean;
  @Input() currentUser: IUser;
  @Input() userBeingEdited: IUser;
  @Input() accountType: EAccountType;

  @Output() selectedPermissionLevel: EventEmitter<number> = new EventEmitter<number>();
  @Output() stepTwoValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private apiKeyService: ApiKeyService,
    private authenticationService: AuthenticationService,
  ) {
    this.inAdminPortal = this.router.url.includes(this.ADMIN_PORTAL_PATH);
    this.authenticationService.getFeatures().subscribe(features => {
      this.hasApiKeyFeature = features.indexOf(Features.apiKey) > -1;
    });
  }

  ngOnInit(): void {
    this.accessSectionHeading = this.editMode ? 'Account Access' : 'What type of account access should this user have?';

    this.initListeners();
    this.setAccessLevel();

    if (!this.editMode) {
      this.apiKeyInfoLoaded = true;
      return;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.editMode && changes.userBeingEdited?.currentValue) {
      this.getApiKey();
    }

    if (changes.accountType?.currentValue) {
      this.addUserPermissionsIfNecessary();
    }

    if (changes.currentUser?.currentValue && changes.userBeingEdited?.currentValue) {
      this.editingSelf = this.currentUser.id === this.userBeingEdited.id;
    }
  }

  private addUserPermissionsIfNecessary(): void {
    if(this.accountType === EAccountType.INTERNAL) {
      if (userIsOPSysAdmin(this.currentUser)) {
        this.accessLevels.unshift(this.opAdminAccessLevel);
        this.accessLevels.unshift(this.opSysAdminAccessLevel);
      }
    }
  }

  private initListeners(): void {
    this.formGroup.valueChanges.subscribe(() => {
      if (this.permissionLevel.valid && this.auditPageLimit.valid) {
        this.stepTwoValid.emit(true);
      }

      if (this.editMode) {
        this.setAccessLevel();
      }
    });

    this.permissionLevel.valueChanges.subscribe((level: number) => {
      this.selectedPermissionLevel.emit(level);
    });
  }

  private setAccessLevel(): void {
    this.selectedAccessLevel = this.permissionLevel.value;
    this.cdr.detectChanges();
  }

  private getApiKey(): void {
    this.apiKeyService.getUserKey(this.userBeingEdited).then((response: IApiKeyResponse) => {
      this.apiKey = response.key;
      this.apiKeyInfoLoaded = true;
      this.cdr.detectChanges();
    });
  }

  setUserAccessLevel(level: number): void {
    this.selectedAccessLevel = level;
    this.permissionLevel.setValue(level);

    // update usage dashboard access if they are not a standard user
    // since it's only applicable to standard users
    if (this.selectedAccessLevel !== StandardPermissions) {
      this.usageDashboardAccess.setValue(false);
      this.formGroup.updateValueAndValidity();
    }
  }

  toggleDashboardAccess(checked: boolean): void {
    this.usageDashboardAccess.setValue(checked);
  }

  generateApiKey(): void {
    this.apiKeyService.createUserKey(this.userBeingEdited).then((apiKey: IApiKey) => {
      this.apiKey = apiKey;
      this.cdr.detectChanges();
    });
  }

  get permissionLevel(): UntypedFormControl {
    return this.formGroup.get('permissionLevel') as UntypedFormControl;
  }

  get auditPageLimit(): UntypedFormControl {
    return this.formGroup.get('auditPageLimit') as UntypedFormControl;
  }

  get usageDashboardAccess(): UntypedFormControl {
    return this.formGroup.get('usageDashboardAccess') as UntypedFormControl;
  }
}
