<ng-container *ngIf="data">
  <ng-container>    
    <div class="legends">
      <span class="legend non-triggered">
        <mat-icon>fiber_manual_record</mat-icon>
        <span>Non-triggered runs</span>
      </span>

      <span class="legend triggered">
        <mat-icon>fiber_manual_record</mat-icon>
        <span>Triggered runs</span>
      </span>
    </div>

    <div *ngIf="data.length > 0" class="vertical-bars-chart-svg-container svg-container-{{ uniqueIdentifier }}"></div>
  </ng-container>

  <div class="zero-state-message" *ngIf="data.length === 0">
    There isn’t any run data for the associated audit. 
  </div>
</ng-container>
