import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ComponentChanges } from '@app/models/commons';
import { Router } from '@angular/router';
import { EAlertResultStatus } from '@app/components/alert/alert.enums';
import { SummaryHistoryPreviewModalComponent } from '@app/components/audit-reports/reports/alert-summary/summary-history-preview-modal/summary-history-preview-modal.component';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { EAlertMetricChangeType, EAlertMetricValueComparator, EAlertPageSummaryMetric, EAlertTagHealthMetric } from '@app/components/alert/alert-logic/alert-logic.enums';
import { ISummaryHistoryPreviewModalPayload } from '@app/components/audit-reports/reports/alert-summary/summary-history-preview-modal/summary-history-preview-modal.models';
import { AlertService } from '../../alert/alert.service';
import { ITriggeredAlertTableRow } from '../triggered-alerts-table/triggered-alerts-table.models';
import { EAlertAssignmentItemType } from '../triggered-alerts.enums';
import { ETriggeredAlertDataSourcesTableColumns } from './triggered-alert-data-sources-table.enums';
import { ITriggeredAlertDataSourcesTableRow, ITriggeredAlertDataSourcesTableSort } from './triggered-alert-data-sources-table.models';
import { triggeredAlertDataSourcesTableDefaultSorting } from './triggered-alert-data-sources-table.constants';
import { AlertUtils } from '@app/components/alert/alert.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'triggered-alert-data-sources-table',
  templateUrl: './triggered-alert-data-sources-table.component.html',
  styleUrls: ['./triggered-alert-data-sources-table.component.scss']
})
export class TriggeredAlertDataSourcesTableComponent implements OnChanges, AfterViewInit, OnDestroy {

  @Input() data: ITriggeredAlertDataSourcesTableRow[];
  @Input() alert: ITriggeredAlertTableRow;
  @Input() loading: boolean;

  @Output() updateTableState = new EventEmitter<ITriggeredAlertDataSourcesTableSort>();

  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild(MatTable) matTable: MatTable<ITriggeredAlertDataSourcesTableRow>;

  dataSource = new MatTableDataSource<ITriggeredAlertDataSourcesTableRow>();

  sortOptions = triggeredAlertDataSourcesTableDefaultSorting;
  sortChangeSubscription: Subscription;
  reloading = false;

  readonly displayedColumns = [
    ETriggeredAlertDataSourcesTableColumns.RunDate,
    ETriggeredAlertDataSourcesTableColumns.DataSourceName,
    ETriggeredAlertDataSourcesTableColumns.Value,
    ETriggeredAlertDataSourcesTableColumns.AlertHistory
  ];
  readonly triggeredAlertDataSourcesTableDefaultSorting = triggeredAlertDataSourcesTableDefaultSorting;

  readonly ETriggeredAlertDataSourcesTableColumns = ETriggeredAlertDataSourcesTableColumns;
  readonly EAlertAssignmentItemType = EAlertAssignmentItemType;
  readonly EAlertMetricChangeType = EAlertMetricChangeType;
  readonly EAlertResultStatus = EAlertResultStatus;
  readonly EAlertMetricValueComparator = EAlertMetricValueComparator;

  constructor(private router: Router,
              private modalService: OpModalService,
              private alertService: AlertService) { }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.matSort;
  }

  ngOnChanges(changes: ComponentChanges<TriggeredAlertDataSourcesTableComponent>) {
    if (changes.data?.currentValue !== changes.data?.previousValue) {
      this.dataSource.data = this.formatData(changes.data.currentValue);
      this.reloading = false;

      if (this.dataSource.data.length > 1 && !this.sortChangeSubscription) {
        if (!this.matSort) {
          return;
        }
        this.sortChangeSubscription = this.matSort.sortChange.subscribe(({ active, direction }) => {
          this.sortOptions.sortBy = active as ETriggeredAlertDataSourcesTableColumns;
          this.sortOptions.sortDesc = direction === 'desc';
          this.sortOptions.sortDir = direction;

          this.updateTableState.emit({...this.sortOptions });
          this.reloading = true;
        });
      }
     }
  }

  ngOnDestroy() {
    this.sortChangeSubscription?.unsubscribe();
  }

  formatData(data: ITriggeredAlertDataSourcesTableRow[]): ITriggeredAlertDataSourcesTableRow[] {
    if (this.alert?.trigger?.metricType) {
      data.map(row => {
        row.value = AlertUtils.renderUiValueWithUnits(
          this.alert.trigger.metricType,
          this.alert.trigger.metricChangeType,
          row.value as number,
        )
      });
    }
    return data;
  }

  openAuditReport({ itemId, runId }: ITriggeredAlertDataSourcesTableRow) {
    const alertReportConfig = AlertUtils.getReportConfigByMetricType(this.alert.trigger.metricType);
    this.router.navigateByUrl(`audit/${itemId}/run/${runId}/${alertReportConfig.link}?alertId=${this.alert.id}`);
  }

  getStatusValue(): string | number {
    if (!this.alert?.trigger) return null;

    const { metricType, metricChangeType, targetValue } = this.alert.trigger;
    return Math.abs(AlertUtils.convertToUIValue(metricType, metricChangeType, targetValue));
  }

  openHistoricalReview(event: PointerEvent, { itemId, runId }: ITriggeredAlertDataSourcesTableRow) {
    event.stopPropagation();

    const alert = {
      id: this.alert.id,
      name: this.alert.name,
      metricType: this.alert.trigger.metricType,
      metricChangeType:this.alert.trigger.metricChangeType,
    }
    const data: ISummaryHistoryPreviewModalPayload = {
      alert,
      barsConfig: { itemId, runId }
    };

    this.modalService.openFixedSizeModal(SummaryHistoryPreviewModalComponent, { data });
  }

}
