import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { WindowRef } from '../core/services/window.service';
import { LoginUrlBuilders } from '../login/login.constants';
import { ShareLinksService } from './share-links.service';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import {
  AUDIT_REPORT_FILTERS_STORAGE_KEY
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { StorageService } from '@app/components/shared/services/storage.service';
import { OpFilterBarService } from '@app/components/shared/components/op-filter-bar/op-filter-bar.service';
import { ISharedItemDetails, IVisitorLoginResponse } from '@app/components/share-links/share-links.models';
import { ESharedItemDetailsItemType } from '@app/components/share-links/share-links.enums';
import { AuthenticationService } from '@app/components/core/services/authentication.service';
import { AuthenticationStorageService } from '@app/components/core/services/authentication-storage.service';
import { FolderService } from '@app/components/folder/folder.service';
import { AccountsService } from '@app/components/account/account.service';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { IFolder } from '@app/components/folder/foldersApiService';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { ApplicationChromeService } from '@app/components/core/services/application-chrome.service';
import { environment } from '@app/environments/environment';

const VISITOR_USERID_PROD = 43267;
const VISITOR_USERID_STG = 10535;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'share-links',
  templateUrl: './share-links.component.html',
  styleUrls: ['./share-links.component.scss']
})
export class ShareLinksComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject();

  loading = true;
  errorMessage: string;

  constructor(
    private windowRef: WindowRef,
    private route: ActivatedRoute,
    private router: Router,
    private shareLinksService: ShareLinksService,
    private authenticationService: AuthenticationService,
    private authenticationStorageService: AuthenticationStorageService,
    private folderService: FolderService,
    private accountsService: AccountsService,
    private auditService: DiscoveryAuditService,
    private storageService: StorageService,
    private applicationChromeService: ApplicationChromeService,
  ) { }

  ngOnInit(): void {
    const authenticationData = this.authenticationStorageService.get();

    // If we are already logged in as a visitor, clear session storage for new share link. This is to allow a visitor
    // to view more than one shared link. Otherwise, we think they are already logged in and the UI breaks. We don't
    // currently have any way to identify a visitor other than this hard-coded user ID after they have already
    // passed the /share link, so not ideal, but only way to know if active user is a visitor or not.
    if (environment.production && authenticationData?.id === VISITOR_USERID_PROD || !environment.production && authenticationData?.id === VISITOR_USERID_STG) {
      this.storageService.clearSessionStorage();
    }

    // Need to wait a cycle to make sure storage is cleared before we handle logging in
    setTimeout(() => {
      this.loginAsVisitorOrContinueAsCurrent();
    });

  }

  // Handles logging in as a visitor or continuing as the current user
  // if they already have access to the shared resource
  loginAsVisitorOrContinueAsCurrent(): void {
    const accessToken = this.route.snapshot.queryParamMap.get('token');
    const authenticationData = this.authenticationStorageService.get();
    const currentlyAuthenticated = !!authenticationData && !!authenticationData.token;
    // Only need the folders if a user is currently logged in
    const getFolders = currentlyAuthenticated ?
      from(this.accountsService.getUserFolders(authenticationData.id)) : of([]);

    let shareRequests = [
      this.shareLinksService.getSharedOPToken({ accessToken }),
      getFolders,
    ];

    // Get visitor and resource info from shared link
    forkJoin(shareRequests)
      .pipe(finalize(() => this.loading = false))
      .subscribe(([visitorLoginResponse, userFolders]) => {
        let { sharedItemDetails, sharedItemDetails: { itemId } } = visitorLoginResponse as IVisitorLoginResponse;

        // Check if user already logged in
        if (currentlyAuthenticated) {
          let getDataSourceCall: Observable<any> = of([]);

          if (sharedItemDetails.itemType === ESharedItemDetailsItemType.Audit) {
            getDataSourceCall = from(this.auditService.getAudit(itemId))
              .pipe(
                catchError((err) => {
                  console.log('error fetching audit: ', err);
                  this.handleVisitorNavigation(visitorLoginResponse as IVisitorLoginResponse);

                  return err;
                })
              );
          }

          getDataSourceCall.subscribe(dataSource => {
            const hasFolderAccess = !!(userFolders as IFolder[]).find(folder => folder.id === dataSource.folderId);

            // If user has access to the data sources' folder, turn off visitor mode and
            // navigate as usual. Otherwise, continue as visitor.
            if (hasFolderAccess) {
              this.applicationChromeService.updateVisitorMode(false);
              switch (sharedItemDetails.itemType) {
                case ESharedItemDetailsItemType.Audit:
                  return this.handleForAudits(sharedItemDetails);
                default:
                  this.errorMessage = 'Unknown shared item type. Please request a new share from the person who sent you the link.';
              }
            } else {
              this.handleVisitorNavigation(visitorLoginResponse as IVisitorLoginResponse);
            }
          });
        } else {
          // If not logged in, continue as visitor.
          this.handleVisitorNavigation(visitorLoginResponse as IVisitorLoginResponse);
        }
      }, error => {
        /** WORKAROUND!
         * The problem is that we don't have access to the Http Status Code here
         * because of how we process errors in the AuthResponseInterceptor.handleResponseError.
         * So, we have to check error message instead.
         * TODO: rewrite AuthResponseInterceptor.handleResponseError, so error code isn't lost along the way
         */
        if (error.message === 'Sharing was not found by provided token') { // Status Code = 404
          this.errorMessage = 'The link to this audit does not exist. Request a new share from the person who sent you the link.';
        } else if (error.message === 'Provided sharing token has expired') { // Status Code = 410
          this.errorMessage = 'The link to this audit is no longer active. Request a new share from the person who sent you the link.';
        } if (error.message === 'You haven\'t supplied a valid auth token') { // Status Code = 401
          // If an existing user was logged in previously, but their auth token
          // has expired, this is the response they will receive when attempting
          // to retrieve folders or users. In that case, just give them the
          // normal visitor login flow.
          this.authenticationStorageService.clear();
          this.loginAsVisitorOrContinueAsCurrent();
        } else {
          this.errorMessage = 'Something happened when loading the link you requested. Please try again or request a new share from the person who sent you the link.';
        }
      });
  }

  // Updates session storage values with the visitor login info and navigates to the shared
  // resource.
  handleVisitorNavigation(visitorLoginResponse: IVisitorLoginResponse): void {
    this.applicationChromeService.updateVisitorMode(true);
    this.shareLinksService.updateSessionStorage(visitorLoginResponse as IVisitorLoginResponse);

    switch (visitorLoginResponse?.sharedItemDetails?.itemType) {
      case ESharedItemDetailsItemType.Audit:
        return this.handleForAudits(visitorLoginResponse?.sharedItemDetails);
      default:
        this.errorMessage = 'Unknown shared item type. Please request a new share from the person who sent you the link.';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleForAudits(sharedItemDetails: ISharedItemDetails) {
    if (sharedItemDetails.filters) {
      OpFilterBarService.setFiltersInStorage(this.storageService, AUDIT_REPORT_FILTERS_STORAGE_KEY, sharedItemDetails.filters);
    }
    this.router.navigateByUrl(`/audit/${sharedItemDetails.itemId}/run/${sharedItemDetails.runId}/${sharedItemDetails.reportName}`);
  }

  openLink(link: string): void {
    this.windowRef.nativeWindow.open(link, 'helpWindow', 'width=1050,height=800');
  }

  switchToLogin() {
    this.router.navigate([LoginUrlBuilders.base()]);
  }
}
