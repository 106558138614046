import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, startWith, switchMap, take } from 'rxjs/operators';
import { ResizeableTableUtils } from '@app/components/utilities/resizeable-table.utils';
import {
  ISwitchableMenuItems,
} from '@app/components/shared/components/switchable-column-menu/switchable-column-menu.models';

@Directive({
  selector: '[resizeableTable]',
})
export class ResizeableTableDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() adjustColumnSizesOnInit: boolean = true;
  @Input() adjustColumnSizesOnChanges: boolean = true;

  private readonly table: HTMLElement;

  private _subscriptions: Subscription[] = [];

  private resize$ = fromEvent(window, 'resize').pipe(debounceTime(250));

  private currentTableSubscription: Subscription;

  set subscriptions(subscription) {
    this._subscriptions.push(subscription);
  }

  get subscriptions(): any {
    return this._subscriptions;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private tableService: ResizeableTableService,
  ) {
    this.table = this.el.nativeElement;
  }

  ngOnInit(): void {
    if (this.adjustColumnSizesOnChanges) {
      this.initCheckboxConfigChangeSubscription();
    }
  }

  ngAfterViewInit(): void {
    if (this.adjustColumnSizesOnInit) {
      this.applyTableColumnWidths({});
    }
    /**
     * Table must have *resizeable* class to display styles correctly
     * Styles are defined in resizeableTable.scss
     */
    this.renderer.addClass(this.table, 'resizeable');
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }

  applyTableColumnWidths(initialColumnsConfig: ISwitchableMenuItems): void {
    let columnsVisibilityConfig = this.getActiveColumns(initialColumnsConfig);

    const allColumnHeaders = ResizeableTableUtils.getAllColumns(this.table);

    this.currentTableSubscription?.unsubscribe();
    this.currentTableSubscription = this.resize$.pipe(startWith(null as Event)).subscribe(event => {
      allColumnHeaders.forEach((cell: HTMLElement, idx) => {
        const columnName = ResizeableTableUtils.cleanTableClassName(cell.classList);
        const isColumnVisible = columnsVisibilityConfig[columnName]?.checked ?? true;

        if (isColumnVisible) {
          const isColumnMarkedAsResizeable =  ResizeableTableUtils.hasResizeableAttribute(cell)

          if (isColumnMarkedAsResizeable) {
            const isNextColumnMarkedAsResizeable = ResizeableTableUtils.hasResizeableAttribute(allColumnHeaders[idx + 1]);
            // Resizeable column that has another resizeable column to the left and non resizeable to the right
            // will always have width set to 'auto', so it lets the table resize
            // If all columns in a table will have explicitly set width
            // the table will be automatically be resizing all columns (proportionally), even if they have max-width set.
            // It is also true when there is only 1 visible resizeable column - it not have a resizer and will have "width: auto"
            // even if it has been manually resized by a user before.
            const requiresWidthAuto = isColumnMarkedAsResizeable && !isNextColumnMarkedAsResizeable;
            let widthToSet = requiresWidthAuto ? 'auto' : this.getUserDefinedColumnWidth(columnName);
            this.renderer.setStyle(cell, 'width', widthToSet);
          }
        }
      });
    });
  }

  private getUserDefinedColumnWidth(columnName: string): string | undefined {
    let userDefinedColumnSizes = this.tableService.pagesConfigInLocalStorage?.sizes ?? [];
    const columnSize = userDefinedColumnSizes.find(d => d.name === columnName);
    return columnSize?.width ? `${columnSize?.width}px` : 'auto';
  }

  private getActiveColumns(initialColumnsConfig: ISwitchableMenuItems): ISwitchableMenuItems {
    return this.tableService.pagesConfigInLocalStorage?.items
      ? this.tableService.pagesConfigInLocalStorage.items
      : initialColumnsConfig;
  }

  private initCheckboxConfigChangeSubscription(): void {
    this.subscriptions = this.tableService.checkboxConfigChanged$
        .pipe(take(1), switchMap(() => this.tableService.checkboxConfigChanged$))
        .subscribe((columns) => setTimeout(() => this.applyTableColumnWidths(columns)));
  }
}
