import { RouteReloadService } from '@app/components/shared/services/route-reload.service';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuditReportBase, IFilterableAuditReport } from '@app/components/audit-reports/reports/general-reports.models';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { BehaviorSubject, EMPTY, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { EAuditReportFilterTypes, } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.models';
import { ActivatedRoute } from '@angular/router';
import { AuditReportFilterBarService } from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import {
  auditTime,
  catchError,
  debounceTime,
  filter,
  finalize,
  first,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { AuditReportLoadingService } from '@app/components/audit-reports/audit-report-loading.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { EConsentCategoryComplianceStatus } from '@app/components/audit-reports/audit-report/audit-report.constants';
import { ConsentCategoriesService } from '@app/components/consent-categories/consent-categories.service';
import { PrivacyRequestsService } from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.service';
import {
  IPrivacyRequestCompliance,
  IPrivacyRequestsLocation,
  IPrivacyRequestsPages,
  IPrivacyRequestsPagesApiBody,
  IPrivacyRequestsSummary,
  IPrivacyRequestsTableState,
  IPrivacyRequestsTrend,
  IPrivacyRequestsTrends,
  PrivacyRequestsComplianceExportMenuData,
  PrivacyRequestsPagesExportMenuData,
  RequestsPrivacyRelevantFilters,
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.models';
import {
  ESplitCardChangeMeaning,
  ISplitCardChartData
} from '@app/components/shared/components/split-card/split-card.models';
import {
  ISparklineChartData,
  ISparklineRunInfo
} from '@app/components/shared/components/viz/sparkline-chart/sparkline-chart.constants';
import { formatNumber } from '@angular/common';
import {
  IFullscreenChartConfig,
  IFullscreenChartData, IFullscreenChartDataWithStats
} from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.constants';
import { ISummaryLine } from '@app/components/shared/components/viz/area-chart/area-chart.constants';
import { FullscreenChartModalComponent } from '@app/components/shared/components/viz/fullscreen-chart-modal/fullscreen-chart-modal.component';
import {
  APPROVED_CHART_CONFIG,
  EPrivacyRequestsExportType,
  EVALUATED_CHART_CONFIG,
  GEOLOCATIONS_CHART_CONFIG,
  PrivacyRequestsTrendName,
  UNAPPROVED_CHART_CONFIG,
  UNIQUE_CHART_CONFIG
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.constants';
import { PrivacyRequestsFullscreenMapModalComponent } from '@app/components/audit-reports/reports/privacy-requests/components/fullscreen-map-modal/privacy-requests-fullscreen-map-modal.component';
import {
  EConsentCategoryType,
  IConsentCategories,
  IConsentCategorySnapshot
} from '@app/components/consent-categories/consent-categories.models';
import { Sort } from '@angular/material/sort';
import { PrivacyRequestsTableComponent } from './components/compliance-table/privacy-requests-table.component';
import { PrivacyRequestsPagesTableComponent } from './components/pages/privacy-requests-pages-table.component';
import { groupBy, head, isEmpty } from 'lodash';
import { ECCEditTabs } from '@app/components/consent-categories/cc-edit/cc-edit.constants';
import { DiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { IAuditModel } from '@app/components/modals/modalData';
import { ISelectedCountry } from '@app/components/audit-reports/reports/privacy-requests/components/map/privacy-requests-map.component';
import { AbbreviateNumberPipe } from '@app/pipes/abbreviate-number.pipe';
import { AuditReportScrollService } from '../../audit-report-scroll.service';
import { sortBy } from 'lodash-es';
import { IOpFilterBarFilter } from '@app/components/shared/components/op-filter-bar/op-filter-bar.models';
import {
  AlertMetricType,
  EAlertRequestsDomainsAndGeosMetric
} from '@app/components/alert/alert-logic/alert-logic.enums';
import { AlertReportingService } from '@app/components/alert/alert-reporting.service';
import { ISpecificAlertSummaryDTO } from '@app/components/alert/alert.models';
import {
  IAuditReportPageDetailsDrawerService
} from '@app/components/audit-reports/audit-report/audit-report-page-details-drawer.models';
import { EConsentCategoryCreateStep } from '@app/components/consent-categories/cc-create/cc-create.enums';
import { ConsentCategoryCreateComponent } from '@app/components/consent-categories/cc-create/cc-create.component';
import { EStandardsTabs } from '@app/components/shared/components/standards-tab/standards-tab.constants';
import { AuditEditorComponent } from '@app/components/audit/audit-editor/audit-editor.component';
import { PrivacyReportsAbstract } from '@app/components/audit-reports/reports/abstracts/privacy-reports.abstract';
import {
  AddConsentCategoriesModalComponent
} from '@app/components/audit-reports/reports/privacy/add-consent-categories-modal/add-consent-categories-modal.component';
import { ResizeableTableService } from '@app/components/shared/directives/resizeable-table/resizeable-table.service';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'privacy-requests',
  templateUrl: './privacy-requests.component.html',
  styleUrls: ['./privacy-requests.component.scss'],
  providers: [AbbreviateNumberPipe, ResizeableTableService]
})
export class PrivacyRequestsComponent
  extends PrivacyReportsAbstract
  implements IFilterableAuditReport, OnInit, OnDestroy {
  readonly EConsentCategoryType = EConsentCategoryType;

  // GENERAL
  audit: IAuditModel;
  auditId: number;
  runId: number;
  totalPageCount: number;
  filteredPageCount: number;
  sparklineDataLoaded: boolean = false;
  apiFilters: IAuditReportApiPostBody = {};
  private filtersUpdated$ = new Subject();

  focusedLocations$: Observable<IPrivacyRequestsLocation[]>;

  //Consent Categories
  isLoadedAuditConsentCategories = false;
  isLoadedRunConsentCategories = false;
  ccsAssignedToRun: IConsentCategorySnapshot[] = [];
  auditConsentCategories: IConsentCategories[] = [];
  categoriesType: ECCEditTabs = ECCEditTabs.requestDomains;

  requests$: Observable<IPrivacyRequestCompliance | any[]>;
  complianceTableState: EFilterSpinnerState;
  complianceTableMetadata: IPrivacyRequestsTableState = {
    sortBy: 'compliance_status',
    sortDesc: true,
    size: 100,
    page: 0,
  };
  sortPaginateRequestCompliance$ = new Subject<void>();
  requestComplianceVariables: IPrivacyRequestsPagesApiBody;
  requestComplianceLocalFilter$ = new Subject<void>();

  pages$: Observable<IPrivacyRequestsPages>;
  pagesTableState: EFilterSpinnerState;
  pagesTablePagination: IPrivacyRequestsTableState = {
    page: 0,
    size: 200,
    sortBy: 'geo_count',
    sortDesc: true
  };
  sortPaginatePages$ = new Subject<void>();

  EConsentCategoryComplianceStatus = EConsentCategoryComplianceStatus;

  // WIDGETS
  widgetsState: EFilterSpinnerState;
  widgetSparklineRunsInfo: ISparklineRunInfo[];

  //1 - PAGES SCANNED
  widgetPagesScanned: ISplitCardChartData = {
    topLabel: 'Pages Scanned',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Count of pages scanned in this audit/run.',
    metricType: EAlertRequestsDomainsAndGeosMetric.PagesScanned,
  };

  //2 - PAGES WITH UNAPPROVED ITEMS
  widgetPagesWithUnapproved: ISplitCardChartData = {
    topLabel: 'Pages with Unapproved Items',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Pages with a request domain or geolocation marked as unapproved in one or more consent category.',
    topHandler: this.toggleFilterByTag(EConsentCategoryType.UNAPPROVED),
    metricType: EAlertRequestsDomainsAndGeosMetric.PagesWithUnapprovedItems,
  };
  unapprovedRequestCount: number;

  //3 - NETWORK REQUEST EVALUATED
  widgetNetworkRequestEvaluated: ISplitCardChartData = {
    topLabel: 'Network Requests Evaluated',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Count of all network requests discovered during this audit/run.',
    bottomHandler: this.openNetworkRequestEvaluatedChart.bind(this),
    metricType: EAlertRequestsDomainsAndGeosMetric.NetworkRequestsEvaluated,
  };
  widgetNetworkRequestEvaluatedData: ISparklineChartData[];

  //4
  widgetUniqueDomains: ISplitCardChartData = {
    topLabel: 'Unique Domains',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Count of unique request domains found during this audit/run.',
    bottomHandler: this.openUniqueDomainsChart.bind(this),
    metricType: EAlertRequestsDomainsAndGeosMetric.UniqueDomains,
  };
  widgetUniqueDomainsData: ISparklineChartData[];

  //5
  widgetUniqueGeolocations: ISplitCardChartData = {
    topLabel: 'Unique Geolocations',
    topChangeMeaning: ESplitCardChangeMeaning.NEUTRAL,
    tooltip: 'Count of unique geolocations found during this audit/run.',
    bottomHandler: this.openUniqueGeolocationsChart.bind(this),
    metricType: EAlertRequestsDomainsAndGeosMetric.UniqueGeolocations,
  };
  widgetUniqueGeolocationsData: ISparklineChartData[];

  //6
  widgetApproved: ISplitCardChartData = {
    topLabel: 'Approved',
    tooltip: 'Count of request domains that were marked as approved in an applied consent category.',
    topHandler: this.toggleFilterByTag(EConsentCategoryType.APPROVED),
    bottomHandler: this.openApprovedChart.bind(this),
    metricType: EAlertRequestsDomainsAndGeosMetric.ApprovedDomains,
  };
  widgetApprovedData: ISparklineChartData[];

  //7
  widgetUnapproved: ISplitCardChartData = {
    topLabel: 'Unapproved',
    tooltip: 'Count of request domains that were marked as unapproved in an applied consent category.',
    topHandler: this.toggleFilterByTag(EConsentCategoryType.UNAPPROVED),
    bottomHandler: this.openUnapprovedChart.bind(this),
    metricType: EAlertRequestsDomainsAndGeosMetric.UnapprovedDomains,
  };
  widgetUnapprovedData: ISparklineChartData[];

  selectedCountry: ISelectedCountry;

  // EXPORTS
  requestsComplianceExportConfig: PrivacyRequestsComplianceExportMenuData = {
    tableName: 'Geolocation and Request Domain Compliance',
    exportType: EPrivacyRequestsExportType.geolocationAndRequestDomainCompliance,
    totalRows: 0,
    filteredRows: 0,
    tableState: this.complianceTableMetadata,
    filters: this.apiFilters
  };
  pagesExportConfig: PrivacyRequestsPagesExportMenuData = {
    tableName: 'Pages Scanned',
    exportType: EPrivacyRequestsExportType.pages,
    totalRows: 0,
    filteredRows: 0,
    tableState: this.pagesTablePagination,
    filters: this.apiFilters,
    specificExportTypes: {
      all: EPrivacyRequestsExportType.pages
    },
    dataToCopy: {
      config: [
        {
          property: 'pageUrl',
          tableColumnName: CommonReportsPagesTableColumns.PageUrl
        },
        {
          property: 'finalPageUrl',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageUrl
        },
        {
          property: 'pageLoadTime',
          displayLike: row => parseFloat((row.pageLoadTime / 1000).toFixed(1)),
          tableColumnName: CommonReportsPagesTableColumns.PageLoadTime
        },
        {
          property: 'finalPageStatusCode',
          tableColumnName: CommonReportsPagesTableColumns.FinalPageStatusCode
        },
        {
          title: '# OF UNIQUE GEOS',
          property: 'geoCount',
          tableColumnName: CommonReportsPagesTableColumns.GeoCount
        },
        {
          title: '# OF NETWORK REQUESTS',
          property: 'requestCount',
          tableColumnName: CommonReportsPagesTableColumns.RequestCount
        }
      ],
      data: null,
      displayedColumns$: this.tableService.displayedColumns$
    }
  };

  private alertCheckComplete$ = new ReplaySubject<boolean>(1);
  currentFilters: IOpFilterBarFilter<EAuditReportFilterTypes>[];
  highlightMetricType: AlertMetricType;
  preventHighlight: boolean;

  @ViewChild('requestsTable') privacyRequestsTableComponent: PrivacyRequestsTableComponent;
  @ViewChild('pagesTable') pagesComponent: PrivacyRequestsPagesTableComponent;
  @ViewChild('pagesTable', { read: ElementRef }) pagesTableScrollTo: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private routeReloadService: RouteReloadService,
    private privacyRequestsService: PrivacyRequestsService,
    private auditReportLoadingService: AuditReportLoadingService,
    protected modalService: OpModalService,
    private filterBarService: AuditReportFilterBarService,
    private modalEscapeService: ModalEscapeService,
    private ccService: ConsentCategoriesService,
    private auditService: DiscoveryAuditService,
    private abbreviateNumberPipe: AbbreviateNumberPipe,
    private cdr: ChangeDetectorRef,
    private scrollService: AuditReportScrollService,
    private alertReportingService: AlertReportingService,
    private pageDetailsDrawerService: IAuditReportPageDetailsDrawerService,
    private tableService: ResizeableTableService,
  ) {
    super();
  }

  ngOnInit() {
    this.route.params
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ auditId, runId }) => {
        this.auditId = +auditId;
        this.runId = +runId;

        const queryParams = (this.route?.queryParams as BehaviorSubject<any>)?.getValue();
        const alertId = queryParams?.alertId;
        const highlight = queryParams?.highlight === undefined; // Only highlight if the query param doesn't exist to (work with existing email URL's)
        this.preventHighlight = !highlight;
        // When loading report from an alert, first override filters with alert
        // filters before loading report data
        if (alertId) {
          this.alertReportingService.getSpecificAlertSummary(this.auditId, this.runId, alertId).subscribe((alert: ISpecificAlertSummaryDTO) => {
            this.filterBarService.overrideFilters(alert.config.filtersV0);
            this.highlightMetricType = highlight && alert.config.metricType;

            this.alertCheckComplete$.next(true);
          });
        } else {
          this.alertCheckComplete$.next(true);
        }

        this.alertCheckComplete$.pipe(
          takeUntil(this.onDestroy$)
        ).subscribe(() => {
          this.onFiltersChanged(this.apiFilters);
        });

        this.getConsentCategoriesList();
        this.getAuditConsentCategoriesList();
      });

    this.routeReloadService.reloadRouteEvents$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.getAudit();
        this.getAuditConsentCategoriesList();
      });

    this.getAudit();
    this.initFilters();
    this.handleComplianceRequests();
    this.handlePages();
    this.handleLocations();
  }

  ngOnDestroy() {
    this.auditReportLoadingService.forceOff();
    this.destroy();
    this.pageDetailsDrawerService.closePageDetails();
  }

  initFilters() {
    this.filterBarService
      .updateSupportedFiltersList(RequestsPrivacyRelevantFilters);

    this.filterBarService
      .apiPostBody$
      .pipe(
        debounceTime(300),
        takeUntil(this.onDestroy$),
        tap(() => this.pagesExportConfig.exportType = EPrivacyRequestsExportType.pages)
      ).subscribe(this.onFiltersChanged.bind(this));
  }

  onFiltersChanged(apiPostBody: IAuditReportApiPostBody) {
    this.apiFilters = apiPostBody;

    if (apiPostBody.countryCode) {
      this.selectedCountry = {
        countryName: 'Selected',
        countryCode: apiPostBody.countryCode
      };
    } else {
      this.selectedCountry = null;
    }

    this.setFirstPagePages();
    this.setFirstPageRequestCompliance();
    this.requestComplianceVariables = undefined;
    this.filtersUpdated$.next();
    this.updateCurrentFilters();
  }

  updateCurrentFilters(): void {
    this.currentFilters = this.filterBarService.currentFilters;
  }

  filterByCountry(country: ISelectedCountry) {
    this.setFirstPagePages();
    this.setFirstPageRequestCompliance();
    if (country) {
      this.filterBarService.addGeolocationFilter(country.countryName, country.countryCode);
    } else {
      this.filterBarService.removeFilterByType(EAuditReportFilterTypes.Geolocation);
    }
  }

  private addSelectionToConsentCategories(items): void {
    this.modalService
      .openModal(AddConsentCategoriesModalComponent, {
        data: {
          runId: this.runId,
          auditId: this.auditId,
          items
        },
        autoFocus: false
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe((selectedConsentCategoryId?: number) => {
      });
  }

  openConsentCategoryManager(): void {
    const assignToCCConfig = {
      data: {
        skipCreate: true,
        step: EConsentCategoryCreateStep.REQUEST_DOMAINS,
        type: !this.ccsAssignedToRun?.length ? 'Assign' : this.ccsAssignedToRun[0]?.type === EConsentCategoryType.UNAPPROVED ? 'Unapproved' : 'Approved',
        editing: true,
        cookies: [],
        tags: [],
        requestDomains: [],
        auditId: this.auditId,
      }
    };

    this.modalService.openFixedSizeModal(ConsentCategoryCreateComponent, assignToCCConfig)
      .afterClosed()
      .pipe(take(1))
      .subscribe((ccData) => {
        if (ccData) {
          this.addSelectionToConsentCategories(ccData);
        }
      });
  }

  openFullscreenMap() {
    this.focusedLocations$
      .pipe(
        first() // This operator is getting only first value from our highlighted countries stream
      )
      .subscribe(focusedCountries => {
        const index = this.modalEscapeService.getLast() + 1;
        this.modalEscapeService.add(index);

        this.modalService.openFullscreenModal(PrivacyRequestsFullscreenMapModalComponent, {
          data: {focusedCountries},
          hasBackdrop: true
        })
          .afterClosed()
          .subscribe(() => this.modalEscapeService.remove(index));
      });
  }

  private getConsentCategoriesList() {
    this.ccService.getConsentCategoriesAssignedToRun(this.auditId, this.runId)
      .pipe(
        catchError(err => {
          console.error(`Failed to load consent category snapshots for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.message}`);
          return EMPTY;
        })
      ).subscribe(ccs => {
        this.ccsAssignedToRun = ccs;
        this.handleWidgets();
        this.handleSparklines();
        this.cdr.detectChanges();
        this.filtersUpdated$.next();
        this.isLoadedRunConsentCategories = true;
    });
  }

  private isFiltered(): boolean {
    return this.filterBarService.currentRelevantFilters.length > 0;
  }

  handleLocations() {
    this.focusedLocations$ = this.filtersUpdated$.pipe(
      takeUntil(this.onDestroy$),
      tap(() => this.auditReportLoadingService.addLoadingToken()),
      switchMap(() => {
        const filters = {...this.apiFilters};
        delete filters.countryCode;
        return this.privacyRequestsService.getPrivacyRequestsLocations(this.auditId, this.runId, filters)
          .pipe(catchError((err) => {
            console.error(`Failed to load locations results for map for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);
            return EMPTY;
          }));
      }),
      map(({locations}) => {
        const groups = groupBy(locations, 'requestGeo.countryCode');
        const keys = Object.keys(groups);
        const locationsNew = [];
        keys.forEach(k => {
          if (groups[k].length !== 1) {
            const approvedCount = groups[k]
              .filter(item => item.status === 'approved')
              .reduce((sum, acc) => sum + acc.requestCount, 0);
            locationsNew.push({...head(groups[k].filter(item => item.status === 'unapproved')), approvedCount});
          } else {
            locationsNew.push(head(groups[k]));
          }
        });

        this.auditReportLoadingService.removeLoadingToken();

        return locationsNew;
      }),
      /**
       * This operator is used for replaying the last state of highlighted countries for other subscribers
       * Second listener of the stream is in method `openFullscreenMap`
       * To prevent double load of the same highlighted countries we used shareReplay(1)
       * See: https://www.learnrxjs.io/learn-rxjs/operators/multicasting/sharereplay
       * */
      shareReplay(1)
    );
  }

  private handleWidgets() {
    this.filtersUpdated$
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => {
          this.auditReportLoadingService.addLoadingToken();
          this.widgetsState = EFilterSpinnerState.Loading;
        }),
        switchMap(() =>
          this.privacyRequestsService
            .getPrivacyRequestsSummary(this.auditId, this.runId, this.apiFilters)
            .pipe(
              catchError((err) => {
                console.error(`Failed to load privacy requests summary for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);
                return of([]);
              }),
              finalize(() => this.finalizeRequest())
            )
        ),
        tap(() => {
          this.auditReportLoadingService.removeLoadingToken();
        })
      )
      .subscribe((data: IPrivacyRequestsSummary | undefined) => {
        this.updateWidgets(data);
        this.widgetsState = this.isFiltered() ? EFilterSpinnerState.Filtered : EFilterSpinnerState.None;
      });
  }

  private handleSparklines() {
    this.filtersUpdated$.pipe(
      takeUntil(this.onDestroy$),
      tap(() => {
        this.sparklineDataLoaded = false;
        this.auditReportLoadingService.addLoadingToken();
      }),
      switchMap(() =>
        this.privacyRequestsService.getPrivacyRequestsTrends(this.auditId, this.runId)
          .pipe(
            catchError((err) => {
              console.error(`Failed to load privacy trends for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);
              return of([]);
            }),
            finalize(() => this.finalizeRequest())
          )),
      map((data: IPrivacyRequestsTrends) => sortBy(data.runs, el => el.runId)),
      tap(() => {
        this.auditReportLoadingService.removeLoadingToken();
      })
    ).subscribe(runs => {
      this.updateSparkLines(runs);
      this.sparklineDataLoaded = true;
    });
  }

  private handleComplianceRequests() {
    this.requests$ = merge(
      this.filtersUpdated$,
      this.sortPaginateRequestCompliance$
    ).pipe(
      takeUntil(this.onDestroy$),
      auditTime(200),
      tap(() => {
        this.auditReportLoadingService.addLoadingToken();
        this.complianceTableState = EFilterSpinnerState.Loading;
        this.cdr.detectChanges();
      }),
      switchMap(() =>
        this.privacyRequestsService.getPrivacyRequestsCompliance(
          this.auditId,
          this.runId,
          this.complianceTableMetadata,
          this.apiFilters
        )
          .pipe(
            tap(data => {
              this.requestsComplianceExportConfig = {
                ...this.requestsComplianceExportConfig,
                filters: this.apiFilters,
                totalRows: Infinity,
                filteredRows: data.metadata.pagination.totalCount,
                tableState: this.complianceTableMetadata
              };
            }),
            catchError((err) => {
              console.error(`Failed to load privacy requests compliance for auditId=${this.auditId}, runId=${this.runId}, reason: ${err.errorCode}`);
              this.complianceTableState = EFilterSpinnerState.None;

              return of([]);
            }),
            finalize(() => {
              this.auditReportLoadingService.removeLoadingToken();
              this.complianceTableState = this.filterBarService.currentRelevantFilters.length
                ? EFilterSpinnerState.Filtered
                : EFilterSpinnerState.None;
              this.cdr.detectChanges();
            })
          )
      )
    );
  }

  private handlePages() {
    this.pages$ =
      merge(
        this.requestComplianceLocalFilter$.pipe(tap(() => {
          this.pagesExportConfig.filters = {
            ...this.apiFilters,
            ...this.requestComplianceVariables
          };
        })),
        this.filtersUpdated$.pipe(tap(() => this.pagesExportConfig.filters = this.apiFilters)),
        this.sortPaginatePages$
      ).pipe(
        takeUntil(this.onDestroy$),
        auditTime(200),
        tap(() => {
          this.auditReportLoadingService.addLoadingToken();
          this.pagesTableState = EFilterSpinnerState.Loading;
          this.cdr.detectChanges();
        }),
        switchMap(() => {
          if (!isEmpty(this.requestComplianceVariables)) {
            return this.privacyRequestsService.getPrivacyRequestsRequestPages(
              this.auditId,
              this.runId,
              this.pagesTablePagination,
              {...this.apiFilters, ...this.requestComplianceVariables}
            )
              .pipe(
                catchError(() => {
                  this.pagesTableState = EFilterSpinnerState.None;
                  return EMPTY;
                }),
                finalize(() => this.finalizeRequest())
              );
          } else {
            this.pagesExportConfig.filteredRows = Infinity;
          }

          return this.privacyRequestsService.getPrivacyRequestsPages(
            this.auditId,
            this.runId,
            this.pagesTablePagination,
            this.apiFilters
          )
            .pipe(
              catchError(() => {
                this.pagesTableState = EFilterSpinnerState.None;
                return EMPTY;
              }),
              finalize(() => this.finalizeRequest())
            );
        }),
        tap(({pages}) => this.pagesExportConfig.dataToCopy.data = pages)
      );
  }

  private updateWidgets(data: IPrivacyRequestsSummary) {
    this.pagesExportConfig.totalRows = this.totalPageCount = data.totalPageCount;
    this.filteredPageCount = data.filteredPageCount;

    this.pagesExportConfig.filteredRows = isEmpty(this.apiFilters) ? this.totalPageCount : this.filteredPageCount;

    this.unapprovedRequestCount = data.filteredPageWithUnapprovedRequestCount;

    this.widgetNetworkRequestEvaluated.topChangeContent = this.formatWidgetContent(data.filteredRequestCount, data.totalRequestCount, true);
    this.widgetNetworkRequestEvaluated.rawValue = data.filteredRequestCount;
    this.widgetUniqueDomains.topChangeContent = this.formatWidgetContent(data.filteredUniqueRequestDomainCount);
    this.widgetUniqueGeolocations.topChangeContent = this.formatWidgetContent(data.filteredUniqueRequestGeoCount);
    this.widgetApproved.topChangeContent = this.formatWidgetContent(data.filteredUniqueApprovedRequestDomainCount, data.totalUniqueApprovedRequestDomainCount);
    this.widgetUnapproved.topChangeContent = this.formatWidgetContent(data.filteredUniqueUnapprovedRequestDomainCount, data.totalUniqueUnapprovedRequestDomainCount);

    this.widgetApproved.topChangeMeaning = data.filteredUniqueApprovedRequestDomainCount === 0
      ? ESplitCardChangeMeaning.NEUTRAL
      : ESplitCardChangeMeaning.POSITIVE;

    this.widgetUnapproved.topChangeMeaning = data.filteredUniqueUnapprovedRequestDomainCount === 0
      ? ESplitCardChangeMeaning.NEUTRAL
      : ESplitCardChangeMeaning.NEGATIVE;
  }

  private updateSparkLines(runs: IPrivacyRequestsTrend[]) {
    const networkRequestEvaluatedData: ISparklineChartData[] = [];
    const widgetUniqueData: ISparklineChartData[] = [];
    const widgetUniqueGeolocationsData: ISparklineChartData[] = [];
    const widgetApprovedData: ISparklineChartData[] = [];
    const widgetUnapprovedData: ISparklineChartData[] = [];
    const runsInfo: ISparklineRunInfo[] = [];

    runs.forEach((val, index) => {
      networkRequestEvaluatedData.push({value: val.totalRequestCount, sequence: index});
      widgetUniqueData.push({value: val.totalUniqueRequestDomainCount, sequence: index});
      widgetUniqueGeolocationsData.push({value: val.totalUniqueRequestGeoCount, sequence: index});
      widgetApprovedData.push({value: val.totalUniqueApprovedRequestDomainCount, sequence: index});
      widgetUnapprovedData.push({value: val.totalUniqueUnapprovedRequestDomainCount, sequence: index});
      runsInfo.push({runId: val.runId, runCompletionDate: val.completedAt});
    });

    this.widgetNetworkRequestEvaluated.bottomHandler = networkRequestEvaluatedData.length < 2 ? null : this.widgetNetworkRequestEvaluated.bottomHandler;
    this.widgetUniqueDomains.bottomHandler = widgetUniqueData.length < 2 ? null : this.widgetUniqueDomains.bottomHandler;
    this.widgetUniqueGeolocations.bottomHandler = widgetUniqueGeolocationsData.length < 2 ? null : this.widgetUniqueGeolocations.bottomHandler;
    this.widgetApproved.bottomHandler = widgetApprovedData.length < 2 ? null : this.widgetApproved.bottomHandler;
    this.widgetUnapproved.bottomHandler = widgetUnapprovedData.length < 2 ? null : this.widgetUnapproved.bottomHandler;

    this.widgetSparklineRunsInfo = runsInfo;
    this.widgetNetworkRequestEvaluatedData = networkRequestEvaluatedData;
    this.widgetUniqueDomainsData = widgetUniqueData;
    this.widgetUniqueGeolocationsData = widgetUniqueGeolocationsData;
    this.widgetApprovedData = widgetApprovedData;
    this.widgetUnapprovedData = widgetUnapprovedData;
  }

  private formatWidgetContent(filteredCount: number, totalCount?: number, abbreviate = false): string {
    const format = (x: number) => formatNumber(x, 'en-US');
    if (!this.isFiltered() || (this.isFiltered() && !totalCount)) {
      return abbreviate ? this.abbreviateNumberPipe.transform(filteredCount) : format(filteredCount);
    } else {
      return abbreviate ?
        `${this.abbreviateNumberPipe.transform(filteredCount)} of ${this.abbreviateNumberPipe.transform(totalCount)}` :
        `${format(filteredCount)} of ${format(totalCount)}`;
    }
  }

  private openFullscreenChart(
    trendName: PrivacyRequestsTrendName,
    secondToLastCompletionDate: string,
    getSummaryLines?: (data: IFullscreenChartData[]) => ISummaryLine[]
  ): void {
    const index = this.modalEscapeService.getLast() + 1;
    this.modalEscapeService.add(index);

    this.modalService.openFullscreenModal(FullscreenChartModalComponent, {
      data: {
        timeframeOriginRunCompletion: secondToLastCompletionDate,
        getData: (days: number) => this.getFullscreenChartData(trendName, days),
        getSummaryLines,
        chartConfig: this.getFullscreenChartConfig(trendName)
      }
    })
      .afterClosed()
      .subscribe(() => this.modalEscapeService.remove(index));
  }

  private getFullscreenChartData(trendName: any, days: number): Observable<IFullscreenChartDataWithStats> {
    return this.privacyRequestsService.getPrivacyRequestsTrend(this.auditId, trendName, days)
      .pipe(
        map(({ runs }) => ({
            chartData: runs.map(({ trendValue, completedAt }) => ({ value: trendValue, date: completedAt }))
          })
        )
      );
  }

  private getFullscreenChartConfig(trendName: PrivacyRequestsTrendName): IFullscreenChartConfig {
    switch (trendName) {
      case PrivacyRequestsTrendName.Unique:
        return UNIQUE_CHART_CONFIG;
      case PrivacyRequestsTrendName.Evaluated:
        return EVALUATED_CHART_CONFIG;
      case PrivacyRequestsTrendName.Geolocations:
        return GEOLOCATIONS_CHART_CONFIG;
      case PrivacyRequestsTrendName.Approved:
        return APPROVED_CHART_CONFIG;
      case PrivacyRequestsTrendName.Unapproved:
        return UNAPPROVED_CHART_CONFIG;
    }
  }

  private toggleFilterByTag(name: EConsentCategoryType) {
    return () => {
      if (this.isFilteredByStatus(name)) {
        this.filterBarService.removeFilterByType(EAuditReportFilterTypes.ConsentCategoryComplianceStatus);
      } else {
        this.filterBarService.addConsentCategoryStatusFilter(name);
      }
    };
  }

  isFilteredByStatus(name: EConsentCategoryType) {
    return this.filterBarService.isFilteredByTypeAndValue(EAuditReportFilterTypes.ConsentCategoryComplianceStatus, name);
  }

  private openNetworkRequestEvaluatedChart() {
    if (this.widgetSparklineRunsInfo.length > 1) {
      this.openFullscreenChart(PrivacyRequestsTrendName.Evaluated, this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate);

    }
  }

  private openUniqueGeolocationsChart() {
    if (this.widgetSparklineRunsInfo.length > 1) {
      this.openFullscreenChart(PrivacyRequestsTrendName.Geolocations, this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate
      );
    }
  }

  private openUniqueDomainsChart() {
    if (this.widgetSparklineRunsInfo.length > 1) {
      this.openFullscreenChart(PrivacyRequestsTrendName.Unique, this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate);
    }
  }

  private openUnapprovedChart() {
    if (this.widgetSparklineRunsInfo.length > 1) {
      this.openFullscreenChart(PrivacyRequestsTrendName.Unapproved, this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate);
    }
  }

  private openApprovedChart() {
    if (this.widgetSparklineRunsInfo.length > 1) {
      this.openFullscreenChart(PrivacyRequestsTrendName.Approved, this.widgetSparklineRunsInfo[this.widgetSparklineRunsInfo.length - 2].runCompletionDate);
    }
  }

  private setFirstPageRequestCompliance() {
    this.complianceTableMetadata.page = 0;

    if (this.privacyRequestsTableComponent) {
      this.privacyRequestsTableComponent.paginator.pageIndex = 0;
    }
  }

  private setFirstPagePages() {
    this.pagesTablePagination.page = 0;

    if (this.pagesComponent) {
      this.pagesComponent.paginator.pageIndex = 0;
    }
  }

  handleSortCompliance(sort: Sort) {
    this.complianceTableMetadata.sortBy = sort.active;
    this.complianceTableMetadata.sortDesc = sort.direction === 'desc';
    this.setFirstPageRequestCompliance();
    this.sortPaginateRequestCompliance$.next();
  }

  handlePaginateCompliance(index: number) {
    this.complianceTableMetadata.page = index;
    this.sortPaginateRequestCompliance$.next();
  }

  handleLocalFilter(data: IPrivacyRequestsPagesApiBody) {
    this.requestComplianceVariables = data;
    this.pagesTablePagination.page = 0;
    this.setFirstPagePages();
    this.requestComplianceLocalFilter$.next();
    this.scrollService.scrollByElement(this.pagesTableScrollTo.nativeElement);
    this.pagesExportConfig.exportType = EPrivacyRequestsExportType.requestPages;
  }

  private finalizeRequest() {
    this.auditReportLoadingService.removeLoadingToken();
    this.pagesTableState = this.filterBarService.currentRelevantFilters.length
      ? EFilterSpinnerState.Filtered
      : EFilterSpinnerState.None;
  }

  handleSortPages(sort: Sort) {
    this.pagesTablePagination.sortBy = sort.active;
    this.pagesTablePagination.sortDesc = sort.direction === 'desc';
    this.setFirstPagePages();
    this.sortPaginatePages$.next();
  }

  handlePaginationPages(index: number) {
    this.pagesTablePagination.page = index;
    this.sortPaginatePages$.next();
  }

  handleConsentCategoryNameFilterFromCompliance(snapshotId: number): void {
    const cc = this.ccsAssignedToRun.find(cc => cc.consentCategorySnapshotId === snapshotId);
    if (cc) {
      this.filterBarService.addConsentCategoryNameFilter(cc.consentCategoryId, cc.name);
    }
  }

  private getAuditConsentCategoriesList() {
    this.ccService
      .getConsentCategoriesAssignedToAudit(this.auditId)
      .subscribe(ccs => {
        this.auditConsentCategories = ccs;
        this.isLoadedAuditConsentCategories = true;
      });
  }

  private getAudit() {
    this.auditService
      .getAudit(this.auditId)
      .then(audit => this.audit = audit);
  }
}
