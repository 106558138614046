import { ICookiePrivacySummary } from '@app/components/audit-reports/reports/privacy-cookies/privacy-cookies.models';
import { ESplitCardChangeMeaning } from '@app/components/shared/components/split-card/split-card.models';
import {
  IHorizontalBarChartDataPoint
} from '@app/components/shared/components/viz/horizontal-bar-chart-rounded/horizontal-bar-chart-rounded.models';
import { IStackedBarChartDataPoint } from '@app/components/audit-reports/reports/tag-inventory/tag-inventory.models';
import {
  IStatsPopupPanelData
} from '@app/components/audit-reports/use-cases/stats-popup-panel/stats-popup-panel.models';
import {
  IDonutChartDataPoint
} from '@app/components/shared/components/viz/donut-chart-with-key/donut-chart-with-key.models';
import {
  TagDuplicatesAndMultiplesSummary
} from '@app/components/audit-reports/reports/tag-duplicates-and-multiples/tag-duplicates-and-multiples.models';
import { IPageSummaryInsights } from '@app/components/audit-reports/reports/page-summary/page-summary.models';
import {
  CookieInventoryCookies,
  CookieInventorySummary
} from '@app/components/audit-reports/reports/cookie-inventory/cookie-inventory.models';
import { TagHealthSummary } from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import { IDomain } from '@app/components/domains/domainsService';
import { ITagPrivacySummary } from '@app/components/audit-reports/reports/privacy-tags/privacy-tags.models';
import {
  IPrivacyRequestsLocation,
  IPrivacyRequestsSummary
} from '@app/components/audit-reports/reports/privacy-requests/privacy-requests.models';
import { IAuditPageInfoWebVitalsSummaryItem } from '@app/components/audit-reports/page-details/page-details.models';
import {
  EWebVitalsMetricType
} from '@app/components/shared/components/viz/web-vitals-chart/web-vitals-chart.constants';

export interface IUseCaseTabs {
  label: string;
  path: string;
  matIcon: string;
  svgIcon: string;
}

export interface IUseCaseSection {
  sectionIcon: string;
  sectionTitle: string;
  customIcon?: boolean;
  bannerColor?: EUseCaseBannerColor;
  bannerText?: string;
  bannerClickAction?: Function;
}

export interface IUseCaseWidget {
  opSelector?: string;
  loading?: boolean;
  hasCustomLeftSideContent?: boolean;
  splitCard?: boolean;
  topIconName?: string;
  topIconOutline?: boolean;
  topIconPath?: string;
  topIconMeaning?: ESplitCardChangeMeaning;
  topText?: string;
  topValue?: string|number;
  topValueMeaning?: ESplitCardChangeMeaning;
  topTooltip?: string;
  topClickAction?: Function;
  hideBottom?: boolean;
  bottomText?: string;
  bottomValue?: string|number;
  bottomTooltip?: string;
  navTo?: Function;
  bodyContent?: IUseCaseBodyContent;
  statsData?: IStatsPopupPanelData;
  chartData?: IHorizontalBarChartDataPoint[]|IStackedBarChartDataPoint[]|IDonutChartDataPoint[]|IUseCaseMostUsedTag[]|IPrivacyRequestsLocation[];
  bannerText?: string;
  bannerColor?: EUseCaseBannerColor;
  bannerClickAction?: Function;
  skipNavigationConfirm?: boolean;
  bottomLeftCustomContent?: string;
}

export interface IUseCaseWebVitalsWidget extends Omit<IUseCaseWidget, 'chartData'> {
  chartData?: IAuditPageInfoWebVitalsSummaryItem;
  metricType: EWebVitalsMetricType;
}

export interface IUseCaseBodyContent {
  bodyText?: string;
  navToText?: string;
}

export enum EUseCaseNavToKeys {
  PageSummary,
  PageSummarySlowLoading,
  PageSummaryBrokenFinalPages,
  PageSummaryAveragePageLoadTime,
  PageSummaryPagesMissingQueryStrings,
  PageSummaryPagesWithRedirects,
  TagInventory,
  TagHealth,
  TagInventoryAnalytics,
  TagInventoryDataLayer,
  TagInventoryMissingTag,
  TagInventorySlowLoadingTags,
  TagInventoryBrokenTags,
  TagInventoryDuplicateTags,
  TagInventoryRuleFailures,
  TagInventoryPagesMissingAnalytics,
  TagInventoryPagesMissingTagManager,
  TagInventoryPagesMissingConsentManager,
  VariableInventory,
  VariableAnalytics,
  UniqueTagVariables,
  UniqueTagVariableValues,
  CookiesInventory,
  CookiesInventoryNonSecureCookies,
  PagesMissingShareSellLinks,
  PagesMissingPrivacyPolicyLinks,
  PrivacyCookies,
  NewJavascriptFiles,
  UnapprovedCookies,
  UnapprovedTags,
  UnapprovedGeoLocations,
  FreeTrial,
  SampleAccount,
  PrivacyGeoLocations,
  PrivacyTags,
  CookiesInventory3rdParty
}

export interface IAuditUseCaseData {
  domain: string;
  domainFull: string;
  pageTitle: string;
}

export interface IDataLayerInfo {
  dataLayer: string;
  dataLayerPagesPercent: string;
  domain: IDomain;
}

export enum EUseCaseWidgetType {
  OverviewPagesScanned,
  AnalyticsPagesScanned,
  PrivacyPagesScanned,
  PrivacyUniqueCookies,
  Privacy3rdPartyCookies,
  PrivacyUniqueTags,
  PrivacyUniqueGeolocations,
  LandingPagePagesScanned,
  UniqueAnalytics,
  TopAnalytics,
  TopTms,
  TagRequests,
  BrokenTagRequests,
  DuplicateTagRequests,
  TagAndVariableRuleFailures,
  UniqueTagVariables,
  UniqueTagVariableValues,
  LandingPageAveragePageLoadTime,
  LandingPagePagesMissingQueryStrings,
  LandingPagePagesWithRedirects,
  LandingPageBrokenPages,
  LandingPageUniqueTags,
  LandingPagePagesMissingAnalytics,
  LandingPagePagesMissingTagManager,
  OverviewAveragePageLoadTime,
  OverviewLargestContentfulPaint,
  OverviewBrokenPages,
  OverviewUniqueCookies,
  OverviewNonSecureCookies,
  OverviewUniqueTags,
  OverviewBrokenTags,
  OverviewAverageTagLoadTime,
  OverviewTagInitiator,
  PagesMissingConsentManager,
  PagesMissingTagManager,
  PagesMissingPrivacyPolicyLinks,
  PrivacyPagesMissingShareSellLinks,
  PrivacyNewJavascriptFiles,
}

export interface IUseCaseDuplicatesAndMultiplesWidgetData extends TagDuplicatesAndMultiplesSummary{
  duplicatesAndMultiplesChartData: IDonutChartDataPoint[];
}

export interface IUseCasePageSummaryInsight extends IPageSummaryInsights {
  averagePageLoadTimeChartData: IHorizontalBarChartDataPoint[];
  brokenPagesChartData: IDonutChartDataPoint[];
  overviewLargestContentfulPaintChartData: IAuditPageInfoWebVitalsSummaryItem;
}

export interface IUseCaseCookiesSummaryInsight extends CookieInventorySummary {
  partCookiesChartData: IDonutChartDataPoint[];
  securityCookiesChartData: IDonutChartDataPoint[]
}

export interface IUseCaseTagHealthSummaryInsight extends TagHealthSummary {
  brokenTagsChartData: IDonutChartDataPoint[];
  tagRequestsChartData: IDonutChartDataPoint[]
}

export interface IUseCaseAnalyticsSummaryInsight {
  brokenAnalyticsTagCount: number;
  uniqueAnalyticsTagCount: number;
  tagInstanceCount: number;
  pageCountWithAnalytics: number;
  pageCountTotal: number;
  uniqueAnalyticsChartData: IStackedBarChartDataPoint[];
  tagRequestsChartData: IDonutChartDataPoint[];
  topTms: any;
  topAnalyticsTag: any;
}

export interface IUseCaseMostUsedTagsSummaryInsight extends CookieInventoryCookies {
  mostUsedTags: IUseCaseMostUsedTag[];
}

export interface IUseCaseMostUsedTag {
  domain: string;
  percent: number;
}

export interface IUseCaseTagPrivacyInfo extends ITagPrivacySummary {
  assignedCCCount: number;
}

export interface IUseCaseRequestPrivacyInfo extends IPrivacyRequestsSummary {
  totalUnapprovedRequestGeos: number;
  assignedCCCount: number;
}

export interface IUseCaseCookiePrivacyInfo extends ICookiePrivacySummary {
  assignedCCCount: number;
}

export enum EUseCaseBannerColor {
  Yellow,
  Gray,
}
