import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';
import { IComparisonCreate, IComparison, IAccountTagSettings } from './comparisons.models';
import { ApiService } from '../core/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOneTimeComparisonDetails } from './one-time-comparison/one-time-comparison.models';
import { defaultDatasource } from './shared/comparison-variable-selector/comparison-variable-selector.constants';
import { EComparisonType } from './comparisons.enums';

@Injectable({
  providedIn: 'root'
})
export class ComparisonsService {

  basePath = environment.apiV3Url + 'comparisons';

  constructor(private apiService: ApiService) { }

  create(comparison: IComparisonCreate): Observable<number> {
    return this.apiService.post(this.basePath, comparison);
  }

  update(comparison: IComparisonCreate, id: number): Observable<void> {
    return this.apiService.put(`${this.basePath}/${id}`, comparison);
  }

  get(id: number): Observable<IComparison> {
    return this.apiService.get<IComparison>(`${this.basePath}/${id}`).pipe(
      map(comparison => {
        if (comparison.type !== EComparisonType.oneTime) return comparison;
        const datasource = (comparison.details as IOneTimeComparisonDetails).datasource;
        // API Workaround. defaultDatasource is needed for API in case of no more selected detasources except "All (Tags Presence Only)"
        datasource.tags = datasource.tags.filter(t => t.tagId !== defaultDatasource.tagId);
        return comparison;
      })
    );
  }

  getAccountTags(): Observable<IAccountTagSettings[]> {
    return this.apiService.get(`${this.basePath}/account-settings/tags`);
  }

}
