import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IButton } from '@app/models/commons';
import { ILabel, LabelService } from '@app/components/shared/services/label.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'add-label-modal',
  templateUrl: './add-label-modal.component.html',
  styleUrls: ['./add-label-modal.component.scss']
})
export class AddLabelModalComponent implements OnInit {

  modalTitle: string = '';
  labels: ILabel[] = [];
  selectedLabels: ILabel[] = [];

  rightFooterButtons: IButton[] = [
    {
      label: 'Add',
      action: () => this.closeModal(this.selectedLabels),
      primary: true,
      disabled: true
    }
  ];

  constructor(
    private dialogRef: MatDialogRef<AddLabelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private labelApiService: LabelService
  ){
    const plural = this.data.selectedCardsCount > 1 ? 's' : '';
    this.modalTitle = `Add label to ${this.data.selectedCardsCount} item${plural}`;
  }

  ngOnInit(): void {
    this.labelApiService.getLabels().subscribe((labels: ILabel[]) => {
      this.labels = labels;
    });
  }

  addLabel(label: ILabel): void {
    this.selectedLabels.push(label);
    this.handleFooterBtnDisabledState();
  }

  removeLabel(label: ILabel): void {
    const index = this.selectedLabels.indexOf(label);
    this.selectedLabels.splice(index, 1);
    this.handleFooterBtnDisabledState();
  }

  handleFooterBtnDisabledState(): void {
    this.rightFooterButtons[0].disabled = !this.selectedLabels.length;
  }

  closeModal(labels?: ILabel[]): void {
    this.dialogRef.close(labels);
  }
}
