import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  EBarChartDirection,
  EBarChartTextPosition
} from '@app/components/shared/components/viz/horizontal-bar-chart/horizontal-bar-chart.constants';
import { CommonReportsPagesTableColumns } from '@app/components/audit-reports/reports/general-reports.constants';
import { IUiTag } from '@app/components/tag-database';

export interface CookieInventorySummary {
  totalPageCount: number;
  filteredPageCount: number;
  totalUniqueCookieCount: number;
  filteredUniqueCookieCount: number;
  totalFirstPartyCookieCount: number;
  filteredFirstPartyCookieCount: number;
  totalThirdPartyCookieCount: number;
  filteredThirdPartyCookieCount: number;
  filteredNonSecureCookieCount: number;
  filteredSameSiteEmptyCookieCount: number;
  filteredUniqueCookieDomainCount: number;
}

export interface CookieInventoryTrendRunOverviews {
  runs: CookieInventoryTrendRunOverview[];
}

export interface CookieInventoryTrendRunOverview {
  runId: number;
  completedAt: string;
  totalUniqueCookieCount: number;
  totalFirstPartyCookieCount: number;
  totalThirdPartyCookieCount: number;
  totalNonSecureCookieCount: number;
  totalSameSiteEmptyCookieCount: number;
}

export interface CookieInventoryTrends {
  runs: CookieInventoryTrend[];
}

export interface CookieInventoryTrend {
  runId: number;
  completedAt: string;
  trendValue: number;
}

export interface CookieInventoryCookies {
  metadata: {
    pagination: {
      totalCount: number;
      totalPageCount: number;
      pageSize: number;
      currentPageSize: number;
      currentPageNumber: number
    }
  },
  cookies: CookieInventoryCookie[];
}

export enum EOriginType {
  PRE_AUDIT_ACTION = 'Pre-Audit Action',
  PAGE_LOAD = 'Page Load',
  ON_PAGE_ACTION = 'On-Page Action',
}

export interface CookieInventoryCookie {
  name: string,
  domain: string,
  partyType: string,
  partitionKey: string;
  expirationType: string,
  sameSite?: string,
  samesite?: string,
  initiators: CookieTagInitiator[];
  initiatorTotalCount: number;
  initiatorTagIds: number[];
  secure: boolean,
  httpOnly: boolean,
  averageSize: number,
  filteredPageCount: number,
  minDurationInMinutes: number,
  maxDurationInMinutes: number,
  cookieChangedDuring: EOriginType[],
}

export interface CookieInventoryCookieRow {
  name: string,
  domain: string,
  partyType: string,
  expirationType: string,
  sameSite: string,
  secure: boolean,
  httpOnly: boolean,
  averageSize: number,
  filteredPageCount: number;
  initiators: CookieTagInitiatorRow[];
  initiatorTagIds: number[];
  firstTagUrl: string;
  firstTagName: string;
  uniqueTagsCount: number;
  uniqueTagsList: IUiTag[];
  initiatorTotalCount: number;
  partitionKey: string;
  barChartSettings: {
    state: EFilterSpinnerState;
    displayPills: boolean;
    calcAsPercentage: boolean;
    displayPercentSymbol: boolean;
    textPosition: EBarChartTextPosition;
  },
  onPages: {
    chartData: [{
      name: string;
      colorClass: string;
      filtered: boolean;
      value: number;
      displayValue: number | null;
    }],
    barDirection: EBarChartDirection;
    uniqueIdentifier: string;
  },
  origin: {
    text: string | number;
    tooltip: string;
    icon?: string;
  }
}

export interface CookieTagInitiator {
  initiatorType: 'UNKNOWN' | 'OP_ACTION' | 'HTTP' | 'APP';
  instanceCount: number;
  tagId?: number;
  urlDomain?: string;
  foundDuring: EOriginType[];
}

export interface CookieTagInitiatorRow extends CookieTagInitiator {
  tagName: string;
  tagImageUrl: string;
  setMethodLabel: string;
  setMethodTooltip: string;
  origin: {
    text: string;
  }
}

export interface AuditData {
  browserVersion: number,
  userAgent: string
  width: number,
  height: number,
  loginActions: boolean
}

export interface CookieInventoryPagesTablePagination {
  page: number;
  size: number;
  sortBy: CommonReportsPagesTableColumns;
  sortDesc: boolean;
}

export interface CookiesTableSorting {
  page?: number;
  size?: number;
  sortBy: string;
  sortDesc: boolean;
}

export interface CookieInventoryPagesRequestBody {
  pageUrl?: {
    filterType?: string,
    filterValue?: string
  };
  cookieName?: string;
}

export interface CookieInventoryPagesResponse {
  metadata: {
    pagination: {
      totalCount: number;
      totalPageCount: number;
      pageSize: number;
      currentPageSize: number;
      currentPageNumber: number
    }
  },
  pages: CookieInventoryPages[]
}

export interface CookieInventoryPages {
  pageId: string;
  pageUrl: string;
  finalPageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  finalPageStatusCode: number;
  cookieCount: number;
  totalCookieSize: number
}

export interface CookieInventoryPagesRow {
  pageId: string;
  pageUrl: string;
  pageLoadTime: number;
  pageStatusCode: number;
  cookieCount: number;
  totalCookieSize: number;
  pageLoadTimeClass: string;
  pageStatusCodeClass: string;
}

export interface CookieInventoryCookiePagesRequestBody {
  pageUrl: {
    filterType: string;
    filterValue: string;
  },
  cookie: {
    name: string;
    domain: string;
    partyType: string;
    expirationType: string;
    sameSite: string;
    secure: boolean;
    httpOnly: boolean;
    partitionKey: {
      value: string|null;
    };
  }
}

export interface CookieFilterVariables {
  cookie: {
    name: string;
    domain?: string;
    expirationType?: string;
    sameSite?: string;
    secure?: boolean;
    httpOnly?: boolean;
    partyType?: string;
  }
}
