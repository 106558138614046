<usage-top-widget>
  <div class="alert-bell-container">
    <div class="alert-bell">
      <op-widget-bell *ngIf="(isVisitorMode$ | async) === false"
                      [widgetName]="usageAlertMetric"
                      [widgetIsHovered]="true"
                      [disableHighlightFromClick]="true"
                      [user]="user"
                      [menuContext]="EAlertMenuContext.Usage"
      ></op-widget-bell>
    </div>
  </div>
  <usage-users-widget [usersUsage]="summary?.usersUsage"></usage-users-widget>
</usage-top-widget>
<usage-top-widget class="help-widget">
  <usage-help-widget [contacts]="summary?.contacts"></usage-help-widget>
</usage-top-widget>
