export enum EWebVitalsMetricType {
  CumulativeLayoutShift = 1,
  FirstContentfulPaint = 2,
  LargestContentfulPaint = 3,
  TimeToFirstByte = 4,
}

export enum EWebVitalsWidgetStatus {
  Pass = 'pass',
  Warn = 'warn',
  Fail = 'fail',
  NoData = 'no-data', // metric couldn't be calculated for this page
  NotAvailable = 'not-available' // audit run is older than when we began collecting web vitals data
}

export const WEB_VITALS_METRIC_NAMES = {
  [EWebVitalsMetricType.CumulativeLayoutShift]: 'Cumulative Layout Shift (CLS)',
  [EWebVitalsMetricType.FirstContentfulPaint]: 'First Contentful Paint (FCP)',
  [EWebVitalsMetricType.LargestContentfulPaint]: 'Largest Contentful Paint (LCP)',
  [EWebVitalsMetricType.TimeToFirstByte]: 'Time to First Byte (TTFB)',
};

export const WEB_VITALS_WIDGET_TOOLTIPS = {
  [EWebVitalsMetricType.LargestContentfulPaint]: 'Largest Contentful Paint (LCP) is a stable Core Web Vital metric for measuring perceived load speed. It marks the point in the page load timeline when the page\'s main content has likely loaded. A fast LCP helps reassure the user that the page is useful.',
  [EWebVitalsMetricType.FirstContentfulPaint]: 'The First Contentful Paint (FCP) metric measures the time from when the user first navigated to the page to when any part of the page\'s content is rendered on the screen. For this metric, "content" refers to text, images (including background images), <svg> elements, or nonwhite <canvas> elements.',
  [EWebVitalsMetricType.TimeToFirstByte]: 'Time to First Byte (TTFB) is a metric that measures the time between the request for a resource and when the first byte of a response begins to arrive.',
  [EWebVitalsMetricType.CumulativeLayoutShift]: 'Cumulative Layout Shift (CLS) is a stable Core Web Vital metric. It\'s an important, user-centric metric for measuring visual stability because it helps quantify how often users experience unexpected layout shifts. A low CLS helps ensure that the page is delightful.',
};

export const WEB_VITALS_TOOLTIPS = {
  NOT_AVAILABLE: 'Web Vitals are only available on runs after March 18, 2024',
  NO_DATA: 'Unable to retrieve this metric from Google after multiple attempts.'
};