<p class="description">Get email addresses for other common destinations you use.</p>

<notification-channels-horizontal-list></notification-channels-horizontal-list>
<div>
  <op-button-2021 labelText="Assign Email Address To Items"
                  matIcon="control_point"
                  (click)="createProfile.emit()"
                  buttonType="filled">
  </op-button-2021>
</div>


