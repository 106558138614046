import { EDataSourcesSortBy, EDataSourceType } from "@app/components/shared/services/data-sources/data-sources.constants";
import { IDataSourceQueryParams } from "@app/components/shared/services/data-sources/data-sources.models";

export const defaultCCDataSourceQueryParams: IDataSourceQueryParams = {
  page: 0,
  pageSize: 100,
  sortBy: EDataSourcesSortBy.ItemName,
  sortDesc: false,
  itemType: EDataSourceType.Audit,
  withRuns: true,
}