<div class="month-by-date-selector">
  <mat-form-field appearance="outline" class="no-overrides">
    <mat-label>DATES OF THE MONTH</mat-label>
    <mat-chip-grid #chipGrid>
      @for (day of selectedDays; track day) {
        <mat-chip-row class="chip-color-primary" (removed)="remove(day)">
          {{ day }}
          <button matChipRemove [attr.aria-label]="'remove ' + day">
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
    <input
      name="currentDay"
      #dayInput
      [formControl]="dayCtrl"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      @for (day of filteredMonthDays$ | async; track day) {
        <mat-option [value]="day">{{ day }}</mat-option>
      }
    </mat-autocomplete>
    <mat-hint *ngIf="warning" class="warning">
      <mat-icon class="warning-icon material-icons-outlined">warning_outline</mat-icon>
      {{ warning }}
    </mat-hint>
  </mat-form-field>
</div>