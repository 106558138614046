import { AfterViewInit, Component, HostListener, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { auditExports } from './web-journey-export.constants';
import { AccountsService } from '@app/components/account/account.service';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { ModalEscapeService } from '@app/components/ui/modalEscape/modalEscapeService';
import {
  WebJourneyExportService
} from '@app/components/web-journey-report/web-journey-exports/web-journey-export.service';
import {
  AbstractExportReports
} from '@app/components/shared/components/export-report/export-reports-component.abstract.directive';
import { IAdvancedExportTag, IExportItem } from '@app/components/shared/components/export-report/export-reports.models';
import { EExportStatuses } from '@app/components/shared/components/export-report/export-reports.enums';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import { WebJourneyReportService } from '../web-journey-report.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IStatusBannerSettings, StatusBannerService } from '@app/components/reporting/statusBanner/statusBannerService';
import { WebJourneyV3Service } from '@app/components/domains/webJourneys/web-journey-v3-api/web-journey-v3.service';
import { RouteReloadService } from '@app/components/shared/services/route-reload.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'web-journey-export',
  templateUrl: './web-journey-export.component.html',
  styleUrls: ['./web-journey-export.component.scss']
})
export class WebJourneyExportComponent
  extends AbstractExportReports
  implements OnInit, AfterViewInit {
  showFullScreenStatusBanner = false;
  runNowPending = false;
  showMainSpinner = false;

  @HostListener('document:keydown.escape')
  keyDown(): void {
    if (!this.modalEscapeService.getLast()) {
      this.webJourneyReportService.closeReport(this.itemId);
    }
  }

  readonly exports: IExportItem[] = [
    {
      name: auditExports.tagAndVariableData.name,
      description: auditExports.tagAndVariableData.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/web-journeys/wj-tag-and-variable.png',
      export: () => this.openConfigModal()
    },
    {
      name: auditExports.tagSummary.name,
      description: auditExports.tagSummary.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/web-journeys/wj-tag-summary.png',
      export: () => this.export('action_tags', auditExports.tagSummary.name)
    },
    {
      name: auditExports.actionSummary.name,
      description: auditExports.actionSummary.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/web-journeys/wj-action-summary.png',
      export: () => this.export('action_summary', auditExports.actionSummary.name)
    },
    {
      name: auditExports.rulesSummary.name,
      description: auditExports.rulesSummary.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/web-journeys/wj-rule-summary.png',
      export: () => this.export('rule_summary', auditExports.rulesSummary.name)
    },
    {
      name: auditExports.cookies.name,
      description: auditExports.cookies.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/web-journeys/wj-cookies.png',
      export: () => this.export('action_cookies', auditExports.cookies.name)
    },
    {
      name: auditExports.requestLog.name,
      description: auditExports.requestLog.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/web-journeys/wj-request-log.png',
      export: () => this.export('action_network_requests', auditExports.requestLog.name)
    },
    {
      name: auditExports.consoleLog.name,
      description: auditExports.consoleLog.description,
      status: EExportStatuses.notExported,
      previewUrl: '/images/export-report-previews/web-journeys/wj-console-logs.png',
      export: () => this.export('action_browser_logs', auditExports.consoleLog.name)
    }
  ];

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected routeReload: RouteReloadService,
    protected accountsService: AccountsService,
    protected service: WebJourneyExportService,
    protected opModalService: OpModalService,
    protected modalEscapeService: ModalEscapeService,
    private webJourneyAPIService: IWebJourneyApiService,
    private webJourneyReportService: WebJourneyReportService,
    protected snackbar: MatSnackBar,
    private webJourneyV3Service: WebJourneyV3Service,
    private statusBannerService: StatusBannerService,
    protected zone: NgZone,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.checkFullScreenBannerState();

    this.routeReload.reloadRouteEvents$
      .pipe(takeUntil(this.destroy$))
      .subscribe(_ => this.ngOnInit());
  }

  private checkFullScreenBannerState(): void {
    this.showMainSpinner = true;
    this.showFullScreenStatusBanner = false;

    this.statusBannerService.getSettingsForWebJourney(this.itemId).then(
      (settings: IStatusBannerSettings) => {
        this.showMainSpinner = false;
        this.showFullScreenStatusBanner = settings.notRunYet || settings.firstRun;
      },
      () => {
        this.showMainSpinner = false;
        this.showFullScreenStatusBanner = false;
      }
    );
  }

  runNow(): void {
    if (this.runNowPending) {
      return;
    }
    this.runNowPending = true;
    this.webJourneyV3Service.runWebJourneyNow(this.itemId).subscribe(() => {
      this.runNowPending = false;
      this.router.navigateByUrl(this.router.url);
    }, (error) => this.webJourneyV3Service.handleWebJourneyRunError(error));
  }

  getRouteParams() {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.itemId = parseInt(params.journeyId);
        this.runId = parseInt(params.runId);
      });
  }

  goToExportCenter() {
  }

  private openConfigModal() {
    const reportType = 'action_tag_variables';
    const reportItem = this.exports.find(report => report.name === auditExports.tagAndVariableData.name);
    reportItem.status = EExportStatuses.generatingExport;

    this.webJourneyAPIService.getJourneyResult(this.itemId, this.runId).then(results => {

      const tagsById: {[tagId: number]: IAdvancedExportTag} = {};

      results.actions.forEach(action => {
        action.tags.forEach(tag => {

          if (!tagsById[tag.tagId]) {
            tagsById[tag.tagId] = {
              id: tag.tagId,
              name: tag.tagName,
              icon: tag.tagIcon,
              accounts: [tag.account],
              selected: false
            };
          } else {
            if (!tagsById[tag.tagId].accounts.includes(tag.account)) {
              tagsById[tag.tagId].accounts.push(tag.account);
            }
          }
        });
      });
      this.openExportConfig(Object.values(tagsById), reportType, reportItem);
    });
  }
}
