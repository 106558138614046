<op-modal [rightFooterButtons]="rightFooterButtons"
          (onClose)="closeModal()">
  <ng-template #headerTemplate>
    <op-modal-header title="{{ createMode ? 'Create' : 'Edit' }} Label"
                     (onClose)="closeModal()">
    </op-modal-header>
  </ng-template>
  <ng-template #bodyTemplate>
    <div class="edit-label-name">
      <ng-container *ngIf="editing">
        <form [formGroup]="labelForm">
          <mat-form-field>
            <input matInput placeholder="Label Name" formControlName="name">
            <mat-error *ngIf="name.errors?.required">This field is required</mat-error>
          </mat-form-field>
        </form>
      </ng-container>
      <ng-container *ngIf="saving">
        <div class="center-elements">
          <mat-spinner [diameter]="25" [strokeWidth]="4"></mat-spinner>
          <span>Saving...</span>
        </div>
      </ng-container>
      <ng-container *ngIf="error">
        <div class="center-elements">
          <p>There was a problem updating the label name.<br>Please refresh the page and try again.</p>
        </div>
      </ng-container>
    </div>
  </ng-template>
</op-modal>
