export const EventTypes = [
  {
    key: 'created',
    label: 'Created'
  }, {
    key: 'edited',
    label: 'Edited'
  }, {
    key: 'deleted',
    label: 'Deleted'
  }, {
    key: 'run-requested',
    label: 'Run Requested'
  }, {
    key: 'overages-enabled',
    label: 'Overages Enabled',
  },
  {
    key: 'overages-disabled',
    label: 'Overages Disabled',
  }
];

export const ItemTypes = [
  {
    key: 'account',
    label: 'Account'
  }, {
    key: 'audit',
    label: 'Audit' 
  }, {
    key: 'web-journey',
    label: 'Web Journey'
  }, {
    key: 'consent-category',
    label: 'Consent Category'
  }, {
    key: 'folder',
    label: 'Folder'
  }, {
    key: 'subfolder',
    label: 'Sub-folder'
  },
  {
    key: 'email-inbox',
    label: 'Email Inbox'
  }
];
