import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import {
  TagHealthTag,
  TagHealthTagAccount,
  TagHealthTags,
  TagStatusCodeDistribution
} from '@app/components/audit-reports/reports/tag-health/tag-health.models';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { TagHealthService } from '@app/components/audit-reports/reports/tag-health/tag-health.service';
import { TagTableFilterType } from '@app/components/audit-reports/reports/tag-health/tags-list/tag-list.constants';
import {
  StatusCodeIndicatorsPayload
} from '@app/components/audit-reports/status-code-indicators/status-code-indicators.models';
import {
  LoadTimeReportFilter,
  TagAccountTableRow,
  TagTableFilter,
  TagTableRow
} from '@app/components/audit-reports/reports/tag-health/tags-list/tags-list.models';
import { IAuditReportApiPostBody } from '@app/components/audit-reports/audit-report/audit-report.models';
import { tap } from 'rxjs/operators';
import { EFilterSpinnerState } from '@app/components/shared/components/filter-spinner/filter-spinner.constants';
import {
  AuditReportFilterBarService
} from '@app/components/audit-reports/audit-report-filter-bar/audit-report-filter-bar.service';
import { MatSort } from '@angular/material/sort';
import { AuditReportScrollService } from '@app/components/audit-reports/audit-report-scroll.service';
import { ValueDisplayAs } from '@app/components/audit-reports/percent-numeric-toggle/percent-numeric-toggle.component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { UiTagService } from '@app/components/tag-database/tag-database.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: [ './tags-list.component.scss' ],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
  providers: [ DecimalPipe ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsListComponent implements AfterViewInit, OnChanges {
  @Input() set items(data: TagHealthTags) {
    if (data) {
      this.tableView$.subscribe(view => {
        this.dataSource.data = this.prepareTags(data.tags, view);
      });
    }
  }

  @Input() auditId: number;
  @Input() runId: number;
  @Input() apiFilters: IAuditReportApiPostBody;
  @Input() tableState: EFilterSpinnerState;

  @Output() tagTableFilterCreated = new EventEmitter<TagTableFilter>();
  @Output() loadTimeReportFilterCreated = new EventEmitter<LoadTimeReportFilter>();

  accountListLoading = false;
  accountListLength: number;
  tags: TagHealthTag[];
  dataSource = new MatTableDataSource<TagTableRow>();
  expandedElement: any;
  selectedTag: TagTableRow;
  selectedTagAccount: TagAccountTableRow;
  selectedLoadTimeBucket: string;
  columns: string[] = [
    'expand',
    'name',
    'numberOfAccounts',
    'averageRequestSize',
    'numberOfRequests',
    'below500',
    'from500to1000',
    'from1000to2000',
    'above2000',
    'statusCodeDistribution'
  ];

  tableView$ = new BehaviorSubject<ValueDisplayAs>(ValueDisplayAs.Percentage);

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private tagHealthService: TagHealthService,
    private filterBarService: AuditReportFilterBarService,
    private cd: ChangeDetectorRef,
    private scrollService: AuditReportScrollService,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnChanges() {
    if (this.apiFilters) {
      this.selectedTag = undefined;
      this.selectedTagAccount = undefined;
      this.selectedLoadTimeBucket = undefined;
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name': {
          return item[property].toLowerCase();
        }
        case 'below500':
        case 'from500to1000':
        case 'from1000to2000':
        case 'above2000': {
          const value: string | number = item[property];
          return typeof value === 'string' ? +(value as string).split('%')[0] : value;
        }
        default:
          return item[property];
      }
    };
  }

  private prepareTags(tags: TagHealthTag[], tableView?: ValueDisplayAs): TagTableRow[] {
    return tags.map(tag => {
      return {
        id: tag.tagId,
        tagIcon: UiTagService.getTagIconUrl(tag.tagId),
        name: tag.tagName,
        numberOfAccounts: tag.filteredTagAccountCount,
        numberOfRequests: tag.tagInstanceCount,
        averageRequestSize: tag.tagRequestSizeAverage,
        below500: this.convert(tag.tagInstanceCount, tag.tagLoadTimeDistribution['below500'], tableView),
        from500to1000: this.convert(tag.tagInstanceCount, tag.tagLoadTimeDistribution['500to1000'], tableView),
        from1000to2000: this.convert(tag.tagInstanceCount, tag.tagLoadTimeDistribution['1000to2000'], tableView),
        above2000: this.convert(tag.tagInstanceCount, tag.tagLoadTimeDistribution['above2000'], tableView),
        statusCodeDistribution: tag.tagStatusCodeDistribution
      };
    });
  }

  private prepareAccounts(accounts: TagHealthTagAccount[], tableView: ValueDisplayAs): TagAccountTableRow[] {
    return accounts.map(account => {
      return {
        id: account.tagId,
        name: account.tagAccount,
        numberOfRequests: account.tagInstanceCount,
        averageRequestSize: account.tagRequestSizeAverage,
        below500: this.convert(account.tagInstanceCount, account.tagLoadTimeDistribution['below500'], tableView),
        from500to1000: this.convert(account.tagInstanceCount, account.tagLoadTimeDistribution['500to1000'], tableView),
        from1000to2000: this.convert(account.tagInstanceCount, account.tagLoadTimeDistribution['1000to2000'], tableView),
        above2000: this.convert(account.tagInstanceCount, account.tagLoadTimeDistribution['above2000'], tableView),
        statusCodeDistribution: account.tagStatusCodeDistribution
      };
    });
  }

  expand(tag: TagTableRow): void {
    if (this.expandedElement === tag) {
      this.expandedElement = null;
    } else {
      this.expandedElement = tag;
      this.getTagAccounts(tag);
    }
  }

  private getTagAccounts(tag: TagTableRow): void {
    this.accountListLoading = true;
    combineLatest([
      this.tagHealthService
        .getTagHealthTagAccounts(this.auditId, this.runId, tag.id, this.apiFilters)
        .pipe(tap(({ tagAccounts }) => this.accountListLength = tagAccounts.length)),
      this.tableView$
    ]).subscribe(([ { tagAccounts }, view ]) => {
      this.accountListLoading = false;
      // cut 20 items by design if they are exist. if they are more that 20 we show info wrap
      tag.tagAccounts = this.prepareAccounts(tagAccounts.slice(0, 20), view);
      this.cd.markForCheck();
    });
  }

  viewSelected(view: ValueDisplayAs): void {
    this.tableView$.next(view);
  }

  private convert(total: number, value: number, tableView): any {
    const getPercentage = (x: number) => x / total * 100;

    if (tableView === 'percentage') {
      return `${ getPercentage(value).toFixed(2) }%`;
    } else {
      return this.decimalPipe.transform(value);
    }
  }

  formatDataForStatusCodeIndicators(data: TagStatusCodeDistribution): StatusCodeIndicatorsPayload {
    return {
      broken: data.broken,
      redirect: data.redirect,
      good: data.good
    };
  }

  filterByTag(tag: TagTableRow): void {
    const filter = {
      type: TagTableFilterType.Tag,
      value: {
        tagId: tag.id,
        tagName: tag.name
      }
    };
    this.tagTableFilterCreated.emit(filter);
  }

  filterByAccAndTag(tag: TagTableRow, account: TagAccountTableRow): void {
    this.filterByTag(tag);
    const filter = {
      type: TagTableFilterType.TagAccount,
      value: {
        tagId: tag.id,
        accountName: account.name
      }
    };
    this.tagTableFilterCreated.emit(filter);
  }

  onLoadTimeBucketSelected(tag: TagTableRow, account: TagAccountTableRow | undefined, bucket: string): void {
    const sameTag = this.selectedTag?.id === tag.id;
    const absentOrSameAccount = (!this.selectedTagAccount && !account) ||
      (this.selectedTagAccount && account && this.selectedTagAccount.name === account.name);
    const sameBucket = this.selectedLoadTimeBucket === bucket;

    if (sameTag && absentOrSameAccount && sameBucket) {
      this.selectedTag = undefined;
      this.selectedTagAccount = undefined;
      this.selectedLoadTimeBucket = undefined;

      this.loadTimeReportFilterCreated.emit(undefined);
    } else {
      this.selectedTag = tag;
      this.selectedTagAccount = account;
      this.selectedLoadTimeBucket = bucket;

      if (account) {
        this.loadTimeReportFilterCreated.emit({
          type: TagTableFilterType.TagAccount,
          bucket: bucket,
          value: {
            tagId: tag.id,
            accountName: account.name
          }
        });
      } else {
        this.loadTimeReportFilterCreated.emit({
          type: TagTableFilterType.Tag,
          bucket: bucket,
          value: {
            tagId: tag.id,
            tagName: tag.name
          }
        });
      }
    }
  }

  trackFn(index, item): string {
    return item.name;
  }

  isTagRowSelected(tag: TagTableRow): boolean {
    return this.selectedTag && this.selectedTag.id === tag.id && !this.selectedTagAccount;
  }

  isTagAccountRowSelected(tag: TagTableRow, account: TagAccountTableRow): boolean {
    return this.selectedTag && this.selectedTagAccount && this.selectedTag.id === tag.id && this.selectedTagAccount.name === account.name;
  }

  isTagAccountRowSelectedAndCollapsed(tag: TagTableRow): boolean {
    return this.selectedTag && this.selectedTagAccount && this.selectedTag.id === tag.id && this.expandedElement === null;
  }

  isTagLoadTimeBucketSelected(tag: TagTableRow, bucket: string): boolean {
    return this.isTagRowSelected(tag) && this.selectedLoadTimeBucket === bucket;
  }

  isTagAccountLoadTimeBucketSelected(tag: TagTableRow, account: TagAccountTableRow, bucket: string): boolean {
    return this.isTagAccountRowSelected(tag, account) && this.selectedLoadTimeBucket === bucket;
  }

  filterByStatusCode(data: { type: TagTableFilterType, value: string }, tag?: TagTableRow, account?: TagAccountTableRow | undefined) {
    if (tag && account) {
      this.filterByAccAndTag(tag, account);
    }

    if (tag && !account) {
      this.filterByTag(tag);
    }

    this.tagTableFilterCreated.emit(data);
  }
}
