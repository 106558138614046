import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDomainsService } from '@app/components/domains/domainsService';
import { IFolder } from '@app/components/folder/foldersApiService';
import { forkJoin } from 'rxjs';
import { DEFAULT_DOMAIN, EManageDomainsMode } from './create-edit-domain.constants';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ICreateDomainRequest, IUpdateDomainRequest } from '@app/components//domains/domainsService';
import { HttpErrorResponse } from '@angular/common/http';
import { ITableDomain } from '../manage-domains.models';
import { DateService, EDateFormats } from '@app/components/date/date.service';
import { ICreateEditDomainData } from './create-edit-domain.models';
import { IButton } from '@app/models/commons';

/** DOMAINS ARE NOW CALLED SUBFOLDERS
 *   - we have not updated code references or component names to reflect this renaming
 *   - user facing strings should use the new nomenclature
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'create-edit-domain',
  templateUrl: './create-edit-domain.component.html',
  styleUrls: ['./create-edit-domain.component.scss']
})
export class CreateEditDomainComponent implements OnInit {

  manageDomainsForm: UntypedFormGroup;
  domains: ITableDomain[];
  folders: IFolder[];
  multi: boolean;
  mode: EManageDomainsMode;
  useDefaultDomain: boolean = false;  // Domain field is hidden and value defaults to http://www.example.com if set to true

  readonly manageDomainsMode = EManageDomainsMode;
  readonly defaultDomain = DEFAULT_DOMAIN;

  editing = true;
  saving = false;
  error = false;

  rightFooterButtons: IButton[] = [
    {
      label: 'Save',
      action: () => {
        this.save();
      },
      primary: true,
      opSelector: 'domain-setup-save-btn',
    }
  ];

  constructor(
    public dialogRef: MatDialogRef<CreateEditDomainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ICreateEditDomainData,
    private domainService: IDomainsService,
    private formBuilder: UntypedFormBuilder,
    private dateService: DateService,
  ) {
    this.mode = data.mode;
    this.domains = data.domains;
    this.multi = this.domains && !!(this.domains.length > 1);
    this.folders = data.folders.sort((folder1, folder2) => (folder1.name.toLowerCase() > folder2.name.toLowerCase()) ? 1 : -1);
  }

  ngOnInit(): void {
    this.initForm();
    this.initFields();
  }

  private initForm(): void {
    this.manageDomainsForm = this.formBuilder.group({
      folder: [''],
      name: [''],
      domain: [this.defaultDomain],
      dataLayer: ['']
    });

    // when editing multiple domains, all fields are optional because
    // patch update is used (fields left blank will not be updated)
    if (!this.multi) {
      this.folder.setValidators(Validators.required);
      this.name.setValidators(Validators.required);
    }
  }

  private initFields(): void {
    // if we're editing multiple fields or we're
    // creating a new domain then return
    if (this.multi || (this.mode === EManageDomainsMode.Create)){
      if (this.data?.useDefaultDomain) {
        this.useDefaultDomain = this.data.useDefaultDomain;
        this.domain.setValue(DEFAULT_DOMAIN);
      }
      return;
    }

    const { folder, name, domain, dataLayer } = this.domains[0];
    this.manageDomainsForm.patchValue({
      folder, name, domain, dataLayer
    });
  }

  save(): void {
    this.manageDomainsForm.markAllAsTouched();
    if (this.manageDomainsForm.invalid) return;

    this.mode === EManageDomainsMode.Create
      ? this.createDomain()
      : this.multi ? this.updateMultiDomains() : this.updateSingleDomain();

    this.editing = false;
    this.saving = true;
    this.rightFooterButtons[0]['disabled'] = true;
  }

  private createDomain(): void {
    const domain: ICreateDomainRequest = {
      name: this.name.value,
      domain: this.domain.value,
      dataLayer: this.dataLayer.value,
      folderId: this.folder.value.id
    };

    this.domainService.createDomain(domain)
      .then(domain => {
        const newDomain: ITableDomain = {
          id: domain.id,
          folder: this.folder.value,
          folderName: this.folder.value.name,
          name: this.name.value,
          domain: this.domain.value,
          dataLayer: this.dataLayer.value,
          createdBy: domain.userId,
          createdDate: this.dateService.formatDate(new Date(domain.createdAt), EDateFormats.dateTwentyThree),
        };

        this.closeModal(newDomain);
      })
      .catch((error: HttpErrorResponse) => {
        this.handleError(error);
      });
  }

  private updateSingleDomain(): void {
    const { id, createdBy, createdDate } = this.domains[0];
    const updatedDomain: ITableDomain = {
      id,
      name: this.name.value,
      domain: this.domain.value,
      dataLayer: this.dataLayer.value,
      createdBy,
      createdDate,
      folderName: this.folder.value.name,
      folder: this.folder.value
    };

    const updateRequest: IUpdateDomainRequest = {
      id: updatedDomain.id,
      name: updatedDomain.name,
      domain: updatedDomain.domain,
      dataLayer: updatedDomain.dataLayer,
      folderId: updatedDomain.folder.id
    };

    this.domainService.updateDomain(updateRequest)
      .then(() => this.closeModal(updatedDomain))
      .catch((error: HttpErrorResponse) => this.handleError(error));
  }

  private updateMultiDomains(): void {
    const updatedDomains: ITableDomain[] = [];
    const domainUpdatePromises = this.domains.map(domain => {
      const updatedDomain: ITableDomain = {
        ...domain,
        folder: this.folder.value ? this.folder.value : domain.folder,
        folderName: this.folder.value ? this.folder.value.name : domain.folder.name,
        name: this.name.value ? this.name.value : domain.name,
        domain: this.domain.value ? this.domain.value : domain.domain,
        dataLayer: this.dataLayer.value ? this.dataLayer.value : domain.dataLayer
      };

      updatedDomains.push(updatedDomain);

      const updateRequest: IUpdateDomainRequest = {
        id: updatedDomain.id,
        name: updatedDomain.name,
        domain: updatedDomain.domain,
        dataLayer: updatedDomain.dataLayer,
        folderId: updatedDomain.folder.id
      };

      return this.domainService.updateDomain(updateRequest);
    });

    forkJoin(domainUpdatePromises).subscribe(
      () => this.closeModal(updatedDomains),
      (error: HttpErrorResponse) => this.handleError(error)
    );
  }

  private handleError(error: HttpErrorResponse): void {
    console.error(error);
    this.saving = false;
    this.error = true;
    this.rightFooterButtons[0].label = 'OK';
    this.rightFooterButtons[0]['disabled'] = false;
    this.rightFooterButtons[0].action = () => {
      this.closeModal();
    };
  }

  closeModal(domains?: ITableDomain | ITableDomain[]): void {
    this.dialogRef.close(domains);
  }

  get folder(): AbstractControl {
    return this.manageDomainsForm.get('folder');
  }

  get name(): AbstractControl {
    return this.manageDomainsForm.get('name');
  }

  get domain(): AbstractControl {
    return this.manageDomainsForm.get('domain');
  }

  get dataLayer(): AbstractControl {
    return this.manageDomainsForm.get('dataLayer');
  }
}
