import { ILabel } from '@app/components/shared/services/label.service';
import { IReduxAction } from './reducers';
import { ActionTypes } from '../constants/actionTypes';
import * as Immutable from 'immutable';

function setLabels(labelsState, labels: Array<ILabel>) {
  return labelsState.set('all', Immutable.fromJS(labels));
}

export function labelReducer(labelsState = Immutable.Map(), action: IReduxAction<Array<ILabel>>) {
  switch (action.type) {
    case ActionTypes.SET_LABELS:
      return setLabels(labelsState, action.payload);
  }

  return labelsState;
}
