import * as angular from 'angular';
import { AngularNames, Names } from '@app/moonbeamConstants';
import { StopRunItem, StopRunItemsGroup, RunnableItem, StopRunModalController } from './stopRunModalController';
import { StatusUpdateType, StatusBannerService, IProgressStatus, DeletionStatus } from '@app/components/reporting/statusBanner/statusBannerService';
import { IEventManager } from '../eventManager/eventManager';
import { IModalService } from '../modals/modalService';
import { Events } from '@app/moonbeamConstants';
import { ModalTemplates } from '../modals/modalTemplates';
import { IStopRunModalData } from '../modals/modalData';
import { ArrayUtils } from '../utilities/arrayUtils';
import { OpModalService } from '@app/components/shared/components/op-modal';
import { IDiscoveryAuditService } from '@app/components/domains/discoveryAudits/discoveryAuditService';
import { IWebJourneyApiService } from '@app/components/domains/webJourneys/webJourneyAPI/webJourneyAPIService';
import {
  ManualJourneyService,
  name as ManualJourneyServiceName
} from '@app/components/live-connect/manual-journey/manualJourneyService';
import { SnackbarService } from '@app/components/shared/services/snackbar-service';

  export interface IRunsStoppedEvent {
    items: Array<StopRunItem>
  }

  export abstract class IStopRunService {
    abstract stopRun(itemType: RunnableItem, itemId: number, runId: number, onStop: () => void): void;
    abstract stopRuns(items: Array<StopRunItem>, onStop: () => void): void;
    abstract stopRunInternal(item: StopRunItem): ng.IPromise<boolean>;
    abstract stopWebJourneyRun(itemId: number, runId: number): ng.IPromise<boolean>;
    abstract subscribeOnStopRunUpdates(stopRunsGroup: StopRunItemsGroup): void;
    abstract subscribeOnRunStoppedEvent(itemType: RunnableItem, itemId: number, runId: number, callback: () => void): number;
    abstract unsubscribeRunStoppedEvent(subscriptionId: number): void;
    abstract runnableItem(updateType: StatusUpdateType): RunnableItem;
  }

  export class StopRunService extends IStopRunService {

    static $inject = [
      AngularNames.q,
      Names.NgServices.snackbarService,
      Names.Services.statusBanner,
      Names.Services.eventManager,
      Names.Services.modal,
      Names.NgServices.opModalService,
      Names.Services.discoveryAudit,
      Names.Services.webJourneyAPI,
      ManualJourneyServiceName
    ];

    constructor(private $q: angular.IQService,
                private snackbarService: SnackbarService,
                private statusBannerService: StatusBannerService,
                private eventManager: IEventManager,
                private modalService: IModalService,
                private opModalService: OpModalService,
                private auditService: IDiscoveryAuditService,
                private webJourneyService: IWebJourneyApiService,
                private manualJourneyService: ManualJourneyService) {
      super();
    }

    subscribeOnStopRunUpdates(stopRunsGroup: StopRunItemsGroup): void {
      const items = stopRunsGroup.items;
      const ctrl = this;
      var remaining = items.length;
      if (remaining === 0) return;

      const checkRemainingRuns = function() {
        if (remaining === 0) {
             ctrl.displayRunIsStoppedToast(stopRunsGroup.notificationTitle);
          ctrl.eventManager.publish(Events.runsStopped, <IRunsStoppedEvent> { items: items });
        }
      };

      items.forEach(item => {
        // Web journeys no longer follow the pattern below.
        // If we're stopping web journey we know it has been stopped already.
        if (item.itemType === RunnableItem.WebJourney) {
          remaining--;
          return;
        }

        this.prefetchStatus(item).then(status => {
          if (this.isRunStopped(status)) {
            remaining--;
            checkRemainingRuns();
          } else {
            const updatesType = this.statusUpdatesType(item.itemType);
            this.statusBannerService.subscribeForStatusUpdates(updatesType, item.itemId, item.runId).subscribe((update) => {
              if (this.isRunStopped(update)) {
                remaining--;
                checkRemainingRuns();
              }
            });
          }
        }, (error) => {
          if (error.code === 404) {
            // Run is already deleted
            remaining--;
            checkRemainingRuns();
          }
        });
      });

      checkRemainingRuns();
    }

    statusUpdatesType(itemType: RunnableItem): StatusUpdateType {
      switch (itemType) {
        case RunnableItem.Audit:      return StatusUpdateType.Audit;
        case RunnableItem.WebJourney: return StatusUpdateType.WebJourney;
      }
    }

    runnableItem(updateType: StatusUpdateType): RunnableItem {
      switch (updateType) {
        case StatusUpdateType.Audit:         return RunnableItem.Audit;
        case StatusUpdateType.WebJourney:    return RunnableItem.WebJourney;
      }
    }

    subscribeOnRunStoppedEvent(itemType: RunnableItem, itemId: number, runId: number, callback: () => void): number {
      return this.eventManager.subscribe(Events.runsStopped, (event: IRunsStoppedEvent, subscriptionId) => {
        const stoppedRun = this.findStoppedRun(event.items, itemType, itemId, runId);
        if (stoppedRun) {
          callback();
          this.eventManager.unSubscribe(Events.runsStopped, subscriptionId);
        }
      });
    }

    subscribeOnRunGroupStoppedEvent(items: Array<StopRunItem>, callback: () => void): number {
      return this.eventManager.subscribe(Events.runsStopped, (event: IRunsStoppedEvent, subscriptionId) => {
        const stoppedRun = this.findStoppedRuns(event.items, items);
        if (stoppedRun) {
          callback();
          this.eventManager.unSubscribe(Events.runsStopped, subscriptionId);
        }
      });
    }

    unsubscribeRunStoppedEvent(subscriptionId: number): void {
      this.eventManager.unSubscribe(Events.runsStopped, subscriptionId);
    }

    stopRun(itemType: RunnableItem, itemId: number, runId: number, onStop: () => void): void {
      const stoppedRunsEvent = this.subscribeOnRunStoppedEvent(itemType, itemId, runId, onStop);
      let modalRef = this.opModalService.openConfirmModal({
        data: {
          title: 'Stop Run',
          messages: [
            `Are you sure you want to stop this ${itemType.name}?`,
            'All data from this run will be discarded.'
          ],
          rightFooterButtons: [
            {
              label: 'Don\'t Stop',
              action: () => {
                this.unsubscribeRunStoppedEvent(stoppedRunsEvent);
                modalRef.close();
              },
              primary: false
            },
            {
              label: 'Yes, Stop',
              action: () => {
                this.stopRunInternal({
                  itemType: itemType,
                  itemId: itemId,
                  runId: runId
                }).then(() => {
                  onStop();
                  this.displayRunIsStoppedToast('The run has been successfully stopped and discarded');
                });
              },
              primary: true
            }
          ]
        }
      });
    }

    stopRuns(items, onStop: () => void) {
      if (items.length === 0) {
        this.snackbarService.openSuccessSnackbar('No running journeys to stop');
        return;
      }
      const stoppedRunsEvent = this.subscribeOnRunGroupStoppedEvent(items, onStop);
      const template = ModalTemplates.initTemplate<IStopRunModalData>(ModalTemplates.StopRun);
      template.modalData = StopRunModalController.defaultModalDataGroup(items);
      this.modalService.showModal<IStopRunModalData>(template, (response) => {
        if (!response.isStopConfirmed) {
          this.unsubscribeRunStoppedEvent(stoppedRunsEvent);
        }
      });
    }

    stopRunInternal(item: StopRunItem): ng.IPromise<boolean> {
      const itemId = item.itemId;
      switch (item.itemType) {
        case RunnableItem.Audit:
          return this.stopAuditRun(itemId);
        case RunnableItem.WebJourney:
          return this.stopWebJourneyRun(itemId, item.runId);
        case RunnableItem.LiveConnect:
          return this.stopLiveConnectRun(itemId, item.runId);
      }
    }

    private stopAuditRun(auditId: number): ng.IPromise<boolean> {
      return this.auditService.stopActiveAuditRun(auditId);
    }

    stopWebJourneyRun(journeyId: number, runId: number): ng.IPromise<boolean> {
      return this.webJourneyService.stopActiveWebJourneyRun(journeyId, runId);
    }

    private stopLiveConnectRun(journeyId: number, runId: number): ng.IPromise<boolean> {
      return this.manualJourneyService.controlAManualJourney(journeyId, runId, 'stop')
        .then(() => true).catch(_ => false);
    }

    /**
     * @return {boolean} indicates whether the run is stopped or not
     */
    private isRunStopped(status: IProgressStatus): boolean {
      return status.deletion === DeletionStatus.Finished;
    }

    private findStoppedRun(runs: Array<StopRunItem>, itemType: RunnableItem, itemId: number, runId: number): StopRunItem {
      return ArrayUtils.find(runs, item =>
      item.itemType === itemType &&
      item.itemId === itemId &&
      item.runId === runId);
    }

    private findStoppedRuns(runs: Array<StopRunItem>, allItems): boolean {
      let finded = undefined;
      runs.forEach(r => {
        let f = !!this.findStoppedRun(allItems, r.itemType, r.itemId, r.runId);
        finded = typeof finded === 'undefined' ? f : finded && f;
      });
      return finded;
    }

    private displayRunIsStoppedToast(title: string): void {
      this.snackbarService.openSuccessSnackbar(title);
    }

    private prefetchStatus(config: StopRunItem): ng.IPromise<IProgressStatus> {
      const itemId = config.itemId;
      const runId = config.runId;
      if (runId === undefined) {
        return this.$q.reject({code: 404});
      }
      switch (config.itemType) {
        case RunnableItem.Audit:      return this.statusBannerService.getAuditStatusProgress(itemId, runId);
        case RunnableItem.WebJourney:
          console.warn('Calling prefetchStatus() with a web journey is no longer supported');
          return this.$q.resolve({} as any);
      }
    }
  }
